import { Component, OnInit, Input } from '@angular/core';
import { RangeWindow, CellValues, DateRange } from '../../models/workspace/index';
import { DatePipe } from '@angular/common';
import { LocaleData } from '../../core/utils/LocaleData';
import { NgbDatepickerHelper } from '../../core/components/ngb-datepicker/ngb-datepicker-helper';
import * as _ from 'lodash';
import {
  DataShareService,
  CellAttributeService,
  BrickBaseService,
  LogHelperService,
  WorkspaceService,
  StateService
} from '../../core/services';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { SystemFlags, UiControl } from '../../models';
import * as moment from 'moment';
import { GLOBAL } from '../../core/utils/app.constant';
import { NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbRangeModalDateParserService } from './ngb-range-modal-date-parser.service';

@Component({
  selector: 'app-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.css'],
  providers: [DatePipe, { provide: NgbDateParserFormatter, useClass: NgbRangeModalDateParserService }]
})

export class RangeComponent implements OnInit {

  /**
   * @description Holds selected values from this modal
   * @type {Brick}
   * @memberof RangeComponent
   */
  @Input() resolveObject: CellValues;

  /**
   * @description Hold value of readonly Modde
   * @type {boolean}
   * @memberof RangeComponent
   */
  readOnlyModal: boolean;

  /**
   * @description selected modal values
   * @type {RangeWindow}
   * @memberof RangeComponent
   */
  rangeModal: RangeWindow = new RangeWindow();

  /**
   * @description ui control object from initial config
   * @type {UiControl}
   * @memberof RangeComponent
   */
  uiControl: UiControl;

  userBundle: object;

  /**
   * @description date format to use to display date
   * @type {string}
   * @memberof RangeComponent
   */
  displayDateFormat = '';

  showWeekNumber = false;

  minStartDate: NgbDateStruct;

  minEndDate: NgbDateStruct;
  /**
   * @description It will decide to diable end date input
   * @type {boolean}
   * @memberof RangeComponent
   */
  disableEndDate = true;
  /**
   * @description it as start and end date for all incharge bric available on workspace
   * @type {any[]}
   * @memberof RangeComponent
   */
  previousBricksDates: DateRange[] = [];

  /**
   * @description When a product Bric is dropped, it shows date range for which product is valid
   * @type {object}
   * @memberof RangeComponent
   */
  productValidations: any;

  /**
   * @description check pcm mode is on/off
   * @memberof RangeComponent
   */
  pcmMode = false;

  /**
   * @description Array of DefaultWeekDay
   * @memberof RangeComponent
   */
  defaultWeekDay: any[];

  /**
   * @description specific startDate day properties cached object for ngbDatePicker day template
   * @memberof RangeComponent
   */
  startDayObjCache = {};

  /**
   * @description specific endDate day properties cached object for ngbDatePicker day template
   * @memberof RangeComponent
   */
  endDayObjCache = {};

  /**
   * @description If range modal is forcefully rendered and selection is mandatory then close button will be hidden.
   * @memberof RangeComponent
   */
  showCloseButton = SystemFlags.isRangeSelectionMandatory ? false : true;

  /**
   * Creates an instance of RangeComponent.
   * @author Amit Mahida
   * @param {DataShareService} dataShareService
   * @param {DisplayTextService} displayTextService
   * @param {CellAttributeService} CellAttributeService
   * @memberof RangeComponent
   */
  constructor(
    private dataShareService: DataShareService,
    private brickBaseService: BrickBaseService,
    private cellAttributeService: CellAttributeService,
    private logHelperService: LogHelperService,
    private appHeaderService: AppHeaderService,
    private workspaceService: WorkspaceService,
    private datePipe: DatePipe,
    private stateService: StateService) {
  }

  /**
   * @description angualr life cycle hook
   * called on component initialization phase
   * @author Alkesh Shah, Nishit
   * @memberof RangeComponent
   */
  ngOnInit(): void {
    this.pcmMode = this.appHeaderService.enabledPCM;
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.defaultWeekDay = this.dataShareService.getdefaultWeekDay();
    this.initilizePreviousBricksDates();
    const today = new Date();
    this.minStartDate = NgbDatepickerHelper.convertDateToDateStruct(today);
    this.minEndDate = NgbDatepickerHelper.convertDateToDateStruct(today);
    if (this.resolveObject.selectedValues && !this.resolveObject.selectedValues.hasOwnProperty('-99')) {
      this.rangeModal = { ...this.resolveObject.selectedValues };
      this.disableEndDate = false;

      // minDate is overwriten here because, in case of loaded campaign its not displaying date before today in textbox,
      // So minDate is set with actual value, but the selection in calendar will be controlled by 'BeforeDisplay'
      if (new Date() > new Date(this.rangeModal.startDate as Date)) {
        const minDate = new Date(this.rangeModal.startDate as Date);
        this.minStartDate = NgbDatepickerHelper.convertDateToDateStruct(minDate);
      }
      const startDate = new Date(this.rangeModal.startDate as Date);
      const endDate = new Date(this.rangeModal.endDate as Date);

      this.minEndDate = NgbDatepickerHelper.convertDateToDateStruct(startDate);
      // below code is for, when you edit a bric, dates of  that bric should not be disabled
      if (this.previousBricksDates && this.previousBricksDates.length) {
        this.previousBricksDates.forEach((date, index) => {
          if (new Date(date.startDate).setHours(0, 0, 0, 0) === (startDate).setHours(0, 0, 0, 0) &&
            new Date(date.endDate).setHours(0, 0, 0, 0) === (endDate).setHours(0, 0, 0, 0)) {
            this.previousBricksDates.splice(index, 1);
          }
        });
      }
      if (SystemFlags.isRangeSelectionMandatory) {
        // SM-7855 : If it is clone campaign and Range modal is forcefully rendered then this code will be executed.
        const fromDate = this.getNearestInchargeDay();
        const diff = moment(this.rangeModal.endDate).diff(moment(this.rangeModal.startDate), 'days');
        const toDate = moment(fromDate).add(diff, 'd').toDate();
        this.rangeModal.startDate = NgbDatepickerHelper.convertDateToDateStruct(fromDate);
        this.rangeModal.endDate = NgbDatepickerHelper.convertDateToDateStruct(toDate);
      } else {
        this.rangeModal.startDate = NgbDatepickerHelper.convertDateToDateStruct(this.rangeModal.startDate as Date);
        this.rangeModal.endDate = NgbDatepickerHelper.convertDateToDateStruct(this.rangeModal.endDate as Date);
      }
    } else {
      this.resolveObject.selectedValues = null;
    }

    // Below code to show info tooltip in case of product
    if (this.resolveObject.productValidations) {
      this.productValidations = this.resolveObject.productValidations;
    }
    this.displayDateFormat = LocaleData.displayDateFormat;
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.showWeekNumber = this.uiControl.displayWeekNumber;
  }

  /**
   * @description Initialize PreviousBricksDates and then filter it
   * @author Dhaval Patel
   */
  initilizePreviousBricksDates() {
    if (this.pcmMode) {
      this.previousBricksDates = [];
    } else {
      this.previousBricksDates =
        this.workspaceService.getDatesFormAllIncharge(this.stateService.getWorkspaceFilterObj().rows);
    }
    this.previousBricksDates = this.previousBricksDates.filter(item => item !== null);
  }

  /**
   * open start/end date datepicker on click
   * @param $event click event object
   * @param datePicker start/end date picker object
   */
  openDatePicker($event: Event, datePicker: NgbInputDatepicker, otherDatePicker: NgbInputDatepicker): void {
    otherDatePicker.close();
    $event.preventDefault();
    $event.stopPropagation();
    datePicker.toggle();
  }

  /**
   * close date picker on out-side click
   * @param event click event object
   * @param datePicker start/end date picker object
   */
  closeDatePickerOutsideClick(event: any, datePicker: NgbInputDatepicker): void {
    if (datePicker.isOpen()) {
      if ((event.target.offsetParent == null && event.target.nodeName !== 'OPTION') || event.target.offsetParent.nodeName !== 'NGB-DATEPICKER') {
        datePicker.close();
      }
    }
  }

  /**
   * start date select event handler
   * @param inst - selected date instance
   */
  onSelectFrom(inst: NgbDateStruct) {
    this.rangeModal.startDate = inst;
    const monday = NgbDatepickerHelper.convertDateStructToDate(inst);
    this.minEndDate = inst;
    if (SystemFlags.isRangeSelectionMandatory) {
      // SM-7855 : If it is clone campaign and Range modal is forcefully rendered then this code will be executed.
      const diff = moment(this.resolveObject.selectedValues.endDate).diff(moment(this.resolveObject.selectedValues.startDate), 'days');
      const toDate = new Date(this.rangeModal.startDate.year, this.rangeModal.startDate.month - 1, this.rangeModal.startDate.day + diff);
      this.rangeModal.endDate = NgbDatepickerHelper.convertDateToDateStruct(toDate);
      return;
    }
    if (monday.getDay() === this.uiControl.defaultInChargeDay) {
      const endDate = new Date(inst.year, inst.month - 1,
        (inst.day + ((7 * this.uiControl.defaultInChargeLength) - 1)));
      this.rangeModal.endDate = NgbDatepickerHelper.convertDateToDateStruct(endDate);

      if (this.uiControl.defaultInChargeLength > 1
        && this.uiControl.inchargeContinuousFromLastYear
        && this.uiControl.inchargeExceptionYears && this.uiControl.inchargeExceptionYears.length > 0
        && this.uiControl.inchargeExceptionYears.indexOf(this.rangeModal.endDate.year) > -1
        && this.rangeModal.endDate.year !== this.rangeModal.startDate.year
        && ((new Date(this.rangeModal.startDate.year, 11, 31).valueOf() - monday.valueOf()) / (60 * 60 * 24 * 1000) < 7)
      ) {
        this.rangeModal.endDate = NgbDatepickerHelper.convertDateToDateStruct(new Date(inst.year, inst.month - 1, (inst.day + ((7 * 1) - 1))));
      }
    } else {
      this.rangeModal.endDate = null;
    }
    this.endDayObjCache = {};
    this.disableEndDate = false;

    if (this.previousBricksDates.length > 0 && !this.uiControl.overlappingRange && !SystemFlags.isRangeSelectionMandatory) {
      const currentdate = this.datePipe.transform(NgbDatepickerHelper.convertDateStructToDate(this.rangeModal.endDate as NgbDateStruct), GLOBAL.DATE_PARSE_FORMAT);
      for (const dates of this.previousBricksDates) {
        const startdate = this.datePipe.transform(dates.startDate, GLOBAL.DATE_PARSE_FORMAT);
        const enddate = this.datePipe.transform(dates.endDate, GLOBAL.DATE_PARSE_FORMAT);

        if ((currentdate <= enddate && currentdate >= startdate)) {
          this.rangeModal.endDate = null;
        }
      }
    }
  }

  markStartDayDisabled = (date: NgbDateStruct): boolean => {
    if (SystemFlags.isRangeSelectionMandatory) {
      const startDate = this.rangeModal.startDate as NgbDateStruct;
      const weekday = moment(NgbDatepickerHelper.convertDateStructToDate(startDate)).day();
      if (weekday === moment(NgbDatepickerHelper.convertDateStructToDate(date)).day()) {
        return false;
      } else {
        return true;
      }
    } else {
      const daystring = this.cacheStartDateObj(date);
      return this.startDayObjCache[daystring].isDisabled;
    }
  }

  /**
   * @description prepare a startDate day object of ngb-datepicker
   * @author Alkesh Shah
   * @param {NgbDateStruct} date - current date
   * @returns
   * @memberof RangeComponent
   */
  prepareStartDayObj(date: NgbDateStruct) {
    const obj = {
      isToday: false,
      isDisabled: false,
      isWeekday: false
    };

    const selecteddaye: any = NgbDatepickerHelper.convertDateStructToDate(date);
    if (this.previousBricksDates.length > 0 && !this.uiControl.overlappingRange && !SystemFlags.isRangeSelectionMandatory) {
      const currentdate = this.datePipe.transform(selecteddaye, GLOBAL.DATE_PARSE_FORMAT);
      for (let i = 0; i <= this.previousBricksDates.length - 1; i++) {
        const dates = this.previousBricksDates[i];
        const startdate = this.datePipe.transform(dates.startDate, GLOBAL.DATE_PARSE_FORMAT);
        const enddate = this.datePipe.transform(dates.endDate, GLOBAL.DATE_PARSE_FORMAT);

        if ((currentdate <= enddate && currentdate >= startdate)) {
          obj.isDisabled = true;
        }
      }
    }

    const today = (new Date().setHours(0, 0, 0, 0));
    if (!obj.isDisabled && selecteddaye < today) {
      obj.isDisabled = true;
    }

    if (!obj.isDisabled) {
      const todayObj = new Date();
      obj.isToday = NgbDatepickerHelper.isEqual(todayObj, date);

      const selectedDay = NgbDatepickerHelper.convertDateStructToDate(date);

      let weekNo = selecteddaye.getWeek() + 1;
      const fullYear = selecteddaye.getFullYear();
      if (this.uiControl.inchargeContinuousFromLastYear
        && (!this.uiControl.inchargeExceptionYears || this.uiControl.inchargeExceptionYears.length === 0
          || this.uiControl.inchargeExceptionYears.indexOf(fullYear) === -1)) {

        let continuousFrom = GLOBAL.continuousFromDate;
        const excludedYear = this.uiControl.inchargeExceptionYears ? this.uiControl.inchargeExceptionYears.filter(f => f < fullYear).sort() : [];
        if (excludedYear.length) {
          const lastExcludedYear = excludedYear[excludedYear.length - 1];
          let lastMondayOfYear = new Date(lastExcludedYear, 11, 31);
          while (lastMondayOfYear.getDay() !== this.uiControl.defaultInChargeDay) {
            lastMondayOfYear = lastMondayOfYear.addDays(-1);
          }

          if ((lastMondayOfYear.getWeek() + 1) % this.uiControl.defaultInChargeLength !== 0) {
            lastMondayOfYear = lastMondayOfYear.addDays(-7);
          }

          continuousFrom = lastMondayOfYear;
        }
        weekNo = selecteddaye.getWeekFromDate(continuousFrom) + 1;
      }

      const condition = ((weekNo % this.uiControl.defaultInChargeLength === 0)
        && (selectedDay.getDay() === this.uiControl.defaultInChargeDay));
      obj.isWeekday = condition;
    }
    return obj;
  }

  /**
   * @description cache specific days object if is not exists
   * @author Alkesh Shah
   * @param {NgbDateStruct} date - current date object
   * @returns {string}
   * @memberof RangeComponent
   */
  cacheStartDateObj(date: NgbDateStruct): string {
    const daystring = NgbDatepickerHelper.getDateString(date);
    if (!this.startDayObjCache[daystring]) {
      this.startDayObjCache[daystring] = this.prepareStartDayObj(date);
    }
    return daystring;
  }

  /**
   * @description get the specific dates property
   * @author Alkesh Shah
   * @param {NgbDateStruct} date current day
   * @param {string} key property key to retrive
   * @returns {boolean}
   * @memberof RangeComponent
   */
  getStartDayProp(date: NgbDateStruct, key: string): boolean {
    const daystring = this.cacheStartDateObj(date);
    return this.startDayObjCache[daystring][key];
  }

  markEndDayDisabled = (date: NgbDateStruct): boolean => {
    const daystring = this.cacheEndDateObj(date, false);
    return this.endDayObjCache[daystring].isDisabled;
  }
  /**
   * @description prepare a startDate day object of ngb-datepicker
   * @author Alkesh Shah
   * @param {NgbDateStruct} date - current date
   * @returns
   * @memberof RangeComponent
   */
  prepareEndDayObj(date: NgbDateStruct, disabled: boolean) {
    const obj = {
      isToday: false,
      isDisabled: disabled,
      isWeekday: false
    };
    if (!obj.isDisabled) {
      const selecteddaye: any = NgbDatepickerHelper.convertDateStructToDate(date);
      obj.isDisabled = this.checkIsDisabled(selecteddaye);
      obj.isToday = NgbDatepickerHelper.isEqual(new Date(), date);
      obj.isWeekday = this.getCondition(selecteddaye);
    }
    if (!obj.isDisabled) {
      const today = new Date();
      obj.isToday = NgbDatepickerHelper.isEqual(today, date);

      const selecteddaye = NgbDatepickerHelper.convertDateStructToDate(date);

      let weekNo = selecteddaye.getWeek();

      const fullYear = selecteddaye.getFullYear();
      if (this.uiControl.inchargeContinuousFromLastYear
        && (!this.uiControl.inchargeExceptionYears || this.uiControl.inchargeExceptionYears.length === 0
          || this.uiControl.inchargeExceptionYears.indexOf(fullYear) !== -1 || this.uiControl.inchargeExceptionYears.indexOf(fullYear - 1) !== -1)) {
        const excludedYear = this.uiControl.inchargeExceptionYears ? this.uiControl.inchargeExceptionYears.filter(f => f <= fullYear).sort() : [];
        if (excludedYear.length) {
          const lastExcludedYear = fullYear - 1;
          const lastMondayOfYear: NgbDateStruct = { 'year': lastExcludedYear, 'month': 12, 'day': 31 };
          while (this.prepareStartDayObj(lastMondayOfYear).isWeekday) {
            lastMondayOfYear.day--;
          }

          let defaultInChargeLength = this.uiControl.defaultInChargeLength;
          if (NgbDatepickerHelper.convertDateStructToDate(lastMondayOfYear) > new Date(fullYear - 1, 11, 24)) {
            defaultInChargeLength = 1;
          }

          const lastYearEndDate = NgbDatepickerHelper.convertDateToDateStruct(new Date(lastMondayOfYear.year, lastMondayOfYear.month - 1, (lastMondayOfYear.day + ((7 * defaultInChargeLength) - 1))));

          weekNo = selecteddaye.getWeekFromDate(NgbDatepickerHelper.convertDateStructToDate(lastYearEndDate));
        }
      } else if (!this.uiControl.inchargeContinuousFromLastYear) {
        let startOfThisYear = new Date(selecteddaye.getFullYear(), 0, 1);
        while (startOfThisYear.getDay() !== this.uiControl.defaultInChargeDay) {
          startOfThisYear = startOfThisYear.addDays(1);
        }

        weekNo = selecteddaye.getWeekFromDate(startOfThisYear);
      }

      const condition = ((weekNo % this.uiControl.defaultInChargeLength === 0)
        && (selecteddaye.getDay() === (this.uiControl.defaultInChargeDay - 1)));

      obj.isWeekday = condition;
    }
    return obj;
  }

  /**
   * @description Get condition
   * @param selecteddaye Selected Days
   */
  getCondition(selecteddaye: any) {
    let weekNo = selecteddaye.getWeek();
    if (this.uiControl.inchargeContinuousFromLastYear && selecteddaye.getFullYear() > 2020) {
      weekNo = selecteddaye.getWeekFromDate(GLOBAL.continuousFromDate);
    }
    return ((weekNo % this.uiControl.defaultInChargeLength === 0)
      && (selecteddaye.getDay() === (this.uiControl.defaultInChargeDay - 1)));
  }

  /**
   * @description Check that is disabled
   * @param selecteddaye Selected Days
   */
  checkIsDisabled(selecteddaye: any) {
    let isDisabled = false;
    if (this.previousBricksDates.length > 0) {
      const currentdate = this.datePipe.transform(selecteddaye, GLOBAL.DATE_PARSE_FORMAT);
      if (this.previousBricksDates.length > 0 && !this.uiControl.overlappingRange && !SystemFlags.isRangeSelectionMandatory) {
        for (let i = 0; i <= this.previousBricksDates.length - 1; i++) {
          const dates = this.previousBricksDates[i];
          const startdate = this.datePipe.transform(dates.startDate, GLOBAL.DATE_PARSE_FORMAT);
          const enddate = this.datePipe.transform(dates.endDate, GLOBAL.DATE_PARSE_FORMAT);

          if ((currentdate <= enddate && currentdate >= startdate)) {
            isDisabled = true;
          }
        }
      }
    }
    const today = new Date().setHours(0, 0, 0, 0);

    if (!isDisabled && (selecteddaye < today)) {
      isDisabled = true;
    }
    return isDisabled;
  }

  /**
   * @description cache specific days object if is not exists
   * @author Alkesh Shah
   * @param {NgbDateStruct} date - current date object
   * @returns {string}
   * @memberof RangeComponent
   */
  cacheEndDateObj(date: NgbDateStruct, disabled: boolean): string {
    const daystring = NgbDatepickerHelper.getDateString(date);
    if (!this.endDayObjCache[daystring]) {
      this.endDayObjCache[daystring] = this.prepareEndDayObj(date, disabled);
    }
    return daystring;
  }

  /**
   * @description get the specific dates property
   * @author Alkesh Shah
   * @param {NgbDateStruct} date current day
   * @param {string} key property key to retrive
   * @returns {boolean}
   * @memberof RangeComponent
   */
  getEndDayProp(date: NgbDateStruct, disabled: boolean, key: string): boolean {
    const daystring = this.cacheEndDateObj(date, disabled);
    return this.endDayObjCache[daystring][key];
  }

  /**
   * callback to close modal popup window
   * @param {*} event - modal event object
   * @memberof RangeComponent
   */
  onModalClosed(event) {
    const defaultRangeModal = new RangeWindow();
    if (event.reason === 'escape' && JSON.stringify(this.rangeModal) !==  JSON.stringify(defaultRangeModal)) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * callback function - called during save click
   * @param {*} event - modal event object
   * @memberof RangeComponent
   */
  onModalSaved(event) {
    const values: CellValues = this.getModalCellValue();
    if (!values.selectedValues.startDate || !values.selectedValues.endDate) {
      this.logHelperService.logError(this.userBundle['workspace.error.selectDate'] || 'Please select start and end date');
      return;
    }

    if ((moment(values.selectedValues.endDate).diff(values.selectedValues.startDate, 'days') + 1) < this.getDays()) {
      if (this.pcmMode) {
        this.logHelperService.logError(this.userBundle['workspace.error.minValueHigherThanDateRange'] ?
          this.userBundle['workspace.error.minValueHigherThanDateRange'] :
          'Minimum range can not be higher than selected range');
      } else {
        this.logHelperService.logError(this.userBundle['workspace.error.invalidRange'] ?
          this.userBundle['workspace.error.invalidRange'] :
          'Selected date range is less then minimum range defined for added product');
      }
      return;
    }
    event.activeModal.close(values);
  }

  /**
   * @description To get days
   */
  getDays() {
    if (this.rangeModal.minValue) {
      if (this.rangeModal.minValueUnit === 0) {
        return this.rangeModal.minValue;
      } else {
        return this.rangeModal.minValue * 7
      }
    }
    return 0;
  }

  /**
   * @description Get Modal Cell Values
   */
  getModalCellValue() {
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    values.selectedValues = _.clone(this.rangeModal);
    values.selectedValues.startDate = NgbDatepickerHelper.convertDateStructToDate(this.rangeModal.startDate as NgbDateStruct);
    values.selectedValues.endDate = NgbDatepickerHelper.convertDateStructToDate(this.rangeModal.endDate as NgbDateStruct);
    values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Incharge, values.selectedValues);
    values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Incharge, values.selectedValues);
    return values;
  }

  /**
   * This method is responsible to get the nearest incharge day
   * eg: If inchange day is Monday then it will find nearest Monday.
   * @author Dhaval Patel
   * @returns Date Nearest Incharge Date
   * @memberof RangeComponent
   */
  private getNearestInchargeDay(): Date {
    const weekday = moment(this.rangeModal.startDate).day();
    let nextMonday = new Date();
    if (nextMonday.getDay() !== weekday) {
      nextMonday = moment().day(weekday + 7).toDate();
    }
    return nextMonday;
  }

  trackByDay(index, item) {
    return item?.dayId;
  }
}

// Inbuild getWeek function gives wrong week no for any week after leap year (like for 6th Jan 25, gives week2)
Date.prototype.getWeek = function () {
  const onejan: any = new Date(this.getFullYear(), 0, 1);
  const today: any = new Date(this.getFullYear(), this.getMonth(), this.getDate());
  const dayOfYear: any = ((today - onejan + 86400000) / 86400000);
  return Math.ceil(dayOfYear / 7);
};
