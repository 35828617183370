import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getFormData } from '../../core/utils/formData';
import { GLOBAL } from '../../core/utils/app.constant';
import { DataShareService } from '../../core/services';

/**
 * @export ProximityFilterService
 * @class ProximityFilterService
 */
@Injectable()
export class ProximityFilterService {

  readonly URL_ENCODED = 'application/x-www-form-urlencoded';

  readonly contentTypeKey: string = 'Content-Type';
  headers = new HttpHeaders({
    [this.contentTypeKey]: this.URL_ENCODED
  });

  /**
   * @description Creates an instance of ProximityFilterService.
   * @param {DataShareService} dataShareService
   * @param {Http} http
   * @memberof ProximityFilterService
   */
  constructor(
    private http: HttpClient,
    private dataShareService: DataShareService
  ) {
  }

  /**
   * @description Get data for point of intereset parameters
   * @param {any} data
   * @returns
   * @memberof ProximityFilterService
   */
  getPointOfInterest(data) {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
      action: 'lookupPoi',
      data: JSON.stringify(data),
    };
    const requestOptions = {
      headers: this.headers,
      body: getFormData(param)
    };
    return this.http.request(GLOBAL.HTTP_METHOD, this.dataShareService.getServiceCallUrlByKey('PROXIMITY_POINT_OF_INTEREST_URL'), requestOptions);
  }

  /**
   * @description Get list of available postcodes from the server
   * @param {any} data
   * @returns
   * @memberof ProximityFilterService
   */
  getPostcode(data) {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
      action: 'lookupPostcode',
      data: JSON.stringify(data),
    };

    const requestOptions = {
      headers: this.headers,
      body: getFormData(param),
    };
    return this.http.request(GLOBAL.HTTP_METHOD, this.dataShareService.getServiceCallUrlByKey('PROXIMITY_POSTCODE_URL'), requestOptions);
  }

  /**
   * @description Upload file with proximity data to the server
   * @param {any} file
   * @param {any} proximityObj
   * @param {any} userSelectionId
   * @returns
   * @memberof ProximityFilterService
   */
  getProximityBrickResponse(file, proximityObj, userSelectionId) {
    const fd = new FormData();
    fd.append('file', file);
    fd.append('bricsCampaignId', String(GLOBAL.BRIC_CAMPAIGN_ID));
    fd.append('action', proximityObj.action);
    delete proximityObj.action;
    fd.append('proximity', JSON.stringify(proximityObj));
    if (userSelectionId) {
      fd.append('userSelectionId', userSelectionId);
    }
    let headers = new HttpHeaders({
      [this.contentTypeKey]: ''
    });
    headers = headers.delete(this.contentTypeKey);
    const requestOptions = {
      headers,
      body: fd,
    };

    return this.http.request(GLOBAL.HTTP_METHOD, this.dataShareService.getServiceCallUrlByKey('FILE_UPLOAD_PROXIMITY_URL'), requestOptions);
  }

  /**
   * @description Submit postcodes withoud file to server
   * @param {any} proximityObj
   * @param {any} userSelectionId
   * @returns
   * @memberof ProximityFilterService
   */
  getProximityBrickResponseWithoutFile(proximityObj, userSelectionId) {
    const param: any = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
      proximity: '',
      action: proximityObj.action
    };
    delete proximityObj.action;
    param.proximity = JSON.stringify(proximityObj);

    if (userSelectionId && userSelectionId > 0) {
      param.userSelectionId = userSelectionId;
    }

    const requestOptions = {
      headers: this.headers,
      body: getFormData(param),
    };

    return this.http.request(GLOBAL.HTTP_METHOD, this.dataShareService.getServiceCallUrlByKey('FILE_UPLOAD_PROXIMITY_URL'), requestOptions)
      .map((data: any) => {
        if (data.status === 'KO') {
          return data;
        } else {
          const response: any = data;
          delete response.data.validCount;
          delete response.data.totalCount;
          delete response.data.userSelectionName;
          return response;
        }
      });
  }

  getPointOfInterestTreeData() {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
      action: 'getPoiAttributeData',
      data: JSON.stringify({}),
    };

    const requestOptions = {
      headers: this.headers,
      body: getFormData(param),
    };
    return this.http.request(GLOBAL.HTTP_METHOD, this.dataShareService.getServiceCallUrlByKey('PROXIMITY_POI_ATTRIBUTE_URL'), requestOptions);
  }
}
