import {
  Pipe,
  PipeTransform,
  OnDestroy,
  WrappedValue,
  ChangeDetectorRef
} from '@angular/core';

import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/observable/of';

import { DomSanitizer } from '@angular/platform-browser';

import { UrlHelperService } from './../services/url-helper-service';

// Using similarity from AsyncPipe to avoid having to pipe |secure|async in HTML.
@Pipe({
  name: 'secure',
  pure: false
})
export class SecurePipe implements PipeTransform, OnDestroy {
  private latestValue: any = null;
  private latestReturnedValue: any = null;
  private subscription: Subscription = null;
  private obj: Observable<any> = null;

  private previousUrl: string;
  private resultBehavior: BehaviorSubject<any> = new BehaviorSubject(null);
  private result: Observable<any> = this.resultBehavior.asObservable();
  private internalSubscription: Subscription = null;

  constructor(
    private ref: ChangeDetectorRef,
    private urlHelperService: UrlHelperService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.disposeMe();
    }
  }

  transform(url: string): any {
    const obj = this.internalTransform(url);
    return this.asyncTrasnform(obj);
  }

  private internalTransform(url: string): Observable<any> {
    if (!url) {
      return this.result;
    }

    if (this.previousUrl !== url) {
      this.previousUrl = url;
      this.internalSubscription = this.urlHelperService.get(url).subscribe((m) => {
        const sanitized = this.sanitizer.bypassSecurityTrustUrl(m);
        this.resultBehavior.next(sanitized);
      });
    }

    return this.result;
  }

  private asyncTrasnform(obj: Observable<any>): any {
    if (!this.obj) {
      if (obj) {
        this.subscribeMe(obj);
      }
      this.latestReturnedValue = this.latestValue;
      return this.latestValue;
    }
    if (obj !== this.obj) {
      this.disposeMe();
      return this.asyncTrasnform(obj);
    }
    if (this.latestValue === this.latestReturnedValue) {
      return this.latestReturnedValue;
    }
    this.latestReturnedValue = this.latestValue;
    return WrappedValue.wrap(this.latestValue);
  }

  private subscribeMe(obj: Observable<any>) {
    this.obj = obj;

    this.subscription = obj.subscribe({
      next: (value) => {
        return this.updateLatestValue(obj, value);
      }, error: (e: any) => { throw e; }
    });
  }

  private disposeMe() {
    this.subscription.unsubscribe();
    this.internalSubscription.unsubscribe();
    this.internalSubscription = null;
    this.latestValue = null;
    this.latestReturnedValue = null;
    this.subscription = null;
    this.obj = null;
  }

  private updateLatestValue(async: any, value: Object) {
    if (async === this.obj) {
      this.latestValue = value;
      this.ref.markForCheck();
    }
  }
}
