import { FurnitureModel } from '../models';

export enum SVGIconType {
  rectangle = 0,
  ellipse = 1
}

export const svgIconSettings = {
  defaultOptions: {
    width: 18,
    height: 18,
    iconOrientation: 0
  },
  extraSpaceForContainer: 3
};

export class MapSvgIcon {

  constructor() { }

  getIcon(asset: FurnitureModel, options, isSelected: boolean, svg, zoom: number, isForLegend) {
    try {
      const svgElement: HTMLElement = svg.childNodes[0].cloneNode(true) as HTMLElement;
      let dynamicWidth = asset.width;
      let dynamicHeight = asset.height;
      if (!isForLegend && zoom && !isNaN(zoom)) {
        const ratio = zoom / 20;
        dynamicWidth = ((zoom * asset.width) / 13) * ratio;
        dynamicHeight = ((zoom * asset.height) / 13) * ratio;
      }
      svgElement.setAttribute('width', `${dynamicWidth}px`);
      svgElement.setAttribute('height', `${dynamicHeight}px`);
      svgElement.style.fill = options.fill;
      // tslint:disable-next-line: no-string-literal
      svgElement.children[0]['style'].fill = options.fill;
      if (!isForLegend && isSelected) {
        svgElement.setAttribute('width', `${dynamicWidth + svgIconSettings.extraSpaceForContainer}px`);
        svgElement.setAttribute('height', `${dynamicHeight + svgIconSettings.extraSpaceForContainer}px`);
      }
      if (asset.iconOrientation > 0) {
        svgElement.setAttribute('transform', `rotate(${asset.iconOrientation})`);
      }

      if (!isForLegend && isSelected) {
        this.appendSelectedSvg(svgElement, asset);
      }
      const serializedSVG = new XMLSerializer().serializeToString((svgElement));

      return `data:image/svg+xml;base64, ${window.btoa(serializedSVG)}`;
    } catch (error) {
      return '';
    }
  }

  getDotIcon() {
    const svgElement: HTMLElement = document.getElementById('svgCircleShape').cloneNode(true) as HTMLElement;
    const options = {
      width: 10,
      height: 10
    };
    svgElement.style.width = `${options.width}px`;
    svgElement.style.height = `${options.height}px`;
    this.renderEllipse(svgElement, options);
    const serializedSVG = new XMLSerializer().serializeToString((svgElement));
    return `data:image/svg+xml;base64, ${window.btoa(serializedSVG)}`;
  }

  fetchSVG(iconImage: string) {
    const domParser = new DOMParser();
    return domParser.parseFromString(iconImage, 'image/svg+xml');
  }

  public appendSelectedSvg(svgElement: HTMLElement, asset: FurnitureModel): void {
    const box = svgElement.getAttribute('viewBox');
    if (box) {
      const splitBox = box.split(/\s+|,/);
      if (splitBox && splitBox[2] && Number(splitBox[2]) > 50) {
        this.handleMoreThan50ViewBox(svgElement, asset);
      } else {
        this.handleLessThan50ViewBox(svgElement, asset);
      }
    } else {
      this.handleLessThan50ViewBox(svgElement, asset);
    }
  }

  private handleMoreThan50ViewBox(svgElement: HTMLElement, asset: FurnitureModel) {
    const selectedShape = document.getElementById('svgShapeSelected').cloneNode(true) as HTMLElement;
    const pathElement = selectedShape.getElementsByTagName('path');
    if (asset.iconOrientation > 0 && pathElement && pathElement[0]) {
      pathElement[0].setAttribute('transform', `rotate(-${asset.iconOrientation} 6 6)`);
    }
    svgElement.appendChild(selectedShape.cloneNode(true));
  }

  // tslint:disable-next-line: no-identical-functions
  private handleLessThan50ViewBox(svgElement: HTMLElement, asset: FurnitureModel) {
    const selectedShape = document.getElementById('svgShapeOtherSelected').cloneNode(true) as HTMLElement;
    const pathElement = selectedShape.getElementsByTagName('path');
    if (asset.iconOrientation > 0 && pathElement && pathElement[0]) {
      pathElement[0].setAttribute('transform', `rotate(-${asset.iconOrientation} 6 6)`);
    }
    svgElement.appendChild(selectedShape.cloneNode(true));
  }

  public renderSvg(svgElement: any, params: any, asset: FurnitureModel, isSelected: boolean, isForLegend: boolean, dynamicWidth: number, dynamicHeight: number): void {
    if (!isForLegend && isSelected) {
      const selectedShape = document.getElementById('svgShapeSelected').cloneNode(true) as HTMLElement;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < selectedShape.children.length; i++) {
        svgElement.appendChild(selectedShape.children[i].cloneNode(true));
      }
    }

    const rectElement = svgElement.getElementsByTagName('rect')[0];
    if (rectElement) {
      rectElement.setAttribute('x', !isForLegend && isSelected ? svgIconSettings.extraSpaceForContainer : 0);
      rectElement.setAttribute('y', !isForLegend && isSelected ? svgIconSettings.extraSpaceForContainer : 0);

      for (const key in params) {
        if (asset.hasOwnProperty(key)) {
          if (key === 'width') {
            rectElement.style.width = dynamicWidth;
          } else if (key === 'height') {
            rectElement.style.height = dynamicHeight;
          } else if (key === 'iconOrientation') {
            rectElement.style.transform = `rotate(${asset[key]}deg)`;
          } else {
            rectElement.style[key] = asset[key];
          }
        }
      }
    }
    const ellipseElement = svgElement.getElementsByTagName('ellipse')[0];
    if (ellipseElement) {
      for (const key in params) {
        if (asset.hasOwnProperty(key)) {
          switch (key) {
            case 'width':
              ellipseElement.setAttribute('rx', dynamicWidth / 2);
              ellipseElement.setAttribute('cx', ((dynamicWidth / 2) + (isSelected ? svgIconSettings.extraSpaceForContainer : 0)));
              break;
            case 'height':
              ellipseElement.setAttribute('ry', dynamicHeight / 2);
              ellipseElement.setAttribute('cy', ((dynamicHeight / 2) + (isSelected ? svgIconSettings.extraSpaceForContainer : 0)));
              break;
            case 'iconOrientation':
              ellipseElement.style.transform = `rotate(${asset[key]} deg)`;
              break;
            default:
              ellipseElement.style[key] = asset[key];
          }
        }
      }
    }
  }

  renderEllipse(svgElement: any, params: any): void {
    svgElement.getElementsByTagName('rect')[0].remove();

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = svgElement.getElementsByTagName('ellipse')[0];
        switch (key) {
          case 'width':
            element.setAttribute('rx', params[key] / 2);
            element.setAttribute('cx', params[key] / 2);
            break;
          case 'height':
            element.setAttribute('ry', params[key] / 2);
            element.setAttribute('cy', params[key] / 2);
            break;
          case 'rotation':
            element.style.transform = `rotate(${params[key]} deg)`;
            break;
          default:
            element.style[key] = params[key];
        }
      }
    }
  }

  async urlToString(url) {
    const req = await fetch(url, { mode: 'cors' });
    return await req.text();
  }

  parseSVG(svgString) {
    const parser = new DOMParser();
    return parser.parseFromString(svgString, 'image/svg+xml');
  }

  replaceColors(svgString, color) {
    // we can do some RegExp magic here
    // but I will just manually check every element
    const doc = this.parseSVG(svgString);
    const element : any = doc.getElementsByTagName('svg');
    if ((element && element.length) && color) {
      element[0].attributes['fill'].value = color;
    }
    // serialize DOM back into string
    const xmlSerializer = new XMLSerializer();
    return xmlSerializer.serializeToString(doc);
  }
}
