/* tslint:disable:no-duplicate-string */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataShareService } from './../../core/services/data-share.service';
import { GLOBAL } from '../../core/utils/app.constant';
import { _throw } from 'rxjs/observable/throw';
/**
 * @export
 * @class ListFilterService
 */
@Injectable()
export class ListFilterService {
  readonly CONTENT_TYPE = 'Content-Type';
  /**
   * Creates an instance of ListFilterService.
   * @param {DataShareService} dataShareService
   * @param {HttpClient} http
   * @memberof ListFilterService
   */
  constructor(
    private dataShareService: DataShareService,
    private http: HttpClient
  ) { }

  /**
   * send file upload request
   * @param {any} file - file to send
   * @param {any} include - include list
   * @param {any} routeFrameIds - frameIds
   * @param {string} [url] - Request url
   * @returns
   * @memberof ListFilterService
   */
  upload(file, exclude, routeFrameIds, url?: string) {
    const fd = new FormData();
    if (file != null) {
      fd.append('file', file);
    } else if (routeFrameIds != null && routeFrameIds.length > 0) {
      fd.append('routeFrameId', routeFrameIds);
    }
    if (exclude != null && exclude !== undefined) {
      fd.append('include', String(!exclude));
    }
    fd.append('bricsCampaignId', String(GLOBAL.BRIC_CAMPAIGN_ID));
    fd.append('action', 'panel');

    let headers = new HttpHeaders({
      'Content-Type': ''
    });
    headers = headers.delete(this.CONTENT_TYPE);
    const requestOptions = {
      headers,
      body: fd
    };
    const reqUrl = this.dataShareService.getServiceCallUrlByKey('FILE_UPLOAD_URL') || url;
    return this.http.request(GLOBAL.HTTP_METHOD, reqUrl, requestOptions)
      .toPromise<any>()
      .catch(error => _throw(error));
  }

  /**
   * @description downloads an already uploaded file
   * @author Amit Mahida
   * @param {*} userSelectionId
   * @returns
   * @memberof ListFilterService
   */
  downloadFile(userSelectionId) {
    const fd = new FormData();
    fd.append('bricsCampaignId', String(GLOBAL.BRIC_CAMPAIGN_ID));
    fd.append('Bos-SessionId', String(GLOBAL.BOS_SESSIONID));
    fd.append('action', 'downloadUserSelection');
    fd.append('userSelectionId', userSelectionId);
    let headers = new HttpHeaders({
      'Content-Type': ''
    });
    headers = headers.delete(this.CONTENT_TYPE);
    const requestOptions = {
      headers,
      body: fd,
      observe: 'response' as 'body',
      // observe: 'events',
      responseType: 'blob' as 'blob'
    };
    const reqUrl = this.dataShareService.getServiceCallUrlByKey('FILE_DOWNLOAD_URL');
    return this.http.request(GLOBAL.HTTP_METHOD, reqUrl, requestOptions)
      .toPromise<any>()
      .catch(error => _throw(error));
  }
}
