import { Component } from '@angular/core';
import { AgCustomRowUtil } from '../../utils/ag-custom-row-util';
import { CellRendererBaseComponent } from '../cell-renderer-base/cell-renderer-base.component';
import { DecimalPipe } from '@angular/common';
import { LocaleData } from '../../../../../core/utils/LocaleData';

@Component({
  selector: 'app-ag-percentage-cell-renderer',
  templateUrl: './ag-percentage-cell-renderer.component.html',
  styleUrls: ['./ag-percentage-cell-renderer.component.css']
})
export class AgPercentageCellRendererComponent extends CellRendererBaseComponent {

  constructor(private decimalPipe: DecimalPipe) {
    super();
  }

  public getParentRowText(): string {
    if (this.groupingField) {
      const rowData = AgCustomRowUtil.getRowData(this.params.api);
      const childRows = AgCustomRowUtil.getChildRows(rowData, this.groupingField, this.params.data[this.groupingField]);
      let sum = 0;
      const calcCellValue = this.params.colDef.cellRendererParams.calcCellValue;
      if (calcCellValue) {
        sum = calcCellValue(childRows, this.params.column.colId);
      }
      this.params.data[this.params.column.colId] = sum;
      return String(sum);
    } else {
      return this.params.data[this.params.column.colId];
    }
  }

  public getChildRowText(): string {
    if (this.params.data[this.params.column.colId]) {
      if (typeof this.params.data[this.params.column.colId] === 'string' && this.params.allowReplaceDecimalSeparator) {
        this.params.data[this.params.column.colId] = LocaleData.replaceLocalDecimalCharWithDot(this.params.data[this.params.column.colId]);
      } else {
        this.params.data[this.params.column.colId] = parseFloat(this.params.data[this.params.column.colId]);
      }
    } else {
      this.params.data[this.params.column.colId] = 0;
    }
    return this.params.data[this.params.column.colId];
  }

  public getFirstPinRowText(): string {
    if (this.params.gridSumRow && this.params.gridSumRow === 1) {
      return this.calcGridTotal();
    } else {
      if (this.params.data[this.params.column.colId] === 0 || (this.params.data[this.params.column.colId]
        && this.params.data[this.params.column.colId] !== '')) {
        if (typeof this.params.data[this.params.column.colId] === 'string' && this.params.allowReplaceDecimalSeparator) {
          this.params.data[this.params.column.colId] = LocaleData.replaceLocalDecimalCharWithDot(this.params.data[this.params.column.colId]);
        } else {
          this.params.data[this.params.column.colId] = parseFloat(this.params.data[this.params.column.colId]);
        }
      } else {
        this.params.data[this.params.column.colId] = '';
      }
      return this.params.data[this.params.column.colId];
    }
  }

  public getSecondPinRowText(): string {
    return this.calcGridTotal();
  }

  public formatText(displayText): string {
    let disText = displayText;
    if (disText !== 0) {
      disText = disText || '';
    }
    if (disText !== '') {
      let template = '.2-2';
      if (this.params.decimalPoints) {
        template = `.${this.params.decimalPoints}-${this.params.decimalPoints}`;
      }

      disText = `${this.decimalPipe.transform(parseFloat(disText), template)}% `;
      if (this.params.prefixText) {
        disText = `${this.params.prefixText(this.params.data)} ${disText}`;
      }
    }
    return disText;
  }

  private calcGridTotal(): string {
    const rowData = AgCustomRowUtil.getRowData(this.params.api);
    const childRows = AgCustomRowUtil.getChildRows(rowData);
    let sum = 0;
    if (this.params.calcCellValue) {
      sum = this.params.calcCellValue(childRows, this.params.column.colId);
    }
    return String(sum);
  }

}
