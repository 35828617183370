<div class="sidebar-left_geo sidebar-right_geo cart_summarybar"
  [ngStyle]="{'right': openSidebar ? '0px' : '-'+(swapZoneWidth) +'px', 'width': swapZoneWidth +'px'}">
  <div class="sidebar-wrapper_geo">
    <button class="filter-link_geo">
      {{initialConfig.userBundle['vp.swap.text.swapZone'] || 'Swap Zone'}}
      <span class="pull-right">
        <em class="fa fa-close" (click)="toggleSideBar()"></em>
      </span>
    </button>
    <!-- <div class="cart_search_container">
      <input type="text" [(ngModel)]="bookingFilterText" (ngModelChange)="searchForCampaign($event)" id="bookingFilterText"
        name="bookingFilterText" class="form-control" placeholder="Search Campaign...">
    </div> -->
    <div class="cart_grid_container">
      <div *ngIf="swapZoneService.swaps.length">
        <div *ngFor="let swap of swapZoneService.swaps; trackBy: trackById; let i = index" class="swap-card"
          [ngClass]="{selected: swap.selected}">
          <div class="row right" *ngIf="swap?.frame == undefined || swap?.frame.length === 0">
            <em class="fa fa-times" aria-hidden="true" (click)="revertSwap(swap, i)"></em>
          </div>
          <div class="row" style="margin-bottom: 10px;">
            <div class="col-md-7" (click)="selectSwapToDelete(swap)">
              <!-- <p style="display: flex;" > -->
              <label class="small" style="cursor: pointer;">
                <input [disabled]="swap.swapDone" [id]="swap.frameId" [name]="swap.frameId" [(ngModel)]="swap.selected"
                  type="checkbox" class="checkbox small" />
                <span
                  class="campaign-ref-label">{{initialConfig.userBundle['vp.swap.text.campaignReference'] || 'Campaign Reference'}}</span>
              </label>
              <!-- </p> -->
            </div>
            <div class="col-md-3">
              <label class="small text-secondary">{{initialConfig.userBundle['vp.swap.text.date'] || 'Date'}} </label>
            </div>
            <div class="col-md-2">
              <label class="small text-secondary">{{initialConfig.userBundle['vp.swap.text.time'] || 'Time'}} </label>
            </div>
          </div>
          <div class="row" *ngFor="let campaignRef of swap.campaignReferences; trackBy: trackByItem;">
            <div class="col-md-7">
              <label class="text-secondary"> {{campaignRef.campaignReference}}
                <span *ngIf="campaignRef.sot">(SOT {{campaignRef.sot | number:'.2-2'}} %)</span>
                <span *ngIf="campaignRef.addedFromGroupId" (click)="editDigitalSwap(swap, campaignRef.addedFromGroupId)"
                  [title]="initialConfig.userBundle['vp.swap.updateBookingFromGroup']">
                  <em class="fa fa-edit"></em>
                </span>
              </label>
            </div>
            <div class="col-md-3">
              <p>{{campaignRef.startDate | date:displayDateFormat}}<br>
                {{campaignRef.endDate | date:displayDateFormat}}</p>
            </div>
            <div class="col-md-2">
              <p>{{campaignRef.startDate | date:displayTimeFormat}}<br>
                {{campaignRef.endDate | date:displayTimeFormat}}</p>
            </div>
          </div>
          <ng-container
            *ngIf="swap.bookingDetailGroups && (!selectedSwapWiseGroups[swap.id] || selectedSwapWiseGroups[swap.id].length < swap.bookingDetailGroups.length)">
            <div class="row" *ngFor="let group of swap.bookingDetailGroups; trackBy: trackByItem;">
              <ng-container *ngIf="!group.selected">
                <div class="col-md-7">
                  <label class="text-secondary" (click)="openDigitalSwap(swap, group)"
                    style="color: #26247b; cursor: pointer;" [ngClass]="{red: !sotMatching(group, swap)}">
                    Please Select Booking <br>
                    (min. SOT {{getMinSOT(group.bookingDetails) | number : '2.2-2'}} %)
                  </label>
                </div>
                <div class="col-md-3">
                  <p>{{getMinStartDate(group.bookingDetails) | date:displayDateFormat}}<br>
                    {{getMaxEndDate(group.bookingDetails) | date:displayDateFormat}}</p>
                </div>
                <div class="col-md-2">
                  <p>{{getMinStartDate(group.bookingDetails) | date:displayTimeFormat}}<br>
                    {{getMaxEndDate(group.bookingDetails) | date:displayTimeFormat}}</p>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <label class="text-secondary">{{initialConfig.userBundle['vp.swap.text.swapFrame'] || 'Swap Frame'}}</label>
          <ng-container *ngIf="swap.frames && swap.frames.length">
            <div class="row" *ngFor="let frame of swap.frames; trackBy: trackByFrame; let frameIndex = index" style="margin-top: 10px;">
              <div class="col-md-4">
                <p style="user-select:text">{{frame.frameCode}}
                  <span *ngIf="frame.targetSOT">
                    ({{frame.targetSOT | number : '2.2-2'}} %)
                  </span>
                </p>
              </div>
              <div class="col-md-2 text-center">
                <p>{{initialConfig.userBundle['vp.swap.text.to'] || 'to'}}</p>
              </div>
              <div class="col-md-6"
                [hidden]="!swap.campaignReferences.length || (swap.bookingDetailGroups && swap.bookingDetailGroups.length !== swap.campaignReferences.length)">
                <tag-input [inputId]="'frameToSwap' + frame.frameCode" [clearOnBlur]="true" [maxItems]="1"
                  (onAdd)="updateNewFrameId($event, swap, frameIndex)"
                  (onRemove)="swapZoneService.revertSwap(swap, i, frameIndex)" [(ngModel)]="frame.newFrame"
                  [placeholder]="'Select Frame'" [secondaryPlaceholder]="'Select Frame'" [inputClass]="'form-control'"
                  [onlyFromAutocomplete]="true" (onFocus)="filterFrameList(swap, frame)">
                  <tag-input-dropdown [showDropdownIfEmpty]="false" [appendToBody]="true" [inputClass]="'form-control'"
                    [identifyBy]="'frameId'" [displayBy]="'frameCode'" [matchingFn]="frameListMatchingFn"
                    [autocompleteObservable]="loadFrameList" [minimumTextLength]="3" [textChangeDebounce]="400">
                    <ng-template let-item="item" let-index="frameCode">
                      <span *ngIf="item.isParent" style="font-weight: bold; font-size: larger;">
                        {{ item.display }}
                      </span>
                      <span *ngIf="!item.isParent"> {{ item.frameCode }} </span>
                    </ng-template>
                  </tag-input-dropdown>
                </tag-input>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="swapZoneService.swaps.length > 0 && swapZoneService.showUpdateButton">
        <button id="reset-selection-button" (click)="performSwap()" class="btn btn-primary"
          title="{{initialConfig.userBundle['vp.swap.text.performSwap'] || 'Perform Swap'}}">
          {{initialConfig.userBundle['vp.swap.text.update'] || 'Update'}}
        </button>
      </div>
    </div>

    <div class="selected_cart_items">
      <span>{{swapZoneService.getSelectedSwapsCount()}}/ {{swapZoneService.swaps.length}}
        {{initialConfig.userBundle['geoplanner.text.selected'] ||
        'Selected'}}
      </span>
      <button (click)="swapZoneService.deleteSwaps()" class="btn white pull-right">
        <em class="fa fa-trash"></em>
      </button>
      <label class="small pull-right">
        <input [(ngModel)]="swapZoneService.allSwapsSelected" (change)="swapZoneService.onSelectAllSwaps()"
          type="checkbox" class="checkbox small" id="swapSelectAll" name="swapSelectAll">
        <span> {{initialConfig.userBundle['geoplanner.text.all'] || 'All'}}</span>
      </label>
    </div>
  </div>
</div>
