<app-sb-modal-wrapper [title]="freeOfCharge.title" [titleBackgroundColor]="brickBgColor" (closed)="onModalClosed($event)"
  (saved)="onModalSaved($event)">
  <div class="modal-body">
      <div class="control_group">
        <label>{{freeOfCharge.labletext}} :</label>
        <select class="form_control chzn-container" id="auditReasonName" [(ngModel)]="freeOfChargeSelectedValue"
          (change)="otherReason(freeOfChargeSelectedValue)" name="auditReasonName">
          <option *ngFor="let item of reasonFreeDropdown; trackBy: trackByItem;" [ngValue]="item">{{item.auditReasonName}}</option>
        </select>
      </div>
      <div *ngIf="showHideText" class="control_group">
        <label>Reason :</label> <input type="text" class="form_control" [(ngModel)]="campaign.freeText" maxlength="200" />
      </div>
  </div>
</app-sb-modal-wrapper>
