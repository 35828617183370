import { Component, OnInit, Input } from '@angular/core';
import {
  DataShareService,
  SharedService,
  BrickBaseService,
  LogHelperService,
  CellAttributeService,
  StateService
} from '../../../core/services/index';
import { CellValues } from '../../../models/workspace/index';
import { FormatBase } from '../format-base';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';
import { ObjectiveService } from '../../../workspace/objective/objective.service';
import { GLOBAL } from '../../../core/utils/app.constant';

@Component({
  selector: 'app-format-modal',
  templateUrl: './format-modal.component.html'
})
export class FormatModalComponent extends FormatBase implements OnInit {

  /**
   * @description It will take data to configure modal window.
   * @type {Brick}
   * @memberof FormatModalComponent
   */
  @Input() resolveObject: CellValues;
  readOnlyModal = false;
  localSolverEnabled: boolean;
  objectiveMode: boolean;

  /**
   * Creates an instance of FormatModalComponent.
   * @author Alkesh Shah
   * @param {DataShareService} dataService
   * @param {SharedService} sharedService
   * @param {BrickBaseService} brickBaseService
   * @param {LogHelperService} logHelperService
   * @memberof FormatModalComponent
   */
  constructor(
    private logHelperService: LogHelperService,
    dataService: DataShareService,
    sharedService: SharedService,
    brickBaseService: BrickBaseService,
    private cellAttributeService: CellAttributeService,
    public appHeaderService: AppHeaderService,
    objectiveService: ObjectiveService,
    stateService: StateService
  ) {
    super(dataService, sharedService, brickBaseService, appHeaderService, objectiveService, stateService);
    this.objectiveMode = this.appHeaderService.objectiveMode;
  }

  /**
   * @description angular life cycle hook - called on component init
   * @author Alkesh Shah
   * @memberof FormatModalComponent
   */
  ngOnInit() {
    const selectedValues = this.resolveObject.selectedValues && !this.resolveObject.selectedValues.hasOwnProperty('-99')
      ? this.resolveObject.selectedValues : {};
    this.init(this.resolveObject.columnConfig, selectedValues);
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.localSolverEnabled = this.resolveObject.reshufflingEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_LS || this.resolveObject.reshufflingEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH || this.objectiveMode;
  }

  /**
   * @description callback to close modal popup window
   * @author Alkesh Shah
   * @param {*} event
   * @memberof FormatModalComponent
   */
  onModalClosed(event) {
    const isSelected = this.isValidSelection();
    if (event.reason === 'escape' && isSelected) {
      if (window.confirm(this.initialConfig['userBundle']['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * @description callback function - called during save click
   * @author Alkesh Shah
   * @param {*} event
   * @memberof FormatModalComponent
   */
  onModalSaved(event) {
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    if (this.isValidSelection()) {
      values.selectedValues = this.selected;
      values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Format, values.selectedValues);
      values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Format, values.selectedValues);
      values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Format,
        values.selectedValues, this.lookups.lookup);
      event.activeModal.close(values);
    } else {
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.noDataSelect']);
    }
  }

}
