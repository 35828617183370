import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, CommercialConfig, HistoryROC } from '../../commercial.model';
import { DataShareService } from '../../../core/services/data-share.service';
import { SystemFlags } from '../../../models';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { StateService, MaskService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { CampaignDetailsBase } from '../campaign-details-base';
import { NumberMaskModel } from '../../../models/imask';
import { CampaignType, CampaignGroupTypeList } from '../../../core/enum';
import { Campaign } from '../../../models/campaign';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-campaign-details-germany',
  templateUrl: './campaign-details-germany.component.html',
  styleUrls: ['./campaign-details-germany.component.css']
})
export class CampaignDetailsGermanyComponent extends CampaignDetailsBase implements OnInit {
  @Input() campaignDetails: CampaignDetails;
  @Input() commercialConfig: CommercialConfig = new CommercialConfig();
  @Input() historyROC: HistoryROC;
  @Output() manuallyCPTUpdated: EventEmitter<any> = new EventEmitter();

  maskService: MaskService = new MaskService();

  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof CampaignDetailsGermanyComponent
   */
  currencyInputMask: NumberMaskModel;

  /**
   * @description set readOnly
   * @memberof CampaignDetailsGermanyComponent
   */
  public readOnly = SystemFlags.readOnly;

  /**
   * @description Camapgin Type used in .html file
   * @memberof CampaignDetailsGermanyComponent
   */
  campaignType = CampaignType;

  percentageInputMask: NumberMaskModel;

  /**
   * @description Search page object
   * @type {Campaign}
   * @memberof CampaignDetailsGermanyComponent
   */
  searchPageObject: Campaign;

  /**
   * @description Data Subscription
   * @type {Subscription}
   * @memberof CampaignDetailsGermanyComponent
   */
  bookingLineDataSubscription: Subscription;

  campaignGroupTypeList = CampaignGroupTypeList;

  constructor(
    dataShareService: DataShareService,
    campaignDetailsService: CampaignDetailsService,
    lookupViewService: LookupViewService,
    commercialService: CommercialService,
    stateService: StateService
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService
    );
    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe((value: any) => {
      if (value.rowIndex >= 0 && value.columnName) {
        this.calculateCPT(value);
      }
      this.onBookingLineChange();
    });
  }

  getBrandsResponse(value: any): void {
    super.getBrandsResponse(value);
    this.commercialService.notifyGrid({
      brandData: value.brand
    });
  }

  onAdvertiserNameSelectedGermany(value: any) {
    let clearBrands = false;
    if (this.campaignDetails.advertiser.advertiserId !== value.advertiserId) {
      clearBrands = true;
    }
    this.commercialService.notifyGrid({
      clearBrands,
      brandData: value.source.brands
    });
    this.onAdvertiserNameSelected(value);
  }

  ngOnInit(): void {
    this.currencyInputMask = this.maskService.currencyInputMask({ max: 999999999999999999999 });
    this.percentageInputMask = this.maskService.currencyInputMask({ max: 100 });
    this.init(this.campaignDetails);
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    this.enableSubAgency();
    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);
    this.searchPageObject = this.stateService.getCampaign();
    if (this.campaignDetails.calculateAtUI) {
      this.manageGrossMediaValueWithoutIndex();
    }

    this.setLookupConfig();
  }

  /**
   * @description - Callback method call on specialist name changed
   * @author Shivani Patel
   * @param {*} value
   * @memberof CampaignDetailsGermanyComponent
   */
  onSpecialistNameSelected(value: any): void {
    if (this.campaignDetails.specialist) {
      this.onSpecialistChange(value);
      this.commercialService.notifyGrid(this.campaignDetails); // Notify booking Details
    }
  }

  /**
   * @description - Callback method call on removing selected specialist
   * @author Shivani Patel
   * @memberof CampaignDetailsGermanyComponent
   */
  removeSelectedSpecialistName() {
    this.onSpecialistChange();
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  /**
   * @description - Callback method call on agency name changed
   * @author Shivani Patel
   * @param {*} value
   * @memberof CampaignDetailsGermanyComponent
   */
  onAgencyNameSelected(value: any): void {
    if (this.campaignDetails.agency) {
      this.onAgencyChange(value);
      this.enableSubAgency();
    }
  }

  /**
   * @description - Callback method call on removing selected agency
   * @author Shivani Patel
   * @memberof CampaignDetailsGermanyComponent
   */
  removeSelectedAgencyName(): void {
    this.onAgencyChange();
    this.enableSubAgency();
  }

  /**
   * @description Event fires when assignedTo is changed
   * @author Shivani Patel
   * @memberof CampaignDetailsGermanyComponent
   */
  assignedToChange() {
    this.enableSubAgency();
    this.setActiveAssignedTo();
  }

  onBookingLineChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description Update pmp discount in grid when CPT value gets changed
   * @author Shivani Patel
   * @memberof CampaignDetailsGermanyComponent
   */
  onAverageCPTChanged() {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT);
    this.onBookingLineChange();
    if (this.uiControl.enableUserDefinedCPMPricingCalculation) {
      this.manuallyCPTUpdated.emit();
    }
  }

  /**
   * @description calculate CPT from gross media value or pmp discount change
   * @author Kishan Patel
   * @param {*} value
   * @memberof CampaignDetailsGermanyComponent
   */
  calculateCPT(value: { rowIndex?: number, columnName?: string }) {
    if (value.columnName === 'grossMediaValue') {
      this.campaignDetailsService.calculateCPT(
        this.campaignDetails, value.rowIndex,
        undefined,
        this.campaignDetails.bookingList[value.rowIndex].grossMediaValue
      );
    } else if (value.columnName === 'pmpdiscount') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex);
    }
  }

}
