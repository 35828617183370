<ag-grid-angular style="width: 100%; height: 400px;" class="ag-theme-fresh" [gridOptions]="gridOptions"
  [rowData]="rowData" [columnDefs]="columnDefs">
</ag-grid-angular>

<div class="col_select_menu">
  <div ngbDropdown class="d-inline-block" placement="left" [autoClose]="false" #toggleColumnContainer>
      <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
          <em class="fa fa-bars"></em>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="drop_container dg-filter">
          <div class="checkbox clearBoth" (click)="clearAllFilters()">
              <label class="center-block" style="cursor: pointer">Clear all filters</label>
          </div>
          <div class="checkbox clearBoth" (click)="downloadExcel()">
              <label class="center-block" style="cursor: pointer">Excel Export</label>
          </div>
          <div class="checkbox clearBoth">
              <label class="center-block">Columns:</label>
          </div>
          <div class="checkCell checkbox clearBoth" style="padding: 8px 5px;" *ngFor="let column of columnList; trackBy: trackByField;" [style.display]="column.colDef.field?'block':'none'">
              <label class="center-block">
                  <input type="checkbox" [checked]="column.visible" (change)="toggleColumnVisible(column.colDef.field, column.visible)" name="check-channel"
                      id="channel-{{column.colDef.field}}">
                  <em class="fa fa-check" *ngIf="column.visible"></em>
                  <em class="fa fa-close" *ngIf="!column.visible"></em>
                  <span>{{column.colDef.headerName}}</span>
              </label>
          </div>
      </div>
  </div>
</div>
