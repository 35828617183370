<div class="sidebar-wrapper_geo">
  <button class="filter-link_geo" style="padding: 0px 5px;width: 100%;">
    {{initialConfig.userBundle['geoplanner.text.cartsummary'] || 'Selection Summary'}}
    <span class="pull-right" (click)="closeSummary()">
      <em class="fa fa-close"></em>
    </span>
  </button>
  <button class="btn success_geo btn_view_detail" (click)="openCartDetail()" title="View detail">
    <em class="fa fa-eye"></em>
  </button>
  <div class="cart_search_container">
    <input type="text" [(ngModel)]="cartFilterText" id="cartFilterText" name="cartFilterText" (keyup)="onCartFilterChange()"
      class="form-control" placeholder="{{initialConfig.userBundle['geoplanner.text.search'] || 'Search...'}}">
  </div>
  <br>
  <div class="row">
    <div class="col-md-12 cart_grid_container" [ngClass]="{'readOnly': systemFlags.readOnlyWorkspace && !systemFlags.splitable }">
      <ag-grid-angular style="width:100%;height: 100%;" class="ag-theme-fresh cart_summary_grid" [gridOptions]="gridOptionsCart">
      </ag-grid-angular>
    </div>
  </div>
  <div class="selected_cart_items" [ngClass]="{'readOnly': systemFlags.readOnlyWorkspace && !systemFlags.splitable }">
    <span>{{cartSelectedFrameCount}}/ {{cartTotalFrameCount}} {{initialConfig.userBundle['geoplanner.text.selected'] ||
      'Selected'}}
    </span>
    <button (click)="deleteFrameFromCart()" class="btn white pull-right">
      <em class="fa fa-trash"></em>
    </button>
    <label class="small pull-right">
      <input [(ngModel)]="allCartFrameSelected" (change)="onSelectAllCartFrameClick()" type="checkbox" class="checkbox small"
        id="cartSelectAll" name="cartSelectAll">
      <span> {{initialConfig.userBundle['geoplanner.text.all'] || 'All'}}</span>
    </label>
  </div>
</div>