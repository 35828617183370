<app-sb-modal-wrapper class="reshuffle_modal" [titleBackgroundColor]="titleColor" title="{{userBundle['reshuffle.text.confirm'] || 'Confirm'}}" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)" saveButton="{{userBundle['reshuffle.text.confirm'] || 'Confirm'}}"
 [isButtonHide]="allowConfirmReshuffle" [footerMessage]="userBundle['reshuffle.zero.frame.error.message']">
    <div class="tabs_container" style="padding: 15px;">
        <div class="tab">
            <ag-grid-angular style="width: 100%; height: 250px;margin-bottom: 15px;" class="ag-theme-fresh reshuffle-detail" [gridOptions]="campaignGridOptions" [rowData]="rowData" [columnDefs]="campaignColumnDefs" [suppressDragLeaveHidesColumns]="true">
            </ag-grid-angular>
            <div class="col_select_menu">
                <div ngbDropdown class="d-inline-block" placement="left" [autoClose]="false" #toggleColumnContainer>
                    <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
            <em class="fa fa-bars"></em>
          </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="drop_container">
                        <div class="checkbox clearBoth" (click)="downloadExcel()">
                            <label class="center-block" style="cursor: pointer">Excel Export</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="reshuffle-footer" footer>
        <div *ngIf="expireTime > 0 ">
            {{expireMins}}m {{expireTime}}s
        </div>
    </div>
</app-sb-modal-wrapper>
