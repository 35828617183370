
import { ProductGroup, InitialConfigModel } from '../../models/initial-config.model';
import { DataShareService } from '../../core/services';

export class ChannelBase {
  public initialConfig: InitialConfigModel;
  public tabList: ProductGroup[];
  userBundle: any;

  constructor(
    private dataShareService: DataShareService,

  ) {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.tabList = this.initialConfig.productGroup;
  }
}
