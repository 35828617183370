import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChecklistDirective } from './directives/checklist.directive';
import { TagInputModule } from 'ngx-chips';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { TreeModule } from '@circlon/angular-tree-component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { ColorPickerModule } from 'ngx-color-picker';

import {
  DataShareService,
  WorkspaceService,
  FilterDataService,
  CampaignService,
  LogHelperService,
  SharedService,
  DistanceConverterService,
  BrickBaseService,
  FilterSupportService,
  StateService,
  CellAttributeService,
  HistoryStackService,
  SyncWsBricksService,
  MaskService,
  PcmService,
  FileUploadService,
  JwtHelperService
} from './services';
import { LookupViewService } from './components/lookup-view/lookup-view.service';
import { LoaderService } from './services/loader.service';
import { CommercialService } from './services/commercial.service';
import { DndModule } from 'ng2-dnd';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { ObjectKeysPipe } from './pipes/object-keys.pipe';

import { LookupViewComponent } from './components/lookup-view/lookup-view.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { NumberMaskDirective } from './directives/number-mask.directive';
import { PanZoomDirective } from './directives/pan-zoom.directive';
import { ResizableDirective } from './directives/resizable.directive';
import { MasterTooltipDirective } from './directives/master-tooltip.directive';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';

import { CartSummaryComponent } from '../geo-map/cart-summary/cart-summary.component';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { TreeViewComponent } from '../core/components/tree-view/tree-view.component';

import {
  TabsComponent,
  ConcertinaSortableComponent,
  ConcertinaCanvasService,
  ConcertinaHttpService,
  ConcertinaDataService
} from './components/concertina';

import { ConcertinaComponent } from './components/concertina/concertina.component';

import { SbSelectModule } from './components/sb-select';
import { BookingDetailsComponent, BookingDetailsService } from './components/booking-details';
import { BaiduMapComponent } from './components/baidu-map/baidu-map.component';
import { SwapZoneComponent, SwapZoneService } from './components/swap-zone';
import { NoDoubleClickDirective } from './directives/no-double-click.directive';
import { DigitalSwapComponent } from './components/swap-zone/digital-swap/digital-swap.component';
import { SbModalPopupModule } from './components/sb-modal-popup';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
// import { SecurePipe } from './pipes/secure-image.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    NgbModule,
    DndModule.forRoot(),
    AgGridModule,
    SbSelectModule,
    TreeModule,
    UiSwitchModule,
    ColorPickerModule,
    SbModalPopupModule.forRoot([
      DigitalSwapComponent
    ]),
  ],
  declarations: [
    // SecurePipe,
    LookupViewComponent,
    FileUploadComponent,
    OnlyNumberDirective,
    NumberMaskDirective,
    PanZoomDirective,
    ResizableDirective,
    MasterTooltipDirective,
    SessionTimeoutComponent,
    CurrencyFormatPipe,
    DateFormatPipe,
    SortByPipe,
    ObjectKeysPipe,
    ConcertinaComponent,
    TabsComponent,
    ConcertinaSortableComponent,
    BookingDetailsComponent,
    CartSummaryComponent,
    GlobalSearchComponent,
    TreeViewComponent,
    SwapZoneComponent,
    BaiduMapComponent,
    NoDoubleClickDirective,
    ChecklistDirective,
    DigitalSwapComponent,
    PaginationComponent,
    ContextMenuComponent
  ],
  // entryComponents: [
  //   LookupViewComponent,
  //   SessionTimeoutComponent,
  //   ConcertinaComponent,
  //   CartSummaryComponent,
  //   GlobalSearchComponent,
  //   SwapZoneComponent,
  //   BaiduMapComponent,
  //   DigitalSwapComponent
  // ],
  exports: [
    LookupViewComponent,
    FileUploadComponent,
    OnlyNumberDirective,
    NumberMaskDirective,
    SessionTimeoutComponent,
    CurrencyFormatPipe,
    DateFormatPipe,
    SortByPipe,
    ObjectKeysPipe,
    ConcertinaComponent,
    NgbModule,
    DndModule,
    AgGridModule,
    UiSwitchModule,
    BookingDetailsComponent,
    SbSelectModule,
    PanZoomDirective,
    ResizableDirective,
    CartSummaryComponent,
    GlobalSearchComponent,
    TreeViewComponent,
    MasterTooltipDirective,
    SwapZoneComponent,
    BaiduMapComponent,
    NoDoubleClickDirective,
    ChecklistDirective,
    DigitalSwapComponent,
    PaginationComponent,
    ContextMenuComponent
  ],
  providers: [
    DataShareService,
    WorkspaceService,
    PcmService,
    FilterDataService,
    LogHelperService,
    SharedService,
    LookupViewService,
    DistanceConverterService,
    BrickBaseService,
    FilterSupportService,
    LoaderService,
    DecimalPipe,
    CurrencyFormatPipe,
    DateFormatPipe,
    SortByPipe,
    ObjectKeysPipe,
    CampaignService,
    BookingDetailsService,
    ConcertinaHttpService,
    ConcertinaDataService,
    CommercialService,
    ConcertinaCanvasService,
    StateService,
    CellAttributeService,
    HistoryStackService,
    SyncWsBricksService,
    MaskService,
    SwapZoneService,
    JwtHelperService,
    FileUploadService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        DataShareService,
        WorkspaceService,
        PcmService,
        FilterDataService,
        LogHelperService,
        SharedService,
        LookupViewService,
        DistanceConverterService,
        FilterSupportService,
        LoaderService,
        CurrencyFormatPipe,
        DateFormatPipe,
        SortByPipe,
        ObjectKeysPipe,
        CampaignService,
        ConcertinaHttpService,
        ConcertinaDataService,
        ConcertinaCanvasService,
        BookingDetailsService,
        StateService,
        CellAttributeService,
        HistoryStackService,
        SyncWsBricksService,
        MaskService,
        SwapZoneService,
        FileUploadService
      ]
    };
  }
}
