import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { DataShareService } from '../../../../core/services/data-share.service';

@Injectable()
export class DataFormatterService {

  constructor(
    private dataShareService: DataShareService,
    private currencyPipe: CurrencyPipe) { }

  public currency(input, decimalPoints = 2): string {
    const currencySymbol = this.dataShareService.getInitialConfigByKey('systemData').currencySymbol;
    let formattedText = '';
    let inputVal = input;
    if ((typeof (inputVal) === 'number') && isNaN(inputVal)) {
      inputVal = 0;
    }
    if (inputVal !== '') {
      formattedText = this.currencyPipe.transform(inputVal, currencySymbol, 'code', `1.2-${decimalPoints}`);
    }
    return formattedText;
  }
}
