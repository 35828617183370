<app-filter-layout [resolveObject]="resolveObject" (closeClick)="closeClick($event)" (saveClick)="saveClick($event)">
  <div class="form-group-wrapper_geo">
    <div class="form-group_geo">
      <input type="radio" class="radio_geo" (change)="showContent(0)" [(ngModel)]="proximity.typeSelection"
        name="radio_groups" id="radio-postcode" value="0">
      <label class="radio-label_geo" for="radio-postcode"><em class="fa fa-circle-o "></em>
        {{userBundle['workspace.collection.bric.proximity.postcode']}}</label>
      <input type="radio" class="radio_geo" (change)="showContent(1)" [(ngModel)]="proximity.typeSelection"
        name="radio_groups" id="radio-poi" value="1">
      <label class="radio-label_geo" for="radio-poi"><em class="fa fa-circle-o "></em>
        {{userBundle['workspace.collection.bric.proximity.poi']}}</label>
    </div>
  </div>
  <div class="form-group-wrapper_geo">
    <div [hidden]="proximity.typeSelection == proximityTypeSelection.POI" [ngClass]="{'readOnly' : readOnlyModal }">
      <!-- Postcode section -->
      <div class="form-group_geo horizontal_geo">
        <input type="radio" id="sectorSelection" name="listuploadSelection"
          [(ngModel)]="proximity.postcodeSelection.listUploadSelection" class="radio_geo" value="false"
          (change)="postcodeSelectionChange(false)" />
        <label class="radio-label_geo" for="sectorSelection"><em class="fa fa-circle-o "></em>
          {{userBundle['workspace.collection.bric.proximity.postcode']}}</label>
        <input type="radio" id="listupload" name="listuploadSelection"
          [(ngModel)]="proximity.postcodeSelection.listUploadSelection" class="radio_geo" value="true"
          (change)="postcodeSelectionChange(true)" />
        <label class="radio-label_geo" for="listupload"><em class="fa fa-circle-o "></em>
          {{userBundle['workspace.collection.bric.proximity.postcodeList']}}</label>
      </div>
      <div class="clearfix"></div>
      <br>

      <div class="form-group_geo horizontal_geo" *ngIf="proximity.postcodeSelection.listUploadSelection == false">
        <!-- * you can add custome tag inout here * -->
        <tag-input id="postcode-tag-input" class="proximity-bricks"
          secondaryPlaceholder="{{userBundle['common.aadTag']}}" placeholder="{{userBundle['common.aadTag']}}"
          [(ngModel)]="proximity.postcodeSelection.selectedModelValues" [identifyBy]="'postcode'"
          [displayBy]="'postcode'" [onlyFromAutocomplete]="true" [minimumTextLength]="2" [maxItems]='100'
          (onAdd)="categoryAddRemoveTags()" (onRemove)="categoryAddRemoveTags()" [maintainSearchText]="false">
          <tag-input-dropdown [minimumTextLength]="3" [identifyBy]="'postcode'" [displayBy]="'postcode'"
            [autocompleteObservable]="loadPostCode" [matchingFn]="postCodeMatchingFn">
          </tag-input-dropdown>
        </tag-input>
      </div>
      <div class="form-group_geo horizontal_geo" *ngIf="proximity.postcodeSelection.listUploadSelection == true">
        <!-- * you can add custome tag inout here * -->
        <label>{{userBundle['geoplanner.text.upload'] || 'Upload'}}</label>
        <app-file-upload ngDefaultControl extension=".xls,.xlsx" [(fileName)]="proximity.fileName"
          (savefilelocally)="savefilelocally($event)"></app-file-upload>
      </div>
      <div class="form-group_geo horizontal_geo ">
        <label>{{userBundle['common.distance']}} </label>
        <input type="text" autocomplete="off" id="postcodeDistance" class="form-control_geo input_geo"
          [(ngModel)]="proximity.postcodeSelection.distance.distancevalue" type="text" [appOnlyNumber]="true"
          [decimalPoints]="2" />
        <div class="clearfix"></div>
        <label>&nbsp;</label>
        <div class="btn-group_geo">
          <button id="meterPostcode"
            (click)="onChangeDistanceType(distanceUnit.Meters, proximityTypeSelection.PostCode)"
            class="btn_geo btn-default"
            [ngClass]="{'active' : proximity.postcodeSelection.distance.distanceType == distanceUnit.Meters }">{{userBundle['common.metres']}}</button>
          <button id="kmPostcode"
            (click)="onChangeDistanceType(distanceUnit.KiloMeters, proximityTypeSelection.PostCode)"
            class="btn_geo btn-default"
            [ngClass]="{'active' : proximity.postcodeSelection.distance.distanceType == distanceUnit.KiloMeters }">{{userBundle['common.kilometres']}}</button>
          <button id="milePostcode" (click)="onChangeDistanceType(distanceUnit.Miles, proximityTypeSelection.PostCode)"
            class="btn_geo btn-default"
            [ngClass]="{'active' : proximity.postcodeSelection.distance.distanceType == distanceUnit.Miles }">{{userBundle['common.miles']}}</button>
        </div>
        <br>
        <label>&nbsp;</label>

        <input type="radio" class="radio_geo" name="radio_groups_poi" id="radio-1-31"
          [(ngModel)]="proximity.postcodeSelection.include" value="true">
        <label class="radio-label_geo" for="radio-1-31"><em class="fa fa-circle-o"></em>
          {{userBundle['common.include']}}</label>
        <input type="radio" class="radio_geo" name="radio_groups_poi" id="radio-1-32"
          [(ngModel)]="proximity.postcodeSelection.include" value="false">
        <label class="radio-label_geo" for="radio-1-32"><em class="fa fa-circle-o"></em>
          {{userBundle['common.exclude']}}</label>
      </div>

    </div>

    <div [hidden]="proximity.typeSelection == proximityTypeSelection.PostCode"
      [ngClass]="{'readOnly' : readOnlyModal }">
      <div class="control_group">
        <!-- Point of interest section -->
        <div class="form-group_geo horizontal_geo">
          <label for="data-source">{{userBundle['workspace.collection.bric.proximity.poi.dataSource']}}</label>
          <select id="data-source" class="form-control_geo select_geo"
            [(ngModel)]="proximity.pointOfInterest.selectedDatasource">
            <option *ngFor="let item of proximity.pointOfInterest.datasource; trackBy: trackByPoiType;" [value]="item">
              {{item.poiTypeName}}</option>
          </select>
        </div>

        <div class="form-group_geo horizontal_geo" *ngIf="!uiControl.proximityPOITree">
          <label for="attribute">{{userBundle['workspace.collection.bric.proximity.poi.attribute']}} </label>
          <select id="attribute" class="form-control_geo select_geo"
            [(ngModel)]="proximity.pointOfInterest.selectedAttributeId" (change)="resetOptions()">
            <option *ngFor="let item of proximity.pointOfInterest.attributeData; trackBy: trackByPoiAttribute;"
              [value]="item.poiAttributeId">
              {{item.poiAttributeName}}</option>
          </select>
        </div>

        <div class="form-group_geo horizontal_geo" *ngIf="!uiControl.proximityPOITree">
          <!--<label for="value1">{{userBundle['workspace.collection.bric.proximity.poi.value']}} </label>-->
          <tag-input class="proximity-bricks" secondaryPlaceholder="{{userBundle['common.aadTag']}}"
            placeholder="{{userBundle['common.aadTag']}}" [(ngModel)]="proximity.pointOfInterest.selectedModelValues"
            [identifyBy]="'poiName'" [displayBy]="'poiName'" [onlyFromAutocomplete]="true" [maxItems]='100'
            (onAdd)="categoryAddRemoveTags()" (onRemove)="categoryAddRemoveTags()" [maintainSearchText]="false">
            <tag-input-dropdown [minimumTextLength]="2" [identifyBy]="'poiName'" [displayBy]="'poiName'"
              [autocompleteObservable]="loadAttributeData">
            </tag-input-dropdown>
          </tag-input>
        </div>

        <div class="form-group_geo horizontal_geo" *ngIf="uiControl.proximityPOITree">
          <label for="attributeTree">{{userBundle['workspace.collection.bric.proximity.poi.attribute']}} </label>
          <select id="attributeTree" class="form-control_geo select_geo"
            [(ngModel)]="proximity.pointOfInterest.selectedAttributeTreeId" (change)="resetOptionsTree()">
            <option *ngFor="let item of proximity.pointOfInterest.attributeTreeData; trackBy: trackById;"
              [value]="item.id">{{item.name}}</option>
          </select>
        </div>

        <div class="form-group_geo horizontal_geo"
          *ngIf="proximity.typeSelection.toString() == '1' && uiControl.proximityPOITree && proximity.pointOfInterest.selectedAttributeTreeId == 1">
          <app-tree-view id="poiTree" [(state)]="state" [data]="treeData" [options]="treeOptions"
            [templateProps]="templateProps" (onSelect)="onSelectNode($event)" (onDeSelect)="onDeSelectNode($event)">
          </app-tree-view>

          <ng-container
            *ngIf="proximity.typeSelection && proximity.typeSelection.toString() === '1' && uiControl.proximityPOITree && proximity.pointOfInterest.selectedAttributeTreeId == 1 && uiControl.poiEnhancedTree">
            <div class="row control_group" *ngIf="this.proximity.pointOfInterest.selectedModelValues.length > 0">
              <div class="pull-left">
                <ng-container *ngIf="objectKeys(this.selectedValue).length > 0">
                  <label style="width:auto;margin-right: 5px;">{{userBundle['workspace.proximity.button.showSelected']
                    || 'Show
                    Selected'}}</label>
                  <label class="switchAllowFrames">
                    <input type="checkbox" [(ngModel)]="showSelected" (change)="onShowSelected()">
                    <span class="sliderAllowFrames round"></span>
                  </label>
                </ng-container>
              </div>
              <div class="pull-right">
                <button type="button" class="btn btn-default" (click)="resetOptionsTree()">
                  {{userBundle['workspace.proximity.button.deselectAll'] || 'Deselect All'}}
                </button>
              </div>
            </div>
          </ng-container>

        </div>

        <div class="form-group_geo horizontal_geo"
          *ngIf="uiControl.proximityPOITree && proximity.pointOfInterest.selectedAttributeTreeId == 2">
          <tag-input class="proximity-bricks" secondaryPlaceholder="{{userBundle['common.aadTag']}}"
            placeholder="{{userBundle['common.aadTag']}}" [(ngModel)]="proximity.pointOfInterest.selectedModelValues"
            [identifyBy]="'poiName'" [displayBy]="'poiName'" [onlyFromAutocomplete]="true" [maxItems]='100'
            (onAdd)="categoryAddRemoveTags()" (onRemove)="categoryAddRemoveTags()" [maintainSearchText]="false">
            <tag-input-dropdown [minimumTextLength]="2" [identifyBy]="'poiName'" [displayBy]="'poiName'"
              [autocompleteObservable]="loadAttributeData">
            </tag-input-dropdown>
          </tag-input>
        </div>

        <div class="form-group_geo horizontal_geo">
          <input type="radio" class="radio_geo" name="rdFrame" id="rbt-frame" value="0"
            [checked]="proximity.pointOfInterest.radioButtons.selection == 0"
            (change)="proximity.pointOfInterest.radioButtons.selection = 0"
            [disabled]="proximity.pointOfInterest.radioButtons.disabled">
          <label class="radio-label_geo" for="rbt-frame"><em class="fa fa-circle-o "></em>
            {{userBundle['workspace.collection.bric.proximity.poi.all']}} </label>
          <input type="radio" name="rdFrame" class="radio_geo" id="rbt-listUpload" value="1"
            [checked]="proximity.pointOfInterest.radioButtons.selection == 1"
            (change)="proximity.pointOfInterest.radioButtons.selection = 1"
            [disabled]="proximity.pointOfInterest.radioButtons.disabled">
          <label class="radio-label_geo" for="rbt-listUpload"><em class="fa fa-circle-o "></em>
            {{userBundle['workspace.collection.bric.proximity.poi.any']}} </label>
        </div>
        <div class="clearfix"></div>
        <br>


        <div class="form-group_geo horizontal_geo ">
          <label>{{userBundle['common.distance']}} </label>
          <input type="text" autocomplete="off" id="poiDistance" class="form-control_geo input_geo"
            [(ngModel)]="proximity.pointOfInterest.distance.distancevalue" type="text" [appOnlyNumber]="true"
            [decimalPoints]="2" />
          <div class="clearfix"></div>
          <label>&nbsp;</label>
          <div class="btn-group_geo">
            <button id="meterPoi" (click)="onChangeDistanceType(distanceUnit.Meters, proximityTypeSelection.POI)"
              class="btn_geo btn-default"
              [ngClass]="{'active' : proximity.pointOfInterest.distance.distanceType == distanceUnit.Meters }">{{userBundle['common.metres']}}</button>
            <button id="kmPoi" (click)="onChangeDistanceType(distanceUnit.KiloMeters, proximityTypeSelection.POI)"
              class="btn_geo btn-default"
              [ngClass]="{'active' : proximity.pointOfInterest.distance.distanceType == distanceUnit.KiloMeters }">{{userBundle['common.kilometres']}}</button>
            <button id="milePoi" (click)="onChangeDistanceType(distanceUnit.Miles, proximityTypeSelection.POI)"
              class="btn_geo btn-default"
              [ngClass]="{'active' : proximity.pointOfInterest.distance.distanceType == distanceUnit.Miles }">{{userBundle['common.miles']}}</button>
          </div>
          <br>
          <label>&nbsp;</label>

          <input type="radio" class="radio_geo" name="radio_groups_include"
            [(ngModel)]="proximity.pointOfInterest.include" value="true" id="check11">
          <label for="check11" class="radio-label_geo"><em class="fa fa-circle-o"></em>
            {{userBundle['common.include']}}</label>
          <input type="radio" class="radio_geo" name="radio_groups_include"
            [(ngModel)]="proximity.pointOfInterest.include" value="false" id="check22">
          <label for="check22" class="radio-label_geo"><em class="fa fa-circle-o"></em>
            {{userBundle['common.exclude']}}</label>
        </div>
      </div>
    </div>
  </div>
</app-filter-layout>