import { Component, OnInit } from '@angular/core';
import { StateService } from '../../../app/core/services/state.service';
import { CampaignDetailsService } from '../campaign-details/campaign-details.service';
import { HistoryROC } from '../commercial.model';
import { SystemFlags } from '../../models';
import { DataShareService, LogHelperService } from '../../core/services';
import { CommercialService } from '../../core/services/commercial.service';
import { CommercialButtonService } from '../commercial-buttons/commercial-buttons.service';
import { DatePipe } from '@angular/common';
import { GLOBAL } from '../../core/utils/app.constant';
import { CommercialBase } from '../commercial-base';
import { RequiredCampaignFields, RequiredBookingsFields, PopulateCommercialFields, PopulateBookingDetailFields } from '../../models/RequiredFields';

@Component({
  selector: 'app-commercial-sg',
  templateUrl: './commercial-sg.component.html'
})
export class CommercialSgComponent extends CommercialBase implements OnInit {
  systemFlags = SystemFlags;

  /**
   * @description commercial data
   * @memberof CommercialSgComponent
   */
  public commercialData;

  /**
   * @description Accordian for campaign details
   * @memberof CommercialSgComponent
   */
  hideCampaignDetails: boolean;
  /**
   * @description Accordian for booking details
   * @memberof CommercialSgComponent
   */
  hideBookingDetails: boolean;
  /**
   * @description Accordian for Discount Grid
   * @memberof CommercialSgComponent
   */
  hideDiscountGrid: boolean;

  readOnly = false;
  /**
   * @description set bookingStatusList
   * @memberof CommercialSgComponent
   */
  bookingStatusList = GLOBAL.bookingStatusList;

  /**
   * @description this will show the history of campaign updated in case of reason/cancel for change
   * @memberof CommercialSgComponent
   */
  historyROC = new HistoryROC();

  constructor(
    stateService: StateService,
    campaignDetailsService: CampaignDetailsService,
    dataShareService: DataShareService,
    commercialService: CommercialService,
    commercialButtonService: CommercialButtonService,
    logHelperService: LogHelperService,
    datePipe: DatePipe
  ) {
    super(stateService, logHelperService, commercialButtonService, dataShareService, commercialService, datePipe, campaignDetailsService);
  }

  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.setDefaultAgencySpecialistPerc();
    this.readOnly = SystemFlags.readOnly;
    this.restoreCommercialData();
  }

  /**
   * @description get commercial data
   * @author Alkesh Shah
   * @memberof CommercialSgComponent
   */
  restoreCommercialData() {
    this.commercialConfig.user = this.dataShareService.getUserModel();
    this.getCommercialDataFromService();
  }

  /**
   * @description get commercial data from service
   * @author Alkesh Shah
   * @memberof CommercialSgComponent
   */
  getCommercialDataFromService(isManual = false) {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
    };

    this.commercialService.loadCommercialCampaignDetails(param).subscribe((data) => {
      if (data.status === 'OK') {
        this.commercialConfig.campaignStatus = data.data.campaignStatusId;
        this.commercialConfig.bookingStatus = data.data.bookingStatus;
        this.commercialConfig.uiControl = data.data.uiControl;
        this.commercialData = data.data;
        
        if (isManual) {
          this.dataShareService.updateBookingList(this.commercialData.bookingList);
        }
        this.commercialData.bookingStatusName =
          this.campaignDetailsService.getStatusNameFromId(data.data.campaignStatusId);
        const assignedToList = this.dataShareService.getInitialConfigByKey('assignedTo');
        this.campaignDetails = this.campaignDetailsService.populateCampaignDetails(
          this.commercialData, this.initialConfig, assignedToList);
        this.campaignDetails.statusCategory = this.commercialData.statusCategory;
        this.commercialData.bookingList = this.commercialData.bookingList || [];
        this.updateBookingList();
        if (isManual) {
          this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT);
        } else {
          this.campaignDetailsService.calculateCPT(this.campaignDetails);
        }
        this.historyROC = this.campaignDetailsService.populateHistoryROC(this.historyROC, data.data.auditReasonsHistoryMap, this.commercialConfig.uiControl.historyROC);
      }
    });

  }

  /**
   * Update Booking List
   */
  updateBookingList() {
    // For individual booking in campaign
    if (this.commercialData.bookingList && this.commercialData.bookingList.length > 0) {
      for (const obj of this.commercialData.bookingList) {
        if (!obj.hideBlanking) { // SBRICS-1596, Nishit
          if (obj.noBlanking) {
            obj.noBlanking = true;
            obj.blankingOption = 'noBlanking';
          } else if (obj.blankOnTermination) {
            obj.noBlanking = true;
            obj.blankingOption = 'blankOnTermination';
          } else if (obj.blankOn) {
            obj.blankOn = new Date(obj.blankOn.replace(/-/g, '/'));
            obj.blankingOption = 'blankOn';
          } else if (obj.blankBy) {
            obj.blankBy = new Date(obj.blankBy.replace(/-/g, '/'));
            obj.blankingOption = 'blankBy';
          }
        }
      }
    }
  }

  /**
   * @description this is output event from commercialbutton when click on update/option/book/cancel button click
   * @author Alkesh Shah
   * @param {*} status
   * @memberof CommercialSgComponent
   */
  onBookCampaign(status: number) {
    if (this.validateCommercialCampaign(status)) {
      status = status || this.campaignDetails.campaignStatusId;

      if (this.isROC(status)) {
        this.handleROC(status, this.createRequestJSONForCommercial);
      } else if (this.isConfirm(status)) {
        this.confirmCampaign(this.createRequestJSONForCommercial);
      } else {
        this.validateServiceRequest(status, this.createRequestJSONForCommercial);
      }
    }
  }

  /**
   * @description commercial form validity
   * @author Alkesh Shah
   * @returns {boolean}
   * @memberof CommercialSgComponent
   */
  validateCommercialCampaign = (status) => {
    const requiredFields: RequiredCampaignFields = new RequiredCampaignFields();
    requiredFields.assignedTo = true;
    requiredFields.campaignEndDate = true;
    let message = this.validateCommonFields(requiredFields);
    if (!message && this.campaignDetails.bookingList) {
      this.campaignDetails.bookingList.forEach((obj) => {
        const requiredBookingsFields: RequiredBookingsFields = new RequiredBookingsFields(false, true, true, true, true);
        if (!message) {
          message = this.validateBookingFields(obj, requiredBookingsFields);
        }
        if (!message && obj.containsDigital) {
          message = this.validateMediaLength(obj, status);
        }
      });
    }
    return this.isValid(message);
  }

  /**
   * @description create requestJSON for passing to backend
   * @author Alkesh Shah
   * @param {*} status
   * @returns
   * @memberof CommercialSgComponent
   */
  createRequestJSONForCommercial = (status) => {
    const campaignDetails = this.campaignDetails;
    const populate: PopulateCommercialFields = this.getCommercialFields();
    const temp = this.populateBookCommercialCampaignParam(status, campaignDetails, populate);
    const populateBooking: PopulateBookingDetailFields = this.getBookingDetailFields();
    temp.bookingList = this.populateBookingDetailsServiceObject(campaignDetails, 'bookingList', populateBooking);
    if (this.commercialData.bookingDetails) {
      temp.bookingDetails = this.commercialData.bookingDetails;
    }
    return temp;
  }

  /**
   * To override the commercial fields
   * This override fields are used to override the default value
   * @memberof CommercialSgComponent
   * @author Dhaval Patel
   */
  getCommercialFields() {
    const populate: PopulateCommercialFields = super.getCommercialFields();
    populate.setPCFields();
    return populate;
  }

  /**
   * To override the booking detail fields
   * This override fields are used to override the default value
   * @memberof CommercialSgComponent
   * @author Dhaval Patel
   */
  getBookingDetailFields() {
    const populate: PopulateBookingDetailFields = super.getBookingDetailFields();
    populate.setPICFields();
    populate.setCCBIBFields();
    populate.setPOORIFields();
    populate.financeAmount = true;
    populate.setPMDBFields();
    return populate;
  }

  async manuallyCPTUpdated() {
    const campaigndata = this.createRequestJSONForCommercial(this.campaignDetails.campaignStatusId);
    await this.updateCommercialInfoForCPT(campaigndata);
    this.getCommercialDataFromService(true);
  }

}
