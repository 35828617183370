<div [ngClass]="{remove: showRemoveBar}" [dndDropzone]="removeDropZone" (dndDrop)="onRemoveBrick($event)"
    dndDragoverClass="highlight-div-panning" id="removeBar">
    <div class="trash" [style.display]="showRemoveBar ? 'block' : 'none'">
        <span>{{userBundle['workspace.label.remove']}}</span>
    </div>
</div>

<div class="switchBRICdragging{{alternateDesign}}" style="display:none"></div>

<div class="sidebar bounceInLeft animated" [ngClass]="{'readOnly' : readOnlyModal}">
    <dl class="accordion">
        <ng-container *ngFor="let data of masterData; trackBy: trackByCategoryId; index as i">
            <dt class="cat_name" [hidden]="data.hide">
                <a href="javascript:void(0)" class="title"
                    [ngClass]="{'down': data.isAccordionDown, '': !data.isAccordionDown}"
                    (click)="data.isAccordionDown = !data.isAccordionDown">
                    <span class="icons animated" [style.background-color]="data.brics[0]?.color"></span>
                    <span class="text" [style.color]="data.brics[0]?.color">{{data.name}}</span>
                </a>
            </dt>
            <dd [ngClass]="{'down': data.isAccordionDown, '': !data.isAccordionDown}" [hidden]="data.hide">

                <div class="brick_container"
                    *ngFor="let bric of data.brics; trackBy: trackByBricId; index as brickIndex"
                    [hidden]="bric.hide && !bric.optionalForProduct">
                    <div class="brick peg" id="abc{{bric.bricid}}" [style.backgroundColor]="bric.color"
                        [dndDraggable]="bric" [dndType]="'master'" [dndEffectAllowed]="draggableMaster.effectAllowed"
                        [dndDragImageOffsetFunction]="calDragImageOffset"
                        [dndDisableIf]="draggableMaster.disable || readOnlyModal"
                        (dndStart)="onMasterBricDragStart(bric)" (dndEnd)="onDragEnd()"
                        dndDraggingClass="switchBRICdragging{{alternateDesign}}"
                        [ngClass]="{'brick_disabled' : bric.disabled}">
                        <img src="{{'assets/img/brick'+alternateDesign+'.png'}}" alt="brick" />
                        <div dndDragImageRef [style.backgroundColor]="bric.color"></div>
                        <div class="panel-line ng-scope" *ngIf="alternateDesign==1"></div>
                    </div>
                    <div class="bricktext">{{bric.bricname}}</div>
                </div>
            </dd>
        </ng-container>
    </dl>
</div>


<div class="workspace bounceInRight animated">
    <div [ngStyle]="{'margin-top': lockedMessageStatus && margin * 2 > 60 ? margin+'px' : '0px'}">
        <app-campaign-summary></app-campaign-summary>
    </div>

    <div class="workspace_block">
        <div class="workspace_block_2 animated zoomIn" id="workspace">
            <div class="iteration-FTG" *ngIf="filter.ftgIterationAllowed && filter.lastFtgIteration">
                <strong>{{userBundle['worksapce.ftgIteration'] || 'FTG Iteration'}}</strong>:
                {{filter.lastFtgIteration}}
            </div>
            <app-allocation-adjustment [readOnly]="readOnlyModal" [campaignParameters]="filter.campaignParameters"
                (campaignParametersChange)="campaignParametersChange($event)"></app-allocation-adjustment>
            <div id="divPanning" dndDropzone [ngClass]="{'highlight-div-panning': highlightDivPanning}"
                (dndDrop)="onDrop($event)" (dragover)="highlightWorkspace()" (dragleave)="highlightDivPanning=false;">
                <div class="panhandler-wrap" appPanZoom [disablePan]="disablePanning" [zoomIn]="zoomIn"
                    [zoomOut]="zoomOut" [zoomReset]="zoomReset" [refContainer]="panZoomContainer">
                    <div *ngIf="waterMark" class="watermark">
                        {{waterMark?.campaignReference}}
                        <br> {{waterMark?.advertiser}}
                        <br> {{waterMark?.agency}}
                    </div>
                    <div class="watermark_middle"
                        [hidden]="waterMark.campaignReference || waterMark.advertiser || waterMark.agency || filter.rows.length > 0">
                        {{userBundle['worksapce.defaultBackgroundText']}}
                    </div>
                    <div id="divZoom" #panZoomContainer>
                        <ng-container *ngFor="let row of filter.rows; trackBy: trackByRowIndex; index as rowIndex">
                            <div class="rows dropRow"
                                [style.width]="(CELLWIDTH * row.cells.length) + rowWidthDelta + 'px'"
                                [dndDraggable]="row" [dndEffectAllowed]="draggableRow.effectAllowed" dndDropzone
                                [dndType]="'row'" (dndStart)="onBricDragStart(row.bric, null)" (dndEnd)="onDndEnd()"
                                (dndDrop)="onDrop($event)" (dragover)="row.highlight(draggedBricHighlight)"
                                (dragleave)="row.removeHighlight()"
                                [ngClass]="{'row_hidden': row.isHidden, denmarkBRIC: alternateDesign == 2, 'disableCursor': row.disableCursorHighlight, 'lvl-over': row.highlightRow }"
                                [dndDisableIf]="readOnlyModal">
                                <div class="dropsrow" dndHandle dndDropzone [dndAllowExternal]="'true'"
                                    (dndDrop)="onMultidropQtyBrick($event, rowIndex)" (dndEnd)="onDndEnd()"
                                    [dndDisableIf]="readOnlyModal">
                                </div>
                                <div class="approved" *ngIf="!row.isHidden && row.isValid">
                                </div>
                                <div class="error" [hidden]="row.isValid"></div>
                                <div class="helpfind" [hidden]="row.isValid"></div>

                                <div class="slot flat_brick switchBRIC_image{{alternateDesign}}" #cellElement
                                    *ngFor="let cell of row.cells; trackBy: trackByCellIndex; index as colIndex"
                                    [hidden]="cell.isHidden" appResizable [disableResize]="cell.isLocked || !cell.isEditable || cell.isMandatoryForProduct || cell.isEmpty || cell.isMandatoryEditableForProduct
                  || row.bric.bricid === brickBaseService.brickID.Audience
                  || row.bric.bricid === brickBaseService.brickID.SecondaryAudience
                  || row.bric.bricid === brickBaseService.brickID.PrimaryAudience
                  || readOnlyModal" (resizing)="onCellExpanding()" (resizeStop)="onCellExpanded($event,cell)"
                                    [minResizeWidth]="CELLWIDTH" [maxResizeWidth]="(row.cells.length - colIndex)"
                                    [actualCellWidth]="cell.cellWidth" (resizeStart)="onCellExpandBegin()"
                                    [style.width]="(cell.cellWidth * CELLWIDTH) + 'px'"
                                    [style.backgroundColor]="cell.isLocked || !cell.isEditable || (cell.isMandatoryForProduct && brickBaseService.PCM_EDITABLE_BRICS.indexOf(row.bric.bricid) == -1) ? row.bric.color + '4D' : row.bric.color"
                                    [style.color]="row.bric.bricid === brickBaseService.brickID.Proximity ? '#545454' :'#ffffff'"
                                    dndDraggingClass="switchBRICdragging{{alternateDesign}}"
                                    [ngClass]="{'unUsed':!cell.isHidden && cell.isEmpty, 'denmarkBRIC': alternateDesign == 2,
                  'explodeicon':allowExplode,
                  'spliticon':allowSplit,
                  'lvl-over': cell.highlightCell,
                  'lr-drop-target-before-brick': cell.highlightLeft,
                  'lr-drop-target-after-brick': cell.highlightRight,
                  'brick_error': !cell.isValid,
                  'half_opacity':cell.selected.disableObjective,
                  'brick_hidden': filter.isPastColumnHidden && stateService.getColumnHelper()[cell.cellIndex] && stateService.getColumnHelper()[cell.cellIndex].systemLocked }"
                                    [dndDisableIf]="cell.isLocked || !cell.isEditable || cell.isMandatoryEditableForProduct"
                                    (dragover)="dragOverCell($event, cellElement, row.bric.bricid, cell);"
                                    (dragleave)="cell.removeHighlight()" dndDropzone [dndType]="'cell'"
                                    (dblclick)="onBrickDblClick(row,cell)" [dndDraggable]="cell"
                                    [dndDisableDragIf]="cell.isLocked || cell.isMandatoryEditableForProduct || !cell.isEditable || cell.isEmpty || isResizing || readOnlyModal || (cell.isMandatoryForProduct && brickBaseService.PCM_EDITABLE_BRICS.indexOf(row.bric.bricid) == -1 && row.bric.bricid != brickBaseService.brickID.ProductCatalogue)"
                                    [dndEffectAllowed]="'move'" [dndDragImageOffsetFunction]="calDragImageOffset"
                                    (dndDrop)="onDrop($event, rowIndex, colIndex)" (click)="onBrickClick(cell)"
                                    (dndStart)="onBricDragStart(row.bric, cell.cellIndex)"
                                    [attr.data-col-index]="colIndex" [attr.data-row-index]="rowIndex"
                                    (mouseover)="disableMasterBrics(cell.cellIndex)"
                                    (mouseleave)="enableAllMasterBrics()" (dndEnd)="onDndEnd()"
                                    id="abc{{row.bric.bricid}}"
                                    (contextmenu)="displayContextMenu($event, rowIndex, colIndex); false">

                                    <div dndDragImageRef [style.backgroundColor]="row.bric.color"
                                        [ngClass]="{'brick_disabled': cell.isLocked || !cell.isEditable || (cell.isMandatoryForProduct && brickBaseService.PCM_EDITABLE_BRICS.indexOf(row.bric.bricid) == -1) }">
                                    </div>
                                    <div class="panel-line ng-scope" *ngIf="alternateDesign==1"
                                        [ngClass]="{'brick_disabled': cell.isLocked || !cell.isEditable || (cell.isMandatoryForProduct && brickBaseService.PCM_EDITABLE_BRICS.indexOf(row.bric.bricid) == -1) }">
                                    </div>
                                    <div [innerHTML]='cell.displayText' appMasterTooltip [tooltipName]="'customtooltip'"
                                        [tooltipScope]="1">
                                    </div>
                                    <span
                                        [ngClass]="{'unUsed':cell.isEmpty,'used':!cell.isEmpty, 'selectedbrick': cell.markSelected, 'brick_disabled': cell.isLocked || !cell.isEditable || (cell.isMandatoryForProduct && brickBaseService.PCM_EDITABLE_BRICS.indexOf(row.bric.bricid) == -1) }"
                                        class="resize">
                                        <em class="halficonbtn appResizableHandle"
                                            [ngClass]="{'disable-expand': cell.isLocked || cell.isMandatoryEditableForProduct || !cell.isEditable || cell.isMandatoryForProduct}">&nbsp;</em>
                                    </span>
                                    <img class="info_icon" src="../../../../../assets/img/information.png"
                                        [ngClass]="{'brick_disabled': cell.isLocked || !cell.isEditable || (cell.isMandatoryForProduct && brickBaseService.PCM_EDITABLE_BRICS.indexOf(row.bric.bricid) == -1) }"
                                        appMasterTooltip alt="info" [tooltipName]="'warningtooltip'"
                                        *ngIf="cell.showWarningIcon === true" [tooltipScope]="1" />
                                    <!-- tolerance warning -->
                                    <span
                                        *ngIf="(row.bric.bricid === 1 || row.bric.bricid === 3 || row.bric.bricid === 8) && !cell.isEmpty && row.bric.isUsed == true && filter.toleranceLimit.cellAddress.indexOf((rowIndex+','+colIndex)) > -1"
                                        appMasterTooltip [tooltipName]="'tolerancetooltip'" [tooltipScope]="1"
                                        [ngClass]="{'brick_disabled': cell.isLocked || !cell.isEditable || (cell.isMandatoryForProduct && brickBaseService.PCM_EDITABLE_BRICS.indexOf(row.bric.bricid) == -1) }">
                                        <em class="fa fa-warning icontruefalse iconwarning" aria-hidden="true"></em>
                                        <div class="tolerancetooltip" style="word-break: break-word;">
                                            {{userBundle['workspace.toleranceLimit.warning']}}</div>
                                    </span>
                                    <!-- End tolerance warning -->
                                    <div *ngIf="cell.toolTipText" class="customtooltip">
                                        <div [innerHTML]='cell.toolTipText'></div>
                                    </div>
                                    <em
                                        [ngClass]="{'fa fa-clone icontruefalse' : cell.selected['allocateAllFrames'], 'allocateAllFrames': cell.selected['allocateAllFrames'] && global.localSolverEnabled,
                                        'brick_disabled': cell.isLocked || !cell.isEditable || (cell.isMandatoryForProduct && brickBaseService.PCM_EDITABLE_BRICS.indexOf(row.bric.bricid) == -1)}"></em>
                                    <em *ngIf="!cell.isEmpty && !cell.isHidden && [1, 3, 8, 27, 28].includes(row.bric.bricid) && global.localSolverEnabled"
                                        class="img-circle trafficLight"
                                        [ngClass]="{'bg-red': cell.selected.status === TOLERANCE_STATUSES.RED, 'bg-orange': cell.selected.status === TOLERANCE_STATUSES.ORANGE, 'bg-green': cell.selected.status === TOLERANCE_STATUSES.GREEN,
                                        'brick_disabled': cell.isLocked || !cell.isEditable || (cell.isMandatoryForProduct && brickBaseService.PCM_EDITABLE_BRICS.indexOf(row.bric.bricid) == -1)}">
                                        &nbsp;
                                    </em>
                                    <em *ngIf="!cell.isEmpty && !cell.isHidden && row.bric.bricid === 19 && global.localSolverEnabled && (!uiControl?.isNetworkAsaFilterEnabled) && filter.allocationEngines[colIndex] === global.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH && cell.selected.status"
                                    class="img-circle trafficLight"
                                    [ngClass]="{'bg-red': cell.selected.status === TOLERANCE_STATUSES.RED, 'bg-orange': cell.selected.status === TOLERANCE_STATUSES.ORANGE, 'bg-green': cell.selected.status === TOLERANCE_STATUSES.GREEN,
                                    'brick_disabled': cell.isLocked || !cell.isEditable || (cell.isMandatoryForProduct && brickBaseService.PCM_EDITABLE_BRICS.indexOf(row.bric.bricid) == -1)}">
                                    &nbsp;
                                    </em>
                                    <div class="priority"
                                        [ngClass]="{'brick_disabled': cell.isLocked || !cell.isEditable || (cell.isMandatoryForProduct && brickBaseService.PCM_EDITABLE_BRICS.indexOf(row.bric.bricid) == -1) }"
                                        *ngIf="global.localSolverEnabled && !uiControl.hideObjectivePriority && cell.selected.priority !== null && cell.selected.priority !== undefined && [1, 3, 8].includes(row.bric.bricid)">
                                        {{cell.selected.priority}}
                                    </div>
                                    <div *ngIf="cell.warningText" class="warningtooltip">
                                        <div [innerHTML]='cell.warningText'></div>
                                    </div>
                                    <app-context-menu *ngIf="rightClickMenuItems.length > 0 && rightClickMenuItems[0].rowIndex == rowIndex
                                    && rightClickMenuItems[0].colIndex == colIndex && isDisplayContextMenu" [ngStyle]="getRightClickMenuStyle()"
                                        [contextMenuItems]="rightClickMenuItems"
                                        (onContextMenuItemClick)="handleMenuItemClick($event)"></app-context-menu>
                                </div>
                            </div>
                        </ng-container>

                        <!--Column Summary Start-->
                        <div class="rows dropRow"
                            *ngIf="columnSummarys.length && !incorrectSolution && (uiControl.showSingleColumnSummary ? filter?.rows[0]?.cells.length >= 1 : filter?.rows[0]?.cells.length > 1)">
                            <div class="columnsummaryrow"
                                *ngFor="let item of columnSummarys; trackBy: trackByIndex; let index = index"
                                [hidden]="filter.isPastColumnHidden && stateService.getColumnHelper()[index] && stateService.getColumnHelper()[index].systemLocked">
                                <!--{{item.isHidden}}-->
                                <div class="columnsummary" *ngIf="!item.isHidden" appMasterTooltip
                                    [tooltipName]="'mastertooltip'"
                                    [alwaysDisplay]="uiControl.alwaysDisplayColumnSummary">
                                    <img src="../../../../../assets/img/question.png"
                                        [hidden]="uiControl.alwaysDisplayColumnSummary" alt="question" />
                                    <div class="mastertooltip"
                                        [ngStyle]="{'display:block': uiControl.alwaysDisplayColumnSummary}">
                                        <table>
                                            <ng-container *ngFor="let k of item | objectKeys; trackBy: trackByItem">
                                                <tr class="contents" *ngIf="k!=='isHidden' && k !== 'dBudgetSummary'">
                                                    <td>
                                                        <img [src]="item[k].imagePath" height="17" width="20"
                                                            alt="price" />
                                                    </td>
                                                    <td style="float:left;padding-left:3px;">{{item[k].value}}</td>
                                                </tr>
                                                <tr class="contents" *ngIf="k!=='isHidden' && k === 'dBudgetSummary'">
                                                    <td class="currency-tooltip">{{systemData.currencySymbol}}</td>
                                                    <td style="float:left;padding-left:3px;">{{item[k].value}}</td>
                                                </tr>
                                            </ng-container>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!--Column Summary End-->
                        <!-- SM-3393 - Reshuffling icon -->
                        <div class="rows dropRow reshuffle-icon-margin"
                            *ngIf="filter.reshuffle.reshufflingColumns.length > 0">
                            <div class="columnsummaryrow"
                                *ngFor="let cell of filter.rows[0].cells; trackBy: trackByCellIndex; let index = index"
                                [hidden]="filter.isPastColumnHidden && stateService.getColumnHelper()[index] && stateService.getColumnHelper()[index].systemLocked">
                                <div class="columnsummary"
                                    *ngIf="filter.reshuffle.reshufflingColumns.indexOf(index) > -1">
                                    <button type="button" class="zoombutton animated bounceIn shufflebutton"
                                        title="Reshuffle" (click)="onReshufflingClick(index)"></button>
                                    <img src="../../../../../assets/img/warning.png"
                                        *ngIf="filter.reshuffle.lsWarning && filter.reshuffle.lsWarning[index]"
                                        class="warning-icon" alt="warning"
                                        title="{{filter.reshuffle.lsWarning[index]}}" />
                                </div>
                            </div>
                        </div>
                        <div class="rows dropRow"
                            *ngIf="filter && filter.allocationEngines && filter.initialConfig && filter.rows && filter.rows.length && filter.initialConfig.uiControl && filter.initialConfig.uiControl.defaultAllocationEngine && filter.initialConfig.uiControl.allowAllocationEngineChange">
                            <div class="columnsummaryrow"
                                *ngFor="let cell of filter.rows[0].cells; trackBy: trackByCellIndex; let index = index"
                                [hidden]="filter.isPastColumnHidden && stateService.getColumnHelper()[index] && stateService.getColumnHelper()[index].systemLocked">
                                <div class="columnsummary" *ngIf="filter.allocationEngines[index]">
                                    <button type="button"
                                        [ngStyle]="{'cursor': (stateService.getColumnHelper()[index] && (stateService.getColumnHelper()[index].systemLocked || stateService.getColumnHelper()[index].userLocked)) || checkIfAllocationEngineCanChange() ? 'not-allowed' : 'pointer'}"
                                        [ngClass]="{'lsbutton': filter.allocationEngines[index] === global.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_LS,
                                        'vioohbutton': filter.allocationEngines[index] === global.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH,
                                        'gsbutton': filter.allocationEngines[index] === global.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_GISGO}"
                                        [disabled]="(uiControl.allowAllocationEngineChange == false) ||
                                        (stateService.getColumnHelper()[index] &&
                                        (stateService.getColumnHelper()[index].systemLocked || stateService.getColumnHelper()[index].userLocked))
                                        || checkIfAllocationEngineCanChange()"
                                        class="zoombutton animated bounceIn" title="Allocation Engine"
                                        (click)="onAllocationEngineClick(index)"></button>
                                </div>
                            </div>
                        </div>
                        <!-- SM-3393 - Reshuffling icon End -->
                        <!--Column Locking Start-->
                        <div class="rows" *ngIf="checkIfColumnLockingEnabled() && !incorrectSolution">
                            <div class="dropsrow">
                                <div class="lock-all" *ngIf="filter.rows[0].cells.length">
                                    <button type="button" class="zoombutton animated bounceIn"
                                        [ngClass]="{'allunlockbutton': !filter.lockAllButton, 'alllockbutton': filter.lockAllButton}"
                                        [hidden]="checkIfOnlyPastColsLeftOnWorkspace()"
                                        [title]="filter.lockAllButton ? userBundle['workspace.button.tooltip.unLockAll'] || 'Unlock All' : userBundle['workspace.button.tooltip.lockAll'] || 'Lock All'"
                                        (click)="toggleAllLocking()"></button>
                                </div>
                            </div>
                            <div class="columnsummaryrow"
                                *ngFor="let cell of filter.rows[0].cells; trackBy: trackByCellIndex; let index = index"
                                [hidden]="filter.isPastColumnHidden && stateService.getColumnHelper()[index] && stateService.getColumnHelper()[index].systemLocked">
                                <div class="columnsummary">
                                    <button
                                        [ngStyle]="{'cursor': stateService.getColumnHelper()[index] && stateService.getColumnHelper()[index].systemLocked ? 'not-allowed' : 'pointer'}"
                                        [disabled]="stateService.getColumnHelper()[index] && stateService.getColumnHelper()[index].systemLocked"
                                        type="button" class="zoombutton animated bounceIn"
                                        [ngClass]="{'unlockbutton': !stateService.getColumnHelper()[index] || !stateService.getColumnHelper()[index]?.userLocked, 'lockbutton': stateService.getColumnHelper()[index] && stateService.getColumnHelper()[index].userLocked}"
                                        [title]="stateService.getColumnHelper()[index] ? userBundle['workspace.button.tooltip.unLock'] || 'Unlock' : userBundle['workspace.button.tooltip.lock'] || 'Lock'"
                                        (click)="toggleLock(index)"></button>
                                </div>
                            </div>
                        </div>
                        <!--Column Locking End-->

                        <!-- Ghost Row start -->
                        <div class="rows droprow ghostrow"
                            *ngIf="checkIfColumnLockingEnabled() && !incorrectSolution && filter.rows.length">
                            <div class="flat_brick slot unUsed"
                                *ngFor="let cell of filter.rows[0].cells; trackBy: trackByCellIndex;"
                                (dragover)="ghostCellHighlight(true)" (dragleave)="ghostCellHighlight()" dndDropzone
                                [style.width]="(CELLWIDTH) + 'px'" (dndDrop)="onDrop($event,null,cell.cellIndex,true)"
                                dndDragoverClass="lvl-over">
                            </div>
                        </div>
                        <!-- Ghost Row end -->
                    </div>
                </div>
            </div>
        </div>
        <div style="clear: both;"></div>
    </div>
    <div class="zoom">
        <div class="left" [ngClass]="{'readOnly' : readOnlyModal}">
            <a id="zoomIn" #zoomIn href="javascript:void(0)" #zoomIn
                title="{{userBundle['workspace.button.tooltip.zoomIn']}}" class="zoombutton in animated bounceIn"></a>
            <a id="zoomReset" #zoomReset href="javascript:void(0)"
                title="{{userBundle['workspace.button.tooltip.defaultZoom']}}"
                class="zoombutton default animated bounceIn"></a>
            <a id="zoomOut" #zoomOut href="javascript:void(0)" #zoomOut
                title="{{userBundle['workspace.button.tooltip.zoomOut']}}" class="zoombutton out animated bounceIn"></a>
            <button type="button" class="zoombutton multidelete animated bounceIn"
                [ngClass]="{'active': allowCustomDelete}"
                title="{{userBundle['workspace.button.tooltip.multipleDelete']}}"
                (click)="toggleButtons('multiDelete')"></button>
            <a id="btnUndo" appNoDblClick href="javascript:void(0)"
                title="{{userBundle['workspace.button.tooltip.undo']}}" class="zoombutton undobutton animated bounceIn"
                (click)="undoLastStep()"
                [ngStyle]="{'cursor': filter.isPastColumnHidden ? 'not-allowed' : 'pointer'}"></a>
            <a id="btnRedo" appNoDblClick href="javascript:void(0)"
                title="{{userBundle['workspace.button.tooltip.redo']}}" class="zoombutton redobutton animated bounceIn"
                (click)="redoLastUndoneStep()"
                [ngStyle]="{'cursor': filter.isPastColumnHidden ? 'not-allowed' : 'pointer'}"></a>
            <button type="button" class="zoombutton explodebutton animated bounceIn"
                [ngClass]="{'active': allowExplode}" id="btnExplode" (click)="toggleButtons('explode')"
                title="{{userBundle['workspace.button.tooltip.explode']}}"></button>
            <a id="btnClear" href="javascript:void(0)" [title]="userBundle['workspace.button.tooltip.wiper']"
                class="zoombutton clearbutton animated bounceIn" (click)="clearWorkspace()"
                [ngStyle]="{'cursor': filter.isPastColumnHidden ? 'not-allowed' : 'pointer'}"></a>
            <a id="split" *ngIf="splitable" href="javascript:void(0)" [ngClass]="{'active': allowSplit}"
                title="{{userBundle['workspace.button.tooltip.split']}}" class="zoombutton split animated bounceIn"
                (click)="toggleButtons('split')"></a>
            <a (click)="enableProductMode()" class="zoombutton pcm animated bounceIn"
                [title]="userBundle['workspace.button.tooltip.pcm']" *ngIf="user.workspaceTabAccess.pcmMode"></a>
        </div>
    </div>
    <div class="redbutton">
        <div class="right">
            <a href="javascript:void(0)" *ngIf="uiControl.availabilityEnabled" (click)="checkAvaibility()"
                title="{{userBundle['workspace.button.tooltip.avaibilityDashboard']}}"
                class="savebutton avaibility bounceIn"></a>
            <a href="javascript:void(0)" *ngIf="user.workspaceTabAccess.downloadAdvanceAvailability"
                (click)="openAdvanceAvailability()" title="{{userBundle['workspace.button.tooltip.binocular']}}"
                class="savebutton binoculars animated bounceIn"></a>
            <a href="javascript:void(0)"
                [ngClass]="{'viewAll': !filter.isPastColumnHidden, 'hideAll': filter.isPastColumnHidden}"
                (click)="toggleVisibilityOfPastColumns()" title="{{userBundle['workspace.button.tooltip.eye']}}"
                class="savebutton animated bounceIn"></a>
            <a href="javascript:void(0)" *ngIf="user.workspaceTabAccess.downloadExport"
                title="{{userBundle['workspace.button.tooltip.downloadExcel']}}"
                (click)="isProximityExportAllowed ? showExportMenu=!showExportMenu : downloadKMLFile()"
                class="savebutton save animated bounceIn exportMenu">
                <!-- SM-8848 : Relocated the proximity report button -->
                <div class="export-menu" *ngIf="showExportMenu">
                    <div class="export-item"><a href="javascript:void(0)"
                            (click)="downloadKMLFile()">{{userBundle['workspace.button.tooltip.downloadExcel']}}</a>
                    </div>
                    <div class="export-item"><a href="javascript:void(0)"
                            (click)="downloadProximityReport()">{{userBundle['workspace.button.proximityreport'] ||
                            "Proximity Report"}}</a></div>
                </div>
            </a>
            <a href="javascript:void(0)" class="savebutton save animated bounceIn" [hidden]="true">
            </a>
            <a href="javascript:void(0)" (click)="redirectToResultPage()"
                title="{{userBundle['workspace.button.tooltip.goToResult']}}"
                class="savebutton review animated bounceIn"></a>
        </div>
    </div>

</div>
<div class="ls-kpi-summary" *ngIf="kpiData && !lsProcessCompleted">
    <div class="title-1">{{userBundle['workspace.label.fetchingCampaigns'] || 'We are fetching campaigns'}}</div>
    <div class="title-2">{{userBundle['workspace.label.kpititle'] || "VIOOH's magic at work to allocate:"}}</div>
    <div class="summary">{{kpiData.bookingsCount}} {{userBundle['workspace.label.bookings'] || 'Bookings'}}</div>
    <div class="summary">{{kpiData.framesCount}} {{userBundle['workspace.label.frames'] || 'Frames'}}</div>
    <div class="summary">{{kpiData.objectivesCount}} {{userBundle['workspace.label.objectives'] || 'Objectives'}}
    </div>
    <div class="summary">{{kpiData.audienceCount}} {{userBundle['workspace.label.audience'] || 'Audience entries'}}
    </div>
    <div class="summary-button">
        <button class="btn btn-primary" (click)="openNewTab()">
            <div class="fa fa fa-plus-circle" style="font-size: 24px;line-height: 40px;padding-right: 5px;"></div>
            <div style="float: right;" class="ng-binding">{{userBundle['workspace.label.continuework'] || 'Continue My
                Work'}}</div>
        </button>
    </div>
</div>
<div class="ls-messages" *ngIf="showLsMessages" [@animatedmessage]="showLsMessages ? 'in' : 'out'">
    {{reshuffleMessage}}
</div>
