import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../core/core.module';

import { AudienceFilterComponent } from './audience-filter/audience-filter.component';
import { FilterLayoutComponent } from './filter-layout/filter-layout.component';
import { FilterBarComponent } from './filter-bar/filter-bar.component';

import { FormatFilterComponent } from '../bricks/format/format-filter/format-filter.component';
import { ListFilterComponent } from '../bricks/list/list-filter/list-filter.component';
import { LocationFilterComponent } from '../bricks/location/location-filter/location-filter.component';
import { TagFilterComponent } from '../bricks/tag/tag-filter/tag-filter.component';

import { FilterBarService } from './filter-bar/filter-bar.service';
import { ListFilterService } from '../bricks/list/list-filter.service';
import { FilterAreaComponent } from './filter-area/filter-area.component';
import { ProximityFilterComponent } from './../bricks/proximity/proximity-filter/proximity-filter.component';
import { ProximityFilterService } from './../bricks/proximity/proximity-filter.service';
import { SotFilterComponent } from '../bricks/sot/sot-filter/sot-filter.component';
import { FacingFilterComponent } from './facing-filter/facing-filter.component';
import { FrameFilterComponent } from './frame-filter/frame-filter.component';
import { SummaryBarComponent } from './summary-bar/summary-bar.component';

import { DataShareService } from '../core/services/data-share.service';
import { AudienceBricService } from '../core/services/audience-bric.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    NgbModule,
    CoreModule.forRoot()
  ],
  declarations: [
    AudienceFilterComponent,
    FilterLayoutComponent,
    FilterBarComponent,
    FormatFilterComponent,
    ListFilterComponent,
    LocationFilterComponent,
    TagFilterComponent,
    FilterAreaComponent,
    ProximityFilterComponent,
    SotFilterComponent,
    FacingFilterComponent,
    SummaryBarComponent,
    FrameFilterComponent
  ],
  // entryComponents: [
  //   AudienceFilterComponent,
  //   FilterLayoutComponent,
  //   FilterBarComponent,
  //   FormatFilterComponent,
  //   ListFilterComponent,
  //   LocationFilterComponent,
  //   TagFilterComponent,
  //   FilterAreaComponent,
  //   ProximityFilterComponent,
  //   SotFilterComponent,
  //   FacingFilterComponent,
  //   FrameFilterComponent,
  //   SummaryBarComponent
  // ],
  exports: [
    FilterBarComponent,
    FilterAreaComponent,
    SummaryBarComponent
  ],
  providers: [
    FilterBarService,
    ListFilterService,
    ProximityFilterService,
    DataShareService,
    AudienceBricService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FilterModule { }
