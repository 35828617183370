import { FileUpload } from './multi-target.model';

export class ListDataModal extends FileUpload {
  routeFrameIds: RouteFrameIds = new RouteFrameIds();
  routeFrameArray: any[] = [];
}
export class RouteFrameIds {
  include: any[] = [];
  exclude: any[] = [];
  requestJSON?: RequestJson = new RequestJson();
}

export class RequestJson {
  exclude: any = {
    validCount: 0,
    totalCount: 0
  };
  include: any = {
    validCount: 0,
    totalCount: 0
  };
}
