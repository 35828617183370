<div class="contentarea">
  <dl class="accordion animated bounceInDown content">
    <dt (click)="hideCampaignDetails = !hideCampaignDetails">
      <span class="editable-title">
        <span class="text">{{initialConfig.userBundle['commercial.tabTitle.campaignDetails']}}
          &nbsp;-&nbsp;
        </span>
        <span class="text allow-copy"> {{commercialData?.campaignReference}}
        </span>
      </span>
      <a href="javascript:void(0)" [ngClass]="!hideCampaignDetails?'down':''" class="title">
        <span class="right">
          <span class="text">{{commercialData?.bookingStatusName}}</span>
          <span class="icon_wrapper">
            <span class="icons animated"></span>
          </span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'readOnly' : systemFlags.readOnly, 'down': !hideCampaignDetails }">
      <app-campaign-details-default *ngIf="commercialData" [commercialConfig]="commercialConfig"
      [campaignDetails]="campaignDetails" [historyROC]="historyROC">
      </app-campaign-details-default>

    </dd>
  </dl>
  <div *ngIf="commercialData">
    <div *ngFor="let booking of commercialData.bookings; trackBy: trackByIndex; let i = index">
      <dl class="accordion animated bounceInDown content">
        <dt (click)="hideBookingDetails = !hideBookingDetails">

          <a href="javascript:void(0)" [ngClass]="!hideBookingDetails?'down':''" class="title">
            <span class="editable-title">
              <span class="booking-header">{{initialConfig.userBundle['commercial.tabTitle.bookingDetails']}} -
                {{booking.bookingHeader}}</span>
            </span>
            <span class="right">
              <span class="footer-message" *ngIf="booking.message"><em>{{booking.message}}</em></span>
              <span class="icon_wrapper"><span class="icons animated"></span>
              </span>
            </span>
          </a>
        </dt>
        <dd [ngClass]="{'readOnly' : systemFlags.readOnly, 'down': !hideBookingDetails }">
          <app-booking-detail-default [rowIndex]="i" [bookings]="commercialData.bookings" [commercialConfig]="commercialConfig"
          [bookingStatusId] = "bookingStatusId"   [campaignDetails]="campaignDetails">
          </app-booking-detail-default>
        </dd>
      </dl>
    </div>
  </div>

  <app-commercial-buttons *ngIf="commercialData" [commercialConfig]="commercialConfig"
    [campaignDetails]="campaignDetails" [validateFn]="validateCommercialCampaign"
    [populateRequestJson]="createRequestJSONForCommercial" (bookCampaign)="onBookCampaign($event)">
  </app-commercial-buttons>

</div>
