import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { LocaleData } from '../../../../../core/utils/LocaleData';

@Component({
  selector: 'app-ag-text-cell-editor',
  templateUrl: './ag-text-cell-editor.component.html'
})
export class AgTextCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('input', { read: ViewContainerRef }) public input;
  private params: any;
  public value: string;
  public isParent: boolean;
  private cancelBeforeStart = false;
  public validation = {
    maxLength: 2000,
    onlyNumber: false,
    decimalPoints: 0,
    minValue: null,
    maxValue: null,
    decimalSep: LocaleData.NUM_DECIMAL_SEP,
    thousandSep: LocaleData.NUM_GROUP_SEP,
    allowComma: false,
    allowNegetiveIntegers: false,
    allowReplaceDecimalSeparator: false // Specific comma input in Germany
  };
  public cellDisable = false;

  constructor() {}

  agInit(params: any): void {
    this.params = params;
    this.validation.allowReplaceDecimalSeparator = this.params.allowReplaceDecimalSeparator || false;
    if (typeof this.params.value === 'function') {
      this.value = this.params.value(this.params.node.data);
    } else if (typeof this.params.value !== 'string' && LocaleData.NUM_DECIMAL_SEP !== '.' && this.params.onlyNumber && this.validation.allowReplaceDecimalSeparator) {
      this.value = String(this.params.value).replace('.', LocaleData.NUM_DECIMAL_SEP);
    } else {
      this.value = this.params.value;
    }

    this.isParent = this.params.node.data.isParent;

    this.validation.onlyNumber = this.params.onlyNumber;
    this.validation.decimalPoints = this.params.decimalPoints;
    this.validation.minValue = this.params.minValue;
    this.validation.maxValue = this.params.maxValue;
    if (this.params.allowComma) {
      this.validation.allowComma = this.params.allowComma;
    }
    if (this.params.allowNegetiveIntegers) {
      this.validation.allowNegetiveIntegers = this.params.allowNegetiveIntegers;
    }

    if (this.params.decimalSep) {
      this.validation.decimalSep = this.params.decimalSep;
    }

    this.setThousandSep();

    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart = this.isParent ||
      (this.params.node.rowPinned === 'bottom' && this.params.rowIndex === 1) ||
      (params.charPress && ('1234567890'.indexOf(params.charPress) < 0));

    if (this.cancelBeforeStart === false && this.params.cellEditable) {
      this.cancelBeforeStart = !this.params.cellEditable(this.params.node.data);
    }

    if (this.params.cellDisable) {
      this.cellDisable = this.params.cellDisable(this.params);
    }
  }

  setThousandSep() {
    if (this.params.thousandSep && !this.params.onlyNumber) {
      this.validation.thousandSep = this.params.thousandSep;
    }
  }

  getValue(): any {
    return this.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
    return false;
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    // Moved to this event as ViewChiled was not getting initialized in agInIt() //
    if (this.params.maxLength) {
      this.validation.maxLength = this.params.maxLength;
      this.input.element.nativeElement.setAttribute('maxlength', this.params.maxLength);
    }
    if (this.params.column.isCellEditable(this.params.node)) {
      setTimeout(() => {
        this.input.element.nativeElement.focus();
      }, 10);
    }
  }

  onFocusLost() {
    this.params.api.stopEditing();
  }

  onTextChange() {
    if (!this.params.column.isCellEditable(this.params.node)) {
      this.params.value = this.value;
      this.params.data[this.params.column.colId] = this.value;
    }
    if (this.params.onTextChange) {
      this.params.onTextChange(this.params);
    }
  }

  onKeyDown(event) {
    const key = event.which || event.keyCode;
    if (key === 37 || // left
      key === 39) { // right
      event.stopPropagation();
    }
  }
}
