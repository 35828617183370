<div class="width_3">
  <div class="control_group" *ngIf="!dataShareService.hideUIElements('common.ctuPersonName')">
    <label class="label">{{initialConfig.userBundle['common.ctuPersonName']}}</label>
    <div class='gcontainer'>
      <app-sb-select [allowClear]="true" [items]="ctuPersonList" [currentItem]="selectedCtuPerson"
        [lookupUrl]="ctuPersonURL" [minLength]="2" (onItemSelected)="onCtuPersonSelected($event)" (onItemRemoved)="removeSelectedCtuPerson()"
        (responseData)="getCtuPersonResponse($event)" [placeholder]="initialConfig.userBundle['common.lookup.defaultOption']"
        [extraParams]="ctuPersonSearchParams" [searchKeyParamName]="'ctuPersonName'" [appendSearchKeyParamNameIn]="'data'">
      </app-sb-select>
    </div>
  </div>
  <div class="control_group">
    <label class="label">{{initialConfig.userBundle['common.buyerContactName']}}</label>
    <div class='gcontainer'>
      <app-sb-select id="buyercontact" [allowClear]="true" [items]="buyerContactList" [currentItem]="selectedBuyerContact"
        [lookupUrl]="buyerContactURL" [minLength]="2" (onItemSelected)="onbuyerContactSelected($event)" (onItemRemoved)="removeSelectedbuyerContact()"
        (responseData)="getBuyerContactResponse($event)" [placeholder]="initialConfig.userBundle['common.lookup.defaultOption']"
        [extraParams]="buyerContactSearchParams" [searchKeyParamName]="'buyerContactName'" [appendSearchKeyParamNameIn]="'data'">
      </app-sb-select>
    </div>
  </div>
  <div class="control_group">
    <label class="label datelabel">
      {{initialConfig.userBundle['commercial.label.bookingStart']}}
    </label>
    <input type="text" [disabled]="true" [ngModel]="booking.startDate | dateFormat : displayDateFormat" class="form_control">
  </div>
</div>
<div class="width_3">
  <div class="control_group">
    <label class="label" [ngClass]="{'required' : (booking.adminPersonId == null || booking.adminPersonId.toString() =='')} ">
      {{initialConfig.userBundle['common.adminPersonName']}}
    </label>
    <div class='gcontainer'>
      <app-sb-select id="adminperson" [allowClear]="true" [items]="adminPersonList" [currentItem]="selectedAdminPerson"
        [lookupUrl]="adminPersonURL" [minLength]="2" (onItemSelected)="onAdminPersonSelected($event)" (onItemRemoved)="removeSelectedAdminPerson()"
        (responseData)="getAdminPersonResponse($event)" [placeholder]="initialConfig.userBundle['common.lookup.defaultOption']"
        [extraParams]="adminPersonSearchParams" [searchKeyParamName]="'adminPersonName'" [appendSearchKeyParamNameIn]="'data'">
      </app-sb-select>
    </div>
  </div>
  <div class="control_group">
    <label class="label" [ngClass]="{'required' : (booking.salesPersonId == null || booking.salesPersonId.toString() =='')} ">
      {{initialConfig.userBundle['common.salesPersonName']}}
    </label>
    <div class='gcontainer'>
      <app-sb-select id="salesperson" [allowClear]="true" [items]="salesPersonList" [currentItem]="selectedSalesPerson"
        [lookupUrl]="salesPersonURL" [minLength]="2" (onItemSelected)="onSalesPersonSelected($event)" (onItemRemoved)="removeSelectedSalesPerson()"
        (responseData)="getSalesPersonResponse($event)" [placeholder]="initialConfig.userBundle['common.lookup.defaultOption']"
        [extraParams]="salesPersonSearchParams" [searchKeyParamName]="'salesPersonName'" [appendSearchKeyParamNameIn]="'data'">
      </app-sb-select>
    </div>
  </div>
  <div class="control_group">
    <label class="label datelabel">
      {{initialConfig.userBundle['commercial.label.bookingEnd']}}
    </label>
    <input type="text" [disabled]="true" [ngModel]="booking.endDate | dateFormat : displayDateFormat" class="form_control col-md-3">
  </div>
</div>
<div class="width_3">
  <div class="control_group">
    <label class="label">{{initialConfig.userBundle['commercial.label.productionCost']}}</label>
    <input type="text" [(ngModel)]="booking.productionCost" class="form_control" [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" />
  </div>
  <div class="control_group">
    <label class="label">{{initialConfig.userBundle['commercial.label.discount']}}</label>
    <input type="text" [(ngModel)]="booking.discount" class="form_control" [imask]="percentageInputMask" [unmask]="maskService.unmask.typed" />
  </div>
  <div class="control_group">
    <label class="label">{{initialConfig.userBundle['commercial.label.mountingCost']}}</label>
    <input type="text" [(ngModel)]="booking.mountingCost" class="form_control" [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" />
  </div>
</div>
<div class="width_8">
  <div class="control_group">
    <label class="label">{{initialConfig.userBundle['common.comments']}}</label>
    <textarea id="comments" class="form_control customtextarea-comments" [(ngModel)]="booking.comments" maxlength="2000" cols="30"
      rows="1"></textarea>
  </div>
</div>
<div class="width_3">
  <div class="control_group">
    <label class="label">{{initialConfig.userBundle['common.productionTypeName']}}</label>
    <select id="Select1" class="form_control" [(ngModel)]="booking.productionTypeId">
      <option value="-1" selected>{{initialConfig.userBundle['common.lookup.defaultOption']}}</option>
      <option *ngFor="let item of productionType; trackBy: trackByProductionType;" [value]="item.productionTypeId">
        {{item.productionTypeName}}
      </option>
    </select>
  </div>
</div>
<label class="subtitles">{{initialConfig.userBundle['commercial.title.financialSummary']}}</label>
<div class="width_3">
  <div class="control_group">
    <label class="label">{{initialConfig.userBundle['common.grossValue']}}</label>
    <input type="text" class="form_control" [(ngModel)]="booking.grossMediaValue" (change)="manageCalculations()"
      [imask]="currencyInputMask" [unmask]="maskService.unmask.typed"/>
  </div>
</div>
<div class="width_3">
  <div class="control_group">
    <label class="label">{{initialConfig.userBundle['common.netValue']}}</label>
    <input type="text" class="form_control" [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" [disabled]="true"
      [(ngModel)]="booking.netMediaValue" />
  </div>
</div>
<div class="width_3" *ngIf="!initialConfig.uiControl.hidePrice">
  <div class="control_group">
    <label class="label">{{initialConfig.userBundle['common.smartBricsValue']}}</label>
    <input id="smartBricsValue" type="text" [(ngModel)]="booking.smartBricsValue" [disabled]="true" class="form_control" maxlen="9" [imask]="currencyInputMask"
      [unmask]="maskService.unmask.typed" />
  </div>
</div>
<div *ngIf="!booking.hideMediaControl">
  <label class="subtitles">{{initialConfig.userBundle['commercial.title.mediaInformation']}}</label>
  <div class="width_3">
    <div class="control_group">
      <label class="label">{{initialConfig.userBundle['commercial.label.mediaLength']}}</label>
      <input id="mediaLength" type="text" [(ngModel)]="booking.mediaLength" class="form_control"
        [imask]="mediaLengthInputMask" [unmask]="maskService.unmask.typed" />
    </div>
  </div>
</div>
