import {
  InitialConfigModel,
  TagGroupModel,
  TagModel,
  NetworkModel,
  DayPartGroup,
  PoiType,
  MediaTypeModel,
  ProductGroup,
  Product,
  UiControl,
} from '../models/initial-config.model';
import { ColumnConfig, ColumnConfigs } from '../models/MapData.model';
import { ListDataModal } from '../models/List.model';
import {
  SOTBricSelectedDataModel,
  InchargeBricSelectedDataModel,
  AllSelection,
} from '../models/MapDataRequest.model';
import { AudienceCategoryGroup } from '../models/audience-filter.model';
import { FileUpload } from '../models/multi-target.model';
import {
  LookupData,
  LookupColumn,
  LookupDataList,
} from '../models/lookup.model';
import {
  PostcodeSelection,
  PointOfInterest,
  PointsSelection,
  POIAttributes,
} from '../models/workspace/proximity-window';
import * as _ from 'lodash';
import { TagCommon } from './../bricks/tag/tag-common';
import { PatternCommon } from './../bricks/pattern/pattern-common';
import { PatternCell } from './../../app/models/workspace/pattern-window';
import { ProductCatalogue } from './../../app/models/workspace/product-catalogue';
import { DynamicKeyObjectData } from '../models/Dynamic.model';
import { GLOBAL } from '../core/utils/app.constant';
import { SharedService } from '../core/services/shared.service';
import { DistanceUnit, ProximityTypeSelection } from '../core/enum';
import { SelectionFlags } from '../models/request-params';
import { ATTRIBUTE_IDS } from '../bricks/proximity/attribute-ids';
import * as moment from 'moment';

/**
 * @description populate selected values from request json
 * mostle use in case of load campaign where we will have json and
 * populate selected value using bric json
 * @author Alkesh Shah
 * @export
 * @class PopulateSelected
 */
export class PopulateSelected {
  /**
   * @description get bric lookup column data
   * @author Alkesh Shah
   * @private
   * @param {number} brickId
   * @param {*} initialConfig
   * @returns
   * @memberof PopulateSelected
   */
  private getLookupColumnForBricId(
    brickId: number,
    initialConfig: InitialConfigModel
  ): LookupColumn {
    const bricLookupCol: LookupColumn[] = initialConfig.lookupColumn.filter(
      (col: LookupColumn) => col.brickId === brickId
    );
    return bricLookupCol[0];
  }

  private fillCartographyBricsData(
    selectionId: number,
    subValue: object,
    lookupData: LookupData[],
    displaycode: boolean,
    relativeFilters: DynamicKeyObjectData<object>
  ) {
    let tempobj: LookupDataList[] = [];
    const temparr = [];
    for (let i = 0; i < lookupData.length; i++) {
      if (lookupData[i].selectionId === selectionId) {
        tempobj = lookupData[i].data;
        if (displaycode) {
          tempobj.forEach((obj) => (obj.displayCode = true));
        }
        break;
      }
    }

    const subKeys = subValue ? Object.keys(subValue) : [];
    if (subKeys.length > 0) {
      for (let i = 0; i < subKeys.length; i++) {
        for (let j = 0; j < tempobj.length; j++) {
          let finalKey = null;
          if (typeof subKeys[i] === 'string') {
            finalKey = parseInt(subKeys[i], 10);
          } else {
            finalKey = subValue[subKeys[i]];
          }

          if (finalKey === tempobj[j].id) {
            temparr.push({
              ...tempobj[j],
              disabled: false,
              relative:
                relativeFilters[selectionId] &&
                relativeFilters[selectionId].hasOwnProperty(finalKey),
            });
          }
        }
      }
    }
    return temparr;
  }

  /**
   * @description get bric lookup column data
   * @author Darshan Vachhani
   * @private
   * @param {number} id - lookupId
   * @param {*} subValue - lookup object
   * @param {*} lookupData
   * @returns
   * @memberof PopulateSelected
   */
  private fillLocationBricSectorDistrict(
    id: number,
    subValue: object,
    lookupData: any,
    relativeFilters: DynamicKeyObjectData<object>
  ) {
    let tempobj = {};
    const temparr = [];

    _.each(lookupData, (obj, index) => {
      if (Number(id) === Number(index)) {
        tempobj = obj;
        return;
      }
    });

    // SBRICS-2315 : Yuvraj Chauhan : 07/03/2017
    if (!_.isEmpty(subValue)) {
      _.each(subValue, (val, key) => {
        _.each(tempobj, (value, idx) => {
          let finalKey = null;
          if (typeof key === 'string') {
            finalKey = Number(key);
          } else {
            finalKey = val;
          }
          if (finalKey === Number(idx)) {
            temparr.push({
              lookupId: Number(idx),
              lookupName: value,
              relative:
                relativeFilters[id] &&
                relativeFilters[id].hasOwnProperty(finalKey),
            });
            return;
          }
        });
      });
    }
    return temparr;
  }

  /**
   * @description populate incharge bric selected values using request json
   * @author Alkesh Shah
   * @param {*} requestJson - incharge bric request json object
   * @returns
   * @memberof PopulateSelected
   */
  public getInchargeSelectedValue(
    requestJson: any
  ): InchargeBricSelectedDataModel | AllSelection {
    if (Object.keys(requestJson).length === 0) {
      return _.cloneDeep(GLOBAL.allSelection);
    }

    return {
      endDate: new Date(requestJson.endDate),
      startDate: new Date(requestJson.startDate),
      current: requestJson.current,
      futureSplitFrom: requestJson.futureSplitFrom
        ? new Date(requestJson.futureSplitFrom)
        : null,
      futureSplitInterval: requestJson.futureSplitInterval,
      defaultWeekDay:
        requestJson.defaultWeekDay || requestJson.defaultWeekDay === 0
          ? requestJson.defaultWeekDay
          : -1,
      defaultValue: requestJson.defaultValue
        ? requestJson.defaultValue.value
        : undefined,
      defaultValueUnit: requestJson.defaultValue
        ? requestJson.defaultValue.unit
        : undefined,
      minValue: requestJson.defaultValue
        ? requestJson.minValue.value
        : undefined,
      minValueUnit: requestJson.defaultValue
        ? requestJson.minValue.unit
        : undefined,
    };
  }

  /**
   * @description populate media bric selected values using request json
   * @author Alkesh Shah
   * @param {*} requestJson
   * @param {*} mediaTypeList
   * @returns
   * @memberof PopulateSelected
   */
  public getMediaSelectedValue(
    requestJson: any,
    mediaTypeList: MediaTypeModel[]
  ) {
    let selectedmediaObj = null;

    const selectedMediaIds = Object.keys(requestJson['22']);
    const selectedObj = mediaTypeList.filter(
      (media) => selectedMediaIds.indexOf(media.mediaTypeId.toString()) > -1
    );
    selectedmediaObj = {
      selectedMedia: selectedObj[0].mediaTypeId.toString(),
      selectedMediaObject: selectedObj[0],
    };
    // TODO: Need to remove the redundant property 'selectedMedia' as we are passing whole object in key 'selectedMediaObject'
    return selectedmediaObj;
  }

  /**
   * @description populate environment bric selected values using request json
   * @author Alkesh Shah
   * @param {*} requestJson
   * @param {number} bricId
   * @param {InitialConfigModel} initialConfig
   * @returns
   * @memberof PopulateSelected
   */
  public getEnvironmentSelectedValue(
    requestJson: any,
    bricId: number,
    initialConfig: InitialConfigModel
  ) {
    const { selection: environmentRequestJson, relativeFilters } =
      this.getSelectionFilters(requestJson);

    const channelObj = {};
    const channels = this.getLookupColumnForBricId(bricId, initialConfig);
    const reqKeys = Object.keys(environmentRequestJson);
    for (const reqKey of reqKeys) {
      for (const lookup of channels.lookup) {
        if (parseInt(reqKey, 10) === lookup.selectionId) {
          channelObj[lookup.selectionId] =
            this.fillCartographyBricsData(
              lookup.selectionId,
              environmentRequestJson[reqKey],
              initialConfig.lookupData,
              lookup.displayCode,
              relativeFilters
            );
          channelObj[lookup.selectionId].forEach((item) => {
            item.disabled = false;
          });
        } else {
          if (!channelObj[lookup.selectionId]) {
            channelObj[lookup.selectionId] = [];
          }
        }
      }
    }
    return channelObj;
  }

  /**
   * Method populates the selectedValues object from requestJSON
   *
   * @param {*} requestJson
   * @param {InitialConfigModel} initialConfig
   * @returns
   * @memberof PopulateSelected
   */
  public getProductSelectedValue(
    requestJson: any,
    initialConfig: InitialConfigModel
  ): ProductCatalogue {
    const productCatalogueId = requestJson ? requestJson.productCatalogueId : 0;
    const productObject =
      initialConfig.productCatalogueHolder.productCatalogue.filter(
        (product) => {
          return product.idProductCatalogue === productCatalogueId;
        }
      );
    return productObject[0];
  }

  /**
   * Method populates the selectedValues object from requestJSON
   *
   * @param {*} requestJson
   * @param {InitialConfigModel} initialConfig
   * @returns
   * @memberof PopulateSelected
   */
  public getChannelSelectedValue(
    requestJson: any,
    initialConfig: InitialConfigModel
  ) {
    if (requestJson[0][1].hasOwnProperty('-99')) {
      return _.cloneDeep(GLOBAL.allSelection);
    } else {
      const selected = {};

      requestJson.forEach((element) => {
        const [selectionFlags, selectionIds] = element;
        const selectedProducts = [];

        _.cloneDeep(initialConfig.productGroup).forEach(
          (productGroup: ProductGroup) => {
            Object.keys(selectionIds).forEach((key) => {
              selectedProducts.push(
                ...this.iterateOverProducts(
                  key,
                  productGroup.product,
                  selectionFlags
                )
              );
            });
          }
        );

        selected[selectionFlags.selectionId] = selected[
          selectionFlags.selectionId
        ]
          ? [...selected[selectionFlags.selectionId], ...selectedProducts]
          : selectedProducts;
      });

      return selected;
    }
  }

  /**
   * Iterates over nested products to get the list of selected products
   *
   * @param {string} key
   * @param {Product[]} products
   * @param {SelectionFlags} selectionFlags
   * @returns {Product[]}
   * @memberof PopulateSelected
   */
  iterateOverProducts(
    key: string,
    products: Product[],
    selectionFlags: SelectionFlags
  ): Product[] {
    const selectedProducts: Product[] = [];
    products.forEach((product) => {
      if (product && product.productId === parseInt(key, 10)) {
        product.relative = selectionFlags.relative;
        selectedProducts.push(product);
      } else {
        if (product.hasOwnProperty('product')) {
          selectedProducts.push(
            ...this.iterateOverProducts(key, product.product, selectionFlags)
          );
        }
      }
    });
    return selectedProducts;
  }

  /**
   * @description Populate SOT bric selected values using request json
   * @author Shivani Patel
   * @param {*} requestJson
   * @param {*} productMode
   * @param {InitialConfigModel} initialConfig
   * @returns
   * @memberof PopulateSelected
   */
  public getSotSelectedValue(
    requestJson: SOTBricSelectedDataModel,
    productMode: boolean,
    initialConfig: InitialConfigModel,
    enabledPCM?
  ) {
    const selectionSot: SOTBricSelectedDataModel = _.clone(requestJson);

    let sotObj = {};
    if (selectionSot) {
      if (!productMode && Object.keys(selectionSot).length === 0) {
        // We are sending null value for mixed and so not getting any propery under selectionSot backend
        // selectionSot will be empty when loading product with AllSelection
        // So added a dirty patch to set sot value as -1 so that next logic can work as expected
        selectionSot.sot = -1;
      }
      if (
        selectionSot.hasOwnProperty('-99') ||
        !Object.keys(selectionSot).length
      ) {
        sotObj = _.cloneDeep(GLOBAL.allSelection);
      } else {
        if (productMode || enabledPCM) {
          if (selectionSot.hasOwnProperty('def')) {
            selectionSot.sot = selectionSot.def;
            selectionSot.increment = selectionSot.increment;
            selectionSot.min = selectionSot.min;
            selectionSot.max = selectionSot.max;
            sotObj = selectionSot;
          }
        } else {
          let shareOfTime: SOTBricSelectedDataModel = {};
          if (selectionSot.framesSlots) {
            // SM-4841
            shareOfTime = {
              framesSlots: selectionSot.framesSlots,
              sotType: '1',
              maxFramesSlotsText: '',
              maxFramesSlots: 1,
            };
          } else {
            if (initialConfig.uiControl.sotFrequencyEnabled) {
              shareOfTime = {
                dummySot: parseFloat(Number(selectionSot.sot).toFixed(2)), // SBRICS-2919
                frequency: selectionSot.frequency,
                framesSlots: selectionSot.framesSlots || 1, // SM-5755
                sot: parseFloat(Number(selectionSot.sot).toFixed(2)),
                commercialSOT: parseFloat(
                  Number(selectionSot.commercialSOT).toFixed(2)
                ),
              };
            } else {
              shareOfTime = {
                sot: Number(selectionSot.sot).toFixed(2), // SBRICS-2919
              };
            }
            shareOfTime.sotType = '0';
          }
          // added OR condition as above code converts -1 to '-1.00'
          if (shareOfTime.sot === -1 || shareOfTime.sot === '-1.00') {
            shareOfTime.sot = 'Mixed';
            if (initialConfig.uiControl.sotFrequencyEnabled) {
              shareOfTime.dummySot = 'Mixed';
            }
            sotObj = shareOfTime;
          } else {
            sotObj = shareOfTime;
          }
        }
      }
    }
    return sotObj;
  }

  /**
   * @description populate format bric selected values using request json
   * @author Darshan Vachhani
   * @param {*} requestJson
   * @param {number} bricId
   * @param {InitialConfigModel} initialConfig
   * @returns
   * @memberof PopulateSelected
   */
  public getFormatSelectedValue(
    cellData: any,
    bricId: number,
    initialConfig: InitialConfigModel
  ) {
    try {
      let formatobj = {};
      const selectionCriteriaFormat = cellData['selectionCriteriaFormat'];
      const { selection: formatRequestJson, relativeFilters } =
        this.getSelectionFilters(selectionCriteriaFormat);

      if (formatRequestJson && formatRequestJson.hasOwnProperty('-99')) {
        formatobj = _.cloneDeep(GLOBAL.allSelection);
      } else {
        const lookups = this.getLookupColumnForBricId(bricId, initialConfig);
        const lookupData = lookups.lookup;
        const reqKeys = Object.keys(formatRequestJson);
        for (let i = 0; i < reqKeys.length; i++) {
          for (let j = 0; j < lookupData.length; j++) {
            if (parseInt(reqKeys[i], 10) === lookupData[j].selectionId) {
              formatobj[lookupData[j].selectionId] =
                this.fillCartographyBricsData(
                  lookupData[j].selectionId,
                  formatRequestJson[reqKeys[i]],
                  initialConfig.lookupData,
                  lookupData[j].displayCode,
                  relativeFilters
                );
            } else {
              if (!formatobj[lookupData[j].selectionId]) {
                formatobj[lookupData[j].selectionId] = [];
              }
            }
          }
        }

        const restrictionJson = cellData.selectionCriteriaRestriction;
        const selectionCriteriaRestriction = {
          '1': false, // Dominate Furniture
          '2': false, // Back to Back Panels
          '3': false // parallel panels
        };

        if (restrictionJson) {
          const reqKeys = Object.keys(restrictionJson);
          if (reqKeys.length > 0) {
            reqKeys.forEach((key) => {
              selectionCriteriaRestriction[key] = restrictionJson[key];
            });
          }
        }

        formatobj['selectionCriteriaRestriction'] =
          selectionCriteriaRestriction;
      }
      return formatobj;
    } catch (err) {
      console.log('error in getFormatSelectedValue');
    }
  }

  /**
   * @description populate SecondaryAudience Seelcted values using request json
   * @author Kishan Patel
   * @param {*} requestJson - SecondaryAudience bric request json object
   * @returns
   * @memberof PopulateSelected
   */
  public getSecondaryAudienceSelectedValue(
    requestJson: any,
    audienceCategoryGroupId: number,
    initialConfig: InitialConfigModel
  ) {
    let selected = null;
    if (
      (Object.keys(requestJson).length === 0 ||
        Object.keys(requestJson.secondaryAudience).length === 1) &&
      requestJson.secondaryAudience.hasOwnProperty('audienceCategoryGroupId')
    ) {
      selected = _.cloneDeep(GLOBAL.allSelection);
    } else {
      selected = this.generateSelectedObjectAudience(
        requestJson.secondaryAudience.audienceCategoryId,
        audienceCategoryGroupId,
        initialConfig.audienceCategoryGroup
      );
    }
    return selected;
  }

  /**
   * @description populate SelectionAudience Seelcted values using request json
   * @author Kishan Patel
   * @param {*} requestJson - SelectionAudience bric request json object
   * @returns
   * @memberof PopulateSelected
   */
  public getSelectionAudienceSelectedValue(
    requestJson: any,
    audienceCategoryGroupId: number,
    initialConfig: InitialConfigModel
  ) {
    let selected = null;
    if (
      Object.keys(requestJson.selectionAudience).length === 0 ||
      (Object.keys(requestJson.selectionAudience).length === 1 &&
        requestJson.selectionAudience.hasOwnProperty('audienceCategoryGroupId'))
    ) {
      selected = _.cloneDeep(GLOBAL.allSelection);
    } else {
      selected = this.generateSelectedObjectAudience(
        requestJson.selectionAudience.audienceCategoryId,
        audienceCategoryGroupId,
        initialConfig.audienceCategoryGroup
      );
    }
    return selected;
  }

  public getPrimaryAudienceSelectedValue(
    cellData: any,
    audienceCategoryGroupId: number,
    audienceCategoryGroup: AudienceCategoryGroup[]
  ) {
    if (Object.keys(cellData.selectionPrimaryAudiences).length === 0) {
      return _.cloneDeep(GLOBAL.allSelection);
    } else {
      const audienceCategoryGroupLocal = _.cloneDeep(audienceCategoryGroup);
      const selectedAudienceTypes = {};
      for (const audienceCategoryGroup of audienceCategoryGroupLocal) {
        if (
          audienceCategoryGroup.audienceCategoryGroupId ===
          audienceCategoryGroupId
        ) {
          for (const audienceCategoryType of audienceCategoryGroup.audienceCategoryType) {
            if (
              Object.keys(cellData.selectionPrimaryAudiences).indexOf(
                String(audienceCategoryType.audienceCategoryTypeId)
              ) > -1
            ) {
              selectedAudienceTypes[
                audienceCategoryType.audienceCategoryTypeId
              ] = [];
              for (const audienceCategory of audienceCategoryType.audienceCategory) {
                if (
                  cellData.selectionPrimaryAudiences[
                    audienceCategoryType.audienceCategoryTypeId
                  ].indexOf(audienceCategory.audienceCategoryId) > -1
                ) {
                  audienceCategory.selected = true;
                  selectedAudienceTypes[
                    audienceCategoryType.audienceCategoryTypeId
                  ].push(audienceCategory);
                }
              }
            }
          }
        }
      }
      return selectedAudienceTypes;
    }
  }

  /**
   * @description populate generate selected volume
   * @author Kishan Patel
   * @param requestJson
   */
  public getSelectionVolumeSelectedValue(requestJson: any) {
    if (
      Object.keys(requestJson).length === 0 ||
      (Object.keys(requestJson).length === 1 &&
        requestJson.hasOwnProperty('allocateAllFrames')) ||
      requestJson.isAllSelection
    ) {
      return _.cloneDeep(GLOBAL.allSelection);
    } else {
      return requestJson;
    }
  }

  /**
   * @description populate generate selected budget
   * @author Amit Mahida
   * @param {*} requestJson
   * @returns
   * @memberof PopulateSelected
   */
  public getSelectionBudgetSelectedValue(requestJson: any) {
    if (
      Object.keys(requestJson).length === 0 ||
      (Object.keys(requestJson).length === 1 &&
        requestJson.hasOwnProperty('allocateAllFrames')) ||
      requestJson.isAllSelection
    ) {
      return _.cloneDeep(GLOBAL.allSelection);
    } else {
      return requestJson;
    }
  }

  /**
   * @description populate generate selected Audience
   * @author Kishan Patel
   * @param audienceCategoryId
   * @param audienceCategoryGroupId - selected audience Category GroupId
   * @param audienceCategoryGroup  - From Initial Config
   */
  generateSelectedObjectAudience(
    audienceCategoryId,
    audienceCategoryGroupId,
    audienceCategoryGroup
  ) {
    const audienceCategoryGroupObject = _.cloneDeep(audienceCategoryGroup);
    for (let i = 0; i < audienceCategoryGroupObject.length; i++) {
      if (
        audienceCategoryGroupObject[i].audienceCategoryGroupId ===
        audienceCategoryGroupId
      ) {
        for (
          let j = 0;
          j < audienceCategoryGroupObject[i].audienceCategoryType.length;
          j++
        ) {
          for (
            let k = 0;
            k <
            audienceCategoryGroupObject[i].audienceCategoryType[j]
              .audienceCategory.length;
            k++
          ) {
            if (audienceCategoryGroupObject[i].options.DisplayFormat === '3') {
              const matchingCategory = audienceCategoryGroupObject[
                i
              ].audienceCategoryType[j].audienceCategory[
                k
              ].audienceCategory.filter(
                (a) => a.audienceCategoryId === audienceCategoryId
              );
              if (matchingCategory.length > 0) {
                matchingCategory[0]['audienceCategoryTypeCode'] =
                  audienceCategoryGroupObject[i].audienceCategoryType[j][
                  'audienceCategoryTypeCode'
                  ];
                return matchingCategory[0];
              }
            } else if (
              audienceCategoryGroupObject[i].audienceCategoryType[j]
                .audienceCategory[k].audienceCategoryId === audienceCategoryId
            ) {
              audienceCategoryGroupObject[i].audienceCategoryType[
                j
              ].audienceCategory[k]['audienceCategoryTypeCode'] =
                audienceCategoryGroupObject[i].audienceCategoryType[j][
                'audienceCategoryTypeCode'
                ];
              return audienceCategoryGroupObject[i].audienceCategoryType[j]
                .audienceCategory[k];
            }
          }
        }
      }
    }
  }

  /**
   * @description
   * @author Kishan Patel
   * @param {*} requestJson
   * @param {InitialConfigModel} initialConfig
   * @returns  proximity Selected Value
   * @memberof PopulateSelected
   */
  public getSelectionProximitySelectedValue(
    requestJson: any,
    poiTypes: PoiType[],
    uiControl
  ) {
    if (
      requestJson.selectionProximity &&
      requestJson.selectionProximity.userSelectionIds.indexOf(-99) > -1
    ) {
      return _.cloneDeep(GLOBAL.allSelection);
    } else if (
      requestJson.selectionProximity &&
      requestJson.selectionProximity.userSelectionIds.length > 0
    ) {
      const defaultPostcode: PostcodeSelection = new PostcodeSelection();
      const defaultPoints: PointsSelection = new PointsSelection();
      const defaultPoi: PointOfInterest = new PointOfInterest();

      if (requestJson.selectionProximity.displayData) {
        return this.getProximitySelectedValueByDisplayData(
          requestJson,
          defaultPostcode,
          defaultPoi,
          defaultPoints,
          poiTypes,
          uiControl
        );
      }
    }
  }

  /**
   * @description find selected value from displat Data object
   * @author Kishan Patel, Amit Mahida, Nishit [refactoring and SM-5761]
   * @param {*} requestJson
   * @param {*} defaultPostcode
   * @param {*} defaultPoi
   * @param {*} poiTypes
   * @param {*} defaultPoints
   * @returns selected value of Proximity Bric
   * @memberof PopulateSelected
   */
  getProximitySelectedValueByDisplayData(
    requestJson: any,
    defaultPostcode: PostcodeSelection,
    defaultPoi: PointOfInterest,
    defaultPoints: PointsSelection,
    poiTypes: PoiType[],
    uiControl: UiControl
  ) {
    const selected = {};
    let postcodeSelection = null;
    let points = null;
    let pointOfInterest = null;
    let selectedPOIType = uiControl.defaultPOITypeId;
    let objSelectedPOIType = null;
    _.each(requestJson.selectionProximity.displayData, (obj, id) => {
      if (obj.poiTypeId) {
        selectedPOIType = obj.poiTypeId;
        objSelectedPOIType = poiTypes.find(e => e.poiTypeId === selectedPOIType);
      }      
      for (
        let i = 0;
        i < requestJson.selectionProximity.userSelectionIds.length;
        i++
      ) {
        if (
          id === requestJson.selectionProximity.userSelectionIds[i].toString()
        ) {
          if (
            Number(obj['proximityType']) === ProximityTypeSelection.PostCode
          ) {
            postcodeSelection = {
              listUploadSelection: obj['postcode'] ? false : true,
              listUpload:
                obj['validCount'] && obj['userSelectionName']
                  ? [
                    {
                      validCount: obj['validCount'],
                      userSelectionName: obj['userSelectionName'],
                    },
                  ]
                  : [],
              selectedModelValues: obj['postcode'] ? obj['postcode'] : [],
              distance: {
                distancevalue: obj['distance'],
                distanceType: obj['unit'] || DistanceUnit.Meters,
                olddistanceType: obj['unit'] || DistanceUnit.Meters,
              },
              include: obj['include'].toString(),
              userSelectionId: Number(id),
            };
          } else if (
            Number(obj['proximityType']) === ProximityTypeSelection.Points
          ) {
            points = {
              listUploadSelection: obj['points'] ? false : true,
              listUpload:
                obj['validCount'] && obj['userSelectionName']
                  ? [
                    {
                      validCount: obj['validCount'],
                      userSelectionName: obj['userSelectionName'],
                    },
                  ]
                  : [],
              selectedModelValues: obj['points'] ? obj['points'] : [],
              distance: {
                distancevalue: obj['distance'],
                distanceType: obj['unit'] || DistanceUnit.Meters,
                olddistanceType: obj['unit'] || DistanceUnit.Meters,
              },
              include: obj['include'].toString(),
              userSelectionId: Number(id),
            };
          } else if (
            Number(obj['proximityType']) === ProximityTypeSelection.POI
          ) {
            let _poi = poiTypes?.find((s) => s.poiTypeId == selectedPOIType);
            defaultPoi.datasource = [
              {
                poiTypeId: _poi?.poiTypeId,
                poiTypeName: _poi?.poiTypeName,
              },
            ];
            // code added for SBRICS-1441, by Nishit, dt: 11/05/2016
            let poiIds: any[] = [];
            const poiAttributes: POIAttributes[] = [];
            for (const poi of obj.poiAttributes) {
              poiAttributes.push({
                poiAttributeId: poi.poiAttributeId,
                poi: poi.pois.map((p) => p.poiId),
                poiRelative: poi.poiRelative,
              });
              if (poi.poiRelative && poi.poiRelative.length) {
                for (const p of poi.pois) {
                  if (poi.poiRelative.indexOf(p.poiId) > -1) {
                    p.relative = true;
                  }
                  switch (poi.poiAttributeId) {
                    case ATTRIBUTE_IDS.category:
                      p.id = `C_${p.poiId}`;
                      break;
                    case ATTRIBUTE_IDS.subcategory:
                      p.id = `S_${p.poiId}`;
                      break;
                    case ATTRIBUTE_IDS.brand:
                      p.id = `B_${p.poiId}`;
                      break;
                  }
                }
              }
              poiIds = [...poiIds, ...poi.pois];
            }             
            pointOfInterest = {
              poiAttributes,
              datasource: [
                {
                  poiTypeId: objSelectedPOIType.poiTypeId,
                  poiTypeName: objSelectedPOIType.poiTypeName,
                },
              ],
              selectedModelValues: poiIds.length ? poiIds : [],
              radioButtons: {
                selection: obj['satisfyAll'] ? '0' : '1',
                disabled: poiIds.length > 1 ? false : true,
              },
              distance: {
                distancevalue: obj['distance'],
                distanceType: obj['unit'] || DistanceUnit.Meters,
                olddistanceType: obj['unit'] || DistanceUnit.Meters,
              },
              include: obj['include'].toString(),
              userSelectionId: Number(id),
            };
          }
          selected['typeSelection'] = Number(obj['proximityType']);
        }
      }

      const proximityType = [
        ProximityTypeSelection.PostCode,
        ProximityTypeSelection.POI,
        ProximityTypeSelection.Points,
      ];
      const treeAttributeIds = [
        ATTRIBUTE_IDS.category,
        ATTRIBUTE_IDS.subcategory,
        ATTRIBUTE_IDS.brand,
      ];
      proximityType.forEach((e) => {
        switch (e) {
          case ProximityTypeSelection.PostCode:
            selected['postcodeSelection'] =
              postcodeSelection || defaultPostcode;
            break;
          case ProximityTypeSelection.POI:
            if (poiTypes && poiTypes.length) {
              defaultPoi.attributeData = poiTypes[0].poiAttributes;
              if (
                pointOfInterest &&
                pointOfInterest.poiAttributes &&
                pointOfInterest.poiAttributes.length
              ) {
                if (uiControl.proximityPOITree) {
                  if (
                    pointOfInterest.poiAttributes.length === 1 &&
                    pointOfInterest.poiAttributes[0].poiAttributeId ===
                    ATTRIBUTE_IDS.location
                  ) {
                    defaultPoi.selectedAttributeId = 2;
                    defaultPoi.selectedAttributeTreeId = 2;
                    defaultPoi.selectedAttribute = this.getSelectedAttributeType(
                      ATTRIBUTE_IDS.location,
                      poiTypes
                    );
                  } else {
                    defaultPoi.selectedAttributeId = 1;
                    defaultPoi.selectedAttributeTreeId = 1;
                    defaultPoi.selectedAttribute.poiAttributeId = 1;
                    defaultPoi.selectedAttribute.poiAttributeName = _.map(
                      _.filter(poiTypes[0].poiAttributes, (val: any) =>
                        treeAttributeIds.includes(val.poiAttributeId)
                      ),
                      (val: any) => val.poiAttributeName
                    ).join(' / ');
                  }
                } else {
                  defaultPoi.selectedAttributeId =
                    pointOfInterest.poiAttributes[0].poiAttributeId;
                }
              } else {
                const poiData = Object.keys(
                  requestJson.selectionProximity.displayData
                ).find(
                  (e) =>
                    requestJson.selectionProximity.displayData[e]
                      .proximityType === 1
                );
                const poiAttributeId = poiData
                  ? requestJson.selectionProximity.displayData[poiData]
                    .poiAttributeId
                  : 1;
                defaultPoi.selectedAttribute = this.getSelectedAttributeType(
                  poiAttributeId,
                  poiTypes
                );
                defaultPoi.selectedAttributeId =
                  defaultPoi.selectedAttribute.poiAttributeId;
              }
              defaultPoi.selectedDatasource = objSelectedPOIType;
              selected['pointOfInterest'] = pointOfInterest
                ? { ...defaultPoi, ...pointOfInterest }
                : defaultPoi;
            }
            break;
          case ProximityTypeSelection.Points:
            selected['points'] = points || defaultPoints;
            break;
          default:
            break;
        }
      });
    });
    return selected;
  }

  /**
   * @description selected Attribute Type from Poi attr
   * @author Kishan Patel
   * @param {*} poiAttributeId
   * @param {*} initialConfig
   * @returns
   * @memberof PopulateSelected
   */
  getSelectedAttributeType(poiAttributeId: number, poiTypes: PoiType[]) {
    let sel = null;
    if (poiAttributeId) {
      _.each(poiTypes[0].poiAttributes, (obj) => {
        if (poiAttributeId === obj['poiAttributeId']) {
          sel = obj;
        }
      });
      return sel;
    } else {
      return null;
    }
  }
  /**
   * @description Populate tag bric selected values using request json
   * @author Shivani Patel
   * @param {*} requestJson
   * @param {number} bricId
   * @param {InitialConfigModel} initialConfig
   * @returns Selected Values of Tag brick
   * @memberof PopulateSelected
   */
  public getTagSelectedValue(
    requestJson: any,
    initialConfig: InitialConfigModel
  ) {
    if (requestJson[0][1].hasOwnProperty('-99')) {
      return _.cloneDeep(GLOBAL.allSelection);
    }

    const tagObj = {
      allTabSelection: {},
      tabeverClicked: false,
      tab: 1,
    };

    if (requestJson) {
      const tagGroupsData: DynamicKeyObjectData<TagGroupModel> = _.cloneDeep(
        initialConfig.tagGroup
      );
      const tagGroup: DynamicKeyObjectData<TagGroupModel> =
        new TagCommon().addExcludeFlagKey(tagGroupsData);
      _.forEach(tagGroup, (tagGroupItem: TagGroupModel) => {
        const newTag = [];
        tagGroupItem.tag.forEach((tagItem: TagModel) => {
          requestJson.forEach((requestJsonItem) => {
            const [selectionFlags, selectionIds] = requestJsonItem;
            for (const key in selectionIds) {
              if (parseInt(key, 10) === tagItem.tagId) {
                tagItem.exclude = selectionFlags.exclude;
                tagItem.relative = selectionFlags.relative;
                newTag.push(tagItem);
              }
            }
          });
        });
        if (newTag.length) {
          tagObj.allTabSelection[tagGroupItem.tagGroupId] = newTag;
        }
      });
    }
    return tagObj;
  }

  /**
   * @description populate location bric selected values using request json
   * @author Darshan Vachhani
   * @param {*} requestJson
   * @param { number } bricId
   * @param { InitialConfigModel } initialConfig
   * @param {*} lookup *
   * @returns
   * @memberof PopulateSelected
   */
  public getLocationSelectedValue(
    requestJson: any,
    bricId: number,
    initialConfig: InitialConfigModel,
    lookUp: any,
    sharedService: SharedService,
    columnConfig
  ) {
    const { selection: locationRequestJson, relativeFilters } =
      this.getSelectionFilters(requestJson);

    let locationobj: any = {};
    if (locationRequestJson.hasOwnProperty('-99')) {
      locationobj = _.cloneDeep(GLOBAL.allSelection);
    } else {
      const locations = this.getLookupColumnForBricId(bricId, initialConfig);
      const lookUpData = locations.lookup;
      const reqKeys = Object.keys(locationRequestJson);
      for (const reqKey of reqKeys) {
        if (reqKey === 'fileList') {
          locationobj.fileList = _.clone(locationRequestJson.fileList);
        } else {
          for (const lData of lookUpData) {
            if (lData.hasOwnProperty('selectionIds')) {
              if (_.includes(_.map(lData.selectionIds, Number), Number(reqKey))) {
                const lookUpSelectionIds = lData.selectionIds;
                for (const lookUpSelectionId of lookUpSelectionIds) {
                  locationobj[lookUpSelectionId] = this.fillCartographyBricsData(lookUpSelectionId,
                    locationRequestJson[lookUpSelectionId], initialConfig.lookupData, lData.displayCode, relativeFilters);
                }
              }
            } else {
              if (Number(reqKey) === Number(lData.selectionId)) {
                switch (lData.selectionId) {
                  case 15:
                  case 16:
                  case 13:
                    // Below function should not execute if lookup data is not available
                    if (lookUp) {
                      locationobj[lData.selectionId] =
                        this.fillLocationBricSectorDistrict(
                          lData.selectionId,
                          locationRequestJson[reqKey],
                          lookUp,
                          relativeFilters,
                        );
                    } else {
                      console.log('Lookup data not found.');
                    }
                    break;
                  default:
                    locationobj[lData.selectionId] =
                      this.fillCartographyBricsData(
                        lData.selectionId,
                        locationRequestJson[reqKey],
                        initialConfig.lookupData,
                        lData.displayCode,
                        relativeFilters,
                      );
                    break;
                }
              } else {
                if (!locationobj[lData.selectionId]) {
                  locationobj[lData.selectionId] = [];
                }
              }
            }
          }
        }
      }
      if (initialConfig.uiControl.geoLocationTree) {
        let selectedIds = [];
        const config = columnConfig && columnConfig.lookupData;
        const list = sharedService.filterGeoTreeWithColumnConfig(
          initialConfig.geoLevels,
          config
        );

        Object.keys(GLOBAL.geoTree).forEach((id) => {
          const selectedIdsforlevel = locationRequestJson[id];
          selectedIds = selectedIdsforlevel
            ? selectedIds.concat(Object.keys(locationRequestJson[id]))
            : selectedIds;
        });

        if (selectedIds && selectedIds.length) {
          const locationGeoTree = sharedService.getSelectedForGeoTree(
            list,
            selectedIds,
            relativeFilters
          );
          locationobj = Object.assign(locationobj, locationGeoTree);
        }
      }
    }
    return locationobj;
  }

  /**
   * @description
   * @author Amit Mahida
   * @param {*} requestJson
   * @returns
   * @memberof PopulateSelected
   */
  public getListSelectedValue(requestJson: any) {
    if (requestJson[0].userSelectionId.toString().indexOf('-99') > -1) {
      return _.cloneDeep(GLOBAL.allSelection);
    } else {
      const listObj: ListDataModal = new ListDataModal();
      for (const obj of requestJson) {
        if (obj.hasOwnProperty('listType') && obj.listType === 0) {
          listObj.listUpload.fileList.push({
            userSelectionId: obj.userSelectionId,
            userSelectionName: obj.userSelectionName,
            exclude: obj.exclude,
            validCount: obj.validCount,
            totalCount: obj.totalCount,
            hardAllocated: obj.hardAllocated,
            swap: obj.swap,
          });
        }
        if (obj.hasOwnProperty('listType') && obj.listType === 1) {
          if (obj.hasOwnProperty('routeFrameId')) {
            if (obj.exclude) {
              listObj.routeFrameIds.requestJSON.exclude.totalCount =
                obj.totalCount;
              listObj.routeFrameIds.requestJSON.exclude.validCount =
                obj.validCount;
            } else {
              listObj.routeFrameIds.requestJSON.include.totalCount =
                obj.totalCount;
              listObj.routeFrameIds.requestJSON.include.validCount =
                obj.validCount;
            }
            for (const routeFrameId of obj.routeFrameId) {
              listObj.routeFrameArray.push({
                routeFrameId,
                userSelectionId: obj.userSelectionId,
                exclude: obj.exclude,
              });
              if (obj.exclude) {
                listObj.routeFrameIds.exclude.push({
                  routeFrameId,
                  userSelectionId: obj.userSelectionId,
                  exclude: obj.exclude,
                  validCount: obj.validCount,
                  totalCount: obj.totalCount,
                  hardAllocated: obj.hardAllocated,
                  swap: obj.swap,
                });
              } else {
                listObj.routeFrameIds.include.push({
                  routeFrameId,
                  userSelectionId: obj.userSelectionId,
                  exclude: obj.exclude,
                  validCount: obj.validCount,
                  totalCount: obj.totalCount,
                  hardAllocated: obj.hardAllocated,
                  swap: obj.swap,
                });
              }
            }
          }
        }
      }
      return listObj;
    }
  }

  /**
   * @description populate Frame bric selected values using request json
   * @author Darshan Vachhani
   * @param {*} requestJson - Frame bric request json object
   */
  getFrameSelectedValue(requestJson: any) {
    // Before LS changes, requestJson was coming {} and so was easy to identify its all selection
    // After LS changes, it has 2 extra property so creating issue at UI, fixing it by checking if frameCount is present or not
    let frameObj = {};
    if (
      requestJson.hasOwnProperty('-99') ||
      !Object.keys(requestJson).length ||
      !requestJson['frameCount']
    ) {
      frameObj = _.cloneDeep(GLOBAL.allSelection);
    } else {
      frameObj = requestJson;
    }
    return frameObj;
  }

  getPatternSelectedValue(
    requestJson: any,
    dateForPattern: any[],
    dayPartGroup: DayPartGroup,
    columnConfig: ColumnConfigs,
    columnIndex: number
  ) {
    const patternCommon = new PatternCommon();
    let patternObj = [];
    let totalColumns = null;
    let noOfRows = null;
    const selected = {
      selected: {
        row: [],
      },
      patternType: null,
      patternLength: null,
    };
    const loadedPattern = {
      startDay: null,
      endDay: null,
      totalDays: null,
      maxPossiblePatternLength: null,
      multipleRow: false,
    };

    if (
      requestJson.selectionPattern &&
      !_.isUndefined(requestJson.selectionPattern.pattern) &&
      requestJson.selectionPattern.pattern.length === 0
    ) {
      return {
        ...GLOBAL.allSelection,
        selected: {
          row: null,
        },
        allowDayParts: requestJson.selectionPattern.allowDayParts,
        allowDays: requestJson.selectionPattern.allowDays,
        allowHours: requestJson.selectionPattern.allowHours,
      };
    } else {
      if (requestJson.selectionPattern.pattern) {
        patternObj = !_.isUndefined(requestJson.selectionPattern.pattern)
          ? requestJson.selectionPattern.pattern
          : [];
        selected.patternType = requestJson.selectionPattern.patternType;
        selected.patternLength = requestJson.selectionPattern.patternLength;
      }

      if (requestJson.selectionPattern.patternType === 1) {
        dateForPattern[columnIndex].startDate = new Date(
          dateForPattern[columnIndex].startDate
        );
        dateForPattern[columnIndex].endDate = new Date(
          dateForPattern[columnIndex].endDate
        );

        const oneDay = 24 * 60 * 60 * 1000;
        loadedPattern.totalDays =
          Math.round(
            Math.abs(
              (dateForPattern[columnIndex].startDate.getTime() -
                dateForPattern[columnIndex].endDate.getTime()) /
              oneDay
            )
          ) + 1;
        loadedPattern.maxPossiblePatternLength = Math.ceil(
          loadedPattern.totalDays / 7
        );
        loadedPattern.startDay =
          dateForPattern[columnIndex].startDate.getDay() === 0
            ? 6
            : dateForPattern[columnIndex].startDate.getDay() - 1;
        loadedPattern.endDay =
          dateForPattern[columnIndex].endDate.getDay() === 0
            ? 6
            : dateForPattern[columnIndex].endDate.getDay() - 1;

        const actualMultiple =
          moment(dateForPattern[columnIndex].startDate).isoWeek() ===
            moment(dateForPattern[columnIndex].endDate).isoWeek()
            ? false
            : true;
        const days = loadedPattern.startDay !== 0 || loadedPattern.endDay !== 6;
        loadedPattern.multipleRow = actualMultiple && days;

        // Nishit, dt:02/05/2016 code for SBRICS-1285, reopen fix
        if (loadedPattern.totalDays >= 14 && days) {
          loadedPattern.multipleRow = true;
        }
      } else if (requestJson.selectionPattern.patternType === 2) {
        patternObj.forEach((celldata) => {
          if (celldata.dayId < 0) {
            switch (celldata.dayId) {
              case -4:
                celldata.dayId = 32;
                break;
              case -3:
                celldata.dayId = 33;
                break;
              case -2:
                celldata.dayId = 34;
                break;
              case -1:
                celldata.dayId = 35;
                break;
            }
          }
        });
      }

      const columnStart = 0;
      const columnEnd = 7;
      totalColumns = requestJson.selectionPattern.patternType === 1 ? 0 : 1;

      const row = [];
      // Populating the Default Pattern Group in case if Column Config is not Available . Done mainly for PCM//
      const dayPartGroupId =
        columnConfig != null && !_.isEmpty(columnConfig)
          ? columnConfig[columnIndex]['dayPartGroupId']
          : 4;
      // changed position of code for SM-3803
      noOfRows =
        requestJson.selectionPattern.patternType === 1
          ? this.calculateRowsToGenerate(
            loadedPattern,
            requestJson.selectionPattern.patternLength
          )
          : 4;
      // TODO: Can be moved to pattern service
      const dropDown = patternCommon.generateDropDownForPattern(
        dayPartGroupId,
        dayPartGroup
      );
      for (let i = 0; i <= noOfRows; i++) {
        const coloumns = [];
        for (let j = columnStart; j < columnEnd; j++) {
          const obj: PatternCell = new PatternCell();
          obj.columnIndex = j;
          obj.disable = false;
          patternObj.forEach((celldata) => {
            if (celldata.dayId === totalColumns) {
              obj.disable = false;
              obj.currentSelection = false;
              obj.selected = true;
              obj.displayText = patternCommon.getDisplayText(celldata.dayPart);
              obj['values'] = patternCommon.getSelectedValue(
                celldata.dayPart,
                dropDown.spans
              );
            }
          });

          coloumns.push(obj);
          totalColumns++;
        }

        const rowObj = {
          columns: coloumns,
        };

        row.push(rowObj);
      }
      selected.selected.row = row;
      return selected;
    }
  }

  // TODO: Can be moved to pattern service

  /**
   * @description calculate number of rowa to be created for battern
   * @author Nishit Parekh
   * @param {*} loadedPattern pattern data object
   * @param {*} requestJson
   * @returns no. of rows to be created
   * @memberof PopulateSelected
   */
  calculateRowsToGenerate(
    loadedPattern,
    requestJsonPatternLength: number
  ): number {
    // pattern with only 1 row start day is not monday and end day is not sunday
    if (
      !loadedPattern.multipleRow &&
      loadedPattern.startDay !== 0 &&
      loadedPattern.endDay !== 6
    ) {
      return requestJsonPatternLength - 1;
      // perfect pattern, start day is monday and end day is sunday
    } else if (loadedPattern.startDay === 0 && loadedPattern.endDay === 6) {
      return requestJsonPatternLength - 1;
      // e.g. end day is friday and start day is between monday to Friday
    } else if (loadedPattern.endDay >= loadedPattern.startDay) {
      if (loadedPattern.maxPossiblePatternLength === requestJsonPatternLength) {
        return requestJsonPatternLength - 1;
      } else {
        return requestJsonPatternLength;
      }
      // e.g. start day is friday and end day is between monday to Friday
    } else if (loadedPattern.startDay > loadedPattern.endDay) {
      return requestJsonPatternLength;
    }
  }

  /**
   * @description Populate pricing tag bric selected values using request json
   * @author Shivani Patel, Amit Mahida
   * @param {*} requestJson
   * @param {InitialConfigModel} initialConfig
   * @param {ColumnConfig} columnConfig
   * @param {number} bricId
   * @returns Selected Values of Pricing Tag brick
   * @memberof PopulateSelected
   */
  public getPricingTagSelectedValue(
    requestJson: any,
    initialConfig: InitialConfigModel,
    columnConfig: ColumnConfig,
    bricId: number
  ) {
    if (requestJson && Object.keys(requestJson).length) {
      if (requestJson[0][1].hasOwnProperty('-99')) {
        return _.cloneDeep(GLOBAL.allSelection);
      }
      // Get frameData from initialConfig.lookupColumn
      const initConfigLookup = initialConfig.lookupColumn.filter(
        (x) => x.brickId === bricId
      )[0];
      let lookup = {};
      if (columnConfig) {
        for (const key in columnConfig.lookupData) {
          if (initConfigLookup) {
            initConfigLookup.lookup.forEach((obj) => {
              if (
                parseInt(key, 10) === parseInt(obj.selectionId.toString(), 10)
              ) {
                lookup = columnConfig.lookupData[key];
              }
            });
          }
        }
      }

      const selectedNetwork = [];
      const networkGroup = initialConfig.networkGroup;
      for (const networkKey in networkGroup) {
        networkGroup[networkKey].network.forEach(
          (networkItem: NetworkModel) => {
            requestJson.forEach((requestJsonItem) => {
              const filteredRequestJson = Object.keys(requestJsonItem[1])
                .filter(
                  (value: string) =>
                    parseInt(value, 10) === networkItem.networkId
                )
                .map((value: string) => {
                  const selected: any = {
                    ...networkItem,
                    optionStack: requestJsonItem[0]['optionStack'],
                    fixedPackagePrice: requestJsonItem[0]['fixedPackagePrice'],
                    networkDefaultQuantity: requestJsonItem[0].openRateCard
                      ? networkItem.networkDefaultQuantity
                      : requestJsonItem[1][value],
                    openRateCard: requestJsonItem[0].openRateCard,
                  };
                  if (requestJsonItem[0].greenTolerance || requestJsonItem[0].greenTolerance == 0) {
                    selected.greenTolerance = requestJsonItem[0].greenTolerance;
                  }
                  if (requestJsonItem[0].orangeTolerance || requestJsonItem[0].orangeTolerance == 0) {
                    selected.orangeTolerance = requestJsonItem[0].orangeTolerance;
                  }
                  if (requestJsonItem[0].priority || requestJsonItem[0].priority == 0) {
                    selected.priority = requestJsonItem[0].priority;
                  }
                  if (lookup[value]) {
                    selected['frameData'] = lookup[value];
                    selected['max'] = requestJsonItem[0]['optionStack']
                      ? lookup[value][0]
                      : lookup[value][1];
                  }
                  if (requestJsonItem[0].editable) {
                    selected['noOfFramesEditable'] = true;
                  } else {
                    selected['noOfFramesEditable'] = false;
                  }
                  return selected;
                });
              selectedNetwork.push(...filteredRequestJson);
            });
          }
        );
      }

      const network = _.uniqBy(selectedNetwork, 'networkId');
      return { [requestJson[0][0].selectionId]: network };
    }
    return [];
  }

  public getAllAudienceSelectedValue(
    requestJson: any,
    initialConfig: InitialConfigModel
  ) {
    if (
      requestJson.hasOwnProperty('-99') ||
      Object.keys(requestJson).length === 0
    ) {
      return _.cloneDeep(GLOBAL.allSelection);
    }
    requestJson.categories.forEach((selectedAudienceCategory) => {
      if (!selectedAudienceCategory.audienceCategoryName) {
        initialConfig.uiControl.secondaryAudienceGroups.forEach((id) => {
          const audienceCategoryGroup =
            initialConfig.audienceCategoryGroup.filter((item) => {
              return id === item.audienceCategoryGroupId;
            });
          this.iterateOverAudienceCategoryGroup(
            audienceCategoryGroup[0].audienceCategoryType,
            selectedAudienceCategory
          );
        });
      }
    });
    return requestJson;
  }

  public iterateOverAudienceCategoryGroup(
    audienceCategories,
    selectedAudienceCategory
  ) {
    audienceCategories.forEach((element) => {
      if (
        element.audienceCategoryId ===
        selectedAudienceCategory.audienceCategoryId
      ) {
        selectedAudienceCategory['audienceCategoryName'] =
          element.audienceCategoryName;
      } else {
        if (element.audienceCategory) {
          this.iterateOverAudienceCategoryGroup(
            element.audienceCategory,
            selectedAudienceCategory
          );
        }
      }
    });
  }

  getSelectionFilters(data) {
    if (!Array.isArray(data)) {
      return {
        selection: data,
        relativeFilters: {},
      };
    }

    const selection: any = {};
    const relativeFilters = {};
    let currentSelectionId = -1;
    let isRelative = false;
    for (const d of data) {
      for (const dd of d) {
        if (dd.userSelectionId && dd.userSelectionName) {
          if (!selection.fileList) {
            selection.fileList = {};
          }

          if (!selection.fileList[dd.selectionId]) {
            selection.fileList[dd.selectionId] = [];
          }

          selection.fileList[dd.selectionId].push({
            userSelectionId: dd.userSelectionId,
            userSelectionName: dd.userSelectionName,
            exclude: dd.exclude,
            validCount: dd.validCount,
            totalCount: dd.totalCount
          });
        } else if (dd.selectionId) {
          currentSelectionId = dd.selectionId;
          isRelative = dd.relative;
          if (!selection[currentSelectionId]) {
            selection[currentSelectionId] = {};
          }
          if (!relativeFilters[currentSelectionId] && isRelative) {
            relativeFilters[currentSelectionId] = {};
          }
        } else {
          const allKeys = Object.keys(dd);
          for (const key of allKeys) {
            if (!selection[currentSelectionId]) {
              selection[currentSelectionId] = {};
            }

            selection[currentSelectionId][key] = -1;
            if (isRelative) {
              relativeFilters[currentSelectionId][key] = -1;
            }
          }
        }
      }
    }

    return {
      selection,
      relativeFilters,
    };
  }

  public getMultiTargetSelectedValue(requestJson: any, productMode: boolean) {
    if (productMode) {
      return requestJson[0];
    } else {
      const listObj: FileUpload = new FileUpload();
      for (const obj of requestJson) {
        listObj.listUpload.fileList.push({
          userSelectionId: obj.userSelectionId,
          userSelectionName: obj.userSelectionName,
          validCount: obj.validCount,
          totalCount: obj.totalCount,
          selectedSOTOption: obj.selectedSOTOption
        });
      }
      return listObj;
    }
  }
}
