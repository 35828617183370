<div class="savecampaign modal-header" [ngStyle]="{'background-color':resolveObject.brickBgColor}">
    <h3 class="modal-title">{{resolveObject.title}}</h3>
    <button aria-label="Close" class="close" type="button" (click)="onClose()">x</button>
</div>
<div class="modal-body">
    <div class="control_group">
        <label>{{resolveObject.campaignTitle}} :</label>
        <input type="text" class="form_control" [(ngModel)]="campaignSave.campaignName" />
    </div>
    <div *ngIf="resolveObject.CCPHideFinanceEnabled" class="control_group CCPFinance">
        <label for="CCPFinance" style="width:100%;">
            <input type="checkbox" id="CCPFinance" class="checkbox small" [disabled]="!userModel.commercialTabAccess.allowHideFinancialCCPEdit" [(ngModel)]="campaignSave.hideFinance" value="campaignSave.hideFinance" />
            <span>{{resolveObject.CCPHideFinance}}</span>
       </label>
    </div>
</div>
<div class="modal-footer" style="clear: both">
    <button class="btn btn-primary" (click)="onSave()">{{resolveObject.ok}}</button>
</div>