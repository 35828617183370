import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-select-cell-editor',
  templateUrl: './ag-select-cell-editor.component.html',
  styleUrls: ['./ag-select-cell-editor.component.css']
})
export class AgSelectCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value: any = '';
  public isParent: boolean;
  public dataArr: any[] = [];
  public valKey = '';
  public textKey = '';
  private cancelBeforeStart = false;
  public cellDisable = false;
  public isCellRenderer = false;

  @ViewChild('select', { read: ViewContainerRef }) public input;

  agInit(params: any): void {
    this.params = params;
    if (typeof this.params.value === 'function') {
      this.value = this.params.value(this.params.node.data);
    } else {
      this.value = this.params.value;
    }

    this.isParent = this.params.node.data.isParent;
    this.valKey = this.params.valKey;
    this.textKey = this.params.textKey;
    this.dataArr = this.params.dataArr(this.params.node.data);
    this.isCellRenderer = this.params.isCellRenderer !== undefined ? this.params.isCellRenderer : false;

    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart = this.isParent || (this.params.node.rowPinned === 'bottom' && this.params.rowIndex === 1);
    if (!this.cancelBeforeStart && this.params.cellEditable) {
      this.cancelBeforeStart = !this.params.cellEditable(this.params.node.data);
    }
    if (this.params.cellDisable) {
      this.cellDisable = this.params.cellDisable(this.params);
    }
  }

  getValue(): any {
    return this.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
    return false;
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    if (!this.isCellRenderer && this.params.column.isCellEditable(this.params.node)) {
      setTimeout(() => {
        this.input.element.nativeElement.focus();
      }, 10);
    }
  }

  onFocusLost() {
    if (this.params.column.isCellEditable(this.params.node)) {
      this.params.api.stopEditing();
    }
  }

  onSelectChange() {
    if (!this.params.column.isCellEditable(this.params.node)) {
      this.params.value = this.value;
      this.params.data[this.params.column.colId] = this.value;
    }
    if (this.params.onSelectChange) {
      this.params.onSelectChange(this.params);
    }
  }

  trackByValKey(index, item) {
    return item && item[this.valKey];
  }
}
