import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { GLOBAL } from '../utils/app.constant';

@Injectable()
export class FileUploadService {
  /**
   * request json data subscriber
   * @type {Subscription}@memberof FileUploadService
   */
  requestJsonDataSubscriber: Subscription;

  constructor(private http: HttpClient) {
  }

  async uploadFileRequest(fileToSend: any[], routeIdToSend, itemIndex: number, fileUploadUrl: string, action: string): Promise<any> {
    let fs;
    let excludeFlag = false;
    let routeFrameIds = [];
    if (fileToSend && fileToSend.length > itemIndex ||
      (routeIdToSend && routeIdToSend.length > (itemIndex - fileToSend.length))) {
      if (fileToSend && fileToSend.length > itemIndex) {
        fs = fileToSend[itemIndex];
        excludeFlag = fileToSend[itemIndex].exclude;
        routeFrameIds = null;
      } else if (routeIdToSend && routeIdToSend.length > (itemIndex - fileToSend.length)) {
        fs = null;
        excludeFlag = routeIdToSend[(itemIndex - fileToSend.length)].exclude;
        routeFrameIds = routeIdToSend[(itemIndex - fileToSend.length)].routeFrameId;
      }
      if (fs && fs.userSelectionId) { // Already Uploaded file
        return {
          status: 'OK',
          data: fs
        };
      }
      return await this.upload(fs, excludeFlag, routeFrameIds, fileUploadUrl, action);
    } else {
      return '';
    }
  }

  /**
   * send file upload request
   * @param {any} file - file to send
   * @param {any} include - include list
   * @param {any} routeFrameIds - frameIds
   * @param {string} [url] - Request url
   * @returns
   * @memberof ListFilterService
   */
  upload(file, exclude, routeFrameIds, url: string, action: string) {
    const fd = new FormData();
    if (file != null) {
      fd.append('file', file);
    } else if (routeFrameIds != null && routeFrameIds.length > 0) {
      fd.append('routeFrameId', routeFrameIds);
    }
    if (exclude != null && exclude !== undefined) {
      fd.append('include', String(!exclude));
    }
    fd.append('bricsCampaignId', String(GLOBAL.BRIC_CAMPAIGN_ID));
    fd.append('action', action);

    const headers = new HttpHeaders();

    const requestOptions = {
      headers,
      body: fd
    };

    // GLOBAL.HTTP_METHOD
    return this.http.request(GLOBAL.HTTP_METHOD, url, requestOptions)
      .toPromise<any>()
      .catch(error => Observable.throwError(error));
  }
}
