import { Component, OnInit, Input } from '@angular/core';
import { GridOptions, ColDef } from 'ag-grid-community';
import { DataShareService } from '../../../core/services';
import {
  AgCustomRowUtil,
  AgNumberCellRendererComponent,
  AgCurrencyCellRendererComponent,
  AgPercentageCellRendererComponent,
  AgCheckboxComponent,
  AgTextCellRendererComponent,
  AgTextCellEditorComponent,
  AgCustomSort,
  AgTextFloatingFilterComponent,
  AgCustomFilterMenuComponent,
  AgCustomHeaderComponent
} from '../ag-grid-support';
import { DecimalPipe } from '@angular/common';
declare const _: any;
@Component({
  selector: 'app-discount-grid-dubai',
  templateUrl: './discount-grid-dubai.component.html',
  styleUrls: ['../ag-grid-custom-style.css', '../ag-grid-col-select-style.css', './discount-grid-dubai.component.css']
})
export class DiscountGridDubaiComponent implements OnInit {
  @Input() config: any;
  @Input() campaignDetails;
  gridOptions: GridOptions;
  columnList: any = [];
  footerRowData: any[];
  userBundle: any = {};
  groupingField: string = null;
  rowData: any[] = [];
  gridData: any = {};
  columnDefs: ColDef[];
  sortObj: AgCustomSort;
  numericFields = [];
  dateRangeFields = [];
  selectFields = [];

  constructor(private dataShareService: DataShareService, private decimalPipe: DecimalPipe) {
  }

  ngOnInit() {
    this.gridData.discounts = this.campaignDetails.discounts;
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.prepareRowData();
    this.prepareColDef();
    this.setFooterRows();
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      scrollbarWidth: 10,
      onGridReady: this.onGridReady,
      getRowHeight: this.getRowHeight,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      suppressScrollOnNewData: true,
      onFilterChanged: this.onFilterChanged,
      onCellEditingStopped: this.onCellEditingStopped,
      groupingField: this.groupingField,
      suppressMovableColumns: true,
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: node => node.data.isVisible,
      onCellClicked: (params) => {
        if (params.rowPinned === 'bottom' && params.rowIndex === 0 && params.column.isCellEditable(params.node)) {
          const par: any = {
            column: params.column,
            rowIndex: params.rowIndex,
            rowPinned: params.rowPinned
          };
          // tslint:disable-next-line: no-string-literal
          params.api['rowRenderer'].startEditingCell(par);
        }
      }

    } as GridOptions;

  }

  onGridReady = () => {
    this.columnList = this.gridOptions.columnApi.getAllColumns();
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (String(params.node.rowPinned) === 'bottom' && parseInt(params.node.rowTop, 10) === 0) {
      height = 34;
    } else if (params.data.isVisible) {
      height = 30;
    }
    return height;
  }

  isFullWidthCell = (rowNode): boolean => {
    return rowNode.data.gridSummary;
  }

  fullWidthCellRenderer = (): HTMLElement => {
    return document.createElement('div');
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.forEach((r) => {
        r.isFilterPass = true;
      });
    }

    // Refresh Grid Header checkbox component
    // tslint:disable-next-line: no-string-literal
    this.gridOptions.api['headerRootComp'].forEachHeaderElement((e1) => {
      if (e1.column.colId === 'selectChk' && e1.columnApi && e1.childComponents && e1.childComponents.length > 1) {
        const headerCmpIns = e1.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  onCellEditingStopped = (event) => {
    if (event.rowPinned !== 'bottom') {
      this.calculateCellValues(event.data);
      this.rowData = _.clone(this.rowData);
    }
  }

  calculateCellValues(rowData) {
    rowData.netPrice = this.calcuateNetAfterDiscount(rowData.grossPrice, rowData.discount);
    rowData.netMedia = this.calculateNetMedia(rowData.netPrice, rowData.agencyCommission);
  }

  calcuateNetAfterDiscount(grossPrice, discount) {
    const grossPriceLocal = parseFloat(grossPrice);
    const discountLocal = parseFloat(discount.toFixed(2));
    return (grossPriceLocal - (grossPriceLocal * (discountLocal / 100))).toFixed(2);
  }

  calculateNetMedia(netPrice, agencyCommission) {
    const netPriceLocal = parseFloat(netPrice);
    const agencyCommissionLocal = parseFloat(agencyCommission.toFixed(2));
    return (netPriceLocal - (netPriceLocal * (agencyCommissionLocal / 100))).toFixed(2);
  }

  prepareRowData() {
    this.rowData = [];
    for (const curRow of this.gridData.discounts) {
      if (curRow.grossPrice === undefined) {
        curRow.grossPrice = parseFloat(curRow.smartBricsValue.toFixed(2));

      }
      // Calulate Net Value
      curRow.netPrice = curRow.grossPrice - ((curRow.grossPrice * curRow.discount) / 100);

      // Calculate Net Media
      curRow.netMedia = curRow.netPrice - (curRow.netPrice * curRow.agencyCommission / 100);
      curRow.isFilterPass = true;
      curRow.isParent = false;
      curRow.isVisible = true;
      curRow.isSelected = false;
      this.rowData.push(curRow);
    }

  }

  blankCellRenderer() {
    return '';
  }

  groupingCellRenderer(params) {
    return params.data.childCount ? params.data.childCount : '';
  }

  // tslint:disable-next-line: no-big-function
  prepareColDef() {
    this.columnDefs = [

      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false, colId: 'selectChk',
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: (params): string[] => {
          const retArr: string[] = [];
          if (params.data.isSelected) {
            retArr.push('selectedRow');
          }
          if (String(params.node.rowPinned) === 'bottom') {
            retArr.push('borderBottom');
          }
          return retArr;
        }
      },
      {
        headerName: this.userBundle['discount.dubai.productName'], field: 'productName',
        width: 200, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer,
      },
      {
        headerName: this.userBundle['discount.dubai.startDate'], field: 'startDate', width: 150, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer,
        cellStyle: { 'text-align': 'right' }
      },
      {
        headerName: this.userBundle['discount.dubai.endDate'], field: 'endDate', width: 150, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer,
        cellStyle: { 'text-align': 'right' }
      },
      {
        headerName: this.userBundle['discount.dubai.weekDays'], field: 'weekDays', width: 150, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.weekDaysPinnedRowCellRenderer,
      },
      {
        headerName: this.userBundle['discount.dubai.noOfFrames'], field: 'numberOfFrames', width: 100, editable: false,
        cellRendererFramework: AgNumberCellRendererComponent,
        pinnedRowCellRendererFramework: AgNumberCellRendererComponent,
        pinnedRowCellRendererParams: {
          calcCellValue: (rows, colId) => {
            const sum = AgCustomRowUtil.sumFieldValues(rows, colId);
            return this.decimalPipe.transform(sum, '.2-2');
          }
        }
      },
      {
        headerName: this.userBundle['discount.dubai.grossPrice'], field: 'grossPrice', width: 150, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          gridSumRow: 1
        }
      },
      {
        headerName: this.userBundle['discount.dubai.discount'], field: 'discount', width: 150, editable: true,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          calcCellValue: this.calculateAvgDiscount
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: (data) => {
            return !data.isParent;
          },
          calcCellValue: this.calculateAvgDiscount,
          prefixText: (data) => {
            return (!data.isParent) ? '' : 'Avg Discount';
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          maxValue: 100,
          allowNegetiveIntegers: true,
          cellEditable: () => {
            return true;
          }
        }
      },
      {
        headerName: this.userBundle['discount.dubai.netPrice'], field: 'netPrice', width: 120, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent
      },
      {
        headerName: this.userBundle['discount.dubai.agencyCommission'], field: 'agencyCommission', width: 150, editable: true,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          calcCellValue: this.calculateAvgAgencyComission
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: (data) => {
            return !data.isParent;
          },
          calcCellValue: this.calculateAvgAgencyComission,
          prefixText: (data) => {
            return (!data.isParent) ? '' : 'Avg Discount';
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          decimalPoints: 2,
          maxValue: 100,
          cellEditable: () => {
            return true;
          }
        }
      },

      {
        headerName: this.userBundle['discount.dubai.netMedia'], field: 'netMedia', width: 150, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          gridSumRow: 1
        }
      },
      {
        headerName: this.userBundle['discount.dubai.productionCosts'], field: 'productionCosts', width: 200, editable: true, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: (data) => {
            return !data.isParent;
          }
        },
        pinnedRowCellRendererParams: {
          cellEditable: (data) => {
            return !data.isParent;
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          cellEditable: (data) => {
            return typeof (data.pinRowIndex) === 'undefined' || data.pinRowIndex === 0;
          }
        }
      },
      {
        headerName: this.userBundle['discount.dubai.comments'], field: 'comments', width: 120, editable: true, pinned: false,
        cellRendererFramework: AgTextCellRendererComponent,
        cellRendererParams: {
          cellEditable: (rowData) => {
            return (!rowData.isParent);
          }
        },
        pinnedRowCellRendererFramework: AgTextCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: (rowData) => {
            return ((!rowData.isParent) && (!rowData.locked));
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: false,
          decimalPoints: 2,
          cellEditable: (rowData) => {
            return (!rowData.isParent);
          }
        }
      }
    ];

    this.numericFields = [
      'childCount',
      'numberOfFrames',
      'discount',
      'agencyCommission'

    ];

    this.dateRangeFields = [];
    this.selectFields = [];

    this.processColumnDefs();

    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField, true);

  }

  processColumnDefs() {
    const decimalFieldsForFilter = [
      'grossPrice',
      'netPrice',
      'netMedia',
      'productionCosts'

    ];
    for (let i = 1; i < this.columnDefs.length; i++) {

      this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
      this.columnDefs[i].floatingFilterComponentParams = {
        value: '',
        suppressFilterButton: true,
        decimalRound: decimalFieldsForFilter.indexOf(this.columnDefs[i].field) > -1 ? 2 : null
      };

      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };
      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => {
        const retArr: string[] = [];
        if (params.data.isParent || params.node.rowPinned === 'bottom') {
          retArr.push('fontBold');
        }
        if (params.node.rowPinned === 'bottom' && (i > 4 || params.rowIndex === 1)) {
          retArr.push('borderBottom');
        }
        if (params.data.isSelected) {
          retArr.push('selectedRow');
        }

        return retArr;
      };
    }
  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinRowIndex: 0 },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
  }

  calculateAvgDiscount(rows) {
    const valueObj = { value: 0, value1: 0, value2: 0, editable: true };
    rows.forEach((row) => {
      valueObj.value1 += parseFloat(row.netPrice);
      valueObj.value2 += parseFloat(row.grossPrice);
    });
    if (valueObj.value2) {
      const divide = (valueObj.value2 !== 0) ? (100 * (1 - (valueObj.value1 / valueObj.value2))) : 0;
      valueObj.value = divide;
    } else {
      valueObj.value = 0;
    }
    return valueObj.value.toFixed(2);

  }

  calculateAvgAgencyComission(rows) {
    const valueObj = { value: 0, value1: 0, value2: 0, editable: true };
    rows.forEach((row) => {
      valueObj.value1 += parseFloat(row.netMedia);
      valueObj.value2 += parseFloat(row.netPrice);
    });
    if (valueObj.value2) {
      const divide = (valueObj.value2 !== 0) ? (100 * (1 - (valueObj.value1 / valueObj.value2))) : 0;
      valueObj.value = divide;
    } else {
      valueObj.value = 0;
    }
    return valueObj.value.toFixed(2);

  }

  weekDaysPinnedRowCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {

      // apply Icon
      const eImg1: HTMLImageElement = document.createElement('img');
      eImg1.src = 'assets/img/apply.png';
      eImg1.title = 'Apply';
      eImg1.onclick = this.apply;
      eDiv.appendChild(eImg1);

      // Reset Icon
      const eImg2: HTMLImageElement = document.createElement('img');
      eImg2.src = 'assets/img/reset.png';
      eImg2.title = 'Reset';
      eImg2.onclick = this.reset;
      eDiv.appendChild(eImg2);
    } else if (params.rowIndex === 1) {
      eDiv.innerHTML = `<span class=\'cellRightSpan fontBold\'> ${this.userBundle['discount.common.totalBar']} </span>`;
    }
    return eDiv;
  }

  apply = () => {
    const selectedChildRows = this.getSelectedChildRows(null, true);
    if (selectedChildRows.length > 0 && (
      (this.footerRowData[0].discount === 0 || this.footerRowData[0].discount)
      ||
      (this.footerRowData[0].agencyCommission === 0 || this.footerRowData[0].agencyCommission)
      ||
      (this.footerRowData[0].productionCosts === 0 || this.footerRowData[0].productionCosts)
      ||
      (this.footerRowData[0].comments === 0 || this.footerRowData[0].comments)
    )) {
      // tslint:disable-next-line:variable-name
      selectedChildRows.forEach((_element, i) => {
        if (this.footerRowData[0].discount) {
          selectedChildRows[i].discount = this.footerRowData[0].discount;
        }
        if (this.footerRowData[0].agencyCommission) {
          selectedChildRows[i].agencyCommission = this.footerRowData[0].agencyCommission;
        }
        if (this.footerRowData[0].productionCosts) {
          selectedChildRows[i].productionCosts = this.footerRowData[0].productionCosts;
        }
        if (this.footerRowData[0].comments) {
          selectedChildRows[i].comments = this.footerRowData[0].comments;
        }
        this.calculateCellValues(selectedChildRows[i]);
      });
    }
    this.rowData = _.clone(this.rowData);

  }

  reset = () => {
    const columnArray = ['discount', 'agencyCommission', 'productionCosts', 'comments'];
    for (const column of columnArray) {
      this.footerRowData[0][column] = null;
    }
    this.rowData = _.clone(this.rowData);

  }

  getSelectedChildRows(groupingId?: string, filterOnly = false): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  getChildRows(groupingId?: string, filterOnly = true): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }
  sumFieldValues(rows: any[], fieldName: string): number {
    return AgCustomRowUtil.sumFieldValues(rows, fieldName);
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
  }

}
