import { Component, OnInit } from '@angular/core';
import { CampaignDetailsService } from '../campaign-details/campaign-details.service';
import { CampaignDetails, HistoryROC } from '../commercial.model';
import { SystemFlags } from '../../models';
import { DataShareService, LogHelperService, StateService } from '../../core/services';
import { CommercialService } from '../../core/services/commercial.service';
import { CommercialButtonService } from '../commercial-buttons/commercial-buttons.service';
import { DatePipe } from '@angular/common';
import { GLOBAL } from '../../core/utils/app.constant';
import { CommercialBase } from '../commercial-base';
import { RequiredCampaignFields, RequiredBookingsFields, PopulateBookingDetailFields } from '../../models/RequiredFields';

@Component({
  selector: 'app-commercial-au',
  templateUrl: './commercial-au.component.html'
})
export class CommercialAuComponent extends CommercialBase implements OnInit {
  systemFlags = SystemFlags;


  /**
   * @description maintain discount grid show hide
   * @type {boolean}
   * @memberof CampaignDetailsAUComponent
   */
  hideDiscountGrid: boolean;

  /**
   * @description campaign details object
   * @type {CampaignDetails}
   * @memberof CampaignDetailsAUComponent
   */
  campaignDetails: CampaignDetails = new CampaignDetails();
  /**
   * @description commercial data
   * @memberof CampaignDetailsAUComponent
   */
  public commercialData;

  /**
   * @description Accordian for campaign details
   * @memberof CampaignDetailsAUComponent
   */
  hideCampaignDetails: boolean;
  /**
   * @description Accordian for booking details
   * @memberof CampaignDetailsAUComponent
   */
  hideBookingDetails: boolean;

  /**
   * @description this will show the history of campaign updated in case of reason/cancel for change
   * @memberof CampaignDetailsAUComponent
   */
  historyROC = new HistoryROC();

  constructor(
    campaignDetailsService: CampaignDetailsService,
    dataShareService: DataShareService,
    commercialService: CommercialService,
    commercialButtonService: CommercialButtonService,
    logHelperService: LogHelperService,
    datePipe: DatePipe,
    stateService: StateService
  ) {
    super(stateService, logHelperService, commercialButtonService, dataShareService, commercialService, datePipe, campaignDetailsService);
  }

  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.setDefaultAgencySpecialistPerc();
    this.restoreCommercialData();
  }

  /**
   * @description get commercial data
   * @author Darshan Vachhani
   * @memberof CampaignDetailsAUComponent
   */
  restoreCommercialData() {
    this.commercialConfig.user = this.dataShareService.getUserModel();
    this.getCommercialDataFromService();
  }

  /**
   * @description get commercial data from service
   * @author Darshan Vachhani
   * @memberof CampaignDetailsAUComponent
   */
  getCommercialDataFromService() {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
    };

    this.commercialService.loadCommercialCampaignDetails(param).subscribe((data) => {
      if (data.status === 'OK') {
        this.commercialConfig.campaignStatus = data.data.campaignStatusId;
        this.commercialConfig.bookingStatus = data.data.bookingStatus;
        this.commercialConfig.uiControl = data.data.uiControl;
        this.commercialData = data.data;
        this.commercialData.bookingStatusName =
          this.campaignDetailsService.getStatusNameFromId(data.data.campaignStatusId);
        const assignedToList = this.dataShareService.getInitialConfigByKey('assignedTo');
        this.campaignDetails = this.campaignDetailsService.populateCampaignDetails(
          this.commercialData,
          this.initialConfig,
          assignedToList
        );
        this.campaignDetailsService.calculateCPT(this.campaignDetails);
        this.historyROC = this.campaignDetailsService.populateHistoryROC(this.historyROC, data.data.auditReasonsHistoryMap, this.commercialConfig.uiControl.historyROC);
      }
    });
  }

  /**
   * @description this is output event from commercialbutton when click on update/option/book/cancel button click
   * @author Darshan Vachhani
   * @param {*} status
   * @memberof CampaignDetailsAUComponent
   */
  onBookCampaign(status: number) {
    if (this.validateCommercialCampaign(status)) {
      status = status || this.campaignDetails.campaignStatusId;

      if (this.isROC(status)) {
        this.handleROC(status, this.createRequestJSONForCommercial);
      } else if (this.isConfirm(status)) {
        this.confirmCampaign(this.createRequestJSONForCommercial);
      } else {
        this.validateServiceRequest(status, this.createRequestJSONForCommercial);
      }
    }
  }

  /**
   * @description commercial form validity
   * @author Dhaval Patel
   * @returns {boolean}
   * @memberof CampaignDetailsAUComponent
   */
  validateCommercialCampaign = (status) => {
    const requiredFields: RequiredCampaignFields = new RequiredCampaignFields();
    requiredFields.assignedTo = true;
    let message = this.validateCommonFields(requiredFields);
    if (!message && this.campaignDetails.bookings) {
      this.campaignDetails.bookings.forEach((obj) => {
        const requiredBookingsFields: RequiredBookingsFields = new RequiredBookingsFields();
        if (!message) {
          message = this.validateBookingFields(obj, requiredBookingsFields);
        }
        if (!message && !obj.hideMediaControl) {
          message = this.validateMediaLength(obj, status);
        }
      });
    }
    return this.isValid(message);
  }

  /**
   * @description create requestJSON for passing to backend
   * @author Dhaval Patel
   * @param {*} status
   * @returns
   * @memberof CampaignDetailsAUComponent
   */
  createRequestJSONForCommercial = (status) => {
    const campaignDetails: CampaignDetails = this.campaignDetails;
    const temp = this.populateBookCommercialCampaignParam(status, campaignDetails);
    const populateBooking: PopulateBookingDetailFields = this.getBookingDetailFields();
    temp.bookings = super.populateBookingDetailsServiceObject(campaignDetails, 'bookings', populateBooking);
    temp.gridLines = campaignDetails.gridLines;
    return temp;
  }

  /**
   * To override the booking detail fields
   * This override fields are used to override the default value
   * @memberof CampaignDetailsAUComponent
   * @author Dhaval Patel
   */
  getBookingDetailFields() {
    const populate: PopulateBookingDetailFields = super.getBookingDetailFields();
    populate.setBookingDateFieldsInverse();
    return populate;
  }
}
