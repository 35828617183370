import { Component, OnInit, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { GridOptions, ColDef } from 'ag-grid-community';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { DataShareService } from '../../../core/services/data-share.service';
import { ExcelCell, ExportToExcel, ExcelColumn } from '../../../core/utils/exportToExcel';
import {
  AgCheckboxComponent,
  AgCustomFilterMenuComponent,
  AgCustomHeaderComponent,
  AgTextCellEditorComponent,
  AgNumberCellRendererComponent,
  AgCurrencyCellRendererComponent,
  AgPercentageCellRendererComponent,
  AgCustomSort,
  AgCustomRowUtil,
  AgTextFloatingFilterComponent
} from '../ag-grid-support/index';
import * as _ from 'lodash';
import { LocaleData } from '../../../core/utils/LocaleData';

@Component({
  selector: 'app-discount-grid-italy',
  templateUrl: './discount-grid-italy.component.html',
  styleUrls: ['../ag-grid-custom-style.css', '../ag-grid-col-select-style.css', './discount-grid-italy.component.css']
})
export class DiscountGridItalyComponent implements OnInit {
  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  gridData: any = {};
  @Input() callFn: any;
  @Input() campaignDetails: any;
  sortObj: AgCustomSort;
  /**
   * Ag-Grid Options Object
   */
  gridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  columnDefs: ColDef[];

  /**
   * column list to show in show/hide column dropdown
   */
  columnList: any = [];

  /**
   * list of Cart Data
   * @memberof CartDetailComponent
   */
  rowData: any[] = [];

  /**
   * Grid footer data
   */
  footerRowData: any[];

  /**
   * user bundle data
   */
  userBundle: any = {};

  totalselectedNet = 0;
  totalselectedRateCard = 0;

  numericFields = [];
  dateRangeFields = [];
  selectFields = [];
  groupingField: string = null;

  constructor(private dataShareService: DataShareService,
              private toaster: LogHelperService, private decimalPipe: DecimalPipe) {

  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
   // this.dataShareService.setInitialConfig(this.config.InitialConfig);
    this.gridData.discounts = this.campaignDetails.discounts;
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');

    this.setFooterRows();
    this.prepareRowData();
    this.prepareColDef();

    this.reset();
    // we pass an empty gridOptions in, so we can grab the api out
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      onRowDataChanged: (event) => {
        event.api.setPinnedBottomRowData(this.footerRowData);
      },
      scrollbarWidth: 10,
      onGridReady: this.onGridReady,
      getRowHeight: this.getRowHeight,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressCellSelection: true,
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      suppressScrollOnNewData: true,
      onFilterChanged: this.onFilterChanged,
      onCellEditingStopped: this.onCellEditingStopped,
      groupingField: this.groupingField,
      suppressMovableColumns: true,
      isExternalFilterPresent: () => true,
      // tslint:disable-next-line:ter-arrow-parens
      doesExternalFilterPass: (node) => node.data.isVisible
    } as GridOptions;
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.forEach((r) => {
        r.isFilterPass = true;
      });
    }
    // Refresh Grid Header checkbox component
    /* tslint:disable:no-string-literal */
    this.gridOptions.api['headerRootComp'].forEachHeaderElement((element) => {
      if (element.column.colId === 'selectChk' && element.columnApi && element.childComponents && element.childComponents.length > 1) {
        const headerCmpIns = element.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  clearAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    this.gridOptions.api.onFilterChanged();
  }

  onGridReady = () => {
    this.columnList = this.gridOptions.columnApi.getAllColumns();
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else if (params.data.isVisible) {
      height = 30;
    }
    return height;
  }

  isFullWidthCell = (rowNode): boolean => {
    return rowNode.data.gridSummary;
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }

  onCellEditingStopped = (event) => {
    if (event.rowPinned !== 'bottom') {
      switch (event.column.colId) {
        case 'newDiscount':
          event.data.finalNet = this.calculateFinalNet(event.data.grossValue, event.data.newDiscount);
          event.data.netNetPrice = this.calculateNetNetPrice(event.data.finalNet, event.data.agencyCommission);
          event.data.totalNetNet = this.calculateTotalNetNet(event.data.netNetPrice, event.data.productionCost);
          break;
        case 'agencyCommission':
          event.data.netNetPrice = this.calculateNetNetPrice(event.data.finalNet, event.data.agencyCommission);
          event.data.totalNetNet = this.calculateTotalNetNet(event.data.netNetPrice, event.data.productionCost);
          break;
        case 'productionCost':
          event.data.productionCost = event.data.productionCost ? LocaleData.replaceLocalDecimalCharWithDot(event.data.productionCost) : 0;
          event.data.totalNetNet = this.calculateTotalNetNet(event.data.netNetPrice, event.data.productionCost);
          break;
        // SM-2201 Final Net Price editable line by line, Nishit
        case 'finalNet':
          event.data.newDiscount = this.calculateNewDiscount(event.data.grossValue, event.data.finalNet);
          event.data.netNetPrice = this.calculateNetNetPrice(event.data.finalNet, event.data.agencyCommission);
          event.data.totalNetNet = this.calculateTotalNetNet(event.data.netNetPrice, event.data.productionCost);
          break;
        default:
          break;
      }
      this.rowData = _.clone(this.rowData);
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen() && $event.button !== 2 && !dropdownEle._isEventFromToggle($event)
    && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
      dropdownEle.close();
    }
  }

  prepareRowData() {
    this.rowData = [];
    for (const curRow of this.gridData.discounts) {
      curRow.grossValue = this.calculateGrossValue(curRow.defaultNetPrice, curRow.defaultDiscount);
      curRow.finalNet = this.calculateFinalNet(curRow.grossValue, curRow.newDiscount);
      curRow.netNetPrice = this.calculateNetNetPrice(curRow.finalNet, curRow.agencyCommission);
      curRow.totalNetNet = this.calculateTotalNetNet(curRow.netNetPrice, curRow.productionCost);

      curRow.isFilterPass = true;
      curRow.isParent = false;
      curRow.isVisible = true;
      curRow.isSelected = false;
      this.rowData.push(curRow);
    }
  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinRowIndex: 0 },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
  }

  getGridSummary() {
    return `<span>${this.userBundle['discount.common.totalFrames']}: ${this.getChildRows().length} &#45;
     ${this.userBundle['discount.common.selectedFrames']}: ${this.getSelectedChildRows().length}</span>`;
  }

  // tslint:disable-next-line: no-big-function
  prepareColDef() {
    /* tslint:disable:no-duplicate-string */
    this.columnDefs = [
      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false, colId: 'selectChk',
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: (params): string[] => {
          const retArr: string[] = [];
          if (params.data.isSelected) {
            retArr.push('selectedRow');
          }
          if (params.node.rowPinned === 'bottom') {
            retArr.push('borderBottom');
          }
          return retArr;
        }
      },
      {
        headerName: this.userBundle['discount.it.priceTag'], field: 'priceTag', width: 100, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.priceTagPinnedRowCellRenderer,
      },
      {
        headerName: this.userBundle['discount.it.city'], field: 'city', width: 150, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer,
        cellStyle: { 'text-align': 'right' }
      },
      {
        headerName: this.userBundle['discount.it.market'], field: 'market', width: 150, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer,
        cellStyle: { 'text-align': 'right' }
      },
      {
        headerName: this.userBundle['discount.it.startDate'], field: 'startDate', width: 150, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer,
        cellStyle: { 'text-align': 'right' }
      },
      {
        headerName: this.userBundle['discount.it.endDate'], field: 'endDate', width: 150, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer,
        cellStyle: { 'text-align': 'right' }
      },
      {
        headerName: this.userBundle['discount.it.numberFrames'], field: 'numberFrames', width: 100, editable: false,
        cellRendererFramework: AgNumberCellRendererComponent,
        pinnedRowCellRendererFramework: AgNumberCellRendererComponent,
        pinnedRowCellRendererParams: {
          calcCellValue: (rows, colId) => {
            const sum = AgCustomRowUtil.sumFieldValues(rows, colId);
            return this.decimalPipe.transform(sum, '.2-2');
          }
        }
      },
      {
        headerName: this.userBundle['discount.it.grossPrice'], field: 'grossValue', width: 150, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          gridSumRow: 1
        }
      },
      {
        headerName: this.userBundle['discount.it.defaultNetPrice'], field: 'defaultNetPrice', width: 150, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          gridSumRow: 1
        }
      },
      {
        headerName: this.userBundle['discount.it.newDiscount'], field: 'newDiscount', width: 150, editable: true,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          }
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          },
          calcCellValue: this.calcAvgNewDiscount,
          gridSumRow: 1
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          allowReplaceDecimalSeparator: true,
          maxValue: 100,
          cellEditable: () => {
            return true;
          }
        }
      },
      {
        headerName: this.userBundle['discount.it.finalNet'], field: 'finalNet', width: 150, editable: true,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          },
          gridSumRow: 1
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => {
            return typeof (data.pinRowIndex) === 'undefined' || data.pinRowIndex === 0;
          }
        }
      },
      {
        headerName: this.userBundle['discount.it.agencyCommission'], field: 'agencyCommission', width: 150, editable: true,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          }
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          },
          calcCellValue: this.calcAvgAgencyCommision,
          gridSumRow: 1
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          },
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.it.netNetPrice'], field: 'netNetPrice', width: 150, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true
        },
        pinnedRowCellRendererFramework: AgNumberCellRendererComponent,
        pinnedRowCellRendererParams: {
          calcCellValue: (rows, colId) => {
            const sum = AgCustomRowUtil.sumFieldValues(rows, colId);
            return this.decimalPipe.transform(sum, '.2-2');
          }
        }
      },
      {
        headerName: this.userBundle['discount.it.productionCost'], field: 'productionCost', width: 100, editable: true,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          },
          gridSumRow: 1
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          }
        }
      },
      {
        headerName: this.userBundle['discount.it.totalNetNet'], field: 'totalNetNet', width: 100, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return false;
          },
          gridSumRow: 1
        }
      }
    ];

    this.numericFields = [
      'numberFrames',
      'grossValue',
      'defaultNetPrice',
      'newDiscount',
      'finalNet',
      'agencyCommission',
      'netNetPrice',
      'productionCost',
      'totalNetNet'
    ];

    this.dateRangeFields = [];
    this.selectFields = [];

    this.processColDef();

    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField, true);
  }

  processColDef() {

    const decimalFieldsForFilter = [
      'grossValue',
      'defaultNetPrice',
      'newDiscount',
      'finalNet',
      'agencyCommission',
      'netNetPrice',
      'productionCost',
      'totalNetNet'
    ];
    for (let i = 1; i < this.columnDefs.length; i++) {

      this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
      this.columnDefs[i].floatingFilterComponentParams = {
        value: '',
        suppressFilterButton: true
      };
      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };
      this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
      this.columnDefs[i].floatingFilterComponentParams = {
        value: '',
        suppressFilterButton: true,
        decimalRound: decimalFieldsForFilter.indexOf(this.columnDefs[i].field) > -1 ? 2 : null
      };
      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => {
        const retArr: string[] = [];
        if (params.data.isParent || params.node.rowPinned === 'bottom') {
          retArr.push('fontBold');
        }
        if (params.node.rowPinned === 'bottom') {
          retArr.push('borderBottom');
        }
        if (params.data.isSelected) {
          retArr.push('selectedRow');
        }
        return retArr;
      };
    }
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
    // restore original data order when sorting is cleared
    if (this.sortObj.sortColumns.length === 0) {
      this.rowData = _.clone(this.gridData.discounts);
    }
  }

  blankCellRenderer() {
    return '';
  }

  priceTagPinnedRowCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {
      // apply Icon
      const eImgApply: HTMLImageElement = document.createElement('img');
      eImgApply.src = 'images/general/apply.png';
      eImgApply.title = 'Apply';
      eImgApply.onclick = this.apply;
      eDiv.appendChild(eImgApply);

      // Reset Icon
      const eImgReset: HTMLImageElement = document.createElement('img');
      eImgReset.src = 'images/general/reset.png';
      eImgReset.title = 'Reset';
      eImgReset.onclick = this.reset;
      eDiv.appendChild(eImgReset);
    }
    return eDiv;
  }

  apply = () => {
    const selectedChildRows = this.getSelectedChildRows();
    if (selectedChildRows.length > 0) {
      if ((this.footerRowData[0].newDiscount === 0 || this.footerRowData[0].newDiscount
        || this.footerRowData[0].agencyCommission === 0 || this.footerRowData[0].agencyCommission
        || this.footerRowData[0].productionCost === 0 || this.footerRowData[0].productionCost
        || this.footerRowData[0].defaultNetPrice === 0 || this.footerRowData[0].defaultNetPrice
        || this.footerRowData[0].finalNet === 0 || this.footerRowData[0].finalNet)) {

        for (const row of selectedChildRows) {
          if (this.footerRowData[0].defaultNetPrice === 0 || this.footerRowData[0].defaultNetPrice) {
            row.defaultNetPrice = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].defaultNetPrice);
            row.grossValue =
              this.calculateGrossValue(row.defaultNetPrice, row.defaultDiscount);
            row.finalNet = this.calculateFinalNet(row.grossValue, row.newDiscount);
            row.netNetPrice = this.calculateFinalNet(row.finalNet, row.agencyCommission);
            row.totalNetNet =
              this.calculateTotalNetNet(row.netNetPrice, row.productionCost);
          }

          if (this.footerRowData[0].newDiscount === 0 || this.footerRowData[0].newDiscount) {
            row.newDiscount = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].newDiscount);
            row.finalNet = this.calculateFinalNet(row.grossValue, row.newDiscount);
            row.netNetPrice = this.calculateFinalNet(row.finalNet, row.agencyCommission);
            row.totalNetNet =
              this.calculateTotalNetNet(row.netNetPrice, row.productionCost);
          }

          if (this.footerRowData[0].finalNet === 0 || this.footerRowData[0].finalNet) {
            row.finalNet = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].finalNet);
            row.newDiscount = this.calculateNewDiscount(row.grossValue, this.footerRowData[0].finalNet);
            row.netNetPrice = this.calculateFinalNet(row.finalNet, row.agencyCommission);
            row.totalNetNet =
              this.calculateTotalNetNet(row.netNetPrice, row.productionCost);
          }

          if (this.footerRowData[0].agencyCommission === 0 || this.footerRowData[0].agencyCommission) {
            row.agencyCommission = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].agencyCommission);
            row.netNetPrice = this.calculateFinalNet(row.finalNet, row.agencyCommission);
            row.totalNetNet =
              this.calculateTotalNetNet(row.netNetPrice, row.productionCost);
          }

          if (this.footerRowData[0].productionCost === 0 || this.footerRowData[0].productionCost) {
            row.productionCost = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].productionCost);
            row.totalNetNet =
              this.calculateTotalNetNet(row.netNetPrice, row.productionCost);
          }
        }
        this.resetApply();
        this.rowData = _.clone(this.rowData);
      }
    } else {
      this.toaster.logInfo('Select atleast one row!');
    }
  }

  reset = () => {
    this.resetApply();
    this.rowData = _.clone(this.rowData);
  }

  resetApply = () => {
    const columnArray = ['newDiscount', 'agencyCommission', 'productionCost', 'defaultNetPrice', 'finalNet'];
    for (const column of columnArray) {
      this.footerRowData[0][column] = '';
    }
  }

  toggleColumnVisible(colId, isVisible) {
    const isVisibleLocal = !isVisible;
    this.gridOptions.columnApi.setColumnVisible(colId, isVisibleLocal);
  }

  // Data functions
  getChildRows(groupingId?: string, filterOnly = true): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  getSelectedChildRows(groupingId?: string): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData, this.groupingField, groupingId);
  }

  //#region field value Calculation
  getTotalOfProperty(propertyName: string): number {
    const rows = AgCustomRowUtil.getChildRows(this.rowData, null, null, true);
    return AgCustomRowUtil.sumFieldValues(rows, propertyName);
  }

  // @ts-ignore
  calcAvgNewDiscount = (rows, colId) => {
    if (this.footerRowData[0][colId] === 0 || (this.footerRowData[0][colId] && this.footerRowData[0][colId] !== '')) {
      return LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0][colId]);
    } else {
      const totalGrossValue = this.getTotalOfProperty('grossValue');
      const totalFinalNetValue = this.getTotalOfProperty('finalNet');
      const avgDiscount = (totalGrossValue === 0 || totalFinalNetValue === 0) ? 0 : (1 - (totalFinalNetValue / totalGrossValue)) * 100;
      return avgDiscount.toFixed(2);
    }
  }

  // @ts-ignore
  calcAvgAgencyCommision = (rows, colId) => {
    if (this.footerRowData[0][colId] === 0 || (this.footerRowData[0][colId] && this.footerRowData[0][colId] !== '')) {
      return LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0][colId]);
    } else {
      const totalNetNetPrice = this.getTotalOfProperty('netNetPrice');
      const totalFinalNetValue = this.getTotalOfProperty('finalNet');
      const avgAgencyCommision = (totalNetNetPrice === 0 || totalFinalNetValue === 0) ? 0
        : (1 - (totalNetNetPrice / totalFinalNetValue)) * 100;
      return avgAgencyCommision.toFixed(2);
    }
  }

  calculateNewDiscount(grossPrice, finalNetPrice) {
    // Gross Price*[1-New Discount] = Final Net Price
    // 1- (Final Net Price/ Gross Price)= New Discount
    return ((1 - (parseFloat(finalNetPrice) / parseFloat(grossPrice))) * 100).toFixed(2);
  }

  calculateGrossValue(netDefaultPrice, defaultDiscount) {
    // NetDefaultPrice == SmartBRICSValue (FYI)
    // Net default price * 1/(1- Default Discount)
    return (parseFloat(netDefaultPrice) * (1 / (1 - (parseFloat(defaultDiscount) / 100)))).toFixed(2);
  }

  calculateFinalNet(grossValue, newDiscount) {
    // Gross Price*[1-New Discount]
    return (parseFloat(grossValue) * (1 - (parseFloat(newDiscount) / 100))).toFixed(2);
  }

  calculateNetNetPrice(finalNet, agencyCommission) {
    // (Final NetPrice*[1-Agency%])
    return (parseFloat(finalNet) * (1 - (parseFloat(agencyCommission) / 100))).toFixed(2);
  }

  calculateTotalNetNet(netNetPrice, productionCost) {
    return (parseFloat(netNetPrice) + parseFloat(productionCost)).toFixed(2);
  }
  //#endregion

  /**
   * download cart data in excel format
   */
  downloadExcel() {
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    // Add Header Row
    const sheetRow: ExcelCell[] = [];
    for (let j = 1; j < this.columnDefs.length; j++) {
      const cell: ExcelCell = {
        text: this.columnDefs[j].headerName,
        fontColor: '#FFFFFF',
        bgColor: '#000000',
        borderColor: '#CCCCCC',
        isFontBold: true
      };
      const col: ExcelColumn = {
        width: this.columnDefs[j].field === 'week' ? 15 : 12
      };
      excelColumn.push(col);
      sheetRow.push(cell);
    }
    sheetData.push(sheetRow);

    // Add Data rows

    let rowsInserted = 0;
    for (const row of this.rowData) {
      if (row.isParent) {
        continue;
      }

      let exportSheetRow: ExcelCell[] = [];
      exportSheetRow = this.getExportSheetRow(exportSheetRow, row, rowsInserted);

      rowsInserted++;
      sheetData.push(exportSheetRow);
    }
    const cartText = 'discount';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }

  getExportSheetRow(exportSheetRow: ExcelCell[], row, rowsInserted: number) {
    const decimalFieldsForFilter = [
      'grossValue',
      'defaultNetPrice',
      'newDiscount',
      'finalNet',
      'agencyCommission',
      'netNetPrice',
      'productionCost',
      'totalNetNet'
    ];
    for (let j = 1; j < this.columnDefs.length; j++) {
      let cellVal = row[this.columnDefs[j].field];
      if (this.selectFields.indexOf(this.columnDefs[j].field) > -1) {
        cellVal = row[`${this.columnDefs[j].field}_sort`];
      }
      if (decimalFieldsForFilter.indexOf(this.columnDefs[j].field) > -1 && cellVal != null && cellVal !== '') {
        // changes for SM-3735, column in exel was of type text, due to which auto sum was not working
        // cellVal = this.decimalPipe.transform(cellVal, '.2-2');
        //  cellVal = cellVal.split('.').join('');
        cellVal = Number(cellVal);
      }
      const cell: ExcelCell = {
        text: cellVal,
        fontColor: '#000000',
        bgColor: rowsInserted % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
        borderColor: '#CCCCCC'
      };
      exportSheetRow.push(cell);
    }

    return exportSheetRow;
  }

  trackByField(index, column) {
    return column?.colDef?.field;
  }
}
