import { Component, OnDestroy, OnInit } from '@angular/core';
import { CampaignDetailsService } from '../campaign-details/campaign-details.service';
import { Discount, FreeOFChargeResult, HistoryROC } from '../commercial.model';
import { SystemFlags } from '../../models';
import { DataShareService, LogHelperService, StateService } from '../../core/services';
import { CommercialService } from '../../core/services/commercial.service';
import { CommercialButtonService } from '../commercial-buttons/commercial-buttons.service';
import { CampaignStatus } from '../../core/enum';
import { DatePipe } from '@angular/common';
import { GLOBAL } from '../../core/utils/app.constant';
import { RequiredCampaignFields, RequiredBookingsFields, PopulateBookingDetailFields, PopulateCommercialFields } from '../../models/RequiredFields';
import { CommercialBase } from '../commercial-base';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-commercial-at',
  templateUrl: './commercial-at.component.html',
  styleUrls: ['./commercial-at.component.css']
})
export class CommercialAtComponent extends CommercialBase implements OnInit, OnDestroy {
  systemFlags = SystemFlags;
  hideDiscountGrid = false;
  brandData: any[];

  /**
   * @description commercial data
   * @memberof CommercialAtComponent
   */
  public commercialData;

  /**
   * @description Accordian for campaign details
   * @memberof CommercialAtComponent
   */
  hideCampaignDetails: boolean;
  /**
   * @description Accordian for booking details
   * @memberof CommercialAtComponent
   */
  hideBookingDetails: boolean;
  /**
    * @description set bookingStatusList
    * @memberof CommercialAtComponent
    */
  bookingStatusList = GLOBAL.bookingStatusList;

  /**
   * @description this will show the history of campaign updated in case of reason/cancel for change
   * @memberof CommercialAtComponent
   */
  historyROC = new HistoryROC();

  subs: Subscription[] = [];

  constructor(
    campaignDetailsService: CampaignDetailsService,
    dataShareService: DataShareService,
    commercialService: CommercialService,
    commercialButtonService: CommercialButtonService,
    logHelperService: LogHelperService,
    datePipe: DatePipe,
    stateService: StateService
  ) {
    super(stateService, logHelperService, commercialButtonService, dataShareService, commercialService, datePipe, campaignDetailsService);
  }

  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.setDefaultAgencySpecialistPerc();
    this.restoreCommercialData();
    this.subs.push(this.commercialService.discounts$.subscribe((res: Discount[]) => {
      this.campaignDetails.discounts = res;
      this.campaignDetails = _.clone(this.campaignDetails);
    }));
  }

  /**
   * @description get commercial data
   * @author Amit Mahida
   * @memberof CommercialAtComponent
   */
  restoreCommercialData() {
    this.commercialConfig.user = this.dataShareService.getUserModel();
    this.getCommercialDataFromService();
  }

  /**
   * @description get commercial data from service
   * @author Amit Mahida
   * @memberof CommercialAtComponent
   */
  getCommercialDataFromService(isManual = false) {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
    };

    this.commercialService.loadCommercialCampaignDetails(param).subscribe((data) => {
      if (data.status === 'OK') {
        this.commercialConfig.campaignStatus = data.data.campaignStatusId;
        this.commercialConfig.bookingStatus = data.data.bookingStatus;
        this.commercialConfig.uiControl = data.data.uiControl;
        this.commercialData = data.data;
        if (isManual) {
          this.dataShareService.updateBookingList(this.commercialData.bookingList);
        }

        this.commercialData['bookingStatusName'] =
        this.campaignDetailsService.getStatusNameFromId(data.data.campaignStatusId);
        const assignedToList = this.dataShareService.getInitialConfigByKey('assignedTo');
        this.campaignDetails = this.campaignDetailsService.populateCampaignDetails(
          this.commercialData, this.initialConfig, assignedToList);
        if (isManual) {
          this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT);
        } else {
          this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, undefined, undefined, {}, 2);
        }
        this.historyROC = this.campaignDetailsService.populateHistoryROC(this.historyROC, data.data.auditReasonsHistoryMap, this.commercialConfig.uiControl.historyROC);

      }
    });

  }

  /**
   * @description this is output event from commercialbutton when click on update/option/book/cancel button click
   * @author Amit Mahida
   * @param {*} status
   * @memberof CommercialAtComponent
   */
  onBookCampaign(status: number) {
    if (this.validateCommercialCampaign(status)) {
      status = status || this.campaignDetails.campaignStatusId;
      /*
        Need to trigger ROChange OR ROCancel in below scenario [SM-5283]
        1. It should be loaded campaign and there should be data in auditType(to populate drop downs) in Initial Config
        2. Trigger ROCancel OR
        3. Trigger ROChange
      */
      if (SystemFlags.isLoadedCampaign && (this.initialConfig.auditType && this.initialConfig.auditType.length > 0) &&
        (this.commercialButtonService.roCancel(this.campaignDetails, this.commercialConfig.uiControl, status) ||
          this.commercialButtonService.roChange(this.campaignDetails, this.commercialConfig.uiControl, 'bookingList', status))) {

        this.commercialButtonService.openReasonForChangeOverlay(
          this.commercialButtonService.populateDataParamReasonForChange(status, this.campaignDetails, this.commercialConfig.uiControl))
          .then((result: any) => {
            if (result) {
              this.campaignDetails = this.commercialButtonService.reasonForChangeOverlayThen(this.campaignDetails, result, status);

              const param = {
                bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
                data: JSON.stringify(this.createRequestJSONForCommercial(status))
              };
              this.commercialButtonService.validateCommercialScreenServiceRequest(param, status);
            }
          });
      } else if ((this.commercialConfig.uiControl['tradingEnabled'] && this.campaignDetails.grossMediaValue === 0) &&
        status === CampaignStatus.confirm) {
        this.commercialButtonService.openFreeOFChargeOverlay().then((result: FreeOFChargeResult) => {
          if (result) {
            this.campaignDetails.freeOfCharge = result['freeOfCharge'];
            let comment = '';
            if (result['freeOfCharge'] && result['freeOfCharge'].specifyOther) {
              comment = (this.commercialButtonService.startsWith(result['freeText'], 'FOC - ') ? '' : 'FOC - ') + result['freeText'];
            } else {
              comment = (this.commercialButtonService.startsWith(result['freeOfCharge'].auditReasonName, 'FOC - ') ? '' : 'FOC - ')
                + result['freeOfCharge'].auditReasonName;
            }
            result['freeOfCharge']['comment'] = comment;
            this.campaignDetails.comments = (this.campaignDetails.comments ?
              (`${this.campaignDetails.comments}\n\r${comment}`) : comment);
            const campaignStatus = CampaignStatus.confirm || this.campaignDetails.campaignStatusId;
            const param = {
              bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
              data: JSON.stringify(this.createRequestJSONForCommercial(campaignStatus))
            };
            this.commercialButtonService.validateCommercialScreenServiceRequest(param, this.campaignDetails.campaignStatusId);
          }
        });
      } else {
        const param = {
          bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
          data: JSON.stringify(this.createRequestJSONForCommercial(status))
        };
        this.commercialButtonService.validateCommercialScreenServiceRequest(param, status);
      }
    }
  }

  /**
   * @description commercial form validity
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof CommercialAtComponent
   */
  validateCommercialCampaign = (status) => {
    let message = null;
    const discounts = this.campaignDetails.discounts;
    if (discounts && discounts.length) {
      for (const disc of discounts) {
        if (!disc.campaignStatus || Number(disc.campaignStatus) === -1) {
          message = this.initialConfig.userBundle['commercial.error.book.dg.status.validation'];
          break;
        }
      }
    }
    if (!message) {
      const requiredFields: RequiredCampaignFields = new RequiredCampaignFields();
      requiredFields.assignedTo = true;
      message = this.validateCommonFields(requiredFields);
    }
    if (!message && this.campaignDetails.bookingList) {
      this.campaignDetails.bookingList.forEach((obj) => {
        const requiredBookingsFields: RequiredBookingsFields = new RequiredBookingsFields();
        if (!message) {
          message = this.validateBookingFields(obj, requiredBookingsFields);
        }
        if (!message && !obj.hideMediaControl) {
          message = this.validateMediaLength(obj, status);
        }
      });
    }
    return this.isValid(message);
  }

  /**
   * @description create requestJSON for passing to backend
   * @author Amit Mahida
   * @param {*} status
   * @returns
   * @memberof CommercialAtComponent
   */
  createRequestJSONForCommercial = (status) => {
    let campaignDetails = this.campaignDetails;
    if (!campaignDetails) {
      campaignDetails = this.stateService.getCommercialObject('campaignDetails');
    }
    const populate: PopulateCommercialFields = this.getCommercialFields();
    const temp = this.populateBookCommercialCampaignParam(status, campaignDetails, populate);
    const populateBooking: PopulateBookingDetailFields = this.getBookingDetailFields();
    temp['bookingList'] = this.populateBookingDetailsServiceObject(campaignDetails, 'bookingList', populateBooking);
    temp['discounts'] = this.campaignDetails.discounts;
    return temp;
  }

  /**
   * To override the commercial fields
   * This override fields are used to override the default value
   * @memberof CommercialAtComponent
   * @author Amit Mahida
   */
  getCommercialFields() {
    const populate: PopulateCommercialFields = super.getCommercialFields();
    populate.setPCFields();
    populate.dgNumber = true;
    populate.opportunityLineId = true;
    return populate;
  }

  /**
   * To override the booking detail fields
   * This override fields are used to override the default value
   * @memberof CommercialAtComponent
   * @author Amit Mahida
   */
  getBookingDetailFields() {
    const populate: PopulateBookingDetailFields = super.getBookingDetailFields();
    populate.comments = true;
    populate.setPICFields();
    populate.setCCBIBFields();
    populate.setPOORIFields();
    populate.financeAmount = true;
    populate.setPMDBFields();
    return populate;
  }

  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  async manuallyCPTUpdated() {
    const campaigndata = this.createRequestJSONForCommercial(this.campaignDetails.campaignStatusId);
    await this.updateCommercialInfoForCPT(campaigndata);
    this.getCommercialDataFromService(true);
  }
}
