import { Behavior } from './behavior';
import { OptionsBehavior } from './select-interfaces';
import { SbSelectComponent } from '../sb-select.component';
import { SelectItem } from './select-item';

export class ChildrenBehavior extends Behavior implements OptionsBehavior {
  public constructor(actor: SbSelectComponent) {
    super(actor);
  }

  public first(): void {
    this.actor.activeOption = this.actor.options[0].children[0];
    this.fillOptionsMap();
    this.ensureHighlightVisible(this.optionsMap);
  }

  public last(): void {
    this.actor.activeOption =
      this.actor
        .options[this.actor.options.length - 1]
        .children[this.actor.options[this.actor.options.length - 1].children.length - 1];
    this.fillOptionsMap();
    this.ensureHighlightVisible(this.optionsMap);
  }

  public prev(): void {
    const indexParent = this.actor.options
      .findIndex((option: SelectItem) => this.actor.activeOption.parent && this.actor.activeOption.parent.id === option.id);
    const index = this.actor.options[indexParent].children
      .findIndex((option: SelectItem) => this.actor.activeOption && this.actor.activeOption.id === option.id);
    this.actor.activeOption = this.actor.options[indexParent].children[index - 1];
    if (!this.actor.activeOption && this.actor.options[indexParent - 1]) {
      this.actor.activeOption = this.actor
        .options[indexParent - 1]
        .children[this.actor.options[indexParent - 1].children.length - 1];
    }
    if (!this.actor.activeOption) {
      this.last();
    }
    this.fillOptionsMap();
    this.ensureHighlightVisible(this.optionsMap);
  }

  public next(): void {
    const indexParent = this.actor.options
      .findIndex((option: SelectItem) => this.actor.activeOption.parent && this.actor.activeOption.parent.id === option.id);
    const index = this.actor.options[indexParent].children
      .findIndex((option: SelectItem) => this.actor.activeOption && this.actor.activeOption.id === option.id);
    this.actor.activeOption = this.actor.options[indexParent].children[index + 1];
    if (!this.actor.activeOption && this.actor.options[indexParent + 1]) {
      this.actor.activeOption = this.actor.options[indexParent + 1].children[0];
    }
    if (!this.actor.activeOption) {
      this.first();
    }
    this.fillOptionsMap();
    this.ensureHighlightVisible(this.optionsMap);
  }

  public filter(query: RegExp): void {
    const options: SelectItem[] = [];
    const optionsMap: Map<string, number> = new Map<string, number>();
    let startPos = 0;
    for (const si of this.actor.itemObjects) {
      const children: SelectItem[] = si.children.filter((option: SelectItem) => query.test(option.text));
      startPos = si.fillChildrenHash(optionsMap, startPos);
      if (children.length > 0) {
        const newSi = si.getSimilar();
        newSi.children = children;
        options.push(newSi);
      }
    }
    this.actor.options = options;
    if (this.actor.options.length > 0) {
      this.actor.activeOption = this.actor.options[0].children[0];
      super.ensureHighlightVisible(optionsMap);
    }
  }
}
