<app-sb-modal-wrapper [title]="initialConfig.userBundle['workspace.share.bric.time.heading']"
    [titleBackgroundColor]="resolveObject.brick.color" [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)"
    (saved)="onModalSaved($event)">
    <div [ngClass]="{'readOnly' : readOnlyModal}">
        <div class="control_group row">

            <div class="col-md-10 frequency" *ngIf="initialConfig.uiControl.sotFrequencyEnabled"
                style="margin-bottom: 10px;">
                <label>{{initialConfig.userBundle['workspace.share.bric.time.frequency']}}:
                    <span class="right">1 /</span>
                </label>
                <input type="text" class="form_control txt" name="txtfrequency" [(ngModel)]="shareOfTime.frequency"
                    (keyup)="calculateSOT()" [imask]="frequencyInputMask"
                    [unmask]="maskService.unmask.typed" />
            </div>

            <div class="col-md-7 left">
                <input type="radio" [(ngModel)]="shareOfTime.sotType" class="radio small" value="0" id="rbt-sottype-0">
                <label for="rbt-sottype-0"
                    *ngIf="!initialConfig.uiControl.sotFrequencyEnabled">{{initialConfig.userBundle['workspace.share.bric.time.sot']}}
                    :</label>
                <label for="rbt-sottype-0" *ngIf="initialConfig.uiControl.sotFrequencyEnabled">{{initialConfig.userBundle['workspace.share.bric.time.commercialSot']}}
                    :</label>
                <input type="text" class="form_control txt" name="txtShareOfTime"
                    (keyup)="calculateSOT()" [(ngModel)]="shareOfTime.sot"
                    [imask]="sotInputMask" [unmask]="maskService.unmask.typed" [disabled]="shareOfTime.sotType != '0'"
                    #sot />
                <span class="percentage-illumination">%</span>
            </div>

            <div class="col-md-5 right" *ngIf="initialConfig.uiControl.sotFrequencyEnabled" style="padding: 0px;">
                <label>{{initialConfig.userBundle['workspace.share.bric.time.sot']}} :</label>
                <input type="text" class="form_control txt" name="txtDummySot" [(ngModel)]="shareOfTime.dummySot"
                    [disabled]="true" />
                <span class="percentage-illumination">%</span>
            </div>

            <div class="col-md-7 left" style="clear: both; padding-top: 10px;" *ngIf="initialConfig.uiControl.sotSlotsEnabled">
                <input type="radio" [(ngModel)]="shareOfTime.sotType" [disabled]="productValidations"
                    class="radio small" value="1" id="rbt-sottype-1">
                <label for="rbt-sottype-1">{{initialConfig.userBundle['workspace.share.bric.time.slots'] || 'Slots'}}
                    :</label>
                <input type="text" class="form_control txt" name="txtSotSlot" style="width: 30px;padding: 0px;"
                    [(ngModel)]="shareOfTime.framesSlots" [disabled]="shareOfTime.sotType != '1'"
                    [imask]="sotTypeInputMask" [unmask]="maskService.unmask.typed" />
                <span class="percentage-illumination" style="left: 0px;">/ {{shareOfTime.maxFramesSlotsText}}</span>
            </div>
        </div>
      </div>
        <div footer class="right pcm-validation-info" *ngIf="productValidations && productValidationsLength"
            appMasterTooltip [tooltipName]="'mastertooltip'" [alwaysDisplay]="false">
            <img height="20px" width="20px" src="assets/img/info-image.png" alt="info-image" />
            <div class="mastertooltip modaltooltip">
                <div class="triangle-up"></div>
                <h5>Valid SOT Range</h5>
                <br>
                Min : {{productValidations.min}}
                <br>
                Max : {{productValidations.max}}
                <br>
                Increment : {{productValidations.increment}}
            </div>
        </div>

</app-sb-modal-wrapper>
