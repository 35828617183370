import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { DataShareService } from '../../core/services/data-share.service';
import { InitialConfigModel } from '../../models';
import { DateType } from '../status.enum';
import { NgbInputDatepicker, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerHelper } from '../../core/components/ngb-datepicker/ngb-datepicker-helper';

@Component({
  selector: 'app-ag-calendar-cell-editor',
  templateUrl: './ag-calendar-cell-editor.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AgCalendarCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  /**
   * Ag-grid cell param object
   */
  private params: any;
  /**
   * whether to cancel cell editing before it start
   */
  private cancelBeforeStart = false;
  /**
   * cell value
   */
  public value: NgbDateStruct;
  /**
   * min date allowed in calendar
   */
  public minDate: NgbDateStruct;
  /**
   * max date allowed in calendar
   */
  public maxDate: NgbDateStruct;
  /**
   * max date allowed in calendar
   */
  private dateInterval = 1;

  private dateType: DateType = DateType.none;
  /**
   * html input element
   */
  @ViewChild('input') input: NgbInputDatepicker;

  public initialConfig: InitialConfigModel;
  /**
   * @description specific day properties cached object for ngbDatePicker day template
   * @memberof AgCalendarCellEditorComponent
   */
  dayObjCache = {};
  isDisableAllDays = false;

  constructor(
    private dataShareService: DataShareService,
    private ngbDateParserService: NgbDateParserFormatter) {
    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  /**
   * Ag-grid cell init method
   * @param params - cell param object
   */
  agInit(params: any): void {
    this.params = params;
    let minDate;
    let maxDate;
    this.value = this.params.value;
    if (this.params.cellEditable) {
      this.cancelBeforeStart = !this.params.cellEditable(this.params.node);
    }
    if (this.params.minDate) {
      minDate = this.params.minDate(this.params);
      this.minDate = NgbDatepickerHelper.convertDateToDateStruct(minDate);
    }
    if (this.params.maxDate) {
      maxDate = this.params.maxDate(this.params);
      this.maxDate = NgbDatepickerHelper.convertDateToDateStruct(maxDate);
    }
    if (minDate && maxDate && minDate > maxDate) {
      this.maxDate = this.minDate;
      this.isDisableAllDays = true;
    }
    if (this.params.dateInterval) {
      this.dateInterval = this.params.dateInterval(this.params);
    }
    if (this.params.dateType) {
      this.dateType = this.params.dateType;
    }
    const colId = `${this.params.column.colId}Req`;
    const val = new Date(this.params.node.data[colId]);
    if (!isNaN(val.getTime())) {
      this.value = NgbDatepickerHelper.convertDateToDateStruct(val);
    }
  }

  /**
   * ag-grid get cell value method
   */
  getValue(): any {
    if (typeof (this.value) === 'object') {
      return this.ngbDateParserService.format(this.value);
    }
    return this.value;
  }

  /**
   * ag-grid cell cancel edit before start method
   */
  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
    return false;
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  /**
   * Angular after view init life cycle hook
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.input.open();
    }, 100);
  }

  /**
   * open start/end date datepicker on click
   * @param $event click event object
   * @param datePicker start/end date picker object
   */
  openDatePicker($event: Event, datePicker: NgbInputDatepicker): void {
    $event.preventDefault();
    $event.stopPropagation();
    datePicker.toggle();
  }

  /**
   * close date picker on out-side click
   * @param event click event object
   * @param datePicker start/end date picker object
   */
  closeDatePickerOutsideClick(event: any, datePicker: NgbInputDatepicker): void {
    if (datePicker.isOpen()) {
      if (event.target.offsetParent == null && event.target.nodeName !== 'OPTION') {
        datePicker.close();
      } else if (event.target.offsetParent.nodeName !== 'NGB-DATEPICKER') {
        datePicker.close();
      }
    }
  }

  markDayDisabled = (date: NgbDateStruct): boolean => {
    const daystring = this.cacheStartDateObj(date, this.isDisableAllDays);
    return this.dayObjCache[daystring].isDisabled;
  }

  /**
   * @description prepare a startDate day object of ngb-datepicker
   * @author Alkesh Shah
   * @param {NgbDateStruct} date - current date
   * @returns
   * @memberof RangeComponent
   */
  prepareStartDayObj(date: NgbDateStruct, disabled: boolean) {
    const obj = {
      isToday: false,
      isDisabled: false,
      isWeekday: false
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selecteddaye: any = NgbDatepickerHelper.convertDateStructToDate(date);
    if (disabled) {
      obj.isDisabled = true;
    } else if (NgbDatepickerHelper.isEqual(today, selecteddaye)) {
      obj.isToday = true;
    } else {
      if (this.dateInterval && this.dateInterval === 7) {
        this.seStartDayObjectForDateInterval7(selecteddaye, obj);
      } else if (this.dateType === DateType.start && this.dateInterval && this.dateInterval === 1) {
        const weekNo = selecteddaye.getWeek() + 1;
        const condition = ((weekNo % this.initialConfig.uiControl.defaultInChargeLength === 0)
          && (selecteddaye.getDay() === this.initialConfig.uiControl.defaultInChargeDay));
        obj.isWeekday = condition;
      } else if (this.dateType === DateType.end) {
        const weekNo = selecteddaye.getWeek();
        const condition = ((weekNo % this.initialConfig.uiControl.defaultInChargeLength === 0)
          && (selecteddaye.getDay() === (this.initialConfig.uiControl.defaultInChargeDay - 1)));
        obj.isWeekday = condition;
      }
    }
    return obj;
  }

  seStartDayObjectForDateInterval7(selecteddaye: any, obj: any) {
    if (this.dateType === DateType.start) {
      const weekNo = selecteddaye.getWeek() + 1;
      const condition = ((weekNo % this.initialConfig.uiControl.defaultInChargeLength === 0)
        && (selecteddaye.getDay() === this.initialConfig.uiControl.defaultInChargeDay));
      if (condition) {
        obj.isWeekday = true;
      } else if (selecteddaye.getDay() === this.initialConfig.uiControl.defaultInChargeDay) {
        obj.isWeekday = false;
      } else {
        obj.isDisabled = true;
      }
    } else if (this.dateType === DateType.end) {
      const weekNo = selecteddaye.getWeek();
      const condition = ((weekNo % this.initialConfig.uiControl.defaultInChargeLength === 0)
        && (selecteddaye.getDay() === (this.initialConfig.uiControl.defaultInChargeDay - 1)));
      if (condition) {
        obj.isWeekday = true;
      } else if (selecteddaye.getDay() === (this.initialConfig.uiControl.defaultInChargeDay - 1)) {
        obj.isWeekday = false;
      } else {
        obj.isDisabled = true;
      }
    }

  }

  /**
   * @description cache specific days object if is not exists
   * @author Alkesh Shah
   * @param {NgbDateStruct} date - current date object
   * @returns {string}
   * @memberof RangeComponent
   */
  cacheStartDateObj(date: NgbDateStruct, disabled: boolean): string {
    const daystring = NgbDatepickerHelper.getDateString(date);
    if (!this.dayObjCache[daystring]) {
      this.dayObjCache[daystring] = this.prepareStartDayObj(date, disabled);
    }
    return daystring;
  }

  /**
   * @description get the specific dates property
   * @author Alkesh Shah
   * @param {NgbDateStruct} date current day
   * @param {string} key property key to retrive
   * @returns {boolean}
   * @memberof RangeComponent
   */
  getStartDayProp(date: NgbDateStruct, disabled: boolean, key: string): boolean {
    const daystring = this.cacheStartDateObj(date, disabled);
    return this.dayObjCache[daystring][key];
  }

  /**
   * called when date selection change
   * @param dateText - selected date text
   * @param inst - calendar instance
   */
  onDateSelect(inst: NgbDateStruct) {
    this.value = inst;
    const newDate = NgbDatepickerHelper.convertDateStructToDate(inst);
    if (this.params.onDateSelect) {
      this.params.onDateSelect(this.params, newDate);
    }
  }
}
