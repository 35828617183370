import { ExpandDirection } from '../models/workspace/cell-position';

export class Brick {
  constructor(
    public bricid: number,
    public editable: boolean,
    public bricname: string,
    public color: string,
    public isprimary: boolean,
    public isUsed: boolean,
    public cellIndex?: number,
    public rowIndex?: number,
    public audienceCategoryGroupId?: number,
    public categoryid?: number,
    public categoryName?: string,
    public expandDirection: ExpandDirection = ExpandDirection.None,
    public targetRowIndex = -1, // This will be used when user will drop new brick on different type of row
    public disabled = false, // When we hover on the product, The bric will be disabled if It does not allowed in the product as an optional bric
    public optionStack?: boolean,
    public hide?: boolean,
    public optionalForProduct?: boolean, // This flag is used to show the brics on Side Bar that are optional for the product //
    public isObjective?: boolean // This flag is used to show the brics on Side Bar that are supported on objective workspace

  ) { }
}

export class BrickRow {
  constructor(
    public items: Brick[],
    public name: string,
    public rowIndex: number,
    public handle: boolean
  ) { }
}
