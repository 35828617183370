import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { ExcelCell, ExportToExcel, ExcelColumn } from '../../../core/utils/exportToExcel';
import { GridOptions, ColDef } from 'ag-grid-community';
import { NgbDropdown, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataShareService } from '../../../core/services/data-share.service';
import { LocaleData } from '../../../core/utils/LocaleData';
import { AgDateCellRendererComponent } from '../../../commercial/discount-grid/ag-grid-support/index';
import { DatePipe, DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-reshuffle-process',
  templateUrl: './reshuffle-process.component.html',
  styleUrls: ['../../../commercial/discount-grid/ag-grid-custom-style.css', '../../../commercial/discount-grid/ag-grid-col-select-style.css', './reshuffle-process.component.css']
})
export class ReshuffleProcessComponent implements OnInit, OnDestroy {

  @Input() resolveObject;

  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  titleColor = '#03a9f4';

  userBundle: any;

  campaignGridData: any = [];

  /**
   * Ag-Grid Options Object
   */
  campaignGridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  campaignColumnDefs: ColDef[];

  expireTime = 0;
  expireMins = 0;
  intervalObj: any;

  constructor(
    private dataShareService: DataShareService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private activeModel: NgbActiveModal
  ) { }

  ngOnInit() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.campaignGridData = this.resolveObject.data.reshuffleSummary;
    this.expireTime = this.resolveObject.data.expireTime;
    this.expireTime = this.expireTime / 1000; // converting milliseconds to seconds
    this.expireMins = 0;
    if (this.expireTime > 60) {
      this.expireMins = parseInt(String(this.expireTime / 60), 10);
      this.expireTime = parseInt(String(this.expireTime - (this.expireMins * 60)), 10);
    }
    this.intervalObj = setInterval(this.startTimer, 1000);
    this.prepareColDef1();
    this.prepareColDef2();
    // we pass an empty gridOptions in, so we can grab the api out
    this.campaignGridOptions = {
      defaultColDef: {
        sortable: true,
        resizable: true
      },
      context: {
        componentParent: this
      },
      onGridReady: (params) => {
        setTimeout(() => {
          params.api.sizeColumnsToFit();
        }, 500);
      },
      scrollbarWidth: 10,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressScrollOnNewData: true,
      suppressMovableColumns: true,
    } as GridOptions;
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  startTimer = () => {
    this.expireTime--;
    if (this.expireTime <= 0 && this.expireMins <= 0) {
      this.activeModel.dismiss('timeout');
      this.stopTimer();
    } else if (this.expireTime <= 0) {
      this.expireTime = 60;
      this.expireMins--;
    }
  }

  stopTimer() {
    clearInterval(this.intervalObj);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen() && $event.button !== 2 && !dropdownEle._isEventFromToggle($event)
    && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
      dropdownEle.close();
    }
  }
  prepareColDef1() {
    this.campaignColumnDefs = [
      {
        headerName: this.userBundle['reshuffle.text.campaignRef'] || 'Campaign Ref',
        field: 'campaignReference',
        suppressMenu: true,
        width: 110,
        minWidth: 110,
      },
      {
        headerName: this.userBundle['reshuffle.text.title'] || 'Title',
        field: 'title',
        suppressMenu: true,
        width: 150,
        minWidth: 150,
      },
      {
        headerName: this.userBundle['reshuffle.text.campaignGroup'] || 'Campaign Group',
        field: 'campaignGroup',
        suppressMenu: true,
        width: 130,
        minWidth: 130,
      },
      {
        headerName: this.userBundle['reshuffle.text.campaignStatus'] || 'Campaign Status',
        field: 'campaignStatus',
        suppressMenu: true,
        width: 150,
        minWidth: 150,
      },
      {
        headerName: this.userBundle['reshuffle.text.startDate'] || 'Start Date',
        field: 'startDateBefore',
        suppressMenu: true,
        width: 100,
        minWidth: 100,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          dateFormat: LocaleData.displayDateFormat
        }
      },
      {
        headerName: this.userBundle['reshuffle.text.endDate'] || 'End Date',
        field: 'endDateBefore',
        suppressMenu: true,
        width: 100,
        minWidth: 100,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          dateFormat: LocaleData.displayDateFormat
        }
      },
      {
        headerName: this.userBundle['reshuffle.text.advertiser'] || 'Advertiser',
        field: 'advertiser',
        suppressMenu: true,
        width: 150,
        minWidth: 150,
      },
      {
        headerName: this.userBundle['reshuffle.text.brand'] || 'Brand',
        field: 'brand',
        suppressMenu: true,
        width: 150,
        minWidth: 150,
      },
      {
        headerName: this.userBundle['reshuffle.text.salesPerson'] || 'Sales Person',
        field: 'salesPerson',
        suppressMenu: true,
        width: 100,
        minWidth: 100,
      },
      {
        headerName: this.userBundle['reshuffle.text.adminPerson'] || 'Admin Person',
        field: 'adminPerson',
        suppressMenu: true,
        width: 100,
        minWidth: 100,
      }
    ];
  }

  prepareColDef2() {
    const remainingDefs = [{
      headerName: this.userBundle['reshuffle.text.nuFrameBefore'] || 'Number Frame Before',
      field: 'numberOfFramesBefore',
      suppressMenu: true,
      width: 160,
      minWidth: 160,
      cellRenderer: this.decimalCellRenderer
    },
      {
        headerName: this.userBundle['reshuffle.text.nuFrameAfter'] || 'Number Frame After',
        field: 'numberOfFramesAfter',
        suppressMenu: true,
        width: 150,
        minWidth: 150,
        cellRenderer: this.decimalCellRenderer
      },
      {
        headerName: this.userBundle['reshuffle.text.impBefore'] || 'Impression Before',
        field: 'impressionsBefore',
        suppressMenu: true,
        width: 150,
        minWidth: 150,
        cellRenderer: this.decimalCellRenderer
      },
      {
        headerName: this.userBundle['reshuffle.text.impAfter'] || 'Impression After',
        field: 'impressionsAfter',
        suppressMenu: true,
        width: 150,
        minWidth: 150,
        cellRenderer: this.decimalCellRenderer
      },
      {
        headerName: this.userBundle['reshuffle.text.sbValueBefore'] || 'Smartbrics value Before',
        field: 'smartBricsValueBefore',
        suppressMenu: true,
        width: 180,
        minWidth: 180,
        cellRenderer: this.decimalCellRenderer
      },
      {
        headerName: this.userBundle['reshuffle.text.sbValueAfter'] || 'Smartbrics value After',
        field: 'smartBricsValueAfter',
        suppressMenu: true,
        width: 180,
        minWidth: 180,
        cellRenderer: this.decimalCellRenderer
      }];
    this.campaignColumnDefs = [...this.campaignColumnDefs, ...remainingDefs];
  }


  decimalCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    let text = params.value;
    if (params.column.colId === 'numberOfFramesBefore' ||
      params.column.colId === 'numberOfFramesAfter' ||
      params.column.colId === 'impressionsBefore' ||
      params.column.colId === 'impressionsAfter'
    ) {
      text = this.decimalPipe.transform(text, '.0-0');
    } else {
      text = this.decimalPipe.transform(text, '.2-2');
    }
    eDiv.innerHTML = text;
    return eDiv;
  }

  /**
   * download reshuffle detail data in excel format
   */
  downloadExcel() {
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    // Add Header Row
    let sheetRow: ExcelCell[] = [];
    for (const column of this.campaignColumnDefs) {
      const cell: ExcelCell = {
        text: column.headerName,
        fontColor: '#FFFFFF',
        bgColor: '#000000',
        borderColor: '#CCCCCC',
        isFontBold: true
      };
      const col: ExcelColumn = {
        width: 12
      };
      excelColumn.push(col);
      sheetRow.push(cell);
    }
    sheetData.push(sheetRow);

    // Add Data rows
    let rowsInserted = 0;
    for (const data of this.campaignGridData) {
      sheetRow = [];
      for (const column of this.campaignColumnDefs) {
        let cellVal = data[column.field];
        if ((column.field === 'startDateBefore' || column.field === 'endDateBefore') && cellVal) {
          cellVal = this.datePipe.transform((new Date(cellVal)), LocaleData.displayDateFormat);
        }
        const cell: ExcelCell = {
          text: cellVal,
          fontColor: '#000000',
          bgColor: rowsInserted % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
          borderColor: '#CCCCCC'
        };
        sheetRow.push(cell);
      }
      rowsInserted++;
      sheetData.push(sheetRow);
    }
    const cartText = 'reshuffle_detail';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }

  onModalClosed(event) {
    event.activeModal.dismiss('cancelclick');
  }

  onModalSaved(event) {
    event.activeModal.close('ok');
  }

}
