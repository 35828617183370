import * as moment from 'moment';
const config = require('../../../../../../package.json');
export abstract class GLOBAL {

  /**
   * @description Hold initial config URL
   * @static
   * @memberof GLOBAL
   */
  public static BRIC_CONFIG_URL = '';

  /**
   * @description Application version would be taken from package.json
   * @static
   * @memberof GLOBAL
   */
  public static frontEndVersion = config.version;

  /**
   * @description Service base url would be set based on the environment provided
   * @static
   * @memberof GLOBAL
   */
  public static SERVICE_BASE_URL = '';

  /**
   * @description To hold main URL
   * @static
   * @memberof GLOBAL
   */
  public static BASE_URL = '';

  /**
   * @description This will be over-written by the given environment
   * @static
   * @memberof GLOBAL
   */
  public static HTTP_METHOD = '';

  /**
   * @description Concertina data key separator
   * @static
   * @memberof GLOBAL
   */
  public static CONCERTINA_SEPARATOR = '|';

  /**
   * @description Tab id on which swapping is allowed on concertina
   * @static
   * @memberof GLOBAL
   */
  public static vpSwappingTabId = 7;

  /**
   * @description Index tab used to map selected audience object in concertina data
   * @static
   * @memberof GLOBAL
   */
  public static indexTabId = 10;

  /**
   * @description Default selected tab on concertina
   * @static
   * @memberof GLOBAL
   */
  public static DEFAULT_CONCERTINA_SELECTED_TAB = 0;

  /**
   * @description
   * @static
   * @memberof GLOBAL
   */
  public static CONCERTINA_EXCEL_FILE_NAME = 'Concertina';

  /**
   * @description Default title background color for modal popups
   * @static
   * @memberof GLOBAL
   */
  public static DEFAULT_TITLE_BACKGROUND_COLOR = '#03A9F4';

  /**
   * @description Legend count on result page map
   * @static
   * @memberof GLOBAL
   */
  public static LEGEND_COUNT = 10;

  /**
   * @description Legend array to display colors on map markers
   * @static
   * @memberof GLOBAL
   */
  public static LEGEND_ARRAY = [
    { Key: '', Color: 'legend-f0391a', Selected: false },
    { Key: '', Color: 'legend-fe851f', Selected: false },
    { Key: '', Color: 'legend-fff000', Selected: false },
    { Key: '', Color: 'legend-0acc77', Selected: false },
    { Key: '', Color: 'legend-38E0EB', Selected: false },
    { Key: '', Color: 'legend-FE70A2', Selected: false },
    { Key: '', Color: 'legend-7fcafe', Selected: false },
    { Key: '', Color: 'legend-ffcc00', Selected: false },
    { Key: '', Color: 'legend-f971db', Selected: false },
    { Key: '', Color: 'legend-FEB89F', Selected: false },
    { Key: '', Color: 'legend-BDD51D', Selected: false }
  ];

  /**
   * @description Default latitude center
   * @static
   * @memberof GLOBAL
   */
  public static DEFAULT_LATITUDE = 51.678418;

  /**
   * @description Default longitude center
   * @static
   * @memberof GLOBAL
   */
  public static DEFAULT_LONGITUDE = 7.809007;

  /**
   * @description booking statuses
   * @static
   * @memberof GLOBAL
   */
  public static BOOKING_STATUS = {
    SEARCH: -1,
    DRAFT: 1,
    OPTION: 2,
    PROPOSAL: 3,
    CONFIRMED: 4,
    CANCELLED: 5,
    OPTIONSTACK: 6,
    REQOPT: 7,
    REQCONF: 8,
    FLEX_AVAIL: 10,
    FLEX_OFFER: 11,
  };

  /**
   * @description default width of the brick on workspace
   * @static
   * @memberof GLOBAL
   */
  public static CELLWIDTH = 160;

  /**
   * @description default height of the brick on workspace
   * @static
   * @memberof GLOBAL
   */
  public static CELLHEIGHT = 84;

  /**
   * @description Stores current session id to be sent in each request header
   * @static
   * @memberof GLOBAL
   */
  public static BOS_SESSIONID = 0;

  /**
   * @description holds the current campaign id
   * @static
   * @memberof GLOBAL
   */
  public static BRIC_CAMPAIGN_ID = 0;

  /**
   * @description session timeout threshold
   * @static
   * @memberof GLOBAL
   */
  public static MAX_INACTIVE_INTERVAL = 50; // This will be overwritten from InitialConfig

  /**
   * @descriptio Params to be set for all selection in PCM mode
   * @static
   * @memberof GLOBAL
   */
  public static allSelection = {
    '-99': {
      '-99': -99
    }
  };

  /**
   * @description SelectionId of product catelogue brick
   * @static
   * @memberof GLOBAL
   */
  public static productBricSelectionId = 35;

  /**
   * @description date parsing format to parse the dates before sending them to server
   * @static
   * @memberof GLOBAL
   */
  public static DATE_PARSE_FORMAT = 'yyyy-MM-dd';

  /**
   * @description will be set from initial config uiControl if google analytics is enabled or not
   * @static
   * @memberof GLOBAL
   */
  public static googleAnalyticsEnabled = false;

  /**
   * @description returns true if internet is available
   * @static
   * @memberof GLOBAL
   */
  public static isOnline = false;

  /**
   * @description returns bookinglistStatus
   * @static
   * @memberof GLOBAL
   */
  public static bookingStatusList = {
    Option: 2,
    Confirmed: 4,
    Cancelled: 5
  };

  /**
   * @description Holds the evironment id having
   * @static
   * @memberof GLOBAL
   */
  public static environmentsHavingCommaAsDecimal = [
    18, // DE
    16, // FI
    26, // DK
    28, // IT
  ];

  /**
   * @description tab id of frames not selected in advance availibity mode
   * @static
   * @memberof GLOBAL
   */
  public static concertinaNotSelectedTab = 11;

  /**
   * @description Geo tree configuration in Location Brick/Filter
   * @static
   * @memberof GLOBAL
   */
  public static geoTree = {
    106: [],
    107: [],
    108: []
  };

  /**
   * @description Geo area configuration in Location Brick/Filter
   * @static
   * @memberof GLOBAL
   */
  public static geoArea = {
    area106: 106,
    area107: 107,
    area108: 108,
  };

  public static campaignTypeGroupCode = {
    GROUP_STANDARD: 'GROUP_STANDARD',
    GROUP_PMP: 'GROUP_PMP',
    GROUP_EXTERNAL_ACCESS: 'GROUP_EXTERNAL_ACCESS',
    GROUP_AUTOMATED_TRADING: 'GROUP_AUTOMATED_TRADING',
    GROUP_FILLER: 'GROUP_FILLER'
  };

  public static continuousFromDate = moment(new Date(2020, 0, 1)).startOf('isoWeek').toDate();

  public static MOMENT_REQ_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

  public static localSolverEnabled = false;

  public static RESHUFFLE_ENGINE = {
    RESHUFFLE_ENGINE_GISGO: 'GISGO',
    RESHUFFLE_ENGINE_LS: 'LOCALSOLVER',
    RESHUFFLE_ENGINE_VIOOH: 'VIOOH',
  };

  public static TOLERANCE_STATUSES = {
    GREEN: 1,
    ORANGE: 2,
    RED: 3,
  };

  public static defaultPriority = 0;
  public static defaultWeight = 1;

  public static SWAP_DISPLAY_DATE_FORMAT = 'shortDate';
  public static SWAP_DISPLAY_TIME_FORMAT = 'HH:mm';


  public static USER: any = {};
  public static LOCAL_JSON = false;
  public static ACCESS_TOKEN = '';
  public static authConfig = {};

  public static sortArrayByKey = (array: any[], key: string): any[] => {
    array.sort((a, b) => {
      const x = a[key];
      const y = b[key];
      let value = 0;
      if (x < y) {
        value = -1;
      } else if (x > y) {
        value = 1;
      }
      return value;
    });
    return array;
  }
}

// Environmentid for commercial
// Default Commercial - ES (Spain) // backend will not provide environmentId
// Dubai - 31
// Finland - 16
// Singapore - 74
// Shanghai - 72
// Australia - 70
// Belgium - 11
// Denmark - 26
// Germany - 18
// Hong Kong 71
// Italy 28
// US - 50
// UK - 1
// NL - 25
// campaign summary columns
//  0 = dates,
//  1 = audience,
//  2 = price,
//  3 = frames,
//  4 = average Qoc,
//  5 = performance Rating
