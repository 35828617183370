import { Component, OnInit, Input, QueryList, ViewChildren } from '@angular/core';
import { PricingTagBase } from '../pricing-tag-base';
import {
  DataShareService,
  CellAttributeService,
  LogHelperService,
  BrickBaseService,
  SharedService,
} from '../../../core/services';
import { TagInputDropdown } from 'ngx-chips';
import { CellValues } from '../../../models/workspace';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';
import { SystemFlags } from '../../../models/system-flags';
import * as _ from 'lodash';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from '../../../geo-map/confirmation/confirmation.component';
import { LegendPosition } from 'ag-grid-community';
@Component({
  selector: 'app-pricing-tag-modal',
  templateUrl: './pricing-tag-modal.component.html',
  styleUrls: ['./pricing-tag-modal.component.css']
})
export class PricingTagModalComponent extends PricingTagBase implements OnInit {
  /**
   * @description Holds selected values from this modal
   * @type {Brick}
   * @memberof VolumeComponent
   */
  @Input() resolveObject: CellValues;
  /**
   * Tag Input dropdown view children instance.
   */
  @ViewChildren(TagInputDropdown) public dropdownTagComp: QueryList<TagInputDropdown>;
  readOnlyModal = false;

  /**
   * ngbModal options
   * @type {NgbModalOptions}@memberof GeoMapComponent
   */
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'sm'
  };

  objectiveSetting = {
    greenTolerance: 0,
    orangeTolerance: 0,
    priority: 0
  }

  isVioohAllocationEngineSelected = false;

  /**
   * Creates an instance of PricingTagModalComponent.
   * @author Amit Mahida
   * @param {DataShareService} dataShareService
   * @param {SharedService} sharedService
   * @param {BrickBaseService} brickBaseService
   * @param {LogHelperService} logHelperService
   * @memberof PricingTagModalComponent
   */
  constructor(
    dataShareService: DataShareService,
    brickBaseService: BrickBaseService,
    sharedService: SharedService,
    appHeaderService: AppHeaderService,
    private cellAttributeService: CellAttributeService,
    private logHelperService: LogHelperService,
    private modalService: NgbModal
  ) {
    super(dataShareService, brickBaseService, sharedService, appHeaderService);
  }

  ngOnInit() {
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.init(this.resolveObject);
    let networkGroupsSelectedId = [];
    if (SystemFlags.isLoadedCampaign && this.resolveObject.selectedValues) {
      networkGroupsSelectedId = Object.keys(this.resolveObject.selectedValues);
    }
    if (this.uiControl.openRateCardsEnabled) {
      this.modeVisible = true;
    } else {
      this.modeVisible = false;
    }
    this.isVioohAllocationEngineSelected = this.resolveObject?.reshufflingEngine === 'VIOOH' ? true : false;
    if (this.resolveObject.selectedValues && this.resolveObject.selectedValues[PricingTagBase.selectionId]
      && this.resolveObject.selectedValues[PricingTagBase.selectionId][0]) {
        if (this.resolveObject.selectedValues[PricingTagBase.selectionId][0].openRateCard) {
          this.modeVisible = true;
          this.setRateCardMode();
        }
        if (!this.uiControl?.isNetworkAsaFilterEnabled && this.isVioohAllocationEngineSelected) {
          this.objectiveSetting.greenTolerance = this.resolveObject.selectedValues[PricingTagBase.selectionId][0].greenTolerance || 0;
          this.objectiveSetting.orangeTolerance = this.resolveObject.selectedValues[PricingTagBase.selectionId][0].orangeTolerance || 0;
          this.objectiveSetting.priority = this.resolveObject.selectedValues[PricingTagBase.selectionId][0].priority || 0;
        }
    }
    const tempNetworkGroups = [];
    this.networkGroups.forEach((item) => {
      if (item.inUse || (!item.inUse && networkGroupsSelectedId.indexOf(item.networkGroupId.toString()) > -1)) {
        tempNetworkGroups.push(item);
      }
    });
    this.networkGroups = tempNetworkGroups;
    this.isAllNetworkDisabled();
  }

  // Todo : Added trackByFun as --aot build was giving error//
  trackByFn(i) {
    return i;
  }

  selectTab(tabId: number) {
    // to close tag-input dropdown on selection menu change
    if (this.dropdownTagComp && this.dropdownTagComp.length > 0 && this.dropdownTagComp.first.dropdown.menu.dropdownState.menuState.isVisible) {
      this.dropdownTagComp.first.dropdown.hide();
    }
    super.selectTab(tabId);
  }

  /**
   * @description Handles modal dismiss
   * @author Amit Mahida
   * @param {*} event
   * @memberof VolumeComponent
   */
  onModalClosed(event) {
    if (event.reason === 'escape' && this.selectedNetworks.length > 0) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * @description Handles modal save
   * @author Amit Mahida
   * @param {*} event
   * @memberof VolumeComponent
   */
  onModalSaved(event) {
    if (this.selectedNetworks.length > 0) {
      const values: CellValues = new CellValues();
      values.brick = this.resolveObject.brick;
      if (this.modeVisible && this.isRateCard) {
        this.selectedNetworks[0].openRateCard = true;
      } else {
        this.selectedNetworks[0].openRateCard = false;
      }
      if (!this.uiControl?.isNetworkAsaFilterEnabled && this.isVioohAllocationEngineSelected && this.objectiveSetting) {
        this.selectedNetworks[0].greenTolerance = this.objectiveSetting?.greenTolerance || 0;
        this.selectedNetworks[0].orangeTolerance = this.objectiveSetting?.orangeTolerance || 0;
        this.selectedNetworks[0].priority = this.objectiveSetting?.priority || 0;
        if(this.objectiveSetting?.greenTolerance !== null && this.objectiveSetting?.orangeTolerance !== null && this.objectiveSetting?.greenTolerance > this.objectiveSetting?.orangeTolerance){
          this.logHelperService.logError(this.userBundle['workspace.error.tolerance.orangeMustBeGreaterOrEqualToGreen'] || 'Orange Tolerance must be greater than or equal to Green Tolerance');
          return false;
        }
      }
      values.selectedValues = {
        [this.lookupColumnData.lookup[0].selectionId]: this.selectedNetworks
      };
      values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.PricingTag, values.selectedValues);
      values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.PricingTag, values.selectedValues[this.lookupColumnData.lookup[0].selectionId]);
      values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.PricingTag, values.selectedValues);
      event.activeModal.close(values);
    } else {
      this.logHelperService.logError(this.userBundle['common.error.invalidValue']);
    }
  }

  trackByIndex(index, item) {
    return index;
  }

  trackByNetworkGroup(index, networkGroup) {
    return networkGroup?.networkGroupId;
  }

  /**
   * @description It will be called when user change mode from UI
   * @author Dhaval Patel
   * @param {*} event
   * @memberof PricingTagModalComponent
   */
  modeChanged(event) {
    if (this.modeVisible) {
      if (this.selectedNetworks && this.selectedNetworks.length && event) {
        event.preventDefault();
        const modalOptions: any = {
          backdrop: this.ngbModalOptions.backdrop,
          keyboard: this.ngbModalOptions.keyboard,
        };
        const modalRef = this.modalService.open(ConfirmationComponent, modalOptions);
        modalRef.componentInstance.resolveObject = {
          content: this.userBundle['workspace.confirm.ratecards'] || 'You might lose the networks/ratecards already selected, do you want to proceed?',
          title: this.userBundle['workspace.confirmation.label'] || ' Confirmation',
          ok: this.userBundle['common.ok'] || 'OK',
        };
        modalRef.result.then(() => {
          this.modeChangeAction();
        }, (reason) => {
          console.log(reason);
        });
      } else {
        this.modeChangeAction();
      }
    }
  }

  /**
   * @description switch to change the mode of brick : Network and Ratecard
   * @author Dhaval Patel
   * @memberof PricingTagModalComponent
   */
  modeChangeAction() {
    if (this.modeVisible) {
      if (!this.uiControl.openRateCardsEnabled) {
        this.modeVisible = false;
      }
      this.selectedNetworks = [];
      this.isRateCard = !this.isRateCard;
      const columnConfig = _.cloneDeep(this.resolveObject.columnConfig);
      this.setNetworkGroup(columnConfig);
    }
  }

  /**
   * @description Set Ratecard mode if ratecard is already selected
   * @author Dhaval Patel
   * @memberof PricingTagModalComponent
   */
  setRateCardMode() {
    if (this.modeVisible) {
      this.isRateCard = !this.isRateCard;
      const columnConfig = _.cloneDeep(this.resolveObject.columnConfig);
      this.setNetworkGroup(columnConfig);
    }
  }

  /**
   * Set default green tolerance
   * @author Dhaval Patel
   * @memberof PricingTagModalComponent
   */
  setDefaultGreenTolerance() {
    if ((this.objectiveSetting.greenTolerance === null || this.objectiveSetting.greenTolerance === undefined)) {
      this.objectiveSetting.greenTolerance = 0;
    }
  }

  /**
   * Set default orange tolerance
   * @author Dhaval Patel
   * @memberof PricingTagModalComponent
   */
  setDefaultOrangeTolerance() {
    if ((this.objectiveSetting.orangeTolerance === null || this.objectiveSetting.orangeTolerance === undefined)) {
      this.objectiveSetting.orangeTolerance = 0;
    }
  }

  /**
   * Set default priority tolerance
   * @author Dhaval Patel
   * @memberof PricingTagModalComponent
   */
  setDefaultPriority() {
    if ((this.objectiveSetting.priority === null || this.objectiveSetting.priority === undefined)) {
      this.objectiveSetting.priority = 0;
    }
  }

  trackByItem(index, item) {
    return item;
  }
}
