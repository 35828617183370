import { Component, OnInit, Input } from '@angular/core';
import { DataShareService } from '../../../core/services/data-share.service';

@Component({
  selector: 'app-continue-work',
  templateUrl: './continue-work.component.html'
})
export class ContinueWorkComponent implements OnInit {
  @Input() resolveObject;

  titleColor = '#03a9f4';

  userBundle: any;

  message = '';

  constructor(private dataShareService: DataShareService) { }

  ngOnInit() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.message = this.resolveObject.message;
  }

  onModalClosed(event) {
    event.activeModal.dismiss('cancelclick');
  }

  onModalSaved(event) {
    event.activeModal.close('ok');
  }

}
