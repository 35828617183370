import { Component } from '@angular/core';
import { AgCustomRowUtil } from '../../utils/ag-custom-row-util';
import { DataFormatterService } from '../../services/data-formatter.service';
import { CellRendererBaseComponent } from '../cell-renderer-base/cell-renderer-base.component';
import { LocaleData } from '../../../../../core/utils/LocaleData';

@Component({
  selector: 'app-ag-currency-cell-renderer',
  templateUrl: './ag-currency-cell-renderer.component.html',
  styleUrls: ['./ag-currency-cell-renderer.component.css']
})
export class AgCurrencyCellRendererComponent extends CellRendererBaseComponent {
  constructor(private dataFormatterService: DataFormatterService) {
    super();
  }

  public getParentRowText(): string {
    if (this.groupingField) {
      const rowData = AgCustomRowUtil.getRowData(this.params.api);
      const childRows = AgCustomRowUtil.getChildRows(rowData, this.groupingField, this.params.data[this.groupingField]);
      const sum = AgCustomRowUtil.sumFieldValues(childRows, this.params.column.colId);
      this.params.data[this.params.column.colId] = sum;
      return String(sum);
    } else {
      return this.params.data[this.params.column.colId];
    }
  }

  public getChildRowText(): string {
    if (!this.params.data[this.params.column.colId]) {
      this.params.data[this.params.column.colId] = 0;
    }
    return this.params.data[this.params.column.colId];
  }

  public getFirstPinRowText(): string {
    if (this.params.gridSumRow && this.params.gridSumRow === 1) {
      if (this.params.data[this.params.column.colId] === 0 || (this.params.data[this.params.column.colId]
        && this.params.data[this.params.column.colId] !== '')) {
        return this.params.data[this.params.column.colId];
      } else {
        return this.calcGridTotal();
      }
    } else {
      this.params.data[this.params.column.colId] = this.params.data[this.params.column.colId] || '';
      return this.isEditable ? this.params.data[this.params.column.colId] : '';
    }
  }

  public getSecondPinRowText(): string {
    return this.calcGridTotal();
  }

  public formatText(displayText): string {
    let displayTextValue = displayText;
    if (this.params.allowReplaceDecimalSeparator && displayText) {
      displayTextValue = LocaleData.replaceLocalDecimalCharWithDot(displayText);
    }
    if (this.params.prefixText) {
      return this.params.prefixText(this.params.data) + this.dataFormatterService.currency(displayTextValue, this.params.decimalPoints || 2);
    } else {
      return this.dataFormatterService.currency(displayTextValue, this.params.decimalPoints || 2);
    }
  }

  private calcGridTotal(): string {
    const rowData = AgCustomRowUtil.getRowData(this.params.api);
    const childRows = AgCustomRowUtil.getChildRows(rowData);
    const sum = AgCustomRowUtil.sumFieldValues(childRows, this.params.column.colId);
    return String(sum);
  }
}
