<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
  [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <div class="modal_content" [ngClass]="{'readOnly' : readOnlyModal}">
    <div class="control_group">
      <div class="full-width sot-slider" *ngIf="isShow">
        <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
      </div>

      <div class="left col-md-10">
        <label for="rbt-frame">{{initialConfig.userBundle['workspace.pcm.increment']}}</label>
        <input id="txt_increment" type="text" class="form_control" [(ngModel)]="increment" appNumberMask [min]="1"
          [max]="100" [scale]="2" [defaultValue]="1" autocomplete="off" (change)="stepUpdated(increment, true)"
          style="width: 66%" />
        <span class="percentage-illumination" *ngIf="increment">%</span>
      </div>
      <div class="left col-md-10">
        <label for="rbt-frame">{{initialConfig.userBundle['workspace.pcm.default']}}</label>
        <input id="txt_increment" type="text" class="form_control" [(ngModel)]="def" appNumberMask [min]="1" [max]="100"
          [scale]="2" [defaultValue]="1" autocomplete="off" style="width: 66%" />
        <span class="percentage-illumination" *ngIf="def">%</span>
      </div>
    </div>
  </div>

</app-sb-modal-wrapper>
