import { Injectable } from '@angular/core';
import { SearchComponent } from '../../geoplanner/src/app/search/search.component';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoaderService } from '../../geoplanner/src/app/core/services';

@Injectable()
export class CanDeactivateSearchService implements CanDeactivate<SearchComponent> {

  constructor(private loaderService: LoaderService) { }

  // @ts-ignore
  canDeactivate(component: SearchComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Promise<boolean> { // tslint:disable-line

    return new Promise((resolve, reject) => {

      if (this.loaderService.getBackButton()) {
        this.loaderService.setBackButton(false);
        // push current state again to prevent further attempts.
        history.pushState(null, null, location.href);
        return resolve(false);
      } else if (component.calledFromGoToWorkspacePage) {
        component.calledFromGoToWorkspacePage = false;
        return resolve(true);
      } else {
        component.campaignDetails.campaignDetails.campaignTypeId = Number(component.campaignDetails.campaignDetails.campaignTypeId);
        component.stateService.setCampaign(component.campaignDetails.campaignDetails);
        component.searchService.redirectFromSearch(nextState.url, component.campaignDetails.campaignDetails).then(() => {
          component.campaignDetails.campaignDetails.campaignTypeId = Number(component.campaignDetails.campaignDetails.campaignTypeId);
          component.stateService.setCampaign(component.campaignDetails.campaignDetails);
          component.calledFromGoToWorkspacePage = true;
          resolve(true);
        }).catch(() => reject(false));
      }
    });
  }
}
