<!-- <button class="btn btn-link" (click)="onDeleteClick()"><em class="fa fa-trash"></em></button> -->
<ul class="list" [ngStyle]="{'border-left-color': params.data.unavailabilityColor ? params.data.unavailabilityColor : 'transparent'}">
    <li class="list-item">
        <p style="height: 20px;">
                <span style="float: left;">{{initialConfig.userBundle['geoplanner.text.frames'] || 'Frames'}}: </span>
                <span class="lineText">{{params.data['frameName']}}</span>
                <span class="imgIcon" (click)="onImageClick($event)" *ngIf="params.data.galleryUrl"><em class="fa fa-picture-o"></em></span>
        </p>
        <p style="clear: both;">
            <span>{{initialConfig.userBundle['geoplanner.text.date'] || 'Date'}}: </span>{{params.data['startDate']}} - {{params.data['endDate']}}
        </p>
        <p>
            <span>{{initialConfig.userBundle['geoplanner.text.format'] || 'Format'}}: </span>{{params.data['productFormatName']}}
        </p>
        <!-- <p><span>{{initialConfig.userBundle['workspace.collection.bric.product.heading']}}: </span>{{params.data['productFormat']}}</p> -->
        <p>
            <span style="vertical-align: top;">{{initialConfig.userBundle['geoplanner.text.city'] || 'City'}}: </span><span class="halflineText" style="width: 150px;">{{params.data['townName']}}</span>
            <span style="vertical-align: top;">{{initialConfig.userBundle['geoplanner.addons.filter.sot.heading'] || 'SOT'}}: </span><span class="halflineText" style="width: 50px;">{{params.data['sot']}}</span>
        </p>
        <!-- <p><span>{{initialConfig.userBundle['geoplanner.text.district'] || 'District'}}: </span>{{params.data['district']}}</p> -->
        <!-- <button class="btn btn-red-outline " (click)="onDeleteClick()"><em class="fa fa-trash"></em> Delete</button> -->
    </li>
</ul>