import { Component, Input, ViewChildren, QueryList, Output, EventEmitter, OnInit } from '@angular/core';
import { DataShareService } from '../../../core/services/data-share.service';
import { FilterDataService } from '../../../core/services/filter-data.service';
import { TagInputDropdown } from 'ngx-chips';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { TagBase } from '../tag-base';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';
import { ObjectiveService } from '../../../workspace/objective/objective.service';
import { BrickBaseService } from '../../../core/services';

@Component({
  selector: 'app-tag-filter',
  templateUrl: './tag-filter.component.html',
  styleUrls: ['./tag-filter.component.css']
})
export class TagFilterComponent extends TagBase implements OnInit {

  /**
   * It will take data to configure filter window.
   */
  @Input() resolveObject;
  /**
   * It calls event to close filter winodw.
   */
  @Output() closeSidebar = new EventEmitter<string>();
  /**
   * Tag Input dropdown view children instance.
   */
  @ViewChildren(TagInputDropdown) public dropdownTagComp: QueryList<TagInputDropdown>;

  /**
   * Creates an instance of TagFilterComponent.
   * @author Amit Mahida
   * @param {DataShareService} dataShareService
   * @param {FilterDataService} filterDataService
   * @param {LogHelperService} logHelperService
   * @memberof TagFilterComponent
   */
  constructor(
    dataShareService: DataShareService,
    private filterDataService: FilterDataService,
    private logHelperService: LogHelperService,
    appHeaderService: AppHeaderService,
    objectiveService: ObjectiveService,
    brickBaseService: BrickBaseService) {
    super(dataShareService, appHeaderService, objectiveService, brickBaseService);
  }

  /**
   * @description angular component init lifecycle hook
   * @author Alkesh Shah
   * @memberof TagFilterComponent
   */
  ngOnInit() {
    this.resolveObject.placeHolder = this.initialConfig.userBundle['common.aadTag'];
    this.init(this.resolveObject.ColumnConfig, this.filterDataService.getTags());
  }

  /**
   * @description To select/highlight selected parent.
   * @author Alkesh Shah
   * @param {string} id Index of selected tab.
   * @memberof TagFilterComponent
   */
  selectTab(id: string): void {
    // to close tag-input dropdown on selection menu change
    if (this.dropdownTagComp.length > 0 && this.dropdownTagComp.first.dropdown.menu.dropdownState.menuState.isVisible) {
      this.dropdownTagComp.first.dropdown.hide();
    }
    this.selectTabChange(id);
  }

  /**
   * @description callback function of save button click
   * @author Alkesh Shah
   * @memberof TagFilterComponent
   */
  saveClick(): void {
    if (this.isValidSelection()) {
      this.filterDataService.setTags(this.tags.allTabSelection);
      this.closeSidebar.emit();
    } else {
      const msg = this.initialConfig.userBundle['common.error.selectAnyValue'] || 'Please select any value to continue!';
      this.logHelperService.logError(msg);
    }
  }

  /**
   * @description callback function of close button click
   * @author Alkesh Shah
   * @memberof TagFilterComponent
   */
  closeClick(): void {
    this.closeSidebar.emit();
  }
}
