import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {
  DataShareService,
  LogHelperService,
  CellAttributeService,
  BrickBaseService
} from '../../core/services/index';
import { CellValues } from '../../models/workspace/index';
import { MaskService } from '../../core/services/mask.service';
import { NumberMaskModel } from '../../models/imask';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { UiControl } from '../../models/initial-config.model';
import { UserModel } from './../../models/user';
import { GLOBAL } from '../../core/utils/app.constant';

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html'
})
export class FrameComponent implements OnInit {
  userBundle: object;
  @Input() resolveObject: CellValues;
  frame = {
    frameCount: null,
    illumination: null,
    allocated: null,
    allocateAllFrames: null,
    tolerance: 0,
    greenTolerance: null,
    orangeTolerance: null,
    status: null,
    boostedTarget: false,
    relative: false,
    weight: GLOBAL.defaultWeight,
    priority: GLOBAL.defaultPriority,
  };
  previousValues;
  readOnlyModal = false;

  maskService: MaskService = new MaskService();

  public global = GLOBAL;
  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof FrameComponent
   */
  currencyInputMask: NumberMaskModel;

  /**
   * @description  Mask input config for Percentage
   * @type {NumberMaskModel}
   * @memberof FrameComponent
   */
  percentageInputMask: NumberMaskModel;

  relativePercentageInputMask: NumberMaskModel;

  /**
   * @description Mask input config for tolerance Percentage field
   * @type {NumberMaskModel}
   * @memberof FrameComponent
   */
  percentageToleranceInputMask: NumberMaskModel;

  /**
   * @description check pcm mode is on/off
   * @memberof FrameComponent
   */
  user: UserModel;

  pcmMode: boolean;

  objectiveMode: boolean;
  localSolverEnabled: boolean;
  /**
   * @description UI control
   * @memberof VolumeComponent
   */
  uiControl: UiControl;

  @ViewChild('frameCount') frameCountElementRef: ElementRef;

  constructor(
    private dataService: DataShareService,
    private logHelperService: LogHelperService,
    private cellAttributeService: CellAttributeService,
    private brickBaseService: BrickBaseService,
    private appHeaderService: AppHeaderService
  ) {
    this.pcmMode = this.appHeaderService.enabledPCM;
    this.objectiveMode = this.appHeaderService.objectiveMode;
  }

  ngOnInit() {
    this.currencyInputMask = this.maskService.currencyInputMask({ scale: 0 });
    this.percentageInputMask = this.maskService.currencyInputMask({ max: 100, scale: 0 });
    this.relativePercentageInputMask = this.maskService.currencyInputMask({ max: 100 });
    this.userBundle = this.dataService.getInitialConfigByKey('userBundle');
    this.uiControl = this.dataService.getInitialConfigByKey('uiControl');
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.user = this.dataService.getUserModel();
    this.localSolverEnabled = this.resolveObject.reshufflingEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_LS || this.resolveObject.reshufflingEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH || this.objectiveMode;

    this.percentageToleranceInputMask = this.maskService.currencyInputMask({
      min: this.uiControl.toleranceMin ? this.uiControl.toleranceMin : 0,
      max: 100,
      scale: 0
    });

    if (this.resolveObject.selectedValues && !this.resolveObject.selectedValues.hasOwnProperty('-99')) {
      this.frame = { ...this.frame, ...this.resolveObject.selectedValues };
      this.frame.frameCount = this.frame.frameCount === null ? this.frame.allocated : this.frame.frameCount;
    } else {
      this.resolveObject.selectedValues = null;
    }

    this.setDefaultGreenTolerance();
    this.setDefaultOrangeTolerance();
    this.previousValues = Object.assign({},this.frame);
  }

  onModalClosed(event) {
    if (event.reason === 'escape' && !(JSON.stringify(this.previousValues) == JSON.stringify(this.frame))){
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  onModalSaved(event) {
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    if (this.frameCountElementRef.nativeElement.value !== '') {
      if (this.frame.greenTolerance !== null
        && this.frame.orangeTolerance !== null
        && this.frame.greenTolerance > this.frame.orangeTolerance
        && this.localSolverEnabled) {
        this.logHelperService.logError(this.userBundle['workspace.error.tolerance.orangeMustBeGreaterOrEqualToGreen'] || 'Orange Tolerance must be greater than or equal to Green Tolerance');
        return false;
      }

      values.selectedValues = this.frame;
      if (!this.uiControl.toleranceEnabled) {
        delete values.selectedValues.tolerance;
      }
      values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Frame, values.selectedValues);
      values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Frame, values.selectedValues);
      this.previousValues = Object.assign({},this.resolveObject.selectedValues);
      event.activeModal.close(values);
    } else {
      this.logHelperService.logError(this.userBundle['common.error.noDataSelect']);
    }
  }

  onChangeRelative(relative) {
    if (relative && this.frame.frameCount > 100) {
      this.frame.frameCount = null;
    }

    if (!relative && this.frame.frameCount) {
      this.frame.frameCount = Number.parseInt(this.frame.frameCount.toString(), 10);
    }

    this.frame.allocated = null;
  }

  setDefaultGreenTolerance() {
    if ((this.frame.greenTolerance === null || this.frame.greenTolerance === undefined) && this.localSolverEnabled) {
      this.frame.greenTolerance = this.uiControl.greenToleranceDefault || null;
    }
  }

  setDefaultOrangeTolerance() {
    if ((this.frame.orangeTolerance === null || this.frame.orangeTolerance === undefined) && this.localSolverEnabled) {
      this.frame.orangeTolerance = this.uiControl.orangeToleranceDefault || null;
    }
  }
}
