<div class="contentarea">
  <dl class="accordion animated bounceInDown content"
    *ngIf="commercialConfig && commercialConfig.uiControl && commercialConfig.uiControl.showDiscountGrid">
    <dt (click)="hideDiscountGrid = !hideDiscountGrid">
      <span class="editable-title">
        <span class="text">{{initialConfig?.userBundle['commercial.tabTitle.discountGrid'] || 'Discount Grid' }}</span>
      </span>
      <a href="javascript:void(0)" [ngClass]="{'down': !hideDiscountGrid}" class="title">
        <span class="right">
          <span class="icon_wrapper">
            <span class="icons animated"></span>
          </span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'down': !hideDiscountGrid }">
      <app-discount-grid-spain [campaignDetails]="campaignDetails"
        *ngIf="campaignDetails && campaignDetails.discounts && campaignDetails.discounts.length">
      </app-discount-grid-spain>
    </dd>
  </dl>

  <dl class="accordion animated bounceInDown content">
    <dt (click)="hideCampaignDetails = !hideCampaignDetails">
      <span class="editable-title">
        <span class="text">{{initialConfig.userBundle['commercial.tabTitle.campaignDetails']}}
          &nbsp;-&nbsp;
        </span>
        <span class="text allow-copy"> {{commercialData?.campaignReference}}
        </span>
      </span>
      <a href="javascript:void(0)" [ngClass]="!hideCampaignDetails?'down':''" class="title">
        <span class="right">
          <span class="text">{{commercialData?.bookingStatusName}}</span>
          <span class="icon_wrapper">
            <span class="icons animated"></span>
          </span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'readOnly' : systemFlags.readOnly, 'down': !hideCampaignDetails }">
      <app-campaign-details-es *ngIf="commercialData" [commercialConfig]="commercialConfig"
        [campaignDetails]="campaignDetails" [historyROC]="historyROC" (manuallyCPTUpdated)="manuallyCPTUpdated()">
      </app-campaign-details-es>
    </dd>
  </dl>

  <dl class="accordion animated bounceInDown content">
    <dt (click)="showHideBookingGrid()">
      <span class="editable-title">
        <span class="text">{{initialConfig?.userBundle['commercial.tabTitle.bookingDetails']}} </span>
      </span>
      <a href="javascript:void(0)" class="title" [ngClass]="!hideBookingGrid ? 'down' : '' ">
        <span class="right">
          <span class="icon_wrapper">
            <span class="icons animated"></span>
          </span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'down': !hideBookingGrid }">
      <app-booking-grid-uk *ngIf="commercialData" [commercialConfig]="commercialConfig"
        [bookingList]="commercialData.bookingList">
      </app-booking-grid-uk>
    </dd>
  </dl>

  <app-commercial-buttons *ngIf="commercialData" [commercialConfig]="commercialConfig"
    [campaignDetails]="campaignDetails" [validateFn]="validateCommercialCampaign"
    [populateRequestJson]="createRequestJSONForCommercial" (bookCampaign)="onBookCampaign($event)">
  </app-commercial-buttons>

</div>
