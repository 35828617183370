
export class LookupColumn {
  brickId: number = null;
  lookup: LookupModel[] = [];
}

export class LookupModel {
  selectionId: number = null;
  displayName: string = null;
  lookupType: number = null;
  selectionIds?: any = null;
  displayCode?: boolean;
  selectAll?: boolean;
}

export class LookupData {
  selectionId: number;
  data: LookupDataList[];
}

export class LookupDataList {
  id: number = null;
  inUse: boolean = null;
  name: string = null;
  lookupId?: number;
  lookupName?: string;
  displayCode?: boolean;
}

export interface FormatLookupModel<T> {
  [key: string]: T[];
}
