import { UiControl } from '../../models/initial-config.model';
import { DataShareService } from '../../core/services/data-share.service';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { UserModel } from '../../models/user';
import { VolumeBricSelectedDataModel } from '../../models/MapDataRequest.model';
import { BrickBaseService, LogHelperService, WorkspaceService } from '../../core/services';
import { GLOBAL } from '../../core/utils/app.constant';

export class VolumeBase {

  resolveObject: any;
  /**
   * @description Holds product validations on volume
   * @type {any}
   * @memberof VolumeBase
   */
  productValidations: any = null;

  /**
   * @description User bundle object
   * @type {object}
   * @memberof VolumeComponent
   */
  userBundle: object;

  /**
   * @description UI control
   * @memberof VolumeComponent
   */
  uiControl: UiControl;

  /**
   * @description master object
   * @memberof VolumeComponent
   */
  ratingImpression: VolumeBricSelectedDataModel = {
    impressions: null,
    sotFloor: null,
    sotCeiling: null,
    allocated: 0,
    tolerance: 0,
    allocateAllFrames: false,
    greenTolerance: null,
    orangeTolerance: null,
    status: null,
    boostedTarget: false,
    relative: false,
    weight: GLOBAL.defaultWeight,
    priority: GLOBAL.defaultPriority,
  };
  previousValues:VolumeBricSelectedDataModel;

  /**
   * @description check pcm mode is on/off
   * @memberof VolumeComponent
   */
  pcmMode: boolean;

  objectiveMode: boolean;

  user: UserModel;

  readonly LESS_THAN_MIN_IMPRESSION = 'impression.error.lessThanMinImpression';
  readonly GRATER_THAN_MAX_IMPRESSION = 'impression.error.graterThanMaxImpression';

  /**
   * Creates an instance of VolumeBase.
   * @author Amit Mahida
   * @param {DataShareService} dataShareService
   * @param {AppHeaderService} appHeaderService
   * @param {BrickBaseService} brickBaseService
   * @param {LogHelperService} logHelperService
   * @memberof VolumeBase
   */
  constructor(
    public dataShareService: DataShareService,
    public appHeaderService: AppHeaderService,
    public brickBaseService: BrickBaseService,
    public workspaceService: WorkspaceService,
    public logHelperService: LogHelperService
  ) {
    this.pcmMode = this.appHeaderService.enabledPCM;
    this.objectiveMode = this.appHeaderService.objectiveMode;
  }

  init() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.user = this.dataShareService.getUserModel();
    // Below code to show info tooltip in case of product
    if (this.resolveObject.productValidations && Object.keys(this.resolveObject.productValidations).length && !this.pcmMode) {
      this.productValidations = this.resolveObject.productValidations;
    }
  }

  /**
   * @description Validate sot floor and ceiling values
   * @author Amit Mahida, Shivani Patel
   * @returns Returns true if It's valid otherwise false
   * @memberof VolumeComponent
   */
  validateVolumeInputs(): boolean {
    if (!this.pcmMode && (!this.ratingImpression.impressions || Number(this.ratingImpression.impressions) === 0)) {
      this.logError('workspace.rating.bric.volume.impression.error');
      return false;
    }

    if (this.pcmMode &&
      (!this.ratingImpression.impressions || Number(this.ratingImpression.impressions) === 0)
      && !this.ratingImpression.minImpressions && !this.ratingImpression.maxImpressions
      && !this.ratingImpression.sotFloor && !this.ratingImpression.sotCeiling) {
      this.logError('common.error.invalidValue');
      return false;
    }

    if(this.pcmMode && this.ratingImpression.sotIncrement == 0){
      this.logHelperService.logError(this.userBundle['sot.error.incrementGTZero'] || 'Increment must be greater than zero!');
      return false;
    }

    if (((!this.pcmMode && this.productValidations) || this.pcmMode) &&
      ((this.ratingImpression.sotIncrement && ((this.ratingImpression.sotCeiling != 0 && !this.ratingImpression.sotCeiling) || (this.ratingImpression.sotFloor != 0 && !this.ratingImpression.sotFloor))) ||
      (this.ratingImpression.sotIncrement && this.ratingImpression.sotFloor != 0 && this.ratingImpression.sotFloor % this.ratingImpression.sotIncrement != 0) ||
      (this.ratingImpression.sotIncrement && this.ratingImpression.sotCeiling % this.ratingImpression.sotIncrement != 0))) {
      this.logError('sot.error.floorCeilingMultipleOfIncrement');
      return false;
    }

    if (!this.validateVolumeRangeValidation()) {
      return false;
    }
    if (!this.validatePCMMode()) {
      return false;
    }
    if (!this.validateProductValidation()) {
      return false;
    }
    return true;
  }

  validatePCMMode() {
    if (this.pcmMode) {
      const minImpressions = this.ratingImpression.minImpressions ? this.ratingImpression.minImpressions : null;
      const maxImpressions = this.ratingImpression.maxImpressions ? this.ratingImpression.maxImpressions : null;
      const impressions = this.ratingImpression.impressions ? this.ratingImpression.impressions : null;

      if (!this.validateImpressionsPCMMode(minImpressions, maxImpressions, impressions)) {
        return false;
      }
    }
    return true;
  }

  validateImpressionsPCMMode(minImpressions, maxImpressions, impressions) {
    if (minImpressions && maxImpressions) {
      if (minImpressions > maxImpressions) {
        this.logError('impression.error.graterThanMax');
        return false;
      } else if (maxImpressions < minImpressions) {
        this.logError('impression.error.lessThanMin');
        return false;
      } else if (impressions && impressions < minImpressions) {
        this.logError(this.LESS_THAN_MIN_IMPRESSION);
        return false;
      } else if (impressions && impressions > maxImpressions) {
        this.logError(this.GRATER_THAN_MAX_IMPRESSION);
        return false;
      }
    } else if (minImpressions && impressions) {
      if (impressions < minImpressions) {
        this.logError(this.LESS_THAN_MIN_IMPRESSION);
        return false;
      }
    } else if (maxImpressions && impressions && impressions > maxImpressions) {
      this.logError(this.GRATER_THAN_MAX_IMPRESSION);
      return false;
    }
    return true;
  }

  validateProductValidation() {
    if (!this.pcmMode && this.productValidations) {
      if (!this.validateSOT()) {
        return false;
      }
      if (!this.validateImpressions()) {
        return false;
      }
    }
    return true;
  }

  validateSOT() {
    const sotFloor = this.productValidations.sotFloor ? this.productValidations.sotFloor : null;
    const sotCeiling = this.productValidations.sotCeiling ? this.productValidations.sotCeiling : null;
    if (sotFloor && sotCeiling) {
      if (this.ratingImpression.sotFloor < sotFloor) {
        this.logError('budget.error.sotFloor');
        return false;
      } else if (!this.ratingImpression.sotCeiling || this.ratingImpression.sotCeiling > sotCeiling) {
        this.logError('budget.error.sotCeiling');
        return false;
      }
    } else if (sotFloor) {
      if (this.ratingImpression.sotFloor < sotFloor) {
        this.logError('budget.error.sotFloor');
        return false;
      }
    } else if (sotCeiling && this.ratingImpression.sotCeiling > sotCeiling) {
      this.logError('budget.error.sotCeiling');
      return false;
    }
    return true;
  }

  validateImpressions() {
    const minImpressions = this.productValidations.minImpressions ? this.productValidations.minImpressions : null;
    const maxImpressions = this.productValidations.maxImpressions ? this.productValidations.maxImpressions : null;
    const impressions = this.ratingImpression.impressions ? this.ratingImpression.impressions : null;
    if (impressions) {
      if (minImpressions && impressions < minImpressions) {
        this.logError(this.LESS_THAN_MIN_IMPRESSION);
        return false;
      }
      if (maxImpressions && impressions > maxImpressions) {
        this.logError(this.GRATER_THAN_MAX_IMPRESSION);
        return false;
      }
    }
    return true;
  }

  validateVolumeRangeValidation() {
    if (this.uiControl.volumeRangeValidationEnabled) {
      const floor = this.ratingImpression.sotFloor ? this.ratingImpression.sotFloor : null;
      const ceil = this.ratingImpression.sotCeiling ? this.ratingImpression.sotCeiling : null;
      if (floor && ceil) {
        if (floor > ceil) {
          this.logError('sot.error.graterThanCeil');
          return false;
        } else if (ceil < floor) {
          this.logError('sot.error.lessThanFloor');
          return false;
        }
      }
    }

    if (this.ratingImpression.greenTolerance !== null
      && this.ratingImpression.orangeTolerance !== null
      && this.ratingImpression.greenTolerance > this.ratingImpression.orangeTolerance) {
      this.logHelperService.logError(this.userBundle['workspace.error.tolerance.orangeMustBeGreaterOrEqualToGreen'] || 'Orange Tolerance must be greater than or equal to Green Tolerance');
      return false;
    }

    return true;
  }

  logError(userBundleLabel) {
    this.logHelperService.logError(this.userBundle[userBundleLabel]);
  }
}
