import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, CommercialConfig, HistoryROC } from '../../commercial.model';
import { DataShareService } from '../../../core/services/data-share.service';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { StateService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { CampaignDetailsBase } from '../campaign-details-base';
import { SystemFlags } from '../../../models/system-flags';
import { NumberMaskModel } from '../../../models/imask';
import { CampaignGroupTypeList, CampaignStatus } from '../../../core/enum';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerHelper } from '../../../core/components/ngb-datepicker/ngb-datepicker-helper';

@Component({
  selector: 'app-campaign-details-nl',
  templateUrl: './campaign-details-nl.component.html',
  styleUrls: ['./campaign-details-nl.component.css']
})
export class CampaignDetailsNlComponent extends CampaignDetailsBase implements OnInit, OnDestroy {
  @Input() campaignDetails: CampaignDetails;
  @Input() commercialConfig: CommercialConfig;
  @Input() historyROC: HistoryROC;
  @Output() reaonlyChanged = new EventEmitter<boolean>();
  @Output() manuallyCPTUpdated: EventEmitter<any> = new EventEmitter();

  bookingLineDataSubscription: Subscription;
  searchPageObject: any;
  showLockedStatus = false;
  /**
   * @description set readOnly
   * @memberof CampaignDetailsDefaultComponent
   */
  public readOnly = SystemFlags.readOnly;
  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof BudgetModalComponent
   */
  currencyInputMask: NumberMaskModel;

  campaignGroupTypeList = CampaignGroupTypeList;

  /**
   * @description specific startDate day properties cached object for ngbDatePicker day template
   * @memberof CampaignDetailsNlComponent
   */
  startDayObjCache = {};
  lockReason = SystemFlags.lockReason;
  minStartDate: NgbDateStruct;
  maxEndDate: NgbDateStruct;
  minStartForEndDate: NgbDateStruct;

  constructor(
    commercialService: CommercialService,
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    stateService: StateService
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService
    );
    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe((value: any) => {
      if (value.rowIndex >= 0 && value.columnName) {
        this.calculateCPT(value);
      }
      this.onBookingLineChange();
    });
  }

  ngOnDestroy() {
    this.bookingLineDataSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.currencyInputMask = this.maskService.currencyInputMask({ max: 999999999999999999999, padFractionalZeros: true });
    this.minStartDate = NgbDatepickerHelper.convertDateToDateStruct(new Date());
    this.maxEndDate = NgbDatepickerHelper.convertDateToDateStruct(new Date(this.campaignDetails.campaignStartDate));
    this.minStartForEndDate = NgbDatepickerHelper.convertDateToDateStruct(new Date(this.campaignDetails.campaignEndDate));
    if (new Date() > new Date(this.campaignDetails.campaignStartDate)) {
      this.minStartDate = this.maxEndDate;
    }
    super.init(this.campaignDetails);
    if (this.campaignDetails.floatingStartDate) {
      const floatingStartDate = new Date(this.campaignDetails.floatingStartDate as Date);
      this.campaignDetails.floatingStartDate = NgbDatepickerHelper.convertDateToDateStruct(floatingStartDate);
    }
    if (this.campaignDetails.floatingEndDate) {
      const floatingEndDate = new Date(this.campaignDetails.floatingEndDate as Date);
      this.campaignDetails.floatingEndDate = NgbDatepickerHelper.convertDateToDateStruct(floatingEndDate);
    }
    this.searchPageObject = this.stateService.getCampaign();
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    this.enableSubAgency();
    this.setLookupConfig();

    const userData = this.dataShareService.getInitialConfigByKey('userData');
    if (userData.tokens.indexOf('EDIT_LOCKED_STATUS') > -1) {
      this.showLockedStatus = true;
    }
  }

  /**
     * @description It will disable pages when locked is true otherwise pages will be enabled
     * @author Dhaval Patel
     * @memberof CampaignDetailsBelgiumComponent
     */
  lockStatusChanged() {
    if (this.showLockedStatus) {
      if (this.campaignDetails.invoiceLocked) {
        this.campaignDetails.locked = true;
        this.stateService.lockWorkspace();
        SystemFlags.readOnly = true;
        SystemFlags.readOnlyWorkspace = true;
        this.readOnly = true;
        this.reaonlyChanged.emit(true);
      } else {
        this.campaignDetails.locked = false;
        this.stateService.unlockWorkspace();
        SystemFlags.readOnly = false;
        if (this.campaignDetails.campaignStatusId === CampaignStatus.cancel) {
          SystemFlags.readOnly = true;
        } else {
          SystemFlags.readOnly = false;
        }
        if (SystemFlags.splitable || SystemFlags.isPPCampaign || this.campaignDetails.campaignStatusId === CampaignStatus.cancel) {
          SystemFlags.readOnlyWorkspace = true;
        } else {
          SystemFlags.readOnlyWorkspace = false;
        }
        this.readOnly = false;
        this.reaonlyChanged.emit(false);
      }
      const campaign = this.stateService.getCampaign();
      campaign.invoiceLocked = this.campaignDetails.invoiceLocked;
      this.stateService.setCampaign(campaign);
    }
  }

  // Functions Related to Specialist//
  onSpecialistNameSelected(value: any): void {
    if (this.campaignDetails.specialist) {
      super.onSpecialistChange(value);
      this.manageGrossMediaValueWithoutIndex();
    }
  }

  removeSelectedSpecialistName() {
    super.onSpecialistChange();
    this.manageGrossMediaValueWithoutIndex();
  }

  onSpecialistPercentageChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  // Functions related to Agencies//
  onAgencyNameSelected(value: any): void {
    if (this.campaignDetails.agency) {
      super.onAgencyChange(value);
      this.enableSubAgency();
      this.manageGrossMediaValueWithoutIndex();
    }
  }
  removeSelectedAgencyName(): void {
    super.onAgencyChange();
    this.enableSubAgency();
    this.manageGrossMediaValueWithoutIndex();
  }
  onAgencyPercentageChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  onBookingLineChange() {
    this.campaignDetailsService.populateAgencyPercentage(this.campaignDetails);
    this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
  }

  assignedToChange() {
    this.campaignDetailsService.populateAgencyPercentage(this.campaignDetails);
    this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
    this.enableSubAgency();
    this.setActiveAssignedTo();
  }

  /**
   * @description calculate CPT from gross media value or pmp discount change
   * @author Kishan Patel
   * @param {*} value
   * @memberof CampaignDetailsNlComponent
   */
  calculateCPT(value) {
    if (value.columnName === 'grossMediaValue') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex,
        undefined, Number(this.campaignDetails.bookingList[value.rowIndex].grossMediaValue));
    } else if (value.columnName === 'pmpdiscount') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex);
    }
  }

  /**
   * @description on CPT value changes then update pmp discount in grid
   * @author Kishan Patel
   * @memberof CampaignDetailsUkComponent
   */
  onAverageCPTChanged() {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT); // SM-4776
    this.commercialService.notifyCampaignDetails(this.campaignDetails.bookingList);
    if (this.uiControl.enableUserDefinedCPMPricingCalculation) {
      this.manuallyCPTUpdated.emit();
    }
  }

  cleanFloatingDate = (dateType: string, otherDatePicker: NgbInputDatepicker): boolean => {
    otherDatePicker.close();
    if (dateType === 'start') {
      this.campaignDetails.floatingStartDate = undefined;
    } else {
      this.campaignDetails.floatingEndDate = undefined;
    }
    return false;
  }

  /**
   * @description get the specific dates property
   * @author Dhaval Patel
   * @param {NgbDateStruct} date current day
   * @param {string} key property key to retrive
   * @returns {boolean}
   * @memberof CampaignDetailsNlComponent
   */
  getStartDayProp(date: NgbDateStruct, key: string): boolean {
    const daystring = this.cacheStartDateObj(date, this.startDayObjCache);
    return this.startDayObjCache[daystring][key];
  }
}
