export class SaveFrameRequestModel {
  startDate: string;
  endDate: string;
  frames: number[];
  sot?: number | string;
}

export class UpdateBasketRequestModel {
  [key: string]: [string, string][];
}
