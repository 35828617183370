import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import {
  DataShareService,
  CellAttributeService,
  BrickBaseService,
  LogHelperService,
  SharedService,
  StateService
} from '../../../core/services';
import { ITreeState } from '@circlon/angular-tree-component';
import { TreeViewComponent } from '../../../core/components/tree-view/tree-view.component';
import { Product } from '../../../models/initial-config.model';
import { CellValues } from '../../../models/workspace/cell-values';
import { ChannelBase } from '../channel-base';
export class ProductDetails {
  productId: number;
  productName: string;
  relative: boolean;
}
import { TemplateProps } from '../../../../app/models/workspace';
import * as _ from 'lodash';
import { GLOBAL } from '../../../core/utils/app.constant';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';
import { Brick } from '../../../workspace/brick-model';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.css']
})
export class ChannelComponent extends ChannelBase implements OnInit, AfterViewInit {

  @Input() resolveObject: CellValues;
  @ViewChild(TreeViewComponent)
  public treeViewComponent: TreeViewComponent;
  state: ITreeState;
  selectedRelativeFilters: object = {};
  public products: Product[];
  activeTabIndex: number;
  options = {
    idField: 'productId',
    displayField: 'productName',
    childrenField: 'product',
    useCheckbox: true,
    scrollContainer: document.body.parentElement
  };
  templateProps: TemplateProps;
  public selectedNodeIds: number[];
  public selectedProducts: ProductDetails[];
  readOnlyModal = false;
  lookupColumnData;
  selectedValuesOnWs: number[];

  constructor(
    dataShareService: DataShareService,
    private cellAttributeService: CellAttributeService,
    private brickBaseService: BrickBaseService,
    private logHelperService: LogHelperService,
    private sharedService: SharedService,
    private stateService: StateService,
    private appHeaderService: AppHeaderService
  ) {
    super(dataShareService);
  }

  showOnlyActiveTabNodes() {
    const searchText = this.treeViewComponent.filter.nativeElement.value;
    if (searchText === '') {
      this.treeViewComponent.tree.treeModel.filterNodes((node) => {
        return node.data.index === this.activeTabIndex;
      });
      this.treeViewComponent.tree.treeModel.collapseAll();
    } else {
      this.filterTreeData();
    }
  }

  filterTreeData = () => {
    this.treeViewComponent.tree.treeModel.filterNodes((node) => {
      const searchText = this.treeViewComponent.filter.nativeElement.value;
      const value: string = node.data.productName;
      return node.data.index === this.activeTabIndex && value.toLowerCase().includes(searchText.trim().toLowerCase());
    });
  }

  /**
   * @description get the product list to display in tree view - filter by column config
   * @author Alkesh Shah
   * @returns - list of products
   * @memberof ChannelComponent
   */
  getProductList() {
    this.tabList = _.cloneDeep(this.initialConfig.productGroup);
    let tempColumnConfig = null;
    if (!_.isUndefined(this.resolveObject.columnConfig) && this.resolveObject && this.resolveObject.columnConfig !== null) {
      const lookupData = this.resolveObject.columnConfig.lookupData[GLOBAL.productBricSelectionId];
      tempColumnConfig = lookupData ? _.keys(lookupData) : null;
    }
    if (tempColumnConfig !== null) {
      _.map(this.tabList, (obj, i) => {
        if (obj.product && obj.product.length > 0) {
          let valueSelectedInTab = true;
          if (this.appHeaderService.objectiveMode) {
            valueSelectedInTab = this.checkValuesSelectedInTab(obj.product);
          }
          this.iterateToDisplayProduct(obj.product, i, tempColumnConfig, this.tabList, valueSelectedInTab);
        }
      });
      this.deleteTreeNodes(this.tabList);
    }
    const products = [];
    if (this.tabList && this.tabList.length) {
      for (let i = 0; i < this.tabList.length; i++) {
        for (const prod of this.tabList[i].product) {
          prod.index = i;
          products.push(prod);
          if (prod.hasOwnProperty('product')) {
            this.iterateOverProducts(prod.product, i);
          }
        }
      }
    }
    return products;
  }
  /**
   * @description Iterate over the products for reindexing.
   * @author (Set the text for this tag by adding docthis.authorName to your settings file.)
   * @date 2020-07-01
   * @param {*} products
   * @param {*} index
   * @memberof ChannelComponent
   */
  iterateOverProducts(products, index) {
    for (const product of products) {
      product.index = index;
      if (product.hasOwnProperty('product')) {
        this.iterateOverProducts(product.product, index);
      }
    }
  }

  /**
   * @description delete the product lists which are not needed to display in tree view
   * @author Alkesh Shah
   * @param {*} data
   * @memberof ChannelComponent
   */
  deleteTreeNodes(data) {
    _.map(data, (obj, index) => {
      // SBRICS-2669: Yuvraj Chauhan : 07/06/2017
      if (obj === undefined) {
        return;
      }
      if (_.has(obj, 'productGroupId') && !obj.isDisplay) {
        data.splice(index, 1);
        this.deleteTreeNodes(data);
      } else {
        if (obj.product && obj.product.length > 0) {
          this.iterateToDeleteProduct(obj.product);
        }
      }
    });
  }

  checkValuesSelectedInTab(products) {
    let valuesSelected = false;
    for (const product of products) {
      if (_.includes(this.selectedValuesOnWs, Number(product.productId))) {
        valuesSelected = true;
        break;
      } else {
        if (!_.isUndefined(product.product) && product.product.length > 0) {
          valuesSelected = this.checkValuesSelectedInTab(product.product);
        }
        if (valuesSelected) {
          break;
        }
      }
    }
    if (valuesSelected) {
      return valuesSelected;
    }
  }

  /**
   * @description delete the child products which are not needed to display in tree view
   * @author Alkesh Shah
   * @param {*} products
   * @memberof ChannelComponent
   */
  iterateToDeleteProduct(products) {
    for (let k = 0; k < products.length; k++) {
      if (!products[k].isDisplay ||
        (this.appHeaderService.objectiveMode && products[k].product &&
          products[k].product.length && !products[k].product.filter(p => p.isDisplay).length)) {
        products.splice(k, 1);
        this.iterateToDeleteProduct(products);
      } else {
        if (!_.isUndefined(products[k].product) && products[k].product.length > 0) {
          this.iterateToDeleteProduct(products[k].product);
        }
      }
    }
  }

  /**
   * @description check the child products which needs to be displayed
   * @author Alkesh Shah
   * @param {*} products
   * @param {*} index
   * @param {*} tempColumnConfig
   * @param {*} totalProductData
   * @memberof ChannelComponent
   */
  iterateToDisplayProduct(products, index, tempColumnConfig, totalProductData, valueSelectedInTab) {
    for (const product of products) {
      if (_.includes(tempColumnConfig, product.productId.toString())) {
        if (product.inUse) {
          product.isDisplay = true;
        }
        this.findParent(totalProductData[index], null, product, totalProductData);
      }
      if (this.appHeaderService.objectiveMode) {

        if (!_.includes(this.selectedValuesOnWs, Number(product.productId)) && valueSelectedInTab) {
          if (product.inUse) {
            product.isDisplay = false;
          }
          this.findParent(totalProductData[index], null, product, totalProductData);
        }
      }
      if (!_.isUndefined(product.product) && product.product.length > 0) {
        this.iterateToDisplayProduct(product.product, index, tempColumnConfig, totalProductData, valueSelectedInTab);
      }
    }
  }

  /**
   * @description find parent of node product
   * @author Alkesh Shah
   * @param {*} node
   * @param {*} parent
   * @param {*} targetNode
   * @param {*} totalProductData
   * @returns
   * @memberof ChannelComponent
   */
  findParent(node, parent, targetNode, totalProductData) {
    if (_.isEqual(_.omit(node, ['isDisplay']), _.omit(targetNode, ['isDisplay']))) {
      this.selectParent(parent, totalProductData);
      return;
    }

    if (node.product) {
      node.product.forEach((item) => {
        this.findParent(item, node, targetNode, totalProductData);
      });
    }
  }

  /**
   * @description on parent found - make is visible
   * @author Alkesh Shah
   * @param {*} parent
   * @param {*} totalProductData
   * @returns
   * @memberof ChannelComponent
   */
  selectParent(parent, totalProductData) {
    if (parent && parent.inUse) {
      parent.isDisplay = true;
    }
    this.findParent(totalProductData[this.activeTabIndex], null, parent, totalProductData);
  }

  /**
   * @description get all children of parent product
   * @author Alkesh Shah
   * @param {*} node
   * @param {*} arr
   * @returns
   * @memberof ChannelComponent
   */
  getAllChildren(node, arr) {
    if (!node) { return; }
    arr.push(node);

    if (node.product) {
      // if the node has children call getSelected for each and concat to array
      node.product.forEach((childNode) => {
        arr = arr.concat(this.getAllChildren(childNode, []));
      });
    }
    return arr;
  }

  ngOnInit() {
    this.lookupColumnData = this.sharedService.getLookupColumnData(this.brickBaseService.brickID.Channel, null);
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.activeTabIndex = 0;
    if (this.appHeaderService.objectiveMode) {
      const brick: Brick = this.stateService.getObjectiveObject('currentObjectiveData').brick;
      const wsFilterObj = this.stateService.getWorkspaceFilterObj();
      const rowIndexOfChannel = wsFilterObj.getExistingRowIndex(Number(this.brickBaseService.brickID.Channel));
      if (rowIndexOfChannel > -1) {
        const objectiveDataCellIndex = wsFilterObj.rows[rowIndexOfChannel].cells[brick.cellIndex] ? brick.cellIndex : brick.cellIndex - 1;
        const overlappingCellIndex = wsFilterObj.rows[rowIndexOfChannel].getOverlappingCell(objectiveDataCellIndex);
        if (!wsFilterObj.rows[rowIndexOfChannel].cells[overlappingCellIndex === -1 ? objectiveDataCellIndex : overlappingCellIndex].isEmpty) {
          const selectedValuesOnWs = wsFilterObj.rows[rowIndexOfChannel].cells[overlappingCellIndex === -1 ? objectiveDataCellIndex : overlappingCellIndex].selected;
          this.selectedValuesOnWs = selectedValuesOnWs[this.lookupColumnData.lookup[0].selectionId].map(prod => prod.productId);
          if (!this.resolveObject.selectedValues) {
            this.resolveObject.selectedValues = _.cloneDeep(selectedValuesOnWs);
          }
        }
      }
    }

    this.products = this.getProductList();
    this.templateProps = {
      displayId: 'productId',
      displayName: 'productName',
      displayRadioButton: false,
      searchPlaceHolder: this.initialConfig.userBundle.search,
      showId: false,
      localSolverEnabled: GLOBAL.localSolverEnabled
    };
  }

  ngAfterViewInit() {
    /* If I load the nodes via directive, and immediately look for the nodes via "treeModel.getNodeById()" I get an exception.
     You can get around this by delaying the setIsSelected by a setTimeout.
     This behavior makes sense, because of the angular lifecycle.
     The data in the directive has to go through the OnChanges routine before they can be manipulated. Delaying it a cycle makes it work.*/
    if (this.resolveObject.selectedValues != null && !this.resolveObject.selectedValues.hasOwnProperty('-99')) {
      const selectedValues = this.resolveObject.selectedValues[this.lookupColumnData.lookup[0].selectionId];
      selectedValues.forEach((product) => {
        this.selectedRelativeFilters[product.productId] = product.relative;
        setTimeout(() => {
          if (this.treeViewComponent.tree.treeModel.getNodeById(product.productId)) {
            this.treeViewComponent.tree.treeModel.getNodeById(product.productId).setIsSelected(true);
          }
        });
      });
    }
    setTimeout(() => {
      this.showOnlyActiveTabNodes();
    }, 10);
  }

  /**
   * This method gets the selected Nodes from the Sate
   *
   * @memberof ChannelComponent
   */
  getSelectedNodesFromSate() {
    const leafNodes = this.treeViewComponent.getSelectedLeafNodeIds();
    this.selectedNodeIds = [];
    for (const key in leafNodes) {
      if (leafNodes.hasOwnProperty(key) && leafNodes[key]) {
        this.getProductNodes(key);
      }
    }
  }

  /**
   * Method to get the acutal nodes to be represented on UI
   * If all the children are selected, dispaly only the Parent Node
   * @param {*} key
   * @memberof ChannelComponent
   */
  getProductNodes(key) {
    if (GLOBAL.localSolverEnabled) {
      this.selectedNodeIds.push(parseInt(key, 10));
      return;
    }

    const parent = this.treeViewComponent.getParentNode(key);
    if (parent) {
      if (parent.isPartiallySelected) {
        this.selectedNodeIds.push(parseInt(key, 10)); // Push the child node
      } else {
        if (!this.selectedNodeIds.includes(parent.id)) {
          const parentOfParent = this.treeViewComponent.getParentNode(parseInt(parent.id, 10));
          if (parentOfParent) {
            this.getProductNodes(parent.id);
          } else {
            this.selectedNodeIds.push(parent.id);
          }
        }
      }
    } else {
      this.selectedNodeIds.push(parseInt(key, 10));
    }
  }
  /**
   * Method to get the Details of the Product
   *
   * @returns
   * @memberof ChannelComponent
   */
  getProductDetails() {
    this.getSelectedNodesFromSate();
    this.selectedProducts = [];
    this.selectedNodeIds.forEach((id) => {
      const productDetails = this.treeViewComponent.getNodeById(id);
      if (GLOBAL.localSolverEnabled) {
        if (!productDetails.isRoot) {
          this.selectedProducts.push({
            productId: productDetails.data.productId,
            productName: productDetails.data.productName,
            relative: !!this.selectedRelativeFilters[productDetails.data.productId],
          });
        }
      } else {
        this.selectedProducts.push({
          productId: productDetails.data.productId,
          productName: productDetails.data.productName,
          relative: !!this.selectedRelativeFilters[productDetails.data.productId],
        });
      }
    });
    return this.selectedProducts;
  }

  onTabChange(index: number) {
    this.activeTabIndex = index;
    this.showOnlyActiveTabNodes();
  }
  /**
   * Method to close the modal popup
   *
   * @param {*} event
   * @memberof ChannelComponent
   */
   onModalClosed(event) {
    const isSelected = {
      [this.lookupColumnData.lookup[0].selectionId]: this.getProductDetails()
    };
    if (event.reason === 'escape' && isSelected[this.lookupColumnData.lookup[0].selectionId].length > 0) {
      if (window.confirm(this.initialConfig['userBundle']['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }
  /**
   * Method to pass the result on the OK of modal popup
   *
   * @param {*} event
   * @memberof ChannelComponent
   */
  onModalSaved(event) {
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    values.selectedValues = {
      [this.lookupColumnData.lookup[0].selectionId]: this.getProductDetails()
    };
    if (values.selectedValues[this.lookupColumnData.lookup[0].selectionId].length > 0) {
      values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Channel, values.selectedValues);
      values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Channel, values.selectedValues[this.lookupColumnData.lookup[0].selectionId]);
      values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Channel, values.selectedValues, this.lookupColumnData.lookup);
      event.activeModal.close(values);
    } else {
      this.logHelperService.logError(this.userBundle['common.error.noDataSelect']);
    }
  }

  trackByProductGroup(index, tab) {
    return tab?.productGroupId;
  }
}
