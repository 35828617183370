import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { DataShareService } from '../../../../services';
import { AppNameEnum } from '../../../../enum';

/**
 * Concertina Sortable Component
 * @export
 * @class ConcertinaSortableComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-concertina-sortable',
  templateUrl: './concertina-sortable.component.html',
  styleUrls: ['./concertina-sortable.component.css']
})
export class ConcertinaSortableComponent implements OnInit {
  appNameEnum = AppNameEnum;
  applyRightMargin = false;
  constructor(
    private dataShareService: DataShareService
  ) {
  }

  /**
   * Defines sortable items
   * @type {Array<Object>}
   * @memberof ConcertinaSortableComponent
   */
  @Input() sortableItems: object[] = [];
  /**
   * Defiens flag notify event
   * @type {EventEmitter<boolean>}
   * @memberof ConcertinaSortableComponent
   */
  @Output() notifySortableFlagChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() notifyIsComponentLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Defines sortable flag
   * @memberof ConcertinaSortableComponent
   */
  sortableFlag = false;

  ngOnInit() {
    if (this.dataShareService.appName === AppNameEnum.visualplanner) {
      this.applyRightMargin = true;
    }
    this.notifyIsComponentLoaded.emit();
  }

  /**
   * To reload concertina grid
   * @memberof ConcertinaSortableComponent
   */
  refreshConcertinaGrid() {
    this.sortableFlag = true;
    this.notifySortableFlagChange.emit(this.sortableFlag);
  }

  trackByIndex(index, item) {
    return index;
  }
}
