<div class="filterbox_geo" #filterboxGeo>
    <div class="form-group_geo horizontal_geo">
        <label> {{initialConfig.userBundle["geoplanner.text.filterBox"] || 'Filter Box'}}: </label>
        <div class="tags_geo" *ngFor="let key of filterBarDataKeys; trackBy: trackByItem;" [ngStyle]="{'background-color':filterBarData[key].brickBgColor}"
            container="body" placement="bottom" [ngbTooltip]="tipContent" #t="ngbTooltip" (click)="openFilterDetail(filterBarData[key].bricID)">
            {{filterBarData[key].text}}
            <ng-template #tipContent>
                <!--for all other bricks except proximity-->
                <div *ngIf="filterBarData[key].bricID != brickBaseService.brickID.Proximity && filterBarData[key].bricID != brickBaseService.brickID.List">
                    <p *ngFor="let item of filterBarData[key].items; trackBy: trackByItem;">{{item}}</p>
                </div>
                <!--for proximity brick-->
                <div *ngIf="filterBarData[key].bricID == brickBaseService.brickID.Proximity" style="text-align: left;">
                    <div class="contents" *ngIf="filterBarData[key].postcodeSelection.values.length > 0 || filterBarData[key].postcodeSelection.listUpload.length > 0">
                        <h5>{{filterBarData[key].postcodeSelection.title}}</h5>
                        <div *ngIf="filterBarData[key].postcodeSelection.values.length > 0">
                            <div>
                                <span><strong>{{filterBarData[key].postcodeSelection.title}}:</strong></span>
                                <span *ngFor="let item of filterBarData[key].postcodeSelection.values; trackBy: trackByItem; let isLast = last;">
                                    {{item.postcode}} <span *ngIf="!isLast">,&nbsp;</span>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="filterBarData[key].postcodeSelection.listUpload && filterBarData[key].postcodeSelection.listUpload.length > 0">
                            <div class="bold">{{filterBarData[key].postcodeSelection.title}} {{initialConfig.userBundle['workspace.collection.proximity.tooltip.count']}}:{{filterBarData[key].postcodeSelection.listUpload[0].validCount}}</div>
                        </div>
                        <div *ngIf="filterBarData[key].postcodeSelection.distance != null">
                            <span class="bold">{{initialConfig.userBundle['common.distance']}}:</span> {{filterBarData[key].postcodeSelection.distance}}
                        </div>
                        <div class="bold"><span>{{filterBarData[key].postcodeSelection.include}}</span></div>
                    </div>
                    <div class="contents" *ngIf="filterBarData[key].pointOfInterest.values && filterBarData[key].pointOfInterest.values.length > 0 && filterBarData[key].pointOfInterest.distance != null">
                        <h5>{{filterBarData[key].pointOfInterest.title}}</h5>
                        <div class="left" *ngIf="filterBarData[key].pointOfInterest.values.length > 0">
                            <div>
                                <span class="bold">{{initialConfig.userBundle['workspace.collection.bric.proximity.poi.dataSource']}}:</span>{{filterBarData[key].pointOfInterest.dataSource}}
                            </div>
                            <div>
                                <span class="bold">{{initialConfig.userBundle['workspace.collection.bric.proximity.poi.attribute']}}: </span>{{filterBarData[key].pointOfInterest.attribute}}
                            </div>
                            <div>
                                <span class="bold">{{initialConfig.userBundle['workspace.collection.bric.proximity.poi.value']}}: &nbsp;</span>
                                <span *ngFor="let item of filterBarData[key].pointOfInterest.values; trackBy: trackByItem; let isLast = last;">{{item.poiName}}<span *ngIf="!isLast">,&nbsp;</span></span>
                            </div>
                        </div>
                        <div>
                            <span class="bold">{{initialConfig.userBundle['common.distance']}}:</span> {{filterBarData[key].pointOfInterest.distance}}
                        </div>
                        <div class="bold"><span>{{filterBarData[key].pointOfInterest.include}}</span></div>
                    </div>
                </div>

                <div *ngIf="filterBarData[key].bricID == brickBaseService.brickID.List" style="text-align: left;">
                    <div class="contents">
                        <div>
                            <span><strong>{{initialConfig.userBundle['workspace.collection.list.tooltip.rowsInFile']}}:</strong></span>
                            <span>{{filterBarData[key].totalCount}}</span>
                        </div>
                        <div>
                            <span><strong>{{initialConfig.userBundle['workspace.collection.list.tooltip.validIncludedFrames']}}:</strong></span>
                            <span>{{filterBarData[key].included}}</span>
                        </div>
                        <div>
                            <span><strong>{{initialConfig.userBundle['workspace.collection.list.tooltip.validExcludedFrames']}}:</strong></span>
                            <span>{{filterBarData[key].excluded}}</span>
                        </div>
                    </div>
                </div>
            </ng-template>
            <button class="close_geo" (click)="clearFilter(filterBarData[key].bricID)">x</button>
        </div>
    </div>
    <button class="filterbox-button_geo" *ngIf="showExpandBtn" (click)="expandFilters()"><em class="fa fa-angle-double-down" [ngClass]="{'collapse-icon':isExpanded}"></em></button>
</div>
