<div ngDraggable [handle]="myHandle" [bounds]="bodyEle" [inBounds]="true">
  <div #myHandle class="modal-header-comercial-valdiation" [style.background-color]="brickBgColor">
    <h4 #myHandle class="modal-title-comercial-valdiation">{{resolveObject.popup.title || title}}</h4>
    <button type="button" class="modal_close" aria-label="Close" (click)="onModalClosed()">x</button>
  </div>
  <div class="modal-body">
    <div class="alert allow-copy" [innerHTML]="resolveObject.popup.popupMessage"></div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" *ngIf="resolveObject.popup.popupType==1" (click)="onModalSaved()">OK</button>
    <button class="btn btn-primary" *ngIf="resolveObject.popup.popupType==2" (click)="onModalSaved()">YES</button>
    <button class="btn btn-primary" *ngIf="resolveObject.popup.popupType==2" (click)="onModalClosed()">NO</button>
    <div class="checkbox_lock">
      <input type="checkbox" id="lock" name="lock">
      <label for="lock" class="animated"></label>
    </div>
  </div>
</div>