<app-sb-modal-wrapper [saveButton]="saveButton" [title]="selectedTitle" [titleBackgroundColor]="brickBgColor" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <div class="tabs_container horizontal">
    <div *ngIf="confirmation; then truthyTemplate; else falsyTemplate"></div>

    <ng-template #falsyTemplate>
        <div class="control_group ">
            <label>{{userBundle['result.campaignTitle']}} :</label>
            <input type="text" class="form_control" [(ngModel)]="campaignSave.campaignName" />
        </div>
    </ng-template>
    
    <ng-template #truthyTemplate>
        <div class="control_group ">
            <div class="alert allow-copy" [innerHTML]="campaignSave">
            </div>            
          </div>
    </ng-template>
  </div>
  <div *ngIf="needFooter" footer class="right bottom-right" [ngStyle]="{'width': '20%'}">
    <button type="button" id="close_modal" class="btn btn-primary" (click)="onModalCancel()">{{cancelButton}}</button>
  </div>
</app-sb-modal-wrapper>