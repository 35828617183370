import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { GridOptions, ColDef } from 'ag-grid-community';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DataShareService } from '../../../core/services/data-share.service';
import {
  AgCheckboxComponent,
  AgCustomFilterMenuComponent,
  AgCustomHeaderComponent,
  AgCustomSort,
  AgCustomRowUtil,
  AgTextFloatingFilterComponent,
  AgTextCellRendererComponent,
  AgCurrencyCellRendererComponent,
  AgPercentageCellRendererComponent,
  AgSelectCellEditorComponent,
  DataFormatterService
} from '../ag-grid-support/index';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { LocaleData } from '../../../core/utils/LocaleData';
import { Discount } from '../../commercial.model';
import { CommercialService } from '../../../core/services';
import { ExcelCell, ExcelColumn, ExportToExcel } from '../../../core/utils/exportToExcel';
import { DatePipe } from '@angular/common';
import { SystemFlags, UiControl } from '../../../models';


@Component({
  selector: 'app-discount-grid-bal',
  templateUrl: './discount-grid-bal.component.html',
  styleUrls: ['../ag-grid-custom-style.css', '../ag-grid-col-select-style.css', './discount-grid-bal.component.css']
})
export class DiscountGridBalComponent implements OnInit, OnDestroy {

  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  @Input() campaignDetails: any = {};
  @Input() brandData = [];
  sortObj: AgCustomSort;
  /**
   * Ag-Grid Options Object
   */
  gridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  columnDefs: ColDef[];

  /**
   * column list to show in show/hide column dropdown
   */
  columnList: any = [];

  /**
   * list of Cart Data
   * @memberof CartDetailComponent
   */
  rowData: any[] = [];

  /**
   * Grid footer data
   */
  footerRowData: any[];

  /**
   * user bundle data
   */
  userBundle: any = {};
  uiControl: UiControl;

  totalFrames = 0;
  totalGrossPrice = 0;

  numericFields = [];
  dateRangeFields = [];
  selectFields = [];
  groupingField: string = null;
  notifyGridSubscription: Subscription;

  precisionOnPrice = 2;

  subs: Subscription[] = [];
  floatingDealStatusCategoryId = 7;

  indexedNegotiationTypes = [];
  public precisionOnPercentageDisplay = 2;
  constructor(
    private dataShareService: DataShareService,
    private dataFormatterService: DataFormatterService,
    private commercialService: CommercialService,
    private datePipe: DatePipe
  ) {
    this.subs.push(this.commercialService.discounts$.subscribe((res: Discount[]) => {
      this.rowData = [];
      this.reset();
      this.campaignDetails.discounts = _.clone(res);
      this.prepareRowData();
    }));
  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.uiControl = this.campaignDetails.uiControl;

    this.setFooterRows();
    this.prepareRowData();
    this.prepareColDef();

    this.reset();

    // we pass an empty gridOptions in, so we can grab the api out
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      onRowDataChanged: (event) => {
        event.api.setPinnedBottomRowData(this.footerRowData);
      },
      scrollbarWidth: 10,
      onGridReady: this.onGridReady,
      getRowHeight: this.getRowHeight,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      suppressScrollOnNewData: true,
      onFilterChanged: this.onFilterChanged,
      groupingField: this.groupingField,
      suppressMovableColumns: true,
      isExternalFilterPresent: () => true,
      // tslint:disable-next-line:ter-arrow-parens
      doesExternalFilterPass: (node) => node.data.isVisible,
      onCellClicked: (params) => {
        if (params.rowPinned === 'bottom' && params.rowIndex === 0 && params.column.isCellEditable(params.node)) {
          const par: any = {
            column: params.column,
            rowIndex: params.rowIndex,
            rowPinned: params.rowPinned
          };
          params.api['rowRenderer'].startEditingCell(par);
        }
      }
    } as GridOptions;



  }

  formatFinalPrice(price) {
    let inputStr = price.replace(/\.\d+/g, '');
    inputStr = inputStr.replace(/[^0-9,]/g, '');
    return inputStr;
  }

  ngOnDestroy() {
    let _finalPrice = Math.round(parseFloat(this.rowData[0].totalFinalPrice));
    let formatedFinalPrice = this.formatFinalPrice(this.dataFormatterService.currency(_finalPrice, 2));
    this.dataShareService.finalPrice.fill(formatedFinalPrice);
    if(!this.dataShareService.isCampaignSummaryAvbl){
      this.dataShareService.loadedFinalPrice.fill(formatedFinalPrice);
    }
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.forEach((r) => {
        r.isFilterPass = true;
      });
    }
    AgCustomRowUtil.setParentRowSelectedProperty(this.rowData, this.groupingField);
    // Refresh Grid Header checkbox component
    this.gridOptions.api['headerRootComp'].forEachHeaderElement((element) => {
      if (element.column.colId === 'selectChk' && element.columnApi && element.childComponents && element.childComponents.length > 1) {
        const headerCmpIns = element.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  clearAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    this.gridOptions.api.onFilterChanged();
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }

  getGridSummary() {
    return `<span>${this.userBundle['discount.common.totalFrames']}: ${this.getChildRows().length} &#45;
     ${this.userBundle['discount.common.selectedFrames']}: ${this.getSelectedChildRows().length}</span>`;
  }

  onGridReady = () => {
    this.columnList = this.gridOptions.columnApi.getAllColumns();
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else if (params.data.isVisible) {
      height = 30;
    }
    return height;
  }

  isFullWidthCell = (rowNode): boolean => {
    return (rowNode.data.gridSummary ? true : false);
  }

  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen()) {
      if ($event.button !== 2 && !dropdownEle._isEventFromToggle($event)
        && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
        dropdownEle.close();
      }
    }
  }

  prepareRowData() {
    this.rowData = [];
    const tempRowData = [];
    let totalAutomationPrice = 0;
    let totalFinalPrice = 0;

    for (const discount of this.campaignDetails.discounts) {
      const curRow: any = discount;
      curRow.isFilterPass = true;
      curRow.isParent = false;
      curRow.isVisible = true;
      curRow.isSelected = false;
      totalAutomationPrice = totalAutomationPrice + Number(curRow.automationPrice);
      if(curRow.applyIndex){
        curRow.seasonalityIndex = curRow.seasonalityIndexCalc;
        curRow.frameVolumeIndex = curRow.frameVolumeIndexCalc;
        curRow.proximityIndex = curRow.proximityIndexCalc;
        curRow.listIndex = curRow.listIndexCalc;
        curRow.finalPrice = (curRow.automationPrice * (curRow.seasonalityIndex/100) * (curRow.frameVolumeIndex/100) * (curRow.proximityIndex/100) * (curRow.listIndex/100)).toFixed(this.precisionOnPrice);
      } else {
        curRow.seasonalityIndex = 100;
        curRow.frameVolumeIndex = 100;
        curRow.proximityIndex = 100;
        curRow.listIndex = 100;
        curRow.finalPrice = (curRow.automationPrice * (curRow.seasonalityIndex/100) * (curRow.frameVolumeIndex/100) * (curRow.proximityIndex/100) * (curRow.listIndex/100)).toFixed(this.precisionOnPrice);
      }
      totalFinalPrice = totalFinalPrice + Number(curRow.finalPrice);
      tempRowData.push(curRow);
    }
    tempRowData.forEach((tempRow) => {
      tempRow.totalAutomationPrice = totalAutomationPrice;
      tempRow.totalFinalPrice = totalFinalPrice.toFixed(this.precisionOnPrice);
    });
    this.rowData = _.clone(tempRowData);
  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinRowIndex: 0 },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
  }

  pinnedRowCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {
      // apply Icon
      const eImgApply: HTMLImageElement = document.createElement('img');
      eImgApply.src = 'images/general/apply.png';
      eImgApply.title = 'Apply';
      eImgApply.onclick = this.apply;
      eDiv.appendChild(eImgApply);
    }
    return eDiv;
  }

  prepareColDef() {
    let applyIndexKeyValue = [{id:true,value:'Yes'},{id:false,value:'No'}];
    this.columnDefs = [
      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false, colId: 'selectChk',
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: (params): string[] => {
          const retArr: string[] = [];
          if (params.data.isSelected) {
            retArr.push('selectedRow');
          }
          if (params.data.amountAdjusted && !params.data.isParent) {
            retArr.push('amountAdjusted');
          }
          if (params.node.rowPinned === 'bottom' && params.rowIndex === 1) {
            retArr.push('borderBottom');
          }
          return retArr;
        }
      },
      {
        headerName: this.userBundle['discount.bal.productCatalogue'], field: 'productCatalogueName', width: 130, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.pinnedRowCellRenderer,
      },

      {
        headerName: this.userBundle['discount.bal.network'], field: 'networkName', width: 100, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer,
      },
      {
        headerName: this.userBundle['discount.bal.city'], field: 'countyName', width: 100, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer,
      },
      {
        headerName: this.userBundle['discount.bal.productformat'], field: 'productFormatName', width: 180, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer
      },

      {
        headerName: this.userBundle['discount.bal.weekNumber'], field: 'weekNumberOfYear', width: 100, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.bal.startDate'], field: 'startDateStr', width: 150, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.bal.endDate'], field: 'endDateStr', width: 180, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.bal.weekDays'], field: 'weekDays', width: 180, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.bal.numberFrames'], field: 'numberFrames', width: 150, editable: false,
        cellRendererFramework: AgTextCellRendererComponent,
        cellRendererParams: {
          onlyNumber: true,
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgTextCellRendererComponent,
        pinnedRowCellRendererParams: {
          onlyNumber: true,
          cellEditable: () => {
            return false;
          }
        },
      },
      {
        headerName: this.userBundle['discount.bal.automationPrice'], field: 'automationPrice', width: 150, editable: false,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: false,
          prefixText: (params) => {
            if (params.automationPrice === 0 || (params.automationPrice && params.automationPrice !== '')) {
              return '';
            } else {
              return this.userBundle['discount.common.total'];
            }
          },
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: false
        }
      },
      {
        headerName: this.userBundle['discount.bal.applyIndex'], field: 'applyIndex',
        width: 150, editable: false,
        cellRendererFramework: AgSelectCellEditorComponent,
        cellRendererParams: {
          valKey: 'id',
          textKey: 'value',
          dataArr: () => applyIndexKeyValue,
          onSelectChange:(data) =>{
            this.onSelectChange([data.data],data.data.applyIndex);
            this.gridOptions.api.redrawRows();
          }
        },
      },
      {
        headerName: this.userBundle['discount.bal.seasonalityIndex'], field: 'seasonalityIndex', width: 150, editable: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: false
        },
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.bal.frameVolumeIndex'], field: 'frameVolumeIndex', width: 130, editable: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: false
        },
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.bal.listIndex'], field: 'listIndex', width: 130, editable: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: false
        },
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.bal.proximityIndex'], field: 'proximityIndex', width: 130, editable: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: false
        },
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.bal.finalPrice'], field: 'finalPrice', width: 150, editable: false,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: false,
          prefixText: (params) => {
            if (params.automationPrice === 0 || (params.automationPrice && params.automationPrice !== '')) {
              return '';
            } else {
              return this.userBundle['discount.common.total'];
            }
          },
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: false
        }
      }
    ];

    this.numericFields = [
      'numberFrames',
      'automationPrice',
      'seasonalityIndex',
      'frameVolumeIndex',
      'listIndex',
      'proximityIndex',
      'finalPrice',
      'weekNumberOfYear'
    ];

    this.dateRangeFields = [

    ];
    this.selectFields = ['negotiationTypeId'];

    const decimalFieldsForFilter = [
      'automationPrice',
      'seasonalityIndex',
      'frameVolumeIndex',
      'listIndex',
      'proximityIndex',
      'finalPrice'
    ];
    for (let i = 1; i < this.columnDefs.length; i++) {

      this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
      this.columnDefs[i].floatingFilterComponentParams = {
        value: '',
        suppressFilterButton: true,
        decimalRound: decimalFieldsForFilter.indexOf(this.columnDefs[i].field) > -1 ? 2 : null
      };

      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };

      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => {
        const retArr: string[] = [];
        if (params.data.isParent || params.node.rowPinned === 'bottom') {
          retArr.push('fontBold');
        }
        if (params.node.rowPinned === 'bottom') {
          if (i > 4 || params.rowIndex === 1) {
            retArr.push('borderBottom');
          }
        }
        if (params.data.isSelected) {
          retArr.push('selectedRow');
        }
        return retArr;
      };
    }

    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField, true);
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
    // restore original data order when sorting is cleared
    if (this.sortObj.sortColumns.length === 0) {
      this.rowData = _.clone(this.campaignDetails.discounts);
    }
  }

  blankCellRenderer() {
    return '';
  }


  reset = () => {
    this.rowData = _.clone(this.rowData);
  }


  isCellEditable(data) {
    return !data.isParent && !data.locked;
  }

  toggleColumnVisible(colId, isVisible) {
    const isVisibleLoc = !isVisible;
    this.gridOptions.columnApi.setColumnVisible(colId, isVisibleLoc);
  }

  // Data functions
  getChildRows(groupingId?: string, filterOnly = true): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  getSelectedChildRows(groupingId?: string, filterOnly = false): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  getParentRows(groupingId?: string, data?: any[], groupingField?: string): any[] {
    const newData = data || this.rowData;
    return AgCustomRowUtil.getParentRows(newData, groupingField, groupingId);
  }

  sumFieldValues(rows: any[], fieldName: string): number {
    return AgCustomRowUtil.sumFieldValues(rows, fieldName);
  }

  /**
  * download cart data in excel format
  */
  downloadExcel() {
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    // Add Header Row
    let sheetRow: ExcelCell[] = [];
    for (let j = 1; j < this.columnDefs.length; j++) {
      const cell: ExcelCell = {
        text: this.columnDefs[j].headerName,
        fontColor: '#FFFFFF',
        bgColor: '#000000',
        borderColor: '#CCCCCC',
        isFontBold: true
      };
      const col: ExcelColumn = {
        width: this.columnDefs[j].field === 'week' ? 15 : 12
      };
      excelColumn.push(col);
      sheetRow.push(cell);
    }
    sheetData.push(sheetRow);

    // Add Data rows
    let rowsInserted = 0;
    for (const row of this.rowData) {
      if (row.isParent) {
        continue;
      }

      sheetRow = [];
      for (let j = 1; j < this.columnDefs.length; j++) {
        let cellVal = row[this.columnDefs[j].field];
        if (this.selectFields.indexOf(this.columnDefs[j].field) > -1) {
          cellVal = row[`${this.columnDefs[j].field}_sort`];
        }
        if (this.columnDefs[j].field === 'startDate' || this.columnDefs[j].field === 'endDate') {
          if (cellVal) {
            cellVal = this.datePipe.transform((new Date(cellVal)), LocaleData.displayDateFormat);
          }
        }
        const cell: ExcelCell = {
          text: cellVal,
          fontColor: '#000000',
          bgColor: rowsInserted % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
          borderColor: '#CCCCCC'
        };
        sheetRow.push(cell);
      }
      rowsInserted++;
      sheetData.push(sheetRow);
    }
    const cartText = 'discount';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }

  /**
   * @description To check that is discount grid is editable or not
   * @author Dhaval Patel
   */
  private manageReadOnly() {
    if (this.dataShareService.getInitialConfigByKey('uiControl').externalUser || SystemFlags.readOnly) {
      const ele: any = document.querySelector('.ag-theme-fresh .ag-body-viewport');
      if (ele) {
        ele.className = `${ele.className} readOnly`;
      }
      const ele1: any = document.querySelector('.ag-theme-fresh .ag-floating-bottom');
      if (ele1) {
        ele1.className = `${ele1.className} readOnly`;
      }
    } else {
      const ele: any = document.querySelector('.ag-theme-fresh .ag-body-viewport');
      if (ele) {
        ele.classList.remove('readOnly');
      }
      const ele1: any = document.querySelector('.ag-theme-fresh .ag-floating-bottom');
      if (ele1) {
        ele1.classList.remove('readOnly');
      }
    }
  }

  apply = () => {
    const selectedChildRows = this.getSelectedChildRows();
    let totalFinalPrice = 0;
    if (selectedChildRows.length > 0){
      if(this.footerRowData[0].applyIndex === 'true'){
        for (const row of selectedChildRows) {
          row.applyIndex = true;
          row.seasonalityIndex = row.seasonalityIndexCalc;
          row.frameVolumeIndex = row.frameVolumeIndexCalc;
          row.proximityIndex = row.proximityIndexCalc;
          row.listIndex = row.listIndexCalc;
          row.finalPrice = (row.automationPrice * (row.seasonalityIndex/100) * (row.frameVolumeIndex/100) * (row.proximityIndex/100) * (row.listIndex/100)).toFixed(this.precisionOnPrice);
        }
      } else if(this.footerRowData[0].applyIndex === 'false'){
        for (const row of selectedChildRows) {
          row.applyIndex = false;
          row.seasonalityIndex = 100;
          row.frameVolumeIndex = 100;
          row.proximityIndex = 100;
          row.listIndex = 100;
          row.finalPrice = (row.automationPrice * (row.seasonalityIndex/100) * (row.frameVolumeIndex/100) * (row.proximityIndex/100) * (row.listIndex/100)).toFixed(this.precisionOnPrice);
        }
      }
      for(const row of this.rowData){
        totalFinalPrice = totalFinalPrice + Number(row.finalPrice);
      }
      this.rowData.forEach(temprow => {
        temprow.totalFinalPrice = totalFinalPrice.toFixed(this.precisionOnPrice);
      })
      this.gridOptions.api.redrawRows();
    }
  }

  onSelectChange(selectedChildRows, applyIndex) {
    let totalFinalPrice = 0;
    if (selectedChildRows.length > 0 && selectedChildRows[0].automationPrice) {
      if(applyIndex === 'true'){
        for (const row of selectedChildRows) {
          row.applyIndex = true;
          row.seasonalityIndex = row.seasonalityIndexCalc;
          row.frameVolumeIndex = row.frameVolumeIndexCalc;
          row.proximityIndex = row.proximityIndexCalc;
          row.listIndex = row.listIndexCalc;
          row.finalPrice = (row.automationPrice * (row.seasonalityIndex/100) * (row.frameVolumeIndex/100) * (row.proximityIndex/100) * (row.listIndex/100)).toFixed(this.precisionOnPrice);
        }
      }else {
        for (const row of selectedChildRows) {
          row.applyIndex = false;
          row.seasonalityIndex = 100;
          row.frameVolumeIndex = 100;
          row.proximityIndex = 100;
          row.listIndex = 100;
          row.finalPrice = (row.automationPrice * (row.seasonalityIndex/100) * (row.frameVolumeIndex/100) * (row.proximityIndex/100) * (row.listIndex/100)).toFixed(this.precisionOnPrice);
        }
      }
    }
    for(const row of this.rowData){
      totalFinalPrice = totalFinalPrice + Number(row.finalPrice);
    }
    this.rowData.forEach(temprow => {
      temprow.totalFinalPrice = totalFinalPrice.toFixed(this.precisionOnPrice);
    })
    return null;
  }

  /**
   * @description This method will be called from parent component when readOnly property is changed.
   * @author Dhaval Patel
   */
  readOnlyUpdate() {
    this.manageReadOnly();
  }

  trackByItem(index, column) {
    return column?.colDef?.field;
  }

  trackByField(index, column) {
    return column?.colDef?.field;
  }

}
