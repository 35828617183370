<div [ngClass]="{'readOnly' : readOnly }">
    <div class="width_3">
        <div class="control_group">
            <label class="label datelabel">
                {{userBundle['common.campaignStartDate']}}
            </label>
            <input id="campaignStartDate" type="text" [disabled]="true"
                [ngModel]="campaignDetails.campaignStartDate | dateFormat : displayFromToDateFormat"
                class="form_control" />
        </div>
    </div>
    <div class="width_3">
        <div class="control_group">
            <label class="label datelabel">
                {{userBundle['common.campaignEndDate']}}
            </label>
            <input id="campaignEndDate" type="text" [disabled]="true"
                [ngModel]="campaignDetails.campaignEndDate  | dateFormat : displayFromToDateFormat "
                class="form_control" />
        </div>
    </div>
    <div class="width_3">
        <div class="control_group">
            <label class="label datelabel">{{userBundle['common.campaignCreateDate']}}</label>
            <input type="text" id="campaignCreateDate" [disabled]="true"
                [ngModel]="campaignDetails.campaignCreateDate  | dateFormat : displayDateFormat" class="form_control" />
        </div>
    </div>
    <div class="width_3">
        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label"
                [ngClass]="{'required' : (campaignDetails?.advertiser.advertiserId == '' || campaignDetails?.advertiser.advertiserId == null)} ">
                {{userBundle['common.advertiserName']}}
            </label>

            <div class="gcontainer">
                <app-sb-select id="advertiserSelect" [config]="advertiserLookupConfig" [items]="advertisersList"
                    [currentItem]="campaignDetails.advertiser" (onItemSelected)="onAdvertiserNameSelected($event)"
                    (onItemRemoved)="removeSelectedAdvertiserName($event);"
                    (responseData)="getAdvertisersResponse($event)">
                </app-sb-select>
            </div>
        </div>

        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label">{{userBundle['common.specialistName']}}</label>
            <div class='gcontainer'>
                <app-sb-select id="specialistSelect" [config]="specialistLookupConfig" [items]="specialistsList"
                    [currentItem]="campaignDetails.specialist" (onItemSelected)="onSpecialistNameSelected($event)"
                    (onItemRemoved)="removeSelectedSpecialistName()" (responseData)="getSpecialistsResponse($event)"
                    [disabled]="readOnly || campaignDetails.disableSpecialist">
                </app-sb-select>
            </div>
        </div>

        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label">{{userBundle['common.agencyName']}}</label>
            <div class='gcontainer'>
                <app-sb-select id="agencySelect" [config]="agencyLookupConfig" [items]="agenciesList"
                    [currentItem]="campaignDetails.agency" (onItemSelected)="onAgencyNameSelected($event)"
                    (onItemRemoved)="removeSelectedAgencyName()" (responseData)="getAgenciesResponse($event)"
                    [disabled]="readOnly  || campaignDetails.disableAgency">
                </app-sb-select>
            </div>
        </div>

        <div class="control_group" [hidden]="commercialHiddenElements('commercial.booking.agencyPercentage')">
            <label class="label">{{userBundle['common.agency.commission']}} </label>
            <input id="agencyPercentage" type="text" [imask]="percentageInputMask" [unmask]="maskService.unmask.typed"
                class="form_control percentage" [(ngModel)]="campaignDetails.agencyPercentage"
                [disabled]="commercialService.commercialDisabledElements('commercial.clientDetails.agencyPercentage')" />
        </div>

    </div>
    <div class="width_3">
        <!-- !(this.campaignDetails.brand.brandId > 0) -->
        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label"
                [ngClass]=" (campaignDetails?.brand.brandId == 0 || campaignDetails?.brand.brandId == null) ?'required' :''">
                {{userBundle['common.brandName']}}
            </label>
            <div class='gcontainer'>
                <app-sb-select id="brandSelect" [config]="brandLookupConfig" [items]="brandsList"
                    [currentItem]="campaignDetails.brand" (onItemSelected)="onBrandSelected($event)"
                    (onItemRemoved)="removeSelectedBrand($event)" (responseData)="getBrandsResponse($event)">
                </app-sb-select>
            </div>

        </div>

        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label" [ngClass]="{'required' : campaignDetails.productCategoryId == -1}">
                {{userBundle['common.productCategoryName']}}</label>
            <select id="productCategorySelect" class="form_control chzn-container"
                [(ngModel)]="campaignDetails.productCategoryId" [ngStyle]="{'width': '58%'}">
                <option value="-1">{{userBundle['common.lookup.defaultOption']}}</option>
                <ng-template ngFor let-item [ngForOf]="productCategoryList"
                    [ngForTrackBy]="trackByProductCategoryGroup">
                    <optgroup *ngIf="item.productCategory"
                        label="{{item.productCategoryGroupName}} - {{item.productCategoryGroupCode}}">
                        <option *ngFor="let child of item.productCategory; trackBy: trackByProductCategory;"
                            [value]="child.productCategoryId">
                            {{child.productCategoryName}}
                        </option>
                    </optgroup>
                    <option *ngIf="!item.productCategory">{{item.productCategoryGroupName}}</option>
                </ng-template>
            </select>
        </div>


        <div class="control_group">
            <label class="label">{{userBundle['commercial.label.product']}}</label>
            <input id="productValue" type="text" class="form_control" [(ngModel)]="campaignDetails.product" />
        </div>
    </div>

    <div class="width_3">

        <div class="control_group">
            <label class="label" [ngClass]="{'required' : campaignDetails.salesTeamId == -1}">
                {{userBundle['common.salesTeamName']}}
            </label>
            <select id="salesTeamSelect" class="form_control chzn-container" [(ngModel)]="campaignDetails.salesTeamId"
                [disabled]="userData.externalUser" [ngStyle]="{'width': '58%'}">
                <option value="-1">{{userBundle['common.lookup.defaultOption']}}</option>
                <option *ngFor="let SaleInfo of salesTeamList; trackBy: trackBySalesTeam;"
                    [value]="SaleInfo.salesTeamId">
                    {{SaleInfo.salesTeamName}}
                </option>
            </select>
        </div>

        <div class="control_group">
            <label class="label" [ngClass]="{'required':(isMediaLengthRequired
      && (campaignDetails.mediaLength == null
       || campaignDetails.mediaLength == ''
      || campaignDetails.mediaLength === '0'))}">
                {{userBundle['commercial.label.mediaLength']}}
            </label>
            <input id="mediaLength" type="text" [appOnlyNumber]="true" [decimalPoints]="0" [minValue]="0"
                [maxValue]="999" class="form_control percentage" [(ngModel)]="campaignDetails.mediaLength"
                [disabled]="commercialService.commercialDisabledElements('commercial.clientDetails.mediaLength')" />
        </div>

    </div>

    <div class="width_8">
        <div class="control_group">
            <label class="label" [ngClass]="campaignDetails.title == null || campaignDetails.title == ''?'required':''">
                {{userBundle['common.title']}}
            </label>
            <textarea id="campaignTitle" class="form_control customtextarea" [(ngModel)]="campaignDetails.title"
                (change)="manuallyTitleChanged()"></textarea>
        </div>
    </div>
    <div class="width_3" *ngIf="!commercialHiddenElements('commercial.booking.link')">
        <div class="control_group">
            <label class="label">{{userBundle['commercial.label.link']}}</label>
            <ng-select class="form_control" [items]="campaignReferenceList" [multiple]="true"
                bindLabel="campaignReferences" [closeOnSelect]="false" bindValue="campaignReference"
                placeholder="None Selected" [(ngModel)]="campaignDetails.campaignReferences"
                [disabled]="commercialService.commercialDisabledElements('commercial.clientDetails.campaignReferences')">

                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    {{item.campaignReferenceDisplay}}
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear" let-index="index">
                    <span class="ng-value-label">{{item.campaignReference}}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
            </ng-select>
        </div>
    </div>
    
    <div class="width_3" *ngIf="commercialConfig.uiControl.showTargetImpression">
      <div class="control_group">
          <label class="label">{{userBundle['commercial.label.targetImpressions'] || "Target Impressions"}}</label>
          <input id="campaignCPT" type="text" [(ngModel)]="campaignDetails.campaignTargetImpressions" [imask]="commonNumberInputMask"
              [unmask]="maskService.unmask.typed" (change)="campaignTargetImpressionsChanged()" class="form_control percentage" />
      </div>
    </div>

    <div class="width_8">
        <div class="control_group">
            <label class="label">{{userBundle['common.comments']}}</label>
            <textarea id="comments" class="form_control customtextarea-comments"
                [(ngModel)]="campaignDetails.comments"></textarea>
        </div>
    </div>

    <div class="width_3"
        [hidden]="commercialHiddenElements('commercial.booking.sendProposal') && commercialHiddenElements('commercial.booking.assignedTo')">
        <div class="control_group" [hidden]="commercialHiddenElements('commercial.booking.sendProposal')">
            <input type="checkbox" class="checkbox" [(ngModel)]="campaignDetails.sendProposal" id="SP"
                [disabled]="commercialService.commercialDisabledElements('commercial.clientDetails.sendProposal')">
            <label for="SP">{{userBundle['commercial.label.sendProposal']}}</label>
        </div>
        <div class="control_group" [hidden]="commercialHiddenElements('commercial.booking.assignedTo')">
            <label class="label">
                {{userBundle['common.assignedToName']}}
            </label>

            <select id="Select9" class="form_control chzn-container" [(ngModel)]="campaignDetails.assignedTo"
                [ngStyle]="{'width': '58%'}" (change)="assignedToChange()"
                [disabled]="commercialService.commercialDisabledElements('commercial.clientDetails.assignedTo')">
                <option value="undefined">
                    {{userBundle['common.lookup.defaultOption']}}
                </option>
                <option *ngFor="let AssignedTo of assignedToList; trackBy: trackByAssignedTo;"
                    [ngValue]="AssignedTo" [disabled]="!AssignedTo.inUse"
                    [ngClass]="{'inactive-assignedto': !AssignedTo.inUse}">
                    {{AssignedTo.assignedToName}}
                </option>
            </select>
        </div>
    </div>
    <div class="width_3">
        <div class="control_group">
            <label class="label"
                [ngClass]="{'required' : floatingDatesMandatory && !campaignDetails.floatingStartDate}">
                {{userBundle['discount.belgium.startFloatingDate']}}
            </label>
            <input type="text" class="form_control" name="floatingstartdate" #floatingstartdate="ngbDatepicker"
                (keydown)="cleanFloatingDate('start',floatingstartdate)" [(ngModel)]="campaignDetails.floatingStartDate"
                [dayTemplate]="floatingDateTemplate" [minDate]="minStartDate" [maxDate]="maxEndDate" ngbDatepicker
                (click)="openDatePicker($event, floatingstartdate,floatingEndDate)" [outsideDays]="'collapsed'"
                (document:click)="closeDatePickerOutsideClick($event, floatingstartdate)" autocomplete="off">
        </div>
    </div>

    <div class="width_3">
        <div class="control_group">
            <label class="label" [ngClass]="{'required' : floatingDatesMandatory && !campaignDetails.floatingEndDate}">
                {{userBundle['discount.belgium.endFloatingDate']}}
            </label>
            <input type="text" class="form_control" name="floatingEndDate" #floatingEndDate="ngbDatepicker"
                (keydown)="cleanFloatingDate('end',floatingEndDate)" [(ngModel)]="campaignDetails.floatingEndDate"
                [dayTemplate]="floatingDateTemplate" [showWeekNumbers]="false" [minDate]="minStartForEndDate"
                ngbDatepicker (click)="openDatePicker($event, floatingEndDate,floatingstartdate)"
                [outsideDays]="'collapsed'" (document:click)="closeDatePickerOutsideClick($event, floatingEndDate)"
                autocomplete="off">
        </div>
    </div>

    <div class="width_3">
        <div class="control_group">
            <label class="label"
                [ngClass]="{'required' : (!userData['externalUser'] &&  (campaignDetails?.externalReference == '' || campaignDetails?.externalReference == null))}">
                {{userBundle['commercial.label.externalReference']}}
            </label>
            <input type="text" id="externalReference" class="form_control"
                [(ngModel)]="campaignDetails.externalReference" maxlength="50"
                [disabled]="commercialService.commercialDisabledElements('commercial.clientDetails.externalReference')" />
        </div>

        <div class="control_group">
            <label class="label">{{userBundle['common.buyerContactName']}}</label>
            <div class="gcontainer">
                <app-sb-select id="buyercontact" [config]="buyerContactLookupConfig" [items]="buyerContactList"
                    [currentItem]="campaignDetails.buyerContact" (onItemSelected)="onbuyerContactSelected($event)"
                    (onItemRemoved)="removeSelectedbuyerContact()" (responseData)="getBuyerContactResponse($event)">
                </app-sb-select>
            </div>
        </div>
    </div>

    <div class="width_3">
        <button *ngIf="uiControl.externalReferenceButtonEnabled" (click)="getExternalReference()" class="btn"
            id="get-reference-button">
            {{userBundle['commercial.label.getReference'] || "Get Reference"}}
        </button>
        <button *ngIf="campaignDetails.externalReference && uiControl.externalCampaignHeaderButtonEnabled"
            (click)="getHeader()" class="btn" id="get-reference-button">
            {{userBundle['commercial.label.getHeader'] || "Get Header"}}
        </button>
        <button *ngIf="campaignDetails.externalReference && commercialConfig.uiControl.externalProposalAppUrl"
            (click)="openSalesProposalLink()" class="btn" id="sales-proposal-link">
            {{userBundle['common.salesProposalLink'] || "Sales Proposal Link"}}
        </button>
    </div>

    <div class="width_3" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}"
        [hidden]="commercialHiddenElements('commercial.booking.pmpDealId')">
        <div class="control_group">
            <label class="label"
                [ngClass]="{'required' : (searchPageObject.campaignTypeGroupId == campaignGroupTypeList.PMP) && (!campaignDetails.pmpDealId || campaignDetails.pmpDealId === '')} ">
                {{userBundle['common.pmpDealId']}}</label>
            <input type="text" class="form_control" autocomplete="off" [(ngModel)]="campaignDetails.pmpDealId"
                maxlength="50" />
        </div>
    </div>
    <div class="width_3" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}"
        [hidden]="commercialHiddenElements('commercial.booking.campaignCPT')">
        <div class="control_group">
            <label class="label"
                [ngClass]="{'required' : (searchPageObject.campaignTypeGroupId == campaignGroupTypeList.PMP && !campaignDetails.campaignCPT)} ">{{userBundle['common.campaignCPT']}}</label>
            <input id="campaignCPT" type="text" [(ngModel)]="campaignDetails.campaignCPT" [imask]="currencyInputMask"
                [unmask]="maskService.unmask.typed" class="form_control percentage"
                [disabled]="commercialService.commercialDisabledElements('commercial.booking.campaignCPT')"
                (blur)="onAverageCPTChanged()" />
        </div>
    </div>
    <div class="width_3" *ngIf="searchPageObject.campaignTypeGroupId == campaignGroupTypeList.PMP">
      <div class="control_group">
        <label class="label" style="width: 46%">
          {{userBundle['commercial.label.preventRecalculation'] || 'Prevent Recalculation'}}
        </label>
        <div class="swichControl-commercial">
          <label class="switchAllowFrames">
            <input type="checkbox" [(ngModel)]="campaignDetails.preventRecalculation" (change)="preventRecalculationChanged()">
            <span class="sliderAllowFrames round"></span>
          </label>
        </div>
      </div>
    </div>
    <div class="width_3" *ngIf="commercialConfig.uiControl.showOvershowToggle">
        <div class="control_group">
            <label class="label" style="width: 46%">
                {{userBundle['commercial.label.overshow']}}
            </label>
            <div class="swichControl-commercial">
                <label class="switchAllowFrames">
                    <input type="checkbox" [(ngModel)]="campaignDetails.overshow">
                    <span class="sliderAllowFrames round"></span>
                </label>
            </div>
        </div>
    </div>
    <div class="width_8" *ngIf="commercialConfig.uiControl.historyROC && historyROC.data">
        <div class="control_group">
            <label class="label">{{userBundle['commercial.label.historyROC'] || 'History'}}</label>
            <textarea class="form_control customtextarea-comments histpry-pointer" [(ngModel)]="historyROC.data" maxlength="2000"
                cols="30" rows="1" title={{historyROC.data}} disabled></textarea>
        </div>
    </div>
    <div class="width_3" [ngClass]="{'force-enable-field' : showLockedStatus && lockReason !== 'USER_LOCKED'}"
        *ngIf="showLockedStatus">
        <div class="control_group">
            <label class="label" style="width: 46%">
                {{userBundle['commercial.label.invoiceLocked'] || 'Invoice Locked'}}
            </label>
            <div class="swichControl-commercial">
                <label class="switchAllowFrames">
                    <input type="checkbox" [(ngModel)]="campaignDetails.invoiceLocked" (change)="lockStatusChanged()">
                    <span class="sliderAllowFrames round"></span>
                </label>
            </div>
        </div>
    </div>
    <div class="width_3">
        <div class="control_group">
            <label class="label" style="width: 46%">
                {{userBundle['commercial.label.readyForPosting'] || 'Ready For Posting'}}
            </label>
            <div class="swichControl-commercial">
                <label class="switchAllowFrames">
                    <input type="checkbox" [(ngModel)]="campaignDetails.readyForPosting">
                    <span class="sliderAllowFrames round"></span>
                </label>
            </div>
        </div>
    </div>
    <div class="width_3">
        <div class="control_group">
            <label class="label"
                [ngClass]="{'required' : (campaignDetails?.adminPerson.adminPersonId == '' || campaignDetails?.adminPerson.adminPersonId == null)} ">
                {{userBundle['common.adminPersonName']}}
            </label>

            <div class="gcontainer">
                <app-sb-select id="adminPerson" [config]="adminPersonLookupConfig" [items]="adminPersonList"
                    [currentItem]="campaignDetails.adminPerson" (onItemSelected)="onAdminPersonSelected($event)"
                    (onItemRemoved)="removeSelectedAdminPersonContact()"
                    (responseData)="getAdminPersonResponse($event)">
                </app-sb-select>
            </div>
        </div>
    </div>
    <div class="width_3">
        <div class="control_group">
            <label class="label"
                [ngClass]="{'required' : (campaignDetails?.salesPerson.salesPersonId == '' || campaignDetails?.salesPerson.salesPersonId == null)} ">
                {{userBundle['common.salesPersonName']}}
            </label>

            <div class="gcontainer">
                <app-sb-select id="salesPerson" [config]="salesPersonLookupConfig" [items]="salesPersonList"
                    [currentItem]="campaignDetails.salesPerson" (onItemSelected)="onSalesPersonSelected($event)"
                    (onItemRemoved)="removeSelectedSalesPerson()" (responseData)="getSalesPersonResponse($event)">
                </app-sb-select>
            </div>
        </div>
    </div>
    <ng-template #floatingDateTemplate let-date="date" let-currentMonth="currentMonth" let-selected="selected"
        let-disabled="disabled">
        <span class="day-container" [class.hidden]="date.month !== currentMonth"
            [class.text-muted]="getStartDayProp(date, 'isDisabled')">
            <div class="custom-day" [class.selected]="selected" [class.today]="getStartDayProp(date, 'isToday')">
                {{ date.day }}
            </div>
        </span>
    </ng-template>
</div>
