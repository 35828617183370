import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DndModule } from 'ng2-dnd';
import { ResultMapComponent } from './result-map.component';
import { CoreModule } from '../core/core.module';
import { ResultService } from '../result/result.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MapComponent } from '../result/map/map.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    NgbModule,
    DndModule.forRoot(),
    CoreModule.forRoot()
  ],
  entryComponents: [
    ResultMapComponent
  ],
  declarations: [
    ResultMapComponent,
    MapComponent
  ],
  providers: [
    ResultService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ResultMapModule { }
