<app-sb-modal-wrapper [title]="userBundle['workspace.collection.bric.productCatalogue.heading']" [readOnlyModal]="readOnlyModal"
  [titleBackgroundColor]="resolveObject.brick.color" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">

    <div class="tabs_container horizontal">
        <ul class="tabs-menu">
            <ng-container *ngFor="let tab of productCatalogueTypes; trackBy: trackByProductCatalogueType; let i = index;">
                <li *ngIf="tab.inUse" [ngClass]="{'current': i == activeTabIndex}">
                    <a (click)="onTabChange(i,tab.idProductCatalogueType)" style="cursor: pointer;">
                        <span class="text">{{tab.productCatalogueTypeName}}</span>
                    </a>
                </li>
            </ng-container>
        </ul>
        <div class="tab" [ngClass]="{'readOnly' : readOnlyModal}">
            <app-tree-view [(state)]="state" [data]="productCatalogueDisplay" [options]="options" [searchMinlength]="3" [templateProps]="templateProps"></app-tree-view>
        </div>
    </div>
    <div footer class="right bottom-right" *ngIf="appHeaderService.enabledPCM">
        <ui-switch [(ngModel)]="hideDisabled" size="small" (change)="hideDisabledChanged()"></ui-switch>
        <span>&nbsp;&nbsp;{{
            userBundle["geoplanner.text.hideDisabled"] ||
              "Hide Disabled"
          }}</span>
      </div>

</app-sb-modal-wrapper>
