import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LogHelperService } from './../core/services/log-helper.service';
import { AdvanceAvailabilityComponent } from './../workspace/modal-windows/advance-availability/advance-availability.component';
import { WorkspaceService } from './../core/services/workspace.service';
import { DataShareService } from './../core/services/data-share.service';
import { AdvanceDashboardComponent } from './../workspace/modal-windows/advance-dashboard/advance-dashboard.component';
import { StateService } from './../core/services/state.service';
import { SbModalPopupService } from '../core/components/sb-modal-popup/sb-modal-popup.service';
import { InitialConfigModel } from '../models/initial-config.model';
import { GoogleAnalyticsEvents, GoogleAnalyticsEventCategory } from '../GoogleAnalytics/GoogleAnalyticsEvent';
import { SystemFlags } from '../models';
import { GLOBAL } from '../core/utils/app.constant';

@Injectable()
export class WorkspaceActionButtonService {

  /**
   * store initial Config Model
   *
   * @type {InitialConfigModel}
   * @memberof WorkspaceActionButtonService
   */
  public initialConfig: InitialConfigModel;

  constructor(
    private workspaceService: WorkspaceService,
    private sbModalPopupService: SbModalPopupService,
    private dataShareService: DataShareService,
    private stateService: StateService,
    private logHelperService: LogHelperService,
  ) {
    this.initialConfig = this.dataShareService.getInitialConfig();
  }
  /**
   * @description open Advance Availability modal popup
   * @author Kishan Patel
   * @memberof WorkspaceActionButtonService
   */
  openAdvanceAvailability() {
    const modalOptions: NgbModalOptions = {
      windowClass: 'modal-backdrop Advance-Availability',
      size: 'sm'
    };
    this.sbModalPopupService.open(AdvanceAvailabilityComponent, null, modalOptions).result.then((advanceAvailability) => {
      if (advanceAvailability != null) {
        const external = advanceAvailability.exportFormatExternal;
        const len = advanceAvailability.exportType === 1 ? 1 : external.length;
        for (let i = 0; i < len; i++) {
          const exportFormat = advanceAvailability.exportType === 1 ? Number(advanceAvailability.exportFormatInternal) :
            this.calculateExportFormatExternal(external[i]);
          // tslint:disable-next-line:max-line-length
          const src = `${this.dataShareService.getServiceCallUrlByKey('EXPORT_URL').toString()}?action=exportBricAvail&bricsCampaignId=${GLOBAL.BRIC_CAMPAIGN_ID.toString()}&Bos-SessionId=${GLOBAL.BOS_SESSIONID.toString()}&exportType=${advanceAvailability.exportType.toString()}&exportFormat=${exportFormat.toString()}`;
          this.workspaceService.downloadFiles(src).then(() => {
          }, (err) => {
            this.logHelperService.logError(err.error.message);
          });
        }
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  /**
   * @description select Export Format selected Value
   * @author Kishan Patel
   * @memberof WorkspaceActionButtonService
   */
  calculateExportFormatExternal(external) {
    let exportValue = null;
    exportValue = external.id;
    return exportValue;
  }

  /**
   * @description open check avaibility
   * @author Kishan Patel
   * @memberof WorkspaceActionButtonService
   */
  checkAvaibility() {
    const workspaceUIControl = this.stateService.getWorkspaceObject('workspaceUIControl');
    const availabilityMode = workspaceUIControl ? workspaceUIControl.availabilityEnabled : false;

    if (availabilityMode || SystemFlags.isLoadedCampaign) {
      const availabilityResultEnabled = this.initialConfig.uiControl.availabilityResultEnabled != null ?
        this.initialConfig.uiControl.availabilityResultEnabled : false;
      const modalOptions: NgbModalOptions = {
        windowClass: 'modal-backdrop Advance-Availability',
        size: 'lg'
      };
      if (!availabilityResultEnabled) {
        const resolvedObject = {
          resolve: !availabilityResultEnabled
        };
        this.sbModalPopupService.open(AdvanceDashboardComponent, resolvedObject, modalOptions).result.then(() => { }, (reason) => {
          console.log(reason);
        });

      } else {
        if (this.initialConfig.uiControl.availabilityResultEnabled) {
          const availabilityObj = {
            availabilityMode,
            availabilityResultEnabled: true
          };
          this.stateService.setWorkspaceObject('availabilityData', availabilityObj);
        }
        return true;
      }
    } else {
      this.logHelperService.logError(this.initialConfig.userBundle['workspace.advancedAvailability.accessError '] || 'Can not perform action at this moment!');
      return false;
    }
  }

  /**
   * @description download KML File
   * @author Kishan Patel
   * @memberof WorkspaceActionButtonService
   */
  downloadKMLFile() {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.FLOPPY,
      'Floppy Download', 'Floppy Download');
    // tslint:disable-next-line:max-line-length
    const src = `${this.dataShareService.getServiceCallUrlByKey('EXPORT_URL')}?action=exportBricDetail&bricsCampaignId=${GLOBAL.BRIC_CAMPAIGN_ID}&Bos-SessionId=${GLOBAL.BOS_SESSIONID}`;
    this.workspaceService.downloadFiles(src).then(() => {
      return true;
    }, (err) => {
      this.logHelperService.logError(err.error.message);
    });
  }

  /**
  * @description download Proximity Report File
  * @author Vijay
  * @memberof WorkspaceActionButtonService
  */
  downloadProximityReport() {
    const src = `${this.dataShareService.getServiceCallUrlByKey('EXPORT_URL')}?action=exportProximityReport&bricsCampaignId=${GLOBAL.BRIC_CAMPAIGN_ID}&Bos-SessionId=${GLOBAL.BOS_SESSIONID}`;
    this.workspaceService.downloadFiles(src).then(() => {
      return true;
    }, (err) => {
      this.logHelperService.logError(err.error.message);
    });

  }

}
