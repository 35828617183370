import { NumberMaskModel, Unmask } from '../../../../../models/imask';

export class AgNumberMaskParamModel {
  /**
   * Function to disable the input control
   */
  cellDisable: object;
  /**
   * Event fires when value has been changed
   */
  onTextChange: object;
  /**
   * imask config for Number mask
   */
  imask?: NumberMaskModel;
  /**
   * Defines the type of the masked value in ngModel
   */
  unmask?: Unmask;
}
