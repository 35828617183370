
import {
  DataShareService,
} from '../../core/services';
import { ProductCatalogue, ProductCatalogueHolder, UiControl, ProductCatalogueType } from '../../models';
import * as _ from 'lodash';

export class ProductCatalogueBase {
  public productCatalogue: ProductCatalogue[];
  public productCatalogueHolder: ProductCatalogueHolder;
  public productCatalogueType: ProductCatalogueType[];
  public uiControl: UiControl;
  public enableProductId: boolean;
  userBundle: any;

  constructor(
    public dataShareService: DataShareService,
  ) {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.productCatalogueHolder = this.dataShareService.getInitialConfigByKey('productCatalogueHolder');
    this.productCatalogue = this.productCatalogueHolder.productCatalogue;
    this.productCatalogueType = this.productCatalogueHolder.productCatalogueType.filter(e => e.inUse) || [];
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.enableProductId = this.uiControl.enableProductId;
  }

  /**
   *
   *
   * @param {*} productTypeId
   * @returns
   * @memberof ProductCatalogueBase
   */
  filterProductCatalogueByProductType(productTypeId) {
    return this.productCatalogue.filter((product) => {
      return product.productCatalogueTypeId === productTypeId;
    });
  }
  /**
   * Groups Products based on Product Group
   *
   * @param {*} productCatalogue
   * @returns
   * @memberof ProductCatalogueBase
   */
  groupProductCatalogueByProductGroup(productCatalogue) {
    return _.mapValues(_.groupBy(productCatalogue, 'productCatalogueGroupId'),
      clist => clist.map(x => _.omit(x, 'productCatalogueGroupId')));
  }
  /**
   * Create the Structured JSON Object of Product
   *
   * @param {*} groupedProductCatalogue
   * @returns
   * @memberof ProductCatalogueBase
   */
  createStructuredProductCataglogueAfterGrouping(groupedProductCatalogue) {
    const productCatalogue = [];
    for (const groupId in groupedProductCatalogue) {
      if (groupedProductCatalogue.hasOwnProperty(groupId)) {
        const productGroup = {
          idProductCatalogue: '',
          productCatalogueName: '',
          products: [],
          isGroup: true

        };
        const productGroupDetails = this.getProductGroupDetails(parseInt(groupId, 10));
        // Used ID+GroupName in order to differentiate between the id's, if GroupId and ProductID are same //
        if (productGroupDetails) {
          productGroup.idProductCatalogue = productGroupDetails.idProductCatalogueGroup + productGroupDetails.productCatalogueGroupName;
          productGroup.productCatalogueName = productGroupDetails.productCatalogueGroupName;
          productGroup.products = groupedProductCatalogue[groupId];
          productCatalogue.push(productGroup);
        }
      }
    }
    return productCatalogue;
  }
  /**
   * Gets the Product Group Details
   *
   * @param {*} id
   * @returns
   * @memberof ProductCatalogueBase
   */
  getProductGroupDetails(id) {
    let productGroup = null;
    const productCatalogueGroup = this.productCatalogueHolder.productCatalogueGroup.filter(e => e.inUse) || [];
    productCatalogueGroup.forEach((element) => {
      if (element.idProductCatalogueGroup === id) {
        productGroup = element;
      }
    });
    return productGroup;
  }

}
