
import {
  Component, EventEmitter, Input, Output, ViewChild,
  ViewChildren, QueryList, ElementRef, OnInit, OnDestroy, HostListener, ChangeDetectorRef
} from '@angular/core';
import { NgbDropdown, NgbInputDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FilterDataService } from './../../core/services/filter-data.service';
import { FilterBarService } from '../filter-bar/filter-bar.service';
import { SharedService } from './../../core/services/shared.service';
import { DataShareService } from './../../core/services/data-share.service';
import { FilterSupportService } from './../../core/services/filter-support.service';
import { LogHelperService } from './../../core/services/log-helper.service';

import { LocationFilterComponent } from '../../bricks/location/location-filter/location-filter.component';
import { AudienceFilterComponent } from './../audience-filter/audience-filter.component';
import { FormatFilterComponent } from '../../bricks/format/format-filter/format-filter.component';
import { ListFilterComponent } from '../../bricks/list/list-filter/list-filter.component';
import { TagFilterComponent } from '../../bricks/tag/tag-filter/tag-filter.component';
import { ProximityFilterComponent } from './../../bricks/proximity/proximity-filter/proximity-filter.component';
import { SotFilterComponent } from '../../bricks/sot/sot-filter/sot-filter.component';
import { FacingFilterComponent } from './../facing-filter/facing-filter.component';
import { FrameFilterComponent } from './../frame-filter/frame-filter.component';
import { BrickBaseService } from './../../core/services/brick-base.service';
import { CartService } from '../../geo-map/cart.service';

import 'rxjs/add/operator/map';
import { Subscription } from 'rxjs';
import {
  InitialConfigModel, MediaTypeModel, FrameModel,
  FilterBarDataModel, FilterDataModel, MediaBricSelectedDataModel, SelectionItemModel, InchargeBricSelectedDataModel,
  SystemFlags
} from '../../models';

import * as _ from 'lodash';
import { NgbDatepickerHelper } from '../../core/components/ngb-datepicker/ngb-datepicker-helper';
import { AppNameEnum } from '../../core/enum';
import { GLOBAL } from '../../core/utils/app.constant';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { StateService } from '../../core/services';
import { GeoMapService } from '../../geo-map/geo-map.service';
import { RequestJsonService } from '../../geo-map/request-json.service';

@Component({
  selector: 'app-filter-area',
  templateUrl: './filter-area.component.html',
  styleUrls: ['./filter-area.component.css']
})
export class FilterAreaComponent implements OnInit, OnDestroy {
  /**
   * @description returns true if need to allow past days in visual planner
   * @memberof FilterAreaComponent
   */
  showPastDaysVisible = false;

  showPastDates = false;

  /**
   * Initial configuration file object *
   * @type {any}
   * @memberof FilterAreaComponent
   */
  @Input() initialConfig: InitialConfigModel;

  @Input() readOnlyForSwap = false;

  /**
   * output event to load filter detail component based on parameter
   * @memberof FilterAreaComponent
   */
  @Output() loadBrickDetail = new EventEmitter<any>();

  /**
   * output event to notify filter area is hidden
   * @memberof FilterAreaComponent
   */
  @Output() hideFilterArea = new EventEmitter();

  /**
   * channel ngbDropdown element. needed this to close dropdown manually i.e. when clicking out side of element
   * @type {NgbDropdown}
   * @memberof FilterAreaComponent
   */
  @ViewChildren(NgbDropdown) channelDropdown: QueryList<NgbDropdown>;

  /**
   * Channel Container element. needed to check wheter click operation happend inside this element or not for manual dropdown close
   * @type {ElementRef}
   * @memberof FilterAreaComponent
   */
  @ViewChild('channelContainer') channelContainer: ElementRef;

  /**
   * Text to display as placeholder for channel filter
   * @type {string}
   * @memberof FilterAreaComponent
   */
  channelPlaceholder = 'Select Channel';

  /**
   * List of channel
   * @type {Array<any>}
   * @memberof FilterAreaComponent
   */
  channels: any[];

  cartData: FrameModel[];

  /**
   * Selected media type.
   * Default is -2 which means no media is selected
   * @type {number}
   * @memberof FilterAreaComponent
   */
  selectedMedia = -2;

  /**
   * List of all media types
   * @type {Array<MediaTypeModel>}
   * @memberof FilterAreaComponent
   */
  medias: MediaTypeModel[];

  /**
   * Selected end date value
   * @type {(NgbDateStruct)}
   * @memberof FilterAreaComponent
   */
  endDate: NgbDateStruct;

  /**
   * Selected start date value
   * @type {(NgbDateStruct)}
   * @memberof FilterAreaComponent
   */
  startDate: NgbDateStruct;

  /**
   * Subscriber object for filter data change
   * @type {Subscription}
   * @memberof FilterAreaComponent
   */
  filterDataSubscriber: Subscription;

  /**
   * Subscriber object for Filter bar data change
   * @type {Subscription}
   * @memberof FilterAreaComponent
   */
  filterBarDataSubscriber: Subscription;

  /**
   * Subscriber object for incharge selected data change
   * @type {Subscription}
   * @memberof FilterAreaComponent
   */
  openFilterDetailSubscriber: Subscription;

  /**
   * object to store various configurable display text for filters
   * @type {*}
   * @memberof FilterAreaComponent
   */
  brickTitle: { [key: string]: string | number } = {};

  /**
   * object to store various configurable display text for string
   * @type {*}
   * @memberof FilterAreaComponent
   */
  configText: { [key: string]: string } = {};

  /**
   * filter data for specific bric is selected or not
   */
  hasFilterData: { [key: string]: boolean } = {
    Location: false,
    Proximity: false,
    Tag: false,
    Format: false,
    SOT: false,
    Audience: false,
    List: false,
    Facing: false,
    FrameIllumination: false
  };

  minStartDate: NgbDateStruct;

  minEndDate: NgbDateStruct;

  /**
   * @description specific startDate day properties cached object for ngbDatePicker day template
   * @memberof FilterAreaComponent
   */
  startDayObjCache = {};

  /**
   * @description specific endDate day properties cached object for ngbDatePicker day template
   * @memberof FilterAreaComponent
   */
  endDayObjCache = {};

  /**
   * Whether optional filter area is visible or not
   */
  isOptionalFilterHidden = false;

  /**
   * Whether Template filter area is visible or not
   */
  isTemplateFilterHidden = false;

  systemFlags = SystemFlags;
  subscriptions: Subscription[] = [];
  showExecuteButton = false;
  requestProcess = {
    isValidRequestJsonVp: false,
    isProcessedVp: false,
    isValidRequestJsonGm: false,
    isProcessedGm: false
  };

  constructor(
    private filterDataService: FilterDataService,
    private sharedService: SharedService,
    private filterBarService: FilterBarService,
    public brickBaseService: BrickBaseService,
    private dataShareService: DataShareService,
    private filterSupportService: FilterSupportService,
    private logHelperService: LogHelperService,
    private cartService: CartService,
    private changeDetectorRef: ChangeDetectorRef,
    private appHeaderService: AppHeaderService,
    private stateService: StateService,
    private geoMapService: GeoMapService,
    private requestJsonService: RequestJsonService
  ) {
    this.subscriptions.push(this.dataShareService.languageChangedSub.subscribe((result) => {
      if (result) {
        this.ngOnInit();
        this.changeDetectorRef.detectChanges();
      }
    }));
  }

  get isVisualPlanner() {
    return this.dataShareService.appName === AppNameEnum.visualplanner;
  }

  get isGeoMapper() {
    return this.dataShareService.appName === AppNameEnum.geomapper;
  }

  /**
   * Angular lifecycle hook - will be Called once after component Initialize
   * @memberof FilterAreaComponent
   */
  ngOnInit() {
    this.setBrickTitles();
    this.minStartDate = NgbDatepickerHelper.convertDateToDateStruct(new Date());
    const channelSelectionData = this.sharedService.getLookupColumnData(this.brickBaseService.brickID.Environment, null);
    const channelLookupData = this.sharedService.getLookupData(channelSelectionData.lookup[0].selectionId, null);
    // SM-7374 : show only linked channel in dropdown
    this.channels = this.getLinkedChannels(channelLookupData);
    this.medias = _.clone(this.initialConfig.mediaType);
    // added default media type - for place holder
    this.medias.splice(0, 0, {
      mediaTypeId: -2,
      mediaTypeName: `${this.configText.select}   ${this.brickTitle.media}`
    });
    this.filterDataSubscription();
    this.openFilterDetailSubscription();
    this.filterBarDataSubscription();
    if (this.initialConfig.uiControl.executeButtonGmVp) {
      this.showExecuteButton = true;
      this.geoMapService.processRequest$.subscribe((processRequest: any) => {
        this.requestProcess = processRequest;
      });
    }

    this.cartService.cartData$.subscribe((data: FrameModel[]) => {
      this.cartData = data;
    });

    if (this.initialConfig.uiControl.visualPlannerPastWeeks && this.dataShareService.appName === AppNameEnum.visualplanner) {
      this.showPastDaysVisible = true;
      this.showPastDates = true;
      this.updatePastDatesValidations();
    }
  }

  /**
   * @description get Linked Channels
   * @author Kishan Patel
   * @memberof WorkspaceBase
   */
  getLinkedChannels(channelLookupData) {
    if (channelLookupData && channelLookupData[0] && channelLookupData[0].data) {
      const channels = [];
      const allowedChannels = this.getAllowedChannels();
      if (allowedChannels && allowedChannels.length > 0) {
        for (const allowedChannel of allowedChannels) {
          for (const data of channelLookupData[0].data) {
            if (data.id === allowedChannel) {
              channels.push(data);
            }
          }
        }
        for (const data of channelLookupData[0].data) {
          for (const channel of channels) {
            if (data.id === channel.id) {
              data.isVisible = true;
              break;
            } else {
              data.isVisible = false;
            }
          }
        }
        return channelLookupData[0].data;
      } else {
        return channelLookupData[0].data;
      }
    } else {
      return [];
    }
  }

  /**
   * Angular lifecycle hook - will be Called once  just before Angular destroys the directive/component
   * @memberof FilterAreaComponent
   */
  ngOnDestroy() {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.filterDataSubscriber.unsubscribe();
    this.openFilterDetailSubscriber.unsubscribe();
    this.filterBarDataSubscriber.unsubscribe();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /**
   * open start/end date datepicker on click
   * @param $event click event object
   * @param datePicker start/end date picker object
   */
  openDatePicker($event: Event, datePicker: NgbInputDatepicker, otherDatePicker: NgbInputDatepicker): void {
    otherDatePicker.close();
    $event.preventDefault();
    $event.stopPropagation();
    datePicker.toggle();
  }

  /**
   * close date picker on out-side click
   * @param event click event object
   * @param datePicker start/end date picker object
   */
  closeDatePickerOutsideClick(event: any, datePicker: NgbInputDatepicker): void {
    if (datePicker.isOpen()) {
      if (event.target.offsetParent == null && event.target.nodeName !== 'OPTION') {
        datePicker.close();
      } else if (event.target.offsetParent.nodeName !== 'NGB-DATEPICKER') {
        datePicker.close();
      }
    }
  }

  /**
   * @description prepare a startDate day object of ngb-datepicker
   * @author Alkesh Shah
   * @param {NgbDateStruct} date - current date
   * @returns
   * @memberof FilterAreaComponent
   */
  prepareStartDayObj(date: NgbDateStruct) {
    const obj = {
      isToday: false,
      isDisabled: false,
      isWeekday: false
    };

    const selectedDay: any = NgbDatepickerHelper.convertDateStructToDate(date);
    let minDate = (new Date().setHours(0, 0, 0, 0));
    if (this.showPastDates) {
      minDate = this.getMondayOfWeek(this.initialConfig.uiControl.visualPlannerPastWeeks * 7).setHours(0, 0, 0, 0);
    }
    if (selectedDay < minDate) {
      obj.isDisabled = true;
    } else {
      let weekNo = selectedDay.getWeek() + 1;
      if (this.initialConfig.uiControl.inchargeContinuousFromLastYear && selectedDay.getFullYear() > 2020) {
        // SM-5949, Should be applied from next year, shold not applied till 2020
        // If last week start of last year was highlighted then first week of current year should not be highlighted
        weekNo = selectedDay.getWeekFromDate(GLOBAL.continuousFromDate) + 1;
      }

      const condition = ((weekNo % this.initialConfig.uiControl.defaultInChargeLength === 0)
        && (selectedDay.getDay() === this.initialConfig.uiControl.defaultInChargeDay));
      obj.isWeekday = condition;
    }
    return obj;
  }

  /**
   * @description cache specific days object if is not exists
   * @author Alkesh Shah
   * @param {NgbDateStruct} date - current date object
   * @returns {string}
   * @memberof FilterAreaComponent
   */
  cacheStartDateObj(date: NgbDateStruct): string {
    const daystring = NgbDatepickerHelper.getDateString(date);
    if (!this.startDayObjCache[daystring]) {
      this.startDayObjCache[daystring] = this.prepareStartDayObj(date);
    }
    return daystring;
  }

  /**
   * @description get the specific dates property
   * @author Alkesh Shah
   * @param {NgbDateStruct} date current day
   * @param {string} key property key to retrive
   * @returns {boolean}
   * @memberof FilterAreaComponent
   */
  getStartDayProp(date: NgbDateStruct, key: string): boolean {
    const daystring = this.cacheStartDateObj(date);
    return this.startDayObjCache[daystring][key];
  }

  /**
   * start date select event handler
   * @param inst - selected date instance
   */
  onSelectFrom(inst: NgbDateStruct): void {
    this.startDate = inst;
    const monday = NgbDatepickerHelper.convertDateStructToDate(inst);
    this.minEndDate = inst;
    this.endDayObjCache = {};
    if (monday.getDay() === this.initialConfig.uiControl.defaultInChargeDay) {
      const endDate = new Date(inst.year, inst.month - 1,
        (inst.day + ((7 * this.initialConfig.uiControl.defaultInChargeLength) - 1)));
      this.endDate = NgbDatepickerHelper.convertDateToDateStruct(endDate);
    } else {
      this.endDate = null;
    }

    this.setInchargeBrickData();
  }

  /**
   * @description prepare a startDate day object of ngb-datepicker
   * @author Alkesh Shah
   * @param {NgbDateStruct} date - current date
   * @returns
   * @memberof FilterAreaComponent
   */
  prepareEndDayObj(date: NgbDateStruct, disabled: boolean) {
    const obj = {
      isToday: false,
      isDisabled: disabled,
      isWeekday: false
    };

    if (!obj.isDisabled) {
      const today = new Date();
      obj.isToday = NgbDatepickerHelper.isEqual(today, date);

      const selecteddaye = NgbDatepickerHelper.convertDateStructToDate(date);

      let weekNo = selecteddaye.getWeek();
      if (this.initialConfig.uiControl.inchargeContinuousFromLastYear && selecteddaye.getFullYear() > 2020) {
        weekNo = selecteddaye.getWeekFromDate(GLOBAL.continuousFromDate);
      }

      const condition = ((weekNo % this.initialConfig.uiControl.defaultInChargeLength === 0)
        && (selecteddaye.getDay() === (this.initialConfig.uiControl.defaultInChargeDay - 1)));
      obj.isWeekday = condition;
    }

    return obj;
  }

  /**
   * @description cache specific days object if is not exists
   * @author Alkesh Shah
   * @param {NgbDateStruct} date - current date object
   * @returns {string}
   * @memberof FilterAreaComponent
   */
  cacheEndDateObj(date: NgbDateStruct, disabled: boolean): string {
    const daystring = NgbDatepickerHelper.getDateString(date);
    if (!this.endDayObjCache[daystring]) {
      this.endDayObjCache[daystring] = this.prepareEndDayObj(date, disabled);
    }
    return daystring;
  }

  /**
   * @description get the specific dates property
   * @author Alkesh Shah
   * @param {NgbDateStruct} date current day
   * @param {string} key property key to retrive
   * @returns {boolean}
   * @memberof FilterAreaComponent
   */
  getEndDayProp(date: NgbDateStruct, disabled: boolean, key: string): boolean {
    const daystring = this.cacheEndDateObj(date, disabled);
    return this.endDayObjCache[daystring][key];
  }

  /**
   * end date select event handler
   */
  onSelectTo(inst: NgbDateStruct): void {
    this.endDate = inst;
    this.setInchargeBrickData();
  }

  /**
   * Set Incharge brick data in filter data service
   * @memberof FilterAreaComponent
   */
  setInchargeBrickData(): void {
    if (this.startDate && this.endDate) {
      this.filterDataService.setIncharge({
        startDate: NgbDatepickerHelper.convertDateStructToDate(this.startDate),
        endDate: NgbDatepickerHelper.convertDateStructToDate(this.endDate)
      });
    } else {
      this.filterDataService.setIncharge({}); // commenting it as it was not allowing to select end date
    }
  }

  /**
   * Hide filter area component
   * @memberof FilterAreaComponent
   */
  onHideFilterArea(): void {
    this.hideFilterArea.emit();
  }

  /**
   * Trigger on document click
   * this is needed for channel ngbDropdown component to manually close on clicking out side of component
   * @param {MouseEvent} $event
   * @memberof FilterAreaComponent
   */
  @HostListener('document:click', ['$event'])
  onDocumentClick($event: any): void {
    if (this.channelDropdown && this.channelDropdown.first) {
      const dropdownEle: any = this.channelDropdown.first;
      if (dropdownEle.isOpen() && $event.button !== 2 && !dropdownEle._isEventFromToggle($event)
        && !!this.channelContainer.nativeElement && !this.channelContainer.nativeElement.contains($event.target)) {
        dropdownEle.close();
      }
    }
  }

  /**
   * set the configurable brick and othe text propeties
   * @memberof FilterAreaComponent
   */
  setBrickTitles(): void {

    this.brickTitle = {
      // channel: this.initialConfig.userBundle['workspace.collection.bric.channel.subHeading'],
      // media: this.initialConfig.userBundle['workspace.collection.bric.media.subHeading'],
      startDate: this.initialConfig.userBundle['workspace.incharge.bric.range.startDate'],
      endDate: this.initialConfig.userBundle['workspace.incharge.bric.range.endDate'],
      // location: this.initialConfig.userBundle['workspace.collection.bric.location.subHeading'],
      // proximity: this.initialConfig.userBundle['workspace.collection.bric.proximity.subHeading'],
      // tags: this.initialConfig.userBundle['workspace.collection.tags.heading'],
      // formats: this.initialConfig.userBundle['workspace.collection.bric.format.subHeading'],
      // sot: this.initialConfig.userBundle['geoplanner.addons.filter.sot.heading'] || 'SOT',
      // audience: audienceCategoryGroup ? audienceCategoryGroup.audienceCategoryGroupName.toUpperCase() : "",
      // list: this.initialConfig.userBundle['workspace.collection.bric.list.subHeading'],
      frame: this.initialConfig.userBundle['workspace.collection.list.title.routeID'],
      // illumination: this.initialConfig.userBundle['workspace.collection.bric.frame.illumination'],
      // facing: this.initialConfig.userBundle['geoplanner.addons.filter.facing.heading'] || 'FACING',
      channel: this.brickBaseService.brickText.Environment,
      media: this.brickBaseService.brickText.Media,
      location: this.brickBaseService.brickText.Location,
      proximity: this.brickBaseService.brickText.Proximity,
      tags: this.brickBaseService.brickText.Tag,
      formats: this.brickBaseService.brickText.Format,
      sot: this.brickBaseService.brickText.SOT,
      audience: this.brickBaseService.brickText.Audience,
      list: this.brickBaseService.brickText.List,
      facing: this.brickBaseService.brickText.Facing,
      frameFilter: this.initialConfig.userBundle['workspace.collection.bric.frame.illumination'],
    };

    this.configText = {
      select: this.initialConfig.userBundle['geoplanner.text.select'] || 'Select',
      addons: this.initialConfig.userBundle['geoplanner.text.addons'] || 'Options',
      templates: this.initialConfig.userBundle['geoplanner.text.templates'] || 'Templates',
      package: this.initialConfig.userBundle['geoplanner.text.package'] || 'Package',
      all: this.initialConfig.userBundle['geoplanner.text.all'] || 'All',
      available: this.initialConfig.userBundle['geoplanner.text.available'] || 'Available',
      selected: this.initialConfig.userBundle['geoplanner.text.selected'] || 'Selected',
      filter: this.initialConfig.userBundle['geoplanner.text.filter'] || 'Filter',
      multipleselected: this.initialConfig.userBundle['geoplanner.text.multipleselected'] || 'Multiple Selected'
    };

    this.channelPlaceholder = `${this.configText.select}  ${this.brickTitle.channel}`;
  }

  /**
   * Subscriber method to be called on filter text click from filter box
   * To Open filter detail compoent based on bric id supplied
   * @memberof FilterAreaComponent
   */
  openFilterDetailSubscription(): void {
    this.openFilterDetailSubscriber = this.filterBarService.openFilterDetail$.subscribe((bricID) => {
      switch (bricID) {
        case this.brickBaseService.brickID.Tag:
          this.openTagFilter();
          break;
        case this.brickBaseService.brickID.Format:
          this.openFormatFilter();
          break;
        case this.brickBaseService.brickID.Location:
          this.openLocationFilter();
          break;
        case this.brickBaseService.brickID.Audience:
          this.openAudienceFilter();
          break;
        case this.brickBaseService.brickID.SOT:
          this.openSOTFilter();
          break;
        case this.brickBaseService.brickID.Proximity:
          this.openPOIFilter();
          break;
        case this.brickBaseService.brickID.List:
          this.openListFilter();
          break;
        case this.brickBaseService.brickID.FrameIllumination:
          this.openFrameFilter();
          break;
        case this.brickBaseService.brickID.Facing:
          this.openFacingFilter();
          break;
      }
    });
  }

  /**
   * Subscriber method to be called on filter text click from filter box
   * To Open filter detail compoent based on bric id supplied
   * @memberof FilterAreaComponent
   */
  filterBarDataSubscription(): void {
    this.filterBarDataSubscriber = this.filterBarService.filterBar$.subscribe((data: FilterBarDataModel) => {
      this.hasFilterData.Audience = data[this.brickBaseService.brickID.Audience];
      this.hasFilterData.Format = data[this.brickBaseService.brickID.Format];
      this.hasFilterData.Location = data[this.brickBaseService.brickID.Location];
      this.hasFilterData.Proximity = data[this.brickBaseService.brickID.Proximity];
      this.hasFilterData.SOT = data[this.brickBaseService.brickID.SOT];
      this.hasFilterData.Tag = data[this.brickBaseService.brickID.Tag];
      this.hasFilterData.Facing = data[this.brickBaseService.brickID.Facing];
      this.hasFilterData.FrameIllumination = data[this.brickBaseService.brickID.FrameIllumination];
    });
  }

  /**
   * Subscription method for filter data change
   * @memberof FilterAreaComponent
   */
  filterDataSubscription(): void {
    this.filterDataSubscriber = this.filterDataService.filterData$
      .subscribe((data: FilterDataModel) => {
        this.filter_mediaChange(data[this.brickBaseService.brickID.Media]);
        this.filter_channelChange(data[this.brickBaseService.brickID.Environment]);
        this.filter_inchargeChange(data[this.brickBaseService.brickID.Incharge]);
        this.filter_routeFrameIdsChange(data[this.brickBaseService.brickID.List]);

      });
  }

  displayMandatoryIcon(brick: string | number): boolean {
    return this.initialConfig.uiControl.processingBrics.indexOf(Number(brick)) > -1;
  }

  /**
   * @description
   * @author Method used to enable only Authorized Channel for the User
   * @date 2019-11-26
   * @memberof FilterAreaComponent
   */
  setAuthorizedChannels() {
    if (this.initialConfig.userData && this.initialConfig.userData.authorizedChannel && this.initialConfig.userData.authorizedChannel.length > 0) {
      this.channels.forEach((channel) => {
        if (
          this.initialConfig.userData.authorizedChannel.includes(channel.id)) {
          channel.disabled = false;
        } else {
          channel.disabled = true;
        }
      });
    } else {
      this.channels.forEach(channel => channel.disabled = false);
    }
  }

  /**
   *
   * @param {any} data
   * @memberof FilterAreaComponent
   */
  filter_routeFrameIdsChange(data): void {
    // if (_.isEmpty(data)) {
    //   this.routeFrameId = [];
    // }
    // this.routeFrameId = data.routeFrameId;
    // whether to show List highlighted or not
    this.hasFilterData.List = !_.isEmpty(data) && !_.isEmpty(data.listUpload);
  }

  /**
   * Media Data change from Filter data subscription
   * @memberof FilterAreaComponent
   */
  filter_mediaChange(data: MediaBricSelectedDataModel): void {
    if (data && data.mediaTypeName) {
      this.selectedMedia = data.mediaTypeId;
    } else {
      this.selectedMedia = -2;
    }
  }

  /**
   * channel Data change from Filter data subscription
   * @memberof FilterAreaComponent
   */
  filter_channelChange(data: SelectionItemModel[]): void {
    if (data && data.length > 0) {
      for (const d of data) {
        for (const c of this.channels) {
          if (c.id === d.id) {
            c.selected = true;
            break;
          }
        }
      }
      if (data.length === 1) {
        this.channelPlaceholder = data[0].name;
      } else {
        this.channelPlaceholder = this.configText.multipleselected;
      }
    } else {
      this.channelPlaceholder = `${this.configText.select} ${this.brickTitle.channel}`;
      this.setAuthorizedChannels();
      this.channels.forEach(channel => channel.selected = false);
    }

  }

  /**
   * incharge Data change from Filter data subscription
   * @memberof FilterAreaComponent
   */
  filter_inchargeChange(data: InchargeBricSelectedDataModel): void {
    if (this.startDate && this.endDate && data && !data.startDate && !data.endDate) {
      this.startDate = null;
      this.endDate = null;
    }
    if (data && data.startDate) {
      this.startDate = NgbDatepickerHelper.convertDateToDateStruct(new Date(data.startDate as string));
    }

    if (data && data.endDate) {
      this.endDate = NgbDatepickerHelper.convertDateToDateStruct(new Date(data.endDate as string));
    }
  }

  filterByAuthorizedChannels(allowedChannels: any[]): any[] {
    if (this.initialConfig.userData.authorizedChannel &&
      this.initialConfig.userData.authorizedChannel.length > 0) {
      allowedChannels = allowedChannels.filter(item => this.initialConfig.userData.authorizedChannel.includes(item));
    }
    return allowedChannels;
  }

  filterByLinkedChannels(selectedChannels: any[], allowedChannels: any[]): any[] {
    const linkedChannels: number[][] = this.initialConfig.linkedChannels;
    if (selectedChannels.length && linkedChannels.length) {
      const linkedChannelGroup = linkedChannels.find(channelGroup => channelGroup.indexOf(selectedChannels[0].id) > -1);
      if (linkedChannelGroup && linkedChannelGroup.length) {
        allowedChannels = allowedChannels.filter(item => linkedChannelGroup.includes(item));
      }
    } else if (this.cartData.length) {
        const linkedChannelGroup = linkedChannels.find(channelGroup => channelGroup.indexOf(this.cartData[0].channelId) > -1);
        if (linkedChannelGroup && linkedChannelGroup.length) {
          allowedChannels = allowedChannels.filter(item => linkedChannelGroup.includes(item));
        }
    } else {
        allowedChannels = this.getAllowedChannels();
    }
    return allowedChannels;
  }

  /**
   * Calls before channel dropdown gets open
   * @param isOpen - whether dropdown is open or not
   */
  openChangeChannel(isOpen: boolean): void {
    if (isOpen) {
      const selectedChannels = this.channels.filter((val) => {
        return (val.selected === true);
      });
      if (selectedChannels.length > 0 || this.cartData.length > 0) {
        let allowedChannels = [];
        for (const channel of this.channels) {
          allowedChannels.push(channel.id);
        }
        // ---  Allow only authorized Channels --- //
        allowedChannels = this.filterByAuthorizedChannels(allowedChannels);
        // --- End : Allow only Authorized Channel ---//
          allowedChannels = this.filterByLinkedChannels(selectedChannels, allowedChannels);
        this.channels.forEach((channel) => {
          if (allowedChannels.indexOf(channel.id) > -1) {
            channel.isVisible = true;
          } else {
            channel.isVisible = false;
          }
        });
      } else {
        this.channels.forEach(channel => channel.isVisible = true);
      }
    }
  }

  /**
   * trigger when selected channel change
   * @memberof FilterAreaComponent
   */
  onChannelChange(item): void {
    const selectedChannels = this.channels.filter((val) => {
      return (val.selected === true);
    });
    this.filterDataService.setChannel(selectedChannels);
    const tempLinkedChannels = this.initialConfig.linkedChannels;
    for (const data of tempLinkedChannels) {
      data.forEach(d => {
        this.channels.forEach(obj => {
          if (_.includes(data, item.id) && item.selected && !_.includes(data, obj.id)) {
            obj.disabled = true;
          } else if (_.includes(data, item.id) && !item.selected && !_.includes(data, obj.id) && selectedChannels.length === 0) {
            if (this.initialConfig.userData.authorizedChannel &&
              this.initialConfig.userData.authorizedChannel.length > 0 &&
              !this.initialConfig.userData.authorizedChannel.includes(obj.id)
            ) {
              obj.disabled = true;
            } else { obj.disabled = false; }
          }
        });
      });
    }
  }

  /**
   * trigger when selected media type change
   * @memberof FilterAreaComponent
   */
  onMediaChange(): void {
    if (Number(this.selectedMedia) !== -2) {
      const selectedMedia = this.medias.filter((val) => {
        return val.mediaTypeId === Number(this.selectedMedia);
      });
      this.filterDataService.setMedia(selectedMedia[0]);
    } else {
      this.filterDataService.setMedia({});
    }
  }

  toggleFilterSection(section: string): void {
    switch (section) {
      case 'OPTIONAL':
        this.isOptionalFilterHidden = !this.isOptionalFilterHidden;
        break;
      case 'TEMPLATE':
        this.isTemplateFilterHidden = !this.isTemplateFilterHidden;
        break;
      default:
        break;
    }
  }

  getColumnConfig() {
    let columnConfig = this.dataShareService.getColumnConfig();
    // Checking column config is available or not
    // if available then check for 0 column exist as in geomapper it will have only one column
    if (columnConfig && columnConfig[0]) {
      columnConfig = columnConfig[0];
    } else {
      columnConfig = null;
    }
    return columnConfig;
  }
  /**
   * Opens Location Filter detail
   * @memberof FilterAreaComponent
   */
  openLocationFilter(): void {
    const tempSelectedObj = this.filterDataService.getLocation();
    const columnConfig = this.getColumnConfig();
    const isAllowedObject = this.filterSupportService.checkForAllowedBrics(columnConfig, this.brickBaseService.brickID.Location);
    if (isAllowedObject.isAllowed) {
      const resolveObject = {
        brickBgColor: this.brickBaseService.brickColor.Location,
        SelectedValue: tempSelectedObj,
        ColumnConfig: columnConfig, // column config will be set from here
        readOnlyModal: false,
        locationStyle: true,
        title: this.brickTitle.location,
        isLargeSidebar: true
      };
      this.loadBrickDetail.emit({ resolveObject, component: LocationFilterComponent });
    } else {
      this.logHelperService.logError(isAllowedObject.logInfo);
    }
  }

  /**
   * Opens POI Filter detail
   * @memberof FilterAreaComponent
   */
  openPOIFilter(): void {
    const proximitySelectedValue = {};
    const columnConfig = this.getColumnConfig();
    const isAllowedObject = this.filterSupportService.checkForAllowedBrics(columnConfig, this.brickBaseService.brickID.Proximity);
    if (isAllowedObject.isAllowed) {
      const resolveObject = {
        brickBgColor: this.brickBaseService.brickColor.Proximity,
        SelectedValue: proximitySelectedValue,
        ColumnConfig: columnConfig,
        readOnlyModal: false,
        title: this.brickTitle.proximity,
        isLargeSidebar: true
      };
      this.loadBrickDetail.emit({ resolveObject, component: ProximityFilterComponent });
    } else {
      this.logHelperService.logError(isAllowedObject.logInfo);
    }
  }

  /**
   * Opens Tag Filter detail
   * @memberof FilterAreaComponent
   */
  openTagFilter(): void {
    const tagModalSelectedValues = {};
    const columnConfig = this.getColumnConfig();
    const isAllowedObject = this.filterSupportService.checkForAllowedBrics(columnConfig, this.brickBaseService.brickID.Tag);
    if (isAllowedObject.isAllowed) {
      const tagGroupData = this.sharedService.getTagDataFromColumnConfig(this.brickBaseService.brickID.Tag,
        columnConfig, _.cloneDeep(this.initialConfig.tagGroup));
      const resolveObject = {
        brickBgColor: this.brickBaseService.brickColor.Tag,
        SelectedValue: tagModalSelectedValues,
        ColumnConfig: tagGroupData,
        readOnlyModal: false,
        title: this.brickTitle.tags,
        isLargeSidebar: true
      };
      this.loadBrickDetail.emit({ resolveObject, component: TagFilterComponent });
    } else {
      this.logHelperService.logError(isAllowedObject.logInfo);
    }
  }

  /**
   * Opens Format Filter detail
   * @memberof FilterAreaComponent
   */
  openFormatFilter(): void {
    const tempSelectedObj = this.filterDataService.getFormat();
    const columnConfig = this.getColumnConfig();
    const isAllowedObject = this.filterSupportService.checkForAllowedBrics(columnConfig, this.brickBaseService.brickID.Format);
    if (isAllowedObject.isAllowed) {
      const resolveObject = {
        brickBgColor: this.brickBaseService.brickColor.Format,
        SelectedValue: tempSelectedObj,
        ColumnConfig: columnConfig, // column config will be set from here
        readOnlyModal: false,
        title: this.brickTitle.formats,
        isLargeSidebar: true
      };
      this.loadBrickDetail.emit({ resolveObject, component: FormatFilterComponent });
    } else {
      this.logHelperService.logError(isAllowedObject.logInfo);
    }
  }

  /**
   * Opens SOT Filter detail
   * @memberof FilterAreaComponent
   */
  openSOTFilter(): void {
    const sotModalSelectedValues = {};
    const columnConfig = this.getColumnConfig();
    const isAllowedObject = this.filterSupportService.checkForAllowedBrics(columnConfig, this.brickBaseService.brickID.SOT);
    if (isAllowedObject.isAllowed) {
      const resolveObject = {
        brickBgColor: this.brickBaseService.brickColor.SOT,
        SelectedValue: sotModalSelectedValues,
        ColumnConfig: {},
        readOnlyModal: false,
        title: this.brickTitle.sot,
        isLargeSidebar: true
      };
      this.loadBrickDetail.emit({ resolveObject, component: SotFilterComponent });
    } else {
      this.logHelperService.logError(isAllowedObject.logInfo);
    }
  }

  /**
   * Opens Audience Filter detail
   * @memberof FilterAreaComponent
   */
  openAudienceFilter(): void {
    const audienceModalSelectedValues = {};
    const columnConfig = this.getColumnConfig();
    const isAllowedObject = this.filterSupportService.checkForAllowedBrics(columnConfig, this.brickBaseService.brickID.Audience);
    if (isAllowedObject.isAllowed) {
      const resolveObject = {
        brickBgColor: this.brickBaseService.brickColor.Audience,
        SelectedValue: audienceModalSelectedValues,
        ColumnConfig: {},
        audienceCategoryGroup: this.initialConfig.audienceCategoryGroup,
        audienceTags: this.initialConfig.audienceTags,
        readOnlyModal: false,
        title: this.brickTitle.audience,
        isLargeSidebar: true
      };
      this.loadBrickDetail.emit({ resolveObject, component: AudienceFilterComponent });
    } else {
      this.logHelperService.logError(isAllowedObject.logInfo);
    }
  }

  /**
   * Opens Facing Filter detail
   * @memberof FilterAreaComponent
   */
  openFacingFilter(): void {
    const facingSelectedValues = {};
    const columnConfig = this.getColumnConfig();
    const isAllowedObject = this.filterSupportService.checkForAllowedBrics(columnConfig, this.brickBaseService.brickID.Facing);
    if (isAllowedObject.isAllowed) {
      const resolveObject = {
        brickBgColor: this.brickBaseService.brickColor.Facing,
        SelectedValue: facingSelectedValues,
        ColumnConfig: {},
        readOnlyModal: false,
        title: this.brickTitle.facing,
        isLargeSidebar: true
      };
      this.loadBrickDetail.emit({ resolveObject, component: FacingFilterComponent });
    } else {
      this.logHelperService.logError(isAllowedObject.logInfo);
    }
  }

  /**
   * Opens Frame Filter detail
   * @memberof FilterAreaComponent
   */
  openFrameFilter(): void {
    const frameModalSelectedValues = {};
    const columnConfig = this.getColumnConfig();
    const isAllowedObject = this.filterSupportService.checkForAllowedBrics(columnConfig, this.brickBaseService.brickID.SOT);
    if (isAllowedObject.isAllowed) {
      const resolveObject = {
        brickBgColor: this.brickBaseService.brickColor.FrameIllumination,
        SelectedValue: frameModalSelectedValues,
        ColumnConfig: {},
        readOnlyModal: false,
        title: this.brickTitle.frameFilter,
        isLargeSidebar: true
      };
      this.loadBrickDetail.emit({ resolveObject, component: FrameFilterComponent });
    } else {
      this.logHelperService.logError(isAllowedObject.logInfo);
    }
  }

  /**
   * Opens List Filter detail
   * @memberof FilterAreaComponent
   */
  openListFilter(): void {
    const columnConfig = this.getColumnConfig();
    const isAllowedObject = this.filterSupportService.checkForAllowedBrics(columnConfig, this.brickBaseService.brickID.List);
    if (isAllowedObject.isAllowed) {
      const resolveObject = {
        brickBgColor: this.brickBaseService.brickColor.List,
        SelectedValue: '',
        ColumnConfig: {},
        readOnlyModal: false,
        title: this.brickTitle.list,
        isLargeSidebar: true
      };
      this.loadBrickDetail.emit({ resolveObject, component: ListFilterComponent });
    } else {
      this.logHelperService.logError(isAllowedObject.logInfo);
    }
  }

  updatePastDatesValidations() {
    if (this.showPastDates) {
      this.minStartDate = NgbDatepickerHelper.convertDateToDateStruct(this.getMondayOfWeek(this.initialConfig.uiControl.visualPlannerPastWeeks * 7));
    } else {
      this.minStartDate = NgbDatepickerHelper.convertDateToDateStruct(new Date());
      if (!this.startDate || new Date(this.startDate.year, this.startDate.month, this.startDate.day).getTime() < new Date().getTime()) {
        if (this.startDate) {
          this.startDate = NgbDatepickerHelper.convertDateToDateStruct(new Date());
        }
      }
      this.minEndDate = NgbDatepickerHelper.convertDateToDateStruct(new Date());
      if (!this.endDate || new Date(this.endDate.year, this.endDate.month, this.endDate.day).getTime() < new Date().getTime()) {
        if (this.endDate) {
          this.endDate = NgbDatepickerHelper.convertDateToDateStruct(new Date());
        }
      }
      this.setInchargeBrickData();
    }
  }

  getMondayOfWeek(noOfDays) {
    const days = noOfDays; // Days you want to subtract
    const date = new Date();
    const last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
    const day = last.getDate();
    const month = last.getMonth() + 1;
    const year = last.getFullYear();
    const d = new Date(`${year}-${month}-${day}`);
    const dayF = d.getDay();
    const diff = d.getDate() - dayF + (dayF === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  onPastDaysVisibilityChange() {
    this.updatePastDatesValidations();
  }

  execute() {
    if (this.dataShareService.appName === AppNameEnum.visualplanner) {
      this.geoMapService.allowProcessVp(true);
    }
    if (this.dataShareService.appName === AppNameEnum.geomapper) {
      // SM-11283: Loading issue fixed occured due to SM-11142 : UI optimization
      //this.dataShareService.shouldLoaderStoppedForGeoMapper = false;
      this.geoMapService.allowProcessGm(true);
    }
    this.requestJsonService.requestJsonDataChange();
  }

  /**
   * @description Get allowed channels
   * @author Dhaval Patel
   * @returns Array allowedChannels
   */
  private getAllowedChannels() {
    let allowedChannels = [];
    const linkedChannels = this.dataShareService.getInitialConfigByKey('linkedChannels');
    const rows = this.appHeaderService.enabledPCM ?
      this.stateService.getPCMFilterObj().rows : this.stateService.getWorkspaceFilterObj().rows;
    rows.forEach((element) => {
      if (element.bric.bricid === this.brickBaseService.brickID.Environment) {
        element.cells.forEach((cell) => {
          if (!cell.isEmpty && !cell.isHidden && !_.isEmpty(cell.selected)) {
            const selKeys = Object.keys(cell.selected);
            if (selKeys.length > 0) {
              for (const selKey of selKeys) {
                if (cell.selected[selKey].length > 0) {
                  allowedChannels = _.find(linkedChannels, (item) => {
                    return _.includes(item, cell.selected[selKey][0].id);
                  });
                }
              }
            }
          }
        });
      }
    });
    return allowedChannels;
  }

  trackByMediaType(index, media) {
    return media?.mediaTypeId;
  }

  trackById(index, channel) {
    return channel?.id;
  }

}
