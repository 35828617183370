import { Component, OnInit } from '@angular/core';
import { StateService } from '../../../app/core/services/state.service';
import { CampaignDetailsService } from '../campaign-details/campaign-details.service';
import { CommercialConfig, CampaignDetails, FreeOFChargeResult, HistoryROC } from '../commercial.model';
import { SystemFlags, InitialConfigModel } from '../../models';
import { DataShareService, LogHelperService } from '../../core/services';
import { CommercialService } from '../../core/services/commercial.service';
import { CommercialButtonService } from '../commercial-buttons/commercial-buttons.service';
import { CampaignStatus, ThirdParty } from '../../core/enum';
import { Helper } from '../../search/campaign-details/helper';
import { DatePipe } from '@angular/common';
import { GLOBAL } from '../../core/utils/app.constant';

@Component({
  selector: 'app-commercial-ae',
  templateUrl: './commercial-ae.component.html'
})
export class CommercialAEComponent implements OnInit {
  systemFlags = SystemFlags;

  /**
   * @description store Commercial Config
   * @type {CommercialConfig}
   * @memberof CommercialAEComponent
   */
  commercialConfig: CommercialConfig = new CommercialConfig();
  /**
   * @description campaign detail store
   * @type {CampaignDetails}
   * @memberof CommercialAEComponent
   */
  campaignDetails: CampaignDetails = new CampaignDetails();
  private helper: Helper = new Helper();
  // @Output() redirectionHomePage = new EventEmitter<any>();

  /**
   * @description maintain discount grid show hide
   * @type {boolean}
   * @memberof CommercialAEComponent
   */
  hideDiscountGrid: boolean;
  /**
   * @description maintain campaign detail show hide
   * @type {boolean}
   * @memberof CommercialAEComponent
   */
  hideCampaignDetail: boolean;

  /**
   * @description store booking detail show hide
   * @type {boolean}
   * @memberof CommercialAEComponent
   */
  hideBookingDetail: boolean;

  /**
   * @description store commercial data
   * @memberof CommercialAEComponent
   */
  public commercialData;

  /**
   * @description
   * @type {InitialConfigModel}
   * @memberof CommercialAEComponent
   */
  initialConfig: InitialConfigModel;
  /**
   * @description set bookingStatusList
   * @memberof CommercialAEComponent
   */
  bookingStatusList = GLOBAL.bookingStatusList;
  bookingStatusId = null;

  /**
   * @description this will show the history of campaign updated in case of reason/cancel for change
   * @memberof CommercialAEComponent
   */
  historyROC = new HistoryROC();

  constructor(private stateService: StateService,
              private campaignDetailsService: CampaignDetailsService,
              private dataShareService: DataShareService,
              private commercialService: CommercialService,
              private commercialButtonService: CommercialButtonService,
              private logHelperService: LogHelperService,
              private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.setDefaultAgencySpecialistPerc();
    this.restoreCommercialData();
  }

  restoreCommercialData() {
    this.commercialConfig.user = this.dataShareService.getUserModel();
    // added it to scope to make is accessible to view
    this.getCommercialDataFromService();

  }

  getCommercialDataFromService() {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
    };

    this.commercialService.loadCommercialCampaignDetails(param).subscribe((data) => {
      if (data.status === 'OK') {
        this.commercialConfig.campaignStatus = data.data.campaignStatusId;
        this.commercialConfig.bookingStatus = data.data.bookingStatus;
        this.commercialConfig.uiControl = data.data.uiControl;
        // SM-4914 : mediaLengthRequired contains array not boolean
        // this.commercialConfig.uiControl['mediaLengthRequired'] = true;
        this.commercialData = data.data;

        this.commercialData.bookingStatusName =
          this.campaignDetailsService.getStatusNameFromId(data.data.campaignStatusId);
        const assignedToList = this.dataShareService.getInitialConfigByKey('assignedTo');
        this.campaignDetails = this.campaignDetailsService.populateCampaignDetails(this.commercialData,
          this.initialConfig, assignedToList);
        this.campaignDetailsService.calculateCPT(this.campaignDetails);
        this.historyROC = this.campaignDetailsService.populateHistoryROC(this.historyROC, data.data.auditReasonsHistoryMap, this.commercialConfig.uiControl.historyROC);
      }
    });

  }

  validateCommercialCampaign(status): boolean {
    const campaignDetails = this.campaignDetails;
    let message = '';
    if (campaignDetails.advertiser.advertiserId <= 0) {
      message = this.initialConfig.userBundle['commercial.validation.advertiser'];
    } else if (campaignDetails.brand.brandId <= 0) {
      message = this.initialConfig.userBundle['commercial.validation.brand'];
    } else if (campaignDetails.productCategoryId <= 0) {
      message = this.initialConfig.userBundle['commercial.validation.productCategory'];
    } else if (!campaignDetails.title) {
      message = this.initialConfig.userBundle['commercial.validation.title'];
    } else if (!campaignDetails.campaignStartDate) {
      message = this.initialConfig.userBundle['commercial.validation.campaignStartDate'];
    } else if (!campaignDetails.campaignEndDate) {
      message = this.initialConfig.userBundle['commercial.validation.campaignEndDate'];
    } else if (campaignDetails.salesTeamId <= 0) {
      message = this.initialConfig.userBundle['commercial.validation.salesTeam'];
    } else if (!campaignDetails.assignedTo && !this.dataShareService.hideUIElements('commercial.booking.assignedTo')) {
      message = this.initialConfig.userBundle['commercial.validation.assignTo'];
    }
    if (message === '') {
      message = this.validateBookingFields(campaignDetails, status);
    }

    if (message.length > 0) {
      this.logHelperService.logError(this.initialConfig.userBundle['commercial.error.book.validation']);
      return false;
    }

    if (!this.commercialButtonService.isProductsValidAgainstRestrictions(this.campaignDetails)) {
      return false;
    }

    return true;
  }

  /**
   * Validate Booking Fields
   * @param campaignDetails CampaignDetails
   * @param status Status
   */
  validateBookingFields(campaignDetails, status) {
    let message = '';
    if (campaignDetails.bookings) {
      campaignDetails.bookings.forEach((obj: any) => {
        if (!obj.adminPersonId) {
          message = this.initialConfig.userBundle['commercial.validation.adminPersonId'];
        } else if (!obj.salesPersonId) {
          message = this.initialConfig.userBundle['commercial.validation.salesPersonId'];
        } else if (Array.isArray(this.commercialConfig.uiControl.mediaLengthRequired) && message === '') {
          message = this.getMediaLengthMessage(status, obj);
        }
      });
    }
    return message;
  }

  /**
   * Get Media Length Message
   * @param status Status
   * @param obj booking detail
   */
  getMediaLengthMessage(status, obj) {
    const mediaLengthErrorMessage = this.getMediaLengthErrorMessage();
    const mediaLengthZeroErrorMessage = this.getMediaLengthZeroErrorMessage();
    let message = '';
    const statuses = this.commercialConfig.uiControl.mediaLengthRequired;
    if (statuses.indexOf(status) > -1) {
      this.bookingStatusId = status;
      if (!obj.mediaLength) {
        message = mediaLengthErrorMessage;
      } else if (Number(obj.mediaLength) === 0) {
        message = mediaLengthZeroErrorMessage;
      }
    }
    return message;
  }

  /**
   * Get Media Length Error Message
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  getMediaLengthErrorMessage() {
    return this.initialConfig.userBundle['commercial.error.book.mediaLength'] || 'Please Enter MediaLength';
  }

  /**
   * Get Media Length Zero Error Message
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  getMediaLengthZeroErrorMessage() {
    return this.initialConfig.userBundle['commercial.error.book.mediaLengthZero'] || 'zero second is not allowed';
  }

  createRequestJSON = (status) => {
    const campaignDetails = this.campaignDetails;
    return this.populateBookCommercialCampaignParam(status, campaignDetails);
  }

  populateBookCommercialCampaignParam(status, campaignDetails) {

    if (campaignDetails == null) {
      campaignDetails = this.stateService.getCommercialObject('campaignDetails');
    }

    const temp: any = this.populateConditionalCampaignParam(campaignDetails);

    if (campaignDetails.brand.brandId) {
      temp.brandId = Number(campaignDetails.brand.brandId);
    }

    if (campaignDetails.advertiser.advertiserId) {
      temp.advertiserId = Number(campaignDetails.advertiser.advertiserId);
    }

    if (campaignDetails.productCategoryId > 0) {
      temp.productCategoryId = campaignDetails.productCategoryId;
    }

    if (campaignDetails.campaignStartDate) {
      temp.campaignStartDate = this.datePipe.transform(campaignDetails.campaignStartDate, GLOBAL.DATE_PARSE_FORMAT);
    }
    if (campaignDetails.campaignEndDate) {
      temp.campaignEndDate = this.datePipe.transform(campaignDetails.campaignEndDate, GLOBAL.DATE_PARSE_FORMAT);
    }
    if (campaignDetails.campaignCreateDate) {
      temp.campaignCreateDate = this.datePipe.transform(new Date(campaignDetails.campaignCreateDate), GLOBAL.DATE_PARSE_FORMAT);
    }

    if (campaignDetails.salesTeamId) {
      temp.salesTeamId = Number(campaignDetails.salesTeamId);
    }

    if (status) {
      temp.campaignStatusId = status;
    } else {
      temp.campaignStatusId = campaignDetails.campaignStatusId;
    }

    const thirdParty = this.getThirdPartyFields(campaignDetails);
    if (thirdParty && this.helper.getPropertyList(thirdParty).length !== 0) {
      temp.thirdParty = thirdParty;
    }

    if (this.commercialConfig.uiControl.showOvershowToggle) {
      temp.overshow = campaignDetails.overshow;
    }

    const commonLogicFields = ['campaignReference', 'financeAmount', 'title', 'comments'];
    commonLogicFields.forEach(field => {
      if (campaignDetails[field]) {
        temp[field] = campaignDetails[field];
      }
    });
    temp.bookings = this.populateBookingDetailsServiceObject(campaignDetails);
    temp.discounts = campaignDetails.discounts;
    return temp;
  }

  populateConditionalCampaignParam(campaignDetails) {
    const temp: any = this.populateCalculatedFields(campaignDetails);

    if (campaignDetails.reasonForChange) {
      temp.auditReasonId = campaignDetails.reasonForChange.auditReasonId;

      if (campaignDetails.reasonForChange.specifyOther) {
        temp.auditReasonText = campaignDetails.reasonForChange.freeText;
      }
    }
    if (campaignDetails.freeOfCharge) {
      temp.auditReasonId = campaignDetails.freeOfCharge.auditReasonId;

      if (campaignDetails.freeOfCharge.specifyOther) {
        temp.auditReasonText = campaignDetails.freeOfCharge.comment;
      }
    }
    if (campaignDetails.smartBricsValue
      && !this.initialConfig.uiControl.hidePrice) { // && !this.InitialConfig.uiControl.hidePrice) {
      temp.smartBricsValue = campaignDetails.smartBricsValue;
    }

    if (campaignDetails.assignedTo
      && !this.commercialService.commercialHiddenElements('commercial.booking.assignedTo')) {
      temp.assignedToId = campaignDetails.assignedTo.assignedToId;
    }

    if (campaignDetails.externalReference && campaignDetails.externalReference !== '') {
      temp.externalReference = campaignDetails.externalReference;
    }
    return temp;
  }

  populateCalculatedFields(campaignDetails) {
    const temp: any = {};
    if (campaignDetails.grossMediaValue != null
      && campaignDetails.grossMediaValue !== ''
      && !isNaN(campaignDetails.grossMediaValue) && this.commercialConfig.uiControl.tradingEnabled) {
      if ((typeof campaignDetails.grossMediaValue === 'string'
        || campaignDetails.grossMediaValue instanceof String)) {
        campaignDetails.grossMediaValue = parseFloat(campaignDetails.grossMediaValue);
      }
      temp.grossMediaValue = campaignDetails.grossMediaValue; //
    } else {
      // VJ: dt 18.11.2015
      temp.grossMediaValue = 0;
    }
    if (campaignDetails.netMediaValue != null
      && campaignDetails.netMediaValue !== ''
      && !isNaN(campaignDetails.netMediaValue) && this.commercialConfig.uiControl.tradingEnabled) {
      if ((typeof campaignDetails.netMediaValue === 'string'
        || campaignDetails.netMediaValue instanceof String)) {
        campaignDetails.netMediaValue = parseFloat(campaignDetails.netMediaValue);
      }
      temp.netMediaValue = campaignDetails.netMediaValue;
    }
    return temp;
  }

  getThirdPartyFields(campaignDetails) {
    const thirdParty = {};
    if (campaignDetails.specialist.specialistId !== 0
      && campaignDetails.specialist.specialistId != null && campaignDetails.specialist.specialistId !== '') {
      // -> Specialist
      let commission = 0;
      if (campaignDetails.specialistPercentage !== 'NaN'
        && campaignDetails.specialistPercentage !== ''
        && campaignDetails.specialistPercentage != null) {
        commission = campaignDetails.specialistPercentage;
      }

      thirdParty[ThirdParty.specialist] = {
        commission,
        thirdPartyId: campaignDetails.specialist.specialistId
      };
    }

    if (campaignDetails.agency.agencyId !== 0 && campaignDetails.agency.agencyId != null && campaignDetails.agency.agencyId !== '') {

      thirdParty[ThirdParty.agency] = {
        thirdPartyId: campaignDetails.agency.agencyId
      };
    }

    if (campaignDetails.subAgency.subAgencyId) {
      thirdParty[ThirdParty.subAgency] = {
        thirdPartyId: campaignDetails.subAgency.subAgencyId,
      };
    }
    return thirdParty;
  }

  populateBookingDetailsServiceObject(campaignDetails: CampaignDetails) {
    const bookings = [];
    if (campaignDetails.bookings) {
      campaignDetails.bookings.forEach((object) => {

        const bookingDetailObject: any = this.populateConditionalBookingFields(object);

        if (object.adminPersonId) {
          bookingDetailObject.adminPersonId = Number(object.adminPersonId);
        }
        if (object.salesPersonId) {
          bookingDetailObject.salesPersonId = Number(object.salesPersonId);
        }

        // SM-1963 - End - Integration status

        // commented for SBRICS-1229, Nishit, dt: 28/04/2016
        // if (object.assignedTo && !hideUIElementsObject('commercial.booking.assignedTo'))
        // { bookingDetailObject.assignedToId = object.assignedTo.assignedToId; };

        // SBRICS-1596, Nishit
        if (object.mediaLength && !object.hideMediaControl) {
          bookingDetailObject.mediaLength = Number(object.mediaLength);
        }

        const commonLogicFields = ['invoiceTypeId', 'startDate', 'endDate', 'smartBricsValue', 'purchaseOrderNo', 'buyerContactId'];
        commonLogicFields.forEach(field => {
          if (campaignDetails[field]) {
            bookingDetailObject[field] = object[field];
          }
        });

        bookings.push(bookingDetailObject);
      });
    }
    return bookings;
  }

  /**
   * Populate Conditional Booking Fields
   * @param object Booking detail
   */
  populateConditionalBookingFields(object) {
    const bookingDetailObject: any = {};
    if (object.ctuPersonId && !this.commercialService.commercialHiddenElements('common.ctuPersonName')) {
      bookingDetailObject.ctuPersonId = Number(object.ctuPersonId);
    }
    if (object.barterId && !this.commercialService.commercialHiddenElements('common.barterName')) {
      bookingDetailObject.barterId = Number(object.barterId); // SBRICS-1351, Nishit
    }
    if (object.barterRate && !this.commercialService.commercialHiddenElements('common.barter.commission')) {
      bookingDetailObject.barterRate = object.barterRate;
    }
    if (object.preBarterValue && !this.commercialService.commercialHiddenElements('common.barter.preValue')) {
      bookingDetailObject.preBarterValue = Number(object.preBarterValue);
    }
    if (object.productionTypeId && object.productionTypeId !== -1) {
      bookingDetailObject.productionTypeId = Number(object.productionTypeId);
    }
    if (object.grossMediaValue) {
      object.grossMediaValue = Number(object.grossMediaValue);
      bookingDetailObject.grossMediaValue = object.grossMediaValue; //
    } else {
      bookingDetailObject.grossMediaValue = 0;
    }
    if (object.netMediaValue != null && object.netMediaValue !== 0 && !isNaN(object.netMediaValue)) {
      object.netMediaValue = Number(object.netMediaValue);
      bookingDetailObject.netMediaValue = object.netMediaValue;
    }
    return bookingDetailObject;
  }

  /**
   * @description this is output event from commercialbutton when click on update/option/book/cancel button click
   * @author Darshan Vachhani
   * @param {*} status
   * @memberof CommercialDefaultComponent
   */
  onBookCampaign(status: number) {
    if (this.validateCommercialCampaign(status)) {
      status = status || this.campaignDetails.campaignStatusId;

      /*
        Need to trigger ROChange OR ROCancel in below scenario [SM-5283]
        1. It should be loaded campaign and there should be data in auditType(to populate drop downs) in Initial Config
        2. Trigger ROCancel OR
        3. Trigger ROChange
      */
      if (SystemFlags.isLoadedCampaign && (this.initialConfig.auditType && this.initialConfig.auditType.length > 0) &&
        (this.commercialButtonService.roCancel(this.campaignDetails, this.commercialConfig.uiControl, status) ||
          this.commercialButtonService.roChange(this.campaignDetails, this.commercialConfig.uiControl, 'bookingList', status))) {
        this.handleROC(status);
      } else if ((this.commercialConfig.uiControl.tradingEnabled ?
        this.campaignDetails.grossMediaValue === 0 : false) &&
        status === CampaignStatus.confirm) {
        this.confirmCampaign();
      } else {
        const param = {
          bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
          data: JSON.stringify(this.populateBookCommercialCampaignParam(status,
            this.campaignDetails))
        };
        this.commercialButtonService.validateCommercialScreenServiceRequest(param, status);
      }
    }
  }

  /**
   * Need to trigger ROChange OR ROCancel in below scenario
   * @param status Status
   */
  handleROC(status) {
    this.commercialButtonService.openReasonForChangeOverlay(this.commercialButtonService.populateDataParamReasonForChange(status, this.campaignDetails, this.commercialConfig.uiControl))
      .then((result: any) => {
        if (result) {
          this.campaignDetails = this.commercialButtonService.reasonForChangeOverlayThen(this.campaignDetails, result, status);

          const param = {
            bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
            data: JSON.stringify(this.populateBookCommercialCampaignParam(status,
              this.campaignDetails))
          };
          this.commercialButtonService.validateCommercialScreenServiceRequest(param, status);
        }
      }, (reason) => {
        console.log(reason);
      });
  }

  /**
   * Confirm Campaign
   */
  confirmCampaign() {
    this.commercialButtonService.openFreeOFChargeOverlay().then((result: FreeOFChargeResult) => {
      if (result) {
        this.campaignDetails.freeOfCharge = result.freeOfCharge;
        const comment = this.getComment(result);
        result.freeOfCharge.comment = comment;
        this.campaignDetails.comments = (this.campaignDetails.comments ?
          (`${this.campaignDetails.comments}\n\r${comment}`) : comment);
        const campaignStatus = CampaignStatus.confirm || this.campaignDetails.campaignStatusId;
        const param = {
          bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
          data: JSON.stringify(this.populateBookCommercialCampaignParam(campaignStatus,
            this.campaignDetails))
        };
        this.commercialButtonService.validateCommercialScreenServiceRequest(param, this.campaignDetails.campaignStatusId);
      }
    });
  }

  /**
   * TO get the comment for campaign details from response
   * @param result Response of confirmed campaign
   */
  getComment(result) {
    if (result.freeOfCharge && result.freeOfCharge.specifyOther) {
      return (this.commercialButtonService.startsWith(result.freeText, 'FOC - ') ? '' : 'FOC - ') + result.freeText;
    }
    return (this.commercialButtonService.startsWith(result.freeOfCharge.auditReasonName, 'FOC - ') ? '' : 'FOC - ')
      + result.freeOfCharge.auditReasonName;
  }

  setDefaultAgencySpecialistPerc(agencyPercentage: number = null, specialistPercentage: number = null) {
    if (agencyPercentage == null) {
      agencyPercentage = this.initialConfig.uiControl.defaultThirdPartyAgencyCommission;
    }

    if (specialistPercentage == null) {
      specialistPercentage = this.initialConfig.uiControl.defaultThirdPartySpecialistCommission;
    }
    this.campaignDetailsService.setDefaultAgencyPercentage(agencyPercentage);
    this.campaignDetailsService.setDefaultSpecialistPercentage(specialistPercentage);
  }

  trackByIndex(index, item) {
    return index;
  }
}
