import { DataShareService, SharedService, BrickBaseService } from '../../core/services/index';
import { Hod } from '../../models/initial-config.model';
import { ColumnConfig } from '../../models/MapData.model';

export class NetowrkBase {
  /**
   * @description User bundle object
   * @type {object}
   * @memberof NetowrkBase
   */
  userBundle: object;
  /**
   * @description columnconfig for that column
   * @type {object}
   * @memberof NetowrkBase
   */
  columnConfig: ColumnConfig;
  /**
   * @description lookup dayt for network
   * @type {object}
   * @memberof NetowrkBase
   */
  lookups: object = null;
  /**
   * @description filter networks to display on UI
   * @type {any[]}
   * @memberof NetowrkBase
   */
  hodOptions: Hod[] = [];
  /**
   * @description network data from Initial Config
   * @type {any[]}
   * @memberof NetowrkBase
   */
  hodData: Hod[] = [];
  /**
   * @description selected network
   * @type {*}
   * @memberof NetowrkBase
   */
  network: any = null;

  constructor(
    private dataShareService: DataShareService,
    private sharedService: SharedService,
    private brickBaseService: BrickBaseService
  ) { }

  init() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.hodData = this.dataShareService.getInitialConfigByKey('hodData');
    this.hodData = Object.keys(this.hodData).map(key => this.hodData[key]);
    if (this.columnConfig) {
      this.lookups = this.sharedService.getConfigLookupForBrick(this.brickBaseService.brickID.Network, this.columnConfig.lookupData);
    }

    this.hodData.forEach((obj) => {
      if (this.lookups) {
        Object.keys(this.lookups).forEach((key) => {
          if (obj.hodId === Number(key)) {
            this.hodOptions.push(obj);
          }
        });
      } else {
        this.hodOptions.push(obj);
      }
    });
  }
  /**
   * @description set column config from modal component
   * @author Nishit Parekh
   * @param {*} data
   * @memberof NetowrkBase
   */
  setColumnConfig(data) {
    this.columnConfig = data;
  }

}
