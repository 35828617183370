<!-- <input type="date" min="{{minDate}}" max ="{{maxDate}}" #input [(ngModel)]="value" style="width: 100%"> -->
<!-- <input #input placeholder="mm-dd-yyyy" (ngModelChange)="onDateChange($event)" [maxDate]="validation.maxDate" [minDate]="validation.minDate"
    (click)="d.toggle()" [container]="'body'" name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" readonly> -->
<!-- <input #input [disabled]="cellDisable" [ngModel]="value" style="width: 100%;height: 100%;" readonly> -->
<input type="text" #input="ngbDatepicker" style="width: 100%;height: 100%;" (ngModelChange)="onDateSelect($event)"
    [(ngModel)]="value" [dayTemplate]="calendarDayTemplate" [minDate]="minDate" [maxDate]="maxDate" ngbDatepicker
    [markDisabled]="markDayDisabled"   [container]="'body'" (click)="openDatePicker($event, input)" [outsideDays]="'collapsed'"
    [disabled]="cellDisable" readonly>
<span class="clearIcon" (click)="clearDate()">
    <em class="fa fa-remove"></em>
</span>

<ng-template #calendarDayTemplate let-date="date" let-currentMonth="currentMonth" let-selected="selected"
    let-disabled="disabled">
    <span class="day-container" [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
        <div class="custom-day" [class.today]="getStartDayProp(date, disabled, 'isToday')" [class.selected]="selected">
            {{ date.day }}
        </div>
    </span>
</ng-template>