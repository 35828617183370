<div *ngIf="isConfigAvailable">
  <div class="control_group search_control_group">
    <label class="label">{{userBundle['common.advertiserName']}}</label>
    <div class='gcontainer' id="advertiserName">
      <app-sb-select id="advertiserSelect" [config]="advertiserLookupConfig"         
        [items]="advertisersList" [currentItem]="campaignDetails.advertiser" (onItemSelected)="onAdvertiserNameSelected($event)"
        (onItemRemoved)="removeSelectedAdvertiserName($event);" (responseData)="getAdvertisersResponse($event)"
        [disabled]="common.isDisabled">
      </app-sb-select>
    </div>
  </div>
  <div class="control_group search_control_group">
    <label class="label">{{userBundle['common.productCategoryName']}}</label>
    <select id="productCategorySelect" class="form_control chzn-container"
      [disabled]="common.isDisabled || common.disableProductCategory" [(ngModel)]="campaignDetails.productCategoryId"
      [ngStyle]="{'width': '80%'}">
      <option value="-1">{{userBundle['common.lookup.defaultOption']}}</option>
      <ng-template ngFor let-item [ngForOf]="productCategoryList" [ngForTrackBy]="trackByProductCategoryGroup">
        <optgroup *ngIf="item.productCategory"
          label="{{item.productCategoryGroupName}} - {{item.productCategoryGroupCode}}">
          <option *ngFor="let child of item.productCategory; trackBy: trackByProductCategory;" [value]="child.productCategoryId">
            {{child.productCategoryName}}
          </option>
        </optgroup>
        <option *ngIf="!item.productCategory">{{item.productCategoryGroupName}}</option>
      </ng-template>
    </select>
  </div>
  <div class="control_group search_control_group">
    <label class="label" [ngClass]=" (campaignDetails?.advertiser.advertiserId && campaignDetails?.brand.brandId === 0 || campaignDetails?.brand.brandId === null) ?'required' :''">{{userBundle['common.brandName']}}</label>
    <div class='gcontainer'>
      <app-sb-select id="brandSelect" [config]="brandLookupConfig"        
        [items]="brandsList" [currentItem]="campaignDetails.brand" (onItemSelected)="onBrandSelected($event)"
        (onItemRemoved)="removeSelectedBrand($event,campaignDetails)" (responseData)="getBrandsResponse($event)"
        [disabled]="common.isDisabled">
      </app-sb-select>
    </div>
  </div>
  <div class="control_group search_control_group">
    <label class="label">{{userBundle['common.agencyName']}}</label>
    <div class='gcontainer'>
      <app-sb-select id="agencySelect" [config]="agencyLookupConfig"
        [items]="agenciesList" [currentItem]="campaignDetails.agency" (onItemSelected)="onAgencyNameSelected($event)"
        (onItemRemoved)="removeSelectedAgencyName()" (responseData)="getAgenciesResponse($event)"
        [disabled]="common.isDisabled || common.disableAgency">
      </app-sb-select>
    </div>
  </div>
  <div class="control_group search_control_group" *ngIf="!hideUIElements('search.specialist')">
    <label class="label">{{userBundle['common.specialistName']}}</label>
    <div class='gcontainer'>
      <app-sb-select id="specialistSelect" [config]="specialistLookupConfig"
        [items]="specialistsList" [currentItem]="campaignDetails.specialist" (onItemSelected)="onSpecialistNameSelected($event)"
        (onItemRemoved)="removeSelectedSpecialistName()" (responseData)="getSpecialistsResponse($event)"
        [disabled]="common.isDisabled || common.disableSpecialist">
      </app-sb-select>
    </div>
  </div>
  <div class="control_group search_control_group" *ngIf="!common.hideSalesTeam">
    <label class="label">{{userBundle['common.salesTeamName']}}</label>
    <select id="salesTeamSelect" class="form_control chzn-container" [(ngModel)]="campaignDetails.salesTeamId"
      [ngStyle]="{'width': '80%'}" [disabled]="common.disableSalesTeam || common.isDisabled">
      <option value="-1">{{userBundle['common.lookup.defaultOption']}}</option>
      <option *ngFor="let SaleInfo of salesTeamList; trackBy: trackBySalesTeam;" [value]="SaleInfo.salesTeamId">
        {{SaleInfo.salesTeamName}}
      </option>
    </select>
  </div>
  <div class="control_group search_control_group" *ngIf="!hideUIElements('search.campaignGroup')">
    <label class="label">{{userBundle['search.exclusion.campaignTypeGroupName']}}</label>
    <select id="campaignGroup" class="form_control chzn-container" [(ngModel)]="campaignDetails.campaignTypeGroupId"
      [ngStyle]="{'width': '80%'}"
      [disabled]="common.disablecampaignGroup || isLoadedCampaign || !userModel.searchTabAccess.campaignTypeAccess"
      (change)="onCampaignGroupChange();">
      <!-- <option value="-1">{{userBundle['common.lookup.defaultOption']}}</option> -->
      <option *ngFor="let item of campaignTypeGroup; trackBy: trackByCampaignTypeGroup;" [value]="item.campaignTypeGroupId">
        {{item.campaignTypeGroupName}}
      </option>
    </select>
  </div>
  <div class="control_group search_control_group" *ngIf="!hideUIElements('search.campaignGroup')">
    <label class="label">{{userBundle['search.exclusion.campaignTypeName']}}</label>
    <select id="campaignTypeSelect" class="form_control chzn-container" [(ngModel)]="campaignDetails.campaignTypeId"
      [ngStyle]="{'width': '80%'}"
      [disabled]="common.disablecampaignGroup || isLoadedCampaign || !userModel.searchTabAccess.campaignTypeAccess">
      <!-- <option value="-1">{{userBundle['common.lookup.defaultOption']}}</option> -->
      <option *ngFor="let item of campaignType; trackBy: trackByCampaignType;" [value]="item.campaignTypeId">
        {{item.campaignTypeName}}
      </option>
    </select>
  </div>
  <div class="control_group search_control_group" *ngIf="!hideUIElements('common.dgNumber')">
    <label class="label">{{userBundle['common.dgNumber']}}</label>
    <input type="text" class="form_control chzn-container" [(ngModel)]="campaignDetails.dgNumber" [disabled]="common.isDisabled" />
  </div>
  <div class="control_group search_control_group" *ngIf="!hideUIElements('common.opportunityLineId')">
    <label class="label">{{userBundle['common.opportunityLineId']}}</label>
    <input type="text" class="form_control chzn-container" [(ngModel)]="campaignDetails.opportunityLineId" [disabled]="common.isDisabled" />
  </div>
  <div class="control_group search_control_group" *ngIf="!hideUIElements('common.customerId')">
    <label class="label">{{userBundle['common.customerId']}}</label>
    <input type="text" class="form_control chzn-container" [value]="customerId" [disabled]="true" />
  </div>

</div>

<div class="exclusion" *ngIf="!hideUIElements('search.exclusionHeading')">
  <div class="control_group">
    <label class="label">{{userBundle['search.exclusionHeading']}}</label>
    <div class="clear"></div>

    <div class="width_7" [ngStyle]="{'width': '12%'}" [hidden]="hideUIElements('search.exclusion.advertiserBlacklist')">
      <input type="checkbox" [(ngModel)]="campaignDetails.excludeAdvertiserBlacklist" class="checkbox" id="advertiserblacklist"
        [disabled]="!campaignDetails?.advertiser?.advertiserId || isInvoiceLocked" />
      <label for="advertiserblacklist">{{userBundle['search.exclusion.advertiserBlacklist']}}</label>
    </div>

    <div class="width_7" [ngStyle]="{'width': '12%'}" [hidden]="hideUIElements('search.exclusion.specialistBlacklist')">
      <input type="checkbox" [(ngModel)]="campaignDetails.excludeSpecialistBlacklist" class="checkbox" id="specialistblacklist"
        [disabled]="!campaignDetails?.specialist?.specialistId || isInvoiceLocked" />
      <label for="specialistblacklist">{{userBundle['search.exclusion.specialistBlacklist']}}</label>
    </div>

    <div class="width_7" [ngStyle]="{'width': '11%'}" [hidden]="hideUIElements('search.exclusion.agencyBlacklist')">
      <input type="checkbox" [(ngModel)]="campaignDetails.excludeAgencyBlacklist" class="checkbox" id="agencyBlacklist"
        [disabled]="!campaignDetails?.agency?.agencyId || isInvoiceLocked" />
      <label for="agencyBlacklist">{{userBundle['search.exclusion.agencyBlacklist']}}</label>
    </div>
    <div class="left" [ngStyle]="{'width': '11%'}" [hidden]="hideUIElements('search.exclusion.duplication')">
      <div class="proximityopen" [ngClass]="{'disable-button' : isInvoiceLocked}" (click)="openDuplication()">+</div>
      <input type="checkbox" [(ngModel)]="campaignDetails.excludeDuplication" class="checkbox" id="duplication" [disabled]="isInvoiceLocked" />
      <label for="duplication">{{userBundle['search.exclusion.duplication']}}</label>
    </div>
    <div class="left" [ngStyle]="{'width': '7%'}" [hidden]="hideUIElements('search.exclusion.juxta')">
      <input type="checkbox" [(ngModel)]="campaignDetails.excludeJuxta" class="checkbox" id="juxta" [disabled]="isInvoiceLocked" />
      <label for="juxta">{{userBundle['search.exclusion.juxta']}}</label>
    </div>
    <div class="proopencontainer" [ngStyle]="{'width': '14%'}"
      [hidden]="hideUIElements('search.exclusion.proximityProhibition')">
      <div class="proximityopen" [ngClass]="{'disable-button' : isInvoiceLocked}" (click)="openProximityProhibition()">+</div>
      <input type="checkbox" [(ngModel)]="campaignDetails.excludeProximityProhibition" class="checkbox"
        id="proximityprohabition" [disabled]="common.disableProximityProhibition" />
      <label for="proximityprohabition">{{userBundle['search.exclusion.proximityProhibition']}}</label>
    </div>

    <div class="left" [ngStyle]="{'width': '14%'}" [hidden]="hideUIElements('search.exclusion.customFormats')">
      <input id="sheets" type="checkbox" [(ngModel)]="campaignDetails.excludeCustomFormats" class="checkbox"
        [disabled]="common.excludeCustomFormats" />
      <label for="sheets">{{userBundle['search.exclusion.customFormats']}}</label>
    </div>
    <div class="left" [ngStyle]="{'width': '12%'}" [hidden]="hideUIElements('search.exclusion.dummyPanels')">
      <input type="checkbox" [(ngModel)]="campaignDetails.excludeDummyPanels" class="checkbox" id="Cancellation-Charge" [disabled]="isInvoiceLocked" />
      <label for="Cancellation-Charge">{{userBundle['search.exclusion.dummyPanels']}}</label>
    </div>

    <div class="left" [hidden]="hideUIElements('search.exclusion.exclusivity')">
      <div class="proximityopen" [ngClass]="{'disable-button' : isInvoiceLocked}" (click)="openProductCategory()">+</div>
      <input type="checkbox" [(ngModel)]="campaignDetails.exclusivity" class="checkbox" id="exclusivity" [disabled]="isInvoiceLocked" />
      <label for="exclusivity">{{userBundle['search.exclusion.exclusivity']}}</label>
    </div>

  </div>
</div>

<div class="footer_btns" *ngIf="isConfigAvailable">
  <button class="btn btn-primary resetButton" (click)="resetCampaign(true)">
    {{userBundle['search.campaign.buttonLabel.reset']}}
  </button>
  <button class="btn btn-primary smartbricbtn" (click)="goToWorkspace('/workspace')" [disabled]="disabledSmartBricsButton">
    <img src="assets/img/smartbric_icon.png" alt="">{{userBundle['common.smart'] || 'Smart'}}
    <strong>{{userBundle['common.brics'] || 'BRICS'}}</strong>
  </button>
  <button class="btn btn-primary smartbricbtn" *ngIf="uiControl['enableGeoMapper']  && !isFillerCampaign"
    (click)="goToWorkspace('/geoplanner')" [disabled]="disabledGeoPlannerButton">
    <div class="fa fa-globe" style="font-size: 24px;line-height: 40px;padding-right: 5px;"></div>
    <div style="float: right;" class="ng-binding">{{userBundle['geoplanner'] || 'Geo Mapper'}}</div>
  </button>
  <button class="btn btn-primary smartbricbtn" *ngIf="uiControl['enableVisualPlanner'] && !isFillerCampaign"
    (click)="goToWorkspace('/visualplanner')" [disabled]="disabledVisualPlannerButton">
    <div class="fa fa-th-list" style="font-size: 20px;line-height: 40px;padding-right: 5px;"></div>
    <div style="float: right;" class="ng-binding">{{userBundle['visualplanner'] || 'Visual Planner'}}</div>
  </button>
</div>
