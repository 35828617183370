import { LocaleData } from '../core/utils/LocaleData';

/**
 * Defines the type of the masked value in ngModel
 */
export type Unmask = 'typed' | boolean;

/**
 * ----------------------------------------------------------------------------
 * WARNING: Please do not change the default values of NumberMaskModel model.
 * ----------------------------------------------------------------------------
 * @description Model of Number Mask
 * @author Shivani Patel
 * @export
 * @class NumberMaskModel
 */
export class NumberMaskModel {
  /**
   * Enable number mask
   */
  mask?: NumberConstructor = Number;
  /**
   * Digits after point, 0 for integers
   */
  scale?: number = 2;
  /**
   * Thousand Separator
   */
  thousandsSeparator?: string = LocaleData.NUM_GROUP_SEP || ',';
  /**
   * Decimal Separator
   */
  radix?: string = LocaleData.NUM_DECIMAL_SEP || '.';
  /**
   * Symbols to process as radix
   */
  mapToRadix?: string[] = ['.'];
  /**
   * Minimum value
   */
  min?: number = 0;
  /**
   * Maximum value
   */
  max?: number = 9999999999;
  /**
   * If true, then pads zeros at end to the length of scale
   */
  padFractionalZeros?: boolean = false;
  /**
   * Appends or removes zeros at ends
   */
  normalizeZeros?: boolean = true;
}

/**
 * @description Model of Dynamic Mask
 * @author Shivani Patel
 * @export
 * @class DynamicMaskModel
 */
export class DynamicMaskModel {
  /**
   * Array of Mask
   */
  mask?: any[];
  /**
   * Dispatch method which takes two params (string, any)
   */
  dispatch?: (...a) => {};
}
