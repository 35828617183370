<div class="bounceInRight animated searchpage innerpage" *ngIf="loadSearchView">
  <dl class="accordion animated bounceInDown content" *ngIf="(userModel?.searchTabAccess?.openSearch || userModel?.searchTabAccess?.openBooking
  || userModel?.searchTabAccess?.openRequest)">
    <dt (click)="accordionCampaignOpened = !accordionCampaignOpened">
      <a [ngClass]="!accordionCampaignOpened? 'down' : ''" id="search-campaign-tab" href="javascript:void(0)"
        class="title">
        <span class="text">{{userBundle['search.search.Heading']}}</span>
        <div class="icon_wrapper">
          <span class="icons animated"></span>
        </div>
      </a>
    </dt>
    <dd id="SavedSearchCampaign" [ngClass]="!accordionCampaignOpened ? 'down' : ''">
      <app-smart-search *ngIf="!accordionCampaignOpened">Smart Search goes here...</app-smart-search>
    </dd>
  </dl>
  <dl class="accordion animated bounceInDown content campaing">
    <dt (click)="accordionCampaignOpened = !accordionCampaignOpened">
      <a [ngClass]="accordionCampaignOpened? 'down' : ''" id="campaign-details-tab" href="javascript:void(0)"
        class="title">
        <span class="text">{{userBundle['search.campaign.Heading']}}</span>
        <div class="icon_wrapper">
          <span class="icons animated"></span>
        </div>
      </a>
    </dt>
    <dd [ngClass]="accordionCampaignOpened? 'down' : ''">
      <app-campaign-details *ngIf="isInitialConfigExist" (emitOnSearch)="goToWorkspace($event)"
        [isLoadedCampaign]="isLoadedCampaign" #campaignDetails>
        Campaign Details goes here...</app-campaign-details>
    </dd>
  </dl>
</div>
<div class="versionInfo ng-binding">
  <span [ngClass]="{ 'selected-env': isMarketChangeble }" (click)="changeEnv()">
    <em class="fa fa-refresh" title="Change environment" *ngIf="isMarketChangeble"></em>
    {{userBundle['app.market'] || 'Market'}}: {{selectedEnvironment}}
  </span> | User: {{userData?.name || userData?.fullName}} | Back End Version: {{systemData?.version}} | Front End
  Version: {{frontEndVersion}}
</div>
