import * as moment from 'moment';
declare global {
  interface Date {
    addDays(days: number): Date;
    getWeek(): number;
    sameDay(date: Date): boolean;
    getWeekFromDate(date: Date): number;
  }
}

/**
 * @description Returns new Date by adding given number of days
 * @param {number} days
 * @returns {Date}
 * @memberof Filter
 */
Date.prototype.addDays = function(days: number): Date {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

/**
 * @description To get number of week
 * @returns {number} Returns number of week
 * @memberof Filter
 */
Date.prototype.getWeek = function(): number {
  // Create a copy of this date object
  const target = new Date(this.valueOf());
  return moment(target).isoWeek();

  // Below code is manual
  // // ISO week date weeks start on monday
  // // so correct the day number
  // const dayNr = (this.getDay() + 6) % 7;
};

/**
 * @description Compares today's date and given date
 * @param {Date} date
 * @returns {boolean} Returns true if today's date and given date are same
 * @memberof Filter
 */
Date.prototype.sameDay = function(date: Date): boolean {
  return this.getFullYear() === date.getFullYear()
    && this.getDate() === date.getDate()
    && this.getMonth() === date.getMonth();
};

/**
 * @description Get week no on given date
 * @param {Date} date
 * @returns {number} Returns week number from given date range
 * @memberof Filter
 */
Date.prototype.getWeekFromDate = function(date): number {
  return moment(this).diff(moment(date), 'week') + 1;
};

export { };
