import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterModule } from './geoplanner/src/app/filter/filter.module';
import { GeoMapModule } from './geoplanner/src/app/geo-map/geo-map.module';
import { CoreModule } from './geoplanner/src/app/core/core.module';

import { BricHostDirective } from './geoplanner/src/app/core/directives/bric-host.directive';
import { AppComponent } from './geoplanner/src/app/app.component';
import { VisualPlannerComponent } from './geoplanner/src/app/visual-planner/visual-planner.component';

@NgModule({
  declarations: [
    AppComponent,
    VisualPlannerComponent,
    BricHostDirective
  ],
  // entryComponents: [
  //   AppComponent,
  //   VisualPlannerComponent
  // ],
  imports: [
    CommonModule,
    FilterModule,
    GeoMapModule,
    CoreModule.forRoot()
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GeoMapperModule { }
