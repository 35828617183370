<div>
  <ng-container *ngIf="!params.data.isParent">
    <div *ngIf="params.data.isChildHeader">
      <span>Frame</span>
    </div>
    <div *ngIf="!params.data.isChildHeader" style="text-align: center;">
      <span *ngIf="params.data.delta < 0" style="font-size: large; color: #de0b0b;">
        <em class="fa fa-sort-down"></em>
      </span>
      <span *ngIf="params.data.delta === 0" style="font-size: medium; color: #ebb734;">
        <em class="fa fa-minus"></em>
      </span>
      <span *ngIf="params.data.delta > 0" style="font-size: large; color: green;">
        <em class="fa fa-sort-up"></em>
      </span>
      <span>{{ params.data.delta }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="params.data.isParent">
    <div [className]="displayText == '1'? 'trafficLight img-circle bg-green' : (displayText =='2' ? 'trafficLight img-circle bg-orange' : (displayText == '3' ? 'trafficLight img-circle bg-red': ''))">
    </div>
  </ng-container>
</div>