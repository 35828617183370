import { Component, OnInit, Input, QueryList, ViewChildren } from '@angular/core';
import { CellValues } from '../../../models/workspace/index';
import {
  DataShareService,
  SharedService,
  BrickBaseService,
  LogHelperService,
  CellAttributeService
} from '../../../core/services';
import { TagInputDropdown } from 'ngx-chips';
import { TagBase } from '../tag-base';
import * as _ from 'lodash';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';
import { ObjectiveService } from '../../../workspace/objective/objective.service';
import { GLOBAL } from '../../../core/utils/app.constant';

@Component({
  selector: 'app-tag-modal',
  templateUrl: './tag-modal.component.html',
  styleUrls: ['./tag-modal.component.css']
})
export class TagModalComponent extends TagBase implements OnInit {
  /**
   * It will take data to configure modal window.
   */
  @Input() resolveObject: CellValues;
  /**
   * Tag Input dropdown view children instance.
   */
  @ViewChildren(TagInputDropdown) public dropdownTagComp: QueryList<TagInputDropdown>;
  readOnlyModal = false;

  public global = GLOBAL;

  /**
   * Creates an instance of TagModalComponent.
   * @author Alkesh Shah
   * @param {DataShareService} dataService
   * @memberof TagModalComponent
   */
  constructor(
    dataService: DataShareService,
    private cellAttributeService: CellAttributeService,
    private sharedService: SharedService,
    brickBaseService: BrickBaseService,
    private brickBaseService1: BrickBaseService,
    private logHelperService: LogHelperService,
    appHeaderService: AppHeaderService,
    objectiveService: ObjectiveService) {
    super(dataService, appHeaderService, objectiveService, brickBaseService);
  }

  /**
   * @description angular component init lifecycle hook
   * @author Alkesh Shah
   * @memberof TagModalComponent
   */
  ngOnInit() {
    const selectedValues = this.resolveObject.selectedValues && !this.resolveObject.selectedValues.hasOwnProperty('-99')
      ? this.resolveObject.selectedValues.allTabSelection : {};
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    const tagGroupDataTemp = this.resolveObject.tagGroupData ||
      this.sharedService.getTagDataFromColumnConfig(this.brickBaseService1.brickID.Tag,
        this.resolveObject.columnConfig, _.cloneDeep(this.initialConfig.tagGroup));
    const tagGroupData = {};
    if (tagGroupDataTemp) {
      tagGroupDataTemp.map((item) => {
        tagGroupData[item.tagGroupId] = item;
      });
    }
    if (tagGroupData && Object.keys(tagGroupData).length > 0) {
      this.init(tagGroupData, selectedValues);
    } else {
      return;
    }
  }

  /**
   * @description To select/highlight selected parent.
   * @author Alkesh Shah
   * @param {string} id Index of selected tab.
   * @memberof TagModalComponent
   */
  selectTab(id: string): void {
    // to close tag-input dropdown on selection menu change
    if (this.dropdownTagComp.length > 0 && this.dropdownTagComp.first.dropdown.menu.dropdownState.menuState.isVisible) {
      this.dropdownTagComp.first.dropdown.hide();
    }
    this.selectTabChange(id);
  }

  /**
   * callback to close modal popup window
   * @param {*} event - modal event object
   * @memberof TagModalComponent
   */
  onModalClosed(event) {
    const isSelected = this.isValidSelection();
    if (event.reason === 'escape' && isSelected) {
      if (window.confirm(this.initialConfig['userBundle']['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * @description callback function of save button click
   * @author Alkesh Shah
   * @memberof TagModalComponent
   */
  onModalSaved(event) {
    if (this.isValidSelection()) {
      const values: CellValues = new CellValues();
      values.brick = this.resolveObject.brick;
      values.selectedValues = this.tags;
      values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService1.brickID.Tag, values.selectedValues);
      values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService1.brickID.Tag,
        values.selectedValues.allTabSelection);
      values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService1.brickID.Tag, values.selectedValues);

      event.activeModal.close(values);
    } else {
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.invalidValue']);
    }
  }
}
