<!--please refer SBRICS-258 for bellow conditions, Nishit Dt:21 May-->

<div class="footer_btns">
    <button id="cancel-button" class="btn btn-save left" [disabled]="readOnly || commercialButtonVisible.disabledCancelButton"
        *ngIf="commercialButtonVisible.showCancelButton && environmentId !== environmentnum.US" (click)="bookCampaignData(5)">
        <img src="assets/img/cancel_btn.png" alt="">{{userBundle['common.cancel']}}
    </button>

    <button id="cancel-button" class="btn btn-save left" [disabled]="readOnly || commercialButtonVisible.disabledCancelButton"
        *ngIf="commercialButtonVisible.showCancelButton && environmentId == environmentnum.US" (click)="bookCampaignData(5)">
        <img src="assets/img/cancel_btn.png" alt="">{{userBundle['commercial.cancel.us']}}
    </button>

    <div class="right">
        <button id="update-button" class="btn btn-book"
            [disabled]="commercialButtonVisible.disabledUpdateButton || (readOnly && commercialButtonVisible.disabledRequestBookingButton)"
            *ngIf="commercialButtonVisible.showUpdateButton"
            (click)="bookCampaignData(campaignDetails?.campaignStatusId, true)">
            <img src="assets/img/update_btn.png" alt="">{{userBundle['common.update']}}
        </button>
        <button id="option-button" class="btn btn-book"
            [disabled]="commercialButtonVisible.disabledOptionButton || (readOnly && commercialButtonVisible.disabledRequestBookingButton)"
            *ngIf="commercialButtonVisible.showOptionButton" (click)="bookCampaignData(2)">
            <img src="assets/img/option_btn.png" alt="">{{userBundle['common.option']}}
        </button>
        <button id="book-button" class="btn btn-book" [disabled]="readOnly || commercialButtonVisible.disabledBookButton"
            *ngIf="commercialButtonVisible.showBookButton" (click)="bookCampaignData(4)">
            <img src="assets/img/book_btn.png" alt="">{{userBundle['common.book']}}
        </button>
        <button *ngIf="commercialConfig?.uiControl?.showCCP3LinkButton && !bookingStatusCancelled && isLoadedCampaign" class="btn btn-proposal right" (click)="performance(10)" [disabled]="readOnly">
          <img src="../../../../../images/proposal.png" alt="">{{userBundle['result.buttonLabel.ccp3link']}}
        </button>
    </div>

    <div class="right" *ngIf="commercialConfig?.uiControl?.showActionButtons">
        <button class="btn btn-book orange right" [disabled]="commercialButtonVisible.disabledRequestBookingButton || readOnly"
            *ngIf="commercialButtonVisible.showRequestBookingButton" (click)="onRequestOptionAndBookingClick(2)">
            <img src="assets/img/book.png"
                alt="" />{{userBundle['result.buttonLabel.requestBooking']}}
        </button>
        <button class="btn btn-book grey right" [disabled]="commercialButtonVisible.disabledRequestOptionButton || readOnly"
            *ngIf="commercialButtonVisible.showRequestOptionButton" (click)="onRequestOptionAndBookingClick(3)">
            <img src="assets/img/book.png" alt="" />{{userBundle['result.buttonLabel.requestOption']}}
        </button>
        <button class="btn btn-proposal right" *ngIf="commercialButtonVisible.showConfirmProposalButton" [disabled]="readOnly"
            (click)="confirmProposal(4)">
            <img src="assets/img/proposal.png" alt="">{{userBundle['common.confirmproposal'] || 'Confirm Proposal'}}
        </button>
        <button class="btn btn-proposal right" *ngIf="commercialButtonVisible.showProposalButton" (click)="proposal(4)" [disabled]="readOnly">
            <img src="assets/img/proposal.png" alt="">{{userBundle['common.proposal' || 'Proposal']}}
        </button>
        <button class="btn btn-proposal right" *ngIf="commercialButtonVisible.showPerformanceButton" [disabled]="commercialButtonVisible.disablePerformanceButton"
            (click)="performance(5)">
            <img src="assets/img/proposal.png" alt="">{{userBundle['result.buttonLabel.performance']}}
        </button>
        <button class="btn btn-revert right" *ngIf="commercialButtonVisible.showRevertOptionToProposal"  [disabled]="readOnly"
            (click)="revertOptionToProprosal()">
            <img src="assets/img/revert.png"
                alt="">{{userBundle['result.buttonLabel.revertOptionToProposal']}}
        </button>
        <button class="btn btn-save right" [disabled]="commercialButtonVisible.disabledSaveButton || readOnly"
            *ngIf="commercialButtonVisible.showSaveButton" (click)="saveCampaign(1)">
            <img src="assets/img/save2.png" alt="">{{userBundle['common.save']}}
        </button>
    </div>

</div>
