import { Injectable } from '@angular/core';
import { ConcertinaHttpService } from './concertina-http.service';
import { VipFrameCountModel } from '../../../models';
import { Subject } from 'rxjs';
import { helper } from '../../utils/app.helper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { LogHelperService } from '../../services/log-helper.service';
import { ExtraInfoMaskEnum } from '../../../geo-map/status.enum';

import * as _ from 'lodash';
import { getFormData } from '../../utils/formData';
import { GLOBAL } from '../../utils/app.constant';
import { DataShareService } from '../../services/data-share.service';
import { SwapCampaign } from '../../../models/vp-swap';
/**
 * Concertina data service to generate data for grid
 *
 * @export
 * @class ConcertinaDataService
 */
@Injectable()
export class ConcertinaDataService {

  availability: false;

  filterCounts: VipFrameCountModel;

  $framesCount: Subject<VipFrameCountModel> = new Subject();

  /**
   * Request header
   * @private
   * @memberof ConcertinaHttpService
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

  /**
   * Grouped data
   *
   * @type {object}
   * @memberof ConcertinaDataService
   */
  public groupedData: object = {};
  /**
   * Request data
   *
   * @type {object}
   * @memberof ConcertinaDataService
   */
  public requestData: object = {};
  /**
   * Count for columns
   *
   * @memberof ConcertinaDataService
   */
  columnCount = 0;
  /**
   * Concertina rows
   *
   * @type {Array<any>}
   * @memberof ConcertinaDataService
   */
  concertinaRows: any[] = [];
  /**
   * Values to be disaplyed
   *
   * @memberof ConcertinaDataService
   */
  values = 'values';
  /**
   * Current selected tab index
   *
   * @memberof ConcertinaDataService
   */
  currentDataType = 0;
  /**
   * Items array for concertina export
   *
   * @type {Array<any>}
   * @memberof ConcertinaDataService
   */
  items: any[] = [];

  /**
   * @description Param to get basket data
   * @memberof ConcertinaDataService
   */
  basketMode = false;

  /**
   * viewing cart data on planner source observable subject
   * @private
   * @memberof ConcertinaDataService
   */
  private basketModeSource: BehaviorSubject<boolean> = new BehaviorSubject(this.basketMode);

  /**
   * cart Data observable
   * @memberof ConcertinaDataService
   */
  public basketMode$: Observable<boolean> = this.basketModeSource.asObservable();

  concertinaData: any;
  originalConcertinaData: any;

  /**
   * Creates an instance of ConcertinaDataService.
   * @memberof ConcertinaDataService
   */
  constructor(
    private httpService: ConcertinaHttpService,
    private http: HttpClient,
    private logHelperService: LogHelperService,
    private dataShareService: DataShareService
  ) { }

  setBasketMode(val: boolean): void {
    this.basketMode = val;
    this.basketModeSource.next(this.basketMode);
  }
  /**
   * To set value for grouped data
   *
   * @param {object} data New grouped data
   * @memberof ConcertinaDataService
   */
  setGroupedData(data: object) {
    this.groupedData = _.clone(data);
  }
  /**
   * To get value of grouped data
   *
   * @returns
   * @memberof ConcertinaDataService
   */
  getGroupedData() {
    return this.groupedData;
  }
  /**
   * To set request data
   *
   * @param {object} data New request data
   * @memberof ConcertinaDataService
   */
  setRequestData(data: object) {
    this.requestData = _.clone(data);
  }
  /**
   * To get request data
   *
   * @returns
   * @memberof ConcertinaDataService
   */
  getRequestData() {
    return this.requestData;
  }
  /**
   * Iterate over xGroups
   *
   * @memberof ConcertinaDataService
   */
  iterateXGroups = (group) => {
    _.map(group, (obj) => {
      this.columnCount++;
      const cellObject = {
        text: obj.displayText.replace(/(\r\n|\n|\r)/gm, ' - '),
        color: obj.colour,
        textColour: obj.textColour
      };
      this.concertinaRows.push(cellObject);
      if (_.has(obj, 'xGroups')) {
        this.iterateXGroups(obj.xGroups);
      }
    });
  }
  /**
   * To iterate over xValues
   *
   * @memberof ConcertinaDataService
   */
  iteratexValues = (group, showFI = false, isIndexTab = false, selectedAudienceCategory = -1, isNotSelectedTab = false) => {
    const allkeys = Object.keys(group).sort();
    allkeys.forEach((element) => {
      const obj = group[element];
      this.columnCount++;
      let displayText = obj[this.values][this.currentDataType];
      if (isIndexTab) {
        displayText = obj[this.values][this.currentDataType][selectedAudienceCategory] || '';
      }
      if (isNotSelectedTab) {
        displayText = obj[this.values][this.currentDataType].sot;
      }
      if (showFI) {
        if (obj.extraInfo === ExtraInfoMaskEnum.fixed) {
          displayText += ' (F)';
        }
        if (obj.extraInfo === ExtraInfoMaskEnum.innovate) {
          displayText += ' (I)';
        }
        if (obj.extraInfo === ExtraInfoMaskEnum.fixedAndInnovate) {
          displayText += ' (F/I)';
        }
      }
      if (this.columnCount !== 0) {
        const cellObject = {
          text: displayText,
          color: isNotSelectedTab ? obj[this.values][this.currentDataType].colour : obj.colour,
          textColour: isNotSelectedTab ? obj[this.values][this.currentDataType].textColour : obj.textColour
        };
        this.concertinaRows.push(cellObject);
      }
    });
    // _.map(group, (obj) => {
    //     this.columnCount++;
    //     const key = 'column' + this.columnCount;
    //     if (this.columnCount !== 0) {
    //         const cellObject = {
    //             text: obj[this.values][this.currentDataType],
    //             color: obj.colour,
    //             textColour: obj.textColour
    //         };
    //         this.concertinaRows.push(cellObject);
    //     }

    //     if (_.has(obj, 'xValues')) {
    //         this.iteratexValues(obj.xValues);
    //     }
    // });
  }
  /**
   * To generate data for concertian excel export
   *
   * @memberof ConcertinaDataService
   */
  getConcertinaExcelData = (selectedTab, concertinaData, showFI = false, isIndexTab = false, selectedAudienceCategory: number, concertinaNotSelectedTab = false) => {
    this.items = [];
    this.concertinaRows = [];
    this.columnCount = 0;
    this.currentDataType = selectedTab;
    let cellObject: object = {
      text: ' ',
      color: '00FFFFFF',
      textColour: '00FFFFFF'
    };
    this.concertinaRows.push(cellObject);

    concertinaData.groups.xGroups.forEach((element) => {
      this.columnCount++;
      const cell = concertinaData.xHeadings[element];
      cellObject = {
        text: cell.displayText.replace(/(\r\n|\n|\r)/gm, ' - '),
        color: cell.colour,
        textColour: cell.textColour
      };
      this.concertinaRows.push(cellObject);
    });

    this.items.push(this.concertinaRows);

    concertinaData.groups.yGroups.forEach((element) => {
      this.concertinaRows = [];
      cellObject = {
        text: concertinaData[element].displayText,
        color: '',
        textColour: '00FFFFFF'
      };
      this.concertinaRows.push(cellObject);
      this.iteratexValues(concertinaData[element].xValues, showFI, isIndexTab, selectedAudienceCategory, concertinaNotSelectedTab);
      this.items.push(this.concertinaRows);
    });
    return this.items;
  }

  /**
   * To iterate over yGroups
   * @param {any} group
   * @memberof ConcertinaDataService
   */
  iterateYgroups(group) {
    const sortedArray = _.sortBy(group, 'displayText');
    sortedArray.forEach((element) => {
      const cellObject = {
        text: element.displayText.replace(/(\u0026)/gm, '&amp;'),
        color: element.colour,
        textColour: element.textColour
      };
      this.concertinaRows.push(cellObject);
      if (_.has(element, 'xValues')) {
        this.iteratexValues(element.xValues);
        this.items.push(this.concertinaRows);
        this.concertinaRows = [];
      }
      if (_.has(element, 'yGroups')) {
        this.concertinaRows = [];
        this.iterateYgroups(element.yGroups);
      }
    });

  }

  /**
   * To get concertina data
   *
   * @param {string} key Selected key
   * @param {object} groupingData Grouping data (Filters)
   * @param {object} requestData Request data
   * @param {object} groupedData Grouped data
   * @param {boolean} availability will be true for concertina opened from workspace availability else false
   * @returns Concertina object
   * @memberof ConcertinaDataService
   */
  getConcertinaData(key: string, groupingData: object, requestData: object, groupedData: object, hideAdvertiserId = undefined) {
    return new Promise((resolve, reject) => {

      const request = this.generateRequestForConcertina(key, groupingData, requestData, groupedData, hideAdvertiserId);
      this.httpService.getConcertinaData(request['concertinaParam']).subscribe((data: any) => {
        if ((data['status']).toUpperCase() === 'OK') {
          const response = this.generateResponseForConcertina(data, request['result']);
          this.$framesCount.next(data['data']['filterCounts']);
          resolve(response);
        } else {
          this.logHelperService.logError(data.message);
          reject(data.message);
        }
      }, (error) => {
        reject(error);
      });
    });
  }

  getConcertinaDataForResult(params: object) {
    return this.httpService.getConcertinaData(params);
  }

  /**
   * @description Prepares concertina req
   * @author Amit Mahida
   * @param {string} key
   * @param {*} concertinaParam
   * @returns
   * @memberof ConcertinaDataService
   */
  manageConcertinaReq(key: string, concertinaParam: any) {
    const splitKey = key && key.length ? key.split(GLOBAL.CONCERTINA_SEPARATOR) : [];
    if (concertinaParam.concertinaRequest.hasOwnProperty('x')) {
      delete concertinaParam.concertinaRequest.x;
    }

    if (concertinaParam.concertinaRequest.hasOwnProperty('y')) {
      delete concertinaParam.concertinaRequest.y;
    }
    splitKey[0] === 'x' ? concertinaParam.concertinaRequest.x = key : concertinaParam.concertinaRequest.y = key;
    return splitKey;
  }

  generateRequestForConcertina(key, groupingData, requestData, groupedData, hideAdvertiserId: boolean) {
    const result = {
      groupingData,
      requestData,
      groupedData
    };
    const concertinaParam: any = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
      action: 'processConcertina'
    };
    let splitKey: string[] = [];
    if (!this.basketMode) {
      if (this.getAvailability()) {
        concertinaParam.availabilityMode = this.getAvailability();
      }
      concertinaParam.concertinaRequest = {};
      if (hideAdvertiserId !== undefined) {
        concertinaParam.concertinaRequest.hideAdvertiserId = hideAdvertiserId;
      }
      concertinaParam.concertinaRequest.grouping = result.groupingData;

      if (result.requestData) {
        concertinaParam.concertinaRequest.groups = result.requestData;
      }
      if (key) {
        splitKey = key.split(GLOBAL.CONCERTINA_SEPARATOR);
        if (concertinaParam.concertinaRequest.hasOwnProperty('x')) {
          delete concertinaParam.concertinaRequest.x;
        }

        if (concertinaParam.concertinaRequest.hasOwnProperty('y')) {
          delete concertinaParam.concertinaRequest.y;
        }
        splitKey[0] === 'x' ? concertinaParam.concertinaRequest.x = key : concertinaParam.concertinaRequest.y = key;
      }
    } else {
      concertinaParam.concertinaRequest = {};
      if (hideAdvertiserId !== undefined) {
        concertinaParam.concertinaRequest.hideAdvertiserId = hideAdvertiserId;
      }
      concertinaParam.concertinaRequest.grouping = result.groupingData;
      concertinaParam.basketMode = this.basketMode;
      if (key) {
        splitKey = key.split(GLOBAL.CONCERTINA_SEPARATOR);
        if (concertinaParam.concertinaRequest.hasOwnProperty('x')) {
          delete concertinaParam.concertinaRequest.x;
        }

        if (concertinaParam.concertinaRequest.hasOwnProperty('y')) {
          delete concertinaParam.concertinaRequest.y;
        }
        splitKey[0] === 'x' ? concertinaParam.concertinaRequest.x = key : concertinaParam.concertinaRequest.y = key;
      }
      if (Object.keys(result.requestData).length > 0) {
        concertinaParam.concertinaRequest.groups = result.requestData;
      }
    }

    return ({ result, splitKey, concertinaParam });
  }

  generateResponseForConcertina(data, result) {
    result.requestData = null;
    if (data.data && data.data.concertina) {
      // This will be called when user clicks on heading
      result.groupedData = data.data.concertina;
      result.groups = data.data.groups;
      result.xHeadings = data.data.xHeadings;
    }

    if (data.data && data.data.groups) {
      result.requestData = data.data.groups;
    }

    return result;
  }

  getFrameDetails(params) {
    const requestOptions = {
      body: getFormData(params),
      headers: this.headers
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      this.dataShareService.getServiceCallUrlByKey('GET_FRAME_DETAILS'),
      requestOptions
    )
      .map(helper.extractData)
      .catch(helper.handleError);

  }

  performSwap(param) {
    const params = {
      action: 'strictSwap',
      data: JSON.stringify(param)
    };
    const requestOptions = {
      body: getFormData(params),
      headers: this.headers
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      this.dataShareService.getServiceCallUrlByKey('VP_STRICT_SWAP'),
      requestOptions
    )
      .map(helper.extractData)
      .catch(helper.handleError);
  }

  setAvailability(val) {
    this.availability = val;
  }

  getAvailability() {
    return this.availability;
  }
   /**
   * @description update concertina after reverting frames from swapzone panel 
   * @author Sagar Vaishnav
   * @param {string} key
   * @param {*} concertinaParam
   * @returns
   * @memberof ConcertinaDataService
   */
  updateConcertinaDataAfterFrameRevert(yKey: string, xKey: string, bookingDetails?: SwapCampaign[]){
    for (const bookingDetail of bookingDetails) {
      let bookingDetailsIndex = -1;
      if (this.originalConcertinaData[yKey].isDigital) {
        bookingDetailsIndex = this.originalConcertinaData[yKey].xValues[xKey].bookingDetails
          .findIndex(bd => bd.campaignReference === bookingDetail.campaignReference
            && bd.startDate === bookingDetail.startDate
            && bd.endDate === bookingDetail.endDate
            && bd.sot === bookingDetail.sot);
      } else {
        bookingDetailsIndex = this.originalConcertinaData[yKey].xValues[xKey].bookingDetails
          .findIndex(bd => bd.campaignReference === bookingDetail.campaignReference
            && bd.startDate === bookingDetail.startDate
            && bd.endDate === bookingDetail.endDate);
      }
      if (bookingDetailsIndex === -1) {
        if (this.originalConcertinaData[yKey].xValues[xKey].values[this.currentDataType].indexOf(bookingDetail.campaignReference) === -1) {
          this.originalConcertinaData[yKey].xValues[xKey].values[this.currentDataType].push(_.cloneDeep(bookingDetail.campaignReference));
        }
        this.originalConcertinaData[yKey].xValues[xKey].bookingDetails.push(_.cloneDeep(bookingDetail));
        if (this.concertinaData[yKey]) {
          if (this.concertinaData[yKey].xValues[xKey].values[this.currentDataType].indexOf(bookingDetail.campaignReference) === -1) {
            this.concertinaData[yKey].xValues[xKey].values[this.currentDataType].push(_.cloneDeep(bookingDetail.campaignReference));
          }
          this.concertinaData[yKey].xValues[xKey].bookingDetails.push(_.cloneDeep(bookingDetail));
        }
      }
    }
  }


}
