import { DecimalPipe } from '@angular/common';
import { Component } from '@angular/core';
import { LocaleData } from '../../../../../core/utils/LocaleData';
import { DataFormatterService } from '../../services/data-formatter.service';
import { AgCustomRowUtil } from '../../utils/ag-custom-row-util';
import { CellRendererBaseComponent } from '../cell-renderer-base/cell-renderer-base.component';

@Component({
  selector: 'app-ag-ls-cell-renderer',
  templateUrl: './ag-ls-cell-renderer.component.html',
  styleUrls: ['./ag-ls-cell-renderer.component.css']
})
export class AgLsCellRendererComponent extends CellRendererBaseComponent {
  constructor(
    private decimalPipe: DecimalPipe,
    private dataFormatterService: DataFormatterService) {
    super();
  }

  /**
   * To display the column text for parent row
   * @author Dhaval Patel
   * @returns display text
   */
  public getParentRowText(): string {
    if (this.params.displayType === 'Percentage') {
      if (this.groupingField) {
        const rowData = AgCustomRowUtil.getRowData(this.params.api);
        const childRows = AgCustomRowUtil.getChildRows(rowData, this.groupingField, this.params.data[this.groupingField]);
        let sum = 0;
        const calcCellValue = this.params.colDef.cellRendererParams.calcCellValue;
        if (calcCellValue) {
          sum = calcCellValue(childRows, this.params.column.colId);
        }
        this.params.data[this.params.column.colId] = sum;
        return String(sum);
      } else {
        return this.params.data[this.params.column.colId];
      }
    } else if (this.params.displayType === 'Number' || this.params.displayType === 'Currency') {
      if (this.params.calcParentCellValue) {
        return this.params.calcParentCellValue(this.params);
      }
      if (this.groupingField) {
        const rowData = AgCustomRowUtil.getRowData(this.params.api);
        const childRows = AgCustomRowUtil.getChildRows(rowData, this.groupingField, this.params.data[this.groupingField]);
        const sum = AgCustomRowUtil.sumFieldValues(childRows, this.params.column.colId);
        this.params.data[this.params.column.colId] = sum;
        return String(sum);
      } else {
        return this.params.data[this.params.column.colId];
      }
    }
    return this.params.data[this.params.column.colId];
  }

  /**
   * To display the column text for child row
   * @author Dhaval Patel
   * @returns display text
   */
  public getChildRowText(): string {
    if (this.params.displayType === 'Percentage') {
      if (this.params.data[this.params.column.colId]) {
        if (typeof this.params.data[this.params.column.colId] === 'string' && this.params.allowReplaceDecimalSeparator) {
          this.params.data[this.params.column.colId] = LocaleData.replaceLocalDecimalCharWithDot(this.params.data[this.params.column.colId]);
        } else {
          this.params.data[this.params.column.colId] = parseFloat(this.params.data[this.params.column.colId]);
        }
      } else {
        this.params.data[this.params.column.colId] = 0;
      }
      return this.params.data[this.params.column.colId];
    } else if (this.params.displayType === 'Number' || this.params.displayType === 'Currency') {
      if (!this.params.data[this.params.column.colId]) {
        this.params.data[this.params.column.colId] = 0;
      }
      return this.params.data[this.params.column.colId];
    }
    return this.params.data[this.params.key];
  }

  /**
   * To display the column text for First pinned row
   * @author Dhaval Patel
   * @returns display text
   */
  public getFirstPinRowText(): string {
    if (this.params.displayType === 'Percentage') {
      if (this.params.gridSumRow && this.params.gridSumRow === 1) {
        return this.calcGridTotal();
      } else {
        if (this.params.data[this.params.column.colId] === 0 || (this.params.data[this.params.column.colId]
          && this.params.data[this.params.column.colId] !== '')) {
          if (typeof this.params.data[this.params.column.colId] === 'string' && this.params.allowReplaceDecimalSeparator) {
            this.params.data[this.params.column.colId] = LocaleData.replaceLocalDecimalCharWithDot(this.params.data[this.params.column.colId]);
          } else {
            this.params.data[this.params.column.colId] = parseFloat(this.params.data[this.params.column.colId]);
          }
        } else {
          this.params.data[this.params.column.colId] = '';
        }
        return this.params.data[this.params.column.colId];
      }
    } else if (this.params.displayType === 'Number' || this.params.displayType === 'Currency') {
      if (this.params.calcCellValue) {
        const rowData = AgCustomRowUtil.getRowData(this.params.api);
        const childRows = AgCustomRowUtil.getChildRows(rowData);
        let sum = 0;
        sum = this.params.calcCellValue(childRows, this.params.column.colId);
        return String(sum);
      } else {
        return '';
      }
    }
    return this.params.data[this.params.key];
  }

  /**
   * To display the column text for second pinned row
   * @author Dhaval Patel
   * @returns display text
   */
  public getSecondPinRowText(): string {
    if (this.params.displayType === 'Percentage') {
      return this.calcGridTotal();
    } else if (this.params.displayType === 'Number' || this.params.displayType === 'Currency') {
      const rowData = AgCustomRowUtil.getRowData(this.params.api);
      const childRows = AgCustomRowUtil.getChildRows(rowData);
      const sum = AgCustomRowUtil.sumFieldValues(childRows, this.params.column.colId);
      return String(sum);
    }
    return this.params.data[this.params.key];
  }

  /**
   * To format the value of column
   * @author Dhaval Patel
   * @returns Formatted string
   */
  public formatText(displayText): string {
    if (this.params.displayType === 'Percentage') {
      let disText = displayText;
      if (disText !== 0) {
        disText = disText || '';
      }
      if (disText !== '') {
        let template = '.2-2';
        if (this.params.decimalPoints) {
          template = `.${this.params.decimalPoints}-${this.params.decimalPoints}`;
        }

        disText = `${this.decimalPipe.transform(parseFloat(disText), template)}% `;
        if (this.params.prefixText) {
          disText = `${this.params.prefixText(this.params.data)} ${disText}`;
        }
      }
      return disText;
    } else if (this.params.displayType === 'Currency') {
      let displayTextValue = displayText;
      if (this.params.allowReplaceDecimalSeparator && displayText) {
        displayTextValue = LocaleData.replaceLocalDecimalCharWithDot(displayText);
      }
      if (this.params.prefixText) {
        return `${this.params.prefixText(this.params.data)}${this.dataFormatterService.currency(displayTextValue, this.params.decimalPoints || 2)}`;
      } else {
        return this.dataFormatterService.currency(displayTextValue, this.params.decimalPoints || 2);
      }
    }
    return displayText;
  }

  /**
   * To calculate grid total
   * @author Dhaval Patel
   * @returns Total value
   */
  private calcGridTotal(): string {
    const rowData = AgCustomRowUtil.getRowData(this.params.api);
    const childRows = AgCustomRowUtil.getChildRows(rowData);
    let sum = 0;
    if (this.params.calcCellValue) {
      sum = this.params.calcCellValue(childRows, this.params.column.colId);
    }
    return String(sum);
  }

  /**
   * To initialize workspace value (SM-8081: Financial locking feature)
   * @author Dhaval Patel
   */
  calculate() {
    if (this.params.data[this.params.workspaceKey] !== this.params.data[this.params.key]) {
      this.params.data[this.params.undoKey] = true;
      this.params.data[this.params.backupKey] = this.params.data[this.params.key];
      this.params.setValue(this.params.data[this.params.workspaceKey]);
      this.refresh(this.params);
    }
  }

  /**
   * To undo workspace value (SM-8081: Financial locking feature)
   * @author Dhaval Patel
   */
  undo() {
    this.params.data[this.params.undoKey] = false;
    this.params.data[this.params.key] = this.params.data[this.params.backupKey];
    this.params.setValue(this.params.data[this.params.backupKey]);
    this.refresh(this.params);
  }
}
