import { DataShareService } from '../../core/services/data-share.service';
import { BudgetBricSelectedDataModel, InitialConfigModel, UiControl } from '../../models';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { UserModel } from '../../models/user';
import { LogHelperService, BrickBaseService, WorkspaceService } from '../../core/services';
import * as _ from 'lodash';
import { GLOBAL } from '../../core/utils/app.constant';
export class BudgetBase {

  /**
   * @description Holds product validations on volume
   * @type {any}
   * @memberof BudgetBase
   */
  productValidations: any = null;
  uiControl: UiControl;
  userBundle: any;
  /**
   * It contians initial config data.
   */
  public initialConfig: InitialConfigModel;

  /**
   * It contains budget data.
   */
  public budget: BudgetBricSelectedDataModel = {
    price: null,
    minPrice: null,
    maxPrice: null,
    sotFloor: null,
    sotCeiling: null,
    allocated: 0,
    tolerance: 0,
    allocateAllFrames: false,
    greenTolerance: null,
    orangeTolerance: null,
    status: null,
    boostedTarget: false,
    relative: false,
    weight: GLOBAL.defaultWeight,
    priority: GLOBAL.defaultPriority,
    isAllSelection: false,
    sotIncrement: null,
  };
  public previousValues: BudgetBricSelectedDataModel;

  /**
   * @description check pcm mode is on/off
   * @memberof BudgetBase
   */
  pcmMode: boolean;

  objectiveMode: boolean;

  /**
   * @description Enable disable based on user tokens
   * @type {UserModel}
   * @memberof BudgetBase
   */
  user: UserModel;

  resolveObject: any;

  readonly LESS_THAN_MIN_PRICE = 'budget.error.lessThanMinPrice';
  readonly GREATER_THAN_MAX_PRICE = 'budget.error.graterThanMaxPrice';

  /**
   * Creates an instance of BudgetBase.
   * @param {DataShareService} dataShareService Service to share data between compoenents.
   * @param {CurrencyPipe} currencyPipe Currency Pipe to format currency
   * @memberof BudgetBase
   */
  constructor(
    public dataShareService: DataShareService,
    public appHeaderService: AppHeaderService,
    public logHelperService: LogHelperService,
    public brickBaseService: BrickBaseService,
    public workSpaceService: WorkspaceService
  ) {
    this.pcmMode = this.appHeaderService.enabledPCM;
    this.objectiveMode = this.appHeaderService.objectiveMode;
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.user = this.dataShareService.getUserModel();
  }

  /**
   * @description Initialize the data.
   * @author Shivani Patel
   * @param {BudgetBricSelectedDataModel} selectedVal
   * @memberof BudgetBase
   */
  init(resolveObject): void {
    if (this.resolveObject.selectedValues && !this.resolveObject.selectedValues.hasOwnProperty('-99')) {
      this.resolveObject.selectedValues.impressions = this.resolveObject.selectedValues.impressions === 0 ? this.resolveObject.selectedValues.impressions = null : this.resolveObject.selectedValues.impressions;
      this.budget = { ...this.budget, ...this.resolveObject.selectedValues };
      if (this.resolveObject.sot) {
        this.budget.sotFloor = this.resolveObject.sot.sotFloor != undefined && this.resolveObject.sot.sotFloor != null ? parseFloat(this.resolveObject.sot.sotFloor.toString()) : this.budget.sotFloor;
        this.budget.sotCeiling = this.resolveObject.sot.sotCeiling != undefined && this.resolveObject.sot.sotCeiling != null ? parseFloat(this.resolveObject.sot.sotCeiling.toString()) : this.budget.sotCeiling;
        this.budget.sotIncrement = this.resolveObject.sot.sotIncrement != undefined && this.resolveObject.sot.sotIncrement != null ? this.resolveObject.sot.sotIncrement : this.budget.sotIncrement;
      }
      if (this.resolveObject.selectedValues.allocated) {
        this.budget.allocated = Number(Number(this.resolveObject.selectedValues.allocated).toFixed(2));
      }
      this.budget.price = this.resolveObject.selectedValues.price ? Number(Number(this.resolveObject.selectedValues.price).toFixed(2)) : this.budget.allocated;
      this.budget.boostedTarget = this.resolveObject.selectedValues.boostedTarget || false;
      this.budget.relative = this.resolveObject.selectedValues.relative || false;
    } else {
      if (this.resolveObject.selectedValues && this.resolveObject.sot) {
        this.budget.sotFloor = this.resolveObject.sot.sotFloor != undefined && this.resolveObject.sot.sotFloor != null ? parseFloat(this.resolveObject.sot.sotFloor.toString()) : this.budget.sotFloor;
        this.budget.sotCeiling = this.resolveObject.sot.sotCeiling != undefined && this.resolveObject.sot.sotCeiling != null ? parseFloat(this.resolveObject.sot.sotCeiling.toString()) : this.budget.sotCeiling;
        this.budget.sotIncrement = this.resolveObject.sot.sotIncrement != undefined && this.resolveObject.sot.sotIncrement != null ? this.resolveObject.sot.sotIncrement : this.budget.sotIncrement;
      }
      this.budget.price = 0;
      this.budget.boostedTarget = false;
      this.budget.relative = false;
      this.resolveObject.selectedValues = null;
    }

    // Below code to show info tooltip in case of product
    if (this.resolveObject.productValidations && !this.pcmMode &&
      !(Object.keys(this.resolveObject.productValidations).length === 1 && this.resolveObject.productValidations.hasOwnProperty('allocateAllFrames'))) {
      this.productValidations = this.resolveObject.productValidations;
    }
  }

  /**
   * @description Validate SOT (Floor value should be equal or less than Ceiling value.
   * @author Shivani Patel
   * @returns {boolean}
   * @memberof BudgetBase
   */
  validateSOTAgainstRange(): boolean {
    if (this.initialConfig.uiControl.volumeRangeValidationEnabled &&
      this.budget.sotFloor && this.budget.sotCeiling && this.budget.sotFloor > this.budget.sotCeiling) {
      return false;
    }
    return true;
  }

  /**
   * @description Validate Modal data
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof BudgetBase
   */
  validateBudgetInputs(): boolean {

    if (!this.generalValidations()) {
      return false;
    }

    if(this.pcmMode && this.budget.sotIncrement == 0){
      this.logHelperService.logError(this.userBundle['sot.error.incrementGTZero'] || 'Increment must be greater than zero!');
      return false;
    }

    if (((!this.pcmMode && this.productValidations) || this.pcmMode) &&
      ((this.budget.sotIncrement && ((this.budget.sotCeiling != 0 && !this.budget.sotCeiling) || (this.budget.sotFloor != 0 && !this.budget.sotFloor))) ||
        (this.budget.sotIncrement && this.budget.sotFloor != 0 && this.budget.sotFloor % this.budget.sotIncrement != 0) ||
        (this.budget.sotIncrement && this.budget.sotCeiling % this.budget.sotIncrement != 0))) {
      this.logHelperService.logError(this.userBundle['sot.error.floorCeilingMultipleOfIncrement']);
      return false;
    }

    if (this.pcmMode) {
      if (!this.pcmModeValidations()) {
        return false;
      }
    }

    if (!this.pcmMode && this.productValidations) {
      if (!this.nonPCMModeValidations()) {
        return false;
      }
    }
    return true;
  }

  /**
   * @description Common validations
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof BudgetBase
   */
  generalValidations = () => {
    if (!this.pcmMode && (!this.budget.price || Number(this.budget.price) === 0)) {
      this.logHelperService.logError(this.userBundle['common.error.invalidValue']);
      return false;
    }

    if (this.pcmMode &&
      ((!this.budget.price || this.budget.price === 0) && !this.budget.minPrice && !this.budget.maxPrice && !this.budget.sotFloor && !this.budget.sotCeiling)) {
      this.logHelperService.logError(this.userBundle['common.error.invalidValue']);
      return false;
    }

    if (!_.isUndefined(this.uiControl.volumeRangeValidationEnabled)
      && this.uiControl.volumeRangeValidationEnabled) {
      const floor = this.budget.sotFloor ? this.budget.sotFloor : null;
      const ceil = this.budget.sotCeiling ? this.budget.sotCeiling : null;

      if (floor && ceil) {
        if (floor > ceil) {
          this.logHelperService.logError(this.userBundle['sot.error.graterThanCeil']);
          return false;
        } else if (ceil < floor) {
          this.logHelperService.logError(this.userBundle['sot.error.lessThanFloor']);
          return false;
        }
      }
    }
    return true;
  }

  /**
   * @description PCM Mode validations
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof BudgetBase
   */
  pcmModeValidations = () => {
    const minPrice = this.budget.minPrice ? this.budget.minPrice : null;
    const maxPrice = this.budget.maxPrice ? this.budget.maxPrice : null;
    const price = this.budget.price ? this.budget.price : null;

    if (minPrice && maxPrice) {
      if (minPrice > maxPrice) {
        this.logHelperService.logError(this.userBundle['budget.error.graterThanMax']);
        return false;
      } else if (maxPrice < minPrice) {
        this.logHelperService.logError(this.userBundle['budget.error.lessThanMin']);
        return false;
      } else if (price && price < minPrice) {
        this.logHelperService.logError(this.userBundle[this.LESS_THAN_MIN_PRICE]);
        return false;
      } else if (price && price > maxPrice) {
        this.logHelperService.logError(this.userBundle[this.GREATER_THAN_MAX_PRICE]);
        return false;
      }
    } else if (price && minPrice) {
      if (price < minPrice) {
        this.logHelperService.logError(this.userBundle[this.LESS_THAN_MIN_PRICE]);
        return false;
      }
    } else if (price && maxPrice && price > maxPrice) {
      this.logHelperService.logError(this.userBundle[this.GREATER_THAN_MAX_PRICE]);
      return false;
    }
    return true;
  }

  /**
   * @description Validations for non pcm mode
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof BudgetBase
   */
  nonPCMModeValidations = () => {
    const sotFloor = this.productValidations.sotFloor ? this.productValidations.sotFloor : null;
    const sotCeiling = this.productValidations.sotCeiling ? this.productValidations.sotCeiling : null;
    const minPrice = this.productValidations.minPrice ? this.productValidations.minPrice : null;
    const maxPrice = this.productValidations.maxPrice ? this.productValidations.maxPrice : null;
    const price = this.budget.price ? this.budget.price : null;

    if (sotFloor && sotCeiling) {
      if (this.budget.sotFloor < sotFloor) {
        this.logHelperService.logError(this.userBundle['budget.error.sotFloor']);
        return false;
      } else if (!this.budget.sotCeiling || this.budget.sotCeiling > sotCeiling) {
        this.logHelperService.logError(this.userBundle['budget.error.sotCeiling']);
        return false;
      }
    } else if (sotFloor) {
      if (this.budget.sotFloor < sotFloor) {
        this.logHelperService.logError(this.userBundle['budget.error.sotFloor']);
        return false;
      }
    } else if (sotCeiling && this.budget.sotCeiling > sotCeiling) {
      this.logHelperService.logError(this.userBundle['budget.error.sotCeiling']);
      return false;
    }

    if (price && minPrice && price < minPrice) {
      this.logHelperService.logError(this.userBundle[this.LESS_THAN_MIN_PRICE]);
      return false;
    }

    if (price && maxPrice && price > maxPrice) {
      this.logHelperService.logError(this.userBundle['budget.error.graterThanMaxPrice']);
      return false;
    }

    if (this.budget.greenTolerance !== null
      && this.budget.orangeTolerance !== null
      && this.budget.greenTolerance > this.budget.orangeTolerance) {
      this.logHelperService.logError(this.userBundle['workspace.error.tolerance.orangeMustBeGreaterOrEqualToGreen'] || 'Orange Tolerance must be greater than or equal to Green Tolerance');
      return false;
    }

    return true;
  }

}
