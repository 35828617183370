import { Injectable } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { BrickBaseService } from '../core/services/brick-base.service';
import { DataShareService } from '../core/services/data-share.service';
import { ConcertinaDataService } from '../core/components/concertina/concertina-data.service';
import { FrameStatus, editablePeriodId } from './status.enum';
import { GeoMapService } from './geo-map.service';
import {
  FrameModel, SaveFrameResponseModel, ResponseModel,
  CampaignSummaryModel, SaveFrameRequestModel, FurnitureModel, UpdateBasketRequestModel
} from '../models/index';
import { AppNameEnum } from '../core/enum';
import { LocaleData } from '../core/utils/LocaleData';
import * as _ from 'lodash';
import * as moment from 'moment';
import { getFormData } from '../core/utils/formData';
import { GLOBAL } from '../core/utils/app.constant';

@Injectable()
export class CartService {

  /**
   * frames saved for cart
   * @type {Array<FrameModel>}@memberof CartService
   */
  cartData: FrameModel[] = [];

  /**
   * cart data source observable subject
   * @private
   * @memberof GeoMapService
   */
  private cartDataSource: BehaviorSubject<FrameModel[]> = new BehaviorSubject(this.cartData);

  /**
   * cart Data observable
   * @memberof GeoMapService
   */
  public cartData$: Observable<FrameModel[]> = this.cartDataSource.asObservable();

  /**
   * frames saved for cart
   * @type {CampaignSummaryModel}@memberof CartService
   */
  cartSummary: CampaignSummaryModel = {};

  /**
   * cart data source observable subject
   * @private
   * @memberof GeoMapService
   */
  private cartSummarySource: BehaviorSubject<CampaignSummaryModel> = new BehaviorSubject(this.cartSummary);

  /**
   * cart Data observable
   * @memberof GeoMapService
   */
  public cartSummary$: Observable<CampaignSummaryModel> = this.cartSummarySource.asObservable();

  /**
   * Request JSON used to load data
   * @type {any}@memberof CartService
   */
  requestJSON: any = [];

  /**
   * is viewing cart data on map
   */
  private isViewCartOnMap = false;

  /**
   * viewing cart data on map source observable subject
   * @private
   * @memberof GeoMapService
   */
  private isViewCartOnMapSource: BehaviorSubject<boolean> = new BehaviorSubject(this.isViewCartOnMap);

  /**
   * cart Data observable
   * @memberof GeoMapService
   */
  public isViewCartOnMap$: Observable<boolean> = this.isViewCartOnMapSource.asObservable();

  private startDate: string;
  public _Frames = [];
  private endDate: string;
  public startDateReq: string;
  public endDateReq: string;

  public requestFrameTimings: [string, string][] = [];

  public concertinaGrouping: string[];

  readonly DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
  public indoorMaps: any[];
  constructor(
    private brickBaseService: BrickBaseService,
    private dataShareService: DataShareService,
    private geoMapService: GeoMapService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private concertinaDataService: ConcertinaDataService,
    // private requestJsonService: RequestJsonService
  ) { }

  setCartSummary(summary: CampaignSummaryModel): void {
    this.cartSummary = summary;
    this.cartSummarySource.next(this.cartSummary);
  }
  /**
   * Set Cart Data
   * @param cartData - Cart Data
   */
  setCartData(data?: FrameModel[]): void {
    if (data) {
      this.cartData = data;
    }
    this.cartData = _.cloneDeep(this.cartData);
    this.cartDataSource.next(this.cartData);
  }

  /**
   * Set Request JSON
   * @param cartData - Cart Data
   */
  setRequestJSON(data: any): void {
    this.requestJSON = data;
    this.startDate = this.getInchargeRequestDate('startDate', true);
    this.endDate = this.getInchargeRequestDate('endDate', true);
  }

  /**
   * Set Request JSON
   * @param cartData - Cart Data
   */
  setRequestFrameTimings(data: [string, string][]): void {
    this.requestFrameTimings = data;
  }

  setIsViewCartOnMap(val: boolean): void {
    this.isViewCartOnMap = val;
    this.isViewCartOnMapSource.next(this.isViewCartOnMap);
  }

  /**
   * is frame exist in cart for selected date range(incharge)
   * @param {number} frameId - id of frame
   * @returns {boolean} - true if exist or false
   * @memberof CartService
   */
  isFrameInCart(frameId: number): boolean {
    if (this.isViewCartOnMap) {
      return true;
    }
    const data = this.cartData.filter((asset: FrameModel) => {
      return (asset.frameId === frameId && asset.startDate === this.startDate && asset.endDate === this.endDate);
    });
    return (data.length > 0);
  }

  /**
   * @description Checks whether frame is in cart for the selected time period
   * @author Amit Mahida
   * @param {number} frameId
   * @param {Date} startDate
   * @param {Date} endDate
   * @returns {boolean}
   * @memberof CartService
   */
  isFrameInCartForVPForPeriod(frameId: number, startDate: Date, endDate: Date) {
    const data = this.cartData.filter((asset: FrameModel) => {
      const sdateToCompare = moment(asset.startDateReq).format(this.DATE_FORMAT);
      const edateToCompare = moment(asset.endDateReq).format(this.DATE_FORMAT);
      const sdate = moment(startDate).format(this.DATE_FORMAT);
      const edate = moment(endDate).format(this.DATE_FORMAT);
      return (asset.frameId === frameId &&
        moment(sdate).isSameOrAfter(sdateToCompare)
        && moment(edate).isSameOrBefore(edateToCompare)
      );
    });
    return (data.length > 0);
  }

  /**
   * is frame exist and selected in cart summary
   * @param frameId id of frame
   * @returns { Boolean } - true if frame is selected in cart summary else false
   */
  isFrameSelectedInCart(frameId: number): boolean {
    const data = this.cartData.filter((asset: FrameModel) => {
      return (asset.frameId === frameId && asset.selected_cart);
    });
    return (data.length > 0);
  }

  /**
   * is frame locked ie. if its running campaign or past frame it is not allow to delete
   * @param {number} frameId - id of frame
   * @returns {boolean} - true if exist or false
   * @memberof CartService
   */
  isFrameDeletable(frameId: number): boolean {
    let isDeletable = true;
    if (this.isViewCartOnMap) {
      const data = this.cartData.filter((asset: FrameModel) => {
        return (asset.frameId === frameId);
      });
      if (data.length > 0) {
        for (const d of data) {
          if (d.editablePeriodId === editablePeriodId.past || d.editablePeriodId === editablePeriodId.running) {
            isDeletable = false;
          }
        }
      }
    } else {
      const data: FrameModel[] = this.cartData.filter((asset: FrameModel) => {
        return (asset.frameId === frameId && asset.startDate === this.startDate && asset.endDate === this.endDate);
      });
      if (data.length > 0) {
        isDeletable = data[0].editablePeriodId === editablePeriodId.future;
      }
    }
    return isDeletable;
  }

  /**
   * get frame from cart for passed date range(incharge)
   * @param {any} frameId - id of frame
   * @param {string} startDate - startDate of frame
   * @param {endDate} endDate - endDate of frame
   * @returns {Array<FrameModel>} - array of frames
   * @memberof CartService
   */
  getFrameFromCart(frameId: number, startDate?: string, endDate?: string): FrameModel[] {
    const sdate = startDate || this.startDate;
    const edate = endDate || this.endDate;
    return this.cartData.filter((asset: FrameModel) => {
      return (asset.frameId === frameId && asset.startDate === sdate && asset.endDate === edate);
    });
  }

  /**
   * gives the provided key data from incharge filter selected object
   * @param {string} key - it can be startDate or endDate
   * @param {boolean} inDisplayFormat - true need date to display else for send date in request format
   * @returns {string} - date in requested format
   * @memberof CartService
   */
  getInchargeRequestDate(key: string, inDisplayFormat: boolean): string {
    let inchargeRangeSelection = {};
    const inchargeReqText = this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Incharge];
    for (const req of this.requestJSON) {
      if (req[inchargeReqText]) {
        inchargeRangeSelection = req;
      }
    }
    if (inchargeRangeSelection[inchargeReqText]) {
      if (inDisplayFormat) {
        /** Issue for US, not returning selected date */
        return this.datePipe.transform(inchargeRangeSelection[inchargeReqText][key], LocaleData.displayDateFormat);
      } else {
        return this.datePipe.transform(inchargeRangeSelection[inchargeReqText][key], GLOBAL.DATE_PARSE_FORMAT);
      }
    } else {
      return '';
    }
  }

  /**
   * gives the SOT data from request json
   * @returns {string} - selected SOT or "" if nothing selected
   * @memberof CartService
   */
  getRequestSOT() {
    let sotSelection = {};
    const sotReqText = this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.SOT];
    for (const req of this.requestJSON) {
      if (req[sotReqText]) {
        sotSelection = req;
      }
    }
    if (sotSelection[sotReqText]) {
      return parseFloat(sotSelection[sotReqText].sot);
    } else {
      return '';
    }
  }

  /**
   * Add frame in cart
   * @param {any} frame
   * @memberof CartService
   */
  addFrame(frame: FrameModel, isTriggerObservable = true) {
    frame.startDate = this.startDate;
    frame.endDate = this.endDate;
    frame.startDateReq = this.startDateReq;
    frame.endDateReq = this.endDateReq;
    frame.requestFrameTimings = this.requestFrameTimings;
    this._Frames.push(frame);
  }
  _addCall(isTriggerObservable = true) {
  this.cartData = this._Frames;
    if (isTriggerObservable) {
      this.setCartData();
    }
  }
  /**
   * Add frame in cart
   * @param {any} frame
   * @memberof CartService
   */
  addFrameVP(frame: any, startDate: string, endDate: string) {
    frame.startDate = startDate;
    frame.endDate = endDate;
    frame.startDateReq = startDate;
    frame.endDateReq = endDate;
    this.cartData.push(frame);
  }

  /**
   * Remove frame from cart
   * @param {any} frameId - id of frame
   * @param {string} [startDate] - start date associated with frame. if not specified will use from request json
   * @param {string} [endDate] - end date associated with frame. if not specified will use from request json
   * @memberof CartService
   */
  removeFrame(frameId: number, startDate?: string, endDate?: string, isTriggerObservable = true) {
    const sdate = startDate || this.startDate;
    const edate = endDate || this.endDate;
    if (this.isViewCartOnMap && (!startDate) && (!endDate)) {
      this._Frames= this.cartData.filter((asset) => {
        return (asset.frameId !== frameId);
      });
    } else {
      this._Frames = this.cartData.filter((asset) => {
        return (!(asset.frameId === frameId && asset.startDate === sdate && asset.endDate === edate));
      });
    }
    this.cartData = this._Frames;
    if (isTriggerObservable) {
      this.setCartData();
    }
  }

  /**
   * get the number of frame selected in cart summary
   * @param {Array<FrameModel>} [tempCartdata]
   * @returns {number}
   * @memberof CartService
   */
  getSelectedFrameCount(tempCartdata?: FrameModel[]): number {
    const cartData = tempCartdata ? tempCartdata : this.cartData;
    const data: FrameModel[] = cartData.filter((asset) => {
      return (asset.selected_cart);
    });
    return data.length;
  }

  /**
   * get the cart data object in format required to send to server
   * @returns
   * @memberof CartService
   */
  getSaveFrameReqData(): UpdateBasketRequestModel {
    const reqData: UpdateBasketRequestModel = {};
    const grpByFrame = _.groupBy(this.cartData, 'frameId');
    const grpByFrameKey = Object.keys(grpByFrame);
    for (const frameId of grpByFrameKey) {
      const frames = grpByFrame[frameId];
      reqData[frameId] = [];
      for (const frame of frames) {
        if (frame.requestFrameTimings && frame.requestFrameTimings.length > 0) {
          reqData[frameId].push(...frame.requestFrameTimings);
        } else {
          reqData[frameId].push([frame.startDateReq, frame.endDateReq]);
        }
      }
    }
    return reqData;
  }

  getSelectedFrameDeleteReqData(): SaveFrameRequestModel[] {
    const reqData: SaveFrameRequestModel[] = [];
    for (const curFrame of this.cartData) {
      if (curFrame.selected_cart) {
        const obj: SaveFrameRequestModel[] = reqData.filter((reqObj) => {
          return (reqObj.startDate === curFrame.startDateReq && reqObj.endDate === curFrame.endDateReq);
        });
        if (obj.length > 0) {
          obj[0].frames.push(curFrame.frameId);
        } else {
          reqData.push({
            startDate: curFrame.startDateReq,
            endDate: curFrame.endDateReq,
            frames: [curFrame.frameId]
          });
        }
      }
    }
    return reqData;
  }

  getSaveFrameReqDataForVP(data, type: string) {
    const reqData = {};
    const keys = Object.keys(data);
    for (const element of keys) {
      switch (type) {
        case 'groupingData':
          if (element === 'y' || element.indexOf(GLOBAL.CONCERTINA_SEPARATOR) > -1) {
            reqData[element] = data[element];
          }
          break;
        case 'frameData':
          if (element !== 'y' && element.indexOf(GLOBAL.CONCERTINA_SEPARATOR) === -1) {
            reqData[element] = data[element];
          }
          break;
        default:
          break;
      }
    }
    return JSON.stringify(reqData);
  }

  /**
   * Remove selected frames from cart
   * @memberof CartService
   */
  removeSelectedFrame(): void {
    this.cartData = this.cartData.filter((asset) => {
      return (!(asset.selected_cart));
    });
  }

  /**
   * get selected frame list
   * @memberof CartService
   */
  getSelectedFrames(): FrameModel[] {
    return this.cartData.filter((asset) => {
      return (asset.selected_cart);
    });
  }

  /**
   * http call to Save map data
   * @returns {Observable<any[]>}
   * @memberof CartService
   */
  saveFrame(): Observable<ResponseModel<SaveFrameResponseModel>> {
    let saveFrameURL: string = this.dataShareService.getServiceCallUrlByKey('UPDATE_BASKET');
    if (this.dataShareService.appName === AppNameEnum.visualplanner) {
      saveFrameURL = this.dataShareService.getServiceCallUrlByKey('VP_UPDATE_BASKET');
    }
    const data: any = this.getSaveFrameReqData();
    const isVP: boolean = this.dataShareService.appName === AppNameEnum.visualplanner;
    const param = {
      action: 'updateBasket',
      concertinaGrouping: isVP ? JSON.stringify(this.concertinaGrouping) : null,
      groupingData: isVP ? this.getSaveFrameReqDataForVP(data, 'groupingData') : null,
      frameData: isVP ? this.getSaveFrameReqDataForVP(data, 'frameData') : null,
      basketMode: isVP && this.concertinaDataService.basketMode,
      data: isVP ? null : JSON.stringify(data)
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const requestOptions = {
      headers,
      body: getFormData(param),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      saveFrameURL,
      requestOptions
    ).map((response: ResponseModel<SaveFrameResponseModel>) => {
      return this.successCallBackOfSaveFrame(response);
    });
  }

  successCallBackOfSaveFrame(response: any): any {
    if (response.status === 'OK') {
      let cartSummary = {};
      if (response.data && response.data.campaignSummary) {
        cartSummary = response.data.campaignSummary[0];
        this.concertinaDataService.filterCounts = response.data.filterCounts;
        this.concertinaDataService.$framesCount.next(this.concertinaDataService.filterCounts);
      }
      if (response.data && response.data.indoorMaps) {
        this.setIndoorMaps(response.data.indoorMaps);
      } else {
        this.setIndoorMaps([]);
      }
      this.setCartSummary(cartSummary);
      if (response.data && response.data.basketData && response.data.basketData.length) {
        this.rebuildCartData(response.data.basketData);
      } else {
        this.rebuildCartData([]);
      }
      if (this.isViewCartOnMap) {
        this.loadCartDataonMap();
      }
    }
    return response;
  }

  rebuildCartData(basketData: FrameModel[]): void {
    const tempCartData: FrameModel[] = [];
    for (const frame of basketData) {
      frame.startDateReq = frame.startDate;
      frame.endDateReq = frame.endDate;
      frame.startDate = this.datePipe.transform(frame.startDateReq, LocaleData.displayDateFormat);
      frame.endDate = this.datePipe.transform(frame.endDateReq, LocaleData.displayDateFormat);
      frame.channelId = frame.legendId; // this is used to restrist allowed channel in filter area component
      if (frame.past) {
        frame.editablePeriodId = editablePeriodId.past;
      } else if (frame.splitFromDate) {
        frame.editablePeriodId = editablePeriodId.running;
      } else {
        frame.editablePeriodId = editablePeriodId.future;
      }
      frame.isDeletable = frame.editablePeriodId === editablePeriodId.future;
      frame.sot = this.decimalPipe.transform(frame.sot, '.2-2');
      tempCartData.push(frame);
    }
    // backend will send basket data in frameName and startDate order
    // no need to do ording in UI
    // tempCartData.sort(function (a, b) {
    //   var x = String(a.frameName).toLowerCase();
    //   var y = String(b.frameName).toLowerCase();
    //   if (x < y) { return -1; }
    //   if (x > y) { return 1; }
    //   var p = new Date(a.startDateReq);
    //   var q = new Date(b.startDateReq);
    //   if (p < q) { return -1; }
    //   if (p > q) { return 1; }
    //   return 0;
    // });
    this.setCartData(tempCartData);
  }

  /**
   * http call to Save map data
   * @returns {Observable<any[]>}
   * @memberof CartService
   */
  getBasket(): Promise<ResponseModel<SaveFrameResponseModel>> {
    const getBasketURL: string = this.dataShareService.getServiceCallUrlByKey('GET_BASKET');
    const params = {
      action: 'getBasket'
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const requestOptions = {
      headers,
      body: getFormData(params),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      getBasketURL,
      requestOptions
    )
      .map((response: ResponseModel<SaveFrameResponseModel>) => {
        return response;
      }).toPromise();
  }

  /**
   * To get the cart data from map
   * @returns FurnitureModel[] - collection of furniture
   */
  getCartDataForMap(): FurnitureModel[] {
    const assetData: FurnitureModel[] = [];
    const grpByFur = _.groupBy(this.cartData, 'furnitureId');
    const grpByFurKey = Object.keys(grpByFur);
    grpByFurKey.forEach(furnitureId => {
      const frameInFur = grpByFur[furnitureId];
      const grpByFrame = _.groupBy(frameInFur, 'frameId');
      const grpByFrameKey = Object.keys(grpByFrame);
      const furnitureObj = this.populateFurnitureObject(frameInFur, grpByFrame, grpByFrameKey);
      assetData.push(furnitureObj);
    });
    return assetData;
  }

  populateFurnitureObject(frameInFur, grpByFrame, grpByFrameKey) {
    const furnitureObj: FurnitureModel = {
      furnitureId: frameInFur[0].furnitureId,
      furnitureName: frameInFur[0].furnitureName,
      furnitureFullName: frameInFur[0].furnitureFullName,
      latitude: frameInFur[0].latitude,
      longitude: frameInFur[0].longitude,
      address: frameInFur[0].address,
      gmIconId: frameInFur[0].gmIconId,
      legendId: frameInFur[0].legendId,
      galleryUrl: frameInFur[0].galleryUrl,
      frames: [],
      iconImage: frameInFur[0].iconImage,
      iconImageId: frameInFur[0].iconImageId,
      iconOrientation: frameInFur[0].iconOrientation,
      height: frameInFur[0].height,
      width: frameInFur[0].width,
      subFamilyName: frameInFur[0].subFamilyName,
      mapId: frameInFur[0].mapId,
      disabled: false,
      familyId: frameInFur[0].familyId ? frameInFur[0].familyId : -1
    };
    grpByFrameKey.forEach(frameId => {
      const frames = grpByFrame[frameId];
      for (const frame of frames) {
        frame.status = FrameStatus.available;
        if (frame.unavailabilityColor && frame.unavailabilityColor !== '') {
          frame.status = FrameStatus.unavailable;
        }
        if (frames.indexOf(frame) === 0) {
          furnitureObj.frames.push(frame);
        } else {
          const lastFrame = furnitureObj.frames[furnitureObj.frames.length - 1];
          if (lastFrame.status === FrameStatus.available) {
            lastFrame.status = frame.status;
          }
          lastFrame.price += frame.price;
          furnitureObj.frames[furnitureObj.frames.length - 1] = lastFrame;
        }
      }
    });
    return furnitureObj;

  }

  /**
   * This method is used to load the cart data on map
   */
  loadCartDataonMap(): void {
    this.geoMapService.setCartDataForMap(this.getCartDataForMap(), this.indoorMaps);
  }

  /**
   * This method used to initialize indoor map list
   */
  setIndoorMaps(indoorMaps: any[] = []) {
    this.indoorMaps = indoorMaps;
  }

}
