<app-sb-modal-wrapper [title]="selectedTitle" [titleBackgroundColor]="brickBgColor" (closed)="onModalClosed($event)"
    (saved)="onModalSaved($event)">
    <div class="tabs_container horizontal">
        <div class="control_group" [hidden]="!resolveObject.overrideProposalExpiryDate">
            <label for="">{{userBundle['workspace.incharge.bric.range.startDate']}}: </label>
            <!-- <input type="text" class="form_control" name="startdate" #startDate
                [ngModel]="rangeModal.startDate | date:displayDateFormat" ui-date="dateOptionsFrom" readonly> -->
            <input type="text" class="form_control" name="startdate" #startDate="ngbDatepicker" [container]="'body'"
                [(ngModel)]="campaignSave.newValidFromDate" [dayTemplate]="startDayTemplate"
                [markDisabled]="markStartDayDisabled" ngbDatepicker (click)="openDatePicker($event, startDate, endDate)"
                [outsideDays]="'collapsed'" (document:click)="closeDatePickerOutsideClick($event, startDate)" readonly>
        </div>
        <div class="control_group" [hidden]="!resolveObject.overrideProposalExpiryDate">
            <label for="">{{userBundle['workspace.incharge.bric.range.endDate']}}: </label>
            <!-- <input type="text" class="form_control" name="enddate" [disabled]="disableEndDate" #endDate
                [ngModel]="rangeModal.endDate | date:displayDateFormat" ui-date="dateOptionsTo" readonly> -->
            <input type="text" class="form_control" name="enddate" #endDate="ngbDatepicker" [container]="'body'"
                [(ngModel)]="campaignSave.newValidToDate" [dayTemplate]="endDayTemplate" [minDate]="minnewValidToDate"
                [markDisabled]="markEndDayDisabled" ngbDatepicker (click)="openDatePicker($event, endDate, startDate)"
                [outsideDays]="'collapsed'" (document:click)="closeDatePickerOutsideClick($event, endDate)" readonly>
        </div>

        <div class="control_group" *ngIf="!hideTitle">
            <label>{{userBundle['result.campaignTitle']}} :</label>
            <input type="text" class="form_control" [(ngModel)]="campaignSave.campaignName" />
        </div>

        <div *ngIf="uiControl.CCPHideFinanceEnabled" class="control_group CCPFinance">
            <label for="CCPFinance" class="small">
                <input type="checkbox" id="CCPFinance" class="checkbox small" [(ngModel)]="campaignSave.hideFinance"
                    value="campaignSave.hideFinance" [disabled]="!userModel.commercialTabAccess.allowHideFinancialCCPEdit" />
                <span>{{userBundle['common.CCPHideFinance']}}</span>
            </label>
        </div>
        <div *ngIf="uiControl.enableTouchedPOIFeature"  class="control_group CCPFinance">
            <label class="small">
                <input type="checkbox" id="showTouchedPOIsOnly" class="checkbox small" [(ngModel)]="campaignSave.showTouchedPOIOnly"
                    value="campaignSave.showTouchedPOIOnly" />
                <span>{{userBundle['common.CCPshowTouchedPOIOnlyDefaultON'] || 'Show Touched POI ONLY default ON?'}}</span>
            </label>
        </div>

        <div *ngIf="uiControl.enableTouchedPOIFeature" class="control_group CCPFinance">
            <label class="small">
                <input type="checkbox" id="editTouchedPOIs" class="checkbox small" [(ngModel)]="campaignSave.allowEditToTouchedPOIs"
                    value="campaignSave.allowEditToTouchedPOIs" />
                <span>{{userBundle['common.CCPallowEditToTouchedPOIsToggle'] || 'Allow Edit to Touched POI toggle?'}}</span>
            </label>
        </div>
        <div *ngIf="uiControl.CCPHideImpressionEnabled" class="control_group CCPImpression">
            <label for="CCPImpression" class="small">
                <input type="checkbox" id="CCPImpression" class="checkbox small"
                    [(ngModel)]="campaignSave.hideImpression" value="campaignSave.hideImpression"
                    [disabled]="!userModel.commercialTabAccess.allowHideImpressionCCPEdit" />
                <span>{{userBundle['common.CCPHideImpressions']}}</span>
            </label>
        </div>

    </div>
</app-sb-modal-wrapper>

<ng-template #startDayTemplate let-date="date" let-currentMonth="currentMonth" let-selected="selected"
    let-disabled="disabled">
    <span class="day-container" [class.hidden]="date.month !== currentMonth"
        [class.text-muted]="getStartDayProp(date, 'isDisabled')" (click)="onSelectFrom(date)">
        <div class="custom-day" [class.today]="getStartDayProp(date, 'isToday')"
            [class.weekend]="getStartDayProp(date, 'isWeekday')" [class.selected]="selected">
            {{ date.day }}
        </div>
    </span>
</ng-template>
<ng-template #endDayTemplate let-date="date" let-currentMonth="currentMonth" let-selected="selected"
    let-disabled="disabled">
    <span class="day-container" [class.hidden]="date.month !== currentMonth"
        [class.text-muted]="getEndDayProp(date, disabled, 'isDisabled')">
        <div class="custom-day" [class.today]="getEndDayProp(date, disabled, 'isToday')"
            [class.weekend]="getEndDayProp(date, disabled, 'isWeekday')" [class.selected]="selected">
            {{ date.day }}
        </div>
    </span>
</ng-template>
