<div [ngSwitch]="lookupType">
  <div *ngSwitchCase="1" data-case="1">
    <ng-container *ngTemplateOutlet="firstAndFour"></ng-container>
  </div>
  <div *ngSwitchCase="2" data-case="2">
    <div class="scrolldiv">
      <div>
        <label class="small" *ngIf="selectAll">
          <input type="checkbox" name="allChecked" class="checkbox small" [(ngModel)]="allChecked"
            (change)="toggleAllCheckboxes(allChecked)">
          <span class="select_all">{{selectAllText}}</span>
        </label>

        <tag-input [(ngModel)]="dataModel" [identifyBy]="'id'" [displayBy]="'name'" [onlyFromAutocomplete]="true"
          [placeholder]="placeholder" [secondaryPlaceholder]="placeholder" [showDropdownIfEmpty]="true"
          class="float-left" *ngIf="!hideTagInput" (onAdd)="onAdd()" (onRemove)="onRemove()"
          [ngClass]="{'tag-input-full-width': hideFileUploader, 'tag-input-width': !hideFileUploader}"
          [inputClass]="'form-control'">
          <ng-template let-item="item" let-index="index">
          </ng-template>
          <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="dataList"
            [autocompleteObservable]="filterListByQuery" [identifyBy]="'id'" [displayBy]="'name'"
            [minimumTextLength]="0" [visibleMenuAfterItemAdd]="true" [maintainSearchText]="true" [appendToBody]="false">
          </tag-input-dropdown>
        </tag-input>
        <div class="float-left" *ngIf="!hideFileUploader && showFileUploader && !hideFileUploaderForArea"
          [ngClass]="{'tag-input-full-width': hideTagInput, 'file-upload': !hideTagInput}">
          <p class="or-text" *ngIf="!hideTagInput">or</p>
          <app-file-upload [multipleFiles]="true" [noOfFiles]="2" extension=".xls,.xlsx"
            (savefilelocally)="saveFilelocally($event)"></app-file-upload>
        </div>
      </div>
      <div class="summaryParent">
        <ul class="summaryContainer" [ngClass]="{ 'summaryContainerAutoHeight': isAutoHeight }" *ngIf="hideFileUploader">
          <li *ngFor="let item of dataModel; trackBy: trackByIndex; let i = index;">
            <label>{{item.name}}</label>
            <div class="actionIcons right">
              <span style="cursor:pointer;" (click)="removeItemById(i)"><em class="fa fa-times"
                  aria-hidden="true"></em></span>
              <div class="filter-lv3-label" [title]="initialConfig.userBundle['workspace.bric.relativeFilter']"
                *ngIf="isRelativeFilterVisible && (objectiveMode)">
                <label for="filter-lv3-{{selectionId}}-{{i}}">
                  <input type="checkbox" [(ngModel)]="item.relative" class="checkbox"
                    id="filter-lv3-{{selectionId}}-{{i}}" name="filter-lv3-{{selectionId}}-{{i}}"
                    [disabled]="disableRelative(item)" (change)="changeRelativeFilter(item)" />
                  <em class="relativeFilterPercentage">%</em>
                </label>
              </div>
            </div>
          </li>
        </ul>
        <ul class="summaryContainer" *ngIf="!hideFileUploader && showFileUploader">
          <li *ngFor="let file of fileList[selectionId]; trackBy: trackByIndex; let i = index;">
            <label style="width: 72% !important">
              {{((file.userSelectionName || file.name).length > 30) ? (file.userSelectionName ||
              file.name).substring(0, 30) + '...' : (file.userSelectionName || file.name)}}</label>
            <span style="width: 28%; float: right">
              <span>
                <input type="radio" [(ngModel)]="file.exclude" (ngModelChange)="fileStatusChanged($event, i)"
                  class="radio small include" name="file-include-{{i}}" [value]="false" id="file-include-{{i}}" />
                <label for="file-include-{{i}}">&nbsp;</label>
                <input type="radio" [(ngModel)]="file.exclude" (ngModelChange)="fileStatusChanged($event, i)"
                  class="radio small exclude" name="file-exclude-{{i}}" [value]="true" id="file-exclude-{{i}}" />
                <label for="file-exclude-{{i}}">&nbsp;</label>
              </span>
              <a class="download-btn" (click)="downloadListFile(file.userSelectionId, file.userSelectionName)"
                *ngIf="file.userSelectionId" title="Click to Download {{file.userSelectionName}}" target="_parent">
                <img alt="Download" [src]="'images/download.png'" />
              </a>
              <a class="download-btn" *ngIf="!file.userSelectionId">
                <img alt="Download" [src]="'images/download.png'" style="opacity: 0.3" />
              </a>
              <a class="right" (click)="removeFile(i)">
                <img alt="Delete" [src]="'images/sub_black_delete.png'" height="10px" width="10px" />
              </a>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="3" data-case="3">
    <div>
      <tag-input [(ngModel)]="dataModel" [identifyBy]="'lookupId'" [displayBy]="'lookupName'" class="float-left"
        *ngIf="!hideTagInput" [onlyFromAutocomplete]="true" [placeholder]="placeholder" (onAdd)="onAdd()"
        (onRemove)="onRemove()"
        [ngClass]="{'tag-input-full-width': hideFileUploader, 'tag-input-width': !hideFileUploader}"
        [secondaryPlaceholder]="placeholder" [showDropdownIfEmpty]="false" [inputClass]="'form-control'">
        <ng-template let-item="item" let-index="index">
        </ng-template>
        <tag-input-dropdown [showDropdownIfEmpty]="true" [identifyBy]="'lookupId'" [displayBy]="'lookupName'"
          [minimumTextLength]="1" [autocompleteObservable]="loadTagData" [visibleMenuAfterItemAdd]="true"
          [maintainSearchText]="true" [matchingFn]="routeMatchingFn" [appendToBody]="false">
        </tag-input-dropdown>
      </tag-input>
      <div class="float-left" *ngIf="!hideFileUploader && showFileUploader"
        [ngClass]="{'tag-input-full-width': hideTagInput, 'file-upload': !hideTagInput}">
        <p class="or-text" *ngIf="!hideTagInput">or</p>
        <app-file-upload [multipleFiles]="true" [noOfFiles]="2" extension=".xls,.xlsx"
          (savefilelocally)="saveFilelocally($event)"></app-file-upload>
      </div>
    </div>
    <div class="summaryParent">
      <ul class="summaryContainer" *ngIf="hideFileUploader">
        <li *ngFor="let item of dataModel; trackBy: trackByIndex; let i = index;">
          <label>{{item.lookupName}}</label>
          <div class="actionIcons right">
            <span style="cursor:pointer;" (click)="removeItemById(i)"><em class="fa fa-times"
                aria-hidden="true"></em></span>
            <div class="filter-lv3-label" [title]="initialConfig.userBundle['workspace.bric.relativeFilter']"
              *ngIf="isRelativeFilterVisible && objectiveMode">
              <label for="filter-lv3-{{selectionId}}-{{i}}">
                <input type="checkbox" [(ngModel)]="item.relative" class="checkbox"
                  id="filter-lv3-{{selectionId}}-{{i}}" name="filter-lv3-{{selectionId}}-{{i}}"
                  [disabled]="disableRelative(item)" (change)="changeRelativeFilter(item)" />
                <em class="relativeFilterPercentage">%</em>
              </label>
            </div>
          </div>
        </li>
      </ul>
      <ul class="summaryContainer" *ngIf="!hideFileUploader && showFileUploader">
        <li *ngFor="let file of fileList[selectionId]; trackBy: trackByIndex; let i = index;">
          <label style="width: 72% !important">
            {{((file.userSelectionName || file.name).length > 30) ? (file.userSelectionName ||
            file.name).substring(0, 30) + '...' : (file.userSelectionName || file.name)}}</label>
          <span style="width: 28%; float: right">
            <span>
              <input type="radio" [(ngModel)]="file.exclude" (ngModelChange)="fileStatusChanged($event, i)"
                class="radio small include" name="file-include-{{i}}" [value]="false" id="file-include-{{i}}" />
              <label for="file-include-{{i}}">&nbsp;</label>
              <input type="radio" [(ngModel)]="file.exclude" (ngModelChange)="fileStatusChanged($event, i)"
                class="radio small exclude" name="file-exclude-{{i}}" [value]="true" id="file-exclude-{{i}}" />
              <label for="file-exclude-{{i}}">&nbsp;</label>
            </span>
            <a class="download-btn" (click)="downloadListFile(file.userSelectionId, file.userSelectionName)"
              *ngIf="file.userSelectionId" title="Click to Download {{file.userSelectionName}}" target="_parent">
              <img alt="Download" [src]="'images/download.png'" />
            </a>
            <a class="download-btn" *ngIf="!file.userSelectionId">
              <img alt="Download" [src]="'images/download.png'" style="opacity: 0.3" />
            </a>
            <a class="right" (click)="removeFile(i)">
              <img alt="Delete" [src]="'images/sub_black_delete.png'" height="10px" width="10px" />
            </a>
          </span>
        </li>
      </ul>
    </div>
  </div>
  <div *ngSwitchCase="4">
    <ng-container *ngTemplateOutlet="firstAndFour"></ng-container>
  </div>
  <div *ngSwitchCase="5">
    <tag-input [(ngModel)]="dataModel" [identifyBy]="'routeFrameId'" [displayBy]="'routeFrameId'"
      [onlyFromAutocomplete]="true" [placeholder]="placeholder" [secondaryPlaceholder]="placeholder"
      [showDropdownIfEmpty]="false" [maintainSearchText]="true" [inputClass]="'form-control'">
      <ng-template let-item="item" let-index="index">
      </ng-template>
      <tag-input-dropdown [showDropdownIfEmpty]="true" [identifyBy]="'routeFrameId'" [displayBy]="'displayText'"
        [minimumTextLength]="1" [autocompleteObservable]="loadRouteData" [visibleMenuAfterItemAdd]="true"
        [matchingFn]="routeMatchingFn" [appendToBody]="false" [focusFirstElement]="true">
      </tag-input-dropdown>
    </tag-input>
    <ul #uiElement class="summaryContainer" (scroll)="onScrollList()">
      <li *ngFor="let item of dataModel; trackBy: trackByIndex; let i = index;">
        <label
          style="width:79% !important;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{item.routeFrameId}}</label>
        <span style="width: 20%; float: right">
          <input type="radio" [(ngModel)]="item.exclude" (change)="dataChange(item.routeFrameId)" class="radio small include"
            name="tag-{{i}}" [value]="false" id="include-tag-{{i}}" checked />
          <label for="include-tag-{{i}}">&nbsp;</label>
          <input type="radio" [(ngModel)]="item.exclude" (change)="dataChange(item.routeFrameId)" class="radio small exclude"
            name="tag-{{i}}" [value]="true" id="exclude-tag-{{i}}" />
          <label for="exclude-tag-{{i}}">&nbsp;</label>
          <span class="right" style="cursor:pointer;" (click)="removeItemById(i)"><em class="fa fa-times"
              aria-hidden="true"></em></span>
        </span>
      </li>
    </ul>
  </div>
  <div *ngSwitchDefault>
    <h3>In Progress</h3>
  </div>
</div>

<ng-template #firstAndFour>
  <div class="scrolldiv">
    <label class="small" *ngIf="selectAll">
      <input type="checkbox" name="allChecked" class="checkbox small" [(ngModel)]="allChecked"
        (change)="toggleAllCheckboxes(allChecked)">
      <span class="select_all">{{selectAllText}}</span>
    </label>
    <label *ngFor="let item of dataList; let i = index" class="small">
      <input type="checkbox" class="checkbox small" appChecklist [(checklist)]="dataModel" [checklistValue]="item" [checked]="isSelected(item)"
        (checklistChange)="checkSelectAll($event, item)" [disabled]="item.disabled">
      <span class="itemName">{{item.name}}</span>
      <div class="filter-lv3-label" [title]="initialConfig.userBundle['workspace.bric.relativeFilter']"
        *ngIf="isRelativeFilterVisible && objectiveMode">
        <label for="filter-lv3-{{selectionId}}-{{i}}">
          <input type="checkbox" [(ngModel)]="item.relative" class="checkbox" id="filter-lv3-{{selectionId}}-{{i}}"
            name="filter-lv3-{{selectionId}}-{{i}}" [disabled]="disableRelative(item)"
            (change)="changeRelativeFilter(item)" />
          <em class="relativeFilterPercentage">%</em>
        </label>
      </div>
    </label>
  </div>
</ng-template>
