import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '../../../app/core/services/state.service';
import { CampaignDetailsService } from '../campaign-details/campaign-details.service';
import { FreeOFChargeResult, HistoryROC } from '../commercial.model';
import { SystemFlags } from '../../models';
import { DataShareService, LogHelperService } from '../../core/services';
import { CommercialService } from '../../core/services/commercial.service';
import { CommercialButtonService } from '../commercial-buttons/commercial-buttons.service';
import { CampaignStatus } from '../../core/enum';
import { DatePipe } from '@angular/common';
import { GLOBAL } from '../../core/utils/app.constant';
import { CommercialBase } from '../commercial-base';
import { RequiredCampaignFields, RequiredBookingsFields, PopulateBookingDetailFields } from '../../models/RequiredFields';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-commercial-cnga',
  templateUrl: './commercial-cnga.component.html'
})
export class CommercialCngaComponent extends CommercialBase implements OnInit, OnDestroy {
  systemFlags = SystemFlags;

  /**
   * @description commercial data
   * @memberof CommercialCngaComponent
   */
  public commercialData;

  /**
   * @description Accordian for campaign details
   * @memberof CommercialCngaComponent
   */
  hideCampaignDetails: boolean;
  /**
   * @description Accordian for booking details
   * @memberof CommercialCngaComponent
   */
  hideBookingDetails: boolean;
  /**
   * @description Accordian for Discount Grid
   * @memberof CommercialCngaComponent
   */
  hideDiscountGrid: boolean;

  readOnly = false;
  /**
   * @description set bookingStatusList
   * @memberof CommercialCngaComponent
   */
  bookingStatusList = GLOBAL.bookingStatusList;

  /**
   * @description this will show the history of campaign updated in case of reason/cancel for change
   * @memberof CommercialCngaComponent
   */
  historyROC = new HistoryROC();

  private subscriptions: Subscription[] = [];
  constructor(
    stateService: StateService,
    campaignDetailsService: CampaignDetailsService,
    dataShareService: DataShareService,
    commercialService: CommercialService,
    commercialButtonService: CommercialButtonService,
    logHelperService: LogHelperService,
    datePipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(stateService, logHelperService, commercialButtonService, dataShareService, commercialService, datePipe, campaignDetailsService);
    this.subscriptions.push(this.dataShareService.languageChangedSub.subscribe((result) => {
      if (result) {
        this.ngOnInit();
        this.changeDetectorRef.detectChanges();
      }
    }));
  }

  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.setDefaultAgencySpecialistPerc();
    this.readOnly = SystemFlags.readOnly;
    this.restoreCommercialData();
  }

  /**
   * @description get commercial data
   * @author Alkesh Shah
   * @memberof CommercialCngaComponent
   */
  restoreCommercialData() {
    this.commercialConfig.user = this.dataShareService.getUserModel();
    this.getCommercialDataFromService();
  }

  /**
   * @description get commercial data from service
   * @author Alkesh Shah
   * @memberof CommercialCngaComponent
   */
  getCommercialDataFromService() {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
    };

    this.commercialService.loadCommercialCampaignDetails(param).subscribe((data) => {
      if (data.status === 'OK') {
        this.commercialConfig.campaignStatus = data.data.campaignStatusId;
        this.commercialConfig.bookingStatus = data.data.bookingStatus;
        this.commercialConfig.uiControl = data.data.uiControl;
        this.commercialData = data.data;

        this.commercialData.bookingStatusName =
          this.campaignDetailsService.getStatusNameFromId(data.data.campaignStatusId);
        const assignedToList = this.dataShareService.getInitialConfigByKey('assignedTo');
        this.campaignDetails = this.campaignDetailsService.populateCampaignDetails(
          this.commercialData, this.initialConfig, assignedToList);
        this.campaignDetailsService.calculateCPT(this.campaignDetails);
        this.historyROC = this.campaignDetailsService.populateHistoryROC(this.historyROC, data.data.auditReasonsHistoryMap, this.commercialConfig.uiControl.historyROC);
      }
    });

  }

  /**
   * @description this is output event from commercialbutton when click on update/option/book/cancel button click
   * @author Alkesh Shah
   * @param {*} status
   * @memberof CommercialCngaComponent
   */
  onBookCampaign(status: number) {
    if (this.validateCommercialCampaign(status)) {
      status = status || this.campaignDetails.campaignStatusId;

      if (SystemFlags.isLoadedCampaign && (this.initialConfig.auditType && this.initialConfig.auditType.length > 0) &&
        (this.commercialButtonService.roCancel(this.campaignDetails, this.commercialConfig.uiControl, status) ||
          this.commercialButtonService.roChange(this.campaignDetails, this.commercialConfig.uiControl, 'bookingList', status))) {

        this.commercialButtonService.openReasonForChangeOverlay(
          this.commercialButtonService.populateDataParamReasonForChange(status, this.campaignDetails, this.commercialConfig.uiControl))
          .then((result: any) => {
            if (result) {
              this.campaignDetails = this.commercialButtonService.reasonForChangeOverlayThen(this.campaignDetails, result, status);

              const param = {
                bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
                data: JSON.stringify(this.createRequestJSONForCommercial(status))
              };
              this.commercialButtonService.validateCommercialScreenServiceRequest(param, status);
            }
          }, (reason) => {
            console.log(reason);
          });
      } else if ((this.commercialConfig.uiControl.tradingEnabled ?
        (this.campaignDetails.grossMediaValue === 0) : false) &&
        status === CampaignStatus.confirm) {
        this.commercialButtonService.openFreeOFChargeOverlay().then((result: FreeOFChargeResult) => {
          if (result) {
            this.campaignDetails.freeOfCharge = result.freeOfCharge;
            let comment = '';
            if (result.freeOfCharge && result.freeOfCharge.specifyOther) {
              comment = (this.commercialButtonService.startsWith(result.freeText, 'FOC - ') ? '' : 'FOC - ') + result.freeText;
            } else {
              comment = (this.commercialButtonService.startsWith(result.freeOfCharge.auditReasonName, 'FOC - ') ? '' : 'FOC - ')
                + result.freeOfCharge.auditReasonName;
            }
            result.freeOfCharge.comment = comment;
            this.campaignDetails.comments = (this.campaignDetails.comments ?
              (`${this.campaignDetails.comments}\n\r${comment}`) : comment);
            const campaignStatus = CampaignStatus.confirm || this.campaignDetails.campaignStatusId;
            const param = {
              bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
              data: JSON.stringify(this.createRequestJSONForCommercial(campaignStatus))
            };
            this.commercialButtonService.validateCommercialScreenServiceRequest(param, this.campaignDetails.campaignStatusId);
          }
        });
      } else {
        const param = {
          bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
          data: JSON.stringify(this.createRequestJSONForCommercial(status))
        };
        this.commercialButtonService.validateCommercialScreenServiceRequest(param, status);
      }
    }
  }

  /**
   * @description commercial form validity
   * @author Alkesh Shah
   * @returns {boolean}
   * @memberof CommercialCngaComponent
   */
  validateCommercialCampaign = (status) => {
    const requiredFields: RequiredCampaignFields = new RequiredCampaignFields();
    requiredFields.pmpDealId = false;
    requiredFields.campaignCPT = false;
    let message = this.validateCommonFields(requiredFields);
    if (!message && this.campaignDetails.bookingList) {
      this.campaignDetails.bookingList.forEach((obj) => {
        const requiredBookingsFields: RequiredBookingsFields = new RequiredBookingsFields(false, true, true, true, true);
        if (!message) {
          message = this.validateBookingFields(obj, requiredBookingsFields);
        }
        if (!message && obj.containsDigital) {
          message = this.validateMediaLength(obj, status, true);
        }
      });
    }
    return this.isValid(message);
  }

  /**
   * @description create requestJSON for passing to backend
   * @author Alkesh Shah
   * @param {*} status
   * @returns
   * @memberof CommercialCngaComponent
   */
  createRequestJSONForCommercial = (status) => {
    const campaignDetails: any = this.campaignDetails;
    const temp = this.populateBookCommercialCampaignParam(status, campaignDetails);
    const populateBooking: PopulateBookingDetailFields = this.getBookingDetailFields();
    temp.bookingList = this.populateBookingDetailsServiceObject(campaignDetails, 'bookingList', populateBooking);
    if (campaignDetails.bookingDetails) {
      temp.bookingDetails = campaignDetails.bookingDetails;
    }

    return temp;
  }

  /**
   * To override the booking detail fields
   * This override fields are used to override the default value
   * @memberof CommercialCngaComponent
   * @author Alkesh Shah
   */
  getBookingDetailFields() {
    const populate: PopulateBookingDetailFields = super.getBookingDetailFields();
    populate.setPICFields();
    populate.setCCBIBFields();
    populate.setPOORIFields();
    populate.financeAmount = true;
    populate.productionCost = true;
    populate.blankingOption = true;
    return populate;
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
