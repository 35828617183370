import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FrameStatus } from '../status.enum';
import { ICellRendererParams } from 'ag-grid-community';
import { FrameModel } from '../../models';

@Component({
  selector: 'app-ag-frame',
  templateUrl: './ag-frame.component.html',
  styleUrls: ['./ag-frame.component.css']
})
export class AgFrameComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;
  public frameStatus = FrameStatus;
  public frames: FrameModel[];
  public isViewCartOnMap = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.frames = this.params.data.frames;
    this.isViewCartOnMap = this.params.context.componentParent.isViewCartOnMap;
  }

    // called when the cell is refreshed
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.frames = this.params.data.frames;
    return true;
  }

  onSelectChange(frame: FrameModel): void {
        // onSelectFrameChange(asset)
    this.params.context.componentParent.onSelectFrameChange(this.params.data, frame);
  }

  infoMouseEnter($event: MouseEvent, frame: FrameModel): void {
    this.params.context.componentParent.onShowFrameDetail($event, frame);
  }

  infoMouseLeave($event: MouseEvent): void {
    this.params.context.componentParent.onHideFrameDetail($event);
  }

  onViewBookingDetailClick(frame: FrameModel): void {
    this.params.context.componentParent.onViewBookingDetailClick(frame);
  }

  trackByFrame(index, frame) {
    return frame?.frameId;
  }
}
