<app-sb-modal-wrapper [title]="audienceCategoryGroup.audienceCategoryGroupName"
    [titleBackgroundColor]="resolveObject.brick.color" [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)"
    (saved)="onModalSaved($event)">
    <div style="overflow-y: auto" [ngClass]="{'readOnly' : readOnlyModal}">
        <div class="ratingblocks" *ngFor="let categoryType of audienceCategoryType; trackBy: trackByAudienceCategoryType; let i = index">
            <div>
                {{categoryType.audienceCategoryTypeName}}
            </div>
            <div class="labelsrating">
                <input type="checkbox" class="checkbox small" id="selectAll-{{i}}"
                    [checked]="categoryType.selected" (click)="checkAllCategoriesInType(categoryType)"
                    name="selectAll-{{i}}" />
                <label for="selectAll-{{i}}">{{userBundle['common.selectAll']}}</label>
            </div>
            <div class="labelsrating">
                <label *ngFor="let category of categoryType.audienceCategory; trackBy: trackByAudienceCategory;" class="small">
                    <input type="checkbox" class="checkbox small" [checked]="category.selected"
                    (click)="isCheckAll(categoryType,category)">
                    <span>{{category.audienceCategoryName}}</span>
                </label>

            </div>
        </div>
    </div>
</app-sb-modal-wrapper>
