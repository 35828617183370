import { Component, OnInit, Input, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';
import { UiControl, CampaignParameters } from '../../models';
import { DataShareService } from '../../core/services';
import { Options } from '@angular-slider/ngx-slider';
import * as _ from 'lodash';

@Component({
  selector: 'app-allocation-adjustment',
  templateUrl: './allocation-adjustment.component.html',
  styleUrls: ['./allocation-adjustment.component.css']
})
export class AllocationAdjustmentComponent implements OnInit {

  isSliderOpen = false;
  uiControl: UiControl;
  userBundle: object;

  @Input() readOnly = false;
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  private privateCampaignParameters: CampaignParameters;
  @Input() public set campaignParameters(value: CampaignParameters) {
    this.privateCampaignParameters = _.cloneDeep(value);
  }
  public get campaignParameters(): CampaignParameters {
    return this.privateCampaignParameters;
  }

  options: Options = {
    floor: 0,
    ceil: 10,
    showSelectionBarFromValue: 0
  };
  @Output() campaignParametersChange: EventEmitter<CampaignParameters> = new EventEmitter();
  constructor(
    private dataShareService: DataShareService,
    private eRef: ElementRef
  ) { }

  ngOnInit() {
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
  }

  toggleAllocationSliders() {
    const audienceFactor = this.campaignParameters.audienceFactor;
    this.campaignParameters.audienceFactor = 10;
    this.campaignParameters.audienceFactor = audienceFactor;
    this.isSliderOpen = !this.isSliderOpen;
    if (!this.isSliderOpen) {
      this.campaignParametersChange.emit(this.campaignParameters);
    }
    this.manualRefresh.emit();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target) && this.isSliderOpen) {
      this.isSliderOpen = false;
      this.campaignParametersChange.emit(this.campaignParameters);
    }
  }

}
