import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagInputModule } from 'ngx-chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CampaignLoadFailureComponent } from './campaign-load-failure/campaign-load-failure.component';
import { SearchComponent } from './search.component';
import {
  SmartSearchComponent,
  SmartSearchService,
  AgDateFloatingFilterComponent,
  AgCustomFilterMenuComponent,
  AgCloneButtonComponent
} from './smart-search';
import { SearchService } from './search.service';
import { SbModalPopupModule } from '../core/components/sb-modal-popup';
import {
  CampaignDetailsComponent,
  DuplicationComponent,
  ProductCategoryComponent,
  ProximityProhibitionComponent
} from './campaign-details';
import { CoreModule } from '../core/core.module';
import { AgGridModule } from 'ag-grid-angular';
import { IMaskModule } from 'angular-imask';

@NgModule({
  imports: [
    TagInputModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SbModalPopupModule.forRoot([
      DuplicationComponent,
      ProductCategoryComponent,
      ProximityProhibitionComponent,
    ]),
    CoreModule.forRoot(),
    AgGridModule.withComponents([
      AgCustomFilterMenuComponent,
      AgDateFloatingFilterComponent,
      AgCloneButtonComponent
    ]),
    IMaskModule
  ],
  declarations: [
    SearchComponent,
    DuplicationComponent,
    ProductCategoryComponent,
    ProximityProhibitionComponent,
    SmartSearchComponent,
    CampaignDetailsComponent,
    CampaignLoadFailureComponent,
    AgCustomFilterMenuComponent,
    AgDateFloatingFilterComponent,
    AgCloneButtonComponent
  ],
  // entryComponents: [
  //   SearchComponent,
  //   SmartSearchComponent,
  //   CampaignDetailsComponent,
  //   CampaignLoadFailureComponent,
  //   AgDateFloatingFilterComponent
  // ],
  providers: [
    SearchService,
    SmartSearchService
  ],
  exports: [SearchComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SearchModule { }
