import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, InternalAdvertiser, CommercialConfig, HistoryROC } from '../../commercial.model';
import { SbSelectedItem } from '../../../models/sb-selected-item';
import { DataShareService } from '../../../core/services/data-share.service';
import {
  defaultInternalAdvertiserParams,
  SystemFlags,
  Campaign,
} from '../../../models';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { StateService, MaskService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { CampaignDetailsBase } from '../campaign-details-base';
import { CampaignType, CampaignGroupTypeList } from '../../../core/enum';
import { SelectConfig } from '../../../core/components/sb-select/core/select-config';
import { NumberMaskModel } from '../../../models/imask';

@Component({
  selector: 'app-campaign-details-italy',
  templateUrl: './campaign-details-italy.component.html',
  styleUrls: ['./campaign-details-italy.css']
})

export class CampaignDetailsITComponent extends CampaignDetailsBase implements OnInit, OnDestroy {
  @Input() campaignDetails: CampaignDetails = new CampaignDetails();
  @Input() commercialConfig: CommercialConfig = new CommercialConfig();
  @Input() historyROC: HistoryROC;
  @Output() manuallyCPTUpdated: EventEmitter<any> = new EventEmitter();

  maskService: MaskService = new MaskService();

  campaignType = CampaignType;
  searchPageObject: Campaign;

  /**
   * @description
   * @type {NumberMaskModel}
   * @memberof CampaignDetailsITComponent
   */
  currencyInputMask: NumberMaskModel;

  // InternalAdvertiserId//
  public resetInternalAdvertiserIdFlag = false;
  internalAdvertiserList: SbSelectedItem[] = [];

  bookingLineDataSubscription: Subscription;

  /**
   * @description
   * @type {SelectConfig}
   * @memberof CampaignDetailsItalyComponent
   */
  public internalAdvertiserLookupConfig: SelectConfig;

  /**
   * @description set readOnly
   * @memberof CampaignDetailsItalyComponent
   */
  public readOnly = SystemFlags.readOnly;

  campaignGroupTypeList = CampaignGroupTypeList;

  constructor(
    commercialService: CommercialService,
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    stateService: StateService
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService

    );
    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe((value: any) => {
      if (value.rowIndex >= 0 && value.columnName) {
        this.calculateCPT(value);
      }
      this.onBookingLineChange();
    });
  }

  calculateCPT(value: { rowIndex?: number, columnName?: string }) {
    if (value.columnName === 'grossMediaValue') {
      this.campaignDetailsService.calculateCPT(
        this.campaignDetails, value.rowIndex,
        undefined,
        this.campaignDetails.bookingList[value.rowIndex].grossMediaValue
      );
    } else if (value.columnName === 'pmpdiscount') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex);
    }
  }

  ngOnDestroy() {
    this.bookingLineDataSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.currencyInputMask = this.maskService.currencyInputMask({ max: 999999999999999999999 });

    this.init(this.campaignDetails);

    if (this.assignedToList) {
      this.campaignDetails.assignedTo = this.assignedToList.filter(e => e.assignedToId === this.campaignDetails.assignedToId)[0];
    }
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    this.enableSubAgency();
    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);
    this.searchPageObject = this.stateService.getCampaign();

    if (this.campaignDetails.calculateAtUI) {
      this.manageGrossMediaValueWithoutIndex();
    }

    this.setLookupConfig();
  }

  /**
   * @description To set the configuration for all lookup components
   * @author Dhaval Patel
   * @memberof CampaignDetailsItalyComponent
   */
  setLookupConfig() {
    this.setAdvertiserConfig();
    this.setBrandConfig();
    this.setSpecialistConfig();
    this.setAgencyConfig();
    this.setSubAgencyConfig();
    this.setInternalAdvertiserConfig();
  }

  /**
   * @description To set the configuration for agency lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setInternalAdvertiserConfig() {
    this.internalAdvertiserLookupConfig = new SelectConfig({
      placeholder: this.userBundle['common.lookup.defaultOption'],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_ADVERTISER_URL'),
      extraParams: defaultInternalAdvertiserParams,
      searchKeyParamName: 'organisationName',
      idField: 'advertiserId',
      textField: 'advertiserName'
    });
  }

  /**
   * @description To set the configuration for agency lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsItalyComponent
   */
  setAgencyConfig() {
    super.setAgencyConfig();
    this.agencyLookupConfig.displayInvoiceCode = true;
  }

  // Functions Related to Specialist//
  onSpecialistNameSelected(value: any): void {
    if (this.campaignDetails.specialist) {
      this.onSpecialistChange(value);
    }
  }

  removeSelectedSpecialistName() {
    this.onSpecialistChange();
  }

  // Functions related to Agencies//
  onAgencyNameSelected(value: any): void {
    if (this.campaignDetails.agency) {
      this.onAgencyChange(value);
      this.enableSubAgency();
    }
  }
  removeSelectedAgencyName(): void {
    this.onAgencyChange();
    this.enableSubAgency();
  }

  onBookingLineChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  assignedToChange() {
    this.manageGrossMediaValueWithoutIndex();
    this.enableSubAgency();
    this.setActiveAssignedTo();
  }

  // Functions Related to InternalAdvertiser//
  onInternalAdvertiserSelected(value: any): void {
    if (this.campaignDetails.internalAdvertiser) {
      this.campaignDetails.internalAdvertiser = value;
    }
  }

  removeSelectedInternalAdvertiser() {
    this.campaignDetails.internalAdvertiser = new InternalAdvertiser();
  }

  getInternalAdvertiserResponse(value: any): void {
    if (value.organisation && value.organisation.length) {
      this.internalAdvertiserList = value.organisation.map(item => ({
        advertiserId: item.organisationId,
        advertiserName: item.organisationName,
        source: item
      }));
    }
  }

  onAverageCPTChanged() {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT);
    this.onBookingLineChange();
    if (this.uiControl.enableUserDefinedCPMPricingCalculation) {
      this.manuallyCPTUpdated.emit();
    }
  }

}
