import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, CommercialConfig, HistoryROC } from '../../commercial.model';
import { DataShareService } from '../../../core/services/data-share.service';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { StateService, MaskService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { CampaignDetailsBase } from '../campaign-details-base';
import { SystemFlags } from '../../../models/system-flags';
import { Campaign } from '../../../models/campaign';
import { NumberMaskModel } from '../../../models/imask';
import { CampaignGroupTypeList } from '../../../core/enum';

@Component({
  selector: 'app-campaign-details-uk',
  templateUrl: './campaign-details-uk.component.html',
  styleUrls: ['./campaign-details-uk.css']
})

export class CampaignDetailsUkComponent extends CampaignDetailsBase implements OnInit, OnDestroy {
  @Input() campaignDetails: CampaignDetails = new CampaignDetails();
  @Input() commercialConfig: CommercialConfig = new CommercialConfig();
  @Input() historyROC: HistoryROC;
  @Output() manuallyCPTUpdated: EventEmitter<any> = new EventEmitter();

  bookingLineDataSubscription: Subscription;

  searchPageObject: Campaign;
  campaignGroupTypeList = CampaignGroupTypeList;

  /**
   * @description set readOnly
   * @memberof CampaignDetailsUkComponent
   */
  public readOnly = SystemFlags.readOnly;

  maskService: MaskService = new MaskService();

  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof CampaignDetailsUkComponent
   */
  currencyInputMask: NumberMaskModel;

  constructor(
    commercialService: CommercialService,
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    stateService: StateService
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService
    );
    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe((value: any) => {
      if (value.rowIndex >= 0 && value.columnName) {
        this.calculateCPT(value);
      }
      this.onBookingLineChange();
    });
  }

  ngOnDestroy() {
    this.bookingLineDataSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.currencyInputMask = this.maskService.currencyInputMask({ max: 999999999999999999999, padFractionalZeros: false, scale: this.commercialConfig.uiControl.campaignCPTScale || 2 });
    this.init(this.campaignDetails);
    this.campaignDetails.assignedTo = this.assignedToList.filter(e => e.assignedToId === this.campaignDetails.assignedToId)[0];
    this.searchPageObject = this.stateService.getCampaign();
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    this.enableSubAgency();
    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);
    this.setLookupConfig();
  }

  // Functions Related to Specialist//
  onSpecialistNameSelected(value: any): void {
    if (this.campaignDetails.specialist) {
      super.onSpecialistChange(value);
      this.manageGrossMediaValueWithoutIndex();
    }
  }

  removeSelectedSpecialistName() {
    this.onSpecialistChange();
    this.manageGrossMediaValueWithoutIndex();
  }

  // Functions related to Agencies//
  onAgencyNameSelected(value: any): void {
    if (this.campaignDetails.agency) {
      this.onAgencyChange(value);
      this.enableSubAgency();
      this.manageGrossMediaValueWithoutIndex();
    }
  }

  /**
   * @description remove Selected Agency
   * @author Kishan Patel
   * @memberof CampaignDetailsUkComponent
   */
  removeSelectedAgencyName(): void {
    this.onAgencyChange();
    this.enableSubAgency();
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description update gross media value from grid update
   * @author Kishan Patel
   * @memberof CampaignDetailsUkComponent
   */
  onBookingLineChange() {
    this.campaignDetailsService.populateAgencyPercentage(this.campaignDetails);
    this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
  }

  assignedToChange() {
    this.campaignDetailsService.populateAgencyPercentage(this.campaignDetails);
    this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
    this.enableSubAgency();
    this.setActiveAssignedTo();
  }

  /**
   * @description on CPT value changes then update pmp discount in grid
   * @author Kishan Patel
   * @memberof CampaignDetailsUkComponent
   */
  onAverageCPTChanged() {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT, undefined, {}, this.commercialConfig.uiControl.campaignCPTScale || 2); // SM-4776
    this.commercialService.notifyCampaignDetails(this.campaignDetails.bookingList);
    if (this.uiControl.enableUserDefinedCPMPricingCalculation) {
      this.manuallyCPTUpdated.emit();
    }
  }

  /**
   * @description calculate CPT from gross media value or pmp discount change
   * @author Kishan Patel
   * @param {*} value
   * @memberof CampaignDetailsUkComponent
   */
  calculateCPT(value) {
    if (value.columnName === 'grossMediaValue') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex,
        undefined, Number(this.campaignDetails.bookingList[value.rowIndex].grossMediaValue), {}, this.commercialConfig.uiControl.campaignCPTScale || 2);
    } else if (value.columnName === 'pmpdiscount') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex, undefined, undefined, {}, this.commercialConfig.uiControl.campaignCPTScale || 2);
    }
  }

  /**
   * @description Changes in Checkbox to Prevent Recalculation of CPT
   * @author Dhaval Patel
   * @memberof CampaignDetailsUkComponent
   */
   preventRecalculationChanged() {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, undefined, this.campaignDetails.bookingList[0].grossMediaValue, this.options, this.commercialConfig.uiControl.campaignCPTScale || 2);
    this.commercialService.notifyGrid(this.campaignDetails);
  }
}
