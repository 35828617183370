/**
 * @description Returns true if element exist in array
 * @author Shivani Patel
 * @export
 * @param {any[]} array
 * @param {*} element
 * @returns {boolean}
 */
export function isExist(array: any[], element: any): boolean {
  return array ? array.indexOf(element) !== -1 : false;
}
