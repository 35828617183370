/**
 * Frame Availability status
 * 0: Available
 * 1: un available
 * @export
 * @enum {number}
 */
export enum FrameStatus {
    available = 0,
    unavailable = 1
}

/**
 * furnitureStatus
 * 0: All frames inside furniture are Available
 * 1: some frames inside furniture are Available and some are not (partial available)
 * 2: All frames inside furniture are UnAvailable
 * @export
 * @enum {number}
 */
export enum FurnitureStatus {
    available = 0,
    partialAvailable = 1,
    unavailable = 2
}

/**
 * FrameFilterType
 * 0: All frames got in response to gmProcessfilter
 * 1: only available frame got in response to gmProcessfilter
 * 2: only user selected frame got in response to gmProcessfilter
 * @export
 * @enum {number}
 */
export enum FrameFilterType {
    all = 0,
    available = 1,
    selected = 2
}

/**
 * @description To filter concertina data with statusMask
 * @export
 * @enum {number}
 */
export enum FrameStatusMaskEnum {
    notAvailable = 1,
    available = 2,
    availableAndNotAvailable = 3,
    selected = 4,
    selectedAndNotAvailable = 5,
    selectedAndAvailable = 6,
    partiallySelected = 7
}

/**
 * @description To filter concertina data with statusMask
 * @export
 * @enum {number}
 */
export enum ExtraInfoMaskEnum {
    fixed = 1,
    innovate = 2,
    fixedAndInnovate = 3
}

/**
 * Map Marker view by
 * 1: View by furniture level
 * 2: View by frame level
 */
export enum MapViewBy {
    furniture = 1,
    frame = 2
}

export enum editablePeriodId {
    past = 0,
    running = 1,
    future = 2
}

export enum FramePeriodType {
    week = 1,
    days = 2,
    time = 3,
    Hrs = 4
}

export enum DateType {
    none = 0,
    start = 1,
    end = 2
}

/**
 * changing end date from cart detail popup,
 * need to add this suffix to make sure frame book till end of selected day
 */
export const endDateSuffix = 'T23:59:59.999';
