export class SelectItem {
  public source: object;
  public children: SelectItem[];
  public parent: SelectItem;
  [key: string]: any;
  public constructor(source: any, idField = 'id', textField = 'text') {
    if (typeof source === 'string') {
      this[idField] = this[textField] = source;
    }
    if (typeof source === 'object') {
      this[idField] = source[idField] || source[textField];
      this[textField] = source[textField];
      this.source = source.source;
      if (source.children && source[textField]) {
        this.children = source.children.map((c: any) => {
          const r: SelectItem = new SelectItem(c);
          r.parent = this;
          return r;
        });
        this[textField] = source[textField];
      }
    }
  }

  public fillChildrenHash(optionsMap: Map<string, number>, startIndex: number): number {
    let i = startIndex;
    this.children.forEach((child: SelectItem) => {
      optionsMap.set(child.id, i++);
    });
    return i;
  }

  public hasChildren(): boolean {
    return this.children && this.children.length > 0;
  }

  public getSimilar(): SelectItem {
    const r: SelectItem = new SelectItem(false);
    r.id = this.id;
    r.text = this.text;
    r.parent = this.parent;
    return r;
  }
}
