import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, QueryList, ViewChildren, } from '@angular/core';
import { TemplateProps } from '../../../models/tree-view';
import { DataShareService } from '../../services/data-share.service';
import { LoaderService } from '../../services';
import { ITreeState, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { AppHeaderService } from 'app/root/app-header/app-header.service';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.css']
})

export class TreeViewComponent implements OnInit {
  @Input() data;
  @Input() options;
  state: ITreeState;
  @Input() searchPlaceholder: string;
  @Input() templateProps: TemplateProps;
  @Input() hideHeader = false;
  @Input() lazySearch = false;
  @Input() searchMinlength = 0;
  @Input() isSearchCaseSensitive = false;
  @Input() emitEvent = false;
  @Input() skipFilter = false;
  @Output() onSelect = new EventEmitter<any>();
  @Output() onDeSelect = new EventEmitter<any>();
  @Output() onSelectAll = new EventEmitter<any>();
  @Output() onColorChange = new EventEmitter<any>();
  @Output() onShapeChange = new EventEmitter<any>();
  @Output() onIconShapeModal = new EventEmitter<any>();
  @ViewChild('tree') tree: TreeNode;
  @ViewChild('filter') filter: ElementRef;
  isIconUpdateModel = false;
  isColorPickerOpened = false;
  @ViewChildren(NgbDropdown)
  dropdowns: QueryList<NgbDropdown>;
  dropdown: NgbDropdown;

  @Output() selectedRelativeFiltersChange = new EventEmitter<object>();

  allChecked = false;
  manuallySelectedAll = false;
  isDataAvailable = true;
  userBundle: Object;
  searchText = '';
  searchedNodesId: string[] = [];
  pcmMode: boolean;
  objectiveMode: boolean;

  constructor(private dataShareService: DataShareService,
    private loaderService: LoaderService, private appHeaderService: AppHeaderService) {
      this.pcmMode = this.appHeaderService.enabledPCM;
      this.objectiveMode = this.appHeaderService.objectiveMode;
  }
  ngOnInit() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.options = {
      useVirtualScroll: true,
      nodeHeight: 22,
      ...this.options,
    };
  }

  handleLabelClick(node) {
    const data = node.data;
    if ((this.templateProps.localSolverEnabled || this.templateProps.checkboxIconEnabled) && !node.isSelected) {
      if (node.isRoot && node.children && node.children.length) {
        node.data.relative = false;
        node.children.forEach(child => child.data.relative = false);
      }
      const parentNode = this.getParentNode(parseInt(node.id, 10));
      if (parentNode) {
        parentNode.data.relative = this.isAllChildRelative(parentNode) ? true : false;
      }
    }
    const emptyITreeState: ITreeState = { selectedLeafNodeIds: {} };
    const { selectedLeafNodeIds } = this.templateProps.displayRadioButton
      ? emptyITreeState
      : this.state || emptyITreeState;
    if (((!_.isEmpty(selectedLeafNodeIds)
      && selectedLeafNodeIds[data[this.templateProps.displayId]])
      || (node.isSelected))
      && !this.templateProps.displayRadioButton) {
      delete selectedLeafNodeIds[data[this.templateProps.displayId]];
      this.removeLeafChildAtDeselectParent(data[this.options.childrenField], selectedLeafNodeIds, data.relative);
      this.removeLeafParentAtDeselectChild(node, selectedLeafNodeIds);
      this.tree.treeModel.getNodeById(node.id).setIsSelected(false);
    } else {
      selectedLeafNodeIds[data[this.templateProps.displayId]] = true;
      this.addSelectionLeafNodeRecursive(data[this.options.childrenField], selectedLeafNodeIds, data.relative);
      this.tree.treeModel.getNodeById(node.id).setIsSelected(true);
    }
    this.state = {
      ...this.state,
      selectedLeafNodeIds: Object.assign({}, selectedLeafNodeIds)
    };
  }

  /**
   * @description When deselect parent node, need to remove all child node from selectedLeafNodeIds.
   * @memberof TreeViewComponent
   * @author Nikunj Gadhiya
   * @param {any} products
   * @param {any} selectedLeafNodeIds
   */
  removeLeafChildAtDeselectParent(products, selectedLeafNodeIds, relative) {
    if (products && products.length > 0) {
      for (const product of products) {
        delete selectedLeafNodeIds[product[this.templateProps.displayId]];
        this.removeLeafChildAtDeselectParent(product[this.options.childrenField], selectedLeafNodeIds, relative);
      }
    }
  }

  /**
   * @description When parent is selected and deselect child node then remove all parent leaf node and deselected child node from selectedLeafNodeIds.
   * @memberof ProximityBase
   * @author Nikunj Gadhiya
   * @param {any} node
   * @param {any} selectedLeafNodeIds
   */
  removeLeafParentAtDeselectChild(node, selectedLeafNodeIds) {
    if (node.realParent) {
      if (selectedLeafNodeIds.hasOwnProperty(node.realParent.id)) {
        this.removeLeafParentAtDeselectChild(node.realParent, selectedLeafNodeIds);
        delete selectedLeafNodeIds[node.realParent.id];
      }
    }
  }

  /**
   * @description When select parent node, need to add all child node in selectedLeafNodeIds.
   * @memberof TreeViewComponent
   * @author Nikunj Gadhiya
   * @param {any} products
   * @param {any} selectedLeafNodeIds
   */
  addSelectionLeafNodeRecursive(products, selectedLeafNodeIds, relative) {
    if (products && products.length > 0) {
      for (const product of products) {
        selectedLeafNodeIds[product[this.templateProps.displayId]] = true;
        this.addSelectionLeafNodeRecursive(product[this.options.childrenField], selectedLeafNodeIds, relative);
      }
    }
  }

  /**
   * Method created to check the data change. Required to make filteration
   * @memberof TreeViewComponent
   */
  onUpdateData() {
    setTimeout(() => {
      if (this.filter && this.filter.nativeElement.value !== '') {
        this.filterNodes(this.filter.nativeElement.value, true);
      }
      this.setIsDataAvailable();
    }, 0);
  }

  filterNodes(searchText: string, skipTextCheck = false) {
    if (!this.isSearchCaseSensitive) {
      searchText = searchText.toLowerCase();
    }
    if ((searchText.length === 0 || searchText.length >= this.searchMinlength) && (skipTextCheck || this.searchText !== searchText)) {
      if (this.templateProps.showId) {
        // Added this function as `filterNodes` method searches case insensitively in the node's display attribute
        // as we want to search by id and display attribute both we have to pass a function as it's arguments.
        this.searchText = searchText;
        this.searchedNodesId = [];
        this.tree.treeModel.filterNodes((node) => {
          const value = `${node.data[this.templateProps.displayId]} - ${node.data[this.templateProps.displayName]}`;
          return value.toLowerCase().trim().includes(searchText.trim().toLowerCase());
        });
      } else {
        // If we pass searchText of type string directly to filterNodes
        // then it will be searched case insensitively in the node's display attribute
        // this.tree.treeModel.filterNodes(searchText);

        this.loaderService.show();
        setTimeout(async () => {
          if (this.skipFilter) {
            this.filterNodesSkipper(searchText, this.tree.treeModel);
          } else {
            this.tree.treeModel.filterNodes(searchText);
          }
          this.searchText = searchText;
          this.searchedNodesId = [];
          this.loaderService.hide();
        }, 100);
      }
    }
    if (searchText.length === 0) {
      this.loaderService.show();
      setTimeout(async () => {
        await this.tree.treeModel.collapseAll();
        this.loaderService.hide();
      }, 1000);
    }
  }

  /**
   * This method will filter the tree nodes based on filtered text
   * @param filter filtered text
   * @param autoShow To expand nodes automatically
   * @returns
   */
  filterNodesSkipper(filter, autoShow) {
    if (autoShow === void 0) { autoShow = true; }
    var filterFn = ((node) => { return node.displayField.toLowerCase().indexOf(filter.toLowerCase()) !== -1; });
    if (!filter) {
      this.tree.treeModel.hiddenNodeIds = {};
      return;
    }
    var ids = {};
    this.tree.treeModel.roots.forEach(((node) => { return this.filterNode(ids, node, filterFn, autoShow); }));
    this.tree.treeModel.hiddenNodeIds = ids;
  }

  /**
   * If filtered text is matched with node name, this method will skip further checkup
   * @param ids Array to store hidden node ids
   * @param node Each node
   * @param filterFn Filter function
   * @param autoShow To expand nodes automatically
   * @returns
   */
  filterNode(ids, node, filterFn, autoShow) {
    var isVisible = filterFn(node);
    if (!isVisible && node.children) {
      node.children.forEach(((child) => {
        if (this.filterNode(ids, child, filterFn, autoShow)) {
          isVisible = true;
        }
      }));
    }
    // mark node as hidden
    if (!isVisible) {
      ids[node.id] = true;
    }
    // auto expand parents to make sure the filtered nodes are visible
    if (autoShow && isVisible) {
      node.ensureVisible();
    }
    return isVisible;
  }

  onTreeViewInitialized() {
    if (this.templateProps && this.templateProps.expandFirstNode) {
      const firstRoot = this.tree.treeModel.roots[0];
      if (firstRoot) {
        firstRoot.expand();
      }
      this.setIsDataAvailable();
    }
  }

  getNodeById(id: number): any {
    return this.tree.treeModel.getNodeById(id);
  }

  getParentNode(id: number): TreeNode {
    return this.getNodeById(id) && !this.getNodeById(id).isRoot ? this.getNodeById(id).parent : null;
  }

  expandAll() {
    this.loaderService.show();
    setTimeout(async () => {
      await this.tree.treeModel.expandAll();
      this.loaderService.hide();
    }, 1000);
  }

  setIsDataAvailable() {
    this.isDataAvailable = this.tree.treeModel.nodes.length > 0;
  }

  onDeSelectItem(event) {
    if (this.templateProps.allowCustomCheckBoxEvents) {
      if (!this.manuallySelectedAll) {
        this.allChecked = false;
      }
      this.onDeSelect.emit(event);
    } else if (this.emitEvent) {
      setTimeout(async () => {
        this.onDeSelect.emit(event);
      }, 100);
    }
  }

  onSelectItem(event) {
    if (this.templateProps.allowCustomCheckBoxEvents) {
      if (!this.manuallySelectedAll) {
        this.allChecked = false;
      }
      this.onSelect.emit(event);
    } else if (this.emitEvent) {
      setTimeout(async () => {
        this.onSelect.emit(event);
      }, 100);
    }
  }

  onToggleSelectAll() {
    this.manuallySelectedAll = true;
    this.onSelectAll.emit(this.allChecked);
  }

  changeRelativeFilter(node: TreeNode) {
    let lastParent;
    this.updateRelativeRecursiveOnChild(node.children, node.data.relative);
    let parent = node.realParent;
    while (parent) {
      parent.data.relative = this.isAllChildRelative(parent);
      lastParent = parent;
      parent = parent.realParent;
    }

    if (lastParent) {
      this.selectedRelativeFiltersChange.emit(lastParent);
    } else {
      this.selectedRelativeFiltersChange.emit(node);
    }
  }

  isAllChildSelected(node: TreeNode) {
    return node.children && node.children.length ? node.children.filter(child => child.isSelected).length === node.children.length : true;
  }

  isAllChildRelative(node: TreeNode) {
    return node.children && node.children.length ? node.children.filter(child => child.data.relative).length === node.children.length : true;
  }

  updateRelativeRecursiveOnChild(children, relative) {
    if (children && children.length > 0) {
      for (const child of children) {
        child.data.relative = relative;
        this.updateRelativeRecursiveOnChild(child.children, relative);
      }
    }
  }

  getSuperSelectedParent(node: TreeNode): TreeNode {
    if (node && node.parent && node.parent.isSelected && node.parent.displayField && !node.parent.isPartiallySelected) {
      return this.getSuperSelectedParent(node.parent);
    } else {
      return node;
    }
  }

  toggleRelativeChildren(node: TreeNode) {
    this.changeRelativeFilter(node);
  }

  /**
   * @description This method filters the nodes by index.
   * eg. In Channel, we need to filter it based on activeTabIndex. This method will help in filtering the nodes
   * @author Shreni Shah
   * @date 2020-07-17
   * @param {*} index
   * @memberof TreeViewComponent
   */
  filterNodesByIndex(index: number) {
    this.tree.treeModel.filterNodes((node) => {
      return node.data.index === index;
    });
  }

  collapseAllNodes() {
    this.tree.treeModel.collapseAll();
  }

  filterNodesByIndexAndProperty(index: number, propertyName: string) {
    this.tree.treeModel.filterNodes((node) => {
      const searchText = this.filter.nativeElement.value;
      const value: string = node.data[propertyName];
      return node.data.index === index && value.toLowerCase().includes(searchText.trim().toLowerCase());
    });
  }

  getSelectedLeafNodeIds(): any {
    return this.state ? this.state.selectedLeafNodeIds : [];
  }

  updateShapeModel(node): void {
    this.onIconShapeModal.emit(node);
  }

  updateIconShape(node, shape): void {
    node.data.shape = shape;
    this.onShapeChange.emit(node);
  }

  updateColorCode(node): void {
    this.onColorChange.emit(node);
  }

}
