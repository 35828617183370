<app-filter-layout [resolveObject]="resolveObject" (closeClick)="close()" (saveClick)="save()">
  <div class="form-group-wrapper_geo " *ngIf="!hideUIElements('workspace.format.checkboxGroup')">
    <div class="form-group_geo">
      <div class="row">
        <label class="small col-sm-6" *ngIf="!hideUIElements('workspace.format.dominateFurniture')">
          <input type="checkbox" class="checkbox small" id="DF" [(ngModel)]="selected.selectionCriteriaRestriction[1]">
          <span>{{initialConfig.userBundle['workspace.collection.bric.format.dominateFurniture']}}</span>
        </label>

        <label class="small col-sm-6" *ngIf="!hideUIElements('workspace.format.backtoBackPanels')">
          <input type="checkbox" class="checkbox small" id="BBP" [(ngModel)]="selected.selectionCriteriaRestriction[2]">
          <span>{{initialConfig.userBundle['workspace.collection.bric.format.b2b']}}</span>
        </label>

        <label class="small col-sm-6" *ngIf="!hideUIElements('workspace.format.parallelPanels')">
          <input type="checkbox" class="checkbox small" id="PP" [(ngModel)]="selected.selectionCriteriaRestriction[3]">
          <span>{{initialConfig.userBundle['workspace.collection.bric.format.parallel']}}</span>
        </label>
        
      </div>
    </div>
  </div>

  <div class="form-group-wrapper_geo grpCnt">
    <div class="form-group_geo">
      <div *ngFor="let lookup of lookups.lookup; trackBy: trackBySelectionId; let i = index;" id="format-{{i}}" class="col-sm-6">
        <span class="text"></span>
        <input type="radio" class="radio_geo" [checked]="selectedItem === i" (change)="showContent(i)" name="rb-format" id="rb-{{i}} ">
        <label [ngClass]="{'highlight-tab': isDataAvailable(lookup.selectionId)}" class="radio-label" for="rb-{{i}} "><em class="fa fa-circle-o "></em> {{lookup.displayName}}</label>
      </div>
    </div>
  </div>

  <div class="form-group-wrapper_geo ">
    <h3 class="form-title_geo"><span class="selected-title">{{initialConfig.userBundle["geoplanner.text.searchFor"] || 'Search for'}} {{searchTitle}}</span></h3>
    <div class="content-area" [ngClass]="{'readOnly' : resolveObject.readOnlyModal}">
      <div *ngFor="let lookup of lookups.lookup; trackBy: trackBySelectionId; let i = index">
        <div id="tab-{{i}}" class="tab-content" [ngStyle]="{'display': i == selectedItem?'block':'none'}">
          <app-lookup-view [lookupType]="lookup.lookupType" [selectionId]="lookup.selectionId" [(ngModel)]="selected[lookup.selectionId]"
            [selectAll]="lookup.selectAll" [placeholder]="lookup.displayName" [regexp]="lookup.regExp" [configLookup]="configLookup?configLookup[lookup.selectionId]: null"></app-lookup-view>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</app-filter-layout>
