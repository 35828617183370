import { GLOBAL } from '../core/utils/app.constant';

declare let ga;
export class GoogleAnalyticsEvents {

  /**
   * Emit google analytics event
   * @static
   * @param {string} eventCategory
   * @param {string} eventAction
   * @param {string} eventLabel
   * @memberof GoogleAnalyticsEvents
   */
  public static send(eventCategory: string, eventAction: string, eventLabel: string) {
    if (GLOBAL.googleAnalyticsEnabled) {
      const eventData = {
        hitType: 'event',
        eventCategory,
        eventAction,
        eventLabel
      };
      ga('send', eventData);
    }
  }
}

export enum GoogleAnalyticsEventCategory {
  Filter = 'FILTER', // Filters(brics) used
  MapFeatures = 'MAP FEATURES', //  map functionality
  CampaignFeatures = 'CAMPAIGN FEATURES',
  Smartsearch = 'SMART SEARCH',
  Brics = 'BRICS',
  Availability = 'AVAILABILITY',
  Commercial = 'COMMERCIAL',
  FLOPPY = 'FLOPPY',
  Cart = 'CART'
}

export enum EventActionsMapFeatures {
  DrawShape = 'DRAW SHAPE', // Draw shape on map
  SearchLocation = 'SEARCH LOCATION', //  Search location on map
  SearchFrame = 'SEARCH FRAME', // Search frame on map
  MapFilter = 'MAP FILTER', // All, Available, selected filter on map
  ToggleCart = 'TOGGLE CART', // Toggle View Cart on map
  MarkerLabel = 'MARKER LABEL', // Enable/disable marker label
  MarkerColor = 'MARKER COLOR', // Enable/disable marker color
  CompactMarker = 'COMPACT MARKER', // Enable/disable Compact marker
}

export enum EventActionsCampaignFeatures {
  LookupCampaign = 'LOOKUP CAMPAIGN', // Search other campaign for plot
  GetCampaign = 'GET CAMPAIGN', // Load other campaign for plot
  FrameBooking = 'FRAME BOOKING', // View frame booking list - timeline
  FolioGallery = 'FOLIO GALLERY' // View frame's folio gallery images
}

export enum EventActionsCart {
  SaveFrame = 'SAVE FRAME', // Save frame from frame selection
  DeleteFrame = 'DELETE FRAME', // Delete frame from cart
  ApplyChanges = 'APPLY CHANGES', // apply changes from cart detail grid
  UpdateCart = 'UPDATE CART', // Update cart from cart detail modal
  ExportExcel = 'EXPORT CART' // Export cart data to excel
}
