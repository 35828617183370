<app-sb-modal-wrapper [saveButton]="initialConfig.userBundle['workspace.advancedAvailability.download']"
    [title]="initialConfig.userBundle['workspace.advancedAvailability.label']" [titleBackgroundColor]="brickBgColor"
    (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
    <div class="modal-body">
        <div class="tabs_container horizontal">
            <ul class="tabs-menu">
                <li [ngClass]="{'current': selectedItem ===1}"><a (click)="internalFormat(1)" style="cursor: pointer;">
                        <span
                            class="text">{{initialConfig.userBundle['workspace.advancedAvailability.label.internal']}}</span></a>
                </li>
                <li [ngClass]="{'current': selectedItem ===2}"><a (click)="internalFormat(2)" style="cursor: pointer;">
                        <span
                            class="text">{{initialConfig.userBundle['workspace.advancedAvailability.label.external']}}</span></a>
                </li>
            </ul>

            <div class="tab">

                <div id="tab-5" class="tab-content" [ngStyle]="{'display': selectedItem ===1?'block':'none'}">
                    <div class="scrolldiv">

                        <input type="radio" name="advanceAvailability"
                            [(ngModel)]="advanceAvailability.exportFormatInternal" value="1" id="btnline"
                            class="radio small">
                        <label for="btnline"
                            class="flexSlide">{{initialConfig.userBundle['workspace.advancedAvailability.label.linebyline']}}</label>


                        <input type="radio" name="advanceAvailability"
                            [(ngModel)]="advanceAvailability.exportFormatInternal" value="2" id="btnAtlas"
                            class="radio small">
                        <label for="btnAtlas"
                            class="flexSlide">{{initialConfig.userBundle['workspace.advancedAvailability.label.atlas']}}</label>

                    </div>
                </div>

                <div id="tab-6" class="tab-content  animated" [ngStyle]="{'display': selectedItem ===2?'block':'none'}">
                    <div class="scrolldiv">
                        <label *ngFor="let item of externalList; trackBy: trackById;" class="small">
                            <!-- <input type="checkbox" id="rbtcarto-{{i}}" class="checkbox small" [(ngModel)]="advanceAvailability.exportFormatExternal">
                            <span>{{item.text}}</span> -->
                            <input type="checkbox" class="checkbox small" appChecklist
                                [(checklist)]="advanceAvailability.exportFormatExternal" [checklistValue]="item">
                            <span>{{item.text}}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <div>
            </div>

        </div>
    </div>

</app-sb-modal-wrapper>
