import { DataShareService } from '../../core/services/data-share.service';
import { SharedService } from '../../core/services/shared.service';
import { BrickBaseService } from '../../core/services/brick-base.service';
import { LookupModel, LocationBricSelectedDataModel, InitialConfigModel } from '../../models';
import { GeoLevels } from '../../models/initial-config.model';
import { GLOBAL } from '../../core/utils/app.constant';
import * as _ from 'lodash';
import { ObjectiveService } from '../../workspace/objective/objective.service';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
export class LocationBase {
  /**
   * It contians initial config data.
   */
  public initialConfig: InitialConfigModel;
  /**
   * It contians lookup data.
   */
  public lookups: LookupModel = { lookup: [], brickId: -1 };
  /**
   * It contians config lookup data.
   */
  public configLookup: any[] = [];
  /**
   * It contains selected filter data.
   */
  public selected: LocationBricSelectedDataModel = {};
  /**
   * It contians index of selected item.
   */
  public selectedItem = 0;
  /**
   * It contains search label string.
   */
  public searchForText = 'Search for';
  /**
   * It contains search title text.
   */
  public searchTitle: string;

  public geoTree;
  public treeData: GeoLevels[] = [];
  selectedRelativeFilters: object = {};
  public selectedNodeIds = [];
  public fileList = {};

  /**
   * Creates an instance of LocationBase.
   * @param {DataShareService} dataShareService -  Service to share data between compoenents.
   * @param {SharedService} sharedService - Common shared service.
   * @param {BrickBaseService} brickBaseService - Service for brick-base data.
   * @memberof LocationBase
   */
  constructor(
    private dataShareService: DataShareService,
    public sharedService: SharedService,
    public brickBaseService: BrickBaseService,
    public objectiveService: ObjectiveService,
    public appHeaderService: AppHeaderService
  ) {

    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  /**
   * initialize the data of instance
   * @param {*} columnConfig - column configuration data
   * @param {*} selectedValue - selected value of cell
   * @memberof LocationBase
   */
  init(columnConfig, selectedValue) {
    const selectionIdsOfLocation =
      this.dataShareService.getSelectionIdsForCell(this.initialConfig.lookupColumn, this.brickBaseService.brickID.Location);
    this.configLookup = columnConfig ? columnConfig.lookupData : null;
    this.lookups = this.sharedService.getLookupColumnData(this.brickBaseService.brickID.Location, columnConfig);
    this.selected = {};
    const tempSelectedValue = Object.keys(selectedValue).length > 0 ? _.cloneDeep(selectedValue) : null;
    if (!tempSelectedValue) {
      this.populateSelected();
    } else {
      // tslint:disable-next-line:max-line-length
      this.selected = this.sharedService.compareSelectedValuesWithColumnConfig(selectionIdsOfLocation, this.configLookup, tempSelectedValue);
      if (this.selected.fileList) {
        const fileKeys = Object.keys(this.selected.fileList);

        if (this.selected.fileList && fileKeys.length > 0) {
          fileKeys.forEach((f: any) => {
            this.fileList[f] = this.selected.fileList[f];
          });
        }
      }
    }
    if (this.initialConfig.uiControl.geoLocationTree) {
      this.treeData = this.sharedService.filterGeoTreeWithColumnConfig(this.initialConfig.geoLevels, this.configLookup);
      Object.keys(this.geoTree).forEach((obj) => {
        this.geoTree[obj] = selectedValue[obj] ? selectedValue[obj] : [];
        if (selectedValue[obj]) {
          selectedValue[obj].forEach((element) => {
            this.selectedRelativeFilters[element.id] = element.relative;
          });
        }
      });
    }

    if (this.appHeaderService.objectiveMode && Object.keys(this.selected).filter(o => (this.selected[o]).length > 0).length === 0) {
      const result = this.objectiveService.getSelectedForCollectionBrick(this.lookups, this.brickBaseService.brickID.Location as number);
      this.selected = result.selected;
      this.lookups = result.lookups;
      this.treeData = this.sharedService.filterGeoTreeWithSelectedValues(this.treeData, result.selected);
    }
  }

  populateSelected() {
    const lookup = this.lookups['lookup'];
    for (const l of lookup) {
      if (l.hasOwnProperty('selectionIds')) {
        for (const selectionId of l.selectionIds) {
          this.selected[selectionId] = [];
          this.geoTree[selectionId] = [];
          this.fileList[selectionId] = [];
        }
      } else {
        this.selected[l.selectionId] = [];
      }
    }
  }

  /**
   * To display content of particular selection.
   * @param index Index of selected item.
   * Commented the below function as during unit testing found that this function is unused
   */
  // showContent(index: number): void {
  //   this.selectedItem = index;
  //   this.searchForText = this.initialConfig.userBundle['geoplanner.text.searchFor'] || 'Search for';
  //   this.searchTitle = this.lookups.lookup[index].displayName;
  // }

  /**
   * To hightlight lable which contains selected data.
   * @param selectionId Id for particular display data.
   * @returns It will return true if contains data else false.
   */
  isDataAvailable(selectionId: number | [number]) {
    if (!Array.isArray(selectionId)) {
      if (this.selected[selectionId] && this.selected[selectionId].length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      let isSelected = false;
      selectionId.forEach(id => {
        if (this.selected[id] && this.selected[id].length > 0) {
          isSelected = true;
        }
      })
      return isSelected;
    }
  }

  /**
   * validate the selections
   * used on save click button
   * @returns {boolean} - valid selection or not
   * @memberof LocationBase
   */
  isValidSelection(): boolean {
    let isAnySelected = false;
    const keys = Object.keys(this.selected);
    for (const key of keys) {
      if (this.selected[key].length > 0) {
        isAnySelected = true;
        break;
      }
    }

    const fileSelectionIds = Object.keys(this.fileList);
    for (const f in fileSelectionIds) {
      if (this.fileList[fileSelectionIds[f]].length > 0) {
        isAnySelected = true;
        break;
      }
    }

    return isAnySelected;
  }

  loopTreeData(data, geoArea, element) {
    let loopBreak = false;
    for (const val1 of data) {
      if (element.id === val1.id) {
        this.geoTree[GLOBAL.geoArea.area106].push(element);
        loopBreak = true;
        break;
      }
      for (const val2 of val1.child) {
        if (element.id === val2.id) {
          this.geoTree[GLOBAL.geoArea.area107].push(element);
          loopBreak = true;
          break;
        }
        for (const val3 of val2.child) {
          if (element.id === val3.id) {
            this.geoTree[GLOBAL.geoArea.area108].push(element);
            loopBreak = true;
            break;
          }
        }
        if (loopBreak) {
          break;
        }
      }
      if (loopBreak) {
        break;
      }
    }
  }

  trackBySelectionId(index, lookup) {
    return lookup?.selectionId;
  }

  trackByItem(index, item) {
    return item;
  }

  async uploadFiles(fileUploadService, selectedValues) {
    if (!selectedValues.fileList) {
      selectedValues.fileList = {};
    }

    const fileSelectionIds = Object.keys(this.fileList);
    for (const fileSelectionId of fileSelectionIds) {
      if (this.fileList[fileSelectionId].length > 0) {
        await this.uploadfile(fileUploadService, this.fileList[fileSelectionId][0], 0, fileSelectionId, selectedValues);
      }
    }
  }

  async uploadfile(fileUploadService, file, index, fileselectionId, selectedValues) {
    await fileUploadService
      .uploadFileRequest([file], undefined, 0, this.initialConfig.serviceCalls.FILE_UPLOAD_URL, `location-${fileselectionId}`)
      .then(async (resData: any) => {
        if (resData.status === 'OK') {
          if (!selectedValues.fileList[fileselectionId]) {
            selectedValues.fileList[fileselectionId] = [];
          }

          selectedValues.fileList[fileselectionId].push({
            'userSelectionId': resData.data.userSelectionId,
            'validCount': resData.data.validCount,
            'totalCount': resData.data.totalCount,
            'exclude': this.fileList[fileselectionId][index].exclude,
            'userSelectionName': resData.data.userSelectionName
          });

          index++;
          if (this.fileList[fileselectionId].length > index) {
            return this.uploadfile(fileUploadService, this.fileList[fileselectionId][index], index, fileselectionId, selectedValues);
          }
          return selectedValues;
        } else {
          return resData.message;
        }
      });
  }
}
