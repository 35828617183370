import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { GridOptions, ColDef } from 'ag-grid-community';
import { ExcelCell, ExportToExcel, ExcelColumn } from '../../../core/utils/exportToExcel';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { DataShareService } from '../../../core/services/data-share.service';
import {
  AgCheckboxComponent,
  AgCustomFilterMenuComponent,
  AgCustomHeaderComponent,
  AgTextCellEditorComponent,
  AgSelectCellEditorComponent,
  AgNumberCellRendererComponent,
  AgCurrencyCellRendererComponent,
  AgSelectCellRendererComponent,
  AgPercentageCellRendererComponent,
  AgCustomSort,
  AgCustomRowUtil,
  AgTextFloatingFilterComponent,
  AgSelectFloatingFilterComponent
} from '../ag-grid-support/index';
import * as _ from 'lodash';
import * as toasterConfig from '../../../core/toasterConfig';
import { UiControl } from '../../../models';
import { Subscription } from 'rxjs';
import { NegotiationTypes } from '../../../core/enum';
import { CommercialService } from '../../../core/services';
import { CampaignDetails } from '../../commercial.model';

@Component({
  selector: 'app-discount-grid-brazil',
  templateUrl: './discount-grid-brazil.component.html',
  styleUrls: ['../ag-grid-custom-style.css', '../ag-grid-col-select-style.css', './discount-grid-brazil.component.css']
})
export class DiscountGridBrazilComponent implements OnInit, AfterViewInit, OnDestroy {

  // @Input() config: any;
  @Input() campaignDetails: any;
  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  uiControl: UiControl;
  sortObj: AgCustomSort;

  /**
   * Ag-Grid Options Object
   */
  gridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  columnDefs: ColDef[];

  /**
   * column list to show in show/hide column dropdown
   */
  columnList: any = [];

  /**
   * list of Cart Data
   * @memberof CartDetailComponent
   */
  rowData: any[] = [];

  /**
   * Grid footer data
   */
  footerRowData: any[];

  /**
   * user bundle data
   */
  userBundle: any = {};

  negotiationTypes = [];
  indexedNegotiationTypes = [];

  numericFields = [];
  dateRangeFields = [];
  selectFields = [];
  groupingField: string = null;
  public toasterConfig: any = toasterConfig.toasterconfig;
  discountDataSubscription: Subscription;
  public precisionOnPercentage = 7;
  public precisionOnPercentageDisplay = 2;
  constructor(
    private dataShareService: DataShareService,
    private toaster: LogHelperService,
    private commercialService: CommercialService
  ) {
    this.discountDataSubscription = this.commercialService.gridData$.subscribe((data: CampaignDetails) => {
      if (this.gridOptions && this.gridOptions.api && data.discounts) {
        data.discounts.forEach((row) => {
          row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount, row.agencyCommissionPercent);
          row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
          row.costPerFrame = this.calculateCostPerFrame(row.netAfterAgencyCommission, row.numberFrames);
        });
        this.gridOptions.api.redrawRows();
      }
    });
  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.setArrayLists();
    this.setFooterRows();
    this.prepareRowData();
    this.prepareColDef();
    this.reset();
    // we pass an empty gridOptions in, so we can grab the api out
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      onRowDataChanged: (event) => {
        event.api.setPinnedBottomRowData(this.footerRowData);
      },
      scrollbarWidth: 10,
      onGridReady: this.onGridReady,
      getRowHeight: this.getRowHeight,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      suppressScrollOnNewData: true,
      onFilterChanged: this.onFilterChanged,
      onCellEditingStopped: this.onCellEditingStopped,
      groupingField: this.groupingField,
      suppressMovableColumns: true,
      isExternalFilterPresent: () => true,
      // tslint:disable-next-line:ter-arrow-parens
      doesExternalFilterPass: (node) => node.data.isVisible
    } as GridOptions;
  }

  ngAfterViewInit() {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    document.body.appendChild(document.getElementsByTagName('toaster-container')[0]);
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    document.body.removeChild(document.querySelector('body > toaster-container'));
    this.discountDataSubscription.unsubscribe();
  }

  setArrayLists() {
    this.setNegotiationType();
  }

  setNegotiationType() {
    this.negotiationTypes = this.campaignDetails.negotiationTypes;
    this.negotiationTypes.unshift({ negotiationTypeId: -1, negotiationTypeName: this.userBundle['common.lookup.defaultOption'] });
  }

  onGridReady = () => {
    // set grid readonly if campaign is readonly
    if (this.uiControl.externalUser) {
      const ele: any = document.querySelector('ag-theme-fresh .ag-body-viewport .ag-center-cols-viewport .ag-center-cols-container');
      ele.className = `${ele.className} readOnly`;
      const ele1: any = document.querySelector('.ag-theme-fresh .ag-floating-bottom');
      ele1.className = `${ele1.className} readOnly`;
    }
    this.columnList = this.gridOptions.columnApi.getAllColumns();
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else if (params.data.isVisible) {
      height = 30;
    }
    return height;
  }

  isFullWidthCell = (rowNode): boolean => {
    return rowNode.data.gridSummary;
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }

  calculateAvgAgencyCommissionPercentage = (rows: any[]) => {
    let avgAgencyCommission = 0;
    let avgNetAfterDiscount = 0;
    rows.forEach((row) => {
      avgAgencyCommission += Number(row.agencyCommission);
      avgNetAfterDiscount += Number(row.netAfterDiscount);
    });
    return avgNetAfterDiscount === 0 ? 0 : parseFloat(((avgAgencyCommission * 100) / avgNetAfterDiscount).toFixed(this.precisionOnPercentageDisplay));
  }

  /**
   * @description calculate average cost per frame
   * @memberof DiscountGridBrazilComponent
   */
  calculateAvgCostPerFrame = (rows: any[]) => {
    let avgNoOfFrames = 0;
    let avgNetAfterAgencyCommission = 0;
    rows.forEach((row) => {
      avgNoOfFrames += row.numberFrames;
      avgNetAfterAgencyCommission += row.netAfterAgencyCommission;
    });
    return avgNoOfFrames === 0 ? 0 : parseFloat((avgNetAfterAgencyCommission / avgNoOfFrames).toFixed(this.precisionOnPercentageDisplay));
  }

  /**
   * @description Calculates media cost on given SB value and seassonal index
   * @memberof DiscountGridBelgiumComponent
   */
  calculateMediaCost = (grossPrice: number, seasonnalityIndex: number) => {
    const mediaCost = grossPrice * (seasonnalityIndex / 100);
    return parseFloat(mediaCost.toFixed(this.precisionOnPercentageDisplay));
  }

  /**
   * @description : Calculate the netAfterDiscount value.
   * @param {number} rateCard - RateCard value
   * @param {number} discount - value of discount
   * @returns Calculated netAfterDiscount value
   */
  calculateNetAfterDiscount(grossVal, discountVal) {
    const grossPrice = parseFloat(grossVal);
    const discount = parseFloat(discountVal);

    const finalNet = grossPrice - (grossPrice * (discount / 100));
    if (isNaN(finalNet)) {
      return 0.00;
    }
    return parseFloat(finalNet.toFixed(this.precisionOnPercentageDisplay));
  }

  calculateAgencyComission(netAfterDiscount, agencyCommissionPercent) {
    const agencyDiscount = (netAfterDiscount !== 0 && agencyCommissionPercent !== 0) ? (netAfterDiscount * agencyCommissionPercent) / 100 : 0;
    return parseFloat(agencyDiscount.toFixed(this.precisionOnPercentageDisplay));
  }

  calculateAgencyCommissionPercent(agencyCommission, netAfterDiscount) {
    const agencyDiscountPercent = (netAfterDiscount !== 0 && agencyCommission !== 0) ? (agencyCommission * 100) / netAfterDiscount : 0;
    return parseFloat(agencyDiscountPercent.toFixed(this.precisionOnPercentageDisplay));
  }

  calculateNetAfterAgencyComission(netAfterDiscount, agencyCommission) {
    const afteragencyCommission = (netAfterDiscount !== 0) ? (netAfterDiscount - agencyCommission) : 0;
    return parseFloat(afteragencyCommission.toFixed(this.precisionOnPercentageDisplay));
  }

  calculateCostPerFrame(afteragencyCommission, noOfFrames) {
    return noOfFrames === 0 ? 0 : parseFloat((afteragencyCommission / noOfFrames).toFixed(this.precisionOnPercentageDisplay));
  }

  onCellEditingStopped = (event) => {
    if (event.rowPinned !== 'bottom') {
      switch (event.column.colId) {
        case 'discount':
          event.data.netAfterDiscount = this.calculateNetAfterDiscount(event.data.grossPrice, event.data.discount);
          event.data.netAfterDiscountBackup = event.data.netAfterDiscount;
          event.data.agencyCommission = this.calculateAgencyComission(event.data.netAfterDiscount, event.data.agencyCommissionPercent);
          event.data.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(event.data.netAfterDiscount, event.data.agencyCommission);
          event.data.costPerFrame = this.calculateCostPerFrame(event.data.netAfterAgencyCommission, event.data.numberFrames);
          break;
        case 'grossPrice':
          event.data.netAfterDiscount = this.calculateNetAfterDiscount(event.data.grossPrice, event.data.discount);
          event.data.netAfterDiscountBackup = event.data.netAfterDiscount;
          event.data.agencyCommission = this.calculateAgencyComission(event.data.netAfterDiscount, event.data.agencyCommissionPercent);
          event.data.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(event.data.netAfterDiscount, event.data.agencyCommission);
          event.data.costPerFrame = this.calculateCostPerFrame(event.data.netAfterAgencyCommission, event.data.numberFrames);
          break;
        case 'agencyCommissionPercent':
          event.data.agencyCommission = this.calculateAgencyComission(event.data.netAfterDiscount, event.data.agencyCommissionPercent);
          event.data.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(event.data.netAfterDiscount, event.data.agencyCommission);
          event.data.costPerFrame = this.calculateCostPerFrame(event.data.netAfterAgencyCommission, event.data.numberFrames);
          break;
        case 'agencyCommission':
          event.data.agencyCommissionPercent = this.calculateAgencyCommissionPercent(event.data.agencyCommission, event.data.netAfterDiscount);
          event.data.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(event.data.netAfterDiscount, event.data.agencyCommission);
          event.data.costPerFrame = this.calculateCostPerFrame(event.data.netAfterAgencyCommission, event.data.numberFrames);
          break;
        // SM-3317, Nishit
        case 'netAfterDiscount':
          if (Number(event.data.netAfterDiscountBackup) !== Number(event.data.netAfterDiscount)) {
            event.data.discount = this.calculateDiscount(event.data.grossPrice, event.data.netAfterDiscount);
            event.data.netAfterDiscountBackup = event.data.netAfterDiscount;
            event.data.agencyCommission = this.calculateAgencyComission(event.data.netAfterDiscount, event.data.agencyCommissionPercent);
            event.data.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(event.data.netAfterDiscount, event.data.agencyCommission);
            event.data.costPerFrame = this.calculateCostPerFrame(event.data.netAfterAgencyCommission, event.data.numberFrames);
          }
          break;
        default:
          break;
      }
      this.rowData = _.clone(this.rowData);
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen() && $event.button !== 2 && !dropdownEle._isEventFromToggle($event)
    && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
      dropdownEle.close();
    }
  }

  prepareRowData() {
    this.rowData = [];
    for (const curRow of this.campaignDetails.discounts) {
      curRow.isFilterPass = true;
      curRow.netAfterDiscountBackup = curRow.netAfterDiscount;
      curRow.agencyCommission = this.calculateAgencyComission(curRow.netAfterDiscount, curRow.agencyCommissionPercent);
      curRow.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(curRow.netAfterDiscount, curRow.agencyCommission);
      curRow.costPerFrame = this.calculateCostPerFrame(curRow.netAfterAgencyCommission, curRow.numberFrames);
      curRow.isParent = false;
      curRow.isSelected = false;
      curRow.isVisible = true;
      if (curRow.optionStack) {
        curRow.negotiationTypeId = NegotiationTypes.Bonificada;
      } else {
        curRow.negotiationTypeId = curRow.negotiationTypeId && curRow.negotiationTypeId !== NegotiationTypes.Bonificada ? curRow.negotiationTypeId : -1;
      }
      this.rowData.push(curRow);
    }
  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinRowIndex: 1 },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
    this.footerRowData[0].negotiationTypeId = -1;

  }

  getGridSummary() {
    return `<span>${this.userBundle['discount.common.totalFrames']}: ${this.getChildRows().length} &#45;
    ${this.userBundle['discount.common.selectedFrames']}: ${this.getSelectedChildRows().length}</span>`;
  }

  weekPinnedRowCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {
      // apply Icon
      const eImgApply: HTMLImageElement = document.createElement('img');
      eImgApply.src = 'images/general/apply.png';
      eImgApply.title = 'Apply';
      eImgApply.onclick = this.apply;
      eDiv.appendChild(eImgApply);

      // reset Icon
      const eImgReset: HTMLImageElement = document.createElement('img');
      eImgReset.src = 'images/general/reset.png';
      eImgReset.title = 'Reset';
      eImgReset.onclick = this.reset;
      eDiv.appendChild(eImgReset);
    }
    return eDiv;
  }

  getCellClassChk(params) {
    const retArr: string[] = [];
    if (params.data.isSelected) {
      retArr.push('selectedRow');
    }
    if (params.node.rowPinned === 'bottom') {
      retArr.push('borderBottom');
    }
    return retArr;
  }

  // tslint:disable-next-line: no-big-function
  prepareColDef() {
    this.columnDefs = [
      {
        headerName: '', width: 30, editable: false,
        pinned: 'left', filter: false, colId: 'selectChk',
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: (params): string[] => this.getCellClassChk(params)
      },
      {
        headerName: this.userBundle['booking.grid.bookingReference'], field: 'bookingReference', width: 130, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.brazil.invoiceTypeName'], field: 'invoiceTypeName', width: 130, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.brazil.channelName'], field: 'channelName', width: 130, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.brazil.networkName'], field: 'networkName', width: 100, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.weekPinnedRowCellRenderer
      },
      {
        headerName: this.userBundle['discount.brazil.weekNumber'], field: 'weekNumberOfYear', width: 150, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.brazil.weekDays'], field: 'weekDays', width: 150, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.brazil.daterange'], field: 'week', width: 150, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer,
      },
      {
        headerName: this.userBundle['discount.brazil.numberFrames'], field: 'numberFrames', width: 100, editable: false,
        cellRendererFramework: AgNumberCellRendererComponent,
        pinnedRowCellRendererFramework: AgNumberCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return false;
          },
          calcCellValue: (rows) => {
            let avgNoOfFrames = 0;
            rows.forEach((row) => {
              avgNoOfFrames += row.numberFrames;
            });
            return avgNoOfFrames;
          },
        },
      },
      {
        headerName: this.userBundle['discount.brazil.negotiationType'],
        field: 'negotiationTypeId', width: 150, editable: true,
        cellRendererFramework: AgSelectCellRendererComponent,
        cellRendererParams: {
          cellEditable: (data) => {
            return !data.optionStack;
          },
          valKey: 'negotiationTypeId',
          textKey: 'negotiationTypeName',
          dataArr: (data) => {
            return data.optionStack ? this.negotiationTypes : this.negotiationTypes.filter(negotiationType => negotiationType.negotiationTypeId !== NegotiationTypes.Bonificada);
          }
        },
        pinnedRowCellRendererFramework: AgSelectCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: (data) => {
            return !data.optionStack;
          },
          valKey: 'negotiationTypeId',
          textKey: 'negotiationTypeName',
          dataArr: (data) => {
            return data.optionStack ? this.negotiationTypes : this.negotiationTypes.filter(negotiationType => negotiationType.negotiationTypeId !== NegotiationTypes.Bonificada);
          }
        },
        cellEditorFramework: AgSelectCellEditorComponent,
        cellEditorParams: {
          cellEditable: (data) => {
            return !data.optionStack;
          },
          valKey: 'negotiationTypeId',
          textKey: 'negotiationTypeName',
          dataArr: (data) => {
            return data.optionStack ? this.negotiationTypes : this.negotiationTypes.filter(negotiationType => negotiationType.negotiationTypeId !== NegotiationTypes.Bonificada);
          },
        }
      },
      {
        headerName: this.userBundle['discount.brazil.grossPrice'], field: 'grossPrice', width: 150, editable: false,
        cellRendererFramework: AgNumberCellRendererComponent,
        pinnedRowCellRendererFramework: AgNumberCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return false;
          },
          calcCellValue: (rows) => {
            let avgGrossPrice = 0;
            rows.forEach((row) => {
              avgGrossPrice += row.grossPrice;
            });
            return avgGrossPrice.toFixed(this.precisionOnPercentageDisplay);
          },
        },
      },
      {
        headerName: this.userBundle['discount.brazil.discount'], field: 'discount', width: 150, editable: true,
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          decimalPoints: this.precisionOnPercentageDisplay,
          calcCellValue: this.calcAvgDiscountPinnedRow,
          gridSumRow: 1
        },
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          decimalPoints: this.precisionOnPercentageDisplay,

        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentage,
          cellEditable: () => {
            return true;
          },
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.brazil.netAfterDiscount'], field: 'netAfterDiscount', width: 150, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          decimalPoints: this.precisionOnPercentageDisplay,
          prefixText: (params) => {
            if (params.netAfterDiscount === 0 || (params.netAfterDiscount && params.netAfterDiscount !== '')) {
              return '';
            } else {
              return this.userBundle['discount.common.total'];
            }
          },
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: () => {
            return true;
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: () => {
            return true;
          }
        }
      },
      {
        headerName: this.userBundle['discount.brazil.agencyCommissionPercent'],
        field: 'agencyCommissionPercent', width: 150, editable: true,
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return false;
          },
          decimalPoints: this.precisionOnPercentageDisplay,
          calcCellValue: this.calculateAvgAgencyCommissionPercentage,
          gridSumRow: 1
        },
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: () => {
            return false;
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: () => {
            return false;
          },
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.brazil.agencyCommission'],
        field: 'agencyCommission', width: 150, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return false;
          },
          prefixText: (params) => {
            return (params.agencyCommission === 0 || (params.agencyCommission && params.agencyCommission !== '')) ? '' : this.userBundle['discount.common.total'];
          },
          gridSumRow: 1
        }
      },
      {
        headerName: this.userBundle['discount.brazil.netAfterAgencyCommission'], field: 'netAfterAgencyCommission', width: 180, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          gridSumRow: 1
        }
      },
      {
        headerName: this.userBundle['discount.brazil.miscCost'], field: 'miscCost', width: 100, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          decimalPoints: this.precisionOnPercentageDisplay,
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          decimalPoints: this.precisionOnPercentageDisplay,
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: () => {
            return true;
          }
        }
      },
      {
        headerName: this.userBundle['discount.brazil.loadingFee'], field: 'loadingFee', width: 100, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          decimalPoints: this.precisionOnPercentageDisplay,
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          decimalPoints: this.precisionOnPercentageDisplay,
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: () => {
            return true;
          }
        }
      },
      {
        headerName: this.userBundle['discount.brazil.costPerFrame'], field: 'costPerFrame', width: 100, editable: false,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return false;
          },
          decimalPoints: this.precisionOnPercentageDisplay,
          gridSumRow: 1,
          calcCellValue: this.calculateAvgCostPerFrame
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return false;
          },
          decimalPoints: this.precisionOnPercentageDisplay,
        }
      }
    ];

    this.numericFields = [
      'numberFrames',
      'grossPrice',
      'discount',
      'netAfterDiscount',
      'agencyCommissionPercent',
      'agencyCommission',
      'netAfterAgencyCommission',
      'weekNumberOfYear'
    ];

    this.dateRangeFields = ['week'];
    this.selectFields = ['negotiationTypeId'];

    this.processColDef();
    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField, true);
  }

  processColDef() {
    const decimalFieldsForFilter = [
      'grossPrice',
      'discount',
      'netAfterDiscount',
      'agencyCommissionPercent',
      'agencyCommission',
      'netAfterAgencyCommission',
    ];
    for (let i = 1; i < this.columnDefs.length; i++) {
      if (this.columnDefs[i].field === 'negotiationTypeId') {
        this.columnDefs[i].floatingFilterComponentFramework = AgSelectFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          suppressFilterButton: true,
          valKey: 'negotiationTypeId',
          textKey: 'negotiationTypeName',
          dataArr: () => {
            return this.negotiationTypes;
          },
          value: ''
        };
      } else {
        this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          value: '',
          suppressFilterButton: true,
          decimalRound: decimalFieldsForFilter.indexOf(this.columnDefs[i].field) > -1 ? 2 : null
        };
      }

      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };
      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => this.cellClass(params, i);
    }
  }

  cellClass(params, i: number): string[] {
    const retArr: string[] = [];
    if (params.data.isParent || params.node.rowPinned === 'bottom') {
      retArr.push('fontBold');
    }
    if (params.node.rowPinned === 'bottom') {
      retArr.push('borderBottom');
    }
    if (params.data.isSelected) {
      retArr.push('selectedRow');
    }
    if (this.columnDefs[i].field === 'negotiationTypeId' &&
      (params.node.rowPinned !== 'bottom' && params.data.negotiationTypeId === -1 || params.data.negotiationTypeId === '-1')) {
      retArr.push('danger');
    }
    return retArr;
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
    // restore original data order when sorting is cleared
    if (this.sortObj.sortColumns.length === 0) {
      this.rowData = _.clone(this.campaignDetails.discounts);
    }
  }

  blankCellRenderer() {
    return '';
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.forEach((r) => {
        r.isFilterPass = true;
      });
    }
    // Refresh Grid Header checkbox component
    /* tslint:disable:no-string-literal */
    this.gridOptions.api['headerRootComp'].forEachHeaderElement((element) => {
      if (element.column.colId === 'selectChk' && element.columnApi && element.childComponents && element.childComponents.length > 1) {
        const headerCmpIns = element.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  clearAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    this.gridOptions.api.onFilterChanged();
  }

  apply = () => {
    const selectedChildRows = this.getSelectedChildRows();
    if (selectedChildRows.length > 0) {
      if ((this.footerRowData[0].negotiationTypeId ||
        this.footerRowData[0].discount === 0 || this.footerRowData[0].discount ||
        this.footerRowData[0].grossValue === 0 || this.footerRowData[0].grossValue ||
        this.footerRowData[0].agencyCommissionPercent === 0 || this.footerRowData[0].agencyCommissionPercent ||
        this.footerRowData[0].agencyCommission === 0 || this.footerRowData[0].agencyCommission ||
        this.footerRowData[0].netAfterDiscount === 0 || this.footerRowData[0].netAfterDiscount ||
        this.footerRowData[0].technicalFee === 0 || this.footerRowData[0].technicalFee ||
        this.footerRowData[0].miscCost === 0 || this.footerRowData[0].miscCost ||
        this.footerRowData[0].loadingFee === 0 || this.footerRowData[0].loadingFee
      )) {
        for (const row of selectedChildRows) {

          if (this.footerRowData[0].agencyCommission === 0 || this.footerRowData[0].agencyCommission) {
            row.agencyCommission = this.footerRowData[0].agencyCommission;
            row.agencyCommissionPercent = this.calculateAgencyCommissionPercent(row.agencyCommission, row.netAfterDiscount);
            row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
            row.costPerFrame = this.calculateCostPerFrame(row.netAfterAgencyCommission, row.numberFrames);
          }

          if (this.footerRowData[0].grossPrice === 0 || this.footerRowData[0].grossPrice) {
            row.grossPrice = this.footerRowData[0].grossPrice;
            row.netAfterDiscount = this.calculateNetAfterDiscount(row.grossPrice, row.discount);
            row.netAfterDiscountBackup = row.netAfterDiscount;
            row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount, row.agencyCommissionPercent);
            row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
            row.costPerFrame = this.calculateCostPerFrame(row.netAfterAgencyCommission, row.numberFrames);
          }

          if (this.footerRowData[0].discount === 0 || this.footerRowData[0].discount) {
            row.discount = this.footerRowData[0].discount;
            row.netAfterDiscount = this.calculateNetAfterDiscount(row.grossPrice, row.discount);
            row.netAfterDiscountBackup = row.netAfterDiscount;
            row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount,
              row.agencyCommissionPercent);
            row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount,
              row.agencyCommission);
            row.costPerFrame = this.calculateCostPerFrame(row.netAfterAgencyCommission, row.numberFrames);
          }

          if ((this.footerRowData[0].netAfterDiscount === 0 || this.footerRowData[0].netAfterDiscount) && Number(this.footerRowData[0].netAfterDiscount) !== Number(this.footerRowData[0].netAfterDiscountBackup)) {
            row.netAfterDiscount = this.footerRowData[0].netAfterDiscount;
            row.netAfterDiscountBackup = row.netAfterDiscount;
            row.discount = this.calculateDiscount(row.grossPrice, row.netAfterDiscount);
            row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount,
              row.agencyCommissionPercent);
            row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount,
              row.agencyCommission);
            row.costPerFrame = this.calculateCostPerFrame(row.netAfterAgencyCommission, row.numberFrames);
          }

          if (this.footerRowData[0].agencyCommissionPercent || this.footerRowData[0].agencyCommissionPercent === 0) {
            row.agencyCommissionPercent = this.footerRowData[0].agencyCommissionPercent;
            row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount, row.agencyCommissionPercent);
            row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
            row.costPerFrame = this.calculateCostPerFrame(row.netAfterAgencyCommission, row.numberFrames);
          }

          if (this.footerRowData[0].grossValue === 0 || this.footerRowData[0].grossValue) {
            row.grossValue = this.footerRowData[0].grossValue;
            row.netAfterDiscount = this.calculateNetAfterDiscount(row.grossValue, row.discount);
            row.netAfterDiscountBackup = row.netAfterDiscount;
          }

          if (this.footerRowData[0].negotiationTypeId && this.footerRowData[0].negotiationTypeId !== -1 && !row.optionStack) {
            row.negotiationTypeId = this.footerRowData[0].negotiationTypeId;
          }

          if ((this.footerRowData[0].netAfterDiscount === 0 || this.footerRowData[0].netAfterDiscount) && Number(this.footerRowData[0].netAfterDiscount) !== Number(this.footerRowData[0].netAfterDiscountBackup)) {
            row.netAfterDiscount = this.footerRowData[0].netAfterDiscount;
            row.netAfterDiscountBackup = row.netAfterDiscount;
            row.discount = this.calculateDiscount(row.grossPrice, row.netAfterDiscount);
          }

          if (this.footerRowData[0].miscCost === 0 || this.footerRowData[0].miscCost) {
            row.miscCost = this.footerRowData[0].miscCost;
          }

          if (this.footerRowData[0].loadingFee === 0 || this.footerRowData[0].loadingFee) {
            row.loadingFee = this.footerRowData[0].loadingFee;
          }

        }
        this.resetApply();
        this.rowData = _.clone(this.rowData);
      }
    } else {
      this.toaster.logInfo('Select atleast one row!');
    }
  }

  reset = () => {
    this.resetApply();
    this.rowData = _.clone(this.rowData);
  }

  resetApply = () => {
    const columnArray = ['negotiationTypeId', 'discount', 'netAfterDiscount', 'smartContentValue', 'uploadCostValue', 'grossValue', 'agencyCommissionPercent', 'agencyCommission', 'netAfterAgencyCommission', 'miscCost', 'loadingFee'];
    for (const column of columnArray) {
      this.footerRowData[0][column] = null;
    }
    this.footerRowData[0].negotiationTypeId = -1;
  }

  // Data functions
  getChildRows(): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData);
  }

  getSelectedChildRows(): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData);
  }

  calcAvgDiscount(rows) {
    let avgDiscount = 0;
    let avgGrossPrice = 0;
    let avgNetAfterDiscount = 0;
    rows.forEach((row) => {
      avgGrossPrice += parseFloat(row.grossPrice);
      avgNetAfterDiscount += parseFloat(row.netAfterDiscount);
    });
    if (avgGrossPrice) {
      avgDiscount = 100 - ((avgNetAfterDiscount * 100) / avgGrossPrice);
    }
    return avgDiscount;
  }

  calcAvgDiscountPinnedRow = (rows) => {
    if (this.footerRowData) {
      if (this.footerRowData[0].discount === 0 || (this.footerRowData[0].discount && this.footerRowData[0].discount !== '')) {
        return Number(this.footerRowData[0].discount).toFixed(this.precisionOnPercentage);
      } else {
        return this.calcAvgDiscount(rows);
      }
    } else {
      return this.calcAvgDiscount(rows);
    }
  }

  calculateDiscount(grossValue, netAfterDiscount) {
    const mediaValue = parseFloat(grossValue);
    const agencyNet = parseFloat(netAfterDiscount);
    const discount = (mediaValue !== 0) ? ((mediaValue - agencyNet) / mediaValue * 100) : 0;
    return Number(discount).toFixed(this.precisionOnPercentage);
  }

  /**
   * download cart data in excel format
   */
  downloadExcel() {
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    // Add Header Row
    let sheetRow: ExcelCell[] = [];
    for (let j = 1; j < this.columnDefs.length; j++) {
      const cell: ExcelCell = {
        text: this.columnDefs[j].headerName,
        fontColor: '#FFFFFF',
        bgColor: '#000000',
        borderColor: '#CCCCCC',
        isFontBold: true
      };
      const col: ExcelColumn = {
        width: this.columnDefs[j].field === 'week' ? 15 : 12
      };
      excelColumn.push(col);
      sheetRow.push(cell);
    }
    sheetData.push(sheetRow);

    // Add Data rows
    let rowsInserted = 0;
    for (const row of this.rowData) {
      if (row.isParent) {
        continue;
      }

      sheetRow = [];
      for (let j = 1; j < this.columnDefs.length; j++) {
        let cellVal = row[this.columnDefs[j].field];
        if (this.selectFields.indexOf(this.columnDefs[j].field) > -1) {
          cellVal = row[`${this.columnDefs[j].field}_sort`];
        }
        const cell: ExcelCell = {
          text: cellVal,
          fontColor: '#000000',
          bgColor: rowsInserted % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
          borderColor: '#CCCCCC'
        };
        sheetRow.push(cell);
      }
      rowsInserted++;
      sheetData.push(sheetRow);
    }
    const cartText = 'discount';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }

  toggleColumnVisible(colId, isVisible) {
    const isVisibleLocal = !isVisible;
    this.gridOptions.columnApi.setColumnVisible(colId, isVisibleLocal);
  }

  trackByField(index, column) {
    return column?.colDef?.field;
  }

}
