/**
 * @description Based on this flag commercial button will nbe show/hide or enable/disable
 * @author Darshan Vachhani
 * @export
 * @class CommercialButtonVisibility
 */
export class CommercialButtonVisibility {
  public disabledCancelButton = false;
  public showCancelButton = false;
  public disabledUpdateButton = false;
  public showUpdateButton = false;
  public disabledOptionButton = false;
  public showOptionButton = false;
  public disabledBookButton = false;
  public showBookButton = false;

  public disabledRequestBookingButton = false;
  public showRequestBookingButton = false;
  public disabledRequestOptionButton = false;
  public showRequestOptionButton = false;
  public showProposalButton = false;
  public showPerformanceButton = false;
  public disablePerformanceButton = false;
  public disabledSaveButton = false;
  public showSaveButton = false;
  public showRevertOptionToProposal = false;

  public showConfirmProposalButton = false;
}
