<div class="mapview_geo drag-boundary" #mapBounds
  [ngClass]="{ 'top-b': !edge?.top, 'bottom-b': !edge?.bottom, 'left-b': !edge?.left, 'right-b': !edge?.right }">
  <!-- <agm-map class="mapview_geo"
  [latitude]="userData.latitude"
  [longitude]="userData.longitude"
  [zoom]="userData.zoomLevel || 5" (mapReady)="onMapReady($event)" (mapClick)="onMapClick($event)" (idle)="onIdle()">
    <agm-marker [latitude]="lat" [longitude]="lng" [visible]="placeHighlighterMarker.visible"
    [iconUrl]="placeHighlighterMarker.icon">
    </agm-marker>

    <agm-info-window [closeWhenOthersOpen]="true" [isOpen]="isInfoWindowOpen" [latitude]="infoWindowObj ? infoWindowObj.latitude : ''" [longitude]="infoWindowObj ? infoWindowObj.longitude : ''" [maxWidth]="200" [ngClass]="{
      readOnly: systemFlags.readOnlyWorkspace && !systemFlags.splitable
    }" id="iw_marker" #infoWindow [closeOnMapClick]="true">
    <ng-template *ngIf="selectedAssetsData.length > 0" [ngTemplateOutlet]="FurnitureDetails"></ng-template>
    </agm-info-window>

    <div id="draggablePopup" ngDraggable [position]="defaultFurnitureDetailsPosition" [bounds]="mapBounds" [inBounds]="inBounds" *ngIf="isMarkerSelected">
      <div id="draggablePopupHeader">{{
        initialConfig.userBundle["geoplanner.text.clickHereToMove"] ||
          "Click here to move"
      }}
      </div>
      <ng-template [ngTemplateOutlet]="FurnitureDetails"></ng-template>
    </div>

    <agm-info-window [ngClass]="{
      readOnly: systemFlags.readOnlyWorkspace && !systemFlags.splitable
    }" id="iw_circle" #circleInfoWindow [closeWhenOthersOpen]="true" [latitude]="circleInfoWindowObj ? circleInfoWindowObj.latitude : ''" [longitude]="circleInfoWindowObj ? circleInfoWindowObj.longitude : ''" [isOpen]="isCircleInfoWindowOpen">
      <div class="modal_popover circle_info">
        <div class="titlebar">
          {{
            initialConfig.userBundle["geoplanner.text.poidetails"] ||
              "POI Details"
          }}
          <button class="close" (click)="closeInfoWindow('circle')">x</button>
        </div>
        <div class="row">
          <div class="col-md-5">
            <div class="form-group_geo horizontal_geo">
              <input type="text" [appOnlyNumber]="true" [decimalPoints]="drawCircleValues.decimalPoints"
                [(ngModel)]="drawCircleValues.radius" class="form-control_geo" placeholder="{{
                  initialConfig.userBundle['geoplanner.text.radius'] || 'radius'
                }}" name="cir_radius" id="cir_radius" />
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group_geo horizontal_geo">
              <select class="form-control_geo" name="cir_unit" id="cir_unit" [(ngModel)]="drawCircleValues.unit"
                (change)="onUnitChange()">
                <option value="3">
                  {{ initialConfig.userBundle["common.feet"] || "Feet" }}
                </option>
                <option value="0" selected>
                  {{ initialConfig.userBundle["common.metres"] }}
                </option>
                <option value="1">
                  {{ initialConfig.userBundle["common.kilometres"] }}
                </option>
                <option value="2">
                  {{ initialConfig.userBundle["common.miles"] }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <button class="btn btn-success" [disabled]="!drawCircleValues.radius" (click)="drawCircle()">
              {{ initialConfig.userBundle["common.ok"] }}
            </button>
          </div>
        </div>
      </div>
    </agm-info-window>


  </agm-map>  -->
  <div #mapObj id="mapObj" style="width:100%;height:100%"></div>
  <div #infoWindow>
    <ng-template *ngIf="selectedAssetsData.length > 0 && !draggableFurnitureSelectionBox" [ngTemplateOutlet]="FurnitureDetails"></ng-template>
  </div>
  <div id='draggableFurniturePopup' class="draggable-padding">
    <div id="draggablePopup" ngDraggable [position]="defaultFurnitureDetailsPosition"
      [bounds]="mapBounds" [inBounds]="inBounds" *ngIf="isMarkerSelectedForDraggable && selectedAssetsData.length > 0">
      <div id="draggablePopupHeader">{{
        initialConfig.userBundle["geoplanner.text.clickHereToMove"] ||
        "Click here to move"
        }}
      </div>
      <ng-template [ngTemplateOutlet]="FurnitureDetails"></ng-template>
    </div>
  </div>
  <div #circleInfoWindow>
    <div class="modal_popover circle_info" *ngIf="isCircleInfoWindowOpen">
      <div class="titlebar">
        {{
          initialConfig.userBundle["geoplanner.text.poidetails"] ||
            "POI Details"
        }}
        <button class="close" (click)="closeInfoWindow('circle')">x</button>
      </div>
      <div class="row">
        <div class="col-md-5">
          <div class="form-group_geo horizontal_geo">
            <input type="text" [appOnlyNumber]="true" [decimalPoints]="drawCircleValues.decimalPoints"
              [(ngModel)]="drawCircleValues.radius" class="form-control_geo" placeholder="{{
                initialConfig.userBundle['geoplanner.text.radius'] || 'radius'
              }}" name="cir_radius" id="cir_radius" />
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group_geo horizontal_geo">
            <select class="form-control_geo" name="cir_unit" id="cir_unit" [(ngModel)]="drawCircleValues.unit"
              (change)="onUnitChange()">
              <option value="3">
                {{ initialConfig.userBundle["common.feet"] || "Feet" }}
              </option>
              <option value="0" selected>
                {{ initialConfig.userBundle["common.metres"] }}
              </option>
              <option value="1">
                {{ initialConfig.userBundle["common.kilometres"] }}
              </option>
              <option value="2">
                {{ initialConfig.userBundle["common.miles"] }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <button class="btn btn-success" [disabled]="!drawCircleValues.radius" (click)="drawCircle()">
            {{ initialConfig.userBundle["common.ok"] }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <button id="campaign-button" [ngClass]="{
      slideLegendMoreLeft:
        isLegendVisible ||
        isCartVisible ||
        isCampaignVisible ||
        isIndoorMapVisible
    }" (click)="toggleCampaign()" [hidden]="!isMapLoaded" class="btn btn-primary map_btn" title="{{
      initialConfig.userBundle['geoplanner.text.otherCampaign'] || 'Campaign'
    }}">
    <em class="fa fa-object-group"></em>
  </button>
  <button id="cart-button" [ngClass]="{
      slideCartMoreLeft:
        isLegendVisible ||
        isCartVisible ||
        isCampaignVisible ||
        isIndoorMapVisible
    }" (click)="toggleCart()" [hidden]="!isMapLoaded" class="btn btn-primary map_btn" title="{{
      initialConfig.userBundle['geoplanner.text.togglecart'] || 'Toggle Cart'
    }}">
    <em class="fa fa-shopping-cart"></em>
  </button>
  <button id="result-button" [ngClass]="{
      slideResultMoreLeft:
        isLegendVisible ||
        isCartVisible ||
        isCampaignVisible ||
        isIndoorMapVisible
    }" (click)="redirectToResultPage()" [disabled]="cartService.cartData.length == 0"
    [hidden]="!isMapLoaded" class="btn btn-primary map_btn" title="{{
      initialConfig.userBundle['geoplanner.text.gotoresult'] || 'Go to Result'
    }}">
    <em class="fa fa-arrow-circle-right"></em>
  </button>
  <button id="legend-button" [ngClass]="{
      slideLegendMoreLeft:
        isLegendVisible ||
        isCartVisible ||
        isCampaignVisible ||
        isIndoorMapVisible
    }" (click)="toggleLegend()" [disabled]="assetsData.length == 0" [hidden]="!isMapLoaded"
    class="btn btn-primary map_btn" title="{{
      initialConfig.userBundle['geoplanner.text.togglelegend'] || 'Toggle Legend'
    }}">
    <em class="fa fa-map-marker"></em>
  </button>
  <button id="indoor-button" [ngClass]="{
      slideLegendMoreLeft:
        isLegendVisible ||
        isCartVisible ||
        isCampaignVisible ||
        isIndoorMapVisible
    }" (click)="toggleIndoorMap()" [disabled]="indoorMaps.length == 0" [hidden]="!isMapLoaded"
    class="btn btn-primary map_btn" title="{{
      initialConfig.userBundle['geoplanner.text.indoorMaps'] || 'Indoor Maps'
    }}">
    <em class="fa fa-map-o"></em>
  </button>
  <div ngbDropdown id="switchMarker-button" [ngClass]="{
      slideLegendMoreLeft:
        isLegendVisible ||
        isCartVisible ||
        isCampaignVisible ||
        isIndoorMapVisible
    }" [autoClose]="false" [placement]="'top'" #mapSettingContainer class="form-control_geo dropdown">
    <button class="btn btn-primary map_btn" ngbDropdownToggle>
      <em class="fa fa-cog"></em>
    </button>
    <div class="dropdown-menu custom_drop_menu " [ngClass]="markers.length > 0 ? 'custom_drop_menu_marker-text' : ''" aria-labelledby="dropdownBasic1" ngbDropdownMenu>
      <div class="mapsetting-item">
        <ui-switch [(ngModel)]="showMarker" size="small" (change)="markerShowHideChanged()"
          [disabled]="!activeIndoorMapId"></ui-switch>
        <span>&nbsp;&nbsp;{{initialConfig.userBundle["geoplanner.text.showMarker"] || "Show Marker"}}</span>
      </div>
      <div class="mapsetting-item">
        <ui-switch [(ngModel)]="isDefaultMap" size="small" (change)="changeMapStyle()" [disabled]="
            !(
              activeIndoorMapId &&
              initialConfig.uiControl.hideShowGMapBackgroundEnabled
            )
          "></ui-switch>
        <span>&nbsp;&nbsp;{{
            initialConfig.userBundle["geoplanner.text.defaultMap"] ||
              "Default Map"
          }}</span>
      </div>
      <div class="mapsetting-item">
        <ui-switch [(ngModel)]="labelMarker" size="small" (change)="toggleLabelMarker()"></ui-switch>
        <span>&nbsp;&nbsp;{{
            initialConfig.userBundle["geoplanner.text.markerLabel"] ||
              "Marker Label"
          }}</span>
      </div>
      <div class="mapsetting-item">
        <ui-switch [(ngModel)]="colorMarker" size="small" (change)="toggleColorMarker()"></ui-switch>
        <span>&nbsp;&nbsp;{{
            initialConfig.userBundle["geoplanner.text.markerColor"] ||
              "Marker Color"
          }}</span>
      </div>
      <div class="mapsetting-item mapsetting-item-compact">
        <ui-switch [(ngModel)]="compactMarker" size="small" (change)="toggleCompactMarker()"></ui-switch>
        <span>&nbsp;&nbsp;{{
            initialConfig.userBundle["geoplanner.text.compactMarker"] ||
              "Compact Marker"
          }}</span>
      </div>
      <div class="mapsetting-item" *ngIf="initialConfig.uiControl.shortenFurnitureCode">
        <ui-switch [(ngModel)]="shortenMarker" size="small" (change)="toggleMarkerLabels()"></ui-switch>
        <span>&nbsp;&nbsp;{{
            initialConfig.userBundle["geoplanner.text.shortenMarkers"] ||
              "Shorten Markers"
          }}</span>
      </div>
      <div class="mapsetting-item frame-orientation" *ngIf="markers.length && enableShowFrameOrientations">
        <ui-switch [(ngModel)]="showFrameIcons" size="small" (change)="toggleShowFrameIcons()"></ui-switch>
        <span>&nbsp;&nbsp;{{
            initialConfig.userBundle["geoplanner.text.showFrameOrientation"] || "Show Orientation"
          }}</span>
      </div>
      <div class="mapsetting-item" *ngIf="markers.length > 0">
        <hr class="custom_drop_menu_marker-icon-margin" *ngIf="markers.length > 0">
        <span>{{
            initialConfig.userBundle["geoplanner.text.markerIconSize"] ||
              "Marker Icon Size"
          }}</span>
          <div class="full-width sot-slider">
            <ngx-slider [(value)]="sliderMarkerIconSizeSelectedValue" [options]="sliderMarkerIconSizeoptions" (userChangeEnd)="updateMapMarkerIconTextSize()"></ngx-slider>
          </div>
      </div>
      <div class="mapsetting-item" *ngIf="markers.length > 0 && labelMarker">
        <hr class="custom_drop_menu_marker-text-margin" *ngIf="markers.length > 0">
        <span>{{
            initialConfig.userBundle["geoplanner.text.markerTextSize"] ||
              "Marker Text Size"
          }}</span>
          <div class="full-width sot-slider">
            <ngx-slider [(value)]="sliderMarkerTextSizeSelectedValue" [options]="sliderMarkerTextSizeoptions" (userChangeEnd)="updateMapMarkerIconTextSize()"></ngx-slider>
          </div>
      </div>
      <div *ngIf= "initialConfig.uiControl.enableIconSwitcher" class="mapsetting-item icon-family">
        <hr class="custom_drop_menu_marker-text-margin" *ngIf="markers.length > 0">
        <span>{{
          initialConfig.userBundle["common.icon.switcher"] || "Icon"
          }}</span>
          <div class="full-width sot-slider">
            <div *ngFor="let lookup of iconImageOptions ; trackBy: trackBySelectionId; let i = index;" id="selection-{{i}}" class="markericon-setting">
            <input type="radio" [checked]="selectedIconImage === lookup.name" (change)="onIconImageChange($event)"
              name="rb-location" id="rb-{{i}}" [value]="lookup.name" />
            <label
              class="radio-label" for="rb-{{i}}">{{initialConfig.userBundle[lookup.labelName] || lookup.name}}</label>
            </div>
          </div>
      </div>

    </div>
  </div>
  <ng-select id="indoormapDropdown" [(ngModel)]="activeIndoorMapId" (change)="onIndoorMapDrpChange()"
    name="indoormapDropdown" [ngStyle]="{ display: indoorMapsDrp.length == 0 ? 'none' : 'block' }" placeholder="{{
      initialConfig.userBundle['geoplanner.text.indoorMaps'] || 'Indoor Maps'
    }}" [items]="indoorMapsDrp" bindLabel="mapName" bindValue="mapId">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <div [title]="item.mapName">{{item.mapName}}</div>
  </ng-template>
  </ng-select>

  <div [(ngModel)]="drawingControlActiveBtn" [hidden]="!isMapLoaded" [ngClass]="{
      readOnly: initialConfig.readOnlyWorkspace && !initialConfig.splitable
    }" (change)="onDrawingControlActionChange()" class="btn-group drawinggrp" ngbRadioGroup name="radioBasic"
    id="drawingControlBtnGrp">
    <label ngbButtonLabel class="btn btn-primary drawingbtn" ngbTooltip="{{
        initialConfig.userBundle['geoplanner.text.drag'] || 'Drag'
      }}" [placement]="'bottom'">
      <input ngbButton type="radio" [value]="1" />
      <em class="fa fa-hand-paper-o"></em>
    </label>
    <label ngbButtonLabel class="btn btn-primary drawingbtn" ngbTooltip="{{
        initialConfig.userBundle['geoplanner.text.addpoi'] || 'Add POI'
      }}" [placement]="'bottom'">
      <input ngbButton type="radio" [value]="2" />
      <em class="fa fa-map-marker"></em>
    </label>
    <label ngbButtonLabel class="btn btn-primary drawingbtn" ngbTooltip="{{
        initialConfig.userBundle['geoplanner.text.drawcircle'] || 'Draw Circle'
      }}" [placement]="'bottom'">
      <input ngbButton type="radio" [value]="3" />
      <em class="fa fa-circle-o"></em>
    </label>
    <label ngbButtonLabel class="btn btn-primary drawingbtn" ngbTooltip="{{
        initialConfig.userBundle['geoplanner.text.drawpolygon'] || 'Draw Polygon'
      }}" [placement]="'bottom'">
      <input ngbButton type="radio" [value]="4" />
      <em class="fa fa-star-o"></em>
    </label>
    <label ngbButtonLabel class="btn btn-primary drawingbtn" ngbTooltip="{{
        initialConfig.userBundle['geoplanner.text.drawrectangle'] ||
          'Draw Rectangle'
      }}" [placement]="'bottom'">
      <input ngbButton type="radio" [value]="5" />
      <em class="fa fa-square-o"></em>
    </label>
    <label ngbButtonLabel class="btn btn-primary drawingbtn" ngbTooltip="{{
        initialConfig.userBundle['geoplanner.text.deleteshape'] ||
          'Delete Selected Shape'
      }}" [placement]="'bottom'">
      <input ngbButton type="radio" [value]="6" />
      <em class="fa fa-trash-o"></em>
    </label>
  </div>

  <div [(ngModel)]="selectedFrameFilter" [hidden]="!isMapLoaded" (change)="onFrameFilterChange()"
    class="btn-group drawinggrp right" ngbRadioGroup name="radioBasic" id="selectedFrameFilterBtnGrp">
    <label ngbButtonLabel class="btn btn-primary drawingbtn"
      ngbTooltip="{{ initialConfig.userBundle['geoplanner.text.all'] || 'All' }}" [placement]="'bottom'">
      <input ngbButton type="radio" [value]="frameFilterType.all" />
      <em class="icon">
        <img alt="" src="images/all_frames.png" />
      </em>
      {{ allFrameOnMap }}
    </label>
    <label ngbButtonLabel class="btn btn-primary drawingbtn" ngbTooltip="{{
        initialConfig.userBundle['geoplanner.text.available'] || 'Available'
      }}" [placement]="'bottom'">
      <input ngbButton type="radio" [value]="frameFilterType.available" />
      <em class="icon">
        <img alt="" src="images/available_frames.png" />
      </em>
      {{ availableFrameOnMap }}
    </label>
    <label ngbButtonLabel class="btn btn-primary drawingbtn" ngbTooltip="{{
        initialConfig.userBundle['geoplanner.text.selected'] || 'Selected'
      }}" [placement]="'bottom'">
      <input ngbButton type="radio" [value]="frameFilterType.selected" />
      <em class="icon">
        <img alt="" src="images/selected_frames.png" />
      </em>
      {{ selectedFrameOnMap }}
    </label>
  </div>


  <button id="viewCartmapbtn" (click)="onViewCartOnMapClick()" [disabled]="cartService.cartData.length ==0" [ngClass]="{'active': isViewCartOnMap}"
      [hidden]="!isMapLoaded" class="btn btn-primary view_cart_map_btn viewCartmapbtn" ngbTooltip="{{initialConfig.userBundle['geoplanner.text.viewcartonmap'] || 'Cart'}}"
      [placement]="'bottom'">
      <em class="icon viewCartmapbtn-icon">
          <img alt="" src="images/cartframes.png">
          <img class="cart-tick-img" *ngIf="isViewCartOnMap" alt="" src="images/tickblueicon.png">
      </em> {{initialConfig.userBundle['geoplanner.text.cart'] || 'Cart'}}
  </button>

  <!-- <div [(ngModel)]="viewBy" [hidden]="!isMapLoaded" (change)="onViewByChange()" class="btn-group drawinggrp" ngbRadioGroup name="radioBasic" id="viewByBtnGrp">
      <label ngbButtonLabel class="btn btn-primary drawingbtn" ngbTooltip="{{initialConfig.userBundle['geoplanner.text.viewbyfurniture'] || 'View by furniture'}}" [placement]="'bottom'">
          <input ngbButton type="radio" [value]="mapViewBy.furniture"><em class="fa fa-hand-paper-o"></em>
      </label>
      <label ngbButtonLabel class="btn btn-primary drawingbtn" ngbTooltip="{{initialConfig.userBundle['geoplanner.text.viewbyframe'] || 'View by frame'}}" [placement]="'bottom'">
          <input ngbButton type="radio" [value]="mapViewBy.frame"><em class="fa fa-map-marker"></em>
      </label>
  </div> -->
  <div id="placeAutoSearch">
      <input places-auto-complete id="inputAutoSearch" (keyup)="onPlaceInputChange($event)"
      (place_changed)="placeChanged($event)" [disabled]="activeIndoorMapId" />
    <button (click)="onClearAutoSearch()" [disabled]="activeIndoorMapId">
      X
    </button>
  </div>
  <div id="frameSearch">
    <input id="inputFrameSearch" [(ngModel)]="frameSearchObj" [disabled]="assetsData.length == 0"
      [ngbTypeahead]="searchFrameOnMap" (selectItem)="onSearchFrameSelect($event)"
      [resultFormatter]="searchFrameFormatter" [inputFormatter]="searchFrameInputFormatter" type="text"
      placeholder="{{initialConfig.userBundle['geoplanner.text.searchFrame'] || 'Search Frame'}}" />
    <button class="closeFrame" (click)="onClearFrameSearch()" [disabled]="assetsData.length == 0">
      X
    </button>
  </div>
  <!-- Cart box -->
  <div class="sidebar-left_geo sidebar-right_geo cart_summarybar" [ngStyle]="{
      right: isCartVisible ? '0px' : '-' + cartWidth + 'px',
      width: cartWidth + 'px'
    }">
    <app-cart-summary [initialConfig]="initialConfig" (cellClick)="onCartSummaryCellClick($event)"
      (delete)="onDeleteFromCartSummary()" (furnitureIconClick)="onShowFurnitureImages($event)"
      (closeCartSummary)="toggleCart()" (cartDetailClose)="onCartDetailClose($event)">
    </app-cart-summary>
  </div>

  <app-booking-details [initialConfig]="initialConfig" [bookingDetailsParams]="bookingDetailsParams">
  </app-booking-details>

  <!-- Legend box -->
  <div class="sidebar-left_geo sidebar-right_geo legendsbar_geo w-350"
    [ngStyle]="{ right: isLegendVisible ? '0px' : '-350px' }">
    <div class="sidebar-wrapper_geo" [ngClass]="{
        readOnly: systemFlags.readOnlyWorkspace && !systemFlags.splitable
      }">
      <button class="filter-link_geo w-100-p">
        {{ initialConfig.userBundle["result.map.legend.label"] }}
        <span class="pull-right" (click)="toggleLegend()">
          <em class="fa fa-close"></em>
        </span>
      </button>
      <br />
      <div class="row">
        <div class="col-md-12">
          <div *ngFor="let legend of iconLegendData; trackBy: trackByItem;" class="legend-item">
            <div class="col-md-12 small legend_line">
              <table aria-label="legend">
                <tr *ngFor="let name of legend.subFamilyNames; trackBy: trackByItem; let isFirst = first">
                  <td *ngIf="isFirst" [attr.rowspan]="legend.subFamilyNames.length" width="50px">
                    <img *ngIf="legend.iconType === 'png'" class="png-img" [src]="legend.icon" alt="" />
                    <img *ngIf="legend.iconType === 'svg'" class="svg-img" [src]="legend.icon"
                      alt="" />
                  </td>
                  <td class="legend-subfamily">
                    <span>{{ name }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Legend box ends  -->

  <!-- other campaign -->
  <div class="sidebar-left_geo sidebar-right_geo cart_summarybar" [ngStyle]="{
      right: isCampaignVisible ? '0px' : '-' + cartWidth + 'px',
      width: cartWidth + 'px'
    }">
    <div class="sidebar-wrapper_geo">
      <button class="filter-link_geo w-100-p p-0-5">
        {{
          initialConfig.userBundle["geoplanner.text.otherCampaign"] || "Campaign"
        }}
        <span class="pull-right" (click)="toggleCampaign()">
          <em class="fa fa-close"></em>
        </span>
      </button>

      <div class="row m-t-30">
        <div class="campaign-list-input tab-content d-b">
          <div *ngIf="openedCampaigns.length == 4" class="campaignMaxItemValid">
            {{
              initialConfig.userBundle["geoplanner.text.maxcampaignlimit"] ||
                "Maximum 4 campaigns are allowed"
            }}
          </div>
          <tag-input [(ngModel)]="openedCampaigns" [maxItems]="4" [identifyBy]="'campaignId'"
            [displayBy]="'campaignLookupDisplay'" [onlyFromAutocomplete]="true" (onAdd)="onAddInCampaignList($event)"
            [placeholder]="
              initialConfig.userBundle['geoplanner.text.searchothercampaign'] ||
              'Search by Campaign Ref'
            " [secondaryPlaceholder]="
              initialConfig.userBundle['geoplanner.text.searchothercampaign'] ||
              'Search by Campaign Ref'
            " [maintainSearchText]="false" [showDropdownIfEmpty]="false" [inputClass]="'form-control'">
            <ng-template let-item="item" let-index="index"> </ng-template>
            <tag-input-dropdown [showDropdownIfEmpty]="false" [identifyBy]="'campaignId'"
              [displayBy]="'campaignLookupDisplay'" [minimumTextLength]="1"
              [autocompleteObservable]="loadOtherCampaignList" [visibleMenuAfterItemAdd]="false"
              [maintainSearchText]="false" [appendToBody]="false" [matchingFn]="otherCampaignMatchingFn"
              [inputClass]="'form-control'">
            </tag-input-dropdown>
          </tag-input>

          <ul class="summaryContainer">
            <li *ngFor="let item of openedCampaigns; trackBy: trackByCampaignId; let i = index" [ngStyle]="{ 'background-color': item.plotColor }">
              <label class="summary-label">
                <div class="summary-ref">
                  {{ item.campaignReference }}
                </div>
                <div>
                  {{ item.campaignStatusName }}
                </div>
                <div>{{ item.startDate }} to {{ item.endDate }}</div>
                <div>
                  {{ item.advertiserName }}
                </div>
                <div>
                  {{ item.brandName }}
                </div>
              </label>
              <span class="summary-span">
                <input type="radio" [(ngModel)]="item.exclude" (change)="toggleCampaignExclude()"
                  class="radio small include" name="campaign-tag-{{ i }}" [value]="false"
                  id="campaign-include-tag-{{ i }}" checked />
                <label for="campaign-include-tag-{{ i }}">&nbsp;</label>
                <input type="radio" [(ngModel)]="item.exclude" (change)="toggleCampaignExclude()"
                  class="radio small exclude" name="campaign-tag-{{ i }}" [value]="true"
                  id="campaign-exclude-tag-{{ i }}" />
                <label for="campaign-exclude-tag-{{ i }}">&nbsp;</label>
                <span class="right summary-right-span" (click)="removeCampaignFromList(i)">
                  <em class="fa fa-times" aria-hidden="true"></em>
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- other campaign ends  -->

  <!-- Indoor Map -->
  <div class="sidebar-left_geo sidebar-right_geo cart_summarybar" [ngStyle]="{
      right: isIndoorMapVisible ? '0px' : '-' + cartWidth + 'px',
      width: cartWidth + 'px'
    }">
    <div class="sidebar-wrapper_geo">
      <button class="filter-link_geo p-0-5 w-100-p">
        {{
          initialConfig.userBundle["geoplanner.text.indoorMaps"] || "Indoor Maps"
        }}
        <span class="pull-right" (click)="toggleIndoorMap()">
          <em class="fa fa-close"></em>
        </span>
      </button>

      <div class="cart_grid_container" id="dvindoormaplistcnt">
        <div class="list" *ngFor="let item of indoorMaps; trackBy: trackByItem;">
          <div class="media-title">
            <div class="media-body">
              <h4 class="mt-0">{{ item.geoAreaName }}</h4>
            </div>
          </div>
          <hr />
          <div class="list-item" *ngFor="let indmap of item.levels; trackBy: trackByIndmap;">
            <div class="media-data">
              <div class="media-body">
                <div class="map-item-cnt" [ngClass]="{ active: activeIndoorMapId == indmap.mapId }"
                  (click)="loadIndoorMap(indmap)">
                  <span *ngIf="activeIndoorMapId == indmap.mapId" class="pull-right" (click)="clearIndoorMap($event)">
                    <em class="fa fa-times-circle-o"></em>
                  </span>
                  <p>
                    <span>
                      {{
                        initialConfig.userBundle["geoplanner.text.mapCode"] ||
                          "Map Code"
                      }}:
                    </span>
                    <small class="text-secondary">
                      <strong>{{ indmap.mapCode }}</strong>
                    </small>
                  </p>
                  <p>
                    <span>
                      {{
                        initialConfig.userBundle["geoplanner.text.mapName"] ||
                          "Map Name"
                      }}:
                    </span>
                    <small class="text-secondary">
                      <strong>{{ indmap.mapName }}</strong>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Indoor Map ends -->

  <!-- Frame Detail  -->
  <div class="modal_popover bottom framedetailclass frame-detail-modal" *ngIf="frameDetailObj.frame" (mouseleave)="onHideFrameDetail($event)"
    [ngStyle]="{ top: frameDetailObj.top, left: frameDetailObj.left }">
    <div class="titlebar">
      {{
        initialConfig.userBundle["geoplanner.text.framedetail"] || "Frame Details"
      }}
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="slider">
          <div class="slides">
            <p>
              <span>{{
                  initialConfig.userBundle["geoplanner.text.frames"] || "Frames"
                }}:</span>
              {{ frameDetailObj.frame.frameName }}
            </p>
            <p>
              <span>{{
                  initialConfig.userBundle["geoplanner.text.frame_type"] ||
                    "Frame Type"
                }}: </span>{{ frameDetailObj.frame.frameTypeName }}
            </p>
            <p>
              <span>{{
                  initialConfig.userBundle["geoplanner.text.format"] || "Format"
                }}:
              </span>
              {{ frameDetailObj.frame.productFormatName }}
            </p>
            <p *ngIf="frameDetailObj.frame.availability">
              <span>{{
                  initialConfig.userBundle["geoplanner.text.availability"] ||
                    "Availability"
                }}:
              </span>
              {{ frameDetailObj.frame.availability }}
            </p>
            <p *ngIf="
                frameDetailObj.frame.price || frameDetailObj.frame.price == 0
              ">
              <span>{{
                  initialConfig.userBundle["geoplanner.text.price"] || "Price"
                }}:</span>
              {{ frameDetailObj.frame.price | currencyFormat }}
            </p>
            <p *ngIf="frameDetailObj.frame.framePositionCode">
              <span>{{
                  initialConfig.userBundle["geoplanner.text.framePositionCode"] || "Position"
                }}:</span>
              {{ frameDetailObj.frame.framePositionCode }}
            </p>
            <p *ngIf="frameDetailObj.frame.sitePositionCode">
              <span>{{
                  initialConfig.userBundle["geoplanner.text.sitePositionCode"] || "Site"
                }}:</span>
              {{ frameDetailObj.frame.sitePositionCode }}
            </p>
            <p *ngIf="showFrameIcons && (frameDetailObj.frame.frameOrientationName || frameDetailObj.frame.frameOrientationName !== '')">
              <span>{{
                  initialConfig.userBundle["geoplanner.text.orientation"] || "Orientation"
                }}:</span>
              {{ frameDetailObj.frame.frameOrientationName }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Frame Detail ends  -->
  <div class="clearfix"></div>

  <div class="svgCircleShape-parent">
    <svg id="svgCircleShape" xmlns="http://www.w3.org/2000/svg" width="10px" height="10px" version="1.1" x="0px" y="0px"
      xml:space="preserve">
      <rect x="0%" y="0%" width="10px" height="10px" style="transform-origin: center; transform-box: fill-box" />
      <ellipse cx="0%" cy="0%" rx="9" ry="9" width="10px" height="10px"
        style="transform-origin: center; transform-box: fill-box" />
    </svg>
    <svg id="svgShapeSelected" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" version="1.1" width="50px" height="50px" style="width:'50px'; height:'50px'" xml:space="preserve">
      <path fill="#4F419A" d="M5.6,0.9C3,0.9,0.9,3,0.9,5.6s2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7C10.3,3,8.2,0.9,5.6,0.9z M8.8,4.1L5.1,7.8
              C5,7.9,4.9,7.9,4.8,7.9c-0.1,0-0.2,0-0.3-0.1L2.6,5.9C2.4,5.7,2.5,5.4,2.7,5.2c0.2-0.1,0.4-0.1,0.6,0l1.5,1.5l3.4-3.4
              c0.2-0.2,0.5-0.1,0.7,0.1C8.9,3.6,8.9,3.9,8.8,4.1z" />
    </svg>
    <svg id="svgShapeOtherSelected" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" version="1.1" width="25px" height="25px" style="width:'25px'; height:'25px'" xml:space="preserve">
      <path fill="#4F419A" d="M5.6,0.9C3,0.9,0.9,3,0.9,5.6s2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7C10.3,3,8.2,0.9,5.6,0.9z M8.8,4.1L5.1,7.8
              C5,7.9,4.9,7.9,4.8,7.9c-0.1,0-0.2,0-0.3-0.1L2.6,5.9C2.4,5.7,2.5,5.4,2.7,5.2c0.2-0.1,0.4-0.1,0.6,0l1.5,1.5l3.4-3.4
              c0.2-0.2,0.5-0.1,0.7,0.1C8.9,3.6,8.9,3.9,8.8,4.1z" />
    </svg>
  </div>
  <ng-template #FurnitureDetails >
    <div class="modal_popover modal_popover-furniture" [ngClass]="{'non-draggable': !draggableFurnitureSelectionBox}">
      <div id="titlebar" class="titlebar">
        {{
          initialConfig.userBundle["geoplanner.text.furnituredetail"] ||
            "Furniture Details"
        }}
        <button class="close closeIconButton" (click)="closeInfoWindow()">x</button>
      </div>
      <div class="row" *ngIf="isShapePropVisible" class="shape-box">
        <div class="col-md-12" class="shape-box-inner">
          <div class="form-group_geo horizontal_geo margin-5-0">
            <label class="shape-box-label">{{
              initialConfig.userBundle["geoplanner.text.shapeproperties"] ||
                "Shape Properties"
            }}</label>
            <input type="text" class="form-control_geo" [(ngModel)]="selectedShapeText" (keyup)="onShapeLabelChange()"
              placeholder="{{
                initialConfig.userBundle['geoplanner.text.shapename'] ||
                  'Shape name'
              }}" name="shape_name" id="shape_name" />
            <label [(colorPicker)]="selectedShapeColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'rgba'"
              [style.background]="selectedShapeColor" (colorPickerChange)="onColorChange($event)" title="{{
                initialConfig.userBundle['geoplanner.text.selectshapecolor'] ||
                  'Select Shape Color'
              }}" class="col-picker-cnt">
              <em class="fa fa-eyedropper"></em>
            </label>
          </div>
        </div>
      </div>
      <div class="row market-box" *ngIf="selectedAssetsData.length > 0">
        <div class="frameControls">
          <div class="row">
            <label class="col-md-6 text-left small">
              <input type="checkbox" *ngIf="selectedAssetsData.length > minFramesToShowAllSelect" class="checkbox small"
                id="checkpanel10" [(ngModel)]="allSelected" (change)="onSelectAllChange()" name="checkpanel10" />
              <span>&nbsp;</span>
            </label>
          </div>
        </div>
        <ag-grid-angular [ngClass]="{
            readOnly: systemFlags.readOnlyWorkspace && !systemFlags.splitable
          }" [ngStyle]="{ height: frameSelectionGridHeight }"
          class="ag-theme-fresh market-box-table" [gridOptions]="gridOptionsFrameSelection" [rowData]="selectedAssetsData">
        </ag-grid-angular>
        <div class="pull-right market-box-action" [hidden]="
            selectedAssetsData.length == 1 &&
            selectedAssetsData[0].isPlottedMarker === true
          ">
          <button class="btn btn-success" [disabled]="isDisbledSaveFrameBtn || systemFlags.readOnly"
            (click)="saveSelectedFrames()">
            {{ initialConfig.userBundle["common.save"] }}
          </button>
        </div>
        <div class="tooltip-relative" *ngIf="showGuideIMP">
          <div class="tooltip-wrap tooltip-wrap-btn">
              <a class="tooltip-close" (click)="closeGuideIMP()">X</a>
              <div class="c">
                {{initialConfig.userBundle['geoplanner.text.guideMapFirst'] || 'Please select an indoor map first'}}
              </div>
          </div>
      </div>
      </div>
    </div>
  </ng-template>
  <div id="indoorMapGuide" class="tooltip-relative tooltip-relative-cmp" [ngStyle]="{ display: showGuideIM ? 'block' : 'none' }">
    <div class="tooltip-wrap tooltip-wrap-cmp">
        <a class="tooltip-close" (click)="closeGuideIM()">X</a>
        <div class="c">
          {{initialConfig.userBundle['geoplanner.text.guideMap'] || 'Please select an indoor map'}}
        </div>
    </div>
  </div>
</div>