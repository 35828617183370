import * as _ from 'lodash';
import { Injectable } from '@angular/core';

interface Bricks {
  Pattern: number | string;
  Environment: number | string;
  Format: number | string;
  List: number | string;
  Proximity: number | string;
  Tag: number | string;
  Location: number | string;
  Audience: number | string;
  SecondaryAudience: number | string;
  Media: number | string;
  SOT: number | string;
  Incharge: number | string;
  Frame: number | string;
  Facing: number | string;
  FrameIllumination: number | string;
  Budget: number | string;
  Distribution: number | string;
  Channel: number | string;
  Pricing: number | string;
  FrameQuality: number | string;
  Volume: number | string;
  ProductCatalogue: number | string;
  PricingTag: number | string;
  Network: number | string;
  AllAudience: number | string;
  PrimaryAudience: number | string;
  Objective: number | string;
  MultiTarget: number | string;
}

@Injectable()
export class BrickBaseService {

  /**
   * brick ID
   */
  public brickID: Bricks = {
    Budget: 1,
    Audience: 2,
    Volume: 3,
    Incharge: 4,
    Format: 5,
    Location: 6,
    Proximity: 7,
    Frame: 8,
    SOT: 9,
    Distribution: 10,
    Pattern: 11,
    List: 12,
    Tag: 13,
    Media: 15,
    Environment: 16,
    Network: 17,
    SecondaryAudience: 18,
    PricingTag: 19,
    Channel: 20, // (Also known as Product for BE)
    // CPT: 21,
    Pricing: 22,
    ProductCatalogue: 23,
    FrameQuality: 24,
    AllAudience: 25,
    Facing: 1001,
    FrameIllumination: 1002,
    PrimaryAudience: 26,
    Objective: 28,
    MultiTarget: 27
  };

  // Array of bricIds which are not allowd to split on UI
  public NON_SPLITTABLE_BRICS = [
    this.brickID.Audience,
    this.brickID.SecondaryAudience,
    this.brickID.Incharge,
    this.brickID.Network,
    this.brickID.ProductCatalogue
  ];
  public OPTIONAL_MANDATORY_BRICS = [
    this.brickID.SOT,
    this.brickID.Incharge,
    this.brickID.Pattern,
    this.brickID.Volume,
    this.brickID.Frame,
    this.brickID.Budget
  ];

  public NEVER_POPULATE_WITH_PRODUCT = [
    this.brickID.Incharge,
    // this.brickID.Volume,
    // this.brickID.Budget,
    this.brickID.MultiTarget,
  ];

  public PCM_EDITABLE_BRICS = [
    this.brickID.PricingTag,
    this.brickID.FrameQuality,
    this.brickID.Objective
  ];

  public EXPLODE_ALLOWED_ON_BRICS = [
    this.brickID.Format,
    this.brickID.Location,
    this.brickID.Incharge,
    this.brickID.Tag,
    this.brickID.Environment
  ];

  public quantityBrickId = [
    this.brickID.Budget, // Budget
    this.brickID.Volume, // Volume
    this.brickID.Frame, // Frame
    //this.brickID.SOT, // SOT
  ];

  public bypassBricks = [
    this.brickID.List,
    this.brickID.ProductCatalogue,
    this.brickID.Network
  ];

  public floorCeilingBrics = [
    this.brickID.Budget, // Budget
    this.brickID.Volume, // Volume
  ];

  /**
   * @description Audience bric ids that have only once brick allowed in the entire campaign
   * @memberof BrickBaseService
   */
  public singleAudienceBrics = [
    this.brickID.Audience,
    this.brickID.SecondaryAudience,
    this.brickID.PrimaryAudience,
  ];

  public multiTargetBrics = [
    this.brickID.Pattern,
    this.brickID.List,
    this.brickID.SOT,
    this.brickID.Distribution,
    this.brickID.Volume,
    this.brickID.Budget,
    this.brickID.Frame,
    this.brickID.FrameQuality
    // this.brickID.ProductCatalogue
  ];

  public copyPasteBricks = [
    this.brickID.List,
    this.brickID.Location,
    this.brickID.Tag,
    this.brickID.Proximity
  ];

  public audienceCategoryGroupId = 0;

  /**
   * Text to use in Request Json for selected data
   * @memberof BrickBaseService
   */
  public brickReqJsonText = {
    11: 'selectionPattern',
    4: 'selectionPeriod',
    16: 'selectionCriteriaChannel',
    15: 'selectionCriteriaMedia',
    13: 'selectionCriteriaTag',
    7: 'selectionProximity',
    9: 'selectionSot',
    2: 'selectionAudience',
    18: 'secondaryAudience',
    5: 'selectionCriteriaFormat',
    12: 'selectionFrameList',
    8: 'selectionFrameReduction',
    6: 'selectionCriteriaLocation',
    1001: 'selectionCriteriaFacing',
    1002: 'selectionIllumination',
    22: 'selectionPricing',
    24: 'selectionFrameQuality',
    20: 'selectionCriteriaProduct',
    1: 'selectionBudget',
    10: 'selectionDistribution',
    3: 'selectionAudienceReduction',
    19: 'selectionCriteriaNetwork',
    23: 'selectionProductCatalogue',
    25: 'secondaryAudiences',
    26: 'selectionPrimaryAudiences',
    28: 'selectionObjectives',
    27: 'selectionMultiTargetList'
  };

  /**
   * Respective color for brics/filters.
   */
  public brickColor: Bricks = {
    Pattern: 'rgb(251, 152, 253)',
    Environment: 'rgb(255, 102, 0)',
    Format: 'rgb(176, 117, 58)',
    List: 'rgb(127, 127, 127)',
    Proximity: '#f7b708',
    Tag: 'rgb(191, 144, 0)',
    Location: 'rgb(255, 150, 0)',
    Audience: 'rgb(100, 100, 9)',
    Media: 'rgb(76, 38, 0)',
    SOT: 'rgb(0, 108, 209)',
    Incharge: '#f84a4a',
    Frame: 'rgba(94, 102, 231, 0.9)',
    Facing: 'rgba(94, 102, 231, 0.8)',
    FrameIllumination: 'rgba(94, 102, 231, 0.7)',
    Budget: 'rgb(76, 76, 76)',
    Distribution: 'rgb(76, 76, 75)',
    SecondaryAudience: 'rgb(76, 76, 74)',
    Channel: 'rgb(76, 76, 73)',
    Pricing: 'rgb(76, 76, 72)',
    FrameQuality: '#64871d',
    Volume: 'rgb(0, 255, 0)',
    ProductCatalogue: 'rgb(51, 204, 204)',
    PricingTag: 'rgb(149, 0, 78)',
    Network: 'rgb(88, 0, 46)',
    AllAudience: 'rgb(30,100,10)',
    PrimaryAudience: 'rgb(76, 250, 162)',
    Objective: '',
    MultiTarget: 'rgb(127, 127, 127)',
  };

  /**
   * Respective text for brics/filters.
   */
  public brickText: Bricks = {
    Pattern: '',
    Environment: '',
    Format: '',
    List: '',
    Proximity: '',
    Tag: '',
    Location: '',
    Audience: '',
    Media: '',
    SOT: '',
    Incharge: '',
    Frame: '',
    Facing: 'Orientation',
    FrameIllumination: '',
    Budget: '',
    Distribution: '',
    SecondaryAudience: '',
    Channel: '',
    Pricing: '',
    FrameQuality: '',
    Volume: '',
    ProductCatalogue: '',
    PricingTag: '',
    Network: '',
    AllAudience: '',
    PrimaryAudience: '',
    Objective: '',
    MultiTarget: ''
  };

  /**
   * @description prevents brick to break when stretched
   * @memberof ColumnLock
   */
  preventLockBreak = [
    this.brickID.Incharge,
    this.brickID.ProductCatalogue,
    this.brickID.Audience,
    this.brickID.SecondaryAudience,
    this.brickID.PrimaryAudience
  ];

  setBricProperties(masterData): void {
    const brics = _.flatten(_.map(masterData.category, 'brics'));

    brics.forEach((bric: any) => {
      switch (bric.bricid) {
        case this.brickID.Pattern:
          this.brickColor.Pattern = bric.color;
          this.brickText.Pattern = bric.bricname;
          break;
        case this.brickID.Environment:
          this.brickColor.Environment = bric.color;
          this.brickText.Environment = bric.bricname;
          break;
        case this.brickID.Format:
          this.brickColor.Format = bric.color;
          this.brickText.Format = bric.bricname;
          break;
        case this.brickID.List:
          this.brickColor.List = bric.color;
          this.brickText.List = bric.bricname;
          break;
        case this.brickID.Proximity:
          this.brickColor.Proximity = bric.color;
          this.brickText.Proximity = bric.bricname;
          break;
        case this.brickID.Tag:
          this.brickColor.Tag = bric.color;
          this.brickText.Tag = bric.bricname;
          break;
        case this.brickID.Location:
          this.brickColor.Location = bric.color;
          this.brickText.Location = bric.bricname;
          break;
        case this.brickID.Audience:
          break;
        case this.brickID.Media:
          this.brickColor.Media = bric.color;
          this.brickText.Media = bric.bricname;
          break;
        case this.brickID.SOT:
          this.brickColor.SOT = bric.color;
          this.brickText.SOT = bric.bricname;
          break;
        case this.brickID.Incharge:
          this.brickColor.Incharge = bric.color;
          this.brickText.Incharge = bric.bricname;
          break;
        case this.brickID.Frame:
          this.brickColor.Frame = bric.color;
          this.brickText.Frame = bric.bricname;

          // Frame is different in GM - setting color etc as in frame
          this.brickColor.FrameIllumination = '#68caf2';
          this.brickText.FrameIllumination = bric.bricname;
          break;
        case this.brickID.Facing:
          this.brickColor.Facing = bric.color;
          this.brickText.Facing = bric.bricname;
          break;
        case this.brickID.Budget:
          this.brickColor.Budget = bric.color;
          this.brickText.Budget = bric.bricname;
          break;
        case this.brickID.Distribution:
          this.brickColor.Distribution = bric.color;
          this.brickText.Distribution = bric.bricname;
          break;
        case this.brickID.SecondaryAudience:
          this.brickColor.SecondaryAudience = bric.color;
          this.brickText.SecondaryAudience = bric.bricname;
          break;
        case this.brickID.Channel:
          this.brickColor.Channel = bric.color;
          this.brickText.Channel = bric.bricname;
          break;
        case this.brickID.Pricing:
          this.brickColor.Pricing = bric.color;
          this.brickText.Pricing = bric.bricname;
          break;
        case this.brickID.FrameQuality:
          this.brickColor.FrameQuality = bric.color;
          this.brickText.FrameQuality = bric.bricname;
          break;
        case this.brickID.Volume:
          this.brickColor.Volume = bric.color;
          this.brickText.Volume = bric.bricname;
          break;
        case this.brickID.PricingTag:
          this.brickColor.PricingTag = bric.color;
          this.brickText.PricingTag = bric.bricname;
          break;
        case this.brickID.ProductCatalogue:
          this.brickColor.ProductCatalogue = bric.color;
          this.brickText.ProductCatalogue = bric.bricname;
          break;
        case this.brickID.AllAudience:
          this.brickColor.AllAudience = bric.color;
          this.brickText.AllAudience = bric.bricname;
          break;
        case this.brickID.PrimaryAudience:
          this.brickColor.PrimaryAudience = bric.color;
          this.brickText.PrimaryAudience = bric.bricname;
          break;
        case this.brickID.Objective:
          this.brickColor.Objective = bric.color;
          this.brickText.Objective = bric.bricname;
          break;
        case this.brickID.MultiTarget:
          this.brickColor.MultiTarget = bric.color;
          this.brickText.MultiTarget = bric.bricname;
          break;
        default:
          break;
      }
    });
  }
}
