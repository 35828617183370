import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { DataShareService } from '../services/data-share.service';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe, private dataShareService: DataShareService) {

  }

  // @ts-ignore
  transform(value: any, args?: any): any {
    const currencySymbol = this.dataShareService.getInitialConfig().systemData.currencySymbol;
    let formattedText = '';
    if (value !== '') {
      formattedText = this.currencyPipe.transform(value, currencySymbol, 'code');
    }
    return formattedText;
  }

}
