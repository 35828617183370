import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataShareService, CommercialService, StateService } from '../../geoplanner/src/app/core/services';
import {
  CommercialUkComponent,
  CommercialDefaultComponent,
  CommercialSgComponent,
  CommercialCngaComponent,
  CommercialGermanyComponent,
  CommercialUsComponent,
  CommercialAuComponent,
  CommercialNorwayComponent,
  CommercialDenmarkComponent,
  CommercialFiComponent,
  CommercialAEComponent,
  CommercialHkComponent,
  CommercialItalyComponent,
  CommercialBeComponent,
  CommercialShanghaiComponent,
  CommercialNlComponent,
  CommercialBrComponent,
  CommercialAtComponent,
  CommercialBalComponent
} from '../../geoplanner/src/app/commercial';
import { EnvironmentId, AppNameEnum } from '../../geoplanner/src/app/core/enum';
import { SearchService } from '../../geoplanner/src/app/search/search.service';
import { GLOBAL } from '../../geoplanner/src/app/core/utils/app.constant';
import { CommercialEsComponent } from '../../geoplanner/src/app/commercial/commercial-es/commercial-es.component';
import { CommercialCnshaComponent } from '../../geoplanner/src/app/commercial/commercial-cnsha/commercial-cnsha.component';
import { UiControl } from '../../geoplanner/src/app/models';
import { CommercialPtComponent } from '../../geoplanner/src/app/commercial/commercial-pt/commercial-pt.component';
import { AppHeaderService } from '../app-header/app-header.service';

@Component({
  selector: 'app-commercial',
  templateUrl: './app-commercial.component.html'
})
export class AppCommercialComponent implements OnInit,OnDestroy {
  @ViewChild(CommercialUkComponent) commercialUK: CommercialUkComponent;
  @ViewChild(CommercialAEComponent) commercialAE: CommercialAEComponent;
  @ViewChild(CommercialHkComponent) commercialHK: CommercialHkComponent;
  @ViewChild(CommercialItalyComponent) commercialIT: CommercialItalyComponent;
  @ViewChild(CommercialGermanyComponent) commercialGermany: CommercialGermanyComponent;
  @ViewChild(CommercialUsComponent) commercialUS: CommercialUsComponent;
  @ViewChild(CommercialDenmarkComponent) commercialDenmark: CommercialDenmarkComponent;
  @ViewChild(CommercialFiComponent) commercialFi: CommercialFiComponent;
  @ViewChild(CommercialDefaultComponent) commercialDefault: CommercialDefaultComponent;
  @ViewChild(CommercialAuComponent) commercialAU: CommercialAuComponent;
  @ViewChild(CommercialNorwayComponent) commercialNOR: CommercialNorwayComponent;
  @ViewChild(CommercialSgComponent) commercialSG: CommercialSgComponent;
  @ViewChild(CommercialCngaComponent) commercialCnga: CommercialCngaComponent;
  @ViewChild(CommercialBeComponent) commercialBE: CommercialBeComponent;
  @ViewChild(CommercialShanghaiComponent) commercialCN: CommercialShanghaiComponent;
  @ViewChild(CommercialCnshaComponent) commercialCNSHA: CommercialCnshaComponent;
  @ViewChild(CommercialNlComponent) commercialNL: CommercialNlComponent;
  @ViewChild(CommercialBrComponent) commercialBR: CommercialBrComponent;
  @ViewChild(CommercialEsComponent) commercialES: CommercialEsComponent;
  @ViewChild(CommercialAtComponent) commercialAT: CommercialAtComponent;
  @ViewChild(CommercialPtComponent) commercialPT: CommercialPtComponent;
  @ViewChild(CommercialBalComponent) commercialBAL: CommercialBalComponent;

  /**
   * @description environmentId which is come from backend
   * @type {number}
   * @memberof AppCommercialComponent
   */
  environmentId: number;
  /**
   * @description Enum for country environemtid
   * @type {typeof EnvironmentId}
   * @memberof AppCommercialComponent
   */
  environmentCountyId: typeof EnvironmentId = EnvironmentId;

  uiControl: UiControl;

  constructor(
    private dataShareService: DataShareService,
    private commercialService: CommercialService,
    private stateService: StateService,
    private searchService: SearchService,
    private appHeaderService: AppHeaderService
  ) { }

  ngOnInit() {
    this.dataShareService.appName = AppNameEnum.commercial;
    this.commercialService.setGoToOtherPage(false);
    this.environmentId = this.dataShareService.getInitialConfig().systemData.environmentId;
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
  }

  /**
   * @description call for update commercial info and set seach page object
   * @author Kishan Patel
   * @returns
   * @memberof AppCommercialComponent
   */
  updateCommercialinfo() {
    const promise = new Promise((resolve, reject) => {
      let campaigndata;
      let campaignDetails;
      switch (this.environmentId) {
        case this.environmentCountyId.UK:  // UK
        case this.environmentCountyId.UK2:  // UK
          campaigndata = this.commercialUK.createRequestJSONForCommercial(this.commercialUK.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialUK.campaignDetails;
          break;
        case this.environmentCountyId.AE:  // Dubai
          campaigndata = this.commercialAE.createRequestJSON(this.commercialAE.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialAE.campaignDetails;
          break;
        case this.environmentCountyId.HKAI:  // HK-Airport
        case this.environmentCountyId.HKCTS:  // HK-Cityscape
        case this.environmentCountyId.HKM:  // HK-Metro
          campaigndata = this.commercialHK.createRequestJSONForCommercial(this.commercialHK.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialHK.campaignDetails;
          break;
        case this.environmentCountyId.Italy:  // Italy
          campaigndata = this.commercialIT.createRequestJSONForCommercial(this.commercialIT.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialIT.campaignDetails;
          break;
        case this.environmentCountyId.Germany:  // Germany
          campaigndata = this.commercialGermany.createRequestJSONForCommercial(this.commercialGermany.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialGermany.campaignDetails;
          break;
        case this.environmentCountyId.US:  // US
          campaigndata = this.commercialUS.createRequestJSONForCommercial(this.commercialUS.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialUS.campaignDetails;
          break;
        case this.environmentCountyId.AU:  // Australia
          campaigndata = this.commercialAU.createRequestJSONForCommercial(this.commercialAU.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialAU.campaignDetails;
          break;
        case this.environmentCountyId.Denmark:  // Denmark
          campaigndata = this.commercialDenmark.createRequestJSONForCommercial(this.commercialDenmark.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialDenmark.campaignDetails;
          break;
        case this.environmentCountyId.SG: // Singapore
          campaigndata = this.commercialSG.createRequestJSONForCommercial(this.commercialSG.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialSG.campaignDetails;
          break;
        case this.environmentCountyId.CNGA: // CN  Guangzhou Airport
          campaigndata = this.commercialCnga.createRequestJSONForCommercial(this.commercialCnga.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialCnga.campaignDetails;
          break;
        case this.environmentCountyId.Belgium: // Belgium
          campaigndata = this.commercialBE.createRequestJSONForCommercial(this.commercialBE.campaignDetails.campaignStatusId);
          this.storeLockedDiscountLines(this.commercialBE.campaignDetails.discounts);
          campaignDetails = this.commercialBE.campaignDetails;
          break;
        case this.environmentCountyId.FI: // Finland
          campaigndata = this.commercialFi.createRequestJSONForCommercial(this.commercialFi.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialFi.campaignDetails;
          break;
        case this.environmentCountyId.CN: // Shanghai
          campaigndata = this.commercialCN.createRequestJSONForCommercial(this.commercialCN.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialCN.campaignDetails;
          break;
        case this.environmentCountyId.CNSHA: // Shanghai SHA
          campaigndata = this.commercialCNSHA.createRequestJSONForCommercial(this.commercialCNSHA.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialCNSHA.campaignDetails;
          break;
        case this.environmentCountyId.NL: // Netherland
          campaigndata = this.commercialNL.createRequestJSONForCommercial(this.commercialNL.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialNL.campaignDetails;
          break;
        case this.environmentCountyId.Norway: // Norway
          campaigndata = this.commercialNOR.createRequestJSONForCommercial(this.commercialNOR.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialNOR.campaignDetails;
          break;
        case this.environmentCountyId.BR: // Brazil
          campaigndata = this.commercialBR.createRequestJSONForCommercial(this.commercialBR.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialBR.campaignDetails;
          break;
        case this.environmentCountyId.ES: // Spain
          campaigndata = this.commercialES.createRequestJSONForCommercial(this.commercialES.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialES.campaignDetails;
          break;
        case this.environmentCountyId.AT: // Austria
          if (this.uiControl.enableNewCommercialConfiguration) {
            campaigndata = this.commercialAT.createRequestJSONForCommercial(this.commercialAT.campaignDetails.campaignStatusId);
            campaignDetails = this.commercialAT.campaignDetails;
          } else {
            campaigndata = this.commercialDefault.createRequestJSONForCommercial(this.commercialDefault.campaignDetails.campaignStatusId);
            campaignDetails = this.commercialDefault.campaignDetails;
          }
          break;
        case this.environmentCountyId.PT: // Portugal
          if (this.uiControl.enableNewCommercialConfiguration) {
            campaigndata = this.commercialPT.createRequestJSONForCommercial(this.commercialPT.campaignDetails.campaignStatusId);
            campaignDetails = this.commercialPT.campaignDetails;
          } else {
            campaigndata = this.commercialDefault.createRequestJSONForCommercial(this.commercialDefault.campaignDetails.campaignStatusId);
            campaignDetails = this.commercialDefault.campaignDetails;
          }
          break;
        case this.environmentCountyId.BAL: // Baltics
        campaigndata = this.commercialBAL.createRequestJSONForCommercial(this.commercialBAL.campaignDetails.campaignStatusId);
        campaignDetails = this.commercialBAL.campaignDetails;
          break;
        case this.environmentCountyId.BAL_HIVE2: // Baltics HIVE 2
        campaigndata = this.commercialBAL.createRequestJSONForCommercial(this.commercialBAL.campaignDetails.campaignStatusId);
        campaignDetails = this.commercialBAL.campaignDetails;
          break;
        default:
          campaigndata = this.commercialDefault.createRequestJSONForCommercial(this.commercialDefault.campaignDetails.campaignStatusId);
          campaignDetails = this.commercialDefault.campaignDetails;
          break;
      }
      if (GLOBAL.BRIC_CAMPAIGN_ID > 0) {
        this.setSearchPageObject(campaignDetails);
      } else {
        this.searchService.updateCampaignDetails(undefined);
      }
      const data = {
        data: JSON.stringify(campaigndata),
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        redirection: true
      };
      this.commercialService.updateCommercialInfo(data).subscribe((res) => {
        if (String(res.status) === 'OK') {
          resolve(true);
          return promise;
        }
      }, () => {
        reject();
        return promise;
      });
    });
    return promise;
  }

  /**
   * @description Set initalconfig for page
   * @author Darshan Vachhani
   * @memberof CommercialButtonsComponent
   */
  setSearchPageObject(campaignDetails) {
    const searchObject = this.stateService.getCampaign();
    searchObject.brand = campaignDetails.brand;
    searchObject.advertiser = campaignDetails.advertiser;
    searchObject.specialist = campaignDetails.specialist;
    searchObject.productCategoryId = campaignDetails.productCategoryId;
    searchObject.agency = campaignDetails.agency;
    searchObject.salesTeamId = campaignDetails.salesTeamId;
    searchObject.thirdParty = campaignDetails.thirdParty;
    searchObject.campaignReference = campaignDetails.campaignReference;
    this.searchService.updateCampaignDetails(searchObject);
    this.stateService.setCampaign(searchObject);
  }

  storeLockedDiscountLines(discounts) {
    const lockedDiscounts = {};
    for (let index = 0; index < discounts.length; index++) {
      const discount = discounts[index];
      const lockedFields = {
        locked: discount.locked,
        seasonnalityIndex: discount.seasonnalityIndex,
        grossValue: discount.grossValue,
        discount: discount.discount,
        net: discount.net,
        statusCategoryId: discount.statusCategoryId,
        technicalFee: discount.technicalFee,
        smartContentValue: discount.smartContentValue,
        uploadCostValue: discount.uploadCostValue,
      };

      if (discount.locked) {
        lockedDiscounts[index] = lockedFields;
      }
    }
    this.stateService.setCommercialObject('lockedDiscounts', lockedDiscounts);
  }
  ngOnDestroy(): void {
    if(this.uiControl.isBrandMandatory && this.stateService.getCampaign().advertiser.advertiserName && !this.stateService.getCampaign().brand.brandName){
      this.dataShareService.activateCommercialTab(false)
    }
  }
}
