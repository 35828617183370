import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpParameterCodec } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SessionTimeoutComponent } from './core/components/session-timeout/session-timeout.component';
import { LoaderService } from './core/services/loader.service';
import 'rxjs/add/operator/do';
import { GLOBAL } from './core/utils/app.constant';
import { environment } from 'environments/environment';
import { AppHeaderService } from '../../../root/app-header/app-header.service';
import { DataShareService } from './core/services';

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private modalService: NgbModal,
    private loaderService: LoaderService,
    private appHeaderService: AppHeaderService,
    private dataShareService: DataShareService
  ) { }
  hideLoader = false;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handleIntercept(GLOBAL.ACCESS_TOKEN, req, next);
  }

  private handleIntercept(token: string, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.hideLoader = req && req.body && req.body.has && req.body.has('hideLoader');
    if (this.hideLoader) {
      req.body.delete('hideLoader');
    } else {
      this.loaderService.show();
    }

    if (req.body && req.body.encoder) {
      req.body.encoder = new CustomEncoder();
    }
    let authReq: any;
    const noAuth = req.headers.get('noAuth');
    if (!token || noAuth) {
      if (noAuth) {
        // @ts-ignore
        req.headers = req.headers.delete('noAuth');
      }
      authReq = req.clone();
    } else {
      authReq = req.clone({
        setHeaders: { Authorization: `Bearer ${token}` }
      });
    }
    GLOBAL.ACCESS_TOKEN = token;
    if (req.url.toLowerCase().indexOf('service') !== -1) {
      const SESSIONID = String(GLOBAL.BOS_SESSIONID);
      authReq.headers = authReq.headers.set('Bos-SessionId', SESSIONID);
    }
    return next
      .handle(authReq)
      .do((event) => {
        if (event instanceof HttpResponse) {
          this.requestSuccess(event);
        }
      }, (err: HttpErrorResponse) => {
        if (!this.hideLoader) {
          this.loaderService.hide();
        }
        this.hideLoader = false;
        if (err.status === 401 || err.status === 403 || err.status === 404) {
          GLOBAL.ACCESS_TOKEN = '';
          const domain = this.appHeaderService.getCookie('domain');
          this.appHeaderService.setCookie('status_code', String(err.status), 1, domain);
          location.assign(environment.landingAppUrl);
          return;
        }
        if (err.error && err.error.status && err.error.status === 'KO') {
          this.requestSuccess(err);
        } else if (err.status !== 200) {
          GLOBAL.ACCESS_TOKEN = '';
          this.openSessionTimeoutModal(true);
        }
      });
  }

  private requestSuccess(res) {
    const localBOSSESSIONID = res.headers.get('Bos-SessionId');
    if (localBOSSESSIONID != null) {
      GLOBAL.BOS_SESSIONID = parseInt(localBOSSESSIONID, 10);
    }
    /**
     * @JIRA : SBRICS-1289
     * @Author : Yuvraj Chauhan
     * @Date : 20/04/2016
     * @Description : Session Timeout
     */
    if (res.body && Object.keys(res.body).length > 0) {
      if (res.body.messageCode === -100) {
        this.openSessionTimeoutModal(false);
      } else if (res.body.messageCode === -101) {
        window.location.reload();
      }
    }
    if (!this.hideLoader && this.dataShareService.shouldLoaderStoppedForGeoMapper) {
      this.loaderService.hide();
    }
    this.hideLoader = false;
    return res;
  }

  /**
   * open Session timeout modal popup
   * @memberof GeoMapComponent
   */
  private openSessionTimeoutModal(isServerError) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'sm'
    };
    const modalRef = this.modalService.open(SessionTimeoutComponent, ngbModalOptions);
    modalRef.componentInstance.isServerError = isServerError;
  }

}
