import { TagGroupModel, TagModel } from '../../models';
import { DynamicKeyObjectData } from '../../models/Dynamic.model';
import * as _ from 'lodash';
/**
 * Common Class for Model Popup and Selected value of Load Compaing
 */
export class TagCommon {
  /**
   * @description To add exclude flag for each object.
   * @author Alkesh Shah
   * @param {TagGroupModel[]} tagGroupsParam Tag groups data.
   * @returns {TagGroupModel[]} It will return tag group data with exclude flag.
   * @memberof TagBase
   */
  addExcludeFlagKey(tagGroupsParam: DynamicKeyObjectData<TagGroupModel>): DynamicKeyObjectData<TagGroupModel> {
    const tagGroups = _.cloneDeep(tagGroupsParam);
    if (tagGroups) {
      _.each(tagGroups, (grpObj: TagGroupModel) => {
        if (grpObj && grpObj.tag) {
          _.each(grpObj.tag, (tagObj: TagModel) => {
            if (!tagObj.exclude) {
              tagObj.exclude = false;
            }
          });
        }
      });
    }
    return tagGroups;
  }
}
