import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ResultService } from './result.service';
import { PerformanceProposalComponent } from './performance-proposal/performance-proposal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { DataShareService } from './../core/services/data-share.service';
import { StateService } from './../core/services/state.service';
import { LogHelperService } from '../core/services/log-helper.service';
import { SharedService } from './../core/services/shared.service';
import { SbModalPopupService } from '../core/components/sb-modal-popup/sb-modal-popup.service';
import { ConfirmationModalPpComponent } from './confirmation-modal-pp/confirmation-modal-pp.component';
import { AppNameEnum, EnvironmentId } from '../core/enum';
import * as _ from 'lodash';
import { InitialConfigModel, SystemFlags, UserModel } from '../models';
import { SearchService } from '../search/search.service';
import { GLOBAL } from '../core/utils/app.constant';
import { AppHeaderService } from '../../../../root/app-header/app-header.service';
import { CommercialService } from '../commercial';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css'],
  providers: [ResultService, SbModalPopupService]
})
export class ResultComponent implements OnInit, OnDestroy {
  campaignSaveDetails = {
    campaignName: '',
    hideFinance: true
  };

  user: UserModel;
  readOnly = false;
  resultData: any = {};
  resultSummary: object = {};
  mapHelper: any = null;
  userBundle: object = {};
  uiControl: any = {};
  resultSummaryLabels: any = {};
  requestParams: object = {
    action: 'getResultDetail',
    bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
  };
  activeAccordion: boolean;
  disableBookedConfirmedCampaign = false;
  bookingStatusCancelled = false;
  isLoadedCampaign = false;
  showHidePerformance = false;
  /**
   * It defines initial configuration data.
   */
  public initialConfig: InitialConfigModel;
  public isOnline = true; // whether user is online or not, it is set from header component
  readonly CONFIRMATION_LABEL = 'result.confirmation.label';
  private subscriptions: Subscription[] = [];
  isResultDataLoaded: boolean = false;

  constructor(
    private logHelper: LogHelperService,
    private router: Router,
    private resultService: ResultService,
    private dataShareService: DataShareService,
    private datePipe: DatePipe,
    private sbModalPopupService: SbModalPopupService,
    private stateService: StateService,
    private sharedService: SharedService,
    private searchService: SearchService,
    private appHeaderService: AppHeaderService,
    private commercialService: CommercialService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.isOnline = GLOBAL.isOnline;
    this.subscriptions.push(this.dataShareService.languageChangedSub.subscribe((result) => {
      if (result) {
        this.ngOnInit();
        this.changeDetectorRef.detectChanges();
      }
    }));
  }

  ngOnInit() {
    // Map integration goes here
    this.dataShareService.appName = AppNameEnum.result;
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.isLoadedCampaign = SystemFlags.isLoadedCampaign;
    this.loadResultData();
    this.getUserBundleData();
    this.restoreData();
    this.user = this.dataShareService.getUserModel();
    this.activeAccordion = _.isUndefined(this.initialConfig.uiControl.resultDefaultExpandMap) ? true : this.initialConfig.uiControl.resultDefaultExpandMap; // SM-8216
  }

  getUserBundleData() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.resultSummaryLabels.target = this.userBundle['result.summary.title.target'];
    this.resultSummaryLabels.actual = this.userBundle['result.summary.title.actual'];
    this.resultSummaryLabels.error = this.userBundle['result.error.coverageAndFrequency'];
  }

  loadResultData() {
    // Availability code goes here
    this.resultService.getResultData(this.requestParams).subscribe((data) => {
      this.resultData = data.data;
      this.uiControl = data.data.uiControl;
      // sm-9010 fetching showCCP3LinkButton value from initialConfig
      const { showCCP3LinkButton } = this.dataShareService.getInitialConfigByKey('uiControl');
      this.uiControl['showCCP3LinkButton'] = showCCP3LinkButton ? showCCP3LinkButton : false;
      this.resultSummaryLabels.header = data.data.audienceInfo;
      this.mapHelper = this.resultData.mapHelper;
      this.isResultDataLoaded = true;
    }, (error) => {
      console.log(error);
    });
  }

  onAccordionClick() {
    this.activeAccordion = !this.activeAccordion;
  }

  redirectToWorkspacePage() {
    this.router.navigate(['/workspace']);
  }

  restoreData() {
    this.readOnly = SystemFlags.readOnly;

    this.campaignSaveDetails.campaignName = this.getCampaignTitle();

    // Shreni :: SBRICS :332//

    const campaignSearch = this.stateService.getCampaign();
    if (campaignSearch && (campaignSearch.bookingStatusId === 4 || campaignSearch.bookingStatusId === 2)) {
      // for SBRICS-405, Nishit
      this.disableBookedConfirmedCampaign = true;
    }
    // VJ SONI : 16Nov2015 : SBRICS-794
    if (!_.isUndefined(campaignSearch.bookingStatusId)) {
      if (campaignSearch.bookingStatusId === 4) {
        this.showHidePerformance = true;
      } else if (campaignSearch.bookingStatusId === 2) {
        this.showHidePerformance = false;
      } else if (campaignSearch.bookingStatusId === 5) {
        this.bookingStatusCancelled = true;
      }
    } else {
      this.showHidePerformance = false;
    }

  }

  /**
   * @description get campaign title from state
   * @author Alkesh Shah
   * @returns
   * @memberof ResultComponent
   */
  getCampaignTitle() {
    /// Store the Campaign Title//
    let title = null;

    if (this.stateService.getResultPageObject('common').name) {
      title = this.stateService.getResultPageObject('common').name;
    }

    // Case of Loaded Campaign
    if (this.stateService.getWorkspaceObject('common').campaignDetail) {
      const campaignDetail = this.stateService.getWorkspaceObject('common').campaignDetail;
      if (campaignDetail.title) {
        title = campaignDetail.title;
      }
      if (campaignDetail.campaignTitle) {
        title = campaignDetail.campaignTitle;
      }
    }
    // Case when title is set in Commercial Page//
    if (this.stateService.getCommercialObject('commercialDetails') &&
      this.stateService.getCommercialObject('commercialDetails').title) {
      title = this.stateService.getCommercialObject('commercialDetails').title;
    }
    return title;
  }

  /**
   * @description This funcution will create a title for save, request option etc
   * @author Nishit Parekh
   * @memberof ResultComponent
   */
  populateCampainTitle(campaignSaveDetails) {
    let campaignObj;
    let advertizer;
    if (campaignSaveDetails.campaignName === null) {
      if (this.stateService.getCampaign()) {

        const customDate = this.datePipe.transform(new Date(), 'ddMMyy');
        const lastName = this.initialConfig.userData.userName.split('.')[1];
        const userInitials = lastName.substring(0, 1).toUpperCase() + lastName.substring(1, lastName.length);
        campaignObj = this.stateService.getSearchPageObject('selectedText');

        if (campaignObj.advertiserName === undefined) {
          campaignSaveDetails.campaignName = `${customDate}_${userInitials}`;
        } else {
          advertizer = campaignObj.advertiserName;
          campaignSaveDetails.campaignName = `${customDate}_${userInitials}_${advertizer}`;
        }
      }
    } else {
      const campaignTitle = this.campaignSaveDetails.campaignName.split('_');
      campaignObj = this.stateService.getSearchPageObject('selectedText');
      if (campaignObj.advertiserName !== undefined) {
        if ((this.campaignSaveDetails.campaignName.toLowerCase()).indexOf(campaignObj.advertiserName.toLowerCase()) === -1) {
          advertizer = campaignObj.advertiserName;
          campaignSaveDetails.campaignName = `${campaignTitle[0]}_${campaignTitle[1]}_${advertizer}`;
        }
      } else {
        campaignSaveDetails.campaignName = `${campaignTitle[0]}_${campaignTitle[1]}`;
      }
    }
    return campaignSaveDetails;
  }

  /**
   * @description This function will be called when we click on save button on result page
   * @author Nishit Parekh
   * @memberof ResultComponent
   */
  saveCampaign(id) {
    const err = this.brandValidation();
    if (err) {
      return this.logHelper.logError(err);
    }
    const reqParams = this.dataShareService.getParamsForActionButtons(id);
    this.populateCampainTitle(this.campaignSaveDetails);
    this.sbModalPopupService.open(ConfirmationModalComponent, {
      SelectedValue: this.campaignSaveDetails,
      SelectedTitle: this.userBundle[reqParams.userBundle].toUpperCase(),
      confirmation: false,
      color: reqParams.color
    }).result.then((result) => {
      if (result) {

        reqParams['title'] = result.campaignName;
        reqParams['referer'] = environment.landingAppUrl;
        const url = reqParams.url;
        delete reqParams.userBundle;
        delete reqParams.url;

        this.sharedService.actionButtonRequest(reqParams, url).subscribe((res) => {
          if (res && (res.status).toUpperCase() === 'OK') {
            // If id==1 then no need to reset campaign
            this.openConfirmationModal(res.message || 'Search saved successfully',
              this.userBundle[this.CONFIRMATION_LABEL].toUpperCase(), reqParams.color, [2, 3, 6, 7].indexOf(id) !== -1);
          } else if (res && res.message && this.userBundle['commercial.error.largeCampaign'] && res.message.split('<=')[0] === this.userBundle['commercial.error.largeCampaign'].split('<=')[0]) {
            // updated for SM-10198
            this.openConfirmationModal(res.message,
              this.userBundle[this.CONFIRMATION_LABEL].toUpperCase(), reqParams.color, false, res && res.data && res.data.campaignAddedForProcessing ? true : false);
          } else {
            this.logHelper.logError(res.message);
          }
        }, (error) => {
          console.error(error);
        });

      }
    }, (reason) => {
      console.log(reason);
    });

  }

  // TODO: This needs to made common for commercial and result

  /**
   * @description This function open modal pop up for confirmation.
   * @author Nishit Parekh
   * @param {string} message Confirmation which we want to display on modal pop up
   * @param {string} title Header of Modal popup
   * @param {string} displalabel label for url
   * @param {boolean} showInfoLabel display message from response
   * @param {boolean} reset reset campaign after success
   * @memberof ResultComponent
   */
  openConfirmationModalPP(url: string, message: string, title: string, displalabel: string, color: string,
    showInfoLabel = false, isForProcessing = false) {
    const modalOptions = { windowClass: 'Copy-Link' };
    this.sbModalPopupService.open(ConfirmationModalPpComponent, {
      url,
      message,
      title,
      displalabel,
      showInfoLabel,
      color,
      isForProcessing
    }, modalOptions).result.then(() => {
      if ((this.initialConfig.uiControl['proposalCampaign'] && showInfoLabel) || isForProcessing) {
        this.resetUIAndNavigateToSearch();
      }
    }, (reason) => {
      console.log(reason);
      if ((this.initialConfig.uiControl['proposalCampaign'] && showInfoLabel) || isForProcessing) {
        this.resetUIAndNavigateToSearch();
      }
    });
  }

  resetUIAndNavigateToSearch() {
    this.searchService.resetCampaign(false, false, true);
    SystemFlags.isLoadedCampaign = false;
    this.appHeaderService.changeResetConfig({ getConfig: true, getBricStructure: true });
    this.commercialService.setGoToOtherPage(true);
    this.dataShareService.activateResultTab(false);
    this.dataShareService.activateCommercialTab(false);
    this.router.navigate(['/search']);
  }

  /**
   * @description This function open modal pop up for confirmation.
   * @author Nishit Parekh
   * @param {string} message Confirmation which we want to display on modal pop up
   * @param {string} title Header of Modal popup
   * @param {boolean} reset trigger reset campaign event if true
   * @memberof ResultComponent
   */

  openConfirmationModal(message: string, title: string, color: string, reset = false, isForProcessingParam = false) {

    this.sbModalPopupService.open(ConfirmationModalComponent, {
      color,
      SelectedValue: message,
      SelectedTitle: title.toUpperCase(),
      confirmation: true
    }).result.then(() => {
      console.log('Confirmation Pop up closed successfully');
      if (reset || isForProcessingParam) {
        this.shouldResetCampaign();
      }
    }, (reason) => {
      if (reset || isForProcessingParam) {
        this.shouldResetCampaign();
      }
      console.log(reason);
    });

  }

  shouldResetCampaign() {
    const refreshCampaign = this.searchService.resetCampaign(true, true);
    if (refreshCampaign) {
      refreshCampaign.then(() => {
        this.router.navigate(['/search']);
      });
    }
  }

  /**
   * @description This function checks whether brand is selected or not.
   * @author Siddharth vaidya
   * @memberof ResultComponent
   */
  brandValidation() {
    let _campaignDetail = this.stateService.getCampaign();
    const environmentId = this.dataShareService.getInitialConfig().systemData.environmentId;
    if (
      this.initialConfig.uiControl?.isBrandMandatory && environmentId !== EnvironmentId.UK
      && environmentId !== EnvironmentId.ES
      && environmentId !== EnvironmentId.PT
      && (!_campaignDetail.advertiser.advertiserId ||
        !_campaignDetail.brand.brandId)
    ) {
      let errMsg =
        this.initialConfig.userBundle["commercial.validation.brand"] ||
        "Please Enter Brand Value";
      return errMsg;
    }
  }
  proposal(id) {
    const err = this.brandValidation();
    if (err) {
      return this.logHelper.logError(err);
    }
    const reqParams = this.dataShareService.getParamsForActionButtons(id);
    this.populateCampainTitle(this.campaignSaveDetails);

    this.sbModalPopupService.open(PerformanceProposalComponent, {
      SelectedValue: this.campaignSaveDetails,
      SelectedTitle: this.userBundle[reqParams.userBundle] ? this.userBundle[reqParams.userBundle].toUpperCase() : '',
      hideTitle: reqParams.hideTitle,
      color: reqParams.color,
      showTouchedPOIOnly: this.uiControl.showTouchedPOIOnly,
      allowEditToTouchedPOIs: this.uiControl.allowEditToTouchedPOIs
    }).result.then((result) => {
      if (result) {
        reqParams['title'] = result.campaignName;
        reqParams['action'] = reqParams['action'];
        reqParams['hideFinance'] = result['hideFinance'];
        reqParams.hideImpression = result.hideImpression;
        reqParams['bricsCampaignId'] = GLOBAL.BRIC_CAMPAIGN_ID;
        reqParams['referer'] = environment.landingAppUrl;
        reqParams['showTouchedPOIOnly'] = result.showTouchedPOIOnly;
        reqParams['allowEditToTouchedPOIs'] = result.allowEditToTouchedPOIs;
        this.sharedService.actionButtonRequest(reqParams, reqParams.url).subscribe((res) => {
          if (res.status.toUpperCase() === 'OK' && res.data && res.data.clientPortelURL) {
            this.openConfirmationModalPP(res.data.clientPortelURL, res.message || this.userBundle['result.showMessage.proposal'],
              this.userBundle['result.confirmation.label'].toUpperCase(),
              this.userBundle['result.showMessage.proposal.urlLabel'], reqParams.color, true, res.data.campaignAddedForProcessing ? true : false);

          } else if (String(res.status) === 'KO') {
            console.log(res.message);
            if (this.userBundle['commercial.error.largeCampaign'] && (res.message.split('<=')[0] === this.userBundle['commercial.error.largeCampaign'].split('<=')[0])) {
              // updated for SM-10198
              this.openConfirmationModal(res.message, this.userBundle[this.CONFIRMATION_LABEL].toUpperCase(), reqParams.color, false);
            } else {
              this.logHelper.logError(res.message);
            }
          }
        }, () => {
          console.error('Error');
        });

      }
    }, (reason) => {
      console.log(reason);
    });

  }

  performance(id) {
    const reqParams = this.dataShareService.getParamsForActionButtons(id);
    if (reqParams.hideFinance) {
      this.sbModalPopupService.open(PerformanceProposalComponent, {
        SelectedValue: this.campaignSaveDetails,
        SelectedTitle: this.userBundle[reqParams.userBundle].toUpperCase(),
        hideTitle: reqParams.hideTitle,
        color: reqParams.color,
        showTouchedPOIOnly: this.uiControl.showTouchedPOIOnly,
        allowEditToTouchedPOIs: this.uiControl.allowEditToTouchedPOIs
      }).result.then((result) => {
        if (result) {
          reqParams['title'] = result.campaignName;
          reqParams['action'] = reqParams['action'];
          reqParams['hideFinance'] = result['hideFinance'];
          reqParams.hideImpression = result.hideImpression;
          reqParams['bricsCampaignId'] = GLOBAL.BRIC_CAMPAIGN_ID;
          reqParams['referer'] = environment.landingAppUrl;
          reqParams['showTouchedPOIOnly'] = result['showTouchedPOIOnly'];
          reqParams['allowEditToTouchedPOIs'] = result['allowEditToTouchedPOIs'];
          this.sharedService.actionButtonRequest(reqParams, reqParams.url).subscribe((res) => {
            if (res.status.toUpperCase() === 'OK' && res.data && res.data.clientPortelURL) {
              this.openConfirmationModalPP(res.data.clientPortelURL, this.userBundle['result.showMessage.performance'],
                this.userBundle['result.confirmation.label'].toUpperCase(),
                this.userBundle['result.showMessage.performance.urlLabel'], reqParams.color, false, res.data.campaignAddedForProcessing ? true : false);

            } else {
              console.log(res.message);
            }
          }, () => {
            console.error('Error');
          });

        }
      }, (reason) => {
        console.log(reason);
      });

    } else {
      reqParams['action'] = reqParams['action'];
      reqParams['bricsCampaignId'] = GLOBAL.BRIC_CAMPAIGN_ID;
      reqParams['referer'] = environment.landingAppUrl;

      this.sharedService.actionButtonRequest(reqParams, reqParams.url).subscribe((res) => {
        if (res.status.toUpperCase() === 'OK' && res.data && res.data.clientPortelURL) {
          this.openConfirmationModalPP(res.data.clientPortelURL, res.data.message || 'Successfully created Performance URL',
            this.userBundle['result.buttonLabel.performance'].toUpperCase(), this.userBundle['result.showMessage.performance.urlLabel'],
            reqParams.color, false, res.data.campaignAddedForProcessing ? true : false);

        } else {
          console.log(res.message);
        }
      }, () => {
        console.error('Error');
      });

    }

  }

  hideFinancePerformance(reqParams) {

    reqParams.bricsCampaignId = GLOBAL.BRIC_CAMPAIGN_ID;

    this.sharedService.actionButtonRequest(reqParams, reqParams.url).subscribe((res) => {
      if (res.status.toUpperCase() === 'OK' && res.data && res.data.clientPortelURL) {

        this.openConfirmationModalPP(res.data.clientPortelURL, res.data.message || 'Successfully created Performance URL',
          this.userBundle['result.buttonLabel.performance'].toUpperCase(), this.userBundle['result.showMessage.performance.urlLabel'],
          reqParams.color, false);

      } else {
        console.log(res.message);
      }
    }, () => {
      console.error('Error');
    });

  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
