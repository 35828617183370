import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DndModule } from 'ng2-dnd';

import { ResultComponent } from './result.component';
import { PerformanceProposalComponent } from './performance-proposal/performance-proposal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ConfirmationModalPpComponent } from './confirmation-modal-pp/confirmation-modal-pp.component';
import { ResultSummaryComponent } from './result-summary/result-summary.component';
import { ResultDetailsComponent } from './result-details/result-details.component';
import { SbModalPopupModule } from './../../app/core/components/sb-modal-popup';
import { CoreModule } from './../core/core.module';
import { ResultService } from './result.service';
// import { NguiMapModule } from '@ngui/map';
// import { AgmCoreModule } from '@agm/core';
// import { AgmMarkerClustererModule } from '@agm/markerclusterer';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DndModule.forRoot(),
    CoreModule.forRoot(),
    SbModalPopupModule.forRoot([
      ConfirmationModalComponent,
      ConfirmationModalPpComponent,
      PerformanceProposalComponent
    ]),

  ],
  // entryComponents: [
  //   ResultComponent,
  // ],
  declarations: [
    ResultComponent,
    ResultSummaryComponent,
    ResultDetailsComponent,
    ConfirmationModalComponent,
    ConfirmationModalPpComponent,
    PerformanceProposalComponent
  ],
  providers: [
    ResultService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ResultModule { }
