import { Component } from '@angular/core';
import { AgCustomRowUtil } from '../../utils/ag-custom-row-util';
import { CellRendererBaseComponent } from '../cell-renderer-base/cell-renderer-base.component';

@Component({
  selector: 'app-ag-checkbox-cell-renderer',
  templateUrl: './ag-checkbox-cell-renderer.component.html'
})
export class AgCheckboxCellRendererComponent extends CellRendererBaseComponent {
  prevVal: string | boolean = 'a';
  indeterminate = false;

  constructor() {
    super();
  }

  public getParentRowText(): string {
    if (this.groupingField) {
      const rowData = AgCustomRowUtil.getRowData(this.params.api);
      const childRows = AgCustomRowUtil.getChildRows(rowData, this.groupingField, this.params.data[this.groupingField]);
      let value: any = 'a';
      childRows.forEach((row) => {
        const fieldValue = row[this.params.column.colId];
        if (value !== '*') {
          if (value === 'a') {
            value = fieldValue;
          } else {
            if (value !== fieldValue) {
              value = '*';
            }
          }
        }
      });
      this.params.data[this.params.column.colId] = value;
      return value;
    } else {
      return this.params.data[this.params.column.colId];
    }
  }

  public getChildRowText(): string {
    return this.params.data[this.params.column.colId];
  }

  public getFirstPinRowText(): string {
    this.indeterminate = true;
    return this.params.data[this.params.column.colId];
  }

  public getSecondPinRowText(): string {
    return '';
  }

  onSelectChange($event) {
    if (this.params.node.rowPinned === 'bottom') {
      let val;
      let indeterminate;
      switch (this.prevVal) {
        case 'a':
          val = false;
          indeterminate = false;
          break;
        case 'false':
        case false:
          val = true;
          indeterminate = false;
          break;
        default:
          val = null;
          indeterminate = true;
          break;
      }
      this.displayText = val;
      this.params.data[this.params.column.colId] = val;
      this.indeterminate = indeterminate;
      this.prevVal = val == null ? 'a' : val;
    } else {
      this.params.data[this.params.column.colId] = $event.target.checked;
    }
    if (this.params.onSelectChange) {
      this.params.onSelectChange(this.params);
    }
  }
}
