/**
 * Set Distribution Enum for Model Popup and Display text for Load Compaing
 */

export class AudienceEnum {

  /**
   * @description Pass Distribution Name List From config
   * @author Kishan Patel
   * @param {*} userBundle
   * @memberof AudienceEnum
   */
  getAudienceEnum(): any {
    return {
      MIXGENDER: 'assets/img/audience/male_female_small.png',
      MALES: 'assets/img/audience/male_small.png',
      FEMALES: 'assets/img/audience/female_small.png',
      SHOPPER: 'assets/img/audience/children_small.png',
      UNIVERSE: 'assets/img/audience/universe.png',
      A: 'assets/img/audience/a.png',
      AB: 'assets/img/audience/ab.png',
      ABC1: 'assets/img/audience/abc1.png'
    };
  }
}
