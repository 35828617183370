import * as _ from 'lodash';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DataShareService } from './../../core/services/data-share.service';
import { CartService } from '../../geo-map/cart.service';
import { Subscription } from 'rxjs';
import { InitialConfigModel, CampaignSummaryModel } from '../../models';

@Component({
  selector: 'app-summary-bar',
  templateUrl: './summary-bar.component.html',
  styleUrls: ['./summary-bar.component.css']
})
export class SummaryBarComponent implements OnInit, OnDestroy {

  /**
   * It calls event to toggle sidebar.
   */
  @Output() filterAreaToggle = new EventEmitter<any>();
  /**
   * It contains sidebar heading text.
   */
  public filterText = '';
  /**
   * It contians initial config data.
   */
  public initialConfig: InitialConfigModel;
  /**
   * loaded Map data
   * @type {Array<any>}@memberof GeoMapComponent
   */
  // assetsData: Array<any>;
  /**
   * frames saved for cart
   * @type {Array<any>}@memberof CartService
   */
  // cartData: Array<any> = [];
  /**
   * It is a subscriber for result summary data.
   */
  private cartSummarySubscriber: Subscription;

  // private mapDataSubscriber: Subscription;
  /**
   * It is a subscriber for result summary frames data.
   */
  // private cartDataSubscriber: Subscription;
  /**
   * It contians result summary data.
   */
  public cartSummary: any = {
    inchargeDate: '',
    audience: '',
    price: '',
    frames: '',
    rating: ''
  };

  /**
   * is viewing cart data on map
   */
  // public isViewCartOnMap: boolean = false;
  // private isViewCartOnMapSubscriber: Subscription;

  /**
   * It contains count of selected frames.
   */
  // public selectedFrames: string = "(0)";

  // public allFrameOnMap: number = 0;
  // public availableFrameOnMap: number = 0;
  // public selectedFrameOnMap: number = 0;

  // public frameFilterType = FrameFilterType;
  // public selectedFrameFilter: FrameFilterType = FrameFilterType.all;

  /**
   * Constructor
   * @param dataShareService Service to share data between compoenents.
   * @param geoMapService Service to handle map data.
   */
  constructor(
    private dataShareService: DataShareService,
    // private geoMapService: GeoMapService,
    private cartService: CartService,
    // private campaignService: CampaignService
  ) {

  }

  /**
   * Function which loads initially.
   */
  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.filterText = this.initialConfig.userBundle['geoplanner.text.filter'] || 'Filter';
    this.cartSummarySubscriber = this.cartService.cartSummary$
      .subscribe(this.cartSummarySubscription);
  }

  /**
   * Function which calls to destroy the component instance.
   */
  ngOnDestroy() {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    // this.mapDataSubscriber.unsubscribe();
    this.cartSummarySubscriber.unsubscribe();
  }
  // mapDataSubscription = (data) => {
  //   this.assetsData = data.mapData;
  //   this.cartService.setRequestJSON(data.requestJSON);
  //   this.cartService.setIsViewCartOnMap(data.isViewCartOnMap);
  //   this.calculateMapFrames();
  // }

  /**
   * To collect data for result summary bar.
   * @param resultSummary This object contains result summary data.
   */
  cartSummarySubscription = (cartSummary: CampaignSummaryModel): void => {
    if (cartSummary && (_.keys(cartSummary)).length > 0) {
      this.cartSummary = {
        inchargeDate: cartSummary.campaignDates,
        audience: `${cartSummary.audienceImpressions} ( ${cartSummary.audienceName} )`,
        price: cartSummary.price,
        frames: cartSummary.frames,
        rating: cartSummary.illuminationPercentage
      };
    } else {
      this.cartSummary = {
        inchargeDate: '',
        audience: '',
        price: '',
        frames: '',
        rating: ''
      };
    }
  }

  // cartDataSubscription = (cartData: any) => {
  //   // if (frames > 0) {
  //   //   this.selectedFrames = '(' + frames + ')';
  //   // } else {
  //   //   this.selectedFrames = '';
  //   // }
  //   //if (frames != undefined) {
  //   this.cartData = cartData;
  //   //this.selectedFrames = '(' + cartData.length + ')';
  //   this.calculateMapFrames();
  //   //}
  // }

  /**
   * To parse the date to date object.
   * @param str Date string.
   */
  parseDate(str): Date {
    const mdy = str.split('/');
    return new Date(mdy[2], mdy[1] - 1, mdy[0]);
  }

  /**
   * To get diffence between two dates.
   * @param startDate Start Date
   * @param endDate End Date
   */
  daydiff(startDate, endDate) {
    return Math.round((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
  }

  // calculateMapFrames() {
  //   this.allFrameOnMap = 0;
  //   this.availableFrameOnMap = 0;
  //   this.selectedFrameOnMap = 0;
  //   this.assetsData.map((furniture) => {
  //     this.allFrameOnMap += furniture.frames.length;
  //     furniture.frames.map((frame) => {
  //       if (frame.status == FrameStatus.available) {
  //         this.availableFrameOnMap += 1;
  //       }
  //       if (this.cartService.isFrameInCart(frame.frameId)) {
  //         this.selectedFrameOnMap += 1;
  //       }
  //       // else if (frame.status == FrameStatus.available) {
  //       //   this.availableFrameOnMap += 1;
  //       // }
  //     });
  //   });
  // }

  /**
   * Function to toggle sidebar.
   */
  toggleFilter(): void {
    this.filterAreaToggle.emit();
  }

  // onFrameFilterChange(frameFilter: FrameFilterType) {
  //   this.selectedFrameFilter = frameFilter;
  //   this.geoMapService.setSelectedFrameFilter(this.selectedFrameFilter);
  // }

  // onViewCartOnMapClick() {
  //   this.campaignService.loadCartOnMap();
  // }
}
