/**
 * Set Distribution Enum for Model Popup and Display text for Load Compaing
 */

export class DistributionEnum {

  /**
   * @description Pass Distribution Name List From config
   * @author Kishan Patel
   * @param {*} userBundle
   * @memberof DistributionEnum
   */
  getDistributionEnum(userBundle): any {
    return {
      1: userBundle['workspace.share.distribution.label.audience'],
      2: 'RoC',
      3: userBundle['workspace.share.distribution.label.sweep'],
      6: userBundle['workspace.share.distribution.label.daySweep'],
      7: userBundle['workspace.share.distribution.label.daypartSweep'],
      8: userBundle['workspace.share.distribution.label.hourSweep']
    };
  }
}
