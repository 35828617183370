import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import {
  DataShareService,
  LogHelperService,
  CellAttributeService,
  BrickBaseService,
  MaskService
} from '../../../core/services';

import { CellValues } from '../../../models/workspace/index';
import { SOTBricSelectedDataModel } from '../../../models';
import { SotBase } from '../sot-base';
import { DynamicMaskModel, NumberMaskModel } from '../../../models/imask';
import * as _ from 'lodash';

@Component({
  selector: 'app-sot-modal',
  templateUrl: './sot-modal.component.html'
})
export class SotModalComponent extends SotBase implements OnInit {
  /**
   * It will take data to configure modal window.
   */
  @Input() resolveObject: CellValues;

   /**
   * @description SOT input element. As we are using masking here the blank or not valid input data always considered as 0. And we have to allow 0 as an input.
   * So, to detect empty string i.e. "" for validation we need to use reference
   * @type {ElementRef}
   * @memberof SotModalComponent
   */
  @ViewChild('sot') sotElementRef: ElementRef;

  readOnlyModal = false;

  productValidations: any;
  maskService: MaskService = new MaskService();
  productValidationsLength = 0;

  /**
   * @description Mask input config for Number mask
   * @type {DynamicMaskModel}
   * @memberof SotModalComponent
   */
  frequencyInputMask: NumberMaskModel;

  sotTypeInputMask: NumberMaskModel;

  readonly INVALID_VALUE = 'common.error.invalidValue';

  /**
   * @description Mask input config which allows Number mask and String 'Mixed'
   * @type {DynamicMaskModel}
   * @memberof SotModalComponent
   */
  sotInputMask: DynamicMaskModel;

  constructor(
    dataShareService: DataShareService,
    private cellAttributeService: CellAttributeService,
    private brickBaseService: BrickBaseService,
    private logHelperService: LogHelperService
  ) {
    super(dataShareService);
  }

  ngOnInit() {
    /**
     * NOTE:
     * To use DynamicMask in multiple inputs of the same file,
     * We have to use separate instance of 'currencyInputMaskWithMixed' method.
     * as we are using variable named 'allowMixed' to prevent user to type 'Mixed' in this method.
     */
    this.sotInputMask = this.maskService.currencyInputMaskWithMixed({ max: 100, padFractionalZeros: true });
    this.frequencyInputMask = this.maskService.currencyInputMask({
      min: this.initialConfig.uiControl.sotFrequencyMin,
      max: this.initialConfig.uiControl.sotFrequencyMax,
      scale: 0
    });
    if (this.resolveObject.productValidations) {
      this.productValidations = this.resolveObject.productValidations;
      this.productValidationsLength = Object.keys(this.productValidations).length;
    }
    this.sotTypeInputMask = this.maskService.currencyInputMask({
      min: 1,
      max: this.resolveObject.selectedValues && this.resolveObject.selectedValues.maxFramesSlots ? this.resolveObject.selectedValues.maxFramesSlots : 1,
      scale: 0
    });
    this.selectedValuesUpdated();
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.previousValues = Object.assign({}, this.shareOfTime);
  }

  /**
   * @description execute when selected value updated
   */
  selectedValuesUpdated() {
    let selectedValue: SOTBricSelectedDataModel = {
      sot: 'Mixed',
      frequency: 1,
      sotType: '0',
      maxFramesSlotsText: 'Mixed',
      maxFramesSlots: 1
    };
    if (this.resolveObject.selectedValues !== null && this.resolveObject.selectedValues !== undefined
      && (this.resolveObject.selectedValues.sot !== '-1'
        && Object.keys(this.resolveObject.selectedValues).length > 0)) {
      selectedValue = {
        ...this.resolveObject.selectedValues,
        sot: this.resolveObject.selectedValues.sot ? this.resolveObject.selectedValues.sot : this.resolveObject.columnSummary[this.resolveObject.brick.cellIndex].sot[0]
      };
      this.init(selectedValue);
      this.updateSOT(selectedValue);
    } else {
      this.shareOfTime = selectedValue;
      this.shareOfTime.sot = this.resolveObject.columnSummary && this.resolveObject.selectedValues ? 0 : this.shareOfTime.sot;
    }
  }

  /**
   * @description Update SOT fields
   * @param selectedValue Selected Value
   */
  updateSOT(selectedValue) {
    if (this.initialConfig.uiControl.sotFrequencyEnabled) {
      this.shareOfTime.frequency = selectedValue.frequency || 1;
      // SM-2818, Nishit
      this.shareOfTime.dummySot = this.shareOfTime.dummySot || selectedValue.commercialSOT;
      if (!selectedValue.commercialSOT) {
        this.calculateSOT();
      } else {
        this.shareOfTime.sot = selectedValue.commercialSOT ? selectedValue.commercialSOT : this.shareOfTime.sot;
      }
    }
  }

  /**
   * callback to close modal popup window
   * @param {*} event - modal event object
   * @memberof SotModalComponent
   */
  onModalClosed(event) {
    if (event.reason === 'escape' && !(JSON.stringify(this.previousValues) == JSON.stringify(this.shareOfTime))){
      if (window.confirm(this.initialConfig['userBundle']['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * callback function - called during save click
   * @param {*} event - modal event object
   * @memberof SotModalComponent
   */
  onModalSaved(event) {
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    if (this.shareOfTime.sotType === '1' && this.resolveObject.cellWidth > 1) {
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.sotSlots.notallowedStretched'] || 'Cannot change Sot type to Slots when bric is stretched, please select Sot to continue.');
      return;
    }
    if (this.sotElementRef.nativeElement.value !== '' || this.shareOfTime.sotType === '1') {
      if (this.shareOfTime.sot === 'Mixed' || this.shareOfTime.sot > 0) {
        if (this.initialConfig.uiControl.sotFrequencyEnabled) {
          this.shareOfTime.commercialSOT = _.clone(this.shareOfTime.sot);
          this.shareOfTime.sot = _.clone(this.shareOfTime.dummySot);
        }
        values.selectedValues = this.shareOfTime;
        values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.SOT, values.selectedValues);
        values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.SOT, values.selectedValues);
        if (values.requestJson.selectionSot.sot === 'Mixed') {
          delete values.requestJson.selectionSot.sot;
        }

        if (this.initialConfig.uiControl.autoCalculateFloorAndCeiling) {
          if (this.restrictSOTValue()) {
            this.logHelperService.logError(this.initialConfig.userBundle['common.error.invalidValue']);
          } else {
            event.activeModal.close(values);
          }
        } else {
          event.activeModal.close(values);
        }
      } else {
        this.logHelperService.logError(this.initialConfig.userBundle['sot.error.zeroSOTNotAllowed']);
      }
    } else {
      this.logHelperService.logError(this.initialConfig.userBundle[this.INVALID_VALUE]);
    }
    Object.assign(this.previousValues,this.resolveObject.selectedValues);
  }

  /**
   * @description This function restricts the user to enter the SOT value which is out of boundries  of Floor/Ceiling.
   * This case is specifically written for Product having Volume/Budget Bric and having Floor/Celiling
   * @author Shreni Shah
   * @date 2019-11-01
   * @returns
   */
  restrictSOTValue() {
    if (this.resolveObject && this.resolveObject.sotRestriction && this.resolveObject.sotRestriction.productValidationVolume) {
      return this.validateSOT('productValidationVolume');
    }
    if (this.resolveObject && this.resolveObject.sotRestriction && this.resolveObject.sotRestriction.productValidationBudget) {
      return this.validateSOT('productValidationBudget');
    }
    return false;
  }

  /**
   * @description This function validates whether SOT selected by user is within the floor/ceiling boundries of Volumne/Budget
   * @author Shreni Shah
   * @date 2019-11-01
   * @param {*} key
   * @returns
   */
  validateSOT(key) {
    if (this.resolveObject.sotRestriction[key].sotFloor && this.resolveObject.sotRestriction[key].sotCeiling) {
      if (this.shareOfTime.sot < this.resolveObject.sotRestriction[key].sotFloor
        || this.shareOfTime.sot > this.resolveObject.sotRestriction[key].sotCeiling) {
        return true;
      }
    } else if (this.resolveObject.sotRestriction[key].sotFloor) {
      if (this.shareOfTime.sot < this.resolveObject.sotRestriction[key].sotFloor) {
        this.logHelperService.logError(this.initialConfig.userBundle[this.INVALID_VALUE]);
        return true;
      }
    } else if (this.resolveObject.sotRestriction[key].sotCeiling && this.shareOfTime.sot > this.resolveObject.sotRestriction[key].sotCeiling) {
      this.logHelperService.logError(this.initialConfig.userBundle[this.INVALID_VALUE]);
      return true;
    }
  }

}
