import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';

@Component({
  selector: 'app-ag-collapse-expand',
  templateUrl: './ag-collapse-expand.component.html',
  styleUrls: ['./ag-collapse-expand.component.css']
})
export class AgCollapseExpandComponent implements ICellRendererAngularComp {

  public params: any;
  public isParent: boolean;
  public isExpanded: boolean;
  private groupingField = '';

  /**
   * @description For multiple grouping
   * @private
   * @memberof AgCollapseExpandComponent
   */
  private groupingFields = [];

  // called on init
  agInit(params: any): void {
    this.params = params;
    this.setValues();
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    this.setValues();
    return true;
  }

  private setValues(): void {
    this.groupingField = this.params.api.gridCore.gridOptions.groupingField;
    this.groupingFields = this.params.api.gridCore.gridOptions.groupingFields;
    if (this.params.data) {
      this.isParent = this.params.data.isParent || this.params.data.isParent1 || this.params.data.isParent2;
      this.isExpanded = this.params.data.isExpanded;
    } else {
      this.isParent = true;
      const parentRows = this.params.context.componentParent.rowData.filter((row) => {
        return row.isParent;
      });
      const expandedParentRows = parentRows.filter((row) => {
        return row.isExpanded;
      });
      this.isExpanded = expandedParentRows.length === parentRows.length;
    }
  }

  public toggleRow() {
    this.isExpanded = !this.isExpanded;
    let rowIndex = this.params.rowIndex;
    const rowData = this.params.context.componentParent.rowData;
    if (this.params.data && this.groupingField) {
      rowIndex = rowData.findIndex(ele => (ele.isParent && ele[this.groupingField] === this.params.data[this.groupingField]));
    }
    if (typeof (rowIndex) !== 'undefined') {
      this.toggleDataRow(rowData, rowIndex);
    } else {
      this.toggleHeaderRow(rowData);
    }
    this.params.context.componentParent.rowData = _.clone(rowData);

    // Refresh collpse/expand Header Cell
    this.params.api.headerRootComp.forEachHeaderElement((e) => {
      if (e.column.colId === '0' && e.columnApi && e.childComponents && e.childComponents.length > 1) {
        const headerCmpIns = e.childComponents[1].getFrameworkComponentInstance();
        if (headerCmpIns && headerCmpIns.refresh) {
          headerCmpIns.refresh(headerCmpIns.params);
        }
      }
    });
  }

  private toggleDataRow(rowData, rowIndex) {
    rowData[rowIndex].isExpanded = this.isExpanded;
    for (let i = rowIndex + 1; i < rowData.length; i++) {
      if (rowData[i].isParent) {
        break;
      } else {
        rowData[i].isVisible = this.isExpanded;
      }
    }
  }

  private toggleHeaderRow(rowData) {
    for (const row of rowData) {
      if (row.isParent) {
        row.isExpanded = this.isExpanded;
      } else {
        row.isVisible = this.isExpanded;
      }
    }
  }
}
