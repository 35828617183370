import { Component, OnInit, Input } from '@angular/core';
import { DataShareService } from '../../../core/services';
import { GLOBAL } from '../../../core/utils/app.constant';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html'
})
export class ProductCategoryComponent implements OnInit {
  public userBundle: any;
  public brickBgColor: string = GLOBAL.DEFAULT_TITLE_BACKGROUND_COLOR;
  public exclusivityMask: number;
  @Input() resolveObject: any;
  constructor(private dataShareService: DataShareService) { }

  ngOnInit() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    if (this.resolveObject.data !== null) {
      this.exclusivityMask = this.resolveObject.data;
    } else {
      this.exclusivityMask = 1;
    }
  }

  onModalSaved(event) {
    event.activeModal.close(this.exclusivityMask);
  }

  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }
}
