import { Injectable } from '@angular/core';
import { PcmComponent } from '../../geoplanner/src/app/workspace/pcm/pcm.component';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoaderService } from '../../geoplanner/src/app/core/services';

@Injectable()
export class CanDeactivatePcmService implements CanDeactivate<PcmComponent> {

  constructor(private loaderService: LoaderService) { }

  // @ts-ignore
  canDeactivate(component: PcmComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean { // tslint:disable-line
    if (this.loaderService.getBackButton()) {
      this.loaderService.setBackButton(false);
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);
      return false;
    }
    return true;
  }

}
