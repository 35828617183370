import { Component } from '@angular/core';
import { CellRendererBaseComponent } from '../cell-renderer-base/cell-renderer-base.component';
import { DatePipe } from '@angular/common';
import { LocaleData } from '../../../../../core/utils/LocaleData';
@Component({
  selector: 'app-ag-date-cell-renderer',
  templateUrl: './ag-date-cell-renderer.component.html',
  styleUrls: ['./ag-date-cell-renderer.component.css'],
  providers: [DatePipe]
})
export class AgDateCellRendererComponent extends CellRendererBaseComponent {

  constructor(private datePipe: DatePipe) {
    super();
  }

  public getParentRowText(): string {
    return '';
  }

  public getChildRowText(): string {
    return this.params.data[this.params.column.colId];
  }

  public getFirstPinRowText(): string {
    return this.params.data[this.params.column.colId];
  }

  public getSecondPinRowText(): string {
    return this.params.data[this.params.column.colId];
  }

  public formatText(displayText): string {
    const dateFormat = LocaleData.displayDateFormat || this.params.dateFormat;
    let disText = '';
    if (displayText) {
      disText = this.datePipe.transform((new Date(displayText)), dateFormat);
    }
    return disText;
  }

}
