<dl class="accordion content animated bounceInDown">
  <dt (click)="onAccordionClick()">
    <a href="javascript:void(0);" class="title" [ngClass]="{'down': activeAccordion}">
      <span class="text">{{initialConfig.userBundle['result.tabTitle.resultDetails']}}</span>
      <div class="icon_wrapper">
        <span class="icons animated"></span>
      </div>
    </a>
  </dt>
  <dd [ngClass]="{'down': activeAccordion}">
    <div style="text-align: center;line-height: 100px;height: 100px;font-size: 24px;font-weight: bold;">
      <app-concertina [availability]="false" [frameDetailsEnabled]="false" [frameStatusFiltersEnabled]="false"
        [frameSearchFilterEnabled]="true" [frameSelectionEnabled]="false" [initialConfig]="initialConfig"
        [expandedTab]="activeAccordion"></app-concertina>
    </div>
  </dd>
</dl>
