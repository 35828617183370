<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
[readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
    <div class="tabs_container horizontal">
        <ul class="tabs-menu">
            <li *ngFor="let tg of tagGroupKeys; trackBy: trackByItem | sort:'tagGroupName'" (click)="selectTab(tg)" [ngClass]="(tags.tab==tg)?'current':''" id="tab-{{tg}}">
                <span class="text">{{tagGroups[tg].tagGroupName}}</span>
            </li>
        </ul>
        <div class="tab" [ngClass]="{'readOnly' : readOnlyModal }">
            <div class="tab-content" style="display: block;">
                <tag-input [(ngModel)]="tags.allTabSelection[tags.tab]" [identifyBy]="'tagId'" [displayBy]="'tagName'" [onlyFromAutocomplete]="true" [placeholder]="initialConfig.userBundle['common.aadTag']" [secondaryPlaceholder]="initialConfig.userBundle['common.aadTag']"
                    [showDropdownIfEmpty]="true">
                    <ng-template let-item="item" let-index="index">
                    </ng-template>
                    <tag-input-dropdown [showDropdownIfEmpty]="true" [identifyBy]="'tagId'" [autocompleteItems]="autocompleteItems" [autocompleteObservable]="loadRoadSide" [visibleMenuAfterItemAdd]="true" [minimumTextLength]="0" [displayBy]="'tagName'" [appendToBody]="false"
                        [inputClass]="'form-control'" [maintainSearchText]="true">
                    </tag-input-dropdown>
                </tag-input>
                <ul class="summaryContainer">
                    <li *ngFor="let item of tags.allTabSelection[tags.tab]; trackBy: trackByIndex; let i = index;">
                        <label style="width: 64%;">{{item.tagName}}</label>
                        <span class="right" style="cursor:pointer;" (click)="removeTagsData(i)"><em class="fa fa-times"
                aria-hidden="true"></em></span>
                        <span class="right" style="width: 21%;">
              <input type="radio" [(ngModel)]="item.exclude" class="radio small include" name="tag-{{i}}"
                [value]="false" id="include-tag-{{i}}" checked />
              <label for="include-tag-{{i}}">&nbsp;</label>
              <input type="radio" [(ngModel)]="item.exclude" class="radio small exclude" name="tag-{{i}}" [value]="true"
                id="exclude-tag-{{i}}" />
              <label for="exclude-tag-{{i}}">&nbsp;</label>
            </span>
                        <div class="filter-lv3-label" [title]="initialConfig.userBundle['workspace.bric.relativeFilter']" *ngIf="global.localSolverEnabled && objectiveMode">
                            <label for="filter-lv3-{{item.tagId}}-{{i}}">
                <input type="checkbox" [(ngModel)]="item.relative" class="checkbox"
                  id="filter-lv3-{{item.tagId}}-{{i}}" name="filter-lv3-{{item.tagId}}-{{i}}" />
                <em class="relativeFilterPercentage">%</em>
              </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</app-sb-modal-wrapper>
