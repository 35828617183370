<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
  [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <div class="tabs_container horizontal" style="max-height:inherit !important;">
    <ul class="tabs-menu" style="max-height:inherit !important;">
      <li class="" *ngFor="let lookup of lookups.lookup; trackBy: trackBySelectionId; let i = index;" [ngClass]="{'current': i == selectedItem}">
        <a (click)="showContent(i)" style="cursor: pointer;">
          <span class="text">{{lookup.displayName}}</span>
        </a>
      </li>
    </ul>
    <div class="tab" [ngClass]="{'readOnly' : readOnlyModal}">
      <div id="tab-{{j}}" class="tab-content" *ngFor="let lookup of lookups.lookup; trackBy: trackBySelectionId; let j = index"
        [ngStyle]="{'display': j==selectedItem?'block':'none'}">
        <div *ngIf="!lookup.selectionIds">
          <app-lookup-view [lookupType]="lookup.lookupType" [selectionId]="lookup.selectionId"
            [(ngModel)]="selected[lookup.selectionId]" [placeholder]="lookup.displayName" [selectAll]="lookup.selectAll"
            [regexp]="lookup.regExp" [displaycode]="lookup.displayCode" [bricId]="brickBaseService.brickID.Location"
            [configLookup]="configLookup?configLookup[lookup.selectionId]: null" [fileList]="fileList"
            [showFileUploader]="initialConfig.uiControl.allowFileUploadForLocationBric"
            [isRelativeFilterVisible]="localSolverEnabled">
          </app-lookup-view>
        </div>
        <div *ngIf="lookup.selectionIds && !initialConfig.uiControl.geoLocationTree">
          <div *ngFor="let selectionid of lookup.selectionIds; trackBy: trackByItem;">
            <app-lookup-view class="left" [lookupType]="lookup.lookupType" [selectionId]="selectionid"
              [(ngModel)]="selected[selectionid]"
              [placeholder]="initialConfig.userBundle['ui.workspace.selectionId.'+selectionid+'.name']"
              [selectAll]="lookup.selectAll" [regexp]="lookup.regExp" [displaycode]="lookup.displayCode"
              [configLookup]="configLookup?configLookup[selectionid]: null" [fileList]="fileList"
              [showFileUploader]="initialConfig.uiControl.allowFileUploadForLocationBric"
              [ngStyle]="{'width': (100/(lookup.selectionIds.length || 1)) +'%'}"
              [isRelativeFilterVisible]="localSolverEnabled">
            </app-lookup-view>
          </div>
        </div>
        <div *ngIf="lookup.selectionIds && initialConfig.uiControl.geoLocationTree">
          <app-tree-view id="geoTree" [data]="treeData" [(selectedRelativeFilters)]="selectedRelativeFilters"
            [options]="options" [templateProps]="templateProps">
          </app-tree-view>
        </div>
      </div>
    </div>
  </div>
  <!-- <ng-template #treeViewTemplate>
    <div class="tab-content" style="display:block;">
      <app-tree-view [(state)]="state"
        [data]="treeData"
        [options]="options" [templateProps]="templateProps">
      </app-tree-view>
    </div>
</ng-template> -->
</app-sb-modal-wrapper>
