import { DataShareService } from '../../core/services/data-share.service';
import { InitialConfigModel } from '../../models';
import { LogHelperService } from '../../core/services/log-helper.service';
import { Options } from '@angular-slider/ngx-slider';

import * as _ from 'lodash';
export class SotPCMBase {
  /**
   * It contians initial config data.
   */
  public initialConfig: InitialConfigModel;

  increment = 15;
  minValue = 0;
  maxValue = 100;
  def = 15;
  defaultOptions = {
    floor: 0,
    ceil: this.maxMultipleOf(Number(this.increment)),
    step: Number(this.increment),
    showTicks: true,
    noSwitching: true,
    enforceStep: false,
    translate: (value) => {
      return value.toFixed(2);
    }
  };

  /**
   * @description shareOf Time default value
   * @memberof SotPCMModalComponent
   */
  public options: Options = _.cloneDeep(this.defaultOptions);

  /**
   * Creates an instance of SotBase.
   * @author Kishan Patel
   * @param {DataShareService} dataShareService
   * @memberof SotBase
   */
  constructor(
    private dataShareService: DataShareService,
    private logHelperService: LogHelperService
  ) {
    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  /**
   * @description initialize the data
   * @author Kishan Patel
   * @memberof SotBase
   */
  init(selectedValue) {
    setTimeout(() => {
      this.setSliderOptions(selectedValue);
    }, 100);
  }

  /**
   * @description validate selection
   * @author Kishan Patel
   * @returns {boolean} - true if valid else false
   * @memberof SotBase
   */
  isValidSelection(): boolean {
    const isValid = true;
    if (!this.options.step || !this.def) {
      this.logHelperService.logError(this.initialConfig.userBundle['sot.error.error1'] || 'Increment and Default both must be greater than zero!');
      return;
    }
    if (this.def < this.minValue ||
      this.def > this.maxValue) {
      this.logHelperService.logError(this.initialConfig.userBundle['sot.error.error2'] || 'Default value should be in between of selected range!');
      return;
    }
    if (this.def % this.options.step !== 0) {
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.invalidWorkspace'] || 'Default value shold be in steps!');
      return;
    }
    return isValid;
  }
  setSliderOptions(selectedVal) {
    if (selectedVal && selectedVal.max) {
      this.stepUpdated(selectedVal.increment);
      this.maxValue = selectedVal.max;
      this.minValue = selectedVal.min;
      this.def = selectedVal.def;
      this.increment = selectedVal.increment;
    }

  }

  /**
   * @description Update Step and calculate Max value of share of time
   * @memberof SotPCMModalComponent
   */
  stepUpdated = function(increment, updated = false, min = 0) {
    if (updated) {
      this.maxValue = this.maxMultipleOf(increment);
      this.minValue = min;
    }
    this.options = Object.assign({}, this.defaultOptions, {
      ceil: this.maxMultipleOf(Number(increment)),
      step: Number(increment),
    });
  };

  maxMultipleOf(no: number) {
    let maxMultiple = 0;
    for (let index = 1; index <= 100; index++) {
      if ((no * index) <= 100) {
        maxMultiple = no * index;
      }
    }
    return maxMultiple;
  }
}
