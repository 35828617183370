<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
    [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
    <div class="modal_content ">
        <div id="PatternBrick" class="modal-body">
            <div id="transmissionPanel" style="position: relative; display: block;">
                <div class="block-1 red" style="margin-top: 10px;">
                    <table style="width: 100%;" aria-label="pattern-table">
                        <tr>
                            <td style="width: 51.7%;"></td>
                            <td
                                *ngIf="(selectedRange?.allowHours == undefined || selectedRange?.allowHours || selectedRange.allowDays) && !pcmMode">
                                <div class="btn-group">
                                    <input type="radio" name="radio_groups_pd" [(ngModel)]="patternObj.hoursSelection"
                                        value="odd" autocomplete="off" id="check_units_pd1" (change)="addOddEvenHours()"
                                        [disabled]="selectedRange.allowDays && !selectedRange.allowHours && !selectedRange.allowDayParts">
                                    <label for="check_units_pd1" class="">
                                        {{userBundle['workspace.pattern.bric.oddHours'] ? userBundle['workspace.pattern.bric.oddHours'] : 'Odd Hours' }}</label>
                                    <input type="radio" name="radio_groups_pd" [(ngModel)]="patternObj.hoursSelection"
                                        value="all" autocomplete="off" id="check_units_pd2"
                                        (change)="addOddEvenHours()">
                                    <label for="check_units_pd2" class="">
                                        {{userBundle['workspace.pattern.bric.offHours'] ? userBundle['workspace.pattern.bric.offHours'] : 'All' }}</label>
                                    <input type="radio" name="radio_groups_pd" [(ngModel)]="patternObj.hoursSelection"
                                        value="even" autocomplete="off" id="check_units_pd3"
                                        (change)="addOddEvenHours()"
                                        [disabled]="selectedRange.allowDays && !selectedRange.allowHours && !selectedRange.allowDayParts">
                                    <label for="check_units_pd3"
                                        class="">{{userBundle['workspace.pattern.bric.evenHours'] ? userBundle['workspace.pattern.bric.evenHours'] : 'Even Hours' }}</label>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <table style="margin-top: 10px;" aria-label="pattern-table">
                        <tbody>
                            <tr *ngIf="pcmMode" style="padding: 0px 30px 30px;">
                                <td colspan="3">
                                    <div class="width_6" style="display: grid;">
                                        <input type="checkbox" [(ngModel)]="patternObj.allowDays"
                                            class="checkbox ng-pristine ng-untouched ng-valid"
                                            [class.disabled]="selectedRange?.minValueUnit == 1" id="days">
                                        <label for="days">
                                            {{userBundle['workspace.pcm.pattern.bric.allowDays']}}
                                        </label>

                                        <input type="checkbox" [(ngModel)]="patternObj.allowDayParts"
                                            class="checkbox ng-pristine ng-untouched ng-valid" id="dayparts"
                                            [class.disabled]="selectedRange?.minValueUnit == 1">
                                        <label for="dayparts">
                                            {{userBundle['workspace.pcm.pattern.bric.allowDayParts']}}</label>

                                        <input type="checkbox" [(ngModel)]="patternObj.allowHours"
                                            class="checkbox ng-pristine ng-untouched ng-valid" id="hourpart"
                                            [class.disabled]="selectedRange?.minValueUnit == 1">
                                        <label for="hourpart">
                                            {{userBundle['workspace.pcm.pattern.bric.allowHours']}}</label>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="!pcmMode">
                                <td style="width: 180px;">
                                    <div class="control_group_distribution">
                                        <input type="radio" [(ngModel)]="patternObj.patternType"
                                            (change)="onPatternTypeChange(2)" class="radio small" [value]="1"
                                            id="rbt-Named" [disabled]="disableEntireScreen" />
                                        <label
                                            for="rbt-Named">{{userBundle['workspace.incharge.pattern.namedDays']}}</label>
                                    </div>
                                    <div class="control_group_distribution">
                                        <input type="radio" [(ngModel)]="patternObj.patternType"
                                            (change)="onPatternTypeChange(1)" class="radio small" [value]="2"
                                            id="rbt-Numbered" [disabled]="disableEntireScreen" />
                                        <label
                                            for="rbt-Numbered">{{userBundle['workspace.incharge.pattern.numberedDays']}}</label>
                                    </div>
                                </td>
                                <td style="padding-right: 10px;" [ngClass]="{'readOnly' : readOnlyModal}">
                                    <div class="field-label bold">
                                        {{userBundle['workspace.incharge.pattern.calender.label.title']}}</div>
                                    <table style="width: 100%;" aria-label="burst-table">
                                        <tbody>
                                            <tr>
                                                <td style="width: 350px; height: 92px;" id="burstPattern1"
                                                    class="burst-pattern-nameddays-table"
                                                    *ngIf="patternObj.patternType == 1">
                                                    <table class="burst-pattern-day-table" aria-label="burst-pattern-day-table">
                                                        <tbody>
                                                            <tr>
                                                                <td class="burst-pattern-day-title" *ngIf="uiControl.showWeekNumberForPattern">
                                                                    {{userBundle['workspace.pattern.bric.weekShort']}}
                                                                </td>
                                                                <td class="burst-pattern-day-title">
                                                                    {{userBundle['workspace.pattern.bric.monShort']}}
                                                                </td>
                                                                <td class="burst-pattern-day-title">
                                                                    {{userBundle['workspace.pattern.bric.tueShort']}}
                                                                </td>
                                                                <td class="burst-pattern-day-title">
                                                                    {{userBundle['workspace.pattern.bric.wedShort']}}
                                                                </td>
                                                                <td class="burst-pattern-day-title">
                                                                    {{userBundle['workspace.pattern.bric.thuShort']}}
                                                                </td>
                                                                <td class="burst-pattern-day-title">
                                                                    {{userBundle['workspace.pattern.bric.friShort']}}
                                                                </td>
                                                                <td class="burst-pattern-day-title">
                                                                    {{userBundle['workspace.pattern.bric.satShort']}}
                                                                </td>
                                                                <td class="burst-pattern-day-title">
                                                                    {{userBundle['workspace.pattern.bric.sunShort']}}
                                                                </td>
                                                                <td style="width: 40px" class="burst-pattern-table-button"
                                                                    (click)="addDaysBlock()"
                                                                    [class.disabled]="disableEntireScreen">+</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div id="daysTableBP1">
                                                        <table class="burst-pattern-day-table" aria-label="burst-pattern-day-table"
                                                            *ngFor="let value of namedDays.row; trackBy: trackByIndex; let key = index; let last = last">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="burst-pattern-day daysBlock{{key}}" *ngIf="uiControl.showWeekNumberForPattern && listWeekNumber.length && value.columns.length">
                                                                        {{value.columns[0].weekNumber}}
                                                                    </td>
                                                                    <td *ngFor="let cell of value.columns; trackBy: trackByIndex; let columnIndex = index;"
                                                                        class="burst-pattern-day daysBlock{{key}}"
                                                                        (mousedown)="selectDay(key, columnIndex, $event)"
                                                                        [ngClass]="{'populated': cell.selected,
                                                                                    ' ': (!cell.selected || !cell.currentSelection || cell.disable) ,
                                                                                    'selected': cell.currentSelection,
                                                                                    'selectable': !cell.disable}">
                                                                        {{cell.displayText}}
                                                                    </td>

                                                                    <td style="width: 30px;" class="burst-pattern-table-button"
                                                                        *ngIf="last" (click)="removeDaysBlock(key);"
                                                                        [class.disabled]="disableEntireScreen">
                                                                        -
                                                                    </td>
                                                                    <td style="width: 30px;" class="burst-pattern-table-button"
                                                                        *ngIf="!last">
                                                                        &nbsp;
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                                <td style="width: 300px;" id="burstPattern2" *ngIf="patternObj.patternType == 2">
                                                    <div id="daysTableBP2">
                                                        <table id="NumberedDays" class="burst-pattern-day-table" aria-label="burst-pattern-day-table">
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let value of namedDays.row; trackBy: trackByIndex; let key = index;">
                                                                    <td *ngFor="let cell of value.columns; trackBy: trackByIndex; let columnIndex = index"
                                                                        class="burst-pattern-day selectable daysBlock{{key}}"
                                                                        [ngClass]="{'populated': cell.selected, '': (!cell.selected || !cell.currentSelection),
                                                                            'selected': cell.currentSelection }"
                                                                        (mousedown)="selectDay(key, columnIndex, $event)">
                                                                        {{cell.displayText}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                                <td style="padding-left: 40px;">
                                                    <table class="burst-pattern-table" style="width: 100%;" aria-label="burst-pattern-day-table">
                                                        <tbody>
                                                            <tr>
                                                                <td class="bold"
                                                                    *ngIf="selectedRange.allowDays && !selectedRange.allowDayParts && !selectedRange.allowHours">
                                                                    {{userBundle['workspace.pattern.bric.fullDay']}}
                                                                </td>
                                                                <td class="bold"
                                                                    *ngIf="selectedRange.allowDays && !selectedRange.allowDayParts && !selectedRange.allowHours">
                                                                    &nbsp;
                                                                </td>
                                                                <td class="bold"
                                                                    *ngIf="selectedRange?.allowDayParts == undefined || selectedRange?.allowDayParts">
                                                                    {{userBundle['workspace.incharge.pattern.calender.label.dayPart']}}
                                                                </td>
                                                                <td style="width: 60px;"
                                                                    *ngIf="(selectedRange?.allowHours == undefined || selectedRange?.allowHours)"
                                                                    class="bold">
                                                                    {{userBundle['workspace.incharge.pattern.calender.label.custom']}}
                                                                </td>
                                                                <td style="width: 100px" colspan="2"
                                                                    *ngIf="selectedRange?.allowHours == undefined || selectedRange?.allowHours">
                                                                    &nbsp;</td>
                                                                <td style="width: 40px;" class="burst-pattern-table-button"
                                                                    (click)="addDaypart(selectedDay)"
                                                                    [class.disabled]="disableEntireScreen">+</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div id="daypartTableBP">
                                                        <ng-container *ngTemplateOutlet="dropdowns"></ng-container>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="burst-pattern-legend" style="margin-top: 10px;">
                                        <div class="burst-pattern-legend-item">
                                            {{userBundle['workspace.pattern.bric.fullDayShort']}}</div>
                                        <div class="burst-pattern-legend-label">
                                            {{userBundle['workspace.pattern.bric.fullDay']}}</div>
                                        <div class="burst-pattern-legend-item">
                                            {{userBundle['workspace.pattern.bric.rangeShort']}}</div>
                                        <div class="burst-pattern-legend-label">
                                            {{userBundle['workspace.pattern.bric.range']}}</div>
                                        <div class="burst-pattern-legend-item">
                                            {{userBundle['workspace.pattern.bric.hoursShort']}}</div>
                                        <div class="burst-pattern-legend-label">
                                            {{userBundle['workspace.pattern.bric.hours']}}</div>
                                        <div class="burst-pattern-legend-item">
                                            {{userBundle['workspace.pattern.bric.rangeAndHoursShort']}}</div>
                                        <div class="burst-pattern-legend-label">
                                            {{userBundle['workspace.pattern.bric.rangeAndHours']}}</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="pattern-footer" footer>
        <div *ngIf="isPatternOverlapped" class="overlapped-msg">
            <span>{{this.userBundle['workspace.pattern.bric.overlappingSelection']}}</span>
        </div>
        <div tooltip class="infoIcon" *ngIf="productValidation">
            <img src="assets/img/information.png" appMasterTooltip [tooltipName]="'customtooltip'" alt="information"
                [tooltipScope]="1" />
            <div class="customtooltip">
                <div class="contents">
                    <div>
                        <h5>Product Validations</h5>
                        <div class="marginoffset" *ngIf="productValidation.startDate">
                            {{productValidation.startDate }}&nbsp;{{userBundle['workspace.rating.bric.to']}}&nbsp;{{productValidation.endDate}}
                        </div>
                        <div class="marginoffset" *ngIf="productValidation.defaultWeekDay">
                            {{ userBundle['workspace.pcm.incharge.defaultWeekDay']+": "+ userBundle['workspace.pcm.defaultweekday.' + productValidation.defaultWeekDay]}}
                        </div>
                        <div class="marginoffset"
                            *ngIf="productValidation?.defaultValue?.value > 0 && productValidation?.defaultValue?.unit == 0">
                            {{ userBundle['workspace.pcm.incharge.bric.default'] + ": " + productValidation.defaultValue.value + " " + userBundle['workspace.pcm.incharge.bric.day']}}
                        </div>
                        <div class="marginoffset"
                            *ngIf="productValidation?.defaultValue?.value > 0 && productValidation?.defaultValue?.unit != 0">
                            {{ userBundle['workspace.pcm.incharge.bric.default'] + ": " + productValidation.defaultValue.value + " " + userBundle['workspace.pcm.incharge.bric.week']}}
                        </div>
                        <div class="marginoffset"
                            *ngIf="productValidation?.minValue?.value > 0 && productValidation?.minValue?.unit == 0">
                            {{ userBundle['workspace.pcm.incharge.bric.min'] + ": " + productValidation?.minValue?.value + " " + userBundle['workspace.pcm.incharge.bric.day']}}
                        </div>
                        <div class="marginoffset"
                            *ngIf="productValidation?.minValue?.value > 0 && productValidation?.minValue?.unit != 0">
                            {{ userBundle['workspace.pcm.incharge.bric.min'] + ": " + productValidation.minValue.value + " " + userBundle['workspace.pcm.incharge.bric.week']}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #dropdowns>
        <div
            *ngIf="(selectedRange?.allowDayParts != false || selectedRange?.allowHours != false || selectedRange?.allowDays != false) && (dayPart.length > 0 || (customStartTime.length > 0 && customEndTime.length > 0))">
            <table id="daypartBlockBR" class="burst-pattern-table" style="margin-top: 1px; width: 100%;" aria-label="daypartBlockBR"
                *ngFor="let items of namedDays.row[selectedDay.rowIndex]?.columns[selectedDay.columnIndex].values; trackBy: trackByIndex; let i = index;">
                <tr>
                    <td style="width: 170px;"
                        *ngIf="selectedRange?.allowDayParts == undefined || selectedRange?.allowDayParts || selectedRange?.allowDays">
                        <select id="Select1" class="select form-control" [(ngModel)]="dayPart[i]"
                            (change)="dropDownChange(i, true)"
                            [ngClass]="{'overlapped': items.overlapped && !items.length}"
                            [disabled]="selectedRange.allowDays && !selectedRange.allowHours && !selectedRange.allowDayParts">
                            <option *ngFor="let item of dropDownData.dayPart; trackBy: trackByItem;" [ngValue]="item">{{item.displayName}}
                            </option>
                        </select>
                    </td>
                    <td *ngIf="(selectedRange?.allowDayParts == undefined || selectedRange?.allowDayParts) && (selectedRange?.allowHours == undefined || selectedRange?.allowHours)"
                        style="width: 60px;" class="bold" style="text-align: center; vertical-align: middle;">
                        {{userBundle['workspace.pattern.bric.or'] || 'Or'}}</td>
                    <td *ngIf="selectedRange?.allowHours == undefined || selectedRange?.allowHours" style="width: 118px;">
                        <select id="Select2" class="select form-control" [(ngModel)]="customStartTime[i]"
                        [ngClass]="{'overlapped': items.overlapped && items.length}"
                            (change)="dropDownChange(i, false)" [class.disabled]="disableCustomHours">
                            <option></option>
                            <option *ngFor="let item of dropDownData.spans; trackBy: trackByItem;" [ngValue]="item">{{item.startLabel}}
                            </option>
                        </select>
                    </td>
                    <td *ngIf="selectedRange?.allowHours == undefined || selectedRange?.allowHours"
                        style="padding-left: 10px; width: 125px;">
                        <select id="Select3" class="select form-control" [(ngModel)]="customEndTime[i]"
                        [ngClass]="{'overlapped': items.overlapped && items.length}"
                            (change)="dropDownChange(i, false)" [class.disabled]="disableCustomHours">
                            <option value=""></option>
                            <option *ngFor="let item of dropDownData.spans; trackBy: trackByItem;" [ngValue]="item">{{item.endLabel}}</option>
                        </select>
                    </td>
                    <td style="width: 40px;" class="burst-pattern-table-button" (click)="removeDaypart(selectedDay, i)">-</td>
                </tr>
            </table>
        </div>
    </ng-template>
</app-sb-modal-wrapper>
