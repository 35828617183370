<app-sb-modal-wrapper [title]="selectedTitle" [titleBackgroundColor]="brickBgColor" (closed)="onModalClosed($event)"
  (saved)="onModalSaved($event)">
  <div class="tabs_container horizontal">
    <table class="audit-table">
      <tr>
        <th>Date</th>
        <th>Type</th>
        <th>Description</th>
      </tr>
      <tr *ngFor="let item of auditRecords; trackBy: trackByAuditType;">
        <td>{{item.date}}</td>
        <td>{{item.auditType}}</td>
        <td>{{userBundle["workspace.audit."+item.auditType]}}</td>
      </tr>
    </table>
  </div>
</app-sb-modal-wrapper>
