import { Component, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import * as toasterConfig from '../../geoplanner/src/app/core/toasterConfig';
import { LoaderService } from '../../geoplanner/src/app/core/services';
import { Subscription } from 'rxjs';
import { AppHeaderService } from '../app-header/app-header.service';

@Component({
  selector: 'app-root',
  templateUrl: './app-root.component.html'
})
export class AppRootComponent implements OnInit {

  public toasterConfig: any = toasterConfig.toasterconfig;
  template = '<div class="slim-loading-bar-progress"></div>';
  private subscriptions: Subscription[] = [];
  selectedEnvironment = '';

  constructor(
    public loaderService: LoaderService,
    private location: LocationStrategy,
    private appHeaderService: AppHeaderService
  ) {

    this.location.onPopState(() => {
      this.loaderService.setBackButton(true);
      return false;
    });
  }

  ngOnInit() {
    this.subscriptions.push(this.appHeaderService.selectedEnvironment$.subscribe((newEnv) => {
      this.selectedEnvironment = newEnv;
    }));
  }

}
