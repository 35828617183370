import { NumberMaskModel, DynamicMaskModel } from '../../models/imask';
import * as imask from 'imask';
import { Injectable } from '@angular/core';
// Imported imask core library specially to use MaskedEnum.

@Injectable()
export class MaskService {
  /**
   * Defines all types of the masked value in ngModel
   */
  unmask: UnmaskModel = new UnmaskModel();

  /**
   * @description Returns imask options with Number mask
   * @author Shivani Patel
   * @param {NumberMaskModel} options
   * @returns
   * @memberof MaskService
   */
  public currencyInputMask(options?: NumberMaskModel): NumberMaskModel {
    return {
      ...new NumberMaskModel(),
      ...options
    };
  }

  /**
   * @description Returns imask options which allows Number mask and String 'Mixed'.
   * `NOTE: Please make separate instance of this method to use DynamicMask in multiple inputs of the same file.`
   * `as we are using variable named 'allowMixed' to prevent user to type 'Mixed'.`
   * @author Shivani Patel
   * @param {NumberMaskModel} [options]
   * @returns {DynamicMaskModel}
   * @memberof MaskService
   */
  public currencyInputMaskWithMixed(options?: NumberMaskModel): DynamicMaskModel {
    let allowMixed = true;
    return {
      mask: [
        {
          mask: imask.MaskedEnum,
          enum: ['Mixed']
        },
        {
          ...new NumberMaskModel(),
          ...options
        },
      ],
      dispatch: (appended: string, dynamicMasked) => {
        let mixed;
        if (/^[a-z]+$/i.test(appended) && allowMixed) {
          const value = (dynamicMasked.value + appended);
          if (value === 'Mixed') {
            // Prevent user to type 'Mixed' after first time populated.
            allowMixed = false;
          }
          mixed = dynamicMasked.compiledMasks[0];
        } else {
          // Keep it false to prevent user to type 'Mixed'.
          allowMixed = false;
          mixed = dynamicMasked.compiledMasks[1];
        }
        return mixed;
      },
    };
  }
}

/**
 * @description Model of Unmask
 * @author Shivani Patel
 * @export
 * @interface UnmaskModel
 */
class UnmaskModel {
  /**
   * Provides original value in ngModel. Used when we defined Type in mask option
   */
  typed: 'typed' = 'typed';
  /**
   * Provides original value in ngModel
   */
  true: true = true;
  /**
   * Provides formatted value in ngModel
   */
  false: false = false;
}
