<div [ngClass]="{'readOnly' : readOnly }">
  <div class="width_3">
    <div class="control_group">
      <label class="label">{{initialConfig.userBundle['common.productionTypeName']}}</label>
      <select id="Select1" class="form_control" [(ngModel)]="booking.productionTypeId">
          <option value=-1>{{initialConfig.userBundle['common.lookup.defaultOption']}}</option>
        <option *ngFor="let item of productionType; trackBy: trackByProductionType;" [value]="item.productionTypeId">
          {{item.productionTypeName}}
        </option>
      </select>
    </div>
    <div class="control_group" *ngIf="!dataShareService.hideUIElements('commercial.booking.productionValue')">
      <label class="label">{{initialConfig.userBundle['commercial.label.productionCost']}}</label>
      <input type="text" [(ngModel)]="booking.productionCost" class="form_control" [imask]="currencyInputMask"
        [unmask]="maskService.unmask.typed" />
    </div>
  </div>
  <div class="width_3">
    <div class="control_group">
      <label class="label">{{initialConfig.userBundle['common.buyerContactName']}}</label>
      <div class='gcontainer'>
        <app-sb-select id="buyercontact" [items]="buyerContactList"
          [currentItem]="booking.buyerContactId" (onItemSelected)="onbuyerContactSelected($event)" (onItemRemoved)="removeSelectedbuyerContact()"
          (responseData)="getBuyerContactResponse($event)">
        </app-sb-select>
      </div>
    </div>
  </div>
  <div class="width_3">
    <div class="control_group" *ngIf="!dataShareService.hideUIElements('common.ctuPersonName')">
      <label class="label">{{initialConfig.userBundle['common.ctuPersonName']}}</label>
      <div class='gcontainer'>
        <app-sb-select [items]="ctuPersonList" [currentItem]="booking['ctuPerson']"
          (onItemSelected)="onCtuPersonSelected($event)" (onItemRemoved)="removeSelectedCtuPerson()" (responseData)="getCtuPersonResponse($event)">
        </app-sb-select>
      </div>
    </div>
  </div>
  <div class="width_3">
    <div class="control_group" *ngIf="!dataShareService.hideUIElements('commercial.booking.discountValue')">
      <label class="label">{{initialConfig.userBundle['commercial.label.discount']}}</label>
      <input type="text" [(ngModel)]="booking.discount" class="form_control" [imask]="percentageInputMask"
        [unmask]="maskService.unmask.typed" />
    </div>
  </div>
  <div class="width_8">
    <div class="control_group">
      <label class="label">{{initialConfig.userBundle['common.comments']}}</label>
      <textarea id="comments" class="form_control customtextarea-comments" [(ngModel)]="booking.comments" maxlength="2000" cols="30"
        rows="1"></textarea>
    </div>
  </div>
  <div class="width_3">
    <div class="control_group">
      <label class="label datelabel">
        {{initialConfig.userBundle['commercial.label.bookingStart']}}
      </label>
      <input type="text" [disabled]="true" [ngModel]="booking.startDate | dateFormat : displayDateFormat"
        class="form_control">
    </div>
  </div>
  <div class="width_3">
    <div class="control_group">
      <label class="label datelabel">
        {{initialConfig.userBundle['commercial.label.bookingEnd']}}
      </label>
      <input type="text" [disabled]="true" [ngModel]="booking.endDate | dateFormat : displayDateFormat"
        class="form_control col-md-3">
    </div>
  </div>
  <label class="subtitles">{{initialConfig.userBundle['commercial.title.financialSummary']}}</label>
  <div class="width_3" *ngIf="!initialConfig.uiControl.hidePrice">
    <div class="control_group">
      <label class="label">{{initialConfig.userBundle['common.smartBricsValue']}}</label>
      <input id="smartBricsValue" type="text" [(ngModel)]="booking.smartBricsValue" [disabled]="true" class="form_control" maxlen="9"
        [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" />
    </div>
  </div>
  <div *ngIf="!booking.hideMediaControl">
    <label class="subtitles">{{initialConfig.userBundle['commercial.title.mediaInformation']}}</label>
    <div class="width_3">
      <div class="control_group">
        <label class="label">{{initialConfig.userBundle['commercial.label.mediaLength']}}</label>
        <input id="mediaLength" type="text" class="form_control" [(ngModel)]="booking.mediaLength" [appOnlyNumber]="true"
          [decimalPoints]="0" [maxValue]="999" />
      </div>
    </div>
  </div>
</div>
