import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';

@Component({
  selector: 'app-ag-checkbox',
  templateUrl: './ag-checkbox.component.html',
  styleUrls: ['./ag-checkbox.component.css']
})
export class AgCheckboxComponent implements ICellRendererAngularComp {
  public params: any;
  public isSelected: boolean;
  private groupingField = '';

  // called on init
  agInit(params: any): void {
    this.params = params;
    this.setValues();
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    this.setValues();
    return true;
  }

  private setValues(): void {
    this.groupingField = this.params.api.gridCore.gridOptions.groupingField;
    if (this.params.data) { // data row
      this.isSelected = this.params.data.isSelected;
    } else { // header cell
      if (this.groupingField) {
        const parentRows = this.params.context.componentParent.rowData.filter((row) => {
          return row.isParent && row.isFilterPass;
        });
        const selectedParentRows = parentRows.filter((row) => {
          return row.isSelected;
        });
        this.isSelected = selectedParentRows.length === parentRows.length;
      } else {
        const parentRows = this.params.context.componentParent.rowData.filter((row) => {
          return row.isFilterPass;
        });
        const selectedParentRows = parentRows.filter((row) => {
          return row.isSelected;
        });
        this.isSelected = selectedParentRows.length === parentRows.length;
      }
    }
  }

  onSelectChange($event) {
    this.isSelected = $event.target.checked;

    const rowData = this.params.context.componentParent.rowData;
    let rowIndex = this.params.rowIndex;
    const row = this.params.data;
    if (this.params.data && this.groupingField) {
      rowIndex = rowData.findIndex(ele => (ele.isParent && ele[this.groupingField] === this.params.data[this.groupingField]));
    }
    if (typeof (rowIndex) !== 'undefined') {
      row.isSelected = this.isSelected;
      if (row.isParent) {
        this.toggleParentRow(rowData, rowIndex);
      } else {
        this.toggleChildRow(rowData, row);
      }
    } else {
      this.toggleHeaderRow(rowData);
    }

    this.params.context.componentParent.rowData = _.clone(rowData);
    // Refresh collpse/expand Header Cell
    const colId = this.params.column.colId;
    this.params.api.headerRootComp.forEachHeaderElement((e) => {
      if (e.column.colId === colId && e.columnApi && e.childComponents && e.childComponents.length > 1) {
        const headerCmpIns = e.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
  }

  private toggleHeaderRow(rowData) {
    for (const row of rowData) {
      if (row.isFilterPass) {
        row.isSelected = this.isSelected;
      }
    }
  }

  private toggleParentRow(rowData, rowIndex) {
    for (let i = rowIndex + 1; i < rowData.length; i++) {
      if (rowData[i].isParent) {
        break;
      } else {
        if (rowData[i].isFilterPass) {
          rowData[i].isSelected = this.isSelected;
        }
      }
    }
  }

  private toggleChildRow(rowData, row) {
    if (this.groupingField) {
      const parentRow = rowData.filter((r) => {
        return (r[this.groupingField] === row[this.groupingField] && r.isParent);
      });
      const childRows = rowData.filter((r) => {
        return (r[this.groupingField] === row[this.groupingField] && (!r.isParent) && r.isFilterPass);
      });
      const selectedChildRows = childRows.filter((r) => {
        return r.isSelected;
      });
      if (parentRow.length > 0) {
        parentRow[0].isSelected = childRows.length === selectedChildRows.length;
      }

    }
  }
}
