import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../../core/core.module';
import {
  RangeComponent,
  MediaComponent,
  EnvironmentModalComponent,
  FormatModalComponent,
  LocationModalComponent,
  PricingModalComponent,
  FramequalityModalComponent,
  ProximityModalComponent,
  FrameComponent,
  BudgetModalComponent,
  TagModalComponent,
  SotModalComponent,
  SotPCMModalComponent,
  DistributionModalComponent,
  AudienceModalComponent,
  ChannelComponent,
  ProductCatalogueModalComponent,
  ListModalComponent,
  VolumeModalComponent,
  PatternModalComponent,
  PricingTagModalComponent,
  NetworkModalComponent,
  AdvanceAvailabilityComponent,
  AddProductComponent,
  AdvanceDashboardComponent,
  CampaignSplitComponent,
  EnableDisableProductComponent,
  AllAudienceComponent,
  PrimaryAudienceModalComponent,
  ReshuffleProcessComponent,
  ReshuffleConfirmComponent,
  ContinueWorkComponent,
  ReshuffleDetailLSComponent,
  ReshuffleDetailLSResultComponent,
  MultiTargetModalComponent
} from './index';
import { TagInputModule } from 'ngx-chips';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SbModalPopupModule, SbModalPopupService } from './../../core/components/sb-modal-popup/index';
import { IMaskModule } from 'angular-imask';
import { MultiTargetPcmModalComponent } from '../../bricks/multi-target/multi-target-pcm-modal/multi-target-pcm-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SbModalPopupModule,
    TagInputModule,
    CoreModule.forRoot(),
    NgxSliderModule,
    IMaskModule
  ],
  // entryComponents: [
  //   RangeComponent,
  //   MediaComponent,
  //   TagModalComponent,
  //   ChannelComponent,
  //   EnvironmentModalComponent,
  //   FormatModalComponent,
  //   LocationModalComponent,
  //   PricingModalComponent,
  //   FramequalityModalComponent,
  //   ProximityModalComponent,
  //   FrameComponent,
  //   ListModalComponent,
  //   BudgetModalComponent,
  //   VolumeModalComponent,
  //   SotModalComponent,
  //   SotPCMModalComponent,
  //   DistributionModalComponent,
  //   AudienceModalComponent,
  //   ChannelComponent,
  //   ProductCatalogueModalComponent,
  //   VolumeModalComponent,
  //   PatternModalComponent,
  //   PricingTagModalComponent,
  //   NetworkModalComponent,
  //   AdvanceAvailabilityComponent,
  //   AddProductComponent,
  //   AdvanceDashboardComponent,
  //   PricingTagModalComponent,
  //   EnableDisableProductComponent,
  //   CampaignSplitComponent,
  //   AllAudienceComponent,
  //   PrimaryAudienceModalComponent,
  //   ReshuffleProcessComponent,
  //   ReshuffleConfirmComponent,
  // ],
  declarations: [
    RangeComponent,
    MediaComponent,
    TagModalComponent,
    ChannelComponent,
    EnvironmentModalComponent,
    FormatModalComponent,
    LocationModalComponent,
    PricingModalComponent,
    FramequalityModalComponent,
    ProximityModalComponent,
    FrameComponent,
    ListModalComponent,
    BudgetModalComponent,
    VolumeModalComponent,
    SotModalComponent,
    SotPCMModalComponent,
    DistributionModalComponent,
    AudienceModalComponent,
    ChannelComponent,
    ProductCatalogueModalComponent,
    VolumeModalComponent,
    PatternModalComponent,
    PricingTagModalComponent,
    NetworkModalComponent,
    AdvanceAvailabilityComponent,
    AddProductComponent,
    AdvanceDashboardComponent,
    PricingTagModalComponent,
    CampaignSplitComponent,
    EnableDisableProductComponent,
    AllAudienceComponent,
    PrimaryAudienceModalComponent,
    ReshuffleProcessComponent,
    ReshuffleConfirmComponent,
    ReshuffleDetailLSComponent,
    ReshuffleDetailLSResultComponent,
    MultiTargetModalComponent,
    MultiTargetPcmModalComponent,
    ContinueWorkComponent
  ],
  providers: [SbModalPopupService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [NgxSliderModule]
})
export class ModalWindowModule { }
