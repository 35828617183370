
export class ProductCatalogue {
  idProductCatalogue: number;
  productCatalogueName = '';
  productCatalogueDescription = '';
  productCatalogueGroupId = 0;
  productCatalogueTypeId = 0;
  restrictions = {
    advertisers: [],
    agencies: [],
    specialists: [],
    campaignTypes: []
  };
  priceConfiguration?: PriceConfiguration = new PriceConfiguration();
  inUse = true;
  bricsData: any[] = [];

}

export class PriceConfiguration {
  fixedCPM = false;
  fixedCPMValue = null;
  limitPricingFactors = false;
  factors: PricingFactor[] = [];
}
export class PricingFactor {
  factor: string;
  min: number;
  max: number;
  enabled = true;
}
