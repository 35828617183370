import { Component } from '@angular/core';
import { AgTrafficLightCellRendererComponent } from '../ag-traffic-light-cell-renderer/ag-traffic-light-cell-renderer.component';
@Component({
  selector: 'app-ag-reshuffle-traffic-light-cell-renderer',
  templateUrl: './ag-reshuffle-traffic-light-cell-renderer.component.html',
  styleUrls: ['./ag-reshuffle-traffic-light-cell-renderer.component.css']
})
export class AgResuffleTrafficLightCellRendererComponent extends AgTrafficLightCellRendererComponent {

}
