<app-sb-modal-wrapper class="" [title]="title" [titleBackgroundColor]="brickBgColor" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
    <!-- <div *ngIf="confirmation; then truthyTemplate; else falsyTemplate"></div>

    <ng-template #falsyTemplate>
        <div class="control_group ">
            <label>{{userBundle[result.campaignTitle]}} :</label>
            <input type="text" class="form_control" [(ngModel)]="campaignSave.campaignName" />
        </div>
    </ng-template>
    
    <ng-template #truthyTemplate>
        <div class="control_group ">
            <div class="alert allow-copy">{{campaignSave}}</div>
          </div>
    </ng-template> -->

    <div class="control_group allow-copy" *ngIf="showInfoLabel">
        <label class="label" style="width: 100%" [innerHTML]="message"></label>
    </div>
    <div class="control_group" *ngIf="!isConfirmProposal" >
        <label class="left label" style="width:24%">{{displalabel}}</label>
        <input type="text" class="form_control left" [(ngModel)]="url" style="width:50%" />
        <button id="prpopsal-copy" (click)="copy()" class="btn-xs btn-primary btn-proposal btn-proposal-bind left"> {{userBundle['common.button.copy'] || 'Copy'}} </button>
        <br/>
        <span class="txt-to-copy" style="opacity:0; white-space: nowrap;">{{url}}</span>
    </div>
</app-sb-modal-wrapper>