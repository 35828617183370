import { ControlValueAccessor } from '@angular/forms';

export type lookUpModel = string | { [key: string]: any };
const noop = () => {
  //
};
/**
 * Check wheather input is valid object or not.
 * @param obj Object to be checked.
 */
export function isObject(obj: any): boolean {
  return obj === Object(obj);
}

export class LookupModelAccessor implements ControlValueAccessor {
  /**
   * It contains list of items.
   */
  private items: lookUpModel[] = [];
  /**
   * Callback function for touch event.
   */
  private onTouchedCallback: () => void = noop;
  /**
   * Callback function for change event.
   */
  private onChangeCallback: (items: lookUpModel[]) => void = noop;

  /**
   * Get the lookup model.
   * @return lookup model
   */
  public get dataModel(): lookUpModel[] {
    return this.items;
  }

  /**
   * Set the lookup model.
   * @param items lookup model array
   */
  public set dataModel(items: lookUpModel[]) {
    this.items = items;
    this.onChangeCallback(this.items);
  }

  /**
   * To be called on touch event.
   */
  public onTouched() {
    this.onTouchedCallback();
  }

  /**
   * To write the data.
   */
  public writeValue(items: any[]) {
    this.items = items || [];
  }

  /**
   * To register on change event.
   * @param fn Callback function.
   */
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  /**
   * To register on touch event.
   * @param fn Callback function.
   */
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
