import { StateService, LogHelperService, BrickBaseService, DataShareService } from '../../core/services';

import { InitialConfigModel } from '../initial-config.model';
import { Row } from './row';
import { SystemFlags } from '../system-flags';
import { ColumnHelper } from './column-helper';
import { Filter } from './filters';
import { GLOBAL } from '../../core/utils/app.constant';

export class Reshuffle {

  public filter: Filter;

  /**
   * @description column index list where need to show reshuffle icon
   * @type {number[]}
   * @memberof Reshuffle
   */
  public reshufflingColumns: number[] = [];

  /**
   * * @description Contains list of column who has warning from LS
   */
  public lsWarning: string[] = [];

  /**
   * @description brick ids to use in reshuffling
   * @private
   * @type {number[]}
   * @memberof Reshuffle
   */
  private reshuffleBrickIds: number[] = [];

  /**
   * @description global initial config
   * @private
   * @type {InitialConfigModel}
   * @memberof Reshuffle
   */
  private initialConfig: InitialConfigModel;

  constructor(
    private stateService: StateService,
    private logHelperService: LogHelperService,
    private brickBaseService: BrickBaseService,
    private dataShareService: DataShareService,
  ) {
    this.reshuffleBrickIds = [
      this.brickBaseService.brickID.Frame as number,
      this.brickBaseService.brickID.Budget as number,
      this.brickBaseService.brickID.Volume as number,
      this.brickBaseService.brickID.PricingTag as number
    ];
    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  /**
   * @description check for reshuffling, reshuffle brics user enter value against assigned value
   * @author Alkesh Shah
   * @param {Row[]} rows - list of row
   * @memberof Reshuffle
   */
  public checkToleranceReshuffleBricks(rows: Row[], columnHelper: ColumnHelper): void {
    const invalidCols: number[] = this.getColsToleranceReshuffleBricks(rows, columnHelper);
    if (invalidCols.length > 0) {
      this.logHelperService.logInfo(this.initialConfig.userBundle['workspace.error.reshuffle.lessallocated']);
    }
  }

  private checkColumnLocking(): boolean {
    return !SystemFlags.incorrectSolution && !SystemFlags.readOnlyWorkspace;
  }

  private isColumnLockedOrNonEditable(columnIndex: number, columnHelper: ColumnHelper): boolean {
    if (
      columnHelper &&
      columnHelper[columnIndex]
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description get the column list where reshuffle is needed
   * @author Alkesh Shah
   * @private
   * @param {Row[]} rows - list of row
   * @returns {number[]} - list of column indexes
   * @memberof Reshuffle
   */
  private getColsToleranceReshuffleBricks(rows: Row[], columnHelper: ColumnHelper): number[] {
    let cols = [];
    const user = this.dataShareService.getUserModel();
    if (Object.keys(this.stateService.columnSummary).length > 0 && user.workspaceTabAccess.reshuffleBooking && this.initialConfig.uiControl.reshuffleEnabled) {
      const rowIndexReshuffleBricks = this.getRowIndexOfReshuffleBricks(rows);
      for (const rowIndex of rowIndexReshuffleBricks) {
        let colIndexs = [];
        if (rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Frame) {
          colIndexs = this.getColsQtyRowThreashold(rows, rowIndex, 'frameCount');
        } else if (rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Budget) {
          colIndexs = this.getColsQtyRowThreashold(rows, rowIndex, 'price');
        } else if (rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Volume) {
          colIndexs = this.getColsQtyRowThreashold(rows, rowIndex, 'impressions');
        } else if (rows[rowIndex].bric.bricid === this.brickBaseService.brickID.PricingTag) {
          colIndexs = this.getColsPricingTagThreashold(rows, rowIndex);
        }

        for (const col of colIndexs) {
          if (cols.indexOf(col) === -1) {
            cols.push(col);
          }
        }
      }

      // SM-4340 - Alkesh Shah
      // dont show reshuffle for lock and past column
      if (cols.length > 0 && this.checkColumnLocking()) {
        const unLockCols = [];
        for (const colIndex of cols) {
          if (!this.isColumnLockedOrNonEditable(colIndex, columnHelper)) {
            unLockCols.push(colIndex);
          }
        }
        cols = unLockCols;
      }
    }

    return cols;
  }

  /**
   * @description get the row indexes of reshuffle bricks
   * @author Alkesh Shah
   * @private
   * @param {Row[]} rows - list of row
   * @returns {number[]} - list of row indexes
   * @memberof Reshuffle
   */
  private getRowIndexOfReshuffleBricks(rows: Row[]): number[] {
    const rowIndexReshuffleBricks: number[] = [];
    rows.forEach((row, index: number) => {
      if (this.reshuffleBrickIds.indexOf(row.bric.bricid) !== -1) {
        rowIndexReshuffleBricks.push(index);
      }
    });
    return rowIndexReshuffleBricks;
  }

  /**
   * @description check the threashold of qty bricks used in reshuffle
   * @author Alkesh Shah
   * @private
   * @param {Row[]} rows - list of row
   * @param {number} rowIndex - rowIndex of checking bricks
   * @param {string} requestedKey - property key name which contains user enter value
   * @returns {number[]} - list of column indexes which overlaps threashold
   * @memberof Reshuffle
   */
  private getColsQtyRowThreashold(rows: Row[], rowIndex: number, requestedKey: string): number[] {
    const cols = [];
    const row = rows[rowIndex];
    for (let i = 0; i < row.cells.length; i++) {
      if (row.cells[i] && row.cells[i].selected && Object.keys(row.cells[i].selected).length > 0 && !row.cells[i].isEmpty) {
        const allocated = parseFloat(row.cells[i].selected.allocated) || 0;
        const requested = parseFloat(row.cells[i].selected[requestedKey]) || 0;
        // SM-4371 - no need to check tolerance
        // const tolerance = parseFloat(row.cells[i].selected.tolerance) || 0;
        // const minThreshold = (requested * (100 - tolerance)) / 100;
        if (allocated < requested) {
          cols.push(i);
        }
      }
    }
    return cols;
  }

  /**
   * @description check the threashold of price tag bricks used in reshuffle
   * @author Alkesh Shah
   * @private
   * @param {Row[]} rows - list of row
   * @param {number} rowIndex - rowIndex of price tag brick
   * @returns {number[]} - list of column indexes which overlaps threashold
   * @memberof Reshuffle
   */
  private getColsPricingTagThreashold(rows: Row[], rowIndex: number): number[] {
    const cols = [];
    const row = rows[rowIndex];
    for (let i = 0; i < row.cells.length; i++) {
      if (row.cells[i] && row.cells[i].selected && Object.keys(row.cells[i].selected).length > 0 && !row.cells[i].isEmpty) {
        const key = Object.keys(row.cells[i].selected)[0];
        if (row.cells[i].selected[key].length === 1) {
          const allocated = this.stateService.columnSummary[i] && this.stateService.columnSummary[i].numberFrames ? this.stateService.columnSummary[i].numberFrames : 0;
          const requested = parseFloat(row.cells[i].selected[key][0].networkDefaultQuantity) || 0;
          if (allocated < requested) {
            cols.push(i);
          }
        }
      }
    }
    return cols;
  }

  /**
   * @description fill the reshuffling column list
   * @author Alkesh Shah
   * @param {Row[]} rows - list of row
   * @param {Row} mediaBrickRow - media bric row object
   * @param {boolean} isNoAvailableFrame - is no frame available in response
   * @param {number} reshuffleColIndexTocheck - if no frame available then bricfailure column index
   * @memberof Reshuffle
   */
  public populateReshufflingColumns(rows: Row[], mediaBrickRow: Row, isNoAvailableFrame: boolean, reshuffleColIndexTocheck: number, columnHelper: ColumnHelper): void {
    this.reshufflingColumns = [];
    this.lsWarning = [];
    // when reshuffle is enabled for environment - uiControl.reshuffleEnabled: true
    // and user has rights to do reshuffling - token RESHUFFLE_BOOKING
    const user = this.dataShareService.getUserModel();
    if (user.workspaceTabAccess.reshuffleBooking && this.initialConfig.uiControl.reshuffleEnabled) {
      if (!GLOBAL.localSolverEnabled) {
        if (isNoAvailableFrame) {
          // when KO is returned in processBric call
          // need to show reshuffle icon on column recieved in response where media is paper
          if (mediaBrickRow && (reshuffleColIndexTocheck || reshuffleColIndexTocheck === 0)) {
            let previousUsedIndexMedia = mediaBrickRow.getOverlappingCell(reshuffleColIndexTocheck);
            previousUsedIndexMedia = previousUsedIndexMedia === -1 ? reshuffleColIndexTocheck : previousUsedIndexMedia;
            const cellValue = mediaBrickRow.cells[previousUsedIndexMedia];
            if (cellValue && cellValue.selected && cellValue.selected.selectedMediaObject.mediaTypeId === 0) {
              if (this.isReshuffleBricAvailableOncolumn(rows, reshuffleColIndexTocheck)) {
                this.reshufflingColumns.push(reshuffleColIndexTocheck);
              }
            }
          }
        } else {
          const invalidCols = this.getColsToleranceReshuffleBricks(rows, columnHelper);
          if (invalidCols.length > 0) {
            if (mediaBrickRow) {
              for (const invalidCol of invalidCols) {
                let previousUsedIndexMedia = mediaBrickRow.getOverlappingCell(invalidCol);
                previousUsedIndexMedia = previousUsedIndexMedia === -1 ? invalidCol : previousUsedIndexMedia;
                const cellValue = mediaBrickRow.cells[previousUsedIndexMedia];
                // reshuffling is allowed for only paper media column
                if (cellValue && cellValue.selected && cellValue.selected.selectedMediaObject.mediaTypeId === 0) {
                  this.reshufflingColumns.push(invalidCol);
                }
              }
            }
          }
        }
      } else {
        this.reshufflingColumns = [];
        const columnSummary = SystemFlags.incorrectSolution ? null : this.stateService.getAllColumnSummary();

        this.filter = this.stateService.getWorkspaceFilterObj();
        if (!this.filter.isMandatoryBricksAvailable()) {
          return;
        }
        this.filter.rows[0].cells.forEach((cell) => {
          let allowedToPush = true;
          if (columnHelper && Object.keys(columnHelper).length && columnHelper[cell.cellIndex] && (columnHelper[cell.cellIndex].systemLocked || columnHelper[cell.cellIndex].userLocked)) {
            allowedToPush = false;
          }
          if (allowedToPush) {
            this.reshufflingColumns.push(cell.cellIndex);
          }

          if (columnSummary && columnSummary[cell.cellIndex]
            && (Math.abs((columnSummary[cell.cellIndex].audienceImpressions - columnSummary[cell.cellIndex].audienceImpressionsEngine)
              / columnSummary[cell.cellIndex].audienceImpressions))
            > (this.initialConfig.uiControl.allocationAudienceDiscrepancy / 100)) {
            // ((audienceImpressions - audienceImpressionsEngine) / audienceImpressions) > allocationAudienceDiscrepancy / 100
            this.lsWarning.push(
              this.initialConfig.userBundle['allocation.audience.warning']
                .replace('{audienceImpressionsEngine}', columnSummary[cell.cellIndex].audienceImpressionsEngine)
                .replace('{audienceImpressions}', columnSummary[cell.cellIndex].audienceImpressions)
                .replace('{AllocationAudienceDiscrepancy}', this.initialConfig.uiControl.allocationAudienceDiscrepancy));
          } else {
            this.lsWarning.push(null);
          }
        });
      }
    }
  }

  /**
   * @description is any of reshuffling bric is available on specified column
   * @author Alkesh Shah
   * @private
   * @param {Row[]} rows - list of row
   * @param {number} colIndex - column index of column to check
   * @returns {boolean}
   * @memberof Reshuffle
   */
  private isReshuffleBricAvailableOncolumn(rows: Row[], colIndex: number): boolean {
    let isReshuffleBricAvail = false;
    const rowIndexReshuffleBricks = this.getRowIndexOfReshuffleBricks(rows);
    for (const rowIndex of rowIndexReshuffleBricks) {
      let previousUsedIndex = rows[rowIndex].getOverlappingCell(colIndex);
      previousUsedIndex = previousUsedIndex === -1 ? colIndex : previousUsedIndex;
      const cellValue = rows[rowIndex].cells[previousUsedIndex];
      if (cellValue && cellValue.selected && Object.keys(cellValue.selected).length > 0 && !cellValue.isEmpty) {
        isReshuffleBricAvail = true;
      }

      if (isReshuffleBricAvail) {
        break;
      }
    }

    return isReshuffleBricAvail;
  }
}
