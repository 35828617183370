import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceComponent } from './workspace.component';
import { DndModule } from 'ngx-drag-drop';
import { CoreModule } from '../core/core.module';
import { ModalWindowModule } from './modal-windows/modal-windows.module';
import { WorkspaceService } from '../core/services/workspace.service';
import { WorkspaceActionButtonService } from './workspace.action.button.service';
import { CampaignSummaryComponent } from './campaign-summary/campaign-summary.component';
import { AllocationAdjustmentComponent } from './allocation-adjustment/allocation-adjustment.component';
import { PcmComponent } from './pcm/pcm.component';
import { ReshuffleService } from './reshuffle.service';
import { AuditModalComponent } from './audit-modal/audit-modal.component';
import { SbModalPopupModule } from '../core/components/sb-modal-popup';
import { ObjectiveComponent } from './objective/objective.component';
import { ObjectiveService } from './objective/objective.service';
@NgModule({
  imports: [
    CommonModule,
    DndModule,
    // ResizableModule,
    ModalWindowModule,
    CoreModule.forRoot(),
    SbModalPopupModule.forRoot([AuditModalComponent])
  ],
  // entryComponents: [
  //   WorkspaceComponent,
  //   CampaignSummaryComponent,
  //   AllocationAdjustmentComponent,
  //   PcmComponent
  // ],
  declarations: [
    WorkspaceComponent,
    CampaignSummaryComponent,
    AllocationAdjustmentComponent,
    PcmComponent,
    AuditModalComponent,
    ObjectiveComponent
  ],
  exports: [
    WorkspaceComponent,
    PcmComponent,
    ObjectiveComponent
  ],
  providers: [
    WorkspaceService,
    WorkspaceActionButtonService,
    ReshuffleService,
    ObjectiveService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WorkspaceModule { }
