import { FileUpload, Filter, InitialConfigModel, ProductHelper } from '../../models';
import { DataShareService } from '../../core/services/data-share.service';
import { BrickBaseService } from '../../core/services/brick-base.service';
import { LogHelperService, StateService } from '../../core/services';
import { MultiTargetService } from './multi-target.service';
import * as _ from 'lodash';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
declare let saveAs; // FileSaver.js
const UPLOAD_FILE = 'geoplanner.text.uploadFile';
const UPLOAD_FILE1 = 'Upload File';
const maxNoFiles = 'workspace.error.list.maxNoFiles';
export class MultiTargetBase {
  /**
   * It contians initial config data.
   */
  public initialConfig: InitialConfigModel;

  /**
   * File to send to backend
   * @type {*}
   * @memberof MultiTargetBase
   */
  public fileToSend: any;

  /**
   * Initalize multi target list modal
   */
  public listData: FileUpload;
  /**
   * It contains Clone of file
   */
  public cloneOfFileList: any = [];

  productValidation: any;
  userBundle: any;
  cellIndex: number;
  fileComponentLocal: any;
  filter: Filter;
  /**
   * Creates an instance of MultiTargetBase.
   * @param {DataShareService} dataShareService
   * @param {BrickBaseService} brickBaseService
   * @param {LogHelperService} logHelperService
   * @param {MultiTargetService} multiTargetService
   * @memberof MultiTargetBase
   */
  constructor(
    private dataShareService: DataShareService,
    public brickBaseService: BrickBaseService,
    public logHelperService: LogHelperService,
    private multiTargetService: MultiTargetService,
    private stateService: StateService,
    private appHeaderService: AppHeaderService
  ) {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.listData = new FileUpload();
  }

  /**
   * @description method to check whether object is a file or not
   * @author Siddharth Vaidya
   * @param {*} selectedValue - selected value of cell
   * @memberof MultiTargetBase
   */
  isFileObject(obj) {
    return obj instanceof File;
  }
  /**
   * @description initialize the data of instance
   * @author Nishit Parekh
   * @param {*} selectedValue - selected value of cell
   * @memberof MultiTargetBase
   */
  init(selectedValue: FileUpload) {
    this.filter = this.stateService.getWorkspaceFilterObj();
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    if (Object.keys(selectedValue).length > 0 && selectedValue.listUpload && Object.keys(selectedValue.listUpload).length > 0) {
      this.listData.listUpload = selectedValue.listUpload;
      this.cloneOfFileList = _.clone(this.listData.listUpload.fileList);
      this.listData.fileNameLabel = this.initialConfig.userBundle[UPLOAD_FILE] || UPLOAD_FILE1;
    }
    if (selectedValue?.listUpload?.fileList?.length && this.isFileObject(selectedValue?.listUpload?.fileList[0])) {
      this.listData.fileNameLabel = selectedValue?.listUpload?.fileList[0].name
    }
    if (!this.appHeaderService.enabledPCM) {
      const productHelper: ProductHelper = this.filter.getProductHelperIfProductExists(this.cellIndex);
      if (productHelper) {
        const multiTargetValidations = this.filter.getProductValidations(this.cellIndex, productHelper, Number(this.brickBaseService.brickID.MultiTarget));
        if (multiTargetValidations) {
          this.productValidation = Object.keys(multiTargetValidations).length ? multiTargetValidations : null;
        }
      }
    }
  }

  /**
   * @description include, exclude file status change
   * @author Nishit Parekh
   * @param {*} event
   * @param {number} i
   * @memberof MultiTargetBase
   */
  fileStatusChanged(event: KeyboardEvent, i: number) {
    this.cloneOfFileList[i] = _.clone(this.listData.listUpload.fileList[i]);
    this.listData.listUpload.fileList[i].exclude = event;
  }

  /**
   * @description Log error message occur during file upload
   * @author Nishit Parekh
   * @param {*} data - msg if any
   * @memberof MultiTargetBase
   */
  logerror(data: string | number) {
    if (data) {
      this.logHelperService.logError(`${this.initialConfig.userBundle[maxNoFiles]} ${data}`);
    } else {
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.invalidFile']);
    }
  }

  checkForExistingFileList(file: { name: any; }): boolean {
    this.listData.listUpload.fileList.forEach((obj) => {
      if (obj.name !== file.name && this.listData.listUpload.fileList.indexOf(file) === -1) {
        if (this.listData.listUpload.fileList.length < 1) {
          this.listData.listUpload.fileList.push(file);
        } else {
          return false;
        }
      }
    });

    return true;
  }

  /**
   * @description send files to server
   * @author Nishit Parekh
   * @param {any[]} fileToSend list of files to send
   * @param {*} routeIdToSend route ids
   * @param {number} itemIndex index of current list item
   * @returns {string} - empty string if success else error msg in upload
   * @memberof MultiTargetBase
   */
  async sendUploadRequest(fileToSend: any[], itemIndex: number, range: any = undefined, selectedAvailability: string = ''): Promise<string> {
    let fs;
    if (fileToSend && fileToSend.length > itemIndex) {
      fs = fileToSend[itemIndex];
      // SM-10158
      if (fs && fs.userSelectionId && fs.selectedSOTOption === selectedAvailability) {
        let index = itemIndex;
        return this.sendUploadRequest(fileToSend, ++index, range, selectedAvailability);
      }
      let dayPartGroupId = 1;
      const columnConfig = this.stateService.getWorkspaceObject('columnConfig');
      if (columnConfig && columnConfig[this.cellIndex] && columnConfig[this.cellIndex].dayPartGroupId) {
        dayPartGroupId = this.stateService.getWorkspaceObject('columnConfig')[this.cellIndex].dayPartGroupId;
      }
      const resData = await this.multiTargetService.upload(fs, this.initialConfig.serviceCalls.FILE_UPLOAD_URL, !this.appHeaderService.enabledPCM ? this.productValidation : null, dayPartGroupId, range, selectedAvailability);
      return this.uploadCallBack(resData, fileToSend, itemIndex, range, selectedAvailability);
    }
    return '';
  }

  uploadCallBack(resData, fileToSend: any[], itemIndex: number, range: any, selectedAvailability: string) {
    if (resData.status === 'OK') {
      if (fileToSend && fileToSend.length > itemIndex) {
        this.listData.listUpload.fileList[itemIndex].selectedSOTOption = selectedAvailability;
        this.listData.listUpload.fileList[itemIndex].userSelectionId = resData.data.userSelectionId;
        this.listData.listUpload.fileList[itemIndex].userSelectionName = resData.data.userSelectionName;
        this.listData.listUpload.fileList[itemIndex].validCount = resData.data.validCount;
        this.listData.listUpload.fileList[itemIndex].totalCount = resData.data.totalCount;
        this.listData.fileNameLabel = this.initialConfig.userBundle[UPLOAD_FILE] || UPLOAD_FILE1;
      }
      // tslint:disable-next-line
      return this.sendUploadRequest(fileToSend, ++itemIndex, range, selectedAvailability);
    } else if (resData && resData.error && !resData.error.ok) {
      saveAs(resData.error.error.text, this.listData.listUpload.fileList[0].userSelectionName || 'validFile.xlsx');
      return resData.error.message;
    }
    return resData.message;
  }

  /**
   * @description Download selected file
   * @author Nishit Parekh, Amit Mahida
   * @param {*} userSelectionId - file user selection id to send to backend
   * @memberof MultiTargetModalComponent
   */
  downloadListFile(userSelectionId: string, fileName: string): void {
    // VJ: dt, 28/07/15, Reported by Brandon in flowdock, need to send singular value instead of an array
    // As discussed with Nishit, userSelectionId will be only 1 value for now
    const userSelectionIdParam = (userSelectionId && userSelectionId.length > 0) ? userSelectionId[0] : userSelectionId;
    this.multiTargetService.downloadFile(userSelectionIdParam).then((file) => {
      saveAs(file.body, fileName);
    });
  }

}
