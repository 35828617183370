<app-sb-modal-wrapper [title]="userBundle['search.exclusion.duplication']" [titleBackgroundColor]="brickBgColor"
  (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <div class="tabs_container horizontal modalduplication">
    <ul class="tabs-menu">
      <li class="current">
        <a href="javascript:void(0)" showtab="">
          <span class="text">{{userBundle['common.advertiserName']}}</span>
        </a>
      </li>
    </ul>
    <div class="tab">
      <div id="tab-5" class="tab-content" style="display: block">
        <!-- <app-lookup-view [lookupType]="3" [templateId]="1" [isSelectAll]="false" [placeholder]="userBundle['search.exclusion.duplication.addAdvertiser']"
          [(ngModel)]="selectedDuplicationAdvertisers" ngDefaultControl [lookupInfo]="lookupDataInfo" [minTextLength]="1">
        </app-lookup-view> -->
        <tag-input [(ngModel)]="selectedDuplicationAdvertisers" [identifyBy]="'advertiserId'"
          [displayBy]="'advertiserName'" [onlyFromAutocomplete]="true"
          [placeholder]="userBundle['search.exclusion.duplication.addAdvertiser']"
          [secondaryPlaceholder]="userBundle['search.exclusion.duplication.addAdvertiser']"
          [inputClass]="'form-control'" [maintainSearchText]="false"
          (onAdd)="addDuplicateAdvertiser()">
          <ng-template let-item="item" let-index="index">
          </ng-template>
          <tag-input-dropdown [showDropdownIfEmpty]="false" [identifyBy]="'advertiserId'" [displayBy]="'advertiserName'"
            [minimumTextLength]="0" [autocompleteObservable]="getAdvertisers" [visibleMenuAfterItemAdd]="false"
            [appendToBody]="false">
          </tag-input-dropdown>
        </tag-input>
        <ul class="summaryContainer">
          <li *ngFor="let item of selectedDuplicationAdvertisers; trackBy: trackByIndex; let i = index;">
            <label
              style="width:79% !important;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{item.advertiserName}}</label>
            <span class="right" style="cursor:pointer;" (click)="removeItemById(i)"><em class="fa fa-times"
                aria-hidden="true"></em></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</app-sb-modal-wrapper>
