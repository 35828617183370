import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-enable-disable-product',
  templateUrl: './enable-disable-product.component.html'
})
export class EnableDisableProductComponent implements OnInit {
  @Input() resolveObject;
  titleColor = 'rgb(88, 0, 46)';
  constructor() { }
  ngOnInit() {

  }
  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }
  onModalSaved(event) {
    event.activeModal.close('ok');
  }

}
