import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BookingDetailsService } from './booking-details.service';
import { ExcelCell, ExportToExcel, ExcelColumn } from '../../../core/utils/exportToExcel';
import { BookingDetailsParam } from '../../../models';
import { LocaleData } from '../../utils/LocaleData';
import { DataShareService } from '../../services';
import { AppNameEnum } from '../../enum';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';

declare let _;

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.css']
})
export class BookingDetailsComponent implements OnInit {

  /**
   * @description Initial config data
   * @type {any}
   * @memberof BookingDetailsComponent
   */
  @Input() initialConfig: any = {
    userBundle: {}
  };

  openSidebar = false;

  /**
   * @description Hold the booking details
   * @memberof BookingDetailsComponent
   */
  bookingDetails = [];

  /**
   * @description Maintains response data for campaign details
   * @memberof BookingDetailsComponent
   */
  masterBookingDetails = [];

  /**
   * @description Selected frame code to display in title
   * @memberof BookingDetailsComponent
   */
  selectedFrame = '';

  bookingFilterText = '';

  isVP = false;

  /**
   * @description Sets booking details req params
   * @type {object}
   * @memberof BookingDetailsComponent
   */
  @Input() public set bookingDetailsParams(value: BookingDetailsParam) {
    if (!_.isEmpty(value)) {
      this.selectedFrame = value.frameCode;
      this.masterBookingDetails = [];
      this.bookingFilterText = '';
      this.bookingDetailsService.getCampaignDetails(value.reqParam).subscribe((res: any) => {
        if (res.status === 'OK') {
          document.getElementById('dvbookingcnt').scrollTop = 0;
          this.masterBookingDetails = res.data || [];
          this.generateBookingDetails(this.masterBookingDetails);
          this.openSidebar = true;
        } else {
          this.openSidebar = false;
          console.log(res.message);
        }
      });
    } else {
      this.openSidebar = false;
    }
  }

  /**
   * @description Width of side bar
   * @type {number}
   * @memberof BookingDetailsComponent
   */
  bookingDetailsWidth = 350;

  /**
   * Creates an instance of BookingDetailsComponent.
   * @author Amit Mahida
   * @param {BookingDetailsService} bookingDetailsService
   * @memberof BookingDetailsComponent
   */
  constructor(
    private bookingDetailsService: BookingDetailsService,
    private datePipe: DatePipe,
    private dataShareService: DataShareService,
    private appHeaderService: AppHeaderService
  ) { }

  ngOnInit() {
    this.isVP = this.dataShareService.appName === AppNameEnum.visualplanner;
  }

  /**
   * @description Toggles side bar view
   * @author Amit Mahida
   * @memberof BookingDetailsComponent
   */
  toggleSideBar() {
    this.openSidebar = !this.openSidebar;
  }

  /**
   * @description Updates search result based on given campaign ref
   * @author Amit Mahida
   * @param {string} key
   * @memberof BookingDetailsComponent
   */
  searchForCampaign(key: string) {
    if (key.length > 0) {
      const filteredCampaigns = [];
      this.masterBookingDetails.forEach((element) => {
        if (element.campaignReference.indexOf(key.toUpperCase()) !== -1) {
          filteredCampaigns.push(element);
        }
      });
      this.generateBookingDetails(filteredCampaigns);
    } else {
      this.generateBookingDetails(this.masterBookingDetails);
    }
  }

  /**
   * @description Generates booking details to display
   * @author Amit Mahida
   * @param {any} masterBookingDetails
   * @memberof BookingDetailsComponent
   */
  generateBookingDetails(masterBookingDetails) {
    const tempBookingDetails = [];
    const groupedByStatus = _.groupBy(masterBookingDetails, 'campaignStatus');
    for (const index in groupedByStatus) {
      if (groupedByStatus.hasOwnProperty(index)) {
        const element = groupedByStatus[index];
        tempBookingDetails.push({
          data: element,
          campaignStatus: index,
          statusImage: this.getSingleStatus(index)
        });
      }
    }
    this.bookingDetails = tempBookingDetails;
  }

  /**
   * Get single status from string
   * @author Dhaval Patel
   * @param status string
   * @memberof BookingDetailsComponent
   */
  getSingleStatus(status): string {
    if (status && status.includes(',')) {
      const strArray = status.split(',');
      if (strArray && strArray.length) {
        return strArray[strArray.length - 1];
      }
    }
    return status;
  }

  /**
   * download cart data in excel format
   */
  exportToExcel() {
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    // Add Header Row
    const sheetHeader: ExcelCell[] = [];
    const exportCol = [
      {
        key: 'campaignReference',
        displayText: this.initialConfig.userBundle['common.campaignRef']
      },
      {
        key: 'campaignTitle',
        displayText: this.initialConfig.userBundle['common.campaignTitle']
      },
      {
        key: 'campaignStatus',
        displayText: this.initialConfig.userBundle['common.status']
      },
      {
        key: 'sot',
        displayText: this.initialConfig.userBundle['common.bookingDetails.bookedSot'] || 'SOT'
      },
      {
        key: 'advertiserName',
        displayText: this.initialConfig.userBundle['common.advertiserName']
      },
      {
        key: 'brandName',
        displayText: this.initialConfig.userBundle['common.brandName']
      },
      {
        key: 'startDate',
        displayText: this.initialConfig.userBundle['workspace.incharge.bric.range.startDate']
      },
      {
        key: 'endDate',
        displayText: this.initialConfig.userBundle['workspace.incharge.bric.range.endDate']
      },
      {
        key: 'agencyName',
        displayText: this.initialConfig.userBundle['common.agencyName']
      },
      {
        key: 'salesPersonName',
        displayText: this.initialConfig.userBundle['common.salesPersonName.us']
      },
      {
        key: 'adminPersonName',
        displayText: this.initialConfig.userBundle['common.adminPersonName']
      },
      {
        key: 'impressions',
        displayText: this.initialConfig.userBundle['common.bookingDetails.bookedImpression']
      },
      {
        key: 'price',
        displayText: this.initialConfig.userBundle['workspace.budget.bric.price.price']
      },
      {
        key: 'productCategoryName',
        displayText: this.initialConfig.userBundle['common.productCategoryName.us']
      }
    ];
    const colDefs = exportCol;
    for (const column of colDefs) {
      const cell: ExcelCell = {
        text: column.displayText,
        fontColor: '#FFFFFF',
        bgColor: '#000000',
        borderColor: '#CCCCCC',
        isFontBold: true
      };
      const col: ExcelColumn = {
        width: (column.key === 'advertiserName' ||
          column.key === 'productCategoryName' ||
          column.key === 'salesPersonName' ||
          column.key === 'adminPersonName') ? 14 : 12
      };
      excelColumn.push(col);
      sheetHeader.push(cell);
    }
    sheetData.push(sheetHeader);

    // // Add Data rows
    for (let i = 0; i < this.masterBookingDetails.length; i++) {
      const sheetRow: ExcelCell[] = this.getSheetRow(colDefs, i);
      sheetData.push(sheetRow);
    }
    const cartText = this.initialConfig.userBundle['commercial.tabTitle.bookingDetails'] || 'Bookings';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }

  getSheetRow(colDefs, i: number) {
    const sheetRow: ExcelCell[] = [];
    for (const column of colDefs) {
      let val = this.masterBookingDetails[i][column.key];
      if (column.key === 'startDate' || column.key === 'endDate') {
        val = this.formatDate(val);
      }
      const cell: ExcelCell = {
        text: val,
        fontColor: '#000000',
        bgColor: i % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
        borderColor: '#CCCCCC'
      };
      sheetRow.push(cell);
    }

    return sheetRow;
  }

  formatDate(date) {
    return this.datePipe.transform(date, LocaleData.displayDateFormat);
  }

  onSelectCampaign(campaignId: number) {
    if (campaignId !== undefined) {
      const market = this.appHeaderService.getCookie('market');
      const url = `${window.location.origin}/visualplanner?market=${market}&id=${campaignId}&action=visualplanner`;
      window.open(url, '_blank');
    }
  }

  trackByItem(index, item) {
    return item;
  }
}
