<div class="det-container">
    <div class="titlebar_geo" [ngStyle]="{'background-color':resolveObject.brickBgColor}"> {{resolveObject.title}} <button class="close_geo" (click)="onClose()">x</button></div>
    <div class="second-level-wrapper_geo">
        <!-- basic transclusion (single slot) -->
        <ng-content></ng-content>
    </div>
    <div class="footerbar_geo">
        <button class="btn_geo opac" (click)="onClose()">{{initialConfig.userBundle['common.cancel']}}</button>
        <button class="btn_geo white pull-right" (click)="onSave()">{{initialConfig.userBundle['common.ok']}}</button>
    </div>
</div>