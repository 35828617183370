export class SearchTabAccess {
  tabAccess = true;
  openRequestReadOnly = true;
  openRequest = true;
  openBookingReadOnly = true;
  openBooking = true;
  openSearch = true;
  campaignTypeAccess = false;
  updateLanguage = false;
}

export class WorkspaceTabAccess {
  tabAccess = true;
  downloadExport = false;
  pcmMode = false;
  downloadAdvanceAvailability = false;
  allocateAllFrames = false;
  secondaryAudienceWeight = false;
  reshuffleBooking = false;
  editNetworkAvailabilityFilter = false;
  editNetworkUnmatchedFilter = false;
}

export class ResultTabAccess {
  tabAccess = true;
  createProposal = false;
  saveSearch = false;
  requestConfirmation = false;
  requestOption = false;
}

export class ResultMapTabAccess {
  tabAccess = true;
}

export class CommericalTabAccess {
  tabAccess = false;
  cancelBooking = false;
  confirmedStatus = false;
  updateBooking = false;
  updateOptionBooking = false;
  updateConfirmBooking = false;
  optionStatus = false;
  dailyPaperBooking = false;
  changePending = false;
  dgLockAccess = false;
  allowHideImpressionCCPEdit = false;
  allowHideFinancialCCPEdit = false;
}

export class GeoPlannerTabAccess {
  tabAccess = true;
}

export class VisualPlannerTabAccess {
  tabAccess = true;
}

export class PcmTabAccess {
  tabAccess = false;
  changePending = false;
  dgLockAccess = false;
}

export class UserModel {
  searchTabAccess: SearchTabAccess = new SearchTabAccess();
  workspaceTabAccess: WorkspaceTabAccess = new WorkspaceTabAccess();
  resultTabAccess: ResultTabAccess = new ResultTabAccess();
  resultMapTabAccess: ResultMapTabAccess = new ResultMapTabAccess();
  commercialTabAccess: CommericalTabAccess = new CommericalTabAccess();
  geoPlannerTabAccess: GeoPlannerTabAccess = new GeoPlannerTabAccess();
  visualPlannerTabAccess: VisualPlannerTabAccess = new VisualPlannerTabAccess();
  pcmTabAccess: PcmTabAccess = new PcmTabAccess();
}
export class User {
  public user: UserModel = new UserModel();
  constructor() {
    //
  }
}
