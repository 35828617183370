import { Component, OnInit, Input } from '@angular/core';
import { DataShareService, DistanceConverterService, MaskService } from '../../../core/services';
import { GLOBAL } from '../../../core/utils/app.constant';
import * as _ from 'lodash';

@Component({
  selector: 'app-proximity-prohibition',
  templateUrl: './proximity-prohibition.component.html',
  styleUrls: ['./proximity-prohibition.component.css']
})
export class ProximityProhibitionComponent implements OnInit {

  public titleBackgroundColor: string = GLOBAL.DEFAULT_TITLE_BACKGROUND_COLOR;
  public userBundle: any;
  @Input() resolveObject: any;
  proximityProhibitionGroup: any = [];
  maskService: MaskService = new MaskService();

  constructor(
    private dataShareService: DataShareService,
    private distanceConverterService: DistanceConverterService
  ) {
    this.userBundle = dataShareService.getInitialConfigByKey('userBundle');
  }

  ngOnInit() {
    if (this.resolveObject.data !== null && this.resolveObject.data.length) {
      this.proximityProhibitionGroup = _.cloneDeep(this.resolveObject.data);

      for (const group of this.proximityProhibitionGroup) {
        for (const typeObj of group.proximityProhibitionType) {
          typeObj.distanceType = typeObj.distancetypedisplay;
          typeObj.distance = typeObj.distancevaluedisplay;
        }
      }
    } else {
      this.proximityProhibitionGroup = this.dataShareService.getInitialConfigByKey('proximityProhibitionGroup');
      this.proximityProhibitionGroup.forEach((element) => {
        element.proximityProhibitionType.forEach((inelement) => {
          inelement.distance = null;
          inelement.distanceType = 0;
          inelement.olddistanceType = 0;
        });
      });
    }
  }

  convertAllToMeters() {
    for (const group of this.proximityProhibitionGroup) {
      for (const typeObj of group.proximityProhibitionType) {
        if (Number(typeObj.distanceType) !== 0) {
          if (Number(typeObj.distanceType) === 1) {
            // km to meter
            const newdistance = this.distanceConverterService.fromKmToMeter(typeObj.distance);
            typeObj.distance = newdistance;
            typeObj.distanceType = 0;
          } else if (Number(typeObj.distanceType) === 2) {
            // miles to meter
            const newdistance = this.distanceConverterService.fromMilesToMeter(typeObj.distance);
            typeObj.distance = newdistance;
            typeObj.distanceType = 0;
          }
        }
      }
    }
  }

  onModalSaved(event) {
    for (const group of this.proximityProhibitionGroup) {
      for (const typeObj of group.proximityProhibitionType) {
        typeObj.distancetypedisplay = typeObj.distanceType;
        typeObj.distancevaluedisplay = typeObj.distance;
      }
    }
    this.convertAllToMeters();
    event.activeModal.close(this.proximityProhibitionGroup);
  }

  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }

  onChangeDistanceType = (ptype: any) => {
    const olddistanceValueType = parseInt(ptype.olddistanceType, 10);
    const newdistanceValueType = parseInt(ptype.distanceType, 10);
    ptype.distanceType = newdistanceValueType;
    if (ptype.distance != null && olddistanceValueType !== newdistanceValueType) {
      const currentDistance = ptype.distance;
      let newDistance = null;
      switch (newdistanceValueType) {
        case 0:
          // Convert to Meters
          if (olddistanceValueType === 1) {
            // Km to Meter
            newDistance = this.distanceConverterService.fromKmToMeter(currentDistance);
          } else if (olddistanceValueType === 2) {
            // Miles to Meter
            newDistance = this.distanceConverterService.fromMilesToMeter(currentDistance);
          } else {
            // Do Nothing
          }
          break;
        case 1:
          // Convert to Kilometer
          if (olddistanceValueType === 0) {
            // Meter to KM
            newDistance = this.distanceConverterService.fromMeterToKm(currentDistance);
          } else if (olddistanceValueType === 2) {
            // Miles to KM
            newDistance = this.distanceConverterService.fromMilesToKm(currentDistance);
          } else {
            // Do Nothing
          }
          break;
        case 2:
          // Convert to Miles
          if (olddistanceValueType === 0) {
            // Meter to Miles
            newDistance = this.distanceConverterService.fromMeterToMiles(currentDistance);
          } else if (olddistanceValueType === 1) {
            // KM to Miles
            newDistance = this.distanceConverterService.fromKmToMiles(currentDistance);
          } else {
            // Do Nothing
          }
          break;
        default:
          break;
      }
      // Note : Very Imp to maintain the old and new distance type of each control
      ptype.olddistanceType = ptype.distanceType;
      ptype.distance = newDistance || 0;

    } else {
      ptype.olddistanceType = parseInt(ptype.distanceType, 10);
    }
  }

  trackByProhibitionGroupName(index, groupname) {
    return groupname?.prohibitionGroupName;
  }

  trackByProhibitionTypeName(index, type) {
    return type?.prohibitionTypeName;
  }

}
