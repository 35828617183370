import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-custom-header',
  templateUrl: './ag-custom-header.component.html',
  styleUrls: ['./ag-custom-header.component.css']
})
export class AgCustomHeaderComponent implements IHeaderAngularComp {

  public params: any;
  public sortOrder = '';
  public sortIndex = 0;

  // called on init
  agInit(params: any): void {
    this.params = params;
  }

  menuIconClick(event) {
    this.hideMenu();
    event.stopImmediatePropagation();
    this.params.showColumnMenu(event.target);
  }

  isColumnSorted(): boolean {
    this.sortOrder = '';
    this.sortIndex = 0;
    if (this.params && this.params.context && this.params.context.componentParent && this.params.context.componentParent.sortObj) {
      const sortColumn = this.params.context.componentParent.sortObj.getSortColumn(this.params.column.colId);
      if (sortColumn) {
        this.sortOrder = sortColumn.order;
        if (!this.params.context.componentParent.sortObj.isSingleColumnSorting) {
          this.sortIndex = sortColumn.index + 1;
        }
      }
    }
    return this.sortOrder !== '';
  }

  headerClick() {
    let order = 'asc';
    if (this.sortOrder === 'asc') {
      order = 'desc';
    } else if (this.sortOrder === 'desc') {
      order = '';
    }
    this.params.context.componentParent.sortData(this.params.column.colId, order);
  }

  private hideMenu() {
    const menuEle: any = document.getElementsByClassName('ag-menu');
    for (const element of menuEle) {
      element.style.display = 'none';
    }
  }
}
