import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class RangeWindow {
  startDate: NgbDateStruct | Date;
  endDate: NgbDateStruct | Date;
  PCM = false;
  defaultValue = 0;
  defaultValueUnit = 0;
  minValue = 0;
  minValueUnit = 0;
  defaultWeekDay = -1;
}

export class DateRange {
  startDate: Date;
  endDate: Date;
}
