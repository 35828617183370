import { Component, OnInit, Input } from '@angular/core';
import {
  DataShareService,
  LogHelperService,
  DistanceConverterService,
  CellAttributeService,
  BrickBaseService,
  LoaderService
} from '../../../core/services/index';

import { CellValues } from '../../../models/workspace/index';
import { ProximityFilterService } from './../proximity-filter.service';
import { ProximityBaseDirective } from './../proximity-base';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaskService } from '../../../core/services/mask.service';
import { NumberMaskModel } from '../../../models/imask';

@Component({
  selector: 'app-proximity-modal',
  templateUrl: './proximity-modal.component.html',
  styleUrls: ['./proximity-modal.component.css'],
  providers: [ProximityFilterService]
})
export class ProximityModalComponent extends ProximityBaseDirective implements OnInit {
  @Input() resolveObject: CellValues;

  maskService: MaskService = new MaskService();

  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof ProximityModalComponent
   */
  currencyInputMask: NumberMaskModel;

  /**
   * @description Mask input config for Lat
   * @type {NumberMaskModel}
   * @memberof ProximityModalComponent
   */
  pointsLatInputMask: NumberMaskModel;

  /**
   * @description Mask input config for Lng
   * @type {NumberMaskModel}
   * @memberof ProximityModalComponent
   */
  pointsLngInputMask: NumberMaskModel;

  /**
   * @description once POI call is made, value of this flag will be set to false
   * @type {boolean}
   * @memberof ProximityModalComponent
   */
  poiCallnotMade: boolean;

  constructor(
    dataShareService: DataShareService,
    proximityService: ProximityFilterService,
    logHelperService: LogHelperService,
    distanceConverterService: DistanceConverterService,
    loaderService: LoaderService,
    // @ts-ignore
    private activeModal: NgbActiveModal,
    private cellAttributeService: CellAttributeService,
    private brickBaseService: BrickBaseService
  ) {
    super(dataShareService, proximityService, logHelperService, distanceConverterService, loaderService);
  }

  ngOnInit() {
    this.setResolveObject(this.resolveObject);
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.init(this.resolveObject.selectedValues);
    this.currencyInputMask = this.maskService.currencyInputMask();
    this.pointsLatInputMask = this.maskService.currencyInputMask({ scale: 8, min: -90, max: 90 });
    this.pointsLngInputMask = this.maskService.currencyInputMask({ scale: 8, min: -180, max: 180 });
  }

  /**
   * @description To set only poi values
   * @memberof ProximityFilterComponent
   * @author Amit Mahida
   */
  async poiServiceCall(): Promise<boolean | any> {
    return new Promise((resolve) => {
      this.proximityService.getProximityBrickResponseWithoutFile(this.proximityRequestObj(),
        this.proximity.pointOfInterest.userSelectionId).subscribe((data) => {
          if (data.status === 'OK') {
            this.setFileid(data.data, this.proximityTypeSelection.POI);
            this.setSelectionId();
            this.poiCallnotMade = false;
            const values: CellValues = new CellValues();
            this.closeComponent(this, values);
          } else {
            if (data.message) {
              this.logHelperService.logError(data.message);
            } else {
              this.logHelperService.logError(this.userBundle[this.CONNECTING]);
            }
          }
          resolve(true);
        });
    });
  }

  onModalClosed(event) {
    if (event.reason === 'escape' && (this.proximity.postcodeSelection.file || this.proximity.postcodeSelection.selectedModelValues.length > 0 || this.proximity.postcodeSelection.distance.distancevalue
      || this.proximity.points.file || this.proximity.points.selectedModelValues.length > 0 || this.proximity.points.distance.distancevalue
      || this.proximity.pointOfInterest.distance.distancevalue || this.proximity.pointOfInterest.selectedModelValues.length > 0)
    ) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  async poiPostCode(): Promise<boolean> {
    if (this.validationForPointOfInterest()) {

      this.setFileData();
      this.setPointsFileData();
      if (this.proximity.postcodeSelection.file || this.proximity.postcodeSelection.selectedModelValues.length > 0 || this.proximity.postcodeSelection.distance.distancevalue
        || this.proximity.points.file || this.proximity.points.selectedModelValues.length > 0 || this.proximity.points.distance.distancevalue
        || this.proximity.pointOfInterest.distance.distancevalue || this.proximity.pointOfInterest.selectedModelValues.length > 0
        || ((this.proximity.postcodeSelection.selectedModelValues.length > 0) && !this.proximity.postcodeSelection.listUploadSelection && this.proximity.postcodeSelection.distance.distancevalue)
      ) {
        await this.postCodeDataUpload(null, null, false);
        await this.poiServiceCall();
      } else if (!this.proximity.postcodeSelection.distance.distancevalue) {
        const msg = this.userBundle[this.DISTANCE_FOR_POSTCODE]
          || this.DISTANCE_FOR_POSTCODE_STR;
        this.logHelperService.logError(msg);
        return false;
      } else if (this.proximity.postcodeSelection.file || (this.proximity.postcodeSelection.selectedModelValues.length > 0)) {
        this.logHelperService.logError(this.userBundle[this.RADIO_SELECTION]);
        return false;
      }
    } else {
      return false;
    }
  }

  async poiFile(): Promise<boolean> {
    if (this.validationForPointOfInterest()) {

      if (this.proximity.points.file && this.proximity.points.listUploadSelection) {

        // points file upload
        await this.pointsFileUpload(null, null, false);
        await this.poiServiceCall();

      } else if (this.proximity.points.selectedModelValues.length && !this.proximity.points.listUploadSelection) {

        await this.pointsDataUpload(null, null, false);
        await this.poiServiceCall();

      } else if (!this.proximity.postcodeSelection.distance.distancevalue) {
        const msg = this.userBundle[this.DISTANCE_FOR_POSTCODE]
          || this.DISTANCE_FOR_POSTCODE_STR;
        this.logHelperService.logError(msg);
        return false;
      } else if (this.proximity.postcodeSelection.file || (this.proximity.postcodeSelection.listUploadSelection && this.proximity.postcodeSelection.selectedModelValues.length > 0)) {
        this.logHelperService.logError(this.userBundle[this.RADIO_SELECTION]);
        return false;
      }

    } else {
      return false;
    }
  }

  filePoi(): boolean {
    if (this.validationForPointOfInterest() && this.poiCallnotMade &&
      ((!this.proximity.postcodeSelection.file && !this.proximity.postcodeSelection.selectedModelValues.length &&
        !this.proximity.postcodeSelection.distance.distancevalue) || (!this.proximity.points.file && !this.proximity.points.selectedModelValues.length && !this.proximity.points.distance.distancevalue))) {
      this.poiServiceCall();
    } else {
      return false;
    }
  }

  postCodeSelection(): false | Promise<boolean> {
    // Any one radio button with value selected
    if ((this.proximity.postcodeSelection.file
      && this.proximity.postcodeSelection.listUploadSelection)
      || ((this.proximity.postcodeSelection.selectedModelValues.length > 0)
        && (!this.proximity.postcodeSelection.listUploadSelection))) {

      return this.poiPostCode();
    } else if ((this.proximity.postcodeSelection.file || this.proximity.postcodeSelection.selectedModelValues.length > 0) && !this.proximity.postcodeSelection.distance.distancevalue) {
      this.logHelperService.logError(this.userBundle[this.DISTANCE_FOR_POSTCODE]);
      return false;
    } else if (this.proximity.postcodeSelection.file || this.proximity.postcodeSelection.selectedModelValues.length > 0) {
      this.logHelperService.logError(this.userBundle[this.RADIO_SELECTION]);
      return false;
    }
  }

  async onModalSaved(event): Promise<boolean> {
    if (!this.validatePOITypeSelection()) {
      this.logHelperService.logError(this.userBundle['common.error.multiplePOITypes'] || 'Please select single POI data');
      return;
    }
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    this.poiCallnotMade = true;

    this.setFileData();
    this.setPointsFileData();

    if (this.proximity.postcodeSelection.file || this.proximity.postcodeSelection.selectedModelValues.length > 0 || this.proximity.postcodeSelection.distance.distancevalue
      || this.proximity.points.file || this.proximity.points.selectedModelValues.length > 0 || this.proximity.points.distance.distancevalue
      || this.proximity.pointOfInterest.distance.distancevalue || this.proximity.pointOfInterest.selectedModelValues.length > 0
    ) {

      // When poi distance or poi data is selected
      if ((this.proximity.pointOfInterest.selectedModelValues.length > 0) || this.proximity.pointOfInterest.distance.distancevalue) {

        this.postCodeSelection();

        // Any one radio button with value selected
        if ((this.proximity.points.file || this.proximity.points.selectedModelValues.length > 0) && this.proximity.points.distance.distancevalue) {
          return this.poiFile();
        }

        return this.filePoi();

      } else {

        this.filePoints(event, values);
      }
    } else {
      const msg = this.userBundle['common.error.selectAnyValue']
        || 'Please select valid values for Postcode or Point of interest or Points to proceed!';
      this.logHelperService.logError(msg);
    }
  }

  async filePoints(event, values: CellValues) {
    if (this.proximity.postcodeSelection.file || this.proximity.postcodeSelection.selectedModelValues.length || this.proximity.postcodeSelection.distance.distancevalue) {
      await this.postCodeTab(event, values);
    }

    if (this.proximity.points.file || this.proximity.points.selectedModelValues.length || this.proximity.points.distance.distancevalue) {
      this.pointsTab(event, values).then(() => {
        this.closeComponent(event, values);
      });
    } else {
      this.closeComponent(event, values);
    }
  }

  async postCodeTab(event, values: CellValues): Promise<any> {

    if (this.proximity.postcodeSelection.file && this.proximity.postcodeSelection.listUploadSelection
      && this.proximity.postcodeSelection.distance.distancevalue) {
      // When no POI data is selected, list upload radio button is selected and file is uploaded and distance is added
      await this.postCodeFileUpload(event, values, true);
    } else if ((this.proximity.postcodeSelection.selectedModelValues.length > 0) && !this.proximity.postcodeSelection.listUploadSelection
      && this.proximity.postcodeSelection.distance.distancevalue) {
      // When no POI data is selected, post code selected from lookup and postcode radio button selected and distance is added
      this.proximity.fileName = null;
      this.proximity.file = {};
      await this.postCodeDataUpload(event, values, true);
    } else if (!this.proximity.postcodeSelection.distance.distancevalue) {
      // When no POI data is selected, post code selected from lookup and postcode radio button selected and distance is not added
      const msg = this.userBundle[this.DISTANCE_FOR_POSTCODE]
        || this.DISTANCE_FOR_POSTCODE_STR;
      this.logHelperService.logError(msg);
      return new Promise(() => false);
    } else if (((this.proximity.postcodeSelection.selectedModelValues.length > 0)
      || this.proximity.postcodeSelection.file) && this.proximity.postcodeSelection.distance.distancevalue) {
      // When no POI data is selected, postcode radio button selected and file is uploaded(may be incorrect) and distance is added
      this.logHelperService.logError(this.userBundle[this.RADIO_SELECTION]);
      return new Promise(() => false);
    } else {
      // When no values selected for proximity
      const msg = this.userBundle['common.error.proximity.valueForPostcode']
        || 'Please provide values for postcodes to continue!';
      this.logHelperService.logError(msg);
      return new Promise(() => false);
    }
  }

  async pointsTab(event, values: CellValues): Promise<boolean | any> {
    if (this.proximity.points.file && this.proximity.points.listUploadSelection
      && this.proximity.points.distance.distancevalue) {
      // When no POI data is selected, list upload radio button is selected and file is uploaded and distance is added
      await this.pointsFileUpload(event, values, true);
    } else if ((this.proximity.points.selectedModelValues.length > 0)
      && !this.proximity.points.listUploadSelection
      && this.proximity.points.distance.distancevalue) {
      // When no POI data is selected, post code selected from lookup and postcode radio button selected and distance is added
      this.proximity.pointsFileName = null;
      this.proximity.pointsFile = {};
      await this.pointsDataUpload(event, values, true);
    } else if (!this.proximity.points.distance.distancevalue) {
      // When no POI data is selected, post code selected from lookup and postcode radio button selected and distance is not added
      const msg = this.userBundle['common.error.proximity.distanceForPoints']
        || 'Please enter distance for selected points!';
      this.logHelperService.logError(msg);
      return new Promise(() => false);
    } else if (((this.proximity.points.selectedModelValues.length > 0)
      || this.proximity.points.file) && this.proximity.points.distance.distancevalue) {
      // When no POI data is selected, postcode radio button selected and file is uploaded(may be incorrect) and distance is added
      this.logHelperService.logError(this.userBundle[this.RADIO_SELECTION]);
      return new Promise(() => false);
    } else {
      // When no values selected for proximity
      const msg = this.userBundle['common.error.proximity.valueForPoints']
        || 'Please provide values for points to continue!';
      this.logHelperService.logError(msg);
      return new Promise(() => false);
    }
  }

  /**
   * @description this will return array of user selecton ids for request json
   * @author Nishit Parekh
   * @returns {number[]}
   * @memberof ProximityModalComponent
   */
  populateSelectionIds(): number[] {
    let userSelectionIds = [];
    if (this.proximity.postcodeSelection.userSelectionId && this.proximity.pointOfInterest.userSelectionId) {
      userSelectionIds.push(this.proximity.postcodeSelection.userSelectionId);
      userSelectionIds.push(this.proximity.pointOfInterest.userSelectionId);
    } else {
      userSelectionIds = [this.proximity.postcodeSelection.userSelectionId || this.proximity.pointOfInterest.userSelectionId];
    }
    return userSelectionIds;
  }

  closeComponent(event, values: CellValues) {
    values.brick = this.resolveObject.brick;
    values.selectedValues = this.proximity;
    values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Proximity, values.selectedValues);
    values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Proximity, this.proximity);
    values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Proximity, values.selectedValues);
    event.activeModal.close(values);
  }
}
