import { Cell } from './cell';
import { CellValues } from './cell-values';
import { Brick } from '../../workspace/brick-model';
import { ExpandDirection } from './cell-position';
import { BrickBaseService } from '../../core/services/brick-base.service';
import { ProductHelper } from './product';
import { ColumnHelper } from './column-helper';

export class Row {
  public bric: Brick;
  public isValid: boolean;

  // If true, it will be displayed at UI, false will not show at UI
  public isHidden = false;

  public index: number;
  public cells: Cell[] = [];
  brickBaseService: BrickBaseService = new BrickBaseService();
  /**
   * @description Disable Cursor while drag over
   * @type {boolean}
   * @memberof Cell
   */
  public disableCursorHighlight = false;

  /**
   * @description Highlight row while drag over
   * @type {boolean}
   * @memberof Cell
   */
  public highlightRow = false;

  /**
   * @description Create new row at given index, leave it empty or -1 to create at last
   * @param {number} rowIndex
   * @param {CellValues} values
   * @param {ColumnHelper} columnHelper
   * @memberof Row
   */
  public createNewRow(rowIndex: number, values: CellValues, columnHelper: ColumnHelper, isObjectiveMode = false) {
    this.bric = values.brick;
    this.isHidden = false;
    this.isValid = true;
    this.index = rowIndex;
    this.pushNewCell(values);

    if (isObjectiveMode) {
      return;
    }

    this.cells.forEach((cell: Cell, index: number) => {
      cell.isLocked = Boolean(columnHelper[index]);
      cell.isEditable = true;
      if (columnHelper[index] && columnHelper[index].systemLocked) {
        cell.isEditable = false;
      }
    });
  }

  /**
   * @description Push new cell at given index
   * @param {CellValues} value
   * @param {number} [index=-1] Push cell at this index, -1 means at the end
   * @param {ExpandDirection} [expandDirection=ExpandDirection.None]
   * @memberof Row
   */
  public pushNewCell(value: CellValues, index = -1, expandDirection: ExpandDirection = ExpandDirection.None) {
    if ((index === -1 || !this.cells[index])) { // Insert at end
      // Check if empty cells available
      const lastEmptyIndex = this.lastEmptyCellIndex();
      if (lastEmptyIndex < this.cells.length && index === -1) {
        // We have empty cell already available, need to replace it
        this.cells[lastEmptyIndex].assignValues(this.index, value);
      } else {
        this.pushNewCellAndCreateCell(value);
      }
    } else { // Push at index
      // Its an empty visible cell
      if (this.cells[index] && this.cells[index].isEmpty && !this.cells[index].isHidden && expandDirection === ExpandDirection.None) {
        this.cells[index].assignValues(this.index, value);
      } else if (this.cells[index].isEmpty && this.cells[index].isHidden) {
        // Must be covered by previous stretchable brick
      } else {
        // Single non empty Cell
        // Create New Cell
        const cell: Cell = new Cell();
        cell.assignValues(this.index, value);
        cell.cellIndex = index;

        this.cells.splice(index, 0, cell);
      }
    }
  }

  pushNewCellAndCreateCell(value: CellValues) {
    if (value.brick.cellIndex > 0 && this.cells.length < value.brick.cellIndex) {
      for (let colIndex = 0; colIndex < value.brick.cellIndex; colIndex++) {
        // creates empty cells if the targeted drop index greater than available
        this.createEmptyCellAtIndex(this.index, colIndex, false);
      }
      // Create New Cell at targeted col index
      this.createNewCell(value, value.brick.cellIndex);
    } else {
      // Create New Cell
      this.createNewCell(value, this.cells.length);
    }
  }

  createNewCell(value: CellValues, index: number) {
    const cell: Cell = new Cell();
    cell.assignValues(this.index, value);
    cell.cellIndex = index;
    this.cells.push(cell);
  }

  /**
   * @description This method returns last empty cell index of current row
   * @returns {number}
   * @memberof Row
   */
  public lastEmptyCellIndex(): number {
    let index: number = this.cells.length;
    for (let cellIndex = this.cells.length - 1; cellIndex >= 0; cellIndex--) {
      if (this.cells[cellIndex].isEmpty && !this.cells[cellIndex].isHidden) {
        index = this.cells[cellIndex].cellIndex;
      } else {
        break;
      }
    }
    return index;
  }

  /**
   * @description Method to move a cell from source index to dest index and Push empty Cell from where Cell is moved if destination is not empty cell
   * @param {Cell} cell Source Cell
   * @param {number} destIndex Move to this index
   * @param {boolean} [replace=false]
   * @memberof Row
   */
  public moveCell(cell: Cell, destIndex: number, replace = false) {
    if (this.cells[cell.cellIndex].cellWidth > 1) {
      while (this.cells[cell.cellIndex].cellWidth > 1) {
        this.cells[cell.cellIndex + this.cells[cell.cellIndex].cellWidth - 1].isEmpty = true;
        this.cells[cell.cellIndex + this.cells[cell.cellIndex].cellWidth - 1].isHidden = false;
        this.cells[cell.cellIndex].cellWidth--;
      }
      cell.cellWidth = this.cells[cell.cellIndex].cellWidth;
    }

    if (replace && this.cells[destIndex]) {
      this.cells[destIndex].updateCellValues(cell);
      this.cells.splice(cell.cellIndex, 1);
      // Replace
    } else {
      // Pushing new brick
      const cellToMove = this.cells[cell.cellIndex];
      this.cells.splice(destIndex, 0, cellToMove);
      if (cell.cellIndex > destIndex) {
        this.cells.splice(cell.cellIndex + 1, 1);
      } else {
        this.cells.splice(cell.cellIndex, 1);
      }
    }

    // In below IF Else, Whenever a new brick is pushed in between source and dest index,
    // need to add 1 in Cell Index to create empty cell
    // In case of replace, we are not pushing any brick
    if (cell.cellIndex < destIndex || replace) { // Moving Left To Right
      this.createEmptyCellAtIndex(cell.rowIndex, cell.cellIndex, false);
    } else if (cell.cellIndex >= destIndex) { // Moving Right To Left
      this.createEmptyCellAtIndex(cell.rowIndex, cell.cellIndex + 1, false);
    }
  }

  /**
   * @author Vijay Sutaria
   * @description This method will be used to expand current Cell to X length from its current start index
   * Mainly this function will get called when
   * 1) bottom row has new brick pushed and need to stretch above rows (Similar to UpperRowUpdation)
   * 2) Brick is dropped on existing cell and need to auto stretch other row's column
   * @param {number} cellIndex Cell index which needs to expand, MUST BE non empty & non hidden cell index
   * @param {ExpandDirection} [expandDirection=ExpandDirection.None] This will have value when user will drop new brick on existing brick
   * @param {number} [expandCellCount=1] This parameter will have no of cells to increase in current cell if dropped on existing cell
   * @param {boolean} [leaveCellIndexExact=false] This parameter will allow this method to modify cellIndex based on logic if its false
   * or if dropped on workspace then will be used to calculate new width
   * @param {ProductHelper[]} [productDetails=[]]
   * @param {boolean[]}[lockButtons=[]] Status of locks applied on each column
   * @returns {number} number of steps cell expanded
   * @memberof Row
   */
  public expandCell(
    cellIndex: number,
    expandDirection: ExpandDirection = ExpandDirection.None,
    expandCellCount = 1,
    leaveCellIndexExact = false,
    productDetails: ProductHelper[] = []
  ): number {
    let widthChangeCount = 0;
    // Is brick dropped at last ?, if yes then last bricks of each row should be expanded
    // If brick dropped in between any existing bricks, then just need to expand that brick as well as
    // Need to re order next brick's cell index

    // VJ: 26-06-2019, When we drop any brick on top of existing one then format is expanding, otherwise not
    if (this.bric.bricid === this.brickBaseService.brickID.Format && expandDirection === ExpandDirection.None) {
      // If Format brick then no need to expand
      return;
    }

    const tmpCellIndex = this.gettempCellIndex(expandDirection, leaveCellIndexExact, cellIndex);
    const productAvailableAtCellIndex: boolean = productDetails && productDetails.filter(p => p.columnIndex <= tmpCellIndex && (p.columnIndex + p.productWidth) > tmpCellIndex).length > 0;
    if (this.bric.bricid !== this.brickBaseService.brickID.Incharge && this.cells[tmpCellIndex] && (this.cells[tmpCellIndex].isLocked || (!this.cells[tmpCellIndex].isEditable) || productAvailableAtCellIndex)) {
      // SM-3619 - Alkesh Shah
      // Added not editable condition to not allow expand of non-editable column
      return;
      /*else if (lockButtons && lockButtons.length > 0 && lockButtons.filter(f => f === true).length > 0) {
        // If CellIndex is before any locked column then no need to expand
        // CellIndex after last locked column can only expand
        for (let l = lockButtons.length - 1; l >= 0; l--) {
          if (lockButtons[l] && l >= tmpCellIndex) {
            return;
          }
        }
      }*/
    }

    const cellWidth = expandDirection === ExpandDirection.None ? cellIndex - tmpCellIndex + expandCellCount : this.cells[tmpCellIndex].cellWidth + expandCellCount;

    // No need to update width if its already higher or equal than calculated
    if (this.cells[tmpCellIndex].cellWidth >= cellWidth) { return 0; }
    widthChangeCount = cellWidth - this.cells[tmpCellIndex].cellWidth;
    this.cells[tmpCellIndex].cellWidth = cellWidth;

    this.expandCellOperation(expandDirection, tmpCellIndex);
    return widthChangeCount;
  }

  expandCellOperation(expandDirection: ExpandDirection, tmpCellIndex: number) {
    let blankCell: Cell;
    for (let i = tmpCellIndex + 1; i < tmpCellIndex + this.cells[tmpCellIndex].cellWidth; i++) {
      blankCell = new Cell();
      if (this.cells[i] && expandDirection === ExpandDirection.None) {
        blankCell = this.cells[i];
        blankCell.createEmptyCell(true);
        blankCell.cellIndex = i;
        blankCell.rowIndex = this.index;
      } else if (!this.cells[i]
        || (this.cells[i] && (!this.cells[i].isEmpty || this.getOverlappingCell(i) !== tmpCellIndex))) {
        // If next cell not available
        // If next cell is available and not empty
        // if next cell is avaialble but under another range
        blankCell.createEmptyCell(true);
        blankCell.cellIndex = i;
        blankCell.rowIndex = this.index;
        this.cells.splice(i, 0, blankCell);
      }
    }
  }

  gettempCellIndex(expandDirection: ExpandDirection, leaveCellIndexExact: boolean, cellIndex: number) {
    let tmpCellIndex = this.getOverlappingCell(expandDirection === ExpandDirection.Left || leaveCellIndexExact ? cellIndex : cellIndex - 1);
    if (tmpCellIndex === -1) {
      // added 2nd ternary condition to handle tmpCellIndex = -1, fix for issue 35, Nishit
      tmpCellIndex = expandDirection === ExpandDirection.Left || leaveCellIndexExact ? cellIndex : cellIndex === 0 ? 0 : cellIndex - 1;
      if (!this.cells[tmpCellIndex] || (this.cells[tmpCellIndex].isEmpty && !this.cells[tmpCellIndex].isHidden)) {
        tmpCellIndex = this.getLastNonEmptyCell(tmpCellIndex);
      }
    }
    return tmpCellIndex;
  }

  /**
   * @description Method to remove a cell
   * @param {number} [index=-1]
   * @memberof Row
   */
  public removeCell(index = -1) {
    this.cells.splice(index, 1);
  }

  /**
   * @description Creates empty cells till maxCellIndex in current row
   * @param {number} maxCellIndex Max Cell Index in each row
   * @param {ColumnHelper} ColumnHelper
   * @memberof Row
   */
  public createEmptyCells(maxCellIndex: number, columnHelper: ColumnHelper) {
    while (this.cells.length - 1 < maxCellIndex) {
      const cell = new Cell();
      // case when Audience Bric, as it is always stretched
      const isHidden = this.bric.bricid === this.brickBaseService.brickID.Audience || this.bric.bricid === this.brickBaseService.brickID.SecondaryAudience || this.bric.bricid === this.brickBaseService.brickID.PrimaryAudience;
      cell.createEmptyCell(isHidden, Boolean(columnHelper[this.cells.length]));
      this.cells.push(cell);
      cell.cellIndex = this.cells.length - 1;
    }
  }

  /**
   * @description Method to create an empty cell at desired index in current row
   * Mainly used for brick swapped, where source index should be replaced by empty cell
   * @param {number} rowIndex
   * @param {number} cellIndex CellIndex where empty cell to push
   * @param {boolean} [isHidden=true]
   * @param {boolean[]} columnHelper
   * @memberof Row
   */
  public createEmptyCellAtIndex(rowIndex: number, cellIndex: number, isHidden = true, columnHelper?: ColumnHelper) {
    const cell = new Cell();
    cell.createEmptyCell(isHidden, Boolean(columnHelper && columnHelper[cellIndex]));
    this.cells.splice(cellIndex, 0, cell);
    cell.cellIndex = cellIndex;
    cell.rowIndex = rowIndex;
  }

  /**
   * @description ReIndexCells
   * @param {number} [rowIndex=-1]
   * @memberof Row
   */
  public reIndexCells(rowIndex = -1) {
    let i = 0;
    this.cells.forEach((element) => {
      element.cellIndex = i++;
      element.rowIndex = rowIndex === -1 ? element.rowIndex : rowIndex;
    });
  }

  /**
   * @description create row with values - used mainly in case of load campaign
   * @author Alkesh Shah
   * @param {Brick} bricDetailsMaster
   * @param {number} rowIndex
   * @param {number} maxColumns
   * @param {*} curRowJsonData
   * @param {string} bricText
   * @memberof Row
   */
  createRowWithValues(bricDetailsMaster: Brick, rowIndex: number, maxColumns: number, curRowJsonData: any, bricText: string) {
    this.bric = bricDetailsMaster;
    this.index = rowIndex;
    this.isHidden = false;
    this.isValid = true;

    // creating max number of empty cells
    for (let i = 0; i <= maxColumns; i++) {
      const blankCell: Cell = new Cell();
      blankCell.createEmptyCell(false);
      blankCell.cellIndex = i;
      blankCell.rowIndex = rowIndex;
      this.cells.push(blankCell);
    }

    // fill valid cells
    for (const d of curRowJsonData) {
      const cellObj = this.cells[d.columns[0]];
      let cellValues: CellValues;
      if (bricText === 'selectionCriteriaFormat' || bricText === 'selectionCriteriaRestriction') {
        const requestJson = {
          ['selectionCriteriaFormat']: d.selectionCriteriaFormat,
          ['selectionCriteriaRestriction']: d.selectionCriteriaRestriction
        };
        cellValues = new CellValues(null, null, '', requestJson);
      } else {
        cellValues = new CellValues(null, null, '', { [bricText]: d[bricText] });
      }
      cellObj.assignValues(rowIndex, cellValues, d.columns.length);
      // stretch bric case
      for (let j = 1; j < d.columns.length; j++) {
        const cellObjNext = this.cells[d.columns[j]];
        cellObjNext.isHidden = true;
      }
    }
  }

  /**
   * @description Method to find out a cell which is overlapping current cell
   * @author Vijay Sutaria, Shivani Patel
   * @param {number} cellIndex  cell Index of cell which is overlapped by other cell
   * @returns {number} returns Cell Index of Cell which is overlapping current cell
   * @memberof Row
   */
  getOverlappingCell(cellIndex: number): number {
    // Return same index if cell is stretched from itself.
    if (this.cells[cellIndex] && this.cells[cellIndex].cellWidth > 1) {
      return cellIndex;
    }

    let index: number = cellIndex;
    while (this.cells[index] && this.cells[index].isEmpty && this.cells[index].isHidden) {
      index--;
    }
    // Return overlapping cell index if cell is stretched from other cell otherwise return -1.
    return index !== cellIndex ? index : -1;
  }

  /**
   * @description Used to find out last non-empty cell before the maximum cellIndex
   * @author Vijay Sutaria
   * @param {number} cellIndex
   * @returns {number}
   * @memberof Row
   */
  getLastNonEmptyCell(cellIndex: number): number {
    let index = cellIndex;

    while (!this.cells[index] || this.cells[index].isEmpty) {
      if (index <= 0) { return 0; }
      index--;
    }
    return index;
  }

  /**
   * @description Set isHidden true if isPastColsHidden is true and all cells under the row is hidden or empty
   * @author Amit Mahida, Shivani Patel
   * @param {boolean} [isPastColsHidden=false] If true then it ignores all past columns
   * @param {ColumnHelper} [columnHelper={}] If isPastColsHidden is true then we have to pass columnHelper
   * @memberof Row
   */
  setIsHidden(isPastColsHidden = false, columnHelper: ColumnHelper = {}) {
    if (isPastColsHidden) {
      const cells: Cell[] = this.cells.filter((cell: Cell) =>
        !columnHelper[cell.cellIndex] || (columnHelper[cell.cellIndex] && !columnHelper[cell.cellIndex].systemLocked)
      );
      this.isHidden = cells.filter((cell: Cell) => cell.isHidden || cell.isEmpty).length === cells.length;
    } else {
      this.isHidden = false;
    }
  }

  /**
   * @description Highlight on row SM-2697
   * @author Shivani Patel
   * @param {{ bric: Brick, cellIndex?: number }} draggedBricHighlight
   * @memberof Row
   */
  highlight(draggedBricHighlight: { bric: Brick, cellIndex?: number }) {
    if (!draggedBricHighlight) {
      return;
    }
    if (draggedBricHighlight.bric.bricid !== this.bric.bricid) {
      if (draggedBricHighlight.bric.isprimary && this.bric.isprimary) {
        this.highlightRow = true;
        this.disableCursorHighlight = false;
      } else {
        this.highlightRow = false;
        this.disableCursorHighlight = true;
      }
    }
  }

  /**
   * @description Removes hightlighting SM-2697
   * @author Shivani Patel
   * @memberof Row
   */
  removeHighlight() {
    this.highlightRow = false;
    this.disableCursorHighlight = false;

    // highlightCell of Cell is always true when we dragover any cell in different row. So, we need to keep it false when we leave that row.
    const highlightedCells: Cell[] = this.cells.filter(c => c.highlightCell);
    highlightedCells.forEach((cell: Cell) => {
      cell.highlightCell = false;
    });
  }
}
