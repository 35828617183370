
import { Component, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { MaskService } from '../../../../../core/services/mask.service';
import { NumberMaskModel, Unmask } from '../../../../../models/imask';

@Component({
  selector: 'app-ag-number-mask-cell-editor',
  templateUrl: './ag-number-mask-cell-editor.component.html',
  styleUrls: ['./ag-number-mask-cell-editor.component.css']
})
export class AgNumberMaskCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  /**
   * value of input
   */
  public value: number;

  maskService: MaskService = new MaskService();

  @ViewChild('inputTag', { read: ViewContainerRef }) public input;
  /**
   * imask config for Number mask
   */
  public imask: NumberMaskModel;
  /**
   * Defines the type of the masked value in ngModel
   */
  public unmask: Unmask = this.maskService.unmask.typed;
  /**
   * True if input is non editable.
   */
  public isDisabled = false;

  constructor() {}

// dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    if (this.params.column.isCellEditable(this.params.column)) {
      this.input.element.nativeElement.focus();
    }
  }

  agInit(params: any): void {
    this.params = params;

    // Overrides default Number mask
    if (this.params.imask) {
      this.imask = this.maskService.currencyInputMask(this.params.imask);
    } else {
      this.imask = this.maskService.currencyInputMask();
    }

    // Overrides default Unmask
    if (this.params.unmask) {
      this.unmask = this.params.unmask;
    }

    // Set current value from params
    if (typeof this.params.value === 'function') {
      this.value = this.params.value(this.params.node.data);
    } else {
      this.value = this.params.value;
    }

    // Set disabled property of input from params
    if (this.params.cellDisable) {
      this.isDisabled = this.params.cellDisable(this.params);
    }
  }

  /**
   * @description Return the final value - called by the grid once after editing is complete
   * @author Shivani Patel
   * @returns {*}
   * @memberof AgCurrenyMaskEditorComponent
   */
  getValue(): any {
    return this.params.value;
  }

  /**
   * @description Event fires when value has been changed
   * @author Shivani Patel
   * @memberof AgCurrenyMaskEditorComponent
   */
  onTextChange() {
    if (!this.params.column.isCellEditable(this.params.node)) {
      this.params.value = this.value;
      this.params.data[this.params.column.colId] = this.value;
    }
    if (this.params.onTextChange) {
     // setTimeout(() => {
      this.params.onTextChange(this.params);
   //   }, 500);
    }
  }

  /**
   * This method allows to navigate withing input using left and right key
   * Also track the backspace
   * @param event input event
   * @author Dhaval Patel
   */
  onKeyDown(event: any) {
    const key = event.which || event.keyCode;
    if (key === 37 || // left
      key === 39) { // right
      event.stopPropagation();
    }
  }
}
