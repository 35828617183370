import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-cell-renderer-base',
  template: '',
})
export class CellRendererBaseComponent implements ICellRendererAngularComp {
  public params: any;
  public displayText = '';
  public isEditable = false;
  public groupingField = '';

  // called on init
  agInit(params: any): void {
    this.params = params;
    this.setValues();
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params.value = params.value;
    this.setValues();
    return true;
  }

  private setValues(): void {
    this.groupingField = this.params.api.gridCore.gridOptions.groupingField;
    this.isEditable = false;
    if (this.params.cellEditable) {
      this.isEditable = this.params.cellEditable(this.params.data);
    }

    this.displayText = this.params.data[this.params.column.colId];
    if (this.params.node.rowPinned === 'bottom') {
      if (this.params.rowIndex === 0) {
        this.displayText = this.getFirstPinRowText();
      } else if (this.params.rowIndex === 1) {
        this.displayText = this.getSecondPinRowText();
      }
    } else if (this.params.data.isParent) {
      this.displayText = this.getParentRowText();
    } else {
      this.displayText = this.getChildRowText();
    }

    this.displayText = this.formatText(this.displayText);
  }

  public getParentRowText(): string {
    return '';
  }

  public getChildRowText(): string {
    return '';
  }

  public getFirstPinRowText(): string {
    return '';
  }

  public getSecondPinRowText(): string {
    return '';
  }

  public formatText(displayText): string {
    return displayText;
  }
}
