<app-sb-modal-wrapper [title]="audienceCategoryGroup.audienceCategoryGroupName"
    [titleBackgroundColor]="resolveObject.brick.color" [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)"
    (saved)="onModalSaved($event)">
    <div style="overflow-y: auto" [ngClass]="{'readOnly' : readOnlyModal}">
        <div *ngIf="options.DisplayFormat == 1">
            <div class="ratingblocks" *ngFor="let categoryType of audienceCategoryType; trackBy: trackByAudienceCategoryType; let i = index"
                [ngStyle]="setStyle()">

                <div class="left padRatings">
                    <img *ngIf="imageHtmlSources[categoryType.audienceCategoryTypeCode]" alt="image" src="{{imageHtmlSources[categoryType.audienceCategoryTypeCode]}}" />
                </div>
                <div class="labelsratingaudiance">
                    <ng-template ngFor let-category [ngForOf]="categoryType.audienceCategory" [ngForTrackBy]="trackByAudienceCategory" let-j="index">
                        <div id="audiance-{{i}}-{{j}}">
                            <span class="text"></span>
                            <input type="radio" class="radio small"
                                [checked]="category.audienceCategoryId==selectedAudience.audienceCategoryId"
                                (change)="selectAudience(category,categoryType)" name="radio-tags"
                                id="radio-{{i}}-{{j}}">
                            <label class="radio-label " for="radio-{{i}}-{{j}}">
                                {{category.audienceCategoryName}}</label>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div *ngIf="options.DisplayFormat == 2">
            <div *ngFor="let categoryType of audienceCategoryType; trackBy: trackByAudienceCategoryType; let i = index" class="col-md-12">
                <table *ngIf="categoryType.audienceCategoryTypeCode == options.PrimaryAudienceType" aria-label="Categories"
                    class="category-table hoverTable">

                    <tr *ngFor="let category of categoryType.audienceCategory; trackBy: trackByAudienceCategory; let j = index"
                        (click)="categoryRow(j, category, categoryType)"
                        [ngClass]="{'highlightRow': selectedIndex === j}">
                        <td *ngIf="j != 0" class="radio-width">
                            <input class="m-audiance-class" type="radio"
                                [checked]="category.audienceCategoryId==selectedAudience.audienceCategoryId"
                                id="radio-{{i}}-{{j}}" name="response2" (change)="audianceRadioclick(j,$event)" />
                        </td>
                        <td *ngIf="j === 0" class="radio-width">
                            &nbsp;
                        </td>
                        <td *ngFor="let columns of category.columns; trackBy: trackByItem; let cell = index" [ngClass]="{'grid-header':j===0}"
                            [ngStyle]="{'background':j === 0?resolveObject.brick.color:''}">{{columns}}
                        </td>
                    </tr>
                </table>
                <div class="web-category-main row"
                    *ngIf="!(categoryType.audienceCategoryTypeCode == options.PrimaryAudienceType)">
                    <div class="col-md-1 webcatecategoryinput">
                        <input class="m-audiance-class" type="radio" id="radio-{{i}}" (click)="webCategorySelection(i)"
                            name="response2" [checked]="selectedIndexTagInput === i" />
                    </div>
                    <div class="col-md-5 webcatecategory">
                        <input id="typeahead-focus" type="text" class="form-control" [ngModel]="valueText[i]"
                            [ngbTypeahead]="searchAudienceCategory(categoryType.audienceCategory, i)"
                            [placeholder]="categoryType.audienceCategoryTypeName" [placement]="'top-left'"
                            (focus)="onFocus($event,i)" (click)="onClick($event,i)" (blur)="onTextChange($event, i)"
                            [resultFormatter]="searchAudienceCategoryFormatter"
                            (selectItem)="onItemAdded($event, i,'description')" #instance="ngbTypeahead" />
                        </div>
                    <br />
                </div>
            </div>
        </div>

        <div *ngIf="options.DisplayFormat ==3">
            <div *ngFor="let categoryType of audienceCategoryType[0].audienceCategory; trackBy: trackByAudienceCategory; let i = index" class="col-md-12">
                <div class="row">
                    <div class="col-md-1 webcatecategoryinput">
                        <input class="m-audiance-class" type="radio" id="radio-{{i}}" (click)="webCategorySelection(i)"
                            name="response2" [checked]="selectedIndexTagInput === i" />
                    </div>
                    <div class="col-md-6 webcatecategory">
                        <input id="typeahead-focus" type="text" class="form-control" [ngModel]="valueText[i]"
                            [ngbTypeahead]="searchAudienceCategory(categoryType['audienceCategory'], i)"
                            [placeholder]="categoryType.audienceCategoryName" [placement]="'top-left'"
                            (focus)="onFocus($event,i)" (click)="onClick($event,i)" (blur)="onTextChange($event, i)"
                            [resultFormatter]="searchAudienceCategoryFormatter"
                            (selectItem)="onItemAdded($event, i,'audienceCategoryName')" #instance="ngbTypeahead" />
                    </div>
                    <br />
                </div>
            </div>
        </div>
    </div>
</app-sb-modal-wrapper>
