import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DataShareService, LoaderService } from '../../services';
import { TreeViewComponent } from '../../../core/components/tree-view/tree-view.component';
import * as _ from 'lodash';
import { MapSvgIcon } from '../../../geo-map/mapSvgIcon';
import { TemplateProps } from '../../../models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { mathRandomNumber } from '../../utils/mathRandom';
declare let BMap;
declare let window;

@Component({
  selector: 'app-baidu-map',
  templateUrl: './baidu-map.component.html',
  styleUrls: ['./baidu-map.component.css']
})
export class BaiduMapComponent implements OnInit {
  @Input() userBundle: object = {};
  iconImages: any[] = [];
  node: any;
  openedModal: any;
  hex = 'hex';
  state: any;
  private privateMarkers = [];
  @Input() public set markers(value: any[]) {
    this.privateMarkers = value;
    this.createMarkerBM(this.baiduMapInstance);
  }
  public get markers(): any[] {
    return this.privateMarkers;
  }
  @ViewChild('content') modalContent;
  // For Showing POI markers on the map
  @ViewChild(TreeViewComponent) set SetTreeViewComponent(value: TreeViewComponent) {
    if (value) {
      this.treeViewComponent = value;
      this.loaderService.show();
      setTimeout(() => {
        this.loadNodes().then(() => {
          this.loaderService.hide();
        }, () => {
          this.loaderService.hide();
        });
      }, 1000);
    }
  }

  treeViewComponent: TreeViewComponent;

  showPOIs = true;
  imagePath = 'images/svg/';
  activeAccordion = false;
  iconShapes = ['circle', 'diamond', 'heart', 'plus', 'polygons', 'triangle'];
  @Input() proximityData: any;
  tempProximityData: any = [];
  poiData: any[] = [];
  mapSvgIcon: MapSvgIcon;
  dynamicHeight = '300px';
  baiduMapInstance: any;
  accordianToggleStyle: any = {};
  templateProps: TemplateProps = {
    displayId: 'key',
    displayName: 'displayText',
    displayRadioButton: false,
    searchPlaceHolder: '',
    showId: false,
    allowCustomCheckBoxEvents: true,
    localSolverEnabled: false,
    checkboxIconEnabled: true
  };
  treeOptions = {
    idField: 'key',
    displayField: 'displayText',
    childrenField: 'points',
    useCheckbox: false,
    useVirtualScroll: true,
    nodeHeight: 34,
    scrollContainer: document.body.parentElement
  };

  constructor(private dataShareService: DataShareService, private modalService: NgbModal, private loaderService: LoaderService) {
    this.mapSvgIcon = new MapSvgIcon();
  }

  ngOnInit() {
    const userData = this.dataShareService.getInitialConfigByKey('userData');
    if (BMap) {
      const map = new BMap.Map('baiduMap'); // create map instance
      this.baiduMapInstance = map;
      const point = new BMap.Point(userData.latitude, userData.longitude); // central point
      map.centerAndZoom(point, userData.zoomLevel); // initialize map,set the central point and map level。
      map.enableScrollWheelZoom(true); // allow scroll to re-size the map
      // add controllers to the map
      const ctrlNav = new BMap.NavigationControl({
        anchor: window.BMAP_ANCHOR_TOP_LEFT,
        type: window.BMAP_NAVIGATION_CONTROL_LARGE
      });
      map.addControl(ctrlNav);

      // add thumbnail controller
      const ctrlOve = new BMap.OverviewMapControl({
        anchor: window.BMAP_ANCHOR_BOTTOM_RIGHT,
        isOpen: 1
      });
      map.addControl(ctrlOve);
      // add scale controller
      const ctrlSca = new BMap.ScaleControl({
        anchor: window.BMAP_ANCHOR_BOTTOM_LEFT
      });
      map.addControl(ctrlSca);
      this.onResize();
      map.clearOverlays();

      this.loaderService.show();
      this.createMarkerBM(map).then(() => {
        Promise.all(this.getAllIconImagesSource()).then(() => {
          this.createPOIMarkers();
          setTimeout(async () => {
            this.fitBoundsBaiduMap(map);
            this.loaderService.hide();
          }, 1000);
        });
      });
    }

  }

  loadNodes = async () => {
    return new Promise((resolve, reject) => {
      if (this.treeViewComponent) {
        for (let i = 0; i < this.tempProximityData.length; i++) {
          if (this.treeViewComponent.tree.treeModel.nodes[i].key) {
            const id = this.treeViewComponent.tree.treeModel.nodes[i].key;
            const node = this.treeViewComponent.tree.treeModel.getNodeById(id);
            if (node) {
              node.setIsSelected(this.treeViewComponent.tree.treeModel.nodes[i].selected);
            }
          }
        }
        if (this.treeViewComponent.tree.treeModel) {
          this.treeViewComponent.tree.treeModel.expandAll();
        }
      } else {
        reject(false);
      }

      resolve(true);
    });
  }

  fitBoundsBaiduMap(map) {
    const markers = [];
    for (const marker of this.markers) {
      markers.push(new BMap.Point(marker.longitude, marker.latitude));
    }

    for (const poiData of this.poiData) {
      markers.push(new BMap.Point(poiData.longitude, poiData.latitude));
    }

    map.setViewport(markers);
  }

  /**
 * @description This method is to replace nested level keys for object passed
 * eg. In array, we need to replace 'categories' to `points` to properly bind the data with angular-tree-component for proximity data on map
 * @author Sagar Vaishnav
 * @date 2021-11-25
 * @param {any} obj
 * @param {string} key
 * @param {string} newKey
 * @memberof MapComponent
 */
  replaceKeysInObj(obj: any, key: string, newKey: string) {
    const newObj = {};
    for (const ogKey in obj) {
      if (ogKey === key) {
        newObj[newKey] = obj[ogKey];
      } else if (typeof obj[ogKey] === 'object') {
        newObj[ogKey] = this.replaceKeysInObj(obj[ogKey], key, newKey);
      } else {
        newObj[ogKey] = obj[ogKey];
      }
    }
    return newObj;
  }

  getIconShape(): string {
    const chosenShape = this.iconShapes[Math.round((mathRandomNumber() % this.iconShapes.length))];
    if (chosenShape === 'plus') {
      return this.getIconShape();
    }
    return chosenShape;
  }

  async createMarkerBM(map) {
    if (map) {
      map.clearOverlays();
      const bmMarkers = [];
      for (const value of this.markers) {
        const icon = new BMap.Icon(value.icon, new BMap.Size(49, 60));
        const point = new BMap.Point(value.longitude, value.latitude); // create point object
        const marker = new BMap.Marker(point, {
          icon
        });
        bmMarkers.push(marker);
        await new Promise(resolve => resolve(setTimeout(() => {
          map.addOverlay(marker);
        }, 10)));
      }
    }
  }

  async createPOIMarkers() {
    if (this.proximityData) {
      const bmMarkers = [];
      if (this.proximityData.pointsOfInterest) {
        let tempPOIData = this.proximityData.pointsOfInterest.categories;
        const tempCate = this.replaceKeysInObj(tempPOIData, 'subcategories', 'points');
        tempPOIData = _.values(tempCate);
        const tempBrands = this.replaceKeysInObj(tempPOIData, 'brands', 'points');
        tempPOIData = _.values(tempBrands);
        tempPOIData.forEach((poi: any, index: number) => {
          poi.selected = true;
          const shape = this.getIconShape();
          const randomColor = Math.floor(mathRandomNumber() * 16777215).toString(16);
          poi.shape = shape;
          poi.colorCode = `#${randomColor}`;
          poi.mixedColored = false;
          tempPOIData[index] = poi;
          poi.points = _.values(poi.points);
          poi.points.forEach((subCate) => {
            subCate.shape = poi.shape;
            subCate.colorCode = poi.colorCode;
            subCate.mixedColored = false;
            subCate.selected = poi.selected;
            subCate.points = _.values(subCate.points);
            subCate.points.forEach((subBrand) => {
              subBrand.shape = poi.shape;
              subBrand.colorCode = poi.colorCode;
              subBrand.selected = poi.selected;
              const tmpsubBrand: any = this.replaceKeysInObj(subBrand, 'points', 'points1');
              subBrand.points1 = _.values(tmpsubBrand.points1);
              delete subBrand.points;
              subBrand.points1.forEach((point, index) => {
                point.selected = true;
                point.shape = poi.shape;
                point.colorCode = `#${randomColor}`;
                point.type = subBrand.key;
                this.poiData.push(point);
                subBrand.points1[index] = point;
              });
            });
          });
          this.tempProximityData.push(poi);
        });
      }
      if (this.proximityData.points) {
        const objPoints: any = {};
        objPoints.key = 'points';
        objPoints.displayText = 'Points Data';
        objPoints.selected = true;
        objPoints.shape = 'plus';
        const randomColor = Math.floor(mathRandomNumber() * 16777215).toString(16);
        objPoints.colorCode = `#${randomColor}`;
        const manualData = this.proximityData.points.manual;
        const fileData = this.proximityData.points.file;
        objPoints.points1 = [];
        if (manualData) {
          manualData.forEach((singleData: any) => {
            const tmpsingleData: any = this.replaceKeysInObj(singleData, 'points', 'points1');
            singleData.points1 = _.values(tmpsingleData.points1);
            delete singleData.points;
            singleData.points1.forEach((poi: any, index: number) => {
              poi.selected = true;
              poi.shape = objPoints.shape;
              poi.colorCode = `#${randomColor}`;
              poi.type = poi.key;
              poi.type = objPoints.key;
              poi.displayText = `point${index}`;
              objPoints.points1.push(poi);
              this.poiData.push(poi);
            });
          });
        }
        let startIndex = objPoints.points1.length;
        if (fileData) {
          fileData.forEach((file: any) => {
            const fileData: any = this.replaceKeysInObj(file, 'points', 'points1');
            file.points1 = _.values(fileData.points1);
            delete file.points;
            file.points1.forEach((poi: any) => {
              poi.selected = true;
              poi.shape = objPoints.shape;
              poi.colorCode = `#${randomColor}`;
              poi.type = objPoints.key;
              poi.displayText = `point${startIndex}`;
              objPoints.points1.push(poi);
              this.poiData.push(poi);
              startIndex++;
            });
          });
        }
        this.tempProximityData.push(objPoints);
      }
      for (const value of this.poiData) {
        value.icon = `${this.imagePath + value.shape}.svg`;
        const imageVal = _.find(this.iconImages, (imgVal) => { return imgVal.shape === value.shape; });
        if (!imageVal || imageVal.length === 0) {
          continue;
        }
        const svgStr = this.mapSvgIcon.replaceColors(imageVal.img, value.colorCode);
        const doc = this.mapSvgIcon.parseSVG(svgStr);
        const serializedSVG = new XMLSerializer().serializeToString((doc));
        const icoImg = `data:image/svg+xml;base64, ${window.btoa(serializedSVG)}`;
        const icon = new BMap.Icon(icoImg, new BMap.Size(20, 20));
        const point = new BMap.Point(value.longitude, value.latitude); // create point object
        const marker = new BMap.Marker(point, { icon });
        const circle = new BMap.Circle(point, value.radius, { fillColor: '#000', strokeWeight: 0.5, fillOpacity: 0.3, strokeOpacity: 0.3, enableEditing: false });
        bmMarkers.push(marker);
        await new Promise(resolve => resolve(setTimeout(() => {
          this.baiduMapInstance.addOverlay(marker);
          this.baiduMapInstance.addOverlay(circle);
        }, 10)));
      }
    }
  }

  getAllIconImagesSource() {
    const promises = [];
    for (const iconShape of this.iconShapes) {
      const icon = `${this.imagePath + iconShape}.svg`;
      const promise = this.mapSvgIcon.urlToString(icon).then((svgStr) => {
        const imageVal = { shape: iconShape, img: svgStr };
        this.iconImages.push(imageVal);
      });
      promises.push(promise);
    }
    return promises;
  }

  onResize() {
    this.dynamicHeight = `${screen.width * 0.445}px`;
  }

  onAccordionClick() {
    this.activeAccordion = !this.activeAccordion;
    if (this.activeAccordion) {
      this.accordianToggleStyle = { width: '20%', position: 'absolute', left: '15px', top: '10px' };
    } else {
      this.accordianToggleStyle = { width: '20%', position: 'absolute', left: '15px', top: '760px' };
    }
  }

  async toggleAllPOIs() {
    this.showPOIs = !this.showPOIs;
    for (const poiData of this.poiData) {
      poiData.selected = this.showPOIs;
    }
    for (let i = 0; i < this.tempProximityData.length; i++) {
      if (this.treeViewComponent.tree.treeModel.nodes[i].key) {
        this.treeViewComponent.tree.treeModel.nodes[i].selected = this.showPOIs;
        const id = this.treeViewComponent.tree.treeModel.nodes[i].key;
        const node = this.treeViewComponent.tree.treeModel.getNodeById(id);
        if (node) {
          this.updateRecursiveNodeValue(node, 'selected');
        }
      }
    }
    this.loaderService.show();
    this.loadNodes().then(() => {
      this.loaderService.hide();
    }, () => {
      this.loaderService.hide();
    });
    this.baiduMapInstance.clearOverlays();
    await this.createMarkerBM(this.baiduMapInstance).then(() => {
      this.updateMapPOIMarkers().then(() => {
        this.loaderService.hide();
      });
    });
  }

  async updateMapPOIMarkers() {
    // Update overlays to hide/show markers on the map
    const bmMarkers = [];
    for (const value of this.poiData) {
      value.icon = `${this.imagePath + value.shape}.svg`;
      const imageVal = _.find(this.iconImages, (imgVal) => { return imgVal.shape === value.shape; });
      if (!imageVal || imageVal.length === 0) {
        continue;
      }
      const svgStr = this.mapSvgIcon.replaceColors(imageVal.img, value.colorCode);
      const doc = this.mapSvgIcon.parseSVG(svgStr);
      const serializedSVG = new XMLSerializer().serializeToString((doc));
      const icoImg = `data:image/svg+xml;base64, ${window.btoa(serializedSVG)}`;
      const icon = new BMap.Icon(icoImg, new BMap.Size(20, 20));
      const point = new BMap.Point(value.longitude, value.latitude); // create point object
      const marker = new BMap.Marker(point, { icon });
      const circle = new BMap.Circle(point, value.radius, { fillColor: '#000', strokeWeight: 0.5, fillOpacity: 0.3, strokeOpacity: 0.3, enableEditing: false });
      if (value.selected) {
        bmMarkers.push(marker);
        await new Promise(resolve => resolve(setTimeout(() => {
          this.baiduMapInstance.addOverlay(marker);
          this.baiduMapInstance.addOverlay(circle);
        }, 10)));
      }
    }
  }

  async onSelectDeSelectNode(selectEvent: any) {

    const node = this.treeViewComponent.tree.treeModel.getNodeById(selectEvent.node.data.key);
    const prevValue = node.data.selected;
    if (selectEvent.eventName === 'select') {
      node.data.selected = true;
      if (node.data.points1) {
        node.data.points1.forEach((element) => {
          element.selected = true;
        });
      }
    } else if (selectEvent.eventName === 'deselect') {
      node.data.selected = false;
      if (node.data.points1) {
        node.data.points1.forEach((element) => {
          element.selected = false;
        });
      }
    }
    this.updateRecursiveNodeValue(node, 'selected');

    // VJ: 13-01-2021
    // This method executes initially when we are loading markers along with marking all items of treeview as selected
    // So initially when we are loding treeview, we dont need to re render markers as its already rendered
    // When user will manually change selected value below condition will be true and so it will rerender markers
    if (prevValue !== node.data.selected) {
      this.loaderService.show();
      this.baiduMapInstance.clearOverlays();
      await this.createMarkerBM(this.baiduMapInstance).then(() => {
        this.updateMapPOIMarkers().then(() => {
          this.loaderService.hide();
        });
      });
    }
  }

  async updateRecursiveNodeValue(node, propertyName) {
    return new Promise((resolve) => {
      if (node.hasChildren) {
        node.children.forEach((subcategory) => {
          subcategory.data[propertyName] = node.data[propertyName];
          if (subcategory.hasChildren) {
            subcategory.data[propertyName] = node.data[propertyName];
            subcategory.children.forEach((brand) => {
              if (brand.data.points1) {
                brand.data[propertyName] = node.data[propertyName];
                const points = brand.data.points1;
                points.forEach((point) => {
                  point[propertyName] = node.data[propertyName];
                  this.poiData.forEach((poi) => {
                    if (poi.key === node.data.key) {
                      poi[propertyName] = node.data[propertyName];
                    }
                  });
                });
              }
            });
          } else {
            if (subcategory.data.points1) {
              const points1 = subcategory.data.points1;
              points1.forEach((point) => {
                point[propertyName] = node.data[propertyName];
                this.poiData.forEach((poi) => {
                  if (poi.key === node.data.key || poi.key === point.key) {
                    poi[propertyName] = node.data[propertyName];
                  }
                });
              });
            }
          }
        });
      } else {
        if (node.data.points1) {
          const points2 = node.data.points1;
          points2.forEach((element) => {
            element[propertyName] = node.data[propertyName];
            this.poiData.forEach((poi) => {
              if (poi.key === node.data.key) {
                poi[propertyName] = node.data[propertyName];
              }
            });
          });
        }
      }
      resolve(true);
    });
  }

  assignPropertyName(item, propertyName, data) {
    item[propertyName] = data[propertyName];
  }

  updateMixedColoredIcon(node: any) {
    const parentNode = this.treeViewComponent.getParentNode(node.data.key);
    if (parentNode) {
      if (node.hasChildren) {
        node.children.forEach((subCate) => {
          if (subCate.data.colorCode !== node.data.colorCode) {
            node.data.mixedColored = true;
            const parentNode = this.treeViewComponent.getParentNode(subCate.data.key);
            if (parentNode) {
              parentNode.data.mixedColored = true;
            }
          } else {
            node.data.mixedColored = false;
          }
          if (subCate.hasChildren) {
            subCate.children.forEach((brand) => {
              if (!brand.hasChildren) {
                if (brand.data.colorCode !== subCate.data.colorCode) {
                  subCate.data.mixedColored = true;
                  const parentNode = this.treeViewComponent.getParentNode(subCate.data.key);
                  if (parentNode) {
                    parentNode.data.mixedColored = true;
                  }
                } else {
                  const parentNode = this.treeViewComponent.getParentNode(subCate.data.key);
                  if (parentNode) {
                    parentNode.data.mixedColored = false;
                  }
                }
              }
            });
          } else {
            const parentNode = this.treeViewComponent.getParentNode(node.data.key);
            if (parentNode) {
              parentNode.data.mixedColored = true;
            }
          }
        });
      } else {
        const parentNode = this.treeViewComponent.getParentNode(node.data.key);
        if (parentNode) {
          if (parentNode.data.colorCode !== node.data.colorCode) {
            parentNode.data.mixedColored = true;
            const subParentNode = this.treeViewComponent.getParentNode(parentNode.data.key);
            if (subParentNode) {
              subParentNode.data.mixedColored = true;
            } else {
              parentNode.data.mixedColored = false;
            }
          }
        }
      }
    } else {
      node.data.mixedColored = false;
      if (node.hasChildren) {
        node.children.forEach((subCate) => {
          subCate.data.mixedColored = false;
          if (subCate.hasChildren) {
            subCate.children.forEach((brand) => {
              brand.data.mixedColored = false;
            });
          }
        });
      }
    }
  }

  onColorChangeNode(node: any) {
    this.updateRecursiveNodeValue(node, 'colorCode');
    this.updateMixedColoredIcon(node);
    this.baiduMapInstance.clearOverlays();
    this.loaderService.show();
    this.createMarkerBM(this.baiduMapInstance).then(() => {
      this.updateMapPOIMarkers().then(() => {
        this.loaderService.hide();
      });
    });
  }

  onShapeChange(node: any) {
    this.updateRecursiveNodeValue(node, 'shape');
    this.baiduMapInstance.clearOverlays();
    this.loaderService.show();
    this.createMarkerBM(this.baiduMapInstance).then(() => {
      this.updateMapPOIMarkers().then(() => {
        this.loaderService.hide();
      });
    });
  }

  open(content) {
    this.modalService.open(content);
  }

  getNodeValue(node) {
    this.node = node;
    this.openedModal = this.modalService.open(this.modalContent, { size: 'sm' });
  }

  updateIconShape(node, shape): void {
    node.data.shape = shape;
    this.onShapeChange(node);
  }

  updateColorCode(node): void {
    this.onColorChangeNode(node);
  }

  close() {
    this.openedModal.close();
  }

}
