import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BrickBaseService, DataShareService, StateService } from '../core/services';
import { DndDragImageOffsetFunction } from './../../../../../scripts/3rdParty/ngx-drag-drop-custom';
import {
  RangeComponent,
  MediaComponent,
  TagModalComponent,
  FormatModalComponent,
  LocationModalComponent,
  PricingModalComponent,
  FramequalityModalComponent,
  ProximityModalComponent,
  FrameComponent,
  BudgetModalComponent,
  DistributionModalComponent,
  AudienceModalComponent,
  VolumeModalComponent,
  SotModalComponent,
  ChannelComponent,
  ProductCatalogueModalComponent,
  ListModalComponent,
  PatternModalComponent,
  EnvironmentModalComponent,
  PricingTagModalComponent,
  NetworkModalComponent,
  SotPCMModalComponent,
  AllAudienceComponent,
  PrimaryAudienceModalComponent,
  MultiTargetModalComponent,
  MultiTargetPcmModalComponent
} from './modal-windows/index';
import { Brick } from './brick-model';
import { Subscription } from 'rxjs';
import { ColumnSummaryTooltip } from '../models/workspace/column-summary-tooltip';
import { SystemData, UiControl, UserModel, Filter, SystemFlags, Cell, ColumnHelper, CampaignParameters } from '../models';
import { ExpandDirection } from '../models/workspace/cell-position';
import * as _ from 'lodash';
import { GoogleAnalyticsEvents, GoogleAnalyticsEventCategory } from '../GoogleAnalytics/GoogleAnalyticsEvent';
import { AppHeaderService } from '../../../../root/app-header/app-header.service';
import { Category } from '../models/bricsMasterData';
import { GLOBAL } from '../core/utils/app.constant';

export class WorkspaceBase {

  /**
   * @description disables panning while stretching and dragging existing bric
   * @memberof WorkspaceBase
   */
  disablePanning = false;

  /**
    * @description Holds tooltip data for columns summary
    * @type {ColumnSummaryTooltip[]}
    * @memberof WorkspaceComponent
    */
  columnSummarys: ColumnSummaryTooltip[] = [];

  /**
    * @description System data properties
    * @type {SystemData}
    * @memberof WorkspaceComponent
    */
  systemData: SystemData;

  CELLWIDTH = GLOBAL.CELLWIDTH;
  CELLHEIGHT = GLOBAL.CELLHEIGHT;
  TOLERANCE_STATUSES = GLOBAL.TOLERANCE_STATUSES;

  /**
   * @description UI control object
   * @type {UiControl}
   * @memberof WorkspaceComponent
   */
  uiControl: UiControl;

  /**
   * @description Holds labels for i18n
   * @type {object}
   * @memberof WorkspaceComponent
   */
  userBundle: object;

  /**
   * @description Property for brick image type
   * @type {number}
   * @memberof WorkspaceComponent
   */
  alternateDesign: number;

  /**
   *store user model value
    *
   * @type {UserDataModel}
   * @memberof WorkspaceComponent
   */
  public user: UserModel;
  public masterData: Category[];

  filter: Filter;
  /**
   * @description - on workspace load - initial state of filter
   * needed to check if filter is changed or not in workspace screen
   * @type {Filter}
   * @memberof WorkspaceComponent
   */
  backupFilter: Filter;
  isResizing = false;

  /**
   * @description Variable to toggle explode mode
   * @type {boolean}
   * @memberof WorkspaceComponent
   */
  allowExplode = false;

  /**
   * @description Variable to on or off split mode
   * @type {boolean}
   * @memberof WorkspaceComponent
   */
  allowSplit = false;

  /**
   * Flag to enable/disable Split ICON
   *
   * @type {boolean}
   * @memberof WorkspaceComponent
   */
  splitable = false;

  readOnlyModal: boolean = SystemFlags.readOnly;

  expandDirection: ExpandDirection = ExpandDirection.None;

  draggableMaster = {
    effectAllowed: 'copy',
    disable: false,
    handle: true
  };

  draggableRow = {
    effectAllowed: 'move',
    disable: false,
    handle: true
  };

  removeDropZone = ['row', 'cell'];

  showRemoveBar = false;

  allowCustomDelete = false;
  readOnlyWorkspace = false;

  /**
   * @description Helps in start/stop panning
   * @memberof WorkspaceComponent
   */
  // startPanning = false;

  /**
   * @description To overcome manage row width
   * @memberof WorkspaceComponent
   */
  rowWidthDelta = 192;
  /**
   * @description Subscription for columns summary
   * @type {Subscription}
   * @memberof WorkspaceComponent
   */
  columnSummarySubscription: Subscription;

  /**
   * Used to track dragged Bric for Highlighter. SM-2697
   */
  draggedBricHighlight: {
    bric: Brick,
    cellIndex?: number
  };

  /**
   * @description Used to highlight the workspace with dashed line when we dragover
   * @type {boolean}
   * @memberof WorkspaceBase
   */
  highlightDivPanning = false;

  /**
   * @description Used to prevent the workspace to highlight when we dragover on the Ghost bric
   * @type {boolean}
   * @memberof WorkspaceBase
   */
  highlightGhost = false;

  constructor(
    public brickBaseService: BrickBaseService,
    public dataShareService: DataShareService,
    public stateService: StateService,
    public appHeaderService: AppHeaderService,
  ) {
    if (this.appHeaderService.enabledPCM) {
      this.filter = stateService.getPCMFilterObj();
      this.filter.objectType = this.filter.objectType == "" ? "pcm" : this.filter.objectType;
    } else if (this.appHeaderService.objectiveMode) {
      this.backupFilter = this.stateService.getWorkspaceFilterObj();
      this.filter = stateService.getObjectiveFilterObj();
      this.filter.objectType = this.filter.objectType == "" ? "objective" : this.filter.objectType;
    } else {
      this.filter = this.stateService.getWorkspaceFilterObj();
      this.filter.objectType = this.filter.objectType == "" ? "workspace" : this.filter.objectType;
      if (SystemFlags.incorrectSolution) {
        this.filter.markWorkspaceInvalid();
      }
    }
  }

  onInit() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.user = this.dataShareService.getUserModel();
    this.alternateDesign = this.dataShareService.getInitialConfigByKey('alternateDesign');
    this.splitable = SystemFlags.splitable;
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.systemData = this.dataShareService.getInitialConfigByKey('systemData');
    this.masterData = _.cloneDeep(this.dataShareService.getBricsMasterData().category);
    if (this.filter.rows && this.filter.rows.length) {
      if ((SystemFlags.forcefullyCallProcessBrics || SystemFlags.processSolutionAfterObjectiveSave) && !SystemFlags.readOnly) {
        SystemFlags.forcefullyCallProcessBrics = false;
        SystemFlags.processSolutionAfterObjectiveSave = false;
        this.filter.generateBrickRequestJSON();
        if (this.appHeaderService.enabledPCM) {
          this.filter.filterProductBuild();
        } else {
          this.filter.processBrics();
        }
      }

      if (GLOBAL.localSolverEnabled && !SystemFlags.readOnlyWorkspace && !this.appHeaderService.objectiveMode) {
        this.filter.populateReshufflingColumns(false, -1);
      }
    }
  }

  onDragEnd() {
    document.getElementsByTagName('body')[0].style.cursor = 'default';
    this.onDndEnd();
  }

  /**
 * @description Called when Bric dragged from sidebar. SM-2697
 * @author Shivani Patel, Amit Mahida
 * @param {Brick} brick
 * @memberof WorkspaceComponent
 */
  onMasterBricDragStart(bric: Brick) {
    document.getElementsByTagName('body')[0].style.cursor = 'all-scroll !important';
    this.draggedBricHighlight = {
      bric
    };
  }

  /**
   * @description get component's modal option to use in modal component based on bric id
   * @author Alkesh Shah
   * @param {number} bricId
   * @returns {NgbModalOptions}
   * @memberof WorkspaceComponent
   */
  getComponentModalOptions(bricId: number): NgbModalOptions {
    let modalOptions: NgbModalOptions;
    switch (bricId) {
      case this.brickBaseService.brickID.Incharge:
        if (this.appHeaderService.enabledPCM) {
          modalOptions = {
            windowClass: 'modal-backdrop'
          };
        } else {
          modalOptions = {
            windowClass: 'modal-backdrop Incharge'
          };
        }

        break;
      case this.brickBaseService.brickID.Media:
        modalOptions = {
          windowClass: 'modal-backdrop Media'
        };
        break;
      case this.brickBaseService.brickID.Environment:
      case this.brickBaseService.brickID.Channel:
        modalOptions = {
          windowClass: 'modal-backdrop Channel'
        };
        break;
      case this.brickBaseService.brickID.Frame:
        modalOptions = {
          windowClass: 'modal-backdrop Frame'
        };
        break;
      case this.brickBaseService.brickID.Format:
        modalOptions = {
          windowClass: 'modal-backdrop Format'
        };
        break;
      case this.brickBaseService.brickID.Location:
        modalOptions = {
          windowClass: 'modal-backdrop Location-window size-md'
        };
        break;
      case this.brickBaseService.brickID.Proximity:
        modalOptions = {
          windowClass: 'modal-backdrop proximityBrick'
        };
        break;
      case this.brickBaseService.brickID.List:
        modalOptions = {
          windowClass: 'modal-backdrop ListModal-window size-md',
        };
        break;
      case this.brickBaseService.brickID.Tag:
        modalOptions = {
          windowClass: 'modal-backdrop Tag',
        };
        break;
      case this.brickBaseService.brickID.Pattern:
        modalOptions = {
          windowClass: 'modal-backdrop Pattern'
        };
        modalOptions.size = this.appHeaderService.enabledPCM ? 'sm' : 'lg';
        break;
      case this.brickBaseService.brickID.Audience:
      case this.brickBaseService.brickID.SecondaryAudience:
        modalOptions = {
          windowClass: 'modal-backdrop Audience RatingModal-window size-md'
        };
        break;
      case this.brickBaseService.brickID.Pricing:
      case this.brickBaseService.brickID.FrameQuality:
        modalOptions = {
          windowClass: 'modal-backdrop BudgetModal-window'
        };
        break;
      case this.brickBaseService.brickID.Volume:
        modalOptions = {
          windowClass: 'modal-backdrop Volume'
        };
        break;
      case this.brickBaseService.brickID.PricingTag:
        modalOptions = {
          windowClass: 'modal-backdrop PricingTag size-lg',
        };
        break;
      case this.brickBaseService.brickID.SOT:
        if (this.appHeaderService.enabledPCM) {
          modalOptions = {
            windowClass: 'modal-backdrop Time-PCM'
          };
        } else {
          modalOptions = {
            windowClass: 'modal-backdrop Time'
          };
        }
        break;
      case this.brickBaseService.brickID.Network:
        modalOptions = {
          windowClass: 'modal-backdrop NetworkModal-window'
        };
        break;
      case this.brickBaseService.brickID.AllAudience:
      case this.brickBaseService.brickID.PrimaryAudience:
        modalOptions = {
          size: 'lg',
        };
        break;
      case this.brickBaseService.brickID.Distribution:
        modalOptions = {
          windowClass: 'modal-backdrop size-md'
        };
        break;
      case this.brickBaseService.brickID.MultiTarget:
        modalOptions = {
          size: 'sm'
        };
        break;
      case this.brickBaseService.brickID.Budget:
        modalOptions = {
          windowClass: 'modal-backdrop Budget'
        };
        break;
      default:
        break;
    }
    return modalOptions;
  }
  /**
   * @description get component to use in modal component based on bric id
   * @author Alkesh Shah
   * @param {number} bricId
   * @returns {*}
   * @memberof WorkspaceComponent
   */
  getComponent(bricId: number): any {

    switch (bricId) {
      case this.brickBaseService.brickID.Incharge:
        return RangeComponent;
      case this.brickBaseService.brickID.Media:
        return MediaComponent;
      case this.brickBaseService.brickID.Environment:
        return EnvironmentModalComponent;
      case this.brickBaseService.brickID.Format:
        return FormatModalComponent;
      case this.brickBaseService.brickID.Location:
        return LocationModalComponent;
      case this.brickBaseService.brickID.Proximity:
        return ProximityModalComponent;
      case this.brickBaseService.brickID.Frame:
        return FrameComponent;
      case this.brickBaseService.brickID.List:
        return ListModalComponent;
      case this.brickBaseService.brickID.Budget:
        return BudgetModalComponent;
      case this.brickBaseService.brickID.Tag:
        return TagModalComponent;
      case this.brickBaseService.brickID.Distribution:
        return DistributionModalComponent;
      case this.brickBaseService.brickID.Audience:
        return AudienceModalComponent;
      case this.brickBaseService.brickID.SecondaryAudience:
        return AudienceModalComponent;
      case this.brickBaseService.brickID.Channel:
        return ChannelComponent;
      case this.brickBaseService.brickID.Pricing:
        return PricingModalComponent;
      case this.brickBaseService.brickID.FrameQuality:
        return FramequalityModalComponent;
      case this.brickBaseService.brickID.Volume:
        return VolumeModalComponent;
      case this.brickBaseService.brickID.Pattern:
        return PatternModalComponent;
      case this.brickBaseService.brickID.PricingTag:
        return PricingTagModalComponent;
      case this.brickBaseService.brickID.SOT:
        if (this.appHeaderService.enabledPCM) {
          return SotPCMModalComponent;
        } else {
          return SotModalComponent;
        }
      case this.brickBaseService.brickID.ProductCatalogue:
        return ProductCatalogueModalComponent;
      case this.brickBaseService.brickID.Network:
        return NetworkModalComponent;
      case this.brickBaseService.brickID.AllAudience:
        return AllAudienceComponent;
      case this.brickBaseService.brickID.PrimaryAudience:
        return PrimaryAudienceModalComponent;
      case this.brickBaseService.brickID.Objective:
        return null;
      case this.brickBaseService.brickID.MultiTarget:
        return this.appHeaderService.enabledPCM ? MultiTargetPcmModalComponent : MultiTargetModalComponent;
      default:
        throw new Error('Unsupported Brick');
    }
  }

  /**
   * @description Set ReadOnly Mode for workspace and bricks
   * @author Kishan Patel
   * @param {boolean} [disable]
   * @returns {boolean}
   * @memberof WorkspaceComponent
   */
  isModalPopupReadonly(disable?: boolean): boolean {
    return SystemFlags.readOnly
      || SystemFlags.readOnlyWorkspace
      || disable;
  }

  /**
   * @description Called when existing Bric of workspace is dragged. SM-2697
   * @author Shivani Patel
   * @param {Brick} brick
   * @param {Cell} cell
   * @memberof WorkspaceComponent
   */
  onBricDragStart(bric: Brick, cellIndex: number) {
    this.draggedBricHighlight = {
      bric,
      cellIndex
    };
    this.showRemoveBar = true;
    this.disablePanning = true;
  }

  /**
   * @description Hide Remove bar and Remove Highlighters
   * @author Shivani Patel
   * @memberof WorkspaceBase
   */
  onDndEnd() {
    this.showRemoveBar = false;
    this.filter.removeHighlighters();
    this.highlightDivPanning = false;
    this.disablePanning = false;
    this.draggedBricHighlight = undefined;
  }

  /**
   * @description Used to highlight the workspace with dashed line when we dragover
   * @author Shivani Patel
   * @memberof WorkspaceBase
   */
  highlightWorkspace() {
    if (this.highlightGhost) {
      this.highlightDivPanning = false;
      return;
    }

    // If only row is highlighted
    let highlightedRow = this.filter.rows.filter(r => r.highlightRow || r.disableCursorHighlight);
    if (highlightedRow.length) {
      this.highlightDivPanning = false;
      return;
    }

    // If any cell is highlighted
    highlightedRow = this.filter.rows.filter(r => r.cells.filter(c => c.highlightLeft || c.highlightRight || c.highlightCell).length);
    if (highlightedRow.length) {
      this.highlightDivPanning = false;
    } else {
      this.highlightDivPanning = true;
    }
  }

  getCellColor(color, isHidden) {
    return isHidden ? '' : color;
  }

  /**
  * @description To clear workspace area
  * @author Shivani Patel
  * @memberof WorkspaceComponent
  */
  clearWorkspace(historyRequired = true) {
    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
    this.filter.clearWorkspace(
      columnHelper,
      historyRequired
    );
  }

  /**
   * @description handles campaigns parameter change event
   * @author Amit Mahida
   * @param {CampaignParameters} newCampaignParameters
   * @memberof WorkspaceComponent
   */
  campaignParametersChange(newCampaignParameters: CampaignParameters) {
    if (JSON.stringify(this.filter.campaignParameters) !== JSON.stringify(newCampaignParameters)) {
      this.filter.campaignParameters = newCampaignParameters;
      this.filter.processBrics();
    }
  }

  /**
  * @description Checks if only non editable bricks left
  * @author Amit Mahida
  * @returns
  * @memberof WorkspaceComponent
  */
  checkIfOnlyPastColsLeftOnWorkspace() {
    let onlyPastCols = false;
    const totalColumns = this.filter.getMaxCellIndex() + 1;
    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
    const keys = Object.keys(columnHelper);
    let totalLockedCount = 0;
    keys.forEach((k) => {
      if (columnHelper[k].systemLocked) {
        totalLockedCount++;
      }
    });

    onlyPastCols = totalLockedCount === totalColumns;

    return onlyPastCols;
  }

  /**
    * @description Applies drag effect on bricks
    * @author Amit Mahida
    * @type {DndDragImageOffsetFunction}
    * @memberof WorkspaceComponent
    */
  calDragImageOffset: DndDragImageOffsetFunction = (event: DragEvent, dragImage: HTMLElement) => {
    const dragImageComputedStyle = window.getComputedStyle(dragImage);
    const width = parseFloat(dragImageComputedStyle.width);
    const height = parseFloat(dragImageComputedStyle.height);
    const paddingTop = parseFloat(dragImageComputedStyle.paddingTop) || 15;
    const paddingLeft = parseFloat(dragImageComputedStyle.paddingLeft) || 0;
    const borderTop = parseFloat(dragImageComputedStyle.borderTopWidth) || 0;
    const borderLeft: any = parseFloat(dragImageComputedStyle.borderLeftWidth) || 0 + (event.clientX - event.clientX);
    return {
      x: paddingLeft + borderLeft + (width / 2),
      y: paddingTop + borderTop + (height / 4)
    };
  }

  /**
  * @description Sets expand direction on draging over left or right of an existing cell
  * @author Amit Mahida
  * @param {Cell} cell
  * @memberof WorkspaceComponent
  */
  setExpandDirection(cell: Cell) {
    if (cell.highlightLeft) {
      this.expandDirection = ExpandDirection.Left;
    } else if (cell.highlightRight) {
      this.expandDirection = ExpandDirection.Right;
    } else {
      this.expandDirection = ExpandDirection.None;
    }
  }

  sendGAEvent(draggedBricId) {
    let BRICNAME = null;
    switch (draggedBricId) {
      case this.brickBaseService.brickID.Incharge:
        BRICNAME = 'InchargeBrick';
        break;
      case this.brickBaseService.brickID.Media:
        BRICNAME = 'MediaBrick';
        break;
      case this.brickBaseService.brickID.Environment:
        BRICNAME = 'ChannelBrick';
        break;
      case this.brickBaseService.brickID.Format:
        BRICNAME = 'FormatBrick';
        break;
      case this.brickBaseService.brickID.Location:
        BRICNAME = 'LocationBrick';
        break;
      case this.brickBaseService.brickID.Proximity:
        BRICNAME = 'ProximityBrick';
        break;
      case this.brickBaseService.brickID.Frame:
        BRICNAME = 'FramesBrick';
        break;
      case this.brickBaseService.brickID.List:
        BRICNAME = 'ListBrick';
        break;
      case this.brickBaseService.brickID.Budget:
        BRICNAME = 'BudgetBrick';
        break;
      case this.brickBaseService.brickID.Tag:
        BRICNAME = 'TagsBrick';
        break;
      case this.brickBaseService.brickID.Distribution:
        BRICNAME = 'DistributionBrick';
        break;
      case this.brickBaseService.brickID.Audience:
        BRICNAME = 'RatingBrick';
        break;
      case this.brickBaseService.brickID.SecondaryAudience:
        BRICNAME = 'SecondaryAudienceBrick';
        break;
      case this.brickBaseService.brickID.Channel:
        BRICNAME = 'EnvironmentBrick';
        break;
      case this.brickBaseService.brickID.Pricing:
        BRICNAME = 'PricingBrick';
        break;
      case this.brickBaseService.brickID.FrameQuality:
        return FramequalityModalComponent;
      case this.brickBaseService.brickID.Volume:
        BRICNAME = 'VolumeBrick';
        break;
      case this.brickBaseService.brickID.Pattern:
        BRICNAME = 'PatternBrick';
        break;
      case this.brickBaseService.brickID.PricingTag:
        BRICNAME = 'PricingTagBrick';
        break;
      case this.brickBaseService.brickID.SOT:
        BRICNAME = 'ShareBrick';
        break;
      case this.brickBaseService.brickID.ProductCatalogue:
        BRICNAME = 'ProductBrick';
        break;
      case this.brickBaseService.brickID.Network:
        BRICNAME = 'NetworkBrick';
        break;
      case this.brickBaseService.brickID.PrimaryAudience:
        BRICNAME = 'Route Audience';
        break;
      default:
        BRICNAME = null;
    }
    if (BRICNAME) {
      GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Brics,
        BRICNAME, 'Create Campaign');
    }

  }

  /**
  * @description Event , triggered from resizable directive when expanding ends
  * @author Vijay Sutaria
  * @param {*} event : parameters sent from resizable directive
  * @param {*} cell  cell which is stretched
  * @memberof WorkspaceComponent
  */
  onCellExpanded(event, cell) {
    this.isResizing = false;
    const brickId = this.filter.rows[cell.rowIndex].bric.bricid;
    if (this.uiControl.allowReductionBrickSpan === false && !this.appHeaderService.enabledPCM && !this.appHeaderService.objectiveMode && 
      (brickId === this.brickBaseService.brickID.Volume || brickId === this.brickBaseService.brickID.Frame || brickId === this.brickBaseService.brickID.Budget)) {
      this.disablePanning = false;
      return;
    }
    if (GLOBAL.localSolverEnabled
      && this.uiControl.allowReductionBrickSpan === false
      && this.filter.isReductionBric(this.filter.rows[cell.rowIndex].bric.bricid)) {
      this.disablePanning = false;
      return;
    }

    if (event.stepsDifference > 0) {
      const isCellWidthChanged = this.filter.cellWidthChanged(cell, event.stepsDifference);
      if (isCellWidthChanged) {
        this.filter.postCellUpdates(true);
      }
    }

    this.disablePanning = false;
  }

  onCellExpanding() {
    this.isResizing = true;
  }

  onCellExpandBegin() {
    this.isResizing = true;
    this.disablePanning = true;
  }

  /**
  * @description To check that forcefully process bric call is required or not. If yes then it will call
  * @author Dhaval Patel
  * @memberof WorkspaceComponent
  */
  forcefullyCallProcessBrics() {
    if (SystemFlags.forcefullyCallProcessBrics && !SystemFlags.readOnly) {
      SystemFlags.forcefullyCallProcessBrics = false;
      this.filter.generateBrickRequestJSON();
      this.filter.processBrics();
    }
  }

  trackByCellIndex(index, cell) {
    return cell?.cellIndex
  }

  trackByCategoryId(index, data) {
    return data?.categoryid;
  }

  trackByBricId(index, bric) {
    return bric?.bricid;
  }

  trackByRowIndex(index, row) {
    return row?.index;
  }

  trackByItem(index, item) {
    return item;
  }

  trackByIndex(index, item) {
    return index;
  }

}
