import { Injectable } from '@angular/core';
import { Advertiser, InitialConfigModel, SbSelectedSpecialist, SbSelectedAgency, SbSelectedAdvertiser, SbSelectedBrand, SystemFlags } from '../../models';
import { DateFormatPipe } from '../../core/pipes/date-format.pipe';
import { CampaignStatus, ThirdParty, CampaignType, EnvironmentId } from '../../core/enum';
import { StateService } from '../../core/services/state.service';
import { CampaignDetails, HistoryROC, BookingLine } from '../commercial.model';
import { DataShareService, CommercialService } from '../../core/services';
import * as _ from 'lodash';
import { ProductCategoryMain } from '../../models/initial-config.model';
import { DecimalPipe } from '@angular/common';
@Injectable()
export class CampaignDetailsService {
  dateFormatePipe = new DateFormatPipe();
  private specialistDefaultPercentage = null;
  private agencyDefaultPercentage = null;

  advertisersList: SbSelectedAdvertiser[] = [];
  productCategoryList: ProductCategoryMain[] = [];
  brandsList: SbSelectedBrand[] = [];
  agenciesList: SbSelectedAgency[] = [];
  specialistsList: SbSelectedSpecialist[] = [];

  constructor(
    public stateService: StateService,
    public dataShareService: DataShareService,
    public commercialService: CommercialService,
    private decimalPipe: DecimalPipe,
  ) { }
  populateCampaignTitle(advertizer: Advertiser, userInitials: any) {
    const customDate = this.dateFormatePipe.transform(new Date(), 'yyMMdd');
    if (advertizer && advertizer.advertiserName) {
      return `${customDate}_${userInitials}_${advertizer.advertiserName}`;
    } else { return `${customDate}_${userInitials}`; }
  }

  populateSpecialistPercentage(campaignDetails: CampaignDetails) {
    if (this.specialistDefaultPercentage !== null && campaignDetails.specialist && campaignDetails.specialist.specialistId > 0) {
      if (campaignDetails.specialistPercentage == null) {
        campaignDetails.specialistPercentage = this.specialistDefaultPercentage;
      }
    }
  }

  populateAgencyPercentage(campaignDetails: CampaignDetails) {
    if (this.agencyDefaultPercentage !== null && campaignDetails.agency && campaignDetails.agency.agencyId > 0) {
      if (campaignDetails.agencyPercentage == null) {
        campaignDetails.agencyPercentage = this.agencyDefaultPercentage;
      }
    }
  }

  calculatePreBarterValue(index, campaignDetails, propertyName) {
    const barter = campaignDetails[propertyName][index].barterId;
    if ((barter == null) &&
      (campaignDetails[propertyName][index].barterRate == null || campaignDetails[propertyName][index].barterRate === 0)) {
      campaignDetails[propertyName][index].preBarterValue = null;
    } else if (barter != null) {
      campaignDetails[propertyName][index].preBarterValue =
        campaignDetails[propertyName][index].preBarterValue === 0 ? '0' : (campaignDetails[propertyName][index].preBarterValue || campaignDetails[propertyName][index].smartBricsValue);
    }
  }

  calculatePostBarterValue(prebarter, barterrate) {
    // 'Post-Barter Value (£)' = 'Pre-Barter Value (£)' / 100 * 'Barter Rate (%)'
    let postbarter = 0;
    if (prebarter && barterrate) {
      // if ((typeof prebarter === 'string' || prebarter instanceof String)) {
      //   prebarter = prebarter;
      // }
      // if ((typeof barterrate === 'string' || barterrate instanceof String)) {
      //   barterrate = barterrate;
      // }
      // Value (£)' = 'Pre-Barter Value (£)' / 100 * 'Barter Rate (%)'
      // postbarter = prebarter - (prebarter / 100 * barterrate);
      postbarter = (prebarter / 100) * barterrate;
    }
    // added parseFloat for this jira : SBRICS-2309 : Amit
    return parseFloat(postbarter.toFixed(2));
  }

  manageGrossMediaValue(index, campaignDetails, propertyName) {
    if (index >= 0) {
      this.calculatePreBarterValue(index, campaignDetails, propertyName);
    }

    if (campaignDetails[propertyName][index].barterId > 0) {
      campaignDetails[propertyName][index].grossMediaValue =
        this.calculatePostBarterValue(campaignDetails[propertyName][index].preBarterValue, campaignDetails[propertyName][index].barterRate);
    }

    campaignDetails[propertyName][index].netMediaValue = this.commercialService.calculateNetMedia(index, campaignDetails, propertyName);
    campaignDetails.grossMediaValue = 0;
    campaignDetails.netMediaValue = 0;

    campaignDetails[propertyName].forEach((booking) => {
      if (campaignDetails.campaignStatusId === CampaignStatus.cancel ||
        booking.bookingStatusId !== CampaignStatus.cancel) {
        campaignDetails.grossMediaValue += booking.grossMediaValue ? parseFloat(booking.grossMediaValue) : 0;
        campaignDetails.netMediaValue += booking.netMediaValue ? parseFloat(booking.netMediaValue) : 0;
      }
    });
  }

  /**
   * @description calculate booking line wise cpt
   * @author Amit Mahida
   * @param {BookingLine} booking
   * @param {number} mediaValue
   * @param {number} discount
   * @memberof CampaignDetailsService
   */
  calculateBookingLineCPT(booking: BookingLine, mediaValue: number, campaignCPTScale: number) {
    booking.campaignCPT = booking.impressions ? this.setZeroForNaN((mediaValue / (booking.impressions / 1000))) : 0;
    booking.campaignCPT = this.floorToTwoDecimalPoints(booking.campaignCPT, campaignCPTScale); // Booking line's CPT
  }

  /**
   * @description Handles edit grossmedia
   * @author Amit Mahida
   * @param {BookingLine} booking
   * @param {number} mediaValue
   * @param {number} discount
   * @memberof CampaignDetailsService
   */
  onEditGrossMediaValue(booking: BookingLine, mediaValue: number, campaignCPTScale: number) {
    this.calculateBookingLineCPT(booking, mediaValue, campaignCPTScale);
    booking.grossMediaRemaining = _.cloneDeep(booking.grossMediaValue);
  }

  /**
   * @description Handles edit pmp discount
   * @author Amit Mahida
   * @param {BookingLine} booking
   * @param {number} mediaValue
   * @param {number} discount
   * @memberof CampaignDetailsService
   */
  onEditPMPDiscount(booking: BookingLine, discount: number, campaignCPTScale: number) {
    booking.grossMediaRemaining = Number((booking.smartBricsValue - (booking.smartBricsValue * discount / 100)).toFixed(2));
    booking.grossMediaValue = _.cloneDeep(booking.grossMediaRemaining);
    this.calculateBookingLineCPT(booking, booking.grossMediaValue, campaignCPTScale);
  }

  /**
   * @description calculate CPT Value,gross media value and pmp discount
   * @author Kishan Patel
   * @param {*} campaignDetails - object of campaing Detail
   * @param {*} [rowIndex] - row index of booking grid which is changed
   * @param {*} [averageCPT] - updated average CPT value
   * @param {*} [grossMediaValue] - updated gross media value fron booking grid
   * @returns
   * @memberof CampaignDetailsService
   */
  calculateCPT(campaignDetails: CampaignDetails, rowIndex?: number, averageCPT?: number, grossMediaValue?: number, options: any = {}, campaignCPTScale = 2) {
    const searchPageObject = this.stateService.getCampaign();
    if (Number(searchPageObject.campaignTypeId) !== CampaignType.fixed
      || (SystemFlags.isLoadedCampaign && new Date(campaignDetails.campaignStartDate) < new Date())
      || SystemFlags.isPPCampaign // SM-5593
    ) {
      return;
    }
    // For individual booking in campaign
    if (campaignDetails.bookingList && campaignDetails.bookingList.length > 0) {
      let newAverageCPT = 0;
      _.each(campaignDetails.bookingList, (obj, i) => {
        if (obj.bookingStatusId !== 5) {
          let mediaValue = obj.smartBricsValue;
          const discount = obj.pmpdiscount ? obj.pmpdiscount : 0;
          // Calculating CPT
          switch (true) {
            case _.isUndefined(averageCPT) && _.isUndefined(rowIndex):
              // initial calculation
              // SM-7361 : If it is fresh campaign then calculation UI formula should be applied.
              if (!campaignDetails?.preventRecalculation) {
                let campaignCPT = 0;
                if (obj.impressions) {
                  campaignCPT = this.setZeroForNaN((mediaValue / (obj.impressions / 1000)));
                }
                obj.campaignCPT = obj.campaignCPT && SystemFlags.isLoadedCampaign && !options.preventRecalculationChanged ? obj.campaignCPT : campaignCPT;
                if (!SystemFlags.isLoadedCampaign || (SystemFlags.isLoadedCampaign && options.preventRecalculationChanged)) {
                  obj.grossMediaValue = obj.smartBricsValue;
                }
                obj.grossMediaRemaining = obj.grossMediaValue;
              }
              if (campaignDetails?.preventRecalculation && !options.preventRecalculationChanged) {
                if (!SystemFlags.isLoadedCampaign) {
                  mediaValue = (obj.impressions / 1000) * campaignDetails.campaignCPT;
                  obj.campaignCPT = _.cloneDeep(campaignDetails.campaignCPT);
                  obj.grossMediaRemaining = mediaValue;
                  obj.grossMediaValue = obj.grossMediaRemaining;
                }
              }
              break;
            case !_.isUndefined(averageCPT):
              // average CPT updated by user
              mediaValue = (obj.impressions / 1000) * averageCPT;
              obj.campaignCPT = _.cloneDeep(averageCPT);
              obj.grossMediaRemaining = mediaValue;
              obj.grossMediaValue = obj.grossMediaRemaining;
              break;
            case !_.isUndefined(rowIndex):
              switch (true) {
                case !_.isUndefined(grossMediaValue):
                  // Gross media value changed
                  if (i === rowIndex) {
                    mediaValue = grossMediaValue;
                    this.onEditGrossMediaValue(obj, mediaValue, campaignCPTScale);
                  }
                  break;
                case _.isUndefined(grossMediaValue):
                  // pmp discount changed
                  if (i === rowIndex) {
                    this.onEditPMPDiscount(obj, discount, campaignCPTScale);
                  }
                  break;
              }
              break;
          }
          if (_.isUndefined(rowIndex) || !_.isUndefined(grossMediaValue)) {
            obj.pmpdiscount = obj.smartBricsValue === 0 ? 0 : this.setZeroForNaN((obj.smartBricsValue - obj.grossMediaValue) * 100 / obj.smartBricsValue);
            obj.pmpdiscountDisplay = this.decimalPipe.transform(obj.pmpdiscount, '.2-2');
          }
          if (!campaignDetails?.preventRecalculation || (campaignDetails?.preventRecalculation && !_.isUndefined(averageCPT))) {
            newAverageCPT += obj.campaignCPT;
            obj.campaignCPT = this.floorToTwoDecimalPoints(obj.campaignCPT, campaignCPTScale); // Booking line's CPT
          }
        }
      });
      // set average of all booking line cpt
      // sm-8307 : campaignCPT is calculated based on confirmed bookings only
      if ((!campaignDetails?.preventRecalculation || (campaignDetails?.preventRecalculation && !_.isUndefined(averageCPT))) && !(SystemFlags.isLoadedCampaign && SystemFlags.readOnly && SystemFlags.splitable)) {
        const getBookingConfirmedLength = campaignDetails.bookingList.filter(list => list['bookingStatusId'] !== CampaignStatus.cancel).length;
        if (getBookingConfirmedLength > 0) {
          campaignDetails.campaignCPT = parseFloat((newAverageCPT / getBookingConfirmedLength).toFixed(campaignCPTScale));
        } else {
          campaignDetails.campaignCPT = 0;
        }
      }
    }

  }

  /**
   * @description return pmpDisount if it is there otherwise 0
   * @param obj object of booking list
   */
  parseDiscount(obj) {
    return obj.pmpdiscount ? obj.pmpdiscount : 0;
  }

  /**
   * @description Checks that calculation for CPT is required or not
   * @param campaignDetails CampaignDetails
   */
  notRequiredCalculationCPT(campaignDetails: CampaignDetails) {
    const searchPageObject = this.stateService.getCampaign();
    // calculateCPT(campaignDetails, averageCPT, newMediaValue, rowIndex) {
    if (Number(searchPageObject.campaignTypeId) !== CampaignType.fixed
      || (SystemFlags.isLoadedCampaign && new Date(campaignDetails.campaignStartDate) < new Date())
      || SystemFlags.isPPCampaign // SM-5593
    ) {
      return true;
    }
    return false;
  }

  /**
   * @description Get Media Value
   * @param obj Object of booking list
   * @param i index
   * @param averageCPT average CPT
   * @param newMediaValue Net Media Value
   * @param rowIndex Row Index
   */
  getMediaValue(obj, i, averageCPT?: number, newMediaValue?: number, rowIndex?: number) {
    let mediaValue = obj.smartBricsValue;
    if (averageCPT) {
      mediaValue = (averageCPT * obj.impressions) / (10 * 100);
    } else if (i === rowIndex && newMediaValue !== undefined) {
      mediaValue = newMediaValue;
    }
    return mediaValue;
  }

  /**
   * @description get Gross Media Remaining
   * @param obj Object of booking list
   * @param i index
   * @param mediaValue Media Value
   * @param averageCPT average CPT
   * @param newMediaValue Net Media Value
   * @param discount Discount
   * @param rowIndex Row Index
   */
  getGrossMediaRemaining(obj, i, mediaValue, averageCPT?: number, newMediaValue?: number, discount?: number, rowIndex?: number) {
    if (averageCPT) {
      return mediaValue;
    } else {
      return Number(((i === rowIndex) && newMediaValue !== undefined
        ? newMediaValue
        : obj.smartBricsValue - (obj.smartBricsValue * discount / 100)).toFixed(2));
    }
  }

  /**
   * @description get PMP Discount
   * @param obj Object of booking list
   */
  getPMPDiscount(obj) {
    return obj.smartBricsValue === 0 ? 0 : this.setZeroForNaN((obj.smartBricsValue - obj.grossMediaValue) * 100 / obj.smartBricsValue);
  }

  /**
   * @description get Campaign CPT
   * @param obj Object of booking list
   * @param averageCPT average CPT
   * @param mediaValue Media Value
   * @param discount Discount
   */
  getCampaignCPT(obj, averageCPT, mediaValue, discount) {
    return averageCPT || (obj.impressions ?
      this.setZeroForNaN((mediaValue - (mediaValue * discount / 100)) * 1000 / obj.impressions) : 0);
  }

  /**
   * @description Get Final Campaign CPT
   * @param finalCampaignCPT
   * @param bookingLineCount
   */
  getFinalCampaignCPT(finalCampaignCPT, bookingLineCount) {
    return finalCampaignCPT ? parseFloat((finalCampaignCPT / bookingLineCount).toFixed(2)) : 0;
  }

  setZeroForNaN(no) {
    return isNaN(no) ? 0 : no;
  }

  floorToTwoDecimalPoints(number, campaignCPTScale = 2) {
    return _.round(number, campaignCPTScale);
  }

  getBookingStatusNameFromId(bookingArray) {
    let statusName = [];

    bookingArray.forEach((element) => {
      if (element.bookingStatusId) {
        statusName.push(this.getStatusNameFromId(element.bookingStatusId));
      }
    });
    if (statusName.length > 0) {
      statusName = _.uniq(statusName);
    }

    let finalStatusName = '';

    statusName.forEach((element) => {
      finalStatusName = `${finalStatusName},  ${element}`;
    });

    finalStatusName = finalStatusName.substr(1);
    return finalStatusName;
  }

  getStatusNameFromId(id) {
    let sel = null;
    const bookingStatus = this.dataShareService.getInitialConfigByKey('bookingStatus');
    bookingStatus.forEach((element) => {
      if (id === element.bookingStatusId) {
        sel = element.bookingStatusName;
      }
    });
    return sel;
  }

  populateCampaignDetails(commercialData, initialConfig: InitialConfigModel, assignedToList = []) {
    let campaignDetails: CampaignDetails = new CampaignDetails();
    campaignDetails = Object.assign(campaignDetails, commercialData);
    const environmentId = this.dataShareService.getInitialConfig().systemData.environmentId;
    if (environmentId !== EnvironmentId.UK) {
      // VJ:19.09.2019 For now, this flag is applied for UK only, but backend keep sending it as true for all other countries, so adding this patch to make it false
      // So that other common logic which uses this flag will ignore this flag
      // SM-3506 Original ticket for this flag
      campaignDetails.calculateAtUI = false;
    }

    if (commercialData.advertiser) {
      campaignDetails.advertiser.advertiserId = commercialData.advertiser.organisationId;
      campaignDetails.advertiser.advertiserName = commercialData.advertiser.organisationName;
      campaignDetails.advertiser.advertiserCode = commercialData.advertiser.organisationCode;
      delete campaignDetails.advertiser.organisationCode;
      delete campaignDetails.advertiser.organisationId;
      delete campaignDetails.advertiser.organisationName;
      // Removed for SM-10204 as this was preventing the customerId field update in edit mode.
      // delete campaignDetails.advertiser.organisationTypeId;
    }
    if (commercialData.internalAdvertiser) {
      campaignDetails.internalAdvertiser.advertiserId = commercialData.internalAdvertiser.organisationId;
      campaignDetails.internalAdvertiser.advertiserName = commercialData.internalAdvertiser.organisationName;
      delete campaignDetails.internalAdvertiser.organisationCode;
      delete campaignDetails.internalAdvertiser.organisationId;
      delete campaignDetails.internalAdvertiser.organisationName;
      delete campaignDetails.internalAdvertiser.organisationTypeId;
    }

    if (commercialData.adminPersonId) {
      campaignDetails.adminPerson.adminPersonId = commercialData.adminPersonId;
      campaignDetails.adminPerson.adminPersonName = commercialData.adminPersonName;
    }

    if (commercialData.salesPersonId) {
      campaignDetails.salesPerson.salesPersonId = commercialData.salesPersonId;
      campaignDetails.salesPerson.salesPersonName = commercialData.salesPersonName;
    }

    if (!campaignDetails.title || SystemFlags.advertiserChange) {
      if (!this.commercialService.IsTitlePersist){
      campaignDetails.title = this.populateCampaignTitle(campaignDetails.advertiser, initialConfig.userData.initials);
      }
      SystemFlags.advertiserChange = false;
    }
    if (assignedToList) {
      campaignDetails.assignedTo = assignedToList.filter(e => e.assignedToId === campaignDetails.assignedToId)[0];
    }
    if (campaignDetails.thirdParty[ThirdParty.agency]) {
      campaignDetails.agency.agencyId = campaignDetails.thirdParty[ThirdParty.agency].thirdPartyId;
      campaignDetails.agency.agencyName = campaignDetails.thirdParty[ThirdParty.agency].thirdPartyName;
      campaignDetails.agency.invoiceStatusCode = campaignDetails.thirdParty[ThirdParty.agency].invoiceStatusCode;
      campaignDetails.agencyPercentage = campaignDetails.thirdParty[ThirdParty.agency].commission;

      this.populateAgencyPercentage(campaignDetails);
    }
    if (campaignDetails.thirdParty[ThirdParty.specialist]) {
      campaignDetails.specialist.specialistId = campaignDetails.thirdParty[ThirdParty.specialist].thirdPartyId;
      campaignDetails.specialist.specialistName = campaignDetails.thirdParty[ThirdParty.specialist].thirdPartyName;
      campaignDetails.specialist.invoiceStatusCode = campaignDetails.thirdParty[ThirdParty.specialist].invoiceStatusCode;
      campaignDetails.specialistPercentage = campaignDetails.thirdParty[ThirdParty.specialist].commission;

      this.populateSpecialistPercentage(campaignDetails);
    }
    if (campaignDetails.thirdParty[ThirdParty.subAgency]) {
      campaignDetails.subAgency.subAgencyId = campaignDetails.thirdParty[ThirdParty.subAgency].thirdPartyId;
      campaignDetails.subAgency.subAgencyName = campaignDetails.thirdParty[ThirdParty.subAgency].thirdPartyName;
      campaignDetails.subAgency.invoiceStatusCode = campaignDetails.thirdParty[ThirdParty.subAgency].invoiceStatusCode;
    }
    // SM-2559 for Belgium, Nishit
    if (initialConfig.userData.externalUser) {
      campaignDetails.salesTeamId = this.getSalesTeamId(campaignDetails.salesTeamId, initialConfig);
      campaignDetails.bookings[0].adminPersonId = this.getAdminPersonId(campaignDetails.bookings[0].adminPersonId, initialConfig);
      campaignDetails.bookings[0].adminPersonName = this.getAdminPersonName(campaignDetails.bookings[0].adminPersonName, initialConfig);
      campaignDetails.bookings[0].salesPersonId = this.getSalesPersonId(campaignDetails.bookings[0].salesPersonId, initialConfig);
      campaignDetails.bookings[0].salesPersonName = this.getSalesPersonName(campaignDetails.bookings[0].salesPersonName, initialConfig);
    }

    if (!Object.keys(this.stateService.getCommercialObject('loadedCommercialDetails')).length) {
      this.stateService.setCommercialObject('loadedCommercialDetails', campaignDetails);
    }

    if (commercialData.organisationContact && commercialData.organisationContact.organisationContactId) {
      campaignDetails.buyerContact.buyerContactId = commercialData.organisationContact.organisationContactId;
      campaignDetails.buyerContact.buyerContactName = commercialData.organisationContact.contactName;
    }
    campaignDetails.overshow = commercialData.overshow;
    return campaignDetails;
  }

  /**
   * @description Get Sales Team ID
   * @param salesTeamId Sales Team ID
   * @param initialConfig InitialConfigModel
   */
  getSalesTeamId(salesTeamId, initialConfig: InitialConfigModel) {
    return salesTeamId ||
      (initialConfig.userData.externalUserData && initialConfig.userData.externalUserData.defaultSalesTeam ? initialConfig.userData.externalUserData.defaultSalesTeam.salesTeamId : null);
  }

  /**
   * @description Get Admin Person ID
   * @param adminPersonId Admin Person ID
   * @param initialConfig InitialConfigModel
   */
  getAdminPersonId(adminPersonId, initialConfig: InitialConfigModel) {
    return adminPersonId ||
      (initialConfig.userData.externalUserData && initialConfig.userData.externalUserData.defaultAdminPerson ? initialConfig.userData.externalUserData.defaultAdminPerson.idAdminPerson : null);
  }

  /**
   * @description Get Admin Person Name
   * @param adminPersonName Admin Person Name
   * @param initialConfig InitialConfigModel
   */
  getAdminPersonName(adminPersonName, initialConfig: InitialConfigModel) {
    return adminPersonName ||
      (initialConfig.userData.externalUserData && initialConfig.userData.externalUserData.defaultAdminPerson ? initialConfig.userData.externalUserData.defaultAdminPerson.adminPersonName : null);
  }

  /**
   * @description Get Sales Person ID
   * @param salesPersonId Sales Person ID
   * @param initialConfig InitialConfigModel
   */
  getSalesPersonId(salesPersonId, initialConfig: InitialConfigModel) {
    return salesPersonId ||
      (initialConfig.userData.externalUserData && initialConfig.userData.externalUserData.defaultSalesPerson ? initialConfig.userData.externalUserData.defaultSalesPerson.idSalesPerson : null);
  }

  /**
   * @description Get Sales Person Name
   * @param salesPersonName Sales Person Name
   * @param initialConfig InitialConfigModel
   */
  getSalesPersonName(salesPersonName, initialConfig: InitialConfigModel) {
    return salesPersonName ||
      (initialConfig.userData.externalUserData && initialConfig.userData.externalUserData.defaultSalesPerson ? initialConfig.userData.externalUserData.defaultSalesPerson.salesPersonName : null);
  }

  /**
   * @description Filters Product category list by productCategoryId
   * @author Shivani Patel
   * @param {*} [productCategoryId=-1]
   * @returns
   * @memberof CampaignDetailsService
   */
  // setProductCategoryList(productCategoryId: number = -1): ProductCategoryMain[] {
  //   const productCategoryList: ProductCategoryMain[] = _.cloneDeep(this.dataShareService.getInitialConfigByKey('productCategoryGroup'));
  //   if (productCategoryId === -1) {
  //     return productCategoryList;
  //   }
  //   const data: ProductCategoryMain[] = [];
  //   productCategoryList.forEach((productCategory) => {
  //     productCategory.productCategory = productCategory.productCategory.filter(value =>
  //       value.productCategoryId === productCategoryId
  //     );
  //     if (productCategory.productCategory.length) {
  //       data.push(productCategory);
  //     }
  //   });
  //   return data;
  // }

  setProductCategoryList(productCategoryList) {
    const initialConfigProductCategory = _.cloneDeep(this.dataShareService.getInitialConfigByKey('productCategoryGroup'));
    const data: ProductCategoryMain[] = [];
    productCategoryList.forEach((productCategory) => {
      initialConfigProductCategory.forEach((productCategoryGroup) => {
        if (productCategoryGroup.productCategory) {
          productCategoryGroup.productCategory.forEach((originalProductCategory) => {
            if (originalProductCategory.productCategoryId === productCategory.productCategoryId) {
              this.groupProductCategory(data, productCategoryGroup, originalProductCategory);
            }
          });
        }
      });
    });
    return this.getCategoryList(data, initialConfigProductCategory);
  }

  groupProductCategory(data: ProductCategoryMain[], productCategoryGroup, originalProductCategory) {
    let groupExists;
    if (data.length > 0) {
      groupExists = data.filter((item) => {
        return item.productCategoryGroupId === productCategoryGroup.productCategoryGroupId;
      });
    }
    if (groupExists && groupExists.length > 0) {
      groupExists[0].productCategory.push(originalProductCategory);
    } else {
      const main = {
        productCategoryGroupCode: productCategoryGroup.productCategoryGroupCode,
        productCategoryGroupId: productCategoryGroup.productCategoryGroupId,
        productCategoryGroupName: productCategoryGroup.productCategoryGroupName,
        productCategory: []
      };
      main.productCategory.push(originalProductCategory);
      data.push(main);
    }
  }

  getCategoryList(data, initialConfigProductCategory) {
    if (data.length > 0) {
      return data;
    } else {
      initialConfigProductCategory.forEach((productCategory) => {
        if (productCategory.productCategory.length) {
          data.push(productCategory);
        }
      });
      return _.orderBy(data, (e) => e.productCategory[0].productCategoryName);
    }
  }

  resetAllList() {
    this.advertisersList = [];
    this.productCategoryList = this.setProductCategoryList([]);
    this.brandsList = [];
    this.agenciesList = [];
    this.specialistsList = [];
  }
  /**
   * @description this will populate the History field in campaign details section
   * @author Nishit Parekh
   * @param {HistoryROC} historyROC
   * @param {Object} auditReasonsHistoryMap list of ROC/FOC etc
   * @param {boolean} showHistoryROC this will be from uiControl
   * @returns {HistoryROC}
   * @memberof CampaignDetailsService
   */
  populateHistoryROC(historyROC: HistoryROC, auditReasonsHistoryMap: object, showHistoryROC: boolean): HistoryROC {
    if (auditReasonsHistoryMap && Object.keys(auditReasonsHistoryMap).length && showHistoryROC) {
      let list = [];
      Object.keys(auditReasonsHistoryMap).forEach((element) => {
        if (element !== 'FOC') {
          list = list.concat(auditReasonsHistoryMap[element]);
        }
      });

      list = _.sortBy(list, o => o.updateStamp).reverse();
      list.forEach((item) => {
        if (item.otherReason) {
          historyROC.data += `${item.otherReason} \n`;
        }
      });
    }
    return historyROC;
  }

  setDefaultAgencyPercentage(agencyPercentage: number | null) {
    this.agencyDefaultPercentage = agencyPercentage;
  }

  setDefaultSpecialistPercentage(specialistPercentage: number | null) {
    this.specialistDefaultPercentage = specialistPercentage;
  }

}
