export class SelectConfig {

  /**
   * @description
   * @type {boolean}
   * @memberof SelectConfig
   */
  hideLoadingBar ? = true;

  allowClear ? = true;
  placeholder ? = null;
  idField ? = 'id';
  multiple ? = false;

  /**
   * @description keeps search term on focus
   * @type {boolean}
   * @memberof SelectConfig
   */
  keepSearchTerm ? = false;

  /**
   * @description Accepts url in string to search
   * @type {string}
   * @memberof SelectConfig
   */
  lookupUrl ? = '';

  /**
   * @description Accepts name of property in extra paramets in which the search key should attach if required.
   * @type {string}
   * @memberof SelectConfig
   */
  appendSearchKeyParamNameIn ? = 'data';

  displayInvoiceCode ? = false;

  /**
   * @description specify extra parameters needed to pass on server with search term
   * @type {object}
   * @memberof SelectConfig
   */
  extraParams?: object = {};

  /**
   * @description property to specify request parameter key for the searched term
   * @type {string}
   * @memberof SelectConfig
   */
  searchKeyParamName ? = 'search';

  /**
   * @description Accepts minimum length of characters to search
   * @type {number}
   * @memberof SelectConfig
   */
  minLength ? = 2;

  /**
   * @description Restricts server call and searchs only in available list if is set to true
   * @type {boolean}
   * @memberof SelectConfig
   */
  searchInItemsOnly ? = false;

  textField ? = 'text';

  constructor(selectConfig: SelectConfig) {
    if (!selectConfig) {
      return;
    }
    if (selectConfig.hideLoadingBar === false) {
      this.hideLoadingBar = false;
    }
    if (selectConfig.allowClear === false) {
      this.allowClear = false;
    }
    if (selectConfig.placeholder) {
      this.placeholder = selectConfig.placeholder;
    }
    if (selectConfig.idField) {
      this.idField = selectConfig.idField;
    }
    if (selectConfig.multiple) {
      this.multiple = true;
    }
    if (selectConfig.keepSearchTerm) {
      this.keepSearchTerm = true;
    }
    if (selectConfig.lookupUrl) {
      this.lookupUrl = selectConfig.lookupUrl;
    }
    if (selectConfig.appendSearchKeyParamNameIn) {
      this.appendSearchKeyParamNameIn = selectConfig.appendSearchKeyParamNameIn;
    }
    if (selectConfig.displayInvoiceCode) {
      this.displayInvoiceCode = true;
    }
    if (SelectConfig.isObject(selectConfig.extraParams)) {
      this.extraParams = selectConfig.extraParams;
    }
    if (selectConfig.searchKeyParamName) {
      this.searchKeyParamName = selectConfig.searchKeyParamName;
    }
    if (selectConfig.minLength) {
      this.minLength = selectConfig.minLength;
    }
    if (selectConfig.searchInItemsOnly) {
      this.searchInItemsOnly = true;
    }
    if (selectConfig.textField) {
      this.textField = selectConfig.textField;
    }
  }

  private static isObject(val) {
    if (val === null) {
      return false;
    }
    return typeof val === 'object';
  }
}
