import { DynamicKeyObjectData } from './Dynamic.model';
import { ColumnConfig } from './MapData.model';
export class VipFrameCountModel {
  total = 0;
  available = 0;
  selected = 0;
}

export class VipProcessFilterModel {
  xHeadings: any;
  concertina: any;
  groups: any;
  filterCounts: VipFrameCountModel;
  columnConfig: DynamicKeyObjectData<ColumnConfig>;
}

export class SelectionData {
  frame: number;
  frameCode: string;
  startDate: string;
  endDate: string;
}
export class DeletedSelection {
  selectionData?: SelectionData;
  selectedColumns?: string[];
  selectedRows?: string[];
}
