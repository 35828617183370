import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing/app-routing.module';

import { NgbModule, NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
// Toaster module
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { CustomHttpInterceptor } from '../geoplanner/src/app/http.interceptor';

import { CoreModule } from '../geoplanner/src/app/core/core.module';

import { AppRootComponent } from './app-root/app-root.component';

import { SearchModule } from '../geoplanner/src/app/search/search.module';
import { ResultModule } from '../geoplanner/src/app/result/result.module';
import { ResultMapModule } from '../geoplanner/src/app/result-map/result-map.module';
import { WorkspaceModule } from '../geoplanner/src/app/workspace/workspace.module';
import { GeoMapperModule } from '../geo-mapper.module';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppCommercialComponent } from './app-commercial/app-commercial.component';
import { CommercialModule } from '../geoplanner/src/app/commercial/commercial.module';
import { AppHeaderService } from './app-header/app-header.service';
import { NgbDatepickerI18 } from '../geoplanner/src/app/core/components/ngb-datepicker/ngb-datepickerI18';
import { NgbDateParserService } from '../geoplanner/src/app/core/components/ngb-datepicker/ngb-date-parser.service';

@NgModule({
  declarations: [
    AppRootComponent,
    AppHeaderComponent,
    AppCommercialComponent,
  ],
  entryComponents: [
  ],
  imports: [
    BrowserModule,
    // Import the module into the application, with configuration
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    ToasterModule,
    CoreModule.forRoot(),
    GeoMapperModule,
    SearchModule,
    ResultModule,
    ResultMapModule,
    WorkspaceModule,
    CommercialModule,
    NgxSpinnerModule
    // Ng4LoadingSpinnerModule.forRoot(), ////TODO : Uncomment
  ],
  providers: [
    AppHeaderService,
    ToasterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    CurrencyPipe,
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18 },
    { provide: NgbDateParserFormatter, useClass: NgbDateParserService }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppRootComponent]
})
export class RootModule { }
