import { Component, OnInit, Input } from '@angular/core';
import { NetowrkBase } from './../network-base';
import { DataShareService, SharedService, BrickBaseService, LogHelperService } from '../../../core/services/index';
import { CellValues } from '../../../models/workspace';
@Component({
  selector: 'app-network-modal',
  templateUrl: './network-modal.component.html',
  styleUrls: ['./network-modal.component.css']
})
export class NetworkModalComponent extends NetowrkBase implements OnInit {

  @Input() resolveObject: CellValues;
  readOnlyModal = false;

  constructor(
    dataShareService: DataShareService,
    sharedService: SharedService,
    brickBaseService: BrickBaseService,
    private logHelperService: LogHelperService
  ) {
    super(dataShareService, sharedService, brickBaseService);
  }

  ngOnInit() {
    this.setColumnConfig(this.resolveObject.columnConfig);
    this.init();
  }

  /**
   * @description Handles modal dismiss
   * @author Nishit Parekh
   * @param {*} event
   * @memberof NetworkModalComponent
   */
  onModalClosed(event) {
    const isSelected = this.network;
    if (event.reason === 'escape' && isSelected) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * @description Handles modal save
   * @author Nishit Parekh
   * @param {*} event
   * @memberof NetworkModalComponent
   */
  onModalSaved(event) {
    if (this.network) {
      const values: CellValues = new CellValues();
      values.brick = this.resolveObject.brick;
      values.selectedValues = this.network;
      event.activeModal.close(values);
    } else {
      this.logHelperService.logError(this.userBundle['common.error.noDataSelect']);
    }
  }

  trackByHodId(index, item) {
    return item?.hodId;
  }

}
