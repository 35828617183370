import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataShareService } from '../core/services/data-share.service';
import { GLOBAL } from '../core/utils/app.constant';
import { getFormData } from '../core/utils/formData';
import { ResponseModel } from '../models';
import { map } from 'rxjs/operators';

@Injectable()
export class ReshuffleService {

  constructor(
    private http: HttpClient,
    private dataShareService: DataShareService
  ) { }

  processReshuffle(columnIndex: number, saveReshuffle: boolean, closeReshuffle = false): Observable<ResponseModel<any>> {
    const processReshuffleURL: string = this.dataShareService.getServiceCallUrlByKey('PROCESS_RESHUFFLE');
    let data: any = {};

    if (closeReshuffle) {
      data = {
        closeReshuffle: true
      };
    } else {
      data = {
        columnIndex,
        saveReshuffle
      };
    }

    const param = {
      action: 'processReshuffle',
      data: JSON.stringify(data),
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const requestOptions = {
      headers,
      body: getFormData(param),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      processReshuffleURL,
      requestOptions
    )
      .map((response: ResponseModel<any>) => {
        return response;
      });
  }

  /**
   * @description This method is used to get the campaings that are ready for reshuffling.
   * @author Shreni Shah
   * @date 2020-05-15
   * @param {number} columnIndex
   * @returns {Observable<ResponseModel<any>>}
   * @memberof ReshuffleService
   */
  getLSReshuffleCampaigns(columnIndex: number): Observable<ResponseModel<any>> {
    const getLSReshuffileCampaingsURL: string = this.dataShareService.getServiceCallUrlByKey('CAMPAIGNS_LS_RESHUFFLE');
    const data = {
      'columnIndex': columnIndex
    };

    const param = {
      action: 'fetchCampaignsReshuffle',
      data: JSON.stringify(data),
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const requestOptions = {
      headers,
      body: getFormData(param),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      getLSReshuffileCampaingsURL,
      requestOptions
    )
      .map((response: ResponseModel<any>) => {
        return response;
      });
  }

  /**
   * @description This method is used to get the campaings that are ready for reshuffling in objective brick.
   * @author Shreni Shah
   * @date 2020-05-15
   * @param {number} columnIndex
   * @returns {Observable<ResponseModel<any>>}
   * @memberof ReshuffleService
   */
  fetchParentBookingsReshuffle(data: any): Observable<ResponseModel<any>> {
    const getLSReshuffileCampaingsURL: string = this.dataShareService.getServiceCallUrlByKey('CAMPAIGNS_LS_RESHUFFLE');
    const param = {
      action: 'fetchParentBookingsReshuffle',
      data: JSON.stringify(data),
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const requestOptions = {
      headers,
      body: getFormData(param),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      getLSReshuffileCampaingsURL,
      requestOptions
    )
      .map((response: ResponseModel<any>) => {
        return response;
      });
  }

  saveOrCancelReshuffle(save: boolean): Observable<ResponseModel<any>> {
    const saveOrCancelURL: string = this.dataShareService.getServiceCallUrlByKey(save ? 'SAVE_LS_RESHUFFLE' : 'CANCEL_LS_RESHUFFLE');
    const action: string = save ? 'saveReshuffle' : 'cancelReshuffle';
    const param = {
      action,
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const requestOptions = {
      headers,
      body: getFormData(param)
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      saveOrCancelURL,
      requestOptions
    )
      .map((response: ResponseModel<any>) => {
        return response;
      });
  }

  /**
   * @description  This method is used to process the changes made by user in the campaings
   * @author  Shreni Shah
   * @date 2020-05-15
   * @param {number} columnIndex
   * @param {*} gridData
   * @Param {*} reshuffleScopeData
   * @returns {Observable<ResponseModel<any>>}
   * @memberof ReshuffleService
   */
  executeReshuffle(columnIndex: number, gridData: any, reshuffleScopeData?: any): Observable<ResponseModel<any>> {
    const processLSReshuffileCampaingsURL: string = this.dataShareService.getServiceCallUrlByKey('PROCESS_LS_RESHUFFLE');
    const data = {
      'columnIndex': columnIndex,
      'data': gridData
    };
    if (reshuffleScopeData) {
      data['reshuffleScopeUserSelection'] = reshuffleScopeData;
    }
    const param = {
      action: 'executeReshuffle',
      data: JSON.stringify(data),
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const requestOptions = {
      headers,
      body: getFormData(param),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      processLSReshuffileCampaingsURL,
      requestOptions
    )
      .map((response: ResponseModel<any>) => {
        return response;
      });

  }

  /**
  * @description  This method is used to call unlock donor 
  * @author  Siddharth vaidya
  * @param {number} columnIndex
  * @memberof ReshuffleService
  */
  executeUnlockDonors(columnIndex: number): Observable<ResponseModel<any>> {
    const UnlockDonorsURL: string = this.dataShareService.getServiceCallUrlByKey('CAMPAIGNS_LS_RESHUFFLE');
    const data = {
      'columnIndex': columnIndex,
    };
    const param = {
      action: 'fetchSmartUnlockDetails',
      data: JSON.stringify(data),
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const requestOptions = {
      headers,
      body: getFormData(param),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      UnlockDonorsURL,
      requestOptions
    ).map((response: ResponseModel<any>) => {
      return response;
    });
  }

  /**
   * @description  This method is used to process the changes made by user in the campaings
   * @author  Dhaval Patel
   * @date 2022-12-15
   * @param {number} columnIndex
   * @param {*} gridData
   * @Param {*} reshuffleScopeData
   * @returns {Observable<ResponseModel<any>>}
   * @memberof ReshuffleService
   */
  executeReshuffleForSingleCampaign(columnIndex: number, gridData: any, reshuffleScopeData?: any): Observable<ResponseModel<any>> {
    const processLSReshuffileCampaingsURL: string = this.dataShareService.getServiceCallUrlByKey('PROCESS_LS_RESHUFFLE');
    const data = {
      'columnIndex': columnIndex,
      'data': gridData
    };
    if (reshuffleScopeData) {
      data['reshuffleScopeUserSelection'] = reshuffleScopeData;
    }
    const param = {
      action: 'executeReshuffleForSingleCampaign',
      data: JSON.stringify(data),
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const requestOptions = {
      headers,
      body: getFormData(param),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      processLSReshuffileCampaingsURL,
      requestOptions
    )
      .map((response: ResponseModel<any>) => {
        return response;
      });

  }

  /**
 * @description  This method is used to fetch the KPI details of current reshuffling process
 * @author  Vijay Sutaria
 * @date 2021-11-18
 * @returns {Observable<ResponseModel<any>>}
 * @memberof ReshuffleService
 */
  fetchLSReshuffleKPIData(): Observable<ResponseModel<any>> {
    const fetchLSReshuffleKPIDataURL: string = this.dataShareService.getServiceCallUrlByKey('PROCESS_LS_RESHUFFLE');
    const param = {
      action: 'fetchReshuffleKPIData',
      hideLoader: true
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const requestOptions = {
      headers,
      body: getFormData(param),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      fetchLSReshuffleKPIDataURL,
      requestOptions
    ).map((response: ResponseModel<any>) => {
      return response;
    });
  }
}
