import { Component } from '@angular/core';
import { AgCustomRowUtil } from '../../utils/ag-custom-row-util';
import { CellRendererBaseComponent } from '../cell-renderer-base/cell-renderer-base.component';

@Component({
  selector: 'app-ag-number-cell-renderer',
  templateUrl: './ag-number-cell-renderer.component.html',
  styleUrls: ['./ag-number-cell-renderer.component.css']
})
export class AgNumberCellRendererComponent extends CellRendererBaseComponent {
  constructor() {
    super();
  }

  public getParentRowText(): string {
    if (this.params.calcParentCellValue) {
      return this.params.calcParentCellValue(this.params);
    }
    if (this.groupingField) {
      const rowData = AgCustomRowUtil.getRowData(this.params.api);
      const childRows = AgCustomRowUtil.getChildRows(rowData, this.groupingField, this.params.data[this.groupingField]);
      const sum = AgCustomRowUtil.sumFieldValues(childRows, this.params.column.colId);
      this.params.data[this.params.column.colId] = sum;
      return String(sum);
    } else {
      return this.params.data[this.params.column.colId];
    }
  }

  public getChildRowText(): string {
    if (!this.params.data[this.params.column.colId]) {
      this.params.data[this.params.column.colId] = 0;
    }
    return this.params.data[this.params.column.colId];
  }

  public getFirstPinRowText(): string {
    if (this.params.calcCellValue) {
      const rowData = AgCustomRowUtil.getRowData(this.params.api);
      const childRows = AgCustomRowUtil.getChildRows(rowData);
      let sum = 0;
      sum = this.params.calcCellValue(childRows, this.params.column.colId);
      return String(sum);
    } else {
      return '';
    }
  }

  public getSecondPinRowText(): string {
    const rowData = AgCustomRowUtil.getRowData(this.params.api);
    const childRows = AgCustomRowUtil.getChildRows(rowData);
    const sum = AgCustomRowUtil.sumFieldValues(childRows, this.params.column.colId);
    return String(sum);
  }
  public formatText(displayText): string {
    let disText = displayText;
    if (this.params.formatCellValue) {
      disText = this.params.formatCellValue(displayText);
    }
    return disText;
  }
}
