import { Injectable } from '@angular/core';
import { DataShareService } from '../core/services/data-share.service';
import { SharedService } from '../core/services/shared.service';
import * as _ from 'lodash';

@Injectable()
export class ResultService {

  constructor(
    private datashareService: DataShareService,
    private sharedService: SharedService) {
  }

  getResultData(params: object) {
    return this.sharedService.makeServerCall(params, this.datashareService.getServiceCallUrlByKey('RESULT_URL'));
  }

  getConcertinaConfig(params: object) {
    return this.sharedService.makeServerCall(params, this.datashareService.getServiceCallUrlByKey('CONCERTINA_CONFIG_URL'));
  }

  getCoverageAndFrequency(params: object) {
    return this.sharedService.makeServerCall(params, this.datashareService.getServiceCallUrlByKey('RESULT_COVER_FREQ_URL'));
  }

  updateGroupingData(sortableItems) {
    const groupingData = [];
    _.map(sortableItems, (obj) => {
      if (obj.value === true) {
        groupingData.push(obj.selectionId);
      }
    });
    return groupingData;
  }

}
