import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { LogHelperService } from '../../services/log-helper.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit, AfterViewInit {

  browseText = '';

  /**
   * It describes extension of uploaded file.
   */
  @Input() extension: string;
  /**
   * Name of the file uploaded.
   */
  @Input() fileName: string;

  /**
   * @memberof FileUploadComponent
   */
  @Output() fileNameChange = new EventEmitter<string>();

  /**
   * Flag to define multiple files are allowes or not.
   */
  @Input() multipleFiles: boolean;

  /**
   * @description Title displays when we hover on the file upload control
   * @type {string}
   * @memberof FileUploadComponent
   */
  @Input() title: string;

  /**
   * Defines total number of uploaded files.
   */
  @Input() noOfFiles: number;

  /**
   * It emits an event to save file locally.
   */
  @Output() savefilelocally = new EventEmitter();

  /**
   * Reference of element for file.
   */
  @ViewChild('file') fileComponent: ElementRef;
  /**
   * It contains uploaded files data.
   */
  private files: any[];

  /**
   * Defines initial config data.
   */
  public initialConfig: any;

  /**
   * Constructor
   * @param dataShareService injected dataShare service object.
   * @param logHelperService injected logHelper service object
   */
  constructor(
    private dataShareService: DataShareService,
    private logHelperService: LogHelperService
  ) { }

  /**
   * Initialization purpose
   */
  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.browseText = this.initialConfig.userBundle['fileUpload.browse'] || 'Browse';
    if (!this.fileName && !this.title) {
      this.fileName = this.initialConfig.userBundle['geoplanner.text.uploadFile'] || 'Upload File';
    }
    if (this.multipleFiles) {
      this.title = this.initialConfig.userBundle['common.file.multiple'] || 'multiple files';
    } else {
      this.title = this.fileName;
    }
  }

  ngAfterViewInit() {
    // Moved code to ngAfterViewInit() as ViewChild was undefined in ngOnInIt()//
    if (this.multipleFiles) {
      this.fileComponent.nativeElement.setAttribute('multiple', true);
    }
  }

  /**
   * called file is changed.
   * @param event event
   */
  fileChanged = (event) => {
    this.files = [];
    let ext;
    this.title = '';
    for (const file of event.target.files) {
      if (typeof file === 'object') {
        let exist = false;
        for (const f of this.files) {
          if (file.name === f.name) {
            exist = true;
            break;
          }
        }
        if (!exist) {
          if (this.files.length >= this.noOfFiles) {
            this.logHelperService.logError(`${this.initialConfig.userBundle['workspace.error.list.maxNoFiles']} 2`);
            break;
          }
          this.files.push(file);
          this.fileName = file.name;
          if (this.files.length > 1) {
            this.title = this.initialConfig.userBundle['common.file.multiple'] || 'multiple files';
          } else {
            this.title += file.name;
          }

          ext = file.name.match(/\.(.+)$/)[1];
          if (ext === 'xls' || ext === 'xlsx') {
            this.savefilelocally.emit({ file: this.files });
          } else {
            const msg = this.initialConfig.userBundle['common.error.invalidFile'] || 'Only .xls or .xlsx files can be uploaded';
            this.logHelperService.logError(msg);
            this.title = '';
            this.fileName = this.initialConfig.userBundle['geoplanner.text.uploadFile'] || 'Upload File';
          }
        }
      }
    }
    if (this.fileComponent.nativeElement) {
      this.fileComponent.nativeElement.files = null;
      this.fileComponent.nativeElement.value = "";
    }
  }
}
