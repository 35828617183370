<div class="tabbar">
  <div class="btn-group smartSearch">
    <input *ngIf="userModel?.searchTabAccess?.openSearch" type="radio" name="radio_groups" [(ngModel)]="typeSelection"
      [value]="0" id="check123" (change)="onTabChange(); findCampaigns()" />
    <label *ngIf="userModel?.searchTabAccess?.openSearch" for="check123">
      {{userBundle['search.search.searchTab']}}
    </label>

    <input *ngIf="userModel?.searchTabAccess?.openRequest && uiControl?.requestSearchEnabled" type="radio"
      name="radio_groups" [(ngModel)]="typeSelection" [value]="2" id="check234" (change)="onTabChange(); findCampaigns()"/>
    <label *ngIf="userModel?.searchTabAccess?.openRequest && uiControl?.requestSearchEnabled" for="check234">
      {{userBundle['search.search.requestTab']}}
    </label>

    <input *ngIf="userModel?.searchTabAccess?.openBooking" type="radio" name="radio_groups" [(ngModel)]="typeSelection"
      [value]="1" id="check222" (change)="onTabChange(); findCampaigns()"/>
    <label *ngIf="userModel?.searchTabAccess?.openBooking" for="check222">
      {{userBundle['search.search.campaignTab']}}
    </label>

    <input *ngIf="userModel?.searchTabAccess?.openBooking && uiControl?.apiSearchEnabled" type="radio"
      name="radio_groups" [(ngModel)]="typeSelection" [value]="3" id="check333" (change)="onTabChange(); findCampaigns()"/>
    <label *ngIf="userModel?.searchTabAccess?.openBooking && uiControl?.apiSearchEnabled" for="check333">
      {{userBundle['search.search.apiSearchTab']}}</label>
  </div>
</div>
<div style="height:auto; clear: both;">
  <ag-grid-angular style="width: 100%; height:260px;" class="ag-theme-fresh" [gridOptions]="gridOptions"
    [columnDefs]="columnDefs" [overlayNoRowsTemplate]="noRowsTemplate" [context]="context" [getRowClass]="getRowClass"
    [frameworkComponents]="frameworkComponents">
  </ag-grid-angular>
  <div style="text-align: center;">
    <div class="ng-table-pager parent">

      <div class="parent">
        <div class="child pagination-selectbox">
          <select (change)="onPageSizeChanged()" class="page-size form-control" id="page-size" [(ngModel)]="pageSize">
            <option value="10" selected="">10</option>
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <a href="javascript:void(0)" (click)="onBtnExport()"
            title="{{userBundle['workspace.button.tooltip.downloadExcel']}}"
            class="export-button savebutton save animated bounceIn"></a>
        </div>

        <div class="child">
          <app-pagination
            [current]="currentPage"
            [total]="lastPage"
            [totalRecords]="campaignsData.length"
            [filterredRecordCount]="totalRec"
            [pageSize]="pageSize"
            (goTo)="onGoTo($event)"
            (next)="onNext($event)"
            (previous)="onPrevious($event)"
            (firstPage)="onFirstPage()"
            (lastPage)="onLastPage()"
            [cursor]="cursor"
          ></app-pagination>
        </div>
        <div class="load-button-container child" *ngIf="cursor && uiControl.applyLimitOnSearch">
          <button class="load-button" (click)="loadMore()">{{ userBundle['search.search.loadmore'] || 'Load More' }}</button>
        </div>
        <div class="load-button-container child" *ngIf="cursor && uiControl.applyLimitOnSearch">
          <button class="load-button" (click)="loadAll()">{{ userBundle['search.search.loadallbuttontext'] || 'Load All' }}</button>
        </div>
      </div>


      <!-- <div class="load-button-container" *ngIf="cursor">
        <button class="load-button" (click)="loadAll()">Load All</button>
      </div> -->
    </div>
  </div>
</div>
