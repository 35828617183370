import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { CellValues } from '../../../models/workspace';
import {
  DataShareService,
  BrickBaseService,
  LogHelperService,
  CellAttributeService,
  StateService,
} from '../../../core/services/index';
import { MultiTargetService } from '../multi-target.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MultiTargetBase } from '../multi-target-base';
import { FileUpload } from '../../../models';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';
import * as _ from 'lodash';

const UPLOAD_FILE = 'geoplanner.text.uploadFile';
const UPLOAD_FILE1 = 'Upload File';
const maxNoFiles = 'workspace.error.list.maxNoFiles';
@Component({
  selector: 'app-multi-target-modal',
  styleUrls: ['./multi-target-modal.css'],
  templateUrl: './multi-target-modal.component.html',
  providers: [MultiTargetService]
})
export class MultiTargetModalComponent extends MultiTargetBase implements OnInit {

  /**
   * This object is used to manipulate and tranfer data between
   * List filter component and filter area component.
   * @type {Brick}
   * @memberof MultiTargetModalComponent
   */
  @Input() resolveObject: CellValues;

  /**
   * Current active tab
   */
  readOnlyModal = false;

  selectedSOTOption: string = "SKIP_FRAME";

  @ViewChild('fileComponent') fileComponent: ElementRef;

  /**
   * Creates an instance of MultiTargetModalComponent.
   * @param {DataShareService} dataService
   * @param {SharedService} sharedService
   * @param {BrickBaseService} brickBaseService
   * @param {LogHelperService} logHelperService
   * @param {multiTargetService} multiTargetService
   * @param {NgbActiveModal} activeModal
   * @memberof MultiTargetModalComponent
   */
  constructor(
    dataService: DataShareService,
    brickBaseService: BrickBaseService,
    logHelperService: LogHelperService,
    multiTargetService: MultiTargetService,
    stateService: StateService,
    appHeaderService: AppHeaderService,
    private activeModal: NgbActiveModal,
    private cellAttributeService: CellAttributeService,
  ) {
    super(dataService, brickBaseService, logHelperService, multiTargetService, stateService, appHeaderService);
  }

  /**
   * @description angular life cycle hook - called on component init
   * @author Nishit Parekh
   * @memberof MultiTargetModalComponent
   */
  ngOnInit() {
    this.fileComponentLocal = this.fileComponent;
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.listData.fileNameLabel = this.initialConfig.userBundle[UPLOAD_FILE] || UPLOAD_FILE1;
    const selectedValues = this.resolveObject.selectedValues ? this.resolveObject.selectedValues : {};
    this.cellIndex = this.resolveObject.brick.cellIndex;
    if (selectedValues.selectedSOTOption) {
      this.selectedSOTOption = selectedValues.selectedSOTOption;
    }
    this.init(selectedValues);
  }

  /**
   * @description callback to close modal popup window
   * @author Nishit Parekh
   * @param {*} event
   * @memberof MultiTargetModalComponent
   */
  onModalClosed(event) {
    if (event.reason === 'escape' && this.listData.listUpload.fileList.length) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * @description callback function - called during save click
   * @author Nishit Parekh
   * @param {*} event
   * @memberof MultiTargetModalComponent
   */
  onModalSaved() {
    if (this.listData.listUpload.fileList.length) {
      const range = this.resolveObject.selectedValues.selectionPeriod;
      this.sendUploadRequest(this.listData.listUpload.fileList, 0, range, this.selectedSOTOption).then((responseMsg) => {
        if (responseMsg === '') {
          const newList: FileUpload = new FileUpload();
          newList.listUpload = this.listData.listUpload;
          newList.selectedSOTOption = this.selectedSOTOption;
          this.resolveObject.selectedValues.listUpload = newList.listUpload;
          const values: CellValues = new CellValues();
          values.brick = this.resolveObject.brick;
          values.selectedValues = newList;
          values.displayText = this.initialConfig.userBundle['workspace.bricname.multitarget'];
          values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.MultiTarget, values.selectedValues);
          values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.MultiTarget, values.selectedValues);
          this.activeModal.close(values);
        } else {
          this.logHelperService.logError(responseMsg);
        }
      }, (error) => {
        this.logHelperService.logError(error);
      });
    } else {
      this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.list.validation.route']);
    }
  }

  trackByFile(index) {
    return index;
  }

  /**
   * @description Remove uploaded file
   * @author Nishit Parekh
   * @param {number} index - index of file to remove
   * @memberof MultiTargetBase
   */
   removeFile(index: number) {
    if (index != null) {
      this.cloneOfFileList = _.clone(this.listData.listUpload.fileList);
      this.listData.listUpload.fileList.splice(index, 1);
    }
    this.listData.fileNameLabel = this.initialConfig.userBundle[UPLOAD_FILE] || UPLOAD_FILE1;
    this.fileComponent['fileName'] = this.listData.fileNameLabel;
    if (this.fileComponent['fileComponent'].nativeElement) {
      this.fileComponent['fileComponent'].nativeElement.files = null;
      this.fileComponent['fileComponent'].nativeElement.value = "";
    }
  }

  /**
   * @description File save locally
   * @author Nishit Parekh
   * @param {*} data
   * @returns {boolean}
   * @memberof MultiTargetBase
   */
  saveFilelocally(data: { file: any; }): boolean {
    if (this.listData.listUpload.fileList.length < 1) {
      const file = data.file;

      if (file.length > 1) {
        this.fileComponent['fileName'] = this.listData.fileNameLabel;
        this.logHelperService.logError(`${this.initialConfig.userBundle[maxNoFiles]} 1`);
        return true;
      }

      for (const f of file) {
        if (this.listData.listUpload.fileList.length > 0) {
          return this.checkForExistingFileList(f);
        } else {
          this.listData.listUpload.fileList.push(f);
        }
      }
    } else {
      this.fileComponent['fileName'] = this.listData.fileNameLabel;
      this.logHelperService.logError(`${this.initialConfig.userBundle[maxNoFiles]} 1`);
    }
    return true;
  }

}
