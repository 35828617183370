import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { LocaleData } from '../utils/LocaleData';

@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {
  @Input() appOnlyNumber: boolean;
  @Input() decimalPoints = 0;
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() thousandSep: string = LocaleData.NUM_GROUP_SEP;
  @Input() decimalSep: string = LocaleData.NUM_DECIMAL_SEP;
  @Input() allowComma = false;
  @Input() allowNegetiveIntegers = false;
  @Input() allowReplaceDecimalSeparator = false;

  private isPaste = false;
  private inputLastValue = '';
  // Allow decimal numbers. The \. is only allowed once to occur

  constructor(private el: ElementRef) {}

  @HostListener('blur', ['$event']) onChange() {
    if (this.appOnlyNumber && this.minValue && (!this.isValidNumberString(this.el.nativeElement.value) || !this.validateMinValue(this.el.nativeElement.value))) {
      this.el.nativeElement.value = this.minValue;
      this.el.nativeElement.dispatchEvent(new Event('input'));
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;
    if (!this.appOnlyNumber) {
      return;
    }
    // SM-1983 - handling paste of data with charcters
    if (this.handlePaste(e)) {
      return;
    }

    if (e.key === LocaleData.NUM_GROUP_SEP && LocaleData.NUM_GROUP_SEP !== '.') {
      e.preventDefault();
    }

    let newString = this.el.nativeElement.value || '';
    const cursorPos = this.el.nativeElement.selectionStart;
    const cursorPosEnd = this.el.nativeElement.selectionEnd;

    newString = cursorPos !== cursorPosEnd ? `${newString.slice(0, cursorPos)}${e.key}${newString.slice(cursorPosEnd)}` : `${newString.slice(0, cursorPos)}${e.key}${newString.slice(cursorPos)}`;
    if (LocaleData.NUM_DECIMAL_SEP !== '.' && e.key === '.' && this.decimalPoints > 0) {
      newString = newString.replace(new RegExp('\\.', 'gi'), LocaleData.NUM_DECIMAL_SEP);
      e.preventDefault();
      if (this.isValidNumberString(newString) && this.validateMaxValue(newString)) {
        this.el.nativeElement.value = newString;
      }
    }
    if (!this.isValidNumberString(newString) || !this.validateMaxValue(newString)) {
      e.preventDefault();
    }

  }

  @HostListener('keyup', ['$event']) onKeyUp() {
    if (this.appOnlyNumber && this.isPaste) {
      // this.el.nativeElement.value = this.el.nativeElement.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSep);
      // SM-1983 - handling paste of data with charcters
      const curValue = this.el.nativeElement.value || '';
      if (!this.isValidNumberString(curValue) || !this.isValidValue(curValue)) {
        this.el.nativeElement.value = this.inputLastValue;
        this.el.nativeElement.dispatchEvent(new Event('input'));
      }
      // SM-1983 - End - handling paste of data with charcters
    }
  }

  private handlePaste(e: any) {
     // SM-1983 - End - handling paste of data with charcters

    if ((e.keyCode === 86 && e.ctrlKey === true)) {
      this.isPaste = true;
      this.inputLastValue = this.el.nativeElement.value || '';
    } else {
      this.isPaste = false;
    }

    // Allow: Ctrl+A // Allow: home, end, left, right
    if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 || ([65, 67, 86, 88].indexOf(e.keyCode) !== -1 && e.ctrlKey) ||
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return true;
    }
  }

  private isValidNumberString(newString: string): boolean {
    const regex: RegExp = new RegExp(/^[0-9]*$/);

    if (this.decimalPoints === 0 && !regex.test(newString)) {
      return false;
    }
    if (newString.indexOf(LocaleData.NUM_DECIMAL_SEP) > 0) {
      const numberArr = newString.split(LocaleData.NUM_DECIMAL_SEP);
      if (numberArr.length !== 2) {
        return false;
      } else {
        // SM-2221 by Shivani Patel,
        // Added .replace because regex.test(num) get fails when numberArr[0] has thousandSep in it.
        const num = numberArr[0].replace(new RegExp(LocaleData.NUM_GROUP_SEP === '.' ? `\\${LocaleData.NUM_GROUP_SEP}` : LocaleData.NUM_GROUP_SEP, 'gi'), '');
        const dec = numberArr[1];
        if (!regex.test(num) || (dec.length > 0 && !regex.test(dec)) || (dec.length > this.decimalPoints)) {
          return false;
        }
        return true;
      }
    }
    return this.isValidString(regex, newString);
  }

  isValidString(regex: RegExp, newString: string) {
    const regexNegetiveInt: RegExp = new RegExp(/^[+-]?\d+$/);
    const regexComma: RegExp = new RegExp(/^[0-9,.]*$/);
    if (this.allowComma && !regexComma.test(newString)) {
      return false;
    } else if (this.allowNegetiveIntegers) {
      if (newString === '-') {
        return true;
      } else if (!regexNegetiveInt.test(newString)) {
        return false;
      }
    } else if (!regex.test(newString)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Validation for max value and min value both
   * @param newString
   */
  private isValidValue(newString) {
    // SM-2221 by Shivani Patel
    // Check validation for maximum and minimum value
    if (!this.validateMaxValue(newString) || !this.validateMinValue(newString)) {
      return false;
    }
    return true;
  }

  /**
   * Validation for max value
   * @param newString
   */
  private validateMaxValue(newString) {
    const filteredNum = this.getFilteredNumber(newString);
    if ((this.maxValue && filteredNum > this.maxValue) || (this.maxValue === 0)) {
      return false;
    }
    return true;
  }

  /**
   * Validation for min value
   * @param newString
   */
  private validateMinValue(newString) {
    const filteredNum = this.getFilteredNumber(newString);
    if ((this.minValue && filteredNum < this.minValue) || (this.minValue === 0)) {
      return false;
    }
    return true;
  }

  /**
   * filter inputed value using RegExp
   * @param newString
   */
  private getFilteredNumber(newString) {
    let filteredNum = newString.replace(new RegExp(LocaleData.NUM_GROUP_SEP === '.' ? `\\${LocaleData.NUM_GROUP_SEP}` : LocaleData.NUM_GROUP_SEP, 'gi'), '');
    if (LocaleData.NUM_DECIMAL_SEP !== '.' && this.allowReplaceDecimalSeparator) {
      filteredNum = filteredNum.replace(new RegExp(LocaleData.NUM_DECIMAL_SEP, 'g'), '.');
    }
    return filteredNum;
  }
}
