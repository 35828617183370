<div [ngClass]="{'readOnly' : readOnly }">
    <div class="width_3">
        <!-- Start Date -->
        <div class="control_group">
            <label class="label datelabel">
                {{userBundle['common.campaignStartDate']}}
            </label>
            <input id="campaignStartDate" type="text" [disabled]="true"
                [ngModel]="campaignDetails.campaignStartDate | dateFormat : displayFromToDateFormat" class="form_control" />
        </div>
    </div>

    <div class="width_3">
        <!-- End Date -->
        <div class="control_group">
            <label class="label datelabel">
                {{userBundle['common.campaignEndDate']}}
            </label>
            <input id="campaignEndDate" type="text" [disabled]="true"
                [ngModel]="campaignDetails.campaignEndDate  | dateFormat : displayFromToDateFormat " class="form_control" />
        </div>
    </div>

    <div class="width_3">
        <!-- Create Date -->
        <div class="control_group">
            <label class="label datelabel">{{userBundle['common.campaignCreateDate']}}</label>
            <input id="campaignCreateDate" type="text" [disabled]="true"
                [ngModel]="campaignDetails.campaignCreateDate  | dateFormat : displayDateFormat" class="form_control" />
        </div>
    </div>

    <div class="width_3">
        <!-- Advertiser -->
        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label"
                [ngClass]="{'required' : (campaignDetails?.advertiser.advertiserId == '' || campaignDetails?.advertiser.advertiserId == null)}">
                {{userBundle['common.advertiserName']}}
            </label>
            <div class="gcontainer">
                <app-sb-select id="advertiserSelect" [config]="advertiserLookupConfig" [items]="advertisersList"
                    [currentItem]="campaignDetails.advertiser" (onItemSelected)="onAdvertiserNameSelected($event, isTitlePersisted)"
                    (onItemRemoved)="removeSelectedAdvertiserName($event, isTitlePersisted);"
                    (responseData)="getAdvertisersResponse($event)">
                </app-sb-select>
            </div>
        </div>

        <!-- Agency -->
        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label">{{userBundle['common.agencyName']}}</label>
            <div class='gcontainer'>
                <app-sb-select id="agencySelect" [config]="agencyLookupConfig" [items]="agenciesList"
                    [currentItem]="campaignDetails.agency"
                    (onItemSelected)="onAgencyNameSelected($event)" (onItemRemoved)="removeSelectedAgencyName()"
                    (responseData)="getAgenciesResponse($event)"
                    [disabled]="readOnly  || campaignDetails.disableAgency">
                </app-sb-select>
            </div>
        </div>

        <!-- Sub Agency -->
        <div class="control_group">
            <label class="label">{{userBundle['common.subAgencyName']}}</label>
            <div class='gcontainer'>
                <app-sb-select id="subagency" [config]="subAgencyLookupConfig"
                    [items]="subAgenciesList" [currentItem]="campaignDetails.subAgency" (onItemSelected)="onSubAgencyNameSelected($event)"
                    (onItemRemoved)="removeSelectedSubAgencyName()" (responseData)="getSubAgencyResponse($event)" [disabled]="subAgencydisable">
                </app-sb-select>
            </div>
        </div>

        <!-- Barter -->
        <div class="control_group" *ngIf="!dataShareService.hideUIElements('common.barterName')">
            <label class="label">{{userBundle['common.barterName']}}</label>
            <div class='gcontainer'>
                <app-sb-select id="barter" [config]="barterLookupConfig" [items]="barterList"
                    [currentItem]="selectedBarter" (onItemSelected)="onBarterNameSelected($event)" (onItemRemoved)="removeSelectedBarterName()"
                    (responseData)="getBarterResponse($event)">
                </app-sb-select>
            </div>
        </div>
    </div>

    <div class="width_3">
        <!-- Brand -->
        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label"
                [ngClass]="{'required' : (campaignDetails?.brand.brandId == 0 || campaignDetails?.brand.brandId == null)}">
                {{userBundle['common.brandName']}}
            </label>
            <div class='gcontainer'>
                <app-sb-select id="brandSelect" [config]="brandLookupConfig" [items]="brandsList"
                    [currentItem]="campaignDetails.brand" (onItemSelected)="onBrandSelected($event, isTitlePersisted)"
                    (onItemRemoved)="removeSelectedBrand($event, isTitlePersisted)" (responseData)="getBrandsResponse($event)">
                </app-sb-select>
            </div>
        </div>

        <!-- Agency (%) -->
        <div class="control_group">
            <label class="label">{{userBundle['common.agency.commission']}}</label>
            <input id="agencyPercentage" type="text" [imask]="percentageInputMask" [unmask]="maskService.unmask.typed"
                class="form_control percentage" [(ngModel)]="campaignDetails.agencyPercentage" />
        </div>

        <!-- Barter Rate (%) -->
        <div class="control_group" *ngIf="!dataShareService.hideUIElements('common.barter.commission')">
            <label class="label">{{userBundle['common.barter.commission']}}</label>
            <input id="barterRate" type="text" [appOnlyNumber]="true" [decimalPoints]="2" [minValue]="0"
                [maxValue]="100" class="form_control percentage"
                [(ngModel)]="campaignDetails?.bookingList[0].barterRate" />
        </div>
    </div>

    <div class="width_3">
        <!-- Industry -->
        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label" [ngClass]="{'required' : campaignDetails.productCategoryId == -1}">
                {{userBundle['common.productCategoryName']}}</label>
            <select id="productCategorySelect" class="form_control chzn-container"
                [(ngModel)]="campaignDetails.productCategoryId" [ngStyle]="{'width': '58%'}">
                <option value="-1">{{userBundle['common.lookup.defaultOption']}}</option>
                <ng-template ngFor let-item [ngForOf]="productCategoryList" [ngForTrackBy]="trackByProductCategoryGroup">
                    <optgroup *ngIf="item.productCategory"
                        label="{{item.productCategoryGroupName}} - {{item.productCategoryGroupCode}}">
                        <option *ngFor="let child of item.productCategory; trackBy: trackByProductCategory;" [value]="child.productCategoryId">
                            {{child.productCategoryName}}
                        </option>
                    </optgroup>
                    <option *ngIf="!item.productCategory">{{item.productCategoryGroupName}}</option>
                </ng-template>
            </select>
        </div>

        <!-- Sales Team -->
        <div class="control_group">
            <label class="label" [ngClass]="{'required' : campaignDetails.salesTeamId == -1}">
                {{userBundle['common.salesTeamName']}}
            </label>
            <select id="salesTeamSelect" class="form_control chzn-container" [(ngModel)]="campaignDetails.salesTeamId"
                [ngStyle]="{'width': '58%'}">
                <option value="-1">{{userBundle['common.lookup.defaultOption']}}</option>
                <option *ngFor="let SaleInfo of salesTeamList; trackBy: trackBySalesTeam;" [value]="SaleInfo.salesTeamId">
                    {{SaleInfo.salesTeamName}}
                </option>
            </select>
        </div>

        <!-- Specialist -->
        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label">{{userBundle['common.specialistName']}}</label>
            <div class='gcontainer'>
                <app-sb-select id="specialistSelect" [config]="specialistLookupConfig"
                    [items]="specialistsList"
                    [currentItem]="campaignDetails.specialist" (onItemSelected)="onSpecialistNameSelected($event)"
                    (onItemRemoved)="removeSelectedSpecialistName()"
                    (responseData)="getSpecialistsResponse($event)"
                    [disabled]="readOnly || campaignDetails.disableSpecialist">
                </app-sb-select>
            </div>
        </div>
    </div>

    <div class="width_8">
        <!-- Title -->
        <div class="control_group">
            <label class="label label-with-checkbox"
                [ngClass]="{'required': (campaignDetails.title == null || campaignDetails.title == '')}">
                {{userBundle['common.title']}}
                <input type="checkbox" title="Persist Title" (change)="changeTitlePersistStatus(isTitlePersisted)" name="Persist Title" id="persist-title" [(ngModel)]="isTitlePersisted">
            </label>
            <textarea id="campaignTitle" class="form_control customtextarea"
                [(ngModel)]="campaignDetails.title" (keyup)="changeTitlePersistStatus(true)" (mouseup)="changeTitlePersistStatus(true)" (change)="manuallyTitleChanged()"></textarea>
        </div>
    </div>

    <div class="width_3"
        *ngIf="commercialConfig.uiControl.hiddenElements.indexOf('commercial.booking.mediaLength')== -1">
        <!-- Media Length -->
        <div class="control_group">
            <label class="label" [ngClass]="{'required':(isMediaLengthRequired &&( campaignDetails.mediaLength == null || campaignDetails.mediaLength ===''
                || campaignDetails.mediaLength === '0'))}">{{userBundle['commercial.label.mediaLength']}}</label>
            <input id="mediaLength" type="text" [appOnlyNumber]="true" [decimalPoints]="0" [minValue]="0"
                [maxValue]="999" class="form_control percentage" [(ngModel)]="campaignDetails.mediaLength" />
        </div>
    </div>
    
    <div class="width_3" *ngIf="commercialConfig.uiControl.showTargetImpression">
      <div class="control_group">
          <label class="label">{{userBundle['commercial.label.targetImpressions'] || "Target Impressions"}}</label>
          <input id="campaignCPT" type="text" [(ngModel)]="campaignDetails.campaignTargetImpressions" [imask]="commonNumberInputMask"
              [unmask]="maskService.unmask.typed" (change)="campaignTargetImpressionsChanged()" class="form_control percentage" />
      </div>
    </div>

    <div class="width_8">
        <!-- Comments -->
        <div class="control_group">
            <label class="label">{{userBundle['common.comments']}}</label>
            <textarea id="comments" class="form_control customtextarea-comments" [(ngModel)]="campaignDetails.comments"
                maxlength="2000"></textarea>
        </div>
    </div>


    <div class="width_3">
        <!-- Assigned To -->
        <div class="control_group" *ngIf="!dataShareService.hideUIElements('commercial.booking.assignedTo')">
            <label class="label">
                {{userBundle['common.assignedToName']}}
            </label>
            <select id="assignedTo" class="form_control chzn-container" [(ngModel)]="campaignDetails.assignedTo"
                [ngStyle]="{'width': '58%'}" (change)="assignedToChange()">
                <option value="undefined">{{userBundle['common.lookup.defaultOption']}}
                </option>
                <option *ngFor="let AssignedTo of assignedToList; trackBy: trackByAssignedTo;" [ngValue]="AssignedTo" [disabled]="!AssignedTo.inUse" [ngClass]="{'inactive-assignedto': !AssignedTo.inUse}">
                    {{AssignedTo.assignedToName}}
                </option>
            </select>
        </div>

        <!-- External Reference -->
        <div class="control_group">
            <label class="label">{{userBundle['commercial.label.externalReference']}}</label>
            <input id="externalReference" type="text" class="form_control"
                [(ngModel)]="campaignDetails.externalReference" maxlength="50" />
        </div>
    </div>

    <div class="width_8" *ngIf="commercialConfig.uiControl.historyROC && historyROC.data">
        <div class="control_group">
            <label class="label">{{userBundle['commercial.label.historyROC'] || 'History'}}</label>
            <textarea class="form_control customtextarea-comments history-pointer" [(ngModel)]="historyROC.data" maxlength="2000"
                cols="30" rows="1" title={{historyROC.data}} disabled></textarea>
        </div>
    </div>
    <div class="width_3">
        <!-- pmp DealId -->
        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}"
            *ngIf="commercialConfig.uiControl.hiddenElements.indexOf('commercial.booking.pmpDealId') == -1 ">
            <label class="label"
                [ngClass]="{'required' : (searchPageObject.campaignTypeGroupId == campaignGroupTypeList.PMP) && (!campaignDetails.pmpDealId || campaignDetails.pmpDealId === '')} ">
                {{userBundle['common.pmpDealId']}}</label>
            <input id="pmpDealId" type="text" class="form_control" autocomplete="off" [(ngModel)]="campaignDetails.pmpDealId" />
        </div>
    </div>
    <div class="width_3">
        <!-- Campaign CPT -->
        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}"
            *ngIf="commercialConfig.uiControl.hiddenElements.indexOf('commercial.booking.campaignCPT')== -1">
            <label class="label"
                [ngClass]="{'required': searchPageObject.campaignTypeGroupId == campaignGroupTypeList.PMP && !campaignDetails.campaignCPT}">{{userBundle['common.campaignCPT']}}</label>
            <input id="campaignCPT" type="text" [(ngModel)]="campaignDetails.campaignCPT"
                class="form_control percentage" [appOnlyNumber]="true" [decimalPoints]="2" [minValue]="0"
                [maxValue]="100000000" (blur)="onAverageCPTChanged()" />
        </div>
    </div>
    <div class="width_3" *ngIf="searchPageObject.campaignTypeGroupId == campaignGroupTypeList.PMP">
      <div class="control_group">
        <label class="label" style="width: 46%">
          {{userBundle['commercial.label.preventRecalculation'] || 'Prevent Recalculation'}}
        </label>
        <div class="swichControl-commercial">
          <label class="switchAllowFrames">
            <input type="checkbox" [(ngModel)]="campaignDetails.preventRecalculation" (change)="preventRecalculationChanged(false, calculateCPTEvent)">
            <span class="sliderAllowFrames round"></span>
          </label>
        </div>
      </div>
    </div>
    <div class="width_3" *ngIf="commercialConfig.uiControl.showOvershowToggle">
        <div class="control_group">
            <label class="label" style="width: 46%">
                {{userBundle['commercial.label.overshow']}}
            </label>
            <div class="swichControl-commercial">
                <label class="switchAllowFrames">
                    <input type="checkbox" [(ngModel)]="campaignDetails.overshow">
                    <span class="sliderAllowFrames round"></span>
                </label>
            </div>
        </div>
    </div>
</div>
