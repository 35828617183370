import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, CampaignReference, CommercialConfig, Discount, HistoryROC } from '../../commercial.model';
import { SbSelectedItem } from '../../../models/sb-selected-item';
import { DataShareService } from '../../../core/services/data-share.service';
import {
  SystemFlags
} from '../../../models';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { StateService, MaskService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { defaultBuyerContactParams, defaultAdminPersonParams, defaultSalesPersonParams } from '../../../models/request-params';
import { CampaignDetailsBase } from '../campaign-details-base';
import { NgbInputDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerHelper } from '../../../core/components/ngb-datepicker/ngb-datepicker-helper';
import { NumberMaskModel } from '../../../models/imask';
import { GLOBAL } from '../../../core/utils/app.constant';
import { CampaignGroupTypeList, CampaignStatus } from '../../../core/enum';
import { SelectConfig } from '../../../core/components/sb-select/core/select-config';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-campaign-details-belgium',
  templateUrl: './campaign-details-belgium.component.html',
  styleUrls: ['./campaign-details-belgium.component.css']
})
export class CampaignDetailsBelgiumComponent extends CampaignDetailsBase implements OnInit, OnChanges {
  @Input() campaignDetails: CampaignDetails;
  @Input() bookingStatusId;
  @Input() commercialConfig: CommercialConfig;
  @Input() historyROC: HistoryROC;
  /**
   * @memberof CampaignDetailsBelgiumComponent
   * @description readonlyChange
   * @author Dhaval Patel
   */
  @Output() reaonlyChanged = new EventEmitter<boolean>();
  @Output() manuallyCPTUpdated: EventEmitter<any> = new EventEmitter();
  percentageInputMask: NumberMaskModel;
  maskService: MaskService = new MaskService();
  /**
   * @description set readOnly
   * @memberof CampaignDetailsDefaultComponent
   */
  public readOnly = SystemFlags.readOnly;
  /**
   * @description set bookingStatusList
   * @memberof CommercialUkComponent
   */
  bookingStatusList = GLOBAL.bookingStatusList;

  // Buyer Contact Variables
  buyerContactList: SbSelectedItem[] = [];

  adminPersonList: SbSelectedItem[] = [];

  salesPersonList: SbSelectedItem[] = [];
  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof CampaignDetailsGermanyComponent
   */
  currencyInputMask: NumberMaskModel;
  /**
   * @description specific startDate day properties cached object for ngbDatePicker day template
   * @memberof RangeComponent
   */
  startDayObjCache = {};
  /**
   * @description link related data
   * @type {CampaignReference[]}
   * @memberof CampaignDetailsBelgiumComponent
   */
  campaignReferenceList: CampaignReference[];
  searchPageObject: any;
  minStartDate: NgbDateStruct;
  maxEndDate: NgbDateStruct;
  minStartForEndDate: NgbDateStruct;

  isMediaLengthRequired = false;

  /**
   * @description
   * @type {SelectConfig}
   * @memberof CampaignDetailsBase
   */
  public buyerContactLookupConfig: SelectConfig;
  public adminPersonLookupConfig: SelectConfig;
  public salesPersonLookupConfig: SelectConfig;

  campaignGroupTypeList = CampaignGroupTypeList;
  showLockedStatus = false;
  lockReason = SystemFlags.lockReason;
  floatingDatesMandatory = false;
  subs: Subscription[] = [];

  constructor(
    commercialService: CommercialService,
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    stateService: StateService
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService
    );
  }
  ngOnChanges(prop) {
    if (!prop.bookingStatusId.firstChange) {
      this.checkIsMediaLengthRequired();
    }
  }

  ngOnInit(): void {
    this.currencyInputMask = this.maskService.currencyInputMask({ max: 999999999999999999999 });
    this.minStartDate = NgbDatepickerHelper.convertDateToDateStruct(new Date());
    this.maxEndDate = NgbDatepickerHelper.convertDateToDateStruct(new Date(this.campaignDetails.campaignStartDate));
    if (new Date() > new Date(this.campaignDetails.campaignStartDate)) {
      this.minStartDate = this.maxEndDate;
    }

    this.minStartForEndDate = NgbDatepickerHelper.convertDateToDateStruct(new Date(this.campaignDetails.campaignEndDate));
    super.init(this.campaignDetails);
    if (this.campaignDetails.floatingStartDate) {
      const floatingStartDate = new Date(this.campaignDetails.floatingStartDate as Date);
      this.campaignDetails.floatingStartDate = NgbDatepickerHelper.convertDateToDateStruct(floatingStartDate);
    }
    if (this.campaignDetails.floatingEndDate) {
      const floatingEndDate = new Date(this.campaignDetails.floatingEndDate as Date);
      this.campaignDetails.floatingEndDate = NgbDatepickerHelper.convertDateToDateStruct(floatingEndDate);
    }
    const userData = this.dataShareService.getInitialConfigByKey('userData');
    if (userData.tokens.indexOf('EDIT_LOCKED_STATUS') > -1) {
      this.showLockedStatus = true;
    }
    this.populateCampaignDetails();
    this.searchPageObject = this.stateService.getCampaign();
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    this.enableSubAgency();
    this.populateSelectedLink();
    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
    this.checkIsMediaLengthRequired();
    this.setLookupConfig();
    this.floatingDatesMandatory = this.campaignDetails.discountsV2.filter(f => f.statusCategoryId === 2 || f.statusCategoryId === '2').length > 0;

    this.subs.push(this.commercialService.discounts$.subscribe((res: Discount[]) => {
      this.floatingDatesMandatory = res.filter(f => f.statusCategoryId === 2 || f.statusCategoryId === '2').length > 0;
    }));
  }

  onFloatingStartDateChange = () => {
    if (this.campaignDetails.floatingEndDate &&
      NgbDatepickerHelper.convertDateStructToDate(this.campaignDetails.floatingEndDate as NgbDateStruct) < NgbDatepickerHelper.convertDateStructToDate(this.campaignDetails.floatingStartDate as NgbDateStruct)) {
      this.campaignDetails.floatingEndDate = this.campaignDetails.floatingStartDate;
    }
  }

  /**
   * @description To set the configuration for all lookup components
   * @author Dhaval Patel
   * @memberof CampaignDetailsBelgiumComponent
   */
  setLookupConfig() {
    this.setAdvertiserConfig();
    this.setBrandConfig();
    this.setSpecialistConfig();
    this.setAgencyConfig();
    this.setBuyerContactConfig();
    this.setBUDirectorConfig();
    this.setSalesPersonConfig();
  }

  /**
   * @description To set the configuration for buyer contact lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsBelgiumComponent
   */
  setBuyerContactConfig() {
    this.buyerContactLookupConfig = new SelectConfig({
      placeholder: this.userBundle['common.lookup.defaultOption'],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_BUYER_CONTRACT_URL'),
      displayInvoiceCode: false,
      extraParams: defaultBuyerContactParams,
      searchKeyParamName: 'buyerContactName',
      idField: 'buyerContactId',
      textField: 'buyerContactName'
    });
  }

  setBUDirectorConfig() {
    this.adminPersonLookupConfig = new SelectConfig({
      placeholder: this.userBundle['common.adminPersonName'],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_SALES_PERSON_URL'),
      displayInvoiceCode: false,
      extraParams: defaultAdminPersonParams,
      searchKeyParamName: 'adminPersonName',
      idField: 'adminPersonId',
      textField: 'adminPersonName'
    });
  }

  setSalesPersonConfig() {
    this.salesPersonLookupConfig = new SelectConfig({
      placeholder: this.userBundle['common.salesPersonName'],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_SALES_PERSON_URL'),
      displayInvoiceCode: false,
      extraParams: defaultSalesPersonParams,
      searchKeyParamName: 'salesPersonName',
      idField: 'salesPersonId',
      textField: 'salesPersonName'
    });
  }

  cleanFloatingDate = (dateType: string, otherDatePicker: NgbInputDatepicker): boolean => {
    otherDatePicker.close();
    if (dateType === 'start') {
      this.campaignDetails.floatingStartDate = undefined;
    } else {
      this.campaignDetails.floatingEndDate = undefined;
    }
    return false;
  }

  /**
   * @description Populate Campaign Details
   * @author Darshan Vachhani, Shivani Patel
   * @memberof CampaignDetailsUsComponent
   */
  populateCampaignDetails() {
    this.campaignReferenceList = this.campaignDetails.campaignReferenceList;
  }

  /**
   * @description populate selected link
   * @author Darshan Vachhani
   * @memberof CampaignDetailsBelgiumComponent
   */
  populateSelectedLink() {
    const campaignReferences = this.campaignDetails.campaignReferences || [];
    if (campaignReferences.length > 0 && campaignReferences[0] !== null) {
      this.campaignDetails.selectedCampaignReference = campaignReferences;
    }
  }

  onbuyerContactSelected(value: any) {
    this.campaignDetails.buyerContact.buyerContactId = value.buyerContactId;
    this.campaignDetails.buyerContact.buyerContactName = value.buyerContactName;
  }

  onAdminPersonSelected(value: any) {
    this.campaignDetails.adminPerson.adminPersonId = value.adminPersonId;
    this.campaignDetails.adminPerson.adminPersonName = value.adminPersonName;
  }

  onSalesPersonSelected(value: any) {
    this.campaignDetails.salesPerson.salesPersonId = value.salesPersonId;
    this.campaignDetails.salesPerson.salesPersonName = value.salesPersonName;
  }

  removeSelectedbuyerContact() {
    this.campaignDetails.buyerContact.buyerContactId = 0;
    this.campaignDetails.buyerContact.buyerContactName = '';
  }

  removeSelectedAdminPersonContact() {
    this.campaignDetails.adminPerson.adminPersonId = 0;
    this.campaignDetails.adminPerson.adminPersonName = '';
  }

  removeSelectedSalesPerson() {
    this.campaignDetails.salesPerson.salesPersonId = 0;
    this.campaignDetails.salesPerson.salesPersonName = '';
  }

  getBuyerContactResponse(value: any): void {
    if (value.buyerContact && value.buyerContact.length) {
      this.buyerContactList = value.buyerContact.map(item => ({
        buyerContactId: item.buyerContactId,
        buyerContactName: item.buyerContactName,
        source: item
      }));
    }
  }

  getAdminPersonResponse(value: any): void {
    if (value.adminPerson && value.adminPerson.length) {
      this.adminPersonList = value.adminPerson.map((item: any) => ({
        adminPersonId: item.idAdminPerson,
        adminPersonName: item.adminPersonName,
        source: item
      }));
    }
  }

  getSalesPersonResponse(value: any): void {
    if (value.salesPerson && value.salesPerson.length) {
      this.salesPersonList = value.salesPerson.map((item: any) => ({
        salesPersonId: item.idSalesPerson,
        salesPersonName: item.salesPersonName,
        source: item
      }));
    }
  }

  // Functions Related to Specialist
  onSpecialistNameSelected(value: any): void {
    if (this.campaignDetails.specialist) {
      super.onSpecialistChange(value);
      this.commercialService.notifyGrid(this.campaignDetails); // This is used when booking Details is used
    }
  }

  removeSelectedSpecialistName() {
    super.onSpecialistChange();
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  /**
   * @description - Callback method call on agency name changed
   * @author Darshan Vachhani, Shivani Patel
   * @param {*} value
   * @memberof CampaignDetailsUsComponent
   */
  onAgencyNameSelected(value: any): void {
    if (this.campaignDetails.agency) {
      super.onAgencyChange(value);
      this.enableSubAgency();
      this.commercialService.notifyGrid(this.campaignDetails);
    }
  }

  /**
   * @description - Callback method call on removing selected agency
   * @author Darshan Vachhani, Shivani Patel
   * @memberof CampaignDetailsUsComponent
   */
  removeSelectedAgencyName(): void {
    super.onAgencyChange();
    this.enableSubAgency();
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  onAgencyPercentageChange() {
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  /**
   * @description change on assignto
   * @author Darshan Vachhani
   * @memberof CampaignDetailsBelgiumComponent
   */
  assignedToChange() {
    this.enableSubAgency();
    this.setActiveAssignedTo();
  }

  /**
   * @description Calculate average CPT
   * @author Darshan Vachhani
   * @memberof CampaignDetailsBelgiumComponent
   */
  onAverageCPTChanged() {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT); // SM-4776
    this.manageGrossMediaValueWithoutIndex();
    if (this.uiControl.enableUserDefinedCPMPricingCalculation) {
      this.manuallyCPTUpdated.emit();
    }
  }

  /**
   * @description get the specific dates property
   * @author Kishan Patel
   * @param {NgbDateStruct} date current day
   * @param {string} key property key to retrive
   * @returns {boolean}
   * @memberof CampaignDetailsBelgiumComponent
   */
  getStartDayProp(date: NgbDateStruct, key: string): boolean {
    const daystring = this.cacheStartDateObj(date, this.startDayObjCache);
    return this.startDayObjCache[daystring][key];
  }

  checkIsMediaLengthRequired() {
    if (Array.isArray(this.commercialConfig.uiControl.mediaLengthRequired)) {
      const statuses = this.commercialConfig.uiControl.mediaLengthRequired;
      if (statuses.indexOf(this.campaignDetails.campaignStatusId) > -1
        || statuses.indexOf(this.bookingStatusId) > -1) {
        this.isMediaLengthRequired = true;
      } else {
        this.isMediaLengthRequired = false;
      }
    } else {
      this.isMediaLengthRequired = false;
    }

  }

  /**
   * @description It will disable pages when locked is true otherwise pages will be enabled
   * @author Dhaval Patel
   * @memberof CampaignDetailsBelgiumComponent
   */
  lockStatusChanged() {
    if (this.showLockedStatus) {
      if (this.campaignDetails.invoiceLocked) {
        this.campaignDetails.locked = true;
        this.stateService.lockWorkspace();
        SystemFlags.readOnly = true;
        SystemFlags.readOnlyWorkspace = true;
        this.readOnly = true;
        this.reaonlyChanged.emit(true);
      } else {
        this.campaignDetails.locked = false;
        this.stateService.unlockWorkspace();
        if (this.campaignDetails.campaignStatusId === CampaignStatus.cancel) {
          SystemFlags.readOnly = true;
        } else {
          SystemFlags.readOnly = false;
        }
        if (SystemFlags.splitable || SystemFlags.isPPCampaign || this.campaignDetails.campaignStatusId === CampaignStatus.cancel) {
          SystemFlags.readOnlyWorkspace = true;
        } else {
          SystemFlags.readOnlyWorkspace = false;
        }
        this.readOnly = false;
        this.reaonlyChanged.emit(false);
      }
      const campaign = this.stateService.getCampaign();
      campaign.invoiceLocked = this.campaignDetails.invoiceLocked;
      this.stateService.setCampaign(campaign);
    }
  }

  getExternalReference() {
    this.commercialService.getExternalReference().subscribe((res: any) => {
      if (res && String(res.status) === 'OK') {
        this.campaignDetails.externalReference = res.data;
      }
    });
  }

  openSalesProposalLink() {
    if (this.campaignDetails.externalReference && this.commercialConfig.uiControl.externalProposalAppUrl) {
      const salesProposalLink = this.commercialConfig.uiControl.externalProposalAppUrl.replace('{EXTERNALREF}', this.campaignDetails.externalReference);
      window.open(salesProposalLink, '_blank');
    }
  }

  getHeader() {
    this.commercialService.getHeader(this.campaignDetails.externalReference).subscribe((res: any) => {
      if (res && res.status === 'OK' && res.data) {
        if (res.data.thirdParties && res.data.thirdParties.length > 0 && res.data.thirdParties[0]) {
          res.data.thirdParties.forEach((thirdParty: any) => {
            switch (thirdParty.type) {
              case 'ADV':
                // Advertiser
                this.onAdvertiserNameSelected({
                  advertiserId: thirdParty.organisationId,
                  advertiserName: thirdParty.name,
                  source: {
                    brands: thirdParty.brands
                  }
                });
                this.campaignDetails.brand = _.clone(this.campaignDetails.brand);
                break;
              case 'SPE':
                // Specialist

                this.onSpecialistNameSelected(
                  {
                    specialistId: thirdParty.organisationId,
                    specialistName: thirdParty.name
                  }
                );
                this.campaignDetails.specialist = _.clone(this.campaignDetails.specialist);
                break;
              case 'AGE':
                // Agency

                this.onAgencyNameSelected(
                  {
                    agencyId: thirdParty.organisationId,
                    agencyName: thirdParty.name
                  }
                );
                this.campaignDetails.agency = _.clone(this.campaignDetails.agency);
                this.campaignDetails.agencyPercentage = _.clone(this.campaignDetails.agencyPercentage);
                break;
            }
          });
        }

        if (res.data.holders && res.data.holders[0]) {
          const holders = res.data.holders[0];
          this.campaignDetails.salesTeamId = holders.salesTeam.salesTeamId;

          this.onSalesPersonSelected({
            salesPersonId: holders.salesPersonId,
            salesPersonName: holders.name
          });

          for (const booking of this.campaignDetails.bookingList) {
            booking.salesPersonId = holders.salesPersonId;
            booking.salesPersonName = holders.name;
          }
          this.campaignDetails.salesPerson = _.clone(this.campaignDetails.salesPerson);
        }
        this.dataShareService.updateBookingList(this.campaignDetails.bookingList);
      }
    });
  }

  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }
}
