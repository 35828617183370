import { ListDataModal, LookupModel, InitialConfigModel } from '../../models';
import { DataShareService } from '../../core/services/data-share.service';
import { SharedService } from '../../core/services/shared.service';
import { BrickBaseService } from '../../core/services/brick-base.service';
import { LoaderService, LogHelperService } from '../../core/services';
import { ListFilterService } from './list-filter.service';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
declare let saveAs; // FileSaver.js
export class ListBase {
  /**
   * It contians initial config data.
   */
  public initialConfig: InitialConfigModel;

  /**
   * File to send to backend
   * @type {*}
   * @memberof ListBase
   */
  public fileToSend: any;

  /**
   * Initalize List modal
   */
  public listData: ListDataModal;

  /**
   * It contains previous route frameId
   */
  public previousRouteFrameArray = [];

  /**
   * It contains Clone of file
   */
  public cloneOfFileList: any = [];

  /**
   * It contians lookup data.
   */
  public lookups: LookupModel = { lookup: [], brickId: -1 };

  /**
   * Current active tab
   */
  public activeTabIndex = 0;

  public paginationDataModel: any[] = [];
  public pageIndex = 0;
  public pageSize = 20;

  readonly UPLOAD_FILE = 'geoplanner.text.uploadFile';
  readonly MAX_NO_FILES = 'workspace.error.list.maxNoFiles';
  readonly UPLOAD_FILE_STR = 'Upload File';
  /**
   * Creates an instance of ListBase.
   * @param {DataShareService} dataShareService
   * @param {SharedService} sharedService
   * @param {BrickBaseService} brickBaseService
   * @param {LogHelperService} logHelperService
   * @memberof ListBase
   */
  constructor(
    private dataShareService: DataShareService,
    private sharedService: SharedService,
    public brickBaseService: BrickBaseService,
    public logHelperService: LogHelperService,
    private listFilterService: ListFilterService,
    private loaderService: LoaderService
  ) {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.listData = new ListDataModal();
  }

  /**
   * @description initialize the data of instance
   * @author Alkesh Shah
   * @param {*} selectedValue - selected value of cell
   * @memberof ListBase
   */
  init(selectedValue: ListDataModal) {
    this.lookups = this.sharedService.getLookupColumnData(this.brickBaseService.brickID.List, null);
    if (Object.keys(selectedValue).length > 0) {
      if (selectedValue.routeFrameIds
        && (selectedValue.routeFrameIds.include.length > 0 || selectedValue.routeFrameIds.exclude.length > 0)) {
        this.listData.routeFrameIds = this.getRoutFrameIdsObj(selectedValue.routeFrameIds);

        this.listData.routeFrameArray = _.cloneDeep(selectedValue.routeFrameArray);
        this.previousRouteFrameArray = _.cloneDeep(selectedValue.routeFrameArray);
        this.getAudits();
      }
      if (selectedValue.listUpload && Object.keys(selectedValue.listUpload).length > 0) {
        this.listData.listUpload = selectedValue.listUpload;
        this.cloneOfFileList = _.clone(this.listData.listUpload.fileList);
        this.listData.fileNameLabel = this.initialConfig.userBundle[this.UPLOAD_FILE] || this.UPLOAD_FILE_STR;
      }
    }
  }

  /**
   * @description create routeFrameIds from selectedValues
   * @author Darshan Vachhani
   * @param {*} routeFrameIdsObj
   * @returns
   * @memberof ListBase
   */
  getRoutFrameIdsObj(routeFrameIdsObj) {
    let excludeFrame = [];
    let includeFrame = [];
    const routeFrameIds = _.cloneDeep(routeFrameIdsObj);
    if (routeFrameIds.exclude.length > 0) {
      if (typeof (routeFrameIds.exclude[0]) !== 'string') {
        excludeFrame = routeFrameIds.exclude.map(rec => rec.routeFrameId);
        routeFrameIds.requestJSON.exclude.userSelectionId = routeFrameIds.exclude[0].userSelectionId;
        routeFrameIds.requestJSON.exclude.exclude = true;
        routeFrameIds.exclude = excludeFrame;
      } else {
        return routeFrameIds;
      }
    } else {
      routeFrameIds.exclude = [];
      routeFrameIds.requestJSON.exclude = {};
    }
    if (routeFrameIds.include.length > 0) {
      if (typeof (routeFrameIds.include[0]) !== 'string') {
        includeFrame = routeFrameIds.include.map(rec => rec.routeFrameId);
        routeFrameIds.requestJSON.include.userSelectionId = routeFrameIds.include[0].userSelectionId;
        routeFrameIds.requestJSON.include.exclude = false;
        routeFrameIds.exclude = includeFrame;
      } else {
        return routeFrameIds;
      }
    } else {
      routeFrameIds.include = [];
      routeFrameIds.requestJSON.include = {};
    }
    return routeFrameIds;
  }
  /**
   * @description Remove uploaded file
   * @author Alkesh Shah
   * @param {number} index - index of file to remove
   * @memberof ListBase
   */
  removeFile(index: number) {
    if (index != null) {
      this.cloneOfFileList = _.clone(this.listData.listUpload.fileList);
      this.listData.listUpload.fileList.splice(index, 1);
    }
    this.listData.fileNameLabel = this.initialConfig.userBundle[this.UPLOAD_FILE] || this.UPLOAD_FILE_STR;
  }

  /**
   * @description include, exclude file status change
   * @author Alkesh Shah
   * @param {*} event
   * @param {number} i
   * @memberof ListBase
   */
  fileStatusChanged(event, i: number) {
    this.cloneOfFileList[i] = _.clone(this.listData.listUpload.fileList[i]);
    this.listData.listUpload.fileList[i].exclude = event;
  }

  /**
   * @description Log error message occur during file upload
   * @author Alkesh Shah
   * @param {*} data - msg if any
   * @memberof ListBase
   */
  logerror(data) {
    if (data) {
      this.logHelperService.logError(`${this.initialConfig.userBundle[this.MAX_NO_FILES]} ${data}`);
    } else {
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.invalidFile']);
    }
  }

  /**
   * @description File save locally
   * @author Alkesh Shah
   * @param {*} data
   * @returns {boolean}
   * @memberof ListBase
   */
  saveFilelocally(data): boolean {
    if (this.listData.listUpload.fileList.length < 2) {
      for (const file of data.file) {
        if (data.file.length <= 2) {
          if (this.listData.listUpload.fileList.length > 0) {
            this.listData.listUpload.fileList.forEach((obj) => {
              if (obj.name !== file.name && this.listData.listUpload.fileList.indexOf(file) === -1) {
                if (this.listData.listUpload.fileList.length < 2) {
                  this.listData.listUpload.fileList.push(file);
                } else {
                  return false;
                }
              }
            });
          } else {
            this.listData.listUpload.fileList.push(file);
          }
        } else {
          this.logHelperService.logError(`${this.initialConfig.userBundle['workspace.error.list.maxNoFiles']} 2`);
          break;
        }
      }

      for (const file of this.listData.listUpload.fileList) {
        if (!file.hasOwnProperty('exclude')) {
          file.exclude = false;
        }
      }
    } else {
      this.logHelperService.logError(`${this.initialConfig.userBundle['workspace.error.list.maxNoFiles']} 2`);
    }
    return true;
  }

  getSelectedFile(fileIndex, userSelectionId) {
    let file = null;
    if (typeof this.listData.listUpload.fileList[fileIndex] !== 'undefined'
      && typeof this.listData.listUpload.fileList[fileIndex].size !== 'undefined'
      && typeof this.listData.listUpload.fileList[fileIndex].type !== 'undefined') {
      file = this.listData.listUpload.fileList[fileIndex];
      this.convertFileToList(fileIndex, file);
    } else if (userSelectionId) {
      const userSelectionIdParam = (userSelectionId && userSelectionId.length > 0) ? userSelectionId[0] : userSelectionId;
      this.listFilterService.downloadFile(userSelectionIdParam).then((donloadedFile) => {
        file = donloadedFile.body;
        this.convertFileToList(fileIndex, file);
      });
    }
  }

  convertFileToList(fileIndex, file) {
    if (file) {
      const reader = new FileReader();
      let workBook = null;
      let jsonData = null;
      reader.onload = (event) => {
        this.loaderService.show();
        setTimeout(() => {
          const data = reader.result;
          workBook = XLSX.read(data, { type: "binary" });
          jsonData = workBook.SheetNames.reduce((initial, name) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet);
            return initial;
          }, {});
          this.processExcelData(jsonData, fileIndex);
          this.listData.listUpload.fileList.splice(fileIndex, 1);
          this.getAudits();
          this.loaderService.hide();
          this.activeTabIndex = 1;
        }, 1000);
      };
      reader.readAsBinaryString(file);
    }
  }

  processExcelData(excelData, fileIndex) {
    const sheetNames = Object.keys(excelData);
    for (let sheetName of sheetNames) {
      const sheetData = excelData[sheetName];
      if (sheetData && sheetData.length) {
        const validFrameIDColumnNames = [
          "Route Frame ID",
          "Frame ID",
          "Route Frame Code"
        ];
        const columnNames = Object.keys(sheetData[0]);
        let columnName = '';
        for (let name of validFrameIDColumnNames) {
          if (columnNames.includes(name)) {
            columnName = name;
            break;
          }
        }
        if (columnNames.includes(columnName)) {
          sheetData.forEach((row) => {
            if (row[columnName]) {
              if (_.findIndex(this.listData.routeFrameArray, { 'routeFrameId': row[columnName] }) == -1) {
                let sheetFrameData = {
                  routeFrameId: (row[columnName]),
                  exclude: this.listData.listUpload.fileList[fileIndex].exclude,
                }
                this.listData.routeFrameArray.push(sheetFrameData)
              }
            }
          });
        }
      }
    }
  }

  public getAuditsList(pageIndex, pageSize) {
    let resData = _.slice(this.listData.routeFrameArray, pageIndex * pageSize, (pageIndex + 1) * pageSize);
    if (resData.length == 0 && this.listData.routeFrameArray.length < 20 && this.listData.routeFrameArray.length > this.paginationDataModel.length) {
      return _.slice(this.listData.routeFrameArray, this.paginationDataModel.length, pageSize);
    } else if (resData.length == 0 && this.listData.routeFrameArray.length > this.paginationDataModel.length) {
      this.listData.routeFrameArray.forEach((frame) => {
        let index = _.findIndex(this.paginationDataModel, { 'routeFrameId': frame.routeFrameId });
        if (index == -1) {
          resData.push(frame);
        }
      });
      return resData;
    } else {
      return resData;
    }
  }

  public getAudits() {
    try {
      const response: any = this.getAuditsList(this.pageIndex, this.pageSize);
      response.forEach((frame) => {
        let index = _.findIndex(this.paginationDataModel, { 'routeFrameId': frame.routeFrameId });
        if (index == -1) {
          this.paginationDataModel.push(frame);
        }
      });
      // this.paginationDataModel = [...this.paginationDataModel, ...response]
      this.pageIndex += 1;
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @description send files to server
   * @author Alkesh Shah
   * @param {any[]} fileToSend list of files to send
   * @param {*} routeIdToSend route ids
   * @param {number} itemIndex index of current list item
   * @returns {string} - empty string if success else error msg in upload
   * @memberof ListBase
   */
  async sendUploadRequest(fileToSend: any[], routeIdToSend, itemIndex: number): Promise<string> {
    let fs;
    let excludeFlag = false;
    let routeFrameIds = [];
    if (this.fileToSend && this.fileToSend.length > itemIndex ||
      (routeIdToSend && routeIdToSend.length > (itemIndex - fileToSend.length))) {
      if (fileToSend && fileToSend.length > itemIndex) {
        fs = fileToSend[itemIndex];
        excludeFlag = fileToSend[itemIndex].exclude;
        routeFrameIds = null;
      } else if (routeIdToSend && routeIdToSend.length > (itemIndex - fileToSend.length)) {
        fs = null;
        excludeFlag = routeIdToSend[(itemIndex - fileToSend.length)].exclude;
        routeFrameIds = routeIdToSend[(itemIndex - fileToSend.length)].routeFrameId;
      }
      if (fs && fs.userSelectionId) { // Already Uploaded file
        let index = itemIndex;
        return this.sendUploadRequest(fileToSend, routeIdToSend, ++index);
      }
      const resData = await this.listFilterService.upload(fs, excludeFlag, routeFrameIds, this.initialConfig.serviceCalls.FILE_UPLOAD_URL);
      if (resData.status === 'OK') {
        if (fileToSend && fileToSend.length > itemIndex) {
          this.listData.listUpload.fileList[itemIndex]['userSelectionId'] = resData.data.userSelectionId;
          this.listData.listUpload.fileList[itemIndex]['userSelectionName'] = resData.data.userSelectionName;
          this.listData.listUpload.fileList[itemIndex]['exclude'] = fs.exclude;
          this.listData.listUpload.fileList[itemIndex]['validCount'] = resData.data.validCount;
          this.listData.listUpload.fileList[itemIndex]['totalCount'] = resData.data.totalCount;
          this.listData.listUpload.fileList[itemIndex]['hardAllocated'] = fs.hardAllocated;
          this.listData.listUpload.fileList[itemIndex]['swap'] = fs.swap;
          this.listData.fileNameLabel = this.initialConfig.userBundle['geoplanner.text.uploadFile'] || 'Upload File';

        } else {
          for (const i in routeIdToSend[(itemIndex - fileToSend.length)].routeFrameId) {
            if (routeIdToSend[(itemIndex - fileToSend.length)].routeFrameId.hasOwnProperty(i)) {
              if (excludeFlag) {
                this.listData.routeFrameIds['requestJSON']['exclude'] = {
                  'userSelectionId': resData.data.userSelectionId,
                  'exclude': routeIdToSend[(itemIndex - fileToSend.length)].exclude,
                  'validCount': resData.data.validCount,
                  'totalCount': resData.data.totalCount
                };
              } else {
                this.listData.routeFrameIds['requestJSON']['include'] = {
                  'userSelectionId': resData.data.userSelectionId,
                  'exclude': routeIdToSend[(itemIndex - fileToSend.length)].exclude,
                  'validCount': resData.data.validCount,
                  'totalCount': resData.data.totalCount
                };
              }
            }
          }
        }
        let index = itemIndex;
        return this.sendUploadRequest(fileToSend, routeIdToSend, ++index);
      } else {
        return resData.message;
      }
    } else {
      return '';
    }
  }

  /**
   * @description get routeId list need to send in request
   * @author Alkesh Shah
   * @returns {any[]}
   * @memberof ListBase
   */
  getRouteIdToSend(): any[] {
    this.fileToSend = [];

    if (this.listData.listUpload.fileList && this.listData.listUpload.fileList != null && this.listData.listUpload.fileList.length > 0) {
      this.fileToSend = this.listData.listUpload.fileList;
    }
    const fileUploadChange = JSON.stringify(this.listData.listUpload.fileList) !== JSON.stringify(this.cloneOfFileList);
    if (this.listData.routeFrameArray.length > 0) {
      return this.getRouteIDs(fileUploadChange);
    } else {
      this.listData.routeFrameIds = {
        include: [],
        exclude: [],
        requestJSON: {
          exclude: {},
          include: {}
        }
      };

      return [];
    }

  }

  getRouteIDs(fileUploadChange) {
    const routeIdToSend = [];

    if (!this.previousRouteFrameArray || fileUploadChange ||
      (JSON.stringify(this.previousRouteFrameArray) !== JSON.stringify(this.listData.routeFrameArray))) {
      this.listData.routeFrameIds = {
        include: [],
        exclude: [],
        requestJSON: {
          exclude: {},
          include: {}
        }
      };
      this.listData.routeFrameArray.forEach((element) => {
        if (element && element.exclude) {
          this.listData.routeFrameIds.exclude.push(element.routeFrameId);
        } else {
          this.listData.routeFrameIds.include.push(element.routeFrameId);
        }
      });
      if (this.listData.routeFrameIds.include.length > 0) {
        routeIdToSend.push({ exclude: false, routeFrameId: this.listData.routeFrameIds.include });
      }
      if (this.listData.routeFrameIds.exclude.length > 0) {
        routeIdToSend.push({ exclude: true, routeFrameId: this.listData.routeFrameIds.exclude });
      }
    }

    return routeIdToSend;
  }

  /**
   * @description validate the selections
   * @author Alkesh Shah
   * @returns {boolean} - valid selection or not
   * @memberof ListBase
   */
  isValidSelection(routeIdToSend: any[]): boolean {
    let isValid = true;
    if ((!this.fileToSend || (this.fileToSend.length === 0)) && routeIdToSend.length === 0 &&
      (this.listData.listUpload.fileList.length === 0 && this.listData.routeFrameArray.length === 0)) {
      isValid = false;
    }
    return isValid;
  }

  /**
   * @description Download selected file
   * @author Alkesh Shah, Amit Mahida
   * @param {*} userSelectionId - file user selection id to send to backend
   * @memberof ListModalComponent
   */
  downloadListFile(userSelectionId, fileName) {
    // VJ: dt, 28/07/15, Reported by Brandon in flowdock, need to send singular value instead of an array
    // As discussed with Nishit, userSelectionId will be only 1 value for now
    const userSelectionIdParam = (userSelectionId && userSelectionId.length > 0) ? userSelectionId[0] : userSelectionId;
    this.listFilterService.downloadFile(userSelectionIdParam).then((file) => {
      saveAs(file.body, fileName);
    });
  }

  trackByIndex(index, item) {
    return index;
  }

}
