import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, QueryList, OnChanges, SimpleChange, ViewChildren } from '@angular/core';
import { FilterDataService } from './../../core/services/filter-data.service';
import { FilterBarService } from './filter-bar.service';
import { BrickBaseService } from './../../core/services/brick-base.service';
import { Subscription } from 'rxjs';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { InitialConfigModel, FilterBarDataModel } from '../../models';
import { ViewChild } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.css']
})
export class FilterBarComponent implements OnInit, OnDestroy, OnChanges {

  /**
   * input - whether filter area is visible or not
   * @type {boolean}
   * @memberof FilterBarComponent
   */
  @Input() isFilterAreaVisible: boolean;

  /**
   * application initial config object
   * @type {*}@memberof FilterBarComponent
   */
  @Input() initialConfig: InitialConfigModel;

  /**
   * Output event on filter bar expand/collapse
   * @memberof FilterBarComponent
   */
  @Output() filterBarToggle = new EventEmitter<any>();

  /**
   * ngbTooltip element object
   * @type {QueryList<NgbTooltip>}@memberof FilterBarComponent
   */
  @ViewChildren('t') public toolTipObj: QueryList<NgbTooltip>;

  /**
   * filter bar data
   * @memberof FilterBarComponent
   */
  filterBarData: FilterBarDataModel = {};

  /**
   * Filter bar data keys
   * @memberof FilterBarComponent
   */
  filterBarDataKeys: string[] = [];

  /**
   * subscription object for filter bar data
   * @type {Subscription}@memberof FilterBarComponent
   */
  filterBarDataSubscriber: Subscription;

  /**
   * Whether to show expand button or not
   * @type {boolean}@memberof FilterBarComponent
   */
  showExpandBtn = false;

  /**
   * whether filter bar is expanded or not
   * @type {boolean}@memberof FilterBarComponent
   */
  isExpanded = false;

  //readonly FILTER_BOX = '.filterbox_geo';
  @ViewChild('filterboxGeo') FILTER_BOX: any;


  constructor(
    private filterDataService: FilterDataService,
    private filterBarService: FilterBarService,
    public brickBaseService: BrickBaseService
  ) {
  }

  /**
   * checks selected filter container width is greater than main container.
   * to identify whether filter bar expand is needed or not
   * @memberof FilterBarComponent
   */
  checkSelectedFilters(): void {
    const parentHeight = parseInt(this.FILTER_BOX.nativeElement.offsetHeight, 10);
    const childHeight = parseInt(this.FILTER_BOX.nativeElement.children[0].offsetHeight, 10);
    if (childHeight > parentHeight) {
      this.showExpandBtn = true;
    } else {
      if (childHeight !== 64) {
        this.showExpandBtn = false;
        this.isExpanded = false;
        this.filterBarToggle.emit(this.isExpanded);
        this.FILTER_BOX.nativeElement.style.height = '32px';
      }
    }
  }

  /**
   * Angular lifecycle hook - will be Called once after component Initialize
   * @memberof FilterAreaComponent
   */
  ngOnInit() {
    this.filterBarDataSubscriber = this.filterBarService.filterBar$.subscribe((data: FilterBarDataModel) => {
      this.filterBarData = data;
      this.filterBarDataKeys = Object.keys(this.filterBarData);
      setTimeout(() => this.checkSelectedFilters(), 100);
    });
  }

  /**
   * Angular lifecycle hook - will be Called after any input variable changes
   * @memberof FilterAreaComponent
   */
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes.isFilterAreaVisible && !changes.isFilterAreaVisible.firstChange
      && (changes.isFilterAreaVisible.currentValue !== changes.isFilterAreaVisible.previousValue)) {
      setTimeout(() => this.checkSelectedFilters(), 500);
    }
  }

  /**
   * expand filter bar
   * @memberof FilterBarComponent
   */
  expandFilters(): void {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      this.FILTER_BOX.nativeElement.style.height = '74px';   
    } else {
      this.FILTER_BOX.nativeElement.style.height = '32px';  
    }
    this.filterBarToggle.emit(this.isExpanded);
  }

  /**
   * clear filter selected data for provided bric id
   * @param {any} bricID
   * @memberof FilterBarComponent
   */
  clearFilter(bricID: number): void {
    switch (bricID) {
      case this.brickBaseService.brickID.Media:
        this.filterDataService.setMedia({});
        break;
      case this.brickBaseService.brickID.Tag:
        this.filterDataService.setTags({});
        break;
      case this.brickBaseService.brickID.Environment:
        this.filterDataService.setChannel([]);
        break;
      case this.brickBaseService.brickID.Format:
        this.filterDataService.setFormat({});
        break;
      case this.brickBaseService.brickID.Location:
        this.filterDataService.setLocation({}, null);
        break;
      case this.brickBaseService.brickID.Audience:
        this.filterDataService.setRating({});
        break;
      case this.brickBaseService.brickID.SOT:
        this.filterDataService.setShare({});
        break;
      case this.brickBaseService.brickID.Proximity:
        this.filterDataService.setProximity({});
        break;
      case this.brickBaseService.brickID.Incharge:
        this.filterDataService.setIncharge({});
        break;
      case this.brickBaseService.brickID.List:
        this.filterDataService.setList({});
        break;
      case this.brickBaseService.brickID.FrameIllumination:
        this.filterDataService.setFrame({});
        break;
      case this.brickBaseService.brickID.Facing:
        this.filterDataService.setFacing({});
        break;
    }
    this.checkSelectedFilters();
  }

  /**
   * Angular lifecycle hook - will be Called once  just before Angular destroys the directive/component
   * @memberof FilterAreaComponent
   */
  ngOnDestroy() {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.filterBarDataSubscriber) {
      this.filterBarDataSubscriber.unsubscribe();
    }
  }

  /**
   * open filter detail component for provided bric id
   * @param {any} bricID
   * @memberof FilterBarComponent
   */
  openFilterDetail(bricID: number): void {
    const allowedBricIds: (number | string)[] = [
      this.brickBaseService.brickID.Tag,
      this.brickBaseService.brickID.Format,
      this.brickBaseService.brickID.Location,
      this.brickBaseService.brickID.Audience,
      this.brickBaseService.brickID.SOT,
      this.brickBaseService.brickID.Proximity,
      this.brickBaseService.brickID.List,
      this.brickBaseService.brickID.FrameIllumination,
      this.brickBaseService.brickID.Facing
    ];

    if (allowedBricIds.indexOf(bricID) > -1) {
      this.filterBarService.openFilterDetail(bricID);
      this.toolTipObj.forEach((tooltip) => { tooltip.close(); });
    }
  }

  trackByItem(index, item) {
    return item;
  }
}
