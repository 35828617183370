import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FurnitureStatus } from '../status.enum';
import { GeoMapService } from '../geo-map.service';
import { DataShareService } from '../../core/services/data-share.service';
import { ICellRendererParams } from 'ag-grid-community';
import { InitialConfigModel } from '../../models';

@Component({
  selector: 'app-ag-furniture',
  templateUrl: './ag-furniture.component.html',
  styleUrls: ['./ag-furniture.component.css']
})
export class AgFurnitureComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;
  public furnitureStatus = FurnitureStatus;
  public assetStatus: FurnitureStatus;
  public isFurnitureLocked = false;
  public rowIndex: number;
  public isViewCartOnMap = false;
    /**
     * application initial config file
     * @private
     * @type {*}@memberof AgFurnitureComponent
     */
  public initialConfig: InitialConfigModel;
  public shortenMarker: boolean;

  constructor(private geoMapService: GeoMapService, private dataShareService: DataShareService) {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.shortenMarker = this.geoMapService.shortenMarker;
    if (!this.initialConfig.userBundle['geoplanner.text.copyAddress']) {
      this.initialConfig.userBundle['geoplanner.text.copyAddress'] = 'Click here to copy this address';
    }
  }

    // called on init
  agInit(params: ICellRendererParams): void {
    this.isViewCartOnMap = params.context.componentParent.isViewCartOnMap;
    this.setValues(params);
  }

    // called when the cell is refreshed
  refresh(params: ICellRendererParams): boolean {
    this.setValues(params);
    return true;
  }

  setValues(params: ICellRendererParams): void {
    this.params = params;
        // during view cart on map no need to check asset status
        // as in this view user is allowed to make selection
    if (this.isViewCartOnMap) {
      this.assetStatus = this.furnitureStatus.available;
    } else {
      this.assetStatus = this.geoMapService.getFurnitureAvailabilityStatus(this.params.data);
    }
    this.isFurnitureLocked = this.geoMapService.isFurnitureLocked(this.params.data, this.isViewCartOnMap);
    this.rowIndex = this.params.rowIndex;
  }

  onSelectChange(): void {
    this.params.context.componentParent.onSelectFurnitureChange(this.params.data);
  }

  onImageClick(): void {
    this.params.context.componentParent.onShowFurnitureImages(this.params.data);
  }

  copyAddress(address: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerText = address;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
