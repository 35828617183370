import { DataShareService } from '../../core/services/data-share.service';
import { SOTBricSelectedDataModel, InitialConfigModel } from '../../models';
import { isNumber } from '../../core/utils/isNumber';

export class SotBase {
  /**
   * It contians initial config data.
   */
  public initialConfig: InitialConfigModel;

  /**
   * It contains sot filter data.
   */
  public shareOfTime: SOTBricSelectedDataModel;
  public previousValues: SOTBricSelectedDataModel;

  /**
   * Creates an instance of SotBase.
   * @author Alkesh Shah
   * @param {DataShareService} dataShareService
   * @memberof SotBase
   */
  constructor(
    private dataShareService: DataShareService,
  ) {
    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  /**
   * @description initialize the data
   * @author Alkesh Shah
   * @param {SOTBricSelectedDataModel} selectedVal
   * @memberof SotBase
   */
  init(selectedVal: SOTBricSelectedDataModel) {
    this.shareOfTime = selectedVal;
  }

  /**
   * @description calculate dummySot in ase sot Frequency is enabled
   * @author Alkesh Shah
   * @memberof SotBase
   */
  calculateSOT(): void {
    if (this.initialConfig.uiControl.sotFrequencyEnabled) {
      if (this.shareOfTime.sot === '' || this.shareOfTime.frequency === '') {
        this.shareOfTime.dummySot = '';
      }

      if (isNumber(this.shareOfTime.sot) && isNumber(this.shareOfTime.frequency) && this.shareOfTime.frequency > 0) {
        this.shareOfTime.dummySot = parseFloat(((1 / Number(this.shareOfTime.frequency)) * Number(this.shareOfTime.sot)).toFixed(2));
      }

      if (this.shareOfTime.sot === 'Mixed') {
        this.shareOfTime.dummySot = 'Mixed';
      }
    }
  }
}
