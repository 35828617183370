import { InitialConfigModel, CampaignTypeGroup, CampaignType } from '../../../models/initial-config.model';
import * as _ from 'lodash';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { of } from 'rxjs';
import { PricingFactor, ProductCatalogue } from '../../../models/productCatalogue';
import { DataShareService } from '../../../core/services/data-share.service';
import { ProductCatalogueGroup, ProductCatalogueType } from '../../../models/workspace/product-catalogue';
import { LogHelperService } from '../../../core/services';

export class AccessControl {
  name = '';
  organisationTypeId = 0;
  url = '';
  selected = [];
  displayBy = '';
  identifyBy = '';
  usePredifinedList ?= [];
  index ?= 0;

}

export class AddProductBase {

  /**
   * inital config Model
   *
   * @type {InitialConfigModel}
   * @memberof AdvanceAvailabilityComponent
   */
  public initialConfig: InitialConfigModel;

  /**
   * set brick color value
   *
   * @memberof AdvanceAvailabilityComponent
   */
  brickBgColor = '#58002e';

  /**
   * @description product Catalogue Object
   * @memberof AddProductComponent
   */
  productCatalogue: ProductCatalogue = new ProductCatalogue();

  /**
   * @description Access Controls Tab
   * @memberof AddProductComponent
   */
  accessControls: AccessControl[];

  /**
   * @description maintain Type Selection
   * @memberof AddProductComponent
   */
  typeSelection = 0;

  /**
   * @description product Cataloue Group List
   * @memberof AddProductComponent
   */
  productCatalogueGroup: ProductCatalogueGroup[];

  /**
   * @description Maintain Product Catalogue Type
   * @memberof AddProductComponent
   */
  productCatalogueType: ProductCatalogueType[];

  /**
   * @description
   * @memberof AddProductComponent
   */
  selectedTab: AccessControl = new AccessControl();

  public campaignTypeGroup: CampaignTypeGroup[];
  public campaignTypes: CampaignType[] = [];
  constructor(
    public lookupViewService: LookupViewService,
    public dataShareService: DataShareService,
    public logHelperService: LogHelperService,
  ) {
  }

  ngInit() {
    this.productCatalogueGroup = this.initialConfig.productCatalogueHolder.productCatalogueGroup.filter(e => e.inUse);
    this.productCatalogueType = this.initialConfig.productCatalogueHolder.productCatalogueType.filter(e => e.inUse);
    this.populateCampaignTypes();
    this.accessControls = [{
      name: this.initialConfig.userBundle['common.advertiserName'],
      organisationTypeId: 1,
      url: this.dataShareService.getServiceCallUrlByKey('LOOKUP_ADVERTISER_URL'),
      selected: [],
      displayBy: 'organisationName',
      identifyBy: 'organisationId',
    }, {
      name: this.initialConfig.userBundle['common.agencyName'],
      organisationTypeId: 3,
      url: this.dataShareService.getServiceCallUrlByKey('LOOKUP_AGENCY_URL'),
      selected: [],
      displayBy: 'organisationName',
      identifyBy: 'organisationId',
    }, {
      name: this.initialConfig.userBundle['common.specialistName'],
      organisationTypeId: 2,
      url: this.dataShareService.getServiceCallUrlByKey('LOOKUP_SPECIALIST_URL'),
      selected: [],
      displayBy: 'organisationName',
      identifyBy: 'organisationId',
    },
    {
      name: this.initialConfig.userBundle['common.campaignType'],
      organisationTypeId: 0,
      url: 'campaignTypeLookUp',
      selected: [],
      displayBy: 'campaignTypeName',
      identifyBy: 'campaignTypeId',
      usePredifinedList: this.campaignTypes,

    }
    ];
    this.selectTab(this.accessControls[0], 0);
    this.initializePricingFactors();

  }
  /**
   * @description Method to get all campaign types from the campaing group object
   * @author Shreni Shah
   * @date 2020-01-30
   * @memberof AddProductBase
   */
  populateCampaignTypes() {
    this.campaignTypeGroup = this.dataShareService.getInitialConfigByKey('campaignTypeGroup') || [];
    this.campaignTypeGroup.forEach((element) => {
      if (element.campaignType) {
        this.campaignTypes = this.campaignTypes.concat(element.campaignType);
      }
    });
  }

  /**
   * @description mapping productcatalogue restrictions object to accessControls object
   * @author Kishan Patel
   * @param {*} data
   * @returns
   * @memberof AddProductComponent
   */
  createAccessControlsMapping(accessControl) {
    const data = accessControl.selected;

    const selectedData = [];
    if (!_.isUndefined(data)) {
      for (const element of data) {
        selectedData.push({
          [accessControl.identifyBy]: element[accessControl.identifyBy],
          [accessControl.displayBy]: element[accessControl.displayBy]
        });
      }
    }
    return selectedData;
  }

  /**
   * Load tags data for load Lookup using service call
   * @param query queryParameter for http call
   * @return responsed route data
   */
  loadLookUpData = (query: string) => {
    const url = this.selectedTab.url;
    if (query) {
      if (url === 'campaignTypeLookUp') {
        return of(this.campaignTypes);
      } else {
        const param: any = {
          action: 'lookupOrganisation',
          data: { organisationName: query, organisationTypeId: this.selectedTab.organisationTypeId }
        };
        param.data = JSON.stringify(param.data);
        return this.lookupViewService.getData(this.selectedTab.url, param)
          .map((data: any) => {
            const returnedData = [];
            if (!_.isUndefined(data.organisation) && data.organisation.length > 0) {
              _.each(data.organisation, (item) => {
                returnedData.push({
                  organisationId: item.organisationId,
                  organisationName: item.organisationName,
                  source: item
                });
              });
            }
            return returnedData;
          });
      }
    } else {
      return of([]);
    }
  }

  /**
   * @description remove tag data from selected list
   * @memberof AddProductComponent
   */
  removeTagsData = function(index) {
    if (index != null) {
      this.selected.selected.splice(index, 1);
    }
  };

  /**
   * @description change tab selection
   * @memberof AddProductComponent
   */
  selectTab = function(tab, index) {
    this.selectedTab = tab;
    this.selectedTab.index = index;
  };

  validationFn() {
    if ((!this.productCatalogue.productCatalogueName || this.productCatalogue.productCatalogueName.trim() === '') ||
      (!this.productCatalogue.productCatalogueTypeId || this.productCatalogue.productCatalogueTypeId === 0) ||
      (!this.productCatalogue.productCatalogueGroupId || this.productCatalogue.productCatalogueGroupId === 0) ||
      (!this.productCatalogue.productCatalogueDescription || this.productCatalogue.productCatalogueDescription === '')) {
      this.logHelperService.logError(this.initialConfig.userBundle['pcm.validation.addproduct'] || 'Please select all the required fields to save Product.');
      return false;
    }
    if (this.initialConfig.uiControl.pcmPriceConfigurationEnabled && this.productCatalogue.priceConfiguration && this.productCatalogue.priceConfiguration.fixedCPM &&
      (_.isUndefined(this.productCatalogue.priceConfiguration.fixedCPMValue) || Number(this.productCatalogue.priceConfiguration.fixedCPMValue) === 0)) {
      this.logHelperService.logError(this.initialConfig.userBundle['pcm.validation.addproduct.pricingConfiguration'] || 'Please add mandatory pricing details');
      return false;
    }
    if (this.initialConfig.uiControl.pcmPriceConfigurationEnabled &&
      this.productCatalogue.priceConfiguration &&
      this.productCatalogue.priceConfiguration.factors &&
      this.productCatalogue.priceConfiguration.factors.length) {
      let invalidFactor = false;
      this.productCatalogue.priceConfiguration.factors.forEach((factor) => {
        if (factor.min > factor.max) {
          invalidFactor = true;
        }
      });
      if (invalidFactor) {
        this.logHelperService.logError(this.initialConfig.userBundle['pcm.validation.addproduct.pricingConfiguration.factor'] || 'One or multiple Min values are larger than Max values.');
        return false;
      }

    }
    return true;
  }

  initializePricingFactors() {
    if (this.initialConfig.uiControl.pcmPriceConfigurationEnabled) {
      this.productCatalogue.priceConfiguration.factors = [];
      this.initialConfig.uiControl.pcmPricingFactors.forEach((element) => {
        const pfactor = new PricingFactor();
        pfactor.factor = element;
        pfactor.min = null;
        pfactor.max = null;
        this.productCatalogue.priceConfiguration.factors.push(pfactor);
      });
    }
  }
}
