import { Component } from '@angular/core';
import { AgCustomRowUtil } from '../../utils/ag-custom-row-util';
import { CellRendererBaseComponent } from '../cell-renderer-base/cell-renderer-base.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-ag-select-cell-renderer',
  templateUrl: './ag-select-cell-renderer.component.html',
  styleUrls: ['./ag-select-cell-renderer.component.css']
})
export class AgSelectCellRendererComponent extends CellRendererBaseComponent {
  dataArray: any[] = [];
  valKey = '';
  textKey = '';
  isValid = true;

  constructor() {
    super();
  }

  public getParentRowText(): string {
    if (this.params.calcParentCellValue) {
      return this.params.calcParentCellValue(this.params);
    }
    if (this.groupingField) {
      const rowData = AgCustomRowUtil.getRowData(this.params.api);
      const childRows = AgCustomRowUtil.getChildRows(rowData, this.groupingField, this.params.data[this.groupingField]);
      let value = '';
      childRows.forEach((row) => {
        const fieldValue = row[this.params.column.colId];
        if (value !== '*') {
          if (!value) {
            value = fieldValue;
          } else if (value !== fieldValue) {
            value = '*';
          }
        }
      });
      return value;
    } else {
      return this.params.data[this.params.column.colId];
    }
  }

  public getChildRowText(): string {
    return this.params.data[this.params.column.colId];
  }

  public getFirstPinRowText(): string {
    return this.params.data[this.params.column.colId];
  }

  public getSecondPinRowText(): string {
    return this.params.data[this.params.column.colId];
  }

  public formatText(displayText): string {
    if (this.params.isValid) {
      this.isValid = this.params.isValid(this.params.data, this.params.column.colId);
    }
    this.dataArray = this.params.dataArr(this.params.data);
    this.valKey = this.params.valKey;
    this.textKey = this.params.textKey;
    const text = this.mapArrayData(displayText);
    this.params.data[`${this.params.column.colId}_sort`] = text;
    return text;
  }

  mapArrayData(displayText: string): string {
    let returnValue = '';
    if (displayText === '' || displayText === null) {
      returnValue = '';
    } else if (displayText === '*') {
      returnValue = '*';
    } else if (this.dataArray && _.isArray(this.dataArray)) {
      for (const data of this.dataArray) {
        if (String(data[this.valKey]) === String(displayText) && data[this.textKey]) {
          returnValue = data[this.textKey];
          break;
        }
      }
    }
    return returnValue;
  }
}
