import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SbSelectComponent } from './sb-select.component';
import { SbSelectService } from './sb-select.service';
import { HighlightPipe } from './core/select-pipes';
import { OffClickDirective } from './core/off-click';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [SbSelectComponent, HighlightPipe, OffClickDirective],
  exports: [SbSelectComponent],
  providers: [SbSelectService]
})
export class SbSelectModule { }
