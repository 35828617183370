import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, CommercialConfig, HistoryROC } from '../../commercial.model';
import { DataShareService } from '../../../core/services/data-share.service';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { MaskService, StateService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { CampaignDetailsBase } from '../campaign-details-base';
import { SystemFlags } from '../../../models/system-flags';
import { Campaign, SbSelectedBrand } from '../../../models';
import { NumberMaskModel } from '../../../models/imask';
import { CampaignGroupTypeList } from '../../../core/enum';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerHelper } from '../../../core/components/ngb-datepicker/ngb-datepicker-helper';

@Component({
  selector: 'app-campaign-details-norway',
  templateUrl: './campaign-details-norway.component.html',
  styleUrls: ['./campaign-details-norway.component.css']
})
export class CampaignDetailsNorwayComponent extends CampaignDetailsBase implements OnInit {
  @Input() campaignDetails: CampaignDetails;
  @Input() commercialConfig: CommercialConfig;
  @Input() historyROC: HistoryROC;
  @Output() manuallyCPTUpdated: EventEmitter<any> = new EventEmitter();
  @Output() datePickerToggle: EventEmitter<any> = new EventEmitter();

  maskService: MaskService = new MaskService();
  searchPageObject: Campaign;
  campaignGroupTypeList = CampaignGroupTypeList;

  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof CampaignDetailsNorwayComponent
   */
  currencyInputMask: NumberMaskModel;

  bookingLineDataSubscription: Subscription;

  /**
 * @description set readOnly
 * @memberof CampaignDetailsNorwayComponent
 */
  public readOnly = SystemFlags.readOnly;

  public customerId = null;

  isCampaignGrossValueHidden = false;
  isCampaignNetValueHidden = false;
  isCampaignCPTHidden = false;
  isPmpDealIdHidden = false;
  minStartDate: NgbDateStruct;
  maxEndDate: NgbDateStruct;
  minStartForEndDate: NgbDateStruct;
  /**
   * @description specific startDate day properties cached object for ngbDatePicker day template
   * @memberof RangeComponent
   */
   startDayObjCache = {};

  isFloatingStartDateValid = true;
  isFloatingEndDateValid = true;

  constructor(
    commercialService: CommercialService,
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    stateService: StateService
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService

    );
    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe((value) => {
      if (value['rowIndex'] >= 0 && value['columnName']) {
        this.calculateCPT(value);
      }
      this.onBookingLineChange();
    });
  }

  ngOnDestroy() {
    this.bookingLineDataSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.currencyInputMask = this.maskService.currencyInputMask({ max: 999999999999999999999, padFractionalZeros: true });
    this.minStartDate = NgbDatepickerHelper.convertDateToDateStruct(new Date());
    this.maxEndDate = NgbDatepickerHelper.convertDateToDateStruct(new Date(this.campaignDetails.campaignStartDate));
    if (new Date() > new Date(this.campaignDetails.campaignStartDate)) {
      this.minStartDate = this.maxEndDate;
    }

    this.minStartForEndDate = NgbDatepickerHelper.convertDateToDateStruct(new Date(this.campaignDetails.campaignEndDate));
    super.init(this.campaignDetails);
    if (this.campaignDetails.floatingStartDate) {
      const floatingStartDate = new Date(this.campaignDetails.floatingStartDate as Date);
      this.campaignDetails.floatingStartDate = NgbDatepickerHelper.convertDateToDateStruct(floatingStartDate);
    }
    if (this.campaignDetails.floatingEndDate) {
      const floatingEndDate = new Date(this.campaignDetails.floatingEndDate as Date);
      this.campaignDetails.floatingEndDate = NgbDatepickerHelper.convertDateToDateStruct(floatingEndDate);
    }

    this.validateFloatingDates(); // SM-10417
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    this.enableSubAgency();
    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);
    this.searchPageObject = this.stateService.getCampaign();
    this.isCampaignGrossValueHidden = this.commercialHiddenElements('commercial.campaignGrossValue');
    this.isCampaignNetValueHidden = this.commercialHiddenElements('commercial.campaignNetValue');
    this.isCampaignCPTHidden = this.commercialHiddenElements('commercial.booking.campaignCPT');
    this.isPmpDealIdHidden = this.commercialHiddenElements('commercial.booking.pmpDealId');
    if (this.campaignDetails.calculateAtUI) {
      this.manageGrossMediaValueWithoutIndex();
    }
    this.setLookupConfig();
    this.setCustomerId();
  }

  /**
   * To validate the selected floating dates are within the valida range or not as per SM-10109
   */
  validateFloatingDates() {
    this.isFloatingStartDateValid = true;
    if (this.campaignDetails.floatingStartDate) {
      const floatingStartDate: NgbDateStruct = <NgbDateStruct>this.campaignDetails.floatingStartDate;
      if (NgbDatepickerHelper.convertDateStructToDate(floatingStartDate) > new Date(this.campaignDetails.campaignStartDate)) {
        this.isFloatingStartDateValid = false;
      }
    }
    this.isFloatingEndDateValid = true;
    if (this.campaignDetails.floatingEndDate) {
      const floatingEndDate: NgbDateStruct = <NgbDateStruct>this.campaignDetails.floatingEndDate;
      if (NgbDatepickerHelper.convertDateStructToDate(floatingEndDate) < new Date(this.campaignDetails.campaignEndDate)) {
        this.isFloatingEndDateValid = false;
      }
    }
  }

  onAdvertiserNameSelected(value): void {
    super.onAdvertiserNameSelected(value);
    this.setCustomerId();
  }

  onBrandSelected(value: SbSelectedBrand): void {
    super.onBrandSelected(value);
    this.setCustomerId();
  }

  // Functions Related to Specialist//
  onSpecialistNameSelected(value: any): void {
    if (this.campaignDetails.specialist) {
      super.onSpecialistChange(value);
      this.manageGrossMediaValueWithoutIndex(); // This function has to be used in case if the booking grid is used;
      this.commercialService.notifyGrid(this.campaignDetails); // This is used when booking Details is used
    }
  }

  removeSelectedAdvertiserName(event) {
    super.removeSelectedAdvertiserName(event);
    this.setCustomerId();
  }

  removeSelectedBrand(event: any) {
    super.removeSelectedBrand(event);
    this.setCustomerId();
  }

  removeSelectedSpecialistName() {
    super.onSpecialistChange();
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  onSpecialistPercentageChange() {
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  // Functions related to Agencies//
  onAgencyNameSelected(value: any): void {
    if (this.campaignDetails.agency) {
      super.onAgencyChange(value);
      this.enableSubAgency();
      this.commercialService.notifyGrid(this.campaignDetails);
    }
  }
  removeSelectedAgencyName(): void {
    super.onAgencyChange();
    this.enableSubAgency();
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  onAgencyPercentageChange() {
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  onBookingLineChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  assignedToChange() {
    this.campaignDetailsService.populateAgencyPercentage(this.campaignDetails);
    this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
    this.enableSubAgency();
    this.setActiveAssignedTo();
  }

  setCustomerId() {
    if (this.campaignDetails && this.campaignDetails.advertiser && this.campaignDetails.advertiser.organisationTypeId === 1) {
      this.customerId = this.campaignDetails.advertiser.advertiserCode;
    } else {
      this.customerId = null;
    }
  }

  onAverageCPTChanged() {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT);
    this.onBookingLineChange();
    if (this.uiControl.enableUserDefinedCPMPricingCalculation) {
      this.manuallyCPTUpdated.emit();
    }
  }

  /**
   * @description calculate CPT from gross media value or pmp discount change
   * @author Amit Mahida
   * @param {{ rowIndex?: number, columnName?: string }} value
   * @memberof CampaignDetailsNorwayComponent
   */
  calculateCPT(value: { rowIndex?: number, columnName?: string }) {
    if (value.columnName === 'grossMediaValue') {
      this.campaignDetailsService.calculateCPT(
        this.campaignDetails, value.rowIndex,
        undefined,
        this.campaignDetails.bookingList[value.rowIndex].grossMediaValue
      );
    } else if (value.columnName === 'pmpdiscount') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex);
    }
  }

  cleanFloatingDate = (dateType: string, otherDatePicker: NgbInputDatepicker): boolean => {
    otherDatePicker.close();
    if (dateType === 'start') {
      this.campaignDetails.floatingStartDate = undefined;
    } else {
      this.campaignDetails.floatingEndDate = undefined;
    }
    return false;
  }

  /**
   * @description get the specific dates property
   * @author Kishan Patel
   * @param {NgbDateStruct} date current day
   * @param {string} key property key to retrive
   * @returns {boolean}
   * @memberof CampaignDetailsBelgiumComponent
   */
   getStartDayProp(date: NgbDateStruct, key: string): boolean {
    const daystring = this.cacheStartDateObj(date, this.startDayObjCache);
    return this.startDayObjCache[daystring][key];
  }
}
