import { Component, OnInit } from '@angular/core';
import { CampaignDetailsService } from '../campaign-details/campaign-details.service';
import { CampaignDetails, HistoryROC } from '../commercial.model';
import { SystemFlags } from '../../models';
import { DataShareService, LogHelperService, StateService } from '../../core/services';
import { CommercialService } from '../../core/services/commercial.service';
import { CommercialButtonService } from '../commercial-buttons/commercial-buttons.service';
import { CampaignStatus } from '../../core/enum';
import { DatePipe } from '@angular/common';
import { GLOBAL } from '../../core/utils/app.constant';
import { CommercialBase } from '../commercial-base';
import { RequiredCampaignFields, PopulateCommercialFields, PopulateBookingDetailFields } from '../../models/RequiredFields';

@Component({
  selector: 'app-commercial-germany',
  templateUrl: './commercial-germany.component.html',
  styleUrls: ['./commercial-germany.component.css']
})
export class CommercialGermanyComponent extends CommercialBase implements OnInit {
  systemFlags = SystemFlags;

  /**
   * @description Commercial data
   * @type {CampaignDetails}
   * @memberof CommercialGermanyComponent
   */
  public commercialData: CampaignDetails;

  /**
   * @description Accordian for campaign details
   * @type {boolean}
   * @memberof CommercialGermanyComponent
   */
  hideCampaignDetails = false;

  /**
   * @description show hide booking Grid
   * @type {boolean}
   * @memberof CommercialUkComponent
   */
  hideBookingGrid: boolean;
  /**
   * @description Accordian for booking details
   * @type {boolean}
   * @memberof CommercialGermanyComponent
   */
  hideBookingDetails = false;

  /**
   * @description Accordian for discount grid
   * @type {boolean}
   * @memberof CommercialGermanyComponent
   */
  hideDiscountGrid = false;
  /**
   * @description set bookingStatusList
   * @memberof CommercialGermanyComponent
   */
  bookingStatusList = GLOBAL.bookingStatusList;

  brandData: any[];

  /**
   * @description this will show the history of campaign updated in case of reason/cancel for change
   * @memberof CommercialGermanyComponent
   */
  historyROC = new HistoryROC();

  constructor(
    stateService: StateService,
    campaignDetailsService: CampaignDetailsService,
    dataShareService: DataShareService,
    commercialService: CommercialService,
    commercialButtonService: CommercialButtonService,
    logHelperService: LogHelperService,
    datePipe: DatePipe
  ) {
    super(stateService, logHelperService, commercialButtonService, dataShareService, commercialService, datePipe, campaignDetailsService);
  }

  ngOnInit() {
    this.restoreCommercialData();
    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  /**
   * @description Get commercial data
   * @author Shivani Patel
   * @memberof CommercialGermanyComponent
   */
  restoreCommercialData() {
    this.commercialConfig.user = this.dataShareService.getUserModel();
    this.getCommercialDataFromService();
  }

  /**
   * @description Get commercial data from service
   * @author Shivani Patel
   * @memberof CommercialGermanyComponent
   */
  getCommercialDataFromService(isManual = false) {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
      brickProcessed: SystemFlags.brickProcessed === true && SystemFlags.isLoadedCampaign ? true : false
    };
    SystemFlags.brickProcessed = false;
    this.commercialService.loadCommercialCampaignDetails(param).subscribe((data) => {
      if (data.status === 'OK') {
        this.commercialData = data.data;
        if (isManual) {
          this.dataShareService.updateBookingList(this.commercialData.bookingList);
        }
        this.commercialConfig.campaignStatus = data.data.campaignStatusId;
        this.commercialConfig.bookingStatus = data.data.bookingStatus;
        this.commercialConfig.uiControl = data.data.uiControl;
        this.commercialData.bookingStatusName = this.campaignDetailsService.getStatusNameFromId(data.data.campaignStatusId);
        this.campaignDetails = this.campaignDetailsService.populateCampaignDetails(
          this.commercialData,
          this.initialConfig,
          this.dataShareService.getInitialConfigByKey('assignedTo')
        );
        if (isManual) {
          this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT);
        } else {
          this.campaignDetailsService.calculateCPT(this.campaignDetails);
        }
        this.historyROC = this.campaignDetailsService.populateHistoryROC(this.historyROC, data.data.auditReasonsHistoryMap, this.commercialConfig.uiControl.historyROC);
        // Send Brand,if Advertiser and Brand data are set in the search Page //
        if (this.commercialData.advertiser) {
          if (this.commercialData.advertiser.brands) {
            this.brandData = [];
            this.commercialData.advertiser.brands.forEach((brand) => {
              this.brandData.push(brand);
            });
          }
        } else {
          this.campaignDetails.discounts = this.commercialService.clearBrandInDiscounts(this.campaignDetails.discounts);
        }
      }
    });
  }

  /**
   * @description Returns true if any discount row is locked
   * @author Shivani Patel
   * @returns {boolean}
   * @memberof CommercialGermanyComponent
   */
  isAnyDiscountRowLocked(): boolean {
    return this.campaignDetails.discounts.filter(value => value.locked).length > 0;
  }

  /**
   * @description This is output event from commercialbutton when click on update/option/book/cancel button click
   * @author Shivani Patel
   * @param {number} status
   * @memberof CommercialGermanyComponent
   */
  onBookCampaign(status: number) {
    // SD : SM-116 : Date : 15th Feb 2018
    if (this.isAnyDiscountRowLocked() && status === CampaignStatus.cancel) {
      this.logHelperService.logError(this.initialConfig.userBundle['commercial.booking.cancelError']);
      return;
    }
    if (this.validateCommercialCampaign(status)) {
      status = status || this.campaignDetails.campaignStatusId;

      if (this.isROC(status)) {
        this.handleROC(status, this.createRequestJSONForCommercial);
      } else {
        this.validateServiceRequest(status, this.createRequestJSONForCommercial);
      }
    }
  }

  /**
   * @description Commercial form validity
   * @author Shivani Patel
   * @returns {boolean}
   * @memberof CommercialGermanyComponent
   */
  validateCommercialCampaign = (status): boolean => {
    let message = null;
    const discounts = this.campaignDetails.discounts;
    if (discounts && discounts.length) {
      for (const discount of discounts) {
        if (!discount.bookingStatusId || discount.bookingStatusId <= 1) {
          message = this.initialConfig.userBundle['commercial.error.book.dg.status.validation'];
        }
      }
    }
    if (!message) {
      const requiredFields: RequiredCampaignFields = new RequiredCampaignFields();
      requiredFields.campaignStartDate = false;
      message = this.validateCommonFields(requiredFields);
      if (!message && this.campaignDetails.bookingList) {
        this.campaignDetails.bookingList.forEach((obj) => {
          if (!message && obj.containsDigital) {
            message = this.validateMediaLength(obj, status, true);
          }
        });
      }
    }
    return this.isValid(message);
  }

  /**
   * @description create requestJSON for passing to backend
   * @author Shivani Patel
   * @param {number} status
   * @returns
   * @memberof CommercialGermanyComponent
   */
  createRequestJSONForCommercial = (status: number) => {
    let campaignDetails = this.campaignDetails;
    if (!campaignDetails) {
      campaignDetails = this.stateService.getCommercialObject('campaignDetails');
    }
    const populate: PopulateCommercialFields = this.getCommercialFields();
    const temp = this.populateBookCommercialCampaignParam(status, campaignDetails, populate);
    const populateBooking: PopulateBookingDetailFields = this.getBookingDetailFields();
    temp.bookingList = this.populateBookingDetailsServiceObject(campaignDetails, 'bookingList', populateBooking);
    temp.discounts = campaignDetails.discounts;
    return temp;
  }

  /**
   * To override the commercial fields
   * This override fields are used to override the default value
   * @memberof CommercialGermanyComponent
   * @author Dhaval Patel
   */
  getCommercialFields() {
    const populate: PopulateCommercialFields = super.getCommercialFields();
    populate.setPCFields();
    populate.campaignReference = false;
    populate.grossMediaValue = false;
    populate.netMediaValue = false;
    return populate;
  }

  /**
   * To override the booking detail fields
   * This override fields are used to override the default value
   * @memberof CommercialGermanyComponent
   * @author Dhaval Patel
   */
  getBookingDetailFields() {
    const populate: PopulateBookingDetailFields = super.getBookingDetailFields();
    populate.setPICFields();
    populate.setCCBIBFields();
    populate.setPOORIFields();
    populate.financeAmount = true;
    populate.setPMDBFields();
    return populate;
  }

  showHideBookingGrid() {
    this.hideBookingGrid = !this.hideBookingGrid;
  }

  async manuallyCPTUpdated() {
    const campaigndata = this.createRequestJSONForCommercial(this.campaignDetails.campaignStatusId);
    await this.updateCommercialInfoForCPT(campaigndata);
    this.getCommercialDataFromService(true);
  }

}
