<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [readOnlyModal]="readOnlyModal"
    [titleBackgroundColor]="resolveObject.brick.color" (closed)="onModalClosed($event)"
    (saved)="onModalSaved($event)" [showCloseButton]="showCloseButton">
    <!-- {{userBundle['workspace.incharge.bric.range.startDate']}} -->
    <div [ngClass]="{'readOnly' : readOnlyModal}">
        <div class="control_group">
            <label for="">{{userBundle['workspace.incharge.bric.range.startDate']}}: </label>
            <!-- <input type="text" class="form_control" name="startdate" #startDate
                [ngModel]="rangeModal.startDate | date:displayDateFormat" ui-date="dateOptionsFrom" readonly> -->
            <input type="text" class="form_control" name="startdate" #startDate="ngbDatepicker"
                [container]="'body'" [(ngModel)]="rangeModal.startDate"
                [dayTemplate]="startDayTemplate" [showWeekNumbers]="showWeekNumber" [minDate]="minStartDate"
                [markDisabled]="markStartDayDisabled" ngbDatepicker (click)="openDatePicker($event, startDate, endDate)"
                [outsideDays]="'collapsed'" (document:click)="closeDatePickerOutsideClick($event, startDate)" readonly>
        </div>
        <div class="control_group">
            <label for="">{{userBundle['workspace.incharge.bric.range.endDate']}}: </label>
            <!-- <input type="text" class="form_control" name="enddate" [disabled]="disableEndDate" #endDate
                [ngModel]="rangeModal.endDate | date:displayDateFormat" ui-date="dateOptionsTo" readonly> -->
            <input type="text" class="form_control" name="enddate" [disabled]="disableEndDate" #endDate="ngbDatepicker"
                [container]="'body'" [(ngModel)]="rangeModal.endDate" [dayTemplate]="endDayTemplate"
                [showWeekNumbers]="showWeekNumber" [minDate]="minEndDate" [markDisabled]="markEndDayDisabled"
                ngbDatepicker (click)="openDatePicker($event, endDate, startDate)" [outsideDays]="'collapsed'"
                (document:click)="closeDatePickerOutsideClick($event, endDate)" readonly>
        </div>
        <div class="control_group" *ngIf='pcmMode'>
            <label for="">{{userBundle['workspace.pcm.incharge.bric.default']}}: </label>
            <!-- {{userBundle['workspace.pcm.incharge.bric.default']}} -->
            <input type="number" [(ngModel)]="rangeModal.defaultValue" class="form_control withCheckbox">
            <div class="btn-group">
                <input type="radio" name="defaultValueDay" [(ngModel)]="rangeModal.defaultValueUnit" [value]=0
                    autocomplete="off" id="defaultValueDay1">
                <label for="defaultValueDay1" class="">{{userBundle['workspace.pcm.incharge.bric.day']}}</label>
                <!-- {{userBundle['workspace.pcm.incharge.bric.day']}} -->
                <input type="radio" name="defaultValueDay" [(ngModel)]="rangeModal.defaultValueUnit" [value]=1
                    autocomplete="off" id="defaultValueDay2">
                <label for="defaultValueDay2" class="">{{userBundle['workspace.pcm.incharge.bric.week']}}</label>
                <!-- {{userBundle['workspace.pcm.incharge.bric.week']}} -->
            </div>
        </div>
        <div class="control_group" *ngIf='pcmMode'>
            <label for="">{{userBundle['workspace.pcm.incharge.bric.min']}}: </label>
            <!-- {{userBundle['workspace.pcm.incharge.bric.min']}} -->
            <input type="number" [(ngModel)]="rangeModal.minValue" class="form_control withCheckbox">
            <div class="btn-group">
                <input type="radio" name="minValueDay" [(ngModel)]="rangeModal.minValueUnit" [value]=0
                    autocomplete="off" id="minValueDay1">
                <label for="minValueDay1" class="">{{userBundle['workspace.pcm.incharge.bric.day']}}</label>
                <!-- {{userBundle['workspace.pcm.incharge.bric.day']}} -->
                <input type="radio" name="minValueDay" [(ngModel)]="rangeModal.minValueUnit" [value]=1
                    autocomplete="off" id="minValueDay2">
                <label for="minValueDay2" class="">{{userBundle['workspace.pcm.incharge.bric.week']}}</label>
                <!-- {{userBundle['workspace.pcm.incharge.bric.week']}} -->
            </div>
        </div>
        <div class="control_group" *ngIf='pcmMode'>
            <label
                class="label">{{userBundle['workspace.pcm.incharge.defaultWeekDay'] ? userBundle['workspace.pcm.incharge.defaultWeekDay'] : 'Default Week Day' }}</label>
            <select id="attribute" class="form_control" [(ngModel)]="rangeModal.defaultWeekDay">
                <option value=-1>{{userBundle['common.lookup.defaultOption']}}</option>
                <option *ngFor="let item of defaultWeekDay; trackBy: trackByDay;" [value]="item.dayId">{{item.dayName}}</option>
            </select>
        </div>
    </div>
    <div footer class="right pcm-validation-info" *ngIf="productValidations && !pcmMode" appMasterTooltip
        [tooltipName]="'mastertooltip'" [alwaysDisplay]="false">
        <img height="20px" width="20px" src="assets/img/info-image.png" alt="info" />
        <div class="mastertooltip modaltooltip">
            <div class="triangle-up"></div>
            <h5>{{userBundle['workspace.incharge.validRange']  || 'Valid Range'}}</h5>
            <br>
            <div *ngIf="productValidations.startDate">
                {{productValidations.startDate | date:displayDateFormat}}
                <div>{{userBundle['workspace.rating.bric.to']}}</div>
                {{productValidations.endDate | date:displayDateFormat}}
            </div>
            <div *ngIf="!productValidations.startDate">
                {{userBundle['workspace.incharge.allValidDates']  || 'All Valid Dates'}}
            </div>
            <br>
            <div *ngIf="productValidations && productValidations.defaultWeekDay != null && productValidations.defaultWeekDay >=0">
                <h5>{{userBundle['workspace.pcm.incharge.defaultWeekDay'] ? userBundle['workspace.pcm.incharge.defaultWeekDay'] : 'Default Week Day' }}
                </h5>
                <br>
                {{defaultWeekDay[productValidations.defaultWeekDay].dayName}}
            </div>
        </div>
    </div>
</app-sb-modal-wrapper>
<ng-template #startDayTemplate let-date="date" let-currentMonth="currentMonth" let-selected="selected"
    let-disabled="disabled">
    <span class="day-container" [class.hidden]="date.month !== currentMonth"
        [class.text-muted]="getStartDayProp(date, 'isDisabled')" (click)="onSelectFrom(date)">
        <div class="custom-day" [class.today]="getStartDayProp(date, 'isToday')"
            [class.weekend]="getStartDayProp(date, 'isWeekday')" [class.selected]="selected">
            {{ date.day }}
        </div>
    </span>
</ng-template>
<ng-template #endDayTemplate let-date="date" let-currentMonth="currentMonth" let-selected="selected"
    let-disabled="disabled">
    <span class="day-container" [class.hidden]="date.month !== currentMonth"
        [class.text-muted]="getEndDayProp(date, disabled, 'isDisabled')">
        <div class="custom-day" [class.today]="getEndDayProp(date, disabled, 'isToday')"
            [class.weekend]="getEndDayProp(date, disabled, 'isWeekday')" [class.selected]="selected">
            {{ date.day }}
        </div>
    </span>
</ng-template>
