export class HistoryStack {
  stack = [];
  constructor() { }

  pop() {
    return this.stack.pop();
  }
  push(item) {
    this.stack.push(item);
  }
  resetAll() {
    this.stack = [];
  }
}
