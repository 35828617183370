import { ColumnConfig } from '../../models/MapData.model';
import { ColumnUIControl } from './column-ui-control';
import { Filter } from './filters';
import { ProductHelper } from './product';

export class WorkspaceObject {
  columnConfig: ColumnConfig;
  columnLineNumber: any = {};
  workspaceUIControl: ColumnUIControl = new ColumnUIControl();
  filter: Filter;
  campaignParameters: any = {};
  productDetails: ProductHelper[] = [];
  availabilityData: any = {};
  common: any = {};
}
