<div aria-label="Pagination">
  <ul class="pagination">
    <li [ngClass]="{ 'disabled': current === 1}" class="page-item">
      <a 
        class="page-link"
        aria-label="Go To First Page"
        [attr.aria-disabled]="current === 1"
        (click)="onFirstPage()"
      >
      ««
      </a>
    </li>
    <li [ngClass]="{ 'disabled': current === 1}" class="page-item">
      <a
        class="page-link"
        aria-label="Go To Previous Page"
        [attr.aria-disabled]="current === 1"
        (click)="onPrevious()"
      >
      «
      </a>
    </li>

    <li *ngFor="let page of pages; index as i" [ngClass]="{'active': page === current}" class="page-item">
      <a
        *ngIf="page !== -1; else more"
        [attr.aria-current]="page === current ? 'page' : null"
        [attr.aria-label]="
          page === current ? 'Current Page, Page ' + page : 'Go to Page ' + page
        "
        class="page-link"
  
        tabindex="0"
        (click)="onGoTo(page)"
        (keyup.enter)="onGoTo(page)"
        >{{  getPageSymbol(page) }}
      </a>
      <ng-template #more>
        <a class="page-link"> ... </a>
      </ng-template>
    </li>
    <li class="page-item" [ngClass]="{ 'disabled': current === total || pages.length === 0}">
      <a
        class="page-link"
        aria-label="Go To Next Page"
        [attr.aria-disabled]="current === total"
        (click)="onNext()"
      >
        »
      </a>
    </li>
    <li class="page-item" [ngClass]="{ 'disabled': current === total || pages.length === 0}">
      <a
        class="page-link"
        aria-label="Go To Last Page"
        [attr.aria-disabled]="current === total"
        (click)="onLastPage()"
      >
      »»
      </a>
    </li>
  </ul>
</div>