export class CellPosition {
  rowIndex: number;
  cellIndex: number;
  targetRowIndex = -1; // This will be used when user will drop new brick on different type of row
  note: string;
  expandDirection: ExpandDirection = ExpandDirection.None;
}

export enum ExpandDirection {
  Left,
  Right,
  None
}
