import { GLOBAL } from '../app/geoplanner/src/app/core/utils/app.constant';

GLOBAL.SERVICE_BASE_URL = 'https://dev-automation-ui.dev.viooh.net.cn';
GLOBAL.BASE_URL = 'https://dev-automation-ui.dev.viooh.net.cn';
GLOBAL.HTTP_METHOD = 'POST';

export const environment = {
    production: false,
    landingAppUrl: 'https://dev-automation-ui.dev.viooh.net.cn'
};

// market:jcd:cn:airport:pvg
// market:jcd:cn:sh:metro
// market:jcd:cn:airport:can
// Comment out below 4 lines to run on local JSON
// GLOBAL.LOCAL_JSON = true;
// GLOBAL.SERVICE_BASE_URL = '';
// GLOBAL.HTTP_METHOD = 'GET';
// GLOBAL.BRIC_CONFIG_URL = GLOBAL.SERVICE_BASE_URL + 'data/US/config.json';