import { Component, OnInit, Input } from '@angular/core';
import { CellValues } from '../../../models/workspace';
import {
  DataShareService,
  SharedService,
  BrickBaseService,
  LogHelperService,
  CellAttributeService,
  LoaderService,
} from '../../../core/services/index';
import { ListFilterService } from '../list-filter.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ListBase } from '../list-base';
import { ListDataModal } from '../../../models';
import * as _ from 'lodash';
@Component({
  selector: 'app-list-modal',
  templateUrl: './list-modal.component.html'
})
export class ListModalComponent extends ListBase implements OnInit {

  /**
   * This object is used to manipulate and tranfer data between
   * List filter component and filter area component.
   * @type {Brick}
   * @memberof ListModalComponent
   */
  @Input() resolveObject: CellValues;

  readOnlyModal = false;

  /**
   * Creates an instance of ListModalComponent.
   * @param {DataShareService} dataService
   * @param {SharedService} sharedService
   * @param {BrickBaseService} brickBaseService
   * @param {LogHelperService} logHelperService
   * @param {ListFilterService} listFilterService
   * @param {NgbActiveModal} activeModal
   * @memberof ListModalComponent
   */
  constructor(
    dataService: DataShareService,
    sharedService: SharedService,
    brickBaseService: BrickBaseService,
    logHelperService: LogHelperService,
    listFilterService: ListFilterService,
    private activeModal: NgbActiveModal,
    private cellAttributeService: CellAttributeService,
    loaderService: LoaderService
  ) {
    super(dataService, sharedService, brickBaseService, logHelperService, listFilterService, loaderService);
  }

  /**
   * @description angular life cycle hook - called on component init
   * @author Alkesh Shah
   * @memberof ListModalComponent
   */
  ngOnInit() {
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.listData.fileNameLabel = this.initialConfig.userBundle['geoplanner.text.uploadFile'] || 'Upload File';
    const selectedValues = this.resolveObject.selectedValues ? this.resolveObject.selectedValues : {};
    this.init(selectedValues);
  }

  /**
   * @description callback to close modal popup window
   * @author Alkesh Shah
   * @param {*} event
   * @memberof ListModalComponent
   */
  onModalClosed(event) {
    const routeIdToSend = this.getRouteIdToSend();
    if (event.reason === 'escape' && this.isValidSelection(routeIdToSend)) {
      if (window.confirm(this.initialConfig['userBundle']['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * @description callback function - called during save click
   * @author Alkesh Shah
   * @param {*} event
   * @memberof ListModalComponent
   */
  onModalSaved() {
    this.paginationDataModel.forEach((frame) => {
      let index = _.findIndex(this.listData.routeFrameArray, { 'routeFrameId': frame.routeFrameId });
      if (index == -1) {
        this.listData.routeFrameArray.push(frame);
      }
    });
    const routeIdToSend = this.getRouteIdToSend();
    if (this.isValidSelection(routeIdToSend)) {
      this.sendUploadRequest(this.fileToSend, routeIdToSend, 0).then((responseMsg) => {
        if (responseMsg === '') {
          const tempList: ListDataModal = new ListDataModal();
          tempList.listUpload = this.listData.listUpload;
          tempList.routeFrameArray = this.listData.routeFrameArray;
          tempList.routeFrameIds = this.listData.routeFrameIds;
          const values: CellValues = new CellValues();
          values.brick = this.resolveObject.brick;
          values.selectedValues = tempList;
          values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.List, values.selectedValues);
          values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.List, values.selectedValues);
          values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.List, values.selectedValues);
          this.activeModal.close(values);
        } else {
          this.logHelperService.logError(responseMsg);
        }
      }, (error) => {
        this.logHelperService.logError(error);
      });
    } else {
      this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.list.validation.route']);
    }
  }

  public removeFrame(event){
    let index = _.findIndex(this.listData.routeFrameArray, { 'routeFrameId': event[0].routeFrameId });
    if (index !== -1) {
      this.listData.routeFrameArray.splice(index,1);
    }
  }
}
