/**
 * @description Helper class for data and ui state manipulation
 * @author Amit Mahida
 * @export
 * @class Helper
 */
export class Helper {
  /**
   * @description Maps Advertisers and Product Category list for request params based on brandId
   * @author Amit Mahida
   * @param {string} brandId
   * @param {Array<any>} totalBrands
   * @returns
   * @memberof Helper
   */
  public findOrganisationAndProductCategoryByBrand(brandId: number, totalBrands: any[]) {
    let organisation;
    let brand;
    if (!totalBrands) {
      return null;
    }
    for (organisation of totalBrands) {
      if (organisation.source && organisation.source.brand) {
        for (brand of organisation.source.brand) {
          if (brand.brandId === brandId) {
            return {
              organisation,
              productCategory: brand.productCategory
            };
          }
        }
      }
    }
    return null;
  }

  /**
   * @description Return property list of an object
   * @author Amit Mahida
   * @param {Object} obj
   * @returns {string[]}
   * @memberof Helper
   */
  public getPropertyList(obj: object): string[] {
    if (obj !== null) {
      return Object.getOwnPropertyNames(obj);
    } else {
      return [];
    }
  }
}
