import { Injectable } from '@angular/core';
import { CampaignSummary, Filter, ColumnSummary, ColumnHelper } from './../../models/workspace';
import { Campaign } from './../../models';
import { DataShareService } from './data-share.service';
import { SharedService } from './shared.service';
import { HistoryStackService } from './history-stack.service';
import { WorkspaceService } from './workspace.service';
import { LogHelperService } from './log-helper.service';
import { CellAttributeService } from './cell-attributes.service';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Category } from './../../models/bricsMasterData';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { PcmService } from './pcm.service';
import { WorkspaceObject } from '../../models/workspace/workspace-object';
import { ObjectiveObject } from '../../models/workspace/objective-object';
import { HistoryStackData } from '../../models/workspace/historyStackData';

@Injectable()
export class StateService {

  private currentCampaign: Campaign = new Campaign();
  // public campaign: BehaviorSubject<Campaign> = new BehaviorSubject(new Campaign());
  isDataAvailable = false;

  private initialStateCampaign: Campaign;
  generalObject = {};
  workspaceObject: WorkspaceObject = new WorkspaceObject();
  objectiveObject: ObjectiveObject = new ObjectiveObject();
  geoMapperObject = {};
  vpObject = {};
  searchPageObject = {};
  resultPageObject = {};
  commercialObject = {};
  authObject = {};
  pcmObject: any = {};
  campaignSummary: CampaignSummary[] = [];
  columnSummary: { [key: string]: ColumnSummary } = {};
  loadedCampaignSummary: CampaignSummary[] = [];
  public columnHelper: ColumnHelper = {};
  globalSearchCampaignId = null;

  /**
   * @description campaignSummarySource
   * @private
   * @type {BehaviorSubject<CampaignSummary>}
   * @memberof StateService
   */
  private campaignSummarySource: BehaviorSubject<CampaignSummary[]> = new BehaviorSubject(this.campaignSummary);

  /**
   * @description Campaign summary observable to get campaign summary data
   * @type {Observable<CampaignSummary>}
   * @memberof StateService
   */
  public campaignSummary$: Observable<CampaignSummary[]> = this.campaignSummarySource.asObservable();

  /**
   * @description Column summary source
   * @private
   * @type {BehaviorSubject<{ [key: string]: ColumnSummary }>}
   * @memberof StateService
   */
  private columnSummarySource: BehaviorSubject<{ [key: string]: ColumnSummary }> = new BehaviorSubject(this.columnSummary);

  /**
   * @description Column summary observable to get column summary data
   * @type {Observable<{ [key: string]: ColumnSummary }>}
   * @memberof StateService
   */
  public columnSummary$: Observable<{ [key: string]: ColumnSummary }> = this.columnSummarySource.asObservable();

  /**
   * @description Globalsearch campaign observalbe
   * @private
   * @type {Subject<any>}
   * @memberof StateService
   */
  public updateGlobalCampaignId: Subject<any> = new Subject();
  public serchAccordian: Subject<any> = new Subject();

  /**
   * @description triggers validation of current workspace
   * @private
   * @type {BehaviorSubject<boolean>}
   * @memberof StateService
   */
  private validWorkspaceSource: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * @description Campaign summary observable to get campaign summary data
   * @type {Observable<boolean>}
   * @memberof StateService
   */
  public validWorkspace$: Observable<boolean> = this.validWorkspaceSource.asObservable();
  /**
   * @description trigers when load product, clear workspace etc
   * @type {Subject<any>}
   * @memberof StateService
   */
  public masterDataSubject: Subject<any> = new Subject();
  public masterData: Category[];

  constructor(
    private dataShareService: DataShareService,
    private logHelperService: LogHelperService,
    private workspaceService: WorkspaceService,
    private datePipe: DatePipe,
    private cellAttributeService: CellAttributeService,
    private historyStackService: HistoryStackService,
    private sharedService: SharedService,
    private appHeaderService: AppHeaderService,
    private pcmService: PcmService
  ) {
    this.resetSearch();
    this.resetExceptSearch();
  }

  setCampaign(updatedCampaign: Campaign) {
    this.currentCampaign = _.cloneDeep(updatedCampaign);
  }

  getCampaign(): Campaign {
    return this.currentCampaign;
  }

  setInitialStateCampaign(campaign: Campaign) {
    this.initialStateCampaign = campaign;
  }

  getInitialStateCampaign() {
    return this.initialStateCampaign;
  }

  // SD : Resolution  ::  Campaing not getting loaded (19th June 2015)//
  setAnyValue(key: string, value) {
    this.generalObject[key] = value;
  }

  getAnyValue(key: string) {
    return this.generalObject[key];
  }

  setWorkspaceObject(key: string, newValue: any) {
    this.isDataAvailable = true;
    if (key === 'filter') {
      newValue['objectType'] = 'workspace';
      this.workspaceObject[key] = this.clone(newValue);
      // this.workspaceObject[key]['objectType'] = 'workspace';
    } else {
      this.workspaceObject[key] = _.cloneDeep(newValue);
    }
  }

  getWorkspaceObject(key: string) {
    if (key === 'filter') {
      return this.workspaceObject[key];
    } else {
      const defaultValue = Array.isArray(this.workspaceObject[key]) ? [] : {};
      return _.cloneDeep(this.workspaceObject[key]) || defaultValue;
    }
  }

  setObjectiveObject(key: string, newValue: any) {
    this.isDataAvailable = true;
    if (key === 'filter') {
      newValue['objectType'] = 'objective';
      this.objectiveObject[key] = this.clone(newValue);
      // this.objectiveObject[key]['objectType'] = 'objective';
    } else {
      this.objectiveObject[key] = _.cloneDeep(newValue);
    }
  }

  getObjectiveObject(key: string) {
    if (key === 'filter') {
      return this.objectiveObject[key];
    } else {
      const defaultValue = Array.isArray(this.objectiveObject[key]) ? [] : {};
      return _.cloneDeep(this.objectiveObject[key]) || defaultValue;
    }
  }

  setPCMObject(key, newValue) {
    this.isDataAvailable = true;
    if (key === 'filter') {
      newValue['objectType'] = 'pcm';
      this.pcmObject[key] = newValue;
      // this.pcmObject[key]['objectType'] = 'pcm';
    } else {
      this.pcmObject[key] = _.cloneDeep(newValue) || {};
    }
  }

  getPCMObject(key) {
    if (key === 'filter') {
      return this.pcmObject[key];
    } else {
      return _.cloneDeep(this.pcmObject[key]) || {};
    }
  }

  setGeoMapperObject(key, newValue) {
    this.geoMapperObject[key] = _.cloneDeep(newValue);
  }

  getGeoMapperObject(key) {
    return _.cloneDeep(this.geoMapperObject[key]) || {};
  }

  setVPObject(key, newValue) {
    this.vpObject[key] = _.cloneDeep(newValue) || {};
  }

  getVPObject(key) {
    return _.cloneDeep(this.vpObject[key]) || {};
  }

  setSearchPageObject(key, newValue) {
    this.isDataAvailable = true;
    this.searchPageObject[key] = _.cloneDeep(newValue) || {};
  }

  getSearchPageObject(key) {
    return _.cloneDeep(this.searchPageObject[key]) || {};
  }

  setResultPageObject(key, newValue) {
    this.isDataAvailable = true;
    this.resultPageObject[key] = _.cloneDeep(newValue) || {};
  }

  getResultPageObject(key) {
    return _.cloneDeep(this.resultPageObject[key]) || {};
  }

  setCommercialObject(key, newValue) {
    this.isDataAvailable = true;
    this.commercialObject[key] = _.cloneDeep(newValue) || {};
  }

  getCommercialObject(key) {
    return _.cloneDeep(this.commercialObject[key]) || {};
  }

  resetExceptSearch() {
    this.workspaceObject = new WorkspaceObject();
    this.objectiveObject = new ObjectiveObject();
    this.geoMapperObject = {
      campaignLoad: {
        isLoadData: false,
        isResetCampaign: true
      },
      commWithOtherPage: {
        refreshFilter: false
      }
    };
    this.vpObject = {
      campaignLoad: {
        isLoadData: false,
        isResetCampaign: true
      },
      commWithOtherPage: {
        refreshFilter: false
      }
    };
    this.resultPageObject = {};
    this.commercialObject = {};
    this.currentCampaign = new Campaign();
    this.setCampaignSummary([]);
    this.setColumnSummary({});
    this.setColumnHelper({});
    this.setLoadedCampaignSummary([]);
  }

  resetSearch() {
    this.searchPageObject = {};
    this.generalObject = {};
    this.isDataAvailable = false;
  }

  /**
   * Method to store campaign process response
   * @param response Process brick response
   */
  setProcessBrickResponse(response) {
    this.setWorkspaceObject('columnConfig', response.columnConfig);
    this.setWorkspaceObject('columnLineNumber', response.columnLineNumber);
    this.setWorkspaceObject('workspaceUIControl', response.uiControl);
    this.setCampaignSummary(response.campaignSummary);
    this.setColumnSummary(response.columnSummary);
    this.setWorkspaceObject('objectiveMeasures', response.objectiveMeasures);
  }

  clearProcessResponse() {
    this.setCampaignSummary([]);
    this.setColumnSummary({});
    // We should not clear the columnLineNumber as in case if user corrects the solution, then last columnlineNumber should be maintained
    // this.setWorkspaceObject('workspaceUIControl', new ColumnUIControl());
  }

  /**
   * @description sets campaign summary and throws subsription
   * @author Amit Mahida
   * @param {CampaignSummary[]} campaignSummary
   * @memberof StateService
   */
  setCampaignSummary(campaignSummary: CampaignSummary[]) {
    this.campaignSummary = campaignSummary;
    this.campaignSummarySource.next(this.campaignSummary);
  }

  setLoadedCampaignSummary(campaignSummary: CampaignSummary[]) {
    this.loadedCampaignSummary = campaignSummary;
  }

  getLoadedCampaignSummary(): CampaignSummary[] {
    return this.loadedCampaignSummary;
  }

  /**
   * @description returns current campaign summary status
   * @author Amit Mahida
   * @returns {CampaignSummary[]}
   * @memberof StateService
   */
  getCampaignSummary(): CampaignSummary[] {
    return this.campaignSummary;
  }

  /**
   * @description sets column summary and throws subsription
   * @author Amit Mahida
   * @param {{ [key: string]: ColumnSummary }} columnSummary
   * @memberof StateService
   */
  setColumnSummary(columnSummary: { [key: string]: ColumnSummary }) {
    this.columnSummary = columnSummary;
    this.columnSummarySource.next(this.columnSummary);
  }

  /**
   * @description returns column summary for given column index
   * @author Amit Mahida
   * @param {string} colIndex
   * @returns {ColumnSummary}
   * @memberof StateService
   */
  getColumnSummary(colIndex: string): ColumnSummary {
    return this.columnSummary[colIndex] || null;
  }

  /**
   * @description sets columns helper state
   * @author Amit Mahida
   * @param {ColumnHelper} columnHelper
   * @memberof StateService
   */
  public setColumnHelper(columnHelper: ColumnHelper) {
    this.columnHelper = columnHelper;
  }

  /**
   * @description returns column helper
   * @author Amit Mahida
   * @returns {ColumnHelper}
   * @memberof StateService
   */
  public getColumnHelper(): ColumnHelper {
    if (this.appHeaderService.enabledPCM) {
      return {};
    }

    return this.columnHelper || {};
  }

  /**
   * @description Get all columnsummary
   * @author Darshan Vachhani
   * @memberof StateService
   */
  getAllColumnSummary(): { [key: string]: ColumnSummary } {
    return this.columnSummary;
  }

  /**
   * @description get workspace filter object if exist else return new instance
   * @author Alkesh Shah
   * @returns {Filter}
   * @memberof StateService
   */
  getWorkspaceFilterObj(): Filter {
    const filter: Filter = this.workspaceObject.filter;
    if (!filter) {
      return this.getFreshFilterObj();
    }
    return this.clone(filter);
  }

  /**
   * @description get PCM filter object if exist else return new instance
   * @author Amit Mahida
   * @returns {Filter}
   * @memberof StateService
   */
  getPCMFilterObj(): Filter {
    const filter: Filter = this.pcmObject.filter;
    if (!filter) {
      return this.getFreshFilterObj();
    }
    return filter;
  }

  getObjectiveFilterObj(): Filter {
    const filter: Filter = this.objectiveObject.filter;
    if (!filter) {
      return this.getFreshFilterObj();
    }
    return filter;
  }

  setGlobalSearchCampaignId(id) {
    this.globalSearchCampaignId = id;
    this.updateGlobalCampaignId.next(id);
  }

  getGlobalSearchCampaignId() {
    return this.globalSearchCampaignId;
  }

  setSerchAccordian(accordian) {
    this.serchAccordian.next(accordian);
  }

  /**
   * @description Creates new Filter object
   * @returns {Filter}
   * @memberof StateService
   */
  getFreshFilterObj(): Filter {
    return new Filter(this,
      this.dataShareService,
      this.logHelperService,
      this.workspaceService,
      this.datePipe,
      this.cellAttributeService,
      this.historyStackService,
      this.sharedService,
      this.pcmService,
      this.appHeaderService,
    );
  }

  /**
   * @description updates validity of workspace
   * @author Amit Mahida
   * @param {boolean} value
   * @memberof StateService
   */
  updateWorkspaceValidity(value: boolean) {
    this.validWorkspaceSource.next(value);
  }

  /**
   * @description Clone the Filter Object
   * @author Shivani Patel
   * @param {Filter} filter
   * @returns {Filter}
   * @memberof Filter
   */
  public clone(filter: Filter): Filter {
    const tempFilter: Filter = this.getFreshFilterObj();
    tempFilter.rows = _.cloneDeep(filter.rows);
    tempFilter.brickRequestJSON = _.cloneDeep(filter.brickRequestJSON);
    tempFilter.lockAllButton = filter.lockAllButton;
    tempFilter.campaignParameters = _.clone(filter.campaignParameters);
    tempFilter.columnLock = _.clone(filter.columnLock);
    tempFilter.isPastColumnHidden = _.clone(filter.isPastColumnHidden);
    tempFilter.reshuffle = filter.reshuffle;
    tempFilter.toleranceLimit = filter.toleranceLimit;
    tempFilter.columnSummaryWithoutBrick = filter.columnSummaryWithoutBrick;
    tempFilter.objectiveMeasuresData = filter.objectiveMeasuresData;
    tempFilter.allocationEngines = filter.allocationEngines;
    tempFilter.lastFtgIteration = filter.lastFtgIteration;
    tempFilter.ftgIterationAllowed = filter.ftgIterationAllowed;
    tempFilter.allocationEngines = filter.allocationEngines;
    tempFilter.objectType = filter.objectType;
    return tempFilter;
  }

  /**
   * @description SM-3835
   * @author Nishit Parekh
   * @param {*} data
   * @memberof StateService
   */
  setMasterDataForLeftPanel(data) {
    this.masterData = data;
    this.masterDataSubject.next(data);
  }
  /**
   * @description return the master data for external users
   * @author Nishit Parekh
   * @returns
   * @memberof StateService
   */
  getMasterDataForLeftPanel() {
    return this.masterData;
  }

  /**
   * @description It will lock the entire bric structure of workspace
   * @author Dhaval Patel
   * @memberof StateService
   */
  lockWorkspace() {
    // Store current item to History Stack
    this.historyStackService.pushInHistoryStack(new HistoryStackData(this.workspaceObject.filter));
    this.historyStackService.pushColumnLineNumberInHistoryStack(this.getWorkspaceObject('columnLineNumber'));
    const columnHelper = this.getColumnHelper();
    this.historyStackService.pushColumnHelperInHistoryStack(columnHelper);
    this.workspaceObject.filter.lockAllButton = true;
    this.workspaceObject.filter.toggleAllColumnsLock(true);
    for (let index = 0; index < this.workspaceObject.filter.rows[0].cells.length; index++) {
      if (this.workspaceObject.filter.lockAllButton && !columnHelper[index].systemLocked) {
        columnHelper[index] = {
          systemLocked: false,
          userLocked: true,
          shown: true,
          allocationEngine: ''
        };
      } else {
        if (columnHelper[index] && !columnHelper[index].systemLocked) {
          delete columnHelper[index];
        }
      }
    }
    this.setColumnHelper(columnHelper);
    this.workspaceObject.filter.updateWorkspaceOnLock();
    this.workspaceObject.filter.populateReshufflingColumns(false, null);
  }

  /**
   * @description It will unlock the entire bric structure of workspace || Fix: SM-9395
   * @author Sagar Vaishnav
   * @memberof StateService
   */
  unlockWorkspace() {
    // Store current item to History Stack
    this.historyStackService.pushInHistoryStack(new HistoryStackData(this.workspaceObject.filter));
    this.historyStackService.pushColumnLineNumberInHistoryStack(this.getWorkspaceObject('columnLineNumber'));
    const columnHelper = this.getColumnHelper();
    this.historyStackService.pushColumnHelperInHistoryStack(columnHelper);
    this.workspaceObject.filter.lockAllButton = false;
    this.workspaceObject.filter.toggleAllColumnsLock(false);
    for (const [i] of this.workspaceObject.filter.rows[0].cells.entries()) {      
      if (this.workspaceObject.filter.lockAllButton && !columnHelper[i].systemLocked) {
        columnHelper[i] = {
          systemLocked: false,
          userLocked: true,
          shown: true,
          allocationEngine: ''
        };
      } else {
        if (columnHelper[i] && !columnHelper[i].systemLocked) {
          delete columnHelper[i];
        }
      }
    }    
    this.setColumnHelper(columnHelper);
    this.workspaceObject.filter.updateWorkspaceOnLock();
    this.workspaceObject.filter.populateReshufflingColumns(false, null);
  }

}


