import { Component, OnInit, Input } from '@angular/core';
import {
  DataShareService,
  CellAttributeService,
  BrickBaseService
} from '../../../core/services';
import { CellValues } from '../../../models/workspace/index';
import { PricingBase } from '../pricing-base';
@Component({
  selector: 'app-pricing-modal',
  templateUrl: './pricing-modal.component.html'
})
export class PricingModalComponent extends PricingBase implements OnInit {
  /**
   * It will take data to configure modal window.
   */
  @Input() resolveObject: CellValues;
  solutionPrice: object;
  readOnlyModal = false;
  constructor(
    dataService: DataShareService,
    private cellAttributeService: CellAttributeService,
    private brickBaseService: BrickBaseService
  ) {
    super(dataService);
  }

  ngOnInit() {
    // @TODO: Remove temp data when column config available
    this.solutionPrice = this.resolveObject.columnSummary.solutionPrice;
    const modalPricingValue = this.resolveObject.selectedValues ? { pricingFactor: this.resolveObject.selectedValues } : null;
    const minMaxStepValues = {
      solutionPrice: this.solutionPrice,
      min: 0,
      max: Object.keys(this.solutionPrice).length - 1,
      step: 1
    };
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.init(modalPricingValue, minMaxStepValues);
  }

  /**
   * callback to close modal popup window
   * @param {*} event - modal event object
   * @memberof LocationModalComponent
   */
  onModalClosed(event) {
    if (event.reason === 'escape' && this.pricingFactor) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * callback function - called during save click
   * @param {*} event - modal event object
   * @memberof LocationModalComponent
   */
  onModalSaved(event) {
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    values.selectedValues = this.pricingFactor;
    values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Pricing, values.selectedValues);
    values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Pricing, values.selectedValues);
    event.activeModal.close(values);
  }
}
