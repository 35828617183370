import { CellBaseModal } from './cell-base';
import { Injectable } from '@angular/core';

@Injectable()
export class PatternWindow extends CellBaseModal {
  selectedPattern: object = {}; // TODO: Need to define type
}

export class PatternDataPrep {
  clearSelectedValue = false;
  startDate: Date;
  endDate: Date;
  totalDays = 0;
  startDay = 0;
  endDay = 0;
  multipleRow = false;
  week = 0;
  allowDayParts = true;
  allowDays = true;
  allowHours = true;
  minValueUnit ?= null;

}

export class PatternData {
  patternType = 1;  // 1 for Named, 2 for Numbered
  patternLength = 1; // Formula Math.ceil((Number of Selectable days) / 7), For Numbered Days it will be always 1
  pattern: any[] = [];
  selected: object = {
    row: []
  };
  allowDays = false;
  allowDayParts = false;
  allowHours = false;
  selectedDay?: SelectedDay = {
    rowIndex: null,
    columnIndex: null
  };
  hoursSelection?: string;
}

export class SelectedDay {
  rowIndex: number = null;
  columnIndex: number = null;
}

export class PatternCell {
  disable = true;
  columnIndex: number = null;
  currentSelection = false;
  selected = false;
  displayText = ' ';
  values: any[] = [];
  weekNumber = '';
}
