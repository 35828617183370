<app-filter-layout [resolveObject]="resolveObject" (closeClick)="closeClick($event)" (saveClick)="saveClick($event)">
    <div [ngSwitch]="options.DisplayFormat">
        <ng-container *ngSwitchCase="1">
            <div class="form-group-wrapper_geo" *ngFor="let categoryType of audienceCategoryType; trackBy: trackByIndex; let i = index">
                <div class="form-group_geo">
                    <!-- <img class="titleIcon" *ngIf="categoryType.audienceCategoryTypeCode == 'MIXGENDER'" [src]="MIXGENDER" /> -->
                    <span class="titleLabel" *ngIf="categoryType.audienceCategoryTypeCode == 'MIXGENDER'">
                        <!-- {{initialConfig.audienceTags['1']}} -->
                        {{categoryType.audienceCategoryTypeName}}
                    </span>
                    <!-- <img class="titleIcon" *ngIf="categoryType.audienceCategoryTypeCode == 'MALES'" [src]="MALES" /> -->
                    <span class="titleLabel" *ngIf="categoryType.audienceCategoryTypeCode == 'MALES'">
                        <!-- {{initialConfig.audienceTags['2']}} -->
                        {{categoryType.audienceCategoryTypeName}}
                    </span>
                    <!-- <img class="titleIcon" *ngIf="categoryType.audienceCategoryTypeCode == 'FEMALES'" [src]="FEMALES" /> -->
                    <span class="titleLabel" *ngIf="categoryType.audienceCategoryTypeCode == 'FEMALES'">
                        <!-- {{initialConfig.audienceTags['3']}} -->
                        {{categoryType.audienceCategoryTypeName}}
                    </span>
                    <!-- <img class="titleIcon" *ngIf="categoryType.audienceCategoryTypeCode == 'SHOPPER'" [src]="SHOPPER" /> -->
                    <span class="titleLabel" *ngIf="categoryType.audienceCategoryTypeCode == 'SHOPPER'">
                        <!-- {{initialConfig.audienceTags['38']}} -->
                        {{categoryType.audienceCategoryTypeName}}
                    </span>
                    <!-- <img class="titleIcon" *ngIf="categoryType.audienceCategoryTypeCode == 'UNIVERSE'" [src]="UNIVERSE" /> -->
                    <span class="titleLabel" *ngIf="categoryType.audienceCategoryTypeCode == 'UNIVERSE'">
                        <!-- {{initialConfig.audienceTags['39']}} -->
                        {{categoryType.audienceCategoryTypeName}}
                    </span>
                    <!-- <img class="titleIcon" *ngIf="categoryType.audienceCategoryTypeCode == 'A'" [src]="A" /> -->
                    <span class="titleLabel" *ngIf="categoryType.audienceCategoryTypeCode == 'A'">
                        <!-- {{initialConfig.audienceTags['14']}} -->
                        {{categoryType.audienceCategoryTypeName}}
                    </span>
                    <!-- <img class="titleIcon" *ngIf="categoryType.audienceCategoryTypeCode == 'AB'" [src]="AB" /> -->
                    <span class="titleLabel" *ngIf="categoryType.audienceCategoryTypeCode == 'AB'">
                        <!-- {{initialConfig.audienceTags['15']}} -->
                        {{categoryType.audienceCategoryTypeName}}
                    </span>
                    <!-- <img class="titleIcon" *ngIf="categoryType.audienceCategoryTypeCode == 'ABC1'" [src]="ABC1" /> -->
                    <span class="titleLabel" *ngIf="categoryType.audienceCategoryTypeCode == 'ABC1'">
                        <!-- {{initialConfig.audienceTags['16']}} -->
                        {{categoryType.audienceCategoryTypeName}}
                    </span>
                    <hr class="horizontal">

                    <ng-template ngFor let-category [ngForOf]="categoryType.audienceCategory" [ngForTrackBy]="trackByAudienceCategory" let-j="index">
                        <div id="audiance-{{i}}-{{j}}">
                            <span class="text"></span>
                            <input type="radio" class="radio_geo" [checked]="category.audienceCategoryId==selectedAudience.audienceCategoryId" (change)="selectAudience(category)"
                                name="radio-tags" id="radio-{{i}}-{{j}}">
                            <label class="radio-label " for="radio-{{i}}-{{j}}"><em class="fa fa-circle-o "></em> {{category.audienceCategoryName}}</label>
                        </div>
                    </ng-template>

                </div>
            </div>

        </ng-container>
    </div>
</app-filter-layout>
