export class SystemFlags {

  /**
   * @description returns true if campaign is loaded
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static isLoadedCampaign = false;

  /**
   * @description readonly check on campaign load for each UI components
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static readOnly = false;

  /**
   * @description returns true if the workspace is readonly
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static readOnlyWorkspace = false;

  /**
   * @description returns true if the campaign in splittable
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static splitable = false;

  /**
   * @description returns true if the campaign is splitted
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static splitted = false;

  /**
   * @description returns true if the solution is incorrect
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static incorrectSolution = true;

  /**
   * @description returns true if need to take latest cart data for geoplanner
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static getBasketDataForGP = false;

  /**
   * @description returns true if need to take latest cart data for VisualPlanner
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static getBasketDataForVP = false;

  /**
   * @description returns true if the loaded campaign is a donor campaign
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static isDonorCampaign = false;

  /**
   * @description returns true if the keep alive call is active
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static keepAliveThreadActive = false;

  /**
   * @description returns true if it needs to reset the campaign
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static resetCampaign = false;

  /**
   * @description returns true if needs to rerun the solution
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static reRunSolution = false;

  /**
   * @description Bric alternate design flag
   * @static
   * @type {number}
   * @memberof SystemFlags
   */
  static switchBRIC = 0;

  /**
   * @description campaignLoadError for if campaign loaded and has some error then display eror message
   * @static
   * @type {number}
   * @memberof SystemFlags
   */
  static campaignLoadError = '';

  /**
   * @description Enable/Disable search tab fields based on 'editable' flag sent by backend
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static enableSearchFieldsForExternalUser = false;

  /**
   * @description This value will be true sent by backend if campaign is created from PP
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static isPPCampaign = false;

  /**
   * @description This value will be true sent by backend if campaign is API campaign
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
   static isAPICampaign = false;

  /**
   * @description This value will be true if campaign is clone
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static isCloneCampaign = false;

  /**
   * @description This value will be true if range bric is mandatory
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static isRangeSelectionMandatory = false;

  static setSwitchBric(val: number): void {
    switch (val) {
      case 0:
      case 1:
      case 2:
        this.switchBRIC = val;
        break;
      default:
        this.switchBRIC = 0;
    }
  }
  /**
   * @description this flag is used to call process Brics call on workspace force fully
   * old name for this flag is allowValidation
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static forcefullyCallProcessBrics = false;

  static isduplicateAdvertiserReplace = true;

  /**
   * @description This flag is true by default and sets to false only in case when Old Card Data
   * and New CartData are not same in GeoMapper and VisualPlanner
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static isCampaignSummaryChanged = true;

  /**
   * @description returns Advertiser is changed from Search Page
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static advertiserChange = false;

  /**
   * @description returns Advertiser is changed from Search Page
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static isFillerCampaign = false;

  /**
   * @description If true run process brics on workspace
   * @static
   * @memberof SystemFlags
   */
  static processSolutionAfterObjectiveSave = false;

  /**
   * @description returns lock reason of campaign
   * @static
   * @type {string}
   * @memberof SystemFlags
   */
  static lockReason = '';

  /**
   * @description It will be set to true once user process bric and it will set to false once user land to commercial page
   * @static
   * @type {boolean}
   * @memberof SystemFlags
   */
  static brickProcessed = false;

   static reset() {
    this.isLoadedCampaign = false;
    this.readOnly = false;
    this.readOnlyWorkspace = false;
    this.splitable = false;
    this.incorrectSolution = true;
    this.getBasketDataForGP = false;
    this.getBasketDataForVP = false;
    this.isDonorCampaign = false;
    this.keepAliveThreadActive = false;
    this.resetCampaign = false;
    this.reRunSolution = false;
    this.enableSearchFieldsForExternalUser = false;
    this.campaignLoadError = '';
    this.isCampaignSummaryChanged = true;
    this.advertiserChange = false;
    this.isFillerCampaign = false;
    this.lockReason = '';
    this.brickProcessed = false;
  }

}
