<div class="row" *ngFor="let frame of frames; trackBy: trackByFrame;" style="line-height: 23px;height: 23px;">
    <label class="col-md-12 small" style="padding: 0px !important;max-width: 157px;" [ngStyle]="{'max-width': params.data.isPlottedMarker === true?'187px':'157px'}">
        <input type="checkbox" class="checkbox small" id="checkpaneFrame{{frame.frameId}}" [(ngModel)]="frame.selected" (change)="onSelectChange(frame)"
            name="checkpaneFrame{{frame.frameId}}" [hidden]="params.data.isPlottedMarker === true" [disabled]="(!isViewCartOnMap && frame.status == frameStatus.unavailable) || (!frame.isDeletable) || frame.disabled">
        <span style="display: inline-block;max-width: 157px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" [ngStyle]="{'max-width': params.data.isPlottedMarker === true?'187px':'157px'}" [ngClass]="{'hideBackground': params.data.isPlottedMarker === true}">{{frame.frameName}}</span>
    </label>
    <span class="fa fa-list-ul pull-right info-bg" style="cursor: pointer;" (click)="onViewBookingDetailClick(frame)" *ngIf="params.data.isPlottedMarker !== true"></span>
    <span class="fa fa-info pull-right info-bg" (mouseenter)="infoMouseEnter($event, frame)" (mouseleave)="infoMouseLeave($event)"></span>

</div>
