import { Component, OnInit } from '@angular/core';
import { CampaignDetailsService } from '../campaign-details/campaign-details.service';
import { HistoryROC } from '../commercial.model';
import { SystemFlags } from '../../models';
import { DataShareService, LogHelperService, StateService } from '../../core/services';
import { CommercialService } from '../../core/services/commercial.service';
import { CommercialButtonService } from '../commercial-buttons/commercial-buttons.service';
import { DatePipe } from '@angular/common';
import { GLOBAL } from '../../core/utils/app.constant';
import { RequiredCampaignFields, RequiredBookingsFields, PopulateCommercialFields } from '../../models/RequiredFields';
import { CommercialBase } from '../commercial-base';

@Component({
  selector: 'app-commercial-br',
  templateUrl: './commercial-br.component.html',
  styleUrls: ['./commercial-br.component.css']
})
export class CommercialBrComponent extends CommercialBase implements OnInit {
  systemFlags = SystemFlags;
  /**
   * @description maintain discount grid show hide
   * @type {boolean}
   * @memberof CommercialBRComponent
   */
  hideDiscountGrid: boolean;
  /**
   * @description maintain campaign detail show hide
   * @type {boolean}
   * @memberof CommercialBRComponent
   */
  hideCampaignDetail: boolean;

  /**
   * @description store booking detail show hide
   * @type {boolean}
   * @memberof CommercialBRComponent
   */
  hideBookingDetail: boolean;

  /**
   * @description booking grid show hide
   * @type {boolean}
   * @memberof CommercialBeComponent
   */
  hideBookingGrid: boolean;

  /**
   * @description commercial data
   * @memberof CommercialBRComponent
   */
  public commercialData;

  /**
   * @description Accordian for campaign details
   * @memberof CommercialBRComponent
   */
  hideCampaignDetails: boolean;
  /**
   * @description Accordian for booking details
   * @memberof CommercialBRComponent
   */
  hideBookingDetails: boolean;
  /**
   * @description set bookingStatusList
   * @memberof CommercialBRComponent
   */
  bookingStatusList = GLOBAL.bookingStatusList;

  /**
   * @description this will show the history of campaign updated in case of reason/cancel for change
   * @memberof CommercialBRComponent
   */
  historyROC = new HistoryROC();

  constructor(
    campaignDetailsService: CampaignDetailsService,
    dataShareService: DataShareService,
    commercialService: CommercialService,
    commercialButtonService: CommercialButtonService,
    logHelperService: LogHelperService,
    datePipe: DatePipe,
    stateService: StateService
  ) {
    super(stateService, logHelperService, commercialButtonService, dataShareService, commercialService, datePipe, campaignDetailsService);
  }

  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.setDefaultAgencySpecialistPerc();
    this.restoreCommercialData();
  }

  /**
   * @description get commercial data
   * @author Darshan Vachhani
   * @memberof CommercialBRComponent
   */
  restoreCommercialData() {
    this.commercialConfig.user = this.dataShareService.getUserModel();
    this.getCommercialDataFromService();
  }

  /**
   * @description get commercial data from service
   * @author Darshan Vachhani
   * @memberof CommercialBRComponent
   */
  getCommercialDataFromService() {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
    };

    this.commercialService.loadCommercialCampaignDetails(param).subscribe((data) => {
      if (data.status === 'OK') {
        this.commercialConfig.campaignStatus = data.data.campaignStatusId;
        this.commercialConfig.bookingStatus = data.data.bookingStatus;
        this.commercialConfig.uiControl = data.data.uiControl;
        this.commercialData = data.data;

        this.commercialData.bookingStatusName =
          this.campaignDetailsService.getStatusNameFromId(data.data.campaignStatusId);
        const assignedToList = this.dataShareService.getInitialConfigByKey('assignedTo');
        this.campaignDetails = this.campaignDetailsService.populateCampaignDetails(
          this.commercialData, this.initialConfig, assignedToList);
        this.campaignDetailsService.calculateCPT(this.campaignDetails);
        this.historyROC = this.campaignDetailsService.populateHistoryROC(this.historyROC, data.data.auditReasonsHistoryMap, this.commercialConfig.uiControl.historyROC);
      }
    });

  }

  /**
   * @description this is output event from commercialbutton when click on update/option/book/cancel button click
   * @author Darshan Vachhani
   * @param {*} status
   * @memberof CommercialBRComponent
   */
  onBookCampaign(status: number) {
    if (this.validateCommercialCampaign(status)) {
      status = status || this.campaignDetails.campaignStatusId;

      if (this.isROC(status)) {
        this.handleROC(status, this.createRequestJSONForCommercial);
      } else if (this.isConfirm(status)) {
        this.confirmCampaign(this.createRequestJSONForCommercial);
      } else {
        this.validateServiceRequest(status, this.createRequestJSONForCommercial);
      }
    }
  }

  /**
   * @description commercial form validity
   * @author Dhaval Patel
   * @returns {boolean}
   * @memberof CampaignDetailsAUComponent
   */
  validateCommercialCampaign = (status) => {
    const requiredFields: RequiredCampaignFields = new RequiredCampaignFields();
    requiredFields.assignedTo = true;
    let message = this.validateCommonFields(requiredFields);
    if (!message && this.campaignDetails.discounts.length && this.campaignDetails.discounts.filter(discount => discount.negotiationTypeId === -1 || discount.negotiationTypeId === '-1').length) {
      message = this.getNegotiationType();
    }
    if (!message && this.campaignDetails.bookingList) {
      this.campaignDetails.bookingList.forEach((obj) => {
        const requiredBookingsFields: RequiredBookingsFields = new RequiredBookingsFields();
        if (!message) {
          message = this.validateBookingFields(obj, requiredBookingsFields);
        }
        if (!message && !obj.hideMediaControl) {
          message = this.validateMediaLength(obj, status);
        }
      });
    }
    return this.isValid(message);
  }

  getNegotiationType() {
    return this.initialConfig.userBundle['commercial.error.book.negotiationType']
        ? this.initialConfig.userBundle['commercial.error.book.negotiationType'] : 'Please select negotiation type';
  }

  /**
   * @description create requestJSON for passing to backend
   * @author Dhaval Patel
   * @param {*} status
   * @returns
   * @memberof CommercialBRComponent
   */
  createRequestJSONForCommercial = (status) => {
    const campaignDetails = this.campaignDetails;
    const populate: PopulateCommercialFields = this.getCommercialFields();
    const temp = this.populateBookCommercialCampaignParam(status, campaignDetails, populate);
    temp.bookingList = super.populateBookingDetailsServiceObject(campaignDetails, 'bookingList');
    temp.discounts = campaignDetails.discounts;
    return temp;
  }

  /**
   * To override the commercial fields
   * This override fields are used to override the default value
   * @memberof CommercialBRComponent
   * @author Dhaval Patel
   */
  getCommercialFields() {
    return super.getCommercialFields();
  }

}
