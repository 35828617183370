import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, CommercialConfig, HistoryROC } from '../../commercial.model';
import { DataShareService } from '../../../core/services/data-share.service';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import * as _ from 'lodash';
import { StateService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { CampaignDetailsBase } from '../campaign-details-base';
import { SystemFlags } from '../../../models/system-flags';

@Component({
  selector: 'app-campaign-details-au',
  templateUrl: './campaign-details-au.component.html',
  styleUrls: ['./campaign-details-au.component.css']
})
export class CampaignDetailsAuComponent extends CampaignDetailsBase implements OnInit, OnDestroy {
  @Input() campaignDetails: CampaignDetails;
  @Input() commercialConfig: CommercialConfig;
  @Input() historyROC: HistoryROC;
  bookingLineDataSubscription: Subscription;

  /**
   * @description set readOnly
   * @memberof CampaignDetailsUkComponent
   */
  public readOnly = SystemFlags.readOnly;

  /**
   * Creates an instance of CampaignDetailsSgComponent.
   * @author Nishit Parekh
   * @param {DataShareService} dataShareService
   * @param {LookupViewService} lookupViewService
   * @param {CampaignDetailsService} campaignDetailsService
   * @param {StateService} stateService
   * @param {CommercialService} commercialService
   * @memberof CampaignDetailsSgComponent
   */
  constructor(
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    stateService: StateService,
    commercialService: CommercialService
  ) {
    super(dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService
    );
    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe(() => {
      this.onBookingLineChange();
    });
  }

  ngOnDestroy() {
    this.bookingLineDataSubscription.unsubscribe();
  }

  ngOnInit(): void {
    super.init(this.campaignDetails);
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    super.enableSubAgency();
    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);
    this.setLookupConfig();
  }

  // Functions Related to Specialist//
  onSpecialistNameSelected(value: any): void {
    if (!_.isUndefined(this.campaignDetails.specialist)) {
      super.onSpecialistChange(value);
      this.manageGrossMediaValueWithoutIndex(); // This function has to be used in case if the booking grid is used;
    }
  }

  // Functions related to Agencies//
  onAgencyNameSelected(value: any): void {
    if (this.campaignDetails.agency) {
      super.onAgencyChange(value);
      super.enableSubAgency();
    }
  }

  removeSelectedSpecialistName() {
    super.onSpecialistChange();
    this.manageGrossMediaValueWithoutIndex();
  }

  onSpecialistPercentageChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  removeSelectedAgencyName(): void {
    super.onAgencyChange();
    super.enableSubAgency();
    this.manageGrossMediaValueWithoutIndex();
  }

  onAgencyPercentageChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description Manage the gross media value
   * @author Dhaval Patel
   * @memberof CampaignDetailsAuComponent
   */
  manageGrossMediaValueWithoutIndex() {
    super.manageGrossMediaValueWithoutIndex('bookings', false);
  }

  onBookingLineChange() {
    this.campaignDetailsService.populateAgencyPercentage(this.campaignDetails);
    this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
  }

  assignedToChange() {
    this.campaignDetailsService.populateAgencyPercentage(this.campaignDetails);
    this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
    super.enableSubAgency();
    this.setActiveAssignedTo();
  }
}
