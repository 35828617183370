import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataShareService, LogHelperService, DistanceConverterService, LoaderService } from './../../../core/services/index';
import { FilterDataService } from './../../../core/services/filter-data.service';
import { ProximityFilterService } from './../proximity-filter.service';
import { ProximityBaseDirective } from './../proximity-base';

@Component({
  selector: 'app-proximity-filter',
  templateUrl: './proximity-filter.component.html',
  styleUrls: ['./proximity-filter.component.css'],
  providers: [ProximityFilterService]
})
export class ProximityFilterComponent extends ProximityBaseDirective implements OnInit {

  /**
   * @description Object that maintains the state for ProximityFilterComponent
   * @memberof ProximityFilterComponent
   * @author Amit Mahida
   */
  @Input() resolveObject: any;

  /**
   * @memberof ProximityFilterComponent
   * @description Event to handle close event of proximity filter sidebar
   * @author Amit Mahida
   */
  @Output() closeSidebar = new EventEmitter<string>();

  /**
   * @description Creates an instance of ProximityFilterComponent.
   * @param {DataShareService} dataShareService
   * @param {ProximityFilterService} proximityService
   * @param {FilterDataService} filterDataService
   * @param {LogHelperService} logHelperService
   * @param {DistanceConverterService} distanceConverterService
   * @memberof ProximityFilterComponent
   * @author Amit Mahida
   */
  constructor(dataShareService: DataShareService,
    proximityService: ProximityFilterService,
    private filterDataService: FilterDataService,
    logHelperService: LogHelperService,
    distanceConverterService: DistanceConverterService,
    loaderService: LoaderService) {
    super(dataShareService, proximityService, logHelperService, distanceConverterService, loaderService);
    this.resolveObject = {
      SelectedValue: {}
    };
  }

  /**
   * @description Initialise the default proximity values
   * @memberof ProximityFilterComponent
   * @author Amit Mahida
   */
  ngOnInit() {
    this.init(this.filterDataService.getProximity());
  }

  /**
   * @description To set only poi values
   * @memberof ProximityFilterComponent
   * @author Amit Mahida
   */
  poiServiceCall() {
    this.proximityService.getProximityBrickResponseWithoutFile(this.proximityRequestObj(),
      this.proximity.pointOfInterest.userSelectionId).subscribe((data) => {
        if (data.status === 'OK') {
          this.setFileid(data.data, this.proximityTypeSelection.POI);
          this.setSelectionId();
          this.filterDataService.setProximity(this.proximity);
          this.closeSidebar.emit();
        } else {
          this.logHelperService.logError(this.userBundle[this.CONNECTING]);
        }
      });
  }

  poi() {
    if (this.validationForPointOfInterest()) {

      if (this.proximity.postcodeSelection.file && this.proximity.postcodeSelection.listUploadSelection
        && this.proximity.postcodeSelection.distance.distancevalue) {

        // postcode file upload
        this.postCodeFileUpload(null, null, false);
        this.poiServiceCall();

      } else if ((this.proximity.postcodeSelection.selectedModelValues.length > 0)
        && !this.proximity.postcodeSelection.listUploadSelection
        && this.proximity.postcodeSelection.distance.distancevalue) {

        this.postCodeDataUpload(null, null, false);
        this.poiServiceCall();

      } else if (!this.proximity.postcodeSelection.distance.distancevalue) {
        const messsage = this.userBundle[this.DISTANCE_FOR_POSTCODE]
          || this.DISTANCE_FOR_POSTCODE_STR;
        this.logHelperService.logError(messsage);
      } else if (this.proximity.postcodeSelection.file || (this.proximity.postcodeSelection.selectedModelValues.length > 0)) {
        this.logHelperService.logError(this.userBundle[this.RADIO_SELECTION]);
      }

    } else {
      return false;
    }
  }

  postCodeFile(msg: string) {
    // postcode file data to send
    if (this.proximity.postcodeSelection.file && this.proximity.postcodeSelection.listUploadSelection
      && this.proximity.postcodeSelection.distance.distancevalue) {
      this.postCodeFileUpload(msg, null, true);
      // postcode sector data to send
    } else if ((this.proximity.postcodeSelection.selectedModelValues.length > 0)
      && !this.proximity.postcodeSelection.listUploadSelection
      && this.proximity.postcodeSelection.distance.distancevalue) {
      this.proximity.fileName = null;
      this.proximity.file = {};
      this.postCodeDataUpload(msg, null, true);
    } else if (!this.proximity.postcodeSelection.distance.distancevalue) {
      const message = this.userBundle[this.DISTANCE_FOR_POSTCODE]
        || this.DISTANCE_FOR_POSTCODE_STR;
      this.logHelperService.logError(message);
      return false;
    } else if (((this.proximity.postcodeSelection.selectedModelValues.length > 0)
      || this.proximity.postcodeSelection.file) && this.proximity.postcodeSelection.distance.distancevalue) {
      this.logHelperService.logError(this.userBundle[this.RADIO_SELECTION]);
      return false;
    } else {
      const messsage = this.userBundle['common.error.proximity.valueForPostcode']
        || 'Please provide values for postcodes to continue!';
      this.logHelperService.logError(messsage);
    }
  }

  postCode() {
    // Any one radio button with value selected
    if (((this.proximity.postcodeSelection.file
      && this.proximity.postcodeSelection.listUploadSelection)
      || ((this.proximity.postcodeSelection.selectedModelValues.length > 0)
        && !this.proximity.postcodeSelection.listUploadSelection))) {
      return this.poi();
    } else if (!this.proximity.postcodeSelection.file &&
      (this.proximity.postcodeSelection.selectedModelValues.length <= 0) && !this.proximity.postcodeSelection.distance.distancevalue) {
      if (this.validationForPointOfInterest()) {
        this.poiServiceCall();
      } else {
        return false;
      }
    } else if (this.proximity.postcodeSelection.file || (this.proximity.postcodeSelection.selectedModelValues.length > 0)) {
      this.logHelperService.logError(this.userBundle[this.RADIO_SELECTION]);
    } else if (!this.proximity.postcodeSelection.distance.distancevalue) {
      const message = this.userBundle[this.DISTANCE_FOR_POSTCODE]
        || this.DISTANCE_FOR_POSTCODE_STR;
      this.logHelperService.logError(message);
    } else {
      const message = this.userBundle['common.error.proximity.valueForPostcode']
        || 'Please provide values for postcodes to continue!';
      this.logHelperService.logError(message);
    }
  }

  saveClick(msg: string) {

    this.setFileData();

    if (this.proximity.postcodeSelection.file || (this.proximity.postcodeSelection.selectedModelValues.length > 0)
      || this.proximity.pointOfInterest.distance.distancevalue || (this.proximity.pointOfInterest.selectedModelValues.length > 0)
      || this.proximity.postcodeSelection.distance.distancevalue) {

      // poi data to send and check postcode validations
      if ((this.proximity.pointOfInterest.selectedModelValues.length > 0) || this.proximity.pointOfInterest.distance.distancevalue) {
        return this.postCode();
      } else {
        return this.postCodeFile(msg);
      }
    } else {
      const message = this.userBundle['common.error.selectAnyValue']
        || 'Please select any value to continue!';
      this.logHelperService.logError(message);
      return false;
    }
  }

  /**
   * @description Apply action to close this sidebar
   * @param {String} msg
   * @memberof ProximityFilterComponent
   * @author Amit Mahida
   */
  closeClick(msg: string) {
    this.closeSidebar.emit(msg);
  }
  closeComponent(msg) {
    this.filterDataService.setProximity(this.proximity);
    this.closeSidebar.emit(msg);
  }
}
