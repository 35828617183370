import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgbDatepickerHelper } from '../../core/components/ngb-datepicker/ngb-datepicker-helper';
import { LocaleData } from '../../core/utils/LocaleData';
import { UiControl } from '../../models';
import { DataShareService } from '../../core/services';

@Injectable()
export class NgbRangeModalDateParserService extends NgbDateParserFormatter {
  uiControl: UiControl;
  constructor(private datePipe: DatePipe, private dataShareService: DataShareService) {
    super();
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
  }

  parse(value: string): NgbDateStruct {
    if (value) {
      const mobj = moment(value, LocaleData.displayDateFormat.toUpperCase());
      return {
        year: mobj.year(),
        month: mobj.month() + 1,
        day: mobj.date()
      };
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    const obj = NgbDatepickerHelper.convertDateStructToDate(date);
    if (this.uiControl.showDayofWeekRangeBric && date) {
      return `${moment(new Date(obj)).locale(LocaleData.locale).format('dddd')}, ${this.datePipe.transform(obj, LocaleData.displayDateFormat)}`;
    }
    return this.datePipe.transform(obj, LocaleData.displayDateFormat);
  }
}
