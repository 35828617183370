import { Brick } from '../workspace/brick-model';

export class Category {
  constructor(
    public brics: Brick[],
    public categoryid: number,
    public name: string,
    public isAccordionDown = true,
    public hide = false,
  ) { }
}

export class BricsMasterData {
  constructor(public category: Category[]) { }
}
