import { FurnitureModel } from './MapData.model';
export class LookupCampaignResponseModel {
  campaignLookup: LookupCampaignModel[];
}

export class LookupCampaignModel {
  campaignId: number;
  campaignLookupDisplay: string;
  campaignReference?: string;
  brandName?: string;
  advertiserName?: string;
    // internal properties
  exclude?: boolean; // whether to exclude campaign in map plotting
  assetData?: FurnitureModel[];
  startDate?: string; // campaign start date in display format
  endDate?: string; // campaign end date in display format
  campaignStatusName?: string; // campaign status
  plotColor?: string; // plot color on map
}
