<div class="sidetable" [ngClass]="{'readOnly': readOnly, 'open': isSliderOpen}">
  <div (click)="toggleAllocationSliders()" class="adjustmentbtn" [hidden]="uiControl.hideAllocationAdjustment">
    <img src="assets/img/slidingbtn.png" alt="" />
  </div>
  <div id="legendheader" [ngClass]="{open: isSliderOpen}">
    <div class="legendstitle">{{userBundle['worksapce.allocationAdjustment']}}</div>
    <!-- <div class="control_group qFactor" [hidden]="true">
      <label class="label">QuoC</label>
      <div class="slider-wrapper">
        <input type="text" ng-model="campaignParameters.quocFactor" class="js-qFactor" />
      </div>
    </div> -->
    <div class="control_group audience">
      <label class="label">{{userBundle['workspace.rating.bric.audience.heading']}}</label>
      <div class="slider-wrapper">
        <ngx-slider [(value)]="campaignParameters.audienceFactor" [manualRefresh]="manualRefresh" [options]="options"></ngx-slider>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</div>
