import { Component, OnInit } from '@angular/core';
import { CampaignDetailsService } from '../campaign-details/campaign-details.service';
import { HistoryROC } from '../commercial.model';
import { SystemFlags } from '../../models';
import { DataShareService, LogHelperService, StateService } from '../../core/services';
import { CommercialService } from '../../core/services/commercial.service';
import { CommercialButtonService } from '../commercial-buttons/commercial-buttons.service';
import { DatePipe } from '@angular/common';
import { GLOBAL } from '../../core/utils/app.constant';
import { CommercialBase } from '../commercial-base';
import { RequiredCampaignFields, RequiredBookingsFields, PopulateCommercialFields, PopulateBookingDetailFields } from '../../models/RequiredFields';

@Component({
  selector: 'app-commercial-italy',
  templateUrl: './commercial-italy.component.html'
})
export class CommercialItalyComponent extends CommercialBase implements OnInit {
  systemFlags = SystemFlags;

  /**
   * @description commercial data
   * @memberof CommercialItalyComponent
   */
  public commercialData;

  /**
   * @description Accordian for campaign details
   * @memberof CommercialItalyComponent
   */
  hideCampaignDetails: boolean;
  /**
   * @description Accordian for booking details
   * @memberof CommercialItalyComponent
   */
  hideBookingDetails: boolean;

  /**
   * @description Set Accordian Discount Grid
   * @type {boolean}
   * @memberof CommercialItalyComponent
   */
  hideDiscountGrid: boolean;
  /**
   * @description set bookingStatusList
   * @memberof CommercialUkComponent
   */
  bookingStatusList = GLOBAL.bookingStatusList;
  /**
   * @description this will show the history of campaign updated in case of reason/cancel for change
   * @memberof CommercialUsComponent
   */
  historyROC = new HistoryROC();

  constructor(
    stateService: StateService,
    campaignDetailsService: CampaignDetailsService,
    dataShareService: DataShareService,
    commercialService: CommercialService,
    commercialButtonService: CommercialButtonService,
    logHelperService: LogHelperService,
    datePipe: DatePipe
  ) {
    super(stateService, logHelperService, commercialButtonService, dataShareService, commercialService, datePipe, campaignDetailsService);
  }

  ngOnInit() {
    this.restoreCommercialData();
    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  /**
   * @description get commercial data
   * @author Darshan Vachhani
   * @memberof CommercialItalyComponent
   */
  restoreCommercialData() {
    this.commercialConfig.user = this.dataShareService.getUserModel();
    this.getCommercialDataFromService();
  }

  /**
   * @description get commercial data from service
   * @author Darshan Vachhani
   * @memberof CommercialItalyComponent
   */
  getCommercialDataFromService(isManual = false) {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
    };
    this.commercialService.loadCommercialCampaignDetails(param).subscribe((data) => {
      if (data.status === 'OK') {
        this.commercialConfig.campaignStatus = data.data.campaignStatusId;
        this.commercialConfig.bookingStatus = data.data.bookingStatus;
        this.commercialConfig.uiControl = data.data.uiControl;
        this.commercialData = data.data;
        if (isManual) {
          this.dataShareService.updateBookingList(this.commercialData.bookingList);
        }

        this.commercialData.bookingStatusName =
          this.campaignDetailsService.getStatusNameFromId(data.data.campaignStatusId);
        const assignedToList = this.dataShareService.getInitialConfigByKey('assignedTo');
        this.campaignDetails = this.campaignDetailsService.populateCampaignDetails(
          this.commercialData, this.initialConfig, assignedToList);
        if (isManual) {
          this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT);
        } else {
          this.campaignDetailsService.calculateCPT(this.campaignDetails);
        }
        this.historyROC = this.campaignDetailsService.populateHistoryROC(this.historyROC, data.data.auditReasonsHistoryMap, this.commercialConfig.uiControl.historyROC);
      }
    });

  }

  /**
   * @description this is output event from commercialbutton when click on update/option/book/cancel button click
   * @author Darshan Vachhani
   * @param {*} status
   * @memberof CommercialItalyComponent
   */
  onBookCampaign(status: number) {
    if (this.validateCommercialCampaign(status)) {
      status = status || this.campaignDetails.campaignStatusId;

      if (this.isROC(status)) {
        this.handleROC(status, this.createRequestJSONForCommercial);
      } else if (this.isConfirm(status)) {
        this.confirmCampaign(this.createRequestJSONForCommercial);
      } else {
        this.validateServiceRequest(status, this.createRequestJSONForCommercial);
      }
    }
  }

  /**
   * @description commercial form validity
   * @author Darshan Vachhani
   * @returns {boolean}
   * @memberof CommercialItalyComponent
   */
  validateCommercialCampaign = (status) => {
    const campaignDetails = this.campaignDetails;
    const requiredFields: RequiredCampaignFields = new RequiredCampaignFields();
    requiredFields.campaignStartDate = false;
    requiredFields.specialist = true;
    requiredFields.internalAdvertiser = true;
    requiredFields.agency = true;
    requiredFields.subAgency = true;
    requiredFields.title = false;
    let message = this.validateCommonFields(requiredFields);
    if (!message && campaignDetails.bookingList) {
      campaignDetails.bookingList.forEach((obj) => {
        const requiredBookingsFields: RequiredBookingsFields = new RequiredBookingsFields(false, false, true, false, false);
        if (!message) {
          message = this.validateBookingFields(obj, requiredBookingsFields);
        }
        if (!message && obj.containsDigital) {
          message = this.validateMediaLength(obj, status, true);
        }
      });
    }
    return this.isValid(message);
  }

  /**
   * @description create requestJSON for passing to backend
   * @author Darshan Vachhani
   * @param {*} status
   * @returns
   * @memberof CommercialItalyComponent
   */
  createRequestJSONForCommercial = (status) => {
    const campaignDetails = this.campaignDetails;
    const populate: PopulateCommercialFields = this.getCommercialFields();
    const temp = this.populateBookCommercialCampaignParam(status, campaignDetails, populate);
    const populateBooking: PopulateBookingDetailFields = this.getBookingDetailFields();
    temp.bookingList = this.populateBookingDetailsServiceObject(campaignDetails, 'bookingList', populateBooking);
    temp.discounts = campaignDetails.discounts;
    return temp;
  }

  /**
   * To override the commercial fields
   * This override fields are used to override the default value
   * @memberof CommercialItalyComponent
   * @author Dhaval Patel
   */
  getCommercialFields() {
    const populate: PopulateCommercialFields = super.getCommercialFields();
    populate.setPCFields();
    populate.internalAdvertiserId = true;
    populate.campaignReference = false;
    populate.grossMediaValue = false;
    populate.smartBricsValue = false;
    populate.netMediaValue = false;
    populate.financeAmount = true;
    return populate;
  }

  /**
   * To override the booking detail fields
   * This override fields are used to override the default value
   * @memberof CommercialItalyComponent
   * @author Dhaval Patel
   */
  getBookingDetailFields() {
    const populate: PopulateBookingDetailFields = super.getBookingDetailFields();
    populate.pmpdiscount = true;
    populate.impressions = true;
    populate.setCCBIBFields();
    populate.setPOORIFields();
    return populate;
  }

  async manuallyCPTUpdated() {
    const campaigndata = this.createRequestJSONForCommercial(this.campaignDetails.campaignStatusId);
    await this.updateCommercialInfoForCPT(campaigndata);
    this.getCommercialDataFromService(true);
  }
}
