<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
[readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)"
    (saved)="onModalSaved($event)">
    <div class="peg distribution-peg">
    </div>
    <div class="modal-body" [ngClass]="{'readOnly' : readOnlyModal}">
        <div class="control_group">
            <input type="radio" [(ngModel)]="distribution.distributionType" class="radio small" [value]="1" id="rbt-distribution-1">
            <label for="rbt-distribution-1" class="flexSlide">
                {{userBundle['workspace.share.distribution.label.audience']}}
            </label>
        </div>
        <label class="subtitles"></label>
         <div class="control_group">
            <input type="radio" [(ngModel)]="distribution.distributionType" class="radio small" [value]="6" id="rbt-distribution-6">
            <label for="rbt-distribution-6" class="flexSlide">{{userBundle['workspace.share.distribution.label.daySweep']}} <em>({{userBundle['workspace.share.distribution.label.daySweep.info']}})</em></label>

            <input type="radio" [(ngModel)]="distribution.distributionType" class="radio small" [value]="7" id="rbt-distribution-7">
            <label for="rbt-distribution-7" class="flexSlide">{{userBundle['workspace.share.distribution.label.daypartSweep']}} <em>({{userBundle['workspace.share.distribution.label.daypartSweep.info']}})</em></label>

            <input type="radio" [(ngModel)]="distribution.distributionType" class="radio small" [value]="8" id="rbt-distribution-8">
            <label for="rbt-distribution-8" class="flexSlide">{{userBundle['workspace.share.distribution.label.hourSweep']}} <em>({{userBundle['workspace.share.distribution.label.hourSweep.info']}})</em></label>
        </div>
        <label class="subtitles"></label>
        <div class="control_group">
            <input type="radio" [(ngModel)]="distribution.distributionType" class="radio small" [value]="3" id="rbt-distribution-3">
            <label for="rbt-distribution-3" class="flexSlide">{{userBundle['workspace.share.distribution.label.sweep']}}</label>
        </div>
    </div>
</app-sb-modal-wrapper>