<app-summary-bar (filterAreaToggle)="toggleFilterArea()"></app-summary-bar>

<app-filter-bar [isFilterAreaVisible]="isFilterAreaVisible" [initialConfig]="initialConfig"
  (filterBarToggle)="onFilterBarToggle($event)"></app-filter-bar>

<aside class="sidebar-left_geo">
  <app-filter-area [initialConfig]="initialConfig" [readOnlyForSwap]="concertinaCanvasService.readOnlyForSwap"
    (loadBrickDetail)="loadBrickDetailComponent($event)" (hideFilterArea)="toggleFilterArea()"></app-filter-area>
</aside>

<aside class="second-level-sidebar_geo sidebar-left_geo" [ngClass]="{'large-sidebar_geo':isLargeDetailSidebar}"
  [ngStyle]="{'left': secondSliderLeft}">
  <ng-template appBricHost></ng-template>
</aside>

<section class="content-wrapper_geo" [ngStyle]="{'padding-top': isFilterBarExpanded? '114px':'80px'}">
  <div>
    <div style="text-align: center;line-height: 100px;height: 100px;font-size: 24px;font-weight: bold;">
      <app-concertina [config]="config" [availability]="false" [frameDetailsEnabled]="true"
        [frameStatusFiltersEnabled]="true" [frameSearchFilterEnabled]="true" [bookingDetailsEnabled]="true" [frameSelectionEnabled]="true"
        [initialConfig]="initialConfig" [expandedTab]="true" (goToResultPage)="redirectToResultPage()"></app-concertina>
    </div>
  </div>
</section>