import { Component, OnInit, Input } from '@angular/core';
import {
  DataShareService,
  CellAttributeService,
  BrickBaseService,
  LogHelperService,
  SharedService,
  StateService
} from '../../../core/services/index';
import { CellValues } from '../../../models/workspace/index';
import { EnvironmentBase } from '../environment-base';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';
import { ObjectiveService } from '../../../workspace/objective/objective.service';
import { GLOBAL } from '../../../core/utils/app.constant';

@Component({
  selector: 'app-environment-modal',
  templateUrl: './environment-modal.component.html',
  styleUrls: ['./environment-modal.component.css']
})
export class EnvironmentModalComponent extends EnvironmentBase implements OnInit {

  /**
   * @description It will take data to configure modal window.
   * @type {Brick}
   * @memberof EnvironmentModalComponent
   */
  @Input() resolveObject: CellValues;
  readOnlyModal = false;
  isRelativeFilterVisible = false;

  /**
   * Creates an instance of EnvironmentModalComponent.
   * @author Alkesh Shah
   * @param {DataShareService} dataService
   * @param {SharedService} sharedService
   * @param {BrickBaseService} brickBaseService
   * @param {LogHelperService} logHelperService
   * @memberof EnvironmentModalComponent
   */
  constructor(
    private logHelperService: LogHelperService,
    dataShareService: DataShareService,
    sharedService: SharedService,
    brickBaseService: BrickBaseService,
    private cellAttributeService: CellAttributeService,
    appHeaderService: AppHeaderService,
    stateService: StateService,
    objectiveService: ObjectiveService
  ) {
    super(dataShareService, brickBaseService, sharedService, stateService, appHeaderService, objectiveService);
  }

  /**
   * @description angular life cycle hook - called on component init
   * @author Alkesh Shah
   * @memberof EnvironmentModalComponent
   */
  ngOnInit() {
    this.isRelativeFilterVisible = this.resolveObject.reshufflingEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_LS || this.resolveObject.reshufflingEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH;
    const selectedValues = this.resolveObject.selectedValues && !this.resolveObject.selectedValues.hasOwnProperty('-99')
      ? this.resolveObject.selectedValues : {};
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    const allowedChannels: any = this.getLinkedChannels();
    if (allowedChannels && allowedChannels.length > 0) {
      this.configLookup = allowedChannels;
    } else {
      // We will never get the values for Environment Brick in LookUPData for SelectionID:34. Hence always take from Initial Config //
      this.configLookup = null;
    }
    this.init(selectedValues);
  }

  /**
   * @description callback to close modal popup window
   * @author Alkesh Shah
   * @param {*} event
   * @memberof EnvironmentModalComponent
   */
  onModalClosed(event) {
    const isSelected = this.selected['34'] ? this.selected['34'].length : null;
    if (event.reason === 'escape' && isSelected > 0) {
      if (window.confirm(this.initialConfig['userBundle']['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
   }

  /**
   * @description callback function - called during save click
   * @author Alkesh Shah
   * @param {*} event
   * @memberof EnvironmentModalComponent
   */
  onModalSaved(event) {
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    if (this.isValidSelection()) {
      values.selectedValues = this.selected;
      values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Environment, values.selectedValues);
      values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Environment,
        values.selectedValues);
      values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Environment, values.selectedValues);
      event.activeModal.close(values);
    } else {
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.noDataSelect']);
    }
  }

  trackBySelectionId(index, lookup) {
    return lookup?.selectionId;
  }

}
