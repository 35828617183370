/* tslint:disable variable-name function-name */
import { Directive, ElementRef, Renderer2, forwardRef, Input, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { LocaleData } from '../utils/LocaleData';

export const NUMBER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NumberMaskDirective),
  multi: true
};

/**
 * 1. scale (number) -> Digits after a point, 0 for integers
 * 2. allowNegative = false (boolean) -> Allow negative values
 * 3. withThousandsSeparator = false (boolean) -> With thousands separator
 * 4. min (number) -> Minimum value
 * 5. max (number) -> Maximum value
 * 6. padFractionalZeros = false (boolean) -> If true, then pads zeros at end to the length of scale
 * 7. mapToDecimalSeparator = '.' (string) -> Map keys to decimal separator
 * 8. defaultValue = null (number) -> On blur, the default value will be set if display value is ''
 *
 * <input type="text" class="form-control" placeholder="ID" [(ngModel)]="temp" appNumberMask
 *      [withThousandsSeparator]="true" [allowNegative]="true" [min]="-10" [max]="10000" [scale]="2"
 *      [padFractionalZeros]="true" mapToDecimalSeparator="." />
 */

@Directive({
  selector: '[appNumberMask]',
  providers: [NUMBER_VALUE_ACCESSOR]
})
export class NumberMaskDirective implements ControlValueAccessor {
  @Input() scale: number; // Digits after point, 0 for integers
  @Input() allowNegative = false; // Allow negative values
  @Input() withThousandsSeparator = false; // With thousands separator
  @Input() min: number; // Minimum value
  @Input() max: number; // Maximum value
  @Input() padFractionalZeros = false; // If true, then pads zeros at end to the length of scale
  @Input() mapToDecimalSeparator = '.'; // Map keys to decimal separator
  @Input() defaultValue: number = null; // On blur, the default value will be set if display value is ''

  private _value: number;
  private _displayValue: string;
  private _cursorPosition: number;
  private _keyDownEventInfo: {
    key: string,
    target: {
      value: string,
      selectionStart: number,
      selectionEnd: number,
    }
  };
  private _displayValueBefore = '';

  get value(): number {
    return this._value;
  }

  set value(value: number) {
    const displayValue = this.formatNumber(value);

    this._displayValue = displayValue;
    const newCursorPosition = this.calculateCursorPosition(this._displayValueBefore, displayValue);
    this.setElementDisplayValue(displayValue);
    this.setCursorPosition(newCursorPosition);

    this._value = value;
    this.onChange(value);
  }

  get displayValue(): string {
    return this._displayValue;
  }

  set displayValue(displayValue: string) {
    const value = this.parseNumber(displayValue);
    this._value = value;
    this.onChange(value);

    this._displayValue = displayValue;
    const newCursorPosition = this.calculateCursorPosition(this._displayValueBefore, displayValue);
    this.setElementDisplayValue(displayValue);
    this.setCursorPosition(newCursorPosition);
  }

  get isNegativeAllowed(): boolean {
    return this.allowNegative && (this.min === undefined || this.min < 0);
  }

  readonly REGEXP = {
    NEGATIVE_INTEGER: new RegExp(/^-?[0-9]*$/),
    INTEGER: new RegExp(/^[0-9]*$/),
    SEPARATE_STRING_BY_3: new RegExp(/\B(?=(\d{3})+(?!\d))/, 'g'),
    LEADING_ZEROS: new RegExp(/^0+(?=\d)/),
    DOT_GLOBAL: new RegExp('\\.', 'g'),
    NUM_DECIMAL_SEP_GLOBAL: new RegExp(`\\${LocaleData.NUM_DECIMAL_SEP}`, 'gi'),
    NUM_GROUP_SEP_GLOBAL: new RegExp(`\\${LocaleData.NUM_GROUP_SEP}`, 'gi'),
  };

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  onChange = (_: any) => { };

  onTouched = () => { };

  registerOnChange(fn: (_: number | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: number): void {
    this.value = value;
  }

  separateByDecimalPoint = (string: string): string[] => {
    const parts = string.split(LocaleData.NUM_DECIMAL_SEP);

    if (parts.length > 2) { throw new Error('Only 1 decimal separator is allowed'); }

    const [num, dec = ''] = parts;
    return [num, dec];
  }

  padFractionalZerosToLength = (value: string): string => {
    if (this.padFractionalZeros && this.scale > 0 && value) {
      const [num, dec] = this.separateByDecimalPoint(value);
      return num.concat(LocaleData.NUM_DECIMAL_SEP, dec.padEnd(this.scale, '0'));
    }

    return value;
  }

  parseDecimalSeparator = (value: string) => value.replace(this.REGEXP.NUM_DECIMAL_SEP_GLOBAL, '.');

  formatDecimalSeparator = (value: string) => value.replace(this.REGEXP.DOT_GLOBAL, LocaleData.NUM_DECIMAL_SEP);

  insertThousandsSeparators = (value: string): string => {
    if (this.withThousandsSeparator) {
      const parts = this.removeThousandsSeparators(value).split(LocaleData.NUM_DECIMAL_SEP);
      parts[0] = parts[0].replace(this.REGEXP.SEPARATE_STRING_BY_3, LocaleData.NUM_GROUP_SEP);
      return parts.join(LocaleData.NUM_DECIMAL_SEP);
    }
    return value;
  }

  removeThousandsSeparators = (value: string): string => {
    if (this.withThousandsSeparator) {
      return value.replace(this.REGEXP.NUM_GROUP_SEP_GLOBAL, '');
    }
    return value;
  }

  parseNumber = (displayValue: string): number => {
    const value = displayValue === '' ? null : parseFloat(this.parseDecimalSeparator(this.removeThousandsSeparators(displayValue)));
    return Number.isNaN(value) ? null : value;
  }

  formatNumber = (value: number): string => (value === null || value === undefined) ? '' : this.insertThousandsSeparators(this.formatDecimalSeparator(value.toString()));

  setElementDisplayValue = (displayValue: string) => {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', displayValue);
  }

  setElementSelectionRange = (from: number, to: number) => {
    this.elementRef.nativeElement.setSelectionRange(from, to);
  }

  setCursorPosition = (cursorPosition: number) => {
    this._cursorPosition = cursorPosition;
    this.setElementSelectionRange(cursorPosition, cursorPosition);
  }

  getThousandsSeparatorsCount = (displayValue: string): number => (displayValue.match(this.REGEXP.NUM_GROUP_SEP_GLOBAL) || []).length;

  calculateCursorPosition = (displayValueBefore: string, displayValueAfter: string): number =>
    this._cursorPosition + this.getThousandsSeparatorsCount(displayValueAfter) - this.getThousandsSeparatorsCount(displayValueBefore)

  validateMinMax = (value: number): boolean => {
    const exceedsMinLimit = this.min !== undefined && this.min > value;
    const exceedsMaxLimit = this.max !== undefined && value > this.max;
    if (value !== null && (exceedsMinLimit || exceedsMaxLimit)) {
      return false;
    }

    return true;
  }

  isNumberStringValid = (numberString: string): boolean => {
    try {
      const [num, dec] = this.separateByDecimalPoint(this.removeThousandsSeparators(numberString));

      const regex = this.isNegativeAllowed ? this.REGEXP.NEGATIVE_INTEGER : this.REGEXP.INTEGER;

      if (!regex.test(num)) { return false; }
      if (!this.REGEXP.INTEGER.test(dec)) { return false; }

      const value = this.parseNumber(numberString);
      if (!Number.isNaN(value) && !this.validateMinMax(value)) { return false; }

      if (this.scale <= 0 && dec.length) { return false; }

      if (this.scale > 0 && dec.length > this.scale) { return false; }

      return true;
    } catch (error) {
      return false;
    }
  }

  @HostListener('input', ['$event'])
  handleInput = (event: KeyboardEvent) => {
    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;

    this._cursorPosition = eventTarget.selectionStart;
    this._displayValueBefore = eventTarget.value;

    // Replace keys with decimal separator i.e. defined in mapToDecimalSeparator
    const displayValueWithDecimalSeparator: string = this._keyDownEventInfo.target.value.slice(0, this._keyDownEventInfo.target.selectionStart) + LocaleData.NUM_DECIMAL_SEP + this._keyDownEventInfo.target.value.slice(this._keyDownEventInfo.target.selectionEnd);
    if (this.mapToDecimalSeparator.indexOf(this._keyDownEventInfo.key) > -1 && LocaleData.NUM_DECIMAL_SEP !== '.' && this.isNumberStringValid(displayValueWithDecimalSeparator)) {
      this._displayValueBefore = displayValueWithDecimalSeparator;
      this.displayValue = this.insertThousandsSeparators(displayValueWithDecimalSeparator);
      return;
    }

    // When user deletes decimal separator
    const isDecimalSeparatorRemoved = this.displayValue === eventTarget.value.slice(0, this._cursorPosition) + LocaleData.NUM_DECIMAL_SEP + eventTarget.value.slice(this._cursorPosition);
    if (isDecimalSeparatorRemoved && !this.validateMinMax(this.parseNumber(eventTarget.value))) {
      this.value = parseInt(this.value.toString(), 10);
      return;
    }

    // When user types valid values
    if (this.isNumberStringValid(eventTarget.value)) {
      this.displayValue = this.insertThousandsSeparators(eventTarget.value);
      return;
    }

    // When user types invalid values
    if (this.isNumberStringValid(this.displayValue)) {
      this.setElementDisplayValue(this.displayValue);
      this.setElementSelectionRange(this._keyDownEventInfo.target.selectionStart, this._keyDownEventInfo.target.selectionEnd);
      return;
    }

    this.displayValue = '';
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown = (event: KeyboardEvent) => {
    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;

    this._keyDownEventInfo = {
      key: event.key,
      target: {
        value: eventTarget.value,
        selectionStart: eventTarget.selectionStart,
        selectionEnd: eventTarget.selectionEnd,
      }
    };

    // Set cursor position on deleting thousands separator
    if (this.withThousandsSeparator) {
      const isThousandsSeparatorRemovedUsingDelete = event.key === 'Delete' && eventTarget.value.substr(eventTarget.selectionStart, LocaleData.NUM_GROUP_SEP.length) === LocaleData.NUM_GROUP_SEP;
      if (isThousandsSeparatorRemovedUsingDelete) {
        this.setCursorPosition(eventTarget.selectionStart + LocaleData.NUM_GROUP_SEP.length);
        event.preventDefault();
      }

      const isThousandsSeparatorRemovedUsingBackspace = event.key === 'Backspace' && eventTarget.value.substr(eventTarget.selectionStart - LocaleData.NUM_GROUP_SEP.length, LocaleData.NUM_GROUP_SEP.length) === LocaleData.NUM_GROUP_SEP;
      if (isThousandsSeparatorRemovedUsingBackspace) {
        this.setCursorPosition(eventTarget.selectionStart - LocaleData.NUM_GROUP_SEP.length);
        event.preventDefault();
      }
    }

    // Do not allow to type decimal separator if scale is 0 or value is set to min bound for negative values and max bound for 0 and positive values
    const value = this.parseNumber(eventTarget.value);
    const isSetToMinBoundary = this.min !== undefined && this.min < 0 && value === this.min;
    const isSetToMaxBoundary = this.max !== undefined && this.max >= 0 && value === this.max;
    if ((this.scale <= 0 || isSetToMinBoundary || isSetToMaxBoundary)
      && (event.key === LocaleData.NUM_DECIMAL_SEP || this.mapToDecimalSeparator.indexOf(event.key) > -1)) {
      event.preventDefault();
    }

    // Prevent leading zeros
    const tempValue: string = this.parseDecimalSeparator(this.removeThousandsSeparators(
      eventTarget.value.slice(0, eventTarget.selectionStart) + event.key + eventTarget.value.slice(eventTarget.selectionEnd))
    );
    const hasLeadingZeros = this.REGEXP.LEADING_ZEROS.test(tempValue);
    if (event.key === '0' && hasLeadingZeros) {
      event.preventDefault();
    }
  }

  @HostListener('blur', ['$event'])
  handleBlur = (event: FocusEvent) => {
    const displayValue = (event.target as HTMLInputElement).value;

    if (!this.isNumberStringValid(displayValue)) {
      return;
    }

    const [num, dec] = this.separateByDecimalPoint(displayValue);

    if (this.value === null || num === '') {
      this.displayValue = this.padFractionalZerosToLength(this.formatNumber(this.defaultValue));
      return;
    }

    if (this.scale > 0) {
      this.displayValue = this.padFractionalZerosToLength(dec ? displayValue : num);
      return;
    }

    this.displayValue = this.padFractionalZerosToLength(displayValue);
  }
}
