import { InitialConfigModel, RequestJSONModel, Filter, Cell, ColumnHelper, ColumnSummary } from '../models';
import { BrickBaseService, SharedService, CellAttributeService, StateService } from '../core/services';
import { DistributionEnum } from '../bricks/distribution/distribution-enum';
import { Brick } from './brick-model';
import { PopulateSelected } from './populate-selected';
import { GLOBAL } from '../core/utils/app.constant';
import { ColumnConfigs } from '../models/MapData.model';
import * as _ from 'lodash';

declare let _: _.LoDashStatic;

export class LoadWorkspace {
  /**
   * Creates an instance of LoadWorkspace.
   * @author Alkesh Shah
   * @param {BrickBaseService} brickBaseService
   * @memberof LoadWorkspace
   */
  constructor(
    private brickBaseService: BrickBaseService,
    private cellAttributeService: CellAttributeService,
    private sharedService: SharedService,
    private stateService: StateService
  ) {
  }

  // @ts-ignore
  getWorkspaceFilter(filterObj: Filter, bricsData: RequestJSONModel[], initialConfig: InitialConfigModel, masterData, columnConfig: ColumnConfigs, columnSummary: { [key: string]: ColumnSummary }, lookUp, productMode = false, columnLineNumber, columnHelper?: ColumnHelper, isObjective = false): Filter { // tslint:disable-line
    const populateSelected = new PopulateSelected();
    // calculate maximum nu of column in any row
    let maxColumns = 0;
    filterObj.rows = [];
    // group bricsData by row
    let rowWiseBricData: any = _.groupBy(bricsData, (curObj: RequestJSONModel) => {
      if (curObj.columns.length > 0) {
        maxColumns = maxColumns < curObj.columns[curObj.columns.length - 1] ? curObj.columns[curObj.columns.length - 1] : maxColumns;
      }
      return curObj.row;
    });
    rowWiseBricData = Object.keys(rowWiseBricData).map(key => rowWiseBricData[key]);
    const dateForPattern = [];

    // get bric json text as key and bric id as value object
    const bricReqTextWithIdMap = this.getBrickReqJsonTextIdMap();
    for (const curRow of rowWiseBricData) {
      const firstCell = curRow[0]; // first cell in current row
      const rowKeys = Object.keys(firstCell).sort();
      const lastKey = rowKeys[rowKeys.length - 1] === 'selectionNetworks' ? 'selectionCriteriaNetwork' : rowKeys[rowKeys.length - 1]; // req json selection text
      //'selectionNetworks' property was newly added in SM-11651 so to bypass added the above condition
      // @ts-ignore
      let bricDetailsMaster: Brick = null; // bric master object
      if (lastKey !== 'columns' && lastKey !== 'row') {
        // get bric id from bric req json text
        let bricId = parseInt(bricReqTextWithIdMap[lastKey], 10);
        // For format bric, it has 2 different key in json so need to handle
        if (lastKey === 'selectionCriteriaRestriction') {
          bricId = parseInt(this.brickBaseService.brickID.Format.toString(), 10);
        }
        // for primary and secondary audience, need to get bric master using audience category group id
        let audienceCategoryGroupId = null;
        if (lastKey === "selectionFrameReduction"){
          curRow.forEach(cell => {
            if (cell.selectionFrameReduction?.allocatedFrameCount){
              cell.selectionFrameReduction.allocated = cell.selectionFrameReduction.allocatedFrameCount;
              delete cell.selectionFrameReduction.allocatedFrameCount;
            } }
            )
        }
        if (bricId === this.brickBaseService.brickID.Audience || bricId === this.brickBaseService.brickID.SecondaryAudience
          || bricId === this.brickBaseService.brickID.PrimaryAudience
        ) {
          if (firstCell[lastKey] && firstCell[lastKey].hasOwnProperty('audienceCategoryGroupId')) {
            audienceCategoryGroupId = firstCell[lastKey].audienceCategoryGroupId;
          } else {
            audienceCategoryGroupId = this.getAudienceCategoryGroupId(firstCell[lastKey].audienceCategoryId, initialConfig.audienceCategoryGroup); // tslint:disable-line
          }
        }
        bricDetailsMaster = this.getBricDetailsFromMasterJSON(bricId, masterData, audienceCategoryGroupId);

        // create blank row and max number of cells
        filterObj.createRow(bricDetailsMaster, firstCell.row, maxColumns, curRow, lastKey);

        // fill selected and display text properties of cell
        for (const cellData of curRow) {
          const cellIndex = cellData.columns[0];
          const filterCell: Cell = filterObj.rows[rowWiseBricData.indexOf(curRow)].cells[cellIndex];
          // SD :  Commented the below condition it is invalid to set the isMandatoryForProduct flag unless we have the Product Details : SM-4577 //
          // if (filterObj.checkIfCellExistsInColumn(cellIndex, Number(this.brickBaseService.brickID.ProductCatalogue)) && !productMode) {
          //   filterCell.isMandatoryForProduct = !this.workspaceService.isOptionalMandatoryBRIC(bricId);
          // }
          if (lastKey === this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Incharge]) {
            for (const column of cellData.columns) {
              dateForPattern[parseInt(column, 10)] = _.cloneDeep(cellData.selectionPeriod);
            }
          }

          if (!isObjective && columnHelper && Object.keys(columnHelper).length) {
            for (const col of cellData.columns) {
              if (columnHelper[col]) {
                filterCell.isEditable = !columnHelper[col].systemLocked;
                filterCell.isLocked = true;
              }
            }
          }
          let brickID: number | string = 0;
          switch (lastKey) {
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Incharge]:
              brickID = this.brickBaseService.brickID.Incharge;
              filterCell.selected = populateSelected.getInchargeSelectedValue(cellData[lastKey]);
              break;
            // Budget Bric
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Budget]:
              brickID = this.brickBaseService.brickID.Budget;
              if ((Object.keys(cellData.selectionBudget).length === 0 ||
                Object.keys(cellData[lastKey]).length === 1 && cellData[lastKey].hasOwnProperty('allocateAllFrames')) || cellData.selectionBudget.isAllSelection) {
                cellData[lastKey] = GLOBAL.allSelection;
              }
              filterCell.selected = cellData[lastKey];
              if (!isObjective && columnSummary && columnSummary[filterCell.cellIndex] && !productMode) {
                filterCell.selected['allocated'] = columnSummary[filterCell.cellIndex].price;
              }
              if (isObjective) {
                filterCell.selected['allocated'] = filterCell.selected.allocatedPrice;
              }
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Distribution]:
              brickID = this.brickBaseService.brickID.Distribution;
              filterCell.selected = cellData[lastKey];
              if (filterCell.selected) {
                const distributionEnum = new DistributionEnum().getDistributionEnum(initialConfig.userBundle);
                filterCell.selected.distributionType = parseInt(filterCell.selected.distributionType.toString(), 10);
                filterCell.selected.distributionName = distributionEnum[filterCell.selected.distributionType];
              }
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Media]:
              brickID = this.brickBaseService.brickID.Media;
              filterCell.selected = populateSelected.getMediaSelectedValue(cellData[lastKey], initialConfig.mediaType);
              for (let allocationIndex = filterCell.cellIndex; allocationIndex < (filterCell.cellIndex + filterCell.cellWidth); allocationIndex += 1) {
                if (columnSummary && columnSummary[allocationIndex] && columnSummary[allocationIndex].allocationEngine) {
                  filterObj.allocationEngines[allocationIndex] = columnSummary[allocationIndex].allocationEngine;
                } else {
                  filterObj.allocationEngines[allocationIndex] = filterCell.selected.selectedMediaObject.defaultAllocationEngine;
                }
              }
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Environment]:
              brickID = this.brickBaseService.brickID.Environment;
              filterCell.selected = populateSelected.getEnvironmentSelectedValue(cellData[lastKey], bricId, initialConfig);
              break;
            // Frame Quality bric
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.FrameQuality]:
              brickID = this.brickBaseService.brickID.FrameQuality;
              if (!cellData[lastKey].hasOwnProperty('audienceLevel')) {
                filterCell.selected = GLOBAL.allSelection;
                break;
              }
              filterCell.selected = cellData.selectionFrameQuality;
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.ProductCatalogue]:
              brickID = this.brickBaseService.brickID.ProductCatalogue;
              filterCell.selected = populateSelected.getProductSelectedValue(cellData[lastKey], initialConfig);
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Channel]:
              brickID = this.brickBaseService.brickID.Channel;
              filterCell.selected = populateSelected.getChannelSelectedValue(cellData[lastKey], initialConfig);
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Pricing]:
              brickID = this.brickBaseService.brickID.Pricing;
              filterCell.selected = cellData[lastKey].pricingFactor;
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.SOT]:
              brickID = this.brickBaseService.brickID.SOT;
              filterCell.selected = populateSelected.getSotSelectedValue(cellData[lastKey], productMode, initialConfig);
              if (!productMode) {
                const colSum = this.sharedService.getColumnSummaries(columnSummary, filterCell.cellIndex, (filterCell.cellIndex + filterCell.cellWidth));
                this.sharedService.setSOTMaxFramesSlotsObj(filterCell.selected, colSum);
              }
              filterCell.requestJSON = this.cellAttributeService.getBrickRequestJSON(brickID, filterCell.selected);
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.SecondaryAudience]:
              brickID = this.brickBaseService.brickID.SecondaryAudience;
              filterCell.selected = populateSelected.getSecondaryAudienceSelectedValue(cellData, audienceCategoryGroupId, initialConfig);
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Audience]:
              brickID = this.brickBaseService.brickID.Audience;
              filterCell.selected = populateSelected.getSelectionAudienceSelectedValue(cellData, audienceCategoryGroupId, initialConfig);
              filterCell.requestJSON = this.cellAttributeService.getBrickRequestJSON(brickID, filterCell.selected);
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Volume]:
              brickID = this.brickBaseService.brickID.Volume;
              filterCell.selected = populateSelected.getSelectionVolumeSelectedValue(cellData[lastKey]);
              if (!isObjective && columnSummary && columnSummary[filterCell.cellIndex]) {
                filterCell.selected['allocated'] = columnSummary[filterCell.cellIndex].audienceImpressions;
                if (filterCell.requestJSON.selectionAudienceReduction.totalFtgIteration) {
                  filterObj.lastFtgIteration = filterCell.requestJSON.selectionAudienceReduction.totalFtgIteration;
                }
                if (filterObj.checkFtgIterationAllowed()) {
                  filterCell.selected.breakFTGIteration = true;
                }
              }
              if (isObjective) {
                filterCell.selected['allocated'] = filterCell.selected.allocatedImpressions;
              }
              if (initialConfig.uiControl.toleranceEnabled && !productMode) {
                filterCell.selected.tolerance = cellData.selectionAudienceReduction.tolerance || 0;
              }
              filterCell.requestJSON = this.cellAttributeService.getBrickRequestJSON(brickID, filterCell.selected);
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Tag]:
              brickID = this.brickBaseService.brickID.Tag;
              filterCell.selected = populateSelected.getTagSelectedValue(cellData[lastKey], initialConfig);
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.PricingTag]:
              brickID = this.brickBaseService.brickID.PricingTag;
              filterCell.selected = populateSelected.getPricingTagSelectedValue(
                cellData[lastKey],
                initialConfig,
                columnConfig && columnConfig[cellIndex] ? columnConfig[cellIndex] : null,
                Number(this.brickBaseService.brickID.PricingTag)
              );
              if(cellData['selectionNetworks']){
                const { selectionNetworks: { status, networkData } } = cellData;
                let lookupColumnData = this.sharedService.getLookupColumnData(this.brickBaseService.brickID.PricingTag, null);
                filterCell.selected[lookupColumnData.lookup[0].selectionId].forEach((network) =>{
                  let matchedData = _.find(networkData, nwrk => nwrk.networkId === network.networkId);
                  network.status = matchedData?.status;
                });
                filterCell.selected.status = status;
              }
              break;
            // Format bric
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Format]:
            case 'selectionCriteriaRestriction':
              brickID = this.brickBaseService.brickID.Format;
              filterCell.selected =
                populateSelected.getFormatSelectedValue(cellData, bricId, initialConfig);
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Proximity]:
              brickID = this.brickBaseService.brickID.Proximity;
              filterCell.selected = populateSelected.getSelectionProximitySelectedValue(cellData, initialConfig.poiTypes, initialConfig.uiControl);
              break;
            // 8: Frame brics
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Frame]:
              brickID = this.brickBaseService.brickID.Frame;
              filterCell.selected = populateSelected.getFrameSelectedValue(cellData[lastKey]);
              if (!isObjective && columnSummary && columnSummary[filterCell.cellIndex]) {
                filterCell.selected['allocated'] = columnSummary[filterCell.cellIndex].numberFrames;
              }
              if (isObjective) { //this condition can be removed totally but kept it so don't break for unseen conditions
                filterCell.selected['allocated'] = filterCell.selected.allocatedFrameCount ? filterCell.selected.allocatedFrameCount : filterCell.selected.allocated;
              }
              if (initialConfig.uiControl.toleranceEnabled && !productMode) {
                filterCell.selected.tolerance = cellData.selectionFrameReduction.tolerance || 0;
              }
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Location]:
              brickID = this.brickBaseService.brickID.Location;
              filterCell.selected =
                populateSelected.getLocationSelectedValue(cellData[lastKey], bricId, initialConfig, lookUp, this.sharedService, columnConfig[cellIndex]);

              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Pattern]:
              brickID = this.brickBaseService.brickID.Pattern;
              filterCell.selected =
                populateSelected.getPatternSelectedValue(cellData, dateForPattern, initialConfig.dayPartGroup, columnConfig, cellIndex);
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.List]:
              brickID = this.brickBaseService.brickID.List;
              filterCell.selected =
                populateSelected.getListSelectedValue(cellData[lastKey]);
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.AllAudience]:
              brickID = this.brickBaseService.brickID.AllAudience;
              filterCell.selected = populateSelected.getAllAudienceSelectedValue(cellData[lastKey], initialConfig);
              filterCell.requestJSON = this.cellAttributeService.getBrickRequestJSON(brickID, filterCell.selected);
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.PrimaryAudience]:
              brickID = this.brickBaseService.brickID.PrimaryAudience;
              filterCell.selected = populateSelected.getPrimaryAudienceSelectedValue(cellData, bricDetailsMaster.audienceCategoryGroupId, initialConfig.audienceCategoryGroup);
              filterCell.requestJSON = this.cellAttributeService.getBrickRequestJSON(brickID, filterCell.selected);
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Objective]:
              const objectiveFilterObj: Filter = this.stateService.getFreshFilterObj();
              brickID = this.brickBaseService.brickID.Objective;
              const objectiveFilter = this.getWorkspaceFilter(
                objectiveFilterObj,
                cellData[lastKey].bricsData,
                initialConfig,
                masterData,
                columnConfig,
                columnSummary,
                lookUp,
                null,
                columnLineNumber,
                columnHelper,
                true
              );
              filterCell.selected = {
                objectives: objectiveFilter.rows,
                status: cellData[lastKey].status,
                disableObjective: cellData[lastKey].disableObjective,
                isAllSelection: cellData[lastKey].isAllSelection
              };
              filterCell.requestJSON = {};
              if(cellData[lastKey].isAllSelection){
                filterCell.requestJSON[this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Objective]] = {
                  "-99":{"-99":-99},"isAllSelection":true
                }
              }else{
                filterCell.requestJSON[this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Objective]] = {
                  bricsData: cellData[lastKey].bricsData
                };
              }
              this.stateService.setObjectiveObject('filter', this.stateService.clone(objectiveFilter));
              break;
            case this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.MultiTarget]:
              brickID = this.brickBaseService.brickID.MultiTarget;
              filterCell.selected =
                populateSelected.getMultiTargetSelectedValue(cellData[lastKey], productMode);
              if (filterCell.selected && filterCell.selected.listUpload && filterCell.selected.listUpload.fileList && filterCell.selected.listUpload.fileList[0] && filterCell.selected.listUpload.fileList[0].selectedSOTOption) {
                filterCell.selected.selectedSOTOption = filterCell.selected.listUpload.fileList[0].selectedSOTOption;
              }
              break;
            default:
              brickID = 0;
              break;
          }
          if (Number(brickID) > 0) {
            filterCell.displayText = this.cellAttributeService.getDisplayText(brickID, filterCell.selected);
            const lookups = this.sharedService.getLookupColumnData(bricId, columnConfig[cellIndex]);
            filterCell.toolTipText = this.cellAttributeService.getToolTip(brickID, filterCell.selected, lookups ? lookups['lookup'] : null);
          }
        }
      }
    }
    filterObj.generateBrickRequestJSON();
    return filterObj;
  }

  /**
   * @description get the bric request json text as key with bric id as value
   * @author Alkesh Shah
   * @returns
   * @memberof LoadWorkspace
   */
  private getBrickReqJsonTextIdMap() {
    const ret = {};
    for (const key in this.brickBaseService.brickReqJsonText) {
      ret[this.brickBaseService.brickReqJsonText[key]] = key;
    }
    return ret;
  }

  /**
   * @description get the bric object from master data using bric id
   * @author Alkesh Shah
   * @param {number} bricid - bric id to look up
   * @param {*} masterdata - master bric data list
   * @param {*} audienceCategoryGroupId
   * @returns
   * @memberof LoadWorkspace
   */
  private getBricDetailsFromMasterJSON(bricid: number, masterdata, audienceCategoryGroupId): Brick {
    let tmpBric: Brick = null;

    if (masterdata && bricid) {
      for (const category of masterdata.category) {
        for (const bricObj of category.brics) {
          if (audienceCategoryGroupId) {
            if (bricObj.bricid === bricid && bricObj.audienceCategoryGroupId === audienceCategoryGroupId) {
              tmpBric = bricObj;
              tmpBric.categoryid = category.categoryid;
              tmpBric.categoryName = category.name;
              break;
            }
          } else {
            if (bricObj.bricid === bricid) {
              tmpBric = bricObj;
              tmpBric.categoryid = category.categoryid;
              tmpBric.categoryName = category.name;
              break;
            }
          }
        }
      }
    }
    return tmpBric;
  }

  /**
   * @description get the audience category group id from audience category id
   * @author Alkesh Shah
   * @param {number} audienceCategoryId
   * @param {*} audienceCategoryGroup
   * @returns {number}
   * @memberof LoadWorkspace
   */
  getAudienceCategoryGroupId(audienceCategoryId: number, audienceCategoryGroups): number {
    let audienceCategoryGroupId: number = null;
    if (audienceCategoryId) {
      for (const audienceCategoryGroup of audienceCategoryGroups) {
        for (const audienceCategoryType of audienceCategoryGroup.audienceCategoryType) {
          for (const audienceCategory of audienceCategoryType.audienceCategory) {
            if (audienceCategoryGroup.audienceCategoryGroupId === 3) {
              if (audienceCategory.audienceCategory) {
                for (const audienceCategoryChild of audienceCategory.audienceCategory) {
                  if (audienceCategoryChild.audienceCategoryId) {
                    if (audienceCategoryChild.audienceCategoryId === audienceCategoryId) {
                      audienceCategoryGroupId = audienceCategoryGroup.audienceCategoryGroupId;
                      break;
                    }
                  }
                }
              } else if (audienceCategoryType.audienceCategory.length) {
                for (const audienceCategoryChild of audienceCategoryType.audienceCategory) {
                  if (audienceCategoryChild.audienceCategoryId) {
                    if (audienceCategoryChild.audienceCategoryId === audienceCategoryId) {
                      audienceCategoryGroupId = audienceCategoryGroup.audienceCategoryGroupId;
                      break;
                    }
                  }
                }
              }
            } else {
              if (audienceCategory.audienceCategoryId) {
                if (audienceCategory.audienceCategoryId === audienceCategoryId) {
                  audienceCategoryGroupId = audienceCategoryGroup.audienceCategoryGroupId;
                  break;
                }
              }
            }
          }
        }
      }
    }
    return audienceCategoryGroupId;
  }
}
