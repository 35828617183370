import { Component } from '@angular/core';
import { CellRendererBaseComponent } from '../cell-renderer-base/cell-renderer-base.component';

@Component({
  selector: 'app-ag-toggle-cell-renderer',
  templateUrl: './ag-toggle-cell-renderer.component.html',
  styleUrls: ['./ag-toggle-cell-renderer.component.css']
})
export class AgToggleCellRendererComponent extends CellRendererBaseComponent {
  cssClass: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.cssClass = this.params.data[this.params.column.colId] ? this.params.class_on() : this.params.class_off();
    if (
      this.params.data.lockedEditable &&
      this.params._unlockDonorIds?.length &&
      this.params._unlockDonorIds?.includes(this.params.data.campaignId)
    ) {
      this.cssClass = this.params.class_off();
    } 
  }

  onSelectChange() {
    if (!this.params.cellEditable || this.params.cellEditable(this.params.data)) {
      this.params.data[this.params.column.colId] = !this.params.data[this.params.column.colId];
      this.cssClass = this.params.data[this.params.column.colId] ? this.params.class_on() : this.params.class_off();
    }
  }
  
}
