<div class="contentarea">
  <dl class="accordion  bounceInDown content">
    <dt (click)="hideDiscountGrid = !hideDiscountGrid">
      <span class="editable-title">
        <span class="text">Discount Grid </span>
        <span class="text allow-copy" *ngIf="campaignDetails.campaignReference">&nbsp;
        </span>
      </span>
      <a href="javascript:void(0)" class="title" [ngClass]="!hideDiscountGrid?'down':''">
        <span class="right">
          <span class="text">&nbsp;</span>
          <span class="icon_wrapper">
            <span class="icons animated"></span>
          </span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'readOnly' : systemFlags.readOnly, 'down' : !hideDiscountGrid}">
      <app-discount-grid-dubai *ngIf="commercialData" [campaignDetails]="campaignDetails">
      </app-discount-grid-dubai>
    </dd>
  </dl>
  <dl class="accordion animated bounceInDown content">
    <dt (click)="hideCampaignDetail = !hideCampaignDetail">
      <span class="editable-title">
        <span class="text">{{initialConfig.userBundle['commercial.tabTitle.campaignDetails']}} &nbsp;-&nbsp;
        </span>
        <span class="text allow-copy"> {{commercialData?.campaignReference}}
        </span>
      </span>
      <a href="javascript:void(0)" class="title" [ngClass]="!hideCampaignDetail?'down':''">
        <span class="right">
          <span class="text">{{commercialData?.bookingStatusName}}</span>
          <span class="icon_wrapper">
            <span class="icons animated"></span>
          </span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'readOnly' : systemFlags.readOnly, 'down' : !hideCampaignDetail}">
      <app-campaign-details-ae *ngIf="commercialData" [campaignDetails]="campaignDetails"
        [commercialConfig]="commercialConfig" [historyROC]="historyROC">
      </app-campaign-details-ae>

    </dd>
  </dl>
  <div *ngIf="commercialData">
    <div *ngFor="let booking of commercialData.bookings; trackBy: trackByIndex; let i = index">
      <dl class="accordion animated bounceInDown content">
        <dt (click)="hideBookingDetail = !hideBookingDetail">
          <span class="editable-title">
            <span class="text">{{initialConfig.userBundle['commercial.tabTitle.bookingDetails']}} </span>
          </span>
          <a href="javascript:void(0)" class="title" [ngClass]="!hideBookingDetail?'down':''">
            <span class="right">
              <span class="icon_wrapper">
                <span class="icons animated"></span>
              </span>
            </span>
          </a>
        </dt>
        <div *ngIf="commercialData">
          <div *ngFor="let booking of commercialData.bookings; trackBy: trackByIndex; let i = index">
            <dd [ngClass]="{'readOnly' : systemFlags.readOnly, 'down' : !hideBookingDetail}">
              <app-booking-detail-ae [rowIndex]="i" [bookings]="commercialData.bookings"
                [bookingStatusId]="bookingStatusId" [commercialConfig]="commercialConfig"
                [campaignDetails]="campaignDetails">
              </app-booking-detail-ae>
            </dd>
          </div>
        </div>
      </dl>
    </div>

  </div>


  <app-commercial-buttons *ngIf="commercialData" [commercialConfig]="commercialConfig"
    [campaignDetails]="campaignDetails" [validateFn]="validateCommercialCampaign"
    [populateRequestJson]="createRequestJSON" (bookCampaign)="onBookCampaign($event)">
  </app-commercial-buttons>
</div>
