import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, OnChanges } from '@angular/core';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, BookingLine, HistoryROC } from '../../commercial.model';
import { SbSelectedItem, DEFAULT_SELECTED_ITEMS } from '../../../models/sb-selected-item';
import { DataShareService } from '../../../core/services/data-share.service';
import {
  defaultBarterSearchParams,
  SystemFlags,
  Campaign
} from '../../../models';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import * as _ from 'lodash';
import { StateService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { CampaignDetailsBase } from '../campaign-details-base';
import { GLOBAL } from '../../../core/utils/app.constant';
import { Subscription } from 'rxjs';
import { CampaignGroupTypeList } from '../../../core/enum';
import { SelectConfig } from '../../../core/components/sb-select/core/select-config';

@Component({
  selector: 'app-campaign-details-us',
  templateUrl: './campaign-details-us.component.html',
  styleUrls: ['./campaign-details-us.css']
})

export class CampaignDetailsUsComponent extends CampaignDetailsBase implements OnInit, OnDestroy, OnChanges {
  @Input() campaignDetails: CampaignDetails = new CampaignDetails();
  @Output() calculateCPTEvent: EventEmitter<any> = new EventEmitter();
  @Output() manuallyCPTUpdated: EventEmitter<any> = new EventEmitter();
  @Input() bookingStatusId;
  @Input() commercialConfig;
  @Input() historyROC: HistoryROC;

  searchPageObject: Campaign;

  /**
   * @description set bookingStatusList
   * @memberof CommercialUkComponent
   */
  bookingStatusList = GLOBAL.bookingStatusList;
  isMediaLengthRequired = false;

  // Barter
  barterList: SbSelectedItem[] = [];
  selectedBarter: SbSelectedItem = _.clone(DEFAULT_SELECTED_ITEMS);
  /**
   * @description
   * @type {SelectConfig}
   * @memberof CampaignDetailsUs
   */
  public barterLookupConfig: SelectConfig;

  bookingLineDataSubscription: Subscription;
  /**
   * @description set readOnly
   * @memberof CampaignDetailsUSComponent
   */
  public readOnly = SystemFlags.readOnly;

  campaignGroupTypeList = CampaignGroupTypeList;

  isTitlePersisted: boolean; // set to true if you don't want to change title on change of advertiser

  constructor(
    commercialService: CommercialService,
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    stateService: StateService
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService
    );

    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe((value: any) => {
      if (value.rowIndex >= 0 && value.columnName) {
        this.calculateCPT(value);
      }
      this.onBookingLineChange();
    });

    this.isTitlePersisted = this.commercialService.IsTitlePersist;
  }

  ngOnInit(): void {
    this.populateCampaignDetails();
    super.init(this.campaignDetails);
    this.searchPageObject = this.stateService.getCampaign();
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    this.enableSubAgency();
    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);
    this.checkIsMediaLengthRequired();
    this.setLookupConfig();
  }

  ngOnChanges(prop) {
    if (prop.bookingStatusId && !prop.bookingStatusId.firstChange) {
      this.checkIsMediaLengthRequired();
    }
  }

  changeTitlePersistStatus(value: boolean) {
    this.isTitlePersisted = value;
    this.commercialService.IsTitlePersist = value;
  }

  /**
   * @description To set the configuration for all lookup components
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setLookupConfig() {
    this.setAdvertiserConfig();
    this.setBrandConfig();
    this.setAgencyConfig();
    this.setSubAgencyConfig();
    this.setBarterConfig();
    this.setSpecialistConfig();
  }

  /**
   * @description To set the configuration for agency lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setAgencyConfig() {
    super.setAgencyConfig();
    this.agencyLookupConfig.displayInvoiceCode = this.uiControl && this.uiControl.invoiceClientStatusEnabled;
  }

  /**
   * @description To set the configuration for barter lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsUs
   */
  setBarterConfig() {
    this.barterLookupConfig = new SelectConfig({
      placeholder: this.userBundle['common.lookup.defaultOption'],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_BARTER_URL'),
      displayInvoiceCode: this.uiControl && this.uiControl.invoiceClientStatusEnabled,
      extraParams: defaultBarterSearchParams,
      searchKeyParamName: 'organisationName',
      idField: 'barterId',
      textField: 'barterName'
    });
  }

  /**
   * @description Populate Campaign Details
   * @author Shivani Patel
   * @memberof CampaignDetailsUsComponent
   */
  populateCampaignDetails() {
    // Populate Barter, Sales Person and Admin Person
    if (this.campaignDetails.bookingList && this.campaignDetails.bookingList.length) {
      const bookingLine: BookingLine = this.campaignDetails.bookingList[0];

      this.selectedBarter = this.commercialService.populateSelectedDropdownWithCode(
        bookingLine.barterId,
        bookingLine.barterName,
        bookingLine.barterInvoiceStatusCode
      );
    }
  }

  // #region - Barter - callback methods
  /**
   * @description Event fires when barter has been selected
   * @author Shivani Patel
   * @param {*} value
   * @memberof CampaignDetailsUsComponent
   */
  onBarterNameSelected(value: any): void {
    const bookingLine: BookingLine = this.campaignDetails.bookingList[0];
    if (bookingLine) {
      bookingLine.barterId = value.id;
      bookingLine.barterName = value.text;
      bookingLine.barterInvoiceStatusCode = value.source.invoiceStatusCode;
      this.selectedBarter = this.commercialService.populateSelectedDropdownWithCode(
        bookingLine.barterId,
        bookingLine.barterName,
        bookingLine.barterInvoiceStatusCode
      );
    }
  }

  /**
   * @description Event fires when we clear the selected Barter
   * @author Shivani Patel
   * @memberof CampaignDetailsUsComponent
   */
  removeSelectedBarterName(): void {
    const bookingLine: BookingLine = this.campaignDetails.bookingList[0];
    bookingLine.barterId = 0;
    bookingLine.barterName = '';
    bookingLine.barterInvoiceStatusCode = '';
  }

  /**
   * @description Event fires when lookup response of Barter is available
   * @author Shivani Patel
   * @param {*} value
   * @memberof CampaignDetailsUsComponent
   */
  getBarterResponse(value: any): void {
    if (value.organisation && value.organisation.length) {
      this.barterList = value.organisation.map(item => ({
        barterId: item.organisationId,
        barterName: item.organisationName,
        source: item
      }));
    }
  }
  // #endregion - Barter

  /**
   * @description - Callback method call on agency name changed
   * @author Shivani Patel
   * @param {*} value
   * @memberof CampaignDetailsUsComponent
   */
  onAgencyNameSelected(value: any): void {
    if (this.campaignDetails.agency) {
      super.onAgencyChange(value);
      this.enableSubAgency();
      this.commercialService.notifyGrid(this.campaignDetails);
    }
  }

  /**
   * @description - Callback method call on removing selected agency
   * @author Shivani Patel
   * @memberof CampaignDetailsUsComponent
   */
  removeSelectedAgencyName(): void {
    super.onAgencyChange();
    this.enableSubAgency();
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  /**
   * @description Event fires when assigned to value has been changed
   * @author Shivani Patel
   * @memberof CampaignDetailsUsComponent
   */
  assignedToChange() {
    this.enableSubAgency();
    this.setActiveAssignedTo();
  }

  /**
   * @description on average CPT value change
   * @author Amit Mahida
   * @memberof CampaignDetailsUkComponent
   */
  onAverageCPTChanged() {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT); // SM-4776
    this.commercialService.notifyCampaignDetails(this.campaignDetails.bookingList);
    if (this.uiControl.enableUserDefinedCPMPricingCalculation) {
      this.manuallyCPTUpdated.emit();
    }
  }

  /**
   * @description calculate CPT from gross media value or pmp discount change
   * @author Amit Mahida
   * @param {*} value
   * @memberof CampaignDetailsUkComponent
   */
  calculateCPT(value) {
    if (value.columnName === 'grossMediaValue') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex,
        undefined, Number(this.campaignDetails.bookingList[value.rowIndex].grossMediaValue));
    } else if (value.columnName === 'pmpdiscount') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex);
    }
    this.calculateCPTEvent.emit();
  }

  checkIsMediaLengthRequired() {
    if (Array.isArray(this.commercialConfig.uiControl.mediaLengthRequired)) {
      const statuses = this.commercialConfig.uiControl.mediaLengthRequired;
      if (statuses.indexOf(this.campaignDetails.campaignStatusId) > -1
        || statuses.indexOf(this.bookingStatusId) > -1) {
        this.isMediaLengthRequired = true;
      } else {
        this.isMediaLengthRequired = false;
      }
    } else {
      this.isMediaLengthRequired = false;
    }
  }

  onBookingLineChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  // Functions Related to Specialist//
  onSpecialistNameSelected(value: any): void {
    if (this.campaignDetails.specialist) {
      this.onSpecialistChange(value);
    }
  }

  removeSelectedSpecialistName() {
    this.onSpecialistChange();
  }

  ngOnDestroy() {
    this.bookingLineDataSubscription.unsubscribe();
  }
}
