import { Component, OnInit, Input } from '@angular/core';
import { DataShareService } from '../../core/services';
import { LogHelperService } from '../../core/services/log-helper.service';
import { SaveCampaignResolvedObject } from '../../models';

@Component({
  selector: 'app-savecampaign',
  templateUrl: './savecampaign.component.html',
  styleUrls: ['./savecampaign.component.css']
})
export class SaveCampaignComponent implements OnInit {
  brickBgColor = '#ef3f3b';
  initialConfig = null;
  @Input() resolveObject: SaveCampaignResolvedObject;

  constructor(
    public dataShareService: DataShareService,
    public logHelperService: LogHelperService
  ) {
  }

  ngOnInit() {
    //
  }

  onModalSaved(event) {
    event.activeModal.close();
  }

  onModalClosed(event) {
    event.activeModal.close(event.reason);
  }

}
