import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FolioResponseModel, ResponseModel } from '../../models';

@Injectable()
export class FolioImageService {

  constructor(private http: HttpClient) { }

    // getFolioImages(folioURL, userName, password) {
    //     //let folioURL = 'http://111.93.67.82:8989/folioupgrade/webroot/
    // index.php/api/asset/get/furniture?furn_name=CDN0005DB&width=300&height=300';
    //     const headers = new HttpHeaders({
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //         'Authorization': "Basic " + btoa(userName + ":" + password) //"gtarayre:rspl123#")
    //     });
    //     const requestOptions = {
    //         body: {},
    //         headers: headers
    //     };
    //     return this.http.request(
    //         'GET',
    //         folioURL,
    //         requestOptions
    //     );
    // }

  getFolioImages(folioURL: string): Observable<ResponseModel<FolioResponseModel>> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const requestOptions = {
      headers,
      body: {}

    };
    return this.http.request(
      'GET',
      folioURL,
      requestOptions
        ).map((res: ResponseModel<FolioResponseModel>): ResponseModel<FolioResponseModel> => {
          return res;
        });
  }
}
