import { Component } from '@angular/core';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { IFilterAngularComp } from 'ag-grid-angular';
import { DatePipe } from '@angular/common';
import { LocaleData } from '../../../../core/utils/LocaleData';
import * as moment from 'moment';

@Component({
  selector: 'app-ag-custom-filter-menu',
  templateUrl: './ag-custom-filter-menu.component.html'
})
export class AgCustomFilterMenuComponent implements IFilterAngularComp {

  private params: any;
  public text = '';
  public filterType = 'text';

  constructor(private datePipe: DatePipe) { }

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  isFilterActive(): boolean {
    return this.text !== null && this.text !== undefined && this.text !== '';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    params.data.isFilterPass = this.filterFunc(params.data);
    return params.data.isFilterPass;
  }

  filterFunc(rowData): boolean {
    if (this.filterType === 'fromto') { // from, to date range
      return this.fromToDateFilterFunc(rowData);
    } else if (this.filterType === 'ledatestring') { // less than or equal to date, date string compare
      return this.lessEqualDateFilter(rowData);
    } else { // by default datestring
      return this.customDateFilter(rowData);
    }
  }

  /**
   * form, to date range filter
   * @param rowData current row data
   */
  fromToDateFilterFunc(rowData): boolean {
    let filterStartDate = null;
    if (this.params.column.gridApi.filterManager.allFilters.startDate) {
      filterStartDate = this.params.column.gridApi.filterManager.allFilters.startDate.filterPromise.resolution.getModel();
    }
    if (filterStartDate) {
      filterStartDate = filterStartDate.value;
    }

    let filterEndDate = null;
    if (this.params.column.gridApi.filterManager.allFilters.endDate) {
      filterEndDate = this.params.column.gridApi.filterManager.allFilters.endDate.filterPromise.resolution.getModel();
    }
    if (filterEndDate) {
      filterEndDate = filterEndDate.value;
    }

    if (filterStartDate && filterEndDate) {
      return this.customDateRangeFilter(rowData, filterStartDate, filterEndDate);
    } else {
      return this.customDateFilter(rowData);
    }
  }

  /**
   * date range filter function
   * @param rowData current row data
   * @param filterStartDate filter start date
   * @param filterEndDate filter end date
   */
  customDateRangeFilter(rowData, filterStartDate, filterEndDate): boolean {
    let returnVal = false;
    const filterStart = moment(filterStartDate, LocaleData.displayDateFormat.toUpperCase(), true);
    const filterEnd = moment(filterEndDate, LocaleData.displayDateFormat.toUpperCase(), true);

    if (filterStart.isValid() && filterEnd.isValid()) {
      const startD = new Date(filterStart.year(), filterStart.month(), filterStart.date()).getTime();
      const endD = new Date(filterEnd.year(), filterEnd.month(), filterEnd.date()).getTime();

      const tempStartDate = new Date(rowData.startDate).getTime();
      const tempEndDate = new Date(rowData.endDate).getTime();
      if ((tempStartDate >= startD && tempStartDate <= endD) && (tempEndDate >= startD && tempEndDate <= endD)) {
        returnVal = true;
      }
    }

    return returnVal;
  }

  /**
   * date filter function - string comparision of date
   * @param rowData current row data
   */
  customDateFilter(rowData): boolean {
    let returnVal = false;
    const value = rowData[this.params.column.colId];
    if (value) {
      returnVal = (this.datePipe.transform(value, LocaleData.displayDateFormat).indexOf(this.text) > -1);
    }
    return returnVal;
  }

  /**
   * if filter is date type then less or equal to date filter.
   * if filter is string, date string comparision
   * @param rowData current row data
   */
  lessEqualDateFilter(rowData): boolean {
    let returnVal = false;
    const filterDate = moment(this.text, LocaleData.displayDateFormat.toUpperCase(), true);
    if (filterDate.isValid()) {
      const startD = new Date(filterDate.year(), filterDate.month(), filterDate.date()).getTime();
      const tempStartDate = new Date(rowData[this.params.column.colId]).getTime();
      if (tempStartDate <= startD) {
        returnVal = true;
      }
    } else {
      returnVal = this.customDateFilter(rowData);
    }
    return returnVal;
  }

  getModel(): any {
    return { value: this.text };
  }

  setModel(model: any): void {
    this.text = model ? model.value : '';
    this.filterType = model ? model.type : 'text';
  }

  // noinspection JSMethodCanBeStatic
  componentMethod(message: string): void {
    alert(`Alert from PartialMatchFilterComponent ${message}`);
  }

  // @ts-ignore
  onFloatingFilterChanged(type, model): void {
    this.text = model ? model.value : '';
    this.filterType = model ? model.type : 'text';
    this.params.filterChangedCallback();
  }

  onChange(newValue): void {
    if (this.text !== newValue) {
      this.text = newValue;
      this.params.filterChangedCallback();
    }
  }
}
