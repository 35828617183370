import { Component, OnInit, Input, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import { GridOptions, ColDef, CellDoubleClickedEvent } from 'ag-grid-community';
import { DataShareService } from '../../../core/services/data-share.service';
import { SharedService } from '../../../core/services/shared.service';
import { Subscription } from 'rxjs';
import {
  AgCustomHeaderComponent,
  AgTextCellEditorComponent,
  AgTypeAheadObservableCellEditorComponent,
  AgCustomSort,
  AgSelectCellEditorComponent,
  AgCheckboxCellRendererComponent,
  AgDateCellRendererComponent,
  AgDateCellEditorComponent,
  AgNumberMaskParamModel,
  AgNumberMaskCellEditorComponent
} from '../ag-grid-support/index';
import { LocaleData } from '../../../core/utils/LocaleData';
import { DatePipe, DecimalPipe } from '@angular/common';

import * as _ from 'lodash';
import { CommercialService } from '../../../core/services/commercial.service';
import { InitialConfigModel, SystemFlags } from '../../../models';
import * as moment from 'moment';
import { StateService } from '../../../core/services/state.service';
import { GLOBAL } from '../../../core/utils/app.constant';
import { Campaign } from '../../../models/campaign';
import { EnvironmentId } from '../../../core/enum';

enum BookingStatusList {
  Option = 2,
  Confirmed = 4,
  Cancelled = 5
}

@Component({
  selector: 'app-booking-grid-uk',
  templateUrl: './booking-grid-uk.component.html',
  styleUrls: ['../ag-grid-custom-style.css', './booking-grid-uk.component.css']
})
export class BookingGridUkComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   *  config object contains - initial config, etc
   */
  @Input() commercialConfig: any;

  config: InitialConfigModel = new InitialConfigModel();

  /**
   * Booking list data coming from parent component
   */
  @Input() bookingList: any;

  /**
   * @description Campaign status id
   * @type {number}
   * @memberof BookingGridUkComponent
   */
  campaignStatus: number;

  /**
   * @description Digits after decimal points required, default is 2
   * @memberof BookingGridUkComponent
   */
  @Input() decimalplaces = 2;

  @Input() isAdminPersonMandatory = true;
  @Input() isSalesPersonMandatory = true;

  /**
   * @description booking status list
   * @type {any[]}
   * @memberof BookingGridUkComponent
   */
  bookingStatus: any[] = [];
  /**
   * UI control object of commercial response
   */
  uiControl: any = {};

  /**
   * Ag-grid column sorting object
   */
  sortObj: AgCustomSort;
  /**
   * Ag-grid grid configuration object
   */
  gridOptions: GridOptions;
  /**
   * Ag-grid column defination configuration object
   */
  columnDefs: ColDef[];
  /**
   * Ag-grid row data
   */
  rowData: any[] = [];
  /**
   * List of production type - fetched from initial config
   */
  productionType: any[] = [];
  /**
   * User Bundle property object from initial config
   */
  userBundle: any = {};

  /**
   * Ag-grid row grouping field - null if no groupping
   */
  groupingField: string = null;
  /**
   * Booking List data subscription object
   * this is needed when booking list data changed from parent component- need to refresh grid
   */
  gridDataSubscription: Subscription;
  /**
   * Ag-grid last scroll position - needed to maintain scroll position on refresh grid
   */
  scrollLeftPosition = 0;
  scrollTopPosition = 0;
  /**
   * Ag-grid last focused cell params - needed to maintain selected cell on refresh grid
   */
  lastFocusedColumnParams: any = null;

  currencyMaskGrossMediaValue: AgNumberMaskParamModel;
  percentageMaskPMPDiscount: AgNumberMaskParamModel;
  userData: any = {};
  systemData: any = {};

  showCancellationPending = false;
  /**
   * @description store search page object
   * @type {Campaign}
   * @memberof CampaignDetailsFiComponent
   */
  searchPageObject: Campaign;

  // currencyMaskModel: AgCurrencyMaskParamModel;

  bookingLineDataSubscription: Subscription;
  readonly OPTION_TO_RENEW = 'common.optionToRenew';
  readonly CTU_PERSON_NAME = 'common.ctuPersonName';
  readonly BARTER_NAME = 'common.barterName';
  readonly COMISSION = 'common.barter.commission';
  readonly PRE_VALUE = 'common.barter.preValue';
  readonly DEFAULT_OPTION = 'common.lookup.defaultOption';
  isBgvEditable = true;

  subscriptions: Subscription[] = [];
  constructor(
    private datePipe: DatePipe,
    private dataShareService: DataShareService,
    private sharedService: SharedService,
    private stateService: StateService,
    private commercialService: CommercialService,
    private decimalPipe: DecimalPipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.subscriptions.push(this.dataShareService.languageChangedSub.subscribe((result) => {
      if (result) {
        this.ngOnInit();
        this.gridOptions.api.redrawRows();
        this.changeDetectorRef.detectChanges();
      }
    }));
    this.subscriptions.push(this.dataShareService.bookingListSub.subscribe((bookingList) => {
      if (bookingList && bookingList.length) {
        this.bookingList = bookingList;
        this.prepareRowData();
        this.gridOptions.api.redrawRows();
        this.changeDetectorRef.detectChanges();
      }
    }));

    this.commercialService.$resetBookingStatusId.subscribe((needAReset) => {
      if (needAReset) {
        this.bookingList.forEach((booking) => {
          booking.bookingStatusId = booking.previousBookingStatusId
        })
      }
    });
  }

  /**
   * @description It is responsible to make grid readonly or editable
   * @author Dhaval Patel
   */
  readOnlyUpdate() {
    const ele: any = document.querySelector('.booking-grid-uk .ag-body-viewport .ag-center-cols-viewport .ag-center-cols-container');
    if (SystemFlags.readOnly) {
      ele.className = `${ele.className} readOnly`;
    } else {
      ele.classList.remove('readOnly');
    }
  }

  /**
   * Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   */
  ngOnInit(): void {
    this.config = this.dataShareService.getInitialConfig();
    this.bookingStatus = this.commercialConfig.bookingStatus;
    this.campaignStatus = this.commercialConfig.campaignStatus;
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.uiControl = this.commercialConfig.uiControl;
    this.productionType = this.dataShareService.getInitialConfigByKey('productionType');
    this.userData = this.dataShareService.getInitialConfigByKey('userData');
    this.systemData = this.dataShareService.getInitialConfigByKey('systemData');
    this.searchPageObject = this.stateService.getCampaign();
    this.commercialService.isStatusChanged = false;
    this.canBgvEditable();

    this.prepareColDef();

    // we pass an empty gridOptions in, so we can grab the api out
    this.setGridOptions();

    this.gridDataSubscription = this.commercialService.gridData$.subscribe((val: any) => {
      this.campaignStatus = val.updatedStatus ? val.updatedStatus : this.campaignStatus;

      this.setLocalScrollLeftPosition();
      setTimeout(() => {
        const activeElement: any = document.activeElement;

        if (this.gridOptions && this.gridOptions.api) {
          this.gridOptions.api.redrawRows();
          this.setGridScrollLeftPosition();
          if (this.lastFocusedColumnParams) {
            const nextColId = this.lastFocusedColumnParams.column.colId;
            if (nextColId) {
              this.gridOptions.api.setFocusedCell(this.lastFocusedColumnParams.rowIndex, nextColId);
            }
          }
          this.lastFocusedColumnParams = null;
        }

        if (activeElement) {
          const attr = activeElement.getAttribute('ng-model');
          const needToReFocus = ['vm.commercial.clientDetails.specialistPercentage', 'vm.commercial.clientDetails.agencyPercentage'];
          if (needToReFocus.indexOf(attr) > -1) {
            activeElement.focus();
          }
        }
      }, 10);
    });
  }

  setGridOptions() {
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: false,
        resizable: true
      },
      context: {
        componentParent: this
      },
      scrollbarWidth: 10,
      rowHeight: 28,
      headerHeight: 34,
      rowSelection: null,
      suppressRowClickSelection: true,
      suppressScrollOnNewData: true,
      groupingField: this.groupingField,
      animateRows: false,
      suppressAnimationFrame: true,
      suppressMovableColumns: true,
      suppressColumnVirtualisation: true,
      rowBuffer: 50,
      // rowClassRules: {
      //   // apply green to 2008
      //   'disable-row': (params) => {
      //     let condition = false;
      //     if (this.config && this.config.isLoadedCampaign) {
      //       if (params.data && params.data.bookingStatusId) {
      //         if (!this.dataShareService.userModel.commercialTabAccess.updateBooking) {
      //           if (params.data.bookingStatusId === GLOBAL.BOOKING_STATUS.OPTION) {
      //             condition = !this.dataShareService.userModel.commercialTabAccess.updateOptionBooking;
      //           }
      //           if (params.data.bookingStatusId === GLOBAL.BOOKING_STATUS.CONFIRMED) {
      //             condition = !this.dataShareService.userModel.commercialTabAccess.updateConfirmBooking;
      //           }
      //         }
      //       }
      //     }
      //     return condition;
      //   },
      // },
      onGridReady: () => {
        // set grid readonly if campaign is readonly
        this.readOnlyUpdate();
        // SM-1916 - show ODD and OTR column if there is any row with option status
        const isAnyLineWithOptionStatus: boolean = this.isAnyBookingWithSelectedStatus(BookingStatusList.Option);
        this.showCancellationPending = this.bookingList.filter(e => e.isCancellationPending).length > 0;
        this.gridOptions.columnApi.setColumnVisible('optionDroppingDate', isAnyLineWithOptionStatus);
        this.gridOptions.columnApi.setColumnVisible('optionToRenew', isAnyLineWithOptionStatus);
        this.gridOptions.columnApi.setColumnVisible('isCancellationPending', this.showCancellationPending);
        // End SM-1916 - show ODD and OTR column if there is any row with option status

        // SM-1963 - show merlin integration column
        const isAnyLineWithintegrationStatusField: boolean = this.isAnyBookingWithIntegrationStatusField();
        this.gridOptions.columnApi.setColumnVisible('integrationSuccess', isAnyLineWithintegrationStatusField);
        // End SM-1963 - show merlin integration column

        this.setGridScrollLeftPosition();
      },
      onCellFocused: (params) => {
        if (params.column) {
          const inputEle: HTMLInputElement = document.querySelector(`ag-grid-angular .ag-center-cols-container [row-index='${params.rowIndex}']
          [col-id='${params.column.getColId()}'] input`);
          const selectEle: HTMLSelectElement = document.querySelector(`ag-grid-angular .ag-center-cols-container [row-index='${params.rowIndex}']
          [col-id='${params.column.getColId()}'] select`);
          if (inputEle) {
            inputEle.focus();
          } else if (selectEle) {
            selectEle.focus();
          }
        }
      }
    } as GridOptions;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.prepareRowData();
    });
  }

  /**
   * Called once, before the instance is destroyed.
   */
  ngOnDestroy(): void {
    this.gridDataSubscription.unsubscribe();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /**
   * cache the scroll position of grid, to restore postion after grid refresh
   */
  setLocalScrollLeftPosition() {
    this.scrollLeftPosition = document.querySelector('.booking-grid-uk .ag-body-horizontal-scroll-viewport').scrollLeft;
    this.scrollTopPosition = document.querySelector('.booking-grid-uk .ag-body-viewport').scrollTop;
  }

  /**
   * restore last cached scroll position of ag-grid
   */
  setGridScrollLeftPosition() {
    document.querySelector('.booking-grid-uk .ag-body-horizontal-scroll-viewport').scrollLeft = this.scrollLeftPosition;
    document.querySelector('.booking-grid-uk .ag-body-viewport').scrollTop = this.scrollTopPosition;
    document.querySelector('.booking-grid-uk .ag-header-viewport').scrollLeft = 0;
  }

  /**
   * this will be called by ag-grid, when column header clicked. for sorting data
   * here we are not providing sorting so no need to do anything
   */
  sortData() {
    // this.rowData = this.sortObj.sort(colId, order, this.rowData);
    // Do Nothing
  }

  /**
   * prepare rowdata from booking list data to use in ag-grid
   */
  prepareRowData() {
    this.rowData = [];
    if (!this.bookingList) {
      return;
    }
    this.setRowData();
    // Below code code for Meida Length for SM-1906, Nishit
    const mediaLength = this.bookingList.filter(e => e.mediaLength && e.mediaLength > 0).length;
    if (mediaLength > 0 && mediaLength !== this.bookingList.length) {
      this.mediaLengthCalculations();
    }
    this.commercialService.notifyCampaignDetails(this.bookingList);
  }

  mediaLengthCalculations() {
    const uniqueInvoiceTypeIds = _.uniq(this.bookingList.map(e => e.invoiceTypeId));

    for (const id of uniqueInvoiceTypeIds) {
      if (id > -1) {
        const rows = this.bookingList.filter(ele => ele.invoiceTypeId === id);
        if (rows.length > 1) {
          const mediaLengthForType = rows.filter(row => row.mediaLength && row.mediaLength > 0);
          if (mediaLengthForType.length > 0 && mediaLengthForType.length !== rows.length) {
            const rowsWithNoMediaLength = rows.filter(r => !r.mediaLength);
            rowsWithNoMediaLength.forEach((noMediaRow) => {
              noMediaRow.mediaLength = mediaLengthForType[0].mediaLength;
            });
          }
        }
      }
    }
  }

  setRowData() {
    for (const curRow of this.bookingList) {
      curRow.isFilterPass = true;
      curRow.isExpanded = true;
      curRow.isParent = false;
      curRow.isVisible = true;
      curRow.isSelected = false;
      curRow.optionToRenew = curRow.optionToRenew || false;
      curRow.readOnlyOptionToRenew = curRow.readOnlyOptionToRenew || false;
      curRow.optionDroppingDate = curRow.optionDroppingDate || null;
      curRow.productCatalogueName = curRow.productCatalogueName ? curRow.productCatalogueName : '';
      curRow.financeAmount = curRow.financeAmount ? curRow.financeAmount : '';
      curRow.bookingReference = curRow.bookingReference ? curRow.bookingReference : '';
      curRow.statusLocked = this.populateStatusLock(curRow);
      curRow.grossMediaValueDisplay = this.decimalPipe.transform(curRow.grossMediaValue, `.0-${this.decimalplaces}`);
      curRow.pmpdiscountDisplay = curRow.pmpdiscount ? this.decimalPipe.transform(curRow.pmpdiscount, `.0-${this.decimalplaces}`) : 0;
      curRow.isStatusChanged = false;
      this.rowData.push(curRow);
    }
  }

  hideColumns(key: string): boolean {
    let hide = false;

    if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.hiddenColumns) {
      hide = this.commercialConfig.uiControl.hiddenColumns.indexOf(key) !== -1;
    }

    return hide;
  }

  disableColumn(key: string): boolean {
    let disable = false;

    if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.disabledColumns) {
      disable = this.commercialConfig.uiControl.disabledColumns.indexOf(key) !== -1;
    }

    return disable;
  }

  onItemSelect = (params, item, dataIdKey, dataNameKey, itemIdKey, itemNameKey) => {
    params.data[dataIdKey] = item[itemIdKey];
    params.data[dataNameKey] = item[itemNameKey];

    // redraw rows to re-check cell class
    const parameters = {
      rowNodes: [params.node]
    };
    this.setLocalScrollLeftPosition();
    params.api.redrawRows(parameters);
    this.setGridScrollLeftPosition();
    if (item[itemIdKey] == null) {
      params.api.setFocusedCell(params.rowIndex, params.colDef.field);
    } else {
      const nextColId = this.getNextColumnFieldId(params);
      params.api.setFocusedCell(params.rowIndex, nextColId);
    }
  }

  disableRowCell(key: string, bookingLineNumber: number): boolean {
    let disable = false;

    if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.disabledRowCell && this.commercialConfig.uiControl.disabledRowCell[key]) {
      disable = this.commercialConfig.uiControl.disabledRowCell[key].indexOf(bookingLineNumber) !== -1;
    }

    return disable;
  }

  /**
   * prepare ag-grid column defination object
   */
  // tslint:disable-next-line: no-big-function   // tslint:disable-next-line: cognitive-complexity
  prepareColDef() {
    this.columnDefs = [];
    if (!this.hideColumns('booking_ref')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['booking.grid.bookingReference'],
          field: 'bookingReference',
          width: 130,
          editable: false,
          filter: false,
          headerTooltip: this.userBundle['booking.grid.bookingReference'],
          cellRenderer: this.textCellRenderer,
        }
      );
    }

    // TODO: Need to ask order of this field to Ravi
    if (!this.hideColumns('int_status')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.integrationStatus'] || 'Integration Status', field: 'integrationSuccess',
          width: 50, editable: false, filter: false,
          headerTooltip: this.userBundle['common.integrationStatus'] || 'Integration Status',
          cellRenderer: this.integrationStatusCellRenderer
        });
    }

    if (!this.hideColumns('inv_type_name')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['booking.grid.invoiceTypeName'], field: 'invoiceTypeName',
          width: 130, editable: false, filter: false,
          cellRenderer: this.textCellRenderer,
          headerTooltip: this.userBundle['booking.grid.invoiceTypeName']
        });
    }

    if (!this.hideColumns('prod_name')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['workspace.pcm.productCatalogueName'],
          field: 'productCatalogueName', width: 120, editable: false, filter: false,
          cellRenderer: this.textCellRenderer,
          headerTooltip: this.userBundle['workspace.pcm.productCatalogueName']
        });
    }

    if (!this.hideColumns('status')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.status'], field: 'bookingStatusId', width: 150,
          editable: (SystemFlags.isPPCampaign && !SystemFlags.readOnly),
          onCellDoubleClicked: (event: CellDoubleClickedEvent) => {
            if (SystemFlags.isPPCampaign && !SystemFlags.readOnly) {
              event.api.stopEditing(true);
            }
          },
          filter: false,
          headerTooltip: this.userBundle['common.status'],
          cellRendererFramework: AgSelectCellEditorComponent,
          cellRendererParams: {
            valKey: 'bookingStatusId',
            textKey: 'bookingStatusName',
            dataArr: (data) => {
              const listFromResponse = _.clone(this.bookingStatus);
              if (listFromResponse[0].bookingStatusId !== 0) {
                listFromResponse.unshift({
                  bookingStatusId: 0,
                  bookingStatusName: 'Select status',
                  inUse: true
                });
              }
              // SM-4649
              let list = [];
              let id = null;
              if (SystemFlags.isLoadedCampaign && data.statusEditable) {
                list = listFromResponse.filter((a) => {
                  return a.bookingStatusId === BookingStatusList.Confirmed || a.bookingStatusId === BookingStatusList.Cancelled || a.bookingStatusId === 0;
                });
              } else {
                list = listFromResponse.filter(a => a.inUse);
                id = data.bookingStatusId;
              }

              const recExist = list.filter((item) => {
                return item.bookingStatusId === id;
              });
              if (recExist.length === 0) {
                const temp = listFromResponse.filter((item) => {
                  return item.bookingStatusId === id;
                });
                if (temp.length > 0) {
                  list.push(temp[0]);
                }
              }
              return list;
            },
            // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
            // VJ: 07-12-2018, disable will be handled by backend using this tokens;
            cellDisable: (params) => this.cellDisable(params.data.locked, params.data.statusLocked, params.data.readOnly, params.data.isCancellationPending, this.disableColumn('status')),
            value: (params) => {
              return params.bookingStatusId == null || Number(params.bookingStatusId) === 0 ? 0 : params.bookingStatusId;
            },
            onSelectChange: (params) => {
              params.data.bookingStatusId = Number(params.node.data.bookingStatusId);
              // SM-1916 - show ODD and OTR column if there is any row with option status
              const isAnyLineWithOptionStatus: boolean = this.isAnyBookingWithSelectedStatus(BookingStatusList.Option);
              this.gridOptions.columnApi.setColumnVisible('optionDroppingDate', isAnyLineWithOptionStatus);
              this.gridOptions.columnApi.setColumnVisible('optionToRenew', isAnyLineWithOptionStatus);
              // End SM-1916 - show ODD and OTR column if there is any row with option status
              const parameters = {
                rowNodes: [params.node]
              };
              this.setLocalScrollLeftPosition();
              params.api.redrawRows(parameters);
              this.setGridScrollLeftPosition();
              const nextColId = this.getNextColumnFieldId(params);
              params.api.setFocusedCell(params.rowIndex, nextColId);
              this.commercialService.notifyCampaignDetails(this.bookingList);
              if (SystemFlags.isAPICampaign && this.bookingList[Number(params.node.id)].previousBookingStatusId != params.node.data.bookingStatusId) {
                this.bookingList[Number(params.node.id)].isStatusChanged = true
              } else {
                this.bookingList[Number(params.node.id)].isStatusChanged = false
              }
              this.commercialService.isStatusChanged = this.checkIsStatusChanged();
            }
          }
        }
      );
    }
    this.columnDefs.push(
      {
        headerName: this.userBundle['booking.grid.cancelPending'], field: 'isCancellationPending', width: 130, editable: false,
        filter: false, cellRendererFramework: AgCheckboxCellRendererComponent,
        cellRendererParams: {
          cellEditable: (data) => {
            return (data.locked || data.readOnly || !data.isCancellationPending || this.dataShareService.userModel.commercialTabAccess.changePending);
          },
          onSelectChange: (params) => {
            const index = this.bookingList.findIndex(e => e.bookingLineNumber === params.data.bookingLineNumber);
            if (index > -1) {
              params.data.bookingStatusId = 0;
              params.data.statusEditable = true;
              this.bookingList[index] = params.data;
            }
            this.commercialService.notifyCampaignDetails(this.bookingList);
          }
        },
        headerTooltip: this.userBundle[this.OPTION_TO_RENEW]
      });

    if (!this.hideColumns('dropping_date')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.optionDroppingDate'],
          field: 'optionDroppingDate', width: 100, editable: true, filter: false,
          headerTooltip: this.userBundle['common.optionDroppingDate'],
          cellRendererFramework: AgDateCellRendererComponent,
          cellRendererParams: {
            cellEditable: (data) => {
              return (!data.isParent && !this.disableColumn('dropping_date')
                && data.bookingStatusId === BookingStatusList.Option
                && this.userData.tokens.indexOf('MAN_ODD_CHANGE') > -1);
            },
            cellDisable: (data) => {
              return !(!data.isParent && !this.disableColumn('dropping_date')
                && data.bookingStatusId === BookingStatusList.Option
                && this.userData.tokens.indexOf('MAN_ODD_CHANGE') > -1);
            },
            dateFormat: LocaleData.displayDateFormat
          },
          cellEditorFramework: AgDateCellEditorComponent,
          cellEditorParams: {
            minDate: (params) => {
              return params.node.data.minDate ? new Date(params.node.data.minDate) : new Date();
            },
            maxDate: (params) => {
              if (params.node.data.bookingStartDate) {
                let bookingStartDate = new Date(params.node.data.bookingStartDate);
                bookingStartDate = new Date(bookingStartDate.setDate(bookingStartDate.getDate() - 1));
                return bookingStartDate;
              }
              return null;
            },
            onDateSelect: (params, newDate) => {
              params.node.data.optionDroppingDate = this.datePipe.transform(newDate, GLOBAL.DATE_PARSE_FORMAT);
            },
            cellEditable: (data) => {
              return (!data.isParent && !this.disableColumn('dropping_date')
                && data.bookingStatusId === BookingStatusList.Option
                && this.userData.tokens.indexOf('MAN_ODD_CHANGE') > -1);
            },
            cellDisable: (data) => {
              return !(!data.isParent && !this.disableColumn('dropping_date')
                && data.bookingStatusId === BookingStatusList.Option
                && this.userData.tokens.indexOf('MAN_ODD_CHANGE') > -1);
            }
          }
        });
    }

    if (!this.hideColumns('option_to_renew')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle[this.OPTION_TO_RENEW],
          field: 'optionToRenew', width: 100, editable: false, filter: false,
          cellRendererFramework: AgCheckboxCellRendererComponent,
          cellRendererParams: {
            cellEditable: (data) => {
              // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
              // VJ: 07-12-2018, disable will be handled by backend using this tokens;
              return !(data.locked || data.readOnlyOptionToRenew || data.readOnly || this.disableColumn('option_to_renew'));
            },
            onSelectChange: () => {
            }
          },
          headerTooltip: this.userBundle[this.OPTION_TO_RENEW]
        });
    }

    if (!this.hideColumns('booking_start')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['commercial.label.bookingStart'],
          field: 'bookingStartDate', width: 100, editable: false, filter: false,
          cellRenderer: this.textCellRenderer,
          headerTooltip: this.userBundle['commercial.label.bookingStart']
        });
    }

    if (!this.hideColumns('booking_end')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['commercial.label.bookingEnd'],
          field: 'bookingEndDate', width: 100, editable: false, filter: false,
          cellRenderer: this.textCellRenderer,
          headerTooltip: this.userBundle['commercial.label.bookingEnd']
        });
    }

    if (!this.hideColumns('impressions')) {
      this.columnDefs.push({
        headerName: this.userBundle['common.impressions'], field: 'impressions',
        width: 150, editable: false, filter: false,
        headerTooltip: this.userBundle['common.impressions'],
        cellRenderer: this.textCellRenderer
      });
    }

    if (!this.hideColumns('impressionsRemaining')) {
      this.columnDefs.push({
        headerName: this.userBundle['common.impressionsRemaining'], field: 'impressionsRemaining',
        width: 150, editable: false, filter: false,
        headerTooltip: this.userBundle['common.impressionsRemaining'],
        cellRenderer: this.textCellRenderer
      });
    }

    if (!this.hideColumns('impressionsPurchased')) {
      this.columnDefs.push({
        headerName: this.userBundle['common.impressionsPurchased'], field: 'impressionsPurchased',
        width: 150, editable: false, filter: false,
        headerTooltip: this.userBundle['common.impressionsPurchased'],
        cellRenderer: this.textCellRenderer
      });
    }
    if (!this.hideColumns('pmpdiscount')) {
      this.columnDefs.push({
        headerName: this.userBundle['common.pmpdiscount'], field: 'pmpdiscountDisplay', width: 100,
        editable: (SystemFlags.isPPCampaign && !SystemFlags.readOnly),
        onCellDoubleClicked: (event: CellDoubleClickedEvent) => {
          if (SystemFlags.isPPCampaign && !SystemFlags.readOnly) {
            event.api.stopEditing(true);
          }
        },
        filter: false,
        headerTooltip: this.userBundle['common.pmpdiscount'],
        cellRendererFramework: AgTextCellEditorComponent,
        cellRendererParams: {
          onlyNumber: true,
          allowReplaceDecimalSeparator: GLOBAL.environmentsHavingCommaAsDecimal.indexOf(this.systemData.environmentId) > -1,
          decimalPoints: this.decimalplaces,
          maxValue: 100,
          onTextChange: (params) => {
            params.data.pmpdiscount = params.data.pmpdiscountDisplay ? LocaleData.replaceLocalDecimalCharWithDot(params.data.pmpdiscountDisplay) : 0;
            params.data.pmpdiscountDisplay = this.decimalPipe.transform(params.data.pmpdiscount, `.0-${this.decimalplaces}`);
            this.lastFocusedColumnParams = params.api.getFocusedCell();
            this.commercialService.notifyCampaignDetails(this.bookingList, params.rowIndex, 'pmpdiscount');
          },
          cellDisable: (params) => {
            return params.data.bookingStatusId === BookingStatusList.Cancelled // SM-2007 - field should be editable for past campaign//
              || params.data.readOnly
              || this.disableColumn('pmpdiscount'); // VJ: 07-12-2018, disable will be handled by backend using this tokens;
          }
        }
      });
    }

    if (!this.hideColumns('admin_person')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.adminPersonName'],
          field: 'adminPersonName',
          width: 150,
          editable: false,
          filter: false,
          headerTooltip: this.userBundle['common.adminPersonName'],
          cellRendererFramework: AgTypeAheadObservableCellEditorComponent,
          cellRendererParams: {
            valKey: 'idAdminPerson',
            textKey: 'adminPersonName',
            placeholder: this.userBundle[this.DEFAULT_OPTION],
            loadAutoCompleteData: (query) => {
              const params = {
                action: 'lookupAdminPerson',
                data: JSON.stringify({ adminPersonName: query }),
                bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
              };
              return this.lookupCall(params, 'LOOKUP_ADMIN_PERSON_URL', 'adminPerson');
            },
            onItemSelect: (params, item) => {
              this.onItemSelect(params, item, 'adminPersonId', 'adminPersonName', 'idAdminPerson', 'adminPersonName');
            },
            // SM-2007 - field should be editable for past campaign //params.data.locked;
            // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
            // VJ: 07-12-2018, disable will be handled by backend using this tokens;
            cellDisable: (params) => this.cellDisable(params.data.bookingStatusId === BookingStatusList.Cancelled, params.data.readOnly, this.disableColumn('admin_person'))
          }
        });
    }

    if (!this.hideColumns('sales_person')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.salesPersonName'], field: 'salesPersonName',
          width: this.dataShareService.getInitialConfigByKey('systemData').environmentId === 28 ? 350 : 150, // SM-4460 - for Italy 2.5x column width
          editable: false, filter: false,
          headerTooltip: this.userBundle['common.salesPersonName'],
          cellRendererFramework: AgTypeAheadObservableCellEditorComponent,
          cellRendererParams: {
            valKey: 'idSalesPerson',
            textKey: 'salesPersonName',
            placeholder: this.userBundle[this.DEFAULT_OPTION],
            loadAutoCompleteData: (query) => {
              const params = {
                action: 'lookupSalesPerson',
                data: JSON.stringify({ salesPersonName: query }),
                bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
              };
              return this.lookupCall(params, 'LOOKUP_SALES_PERSON_URL', 'salesPerson');
            },
            onItemSelect: (params, item) => {
              this.onItemSelect(params, item, 'salesPersonId', 'salesPersonName', 'idSalesPerson', 'salesPersonName');
            },
            // SM-2007 - field should be editable for past campaign //return params.data.locked;
            // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
            // VJ: 07-12-2018, disable will be handled by backend using this tokens;
            cellDisable: (params) => this.cellDisable(params.data.bookingStatusId === BookingStatusList.Cancelled, params.data.readOnly, this.disableColumn('admin_person'))
          }
        });
    }

    if (!this.hideColumns('sales_person_email')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['commercial.label.salesPersonEmail'],
          field: 'salesPersonEmail', width: 100, editable: false, filter: false,
          cellRenderer: this.textCellRenderer,
          headerTooltip: this.userBundle['commercial.label.salesPersonEmail']
        });
    }

    // smartBrics Value
    if (!this.uiControl.hidePrice && !this.hideColumns('sb_value')) {
      const label = this.systemData.environmentId === 50 ? this.userBundle['common.automationValue'] : this.userBundle['common.smartBricsValue'];
      this.columnDefs.push({
        headerName: label, field: 'smartBricsValue',
        width: 150, editable: false, filter: false,
        headerTooltip: label,
        cellRenderer: this.textCellRenderer
      });
    }

    this.currencyMaskGrossMediaValue = {
      imask: {
        max: 10000000000,
        scale: this.decimalplaces
      },
      cellDisable: (params) => {
        return ((params.data.barterId && params.data.barterId !== '')
          || params.data.locked
          || params.data.readOnly
          || this.disableColumn('booked_gross_value')
          || this.disableRowCell('booked_gross_value', params.data.bookingLineNumber)
          || !this.isBgvEditable) ? true : false;
      },
      onTextChange: (params) => {
        this.commercialService.notifyCampaignDetails(this.bookingList, params.rowIndex, 'grossMediaValue');
      }
    };

    // grossvalue, net value , buyer Contact Name add in column Def
    if (!this.hideColumns('booked_gross_value')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.grossValue'], field: 'grossMediaValue',
          width: 150,
          editable: (SystemFlags.isPPCampaign && !SystemFlags.readOnly && this.isBgvEditable),
          filter: false,
          headerTooltip: this.userBundle['common.grossValue'],
          cellRendererFramework: AgNumberMaskCellEditorComponent,
          cellRendererParams: this.currencyMaskGrossMediaValue,
        }
      );
    }

    if (!this.hideColumns('grossMediaRemaining')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.grossMediaRemaining'],
          field: 'grossMediaRemaining', width: 100, editable: false, filter: false,
          cellRenderer: this.textCellRenderer,
          headerTooltip: this.userBundle['common.grossMediaRemaining']
        });
    }

    if (!this.hideColumns('grossMediaPurchased')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.grossMediaPurchased'],
          field: 'grossMediaPurchased', width: 100, editable: false, filter: false,
          cellRenderer: this.textCellRenderer,
          headerTooltip: this.userBundle['common.grossMediaPurchased']
        });
    }

    if (!this.hideColumns('net_media')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.netValue'], field: 'netMediaValue',
          width: 100, editable: false, filter: false,
          headerTooltip: this.userBundle['common.netValue'],
          cellRenderer: this.textCellRenderer
        });
    }

    if (!this.hideColumns('buyer_contact_name')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.barter.buyerContactName'], field: 'buyerContactName', width: 150,
          editable: false, filter: false,
          headerTooltip: this.userBundle['common.barter.buyerContactName'],
          cellRendererFramework: AgTypeAheadObservableCellEditorComponent,
          cellRendererParams: {
            valKey: 'buyerContactId',
            textKey: 'buyerContactName',
            placeholder: this.userBundle[this.DEFAULT_OPTION],
            loadAutoCompleteData: (query) => {
              const params = {
                action: 'lookupBuyerContact',
                data: JSON.stringify({ buyerContactName: query }),
                bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
              };
              return this.lookupCall(params, 'LOOKUP_BUYER_CONTRACT_URL', 'buyerContact');
            },
            onItemSelect: (params, item) => {
              this.onItemSelect(params, item, 'buyerContactId', 'buyerContactName', 'buyerContactId', 'buyerContactName');
            },
            // SM-2007 - field should be editable for past campaign //return params.data.locked;
            // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
            // VJ: 07-12-2018, disable will be handled by backend using this tokens;
            cellDisable: (params) => this.cellDisable(params.data.bookingStatusId === BookingStatusList.Cancelled, params.data.readOnly, this.disableColumn('booked_gross_value'))
          }
        }
      );
    }

    // ctuPerson Name
    if (!this.hideUIElements(this.CTU_PERSON_NAME) && !this.hideColumns('ctu_person')) {
      this.columnDefs.push({
        headerName: this.userBundle[this.CTU_PERSON_NAME], field: 'ctuPersonName',
        width: 150, editable: false, filter: false,
        headerTooltip: this.userBundle[this.CTU_PERSON_NAME],
        cellRendererFramework: AgTypeAheadObservableCellEditorComponent,
        cellRendererParams: {
          valKey: 'idCtuPerson',
          textKey: 'ctuPersonName',
          placeholder: this.userBundle[this.DEFAULT_OPTION],
          loadAutoCompleteData: (query) => {
            const params = {
              action: 'lookupCtuPerson',
              data: JSON.stringify({ ctuPersonName: query }),
              bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
            };

            return this.lookupCall(params, 'LOOKUP_CTU_PERSON_URL', 'ctuPerson');
          },
          onItemSelect: (params, item) => {
            this.onItemSelect(params, item, 'ctuPersonId', 'ctuPersonName', 'idCtuPerson', 'ctuPersonName');
          },
          cellDisable: (params) => this.cellDisable(params.data.bookingStatusId === BookingStatusList.Cancelled, params.data.readOnly, this.disableColumn('booked_gross_value'))
          // SM-2007 - field should be editable for past campaign//return params.data.locked;
          // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
          // VJ: 07-12-2018, disable will be handled by backend using this tokens;
        }
      });
    }

    // ponumber, customerRef, mediaLength, comments
    if (!this.hideColumns('pur_order_no')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.purchaseOrderNumber'], field: 'poNumber',
          width: 150, editable: false, filter: false,
          headerTooltip: this.userBundle['common.purchaseOrderNumber'],
          cellRendererFramework: AgTextCellEditorComponent,
          cellRendererParams: {
            maxLength: 100,
            // SM-2007 - field should be editable for past campaign//return params.data.locked;
            // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
            // VJ: 07-12-2018, disable will be handled by backend using this tokens;
            cellDisable: (params) => this.cellDisable(params.data.bookingStatusId === BookingStatusList.Cancelled, params.data.readOnly, this.disableColumn('booked_gross_value')),
            onTextChange: (params) => this.onTextChange(params)
          }
        });
    }

    if (!this.hideColumns('cust_ref')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.customerReference'], field: 'customerRef',
          width: 100, editable: false, filter: false,
          headerTooltip: this.userBundle['common.customerReference'],
          cellRendererFramework: AgTextCellEditorComponent,
          cellRendererParams: {
            maxLength: 50,
            cellDisable: (params) => this.cellDisable(params.data.bookingStatusId === BookingStatusList.Cancelled, params.data.readOnly, this.disableColumn('booked_gross_value')),
            // SM-2007 - field should be editable for past campaign//return params.data.locked;
            // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
            // VJ: 07-12-2018, disable will be handled by backend using this tokens;
            onTextChange: (params) => this.onTextChange(params)
          }
        });
    }

    if (!this.hideColumns('media_length')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['commercial.label.mediaLength'], field: 'mediaLength', width: 150,
          editable: false, filter: false,
          headerTooltip: this.userBundle['commercial.label.mediaLength'],
          cellRendererFramework: AgTextCellEditorComponent,
          cellRendererParams: {
            onlyNumber: true,
            decimalPoints: 0,
            maxValue: 1000,
            value: (params) => {
              if (!_.isUndefined(params.hideMediaControl) && params.hideMediaControl) {
                return '';
              } else {
                return params.mediaLength;
              }
            },
            cellDisable: (params) => this.cellDisable(params.data.hideMediaControl, params.data.bookingStatusId === BookingStatusList.Cancelled, params.data.readOnly, this.disableColumn('media_length')),
            // VJ: 07-12-2018, disable will be handled by backend using this tokens;
            // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
            onTextChange: (params) => {
              const bookingList = _.clone(this.bookingList);
              bookingList.forEach((element) => {
                if (params.data.invoiceTypeId === element.invoiceTypeId && !element.hideMediaControl && this.uiControl.enableCopyingMediaLength) {
                  element.mediaLength = params.data.mediaLength;
                }
              });
              this.bookingList = bookingList;
              this.commercialService.notifyCampaignDetails(this.bookingList);
              params.api.redrawRows();
            }
          }
        });
    }

    if (!this.hideColumns('comments')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.comments'], field: 'comments', width: 150, editable: false, filter: false,
          headerTooltip: this.userBundle['common.comments'],
          cellRendererFramework: AgTextCellEditorComponent,
          cellRendererParams: {
            maxLength: 2000,
            // SM-2007 - field should be editable for past campaign//return params.data.locked;
            // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
            // VJ: 07-12-2018, disable will be handled by backend using this tokens;
            cellDisable: (params) => this.cellDisable(params.data.bookingStatusId === BookingStatusList.Cancelled, params.data.readOnly, this.disableColumn('comments')),
            onTextChange: (params) => this.onTextChange(params)
          }
        }
      );
    }

    // barter
    if (!this.hideUIElements(this.BARTER_NAME)) {
      if (!this.hideColumns('barter')) {
        this.columnDefs.push(
          {
            headerName: this.userBundle[this.BARTER_NAME], field: 'barterName', width: 150, editable: false, filter: false,
            headerTooltip: this.userBundle[this.BARTER_NAME],
            cellRendererFramework: AgTypeAheadObservableCellEditorComponent,
            cellRendererParams: {
              valKey: 'organisationId',
              textKey: 'organisationName',
              placeholder: this.userBundle[this.DEFAULT_OPTION],
              loadAutoCompleteData: (query) => {
                const params = {
                  action: 'lookupOrganisation',
                  data: JSON.stringify({ organisationName: query, organisationTypeId: 5 }),
                  bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
                };
                return this.lookupCall(params, 'LOOKUP_BARTER_URL', 'organisation');
              },
              onItemSelect: (params, item) => {
                params.data.barterId = item.organisationId || '';
                params.data.barterName = item.organisationName || '';
                params.node.data.barterInvoiceStatusCode = item.invoiceStatusCode || '';
                const parameters = {
                  rowNodes: [params.node]
                };
                this.setLocalScrollLeftPosition();
                params.api.redrawRows(parameters);
                this.setGridScrollLeftPosition();
                if (item.organisationId == null) {
                  params.api.setFocusedCell(params.rowIndex, params.colDef.field);
                } else {
                  const nextColId = this.getNextColumnFieldId(params);
                  params.api.setFocusedCell(params.rowIndex, nextColId);
                }
                if (params.data.barterName !== '') {
                  this.stateService.setAnyValue(`bookedGrossMediaValue${params.rowIndex}`,
                    this.bookingList[params.rowIndex].grossMediaValue);
                  this.stateService.setAnyValue(`bookedNetMedia${params.rowIndex}`,
                    this.bookingList[params.rowIndex].netMediaValue);
                  this.bookingList[params.rowIndex].preBarterValue = this.bookingList[params.rowIndex].grossMediaValue;
                  this.bookingList[params.rowIndex].grossMediaValue = 0;
                  this.bookingList[params.rowIndex].netMediaValue = 0;
                } else {
                  this.bookingList[params.rowIndex].grossMediaValue =
                    parseFloat(this.stateService.getAnyValue(`bookedGrossMediaValue${params.rowIndex}`))
                    || this.bookingList[params.rowIndex].preBarterValue; // SBRICS-1773
                  this.bookingList[params.rowIndex].netMediaValue =
                    this.stateService.getAnyValue(`bookedNetMedia${params.rowIndex}`) || 0;
                  // SBRICS-1094, ends
                  this.bookingList[params.rowIndex].barterId = null;
                  this.bookingList[params.rowIndex].barterRate = null;
                }
                this.commercialService.notifyCampaignDetails(this.bookingList);
              },
              // SM-2007 - field should be editable for past campaign//return params.data.locked;
              // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
              // VJ: 07-12-2018, disable will be handled by backend using this tokens;
              cellDisable: (params) => this.cellDisable(params.data.bookingStatusId === BookingStatusList.Cancelled, params.data.readOnly, this.disableColumn('comments'))
            }
          });
      }

      if (!this.hideColumns('barterInvoiceStatusCode')) {
        this.columnDefs.push(
          {
            headerName: '', field: 'barterInvoiceStatusCode', width: 30, editable: false, filter: false
          }
        );
      }
    }

    // barter rate
    if (!this.hideUIElements(this.COMISSION) && !this.hideColumns('barter_rate')) {
      const percentageMaskBarterRate: AgNumberMaskParamModel = {
        imask: {
          max: 100,
          scale: this.decimalplaces
        },
        // SM-2007 - field should be editable for past campaign //return params.data.locked;
        // VJ: 29-11-2018, Flexible Campaign
        // VJ: 07-12-2018, disable will be handled by backend using this tokens;
        cellDisable: (params) => this.cellDisable(params.data.bookingStatusId === BookingStatusList.Cancelled, params.data.readOnly, this.disableColumn('barter_rate')),
        onTextChange: (params) => {
          params.data.barterRate = params.data.barterRate ? params.data.barterRate : 0;
          this.commercialService.notifyCampaignDetails(this.bookingList);
        }
      };
      this.columnDefs.push({
        headerName: this.userBundle[this.COMISSION], field: 'barterRate', width: 100, editable: false, filter: false,
        headerTooltip: this.userBundle[this.COMISSION],
        cellRendererFramework: AgNumberMaskCellEditorComponent,
        cellRendererParams: percentageMaskBarterRate
      });
    }

    // pre barter value
    if (!this.hideUIElements(this.PRE_VALUE) && !this.hideColumns('pre_barter')) {
      const currencyMaskPreBarterValue: AgNumberMaskParamModel = {
        imask: {
          scale: this.decimalplaces
        },
        // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
        // VJ: 07-12-2018, disable will be handled by backend using this tokens;
        cellDisable: (params) => this.cellDisable(params.data.locked, params.data.readOnly, this.disableColumn('pre_barter')),
        onTextChange: (params) => {
          params.data.preBarterValue = params.data.preBarterValue ? params.data.preBarterValue : 0;
          this.commercialService.notifyCampaignDetails(this.bookingList);
        }
      };
      this.columnDefs.push({
        headerName: this.userBundle[this.PRE_VALUE], field: 'preBarterValue',
        width: 130, editable: false, filter: false,
        headerTooltip: this.userBundle[this.PRE_VALUE],
        cellRendererFramework: AgNumberMaskCellEditorComponent,
        cellRendererParams: currencyMaskPreBarterValue
      });
    }

    // post barter value, authorizedAmount, productionTypeName
    if (!this.hideColumns('post_barter')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.barter.postValue'], field: 'grossMediaValue',
          width: 150, editable: false, filter: false,
          headerTooltip: this.userBundle['common.barter.postValue'],
          cellRenderer: (params) => {
            return params.data.barterId && params.data.grossMediaValue > 0 ? params.data.grossMediaValue : '';
          }
        });
    }

    if (!this.hideColumns('authorized_amt')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.authorizedAmount'], field: 'financeAmount',
          width: 150, editable: false, filter: false,
          headerTooltip: this.userBundle['common.authorizedAmount'],
          cellRenderer: this.textCellRenderer
        });
    }

    if (!this.hideColumns('production_type')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.productionTypeName'],
          field: 'productionTypeId', width: 150, editable: false, filter: false,
          headerTooltip: this.userBundle['common.productionTypeName'],
          cellRendererFramework: AgSelectCellEditorComponent,
          cellRendererParams: {
            valKey: 'productionTypeId',
            textKey: 'productionTypeName',
            dataArr: () => {
              return this.productionType;
            },
            // SM-2007 - field should be editable for past campaign//return params.data.locked;
            // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
            // VJ: 07-12-2018, disable will be handled by backend using this tokens;
            cellDisable: (params) => this.cellDisable(params.data.bookingStatusId === BookingStatusList.Cancelled, params.data.readOnly, this.disableColumn('production_type'))
          }
        }
      );
    }

    // productionValue
    if (!this.hideUIElements('commercial.booking.productionValue') && !this.hideColumns('productionValue')) {
      this.columnDefs.push({
        headerName: this.userBundle['common.productionValue'], field: 'productionCost',
        width: 100, editable: false, filter: false,
        headerTooltip: this.userBundle['common.productionValue'],
        cellRendererFramework: AgTextCellEditorComponent,
        cellRendererParams: {
          onlyNumber: true,
          decimalPoints: this.decimalplaces,
          // SM-2007 - field should be editable for past campaign//return params.data.locked;
          // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
          // VJ: 07-12-2018, disable will be handled by backend using this tokens;
          cellDisable: (params) => this.cellDisable(params.data.bookingStatusId === BookingStatusList.Cancelled, params.data.readOnly, this.disableColumn('productionValue'))
        }
      });
    }

    /** This column is not in use as of now, but may be usefull in future as per discussion with Ian,
     *  so left it added here but backend will send flag to hide it
     */
    if (!this.hideColumns('campaignCPT')) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.campaignCPT'], field: 'campaignCPT',
          width: 150, editable: false, filter: false,
          headerTooltip: this.userBundle['common.campaignCPT'],
          cellRendererFramework: AgTextCellEditorComponent,
          cellRendererParams: {
            // VJ: 29-11-2018, readOnly flag added for Flexible Campaign changes;
            // VJ: 07-12-2018, disable will be handled by backend using this tokens;
            cellDisable: (params) => this.cellDisable(params.data.locked, params.data.readOnly, this.disableColumn('campaignCPT')),
            onlyNumber: true,
            decimalPoints: this.commercialConfig.uiControl.campaignCPTScale || 2,
            thousandSep: LocaleData.NUM_GROUP_SEP,
            decimalSep: LocaleData.NUM_DECIMAL_SEP
          }
        });
    }

    for (const colDef of this.columnDefs) {
      colDef.headerComponentFramework = AgCustomHeaderComponent;
      colDef.cellClass = (params): string[] => {
        const retArr: string[] = [];
        if (params.data.isSelected) {
          retArr.push('selectedRow');
        }
        if (colDef.field === 'integrationSuccess') {
          retArr.push('overrideReadOnly');
        }
        if (colDef.field === 'bookingStatusId' &&
          (params.data.bookingStatusId == null || Number(params.data.bookingStatusId) === 0)) {
          retArr.push('danger');
        }

        let isCancelledBooking = false;
        if (params.data.bookingStatusId && Number(params.data.bookingStatusId) === BookingStatusList.Cancelled) {
          isCancelledBooking = true;
        }

        if (colDef.field === 'salesPersonName' && !isCancelledBooking && this.isSalesPersonMandatory) {
          if (!params.data['salesPersonId']) {
            retArr.push('danger');
          }
        }
        if (colDef.field === 'adminPersonName' && !isCancelledBooking && this.isAdminPersonMandatory && !params.data.adminPersonId) {
          retArr.push('danger');
        }
        // SM-3294
        if (Array.isArray(this.commercialConfig.uiControl.mediaLengthRequired) && colDef.field === 'mediaLength') {
          const statuses = this.uiControl.mediaLengthRequired;
          const status = params.data.bookingStatusId ? params.data.bookingStatusId : this.campaignStatus;
          if (statuses.indexOf(status) > -1 && params.data.containsDigital && (!params.data.mediaLength || Number(params.data.mediaLength) === 0)) {
            retArr.push('danger');
          }
        }

        return retArr;
      };
    }
    this.sortObj = new AgCustomSort([], [], [], this.groupingField);
  }

  /**
   * check is there any booking line with option status
   * if its true- need to show ODD and OTR column otherwise this 2 column will be hidden
   */
  isAnyBookingWithSelectedStatus(status): boolean {
    let isAnyLineWithSelectedStatus = false;
    if (this.bookingList) {
      const arr = this.bookingList.filter(lineItem => lineItem.bookingStatusId === status);
      isAnyLineWithSelectedStatus = arr.length > 0;
    }
    return isAnyLineWithSelectedStatus;
  }

  /**
   * Ag-grid - get next visible column from params column
   * used to set focus on next column after selecting value in one column
   */
  getNextColumnFieldId(params): string {
    const allCols = params.api.columnController.allDisplayedColumns;
    let nextColId = '';
    for (let i = 0; i < allCols.length; i++) {
      if (allCols[i].colId === params.column.colId) {
        nextColId = allCols[i + 1].colId || '';
        break;
      }
    }
    return nextColId;
  }

  /**
   * checks property in config >> hideUIElement
   */
  hideUIElements(key) {
    return this.dataShareService.hideUIElements(key);
  }

  /**
   * Ag-grid - Label string text renderer
   */
  textCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'ag-custom-tooltip-cell-div';
    let text = params.value;
    let toolTipText = text;
    if (params.column.colId === 'productCatalogueName') {
      toolTipText = `${params.data.productCatalogueId} - ${params.data.productCatalogueName}`;
    } else if (params.column.colId === 'bookingStartDate' || params.column.colId === 'bookingEndDate'
      || params.column.colId === 'optionDroppingDate') {
      if (params.value) {
        text = moment(params.value).format(LocaleData.displayDateFormat.toUpperCase());
      } else {
        text = '';
      }
      toolTipText = text;
    } else if (params.column.colId === 'smartBricsValue' || params.column.colId === 'pmpdiscount'
      || params.column.colId === 'grossMediaRemaining' || params.column.colId === 'grossMediaValue'
      || params.column.colId === 'grossMediaPurchased' || params.column.colId === 'netMediaValue') {
      if (params.value && typeof (params.value) === 'number') {
        text = this.decimalPipe.transform(parseFloat(params.value), `.0-${this.decimalplaces}`);
        toolTipText = text;
      }
    } else if ((params.column.colId === 'impressions' || params.column.colId === 'impressionsRemaining'
      || params.column.colId === 'impressionsPurchased') && (params.value && typeof (params.value) === 'number')) {
      text = params.value.toFixed(0);
      text = this.decimalPipe.transform(parseFloat(text), `.0-${this.decimalplaces}`);
      toolTipText = text;
    }
    // else if (params.column.colId === 'isCancellationPending') {
    //   if (params.value) {
    //     text = this.userBundle['booking.grid.changePending'];
    //     toolTipText = text;
    //   } else {
    //     text = '';
    //     toolTipText = '';
    //   }
    // }
    // else if () {
    //   if (params.data.pmpdiscount && typeof (params.data.pmpdiscount) === 'number') {
    //     text = params.data.pmpdiscount.toFixed(2);
    //     text = Number(text).toLocaleString();
    //     toolTipText = text;
    //   }
    // }
    // else if (params.column.colId === 'grossMediaRemaining') {
    //   if (params.data.grossMediaRemaining && typeof (params.data.grossMediaRemaining) === 'number') {
    //     text = params.data.grossMediaRemaining.toFixed(2);
    //     text = Number(text).toLocaleString();
    //     toolTipText = text;
    //   }
    // }
    // else if (params.column.colId === 'grossMediaValueDisplay') {
    //   if (params.data.grossMediaValueDisplay && typeof (params.data.grossMediaValueDisplay) === 'number') {
    //     text = params.data.grossMediaValueDisplay.toFixed(2);
    //     text = Number(text).toLocaleString();
    //     toolTipText = text;
    //   }
    // }
    // else if (params.column.colId === 'grossMediaPurchased') {
    //   if (params.data.grossMediaPurchased && typeof (params.data.grossMediaPurchased) === 'number') {
    //     text = params.data.grossMediaPurchased.toFixed(2);
    //     text = Number(text).toLocaleString();
    //     toolTipText = text;
    //   }
    // }
    // else if (params.column.colId == 'impressionsRemaining') {
    //   if (params.data.impressionsRemaining && typeof (params.data.impressionsRemaining) === 'number') {
    //     text = params.data.impressionsRemaining.toFixed(0);
    //     text = Number(text).toLocaleString();
    //     toolTipText = text;
    //   }
    // }
    // else if (params.column.colId == 'impressionsPurchased') {
    //   if (params.data.impressionsPurchased && typeof (params.data.impressionsPurchased) === 'number') {
    //     text = params.data.impressionsPurchased.toFixed(0);
    //     text = Number(text).toLocaleString();
    //     toolTipText = text;
    //   }
    // }
    // else if (params.column.colId === 'netMediaValue') {
    //   if (params.data.netMediaValue && typeof (params.data.netMediaValue) === 'number') {
    //     text = params.data.netMediaValue.toFixed(2);
    //     text = Number(text).toLocaleString();
    //     toolTipText = text;
    //   }
    // }
    eDiv.title = toolTipText;
    eDiv.innerHTML = text;
    return eDiv;
  }

  cellDisable(...params: boolean[]) {
    return params.indexOf(true) > -1;
  }

  onTextChange(params) {
    // redraw rows //
    const parameters = {
      rowNodes: [params.node]
    };
    this.setLocalScrollLeftPosition();
    params.api.redrawRows(parameters);
    this.setGridScrollLeftPosition();
    params?.colDef?.field !== "comments" && params.api.setFocusedCell(params.rowIndex, params.colDef.field);
  }

  /**
   * Ag-grid - cell renderer for Integration status column
   */
  integrationStatusCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'integrationStatusCol';
    let toolTipText = '';
    if (params.data[params.column.colId] === true) {
      const eImg: HTMLImageElement = document.createElement('img');
      eImg.src = 'images/integration_success.png';
      eDiv.appendChild(eImg);
      toolTipText = params.data.integrationMessage || '';
    } else if (params.data[params.column.colId] === false) {
      const eImg: HTMLImageElement = document.createElement('img');
      eImg.src = 'images/integration_fail.png';
      eDiv.appendChild(eImg);
      toolTipText = params.data.integrationMessage || '';
    }
    eDiv.title = toolTipText;
    return eDiv;
  }

  /**
   * check is there any booking line with integrationSuccess property
   * if its true- need to show integration status column otherwise this integrationStatus column will be hidden
   */
  isAnyBookingWithIntegrationStatusField(): boolean {
    let isAnyLineWithIntegrationStatus = false;
    if (this.bookingList) {
      const arrStatusWithOption = this.bookingList.filter(lineItem => typeof (lineItem.integrationSuccess) !== 'undefined');
      isAnyLineWithIntegrationStatus = arrStatusWithOption.length > 0;
    }
    return isAnyLineWithIntegrationStatus;
  }
  /**
   * @description will set the value of flag to diable the dropdown of status for SM-4649
   * @author Nishit Parekh
   * @param {*} curRow booking details for particular row
   * @returns boolean
   * @memberof BookingGridUkComponent
   */
  populateStatusLock(curRow) {
    if (curRow.isCancellationPending && this.dataShareService.userModel.commercialTabAccess.changePending) {
      return false;
    } else {
      return curRow.bookingStatusId === BookingStatusList.Confirmed && this.campaignStatus && SystemFlags.isLoadedCampaign;
    }

  }

  lookupCall(params: object, urlKey: string, key: string) {
    return this.sharedService.makeServerCall(params, this.dataShareService.getServiceCallUrlByKey(urlKey))
      .map((data) => {
        let returnedData = [];
        if (data && data.data[key] && data.data[key].length) {
          returnedData = data.data[key];
        }
        return returnedData;
      });
  }

  canBgvEditable() {
    if (SystemFlags.isLoadedCampaign && SystemFlags.splitable && SystemFlags.readOnlyWorkspace && (!this.uiControl.bgvEditableUsingToken || (this.uiControl.bgvEditableUsingToken && this.config.userData.tokens.indexOf('RESTRICT_USER_FROM_EDITING_BGV_FOR_LIVE_CAMPAIGN') !== -1))) {
      this.isBgvEditable = false;
    } else {
      this.isBgvEditable = true;
    }
  }

  checkIsStatusChanged() {
    for (let booking of this.bookingList) {
      if (booking.isStatusChanged) {
        return true;
      }
    }
    return false;
  }

}
