<app-sb-modal-wrapper [title]="" [titleBackgroundColor]="brickBgColor" (closed)="onModalClosed($event)" [isButtonHide] = "true"  (saved)="onModalSaved($event)">
    <div class="modal-body" style="max-height: 100%">
        <div class="tabs_container" style="padding: 15px;">
            <div class="tab">
                <div style="text-align: center;">
                    <app-concertina [availability]="true" [initialConfig]="initialConfig" [frameStatusFiltersEnabled]="false"
                    [frameSearchFilterEnabled]="true" [frameDetailsEnabled]="false"></app-concertina>
                </div>
            </div>
        </div>
    </div>

</app-sb-modal-wrapper>
