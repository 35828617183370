import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { GridOptions, ColDef } from 'ag-grid-community';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { DataShareService } from '../../../core/services/data-share.service';
import {
  // AgCollapseExpandComponent,
  AgCheckboxComponent,
  AgCustomFilterMenuComponent,
  AgCustomHeaderComponent,
  // AgTextCellEditorComponent,
  // AgSelectCellEditorComponent,
  // AgCurrencyCellRendererComponent,
  // AgSelectCellRendererComponent,
  // AgTextCellRendererComponent,
  // AgPercentageCellRendererComponent,
  AgCustomSort,
  AgCustomRowUtil,
  DataFormatterService,
  AgTextFloatingFilterComponent,
  // AgSelectFloatingFilterComponent,
  AgSelectCellRendererComponent,
  AgSelectCellEditorComponent,
  AgCurrencyCellRendererComponent,
  AgTextCellRendererComponent
} from '../ag-grid-support/index';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { LocaleData } from '../../../core/utils/LocaleData';
import { Discount } from '../../commercial.model';
import { CommercialService } from '../../../core/services';
import { ExcelCell, ExcelColumn, ExportToExcel } from '../../../core/utils/exportToExcel';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-discount-grid-spain',
  templateUrl: './discount-grid-spain.component.html',
  styleUrls: ['../ag-grid-custom-style.css', '../ag-grid-col-select-style.css', './discount-grid-spain.component.css']
})
export class DiscountGridSpainComponent implements OnInit, OnDestroy {

  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  @Input() campaignDetails: any = {};
  @Input() brandData = [];
  sortObj: AgCustomSort;
  /**
   * Ag-Grid Options Object
   */
  gridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  columnDefs: ColDef[];

  /**
   * column list to show in show/hide column dropdown
   */
  columnList: any = [];

  /**
   * list of Cart Data
   * @memberof CartDetailComponent
   */
  rowData: any[] = [];

  /**
   * Grid footer data
   */
  footerRowData: any[];

  /**
   * user bundle data
   */
  userBundle: any = {};

  totalFrames = 0;
  totalGrossPrice = 0;

  numericFields = [];
  dateRangeFields = [];
  selectFields = [];
  groupingField: string = null;
  notifyGridSubscription: Subscription;

  precisionOnPrice = 2;
  precisionOnPercentage = 2;

  overrideDiscountOptions = [];
  subs: Subscription[] = [];
  floatingDealStatusCategoryId = 7;

  negotiationTypes = [];
  indexedNegotiationTypes = [];
  constructor(
    private dataShareService: DataShareService,
    private toaster: LogHelperService,
    private dataFormatterService: DataFormatterService,
    private commercialService: CommercialService,
    private datePipe: DatePipe
  ) {
    this.subs.push(this.commercialService.discounts$.subscribe((res: Discount[]) => {
      this.rowData = [];
      this.reset();
      this.campaignDetails.discounts = _.clone(res);
      this.prepareRowData();
    }));
  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.overrideDiscountOptions = [
      {
        value: true,
        label: this.userBundle['discount.es.yes'] || 'Yes',
      },
      {
        value: false,
        label: this.userBundle['discount.es.no'] || 'No',
      }
    ];
    this.setArrayLists();

    this.setFooterRows();
    this.prepareRowData();
    this.prepareColDef();

    this.reset();

    // we pass an empty gridOptions in, so we can grab the api out
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      onRowDataChanged: (event) => {
        event.api.setPinnedBottomRowData(this.footerRowData);
      },
      scrollbarWidth: 10,
      onGridReady: this.onGridReady,
      getRowHeight: this.getRowHeight,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      suppressScrollOnNewData: true,
      onFilterChanged: this.onFilterChanged,
      onCellEditingStopped: this.onCellEditingStopped,
      groupingField: this.groupingField,
      suppressMovableColumns: true,
      isExternalFilterPresent: () => true,
      // tslint:disable-next-line:ter-arrow-parens
      doesExternalFilterPass: (node) => node.data.isVisible,
      onCellClicked: (params) => {
        if (params.rowPinned === 'bottom' && params.rowIndex === 0 && params.column.isCellEditable(params.node)) {
          const par: any = {
            column: params.column,
            rowIndex: params.rowIndex,
            rowPinned: params.rowPinned
          };
          params.api['rowRenderer'].startEditingCell(par);
        }
      }
    } as GridOptions;

  }

  setNegotiationType() {
    this.negotiationTypes = this.campaignDetails.negotiationTypes;
  }

  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.forEach((r) => {
        r.isFilterPass = true;
      });
    }
    AgCustomRowUtil.setParentRowSelectedProperty(this.rowData, this.groupingField);
    // Refresh Grid Header checkbox component
    this.gridOptions.api['headerRootComp'].forEachHeaderElement((element) => {
      if (element.column.colId === 'selectChk' && element.columnApi && element.childComponents && element.childComponents.length > 1) {
        const headerCmpIns = element.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  clearAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    this.gridOptions.api.onFilterChanged();
  }

  setArrayLists() {
    this.setNegotiationType();
  }

  onGridReady = () => {
    this.columnList = this.gridOptions.columnApi.getAllColumns();
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else if (params.data.isVisible) {
      height = 30;
    }
    return height;
  }

  isFullWidthCell = (rowNode): boolean => {
    return (rowNode.data.gridSummary ? true : false);
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }

  onCellEditingStopped = (event) => {
    if (event.rowPinned !== 'bottom') {
      if (event.column.colId === 'networkPrice') {
        event.data.networkPrice = LocaleData.replaceLocalDecimalCharWithDot(event.data.networkPrice);
      }
      this.rowData = _.clone(this.rowData);
    }
  }

  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen() && $event.button !== 2 && !dropdownEle._isEventFromToggle($event)
      && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
      dropdownEle.close();
    }
  }

  prepareRowData() {
    this.rowData = [];
    const tempRowData = [];

    for (const curRow of this.campaignDetails.discounts) {
      curRow.isFilterPass = true;
      curRow.isParent = false;
      curRow.isVisible = true;
      curRow.isSelected = false;
      tempRowData.push(curRow);
    }
    this.rowData = _.clone(tempRowData);
  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinRowIndex: 1 },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
  }

  getGridSummary() {
    this.calculateGridSummaryValues();
    return `<span>${this.userBundle['discount.common.totalFrames']}
    : ${this.getChildRows(null, false).length} &#45; ${this.userBundle['discount.common.selectedFrames']}
      : ${this.getSelectedChildRows().length} (<b>${this.userBundle['discount.es.totalFrames']}
      </b>: ${this.totalFrames}, <b>${this.userBundle['discount.es.totalGrossPrice']}
      </b>: ${this.dataFormatterService.currency(this.totalGrossPrice)})</span></div>`;
  }

  prepareColDef() {
    this.columnDefs = [
      // {
      //   headerName: '', width: 30, editable: false, pinned: 'left', filter: false,
      //   cellRendererFramework: AgCollapseExpandComponent,
      //   pinnedRowCellRenderer: this.blankCellRenderer,
      //   headerComponentFramework: AgCollapseExpandComponent,
      //   cellClass: (params): string[] => {
      //     const retArr: string[] = [];
      //     if (params.data.isSelected) {
      //       retArr.push('selectedRow');
      //     }
      //     if (params.node.rowPinned === 'bottom' && params.rowIndex === 1) {
      //       retArr.push('borderBottom');
      //     }
      //     return retArr;
      //   }
      // },
      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false, colId: 'selectChk',
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: (params): string[] => {
          const retArr: string[] = [];
          if (params.data.isSelected) {
            retArr.push('selectedRow');
          }
          if (params.node.rowPinned === 'bottom' && params.rowIndex === 1) {
            retArr.push('borderBottom');
          }
          return retArr;
        }
      },
      {
        headerName: this.userBundle['discount.es.networkName'], field: 'networkName', width: 300, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.networkNamePinnedRowCellRenderer,
      },
      {
        headerName: this.userBundle['discount.es.package'], field: 'productCatalogueName', width: 100, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.es.channel'], field: 'channelName', width: 180, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.es.weekNumber'], field: 'weekNumberOfYear', width: 100, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.es.weekDays'], field: 'weekDays', width: 180, editable: false, pinned: false,
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.es.dates'], field: 'dateRange', width: 150, editable: false, pinned: false,
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.es.numberFrames'], field: 'numberFrames', width: 110, editable: false,
        cellRendererFramework: AgTextCellRendererComponent,
        cellRendererParams: {
          onlyNumber: true,
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgTextCellRendererComponent,
        pinnedRowCellRendererParams: {
          onlyNumber: true,
          cellEditable: () => {
            return false;
          }
        },
      },
      {
        headerName: this.userBundle['discount.es.negotiationType'],
        field: 'negotiationTypeId', width: 150, editable: true,
        cellRendererFramework: AgSelectCellRendererComponent,
        cellRendererParams: {
          cellEditable: (rowData) => {
            return !rowData.isParent;
          },
          valKey: 'negotiationTypeId',
          textKey: 'negotiationTypeName',
          dataArr: () => {
            return this.negotiationTypes;
          }
        },
        pinnedRowCellRendererFramework: AgSelectCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: (rowData) => {
            return !rowData.isParent;
          },
          valKey: 'negotiationTypeId',
          textKey: 'negotiationTypeName',
          dataArr: () => {
            return this.negotiationTypes;
          }
        },
        cellEditorFramework: AgSelectCellEditorComponent,
        cellEditorParams: {
          cellEditable: (rowData) => {
            return !rowData.isParent;
          },
          valKey: 'negotiationTypeId',
          textKey: 'negotiationTypeName',
          dataArr: () => {
            return this.negotiationTypes;
          },
        }
      },
      {
        headerName: this.userBundle['discount.es.grossPrice'], field: 'grossPrice', width: 110, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          },
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          },
          gridSumRow: 1
        },
      },

    ];

    this.numericFields = [
      'numberFrames'
    ];

    this.dateRangeFields = [

    ];
    this.selectFields = ['negotiationTypeId'];

    const decimalFieldsForFilter = [
      'grossPrice'
    ];
    for (let i = 1; i < this.columnDefs.length; i++) {

      this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
      this.columnDefs[i].floatingFilterComponentParams = {
        value: '',
        suppressFilterButton: true,
        decimalRound: decimalFieldsForFilter.indexOf(this.columnDefs[i].field) > -1 ? 2 : null
      };

      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };

      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => {
        const retArr: string[] = [];
        if (params.data.isParent || params.node.rowPinned === 'bottom') {
          retArr.push('fontBold');
        }

        if (params.node.rowPinned === 'bottom' && (i > 4 || params.rowIndex === 1)) {
          retArr.push('borderBottom');
        }

        if (params.data.isSelected) {
          retArr.push('selectedRow');
        }
        return retArr;
      };
    }

    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField, true);
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
  }

  blankCellRenderer() {
    return '';
  }

  networkNamePinnedRowCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {
      // apply Icon
      const eImgApply: HTMLImageElement = document.createElement('img');
      eImgApply.src = 'images/general/apply.png';
      eImgApply.title = 'Apply';
      eImgApply.onclick = this.apply;
      eDiv.appendChild(eImgApply);

      // Reset Icon
      const eImg: HTMLImageElement = document.createElement('img');
      eImg.src = 'images/general/reset.png';
      eImg.title = 'Reset';
      eImg.onclick = this.reset;
      eDiv.appendChild(eImg);
    } else if (params.rowIndex === 1) {
      eDiv.innerHTML = `<span class='cellRightSpan fontBold'>${this.userBundle['discount.common.totalBar']}</span>`;
    }
    return eDiv;
  }

  updateRowOnOverrideDiscount(selectedRow: Discount) {
    if (selectedRow.overrideDiscounts && String(selectedRow.overrideDiscounts) === 'true') {
      selectedRow.discount = 0;
      selectedRow.mediaDiscount1 = 0;
      selectedRow.mediaDiscount2 = 0;
    } else {
      selectedRow.discount = _.clone(selectedRow.discountStafRabDefault);
      selectedRow.mediaDiscount1 = _.clone(selectedRow.mediaDiscount1Default);
      selectedRow.mediaDiscount2 = _.clone(selectedRow.mediaDiscount2Default);
    }
  }

  apply = () => {
    const selectedChildRows = this.getSelectedChildRows(null, true);
    if (selectedChildRows.length > 0) {
      if (this.footerRowData[0] || this.footerRowData[0].negotiationTypeId) {
        for (const selectedChildRow of selectedChildRows) {
          if (this.footerRowData[0].negotiationTypeId != null) {
            selectedChildRow.negotiationTypeId = this.footerRowData[0].negotiationTypeId;
          }
        }
        this.resetApply();
        this.rowData = _.clone(this.rowData);
      }
    } else {
      this.toaster.logInfo(this.userBundle['discount.es.selectionError'] || 'Select atleast one row!');
    }
  }

  reset = () => {
    this.resetApply();
    this.rowData = _.clone(this.rowData);
  }

  resetApply = () => {
    const columnArray = [
      'negotiationTypeId'
    ];
    for (const col of columnArray) {
      this.footerRowData[0][col] = null;
    }
  }

  groupingCellRenderer(params) {
    return params.data.childCount ? params.data.childCount : '';
  }

  toggleColumnVisible(colId, isVisible) {
    const isVisibleLoc = !isVisible;
    this.gridOptions.columnApi.setColumnVisible(colId, isVisibleLoc);
  }

  // Data functions
  getChildRows(groupingId?: string, filterOnly = true): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  getSelectedChildRows(groupingId?: string, filterOnly = false): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  getParentRows(groupingId?: string, data?: any[], groupingField?: string): any[] {
    const newData = data || this.rowData;
    return AgCustomRowUtil.getParentRows(newData, groupingField, groupingId);
  }

  sumFieldValues(rows: any[], fieldName: string): number {
    return AgCustomRowUtil.sumFieldValues(rows, fieldName);
  }

  calculateGridSummaryValues() {
    this.totalFrames = this.getTotalOfSelectedProperty('numberFrames');
    this.totalGrossPrice = this.getTotalOfSelectedProperty('grossPrice');
  }

  getTotalOfSelectedProperty(propertyName: string, filterOnly = false): number {
    const rows = AgCustomRowUtil.getSelectedChildRows(this.rowData, null, null, filterOnly);
    return AgCustomRowUtil.sumFieldValues(rows, propertyName);
  }

  /*
  * download cart data in excel format
  */
  downloadExcel() {
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    // Add Header Row
    let sheetRow: ExcelCell[] = [];
    for (let j = 1; j < this.columnDefs.length; j++) {
      const cell: ExcelCell = {
        text: this.columnDefs[j].headerName,
        fontColor: '#FFFFFF',
        bgColor: '#000000',
        borderColor: '#CCCCCC',
        isFontBold: true
      };
      const col: ExcelColumn = {
        width: this.columnDefs[j].field === 'week' ? 15 : 12
      };
      excelColumn.push(col);
      sheetRow.push(cell);
    }
    sheetData.push(sheetRow);

    // Add Data rows
    let rowsInserted = 0;
    for (const row of this.rowData) {
      if (row.isParent) {
        continue;
      }

      sheetRow = [];
      for (let j = 1; j < this.columnDefs.length; j++) {
        let cellVal = row[this.columnDefs[j].field];
        if (this.selectFields.indexOf(this.columnDefs[j].field) > -1) {
          cellVal = row[`${this.columnDefs[j].field}_sort`];
          if (this.columnDefs[j].field === 'negotiationTypeId' && !row[`${this.columnDefs[j].field}_sort`]) {
            const negotiationType = this.negotiationTypes.find(type => String(type.negotiationTypeId) === String(row[this.columnDefs[j].field]));
            cellVal = negotiationType ? negotiationType.negotiationTypeName : row[this.columnDefs[j].field];
          }
        }
        if ((this.columnDefs[j].field === 'startDate' || this.columnDefs[j].field === 'endDate') && cellVal) {
          cellVal = this.datePipe.transform((new Date(cellVal)), LocaleData.displayDateFormat);
        }
        const cell: ExcelCell = {
          text: cellVal,
          fontColor: '#000000',
          bgColor: rowsInserted % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
          borderColor: '#CCCCCC'
        };
        sheetRow.push(cell);
      }
      rowsInserted++;
      sheetData.push(sheetRow);
    }
    const cartText = 'discount';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }

  trackByField(index, column) {
    return column?.colDef?.field;
  }
}
