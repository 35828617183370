import { Injectable } from '@angular/core';
import { AppCommercialComponent } from '../app-commercial/app-commercial.component';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CommercialService } from '../../geoplanner/src/app/commercial';
import { DataShareService, LoaderService, StateService } from '../../geoplanner/src/app/core/services';
import { SearchService } from '../../geoplanner/src/app/search';
import { SystemFlags } from 'app/geoplanner/src/app/models';

@Injectable()
export class CanDeactivateCommercialService implements CanDeactivate<AppCommercialComponent> {
  userBundle: object;
  constructor(private router: Router,
    private commercialService: CommercialService,
    private loaderService: LoaderService,
    private searchService: SearchService,
    private stateService: StateService,
    private dataShareService: DataShareService) { }

  // @ts-ignore
  canDeactivate(component: AppCommercialComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean { // tslint:disable-line
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    if (this.loaderService.getBackButton()) {
      this.loaderService.setBackButton(false);
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);
      return false;
    } else if (this.commercialService.getGoToOtherPage()) {
      return true;
    } else {
      if (SystemFlags.isAPICampaign && this.commercialService.isStatusChanged) {
        const title = this.userBundle["commercial.unsaved.title"] ? this.userBundle["commercial.unsaved.title"] : "Information";
        const message = this.userBundle["commercial.unsaved.message"] ? this.userBundle["commercial.unsaved.message"] : "You have unsaved status changes on this page that will be lost. <br/> Are you sure you want to leave?";
        const leaveButton = this.userBundle["commercial.unsaved.button.leave"] ? this.userBundle["commercial.unsaved.button.leave"] : "Leave this page";
        const stayButton = this.userBundle["commercial.unsaved.button.stay"] ? this.userBundle["commercial.unsaved.button.stay"] : "Stay on this page";
        this.commercialService.openConfirmationModal(message, title, "#ef3f3b", false, true, leaveButton, stayButton, nextState.url, true);
        return false;
      } else {
        component.updateCommercialinfo().then(() => {
          const params = this.stateService.getAnyValue('paramsForSmartSearch');
          if (params && Object.keys(params).length) {
            this.searchService.emitLoadCampaign(params);
            this.stateService.setAnyValue('paramsForSmartSearch', undefined);
          }
          this.commercialService.setGoToOtherPage(true);
          this.router.navigate([nextState.url]);
        });
        return false;
      }
    }
  }

}
