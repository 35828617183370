export class TemplateProps {
  displayName: string;
  displayId: string;
  displayRadioButton: boolean;
  searchPlaceHolder: string;
  showId: boolean;
  noDataAvailableText?: string;
  allowCustomCheckBoxEvents?: boolean;
  expandFirstNode ?= false;
  localSolverEnabled ?= false;
  checkboxIconEnabled ?= false;
}
