<div #myHeader class="header animated bounceInDown" *ngIf="!objectiveMode" [ngStyle]="{'background': enabledPCM ?  '#58002E' : '#e6e6e6'}">
  <div class="logo">
    <a href="javascript:void(0)" (click)="onLogoClick()">
      <img src="assets/img/viooh_logo.png" alt="Logo" style="max-height: 60px">
    </a>
  </div>
  <a *ngIf="auditRecords && auditRecords.length > 0 && router.url.indexOf('workspace') != -1" href="javascript:void(0)"
    class="pull-right audit-warning-icon" title={{auditTooltipText}} (click)="showAuditMessages()">
    <img src="assets/img/iconfinder_Warning_31226.ico" alt="Warning">
  </a>
  <app-global-search *ngIf="initialConfig && !enabledPCM" [hidden]="selectedEnvironment == ''" [userBundle]="initialConfig.userBundle"></app-global-search>
  <div class="menu_header" *ngIf="!enabledPCM" [hidden]="selectedEnvironment == ''">
    <ul>
      <li *ngIf="userModel?.searchTabAccess?.tabAccess" [ngClass]="{'disabled': disabledSearchTab}"
        routerLinkActive="active">
        <a routerLink="/search">{{initialConfig && initialConfig.userBundle['search'] || 'Search'}}</a>
      </li>
      <li *ngIf="userModel?.workspaceTabAccess?.tabAccess" [ngClass]="{'disabled': disabledWorkSpaceTab}"
        routerLinkActive="active">
        <a routerLink="/workspace">{{initialConfig && initialConfig.userBundle['workspace'] || 'Workspace'}}</a>
      </li>
      <li *ngIf="userModel?.geoPlannerTabAccess?.tabAccess && geoPlannerVisible"
        [ngClass]="{'disabled': disabledGeoPlannerTab}" routerLinkActive="active">
        <a routerLink="/geoplanner">{{initialConfig && initialConfig.userBundle['geoplanner'] || 'Geo Mapper' }}</a>
      </li>
      <li *ngIf="userModel?.visualPlannerTabAccess?.tabAccess && visualPlannerVisible"
        [ngClass]="{'disabled': disabledVisualPlannerTab}" routerLinkActive="active">
        <a routerLink="/visualplanner">{{initialConfig && initialConfig.userBundle['visualplanner'] || 'Visual
          Planner' }}</a>
      </li>
      <li *ngIf="userModel?.resultTabAccess?.tabAccess" [ngClass]="{'disabled': !resultTabActive}"
        routerLinkActive="active">
        <a routerLink="/result">{{initialConfig && initialConfig.userBundle['result'] || 'Result'}}</a>
      </li>
      <li *ngIf="userModel?.resultMapTabAccess?.tabAccess" [ngClass]="{'disabled': !resultTabActive}"
      routerLinkActive="active">
      <a routerLink="/resultmap">{{initialConfig && initialConfig.userBundle['resultMap'] || 'Result Map'}}</a>
      </li>
      <li *ngIf="userModel?.commercialTabAccess?.tabAccess" [ngClass]="{'disabled': !commercialTabActive}"
        routerLinkActive="active">
        <a routerLink="/commercial">{{initialConfig && initialConfig.userBundle['commercial'] || 'Commercial'}}</a>
      </li>
    </ul>
  </div>
  <div class="pcm-logo" *ngIf="enabledPCM">
    <img src="assets/img/pcm_logo.png" alt="Logo">
  </div>
  <div *ngIf="userModel?.searchTabAccess?.updateLanguage && supportedLanguages.length && !isOnCommercial" class="control_group">
    <select id="campaignGroup" class="form_control chzn-container" [(ngModel)]="selectedLanguage"
      (change)="updateLanguage()" [style.color]="enabledPCM ? '#FFF' : 'inherit'">
      <option *ngFor="let lang of supportedLanguages; trackBy: trackByLocale;" [value]="lang.locale" [style.color]="'#000'">
        {{lang.language}}
      </option>
    </select>
  </div>
  <span *ngIf="lockedMessage && !enabledPCM" class="pull-right alert alert-danger header_warning"
    style="width:28%;" [innerHTML]="lockedMessage"></span>
    <span *ngIf="forceRerun && !enabledPCM" class="forceReRunMsg pull-right alert alert-danger header_warning"
  style="width:38%; margin-right: 10px;">{{forceRerunMessage}}</span>
  <span *ngIf="infoMessage && !enabledPCM" class="pull-right alert alert-primary header_warning"
    style="width:28%;">{{infoMessage}}</span>
</div>

<div class="header animated bounceInDown" *ngIf="objectiveMode" style="background: rgb(20, 83, 92);">
  <div class="logo" (click)="backToWorkspace()">
    <a href="javascript:void(0)">
      <img src="./../../../assets/img/viooh_logo.png" alt="Logo" style="max-height: 60px">
    </a>
  </div>
  <div class="objective-title">
    {{initialConfig.userBundle['workspace.objective.bric.title'] || 'Objective'}}
  </div>
</div>
