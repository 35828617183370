<div class="row" style="line-height: 21px;height: 21px;">
    <label class="col-md-12 small" style="padding: 0px !important;width: 90%;float: left;">
        <input type="checkbox" class="checkbox small" id="checkpaneFurniture{{rowIndex}}" [(ngModel)]="params.data.selected" (change)="onSelectChange()"
            name="checkpaneFurniture{{rowIndex}}" [hidden]="params.data.isPlottedMarker === true" [disabled]="assetStatus == furnitureStatus.unavailable || isFurnitureLocked || params.data.disabled">
        <span style="text-overflow: ellipsis;overflow: hidden;" [ngClass]="{'hideBackground': params.data.isPlottedMarker === true}">{{shortenMarker ? params.data.furnitureName : params.data.furnitureFullName }}</span>
        <!-- <label for="checkpaneFurniture{{rowIndex}}"><em class="fa fa-check"></em>{{params.data.furnitureName}}</label> -->
    </label>
    <div class="imgIcon" (click)="onImageClick()" *ngIf="params.data.galleryUrl">
        <em class="fa fa-picture-o"></em>
    </div>
</div>
<div class="row" style="line-height: 21px;">
    <div class="col-md-11 furniture-address">
        <span>{{initialConfig.userBundle['geoplanner.text.address'] || "Address"}}:</span>
        <span [title]="params.data.address">{{params.data.address}}</span>
    </div>
    <div class="col-md-1" style="padding: 0px;">
        <span class="fa fa-clipboard" style="cursor: pointer;" [title]="initialConfig.userBundle['geoplanner.text.copyAddress']" (click)="copyAddress(params.data.address)"></span>
    </div>
</div>