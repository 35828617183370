import { Component, OnInit, Input } from '@angular/core';
import { DataShareService } from '../../core/services';
import { LogHelperService } from '../../core/services/log-helper.service';
import { FreeOFChargeResult } from '../commercial.model';

@Component({
  selector: 'app-freeofcharge',
  templateUrl: './freeofcharge.component.html',
  styleUrls: ['./freeofcharge.component.css']
})
export class FreeOfChargeComponent implements OnInit {
  private userBundle: any;
  public freeOfChargeSelectedValue;
  brickBgColor = '#ef3f3b';
  public campaign = {
    freeOfCharge: new FreeOFChargeResult(),
    freeText: ''
  };
  public reasonFreeDropdown = null;
  public freeOfCharge = {
    labletext: null,
    title: null,
    bgcolor: '#DEDEDE'
  };
  public showHideText = false;
  autocompleteItems: any;
  initialConfig = null;
  @Input() resolveObject: any;

  constructor(
    private dataShareService: DataShareService,
    private logHelperService: LogHelperService
  ) {
  }

  ngOnInit() {

    this.initialConfig = this.dataShareService.getInitialConfig();
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.reasonFreeDropdown = this.initialConfig.auditType[1].auditReason;
    this.freeOfCharge = {
      labletext: this.userBundle['commercial.label.foc'],
      title: this.userBundle['commercial.title.foc'].toUpperCase(),
      bgcolor: '#DEDEDE'
    };
  }

  otherReason = function(item) {
    if (item && item.specifyOther) {
      this.showHideText = true;
    } else {
      this.showHideText = false;
    }
    this.campaign.freeOfCharge = item;
  };

  onModalSaved(event) {
    if (Object.keys(this.campaign.freeOfCharge).length) {
      if (!(this.campaign.freeOfCharge.specifyOther)) {
        event.activeModal.close(this.campaign);
      } else {
        if (this.campaign.freeText !== '' && this.campaign.freeText != null) {
          event.activeModal.close(this.campaign);
        } else {
          this.logHelperService.logError(this.initialConfig.userBundle['commercial.error.foc']);
        }
      }

    } else {
      this.logHelperService.logError(this.initialConfig.userBundle['commercial.error.foc']);
    }

  }

  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }

  trackByItem(index, item) {
    return item;
  }

}
