<div class="contentarea">
  <dl class="accordion animated bounceInDown content">
    <dt (click)="hideDiscountGrid = !hideDiscountGrid">
      <a href="javascript:void(0)" class="title" [ngClass]="{'down' : !hideDiscountGrid}">
        <span class="text" *ngIf="commercialConfig && commercialConfig.uiControl && commercialConfig.uiControl.showDiscountGrid">
          {{initialConfig.userBundle['commercial.tabTitle.norway.discountGrid']}}
        </span>
        <span class="text" *ngIf="commercialConfig && commercialConfig.uiControl && !commercialConfig.uiControl.showDiscountGrid">
          {{initialConfig.userBundle['commercial.tabTitle.discountGrid']}}
        </span>
        <span class="icon_wrapper">
          <span class="icons animated"></span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'down' : !hideDiscountGrid}">
      <app-discount-grid-norway *ngIf="commercialData" [campaignDetails]="campaignDetails" [uiControl]="commercialConfig.uiControl"></app-discount-grid-norway>
    </dd>
  </dl>

  <dl class="accordion animated bounceInDown content campaign-details">
    <dt (click)="hideCampaignDetails = !hideCampaignDetails">
      <span class="editable-title">
        <span class="text">{{initialConfig.userBundle['commercial.tabTitle.campaignDetails']}}
          &nbsp;-&nbsp;
        </span>
        <span class="text allow-copy"> {{commercialData?.campaignReference}}
        </span>
      </span>
      <a href="javascript:void(0)" [ngClass]="!hideCampaignDetails?'down':''" class="title">
        <span class="right">
          <span class="text">{{commercialData?.bookingStatusName}}</span>
          <span class="icon_wrapper">
            <span class="icons animated"></span>
          </span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'readOnly' : systemFlags.readOnly, 'down' : !hideCampaignDetails}">
      <app-campaign-details-norway *ngIf="commercialData" [commercialConfig]="commercialConfig"
        [campaignDetails]="campaignDetails" [historyROC]="historyROC" (manuallyCPTUpdated)="manuallyCPTUpdated()">
      </app-campaign-details-norway>
    </dd>
  </dl>

  <div *ngIf="commercialData">
    <dl class="accordion animated bounceInDown content booking-grid">
      <dt (click)="hideBookingDetails = !hideBookingDetails">
        <span class="editable-title">
          <span class="text">{{initialConfig?.userBundle['commercial.tabTitle.bookingDetails']}} </span>
        </span>
        <a href="javascript:void(0)" class="title" [ngClass]="!hideBookingDetails?'down':''">
          <span class="right">
            <span class="icon_wrapper">
              <span class="icons animated"></span>
            </span>
          </span>
        </a>
      </dt>
      <dd [ngClass]="{'down' : !hideBookingDetails}">
        <app-booking-grid-uk *ngIf="commercialData" [commercialConfig]="commercialConfig"
          [bookingList]="commercialData.bookingList">
        </app-booking-grid-uk>
      </dd>
    </dl>
  </div>

  <app-commercial-buttons *ngIf="commercialData" [commercialConfig]="commercialConfig"
    [campaignDetails]="campaignDetails" [validateFn]="validateCommercialCampaign"
    [populateRequestJson]="createRequestJSONForCommercial" (bookCampaign)="onBookCampaign($event)">
  </app-commercial-buttons>

</div>
