export class FileUpload {
  listUpload: FileList = new FileList();
  fileNameLabel = '';
  selectedSOTOption = null;
}

export class FileList {
  fileList: any[] = [];
}

export class MultiTarget {
  allowDays = false;
  allowDayParts = false;
  allowHours = false;
  allowSOT = false;
  allowCustomSOT = false;
  sotRangeMinVal = 0;
  sotRangeMaxVal = 100;
  increment = 15;
  defaultSot = 15;
  userSelectionId = -99;
}
