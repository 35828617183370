import { DataShareService } from '../../core/services';
import { InitialConfigModel } from '../../models';
import { Options, LabelType } from '@angular-slider/ngx-slider';

interface MinMaxStepValues {
  min: number;
  max: number;
  step: number;
  solutionPrice: any;
}

export class PricingBase {

  /**
   * @description
   * @type {object}
   * @memberof PricingBase
   */
   userBundle: object;

  /**
   * It contians initial config data.
   */
  public initialConfig: InitialConfigModel;

  /**
   * selected pricing value
   */
  public pricingFactor = 0;

  /**
   * column configurable values
   * @private
   */
  private minMaxStepValues: MinMaxStepValues;

  /**
   * ngx-slider options
   */
  public options: Options = {
    floor: 0,
    ceil: 10
  };

  /**
   * Creates an instance of LocationBase.
   * @param {DataShareService} dataShareService -  Service to share data between compoenents.
   * @memberof PricingBase
   */
  constructor(
    private dataShareService: DataShareService,
  ) {
    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  /**
   * initialize the data of instance
   * @param {*} modalPricingValue - selected value of cell
   * @param {MinMaxStepValues} minMaxStepValues - column configurable values
   * @memberof PricingBase
   */
  init(modalPricingValue, minMaxStepValues: MinMaxStepValues) {
    this.minMaxStepValues = minMaxStepValues;
    this.pricingFactor = minMaxStepValues.min;
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    if (modalPricingValue && modalPricingValue.pricingFactor) {
      this.pricingFactor = modalPricingValue.pricingFactor;
    }
    // Shreni : Ravi : By default set it to the intermediate value
    if (this.pricingFactor === 0) {
      this.pricingFactor = parseInt(((minMaxStepValues.max - minMaxStepValues.min) / 2).toString(), 10);
    }

    // slider option setting
    // settimeout is temporary workaround the slider issue
    // Dynamic min and max value for handles are not being applied
    // issue Ref: https://github.com/angular-slider/ngx-slider/issues/58
    setTimeout(() => {
      this.setSliderOptions();
    }, 400);
  }

  /**
   * set the slider options from configurable object
   * @private
   * @memberof PricingBase
   */
  private setSliderOptions(): void {
    this.options = {
      step: this.minMaxStepValues.step,
      floor: this.minMaxStepValues.min,
      ceil: this.minMaxStepValues.max,
      showSelectionBar: true,
      translate: (value: number, label: LabelType): string => {
        let retStr = value;
        switch (label) {
          case LabelType.Low:
          case LabelType.Ceil:
          case LabelType.Floor:
            retStr = this.minMaxStepValues.solutionPrice ? parseInt(this.minMaxStepValues.solutionPrice[value][0], 10) : value;
            break;
        }
        return this.initialConfig.systemData.currencySymbol + retStr;
      }
    };
  }

}
