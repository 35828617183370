import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FilterDataService } from './../../core/services/filter-data.service';

@Component({
  selector: 'app-facing-filter',
  templateUrl: './facing-filter.component.html',
  styleUrls: ['./facing-filter.component.css']
})
export class FacingFilterComponent implements OnInit {

    /**
     * It will take data to configure filter window.
     */
  @Input() resolveObject;
    /**
     * It calls event to close filter winodw.
     */
  @Output() closeSidebar = new EventEmitter<string>();
    /**
     * It contains facing filter data.
     */
  public facing: any;
    /**
     * List of channel
     * @type {Array<any>}
     */
  facings: any[] = [
    {
      id: 1,
      name: 'NORTH'
    }, {
      id: 2,
      name: 'SOUTH'
    }, {
      id: 3,
      name: 'EAST'
    }, {
      id: 4,
      name: 'WEST'
    }
  ];
    /**
     * Constructor
     * @param filterDataService Service which used to handle filter data.
     */
  constructor(private filterDataService: FilterDataService) { }

    /**
     * Function which loads initially.
     */
  ngOnInit() {
    this.facing = this.filterDataService.getFacing();
    if (!(this.facing && this.facing.length > 0)) {
      this.facing = [];
    }
  }

    /**
     * To store selected items/data.
     */
  saveClick() {
    this.filterDataService.setFacing(this.facing);
    this.closeSidebar.emit();
  }

    /**
     * To close filter window.
     */
  closeClick() {
    this.closeSidebar.emit();
  }

  trackById(index, item) {
    return item?.id;
  }

}
