import { Injectable } from '@angular/core';
import { DataShareService } from './../../core/services/data-share.service';
import { BrickBaseService } from './../../core/services/brick-base.service';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import {
  InitialConfigModel, TagBricSelectedDataModel,
  MediaBricSelectedDataModel, SelectionItemModel,
  FilterBarModel, FormatBricSelectedDataModel,
  LocationBricSelectedDataModel, SOTBricSelectedDataModel, InchargeBricSelectedDataModel,
  FilterBarDataModel
} from '../../models';
import { LocaleData } from '../../core/utils/LocaleData';
import * as _ from 'lodash';

@Injectable()
export class FilterBarService {

  /**
   * Multiple selected display text
   * @private
   * @type {string}@memberof FilterBarService
   */
  private multipleText: string;

  /**
   * Application initial config
   * @type {*}@memberof FilterBarService
   */
  initialConfig: InitialConfigModel;

  /**
   * Filter bar data object
   * @private
   * @type {*}@memberof FilterBarService
   */
  public filterBarData: FilterBarDataModel = {};

  /**
   * Filter bar data observable subject
   * @private
   * @memberof FilterBarService
   */
  private filterBarSource: BehaviorSubject<FilterBarDataModel> = new BehaviorSubject(this.filterBarData);

  /**
   * Filter bar data observable
   * @memberof FilterBarService
   */
  public filterBar$: Observable<FilterBarDataModel> = this.filterBarSource.asObservable();

  /**
   * Open filter detail subject
   * @private
   * @memberof FilterBarService
   */
  private openFilterDetailSource = new Subject();

  /**
   * Open filter detail Observble
   * @memberof FilterBarService
   */
  public openFilterDetail$ = this.openFilterDetailSource.asObservable();

  constructor(
    private dataShareService: DataShareService,
    private brickBaseService: BrickBaseService,
    private datePipe: DatePipe) {
  }

  /**
   * Set initial data like initial config, multiple text
   * @memberof FilterBarService
   */
  setInitialData() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.multipleText = this.initialConfig.userBundle['geoplanner.text.multipleselected'] || 'Multiple Selected';
  }

  /**
   * Open filter detail of provided bric id
   * @param {any} bricID
   * @memberof FilterBarService
   */
  openFilterDetail(bricID): void {
    this.openFilterDetailSource.next(bricID);
  }

  resetFilterBarData(): void {
    this.filterBarData = {};
  }

  /**
   * filter bar data change method
   * @memberof FilterBarService
   */
  filterBarDataChange(): void {
    this.filterBarSource.next(this.filterBarData);
  }

  /**
   * Get Distance display text based on type
   * @param {number} distanceType
   * @returns {string}
   * @memberof FilterBarService
   */
  getDistanceTypeName(distanceType: number): string {
    let distanceTypeName = '';
    if (distanceType === 0) {
      distanceTypeName = this.initialConfig.userBundle['common.metres'];
    } else if (distanceType === 1) {
      distanceTypeName = this.initialConfig.userBundle['common.kilometres'];
    } else if (distanceType === 2) {
      distanceTypeName = this.initialConfig.userBundle['common.miles'];
    }
    return distanceTypeName;
  }

  /**
   * Prepare Filter bar data of proximity bric
   * @param {any} proximityBricSelectedData - selected proximity bric data
   * @memberof FilterBarService
   */
  prepareProximityBricFilterBar(proximityBricSelectedData: any): void {
    this.setInitialData();
    const tempFilterBarData: any = {
      bricID: this.brickBaseService.brickID.Proximity,
      brickBgColor: this.brickBaseService.brickColor.Proximity,
      text: 'Proximity',
      postcodeSelection: {},
      pointOfInterest: {}
    };
    _.each(proximityBricSelectedData, (val, key) => {
      if (key === 'postcodeSelection') {
        const distanceType = this.getDistanceTypeName(val.distance.distanceType);
        this.setFilterBarDataForPostCodeSelection(tempFilterBarData, val, distanceType);
      }
      if (key === 'pointOfInterest' && val.selectedModelValues.length > 0) {
        const distanceType = this.getDistanceTypeName(val.distance.distanceType);
        this.setFilterBarDataForPointOfInterest(tempFilterBarData, val, distanceType);
      }
    });
    const poiKeycnt: number = tempFilterBarData.pointOfInterest
      && tempFilterBarData.pointOfInterest.values ? tempFilterBarData.pointOfInterest.values.length : 0;
    let postCodeKeycnt = 0;
    if (tempFilterBarData.postcodeSelection
      && tempFilterBarData.postcodeSelection.values && tempFilterBarData.postcodeSelection.values.length > 0) {
      postCodeKeycnt = tempFilterBarData.postcodeSelection.values.length;
    }
    if (tempFilterBarData.postcodeSelection
      && tempFilterBarData.postcodeSelection.listUpload && tempFilterBarData.postcodeSelection.listUpload.length > 0) {
      postCodeKeycnt = tempFilterBarData.postcodeSelection.listUpload.length;
    }
    if ((poiKeycnt + postCodeKeycnt) === 0) {
      delete this.filterBarData[this.brickBaseService.brickID.Proximity];
    } else {
      this.setTextForFilterBarTextForProximity(tempFilterBarData, poiKeycnt, postCodeKeycnt);
      this.filterBarData[this.brickBaseService.brickID.Proximity] = tempFilterBarData;
    }
    this.filterBarDataChange();
  }

  setFilterBarDataForPostCodeSelection(filterBarData: any, val: any, distanceType: string) {
    filterBarData.postcodeSelection = {
      values: [],
      listUpload: [],
      distance: `${val.distance.distancevalue}   ${distanceType}`,
      include: val.include === 'true' ?
        this.initialConfig.userBundle['common.include'] : this.initialConfig.userBundle['common.exclude'],
      title: this.initialConfig.userBundle['workspace.collection.bric.proximity.postcode']
    };
    if (_.isUndefined(val.file) || val.file === null) {
      filterBarData.postcodeSelection.values = val.selectedModelValues;
    } else {
      filterBarData.postcodeSelection.listUpload = val.listUpload;
    }
  }

  setFilterBarDataForPointOfInterest(filterBarData: any, val: any, distanceType: string) {
    filterBarData.pointOfInterest = {
      values: val.selectedModelValues,
      distance: `${val.distance.distancevalue}  ${distanceType}`,
      include: val.include === 'true' ?
        this.initialConfig.userBundle['common.include'] : this.initialConfig.userBundle['common.exclude'],
      title: this.initialConfig.userBundle['workspace.collection.bric.proximity.poi'],
      attribute: val.selectedAttribute.poiAttributeName,
      dataSource: val.selectedDatasource.poiTypeName
    };
  }

  setTextForFilterBarTextForProximity(filterBarData: any, poiKeycnt: number, postCodeKeycnt: number) {
    if (poiKeycnt > 0 && postCodeKeycnt > 0) {
      filterBarData.text = this.multipleText;
    } else if (poiKeycnt > 0) {
      if (filterBarData.pointOfInterest.values.length > 1) {
        filterBarData.text = this.multipleText;
      } else {
        filterBarData.text = filterBarData.pointOfInterest.values[0].poiName;
      }
    } else if (postCodeKeycnt > 0) {
      if (filterBarData.postcodeSelection.listUpload && filterBarData.postcodeSelection.listUpload.length > 0) {
        filterBarData.text = `Postcodes count:  ${filterBarData.postcodeSelection.listUpload[0].validCount}`;
      } else {
        if (filterBarData.postcodeSelection.values.length > 1) {
          filterBarData.text = this.multipleText;
        } else {
          filterBarData.text = filterBarData.postcodeSelection.values[0].postcode;
        }
      }
    }
  }

  /**
   * Prepare Filter bar data of frame(Illumination) bric
   * @param frameValue - selected frame Illumination bric data
   */
  prepareFrameFilterBar(frameValue: any): void {
    this.setInitialData();
    const illuminationText = this.initialConfig.userBundle['workspace.collection.bric.frame.illumination'];
    if (Object.keys(frameValue).length > 0) {
      if (frameValue.illumination !== 3) {
        const illuminationVal = frameValue.illumination === 1 ? 'On' : 'Off';
        const tempFilterBarData = {
          bricID: this.brickBaseService.brickID.FrameIllumination,
          brickBgColor: this.brickBaseService.brickColor.FrameIllumination,
          text: `${illuminationText}:  ${illuminationVal}`,
          items: []
        };
        tempFilterBarData.items.push(`${illuminationText}: ${illuminationVal}`);
        this.filterBarData[this.brickBaseService.brickID.FrameIllumination] = tempFilterBarData;
      }
    } else {
      delete this.filterBarData[this.brickBaseService.brickID.FrameIllumination];
    }
    this.filterBarDataChange();
  }

  /**
   * Prepare Filter bar data of Tag bric
   * @param {any} tagBricSelectedData - selected tag bric data
   * @memberof FilterBarService
   */
  prepareTagBricFilterBar(tagBricSelectedData: TagBricSelectedDataModel): void {
    this.setInitialData();
    const tempFilterBarData: FilterBarModel = {
      bricID: this.brickBaseService.brickID.Tag,
      brickBgColor: this.brickBaseService.brickColor.Tag,
      text: 'Tag Brick',
      items: []
    };
    const selectedTags = [];
    const items: string[] = [];
    _.each(tagBricSelectedData, (val) => {
      if (val.length > 0) {
        _.each(val, (tag) => {
          selectedTags.push(tag);
          items.push(tag.tagName);
        });
      }
    });
    if (selectedTags.length > 0) {
      if (selectedTags.length > 1) {
        tempFilterBarData.text = this.multipleText;
      } else {
        tempFilterBarData.text = selectedTags[0].tagName;
      }
      tempFilterBarData.items = _.cloneDeep(items);
      tempFilterBarData.items.sort();
      this.filterBarData[this.brickBaseService.brickID.Tag] = tempFilterBarData;
    } else {
      delete this.filterBarData[this.brickBaseService.brickID.Tag];
    }
    this.filterBarDataChange();
  }

  /**
   * Prepare Filter bar data of media bric
   * @param {any} mediaBricSelectedData - selected media bric data
   * @memberof FilterBarService
   */
  prepareMediaBricFilterBar(mediaBricSelectedData: MediaBricSelectedDataModel): void {
    const tempFilterBarData: FilterBarModel = {
      bricID: this.brickBaseService.brickID.Media,
      brickBgColor: this.brickBaseService.brickColor.Media,
      text: 'Media Brick',
      items: []
    };
    if (mediaBricSelectedData.mediaTypeName) {
      tempFilterBarData.text = mediaBricSelectedData.mediaTypeName;
      tempFilterBarData.items.push(mediaBricSelectedData.mediaTypeName);
      this.filterBarData[this.brickBaseService.brickID.Media] = tempFilterBarData;
    } else {
      delete this.filterBarData[this.brickBaseService.brickID.Media];
    }
    this.filterBarDataChange();
  }

  /**
   * Prepare Filter bar data of channel bric
   * @param {any} channelBricSelectedData - selected channel bric data
   * @memberof FilterBarService
   */
  public prepareChannelBricFilterBar(channelBricSelectedData: SelectionItemModel[]): void {
    this.setInitialData();
    const tempFilterBarData: FilterBarModel = {
      bricID: this.brickBaseService.brickID.Environment,
      brickBgColor: '#ff6600',
      text: 'Channel Brick',
      items: []
    };
    const items: string[] = [];
    _.each(channelBricSelectedData, (val) => {
      items.push(val.name);
    });
    if (channelBricSelectedData.length > 0) {
      if (channelBricSelectedData.length > 1) {
        tempFilterBarData.text = this.multipleText;
      } else {
        tempFilterBarData.text = channelBricSelectedData[0].name;
      }
      tempFilterBarData.items = _.cloneDeep(items);
      tempFilterBarData.items.sort();
      this.filterBarData[this.brickBaseService.brickID.Environment] = tempFilterBarData;
    } else {
      delete this.filterBarData[this.brickBaseService.brickID.Environment];
    }
    this.filterBarDataChange();
  }

  /**
   * Prepare Filter bar data of format bric
   * @param {any} selectedData - selected format bric data
   * @memberof FilterBarService
   */
  prepareFormatBricFilterBar(selectedData: FormatBricSelectedDataModel): void {
    this.setInitialData();
    if (!_.isEmpty(selectedData)) {
      const tempFilterBarData: FilterBarModel = {
        bricID: this.brickBaseService.brickID.Format,
        brickBgColor: this.brickBaseService.brickColor.Format,
        text: 'Format Filter',
        items: []
      };
      const selectedFormat: any[] = [];
      const items: string[] = [];
      _.each(selectedData, (val) => {
        if (_.isArray(val) && val.length > 0) {
          _.each(val, (item) => {
            selectedFormat.push(item);
            items.push(item.name);
          });
        }
      });

      if (selectedFormat.length > 1) {
        tempFilterBarData.text = this.multipleText;
      } else {
        tempFilterBarData.text = selectedFormat[0].name;
      }
      tempFilterBarData.items = _.cloneDeep(items);
      tempFilterBarData.items.sort();
      this.filterBarData[this.brickBaseService.brickID.Format] = tempFilterBarData;
    } else {
      delete this.filterBarData[this.brickBaseService.brickID.Format];
    }
    this.filterBarDataChange();
  }

  /**
   * Prepare Filter bar data of channel bric
   * @param {any} selectedData - selected channel bric data
   * @memberof FilterBarService
   */
  prepareLocationBricFilterBar(selectedData: LocationBricSelectedDataModel): void {
    this.setInitialData();
    if (!_.isEmpty(selectedData)) {
      const tempFilterBarData: FilterBarModel = {
        bricID: this.brickBaseService.brickID.Location,
        brickBgColor: this.brickBaseService.brickColor.Location,
        text: 'Location Filter',
        items: []
      };
      const selectedFormat: any[] = [];
      const items: string[] = [];
      _.each(selectedData, (val) => {
        if (_.isArray(val) && val.length > 0) {
          _.each(val, (item) => {
            selectedFormat.push(item);
            const name = item.name || item.lookupName;
            items.push(name);
          });
        } else if (_.isObject(val)) {
          // mostly this will be fileList
          const keys = Object.keys(val);
          _.each(keys, (key) => {
            _.each(val[key], (item) => {
              selectedFormat.push(item);
              const name = item['userSelectionName'];
              items.push(name);
            });
          });
        }
      });

      if (selectedFormat.length > 1) {
        tempFilterBarData.text = this.multipleText;
      } else if (selectedFormat && selectedFormat[0]) {
        tempFilterBarData.text = selectedFormat[0].name || selectedFormat[0].lookupName || selectedFormat[0].userSelectionName;
      }
      tempFilterBarData.items = _.cloneDeep(items);
      tempFilterBarData.items.sort();
      this.filterBarData[this.brickBaseService.brickID.Location] = tempFilterBarData;
    } else {
      delete this.filterBarData[this.brickBaseService.brickID.Location];
    }
    this.filterBarDataChange();
  }

  /**
   * Prepare Filter bar data of Audience bric
   * @param {any} audienceBricSelectedData - selected Audience bric data
   * @memberof FilterBarService
   */
  public prepareAudienceBricFilterBar(audienceBricSelectedData: any): void {
    const tempFilterBarData: FilterBarModel = {
      bricID: this.brickBaseService.brickID.Audience,
      brickBgColor: this.brickBaseService.brickColor.Audience,
      text: 'Audience Filter',
      items: []
    };
    if (audienceBricSelectedData && audienceBricSelectedData.description) {
      tempFilterBarData.text = audienceBricSelectedData.description;
      tempFilterBarData.bricID = this.brickBaseService.brickID.Audience;
      tempFilterBarData.items.push(audienceBricSelectedData.description);
      this.filterBarData[this.brickBaseService.brickID.Audience] = tempFilterBarData;
    } else {
      delete this.filterBarData[this.brickBaseService.brickID.Audience];
    }
    this.filterBarDataChange();
  }

  /**
   * Prepare Filter bar data of SOT bric
   * @param {any} sotBricSelectedData - selected SOT bric data
   * @memberof FilterBarService
   */
  public prepareSotBricFilterBar(sotBricSelectedData: SOTBricSelectedDataModel): void {
    this.setInitialData();
    const tempFilterBarData: FilterBarModel = {
      bricID: this.brickBaseService.brickID.SOT,
      brickBgColor: this.brickBaseService.brickColor.SOT,
      text: 'SOT Filter',
      items: []
    };
    if (sotBricSelectedData && sotBricSelectedData.sot) {
      tempFilterBarData.text = `${sotBricSelectedData.sot}%`;
      tempFilterBarData.bricID = this.brickBaseService.brickID.SOT;
      tempFilterBarData.items.push(`${this.initialConfig.userBundle['workspace.share.bric.time.sot']}   ${tempFilterBarData.text}`);
      this.filterBarData[this.brickBaseService.brickID.SOT] = tempFilterBarData;
    } else {
      delete this.filterBarData[this.brickBaseService.brickID.SOT];
    }
    this.filterBarDataChange();
  }

  /**
   * Prepare Filter bar data of Incharge bric
   * @param {any} inchargeBricSelectedData - selected Incharge bric data
   * @memberof FilterBarService
   */
  public prepareInchargeBricFilterBar(inchargeBricSelectedData: InchargeBricSelectedDataModel): void {
    const tempFilterBarData: FilterBarModel = {
      bricID: this.brickBaseService.brickID.Incharge,
      brickBgColor: this.brickBaseService.brickColor.Incharge,
      text: 'Incharge Range Filter',
      items: []
    };
    if (inchargeBricSelectedData.startDate && inchargeBricSelectedData.endDate) {
      tempFilterBarData.text = `${this.datePipe.transform(inchargeBricSelectedData.startDate,
        LocaleData.displayDateFormat)} to ${this.datePipe.transform(inchargeBricSelectedData.endDate,
          LocaleData.displayDateFormat)}`;
      tempFilterBarData.items.push(tempFilterBarData.text);
      this.filterBarData[this.brickBaseService.brickID.Incharge] = tempFilterBarData;
    } else {
      delete this.filterBarData[this.brickBaseService.brickID.Incharge];
    }
    this.filterBarDataChange();
  }

  /**
   * Prepare Filter bar data of list bric
   * @param {any} listUploadBricSelectedData - selected channel list data
   * @memberof FilterBarService
   * @author Amit Mahida
   */
  public prepareListFilterBar(listUploadBricSelectedData: any): void {
    if (Object.keys(listUploadBricSelectedData).length === 0) {
      delete this.filterBarData[this.brickBaseService.brickID.List];
      this.filterBarDataChange();
      return;
    }
    if ((!_.isUndefined(listUploadBricSelectedData.routeFrameIds) ||
      !_.isUndefined(listUploadBricSelectedData.listUpload))
    ) {
      if (!_.isUndefined(listUploadBricSelectedData.routeFrameIds)
        && listUploadBricSelectedData.routeFrameIds.include.length === 0
        && listUploadBricSelectedData.routeFrameIds.exclude.length === 0) {
        delete this.filterBarData[this.brickBaseService.brickID.List];
        this.filterBarDataChange();
        if (_.isUndefined(listUploadBricSelectedData.listUpload)) {
          return;
        }
      }
      if (!_.isUndefined(listUploadBricSelectedData.listUpload) &&
        listUploadBricSelectedData.listUpload.fileList.length === 0) {
        delete this.filterBarData[this.brickBaseService.brickID.List];
        this.filterBarDataChange();
        if (_.isUndefined(listUploadBricSelectedData.routeFrameIds)) {
          return;
        }
      }
    }
    this.setInitialData();
    this.filterBarData[this.brickBaseService.brickID.List] = this.getFilterBarDataForListBrick(listUploadBricSelectedData);
    this.filterBarDataChange();
  }

  getFilterBarDataForListBrick(listUploadBricSelectedData: any): any {
    const tempFilterBarData = {
      bricID: this.brickBaseService.brickID.List,
      brickBgColor: this.brickBaseService.brickColor.List,
      text: 'List Upload',
      totalCount: 0,
      included: 0,
      excluded: 0
    };
    if (!_.isUndefined(listUploadBricSelectedData.routeFrameIds) && !_.isEmpty(listUploadBricSelectedData.routeFrameIds)) {
      const tempRouteFrameIds = listUploadBricSelectedData.routeFrameIds.requestJSON;
      if (!_.isEmpty(tempRouteFrameIds.exclude)) {
        tempFilterBarData.excluded += tempRouteFrameIds.exclude.validCount;
        tempFilterBarData.totalCount += tempRouteFrameIds.exclude.totalCount;
      }
      if (!_.isEmpty(tempRouteFrameIds.include)) {
        tempFilterBarData.included += tempRouteFrameIds.include.validCount;
        tempFilterBarData.totalCount += tempRouteFrameIds.include.totalCount;
      }
    }
    if (!_.isUndefined(listUploadBricSelectedData.listUpload)
      && !_.isEmpty(listUploadBricSelectedData.listUpload)) {
      const listUpload = listUploadBricSelectedData.listUpload;
      listUpload.fileList.forEach((element) => {
        tempFilterBarData.totalCount += element.totalCount;
        if (element.exclude) {
          tempFilterBarData.excluded += element.validCount;
        } else {
          tempFilterBarData.included += element.validCount;
        }
      });
    }
    tempFilterBarData.text = tempFilterBarData.included > 0 ?
      `${this.initialConfig.userBundle['workspace.collection.bric.list.include']}:
            ${tempFilterBarData.included} ${this.initialConfig.userBundle['workspace.collection.bric.frame.heading']}` :
      `${this.initialConfig.userBundle['workspace.collection.bric.list.exclude']}:
                ${tempFilterBarData.excluded} ${this.initialConfig.userBundle['workspace.collection.bric.frame.heading']}`;

    return tempFilterBarData;
  }

  /**
   * Prepare Filter bar data of Facing bric
   * @param {any} facingBricSelectedData - selected Facing bric data
   * @memberof FilterBarService
   */
  public prepareFacingBricFilterBar(facingBricSelectedData: any): void {
    this.setInitialData();
    const tempFilterBarData: FilterBarModel = {
      bricID: this.brickBaseService.brickID.Facing,
      brickBgColor: this.brickBaseService.brickColor.Facing,
      text: 'Facing Brick',
      items: []
    };
    const items: string[] = [];
    _.each(facingBricSelectedData, (val) => {
      items.push(val.name);
    });
    if (facingBricSelectedData.length > 0) {
      if (facingBricSelectedData.length > 1) {
        tempFilterBarData.text = this.multipleText;
      } else {
        tempFilterBarData.text = facingBricSelectedData[0].name;
      }
      tempFilterBarData.items = _.cloneDeep(items);
      tempFilterBarData.items.sort();
      this.filterBarData[this.brickBaseService.brickID.Facing] = tempFilterBarData;
    } else {
      delete this.filterBarData[this.brickBaseService.brickID.Facing];
    }
    this.filterBarDataChange();
  }

}
