import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { SbModalPopupModule } from './../../app/core/components/sb-modal-popup';
import { TagInputModule } from 'ngx-chips';
import { CoreModule } from '../core/core.module';
import { IMaskModule } from 'angular-imask';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  CommercialButtonService,
  DiscountGridGermanyComponent,
  DiscountGridAtComponent,
  CommercialGermanyComponent,
  DiscountGridBelgiumComponent,
  DiscountGridUSComponent,
  DiscountGridAustraliaComponent,
  DiscountGridSingaporeComponent,
  DiscountGridShanghaiComponent,
  DiscountGridCngaComponent,
  DiscountGridHkComponent,
  DiscountGridService,
  BookingDetailsGermanyComponent,
  DiscountGridNorwayComponent,
  DiscountGridItalyComponent,
  DiscountGridDubaiComponent,
  AgCollapseExpandComponent,
  AgCheckboxComponent,
  AgCustomFilterMenuComponent,
  AgTextFloatingFilterComponent,
  AgSelectFloatingFilterComponent,
  AgCustomHeaderComponent,
  AgTextCellEditorComponent,
  AgSelectCellEditorComponent,
  AgDateCellEditorComponent,
  CopyLineModalComponent,
  AgTypeAheadCellEditorComponent,
  AgTypeAheadObservableCellEditorComponent,
  CellRendererBaseComponent,
  AgNumberCellRendererComponent,
  AgCurrencyCellRendererComponent,
  DataFormatterService,
  AgSelectCellRendererComponent,
  AgTextCellRendererComponent,
  AgCheckboxCellRendererComponent,
  AgTrafficLightCellRendererComponent,
  AgToggleCellRendererComponent,
  AgPercentageCellRendererComponent,
  AgDateCellRendererComponent,
  CampaignDetailsUkComponent,
  CampaignDetailsNlComponent,
  CampaignDetailsShanghaiComponent,
  CampaignDetailsCngaComponent,
  CampaignDetailsBelgiumComponent,
  CampaignDetailsBalComponent,
  CampaignDetailsDefaultComponent,
  CampaignDetailsAtComponent,
  CampaignDetailsNorwayComponent,
  CampaignDetailsFiComponent,
  CommercialService,
  CampaignDetailsService,
  CommercialButtonsComponent,
  CommercialUkComponent,
  AgNumberMaskCellEditorComponent,
  CommercialDefaultComponent,
  CommercialAtComponent,
  CommercialSgComponent,
  CommercialCngaComponent,
  CampaignDetailsSgComponent,
  CommercialHkComponent,
  CampaignDetailsHKComponent,
  CommercialItalyComponent,
  CampaignDetailsGermanyComponent,
  CommercialUsComponent,
  CampaignDetailsUsComponent,
  CommercialAuComponent,
  CommercialNorwayComponent,
  CampaignDetailsAuComponent,
  CommercialDenmarkComponent,
  CommercialFiComponent,
  BookingDetailsDenmarkComponent,
  CampaignDetailsDenmarkComponent,
  CommercialBeComponent,
  CommercialBalComponent,
  CommercialShanghaiComponent,
  CommercialNlComponent,
  CommercialBrComponent,
  CampaignDetailsBrComponent,
  CommercialEsComponent,
  AgLinkCellRendererComponent,
  AgReshuffleLinkCellRendererComponent,
  CommercialCnshaComponent,
  CampaignDetailsCnshaComponent,
  DiscountGridCnshaComponent,
  AgLsCellRendererComponent,
  AgResuffleTrafficLightCellRendererComponent
} from './index';
import { FreeOfChargeComponent } from './freeofcharge/freeofcharge.component';
import { ReasonForChangeComponent } from './reasonforchange/reasonforchange.component';
import { SaveCampaignComponent } from './savecampaign/savecampaign.component';
import { CommercialValdiationComponent } from './commercial-valdiation/commercial-valdiation.component';
import { CommercialAEComponent } from './commercial-ae/commercial-ae.component';
import { CampaignDetailsAEComponent } from './campaign-details/campaign-detail-ae/campaign-details-ae.component';
import { BookingGridUkComponent } from './discount-grid/booking-grid-uk/booking-grid-uk.component';
import { BookingDetailsDefaultComponent } from './booking-detail/booking-detail-default/booking-detail-default.component';
import { BookingDetailNorwayComponent } from './booking-detail/booking-detail-norway/booking-detail-norway.component';
import { BookingDetailsAEComponent } from './booking-detail/booking-detail-default.ae/booking-detail-ae.component';
import { BookingDetailsItalyComponent } from './booking-detail/booking-detail-italy/booking-detail-italy.component';
import { CampaignDetailsITComponent } from './campaign-details/campaign-details-italy/campaign-details-italy.component';

import { DiscountGridBrazilComponent } from './discount-grid/discount-grid-brazil/discount-grid-brazil.component';
import { CompetitiveCampaignsComponent } from './competitive-campaigns/competitive-campaigns.component';
import { AgLockComponent } from './discount-grid/ag-grid-support/ag-lock/ag-lock.component';
import { CampaignDetailsEsComponent } from './campaign-details/campaign-details-es/campaign-details-es.component';
import { DiscountGridSpainComponent } from './discount-grid/discount-grid-spain/discount-grid-spain.component';
import { DiscountGridPortugalComponent } from './discount-grid/discount-grid-portugal/discount-grid-portugal.component';
import { CommercialPtComponent } from './commercial-pt/commercial-pt.component';
import { DiscountGridNlComponent } from './discount-grid/discount-grid-nl/discount-grid-nl.component';
import { DiscountGridBalComponent } from './discount-grid/discount-grid-bal/discount-grid-bal.component';
@NgModule({
  imports: [
    IMaskModule,
    BrowserModule,
    NgSelectModule,
    FormsModule,
    NgbModule,
    TagInputModule,
    AgGridModule.withComponents([
      AgCollapseExpandComponent,
      AgCheckboxComponent,
      AgCustomFilterMenuComponent,
      AgTextFloatingFilterComponent,
      AgSelectFloatingFilterComponent,
      AgCustomHeaderComponent,
      AgTextCellEditorComponent,
      AgSelectCellEditorComponent,
      AgDateCellEditorComponent,
      AgTypeAheadCellEditorComponent,
      AgTypeAheadObservableCellEditorComponent,
      AgNumberCellRendererComponent,
      AgCurrencyCellRendererComponent,
      AgSelectCellRendererComponent,
      AgTextCellRendererComponent,
      AgCheckboxCellRendererComponent,
      AgTrafficLightCellRendererComponent,
      AgResuffleTrafficLightCellRendererComponent,
      AgToggleCellRendererComponent,
      AgPercentageCellRendererComponent,
      AgDateCellRendererComponent,
      AgNumberMaskCellEditorComponent,
      AgLinkCellRendererComponent,
      AgReshuffleLinkCellRendererComponent,
      AgLockComponent,
      AgLsCellRendererComponent
    ]),
    SbModalPopupModule.forRoot([
      CopyLineModalComponent,
      FreeOfChargeComponent,
      SaveCampaignComponent,
      ReasonForChangeComponent,
      CommercialValdiationComponent
    ]),
    CoreModule.forRoot(),
  ],
  declarations: [
    CommercialEsComponent,
    CommercialUkComponent,
    CommercialAEComponent,
    CommercialBeComponent,
    CommercialBalComponent,
    CampaignDetailsUkComponent,
    CampaignDetailsNlComponent,
    CampaignDetailsShanghaiComponent,
    CampaignDetailsCngaComponent,
    CampaignDetailsBelgiumComponent,
    CampaignDetailsBalComponent,
    CampaignDetailsDefaultComponent,
    CampaignDetailsNorwayComponent,
    CampaignDetailsAtComponent,
    CampaignDetailsEsComponent,
    CampaignDetailsFiComponent,
    CampaignDetailsAEComponent,
    CommercialButtonsComponent,
    CommercialShanghaiComponent,
    AgCollapseExpandComponent,
    AgCheckboxComponent,
    DiscountGridGermanyComponent,
    DiscountGridAtComponent,
    DiscountGridBelgiumComponent,
    DiscountGridDubaiComponent,
    BookingGridUkComponent,
    DiscountGridUSComponent,
    DiscountGridSingaporeComponent,
    DiscountGridShanghaiComponent,
    DiscountGridCngaComponent,
    DiscountGridAustraliaComponent,
    DiscountGridNorwayComponent,
    DiscountGridItalyComponent,
    DiscountGridHkComponent,
    DiscountGridDubaiComponent,
    DiscountGridBrazilComponent,
    AgCustomFilterMenuComponent,
    AgTextFloatingFilterComponent,
    AgSelectFloatingFilterComponent,
    AgCustomHeaderComponent,
    AgTextCellEditorComponent,
    AgSelectCellEditorComponent,
    AgDateCellEditorComponent,
    CopyLineModalComponent,
    AgTypeAheadCellEditorComponent,
    AgTypeAheadObservableCellEditorComponent,
    CellRendererBaseComponent,
    AgNumberCellRendererComponent,
    AgCurrencyCellRendererComponent,
    AgSelectCellRendererComponent,
    AgTextCellRendererComponent,
    AgCheckboxCellRendererComponent,
    AgTrafficLightCellRendererComponent,
    AgResuffleTrafficLightCellRendererComponent,
    AgToggleCellRendererComponent,
    AgPercentageCellRendererComponent,
    AgDateCellRendererComponent,
    FreeOfChargeComponent,
    SaveCampaignComponent,
    ReasonForChangeComponent,
    CommercialValdiationComponent,
    AgNumberMaskCellEditorComponent,
    CommercialDefaultComponent,
    CommercialAtComponent,
    CommercialSgComponent,
    CommercialCngaComponent,
    CampaignDetailsSgComponent,
    BookingDetailsDefaultComponent,
    BookingDetailNorwayComponent,
    CommercialHkComponent,
    CampaignDetailsHKComponent,
    CommercialItalyComponent,
    CampaignDetailsITComponent,
    BookingDetailsItalyComponent,
    CommercialGermanyComponent,
    BookingDetailsGermanyComponent,
    CampaignDetailsGermanyComponent,
    CommercialUsComponent,
    CampaignDetailsUsComponent,
    BookingDetailsAEComponent,
    CommercialAuComponent,
    CommercialNorwayComponent,
    CampaignDetailsAuComponent,
    CommercialDenmarkComponent,
    CommercialFiComponent,
    BookingDetailsDenmarkComponent,
    CampaignDetailsDenmarkComponent,
    CommercialNlComponent,
    CompetitiveCampaignsComponent,
    CommercialBrComponent,
    CampaignDetailsBrComponent,
    AgLinkCellRendererComponent,
    AgReshuffleLinkCellRendererComponent,
    AgLockComponent,
    CommercialCnshaComponent,
    CampaignDetailsCnshaComponent,
    DiscountGridCnshaComponent,
    AgLsCellRendererComponent,
    DiscountGridSpainComponent,
    CommercialPtComponent,
    DiscountGridPortugalComponent,
    DiscountGridNlComponent,
    DiscountGridBalComponent
  ],
  providers: [
    CommercialButtonService,
    CommercialService,
    CampaignDetailsService,
    DecimalPipe,
    CurrencyPipe,
    DiscountGridService,
    DataFormatterService,
  ],
  // entryComponents: [
  //   CommercialEsComponent,
  //   DiscountGridGermanyComponent,
  //   BookingGridUkComponent,
  //   CommercialButtonsComponent,
  //   CampaignDetailsUkComponent,
  //   CampaignDetailsNlComponent,
  //   CampaignDetailsShanghaiComponent,
  //   CampaignDetailsCngaComponent,
  //   CampaignDetailsBelgiumComponent,
  //   CampaignDetailsDefaultComponent,
  //   CampaignDetailsEsComponent,
  //   CampaignDetailsFiComponent,
  //   CampaignDetailsAEComponent,
  //   CommercialUkComponent,
  //   CommercialAEComponent,
  //   CommercialBeComponent,
  //   CommercialDefaultComponent,
  //   CommercialSgComponent,
  //   CommercialCngaComponent,
  //   CommercialShanghaiComponent,
  //   CampaignDetailsSgComponent,
  //   DiscountGridBelgiumComponent,
  //   DiscountGridUSComponent,
  //   DiscountGridSingaporeComponent,
  //   DiscountGridShanghaiComponent,
  //   DiscountGridCngaComponent,
  //   DiscountGridAustraliaComponent,
  //   DiscountGridItalyComponent,
  //   DiscountGridHkComponent,
  //   BookingDetailsDefaultComponent,
  //   DiscountGridDubaiComponent,
  //   CompetitiveCampaignsComponent,
  //   CommercialHkComponent,
  //   CampaignDetailsHKComponent,
  //   CommercialItalyComponent,
  //   CampaignDetailsITComponent,
  //   BookingDetailsItalyComponent,
  //   CommercialGermanyComponent,
  //   BookingDetailsGermanyComponent,
  //   CampaignDetailsGermanyComponent,
  //   DiscountGridDubaiComponent,
  //   CommercialUsComponent,
  //   CampaignDetailsUsComponent,
  //   BookingDetailsAEComponent,
  //   BookingDetailsDefaultComponent,
  //   CommercialAuComponent,
  //   CommercialNorwayComponent,
  //   CampaignDetailsAuComponent,
  //   CommercialDenmarkComponent,
  //   CommercialFiComponent,
  //   BookingDetailsDenmarkComponent,
  //   CampaignDetailsDenmarkComponent,
  //   CommercialNlComponent,
  //   CommercialBrComponent,
  //   CampaignDetailsBrComponent,
  //   CommercialCnshaComponent,
  //   CampaignDetailsCnshaComponent,
  //   DiscountGridCnshaComponent
  // ],
  exports: [
    CommercialEsComponent,
    CommercialUkComponent,
    CommercialAEComponent,
    CommercialBeComponent,
    CommercialBalComponent,
    CommercialDefaultComponent,
    CommercialAtComponent,
    CommercialSgComponent,
    CommercialCngaComponent,
    CommercialHkComponent,
    CommercialItalyComponent,
    CommercialGermanyComponent,
    CommercialUsComponent,
    CommercialAuComponent,
    CommercialNorwayComponent,
    CommercialDenmarkComponent,
    CommercialFiComponent,
    CommercialShanghaiComponent,
    CommercialNlComponent,
    CommercialBrComponent,
    CommercialCnshaComponent,
    DiscountGridBrazilComponent,
    DiscountGridNorwayComponent,
    CompetitiveCampaignsComponent,
    CampaignDetailsCnshaComponent,
    DiscountGridCnshaComponent,
    DiscountGridSpainComponent,
    CommercialPtComponent,
    DiscountGridPortugalComponent,
    DiscountGridNlComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class CommercialModule { }
