import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ExcelCell, ExportToExcel, ExcelColumn } from '../../../core/utils/exportToExcel';
import { GridOptions, ColDef, GridApi } from 'ag-grid-community';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DataShareService } from '../../../core/services/data-share.service';
import { LocaleData } from '../../../core/utils/LocaleData';
import { AgDateCellRendererComponent, AgLinkCellRendererComponent, AgCheckboxComponent, AgCustomRowUtil, AgTextFloatingFilterComponent, AgCustomFilterMenuComponent, AgCustomHeaderComponent, AgCustomSort } from '../../../commercial/discount-grid/ag-grid-support/index';
import { DatePipe, DecimalPipe } from '@angular/common';
import { AgToggleCellRendererComponent } from '../../../commercial/discount-grid/ag-grid-support/cell-renderer/ag-toggle-cell-renderer/ag-toggle-cell-renderer.component';
import * as _ from 'lodash';
import { AgTrafficLightCellRendererComponent } from '../../../commercial/discount-grid/ag-grid-support/cell-renderer/ag-traffic-light-cell-renderer/ag-traffic-light-cell-renderer.component';
import { UiControl } from '../../../models';
import * as XLSX from 'xlsx';
import { LogHelperService } from '../../../core/services';
import { ReshuffleService } from '../../reshuffle.service';

@Component({
  selector: 'reshuffle-detail-ls',
  templateUrl: './reshuffle-detail-ls.component.html',
  styleUrls: ['./../../../commercial/discount-grid/ag-grid-custom-style.css', './../../../commercial/discount-grid/ag-grid-col-select-style.css', './reshuffle-detail-ls.component.css'],
  host: {
    '(document:click)': 'onDocumentClick($event)'
  }
})
export class ReshuffleDetailLSComponent {

  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  gridApi: GridApi;

  @Input() resolveObject;

  @Output() selectionChange = new EventEmitter<any>();

  @Output() lockChange = new EventEmitter<any>();

  @Output() fillerChange = new EventEmitter<any>();

  rowData: any = [];
  rowDataCopy: any = [];
  isFileUploaded: boolean = false;
  public titleColor = '#03a9f5';
  groupingField: string = null;
  uiControl: UiControl;
  unlockDonors: any = [];
  /**
   * Ag-Grid Options Object
   */
  campaignGridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  campaignColumnDefs: ColDef[];

  footerRowData: any[];

  /**
   * user bundle data
   */
  userBundle: any = {};
  sortObj: AgCustomSort;

  hideBookings = true;
  selectedFileName: string;

  exclusionValue = "";
  excludeOptions;
  lookups = {
    lookup: []
  };
  userTokens;
  isReshuffleScopeAvailable: boolean = false;
  dummySelectOption;

  public selected = {};

  trackBySelectionId(index, lookup) {
    return lookup?.selectionId;
  }

  constructor(
    private dataShareService: DataShareService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private reshuffleService: ReshuffleService,
    private toaster: LogHelperService
  ) {
  }

  mapLookUpDataToReshuffleLookup() {
    const lookupDataList = this.resolveObject.reshuffleScopeLookUpData;
    this.lookups.lookup.map(element => {
      const lookupDataIndex = _.findIndex(lookupDataList, { selectionId: element.selectionId });
      lookupDataIndex >= 0 ? element.lookupData = _.cloneDeep(lookupDataList[lookupDataIndex].data) : element.lookupData = [];
    });
  }

  ngOnInit(): void {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.userTokens = this.dataShareService.getInitialConfigByKey('userData');
    if (this.userTokens.tokens.indexOf('RESHUFFLE_SCOPE_LIMIT') > -1 || this.userTokens.tokens.indexOf('RESHUFFLE_SCOPE_IN_THE_OBJECTIVE_WORKSPACE') > -1) {
      this.isReshuffleScopeAvailable = true;
    }
    if ((this.uiControl.reshuffleScopingEnabled || this.uiControl.reshuffleScopingForObjectiveWorkspaceEnabled) && this.isReshuffleScopeAvailable) {
      this.rowData = this.resolveObject.reshuffleCandidates;
      this.lookups.lookup = this.resolveObject.reshuffleScopeLookUp;
      this.excludeOptions = this.resolveObject.reshuffleScopeLimitCriteria;
      this.dummySelectOption = this.resolveObject.dummySelectOption;
      this.mapLookUpDataToReshuffleLookup();
    } else {
      this.rowData = this.resolveObject.data;
    }
    this.rowData.forEach((g) => {
      g.isFilterPass = true;
      g.lockedAtUI = g.locked;
      g.filler = false;
      g.campaignRef = `${g.campaignRef}-${(g.columnIndex + 1)}`;
      if (this.resolveObject.selectedProducts && this.uiControl.enableFormatBrickFilter) {
        let isVisible = false;
        for (const selectedProduct of this.resolveObject.selectedProducts.split(', ')) {
          if (g.productFormats && g.productFormats.includes(selectedProduct)) {
            isVisible = true;
            g.lockedAtUI = g.locked;
            break;
          } else {
            g.lockedAtUI = true;
          }
        }
        g.isVisible = isVisible;
      } else {
        g.isVisible = true;
      }
    });

    this.setFooterRows();
    this.prepareColDef();
    // we pass an empty gridOptions in, so we can grab the api out
    this.campaignGridOptions = ({
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      onRowDataChanged: (event) => {
        event.api.setPinnedBottomRowData(this.footerRowData);

        this.rowData.forEach((g) => {
          g.lockedAtUI = g.lockedAtUI === '' ? g.locked : g.lockedAtUI;
          g.filler = g.filler === '' ? false : g.filler;
          g.campaignRef = g.campaignRef.indexOf('-') > -1 ? g.campaignRef : `${g.campaignRef}-${(g.columnIndex + 1)}`;
        });
      },
      scrollbarWidth: 10,
      getRowHeight: this.getRowHeight,
      getRowStyle: this.getRowStyle,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      onFilterChanged: this.onFilterChanged,
      suppressScrollOnNewData: true,
      groupingField: this.groupingField,
      suppressMovableColumns: false,
      onGridReady: () => {
        this.campaignGridOptions.columnApi.setColumnVisible('floatingStartDate', this.uiControl.showFloatingDateReshufflingDetails);
        this.campaignGridOptions.columnApi.setColumnVisible('floatingEndDate', this.uiControl.showFloatingDateReshufflingDetails);
        this.campaignGridOptions.columnApi.setColumnVisible('productCatalogue', this.rowData.filter(r => r.productCatalogue && r.productCatalogue !== '').length > 0);
      },
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: node => node.data.isVisible
    } as GridOptions);
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  callUnlockDonors = () => {
    this.reshuffleService.executeUnlockDonors(this.resolveObject.columnIndex).subscribe((res) => {
      if (res.data) {
        let totalCampRef = 0;
        let campUnlocked = 0; 
        this.rowData.forEach(element => {
          if(res.data?.includes(element.campaignId)) {
            totalCampRef = totalCampRef + 1;
          }
          if (element.lockedEditable && res.data?.length && res.data?.includes(element.campaignId) || element.campaignId == 0) {
            if (element.campaignId !== 0) {
              campUnlocked = campUnlocked + 1;
            }
            element.lockedAtUI = false;
          } 
        });
        this.toaster.logSuccess(`${campUnlocked} of ${totalCampRef} ${this.userBundle['reshuffle.text.target.unlocked'] || 'target booking unlocked.'} `);
      }
      this.unlockDonors = res.data;
      if(this.gridApi) {
        this.gridApi.refreshCells({ force: true })
        const api = this.campaignGridOptions.api;
        const allRowNodes: any[] = [];
        const column = this.campaignGridOptions.columnApi.getColumn('lockedAtUI');
        let rendererParams = column?.getColDef().cellRendererParams;
        rendererParams['_unlockDonorIds'] = res.data;
        api?.forEachNode(node => allRowNodes.push(node));
        // Redraw all rows
        api.redrawRows({ rowNodes: allRowNodes });
        rendererParams['_unlockDonorIds'] = [];
      }
    })
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else {
      height = 25;
    }
    return height;
  }

  getRowStyle = (param) => {
    if (this.resolveObject.selectedProducts && this.uiControl.enableFormatBrickFilter) {
      let isProductFormateMatched = false;
      for (const selectedProduct of this.resolveObject.selectedProducts.split(', ')) {
        if (param.node.data.productFormats && param.node.data.productFormats.includes(selectedProduct)) {
          isProductFormateMatched = true;
          break;
        }
      }
      if (param.node.rowPinned !== 'bottom' && !isProductFormateMatched) {
        return { 'background-color': '#c6c6c6' };
      } else {
        return null;
      }
    } else {
      if (param.node.rowPinned !== 'bottom') {
        return null;
      }
    }
  }

  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen()) {
      if ($event.button !== 2 && !dropdownEle._isEventFromToggle($event)
        && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
        dropdownEle.close();
      }
    }
  }

  // Data functions
  getChildRows(): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData);
  }

  getSelectedChildRows(): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData);
  }

  onHideBookings() {
    this.rowData.forEach((g) => {
      if (this.hideBookings) {
        g.isVisible = false;
        if (this.resolveObject.selectedProducts && this.uiControl.enableFormatBrickFilter) {
          for (const selectedProduct of this.resolveObject.selectedProducts.split(', ')) {
            if (g.productFormats && g.productFormats.includes(selectedProduct)) {
              g.isVisible = true;
              break;
            }
          }
        } else {
          g.isVisible = true;
        }
      } else {
        g.isVisible = true;
      }
    });
    this.campaignGridOptions.api.onFilterChanged();
  }

  getGridSummary() {
    return `<span>${this.userBundle['discount.common.totalFrames']}: ${this.getChildRows().length} &#45;
      ${this.userBundle['discount.common.selectedFrames']}: ${this.getSelectedChildRows().length}</span>`;
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }

  isFullWidthCell = (rowNode): boolean => {
    return (rowNode.data.gridSummary ? true : false);
  }

  blankCellRenderer() {
    return '';
  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinRowIndex: 0 },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.map((r) => {
        r.isFilterPass = true;
      });
    }
    // Refresh Grid Header checkbox component
    this.campaignGridOptions.api['headerRootComp'].forEachHeaderElement((element) => {
      if (element.column.colId === 'selectChk' && element.columnApi && element.childComponents && element.childComponents.length > 1) {
        const headerCmpIns = element.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  prepareColDef() {
    this.campaignColumnDefs = [
      {
        headerName: '', width: 30,
        pinned: 'left', filter: false, colId: 'selectChk',
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: (params): string[] => {
          const retArr: string[] = [];
          if (params.data.isSelected) {
            retArr.push('selectedRow');
          }
          if (params.node.rowPinned === 'bottom') {
            retArr.push('borderBottom');
          }
          return retArr;
        }
      },
      {
        headerName: this.userBundle['common.campaignRef_columnno'] || 'Campaign Ref-Column',
        field: 'campaignRef',
        width: 135,
        cellRendererFramework: AgLinkCellRendererComponent,
        cellRendererParams: {
          queryString: { id: 'campaignId' }
        },
        pinned: 'left',
        pinnedRowCellRenderer: this.weekPinnedRowCellRenderer
      },
      // {
      //   headerName: this.userBundle['reshuffle.text.columnNumber'] || 'Column Number',
      //   field: 'columnIndex',
      //   width: 80,
      //   pinned: 'left',
      // },
      {
        headerName: this.userBundle['reshuffle.text.objectiveStatus'] || 'Objective Status',
        field: 'objectivesStatus',
        width: 50,
        filter: false,
        pinned: 'left',
        cellRendererFramework: AgTrafficLightCellRendererComponent
      },
      {
        headerName: 'Locked',
        field: 'lockedAtUI',
        filter: false,
        cellRendererFramework: AgToggleCellRendererComponent,
        cellStyle: (params) => {
          const value = params.data;
          if(!value.lockedEditable && this.unlockDonors?.includes(value.campaignId)) {
            return { backgroundColor: '#f45344', color: 'white' };
          }
        },
        cellRendererParams: {
          _unlockDonorIds: this.unlockDonors,
          onSelectChange: (data) => {
            this.lockChange.emit(data.data);
          },
          class_on: () => 'lock_on',
          class_off: () => 'lock_off',
          cellEditable: (data) => {
            if ((data.locked && data.lockedEditable) || (!data.locked && data.lockedEditable) || (!data.locked && !data.lockedEditable)) {
              return true;
            }
            if (data.locked && !data.lockedEditable) {
              return false;
            }
            // return !data.locked;
          }
        },
        pinned: 'left',
        width: 54,
      },
      {
        headerName: 'Filler',
        field: 'filler',
        filter: false,
        cellRendererFramework: AgToggleCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          onSelectChange: (data) => {
            this.fillerChange.emit(data.data);
          },
          class_on: () => 'filler_on',
          class_off: () => 'filler_off'
        },
        pinned: 'left',
        width: 50,
      },
      {
        headerName: this.userBundle['reshuffle.text.lockedReason'] || 'Locked Reason',
        field: 'lockedReason'
      },
      {
        headerName: this.userBundle['common.channelName'] || 'Channel',
        field: 'channel',
        width: 120,
      },
      {
        headerName: this.userBundle['common.productName'] || 'Product',
        field: 'productCatalogue',
        tooltipField: 'productCatalogue',
        width: 120
      },
      {
        headerName: this.userBundle['common.productFormatName'] || 'Product Format',
        field: 'productFormats',
        tooltipField: 'productFormats',
        width: 120
      },
      {
        headerName: this.userBundle['common.title'] || 'Title',
        field: 'title',
        width: 150
      },
      {
        headerName: this.userBundle['reshuffle.text.campaignGroup'] || 'Campaign Group',
        field: 'campaignGroup',
        width: 120
      },
      {
        headerName: this.userBundle['reshuffle.text.campaignStatus'] || 'Campaign Status',
        field: 'campaignStatus',
        width: 130
      },
      {
        headerName: this.userBundle['reshuffle.text.startDate'] || 'Start Date',
        field: 'startDate',
        width: 80,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          dateFormat: LocaleData.displayDateFormat
        }
      },
      {
        headerName: this.userBundle['reshuffle.text.endDate'] || 'End Date',
        field: 'endDate',
        width: 80,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          dateFormat: LocaleData.displayDateFormat
        }
      },
      {
        headerName: this.userBundle['reshuffle.text.floatingStartDate'] || 'Floating Start Date',
        field: 'floatingStartDate',
        width: 80,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          dateFormat: LocaleData.displayDateFormat
        }
      },
      {
        headerName: this.userBundle['reshuffle.text.floatingEndDate'] || 'Floating End Date',
        field: 'floatingEndDate',
        width: 80,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          dateFormat: LocaleData.displayDateFormat
        }
      },
      {
        headerName: this.userBundle['reshuffle.text.advertiser'] || 'Advertiser',
        field: 'advertiser',
        width: 160,
      },
      {
        headerName: this.userBundle['reshuffle.text.brand'] || 'Brand',
        field: 'brand',
        width: 150,
      },
      {
        headerName: this.userBundle['reshuffle.text.salesPerson'] || 'Sales Person',
        field: 'salesPerson',
        width: 100,
      },
      {
        headerName: this.userBundle['reshuffle.text.adminPerson'] || 'Admin Person',
        field: 'adminPerson',
        width: 100,
      },
      {
        headerName: this.userBundle['reshuffle.text.nbFrames_alloc'] || 'Nb Frame (Allocated)',
        field: 'nbFrames',
        width: 100
      },
      {
        headerName: this.userBundle['reshuffle.text.impression_alloc'] || 'Impressions(Allocated)',
        field: 'impressions',
        width: 120,
        cellRenderer: this.decimalCellRenderer
      },
      {
        headerName: this.userBundle['reshuffle.text.automationValue'] || 'Automation value',
        field: 'price',
        width: 110,
        cellRenderer: this.decimalCellRenderer
      },
    ];

    for (let i = 1; i < this.campaignColumnDefs.length; i++) {
      this.campaignColumnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
      this.campaignColumnDefs[i].floatingFilterComponentParams = {
        value: '',
        suppressFilterButton: true,
        decimalRound: null
      };
      this.campaignColumnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };
      this.campaignColumnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.campaignColumnDefs[i].headerComponentFramework = AgCustomHeaderComponent;

      this.campaignColumnDefs[i].cellClass = (params): string[] => {
        const retArr: string[] = [];
        if (params.node.rowPinned === 'bottom') {
          retArr.push('fontBold', 'borderBottom');
        }
        if (params.data.isSelected) {
          retArr.push('selectedRow');
        }
        return retArr;
      };
    }

    this.sortObj = new AgCustomSort([], [], [], this.groupingField, true);
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
  }

  apply = () => {
    const selectedRows = this.getSelectedChildRows();
    if (selectedRows.length > 0) {
      selectedRows.forEach((s) => {
        s.lockedAtUI = s.lockedEditable ? !!this.footerRowData[0].lockedAtUI : s.lockedAtUI;
        s.filler = this.footerRowData[0].filler != null ? this.footerRowData[0].filler : s.filler;
      });

      this.reset();
      this.rowData = _.clone(this.rowData);
    }
  }

  reset = () => {
    const columnArray = ['locked', 'filler'];
    for (let index = 0; index < columnArray.length; index++) {
      this.footerRowData[0][columnArray[index]] = null;
    }
  }

  weekPinnedRowCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {
      // apply Icon
      const eImgApply: HTMLImageElement = document.createElement('img');
      eImgApply.src = 'images/general/apply.png';
      eImgApply.title = 'Apply';
      eImgApply.onclick = this.apply;
      eDiv.appendChild(eImgApply);

      // reset Icon
      const eImgReset: HTMLImageElement = document.createElement('img');
      eImgReset.src = 'images/general/reset.png';
      eImgReset.title = 'Reset';
      eImgReset.onclick = this.reset;
      eDiv.appendChild(eImgReset);
    }
    return eDiv;
  }

  decimalCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    let text = params.value;
    if (params.column.colId === 'nbFrames_alloc' ||
      params.column.colId === 'nbFrames_obj' ||
      params.column.colId === 'impression_alloc' ||
      params.column.colId === 'impression_obj'
    ) {
      text = this.decimalPipe.transform(text, '.0-0');
    } else {
      text = this.decimalPipe.transform(text, '.2-2');
    }
    eDiv.innerHTML = text;
    return eDiv;
  }

  /**
   * download cart data in excel format
   */
  downloadExcel() {
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    // Add Header Row
    let sheetRow: ExcelCell[] = [];
    for (let j = 0; j < this.campaignColumnDefs.length; j++) {
      const cell: ExcelCell = {
        text: this.campaignColumnDefs[j].headerName,
        fontColor: '#FFFFFF',
        bgColor: '#000000',
        borderColor: '#CCCCCC',
        isFontBold: true
      };
      const col: ExcelColumn = {
        width: 12
      };
      excelColumn.push(col);
      sheetRow.push(cell);
    }
    sheetData.push(sheetRow);

    // Add Data rows
    let rowsInserted = 0;
    for (let i = 0; i < this.rowData.length; i++) {
      sheetRow = [];
      for (let j = 0; j < this.campaignColumnDefs.length; j++) {
        let cellVal = this.rowData[i][this.campaignColumnDefs[j].field];
        if (this.campaignColumnDefs[j].field === 'startDate' || this.campaignColumnDefs[j].field === 'endDate') {
          if (cellVal) {
            cellVal = this.datePipe.transform((new Date(cellVal)), LocaleData.displayDateFormat);
          }
        }
        const cell: ExcelCell = {
          text: cellVal,
          fontColor: '#000000',
          bgColor: rowsInserted % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
          borderColor: '#CCCCCC'
        };
        sheetRow.push(cell);
      }
      rowsInserted++;
      sheetData.push(sheetRow);
    }
    const cartText = 'reshuffle_detail';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }
  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }
  onModalSaved(event) {
    if ((this.uiControl.reshuffleScopingEnabled || this.uiControl.reshuffleScopingForObjectiveWorkspaceEnabled) && this.isReshuffleScopeAvailable) {
      const responseObject = {
        rowData: this.rowData,
        reshuffleScopeUserSelection: {}
      };
      responseObject.reshuffleScopeUserSelection['38'] = [];
      responseObject.reshuffleScopeUserSelection['38'].push(this.exclusionValue === "" ? 1 : this.exclusionValue);
      for (const [key, value] of Object.entries(this.selected)) {
        const data = [];
        this.selected[key].forEach(element => {
          data.push(element.id);
        });
        responseObject.reshuffleScopeUserSelection[Number(key)] = data;
      }
      event.activeModal.close(responseObject);
    } else {
      event.activeModal.close(this.rowData);
    }
  }


  onFileChange(event: any) {
    const selectedFile = event?.target?.files[0];
    //take a copy before file upload process start
    this.isFileUploaded ? null : (this.rowDataCopy = _.cloneDeep(this.rowData));
    if (selectedFile && this.checkFileType(selectedFile)) {
      this.handleFileUpload(event);
    }
  }

  // checks whether the uploaded file is of required type or not
  checkFileType(sender) {
    var validFileExtensions = [
      "csv",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    var fileExt = sender?.type;
    if (validFileExtensions.indexOf(fileExt) < 0) {
      this.toaster.logError("Invalid file selected");
      this.selectedFileName = "Invalid file selected";
      return false;
    } else {
      this.selectedFileName = sender?.name;
      return true;
    }
  }

  // reading data from the valid excel
  handleFileUpload(event){
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = event?.target?.files[0];
    event.target.value = '';
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: "binary" });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial['Sheet1'] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      this.processExcelData(jsonData);
    };
    reader.readAsBinaryString(file);
  }

  processExcelData(excelData) {
    const sheet1Data: any[] = excelData ? excelData["Sheet1"] : [];
    const unlockBookings: any[] = [];
    if (sheet1Data && sheet1Data.length) {
      const validCampaginRefColumnNames = [
        "Campaign Reference",
        "Campaign",
        "Campaign Ref"
      ];
      const validBookingRefColumnNames = [
        "Campaign Ref-Column",
        "Booking",
        "Booking Reference",
        "Booking Ref"
      ];
      const columnName = Object.keys(sheet1Data[0])[0];
      // campaign ref
      if (columnName && validCampaginRefColumnNames.includes(columnName)) {
        sheet1Data.forEach((row) => {
          if (row[columnName]) {
            unlockBookings.push({
              columnName: row[columnName],
              isCampaignRef: true
            });
          }
        });
        this.lockUnlockBookingsInPopUp(unlockBookings);
        // booking ref
      } else if (columnName && validBookingRefColumnNames.includes(columnName)) {
        sheet1Data.forEach((row) => {
          if (row[columnName]) {
            unlockBookings.push({
              columnName: row[columnName],
              isBookingRef: true
            });
          }
        });
        this.lockUnlockBookingsInPopUp(unlockBookings);
      } else {
        this.toaster.logError("Invalid Column Name");
      }
    }
  }

    /**
   * @description This will unlock the matched campaigns/bookings in the excel sheet and reshuffle pop-up, remaining will be locked
   * @private
   * @memberof reshuffle-detail-ls component
   */
  lockUnlockBookingsInPopUp(unlockBookings: any[]) {
    if (unlockBookings.length) {
      let pattern = /New/i;
      unlockBookings.forEach((unlockBooking) => {
        if (unlockBooking && unlockBooking["isCampaignRef"]) {
          this.rowData.forEach((reshuffleData) => {
            if (!pattern.test(reshuffleData.campaignRef)) {
              if (reshuffleData && reshuffleData.lockedEditable && (unlockBookings.some((uB) => uB["columnName"] === (reshuffleData.campaignRef.split('-')[0])))) {
                reshuffleData.lockedAtUI = false;
              }
              else {
                reshuffleData.lockedAtUI = true;
              }
            }
          });
        } else if (unlockBooking && unlockBooking["isBookingRef"]) {
          this.rowData.forEach((reshuffleData) => {
            if (!pattern.test(reshuffleData.campaignRef)) {
              if (reshuffleData && reshuffleData.lockedEditable && (unlockBookings.some((uB) => uB["columnName"] === reshuffleData.campaignRef))) {
                reshuffleData.lockedAtUI = false;
              }
              else {
                reshuffleData.lockedAtUI = true;
              }
            }
          });
        }
      });
    }
    this.isFileUploaded = true;
    this.campaignGridOptions.api.setRowData(this.rowData);
  }

  clearSelectedFile() {
    this.selectedFileName = '';
    this.isFileUploaded = false;
    this.rowData = _.cloneDeep(this.rowDataCopy)
    this.campaignGridOptions.api.setRowData(this.rowData); // set with copy date
  }
}
