import { Injectable } from '@angular/core';
import { FilterDataService } from './filter-data.service';
import { BrickBaseService } from './brick-base.service';
import { RequestJsonService } from '../../geo-map/request-json.service';
import { FilterBarService } from '../../filter/filter-bar/filter-bar.service';
import { CartService } from '../../geo-map/cart.service';
import { DataShareService } from '../services/data-share.service';
import { ConcertinaDataService } from '../components/concertina/concertina-data.service';
import { ConcertinaCanvasService } from '../components/concertina/concertina-canvas.service';
import { LoaderService } from '../services/loader.service';
import * as _ from 'lodash';
import { Row } from '../../models';
import { GeoMapService } from '../../geo-map/geo-map.service';

@Injectable()
export class CampaignService {

  public geoMapperBackupData: any = null;
  public visualPlannerBackupData: any = null;

  isLoadedCampaign = false;
  constructor(
    private filterDataService: FilterDataService,
    private requestJsonService: RequestJsonService,
    private filterBarService: FilterBarService,
    private cartService: CartService,
    private brickBaseService: BrickBaseService,
    private dataShareService: DataShareService,
    private concertinaCanvasService: ConcertinaCanvasService,
    private concertinaDataService: ConcertinaDataService,
    private loaderService: LoaderService,
    private geoMapService: GeoMapService,
  ) { }

  loadCartOnMap(): void {
    this.clearFilters();
    this.cartService.loadCartDataonMap();
  }

  clearFilters(): void {
    // clear filter data
    this.filterDataService.resetAllFilters();
    this.filterDataService.filterDataChange();

    // clear request json data
    this.requestJsonService.resetRequestJsonData();
    this.requestJsonService.requestJsonDataChange();

    // clear filter bar data
    this.filterBarService.resetFilterBarData();
    this.filterBarService.filterBarDataChange();

    // clear process request for GM/VP
    this.geoMapService.resetProcessRequest();
  }

  loadFilters(items: any[]): void {
    // Channel Bric - set filter data from workspace item
    const channelBric = _.result(_.find(items, (obj) => {
      return obj.bricId === this.brickBaseService.brickID.Environment;
    }), 'brics');
    const selectedChannels = [];
    for (const key in channelBric[0].Selected) {
      if (channelBric[0].Selected.hasOwnProperty(key)) {
        const element = channelBric[0].Selected[key];
        element.forEach((channel) => {
          channel['selected'] = true;
          selectedChannels.push(channel);

        });
      }
    }
    if (selectedChannels.length > 0) {
      this.filterDataService.setChannel(selectedChannels);
    }

    // Media Bric - set filter data from workspace item
    const mediaBric = _.result(_.find(items, (obj) => {
      return obj.bricId === this.brickBaseService.brickID.Media;
    }), 'brics');
    if (mediaBric && !_.isEmpty(mediaBric[0].Selected)) {
      this.filterDataService.setMedia(mediaBric[0].Selected);
    }

    // location Bric - set filter data from workspace item
    const locationBric = _.result(_.find(items, (obj) => {
      return obj.bricId === this.brickBaseService.brickID.Location;
    }), 'brics');
    if (locationBric && !_.isEmpty(locationBric[0].Selected)) {
      for (const key in locationBric[0].Selected) {
        if (locationBric[0].Selected.hasOwnProperty(key)) {
          const element = locationBric[0].Selected[key];
          element.forEach((location) => {
            delete location.isChecked;
            delete location.$$hashKey;
          });
        }
      }
      this.filterDataService.setLocation(locationBric[0].Selected, null);
    }

    // Proximity Bric - set filter data from workspace item
    const proximityBric = _.result(_.find(items, (obj) => {
      return obj.bricId === this.brickBaseService.brickID.Proximity;
    }), 'brics');
    if (proximityBric && !_.isEmpty(proximityBric[0].Selected)) {
      this.filterDataService.setProximity(proximityBric[0].Selected);
    }

    // Tag Bric - set filter data from workspace item
    const tagBric = _.result(_.find(items, (obj) => {
      return obj.bricId === this.brickBaseService.brickID.Tag;
    }), 'brics');
    if (tagBric && !_.isEmpty(tagBric[0].Selected)) {
      const initialConfig = this.dataShareService.getInitialConfig();
      const tagGroups = initialConfig.tagGroup;
      const selectedTags = {};
      for (const key in tagGroups) {
        if (tagGroups.hasOwnProperty(key)) {
          const element = tagGroups[key];
          selectedTags[element.tagGroupId] = [];
          element.tag.forEach((tag) => {
            if (tagBric[0].Selected.allTabSelection.indexOf(tag.tagId) > -1) {
              tag.exclude = false;
              tag.isDisabled = false;
              selectedTags[element.tagGroupId].push(tag);
            }
          });
        }
      }
      this.filterDataService.setTags(selectedTags);
    }

    // Format Bric - set filter data from workspace item
    const formatBric = _.result(_.find(items, (obj) => {
      return obj.bricId === this.brickBaseService.brickID.Format;
    }), 'brics');
    if (formatBric && !_.isEmpty(formatBric[0].Selected)) {
      for (const key in formatBric[0].Selected) {
        if (formatBric[0].Selected.hasOwnProperty(key)) {
          const element = formatBric[0].Selected[key];
          if (element instanceof Array) {
            element.forEach((format) => {
              delete format.isChecked;
              delete format.$$hashKey;
            });
          }
        }
      }
      this.filterDataService.setFormat(formatBric[0].Selected);
    }

    // SOT Bric - set filter data from workspace item
    const sotBric = _.result(_.find(items, (obj) => {
      return obj.bricId === this.brickBaseService.brickID.SOT;
    }), 'brics');
    if (sotBric && !_.isEmpty(sotBric[0].Selected)) {
      this.filterDataService.setShare({
        sot: sotBric[0].Selected.sot.toString()
      });
    }

    // list Bric - set filter data from workspace item
    const listBric = _.result(_.find(items, (obj) => {
      return obj.bricId === this.brickBaseService.brickID.List;
    }), 'brics');
    if (listBric && !_.isEmpty(listBric[0].Selected)) {
      const tempList = this.filterDataService.getList();
      const routeFrameId = [];
      const routeFrameIds = {
        include: [],
        exclude: [],
        requestJSON: {
          include: {
            userSelectionId: 0,
            exclude: false,
            validCount: 0,
            totalCount: 0
          },
          exclude: {
            userSelectionId: 0,
            exclude: false,
            validCount: 0,
            totalCount: 0
          }
        }
      };
      listBric[0].Selected.routeFrameId.forEach((element) => {
        routeFrameId.push({
          routeFrameId: element.routeFrameId,
          exclude: element.exclude,
          displayText: element.routeFrameId,
          isDisabled: false
        });
        routeFrameIds.requestJSON.include.userSelectionId = element.userSelectionId;
        if (!element.exclude) {
          routeFrameIds.include.push(element.routeFrameId);
          routeFrameIds.requestJSON.include.exclude = element.exclude;
          routeFrameIds.requestJSON.include.validCount += element.validCount;
          routeFrameIds.requestJSON.include.totalCount += element.totalCount;

        } else {
          routeFrameIds.exclude.push(element.routeFrameId);
          routeFrameIds.requestJSON.exclude.exclude = element.exclude;
          routeFrameIds.requestJSON.exclude.validCount += element.validCount;
          routeFrameIds.requestJSON.exclude.totalCount += element.totalCount;
        }
      });
      tempList.listUpload = {
        fileList: listBric[0].Selected.fileName
      };
      tempList.routeFrameIds = routeFrameIds;
      tempList.routeFrameId = routeFrameId;
      this.filterDataService.setList(tempList);
    }

    // Incharge Bric - set filter data from workspace item
    const inchargeBric = _.result(_.find(items, (obj) => {
      return obj.bricId === this.brickBaseService.brickID.Incharge;
    }), 'brics');
    if (inchargeBric) {
      this.filterDataService.setIncharge({
        startDate: inchargeBric[0].Selected.startDate,
        endDate: inchargeBric[0].Selected.endDate
      });
    }
  }

  clearBackupFilters(): void {
    this.filterDataService.setBackupFilterData();
  }

  takeGeoMapperBackupData(): void {
    this.geoMapperBackupData = {
      filterData: _.cloneDeep(this.filterDataService.filterData),
      requestJsonData: _.cloneDeep(this.requestJsonService.requestJsonData),
      filterBarData: _.cloneDeep(this.filterBarService.filterBarData)
    };
  }

  restoreGeoMapperBackupData(): void {
    if (this.geoMapperBackupData) {
      this.filterDataService.filterData = _.cloneDeep(this.geoMapperBackupData.filterData);
      this.filterDataService.filterDataChange();

      this.requestJsonService.requestJsonData = _.cloneDeep(this.geoMapperBackupData.requestJsonData);

      this.filterBarService.filterBarData = _.cloneDeep(this.geoMapperBackupData.filterBarData);
      this.filterBarService.filterBarDataChange();
    }
  }

  takeVisualPlannerBackupData() {
    this.visualPlannerBackupData = {
      filterData: _.cloneDeep(this.filterDataService.filterData),
      requestJsonData: _.cloneDeep(this.requestJsonService.requestJsonData),
      filterBarData: _.cloneDeep(this.filterBarService.filterBarData)
    };
  }

  restoreVisualPlannerBackupData() {
    if (this.visualPlannerBackupData) {
      this.filterDataService.filterData = _.cloneDeep(this.visualPlannerBackupData.filterData);
      this.filterDataService.filterDataChange();

      this.requestJsonService.requestJsonData = _.cloneDeep(this.visualPlannerBackupData.requestJsonData);

      this.filterBarService.filterBarData = _.cloneDeep(this.visualPlannerBackupData.filterBarData);
      this.filterBarService.filterBarDataChange();
    }
  }

  loadCartOnPlanner(): void {
    this.clearFilters();
    let hideAdvertiserId;
    if (this.concertinaCanvasService.initialConfig.uiControl.hideShowAdvertiserIdEnabled) {
      hideAdvertiserId = this.concertinaCanvasService.hideAdvertiserId;
    }
    this.concertinaDataService.getConcertinaData(undefined, this.concertinaCanvasService.getGroupingdata(), [],
      {}, hideAdvertiserId).then((data: any) => {
        this.concertinaCanvasService.resetConcertinaData();
        this.concertinaCanvasService.resetCurrentSelection();
        this.concertinaCanvasService.pushConcertinaData(data.groupedData, data.groups, data.xHeadings);
        this.concertinaCanvasService.setGroups(data.groups);
        this.loaderService.show();
        setTimeout(() => {
          this.concertinaCanvasService.refreshTable();
          this.loaderService.hide();
        }, 10);

      }, (error) => {
        console.error(error);
      });
  }

  setPlannerBasketMode(isBasketMode): void {
    this.concertinaDataService.setBasketMode(isBasketMode);
  }

  /**
   * This method is to check which type of campaign it is
   * @param items Rows array of workspace
   */
  getCampaignMediaType(items: Row[]): { isPaper: boolean, isDigital: boolean } {
    const res = {
      isDigital: false,
      isPaper: false
    };

    if (items && items.length > 0) {
      const mediaRow = items.filter(i => i.bric.bricid === this.brickBaseService.brickID.Media);
      for (const bric of mediaRow[0].cells) {
        if (bric.selected.selectedMediaObject.mediaTypeId === -1) {
          res.isDigital = true;
          res.isPaper = true;
        } else if (bric.selected.selectedMediaObject.mediaTypeId === 1) {
          res.isDigital = true;
        } else if (bric.selected.selectedMediaObject.mediaTypeId === 0) {
          res.isPaper = true;
        }
      }
    }
    return res;
  }

}
