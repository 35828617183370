import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import {
  SharedService,
  BrickBaseService,
  LogHelperService,
  CellAttributeService,
  StateService,
  FileUploadService,
  DataShareService
} from '../../../core/services/index';
import { CellValues, TemplateProps } from '../../../models/workspace/index';
import { LocationBase } from '../location-base';
import * as _ from 'lodash';
import { TreeViewComponent } from '../../../core/components/tree-view/tree-view.component';
import { GLOBAL } from '../../../core/utils/app.constant';
import { ObjectiveService } from '../../../workspace/objective/objective.service';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';
import { Brick } from '../../../workspace/brick-model';
import { EnvironmentId, LocationTypeSelection } from '../../../core/enum';

@Component({
  selector: 'app-location-modal',
  templateUrl: './location-modal.component.html'
})
export class LocationModalComponent extends LocationBase implements OnInit, AfterViewInit {

  /**
   * It will take data to configure modal window.
   */
  @Input() resolveObject: CellValues;
  readOnlyModal = false;
  templateProps: TemplateProps;
  localSolverEnabled: boolean;

  @ViewChild(TreeViewComponent)
  public treeViewComponent: TreeViewComponent;
  options = {
    idField: 'id',
    displayField: 'name',
    childrenField: 'child',
    useCheckbox: true,
    scrollContainer: document.body.parentElement
  };
  /**
   * Creates an instance of LocationModalComponent.
   * @param {DataShareService} dataService Service to share data between compoenents.
   * @param {SharedService} sharedService Common shared service.
   * @param {BrickBaseService} brickBaseService Service for brick-base data.
   * @param {LogHelperService} logHelperService Service for log messages.
   * @memberof LocationModalComponent
   */
  constructor(dataService: DataShareService,
    sharedService: SharedService,
    brickBaseService: BrickBaseService,
    private cellAttributeService: CellAttributeService,
    private logHelperService: LogHelperService,
    private stateService: StateService,
    public objectiveService: ObjectiveService,
    public appHeaderService: AppHeaderService,
    public fileUploadService: FileUploadService
  ) {
    super(dataService, sharedService, brickBaseService, objectiveService, appHeaderService);
  }

  /**
   * angualr life cycle hook
   * called on component initialization phase
   * @memberof LocationModalComponent
   */
  ngOnInit() {
    this.geoTree = _.cloneDeep(GLOBAL.geoTree);
    this.localSolverEnabled = this.resolveObject.reshufflingEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_LS || this.resolveObject.reshufflingEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH || this.appHeaderService.objectiveMode;
    if (this.appHeaderService.objectiveMode) {
      const brick: Brick = this.stateService.getObjectiveObject('currentObjectiveData').brick;
      const wsFilterObj = this.stateService.getWorkspaceFilterObj();
      const rowIndexOfLocation = wsFilterObj.getExistingRowIndex(Number(this.brickBaseService.brickID.Location));
      if (rowIndexOfLocation > -1) {
        const objectiveDataCellIndex = wsFilterObj.rows[rowIndexOfLocation].cells[brick.cellIndex] ? brick.cellIndex : brick.cellIndex - 1;
        const overlappingCellIndex = wsFilterObj.rows[rowIndexOfLocation].getOverlappingCell(objectiveDataCellIndex);
        if (!wsFilterObj.rows[rowIndexOfLocation].cells[overlappingCellIndex === -1 ? objectiveDataCellIndex : overlappingCellIndex].isEmpty) {
          const selectedValuesOnWs = wsFilterObj.rows[rowIndexOfLocation].cells[overlappingCellIndex === -1 ? objectiveDataCellIndex : overlappingCellIndex].selected;
          if (!this.resolveObject.selectedValues) {
            this.resolveObject.selectedValues = _.cloneDeep(selectedValuesOnWs);
          }
        }
      }
    }
    const selectedValues = this.resolveObject.selectedValues && !this.resolveObject.selectedValues.hasOwnProperty('-99')
      ? this.resolveObject.selectedValues : {};
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.init(this.resolveObject.columnConfig, selectedValues);
    this.showContent(0);
    this.templateProps = {
      displayId: 'id',
      displayName: 'name',
      displayRadioButton: false,
      searchPlaceHolder: this.initialConfig.userBundle.search,
      showId: false,
      allowCustomCheckBoxEvents: false,
      localSolverEnabled: this.localSolverEnabled,
    };
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const selectedKey = Object.keys(this.geoTree);
      let selectedValues = [];
      _.map(selectedKey, (obj) => {
        selectedValues = [...this.geoTree[obj], ...selectedValues];
      });
      if (this.treeViewComponent) {
        selectedValues.forEach((node) => {
          this.treeViewComponent.tree.treeModel.getNodeById(node.id).setIsSelected(true);
        });
      }
    }, 100);
  }

  /**
   * callback to close modal popup window
   * @param {*} event - modal event object
   * @memberof LocationModalComponent
   */
  onModalClosed(event) {
    const keys = Object.keys(this.selected);
    let isSelectedValuesLength = 0;
    keys.forEach(key => isSelectedValuesLength = isSelectedValuesLength + this.selected[key].length);
    if (event.reason === 'escape' && isSelectedValuesLength > 0) {
      if (window.confirm(this.initialConfig['userBundle']['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }
  /**
   * To display content of particular selection.
   * @param index Index of selected item.
   */
  showContent(index: number): void {

    if(this.initialConfig.systemData.environmentId == EnvironmentId.UK){
      // UK : passing true only if the selectionId matches to display/hide upload button
      this.sharedService.hideUploadForArea.next(this.lookups.lookup[index].selectionId == LocationTypeSelection.Area);
    }

    this.selectedItem = index;
    this.searchForText = this.initialConfig.userBundle['geoplanner.text.searchFor'] || 'Search for';
    this.searchTitle = this.lookups.lookup[index].displayName;
    setTimeout(() => {
      if (this.treeViewComponent) {
        // @ts-ignore
        this.treeViewComponent.tree.sizeChanged();
      }
    });
  }

  /**
   * callback function - called during save click
   * @param {*} event - modal event object
   * @memberof LocationModalComponent
   */
  onModalSaved(event) {
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    if (this.lookups['lookup'][this.selectedItem].selectionIds && this.initialConfig.uiControl.geoLocationTree) {
      this.geoTreeViewValue();
    }
    if (this.isValidSelection()) {
      values.selectedValues = this.selected;

      const fileKeys = Object.keys(this.fileList);
      const selectedFileKeys = values.selectedValues.fileList ? Object.keys(values.selectedValues.fileList) : [];
      const selectionIDs = this.lookups.lookup ? this.lookups.lookup.map(l => l.selectionId) : [];

      if (fileKeys.length > 0 && selectedFileKeys.length > 0) {
        for (const selectionId of selectionIDs) {
          if (values.selectedValues.fileList[selectionId]) {
            // delete all files first
            delete values.selectedValues.fileList[selectionId];
          }
        }
      }

      this.uploadFiles(this.fileUploadService, values.selectedValues).then(() => {
        values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Location, values.selectedValues);
        values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Location, values.selectedValues);
        values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Location,
          values.selectedValues, this.lookups.lookup);
        event.activeModal.close(values);
      });
    } else {
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.noDataSelect']);
    }
  }

  /**
   * @description get selected Treeview Value
   * @author Kishan Patel
   * @memberof LocationModalComponent
   */
  geoTreeViewValue() {
    this.getSelectedNodesFromSate();

    if (this.initialConfig.uiControl.geoLocationTree) {
      const treeData = this.treeData;
      const selectedProducts = [];
      this.selectedNodeIds.forEach((id) => {
        const productDetails = this.treeViewComponent.getNodeById(id);
        selectedProducts.push({
          id: productDetails.data.id,
          name: productDetails.data.name,
          relative: !!this.selectedRelativeFilters[productDetails.data.id],
        });
      });
      const selectedKey = Object.keys(this.geoTree);
      _.map(selectedKey, (obj) => {
        this.geoTree[obj] = [];
      });

      if (selectedProducts.length > 0) {
        const resultData = _.map(selectedProducts, (data) => {
          return { ...data };
        });
        const len = resultData.length;
        for (let index = 0; index < len; index++) {
          const element = resultData[index];
          let loopBreak = false;
          this.loopTreeData(treeData,GLOBAL.geoArea.area106,element);
        }
      }
    }
    Object.assign(this.selected, this.geoTree);

  }

  /**
   * This method gets the selected Nodes from the Sate
   *
   * @memberof
   */
  getSelectedNodesFromSate() {
    if (this.treeViewComponent) {
      const leafNodes = this.treeViewComponent.getSelectedLeafNodeIds();
      this.selectedNodeIds = [];
      for (const key in leafNodes) {
        if (leafNodes.hasOwnProperty(key) && leafNodes[key]) {
          this.getTreeNodes(key);
        }
      }
    }
  }

  /**
   * Method to get the acutal nodes to be represented on UI
   * If all the children are selected, dispaly only the Parent Node
   * @param {*} key
   * @memberof
   */
  getTreeNodes(key) {
    if (GLOBAL.localSolverEnabled) {
      this.selectedNodeIds.push(parseInt(key, 10));
      return;
    }

    const parent = this.treeViewComponent.getParentNode(key);
    if (parent) {
      if (parent.isPartiallySelected) {
        this.selectedNodeIds.push(parseInt(key, 10)); // Push the child node
      } else {
        if (!this.selectedNodeIds.includes(parent.id)) {
          const parentOfParent = this.treeViewComponent.getParentNode(parseInt(parent.id, 10));
          if (parentOfParent) {
            this.getTreeNodes(parent.id);
          } else {
            this.selectedNodeIds.push(parent.id);
          }
        }
      }
    } else {
      this.selectedNodeIds.push(parseInt(key, 10));
    }
  }
}
