import { Pipe, PipeTransform } from '@angular/core';
import { escapeRegexp } from './common';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  public transform(value: string, query: string): any {

    const tagRE = new RegExp('<[^<>]*>', 'ig');
    // get list of tags
    const tagList = value.match(tagRE);
    // Replace tags with token
    const tmpValue = value.replace(tagRE, '$!$');

    if (query) {
      // Replace search words
      value = tmpValue.replace(new RegExp(escapeRegexp(query), 'gi'), '<strong>$&</strong>');
      // Reinsert HTML
      for (let i = 0; value.indexOf('$!$') > -1; i++) {
        value = value.replace('$!$', tagList[i]);
      }
    } else {
      value = tmpValue;
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

export function stripTags(input: string): string {
  const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
  return input.replace(commentsAndPhpTags, '').replace(tags, '');
}
