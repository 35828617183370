<input #input [(ngModel)]="value" style="width: 100%"
    [appOnlyNumber]="validation.onlyNumber"
    [allowReplaceDecimalSeparator]="validation.allowReplaceDecimalSeparator"
    [decimalPoints]="validation.decimalPoints"
    [minValue]="validation.minValue"
    [maxValue]="validation.maxValue"
    [thousandSep]="validation.thousandSep"
    [decimalSep]="validation.decimalSep"
    [allowComma]="validation.allowComma"
    [allowNegetiveIntegers]="validation.allowNegetiveIntegers"
    [disabled] ='cellDisable'
    (blur)="onFocusLost()" (change)="onTextChange()" [title]="value" (keydown)="onKeyDown($event)">
