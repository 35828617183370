import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { FolioImageService } from './folio-image.service';
import { DataShareService } from '../../core/services/data-share.service';
import { FurnitureModel, InitialConfigModel, ResponseModel, FolioResponseModel } from '../../models';
import { GLOBAL } from '../../core/utils/app.constant';

@Component({
  selector: 'app-folio-image',
  templateUrl: './folio-image.component.html',
  styleUrls: ['./folio-image.component.css'],
  providers: [NgbCarouselConfig]
})
export class FolioImageComponent implements OnInit {
  @Input() furniture: FurnitureModel;
  galleryImages: string[] = [];
  initialConfig: InitialConfigModel;
  isLoadingData = false;

  constructor(private activeModal: NgbActiveModal,
              config: NgbCarouselConfig,
              private folioImageService: FolioImageService,
              private dataShareService: DataShareService) {
    this.initialConfig = this.dataShareService.getInitialConfig();
    config.interval = -1;
  }

  ngOnInit() {
    setTimeout(() => { this.loadImages(); }, 500);
  }

  loadImages() {
    this.galleryImages = [];
    if (this.furniture.galleryUrl) {
      this.isLoadingData = true;
      this.folioImageService.getFolioImages(GLOBAL.SERVICE_BASE_URL + this.furniture.galleryUrl).subscribe((data: ResponseModel<FolioResponseModel>) => {
        if (data.status === 'OK') {
          const folioImageUrls: string[] = data.data.imageUrls;
          const galleryImages: string[] = [];
          for (const url of folioImageUrls) {
            if (this.initialConfig?.uiControl?.enableNewFolioFurnitureAPI) {
              galleryImages.push(url);
            } else {
              // tslint:disable-next-line:prefer-template
              const imgUrl = this.dataShareService.getServiceCallUrlByKey('FOLIO_IMAGE_PROXY') + '?action=folioImageProxy&url=' + url;
              galleryImages.push(imgUrl);
            }
          }
          this.galleryImages = galleryImages;
          this.isLoadingData = false;
          this.animateCarouselItems();
        } else if (data.status === 'KO') {
          this.isLoadingData = false;
        }
      });
    }
  }

  animateCarouselItems(): void {
    setTimeout(() => {
      const carouselItems = document.getElementsByClassName('carousel-item');
      /* tslint:disable:prefer-for-of */
      for (let i = 0; i < carouselItems.length; i++) {
        const ele: HTMLDivElement = carouselItems[i] as HTMLDivElement;
        ele.classList.add('animated', 'fadeIn');
      }
    }, 1000);
  }

  /**
   * To close the opened modal.
   */
  onClose() {
    this.activeModal.dismiss();
  }

  trackByItem(index, item) {
    return item;
  }

}
