import { PatternDataPrep, PatternData, SelectedDay, PatternCell, ProductHelper } from '../../models/workspace/index';
import {
  DataShareService,
  StateService,
  LogHelperService
} from '../../core/services';
import { PattenService } from './pattern-modal.service';
import { DayPartSpan, UiControl } from '../../models/initial-config.model';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import * as _ from 'lodash';
import * as moment from 'moment';

export class PatternBase {
  product: ProductHelper;
  disableEntireScreen = false;
  selectedRange: PatternDataPrep;
  userBundle: any;
  uiControl: UiControl;
  patternObj: PatternData = new PatternData();
  selectedDay: SelectedDay = new SelectedDay();
  selectedValue: PatternData;
  pcmMode;
  readOnlyModal = false;
  dropDownData: any;
  // NOTE: do not clone `defaultSelection`
  defaultSelection: DayPartSpan;
  patternTypeData: any[] = [];  // 2-D Array to store values for named and numberd days
  currentSelectionData: any[] = [];
  toggleSelectedDay: SelectedDay[] = [];
  dayPart: DayPartSpan[] = [];
  customStartTime: DayPartSpan[] = [];
  customEndTime: DayPartSpan[] = [];
  selectedMultiple: SelectedDay[] = [];
  listOfDays: number | string[] = [];
  listWeekNumber: number[] | string[] = [];
  disableCustomHours = false;
  namedDays = {
    row: []
  };
  readonly NOT_EDITABLE = 'common.info.notEditable';
  isPatternOverlapped = false;

  constructor(
    private dataService: DataShareService,
    public pattenService: PattenService,
    public stateService: StateService,
    private logHelper: LogHelperService,
    private appHeaderService: AppHeaderService
  ) {
    this.pcmMode = this.appHeaderService.enabledPCM;
    // don't delete, Nishit
    // this.getSelectedRangeForCalc();
    // console.log(this.selectedRange);
  }

  // getSelectedRangeForCalc() {
  //   this.selectedRange = this.pattenService.getSelectedRange();
  // }

  init(selected: PatternData) {

    this.dropDownData = this.pattenService.dropDownData();
    this.defaultSelection = this.dropDownData ? this.dropDownData.dayPart[0] : 0; // When we click on cell

    this.userBundle = this.dataService.getInitialConfigByKey('userBundle');
    this.uiControl = this.dataService.getInitialConfigByKey('uiControl');
    this.selectedValue = selected;

    if (this.pcmMode && this.selectedValue) {
      this.patternObj = this.selectedValue;
    } else {
      this.loadPatternObj();
    }
  }

  loadPatternObj() {
    this.getListOfDays();

    if (this.selectedValue && Object.keys(this.selectedValue).length > 0) {
      this.loadFromSelectedValue();
    } else {
      this.patternObj.allowDays = this.selectedRange ? this.selectedRange.allowDays : false;
      this.patternObj.allowDayParts = this.selectedRange ? this.selectedRange.allowDayParts : false;
      this.patternObj.allowHours = this.selectedRange ? this.selectedRange.allowHours : false;
      this.generateTable();
      this.displayTextNamedDays();
      this.selectedDay = {
        rowIndex: null,
        columnIndex: null
      };
    }
    this.patternObj.hoursSelection = 'all';
    if (this.patternObj.allowDayParts && !this.patternObj.allowDays && !this.patternObj.allowHours) {
      this.defaultSelection = this.dropDownData.dayPart[1] || this.dropDownData.dayPart[0];
    } else if (this.patternObj.allowDayParts && !this.patternObj.allowDays && this.patternObj.allowHours) {
      this.defaultSelection = this.dropDownData.dayPart[1];
    }
    this.disablePatternScreen();
  }

  loadFromSelectedValue(): void {
    this.patternObj = {
      patternType: this.selectedValue.patternType, // 1 for Named, 2 for Numbered
      patternLength: this.selectedValue.patternLength,
      selected: [],
      pattern: [],
      allowDays: this.selectedRange.minValueUnit === 0 ? this.selectedValue.allowDays : this.selectedRange.allowDays,
      allowDayParts: this.selectedRange.minValueUnit === 0 ? this.selectedValue.allowDayParts : this.selectedRange.allowDayParts,
      allowHours: this.selectedRange.minValueUnit === 0 ? this.selectedValue.allowHours : this.selectedRange.allowHours
    };

    this.namedDays = {
      // @ts-ignore
      row: this.selectedValue.selected.row
    };

    if (this.selectedValue.selectedDay) {
      this.selectedDay = {
        rowIndex: this.selectedValue.selectedDay.rowIndex,
        columnIndex: this.selectedValue.selectedDay.columnIndex
      };

    } else {
      this.selectedDay = {
        rowIndex: null,
        columnIndex: null
      };
    }
    this.setSelectedMultiple();
    if (this.selectedValue.patternType === 1) {
      this.generateTableLoadedCampaign();
      this.displayTextNamedDays();
    } else if (this.selectedValue.patternType === 2) {
      this.displayTextNumberedDays();
    }
    // @ts-ignore
    this.patternTypeData[this.selectedValue.patternType - 1] = this.selectedValue.selected.row;

    if (Object.keys(this.selectedValue).length > 0 && this.selectedDay.rowIndex != null && this.selectedDay.columnIndex != null) {

      this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex].values.forEach((obj, i) => {

        if (Array.isArray(obj)) {
          this.customStartTime[i] = this.dropDownData.spans.find(e => e.spanId === obj[0].spanId);
          this.customEndTime[i] = this.dropDownData.spans.find(e => e.spanId === obj[1].spanId);
        } else {
          this.dayPart[i] = this.dropDownData.dayPart.find(e => e.dayPartId === obj.dayPartId);
        }

      });
    }
  }

  /**
   * @description this function will decide if it should disable/enable pattern screen
   * @author Nishit Parekh
   * @memberof PatternBase
   */
  disablePatternScreen(): void {
    if (this.selectedRange && !this.patternObj.allowDays && !this.patternObj.allowDayParts && !this.patternObj.allowHours) {
      this.selectedValue = new PatternData();
      this.disableEntireScreen = true;
      this.logHelper.logInfo(this.userBundle[this.NOT_EDITABLE]);
      return;
    }

    if (this.selectedRange && this.selectedRange.clearSelectedValue) {
      this.selectedValue = new PatternData();
      this.disableEntireScreen = true;
      this.logHelper.logInfo(this.userBundle[this.NOT_EDITABLE]);
    }

    if (this.selectedRange && this.selectedRange.allowDayParts === false && !this.selectedRange.allowDays
      && !this.selectedRange.allowHours) {
      this.disableEntireScreen = true;
      this.logHelper.logInfo(this.userBundle[this.NOT_EDITABLE]);
    }

    if (this.selectedRange && this.selectedRange.allowDays !== undefined && !this.selectedRange.allowDays && this.dropDownData) {
      // As day selection is not allowed, so remove 'Full Day' from drop down, daypart id is -1
      this.dropDownData.dayPart.forEach((obj, i) => {
        if (obj && obj.dayPartId === -1) {
          this.dropDownData.dayPart.splice(i, 1);
          return false;
        }
      });
    }

    if (this.dropDownData && this.dropDownData.spans.length === 0) {
      this.disableCustomHours = true;
    }
  }

  /**
   * @description This function will calculate the date(Day) range(in Incharge brick) TO BE DISPLAYED e.g 3-21, 12-30 etc
   * @returns {void}
   * @memberof PatternModalComponent
   */
  getListOfDays(): void {
    if (!this.selectedRange) { return; }
    const tempDate = new Date(this.selectedRange.startDate);
    for (let i = this.selectedRange.startDay; i < (this.selectedRange.totalDays + this.selectedRange.startDay); i++) {
      if (i === this.selectedRange.startDay) {
        tempDate.setDate(tempDate.getDate());
      } else {
        tempDate.setDate(tempDate.getDate() + 1);
      }
      this.listOfDays[i] = (tempDate.getDate() < 10 ? `0${tempDate.getDate()}` : tempDate.getDate());
      this.listWeekNumber[i] = moment(tempDate).isoWeek();
    }
  }

  disableColumnByStartDay = (obj, j) => {
    if (j <= (this.selectedRange.startDay - 1)) {
      obj.disable = false;
    } else {
      obj.disable = true;
    }
  }

  disableColumnByEndDay = (obj, j) => {
    if (j <= this.selectedRange.endDay) {
      obj.disable = false;
    } else {
      obj.disable = true;
    }
  }

  /**
   * @description Generate pattern when we open pattern brick after loading a campaign
   * @author Nishit Parekh
   * @returns {void}
   * @memberof PatternBase
   * @refactoredBy Vijay Sutaria
   */
  generateTableLoadedCampaign(): void {
    if (!this.selectedRange || (this.selectedRange.startDay === 0 && this.selectedRange.endDay === 6)) {
      return;
    }

    let len = this.namedDays.row.length; // row count

    if (!this.selectedRange.multipleRow && (this.selectedRange.startDay !== 0 || this.selectedRange.endDay !== 6)) {
      // If Pattern has only one row and start and end day is not Monday or Sunday
      this.namedDays.row[0].columns.forEach((obj, i) => {
        obj.disable = (i < this.selectedRange.startDay && i > this.selectedRange.endDay);
      });
    } else {
      //  if start Day is not monday and more than 1 row

      // It will disable cells in first row if any
      this.namedDays.row[0].columns.forEach((obj, i) => {
        obj.disable = (i < this.selectedRange.startDay);
      });

      this.generateColumnsLoaded(len);
    }
  }

  generateColumnsLoaded(len) {
    // pattern length(from backend) and no/length of rows (calculated in common functions)
    if (this.patternObj.patternLength === len) {
      //  create a new row and disable cells in that row
      if (this.selectedRange.week !== len) {
        this.namedDays.row.push({ columns: this.generateColumns() });
        len = this.namedDays.row.length; // row count
      }

      this.namedDays.row[len - 1].columns.forEach((obj, j) => {
        obj.disable = (j > this.selectedRange.endDay);
      });

    } else if (this.patternObj.patternLength < len) {
      switch (len !== this.selectedRange.week || this.selectedRange.startDay <= this.selectedRange.endDay) {
        case true:
          this.namedDays.row[len - 1].columns.forEach(this.disableColumnByStartDay);
          break;
        case false:
          this.namedDays.row[len - 1].columns.forEach(this.disableColumnByEndDay);
          break;
      }
    }
  }

  /**
   * @description generates matrix when perfect pattern, i.e. Monday to Sunday
   * @author Nishit Parekh
   * @returns {void}
   * @memberof PatternBase
   */
  generateTable(): void {
    if (!this.selectedRange) { return; }

    if (!this.selectedRange.multipleRow) {

      this.namedDays = {
        row: [
          { columns: this.generateColumnsForWeek() }
        ]
      };

    } else if (this.selectedRange.multipleRow && (this.selectedRange.startDay === 0 && this.selectedRange.endDay === 6)) {
      this.namedDays = {
        row: [
          {
            columns: this.generateColumns()
          }
        ]
      };
    } else {

      if (this.selectedRange.week === 2) {
        this.namedDays = {
          row: [
            { columns: this.generateColumnsForMultiple(true, this.selectedRange.startDay) },
            { columns: this.generateColumnsForMultiple(false, this.selectedRange.startDay - 1) }
          ]
        };

        const selectable = this.selectableDaysCount();
        const offset = ((this.selectedRange.totalDays - selectable) % 7);

        if (offset < 0) {
          this.namedDays.row[this.namedDays.row.length - 1].columns.forEach(this.disableColumnByEndDay);
        }

      } else {
        this.namedDays = {
          row: [
            { columns: this.generateColumnsForMultiple(true, this.selectedRange.startDay) },
            { columns: this.generateColumnsForMultiple(false, this.selectedRange.startDay - 1) }
          ]
        };
      }
    }
  }

  /**
   * @description When there is only one row and start day is not monday or end day is not sunday
   * @author Nishit Parekh
   * @returns {PatternCell[]}
   * @memberof PatternBase
   */
  generateColumnsForWeek(): PatternCell[] {

    const generatedColumns = [];

    for (let i = 0; i < 7; i++) {

      const obj: PatternCell = new PatternCell();
      obj.columnIndex = i;

      if (i >= this.selectedRange.startDay && i <= this.selectedRange.endDay) {
        obj.disable = false;
      }

      generatedColumns.push(obj);

    }
    return generatedColumns;
  }

  /**
   * @description When start day is monday and end date is Sunday
   * @author Nishit Parekh
   * @returns {PatternCell[]}
   * @memberof PatternBase
   */
  generateColumns(): PatternCell[] {
    const generatedColumns = [];

    for (let i = 0; i < 7; i++) {

      const obj = new PatternCell();
      obj.columnIndex = i;
      obj.disable = false;
      generatedColumns.push(obj);

    }
    return generatedColumns;
  }

  /**
   * @description When start day is not monday or end day is not sunday
   * @author Nishit Parekh
   * @param {*} offset decides left/right cells to disable
   * @param {*} value decides how many cells to disable
   * @returns {PatternCell[]}
   * @memberof PatternBase
   */
  generateColumnsForMultiple(offset, value): PatternCell[] {

    const generatedColumns = [];
    if (offset) {
      for (let i = 0; i < 7; i++) {
        const obj: PatternCell = new PatternCell();
        obj.columnIndex = i;

        if (i >= value) {
          obj.disable = false;
        }
        generatedColumns.push(obj);
      }
    } else {
      for (let i = 0; i < 7; i++) {
        const obj: PatternCell = new PatternCell();
        obj.columnIndex = i;

        if (i <= value) {
          obj.disable = false;
        }
        generatedColumns.push(obj);
      }
    }

    return generatedColumns;
  }

  /**
   * @description calculates count of disble cells
   * @author Nishit Parekh
   * @returns {number} count of disble cells
   * @memberof PatternBase
   */
  selectableDaysCount(): number {
    let count = 0;
    this.namedDays.row.forEach((columnObj) => {
      columnObj.columns.forEach((obj) => {
        if (!obj.disable) {
          count++;
        }
      });
    });

    return count;
  }
  /**
   * @description display Text on cells for namedDays
   * @author Nishit Parekh
   * @memberof PatternBase
   */
  displayTextNamedDays(): void {
    this.namedDays.row.forEach((columnObj, i) => {
      let weekNumber = null;
      columnObj.columns.forEach((obj, j) => {
        if (!obj.disable && obj.displayText === ' ') {
          obj.displayText = this.listOfDays[(i * 7) + j];
        }
        if (this.listWeekNumber[(i * 7) + j] && weekNumber == null) {
          weekNumber = this.listWeekNumber[(i * 7) + j];
        }
      });
      columnObj.columns.forEach((obj) => {
        obj.weekNumber = weekNumber;
      });
    });
  }

  /**
   * @description display Text on cells for Numbered Days
   * @author Nishit Parekh
   * @memberof PatternBase
   */
  displayTextNumberedDays(): void {
    for (let j = 0; j < this.namedDays.row.length; j++) {
      this.namedDays.row[j].columns.forEach((cell, i) => {
        if (j === 4 && cell.columnIndex > 2) {
          if (cell.displayText === ' ') {
            cell.displayText = this.assignDisplayText(cell);
          }
        } else if (!cell.disable && cell.displayText === ' ') {
          cell.displayText = j + 1 + (j * 6) + i;
        }
      });
    }
  }

  assignDisplayText(cell): string {
    let displayText = '';
    switch (cell.columnIndex) {
      case 3:
        displayText = 'L';
        break;
      case 4:
        displayText = '-3';
        break;
      case 5:
        displayText = '-2';
        break;
      case 6:
        displayText = '-1';
        break;
    }
    return displayText;
  }

  /**
   * @description allow to add only that many rows(pattern) which are equal to rows selected in range brick, only for Named days
   * @author Nishit Parekh
   * @memberof PatternBase
   */
  addDaysBlock(): void {
    // visible row count
    const len = this.namedDays.row.length;

    if (this.selectedRange.multipleRow) {

      this.addDaysBlockMultipleRow(len);
    } else {
      if (len < this.selectedRange.week) {
        this.namedDays.row.push({ columns: this.generateColumns() });
      } else {
        this.logHelper.logError(this.userBundle['workspace.error.patternLimit']);
      }
    }
    this.displayTextNamedDays();
  }

  addDaysBlockMultipleRow(len) {
    if (len === (this.selectedRange.week - 1)) {

      this.namedDays.row[len - 1].columns.forEach(obj => obj.disable = false);
      this.namedDays.row.push({ columns: this.generateColumnsForMultiple(false, this.selectedRange.startDay - 1) });

      const selectable = this.selectableDaysCount();
      const offset = ((this.selectedRange.totalDays - selectable) % 7);

      if (offset < 0) {
        this.namedDays.row[len].columns.forEach(this.disableColumnByEndDay);
      }

    } else if (len < this.selectedRange.week) {

      this.namedDays.row[len - 1].columns.forEach(obj => obj.disable = false);
      this.namedDays.row.push({ columns: this.generateColumnsForMultiple(false, this.selectedRange.startDay - 1) });

    } else if (len === this.selectedRange.week) {
      const selectable = this.selectableDaysCount();
      const offset = ((this.selectedRange.totalDays - selectable) % 7);

      if (offset > 0) {
        this.namedDays.row[len - 1].columns.forEach(this.disableColumnByEndDay);
      } else {
        this.logHelper.logError(this.userBundle['workspace.error.patternLimit']);
      }
    }

  }

  setDefaultValueByStartDay = (obj, j) => {
    if (j >= this.selectedRange.startDay) {
      this.setDefaultValuetoCell(obj);
    }
  }

  /**
   * @description  allow to remove 1 pattern at a time, only for Named days
   * @author Nishit Parekh
   * @param {*} index index of the pattern to be removed
   * @memberof PatternBase
   */
  removeDaysBlock(index: number): void {

    const len = this.namedDays.row.length; // visible row count

    if (this.selectedRange.multipleRow) {

      // if index is equal to max possible last row
      this.removeDaysBlockCase(index, len);

    } else if (len > 1) {
      // case of start day
      if (this.selectedDay.rowIndex === index) {
        this.selectedDay = {
          rowIndex: null,
          columnIndex: null
        };
      }
      this.namedDays.row.splice(index, 1);
    }

    this.displayTextNamedDays();
  }

  removeDaysBlockCaseOffsetZero(index: number, len: number) {
    if (this.selectedRange.endDay >= this.selectedRange.startDay) {

      this.namedDays.row[len - 1].columns.forEach((obj, j) => {
        if (j >= this.selectedRange.startDay && j <= this.selectedRange.endDay) {
          this.setDefaultValuetoCell(obj);
        }
      });

    } else {
      this.namedDaysOperation(len);
      this.namedDays.row.splice(index, 1);
    }

    if (this.selectedDay.rowIndex === index) {
      this.selectedDay = {
        rowIndex: null,
        columnIndex: null
      };
    }
  }

  removeDaysBlockCaseOffeseNonZero(index: number, len: number) {
    this.namedDaysOperation(len);
    this.namedDays.row.splice(index, 1);

    if (this.selectedDay.rowIndex === index) {
      this.selectedDay = {
        rowIndex: null,
        columnIndex: null
      };
    }
  }

  removeDaysBlockCase(index: number, len: number) {
    if (((this.selectedRange.week - 1) === index) && (len > 2)) {

      const selectable = this.selectableDaysCount();
      const offset = ((this.selectedRange.totalDays - selectable) % 7);
      // perfect pattern where end date is 1 less than start day
      if (((this.selectedRange.startDay - 1) === this.selectedRange.endDay) && (offset === 0)) {

        this.namedDaysOperation(len);
        this.namedDays.row.splice(index, 1);

      } else if (offset !== 0) {
        this.removeDaysBlockCaseOffeseNonZero(index, len);
      } else if (offset === 0) {
        this.removeDaysBlockCaseOffsetZero(index, len);
      } else {
        this.logHelper.logError(this.userBundle['workspace.error.pattern.rows']);
      }

    } else if (len > 2) {

      this.namedDaysOperation(len);
      this.namedDays.row.splice(index, 1);

      if (this.selectedDay.rowIndex === index) {
        this.selectedDay = {
          rowIndex: null,
          columnIndex: null
        };
      }

    } else if (((this.selectedRange.week - 1) === index) && (len === 2)) {

      this.namedDays.row[len - 1].columns.forEach(this.setDefaultValueByStartDay);
    }
  }

  /**
   * @description set Default Value to Pattern Cell
   * @author Nishit Parekh
   * @param {*} obj cell object
   * @returns {PatternCell}
   * @memberof PatternBase
   */
  setDefaultValuetoCell(obj): PatternCell {
    obj.values = [];
    obj.disable = true;
    obj.currentSelection = false;
    obj.selected = false;
    obj.displayText = ' ';
    return obj;
  }

  /**
   * @description helper function for Named Days
   * @author Nishit Parekh
   * @param {*} len row numberfor calculation
   * @memberof PatternBase
   */
  namedDaysOperation(len): void {
    // 2 becaue index starts with zero and need to make change in previous row.
    this.namedDays.row[len - 2].columns.forEach(this.setDefaultValueByStartDay);
  }

  /**
   * @description check for current selection
   * @author Nishit Parekh
   * @returns {boolean} current selection
   * @memberof PatternBase
   */
  selectedCurrentSelectionCount(): boolean {
    let count = false;
    this.namedDays.row.forEach((columnObj) => {
      columnObj.columns.forEach((obj) => {
        if (obj.currentSelection && obj.displayText !== ' ') {
          count = true;
        }
      });
    });
    return count;
  }

  /**
   * @description In tcase of multiple selection with ctrl or shift key maintains array of selected cells
   * @author Nishit Parekh
   * @memberof PatternBase
   */
  setSelectedMultiple(): void {
    this.selectedMultiple = [];
    this.namedDays.row.forEach((obj, rowIndex) => {
      obj.columns.forEach((cellObj) => {
        if (cellObj.selected && cellObj.currentSelection) {
          this.selectedMultiple.push({
            rowIndex,
            columnIndex: cellObj.columnIndex,
          });
        }
      });
    });
  }

  /**
   * @description apply Default Selection when a new cell is clicked
   * @author Nishit Parekh
   * @param {*} item cell object cicked
   * @param {*} defaultValue default selection i.e. Full day to be applied
   * @param {*} rowIndex rowIndex of pattern
   * @param {*} columnIndex columnIndex of pattern
   * @param {*} overwrite if we need to allow it
   * @memberof PatternBase
   */
  applyDefaultSelection(item, defaultValue, rowIndex, columnIndex, overwrite): void {
    if (this.selectedRange.allowDays === undefined || this.selectedRange.allowDays) {
      // If allow days is not at all set or if set then it must be true
      if (overwrite) {
        item.displayText = 'Fd';
        item.values = [defaultValue];
      }
    } else if (this.selectedRange.allowDayParts === undefined || this.selectedRange.allowDayParts) {
      // If allow dayPart is not at all set or if set then it must be true
      if (overwrite) {
        item.displayText = 'Rg';
        item.values = [defaultValue];
      }
    } else if ((this.selectedRange.allowHours === undefined || this.selectedRange.allowHours) && overwrite) {
      // If allow hours is not at all set or if set then it must be true
      item.displayText = 'Hr';
      item.values = [[this.dropDownData.spans[0], this.dropDownData.spans[1]]];
    }

    item.selected = true;
    item.currentSelection = true;

    this.generateScope(rowIndex, columnIndex);
  }

  /**
   * @description Assign value to dropdown model
   * @author Nishit Parekh
   * @param {*} rowIndex
   * @param {*} columnIndex
   * @memberof PatternBase
   */
  generateScope(rowIndex, columnIndex): void {
    this.namedDays.row[rowIndex].columns[columnIndex].values.forEach((obj, i) => {
      if (Array.isArray(obj)) {
        this.customStartTime[i] = obj[0];
        this.customEndTime[i] = obj[1];
        this.dayPart[i] = null;
      } else {
        this.dayPart[i] = obj;
        this.customStartTime[i] = null;
        this.customEndTime[i] = null;
      }
    });
  }

  /**
   * @description Convert hours array to object
   * @author Nishit Parekh
   * @param {*} obj array to be converted
   * @memberof PatternBase
   */
  convertArraytoCustomObject(obj): void {
    obj.forEach((object, i) => {
      if (Array.isArray(object)) {
        const objToSend = {
          dayPartId: 0,
          startOffset: object[0].startOffset,
          endOffset: object[1].endOffset
        };
        obj[i] = objToSend;
      }
    });
  }

  dropDownChangeForDaypart(index: number, isDayPart: boolean) {
    // For Full Day and Day Part//
    if (this.dayPart.length > 0 && isDayPart) {

      if (this.dayPart[index] && this.customStartTime[index] && this.customEndTime[index]) {
        this.customStartTime[index] = null;
        this.customEndTime[index] = null;
      }

      this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex].values[index] = this.dayPart[index];
      const dayPartArray = this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex].values;
      // made change in _.uniqBy() for SM-4151
      this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex].values = _.uniqBy(_.cloneDeep(dayPartArray), (e: any) => {
        return Array.isArray(e) ? e : e.dayPartId;
      });
      // added for SM-4434, when we select duplicate dayPart, lenght will be different
      if (dayPartArray.length !== this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex].values.length) {
        this.dayPart[index] = null;
      }
      this.customEndTime[index] = null;
      this.customStartTime[index] = null;
    }
  }

  /**
   * @description optraions performed on dropdown change, lik hide duplicate range, remove values of FD dropdown when hours are selected
   * @author Nishit Parekh
   * @param {*} index index of values array
   * @param {boolean} isDayPart if drop down is Hours or range/FD
   * @memberof PatternBase
   */
  dropDownChange(index: number, isDayPart: boolean): void {

    const selectedDay = this.selectedDay;

    this.dropDownChangeForDaypart(index, isDayPart);

    // For Custom Hours//
    if ((this.customStartTime.length > 0 && this.customEndTime.length > 0) && !isDayPart) {
      const tempCustomHours = [];
      if (this.customStartTime[index] != null && this.customEndTime[index] != null) {
        this.dayPart[index] = null;
        tempCustomHours.push(this.customStartTime[index]);
        tempCustomHours.push(this.customEndTime[index]);
        this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex].values[index] = tempCustomHours;
      }

    }
    // moved code for SM-4434
    this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values.forEach(() => {
      if (this.customEndTime[index] == null && this.customStartTime[index] == null && this.dayPart[index] == null) {
        this.customStartTime.splice(index, 1);
        this.customEndTime.splice(index, 1);
        this.dayPart.splice(index, 1);
      }
    });

    const newValue = this.dayPart[index] || this.customEndTime[index] || this.customStartTime[index];
    // const availability = this.checkWhichDayPartExists(this.selectedDay, index);
    // added condition for SM-4522
    if (newValue && newValue.dayPartId === 0 && this.checkCustomHours(this.customStartTime[index], this.customEndTime[index])) {
      this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values.splice(index, 1);
      this.customStartTime[index] = null;
      this.customEndTime[index] = null;
    }

    this.setSelectedMultiple();

    this.setDisplayText(selectedDay);

    if (this.selectedMultiple.length > 0) {

      this.selectedMultiple.forEach((obj) => {

        this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].values =
          _.cloneDeep(this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex].values);
        this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].displayText =
          this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex].displayText;
      });
    }

    // SM-8446
    if (this.uiControl.hourlyPatternFunctionEnabled) {
      this.resetOverlap();
      this.checkIsOverlapped();
    }
  }

  /**
   * @description Check selected datPart is overlapped. if overlappd, set overlapped true. (SM-8446)
   * @author Nikunj Gadhiya
   * @returns {object}
   * @memberof PatternBase
   */
  checkIsOverlapped(): object {
    const overlappedObj = {
      rowIndex: -1,
      columnIndex: -1,
      isOverlapped: false
    };
    const namedDaysObj = _.cloneDeep(this.namedDays);
    namedDaysObj.row.forEach((columnObj) => {
      columnObj.columns.forEach((valueObj) => {
        if (valueObj.values.length > 0) {
          this.convertArraytoCustomObject(valueObj.values);
        }
      });
    });
    namedDaysObj.row.forEach((columnObj, rowIndex) => {
      columnObj.columns.forEach((valueObj, columnIndex) => {
        if (valueObj.values.length > 0) {
          valueObj.values.forEach((dayPart, valueIndex1) => {
            valueObj.values.forEach((obj, valueIndex2) => {
              if (valueIndex1 !== valueIndex2) {
                if ((dayPart.startOffset <= obj.startOffset && dayPart.endOffset >= obj.startOffset)
                  || (dayPart.startOffset <= obj.endOffset && dayPart.endOffset >= obj.endOffset)) {
                  this.namedDays.row[rowIndex].columns[columnIndex].values[valueIndex1].overlapped = true;
                  this.namedDays.row[rowIndex].columns[columnIndex].values[valueIndex2].overlapped = true;

                  if (!overlappedObj.isOverlapped) {
                    overlappedObj.rowIndex = rowIndex;
                    overlappedObj.columnIndex = valueObj.columnIndex;
                  }
                  overlappedObj.isOverlapped = true;
                }
              }
            });
          });
        }
      });
    });
    if (overlappedObj.isOverlapped) {
      this.isPatternOverlapped = true;
    }
    return overlappedObj;
  }

  /**
   * @description delete overlapped from namedDays and reset it. (SM-8446)
   * @author Nikunj Gadhiya
   * @memberof PatternBase
   */
  resetOverlap() {
    this.isPatternOverlapped = false;
    this.namedDays.row.forEach((columnObj) => {
      columnObj.columns.forEach((obj) => {
        if (obj.values.length > 0) {
          obj.values.forEach((object) => {
            delete object.overlapped;
          });
        }
      });
    });
  }

  /**
   * @description check Which DayPart Exists
   * @author Nishit Parekh
   * @param {*} selectedDay
   * @param {*} index
   * @returns {boolean}
   * @memberof PatternBase
   */
  checkWhichDayPartExists(selectedDay, index): boolean {

    const dayPartIds = [];
    let allowed = true;

    this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values.forEach((obj) => {

      if (obj && !Array.isArray(obj)) {

        if (dayPartIds.indexOf(obj.dayPartId) === -1) {
          dayPartIds.push(obj.dayPartId);
        } else {
          this.checkDayParts(selectedDay, index);
          allowed = false;
          return allowed;
        }
      }

    });

    if (dayPartIds.indexOf(-1) >= 0) {
      allowed = false;
    }
    return allowed;

  }

  /**
   * @description On click of day or change in daypart, range, hour it will change change Display Text
   * @author Nishit Parekh
   * @param {*} selectedDay
   * @memberof PatternBase
   */
  setDisplayText(selectedDay): void {

    const dayPartCount = {
      fullDay: 0,
      dayPart: 0,
      Hours: 0
    };

    this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values.forEach((obj) => {
      if (obj instanceof Object && obj.constructor === Object && obj.dayPartId != null) {

        if (obj.dayPartId === -1) {
          dayPartCount.fullDay++;
        } else if (obj.dayPartId > 0) {
          dayPartCount.dayPart++;
        }

      } else if (Array.isArray(obj)) {

        if (obj[0].dayPartId === 0 && obj[1].dayPartId === 0) {
          dayPartCount.Hours++;
        }
      } else {
        console.log('typeof obj is not defined');
      }
    });

    this.setDisplayTextNamedDays(dayPartCount, selectedDay);
  }

  setDisplayTextNamedDays(dayPartCount, selectedDay) {
    if (dayPartCount.fullDay > 0) {
      this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].displayText = 'Fd';
    } else if (dayPartCount.dayPart > 0 && dayPartCount.Hours === 0) {
      this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].displayText = 'Rg';
    } else if (dayPartCount.Hours > 0 && dayPartCount.dayPart === 0) {
      this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].displayText = 'Hr';
    } else if (dayPartCount.Hours > 0 && dayPartCount.dayPart > 0) {
      this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].displayText = 'RH';
    } else {
      this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].displayText = ' ';
    }
  }
  /**
   * @description set  null to daypart
   * @author Nishit Parekh
   * @param {*} selectedDay
   * @param {*} index index of values arrau to be manupilated
   * @memberof PatternBase
   */
  checkDayParts(selectedDay, index): void {
    if (Array.isArray(this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values[index])) {
      this.customStartTime[index] = null;
      this.customEndTime[index] = null;
    } else {
      this.dayPart[index] = null;
    }
    this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values.splice(index, 1);
  }

  /**
   * @description check if same custom hours present for selected day when selecting hours
   * @author Nishit Parekh
   * @param {*} start start hours selected
   * @param {*} end end hours selected
   * @returns {boolean}
   * @memberof PatternBase
   */
  checkCustomHours(start, end): boolean {
    let count = 0;
    this.customStartTime.forEach((obj, i) => {
      if ((obj === start) && (this.customEndTime[i] === end)) {
        count++;
      }
    });
    if (count > 1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description When we change pattern type, need to maintain the state and reset some values
   * @author Nishit Parekh
   * @param {*} selection
   * @memberof PatternBase
   */
  onPatternTypeChange(selection): void {

    this.patternTypeData[parseInt(selection, 10) - 1] = this.namedDays;
    this.namedDays = null;
    this.namedDays = this.patternTypeData[this.patternObj.patternType - 1];

    this.toggleSelectedDay[selection - 1] = this.selectedDay;
    this.currentSelectionData[selection - 1] = this.dayPart;

    this.selectedDay = {
      rowIndex: null,
      columnIndex: null
    };
    if (this.patternObj.patternType === 2) {
      this.stateService.setAnyValue('NumberedDays.customStartTime', this.customStartTime);
      this.stateService.setAnyValue('NumberedDays.customEndTime', this.customEndTime);
    } else {
      this.stateService.setAnyValue('namedDays.customStartTime', this.customStartTime);
      this.stateService.setAnyValue('namedDays.customEndTime', this.customEndTime);
    }

    this.dayPart = [];
    this.customStartTime = [];
    this.customEndTime = [];

    if (this.patternObj.patternType === 1) {

      if (!this.patternTypeData[this.patternObj.patternType - 1]) {
        this.generateTable();
      }

      if (this.selectedCurrentSelectionCount()) {
        this.selectedDay = this.toggleSelectedDay[this.patternObj.patternType - 1];
        this.dayPart = this.currentSelectionData[this.patternObj.patternType - 1];
        this.customStartTime = this.stateService.getAnyValue('NumberedDays.customStartTime');
        this.customEndTime = this.stateService.getAnyValue('NumberedDays.customEndTime');
        this.setSelectedMultiple();
      }
      this.displayTextNamedDays();

    } else if (this.patternObj.patternType === 2) {

      if (!this.patternTypeData[this.patternObj.patternType - 1]) {
        this.namedDays = {
          row: [
            { columns: this.generateColumns() },
            { columns: this.generateColumns() },
            { columns: this.generateColumns() },
            { columns: this.generateColumns() },
            { columns: this.generateColumns() }
          ]
        };
      }

      if (this.selectedCurrentSelectionCount()) {
        this.selectedDay = this.toggleSelectedDay[this.patternObj.patternType - 1];
        this.dayPart = this.currentSelectionData[this.patternObj.patternType - 1];
        this.customStartTime = this.stateService.getAnyValue('namedDays.customStartTime');
        this.customEndTime = this.stateService.getAnyValue('namedDays.customEndTime');
        this.setSelectedMultiple();
      }
      this.displayTextNumberedDays();
    }
  }

  /**
   * @description when we click on cell this fn will be called
   * @author Nishit Parekh
   * @param {*} rowIndex row index of Pattern bric matrix
   * @param {*} columnIndex column index of Pattern bric matrix
   * @param {*} event event object of cell
   * @memberof PatternBase
   */
  selectDay(rowIndex: number, columnIndex: number, event): void {

    this.dayPart = [];
    this.customStartTime = [];
    this.customEndTime = [];
    let currentSelection: boolean;
    let tempCurrentSelection: PatternCell[] = [];
    this.patternObj.hoursSelection = 'all';

    if (event.ctrlKey) {
      this.setSelectedMultiple();
      if (!this.namedDays.row[rowIndex].columns[columnIndex].currentSelection) {
        this.selectedMultiple.push({
          rowIndex,
          columnIndex
        });
      }
      currentSelection = this.selectedCurrentSelectionCount();
    }
    if (this.namedDays.row.length > this.selectedDay.rowIndex &&
      (this.selectedDay.rowIndex != null && this.selectedDay.columnIndex != null)) {
      tempCurrentSelection = this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex];
    }

    this.selectedDay = {
      rowIndex,
      columnIndex
    };

    if (event.ctrlKey && !event.shiftKey) {

      this.onCtrlPress(rowIndex, columnIndex, currentSelection, tempCurrentSelection);

    } else if (event.ctrlKey && event.shiftKey) {

      this.onCtrlShiftPress(rowIndex, columnIndex);

      this.applyDefaultSelection(this.namedDays.row[rowIndex].columns[columnIndex],
        this.defaultSelection, rowIndex, columnIndex, true);
    } else {
      this.normalSelectDay(rowIndex, columnIndex);
    }

  }

  normalSelectDay(rowIndex: number, columnIndex: number) {
    this.selectedMultiple = [];
    if (!this.namedDays.row[rowIndex].columns[columnIndex].currentSelection) {
      if (this.namedDays.row[rowIndex].columns[columnIndex].values.length > 0) {
        // The value for the day is Already Selected
        this.namedDays.row[rowIndex].columns[columnIndex].values.forEach((obj, i) => {
          // Case of Full Day and dayPart//
          if (obj.length === undefined) {
            this.dayPart[i] = this.dropDownData.dayPart.find(e => e.dayPartId === obj.dayPartId);
            this.customStartTime[i] = null;
            this.customEndTime[i] = null;
            // Case of Custom Hours
          } else if (obj.length > 0) {
            this.customStartTime[i] = this.dropDownData.spans.find(e => e.spanId === obj[0].spanId);
            this.customEndTime[i] = this.dropDownData.spans.find(e => obj[1] && e.spanId === obj[1].spanId);
            this.dayPart[i] = null;
          }
        });

      } else {
        // New cell selected
        this.applyDefaultSelection(this.namedDays.row[rowIndex].columns[columnIndex], this.defaultSelection,
          rowIndex, columnIndex, true);
        this.dayPart[0] = this.defaultSelection;
      }

      this.selectedCurrentSelection();
      this.namedDays.row[rowIndex].columns[columnIndex].currentSelection = true;
    } else {
      this.selectedCurrentSelection();
      this.selectedMultiple = [];
      this.selectedDay = {
        rowIndex: null,
        columnIndex: null
      };
    }
  }

  getcurrentSelectedDayOnCtrlShiftPress() {
    const currentSelectedDay = {
      rowIndex: null,
      columnObj: null
    };
    let getcurrentSelectedDay = false;
    this.namedDays.row.forEach((columnObj, i) => {
      if (!getcurrentSelectedDay) {
        columnObj.columns.forEach((obj) => {
          if (!getcurrentSelectedDay && obj.selected && obj.currentSelection) {
            currentSelectedDay.rowIndex = i;
            currentSelectedDay.columnObj = obj;
            getcurrentSelectedDay = true;
          }
        });
      }
    });
    return currentSelectedDay;
  }

  onCtrlShiftPress(rowIndex: number, columnIndex: number) {
    // Step1 : get the current seleced
    const currentSelectedDay = this.getcurrentSelectedDayOnCtrlShiftPress();

    // rowIndex, columnIndex
    const clickedDay = {
      rowIndex,
      columnObj: this.namedDays.row[rowIndex].columns[columnIndex]
    };
    // Step 2 : Check if Current Selection Available or Not
    // => Based On Row Diifercence  Loop will be executed In reverse or forward case.
    if ((currentSelectedDay && parseInt(currentSelectedDay.rowIndex, 10) >= 0 &&
      currentSelectedDay.columnObj) && (clickedDay && parseInt(clickedDay.rowIndex.toString(), 10) >= 0 && clickedDay.columnObj)) {

      //    case :
      // =>  clickedDayRow >
      if (parseInt(clickedDay.rowIndex.toString(), 10) > parseInt(currentSelectedDay.rowIndex, 10)) {
        // Clicked Day is After currentSelected Week
        this.onCtrlShiftPressAfterCurrentSelectedWeek(currentSelectedDay, clickedDay);

      } else if (parseInt(clickedDay.rowIndex.toString(), 10) < parseInt(currentSelectedDay.rowIndex, 10)) {

        // Clicked Day is Before currentSelected Week
        this.onCtrlShiftPressBeforeCurrentSelectedWeek(currentSelectedDay, clickedDay);
      } else {

        // Both are in same Weeks
        this.onCtrlShiftPressBoothWeekSame(currentSelectedDay, clickedDay, rowIndex);
      }
    }
  }

  onCtrlShiftPressBoothWeekSame(currentSelectedDay, clickedDay, rowIndex) {
    let columnStartIndex = 0;
    let columnEndIndex = this.namedDays.row[rowIndex].columns.length - 1;

    if (parseInt(clickedDay.columnObj.columnIndex, 10) > parseInt(currentSelectedDay.columnObj.columnIndex, 10)) {
      columnStartIndex = parseInt(currentSelectedDay.columnObj.columnIndex, 10);
      columnEndIndex = parseInt(clickedDay.columnObj.columnIndex, 10);
    } else if (parseInt(clickedDay.columnObj.columnIndex, 10) < parseInt(currentSelectedDay.columnObj.columnIndex, 10)) {
      columnStartIndex = parseInt(clickedDay.columnObj.columnIndex, 10);
      columnEndIndex = parseInt(currentSelectedDay.columnObj.columnIndex, 10);
    }
    for (let j = columnStartIndex; j <= columnEndIndex; j++) {
      this.applyDefaultSelection(this.namedDays.row[rowIndex].columns[j], this.defaultSelection,
        rowIndex, j, true);
      // NOTE: columnStartIndex was i earlier which was giving lint error
      if (!this.checkIsAddedToselectedMultiple(columnStartIndex, j)) {
        this.selectedMultiple.push({ columnIndex: j, rowIndex });
      }
    }
  }

  onCtrlShiftPressBeforeCurrentSelectedWeek(currentSelectedDay, clickedDay) {
    for (let i = parseInt(clickedDay.rowIndex.toString(), 10); i <= parseInt(currentSelectedDay.rowIndex, 10); i++) {
      let columnStartIndex = 0;
      let columnEndIndex = this.namedDays.row[i].columns.length - 1;

      if (i === parseInt(clickedDay.rowIndex.toString(), 10)) {
        columnStartIndex = parseInt(clickedDay.columnObj.columnIndex, 10);
      } else if (i === parseInt(currentSelectedDay.rowIndex, 10)) {
        columnEndIndex = parseInt(currentSelectedDay.columnObj.columnIndex, 10);
      }
      for (let j = columnStartIndex; j <= columnEndIndex; j++) {
        this.applyDefaultSelection(this.namedDays.row[i].columns[j], this.defaultSelection, i, j, true);
        if (!this.checkIsAddedToselectedMultiple(i, j)) {
          this.selectedMultiple.push({ columnIndex: j, rowIndex: i });
        }
      }
    }
  }

  onCtrlShiftPressAfterCurrentSelectedWeek(currentSelectedDay, clickedDay) {
    for (let i = parseInt(currentSelectedDay.rowIndex, 10); i <= parseInt(clickedDay.rowIndex.toString(), 10); i++) {
      let columnStartIndex = 0;
      let columnEndIndex = this.namedDays.row[i].columns.length - 1;
      if (i === parseInt(currentSelectedDay.rowIndex, 10)) {
        columnStartIndex = parseInt(currentSelectedDay.columnObj.columnIndex, 10);
      } else if (i === parseInt(clickedDay.rowIndex.toString(), 10)) {
        columnEndIndex = parseInt(clickedDay.columnObj.columnIndex, 10);
      }
      for (let j = columnStartIndex; j <= columnEndIndex; j++) {
        this.applyDefaultSelection(this.namedDays.row[i].columns[j], this.defaultSelection, i, j, true);
        if (!this.checkIsAddedToselectedMultiple(i, j)) {
          this.selectedMultiple.push({ columnIndex: j, rowIndex: i });
        }
      }

    }
  }

  onCtrlPress(rowIndex: number, columnIndex: number, currentSelection, tempCurrentSelection) {
    if (!this.namedDays.row[rowIndex].columns[columnIndex].currentSelection) {

      this.onCtrlPressCurrentSelectionNotDefined(rowIndex, columnIndex, currentSelection, tempCurrentSelection);

    } else {

      this.onCtrlPressCurrentSelection(rowIndex, columnIndex);

    }
  }

  onCtrlPressCurrentSelection(rowIndex: number, columnIndex: number) {
    if (!this.differentDisplayTextavailable()) {
      const index = this.indexCurrentSelectionList({ columnIndex, rowIndex });
      if (index >= 0) {
        this.selectedMultiple.splice(index, 1);
        const arrLength = this.selectedMultiple.length;
        if (arrLength > 0) {
          this.generateScope(this.selectedMultiple[arrLength - 1].rowIndex, this.selectedMultiple[arrLength - 1].columnIndex);
          this.selectedDay = {
            rowIndex: this.selectedMultiple[arrLength - 1].rowIndex,
            columnIndex: this.selectedMultiple[arrLength - 1].columnIndex
          };
          this.namedDays.row[rowIndex].columns[columnIndex].currentSelection = false;
        } else {
          this.namedDays.row[rowIndex].columns[columnIndex].currentSelection = false;
        }
      }
    } else {
      this.namedDays.row[rowIndex].columns[columnIndex].currentSelection = false;
    }
  }

  onCtrlPressCurrentSelectionNotDefined(rowIndex: number, columnIndex: number, currentSelection, tempCurrentSelection) {
    if (currentSelection && tempCurrentSelection && tempCurrentSelection.displayText === 'Fd') {

      if ((parseInt(this.namedDays.row[rowIndex].columns[columnIndex].displayText, 10) > 0 ||
        this.namedDays.row[rowIndex].columns[columnIndex].displayText === 'Fd') && !(this.currentSelectionNoDisplayText())) {
        this.applyDefaultSelection(this.namedDays.row[rowIndex].columns[columnIndex],
          this.defaultSelection, rowIndex, columnIndex, true);
      } else {
        this.namedDays.row[rowIndex].columns[columnIndex].currentSelection = true;
        this.namedDays.row[rowIndex].columns[columnIndex].selected = true;
        this.namedDays.row[rowIndex].columns[columnIndex].displayText =
          (this.namedDays.row[rowIndex].columns[columnIndex].displayText === ' ' ? 'Fd' :
            this.namedDays.row[rowIndex].columns[columnIndex].displayText);
      }
    } else {
      this.namedDays.row[rowIndex].columns[columnIndex].displayText =
        (this.namedDays.row[rowIndex].columns[columnIndex].displayText === ' ' ? 'Fd' :
          this.namedDays.row[rowIndex].columns[columnIndex].displayText); // For SBRICS-757, SBRICS-1527
      this.applyDefaultSelection(this.namedDays.row[rowIndex].columns[columnIndex], this.defaultSelection,
        rowIndex, columnIndex, false);
    }
  }
  /**
   * @description remove current selection for all cell
   * @author Nishit Parekh
   * @memberof PatternBase
   */
  selectedCurrentSelection(): void {
    this.namedDays.row.forEach((columnObj) => {
      columnObj.columns.forEach((obj) => {
        if (obj.currentSelection) {
          obj.currentSelection = false;
        }
      });
    });
  }

  /**
   * @description current Selection with No Display Text
   * @author Nishit Parekh
   * @returns {boolean}
   * @memberof PatternBase
   */
  currentSelectionNoDisplayText(): boolean {
    let count = 0;
    this.namedDays.row.forEach((columnObj) => {
      columnObj.columns.forEach((obj) => {
        if (obj.currentSelection && (parseInt(obj.displayText, 10) > 0)) {
          count++;
        }
      });
    });

    if (count === 0) {
      return false;
    } else if (count > 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description
   * @author Nishit Parekh
   * @param {*} obj
   * @returns {boolean}
   * @memberof PatternBase
   */
  currentSelectionList(obj): boolean {

    for (let i = 0; i <= this.selectedMultiple.length; i++) {
      if (this.selectedMultiple[i] && this.selectedMultiple[i].columnIndex === obj.columnIndex &&
        this.selectedMultiple[i].rowIndex === obj.rowIndex) {
        return false;
      }
    }
    return true;
  }

  /**
   * @description return index of current selection
   * @author Nishit Parekh
   * @param {*} obj
   * @returns {number}
   * @memberof PatternBase
   */
  indexCurrentSelectionList(obj): number {

    for (let i = 0; i <= this.selectedMultiple.length; i++) {
      if (this.selectedMultiple[i] && this.selectedMultiple[i].columnIndex === obj.columnIndex &&
        this.selectedMultiple[i].rowIndex === obj.rowIndex) {
        return i;
      }
    }
    return -1;
  }

  /**
   * @description check if different Display Text available for the cell
   * @author Nishit Parekh
   * @returns {boolean}
   * @memberof PatternBase
   */
  differentDisplayTextavailable(): boolean {

    let offset = false;
    let txt;
    this.selectedMultiple.forEach((obj, i) => {
      if (i === 0) {
        txt = this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].displayText;
      }

      if (this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].displayText !== txt) {
        offset = true;
      }
    });

    return offset;
  }

  /**
   * @description Add new option to select, FD/RG/RH/H for the selected cell/s
   * @author Nishit Parekh
   * @param {*} selectedDay
   * @memberof PatternBase
   */
  addDaypart(selectedDay): void {
    if (!this.checkIfCurrentSelection()) {
      selectedDay.columnIndex = this.selectedDay.columnIndex = null;
      selectedDay.rowIndex = this.selectedDay.rowIndex = null;
    }

    if (selectedDay && selectedDay.rowIndex != null && selectedDay.columnIndex != null) {

      const availability = this.checkWhichDayPartExists(selectedDay,
        this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values.length - 1);

      if (availability) {
        if (this.patternObj.allowHours && !this.patternObj.allowDayParts) {
          // This is to handle product's pattern brick
          this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values.push(
            [this.dropDownData.spans[0], this.dropDownData.spans[0]]
          );
          this.customStartTime[0] = this.dropDownData.spans[0];
          this.customEndTime[0] = this.dropDownData.spans[0];
        } else {
          this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values.push(this.defaultSelection);
        }
        this.addDaypartSelectedMultiple(selectedDay);
      } else {
        this.logHelper.logError(this.userBundle['workspace.error.pattern.dayPartAlreadySelected']);
      }
    }

    if (this.checkIfCurrentSelection()) {
      this.setDisplayText(selectedDay);
    }
    this.patternObj.hoursSelection = 'all';

    // SM-8446
    if (this.uiControl.hourlyPatternFunctionEnabled) {
      this.resetOverlap();
      this.checkIsOverlapped();
    }
  }

  addDaypartSelectedMultiple(selectedDay) {
    this.dayPart[this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values.length - 1] = this.defaultSelection;
    this.setSelectedMultiple();
    if (this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values.length > 0
      && this.selectedMultiple.length === 0) {
      this.dayPart[this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values.length - 1] = this.defaultSelection;

      this.setDisplayText(selectedDay);
    } else if (this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values.length > 0 &&
      this.selectedMultiple.length > 0 && this.selectedMultiple.length > 0) {
      const singleMultiple = this.differentDisplayTextavailable();
      this.selectedMultiple.forEach((obj) => {
        if (singleMultiple && !this.patternObj.allowHours && this.patternObj.allowDays && this.patternObj.allowDayParts) {
          this.dayPart[0] = this.defaultSelection;
          this.customEndTime[0] = null;
          this.customStartTime[0] = null;
          this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].values = [this.defaultSelection];
        } else if (singleMultiple && this.patternObj.allowHours && !this.patternObj.allowDays && !this.patternObj.allowDayParts
          && (obj.columnIndex !== selectedDay.columnIndex && obj.rowIndex !== selectedDay.rowIndex)) {
          this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].values.push([this.dropDownData.spans[0], this.dropDownData.spans[0]]);
          this.customStartTime[0] = this.dropDownData.spans[0];
          this.customEndTime[0] = this.dropDownData.spans[0];
          this.dayPart = [];
        } else {
          this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].values =
            this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values;
        }

        if (this.patternObj.allowHours && !this.patternObj.allowDayParts && !this.patternObj.allowDays) {
          this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].displayText = 'Hr';
        } else if (this.patternObj.allowDayParts && !this.patternObj.allowDays) {
          this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].displayText = 'Rg';
        } else {
          this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].displayText = 'Fd';
        }
      });
    }
  }
  /**
   * @description check if any cell with current selection
   * @author Nishit Parekh
   * @returns {boolean}
   * @memberof PatternBase
   */
  checkIfCurrentSelection(): boolean {
    let current = false;
    this.namedDays.row.forEach((columnObj) => {
      columnObj.columns.forEach((obj) => {
        if (obj.currentSelection) {
          current = true;
        }
      });
    });
    return current;
  }

  /**
   * @description remove option FD/RG/RH/H for the selected cell/s
   * @author Nishit Parekh
   * @param {*} selectedDay
   * @param {*} index
   * @memberof PatternBase
   */
  removeDaypart(selectedDay, index): void {

    if (this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values.length === 1) {
      this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values = [];
      this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].currentSelection = false;
      this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].selected = false;

      this.setSelectedMultiple();

      this.selectedMultiple.forEach((obj) => {
        this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].values =
          _.cloneDeep(this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values);
        this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].currentSelection = false;
        this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].selected = false;
      });

      if (this.patternObj.patternType === 1) {
        this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].displayText = ' ';

        this.selectedMultiple.forEach((obj) => {
          this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].displayText = ' ';
        });
        this.selectedMultiple = [];
        this.displayTextNamedDays();

      } else {
        this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].displayText = ' ';
        this.selectedMultiple.forEach((obj) => {
          this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].displayText = ' ';
        });
        this.displayTextNumberedDays();
      }

      // Clear the ng-models//
      this.selectedDay = {
        rowIndex: null,
        columnIndex: null
      };
      this.dayPart = [];
      this.customStartTime = [];
      this.customEndTime = [];

    } else {
      // Remove from actual array and remove from ng-models binded to View//
      // this.namedDays.row = tempNameDays;

      this.dayPart.splice(index, 1);
      this.customStartTime.splice(index, 1);
      this.customEndTime.splice(index, 1);
      this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values.splice(index, 1);
      this.setDisplayText(selectedDay);
      this.setSelectedMultiple();
      if (this.selectedMultiple.length > 0) {
        this.selectedMultiple.forEach((obj) => {
          this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].values =
            _.cloneDeep(this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].values);
          this.namedDays.row[obj.rowIndex].columns[obj.columnIndex].displayText =
            this.namedDays.row[selectedDay.rowIndex].columns[selectedDay.columnIndex].displayText;
        });
      }
    }
    this.patternObj.hoursSelection = 'all';

    // SM-8446
    if (this.uiControl.hourlyPatternFunctionEnabled) {
      this.resetOverlap();
      this.checkIsOverlapped();
    }
  }

  /**
   * @description check if click cell present in selectedMultiple
   * @author Nishit Parekh
   * @param {*} rowIndex row index of Pattern bric matrix
   * @param {*} columnIndex column index of Pattern bric matrix
   * @returns {boolean}
   * @memberof PatternBase
   */
  checkIsAddedToselectedMultiple(rowIndex, columnIndex): boolean {
    let isExist = false;
    if (rowIndex >= 0 && columnIndex >= 0 && this.selectedMultiple.length > 0) {
      this.selectedMultiple.forEach((obj) => {
        if (obj.rowIndex === rowIndex && obj.columnIndex === columnIndex) {
          isExist = true;
        }
      });
    }
    return isExist;
  }

  /**
   * @description This function will format the selected data as requested by service
   * @author Nishit Parekh
   * @param {*} rowObj Pattern bric matrix
   * @memberof PatternBase
   */
  convertForService(rowObj): void {
    let dataToSend = [];
    let patternLen = 1;
    if (this.patternObj.patternType === 1) {
      rowObj.forEach((columnObj, i) => {
        columnObj.columns.forEach((obj, j) => {
          if (obj.values.length > 0) {
            this.convertArraytoCustomObject(obj.values);
            dataToSend.push({ dayId: i + (i * 6) + j, dayPart: obj.values });
          }
          if (!obj.disable) {
            patternLen++;
          }
        });
      });

      this.patternObj.patternLength = Math.ceil((patternLen - 1) / 7);
      // Formula for Pattern Length is Math.ceil((Number of Selectable days) / 7),  For Numbered Days it will be always 1

    } else if (this.patternObj.patternType === 2) {

      dataToSend = this.convertForServicepatternType2(rowObj, dataToSend);

      this.patternObj.patternLength = patternLen;

    } else {
      console.log('Issue with pattern type selection');
    }

    this.patternObj.pattern = null;
    this.patternObj.pattern = dataToSend;
  }

  convertForServicepatternType2(rowObj, dataToSend: any[]): any[] {
    rowObj.forEach((columnObj, i) => {
      columnObj.columns.forEach((obj, j) => {
        if (obj.values.length > 0) {
          const key = ((i * 7) + (j + 1));
          if ((key > 0) && (key < 32)) {
            dataToSend.push({ dayId: key, dayPart: obj.values });
          } else if (key >= 32 && key <= 35) {
            switch (key) {
              case 32:
                dataToSend.push({ dayId: -4, dayPart: obj.values });
                break;

              case 33:
                dataToSend.push({ dayId: -3, dayPart: obj.values });
                break;

              case 34:
                dataToSend.push({ dayId: -2, dayPart: obj.values });
                break;

              case 35:
                dataToSend.push({ dayId: -1, dayPart: obj.values });
                break;
            }
          }
          this.convertArraytoCustomObject(obj.values);
        }
      });
    });
    return dataToSend;
  }
  /**
   * @description It will remove selected day/daypart/hours From Selected Days
   * @author Nishit Parekh
   */
  removeValuesFromSelectedDays(): void {
    // clear selected day/s values
    if (this.selectedMultiple.length) {
      this.selectedMultiple.forEach(e => this.namedDays.row[e.rowIndex].columns[e.columnIndex].values = []);
    } else {
      this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex].values = [];
    }
    // clear the dropdown data
    this.dayPart = [];
    this.customStartTime = [];
    this.customEndTime = [];
  }

  addOddHours() {
    let index = 0;
    this.dropDownData.spans.forEach((span) => {
      if ((parseInt(span.startLabel, 10) % 2) !== 0) {
        this.customEndTime[index] = span;
        this.customStartTime[index] = span;
        if (this.selectedMultiple.length) {
          this.selectedMultiple.forEach((e) => {
            this.namedDays.row[e.rowIndex].columns[e.columnIndex].values.push([span, span]);
            this.namedDays.row[e.rowIndex].columns[e.columnIndex].displayText = 'Hr';
          });
        } else {
          this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex].values.push([span, span]);
        }
        index++;
      }
    });
    this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex].displayText = 'Hr';
  }

  addEvenHours() {
    let index = 0;
    this.dropDownData.spans.forEach((span) => {
      if ((parseInt(span.startLabel, 10) % 2) === 0) {
        this.customEndTime[index] = span;
        this.customStartTime[index] = span;
        // case of multiple selection
        if (this.selectedMultiple.length) {
          this.selectedMultiple.forEach((e) => {
            this.namedDays.row[e.rowIndex].columns[e.columnIndex].values.push([span, span]);
            this.namedDays.row[e.rowIndex].columns[e.columnIndex].displayText = 'Hr';
          });
        } else {
          this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex].values.push([span, span]);
        }
        index++;
      }
    });
    this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex].displayText = 'Hr';
  }

  /**
   * @description logic for selection of odd/even hours
   * @author Nishit Parekh
   */
  addOddEvenHours(): void {
    // Remove the previous Selection //
    // this.hoursSelection = hoursSelection;

    if (this.selectedDay && this.selectedDay.rowIndex == null && this.selectedDay.columnIndex == null) {
      this.logHelper.logError(this.userBundle['workspace.incharge.pattern.calender.label.title']);
      return;
    }

    this.removeValuesFromSelectedDays();
    switch (this.patternObj.hoursSelection) {
      case 'even':
        this.addEvenHours();
        break;

      case 'odd':
        this.addOddHours();
        break;

      default:
        // SM-6601
        if (this.selectedMultiple.length) {
          this.selectedMultiple.forEach((e) => {
            this.applyDefaultSelection(this.namedDays.row[e.rowIndex].columns[e.columnIndex], this.defaultSelection,
              this.selectedDay.rowIndex, this.selectedDay.columnIndex, true);
          });
        } else {
          this.applyDefaultSelection(this.namedDays.row[this.selectedDay.rowIndex].columns[this.selectedDay.columnIndex], this.defaultSelection,
            this.selectedDay.rowIndex, this.selectedDay.columnIndex, true);
        }
        this.dayPart[0] = this.defaultSelection;
        break;
    }

  }

  trackByIndex(index, data) {
    return index;
  }

  trackByItem(index, item) {
    return item;
  }

}
