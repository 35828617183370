import { Injectable } from '@angular/core';
import { LogHelperService } from '../../services/log-helper.service';
import { ConcertinaDataService } from './concertina-data.service';
import { CartService } from '../../../geo-map/cart.service';
import { FrameFilterType, FrameStatusMaskEnum, ExtraInfoMaskEnum } from '../../../geo-map/status.enum';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { GeoMapService } from '../../../geo-map/geo-map.service';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from '../../services/loader.service';
import * as _ from 'lodash';
import * as Konva from 'konva';
import * as moment from 'moment';
import { SwapZoneService } from '../../components/swap-zone';
import { AppNameEnum, EnvironmentId } from '../../enum';
import { Booking, Frame, SwapData, DynamicKeyObjectData, DeletedSelection, SelectionData, InitialConfigModel, SystemFlags } from '../../../models';

import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/switchMap';
import { Subscription } from 'rxjs';
import { DataShareService } from '../../services/data-share.service';
import { GLOBAL } from '../../utils/app.constant';
import { GoogleAnalyticsEvents } from '../../../GoogleAnalytics/GoogleAnalyticsEvent';
import { SwapFrame, SwapCampaign, SwapBookingDetails } from '../../../models/vp-swap';
import { merge } from 'rxjs';
import { mathRandomNumber } from '../../utils/mathRandom';

@Injectable()
export class ConcertinaCanvasService {
  multiSelectionForSwap: {
    [key: string]: SwapData // Here the key is frame id
  } = {};
  controlPressed = false;
  shiftPressed = false;
  private horizontalNavigationSub = new Subject();

  tabValue: number;
  showFI = false;
  scrollSubscription: Subscription;
  clickSubscription: Subscription;
  wheelEventListener;
  anims = [];
  userBundle = {};
  frameDetailsEnabled = false;
  frameSelectionEnabled = false;
  drilledDownKeys = [];
  standardSpacingForExpansion = 2.5;
  /**
   * @description Holds current position for horizontal scrolling
   * @memberof ConcertinaCanvasService
   */
  currentConcertinaPos = {
    x: 0,
    y: 0
  };
  previousDataX = 0;
  delta = 21;
  frameSearchKey = '';
  cachedRowCell: any;
  tempConcertinaData = {};
  filteredKeysForFrame = [];
  hasNewSelection = false;
  tempSelectionsAfterCart = [];

  stageThreshold = 25;
  colThreshold = 4;
  // Decided to destroy selection groups on collapse
  gridCollapsed = false;

  startRowIndex = 0;
  endRowIndex = 0;
  startColIndex = 0;
  endColIndex = 0;
  navigationClicksCount = 0;
  previousScrollTop = 0;

  parentCols = [];
  parentRows = [];
  containerHeight = '0px';
  readOnlyForSwap = false;

  /*
  * ngbModal options
  * @type {NgbModalOptions}@memberof GeoMapComponent
  */
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'sm'
  };

  /**
   * @description Holds the col keys & position of currently displayed in concertina
   * @memberof ConcertinaCanvasService
   */
  currentConcertinaCols = [];

  /**
   * @description Holds the row keys & position of currently displayed in concertina
   * @memberof ConcertinaCanvasService
   */
  currentConcertinaRows = [];

  deletedMarkedSelections: DynamicKeyObjectData<DeletedSelection> = {};

  initialConfig: InitialConfigModel;

  /**
   * @description Types of frame filters
   * @memberof ConcertinaCanvasService
   */
  frameFilterType = FrameFilterType;

  /**
   * @description Main stage holding xValues data
   * @type {*}
   * @memberof ConcertinaCanvasService
   */
  stage: Konva.Stage;
  loadingStage: Konva.Stage;
  loadingLayer: Konva.Layer;
  /**
   * @description Header stage holds the xGroups headers data
   * @type {*}
   * @memberof ConcertinaCanvasService
   */
  headerStage: Konva.Stage;

  /**
   * @description Header layer
   * @type {*}
   * @memberof ConcertinaCanvasService
   */
  headerLayer: Konva.Layer;

  /*
  * @description Fixed/Innovate text layer
  * @type {*}
  * @memberof ConcertinaCanvasService
  */
  // tslint:disable-next-line:variable-name
  FITextLayer: Konva.Layer;

  /**
   * @description Left blank bar + Left nevigation button
   * @type {*}
   * @memberof ConcertinaCanvasService
   */
  leftHeaderLayer: Konva.Layer;

  /**
   * @description Holds all the remaining data except headers
   * @type {*}
   * @memberof ConcertinaCanvasService
   */
  subGroupLayer: Konva.Layer;

  /**
   * @description Top left corner layer
   * @type {*}
   * @memberof ConcertinaCanvasService
   */
  cornerLayerLeft: any;
  /**
   * @description Top right corner layer
   * @type {*}
   * @memberof ConcertinaCanvasService
   */
  cornerLayerRight: any;

  /**
   * @description Prints no data found
   * @type {*}
   * @memberof ConcertinaCanvasService
   */
  noDataFoundLayer: Konva.Layer;

  /**
   * @description Defines Stage width
   * @memberof ConcertinaCanvasService
   */
  stageWidth = 800;
  stageHeight = 0;

  /**
   * @description Hold layer object for all selections
   * @memberof ConcertinaCanvasService
   */
  selectionLayer: Konva.Layer;

  /**
   * @description Hold layer object for all multi swap selections
   * @type {Konva.Layer}
   * @memberof ConcertinaCanvasService
   */
  swapSelectionLayer: Konva.Layer;

  /**
   * @description Holds original position for horizontal scrolling
   * @memberof ConcertinaCanvasService
   */
  originalPosition = { x: 0, y: 0 };

  rectWidth = 58;
  rectHeight = 35;
  textMarginY = 10;
  textMarginX = 10;
  iconWidth = 20;
  tabHeight = 35;
  tabWidth = 75;
  dataX = 0;
  dataY = 0;
  groupingData: any[] = [];
  selectedTabIndex = 0;
  expandKey = '';
  frameTextLimit = 25;
  frameTextPostfix = '...';
  readonly ZERO = 0;
  swapRevertFrameId = -1;
  /**
   * @description Concertina groups
   * @memberof ConcertinaCanvasService
   */
  groups;
  rowCounter = 0;

  /**
   * @description Decides wheather selection mode is on/off
   * @type {boolean}
   * @memberof ConcertinaCanvasService
   */
  selectionMode = false;

  /**
   * @description Holds the selection layers
   * @type {*}
   * @memberof ConcertinaCanvasService
   */
  selectionGroups: Konva.Collection<Konva.Node>;

  /**
   * @description Used to maintain cartSelectionData while selection
   * @type {*}
   * @memberof ConcertinaCanvasService
   */
  cartSelectionData: any = {};

  /**
   * @description Holds the selection data before adding it to the cart params
   * @type {*}
   * @memberof ConcertinaCanvasService
   */
  cartRequstParams: any = {};

  /**
   * @description Forms the request payload for cart
   * @type {*}
   * @memberof ConcertinaCanvasService
   */
  cartReqData: any = [];

  /**
   * @description Gives wheather the current selection is valid or not and
   * enables or disables the save button accordingly
   * @memberof ConcertinaCanvasService
   */
  isValidSelection = true;

  /**
   * @description Holds the frame detail icon images
   * @memberof ConcertinaCanvasService
   */
  frameDetailsIcons = [];

  frameDrillableIcons = [];

  /**
   * @description Holds the frame
   * @memberof ConcertinaCanvasService
   */
  frameDetails = {};

  /**
   * @description Notifies when frame details updated
   * @type {Subject<any>}
   * @memberof ConcertinaCanvasService
   */
  $frameDetailsSub: Subject<any> = new Subject<any>();

  /**
   * @description Notifies when to show tooltip on concertina for multiple values
   * @type {Subject<any>}
   * @memberof ConcertinaCanvasService
   */
  $concertinaTooltip: Subject<any> = new Subject<any>();

  /**
   * @description Notifies when clicked on grid to show campaign details
   * @type {Subject<any>}
   * @memberof ConcertinaCanvasService
   */
  $campaignDetailsSub: Subject<any> = new Subject<any>();

  /**
   * Filter area slider toggle subject
   * @type {Subject<boolean>}
   * @memberof ConcertinaCanvasService
   */
  $filterAreaSliderSub: Subject<boolean> = new Subject<any>();

  $toggleFilterAreaSub: Subject<boolean> = new Subject<any>();

  $swapZoneOpenSub: Subject<any> = new Subject<any>();

  /*  Konva Templates */
  rectTemplate = new Konva.Rect({
    stroke: 'lightgrey',
    strokeWidth: 1,
    width: mathRandomNumber(),
    height: mathRandomNumber(),
    // listening: false,
    // hitGraphEnabled: false,
    perfectDrawEnabled: false
  });

  /**
   * @description Konva Text template
   * @memberof ConcertinaCanvasService
   */
  textTemplate = new Konva.Text({
    fontSize: 10,
    fontFamily: 'roboto',
    width: mathRandomNumber(),
    height: mathRandomNumber(),
    strokeWidth: 1,
    perfectDrawEnabled: false,
    shadowForStrokeEnabled: false,
    shadowEnabled: false,
    text: ''
  });

  /**
   * @description Holds the current filter status
   * @type {number}
   * @memberof ConcertinaCanvasService
   */
  statusFilter = 0;

  /**
   * @description Holds the current selected period for auto expand feature
   * @type {number}
   * @memberof ConcertinaCanvasService
   */
  period = 0;

  /**
   * @description Konva layer template
   * @memberof ConcertinaCanvasService
   */
  layerTemplate = new Konva.Layer();

  /**
   * @description selected audience category for index tab
   * @memberof ConcertinaCanvasService
   */
  selectedAudienceCategory = -1;

  forceFilterApplied = false;

  hideAdvertiserId = false;
  openSidebar: boolean;

  /**
   * Creates an instance of ConcertinaCanvasService.
   * @author Amit Mahida
   * @param {ConcertinaDataService} concertinaDataService
   * @param {DataShareService} dataShareService
   * @param {CartService} cartService
   * @param {LogHelperService} logHelper
   * @memberof ConcertinaCanvasService
   */
  constructor(
    private concertinaDataService: ConcertinaDataService,
    private dataShareService: DataShareService,
    private cartService: CartService,
    private logHelper: LogHelperService,
    private geoMapService: GeoMapService,
    private loaderService: LoaderService,
    private swapZoneService: SwapZoneService
  ) {
    this.rectTemplate.cache();
    this.swapZoneService.swapMode$.subscribe((swapMode) => {
      if (this.openSidebar !== swapMode) {
      this.updateHeaderWidth(swapMode)
      }

      this.openSidebar = swapMode;
    });
    this.textTemplate.cache();
  }

  resetDeleteMarks() {
    this.selectionGroups = this.selectionLayer.getChildren();
    for (let index = this.selectionGroups.length - 1; index >= 0; index--) {
      const group = this.selectionGroups[index] as Konva.Group;
      if (group.getAttr('isMarkedToDelete')) {
        group.setAttr('isMarkedToDelete', false);
        const deleteButton = group.find('.removeButton')[0] as Konva.Text;
        deleteButton.fill('#FFF');
        deleteButton.hide();
      }
    }
  }

  resetNewSelection() {
    this.selectionGroups = this.selectionLayer.getChildren();
    for (let index = this.selectionGroups.length - 1; index >= 0; index--) {
      const group = this.selectionGroups[index] as Konva.Group;
      if (!group.getAttr('selectedInCart')) {
        delete this.cartRequstParams[group.getAttr('name')];
        this.updateCartReqParams(undefined, undefined);
        this.clearGroups(group);
      }
    }
    this.cartSelectionData = {};
    this.selectionLayer.batchDraw();
    this.hasNewSelection = false;
  }

  /**
   * @description Resets current selection after save
   * @author Amit Mahida
   * @memberof ConcertinaCanvasService
   */
  resetCurrentSelection() {
    this.cartRequstParams = {};
    this.cartReqData = [];

    if (this.subGroupLayer && this.subGroupLayer.getChildren().length > 0) {
      this.subGroupLayer.moveToTop();
    }
    if (this.leftHeaderLayer && this.leftHeaderLayer.getChildren().length > 0) {
      this.leftHeaderLayer.moveToTop();
    }
    if (this.selectionLayer) {
      this.selectionLayer.destroyChildren();
    }
    this.updateSelectionStatus();
  }

  /**
   * @description Resets custom selection made for swap
   * @author Amit Mahida
   * @memberof ConcertinaCanvasService
   */
  resetSwapSelection() {
    this.multiSelectionForSwap = {};
    this.swapSelectionLayer.destroyChildren();
  }

  /**
   * @description Returns selection mode details
   * @author Amit Mahida
   * @returns
   * @memberof ConcertinaCanvasService
   */
  getSelectMode() {
    return this.selectionMode;
  }

  /**
   * @description Sets current tab index
   * @author Amit Mahida
   * @param {number} tabIndex
   * @memberof ConcertinaCanvasService
   */
  setSelectedTab(tabIndex: number) {
    this.selectedTabIndex = tabIndex;
  }

  /**
   * @description Used for autoexpand feature
   * @author Amit Mahida
   * @memberof ConcertinaCanvasService
   */
  autoExpandAll() {
    this.concertinaDataService.concertinaData.groups.xGroups.forEach((cell) => {
      if (cell !== 'x') {
        this.setData(cell);
      }
    });
  }

  /**
   * @description Sets data on auto expand
   * @author Amit Mahida
   * @param {any} concertinaData
   * @memberof ConcertinaCanvasService
   */
  async setData(xGroup) {
    const promiseArray = [];
    // xGroups.forEach(async (cell) => {
    promiseArray.push(this.getDataOnClick(xGroup));
    await Promise.all([
      promiseArray
    ]).then(() => {
      this.loaderService.show();
      setTimeout(() => {
        this.refreshTable();
        this.loaderService.hide();
      }, 10);
    });
  }

  /**
   * @description returns parent columns of given column
   * @author Amit Mahida
   * @param {string} col
   * @returns
   * @memberof ConcertinaCanvasService
   */
  getParentColumns(col: string) {
    this.parentCols = [];
    let parentColArray = [];
    let parentCol;
    const recursiveFunc = (column) => {
      parentColArray = column.split(GLOBAL.CONCERTINA_SEPARATOR);
      parentColArray.splice(parentColArray.length - 1, 1);
      parentCol = parentColArray.join(GLOBAL.CONCERTINA_SEPARATOR);
      if (parentCol !== '' && (parentCol.indexOf(GLOBAL.CONCERTINA_SEPARATOR) > -1 || parentCol === 'x')) {
        this.parentCols.push(parentCol);
        recursiveFunc(parentCol);
      }
    };
    parentColArray = col.split(GLOBAL.CONCERTINA_SEPARATOR);
    parentColArray.splice(parentColArray.length - 1, 1);
    parentCol = parentColArray.join(GLOBAL.CONCERTINA_SEPARATOR);
    if (parentCol !== '') {
      this.parentCols.push(parentCol);
    }
    if (parentCol.indexOf(GLOBAL.CONCERTINA_SEPARATOR) > -1) {
      recursiveFunc(parentCol);
    }
    return this.parentCols;
  }

  /**
   * @description returns parent rows of given row
   * @author Amit Mahida
   * @param {string} row
   * @returns
   * @memberof ConcertinaCanvasService
   */
  getParentRows(row: string) {
    this.parentRows = [];
    let parentRowArray = [];
    let parentRow;
    const recursiveFunc = (row) => {
      parentRowArray = row.split(GLOBAL.CONCERTINA_SEPARATOR);
      parentRowArray.splice(parentRowArray.length - 1, 1);
      parentRow = parentRowArray.join(GLOBAL.CONCERTINA_SEPARATOR);
      if (parentRow !== '' && (parentRow.indexOf(GLOBAL.CONCERTINA_SEPARATOR) > -1 || parentRow === 'y')) {
        this.parentRows.push(parentRow);
        recursiveFunc(parentRow);
      }
    };
    parentRowArray = row.split(GLOBAL.CONCERTINA_SEPARATOR);
    parentRowArray.splice(parentRowArray.length - 1, 1);
    parentRow = parentRowArray.join(GLOBAL.CONCERTINA_SEPARATOR);
    if (parentRow !== '') {
      this.parentRows.push(parentRow);
    }
    if (parentRow.indexOf(GLOBAL.CONCERTINA_SEPARATOR) > -1) {
      recursiveFunc(parentRow);
    }
    return this.parentRows;
  }

  /**
   * @description returns child columns of given column
   * @author Amit Mahida
   * @param {string} col
   * @returns
   * @memberof ConcertinaCanvasService
   */
  getChilderenCols(col: string) {
    return this.concertinaDataService.originalConcertinaData.groups.xGroups.filter((xGroup) => {
      return xGroup !== col && (xGroup.indexOf(col) > -1);
    });
  }

  /**
   * @description returns child rows of given row
   * @author Amit Mahida
   * @param {string} row
   * @returns
   * @memberof ConcertinaCanvasService
   */
  getChilderenRows(row: string) {
    return this.concertinaDataService.originalConcertinaData.groups.yGroups.filter((yGroup) => {
      return yGroup !== row && (yGroup.indexOf(row) > -1);
    });
  }

  /**
   * @description Filters concertina data by frame id / status masking
   * @author Amit Mahida
   * @param {string} frame
   * @memberof ConcertinaCanvasService
   */
  filterConcertinaData() {
    let concertinaData: any = {};
    if (this.concertinaDataService.originalConcertinaData) {
      concertinaData = _.cloneDeep(this.concertinaDataService.originalConcertinaData);
    }

    if (_.isEmpty(concertinaData)) {
      return false;
    }
    const foundKeys = [];
    this.filteredKeysForFrame = [];
    if (this.frameSearchKey !== '') {
      const concertinaArray = _.values(concertinaData);
      const filteredConcertinaArray = concertinaArray.filter((item) => {
        if (!_.isUndefined(item.key) && item.key.indexOf('y') > -1) {
          const displayText = item.displayText.toUpperCase();
          if (displayText.indexOf(this.frameSearchKey.toUpperCase()) > -1) {
            foundKeys.push(item.key);
            this.filteredKeysForFrame.push(item.key);
          }
          return displayText.indexOf(this.frameSearchKey.toUpperCase()) > -1;
        }
      });
      let newConcertinaData = _.keyBy(filteredConcertinaArray, 'key');
      newConcertinaData = _.extend(newConcertinaData, { groups: concertinaData.groups });
      newConcertinaData = _.extend(newConcertinaData, { xHeadings: concertinaData.xHeadings });
      if (!newConcertinaData.hasOwnProperty('y')) {
        newConcertinaData = _.extend(newConcertinaData, { y: concertinaData.y });
      }
      if (foundKeys.length > 0) {
        for (const element of foundKeys) {
          const childKeys = [];
          if (this.concertinaDataService.originalConcertinaData.hasOwnProperty(element) && this.concertinaDataService.originalConcertinaData.groups.yGroups.indexOf(element) > -1) {
            this.concertinaDataService.originalConcertinaData.groups.yGroups.forEach((yGroup) => {
              if (yGroup.indexOf(element) > -1) {
                childKeys.push(yGroup);
              }
            });
          }
          const parentsComb = element.split(GLOBAL.CONCERTINA_SEPARATOR);
          parentsComb.splice(parentsComb.length - 1, 1);
          const parentKeys = [];
          const loopLength = parentsComb.length;
          for (let i = 0; i < loopLength; i++) {
            if (parentsComb.length >= i) {
              parentKeys.push(parentsComb.join(GLOBAL.CONCERTINA_SEPARATOR));
              parentsComb.splice(parentsComb.length - 1, 1);
            }
          }
          if (parentKeys.length > 0) {
            parentKeys.forEach((parentKey) => {
              if (parentKey !== 'y') {
                newConcertinaData[parentKey] = concertinaData[parentKey];
                this.filteredKeysForFrame.push(parentKey);
              }
            });
          }
          if (childKeys.length > 0) {
            childKeys.forEach((childKey) => {
              if (childKey !== 'y') {
                newConcertinaData[childKey] = concertinaData[childKey];
                this.filteredKeysForFrame.push(childKey);
              }
            });
          }

        }
      }
      this.filteredKeysForFrame = this.filteredKeysForFrame.filter((item, pos) => this.filteredKeysForFrame.indexOf(item) === pos);
      concertinaData = _.clone(newConcertinaData);
      for (let index = concertinaData.groups.yGroups.length - 1; index >= 0; index--) {
        const element = concertinaData.groups.yGroups[index];
        if (this.filteredKeysForFrame.indexOf(element) === -1 && element !== 'y') {
          concertinaData.groups.yGroups.splice(index, 1);
        }
      }
    }

    if (this.statusFilter !== this.frameFilterType.all) {
      const counter = concertinaData.groups.yGroups.length - 1;
      for (let index = counter; index >= 0; index--) {
        const yKey = concertinaData.groups.yGroups[index];
        const row = concertinaData[yKey];
        let recordAvailableInRow = false;
        for (const xKey of concertinaData.groups.xGroups) {
          const col = row.xValues[xKey];
          const statusMask: number = col['statusMask'];
          if (statusMask) {
            if (this.statusFilter === this.frameFilterType.available) {
              // tslint:disable-next-line:no-bitwise
              const partiallySelectedCond = statusMask & FrameStatusMaskEnum.availableAndNotAvailable;
              if (statusMask === FrameStatusMaskEnum.available ||
                partiallySelectedCond === FrameStatusMaskEnum.availableAndNotAvailable ||
                statusMask === FrameStatusMaskEnum.selected) {
                recordAvailableInRow = true;
              }
            }
            if (this.statusFilter === this.frameFilterType.selected) {
              // tslint:disable-next-line:no-bitwise
              const partiallySelectedCond = statusMask & FrameStatusMaskEnum.selected;
              if (statusMask === FrameStatusMaskEnum.selected
                || partiallySelectedCond === FrameStatusMaskEnum.selected) {
                recordAvailableInRow = true;
              }
            }
          }
        }
        if (!recordAvailableInRow && yKey !== 'y') {
          concertinaData.groups.yGroups.splice(index, 1);
          delete concertinaData[yKey];
        }
      }
    }

    let dataNotFoundInNotSelected = false;
    if (this.tabValue === GLOBAL.concertinaNotSelectedTab && this.dataShareService.appName === AppNameEnum.workspace) {
      concertinaData = _.cloneDeep(this.filterFramesNotSelected(concertinaData));
      if (concertinaData.groups.yGroups.length === 1 && concertinaData.groups.yGroups[0] !== 'y') {
        dataNotFoundInNotSelected = true;
      }
    }

    if ((this.filteredKeysForFrame.length === 0 && this.frameSearchKey !== '') ||
      dataNotFoundInNotSelected ||
      (concertinaData.groups.yGroups[0] === 'y' && concertinaData.groups.yGroups.length === 1)) {
      this.headerStage.getChildren().each((child) => {
        child.hide();
      });
      this.stage.getChildren().each((child) => {
        child.hide();
      });
      if (this.noDataFoundLayer) {
        this.manageContainers();
        this.noDataFoundLayer.show();
        this.loadingLayer.hide();
        this.loadingLayer.batchDraw();
      }
      this.printNoDataFound(this.userBundle['vip.concertina.noDataAvailable'] || 'Frames not Available!');

      if (this.statusFilter === this.frameFilterType.all && this.frameSearchKey !== '') {
        this.concertinaDataService.$framesCount.next({ total: 0, available: 0, selected: 0 });
      }
      return false;
    } else {
      this.concertinaDataService.concertinaData = _.cloneDeep(concertinaData);
      this.headerStage.getChildren().each((child) => {
        child.show();
      });
      this.stage.getChildren().each((child) => {
        child.show();
      });
      if (this.noDataFoundLayer) {
        this.noDataFoundLayer.hide();
        this.noDataFoundLayer.batchDraw();
      }
      this.concertinaDataService.$framesCount.next(this.concertinaDataService.filterCounts);
      this.loaderService.show();
      setTimeout(() => {
        let resetColumns = true;
        if (this.dataShareService.appName === AppNameEnum.workspace || this.dataShareService.appName === AppNameEnum.visualplanner) {
          resetColumns = false;
        }
        this.refreshTable(resetColumns);
        this.manageScrollHeight();
        this.loaderService.hide();
      }, 10);
      return true;
    }
  }

  /**
   * @description Prints no data found
   * @author Amit Mahida
   * @param {string} message
   * @memberof ConcertinaCanvasService
   */
  printNoDataFound(message: string) {
    this.stageWidth = this.getContainerWidth();
    if (!this.noDataFoundLayer) {
      this.noDataFoundLayer = this.layerTemplate.clone({
        name: 'nodataFoundLayer'
      });
      this.stage.add(this.noDataFoundLayer);
    } else {
      this.noDataFoundLayer.destroyChildren();
      this.stage.add(this.noDataFoundLayer);
    }
    this.stage.height(200);
    const container = document.querySelector('#container');
    const largeContainer = document.querySelector('#large-container');
    const largeHeaderContainer = document.getElementById('header-large-container');

    if (container) {
      container['style']['height'] = `${this.stage.height()}px`;
    } else {
      return;
    }

    if (largeContainer) {
      largeContainer['style']['height'] = `${this.stage.height()}px`;
    } else {
      return;
    }

    if (largeHeaderContainer) {
      largeHeaderContainer['style']['width'] = `${this.headerStage.width()}px`;
    } else {
      return;
    }

    const textRectHeight = this.stage.height() - this.rectHeight;
    const drawTextOnRect = this.drawTextOnRect(
      message, 0, 50,
      'black', this.stageWidth, textRectHeight, 'center',
      false, null, 0, 30
    );
    this.noDataFoundLayer.add(drawTextOnRect);
    drawTextOnRect.moveToTop();
    this.noDataFoundLayer.show();
    this.stage.batchDraw();
  }

  /**
   * @description Sets concertina data
   * @param {any} data
   * @param {boolean} [isOriginalData=true]
   * @memberof ConcertinaCanvasService
   */
  setConcertinaData(data, groups, xHeadings, isOriginalData = true) {
    if (this.initialConfig.uiControl.concertinaInAlphaNumericOrder) {
      groups['yGroups'] = this.sortYGroup(data, groups, false);
    }

    if (isOriginalData) {
      this.concertinaDataService.originalConcertinaData = _.cloneDeep(data);
      this.concertinaDataService.originalConcertinaData.groups = _.clone(groups);
      this.concertinaDataService.originalConcertinaData.xHeadings = _.clone(xHeadings);
    }
    this.concertinaDataService.concertinaData = _.cloneDeep(data);
    this.concertinaDataService.concertinaData.groups = _.clone(groups);
    this.concertinaDataService.concertinaData.xHeadings = _.clone(xHeadings);
    this.setFrameListForSwap();
  }

  /**
   * @description Prepared frame list for swap
   * @author Amit Mahida
   * @memberof ConcertinaCanvasService
   */
  setFrameListForSwap() {
    if (this.dataShareService.appName === AppNameEnum.visualplanner) {
      const frameList: Frame[] = [];
      for (const yKey of this.concertinaDataService.originalConcertinaData.groups.yGroups) {
        const frame = this.concertinaDataService.originalConcertinaData[yKey];
        if (yKey !== 'y') {
          const bookings: Booking[] = [];
          for (const column of this.concertinaDataService.originalConcertinaData.groups.xGroups) {
            if (column !== 'x') {
              // Should be collected as continuous time period only
              // For digital & paper frame
              for (const bookingDetail of frame.xValues[column].bookingDetails) {
                const lastBookingIndex = bookings.findIndex(b => b.campaignReference === bookingDetail.campaignReference);
                const lastBooking = bookings[lastBookingIndex];
                if (bookings.length && lastBooking
                  && !(moment(bookingDetail.startDate).isSameOrAfter(moment(lastBooking.startDate))
                    && moment(bookingDetail.endDate).isSameOrBefore(moment(lastBooking.endDate)))
                  && moment(lastBooking.endDate).add(1, 'hour')
                    .startOf('hour').format('YYYY-MM-DDTHH:mm:ss.SSS') === bookingDetail.startDate
                  && bookingDetail.campaignReference === lastBooking.campaignReference) {
                  bookings[lastBookingIndex].endDate = bookingDetail.endDate;
                } else {
                  const bookingIsSubset = bookings.length ? bookings.findIndex(b =>
                    bookingDetail.campaignReference === b.campaignReference
                    && bookingDetail.startDate >= b.startDate
                    && bookingDetail.endDate <= b.endDate
                    && bookingDetail.sot === b.sot
                  ) > -1 : false;
                  if (!bookingIsSubset) {
                    const booking: Booking = {
                      campaignReference: bookingDetail.campaignReference,
                      startDate: bookingDetail.startDate,
                      endDate: bookingDetail.endDate,
                      sot: bookingDetail.sot
                    };
                    bookings.push(booking);
                  }
                }
              }
            }
          }
          frameList.push({
            bookings,
            frameCode: frame.displayText,
            frameId: frame.frameId,
            visualUnitId: frame.visualUnitId,
            productFormatId: frame.productFormatId,
            y: yKey,
            isDigital: frame.isDigital
          });
        }
      }
      this.swapZoneService.setFramesList(frameList);
    }
  }

  /**
   * @description Push inside concertina data
   * @param {any} data
   * @param {boolean} [isOriginalData=true]
   * @memberof ConcertinaCanvasService
   */
  pushConcertinaData(data, groups, xHeadings, name = '') {
    if (!this.concertinaDataService.originalConcertinaData || _.isEmpty(this.concertinaDataService.originalConcertinaData)) {
      this.concertinaDataService.originalConcertinaData = {
        groups: {
          xGroups: [],
          yGroups: []
        },
        xHeadings: {}
      };
    }
    if (!this.concertinaDataService.concertinaData || _.isEmpty(this.concertinaDataService.concertinaData)) {
      this.concertinaDataService.concertinaData = {
        groups: {
          xGroups: [],
          yGroups: []
        },
        xHeadings: {}
      };
    }
    const tempConcertinaData = _.clone(this.concertinaDataService.originalConcertinaData);
    if (groups) {
      if (groups.xGroups) {
        tempConcertinaData.groups.xGroups = this.updateConcertinaDataGroups(groups.xGroups, tempConcertinaData.groups.xGroups);
      }
      if (groups.yGroups) {
        if (this.initialConfig.uiControl.concertinaInAlphaNumericOrder) {
          groups['yGroups'] = this.sortYGroup(data, groups, true);
        }
        tempConcertinaData.groups.yGroups = this.updateConcertinaDataGroups(groups.yGroups, tempConcertinaData.groups.yGroups);
      }
    }

    tempConcertinaData.groups.xGroups = tempConcertinaData.groups.xGroups.sort();

    Object.keys(data).forEach((element) => {
      if (name && tempConcertinaData[element] && tempConcertinaData[element].xValues[name] && tempConcertinaData[element].xValues[name].isMarkedToDelete) {
        Object.keys(data[element].xValues).forEach((f) => {
          data[element].xValues[f].isMarkedToDelete = true;
        });
      }

      if (!tempConcertinaData[element]) {
        tempConcertinaData[element] = data[element];
      } else {
        if (data[element].xValues) {
          Object.assign(tempConcertinaData[element].xValues, data[element].xValues);
        }
      }
    });

    if (xHeadings) {
      Object.keys(xHeadings).forEach((element) => {
        if (!tempConcertinaData.xHeadings[element]) {
          tempConcertinaData.xHeadings[element] = xHeadings[element];
        }
      });
    }
    this.concertinaDataService.originalConcertinaData = _.cloneDeep(tempConcertinaData);
    this.concertinaDataService.concertinaData = _.cloneDeep(tempConcertinaData);
    this.setFrameListForSwap();
  }

  sortYGroup(data, groups, isReverse) {
    var orderedData = {};
    _(data).keys().sortBy(data, [function (o) {
      return data[o]["displayText"];
    }]).each(function (key) {
      orderedData[key] = data[key];
    });

    var orderedYGroup = _.sortBy(groups['yGroups'], function (item) {
      return Object.keys(orderedData).indexOf(item)
    });

    if (isReverse) {
      orderedYGroup = orderedYGroup.reverse();
    }

    var matchedIndex = [];
    var treeLavelGroups = []
    var matchedCount = 0;
    do {
      var treeLavel = _.filter(orderedYGroup, function (el, ind) {
        var count = (el.match(/\|/g) || []).length;
        if (matchedCount == count) {
          matchedIndex.push(ind);
        }
        return (el.match(/\|/g) || []).length === matchedCount;
      });

      if (treeLavel.length > 0) {
        matchedCount++;
      } else {
        matchedCount = 0;
      }

      treeLavelGroups = [...treeLavelGroups, ...treeLavel];
    } while (matchedCount > 0);

    matchedIndex.forEach((el, ind) => {
      orderedYGroup.splice(el - ind, 1);
    });

    orderedYGroup = [...orderedYGroup, ...treeLavelGroups];
    return orderedYGroup;
  }

  /**
   * @description updates concertina groups on expand/collapse
   * @author Amit Mahida
   * @param {string[]} newGroups
   * @param {string[]} currentGroups
   * @returns
   * @memberof ConcertinaCanvasService
   */
  updateConcertinaDataGroups(newGroups: string[], currentGroups: string[]) {
    for (const element of newGroups) {
      if (currentGroups.indexOf(element) === -1) {
        const currentGroupIndex = currentGroups.indexOf(element.substring(0, element.lastIndexOf('|')));
        if (currentGroupIndex === -1 || currentGroups.length === (currentGroupIndex + 1)) {
          currentGroups.push(element);
        } else {
          currentGroups.splice(currentGroupIndex + 1, 0, element);
        }
      }
    }
    return currentGroups;
  }

  /**
   * @description Set current groups
   * @author Amit Mahida
   * @param {*} data
   * @memberof ConcertinaCanvasService
   */
  setGroups(data) {
    this.groups = _.clone(data);
  }

  /**
   * @description Returns current grouping data
   * @author Amit Mahida
   * @returns
   * @memberof ConcertinaCanvasService
   */
  getGroupingdata() {
    return this.groupingData;
  }

  /**
   * @description Sets grouping data
   * @author Amit Mahida
   * @param {*} data
   * @memberof ConcertinaCanvasService
   */
  setGroupingdata(data) {
    this.groupingData = data;
    this.geoMapService.concertinaRequest = data;
    this.cartService.concertinaGrouping = data;
  }

  /**
   * @description update the width of convertina when swap zone drawer opens
   * @author Siddharth Vaidya
   * @memberof ConcertinaCanvasService
   */
  updateHeaderWidth(status) {
    const drawerWidth = 400;
    const header_width = status ? this.headerStage.width() - drawerWidth : this.headerStage.width() + drawerWidth;
    const headerLayer_width = status ? this.headerLayer.width() - drawerWidth : this.headerLayer.width() + drawerWidth;
    const stage_width = status ? this.stage.width() - drawerWidth : this.stage.width() + drawerWidth;
    const gridContanier = document.getElementById('large-container');
    gridContanier['style']['width'] = stage_width+'px';
    const headerCon = document.getElementById('header-container');
    const canvasElements = headerCon.querySelectorAll('canvas');
    canvasElements.forEach(canvas => {
      canvas['style']['width'] = headerLayer_width+'px';
    });
    this.stage.width(stage_width)
    this.stageWidth = stage_width;
    this.headerStage.width(header_width)
    this.headerLayer.width(headerLayer_width)
    const call = [1, 1]
    call.forEach((a) => {
    this.refreshTable()
    })
  }

  /**
   * @description Initialize Concertina and load default data
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof ConcertinaCanvasService
   */
  initThisService(): boolean {
    if (this.dataShareService.appName === AppNameEnum.workspace) {
      this.stageThreshold = 18;
    } else if (this.dataShareService.appName === AppNameEnum.visualplanner || this.dataShareService.appName === AppNameEnum.result) {
      this.stageThreshold = 25;
    }
    if (this.dataShareService.getInitialConfigByKey('systemData').environmentId === EnvironmentId.SG) {
      this.colThreshold = this.dataShareService.appName === AppNameEnum.visualplanner ? 8 : 7;
    }
    this.stageWidth = this.getContainerWidth();
    this.rectWidth = this.stageWidth / this.stageThreshold;
    this.delta = this.rectWidth / 4; // width of left right icon
    if (this.stage) {
      this.stage.destroyChildren();
      this.stage.destroy();
    }
    this.stage = new Konva.Stage({
      container: 'container',
      width: this.getContainerWidth(),
      height: this.stageHeight,
      dragBoundFunc: (pos) => {
        // important pos - is absolute position of the node
        // you should return absolute position too
        return {
          x: this.stage.getAbsolutePosition().x,
          y: pos.y
        };
      }
    });
    if (this.loadingStage) {
      this.loadingStage.destroyChildren();
      this.loadingStage.destroy();
    }
    this.loadingStage = new Konva.Stage({
      container: 'loading-container',
      width: this.getContainerWidth(),
      height: this.stageHeight,
    });
    this.loadingLayer = new Konva.Layer({
      name: 'loadingLayer'
    });
    this.loadingStage.add(this.loadingLayer);
    if (this.headerStage) {
      this.headerStage.destroyChildren();
      this.headerStage.destroy();
    }
    this.headerStage = new Konva.Stage({
      container: 'header-container',
      width: this.getContainerWidth() + this.delta,
      height: this.rectHeight
    });

    if (this.frameSelectionEnabled) {
      if (!this.selectionLayer) {
        this.selectionLayer = this.layerTemplate.clone({
          name: 'selectionLayer'
        });
        this.stage.add(this.selectionLayer);
      }
      if (!this.swapSelectionLayer) {
        this.swapSelectionLayer = this.layerTemplate.clone({
          name: 'swapSelectionLayer'
        });
        this.stage.add(this.swapSelectionLayer);
      }
    }

    this.originalPosition.x = 0;
    this.originalPosition.y = 0;
    this.deletedMarkedSelections = {};
    return true;
  }

  /**
   * @description Destroys the group
   * @author Amit Mahida
   * @param {Konva.Group} groupObj
   * @memberof ConcertinaCanvasService
   */
  clearGroups(groupObj: Konva.Group) {
    groupObj.destroyChildren();
    groupObj.destroy();
  }

  /**
   * @description Places frames info icons next to frame id
   * @author Amit Mahida
   * @memberof ConcertinaCanvasService
   */
  placeFrameDetailsIcons(element) {
    const infoIcon = this.drawTextOnRect('', element.position.x, element.position.y - 7, '#fff', 20, this.rectHeight,
      'center', false, null, 0, 18, 'bold', 'FontAwesome', '');

    infoIcon.setAttr('frame', element.frame);
    infoIcon.on('mousedown touchstart', (event) => {
      this.getFrameDetails(infoIcon.getAttr('frame')).subscribe((res) => {
        if (res['status'] === 'OK') {
          this.frameDetails = res['data'];
          this.$frameDetailsSub.next({ event, data: res['data'] });
        }
      });
    });
    infoIcon.on('mouseenter', () => {
      this.stage.container().style.cursor = 'pointer';
      infoIcon.strokeWidth(3);
    });
    infoIcon.on('mouseleave', () => {
      this.stage.container().style.cursor = 'default';
      infoIcon.strokeWidth(2);
    });
    this.leftHeaderLayer.add(infoIcon);
  }

  /**
   * @description Places drill icons +/- as per the current expansion
   * @author Amit Mahida
   * @memberof ConcertinaCanvasService
   */
  placeDrillableIcons() {
    for (let index = this.frameDrillableIcons.length - 1; index >= 0; index--) {
      const element = this.frameDrillableIcons[index];
      let text; // default text is plus icon
      if (this.drilledDownKeys.indexOf(element.key) > -1 || element.key === 'y') {
        text = ''; // minus icon
      } else {
        text = ''; // plus icon
      }
      const drillIcon = this.drawTextOnRect(text, element.position.x, element.position.y - 7, '#fff', 50, 50,
        'center', false, { key: element.key }, 0, 18, 'normal', 'FontAwesome', element.key);

      drillIcon.on('mousedown touchstart', () => {
        const cellValues = drillIcon.getAttr('cellValues');
        if (!_.isUndefined(cellValues)
          && cellValues.key === 'y') {
          return;
        }
        if (!_.isUndefined(cellValues)
          && cellValues.key.includes('y')) {
          this.manageDrilledDownKeys(drillIcon.getAttr('name'));
        }
        if (cellValues) {
          drillIcon.setAttr('drilled', true);
          this.manageExpandCollapse(cellValues.key);
        }
      });
      drillIcon.on('mouseenter', () => {
        this.stage.container().style.cursor = 'pointer';
        drillIcon.strokeWidth(3);
      });
      drillIcon.on('mouseleave', () => {
        this.stage.container().style.cursor = 'default';
        drillIcon.strokeWidth(2);
      });
      this.leftHeaderLayer.add(drillIcon);
    }
  }

  /**
   * @description Manages the visibility of selection groups on expand collapse
   * @author Amit Mahida
   * @memberof ConcertinaCanvasService
   */
  manageVisibilityOfSelectionGroups() {
    this.selectionGroups = this.selectionLayer.getChildren();
    if (this.selectionGroups.length > 0) {
      for (let index = this.selectionGroups.length - 1; index >= 0; index--) {
        const group = this.selectionGroups[index];
        if (group.getAttr('selectedColumns')) {
          const selectedColStartIndex = group.getAttr('selectedColumns')[0];
          const selectedColEndIndex = group.getAttr('selectedColumns')[group.getAttr('selectedColumns').length - 1];
          const startIndexExistInCols = this.currentConcertinaCols.findIndex((element) => {
            return element.colKey === selectedColStartIndex;
          });
          const endIndexExistInCols = this.currentConcertinaCols.findIndex((element) => {
            return element.colKey === selectedColEndIndex;
          });

          const selectedRowStartIndex = group.getAttr('selectedRows')[0];
          const selectedRowEndIndex = group.getAttr('selectedRows')[group.getAttr('selectedRows').length - 1];
          const startIndexExistInRows = this.currentConcertinaRows.findIndex((element) => {
            return element.rowKey === selectedRowStartIndex;
          });
          const endIndexExistInRows = this.currentConcertinaRows.findIndex((element) => {
            return element.rowKey === selectedRowEndIndex;
          });
          if (startIndexExistInCols > -1 && endIndexExistInCols > -1
            && startIndexExistInRows > -1 && endIndexExistInRows > -1) {
            group.setAttr('hidden', false);
            group.show();
            group.moveToTop();
          } else {
            if (group.getAttr('selectedInCart') && this.gridCollapsed) {
              delete this.cartRequstParams[group.getAttr('name')];
              group.destroy();
              this.selectionLayer.clearCache();
            } else {
              group.setAttr('hidden', true);
              group.hide();
            }
          }

          // updates position on scrolling
          if (this.selectionGroups.length > 0) {
            this.currentConcertinaCols.forEach((col) => {
              if (group.getAttr('selectedColumns')[0] === col.colKey) {
                group.x(col.position.x);
                group.moveToTop();
              }
            });
            this.currentConcertinaRows.forEach((row) => {
              if (group.getAttr('selectedRows').indexOf(row.rowKey) > -1) {
                group.y(row.position.y + this.previousScrollTop);
                group.moveToTop();
              }
            });
          }
        }
        if (this.gridCollapsed) {
          this.gridCollapsed = false;
        }
        this.selectionLayer.show();
        this.selectionLayer.moveToTop();
        this.selectionLayer.batchDraw();
        this.leftHeaderLayer.show();
        this.leftHeaderLayer.moveToTop();
        this.leftHeaderLayer.batchDraw();
      }
    }
  }

  /**
   * This method will trigged whenever column expand collapse even occured
   */
  updateTable(resetXPosition = true): boolean {
    this.manageContainers(false);
    const largeContainer = document.getElementById('large-container');
    if (largeContainer) {
      largeContainer['style']['height'] =
        `${((this.concertinaDataService.concertinaData.groups.yGroups.length + 1) * this.rectHeight)}px`;
    }
    const largeHeaderContainer = document.getElementById('header-large-container');
    if (largeHeaderContainer && this.concertinaDataService.concertinaData) {
      largeHeaderContainer['style']['width'] =
        `${((this.concertinaDataService.concertinaData.groups.xGroups.length + this.colThreshold) * this.rectWidth) + (this.delta * 2)}px`;
    }
    if (this.frameSelectionEnabled) {
      this.selectionGroups = this.selectionLayer.getChildren();
      for (let index = this.selectionGroups.length - 1; index >= 0; index--) {
        const group = this.selectionGroups[index];
        if (group.getAttr('selectedInCart') ||
          (!_.isUndefined(group.getAttr('isMarkedToDelete')) && !group.getAttr('isMarkedToDelete'))) {
          group.destroy();
        }
      }
      if (this.selectionGroups.length === 0) {
        this.selectionLayer.hide();
      }
    }
    if (resetXPosition) {
      this.manageEndColIndex();
    }
    const containerHeightNumber: number = Number(this.containerHeight.replace('px', ''));
    this.stage.height(containerHeightNumber);
    this.loadingStage.height(containerHeightNumber);
    // Generating Rows
    const noOfRows = parseInt((containerHeightNumber / this.rectHeight).toString(), 10) + 20;
    this.endRowIndex = this.startRowIndex + noOfRows;
    this.generateHeaders();
    this.generateRows();
    this.generateSkeletonRows();
    if (this.noDataFoundLayer) {
      this.noDataFoundLayer.hide();
      this.noDataFoundLayer.batchDraw();
    }
    return true;
  }

  /**
   * @description Calculates end column index for lazy loading
   * @author Amit Mahida
   * @param {boolean} resetXPosition
   * @memberof ConcertinaCanvasService
   */
  manageEndColIndex() {
    const currentNoOfCols = this.concertinaDataService.originalConcertinaData.groups.xGroups.length;
    const noOfColsOnCurrentViewPort = (this.stageWidth / this.rectWidth) - this.colThreshold;
    const moreColsThanCurrentViewPort = currentNoOfCols > noOfColsOnCurrentViewPort;
    if (this.endColIndex < noOfColsOnCurrentViewPort || this.startColIndex === 0) {
      this.endColIndex = moreColsThanCurrentViewPort ? Math.round(noOfColsOnCurrentViewPort) : currentNoOfCols;
    }
    if (this.endColIndex > currentNoOfCols) {
      this.endColIndex = currentNoOfCols;
      this.startColIndex = (this.endColIndex - noOfColsOnCurrentViewPort) > 0 ? Math.round(this.endColIndex - noOfColsOnCurrentViewPort) : 0;
    }
  }

  /**
   * @description Reload Data/Table with available data
   * @memberof ConcertinaCanvasService
   */
  refreshTable(resetColumns = true): boolean {
    this.dataX = 0;
    this.dataY = 0;
    if (resetColumns) {
      this.startColIndex = 0;
    }
    this.manageEndColIndex();
    this.generateHeaders();

    // Body Stage Starts
    if (!this.setContainerHeight()) {
      return;
    }

    const containerHeightNumber: number = Number(this.containerHeight.replace('px', ''));
    this.stage.height(containerHeightNumber);
    this.loadingStage.height(containerHeightNumber);
    // Generating Rows
    const noOfRows = parseInt((containerHeightNumber / this.rectHeight).toString(), 10) + 20;
    this.startRowIndex = 0;
    this.endRowIndex = this.startRowIndex + noOfRows;
    this.previousScrollTop = 0;
    this.generateRows(this.startRowIndex, this.endRowIndex, this.previousScrollTop);
    this.generateSkeletonRows();
    // Generating Rows End

    this.previousDataX = this.dataX;
    this.manageContainers();

    // Body Stage End
    return true;
  }

  manageContainers(clearXYPosition = true) {
    if (!this.setContainerHeight()) {
      return;
    }
    const largeContainer = document.getElementById('large-container');
    if (largeContainer && this.concertinaDataService.concertinaData) {
      largeContainer['style']['height'] =
        `${((this.concertinaDataService.concertinaData.groups.yGroups.length + 1) * this.rectHeight)}px`;
    }
    const largeHeaderContainer = document.getElementById('header-large-container');
    if (largeHeaderContainer && this.concertinaDataService.concertinaData) {
      largeHeaderContainer['style']['width'] =
        `${((this.concertinaDataService.concertinaData.groups.xGroups.length + this.colThreshold) * this.rectWidth) + (this.delta * 2)}px`;
    }
    const scrollContainer = document.getElementById('scroll-container');
    const loadingContainer = document.getElementById('loading-container');

    if (scrollContainer) {
      scrollContainer['style']['height'] = this.containerHeight;
      loadingContainer['style']['height'] = this.containerHeight;

      // VJ: Resetting scroll container position
      if (clearXYPosition) {
        scrollContainer.scrollTop = 0;
        scrollContainer.scrollLeft = 0;
        this.stage.x(0);
        this.stage.y(0);
        this.stage.container().style.transform = 'translate(0px, 0px)';
      }

      if (this.scrollSubscription) {
        this.scrollSubscription.unsubscribe();
      }

      let count = 0;
      const scroll: any = Observable.fromEvent(scrollContainer, 'scroll')
        .throttleTime(200)
        .mapTo('scroll');
      const noscroll = Observable.fromEvent(scrollContainer, 'scroll')
        .startWith(0) // init with no scroll.
        .debounceTime(300) // detect no scroll after 300 ms.
        .mapTo('scrollStop');
      this.scrollSubscription = merge(scroll, noscroll)
        .switchMap((e) => {
          if (e === 'scroll') {
            return Observable.of('scroll');
          }
          if (e === 'scrollStop' && count === 1) {
            return Observable.of('scrollStop');
          } else {
            count = 1;
            return Observable.of('no');
          }
        })
        // start the interval if there was no scroll. Stop the interval if there was a scroll.
        .subscribe(
          (event) => {
            if (event === 'scroll') {
              const concertinaTooltip = document.getElementById('concertina-tooltip');
              if (concertinaTooltip && !concertinaTooltip.attributes.hasOwnProperty('hidden')) {
                scrollContainer.scrollTop = this.previousScrollTop;
                return;
              }
              this.loadingLayer.show();
              const dx = scrollContainer.scrollLeft;
              const dy = scrollContainer.scrollTop;
              this.stage.container().style.transform = `translate(${dx}px, ${dy}px)`;
              this.stage.x(-dx);
              this.stage.y(-dy);
              this.stage.batchDraw();
            } else if (event === 'scrollStop') {
              const dx = scrollContainer.scrollLeft;
              const dy = scrollContainer.scrollTop;
              this.stage.container().style.transform = `translate(${dx}px, ${dy}px)`;
              this.stage.x(-dx);
              this.stage.y(-dy);
              this.stage.batchDraw();
              this.onContainerScroll(scrollContainer);
              this.previousScrollTop = scrollContainer.scrollTop;
              this.loadingLayer.hide();
            }
          });

      if (this.wheelEventListener) {
        scrollContainer.removeEventListener('wheel', this.wheelEventListener);
      }
      this.wheelEventListener = scrollContainer.addEventListener('wheel', (event: any) => {
        const concertinaTooltip = document.getElementById('concertina-tooltip');
        if (concertinaTooltip && !concertinaTooltip.attributes.hasOwnProperty('hidden')) {
          concertinaTooltip.scrollTop += event.deltaY;
          if (concertinaTooltip.scrollTop < 0) {
            concertinaTooltip.scrollTop = 0;
          }
        }
      }, false);
    }
  }

  onContainerScroll(scrollContainer) {
    // Lazy Loading
    const totalScrolled: number = scrollContainer.scrollTop - this.previousScrollTop;
    this.previousScrollTop = scrollContainer.scrollTop;
    const totalCellMoved = Math.round(totalScrolled / this.rectHeight);

    // VJ: 10-12-2018, if  scrollContainer.scrollTop 0 that means scroll is already at top and
    // so startRowIndex must be 0 to start from first row
    // This fix is solving issue where 'All' (first) row was not displayed sometime
    this.startRowIndex = scrollContainer.scrollTop === 0 ? 0 : parseInt((this.startRowIndex + totalCellMoved).toString(), 10);
    this.endRowIndex = parseInt((this.endRowIndex + totalCellMoved).toString(), 10);

    if (this.startRowIndex < 0) {
      this.startRowIndex = 0;
      let containerHeight: any = '';
      if (this.setContainerHeight()) {
        containerHeight = this.setContainerHeight();
        const containerHeightNumber: number = Number(containerHeight.replace('px', ''));
        const noOfRows = parseInt((containerHeightNumber / this.rectHeight).toString(), 10);
        this.startRowIndex = 0;
        this.endRowIndex = this.startRowIndex + noOfRows;
      }
    }
    if (this.concertinaDataService.concertinaData) {
      if (this.endRowIndex > this.concertinaDataService.concertinaData.groups.yGroups.length - 1) {
        this.endRowIndex = this.concertinaDataService.concertinaData.groups.yGroups.length;
        const containerHeight: any = this.setContainerHeight();
        const containerHeightNumber: number = Number(containerHeight.replace('px', ''));
        const noOfRows = parseInt((containerHeightNumber / this.rectHeight).toString(), 10);
        if (totalScrolled > 0) {
          this.startRowIndex = this.concertinaDataService.concertinaData.groups.yGroups.length - noOfRows;
        }
      }
    }
    this.generateRows(this.startRowIndex, this.endRowIndex, scrollContainer.scrollTop);
  }

  clearGroupAndSelectionLayers() {
    this.rowCounter = 0;
    if (this.leftHeaderLayer) {
      this.leftHeaderLayer.destroy();
    }
    if (this.subGroupLayer) {
      this.subGroupLayer.destroy();
    }
    if (this.FITextLayer) {
      this.FITextLayer.destroy();
    }
    if (this.swapSelectionLayer) {
      this.swapSelectionLayer.destroy();
    }
    if (!this.leftHeaderLayer) {
      this.leftHeaderLayer = this.layerTemplate.clone({
        name: 'leftHeaderLayer'
      });
    }
    if (!this.subGroupLayer) {
      this.subGroupLayer = this.layerTemplate.clone({
        name: 'subGroupLayer'
      });
    }
    if (!this.FITextLayer) {
      this.FITextLayer = this.layerTemplate.clone({
        name: 'FITextLayer'
      });
    }
    if (!this.swapSelectionLayer) {
      this.swapSelectionLayer = this.layerTemplate.clone({
        name: 'swapSelectionLayer'
      });
    }
    if (this.selectionLayer && this.selectionLayer.getChildren().length > 0) {
      this.selectionLayer.getChildren().each((selectionGroup) => {
        if (selectionGroup.getAttr('selectedInCart')) {
          delete this.cartRequstParams[selectionGroup.getAttr('name')];
          selectionGroup.destroy();
        }
      });
      this.selectionLayer.clearCache();
    }
  }

  generateHeaders() {
    this.dataX = 0;
    this.dataY = 0;
    if (this.headerLayer) {
      this.headerLayer.destroy();
    }
    if (this.cornerLayerLeft) {
      this.cornerLayerLeft.destroy();
    }
    if (this.cornerLayerRight) {
      this.cornerLayerRight.destroy();
    }
    //  Re-Initialize Layers which was destroyed in ClearLayers
    this.headerLayer = this.layerTemplate.clone({
      name: 'headerLayer'
    });

    this.cornerLayerLeft = this.layerTemplate.clone();
    this.cornerLayerRight = this.layerTemplate.clone();
    this.frameDetailsIcons = [];
    this.frameDrillableIcons = [];

    this.currentConcertinaCols = [];
    // Below rect will just to hide the table when scrolling up-down or left-right
    this.cornerLayerLeft.add(this.drawRect(this.dataX, this.dataY, '#f6f6f6',
      (this.rectWidth * this.colThreshold) - this.delta, this.rectHeight, 0));

    this.dataX = this.rectWidth * this.colThreshold;
    const leftArrowPosX = ((this.rectWidth * this.colThreshold) - this.delta);
    const buttonLeft = this.createButton('<', leftArrowPosX,
      this.dataY, this.rectHeight, this.delta, this.cornerLayerLeft, 'leftArrow');

    if (this.clickSubscription) {
      this.clickSubscription.unsubscribe();
    }
    this.clickSubscription = this.horizontalNavigationSub
      .debounceTime(500)
      .subscribe((e) => {
        this.loadingLayer.hide();
        if (e === 'left') {
          this.navigateLeft();
          return;
        }
        this.navigateRight();
      });
    buttonLeft.on('click', () => {
      if ((this.startColIndex - this.navigationClicksCount - 1) < 0) {
        return;
      }
      this.navigationClicksCount++;
      this.loadingLayer.show();
      this.headerLayer.x(this.headerLayer.x() + this.rectWidth);
      this.subGroupLayer.x(this.subGroupLayer.x() + this.rectWidth);
      this.subGroupLayer.batchDraw();
      this.headerLayer.batchDraw();
      if (this.frameSelectionEnabled) {
        this.selectionLayer.x(this.selectionLayer.x() + this.rectWidth);
        this.selectionLayer.batchDraw();
      }
      this.horizontalNavigationSub.next('left');
    });

    const rightArrowPosX = this.headerStage.width() - this.delta;
    const buttonRight = this.createButton('>', rightArrowPosX,
      this.dataY, this.rectHeight, this.delta, this.cornerLayerRight, 'rightArrow');

    buttonRight.on('click', (event) => {
      this.endColIndex = Math.round(this.endColIndex);
      if ((this.endColIndex + this.navigationClicksCount + 1) > this.concertinaDataService.concertinaData.groups.xGroups.length) {
        return;
      }
      this.navigationClicksCount++;
      this.loadingLayer.show();
      this.headerLayer.x(this.headerLayer.x() - this.rectWidth);
      this.subGroupLayer.x(this.subGroupLayer.x() - this.rectWidth);
      this.subGroupLayer.batchDraw();
      this.headerLayer.batchDraw();
      this.horizontalNavigationSub.next(event);
      if (this.frameSelectionEnabled) {
        this.selectionLayer.x(this.selectionLayer.x() - this.rectWidth);
        this.selectionLayer.batchDraw();
      }
    });

    this.generateHeaderCells();

    this.headerStage.add(this.cornerLayerLeft);
    this.headerStage.add(this.headerLayer);
    this.headerStage.add(this.cornerLayerRight);

    this.headerStage.width(this.getContainerWidth() + this.delta);
    this.headerStage.batchDraw();

    // Show / hide horizontal scrolling buttons
    const colWidth = Number(parseFloat((this.rectWidth * (this.concertinaDataService.concertinaData.groups.xGroups.length + this.colThreshold)).toString()).toFixed(8));
    const stageWidth = Number(parseFloat((this.stageWidth).toString()).toFixed(8));
    if (colWidth <= stageWidth) {
      this.cornerLayerLeft.hide();
      this.cornerLayerRight.hide();
      this.currentConcertinaPos = {
        x: 0, y: 0
      };
    } else {
      this.cornerLayerLeft.show();
      this.cornerLayerRight.show();
    }

    // Table layer should not go above tabLayer
    this.headerLayer.clearCache();
    this.headerLayer.x(this.currentConcertinaPos.x);
    this.headerLayer.y(this.currentConcertinaPos.y);
    this.cornerLayerRight.moveToTop();
    this.cornerLayerLeft.moveToTop();
  }

  /**
   * @description Generated skeletop loading animation
   * @author Amit Mahida
   * @param {number} [startIndex=this.startRowIndex]
   * @param {number} [endIndex=this.endRowIndex]
   * @memberof ConcertinaCanvasService
   */
  generateSkeletonRows(startIndex: number = this.startRowIndex, endIndex: number = this.endRowIndex) {
    this.loadingLayer.destroy();
    if (this.loadingLayer.getParent() === undefined) {
      this.loadingStage.add(this.loadingLayer);
    }
    let dataX = 0;
    let dataY = 0;

    // stop all running animations
    // creates memory issue so make sure to stop running anim
    this.anims.forEach(ani => ani.stop());
    this.anims = [];
    for (let index = startIndex; index <= endIndex; index++) {
      if (this.concertinaDataService.concertinaData.groups.yGroups[index]) {
        dataX = 0;
        const nodeConfig: any = {
          x: dataX,
          y: dataY,
          width: (this.rectWidth * this.colThreshold),
          height: this.rectHeight,
          fill: 'rgb(230, 230, 230, 0.4)',
          stroke: 'rgb(230, 230, 230)',
          strokeWidth: 2,
          transformsEnabled: 'position'
        };
        let rect = this.rectTemplate.clone(nodeConfig);
        this.loadingLayer.add(rect);
        let animRect;
        animRect = new Konva.Rect({
          x: dataX,
          y: dataY,
          width: this.rectWidth,
          height: 4,
          fillLinearGradientStartPoint: { x: 0, y: this.rectHeight },
          fillLinearGradientEndPoint: { x: this.rectWidth, y: this.rectHeight },
          fillLinearGradientColorStops:
            [0, 'rgb(85, 85, 85, 0.05)', 0.5, 'rgb(85, 85, 85, 0.12)', 1, 'rgb(85, 85, 85, 0.18)'],
        });
        this.loadingLayer.add(animRect);
        dataX += this.rectWidth * this.colThreshold;
        const totalNoOfColsInViewPort = (this.stageWidth / this.rectWidth) - this.colThreshold;
        for (let colIndex = 0; colIndex < totalNoOfColsInViewPort; colIndex++) {
          nodeConfig.x = dataX;
          nodeConfig.y = dataY;
          nodeConfig.width = this.rectWidth;
          rect = this.rectTemplate.clone(nodeConfig);
          this.loadingLayer.add(rect);
          dataX += this.rectWidth;
        }

        const amplitude = dataX / 10;
        const period = 3500;
        this.anims.push(new Konva.Animation((frame) => {
          animRect.x(amplitude * Math.tan(frame.time * 2 * Math.PI / period) + amplitude);
        }, this.loadingLayer));
        // }
        dataY += this.rectHeight;
      }
    }
    for (const anim of this.anims) {
      anim.start();
    }
    this.loadingLayer.hide();
  }

  /**
   * @description Updates concertina data & frame bookings on expand/collapse to maintain the state with swap zone
   * @author Amit Mahida
   * @param {number} frameId
   * @param {string} xKey
   * @memberof ConcertinaCanvasService
   */
  syncSwapZoneStateOnConcertina(frameId: number, xKey: string) {
    // Remove frames moved to swap zone from concertina data
    for (const swapData of this.swapZoneService.swaps) {
      for (const cref of swapData.campaignReferences) {
        const startXIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(cref.xStart);
        const endXIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(cref.xEnd);
        const currentXIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(xKey);
        if (currentXIndex >= startXIndex && currentXIndex <= endXIndex) {
          for (const frame of swapData.frames) {
            if (frame.frameId === frameId && frameId !== this.swapRevertFrameId) {
              this.updateFrameList(swapData, false, frameId);
              this.swapRevertFrameId = frameId;
            }
            this.updateConcertinaDataOnSwap(frame.y, xKey);
          }
        }
      }
    }

    // Add selected target frames back on concertina data
    for (const swapData of this.swapZoneService.swaps) {
      for (const cref of swapData.campaignReferences) {
        const startXIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(cref.xStart);
        const endXIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(cref.xEnd);
        const currentXIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(xKey);
        if (currentXIndex >= startXIndex && currentXIndex <= endXIndex) {
          for (const frame of swapData.frames) {
            if (frame.newY && frame.newFrame && frame.newFrame.length && frame.newFrame[0].frameId === frameId) {
              const bookingDetails = swapData.bookingDetailsColumnWise[xKey].filter(bookingDetail => bookingDetail.campaignReference === cref.campaignReference);
              this.updateConcertinaDataOnSwap(frame.newY, xKey, true, true, bookingDetails);
              this.updateFrameList(swapData, false, frame.newFrame[0].frameId);
            }
          }
        }
      }
    }
  }

  /**
   * Method to generate rows as per start and end index instead of generating all rows , Lazy loading
   * @param startIndex Start Index from where rows creation to be started, Default is 0
   * @param endIndex End Index till where rows creation to be required, Default is last row
   */
  generateRows(startIndex: number = this.startRowIndex, endIndex: number = this.endRowIndex, scrollTop: number = this.previousScrollTop) {

    if (this.concertinaDataService.concertinaData && Object.keys(this.concertinaDataService.concertinaData).length > 0) {
      this.currentConcertinaRows = [];
      this.clearGroupAndSelectionLayers();
      this.frameDrillableIcons = [];
      this.dataX = 0;
      this.dataY = 0;
      for (let index = startIndex; index <= endIndex; index++) {
        if (this.concertinaDataService.concertinaData.groups.yGroups[index]) {
          const element = this.concertinaDataService.concertinaData.groups.yGroups[index];
          if (this.concertinaDataService.concertinaData[element]) {
            this.generateRow(this.concertinaDataService.originalConcertinaData[element]);
          }
        }
      }
      this.swapRevertFrameId = -1;
      this.subGroupLayer.clearCache();
      this.leftHeaderLayer.clearCache();
      this.FITextLayer.clearCache();

      this.subGroupLayer.setAbsolutePosition({ x: 0, y: scrollTop });
      this.leftHeaderLayer.setAbsolutePosition({ x: 0, y: scrollTop });
      this.FITextLayer.setAbsolutePosition({ x: 0, y: scrollTop });

      // Fix for SM-1518
      if ((this.dataX + this.currentConcertinaPos.x) < this.getContainerWidth() && this.currentConcertinaPos.x < 0) {
        this.currentConcertinaPos.x = (this.currentConcertinaPos.x) + (this.previousDataX - this.dataX);
      }
      // Fix for SM-1507
      if (this.currentConcertinaPos.x > 0) {
        this.currentConcertinaPos.x = 0;
      }
      this.setPointerCursor();

      this.placeDrillableIcons();
      if (this.leftHeaderLayer.getParent() === undefined) {
        this.stage.add(this.leftHeaderLayer);
      }
      if (this.subGroupLayer.getParent() === undefined) {
        this.stage.add(this.subGroupLayer);
      }
      if (this.FITextLayer.getParent() === undefined) {
        this.stage.add(this.FITextLayer);
      }

      this.subGroupLayer.x(this.currentConcertinaPos.x);
      this.headerLayer.x(this.currentConcertinaPos.x);
      this.headerLayer.batchDraw();
      this.subGroupLayer.show();
      this.subGroupLayer.moveToTop();
      this.subGroupLayer.batchDraw();
      if (!this.showFI) {
        this.FITextLayer.hide();
        this.FITextLayer.batchDraw();
      } else {
        this.FITextLayer.x(this.currentConcertinaPos.x);
        this.FITextLayer.moveToTop();
        this.FITextLayer.show();
        this.FITextLayer.batchDraw();
      }
      if (this.frameSelectionEnabled) {
        this.selectionGroups = this.selectionLayer.getChildren();
        if (this.selectionLayer.getParent() === undefined) {
          this.stage.add(this.selectionLayer);
        }
        if (this.selectionGroups.length > 0) {
          setTimeout(() => {
            this.manageVisibilityOfSelectionGroups();
          }, 10);
        }
        this.updateSelectionStatus();
        this.selectionLayer.x(this.currentConcertinaPos.x);
        this.selectionLayer.batchDraw();
      }
      this.leftHeaderLayer.show();
      this.leftHeaderLayer.moveToTop();
      this.leftHeaderLayer.batchDraw();
      this.previousDataX = this.dataX;
      if (this.swapZoneService.swapMode) {
        if (this.swapSelectionLayer.getParent() === undefined) {
          this.stage.add(this.swapSelectionLayer);
        }
        if (this.swapSelectionLayer) {
          this.swapSelectionLayer.moveToTop();
        }
      }
    }

  }

  /**
   * @description Sets cursor pointers with respective layers
   * @author Amit Mahida
   * @memberof ConcertinaCanvasService
   */
  setPointerCursor() {
    this.headerLayer.on('mouseenter', () => {
      this.headerStage.container().style.cursor = 'pointer';
    });

    this.headerLayer.on('mouseleave', () => {
      this.headerStage.container().style.cursor = 'default';
    });

    this.leftHeaderLayer.on('mouseenter', () => {
      this.stage.container().style.cursor = 'pointer';
    });

    this.leftHeaderLayer.on('mouseleave', () => {
      this.stage.container().style.cursor = 'default';
    });

    this.cornerLayerLeft.on('mouseenter', () => {
      this.headerStage.container().style.cursor = 'pointer';
    });

    this.cornerLayerLeft.on('mouseleave', () => {
      this.headerStage.container().style.cursor = 'default';
    });

    this.cornerLayerRight.on('mouseenter', () => {
      this.headerStage.container().style.cursor = 'pointer';
    });

    this.cornerLayerRight.on('mouseleave', () => {
      this.headerStage.container().style.cursor = 'default';
    });
  }

  /**
   * @description Creates all cells for table header
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof ConcertinaCanvasService
   */
  generateHeaderCells(): boolean {
    if (this.concertinaDataService.concertinaData && this.concertinaDataService.concertinaData.groups) {
      this.currentConcertinaCols = [];
      for (let index = this.startColIndex; index < this.endColIndex; index++) {
        const g = this.concertinaDataService.concertinaData.groups.xGroups[index];
        if (this.concertinaDataService.concertinaData.xHeadings[g]) {
          const cell = this.concertinaDataService.concertinaData.xHeadings[g];

          this.headerLayer.add(this.drawRect(this.dataX, this.dataY, cell.colour,
            this.rectWidth, this.rectHeight, 0));

          const range = {
            startDate: cell.startDate,
            endDate: cell.endDate
          };
          const selectionAllowed = cell.selectionAllowed ? cell.selectionAllowed : false;
          const customData = {
            range, selectionAllowed, key: cell.key, x: this.dataX
          };
          this.headerLayer.add(this.drawTextOnRect(cell.displayText, this.dataX, this.dataY,
            cell.textColour, this.rectWidth, this.rectHeight, 'center', true,
            customData, 0));
          this.currentConcertinaCols.push({
            range,
            colKey: cell.key,
            position: {
              x: this.dataX,
              y: this.dataY,
            }
          });
          this.dataX += this.rectWidth;
        }
      }
    }
    return true;
  }

  /**
   * @description Handles left navigation
   * @memberof ConcertinaCanvasService
   */
  navigateLeft = () => {
    this.startColIndex -= this.navigationClicksCount;
    this.endColIndex -= this.navigationClicksCount;
    this.navigationClicksCount = 0;
    if (this.startColIndex < 0) {
      this.startColIndex = 0;
      this.manageEndColIndex();
    }
    this.updateTable(false);
  }

  /**
   * @description Handles right navigation
   * @memberof ConcertinaCanvasService
   */
  navigateRight = () => {
    const noOfColsOnCurrentViewPort = (this.stageWidth / this.rectWidth) - this.colThreshold;
    this.startColIndex += this.navigationClicksCount;
    this.endColIndex += this.navigationClicksCount;
    this.navigationClicksCount = 0;

    if (this.endColIndex > this.concertinaDataService.concertinaData?.groups?.xGroups?.length) {
      this.endColIndex = this.concertinaDataService.concertinaData.groups.xGroups.length;
    }
    if (noOfColsOnCurrentViewPort >= (this.endColIndex - this.startColIndex)) {
      this.startColIndex = Math.round(this.endColIndex - noOfColsOnCurrentViewPort);
    }
    this.updateTable(false);
  }

  /**
   * @description creates navigation buttons on concertina
   * @author Amit Mahida
   * @param {string} text
   * @param {number} x
   * @param {number} y
   * @param {number} height
   * @param {number} width
   * @param {Konva.Layer} layer
   * @param {*} [clickHandler=null]
   * @param {string} name
   * @memberof ConcertinaCanvasService
   */
  createButton(text: string, x: number, y: number, height: number, width: number, layer: Konva.Layer, name: string) {
    layer.add(this.drawRect(x, y, 'green', width, height, 1, 1));
    const button = this.drawTextOnRect(text, x, y, '#fff', width + 5, height + 5,
      'center', false, null, 5, 12, 'bold', undefined, name);
    button.on('mouseenter', () => {
      this.headerStage.container().style.cursor = 'pointer';
      button.strokeWidth(3);
    });
    button.on('mouseleave', () => {
      this.headerStage.container().style.cursor = 'default';
      button.strokeWidth(2);
    });
    layer.add(button);
    return button;
  }

  /**
   * @description This method will generate Rows in Concertina table
   * @author Amit Mahida
   * @param {*} rowData
   * @memberof ConcertinaCanvasService
   */
  generateRow(rowData: any) {
    this.rowCounter++;
    this.dataY += this.rowCounter === 1 ? 0 : this.rectHeight;
    this.dataX = 0;

    // First (Creating Group Title - First cell)
    this.generateGroupHeader(rowData);

    // Second (Creating all Cells in a row)
    this.generateRowCell(rowData);

    this.currentConcertinaRows.push({
      rowKey: rowData.key,
      position: {
        x: this.dataX,
        y: this.dataY,
      },
      isGroup: rowData.frameId === undefined,
      frameId: rowData.frameId === undefined ? rowData.key : rowData.frameId
    });
  }

  /**
   * @description Method to create Header in a row
   * @author Amit Mahida
   * @param {*} rowData
   * @memberof ConcertinaCanvasService
   */
  generateGroupHeader(rowData) {
    this.frameTextLimit = this.dataShareService.appName === AppNameEnum.visualplanner ? (this.rectWidth / 2) - 3 : (this.rectWidth / 2) + 2;
    this.leftHeaderLayer.add(this.drawRect(this.dataX, this.dataY, rowData.colour,
      (this.rectWidth * this.colThreshold), this.rectHeight));
    this.dataX += this.iconWidth;
    const frame = _.isUndefined(rowData.frameId) ? rowData.key : rowData.frameId;
    let xPos = this.dataX;
    for (let index = 2; index < 12; index++) {
      if (rowData.key.split(GLOBAL.CONCERTINA_SEPARATOR).length === index) {
        xPos = this.dataX + (this.standardSpacingForExpansion * index);
      }
    }
    this.leftHeaderLayer.add(this.drawTextOnRect(rowData.displayText, xPos, this.dataY,
      rowData.textColour, (this.rectWidth * this.colThreshold) - this.iconWidth,
      this.rectHeight, 'Left', true, { frame, key: rowData.key }, 5,
      11, 'normal', 'Roboto', rowData.key));
    if (frame !== null && this.frameDetailsEnabled && rowData.frameId) {
      const frameObj = {
        frame: rowData.frameId,
        position: {
          x: this.dataX + (this.rectWidth * (this.colThreshold - 1)),
          y: this.dataY + 8
        }
      };

      this.placeFrameDetailsIcons(frameObj);
    }
    if (rowData.drillable) {
      this.frameDrillableIcons.push({
        position: {
          x: xPos - 32,
          y: this.dataY + 9
        },
        key: rowData.key,
      });
    }
    this.dataX = this.rectWidth * this.colThreshold;
  }

  /**
   * @description If selection is already marked as green
   * @author Amit Mahida
   * @param {string} col
   * @param {string} row
   * @returns {boolean}
   * @memberof ConcertinaCanvasService
   */
  isSelectionGroupAvailable(col: string, row: string) {
    const selectionGroups: any = this.selectionLayer.getChildren();
    const index = selectionGroups.findIndex((group) => {
      return group.attrs.selectedColumns.indexOf(col) > -1 &&
        group.attrs.selectedRows.indexOf(row) > -1;
    });
    return index > -1;
  }

  getBookedSOT(bookingDetails: any[]) {
    let sot = '';
    if (bookingDetails && bookingDetails.length) {
      for (const bookingDetail of bookingDetails) {
        if (sot !== 'Mixed' && (sot === '' || sot === bookingDetail.sot)) {
          sot = bookingDetail.sot;
        } else {
          return 'Mixed';
        }
      }
    }
    return sot;
  }

  /**
   * @description Method to create a cell in a row
   * @author VJ, Amit
   * @param {Konva.Layer} newLayer
   * @param {*} rowDataObj
   * @returns
   * @memberof ConcertinaCanvasService
   */
  generateRowCell(rowDataObj) {
    return new Promise((resolve) => {
      let rowData = _.cloneDeep(rowDataObj);
      for (let index = this.startColIndex, len = this.endColIndex; index < len; ++index) {
        const xKey = this.concertinaDataService.concertinaData.groups.xGroups[index];
        if (xKey !== 'x' && rowDataObj.key !== 'y' && this.swapZoneService.swapMode && this.swapZoneService.swaps.length) {
          this.syncSwapZoneStateOnConcertina(rowDataObj.frameId, xKey);
          rowData = _.cloneDeep(this.concertinaDataService.concertinaData[rowDataObj.key]);
        }
        const xValues = rowData.xValues[xKey];
        let strokeColor = '#f6f6f6';
        let strokeWidth = 0;
        let rectHeight = this.rectHeight;
        let rectWidth = this.rectWidth;
        let rectColor = xValues.colour;
        let opacity = 1;
        if (xValues.statusMask && this.frameSelectionEnabled) {
          // tslint:disable-next-line:no-bitwise
          const selectedMaskCond = xValues.statusMask & FrameStatusMaskEnum.selected;
          if (selectedMaskCond === FrameStatusMaskEnum.selected
            && xValues.statusMask !== FrameStatusMaskEnum.selected) {
            // Settings for Partial Selection//
            strokeColor = '#f09e00';
            strokeWidth = 2;
            rectHeight = this.rectHeight - 2;
            rectWidth = this.rectWidth - 2;
          }
          if (xValues.swapped) {
            rectColor = '#13282c';
            opacity = 0.7;
          }
        }

        let textColour = xValues.textColour;
        if (xValues.values[this.selectedTabIndex].colour) {
          rectColor = xValues.values[this.selectedTabIndex].colour;
          textColour = xValues.values[this.selectedTabIndex].textColour;
        }

        const rect = this.drawRect(this.dataX, this.dataY,
          rectColor, this.rectWidth, this.rectHeight, 0, opacity);
        rect.stroke(strokeColor);
        rect.strokeWidth(strokeWidth);
        rect.height(rectHeight);
        rect.width(rectWidth);

        let cellText = '';
        let showTooltip = false;
        if (typeof xValues.values[this.selectedTabIndex] === 'string' || typeof xValues.values[this.selectedTabIndex] === 'number') {
          cellText = xValues.values[this.selectedTabIndex].length > 0 ? xValues.values[this.selectedTabIndex] :
            xValues.values[this.selectedTabIndex] === '' ? '' : '-';
        } else {
          if (this.tabValue === GLOBAL.indexTabId) {
            cellText = xValues.values[this.selectedTabIndex][this.selectedAudienceCategory];
          } else if (this.tabValue === GLOBAL.concertinaNotSelectedTab) {
            cellText = xValues.values[this.selectedTabIndex].sot;
          } else {
            if (xValues.values[this.selectedTabIndex].length === 0) {
              cellText = '';
            } else if (xValues.values[this.selectedTabIndex].length === 1) {
              cellText = xValues.values[this.selectedTabIndex][0];
            } else {
              cellText = this.initialConfig.userBundle['vp.text.multiple'];
              showTooltip = true;
            }
          }

        }
        const customData = {
          showTooltip,
          selectionAllowed: xValues.selectionAllowed,
          xKey: xValues.key, yKey: rowData.key,
          x: this.dataX, y: this.dataY,
          tooltipValue: xValues.values
        };
        const rectText = this.drawTextOnRect(cellText, this.dataX, this.dataY,
          textColour, this.rectWidth, this.rectHeight, 'center', true, customData, 5, 11,
          'normal', 'roboto', '');

        this.subGroupLayer.add(rect);
        this.subGroupLayer.add(rectText);

        if (this.frameSelectionEnabled) {
          const frame = _.isUndefined(rowData.frameId) ? rowData.key : rowData.frameId;
          const frameCode = rowData.displayText;
          let cellValues = {
            frame,
            frameCode,
            range: {
              startDate: this.concertinaDataService.concertinaData.xHeadings[xKey]['startDate'],
              endDate: this.concertinaDataService.concertinaData.xHeadings[xKey]['endDate'],
            },
            sot: this.getBookedSOT(this.concertinaDataService.concertinaData[rowData.key].xValues[xKey].bookingDetails),
            impressions: this.concertinaDataService.concertinaData[rowData.key].xValues[xKey].values[1]
          };
          const infoIcon = this.drawTextOnRect('', this.dataX + (this.rectWidth / 12),
            this.dataY + 3, '#fff', 20, 25, 'center', false,
            cellValues, 0, 14, 'bold', 'FontAwesome', 'bookingDetailsIcon');
          infoIcon.on('mousedown touchstart', () => {
            if (infoIcon.getAttr('cellValues')['frame']) {
              cellValues = infoIcon.getAttr('cellValues');
              const reqParamsForCampaignDetails = {
                frame: cellValues['frame'],
                frameCode: cellValues['frameCode'],
                range: [cellValues['range']],
                sot: cellValues.sot,
                impressions: cellValues.impressions,
                bookingDetails: []
              };
              reqParamsForCampaignDetails.bookingDetails = this.concertinaDataService.concertinaData[customData.yKey].xValues[customData.xKey].bookingDetails;
              this.$campaignDetailsSub.next(reqParamsForCampaignDetails);
            }
          });
          infoIcon.on('mouseenter', () => {
            // //console.log('infoIcon.on mouseenter');
            this.stage.container().style.cursor = 'pointer';
            infoIcon.show();
            infoIcon.moveToTop();
            this.subGroupLayer.batchDraw();
          });
          infoIcon.on('mouseleave', () => {
            // //console.log('infoIcon.on mouseleave');
            this.stage.container().style.cursor = 'default';
            infoIcon.hide();
            this.subGroupLayer.batchDraw();
          });
          rectText.on('mouseleave', () => {
            // //console.log('rectText.on mouseleave');
            this.stage.container().style.cursor = 'default';
            infoIcon.hide();
            this.subGroupLayer.batchDraw();
          });
          infoIcon.on('mouseleave', () => {
            // //console.log('rectText.on mouseleave');
            this.stage.container().style.cursor = 'default';
            infoIcon.hide();
            this.subGroupLayer.batchDraw();
          });
          rectText.on('mouseenter', () => {
            // //console.log('rectText.on mouseenter');
            const data = rectText.getAttr('cellValues');
            const yKey = data.yKey;
            const xKey2 = data.xKey;
            if (this.swapZoneService.swapMode
              && this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey2].swappable) {
              this.stage.container().style.cursor = 'grab';
            } else {
              this.stage.container().style.cursor = 'pointer';
            }
            infoIcon.show();
            infoIcon.moveToTop();
            this.subGroupLayer.batchDraw();
          });
          this.subGroupLayer.add(infoIcon);
          infoIcon.hide();
          let fiText = '';
          if (xValues.extraInfo === ExtraInfoMaskEnum.fixed) {
            fiText = 'F';
          }
          if (xValues.extraInfo === ExtraInfoMaskEnum.innovate) {
            fiText = 'I';
          }
          if (xValues.extraInfo === ExtraInfoMaskEnum.fixedAndInnovate) {
            fiText = 'F/I';
          }
          if (fiText !== '') {
            const text = this.drawTextOnRect(fiText, (this.dataX + (this.rectWidth / 2) + this.delta) - this.colThreshold,
              this.dataY + (this.rectHeight / 2), xValues.textColour, 15, 15, 'center', true, null, 0, 11,
              'normal', 'roboto', 'FI');
            this.FITextLayer.add(text);
          }
          if (rowData.xValues[xKey].statusMask === FrameStatusMaskEnum.selected) {
            const selectionGroup = this.addSelectionRect(rectText, true, rowData.xValues[xKey].isMarkedToDelete);
            if (selectionGroup) {
              this.selectionLayer.add(selectionGroup);
            }
          }
        }

        if (this.swapZoneService.swapMode && Object.keys(this.multiSelectionForSwap).length) {
          if (this.multiSelectionForSwap[rowData.frameId]) {
            const swapData = this.multiSelectionForSwap[rowData.frameId];
            const bookingStart = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(swapData.xStart);
            const bookingEnd = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(swapData.xEnd);
            const currentXCellIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(xKey);
            if (currentXCellIndex >= bookingStart && currentXCellIndex <= bookingEnd) {
              this.manageSwapSelectionRect(rowData.frameId, rectText);
            }
          }
        }
        // add the shape to the layer
        this.dataX += this.rectWidth;
      }
      resolve(true);
    });
  }

  /**
   * @description create a rect element , a Cell , which will display value
   * @author Amit Mahida
   * @param {number} x
   * @param {number} y
   * @param {string} backColor
   * @param {number} rectWidth
   * @param {number} rectHeight
   * @param {number} [strokeWidth=1]
   * @param {number} [opacity=1]
   * @returns
   * @memberof ConcertinaCanvasService
   */
  drawRect(x: number, y: number, backColor: string, rectWidth: number, rectHeight: number, strokeWidth = 1, opacity = 1) {
    const nodeConfig: any = {
      x,
      y,
      strokeWidth,
      opacity,
      width: rectWidth,
      height: rectHeight,
      fill: backColor,
      stroke: strokeWidth === 0 ? '#f6f6f6' : 'lightgrey',
      transformsEnabled: 'position'
    };
    return this.rectTemplate.clone(nodeConfig);
  }

  updateAvailableSOTFrameWise(frame: SwapFrame, swapData: SwapData, onlyToFrame: boolean, revert: boolean) {
    if (frame.isDigital) {
      const bookingDetailGroups = swapData && swapData.bookingDetailGroups ? swapData.bookingDetailGroups.map(bdg => bdg.groupId) : [];
      let selectedBDGs = swapData.campaignReferences.map(cref => cref.addedFromGroupId);
      selectedBDGs = _.uniq(selectedBDGs);
      const swapDoneForAllGroups = bookingDetailGroups.length && selectedBDGs.length ? selectedBDGs.length === bookingDetailGroups.length : false;
      if (swapData.campaignReferences && swapData.campaignReferences.length && swapDoneForAllGroups && onlyToFrame) {
        for (const cref of swapData.campaignReferences) {
          const startXIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(cref.xStart);
          const endXIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(cref.xEnd);
          if (startXIndex > -1 && endXIndex > -1) {
            for (let index = startXIndex; index <= endXIndex; index++) {
              const y = onlyToFrame ? frame.newY : frame.y;
              const xKey = this.concertinaDataService.originalConcertinaData.groups.xGroups[index];
              if (revert) {
                this.concertinaDataService.originalConcertinaData[y].xValues[xKey].availableSOT -= cref.sot;
              } else {
                this.concertinaDataService.originalConcertinaData[y].xValues[xKey].availableSOT += cref.sot;
              }
            }
          }
        }
      } else {
        for (const xKey in swapData.bookingDetailsColumnWise) {
          if (Object.prototype.hasOwnProperty.call(swapData.bookingDetailsColumnWise, xKey)) {
            const bookingDetails = swapData.bookingDetailsColumnWise[xKey];
            for (const bookingDetail of bookingDetails) {
              const y = onlyToFrame ? frame.newY : frame.y;
              if (revert) {
                this.concertinaDataService.originalConcertinaData[y].xValues[xKey].availableSOT -= bookingDetail.sot;
              } else {
                this.concertinaDataService.originalConcertinaData[y].xValues[xKey].availableSOT += bookingDetail.sot;
              }
            }
          }
        }
      }
    }
  }

  /**
   * @description Updates available SOT when we move a cell to swap zone / revert a swap
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @param {boolean} [revert=false]
   * @param {boolean} [onlyToFrame=false]
   * @memberof ConcertinaCanvasService
   */
  updateAvailableSOT(swapData: SwapData, revert = false, onlyToFrame = false, onlyToFrameIndex = -1) {
    if (onlyToFrameIndex > -1) {
      const frame = swapData.frames[onlyToFrameIndex];
      this.updateAvailableSOTFrameWise(frame, swapData, onlyToFrame, revert);
    } else {
      let frames = _.cloneDeep(swapData.frames);
      if (onlyToFrame) {
        frames = frames.filter(frame => frame.newY);
      }
      for (const frame of frames) {
        this.updateAvailableSOTFrameWise(frame, swapData, onlyToFrame, revert);
      }
    }
  }

  /**
   * @description Triggers swapdata to swap zone
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @memberof ConcertinaCanvasService
   */
  updateSwapZone(swapData: SwapData) {
    this.updateAvailableSOT(swapData);
    this.swapZoneService.setSwapData(swapData);
    this.$swapZoneOpenSub.next();
  }

  /**
   * @description Updates concertina data when moved campaigns to swap zone / revert a swap
   * @author Amit Mahida
   * @param {string} yKey
   * @param {string} xKey
   * @param {boolean} [revert=false]
   * @param {string} [campaignReference]
   * @param {boolean} [swapDone]
   * @memberof ConcertinaCanvasService
   */
  updateConcertinaDataOnSwap(yKey: string, xKey: string, revert = false, swapDone?: boolean, bookingDetails?: SwapCampaign[]) {
    if (revert) {
      if (swapDone) {
        this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].swapped = true;
        if (this.concertinaDataService.concertinaData[yKey]) {
          this.concertinaDataService.concertinaData[yKey].xValues[xKey].swapped = true;
        }
      }
      for (const bookingDetail of bookingDetails) {
        let bookingDetailsIndex = -1;
        if (this.concertinaDataService.originalConcertinaData[yKey].isDigital) {
          bookingDetailsIndex = this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].bookingDetails
            .findIndex(bd => bd.campaignReference === bookingDetail.campaignReference
              && bd.startDate === bookingDetail.startDate
              && bd.endDate === bookingDetail.endDate
              && bd.sot === bookingDetail.sot);
        } else {
          bookingDetailsIndex = this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].bookingDetails
            .findIndex(bd => bd.campaignReference === bookingDetail.campaignReference
              && bd.startDate === bookingDetail.startDate
              && bd.endDate === bookingDetail.endDate);
        }
        if (bookingDetailsIndex === -1) {
          if (this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].values[this.selectedTabIndex].indexOf(bookingDetail.campaignReference) === -1) {
            this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].values[this.selectedTabIndex].push(_.cloneDeep(bookingDetail.campaignReference));
          }
          this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].bookingDetails.push(_.cloneDeep(bookingDetail));
          if (this.concertinaDataService.concertinaData[yKey]) {
            if (this.concertinaDataService.concertinaData[yKey].xValues[xKey].values[this.selectedTabIndex].indexOf(bookingDetail.campaignReference) === -1) {
              this.concertinaDataService.concertinaData[yKey].xValues[xKey].values[this.selectedTabIndex].push(_.cloneDeep(bookingDetail.campaignReference));
            }
            this.concertinaDataService.concertinaData[yKey].xValues[xKey].bookingDetails.push(_.cloneDeep(bookingDetail));
          }
        }
      }
    } else {
      if (this.concertinaDataService.originalConcertinaData[yKey]) {
        delete this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].swapped;
      }
      if (this.concertinaDataService.concertinaData[yKey]) {
        delete this.concertinaDataService.concertinaData[yKey].xValues[xKey].swapped;
      }
      if (bookingDetails) {
        for (const bookingDetail of bookingDetails) {
          const bookingDetailsIndex = this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].bookingDetails
            .findIndex(bd => bd.campaignReference === bookingDetail.campaignReference
              && bd.startDate <= bookingDetail.startDate
              && bd.endDate >= bookingDetail.endDate
              && bd.sot === bookingDetail.sot);
          if (bookingDetailsIndex > -1) {
            this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].bookingDetails.splice(bookingDetailsIndex, 1);
            const crefIndex = this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].values[this.selectedTabIndex]
              .indexOf(bookingDetail.campaignReference);
            if (crefIndex > -1) {
              this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].values[this.selectedTabIndex].splice(crefIndex, 1);
            }
            if (this.concertinaDataService.concertinaData[yKey]) {
              this.concertinaDataService.concertinaData[yKey].xValues[xKey].bookingDetails.splice(bookingDetailsIndex, 1);
              if (crefIndex > -1) {
                this.concertinaDataService.concertinaData[yKey].xValues[xKey].values[this.selectedTabIndex].splice(crefIndex, 1);
              }
            }
          }
        }
      } else {
        this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].values[this.selectedTabIndex] = [];
        this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].bookingDetails = [];
        if (this.concertinaDataService.concertinaData[yKey]) {
          this.concertinaDataService.concertinaData[yKey].xValues[xKey].values[this.selectedTabIndex] = [];
          this.concertinaDataService.concertinaData[yKey].xValues[xKey].bookingDetails = [];
        }
      }
    }
  }

  /**
   * @description This method returns false if the swapping is not allowed
   * eg 1. if Week level data is already present in the swap data, it will restrict the day level data to be added in the swap data.
   * eg 2. if Day Level data is there in swapData, on adding week level, all the day level data for the mathcing criteria will
   * reverted from the swap data and week level data will be added.
   * @author Shreni Shah
   * @date 2020-07-02
   * @param {string} xKey
   * @param {string} yKey
   * @returns
   * @memberof ConcertinaCanvasService
   */
  allowSwapping(xKey: string, yKey: string): boolean {
    const swaps = this.swapZoneService.swaps;
    if (swaps.length === 0) {
      return true;
    }
    const bookingDetails = this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].bookingDetails;
    const frameId = this.concertinaDataService.originalConcertinaData[yKey].frameId;
    const swapsByCampaingRefrence = swaps.filter((swap) => {
      return (
        swap.campaignReferences.findIndex(cmprf => bookingDetails.findIndex(bd => bd.campaignReference === cmprf.campaignReference) > -1) > -1 &&
        swap.frames.findIndex(frame => frame.frameId === frameId) > -1
      );
    });
    if (swapsByCampaingRefrence.length) {
      for (const bookingDetail of bookingDetails) {
        const selectedCellDates = {
          'startDate': bookingDetail.startDate,
          'endDate': bookingDetail.endDate
        };
        for (const swap of swapsByCampaingRefrence) {
          let revertSwap = false;
          for (const cmprefSwap of swap.campaignReferences) {
            const swapDates = {
              'startDate': cmprefSwap.startDate,
              'endDate': cmprefSwap.endDate
            };

            if (this.isSubset(selectedCellDates, swapDates)) {
              return false;
            } else if (this.isSubset(swapDates, selectedCellDates)) {
              revertSwap = true;
            }
          }
          if (revertSwap) {
            const indexOfSwap = swaps.findIndex(localswap => localswap.id === swap.id);
            this.swapZoneService.revertSwap(swap, indexOfSwap);
          }
        }
      }
    }
    return true;
  }

  /**
   * @description This method will check if first data is subset of second data
   * @author Shreni Shah
   * @date 2020-07-03
   * @param {*} first
   * @param {*} second
   * @returns {boolean}
   * @memberof ConcertinaCanvasService
   */
  isSubset(first: any, second: any): boolean {
    if (first.startDate >= second.startDate && first.endDate <= second.endDate) {
      return true;
    } return false;
  }

  /**
   * @description handles click to swap
   * @author Amit Mahida
   * @param {string} xKey
   * @param {string} yKey
   * @memberof ConcertinaCanvasService
   */
  handleSwap(xKey: string, yKey: string) {
    const allowSwapping = this.allowSwapping(xKey, yKey);
    if (!allowSwapping) {
      this.logHelper.logInfo(this.userBundle['vip.concertina.swapNotPossible']
        || 'Swap is not possible here!');
      return;
    }
    const swapData: SwapData = {
      id: Date.now(),
      frames: [],
      campaignReferences: [],
      xStart: xKey,
      xEnd: xKey,
    };
    // Add campaign ref to swap data
    const bookingDetails = this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].bookingDetails;
    swapData.bookingDetailsColumnWise = {};
    const isDigital = this.concertinaDataService.originalConcertinaData[yKey].isDigital;
    if (isDigital && bookingDetails.length > 1) {
      swapData.bookingDetailGroups = [];
      const tempBookingDetails = [];
      const groupId = bookingDetails.map(b => b.campaignReference).join('');
      for (const bookingDetail of bookingDetails) {
        tempBookingDetails.push({
          xStart: xKey,
          xEnd: xKey,
          ...bookingDetail
        });
      }
      swapData.bookingDetailGroups.push({
        groupId,
        bookingDetails: tempBookingDetails,
        allowed: true
      });
    } else {
      for (const bookingDetail of bookingDetails) {
        swapData.campaignReferences.push({
          xStart: xKey,
          xEnd: xKey,
          ...bookingDetail
        });
      }
    }
    swapData.bookingDetailsColumnWise[xKey] = bookingDetails;

    const vuId = this.concertinaDataService.originalConcertinaData[yKey].visualUnitId;
    if (vuId) {
      for (const yGroup of this.concertinaDataService.originalConcertinaData.groups.yGroups) {
        if (this.concertinaDataService.originalConcertinaData[yGroup].visualUnitId && this.concertinaDataService.originalConcertinaData[yGroup].visualUnitId === vuId) {
          const vuFrame: SwapFrame = {
            frameId: this.concertinaDataService.originalConcertinaData[yGroup].frameId,
            visualUnitId: this.concertinaDataService.originalConcertinaData[yGroup].visualUnitId,
            frameCode: this.concertinaDataService.originalConcertinaData[yGroup].displayText,
            productFormatId: this.concertinaDataService.originalConcertinaData[yGroup].productFormatId,
            y: yGroup,
            isDigital: this.concertinaDataService.originalConcertinaData[yGroup].isDigital,
            availableSOT: this.concertinaDataService.originalConcertinaData[yGroup].xValues[xKey].availableSOT,
            campaignReference: []
          };
          if (vuFrame.isDigital && swapData.campaignReferences.length === 1) {
            vuFrame.targetSOT = swapData.campaignReferences[0].sot;
          }
          for (let index = 0; index < bookingDetails.length; index++) {
            const campaignRef = bookingDetails[index].campaignReference;
            vuFrame.campaignReference.push(campaignRef);
          }
          swapData.frames.push(vuFrame);
          this.updateConcertinaDataOnSwap(yGroup, xKey);
        }
      }
    } else {
      const frame: SwapFrame = {
        frameId: this.concertinaDataService.originalConcertinaData[yKey].frameId,
        visualUnitId: this.concertinaDataService.originalConcertinaData[yKey].visualUnitId,
        frameCode: this.concertinaDataService.originalConcertinaData[yKey].displayText,
        productFormatId: this.concertinaDataService.originalConcertinaData[yKey].productFormatId,
        y: yKey,
        isDigital: this.concertinaDataService.originalConcertinaData[yKey].isDigital,
        availableSOT: this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].availableSOT,
        campaignReference: []
      };
      if (isDigital && swapData.campaignReferences.length === 1) {
        frame.targetSOT = swapData.campaignReferences[0].sot;
      }
      for (let index = 0; index < bookingDetails.length; index++) {
        const campaignRef = bookingDetails[index].campaignReference;
        frame.campaignReference.push(campaignRef);
      }
      swapData.frames.push(frame);
      this.updateConcertinaDataOnSwap(yKey, xKey);
    }

    this.updateFrameList(swapData, false);
    this.updateTable();
    return swapData;
  }
  /**
   * @description This will return false and will not allow to add the swap data, if new data is subset of already added data.
   * eg If week level already exits in multilevelselectionForSwap, day level for the same week for the particular campaing refrence
   * will not be allowed to be added in the selection layer.
   * @author Shreni Shah
   * @date 2020-07-02
   * @param {*} xKey
   * @param {*} yKey
   * @returns {boolean}
   * @memberof ConcertinaCanvasService
   */
  isAllowedToAddSwapData(xKey: string, yKey: string): boolean {
    const frameId = this.concertinaDataService.originalConcertinaData[yKey].frameId;
    const bookingDetails = this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].bookingDetails;

    for (const bookingDetail of bookingDetails) {
      const startDate = bookingDetail.startDate;
      const endDate = bookingDetail.endDate;
      const selectedCell = {
        startDate,
        endDate
      };
      if (Object.keys(this.multiSelectionForSwap).length === 0 || !this.multiSelectionForSwap[frameId] || !this.multiSelectionForSwap[frameId].campaignReferences) {
        return true;
      }
      const addedCampaingRefrences = this.multiSelectionForSwap[frameId].campaignReferences.filter(cmref => cmref.campaignReference === bookingDetail.campaignReference);
      for (const cmref of addedCampaingRefrences) {
        const alreadyAddedData = {
          'startDate': cmref.startDate,
          'endDate': cmref.endDate
        };
        if (this.isSubset(selectedCell, alreadyAddedData)) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * @description Updates multi selection swap data bookings
   * @author Amit Mahida
   * @param {number} frameId
   * @param {string} campaignReference
   * @param {string} xKey
   * @param {SwapCampaign} bookingDetail
   * @memberof ConcertinaCanvasService
   */
  updateMultiSwapData(frameId: number, campaignReference: string, xKey: string, bookingDetail: { startDate: string, endDate: string, sot?: number }, isDigital = false) {
    if (!isDigital) {
      const campaignExistInSelection = this.multiSelectionForSwap[frameId].campaignReferences.findIndex(cref => cref.campaignReference === campaignReference);
      const existingBooking = this.multiSelectionForSwap[frameId].campaignReferences[campaignExistInSelection];
      const isCurrentBookingContinuous = existingBooking ? moment(existingBooking.endDate).add(1, 'hour').startOf('hour').format('YYYY-MM-DDTHH:mm:ss.SSS') === bookingDetail.startDate : false;
      if (campaignExistInSelection > -1 && isCurrentBookingContinuous) {
        this.multiSelectionForSwap[frameId].campaignReferences[campaignExistInSelection].endDate = bookingDetail.endDate;
        this.multiSelectionForSwap[frameId].campaignReferences[campaignExistInSelection].xEnd = xKey;
      } else {
        const isBookingAlreadyPresentInSelection = this.multiSelectionForSwap[frameId].campaignReferences.length
          && (this.multiSelectionForSwap[frameId].campaignReferences.findIndex(cref => cref.campaignReference === campaignReference
            && cref.startDate <= this.concertinaDataService.originalConcertinaData.xHeadings[xKey].startDate
            && cref.endDate >= this.concertinaDataService.originalConcertinaData.xHeadings[xKey].endDate) > -1);
        if (!isBookingAlreadyPresentInSelection) {
          this.multiSelectionForSwap[frameId].campaignReferences.push({
            campaignReference,
            startDate: bookingDetail.startDate,
            endDate: bookingDetail.endDate,
            xStart: xKey,
            xEnd: xKey,
            sot: bookingDetail.sot
          });
        }
      }
    }
  }

  /**
   * @description adds custom swap data when ctrl/shift is pressed
   * @author Amit Mahida
   * @param {string} xKey
   * @param {string} yKey
   * @returns
   * @memberof ConcertinaCanvasService
   */
  addMultiSwapData(xKey: string, yKey: string) {
    if (!_.isUndefined(this.concertinaDataService.originalConcertinaData[yKey].isDigital)
      && !this.concertinaDataService.originalConcertinaData[yKey].isDigital && !this.initialConfig.uiControl.enableCustomPeriodSwapForPaper) {
      this.logHelper.logInfo(this.userBundle['vip.concertina.customPeriodSwapNotAllowed'] || 'Custom period swap is not allowed');
      return false;
    }
    const swapData: SwapData = {
      id: Date.now(),
      campaignReferences: [],
      frames: [],
      xStart: xKey,
      xEnd: xKey
    };
    const isAllowed = this.isAllowedToAddSwapData(xKey, yKey);
    if (!isAllowed) {
      return;
    }
    const frameId = this.concertinaDataService.originalConcertinaData[yKey].frameId;
    if (this.multiSelectionForSwap[frameId]) {
      const currentSwapData = this.multiSelectionForSwap[frameId];

      if (this.controlPressed && !this.shiftPressed) {
        if (this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(currentSwapData.xEnd) !== (this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(xKey) - 1)) {
          this.logHelper.logInfo(this.userBundle['vip.swap.swapNotInSequence'] || 'Selection is not in sequencial timeline');
          return false;
        } else if (currentSwapData.xEnd && this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(currentSwapData.xEnd) !== (this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(xKey) - 1)) {
          this.logHelper.logInfo(this.userBundle['vip.swap.swapNotInSequence'] || 'Selection is not in sequencial timeline');
          return false;
        }
        const bookingDetails = this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].bookingDetails;
        for (const bookingDetail of bookingDetails) {
          this.updateMultiSwapData(frameId, bookingDetail.campaignReference, xKey, bookingDetail, this.concertinaDataService.originalConcertinaData[yKey].isDigital);
        }
        this.multiSelectionForSwap[frameId].bookingDetailsColumnWise[xKey] = bookingDetails;

      }

      if (this.shiftPressed) {
        const lastColSelectedIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(this.multiSelectionForSwap[frameId].xEnd);
        const selectedColIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(xKey);
        if (lastColSelectedIndex > selectedColIndex) {
          this.logHelper.logInfo(this.userBundle['vip.swap.swapNotInSequence'] || 'Selection is not in sequencial timeline');
          return false;
        }
        for (let index = lastColSelectedIndex + 1; index <= selectedColIndex; index++) {
          const xKeySeq = this.concertinaDataService.originalConcertinaData.groups.xGroups[index];

          const bookingDetails = this.concertinaDataService.originalConcertinaData[yKey].xValues[xKeySeq].bookingDetails;
          for (const bookingDetail of bookingDetails) {
            this.updateMultiSwapData(frameId, bookingDetail.campaignReference, xKeySeq, bookingDetail, this.concertinaDataService.originalConcertinaData[yKey].isDigital);
          }
          this.multiSelectionForSwap[frameId].bookingDetailsColumnWise[xKeySeq] = bookingDetails;
        }
      }
      this.multiSelectionForSwap[frameId].xEnd = xKey;
      return true;
    } else {
      swapData.bookingDetailsColumnWise = {};
      const bookingDetails = this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].bookingDetails;
      if (!this.concertinaDataService.originalConcertinaData[yKey].isDigital) {
        for (const bookingDetail of bookingDetails) {
          swapData.campaignReferences.push({
            xStart: xKey,
            xEnd: xKey,
            ...bookingDetail
          });
        }
      }
      swapData.bookingDetailsColumnWise[xKey] = bookingDetails;
      swapData.frames.push({
        frameId,
        frameCode: this.concertinaDataService.originalConcertinaData[yKey].displayText,
        productFormatId: this.concertinaDataService.originalConcertinaData[yKey].productFormatId,
        visualUnitId: this.concertinaDataService.originalConcertinaData[yKey].visualUnitId,
        y: yKey,
        isDigital: this.concertinaDataService.originalConcertinaData[yKey].isDigital
      });
      this.multiSelectionForSwap[frameId] = swapData;
      return true;
    }
  }

  /**
   * @description It sorts bookings on campaign ref & start date then merges the continuous bookings
   * @author Amit Mahida
   * @param {any[]} bookings
   * @returns {any[]}
   * @memberof ConcertinaCanvasService
   */
  mergeContinuousBookings(bookings: any[]): any[] {
    const sortedBookings = GLOBAL.sortArrayByKey(bookings, 'startDate');
    const totalBookings = sortedBookings.length - 1;
    for (let index = totalBookings; index >= 0; index--) {
      const currentBooking = sortedBookings[index];
      for (let similarBookingIndex = index; similarBookingIndex >= 0; similarBookingIndex--) {
        const previousBooking = sortedBookings[similarBookingIndex];
        if (previousBooking.campaignReference === currentBooking.campaignReference && similarBookingIndex !== index) {
          // We have similar booking
          if (previousBooking.endDate === moment(currentBooking.startDate).subtract(1, 'hour').endOf('hour').format('YYYY-MM-DDTHH:mm:ss.SSS')) {
            previousBooking.endDate = currentBooking.endDate;
            previousBooking.xEnd = currentBooking.xEnd;
            sortedBookings.splice(index, 1);
            break;
          }
        }
      }
    }
    return sortedBookings;
  }

  /**
   * @description returns total no of unique campaigns selected in custom range swap
   * @author Amit Mahida
   * @returns
   * @memberof ConcertinaCanvasService
   */
  getSelectedBookingsInMultiSwap(): number {
    const uniqueCampaigns = [];
    for (const key in this.multiSelectionForSwap) {
      if (this.multiSelectionForSwap.hasOwnProperty(key)) {
        const swap = this.multiSelectionForSwap[key];
        swap.campaignReferences.forEach((cref) => {
          if (uniqueCampaigns.indexOf(cref.campaignReference) === -1) {
            uniqueCampaigns.push(cref.campaignReference);
          }
        });
      }
    }
    return uniqueCampaigns.length;
  }

  /** @description This method check if the selection layer created by the user is swappable or not
  * eg. If frame for the entire week is already there in swap data, if user selects the layer which has
  * the days belonging to the same week, it will not be allowed to moved to swap zone.
  * @author Shreni Shah
  * @date 2020-07-03
  * @param {*} swapData
  * @param {*} frameId
  * @returns
  * @memberof ConcertinaCanvasService
  */
  isSelectionLayerSwappable(swapData: SwapData, frameId: string): boolean {
    const starIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(swapData.xStart);
    const endIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(swapData.xEnd);
    let selectionAllowedOnAllCells = false;
    for (let i = starIndex; i <= endIndex; i++) {
      const xKey = this.concertinaDataService.originalConcertinaData.groups.xGroups[i];
      const frameObj = swapData.frames.filter(frame => frame.frameId === parseInt(frameId, 10));
      const yKey = frameObj[0].y;
      selectionAllowedOnAllCells = this.allowSwapping(xKey, yKey);
      if (!selectionAllowedOnAllCells) {
        break;
      }
    }
    return selectionAllowedOnAllCells;
  }

  /**
   * @description Moves all custom selection for swaping to swap zone
   * @author Amit Mahida
   * @memberof ConcertinaCanvasService
   */
  performMultiSwap() {
    if ((this.swapZoneService.getTotalCampaignRefsForSwap() + this.getSelectedBookingsInMultiSwap())
      > this.initialConfig.uiControl.perSwapCampaignsLimit) {
      this.logHelper.logInfo(`${this.userBundle['vip.concertina.abovePerSwapCampaignsLimit']}${this.initialConfig.uiControl.perSwapCampaignsLimit}`
        || `Max limit for campaigns swapped at a time is ${this.initialConfig.uiControl.perSwapCampaignsLimit}`);
      return;
    }
    this.swapSelectionLayer.destroyChildren();
    for (const key in this.multiSelectionForSwap) {
      if (this.multiSelectionForSwap.hasOwnProperty(key)) {
        const swapData = this.multiSelectionForSwap[key];
        // Add vu frames
        const allowSwapping = this.isSelectionLayerSwappable(swapData, key);
        if (!allowSwapping) {
          const frameObj = swapData.frames.filter(frame => frame.frameId === parseInt(key, 10));
          this.logHelper.logInfo(`Can't move frame ${frameObj[0].frameCode} to SwapZone as it is there is swap zone for entire week`);
          continue;
        }
        for (const frame of swapData.frames) {
          if (frame.visualUnitId) {
            const yGroups = this.concertinaDataService.originalConcertinaData.groups.yGroups;
            for (const yKey of yGroups) {
              if (this.concertinaDataService.originalConcertinaData[yKey].frameId !== frame.frameId
                && this.concertinaDataService.originalConcertinaData[yKey].visualUnitId === frame.visualUnitId
                && swapData.frames.findIndex(vuFrame => vuFrame.frameId === this.concertinaDataService.originalConcertinaData[yKey].frameId) === -1) {
                swapData.frames.push({
                  frameId: this.concertinaDataService.originalConcertinaData[yKey].frameId,
                  frameCode: this.concertinaDataService.originalConcertinaData[yKey].displayText,
                  productFormatId: this.concertinaDataService.originalConcertinaData[yKey].productFormatId,
                  visualUnitId: this.concertinaDataService.originalConcertinaData[yKey].visualUnitId,
                  y: yKey,
                  isDigital: this.concertinaDataService.originalConcertinaData[yKey].isDigital
                });
              }
            }
          }
        }
        // Update concertina for each frame swapped
        for (const frame of swapData.frames) {
          if (this.multiSelectionForSwap[frame.frameId]) {
            // Remove duplicate selection against VU frames
            delete this.multiSelectionForSwap[frame.frameId];
          }
          this.updateConcertinaDataForCustomDates(frame.y, swapData.xStart, swapData.xEnd);
        }
        if (!swapData.campaignReferences.length) {
          // Group digital swap data
          const bookingDetailsColumnWise = Object.keys(swapData.bookingDetailsColumnWise)
            .map((xKey) => {
              const cref = swapData.bookingDetailsColumnWise[xKey].map((b) => {
                return { ...b, xStart: xKey, xEnd: xKey };
              });
              return _.groupBy(cref, 'campaignReference');
            });
          let bookingGroups: SwapBookingDetails[] = [];
          for (const bookingDetail of bookingDetailsColumnWise) {
            const bGroupIndex = bookingGroups.findIndex(b => b.groupId === Object.keys(bookingDetail).join(''));
            if (bookingGroups.length && bGroupIndex > -1) {
              bookingGroups[bGroupIndex].bookingDetails = [
                ...bookingGroups[bGroupIndex].bookingDetails,
                ..._.flatten(_.values(bookingDetail))
              ];
            } else {
              bookingGroups.push({
                groupId: Object.keys(bookingDetail).join(''),
                bookingDetails: _.flatten(_.values(bookingDetail)),
                allowed: true
              });
            }
          }
          bookingGroups = bookingGroups.filter(bg => bg.bookingDetails.length);
          for (const bookingGroup of bookingGroups) {
            bookingGroup.bookingDetails = this.mergeContinuousBookings(bookingGroup.bookingDetails);
          }
          swapData.bookingDetailGroups = bookingGroups;
        } else {
          swapData.campaignReferences = this.mergeContinuousBookings(swapData.campaignReferences);
        }

        this.updateFrameList(swapData, false);
        this.updateSwapZone(swapData);
      }
    }
    this.updateTable();
  }

  /**
   * @description Method to create a text on previsouly created Rect
   * @author VJ - Amit Mahida
   * @param {string} text
   * @param {number} x
   * @param {number} y
   * @param {string} color
   * @param {number} rectWidth
   * @param {number} rectHeight
   * @param {string} [align='center']
   * @param {boolean} [events=false]
   * @param {*} [customData=null]
   * @param {number} [padding=0]
   * @param {number} [fontSize=11]
   * @param {string} [fontStyle='normal']
   * @param {string} [fontFamily='Roboto']
   * @param {string} [name='']
   * @returns {Konva.Text}
   * @memberof ConcertinaCanvasService
  */
  drawTextOnRect(text: string, x: number, y: number, color: string, rectWidth: number,
    rectHeight: number, align = 'center', events = false,
    customData: any = null, padding = 0, fontSize = 11,
    fontStyle = 'normal', fontFamily = 'Roboto', name = ''): Konva.Text {

    const nodeConfig: any = {
      x,
      text,
      align,
      padding,
      fontSize,
      fontFamily,
      fontStyle,
      name,
      y: y + 7,
      width: rectWidth,
      height: rectHeight - 11,
      fill: color,
      id: customData ? customData.xKey + customData.yKey + name : null
      // perfectDrawEnabled: false
    };

    const simpleText = this.textTemplate.clone(nodeConfig);
    simpleText.setAttr('cellValues', customData);

    if (events) {
      simpleText.on('click', () => {
        const cellValues = simpleText.getAttr('cellValues');
        if (!cellValues) {
          return;
        }
        const yKey = cellValues.yKey;
        const xKey = cellValues.xKey;
        if (this.swapZoneService.swapMode && this.dataShareService.appName === AppNameEnum.visualplanner
          && simpleText.getParent().getAttr('name') === 'subGroupLayer' && this.tabValue === GLOBAL.vpSwappingTabId) {
          if (this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].swappable
            && !this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].swapped
            && this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].bookingDetails
          ) {
            if (this.controlPressed || this.shiftPressed) {
              if (this.addMultiSwapData(xKey, yKey)) {
                const frameId = this.concertinaDataService.originalConcertinaData[yKey].frameId;
                this.manageSwapSelectionRect(frameId, simpleText);
              }
              return;
            }
            if ((this.swapZoneService.getTotalCampaignRefsForSwap() + 1) > this.initialConfig.uiControl.perSwapCampaignsLimit) {
              this.logHelper.logInfo(`${this.userBundle['vip.concertina.abovePerSwapCampaignsLimit']}${this.initialConfig.uiControl.perSwapCampaignsLimit}`
                || `Max limit for campaigns swapped at a time is ${this.initialConfig.uiControl.perSwapCampaignsLimit}`);
              return;
            }
            const swapData = this.handleSwap(xKey, yKey);
            if (swapData) {
              this.updateSwapZone(swapData);
            }
            return;
          } else {
            this.logHelper.logInfo(this.userBundle['vip.concertina.swapNotPossible']
              || 'Swap is not possible here!');
            return;
          }
        }

        if (this.frameSelectionEnabled && simpleText.getParent().getAttr('name') === 'subGroupLayer'
          && cellValues.selectionAllowed) {
          if (SystemFlags.readOnlyWorkspace && !SystemFlags.splitable) {
            // do nothing
          } else {
            const selectionGroup = this.addSelectionRect(simpleText, false);
            if (selectionGroup) {
              this.selectionLayer.add(selectionGroup);
              this.selectionLayer.moveToTop();
              selectionGroup.draw();
              this.updateSelectionStatus();
            }
          }
          return;
        }
        if (!this.frameSelectionEnabled && simpleText.getParent().getAttr('name') === 'subGroupLayer') {
          return;
        }
        if (!cellValues.selectionAllowed && simpleText.getParent().getAttr('name') === 'subGroupLayer') {
          this.logHelper.logError(this.userBundle['vip.concertina.selectionNotAllowed']
            || 'Selection is not allowed here!');
          return;
        }
        if (!_.isUndefined(cellValues)
          && cellValues.key === 'y') {
          return;
        }
        if (!_.isUndefined(cellValues)
          && cellValues.key.includes('y')) {
          this.manageDrilledDownKeys(cellValues.key);
        }
        if (!_.isUndefined(cellValues)) {
          this.manageExpandCollapse(cellValues.key);
        }
      });
      const tooltipData = {
        show: true,
        data: ''
      };
      simpleText.on('mouseenter', (event) => {
        const cellValues = simpleText.getAttr('cellValues');
        if (cellValues && cellValues.yKey && cellValues.xKey) {
          const yKey = cellValues.yKey;
          const xKey = cellValues.xKey;
          if (cellValues.tooltipValue && cellValues.tooltipValue[this.selectedTabIndex]
            && (typeof cellValues.tooltipValue[this.selectedTabIndex] !== 'string'
              || this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].values[this.selectedTabIndex].length > 5)) {
            tooltipData.show = true;
            tooltipData.data = cellValues.tooltipValue[this.selectedTabIndex];
            const concertinaTooltip = document.getElementById('concertina-tooltip');
            if (concertinaTooltip) {
              concertinaTooltip.scrollTop = 0;
            }
            this.$concertinaTooltip.next({ event, tooltipData });
          }

          // }
          if (this.swapZoneService.swapMode
            && this.concertinaDataService.originalConcertinaData[yKey]
            && this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].swappable) {
            simpleText.offsetX(-6);
            this.subGroupLayer.batchDraw();
          }
        } else if (cellValues && cellValues.key && cellValues.key.length > 5 && cellValues.frame && cellValues.key !== cellValues.frame) {
          const splittedData = cellValues.key.split('|');
          tooltipData.show = true;
          tooltipData.data = splittedData[splittedData.length - 1];
          const concertinaTooltip = document.getElementById('concertina-tooltip');
          if (concertinaTooltip) {
            concertinaTooltip.scrollTop = 0;
          }
          this.$concertinaTooltip.next({ event, tooltipData });
        }

      });

      simpleText.on('mouseleave', () => {
        const cellValues = simpleText.getAttr('cellValues');
        if (cellValues && cellValues.yKey && cellValues.xKey) {
          const yKey = cellValues.yKey;
          const xKey = cellValues.xKey;
          tooltipData.show = false;
          this.$concertinaTooltip.next({ tooltipData, event: null });
          if (this.swapZoneService.swapMode
            && this.concertinaDataService.originalConcertinaData[yKey]
            && this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].swappable) {
            simpleText.offsetX(0);
            this.subGroupLayer.batchDraw();
          }
        } else if (cellValues && cellValues.key && cellValues.key.length > 5 && cellValues.frame && cellValues.key !== cellValues.frame) {
          tooltipData.show = false;
          this.$concertinaTooltip.next({ tooltipData, event: null });
        }
      });
    }
    return simpleText;
  }

  /**
   * @description Adds selection rect on the top of concertina grid
   * @author Amit Mahida
   * @param {Konva.Text} rectClicked
   * @param {boolean} validateSelection
   * @param {boolean} [freeze=false]
   * @returns
   * @memberof ConcertinaCanvasService
   */
  addSelectionRect(rectClicked: Konva.Text, freeze = false, markedToDelete = false) {
    if (!this.isValidSelection) {
      this.logHelper.logError('Please remove invalid selection!');
      return;
    }
    if (rectClicked.getParent().getAttr('name') !== 'subGroupLayer') {
      return;
    }
    if (this.selectionLayer.getChildren().length > 0 && this.stage.find('.selectionLayer').length === 0) {
      this.stage.add(this.selectionLayer);
    }
    this.selectionLayer.show();
    const xAxis = rectClicked.x();
    const yAxis = rectClicked.y() - 7;
    const selectionGroup = new Konva.Group({
      name: `selectionGroup-${Date.now()}`,
      x: xAxis,
      y: yAxis + this.previousScrollTop,
      draggable: true,
      dragBoundFunc: (pos) => {
        const maxPosition = this.leftHeaderLayer.getChildren()[0].width()
          + (this.concertinaDataService.concertinaData.groups.xGroups.length * this.rectWidth) - selectionGroup.getChildren()[0].width();
        return {
          x: pos.x < this.leftHeaderLayer.getChildren()[0].width() ? this.leftHeaderLayer.getChildren()[0].width() :
            pos.x > maxPosition ? maxPosition : pos.x,
          y: pos.y < 0 ? 0 : pos.y
        };
      }
    });
    const cellValues = rectClicked.getAttr('cellValues');
    selectionGroup.setAttr('cellValues', cellValues);
    if (this.concertinaDataService.originalConcertinaData[cellValues.yKey].visualUnitId) {
      selectionGroup.setAttr('visualUnitId', this.concertinaDataService.originalConcertinaData[cellValues.yKey].visualUnitId);
    }
    const droppableRect = new Konva.Rect({
      width: rectClicked.width(),
      height: this.rectHeight,
      fill: 'transparent',
      stroke: 'yellow',
      strokeWidth: 2
    });
    selectionGroup.add(droppableRect);
    selectionGroup.on('dragend', () => {
      const rect = selectionGroup.get('Rect')[0];
      const topLeft = selectionGroup.get('.topLeft')[0];
      const bottomLeft = selectionGroup.get('.bottomLeft')[0];
      if (rect.x() < 0) {
        selectionGroup.setX(selectionGroup.x() + rect.x());
        rect.x(0);
        topLeft.setX(0);
        bottomLeft.setX(0);
        const topRight = selectionGroup.get('.topRight')[0];
        const bottomRight = selectionGroup.get('.bottomRight')[0];
        topRight.setX(topLeft.x() + rect.width());
        bottomRight.setX(bottomLeft.x() + rect.width());
        this.selectionLayer.batchDraw();
      }
      selectionGroup.moveToTop();
      const cellValues = selectionGroup.getAttr('cellValues');
      cellValues.x = selectionGroup.x();
      cellValues.y = selectionGroup.y();
      selectionGroup.setAttr('cellValues', cellValues);
      const removeButton = selectionGroup.find('.removeButton');
      removeButton.x(bottomLeft.x() + ((rect.width() / 6) + (this.rectWidth / 2)));
      const bookingDetailsIcon = selectionGroup.find('.bookingDetailsIcon');
      bookingDetailsIcon.x(bottomLeft.x() + (rect.width() / 6));
      this.selectData(selectionGroup, freeze);
    });
    selectionGroup.draggable(true);
    this.selectData(selectionGroup, freeze);
    this.addCampaignDetailsIcon(selectionGroup, rectClicked, this.selectionLayer);
    if (this.concertinaDataService.concertinaData[cellValues.yKey].frameId || !freeze) {
      this.addRemoveButton(selectionGroup, rectClicked, markedToDelete);
    }
    if (freeze) {
      selectionGroup.draggable(false);
      selectionGroup.setAttr('selectedInCart', true);
      const childeren = selectionGroup.getChildren();
      if (childeren.length > 0) {
        childeren.each((element) => {
          element.draggable(false);
        });
      }
      droppableRect.stroke('#7FFF00');
      droppableRect.height(droppableRect.height() - 2);
      droppableRect.width(droppableRect.width() - 2);
    } else {
      selectionGroup.draggable(true);
      selectionGroup.setAttr('selectedInCart', false);
      this.addAnchor(selectionGroup, 0, 0, 'topLeft', this.selectionLayer);
      this.addAnchor(selectionGroup, rectClicked.width(), 0, 'topRight', this.selectionLayer);
      this.addAnchor(selectionGroup, rectClicked.width(), this.rectHeight, 'bottomRight', this.selectionLayer);
      this.addAnchor(selectionGroup, 0, this.rectHeight, 'bottomLeft', this.selectionLayer);
    }
    return selectionGroup;
  }

  /**
   * @description Manages swap selection highlighted rect when shift/ctrl is pressed with custom selection
   * @author Amit Mahida
   * @param {Number} frameId
   * @param {Konva.Text} rectClicked
   * @memberof ConcertinaCanvasService
   */
  manageSwapSelectionRect(frameId: number, rectClicked: Konva.Text) {
    const isHighlightCellPresent = this.swapSelectionLayer.getChildren().length && this.swapSelectionLayer.findOne(`.swapSelectionRect-${frameId}`);
    if (isHighlightCellPresent) {
      const rectToExtend: Konva.Rect = this.swapSelectionLayer.findOne(`.swapSelectionRect-${frameId}`);
      this.addSwapSelectionRect(rectClicked, rectToExtend);
    } else {
      const selectionGroup = this.addSwapSelectionRect(rectClicked);
      if (selectionGroup) {
        this.swapSelectionLayer.add(selectionGroup);
      }
    }
    if (this.swapSelectionLayer.getParent() === undefined) {
      this.stage.add(this.swapSelectionLayer);
    }
    this.swapSelectionLayer.moveToTop();
    this.swapSelectionLayer.batchDraw();
  }

  /**
   * @description Add a highlighted selection rect for the selection of custom swap
   * @author Amit Mahida
   * @param {Konva.Text} rectClicked
   * @param {Konva.Rect} [extendCell]
   * @returns {Konva.Group}
   * @memberof ConcertinaCanvasService
   */
  addSwapSelectionRect(rectClicked: Konva.Text, extendCell?: Konva.Rect): Konva.Group {
    if (rectClicked.getParent().getAttr('name') !== 'subGroupLayer') {
      return;
    }
    const cellValues = rectClicked.getAttr('cellValues');
    const targetXKey = cellValues.xKey;
    if (extendCell) {
      if ((this.controlPressed && !this.shiftPressed) || (!this.controlPressed && !this.shiftPressed)) {
        extendCell.setAttr('xKeyEnd', targetXKey);
        extendCell.width(extendCell.width() + this.rectWidth);
      }
      if (this.shiftPressed) {
        const lastXKey = extendCell.getAttr('xKeyEnd');
        const indexOfTargetCell = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(targetXKey);
        const indexOfHighlightCellEnd = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(lastXKey);
        const expandCells = indexOfTargetCell - indexOfHighlightCellEnd;
        extendCell.setAttr('xKeyEnd', targetXKey);
        extendCell.width(extendCell.width() + (this.rectWidth * expandCells));
      }
      const frameId = this.concertinaDataService.originalConcertinaData[cellValues.yKey].frameId;
      const removeButton = this.swapSelectionLayer.findOne(`.removeSwap${frameId}`);
      removeButton.x(extendCell.width() / 2);
      this.swapSelectionLayer.moveToTop();
      this.swapSelectionLayer.batchDraw();
    } else {
      const xAxis = rectClicked.x();
      const yAxis = rectClicked.y() - 7;
      const selectionGroup = new Konva.Group({
        name: `swapSelectionGroup-${this.concertinaDataService.originalConcertinaData[cellValues.yKey].frameId}`,
        x: xAxis,
        y: yAxis + this.previousScrollTop
      });
      const droppableRect = new Konva.Rect({
        name: `swapSelectionRect-${this.concertinaDataService.originalConcertinaData[cellValues.yKey].frameId}`,
        width: rectClicked.width(),
        height: this.rectHeight,
        fill: 'transparent',
        stroke: '#39FF14',
        strokeWidth: 2
      });
      droppableRect.setAttr('xKey', targetXKey);
      droppableRect.setAttr('xKeyEnd', targetXKey);
      selectionGroup.add(droppableRect);
      this.addRemoveButtonForMultiSwap(selectionGroup, droppableRect, this.concertinaDataService.originalConcertinaData[cellValues.yKey].frameId);
      return selectionGroup;
    }
  }

  /**
   * @description Updates position of anchors in case of dragging the entire selection
   * @author Amit Mahida
   * @param {any} activeAnchor
   * @memberof ConcertinaCanvasService
   */
  updateAnchorPos(activeAnchor) {
    const group = activeAnchor.getParent();
    const topLeft = group.get('.topLeft')[0];
    const topRight = group.get('.topRight')[0];
    const bottomRight = group.get('.bottomRight')[0];
    const bottomLeft = group.get('.bottomLeft')[0];
    const rect = group.get('Rect')[0];
    const anchorX = activeAnchor.getX();
    // const anchorY = activeAnchor.getY();
    // update anchor positions
    switch (activeAnchor.getName()) {
      case 'topLeft':
        bottomLeft.setX(anchorX);
        break;
      case 'topRight':
        bottomRight.setX(anchorX);
        break;
      case 'bottomRight':
        topRight.setX(anchorX);
        break;
      case 'bottomLeft':
        topLeft.setX(anchorX);
        break;
    }
    rect.position(topLeft.position());
    const width = topRight.getX() - topLeft.getX();
    const height = bottomLeft.getY() - topLeft.getY();
    if (width && height) {
      rect.width(width);
    }
  }

  /**
   * @description Updates the status if we have new selections which is not there in cart
   * @author Amit Mahida
   * @memberof ConcertinaCanvasService
   */
  updateSelectionStatus() {
    if (this.selectionLayer) {
      let hasNewSelection = false;
      this.selectionGroups = this.selectionLayer.getChildren();
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < this.selectionGroups.length; index++) {
        const group = this.selectionGroups[index];
        if (group.getAttr('selectedInCart') === false) {
          hasNewSelection = true;
          break;
        } else {
          hasNewSelection = false;
        }
      }
      if (Object.keys(this.deletedMarkedSelections).length) {
        hasNewSelection = true;
      }
      this.hasNewSelection = hasNewSelection;
    }
  }

  /**
   * @description Delete selection group from stage and clears params
   * @author Amit Mahida
   * @param {Konva.Group} group
   * @memberof ConcertinaCanvasService
   */
  deleteSelection(group: Konva.Group) {
    delete this.cartRequstParams[group.getAttr('name')];
    this.clearGroups(group);
    this.isValidSelection = true;
    this.selectionLayer.batchDraw();
  }

  /**
   * @description Deletes selection from cart
   * @author Amit Mahida
   * @param {Konva.Group} selectionGroup
   * @memberof ConcertinaCanvasService
   */
  deleteSelectionFromCart() {
    const tempCartData = _.clone(this.cartService.cartData);
    for (const key in this.deletedMarkedSelections) {
      if (this.deletedMarkedSelections.hasOwnProperty(key)) {
        const selection = this.deletedMarkedSelections[key];
        if (selection) {
          const selectedColumns = selection.selectedColumns;
          const cartMapping = selection.selectionData;
          let sdate = '';
          let edate = '';
          if (selectedColumns[0].split(GLOBAL.CONCERTINA_SEPARATOR).length <= 3) {
            sdate = moment(cartMapping.startDate).format('YYYY-MM-DD');
            edate = moment(cartMapping.endDate).format('YYYY-MM-DD');
          } else {
            sdate = moment(cartMapping.startDate).format('YYYY-MM-DD HH:mm:ss');
            edate = moment(cartMapping.endDate).format('YYYY-MM-DD HH:mm:ss');
          }

          let isSelectionExact = false;
          let isSelectionInBetween = false;
          let isSelectionAtStart = false;
          let isSelectionAtEnd = false;
          const splitSelection = {
            index: -1,
            startDate: '',
            endDate: ''
          };
          // for (const cartData of tempCartData) {
          for (let index = 0; index < tempCartData.length; index++) {
            const cartData = tempCartData[index];
            let startDateReq = '';
            let endDateReq = '';
            if (cartMapping.frame === cartData.frameId) {
              if (selectedColumns[0].split(GLOBAL.CONCERTINA_SEPARATOR).length <= 3) {
                startDateReq = moment(cartData.startDateReq).format('YYYY-MM-DD');
                endDateReq = moment(cartData.endDateReq).format('YYYY-MM-DD');
              } else {
                startDateReq = moment(cartData.startDateReq).format('YYYY-MM-DD HH:mm:ss');
                endDateReq = moment(cartData.endDateReq).format('YYYY-MM-DD HH:mm:ss');
              }

              let isMatchFound = false;
              if (sdate === startDateReq && edate === endDateReq) { // Exact match
                isSelectionExact = true;
                isMatchFound = true;
              } else if (sdate === startDateReq) { // at start of a range
                isSelectionAtStart = true;
                isMatchFound = true;
              } else if (edate === endDateReq) { // at end of a range
                isSelectionAtEnd = true;
                isMatchFound = true;
              } else if (moment(sdate).isBetween(startDateReq, endDateReq)
                && moment(edate).isBetween(startDateReq, endDateReq)) { // in between
                isSelectionInBetween = true;
                isMatchFound = true;
              } else if (moment(startDateReq).isBetween(sdate, edate)
                && moment(endDateReq).isBetween(sdate, edate)) { // Alkesh - out-bound date - basket mode - week level
                isSelectionExact = true;
                isMatchFound = true;
              } else if (moment(startDateReq).isBetween(sdate, edate)) {
                isSelectionAtStart = true;
                isMatchFound = true;
              } else if (moment(endDateReq).isBetween(sdate, edate)) {
                isSelectionAtEnd = true;
                isMatchFound = true;
              }else if (edate === startDateReq){
                isSelectionAtStart = true;
                isMatchFound = true;
              }

              if (isMatchFound) {
                splitSelection.startDate = startDateReq;
                splitSelection.endDate = endDateReq;
                splitSelection.index = index;
                break;
              }
            }
          }

          if (isSelectionExact) {
            tempCartData.splice(splitSelection.index, 1);
          } else if (isSelectionInBetween) {
            const splitCart = tempCartData[splitSelection.index];
            tempCartData.splice(splitSelection.index, 1);
            for (let index = 0; index < 2; index++) {
              if (index === 0) {
                const splitCartClone = _.clone(splitCart);
                // range/week/day level
                if (selectedColumns[0].split(GLOBAL.CONCERTINA_SEPARATOR).length <= 3) {
                  splitCartClone.endDateReq =
                    moment(cartMapping.startDate).subtract(1, 'days').endOf('day').format(GLOBAL.MOMENT_REQ_DATE_FORMAT);
                  tempCartData.push(splitCartClone);
                } else {
                  // daypart/hour level
                  splitCartClone.endDateReq =
                    moment(cartMapping.startDate).subtract(1, 'hour').endOf('hour').format(GLOBAL.MOMENT_REQ_DATE_FORMAT);
                  tempCartData.push(splitCartClone);
                }
              } else {
                const splitCartClone = _.clone(splitCart);
                // range/week/day level
                if (selectedColumns[0].split(GLOBAL.CONCERTINA_SEPARATOR).length <= 3) {
                  splitCartClone.startDateReq = moment(cartMapping.endDate).add(1, 'hour').startOf('day').format(GLOBAL.MOMENT_REQ_DATE_FORMAT);
                  tempCartData.push(splitCartClone);
                } else {
                  // daypart/hour level
                  splitCartClone.startDateReq =
                    moment(cartMapping.endDate).add(1, 'hour').startOf('hour').format(GLOBAL.MOMENT_REQ_DATE_FORMAT);
                  tempCartData.push(splitCartClone);
                }
              }
            }
          } else if (isSelectionAtStart) {
            const splitCart = tempCartData[splitSelection.index];
            tempCartData.splice(splitSelection.index, 1);
            const splitCartClone = _.clone(splitCart);
            // range/week/day level
            if (selectedColumns[0].split(GLOBAL.CONCERTINA_SEPARATOR).length <= 3) {
              splitCartClone.startDateReq = moment(cartMapping.endDate).add(1, 'hour')
                .startOf('day').format(GLOBAL.MOMENT_REQ_DATE_FORMAT);
              tempCartData.push(splitCartClone);
            } else {
              // daypart/hour level
              splitCartClone.startDateReq = moment(cartMapping.endDate).add(1, 'hour')
                .startOf('hour').format(GLOBAL.MOMENT_REQ_DATE_FORMAT);
              tempCartData.push(splitCartClone);
            }

          } else if (isSelectionAtEnd) {
            const splitCart = tempCartData[splitSelection.index];
            tempCartData.splice(splitSelection.index, 1);
            const splitCartClone = _.clone(splitCart);
            // range/week/day level
            if (selectedColumns[0].split(GLOBAL.CONCERTINA_SEPARATOR).length <= 3) {
              splitCartClone.endDateReq = moment(cartMapping.startDate).subtract(1, 'hour').endOf('day').format(GLOBAL.MOMENT_REQ_DATE_FORMAT);
              tempCartData.push(splitCartClone);
            } else {
              // daypart/hour level
              splitCartClone.endDateReq = moment(cartMapping.startDate).subtract(1, 'hour')
                .endOf('hour').format(GLOBAL.MOMENT_REQ_DATE_FORMAT);
              tempCartData.push(splitCartClone);
            }
          }
        }
      }
    }
    this.cartService.cartData = _.clone(tempCartData);
  }

  /**
   * @description Marks childeren rows to delete
   * @author Amit Mahida
   * @param {Konva.Group} group
   * @memberof ConcertinaCanvasService
   */
  markChilderenRowsToDelete(group: Konva.Group) {
    const selectedCol = group.getAttr('selectedColumns')[0];
    const selectedRow = group.getAttr('selectedRows')[0];
    this.selectionGroups = this.selectionLayer.getChildren();
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < this.selectionGroups.length; index++) {
      const element = this.selectionGroups[index] as Konva.Group;
      const deleButton: Konva.Text = element.find('.removeButton')[0] as Konva.Text;
      if (element.getAttr('selectedRows')[0] !== selectedRow
        && element.getAttr('selectedRows')[0].indexOf(selectedRow) > -1
        && element.getAttr('selectedColumns')[0] === selectedCol) {
        if (group.getAttr('isMarkedToDelete')) {
          element.setAttr('isMarkedToDelete', false);
          deleButton.fill('#fff');
          deleButton.hide();
        } else {
          element.setAttr('isMarkedToDelete', true);
          deleButton.fill('red');
          deleButton.show();
        }
      }
    }
  }

  /**
   * @description Marks childeren columns to delete
   * @author Amit Mahida
   * @param {Konva.Group} group
   * @memberof ConcertinaCanvasService
   */
  markChilderenColsToDelete(group: Konva.Group) {
    const selectedCol = group.getAttr('selectedColumns')[0];
    const selectedRow = group.getAttr('selectedRows')[0];
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < this.selectionGroups.length; index++) {
      const element = this.selectionGroups[index] as Konva.Group;
      const deleButton: Konva.Text = element.find('.removeButton')[0] as Konva.Text;
      if (element.getAttr('selectedColumns')[0] !== selectedCol
        && element.getAttr('selectedColumns')[0].indexOf(selectedCol) > -1
        && element.getAttr('selectedRows')[0] === selectedRow
      ) {
        if (group.getAttr('isMarkedToDelete')) {
          element.setAttr('isMarkedToDelete', false);
          deleButton.fill('#fff');
          deleButton.hide();
        } else {
          element.setAttr('isMarkedToDelete', true);
          deleButton.fill('red');
          deleButton.show();
        }
      }
    }
  }

  /**
   * @description Updates concertina data and deletedMarkedSelections on deleted marked selections
   * @author Amit Mahida
   * @param {string[]} selectedColumns
   * @param {string[]} selectedRows
   * @param {SelectionData} selectionData
   * @memberof ConcertinaCanvasService
   */
  manageDeleteOnConcertinaData(selectedColumns: string[], selectedRows: string[], selectionData: SelectionData) {
    const selectedColumn: any = selectedColumns[0];
    const selectedRow: any = selectedRows[0];
    const key = selectedRow + GLOBAL.CONCERTINA_SEPARATOR + GLOBAL.CONCERTINA_SEPARATOR + selectedColumn;
    let deleteSelection = true;
    if (this.concertinaDataService.originalConcertinaData[selectedRow].xValues[selectedColumn].isMarkedToDelete) {
      delete this.concertinaDataService.originalConcertinaData[selectedRow].xValues[selectedColumn].isMarkedToDelete;
      delete this.deletedMarkedSelections[key];
      deleteSelection = true;
    } else {
      this.concertinaDataService.originalConcertinaData[selectedRow].xValues[selectedColumn].isMarkedToDelete = true;
      const deleteData = {
        selectionData,
        selectedColumns,
        selectedRows
      };
      this.deletedMarkedSelections[key] = deleteData;
      deleteSelection = false;
    }

    const parentCols = this.getParentColumns(selectedColumn);
    if (parentCols && parentCols.length) {
      if (deleteSelection) {
        for (const parentCol of parentCols) {
          const deleteKey = selectedRow + GLOBAL.CONCERTINA_SEPARATOR + GLOBAL.CONCERTINA_SEPARATOR + parentCol;
          if (parentCol && this.deletedMarkedSelections[deleteKey]) {
            delete this.concertinaDataService.originalConcertinaData[selectedRow].xValues[parentCol].isMarkedToDelete;
            delete this.deletedMarkedSelections[deleteKey];
          }
        }
      } else {
        const childOfParents = this.getChilderenCols(parentCols[0]);
        let isAllSelected = true;
        for (const childOfParent of childOfParents) {
          if (!this.concertinaDataService.originalConcertinaData[selectedRow].xValues[childOfParent].isMarkedToDelete) {
            isAllSelected = false;
          }
        }
        if (isAllSelected && childOfParents.length) {
          this.concertinaDataService.originalConcertinaData[selectedRow].xValues[parentCols[0]].isMarkedToDelete = true;
          const frame = !_.isUndefined(this.concertinaDataService.originalConcertinaData[selectedRow].frameId) ?
            this.concertinaDataService.originalConcertinaData[selectedRow].frameId : this.concertinaDataService.originalConcertinaData[selectedRow].key;
          const deleteData = {
            selectedColumns: [parentCols[0]],
            selectedRows: [selectedRow],
            selectionData: {
              frame,
              startDate: this.concertinaDataService.originalConcertinaData.xHeadings[parentCols[0]].startDate,
              endDate: this.concertinaDataService.originalConcertinaData.xHeadings[parentCols[0]].endDate,
              frameCode: selectedRow
            }
          };
          const deleteKey = selectedRow + GLOBAL.CONCERTINA_SEPARATOR + GLOBAL.CONCERTINA_SEPARATOR + parentCols[0];
          this.deletedMarkedSelections[deleteKey] = deleteData;
        }
      }
    }
    const childCols = this.getChilderenCols(selectedColumn);
    for (const col of childCols) {
      const deleteKey = selectedRow + GLOBAL.CONCERTINA_SEPARATOR + GLOBAL.CONCERTINA_SEPARATOR + col;
      if (deleteSelection) {
        delete this.concertinaDataService.originalConcertinaData[selectedRow].xValues[col].isMarkedToDelete;
        delete this.deletedMarkedSelections[deleteKey];
      } else {
        this.concertinaDataService.originalConcertinaData[selectedRow].xValues[col].isMarkedToDelete = true;
        const frame = !_.isUndefined(this.concertinaDataService.originalConcertinaData[selectedRow].frameId) ?
          this.concertinaDataService.originalConcertinaData[selectedRow].frameId : this.concertinaDataService.originalConcertinaData[selectedRow].key;
        const deleteData = {
          selectedColumns: [col],
          selectedRows: [selectedRow],
          selectionData: {
            frame,
            startDate: this.concertinaDataService.originalConcertinaData.xHeadings[col].startDate,
            endDate: this.concertinaDataService.originalConcertinaData.xHeadings[col].endDate,
            frameCode: selectedRow
          }
        };
        this.deletedMarkedSelections[deleteKey] = deleteData;
      }
    }
    const childRows = this.getChilderenRows(selectedRow);
    for (const row of childRows) {
      const deleteKey = row + GLOBAL.CONCERTINA_SEPARATOR + GLOBAL.CONCERTINA_SEPARATOR + selectedColumn;
      if (deleteSelection) {
        delete this.concertinaDataService.originalConcertinaData[row].xValues[selectedColumn].isMarkedToDelete;
        delete this.deletedMarkedSelections[deleteKey];
      } else {
        this.concertinaDataService.originalConcertinaData[row].xValues[selectedColumn].isMarkedToDelete = true;
        const frame = !_.isUndefined(this.concertinaDataService.originalConcertinaData[row].frameId) ?
          this.concertinaDataService.originalConcertinaData[row].frameId : this.concertinaDataService.originalConcertinaData[row].key;
        const deleteData = {
          selectedColumns: [selectedColumn],
          selectedRows: [row],
          selectionData: {
            frame,
            startDate: this.concertinaDataService.originalConcertinaData.xHeadings[selectedColumn].startDate,
            endDate: this.concertinaDataService.originalConcertinaData.xHeadings[selectedColumn].endDate,
            frameCode: row
          }
        };
        this.deletedMarkedSelections[deleteKey] = deleteData;
      }
    }
  }

  /**
   * @description Marks selection to delete from cart
   * @author Amit Mahida
   * @param {Konva.Group} group
   * @memberof ConcertinaCanvasService
   */
  markSelectionToDelete(group: Konva.Group, markedToDelete?: boolean) {
    if (SystemFlags.readOnlyWorkspace && !SystemFlags.splitable) {
      // do nothing - past or cancelled campaign
      this.logHelper.logInfo(this.initialConfig.userBundle['vip.concertina.deleteNotAllowed'] ||
        'Delete operation is not allowed!');
    } else {
      if (group.getAttr('cellValues').selectionAllowed) {
        if (!markedToDelete) {
          this.manageDeleteOnConcertinaData(group.getAttr('selectedColumns'), group.getAttr('selectedRows'), group.getAttr('selectionData'));
        }
        const deleteButton: Konva.Text = group.find('.removeButton')[0] as Konva.Text;
        this.markChilderenRowsToDelete(group);
        this.markChilderenColsToDelete(group);
        if (group.getAttr('isMarkedToDelete')) {
          group.setAttr('isMarkedToDelete', false);
          deleteButton.fill('#fff');
          deleteButton.hide();
        } else {
          group.setAttr('isMarkedToDelete', true);
          deleteButton.fill('red');
          deleteButton.show();
        }
        this.selectionLayer.moveToTop();
        this.selectionLayer.batchDraw();
        if (this.leftHeaderLayer.getParent() !== undefined) {
          this.leftHeaderLayer.moveToTop();
        }
        this.updateSelectionStatus();
      } else {
        this.logHelper.logInfo(this.initialConfig.userBundle['vip.concertina.deleteNotAllowed'] ||
          'Delete operation is not allowed!');
      }
    }
  }

  /**
   * @description Removes selection layer data from update cart params temporarily/forever
   * @author Amit Mahida
   * @param {Konva.Group} group
   * @param {boolean} [removeForever]
   * @memberof ConcertinaCanvasService
   */
  removeSelection(group: Konva.Group, removeForever?: boolean) {
    if (removeForever) {
      if (group.getAttr('selectedInCart')) {
        if (group.getAttr('visualUnitId')) {
          const selectedColumn = group.getAttr('selectedColumns')[0];
          for (const yKey of this.concertinaDataService.originalConcertinaData.groups.yGroups) {
            if (this.concertinaDataService.originalConcertinaData[yKey].visualUnitId &&
              this.concertinaDataService.originalConcertinaData[yKey].visualUnitId === group.getAttr('visualUnitId')) {
              this.manageDeleteOnConcertinaData(group.getAttr('selectedColumns'), [yKey],
                {
                  frame: this.concertinaDataService.originalConcertinaData[yKey].frameId,
                  startDate: this.concertinaDataService.originalConcertinaData.xHeadings[selectedColumn].startDate,
                  endDate: this.concertinaDataService.originalConcertinaData.xHeadings[selectedColumn].endDate,
                  frameCode: this.concertinaDataService.originalConcertinaData[yKey].displayText
                });
            }
          }
          this.updateTable();
        } else {
          this.markSelectionToDelete(group);
        }
      } else {
        this.deleteSelection(group);
        this.updateSelectionStatus();
      }
    } else {
      delete this.cartRequstParams[group.getAttr('name')];
      this.updateCartReqParams(undefined, undefined);
      this.updateSelectionStatus();
    }
    this.selectionLayer.batchDraw();
    this.selectionLayer.moveToTop();
    this.leftHeaderLayer.moveToTop();
  }

  /**
   * @description Add a trash button with each custom swap selection to remove it
   * @author Amit Mahida
   * @param {Konva.Group} selectionGroup
   * @param {Konva.Rect} rect
   * @param {number} frameId
   * @memberof ConcertinaCanvasService
   */
  addRemoveButtonForMultiSwap(selectionGroup: Konva.Group, rect: Konva.Rect, frameId: number) {
    const removeButton = this.drawTextOnRect('', (rect.getAttr('width') / 2), 0, '#f00', 20, 25,
      'center', false, null, 0, 14, 'bold', 'FontAwesome', `removeSwap${frameId}`);
    removeButton.on('mousedown touchstart', () => {
      const xStart = rect.getAttr('xKey');
      const xEnd = rect.getAttr('xKeyEnd');
      if (this.multiSelectionForSwap[frameId].xStart === xStart
        && this.multiSelectionForSwap[frameId].xEnd === xEnd) {
        delete this.multiSelectionForSwap[frameId];
      }
      removeButton.getParent().destroy();
      this.swapSelectionLayer.batchDraw();
    });
    selectionGroup.add(removeButton);
    removeButton.setZIndex(999);
  }

  /**
   * @description Adds the remove button on selection rect
   * @author Amit Mahida
   * @param {Konva.Group} selectionGroup
   * @param {Konva.Text} rect
   * @memberof ConcertinaCanvasService
   */
  addRemoveButton(selectionGroup: Konva.Group, rect: Konva.Text, markedToDelete: boolean) {
    const removeButton = this.drawTextOnRect('', (rect.getAttr('width') / 12) + (this.rectWidth / 2), 3, '#fff', 20, 25,
      'center', false, selectionGroup.getAttr('cellValues'), 0, 14, 'bold', 'FontAwesome', 'removeButton');
    removeButton.on('mousedown touchstart', () => {
      this.removeSelection(removeButton.getParent(), true);
    });

    const tooltipData = {
      show: true,
      data: ''
    };
    selectionGroup.on('mouseenter', (event) => {
      if (!selectionGroup.getAttr('isMarkedToDelete')) {
        removeButton.show();
        this.selectionLayer.batchDraw();
      }
      this.selectionLayer.batchDraw();
      const cellValues = selectionGroup.getAttr('cellValues');
      const yKey = cellValues.yKey;
      const xKey = cellValues.xKey;
      if (cellValues.tooltipValue && cellValues.tooltipValue[this.selectedTabIndex]
        && (typeof cellValues.tooltipValue[this.selectedTabIndex] !== 'string'
          || this.concertinaDataService.originalConcertinaData[yKey].xValues[xKey].values[this.selectedTabIndex].length > 5)) {
        tooltipData.show = true;
        tooltipData.data = cellValues.tooltipValue[this.selectedTabIndex];
        const concertinaTooltip = document.getElementById('concertina-tooltip');
        if (concertinaTooltip) {
          concertinaTooltip.scrollTop = 0;
        }
        this.$concertinaTooltip.next({ event, tooltipData });
      }
      this.stage.container().style.cursor = 'pointer';
    });
    selectionGroup.on('mouseleave', () => {
      if (!selectionGroup.getAttr('isMarkedToDelete')) {
        removeButton.hide();
        this.selectionLayer.batchDraw();
      }
      tooltipData.show = false;
      this.$concertinaTooltip.next({ tooltipData, event: null });
      this.stage.container().style.cursor = 'default';
    });

    selectionGroup.add(removeButton);
    removeButton.setZIndex(999);
    if (markedToDelete) {
      this.markSelectionToDelete(selectionGroup, markedToDelete);
    } else {
      removeButton.hide();
    }

  }

  /**
  * @description  Adds anchors on selection rect
  * @author Amit Mahida
  * @param {Konva.Group} group
  * @param {number} x
  * @param {number} y
  * @param {string} name
  * @param {Konva.Layer} layer
  * @memberof ConcertinaCanvasService
  */
  addAnchor(group: Konva.Group, x: number, y: number, name: string, layer: Konva.Layer) {
    const anchor = new Konva.Circle({
      x,
      y,
      name,
      stroke: '#666',
      fill: '#ddd',
      strokeWidth: 2,
      radius: 2,
      draggable: true,
      dragBoundFunc: (pos) => {
        let xPostion = pos.x;
        let minPosition = this.leftHeaderLayer.getChildren()[0].width() + this.rectWidth;
        let maxPosition = this.getMaxPos(anchor.parent) + this.rectWidth;
        if (name === 'topLeft' || name === 'bottomLeft') {
          maxPosition = group.x() + group.find('.bottomRight')[0].x() - this.rectWidth;
        }
        if (name === 'topRight' || name === 'bottomRight') {
          minPosition = group.x() + group.find('.bottomLeft')[0].x() + this.rectWidth;
        }
        xPostion = xPostion < maxPosition ? (pos.x < minPosition ? minPosition : pos.x) : maxPosition;
        return {
          x: xPostion,
          y: anchor.getAbsolutePosition().y
        };
      }

    });
    anchor.on('dragmove', () => {
      this.updateAnchorPos(anchor);
      layer.moveToTop();
      layer.batchDraw();
    });
    anchor.on('mousedown touchstart', () => {
      layer.moveToTop();
      group.draggable(false);
    });
    anchor.on('mouseenter', () => {
      this.stage.container().style.cursor = 'pointer';
      anchor.setStrokeWidth(3);
    });
    anchor.on('mouseleave', () => {
      anchor.draggable(true);
      this.stage.container().style.cursor = 'default';
      anchor.setStrokeWidth(2);
    });

    group.add(anchor);
    anchor.setZIndex(999);
  }

  /**
   * @description Adds campaign detail icons on concertina
   * @author Amit Mahida
   * @param {Konva.Group} group
   * @param {Konva.Text} rect
   * @param {Konva.Layer} layer
   * @memberof ConcertinaCanvasService
   */
  addCampaignDetailsIcon(group: Konva.Group, rect: Konva.Text, layer: Konva.Layer) {

    const infoIcon = this.drawTextOnRect('', (rect.width() / 12), 3, '#fff', 20, 25,
      'center', false, null, 0, 14, 'bold', 'FontAwesome', 'bookingDetailsIcon');

    infoIcon.on('mousedown touchstart', () => {
      let campaignDetailsParams = this.cartSelectionData[group.getAttr('name')];
      const cellValues = group.getAttr('cellValues');
      const sot = this.getBookedSOT(this.concertinaDataService.concertinaData[cellValues.yKey].xValues[cellValues.xKey].bookingDetails);
      const impressions = this.concertinaDataService.concertinaData[cellValues.yKey].xValues[cellValues.xKey].values[1];
      if (typeof campaignDetailsParams === 'undefined') {
        const selectionData = group.getAttr('selectionData');
        campaignDetailsParams = {
          sot,
          impressions,
          frame: selectionData.frame,
          frameCode: selectionData.frameCode,
          range: [{
            startDate: selectionData.startDate,
            endDate: selectionData.endDate,
          }],
        };
      } else {
        campaignDetailsParams.sot = sot;
        campaignDetailsParams.impressions = impressions;
      }
      campaignDetailsParams.bookingDetails = this.concertinaDataService.concertinaData[cellValues.yKey].xValues[cellValues.xKey].bookingDetails;
      this.$campaignDetailsSub.next(campaignDetailsParams);
    });

    group.on('mouseenter', () => {
      this.stage.container().style.cursor = 'pointer';
      infoIcon.show();
      layer.batchDraw();
    });
    group.on('mouseleave', () => {
      if (!group.getAttr('selectedInCart')) {
        group.draggable(true);
      }
      this.stage.container().style.cursor = 'default';
      infoIcon.hide();
      layer.batchDraw();
    });
    group.add(infoIcon);
    infoIcon.setZIndex(1);
    infoIcon.hide();
  }

  /**
   * @description Select data from a specific selection group
   * @author Amit Mahida
   * @param {Konva.Group} group
   * @param {boolean} validateSelection
   * @returns
   * @memberof ConcertinaCanvasService
   */
  selectData(group: Konva.Group, freeze = false) {
    const selector = {
      'startPosition': {
        x: group.x(),
        y: group.y()
      },
      'endPostion': {
        x: group.x() + group.find('Rect')[0].width(),
        y: group.y() + this.rectHeight
      }
    };
    const groupingChildren: any = this.leftHeaderLayer.getChildren();
    const headerChildren: any = this.headerLayer.getChildren();
    const actualStartPositionX = selector.startPosition.x - (this.rectWidth / 2);
    const actualEndPostionX = selector.endPostion.x + (this.rectWidth / 2);
    const actualStartPositionY = selector.startPosition.y;
    const actualEndPositionY = selector.endPostion.y;

    const range = [];
    let selectionAllowed = false;
    let frame = '';
    let frameCode = '';
    const selectionOnColIndex = [];
    const selectionOnRowIndex = [];

    if (headerChildren) {
      const txtHeaderChildren = headerChildren.filter(f => f.className === 'Text');
      txtHeaderChildren.forEach((element) => {
        const xStart = element.attrs.x;
        const xEnd = element.attrs.x + element.attrs.width;
        if (xStart >= actualStartPositionX && xStart <= actualEndPostionX
          && xEnd >= actualStartPositionX && xEnd <= actualEndPostionX) {
          range.push(element.attrs.cellValues.range);
          selectionAllowed = element.attrs.cellValues.selectionAllowed;
          selectionOnColIndex.push(element.attrs.cellValues.key);
        }
      });
    }

    if (groupingChildren) {
      const txtGroupingChildren = groupingChildren.filter(f => f.className === 'Text');
      txtGroupingChildren.forEach((element) => {
        const originalMin = element.attrs.y + this.previousScrollTop;
        if (originalMin >= actualStartPositionY && originalMin <= actualEndPositionY) {
          if (element.attrs.cellValues && element.attrs.cellValues.frame && element.attrs.cellValues.key) {
            frame = element.attrs.cellValues.frame;
            frameCode = element.attrs.text;
            selectionOnRowIndex.push(element.attrs.cellValues.key);
          }
        }
      });
    }
    if (selectionOnColIndex.length > 0) {
      const countOfConcertinaSeperator = selectionOnColIndex[0].split(GLOBAL.CONCERTINA_SEPARATOR).length;
      selectionOnColIndex.forEach((element) => {
        const localCountOfConcertinaSeperator = element.split(GLOBAL.CONCERTINA_SEPARATOR).length;
        if (countOfConcertinaSeperator !== localCountOfConcertinaSeperator) {
          selectionAllowed = false;
        }
      });
    }
    if (selectionAllowed || freeze) {
      const selectedRow = selectionOnRowIndex[0];
      this.concertinaDataService.originalConcertinaData[selectedRow]['selectedInCart'] = true;
      const selectedCols = selectionOnColIndex;
      for (const selectedCol of selectedCols) {
        this.concertinaDataService.originalConcertinaData[selectedRow].xValues[selectedCol]['selectedInCart'] = true;
      }
      group.setAttr('selectedColumns', selectionOnColIndex);
      group.setAttr('selectedRows', selectionOnRowIndex);
      group.setAttr('selectionData',
        {
          frame,
          frameCode,
          startDate: range[0].startDate,
          endDate: range[range.length - 1].endDate
        });
      group.setAttr('hidden', false);
      if (!freeze) {
        this.cartSelectionData[group.getAttr('name')] = { range, frame, frameCode };
        const selection = this.getSelectionData(group);
        this.updateCartReqParams(selection.selectedData, group.getAttr('name'));
      }
    } else {
      group.destroy();
      this.logHelper.logError('Selection is not allowed here!');
    }
    return true;
  }

  getMaxPos(parent) {
    const startKey = parent.attrs.selectedColumns[0];
    const countOfConcertinaSeperator = startKey.split(GLOBAL.CONCERTINA_SEPARATOR).length;
    const allowedColumns = [];
    const startIndexExistInCols = this.currentConcertinaCols.findIndex((element) => {
      return element.colKey === startKey;
    });
    let i;
    let reachedEnd = false;
    for (i = startIndexExistInCols; i < this.currentConcertinaCols.length; i++) {
      if (!reachedEnd) {
        const localCountOfConcertinaSeperator = this.currentConcertinaCols[i].colKey.split(GLOBAL.CONCERTINA_SEPARATOR).length;
        if (localCountOfConcertinaSeperator === countOfConcertinaSeperator) {
          allowedColumns.push(this.currentConcertinaCols[i]);
        } else {
          reachedEnd = true;
        }
      }
    }
    return allowedColumns[allowedColumns.length - 1].position.x;

  }

  /**
   * @description Sends Events to Google Analytics
   * @author Amit Mahida
   * @param {string} name
   * @memberof ConcertinaCanvasService
   */
  sendGAEvent(name: string) {
    const currentKeyStage = name.split(GLOBAL.CONCERTINA_SEPARATOR).length - 1;
    const actionName = currentKeyStage === 0 ? 'Week' : currentKeyStage === 1 ? 'Day' :
      currentKeyStage === 2 ? 'Day Part' : currentKeyStage === 3 ? 'Hour' : '';

    GoogleAnalyticsEvents.send('CONCERTINA EVENTS', actionName, 'Concertina');
  }

  /**
   * @description Method to trigger below logic on click of any allowed cell in grid
   * @author Amit Mahida
   * @param {string} name
   * @returns
   * @memberof ConcertinaCanvasService
   */
  getDataOnClick(name: string) {
    this.expandKey = name;
    this.sendGAEvent(this.expandKey);
    return new Promise((resolve, reject) => {
      let groups = [];
      let concertinaData = {};
      groups = _.clone(this.concertinaDataService.originalConcertinaData.groups);
      concertinaData = _.cloneDeep(this.concertinaDataService.originalConcertinaData);
      let hideAdvertiserId;
      if (this.initialConfig.uiControl.hideShowAdvertiserIdEnabled) {
        hideAdvertiserId = this.hideAdvertiserId;
      }
      this.concertinaDataService.getConcertinaData(name, this.groupingData, groups,
        concertinaData, hideAdvertiserId).then((data: any) => {
          if (data && data.groupedData) {
            for (const outerKey in data.groupedData) {
              if (data.groupedData[outerKey] && data.groupedData[outerKey]['xValues']) {
                for (const innerKey in data.groupedData[outerKey]['xValues']) {
                  if (data.groupedData[outerKey]['xValues'][innerKey] && concertinaData && concertinaData[outerKey] && concertinaData[outerKey]['xValues'] && concertinaData[outerKey]['xValues'][name] && concertinaData[outerKey]['xValues'][name].isMarkedToDelete) {
                    data.groupedData[outerKey]['xValues'][innerKey].isMarkedToDelete = true;
                    const frame = !_.isUndefined(data.groupedData[outerKey].frameId) ?
                      data.groupedData[outerKey].frameId : data.groupedData[outerKey].key;
                    const deleteData = {
                      selectedColumns: [innerKey],
                      selectedRows: [outerKey],
                      selectionData: {
                        frame,
                        startDate: data.xHeadings[data.groupedData[outerKey]['xValues'][innerKey].key].startDate,
                        endDate: data.xHeadings[data.groupedData[outerKey]['xValues'][innerKey].key].endDate,
                        frameCode: outerKey
                      }
                    };
                    const deleteKey = outerKey + GLOBAL.CONCERTINA_SEPARATOR + GLOBAL.CONCERTINA_SEPARATOR + innerKey;
                    this.deletedMarkedSelections[deleteKey] = deleteData;
                  } else {
                    const deleteKey = outerKey + GLOBAL.CONCERTINA_SEPARATOR + GLOBAL.CONCERTINA_SEPARATOR + innerKey;
                    if (this.deletedMarkedSelections[deleteKey]) {
                      data.groupedData[outerKey]['xValues'][innerKey].isMarkedToDelete = true;
                    }
                  }
                }
              }
            }
          }
          this.pushConcertinaData(data['groupedData'], data['groups'], data['xHeadings']);
          this.setGroups(this.concertinaDataService.originalConcertinaData.groups);
          resolve(true);
        }, (error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  /**
     * @description Manage scroll height on expand/collapse on row
     * @author Amit Mahida
     * @memberof ConcertinaCanvasService
     */
  manageScrollHeight() {
    if (this.expandKey.indexOf('y') > -1) {
      setTimeout(() => {
        document.getElementById('scroll-container').dispatchEvent(new CustomEvent('scroll'));
        this.loadingLayer.hide();
        this.expandKey = '';
      }, 500);
    }
  }

  /**
   * @description Manages expand collapse
   * @author VJ
   * @param {string} key
   * @returns
   * @memberof ConcertinaCanvasService
   */
  manageExpandCollapse(key: string) {

    let fetchData = true;
    let tempConcertinaData: any = {};
    tempConcertinaData = _.cloneDeep(this.concertinaDataService.originalConcertinaData);
    if (key.indexOf('x') >= 0) {
      if (!tempConcertinaData.xHeadings[key].drillable) {
        return;
      }
      for (let i = tempConcertinaData.groups.xGroups.length - 1; i >= 0; i--) {
        if (tempConcertinaData.groups.xGroups[i].indexOf(`${key}|`) > -1) {
          fetchData = false;
          tempConcertinaData.groups.xGroups.splice(i, 1);
        }
      }
      Object.keys(tempConcertinaData).forEach((element) => {
        if (tempConcertinaData[element].xValues) {
          Object.keys(tempConcertinaData[element].xValues).forEach((e) => {
            if (e.indexOf(`${key}|`) > -1) {
              delete tempConcertinaData[element].xValues[e];
            }
          });
        }
      });
    } else if (key.indexOf('y') >= 0) {
      for (let i = tempConcertinaData.groups.yGroups.length - 1; i >= 0; i--) {
        if (tempConcertinaData.groups.yGroups[i].indexOf(`${key}|`) > -1) {
          fetchData = false;
          tempConcertinaData.groups.yGroups.splice(i, 1);
        }
      }
      Object.keys(tempConcertinaData).forEach((element) => {
        if (element.indexOf(`${key}|`) > -1) {
          fetchData = false;
          delete tempConcertinaData[element];
        }
      });
    }
    if (!fetchData && this.swapZoneService.swapMode) {
      this.logHelper.logInfo(this.userBundle['vp.concertina.collapseNotAllowed'] || 'Collapse operation is not permitted in swap mode');
      return;
    }
    if (this.swapZoneService.swapMode && !_.isEmpty(this.multiSelectionForSwap)) {
      this.swapSelectionLayer.destroyChildren();
      this.swapSelectionLayer.batchDraw();
      this.multiSelectionForSwap = {};
    }
    this.concertinaDataService.concertinaData = _.cloneDeep(tempConcertinaData);
    this.concertinaDataService.originalConcertinaData = _.cloneDeep(tempConcertinaData);

    const searchInput: any = document.querySelector('#frameSearchFilter');
    searchInput.value = '';
    if (fetchData) {
      if (!_.isUndefined(this.concertinaDataService.concertinaData.xHeadings[key]) && !this.concertinaDataService.concertinaData.xHeadings[key].drillable) {
        return;
      }
      if (!_.isUndefined(this.concertinaDataService.concertinaData[key]) && !this.concertinaDataService.concertinaData[key].drillable) {
        return;
      }
      this.getDataOnClick(key).then((res) => {
        if (res) {
          if (this.isFiltersApplied()) {
            searchInput.value = this.frameSearchKey;
            this.filterConcertinaData();
          } else {
            this.loaderService.show();
            setTimeout(() => {
              this.updateTable();
              this.manageScrollHeight();
              this.loaderService.hide();
            }, 10);
          }
        }
      });
    } else {
      this.expandKey = key;
      this.gridCollapsed = true;
      if (this.isFiltersApplied()) {
        searchInput.value = this.frameSearchKey;
        this.filterConcertinaData();
      } else {
        this.loaderService.show();
        setTimeout(() => {
          this.updateTable();
          this.manageScrollHeight();
          this.loaderService.hide();
        }, 10);
      }
      this.cornerLayerLeft.moveToTop();
      this.cornerLayerRight.moveToTop();
    }
  }

  /**
   * @description Handles concertina view port on zoom in / zoom out
   * @author Amit Mahida
   * @memberof ConcertinaCanvasService
   */
  fitStageIntoParentContainer() {
    if (this.stage && this.headerStage) {
      this.stageWidth = this.getContainerWidth();
      this.rectWidth = this.stageWidth / this.stageThreshold;
      this.delta = this.rectWidth / this.colThreshold;
      this.stage.width(this.stageWidth);
      this.headerStage.width(this.getContainerWidth() + this.delta);

      // Body Stage Starts
      if (!this.setContainerHeight()) {
        return;
      }

      const containerHeightNumber: number = Number(this.containerHeight.replace('px', ''));
      this.stage.height(containerHeightNumber);
      this.loadingStage.height(containerHeightNumber);
      if (this.concertinaDataService.concertinaData) {
        this.loaderService.show();
        setTimeout(() => {
          if (this.isFiltersApplied()) {
            this.filterConcertinaData();
          } else {
            this.updateTable();
          }
          this.loaderService.hide();
        }, 10);
      }
      if (this.noDataFoundLayer && this.noDataFoundLayer.getChildren().length) {
        this.noDataFoundLayer.getChildren()[0].width(this.stageWidth);
      }
      this.stage.batchDraw();
      this.headerStage.batchDraw();
    }
  }

  /**
   * @description Sets container height for vertical scrolling
   * @author Amit Mahida
   * @returns
   * @memberof ConcertinaCanvasService
   */
  setContainerHeight() {
    const container = document.querySelector('#container');
    if (container) {
      const height = window.innerHeight;
      if (this.frameSelectionEnabled && container) {
        this.containerHeight = `${height - 295}px`; // Visual Planner
      } else {
        this.containerHeight = `${height - 465}px`; // Result Tab
      }
      container['style']['height'] = this.containerHeight;
      return this.containerHeight;
    }
    return false;
  }

  /**
   * @description Returns the width of container of concertina stage
   * @author Amit Mahida
   * @returns
   * @memberof ConcertinaCanvasService
   */
  getContainerWidth() {
    const container = document.querySelector('#container');

    if (this.frameSelectionEnabled) {
      //  now we need to fit stage into parent
      return container.clientWidth - this.delta;
    } else {
      //  now we need to fit stage into parent
      return container.clientWidth;
    }
  }

  /**
   * @description Updates cart data params on selection change
   * @author Amit Mahida
   * @param {*} data
   * @param {string} key
   * @memberof ConcertinaCanvasService
   */
  updateCartReqParams(data: any, key: string) {
    if (!_.isUndefined(key) && !_.isUndefined(data)) {
      this.cartRequstParams[key] = {
        frame: data.frame,
        startDate: data.minDate,
        endDate: data.maxDate,
      };
    }
    const selectedFrames: any = _.values(this.cartRequstParams);
    for (let index = selectedFrames.length - 1; index >= 0; index--) {
      const element = selectedFrames[index];
      if (element.selectedInCart) {
        selectedFrames.splice(index, 1);
      }
    }
    const groupedFrames = {};
    for (const selectedFrame of selectedFrames) {
      const groupKey = selectedFrame.startDate + selectedFrame.endDate;
      if (!groupedFrames[groupKey]) {
        groupedFrames[groupKey] = [];
      }
      groupedFrames[groupKey].push(selectedFrame);
    }
    const groupedData = [];
    for (const index in groupedFrames) {
      if (groupedFrames.hasOwnProperty(index)) {
        const element = groupedFrames[index];
        if (element.length > 0) {
          groupedData.push({
            startDate: element[0].startDate,
            endDate: element[0].endDate,
            frames: []
          });
          element.forEach((frame) => {
            groupedData[groupedData.length - 1].frames.push(frame.frame);
          });
        }
      }
    }
    this.cartReqData = groupedData;
  }

  /**
   * @description Validates the current selection with respect to dates and frame
   * @author Amit Mahida
   * @param {any} layer
   * @param {any} [removingSelection]
   * @returns
   * @memberof ConcertinaCanvasService
   */
  getSelectionData(group: Konva.Group) {
    if (!_.isUndefined(group)) {
      const cartRequestParam = this.cartSelectionData[group.getAttr('name')];
      let frame = null;
      let maxDate;
      let minDate;
      frame = cartRequestParam.frame;
      const datesInSelection = [];
      cartRequestParam.range.forEach((range) => {
        const dates = [];
        dates.push(range.startDate);
        dates.push(range.endDate);
        dates.forEach((date) => {
          const innerDate = new Date(date);
          if (Object.prototype.toString.call(innerDate) === '[object Date]') {
            // it is a date

            if (isNaN(innerDate.getTime())) {  // d.valueOf() could also work
              // date is not valid
              return;
            } else {
              // date is valid
              // isSelectionHasAnyDate = true;
              datesInSelection.push(date);
            }
          } else {
            return;
            // not a date
          }
        });
      });
      maxDate = minDate = datesInSelection[0];
      datesInSelection.forEach((date) => {
        if (moment(date).isSameOrAfter(maxDate)) {
          maxDate = date;
        }
        if (moment(date).isSameOrBefore(minDate)) {
          minDate = date;
        }
      });
      const selectedData = {
        frame,
        minDate,
        maxDate,
      };
      return {
        selectedData,
        status: true
      };
    }
  }

  /**
   * @description Updates concertina data on delete
   * @author Amit Mahida
   * @memberof ConcertinaCanvasService
   */
  updateConcertinaDataOnDelete() {
    for (const key in this.deletedMarkedSelections) {
      if (this.deletedMarkedSelections.hasOwnProperty(key)) {
        const selection = this.deletedMarkedSelections[key];
        const selectedRow = selection.selectedRows[0];
        const selectedColumns = selection.selectedColumns;
        for (const selectedColumn of selectedColumns) {
          if (this.concertinaDataService.originalConcertinaData[selectedRow] &&
            this.concertinaDataService.originalConcertinaData[selectedRow].xValues &&
            this.concertinaDataService.originalConcertinaData[selectedRow].xValues[selectedColumn]) {
            delete this.concertinaDataService.originalConcertinaData[selectedRow].xValues[selectedColumn].isMarkedToDelete;
            delete this.concertinaDataService.originalConcertinaData[selectedRow].xValues[selectedColumn].selectedInCart;
          }
        }
      }
    }
  }

  /**
   * @description Updates status masks on deleting seletions
   * @author Amit Mahida
   * @returns
   * @memberof ConcertinaCanvasService
   */
  updateCartDataOnDelete() {
    let selectionGroups: any = this.selectionLayer.getChildren();
    selectionGroups = selectionGroups.filter((group) => {
      return group.attrs.isMarkedToDelete;
    });
    for (let index = selectionGroups.length - 1; index >= 0; index--) {
      this.deleteSelection(selectionGroups[index]);
    }

    if (!_.isEmpty(this.deletedMarkedSelections)) {
      this.deleteSelectionFromCart();
      this.updateConcertinaDataOnDelete();
      this.deletedMarkedSelections = {};
    }
  }

  /**
   * @description Returns array of frame ids of same visual unit as passed frameid (frameId is excluded)
   * @author Amit Mahida
   * @param {number} frameId
   * @returns
   * @memberof ConcertinaCanvasService
   */
  getSameVuFrames(frameId: number) {
    let vUnit = undefined;
    const frames = [];
    const yGroups = this.concertinaDataService.originalConcertinaData.groups.yGroups;
    for (const yGroup of yGroups) {
      if (this.concertinaDataService.originalConcertinaData[yGroup].frameId === frameId
        && this.concertinaDataService.originalConcertinaData[yGroup].visualUnitId) {
        vUnit = this.concertinaDataService.originalConcertinaData[yGroup].visualUnitId;
        break;
      }
    }
    if (vUnit) {
      for (const yGroup of yGroups) {
        if (this.concertinaDataService.originalConcertinaData[yGroup].visualUnitId
          && this.concertinaDataService.originalConcertinaData[yGroup].visualUnitId === vUnit
          && this.concertinaDataService.originalConcertinaData[yGroup].frameId !== frameId) {
          frames.push(this.concertinaDataService.originalConcertinaData[yGroup].frameId);
        }
      }
    }
    return frames;
  }

  /**
   * @description Save selected frames
   * @author Amit Mahida
   * @returns
   * @memberof ConcertinaCanvasService
   */
  saveFrame() {
    if (this.cartReqData.length > 0) {
      this.cartReqData.forEach((element) => {
        element.frames.forEach((frame) => {
          this.cartService.addFrameVP({
            frameId: frame
          }, element.startDate, element.endDate);
          const vuFrames = this.getSameVuFrames(frame);
          if (vuFrames.length) {
            for (const vuFrameId of vuFrames) {
              this.cartService.addFrameVP({
                frameId: vuFrameId
              }, element.startDate, element.endDate);
            }
          }
        });
      });
    }
    return this.cartService.saveFrame();
  }

  /**
   * @description Get frame details
   * @author Amit Mahida
   * @param {string} frameId
   * @returns
   * @memberof ConcertinaCanvasService
   */
  getFrameDetails(frameId: string) {
    const params = {
      frameId,
      action: 'getFrameDetail'
    };
    return this.concertinaDataService.getFrameDetails(params);
  }

  /**
   * @description Manages state of the drillable icons
   * @author Amit Mahida
   * @param {string} nameOfKey
   * @memberof ConcertinaCanvasService
   */
  manageDrilledDownKeys(nameOfKey: string) {

    if (this.drilledDownKeys.indexOf(nameOfKey) > -1) {
      const index = this.drilledDownKeys.indexOf(nameOfKey);
      this.drilledDownKeys.splice(index, 1);

      const indexes = this.drilledDownKeys.filter(item => item.includes(`${nameOfKey}|`));
      indexes.forEach((element) => {
        const indexOfChild = this.drilledDownKeys.indexOf(element);
        this.drilledDownKeys.splice(indexOfChild, 1);
      });
    } else {
      this.drilledDownKeys.push(nameOfKey);
    }
  }

  /**
   * @description Returns true if filters applied
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof ConcertinaCanvasService
   */
  isFiltersApplied(): boolean {
    let filtersApplied = false;
    if (this.frameSearchKey !== ''
      || (this.frameSelectionEnabled && this.statusFilter !== this.frameFilterType.all)
      || (this.dataShareService.appName === AppNameEnum.workspace && this.tabValue === GLOBAL.concertinaNotSelectedTab)
      || this.forceFilterApplied) {
      filtersApplied = true;
      this.forceFilterApplied = false;
    }
    return filtersApplied;
  }

  /**
   * @description Resets concertina data
   * @author Amit Mahida
   * @memberof ConcertinaCanvasService
   */
  resetConcertinaData() {
    this.concertinaDataService.originalConcertinaData = undefined;
    this.concertinaDataService.concertinaData = undefined;
    this.setGroups([]);
    if (this.frameSelectionEnabled) {
      this.resetCurrentSelection();
    }
    if (this.swapZoneService.swapMode) {
      this.swapZoneService.swapMode = false;
      this.readOnlyForSwap = false;
      this.swapZoneService.setSwapData(new SwapData());
    }
  }

  /**
   * @description Handles navigation positions on expand/collapse filter
   * @author Amit Mahida
   * @param {boolean} isFilterAreaVisible
   * @memberof ConcertinaCanvasService
   */
  onFilterAreaVisibilityChange(isFilterAreaVisible: boolean): void {
    this.$filterAreaSliderSub.next(isFilterAreaVisible);
  }

  toggleFI() {
    if (this.showFI) {
      this.FITextLayer.show();
    } else {
      this.FITextLayer.hide();
    }
    this.FITextLayer.moveToTop();
    this.FITextLayer.batchDraw();
  }

  /**
   * @description Update bookings on moving campaings from planner to swap zone
   * @author Amit Mahida
   * @param {string} campaignRef
   * @param {Booking[]} bookings
   * @param {Booking} bookedSlot
   * @returns {Booking[]}
   * @memberof ConcertinaCanvasService
   */
  updateBookingOnSwap(campaignRef: string, bookings: Booking[], bookedSlot: Booking): Booking[] {
    const removeBookingIndex = bookings.findIndex(booking => booking.campaignReference === campaignRef
      && moment(bookedSlot.startDate).isSameOrAfter(booking.startDate)
      && moment(bookedSlot.endDate).isSameOrBefore(booking.endDate));
    const splitBooking = bookings[removeBookingIndex];
    bookings.splice(removeBookingIndex, 1);
    if (splitBooking && splitBooking.startDate === bookedSlot.startDate && splitBooking.endDate !== bookedSlot.endDate) {
      // When the split selection is at start and end in between
      const booking: Booking = {
        campaignReference: splitBooking.campaignReference,
        startDate: moment(bookedSlot.endDate).add(1, 'hour').startOf('hour').format('YYYY-MM-DDTHH:mm:ss.SSS'),
        endDate: splitBooking.endDate,
        sot: splitBooking.sot
      };
      bookings.push(booking);
    } else if (splitBooking && splitBooking.startDate !== bookedSlot.startDate && splitBooking.endDate === bookedSlot.endDate) {
      // When the split selection is at end and start in between
      const booking: Booking = {
        campaignReference: splitBooking.campaignReference,
        startDate: splitBooking.startDate,
        endDate: moment(bookedSlot.startDate).subtract(1, 'hour').endOf('hour').format('YYYY-MM-DDTHH:mm:ss.SSS'),
        sot: splitBooking.sot
      };
      bookings.push(booking);
    } else if (splitBooking && splitBooking.startDate === bookedSlot.startDate && splitBooking.endDate === bookedSlot.endDate) {
      // When the split selection exact
      // bookings.splice(removeBookingIndex, 1); This is not required
    } else if (splitBooking) {
      // When the split selection is in between
      const booking1: Booking = {
        campaignReference: splitBooking.campaignReference,
        startDate: splitBooking.startDate,
        endDate: moment(bookedSlot.startDate).subtract(1, 'hour').endOf('hour').format('YYYY-MM-DDTHH:mm:ss.SSS'),
        sot: splitBooking.sot
      };
      bookings.push(booking1);
      const booking2: Booking = {
        campaignReference: splitBooking.campaignReference,
        startDate: moment(bookedSlot.endDate).add(1, 'hour').startOf('hour').format('YYYY-MM-DDTHH:mm:ss.SSS'),
        endDate: splitBooking.endDate,
        sot: splitBooking.sot
      };
      bookings.push(booking2);
    }
    return bookings;
  }

  /**
   * @description Update bookings on reverting a swap from swap zone
   * @author Amit Mahida
   * @param {string} campaignRef
   * @param {Booking[]} bookings
   * @param {Booking} bookedSlot
   * @returns {Booking[]}
   * @memberof ConcertinaCanvasService
   */
  updateBookingOnSwapRevert(campaignRef: string, bookings: Booking[], bookedSlot: Booking): Booking[] {
    const sortedBookings = bookings.sort((a, b) => (a.startDate > b.startDate) ? 1 : -1);
    const addBookingIndex = sortedBookings.findIndex(booking => booking.campaignReference === campaignRef);
    const mergeBooking = sortedBookings[addBookingIndex];
    if (moment(bookedSlot.endDate).add(1, 'hour').startOf('hour').format('YYYY-MM-DDTHH:mm:ss.SSS') === mergeBooking.startDate) {
      // End date of bookedSlot is at edge of current start
      sortedBookings[addBookingIndex].startDate = bookedSlot.startDate;
    } else if (moment(bookedSlot.startDate).subtract(1, 'hour').endOf('hour').format('YYYY-MM-DDTHH:mm:ss.SSS') === mergeBooking.endDate) {
      // start date of bookedSlot is at edge of current end
      sortedBookings[addBookingIndex].endDate = bookedSlot.endDate;
    } else {
      // same booking but not continuous
      sortedBookings.push(bookedSlot);
    }
    // Merge bookings that are continuous
    return this.mergeContinuousBookings(sortedBookings);
  }

  /**
   * @description Updates bookings in frame list
   * @author Amit Mahida
   * @param {Frame} frame
   * @param {SwapData} swapData
   * @param {boolean} insertion
   * @returns
   * @memberof ConcertinaCanvasService
   */
  updateFrameBookings(frame: Frame, swapData: SwapData, insertion: boolean) {
    for (const key in swapData.bookingDetailsColumnWise) {
      if (Object.prototype.hasOwnProperty.call(swapData.bookingDetailsColumnWise, key)) {
        const bookingDetails = swapData.bookingDetailsColumnWise[key];
        for (const bookingDetail of bookingDetails) {
          if (insertion) {
            const bookingIndex = frame.bookings.findIndex(booking => booking.campaignReference === bookingDetail.campaignReference);
            if (bookingIndex > -1) {
              // Campaign exist in the frame
              const booking: Booking = {
                campaignReference: bookingDetail.campaignReference,
                startDate: bookingDetail.startDate,
                endDate: bookingDetail.endDate,
                sot: bookingDetail.sot
              };
              frame.bookings = this.updateBookingOnSwapRevert(bookingDetail.campaignReference, frame.bookings, booking);
            } else {
              const isBookingAlreadyPresent = frame.bookings.findIndex(b =>
                bookingDetail.campaignReference === b.campaignReference
                && bookingDetail.startDate === b.startDate
                && bookingDetail.endDate === b.endDate
                && bookingDetail.sot === b.sot
              );
              if (isBookingAlreadyPresent === -1) {
                const booking: Booking = {
                  campaignReference: bookingDetail.campaignReference,
                  startDate: bookingDetail.startDate,
                  endDate: bookingDetail.endDate,
                  sot: bookingDetail.sot
                };
                frame.bookings.push(booking);
              }
            }
          } else {
            const booking = {
              campaignReference: bookingDetail.campaignReference,
              startDate: bookingDetail.startDate,
              endDate: bookingDetail.endDate,
              sot: bookingDetail.sot
            };
            frame.bookings = this.updateBookingOnSwap(bookingDetail.campaignReference, frame.bookings, booking);
          }
        }
      }
    }
    return frame;
  }

  /**
   * @description updates master frame list on swap performed
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @param {boolean} [insertion=true]
   * @param {number} [frameId]
   * @memberof ConcertinaCanvasService
   */
  updateFrameList(swapData: SwapData, insertion = true, frameId?: number) {
    const frameList = this.swapZoneService.getFramesList();
    if (frameId) {
      const frameIndex = frameList.findIndex((frameData) => {
        return frameData.frameId === frameId;
      });
      frameList[frameIndex] = this.updateFrameBookings(frameList[frameIndex], swapData, insertion);
    } else {
      if (swapData.frames && swapData.frames.length) {
        for (const frame of swapData.frames) {
          const frameIndex = frameList.findIndex((frameData) => {
            return frameData.frameId === frame.frameId;
          });
          frameList[frameIndex] = this.updateFrameBookings(frameList[frameIndex], swapData, insertion);
        }
      }
    }
    this.swapZoneService.setFramesList(frameList);
  }

  /**
   * @description Updates concertina data on custom date selection for swap
   * @author Amit Mahida
   * @param {string} newY
   * @param {string} xStart
   * @param {string} xEnd
   * @param {boolean} [revert=false]
   * @param {boolean} [swapDone]
   * @param {SwapData} [swapData]
   * @memberof ConcertinaCanvasService
   */
  updateConcertinaDataForCustomDates(newY: string, xStart: string, xEnd: string, revert = false, swapDone?: boolean, swapData?: SwapData, frame?: any) {
    const startXIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(xStart);
    const endXIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(xEnd);
    if (startXIndex > -1 && endXIndex > -1) {
      for (let index = startXIndex; index <= endXIndex; index++) {
        const xKey = this.concertinaDataService.originalConcertinaData.groups.xGroups[index];
        let bookings = swapData && swapData.bookingDetailsColumnWise[xKey];
        const bookingDetailGroups = swapData && swapData.bookingDetailGroups ? swapData.bookingDetailGroups.map(bdg => bdg.groupId) : [];
        let selectedBDGs = swapData ? swapData.campaignReferences.map(cref => cref.addedFromGroupId) : [];
        selectedBDGs = _.uniq(selectedBDGs);
        const swapDoneForAllGroups = bookingDetailGroups.length && selectedBDGs.length ? selectedBDGs.length === bookingDetailGroups.length : false;
        if (swapData && swapDone && swapData.campaignReferences && swapData.campaignReferences.length
          && swapDoneForAllGroups) {
          bookings = swapData.campaignReferences;
        }
        let tempBookings = [];
        if (revert && !swapDone) {
          frame.campaignReference.forEach(cr => {
            tempBookings.push(bookings.find(b => b.campaignReference === cr));
          });
          tempBookings = _.uniq(tempBookings);
        }
        this.updateConcertinaDataOnSwap(newY, xKey, revert, swapDone, tempBookings);
      }
    }
  }

  /**
   * @description Updates concertina data on swap revert
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @memberof ConcertinaCanvasService
   */
  updateConcertinaDataOnSwapRevert(swapData: SwapData) {
    for (const xKey in swapData.bookingDetailsColumnWise) {
      if (swapData.bookingDetailsColumnWise.hasOwnProperty(xKey)) {
        if (swapData.frames && swapData.frames.length) {
          if (!_.isUndefined(swapData.onlyToFrameIndex)) {
            const frame = swapData.frames[swapData.onlyToFrameIndex];
            this.updateConcertinaDataForCustomDates(frame.newY, xKey, xKey, undefined, undefined, swapData);
            this.updateAvailableSOT(swapData, false, true, swapData.onlyToFrameIndex);
          } else {
            swapData.frames.forEach((frame) => {
              if (frame.newY) {
                this.updateConcertinaDataForCustomDates(frame.newY, xKey, xKey, undefined, undefined, swapData, frame);
              }
              this.updateConcertinaDataForCustomDates(frame.y, xKey, xKey, true, false, swapData, frame);
            });
            this.updateAvailableSOT(swapData, true);
          }
        }
      }
    }
    if (_.isUndefined(swapData.onlyToFrameIndex)) {
      this.updateFrameList(swapData);
    }
  }

  /**
   * @description Handles changes planned when a target frame is selected from swap zone
   * @author Amit Mahida
   * @param {SwapData} swapDone
   * @memberof ConcertinaCanvasService
   */
  swapDone(swapDone: SwapData) {
    for (const cref of swapDone.campaignReferences) {
      for (const frame of swapDone.frames) {
        if (frame.newY) {
          this.updateConcertinaDataForCustomDates(frame.newY, cref.xStart, cref.xEnd, true, true, swapDone);
        }
      }
    }
    this.updateAvailableSOT(swapDone, true, true);
    this.updateTable();
  }

  /**
   * @description updates concertina on reverting a swap
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @memberof ConcertinaCanvasService
   */
  revertSwap(swapData: SwapData) {
    this.updateConcertinaDataOnSwapRevert(swapData);
    if (this.swapZoneService.allSwapsSelected && !this.swapZoneService.swaps.length) {
      this.updateTable();
    }
    if (!this.swapZoneService.allSwapsSelected && this.concertinaDataService.concertinaData) {
      this.updateTable();
    }
  }

  /**
   * @description toggles filter area
   * @author Amit Mahida
   * @param {boolean} close
   * @memberof ConcertinaCanvasService
   */
  toggleFilterArea(close: boolean) {
    this.$toggleFilterAreaSub.next(close);
  }

  /**
   * @description Filters frames which are not selected in the current solution on workspace in advanced availibity popup
   * @author Amit Mahida
   * @param {*} concertinaData
   * @returns
   * @memberof ConcertinaCanvasService
   */
  filterFramesNotSelected(concertinaData) {
    concertinaData.groups.yGroups = concertinaData.groups.yGroups.filter((group) => {
      return group === 'y' || !concertinaData[group].inSolution;
    });
    return concertinaData;
  }

}
