import { TagModel, SelectionItemModel } from './initial-config.model';
import { DynamicKeyObjectData } from './Dynamic.model';

interface BaseBricRequestModel {
  columns: number[];
  row: number;
}

export interface AllSelection {
  '-99'?: {
    '-99': number | string;
  };
}

export interface InchargeBricSelectedDataModel {
  startDate?: string | Date;
  endDate?: string | Date;
  minValue?: number;
  minValueUnit?: number;
  defaultValue?: number;
  defaultValueUnit?: number;
  defaultWeekDay?: number;
  current?: boolean;
  futureSplitFrom?: Date;
  futureSplitInterval?: number;
}

export interface InchargeBricRequestModel extends BaseBricRequestModel {
  selectionPeriod?: InchargeBricSelectedDataModel;
}

export interface MediaBricSelectedDataModel {
  mediaTypeId?: number;
  mediaTypeName?: string;
}

export interface MediaBricRequestModel extends BaseBricRequestModel {
  selectionCriteriaMedia?: DynamicKeyObjectData<DynamicKeyObjectData<-1>>;
}

export interface ChannelBricRequestModel extends BaseBricRequestModel {
  selectionCriteriaChannel?: DynamicKeyObjectData<DynamicKeyObjectData<-1>>;
}

export interface TagBricSelectedDataModel {
  [key: string]: TagModel[];
}
export interface TagBricRequestModel extends BaseBricRequestModel {
  selectionCriteriaTag?: [{ selectionId: number, exclude: boolean }, DynamicKeyObjectData<number>][];
}

export interface FormatBricSelectedDataModel {
  [key: string]: SelectionItemModel[] | DynamicKeyObjectData<boolean>;
  selectionCriteriaRestriction?: DynamicKeyObjectData<boolean>;
}
export interface FormatBricRequestModel extends BaseBricRequestModel {
  selectionCriteriaRestriction?: DynamicKeyObjectData<boolean>;
  selectionCriteriaFormat?: DynamicKeyObjectData<DynamicKeyObjectData<number>>;
}

export interface LocationBricSelectedDataModel {
  [key: string]: SelectionItemModel[];
}
export interface LocationBricRequestModel extends BaseBricRequestModel {
  selectionCriteriaLocation?: DynamicKeyObjectData<DynamicKeyObjectData<number>>;
}

export interface SOTBricSelectedDataModel {
  sot?: string | number;
  frequency?: string | number;
  dummySot?: string | number;
  def?: string | number;
  increment?: string | number;
  min?: string | number;
  max?: string | number;
  commercialSOT?: string | number;
  sotType?: string;
  framesSlots?: number;
  maxFramesSlotsText?: string;
  maxFramesSlots?: number;
}
export interface SOTBricRequestModel extends BaseBricRequestModel {
  selectionSot?: { sot: string | number };
}

export interface BudgetBricSelectedDataModel {
  price: number;
  minPrice?: number;
  maxPrice?: number;
  sotFloor?: number;
  sotCeiling?: number;
  allocated: number;
  tolerance: number;
  allocateAllFrames: boolean;
  greenTolerance: number;
  orangeTolerance: number;
  status?: number;
  boostedTarget?: boolean;
  relative?: boolean;
  weight?: number;
  priority?: number;
  isAllSelection?: boolean;
  sotIncrement?: number;
}

export interface BudgetBricRequestModel extends BaseBricRequestModel {
  selectionBudget?: BudgetBricSelectedDataModel;
}

export interface VolumeBricSelectedDataModel {
  impressions: number;
  minImpressions?: number;
  maxImpressions?: number;
  sotFloor: number;
  sotCeiling: number;
  allocated: number;
  tolerance?: number;
  allocateAllFrames: boolean;
  greenTolerance: number;
  orangeTolerance: number;
  status?: number;
  boostedTarget?: boolean;
  relative?: boolean;
  weight?: number;
  priority?: number;
  isAllSelection?: boolean;
  sotIncrement?: number;
}

export interface VolumeBricRequestModel extends BaseBricRequestModel {
  selectionAudienceReduction?: VolumeBricSelectedDataModel;
}

export class SotFloorCeilingDataModel {
  sotFloor: number = null;
  sotCeiling: number = null;
  sotIncrement?: number = null;
  disableFloorAndCeiling?: boolean = null;
  disableSOTIncrement?: boolean = null;
}
