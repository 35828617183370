export class Restrictions {
  startDate: string;
  endDate: string;
  defaultValue: {
    value: number,
    unit: number
  };
  minValue: {
    value: number,
    unit: number
  };
}

export class ProductCatalogue {
  idProductCatalogue: number;
  productCatalogueName: string;
  productCatalogueDescription: string;
  productCatalogueGroupId: number;
  productCatalogueTypeId: number;
  restrictions: Restrictions;
  inUse: boolean;
}

export class ProductCatalogueGroup {
  idProductCatalogueGroup: number;
  productCatalogueGroupCode: string;
  productCatalogueGroupName: string;
  inUse: boolean;
}

export class ProductCatalogueType {
  idProductCatalogueType: number;
  productCatalogueTypeCode: string;
  productCatalogueTypeName: string;
  inUse: boolean;
}

export class ProductCatalogueHolder {
  productCatalogueType: ProductCatalogueType[];
  productCatalogueGroup: ProductCatalogueGroup[];
  productCatalogue: ProductCatalogue[];
}
