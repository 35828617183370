import {
  DataShareService,
} from '../../core/services';
import { Options } from '@angular-slider/ngx-slider';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { CellValues } from '../../models';

export class FrameQualityBase {

  pcmMode: boolean;
  /**
   * It contians initial config data.
   */
  public userBundle: any;
  /**
   * selected brick value
   */
  public frameQuality = {
    audienceLevel: 5,
    isEditable: true
  };
  public previousValues;
  /**
   * ngx-slider options
   */
  public options: Options = {
    floor: 1,
    ceil: 10,
  };

  /**
   * Creates an instance of FrameQualityBase.
   * @param {DataShareService} dataShareService -  Service to share data between compoenents.
   * @memberof FrameQualityBase
   */
  constructor(
    private dataShareService: DataShareService,
    private appHeaderService: AppHeaderService
  ) {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
  }

  /**
   * @description initialize the data of instance
   * @author Amit Mahida
   * @param {function} fQSelectedValue selected value of cell
   * @param {CellValues} resolveObject
   * @memberof FrameQualityBase
   */
  init(fQSelectedValue, resolveObject: CellValues) {
    this.pcmMode = this.appHeaderService.enabledPCM;

    if (fQSelectedValue.hasOwnProperty('audienceLevel')) {
      this.frameQuality.audienceLevel = parseInt(fQSelectedValue.audienceLevel, 10);
    } else {
      this.frameQuality.audienceLevel = 5;
    }

    if (fQSelectedValue.hasOwnProperty('isEditable')) {
      this.frameQuality.isEditable = fQSelectedValue.isEditable;
    } else {
      this.frameQuality.isEditable = true;
    }

    // slider option setting
    // settimeout is temporary workaround the slider issue
    // Dynamic min and max value for handles are not being applied
    // issue Ref: https://github.com/angular-slider/ngx-slider/issues/58
    setTimeout(() => {
      this.setSliderOptions(resolveObject);
    }, 400);
  }

  /**
   * @description set the slider options from configurable object
   * @author Amit Mahida
   * @private
   * @param {CellValues} resolveObject
   * @memberof FrameQualityBase
   */
  private setSliderOptions(resolveObject: CellValues): void {
    const isEditable = resolveObject.productValidations ? !resolveObject.productValidations.isEditable : null;
    this.options = {
      floor: 1,
      ceil: 10,
      showTicks: true,
      noSwitching: true,
      enforceStep: false,
      showTicksValues: true,
      stepsArray: [
        { value: 1, legend: this.userBundle['workspace.framequality.slider.min'] },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
        { value: 10, legend: this.userBundle['workspace.framequality.slider.max'] },
      ],
      disabled: !this.pcmMode && isEditable
    };
  }

}
