import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { helper } from '../../utils/app.helper';
import { AppNameEnum } from '../../enum';
import { DataShareService } from '../../services/data-share.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { getFormData } from '../../utils/formData';
import { GLOBAL } from '../../utils/app.constant';
/**
 * Concertina http service to call backend service
 *
 * @export
 * @class ConcertinaHttpService
 */
@Injectable()
export class ConcertinaHttpService {
  /**
   * Request header
   *
   * @private
   * @memberof ConcertinaHttpService
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
  /**
   * Creates an instance of ConcertinaHttpService.
   * @param {HttpClient} http HttpClient Instance
   * @memberof ConcertinaHttpService
   */
  constructor(private http: HttpClient,
              private dataShareService: DataShareService) { }
  /**
   * To get request options
   *
   * @param {object} params
   * @returns
   * @memberof ConcertinaHttpService
   */
  getOptions(params: object) {
    return {
      body: getFormData(params),
      headers: this.headers
    };
  }
  /**
   * To get concertina configuration data
   *
   * @param {object} reqParams Request params
   * @returns
   * @memberof ConcertinaHttpService
   */
  getConcertinaConfig(reqParams: object) {
    let reqUrl = '';
    if (this.dataShareService.appName === AppNameEnum.visualplanner) {
      reqUrl = this.dataShareService.getServiceCallUrlByKey('VP_CONCERTINA_CONFIG');
    } else {
      reqUrl = this.dataShareService.getServiceCallUrlByKey('CONCERTINA_CONFIG_URL');
    }
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      reqUrl,
      this.getOptions(reqParams)
    )
      .map(helper.extractData)
      .catch(helper.handleError);
  }
  /**
   * To get concertina grid data
   *
   * @param {object} reqParams Request params
   * @returns
   * @memberof ConcertinaHttpService
   */
  getConcertinaData(reqParams: any) {
    let concertinaUrl = '';
    if (reqParams.concertinaRequest) {
      let jsonFile = '';
      if (reqParams.concertinaRequest.x) {
        jsonFile = reqParams.concertinaRequest.x;
        concertinaUrl = `/data/concertina_jsons_structure_change/${jsonFile.replace(/[|]/g, '-')}.json`;
      } else if (reqParams.concertinaRequest.y) {
        jsonFile = reqParams.concertinaRequest.y;
        concertinaUrl = `/data/concertina_jsons_structure_change/${jsonFile.replace(/[|]/g, '-')}.json`;
      } else {
        concertinaUrl = '/data/resultjson/vip_digital.json';
      }
      reqParams.concertinaRequest = JSON.stringify(reqParams.concertinaRequest);
    }

    let reqUrl = '';
    if (this.dataShareService.appName === AppNameEnum.visualplanner) {
      reqUrl = GLOBAL.HTTP_METHOD === 'GET' ? concertinaUrl : this.dataShareService.getServiceCallUrlByKey('VP_CONCERTINA_PROCESS');
    }
    if (this.dataShareService.appName === AppNameEnum.result || this.dataShareService.appName === AppNameEnum.workspace) {
      reqUrl = GLOBAL.HTTP_METHOD === 'GET' ? concertinaUrl : this.dataShareService.getServiceCallUrlByKey('RESULT_URL');
    }

    return this.http.request(
      GLOBAL.HTTP_METHOD,
      reqUrl,
      this.getOptions(reqParams)
    )
      .map(helper.extractData)
      .catch(helper.handleError);
  }

}
