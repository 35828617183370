import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { BrickBaseService } from '../../core/services/brick-base.service';
import { CellAttributeService } from '../../core/services/cell-attributes.service';
import { DataShareService } from '../../core/services/data-share.service';
import { LocaleData } from '../../core/utils/LocaleData';
import { Filter, Row, Campaign, UiControl, ProductHelper, SystemFlags, ColumnHelper } from '../../models';
import { LoadWorkspace } from '../../workspace/load-workspace';
import { SharedService } from './../../core/services/shared.service';
import { DatePipe } from '@angular/common';
import { getFormData } from '../utils/formData';
import { StateService } from './state.service';
import { LoaderService } from './loader.service';
import { CampaignDetails } from '../../commercial/commercial.model';
import { GLOBAL } from '../utils/app.constant';

declare let XLSX: any;
declare let saveAs; // FileSaver.js
class Workbook {
  // tslint:disable-next-line:variable-name
  SheetNames;
  // tslint:disable-next-line:variable-name
  Sheets;
  constructor() {
    if (!(this instanceof Workbook)) {
      return new Workbook();
    }
    this.SheetNames = [];
    this.Sheets = {};
  }
}
@Injectable()
export class PcmService {

  headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  userBundle = {};

  /**
   * Creates an instance of PcmService.
   * @author Amit Mahida
   * @param {HttpClient} http
   * @param {DataShareService} dataShareService
   * @param {BrickBaseService} brickBaseService
   * @param {SharedService} sharedService
   * @param {CellAttributeService} cellAttributeService
   * @param {DatePipe} datePipe
   * @param {LoaderService} loaderService
   * @memberof PcmService
   */
  constructor(
    private http: HttpClient,
    private dataShareService: DataShareService,
    private brickBaseService: BrickBaseService,
    private sharedService: SharedService,
    private cellAttributeService: CellAttributeService,
    private datePipe: DatePipe,
    private loaderService: LoaderService
  ) {
  }

  extractData(res: Response) {
    return res;
  }

  handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body['error'] || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return Observable.throwError(errMsg);
  }

  /**
   * @description prepate product data for Export
   * @author Kishan Patel
   * @param {*} productCatalogueHolder
   * @param {*} columnConfig
   * @param {*} stateService
   * @returns
   * @memberof PcmService
   */
  prepareProductDataForExport(productCatalogueHolder, columnConfig, stateService) {
    return new Promise((resolve) => {
      const productRows = [];
      const productsOfProductCatalogue = productCatalogueHolder.productCatalogue;
      this.updateProductWithBricsData(productsOfProductCatalogue, columnConfig, stateService).then((products: any[]) => {

        const groups = productCatalogueHolder.productCatalogueGroup;
        const types = productCatalogueHolder.productCatalogueType;
        let row = [];
        products.forEach((product) => {
          row = [
            {
              text: product['idProductCatalogue'],
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'left'
            },
            {
              text: product['productCatalogueName'],
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'left'
            },
            {
              text: product['productCatalogueDescription'],
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'left'
            },
            {
              text: !_.isUndefined(product['inUse']) && product['inUse'] ? 'Yes' : 'No',
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'center'
            },
            {
              text: this.getProductGroupName(groups, product['productCatalogueGroupId']),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'center'
            },
            {
              text: this.getProductTypeName(types, product['productCatalogueTypeId']),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'center'
            },
            {
              text: !_.isUndefined(product['restrictions']['startDate']) ?
                this.datePipe.transform(product['restrictions']['startDate'], LocaleData.displayDateFormat) : '-',
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'center'
            },
            {
              text: !_.isUndefined(product['restrictions']['endDate']) ?
                this.datePipe.transform(product['restrictions']['endDate'], LocaleData.displayDateFormat) : '-',
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'center'
            },
            {
              text: !_.isUndefined(product['restrictions']['agencies']) ?
                this.getAccessControlData(product['restrictions']['agencies'], 'organisationName') : '-',
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'left'
            },
            {
              text: !_.isUndefined(product['restrictions']['advertisers']) ?
                this.getAccessControlData(product['restrictions']['advertisers'], 'organisationName') : '-',
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'left'
            },
            {
              text: !_.isUndefined(product['restrictions']['specialists']) ?
                this.getAccessControlData(product['restrictions']['specialists'], 'organisationName') : '-',
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'left'
            },
            {
              text: !_.isUndefined(product['restrictions']['campaignTypes']) ?
                this.getAccessControlData(product['restrictions']['campaignTypes'], 'campaignTypeName') : '-',
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'left'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.Channel),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'left'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.Media),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'center'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.Location),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'left'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.Format),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'left'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.SOT),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'left'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.Audience),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'left'
            },
            {
              // allowDayParts allowDays allowHours
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.Pattern, 'allowDays'),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'center'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.Pattern, 'allowDayParts'),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'center'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.Pattern, 'allowHours'),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'center'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.Tag),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'left'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.Environment),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'left'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.Distribution),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'center'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.Frame),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'center'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.Budget),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'center'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.Proximity),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'center'
            },
            {
              text: this.getBrickSelectedValues(product['items'], this.brickBaseService.brickID.List),
              color: '00FFFFFF',
              textColour: '00000000',
              bold: false,
              alignment: 'center'
            }
          ];

          productRows.push(row);
        });
        resolve(productRows);
      });
    });
  }

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  /**
   * @description update Product With Brics Data
   * @author Kishan Patel
   * @param {*} products
   * @param {*} columnConfig
   * @param {*} stateService
   * @returns
   * @memberof PcmService
   */
  async updateProductWithBricsData(products, columnConfig, stateService) {
    return new Promise(async (resolve) => {
      this.loaderService.show();
      const params = {
        action: 'getProductCatalogueList',
        listOfProductCatalogueId: []
      };
      this.getProductDetailsHttpCall(params).subscribe(async (data: any) => {
        if (String(data.status) === 'OK') {
          await this.asyncForEach(products, async (product) => {
            const productBricsData = data.data.find(id => id.idProductCatalogue === product.idProductCatalogue);

            if (productBricsData !== undefined && productBricsData['bricsData']) {
              productBricsData.bricsData = _.sortBy(productBricsData.bricsData, 'row');

              let filterObj: Filter = stateService.getWorkspaceFilterObj();
              const loadWorkspace: LoadWorkspace = new LoadWorkspace(this.brickBaseService, this.cellAttributeService, this.sharedService, stateService);
              filterObj = loadWorkspace.getWorkspaceFilter(filterObj, productBricsData.bricsData,
                this.dataShareService.getInitialConfig(),
                this.dataShareService.getBricsMasterData(),
                columnConfig,
                null,
                null,
                true,
                null);
              product['items'] = filterObj.rows;
            }
          });
          this.loaderService.hide();
          resolve(products);
        }
      });
    });
  }

  /**
   * @description get Product Details Http Call
   * @author Kishan Patel
   * @param {*} param
   * @returns
   * @memberof PcmService
   */
  getProductDetailsHttpCall(param) {
    const serviceUrl = this.dataShareService.getServiceCallUrlByKey('PCM_GET_PRODUCT');
    const requestOptions = {
      headers: this.headers,
      body: getFormData(param)
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      serviceUrl,
      requestOptions
    )
      .map(this.extractData)
      .catch(this.handleError);
  }

  /**
   * @description get Product Group Name
   * @author Kishan Patel
   * @param {*} groups
   * @param {*} id
   * @returns
   * @memberof PcmService
   */
  getProductGroupName(groups, id) {
    const foundGroup = groups.find((group) => {
      return group.idProductCatalogueGroup === id;
    });
    return foundGroup.productCatalogueGroupName;
  }

  /**
   * @description get Product Name
   * @author Kishan Patel
   * @param {*} types
   * @param {*} id
   * @returns
   * @memberof PcmService
   */
  getProductTypeName(types, id) {
    const foundType = types.find((type) => {
      return type.idProductCatalogueType === id;
    });
    return foundType.productCatalogueTypeName;
  }

  /**
   * @description Generic method to get the concatenated keyData from the Object based on the keyName.
   * @author Shreni Shah
   * @date 2020-01-31
   * @param {*} objectName
   * @param {*} keyName
   * @returns
   * @memberof PcmService
   */
  getAccessControlData(objectName, keyName) {
    return objectName.map(item => item[keyName]).join(', ');
  }

  /**
   * @description
   * @author Kishan Patel
   * @param {Row[]} rows
   * @param {(number | string)} bricId
   * @param {*} [patternType]
   * @returns
   * @memberof PcmService
   */
  getBrickSelectedValues(rows: Row[], bricId: number | string, patternType?) {
    try {
      let selectedValues = '';
      if (rows) {
        rows.forEach((row) => {
          row.cells.forEach((col) => {
            if (row.bric.bricid === bricId) {
              switch (bricId) {
                // ProductBrickID = channel brick
                case this.brickBaseService.brickID.Channel:
                  if (!_.isEmpty(col.selected)) {
                    if (col.selected.hasOwnProperty('-99')) {
                      selectedValues = `${this.userBundle['workspace.common.allSelection']}, `;
                    } else {
                      for (const key in col.selected) {
                        if (col.selected.hasOwnProperty(key)) {
                          const element = col.selected[key];
                          element.forEach((value) => {
                            selectedValues += `${value.productName}, `;
                          });
                        }
                      }
                    }
                  }
                  break;
                case this.brickBaseService.brickID.Media:
                  if (!_.isEmpty(col.selected)) {
                    if (col.selected.hasOwnProperty('-99')) {
                      selectedValues = `${this.userBundle['workspace.common.allSelection']}, `;
                    } else {
                      selectedValues = `${col.selected.selectedMediaObject.mediaTypeName}, `;
                    }
                  }
                  break;
                case this.brickBaseService.brickID.Location:
                  if (!_.isEmpty(col.selected)) {
                    if (col.selected.hasOwnProperty('-99')) {
                      selectedValues = `${this.userBundle['workspace.common.allSelection']}, `;
                    } else {
                      for (const key in col.selected) {
                        if (col.selected.hasOwnProperty(key)) {
                          const element = col.selected[key];
                          element.forEach((value) => {
                            selectedValues += `${value.name}, `;
                          });
                        }
                      }
                    }
                  }
                  break;
                case this.brickBaseService.brickID.Format:
                  if (!_.isEmpty(col.selected)) {
                    if (col.selected.hasOwnProperty('-99')) {
                      selectedValues = `${this.userBundle['workspace.common.allSelection']}, `;
                    } else {
                      delete col.selected.selectionCriteriaRestriction;
                      for (const key in col.selected) {
                        if (col.selected.hasOwnProperty(key)) {
                          const element = col.selected[key];
                          element.forEach((value) => {
                            selectedValues += `${value.name}, `;
                          });
                        }
                      }
                    }
                  }
                  break;
                case this.brickBaseService.brickID.SOT:
                  if (_.isEmpty(col.selected) || col.selected.hasOwnProperty('-99')) {
                    selectedValues = `${this.userBundle['workspace.common.allSelection']}, `;
                  } else {
                    selectedValues = `${col.selected.sot}, `;
                  }
                  break;
                case this.brickBaseService.brickID.Audience:
                  if (_.isEmpty(col.selected) || col.selected.hasOwnProperty('-99')) {
                    selectedValues = `${this.userBundle['workspace.common.allSelection']}, `;
                  } else {
                    selectedValues = `${col.selected.description}, `;
                  }
                  break;
                case this.brickBaseService.brickID.Pattern:
                  if (!_.isEmpty(col.selected)) {
                    selectedValues = col.selected[patternType] ? 'Yes, ' : 'No, ';
                  }
                  break;
                case this.brickBaseService.brickID.Tag:
                  if (_.isEmpty(col.selected) || col.selected.hasOwnProperty('-99')) {
                    selectedValues = `${this.userBundle['workspace.common.allSelection']}, `;
                  } else {
                    col.selected.allTabSelection.forEach((tag) => {
                      if (tag.exclude) {
                        if (selectedValues.indexOf('Exclude: ') === -1) {
                          selectedValues += '\nExclude: ';
                        }
                        selectedValues += `${tag.tagName}, `;
                      }
                    });
                    col.selected.allTabSelection.forEach((tag) => {
                      if (!tag.exclude) {
                        if (selectedValues.indexOf('Include: ') === -1) {
                          selectedValues += '\nInclude: ';
                        }
                        selectedValues += `${tag.tagName}, `;
                      }
                    });

                  }
                  break;
                case this.brickBaseService.brickID.Environment:
                  if (_.isEmpty(col.selected) || col.selected.hasOwnProperty('-99')) {
                    selectedValues = `${this.userBundle['workspace.common.allSelection']}, `;
                  } else {
                    for (const key in col.selected) {
                      if (col.selected.hasOwnProperty(key)) {
                        const element = col.selected[key];
                        element.forEach((value) => {
                          selectedValues += `${value.name}, `;
                        });
                      }
                    }
                  }
                  break;
                case this.brickBaseService.brickID.Distribution:
                  if (_.isEmpty(col.selected) || col.selected.hasOwnProperty('-99')) {
                    selectedValues = `${this.userBundle['workspace.common.allSelection']}, `;
                  } else {
                    selectedValues = `${col.selected.distributionName}, `;
                  }
                  break;
                case this.brickBaseService.brickID.Frame:
                  if (_.isEmpty(col.selected) || col.selected.hasOwnProperty('-99')) {
                    selectedValues = `${this.userBundle['workspace.common.allSelection']}, `;
                  } else {
                    selectedValues = `${col.selected.frameCount}, `;
                  }
                  break;
                case this.brickBaseService.brickID.Budget:
                  if (_.isEmpty(col.selected) || col.selected.hasOwnProperty('-99')) {
                    selectedValues = `${this.userBundle['workspace.common.allSelection']}, `;
                  } else {
                    selectedValues = `${col.selected.price}, `;
                  }
                  break;
                case this.brickBaseService.brickID.Proximity:
                  if (_.isEmpty(col.selected) || col.selected.hasOwnProperty('-99')) {
                    selectedValues = `${this.userBundle['workspace.common.allSelection']}, `;
                  } else {
                    if (col.selected.pointOfInterest) {
                      selectedValues += '\nPOI: ';
                      col.selected.pointOfInterest.selectedModelValues.forEach((tag) => {
                        if (col.selected.pointOfInterest.include === 'false') {
                          if (selectedValues.indexOf('Exclude: ') === -1) {
                            selectedValues += '\nExclude: ';
                          }
                          selectedValues += `${tag.poiName}, `;
                        } else {
                          if (selectedValues.indexOf('Include: ') === -1) {
                            selectedValues += '\nInclude: ';
                          }
                          selectedValues += `${tag.poiName}, `;
                        }
                      });
                      selectedValues += '\nDistance: ';
                      selectedValues += `${col.selected.pointOfInterest.distance.distancevalue}meter(s)`;
                    }

                    if (col.selected.postcodeSelection) {
                      selectedValues += '\nPostCodes: ';
                      col.selected.postcodeSelection.selectedModelValues.forEach((tag) => {
                        if (col.selected.postcodeSelection.include === 'false') {
                          if (selectedValues.indexOf('Exclude: ') === -1) {
                            selectedValues += '\nExclude: ';
                          }
                          selectedValues += `${tag.postcode}, `;
                        } else {
                          if (selectedValues.indexOf('Include: ') === -1) {
                            selectedValues += '\nInclude: ';
                          }
                          selectedValues += `${tag.postcode}, `;
                        }
                      });
                      selectedValues += '\nDistance: ';
                      selectedValues += `${col.selected.postcodeSelection.distance.distancevalue} meter(s)`;
                    }
                  }
                  break;
                case this.brickBaseService.brickID.List:
                  if (_.isEmpty(col.selected) || col.selected.hasOwnProperty('-99')) {
                    selectedValues = `${this.userBundle['workspace.common.allSelection']}, `;
                  } else {
                    if (col.selected.routeFrameIds) {
                      if (col.selected.routeFrameIds.include.length > 0) {
                        selectedValues += '\nInclude: ';
                        for (const tag of col.selected.routeFrameIds.include) {
                          if (!tag.exclude) {
                            selectedValues += `${tag.routeFrameId}, `;
                          }
                        }
                      }
                      if (col.selected.routeFrameIds.exclude.length > 0) {
                        selectedValues += '\nExclude: ';
                        for (const tag of col.selected.routeFrameIds.exclude) {
                          if (tag.exclude) {
                            selectedValues += `${tag.routeFrameId} + ', '`;
                          }
                        }
                      }
                    }
                  }
                  break;
                default:
                  break;
              }
            }
          });
        });
      }
      if (selectedValues === '') {
        selectedValues = '-';
      } else {
        selectedValues = selectedValues.slice(0, -2);
      }
      return selectedValues;
    } catch (err) {
      console.log('error in getBrickSelectedValues');
    }
  }

  /**
   * @description export Product
   * @author Kishan Patel
   * @param {*} productRows selected Product rows
   * @param {*} userBundle user
   * @memberof PcmService
   */
  exportProducts(productRows, userBundle) {
    const items = [];
    const productCols = [
      {
        text: userBundle['workspace.pcm.export.productCatalogueId'] || 'Product Catalogue Id',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 20
      },
      {
        text: userBundle['workspace.pcm.export.productCatalogueName'] || 'Product Catalogue Name',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 20
      },
      {
        text: userBundle['workspace.pcm.export.description'] || 'Description',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 20
      },
      {
        text: userBundle['workspace.pcm.export.active'] || 'Active',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.groupName'] || 'Group Name',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.typeName'] || 'Type Name',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.startDate'] || 'Start Date',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.endDate'] || 'End Date',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.agencies'] || 'Agencies',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.advertisers'] || 'Advertisers',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 14
      },
      {
        text: userBundle['workspace.pcm.export.specialists'] || 'Specialists',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['common.campaignType'] || 'Campaign Type',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.channel'] || 'Channel',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.media'] || 'Media',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.location'] || 'Location',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.format'] || 'Format',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.sot'] || 'SOT',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.rating'] || 'Rating',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.allowedDays'] || 'AllowedDays',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.allowedDayparts'] || 'Allowed Dayparts',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 14
      },
      {
        text: userBundle['workspace.pcm.export.allowedHours'] || 'Allowed Hours',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.tags'] || 'Tags',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.environments'] || 'Environments',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.distribution'] || 'Distribution',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.frames'] || 'Frames',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.price'] || 'Price',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 12
      },
      {
        text: userBundle['workspace.pcm.export.proximity'] || 'Proximity',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 20
      },
      {
        text: userBundle['workspace.pcm.export.list'] || 'List',
        color: '0070c28f',
        textColour: '00000000',
        bold: true,
        alignment: 'center',
        width: 20
      }
    ];
    items.push(productCols);
    productRows.forEach((row) => {
      items.push(row);
    });

    const wsName = 'Products';
    const wb = new Workbook();
    const ws = this.getSheetData(items);
    // column width
    const colWidth = [];
    for (const productCol of productCols) {
      colWidth.push({
        wch: productCol.width
      });
    }
    ws['!cols'] = colWidth;
    wb.SheetNames.push(wsName);
    wb.Sheets[wsName] = ws;
    const wbout = XLSX.write(wb, {
      bookType: 'xlsx', type: 'binary'
    });
    saveAs(new Blob([this.s2ab(wbout)], {
      type: 'application/octet-stream'
    }), 'Products.xlsx');
  }

  /**
   * @description get sheet Data
   * @author Kishan Patel
   * @param {*} data
   * @returns
   * @memberof PcmService
   */
  getSheetData(data) {
    const ws = {};
    const range = {
      s: {
        c: 10000000,
        r: 10000000
      },
      e: {
        c: 0,
        r: 0
      }
    };
    for (let R = 0; R !== data.length; ++R) {
      for (let C = 0; C !== data[R].length; ++C) {

        if (range.s.r > R) {
          range.s.r = R;
        }
        if (range.s.c > C) {
          range.s.c = C;
        }
        if (range.e.r < R) {
          range.e.r = R;
        }
        if (range.e.c < C) {
          range.e.c = C;
        }
        const cell = {
          v: data[R][C].text,
          color: data[R][C].color,
          textColour: data[R][C].textColour
        };
        if (cell.v === null) {
          continue;
        }
        const cellRef = XLSX.utils.encode_cell({
          c: C,
          r: R
        });

        if (typeof cell.v === 'number') {
          cell['t'] = 'n';
        } else if (typeof cell.v === 'boolean') {
          cell['t'] = 'b';
        } else {
          cell['t'] = 's';
        }
        const alignment = data[R][C].text === '-' ? 'center' : data[R][C].alignment;
        this.styleCell(cell, data[R][C].bold, alignment);
        ws[cellRef] = cell;
      }
    }
    if (range.s.c < 10000000) {
      ws['!ref'] = XLSX.utils.encode_range(range);
    }
    return ws;
  }

  /**
   * @description set style for cell
   * @author Kishan Patel
   * @param {*} cell
   * @param {*} bold
   * @param {*} alignment
   * @memberof PcmService
   */
  styleCell(cell, bold, alignment) {
    let argbColor = null;
    let argbTextColor = null;
    if (cell.color) {
      argbColor = cell.color.replace('#', 'FF');
    }
    if (cell.textColour) {
      argbTextColor = cell.textColour.replace('#', 'FF');
    }
    cell.s = {
      fill: {
        patternType: 'solid',
        fgColor: {
          rgb: argbColor
        }
      },
      font: {
        color: {
          rgb: argbTextColor
        },
        bold: bold ? true : false,
        sz: '11'
      },
      border: {
        top: {
          style: 'thin',
          color: {
            rgb: '00FFFFFF'
          }
        },
        left: {
          style: 'thin',
          color: {
            rgb: '00FFFFFF'
          }
        }
      },
      alignment: {
        horizontal: alignment
        // vertical: alignment
      }
    };
  }

  /**
   * @description set saveto Blob file
   * @author Kishan Patel
   * @param {*} s - xlsx write file
   * @returns
   * @memberof PcmService
   */
  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      // tslint:disable-next-line:no-bitwise
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }

  /**
   * Method to filter the products based on DateRange, Advertiser, Specialist , Agency
   *
   * @param {*} productCatalogues
   * @param {*} campaignDetails
   * @param {*} selectedRange
   * @param {*} filterInUseProducts
   * @returns
   * @memberof PcmService
   */
  filterProductsBasedOnRestriction(productCatalogues, campaignDetails, selectedRange, filterInUseProducts, stateService) {
    const uiControl: UiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    const showInvalidProducts = uiControl.showInvalidProducts;

    let productsWithoutRestrictions = [];
    let productsInGivenRange = [];
    let productsWithAllSelectionInRange = [];
    let productsWithCampaignType = [];
    let filterProducts = [];
    const products = [];
    const campaignTypeId = stateService.getCampaign().campaignTypeId;
    campaignDetails.campaignTypeId = campaignTypeId !== undefined ? campaignTypeId : campaignDetails.campaignTypeId;
    // SM-9278
    if (campaignDetails.campaignTypeId === -1) {
      campaignDetails.campaignTypeId = 0;
    }
    let productsInUse = _.cloneDeep(productCatalogues);
    // Products in use
    if (filterInUseProducts) {
      productsInUse = productCatalogues.filter(product => product.inUse);
    }

    // Products for selected Campaign Type
    productsWithCampaignType = productsInUse.filter((product) => {
      if (product.restrictions && Object.keys(product.restrictions).length && product.restrictions.campaignTypes) {
        const productCampaignType = product.restrictions.campaignTypes;
        return productCampaignType.filter(element => element.campaignTypeId === campaignDetails.campaignTypeId).length > 0;
      } else {
        return true;
      }
    });

    // Products in given range
    productsInGivenRange = productsWithCampaignType.filter((product) => {
      // unit = 0 then day else week
      let days = 0;
      if (product.restrictions.minValue) {
        if (product.restrictions.minValue.unit === 0) {
          days = product.restrictions.minValue.value;
        } else {
          days = product.restrictions.minValue.value * 7;
        }
      }
      product.inValidForCurrentColumn = product.restrictions.startDate && !(moment(selectedRange.startDate).isSameOrAfter(product.restrictions.startDate) &&
        moment(selectedRange.endDate).isSameOrBefore(product.restrictions.endDate) &&
        (moment(selectedRange.endDate).diff(selectedRange.startDate, 'days') + 1) >= days);

      return showInvalidProducts || !product.inValidForCurrentColumn;
      // VJ: 24-08-2018 Range/Day filter added
    });

    // // Products with Default Week Day SM-2665//
    const defaultWeekDayProducts = productsInGivenRange.filter((product) => {
      const defaultWeekDay = new Date(selectedRange.startDate).getDay();
      if (product.restrictions.defaultWeekDay || product.restrictions.defaultWeekDay === 0) {
        product.inValidForCurrentColumn = product.inValidForCurrentColumn || defaultWeekDay !== product.restrictions.defaultWeekDay;
        return !product.inValidForCurrentColumn || showInvalidProducts;
      }
      return true;
    });

    // VJ: few products are filtered due to default week day is not matching ,and also its possible that all products has default week day
    // which is not matching and so can be blank
    productsInGivenRange = defaultWeekDayProducts.length > 0 ? defaultWeekDayProducts : [];

    // Products having All Section in Range BRIC//
    productsWithAllSelectionInRange = productsWithCampaignType.filter((product) => {
      return !product.restrictions.startDate && !product.restrictions.endDate;
    });

    // All Products without Restrictions//
    productsWithoutRestrictions = productsWithCampaignType.filter((product) => {
      return (product.restrictions === {});
    });

    filterProducts = _.uniq(_.union(productsWithAllSelectionInRange, productsInGivenRange, productsWithoutRestrictions));

    filterProducts.forEach((product) => {
      let match = true;
      // Adveriser Block //
      if (campaignDetails.advertiser.advertiserId !== 0) {
        if (product.restrictions.advertisers) {
          const restrictionsIndex = product.restrictions.advertisers.findIndex((adv) => {
            return adv.organisationId === parseInt(campaignDetails.advertiser.advertiserId, 10);
          });
          if (restrictionsIndex === -1) {
            match = false;
          }
        } else if ( // If User has selected advertiser, he should not have access products created for Agencies or Specialist//
          product.restrictions.specialists || product.restrictions.agencies
        ) {
          match = false;
        }
      }

      // Agency Block //
      if (campaignDetails.agency && campaignDetails.agency.agencyId !== 0 && (match === false || campaignDetails.advertiser.advertiserId === 0)) {
        // Second Condition in if statement : Go and check for this case if either the Advertiser is blank or does not match advertiser//
        if (product.restrictions.agencies) {
          const restrictionsIndex = product.restrictions.agencies.findIndex((agn) => {
            return agn.organisationId === parseInt(campaignDetails.agency.agencyId, 10);
          });
          if (restrictionsIndex > -1) {
            match = true;
          } else {
            match = false;
          }
        } else if ( // If user has selected Agency, he should not have access to the products created for Advertisers or Specialist //
          product.restrictions.advertisers
          || product.restrictions.specialists
        ) {
          match = false;
        }
      }

      // Specialist Block//
      if (campaignDetails.specialist && campaignDetails.specialist.specialistId !== 0 &&
        (match === false || (campaignDetails.advertiser.advertiserId === 0 && campaignDetails.agency.agencyId === 0))) {
        // Second Condition in if statement : (Advertiser AND Agency are blank) or (Advertiser OR Agency does not match)//
        if (product.restrictions.specialists) {
          const restrictionsIndex = product.restrictions.specialists.findIndex((spl) => {
            return spl.organisationId === parseInt(campaignDetails.specialist.specialistId, 10);
          });
          if (restrictionsIndex > -1) {
            match = true;
          } else {
            match = false;
          }
        } else if // If User has selected specialist, he should not have access products created for Agencies or Advertisers//
          (product.restrictions.agencies
          || product.restrictions.advertisers) {
          match = false;
        }
      }

      /* If nothing is selected by user on the search page, he should not see the products specially created for
       advertisers,agencies and specialists */
      if (
        campaignDetails.advertiser.advertiserId === 0
        && campaignDetails.agency.agencyId === 0
        && campaignDetails.specialist.specialistId === 0
      ) {
        if (product.restrictions.advertisers
          || product.restrictions.agencies
          || product.restrictions.specialists
        ) {
          match = false;
        }
      }
      if (match) {
        products.push(product);
      }
    });
    return products;

  }

  /**
   * @description Check validity for product bric
   * @author Darshan Vachhani
   * @param {Row[]} items
   * @param {Campaign | CampaignDetails} campaignDetails
   * @param {number} inchargeRowIndex
   * @returns
   * @memberof PcmService
   */
  markProductBricInvalid(items: Row[], campaignDetails: Campaign | CampaignDetails, inchargeRowIndex: number, filterInUseProducts: boolean, stateService: StateService) {
    const userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    if (inchargeRowIndex !== -1) {
      items.forEach((row) => {
        if (row.bric.bricid === this.brickBaseService.brickID.ProductCatalogue) {
          row.isValid = true;
          row.cells.forEach((cell, index) => {
            // change for SM-10177 and SM-9313
            const inchargeCell = items[inchargeRowIndex].cells[index];
            let dateRange = {};
            const usedIncharge = items[inchargeRowIndex].getOverlappingCell(index);
            if (usedIncharge > -1) {
              dateRange = items[inchargeRowIndex].cells[usedIncharge].selected;
            } else {
              dateRange = inchargeCell.selected;
            }
            const isPastBooking = moment(dateRange['endDate']).isSameOrBefore(new Date()) ? true : false;
            if (!cell.isEmpty && !cell.isHidden
            && inchargeCell.selected && !_.isEmpty(inchargeCell.selected) && !isPastBooking) {

              const columnHelper: ColumnHelper = stateService.getColumnHelper();
              if (!(columnHelper[index] && columnHelper[index].systemLocked)) {
                const filteredProducts = this.filterProductsBasedOnRestriction(
                  this.dataShareService.getInitialConfig().productCatalogueHolder.productCatalogue,
                  campaignDetails,
                  dateRange,
                  filterInUseProducts,
                  stateService);

                const selectedProduct = filteredProducts.filter(e => e.idProductCatalogue === cell.selected.idProductCatalogue);

                inchargeCell.showWarningIcon = false;
                inchargeCell.warningText = '';
                cell.isValid = false;

                if (selectedProduct.length > 0) {
                  cell.isValid = true;
                  const defaultWeekDay = `workspace.pcm.defaultweekday.${selectedProduct[0]['restrictions'].defaultWeekDay}`;
                  if (selectedProduct[0].inValidForCurrentColumn) {
                    inchargeCell.showWarningIcon = selectedProduct[0].inValidForCurrentColumn;
                    inchargeCell.warningText =
                      `<div>
                  <h5>Product Validations</h5>
                  <div class="marginoffset">
                    ${this.datePipe.transform((new Date(selectedProduct[0]['restrictions'].startDate)), LocaleData.displayDateFormat)}&nbsp;${userBundle['workspace.rating.bric.to']}&nbsp;${this.datePipe.transform((new Date(selectedProduct[0]['restrictions'].endDate)), LocaleData.displayDateFormat)}
                  </div>`;
                    if (selectedProduct[0]['restrictions'].defaultWeekDay || selectedProduct[0]['restrictions'].defaultWeekDay === 0) {
                      inchargeCell.warningText += `<div class="marginoffset" ng -if= "c.validRange.defaultWeekDay" >
                      ${ userBundle['workspace.pcm.incharge.defaultWeekDay']}: ${userBundle[defaultWeekDay]}
                    </div>`;
                    }
                    if (selectedProduct[0]['restrictions'].defaultValue.value && selectedProduct[0]['restrictions'].defaultValue.unit === 0) {
                      inchargeCell.warningText += `<div class="marginoffset">
                    ${ userBundle['workspace.pcm.incharge.bric.default']}: ${selectedProduct[0]['restrictions'].defaultValue.value} ${userBundle['workspace.pcm.incharge.bric.day']}
                  </div>`;
                    }
                    if (selectedProduct[0]['restrictions'].defaultValue.value && selectedProduct[0]['restrictions'].defaultValue.unit !== 0) {
                      inchargeCell.warningText += `<div class="marginoffset">
                      ${ userBundle['workspace.pcm.incharge.bric.default']}: ${selectedProduct[0]['restrictions'].defaultValue.value} ${userBundle['workspace.pcm.incharge.bric.week']}
                  </div>`;
                    }
                    if (selectedProduct[0]['restrictions'].minValue.value > 0 && selectedProduct[0]['restrictions'].minValue.unit === 0) {
                      inchargeCell.warningText += `<div class="marginoffset">
                      ${ userBundle['workspace.pcm.incharge.bric.min']}: ${selectedProduct[0]['restrictions'].minValue.value} ${userBundle['workspace.pcm.incharge.bric.day']}
                  </div>`;
                    }
                    if (selectedProduct[0]['restrictions'].minValue.value > 0 && selectedProduct[0]['restrictions'].minValue.unit !== 0) {
                      inchargeCell.warningText += `<div class="marginoffset">
                      ${ userBundle['workspace.pcm.incharge.bric.min']}: ${selectedProduct[0]['restrictions'].minValue.value} ${userBundle['workspace.pcm.incharge.bric.week']}
                  </div>`;
                    }

                    inchargeCell.warningText += '</div>';
                    inchargeCell.isValid = false;
                  }
                }
              }
            }

            if (!cell.isValid) {
              row.isValid = false;
            }
          });
        }
      });
    }
    return items;
  }

  /**
   * @description returns true if brick is optional in product
   * @author Amit Mahida
   * @param {number} productIndex
   * @param {*} productDetails
   * @param {number} bricId
   * @param {number} cellIndex column index of cell
   * @returns {boolean}
   * @memberof PcmService
   */
  isOptionalInProduct(productIndex: number, productDetails, bricId: number, cellIndex?: number) {
    if (cellIndex === undefined) {
      cellIndex = productIndex;
    }
    const productDetail = productDetails.filter(prod => prod.columnIndex === productIndex);
    if (productDetail.length > 0) {
      if (productDetail[0].optional[cellIndex] && productDetail[0].optional[cellIndex].indexOf(bricId) > -1) {
        return true;
      }
    }
    return false;
  }

  /**
   * @description Updates product validations on removing any existing column
   * @author Amit Mahida
   * @param {number[]} removeColIndexes
   * @param {ProductHelper[]} productDetails
   * @returns
   * @memberof PcmService
   */
  updateProductValidationsOnRemoveColumn(removeColIndexes: number[], productDetails: ProductHelper[]) {
    for (const removeColIndex of removeColIndexes) {
      for (const product of productDetails) {
        if (product) {
          for (let index = Object.keys(product.validation).length - 1; index >= 0; index--) {
            const key = Object.keys(product.validation)[index];
            if (Number(key) > removeColIndex) {
              product.validation[Number(key) - 1] = product.validation[key];
              delete product.validation[key];
            }
          }
          for (let index = Object.keys(product.optional).length - 1; index >= 0; index--) {
            const key = Object.keys(product.optional)[index];
            if (Number(key) > removeColIndex) {
              product.optional[Number(key) - 1] = product.optional[key];
              delete product.optional[key];
            }
          }
        }
      }
    }
    return productDetails;
  }

  /**
   * @description Updates product validations on an explode on a product column
   * @author Amit Mahida
   * @param {number} explodedColIndex
   * @param {ProductHelper[]} productDetails
   * @param {number} noOfColIncreased
   * @returns
   * @memberof PcmService
   */
  updateProductValidationsOnExplode(explodedColIndex: number, productDetails: ProductHelper[], noOfColIncreased: number) {
    for (const product of productDetails) {
      if (product) {
        for (let index = Object.keys(product.validation).length - 1; index >= 0; index--) {
          const key = Object.keys(product.validation)[index];
          if (explodedColIndex >= product.columnIndex && Number(key) > explodedColIndex) {
            product.validation[Number(key) + noOfColIncreased] = product.validation[key];
            delete product.validation[key];
          }
        }
        for (let index = Object.keys(product.optional).length - 1; index >= 0; index--) {
          const key = Object.keys(product.optional)[index];
          if (explodedColIndex >= product.columnIndex && Number(key) > explodedColIndex) {
            product.optional[Number(key) + noOfColIncreased] = product.optional[key];
            delete product.optional[key];
          }
        }
      }
    }
    return productDetails;
  }

  /**
   * @description Check if Product Exists at the Particular Cell Index
   * @author Nishit Parekh
   * @param {Row[]} rows
   * @param {number} cellIndex
   * @param {ProductHelper[]} productDetails
   * @returns
   * @memberof PcmService
   */
  checkIfProductExistsInColumn(rows: Row[], cellIndex: number, productDetails: ProductHelper[]): boolean {
    let productExits = false;
    cellIndex = this.getOverlappingProductColumn(rows, cellIndex);
    if (productDetails && productDetails.length > 0) {
      productDetails.forEach((product: ProductHelper) => {
        Object.keys(product.validation).forEach((column) => {
          if (cellIndex === Number(column)) {
            productExits = true;
          }
        });
      });
    }
    return productExits;
  }
  /**
   * @description
   * @author Nishit Parekh
   * @param {Row[]} rows
   * @param {number} cellIndex
   * @returns {number} cellIndex
   * @memberof PcmService
   */
  getOverlappingProductColumn(rows: Row[], cellIndex: number): number {
    const productRow = rows.filter(row => row.bric.bricid === this.brickBaseService.brickID.ProductCatalogue);
    if (productRow.length) {
      const validationIndex = productRow[0].getOverlappingCell(cellIndex);
      if (validationIndex !== -1) {
        cellIndex = validationIndex;
      }
    }
    return cellIndex;
  }

  /**
   * To get in use products
   * @param productCatalogues
   * @returns productsInUse
   */
  getInUseProduct(productCatalogues) {
    let productsInUse = [];
    // Products in use
    if (productCatalogues) {
      productsInUse = productCatalogues.filter(product => product.inUse);
    }
    return productsInUse;
  }
}
