import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subscriber } from 'rxjs';
import { GLOBAL } from '../utils/app.constant';

@Injectable()
export class UrlHelperService {
  constructor(
    private http: HttpClient,
  ) {
  }

  get(url: string): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      let objectUrl: string = null;

      this.http
        .get(url, {
          headers: this.getHeaders(),
          responseType: 'blob'
        })
        .subscribe((m) => {
          objectUrl = URL.createObjectURL(m);
          observer.next(objectUrl);
        });

      return () => {
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
          objectUrl = null;
        }
      };
    });
  }

  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    if (GLOBAL.ACCESS_TOKEN) {
      headers.set('Authorization', `$Bearer ${GLOBAL.ACCESS_TOKEN}`);
    }

    return headers;
  }
}
