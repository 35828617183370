import { Component, ViewChild, forwardRef, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { TagInputDropdown } from 'ngx-chips';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { of } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

const CUSTOM_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AgTypeAheadObservableCellEditorComponent),
  multi: true
};

@Component({
  selector: 'app-ag-type-ahead-observable-cell-editor',
  templateUrl: './ag-type-ahead-observable-cell-editor.component.html',
  styleUrls: ['./ag-type-ahead-observable-cell-editor.component.css'],
  providers: [CUSTOM_ACCESSOR]
})
export class AgTypeAheadObservableCellEditorComponent implements ICellEditorAngularComp {
  private params: any;
  public value: any;
  public valueText: string;
  public isParent: boolean;
  private cancelBeforeStart = false;
  public valKey = '';
  public textKey = '';
  public placeholder = '';
  public setValueInternally = false;
  public cellDisable = false;

  @ViewChild('input', { read: ViewContainerRef }) public input;
  @ViewChild('dropdown') public dropdownTagComp: TagInputDropdown;

  agInit(params: any): void {
    this.params = params;
    this.value = [];
    this.isParent = this.params.node.data.isParent;
    this.valueText = '';

    this.valKey = this.params.valKey;
    this.textKey = this.params.textKey;
    this.placeholder = this.params.placeholder || 'select an option';
    if (this.params.value) {
      this.valueText = this.params.value;
    }

    // only start edit if key pressed is a number, not a letter
    // this.cancelBeforeStart = this.isParent || (this.params.node.rowPinned == 'bottom' && this.params.rowIndex == 1) ? true : false;
    if (this.cancelBeforeStart === false && this.params.cellEditable) {
      this.cancelBeforeStart = !this.params.cellEditable(this.params);
    }
    if (this.params.cellDisable) {
      this.cellDisable = this.params.cellDisable(this.params);
    }
  }

  getValue(): any {
    return this.params.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
    return false;
  }

  loadAutoCompleteData = (query: string) => {
    // menu gets open every time loading auto complete list
    // so it is registering keydown events multiple time
    // which is creating issue when user press down, up key, as it gets fired multiple times
    if (this.dropdownTagComp.isVisible) {
      this.dropdownTagComp.hide();
    }

    if (this.setValueInternally) {
      this.setValueInternally = false;
      return of([]);
    }
    if (this.cellDisable) {
      return of([]);
    }
    if (this.params.loadAutoCompleteData) {
      return this.params.loadAutoCompleteData(query);
    } else {
      return of([]);
    }
  }

  onItemAdded = (item) => {
    this.value = [item];
    this.valueText = item[this.textKey];
    if (this.params.onItemSelect) {
      this.params.onItemSelect(this.params, item);
    }
  }

  onClearItem() {
    this.value = [];
    this.valueText = '';
    if (this.params.onItemSelect) {
      const item = {};
      item[this.valKey] = null;
      item[this.textKey] = null;
      this.params.onItemSelect(this.params, item);
    }
  }

  onInputLostFocus(inputText) {
    if ((!inputText) || inputText === '') {
      this.setValueInternally = true;
      this.valueText = '';
      if (this.params.value) {
        this.valueText = this.params.value;
      }
    }
  }

  onKeyDown(event) {
    const key = event.which || event.keyCode;
    // Tab key press, Ag-grid moves focus to next cell
    // so need to hide dropdown menu manually
    if (key === 9) { // Tab key
      event.stopPropagation();
      if (this.dropdownTagComp.isVisible) {
        this.dropdownTagComp.hide();
      }
    }

    // prevent Ag-grid left,right key navigation
    // to allow navigation inside text box text
    if (key === 37 // left
      || key === 39 // right
    ) {
      event.stopPropagation();
    }

    // on key up and down, prevent ag-grid cell change event and
    // apply dropdown event to navigate inside menu items
    if ((key === 38 // UP
      || key === 40) // Down
      && (this.dropdownTagComp.isVisible)
    ) {
      event.stopPropagation();
      this.dropdownTagComp.dropdown.menu.handleKeypress(event);
    }
  }
}
