import { Component } from '@angular/core';
import { AgCustomRowUtil } from '../../utils/ag-custom-row-util';
import { CellRendererBaseComponent } from '../cell-renderer-base/cell-renderer-base.component';

@Component({
  selector: 'app-ag-text-cell-renderer',
  templateUrl: './ag-text-cell-renderer.component.html',
  styleUrls: ['./ag-text-cell-renderer.component.css']
})
export class AgTextCellRendererComponent extends CellRendererBaseComponent {
  constructor() {
    super();
  }

  public getParentRowText(): string {
    if (!this.groupingField) {
      return this.params.data[this.params.column.colId];
    }
    const rowData = AgCustomRowUtil.getRowData(this.params.api);
    const childRows = AgCustomRowUtil.getChildRows(rowData, this.groupingField, this.params.data[this.groupingField]);
    let text = '';
    childRows.forEach((row) => {
      const fieldValue = row[this.params.column.colId];
      if (text !== '*') {
        if (!text) {
          text = fieldValue;
        } else if (text !== fieldValue) {
          text = '*';
        }
      }
    });
    text = text || '';
    this.params.data[this.params.column.colId] = text;
    return text;
  }

  public getChildRowText(): string {
    return this.params.data[this.params.column.colId];
  }

  public getFirstPinRowText(): string {
    return this.params.data[this.params.column.colId];
  }

  public getSecondPinRowText(): string {
    return this.params.data[this.params.column.colId];
  }
}
