import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { CampaignDetails, BookingLine } from '../../commercial.model';
import { DataShareService, CommercialService } from '../../../core/services/index';
import { SbSelectedItem, DEFAULT_SELECTED_ITEMS } from '../../../models/sb-selected-item';
import {
  RequestParams,
  defaultBuyerContactParams,
  defaultAdminPersonParams,
  defaultSalesPersonParams,
  defaultctuPersonParams
} from '../../../models/request-params';
import { InitialConfigModel } from '../../../models';
import { LocaleData } from '../../../core/utils/LocaleData';
import { Subscription } from 'rxjs';
import { MaskService } from '../../../core/services/mask.service';
import { NumberMaskModel } from '../../../models/imask';

@Component({
  selector: 'app-booking-detail-denmark',
  templateUrl: './booking-detail-denmark.component.html',
  styleUrls: ['./booking-detail-denmark.component.css']
})
export class BookingDetailsDenmarkComponent implements OnInit, OnDestroy {
  @Input() campaignDetails: CampaignDetails;

  /**
   * @description Used to store row index of Bookings
   * @type {number}
   * @memberof BookingDetailsDenmarkComponent
   */
  @Input() rowIndex: number;

  /**
   * @description Initial Config
   * @type {InitialConfigModel}
   * @memberof BookingDetailsDenmarkComponent
   */
  initialConfig: InitialConfigModel;

  /**
   * @description Used to store row of Bookings
   * @type {BookingLine}
   * @memberof BookingDetailsDenmarkComponent
   */
  booking: BookingLine;

  // Buyer Contact
  buyerContactURL: string;
  buyerContactList: SbSelectedItem[] = [];
  selectedBuyerContact: SbSelectedItem = _.clone(DEFAULT_SELECTED_ITEMS);
  public buyerContactSearchParams: RequestParams = defaultBuyerContactParams;

  // Ctu Person
  ctuPersonURL: string;
  ctuPersonList: SbSelectedItem[] = [];
  selectedCtuPerson: SbSelectedItem = _.clone(DEFAULT_SELECTED_ITEMS);
  public ctuPersonSearchParams: RequestParams = defaultctuPersonParams;

  // Admin Person
  adminPersonURL: string;
  adminPersonList: SbSelectedItem[] = [];
  selectedAdminPerson: SbSelectedItem = _.clone(DEFAULT_SELECTED_ITEMS);
  public adminPersonSearchParams: RequestParams = defaultAdminPersonParams;

  // Sales Person
  salesPersonURL: string;
  salesPersonList: SbSelectedItem[] = [];
  selectedSalesPerson: SbSelectedItem = _.clone(DEFAULT_SELECTED_ITEMS);
  public salesPersonSearchParams: RequestParams = defaultSalesPersonParams;

  productionType: any[] = [];

  /**
   * @description Date format to use to display date
   * @type {string}
   * @memberof BookingDetailsGermanyComponent
   */
  displayDateFormat = '';

  /**
   * @description Data Subscription
   * @type {Subscription}
   * @memberof BookingDetailsDenmarkComponent
   */
  gridDataSubscription: Subscription;

  maskService: MaskService = new MaskService();

  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof BookingDetailsDenmarkComponent
   */
  currencyInputMask: NumberMaskModel;
  /**
   * @description  Mask input config for Percentage
   * @type {NumberMaskModel}
   * @memberof BookingDetailsDenmarkComponent
   */
  percentageInputMask: NumberMaskModel;
  /**
   * @description  Mask input config for media Length
   * @type {NumberMaskModel}
   * @memberof BookingDetailsDenmarkComponent
   */
  mediaLengthInputMask: NumberMaskModel;

  constructor(
    public dataShareService: DataShareService,
    private commercialService: CommercialService
  ) { }

  ngOnInit(): void {
    this.currencyInputMask = this.maskService.currencyInputMask();
    this.percentageInputMask = this.maskService.currencyInputMask({ max: 100 });
    this.mediaLengthInputMask = this.maskService.currencyInputMask({ max: 999 });
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.buyerContactURL = this.dataShareService.getServiceCallUrlByKey('LOOKUP_BUYER_CONTRACT_URL');
    this.adminPersonURL = this.dataShareService.getServiceCallUrlByKey('LOOKUP_ADMIN_PERSON_URL');
    this.salesPersonURL = this.dataShareService.getServiceCallUrlByKey('LOOKUP_SALES_PERSON_URL');
    this.ctuPersonURL = this.dataShareService.getServiceCallUrlByKey('LOOKUP_CTU_PERSON_URL');
    this.productionType = this.dataShareService.getInitialConfigByKey('productionType');
    this.displayDateFormat = LocaleData.displayDateFormat;
    this.booking = this.campaignDetails.bookings[this.rowIndex];
    this.populateBookingDetails();
    this.gridDataSubscription = this.commercialService.gridData$.subscribe(() => {
      this.manageCalculations();
    });
  }

  ngOnDestroy() {
    this.gridDataSubscription.unsubscribe();
  }

  /**
   * @description Populate Booking Details Data
   * @author Shivani Patel
   * @memberof BookingDetailsDenmarkComponent
   */
  populateBookingDetails() {
    this.selectedBuyerContact = this.populateSelected(
      this.booking.buyerContactId,
      this.booking.buyerContactName
    );
    this.selectedCtuPerson = this.populateSelected(
      this.booking.ctuPersonId,
      this.booking.ctuPersonName
    );
    this.selectedSalesPerson = this.populateSelected(
      this.booking.salesPersonId,
      this.booking.salesPersonName
    );
    this.selectedAdminPerson = this.populateSelected(
      this.booking.adminPersonId,
      this.booking.adminPersonName
    );

    this.booking.productionTypeId = this.booking.productionTypeId || -1;
  }

  // Functions related to Admin Persons
  onAdminPersonSelected(value: any) {
    this.booking.adminPersonId = this.selectedAdminPerson.id = value.id;
    this.booking.adminPersonName = this.selectedAdminPerson.text = value.text;
  }
  removeSelectedAdminPerson() {
    this.selectedAdminPerson = _.clone(DEFAULT_SELECTED_ITEMS);
    this.booking.adminPersonId = null;
    this.booking.adminPersonName = null;
  }
  getAdminPersonResponse(value: any): void {
    if (value.adminPerson && value.adminPerson.length) {
      this.adminPersonList = value.adminPerson.map(item => ({
        id: item.idAdminPerson,
        text: item.adminPersonName,
        source: item
      }));
    }
  }

  // Functions related to Sales Persons
  onSalesPersonSelected(value: any) {
    this.booking.salesPersonId = this.selectedSalesPerson.id = value.id;
    this.booking.salesPersonName = this.selectedSalesPerson.text = value.text;
  }
  removeSelectedSalesPerson() {
    this.selectedSalesPerson = _.clone(DEFAULT_SELECTED_ITEMS);
    this.booking.salesPersonId = null;
    this.booking.salesPersonName = null;
  }
  getSalesPersonResponse(value: any): void {
    if (value.salesPerson && value.salesPerson.length) {
      this.salesPersonList = value.salesPerson.map(item => ({
        id: item.idSalesPerson,
        text: item.salesPersonName,
        source: item
      }));
    }
  }

  // Functions related to Buyer Contact
  onbuyerContactSelected(value: any) {
    this.booking.buyerContactId = this.selectedBuyerContact.id = value.id;
    this.booking.buyerContactName = this.selectedBuyerContact.text = value.text;
  }
  removeSelectedbuyerContact() {
    this.selectedBuyerContact = _.clone(DEFAULT_SELECTED_ITEMS);
    this.booking.buyerContactId = null;
    this.booking.buyerContactName = null;
  }
  getBuyerContactResponse(value: any): void {
    if (value.buyerContact && value.buyerContact.length) {
      this.buyerContactList = value.buyerContact.map(item => ({
        id: item.buyerContactId,
        text: item.buyerContactName,
        source: item
      }));
    }
  }

  // Functions related to Ctu Person
  onCtuPersonSelected(value: any) {
    this.booking.ctuPersonId = this.selectedCtuPerson.id = value.id;
    this.booking.ctuPersonName = this.selectedCtuPerson.text = value.text;
  }
  removeSelectedCtuPerson() {
    this.selectedCtuPerson = _.clone(DEFAULT_SELECTED_ITEMS);
    this.booking.ctuPersonId = null;
    this.booking.ctuPersonName = null;
  }
  getCtuPersonResponse(value: any): void {
    if (value.ctuPerson && value.ctuPerson.length) {
      this.ctuPersonList = value.ctuPerson.map(item => ({
        id: item.idCtuPerson,
        text: item.ctuPersonName,
        source: item
      }));
    }
  }

  populateSelected(id: number, text: string) {
    return {
      id,
      text
    };
  }
  populateSelectedWithInvoiceCode(id: number, text: string, invoiceStatusCode: string) {
    return {
      id,
      text,
      invoiceStatusCode
    };
  }

  /**
   * @description Manages calculation for Net Media and notifies the Campaign Details
   * @author Shivani Patel
   * @memberof BookingDetailsDenmarkComponent
   */
  manageCalculations() {
    this.booking.netMediaValue = this.commercialService.calculateNetMedia(this.rowIndex, this.campaignDetails, 'bookingList');
    this.commercialService.notifyCampaignDetails(this.campaignDetails.bookingList);
  }

  trackByProductionType(index, item) {
    return item?.productionTypeId;
  }
}
