import { FurnitureModel, FrameModel } from './MapData.model';
import { editablePeriodId } from '../geo-map/status.enum';
import { VipFrameCountModel } from './vip-process-filter.model';

export class SaveFrameResponseModel {
  campaignSummary: CampaignSummaryModel;
  basketData: FrameModel[];
  filterCounts?: VipFrameCountModel;
  indoorMaps: any[];
  familyMapIcon?: any;
  frameOrientations: any[];
  frameSizeFamilyMapIcons?: any;
}

export interface FamilyMapIcon {
  familyCode: string
  iconPath: string
  orientation: number
  width: number
  height: number
}
export class CampaignSummaryModel {
  campaignDates?: string;
  audienceName?: string;
  audienceImpressions?: string;
  price?: string;
  frames?: string;
  illuminationPercentage?: string;
  illuminatedFrames?: number;
}

export class BasketDataModel {
  startDate: Date;
  endDate: Date;
  editablePeriodId: editablePeriodId;
  assetData: FurnitureModel[];
}
