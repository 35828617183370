import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { BrickBaseService } from './../core/services/brick-base.service';
import { CellAttributeService } from './../core/services/cell-attributes.service';
import * as _ from 'lodash';
import { RequestJsonGenerator } from '../core/utils/request-json-generator';

@Injectable()
export class RequestJsonService {

  /**
   * Request json data object
   * @private
   * @type {*}@memberof RequestJsonService
   */
  public requestJsonData: any[] = [];

  /**
   * Request json data source observable subject
   * @private
   * @memberof RequestJsonService
   */
  private requestJsonSource: Subject<any[]> = new Subject();

  /**
   * Request json data observable object
   * @memberof RequestJsonService
   */
  public requestJson$: Observable<any[]> = this.requestJsonSource.asObservable();

  requestJsonGenerator: RequestJsonGenerator;
  /**
   *
   * @param brickBaseService Service for brick-base data.
   */
  constructor(private brickBaseService: BrickBaseService,
    private cellAttributeService: CellAttributeService) {
  }

  resetRequestJsonData(): void {
    this.requestJsonData = [];
  }

  /**
   * Request json data change method
   * @memberof RequestJsonService
   */
  requestJsonDataChange(): void {
    this.setRowInRequestJson();
    this.requestJsonSource.next(this.requestJsonData);
  }

  private setRowInRequestJson(): void {
    /* tslint:disable:prefer-for-of */
    for (let i = 0; i < this.requestJsonData.length; i++) {
      this.requestJsonData[i].row = i;
      this.requestJsonData[i].columns = [0];
    }
  }

  /**
   * Prepare request json data for audience bric
   * @param {any} audienceBricSelectedData - selected audience bric data
   * @memberof RequestJsonService
   */
  public prepareAudienceBricReqJson(audienceBricSelectedData: any): void {
    const bricText = this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Audience];
    this.removeFromRequestJsonByKey(bricText);
    if (audienceBricSelectedData && audienceBricSelectedData.audienceCategoryId) {
      this.prepareBrickRequestJson(this.brickBaseService.brickID.Audience, audienceBricSelectedData);
    }
    this.requestJsonDataChange();
  }

  public prepareBrickRequestJson(brickID, selectedData) {
    const bricText = this.brickBaseService.brickReqJsonText[brickID];
    this.removeFromRequestJsonByKey(bricText);
    if (!_.isEmpty(selectedData)) {
      const result = this.cellAttributeService.getBrickRequestJSON(brickID, selectedData);
      this.requestJsonData.push(result);
    }
    this.requestJsonDataChange();
  }

  private removeFromRequestJsonByKey(key: string): void {
    let index = -1;
    /* tslint:disable:prefer-for-of */
    for (const requestJsonData of this.requestJsonData) {
      if (this.requestJsonData[this.requestJsonData.indexOf(requestJsonData)][key]) {
        index = this.requestJsonData.indexOf(requestJsonData);
        break;
      }
    }
    if (index > -1) {
      this.requestJsonData.splice(index, 1);
    }
  }
}
