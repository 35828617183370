import { Directive, HostListener, Input } from '@angular/core';

/**
 * @description It prevents double click on an element
 * @author Amit Mahida
 * @export
 * @class NoDoubleClickDirective
 */
@Directive({
  selector: '[appNoDoubleClick]'
})

export class NoDoubleClickDirective {

  /**
   * @description click will be enabled after n milliseconds given in this Input
   * @memberof NoDoubleClickDirective
   */
  @Input() clickTimeout = 600;

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.srcElement.setAttribute('disabled', true);
    setTimeout(() => {
      event.srcElement.removeAttribute('disabled');
    }, this.clickTimeout);
  }
}
