import { Directive, Input, Output, EventEmitter, OnChanges, HostListener, HostBinding } from '@angular/core';

@Directive({
  // host: {
  //   '(change)': 'triggerOnChange($event)',
  //   '[checked]': 'isChecked',
  // },
  selector: '[appChecklist]'
})
export class ChecklistDirective implements OnChanges {

  constructor() { }

  @Input() checklist: any[] = [];
  @Input() checklistValue: any;
  @Output() checklistChange = new EventEmitter<any[]>();
  @HostBinding('checked') isChecked: boolean;


  contains(obj: object) {
    return this.checklist.some(val => JSON.stringify(obj) === JSON.stringify(val));
  }

  getIndex(obj: object): number {
    let index = -1;
    this.checklist.forEach((val: any, key: any) => {
      if (JSON.stringify(obj) === JSON.stringify(val)) {
        index = key;
      }
    });
    return index;
  }

  ngOnChanges() {
    if (this.checklist) {
      if (typeof this.checklistValue === 'object') {
        this.isChecked = this.contains(this.checklistValue);
      } else {
        this.isChecked = this.checklist.indexOf(this.checklistValue) >= 0;
      }
    }
  }

  @HostListener('change', ['$event'])
  triggerOnChange($event: Event) {
    const target = $event.target as HTMLInputElement;
    let updatedList;
    let i: any = null;

    if (target && target.checked) {
      if (typeof this.checklistValue === 'object') {
        this.checklist.push(this.checklistValue);
        updatedList = this.checklist;
      } else {
        updatedList = [...this.checklist, this.checklistValue];
      }
    } else {
      if (typeof this.checklistValue === 'object') {
        i = this.getIndex(this.checklistValue);
        this.checklist.splice(i, 1);
        updatedList = this.checklist;
      } else {
        i = this.checklist.indexOf(this.checklistValue);
        updatedList = [...this.checklist.slice(0, i), ...this.checklist.slice(i + 1)];
      }
    }

    this.checklistChange.emit(updatedList);
  }

}
