<div class="contentarea">
  <dl class="accordion animated bounceInDown content">
    <dt (click)="hideCampaignDetails = !hideCampaignDetails">
      <span class="editable-title">
        <span class="text">{{initialConfig.userBundle['commercial.tabTitle.campaignDetails']}}
          &nbsp;-&nbsp;
        </span>
        <span class="text allow-copy"> {{commercialData?.campaignReference}}
        </span>
      </span>
      <a href="javascript:void(0)" [ngClass]="!hideCampaignDetails?'down':''" class="title">
        <span class="right">
          <span class="text">{{commercialData?.bookingStatusName}}</span>
          <span class="icon_wrapper">
            <span class="icons animated"></span>
          </span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'readOnly' : systemFlags.readOnly, 'down' : !hideCampaignDetails}">
      <app-campaign-details-sg *ngIf="commercialData" [historyROC]="historyROC" [commercialConfig]="commercialConfig"
        [campaignDetails]="campaignDetails" (manuallyCPTUpdated)="manuallyCPTUpdated()">
      </app-campaign-details-sg>
    </dd>
  </dl>

  <dl class="accordion animated bounceInDown content">
    <dt (click)="hideBookingDetails = !hideBookingDetails">
      <span class="editable-title">
        <span class="text">{{initialConfig.userBundle['commercial.tabTitle.bookingDetails']}}</span>
      </span>
      <a href="javascript:void(0)" [ngClass]="!hideBookingDetails?'down':''" class="title">
        <span class="right">
          <span class="icon_wrapper">
            <span class="icons animated"></span>
          </span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'down' : !hideBookingDetails}">
      <app-booking-grid-uk *ngIf="commercialData" [commercialConfig]="commercialConfig"
        [bookingList]="commercialData.bookingList"></app-booking-grid-uk>
    </dd>
  </dl>

  <dl class="accordion animated bounceInDown content">
    <dt (click)="hideDiscountGrid = !hideDiscountGrid">
      <a href="javascript:void(0)" [ngClass]="!hideDiscountGrid?'down':''" class="title">
        <span class="text"> {{initialConfig.userBundle['commercial.tabTitle.discountGrid']}}</span>
        <span class="icon_wrapper">
          <span class="icons animated"></span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'down' : !hideDiscountGrid}">
      <app-discount-grid-singapore [commercialData]="commercialData"
        *ngIf="commercialData && commercialData.bookingDetails && commercialData.bookingDetails.length > 0"
        [config]="commercialData">
      </app-discount-grid-singapore>
    </dd>
  </dl>

  <app-commercial-buttons *ngIf="commercialData" [commercialConfig]="commercialConfig"
    [campaignDetails]="campaignDetails" [validateFn]="validateCommercialCampaign"
    [populateRequestJson]="createRequestJSONForCommercial" (bookCampaign)="onBookCampaign($event)">
  </app-commercial-buttons>

</div>
