/* tslint:disable:no-string-literal */
import {
  Component, OnInit, OnChanges, Input, EventEmitter, Output,
  HostListener, SimpleChange, OnDestroy, ElementRef
} from '@angular/core';
import { ConcertinaDataService } from '../concertina-data.service';
import { ConcertinaCanvasService } from '../concertina-canvas.service';
import { FrameFilterType, FramePeriodType } from '../../../../geo-map/status.enum';
import { Subscription } from 'rxjs';
import { VipFrameCountModel, SwapData } from '../../../../models';
import { FilterDataService } from '../../../services/filter-data.service';
import { CampaignService } from '../../../services/campaign.service';
import { CartService } from '../../../../geo-map/cart.service';
import { SwapZoneService } from '../../swap-zone';
import { GLOBAL } from '../../../utils/app.constant';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from '../../../../geo-map/confirmation/confirmation.component';
import { DataShareService } from '../../../services';
import { AppNameEnum } from '../../../enum';
import * as _ from 'lodash';

declare let saveAs; // FileSaver.js
declare const XLSX: any;

/**
 * @description c & r resprents column & row respectively
 * s = First cell in the range
 * e = Last cell in the range
 * @author Amit Mahida
 * @class Range
 */
class Range {
  s: { c: number, r: number };
  e: { c: number, r: number };
}

/**
 * @description Cell in XLSX
 * v: any raw value
 * color: background color
 * textColour: text color
 * t: data type of cell value;
 * @author Amit Mahida
 * @class SheetCell
 */
class SheetCell {
  v: any;
  color: string;
  textColour: string;
  t: string;
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit, OnChanges, OnDestroy {
  screen = window.screen;
  showAudienceDropdown = false;
  openSortingCalled = false;

  /**
   * ngbModal options
   * @type {NgbModalOptions}@memberof GeoMapComponent
   */
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'sm'
  };
  public frameFilterType = FrameFilterType;
  public framePeriodType = FramePeriodType;

  private framesCountSub: Subscription;

  isViewCartOnPlanner = false;
  showNavigation = false;

  framesCount: VipFrameCountModel = {
    total: 0,
    available: 0,
    selected: 0
  };

  // tslint:disable-next-line:variable-name
  private _isCartVisible: boolean;

  /**
   * track last tab container width
   * used in checkDisplaySliding, to set slide position
   */
  prevContainerWidth = 0;

  /**
   * Array of tabs data
   *
   * @type {number[]}
   * @memberof TabsComponent
   */
  @Input() tabs: number[] = [];

  @Input() selectedFrameFilter;

  @Input() selectedFramePeriodFilter;

  @Input() frameStatusFiltersEnabled;

  @Input() initialConfig;

  @Input() audienceSelectionEnabled = false;

  private privateSelectedAudienceList = [];

  downloadConcertinaInBlackWhite: boolean = false;

  /**
   * @description sets selected audience
   * @memberof TabsComponent
   */
  @Input() public set selectedAudienceList(newList: any[]) {
    this.privateSelectedAudienceList = newList;
    if (newList.length > 0) {
      this.concertinaCanvasService.selectedAudienceCategory = this.selectedAudienceList[0].audienceCategoryId;
    }
  }

  public get selectedAudienceList(): any[] {
    return this.privateSelectedAudienceList;
  }

  @Output() isCartVisibleChange: EventEmitter<boolean> = new EventEmitter();

  @Output() redirectToResultPage: EventEmitter<boolean> = new EventEmitter();

  @Input() public set isCartVisible(value: boolean) {
    this._isCartVisible = value;
    this.isCartVisibleChange.emit(value);
  }

  public get isCartVisible(): boolean {
    return this._isCartVisible;
  }

  /**
   * UserBundle object data
   *
   * @type {object}
   * @memberof TabsComponent
   */
  @Input() userBundle: object = {};
  /**
   * Sortable itesm array
   *
   * @type {object[]}
   * @memberof TabsComponent
   */
  @Input() filters: object[] = [];

  /**
   * Output trigger event on tab changes
   *
   * @memberof TabsComponent
   */
  @Output() tabChanged = new EventEmitter<object>();

  @Output() groupingChange = new EventEmitter<any[]>();

  @Output() statusFilterChanged = new EventEmitter<number>();

  @Output() framePeriodTypeChanged = new EventEmitter<any>();

  /**
   * Selected tab index
   *
   * @memberof TabsComponent
   */
  selectedTabIndex = GLOBAL.DEFAULT_CONCERTINA_SELECTED_TAB;

  selectionMode = false;

  /**
   * Defines flg for concertina sortable
   * @memberof ResultDetailsComponent
   */
  concertinaSortableFlag = false;

  private filterAreaSub: Subscription;
  /**
   * basket Mode subscription object
   */
  basketModeSubscriber: Subscription;
  readonly SORT_BY_POPUP = '.sort_by_popup';
  readonly SWAP_MESSAGE = 'vp.swap.confirm.message';
  readonly CONFIRMATION_LABEL = 'vp.swap.confirmation.label';

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.openSortingCalled) {
      this.openSortingCalled = false;
      event.preventDefault();
      return;
    }
    const elem: any = document.querySelector(this.SORT_BY_POPUP);
    if (!this.eRef.nativeElement.contains(event.target)) {
      // clicked outside
      if (elem) {
        this.slideUp(elem);
      }
      if (this.concertinaSortableFlag) {
        this.groupingChange.emit(this.filters);
        this.concertinaSortableFlag = false;
      }
    }
  }

  /**
   * Creates an instance of TabsComponent.
   * @memberof TabsComponent
   */
  constructor(
    private concertinaDataService: ConcertinaDataService,
    public concertinaCanvasService: ConcertinaCanvasService,
    public cartService: CartService,
    private campaignService: CampaignService,
    private filterDataService: FilterDataService,
    private swapZoneService: SwapZoneService,
    private modalService: NgbModal,
    private dataShareService: DataShareService,
    private eRef: ElementRef
  ) {
    this.framesCountSub = this.concertinaDataService.$framesCount.subscribe((updatedCount) => {
      if (updatedCount) {
        this.framesCount = updatedCount;
      }
    });
  }

  ngOnInit() {
    this.selectedTabIndex = 0;
    this.concertinaCanvasService.tabValue = this.tabs[0];
    this.downloadConcertinaInBlackWhite = this.initialConfig.uiControl.downloadConcertinaInBlackWhite;
    this.basketModeSubscriber = this.concertinaDataService.basketMode$.subscribe((val: boolean) => {
      this.isViewCartOnPlanner = val;
    });
    this.filterAreaSub = this.concertinaCanvasService.$filterAreaSliderSub.subscribe(() => {
      setTimeout(() => {
        this.checkDisplaySliding();
      }, 500);
    });
    this.checkDisplaySliding();
  }

  /**
   * Angular lifecycle hook - will be Called after any input property changes
   */
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add 'implements OnChanges' to the class.
    if (changes.tabs && (changes.tabs.currentValue !== changes.tabs.previousValue)) {
      this.selectedTabIndex = GLOBAL.DEFAULT_CONCERTINA_SELECTED_TAB;
    }
  }

  /**
   * To select concertina tab
   *
   * @param {any} index
   * @param {any} tab
   * @memberof TabsComponent
   */
  selectConcertinaTab(index: number, tab: number) {
    if (this.swapZoneService.swapMode && tab !== GLOBAL.vpSwappingTabId
      && (this.swapZoneService.swaps.length > 0 || Object.keys(this.concertinaCanvasService.multiSelectionForSwap).length)) {
      this.showAudienceDropdown = false;
      const modalRef = this.modalService.open(ConfirmationComponent, this.ngbModalOptions);
      modalRef.componentInstance.resolveObject = {
        content: this.initialConfig.userBundle[this.SWAP_MESSAGE]
          ? this.initialConfig.userBundle[this.SWAP_MESSAGE] : 'If the swap mode is turned off, the work would be lost!',
        title: this.initialConfig.userBundle[this.CONFIRMATION_LABEL]
          ? this.initialConfig.userBundle[this.CONFIRMATION_LABEL] : 'Swap Confiramtion',
        ok: this.initialConfig.userBundle['common.ok']
      };
      modalRef.result.then(() => {
        this.swapZoneService.swapMode = false;
        this.concertinaCanvasService.readOnlyForSwap = false;
        this.selectedTabIndex = index;
        this.tabChanged.emit({ index, tab, resetSwaps: true });
      }, () => {
        // do nothing
      }).catch(() => {
        // nothing to be done on close
      });
    } else {
      if (tab === GLOBAL.indexTabId) {
        this.showAudienceDropdown = true;
      } else {
        this.showAudienceDropdown = false;
      }
      this.selectedTabIndex = index;
      this.concertinaCanvasService.selectedTabIndex = index;
      this.concertinaCanvasService.tabValue = tab;
      this.tabChanged.emit({ index, tab });
    }
  }

  styleCell(cell) {
    let argbColor = null;
    let argbTextColor = null;
    const borderColor = this.downloadConcertinaInBlackWhite ? '000000' : '00FFFFFF';
    if (cell.color) {
      argbColor = cell.color.replace('#', 'FF');
    }
    if (cell.textColour) {
      argbTextColor = cell.textColour.replace('#', 'FF');
    }
    cell.s = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: argbColor }
      },
      font: {
        color: { rgb: argbTextColor },
        bold: true,
        sz: '11'
      },
      border: {
        top: { style: 'thin', color: { rgb: borderColor } },
        left: { style: 'thin', color: { rgb: borderColor } },
        bottom: { style: 'thin', color: { rgb: borderColor } },
        right: { style: 'thin', color: { rgb: borderColor } }
      },
      alignment: {
        horizontal: 'center'
      }
    };
  }

  /**
   * @description sets range of cells in terms of row/column in export
   * @author Amit Mahida
   * @param {Range} range
   * @param {number} R
   * @param {number} C
   * @memberof TabsComponent
   */
  setRange(range: Range, R: number, C: number) {
    range.s.r = range.s.r > R ? R : range.s.r;
    range.s.c = range.s.c > C ? C : range.s.c;
    range.e.r = range.e.r < R ? R : range.e.r;
    range.e.c = range.e.c < C ? C : range.e.c;
  }

  getSheetData(data) {
    const ws = {};
    const range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
    for (let R = 0; R !== data.length; ++R) {
      for (let C = 0; C !== data[R].length; ++C) {
        this.setRange(range, R, C);
        const cell = {
          v: data[R][C].text,
          color: this.downloadConcertinaInBlackWhite ? 'ffffff' : data[R][C].color,
          textColour: this.downloadConcertinaInBlackWhite ? '000000' : data[R][C].textColour,
          t: 's'
        };
        if (cell.v === null) { continue; }
        const cellRef = XLSX.utils.encode_cell({ c: C, r: R });
        switch (typeof cell.v) {
          case 'number':
            cell.t = 'n';
            break;
          case 'boolean':
            cell.t = 'b';
            break;
          case 'object':
            cell.t = 's';
            cell.v = cell.v.join(',');
            break;
          default:
            cell.t = 's';
            break;
        }
        this.styleCell(cell);
        ws[cellRef] = cell;
      }
    }
    if (range.s.c < 10000000) { ws['!ref'] = XLSX.utils.encode_range(range); }
    return ws;
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      // tslint:disable-next-line:no-bitwise
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }

  /**
   * Export to excel concertina
   *
   * @param {any} event
   * @memberof TabsComponent
   */
  exportConcertina(event) {
    if (!_.isUndefined(this.concertinaDataService.concertinaData)) {
      event.stopPropagation();
      let items = [];
      if (this.dataShareService.appName === AppNameEnum.visualplanner) {
        items = this.concertinaDataService.getConcertinaExcelData(this.selectedTabIndex, this.concertinaDataService.concertinaData,
          this.concertinaCanvasService.showFI, this.showAudienceDropdown, this.concertinaCanvasService.selectedAudienceCategory);
      } else {
        items = this.concertinaDataService.getConcertinaExcelData(this.selectedTabIndex, this.concertinaDataService.concertinaData,
          this.concertinaCanvasService.showFI, this.showAudienceDropdown, this.concertinaCanvasService.selectedAudienceCategory, this.concertinaCanvasService.tabValue === GLOBAL.concertinaNotSelectedTab);
      }
      const wsName = GLOBAL.CONCERTINA_EXCEL_FILE_NAME;
      const wb = new Workbook();
      const ws = this.getSheetData(items);

      wb.SheetNames.push(wsName);
      wb.Sheets[wsName] = ws;
      const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
      saveAs(new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }), 'ConcertinaGrid.xlsx');
    }
  }
  /**
   * To notify sortable flag change event
   *
   * @param {boolean} sortableFlag Sortable flag
   * @memberof TabsComponent
   */
  notifySortableFlagChange(sortableFlag: boolean) {
    this.concertinaSortableFlag = sortableFlag;
  }

  openSorting() {
    const elem: any = document.querySelector(this.SORT_BY_POPUP);
    this.openSortingCalled = true;
    if (elem.style.height === '300px') {
      this.slideUp(elem);
      if (this.concertinaSortableFlag) {
        this.groupingChange.emit(this.filters);
        this.concertinaSortableFlag = false;
      }
    } else {
      this.slideDown(elem);
    }
  }

  slideUp(elem) {
    elem.style.transition = 'all 0.5s ease-in-out';
    elem.style.height = '0px';
  }

  slideDown(elem) {
    elem.style.transition = 'all 0.5s ease-in-out';
    elem.style.height = '300px';
  }

  onFrameStatusFilterChange() {
    this.statusFilterChanged.emit(this.selectedFrameFilter);
  }

  onFramePeriodTypeChange() {
    this.framePeriodTypeChanged.emit(this.selectedFramePeriodFilter);
  }

  toggleBasketMode() {

    if (this.swapZoneService.swapMode && this.swapZoneService.swaps.length > 0) {
      const modalRef = this.modalService.open(ConfirmationComponent, this.ngbModalOptions);
      modalRef.componentInstance.resolveObject = {
        content: this.initialConfig.userBundle[this.SWAP_MESSAGE]
          ? this.initialConfig.userBundle[this.SWAP_MESSAGE] : 'If the swap mode is turned off, the work would be lost!',
        title: this.initialConfig.userBundle[this.CONFIRMATION_LABEL]
          ? this.initialConfig.userBundle[this.CONFIRMATION_LABEL] : 'Swap Confiramtion',
        ok: this.initialConfig.userBundle['common.ok']
      };
      modalRef.result.then(() => {
        this.swapZoneService.swapMode = false;
        this.concertinaCanvasService.readOnlyForSwap = false;
        this.swapZoneService.setSwapData(new SwapData());
        this.isViewCartOnPlanner = !this.isViewCartOnPlanner;
        if (this.isViewCartOnPlanner) {
          this.filterDataService.setBackupFilterData();
          this.campaignService.loadCartOnPlanner();
        } else {
          this.filterDataService.restoreFilterData();
        }
      }, () => {
        // do nothing
      }).catch(() => {
        // nothing to be done on close
      });
    } else {
      this.isViewCartOnPlanner = !this.isViewCartOnPlanner;
      if (this.isViewCartOnPlanner) {
        this.filterDataService.setBackupFilterData();
        this.concertinaDataService.setBasketMode(true);
        this.campaignService.loadCartOnPlanner();
      } else {
        this.concertinaDataService.setBasketMode(false);
        this.filterDataService.restoreFilterData();
      }
    }
  }

  ngOnDestroy() {
    this.framesCountSub.unsubscribe();
    this.filterAreaSub.unsubscribe();
    this.basketModeSubscriber.unsubscribe();
  }

  /**
   * @description sets left position of container
   * @author Amit Mahida
   * @param {number} leftPos
   * @param {number} containerWidth
   * @param {number} tabWidth
   * @memberof TabsComponent
   */
  setLeftPosition(leftPos: number, containerWidth: number, tabWidth: number) {
    if (this.prevContainerWidth !== 0 && leftPos !== 0) {
      if (containerWidth > this.prevContainerWidth) { // when increased window width
        leftPos = leftPos + (containerWidth - this.prevContainerWidth);
      } else { // when decreased window width
        leftPos = leftPos - (this.prevContainerWidth - containerWidth);
      }
      // left position become higher than right side postion
      if ((containerWidth - leftPos) > tabWidth) {
        leftPos = containerWidth - tabWidth;
      }
      // max left position should be 0
      if (leftPos > 0) {
        leftPos = 0;
      }
      document.getElementsByClassName('tab-list')[0]['style']['left'] = `${leftPos}px`;
    }
  }

  checkDisplaySliding() {
    const oldLeftSlidePos = document.getElementsByClassName('tab-list')[0]['style']['left'] || '0px';
    document.getElementsByClassName('tab-list')[0]['style']['left'] = '0px';
    const tabWidth = document.getElementsByClassName('tab-list')[0].clientWidth;
    const containerWidth = document.getElementById('viewContainer').clientWidth;
    if (tabWidth < containerWidth) {
      this.showNavigation = false;
      document.getElementById('viewContainer')['style']['left'] = '0';
    } else {
      this.showNavigation = true;
      document.getElementById('viewContainer')['style']['left'] = '22px';
      // SM-3460 - on window resize keep the last slide position
      const leftPos = parseInt(oldLeftSlidePos, 10);
      this.setLeftPosition(leftPos, containerWidth, tabWidth);
    }
    this.prevContainerWidth = containerWidth;
  }

  onResize() {
    this.checkDisplaySliding();
  }

  //#region - Tab navigation
  tabSlideLeft() {
    const move = 150;
    let leftPos = document.getElementsByClassName('tab-list')[0]['style']['left'] || 0;
    leftPos = parseInt(leftPos, 10);
    if (leftPos !== 0) {
      if ((leftPos + move) > 0) {
        leftPos = 0;
      } else {
        leftPos = leftPos + move;
      }
      document.getElementsByClassName('tab-list')[0]['style']['left'] = `${leftPos}px`;
    }
  }

  tabSlideRight() {
    const move = 150;
    let leftPos = document.getElementsByClassName('tab-list')[0]['style']['left'] || 0;
    const tabWidth = document.getElementsByClassName('tab-list')[0].clientWidth;
    const containerWidth = document.getElementById('viewContainer').clientWidth;

    leftPos = parseInt(leftPos, 10);
    if ((containerWidth - leftPos) < tabWidth) {
      if ((containerWidth - leftPos + move) > tabWidth) {
        leftPos = containerWidth - tabWidth;
      } else {
        leftPos = leftPos - move;
      }
      document.getElementsByClassName('tab-list')[0]['style']['left'] = `${leftPos}px`;
    }
  }
  //#endregion - Tab navigation

  audienceCategoryChange(newCategory: string | number) {
    this.concertinaCanvasService.selectedAudienceCategory = Number(newCategory);
    this.selectConcertinaTab(this.concertinaCanvasService.selectedTabIndex, this.concertinaCanvasService.tabValue);
  }

  /**
   * @description OutPutEvent of Concertina Sortable Component to notify that it is loaded
   * @author Shreni Shah
   * @date 2020-05-14
   * @memberof TabsComponent
   */
  notifyIsComponentLoaded() {
    const elem: any = document.querySelector(this.SORT_BY_POPUP);
    if (elem) {
      this.slideUp(elem);
    }
  }

  trackByAudienceCategory(index, category) {
    return category?.audienceCategoryId;
  }

  trackByItem(index, item) {
    return item;
  }

}

export class Workbook {
  // tslint:disable-next-line:variable-name
  SheetNames: any;
  // tslint:disable-next-line:variable-name
  Sheets: any;
  constructor() {
    if (!(this instanceof Workbook)) { return new Workbook(); }
    this.SheetNames = [];
    this.Sheets = {};
  }

}
