import { BrickBaseService } from './../../core/services/brick-base.service';
import { StateService } from './../../core/services/state.service';
import { DataShareService } from './../../core/services/data-share.service';
import { LogHelperService } from './../../core/services/log-helper.service';
import { WorkspaceService } from './../../core/services/workspace.service';
import { Row } from './row';
import { ColumnHelper } from './column-helper';
import { Filter } from './filters';
import { ColumnConfigs } from '../../models/MapData.model';
import * as _ from 'lodash';

export class ColumnLock {
  userBundle: object;
  brickBaseService: BrickBaseService = new BrickBaseService();

  constructor(
    private stateService: StateService,
    private dataShareService: DataShareService,
    private logHelperService: LogHelperService,
    private workspaceService: WorkspaceService
  ) {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
  }

  /**
   * @description locks a column
   * @author Amit Mahida
   * @param {number} colIndex
   * @param {boolean} lock
   * @param {Row[]} rows
   * @param {ColumnHelper} columnHelper
   * @memberof ColumnLock
   */
  // tslint:disable-next-line:cognitive-complexity // This methods complexity was 50 reduced it to 39.//
  public lockColumn(colIndex: number, columnHelper: ColumnHelper, rows: Row[]) {
    const lock = Boolean(columnHelper[colIndex]);
    rows.forEach(row => {
      if (row.cells[colIndex].cellWidth === 1) {
        row.cells[colIndex].isLocked = lock;
      }
      if (row.cells[colIndex].cellWidth > 1) {
        const length = (row.cells[colIndex].cellIndex + row.cells[colIndex].cellWidth - 1);
        let updateLock = true;
        for (let i = length; i >= row.cells[colIndex].cellIndex; i--) {
          if (lock) {
            row.cells[i].isLocked = lock;
          } else if (Object.keys(columnHelper).indexOf(String(i)) > -1) {
            updateLock = false;
          }
        }
        if (!lock && updateLock) {
          for (let i = length; i >= row.cells[colIndex].cellIndex; i--) {
            row.cells[i].isLocked = lock;
          }
        }
      }
      if (row.getOverlappingCell(colIndex) !== -1 && row.getOverlappingCell(colIndex) !== colIndex) {
        const overlappedCellIndex = row.getOverlappingCell(colIndex);
        const length = overlappedCellIndex + row.cells[overlappedCellIndex].cellWidth;
        let updateLock = true;

        for (let i = overlappedCellIndex; i < length; i++) {
          if (lock) {
            row.cells[i].isLocked = lock;
          } else if (Object.keys(columnHelper).indexOf(String(i)) > -1) {
            updateLock = false;
          }
        }
        if (!lock && updateLock) {
          for (let i = overlappedCellIndex; i < length; i++) {
            row.cells[i].isLocked = lock;
          }
        }
      }
    });
  }

  /**
   * @description toggles all column lock
   * @author Amit Mahida
   * @param {boolean} lock
   * @memberof ColumnLock
   */
  public toggleAllColumnsLock(lock: boolean, rows: Row[]) {
    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
    const maxColumn = rows.length && rows[0].cells.length ? rows[0].cells.length : 0;
    for (let colIndex = 0; colIndex < maxColumn; colIndex++) {
      if (!(columnHelper && columnHelper[colIndex] && columnHelper[colIndex].systemLocked)) {
        // Break all the stretched columns
        if (lock) {
          this.breakLockedColumn(colIndex, rows);
        }

        // After breaking the column set isLocked flag of all the cells
        rows.forEach((row: Row) => {
          row.cells[colIndex].isLocked = lock;
        });

        if (lock && !columnHelper[colIndex]) {
          columnHelper[colIndex] = {
            systemLocked: false,
            userLocked: true,
            shown: true,
            allocationEngine: ''
          };
        } else if (!lock && columnHelper && columnHelper[colIndex] && !columnHelper[colIndex].systemLocked) {
          delete columnHelper[colIndex];
        }
      }
    }
  }

  /**
   * @description Check if all columns locked by lockButtons
   * @author Amit Mahida
   * @param {Filter} filter
   * @returns {boolean}
   * @memberof ColumnLock
   */
  isAllColumnsLocked(filter: Filter): boolean {
    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
    return (filter.getMaxCellIndex() + 1) === Object.keys(columnHelper).length;
  }

  /**
   * @description updates state of frozen columns
   * @author Amit Mahida
   * @param {boolean[]} lockButtons
   * @memberof ColumnLock
   */
  updateWorkspaceOnLock(filter: Filter) {
    const bricChangeUrl = this.dataShareService.getServiceCallUrlByKey('BRIC_CHANGE_URL');
    const campaignUrl = this.dataShareService.getServiceCallUrlByKey('CAMPAIGN_URL');
    const serviceURL = bricChangeUrl ? bricChangeUrl : campaignUrl;

    this.workspaceService.updateWorkspaceOnLock(filter,
      serviceURL, this.stateService.getWorkspaceObject('columnLineNumber')).subscribe((data) => {

        if (data.status === 'OK') {
          const columnConfig: ColumnConfigs = this.stateService.getWorkspaceObject('columnConfig');
          filter = this.workspaceService.validateBricksAgainstColumnConfig(filter, columnConfig);
        } else if (data.status === 'KO') {
          this.logHelperService.logError(this.userBundle['workspace.error.lockcolumn.unsuccessful']);
        }
      }, (error) => {
        console.error(error);
        this.logHelperService.logError(this.userBundle['workspace.error.lockcolumn.unsuccessful']);
      });
  }

  /**
   * @description breaks stretch brick on the column locked
   * @author Amit Mahida, Shivani Patel
   * @param {number} colIndex
   * @param {Row[]} rows
   * @memberof ColumnLock
   */
  breakLockedColumn(colIndex: number, rows: Row[]) {
    for (const row of rows) {
      if (this.brickBaseService.preventLockBreak.indexOf(row.bric.bricid) === -1) {
        let overlappingCellIndex = row.getOverlappingCell(colIndex);
        overlappingCellIndex = overlappingCellIndex !== -1 ? overlappingCellIndex : colIndex;
        if (row.cells[overlappingCellIndex].cellWidth > 1) {
          const clonedCell = _.cloneDeep(row.cells[overlappingCellIndex]);
          clonedCell.cellWidth = 1;
          for (let index = overlappingCellIndex + row.cells[overlappingCellIndex].cellWidth - 1; index >= overlappingCellIndex; index--) {
            clonedCell.cellIndex = index;
            row.cells[index].updateCellValues(clonedCell);
          }
        }
      }
    }
  }
}
