import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SearchComponent } from '../../geoplanner/src/app/search/search.component';
import { WorkspaceComponent } from '../../geoplanner/src/app/workspace/workspace.component';
import { PcmComponent } from '../../geoplanner/src/app/workspace/pcm/pcm.component';
import { AppComponent } from '../../geoplanner/src/app/app.component';
import { VisualPlannerComponent } from '../../geoplanner/src/app/visual-planner/visual-planner.component';
import { ResultComponent } from '../../geoplanner/src/app/result/result.component';
import { ResultMapComponent } from '../../geoplanner/src/app/result-map/result-map.component';

import { CanDeactivateSearchService } from './can-deactivate-search.service';
import { CanDeactivateGeomapperService } from './can-deactivate-geomapper.service';
import { CanDeactivateVpService } from './can-deactivate-vp.service';
import { CanDeactivateWorkspaceService } from './can-deactivate-workspace.service';
import { CanDeactivatePcmService } from './can-deactivate-pcm.service';
import { AppCommercialComponent } from '../app-commercial/app-commercial.component';
import { CanDeactivateCommercialService } from './can-deactivate-commercial.service';
import { CanDeactivateResultService } from './can-deactivate-result.service';
import { CanDeactivateResultMapService } from './can-deactivate-result-map.service';
import { CanDeactivateObjectiveService } from './can-deactivate-objective.service';
import { ObjectiveComponent } from '../../geoplanner/src/app/workspace/objective/objective.component';

const routes = [
  { path: 'search', component: SearchComponent, canDeactivate: [CanDeactivateSearchService] },
  { path: 'workspace', component: WorkspaceComponent, canDeactivate: [CanDeactivateWorkspaceService] },
  { path: 'pcm', component: PcmComponent, canDeactivate: [CanDeactivatePcmService] },
  { path: 'geoplanner', component: AppComponent, canDeactivate: [CanDeactivateGeomapperService] },
  { path: 'visualplanner', component: VisualPlannerComponent, canDeactivate: [CanDeactivateVpService] },
  { path: 'result', component: ResultComponent, canDeactivate: [CanDeactivateResultService] },
  { path: 'resultmap', component: ResultMapComponent, canDeactivate: [CanDeactivateResultMapService] },
  { path: 'commercial', component: AppCommercialComponent, canDeactivate: [CanDeactivateCommercialService] },
  { path: 'objective', component: ObjectiveComponent, canDeactivate: [CanDeactivateObjectiveService] },
  { path: '**', redirectTo: 'search' }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ],
  declarations: [
  ],
  providers: [
    CanDeactivateSearchService,
    CanDeactivateGeomapperService,
    CanDeactivateWorkspaceService,
    CanDeactivatePcmService,
    CanDeactivateCommercialService,
    CanDeactivateVpService,
    CanDeactivateResultService,
    CanDeactivateResultMapService,
    CanDeactivateObjectiveService
  ]
})
export class AppRoutingModule { }
