import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { LocaleData } from '../../utils/LocaleData';
import { NgbDatepickerHelper } from './ngb-datepicker-helper';

@Injectable()
export class NgbDateParserService extends NgbDateParserFormatter {

  constructor(private datePipe: DatePipe) {
    super();
  }

  parse(value: string): NgbDateStruct {
    if (value) {
      const mobj = moment(value, LocaleData.displayDateFormat.toUpperCase());
      return {
        year: mobj.year(),
        month: mobj.month() + 1,
        day: mobj.date()
      };
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    const obj = NgbDatepickerHelper.convertDateStructToDate(date);
    return this.datePipe.transform(obj, LocaleData.displayDateFormat);
  }
}
