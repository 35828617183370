import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
@Component({
  selector: 'app-ag-lock',
  templateUrl: './ag-lock.component.html',
  styleUrls: ['./ag-lock.component.css']
})
export class AgLockComponent implements ICellRendererAngularComp {
  public params: any;

  // called on init
  agInit(params: any): void {
    this.params = params;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  handleLock(event) {
    const handleLock = this.params.colDef.cellRendererParams.handleLock;
    if (handleLock) {
      handleLock(event, this.params.rowIndex);
    }
  }
}
