import { Injectable } from '@angular/core';
import { DataShareService } from './data-share.service';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { List } from 'linqts';
import { LookupModel, Network, SOTBricSelectedDataModel, ColumnSummary, ColumnConfig, DynamicKeyObjectData } from '../../models';
import * as _ from 'lodash';
import { BrickBaseService } from './brick-base.service';
import { getFormData } from '../utils/formData';
import { GLOBAL } from '../utils/app.constant';
import { GeoLevels, GroupData } from '../../models/initial-config.model';
import { _throw } from 'rxjs/observable/throw';

@Injectable()
export class SharedService {

  uiControl: any = {};
  lookupColumn: any = [];
  lookupData: any = [];
  public hideUploadForArea = new Subject<boolean>();
  /**
   * Constructor
   * @param dataShareService data share service object
   */
  constructor(
    private dataShareService: DataShareService,
    private brickBaseService: BrickBaseService,
    private http: HttpClient
  ) { }

  /**
   * Set initial data like initial config
   * @memberof SharedService
   */
  setInitialData() {
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.lookupColumn = this.dataShareService.getInitialConfigByKey('lookupColumn');
    this.lookupData = this.dataShareService.getInitialConfigByKey('lookupData');
  }

  extractData(res: Response) {
    return res;
  }

  handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      /* tslint:disable:no-string-literal */
      const err = body['error'] || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return _throw(errMsg);
  }

  makeServerCall(params: any, serviceURL: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const requestOptions = {
      headers,
      body: getFormData(params)
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      serviceURL,
      requestOptions
    )
      .map(this.extractData)
      .catch(this.handleError);
  }

  /**
   * To hide the UI element (Used for only format filter)
   * @param key key value
   */
  hideUIElements(key: any) {
    let exists = null;
    this.setInitialData();
    if (this.uiControl) {
      for (const hiddenElement of this.uiControl.hiddenElements) {
        if (hiddenElement === key) {
          exists = true;
        }
      }
    }
    if (exists) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Get the lockup column data of specified brickId.
   * @param brickid Id of the brick.
   * @param columndata column data
   * @return tabsData which is array that contains lookup column data.
   */
  getLookupColumnData(brickid: number | string, columndata: any): LookupModel {
    this.setInitialData();
    const lookupColumnData = _.cloneDeep(this.lookupColumn);
    const tabsData = new List<any>(lookupColumnData)
      .Where((x: any) => {
        return x.brickId === brickid;
      }).Select((x: any) => {
        if (columndata !== undefined && columndata != null) {
          const tempLookup: any = [];
          const tempSelectionIds: any = [];
          x.lookup.forEach((obj: any) => {
            const keys: string[] = Object.keys(columndata.lookupData);
            if (obj.hasOwnProperty('selectionIds')) {
              obj.selectionIds.forEach((val: any) => {
                tempSelectionIds.push(String(val));
              });
              const result: any = _.intersection(tempSelectionIds, keys).map((val: any) => Number(val));
              obj.selectionIds = result;
              tempLookup.push(obj);
            } else {
              keys.forEach((key: any) => {
                if (Number(key) === obj.selectionId) {
                  tempLookup.push(obj);
                }
              });
            }
          });
          x.lookup = tempLookup;
        }
        return x;
      }).ToArray();
    return tabsData[0];
  }

  /**
   * Get column config lookup data for brick.
   * @function
   * @param {int} brickId - Brick Id.
   * @date : 29/01/2019
   * @author : Nishit Parekh
   */
  getConfigLookupForBrick(brickId, lookupData) {
    const lookupColumn = this.lookupColumn;
    const tempData = [];
    new List<any>(lookupColumn)
      .Where((x) => {
        return x.brickId === brickId;
      }).Select((x) => {
        x.lookup.forEach((obj) => {
          _.forEach(lookupData, (val, key) => {
            let finalKey = null;
            if (typeof (key) === 'string') {
              finalKey = parseInt(key, 10);
            } else {
              finalKey = val;
            }
            if (obj.selectionId === finalKey) {
              tempData.push(val);
            }
          });
        });
      }).ToArray();
    if (tempData.length > 0) {
      return tempData[0];
    } else {
      return tempData;
    }
  }

  /**
   * Get the lookup data of selectionID.
   * @param selectionId Selection id
   * @param configlookup Configlookup
   * @return tabsDetailData is array that contains lookup data.
   */
  getLookupData(selectionId: any, configlookup: any) {
    this.setInitialData();
    const lookupDetailData = _.cloneDeep(this.lookupData);
    return new List<any>(lookupDetailData)
      .Where((x: any) => {
        return x.selectionId === selectionId;
      }).Select((x) => {
        if (configlookup !== undefined && configlookup != null) {
          const tempData: any = [];
          x.data.forEach((obj: any) => {
            _.forEach(configlookup, (val: any, key: any) => {
              let value: any = null;
              if (typeof (key) === 'string') {
                value = parseInt(key, 10);
              } else {
                value = val;
              }
              if (value === obj.id) {
                tempData.push(obj);
              }
            });
          });
          x.data = tempData;
        }
        return x;
      }).ToArray();
  }

  /**
   * Remove selected value which are not exist in column config.
   * @function
   * @param {array} selectionIdsArray - Array of selection ids of particular brick.
   * @param {object} configLookup - lookup data from column config of particular column.
   * @param {object} selected - selcted data of particular brick.
   * @since : 25/05/2017
   * @author : Yuvraj Chauhan
   */
  compareSelectedValuesWithColumnConfig(selectionIdsArray: any, configLookup: any, selected: any): any {
    if (_.isEmpty(selectionIdsArray) || _.isEmpty(configLookup) || _.isEmpty(selected)) {
      return selected;
    }
    let selectionIdData: any = [];

    selectionIdsArray.forEach((selectionData) => {
      if (typeof selected[selectionData] !== 'undefined' && selected[selectionData].length > 0) {
        selectionIdData = this.getLookupData(selectionData, configLookup[selectionData]) || [];
        const selectedData = {};
        selectedData[selectionData] = [];
        if (selectionIdData.length) { // Added by Yuvraj Chauhan : SBRICS-1779 : 02/08/2016
          selected[selectionData].forEach((selectedObj: any) => {
            selectionIdData[0].data.forEach((obj: any) => {
              if ((_.has(obj, 'id') && selectedObj.id === obj.id) || (_.has(obj, 'lookupId') && selectedObj.lookupId === obj.lookupId)) {
                selectedData[selectionData].push(selectedObj);
              }
            });
          });
          selected[selectionData] = _.cloneDeep(selectedData[selectionData]);
        }
      }
    });
    return selected;
  }

  /**
   * @description Generated frame data for selected networks from columnConfig
   * @author Amit Mahida
   * @param {ColumnConfig} columnConfig
   * @param {Network[]} selectedNetworks
   * @returns
   * @memberof SharedService
   */

  setLookup(lookup: object, columnConfig: ColumnConfig, x) {

    for (const key in columnConfig.lookupData) {
      if (columnConfig.lookupData.hasOwnProperty(key)) {
        const res = x.lookup.filter(obj => parseInt(key, 10) === parseInt(obj.selectionId, 10));
        if (res.length > 0) {
          return columnConfig.lookupData[key];
        }
      }
    }
    return lookup;
  }

  getFrameData(lookup, innerObj) {

    for (const key in lookup) {
      if (lookup.hasOwnProperty(key) && Number(key) === Number(innerObj.networkId)) {
        innerObj.frameData = lookup[key];
        break;
      }
    }
    return innerObj;
  }

  generateFrameDataforPricingTag(columnConfig: ColumnConfig, selectedNetworks: Network[]) {
    const lookupColumn = _.cloneDeep(this.dataShareService.getInitialConfigByKey('lookupColumn'));
    const tagsData = new List<any>(lookupColumn).Where((x) => {
      return x.brickId === this.brickBaseService.brickID.PricingTag;
    }).Select((x) => {
      if (!_.isEmpty(columnConfig)) {
        let lookup = {};
        if (selectedNetworks && selectedNetworks[0] && selectedNetworks[0].openRateCard) {
          // SM-9077: RateCard feature
          lookup = columnConfig.rateCardLookupData;
        } else {
          for (const key in columnConfig['lookupData']) {
            if (columnConfig['lookupData'].hasOwnProperty(key)) {
              const val = columnConfig['lookupData'][key];
              for (const obj of x.lookup) {
                if (parseInt(key, 10) === parseInt(obj.selectionId, 10)) {
                  lookup = val;
                }
              }
            }
          }
        }
        if (Object.keys(lookup).length > 0) {
          for (let innerObj of selectedNetworks) {
            innerObj = this.getFrameData(lookup, innerObj);
          }
        }
      }
      return selectedNetworks;
    }).ToArray();
    return tagsData[0];
  }


  getDataToPopulateForTagBrick(dataToPopulate: any[], obj: any, lookup: any) {
    dataToPopulate.push(_.cloneDeep(obj));
    dataToPopulate[dataToPopulate.length - 1].tag = [];
    for (const innerObj of obj.tag) {
      for (const key in lookup) {
        // added inUse for SM-4796
        if (lookup.hasOwnProperty(key) && Number(key) === Number(innerObj.tagId) && innerObj.inUse) {
          innerObj.frameData = lookup[key];
          dataToPopulate[dataToPopulate.length - 1].tag.push(innerObj);
          break;
        }
      }
    }
    if (dataToPopulate[dataToPopulate.length - 1].tag && dataToPopulate[dataToPopulate.length - 1].tag.length === 0) {
      dataToPopulate.splice(dataToPopulate.length - 1, 1);
    }

  }

  getDataToPopulateForPricingTagBrick(dataToPopulate: any[], obj: any, lookup: any, allSelection: any) {
    dataToPopulate.push(_.cloneDeep(obj));
    dataToPopulate[dataToPopulate.length - 1].network = [];
    for (const innerObj of obj.network) {
      if (!obj.inUse) {
        innerObj.inUse = false;
      }
      for (const key in lookup) {
        if (lookup.hasOwnProperty(key) && (Number(key) === Number(innerObj.networkId))) {
          innerObj.frameData = lookup[key];
          innerObj.noOfFramesEditable = true;
          dataToPopulate[dataToPopulate.length - 1].network.push(innerObj);
          break;
        }
      }
    }
    if (dataToPopulate[dataToPopulate.length - 1].network.length === 0) {
      dataToPopulate.splice(dataToPopulate.length - 1, 1);
    } else {
      allSelection.network.push(dataToPopulate[dataToPopulate.length - 1].network);
    }
  }

  getDataToPopulateForChannelBrick(dataToPopulate: any[], groupIndex: number, obj: any, lookup: any) {
    dataToPopulate[groupIndex] = _.cloneDeep(obj);
    dataToPopulate[groupIndex].product = [];
    if (obj.product) {
      for (const innerObj of obj.product) {
        for (const key in lookup) {
          if (lookup.hasOwnProperty(key) && Number(key) === Number(innerObj.productId)) {
            innerObj.frameData = lookup[key];
            dataToPopulate[dataToPopulate.length - 1].product.push(innerObj);
            break;
          }
        }
      }
    }
    if (dataToPopulate[groupIndex].product && dataToPopulate[groupIndex].product.length === 0) {
      dataToPopulate.splice(dataToPopulate.length - 1, 1);
    }
  }

  getAllSelectionDataForPricingTag(groupData: GroupData, allSelection: any) {
    let tempGroupData: any = _.cloneDeep(groupData);
    if (tempGroupData.length > 0) {
      for (const group of tempGroupData) {
        for (const network of group.network) {
          network.frameData = [
            network.networkDefaultQuantity || 0,
            network.networkDefaultQuantity || 0,
            network.networkDefaultQuantity || 0
          ];
          allSelection.network.push(network);
        }
      }
    }
    allSelection.network = _.flatten(allSelection.network);
    tempGroupData = _.sortBy(tempGroupData, 'networkGroupName');
    tempGroupData.unshift(allSelection);
    return tempGroupData;
  }

  getDataFilteredByColumnConfig(x: any, groupData: GroupData,
    dataToPopulate: any[], lookup: any, allSelection: any) {
    const groupDataArray = Array.isArray(groupData) ? _.cloneDeep(groupData) : _.values(groupData);
    for (const groupDataObj of groupDataArray) {
      if (this.brickBaseService.brickID.Tag === x.brickId) {
        this.getDataToPopulateForTagBrick(dataToPopulate, groupDataObj, lookup);
      }
      if (this.brickBaseService.brickID.PricingTag === x.brickId) {
        this.getDataToPopulateForPricingTagBrick(dataToPopulate, groupDataObj, lookup, allSelection);
      }
      if (this.brickBaseService.brickID.Channel === x.brickId) {
        this.getDataToPopulateForChannelBrick(dataToPopulate, groupDataArray.indexOf(groupDataObj), groupDataObj, lookup);
      }
    }
  }

  /**
   * @description returns list of tags/networks/channels filtered by column config
   * @author Amit Mahida
   * @param {(number | string)} bricId
   * @param {ColumnConfig} columnConfig
   * @param {(NetworkGroup[] | DynamicKeyObjectData<TagGroupModel>)} groupData
   * @returns
   * @memberof SharedService
   */
  getTagDataFromColumnConfig(bricId: number | string, columnConfig: ColumnConfig, groupData: GroupData) {
    const lookupData = _.cloneDeep(this.dataShareService.getInitialConfigByKey('lookupColumn'));
    const userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    const tagsData = new List<any>(lookupData).Where((x) => {
      return x.brickId === bricId;
    }).Select((x) => {
      const allSelection = {
        inUse: true,
        networkGroupCode: 'ALLSELECTION',
        networkGroupId: 0,
        networkGroupName: userBundle['workspace.collection.networks.allSelection'],
        network: []
      };
      if (!_.isEmpty(columnConfig)) {
        let lookup = undefined;
        let dataToPopulate: any = [];
        for (const key in columnConfig['lookupData']) {
          if (columnConfig['lookupData'].hasOwnProperty(key)) {
            const val = columnConfig['lookupData'][key];
            for (const obj of x.lookup) {
              if (Number(key) === Number(obj.selectionId)) {
                lookup = val;
              }
            }
          }
        }
        if (lookup && Object.keys(lookup).length > 0) {
          let groupDataArray = [];
          if (!Array.isArray(groupData)) {
            groupDataArray = _.values(groupData);
          } else {
            groupDataArray = _.cloneDeep(groupData);
          }

          for (const groupDataObj of groupDataArray) {
            const groupIndex = groupDataArray.indexOf(groupDataObj);
            if (this.brickBaseService.brickID.Tag === x.brickId) {
              dataToPopulate.push(_.cloneDeep(groupDataObj));
              dataToPopulate[dataToPopulate.length - 1].tag = [];
              for (const innerObj of groupDataObj.tag) {
                for (const key in lookup) {
                  if (lookup.hasOwnProperty(key)) {
                    const v = lookup[key];
                    // added inUse for SM-4796
                    if (Number(key) === Number(innerObj.tagId) && innerObj.inUse) {
                      innerObj['frameData'] = v;
                      dataToPopulate[dataToPopulate.length - 1].tag.push(innerObj);
                    }
                  }
                }
              }
              if (dataToPopulate[dataToPopulate.length - 1].tag && dataToPopulate[dataToPopulate.length - 1].tag.length === 0) {
                dataToPopulate.splice(dataToPopulate.length - 1, 1);
              }
            }

            if (this.brickBaseService.brickID.PricingTag === x.brickId) {
              dataToPopulate.push(_.cloneDeep(groupDataObj));
              dataToPopulate[dataToPopulate.length - 1].network = [];
              for (const innerObj of groupDataObj.network) {
                if (!groupDataObj.inUse) {
                  innerObj.inUse = false;
                }
                for (const key in lookup) {
                  if (lookup.hasOwnProperty(key)) {
                    const v = lookup[key];
                    if (Number(key) === Number(innerObj.networkId)) {
                      innerObj.frameData = v;
                      innerObj.noOfFramesEditable = true;
                      dataToPopulate[dataToPopulate.length - 1].network.push(innerObj);
                    }
                  }
                }
              }
              if (dataToPopulate[dataToPopulate.length - 1].network.length === 0) {
                dataToPopulate.splice(dataToPopulate.length - 1, 1);
              } else {
                allSelection.network.push(dataToPopulate[dataToPopulate.length - 1].network);
              }
            }

            if (this.brickBaseService.brickID.Channel === x.brickId) {
              dataToPopulate[groupIndex] = _.cloneDeep(groupDataObj);
              dataToPopulate[groupIndex].product = [];
              if (groupDataObj.product) {
                for (const innerObj of groupDataObj.product) {
                  for (const key in lookup) {
                    if (lookup.hasOwnProperty(key)) {
                      const v = lookup[key];
                      if (Number(key) === Number(innerObj.productId)) {
                        innerObj['frameData'] = v;
                        dataToPopulate[dataToPopulate.length - 1].product.push(innerObj);
                      }
                    }
                  }
                }
              }
              if (dataToPopulate[groupIndex].product && dataToPopulate[groupIndex].product.length === 0) {
                dataToPopulate.splice(dataToPopulate.length - 1, 1);
              }
            }
          }
        }
        if (bricId === this.brickBaseService.brickID.PricingTag) {
          allSelection.network = _.flatten(allSelection.network);
          dataToPopulate = _.sortBy(dataToPopulate, 'networkGroupName');
          dataToPopulate.unshift(allSelection);
        }
        return dataToPopulate;
      } else {
        if (x.brickId === this.brickBaseService.brickID.PricingTag) {
          groupData = _.cloneDeep(this.getAllSelectionDataForPricingTag(groupData, allSelection));
        }
        return groupData;
      }
    }).ToArray();
    return this.brickBaseService.brickID.Tag === bricId ? _.values(tagsData[0]) : tagsData[0];
  }

  /**
   * @description This will call resetUI backend call and clear loaded campaign
   * @author Nishit Parekh
   * @memberof SharedService
   */
  clearLoadedCampaign(params: object, url: string) {
    return this.makeServerCall(params, url);
  }

  /**
   * @description This function is used by Result as well as Commmercial page by action buttons at bottom
   * @author Nishit Parekh
   * @param {object} params parameters to send in request
   * @param {string} url url to call service
   * @returns Observal
   * @memberof SharedService
   */
  actionButtonRequest(params: object, url: string): Observable<any> {
    return this.makeServerCall(params, url);
  }

  /**
   * @description filter lookup value and set tree stucture data in location bric
   * @author Kishan Patel
   * @param {GeoLevels[]} geoLevels
   * @param {any[]} configLookup
   * @returns list of geoLevels
   * @memberof shareService
   */
  filterGeoTreeWithColumnConfig(geoLevels: GeoLevels[], configLookup: any[]) {
    this.setInitialData();
    let list = _.cloneDeep(geoLevels) || [];
    if (list.length > 0 && this.uiControl.locationInAlphabeticalOrder) {
      list = _.sortBy(list, 'name');
    }

    if (!(configLookup && Object.keys(configLookup).length && list.length)) {
      return list;
    }

    // Fixed SM-4947 and checked case for solution not possible also
    if (!configLookup[GLOBAL.geoArea.area106] && !configLookup[GLOBAL.geoArea.area107] && !configLookup[GLOBAL.geoArea.area108]) {
      return [];
    }

    if (configLookup[GLOBAL.geoArea.area106]) {
      const configList = Object.keys(configLookup[GLOBAL.geoArea.area106]);
      list = list.filter((item) => {
        if (configList.indexOf(item.id.toString()) > -1) {
          return item;
        }
      });
    }
    if (configLookup[GLOBAL.geoArea.area107]) {
      const configList = Object.keys(configLookup[GLOBAL.geoArea.area107]);
      list.forEach((item) => {
        if (item.child) {
          this.filterChildren(item.child, configList);
        }
      });
    }
    if (configLookup[GLOBAL.geoArea.area108]) {
      const configList = Object.keys(configLookup[GLOBAL.geoArea.area108]);
      this.filterGeoTree108(list, configList);
    }

    return list;
  }

  filterGeoTree108(list: GeoLevels[], configList: string[]) {
    list.forEach((item) => {
      if (item.child) {
        item.child.forEach((ele) => {
          if (ele.child) {
            this.filterChildren(ele.child, configList);
          }
        });
      }
    });
  }

  /**
   * @description Return true if any value is selected on passed tree node
   * @author Amit Mahida
   * @param {any[]} locations
   * @param {*} selectedValuesOnWs
   * @returns {boolean}
   * @memberof SharedService
   */
  checkValuesSelectedInNode(locations: any[], selectedValuesOnWs: any): boolean {
    let valuesSelected = false;
    if (!locations) {
      return valuesSelected;
    }
    for (const loc of locations) {
      if (_.includes(selectedValuesOnWs, Number(loc.id))) {
        valuesSelected = true;
        break;
      } else {
        if (!_.isUndefined(loc.child) && loc.child.length > 0) {
          valuesSelected = this.checkValuesSelectedInNode(loc.child, selectedValuesOnWs);
        }
        if (valuesSelected) {
          break;
        }
      }
    }
    if (valuesSelected) {
      return valuesSelected;
    }
  }

  /**
   * @description deletes tree nodes
   * @author Amit Mahida
   * @param {any[]} data
   * @param {number[]} selectedIds
   * @memberof SharedService
   */
  deleteTreeNodes(data: any[], selectedIds: number[]) {
    for (let index = data.length - 1; index >= 0; index--) {
      const obj = data[index];
      if (obj) {
        if (this.checkValuesSelectedInNode(obj.child, selectedIds)) {
          if (obj.child && obj.child.length > 0) {
            this.iterateToDeleteLocation(obj.child, selectedIds);
          }
        } else {
          data.splice(index, 1);
        }
      }
    }
  }

  /**
   * @description Traverse on tree data to filter the selected values on workspace for objective mode
   * @author Amit Mahida
   * @param {any[]} locations
   * @param {number[]} selectedIds
   * @memberof SharedService
   */
  iterateToDeleteLocation(locations: any[], selectedIds: number[]) {
    for (let k = locations.length - 1; k >= 0; k--) {
      if (locations[k]) {
        if (selectedIds.indexOf(locations[k].id) === -1 && !this.checkValuesSelectedInNode(locations[k].child, selectedIds)) {
          locations.splice(k, 1);
          this.iterateToDeleteLocation(locations, selectedIds);
        } else {
          if (!_.isUndefined(locations[k].child) && locations[k].child.length > 0) {
            this.iterateToDeleteLocation(locations[k].child, selectedIds);
          }
        }
      }
    }
  }

  /**
   * @description Filters tree of locations with selected values on workspace for objective mode
   * @author Amit Mahida
   * @param {GeoLevels[]} geoLevels
   * @param {*} selectedValues
   * @returns {GeoLevels[]}
   * @memberof SharedService
   */
  filterGeoTreeWithSelectedValues(geoLevels: GeoLevels[], selectedValues: any): GeoLevels[] {
    const list = _.cloneDeep(geoLevels) || [];

    if (selectedValues && Object.keys(selectedValues).length && list.length) {

      if (!selectedValues[GLOBAL.geoArea.area106] && !selectedValues[GLOBAL.geoArea.area107] && !selectedValues[GLOBAL.geoArea.area108]) {
        return [];
      }

      if (selectedValues[GLOBAL.geoArea.area106].length) {
        const selectedIds = selectedValues[GLOBAL.geoArea.area106].map(l => l.id);
        this.deleteTreeNodes(list, selectedIds);
      }
      if (selectedValues[GLOBAL.geoArea.area107].length) {
        const selectedIds = selectedValues[GLOBAL.geoArea.area107].map(l => l.id);
        this.deleteTreeNodes(list, selectedIds);
      }
      if (selectedValues[GLOBAL.geoArea.area108].length) {
        const selectedIds = selectedValues[GLOBAL.geoArea.area108].map(l => l.id);
        this.deleteTreeNodes(list, selectedIds);
      }
    }
    return list;
  }

  /**
* @description filter children from item config list
* @author Kishan Patel
* @param {*} item GeoLevels[]
* @param {*} configList string[]
* @memberof shareService
*/
  filterChildren(item: GeoLevels[], configList: string[]) {
    const len = item.length - 1;
    for (let index = len; index >= 0; index--) {
      if (configList.indexOf(item[index].id.toString()) === -1) {
        item.splice(index, 1);
      }
    }
  }

  public getSelectedForGeoTree(list, selectedGeoTree, relativeFilters: DynamicKeyObjectData<object>) {
    const geoTree = _.cloneDeep(GLOBAL.geoTree);

    for (const selectedGeoTreeEle of selectedGeoTree) {
      let loopBreak = false;
      for (const li of list) {
        if (Number(selectedGeoTreeEle) === Number(li.id)) {
          li.relative = relativeFilters[GLOBAL.geoArea.area106] && relativeFilters[GLOBAL.geoArea.area106].hasOwnProperty(li.id);
          if (li.child) {
            li.child = [];
          }
          geoTree[GLOBAL.geoArea.area106].push(li);
          loopBreak = true;
          break;
        }
        for (const c of li.child) {
          if (Number(selectedGeoTreeEle) === Number(c.id)) {
            c.relative = relativeFilters[GLOBAL.geoArea.area107] && relativeFilters[GLOBAL.geoArea.area107].hasOwnProperty(c.id);
            if (c.child) {
              c.child = [];
            }
            geoTree[GLOBAL.geoArea.area107].push(c);
            loopBreak = true;
            break;
          }
          for (const kc of c.child) {
            if (Number(selectedGeoTreeEle) === Number(kc.id)) {
              kc.relative = relativeFilters[GLOBAL.geoArea.area108] && relativeFilters[GLOBAL.geoArea.area108].hasOwnProperty(kc.id);
              geoTree[GLOBAL.geoArea.area108].push(kc);
              loopBreak = true;
              break;
            }
          }
          if (loopBreak) {
            break;
          }
        }
        if (loopBreak) {
          break;
        }
      }
    }
    return geoTree;
  }

  // SM-4841 - Alkesh
  getColumnSummaries(columnSummary: { [key: string]: ColumnSummary }, startIndex: number, endIndex: number): ColumnSummary[] {
    const colSum: ColumnSummary[] = [];
    for (let colSuIndex = startIndex; colSuIndex < endIndex; colSuIndex++) {
      if (columnSummary && columnSummary[colSuIndex]) {
        colSum.push(columnSummary[colSuIndex]);
      }
    }
    return colSum;
  }

  getsoTSlots(columnSummary): any[] {
    const soTSlots = [];
    for (const colSum of columnSummary) {
      if (colSum && colSum.framesSlots && colSum.framesSlots.length > 0) {
        for (const slot of colSum.framesSlots) {
          if (soTSlots.indexOf(slot) === -1) {
            soTSlots.push(slot);
          }
        }
      }
    }
    return soTSlots;
  }

  setSOTMaxFramesSlotsObj(shareOfTime: SOTBricSelectedDataModel, columnSummary: ColumnSummary[]): SOTBricSelectedDataModel {
    if (columnSummary) {
      const soTSlots = this.getsoTSlots(columnSummary);
      if (soTSlots && soTSlots.length > 0) {
        if (soTSlots.length === 1) {
          shareOfTime.maxFramesSlotsText = soTSlots[0].toString();
          shareOfTime.maxFramesSlots = soTSlots[0];
        } else {
          shareOfTime.maxFramesSlotsText = 'Mixed';
          const sortedSlots = this.sortSOTSlots(soTSlots);
          shareOfTime.maxFramesSlots = sortedSlots[sortedSlots.length - 1];
        }
      }
    }
    return shareOfTime;
  }

  sortSOTSlots(soTSlots) {
    return soTSlots.sort((a, b) => (a < b ? -1 : 1));
  }
}
