import { Component, OnInit, AfterContentInit, Input } from '@angular/core';
import { of } from 'rxjs';
import { LookupViewService } from './../../../core/components/lookup-view/lookup-view.service';
import { DataShareService, StateService } from '../../../core/services';
import { SystemFlags } from '../../../models';
import { GLOBAL } from '../../../core/utils/app.constant';
import * as _ from 'lodash';

@Component({
  selector: 'app-duplication',
  templateUrl: './duplication.component.html',
  styleUrls: ['./duplication.component.css']
})
export class DuplicationComponent implements OnInit, AfterContentInit {

  public brickBgColor: string = GLOBAL.DEFAULT_TITLE_BACKGROUND_COLOR;
  public userBundle: {};
  public selectedDuplicationAdvertisers = [];
  private lookupDataInfo: any;
  public lookupData: any;
  autocompleteItems;
  searchText = '';
  @Input() resolveObject;

  constructor(
    private dataShareService: DataShareService,
    private lookupViewService: LookupViewService,
    private stateService: StateService
  ) {
  }

  ngOnInit() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.lookupDataInfo = {
      url: this.dataShareService.getServiceCallUrlByKey('LOOKUP_ADVERTISER_URL'),
      param: {}
    };

    this.selectedValueCheck();

    let advertizerExists = false;
    if (_.isArray(this.stateService.getSearchPageObject('duplicationData')) && this.stateService.getSearchPageObject('duplicationData').length && !this.resolveObject.data.changedAdvertiser) {
      this.selectedDuplicationAdvertisers = this.stateService.getSearchPageObject('duplicationData');
    } else {
      if (this.selectedDuplicationAdvertisers.length > 0) {
        this.selectedDuplicationAdvertisers.forEach((obj, i) => {
          if (obj.hasOwnProperty('auto')) {
            this.selectedDuplicationAdvertisers[i] = this.resolveObject.data.searchAdvertizer;
          } else {
            this.selectedDuplicationAdvertisers.forEach((obj) => {
              if (obj.organisationId === this.resolveObject.data.searchAdvertizer.organisationId) {
                advertizerExists = true;
              }
            });
            if (!advertizerExists) {
              this.selectedDuplicationAdvertisers.push(this.resolveObject.data.searchAdvertizer);
            }
          }
        });
      } else {
        if (this.resolveObject.data.searchAdvertizer) {
          this.selectedDuplicationAdvertisers.push(this.resolveObject.data.searchAdvertizer);
        }
      }
    }
    // }
  }

  selectedValueCheck() {
    if (this.resolveObject.data.SelectedValue) {
      const dupliationAdvertisers = [];
      this.resolveObject.data.SelectedValue.forEach((obj) => {
        const object: any = {
          advertiserId: obj.advertiserId,
          advertiserName: obj.advertiserName
        };
        if (obj.hasOwnProperty('auto')) {
          object.auto = obj.auto;
        }
        dupliationAdvertisers.push(object);
      });
      this.selectedDuplicationAdvertisers = dupliationAdvertisers;
    }
  }

  /**
   * @description get advertisers.
   * @memberof DuplicationComponent
   */
  getAdvertisers = (query: string) => {
    if (query && query.length >= 2) {
      this.lookupDataInfo.param = {
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        action: 'lookupOrganisation',
        data: { organisationName: query, organisationTypeId: 1 }
      };
      this.lookupDataInfo.param.data = JSON.stringify(this.lookupDataInfo.param.data);
      return this.lookupViewService.getData(this.lookupDataInfo.url, this.lookupDataInfo.param)
        .map((data: any) => {
          const returnedData = [];
          if (!_.isUndefined(data.organisation) && data.organisation.length > 0) {
            _.each(data.organisation, (item) => {
              returnedData.push({
                advertiserId: item.organisationId,
                advertiserName: item.organisationName,
                source: item
              });
            });
          }
          return returnedData;
        });
    } else {
      return of([]);
    }
  }

  ngAfterContentInit() {
    //
  }

  /**
   * @description remove Item Id for Advertisers
   * @author Kishan Patel
   * @param {number} index
   * @memberof DuplicationComponent
   */
  removeItemById(index: number): void {
    if (index !== null) {
      this.selectedDuplicationAdvertisers.splice(index, 1);
    }
    SystemFlags.isduplicateAdvertiserReplace = false;
  }
  addDuplicateAdvertiser(): void {
    SystemFlags.isduplicateAdvertiserReplace = false;
  }

  onModalSaved(event) {
    this.stateService.setSearchPageObject('duplicationData', this.selectedDuplicationAdvertisers);
    event.activeModal.close(this.selectedDuplicationAdvertisers);
  }

  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }

  trackByIndex(index, item) {
    return index;
  }

}
