import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  ChangeDetectorRef,
  HostListener,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  ExcelCell,
  ExportToExcel,
  ExcelColumn
} from '../../../core/utils/exportToExcel';
import { GridOptions, ColDef } from 'ag-grid-community';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { DataShareService } from '../../../core/services/data-share.service';
import { SelectSupport } from '../ag-grid-support/utils/SelectSupport';
import { LocaleData } from '../../../core/utils/LocaleData';

import {
  AgCollapseExpandComponent,
  AgCheckboxComponent,
  AgCustomFilterMenuComponent,
  AgTextFloatingFilterComponent,
  AgSelectFloatingFilterComponent,
  AgCustomHeaderComponent,
  AgTextCellEditorComponent,
  AgSelectCellEditorComponent,
  AgDateCellEditorComponent,
  AgNumberCellRendererComponent,
  AgCurrencyCellRendererComponent,
  AgSelectCellRendererComponent,
  AgDateCellRendererComponent,
  AgCheckboxCellRendererComponent,
  AgCustomSort,
  AgCustomRowUtil,
  DataFormatterService
} from '../ag-grid-support/index';
import * as _ from 'lodash';
import * as moment from 'moment';

import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { CommercialService } from '../../../core/services/commercial.service';
import { GLOBAL } from '../../../core/utils/app.constant';

@Component({
  selector: 'app-discount-grid-us',
  templateUrl: './discount-grid-us.component.html',
  styleUrls: ['../ag-grid-col-select-style.css', '../ag-grid-custom-style.css'],
  providers: [DatePipe]
})
export class DiscountGridUSComponent implements OnInit, OnDestroy {

  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  uiControl: any;
  gridCampaignDetails: any = {
    noOfWeeks: 0,
    discount: 0,
    rateCard: 0,
    net: 0,
    paid: 0,
    GB: 0,
    SAB: 0
  };

  /**
   * Commercial Config object contains - initial config, etc
   */
  @Input() commercialConfig: any;

  @Input() gridData: any = {};
  /**
   * Ag-grid Data column sort object
   */
  sortObj: AgCustomSort;

  /**
   * Ag-Grid Options Object
   */
  gridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  columnDefs: ColDef[];

  /**
   * column list to show in show/hide column dropdown
   */
  columnList: any = [];

  /**
   * list of Cart Data
   * @memberof CartDetailComponent
   */
  rowData: any[] = [];

  /**
   * Grid footer data
   */
  footerRowData: any[];

  /**
   * user bundle data
   */
  userBundle: any = {};
  totalNet: any = 0;
  totalRateCard: any = 0;
  totalselectedRateCard = 0;
  totalselectedNet = 0;

  statusCategory = [];
  indexedFieldList = [];

  numericFields = [];
  dateRangeFields = [];
  selectFields = [];
  groupingField = 'week';
  PAID_STATUS: any = '';
  DM_STATUS = 4;
  isActualStartDateChanged = false;

  /**
   * @description Grid data change subscription
   * @type {Subscription}
   * @memberof DiscountGridUSComponent
   */
  discountDataSubscription: Subscription;

  constructor(private dataShareService: DataShareService,
              private datePipe: DatePipe,
              private toaster: LogHelperService,
              private dataFormatterService: DataFormatterService,
              private commercialService: CommercialService,
              private cdref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.uiControl = this.commercialConfig.uiControl;
    this.fillGridCampaignDetails(this.gridData.discounts);

    this.setArrayLists();

    this.setFooterRows();
    this.prepareRowData();
    this.prepareColDef();

    this.reset();

    // we pass an empty gridOptions in, so we can grab the api out
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      onRowDataChanged: (event) => {
        setTimeout(() => {
          event.api.setPinnedBottomRowData(this.footerRowData);
        }, 0);
      },
      scrollbarWidth: 10,
      onGridReady: this.onGridReady,
      getRowHeight: this.getRowHeight,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      onFilterChanged: this.onFilterChanged,
      suppressScrollOnNewData: true,
      onCellEditingStopped: this.onCellEditingStopped,
      groupingField: this.groupingField,
      suppressMovableColumns: true,
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: node => node.data.isVisible
    } as GridOptions;

    this.discountDataSubscription = this.commercialService.gridData$.subscribe(() => {
      if (this.gridOptions && this.gridOptions.api) {
        this.gridOptions.api.redrawRows();
      }
    });
  }

  ngOnDestroy() {
    this.discountDataSubscription.unsubscribe();
  }

  setArrayLists() {
    this.setStatusCategory();
  }

  setStatusCategory() {
    this.statusCategory = this.gridData.statusCategory;
    this.indexedFieldList = [];

    if (this.statusCategory && _.isArray(this.statusCategory)) {
      for (const object of this.statusCategory) {
        if (object) {
          this.indexedFieldList[object.idStatusCategory] = object;
        }
        if (object && object.statusCategoryName.toLowerCase() === 'paid') {
          this.PAID_STATUS = object.idStatusCategory;
        }
        if (object && object.statusCategoryName.toLowerCase() === 'dm') {
          this.DM_STATUS = object.idStatusCategory;
        }
      }
    }
  }

  onGridReady = () => {
    this.columnList = this.gridOptions.columnApi.getAllColumns();
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else if (params.data.isVisible) {
      height = 30;
    }
    return height;
  }

  isFullWidthCell = (rowNode): boolean => {
    return rowNode.data.gridSummary;
  }

  weekPinnedRowCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {
      // apply Icon
      const eImg: HTMLImageElement = document.createElement('img');
      eImg.src = 'images/general/apply.png';
      eImg.title = 'Apply';
      eImg.onclick = this.apply;
      eDiv.appendChild(eImg);

      if (this.footerRowData[0].net === 0 || this.footerRowData[0].net) {
        // Spread Icon
        // const eImg: HTMLImageElement = document.createElement('img');
        eImg.src = 'images/general/spread.png';
        eImg.title = 'Spread';
        eImg.onclick = this.spread;
        eDiv.appendChild(eImg);
      }
    }
    return eDiv;
  }

  weekCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'dvTextoverflow';
    let text = '';
    if (params.data.isParent) {
      const childRows = this.getChildRows(params.data[this.groupingField]);
      text = `${params.data[params.column.colId]} (${childRows.length})`;
    } else {
      text = params.data[params.column.colId];
    }
    eDiv.innerHTML = text;
    return eDiv;
  }

  weekDaysCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'dvTextoverflow';
    let text = '';
    if (params.data.isParent) {
      const childRows = this.getChildRows(params.data[this.groupingField]);
      let groupRow = [];
      _.each(childRows, (data) => {
        const row = data.weekDays.split(',');
        groupRow = groupRow.concat(row);
      });
      groupRow = groupRow.map(i => i.trim());
      groupRow = _.uniq(groupRow);
      groupRow.sort(this.sortDays);

      text = groupRow.join(', ');
    } else {
      text = params.data[params.column.colId];
    }
    eDiv.innerHTML = text;
    return eDiv;
  }

  sortDays(aa, bb) {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const a = days.indexOf(aa);
    const b = days.indexOf(bb);
    return a - b;
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.forEach((r) => {
        r.isFilterPass = true;
      });
    }
    AgCustomRowUtil.setParentRowSelectedProperty(this.rowData, this.groupingField);
    // Refresh Grid Header checkbox component
    /* tslint:disable:no-string-literal */
    this.gridOptions.api['headerRootComp'].forEachHeaderElement((ev: any) => {
      if (ev.column.colId === 'selectChk' && ev.columnApi && ev.childComponents && ev.childComponents.length > 1) {
        const headerCmpIns = ev.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  clearAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    this.gridOptions.api.onFilterChanged();
  }

  onCellEditingStopped = (event) => {
    if (event.rowPinned !== 'bottom') {
      switch (event.column.colId) {
        // SM-2328 - hide discount and net
        // case "discount":
        //   if (event.data.statusCategoryId == this.PAID_STATUS || event.data.statusCategoryId == this.DM_STATUS) {
        //     event.data.net = this.calculateNet(event.data.rateCard, event.data.discount);
        //   } else {
        //     event.data.discount = this.indexedFieldList[event.data.idStatusCategory].defaultDiscountAmount;
        //     event.data.net = this.calculateNet(event.data.rateCard, event.data.discount);
        //   }
        //   break;
        // case "net":
        //   if (event.data.statusCategoryId == this.PAID_STATUS || event.data.statusCategoryId == this.DM_STATUS) {
        //     if (event.data) {
        //       event.data.discount = this.calculateDiscount(event.data.rateCard, event.data.net);
        //     }
        //   } else {
        //     event.data.discount = 100;
        //     event.data.net = this.calculateNet(event.data.rateCard, event.data.discount);
        //   }
        //   break;
        // case "statusCategoryId":
        //   if (event.data.statusCategoryId != this.PAID_STATUS && event.data.statusCategoryId != this.DM_STATUS) {
        //     event.data.discount = 100;
        //     event.data.net = this.calculateNet(event.data.rateCard, event.data.discount);
        //   }
        //   break;
        case 'actualStart':
          if (typeof event.data.actualStart !== 'undefined' && event.data.actualStart !== '' && event.data.actualStart !== null) {
            event.data.delivered = 1;
          } else {
            event.data.delivered = 0;
          }
          break;
        case 'delivered':
          event.data.delivered = Number(event.data.delivered);
          if (event.data.delivered === 1) {
            if (typeof event.data.actualStart === 'undefined' || event.data.actualStart === '' || event.data.actualStart === null) {
              event.data.actualStart = event.data.minDate.toDate();
            }
          } else if (event.data.delivered === 0) {
            event.data.actualStart = '';
          }
          break;
        default:
          break;
      }
      const childRows = this.getChildRows(null, false);
      this.fillGridCampaignDetails(childRows);
      this.rowData = _.clone(this.rowData);
    } else if (event.column.colId === 'net') {
      this.rowData = _.clone(this.rowData);
    } else if (event.column.colId === 'actualStart' && this.footerRowData[0].actualStart) {
      this.isActualStartDateChanged = true;
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen() && $event.button !== 2 && !dropdownEle._isEventFromToggle($event)
    && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
      dropdownEle.close();
    }
  }

  prepareRowData() {
    const tempRowData = _.clone(this.rowData);
    this.rowData = [];
    let groupId = null;
    let tempRow: any;
    for (let curRow of this.gridData.discounts) {
      tempRow = this.getParentRows(curRow[this.groupingField], tempRowData);
      let isExpanded = false;
      if (tempRow && tempRow.length > 0) {
        isExpanded = tempRow[0].isExpanded;
      }
      curRow.isFilterPass = true;

      // SM-2328 - hide discount and net
      // curRow.net = parseFloat(curRow.net.toFixed(2));
      curRow.rateCard = parseFloat(curRow.rateCard.toFixed(2));
      if (typeof curRow.actualStart !== 'undefined' && curRow.actualStart !== '') {
        curRow.actualStart = new Date(curRow.actualStart);
        curRow.delivered = 1;
      } else {
        curRow.delivered = 0;
      }

      curRow = this.getCurRow(curRow);

      if (groupId === curRow[this.groupingField]) {
        tempRow = curRow;
        tempRow.isParent = false;
        tempRow.isVisible = isExpanded;
        tempRow.isSelected = false;
        this.rowData.push(tempRow);
      } else {
        groupId = curRow[this.groupingField];
        tempRow = _.clone(curRow);
        tempRow.isParent = true;
        tempRow.isVisible = true;
        tempRow.isExpanded = isExpanded;
        tempRow.isSelected = false;
        tempRow.frame = '';
        tempRow.childCount = this.gridData.discounts.filter((row) => {
          return row[this.groupingField] === tempRow[this.groupingField];
        }).length;
        this.rowData.push(tempRow);

        tempRow = curRow;
        tempRow.isParent = false;
        tempRow.isVisible = isExpanded;
        tempRow.isSelected = false;
        this.rowData.push(tempRow);
      }
    }
  }

  getCurRow(curRow: any) {
    if (typeof curRow.week !== 'undefined' && curRow.week !== '') {
      const weekDate = curRow.week.split('-');
      if (weekDate.length > 0) {
        const dateFormat = LocaleData.displayDateFormat.replace('y', 'yy').toUpperCase();
        if (typeof weekDate[0] !== 'undefined' && weekDate[0] !== '') {
          curRow.minDate = moment(weekDate[0], dateFormat);
        }
        if (typeof weekDate[1] !== 'undefined' && weekDate[1] !== '') {
          curRow.maxDate = moment(weekDate[1], dateFormat);
        }
      }
    }
    return curRow;
  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinnedRow: 1 },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
  }

  getGridSummary() {
    this.calculateGridSummaryValues();
    // SM-2328 - hide discount and net
    return `<span>${this.userBundle['discount.common.totalFrames']} : ${this.getChildRows().length} &#45;
     ${this.userBundle['discount.common.selectedFrames']} : ${this.getSelectedChildRows().length}
     (<b>${this.userBundle['discount.us.rateCard']}</b>: ${this.dataFormatterService.currency(this.totalselectedRateCard)} )</span>`;
    // , <b>' + this.userBundle['discount.us.net'] + '</b>: ' + this.dataFormatterService.currency(this.totalselectedNet) + ')</span>';
  }

  calculateGridSummaryValues() {
    this.totalRateCard = this.getTotalOfProperty('rateCard');
    this.totalRateCard = this.dataFormatterService.currency(this.totalRateCard);
    this.cdref.detectChanges();
    this.totalselectedRateCard = this.getTotalOfSelectedProperty('rateCard');
  }

  getTotalOfSelectedProperty(propertyName: string): number {
    const rows = AgCustomRowUtil.getSelectedChildRows(this.rowData);
    return AgCustomRowUtil.sumFieldValues(rows, propertyName);
  }

  getTotalOfProperty(propertyName: string): number {
    const rows = AgCustomRowUtil.getChildRows(this.rowData);
    return AgCustomRowUtil.sumFieldValues(rows, propertyName);
  }

  hideColumns(key: string): boolean {
    let show = false;

    if (this.uiControl && this.uiControl.hiddenColumns) {
      show = this.uiControl.hiddenColumns.indexOf(key) !== -1;
    }

    return show;
  }

  disableColumn(key: string): boolean {
    let disable = false;

    if (this.uiControl && this.uiControl.disabledColumns) {
      disable = this.uiControl.disabledColumns.indexOf(key) !== -1;
    }

    return disable;
  }

  getCellClass = (params): string[] => {
    const retArr: string[] = [];
    if (params.data.isSelected) {
      retArr.push('selectedRow');
    }
    if (params.node.rowPinned === 'bottom') {
      retArr.push('borderBottom');
    }
    return retArr;
  }

  // tslint:disable-next-line
  prepareColDef() {
    this.columnDefs = [];

    this.columnDefs.push(
      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false,
        cellRendererFramework: AgCollapseExpandComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCollapseExpandComponent,
        cellClass: this.getCellClass
      });

    this.columnDefs.push(
      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false, colId: 'selectChk',
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: this.getCellClass
      });

    if (this.hideColumns('week') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.us.week'] || 'Week', field: 'week', width: 160, editable: false,
          cellRenderer: this.weekCellRenderer,
          pinnedRowCellRenderer: this.weekPinnedRowCellRenderer
        });
    }
    if (this.hideColumns('frame') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.us.frame'], field: 'frame', width: 150, editable: false,
          pinnedRowCellRenderer: this.blankCellRenderer,
        });
    }
    if (this.hideColumns('weekDays') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.us.weekDays'] || 'Week Days', field: 'weekDays', width: 150, editable: false,
          cellRenderer: this.weekDaysCellRenderer,
          pinnedRowCellRenderer: this.blankCellRenderer,
        });
    }
    if (this.hideColumns('rateCard') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.us.rateCard'], field: 'rateCard', width: 200, editable: false,
          cellRendererFramework: AgCurrencyCellRendererComponent,
          pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
          pinnedRowCellRendererParams: {
            prefixText: () => {
              return this.userBundle['discount.common.total'];
            },
            gridSumRow: 1
          }
        });
    }
    if (this.hideColumns('rateType') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.us.statusCategoryId'], field: 'statusCategoryId', width: 150, editable: true, pinned: false,
          cellRendererFramework: AgSelectCellRendererComponent,
          cellRendererParams: {
            cellEditable: (data) => {
              return (!data.isParent) && !this.disableColumn('rateType');
            },
            calcParentCellValue: () => {
              return '';
            },
            valKey: 'idStatusCategory',
            textKey: 'statusCategoryName',
            dataArr: () => {
              return this.statusCategory;
            }
          },
          pinnedRowCellRendererFramework: AgSelectCellRendererComponent,
          pinnedRowCellRendererParams: {
            cellEditable: () => {
              return true;
            },
            valKey: 'idStatusCategory',
            textKey: 'statusCategoryName',
            dataArr: () => {
              return this.statusCategory;
            }
          },
          cellEditorFramework: AgSelectCellEditorComponent,
          cellEditorParams: {
            valKey: 'idStatusCategory',
            textKey: 'statusCategoryName',
            dataArr: () => {
              return this.statusCategory;
            },
            cellEditable: (data) => {
              return (!data.isParent && !this.disableColumn('rateType'));
            }
          }
        });
    }
    if (this.hideColumns('impressions') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.impressions'], field: 'impressions', width: 150, editable: false,
          cellRendererFramework: AgNumberCellRendererComponent,
          pinnedRowCellRendererFramework: AgNumberCellRendererComponent,
          pinnedRowCellRendererParams: {
            cellEditable: () => {
              return !this.disableColumn('impressions');
            },
            calcCellValue: this.getTotalOfFieldsFromRows
          }
        });
    }
    if (this.hideColumns('impressionsRemaining') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.impressionsRemaining'], field: 'impressionsRemaining', width: 150, editable: false,
          cellRendererFramework: AgNumberCellRendererComponent,
          pinnedRowCellRendererFramework: AgNumberCellRendererComponent,
          pinnedRowCellRendererParams: {
            cellEditable: () => {
              return !this.disableColumn('impressionsRemaining');
            },
            calcCellValue: this.getTotalOfFieldsFromRows
          }
        });
    }
    if (this.hideColumns('impressionsPurchased') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['common.impressionsPurchased'], field: 'impressionsPurchased', width: 150, editable: false,
          cellRendererFramework: AgNumberCellRendererComponent,
          pinnedRowCellRendererFramework: AgNumberCellRendererComponent,
          pinnedRowCellRendererParams: {
            cellEditable: () => {
              return !this.disableColumn('impressionsPurchased');
            },
            calcCellValue: this.getTotalOfFieldsFromRows
          }
        });
    }
    if (this.hideColumns('fixed') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.us.fixed'] || 'Fixed', field: 'fixed', width: 85,
          editable: false, pinned: false, filter: false,
          pinnedRowCellRendererFramework: AgCheckboxCellRendererComponent,
          cellRendererFramework: AgCheckboxCellRendererComponent,
          cellRendererParams: this.getCheckBoxCellRenderParam(false, this.disableColumn('fixed')),
          pinnedRowCellRendererParams: this.getCheckBoxCellRenderParam(true, this.disableColumn('fixed'))
        });
    }
    if (this.hideColumns('innovate') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.us.innovate'] || 'Innovate', field: 'innovate', width: 85,
          editable: false, pinned: false, filter: false,
          pinnedRowCellRendererFramework: AgCheckboxCellRendererComponent,
          cellRendererFramework: AgCheckboxCellRendererComponent,
          cellRendererParams: this.getCheckBoxCellRenderParam(false, this.disableColumn('innovate')),
          pinnedRowCellRendererParams: this.getCheckBoxCellRenderParam(true, this.disableColumn('innovate'))
        });
    }
    // SM-2328 - hide discount and net
    // {
    //   headerName: this.userBundle['discount.us.discount'], field: "discount", width: 150, editable: true,
    //   cellRendererFramework: AgPercentageCellRendererComponent,
    //   cellRendererParams: {
    //     cellEditable: (data) => {
    //       return ((!data.isParent) && this.indexedFieldList[data.statusCategoryId].editable) ? true : false;
    //     },
    //     calcCellValue: this.calcAvgDiscount
    //   },
    //   pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
    //   pinnedRowCellRendererParams: {
    //     cellEditable: (data) => {
    //       return true;
    //     },
    //     calcCellValue: this.calcAvgDiscountPinnedRow,
    //     gridSumRow: 1
    //   },
    //   cellEditorFramework: AgTextCellEditorComponent,
    //   cellEditorParams: {
    //     onlyNumber: true,
    //     decimalPoints: 2,
    //     cellEditable: (data) => {
    //       if (data.pinnedRow == 1) {
    //         return true;
    //       } else {
    //         return ((!data.isParent) && this.indexedFieldList[data.statusCategoryId].editable) ? true : false;
    //       }
    //     },
    //     maxValue: 100
    //   }
    // },
    // {
    //   headerName: this.userBundle['discount.us.net'], field: "net", width: 150, editable: true,
    //   cellRendererFramework: AgCurrencyCellRendererComponent,
    //   cellRendererParams: {
    //     cellEditable: (data) => {
    //       return ((!data.isParent) && this.indexedFieldList[data.statusCategoryId].editable) ? true : false;
    //     }
    //   },
    //   pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
    //   pinnedRowCellRendererParams: {
    //     prefixText: (params) => {
    //       if (params['net'] === 0 || (params['net'] && params['net'] != "")) {
    //         return "";
    //       }
    //       return this.userBundle["discount.common.total"];
    //     },
    //     gridSumRow: 1
    //   },
    //   cellEditorFramework: AgTextCellEditorComponent,
    //   cellEditorParams: {
    //     onlyNumber: true,
    //     decimalPoints: 2,
    //     cellEditable: (data) => {
    //       if (data.pinnedRow == 1) {
    //         return true;
    //       } else {
    //         return ((!data.isParent) && this.indexedFieldList[data.statusCategoryId].editable) ? true : false;
    //       }
    //     }
    //   }
    // },

    if (this.hideColumns('actualStart') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.us.actualStart'] || 'Actual Start', field: 'actualStart', width: 150, editable: true,
          pinnedRowCellRendererFramework: AgDateCellRendererComponent,
          pinnedRowCellRendererParams: {
            cellEditable: () => {
              return !this.disableColumn('actualStart');
            },
            dateFormat: LocaleData.displayDateFormat
          },
          cellRendererFramework: AgDateCellRendererComponent,
          cellRendererParams: {
            cellEditable: (data) => {
              return (!data.isParent && !this.disableColumn('actualStart'));
            },
            dateFormat: LocaleData.displayDateFormat
          },
          cellEditorFramework: AgDateCellEditorComponent,
          cellEditorParams: {
            minDate: (params) => {
              return params.node.data.minDate ? new Date(params.node.data.minDate) : null;
            },
            maxDate: (params) => {
              return params.node.data.maxDate ? new Date(params.node.data.maxDate) : null;
            },
            onDateSelect: (params, newDate) => {
              params.node.data.actualStart = this.datePipe.transform(newDate, GLOBAL.DATE_PARSE_FORMAT);
            },
            cellEditable: (data) => {
              return (!data.isParent && !this.disableColumn('actualStart'));
            }
          }
        });
    }

    if (this.hideColumns('delivered') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.us.delivered'] || 'Delivered', field: 'delivered', width: 150, editable: true,
          pinnedRowCellRenderer: this.blankCellRenderer,
          cellRendererFramework: AgNumberCellRendererComponent,
          cellRendererParams: {
            cellEditable: (data) => {
              return (!data.isParent && !this.disableColumn('delivered'));
            },
            calcParentCellValue: () => {
              return '';
            },
          },

          cellEditorFramework: AgTextCellEditorComponent,
          cellEditorParams: {
            onlyNumber: true,
            decimalPoints: 0,
            cellEditable: (data) => {
              return (!data.isParent && data.pinnedRow !== 1 && !this.disableColumn('delivered'));
            },
            maxValue: 1
          }
        });
    }

    this.numericFields = [
      'delivered',
      // 'net',
      'rateCard',
      // 'discount'
      'impression',
      'remainingImpression',
      'purchasedImpression'
    ];

    this.dateRangeFields = [];
    this.selectFields = ['statusCategoryId'];
    this.processColDef();

    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField);
  }

  processColDef() {
    const decimalFieldsForFilter = [
      // 'net',
      'rateCard',
      // 'discount'
    ];
    for (let i = 2; i < this.columnDefs.length; i++) {
      if (this.columnDefs[i].field === 'statusCategoryId') {
        this.columnDefs[i].floatingFilterComponentFramework = AgSelectFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          suppressFilterButton: true,
          valKey: 'idStatusCategory',
          textKey: 'statusCategoryName',
          dataArr: () => {
            return this.statusCategory;
          },
          value: ''
        };
      } else {
        this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          value: '',
          suppressFilterButton: true,
          decimalRound: decimalFieldsForFilter.indexOf(this.columnDefs[i].field) > -1 ? 2 : null
        };
      }

      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };
      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => this.getCellClasses(params);
    }
  }

  getCellClasses(params) {
    const retArr: string[] = [];
    if (params.data.isParent || params.node.rowPinned === 'bottom') {
      retArr.push('fontBold');
    }
    if (params.node.rowPinned === 'bottom') {
      retArr.push('borderBottom');
    }
    if (params.data.isSelected) {
      retArr.push('selectedRow');
    }
    return retArr;
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
    // restore original data order when sorting is cleared
    if (this.sortObj.sortColumns.length === 1 && this.sortObj.sortColumns[0].colId === this.groupingField
      && this.sortObj.sortColumns[0].order === 'asc') {
      const tempRowData = [];
      this.rowData.forEach((row) => {
        if (row.isParent) {
          tempRowData.push(row);
          const childRows = this.gridData.discounts.filter(r => r[this.groupingField] === row[this.groupingField]);
          tempRowData.push(...childRows);
        }
      });
      this.rowData = _.clone(tempRowData);
    }
  }

  blankCellRenderer() {
    return '';
  }

  getCheckBoxCellRenderParam = (isPinnedRow, forceDisable) => {
    if (isPinnedRow) {
      return {
        cellEditable: () => {
          return !forceDisable;
        }
      };
    } else {
      return {
        cellEditable: (data) => {
          if (data.isParent || forceDisable) {
            return false;
          } else {
            return true;
          }
        },
        onSelectChange: (params) => {
          this.onCellEditingStopped(params);
        }
      };
    }
  }

  toggleColumnVisible(colId, isVisible) {
    const locIsVisible = !isVisible;
    this.gridOptions.columnApi.setColumnVisible(colId, locIsVisible);
  }

  calcAvgDiscount(rows) {
    const valueObj = { value1: 0, value2: 0 };
    rows.forEach((row) => {
      valueObj.value1 += parseFloat(row.rateCard);
      valueObj.value2 += parseFloat(row.net);
    });
    let value = 0;
    let divide = 0;
    if (valueObj.value2 !== 0 && valueObj.value1 !== 0) {
      divide = valueObj.value2 / valueObj.value1;
    }
    value = (100 * (1 - divide));
    return value.toFixed(2);
  }

  calcAvgDiscountPinnedRow = (rows) => {
    if (this.footerRowData) {
      if (this.footerRowData[0].discount === 0 || (this.footerRowData[0].discount && this.footerRowData[0].discount !== '')) {
        return this.footerRowData[0].discount;
      } else {
        return this.calcAvgDiscount(rows);
      }
    } else {
      return this.calcAvgDiscount(rows);
    }
  }

  calculateDiscount(rateCard, net) {
    const divide: number = (parseFloat(rateCard) !== 0) ? parseFloat(net) / parseFloat(rateCard) : 0;
    return (100 * (1 - divide)).toFixed(2);
  }
  /**
   * @description : Calculate the NET value.
   * @param {number} rateCard - RateCard value
   * @param {number} discount - value of discount
   * @returns Calculated net value
   */
  calculateNet(rateCard, discount) {
    return parseFloat((rateCard - (rateCard * (parseFloat(discount) / 100))).toFixed(2));
  }

  apply = () => {
    const selectedChildRows = this.getSelectedChildRows();
    if (selectedChildRows.length > 0) {
      if ((this.footerRowData[0].statusCategoryId || this.footerRowData[0].discount === 0
        || this.footerRowData[0].discount || this.footerRowData[0].net === 0 || this.footerRowData[0].net
        || this.footerRowData[0].actualStart || this.footerRowData[0].fixed != null || this.footerRowData[0].innovate != null)) {
        for (const row of selectedChildRows) {
          // SM-2328 - hide discount and net
          // if (this.footerRowData[0].discount === 0 || this.footerRowData[0].discount) {
          //   if (selectedChildRows[i].statusCategoryId == this.PAID_STATUS || selectedChildRows[i].statusCategoryId == this.DM_STATUS) {
          //     selectedChildRows[i].discount = this.footerRowData[0].discount;
          //     selectedChildRows[i].net = this.calculateNet(selectedChildRows[i].rateCard, selectedChildRows[i].discount);
          //   }
          // }

          if (this.footerRowData[0].statusCategoryId) {
            row.statusCategoryId = this.footerRowData[0].statusCategoryId;
          }

          if (this.footerRowData[0].fixed != null) {
            row.fixed = this.footerRowData[0].fixed;
          }

          if (this.footerRowData[0].innovate != null) {
            row.innovate = this.footerRowData[0].innovate;
          }

          if (this.footerRowData[0].actualStart) {
            row.actualStart = this.footerRowData[0].actualStart;
            row.delivered = 1;
          }
        }
        const childRows = this.getChildRows(null, false);
        this.fillGridCampaignDetails(childRows);
        this.resetApply();
        this.rowData = _.clone(this.rowData);
      }

      if (this.isActualStartDateChanged && !this.footerRowData[0].actualStart) {
        for (const row of selectedChildRows) {
          if (typeof this.footerRowData[0].actualStart === 'undefined'
            || this.footerRowData[0].actualStart === '' || this.footerRowData[0].actualStart === 0
            || this.footerRowData[0].actualStart === null) {
            row.actualStart = '';
            row.delivered = 0;
          }
        }
        const childRows = this.getChildRows(null, false);
        this.fillGridCampaignDetails(childRows);
        this.rowData = _.clone(this.rowData);
      }
    } else {
      this.toaster.logInfo('Select atleast one row!');
    }
  }

  spread = () => {
    const selectedChildRows = this.getSelectedChildRows();
    if (selectedChildRows.length > 0) {
      if ((this.footerRowData[0].net === 0 || this.footerRowData[0].net)) {
        let sumRateCards = 0;
        for (const row of selectedChildRows) {
          if (row.statusCategoryId === this.PAID_STATUS || row.statusCategoryId === this.DM_STATUS) {
            sumRateCards = sumRateCards + row.rateCard;
          }
        }
        for (const row of selectedChildRows) {
          if (row.statusCategoryId === this.PAID_STATUS || row.statusCategoryId === this.DM_STATUS) {
            if (sumRateCards !== 0) {
              row.net = parseFloat(((this.footerRowData[0].net * row.rateCard)
                / sumRateCards).toFixed(2));
            } else {
              row.net = 0;
            }
            row.discount = this.calculateDiscount(row.rateCard, row.net);
          }
        }
        const childRows = this.getChildRows(null, false);
        this.fillGridCampaignDetails(childRows);
        this.resetSpread();
        this.rowData = _.clone(this.rowData);
      }
    } else {
      this.toaster.logInfo('Select atleast one row!');
    }
  }

  reset = () => {
    this.resetApply();
    this.resetSpread();
    this.rowData = _.clone(this.rowData);
  }

  resetApply = () => {
    this.isActualStartDateChanged = false;
    const columnArray = ['statusCategoryId', 'discount', 'net', 'actualStart', 'fixed', 'innovate'];
    for (const column of columnArray) {
      this.footerRowData[0][column] = null;
    }
  }

  resetSpread = () => {
    this.footerRowData[0].net = null;
  }

  getTotalOfFieldsFromRows = (rows, colId) => {
    const sum = AgCustomRowUtil.sumFieldValues(rows, colId);
    return String(sum);
  }

  // Data functions
  getChildRows(groupingId?: string, filterOnly = true): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  getSelectedChildRows(groupingId?: string): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData, this.groupingField, groupingId);
  }

  getParentRows(groupingId?: string, data?: any[]): any[] {
    const newData = data || this.rowData;
    return AgCustomRowUtil.getParentRows(newData, this.groupingField, groupingId);
  }

  /**
   * download cart data in excel format
   */
  downloadExcel() {
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    // Add Header Row
    let sheetRow: ExcelCell[] = [];
    for (let j = 2; j < this.columnDefs.length; j++) {
      const cell: ExcelCell = {
        text: this.columnDefs[j].headerName,
        fontColor: '#FFFFFF',
        bgColor: '#000000',
        borderColor: '#CCCCCC',
        isFontBold: true
      };
      const col: ExcelColumn = {
        width: this.columnDefs[j].field === 'week' ? 15 : 12
      };
      excelColumn.push(col);
      sheetRow.push(cell);
    }
    sheetData.push(sheetRow);

    // Add Data rows
    let rowsInserted = 0;
    for (const row of this.rowData) {
      if (row.isParent) {
        continue;
      }

      sheetRow = [];
      sheetRow = this.getSheetRow(sheetRow, row, rowsInserted);
      rowsInserted++;
      sheetData.push(sheetRow);
    }
    const cartText = 'discount';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }

  getSheetRow(sheetRow: ExcelCell[], row: any[], rowsInserted: number) {
    for (let j = 2; j < this.columnDefs.length; j++) {
      let cellVal = row[this.columnDefs[j].field];
      if (this.columnDefs[j].field === 'actualStart' && cellVal) {
        cellVal = this.datePipe.transform((new Date(cellVal)), LocaleData.displayDateFormat);
      }
      if (this.selectFields.indexOf(this.columnDefs[j].field) > -1) {
        cellVal = SelectSupport.mapArrayData(this.columnDefs[j].cellRendererParams.dataArr(),
          this.columnDefs[j].cellRendererParams.valKey, this.columnDefs[j].cellRendererParams.textKey,
          row[this.columnDefs[j].field]);
      }
      const cell: ExcelCell = {
        text: cellVal,
        fontColor: '#000000',
        bgColor: rowsInserted % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
        borderColor: '#CCCCCC'
      };
      sheetRow.push(cell);
    }

    return sheetRow;
  }

  fillGridCampaignDetails(discountsData) {
    const noOfWeeks = _.uniq(discountsData.map((item) => {
      return item.week;
    })).length || 0;

    let rateCard = 0;
    let net = 0;

    discountsData.forEach((obj) => {
      rateCard += obj.rateCard;
      net += obj.net;
    });

    rateCard = parseFloat(rateCard.toFixed(2));
    net = parseFloat(net.toFixed(2));

    const divide = (net !== 0) ? (net / rateCard) : 0;
    const discount = parseFloat((100 * (1 - divide)).toFixed(2));

    this.gridCampaignDetails = {
      noOfWeeks,
      discount,
      rateCard,
      net,
      paid: discountsData.filter(item => parseInt(item.statusCategoryId, 10) === 1).length,
      GB: discountsData.filter(item => parseInt(item.statusCategoryId, 10) === 2).length,
      SAB: discountsData.filter(item => parseInt(item.statusCategoryId, 10) === 3).length
    };
  }

  trackByField(index, column) {
    return column?.colDef?.field;
  }
}
