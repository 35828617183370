import { Injectable } from '@angular/core';
import { DataShareService, SharedService } from '../../core/services';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable()

export class SmartSearchService {

  constructor(
    private dataShareService: DataShareService,
    private sharedService: SharedService
  ) { }

  getSavedCampaign(param) {
    const serviceURL = this.dataShareService.getServiceCallUrlByKey('SEARCH_CAMPAIGN_RESPONSE');
    return this.sharedService.makeServerCall(param, serviceURL);
  }
}
