import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';
import { DataShareService } from '../../services/data-share.service';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html'
})
export class SessionTimeoutComponent implements OnInit {
  /**
   * Whether component called due to server error
   * @type {boolean}
   * @memberof SessionTimeoutComponent
   */
  @Input() isServerError = false;

  /**
   * Initial config object
   * @type {*}
   * @memberof SessionTimeoutComponent
   */
  public initialConfig: any = {};

  /**
   * Creates an instance of SessionTimeoutComponent.
   * @param {DataShareService} dataShareService
   * @memberof SessionTimeoutComponent
   */
  constructor(
    private dataShareService: DataShareService,
  ) { }

  /**
   * Angular life cycle hook
   * @memberof SessionTimeoutComponent
   */
  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  /**
   * ON OK button click event
   * @memberof SessionTimeoutComponent
   */
  onOK() {
    location.assign(environment.landingAppUrl);
  }
}
