import { Injectable } from '@angular/core';
import { CartDetailColumnItem } from '../../models';

@Injectable()
export class CartDetailService {

  private defaultColumnList: CartDetailColumnItem[] = [
        { field: 'frameName', isVisible: true, pinned: 'left' },
        { field: 'startDate', isVisible: true, pinned: null },
        { field: 'endDate', isVisible: true, pinned: null },
        { field: 'sot', isVisible: true, pinned: null },
        { field: 'mediaName', isVisible: true, pinned: null },
        { field: 'productFormatName', isVisible: true, pinned: null },
        { field: 'townName', isVisible: true, pinned: null },
        { field: 'districtName', isVisible: true, pinned: null },
        { field: 'address', isVisible: true, pinned: null },
        { field: 'postcode', isVisible: true, pinned: null },
        { field: 'latitude', isVisible: true, pinned: null },
        { field: 'longitude', isVisible: true, pinned: null },
        { field: 'price', isVisible: true, pinned: null },
        { field: 'furnitureName', isVisible: false, pinned: null },
        { field: 'channelName', isVisible: false, pinned: null },
        { field: 'familyName', isVisible: false, pinned: null },
        { field: 'subFamilyName', isVisible: false, pinned: null },
        { field: 'frameTypeName', isVisible: false, pinned: null },
        { field: 'frameAltCode', isVisible: false, pinned: null },
        { field: 'unavailability', isVisible: false, pinned: null }
  ];

  getColumnList(): Promise<CartDetailColumnItem[]> {
    return new Promise((resolve) => {
      try {
        let colList: CartDetailColumnItem[] = [];
        const storedList = localStorage.getItem('cartColList');
        if (storedList) {
          colList = JSON.parse(storedList);
        } else {
          colList = this.defaultColumnList;
        }
        resolve(colList);
      } catch (err) {
        console.log('error in retrieving column list');
        resolve(this.defaultColumnList);
      }
    });
  }

  setColumnList(colList: CartDetailColumnItem[]): Promise<boolean> {
    return new Promise((resolve) => {
      try {
        localStorage.setItem('cartColList', JSON.stringify(colList));
        resolve(true);
      } catch (err) {
        console.log('error in saving column list');
        resolve(false);
      }
    });
  }
}
