<div ngDraggable [handle]="myHandle" [bounds]="bodyEle">
  <div class="savecampaign modal-header" style="background-color: #03a9f4 !important;">
    <h3 #myHandle class="modal-title">{{initialConfig.userBundle['geoplanner.cart.text.detail'] || 'Cart Detail'}}</h3>
    <button aria-label="Close" class="close" type="button" (click)="onClose()">x</button>
    <div class="top-btn-container">
      <div ngbDropdown [autoClose]="false" class="form-control_geo column_dropdown dropdown" #toggleColumnContainer>
        <button class="btn btn_header btn-cart-detail-column" id="dropdownBasic1" ngbDropdownToggle>
          <em class="fa fa-th"></em> {{initialConfig.userBundle['geoplanner.text.column'] || 'Column'}}</button>
        <div class="dropdown-menu" aria-labelledby="dropdownBasic1" ngbDropdownMenu>
          <div class="clearBoth" *ngFor="let column of columnDefs; trackBy: trackByField;">
            <label class="center-block small" *ngIf="column.field != 'selected_cart' && column.field != 'frameName'">
              <input type="checkbox" class="checkbox small" [checked]="column.isVisible" [(ngModel)]="column.isVisible" (change)="toggleColumnVisible(column.field, column.isVisible)"
                name="check-channel" id="channel-{{column.field}}" />
              <span>{{column.headerName}}</span>
            </label>
          </div>
        </div>
        <button class="btn btn_header" (click)="downloadExcel()">
          <em class="fa fa-download"></em> {{initialConfig.userBundle['geoplanner.text.export'] || 'Export'}}</button>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div style="padding: 10px 15px 5px 15px;">
      <ag-grid-angular style="height: 300px;width:99%;" class="ag-theme-fresh cart-detail-grid" [gridOptions]="gridOptions" [rowData]="cartData" [columnDefs]="columnDefs">
      </ag-grid-angular>
    </div>
  </div>
  <div class="modal-footer container" [ngClass]="{'readOnly': systemFlags.readOnlyWorkspace && !systemFlags.splitable }" style="float: none; max-width: 100%;">
    <button class="btn white pull-right" (click)="onSave()">{{initialConfig.userBundle['common.update']}}</button>
    <button class="btn gray pull-left" (click)="onClose()">{{initialConfig.userBundle['common.cancel']}}</button>
  </div>
</div>
