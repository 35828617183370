<div class="headerContainer" (click)="headerClick()">
  <div class="headerText">
    {{params.displayName}}
    <span *ngIf="isColumnSorted()">
      <span *ngIf="sortOrder=='asc'">
        <em class="fa fa-sort-asc"></em>
      </span>
      <span *ngIf="sortOrder=='desc'">
        <em class="fa fa-sort-desc"></em>
      </span>
      <span *ngIf="sortIndex>0">{{sortIndex}}</span>
    </span>
  </div>
</div>
<div class="menuIcon" (click)="menuIconClick($event)">
  <em class="fa fa-angle-down"></em>
</div>