import { Injectable } from '@angular/core';
import { HistoryStack } from './history-stack';
import { Filter } from '../../models';
import { HistoryStackData } from '../../models/workspace/historyStackData';
import * as _ from 'lodash';

@Injectable()
export class HistoryStackService {
  historyStack = new HistoryStack();
  undonehistoryStack = new HistoryStack();
  historyStackObjective = new HistoryStack();
  undonehistoryStackObjective = new HistoryStack();
  historyStackPCM = new HistoryStack();
  undonehistoryStackPCM = new HistoryStack();
  historyStackColumnLineNumber = new HistoryStack();
  undoHistoryStackColumnLineNumber = new HistoryStack();
  historyStackColumnHelper = new HistoryStack();
  undoHistoryStackColumnHelper = new HistoryStack();

  constructor() { }

  /**
   * @description Clear the redo stack when Pushed something new into Undo Stack (eg: Change something in Workspace area)
   * @author Shivani Patel
   * @param {HistoryStackData} item
   * @memberof HistoryStackService
   */
  pushInHistoryStack(item: HistoryStackData) {
    this.historyStack.push(item);
    this.undonehistoryStack.resetAll();
  }

  /**
   * @description Push the item into Undo stack.
   * Used when we do Undo then Redo (eg: To push into Undo stack from Redo stack)
   * @author Shivani Patel
   * @param {HistoryStackData} item
   * @memberof HistoryStackService
   */
  pushInHistoryStackRedo(item: HistoryStackData) {
    this.historyStack.push(item);
  }

  /**
   * @description Pop the item from Undo stack
   * @author Shivani Patel
   * @param {Filter} freshFilter
   * @returns {Filter}
   * @memberof HistoryStackService
   */
  popFromHistoryStack(freshFilter: Filter): Filter {
    const historyStackData = this.historyStack.pop();
    if (historyStackData) {
      return Object.assign(freshFilter, historyStackData);
    }
  }

  /**
   * @description Push the item into Redo stack
   * @author Shivani Patel
   * @param {HistoryStackData} item
   * @memberof HistoryStackService
   */
  pushInUndoneHistoryStack(item: HistoryStackData) {
    this.undonehistoryStack.push(item);
  }

  /**
   * @description Pop the item from Redo stack
   * @author Shivani Patel
   * @param {StateService} stateService
   * @returns {Filter}
   * @memberof HistoryStackService
   */
  popFromUndoneHistoryStack(freshFilter: Filter): Filter {
    const historyStackData = this.undonehistoryStack.pop();
    if (historyStackData) {
      return Object.assign(freshFilter, historyStackData);
    }
  }

  /**
   * PCM
   */
  pushInHistoryStackRedoPCM(item: HistoryStackData) {
    this.historyStackPCM.push(item);
  }
  pushInHistoryStackPCM(item: HistoryStackData) {
    this.historyStackPCM.push(item);
    this.undonehistoryStackPCM.resetAll();
  }
  popFromHistoryStackPCM(freshFilter: Filter): Filter {
    const historyStackData = this.historyStackPCM.pop();
    if (historyStackData) {
      return Object.assign(freshFilter, historyStackData);
    }
  }
  pushInUndoneHistoryStackPCM(item: HistoryStackData) {
    this.undonehistoryStackPCM.push(item);
  }
  popFromUndoneHistoryStackPCM(freshFilter: Filter): Filter {
    const historyStackData = this.undonehistoryStackPCM.pop();
    if (historyStackData) {
      return Object.assign(freshFilter, historyStackData);
    }
  }

  /**
   * ColumnLineNumber
   */
  pushColumnLineNumberInHistoryStack(columnLineNumber) {
    this.historyStackColumnLineNumber.push(columnLineNumber);
    this.undoHistoryStackColumnLineNumber.resetAll();
  }
  pushColumnLineNumberInHistoryStackRedo(columnLineNumber) {
    this.historyStackColumnLineNumber.push(columnLineNumber);
  }

  popColumnLineNumberInHistoryStack() {
    return this.historyStackColumnLineNumber.pop();
  }

  pushInUndoColumnLineNumberHistoryStack(columnLineNumber) {
    this.undoHistoryStackColumnLineNumber.push(columnLineNumber);
  }

  popFromUndoColumnLineNumberHistoryStack() {
    return this.undoHistoryStackColumnLineNumber.pop();
  }

  /**
   * ColumnHelper
   */
  pushColumnHelperInHistoryStack(param) {
    this.historyStackColumnHelper.push(_.clone(param));
    this.undoHistoryStackColumnHelper.resetAll();
  }
  pushColumnHelperInHistoryStackRedo(param) {
    this.historyStackColumnHelper.push(_.clone(param));
  }

  popColumnHelperInHistoryStack() {
    return this.historyStackColumnHelper.pop();
  }

  pushInUndoColumnHelperHistoryStack(param) {
    this.undoHistoryStackColumnHelper.push(_.clone(param));
  }

  popFromUndoColumnHelperHistoryStack() {
    return this.undoHistoryStackColumnHelper.pop();
  }

  pushInHistoryStackRedoObjective(item: HistoryStackData) {
    this.historyStackObjective.push(item);
  }
  pushInHistoryStackObjective(item: HistoryStackData) {
    this.historyStackObjective.push(item);
    this.undonehistoryStackObjective.resetAll();
  }
  popFromHistoryStackObjective(freshFilter: Filter): Filter {
    const historyStackData = this.historyStackObjective.pop();
    if (historyStackData) {
      return Object.assign(freshFilter, historyStackData);
    }
  }
  pushInUndoneHistoryStackObjective(item: HistoryStackData) {
    this.undonehistoryStackObjective.push(item);
  }
  popFromUndoneHistoryStackObjective(freshFilter: Filter): Filter {
    const historyStackData = this.undonehistoryStackObjective.pop();
    if (historyStackData) {
      return Object.assign(freshFilter, historyStackData);
    }
  }
  /**
   * @description Reset HistoryStack
   * @memberof HistoryStackService
   */
  resetHistoryStack() {
    this.historyStack.resetAll();
    this.undonehistoryStack.resetAll();
    this.historyStackObjective.resetAll();
    this.undonehistoryStackObjective.resetAll();
    this.historyStackPCM.resetAll();
    this.undonehistoryStackPCM.resetAll();
    this.historyStackColumnLineNumber.resetAll();
    this.undoHistoryStackColumnLineNumber.resetAll();
    this.historyStackColumnHelper.resetAll();
    this.undoHistoryStackColumnHelper.resetAll();
  }

  /**
   * @description Reset HistoryStack For PCM only
   * @memberof HistoryStackService
   */
  resetHistoryStackForPCM() {
    this.historyStackPCM.resetAll();
    this.undonehistoryStackPCM.resetAll();
  }
}
