<!-- <input #input [(ngModel)]="value" style="width: 100%;height: 100%;" readonly> -->
<input type="text" #input="ngbDatepicker" style="width: 100%;height: 100%;"
  (ngModelChange)="onDateSelect($event)" [(ngModel)]="value" [dayTemplate]="calendarDayTemplate"
  [minDate]="minDate" [maxDate]="maxDate" ngbDatepicker [container]="'body'" [markDisabled]="markDayDisabled"
  (click)="openDatePicker($event, input)" (document:click)="closeDatePickerOutsideClick($event, input)" [outsideDays]="'collapsed'"
  readonly>

<ng-template #calendarDayTemplate let-date="date" let-currentMonth="currentMonth" let-selected="selected"
  let-disabled="disabled">
  <span class="day-container" [class.hidden]="date.month !== currentMonth"
    [class.text-muted]="getStartDayProp(date, disabled, 'isDisabled')">
    <div class="custom-day" [class.today]="getStartDayProp(date, disabled, 'isToday')"
      [class.weekend]="getStartDayProp(date, disabled, 'isWeekday')" [class.selected]="selected">
      {{ date.day }}
    </div>
  </span>
</ng-template>