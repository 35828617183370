import { Component, ViewChild, ElementRef, Input, OnInit, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ExcelCell, ExportToExcel, ExcelColumn } from '../../../core/utils/exportToExcel';
import { GridOptions, ColDef } from 'ag-grid-community';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { DataShareService } from '../../../core/services/data-share.service';
import { SelectSupport } from '../ag-grid-support/utils/SelectSupport';

import {
  AgCheckboxComponent,
  AgCustomFilterMenuComponent,
  AgTextFloatingFilterComponent,
  AgSelectFloatingFilterComponent,
  AgCustomHeaderComponent,
  AgSelectCellEditorComponent,
  AgNumberCellRendererComponent,
  AgCurrencyCellRendererComponent,
  AgSelectCellRendererComponent,
  AgDateCellRendererComponent,
  AgCustomSort,
  AgCustomRowUtil
} from '../ag-grid-support/index';

declare const _: any;

import * as toasterConfig from '../../../core/toasterConfig';
import { LocaleData } from '../../../core/utils/LocaleData';

@Component({
  selector: 'app-discount-grid-singapore',
  templateUrl: './discount-grid-singapore.component.html',
  styleUrls: ['../ag-grid-custom-style.css', '../ag-grid-col-select-style.css', './discount-grid-singapore.component.css']
})
export class DiscountGridSingaporeComponent implements OnInit {

  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;
  @Input() config: any;
  uiControl: any;
  @Input() commercialData;

  gridData: any = {};
  /**
   * Ag-grid Data column sort object
   */
  sortObj: AgCustomSort;

  /**
   * Ag-Grid Options Object
   */
  gridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  columnDefs: ColDef[];

  /**
   * column list to show in show/hide column dropdown
   */
  columnList: any = [];

  /**
   * list of Cart Data
   * @memberof CartDetailComponent
   */
  rowData: any[] = [];

  /**
   * Grid footer data
   */
  footerRowData: any[];

  /**
   * user bundle data
   */
  userBundle: any = {};

  statusCategory = [];

  numericFields = [];
  dateRangeFields = [];
  selectFields = [];
  groupingField: string = null;
  public toasterConfig: any = toasterConfig.toasterconfig;

  constructor(private dataShareService: DataShareService,
              private toaster: LogHelperService,
              private datePipe: DatePipe) {

  }

  ngOnInit(): void {
    this.gridData = this.commercialData.bookingDetails;
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.uiControl = this.commercialData.uiControl;
    this.statusCategory = this.commercialData.statusCategory;

    this.setFooterRows();
    this.prepareRowData();
    this.prepareColDef();

    this.reset();

    // we pass an empty gridOptions in, so we can grab the api out
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      onRowDataChanged: (event) => {
        event.api.setPinnedBottomRowData(this.footerRowData);
      },
      scrollbarWidth: 10,
      onGridReady: this.onGridReady,
      getRowHeight: this.getRowHeight,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      onFilterChanged: this.onFilterChanged,
      suppressScrollOnNewData: true,
      onCellEditingStopped: this.onCellEditingStopped,
      groupingField: this.groupingField,
      suppressMovableColumns: true,
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: node => node.data.isVisible
    } as GridOptions;
  }

  onGridReady = () => {
    this.columnList = this.gridOptions.columnApi.getAllColumns();
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else if (params.data.isVisible) {
      height = 30;
    }
    return height;
  }

  isFullWidthCell = (rowNode): boolean => {
    return rowNode.data.gridSummary;
  }

  weekPinnedRowCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {
      // apply Icon
      const eImg: HTMLImageElement = document.createElement('img');
      eImg.src = 'images/general/apply.png';
      eImg.title = 'Apply';
      eImg.onclick = this.apply;
      eDiv.appendChild(eImg);
    }
    return eDiv;
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.forEach((r) => {
        r.isFilterPass = true;
      });
    }
    // Refresh Grid Header checkbox component
    /* tslint:disable:no-string-literal */
    this.gridOptions.api['headerRootComp'].forEachHeaderElement((ev: any) => {
      if (ev.column.colId === 'selectChk' && ev.columnApi && ev.childComponents && ev.childComponents.length > 1) {
        const headerCmpIns = ev.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  clearAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    this.gridOptions.api.onFilterChanged();
  }

  onCellEditingStopped = (event) => {
    if (event.rowPinned !== 'bottom') {
      this.rowData = _.clone(this.rowData);
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen() && $event.button !== 2 && !dropdownEle._isEventFromToggle($event)
    && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
      dropdownEle.close();
    }
  }

  prepareRowData() {
    this.rowData = [];
    for (const curRow of this.gridData) {
      curRow.bookingReference = curRow.bookingReference ? curRow.bookingReference : '';
      curRow.isFilterPass = true;
      curRow.isParent = false;
      curRow.isVisible = true;
      curRow.isSelected = false;
      this.rowData.push(curRow);
    }
  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinnedRow: 1 },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
  }

  getGridSummary() {
    return `<span>${this.userBundle['discount.common.totalFrames']} : ${this.getChildRows(null, false).length} &#45;
     ${this.userBundle['discount.common.selectedFrames']} : ${this.getSelectedChildRows(null, false).length}</span>`;
  }

  hideColumns(key: string): boolean {
    let show = false;

    if (this.uiControl && this.uiControl.hiddenColumns) {
      show = this.uiControl.hiddenColumns.indexOf(key) !== -1;
    }

    return show;
  }

  disableColumn(key: string): boolean {
    let disable = false;

    if (this.uiControl && this.uiControl.disabledColumns) {
      disable = this.uiControl.disabledColumns.indexOf(key) !== -1;
    }

    return disable;
  }

  // tslint:disable-next-line: no-big-function
  prepareColDef() {
    this.columnDefs = [];

    this.columnDefs.push(
      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false, colId: 'selectChk',
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: (params): string[] => {
          const retArr: string[] = [];
          if (params.data.isSelected) {
            retArr.push('selectedRow');
          }
          if (params.node.rowPinned === 'bottom') {
            retArr.push('borderBottom');
          }
          return retArr;
        }
      });

    if (this.hideColumns('booking_ref') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['booking.grid.bookingReference'], field: 'bookingReference', width: 130, editable: false,
          pinnedRowCellRenderer: this.weekPinnedRowCellRenderer
        }
      );
    }

    if (this.hideColumns('prod_name') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['workspace.pcm.productCatalogueName'], field: 'productCatalogueName', width: 200, editable: false,
          pinnedRowCellRenderer: this.blankCellRenderer
        });
    }
    if (this.hideColumns('networkName') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.sg.networkName'], field: 'networkName', width: 200, editable: false,
          pinnedRowCellRenderer: this.blankCellRenderer
        });
    }
    this.columnDefs.push(
      {
        headerName: this.userBundle['discount.sg.channelName'], field: 'channelName', width: 135, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer
      });
    if (this.hideColumns('productName') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.sg.productName'], field: 'productName', width: 150, editable: false,
          pinnedRowCellRenderer: this.blankCellRenderer,
        });
    }
    if (this.hideColumns('invoiceTypeName') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.sg.invoiceTypeName'], field: 'invoiceTypeName', width: 150, editable: false,
          pinnedRowCellRenderer: this.blankCellRenderer,
        });
    }
    if (this.hideColumns('startDate') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.sg.startDate'], field: 'startDate', width: 150, editable: false,
          cellRendererFramework: AgDateCellRendererComponent,
          cellRendererParams: {
            dateFormat: LocaleData.displayDateFormat
          },
          pinnedRowCellRenderer: this.blankCellRenderer,
        });
    }
    if (this.hideColumns('endDate') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.sg.endDate'], field: 'endDate', width: 150, editable: false,
          cellRendererFramework: AgDateCellRendererComponent,
          cellRendererParams: {
            dateFormat: LocaleData.displayDateFormat
          },
          pinnedRowCellRenderer: this.blankCellRenderer,
        });
    }
    if (this.hideColumns('numberOfFrames') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.sg.numberOfFrames'], field: 'numberOfFrames', width: 150, editable: false,
          cellRendererFramework: AgNumberCellRendererComponent,
          pinnedRowCellRendererFramework: AgNumberCellRendererComponent
        });
    }
    if (this.hideColumns('grossPrice') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.sg.grossPrice'], field: 'grossPrice', width: 200, editable: false,
          cellRendererFramework: AgCurrencyCellRendererComponent,
          pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
          pinnedRowCellRendererParams: {
            prefixText: () => {
              return this.userBundle['discount.common.total'];
            },
            gridSumRow: 1
          }
        });
    }
    if (this.hideColumns('rateType') === false) {
      this.columnDefs.push(
        {
          headerName: this.userBundle['discount.sg.statusCategoryId'], field: 'statusCategoryId', width: 150, editable: true, pinned: false,
          cellRendererFramework: AgSelectCellRendererComponent,
          cellRendererParams: {
            cellEditable: () => {
              return !this.disableColumn('rateType');
            },
            calcParentCellValue: () => {
              return '';
            },
            valKey: 'idStatusCategory',
            textKey: 'statusCategoryName',
            dataArr: () => {
              return this.statusCategory;
            }
          },
          pinnedRowCellRendererFramework: AgSelectCellRendererComponent,
          pinnedRowCellRendererParams: {
            cellEditable: () => {
              return true;
            },
            valKey: 'idStatusCategory',
            textKey: 'statusCategoryName',
            dataArr: () => {
              return this.statusCategory;
            }
          },
          cellEditorFramework: AgSelectCellEditorComponent,
          cellEditorParams: {
            valKey: 'idStatusCategory',
            textKey: 'statusCategoryName',
            dataArr: () => {
              return this.statusCategory;
            },
            cellEditable: () => {
              return (!this.disableColumn('rateType'));
            }
          }
        });
    }

    this.numericFields = [
      'grossPrice',
      'numberOfFrames'
    ];

    this.dateRangeFields = [];
    this.selectFields = ['statusCategoryId'];
    this.prepareColDefs();

    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField);
  }

  prepareColDefs() {
    const decimalFieldsForFilter = [
      'grossPrice'
    ];
    for (let i = 1; i < this.columnDefs.length; i++) {
      if (this.columnDefs[i].field === 'statusCategoryId') {
        this.columnDefs[i].floatingFilterComponentFramework = AgSelectFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          suppressFilterButton: true,
          valKey: 'idStatusCategory',
          textKey: 'statusCategoryName',
          dataArr: () => {
            return this.statusCategory;
          },
          value: ''
        };
      } else {
        this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          value: '',
          suppressFilterButton: true,
          decimalRound: decimalFieldsForFilter.indexOf(this.columnDefs[i].field) > -1 ? 2 : null
        };
      }

      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };
      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => this.getArr(params);
    }
  }

  getArr(params) {
    const retArr: string[] = [];
    if (params.data.isParent || params.node.rowPinned === 'bottom') {
      retArr.push('fontBold');
    }
    if (params.node.rowPinned === 'bottom') {
      retArr.push('borderBottom');
    }
    if (params.data.isSelected) {
      retArr.push('selectedRow');
    }
    return retArr;
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
    // restore original data order when sorting is cleared
    if (this.sortObj.sortColumns.length === 0) {
      this.rowData = _.clone(this.gridData);
    }
  }

  blankCellRenderer() {
    return '';
  }

  toggleColumnVisible(colId, isVisible) {
    const locIsVisible = !isVisible;
    this.gridOptions.columnApi.setColumnVisible(colId, locIsVisible);
  }

  apply = () => {
    const selectedChildRows = this.getSelectedChildRows();
    if (selectedChildRows.length > 0) {
      if (this.footerRowData[0].statusCategoryId) {
        for (const row of selectedChildRows) {
          if (this.footerRowData[0].statusCategoryId) {
            row.statusCategoryId = this.footerRowData[0].statusCategoryId;
          }
        }
        this.resetApply();
        this.rowData = _.clone(this.rowData);
      }
    } else {
      this.toaster.logInfo('Select atleast one row!');
    }
  }

  reset = () => {
    this.resetApply();
    this.rowData = _.clone(this.rowData);
  }

  resetApply = () => {
    const columnArray = ['statusCategoryId'];
    for (const column of columnArray) {
      this.footerRowData[0][column] = null;
    }
  }

  // Data functions
  getChildRows(groupingId?: string, filterOnly = true): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  getSelectedChildRows(groupingId?: string, filterOnly = true): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  /**
   * download grid data in excel format
   */
  downloadExcel() {
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    // Add Header Row
    let sheetRow: ExcelCell[] = [];
    for (let j = 1; j < this.columnDefs.length; j++) {
      const cell: ExcelCell = {
        text: this.columnDefs[j].headerName,
        fontColor: '#FFFFFF',
        bgColor: '#000000',
        borderColor: '#CCCCCC',
        isFontBold: true
      };
      const col: ExcelColumn = {
        width: 12
      };
      excelColumn.push(col);
      sheetRow.push(cell);
    }
    sheetData.push(sheetRow);

    // Add Data rows
    let rowsInserted = 0;
    for (const row of this.rowData) {
      if (row.isParent) {
        continue;
      }

      sheetRow = [];
      sheetRow = this.prepareSheetRow(sheetRow, row, rowsInserted);
      rowsInserted++;
      sheetData.push(sheetRow);
    }
    const cartText = 'discount';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }

  prepareSheetRow(sheetRow: ExcelCell[], row: any[], rowsInserted: number) {
    for (let j = 1; j < this.columnDefs.length; j++) {
      let cellVal = row[this.columnDefs[j].field];
      if ((this.columnDefs[j].field === 'startDate' || this.columnDefs[j].field === 'endDate') && cellVal) {
        cellVal = this.datePipe.transform((new Date(cellVal)), LocaleData.displayDateFormat);
      }
      if (this.selectFields.indexOf(this.columnDefs[j].field) > -1) {
        cellVal = SelectSupport.mapArrayData(this.columnDefs[j].cellRendererParams.dataArr(),
          this.columnDefs[j].cellRendererParams.valKey, this.columnDefs[j].cellRendererParams.textKey,
          row[this.columnDefs[j].field]);
      }
      const cell: ExcelCell = {
        text: cellVal,
        fontColor: '#000000',
        bgColor: rowsInserted % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
        borderColor: '#CCCCCC'
      };
      sheetRow.push(cell);
    }

    return sheetRow;
  }

  trackByField(index, column) {
    return column?.colDef?.field;
  }
}
