import { CellBaseModal } from './cell-base';

export class TagWindow extends CellBaseModal {
  selectedTag; // TODO: Need to define type
}

export class TagModel {
  tagId: number;
  tagName: string;
  inUse: boolean;
  exclude?: boolean;
  isDisabled?: boolean;
}

export class TagGroupModel {
  tagGroupId: number;
  tagGroupName: string;
  inUse: boolean;
  tag: TagModel[];
}
