/**
 * @description get random number
 * @author Nikunj Gadhiya
 * @export
 * @returns {number} - randome number
 */
export function mathRandomNumber(): number {
    const crypto = window.crypto;
    const array = new Uint32Array(1);
    return crypto.getRandomValues(array)[0];
}
