export class JqScale {

  static jqscale = (div, option, independentScaleY = null) => {
    const thisScaler = div;
    let action = 'getScale';
    let scaleX;
    let scaleY;
    if (typeof option === 'number') {
      action = 'setScale';
      scaleX = option;
      scaleY = (typeof independentScaleY === 'number') ? independentScaleY : option;
    } else if (typeof option === 'boolean' && option === true) {
      action = 'calculateScale';
    }
    thisScaler.setScale = (element) => {
      const matrix = thisScaler.getElementTransformMatrix(element);
      matrix[0] = scaleX;
      matrix[3] = scaleY;
      element.style.transform = 'matrix(' + matrix.toString(',') + ')';
      element.setAttribute('data-jqscale', scaleX + ',' + scaleY);
      const x = element.clientWidth / 2;
      element.style.transformOrigin = x + 'px 0';



    };
    thisScaler.getScale = (element) => {
      const matrix = thisScaler.getElementTransformMatrix(element);
      element.setAttribute('data-jqscale', matrix[0] + ',' + matrix[3]);
      return [matrix[0], matrix[3]];
    };
    thisScaler.calculateScale = (element) => {
      const parents = thisScaler.parents(element);
      const parentCount = parents.length;
      let aggregatedScaleX = 1;
      let aggregatedScaleY = 1;
      let elementScales = thisScaler.getScale(element);
      aggregatedScaleX = aggregatedScaleX * elementScales[0];
      aggregatedScaleY = aggregatedScaleX * elementScales[1];
      for (let i = 0; i < parentCount; i++) {
        elementScales = thisScaler.getScale(parents[i]);
        aggregatedScaleX = aggregatedScaleX * elementScales[0];
        aggregatedScaleY = aggregatedScaleX * elementScales[1];
      }
      element.setAttribute('data-aggregated-jqscale', aggregatedScaleX + ',' + aggregatedScaleY);
      return [aggregatedScaleX, aggregatedScaleY];
    };
    thisScaler.getElementTransformMatrix = (element) => {
      const matrixArray = [];
      let matrixStrings = element.style.transform;
      if (matrixStrings === null) {
        matrixStrings = element.style.webkitTransform;
      }
      if (typeof matrixStrings === 'undefined' || matrixStrings === null || matrixStrings.indexOf('matrix') !== 0) {
        return [1, 0, 0, 1, 0, 0];
      }
      matrixStrings = matrixStrings.split('(')[1].split(')')[0].split(',');
      const matrixStringCount = matrixStrings.length;
      for (let i = 0; i < matrixStringCount; i++) {
        const value = (isNaN(Number(matrixStrings[i]))) ? 0 : Number(matrixStrings[i]);
        matrixArray.push(value);
      }
      return matrixArray;
    };
    thisScaler.parents = (node) => {
      let current = node;
      const list = [];
      while (current.parentNode != null && current.parentNode !== document.documentElement) {
        list.push(current.parentNode);
        current = current.parentNode;
      }
      return list;
    };

    return thisScaler[action](div);
  }
}
