import { Component, OnInit, Input } from '@angular/core';
import { DataShareService } from '../../../core/services/data-share.service';
import { InitialConfigModel } from '../../../models/initial-config.model';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { GoogleAnalyticsEvents, GoogleAnalyticsEventCategory } from '../../../GoogleAnalytics/GoogleAnalyticsEvent';

@Component({
  selector: 'app-advance-availability',
  templateUrl: './advance-availability.component.html'
})

export class AdvanceAvailabilityComponent implements OnInit {
  /**
   *
   * pass params from main workspace componenet
   * @memberof AdvanceAvailabilityComponent
   */
  @Input() resolveObject;
  /**
   * inital config Model
   *
   * @type {InitialConfigModel}
   * @memberof AdvanceAvailabilityComponent
   */
  public initialConfig: InitialConfigModel;
  /**
   * set brick color value
   *
   * @memberof AdvanceAvailabilityComponent
   */
  brickBgColor = '#ef3f3b';
  /**
   * external List store
   *
   * @memberof AdvanceAvailabilityComponent
   */
  externalList = [];

  /**
   *
   *
   * @memberof AdvanceAvailabilityComponent
   */
  advanceAvailability = {
    exportType: 1,
    exportFormatInternal: null,
    exportFormatExternal: []
  };
  /**
   * set selected tab active
   *
   * @memberof AdvanceAvailabilityComponent
   */
  selectedItem = 1;
  constructor(dataShareService: DataShareService,
              private logHelperService: LogHelperService) {
    this.initialConfig = dataShareService.getInitialConfig();
  }

  ngOnInit(): void {
    this.externalList = [{
      id: 1,
      text: this.initialConfig.userBundle['workspace.advancedAvailability.label.kinetic']
    },
    {
      id: 2,
      text: this.initialConfig.userBundle['workspace.advancedAvailability.label.posterscope']
    },
    {
      id: 3,
      text: this.initialConfig.userBundle['workspace.advancedAvailability.label.rapport']
    },
    {
      id: 4,
      text: this.initialConfig.userBundle['workspace.advancedAvailability.label.talon']
    },
    {
      id: 5,
      text: this.initialConfig.userBundle['workspace.advancedAvailability.label.zone']
    },
    {
      id: 6,
      text: this.initialConfig.userBundle['workspace.advancedAvailability.label.atlas']
    },
    {
      id: 7,
      text: this.initialConfig.userBundle['workspace.advancedAvailability.label.linebyline']
    }
    ];

    if (this.resolveObject && this.resolveObject.selectedValue) {
      this.advanceAvailability = this.resolveObject.selectedValue;
      this.advanceAvailability.exportType = 1;
    } else {
      this.advanceAvailability = {
        exportType: 1,
        exportFormatInternal: null,
        exportFormatExternal: []
      };
    }
  }

  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }

  onModalSaved(event) {
    this.sendGAEvent(this.advanceAvailability);
    if (this.advanceAvailability.exportType === 1) {
      this.advanceAvailability.exportFormatExternal = [];
    } else if (this.advanceAvailability.exportType === 2) {
      this.advanceAvailability.exportFormatInternal = null;
    }

    if (this.advanceAvailability.exportFormatInternal || this.advanceAvailability.exportFormatExternal.length > 0) {
      event.activeModal.close(this.advanceAvailability);
    } else {
      this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.advanceavailability.noselection']);
    }

  }

  internalFormat(exportType) {
    if (exportType) {
      this.advanceAvailability.exportType = exportType;
    }
    this.selectedItem = exportType;
  }

  sendGAEvent(advanceAvailability) {
    let actionName = '';
    if (advanceAvailability.exportType === 1) {
      actionName = advanceAvailability.exportFormatInternal === '1' ? 'Line By Line' : 'Atlas';
      GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Availability,
        actionName, 'DownLoad Availability');
    } else {
      advanceAvailability.exportFormatExternal.forEach((element) => {
        actionName = '';
        actionName = this.externalList.find(x => x.id === element.id).text;
      });
      GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Availability,
        actionName, 'DownLoad Availability');
    }

  }

  trackById(index, item) {
    return item?.id;
  }
}
