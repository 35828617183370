import { Component, OnInit, ViewChild } from '@angular/core';
import { CampaignDetailsService } from '../campaign-details/campaign-details.service';
import { HistoryROC } from '../commercial.model';
import { SystemFlags } from '../../models';
import { DataShareService, LogHelperService } from '../../core/services';
import { StateService } from '../../../app/core/services/state.service';
import { CommercialService } from '../../core/services/commercial.service';
import { CommercialButtonService } from '../commercial-buttons/commercial-buttons.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { GLOBAL } from '../../core/utils/app.constant';
import { CommercialBase } from '../commercial-base';
import { RequiredCampaignFields, RequiredBookingsFields, PopulateBookingDetailFields, PopulateCommercialFields } from '../../models/RequiredFields';
import { CommercialButtonsComponent } from '../commercial-buttons/commercial-buttons.component';
import { DiscountGridBalComponent } from '../discount-grid/discount-grid-bal/discount-grid-bal.component';
@Component({
  selector: 'app-commercial-bal',
  templateUrl: './commercial-bal.component.html',
  styleUrls: ['./commercial-bal.component.css']
})
export class CommercialBalComponent extends CommercialBase implements OnInit {
  @ViewChild(CommercialButtonsComponent) commercialButtonsComponent: CommercialButtonsComponent;
  @ViewChild(DiscountGridBalComponent) discountGridBalComponent: DiscountGridBalComponent;
  systemFlags = SystemFlags;

  /**
   * @description commercial data
   * @memberof CommercialBalComponent
   */
  public commercialData;

  /**
   * @description discount grid data
   * @memberof CommercialBalComponent
   */
  public discountGridData;

  /**
   * @description Accordian for campaign details
   * @memberof CommercialBalComponent
   */
  hideCampaignDetails: boolean;

  /**
   * @description Accordian for booking details
   * @memberof CommercialBalComponent
   */
  hideBookingDetails: boolean;

  /**
   * @description Accordian for discount grid
   * @type {boolean}
   * @memberof CommercialBalComponent
   */
  hideDiscountGrid : boolean;

  /**
   * @description set bookingStatusList
   * @memberof CommercialBalComponent
   */
  bookingStatusList = GLOBAL.bookingStatusList;

  /**
   * @description this will show the history of campaign updated in case of reason/cancel for change
   * @memberof CommercialBalComponent
   */
  historyROC = new HistoryROC();

  shouldZIndexApply = false;

  constructor(
    campaignDetailsService: CampaignDetailsService,
    dataShareService: DataShareService,
    commercialService: CommercialService,
    stateService: StateService,
    commercialButtonService: CommercialButtonService,
    logHelperService: LogHelperService,
    datePipe: DatePipe
  ) {
    super(stateService, logHelperService, commercialButtonService, dataShareService, commercialService, datePipe, campaignDetailsService);
  }

  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.setDefaultAgencySpecialistPerc();
    this.restoreCommercialData();
  }

  /**
   * @description get commercial data
   * @author Darshan Vachhani
   * @memberof CommercialBalComponent
   */
  restoreCommercialData() {
    this.commercialConfig.user = this.dataShareService.getUserModel();
    this.getCommercialDataFromService();
  }

  /**
   * @description get commercial data from service
   * @author Darshan Vachhani
   * @memberof CommercialBalComponent
   */
  getCommercialDataFromService(isManual = false) {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
    };

    this.commercialService.loadCommercialCampaignDetails(param).subscribe((data) => {
      if (data.status === 'OK') {
        this.commercialConfig.campaignStatus = data.data.campaignStatusId;
        this.commercialConfig.bookingStatus = data.data.bookingStatus;
        this.commercialConfig.uiControl = data.data.uiControl;
        this.commercialData = data.data;
        if (isManual) {
          this.dataShareService.updateBookingList(this.commercialData.bookingList);
        }
        this.hideDiscountGrid = this.commercialConfig.uiControl.collapseDiscountGridbyDefault || false;

        this.commercialData.bookingStatusName =
          this.campaignDetailsService.getStatusNameFromId(data.data.campaignStatusId);
        const assignedToList = this.dataShareService.getInitialConfigByKey('assignedTo');
        this.campaignDetails = this.campaignDetailsService.populateCampaignDetails(
          this.commercialData, this.initialConfig, assignedToList);
        if (isManual) {
          this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT);
        } else {
          this.campaignDetailsService.calculateCPT(this.campaignDetails);
        }
        this.historyROC = this.campaignDetailsService.populateHistoryROC(this.historyROC, data.data.auditReasonsHistoryMap, this.commercialConfig.uiControl.historyROC);
      }
    });

  }

  /**
   * @description this is output event from commercialbutton when click on update/option/book/cancel button click
   * @author Darshan Vachhani
   * @param {*} status
   * @memberof CommercialBalComponent
   */
  onBookCampaign(status: number) {
    if (this.validateCommercialCampaign(status)) {
      status = status || this.campaignDetails.campaignStatusId;
      if (this.isROC(status)) {
        this.handleROC(status, this.createRequestJSONForCommercial);
      } else if (this.isConfirm(status)) {
        this.confirmCampaign(this.createRequestJSONForCommercial);
      } else {
        this.validateServiceRequest(status, this.createRequestJSONForCommercial);
      }
    }
  }

  /**
   * @description commercial form validity
   * @author Darshan Vachhani
   * @returns {boolean}
   * @memberof CommercialBalComponent
   */
  validateCommercialCampaign = (status) => {
    const requiredFields: RequiredCampaignFields = new RequiredCampaignFields();
    requiredFields.assignedTo = true;
    let message = this.validateCommonFields(requiredFields);
    if (!message && this.campaignDetails.bookingList) {
      this.campaignDetails.bookingList.forEach((obj) => {
        const requiredBookingsFields: RequiredBookingsFields = new RequiredBookingsFields();
        if (!message) {
          message = this.validateBookingFields(obj, requiredBookingsFields);
        }
        if (!message && !obj.hideMediaControl) {
          message = this.validateMediaLength(obj, status);
        }
      });
    }
    return this.isValid(message);
  }

  /**
   * @description create requestJSON for passing to backend
   * @author Darshan Vachhani
   * @param {*} status
   * @returns
   * @memberof CommercialBalComponent
   */
  createRequestJSONForCommercial = (status) => {
    const campaignDetails = this.campaignDetails;
    const populate: PopulateCommercialFields = this.getCommercialFields();
    populate.floatingStartDate = true;
    populate.floatingEndDate = true;
    const temp = this.populateBookCommercialCampaignParam(status, campaignDetails, populate);
    const populateBooking: PopulateBookingDetailFields = this.getBookingDetailFields();
    temp.bookingList = this.populateBookingDetailsServiceObject(campaignDetails, 'bookingList', populateBooking);
    temp['discounts'] = this.campaignDetails.discounts;
    return temp;
  }

  /**
   * To override the commercial fields
   * This override fields are used to override the default value
   * @memberof CommercialAtComponent
   * @author Nikunj Gadhiya
   */
  getCommercialFields() {
    const populate: PopulateCommercialFields = super.getCommercialFields();
    populate.setPCFields();
    populate.dgNumber = true;
    populate.opportunityLineId = true;
    return populate;
  }

  /**
   * To override the booking detail fields
   * This override fields are used to override the default value
   * @memberof CommercialBalComponent
   * @author Dhaval Patel
   */
  getBookingDetailFields() {
    const populate: PopulateBookingDetailFields = super.getBookingDetailFields();
    populate.setPICFields();
    populate.setCCBIBFields();
    populate.setPOORIFields();
    populate.financeAmount = true;
    populate.setPMDBFields();
    populate.isCancellationPending = true;
    return populate;
  }


  async manuallyCPTUpdated() {
    const campaigndata = this.createRequestJSONForCommercial(this.campaignDetails.campaignStatusId);
    await this.updateCommercialInfoForCPT(campaigndata);
    this.getCommercialDataFromService(true);
  }
}
