import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataShareService } from '../../core/services/data-share.service';
import { GLOBAL } from '../../core/utils/app.constant';
import { throwError } from 'rxjs';
/**
 * @export
 * @class MultiTargetService
 */
@Injectable()
export class MultiTargetService {
  /**
   * Creates an instance of MultiTargetService.
   * @param {DataShareService} dataShareService
   * @param {HttpClient} http
   * @memberof MultiTargetService
   */
  constructor(
    private dataShareService: DataShareService,
    private http: HttpClient,
    // private brickBaseService: BrickBaseService,
  ) { }

  /**
   * send file upload request
   * @param {any} file - file to send
   * @param {any} include - include list
   * @param {any} routeFrameIds - frameIds
   * @param {string} [url] - Request url
   * @returns
   * @memberof MultiTargetService
   */
  upload(file, url?: string, productValidation?: any, dayPartGroupId?: number, range = undefined, sotAvaialability: string = '') {
    const fd = new FormData();
    if (file != null) {
      fd.append('file', file);
      fd.append('bricsCampaignId', String(GLOBAL.BRIC_CAMPAIGN_ID));
      fd.append('action', 'multitarget');
      if (range) {
        fd.append('selectionPeriod', JSON.stringify(range));
      }
      if (sotAvaialability) {
        fd.append('sotAvaibility', sotAvaialability);
      }
      if (productValidation) {
        fd.append('selectionMultiTarget', JSON.stringify({ ...productValidation[0], dayPartGroupId }));
      }
      let headers = new HttpHeaders({
        'Content-Type': ''
      });
      headers = headers.delete('Content-Type');
      const requestOptions = {
        headers,
        body: fd
      };
      const reqUrl = this.dataShareService.getServiceCallUrlByKey('FILE_UPLOAD_URL') || url;
      return this.http.request(GLOBAL.HTTP_METHOD, reqUrl, requestOptions)
        .toPromise<any>()
        .catch(error => throwError(error));
    }
  }

  /**
   * @description downloads an already uploaded file
   * @author Amit Mahida
   * @param {*} userSelectionId
   * @returns
   * @memberof MultiTargetService
   */
  downloadFile(userSelectionId: string) {
    const fd = new FormData();
    fd.append('bricsCampaignId', String(GLOBAL.BRIC_CAMPAIGN_ID));
    fd.append('Bos-SessionId', String(GLOBAL.BOS_SESSIONID));
    fd.append('action', 'downloadUserSelection');
    fd.append('userSelectionId', userSelectionId);
    const headers = new HttpHeaders();
    const requestOptions = {
      headers,
      body: fd,
      observe: 'response' as 'body',
      // observe: 'events',
      responseType: 'blob' as 'blob'
    };
    const reqUrl = this.dataShareService.getServiceCallUrlByKey('FILE_DOWNLOAD_URL');
    return this.http.request(GLOBAL.HTTP_METHOD, reqUrl, requestOptions)
      .toPromise<any>()
      .catch(error => throwError(error));
  }
}
