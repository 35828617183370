<tag-input class="globalSearchTagInput" [(ngModel)]="openedCampaigns" [identifyBy]="'campaignId'"
    [displayBy]="'campaignLookupDisplay'" [onlyFromAutocomplete]="true" (onAdd)="onAddInCampaignList($event)"
    [placeholder]="userBundle['global.keywordSearch'] || 'Keyword Search'"
    [secondaryPlaceholder]="userBundle['global.keywordSearch'] || 'Keyword Search'" [inputClass]="'form-control'"
    [maintainSearchText]="false" [showDropdownIfEmpty]="false" [onTextChangeDebounce]="500">
    <ng-template let-item="item" let-index="index">
    </ng-template>
    <tag-input-dropdown [showDropdownIfEmpty]="false" [identifyBy]="'campaignId'" [displayBy]="'campaignLookupDisplay'"
        [minimumTextLength]="1" [autocompleteObservable]="loadOtherCampaignList"
        [appendToBody]="false" [matchingFn]="otherCampaignMatchingFn">
    </tag-input-dropdown>
</tag-input>
