<app-sb-modal-wrapper [title]="userBundle['workspace.reshuffleDetail.header']" [titleBackgroundColor]="titleColor"
  (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <div class="tabs_container" style="padding: 15px;">
    <div class="tab">
      <ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-fresh" [gridOptions]="campaignGridOptions"
        [rowData]="campaignGridData" [columnDefs]="campaignColumnDefs">
      </ag-grid-angular>
      <div class="col_select_menu">
        <div ngbDropdown class="d-inline-block" placement="left" [autoClose]="false" #toggleColumnContainer>
          <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
            <em class="fa fa-bars"></em>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="drop_container">
            <div class="checkbox clearBoth" (click)="downloadExcel()">
              <label class="center-block" style="cursor: pointer">Excel Export</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-sb-modal-wrapper>
<div class="timer-cnt" *ngIf="expireTime > 0 ">
  {{expireMins}}m {{expireTime}}s
</div>