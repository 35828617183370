<div class="contentarea">
  <dl class="accordion animated bounceInDown content">
    <dt (click)="showHideCampaignDetail()">
      <span class="editable-title">
        <span class="text">{{initialConfig?.userBundle['commercial.tabTitle.campaignDetails']}}
          &nbsp;-&nbsp; </span>
        <span class="text allow-copy"> {{commercialData?.campaignReference}}
        </span>
      </span>
      <a href="javascript:void(0)" class="title" [ngClass]="!hideCampaignDetail?'down':''">
        <span class="right">
          <span class="text">{{commercialData?.bookingStatusName}}</span>
          <span class="icon_wrapper">
            <span class="icons animated"></span>
          </span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'readOnly' : systemFlags.readOnly, 'down' : !hideCampaignDetail}">
      <app-campaign-details-uk *ngIf="commercialData" [campaignDetails]="campaignDetails"
        [commercialConfig]="commercialConfig" [historyROC]="historyROC" (manuallyCPTUpdated)="manuallyCPTUpdated()"></app-campaign-details-uk>
    </dd>
  </dl>

  <dl class="accordion animated bounceInDown content">
    <dt (click)="showHideBookingGrid()">
      <span class="editable-title">
        <span class="text">{{initialConfig?.userBundle['commercial.tabTitle.bookingDetails']}} </span>
      </span>
      <a href="javascript:void(0)" class="title" [ngClass]="!hideBookingGrid?'down':''">
        <span class="right">
          <span class="icon_wrapper">
            <span class="icons animated"></span>
          </span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'down' : !hideBookingGrid}">
      <app-booking-grid-uk *ngIf="commercialData" [commercialConfig]="commercialConfig"
        [bookingList]="commercialData.bookingList">
      </app-booking-grid-uk>
    </dd>
  </dl>

  <app-commercial-buttons *ngIf="commercialData" [commercialConfig]="commercialConfig"
    [campaignDetails]="campaignDetails" [validateFn]="validateCommercialCampaign"
    [bookingList]="commercialData.bookingList" [populateRequestJson]="createRequestJSONForCommercial"
    (bookCampaign)="onBookCampaign($event)">
  </app-commercial-buttons>
</div>
