import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { GridOptions, ColDef } from 'ag-grid-community';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { LogHelperService } from '../../../core/services/log-helper.service';
import { DataShareService } from '../../../core/services/data-share.service';
import {
  AgCheckboxComponent,
  AgCustomFilterMenuComponent,
  AgCustomHeaderComponent,
  AgCurrencyCellRendererComponent,
  AgCheckboxCellRendererComponent,
  AgCustomSort,
  AgCustomRowUtil,
  AgTextFloatingFilterComponent
} from '../ag-grid-support/index';
import * as toasterConfig from '../../../core/toasterConfig';
import { UiControl } from '../../../models';
import { ExcelCell, ExcelColumn, ExportToExcel } from '../../../core/utils/exportToExcel';
declare const _: any;

@Component({
  selector: 'app-discount-grid-norway',
  templateUrl: './discount-grid-norway.component.html',
  styleUrls: ['../ag-grid-custom-style.css', '../ag-grid-col-select-style.css', './discount-grid-norway.component.css']
})
export class DiscountGridNorwayComponent implements OnInit, AfterViewInit, OnDestroy {
  
  @Input() campaignDetails: any;

  @Input() uiControl: UiControl

  gridData: any;

  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  sortObj: AgCustomSort;
  /**
   * Ag-Grid Options Object
   */
  gridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  columnDefs: ColDef[];

  /**
   * column list to show in show/hide column dropdown
   */
  columnList: any = [];

  /**
   * list of Cart Data
   * @memberof CartDetailComponent
   */
  rowData: any[] = [];

  /**
   * Grid footer data
   */
  footerRowData: any[];

  /**
   * user bundle data
   */
  userBundle: any = {};

  numericFields = [];
  dateRangeFields = [];
  selectFields = [];
  groupingField: string = null;
  public toasterConfig: any = toasterConfig.toasterconfig;

  constructor(private dataShareService: DataShareService,
    private toaster: LogHelperService) {

  }

  ngOnInit(): void {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.gridData = this.uiControl.showDiscountGrid ? this.campaignDetails.discounts : this.campaignDetails.gridLines;
    // this.uiControl.showDiscountGrid = true;
    this.setFooterRows();
    this.prepareRowData();

    //SM-10214
    if (this.uiControl.showDiscountGrid) {
      this.prepareColDef2();
    } else {
      this.prepareColDef();
    }

    this.reset();
    // we pass an empty gridOptions in, so we can grab the api out
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      onRowDataChanged: (event) => {
        event.api.setPinnedBottomRowData(this.footerRowData);
      },
      scrollbarWidth: 10,
      onGridReady: this.onGridReady,
      getRowHeight: this.getRowHeight,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      suppressScrollOnNewData: true,
      onFilterChanged: this.onFilterChanged,
      // onCellEditingStopped: this.onCellEditingStopped,
      groupingField: this.groupingField,
      suppressMovableColumns: true,
      isExternalFilterPresent: () => true,
      // tslint:disable-next-line:ter-arrow-parens
      doesExternalFilterPass: (node) => node.data.isVisible,
      onCellClicked: (params) => {
        if (params.rowPinned === 'bottom' && params.rowIndex === 0 && params.column.isCellEditable(params.node)) {
          const par: any = {
            column: params.column,
            rowIndex: params.rowIndex,
            rowPinned: params.rowPinned
          };
          // tslint:disable-next-line: no-string-literal
          params.api['rowRenderer'].startEditingCell(par);
        }
      }
    } as GridOptions;
  }

  ngAfterViewInit() {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    document.body.appendChild(document.getElementsByTagName('toaster-container')[0]);
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    document.body.removeChild(document.querySelector('body > toaster-container'));
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.forEach((r) => {
        r.isFilterPass = true;
      });
    }
    // Refresh Grid Header checkbox component
    // tslint:disable-next-line: no-string-literal
    this.gridOptions.api['headerRootComp'].forEachHeaderElement((element) => {
      if (element.column.colId === 'selectChk' && element.columnApi && element.childComponents && element.childComponents.length > 1) {
        const headerCmpIns = element.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  clearAllFilters() {
    this.gridOptions.api.setFilterModel(null);
  }

  onGridReady = () => {
    this.columnList = this.gridOptions.columnApi.getAllColumns();
  }

  getRowHeight = (params): number => {
    let height = 30;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else if (params.data.isVisible) {
      height = 30;
    }
    return height;
  }

  isFullWidthCell = (rowNode): boolean => {
    return rowNode.data.gridSummary;
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }

  getGridSummary() {
    return `<span>${this.userBundle['discount.common.totalFrames']}: ${this.getChildRows().length} &#45;
      ${this.userBundle['discount.common.selectedFrames']}: ${this.getSelectedChildRows().length}`;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen() && $event.button !== 2 && !dropdownEle._isEventFromToggle($event)
      && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
      dropdownEle.close();
    }
  }

  prepareRowData() {
    this.rowData = [];
    for (const curRow of this.gridData) {
      curRow.isFilterPass = true;
      curRow.isParent = false;
      curRow.isVisible = true;
      curRow.isSelected = false;
      this.rowData.push(curRow);
    }
  }

  prepareColDef() {
    this.columnDefs = [
      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false, colId: 'selectChk',
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: (params): string[] => {
          const retArr: string[] = [];
          if (params.data.isSelected) {
            retArr.push('selectedRow');
          }
          if (params.node.rowPinned === 'bottom' && params.rowIndex === 1) {
            retArr.push('borderBottom');
          }
          return retArr;
        }
      },
      // {
      //   headerName:'', field: 'tagName', width: 135, editable: false, pinned: 'left',
      //   pinnedRowCellRenderer: this.tagNamePinnedRowCellRenderer,
      // },
      {
        headerName: this.userBundle['discount.norway.networkName'], field: 'networkName', width: 195, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.norway.channelName'], field: 'productName', width: 150, editable: false
      },
      {
        headerName: this.userBundle['discount.norway.cityName'], field: 'townName', width: 150, editable: false // SM-4924
      },
      {
        headerName: this.userBundle['discount.norway.productformat'], field: 'productFormatName',
        width: 200, editable: false
      },
      {
        headerName: this.userBundle['discount.norway.weekNumber'], field: 'weekNumber', width: 150, editable: false
      },
      {
        headerName: this.userBundle['discount.norway.startDate'], field: 'startDate', width: 110, editable: false,
        // commented below code as now backend gives formated date
        // pinnedRowCellRenderer: this.blankCellRenderer,
        // cellRendererFramework: AgDateCellRendererComponent,
        // cellRendererParams: {
        //   dateFormat: LocaleData.displayDateFormat
        // }
      },
      {
        headerName: this.userBundle['discount.norway.endDate'], field: 'endDate', width: 110, editable: false,
        // commented below code as now backend gives formated date
        // pinnedRowCellRenderer: this.blankCellRenderer,
        // cellRendererFramework: AgDateCellRendererComponent,
        // cellRendererParams: {
        //   dateFormat: LocaleData.displayDateFormat
        // }
      },
      {
        headerName: this.userBundle['discount.norway.weekDays'], field: 'weekDays', width: 210, editable: false
      },
      {
        headerName: this.userBundle['discount.norway.numberFrames'], field: 'numberOfFrames', width: 120, editable: false,
        // uncommeting below code will format number frames but will right align it
        // cellRendererFramework: AgNumberCellRendererComponent,
        // pinnedRowCellRendererFramework: AgNumberCellRendererComponent
      },
      {
        headerName: this.userBundle['discount.norway.grossValue'], field: 'grossPrice', width: 120, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return false;
          }
        },
      },
      {
        headerName: this.userBundle['discount.norway.lineByLine'], field: 'lineByLine', width: 120, editable: false,
        pinnedRowCellRendererFramework: AgCheckboxCellRendererComponent,
        cellRendererFramework: AgCheckboxCellRendererComponent,
        cellRendererParams: this.getCheckBoxCellRenderParam(false),
        pinnedRowCellRendererParams: this.getCheckBoxCellRenderParam(false)
      }
    ];

    this.numericFields = [
      'grossPrice',
      'numberOfFrames'
    ];

    this.dateRangeFields = [];

    this.processColdefs();
    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField);
  }

  prepareColDef2() {
    this.columnDefs = [
      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false, colId: 'selectChk',
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: (params): string[] => {
          const retArr: string[] = [];
          if (params.data.isSelected) {
            retArr.push('selectedRow');
          }
          if (params.node.rowPinned === 'bottom' && params.rowIndex === 1) {
            retArr.push('borderBottom');
          }
          return retArr;
        }
      },
      // {
      //   headerName:'', field: 'tagName', width: 135, editable: false, pinned: 'left',
      //   pinnedRowCellRenderer: this.tagNamePinnedRowCellRenderer,
      // },
      {
        headerName: this.userBundle['discount.norway.package'], field: 'productCatalogueName', width: 100, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.tagNamePinnedRowCellRenderer
      },
      {
        headerName: this.userBundle['discount.norway.networkName'], field: 'networkName', width: 195, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.norway.channelName'], field: 'productName', width: 150, editable: false
      },
      {
        headerName: this.userBundle['discount.norway.cityName'], field: 'countyName', width: 150, editable: false // SM-4924
      },
      {
        headerName: this.userBundle['discount.norway.productformat'], field: 'productFormatName',
        width: 200, editable: false
      },
      {
        headerName: this.userBundle['discount.norway.weekNumber'], field: 'weekNumber', width: 150, editable: false
      },
      {
        headerName: this.userBundle['discount.norway.startDate'], field: 'startDate', width: 110, editable: false,
        // commented below code as now backend gives formated date
        // pinnedRowCellRenderer: this.blankCellRenderer,
        // cellRendererFramework: AgDateCellRendererComponent,
        // cellRendererParams: {
        //   dateFormat: LocaleData.displayDateFormat
        // }
      },
      {
        headerName: this.userBundle['discount.norway.endDate'], field: 'endDate', width: 110, editable: false,
        // commented below code as now backend gives formated date
        // pinnedRowCellRenderer: this.blankCellRenderer,
        // cellRendererFramework: AgDateCellRendererComponent,
        // cellRendererParams: {
        //   dateFormat: LocaleData.displayDateFormat
        // }
      },
      {
        headerName: this.userBundle['discount.norway.weekDays'], field: 'weekDays', width: 210, editable: false
      },
      {
        headerName: this.userBundle['discount.norway.numberFrames'], field: 'numberOfFrames', width: 120, editable: false,
        // uncommeting below code will format number frames but will right align it
        // cellRendererFramework: AgNumberCellRendererComponent,
        // pinnedRowCellRendererFramework: AgNumberCellRendererComponent
      },
      {
        headerName: this.userBundle['discount.norway.grossValue'], field: 'grossPrice', width: 120, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return false;
          }
        },
      }
    ];

    this.numericFields = [
      'grossPrice',
      'numberOfFrames'
    ];

    this.dateRangeFields = [];

    this.processColdefs();
    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField);
  }

  processColdefs() {

    const decimalFieldsForFilter = [
      'grossPrice',
    ];
    for (let i = 1; i < this.columnDefs.length; i++) {
      this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
      this.columnDefs[i].floatingFilterComponentParams = {
        value: '',
        suppressFilterButton: true,
        decimalRound: decimalFieldsForFilter.indexOf(this.columnDefs[i].field) > -1 ? 2 : null
      };

      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };

      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => {
        const retArr: string[] = [];
        if (params.data.isParent || params.node.rowPinned === 'bottom') {
          retArr.push('fontBold');
        }
        if (params.node.rowPinned === 'bottom' && (i > 4 || params.rowIndex === 1)) {
          retArr.push('borderBottom');
        }
        if (params.data.isSelected) {
          retArr.push('selectedRow');
        }
        return retArr;
      };

    }
  }


  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
    // restore original data order when sorting is cleared
    if (this.sortObj.sortColumns.length === 0) {
      this.rowData = _.clone(this.gridData);
    }
  }

  getSelectedChildRows(groupingId?: string, filterOnly = false): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  apply = () => {
    const selectedChildRows = this.getSelectedChildRows();
    if (selectedChildRows.length > 0) {
      for (const row of selectedChildRows) {
        row.lineByLine = this.footerRowData[0].lineByLine;
      }
      this.resetApply();
      this.rowData = _.clone(this.rowData);
    } else {
      this.toaster.logInfo('Select atleast one row!');
    }
  }

  reset = () => {
    this.resetApply();
    this.rowData = _.clone(this.rowData);
  }

  isCellEditable(data) {
    return !data.locked;
  }

  blankCellRenderer() {
    return '';
  }

  tagNamePinnedRowCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {
      // apply Icon
      const eImg: HTMLImageElement = document.createElement('img');
      eImg.src = 'images/general/apply.png';
      eImg.title = 'Apply';
      eImg.onclick = this.apply;
      eDiv.appendChild(eImg);

      // Reset Icon
      const reImg: HTMLImageElement = document.createElement('img');
      reImg.src = 'images/general/reset.png';
      reImg.title = 'Reset';
      reImg.onclick = this.reset;
      eDiv.appendChild(reImg);
    } else if (params.rowIndex === 1) {
      return '';
    }
    return eDiv;
  }

  getCheckBoxCellRenderParam = (isPinnedRow) => {
    if (isPinnedRow) {
      return {
        cellEditable: () => {
          return true;
        }
      };
    } else {
      return {
        cellEditable: (data) => {
          if (data.isParent) {
            return false;
          } else {
            return (this.isCellEditable(data));
          }
        },
        onSelectChange: () => {
        }
      };
    }
  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinRowIndex: 0 },
      { isVisible: true, locked: true },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
  }

  resetApply = () => {
    const columnArray = ['lineByLine'];
    for (const column of columnArray) {
      this.footerRowData[0][column] = null;
    }
  }

  // // Data functions
  getChildRows(filterOnly = true): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData, null, null, filterOnly);
  }

  sumFieldValues(rows: any[], fieldName: string): number {
    return AgCustomRowUtil.sumFieldValues(rows, fieldName);
  }

  getParentRows(groupingId?: string, data?: any[]): any[] {
    const newData = data || this.rowData;
    return AgCustomRowUtil.getParentRows(newData, null, groupingId);
  }

  toggleColumnVisible(colId, isVisible) {
    const isVisibleLocal = !isVisible;
    this.gridOptions.columnApi.setColumnVisible(colId, isVisibleLocal);
  }

  trackByField(index, column) {
    return column?.colDef?.field;
  }

  /**
   * download cart data in excel format
   */
   downloadExcel() {
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    // Add Header Row
    let sheetRow: ExcelCell[] = [];
    for (let j = 1; j < this.columnDefs.length; j++) {
      if (this.gridOptions.columnApi.getColumn(this.columnDefs[j].field)['visible']) {
        const cell: ExcelCell = {
          text: this.columnDefs[j].headerName,
          fontColor: '#FFFFFF',
          bgColor: '#000000',
          borderColor: '#CCCCCC',
          isFontBold: true
        };
        if (this.columnDefs[j].field === 'locked') {
          cell.text = this.userBundle['discount.be.locked'] || 'Locked';
        }
        const col: ExcelColumn = {
          width: this.columnDefs[j].field === 'week' ? 15 : 12
        };
        excelColumn.push(col);
        sheetRow.push(cell);
      }
    }
    sheetData.push(sheetRow);

    // Add Data rows
    let rowsInserted = 0;
    for (const row of this.rowData) {
      if (row.isParent) {
        continue;
      }

      sheetRow = [];
      for (let j = 1; j < this.columnDefs.length; j++) {
        if (this.gridOptions.columnApi.getColumn(this.columnDefs[j].field)['visible']) {
          let cellVal = row[this.columnDefs[j].field];
          if (this.selectFields.indexOf(this.columnDefs[j].field) > -1) {
            cellVal = row[`${this.columnDefs[j].field}_sort`];
          }
          const cell: ExcelCell = {
            text: cellVal,
            fontColor: '#000000',
            bgColor: rowsInserted % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
            borderColor: '#CCCCCC'
          };
          sheetRow.push(cell);
        }
      }
      rowsInserted++;
      sheetData.push(sheetRow);
    }
    const cartText = 'discount';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }

}
