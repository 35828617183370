import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataShareService } from '../../../core/services/data-share.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { getFormData } from '../../../core/utils/formData';
import { GLOBAL } from '../../../core/utils/app.constant';
import { _throw } from 'rxjs/observable/throw';

@Injectable()
export class DiscountGridService {

  constructor(private http: HttpClient, private dataShareService: DataShareService) { }

  extractData(res: Response) {
    return res;
  }

  handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      /* tslint:disable:no-string-literal */
      const err = body['error'] || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return _throw(errMsg);
  }

  copyFramesForDiscontGrid(param): Observable<any> {
    const serviceURL = this.dataShareService.getServiceCallUrlByKey('LOAD_COMMERCIAL_CAMPAIGN');
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const requestOptions = {
      headers,
      body: getFormData(param),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      serviceURL,
      requestOptions
    )
      .map(this.extractData)
      .catch(this.handleError);
  }
}
