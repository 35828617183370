import { Injectable } from '@angular/core';
import { SbModalPopupService } from '../../core/components/sb-modal-popup/sb-modal-popup.service';
import { FreeOfChargeComponent } from '../freeofcharge/freeofcharge.component';
import { CampaignStatus } from '../../core/enum';
import { ReasonForChangeResolveParam, BookingLine, FreeOFChargeResult, ReasonForChangeResult, CampaignDetails } from '../commercial.model';

import { ReasonForChangeComponent } from '../reasonforchange/reasonforchange.component';
import { DataShareService, StateService, LogHelperService } from '../../core/services';
import { InitialConfigModel, SystemFlags, Cell, Row, UiControl, SaveCampaignResolvedObject } from '../../models';
import { DatePipe } from '@angular/common';
import { ConfirmationModalPpComponent } from '../../result/confirmation-modal-pp/confirmation-modal-pp.component';
import { CommercialValdiationComponent } from '../commercial-valdiation/commercial-valdiation.component';
import { SaveCampaignComponent } from '../savecampaign/savecampaign.component';
import { CommercialService } from '../../core/services/commercial.service';
import { BrickBaseService } from './../../core/services/brick-base.service';
import { Router } from '@angular/router';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import * as _ from 'lodash';
import { SearchService } from '../../search';
import { GLOBAL } from '../../core/utils/app.constant';

@Injectable()
export class CommercialButtonService {
  config: InitialConfigModel;
  ignoreList: string[] = [];
  ignoreValues: string[] = [];
  param: any;
  userBundle: object;
  readonly CONFIRMATION_LABEL = 'result.confirmation.label';
  constructor(
    private sbModalPopupService: SbModalPopupService,
    private dataShareService: DataShareService,
    private stateService: StateService,
    private datePipe: DatePipe,
    private logHelperService: LogHelperService,
    private commercialService: CommercialService,
    private router: Router,
    private appHeaderService: AppHeaderService,
    private searchService: SearchService,
    private brickBaseService: BrickBaseService
  ) {
    this.config = this.dataShareService.getInitialConfig();
    this.ignoreList = ['uiControl', 'bookingList', 'bookings', 'bookingStatus', 'bookingStatusName', 'disableGrossMedia', 'disablePreBarter', 'bookingDetails', 'weekDaySet', 'startDate', 'endDate', 'discountGridData', 'updatedStatus', 'netAfterDiscountAndMiscCost',
      'netMediaValue', 'grossMediaValue', 'campaignStartDate', 'campaignEndDate', 'campaignCreateDate', 'disableBookButton', 'disableBookButton', 'disableBookButton', 'campaignReferenceList', 'discounts', 'statusCategory', 'agreement', 'agencyDiscount',
      'smartBricsValuePerWeek', 'smartBricsValueTotal', 'smartBricsValueTotal', 'discountKey', 'weekDaySet', 'netAfterDiscount'];
    this.ignoreValues = [null, undefined, ''];
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
  }

  openFreeOFChargeOverlay() {
    return new Promise((resolve) => {
      this.sbModalPopupService.open(FreeOfChargeComponent, null).result.then((result: FreeOFChargeResult) => {
        resolve(result);
      }, (reason) => {
        console.log(reason);
      });
    });
  }

  /**
   * @description Reason for change
   * @author Darshan Vachhani
   * @param {ReasonForChangeResolveParam} data
   * @returns
   * @memberof CommercialButtonService
   */
  openReasonForChangeOverlay(data: ReasonForChangeResolveParam) {
    return new Promise((resolve) => {
      this.sbModalPopupService.open(ReasonForChangeComponent, data).result.then((result: ReasonForChangeResult) => {
        resolve(result);
      }, (reason) => {
        console.log(reason);
      });
    });
  }

  startsWith(originalString, searchString, position = 0) {
    return originalString.indexOf(searchString, position) === position;
  }

  /**
   * @description Check if all booking lines are in option or not
   * @author Darshan Vachhani
   * @param {BookingLine[]} bookingList
   * @returns
   * @memberof CommercialButtonService
   */
  checkIfAllTheBookingLinesAreInOption(bookingList: BookingLine[]) {
    let allLinesOptional = true;
    if (bookingList && bookingList.length > 0) {
      bookingList.forEach((element) => {
        if (element.bookingStatusId !== CampaignStatus.option) {
          allLinesOptional = false;
        }
      });
    }
    return allLinesOptional;
  }

  /**
   * @description check if any booking lines are confirmed
   * @author Darshan Vachhani
   * @param {BookingLine[]} bookingList
   * @returns
   * @memberof CommercialButtonService
   */
  chekIfTheAnyOneBookingLineIsConfirmed(bookingList: BookingLine[]) {
    let confirmedLine = false;
    bookingList.forEach((element) => {
      if (element.bookingStatusId === 4 && element.locked === false) {
        confirmedLine = true;
      }
    });
    return confirmedLine;
  }

  isCampaignAuditDetailsChanged(campaignDetails: CampaignDetails, propertyName: string, uiControl: UiControl, populateCampaignAuditDetailsObject?): boolean {
    let isChanged = false;
    let newCommercialCampaignAuditDetailsInitialObject: CampaignDetails = null;
    let commercialCampaignAuditDetailsInitialObject: CampaignDetails = null;
    switch (uiControl.triggerROChangeType) {
      case 2:
        // Trigger ROChange when we change any value on commercial
        isChanged = this.isCampaignAuditDetailsChangedForAnyChange(campaignDetails, propertyName);
        break;
      case 3:
        // Trigger ROChange when we change Booked Gross Media Value
        newCommercialCampaignAuditDetailsInitialObject = _.clone(campaignDetails);
        commercialCampaignAuditDetailsInitialObject = this.stateService.getCommercialObject('loadedCommercialDetails') || {};

        if (newCommercialCampaignAuditDetailsInitialObject.bookingList.length !==
          commercialCampaignAuditDetailsInitialObject.bookingList.length) {
          isChanged = true;
        } else {
          newCommercialCampaignAuditDetailsInitialObject.bookingList.forEach((row, i) => {
            const bookingRowStatusId = newCommercialCampaignAuditDetailsInitialObject.bookingList[i].bookingStatusId;
            if ((row.grossMediaValue !== commercialCampaignAuditDetailsInitialObject.bookingList[i].grossMediaValue)
              && (uiControl.enableROChangeForBookingStatus && (!bookingRowStatusId || uiControl.enableROChangeForBookingStatus.indexOf(bookingRowStatusId) > -1))) {
              isChanged = true;
            }
          });
        }
        break;

      case 1:
      default:

        // Trigger ROChange on specific value change e.g. UK

        this.populateCampaignAuditDetailsObject = populateCampaignAuditDetailsObject ? populateCampaignAuditDetailsObject : this.populateCampaignAuditDetailsObject;

        newCommercialCampaignAuditDetailsInitialObject = this.populateCampaignAuditDetailsObject(campaignDetails, propertyName);
        commercialCampaignAuditDetailsInitialObject =
          this.populateCampaignAuditDetailsObject(this.stateService.getCommercialObject('loadedCommercialDetails'), propertyName) || {};
        if (!_.isEqual(newCommercialCampaignAuditDetailsInitialObject, commercialCampaignAuditDetailsInitialObject)) {
          isChanged = true;
        }
        break;
    }
    return isChanged;
  }

  isCampaignAuditDetailsChangedForAnyChange(campaignDetails: CampaignDetails, propertyName: string): boolean {
    let newCommercialCampaignAuditDetailsInitialObject: CampaignDetails = null;
    let commercialCampaignAuditDetailsInitialObject: CampaignDetails = null;
    newCommercialCampaignAuditDetailsInitialObject = _.clone(campaignDetails);
    commercialCampaignAuditDetailsInitialObject = this.stateService.getCommercialObject('loadedCommercialDetails') || {};
    for (const [i, row] of commercialCampaignAuditDetailsInitialObject[propertyName].entries()) {
      const keylist = Object.keys(row);
      for (const key of keylist) {
        if (!newCommercialCampaignAuditDetailsInitialObject.bookingList[i]
          || row[key] !== newCommercialCampaignAuditDetailsInitialObject.bookingList[i][key]) {
          return true;
        }
      }
    }

    // for HK Airport
    if (commercialCampaignAuditDetailsInitialObject.bookingDetails.length
      === newCommercialCampaignAuditDetailsInitialObject.bookingDetails.length) {
        for (const [i, row] of commercialCampaignAuditDetailsInitialObject.bookingDetails.entries()) {
          const keylist = Object.keys(row);
          for (const key of keylist) {
            if (row[key] !== newCommercialCampaignAuditDetailsInitialObject.bookingDetails[i][key] && (this.ignoreList.indexOf(key) === -1)) {
              return true;
            }
          }
        }
    } else {
      return true;
    }

    // campaign details compare
    const campaignDetailsKeys = Object.keys(newCommercialCampaignAuditDetailsInitialObject);
    for (const key of campaignDetailsKeys) {
      if ((this.ignoreList.indexOf(key) === -1) && this.isValueChanged(commercialCampaignAuditDetailsInitialObject[key], newCommercialCampaignAuditDetailsInitialObject[key])) {
        return true;
      }
    };
    return false;
  }

  /**
   * @description Campaign audit details object
   * @author Darshan Vachhani
   * @param {CampaignDetails} commercialData
   * @returns
   * @memberof CommercialButtonService
   */
  populateCampaignAuditDetailsObject(commercialData: CampaignDetails, propertyName: string) {

    let commercialAuditObject = null;
    // SBRICS-226 Commercial Page Audit Object
    // SBRICS-1810, Nishit updated code for multiple booking
    if (Object.keys(commercialData).length) {
      commercialAuditObject = {
        brandId: commercialData.brand.brandId || null, // chosen search
        specialistId: commercialData.specialist.specialistId || null,
        specialistPercentage: commercialData.specialistPercentage || null,
        agencyId: commercialData.agency.agencyId || null,
        agencyPercentage: commercialData.agencyPercentage || null,
        advertiserId: commercialData.advertiser.advertiserId || null
      };
      commercialAuditObject[propertyName] = this.populateBookingsOrBookignList(commercialData, propertyName);
    }
    return commercialAuditObject;
  }

  /**
   * populate Bookings Or BookignList
   * @param commercialData CampaignDetails
   * @param propertyName string
   */
  populateBookingsOrBookignList(commercialData: CampaignDetails, propertyName: string) {
    const result = [];
    if (commercialData[propertyName] && commercialData[propertyName].length > 0) {
      let i = 0;
      commercialData[propertyName].forEach((bookingObj) => {
        if (propertyName === 'bookings' ||
          (propertyName === 'bookingList' && typeof (bookingObj) === 'object')) { // SM-2111
          result[i] = {};
          result[i].barterId = bookingObj.barterId || null;
          result[i].barterRate = bookingObj.barterRate || null;
          result[i].preBarterValue = bookingObj.preBarterValue || null;
          result[i].grossMediaValue =
            bookingObj.grossMediaValue && parseFloat(bookingObj.grossMediaValue.toFixed(2)) || null;
          result[i].netMediaValue =
            bookingObj.netMediaValue && parseFloat(bookingObj.netMediaValue.toFixed(2)) || null;
          i = i + 1;
        }
      });
    }
    return result;
  }

  /**
   * @description Data param for reason for change
   * @author Darshan Vachhani
   * @param {number} status
   * @param {CampaignDetails} commercialData
   * @returns {ReasonForChangeResolveParam}
   * @memberof CommercialButtonService
   */
  populateDataParamReasonForChange(status: number, commercialData: CampaignDetails, uiControl: UiControl): ReasonForChangeResolveParam {
    const data = new ReasonForChangeResolveParam();
    data.status = status;
    let reasonForChange = null;
    if (status === CampaignStatus.confirm && commercialData.reasonForChange) {
      reasonForChange = commercialData.reasonForChange;
    } else if (status === CampaignStatus.cancel && commercialData.comments) {
      reasonForChange = commercialData.reasonForChange;
    } else {
      reasonForChange = '';
    }
    data.reasonForChange = reasonForChange;

    const isDropDown = (status === CampaignStatus.cancel && uiControl.enableROCancelReasonList) || (status !== CampaignStatus.cancel && uiControl.enableROChangeReasonList);

    data.isDropDown = isDropDown;
    return data;
  }

  /**
   * @description This funcution will create a title for save, request option etc
   * @author Darshan Vachhani
   * @memberof CommercialButtonService
   */
  populateCampaignTitle(advertiser, campaignSaveDetails) {
    let campaignObj;
    if(!this.commercialService.IsTitlePersist){
    if (!campaignSaveDetails.campaignName) {
      if (this.stateService.getCampaign()) {

        const customDate = this.datePipe.transform(new Date(), 'ddMMyy');
        const lastName = this.config.userData.userName.split('.')[1];
        const userInitials = lastName.substring(0, 1).toUpperCase() + lastName.substring(1, lastName.length);
        campaignObj = this.stateService.getSearchPageObject('selectedText');

        if (campaignObj.advertiserName === undefined) {
          advertiser = '';
        } else {
          advertiser = campaignObj.advertiserName;
        }

        campaignSaveDetails.campaignName = `${customDate} ${userInitials} ${advertiser}`;
      }
    } else {
      const campaignTitle = campaignSaveDetails.campaignName.split('_');
      if (campaignTitle[2] === '') {
        campaignObj = this.stateService.getSearchPageObject('selectedText');
        if (campaignObj.advertiserName === undefined) {
          advertiser = '';
        } else {
          advertiser = campaignObj.advertiserName;
        }
        campaignSaveDetails.campaignName = `${campaignTitle[0]} ${campaignTitle[1]} ${advertiser}`;
      }
    }
  }
  return campaignSaveDetails;
}

  /**
* @description This function open modal pop up for confirmation.
* @author Darshan Vachhani
* @param {string} message Confirmation which we want to display on modal pop up
* @param {string} title Header of Modal popup
* @param {string} displalabel label for url
* @param {boolean} showInfoLabel display message from response
* @param {boolean} reset reset campaign after success
* @param {boolean} isConfirmProposal confirm proposal Flag . Based on the flag, HTML will be hidden on the Modal popup
* @memberof CommercialButtonService
*/
  openConfirmationModalPP(url: string, message: string, title: string, displalabel: string, color: string,
    showInfoLabel: boolean, redirect = false, isConfirmProposal = false, isForProcessing = false) {
    const modalOptions = { windowClass: 'Copy-Link' };
    this.sbModalPopupService.open(ConfirmationModalPpComponent, {
      url,
      message,
      title,
      displalabel,
      showInfoLabel,
      color
    }, modalOptions).result.then(() => {
      if (redirect || isForProcessing) {
        this.commercialService.clearLoadedCampaign().subscribe((res) => {
          this.searchService.resetCampaign(false, false, true);
          if (String(res.status) === 'OK') {
            this.appHeaderService.changeResetConfig({ getConfig: true, getBricStructure: true });
            this.commercialService.setGoToOtherPage(true);
            this.dataShareService.activateResultTab(false);
            this.dataShareService.activateCommercialTab(false);
            this.router.navigate(['/search']);
          } else {
            this.logHelperService.logError(this.config.userBundle['common.error.connecting']);
          }
        });
      }
      console.log('Confirmation Pop up closed successfully');
    }, (reason) => {
      console.log(reason);
    });
  }

  /**
   * @description validate commercial screen
   * @author Darshan Vachhani
   * @param {*} param
   * @param {number} status
   * @memberof CommercialButtonService
   */
  validateCommercialScreenServiceRequest(param, status: number) {
    this.commercialService.validateCommercialScreen(param).subscribe((data: any) => {
      // CampaignService.validateCommercialScreen(param).then(function (data) {
      const message = data.message;
      if (data.status === 'OK') {
        if (data.messageCode === 0 || status === CampaignStatus.cancel) {
          // Call the Booking Service //
          this.saveCommercialCampaignData(param, status);
        } else {
          this.openCommercialScreenValidationOverlay(data.data, param);
          // Open the validation PopUP //
          // $scope.callDirectiveFn.openCommercialScreenValidationOverlay(data.data, param);
        }

      } else {
        this.logHelperService.logError(message || 'Service did not return with valid response');
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  /**
   * @description save campaign data
   * @author Darshan Vachhani
   * @param {*} param
   * @param {number} status
   * @memberof CommercialButtonService
   */
  saveCommercialCampaignData(param, status: number) {
    this.param = _.cloneDeep(param);
    this.commercialService.saveCommercialCampaignData(param).subscribe((data: any) => {
      const message = data.message;
      if (String(data.status) === 'OK') {
        const saveMessageObject: SaveCampaignResolvedObject = {
          title: this.config.userBundle[this.CONFIRMATION_LABEL].toUpperCase(),
          message: message || 'Request processed successfully',
          headerColor: GLOBAL.DEFAULT_TITLE_BACKGROUND_COLOR,
          defaultDesign: true,
          isIgnoreButtonEnabled: false // SM-8217
        };
        this.sbModalPopupService.open(SaveCampaignComponent, saveMessageObject).result.then(() => {
          this.commercialService.clearLoadedCampaign().subscribe((res) => {
            this.searchService.resetCampaign(false, false, true);
            if (String(res.status) === 'OK') {
              SystemFlags.isLoadedCampaign = false;
              this.appHeaderService.changeResetConfig({ getConfig: true, getBricStructure: true });
              this.commercialService.setGoToOtherPage(true);
              this.dataShareService.activateResultTab(false);
              this.dataShareService.activateCommercialTab(false);
              this.router.navigate(['/search']);
            } else {
              this.logHelperService.logError(this.config.userBundle['common.error.connecting']);
            }
          });
        }, (reason) => {
          console.log(reason);
        });
        SystemFlags.keepAliveThreadActive = true;
      } else {
        if (status !== 5) {
          if (this.userBundle['commercial.error.largeCampaign'] && (data.message && (data.message.split('<=')[0] === this.userBundle['commercial.error.largeCampaign'].split('<=')[0]))) {
            // updated for SM-10198
            this.commercialService.openConfirmationModal(data.message,
                    this.userBundle[this.CONFIRMATION_LABEL].toUpperCase(), GLOBAL.DEFAULT_TITLE_BACKGROUND_COLOR, false, false, undefined, undefined, 'workspace');
          } else {
            const frameisOOCBundle = this.config.userBundle['commercial.error.oocFrame'] || 'Frame is OOC';
            const saveMessageObject: SaveCampaignResolvedObject = {
              message: message || 'Please Re-run Solution',
              title: this.config.userBundle[this.CONFIRMATION_LABEL].toUpperCase(),
              headerColor: GLOBAL.DEFAULT_TITLE_BACKGROUND_COLOR,
              isIgnoreButtonEnabled: message === frameisOOCBundle ? this.config.uiControl['ignoreOOCFrames'] : false,
            };
            this.redirectToWorkspace(saveMessageObject);
          }
        }
      }
    });
  }

  /**
   * @description open commercial screen validation ovcerlay
   * @author Darshan Vachhani
   * @memberof CommercialButtonService
   */
  openCommercialScreenValidationOverlay = (data, param) => {
    this.sbModalPopupService.open(CommercialValdiationComponent, data).result.then((result) => {
      if (result.callBookingService) {
        const paramObj = JSON.parse(param.data);
        this.saveCommercialCampaignData(param, paramObj.campaignStatusId);
      }
      if (result.updateDiscounts && data.discounts && data.discounts.length) {
        this.commercialService.updateDiscounts(data.discounts);
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  /**
   * @description this will simpley redirect to worksapce
   * @author Nishit Parekh
   * @param {Object} saveMessageObject message, title and header color
   * @memberof CommercialButtonService
   */
  redirectToWorkspace(saveMessageObject: SaveCampaignResolvedObject) {
    saveMessageObject.defaultDesign = true;
    this.sbModalPopupService.open(SaveCampaignComponent, saveMessageObject).result.then(
      (res) => {
        if (res && res === 'cancel') {
          let paramObj = JSON.parse(this.param.data);
          paramObj.ignoreOOCFrames = true; // if set true, excludes ooc check in backend
          this.param.data = JSON.stringify(paramObj);
          this.saveCommercialCampaignData(this.param, CampaignStatus.confirm);
        } else {
          // handles 'OK' and 'X' (dismiss) events
          SystemFlags.forcefullyCallProcessBrics = true;
          this.router.navigate(['/workspace']);
        }
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  /**
   * @description This is the common function to check the Validity of campaign(Workspace) if user changes the Advertiser in the commercial page
   * If the campaign is created with the Product of Specific Advertiser, and if Advertiser get changed on commercial page, we cant allow
   * the user to book the campaign
   * @author Shreni Shah, Amit Mahida
   * @param {CampaignDetails} campaignDetails
   * @returns {boolean}
   * @memberof CommercialButtonService
   */
  isProductsValidAgainstRestrictions(campaignDetails: CampaignDetails): boolean {
    const filter = this.stateService.getWorkspaceFilterObj();
    const productRow: Row = filter.rows.find(e => e.bric.bricid === this.brickBaseService.brickID.ProductCatalogue);
    if (productRow) {
      const productsUsedInCampaign = productRow.cells.filter((cell: Cell) => cell.selected && !_.isEmpty(cell.selected));
      const productsUsedInCampaignCount = productsUsedInCampaign.length;
      if (productsUsedInCampaignCount > 0) {
        const filterDisabledProducts = !SystemFlags.isLoadedCampaign;
        const inchargeRowIndex = filter.getExistingRowIndex(Number(this.brickBaseService.brickID.Incharge));
        campaignDetails = {
          ...campaignDetails,
          campaignTypeId: this.stateService.getCampaign().campaignTypeId
        };
        filter.pcmService.markProductBricInvalid(filter.rows, campaignDetails, inchargeRowIndex, filterDisabledProducts, this.stateService);
        const isAnyProductInvalid = productRow.cells.filter(cell => !cell.isEmpty && !cell.isHidden && !cell.isValid).length;
        if (isAnyProductInvalid) {
          const saveMessageObject = {
            message: this.config.userBundle['workspace.error.invalidBricks'] || 'Please Re-run Solution',
            title: this.config.userBundle['result.confirmation.label'].toUpperCase(),
            headerColor: GLOBAL.DEFAULT_TITLE_BACKGROUND_COLOR
          };
          this.redirectToWorkspace(saveMessageObject);
          return false;
        }
      }
    }
    return true;
  }

  /**
   * @description will compare 2 values
   * @author Nishit Parekh
   * @param {*} initial
   * @param {*} changed
   * @returns boolean value
   * @memberof CommercialButtonService
   */
  isValueChanged(initial: any, changed: any) {
    if (this.ignoreValues.indexOf(initial) > -1 && this.ignoreValues.indexOf(changed) > -1) {
      return false;
    } else if (typeof initial === 'object') {
      return JSON.stringify(initial) !== JSON.stringify(changed);
    } else if (initial === changed) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * @description validation for ROCancel [SM-5283]
   * @author Nishit Parekh
   * @param {*} uiControl from comemrcial response
   * @param {*} statusId
   * @returns if ROCancel should be triggered or not and if it needs to be a textbox or dropdown
   */
  reasonForCancelValidation(uiControl: any, statusId: number): boolean {
    // if enableROCancelForBookingStatus not present || enableROCancelForBookingStatus array is empty
    if (!uiControl.enableROCancelForBookingStatus || (uiControl.enableROCancelForBookingStatus && !uiControl.enableROCancelForBookingStatus.length)) {

      // no ROCancel pop up
      return false;

      // if enableROCancelForBookingStatus exists && (if enableROCancelReasonList does not exist || enableROCancelReasonList = false)
    } else if (uiControl.enableROCancelForBookingStatus && !uiControl.enableROCancelReasonList) {

      // ROCancel will be textbox if status id matches from enableROCancelForBookingStatus
      return !(!uiControl.enableROCancelForBookingStatus.length || uiControl.enableROCancelForBookingStatus.indexOf(statusId) === -1);

      // (if enableROCancelForBookingStatus present && enableROCancelForBookingStatus array is NOT empty) && (enableROCancelReasonList = true i.e show dropdown)
    } else if (uiControl.enableROCancelForBookingStatus && uiControl.enableROCancelForBookingStatus.length && uiControl.enableROCancelReasonList) {

      // if current campaignId is present in enableROCancelForBookingStatus array, show ROCancel else don't show
      return uiControl.enableROCancelForBookingStatus.indexOf(statusId) > -1;
    } else {

      // for all other cases don't show ROCancel
      return false;
    }
  }

  /**
   * @description validation for ROChange [SM-5283]
   * @author Nishit Parekh
   * @param {*} uiControl from comemrcial response
   * @param {*} statusId
   * @returns if ROChange should be triggered or not and if it needs to be a textbox or dropdown
   */
  reasonForChangeValidation(uiControl: any, statusId: number): boolean {
    // if (enableROChangeForBookingStatus not present or enableROChangeForBookingStatus is false) || enableROChangeForBookingStatus array is empty
    if (!uiControl.enableROChangeForBookingStatus || (uiControl.enableROChangeForBookingStatus && !uiControl.enableROChangeForBookingStatus.length)) {

      // no ROChange pop up
      return false;

      // if enableROChangeForBookingStatus exists && (if enableROChangeReasonList does not exist || enableROChangeReasonList is false)
    } else if (uiControl.enableROChangeForBookingStatus && !uiControl.enableROChangeReasonList) {

      // ROCchange will be textbox if status id matches from enableROChangeForBookingStatus
      return !(!uiControl.enableROChangeForBookingStatus.length || uiControl.enableROChangeForBookingStatus.indexOf(statusId) === -1);

      // (if enableROChangeForBookingStatus present && enableROChangeForBookingStatus array is NOT empty) && (enableROChangeReasonList = true i.e show dropdown)
    } else if (uiControl.enableROChangeForBookingStatus && uiControl.enableROChangeForBookingStatus.length && uiControl.enableROChangeReasonList) {

      // if current campaignId is present in enableROChangeForBookingStatus array, show ROChange popup else don't show
      return uiControl.enableROChangeForBookingStatus.indexOf(statusId) > -1;
    } else {

      // for all other cases don't show ROChange popup
      return false;
    }
  }
  /**
   * @description called when we click ok for reasonForChangeOverlay
   * @author Nishit Parekh
   * @param {CampaignDetails} campaignDetails
   * @param {*} result selected data of dropdown
   * @param {number} status current status of campaign
   * @returns {CampaignDetails}
   * @memberof CommercialButtonService
   */
  reasonForChangeOverlayThen(campaignDetails: CampaignDetails, result, status: number): CampaignDetails {
    const reasonType = result.freeText !== '' && result.freeText != null;
    if (status === CampaignStatus.cancel) {
      const comment = (this.startsWith(reasonType ? result.freeText : result.reasonForChange.auditReasonName, 'CANCELLED - ') ? '' : 'CANCELLED - ') + (reasonType ? result.freeText : result.reasonForChange.auditReasonName);
      campaignDetails.comments = campaignDetails.comments ? `${campaignDetails.comments}\n\r${comment}` : comment;
    }
    campaignDetails.reasonForChange = result.reasonForChange;
    if (reasonType) {
      campaignDetails.reasonForChange.freeText = result.freeText;
    }
    return campaignDetails;
  }

  /**
   * @description Trigger ROChange when ALL below conditions satisfied
   *    1. Campaign status should NOT BE cancelled i.e. user clicks update/save/book/proposal button etc
   *    2. uiControl boolean property {bgvGreaterThanZero} will decided if (bgvGreaterThanZero > 0) needs to be checked
   *    3. (reasonForChangeValidation) will decide if ROChange should be triggered or not
   *    4. (isCampaignAuditDetailsChanged) will decide if any details changed on commercial page based on uiControl property
   * @author Nishit Parekh
   * @param {*} status
   * @returns {boolean}
   * @memberof CommercialEsComponent
   */
  roChange(campaignDetails: CampaignDetails, uiControl: UiControl, propertyName: string, status: number, populateCampaignAuditDetailsObject?): boolean {
    return status !== CampaignStatus.cancel &&
      (!uiControl.bgvGreaterThanZero || (uiControl.bgvGreaterThanZero && campaignDetails.grossMediaValue > 0)) &&
      this.reasonForChangeValidation(uiControl, campaignDetails.campaignStatusId) &&
      this.isCampaignAuditDetailsChanged(campaignDetails, propertyName, uiControl, populateCampaignAuditDetailsObject);
  }

  /**
   * @description Trigger ROCancel when ALL below conditions are satisfied
   *    1. Campaign status should BE cancelled i.e. user clicks cancel button
   *    2. (reasonForCancelValidation) will decide if ROCancel should be triggered or not
   * @author Nishit Parekh
   * @param {*} status
   * @returns {boolean}
   * @memberof CommercialEsComponent
   */
  roCancel(campaignDetails, uiControl, status): boolean {
    return status === CampaignStatus.cancel && this.reasonForCancelValidation(uiControl, campaignDetails.campaignStatusId);
  }

}
