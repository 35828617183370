import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { LoaderService } from '../../geoplanner/src/app/core/services';
import { ResultMapComponent } from '../../geoplanner/src/app/result-map/result-map.component';

@Injectable()
export class CanDeactivateResultMapService implements CanDeactivate<ResultMapComponent> {

  constructor(private loaderService: LoaderService) { }

  // @ts-ignore
  canDeactivate(component: ResultMapComponent): boolean { // tslint:disable-line

    if (this.loaderService.getBackButton()) {
      this.loaderService.setBackButton(false);
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);
      return false;
    }
    return true;
  }
}
