<app-sb-modal-wrapper [title]="userBundle['workspace.label.continuework']" [titleBackgroundColor]="titleColor"
  (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <div class="tabs_container horizontal">
    <div style="padding: 10px 0px;font-size: 14px;">
      You can continue your work while the reshuffling is ongoing. Please keep the following in mind:<br />
      <ul>
        <li>
          You can open the campaigns that are being allocated by the reshuffling, but they will be in read only. You
          will be able to edit them once the reshuffling will be completed
        </li>
        <li>
          We recommend you to not create new campaigns on the same period of time that is being impacted by the
          reshuffling. Please try to work on other weeks while the reshuffling is still ongoing
        </li>
      </ul>
    </div>
  </div>
</app-sb-modal-wrapper>
