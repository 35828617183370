<app-sb-modal-wrapper 
[title]="userBundle['search.exclusion.exclusivity']" [titleBackgroundColor]="brickBgColor"
(closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
<div>
    <div class="control_group ">
        <input type="radio" name="pc_exclusivity" [(ngModel)]="exclusivityMask" [value]="1"
        id="btnFrameLevel" class="radio small">
        <label for="btnFrameLevel" class="flexSlide">
            {{userBundle['search.exclusion.productCategoryExclusivity.frameLevel']}}
        </label>
    </div>

    <div class="control_group">
        <input type="radio" name="pc_exclusivity"  [(ngModel)]="exclusivityMask" [value]="2" id="btnFurnitureLevel" 
        class="radio small">
        <label for="btnFurnitureLevel">{{userBundle['search.exclusion.productCategoryExclusivity.furnitureLevel']}}</label>
    </div>    
</div>
</app-sb-modal-wrapper>
  
