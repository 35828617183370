import { Component, OnInit, Input } from '@angular/core';
import { InitialConfigModel } from '../../../../models';
import { DataShareService } from '../../../services/data-share.service';
import { SwapCampaign } from '../../../../models/vp-swap';
import { LogHelperService } from '../../../services/log-helper.service';
import { GLOBAL } from '../../../utils/app.constant';

@Component({
  selector: 'app-digital-swap',
  templateUrl: './digital-swap.component.html',
  styleUrls: ['./digital-swap.component.css']
})
export class DigitalSwapComponent implements OnInit {
  @Input() resolveObject: any;
  initialConfig: InitialConfigModel;
  selectedBooking = '';
  displayDateFormat = GLOBAL.SWAP_DISPLAY_DATE_FORMAT;
  displayTimeFormat = GLOBAL.SWAP_DISPLAY_TIME_FORMAT;

  constructor(
    private dataShareService: DataShareService,
    private logHelperService: LogHelperService
  ) { }

  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    const selectedBooking = this.resolveObject.bookingDetails.filter(b => b.selected);
    const booking = selectedBooking.length ? selectedBooking[0] : null;
    this.selectedBooking = booking ? booking.campaignReference + booking.sot + booking.startDate + booking.endDate : '';
  }

  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }

  onModalSaved(event) {
    const selectedBooking = this.resolveObject.bookingDetails.filter(b => b.selected);
    if (!selectedBooking.length) {
      this.logHelperService.logInfo(this.initialConfig.userBundle['common.error.noDataSelect']);
      return;
    }
    event.activeModal.close(selectedBooking);
  }

  bookingChange(booking: SwapCampaign) {
    if (booking.selected) {
      this.selectedBooking = booking.campaignReference + booking.sot + booking.startDate + booking.endDate;
    } else {
      this.selectedBooking = '';
    }
  }

  trackByItem(index, item) {
    return item;
  }

}
