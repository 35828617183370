<div class="sidebar-wrapper_geo">
    <button class="filter-link_geo">{{configText.filter}}
        <span class="pull-right" (click)="onHideFilterArea()">
            <em class="fa fa-bars"></em>
        </span>
    </button>
    <br>
    <div class="form-group-wrapper_geo" style="padding-top: 15px;" [ngClass]="{'readOnly': systemFlags.readOnlyWorkspace && !systemFlags.splitable || readOnlyForSwap }">
        <div class="form-group_geo horizontal_geo">
            <label class="ellipsisText">{{brickTitle.channel}}
                <span *ngIf="displayMandatoryIcon(brickBaseService.brickID.Environment)">
                    *</span>
            </label>
            <div ngbDropdown (openChange)="openChangeChannel($event)" [autoClose]="false" #channelContainer
                class="form-control_geo dropdown">
                <button class="btnInput btn btn-outline-primary" id="dropdownBasic1"
                    ngbDropdownToggle>{{channelPlaceholder}}</button>
                <div class="dropdown-menu custom_drop_menu" aria-labelledby="dropdownBasic1" ngbDropdownMenu>
                    <ng-template ngFor let-channel [ngForOf]="channels" [ngForTrackBy]="trackById">
                        <div class="clearBoth" *ngIf="channel.isVisible">
                            <label class="center-block small">
                                <input type="checkbox" class="checkbox small" [checked]="channel.selected" [(ngModel)]="channel.selected"
                                    [disabled]="channel.disabled" (change)="onChannelChange(channel)" name="check-channel"
                                    id="channel-{{channel.id}}" />
                                <span>{{channel.name}}</span>
                            </label>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="form-group_geo horizontal_geo">
            <label>{{brickTitle.media}}
                <span *ngIf="displayMandatoryIcon(brickBaseService.brickID.Media)">
                    *</span>
            </label>
            <select class="form-control_geo" id="media" [(ngModel)]="selectedMedia" (change)="onMediaChange()"
                name="media">
                <option *ngFor="let media of medias; trackBy: trackByMediaType" [value]="media.mediaTypeId">{{media.mediaTypeName}}</option>
            </select>
            <!--<input type="text" class="form-control_geo" value="Digital & Paper">-->
        </div>
        <div class="form-group_geo horizontal_geo">
            <label>{{brickTitle.startDate}}
                <span *ngIf="displayMandatoryIcon(brickBaseService.brickID.Incharge)">
                    *</span>
            </label>
            <!-- <input type="text" class="form-control_geo" placeholder="{{configText.select + ' ' +brickTitle.startDate}}"
                name="startdate" #startdate [ngModel]="startDate | date:displayDateFormat" ui-date="dateOptionsFrom"
                readonly /> -->
            <input type="text" class="form-control_geo" placeholder="{{configText.select + ' ' +brickTitle.startDate}}"
                name="startdate" #startdate="ngbDatepicker" (dateSelect)="onSelectFrom($event)"
                [(ngModel)]="startDate" [dayTemplate]="startDayTemplate" [minDate]="minStartDate" [outsideDays]="'collapsed'"
                ngbDatepicker (click)="openDatePicker($event, startdate, enddate)" (document:click)="closeDatePickerOutsideClick($event, startdate)" readonly>
        </div>
        <div class="form-group_geo horizontal_geo">
            <label>{{brickTitle.endDate}}
                <span *ngIf="displayMandatoryIcon(brickBaseService.brickID.Incharge)">
                    *</span>
            </label>
            <!-- <input type="text" class="form-control_geo" placeholder="{{configText.select + ' ' +brickTitle.endDate}}"
                name="enddate" #enddate [ngModel]="endDate | date:displayDateFormat" ui-date="dateOptionsTo" [disabled]="!startDate"
                readonly /> -->
            <input type="text" class="form-control_geo" placeholder="{{configText.select + ' ' +brickTitle.endDate}}"
                name="enddate" [disabled]="!startDate" #enddate="ngbDatepicker" (ngModelChange)="onSelectTo($event)"
                [(ngModel)]="endDate" [dayTemplate]="endDayTemplate" [minDate]="minEndDate" [outsideDays]="'collapsed'"
                ngbDatepicker (click)="openDatePicker($event, enddate, startdate)" (document:click)="closeDatePickerOutsideClick($event, enddate)" readonly>
        </div>

        <div class="form-group_geo horizontal_geo" *ngIf="showPastDaysVisible">
            <label class="small col-sm-6">
                <input class="checkbox small" id="showPastDays" type="checkbox" [(ngModel)]="showPastDates" (change)="onPastDaysVisibilityChange()">
                <span>{{initialConfig.userBundle['visualplanner.filter.showPastDays'] || 'Show past days'}}</span>
            </label>
        </div>
        <div class="clearfix"></div>
    </div>

    <div class="accordion-wrapper_geo active" style="height: 200px;" [ngClass]="{'collapsed': isOptionalFilterHidden, 'readOnly': systemFlags.readOnlyWorkspace && !systemFlags.splitable || readOnlyForSwap}">
        <h3 class="form-title_geo" (click)="toggleFilterSection('OPTIONAL')">
            <span class="arrows">
                <img src="images/arrow.png" alt="">
            </span>
            <span class="title">{{configText.addons}}</span>
        </h3>
        <div class="accordion_content">
            <ul class="list_icons">
                <li class="tags_icons" [ngClass]="{'hasFilterData': hasFilterData.Location}"
                    (click)="openLocationFilter()">
                    <a>
                        <figure [ngStyle]="{'background-color':brickBaseService.brickColor.Location}">
                            <img src="images/options_pin.png" alt="">
                        </figure>
                        <span class="text_tags">{{brickTitle.location}}</span>
                    </a>
                </li>
                <li class="tags_icons" [ngClass]="{'hasFilterData': hasFilterData.Proximity}" (click)="openPOIFilter()">
                    <a>
                        <figure [ngStyle]="{'background-color':brickBaseService.brickColor.Proximity}">
                            <img src="images/options_proximity.png" alt="">
                        </figure>
                        <span class="text_tags">{{brickTitle.proximity}}</span>
                    </a>
                </li>
                <li class="tags_icons" [ngClass]="{'hasFilterData': hasFilterData.Tag}" (click)="openTagFilter()">
                    <a>
                        <figure [ngStyle]="{'background-color':brickBaseService.brickColor.Tag}">
                            <img src="images/options_tags.png" alt="">
                        </figure>
                        <span class="text_tags">{{brickTitle.tags}}</span>
                    </a>
                </li>
                <li class="tags_icons" [ngClass]="{'hasFilterData': hasFilterData.Format}" (click)="openFormatFilter()">
                    <a>
                        <figure [ngStyle]="{'background-color':brickBaseService.brickColor.Format}">
                            <img src="images/options_formats.png" alt="">
                        </figure>
                        <span class="text_tags">{{brickTitle.formats}}</span>
                    </a>
                </li>
                <li class="tags_icons" [ngClass]="{'hasFilterData': hasFilterData.SOT}" (click)="openSOTFilter()">
                    <a>
                        <figure [ngStyle]="{'background-color':brickBaseService.brickColor.SOT}">
                            <img src="images/options_SOT.png" alt="">
                        </figure>
                        <span class="text_tags">{{brickTitle.sot}}</span>
                    </a>
                </li>
                <li class="tags_icons hidden" [ngClass]="{'hasFilterData': hasFilterData.Audience}"
                    (click)="openAudienceFilter()">
                    <a>
                        <figure [ngStyle]="{'background-color':brickBaseService.brickColor.Audience}">
                            <img src="images/options_audience.png" alt="">
                        </figure>
                        <span class="text_tags">{{brickTitle.audience}}</span>
                    </a>
                </li>
                <li class="tags_icons" [ngClass]="{'hasFilterData': hasFilterData.FrameIllumination}" (click)="openFrameFilter()">
                    <a>
                        <figure [ngStyle]="{'background-color':brickBaseService.brickColor.FrameIllumination}">
                            <img src="images/options_illumination.png" alt="">
                        </figure>
                        <span class="text_tags">{{brickTitle.frameFilter}}</span>
                    </a>
                </li>
                <li class="tags_icons hidden" [ngClass]="{'hasFilterData': hasFilterData.Facing}"
                    (click)="openFacingFilter()">
                    <a>
                        <figure [ngStyle]="{'background-color':brickBaseService.brickColor.Facing}">
                            <img src="images/options_facing.png" alt="">
                        </figure>
                        <span class="text_tags">{{brickTitle.facing}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="clearfix"></div>
    </div>

    <div class="accordion-wrapper_geo active last" style="height: 119px;" [ngClass]="{'collapsed': isTemplateFilterHidden, 'readOnly': systemFlags.readOnlyWorkspace && !systemFlags.splitable || readOnlyForSwap}">
        <h3 class="form-title_geo" (click)="toggleFilterSection('TEMPLATE')">
            <span class="arrows">
                <img src="images/arrow.png" alt="">
            </span>
            <span class="title">{{configText.templates}}</span>
        </h3>
        <div class="accordion_content">
            <ul class="list_icons">
                <li class="tags_icons hidden">
                    <a>
                        <figure style="background:#f0b501;">
                            <img src="images/templates_package.png" alt="">
                        </figure>
                        <span class="text_tags">{{configText.package}}</span>
                    </a>
                </li>
                <li class="tags_icons" [ngClass]="{'hasFilterData': hasFilterData.List}" (click)="openListFilter()">
                    <a>
                        <figure [ngStyle]="{'background-color':brickBaseService.brickColor.List}">
                            <img src="images/templates_list.png" alt="">
                        </figure>
                        <span class="text_tags">{{brickTitle.list}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="clearfix"></div>
        <!-- <div class="form-group_geo horizontal_geo" *ngIf="listFrameLookups.lookup">
            <label>{{brickTitle.frame}}</label>
            <div id="tabFrameId" class="tab-content" [ngStyle]="{'display': 'block'}">
                <app-lookup-view [lookupType]="listFrameLookups.lookup[0].lookupType" [selectionId]="listFrameLookups.lookup[0].selectionId"
                    [placeholder]="listFrameLookups.lookup[0].displayName" [regexp]="listFrameLookups.lookup[0].regExp" [(ngModel)]="routeFrameId"
                    [displayType]="listFrameLookups.lookup[0].displayType" name="routeFrameId" (ngModelChange)="onRouteChange($event)">
                </app-lookup-view>
            </div>
            <input type="text" class="form-control_geo" placeholder="Search frame id...">
        </div> -->
        <div class="clearfix"></div>
    </div>
    <div *ngIf="showExecuteButton" class="accordion-wrapper_geo" style="text-align: center;">
        <button *ngIf="isVisualPlanner" class="execute-btn" [ngClass]="{'execute-btn-clicked': requestProcess.isProcessedVp}" (click)="execute()" [disabled]="!requestProcess.isValidRequestJsonVp || readOnlyForSwap">Execute</button>
        <button *ngIf="isGeoMapper" class="execute-btn" [ngClass]="{'execute-btn-clicked': requestProcess.isProcessedGm}" (click)="execute()" [disabled]="!requestProcess.isValidRequestJsonGm">Execute</button>
    </div>
</div>
<ng-template #startDayTemplate let-date="date" let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled">
    <span class="day-container" [class.hidden]="date.month !== currentMonth" [class.text-muted]="getStartDayProp(date, 'isDisabled')">
      <div class="custom-day"
        [class.today]="getStartDayProp(date, 'isToday')"
        [class.weekend]="getStartDayProp(date, 'isWeekday')"
        [class.selected]="selected"
      >
        {{ date.day }}
    </div>
    </span>
</ng-template>
<ng-template #endDayTemplate let-date="date" let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled">
    <span class="day-container" [class.hidden]="date.month !== currentMonth" [class.text-muted]="getEndDayProp(date, disabled, 'isDisabled')">
      <div class="custom-day"
        [class.today]="getEndDayProp(date, disabled, 'isToday')"
        [class.weekend]="getEndDayProp(date, disabled, 'isWeekday')"
        [class.selected]="selected"
      >
        {{ date.day }}
    </div>
    </span>
</ng-template>
