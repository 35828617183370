import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DataShareService, LogHelperService, CellAttributeService, BrickBaseService } from '../../../core/services/index';
import { CellValues } from './../../../models/workspace';
import { AudienceBricService } from './../../../core/services';
import { PrimaryAudienceBase } from '../primary-audience-base';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-primary-audience',
  templateUrl: './primary-audience-modal.component.html'
})

export class PrimaryAudienceModalComponent extends PrimaryAudienceBase implements OnInit {

  @Input() resolveObject: CellValues;
  @ViewChild('instance') typeaheadInstance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  readOnlyModal = false;

  constructor(
    dataService: DataShareService,
    private brickBaseService: BrickBaseService,
    audienceBricService: AudienceBricService,
    private logHelperService: LogHelperService,
    private cellAttributeService: CellAttributeService
  ) {
    super(dataService, audienceBricService);
  }

  ngOnInit(): void {
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.init(this.resolveObject.brick.audienceCategoryGroupId);
    if (this.resolveObject.selectedValues) {
      for (const key in this.resolveObject.selectedValues) {
        if (this.resolveObject.selectedValues.hasOwnProperty(key)) {
          const audienceCategoryTypeIndex = this.audienceCategoryType.findIndex(audienceCategoryType => audienceCategoryType.audienceCategoryTypeId.toString() === key);
          const audienceCategories = this.resolveObject.selectedValues[key];
          for (const audienceCategory of audienceCategories) {
            const selectedAudienceCat = this.audienceCategoryType[audienceCategoryTypeIndex].audienceCategory.filter(audienceCat => audienceCat.audienceCategoryId === audienceCategory.audienceCategoryId)[0];
            selectedAudienceCat.selected = true;
          }
        }
      }
      this.manageCheckAll();
    }
  }

  onModalSaved(event) {
    const selectedAudiences = {};
    for (const categoryType of this.audienceCategoryType) {
      if (categoryType.audienceCategory.filter(category => category.selected).length === 0) {
        this.logHelperService.logError(this.userBundle['primaryAudience.error.noDataSelect']);
        return;
      }
      const obj = {};
      obj[categoryType.audienceCategoryTypeId] = [];
      for (const category of categoryType.audienceCategory) {
        if (category.selected) {
          obj[categoryType.audienceCategoryTypeId].push(category);
        }
      }
      if (obj[categoryType.audienceCategoryTypeId].length) {
        selectedAudiences[categoryType.audienceCategoryTypeId] = obj[categoryType.audienceCategoryTypeId];
      }
    }
    if (Object.keys(selectedAudiences).length) {
      const values: CellValues = new CellValues();
      values.brick = this.resolveObject.brick;
      values.selectedValues = selectedAudiences;
      values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.PrimaryAudience,
        selectedAudiences);
      values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.PrimaryAudience,
        selectedAudiences);
      values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.PrimaryAudience, values.selectedValues);
      event.activeModal.close(values);
    } else {
      this.logHelperService.logError(this.userBundle['common.error.noDataSelect']);
    }
  }
  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }

  /**
   * @description on checkbox change event check all selectio is checked or not
   * @memberof PrimaryAudienceModalComponent
   */
  isCheckAll = (audienceCategoryType, category) => {
    category.selected = !category.selected;
    audienceCategoryType.selected = audienceCategoryType.audienceCategory.filter(audienceCategory => audienceCategory.selected).length === audienceCategoryType.audienceCategory.length;
  }

  /**
   * @description manage all selection checked
   * @author Kishan Patel
   * @memberof PrimaryAudienceModalComponent
   */
  manageCheckAll() {
    for (const audienceCategoryType of this.audienceCategoryType) {
      /* tslint:disable:no-string-literal */
      audienceCategoryType['selected'] = audienceCategoryType.audienceCategory.filter(audienceCategory => audienceCategory.selected).length === audienceCategoryType.audienceCategory.length;
    }
  }

  /**
   * @description  check all cateogories in selected categoriesType  select all
   * @author Kishan Patel
   * @param {*} categoryType
   * @memberof PrimaryAudienceModalComponent
   */
  checkAllCategoriesInType(categoryType) {
    categoryType.selected = !categoryType.selected;
    for (const audienceCategory of categoryType.audienceCategory) {
      audienceCategory.selected = categoryType.selected;
    }
  }

  trackByAudienceCategoryType(index, categoryType) {
    return categoryType?.audienceCategoryTypeId;
  }

  trackByAudienceCategory(index, category) {
    return category?.audienceCategoryId;
  }
}
