<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
[readOnlyModal]="readOnlyModal"
 (closed)="onModalClosed($event)"
    (saved)="onModalSaved($event)">
    <div class="modal_content" [ngClass]="{'readOnly' : readOnlyModal}">
        <div class="mediablocks">
            <div class="labelsrating">
                <div *ngFor="let media of mediaTypes; trackBy: trackByMediaType;">
                    <input type="radio" class="radio small" value="{{media.mediaTypeId}}" [(ngModel)]="mediaModal.selectedMedia"
                        name="radiogroup_media" id="rbtRating-{{media.mediaTypeId}}" (change)="onMediaSelect(media)" />
                    <label for="rbtRating-{{media.mediaTypeId}}">{{media.mediaTypeName}}</label>
                </div>
            </div>
        </div>
    </div>
</app-sb-modal-wrapper>
