import { Injectable } from '@angular/core';
import { FilterBarService } from './../../filter/filter-bar/filter-bar.service';
import { RequestJsonService } from './../../geo-map/request-json.service';
import { BrickBaseService } from './brick-base.service';
import { SharedService } from './shared.service';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import {
  SOTBricSelectedDataModel, FilterDataModel, InchargeBricSelectedDataModel,
  FormatBricSelectedDataModel, LocationBricSelectedDataModel, TagBricSelectedDataModel,
  MediaBricSelectedDataModel, SelectionItemModel
} from '../../models';
import { GoogleAnalyticsEvents, GoogleAnalyticsEventCategory } from '../../GoogleAnalytics/GoogleAnalyticsEvent';
import * as _ from 'lodash';

@Injectable()
export class FilterDataService {

  /**
   * It contains selected filter data.
   */
  public filterData: FilterDataModel = {};
  /**
   * It contains last filter data for restore after toggling cart view.
   */
  private backupFilterData: FilterDataModel = {};
  /**
   * Observable variables for media filter.
   */
  private filterDataSource: BehaviorSubject<FilterDataModel> = new BehaviorSubject(this.filterData);
  public filterData$: Observable<FilterDataModel> = this.filterDataSource.asObservable();
  readonly FILTER_CHANGE = 'Filter change.';
  /**
   * Constructor
   * @param filterBarService injected the filter bar service object
   * @param requestJsonService injected the request JSON service object
   * @param brickBaseService injected the brick-base service object
   */
  constructor(
    private filterBarService: FilterBarService,
    private requestJsonService: RequestJsonService,
    private brickBaseService: BrickBaseService,
    private sharedService: SharedService) {
    this.resetAllFilters();
  }

  resetAllFilters(): void {
    _.each(this.brickBaseService.brickID, (val) => {
      this.filterData[val] = {};
    });
  }

  setBackupFilterData() {
    this.backupFilterData = _.cloneDeep(this.filterData);
  }

  restoreFilterData(): void {
    if (Object.keys(this.backupFilterData).length > 0) {
      _.each(this.brickBaseService.brickID, (val) => {
        switch (val) {
          case this.brickBaseService.brickID.Audience:
            this.setRating(this.backupFilterData[val]);
            break;
          case this.brickBaseService.brickID.Environment:
            this.setChannel(this.backupFilterData[val]);
            break;
          case this.brickBaseService.brickID.Facing:
            this.setFacing(this.backupFilterData[val]);
            break;
          case this.brickBaseService.brickID.Format:
            this.setFormat(this.backupFilterData[val]);
            break;
          case this.brickBaseService.brickID.FrameIllumination:
            this.setFrame(this.backupFilterData[val]);
            break;
          case this.brickBaseService.brickID.Incharge:
            break;
          case this.brickBaseService.brickID.List:
            this.setList(this.backupFilterData[val]);
            break;
          case this.brickBaseService.brickID.Location:
            this.setLocation(this.backupFilterData[val], null);
            break;
          case this.brickBaseService.brickID.Media:
            this.setMedia(this.backupFilterData[val]);
            break;
          case this.brickBaseService.brickID.Proximity:
            this.setProximity(this.backupFilterData[val]);
            break;
          case this.brickBaseService.brickID.SOT:
            this.setShare(this.backupFilterData[val]);
            break;
          case this.brickBaseService.brickID.Tag:
            this.setTags(this.backupFilterData[val]);
            break;
          default:
            break;
        }
      });
      this.setIncharge(this.backupFilterData[this.brickBaseService.brickID.Incharge]);
    }
  }

  /**
   * Filter Data change method
   * @memberof FilterDataService
   */
  filterDataChange(): void {
    this.filterDataSource.next(this.filterData);
  }

  /**
   * Clone the JSON object
   * @param value JSON object
   * @return Cloned JSON object
   */
  private cloneDeep(value: any): any {
    return JSON.parse(JSON.stringify(value));
  }

  /**
   * Set the rating filter
   * @param value Rating data
   */
  setRating(value: any): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Filter, 'Audience', this.FILTER_CHANGE);
    this.filterData[this.brickBaseService.brickID.Audience] = this.cloneDeep(value);
    this.filterBarService.prepareAudienceBricFilterBar(this.filterData[this.brickBaseService.brickID.Audience]);
    this.requestJsonService.prepareAudienceBricReqJson(this.filterData[this.brickBaseService.brickID.Audience]);
    this.filterDataChange();
  }

  /**
   * Get the rating data.
   * @return cloned rating data
   */
  getRating(): any {
    return this.cloneDeep(this.filterData[this.brickBaseService.brickID.Audience]);
  }

  /**
   * Set the share filter
   * @param value Share data
   */
  setShare(value: SOTBricSelectedDataModel): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Filter, 'SOT', this.FILTER_CHANGE);
    this.filterData[this.brickBaseService.brickID.SOT] = this.cloneDeep(value);
    this.filterBarService.prepareSotBricFilterBar(this.filterData[this.brickBaseService.brickID.SOT]);
    this.requestJsonService.prepareBrickRequestJson(this.brickBaseService.brickID.SOT,
      this.filterData[this.brickBaseService.brickID.SOT]);
    this.filterDataChange();
  }

  /**
   * Get the rating data.
   * @return cloned rating data
   */
  getShare(): SOTBricSelectedDataModel {
    return this.cloneDeep(this.filterData[this.brickBaseService.brickID.SOT]);
  }

  /**
   * Set the incharge filter
   * @param value Incharge data
   */
  setIncharge(value: InchargeBricSelectedDataModel): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Filter, 'Incharge', this.FILTER_CHANGE);
    this.filterData[this.brickBaseService.brickID.Incharge] = this.cloneDeep(value);
    this.filterBarService.prepareInchargeBricFilterBar(this.filterData[this.brickBaseService.brickID.Incharge]);
    this.requestJsonService.prepareBrickRequestJson(this.brickBaseService.brickID.Incharge,
      this.filterData[this.brickBaseService.brickID.Incharge]);
    this.filterDataChange();
  }

  /**
   * Get the rating data.
   * @return cloned rating data
   */
  getIncharge(): InchargeBricSelectedDataModel {
    return this.cloneDeep(this.filterData[this.brickBaseService.brickID.Incharge]);
  }

  /**
   * Set the format filter
   * @param value Format data
   */
  setFormat(value: FormatBricSelectedDataModel): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Filter, 'Format', this.FILTER_CHANGE);
    this.filterData[this.brickBaseService.brickID.Format] = this.cloneDeep(value);
    this.filterBarService.prepareFormatBricFilterBar(this.filterData[this.brickBaseService.brickID.Format]);
    this.requestJsonService.prepareBrickRequestJson(this.brickBaseService.brickID.Format,
      this.filterData[this.brickBaseService.brickID.Format]);
    this.filterDataChange();
  }

  /**
   * Get the rating data.
   * @return cloned rating data
   */
  getFormat(): FormatBricSelectedDataModel {
    return this.cloneDeep(this.filterData[this.brickBaseService.brickID.Format]);
  }

  /**
   * Set the location filter
   * @param value Location data
   */
  setLocation(value: LocationBricSelectedDataModel, fileList: any): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Filter, 'Location', 'Filter change.');
    this.filterData[this.brickBaseService.brickID.Location] = this.cloneDeep(value);
    if (fileList) {
      this.filterData[this.brickBaseService.brickID.Location].fileList = fileList;
    }
    this.filterBarService.prepareLocationBricFilterBar(this.filterData[this.brickBaseService.brickID.Location]);
    this.requestJsonService.prepareBrickRequestJson(this.brickBaseService.brickID.Location,
      this.filterData[this.brickBaseService.brickID.Location]);
    this.filterDataChange();
  }

  /**
   * Get the location data.
   * @return cloned location data
   */
  getLocation(): LocationBricSelectedDataModel {
    return this.cloneDeep(this.filterData[this.brickBaseService.brickID.Location]);
  }

  /**
   * Set the proximity filter
   * @param value Proximity data
   */
  setProximity(value: any): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Filter, 'Proximity', this.FILTER_CHANGE);
    this.filterData[this.brickBaseService.brickID.Proximity] = value;
    this.filterBarService.prepareProximityBricFilterBar(this.filterData[this.brickBaseService.brickID.Proximity]);
    this.requestJsonService.prepareBrickRequestJson(this.brickBaseService.brickID.Proximity,
      this.filterData[this.brickBaseService.brickID.Proximity]);
    this.filterDataChange();
  }

  /**
   * Get the proximity data.
   * @return cloned proximity data
   */
  getProximity(): any {
    return this.filterData[this.brickBaseService.brickID.Proximity];
  }

  /**
   * Set the list filter
   * @param value List data
   */
  setList(value: any): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Filter, 'List', this.FILTER_CHANGE);
    this.filterData[this.brickBaseService.brickID.List] = value;
    this.filterBarService.prepareListFilterBar(this.filterData[this.brickBaseService.brickID.List]);
    this.requestJsonService.prepareBrickRequestJson(this.brickBaseService.brickID.List,
      this.filterData[this.brickBaseService.brickID.List]);
    this.filterDataChange();
  }

  /**
   * Get the list data.
   * @return cloned list data
   */
  getList(): any {
    return this.filterData[this.brickBaseService.brickID.List];
  }

  /**
   * Set the tags filter
   * @param value Tags data
   */
  setTags(value: TagBricSelectedDataModel): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Filter, 'Tag', this.FILTER_CHANGE);
    this.filterData[this.brickBaseService.brickID.Tag] = this.cloneDeep(value);
    this.filterBarService.prepareTagBricFilterBar(this.filterData[this.brickBaseService.brickID.Tag]);
    this.requestJsonService.prepareBrickRequestJson(this.brickBaseService.brickID.Tag,
      this.filterData[this.brickBaseService.brickID.Tag]);
    this.filterDataChange();
  }

  /**
   * Get the tags data.
   * @return cloned tags data
   */
  getTags(): TagBricSelectedDataModel {
    return this.cloneDeep(this.filterData[this.brickBaseService.brickID.Tag]);
  }

  /**
   * Set the media filter
   * @param value Media data
   */
  setMedia(value: MediaBricSelectedDataModel): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Filter, 'Media', this.FILTER_CHANGE);
    this.filterData[this.brickBaseService.brickID.Media] = this.cloneDeep(value);
    this.filterBarService.prepareMediaBricFilterBar(this.filterData[this.brickBaseService.brickID.Media]);
    this.requestJsonService.prepareBrickRequestJson(this.brickBaseService.brickID.Media,
      this.filterData[this.brickBaseService.brickID.Media]);
    this.filterDataChange();
  }

  /**
   * Get the media data.
   * @return cloned media data
   */
  getMedia(): MediaBricSelectedDataModel {
    return this.cloneDeep(this.filterData[this.brickBaseService.brickID.Media]);
  }

  /**
   * Set the channel filter
   * @param value Channel data
   */
  setChannel(value: SelectionItemModel[]): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Filter, 'Channel', this.FILTER_CHANGE);
    this.filterData[this.brickBaseService.brickID.Environment] = this.cloneDeep(value);
    this.filterBarService.prepareChannelBricFilterBar(this.filterData[this.brickBaseService.brickID.Environment]);
    if (value.length > 0) {
      const channelSelectionData = this.sharedService.getLookupColumnData(this.brickBaseService.brickID.Environment, null);
      const channelSelectionId = channelSelectionData.lookup[0].selectionId;
      const selected = {
        [channelSelectionId]: this.filterData[this.brickBaseService.brickID.Environment],
      };
      this.requestJsonService.prepareBrickRequestJson(this.brickBaseService.brickID.Environment, selected);
    } else {
      this.requestJsonService.prepareBrickRequestJson(this.brickBaseService.brickID.Environment, value);
    }
    this.filterDataChange();
  }

  /**
   * Get the channel data.
   * @return cloned channel data
   */
  getChannel(): SelectionItemModel[] {
    return this.cloneDeep(this.filterData[this.brickBaseService.brickID.Environment]);
  }

  // Frame Bric
  setFrame(value: any): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Filter, 'Frame', this.FILTER_CHANGE);
    this.filterData[this.brickBaseService.brickID.FrameIllumination] = this.cloneDeep(value);
    this.filterBarService.prepareFrameFilterBar(this.filterData[this.brickBaseService.brickID.FrameIllumination]);
    this.requestJsonService.prepareBrickRequestJson(this.brickBaseService.brickID.FrameIllumination,
      this.filterData[this.brickBaseService.brickID.FrameIllumination]);
    this.filterDataChange();
  }

  getFrame(): any {
    return this.cloneDeep(this.filterData[this.brickBaseService.brickID.FrameIllumination]);
  }

  /**
   * Set the Facing filter
   * @param value facing data
   */
  setFacing(value: any): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Filter, 'Facing', this.FILTER_CHANGE);
    this.filterData[this.brickBaseService.brickID.Facing] = this.cloneDeep(value);
    this.filterBarService.prepareFacingBricFilterBar(this.filterData[this.brickBaseService.brickID.Facing]);
    this.requestJsonService.prepareBrickRequestJson(this.brickBaseService.brickID.Channel,
      this.filterData[this.brickBaseService.brickID.Channel]);
    this.filterDataChange();
  }

  /**
   * Get the Facing data.
   * @return cloned Facing data
   */
  getFacing(): any {
    return this.cloneDeep(this.filterData[this.brickBaseService.brickID.Facing]);
  }
}
