<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
    (closed)="onModalClosed($event)" [readOnlyModal]="readOnlyModal" (saved)="onModalSaved($event)">
    <div class="tabs_container horizontal">
        <div class="productTabs">
            <ul class="tabs-menu tab-menu-overwrite">
                <li class="" *ngFor="let tab of tabList; trackBy: trackByProductGroup; let i = index;" [ngClass]="{'current': i == activeTabIndex}">
                    <a style="cursor: pointer;" (click)="onTabChange(i)">
                        <span class="text">{{tab.productGroupName}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab" [ngClass]="{'readOnly' : readOnlyModal}">
            <div class="tab-content" style="display:block;">
                <app-tree-view [data]="products" [options]="options" [templateProps]="templateProps"
                    [(selectedRelativeFilters)]="selectedRelativeFilters">
                </app-tree-view>
            </div>
        </div>
    </div>

</app-sb-modal-wrapper>
