<div class="container">
  <ul>
    <li>
      <button type="button" class="btn btn-primary" (click)="sortAscending()">Sort Ascending</button>
    </li>
    <li>
      <button type="button" class="btn btn-primary" (click)="sortDescending()">Sort Descending</button>
    </li>
    <li>
      <button type="button" class="btn btn-primary" [hidden]="!isColumnSorted()" (click)="removeSort()">Remove Sort</button>
    </li>
    <li>
      <button type="button" class="btn btn-primary" (click)="hideColumn()">Hide Column</button>
    </li>
    <li>
      <button type="button" class="btn btn-primary" [hidden]="params.column.pinned == 'left'" (click)="pinLeft()">Pin Left</button>
    </li>
    <li>
      <button type="button" class="btn btn-primary" [hidden]="params.column.pinned == 'right'" (click)="pinRight()">Pin Right</button>
    </li>
    <li>
      <button type="button" class="btn btn-primary" [hidden]="!params.column.pinned" (click)="unpin()">Unpin</button>
    </li>
  </ul>
</div>