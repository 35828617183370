import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataShareService } from '../../../app/core/services/data-share.service';
import { LogHelperService } from './../../core/services/log-helper.service';
import { GLOBAL } from '../../core/utils/app.constant';
import { UiControl } from '../../models';
import { SharedService } from '../../../app/core/services/shared.service';

@Component({
  selector: 'app-confirmation-pp-modal',
  templateUrl: './confirmation-modal-pp.component.html',
  styleUrls: ['./confirmation-modal-pp.component.css']
})

export class ConfirmationModalPpComponent implements OnInit {

  @Output() goToWorkspacePage = new EventEmitter<any>();
  @Input() resolveObject: any;

  uiControl: UiControl;
  userBundle: object;
  url = '';
  message = '';
  title = '';
  displalabel = '';
  showInfoLabel: boolean;
  isConfirmProposal: boolean;
  brickBgColor: string;

  constructor(private logHelper: LogHelperService,
              private dataShareService: DataShareService, private sharedService: SharedService) { }

  ngOnInit() {
    this.url = this.resolveObject.url;
    this.title = this.resolveObject.title;
    this.message = this.resolveObject.message;
    this.displalabel = this.resolveObject.displalabel;
    this.showInfoLabel = this.resolveObject.showInfoLabel;
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.brickBgColor = this.resolveObject.color || GLOBAL.DEFAULT_TITLE_BACKGROUND_COLOR;
    this.isConfirmProposal = this.resolveObject.isConfirmProposal;
  }

  copy() {
    const txt = this.url;
    if (!txt || txt === '') {
      return;
    }
    this.copyTextToClipboard(txt);
  }

  copyTextToClipboard(text) {

    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      const message = this.userBundle['common.info.URLCopied'];
      this.logHelper.logSuccess(message);
    } catch (err) {
      const message = `${this.displalabel} ${this.userBundle['common.info.unableToCopy']}`;
      this.logHelper.logError(message);
    }
    document.body.removeChild(textArea);

  }

  onModalSaved(event) {
    this.goToWorkspacePage.emit();
    // showInfoLabel will be true in case of proposal and false in case of performance
    this.callResetUIForProposalCampaings();
    event.activeModal.close();

  }

  onModalClosed(event) {
    this.callResetUIForProposalCampaings();
    event.activeModal.dismiss('dismiss');
  }

  callResetUIForProposalCampaings() {
    if (this.uiControl.proposalCampaign && this.showInfoLabel) {
      const param = { action: 'resetUI' };
      this.sharedService.clearLoadedCampaign(param, this.dataShareService.
        getServiceCallUrlByKey('CLEAR_LOADED_CAMPAIGN')).subscribe((data) => {
          if (data.status === 'OK') {
            this.dataShareService.setRootScopeByKey('dontUpdateCommercialCall', false);
            this.dataShareService.setRootScopeByKey('resetUICalled', true);
          }
        }, (error) => {
          console.log(error);
        });
    }
  }
}
