import { Component, OnInit, Input } from '@angular/core';
import { CellValues, PatternWindow, ProductHelper } from '../../../models/workspace/index';
import {
  DataShareService,
  StateService,
  LogHelperService,
  CellAttributeService,
  BrickBaseService,
  PcmService
} from '../../../core/services';
import { PattenService } from '../pattern-modal.service';
import { PatternBase } from './../pattern-base';
import * as _ from 'lodash';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';

@Component({
  selector: 'app-pattern-modal',
  templateUrl: './pattern-modal.component.html',
  styleUrls: ['./pattern-modal.component.css'],
  providers: [PattenService, PatternWindow]
})

export class PatternModalComponent extends PatternBase implements OnInit {

  @Input() resolveObject: CellValues;
  cellIndex: number;
  productValidation: any;

  constructor(
    dataService: DataShareService,
    public pattenService: PattenService,
    public pcmService: PcmService,
    stateService: StateService,
    private cellAttributeService: CellAttributeService,
    private brickBaseService: BrickBaseService,
    appHeaderService: AppHeaderService,
    private logHelperService: LogHelperService) {
    super(dataService, pattenService, stateService, logHelperService, appHeaderService);
  }

  ngOnInit() {
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    if (!this.pcmMode) {
      this.selectedRange = this.pattenService.getSelectedRange(this.resolveObject.brick);
      if (this.resolveObject.productValidations) {
        _.assign(this.selectedRange, this.resolveObject.productValidations);
      }
    }
    this.init(this.resolveObject.selectedValues);
    this.cellIndex = this.resolveObject.brick.cellIndex;
    const productDetails: ProductHelper[] = this.pcmMode ? [] : this.stateService.getWorkspaceObject('productDetails');

    if (productDetails) {
      const productDetail = productDetails.find(pd => pd.columnIndex === this.cellIndex);
      if (productDetail && productDetail.validation) {
        const inchargeBricValidations = productDetail.validation[this.cellIndex][this.brickBaseService.brickID.Incharge];
        this.productValidation = Object.keys(inchargeBricValidations).length ? inchargeBricValidations : null;
      }
    }
  }

  /**
   * This method is to handle a rule mentioned in SM-4637 (Rule 2-enforcePCMRulesForPattern)
   * @param selected object of selected variable
   */
  checkForPCMValidationRule(selected): boolean {
    if (this.uiControl.enforcePCMRulesForPattern && this.productValidation && this.productValidation.minValue) {
      const minDays = this.productValidation.minValue.unit === 0 ? this.productValidation.minValue.value : this.productValidation.minValue.value * 7;

      let total = 0;
      let firstDay = -1;
      selected.row.forEach((element: { columns: any[]; }) => {
        const filtered = element.columns.filter((c: { selected: any; }) => c.selected);
        total += filtered.length;
        if (filtered.length > 0 && firstDay === -1) {
          firstDay = filtered[0].columnIndex;
        }
      });

      if (total < minDays) {
        this.logHelperService.logError(this.userBundle['common.error.minRangeNotMatching']);
        return false;
      } else if (this.productValidation.defaultWeekDay
        && (firstDay + 1) !== this.productValidation.defaultWeekDay) {
        this.logHelperService.logError(this.userBundle['common.error.defaultWeekDayNotMatching']);
        return false;
      }
    }
    return true;
  }
  /**
   * This method is to handle a rule mentioned in SM-4637 (Rule 3-enforcePatternStabilityforPCM)
   * @param selected object of selected variable
   */
  checkForConcecutivenessForPCM(selected): boolean {
    if (!(this.uiControl.enforcePatternStabilityforPCM && this.productValidation && this.productValidation.minValue)) {
      return true;
    }

    const minDays = this.productValidation.minValue.unit === 0 ? this.productValidation.minValue.value :
      this.productValidation.minValue.value * 7;

    let data = {
      prevPattern: null,
      consecutive: false,
      consecutiveDays: 0
    };

    if (minDays === 0) {
      return true;
    }
    for (const r in selected.row) {
      if (selected.row.hasOwnProperty(r)) {
        data = this.concecutive(data, selected, r, minDays);
        if (data.consecutive) {
          break;
        }
      }
    }

    if (!data.consecutive) {
      this.logHelperService.logError(this.userBundle['common.error.concecituvePatternMismatch']);
      return false;
    }

    return true;
  }

  concecutive(data: any, selected: any, r: string, minDays: number) {
    for (const col in selected.row[r].columns) {
      if (!selected.row[r].columns.hasOwnProperty(col)) {
        continue;
      }

      const c = selected.row[r].columns[col];
      if (!c.selected) {
        continue;
      }

      if (!data.prevPattern) {
        data.prevPattern = c.displayText;
      }
      if (data.prevPattern === c.displayText) {
        data.consecutiveDays++;
        if (minDays === data.consecutiveDays) {
          data.consecutive = true;
          break;
        }
      } else {
        data.prevPattern = c.displayText;
        data.consecutiveDays = 0;
      }

    }
    return data;
  }

  onModalClosed(event) {
    const isSelected = this.namedDays?.['row'][0]?.['columns']?.find(item => item.selected);
    if (event.reason === 'escape' && isSelected) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  onModalSaved(event) {
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    let selected = this.namedDays;
    selected = _.cloneDeep(selected);

    // SM-8446
    if (this.uiControl.hourlyPatternFunctionEnabled) {
      const overlappedObj = this.checkIsOverlapped();
      if (overlappedObj['isOverlapped']) {
        this.namedDays.row[overlappedObj['rowIndex']].columns[overlappedObj['columnIndex']].currentSelection = false;
        this.selectDay(overlappedObj['rowIndex'], overlappedObj['columnIndex'], event);
        return;
      } else {
        this.resetOverlap();
      }
    }

    const isAnyPCMValueSelected = this.patternObj.allowDayParts || this.patternObj.allowDays || this.patternObj.allowHours;
    if (this.pcmMode && !isAnyPCMValueSelected) {
      this.logHelperService.logError(this.userBundle['common.error.noDataSelect']);
      return;
    }

    if (!this.checkForPCMValidationRule(selected) || !this.checkForConcecutivenessForPCM(selected)) {
      return;
    }

    this.patternObj.selected = selected;
    this.patternObj.selectedDay = this.selectedDay;
    this.convertForService(this.namedDays.row);
    if (this.pcmMode) {
      this.patternObj.allowDayParts = this.patternObj.allowDayParts || false;
      this.patternObj.allowDays = this.patternObj.allowDays || false;
      this.patternObj.allowHours = this.patternObj.allowHours || false;
    } else {
      delete this.patternObj.allowDayParts;
      delete this.patternObj.allowDays;
      delete this.patternObj.allowHours;
    }

    // In PCM mode, pattern is not allowed
    if (this.patternObj.pattern.length > 0 || this.pcmMode) {
      values.selectedValues = _.cloneDeep(this.patternObj);
      values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Pattern, values.selectedValues);
      delete this.patternObj.selected;
      delete this.patternObj.selectedDay;
      values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Pattern, this.patternObj);
      event.activeModal.close(values);
    } else {
      this.logHelperService.logError(this.userBundle['common.error.noDataSelect']);
    }
  }
}
