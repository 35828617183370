import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent  {
  /**
   * It will take object data as input to configure.
   */
  @Input() resolveObject;

  /**
   * Constructor
   * @param activeModal Describes for bootstrap modal.
   */
  constructor(private activeModal: NgbActiveModal) { }

  /**
   * Function that loads initially.
   */

  /**
   * To save the given data.
   */
  onSave() {
    this.activeModal.close();
  }

  /**
   * To close the opened modal.
   */
  onClose() {
    this.activeModal.dismiss();
  }
}
