import { FurnitureModel } from './MapData.model';

export class GetCampaignResponseModel {
  campaign: GetCampaignModel;
}

export class GetCampaignModel {
  campaignId: number;
  campaignReference: string;
  startDate: Date;
  endDate: Date;
  brandName: string;
  advertiserName: string;
  campaignStatusName: string;
  assetData: FurnitureModel[];
}
