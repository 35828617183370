import { Brand } from './brand';
import { ProductCategoryLookUp } from './product-category-lookup';

export class Advertiser {
  advertiserId = 0;
  advertiserName = '';
  invoiceStatusCode = '';
  brands?: Brand[];
  productCategory?: ProductCategoryLookUp[];
  organisationTypeId: number;
  advertiserCode: string;
  organisationCode?: number;
  organisationId?: number;
  organisationName?: number;
}
