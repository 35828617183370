<app-sb-modal-wrapper [title]="userBundle['discount.germany.newStatusLine']" [titleBackgroundColor]="'lightblue'" (closed)="onModalClosed($event)"
  (saved)="onModalSaved($event)">
  <div class="row">
    <div class="form-group">
      <div class="col-lg-12">
        <label class="col-md-4 control-label" for="numberFrames">{{userBundle['discount.germany.numberOfFrames']}}</label>
        <div class="col-md-8">
          <input id="numberFrames" class="form-control" type="number" [appOnlyNumber]="true" [decimalPoints]="0" [maxValue]="maxNumberofFrames"
            [(ngModel)]="selectedRecord.numberFrames" name="numberOfFrames" placeholder="Enter number of frames">
        </div>
      </div>
    </div>
    <br><br>

    <div class="form-group">{{selectedRecord.status}}
      <div class="col-lg-12">
        <label class="col-md-4 control-label" for="status">{{userBundle['discount.germany.statusCategoryId']}}</label>
        <div class="col-md-8">
          <select id="status" class="form-control" [(ngModel)]="selectedRecord.statusCategoryId">
            <option *ngFor="let item of statusOptions; trackBy: trackByIdStatusCategory;" [value]="item['idStatusCategory']">{{item['label']}}</option>
          </select>
        </div>
      </div>
    </div>
    <br><br>
  </div>
</app-sb-modal-wrapper>
