<div id="baiduMap" class="baidu-map" [ngStyle]="{'height': dynamicHeight }" (window:resize)="onResize()"></div>
<div class="result-map-fiter" *ngIf="tempProximityData.length" [ngStyle]="accordianToggleStyle">
  <dl class="accordion content animated bounceInDown">
    <dt style="min-height: 50px;">
      <div style="float: left;width: 10%;padding-left: 15px;">
        <ui-switch class="left" size="small" [checked]="showPOIs" (change)="toggleAllPOIs()" style="padding-top:9px;">
        </ui-switch>
      </div>
      <div style="float: left;width: 90%;" (click)="onAccordionClick()">
        <a href="javascript:void(0);" class="title" [ngClass]="{'down': activeAccordion}">
          <div class="icon_wrapper">
            <span class="icons animated"></span>
          </div>
          <span class="text pull-right pad-70">{{userBundle['result.map.filter.poi'] || 'Point of Interest'}}</span>
        </a>
      </div>
    </dt>
    <dd [ngClass]="{'down': activeAccordion}">
      <div class="result-map-tree-view-container">
        <app-tree-view [(state)]="state" [hideHeader]="true" [data]="tempProximityData" [options]="treeOptions"
          [lazySearch]="false" [searchMinlength]="3" [isSearchCaseSensitive]="true"
          (onDeSelect)="onSelectDeSelectNode($event)" (onSelect)="onSelectDeSelectNode($event)"
          (onColorChange)="onColorChangeNode($event)" (onShapeChange)="onShapeChange($event)"
          (onIconShapeModal)="getNodeValue($event)" [templateProps]="templateProps">
        </app-tree-view>
      </div>
    </dd>
  </dl>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{userBundle['result.map.changeColorShape'] || 'Color/Shape Change'}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="mapsetting-item">
      <div class="select-shape">{{userBundle['result.map.selectShapeToChange'] || 'Select shape to change'}}</div>
      <span (click)="updateIconShape(node,'circle')">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20">
          <g id="circle" transform="translate(-6 -6)">
            <g id="Group_1" data-name="Group 1" transform="translate(6 6)">
              <path id="Path_1" data-name="Path 1" d="M16,958.362a10,10,0,1,0,10,10A10,10,0,0,0,16,958.362Z"
                transform="translate(-6 -958.362)" />
            </g>
          </g>
        </svg>
      </span>
      <span (click)="updateIconShape(node,'diamond')">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <g id="Diamond" transform="translate(-5.556 -5.556)">
            <g id="Group_2" data-name="Group 2" transform="translate(5.556 5.556)">
              <rect id="Rectangle_1" data-name="Rectangle 1" width="14.142" height="14.142"
                transform="translate(0 10) rotate(-45)" />
            </g>
          </g>
        </svg>
      </span>
      <span (click)="updateIconShape(node,'heart')">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17.292" viewBox="0 0 20 17.292">
          <g id="Heart" transform="translate(-2.499 -8.929)">
            <path id="Path_2" data-name="Path 2"
              d="M19.627,9.748a5.64,5.64,0,0,0-7.088,1.274A5.65,5.65,0,0,0,2.5,14.527a5.526,5.526,0,0,0,.263,1.741c.924,4.37,9.623,9.953,9.623,9.953s8.8-5.406,9.814-9.756a5.568,5.568,0,0,0,.3-1.737,5.657,5.657,0,0,0-2.871-4.981Z" />
          </g>
        </svg>
      </span>
      <span (click)="updateIconShape(node,'plus')">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <g id="Plus" transform="translate(-0.005 -0.002)">
            <path id="Path_3" data-name="Path 3" d="M20,7.7H12.3V0h-4.6V7.7H0v4.6h7.7V20h4.6V12.3H20Z" />
          </g>
        </svg>
      </span>
      <span (click)="updateIconShape(node,'polygons')">
        <svg id="polygons" xmlns="http://www.w3.org/2000/svg" width="20" height="19.017" viewBox="0 0 20 19.017">
          <g id="Group_3" data-name="Group 3">
            <path id="Path_4" data-name="Path 4" d="M9.985,0,20,7.25,16.16,19.017H3.81L0,7.25Z" fill-rule="evenodd" />
          </g>
        </svg>

      </span>
      <span (click)="updateIconShape(node,'triangle')">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.674" viewBox="0 0 20 16.674">
          <g id="triangle" transform="translate(-9.766 -16.457)">
            <g id="Group_4" data-name="Group 4" transform="translate(9.766 16.457)">
              <path id="Path_5" data-name="Path 5" d="M19.766,968.819l5.006,8.346,4.994,8.328h-20l4.994-8.327Z"
                transform="translate(-9.766 -968.819)" fill-rule="evenodd" />
            </g>
          </g>
        </svg>
      </span>
    </div>
    <hr class="margin-0" />
    <input [style.background]="node.data['colorCode']" placeholder="Click to change color" [cpOutputFormat]="hex"
      [(colorPicker)]="node.data['colorCode']" (colorPickerChange)="updateColorCode(node)" [cpPosition]="'bottom'"
      [cpOKButton]="true" class="input-color-picker">
  </div>
</ng-template>