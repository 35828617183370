import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { GridOptions, ColDef } from 'ag-grid-community';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { DataShareService } from '../../../core/services/data-share.service';
import {
  AgCollapseExpandComponent,
  AgCheckboxComponent,
  AgCustomFilterMenuComponent,
  AgCustomHeaderComponent,
  AgTextCellEditorComponent,
  AgSelectCellEditorComponent,
  AgCurrencyCellRendererComponent,
  AgSelectCellRendererComponent,
  AgTextCellRendererComponent,
  AgPercentageCellRendererComponent,
  AgCustomSort,
  AgCustomRowUtil,
  DataFormatterService,
  AgTextFloatingFilterComponent,
  AgSelectFloatingFilterComponent
} from '../ag-grid-support/index';
import { DecimalPipe } from '@angular/common';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { LocaleData } from '../../../core/utils/LocaleData';
import { Discount } from '../../commercial.model';
import { CommercialService } from '../../../core/services';
import { SystemFlags } from '../../../models';

@Component({
  selector: 'app-discount-grid-at',
  templateUrl: './discount-grid-at.component.html',
  styleUrls: ['../ag-grid-custom-style.css', '../ag-grid-col-select-style.css', './discount-grid-at.component.css'],
  host: {
    '(document:click)': 'onDocumentClick($event)'
  }
})
export class DiscountGridAtComponent implements OnInit, OnDestroy {
  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  @Input() gridData: any = {};
  @Input() brandData = [];
  @Input() advertiserId;
  sortObj: AgCustomSort;
  /**
   * Ag-Grid Options Object
   */
  gridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  columnDefs: ColDef[];

  /**
   * column list to show in show/hide column dropdown
   */
  columnList: any = [];

  /**
   * list of Cart Data
   * @memberof CartDetailComponent
   */
  rowData: any[] = [];

  /**
   * Grid footer data
   */
  footerRowData: any[];

  /**
   * user bundle data
   */
  userBundle: any = {};

  avgSurcharge = 0;
  avgNetworkPrice = 0;
  avgDiscount = 0;
  avgMediaDiscount1 = 0;
  avgMediaDiscount2 = 0;
  avgFinalTotal = 0;
  avgAgencyFeePercent = 0;
  avgAgencyFee = 0;

  statusCategory = [];
  indexedStatusCategory = [];
  agencyDiscount = [];
  agreement = [];

  numericFields = [];
  dateRangeFields = [];
  selectFields = [];
  groupingField = 'groupingKey';
  notifyGridSubscription: Subscription;

  precisionOnPrice = 2;
  precisionOnPercentage = 2;

  overrideDiscountOptions = [];
  subs: Subscription[] = [];
  floatingDealStatusCategoryId = 7;
  defaultStatusCategoryId = -1;
  budgetImpressionsStatusCategoryId = 16;
  sonstigesStatusCategoryId = 14;

  // sm-8379
  mediaDiscount1IniitialValue = 100; // for statusCategory 'DT' || 'EM' || 'EO' || 'KM' || 'KO' || 'GS'

  constructor(
    private dataShareService: DataShareService,
    // private discountGridService: DiscountGridAtService,
    // private sbModalPopupService: SbModalPopupService,
    private toaster: LogHelperService,
    private dataFormatterService: DataFormatterService,
    private decimalPipe: DecimalPipe,
    private commercialService: CommercialService
  ) {
    this.subs.push(this.commercialService.discounts$.subscribe((res: Discount[]) => {
      this.rowData = [];
      this.reset();
      this.gridData.discounts = _.clone(res);
      this.prepareRowData();
    }));

  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.overrideDiscountOptions = [
      {
        value: true,
        label: this.userBundle['discount.at.yes'] || 'Yes',
      },
      {
        value: false,
        label: this.userBundle['discount.at.no'] || 'No',
      }
    ];
    this.setArrayLists();

    this.setFooterRows();
    this.prepareRowData();
    this.prepareColDef();

    this.reset();

    // we pass an empty gridOptions in, so we can grab the api out
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      onRowDataChanged: (event) => {
        event.api.setPinnedBottomRowData(this.footerRowData);
      },
      scrollbarWidth: 10,
      onGridReady: this.onGridReady,
      getRowHeight: this.getRowHeight,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      suppressScrollOnNewData: true,
      onFilterChanged: this.onFilterChanged,
      onCellEditingStopped: this.onCellEditingStopped,
      groupingField: this.groupingField,
      suppressMovableColumns: true,
      isExternalFilterPresent: () => true,
      // tslint:disable-next-line:ter-arrow-parens
      doesExternalFilterPass: (node) => node.data.isVisible,
      onCellClicked: (params) => {
        if (params.rowPinned === 'bottom' && params.rowIndex === 0 && params.column.isCellEditable(params.node)) {
          const par: any = {
            column: params.column,
            rowIndex: params.rowIndex,
            rowPinned: params.rowPinned
          };
          params.api['rowRenderer'].startEditingCell(par);
        }
      }
    } as GridOptions;

  }

  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.forEach((r) => {
        r.isFilterPass = true;
      });
    }
    AgCustomRowUtil.setParentRowSelectedProperty(this.rowData, this.groupingField);
    // Refresh Grid Header checkbox component
    this.gridOptions.api['headerRootComp'].forEachHeaderElement((element) => {
      if (element.column.colId === 'selectChk' && element.columnApi && element.childComponents && element.childComponents.length > 1) {
        const headerCmpIns = element.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  clearAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    this.gridOptions.api.onFilterChanged();
  }

  setArrayLists() {
    this.agreement = this.gridData.agreement;
    this.setStatusCategory();
    this.setAgencyDiscountArr();
  }

  setStatusCategory() {
    this.statusCategory = this.gridData.statusCategory;
    this.indexedStatusCategory = [];

    if (this.statusCategory && _.isArray(this.statusCategory)) {
      for (const statusCategory of this.statusCategory) {
        const object = statusCategory;
        let label = '';
        if (object.statusCategoryName) {
          label = object.statusCategoryName;
        }
        statusCategory.label = label;
        this.indexedStatusCategory[object.idStatusCategory] = statusCategory;
      }
    }
  }

  setAgencyDiscountArr() {
    this.agencyDiscount = [];
    if (this.gridData && this.gridData.agencyDiscount && _.isArray(this.gridData.agencyDiscount)) {
      for (let index = 0; index < this.gridData.agencyDiscount.length; index++) {
        this.agencyDiscount.push({ id: index, agencyDiscountValue: this.gridData.agencyDiscount[index] });
      }
    }
  }

  updateStatusCategory = (selectedRecord) => {
    const statusCategories = [];
    this.gridData.discounts.forEach(function (element) {
      if (element[this.groupingField] === selectedRecord[this.groupingField]) {
        if (element.statusCategory !== selectedRecord.statusCategory) {
          statusCategories.push(element.statusCategory);
        }
      }
    }, this);
    const updatedSatusCategory = _.clone(this.statusCategory);
    if (statusCategories) {
      statusCategories.forEach((element) => {
        const index = _.findIndex(updatedSatusCategory, (o) => {
          return o.idStatusCategory === element;
        });
        updatedSatusCategory.splice(index, 1);
      }, this);
    }
    return updatedSatusCategory;
  }

  onGridReady = () => {
    this.columnList = this.gridOptions.columnApi.getAllColumns();
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else if (params.data.isVisible) {
      height = 30;
    }
    return height;
  }

  isFullWidthCell = (rowNode): boolean => {
    return (rowNode.data.gridSummary ? true : false);
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }

  calculateMediaDiscount1(rowData) {
    rowData.newTotal3 = this.calculateTotal3(rowData);
    rowData.newTotal4 = this.calculateTotal4(rowData);
    rowData.agencyFee = this.calculateAgencyFee(rowData);
    rowData.newTotal5 = this.calculateTotal5(rowData);
    rowData.newTotal6 = this.calculateTotal6(rowData);
    rowData.newTotal6_5 = this.calculateTotal6_5(rowData);
    rowData.newTotal7 = this.calculateTotal7(rowData);
    rowData.finalTotal = this.calculateFinalTotal(rowData);
  }

  calculateMediaDiscount2(rowData) {
    rowData.newTotal4 = this.calculateTotal4(rowData);
    rowData.agencyFee = this.calculateAgencyFee(rowData);
    rowData.newTotal5 = this.calculateTotal5(rowData);
    rowData.newTotal6 = this.calculateTotal6(rowData);
    rowData.newTotal6_5 = this.calculateTotal6_5(rowData);
    rowData.newTotal7 = this.calculateTotal7(rowData);
    rowData.finalTotal = this.calculateFinalTotal(rowData);
  }

  calculateAgencyFeePercent(rowData) {
    rowData.agencyFee = this.calculateAgencyFee(rowData);
    rowData.newTotal5 = this.calculateTotal5(rowData);
    rowData.newTotal6 = this.calculateTotal6(rowData);
    rowData.newTotal6_5 = this.calculateTotal6_5(rowData);
    rowData.newTotal7 = this.calculateTotal7(rowData);
    rowData.finalTotal = this.calculateFinalTotal(rowData);
  }

  calculateHandlingFee(rowData) {
    rowData.newTotal6 = this.calculateTotal6(rowData);
    rowData.newTotal6_5 = this.calculateTotal6_5(rowData);
    rowData.newTotal7 = this.calculateTotal7(rowData);
    rowData.finalTotal = this.calculateFinalTotal(rowData);
  }

  calculateAdvertisingTax(rowData) {
    rowData.newTotal6_5 = this.calculateTotal6_5(rowData);
    rowData.newTotal7 = this.calculateTotal7(rowData);
    rowData.finalTotal = this.calculateFinalTotal(rowData);
  }

  calculateCommercialTax(rowData) {
    rowData.newTotal7 = this.calculateTotal7(rowData);
    rowData.finalTotal = this.calculateFinalTotal(rowData);
  }

  updateNetworkPriceEditable(editable: boolean) {
    const index = this.columnDefs.findIndex(def => def.field === 'networkPrice');
    // this.columnDefs[index].editable = editable;
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setColumnDefs(this.columnDefs);
    }
  }

  handleStatusCategoryChange(rowData) {
    const statusCat = this.statusCategory.find(sc => sc.idStatusCategory === Number(rowData.statusCategory));
    switch (statusCat.statusCategoryCode) {
      case 'DT':
      case 'EM':
      case 'EO':
      case 'KM':
      case 'KO':
      case 'GS':
        rowData.overrideDiscounts = 'false';
        rowData.networkPrice = _.clone(rowData.networkPriceDefault);
        rowData.surcharge = _.clone(rowData.surchargeDefault);
        rowData.mediaDiscount1 = this.mediaDiscount1IniitialValue;
        break;
      case 'GM':
      case 'GO':
      case 'AT':
      case 'GF':
        rowData.networkPrice = 0;
        rowData.surcharge = _.clone(rowData.surchargeDefault);
        rowData.mediaDiscount1 = _.clone(rowData.mediaDiscount1Default);
        break;
      case 'WH':
        rowData.networkPrice = _.clone(rowData.networkPriceDefault);
        rowData.surcharge = 15;
        rowData.mediaDiscount1 = _.clone(rowData.mediaDiscount1Default);
        break;
      case 'SG':
      case 'VS':
        rowData.networkPrice = 0;
        break;
      case 'SS':
        //updated column definition for network price will handle
        break;
      case 'BI':
        //updated column definition for network price will handle
        break;
      case 'FD':
      case 'DEFAULT':
        rowData.networkPrice = _.clone(rowData.networkPrice);
        rowData.surcharge = _.clone(rowData.surchargeDefault);
        if (!rowData.mediaDiscount1 && rowData.overrideDiscounts === false) {
          rowData.mediaDiscount1 = _.clone(rowData.mediaDiscount1Default);
        }
        break;
      default:
        break;
    }
  }

  onCellEditingStopped = (event) => {
    if (event.rowPinned !== 'bottom') {
      switch (event.column.colId) {
        case 'campaignStatus':
          break;
        case 'networkPrice':
          event.data.networkPrice = LocaleData.replaceLocalDecimalCharWithDot(event.data.networkPrice);
          this.calculateCellValues(event.data);
          break;
        case 'statusCategory':
          if (this.rowData.length > 0) {
            if (this.footerRowData[0].discount || this.footerRowData[0].mediaDiscount1 || this.footerRowData[0].mediaDiscount2 !== null) {
              event.data.overrideDiscounts = 'false';
              if (this.footerRowData[0].discount && this.footerRowData[0].discount > 0) {
                event.data.mediaDiscount1 = this.mediaDiscount1IniitialValue;
              }
            }
            this.handleStatusCategoryChange(event.data);
            this.calculateCellValues(event.data);
          }
          break;
        case 'surcharge':
          event.data.surchargeDefault = _.clone(event.data.surcharge);
          this.calculateCellValues(event.data);
          break;
        case 'discount':
          if (event.data.discount || parseFloat(event.data.discount) > 0) {
            event.data.overrideDiscounts = 'false';
          }
          event.data.discountStafRabDefault = _.clone(event.data.discount);
          this.calculateCellValues(event.data);
          break;
        case 'mediaDiscount1':
          if (event.data.mediaDiscount1 || parseFloat(event.data.mediaDiscount1) > 0) {
            event.data.overrideDiscounts = 'false';
          }
          event.data.mediaDiscount1Default = _.clone(event.data.mediaDiscount1);
          this.calculateMediaDiscount1(event.data);
          break;
        case 'mediaDiscount2':
          if (event.data.mediaDiscount2 || parseFloat(event.data.mediaDiscount2) > 0) {
            event.data.overrideDiscounts = 'false';
          }
          event.data.mediaDiscount2Default = _.clone(event.data.mediaDiscount2);
          this.calculateMediaDiscount2(event.data);
          break;
        case 'agencyFeePercent':
          this.calculateAgencyFeePercent(event.data);
          break;
        case 'handlingFee':
          event.data.handlingFee = LocaleData.replaceLocalDecimalCharWithDot(event.data.handlingFee);
          this.calculateHandlingFee(event.data);
          break;
        case 'advertisingTax':
          this.calculateAdvertisingTax(event.data);
          break;
        case 'commercialTax':
          this.calculateCommercialTax(event.data);
          break;
        case 'overrideDiscounts':
          if (event.data.overrideDiscounts === 'true') {
            event.data.discount = 0;
            event.data.mediaDiscount1 = 0;
            event.data.mediaDiscount2 = 0;
          } else {
            event.data.discount = _.clone(event.data.discountStafRabDefault);
            event.data.mediaDiscount1 = _.clone(event.data.mediaDiscount1Default);
            event.data.mediaDiscount2 = _.clone(event.data.mediaDiscount2Default);

            // sm-8379
            const statusCat = this.statusCategory.find(sc => sc.idStatusCategory === Number(event.data.statusCategory));
            const statusCatSpecific = ['DT', 'EM', 'EO', 'KM', 'KO', 'GS'];
            if (statusCatSpecific.indexOf(statusCat.statusCategoryCode) !== -1) {
              event.data.mediaDiscount1 = this.mediaDiscount1IniitialValue;
            }
          }
          this.calculateCellValues(event.data);
          break;
        case 'fees':
        case 'dynamicContentFee':
          event.data.fees = LocaleData.replaceLocalDecimalCharWithDot(event.data.fees);
          event.data.dynamicContentFee = LocaleData.replaceLocalDecimalCharWithDot(event.data.dynamicContentFee);
          event.data.finalTotal = this.calculateFinalTotal(event.data);
          break;
        default:
          break;
      }
      this.rowData = _.clone(this.rowData);
    } else {
      if (event.column.colId === "statusCategory") {
        if (Number(event.data.statusCategory) === this.floatingDealStatusCategoryId || Number(event.data.statusCategory) === this.defaultStatusCategoryId || Number(event.data.statusCategory) === this.sonstigesStatusCategoryId) {
          this.updateNetworkPriceEditable(true);
          if (this.footerRowData[0].discount || this.footerRowData[0].mediaDiscount1 || this.footerRowData[0].mediaDiscount2) {
            event.data.overrideDiscounts = 'false';
          }
        } else {
          event.data.networkPrice = null;
          this.updateNetworkPriceEditable(false);
        }
      }
    }
  }

  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen()) {
      if ($event.button !== 2 && !dropdownEle._isEventFromToggle($event)
        && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
        dropdownEle.close();
      }
    }
  }

  prepareRowData() {
    const tempRowData = _.clone(this.rowData);
    this.rowData = [];
    const tempRowData1 = [];

    let groupId = null;
    for (const discount of this.gridData.discounts) {
      const curRow: any = discount;
      const parentRow = this.getParentRows(curRow[this.groupingField], tempRowData);
      let isExpanded = false;
      if (parentRow && parentRow.length > 0) {
        isExpanded = parentRow[0].isExpanded;
      }
      curRow.isFilterPass = true;
      if (groupId === curRow[this.groupingField]) {
        const tempRowWithRow = curRow;
        tempRowWithRow.isParent = false;
        tempRowWithRow.isVisible = isExpanded;
        tempRowWithRow.networkPriceDefault = _.clone(parseFloat(curRow.smartBricValue.toFixed(this.precisionOnPrice)));
        tempRowWithRow.mediaDiscount1Default = _.clone(curRow.mediaDiscount1);
        tempRowWithRow.mediaDiscount2Default = _.clone(curRow.mediaDiscount2);
        tempRowWithRow.surchargeDefault = _.clone(curRow.surcharge);
        tempRowWithRow.discountStafRabDefault = _.clone(curRow.calcDiscountStafRab);
        if (curRow.overrideDiscounts) {
          curRow.discount = 0;
          curRow.mediaDiscount1 = 0;
          curRow.mediaDiscount2 = 0;
          this.calculateCellValues(curRow);
        }
        tempRowWithRow.isSelected = false;
        tempRowData1.push(tempRowWithRow);
      } else {
        groupId = curRow[this.groupingField];
        let tempRowWithOutGroupFiled = _.clone(curRow);
        tempRowWithOutGroupFiled.isParent = true;
        tempRowWithOutGroupFiled.isVisible = true;
        tempRowWithOutGroupFiled.isExpanded = isExpanded;
        tempRowWithOutGroupFiled.isSelected = false;
        tempRowWithOutGroupFiled.childCount = this.gridData.discounts.filter((row) => {
          return row[this.groupingField] === tempRowWithOutGroupFiled[this.groupingField];
        }).length;
        tempRowWithOutGroupFiled.dayPart = '';
        tempRowWithOutGroupFiled.statusCategory = -1;
        tempRowData1.push(tempRowWithOutGroupFiled);

        tempRowWithOutGroupFiled = curRow;
        tempRowWithOutGroupFiled.isParent = false;
        tempRowWithOutGroupFiled.isVisible = isExpanded;
        tempRowWithOutGroupFiled.networkPriceDefault = _.clone(parseFloat(curRow.smartBricValue.toFixed(this.precisionOnPrice)));
        tempRowWithOutGroupFiled.surchargeDefault = _.clone(curRow.surcharge);
        tempRowWithOutGroupFiled.mediaDiscount1Default = _.clone(curRow.mediaDiscount1);
        tempRowWithOutGroupFiled.mediaDiscount2Default = _.clone(curRow.mediaDiscount2);
        tempRowWithOutGroupFiled.discountStafRabDefault = _.clone(curRow.calcDiscountStafRab);
        if (tempRowWithOutGroupFiled.overrideDiscounts) {
          tempRowWithOutGroupFiled.discount = 0;
          tempRowWithOutGroupFiled.mediaDiscount1 = 0;
          tempRowWithOutGroupFiled.mediaDiscount2 = 0;
          this.calculateCellValues(tempRowWithOutGroupFiled);
        }

        tempRowWithOutGroupFiled.isSelected = false;
        tempRowData1.push(tempRowWithOutGroupFiled);
      }
    }
    this.rowData = tempRowData1;
  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinRowIndex: 0 },
      { isVisible: true, locked: true },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
  }

  getGridSummary() {
    this.calculateGridSummaryValues();
    const totalSelected = this.getSelectedChildRows().length;
    return `<span>${this.userBundle['discount.common.totalFrames']}
    : ${this.getChildRows(null, false).length} &#45; ${this.userBundle['discount.common.selectedFrames']}
      : ${this.getSelectedChildRows().length} (<b>${this.userBundle['discount.at.surcharge']}
      </b>: ${this.decimalPipe.transform(totalSelected ? (this.avgSurcharge / totalSelected) : 0, '.2-2')}
      %, <b>${this.userBundle['discount.at.networkPrice']}</b>: ${this.dataFormatterService.currency(this.avgNetworkPrice)},
      <b> ${this.userBundle['discount.at.discountStafRab']}</b>: ${this.decimalPipe.transform(totalSelected ? (this.avgDiscount / totalSelected) : 0, '.2-2')}
      %, <b>${this.userBundle['discount.at.mediaDiscount1']}
      </b>: ${this.decimalPipe.transform(totalSelected ? (this.avgMediaDiscount1 / totalSelected) : 0, '.2-2')} %, <b>${this.userBundle['discount.at.mediaDiscount2']}</b>:
      ${this.decimalPipe.transform(totalSelected ? (this.avgMediaDiscount2 / totalSelected) : 0, '.2-2')} %, <b>${this.userBundle['discount.at.agencyFeePercent']}
      </b>: ${this.decimalPipe.transform(totalSelected ? (this.avgAgencyFeePercent / totalSelected) : 0, '.2-2')}
      %, <b>${this.userBundle['discount.at.agencyFee']}</b>
      : ${this.dataFormatterService.currency(this.avgAgencyFee)}), <b>${this.userBundle['discount.at.finalTotal']}</b>:
      ${this.dataFormatterService.currency(this.avgFinalTotal)}</span></div>`;
  }

  prepareColDef() {
    let isNetworkPriceEditable = false;

    this.columnDefs = [
      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false,
        cellRendererFramework: AgCollapseExpandComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCollapseExpandComponent,
        cellClass: (params): string[] => {
          const retArr: string[] = [];
          if (params.data.isSelected) {
            retArr.push('selectedRow');
          }
          if (params.data.amountAdjusted && !params.data.isParent) {
            retArr.push('amountAdjusted');
          }
          if (params.node.rowPinned === 'bottom' && params.rowIndex === 1) {
            retArr.push('borderBottom');
          }
          return retArr;
        }
      },
      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false, colId: 'selectChk',
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: (params): string[] => {
          const retArr: string[] = [];
          if (params.data.isSelected) {
            retArr.push('selectedRow');
          }
          if (params.data.amountAdjusted && !params.data.isParent) {
            retArr.push('amountAdjusted');
          }
          if (params.node.rowPinned === 'bottom' && params.rowIndex === 1) {
            retArr.push('borderBottom');
          }
          return retArr;
        }
      },
      {
        headerName: this.userBundle['discount.at.groupingId'],
        field: this.groupingField, width: 86, editable: false, pinned: 'left', filter: false,
        pinnedRowCellRenderer: this.blankCellRenderer,
        cellRenderer: this.groupingCellRenderer
      },
      {
        headerName: this.userBundle['discount.at.network'], field: 'network', width: 135, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.networkNamePinnedRowCellRenderer,
      },
      {
        headerName: this.userBundle['discount.at.week'], field: 'week', width: 50, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.at.dateRange'], field: 'dateRange', width: 150, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.at.days'], field: 'days', width: 150, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.at.city'], field: 'city', width: 110, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.at.listBricId'], field: 'listBricId', width: 80, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.at.dayPart'], field: 'dayPart', width: 105, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.at.campaignStatus'], field: 'campaignStatus', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgSelectCellRendererComponent,
        cellRendererParams: {
          cellEditable: (rowData) => {
            if (rowData.isParent) {
              return false;
            } else {
              return (!rowData.statusLocked && !rowData.locked && !SystemFlags.isPPCampaign);
            }
          },
          isValid: (rowData, colId) => {
            if (rowData.isParent) {
              let isValid = true;
              const childRows = this.getChildRows(rowData[this.groupingField]);
              for (const childRow of childRows) {
                if (!childRow[colId] || Number(childRow[colId]) === -1) {
                  isValid = false;
                  break;
                }
              }
              return isValid;
            } else {
              const fieldValue = rowData[colId];
              if (!fieldValue || Number(fieldValue) === -1) {
                return false;
              } else {
                return true;
              }
            }
          },
          valKey: 'bookingStatusId',
          textKey: 'bookingStatusName',
          dataArr: () => {
            return this.gridData.bookingStatus;
          }
        },
        pinnedRowCellRendererFramework: AgSelectCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: (rowData) => {
            return !rowData.isParent && !rowData.locked && !SystemFlags.isPPCampaign;
          },
          valKey: 'bookingStatusId',
          textKey: 'bookingStatusName',
          dataArr: () => {
            return this.gridData.bookingStatus;
          }
        },
        cellEditorFramework: AgSelectCellEditorComponent,
        cellEditorParams: {
          valKey: 'bookingStatusId',
          textKey: 'bookingStatusName',
          dataArr: () => {
            return this.gridData.bookingStatus.filter((item) => {
              return item.inUse;
            });
          },
          cellEditable: (rowData) => {
            if (rowData.isParent) {
              return false;
            } else {
              return (!rowData.statusLocked && !rowData.locked && !SystemFlags.isPPCampaign);
            }
          }
        }
      },
      {
        headerName: this.userBundle['discount.at.statusCategory'],
        field: 'statusCategory', width: 150, editable: true, pinned: false,
        cellRendererFramework: AgSelectCellRendererComponent,
        cellRendererParams: {
          cellEditable: (rowData) => {
            if (rowData.isParent) {
              return false;
            } else {
              return ((!rowData.statusLocked) && (!rowData.locked));
            }
          },
          isValid: (rowData, colId) => {
            // Removed validation for Status Category Column as per SM-8997
            if (rowData.isParent) {
              let isValid = true;
              const childRows = this.getChildRows(rowData[this.groupingField]);
              for (const childRow of childRows) {
                if (!childRow[colId]) {
                  isValid = false;
                  break;
                }
              }
              return isValid;
            } else {
              if (!rowData[colId]) {
                return false;
              }
              return true;
            }
          },
          valKey: 'idStatusCategory',
          textKey: 'label',
          dataArr: () => {
            return this.statusCategory;
          }
        },
        pinnedRowCellRendererFramework: AgSelectCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: (rowData) => {
            return !rowData.isParent && !rowData.locked;
          },
          valKey: 'idStatusCategory',
          textKey: 'label',
          dataArr: () => {
            return this.statusCategory;
          }
        },
        cellEditorFramework: AgSelectCellEditorComponent,
        cellEditorParams: {
          valKey: 'idStatusCategory',
          textKey: 'label',
          dataArr: () => {
            return this.statusCategory;
          },
          cellEditable: (rowData) => {
            return !rowData.isParent;
          }
        }
      },
      {
        headerName: this.userBundle['discount.at.networkPrice'],
        field: 'networkPrice', width: 115, pinned: false,
        editable: (params: any) => {
          return !params.data.isParent && !params.data.locked && (Number(params.data.statusCategory) !== this.budgetImpressionsStatusCategoryId);
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: (data) => {
            return !data.isParent && !data.locked && (!data.statusCategory || Number(data.statusCategory) !== this.budgetImpressionsStatusCategoryId);
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPrice,
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => {
            return !data.isParent && !data.locked && (!data.statusCategory || Number(data.statusCategory) !== this.budgetImpressionsStatusCategoryId);
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: (data) => {
            return !data.isParent && !data.locked && (!data.statusCategory || Number(data.statusCategory) !== this.budgetImpressionsStatusCategoryId);
          }
        },
      },
      {
        headerName: this.userBundle['discount.at.surcharge'],
        field: 'surcharge', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => {
            return !data.isParent;
          },
          calcCellValue: this.calcAverage
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (rowData) => {
            return !rowData.isParent && !rowData.locked;
          },
          calcCellValue: this.calcAverage,
          prefixText: (data) => {
            return !data.isParent ? '' : this.userBundle['discount.common.avg'];
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentage,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable,
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.at.newTotal1'],
        field: 'newTotal1', width: 115, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
      },
      {
        headerName: this.userBundle['discount.at.discountStafRab'],
        field: 'discount', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (rowData) => {
            return !rowData.isParent;
          },
          calcCellValue: this.calcAverage
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (rowData) => {
            return !rowData.isParent && !rowData.locked;
          },
          calcCellValue: this.calcAverage,
          prefixText: (data) => {
            return !data.isParent ? '' : this.userBundle['discount.common.avg'];
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentage,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable,
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.at.newTotal2'],
        field: 'newTotal2', width: 115, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
      },
      {
        headerName: this.userBundle['discount.at.mediaDiscount1'],
        field: 'mediaDiscount1', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (rowData) => {
            return !rowData.isParent && !rowData.locked;
          },
          calcCellValue: this.calcAverage
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (rowData) => {
            return !rowData.isParent && !rowData.locked;
          },
          calcCellValue: this.calcAverage,
          prefixText: (data) => {
            return !data.isParent ? '' : this.userBundle['discount.common.avg'];
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentage,
          allowReplaceDecimalSeparator: true,
          cellEditable: (rowData) => {
            return !rowData.isParent && !rowData.locked;
          },
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.at.newTotal3'],
        field: 'newTotal3', width: 115, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
      },
      {
        headerName: this.userBundle['discount.at.mediaDiscount2'],
        field: 'mediaDiscount2', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (rowData) => {
            return !rowData.isParent && !rowData.locked;
          },
          calcCellValue: this.calcAverage
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (rowData) => {
            return !rowData.isParent && !rowData.locked;
          },
          calcCellValue: this.calcAverage,
          prefixText: (data) => {
            return !data.isParent ? '' : this.userBundle['discount.common.avg'];
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentage,
          allowReplaceDecimalSeparator: true,
          cellEditable: (rowData) => {
            return !rowData.isParent && !rowData.locked;
          },
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.at.newTotal4'],
        field: 'newTotal4', width: 115, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
      },
      {
        headerName: this.userBundle['discount.at.agencyFeePercent'],
        field: 'agencyFeePercent', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable,
          calcCellValue: this.calcAverage
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable,
          calcCellValue: this.calcAverage,
          prefixText: (data) => {
            return !data.isParent ? '' : this.userBundle['discount.common.avg'];
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentage,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable,
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.at.agencyFee'],
        field: 'agencyFee', width: 115, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
      },
      {
        headerName: this.userBundle['discount.at.newTotal5'],
        field: 'newTotal5', width: 115, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
      },
      {
        headerName: this.userBundle['discount.at.handlingFee'],
        field: 'handlingFee', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: this.isCellEditable
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: this.isCellEditable
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPrice,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable
        }
      },
      {
        headerName: this.userBundle['discount.at.newTotal6'],
        field: 'newTotal6', width: 115, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
      },
      {
        headerName: this.userBundle['discount.at.advertisingTax'],
        field: 'advertisingTax', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable,
          calcCellValue: this.calcAverage
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable,
          calcCellValue: this.calcAverage,
          prefixText: (data) => {
            return !data.isParent ? '' : this.userBundle['discount.common.avg'];
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentage,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable,
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.at.newTotal6_5'],
        field: 'newTotal6_5', width: 115, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
      },
      {
        headerName: this.userBundle['discount.at.commercialTax'],
        field: 'commercialTax', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable,
          calcCellValue: this.calcAverage
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable,
          calcCellValue: this.calcAverage,
          prefixText: (data) => {
            return !data.isParent ? '' : this.userBundle['discount.common.avg'];
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentage,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable,
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.at.newTotal7'],
        field: 'newTotal7', width: 115, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
      },
      {
        headerName: this.userBundle['discount.at.fees'],
        field: 'fees', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: this.isCellEditable
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: this.isCellEditable
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPrice,
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => {
            return !data.isParent;
          }
        }
      },
      {
        headerName: this.userBundle['discount.at.feesDescription'],
        field: 'feesDescription', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgTextCellRendererComponent,
        cellRendererParams: {
          onlyNumber: false,
          cellEditable: this.isCellEditable
        },
        pinnedRowCellRendererFramework: AgTextCellRendererComponent,
        pinnedRowCellRendererParams: {
          onlyNumber: false,
          cellEditable: this.isCellEditable
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: false,
          cellEditable: this.isCellEditable
        }
      },
      {
        headerName: this.userBundle['discount.at.dynamicContentFee'],
        field: 'dynamicContentFee', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: this.isCellEditable
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: this.isCellEditable
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPrice,
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => {
            return !data.isParent;
          }
        }
      },
      {
        headerName: this.userBundle['discount.at.overrideDiscounts'],
        field: 'overrideDiscounts', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgSelectCellRendererComponent,
        cellRendererParams: {
          cellEditable: this.isCellEditable,
          valKey: 'value',
          textKey: 'label',
          dataArr: () => {
            return this.overrideDiscountOptions;
          }
        },
        pinnedRowCellRendererFramework: AgSelectCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: this.isCellEditable,
          valKey: 'value',
          textKey: 'label',
          dataArr: () => {
            return this.overrideDiscountOptions;
          }
        },
        cellEditorFramework: AgSelectCellEditorComponent,
        cellEditorParams: {
          valKey: 'value',
          textKey: 'label',
          dataArr: () => {
            return this.overrideDiscountOptions;
          },
          cellEditable: (rowData) => {
            return !rowData.isParent;
          }
        }
      },
      {
        headerName: this.userBundle['discount.at.finalTotal'],
        field: 'finalTotal', width: 115, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: () => {
            return false;
          }
        },
      },
      {
        headerName: this.userBundle['discount.at.comments'], field: 'comments', width: 120, editable: true, pinned: false,
        cellRendererFramework: AgTextCellRendererComponent,
        cellRendererParams: {
          cellEditable: this.isCellEditable
        },
        pinnedRowCellRendererFramework: AgTextCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: this.isCellEditable
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: false,
          cellEditable: (rowData) => {
            return !rowData.isParent;
          }
        }
      }
    ];

    this.numericFields = [
      'zuluPeriod',
      'week',
      'listBricId',
      'dayPartId',
      'surcharge',
      'networkPrice',
      'discount',
      'mediaDiscount1',
      'mediaDiscount2',
      'newTotal1',
      'newTotal2',
      'newTotal3',
      'newTotal4',
      'newTotal5',
      'newTotal6',
      'newTotal6_5',
      'newTotal7',
      'finalTotal',
      'handlingFee',
      'agencyFeePercent',
      'agencyFee',
      'advertisingTax',
      'commercialTax',
      'fees',
      'dynamicContentFee',
      'dayMask',
      'numberfFrames'
    ];

    this.dateRangeFields = [];
    this.selectFields = ['campaignStatus', 'statusCategory', 'overrideDiscounts'];

    const decimalFieldsForFilter = [
      'surcharge',
      'networkPrice',
      'campaignStatus',
      'statusCategory',
      'discount',
      'mediaDiscount1',
      'mediaDiscount2',
      'newTotal1',
      'newTotal2',
      'newTotal3',
      'newTotal4',
      'newTotal5',
      'newTotal6',
      'newTotal6_5',
      'newTotal7',
      'finalTotal',
      'handlingFee',
      'agencyFeePercent',
      'agencyFee',
      'advertisingTax',
      'commercialTax',
      'fees',
      'dynamicContentFee',
    ];
    for (let i = 2; i < this.columnDefs.length; i++) {
      if (this.columnDefs[i].field === 'campaignStatus') {
        this.columnDefs[i].floatingFilterComponentFramework = AgSelectFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          suppressFilterButton: true,
          valKey: 'bookingStatusId',
          textKey: 'bookingStatusName',
          dataArr: () => {
            const list = this.gridData.bookingStatus.filter((item) => {
              return item.inUse;
            });
            for (const row of this.gridData.discounts) {
              const id = row.campaignStatus;
              const recExist = list.filter((item) => {
                return item.bookingStatusId === id;
              });
              if (recExist.length === 0) {
                const temp = this.gridData.bookingStatus.filter((item) => {
                  return item.bookingStatusId === row.campaignStatus;
                });
                if (temp.length > 0) {
                  list.push(temp[0]);
                }
              }
            }
            return list;
          },
          value: ''
        };
      } else if (this.columnDefs[i].field === 'statusCategory') {
        this.columnDefs[i].floatingFilterComponentFramework = AgSelectFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          suppressFilterButton: true,
          valKey: 'idStatusCategory',
          textKey: 'label',
          dataArr: () => {
            return this.statusCategory;
          },
          value: ''
        };
      } else if (this.columnDefs[i].field === 'overrideDiscounts') {
        this.columnDefs[i].floatingFilterComponentFramework = AgSelectFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          suppressFilterButton: true,
          valKey: 'value',
          textKey: 'label',
          dataArr: () => {
            return this.overrideDiscountOptions;
          },
          value: ''
        };
      } else {
        this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          value: '',
          suppressFilterButton: true,
          decimalRound: decimalFieldsForFilter.indexOf(this.columnDefs[i].field) > -1 ? 2 : null
        };
      }
      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };

      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => {
        const retArr: string[] = [];
        if (params.data.isParent || params.node.rowPinned === 'bottom') {
          retArr.push('fontBold');
        }
        if (params.node.rowPinned === 'bottom') {
          if (i > 4 || params.rowIndex === 1) {
            retArr.push('borderBottom');
          }
        }
        if (params.data.isSelected) {
          retArr.push('selectedRow');
        }
        if (params.data.amountAdjusted && !params.data.isParent) {
          retArr.push('amountAdjusted');
        }
        if (this.columnDefs[i].field === 'solutionNumberFrames' || this.columnDefs[i].field === 'numberFrames') {
          let valSolNum = params.data['solutionNumberFrames'];
          let valNumFrame = params.data['numberFrames'];
          if (params.data.isParent) {
            const childRows = this.getChildRows(params.data[this.groupingField]);
            valSolNum = this.sumFieldValues(childRows, 'solutionNumberFrames');
            valNumFrame = this.sumFieldValues(childRows, 'numberFrames');
          }
          if (valSolNum !== valNumFrame) {
            retArr.push('danger');
          }
        }
        if (this.columnDefs[i].field === 'locked' || this.columnDefs[i].field === 'warning') {
          retArr.push('lockCellClass');
        }
        return retArr;
      };
    }

    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField);
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
  }

  blankCellRenderer() {
    return '';
  }

  networkNamePinnedRowCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {
      // apply Icon
      const eImgApply: HTMLImageElement = document.createElement('img');
      eImgApply.src = 'images/general/apply.png';
      eImgApply.title = 'Apply';
      eImgApply.onclick = this.apply;
      eDiv.appendChild(eImgApply);

      // Spread Icon
      const eImgSpread: HTMLImageElement = document.createElement('img');
      eImgSpread.src = 'images/general/spread.png';
      eImgSpread.title = 'Spread';
      eImgSpread.onclick = this.spread;
      eDiv.appendChild(eImgSpread);

      // Reset Icon
      const eImg: HTMLImageElement = document.createElement('img');
      eImg.src = 'images/general/reset.png';
      eImg.title = 'Reset';
      eImg.onclick = this.reset;
      eDiv.appendChild(eImg);
    } else if (params.rowIndex === 1) {
      eDiv.innerHTML = `<span class='cellRightSpan fontBold'>${this.userBundle['discount.common.totalBar']}</span>`;
    }
    return eDiv;
  }

  updateRowOnOverrideDiscount(selectedRow: Discount) {
    if (selectedRow.overrideDiscounts && String(selectedRow.overrideDiscounts) === 'true') {
      selectedRow.discount = 0;
      selectedRow.mediaDiscount1 = 0;
      selectedRow.mediaDiscount2 = 0;
    } else {
      selectedRow.discount = _.clone(selectedRow.discountStafRabDefault);
      selectedRow.mediaDiscount1 = _.clone(selectedRow.mediaDiscount1Default);
      selectedRow.mediaDiscount2 = _.clone(selectedRow.mediaDiscount2Default);
      const statusCat = this.statusCategory.find(sc => sc.idStatusCategory === Number(selectedRow.statusCategory));
      if (statusCat.statusCategoryCode === ('DT' || 'EM' || 'EO' || 'KM' || 'KO' || 'GS')) {
        selectedRow.mediaDiscount1 = this.mediaDiscount1IniitialValue;
      }
    }

  }

  apply = () => {
    const selectedChildRows = this.getSelectedChildRows(null, true);
    if (selectedChildRows.length > 0) {
      if (this.footerRowData[0].campaignStatus || this.footerRowData[0].statusCategory
        || this.footerRowData[0].networkPrice === 0 || this.footerRowData[0].networkPrice
        || this.footerRowData[0].surcharge === 0 || this.footerRowData[0].surcharge
        || this.footerRowData[0].discount === 0 || this.footerRowData[0].discount
        || this.footerRowData[0].mediaDiscount1 === 0 || this.footerRowData[0].mediaDiscount1
        || this.footerRowData[0].mediaDiscount2 === 0 || this.footerRowData[0].mediaDiscount2
        || this.footerRowData[0].agencyFeePercent === 0 || this.footerRowData[0].agencyFeePercent
        || this.footerRowData[0].handlingFee === 0 || this.footerRowData[0].handlingFee
        || this.footerRowData[0].advertisingTax === 0 || this.footerRowData[0].advertisingTax
        || this.footerRowData[0].commercialTax === 0 || this.footerRowData[0].commercialTax
        || this.footerRowData[0].fees === 0 || this.footerRowData[0].fees
        || this.footerRowData[0].dynamicContentFee === 0 || this.footerRowData[0].dynamicContentFee
        || this.footerRowData[0].overrideDiscounts !== null
        || this.footerRowData[0].comments
        || this.footerRowData[0].feesDescription
      ) {

        for (const child of selectedChildRows) {
          if (this.footerRowData[0].campaignStatus != null) {
            if (!child.statusLocked) {
              child.campaignStatus = this.footerRowData[0].campaignStatus;
            }
          }
          // SM-8997 - Removed mandatory validation
          if (this.footerRowData[0].statusCategory === null && child.statusCategory === null) {
            child.statusCategory = -1;
          } else {
            if (this.footerRowData[0].statusCategory && !this.footerRowData[0].overrideDiscounts) {
              child.statusCategory = Number(this.footerRowData[0].statusCategory);
              this.handleStatusCategoryChange(child);
            }
          }
          if ((this.footerRowData[0].networkPrice === 0 || this.footerRowData[0].networkPrice) &&
            (Number(child.statusCategory) === this.floatingDealStatusCategoryId || Number(child.statusCategory) === this.defaultStatusCategoryId || Number(child.statusCategory) === 14)) {
            this.footerRowData[0].networkPrice = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].networkPrice);
            child.networkPrice = this.footerRowData[0].networkPrice;
          }
          if (this.footerRowData[0].surcharge === 0 || this.footerRowData[0].surcharge) {
            child.surcharge = this.footerRowData[0].surcharge;
          }
          if (this.footerRowData[0].discount === 0 || this.footerRowData[0].discount) {
            child.overrideDiscounts = 'false';
            child.discount = this.footerRowData[0].discount;
            child.discountStafRabDefault = _.clone(this.footerRowData[0].discount);
          }
          if ((this.footerRowData[0].mediaDiscount1 === 0 || this.footerRowData[0].mediaDiscount1)) {
            child.overrideDiscounts = 'false';
            child.mediaDiscount1 = this.footerRowData[0].mediaDiscount1;
            child.mediaDiscount1Default = _.clone(this.footerRowData[0].mediaDiscount1);
          }
          if ((this.footerRowData[0].mediaDiscount2 === 0 || this.footerRowData[0].mediaDiscount2)) {
            child.overrideDiscounts = 'false';
            child.mediaDiscount2 = this.footerRowData[0].mediaDiscount2;
            child.mediaDiscount2Default = _.clone(this.footerRowData[0].mediaDiscount2);
          }
          if (this.footerRowData[0].agencyFeePercent === 0 || this.footerRowData[0].agencyFeePercent) {
            child.agencyFeePercent = this.footerRowData[0].agencyFeePercent;
          }
          if (this.footerRowData[0].handlingFee === 0 || this.footerRowData[0].handlingFee) {
            this.footerRowData[0].handlingFee = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].handlingFee);
            child.handlingFee = this.footerRowData[0].handlingFee;
          }
          if (this.footerRowData[0].advertisingTax === 0 || this.footerRowData[0].advertisingTax) {
            child.advertisingTax = this.footerRowData[0].advertisingTax;
          }
          if (this.footerRowData[0].commercialTax === 0 || this.footerRowData[0].commercialTax) {
            child.commercialTax = this.footerRowData[0].commercialTax;
          }
          if (this.footerRowData[0].fees === 0 || this.footerRowData[0].fees) {
            this.footerRowData[0].fees = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].fees);
            child.fees = this.footerRowData[0].fees;
          }
          if (this.footerRowData[0].dynamicContentFee === 0 || this.footerRowData[0].dynamicContentFee) {
            this.footerRowData[0].dynamicContentFee = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].dynamicContentFee);
            child.dynamicContentFee = this.footerRowData[0].dynamicContentFee;
          }
          if (this.footerRowData[0].overrideDiscounts !== null) {
            child.overrideDiscounts = this.footerRowData[0].overrideDiscounts;
            this.updateRowOnOverrideDiscount(child);
          }
          if (this.footerRowData[0].comments) {
            child.comments = this.footerRowData[0].comments;
          }
          if (this.footerRowData[0].feesDescription) {
            child.feesDescription = this.footerRowData[0].feesDescription;
          }
          this.calculateCellValues(child);
        }
        this.resetApply();
        this.rowData = _.clone(this.rowData);
      }
    } else {
      this.toaster.logInfo(this.userBundle['discount.at.selectionError'] || 'Select atleast one row!');
    }
  }

  spread = () => {
    const selectedChildRows = this.getSelectedChildRows(null, true);
    if (selectedChildRows.length > 0) {
      if (this.footerRowData[0].surcharge === 0 || this.footerRowData[0].surcharge
        || this.footerRowData[0].discount === 0 || this.footerRowData[0].discount
        || this.footerRowData[0].networkPrice === 0 || this.footerRowData[0].networkPrice
        || this.footerRowData[0].mediaDiscount1 === 0 || this.footerRowData[0].mediaDiscount1
        || this.footerRowData[0].mediaDiscount2 === 0 || this.footerRowData[0].mediaDiscount2
        || this.footerRowData[0].agencyFeePercent === 0 || this.footerRowData[0].agencyFeePercent
        || this.footerRowData[0].handlingFee === 0 || this.footerRowData[0].handlingFee
        || this.footerRowData[0].advertisingTax === 0 || this.footerRowData[0].advertisingTax
        || this.footerRowData[0].commercialTax === 0 || this.footerRowData[0].commercialTax
        || this.footerRowData[0].fees === 0 || this.footerRowData[0].fees
        || this.footerRowData[0].dynamicContentFee === 0 || this.footerRowData[0].dynamicContentFee) {
        this.footerRowData[0].net1 = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].net1);
        for (const selectedChildRow of selectedChildRows) {
          if (this.footerRowData[0].networkPrice === 0 || this.footerRowData[0].networkPrice) {
            this.footerRowData[0].networkPrice = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].networkPrice);
            selectedChildRow.networkPrice = this.footerRowData[0].networkPrice / selectedChildRows.length;
          }
          if (this.footerRowData[0].surcharge === 0 || this.footerRowData[0].surcharge) {
            selectedChildRow.surcharge = this.footerRowData[0].surcharge / selectedChildRows.length;
          }
          if (this.footerRowData[0].discount === 0 || this.footerRowData[0].discount) {
            selectedChildRow.discount = this.footerRowData[0].discount / selectedChildRows.length;
          }
          if ((this.footerRowData[0].mediaDiscount1 === 0 || this.footerRowData[0].mediaDiscount1)) {
            selectedChildRow.mediaDiscount1 = this.footerRowData[0].mediaDiscount1 / selectedChildRows.length;
          }
          if ((this.footerRowData[0].mediaDiscount2 === 0 || this.footerRowData[0].mediaDiscount2)) {
            selectedChildRow.mediaDiscount2 = this.footerRowData[0].mediaDiscount2 / selectedChildRows.length;
          }
          if (this.footerRowData[0].agencyFeePercent === 0 || this.footerRowData[0].agencyFeePercent) {
            selectedChildRow.agencyFeePercent = this.footerRowData[0].agencyFeePercent / selectedChildRows.length;
          }
          if (this.footerRowData[0].handlingFee === 0 || this.footerRowData[0].handlingFee) {
            this.footerRowData[0].handlingFee = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].handlingFee);
            selectedChildRow.handlingFee = this.footerRowData[0].handlingFee / selectedChildRows.length;
          }
          if (this.footerRowData[0].advertisingTax === 0 || this.footerRowData[0].advertisingTax) {
            selectedChildRow.advertisingTax = this.footerRowData[0].advertisingTax / selectedChildRows.length;
          }
          if (this.footerRowData[0].commercialTax === 0 || this.footerRowData[0].commercialTax) {
            selectedChildRow.commercialTax = this.footerRowData[0].commercialTax / selectedChildRows.length;
          }
          if (this.footerRowData[0].fees === 0 || this.footerRowData[0].fees) {
            this.footerRowData[0].fees = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].fees);
            selectedChildRow.fees = this.footerRowData[0].fees / selectedChildRows.length;
          }
          if (this.footerRowData[0].dynamicContentFee === 0 || this.footerRowData[0].dynamicContentFee) {
            this.footerRowData[0].dynamicContentFee = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].dynamicContentFee);
            selectedChildRow.dynamicContentFee = this.footerRowData[0].dynamicContentFee / selectedChildRows.length;
          }
          if (this.footerRowData[0].overrideDiscounts !== null) {
            selectedChildRow.overrideDiscounts = this.footerRowData[0].overrideDiscounts / selectedChildRows.length;
            this.updateRowOnOverrideDiscount(selectedChildRow);
          }

          this.calculateCellValues(selectedChildRow);
        }
        this.resetApply();
        this.rowData = _.clone(this.rowData);
      }
    } else {
      this.toaster.logInfo(this.userBundle['discount.at.selectionError'] || 'Select atleast one row!');
    }
  }

  reset = () => {
    this.resetApply();
    this.rowData = _.clone(this.rowData);
  }

  resetApply = () => {
    const columnArray = [
      'campaignStatus',
      'networkPrice',
      'surcharge',
      'discount',
      'mediaDiscount1',
      'mediaDiscount2',
      'agencyFeePercent',
      'handlingFee',
      'advertisingTax',
      'commercialTax',
      'fees',
      'dynamicContentFee',
      'overrideDiscounts',
      'comments',
      'feesDescription',
      'statusCategory'
    ];
    for (const column of columnArray) {
      this.footerRowData[0][column] = null;
    }

    // After reset, when we try to edit network price footer cell, its not changing to textbox
    this.updateNetworkPriceEditable(true);
  }

  groupingCellRenderer(params) {
    return params.data.childCount ? params.data.childCount : '';
  }

  calcAverage(rows, property) {
    let returnVal = 0;
    rows.forEach((row) => {
      returnVal += parseFloat(row[property]);
    });
    return (returnVal / rows.length).toFixed(2);
  }

  isCellEditable(data) {
    return !data.isParent && !data.locked;
  }

  toggleColumnVisible(colId, isVisible) {
    const isVisibleLoc = !isVisible;
    this.gridOptions.columnApi.setColumnVisible(colId, isVisibleLoc);
  }

  // Data functions
  getChildRows(groupingId?: string, filterOnly = true): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  getSelectedChildRows(groupingId?: string, filterOnly = false): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  getParentRows(groupingId?: string, data?: any[], groupingField?: string): any[] {
    const newData = data || this.rowData;
    return AgCustomRowUtil.getParentRows(newData, groupingField, groupingId);
  }

  sumFieldValues(rows: any[], fieldName: string): number {
    return AgCustomRowUtil.sumFieldValues(rows, fieldName);
  }

  calculateGridSummaryValues() {
    this.avgSurcharge = this.getTotalOfSelectedProperty('surcharge');
    this.avgNetworkPrice = this.getTotalOfSelectedProperty('networkPrice');
    this.avgDiscount = this.getTotalOfSelectedProperty('discount');
    this.avgMediaDiscount1 = this.getTotalOfSelectedProperty('mediaDiscount1');
    this.avgMediaDiscount2 = this.getTotalOfSelectedProperty('mediaDiscount2');
    this.avgFinalTotal = this.getTotalOfSelectedProperty('finalTotal');
    this.avgAgencyFeePercent = this.getTotalOfSelectedProperty('agencyFeePercent');
    this.avgAgencyFee = this.getTotalOfSelectedProperty('agencyFee');
  }

  getTotalOfSelectedProperty(propertyName: string, filterOnly = false): number {
    const rows = AgCustomRowUtil.getSelectedChildRows(this.rowData, null, null, filterOnly);
    return AgCustomRowUtil.sumFieldValues(rows, propertyName);
  }

  calculateTotal1(rowData) {
    const result = parseFloat(Number(rowData.networkPrice).toFixed(this.precisionOnPrice)) + (((parseFloat(rowData.surcharge) / 100) * parseFloat(Number(rowData.networkPrice).toFixed(this.precisionOnPrice))));
    return parseFloat(result.toFixed(this.precisionOnPrice));
  }

  calculateTotal2(rowData) {
    const result = parseFloat(rowData.newTotal1) - (((parseFloat(rowData.discount) / 100) * parseFloat(rowData.newTotal1)));
    return parseFloat(result.toFixed(this.precisionOnPrice));
  }

  calculateTotal3(rowData) {
    const result = parseFloat(rowData.newTotal2) - (((parseFloat(rowData.mediaDiscount1) / 100) * parseFloat(rowData.newTotal2)));
    return parseFloat(result.toFixed(this.precisionOnPrice));
  }

  calculateTotal4(rowData) {
    const result = parseFloat(rowData.newTotal3) - (((parseFloat(rowData.mediaDiscount2) / 100) * parseFloat(rowData.newTotal3)));
    return parseFloat(result.toFixed(this.precisionOnPrice));
  }

  calculateAgencyFee(rowData) {
    const result = ((parseFloat(rowData.agencyFeePercent) / 100) * parseFloat(rowData.newTotal4));
    return parseFloat(result.toFixed(this.precisionOnPrice));
  }

  calculateTotal5(rowData) {
    const result = parseFloat(rowData.newTotal4) - parseFloat(rowData.agencyFee);
    return parseFloat(result.toFixed(this.precisionOnPrice));
  }

  calculateTotal6(rowData) {
    const result = parseFloat(rowData.newTotal5) + parseFloat(rowData.handlingFee);
    return parseFloat(result.toFixed(this.precisionOnPrice));
  }

  calculateTotal6_5(rowData) {
    const result = parseFloat(rowData.newTotal6) + ((parseFloat(rowData.advertisingTax) / 100) * parseFloat(rowData.newTotal6));
    return parseFloat(result.toFixed(this.precisionOnPrice));
  }

  calculateTotal7(rowData) {
    const result = parseFloat(rowData.newTotal6_5) + ((parseFloat(rowData.commercialTax) / 100) * parseFloat(rowData.newTotal6_5));
    return parseFloat(result.toFixed(this.precisionOnPrice));
  }

  calculateFinalTotal(rowData) {
    const result = parseFloat(rowData.newTotal7) + parseFloat(rowData.fees) + parseFloat(rowData.dynamicContentFee);
    return parseFloat(result.toFixed(this.precisionOnPrice));
  }

  calculateCellValues(rowData) {
    rowData.newTotal1 = this.calculateTotal1(rowData);
    rowData.newTotal2 = this.calculateTotal2(rowData);
    rowData.newTotal3 = this.calculateTotal3(rowData);
    rowData.newTotal4 = this.calculateTotal4(rowData);
    rowData.agencyFee = this.calculateAgencyFee(rowData);
    rowData.newTotal5 = this.calculateTotal5(rowData);
    rowData.newTotal6 = this.calculateTotal6(rowData);
    rowData.newTotal6_5 = this.calculateTotal6_5(rowData);
    rowData.newTotal7 = this.calculateTotal7(rowData);
    rowData.finalTotal = this.calculateFinalTotal(rowData);
  }

  trackByField(index, column) {
    return column?.colDef?.field;
  }

}
