import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChange, HostListener, OnDestroy, OnChanges } from '@angular/core';
import { GridOptions, ColDef } from 'ag-grid-community';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { DataShareService } from '../../../core/services/data-share.service';
import { DiscountGridService } from './discount-grid.service';
import {
  AgCollapseExpandComponent,
  AgCheckboxComponent,
  AgCustomFilterMenuComponent,
  AgCustomHeaderComponent,
  AgTextCellEditorComponent,
  AgSelectCellEditorComponent,
  AgTypeAheadCellEditorComponent,
  AgNumberCellRendererComponent,
  AgCurrencyCellRendererComponent,
  AgSelectCellRendererComponent,
  AgTextCellRendererComponent,
  AgCheckboxCellRendererComponent,
  AgPercentageCellRendererComponent,
  AgCustomSort,
  AgCustomRowUtil,
  DataFormatterService,
  AgTextFloatingFilterComponent,
  AgSelectFloatingFilterComponent
} from '../ag-grid-support/index';
import { CopyLineModalComponent } from '../copy-line-modal/copy-line-modal.component';
import { SbModalPopupService } from '../../../core/components/sb-modal-popup/sb-modal-popup.service';
import { DecimalPipe } from '@angular/common';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { CommercialService } from '../../../core/services';
import { LocaleData } from '../../../core/utils/LocaleData';
import { SystemFlags } from '../../../models';

@Component({
  selector: 'app-discount-grid-germany',
  templateUrl: './discount-grid-germany.component.html',
  styleUrls: ['../ag-grid-custom-style.css', '../ag-grid-col-select-style.css', './discount-grid-germany.component.css']
})
export class DiscountGridGermanyComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  @Input() gridData: any = {};
  @Input() brandData = [];
  @Input() advertiserId;
  sortObj: AgCustomSort;
  /**
   * Ag-Grid Options Object
   */
  gridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  columnDefs: ColDef[];

  /**
   * column list to show in show/hide column dropdown
   */
  columnList: any = [];

  /**
   * list of Cart Data
   * @memberof CartDetailComponent
   */
  rowData: any[] = [];

  /**
   * Grid footer data
   */
  footerRowData: any[];

  /**
   * user bundle data
   */
  userBundle: any = {};

  totalSelectedNet1 = 0;
  totalSelectedNet2 = 0;
  totalSelectedNet3 = 0;
  totalSelectedMediaValue = 0;
  totalSelectedAgencyNet = 0;
  avgSelectedDiscount1 = '';
  avgSelectedDiscount2 = '';
  avgSelectedAgencyDiscount = '';

  statusCategory = [];
  indexedStatusCategory = [];
  agencyDiscount = [];
  agreement = [];

  numericFields = [];
  dateRangeFields = [];
  selectFields = [];
  groupingField = 'groupingId';
  notifyGridSubscription: Subscription;
  readonly AVG = 'discount.common.avg';

  constructor(
    private dataShareService: DataShareService,
    private discountGridService: DiscountGridService,
    private sbModalPopupService: SbModalPopupService,
    private toaster: LogHelperService,
    private dataFormatterService: DataFormatterService,
    private decimalPipe: DecimalPipe,
    private commercialService: CommercialService
  ) { }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.setArrayLists();

    this.setFooterRows();
    this.prepareRowData();
    this.prepareColDef();

    this.reset();

    // we pass an empty gridOptions in, so we can grab the api out
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      onRowDataChanged: (event) => {
        event.api.setPinnedBottomRowData(this.footerRowData);
      },
      scrollbarWidth: 10,
      onGridReady: this.onGridReady,
      getRowHeight: this.getRowHeight,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      suppressScrollOnNewData: true,
      onFilterChanged: this.onFilterChanged,
      onCellEditingStopped: this.onCellEditingStopped,
      groupingField: this.groupingField,
      suppressMovableColumns: true,
      isExternalFilterPresent: () => true,
      // tslint:disable-next-line:ter-arrow-parens
      doesExternalFilterPass: (node) => node.data.isVisible,
      onCellClicked: (params) => {
        if (params.rowPinned === 'bottom' && params.rowIndex === 0 && params.column.isCellEditable(params.node)) {
          const par: any = {
            column: params.column,
            rowIndex: params.rowIndex,
            rowPinned: params.rowPinned
          };
          /* tslint:disable:no-string-literal */
          params.api['rowRenderer'].startEditingCell(par);
        }
      }
    } as GridOptions;

    this.notifyGridSubscription = this.commercialService.gridData$.subscribe((data: { brandData: any[], clearBrands?: boolean }) => {
      if (data.brandData) {
        this.brandData = data.brandData;
      }
      if (data.clearBrands) {
        this.rowData = this.commercialService.clearBrandInDiscounts(this.rowData);
      }
      if (this.gridOptions.api) {
        this.gridOptions.api.redrawRows();
      }
    });
  }

  ngOnDestroy() {
    this.notifyGridSubscription.unsubscribe();
  }

  /**
   * Angular lifecycle hook - will be Called after any input property changes
   * @memberof GeoMapComponent
   */
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add 'implements OnChanges' to the class.
    if (changes.advertiserId && !changes.advertiserId.firstChange
      && (changes.advertiserId.currentValue !== changes.advertiserId.previousValue)) {
      this.footerRowData[0].brandName = null;
      this.rowData = _.clone(this.rowData);
    }
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.forEach((r) => {
        r.isFilterPass = true;
      });
    }
    AgCustomRowUtil.setParentRowSelectedProperty(this.rowData, this.groupingField);
    // Refresh Grid Header checkbox component
    /* tslint:disable:no-string-literal */
    this.gridOptions.api['headerRootComp'].forEachHeaderElement((element) => {
      if (element.column.colId === 'selectChk' && element.columnApi && element.childComponents && element.childComponents.length > 1) {
        const headerCmpIns = element.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  clearAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    this.gridOptions.api.onFilterChanged();
  }

  setArrayLists() {
    this.agreement = this.gridData.agreement;
    this.setStatusCategory();
    this.setAgencyDiscountArr();
  }

  setStatusCategory() {
    this.statusCategory = this.gridData.statusCategory;
    this.indexedStatusCategory = [];

    if (this.statusCategory && _.isArray(this.statusCategory)) {
      for (const object of this.statusCategory) {
        let label = '';
        if (object.statusCategoryCode && object.statusCategoryName) {
          label = `${object.statusCategoryCode} - ${object.statusCategoryName}`;
        } else if (object.statusCategoryCode) {
          label = object.statusCategoryCode;
        } else if (object.statusCategoryName) {
          label = object.statusCategoryName;
        } else {
          label = '';
        }
        object.label = label;
        this.indexedStatusCategory[object.idStatusCategory] = object;
      }
    }
  }

  setAgencyDiscountArr() {
    this.agencyDiscount = [];
    if (this.gridData && this.gridData.agencyDiscount && _.isArray(this.gridData.agencyDiscount)) {
      for (const discount of this.gridData.agencyDiscount) {
        this.agencyDiscount.push({ id: this.gridData.agencyDiscount.indexOf(discount), agencyDiscountValue: discount });
      }
    }
  }

  updateStatusCategory = (selectedRecord) => {
    const statusCategories = [];
    this.gridData.discounts.forEach(function (element) {
      if (element[this.groupingField] === selectedRecord[this.groupingField] && element.statusCategoryId !== selectedRecord.statusCategoryId) {
        statusCategories.push(element.statusCategoryId);
      }
    }, this);
    const updatedSatusCategory = _.clone(this.statusCategory);
    if (statusCategories) {
      statusCategories.forEach((element) => {
        const index = _.findIndex(updatedSatusCategory, (o) => {
          return o.idStatusCategory === element;
        });
        updatedSatusCategory.splice(index, 1);
      }, this);
    }
    return updatedSatusCategory;
  }

  onGridReady = () => {
    this.columnList = this.gridOptions.columnApi.getAllColumns();
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else if (params.data.isVisible) {
      height = 30;
    }
    return height;
  }

  isFullWidthCell = (rowNode): boolean => {
    return rowNode.data.gridSummary;
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }
  copyFramesForDiscontGrid = (param) => {
    this.discountGridService.copyFramesForDiscontGrid(param).subscribe((res) => {
      if (res.status === 'OK') {
        this.toaster.logSuccess(res.message);
        this.gridData.discounts = res.data.discounts;
        this.prepareRowData();
      } else {
        this.toaster.logError(res.message);
      }
    }, (error) => {
      this.toaster.logError(error.message);
    });
  }

  onCellEditingStopped = (event) => {
    if (event.rowPinned !== 'bottom') {
      switch (event.column.colId) {
        case 'numberFrames':
          const param = {
            action: 'updateDiscount',
            discounts: JSON.stringify(this.getChildRows(null, false))
          };
          this.copyFramesForDiscontGrid(param);
          break;
        case 'bookingStatusId':
          if (this.rowData.length > 0) {
            for (const object of this.rowData) {
              if (object[this.groupingField] === event.data[this.groupingField] && (object.isParent || !object.statusLocked)) {
                object.bookingStatusId = event.data.bookingStatusId;
              }
            }
          }
          break;
        case 'statusCategoryId':
          this.setDefaultDiscount(event.data);
          this.calculateTechFee(event.data);
          break;
        case 'discount1':
          this.calculateTechFee(event.data);
          break;
        case 'technicalFee':
          event.data.technicalFee = LocaleData.replaceLocalDecimalCharWithDot(event.data.technicalFee);
          break;
        default:
          break;
      }
      this.calculateCellValues(event.data);
      this.rowData = _.clone(this.rowData);
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen() && $event.button !== 2 && !dropdownEle._isEventFromToggle($event)
      && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
      dropdownEle.close();
    }
  }

  prepareRowData() {
    const tempRowData = _.clone(this.rowData);
    this.rowData = [];
    let groupId = null;
    for (const curRow of this.gridData.discounts) {
      if (curRow.grossValue === undefined) {
        curRow.grossValue = parseFloat(curRow.smartBricsValue.toFixed(2));
      }
      if (curRow.consecutiveDiscount) {
        curRow.consecutiveDiscount = ((1 - (curRow.net2 / curRow.mediaValue)) * 100).toFixed(2);
      }
      const tempRow = this.getParentRows(curRow[this.groupingField], tempRowData);
      let isExpanded = false;
      if (tempRow && tempRow.length > 0) {
        isExpanded = tempRow[0].isExpanded;
      }
      curRow.isFilterPass = true;
      if (groupId === curRow[this.groupingField]) {
        const tempRowWithRow = curRow;
        tempRowWithRow.isParent = false;
        tempRowWithRow.isVisible = isExpanded;
        tempRowWithRow.isSelected = false;
        this.rowData.push(tempRowWithRow);
      } else {
        groupId = curRow[this.groupingField];
        let tempRowWithOutGroupFiled = _.clone(curRow);
        tempRowWithOutGroupFiled.isParent = true;
        tempRowWithOutGroupFiled.isVisible = true;
        tempRowWithOutGroupFiled.isExpanded = isExpanded;
        tempRowWithOutGroupFiled.isSelected = false;
        tempRowWithOutGroupFiled.childCount = this.gridData.discounts.filter((row) => {
          return row[this.groupingField] === tempRowWithOutGroupFiled[this.groupingField];
        }).length;
        this.rowData.push(tempRowWithOutGroupFiled);

        tempRowWithOutGroupFiled = curRow;
        tempRowWithOutGroupFiled.isParent = false;
        tempRowWithOutGroupFiled.isVisible = isExpanded;
        tempRowWithOutGroupFiled.isSelected = false;
        this.rowData.push(tempRowWithOutGroupFiled);
      }
    }
  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinRowIndex: 0 },
      { isVisible: true, locked: true },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
  }

  getGridSummary() {
    this.calculateGridSummaryValues();
    return `<span>${this.userBundle['discount.common.totalFrames']}
    : ${this.getChildRows(null, false).length} &#45; ${this.userBundle['discount.common.selectedFrames']}
      : ${this.getSelectedChildRows().length} (<b>${this.userBundle['discount.germany.discount1']}
      </b>: ${this.decimalPipe.transform(this.avgSelectedDiscount1, '.2-2')}
      %, <b>${this.userBundle['discount.germany.net1']}</b>: ${this.dataFormatterService.currency(this.totalSelectedNet1)},
      <b> ${this.userBundle['discount.germany.discount2']}</b>: ${this.decimalPipe.transform(this.avgSelectedDiscount2, '.2-2')}
      %, <b>${this.userBundle['discount.germany.net2']}
      </b>: ${this.dataFormatterService.currency(this.totalSelectedNet2)}, <b>${this.userBundle['discount.germany.net3']}</b>:
      ${this.dataFormatterService.currency(this.totalSelectedNet3)}, <b>${this.userBundle['discount.germany.agencyDiscount']}
      </b>: ${this.decimalPipe.transform(this.avgSelectedAgencyDiscount, '.2-2')}
      %, <b>${this.userBundle['discount.germany.agencyNet']}</b>
      : ${this.dataFormatterService.currency(this.totalSelectedAgencyNet)})</span></div>`;
  }

  getCellClass = (params): string[] => {
    const retArr: string[] = [];
    if (params.data.isSelected) {
      retArr.push('selectedRow');
    }
    if (params.node.rowPinned === 'bottom' && params.rowIndex === 1) {
      retArr.push('borderBottom');
    }
    return retArr;
  }

  // tslint:disable-next-line: no-big-function   // tslint:disable-next-line: cognitive-complexity
  prepareColDef() {
    this.columnDefs = [
      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false,
        cellRendererFramework: AgCollapseExpandComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCollapseExpandComponent,
        cellClass: this.getCellClass
      },
      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false, colId: 'selectChk',
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: this.getCellClass
      },
      {
        headerName: this.userBundle['discount.germany.groupingId'],
        field: this.groupingField, width: 50, editable: false, pinned: 'left', filter: false,
        pinnedRowCellRenderer: this.blankCellRenderer,
        cellRenderer: this.groupingCellRenderer
      },
      {
        headerName: '', field: 'locked', width: 30, editable: false, pinned: 'left', filter: false,
        pinnedRowCellRenderer: this.blankCellRenderer,
        cellRenderer: this.lockColumnCellRenderer
      },
      {
        headerName: '', field: 'warning', width: 30, editable: false, pinned: 'left', filter: false,
        pinnedRowCellRenderer: this.blankCellRenderer,
        cellRenderer: this.warningColumnCellRenderer
      },
      {
        headerName: this.userBundle['discount.germany.tagName'], field: 'tagName', width: 135, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.tagNamePinnedRowCellRenderer,
      },
      {
        headerName: this.userBundle['discount.germany.weekNumber'], field: 'weekNumber', width: 150, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.germany.week'], field: 'week', width: 150, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.germany.weekDays'], field: 'weekDays', width: 150, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.germany.city'], field: 'city', width: 120, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.germany.media'], field: 'media', width: 120, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.germany.solutionNumberFrames'],
        field: 'solutionNumberFrames', width: 115, editable: false, pinned: false,
        cellRendererFramework: AgNumberCellRendererComponent,
        pinnedRowCellRendererFramework: AgNumberCellRendererComponent
      },
      {
        headerName: this.userBundle['discount.germany.numberFrames'], field: 'numberFrames', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgNumberCellRendererComponent,
        cellRendererParams: {
          cellEditable: (rowData) => {
            return !(rowData.isParent || rowData.locked);
          }
        },
        pinnedRowCellRendererFramework: AgNumberCellRendererComponent,
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 0,
          allowReplaceDecimalSeparator: true,
          cellEditable: (rowData) => {
            return (typeof (rowData.pinRowIndex) === 'number') && rowData.pinRowIndex === 0 ? false : !(rowData.isParent || rowData.locked);
          }
        }
      },
      {
        headerName: this.userBundle['discount.germany.status'], field: 'bookingStatusId', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgSelectCellRendererComponent,
        cellRendererParams: {
          cellEditable: (rowData) => {
            if (rowData.isParent) {
              return false;
            } else {
              return (!rowData.statusLocked && !rowData.locked && !SystemFlags.isPPCampaign);
            }
          },
          isValid: (rowData, colId) => {
            if (rowData.isParent) {
              let isValid = true;
              const childRows = this.getChildRows(rowData[this.groupingField]);
              for (const childRow of childRows) {
                if (!childRow[colId] || Number(childRow[colId]) === -1) {
                  isValid = false;
                  break;
                }
              }
              return isValid;
            } else {
              const fieldValue = rowData[colId];
              if (!fieldValue || fieldValue === -1) {
                return false;
              } else {
                return true;
              }
            }
          },
          valKey: 'bookingStatusId',
          textKey: 'bookingStatusCodeAlt',
          dataArr: () => {
            return this.gridData.bookingStatus;
          }
        },
        pinnedRowCellRendererFramework: AgSelectCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: (rowData) => {
            return (!rowData.locked && !SystemFlags.isPPCampaign) ? true : false;
          },
          valKey: 'bookingStatusId',
          textKey: 'bookingStatusCodeAlt',
          dataArr: () => {
            return this.gridData.bookingStatus;
          }
        },
        cellEditorFramework: AgSelectCellEditorComponent,
        cellEditorParams: {
          valKey: 'bookingStatusId',
          textKey: 'bookingStatusCodeAlt',
          dataArr: () => {
            return this.gridData.bookingStatus.filter((item) => {
              return item.inUse;
            });
          },
          cellEditable: (rowData) => {
            if (rowData.isParent) {
              return false;
            } else {
              return (!rowData.statusLocked && !rowData.locked && !SystemFlags.isPPCampaign);
            }
          }
        }
      },
      {
        headerName: this.userBundle['discount.germany.statusCategoryId'],
        field: 'statusCategoryId', width: 150, editable: true, pinned: false,
        cellRendererFramework: AgSelectCellRendererComponent,
        cellRendererParams: {
          cellEditable: (rowData) => {
            return rowData.isParent ? false : !rowData.locked;
          },
          valKey: 'idStatusCategory',
          textKey: 'label',
          dataArr: () => {
            return this.statusCategory;
          }
        },
        pinnedRowCellRendererFramework: AgSelectCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: (rowData) => {
            return !rowData.locked;
          },
          valKey: 'idStatusCategory',
          textKey: 'label',
          dataArr: () => {
            return this.statusCategory;
          }
        },
        cellEditorFramework: AgSelectCellEditorComponent,
        cellEditorParams: {
          valKey: 'idStatusCategory',
          textKey: 'label',
          dataArr: this.updateStatusCategory,
          cellEditable: (rowData) => {
            return !rowData.locked;
          }
        }
      },
      /*
      SM-30- hide smartBrics value column and gross value column should not be editable
      */
      // {
      //   headerName: this.userBundle['discount.germany.smartBricsValue'],
      // field: 'smartBricsValue', width: 120, editable: false, pinned: false,
      //   cellRendererFramework: AgCurrencyCellRendererComponent,
      //   pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent
      // },
      {
        headerName: this.userBundle['discount.germany.grossValue'], field: 'grossValue', width: 100, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return false;
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => this.cellEditable(data)
        }
      },
      {
        headerName: this.userBundle['discount.germany.extraCharge'], field: 'extraCharge', width: 100, editable: true, pinned: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => this.cellEditable(data),
          calcCellValue: this.calcAvgExtraCharge
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => this.cellEditable(data),
          calcCellValue: this.calcAvgExtraCharge,
          prefixText: (data) => {
            return ((!data.isParent) && (!data.locked)) ? '' : this.userBundle[this.AVG];
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable,
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.germany.smartNet'],
        field: 'smartNet', width: 85, editable: false, pinned: false, filter: false,
        pinnedRowCellRendererFramework: AgCheckboxCellRendererComponent,
        cellRendererFramework: AgCheckboxCellRendererComponent,
        cellRendererParams: this.getCheckBoxCellRenderParam(false),
        pinnedRowCellRendererParams: this.getCheckBoxCellRenderParam(true)
      },
      {
        headerName: this.userBundle['discount.germany.mediaValue'], field: 'mediaValue', width: 100, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true
        }
      },
      {
        headerName: this.userBundle['discount.germany.discount1'], field: 'discount1', width: 100, editable: true, pinned: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => this.cellEditable(data),
          calcCellValue: this.calcDiscount1
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => this.cellEditable(data),
          calcCellValue: this.calcDiscount1,
          prefixText: (data) => {
            return ((!data.isParent) && (!data.locked)) ? '' : this.userBundle[this.AVG];
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable,
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.germany.net1'], field: 'net1', width: 120, editable: true, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => this.isCellEditable(data)
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          allowReplaceDecimalSeparator: true,
          cellEditable: (row) => {
            return !row[this.groupingField];
          }
        }
      },
      {
        headerName: this.userBundle['discount.germany.discount2'], field: 'discount2', width: 100, editable: true, pinned: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => this.cellEditable(data),
          calcCellValue: this.calcDiscount2
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => this.cellEditable(data),
          calcCellValue: this.calcDiscount2,
          prefixText: (data) => {
            return ((!data.isParent) && (!data.locked)) ? '' : this.userBundle[this.AVG];
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable,
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.germany.net2'], field: 'net2', width: 120, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true
        }
      },
      {
        headerName: this.userBundle['discount.germany.agencyDiscount'], field: 'agencyDiscount', width: 115, editable: true, pinned: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => this.cellEditable(data),
          calcCellValue: this.calcAgencyDiscount
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => this.cellEditable(data),
          calcCellValue: this.calcAgencyDiscount,
          prefixText: (data) => {
            return ((!data.isParent) && (!data.locked)) ? '' : this.userBundle[this.AVG];
          }
        },
        cellEditorFramework: AgSelectCellEditorComponent,
        cellEditorParams: {
          valKey: 'agencyDiscountValue',
          textKey: 'agencyDiscountValue',
          dataArr: () => {
            return this.agencyDiscount;
          },
          cellEditable: this.isCellEditable
        }
      },
      {
        headerName: this.userBundle['discount.germany.agencyNet'], field: 'agencyNet', width: 90, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true
        }
      },
      {
        headerName: this.userBundle['discount.germany.payRate'], field: 'payRate', width: 90, editable: false, pinned: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          calcCellValue: this.calcPayRate,
          allowReplaceDecimalSeparator: true
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          calcCellValue: this.calcPayRate,
          allowReplaceDecimalSeparator: true,
          prefixText: (data) => ((!data.isParent) && (!data.locked)) ? '' : this.userBundle[this.AVG]
        },
      },
      {
        headerName: this.userBundle['discount.germany.consecutiveDiscount'],
        field: 'consecutiveDiscount', width: 100, editable: false, pinned: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          calcCellValue: this.calcAvgConsecutiveDiscount,
          allowReplaceDecimalSeparator: true
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          calcCellValue: this.calcAvgConsecutiveDiscount,
          allowReplaceDecimalSeparator: true,
          prefixText: (data) => {
            return ((!data.isParent) && (!data.locked)) ? '' : this.userBundle[this.AVG];
          }
        }
      },
      {
        headerName: this.userBundle['discount.germany.technicalFee'], field: 'technicalFee', width: 100, editable: true, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => this.cellEditable(data)
        },
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => this.cellEditable(data)
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable
        }
      },
      {
        headerName: this.userBundle['discount.germany.sapNumber'], field: 'sapNumber', width: 100, editable: true, pinned: false,
        cellRendererFramework: AgTextCellRendererComponent,
        cellRendererParams: {
          cellEditable: (rowData) => {
            return ((!rowData.isParent) && (!rowData.locked));
          }
        },
        pinnedRowCellRendererFramework: AgTextCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: (rowData) => {
            return ((!rowData.isParent) && (!rowData.locked));
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: false,
          decimalPoints: 2,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable
        }
      },
      {
        headerName: this.userBundle['discount.germany.net3'], field: 'net3', width: 120, editable: false, pinned: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true
        }
      },
      {
        headerName: this.userBundle['discount.germany.poNumber'], field: 'poNumber', width: 120, editable: true, pinned: false,
        cellRendererFramework: AgTextCellRendererComponent,
        cellRendererParams: {
          cellEditable: (rowData) => {
            return ((!rowData.isParent) && (!rowData.locked));
          }
        },
        pinnedRowCellRendererFramework: AgTextCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: (rowData) => {
            return ((!rowData.isParent) && (!rowData.locked));
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: false,
          decimalPoints: 2,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isCellEditable
        }
      },
      {
        headerName: this.userBundle['discount.germany.brand'], field: 'brandName', width: 180, editable: true, pinned: false,
        pinnedRowCellRenderer: this.brandPinnedCellRenderer,
        cellRendererFramework: AgTextCellRendererComponent,
        cellEditorFramework: AgTypeAheadCellEditorComponent,
        cellEditorParams: {
          valKey: 'brandId',
          textKey: 'brandName',
          dataArr: () => {
            return this.brandData;
          },
          cellEditable: this.isBrandPinCellEditable
        }
      },
      {
        headerName: this.userBundle['discount.germany.inclusive'],
        field: 'inclusive', width: 90, editable: false, pinned: false, filter: false,
        pinnedRowCellRendererFramework: AgCheckboxCellRendererComponent,
        cellRendererFramework: AgCheckboxCellRendererComponent,
        cellRendererParams: this.getCheckBoxCellRenderParam(false),
        pinnedRowCellRendererParams: this.getCheckBoxCellRenderParam(true)
      },
      {
        headerName: this.userBundle['discount.germany.pop'], field: 'pop', width: 90, editable: false, pinned: false, filter: false,
        pinnedRowCellRendererFramework: AgCheckboxCellRendererComponent,
        cellRendererFramework: AgCheckboxCellRendererComponent,
        cellRendererParams: {
          cellEditable: (data) => {
            return (!data.isParent);
          },
          onSelectChange: (params) => {
            this.onCellEditingStopped(params);
          }
        },
        pinnedRowCellRendererParams: this.getCheckBoxCellRenderParam(true)
      },
      {
        headerName: this.userBundle['discount.germany.inSitu'],
        field: 'inSitu', width: 150, editable: false, pinned: false, filter: false,
        pinnedRowCellRendererFramework: AgCheckboxCellRendererComponent,
        cellRendererFramework: AgCheckboxCellRendererComponent,
        cellRendererParams: {
          cellEditable: (data) => {
            return (!data.isParent);
          },
          onSelectChange: (params) => {
            this.onCellEditingStopped(params);
          }
        },
        pinnedRowCellRendererParams: this.getCheckBoxCellRenderParam(true)
      },
      {
        headerName: this.userBundle['discount.germany.agreementId'],
        field: 'agreementId', width: 100, editable: true, pinned: false,
        cellRendererFramework: AgSelectCellRendererComponent,
        cellRendererParams: {
          cellEditable: (rowData) => {
            return (!rowData.isParent);
          },
          valKey: 'id',
          textKey: 'agreementName',
          dataArr: () => {
            return this.agreement;
          }
        },
        pinnedRowCellRendererFramework: AgSelectCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: (rowData) => {
            return !rowData.locked;
          },
          valKey: 'id',
          textKey: 'agreementName',
          dataArr: () => {
            return this.agreement;
          }
        },
        cellEditorFramework: AgSelectCellEditorComponent,
        cellEditorParams: {
          valKey: 'id',
          textKey: 'agreementName',
          dataArr: () => {
            return this.agreement;
          },
          cellEditable: (rowData) => {
            return (!rowData.isParent);
          }
        }
      },
      {
        headerName: this.userBundle['discount.germany.finalised'],
        field: 'finalised', width: 120, editable: false, pinned: false, filter: false,
        pinnedRowCellRendererFramework: AgCheckboxCellRendererComponent,
        cellRendererFramework: AgCheckboxCellRendererComponent,
        cellRendererParams: this.getCheckBoxCellRenderParam(false),
        pinnedRowCellRendererParams: this.getCheckBoxCellRenderParam(true)
      },
      {
        headerName: this.userBundle['discount.germany.comments'], field: 'comments', width: 120, editable: true, pinned: false,
        cellRendererFramework: AgTextCellRendererComponent,
        cellRendererParams: {
          cellEditable: (rowData) => {
            return (!rowData.isParent);
          }
        },
        pinnedRowCellRendererFramework: AgTextCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: (rowData) => {
            return ((!rowData.isParent) && (!rowData.locked));
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: false,
          decimalPoints: 2,
          allowReplaceDecimalSeparator: true,
          cellEditable: (rowData) => {
            return (!rowData.isParent);
          }
        }
      }
    ];

    this.numericFields = [
      'childCount',
      'weekNumber',
      'solutionNumberFrames',
      'numberFrames',
      'smartBricsValue',
      'grossValue',
      'extraCharge',
      'mediaValue',
      'discount1',
      'net1',
      'discount2',
      'net2',
      'agencyDiscount',
      'agencyNet',
      'payRate',
      'consecutiveDiscount',
      'technicalFee',
      'net3'
    ];

    this.dateRangeFields = ['week'];
    this.selectFields = ['bookingStatusId', 'statusCategoryId', 'agreementId'];

    this.processColumnDefs();

    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField);
  }

  processColumnDefs() {
    const decimalFieldsForFilter = [
      'grossValue',
      'extraCharge',
      'mediaValue',
      'discount1',
      'net1',
      'discount2',
      'net2',
      'agencyDiscount',
      'agencyNet',
      'payRate',
      'consecutiveDiscount',
      'technicalFee',
      'net3'
    ];
    for (let i = 2; i < this.columnDefs.length; i++) {
      if (this.columnDefs[i].field === 'bookingStatusId') {
        this.columnDefs[i].floatingFilterComponentFramework = AgSelectFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          suppressFilterButton: true,
          valKey: 'bookingStatusId',
          textKey: 'bookingStatusCodeAlt',
          dataArr: () => this.getDataArr(),
          value: ''
        };
      } else if (this.columnDefs[i].field === 'statusCategoryId') {
        this.columnDefs[i].floatingFilterComponentFramework = AgSelectFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          suppressFilterButton: true,
          valKey: 'idStatusCategory',
          textKey: 'label',
          dataArr: () => {
            return this.updateStatusCategory({});
          },
          value: ''
        };
      } else if (this.columnDefs[i].field === 'agreementId') {
        this.columnDefs[i].floatingFilterComponentFramework = AgSelectFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          suppressFilterButton: true,
          valKey: 'id',
          textKey: 'agreementName',
          dataArr: () => {
            return this.agreement;
          },
          value: ''
        };
      } else {
        this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          value: '',
          suppressFilterButton: true,
          decimalRound: decimalFieldsForFilter.indexOf(this.columnDefs[i].field) > -1 ? 2 : null
        };
      }
      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };

      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => this.processCellClass(params, i);
    }
  }

  getDataArr() {
    const list = this.gridData.bookingStatus.filter((item) => {
      return item.inUse;
    });
    for (const disount of this.gridData.discounts) {
      const id = disount.bookingStatusId;
      const recExist = list.filter((item) => {
        return item.bookingStatusId === id;
      });
      if (recExist.length === 0) {
        const temp = this.gridData.bookingStatus.filter((item) => {
          return item.bookingStatusId === disount.bookingStatusId;
        });
        if (temp.length > 0) {
          list.push(temp[0]);
        }
      }
    }
    return list;
  }

  processCellClass(params, i) {
    const retArr: string[] = [];
    if (params.data.isParent || params.node.rowPinned === 'bottom') {
      retArr.push('fontBold');
    }
    if (params.node.rowPinned === 'bottom' && (i > 4 || params.rowIndex === 1)) {
      retArr.push('borderBottom');
    }
    if (params.data.isSelected) {
      retArr.push('selectedRow');
    }
    if (this.columnDefs[i].field === 'solutionNumberFrames' || this.columnDefs[i].field === 'numberFrames') {
      let valSolNum = params.data.solutionNumberFrames;
      let valNumFrame = params.data.numberFrames;
      if (params.data.isParent) {
        const childRows = this.getChildRows(params.data[this.groupingField]);
        valSolNum = this.sumFieldValues(childRows, 'solutionNumberFrames');
        valNumFrame = this.sumFieldValues(childRows, 'numberFrames');
      }
      if (valSolNum !== valNumFrame) {
        retArr.push('danger');
      }
    }
    if (this.columnDefs[i].field === 'locked' || this.columnDefs[i].field === 'warning') {
      retArr.push('lockCellClass');
    }
    return retArr;
  }

  isValidStatus(rowData, colId) {
    if (rowData.isParent) {
      let isValid = true;
      const childRows = this.getChildRows(rowData[this.groupingField]);
      for (const row of childRows) {
        if (!row[colId] || row[colId] === -1) {
          isValid = false;
          break;
        }
      }
      return isValid;
    } else {
      const fieldValue = rowData[colId];
      if (!fieldValue || fieldValue === -1) {
        return false;
      } else {
        return true;
      }
    }
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
  }

  blankCellRenderer() {
    return '';
  }

  tagNamePinnedRowCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {
      // Copy Icon
      const eImgCopy: HTMLImageElement = document.createElement('img');
      eImgCopy.src = 'images/general/copy.png';
      eImgCopy.title = 'copy';
      eImgCopy.onclick = this.copy;
      eDiv.appendChild(eImgCopy);

      // apply Icon
      const eImgApply: HTMLImageElement = document.createElement('img');
      eImgApply.src = 'images/general/apply.png';
      eImgApply.title = 'Apply';
      eImgApply.onclick = this.apply;
      eDiv.appendChild(eImgApply);

      // Spread Icon
      const eImgSpread: HTMLImageElement = document.createElement('img');
      eImgSpread.src = 'images/general/spread.png';
      eImgSpread.title = 'Spread';
      eImgSpread.onclick = this.spread;
      eDiv.appendChild(eImgSpread);

      // Reset Icon
      const eImg: HTMLImageElement = document.createElement('img');
      eImg.src = 'images/general/reset.png';
      eImg.title = 'Reset';
      eImg.onclick = this.reset;
      eDiv.appendChild(eImg);
    } else if (params.rowIndex === 1) {
      eDiv.innerHTML = `<span class='cellRightSpan fontBold'>${this.userBundle['discount.common.totalBar']}</span>`;
    }
    return eDiv;
  }

  copy = () => {
    if (this.rowData.length > 0) {
      const selectedRecord: any[] = _.clone(this.getSelectedChildRows(null, true));

      if (selectedRecord.length === 1) {
        const updatedSatusCategory = this.updateStatusCategory(selectedRecord[0]);
        this.sbModalPopupService.open(CopyLineModalComponent, {
          selectedRecord: _.clone(selectedRecord[0]),
          statusOptions: updatedSatusCategory
        }).result.then((selectedItem) => {
          selectedRecord[0].numberFrames = selectedRecord[0].numberFrames - selectedItem.numberFrames;
          this.setDefaultDiscount(selectedItem);
          this.calculateCellValues(selectedItem);
          const childRows = this.getChildRows(null, false);
          childRows.push(selectedItem);
          const param = {
            action: 'updateDiscount',
            discounts: JSON.stringify(childRows)
          };
          this.copyFramesForDiscontGrid(param);
        }, () => {
        });
      } else {
        if (selectedRecord.length === 0) {
          this.toaster.logInfo(this.userBundle['discount.germany.atleastOne']);
        } else {
          this.toaster.logInfo(this.userBundle['discount.germany.onlyOne']);
        }
      }
    } else {
      this.toaster.logError('Data not available!');
    }
  }

  apply = () => {
    const selectedChildRows = this.getSelectedChildRows(null, true);
    if (selectedChildRows.length > 0) {
      if ((this.footerRowData[0].extraCharge === 0 || this.footerRowData[0].extraCharge
        || this.footerRowData[0].discount1 === 0 || this.footerRowData[0].discount1
        || this.footerRowData[0].discount2 === 0 || this.footerRowData[0].discount2
        || this.footerRowData[0].agencyDiscount === 0 || this.footerRowData[0].agencyDiscount
        || this.footerRowData[0].agreementId
        || this.footerRowData[0].grossValue === 0 || this.footerRowData[0].grossValue
        || this.footerRowData[0].statusCategoryId || this.footerRowData[0].sapNumber
        || this.footerRowData[0].smartNet != null || this.footerRowData[0].inclusive != null
        || this.footerRowData[0].pop != null || this.footerRowData[0].inSitu != null
        || this.footerRowData[0].bookingStatusId != null || this.footerRowData[0].finalised != null
        || this.footerRowData[0].brandName || this.footerRowData[0].poNumber
        || this.footerRowData[0].technicalFee || this.footerRowData[0].technicalFee === 0 || this.footerRowData[0].comments)) {

        for (const row of selectedChildRows) {
          if (row.locked) {
            if (this.footerRowData[0].pop != null) {
              row.pop = this.footerRowData[0].pop;
            }
            if (this.footerRowData[0].inSitu != null) {
              row.inSitu = this.footerRowData[0].inSitu;
            }
            if (this.footerRowData[0].brandName && this.footerRowData[0].brandName.length > 0) {
              if (this.footerRowData[0].brandName[0].brandName) {
                row.brandName = this.footerRowData[0].brandName[0].brandName;
              }
              if (this.footerRowData[0].brandName[0].brandId) {
                row.brandId = this.footerRowData[0].brandName[0].brandId;
              }
            }
            if (this.footerRowData[0].comments) {
              row.comments = this.footerRowData[0].comments;
            }
            if (this.footerRowData[0].agreementId) {
              row.agreementId = this.footerRowData[0].agreementId;
            }
          } else {
            if (this.footerRowData[0].extraCharge === 0 || this.footerRowData[0].extraCharge) {
              row.extraCharge = this.footerRowData[0].extraCharge;
            }
            if (this.footerRowData[0].discount1 === 0 || this.footerRowData[0].discount1) {
              row.discount1 = this.footerRowData[0].discount1;
              this.calculateTechFee(row);
            }
            if (this.footerRowData[0].discount2 === 0 || this.footerRowData[0].discount2) {
              row.discount2 = this.footerRowData[0].discount2;
            }
            if (this.footerRowData[0].agencyDiscount === 0 || this.footerRowData[0].agencyDiscount) {
              row.agencyDiscount = this.footerRowData[0].agencyDiscount;
            }
            if (this.footerRowData[0].agreementId) {
              row.agreementId = this.footerRowData[0].agreementId;
            }
            if (this.footerRowData[0].grossValue === 0 || this.footerRowData[0].grossValue) {
              row.grossValue = this.footerRowData[0].grossValue;
            }
            if (this.footerRowData[0].statusCategoryId) {
              row.statusCategoryId = this.footerRowData[0].statusCategoryId;
              this.setDefaultDiscount(row);
              this.calculateTechFee(row);
            }
            if (this.footerRowData[0].sapNumber) {
              row.sapNumber = this.footerRowData[0].sapNumber;
            }
            if (this.footerRowData[0].smartNet != null) {
              row.smartNet = this.footerRowData[0].smartNet;
            }
            if (this.footerRowData[0].inclusive != null) {
              row.inclusive = this.footerRowData[0].inclusive;
            }
            if (this.footerRowData[0].pop != null) {
              row.pop = this.footerRowData[0].pop;
            }
            if (this.footerRowData[0].inSitu != null) {
              row.inSitu = this.footerRowData[0].inSitu;
            }
            if (this.footerRowData[0].finalised != null) {
              row.finalised = this.footerRowData[0].finalised;
            }
            if (this.footerRowData[0].bookingStatusId != null && !row.statusLocked) {
              row.bookingStatusId = this.footerRowData[0].bookingStatusId;
            }
            if (this.footerRowData[0].brandName && this.footerRowData[0].brandName.length > 0) {
              if (this.footerRowData[0].brandName[0].brandName) {
                row.brandName = this.footerRowData[0].brandName[0].brandName;
              }
              if (this.footerRowData[0].brandName[0].brandId) {
                row.brandId = this.footerRowData[0].brandName[0].brandId;
              }
            }
            if (this.footerRowData[0].poNumber) {
              row.poNumber = this.footerRowData[0].poNumber;
            }
            if (((this.footerRowData[0].technicalFee) || this.footerRowData[0].technicalFee === 0)
              && this.footerRowData[0].technicalFee !== '') {
              this.footerRowData[0].technicalFee = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].technicalFee);
              row.technicalFee = this.footerRowData[0].technicalFee;
            }
            if (this.footerRowData[0].comments) {
              row.comments = this.footerRowData[0].comments;
            }
            this.calculateCellValues(row);
          }
        }
        this.resetApply();
        this.rowData = _.clone(this.rowData);
      }
    } else {
      this.toaster.logInfo('Select atleast one row!');
    }
  }

  spread = () => {
    const selectedChildRows = this.getSelectedChildRows(null, true);
    if (selectedChildRows.length > 0) {
      if ((this.footerRowData[0].net1 === 0 || this.footerRowData[0].net1)) {
        this.footerRowData[0].net1 = LocaleData.replaceLocalDecimalCharWithDot(this.footerRowData[0].net1);
        for (const row of selectedChildRows) {
          if (row.locked === false) {
            if (this.footerRowData[0].net1 === 0 || this.footerRowData[0].net1) {
              row.net1 = parseFloat(this.calculateNet1Spread(row.mediaValue,
                parseFloat(this.footerRowData[0].net1)));
            }

            // calculate Discount1
            const parsedMediaValue = parseFloat(row.mediaValue);
            const divide = (parsedMediaValue !== 0) ? (100 * (1 - (parseFloat(row.net1) / parsedMediaValue))) : 0;
            row.discount1 = divide;

            this.calculateCellValuesSpread(row);
          }
        }
        this.resetSpread();
        this.rowData = _.clone(this.rowData);
      }
    } else {
      this.toaster.logInfo('Select atleast one row!');
    }
  }

  reset = () => {
    this.resetApply();
    this.resetSpread();
    this.rowData = _.clone(this.rowData);
  }

  resetApply = () => {
    const columnArray = [
      'extraCharge', 'discount1', 'discount2', 'agencyDiscount', 'techFee',
      'agreementId', 'grossValue', 'statusCategoryId', 'sapNumber', 'smartNet', 'inclusive', 'pop', 'inSitu',
      'bookingStatusId', 'brandName', 'poNumber', 'finalised', 'technicalFee', 'comments'];
    for (const column of columnArray) {
      this.footerRowData[0][column] = null;
    }
  }

  resetSpread = () => {
    this.footerRowData[0].net1 = null;
  }

  calculateNet1Spread = (mediaVal, net1Val) => {
    const mediaValue = parseFloat(mediaVal);
    const net1 = parseFloat(net1Val);
    let result = 0;
    let totalMediaValue = 0;
    totalMediaValue = this.getTotalOfSelectedProperty('mediaValue', true);
    result = totalMediaValue !== 0 ? (net1 * mediaValue / totalMediaValue) : 0;
    return result.toFixed(2);
  }

  calculateCellValuesSpread = (rowData) => {

    rowData.net2 = this.calculateNet2(rowData.net1, rowData.discount2);
    rowData.agencyNet = this.calculateAgencyNet(rowData.net2, rowData.agencyDiscount);

    rowData.payRate = this.calculatePayRate(rowData.agencyNet, rowData.mediaValue);

    rowData.net3 = this.calculateNet3(rowData.agencyNet, rowData.technicalFee);

    rowData.consecutiveDiscount = this.calculateConDiscount(rowData.net2, rowData.mediaValue);
  }

  lockColumnCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    let text = '';
    if (params.data.isParent) {
      const childRows = this.getChildRows(params.data.groupingId);
      const anyrowLocked = (childRows.filter(val => val.locked)).length > 0;
      if (anyrowLocked) {
        text = '<em class="lock_icon"></em>';
      }
    } else {
      if (params.data[params.column.colId]) {
        text = '<em class="lock_icon"></em>';
      }
    }
    eDiv.innerHTML = text;
    return eDiv;
  }

  warningColumnCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    let text = '';
    if (params.data.isParent) {
      const childRows = this.getChildRows(params.data.groupingId);
      const anyrowWarning = (childRows.filter(val => val.warning)).length > 0;
      if (anyrowWarning) {
        text = '<em class="warning_icon"></em>';
      }
    } else {
      if (params.data[params.column.colId]) {
        text = '<em class="warning_icon"></em>';
      }
    }
    eDiv.innerHTML = text;
    return eDiv;
  }

  groupingCellRenderer(params) {
    return params.data.childCount ? params.data.childCount : '';
  }

  isBrandPinCellEditable = (params) => {
    if (params.node.rowPinned === 'bottom' && params.rowIndex === 0) {
      return this.advertiserId;
    } else {
      return false;
    }
  }

  brandPinnedCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    let text = '';
    if (params.rowIndex === 0) {
      const brand = params.data[params.column.colId];
      if (brand && brand.length > 0) {
        text = brand[0].brandName;
      }
    }
    text = text || '';
    const isEditable = this.isBrandPinCellEditable(params);
    if (isEditable) {
      text = `<span class='cellLeftSpanWithellipse paddingRight15'>${text}</span>`;
    } else {
      text = `<span class='cellLeftSpanWithellipse'>${text}</span>`;
    }
    if (this.isBrandPinCellEditable(params)) {
      text += '<span class="cellRightSpanWithellipse"><em class="fa fa-pencil"></em></span>';
    }
    eDiv.innerHTML = text;
    return eDiv;
  }

  getCheckBoxCellRenderParam = (isPinnedRow) => {
    if (isPinnedRow) {
      return {
        cellEditable: () => {
          return true;
        }
      };
    } else {
      return {
        cellEditable: (data) => {
          if (data.isParent) {
            return false;
          } else {
            return (this.isCellEditable(data));
          }
        },
        onSelectChange: (params) => {
          this.onCellEditingStopped(params);
        }
      };
    }
  }

  calcAvgExtraCharge(rows) {
    let returnVal = 0;
    const valueObj = { value1: 0, value2: 0 };
    rows.forEach((row) => {
      valueObj.value1 += parseFloat(row.grossValue) * (1 + parseFloat(row.extraCharge));
      valueObj.value2 += parseFloat(row.grossValue);
    });
    if (valueObj.value2) {
      returnVal = (valueObj.value2 !== 0) ? (valueObj.value1 / valueObj.value2 - 1) : 0;
    }
    return returnVal.toFixed(2);
  }

  calcDiscount1(rows) {
    const valueObj = { value: 0, value1: 0, value2: 0, editable: true };
    rows.forEach((row) => {
      valueObj.value1 += parseFloat(row.net1);
      valueObj.value2 += parseFloat(row.mediaValue);
    });
    if (valueObj.value2) {
      const divide = (valueObj.value2 !== 0) ? (100 * (1 - (valueObj.value1 / valueObj.value2))) : 0;
      valueObj.value = divide;
    } else {
      valueObj.value = 0;
    }
    return valueObj.value.toFixed(2);
  }

  calcDiscount2(rows) {
    const valueObj = { value: 0, value1: 0, value2: 0, editable: true };
    rows.forEach((row) => {
      valueObj.value1 += parseFloat(row.net1);
      valueObj.value2 += parseFloat(row.net2);
    });
    if (valueObj.value1) {
      const divide: any = (valueObj.value1 !== 0) ? (100 * (1 - (valueObj.value2 / valueObj.value1))) : 0;
      valueObj.value = divide;
    } else {
      valueObj.value = 0;
    }
    return valueObj.value.toFixed(2);
  }

  calcAgencyDiscount(rows) {
    const valueObj = { value: 0, value1: 0, value2: 0 };
    rows.forEach((row) => {
      valueObj.value1 += parseFloat(row.agencyNet);
      valueObj.value2 += parseFloat(row.net2);
    });
    if (valueObj.value2) {
      const divide = (valueObj.value2 !== 0) ? (100 * (1 - (valueObj.value1 / valueObj.value2))) : 0;
      valueObj.value = divide;
    } else {
      valueObj.value = 0;
    }
    return valueObj.value.toFixed(2);
  }

  calcPayRate(rows) {
    const valueObj = { value: 0, value1: 0, value2: 0 };
    rows.forEach((row) => {
      valueObj.value1 += parseFloat(row.agencyNet);
      valueObj.value2 += parseFloat(row.mediaValue);
    });
    if (valueObj.value2) {
      const divide = (valueObj.value2 !== 0) ? (100 * (valueObj.value1 / valueObj.value2)) : 0;
      valueObj.value = divide;
    } else {
      valueObj.value = 0;
    }
    return valueObj.value.toFixed(2);
  }

  calcAvgConsecutiveDiscount(rows) {
    const valueObj = { value: 0, value1: 0, value2: 0 };
    rows.forEach((row) => {
      valueObj.value1 += parseFloat(row.net2);
      valueObj.value2 += parseFloat(row.mediaValue);
    });
    if (valueObj.value2) {
      const divide = (valueObj.value2 !== 0) ? ((1 - (valueObj.value1 / valueObj.value2)) * 100) : 0;
      valueObj.value = divide;
    } else {
      valueObj.value = 0;
    }
    return valueObj.value.toFixed(2);
  }

  isCellEditable(data) {
    return !data.locked;
  }

  toggleColumnVisible(colId, isVisible) {
    const isVisibleLoc = !isVisible;
    this.gridOptions.columnApi.setColumnVisible(colId, isVisibleLoc);
  }

  // Data functions
  getChildRows(groupingId?: string, filterOnly = true): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  getSelectedChildRows(groupingId?: string, filterOnly = false): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  getParentRows(groupingId?: string, data?: any[]): any[] {
    const newData = data || this.rowData;
    return AgCustomRowUtil.getParentRows(newData, this.groupingField, groupingId);
  }

  sumFieldValues(rows: any[], fieldName: string): number {
    return AgCustomRowUtil.sumFieldValues(rows, fieldName);
  }

  calculateGridSummaryValues() {
    this.totalSelectedNet1 = this.getTotalOfSelectedProperty('net1');
    this.totalSelectedNet2 = this.getTotalOfSelectedProperty('net2');
    this.totalSelectedNet3 = this.getTotalOfSelectedProperty('net3');
    this.totalSelectedMediaValue = this.getTotalOfSelectedProperty('mediaValue');
    this.totalSelectedAgencyNet = this.getTotalOfSelectedProperty('agencyNet');

    const divideDiscount1 = (this.totalSelectedMediaValue !== 0) ?
      (100 * (1 - (this.totalSelectedNet1 / this.totalSelectedMediaValue))) : 0;
    this.avgSelectedDiscount1 = divideDiscount1.toFixed(2);

    const divideDiscount2 = (this.totalSelectedNet1 !== 0) ? (100 * (1 - (this.totalSelectedNet2 / this.totalSelectedNet1))) : 0;
    this.avgSelectedDiscount2 = divideDiscount2.toFixed(2);

    const divideAgencyDiscount = (this.totalSelectedNet2 !== 0) ? (100 * (1 - (this.totalSelectedAgencyNet / this.totalSelectedNet2))) : 0;
    this.avgSelectedAgencyDiscount = divideAgencyDiscount.toFixed(2);
  }

  getTotalOfSelectedProperty(propertyName: string, filterOnly = false): number {
    const rows = AgCustomRowUtil.getSelectedChildRows(this.rowData, null, null, filterOnly);
    return AgCustomRowUtil.sumFieldValues(rows, propertyName);
  }

  calculateTechFee(rowData) {
    this.statusCategory.forEach((element) => {
      if (element.idStatusCategory === parseInt(rowData.statusCategoryId, 10)) {
        if (element.calcTechFee && (parseInt(rowData.discount1, 10) === 100)) {
          rowData.technicalFeeCalc = LocaleData.replaceLocalDecimalCharWithDot(rowData.technicalFeeCalc);
          rowData.technicalFee = rowData.technicalFeeCalc.toFixed(2);
        } else {
          rowData.technicalFee = 0;
        }
      }
    }, this);
  }

  setDefaultDiscount(row) {
    row.discount1 = this.indexedStatusCategory[row.statusCategoryId].defaultDiscountAmount;
  }

  calculateCellValues(rowData) {

    rowData.mediaValue = this.calculateMediaValue(rowData.grossValue, rowData.extraCharge, rowData.smartNet);

    rowData.net1 = this.calculateNet1(rowData.mediaValue, rowData.discount1);

    rowData.net2 = this.calculateNet2(rowData.net1, rowData.discount2);

    rowData.agencyNet = this.calculateAgencyNet(rowData.net2, rowData.agencyDiscount);

    rowData.payRate = this.calculatePayRate(rowData.agencyNet, rowData.mediaValue);

    rowData.net3 = this.calculateNet3(rowData.agencyNet, rowData.technicalFee);

    rowData.consecutiveDiscount = this.calculateConDiscount(rowData.net2, rowData.mediaValue);
    rowData.agencyDiscount = this.calculateAgencyDiscount(rowData.net2, rowData.agencyNet);
  }

  calculateMediaValue(grossVal, extraChargeVal, smartNet) {
    const grossValue = parseFloat(grossVal);
    const extraCharge = parseFloat(extraChargeVal);
    let finalMediaValue = grossValue + (grossValue * (extraCharge / 100));
    if (smartNet) {
      finalMediaValue = finalMediaValue + (grossValue * (15 / 100));
    }
    return finalMediaValue.toFixed(2);
  }

  calculateNet1(mediaVal, discount1Val) {
    const discount1 = parseFloat(discount1Val);
    const mediaValue = parseFloat(mediaVal);
    const finalNet1 = mediaValue - (mediaValue * (discount1 / 100));
    return finalNet1.toFixed(2);
  }

  calculateNet2(net1Val, discount2Val) {
    const discount2 = parseFloat(discount2Val);
    const net1 = parseFloat(net1Val);
    const finalNet2 = net1 - (net1 * (discount2 / 100));
    return finalNet2.toFixed(2);
  }

  calculateAgencyNet(net2Val, agencyDiscountVal: any) {
    const agencyDiscount = parseFloat(agencyDiscountVal);
    const net2 = parseFloat(net2Val);
    const agencyNet = net2 - (net2 * (agencyDiscount / 100));
    return agencyNet.toFixed(2);
  }

  calculatePayRate(agencyNetVal, mediaVal) {
    const mediaValue = parseFloat(mediaVal);
    const agencyNet = parseFloat(agencyNetVal);
    const payRate = (mediaValue !== 0) ? (100 * (agencyNet / mediaValue)) : 0;
    return payRate.toFixed(2);
  }

  calculateNet3(agencyNet, technicalFee) {
    let net3: any = parseFloat(agencyNet) + LocaleData.replaceLocalDecimalCharWithDot(technicalFee);
    net3 = net3.toFixed(2);

    return net3;
  }

  calculateConDiscount(net2Val, mediaVal) {
    const mediaValue = parseFloat(mediaVal);
    const net2 = parseFloat(net2Val);
    const conDiscount = (mediaValue !== 0) ? ((1 - (net2 / mediaValue)) * 100) : 0;
    return conDiscount.toFixed(2);
  }

  calculateAgencyDiscount(net2Val, agencyNetVal) {
    const agencyNet = parseFloat(agencyNetVal);
    const net2 = parseFloat(net2Val);
    const agencyDiscount = (net2 !== 0) ? ((1 - (agencyNet / net2)) * 100) : 0;
    return agencyDiscount.toFixed(2);
  }

  cellEditable(data) {
    return !data.isParent && !data.locked;
  }

  trackByField(index, column) {
    return column?.colDef?.field;
  }

}
