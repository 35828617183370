import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../../../../../environments/environment';
import { AppHeaderService } from '../../../../../../../../root/app-header/app-header.service';
import { CellRendererBaseComponent } from '../cell-renderer-base/cell-renderer-base.component';

@Component({
  selector: 'app-ag-link-cell-renderer',
  templateUrl: './ag-link-cell-renderer.component.html'
})
export class AgLinkCellRendererComponent extends CellRendererBaseComponent {
  url = '';
  private subscriptions: Subscription[] = [];
  private selectedEnvironment = '';
  constructor(
    public appHeaderService: AppHeaderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(this.appHeaderService.selectedEnvironment$.subscribe((newEnv) => {
      this.selectedEnvironment = newEnv;
    }));
    if (this.params && this.params.queryString) { // Query String Example : {'id':'campaignId'} , where id will key in query string and campaignId is column available in params.data
      const keys = Object.keys(this.params.queryString);
      this.url = '';
      keys.forEach((k) => {
        if (this.params.data[this.params.queryString[k]]) {
          this.url += `&${k}=${this.params.data[this.params.queryString[k]]}`;
        }
      });
      this.url = `${this.url}&market=${this.selectedEnvironment}&action=workspace`;

      if (this.url.length > 0) {
        this.url = this.url.substring(1);
        this.url = `${environment.landingAppUrl}?${this.url}`;
      }
    }
  }

  public getChildRowText(): string {
    return this.params.data[this.params.column.colId];
  }

  public getFirstPinRowText(): string {
    return this.params.data[this.params.column.colId];
  }

  public getSecondPinRowText(): string {
    return this.params.data[this.params.column.colId];
  }
}
