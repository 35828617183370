<div class="sidebar-left_geo sidebar-right_geo cart_summarybar"
  [ngStyle]="{'right': openSidebar ? '0px' : '-'+(bookingDetailsWidth) +'px', 'width': bookingDetailsWidth +'px'}">
  <div class="sidebar-wrapper_geo">
    <button class="filter-link_geo">
      <span class="frame-title">{{initialConfig.userBundle['discount.us.frame']}} : {{selectedFrame}}</span>
      <span style="padding-left: 5px" (click)="exportToExcel()">
        <em class="fa fa-download"></em>
      </span>

      <span style="padding-left: 5px" class="pull-right" (click)="toggleSideBar()">
        <em class="fa fa-close"></em>
      </span>

    </button>
    <div class="cart_search_container">
      <input type="text" [(ngModel)]="bookingFilterText" (ngModelChange)="searchForCampaign($event)"
        id="bookingFilterText" name="bookingFilterText" class="form-control"
        placeholder="{{initialConfig.userBundle['geoplanner.text.searchCampaign'] || 'Search Campaign...'}}">
    </div>
    <div class="cart_grid_container" id="dvbookingcnt">
      <div class="list" *ngFor="let item of bookingDetails; trackBy: trackByItem;">
        <div class="media-title">
          <div class="icon">
            <img [src]="'assets/img/status/' + item.statusImage + '.png'" alt="statusImage">
          </div>
          <div class="media-body">
            <h4 class="mt-0">{{item.campaignStatus}}
              <span *ngIf="isVP"> (TOTAL:<span class="total-campaign">{{item.data.length}}</span>)</span>
            </h4>
          </div>
        </div>
        <hr>
        <div class="list-item" *ngFor="let campaign of item.data; trackBy: trackByItem;">
          <div class="media-data">
            <!-- <div class="mr-2 font-weight-bold" *ngIf="campaign.sot!=undefined">{{campaign.sot}}</div> -->
            <div class="media-body">
              <div class="mt-0">
                <span class="lable-campaign-reference"
                  *ngIf="!isVP && campaign.campaignReference!=undefined">{{campaign.campaignReference}}</span>
                <button class="btn-campaign-reference" *ngIf="isVP && campaign.campaignReference!=undefined"
                  (click)="onSelectCampaign(campaign.campaignId)">
                  {{campaign.campaignReference}}
                </button>
                <div *ngIf="campaign.campaignTitle!=undefined">
                  <small class="text-secondary">
                    {{initialConfig.userBundle['common.campaignTitle']}}:</small>
                  {{campaign.campaignTitle}}
                </div>
                <div *ngIf="campaign.advertiserName!=undefined">
                  <small class="text-secondary">
                    {{initialConfig.userBundle['common.advertiserName']}}:</small>
                  {{campaign.advertiserName}}
                </div>
                <div *ngIf="campaign.brandName!=undefined">
                  <small class="text-secondary">
                    {{initialConfig.userBundle['common.brandName']}}:</small>
                  {{campaign.brandName}}
                </div>
                <small class="text-secondary">({{formatDate(campaign.startDate)}}
                  {{initialConfig.userBundle['workspace.rating.bric.to']}}
                  {{formatDate(campaign.endDate)}})</small>
                <div *ngIf="campaign.agencyName!=undefined">
                  <small class="text-secondary">
                    {{initialConfig.userBundle['common.agencyName']}}:</small>
                  {{campaign.agencyName}}
                </div>
                <div *ngIf="campaign.productCategoryName!=undefined">
                  <small class="text-secondary">{{initialConfig.userBundle['common.productCategoryName.us']}}:</small>
                  {{campaign.productCategoryName}}
                </div>
                <div *ngIf="campaign.salesPersonName!=undefined">
                  <small class="text-secondary">{{initialConfig.userBundle['common.salesPersonName.us']}}:</small>
                  {{campaign.salesPersonName}}
                </div>
                <div *ngIf="campaign.adminPersonName!=undefined">
                  <small class="text-secondary">{{initialConfig.userBundle['common.adminPersonName']}}:</small>
                  {{campaign.adminPersonName}}
                </div>
                <div *ngIf="isVP && campaign.sot!=undefined">
                  <small class="text-secondary">{{initialConfig.userBundle['common.bookingDetails.bookedSot']}}:</small>
                  {{campaign.sot}}
                </div>
                <div *ngIf="isVP && campaign.impressions!=undefined">
                  <small class="text-secondary">
                    {{initialConfig.userBundle['common.bookingDetails.bookedImpression']}}:</small>
                  {{campaign.impressions}}
                </div>
                <div *ngIf="campaign.price!=undefined">
                  <small class="text-secondary">
                    {{initialConfig.userBundle['workspace.budget.bric.price.price']}}:</small>
                  {{campaign.price}}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
