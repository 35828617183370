<header class="sub-header_geo">
    <nav class="sub-navbar_geo">
        <button class="filter-link_geo">{{filterText}} <span class="pull-right"  (click)="toggleFilter()"><em class="fa fa-bars"></em></span></button>
        <ul class="nav_geo ">
            <li><a><em class="fa fa-calendar"></em>{{cartSummary.inchargeDate}}</a></li>
            <li><a><em class="fa fa-users"></em>{{cartSummary.audience}}</a></li>
            <li><a><em class="fa currency">{{initialConfig['systemData']['currencySymbol']}}</em>{{cartSummary.price}}</a></li>
            <li><a><em class="fa fa-clone"></em>{{cartSummary.frames}}</a></li>
            <li><a><em class="icon"><img src="assets/img/bulb.png" alt=""></em>{{cartSummary.rating}}</a></li>
        </ul>
    </nav>
</header>