import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { RootModule } from './root/root.module';

/* Files from Vendor.ts */
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-fresh.css';
// import '../node_modules/angular-tree-component/dist/angular-tree-component.css';
// import '../node_modules/angular2-toaster/toaster.css';

import './geoplanner/src/app/core/utils/date.extensions';
import '@angular/common/locales/de';
import '@angular/common/locales/en-GB';
import '@angular/common/locales/en-AU';
import '@angular/common/locales/nl-BE';
import '@angular/common/locales/it';
import '@angular/common/locales/zh-Hant-HK';
import '@angular/common/locales/en-SG';
import '@angular/common/locales/nl';
import '@angular/common/locales/zh-Hans';
import '@angular/common/locales/ar-AE';
import { environment } from 'environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(RootModule)
  .catch(err => console.error(err));
