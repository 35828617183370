import { DataShareService } from '../../core/services/data-share.service';
import { AudienceBricService } from '../../core/services/audience-bric.service';
import { AudienceCategoryGroup, AudienceCategory, AudienceCategoryType } from './../../models/audience-filter.model';
import * as _ from 'lodash';
import { AudienceEnum } from './audience-enum';

export class AudienceBase {
  /**
   * display value in auto complate dropdown
   *
   * @memberof AudienceBase
   */
  public valueText = [];

  /* store current audience tag from inital config
  *
  * @type {*}
  * @memberof AudienceBase
  */
  public audienceTags: any;
  /**
   * store category for audience from inital config
   * @type {*}
   * @memberof AudienceBase
   */
  public audienceCategoryGroup: AudienceCategoryGroup;
  /**
   * It defines ID for audienceCategoryGroup.
   */
  public audienceCategoryGroupId = 2;
  /**
   * It defines category type for audience.
   */
  public audienceCategoryType: AudienceCategoryType[];

  /**
   * @description source for display in workspace page for routing bricks
   * @memberof AudienceBase
   */
  public imageSources = new AudienceEnum().getAudienceEnum();

  /**
   * @description source for modal popup window in routing bricks
   * @memberof AudienceBase
   */
  public imageHtmlSources = {
    MIXGENDER: 'assets/img/male_female.png',
    MALES: 'assets/img/male.png',
    FEMALES: 'assets/img/female.png',
    SHOPPER: 'assets/img/children.png',
    UNIVERSE: 'assets/img/universe.png',
    A: 'assets/img/a.png',
    AB: 'assets/img/ab.png',
    ABC1: 'assets/img/abc1.png'
  };

  /**
   * It defines options for audience.
   */
  public options: any;

  /**
   * contans selected value from route or there audiance bricks
   *
   * @memberof AudienceBase
   */
  selectedRouteValues = {
    src: '',
    selecteAudience: null
  };

  /**
   * maintain selected value from autocomplate dropdown
   *
   * @memberof AudienceBase
   */
  item: AudienceCategory[] = [];
  /**
   * store single value from audiance grid
   *
   * @type {*}
   * @memberof AudienceBase
   */
  selectedAudience: AudienceCategory = new AudienceCategory();
  previousValues:AudienceCategory;
  /**
   * store selected index from audiance grid
   *
   * @memberof AudienceBase
   */
  selectedIndex;

  /**
   * @description for maintain selected Index
   * @memberof AudienceBase
   */
  selectedIndexTagInput;

  userBundle: any;

  constructor(
    private dataService: DataShareService,
    private audienceBricService: AudienceBricService
  ) { }

  /**
   * @description getting audience Category group and option
   * @author Kishan Patel
   * @param {*} audienceCategoryGroupId
   * @memberof AudienceBase
   */
  init(audienceCategoryGroupId) {
    this.userBundle = this.dataService.getInitialConfigByKey('userBundle');
    let audienceCategoryGroup = _.cloneDeep(this.dataService.getInitialConfigByKey('audienceCategoryGroup'));
    const audienceTags = _.cloneDeep(this.dataService.getInitialConfigByKey('audienceTags'));
    audienceCategoryGroup = this.audienceBricService.populateDataForAudienceBric(
      audienceCategoryGroupId,
      audienceCategoryGroup, audienceTags);

    this.audienceCategoryGroup = audienceCategoryGroup;
    this.audienceCategoryType = _.cloneDeep(audienceCategoryGroup.audienceCategoryType);
    this.options = _.cloneDeep(audienceCategoryGroup.options);
  }

  /**
   * @description maintain selected audience from routing brics
   * @author Kishan Patel
   * @param {*} selecteAudience
   * @param {*} categoryType
   * @memberof AudienceBase
   */
  selectAudience(selecteAudience: any, categoryType: any) {
    categoryType.src = this.imageSources[categoryType.audienceCategoryTypeCode];
    this.selectedAudience = selecteAudience;
    this.selectedAudience.audienceCategoryTypeCode = categoryType.audienceCategoryTypeCode;
    this.selectedAudience.src = categoryType.src;
  }

  /**
   * @description set css for routing block
   * @author Kishan Patel
   * @returns
   * @memberof AudienceBase
   */
  setStyle() {
    if (this.audienceCategoryType && this.audienceCategoryType.length > 0) {
      const tempWidth = `${100 / this.audienceCategoryType.length}%`;
      return { width: tempWidth };
    }
  }

  /**
   * @description maintain selected audiance fromtag input for secondary audience
   * @author Kishan Patel
   * @param {*} index
   * @memberof AudienceBase
   */
  audianceRadioclick(index, event) {
    if (event && this.selectedIndex !== index) {
      event.stopPropagation();
      this.selectedIndex = index;
    }
  }

  /**
   * @description maintain selection category audience,
   * @author Kishan Patel
   * @param {*} index
   * @param {*} category
   * @memberof AudienceBase
   */
  categoryRow(index, category: AudienceCategory, categoryType: any) {
    if (index !== 0 && this.selectedIndex !== index) {
      this.selectedIndex = index;
      this.selectedAudience = category;
      this.selectedAudience.audienceCategoryTypeCode = categoryType.audienceCategoryTypeCode;
    }

  }

  /**
   * @description  set selection for category  redio button
   * @author Kishan Patel
   * @param {*} index
   * @memberof AudienceBase
   */
  webCategorySelection(index) {
    this.valueText = [];
    this.selectedIndex = null;
    this.selectedAudience = new AudienceCategory();
    if (this.item[index] != null && typeof this.item[index] === 'object') {
      this.selectedAudience = this.item[index];
      this.valueText[index] = this.selectedAudience.description.toString();
    }
  }

  /**
   * @description select audience from tag input dropdown
   * @memberof AudienceBase
   */
  onItemAdded(event: { item: AudienceCategory }, i, selectedKey) {
    this.valueText = [];
    this.selectedAudience = event.item;
    this.valueText[i] = this.selectedAudience[selectedKey].toString();
    this.item[i] = this.selectedAudience;
    this.selectedIndexTagInput = i;
    this.selectedIndex = null;
  }
}
