import { Injectable } from '@angular/core';

@Injectable()
export class AudienceBricService {

  /**
   * To generate data for Audience filter.
   * @param audienceCategoryGroupId Category group ID.
   * @param audienceCategoryGroup Category group data.
   * @param audienceTags Tags data.
   * @returns Modified data for Audience filter.
   */
  populateDataForAudienceBric(audienceCategoryGroupId, audienceCategoryGroup, audienceTags) {
    if (parseInt(audienceCategoryGroupId, 10) >= 0) {
      for (const acg of audienceCategoryGroup) {
        if (acg.audienceCategoryGroupId === audienceCategoryGroupId) {
          if (parseInt(acg.options.DisplayFormat, 10) === 1) {
            return acg;
          } else if (parseInt(acg.options.DisplayFormat, 10) === 2) {
            return this.populateDataForAudienceBricFormat2(acg, audienceTags);
          } else if (parseInt(acg.options.DisplayFormat, 10) === 3) {
            return this.populateDataForAudienceBricFormat3(acg, audienceTags);
          }
          break;
        }
      }
    }
    return new Object();
  }

  /**
   * Alkesh Shah
   * THis is a temporary fix for geoMapper
   * Created this for GeoMapper
   * In GeoMapper Getting issue in US data as it gives 1 as id and in UK it is 2
   * audienceCategoryGroupCode is common so temporary getting audience data based on audienceCategoryGroupCode
   */
  /**
   * To generate data for Audience filter.
   * @param audienceCategoryGroupId Category group ID.
   * @param audienceCategoryGroup Category group data.
   * @param audienceTags Tags data.
   * @returns Modified data for Audience filter.
   */
  populateDataForAudienceBricByCode(audienceCategoryGroupCode, audienceCategoryGroup, audienceTags) {
    if (audienceCategoryGroupCode) {
      for (const categoryGroup of audienceCategoryGroup) {
        if (categoryGroup.audienceCategoryGroupCode === audienceCategoryGroupCode) {
          if (parseInt(categoryGroup.options.DisplayFormat, 10) === 1) {
            return categoryGroup;
          } else if (parseInt(categoryGroup.options.DisplayFormat, 10) === 2) {
            return this.populateDataForAudienceBricFormat2(categoryGroup, audienceTags);
          }
          break;
        }
      }
    }
    return new Object();
  }

  /**
   * To generate data for Audience filter in different format.
   * @param audienceCategoryGroup Category group data.
   * @param audienceTags Tags data.
   * @return It will returns audience category group data.
   */
  populateDataForAudienceBricFormat2(audienceCategoryGroup, audienceTags) {

    const firstObj = this.getFirstObj(audienceCategoryGroup);

    for (const type of audienceCategoryGroup.audienceCategoryType) {
      for (const category of type.audienceCategory) {
        if (audienceCategoryGroup.options.PrimaryAudienceType
          && type.audienceCategoryTypeCode === audienceCategoryGroup.options.PrimaryAudienceType) {
          this.mainAudienceCategory(audienceCategoryGroup, audienceTags, type.audienceCategory);
        }
      }
    }

    for (const type of audienceCategoryGroup.audienceCategoryType) {
      if (audienceCategoryGroup.options.PrimaryAudienceType
        && type.audienceCategoryTypeCode === audienceCategoryGroup.options.PrimaryAudienceType) {
        type.audienceCategory.splice(0, 0, firstObj);
      }
    }

    return audienceCategoryGroup;
  }

  getFirstObj(audienceCategoryGroup) {
    const firstObj: any = {
      columns: []
    };
    for (const key in audienceCategoryGroup.options) {
      if (key.indexOf('Tag') >= 0 && key.indexOf('Name') >= 0) {
        const index = parseInt(key.replace('Tag', '').replace('Name', ''), 10);
        firstObj.columns[index - 1] = audienceCategoryGroup.options[key];
      }
    }
    return firstObj;
  }

  audienceTagsCategoryObj(categoryObj, audienceTags) {
    for (const key in categoryObj) {
      if (key.indexOf('tag') >= 0) {
        const index = parseInt(key.replace('tag', ''), 10) - 1;
        categoryObj.columns[index] = audienceTags[categoryObj[key]];
      }
    }
  }

  formatCategoryObj(audienceCategoryGroup, categoryObj) {
    for (let k = 0; k < JSON.parse(audienceCategoryGroup.options.Tags).length; k++) {
      if (!categoryObj.columns[k]) {
        categoryObj.columns[k] = null;
      }
    }
  }

  populateDataForAudienceBricFormat3(audienceCategoryGroup, audienceTags) {

    for (const audienceCategoryType of audienceCategoryGroup.audienceCategoryType) {
      for (const category of audienceCategoryType.audienceCategory) {
        for (const audienceCategory of category.audienceCategory) {
          if (audienceCategoryGroup.options.PrimaryAudienceType
            && audienceCategoryType.audienceCategoryTypeCode === audienceCategoryGroup.options.PrimaryAudienceType) {
            this.mainAudienceCategory(audienceCategoryGroup, audienceTags, category.audienceCategory);
          }
        }
      }
    }

    return audienceCategoryGroup;
  }

  mainAudienceCategory(audienceCategoryGroup, audienceTags, data) {
    data.forEach((categoryObj) => {
      categoryObj.columns = [];
      this.audienceTagsCategoryObj(categoryObj, audienceTags);

      this.formatCategoryObj(audienceCategoryGroup, categoryObj);
    });
  }

}
