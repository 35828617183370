import { Component, OnInit, Input } from '@angular/core';
import { DataShareService } from '../../../app/core/services/data-share.service';
import { GLOBAL } from '../../core/utils/app.constant';

@Component({
  selector: 'app-audit-modal',
  templateUrl: './audit-modal.component.html',
  styleUrls: ['./audit-modal.component.css']
})

export class AuditModalComponent implements OnInit {

  @Input() resolveObject: any;

  campaignSave = {
    campaignName: ''
  };
  brickBgColor: string;
  userBundle: object;
  selectedTitle = '';
  auditRecords = [];

  constructor(private dataShareService: DataShareService) { }

  ngOnInit() {
    this.brickBgColor = this.resolveObject.color || GLOBAL.DEFAULT_TITLE_BACKGROUND_COLOR;
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.selectedTitle = this.resolveObject.SelectedTitle;
    this.auditRecords = this.resolveObject.AuditRecords;
  }

  onModalSaved(event) {
    event.activeModal.close();
  }

  onModalClosed(event) {
    event.activeModal.dismiss();
  }

  trackByAuditType(index, item) {
    return item?.auditType;
  }

}
