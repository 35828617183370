import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LogHelperService } from '../../services/log-helper.service';
import { DataShareService } from '../../services/data-share.service';
import { AppNameEnum } from '../../enum';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { getFormData } from '../../utils/formData';
import { GLOBAL } from '../../utils/app.constant';
import { _throw } from 'rxjs/observable/throw';

@Injectable()
export class BookingDetailsService {

  /**
   * @description Request Headers
   * @private
   * @memberof BookingDetailsService
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

  /**
   * Creates an instance of BookingDetailsService.
   * @author Amit Mahida
   * @param {HttpClient} http
   * @memberof BookingDetailsService
   */
  constructor(
    private http: HttpClient,
    private logHelperService: LogHelperService,
    private dataShareService: DataShareService
  ) { }

  /**
   * @description To get request options
   * @author Amit Mahida
   * @param {object} params
   * @returns
   * @memberof BookingDetailsService
   */
  getOptions(params: object) {
    return {
      body: getFormData(params),
      headers: this.headers
    };
  }

  /**
   * @description Fetch campaign details from server
   * @author Amit Mahida
   * @param {any} reqParams
   * @returns
   * @memberof BookingDetailsService
   */
  getCampaignDetails(reqParams: any) {
    reqParams.action = 'getFrameBookings';
    let reqUrl = this.dataShareService.getServiceCallUrlByKey('GET_FRAME_BOOKINGS');
    if (this.dataShareService.appName === AppNameEnum.visualplanner) {
      reqUrl = this.dataShareService.getServiceCallUrlByKey('VP_GET_FRAME_BOOKINGS');
    }

    return this.http.request(
      GLOBAL.HTTP_METHOD,
      reqUrl,
      this.getOptions(reqParams)
    )
      .map((res: Response) => {
        return res;
      })
      .catch((error: Response | any) => {
        this.logHelperService.logError(error.message || error);
        return _throw(error.message || error);
      });
  }

}
