import { Component, OnInit, ViewChild } from '@angular/core';
import { HistoryROC } from '../commercial.model';
import { SystemFlags } from '../../models';
import { DataShareService, CommercialService, LogHelperService, StateService } from '../../core/services';

import { CampaignDetailsService } from '../campaign-details/campaign-details.service';
import { DatePipe } from '@angular/common';
import { CommercialButtonService } from '../commercial-buttons/commercial-buttons.service';
import { Subscription } from 'rxjs';
import { GLOBAL } from '../../core/utils/app.constant';
import { NumberMaskModel } from '../../models/imask';
import { RequiredCampaignFields, RequiredBookingsFields, PopulateCommercialFields, PopulateBookingDetailFields } from '../../models/RequiredFields';
import { CommercialBase } from '../commercial-base';
import { BookingGridUkComponent } from '../discount-grid/booking-grid-uk/booking-grid-uk.component';
import { CommercialButtonsComponent } from '../commercial-buttons/commercial-buttons.component';
import { DiscountGridBelgiumComponent } from '../discount-grid/discount-grid-belgium/discount-grid-belgium.component';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { CampaignStatus } from '../../core/enum';

@Component({
  selector: 'app-commercial-be',
  templateUrl: './commercial-be.component.html',
  styleUrls: ['./commercial-be.component.css']
})
export class CommercialBeComponent  extends CommercialBase implements OnInit {
  systemFlags = SystemFlags;
  percentageInputMask: NumberMaskModel;
  @ViewChild(BookingGridUkComponent) bookingGridUkComponent: BookingGridUkComponent;
  @ViewChild(CommercialButtonsComponent) commercialButtonsComponent: CommercialButtonsComponent;
  @ViewChild(DiscountGridBelgiumComponent) discountGridBelgiumComponent: DiscountGridBelgiumComponent;
  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof CampaignDetailsGermanyComponent
   */
  currencyInputMask: NumberMaskModel;
  /**
   * @description set bookingStatusList
   * @memberof CommercialUkComponent
   */
  bookingStatusList = GLOBAL.bookingStatusList;
  // @Output() redirectionHomePage = new EventEmitter<any>();

  /**
   * @description maintain discount grid show hide
   * @type {boolean}
   * @memberof CommercialAEComponent
   */
  hideDiscountGrid: boolean;
  /**
   * @description maintain campaign detail show hide
   * @type {boolean}
   * @memberof CommercialAEComponent
   */
  hideCampaignDetail: boolean;

  /**
   * @description store booking detail show hide
   * @type {boolean}
   * @memberof CommercialAEComponent
   */
  hideBookingDetail: boolean;

  /**
   * @description booking grid show hide
   * @type {boolean}
   * @memberof CommercialBeComponent
   */
  hideBookingGrid: boolean;

  /**
   * @description Booking line change subscription
   * @type {Subscription}
   * @memberof CommercialBeComponent
   */
  bookingLineDataSubscription: Subscription;

  /**
   * @description store commercial data
   * @memberof CommercialBeComponent
   */
  public commercialData;

  /**
   * @description this will show the history of campaign updated in case of reason/cancel for change
   * @memberof CommercialBeComponent
   */
  historyROC = new HistoryROC();

  userData: any;

  constructor(
    datePipe: DatePipe,
    campaignDetailsService: CampaignDetailsService,
    dataShareService: DataShareService,
    commercialService: CommercialService,
    logHelperService: LogHelperService,
    commercialButtonService: CommercialButtonService,
    stateService: StateService,
    private appHeaderService : AppHeaderService
  ) {
    super(stateService, logHelperService, commercialButtonService, dataShareService, commercialService, datePipe, campaignDetailsService);
  }

  ngOnInit() {
    this.commercialConfig.user = this.dataShareService.getUserModel();
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.setDefaultAgencySpecialistPerc();
    this.getCommercialDataFromService();
    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe((value: any) => {
      if (value.rowIndex >= 0 && value.columnName) {
        this.calculateCPT(value);
      }
      this.onBookingLineChange();
    });

    this.userData = this.dataShareService.getInitialConfigByKey('userData');
  }

  /**
   * @description get commercial data
   * @author Darshan Vachhani
   * @memberof CommercialBeComponent
   */
  getCommercialDataFromService(isManual = false) {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
    };

    this.commercialService.loadCommercialCampaignDetails(param).subscribe((data) => {
      if (data.status === 'OK') {
        this.commercialConfig.campaignStatus = data.data.campaignStatusId;
        this.commercialConfig.bookingStatus = data.data.bookingStatus;
        this.commercialConfig.uiControl = data.data.uiControl;
        this.commercialData = data.data;
        if (isManual) {
          this.dataShareService.updateBookingList(this.commercialData.bookingList);
        }

        this.commercialData.bookingList.forEach((element : any) => {
          delete element.adminPersonId;
          delete element.salesPersonId;
          delete element.adminPersonName;
          delete element.salesPersonName;
        });

        this.commercialData.bookingStatusName =
          this.campaignDetailsService.getStatusNameFromId(data.data.campaignStatusId);
        const assignedToList = this.dataShareService.getInitialConfigByKey('assignedTo');
        this.campaignDetails = this.campaignDetailsService.populateCampaignDetails(this.commercialData,
          this.initialConfig, assignedToList);
        if (isManual) {
          this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT);
        } else {
          this.campaignDetailsService.calculateCPT(this.campaignDetails);
        }
        this.historyROC = this.campaignDetailsService.populateHistoryROC(this.historyROC, data.data.auditReasonsHistoryMap, this.commercialConfig.uiControl.historyROC);

        // SM-9101, Message to split has the highest priority than floating message
        if (this.commercialData.floatingEndDate && this.commercialData.floatingStartDate && ((SystemFlags.splitable && SystemFlags.splitted) || (!SystemFlags.splitable))) {
          this.appHeaderService.changeLockedMessage(this.initialConfig.userBundle['commercial.validation.seasonalityWarning'] || 'Your campaign has floating dates, Please verify your seasonality indices values');
        }
      }
    });
  }

  discountGridChanged() {
    this.checkForSeasonalityMismatch();
  }

  /**
   * SM-8624
   * This method is used to show alert when user navigates to commercial or modify seasonality index in discount grid and there is a mismatch in value
   * @Author Vijay Sutaria
   * @returns true or false
   */
  checkForSeasonalityMismatch(): boolean {
    if (this.campaignDetails.discountsV2 && this.campaignDetails.campaignStatusId === CampaignStatus.proposal) {
      let showToaster = false;
      this.campaignDetails.discountsV2.forEach((d : any) => {
        if ((d.seasonnalityIndex !== d.workspaceSeasonalityIndex) || (d.technicalFee !== d.flTaxes)) {
          showToaster = true;
          return true;
        }
      }
      );
      if (showToaster) {
        this.logHelperService.logError(this.initialConfig.userBundle['commercial.validation.seasonalityMismatch'] || 'Your seasonality indexes &/or taxes are out of sync with the workspace, please verify them');
      }
    }

    return true;
  }

  /**
   * @description create request json
   * @memberof CommercialBeComponent
   */
  createRequestJSONForCommercial = (status) => {
    const campaignDetails = this.campaignDetails;
    const populate: PopulateCommercialFields = this.getCommercialFields();
    const temp = this.populateBookCommercialCampaignParam(status, campaignDetails, populate);
    const populateBooking: PopulateBookingDetailFields = this.getBookingDetailFields();
    temp.bookingList = super.populateBookingDetailsServiceObject(campaignDetails, 'bookingList', populateBooking);
    const lockedDiscounts = this.stateService.getCommercialObject('lockedDiscounts');
    for (const key in lockedDiscounts) {
      if (lockedDiscounts.hasOwnProperty(key)) {
        const curRow = lockedDiscounts[key];
        if (campaignDetails.discountsV2 && campaignDetails.discountsV2.length) {
          campaignDetails.discountsV2[key] = { ...campaignDetails.discountsV2[key], ...curRow };
        } else {
          campaignDetails.discounts[key] = { ...campaignDetails.discounts[key], ...curRow };
        }
      }
    }
    if (campaignDetails.discountsV2 && campaignDetails.discountsV2.length) {
      temp['discountsV2'] = campaignDetails.discountsV2;
    } else {
      temp['discounts'] = campaignDetails.discounts;
    }
    return temp;
  }

  /**
   * To override the commercial fields
   * This override fields are used to override the default value
   * @memberof CommercialBeComponent
   * @author Dhaval Patel
   */
  getCommercialFields() {
    const populate: PopulateCommercialFields = super.getCommercialFields();
    populate.setPCFields();
    populate.campaignReferences = true;
    populate.mediaLength = true;
    populate.sendProposal = true;
    populate.product = true;
    populate.buyerContactId = true;
    populate.grossMediaValue = false;
    populate.subAgency = false;
    populate.netMediaValue = false;
    populate.financeAmount = true;
    populate.floatingStartDate = true;
    populate.floatingEndDate = true;
    populate.readyForPosting = true;
    populate.salesPerson = true;
    populate.adminPerson = true;
    const userData = this.dataShareService.getInitialConfigByKey('userData');
    if (userData.tokens.indexOf('EDIT_LOCKED_STATUS') > -1) {
      populate.invoiceLocked = true;
    }
    return populate;
  }

  /**
   * To override the booking detail fields
   * This override fields are used to override the default value
   * @memberof CommercialBeComponent
   * @author Dhaval Patel
   */
  getBookingDetailFields() {
    const populate: PopulateBookingDetailFields = super.getBookingDetailFields();
    populate.setPICFields();
    populate.setCCBIBFields();
    populate.setPOORIFields();
    populate.financeAmount = true;
    populate.buyerContactId = false;
    populate.mediaLength = false;
    return populate;
  }

  /**
   * @description validate campaign commercial
   * @memberof CommercialBeComponent
   */
  validateCommercialCampaign = (status) => {
    const requiredFields: RequiredCampaignFields = new RequiredCampaignFields();
    requiredFields.salesPerson = true;
    requiredFields.adminPerson = true;
    requiredFields.externalReference = (this.userData && this.userData['externalUser']) ? false : true;

    let message = '';
    if (this.campaignDetails && this.campaignDetails.discountsV2 && this.campaignDetails.discountsV2.length) {
      if (this.campaignDetails.discountsV2.filter(d => d.statusCategoryId === 2 || d.statusCategoryId === '2').length > 0) {
        requiredFields.floatingEndDate = true;
        requiredFields.floatingStartDate = true;
      } else if ((this.campaignDetails.floatingStartDate || this.campaignDetails.floatingEndDate) && this.campaignDetails.discountsV2.filter(d => d.statusCategoryId === 2 || d.statusCategoryId === '2').length === 0) {
        message = this.initialConfig.userBundle['commercial.validation.floatingType'] || 'Please specify which discount grid lines have Type of Period =  Floating';
      }
    }

    if (!message) {
      message = this.validateCommonFields(requiredFields);
    }

    if (!message && this.campaignDetails.bookingList) {
      for (const obj of this.campaignDetails.bookingList) {
        const requiredBookingsFields: RequiredBookingsFields = new RequiredBookingsFields();
        if (!message) {
          message = this.validateBookingFields(obj, requiredBookingsFields);
        }
      }
    }
    if (!message) {
      message = this.validateMediaLength(this.campaignDetails, status);
    }
    return this.isValid(message);
  }

  /**
   * @description Book campaign
   * @author Darshan Vachhani
   * @param {*} status
   * @memberof CommercialBeComponent
   */
  onBookCampaign(status: number) {
    if (this.validateCommercialCampaign(status)) {
      status = status || this.campaignDetails.campaignStatusId;

      if (this.isROC(status)) {
        this.handleROC(status, this.createRequestJSONForCommercial);
      } else {
        this.validateServiceRequest(status, this.createRequestJSONForCommercial);
      }
    }
  }

  /**
   * @description update campaign details on booking line change
   * @author Darshan Vachhani
   * @memberof CommercialBeComponent
   */
  onBookingLineChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description managegross media value for whole booking grid
   * @author Darshan Vachhani
   * @memberof CommercialBeComponent
   */
  manageGrossMediaValueWithoutIndex() {
    if (this.campaignDetails.bookingList) {
      this.campaignDetails.bookingList.forEach((_, index) => {
        this.campaignDetailsService.manageGrossMediaValue(index, this.campaignDetails, 'bookingList');
      });
    }
    this.commercialService.notifyGrid(this.campaignDetails.bookingList);
  }

  /**
   * @description calcualte CPT
   * @author Darshan Vachhani
   * @param {*} value
   * @memberof CommercialBeComponent
   */
  calculateCPT(value) {
    if (value.columnName === 'grossMediaValue') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex,
        undefined, Number(this.campaignDetails.bookingList[value.rowIndex].grossMediaValue));
    } else if (value.columnName === 'pmpdiscount') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex);
    }
  }

  /**
   * @description This will be called when readOnly flag changed
   * @author Dhaval Patel
   * @memberof CommercialBeComponent
   */
  reaonlyChanged() {
    this.bookingGridUkComponent.readOnlyUpdate();
    this.commercialButtonsComponent.readOnlyChanged();
    this.discountGridBelgiumComponent.readOnlyUpdate();
  }

  async manuallyCPTUpdated() {
    const campaigndata = this.createRequestJSONForCommercial(this.campaignDetails.campaignStatusId);
    await this.updateCommercialInfoForCPT(campaigndata);
    this.getCommercialDataFromService(true);
  }

}
