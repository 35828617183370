import { Brand } from './brand';
import { Advertiser } from './advertiser';
import { Specialist } from './specialist';
import { Agency } from './agency';
import { AuditModel } from './auditModel';
import { ProximityProhibitionGroup } from './workspace';
import { CampaignDetails } from '../commercial/commercial.model';

export class Campaign extends CampaignDetails {
  id = 0;
  bookingStatusId = 0;
  brand: Brand = new Brand();
  advertiser: Advertiser = new Advertiser();
  specialist?: Specialist = new Specialist();
  agency: Agency = new Agency();
  duplicationAdvertisers: Advertiser[] = [];
  excludeCustomFormats = true;
  excludeDummyPanels = true;
  excludeDuplication = true;
  excludeJuxta = true;
  excludeProximityProhibition = false;
  excludeAgencyBlacklist = true;
  excludeAdvertiserBlacklist = true;
  excludeSpecialistBlacklist = true;
  exclusivity = false;
  exclusivityMask = 1;
  productCategoryId = -1;
  proximityProhibition: ProximityProhibitionGroup[] = [];
  salesTeamId = -1;
  campaignTypeGroupId = -1;
  campaignTypeId = -1;
  disableAgency = false;
  disableSpecialist = false;
  auditRecords: AuditModel;
}
