import { Component, OnInit, Input } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { LocaleData } from '../../core/utils/LocaleData';
import { DatePipe } from '@angular/common';
import {
  AgCustomRowUtil,
  AgCollapseExpandComponent,
  AgTextFloatingFilterComponent,
  AgCustomFilterMenuComponent,
  AgCustomHeaderComponent,
  AgCustomSort
} from '../discount-grid/ag-grid-support';
import { DataShareService } from '../../core/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
@Component({
  selector: 'app-competitive-campaigns',
  templateUrl: './competitive-campaigns.component.html',
  styleUrls: ['../discount-grid/ag-grid-custom-style.css', '../discount-grid/ag-grid-col-select-style.css', './competitive-campaigns.component.css']
})
export class CompetitiveCampaignsComponent implements OnInit {
  @Input() resolveObject: any = [];

  rowData: any;
  // @Input() config: any;
  userBundle: any;
  brickBgColor = '#ef3f3b';

  /**
   * Ag-grid Data column sort object
   */
  sortObj: AgCustomSort;

  /**
   * Ag-Grid Options Object
   */
  gridOptions: GridOptions;

  numericFields = [];
  dateRangeFields = [];
  selectFields = [];
  groupingField = 'campaignId';

  columnDefs = [];

  constructor(
    private datePipe: DatePipe,
    private dataShareService: DataShareService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.rowData = this.resolveObject.rowData;
    this.prepareColumnDefs();
    this.prepareRowData();
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      scrollbarWidth: 10,
      getRowHeight: this.getRowHeight,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      onFilterChanged: this.onFilterChanged,
      suppressScrollOnNewData: true,
      groupingField: this.groupingField,
      suppressMovableColumns: true,
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: node => node.data.isVisible
    } as GridOptions;
  }

  groupFieldCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'dvTextoverflow';
    let text = '';
    if (params.data.isParent) {
      text = `${params.data[params.column.colId]} (${params.data.childCount})`;
    } else {
      text = params.data[params.column.colId];
    }
    eDiv.innerHTML = text;
    return eDiv;
  }

  dateCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'dvTextoverflow';
    let text = '';
    if (params.value) {
      text = this.datePipe.transform(params.value, LocaleData.displayDateFormat);
    }
    eDiv.innerHTML = text;
    return eDiv;
  }

  prepareColumnDefs() {
    this.columnDefs.push(
      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false,
        cellRendererFramework: AgCollapseExpandComponent,
        headerComponentFramework: AgCollapseExpandComponent,
        cellClass: (params): string[] => {
          const retArr: string[] = [];
          if (params.data.isSelected) {
            retArr.push('selectedRow');
          }
          if (params.node.rowPinned === 'bottom') {
            retArr.push('borderBottom');
          }
          return retArr;
        }
      }
    );

    this.columnDefs.push(
      {
        headerName: this.userBundle['common.campaignRef'], pinned: 'left', field: 'campaignReference', width: 150, editable: false,
        cellRenderer: this.groupFieldCellRenderer
      });
    this.columnDefs.push(
      {
        headerName: this.userBundle['common.frameId'], pinned: 'left', field: 'frameId', width: 150, editable: false,
      });
    this.columnDefs.push(
      {
        headerName: this.userBundle['common.fromDate'], field: 'startDate', width: 150, editable: false, filter: false,
        cellRenderer: this.dateCellRenderer
      });
    this.columnDefs.push(
      {
        headerName: this.userBundle['common.toDate'], field: 'endDate', width: 150, editable: false, filter: false,
        cellRenderer: this.dateCellRenderer
      });
    this.columnDefs.push(
      {
        headerName: this.userBundle['common.title'], field: 'title', width: 225, editable: false
      });
    this.columnDefs.push(
      {
        headerName: this.userBundle['common.brandName'], field: 'brand', width: 150, editable: false
      });
    this.columnDefs.push(
      {
        headerName: this.userBundle['common.advertiserName'], field: 'advertiser', width: 150, editable: false
      });

    this.columnDefs.push(
      {
        headerName: this.userBundle['common.salesTeamName'], field: 'salesTeam', width: 150, editable: false
      });

    this.columnDefs.push(
      {
        headerName: this.userBundle['common.salesPersonName'], field: 'salesPerson', width: 150, editable: false
      });

    for (let i = 1; i < this.columnDefs.length; i++) {

      this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
      this.columnDefs[i].floatingFilterComponentParams = {
        value: '',
        suppressFilterButton: true,
        decimalRound: null
      };
      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };
      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => {
        const retArr: string[] = [];
        if (params.data.isParent || params.node.rowPinned === 'bottom') {
          retArr.push('fontBold');
        }
        return retArr;
      };
    }

    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField, true);
  }

  prepareRowData() {
    const rowData = [];
    for (const element of this.rowData) {
      element.isVisible = true;
      element.isExpanded = false;
      element.isParent = true;
      element.isFilterPass = true;
      element.isSelected = false;
      element.childCount = element.framesList.length;

      rowData.push(element);
      for (const fl of element.framesList) {
        const frame: any = {};
        frame.frameId = fl;
        frame.isVisible = false;
        frame.isExpanded = false;
        frame.isParent = false;
        element.isSelected = false;
        element.isFilterPass = true;
        rowData.push({ ...element, ...frame });
      }
    }
    this.rowData = rowData;
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else if (params.data.isVisible) {
      height = 30;
    }
    return height;
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.map((r) => {
        r.isFilterPass = true;
      });
    }
    AgCustomRowUtil.setParentRowSelectedProperty(this.rowData, this.groupingField);
    // Refresh Grid Header checkbox component
    /* tslint:disable:no-string-literal */
    this.gridOptions.api['headerRootComp'].forEachHeaderElement((ev: any) => {
      if (ev.column.colId === 'selectChk' && ev.columnApi && ev.childComponents && ev.childComponents.length > 1) {
        const headerCmpIns = ev.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
  }

  onModalSaved() {
    this.activeModal.close(true);
  }

  onModalClosed() {
    this.activeModal.dismiss('dismiss');
  }

}
