<div [ngClass]="{'readOnly' : readOnly }">
    <div class="width_3">
        <div class="control_group">
            <label class="label datelabel">
                {{userBundle['common.campaignStartDate']}}
            </label>
            <input id="campaignStartDate" type="text" [disabled]="true"
                [ngModel]="campaignDetails.campaignStartDate | dateFormat : displayFromToDateFormat" class="form_control" />
        </div>
    </div>
    <div class="width_3">
        <div class="control_group">
            <label class="label datelabel">
                {{userBundle['common.campaignEndDate']}}
            </label>
            <input id="campaignEndDate" type="text" [disabled]="true"
                [ngModel]="campaignDetails.campaignEndDate  | dateFormat : displayFromToDateFormat " class="form_control" />
        </div>
    </div>
    <div class="width_3">
        <div class="control_group">
            <label class="label datelabel">{{userBundle['common.campaignCreateDate']}}</label>
            <input id="campaignCreateDate" type="text" [disabled]="true"
                [ngModel]="campaignDetails.campaignCreateDate  | dateFormat : displayDateFormat" class="form_control" />
        </div>
    </div>
    <div class="width_3">
        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label"
                [ngClass]="{'required' : (campaignDetails?.advertiser.advertiserId == '' || campaignDetails?.advertiser.advertiserId == null)} ">
                {{userBundle['common.advertiserName']}}
            </label>

            <div class="gcontainer">
                <app-sb-select id="advertiserSelect" [config]="advertiserLookupConfig"
                    [items]="advertisersList"
                    [currentItem]="campaignDetails.advertiser" (onItemSelected)="onAdvertiserNameSelected($event)"
                    [disabled]="readOnly" (onItemRemoved)="removeSelectedAdvertiserName($event);"
                    (responseData)="getAdvertisersResponse($event)">
                </app-sb-select>
            </div>
        </div>

        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label">{{userBundle['common.specialistName']}}</label>
            <div class='gcontainer'>
                <app-sb-select id="specialistSelect" [config]="specialistLookupConfig"
                    [items]="specialistsList"
                    [currentItem]="campaignDetails.specialist" (onItemSelected)="onSpecialistNameSelected($event)"
                    (onItemRemoved)="removeSelectedSpecialistName()"
                    (responseData)="getSpecialistsResponse($event)"
                    [disabled]="readOnly || campaignDetails.disableSpecialist">
                </app-sb-select>
            </div>
        </div>

        <div class="control_group"  [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label">{{userBundle['common.agencyName']}}</label>
            <div class='gcontainer'>
                <app-sb-select id="agencySelect" [config]="agencyLookupConfig" [items]="agenciesList"
                    [currentItem]="campaignDetails.agency"
                    (onItemSelected)="onAgencyNameSelected($event)" (onItemRemoved)="removeSelectedAgencyName()"
                    (responseData)="getAgenciesResponse($event)"
                    [disabled]="readOnly  || campaignDetails.disableAgency">
                </app-sb-select>
            </div>
        </div>

    </div>
    <div class="width_3">
        <!-- !(this.campaignDetails.brand.brandId > 0) -->
        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label"
                [ngClass]=" (campaignDetails?.brand.brandId == 0 || campaignDetails?.brand.brandId == null) ?'required' :''">
                {{userBundle['common.brandName']}}
            </label>
            <div class='gcontainer'>
                <app-sb-select id="brandSelect" [config]="brandLookupConfig"
                    [items]="brandsList" [currentItem]="campaignDetails.brand" (onItemSelected)="onBrandSelected($event)"
                    [disabled]="readOnly" (onItemRemoved)="removeSelectedBrand($event)"
                    (responseData)="getBrandsResponse($event)">
                </app-sb-select>
            </div>

        </div>

        <div class="control_group">
            <label class="label">{{userBundle['common.specialist.commission']}} </label>
            <input id="specialistPercentage" type="text" [imask]="percentageInputMask"
                [unmask]="maskService.unmask.typed" class="form_control percentage"
                [(ngModel)]="campaignDetails.specialistPercentage" (change)="manageGrossMediaValueWithoutIndex()" />
        </div>
        <div class="control_group">
            <label class="label">{{userBundle['common.agency.commission']}} </label>
            <input id="agencyPercentage" type="text" [imask]="percentageInputMask" [unmask]="maskService.unmask.typed"
                class="form_control percentage" [(ngModel)]="campaignDetails.agencyPercentage"
                (change)="manageGrossMediaValueWithoutIndex()" />
        </div>
    </div>
    <div class="width_3">
        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label" [ngClass]="{'required' : campaignDetails.productCategoryId == -1}">
                {{userBundle['common.productCategoryName']}}</label>
            <select id="productCategorySelect" class="form_control chzn-container"
                [(ngModel)]="campaignDetails.productCategoryId" [ngStyle]="{'width': '58%'}">
                <option value="-1">{{userBundle['common.lookup.defaultOption']}}</option>
                <ng-template ngFor let-item [ngForOf]="productCategoryList" [ngForTrackBy]="trackByProductCategoryGroup">
                    <optgroup *ngIf="item.productCategory"
                        label="{{item.productCategoryGroupName}} - {{item.productCategoryGroupCode}}">
                        <option *ngFor="let child of item.productCategory; trackBy: trackByProductCategory;" [value]="child.productCategoryId">
                            {{child.productCategoryName}}
                        </option>
                    </optgroup>
                    <option *ngIf="!item.productCategory">{{item.productCategoryGroupName}}</option>
                </ng-template>
            </select>
        </div>


        <div class="control_group">
            <label class="label" [ngClass]="{'required' : campaignDetails.salesTeamId == -1}">
                {{userBundle['common.salesTeamName']}}
            </label>
            <select id="salesTeamSelect" class="form_control chzn-container" [(ngModel)]="campaignDetails.salesTeamId"
                [ngStyle]="{'width': '58%'}">
                <option value="-1">{{userBundle['common.lookup.defaultOption']}}</option>
                <option *ngFor="let SaleInfo of salesTeamList; trackBy: trackBySalesTeam;" [value]="SaleInfo.salesTeamId">
                    {{SaleInfo.salesTeamName}}
                </option>
            </select>

        </div>
        <div class="control_group">
            <label class="label">{{userBundle['common.subAgencyName']}}</label>
            <div class='gcontainer'>
                <app-sb-select id="subagency" [config]="subAgencyLookupConfig"
                    [items]="subAgenciesList" [currentItem]="campaignDetails.subAgency"
                    (onItemSelected)="onSubAgencyNameSelected($event)"
                    (onItemRemoved)="removeSelectedSubAgencyName()" (responseData)="getSubAgencyResponse($event)"
                    [disabled]="subAgencydisable">
                </app-sb-select>
            </div>
        </div>
    </div>

    <div class="width_8">
        <div class="control_group">
            <label class="label" [ngClass]="{'required' : campaignDetails.title == null || campaignDetails.title.trim() === ''}">
                {{userBundle['common.title']}}
            </label>
            <textarea id="campaignTitle" class="form_control customtextarea"
                [(ngModel)]="campaignDetails.title" (change)="manuallyTitleChanged()"></textarea>
        </div>
    </div>

    <div class="width_3" [hidden]="commercialHiddenElements('commercial.booking.overs')">
        <div class="control_group">
            <label class="label">{{userBundle['common.overs']}}</label>
            <input id="overs" type="text" class="form_control" [(ngModel)]="campaignDetails.overs" maxlength="255" />
        </div>
    </div>

    <div class="width_3" [hidden]="commercialHiddenElements('commercial.booking.pmpDealId')">
        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label"
                [ngClass]="{'required' : (searchPageObject.campaignTypeGroupId == campaignGroupTypeList.PMP) && (!campaignDetails.pmpDealId || campaignDetails.pmpDealId === '')} ">
                {{userBundle['common.pmpDealId']}}</label>
            <input id="pmpDealId" type="text" class="form_control" autocomplete="off" [(ngModel)]="campaignDetails.pmpDealId"
                maxlength="50" />
        </div>
    </div>

    <div class="width_3">
        <div class="control_group">
            <label class="label"
                [ngClass]="(campaignDetails?.assignedTo == 'undefined' || campaignDetails?.assignedTo == null) ? 'required' : ''">
                {{userBundle['common.assignedToName']}}
            </label>

            <select id="assignedTo" class="form_control chzn-container" [(ngModel)]="campaignDetails.assignedTo"
                [ngStyle]="{'width': '58%'}" (change)="assignedToChange()">
                <option value="undefined">{{userBundle['common.lookup.defaultOption']}}
                </option>
                <option *ngFor="let AssignedTo of assignedToList; trackBy: trackByAssignedTo;" [ngValue]="AssignedTo" [disabled]="!AssignedTo.inUse" [ngClass]="{'inactive-assignedto': !AssignedTo.inUse}">
                    {{AssignedTo.assignedToName}}
                </option>
            </select>

        </div>
    </div>
    <div class="width_3">
        <div class="control_group">
            <label class="label">{{userBundle['commercial.label.externalReference']}}</label>
            <input id="externalReference" type="text" class="form_control"
                [(ngModel)]="campaignDetails.externalReference" maxlength="50" />
        </div>
    </div>
    <div class="width_3" [hidden]="commercialHiddenElements('commercial.booking.campaignCPT')">
        <div class="control_group" [ngClass]="{'readOnly': ppCampaignReadOnlyFlag}">
            <label class="label"
                [ngClass]="{'required': searchPageObject.campaignTypeGroupId == campaignGroupTypeList.PMP && !campaignDetails.campaignCPT}">{{userBundle['common.campaignCPT']}}</label>
            <input id="campaignCPT" type="text" [(ngModel)]="campaignDetails.campaignCPT" [imask]="currencyInputMask"
                [unmask]="maskService.unmask.typed" class="form_control percentage" (blur)="onAverageCPTChanged()" />
        </div>
    </div>

    <div class="width_3" *ngIf="searchPageObject.campaignTypeGroupId == campaignGroupTypeList.PMP">
      <div class="control_group">
        <label class="label" style="width: 46%">
          {{userBundle['commercial.label.preventRecalculation'] || 'Prevent Recalculation'}}
        </label>
        <div class="swichControl-commercial">
          <label class="switchAllowFrames">
            <input type="checkbox" [(ngModel)]="campaignDetails.preventRecalculation" (change)="preventRecalculationChanged()">
            <span class="sliderAllowFrames round"></span>
          </label>
        </div>
      </div>
    </div>
    
    <div class="width_3" *ngIf="commercialConfig.uiControl.showTargetImpression">
        <div class="control_group">
            <label class="label">{{userBundle['commercial.label.targetImpressions'] || "Target Impressions"}}</label>
            <input id="campaignCPT" type="text" [(ngModel)]="campaignDetails.campaignTargetImpressions" [imask]="commonNumberInputMask"
                [unmask]="maskService.unmask.typed" (change)="campaignTargetImpressionsChanged()" class="form_control percentage" />
        </div>
    </div>

    <div class="width_8">
        <div class="control_group">
            <label class="label">{{userBundle['common.comments']}}</label>
            <textarea id="comments" class="form_control customtextarea-comments" [(ngModel)]="campaignDetails.comments"
                maxlength="2000"></textarea>
        </div>
    </div>


    <div class="width_3" *ngIf="commercialConfig.uiControl.showOvershowToggle">
        <div class="control_group">
            <label class="label" style="width: 46%">
                {{userBundle['commercial.label.overshow']}}
            </label>
            <div class="swichControl-commercial">
                <label class="switchAllowFrames">
                    <input type="checkbox" [(ngModel)]="campaignDetails.overshow">
                    <span class="sliderAllowFrames round"></span>
                </label>
            </div>
        </div>
    </div>

    <div class="width_8" *ngIf="commercialConfig.uiControl.historyROC && historyROC.data">
        <div class="control_group">
          <label class="label">{{userBundle['commercial.label.historyROC'] || 'History'}}</label>
          <textarea class="form_control customtextarea-comments history-pointer" [(ngModel)]="historyROC.data"
            maxlength="2000" cols="30" rows="1" title={{historyROC.data}} disabled></textarea>
        </div>
    </div>
    <div class="width_3">
        <div class="control_group"></div>
    </div>

    <label class="subtitles">{{userBundle['commercial.title.financialSummary']}}</label>

    <div class="width_3">
        <div class="control_group">
            <label class="label">
                <!--{{common.isBookGrossMedia.displayLabel}}-->
                {{userBundle['commercial.label.campaignGrossMedia']}}
                ({{systemData.currencySymbol}})</label>
            <input id="grossMediaValue" type="text" [appOnlyNumber]="true"
                [ngModel]="campaignDetails['grossMediaValue'] | number"
                (ngModelChange)="campaignDetails['grossMediaValue']=$event" [disabled]=true class="form_control" />
        </div>
    </div>
    <div class="width_3">

        <div class="control_group">
            <label class="label">
                {{userBundle['commercial.label.campaignNetMedia']}}
                ({{systemData.currencySymbol}})</label>
            <input id="netMediaValue" type="text" [ngModel]="campaignDetails.netMediaValue | number"
                (ngModelChange)="campaignDetails.netMediaValue=$event" [disabled]=true class="form_control" />
        </div>
    </div>
    <div class="width_3" [hidden]="uiControl.hidePrice">
        <div class="control_group">
            <label class="label">{{userBundle['common.smartBricsValue']}}</label>
            <input id="smartBricsValue" type="text" [appOnlyNumber]="true"
                [ngModel]="campaignDetails.smartBricsValue | number"
                (ngModelChange)="campaignDetails.smartBricsValue=$event" [disabled]=true class="form_control"
                maxlen="9" />
        </div>
    </div>

</div>
