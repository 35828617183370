import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { SbModalPopupService } from '../sb-modal-popup/sb-modal-popup.service';
import { SbModalWrapperComponent } from './sb-modal-wrapper/sb-modal-wrapper.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularDraggableModule } from 'angular2-draggable';
import { CommonModule } from '@angular/common';
@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    AngularDraggableModule
  ],
  declarations: [SbModalWrapperComponent],
  exports: [SbModalWrapperComponent],
  providers: [SbModalPopupService]
})
export class SbModalPopupModule {
  static forRoot(components: any[]): ModuleWithProviders<SbModalPopupModule> {
    return {
      ngModule: SbModalPopupModule,
      providers: [
        SbModalPopupService
      ]
    };
  }
  constructor(@Optional() @SkipSelf() parentModule: SbModalPopupModule) {
    if (parentModule) {
      throw new Error(
        'SbModalPopupModule is already loaded. Import it in the AppModule only');
    }
  }
}
