export class AudienceFilterModel {
  audienceCategoryId: number;
  audienceCategoryName: string;
  description: string;
}

export class AudienceCategoryGroup {
  audienceCategoryGroupId: number = null;
  audienceCategoryGroupCode: string = null;
  audienceCategoryGroupShortName: string = null;
  audienceCategoryGroupName: string = null;
  options: AudienceOption = null;
  audienceCategoryType: AudienceCategoryType[] = [];
}

export class AudienceCategoryType {
  audienceCategoryTypeId: number | string = null;
  audienceCategoryTypeCode: string = null;
  audienceCategoryTypeName: string = null;
  audienceCategory: AudienceCategory[];
}

export class AudienceCategory {
  audienceCategoryId: number = null;
  audienceCategoryName: string = null;
  description: string = null;
  selected = false;
  audienceCategoryTypeCode: any;
  src: any;
}

export class AudienceOption {
  bricColour: string = null;
  coverAndFreq: boolean;
  displayFormat: number = null;
  primaryAudienceType: string = null;
  qocAvailable: boolean;
  secondaryAudience: boolean;
  tag1Name: string = null;
  tag2Name: string = null;
  tags: number[];
}
