import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class SbModalPopupService {

  /**
   * @description default modal options
   * @private
   * @type {NgbModalOptions}
   * @memberof SbModalPopupService
   */
  private modalOptions: NgbModalOptions = {
    backdrop: false,
    keyboard: false,
    windowClass: 'modal-backdrop',
    size: 'sm'
  };

  /**
   * Creates an instance of SbModalPopupService.
   * @author Amit Mahida
   * @param {NgbModal} modalService
   * @memberof SbModalPopupService
   */
  constructor(
    private modalService: NgbModal,
  ) { }

  /**
   * @description opens modal
   * @author Amit Mahida
   * @param {*} component
   * @param {*} resolveObject
   * @param {*} [modalOptions]
   * @returns
   * @memberof SbModalPopupService
   */
  open(component, resolveObject, modalOptions?: NgbModalOptions) {
    const newOptions = Object.assign({}, this.modalOptions, modalOptions);
    const modalRef: any = this.modalService.open(component, newOptions);
    modalRef.componentInstance.resolveObject = resolveObject;
    return modalRef;
  }
}
