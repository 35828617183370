import { ToasterConfig } from 'angular2-toaster';

/**
 * Toaster Configuration
 */
export const toasterconfig: ToasterConfig =
  new ToasterConfig({
    animation: 'fade',
    limit: 5,
    showCloseButton: false,
    mouseoverTimerStop: true,
    positionClass: 'toast-bottom-right',
    timeout: 3000
  });
