import { Component, OnInit, Input } from '@angular/core';
import { DataShareService, LogHelperService } from '../../core/services';
import { ReasonForChangeResult } from '../commercial.model';
import { CampaignStatus } from '../../core/enum';

@Component({
  selector: 'app-reasonforchange',
  templateUrl: './reasonforchange.component.html',
  styleUrls: ['./reasonforchange.component.css']
})
export class ReasonForChangeComponent implements OnInit {
  brickBgColor = '#ef3f3b';
  public campaign = {
    reasonForChange: new ReasonForChangeResult(),
    freeText: null
  };
  public reasonDropdown = null;
  public reasonForChange = {
    labletext: null,
    title: null,
    bgcolor: '#DEDEDE'
  };
  // public showHideText: boolean = false;
  autocompleteItems: any;
  initialConfig = null;
  @Input() resolveObject: any;

  constructor(
    private dataShareService: DataShareService,
    private logHelperService: LogHelperService
  ) {
  }

  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    if (this.resolveObject != null && this.resolveObject.status === CampaignStatus.cancel) {
      this.reasonForChange = {
        labletext: this.initialConfig.userBundle['commercial.label.rfcancel'],
        title: this.initialConfig.userBundle['commercial.title.rfcancel'].toUpperCase(),
        bgcolor: '#ef3f3b'
      };
      this.brickBgColor = '#ef3f3b';
    } else {
      this.loadDefaultForChangeReason();
    }
    // if status is cancel, only then show reasons for cancel in rest call cases need to show reason for change
    this.reasonDropdown = this.resolveObject.status !== CampaignStatus.cancel ? this.initialConfig.auditType.find(item => item.auditTypeId === 1).auditReason : this.initialConfig.auditType.find(item => item.auditTypeId === 3).auditReason;
    this.reasonDropdown = this.reasonDropdown.filter(data => !this.resolveObject.isDropDown === data.specifyOther);
  }

  loadDefaultForChangeReason() {
    this.reasonForChange = {
      labletext: this.initialConfig.userBundle['commercial.label.rfc'],
      title: this.initialConfig.userBundle['commercial.title.rfc'].toUpperCase(),
      bgcolor: '#DEDEDE'
    };
    this.brickBgColor = '#DEDEDE';
  }

  onModalSaved(event) {

    if (this.campaign && !Object.keys(this.campaign.reasonForChange).length) {
      this.logHelperService.logError(this.initialConfig.userBundle[this.resolveObject.status === CampaignStatus.confirm ? 'commercial.error.rfc' : 'commercial.error.reasonforcancel']);
      return;
    }

    if (!this.campaign.reasonForChange.specifyOther) {
      event.activeModal.close(this.campaign);
    } else {
      if (this.campaign.freeText !== '' && this.campaign.freeText !== null) {
        event.activeModal.close(this.campaign);
      } else {
        this.logHelperService.logError(this.initialConfig.userBundle[this.resolveObject.status === CampaignStatus.confirm ? 'commercial.error.rfc' : 'commercial.error.reasonforcancel']);
      }
    }

  }

  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }

  trackByItem(index, item) {
    return item;
  }

}
