export class AgCustomRowUtil {

  public static getRowData(gridApi) {
    const rowData = [];
    gridApi.forEachNode((node) => {
      rowData.push(node.data);
    });
    return rowData;
  }

  public static getChildRows(rowData, groupingField?: string, groupingFieldId?: string, filterOnly = true): any[] {
    let childRows = [];
    if (groupingField && groupingFieldId) {
      childRows = rowData.filter((r) => {
        if (filterOnly) {
          return (r[groupingField] === groupingFieldId && (!r.isParent) && r.isFilterPass);
        } else {
          return (r[groupingField] === groupingFieldId && (!r.isParent));
        }
      });
    } else {
      childRows = rowData.filter((r) => {
        if (filterOnly) {
          return ((!r.isParent) && r.isFilterPass);
        } else {
          return (!r.isParent);
        }
      });
    }
    return childRows;
  }

  public static getSelectedChildRows(rowData, groupingField?: string, groupingFieldId?: string, filterOnly = false): any[] {
    let selectedChildRows = [];
    if (groupingField && groupingFieldId) {
      selectedChildRows = rowData.filter((r) => {
        if (filterOnly) {
          return (r[groupingField] === groupingFieldId && (!r.isParent) && r.isSelected && r.isFilterPass);
        } else {
          return (r[groupingField] === groupingFieldId && (!r.isParent) && r.isSelected);
        }
      });
    } else {
      selectedChildRows = rowData.filter((r) => {
        if (filterOnly) {
          return (!r.isParent && r.isSelected && r.isFilterPass);
        } else {
          return (!r.isParent && r.isSelected);
        }
      });
    }
    return selectedChildRows;
  }

  public static getParentRows(rowData, groupingField?: string, groupingFieldId?: string): any[] {
    let parentRows = [];
    if (groupingField && groupingFieldId) {
      parentRows = rowData.filter((r) => {
        return (r[groupingField] === groupingFieldId && (r.isParent) && r.isFilterPass);
      });
    } else {
      parentRows = rowData.filter((r) => {
        return (r.isParent && r.isFilterPass);
      });
    }
    return parentRows;
  }

  public static setParentRowSelectedProperty(rowData, groupingField: string): void {
    let parentRows = [];
    parentRows = rowData.filter((r) => {
      return (r.isParent && r.isFilterPass);
    });
    for (const parentRow of parentRows) {
      const childRows = rowData.filter((r) => {
        return (r[groupingField] === parentRow[groupingField] && (!r.isParent) && r.isFilterPass);
      });
      const selectedChildRows = childRows.filter((r) => {
        return r.isSelected;
      });
      parentRow.isSelected = childRows.length === selectedChildRows.length;
    }
  }

  public static sumFieldValues(rows: any[], fieldName: string): number {
    let sum = 0;
    rows.forEach((row) => {
      if (row[fieldName]) {
        sum = sum + parseFloat(row[fieldName]);
      }
    });
    return sum;
  }
}
