import { DataShareService } from '../../core/services/data-share.service';
import { DistributionEnum } from './distribution-enum';

export class Distribution {
  distributionType: number | string;
  distributionName: string = null;
}
export class DistributionBase {

  /**
   * @description User bundle object
   * @type {object}
   * @memberof DistributionModalComponent
   */
  userBundle: object;
  distributionEnum: object;
  /**
   * use for selected distribution
   *
   * @memberof DistributionBase
   */
  distribution = new Distribution();
  previousValues = new Distribution();

  constructor(
    private dataService: DataShareService
  ) {
    //
  }

  init(selectedValue: Distribution) {
    this.userBundle = this.dataService.getInitialConfigByKey('userBundle');
    this.distributionEnum = new DistributionEnum().getDistributionEnum(this.userBundle);
    if (selectedValue != null && Object.keys(selectedValue).length > 0) {
      this.distribution = selectedValue;
    } else {
      this.distribution = {
        distributionType: 1,
        distributionName: ''
      };
    }
  }

  /**
   *  set distribution Name by distribution Type
   *
   * @memberof DistributionBase
   */
  setDistributionName() {
    if (this.distribution) {
      this.distribution.distributionType = parseInt(this.distribution.distributionType.toString(), 10);
      this.distribution.distributionName = this.distributionEnum[this.distribution.distributionType];
    }
  }
}
