import { Component, OnInit, Input } from '@angular/core';
import { DataShareService } from '../../../core/services/data-share.service';

@Component({
  selector: 'app-copy-line-modal',
  templateUrl: './copy-line-modal.component.html'
})
export class CopyLineModalComponent implements OnInit {
  @Input() resolveObject: any;
  public userBundle: any;
  public maxNumberofFrames: any;
  public selectedRecord: any;
  public statusOptions: any;

  constructor(dataShareService: DataShareService) {
    this.userBundle = dataShareService.getInitialConfigByKey('userBundle');
  }

  ngOnInit() {
    this.selectedRecord = this.resolveObject.selectedRecord;
    this.statusOptions = this.resolveObject.statusOptions;
  }

  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }

  onModalSaved(event) {
    event.activeModal.close(this.selectedRecord);
  }

  trackByIdStatusCategory(index, item) {
    return item && item.idStatusCategory;
  }

}
