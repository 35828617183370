import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DataShareService } from './../../core/services/data-share.service';
import { FilterDataService } from './../../core/services/filter-data.service';
import { LogHelperService } from './../../core/services/log-helper.service';
import { AudienceBricService } from './../../core/services/audience-bric.service';
import { BrickBaseService } from './../../core/services/brick-base.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-audience-filter',
  templateUrl: './audience-filter.component.html',
  styleUrls: ['./audience-filter.component.css']
})
export class AudienceFilterComponent implements OnInit {

  /**
   * It will get data as object to configure filter.
   */
  @Input() public resolveObject: any = {
    audienceCategoryGroup: {}
  };
  /**
   * It emits event to close filter window.
   */
  @Output() closeSidebar = new EventEmitter<string>();
  /**
   * It contains selected data.
   */
  public selectedAudience: any = {};
  /**
   * It defines category type for audience.
   */
  public audienceCategoryType: any;
  /**
   * It defines options for audience.
   */
  public options: any;
  /**
   * It contains inintal config data.
   */
  private initialConfig: any;

  /**
   * Constructor
   * @param dataShareService Service to share data between compoenents.
   * @param filterDataService Service which used to handle filter data.
   * @param logHelperService Service for log messages.
   * @param audienceBricService Service for common functions.
   */
  constructor(
    private dataShareService: DataShareService,
    private filterDataService: FilterDataService,
    private logHelperService: LogHelperService,
    private audienceBricService: AudienceBricService,
    private brickBaseService: BrickBaseService
  ) { }

  /**
   * Initialization purpose
   */
  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    const audienceCategoryGroup = this.audienceBricService.populateDataForAudienceBric(this.brickBaseService.audienceCategoryGroupId,
      _.clone(this.resolveObject.audienceCategoryGroup), this.resolveObject.audienceTags);

    this.audienceCategoryType = audienceCategoryGroup.audienceCategoryType;
    this.options = audienceCategoryGroup.options;

    // Restore to previous state
    this.selectedAudience = this.filterDataService.getRating();
  }

  /**
   * Set the audience to model.
   * @param selecteAudience Selected Audience
   */
  selectAudience(selecteAudience: any) {
    this.selectedAudience = selecteAudience;
  }

  /**
   * Save the changes of audience filter.
   * @param msg message to display in popup.
   */
  saveClick(msg: string) {
    if (Object.keys(this.selectedAudience).length > 0) {
      this.filterDataService.setRating(this.selectedAudience);
      this.closeSidebar.emit(msg);
    } else {
      const message = this.initialConfig.userBundle['geoplanner.audience.selectAnyValue']
        || 'Please select an audience category to continue!';
      this.logHelperService.logError(message);
      return false;
    }
  }

  /**
   * Close the audience filter popup.
   * @param msg message
   */
  closeClick(msg: string) {
    this.closeSidebar.emit(msg);
  }

  trackByAudienceCategory(index, category) {
    return category?.audienceCategoryId;
  }

  trackByIndex(index, item) {
    return index;
  }
}
