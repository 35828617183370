import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { LookupColumn, SystemFlags, UiControl } from '../../models';
import { Filter, Row, Cell, ProductHelper, ProcessBrick, ProcessParams, DateRange, ColumnSummary, ColumnHelper } from '../../models/workspace';

import { Brick } from '../../workspace/brick-model';
import { SharedService } from './shared.service';
import { DataShareService } from './data-share.service';
import { BrickBaseService } from './brick-base.service';
import { LogHelperService } from './log-helper.service';
import { CellAttributeService } from './cell-attributes.service';
import { ColumnConfigs } from '../../models/MapData.model';
import { Subject } from 'rxjs';
import { getFormData } from '../utils/formData';
import { AppNameEnum } from '../enum';
import { GLOBAL } from '../utils/app.constant';
import { List } from 'linqts';

declare let saveAs; // FileSaver.js
import * as _ from 'lodash';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { PcmService } from './pcm.service';
import { ObjectiveMeasure } from '../../models/workspace/objective-measure';
import { ConfigUtils } from './../utils/configUtils';
//  './../../app/core/utils/configUtils';

@Injectable()
export class WorkspaceService {

  headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  defaultPanningSub: Subject<boolean> = new Subject();

  /**
   * Constructor
   * @param WorkspaceService workspace service object
   */
  constructor(
    private http: HttpClient,
    private dataShareService: DataShareService,
    private brickBaseService: BrickBaseService,
    private logHelperService: LogHelperService,
    private sharedService: SharedService,
    private datePipe: DatePipe,
    private cellAttributeService: CellAttributeService,
    private appHeaderService: AppHeaderService,
    private pcmService: PcmService
  ) { }

  getProduct(requestParams, url) {
    return this.sharedService.makeServerCall(requestParams, url);
  }

  processBricks(params: ProcessParams, serviceURL: string): Observable<any> {

    const requestOptions = {
      headers: this.headers,
      body: getFormData(params)
    };

    return this.http.request(
      GLOBAL.HTTP_METHOD,
      serviceURL,
      requestOptions
    )
      .map(this.extractData)
      .catch(this.handleError);
  }

  addProduct(params, serviceURL: string): Observable<any> {
    const requestOptions = {
      headers: this.headers,
      body: getFormData(params)
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      serviceURL,
      requestOptions
    )
      .map(this.extractData)
      .catch(this.handleError);
  }

  extractData(res: Response) {
    return res;
  }

  handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body['error'] || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return Observable.throwError(errMsg);
  }

  /**
   *
   * @param url  getting download file name
   */
  public downloadResource(url: string) {
    const apiUrl = url;
    const headers = new HttpHeaders();
    return this.http.get(
      apiUrl,
      {
        headers,
        responseType: 'blob' as 'json',
        observe: 'response'
      });
  }

  /**
   *
   * @param url  set url for getting resoure
   * @param fileName name for file for download
   */
  public async downloadFiles(url: string): Promise<void> {
    this.downloadResource(url).subscribe((res: HttpResponse<any>) => {
      const status = res.headers.get('status');
      if (status === 'KO') {
        this.logHelperService.logError(res.headers.get('message'));
      } else {
        const fileNameNew: string = res.headers.get('Download-file-name');
        const blob: Blob = res.body;
        saveAs(new Blob([blob], { type: blob.type, }), fileNameNew.replace('"', ''));
      }
    });

  }

  updateWorkspaceOnLock(filter: Filter, serviceURL: string, columnLineNumber) {
    const param: ProcessParams = new ProcessParams();
    param.bricsCampaignId = 1;
    param.action = 'updateWorkspace';
    const processBrick = new ProcessBrick();

    processBrick.bricsData = filter.brickRequestJSON;
    processBrick.campaignParameters = filter.campaignParameters;

    const columnHelper: ColumnHelper = _.cloneDeep(filter.stateService.getColumnHelper());

    if (filter.initialConfig.uiControl.defaultAllocationEngine) {
      // SM-6264
      if (filter.allocationEngines && filter.allocationEngines.length > 0) {
        Object.keys(filter.allocationEngines).forEach((index) => {
          columnHelper[index] = {
            systemLocked: columnHelper[index] ? columnHelper[index].systemLocked : false,
            shown: columnHelper[index] ? columnHelper[index].shown : true,
            userLocked: columnHelper[index] ? columnHelper[index].userLocked : false,
            allocationEngine: filter.allocationEngines[index]
              || filter.initialConfig.uiControl.defaultAllocationEngine
              || GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_GISGO
          };
        });
      } else {
        for (let r = 0; r < filter.rows[0].cells.length; r++) {
          columnHelper[r] = {
            systemLocked: columnHelper[r] ? columnHelper[r].systemLocked : false,
            shown: columnHelper[r] ? columnHelper[r].shown : true,
            userLocked: columnHelper[r] ? columnHelper[r].userLocked : false,
            allocationEngine: filter.initialConfig.uiControl.defaultAllocationEngine
              || GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_GISGO
          };
        }
      }
    }

    if (Object.keys(columnHelper).length) {
      processBrick.columnHelper = columnHelper;
    }
    processBrick.columnLineNumber = columnLineNumber;
    processBrick.deletedColumns = null; // TODO:

    // Populate columnLineNumber //
    // let columnLineNumber = createColumLineNumberParam();
    // param['data']['columnLineNumber'] = columnLineNumber;

    param.data = JSON.stringify(processBrick);
    return this.processBricks(param, serviceURL);
  }

  getCampaignSplitData(params, serviceURL) {
    params['bricsCampaignId'] = GLOBAL.BRIC_CAMPAIGN_ID;
    params['action'] = 'splitCampaign';
    const requestOptions = {
      headers: this.headers,
      body: getFormData(params)
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      serviceURL,
      requestOptions
    )
      .map(this.extractData)
      .catch(this.handleError);

  }

  /**
   * @description Init function to populate product with existing filter
   * @author Nishit Parekh
   * @param {any[]} productRows
   * @param {any[]} existingRows
   * @param {number} productDropIndex
   * @param {number} productDropRowIndex
   * @returns
   * @memberof WorkspaceService
   */
  populateProductBric(productRows: Row[], existingRows: Row[],
    productDropIndex: number, productDropRowIndex: number) {
    return new Promise((resolve) => {
      let inchargeBRIC: Cell = null;
      existingRows.forEach((row: Row, rowIndex) => {
        if (row.bric.bricid === this.brickBaseService.brickID.Incharge) {
          row.cells.forEach((bric: Cell, cellIndex) => {
            if (cellIndex === productDropIndex) {
              bric.cellIndex = cellIndex;
              bric.rowIndex = rowIndex;
              inchargeBRIC = bric;
            }
          });
        }
      });

      const resized = existingRows[inchargeBRIC.rowIndex].getOverlappingCell(inchargeBRIC.cellIndex);
      const multipleProductsUnderSameIncharge = this.checkIfMultipleProductsExistsUnderSameIncharge(inchargeBRIC, resized, existingRows);

      if (multipleProductsUnderSameIncharge) {

        const productData = productRows;

        this.insertBlankBricsForProduct(productData, inchargeBRIC, existingRows);
        /// Apply the product Data//
        this.applyProductData(productData, existingRows, inchargeBRIC);

        // Remove all the blank Rows in case if they are any//
        this.removeBlankRows(existingRows);

        // Stretch the Incharge Bric Till the Length of All the Product BRICS
        this.stretchInchargeBRIC(existingRows, inchargeBRIC, resized, productData);
      } else {
        if (resized === -1) {
          this.removeColumnBRICS(existingRows, productDropIndex, false);
        } else {
          const rowAddress = inchargeBRIC.rowIndex;
          inchargeBRIC = existingRows[inchargeBRIC.rowIndex].cells[resized];
          inchargeBRIC.rowIndex = rowAddress;
          const columnsToRemove = inchargeBRIC.cellWidth;
          let inchargeIndex = inchargeBRIC.cellIndex;
          for (let i = 0; i < columnsToRemove; i++) {
            this.removeColumnBRICS(existingRows, inchargeIndex, false);
            inchargeIndex = inchargeIndex + 1;
          }

          // Place the proudct bric exactly below the first column of Incharge Bric if  Inharge BRIC is stretched
          // and Product is placed anywhere withing the range of inchage bric
          this.changeProductBricPosition(productDropIndex, productDropRowIndex, inchargeBRIC, existingRows);
        }

        // Remove all the blank Rows in case if they are any//
        this.removeBlankRows(existingRows);
        existingRows.forEach((row, i) => {
          row.index = i;
          row.cells.forEach(cell => cell.rowIndex = i);
        });

        // Insert Blank Brics To maintain the Matrix//
        this.insertBlankBricsForProduct(productRows, inchargeBRIC, existingRows);

        /// Apply the product Data//
        this.applyProductData(productRows, existingRows, inchargeBRIC);

        // Remove all the blank Rows in case if they are any//
        this.removeBlankRows(existingRows); // Shreni : Removed this function Code handled in above function
      }

      resolve(existingRows);
    });
  }

  /**
   * @description Check if the Product Exists under the Incharge BRIC
   * @author Nishit Parekh
   * @param {Cell} inchargeBRIC
   * @param {number} resizedCellIndex
   * @param {Row[]} rows
   * @returns {boolean}
   * @memberof WorkspaceService
   */
  checkIfMultipleProductsExistsUnderSameIncharge(inchargeBRIC: Cell, resizedCellIndex: number, rows: Row[]): boolean {
    // Multiple products can exists only in case when the incharge is stretched
    let totalNumberOfProductBrics = 0;
    if (resizedCellIndex !== -1) {
      rows.forEach((row: Row) => {
        if (row.bric.bricid === this.brickBaseService.brickID.ProductCatalogue) {
          for (let i = resizedCellIndex; i < (resizedCellIndex + rows[inchargeBRIC.rowIndex].cells[resizedCellIndex].cellWidth); i++) {
            const productBRICAtIndex: Cell = row.cells[i];
            if (
              productBRICAtIndex.isEmpty === false && productBRICAtIndex.isHidden === false) {
              totalNumberOfProductBrics++;
            }
          }
        }
      });
    }
    if (totalNumberOfProductBrics > 1) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * @description insert blank Brics
   * @author Nishit Parekh
   * @param {*} productData
   * @param {Cell} inchargeBRIC
   * @param {*} rows
   * @memberof WorkspaceService
   */
  insertBlankBricsForProduct(productData: Row[], inchargeBRIC: Cell, rows: Row[]) {
    const totalBlankBrics = productData[0].cells.length;
    rows.forEach((row: Row) => {
      let insertIndex = inchargeBRIC.cellIndex + 1; // One blank bric is already there hence add the new ones only.
      if (row.bric.bricid === this.brickBaseService.brickID.Incharge ||
        row.bric.bricid === this.brickBaseService.brickID.ProductCatalogue) {
        // If existing Incharge is streched then make other cells to empty so it will removed by autosupress.
        if (row.bric.bricid === this.brickBaseService.brickID.Incharge && row.cells[inchargeBRIC.cellIndex].cellWidth > 1) {
          for (let i = insertIndex; i < inchargeBRIC.cellIndex + row.cells[inchargeBRIC.cellIndex].cellWidth; i++) {
            row.cells[i].createEmptyCell(false);
          }
        }

        // Creates new empty cell to load product's incharge.
        for (let i = 0; i < totalBlankBrics - 1; i++) {
          const blankBRIC: Cell = _.cloneDeep(row.cells[inchargeBRIC.cellIndex]);
          blankBRIC.createEmptyCell(true);
          blankBRIC.cellIndex = insertIndex;
          row.cells.splice(insertIndex, 0, blankBRIC);
          insertIndex = insertIndex + 1;
        }
        if (!row.cells[inchargeBRIC.cellIndex].isEmpty) {
          row.cells[inchargeBRIC.cellIndex].cellWidth = totalBlankBrics;
        }
        row.cells[inchargeBRIC.cellIndex].isMandatoryForProduct =
          row.bric.bricid === this.brickBaseService.brickID.ProductCatalogue ? true : false;

      } else {
        for (let i = 0; i < totalBlankBrics - 1; i++) {
          const blankBRIC: Cell = _.cloneDeep(row.cells[inchargeBRIC.cellIndex]);
          blankBRIC.createEmptyCell(false);
          blankBRIC.cellIndex = insertIndex;
          row.cells.splice(insertIndex, 0, blankBRIC);
          insertIndex = insertIndex + 1;
        }
      }
    });
  }

  /**
   * @description Merges the product filter with existing filter
   * @author Nishit Parekh, Amit Mahida
   * @param {*} productRows
   * @param {*} existingRows
   * @param {*} inchargeBRIC
   * @memberof WorkspaceService
   */
  applyProductData(productRows: Row[], existingRows: Row[], inchargeBRIC: Cell) {
    productRows.forEach((row: Row) => {
      let neverPopulateBric = false;
      if (row.cells && row.cells.length) {
        if (row.bric.bricid === this.brickBaseService.brickID.Volume) {
          // SM-9434
          // updated below for SM-10181
          neverPopulateBric = row.cells.every(cell => ((cell.selected.impressions === undefined || cell.selected.impressions === 0) && !cell.isEmpty));
          // Updated for SM-10275
          row.cells.forEach((cell) => {
            if ((cell.selected.impressions === undefined || cell.selected.impressions === 0) && !cell.isEmpty) {
              cell['excludeBrick'] = true;
            }
          });
        }
        if (row.bric.bricid === this.brickBaseService.brickID.Budget) {
          row.cells.forEach((cell) => {
            if (cell.selected.price === undefined && !cell.isEmpty) {
              neverPopulateBric = true;
            }
          });
        }
        if (row.bric.bricid === this.brickBaseService.brickID.Objective) {
          neverPopulateBric = row.cells.every(cell => (cell.selected.isAllSelection === true));
          row.cells.forEach((cell) => {
            if (cell.selected.isAllSelection === true) {
              cell['excludeBrick'] = true;
              cell.selected = { ...cell.selected, ...GLOBAL.allSelection };
            }
          });
        }
      }
      if (this.brickBaseService.NEVER_POPULATE_WITH_PRODUCT.indexOf(row.bric.bricid) === -1 && !neverPopulateBric) {
        const productRow: Row = _.cloneDeep(row);
        // Check if the Product Row exists in the Original Items//
        const isRowAvailable = this.isBricRowAvailable(row.bric.bricid, existingRows);
        if (isRowAvailable.Available_row_Index != null) {
          existingRows.forEach((itemRows: Row) => {
            if (productRow.bric.bricid === itemRows.bric.bricid) {
              // Loop on the product brics and replace them in Original Items
              let replaceIndex = this.brickBaseService.singleAudienceBrics.indexOf(productRow.bric.bricid) > -1 ? 0 : inchargeBRIC.cellIndex;
              productRow.cells.forEach((productBRIC: Cell) => {
                if (!productBRIC.selected.hasOwnProperty('-99') && !_.isEmpty(productBRIC.selected)) {
                  const resizedIndex = productRows[productRow.index].getOverlappingCell(productBRIC.cellIndex);
                  if (resizedIndex > -1 && (productRow.cells[resizedIndex].selected.hasOwnProperty('-99') || _.isEmpty(productRow.cells[resizedIndex].selected) ||
                    (Object.keys(productRow.cells[resizedIndex].selected).length === 1 && productRow.cells[resizedIndex].selected.hasOwnProperty('isEditable')))) {

                  } else {
                    const replaceCell = new Cell();
                    replaceCell.updateCellValues(productBRIC);
                    itemRows.cells[replaceIndex] = replaceCell;
                    if (!this.isOptionalMandatoryBRIC(itemRows.bric.bricid) && !this.appHeaderService.enabledPCM) {
                      itemRows.cells[replaceIndex].isMandatoryForProduct = true;
                      itemRows.cells[replaceIndex].isMandatoryEditableForProduct = false;
                    } else if (this.brickBaseService.quantityBrickId.indexOf(itemRows.bric.bricid) > -1 && !itemRows.cells[replaceIndex].selected.hasOwnProperty('-99')) {
                      // If Qty brick is part of product with some value then do not allow to delete it. User can still edit its value
                      itemRows.cells[replaceIndex].isMandatoryEditableForProduct = true;
                    }
                  }
                }
                if (productBRIC.isHidden) {
                  itemRows.cells[replaceIndex].isHidden = productBRIC.isHidden;
                }
                if (productBRIC.cellWidth > 1) {
                  itemRows.cells[replaceIndex].cellWidth = productBRIC.cellWidth;
                }
                replaceIndex = replaceIndex + 1;
              });
            }
          });
        } else {
          // Create a new Row
          this.createNewProductRow(existingRows, productRow, inchargeBRIC, productRows);
        }
      }
    });
  }

  /**
   * @description check if brick row available in filter
   * @author Nishit Parekh
   * @param {*} draggedElementBricId
   * @param {*} rows
   * @returns {boolean} Available lastcell Index and row Index
   * @memberof WorkspaceService
   */
  isBricRowAvailable(draggedElementBricId, rows) {
    const rowObj = {
      Available_row_Index: null,
      Available_lastcell_Index: null,
      Available_First_Cell_Index: null
    };
    if (rows) {
      rows.forEach((row: Row, i) => {
        if (draggedElementBricId === row.bric.bricid) {
          rowObj.Available_row_Index = i;
          let lastUsedCellIndex = 0;
          let firstUsedCellIndex = -1;
          row.cells.forEach((cell, j) => {
            if (cell.isEmpty === false) {
              lastUsedCellIndex = j;
              if (firstUsedCellIndex == -1) {
                firstUsedCellIndex = j;
              }
            }
          });
          rowObj.Available_lastcell_Index = lastUsedCellIndex;
          rowObj.Available_First_Cell_Index = firstUsedCellIndex;
          return rowObj;
        }
      });
    }
    return rowObj;

  }

  /**
   * @description Removes all the blank rows from the DOM
   * @author Nishit Parekh
   * @param {Row[]} rows filter object
   * @memberof WorkspaceService
   */
  removeBlankRows(rows: Row[]) {
    rows.forEach((row: Row, rowIndex) => {
      let counter = 0;
      row.cells.forEach((cell: Cell) => {
        if (cell.isEmpty === true && cell.isHidden === false && cell.cellWidth === 1) {
          counter++;
        }
      });
      if (counter === row.cells.length) {
        rows.splice(rowIndex, 1);
        this.removeBlankRows(rows);
      }
    });
  }

  /**
   * @description  Stretch the Inchar BRIC
   * @author Nishit Parekh
   * @param {*} rows filter array
   * @param {Cell} inchargeBRIC instance of Incharge Bric
   * @param {*} resized if bric is resized
   * @param {*} productData filter object of product
   * @memberof WorkspaceService
   */
  stretchInchargeBRIC(rows: Row[], inchargeBRIC: Cell, resized: number, productData: Row[]) {
    const originalWidth = rows[inchargeBRIC.rowIndex].cells[resized].cellWidth;
    const additionalWidth = productData[0].cells.length - 1;
    const finalWidth = originalWidth + additionalWidth;
    rows.forEach((row: Row, index: number) => {
      if (index === inchargeBRIC.rowIndex) {
        row.cells[resized].cellWidth = finalWidth;
      }
    });
  }

  /**
   * @description Checks if the BRIC is Optional Mandatory
   * @author Nishit Parekh
   * @param {number} bricId
   * @returns {boolean}
   * @memberof WorkspaceService
   */
  isOptionalMandatoryBRIC(bricId: number): boolean {
    let result = false;
    const optionalBrics = this.brickBaseService.OPTIONAL_MANDATORY_BRICS;
    optionalBrics.forEach((id: number) => {
      if (id === bricId) {
        result = true;
      }
    });
    return result;
  }

  /**
   * @description Creates new rows in WorkSpace in case if Product has extra brics that are not there in the workspace
   * @author Nishit Parekh, Amit Mahida
   * @param {Row[]} existingRows
   * @param {Row} productRow
   * @param {Cell} inchargeBRIC
   * @param {Row[]} productRows
   * @memberof WorkspaceService
   */
  createNewProductRow(existingRows: Row[], productRow: Row, inchargeBRIC: Cell, productRows: Row[]) {
    const rowObj = new Row();
    // added missing properties for SM-4804
    const bricObj = new Brick(productRow.bric.bricid, true, productRow.bric.bricname, productRow.bric.color,
      productRow.bric.isprimary, productRow.bric.isUsed, productRow.bric.cellIndex, productRow.index, productRow.bric.audienceCategoryGroupId,
      productRow.bric.categoryid, productRow.bric.categoryName, productRow.bric.expandDirection, productRow.bric.targetRowIndex,
      productRow.bric.disabled, productRow.bric.optionStack, productRow.bric.hide, productRow.bric.optionalForProduct);

    rowObj.bric = bricObj;
    rowObj.index = existingRows.length;
    rowObj.isValid = true;
    rowObj.cells = [];
    const totalBRICSInItems = existingRows ? existingRows[0].cells.length : 0;
    for (let i = 0; i < totalBRICSInItems; i++) {
      rowObj.bric.cellIndex = i;
      const cellObj = new Cell();
      // SM-10275
      cellObj['excludeBrick'] = (productRow.cells && productRow.cells[i] && productRow.cells[i]['excludeBrick']) ? true : false;
      cellObj.cellIndex = i;
      cellObj.rowIndex = existingRows.length;
      cellObj.displayText = '';
      cellObj.isHidden = false;
      cellObj.requestJSON = {};
      cellObj.isLocked = false;
      rowObj.cells.push(cellObj);
    }
    // Copy the Values of Product BRICS in the Items Brics
    let replaceIndex = this.brickBaseService.singleAudienceBrics.indexOf(productRow.bric.bricid) > -1 ? 0 : inchargeBRIC.cellIndex;
    productRow.cells.forEach((productBRIC: Cell) => {
      const resizedIndex = productRows[productRow.index].getOverlappingCell(productBRIC.cellIndex);
      // Impacts ->  SM-5529,SM-5368,SM-5691 //
      if (
        (resizedIndex > -1 && productRow.cells[resizedIndex].selected.hasOwnProperty('-99')) // All selection Stretched
        || (resizedIndex === -1 && productRow.cells[productBRIC.cellIndex].selected.hasOwnProperty('-99')) // All selection single brick
      ) { } else {
        // SM-10275
        if (!productBRIC['excludeBrick']) {
          const replaceCell = new Cell();
          replaceCell.updateCellValues(productBRIC);
          rowObj.cells[replaceIndex] = replaceCell;
          if (!this.isOptionalMandatoryBRIC(rowObj.bric.bricid) && !this.appHeaderService.enabledPCM) {
            rowObj.cells[replaceIndex].isMandatoryForProduct = true;
            rowObj.cells[replaceIndex].isMandatoryEditableForProduct = false;
          } else if (this.brickBaseService.quantityBrickId.indexOf(rowObj.bric.bricid) > -1 && !rowObj.cells[replaceIndex].selected.hasOwnProperty('-99')) {
            // If Qty brick is part of product with some value then do not allow to delete it. User can still edit its value
            rowObj.cells[replaceIndex].isMandatoryEditableForProduct = true;
          }
        }
      }

      replaceIndex = replaceIndex + 1;

    });

    if (rowObj.bric.bricid === this.brickBaseService.brickID.Pattern) {
      const isRowAvailable = this.isBricRowAvailable(this.brickBaseService.brickID.Incharge, existingRows);
      existingRows.splice(isRowAvailable.Available_row_Index + 1, 0, rowObj);
    } else {
      existingRows.push(rowObj);
    }
  }

  /**
   * @description Removes the single column from the particular Index.
   * It will remove the Product and Audience based on the condition.
   * It will always ignores the Incharge row
   * @author Nishit Parekh, Shivani Patel
   * @param {Row[]} rows
   * @param {number} productDropIndex The column index where we creates empty cells
   * @param {boolean} removeProductBRIC If true then it will make the cell empty at productDropIndex for Product row
   * @memberof WorkspaceService
   */
  removeColumnBRICS(rows: Row[], productDropIndex: number, removeProductBRIC = false) {
    // STEP-1 : Remove all the bricks under that column
    const inchargeBRICID = this.brickBaseService.brickID.Incharge;
    for (const row of rows) {
      const isIncharge = row.bric.bricid === inchargeBRICID;
      const isProduct = row.bric.bricid === this.brickBaseService.brickID.ProductCatalogue;
      const isAudience = this.brickBaseService.singleAudienceBrics.indexOf(row.bric.bricid) > -1;
      // While removing the Product, remove the Audiece BRIC Completely//
      if (removeProductBRIC && isAudience) {
        row.cells.forEach((cell) => {
          cell.createEmptyCell(false);
        });
      }
      // while removing the product bric,Leave the InchargeBRIC Row, When the product is added, leave Incharge and Product BRIC row//
      if (removeProductBRIC ? !isIncharge : !isIncharge && !isProduct) {
        row.cells.forEach((bric: Cell, cellIndex) => {
          if (cellIndex === productDropIndex) {
            const overlappingCell = row.getOverlappingCell(cellIndex);
            // When the brick is stretched from productDropIndex
            if (overlappingCell === productDropIndex) {
              // Make the next bric uptodate
              if (!isAudience) {
                const nextBRIC: Cell = row.cells[overlappingCell + 1];
                nextBRIC.updateCellValues(bric);
                nextBRIC.cellWidth = bric.cellWidth - 1;
                bric.createEmptyCell(false);
                bric.isMandatoryForProduct = false;
              }
              // When the brick is stretched from the previous column
            } else if (overlappingCell < productDropIndex && overlappingCell !== -1) {
              // Making the previous bric proper
              const previousBRIC: Cell = row.cells[overlappingCell];
              const totalNUmberOfBRICS = previousBRIC.cellWidth;
              previousBRIC.cellWidth = productDropIndex - overlappingCell;
              // Make the current brick blank
              bric.createEmptyCell(false);
              bric.isMandatoryForProduct = false;
              // Make the next bric uptodate only if it is not the last column
              if (productDropIndex + 1 < totalNUmberOfBRICS) {
                const nextBRIC: Cell = row.cells[productDropIndex + 1];
                nextBRIC.updateCellValues(previousBRIC);
                nextBRIC.cellWidth = totalNUmberOfBRICS - (productDropIndex + 1);
              }
            } else {
              bric.createEmptyCell(false);
              bric.isMandatoryForProduct = false;
            }
          }
        });
      }
    }
  }

  /**
   * @description After Merging Change the Position of Product BRIC, case when the Incharge is Stretched in workSpace
   * and user places the product any where below the Incharge BRIC
   * @author Nishit Parekh
   * @param {number} productDropIndex
   * @param {number} productDropRowIndex
   * @param {Cell} inchargeBRIC
   * @param {*} rows
   * @memberof WorkspaceService
   */
  changeProductBricPosition(productDropIndex: number, productDropRowIndex: number, inchargeBRIC: Cell, rows: Row[]) {
    if (productDropIndex !== inchargeBRIC.cellIndex) {
      const productBRIC: Cell = rows[productDropRowIndex].cells[productDropIndex];
      rows[productDropRowIndex].cells[inchargeBRIC.cellIndex] = _.cloneDeep(productBRIC);
      rows[productDropRowIndex].cells[inchargeBRIC.cellIndex].cellIndex = inchargeBRIC.cellIndex;
      rows[productDropRowIndex].cells[inchargeBRIC.cellIndex].rowIndex = inchargeBRIC.rowIndex;
      productBRIC.createEmptyCell(false);
    }
  }

  /**
   * @description populate productDetails array
   * @author Amit Mahida
   * @param {Row[]} productRows New Product rows
   * @param {Row[]} currentRows Existing rows
   * @param {ProductHelper[]} productDetails Existing product details
   * @param {number} idProductCatalogue new product id
   * @param {number} cellIndex Column on which new product is added
   * @returns {ProductHelper[]}
   * @memberof WorkspaceService
   */
  setProductData(productRows: Row[], currentRows: Row[], productDetails: ProductHelper[], idProductCatalogue: number, cellIndex?: number): ProductHelper[] {
    const product: ProductHelper = new ProductHelper();
    product.idProductCatalogue = idProductCatalogue;
    const productRow = currentRows.find((row: Row) => {
      return row.bric.bricid === this.brickBaseService.brickID.ProductCatalogue;
    });
    if (productRows) {
      product.productWidth = productRows[0].cells.length;

      if (!_.isUndefined(cellIndex)) {
        // Case when new product is added on left side
        for (const productDetail of productDetails) {
          if (productDetail.columnIndex > cellIndex) {
            productDetail.columnIndex = cellIndex + product.productWidth;
            const newValidation = {};
            for (const key in productDetail.validation) {
              if (productDetail.validation.hasOwnProperty(key)) {
                newValidation[Number(key) + cellIndex + product.productWidth - 1] = productDetail.validation[key];
              }
            }
            productDetail.validation = newValidation;
            const newOptional = {};
            for (const key in productDetail.optional) {
              if (productDetail.optional.hasOwnProperty(key)) {
                newOptional[Number(key) + cellIndex + product.productWidth - 1] = productDetail.optional[key];
              }
            }
            productDetail.optional = newOptional;
          }
        }
      }

      const products: Cell[] = productRow.cells.filter(ele => ele.selected.idProductCatalogue === idProductCatalogue);

      if (products && products.length === 1) {
        product['columnIndex'] = products[0].cellIndex;
      } else {
        for (const e of products) {
          if (productDetails.length === 0) {
            product['columnIndex'] = e.cellIndex;
            break;
          }
          for (const p of productDetails) {
            if (e.cellIndex !== p.columnIndex && productDetails.filter(ele => ele.columnIndex === e.cellIndex).length === 0
              && e['indexBeforeDelete'] !== p.columnIndex) {
              product['columnIndex'] = e.cellIndex;
              break;
            }
          }
          if (product['columnIndex']) {
            break;
          }
        }
      }

      for (const rowNumber in productRows) {
        const row = parseInt(rowNumber, 10);
        const bricId = productRows[row].bric.bricid;
        for (let productCellIndex = 0; productCellIndex < productRows[row].cells.length; productCellIndex++) {
          if (!product.validation[productCellIndex + product['columnIndex']]) {
            product.validation[productCellIndex + product['columnIndex']] = {};
            product.optional[productCellIndex + product['columnIndex']] = [];
          }

          const inUseByResizable = productRows[row].getOverlappingCell(productCellIndex);
          const requestJson = inUseByResizable > -1 ?
            productRows[row].cells[inUseByResizable].requestJSON : productRows[row].cells[productCellIndex].requestJSON;
          const cellKeys = Object.keys(requestJson).sort();
          let cellLastKey = cellKeys[cellKeys.length - 1];
          if (cellLastKey === 'selectionCriteriaRestriction') {
            cellLastKey = 'selectionCriteriaFormat';
          }
          const selectionCriteria = requestJson[cellLastKey];

          if ([
            this.brickBaseService.brickID.SOT,
            this.brickBaseService.brickID.Incharge,
            this.brickBaseService.brickID.Volume,
            this.brickBaseService.brickID.Budget,
            this.brickBaseService.brickID.PricingTag,
            this.brickBaseService.brickID.FrameQuality,
            this.brickBaseService.brickID.MultiTarget
          ].indexOf(bricId) > -1 && selectionCriteria) {
            product.validation[productCellIndex + product['columnIndex']][bricId] = requestJson[cellLastKey];
          }
          if (selectionCriteria) {
            const exists = Object.keys(selectionCriteria).some((k) => {
              return selectionCriteria[k] && selectionCriteria[k][k] === parseInt(k, 10);
            });
            let checkForChannel = false;
            if (!_.isUndefined(selectionCriteria[0]) && !_.isUndefined(selectionCriteria[0][1])) {
              checkForChannel = selectionCriteria[0][1].hasOwnProperty(-99);
            }
            let checkForList = false;
            if (!_.isUndefined(selectionCriteria[0]) && selectionCriteria[0].userSelectionId === -99) {
              checkForList = true;
            }
            let checkForMultiTarget = false;
            if (!_.isUndefined(selectionCriteria[0]) && selectionCriteria[0].userSelectionId === -99) {
              checkForMultiTarget = true;
            }
            let checkForProximity = false;
            if (!_.isUndefined(selectionCriteria.userSelectionIds) && selectionCriteria.userSelectionIds[0] === -99) {
              checkForProximity = true;
            }
            let checkForPricingTag = false;
            // added AND conditions for SM-3588
            if (selectionCriteria.length > 0 && selectionCriteria[0] && selectionCriteria[0][1] && selectionCriteria[0][1]['-99'] === -99) {
              checkForPricingTag = true;
            }

            let checkForFrameQuality = false;
            if (Object.keys(selectionCriteria).length === 1 && !selectionCriteria.hasOwnProperty('audienceLevel') && selectionCriteria.hasOwnProperty('isEditable')) {
              checkForFrameQuality = true;
            }

            let checkForVolume = false;
            if (!_.isUndefined(selectionCriteria.minImpressions) && !_.isUndefined(selectionCriteria.maxImpressions) && _.isUndefined(selectionCriteria.impressions)) {
              checkForVolume = true;
            }

            let checkForBudget = false;
            if (!_.isUndefined(selectionCriteria.minPrice) && !_.isUndefined(selectionCriteria.maxPrice) && _.isUndefined(selectionCriteria.price)) {
              checkForBudget = true;
            }

            if (exists || this.brickBaseService.OPTIONAL_MANDATORY_BRICS.indexOf(bricId) > -1
              || checkForChannel || checkForList || checkForProximity || checkForPricingTag || checkForFrameQuality || checkForMultiTarget || checkForVolume || checkForBudget) {
              product.optional[productCellIndex + product['columnIndex']].push(bricId);
            }

            if (cellLastKey === 'secondaryAudience' && selectionCriteria.hasOwnProperty('audienceCategoryGroupId')) {
              product.optional[productCellIndex + product['columnIndex']].push(bricId);
            }

            if ((cellLastKey === 'selectionAudience' ||
              cellLastKey === 'selectionAudienceReduction' || cellLastKey === 'selectionFrameReduction' ||
              cellLastKey === 'selectionPrimaryAudiences') &&
              (!requestJson[cellLastKey] || Object.keys(requestJson).length === 0 || (cellLastKey === 'selectionFrameReduction' && !requestJson[cellLastKey].frameCount))) { // SM-8224
              product.optional[productCellIndex + product['columnIndex']].push(bricId);
            } else if (cellLastKey === 'selectionFrameReduction' || (cellLastKey === 'selectionAudienceReduction' &&
              (!requestJson[cellLastKey].hasOwnProperty('-99') && !_.isUndefined(requestJson[cellLastKey].maxImpressions) && !_.isUndefined(requestJson[cellLastKey].minImpressions) && (!_.isUndefined(requestJson[cellLastKey].impressions) && _.isUndefined(Number(requestJson[cellLastKey].impressions) != 0)))) ||
              (cellLastKey === 'selectionBudget' && (!requestJson[cellLastKey].hasOwnProperty('-99') && !_.isUndefined(requestJson[cellLastKey].maxPrice) && !_.isUndefined(requestJson[cellLastKey].minPrice) && !_.isUndefined(requestJson[cellLastKey].price)))) {
              // VJ: Need to remove frame brick id from optional if frameCount value is present, frame count is present so frame brick is no more optional
              // frame brick id is inserted as part of OPTIONAL_MANDATORY_BRICS
              // SM-9227
              // DT: 7th Dec 2021
              product.optional[productCellIndex + product['columnIndex']].splice(product.optional[productCellIndex + product['columnIndex']].indexOf(bricId), 1);
            }

            if (cellLastKey === 'selectionPattern') {
              const selectionPattern = {};
              selectionPattern['allowDayParts'] = _.isUndefined(selectionCriteria.allowDayParts) ? false : selectionCriteria.allowDayParts;
              selectionPattern['allowDays'] = _.isUndefined(selectionCriteria.allowDays) ? false : selectionCriteria.allowDays;
              selectionPattern['allowHours'] = _.isUndefined(selectionCriteria.allowHours) ? false : selectionCriteria.allowHours;

              product.validation[productCellIndex + product['columnIndex']][bricId] = selectionPattern;
            }

            if (cellLastKey === 'selectionPrimaryAudiences' && Object.keys(requestJson[cellLastKey]).length === 0) {
              product.optional[productCellIndex + product['columnIndex']].push(bricId);
            }
          }
        }
      }

      if (!_.isUndefined(cellIndex)) {
        productDetails.splice(cellIndex, 0, product);
      } else {
        productDetails.push(product);

      }
    } else {
      console.log('Invalid Product Data');
    }

    const cell = productRow.cells[product.columnIndex];
    const explodedColumn = cell.requestJSON && cell.requestJSON.selectionProductCatalogue ? cell.requestJSON.selectionProductCatalogue.explodedColumn : {};
    if (explodedColumn && Object.keys(explodedColumn).length) {
      for (const key in explodedColumn) {
        if (explodedColumn.hasOwnProperty(key)) {
          const noOfColIncreased = explodedColumn[key];
          productDetails = this.pcmService.updateProductValidationsOnExplode(Number(key), productDetails, noOfColIncreased);
        }
      }
    }

    return _.cloneDeep(productDetails);
  }

  removeProductBRIC(rows: Row[], dragRowIndex: number, dragCellIndex: number) {

    const uiControl: UiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    if (uiControl.pendoEnabled) {
      ConfigUtils.manuallyPushEventsToPendo('Removing Product', {
        'RowIndex': dragRowIndex,
        'CellIndex': dragCellIndex,
        'Type': 'Product',
        'ProductName': rows[dragRowIndex].cells[dragCellIndex].selected.productCatalogueName,
        'ProductId': rows[dragRowIndex].cells[dragCellIndex].selected.productCatalogueName,
        'Group': 'Workspace',
      });
    }
    // productDetails = productDetails.filter(product => product.columnIndex !== dragCellIndex);
    // Get the ProductCatalogue  BRIC//
    let productCatalogue: Cell = null;
    rows.forEach((row: Row, rowIndex) => {
      if (row.bric.bricid === this.brickBaseService.brickID.ProductCatalogue) {
        row.cells.forEach((bric: Cell, cellIndex: number) => {
          if (cellIndex === dragCellIndex) {
            bric.cellIndex = cellIndex;
            bric.rowIndex = rowIndex;
            productCatalogue = bric;
          }
        });
      }
    });

    // Remove all the brics under the ProductCatalogue BRIC
    const resized = rows[productCatalogue.rowIndex].getOverlappingCell(productCatalogue.cellIndex);
    if (resized === -1) {
      this.removeColumnBRICS(rows, dragCellIndex, true);
    } else {
      const rowAddress = productCatalogue.rowIndex;
      productCatalogue = rows[rowAddress].cells[resized];
      productCatalogue.rowIndex = rowAddress;
      const columnsToRemove = productCatalogue.cellWidth;
      let inchargeIndex = productCatalogue.cellIndex;
      for (let i = 0; i < columnsToRemove; i++) {
        this.removeColumnBRICS(rows, inchargeIndex, true);
        inchargeIndex = inchargeIndex + 1;
      }
    }
    // Remove all the blank Rows in case if they are any//
    this.removeBlankRows(rows);
  }

  /**
   * @description This will count number of used and visible brics and return details
   * @author Nishit Parekh
   * @param {Row[]} rows filter array
   * @param {(string | number)} brickId brick Id
   * @returns {any[]}
   * @memberof WorkspaceService
   */
  // getBrickCountAndDetails(rows: Row[], brickId: string | number): any[] {

  getAudienceCategoryGroupId(audienceCategoryId: number | string) {
    const audienceCategoryGroup = this.dataShareService.getInitialConfigByKey('audienceCategoryGroup');
    if (audienceCategoryId) {
      for (const audienceCategoryG of audienceCategoryGroup) {
        for (const audienceCategoryT of audienceCategoryG.audienceCategoryType) {
          for (const audienceCategoryObj of audienceCategoryT.audienceCategory) {
            if (audienceCategoryObj.audienceCategoryId) {
              if (audienceCategoryObj.audienceCategoryId === audienceCategoryId) {
                return audienceCategoryG.audienceCategoryGroupId;
              }
            }
          }
        }
      }
    } else {
      return null;
    }
  }

  deleteValueFromRequestJson(cell: Cell, brickID: number, selectionId?, idValue?) {
    switch (brickID) {
      case this.brickBaseService.brickID.Format:
        if (cell.requestJSON.selectionCriteriaFormat[selectionId] && idValue > 0) {
          delete cell.requestJSON.selectionCriteriaFormat[selectionId][idValue];
        }
        if (_.isEmpty(cell.requestJSON.selectionCriteriaFormat[selectionId])) {
          delete cell.requestJSON.selectionCriteriaFormat[selectionId];
        }
        break;
      case this.brickBaseService.brickID.Location:
        if (cell.requestJSON.selectionCriteriaLocation[selectionId] && idValue > 0) {
          delete cell.requestJSON.selectionCriteriaLocation[selectionId][idValue];
        }
        if (_.isEmpty(cell.requestJSON.selectionCriteriaLocation[selectionId])) {
          delete cell.requestJSON.selectionCriteriaLocation[selectionId];
        }
        break;
      case this.brickBaseService.brickID.Environment:
        if (cell.requestJSON.selectionCriteriaChannel[selectionId] && idValue > 0) {
          delete cell.requestJSON.selectionCriteriaChannel[selectionId][idValue];
        }

        if (_.isEmpty(cell.requestJSON.selectionCriteriaChannel[selectionId])) {
          delete cell.requestJSON.selectionCriteriaChannel[selectionId];
        }
        break;
      case this.brickBaseService.brickID.Channel:
        if (cell.requestJSON.selectionCriteriaProduct[selectionId] && idValue > 0) {
          delete cell.requestJSON.selectionCriteriaProduct[selectionId][idValue];
        }

        if (_.isEmpty(cell.requestJSON.selectionCriteriaProduct[selectionId])) {
          delete cell.requestJSON.selectionCriteriaProduct[selectionId];
        }
        break;
      case this.brickBaseService.brickID.Audience:
        delete cell.requestJSON.selectionAudience;
        cell.requestJSON = {
          columns: [],
          row: null
        };
        break;
      case this.brickBaseService.brickID.SecondaryAudience:
        delete cell.requestJSON.secondaryAudience;
        cell.requestJSON = {
          columns: [],
          row: null
        };
        break;
      default:
        break;

    }
  }

  /**
   * @description validates all bricks against column configs
   * @author Amit Mahida
   * @param {Filter} filter
   * @param {ColumnConfig} columnConfigs
   * @returns {Filter}
   * @memberof WorkspaceService
   */
  validateBricksAgainstColumnConfig(filter: Filter, columnConfigs: ColumnConfigs): Filter {
    let foundInvalidBricks = false;
    const userBundle = this.dataShareService.getInitialConfigByKey('userBundle');

    // SM-7014, Validation should not apply to past columns
    // Sometime old used values of past columns are disabled/deleted in database for some reason and so not coming in column config
    // Discussed with Yen and JP

    const allDates = this.getDatesFormAllIncharge(filter.rows);

    // populated in  search controller
    const lookupColumn: LookupColumn[] = this.dataShareService.getInitialConfigByKey('lookupColumn');

    for (const row of filter.rows) {
      for (const cell of row.cells) {
        let dateForCurrentColumn = allDates[row.cells.indexOf(cell)];
        let currentIndex = row.cells.indexOf(cell);
        if (!dateForCurrentColumn) {
          while (currentIndex > -1 && !dateForCurrentColumn) {
            dateForCurrentColumn = allDates[currentIndex];
            currentIndex--;
          }
        }
        if ((dateForCurrentColumn && dateForCurrentColumn.endDate < new Date()) || row.bric.bricid === this.brickBaseService.brickID.Format) { //ignoring the validation for format bric SM-11991
          continue;
        }

        cell.isValid = true;

        let isValidBrick = true;
        let tagData = null;
        const allowedSelectionID = this.dataShareService.getSelectionIdsForCell(lookupColumn, row.bric.bricid);
        if (Object.keys(columnConfigs).length > 0) {
          const columnConfig = columnConfigs[cell.cellIndex];
          if (row.bric.bricid === this.brickBaseService.brickID.Audience || row.bric.bricid === this.brickBaseService.brickID.PrimaryAudience
            || row.bric.bricid === this.brickBaseService.brickID.SecondaryAudience) {
            if (row.bric.bricid === this.brickBaseService.brickID.Audience) {
              const audienceCategoryGroupId = this.getAudienceCategoryGroupId(row.cells[0].selected['audienceCategoryId']);
              if (audienceCategoryGroupId !== null
                && columnConfig && columnConfig.audienceCategoryGroupId !== audienceCategoryGroupId) {
                isValidBrick = false;
                this.deleteValueFromRequestJson(cell, row.bric.bricid);
                cell.selected = {};
              }
            }

            if (row.bric.bricid === this.brickBaseService.brickID.SecondaryAudience) {
              const audienceCategoryGroupId = this.getAudienceCategoryGroupId(row.cells[0].selected['audienceCategoryId']);
              if (audienceCategoryGroupId != null
                && columnConfig && columnConfig.secondaryAudience.indexOf(audienceCategoryGroupId) === -1) {
                isValidBrick = false;
              }
            }
            if (row.bric.bricid === this.brickBaseService.brickID.PrimaryAudience) {
              const audienceCategoryGroupId = this.getAudienceCategoryGroupId(Object.keys(cell.selected)[0]);
              if (audienceCategoryGroupId != null && columnConfig && columnConfig.audienceCategoryGroupId !== audienceCategoryGroupId) {
                isValidBrick = false;
                cell.selected = {};
              }
            }

          }
          if (row.bric.bricid === this.brickBaseService.brickID.Tag) {
            // SM-7365
            if (row.cells[row.cells.indexOf(cell)].isEmpty) {
              continue;
            }
            const tagGroup = this.dataShareService.getInitialConfigByKey('tagGroup');
            tagData = this.sharedService.getTagDataFromColumnConfig(row.bric.bricid, columnConfig, _.cloneDeep(tagGroup))
            if (tagData.length === 0) {
              isValidBrick = false;
            }
          }
          // go through each selected values and check it against column config
          for (const key in cell.selected) {
            if (cell.selected.hasOwnProperty(key) && row.bric.bricid !== this.brickBaseService.brickID.PrimaryAudience) {
              const selected = (row.bric.bricid === this.brickBaseService.brickID.Location) ? JSON.parse(JSON.stringify(cell.selected[key])) : cell.selected[key];
              const isTagBrick = row.bric.bricid === this.brickBaseService.brickID.Tag && key == 'allTabSelection';
              // selected must be a numeric value as its selectionId
              if (!isNaN(parseFloat(key)) && isFinite(parseFloat(key))) {
                // Check if current selection id is in allowed list, if not then tab is not at all allowed
                const res = new List<any>(allowedSelectionID)
                  .Where((x) => {
                    return (x.toString() === key.toString());
                  }).Select((x) => {
                    return x;
                  }).ToArray();
                // Current SelectionId is not allowed
                if (res.length === 0) {
                  delete cell.selected[key]; // Removing current selection id
                  isValidBrick = false;
                } else {
                  // SelectionId is allowed, now check for allowed values inside this selectionID
                  const allowedValues = [];
                  if (selected && selected[0] && selected[0].openRateCard) {
                    // SM-9077: RateCard feature
                    if (columnConfig.rateCardLookupData !== undefined) {
                      const rateCardLookupData = Object.keys(columnConfig.rateCardLookupData);
                      for (const rateCard of rateCardLookupData) {
                        allowedValues.push(parseInt(rateCard, 10));
                      }
                    }
                  } else {
                    if (columnConfig.lookupData[key] !== undefined) {
                      const lookupData = Object.keys(columnConfig.lookupData[key])
                      for (const lookup of lookupData) {
                        allowedValues.push(parseInt(lookup, 10));
                      }
                    }
                  }

                  if (allowedValues.length) {
                    // SelectionID is available, now check allowed values
                    if (selected.length > 0) {
                      let removeIncorrectValues = 0;
                      for (let i = selected.length - 1; i >= 0; i--) {
                        const objSelected = selected[i];
                        const resAllowed = new List<any>(allowedValues)
                          .Where((x) => {
                            if (typeof (x) === 'object') {
                              return (parseInt(x.Key, 10) === (objSelected.id || objSelected.productId || objSelected.lookupId));
                            } else {
                              return (x === (objSelected.id || objSelected.productId || objSelected.lookupId || objSelected.networkId));
                            }
                          }).Select((x) => {
                            return x;
                          }).ToArray();

                        if (resAllowed.length === 0) { // Value is not allowed
                          // Highlight brick
                          removeIncorrectValues++; // commenting above code for SBRICS-1874, Nishit
                          const selectedId = objSelected.id || objSelected.lookupId || objSelected.productId || objSelected.networkId;
                          this.deleteValueFromRequestJson(cell, row.bric.bricid, selected, selectedId);
                          selected.splice(i, 1);
                          if(row.bric.bricid === this.brickBaseService.brickID.Location){
                            cell.selected[key] = JSON.parse(JSON.stringify(selected));
                          }
                          // below changes for SM-4227
                          const lookup = this.sharedService.getLookupColumnData(row.bric.bricid, null);
                          cell.displayText = this.cellAttributeService.getDisplayText(row.bric.bricid, cell.selected);
                          cell.requestJSON = this.cellAttributeService.getBrickRequestJSON(row.bric.bricid, cell.selected);
                          cell.toolTipText = this.cellAttributeService.getToolTip(row.bric.bricid, cell.selected, lookup.lookup);
                        } else {
                          if (selected && selected[0] && selected[0].openRateCard && cell.selected[key] && cell.selected[key][0]) {
                            cell.selected[key][0].frameData = columnConfig.rateCardLookupData[resAllowed[0]];
                            if (cell.requestJSON && cell.requestJSON.selectionCriteriaNetwork && cell.requestJSON.selectionCriteriaNetwork[0] && cell.requestJSON.selectionCriteriaNetwork[0][1]) {
                              cell.requestJSON.selectionCriteriaNetwork[0][1] = {};
                              cell.requestJSON.selectionCriteriaNetwork[0][1][resAllowed[0]] = cell.selected[key][0].frameData[2];
                            }
                          }
                        }
                      }
                      if (removeIncorrectValues && this.dataShareService.appName === AppNameEnum.workspace) {
                        this.logHelperService.logInfo(userBundle['workspace.error.removeInvalidBrics']);
                      }
                    }
                  } else if (allowedValues.length && selected.length > 0
                    && (row.bric.bricid === this.brickBaseService.brickID.Location
                      || row.bric.bricid === this.brickBaseService.brickID.Format)) {
                    // changed else if condition for SM-1771, Nishit
                    cell.selected[key] = [];
                  }
                }
              } else if (isTagBrick && tagData.length > 0) {
                let removeIncorrectValues = 0;
                for (const subKey in cell.selected[key]) {
                  const mathcedTag = _.find(tagData, { 'tagGroupId': parseInt(subKey) });
                  if (mathcedTag === undefined) {
                    removeIncorrectValues++;
                    delete cell.selected[key][subKey];
                    const lookup = this.sharedService.getLookupColumnData(row.bric.bricid, null);
                    cell.displayText = this.cellAttributeService.getDisplayText(row.bric.bricid, cell.selected);
                    cell.requestJSON = this.cellAttributeService.getBrickRequestJSON(row.bric.bricid, cell.selected);
                    cell.toolTipText = this.cellAttributeService.getToolTip(row.bric.bricid, cell.selected, lookup.lookup);
                    continue;
                  }
                  for (let i = 0; i < cell.selected[key][subKey].length; i++) {
                    const mathcedTagData = _.find(mathcedTag.tag, { 'tagId': cell.selected[key][subKey][i].tagId });
                    if (mathcedTagData === undefined) {
                      removeIncorrectValues++;
                      cell.selected[key][subKey].splice(i, 1);
                      const lookup = this.sharedService.getLookupColumnData(row.bric.bricid, null);
                      cell.displayText = this.cellAttributeService.getDisplayText(row.bric.bricid, cell.selected);
                      cell.requestJSON = this.cellAttributeService.getBrickRequestJSON(row.bric.bricid, cell.selected);
                      cell.toolTipText = this.cellAttributeService.getToolTip(row.bric.bricid, cell.selected, lookup.lookup);
                      i--;
                    }
                  }
                }
                if (removeIncorrectValues && this.dataShareService.appName === AppNameEnum.workspace) {
                  this.logHelperService.logInfo(userBundle['workspace.error.removeInvalidBrics']);
                }
              }
            }
          }

          if (!isValidBrick) {
            if (row.bric.bricid === this.brickBaseService.brickID.Audience
              || row.bric.bricid === this.brickBaseService.brickID.SecondaryAudience) {
              row.cells[0].isValid = false;
            } else {
              cell.isValid = false;
            }
            row.isValid = false;
            foundInvalidBricks = true;
          }

        }
      }
    }
    if (foundInvalidBricks) {
      SystemFlags.incorrectSolution = true;
      filter.stateService.updateWorkspaceValidity(!SystemFlags.incorrectSolution);
      this.dataShareService.activateResultTab(false);
      this.dataShareService.activateCommercialTab(false);
      filter.stateService.clearProcessResponse();
    } else {
      SystemFlags.incorrectSolution = false;
      filter.stateService.updateWorkspaceValidity(!SystemFlags.incorrectSolution);
    }
    return filter;
  }

  /**
   * @description this fn will create, format array of all dates selected on workspace
   * @author Nishit Parekh
   * @param {*} filterRows
   * @returns {DateRange[]}
   * @memberof WorkspaceService
   */
  getDatesFormAllIncharge(filterRows: Row[]): DateRange[] {
    const previousBricksDates: DateRange[] = [];
    const rows: Row[] = filterRows;
    const inchargeRow: Row = rows.find((e: Row) => e.bric.bricid === this.brickBaseService.brickID.Incharge);
    if (inchargeRow) {
      inchargeRow.cells.forEach((cell: Cell) => {
        if (!cell.isEmpty && !cell.isHidden) {
          const dates: DateRange = {
            ...cell.selected
          };
          previousBricksDates.push(dates);
        } else {
          previousBricksDates.push(null);
        }
      });
    }
    return previousBricksDates;
  }

  /**
   * @description
   * @author Nishit Parekh, Shivani Patel
   * @param {*} selectionPeriod Selected start and end date
   * @param {DateRange[]} previousBricksDates Array of existing Incharge brics
   * @param {Brick} brick
   * @returns Array of dates i.e number of brics to be created and Array of range to replace existing brics with it's cellIndex
   * @memberof WorkspaceService
   */
  allDatesProcessing(selectionPeriod, previousBricksDates: DateRange[], brick: Brick, items: Row[]): {
    bricksToPush: DateRange[],
    bricToReplace: DateRange[]
  } {
    const uiControl: UiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    let bricToReplace: DateRange[] = [];
    const bricksToPush: DateRange[] = [];

    if (uiControl.overlappingRange && !SystemFlags.isRangeSelectionMandatory) {
      return {
        bricksToPush,
        bricToReplace
      };
    }

    let allBricksSelectedDate: DateRange[] = [...previousBricksDates];

    let isEditMode = false;
    if (allBricksSelectedDate[brick.cellIndex]) {
      isEditMode = true;
      allBricksSelectedDate.splice(brick.cellIndex, 1);
    }

    allBricksSelectedDate = allBricksSelectedDate.filter(item => item !== null);

    if (allBricksSelectedDate.length) {
      allBricksSelectedDate.sort(this.compareStartDates.bind(this));
      let currentRange = { ...selectionPeriod };
      for (const selectedDate of allBricksSelectedDate) {
        const pos = this.splitInchargeIfOverlappedByExisting({
          startDate: selectedDate.startDate,
          endDate: selectedDate.endDate
        }, currentRange);

        currentRange = pos.data.pop();
        // If position is right then need to check all the brics
        if (pos.position === 1) {
          continue;
        }
        // If position is left then not need to check next brics
        if (pos.position === -1) {
          break;
        }
        bricksToPush.push(...pos.data);
      }
      bricksToPush.push(currentRange);
    }
    // Issue 46 from sheet
    const cell = items[brick.rowIndex] && items[brick.rowIndex].cells[brick.cellIndex];
    if ((isEditMode || (cell && cell.isEmpty && !cell.isHidden)) && bricksToPush.length) {
      bricToReplace = bricksToPush.splice(0, 1);
    }

    return {
      bricksToPush,
      bricToReplace
    };
  }

  /**
   * @description Compare new and existing Incharge brics. Split a new bric if it's date range has overlapped by date range of existing Incharge bric.
   * Returns splitted brics and It's position
   * @author Shivani Patel
   * @param {DateRange} existing
   * @param {DateRange} newSelected
   * @returns
   * @memberof WorkspaceService
   */
  splitInchargeIfOverlappedByExisting(existing: DateRange, newSelected: DateRange) {

    // Issue 46 from sheet
    existing.startDate = new Date(existing.startDate);
    existing.endDate = new Date(existing.endDate);

    //       [*--]  Existing
    // [---*]       New
    // If new bric is Left of Existing one
    if (newSelected.endDate < existing.startDate) {
      return {
        data: [newSelected],
        position: -1
      };
    }

    // [--*]        Existing
    //      [*---]  New
    // If new bric is Right of Existing one
    if (existing.endDate < newSelected.startDate) {
      return {
        data: [newSelected],
        position: 1
      };
    }

    /**
     * -1: Left
     * 0: Splitted by Existing Incharge
     * 1: Right
     */
    let position = 0;
    const data: DateRange[] = [];

    //    [*-]       [*-]        [*-----]  Existing
    // [*-|---   [*--|-----   [*-|--       New
    // Cut new bric from start date of existing bric and set position as left
    if (newSelected.startDate < existing.startDate) {
      data.push({
        startDate: new Date(newSelected.startDate),
        endDate: new Date(
          existing.startDate.getFullYear(),
          existing.startDate.getMonth(),
          existing.startDate.getDate() - 1
        )
      });
      position = -1;
    }

    // [-*]        [-*]       [----*]      Existing
    // ---|-*]   -----|---*]     ---|-*]   New
    // Cut new bric from end date of existing bric and set position as right
    if (existing.endDate < newSelected.endDate) {
      data.push({
        startDate: new Date(
          existing.endDate.getFullYear(),
          existing.endDate.getMonth(),
          existing.endDate.getDate() + 1
        ),
        endDate: new Date(newSelected.endDate),
      });
      position = 1;
    }

    return {
      data,
      position: data.length > 1 ? 0 : position
    };
  }

  /**
   * @description sort previousBricksDates by start date
   * @author Nishit Parekh
   * @param {DateRange} a
   * @param {DateRange} b
   * @returns {number}
   * @memberof WorkspaceService
   */
  compareStartDates(a: DateRange, b: DateRange): number {
    const astartdate = this.datePipe.transform(a.startDate, GLOBAL.DATE_PARSE_FORMAT);
    const bstartdate = this.datePipe.transform(b.startDate, GLOBAL.DATE_PARSE_FORMAT);
    if (astartdate < bstartdate) {
      return -1;
    }
    if (astartdate > bstartdate) {
      return 1;
    }
    return 0;
  }

  /**
   * @description returns true if brick is allowed to stretched on product catelogue
   * @author Amit Mahida
   * @param {number} colIndex
   * @param {ProductHelper[]} productDetails
   * @returns {boolean}
   * @memberof WorkspaceService
   */
  isBrickAllowedToMoveOrStretch(colIndex: number, productDetails: ProductHelper[]) {
    let allowBrickToMove = true;
    if (!_.isUndefined(colIndex)) {
      if (productDetails.length > 0) {
        for (const element of productDetails) {
          if (element.optional.hasOwnProperty(colIndex)) {
            allowBrickToMove = false;
            return allowBrickToMove;
          }
        }
      }
    }
    return allowBrickToMove;
  }

  /**
   * @description It will retun object which contains validations/range to select values
   * @author Nishit Parekh
   * @param {number} col column Index
   * @param {ProductHelper[]} productDetails
   * @param {number} brickId brick id for which we need to show validation
   * @returns {Object}
   * @memberof WorkspaceService
   */
  // getProductValidations(col: number, productDetails: ProductHelper[], brickId: number): Object {
  //   const product = productDetails.filter((item) => {
  //     return item.columnIndex === col;
  //   });
  //   if (product.length > 0) {
  //     return product[0].validation[col][brickId];
  //   } else {
  //     return null;
  //   }
  // }

  setDefaultPanning() {
    this.defaultPanningSub.next(true);
  }

  /**
    * @description Function to calcualte the Ceiling based on the formula
    * @author Shreni Shah
    * @param {*} floorCeilingPrecision - we get this form the Initial Config
    * @param {*} targeted - value of Price/Impression added by user in Budget/Impression Bric
    * @param {*} sotSwing - we get this from the Initial Config
    * @param {*} productValidations - validations for the Product if product is used
  */
  computeCeilingFromFormula(floorCeilingPrecision: number, sotSwing: number, targeted: number, productValidations) {
    /* CEILING = MIN(FLOOR + 2 * SOT Swing, MAX value specified in PCM (if PCM used) )*/
    const computation = parseFloat((targeted + 2 * sotSwing).toFixed(floorCeilingPrecision));
    const ceiling = computation > 100 ? 100 : computation;
    if (productValidations && Object.keys(productValidations).length && productValidations.sotCeiling) {
      let pcmCeiling = null;
      pcmCeiling = productValidations.sotCeiling;
      return Math.min(ceiling, pcmCeiling);
    } else {
      return ceiling;
    }
  }

  /**
  * @description Function to calcualte the Floor based on the formula
  * @author Shreni Shah
  * @param {*} floorCeilingPrecision - we get this form the Initial Config
  * @param {*} targeted - value of Price/Impression added by user in Budget/Impression Bric
  * @param {*} sotSwing - we get this from the Initial Config
  * @param {*} productValidations - validations for the Product if product is used
  * @param {*} columnSummary - columnSummary to get the Allocated Price/Impression
  * @param {*} allocatedKey - Key that is used to identify which value to be taken from columnSummary
    */
  computeFloorFromFormula(floorCeilingPrecision: number, targeted: number, sotSwingValue: number, productValidations: any, columnSummary: ColumnSummary, allocatedKey: string) {
    /* FLOOR = MAX(1, [Y/X * avg(default SOT) - SOT swing] , MIN value specified in PCM (if PCM used) )*/
    /*  Y = Targeted Impressions / Price
        X = Allocated Impressions / Price
    */
    const Y = targeted;
    let X = 0;
    let totalSot = 0;
    let totalSpans = 0;
    if (columnSummary) {
      for (const key in columnSummary) {
        if (columnSummary[key]) {
          X += columnSummary[key][allocatedKey];
          totalSot += columnSummary[key].totalSot;
          totalSpans += columnSummary[key].totalSpans;
        }
      }
    }

    const averageSot = totalSpans === 0 ? 0 : totalSot / totalSpans;
    const sotSwing = sotSwingValue;
    const computation = parseFloat(((Y / X * (averageSot)) - sotSwing).toFixed(floorCeilingPrecision));
    const calculateFloor = computation > 100 ? 100 : computation;
    let pcmFloor = null;
    if (productValidations && Object.keys(productValidations).length && productValidations.sotFloor) {
      pcmFloor = productValidations.sotFloor;
    }
    const finalFloorValue = Math.max(1, isNaN(calculateFloor) ? null : calculateFloor, pcmFloor);
    // Case when the Calcualted Floor is Greater than Ceiling, then consider the Products Ceiling
    if (finalFloorValue && productValidations && productValidations.sotCeiling && (finalFloorValue > productValidations.sotCeiling)) {
      return Math.min(finalFloorValue, productValidations.sotCeiling);
    } else { return finalFloorValue; }

  }

  isCellValidAgainstProductValidation(bricId: number, cell: Cell, productHelper: ProductHelper) {
    if (!productHelper) {
      return true;
    }
    if (bricId === this.brickBaseService.brickID.Volume || bricId === this.brickBaseService.brickID.Budget) {
      const productValidations = (productHelper.validation && productHelper.validation[cell.cellIndex]) ? productHelper.validation[cell.cellIndex][bricId] : null;
      if (productValidations) {
        const sotFloor = parseFloat(productValidations.sotFloor);
        const isFloorInValid = !isNaN(sotFloor) && (cell.selected.sotFloor === null || cell.selected.sotFloor < sotFloor);
        if (isFloorInValid) return false;

        const sotCeiling = parseFloat(productValidations.sotCeiling);
        const isCeilingInValid = !isNaN(sotCeiling) && (cell.selected.sotCeiling === null || cell.selected.sotCeiling > sotCeiling);
        if (isCeilingInValid) return false;
      }
    }
    return true;
  }

  mergeSelectionObjectives(cell: Cell, selectionObjectives: ObjectiveMeasure): Cell {
    const objectiveCell: Cell = _.cloneDeep(cell);

    objectiveCell.selected.status = selectionObjectives.status;
    objectiveCell.requestJSON.status = selectionObjectives.status;

    selectionObjectives.bricsData.forEach((bricData) => {
      const rowId = bricData.row;
      const [colId] = bricData.columns;
      objectiveCell.selected.objectives[rowId].cells[colId] = this.getMergedObjectiveCell(
        objectiveCell.selected.objectives[rowId].cells[colId],
        bricData,
        objectiveCell.selected.objectives[rowId].bric.bricid
      );
    });

    return objectiveCell;
  }

  getMergedObjectiveCell(cell: Cell, requestJson: any, bricId: number): Cell {
    let clonedCell = _.cloneDeep(cell);

    switch (true) {
      case requestJson.hasOwnProperty('selectionBudget'): {
        const { selectionBudget: { status, allocatedPrice } } = requestJson;
        clonedCell.selected.status = status;
        clonedCell.selected.allocated = allocatedPrice || clonedCell.selected.allocated;
        break;
      }
      case requestJson.hasOwnProperty('selectionAudienceReduction'): {
        const { selectionAudienceReduction: { status, allocatedImpressions } } = requestJson;
        clonedCell.selected.status = status;
        clonedCell.selected.allocated = allocatedImpressions || clonedCell.selected.allocated;
        break;
      }
      case requestJson.hasOwnProperty('selectionFrameReduction'): {
        const { selectionFrameReduction: { status, allocatedFrameCount } } = requestJson;
        clonedCell.selected.status = status;
        clonedCell.selected.allocated = (allocatedFrameCount || allocatedFrameCount === 0) ? allocatedFrameCount : clonedCell.selected.allocated;
        break;
      }
      case requestJson.hasOwnProperty('selectionObjectives'): {
        const { selectionObjectives } = requestJson;
        clonedCell = this.mergeSelectionObjectives(
          clonedCell,
          selectionObjectives,
        );
        break;
      }
      case requestJson.hasOwnProperty('selectionNetworks'): {
        const { selectionNetworks: { status, networkData } } = requestJson;
        let lookupColumnData = this.sharedService.getLookupColumnData(this.brickBaseService.brickID.PricingTag, null);
        clonedCell.selected[lookupColumnData.lookup[0].selectionId].forEach((network) => {
          let matchedData = _.find(networkData, nwrk => nwrk.networkId === network.networkId);
          network.status = matchedData?.status;
        });
        clonedCell.selected.status = status;
        break;
      }
      default:
    }

    clonedCell.requestJSON = this.cellAttributeService.getBrickRequestJSON(bricId, clonedCell.selected);
    clonedCell.displayText = this.cellAttributeService.getDisplayText(bricId, clonedCell.selected);
    clonedCell.toolTipText = this.cellAttributeService.getToolTip(bricId, clonedCell.selected);

    return clonedCell;
  }

  mergeObjectiveMeasures(objectiveMeasures: any[], rows: Row[]): Row[] {
    const clonedItems = _.cloneDeep(rows);
    objectiveMeasures.forEach((item) => {
      const {
        row: rowIndex,
        columns: [columnIndex],
      } = item;

      if (clonedItems[rowIndex] && clonedItems[rowIndex].cells[columnIndex]) {
        clonedItems[rowIndex].cells[columnIndex] = this.getMergedObjectiveCell(
          clonedItems[rowIndex].cells[columnIndex],
          item,
          clonedItems[rowIndex].bric.bricid
        );
      }
    });
    return clonedItems;
  }

  getObjectiveMeasuresOfCell(cell: Cell, objectiveMeasures: any[]) {
    return objectiveMeasures.find(data => data.row === cell.rowIndex && data.columns.indexOf(cell.cellIndex) > -1);
  }

  /**
   * @description get Linked Channels
   * @author Dhaval Patel
   * @memberof WorkspaceService
   */
  getLinkedChannels(rows: Row[]): number[] {
    let allowedChannels = [];
    const linkedChannels = this.dataShareService.getInitialConfigByKey('linkedChannels');
    const envRow = rows.filter(row => row.bric.bricid === this.brickBaseService.brickID.Environment);
    for (const element of envRow) {
      const usedCells = element.cells.filter(cell => !cell.isEmpty && !cell.isHidden && !_.isEmpty(cell.selected));
      for (const cell of usedCells) {
        const selKeys = Object.keys(cell.selected);
        if (selKeys.length > 0) {
          for (const selKey of selKeys) {
            if (cell.selected[selKey].length > 0) {
              allowedChannels = _.find(linkedChannels, (item) => {
                return _.includes(item, cell.selected[selKey][0].id);
              });
            }
          }
        }
      }
    }
    return allowedChannels && allowedChannels.length > 0 ? allowedChannels : [];
  }

  /**
   * To check that is product catalog has linked channel
   * @param bricsData Brics Data
   * @param rows Rows
   */
  isProductCatalogLinkedWithChannel(bricsData: any[], rows: Row[]): boolean {
    if (Array.isArray(bricsData)) {
      for (const bric of bricsData) {
        const requestJsonText = this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Environment];
        const key = 34;
        if (bric && bric[requestJsonText] && bric[requestJsonText][key]) {
          const selKeys = Object.keys(bric[requestJsonText][key]);
          if (selKeys.length > 0) {
            const linkedChannel = this.getLinkedChannels(rows);
            for (const selKey of selKeys) {
              if (linkedChannel && linkedChannel.length > 0) {
                const isLinkedChannel = linkedChannel.indexOf(Number(selKey)) > -1;
                if (!isLinkedChannel) {
                  return false;
                }
              }
            }
          }
        }
      }
    }
    return true;
  }

  isAllowReductionBrickSpan(bricsData: any[]) {
    const initialConfig = this.dataShareService.getInitialConfig();
    let isAllowed = true;
    if (initialConfig?.uiControl?.allowReductionBrickSpan === false && bricsData?.length) {
      for (const rowData of bricsData) {
        if ((rowData?.selectionFrameReduction || rowData?.selectionAudienceReduction || rowData?.selectionBudget) && rowData?.columns?.length > 1) {
          isAllowed = false;
        }
      }
    }
    return isAllowed;
  }

  updateExplodedColumnsInProductAfterSplit(bricsData: any[], columnIndex: number) {
    for (const bric of bricsData) {
      const productRow = bric[this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.ProductCatalogue]];
      if (productRow && productRow.explodedColumn && !_.isEmpty(productRow.explodedColumn)
        && bric.columns[0] > columnIndex) {
        SystemFlags.forcefullyCallProcessBrics = true;
        for (const colIndex of Object.keys(productRow.explodedColumn)) {
          productRow.explodedColumn[Number(colIndex) + bric.columns[0]] = productRow.explodedColumn[Number(colIndex)];
          delete productRow.explodedColumn[Number(colIndex)];
        }
      }
    }

    return bricsData;
  }
}
