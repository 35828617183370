import { Injectable } from '@angular/core';
import { StateService, DataShareService, WorkspaceService } from '../../core/services/index';
import { BrickBaseService } from './../../../app/core/services/brick-base.service';
import { DayPartGroup } from '../../models/initial-config.model';
import { PatternDataPrep, Filter, ProductHelper, Row } from '../../models/workspace/index';
import { PatternCommon } from './pattern-common';
import * as moment from 'moment';

@Injectable()
export class PattenService {

  dayPartGroup: DayPartGroup;
  cellIndex: number;
  cellIndexForIncharge: number;

  constructor(private stateService: StateService, private dataService: DataShareService, private brickBaseService: BrickBaseService, private workspaceService: WorkspaceService) {
    this.dayPartGroup = this.dataService.getInitialConfigByKey('dayPartGroup');
  }

  getInchargeDates(rows: Row[]) {
    let incharge = {
      startDate: null,
      endDate: null
    };
    rows.forEach((row) => {
      if (row.bric.bricid === this.brickBaseService.brickID.Incharge) {
        row.cells.forEach((cell) => {
          if (cell.cellIndex === this.cellIndex) {
            incharge = {
              startDate: cell.selected.startDate,
              endDate: cell.selected.endDate,
            };
          }
        });
      }
    });
    return incharge;
  }
  getSelectedRange(resolveObject = null): PatternDataPrep {
    const data = new PatternDataPrep();

    if (!resolveObject) {
      return data;
    }

    const filter: Filter = this.stateService.getWorkspaceObject('filter');
    const patternBricId = this.brickBaseService.brickID.Pattern;
    const maxCellIndex = filter.getMaxCellIndex();

    const inchargeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Incharge,
      filter.rows).Available_row_Index;
    this.cellIndexForIncharge = resolveObject.cellIndex > maxCellIndex ? maxCellIndex : resolveObject.cellIndex;
    const inUseByResizable = filter.rows[inchargeRowIndex].getOverlappingCell(this.cellIndexForIncharge);
    this.cellIndex = (inUseByResizable === -1 ? this.cellIndexForIncharge : inUseByResizable);

    const inchargeDates = this.getInchargeDates(filter.rows);

    const date = {
      startDate: new Date(inchargeDates.startDate),
      endDate: new Date(inchargeDates.endDate)
    };

    data.startDate = date.startDate;
    data.endDate = date.endDate;

    // // added getDay() to solve Day light saving issue, don't use getUTCDay()
    data.startDay = (date.startDate.getDay() === 0 ? 6 : date.startDate.getDay() - 1);
    data.endDay = (date.endDate.getDay() === 0 ? 6 : date.endDate.getDay() - 1);

    const actualMultiple = ((moment(date.startDate).isoWeek() === moment(date.endDate).isoWeek()) ? false : true);
    const days = ((data.startDay !== 0) || (data['endDay'] !== 6));
    data['multipleRow'] = (actualMultiple && days);

    const oneDay = 24 * 60 * 60 * 1000;
    data.totalDays = Math.round(Math.abs((date.startDate.getTime() - date.endDate.getTime()) / (oneDay))) + 1;

    // Nishit, dt:18/04/2016 code for SBRICS-1285
    if (data.totalDays >= 14 && days) {
      data.multipleRow = true;
    }
    // #end SBRICS-1285

    if (data.multipleRow && data.startDay > data.endDay) {
      data.week = Math.ceil(data.totalDays / 7) + 1;
    } else {
      data.week = Math.ceil(data.totalDays / 7);
    }

    const productDetails = this.stateService.getWorkspaceObject('productDetails');
    const loadedProduct: ProductHelper = Object.keys(productDetails).length && productDetails.find(x => x.columnIndex === this.cellIndexForIncharge);
    // Change for  SBRICS-966
    if (data.clearSelectedValue) {
      // $scope.items[item.rowAddress].brics[cellIndex].Selected = {};
      // $scope.items[item.rowAddress].brics[cellIndex].RequestJson.selectionPattern = null;
    } else if (loadedProduct) {
      if (loadedProduct.validation[this.cellIndexForIncharge] && loadedProduct.validation[this.cellIndexForIncharge][patternBricId]) {

        // VJ: Patch to handle wrong data coming from backend
        // Getting undefined if value is false
        if (loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDays
          || loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDayParts
          || loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowHours) {
          loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDays =
            loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDays === undefined ? false :
              loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDays;
          loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDayParts =
            loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDayParts === undefined ? false :
              loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDayParts;
          loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowHours =
            loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowHours === undefined ? false :
              loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowHours;
        }
        // End Patch to handle wrong data coming from backend

        data.allowDays = loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDays;
        data.allowDayParts = loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDayParts;
        data.allowHours = loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowHours;
      }
    }

    return data;
  }

  getValidation(loadedProduct: ProductHelper, patternBricId: string | number) {
    if (loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDays
      || loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDayParts
      || loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowHours) {
      loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDays =
        loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDays === undefined ? false :
          loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDays;
      loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDayParts =
        loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDayParts === undefined ? false :
          loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowDayParts;
      loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowHours =
        loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowHours === undefined ? false :
          loadedProduct.validation[this.cellIndexForIncharge][patternBricId].allowHours;
    }

    return loadedProduct.validation;
  }

  /**
   * @description dropdown for daypart and hours
   * @author Nishit Parekh
   * @returns dropdown for daypart and hours
   * @memberof PattenService
   */
  dropDownData() {
    let dayPartGroupId = 1;
    const columnConfig = this.stateService.getWorkspaceObject('columnConfig');
    if (columnConfig && columnConfig[this.cellIndexForIncharge] && columnConfig[this.cellIndexForIncharge].dayPartGroupId) {
      dayPartGroupId = this.stateService.getWorkspaceObject('columnConfig')[this.cellIndexForIncharge].dayPartGroupId;
    }
    return new PatternCommon().generateDropDownForPattern(dayPartGroupId, this.dayPartGroup);
  }

}
