<app-filter-layout [resolveObject]="resolveObject" (closeClick)="close()" (saveClick)="save()">
    <div class="form-group-wrapper_geo" style="padding-bottom: 0px;">
        <div class="form-group_geo horizontal_geo">
            <label for="">{{initialConfig.userBundle['geoplanner.text.upload'] || 'Upload'}}</label>
            <app-file-upload [multipleFiles]="true" ngDefaultControl extension=".xls,.xlsx"
                [(fileName)]="listData.fileNameLabel" logerror="logerror(data)" [noOfFiles]="2"
                (savefilelocally)="saveFilelocally($event)"></app-file-upload>

        </div>
    </div>
    <div class="form-group-wrapper_geo" style="height: 110px;">

        <ul class="summaryContainer" *ngIf="listData.listUpload.fileList.length > 0">
            <li *ngFor="let file of listData.listUpload.fileList; trackBy: trackByIndex; let i = index;">
                <label class="filter-file-label"
                    title="{{file.userSelectionName || file.name}}">{{ (file.userSelectionName || file.name)}}</label>
                <span style="width: 28%; float: left;">
                    <input type="radio" [ngModel]="file.exclude" (ngModelChange)="fileStatusChanged($event, i)"
                        class="radio small include" name="file-{{i}}" [value]="false" id="include-file-{{i}}" checked />
                    <label for="include-file-{{i}}">&nbsp;</label>
                    <input type="radio" [ngModel]="file.exclude" (ngModelChange)="fileStatusChanged($event, i)"
                        class="radio small exclude" name="file-{{i}}" [value]="true" id="exclude-file-{{i}}" />
                    <label for="exclude-file-{{i}}">&nbsp;</label>
                    <span (click)="removeFile(i)" style="cursor: pointer;font-size: 16px;padding: 0px 5px 0px;">
                        <em class="fa fa-times" aria-hidden="true"></em>
                    </span>
                </span>
            </li>
        </ul>

    </div>

    <div class="form-group-wrapper_geo routeIdContainer">
        <!-- <div class="form-group_geo horizontal_geo"> -->
            <label class="form-title_geo"
                for="">{{initialConfig.userBundle['workspace.collection.list.title.routeID']}}</label>
            <div class="content-area">
                <div id="tabFrameId" class="tab-content" [ngStyle]="{'display': 'block'}" style="padding: 0px">
                    <app-lookup-view [lookupType]="lookups.lookup[0].lookupType"
                        [selectionId]="lookups.lookup[0].selectionId" [placeholder]="lookups.lookup[0].displayName"
                        [regexp]="lookups.lookup[0]['regExp']" [(ngModel)]="listData.routeFrameArray"
                        [displayType]="lookups.lookup[0]['displayType']" name="routeFrameId">
                    </app-lookup-view>
                </div>
            </div>
        <!-- </div> -->
    </div>
</app-filter-layout>
