import { CellValues } from './cell-values';
import * as _ from 'lodash';
import { Brick } from '../../workspace/brick-model';
export class Cell {
  /**
   * Current Row Index
   */
  public rowIndex: number;
  /**
   * Current Index
   */
  public cellIndex: number;

  /**
   * Cell size
   */
  public cellWidth = 1;

  /**
   * Is Brick displayed with values or its empty brick
   *
   */
  public isEmpty = true;
  /**
   * If true, it will be displayed at UI, false will not show at UI
   */
  public isHidden = true;

  /**
   * Is cell editable or not
   */
  public isEditable = true;

  /**
   * Is cell locked
   */
  public isLocked = true;

  /**
   * String to display on top of Cell
   */
  public displayText: string;

  /**
   * Json object for current cell to send it to backend
   */
  public requestJSON: any;

  /**
   * Current selected values
   */
  public selected: any = {};

  /**
   *  set tooltiptex
   */
  public toolTipText?: string;
  /**
   * @description this will be boolean when selected for multi delete
   * @type {boolean}
   * @memberof Cell
   */
  public markSelected = false;

  /**
   * @description if cell is maked isValid = false, red border will appear on the cell
   * @type {boolean}
   * @memberof Cell
   */
  public isValid = true;

  /**
   * @description this will be used for brics associated with that product
   * @memberof Cell
   */
  public isMandatoryForProduct = false;

    /**
   * @description This property is introduced to allow to edit qty bricks value but it cant delete
   * @memberof Cell
   */
  public isMandatoryEditableForProduct = false;

  /**
   * @description Highlight cell on left while drag over
   * @type {boolean}
   * @memberof Cell
   */
  public highlightLeft = false;

  /**
   * @description Highlight cell on right while drag over
   * @type {boolean}
   * @memberof Cell
   */
  public highlightRight = false;

  /**
   * @description Highlight border of cell while drag over
   * @type {boolean}
   * @memberof Cell
   */
  public highlightCell = false;

  /**
   * @description Display warning icon on (Incharge) brick when selected product is not matching with selected date range, SM-4637
   * @type {boolean}
   * @memberof Cell
   */
  public showWarningIcon = false;

  /**
   * @description Warning message to be displayed when showWarningIcon is true, SM-4637
   * @type {boolean}
   * @memberof Cell
   */
  public warningText = '';

  /**
   * Added to resolve lint warning as it was used like a['indexBeforeDelete']
   */
  public indexBeforeDelete = -1;

  /**
   * Assign values to current cell
   */
  public assignValues(rowIndex: number, values: CellValues, cellWidth = 1) {
    // TODO: This method can be merged with updateCellValues
    this.cellWidth = cellWidth;
    this.displayText = values.displayText;
    this.isEditable = true;
    this.isEmpty = false;
    this.isHidden = false;
    this.isLocked = false;
    this.markSelected = false;
    this.isValid = true;
    this.requestJSON = values.requestJson;
    this.rowIndex = rowIndex;
    this.selected = values.selectedValues;
    this.toolTipText = values.toolTipText;
  }

  /**
   * Method to get request json based on selected values
   * @param values Selected values for current brick/cell
   * TODO: Need to move this method out of this class as it will have logic for all bricks
   */
  // private getRequestJson(values: CellValues): string {
  //   const reqJson: string = '';
  //   return reqJson;
  // }

  /**
   * Creates Empty Cell
   */
  public createEmptyCell(isHidden = false, isLocked = false) {
    this.cellWidth = 1;
    this.displayText = '';
    this.isEmpty = true;
    this.isHidden = isHidden;
    this.isLocked = isLocked;
    this.requestJSON = '';
    this.rowIndex = -1;
    this.markSelected = false;
    this.isValid = true;
    this.selected = {};
    this.toolTipText = '';
  }

  /**
   * Method to update values of current cell
   * @param cell Cell to copy
   */
  public updateCellValues(cell: Cell): boolean {
    // TODO: This method can be merged with assignValues

    const clonedCell: Cell = _.cloneDeep(cell);
    this.cellIndex = clonedCell.cellIndex;
    this.cellWidth = clonedCell.cellWidth;
    this.displayText = clonedCell.displayText;
    this.isEditable = clonedCell.isEditable;
    this.isEmpty = clonedCell.isEmpty;
    this.isHidden = clonedCell.isHidden;
    this.isLocked = clonedCell.isLocked;
    this.requestJSON = clonedCell.requestJSON;
    this.rowIndex = clonedCell.rowIndex;
    this.selected = clonedCell.selected;
    this.markSelected = clonedCell.markSelected;
    this.isValid = clonedCell.isValid;
    this.toolTipText = clonedCell.toolTipText;
    this.isMandatoryForProduct = clonedCell.isMandatoryForProduct;
    return true;
  }

  /**
   * @description Highlight on left/right SM-2697
   * @author Amit Mahida, Shivani Patel
   * @param {DragEvent} event
   * @param {{ bric: Brick, cellIndex?: number }} draggedBricHighlight
   * @param {Element} cellElement
   * @param {number} currentBricid
   * @memberof Cell
   */
  highlight(event: DragEvent, draggedBricHighlight: { bric: Brick, cellIndex?: number }, cellElement: Element, currentBricid: number) {
    if (this.isLocked || !this.isEditable || this.isMandatoryForProduct || !draggedBricHighlight) { return; }

    this.highlightCell = true;
    if (draggedBricHighlight.bric.bricid === currentBricid) {
      if (draggedBricHighlight.cellIndex !== this.cellIndex) {
        const boundingClientRect = cellElement.getBoundingClientRect();
        const isLeft = event.clientX <= (boundingClientRect.left + boundingClientRect.right) / 2;
        if (isLeft) {
          this.highlightLeft = true;
          this.highlightRight = false;
        } else {
          this.highlightLeft = false;
          this.highlightRight = true;
        }
      } else {
        this.highlightCell = false;
      }
    }
  }

  /**
   * @description Removes hightlighting SM-2697
   * @author Amit Mahida
   * @memberof Cell
   */
  removeHighlight() {
    this.highlightCell = false;
    this.highlightLeft = false;
    this.highlightRight = false;
  }
}
