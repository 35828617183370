import * as _ from 'lodash';
export class SelectSupport {
  public static mapArrayData(dataArray: any[], valKey: string, textKey: string, value): string {
    let returnValue = '';
    if (!value) {
      returnValue = '';
    } else if (value === '*') {
      returnValue = '*';
    } else if (dataArray && _.isArray(dataArray)) {
      for (const data of dataArray) {
        if (String(data[valKey]) === String(value)) {
          returnValue = data[textKey] || returnValue;
          break;
        }
      }
    }
    return returnValue;
  }
}
