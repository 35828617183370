import {
  DataShareService,
  BrickBaseService,
  SharedService,
  StateService
} from '../../core/services';
import { InitialConfigModel, LookupModel } from '../../models';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { ObjectiveService } from '../../workspace/objective/objective.service';
declare var _;

export class EnvironmentBase {
  /**
   * @description It contians initial config data.
   * @type {InitialConfigModel}
   * @memberof EnvironmentBase
   */
  public initialConfig: InitialConfigModel;
  /**
   * @description It contians lookup data.
   * @type {LookupModel}
   * @memberof EnvironmentBase
   */
  public lookups: LookupModel = { lookup: [], brickId: -1 };
  /**
   * @description It contians config lookup data.
   * @type {number[]}
   * @memberof EnvironmentBase
   */
  public configLookup = null;
  /**
   * @description It contains selected filter data.
   * @type {*}
   * @memberof EnvironmentBase
   */
  public selected: any = {};

  /**
   * Creates an instance of EnvironmentBase.
   * @author Alkesh Shah
   * @param {DataShareService} dataShareService
   * @param {BrickBaseService} brickBaseService
   * @memberof EnvironmentBase
   */
  constructor(
    private dataShareService: DataShareService,
    public brickBaseService: BrickBaseService,
    public sharedService: SharedService,
    public stateService: StateService,
    public appHeaderService: AppHeaderService,
    private objectiveService: ObjectiveService
  ) {
    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  /**
   * @description initialize the data of instance
   * @author Alkesh Shah
   * @param {*} selectedValue - selected value of cell
   * @memberof EnvironmentBase
   */
  init(selectedValue) {
    this.lookups = this.sharedService.getLookupColumnData(this.brickBaseService.brickID.Environment, null);
    if (Object.keys(selectedValue).length === 0) {
      for (const lookup of this.lookups.lookup) {
        this.selected[lookup.selectionId] = [];
      }

      if (this.appHeaderService.objectiveMode && Object.keys(this.selected).filter(o => (this.selected[o]).length > 0).length === 0) {
        const result = this.objectiveService.getSelectedForCollectionBrick(this.lookups, this.brickBaseService.brickID.Environment as number);
        this.selected = result.selected;
        this.lookups = result.lookups;
      }

    } else {
      this.selected = selectedValue;
    }
  }

  /**
   * @description get list of allowed channel
   * @author Alkesh Shah
   * @param {*} selectedValue - current selected values
   * @returns
   * @memberof EnvironmentBase
   */
  getAllowedChannel(selectedValue) {
    let allowedChannels = [];
    const linkedChannels: number[][] = this.initialConfig.linkedChannels;
    const selKeys = Object.keys(selectedValue);
    if (selKeys.length > 0) {
      for (const selKey of selKeys) {
        if (selectedValue[selKey].length > 0) {
          allowedChannels = _.find(linkedChannels, (item) => {
            return _.includes(item, selectedValue[selKey][0].id);
          });
        }
      }
    }
    return allowedChannels.length > 0 ? allowedChannels : null;
  }

  /**
   * @description validate the selections
   * @author Alkesh Shah
   * @returns {boolean} - valid selection or not
   * @memberof EnvironmentBase
   */
  isValidSelection(): boolean {
    let isValid = false;
    const keys = Object.keys(this.selected);
    for (const key of keys) {
      if (this.selected[key].length > 0) {
        isValid = true;
        break;
      }
    }
    return isValid;
  }

  /**
   * @description get Linked Channels
   * @author Kishan Patel
   * @memberof WorkspaceBase
   */
  getLinkedChannels() {
    let allowedChannels = null;
    const linkedChannels = this.dataShareService.getInitialConfigByKey('linkedChannels');
    const rows = this.appHeaderService.enabledPCM ?
      this.stateService.getPCMFilterObj().rows : this.stateService.getWorkspaceFilterObj().rows;

    const environmentRow = rows.filter(r => r.bric.bricid === this.brickBaseService.brickID.Environment);

    if (environmentRow.length > 0) {
      environmentRow[0].cells.forEach((cell) => {
        if (!cell.isEmpty && !cell.isHidden && !_.isEmpty(cell.selected)) {
          const selKeys = Object.keys(cell.selected);
          for (const selKey of selKeys) {
            if (cell.selected[selKey].length > 0) {
              allowedChannels = _.find(linkedChannels, (item) => {
                return _.includes(item, cell.selected[selKey][0].id);
              });
            }
          }
        }
      });
    }

    return allowedChannels;

  }

}
