<app-sb-modal-wrapper [title]="userBundle['workspace.collection.bric.secondaryAudience.heading']" [titleBackgroundColor]="resolveObject.brick.color"
  (closed)="onModalClosed($event)" [readOnlyModal]="readOnlyModal" (saved)="onModalSaved($event)">
  <div class="tabs_container horizontal popup-overflow">
    <ul class="tabs-menu" style="max-height:inherit !important;">
      <li class="" *ngFor="let tab of tabList; trackBy: trackByAudienceCategoryGroup; let i = index;" (click)="onTabChange(i)"
        [ngClass]="{'current': i == activeTabIndex}">
        <a style="cursor: pointer;">
          <span class="text">{{tab.audienceCategoryGroupName}}</span>
        </a>
      </li>
    </ul>
    <div class="tab" [ngClass]="{'readOnly' : readOnlyModal}">
      <div class="tab-content" style="display:block;">
        <app-tree-view [data]="audienceCategory" [options]="options" [templateProps]="templateProps"
          (onSelect)="onSelectNode($event)" (onDeSelect)="onDeSelectNode($event)" (onSelectAll)="onSelectAll($event)">
        </app-tree-view>
      </div>
    </div>
    <div class="tab" [ngClass]="{'readOnly' : readOnlyModal}">
      <div>
        <ul dnd-sortable-container [sortableData]="allAudienceState.categories" class="segementElements">
          <li
            [ngClass]="{ 'summaryContainerBlue' : item.selectedForSegmentWeight, 'summaryContainer' : !item.selectedForSegmentWeight }"
            dnd-sortable *ngFor="let item of allAudienceState.categories; trackBy: trackByAudienceCategory; let i = index" [sortableIndex]="i">
            <div (click)="selectItem(item)">
              <strong *ngIf="user.workspaceTabAccess.secondaryAudienceWeight">{{item.weight}}</strong>&nbsp;&nbsp;&nbsp;
              <span [title]="item.audienceCategoryName">{{ item.audienceCategoryName  | slice:0:40}}
                {{item.audienceCategoryName.length > 40 ? '...' : ''}}</span>
              <span class="right dropdown" (click)="removeItem(item)"> <em class="fa fa-times"></em> </span>
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="user.workspaceTabAccess.secondaryAudienceWeight && allAudienceState.categories.length" class="weight">
        <label
          class="left full-width">{{userBundle['workspace.collection.bric.secondaryAudience.segmentWeightSelection']}}</label>
        <div>
          <div class="slider-wrapper">
            <ngx-slider (valueChange)="onSliderValueChange()" [(value)]="allAudienceState.segmentWeight"
              [options]="sliderOptions"></ngx-slider>
          </div>
          <input type="checkbox" [(ngModel)]="allAudienceState.lock" (change)="onLockChange()" class="check_box"
            id="checkbox1">
          <label for="checkbox1"> </label>
        </div>
      </div>
      <div class="left full-width horizantal-spacing">
        <div *ngIf="allAudienceState.categories.length > 1" class="left full-width horizantal-spacing">
          <label
            class="flexSlide">{{userBundle['workspace.collection.bric.secondaryAudience.segmentSelection']}}</label>
          <input type="radio" name="segment-selection" [(ngModel)]="allAudienceState.segmentSelection" value="AND"
            id="and" class="radio small">
          <label for="and">
            {{userBundle['workspace.collection.bric.secondaryAudience.and']}}
          </label>
          <div class="col-md-2">&nbsp;</div>
          <input type="radio" name="segment-selection" [(ngModel)]="allAudienceState.segmentSelection" value="OR"
            id="or" class="radio small">
          <label for="or">
            {{userBundle['workspace.collection.bric.secondaryAudience.or']}}
          </label>
        </div>
        <button *ngIf="allAudienceState.categories.length" type="button" class="btn btn-default"
          (click)="removeAllSelected()">{{userBundle['workspace.collection.bric.secondaryAudience.clearAll'] || 'Clear All'}}</button>
      </div>
    </div>
  </div>

</app-sb-modal-wrapper>
