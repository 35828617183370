import { Component, OnInit } from '@angular/core';
import { StateService } from '../../../app/core/services/state.service';
import { CampaignDetails, HistoryROC } from '../commercial.model';
import { SystemFlags } from '../../models';
import { DataShareService, LogHelperService } from '../../core/services';
import { CampaignGroupTypeList } from '../../core/enum';
import { CommercialService } from '../../core/services/commercial.service';
import { DatePipe } from '@angular/common';
import { CommercialButtonService } from '../commercial-buttons/commercial-buttons.service';
import { CampaignDetailsService } from '../campaign-details/campaign-details.service';
import { GLOBAL } from '../../core/utils/app.constant';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CompetitiveCampaignsComponent } from '../competitive-campaigns/competitive-campaigns.component';
import { SbModalPopupService } from '../../core/components/sb-modal-popup';
import { CommercialValdiationComponent } from '../commercial-valdiation/commercial-valdiation.component';
import { CommercialBase } from '../commercial-base';
import { RequiredCampaignFields, RequiredBookingsFields, PopulateCommercialFields, PopulateBookingDetailFields } from '../../models/RequiredFields';

@Component({
  selector: 'app-commercial-us',
  templateUrl: './commercial-us.component.html'
})
export class CommercialUsComponent extends CommercialBase implements OnInit {
  systemFlags = SystemFlags;
  /**
   * @description returns true if update button is clicked
   * @type {boolean}
   * @memberof CommercialUsComponent
   */
  updateClicked: boolean;
  /**
   * @description set bookingStatusList
   * @memberof CommercialUkComponent
   */
  bookingStatusList = GLOBAL.bookingStatusList;
  paperBookingNotAllowed = false;
  /**
   * @description Commercial data from backend
   * @type {CampaignDetails}
   * @memberof CommercialUsComponent
   */
  public commercialData: CampaignDetails;

  /**
   * Data to pass in Discount Grid
   */
  gridData = {
    statusCategory: [],
    discounts: []
  };

  /**
   * @description Accordian for campaign details
   * @type {boolean}
   * @memberof CommercialGermanyComponent
   */
  hideCampaignDetails = false;

  /**
   * @description Accordian for booking details
   * @type {boolean}
   * @memberof CommercialUsComponent
   */
  hideBookingDetails = false;

  /**
   * @description Accordian for discount grid
   * @type {boolean}
   * @memberof CommercialUsComponent
   */
  hideDiscountGrid = false;

  /**
   * @description this will show the history of campaign updated in case of reason/cancel for change
   * @memberof CommercialUsComponent
   */
  historyROC = new HistoryROC();

  constructor(
    stateService: StateService,
    commercialService: CommercialService,
    dataShareService: DataShareService,
    logHelperService: LogHelperService,
    datePipe: DatePipe,
    commercialButtonService: CommercialButtonService,
    campaignDetailsService: CampaignDetailsService,
    private sbModalPopupService: SbModalPopupService
  ) {
    super(stateService, logHelperService, commercialButtonService, dataShareService, commercialService, datePipe, campaignDetailsService);
  }

  ngOnInit() {
    this.restoreCommercialData();
    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  restoreCommercialData() {
    this.commercialConfig.user = this.dataShareService.getUserModel();
    this.getCommercialDataFromService();
  }

  /**
   * @description Get commercial Data from backend
   * @author Shivani Patel
   * @memberof CommercialUsComponent
   */
  getCommercialDataFromService(isManual = false) {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
    };

    this.commercialService.loadCommercialCampaignDetails(param).subscribe((data) => {
      if (data.status === 'OK') {
        this.commercialData = data.data;
        if (isManual) {
          this.dataShareService.updateBookingList(this.commercialData.bookingList);
        }
        this.commercialConfig.campaignStatus = data.data.campaignStatusId;
        this.commercialConfig.bookingStatus = data.data.bookingStatus;
        this.commercialConfig.uiControl = data.data.uiControl;
        this.commercialData.bookingStatusName = this.campaignDetailsService.getStatusNameFromId(data.data.campaignStatusId);
        this.campaignDetails = this.campaignDetailsService.populateCampaignDetails(this.commercialData, this.initialConfig, this.dataShareService.getInitialConfigByKey('assignedTo'));
        this.offPostPaperChecking();
        this.campaignDetails.mediaLength = this.campaignDetails.bookingList[0].mediaLength;
        this.commercialService.IsTitlePersist = data.data.uiControl.isTitlePersist ? data.data.uiControl.isTitlePersist : false;
        /* Get Discounts Data */
        if (this.campaignDetails.discounts) {
          this.processDiscounts();
        }
        if (isManual) {
          this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT);
        } else {
          this.campaignDetailsService.calculateCPT(this.campaignDetails);
        }
        this.historyROC = this.campaignDetailsService.populateHistoryROC(this.historyROC, data.data.auditReasonsHistoryMap, this.commercialConfig.uiControl.historyROC);
      }
    });
  }

  /**
   * This function will check if off post warning is to be displayed or not as well as based on token
   * it will set a flag so that Confirm and Option/Reserve button can be disabled
   * SM-4408, VJ: 24-Sep-2019
   */
  offPostPaperChecking() {
    this.paperBookingNotAllowed = this.commercialConfig.uiControl.containsPaper && !this.dataShareService.userModel.commercialTabAccess.dailyPaperBooking;

    if (this.commercialConfig.uiControl.showOffPostWarning && this.paperBookingNotAllowed) {
      const resolveObject = {
        popup: {
          popupType: 1,
          popupMessage: this.initialConfig.userBundle['commercial.error.book.dailyPaperWarning'],
          title: this.initialConfig.userBundle['common.warning'].toUpperCase()
        }
      };
      const modalOptions: NgbModalOptions = {
        windowClass: 'modal-backdrop'
      };
      this.sbModalPopupService.open(CommercialValdiationComponent, resolveObject, modalOptions);
    }
  }

  /**
   * @description Prepares props to pass to the Discount Grid
   * @author Shivani populateCampaignDetails
   * @memberof CommercialUsComponent
   */
  processDiscounts() {
    // Added originalRateCard for cpt calculation as we have to apply discount and campaign cpt to original ratecard
    this.campaignDetails.discounts = this.campaignDetails.discounts.map(discount => ({
      ...discount,
      originalRateCard: discount.rateCard
    }));
    this.gridData = {
      statusCategory: this.campaignDetails.statusCategory,
      discounts: this.campaignDetails.discounts
    };
    this.calculateCPT();
  }

  /**
   * @description It calculates Ratecard based on impressions, campaignCPT and pmpDiscount
   * @author Shivani Patel
   * @returns
   * @memberof CommercialUsComponent
   */
  calculateCPT() {
    const searchPageObject = this.stateService.getCampaign();
    if (Number(searchPageObject.campaignTypeGroupId) !== CampaignGroupTypeList.PMP
      || (SystemFlags.isLoadedCampaign && new Date(this.campaignDetails.campaignStartDate) < new Date())
      || SystemFlags.isPPCampaign) { // SM-5593
      return;
    }
    if (this.campaignDetails.discounts) {
      this.campaignDetails.discounts.forEach((obj) => {
        let rateCard = obj.originalRateCard;
        if (this.campaignDetails.campaignCPT) {
          rateCard = (obj.impressions / 1000) * this.campaignDetails.campaignCPT;
        }
        if (this.campaignDetails.pmpDiscount > 0) {
          rateCard = rateCard - (rateCard * this.campaignDetails.pmpDiscount / 100);
        }
        obj.rateCard = rateCard;
      });
      this.commercialService.notifyGrid(this.campaignDetails.discounts);
    }
  }

  /**
   * @description Validates Campaign details
   * @author Shivani Patel, Amit Mahida
   * @returns
   * @memberof CommercialUsComponent
   */
  validateCommercialCampaign = (status) => {
    this.bookingStatusId = status;
    const requiredFields: RequiredCampaignFields = new RequiredCampaignFields();
    requiredFields.campaignEndDate = true;
    let message = this.validateCommonFields(requiredFields);
    if (!message && this.campaignDetails.bookingList) {
      this.campaignDetails.bookingList.forEach((obj) => {
        const requiredBookingsFields: RequiredBookingsFields = new RequiredBookingsFields(false, false, true, false, false);
        if (!message) {
          message = this.validateBookingFields(obj, requiredBookingsFields);
        }
      });
    }
    if (!message) {
      message = this.validateMediaLength(this.campaignDetails, status, true);
    }
    return this.isValid(message);
  }

  /**
   * @description Create requestJSON for backend
   * @author Shivani Patel
   * @param {*} status
   * @returns
   * @memberof CommercialUsComponent
   */
  createRequestJSONForCommercial = (status: number) => {
    const campaignDetails = this.campaignDetails;
    const populate: PopulateCommercialFields = this.getCommercialFields();
    const temp = super.populateBookCommercialCampaignParam(status, campaignDetails, populate);
    if (this.gridData.discounts) {
      this.gridData.discounts.forEach((element) => {
        if (element.actualStart && element.actualStart !== '') {
          element.actualStart = this.datePipe.transform(element.actualStart, GLOBAL.DATE_PARSE_FORMAT);
          element.delivered = 1;
        } else {
          element.delivered = 0;
          delete element.actualStart;
        }
        if (element.maxDate) {
          delete element.maxDate;
        }
        if (element.minDate) {
          delete element.minDate;
        }
        if (element.originalRateCard) {
          delete element.originalRateCard;
        }

        if (this.commercialConfig.uiControl.showOvershowToggle) {
          temp.overshow = campaignDetails.overshow;
        }
      });
      temp.discounts = this.gridData.discounts;
    }
    const populateBooking: PopulateBookingDetailFields = this.getBookingDetailFields();
    temp.bookingList = this.populateBookingDetailsServiceObject(campaignDetails, 'bookingList', populateBooking);

    return temp;
  }

  /**
   * To override the commercial fields
   * This override fields are used to override the default value
   * @memberof CommercialUsComponent
   * @author Dhaval Patel
   */
  getCommercialFields() {
    const populate: PopulateCommercialFields = super.getCommercialFields();
    populate.setPCFields();
    populate.specialist = true;
    populate.grossMediaValue = false;
    populate.netMediaValue = false;
    populate.pmpDiscount = true;
    populate.overshow = false;
    return populate;
  }

  /**
   * To override the booking detail fields
   * This override fields are used to override the default value
   * @memberof CommercialUsComponent
   * @author Dhaval Patel
   */
  getBookingDetailFields() {
    const populate: PopulateBookingDetailFields = super.getBookingDetailFields();
    populate.bookingReference = true;
    populate.optionDroppingDate = true;
    populate.impressions = true;
    populate.impressionsRemaining = true;
    populate.impressionsPurchased = true;
    populate.pmpdiscount = true;
    populate.grossMediaRemaining = true;
    populate.grossMediaPurchased = true;
    populate.bookingStatusId = true;
    populate.isCancellationPending = true;
    populate.optionToRenew = true;
    populate.readOnlyOptionToRenew = true;
    populate.adminPersonId = false;
    populate.ctuPersonId = false;
    populate.barterId = false;
    populate.barterRate = false;
    populate.preBarterValue = false;
    populate.productionTypeId = false;
    populate.purchaseOrderNo = false;
    populate.buyerContactId = false;
    populate.netMediaValue = false;
    return populate;
  }

  /**
   * @description Shows competitive proposal campaigns
   * @author Amit Mahida
   * @returns
   * @memberof CommercialUsComponent
   */
  confirmCompetitionRemoval() {
    return new Promise((resolve, reject) => {
      const modalOptions: NgbModalOptions = {
        windowClass: 'modal-backdrop free-of-change',
        size: 'lg'
      };

      const resolveObject = {
        rowData: this.commercialData.competitionArray
      };

      this.sbModalPopupService.open(CompetitiveCampaignsComponent, resolveObject, modalOptions).result.then((obj) => {
        resolve(obj);
      }, (reason) => {
        reject(reason);
      });
    });
  }

  onUpdateClicked(event) {
    this.updateClicked = event;
  }

  /**
   * @description Event fired when we click on update/option/book/cancel button
   * @author Shivani Patel, Amit Mahida
   * @param {number} status
   * @memberof CommercialUsComponent
   */
  async bookCommercialCampaign(status: number) {
    const campaignDetails = this.campaignDetails;
    if (this.validateCommercialCampaign(status)) {
      const confirmDeleteFrames = await this.isConfirmDeleteFrames(status);
      if (!confirmDeleteFrames) { return; }

      status = status || campaignDetails.campaignStatusId;

      if (this.isROC(status)) {
        this.handleROC(status, this.createRequestJSONForCommercial);
      } else if (this.isConfirm(status)) {
        this.confirmCampaign(this.createRequestJSONForCommercial);
      } else {
        this.validateServiceRequest(status, this.createRequestJSONForCommercial);
      }
    } else {
      console.log('Invalid Page Data');
    }
  }

  /**
   * Is Confirm Delete Frames
   * @param status Status
   */
  async isConfirmDeleteFrames(status: number) {
    let confirmDeleteFrames = false;
    if (
      this.commercialConfig.uiControl.competitionEnabled &&
      this.commercialData.competitionArray.length > 0 &&
      (status === GLOBAL.bookingStatusList.Option ||
        status === GLOBAL.bookingStatusList.Confirmed || this.updateClicked)
    ) {
      await this.confirmCompetitionRemoval().then(
        (res: boolean) => {
          confirmDeleteFrames = res;
        },
        () => {
        }
      );
    } else {
      confirmDeleteFrames = true;
    }
    return confirmDeleteFrames;
  }

  async manuallyCPTUpdated() {
    const campaigndata = this.createRequestJSONForCommercial(this.campaignDetails.campaignStatusId);
    await this.updateCommercialInfoForCPT(campaigndata);
    this.getCommercialDataFromService(true);
  }
}
