import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { DataShareService } from '../../../app/core/services/data-share.service';
import { GLOBAL } from '../../core/utils/app.constant';
import { DatePipe } from '@angular/common';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerHelper } from '../../core/components/ngb-datepicker/ngb-datepicker-helper';
import { UiControl, UserModel } from '../../models';
import { LogHelperService } from '../../core/services/log-helper.service';

@Component({
  selector: 'app-performance-proposal',
  templateUrl: './performance-proposal.component.html',
  styleUrls: ['./performance-proposal.component.css'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.Emulated
})

export class PerformanceProposalComponent implements OnInit {

  @Input() resolveObject: any;

  endDayObjCache = {};
  startDayObjCache = {};

  campaignSave = {
    campaignName: '',
    hideFinance: false,
    hideImpression: false,
    newValidFromDate: null,
    newValidToDate: null,
    newValidStartDate : null,
    showTouchedPOIOnly: true,
    allowEditToTouchedPOIs: false,
    enableTouchedPOIFeature: false
  };
  brickBgColor: string = GLOBAL.DEFAULT_TITLE_BACKGROUND_COLOR;
  uiControl: UiControl;
  userBundle: object;
  selectedTitle = '';
  hideTitle = false;
  disableEndDate = true;
  userModel: UserModel;

  minnewValidFromDate: NgbDateStruct;

  minnewValidToDate: NgbDateStruct;

  /**
   * @description Array of DefaultWeekDay
   * @memberof RangeComponent
   */
  defaultWeekDay: object[];
  /**
   * @description It will decide to diable end date input
   * @type {boolean}
   * @memberof RangeComponent
   */
  disablenewValidToDate = true;
  constructor(private dataShareService: DataShareService,
              private datePipe: DatePipe,
              private logHelperService: LogHelperService
  ) { }

  ngOnInit() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.userModel = this.dataShareService.getUserModel();
    this.campaignSave.hideFinance = this.uiControl.CCPHideFinanceEnabled;
    this.campaignSave.hideImpression = this.uiControl.CCPHideImpressionEnabled;
    this.campaignSave = { ...this.campaignSave, ...this.resolveObject.SelectedValue };
    this.selectedTitle = this.resolveObject.SelectedTitle;
    this.brickBgColor = this.resolveObject.color || GLOBAL.DEFAULT_TITLE_BACKGROUND_COLOR;
    this.hideTitle = this.resolveObject.hideTitle;
    this.campaignSave.showTouchedPOIOnly = this.uiControl.enableTouchedPOIFeature  ? this.resolveObject.showTouchedPOIOnly : false;
    this.campaignSave.allowEditToTouchedPOIs = this.uiControl.enableTouchedPOIFeature  ? this.resolveObject.allowEditToTouchedPOIs : false;
    this.defaultWeekDay = this.dataShareService.getdefaultWeekDay();
    if (this.resolveObject.proposalGlobalExpiryPeriodInDays) {
      const currentDate = new Date();
      this.campaignSave.newValidFromDate = NgbDatepickerHelper.convertDateToDateStruct(currentDate);
      const d = new Date();
      const toDate = new Date(d.setDate(d.getDate() + Number(this.resolveObject.proposalGlobalExpiryPeriodInDays)));
      this.campaignSave.newValidToDate = NgbDatepickerHelper.convertDateToDateStruct(toDate);
      this.disableEndDate = false;
    }
  }

  onModalSaved(event) {
    if (this.resolveObject.overrideProposalExpiryDate) {
      if (this.campaignSave.newValidFromDate && this.campaignSave.newValidToDate) {
        const sDate = NgbDatepickerHelper.convertDateStructToDate(this.campaignSave.newValidFromDate as NgbDateStruct);
        const eDate = NgbDatepickerHelper.convertDateStructToDate(this.campaignSave.newValidToDate as NgbDateStruct);
        this.campaignSave.newValidFromDate = this.datePipe.transform(sDate, GLOBAL.DATE_PARSE_FORMAT);
        this.campaignSave.newValidToDate = this.datePipe.transform(eDate, GLOBAL.DATE_PARSE_FORMAT);
        event.activeModal.close(this.campaignSave);
      } else {
        this.logHelperService.logError(this.userBundle['commercial.error.selectProposalExpiryDate'] || 'Please select valid duration for ProposalLink');
      }
    } else {
      event.activeModal.close(this.campaignSave);
    }

  }

  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }

  /**
   * open start/end date datepicker on click
   * @param $event click event object
   * @param datePicker start/end date picker object
   */
  openDatePicker($event: Event, datePicker: NgbInputDatepicker, otherDatePicker: NgbInputDatepicker): void {
    otherDatePicker.close();
    $event.preventDefault();
    $event.stopPropagation();
    datePicker.toggle();
  }

  /**
   * close date picker on out-side click
   * @param event click event object
   * @param datePicker start/end date picker object
   */
  closeDatePickerOutsideClick(event: any, datePicker: NgbInputDatepicker): void {
    if (datePicker.isOpen()) {
      if (event.target.offsetParent == null && event.target.nodeName !== 'OPTION') {
        datePicker.close();
      } else if (event.target.offsetParent.nodeName !== 'NGB-DATEPICKER') {
        datePicker.close();
      }
    }
  }

  /**
   * start date select event handler
   * @param inst - selected date instance
   */
  onSelectFrom(inst: NgbDateStruct) {
    this.campaignSave.newValidFromDate = inst;
    this.minnewValidToDate = inst;
    this.campaignSave.newValidToDate = null;
    this.endDayObjCache = {};
    this.disablenewValidToDate = false;
    this.disableEndDate = false;
    if (this.resolveObject.proposalGlobalExpiryPeriodInDays) {
      const d = new Date(NgbDatepickerHelper.convertDateStructToDate(this.campaignSave.newValidFromDate));
      const endDate = new Date(d.setDate(d.getDate() + Number(this.resolveObject.proposalGlobalExpiryPeriodInDays)));
      this.campaignSave.newValidToDate = NgbDatepickerHelper.convertDateToDateStruct(endDate);
    }
  }

  markStartDayDisabled = (date: NgbDateStruct): boolean => {
    const daystring = this.cachenewValidFromDateObj(date);
    return this.startDayObjCache[daystring].isDisabled;
  }

  /**
   * @description prepare a newValidFromDate day object of ngb-datepicker
   * @author Kishan Patel
   * @param {NgbDateStruct} date - current date
   * @returns
   * @memberof RangeComponent
   */
  prepareStartDayObj(date: NgbDateStruct) {
    const obj = {
      isToday: false,
      isDisabled: false,
      isWeekday: false
    };

    const selectedDay: any = NgbDatepickerHelper.convertDateStructToDate(date);

    const today = (new Date().setHours(0, 0, 0, 0));
    if (!obj.isDisabled && selectedDay < today) {
      obj.isDisabled = true;
    }

    if (!obj.isDisabled) {
      const todayDate = new Date();
      obj.isToday = NgbDatepickerHelper.isEqual(todayDate, date);
      const selecteddaye = NgbDatepickerHelper.convertDateStructToDate(date);
      const weekNo = selecteddaye.getWeek() + 1;
      const condition = ((weekNo % this.uiControl.defaultInChargeLength === 0)
        && (selecteddaye.getDay() === this.uiControl.defaultInChargeDay));
      obj.isWeekday = condition;
    }
    return obj;
  }

  /**
   * @description cache specific days object if is not exists
   * @author Kishan Patel
   * @param {NgbDateStruct} date - current date object
   * @returns {string}
   * @memberof RangeComponent
   */
  cachenewValidFromDateObj(date: NgbDateStruct): string {
    const daystring = NgbDatepickerHelper.getDateString(date);
    if (!this.startDayObjCache[daystring]) {
      this.startDayObjCache[daystring] = this.prepareStartDayObj(date);
    }
    return daystring;
  }

  /**
   * @description get the specific dates property
   * @author Kishan Patel
   * @param {NgbDateStruct} date current day
   * @param {string} key property key to retrive
   * @returns {boolean}
   * @memberof RangeComponent
   */
  getStartDayProp(date: NgbDateStruct, key: string): boolean {
    const daystring = this.cachenewValidFromDateObj(date);
    return this.startDayObjCache[daystring][key];
  }

  markEndDayDisabled = (date: NgbDateStruct): boolean => {
    const daystring = this.cachenewValidToDateObj(date, false);
    return this.endDayObjCache[daystring].isDisabled;
  }
  /**
   * @description prepare a newValidFromDate day object of ngb-datepicker
   * @author Kishan Patel
   * @param {NgbDateStruct} date - current date
   * @returns
   * @memberof RangeComponent
   */
  prepareEndDayObj(date: NgbDateStruct, disabled: boolean) {
    const obj = {
      isToday: false,
      isDisabled: disabled,
      isWeekday: false
    };
    if (!obj.isDisabled) {
      const selecteddaye: any = NgbDatepickerHelper.convertDateStructToDate(date);

      const today = new Date().setHours(0, 0, 0, 0);
      if (!obj.isDisabled && (selecteddaye < today)) {
        obj.isDisabled = true;
      }
    }

    if (!obj.isDisabled) {
      const today = new Date();
      obj.isToday = NgbDatepickerHelper.isEqual(today, date);
      const selecteddaye = NgbDatepickerHelper.convertDateStructToDate(date);
      const weekNo = selecteddaye.getWeek();
      const condition = ((weekNo % this.uiControl.defaultInChargeLength === 0)
        && (selecteddaye.getDay() === (this.uiControl.defaultInChargeDay - 1)));
      obj.isWeekday = condition;
    }
    return obj;
  }

  /**
   * @description cache specific days object if is not exists
   * @author Kishan Patel
   * @param {NgbDateStruct} date - current date object
   * @returns {string}
   * @memberof RangeComponent
   */
  cachenewValidToDateObj(date: NgbDateStruct, disabled: boolean): string {
    const daystring = NgbDatepickerHelper.getDateString(date);
    if (!this.endDayObjCache[daystring]) {
      this.endDayObjCache[daystring] = this.prepareEndDayObj(date, disabled);
    }
    return daystring;
  }

  /**
   * @description get the specific dates property
   * @author Kishan Patel
   * @param {NgbDateStruct} date current day
   * @param {string} key property key to retrive
   * @returns {boolean}
   * @memberof RangeComponent
   */
  getEndDayProp(date: NgbDateStruct, disabled: boolean, key: string): boolean {
    const daystring = this.cachenewValidToDateObj(date, disabled);
    return this.endDayObjCache[daystring][key];
  }

}
