<app-filter-layout [resolveObject]="resolveObject" (closeClick)="closeClick()" (saveClick)="saveClick()">
    <div class="form-group-wrapper_geo grpCnt">
        <div class="form-group_geo">
            <div *ngFor="let tg of tagGroupKeys; trackBy: trackByItem;" id="tab-{{tg}}">
                <span class="text"></span>
                <input type="radio" class="radio_geo" [checked]="tags.tab==tg" (change)="selectTab(tg)" name="radio-tags" id="radio-{{tg}} ">
                <label class="radio-label " [ngClass]="{'highlight-tab': isTagDataSelected(tg)}" for="radio-{{tg}} "><em class="fa fa-circle-o"></em> {{tagGroups[tg].tagGroupName}}</label>
            </div>
        </div>
    </div>
    <div class="form-group-wrapper_geo">
        <!--<h3 class="form-title_geo ">{{searchForText + " " + searchForTagText}}</h3>-->
        <h3 class="form-title_geo"><span class="selected-title">{{searchForText}} {{searchForTagText}}</span></h3>
        <div class="Tag" [ngClass]="{'readOnly' : resolveObject.readOnlyModal}">
            <!--Common Tab-->
            <div class="tab-content" style="display: block;">
                <tag-input [(ngModel)]="tags.allTabSelection[tags.tab]" [identifyBy]="'tagId'" [displayBy]="'tagName'" [onlyFromAutocomplete]="true" [placeholder]="resolveObject.placeHolder"
                    [secondaryPlaceholder]="resolveObject.placeHolder" [inputClass]="'form-control'" [maintainSearchText]="true">
                    <ng-template let-item="item" let-index="index">
                    </ng-template>
                    <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="autocompleteItems" [identifyBy]="'tagId'" [displayBy]="'tagName'"
                        [minimumTextLength]="0" [autocompleteObservable]="loadRoadSide" [visibleMenuAfterItemAdd]="true"
                        [appendToBody]="false">
                    </tag-input-dropdown>
                </tag-input>

                <ul class="summaryContainer">
                    <li *ngFor="let item of tags.allTabSelection[tags.tab]; trackBy: trackByIndex; let i = index;">
                        <label style="width: 80%;">{{item.tagName}}</label>
                        <span style="width: 20%; float: right">
                                <input type="radio" [(ngModel)]="item.exclude" class="radio small include" name="tag-{{i}}" [value]="false" id="include-tag-{{i}}" checked />
                                <label for="include-tag-{{i}}">&nbsp;</label>
                                <input type="radio" [(ngModel)]="item.exclude" class="radio small exclude" name="tag-{{i}}" [value]="true" id="exclude-tag-{{i}}" />
                                <label for="exclude-tag-{{i}}">&nbsp;</label>
                                <span class="right" style="cursor:pointer;" (click)="removeTagsData(i)"><em class="fa fa-times" aria-hidden="true"></em></span>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</app-filter-layout>
