import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import * as toasterConfig from '../../core/toasterConfig';

import { DataShareService } from '../../core/services/data-share.service';
import { LoaderService } from '../../core/services/loader.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
@Component({
  selector: 'app-result-details',
  templateUrl: './result-details.component.html',
  styleUrls: ['./result-details.component.css']
})
export class ResultDetailsComponent implements OnInit, OnDestroy {

  activeAccordion: boolean;

  /**
   * It contains config data which passed from SmartBRICS.
   * It emit event to SmartBRICS application to enable/disable Result/Commercial Tabs.
   */
  @Output() toggleAccordion = new EventEmitter<any>();

  /**
   * It emit event to redirect to Result Tab.
   */
  @Output() goToResultPage = new EventEmitter<any>();

  /**
   * It defines initial configuration data.
   */
  initialConfig: any = { userBundle: [] };
  /**
   * Defiens configuration object for toaster messages.
   */
  toasterConfig: any = toasterConfig.toasterconfig;

  private subscriptions: Subscription[] = [];
  constructor(
    private dataShareService: DataShareService,
    public loader: LoaderService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.subscriptions.push(this.dataShareService.languageChangedSub.subscribe((result) => {
      if (result) {
        this.ngOnInit();
        this.changeDetectorRef.detectChanges();
      }
    }));
  }

  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.activeAccordion = _.isUndefined(this.initialConfig.uiControl.resultDefaultExpandMap) ? false : !this.initialConfig.uiControl.resultDefaultExpandMap; // SM-8216
  }
  /**
   * To redirect to Result page.
   */
  redirectToResultPage() {
    this.goToResultPage.emit();
  }

  onAccordionClick() {
    this.activeAccordion = !this.activeAccordion;
    setTimeout(() => {
      this.toggleAccordion.emit();
    }, 100);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
