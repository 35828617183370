import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-campaign-load-failure',
  templateUrl: './campaign-load-failure.component.html'
})
export class CampaignLoadFailureComponent implements OnInit {

  @Input() resolveObject: any;

  constructor(
  ) { }

  ngOnInit() {
    //
  }

}
