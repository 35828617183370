import { Component, Input, OnInit } from '@angular/core';
import { LookupCampaignModel, LookupCampaignResponseModel } from '../../../models/LookupCampaignResponse.model';
import {
  GoogleAnalyticsEvents, GoogleAnalyticsEventCategory,
  EventActionsCampaignFeatures
} from '../../../GoogleAnalytics/GoogleAnalyticsEvent';
import { Observable, of } from 'rxjs';
import { GeoMapService } from '../../../geo-map/geo-map.service';
import { Router } from '@angular/router';
import { SearchService } from '../../../search';
import { StateService } from '../../services';
import { SystemFlags } from '../../../models';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css']
})
export class GlobalSearchComponent implements OnInit {

  openedCampaigns: LookupCampaignModel[] = [];
  @Input() userBundle = {};
  constructor(
    private geoMapService: GeoMapService,
    private router: Router,
    private searchService: SearchService,
    private stateService: StateService
  ) {
    // This is used for reload component if redirect to same route
    // Need to Refactor
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }
  // @Output() goToSearchPage = new EventEmitter<any>();

  ngOnInit() {
  }

  /**
   * Load the campaign list using service call.
   * @param query queryParameter for http call - serched text
   * @return responsed campaign data
   */
  loadOtherCampaignList = (query: string): Observable<LookupCampaignModel[]> => {
    // Search text should be exact 8 character long - discuss with andrius on slack
    // it will be campaign reference id - which is 8 character long so
    // searchString: "coca" <reference, advertiser, brand, title/> - keeping it 3 or more character
    if (query && query.length >= 3) {
      GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.CampaignFeatures,
        EventActionsCampaignFeatures.LookupCampaign, 'Search other campaign for plot');
      return this.geoMapService.getSearchedCampaignList(query).map((data: LookupCampaignResponseModel) => {
        const returnArr: LookupCampaignModel[] = [];
        if (data && data.campaignLookup) {
          for (const cl of data.campaignLookup) {
            const obj: LookupCampaignModel = {
              campaignId: cl.campaignId,
              campaignLookupDisplay: cl.campaignLookupDisplay
            };
            returnArr.push(obj);
          }
        }
        return returnArr;
      });
    } else {
      return of([]);
    }
  }
  otherCampaignMatchingFn = (): boolean => {
    // data is already filtered by back-end so it will always return true
    // This is needed as we dont want to show searched text in autocomplete and
    // in this case component automatically filter-out data whose text doesn't match search text
    return true;
  }

  /**
   * @description On add campain store comapaing Id to stateservice and redirect to search
   * @author Darshan Vachhani
   * @param {LookupCampaignModel} event
   * @memberof GlobalSearchComponent
   */
  onAddInCampaignList(event: LookupCampaignModel) {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.CampaignFeatures,
      EventActionsCampaignFeatures.GetCampaign, 'Load other campaign for plot');
    event.exclude = false;
    const params = {
      requestParams: {
        id: event.campaignId,
        action: 1
      }
    };
    SystemFlags.isCloneCampaign = false;
    SystemFlags.isRangeSelectionMandatory = false;
    if (this.router.url !== '/' && this.router.url !== '/search') {
      this.router.navigate(['/search']);
    }
    this.router.navigated = false;
    this.openedCampaigns = [];
    if (this.router.url.indexOf('commercial') === -1) {
      this.searchService.emitLoadCampaign(params);
    } else {
      this.stateService.setAnyValue('paramsForSmartSearch', params);
    }
  }
}
