import { Brand } from './brand';
import { ProductCategoryLookUp } from './product-category-lookup';
import { Organisation } from './organisation';

export interface SbSelectedItem {
  id: number;
  text: string;
  invoiceStatusCode?: string;
  source?: any;
}

export interface SbSelectedAgency {
  agencyId: number;
  agencyName: string;
  invoiceStatusCode?: string;
  source?: any;
}

export interface SbSelectedAdvertiser {
  advertiserId: number;
  advertiserName: string;
  invoiceStatusCode?: string;
  source?: any;
  brands?: Brand[];
  productCategory?: ProductCategoryLookUp[];
  organisationTypeId?: number;
  advertiserCode?: string;
}

export interface SbSelectedBrand {
  brandId: number;
  brandName: string;
  invoiceStatusCode?: string;
  source?: any;
  productCategory?: ProductCategoryLookUp[];
  organisations?: Organisation[];
}

export interface SbSelectedSpecialist {
  specialistId: number;
  specialistName: string;
  invoiceStatusCode?: string;
  source?: any;
}

export interface SbSelectedBuyerContact {
  buyerContactId: number;
  buyerContactName: string;
  source?: any;
}

export interface SbSelectedCtuPerson {
  ctuPersonId: number;
  ctuPersonName: string;
  source?: any;
}

export let DEFAULT_SELECTED_ITEMS: SbSelectedItem = {
  id: 0,
  text: ''
};
