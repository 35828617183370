import { Injectable } from '@angular/core';
import { WorkspaceComponent } from '../../geoplanner/src/app/workspace/workspace.component';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StateService, LoaderService } from '../../geoplanner/src/app/core/services';

@Injectable()
export class CanDeactivateWorkspaceService implements CanDeactivate<WorkspaceComponent> {

  constructor(
    private stateService: StateService,
    private loaderService: LoaderService
  ) { }

  // @ts-ignore
  canDeactivate(component: WorkspaceComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean { // tslint:disable-line
    if (this.loaderService.getBackButton()) {
      this.loaderService.setBackButton(false);
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);
      return false;
    }
    this.stateService.setWorkspaceObject('filter', this.stateService.clone(component.filter));
    return component.beforeNavigate();
  }

}
