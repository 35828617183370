import { Component, OnInit, Input } from '@angular/core';
import { CampaignDetails, CommercialConfig, BookingLine } from '../../commercial.model';
import { DataShareService, CommercialService } from '../../../core/services/index';
import { SbSelectedItem, DEFAULT_SELECTED_ITEMS } from '../../../models/sb-selected-item';
import {
  RequestParams, defaultSalesPersonParams
} from '../../../models/request-params';
import { InitialConfigModel, SystemFlags } from '../../../models';
import * as _ from 'lodash';
import { LocaleData } from '../../../core/utils/LocaleData';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-booking-detail-italy',
  templateUrl: './booking-detail-italy.component.html',
  styleUrls: ['.//booking-detail-italy.component.css']

})
export class BookingDetailsItalyComponent implements OnInit {
  @Input() campaignDetails: CampaignDetails;
  @Input() commercialConfig: CommercialConfig;
  @Input() rowIndex: number;
  @Input() bookings: BookingLine[];

  gridDataSubscription: Subscription;
  config: InitialConfigModel;
  booking: BookingLine;
  productionType: any[] = [];

  salesPersonURL: string;
  salesPersonList: SbSelectedItem[] = [];
  selectedSalesPerson: SbSelectedItem = _.clone(DEFAULT_SELECTED_ITEMS);
  public salesPersonSearchParams: RequestParams = defaultSalesPersonParams;

  /**
   * @description date format to use to display date
   * @type {string}
   * @memberof BookingDetailsItalyComponent
   */
  displayDateFormat = '';
  /**
   * @description set readOnly
   * @memberof CampaignDetailsDefaultComponent
   */
  public readOnly = SystemFlags.readOnly;
  constructor(
    private dataShareService: DataShareService,
    private commercialService: CommercialService
  ) { }

  ngOnInit(): void {
    this.config = this.dataShareService.getInitialConfig();
    this.salesPersonURL = this.dataShareService.getServiceCallUrlByKey('LOOKUP_SALES_PERSON_URL');
    this.productionType = this.dataShareService.getInitialConfigByKey('productionType');
    this.booking = this.bookings[this.rowIndex];
    this.displayDateFormat = LocaleData.displayDateFormat;
    this.selectedSalesPerson = this.commercialService.populateSelectedDropdown(this.booking.salesPersonId,
      this.booking.salesPersonName);
  }

  /** This method used on DOM to hide the element
   *
   *
   * @param {*} key
   * @returns
   * @memberof BookingDetailsItalyComponent
   */
  hideUIElements(key) {
    return this.dataShareService.hideUIElements(key);
  }

  // Functions related to Sales Persons//
  onSalesPersonSelected(value: any) {
    this.booking.salesPersonId = this.selectedSalesPerson.id = value.id;
    this.booking.salesPersonName = this.selectedSalesPerson.text = value.text;
  }

  removeSelectedSalesPerson() {
    this.selectedSalesPerson = _.clone(DEFAULT_SELECTED_ITEMS);
    this.booking.salesPersonId = null;
    this.booking.salesPersonName = null;

  }

  getSalesPersonResponse(value: any): void {
    if (!_.isUndefined(value.salesPerson) && value.salesPerson.length > 0) {
      this.salesPersonList = [];
      _.each(value.salesPerson, (item) => {
        this.salesPersonList.push({
          id: item.idSalesPerson,
          text: item.salesPersonName,
          source: item
        });
      });
    }
  }

}
