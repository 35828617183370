<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
  [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
    <div class="modal_content" [ngClass]="{'readOnly' : readOnlyModal}">

        <div class="row">
            <div [ngClass]="{'col-md-7 pr-0': localSolverEnabled}">
                <div class="control_group pr-0">
                    <label for="rbt-frame" [ngStyle]="localSolverEnabled ? {width: '60%'} : {}">{{userBundle['workspace.collection.bric.frame.noOfFrames']}}</label>
                    <input *ngIf="!frame.relative" type="text" class="form_control" [(ngModel)]="frame.frameCount" [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" #frameCount [ngStyle]="localSolverEnabled ? {width: '40%'} : {}" />
                    <input *ngIf="frame.relative" type="text" class="form_control" [(ngModel)]="frame.frameCount" [imask]="relativePercentageInputMask" [unmask]="maskService.unmask.typed" #frameCount [ngStyle]="localSolverEnabled ? {width: '40%'} : {}" />
                    <span *ngIf="frame.relative" class="percentage-illumination" style="display: flex; left: -8%;">
                      <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                      {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
            </div>
            <div class="col-md-5 pl-0" *ngIf="localSolverEnabled && objectiveMode">
                <div class="control_group pl-0">
                    <div class="btn-group">
                      <input type="radio" name="radio_groups_pd" [(ngModel)]="frame.relative" [value]="false" autocomplete="off"
                        id="check_units_pd1" (change)="onChangeRelative(frame.relative)">
                      <label for="check_units_pd1" class="">
                        {{userBundle['common.amount'] || 'Amount'}}
                      </label>
                      <input type="radio" name="radio_groups_pd" [(ngModel)]="frame.relative" [value]="true" autocomplete="off"
                        id="check_units_pd2" (change)="onChangeRelative(frame.relative)">
                      <label for="check_units_pd2" class="">
                        {{userBundle['common.percentage'] || 'Percentage'}}
                      </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="!(objectiveMode && !uiControl.objectiveFrameIlluminationEnabled)">
            <div class="control_group">
              <label for="rbt-illumination">{{userBundle['workspace.collection.bric.frame.illumination']}}</label>
              <input type="text" class="form_control" name="rdillumination" [disabled]="!frame.frameCount"
                [(ngModel)]="frame.illumination" id="rbt-illumination" style="width: 20%" [imask]="percentageInputMask"
                [unmask]="maskService.unmask.typed" />
              <span class="percentage-illumination" *ngIf="frame.illumination" style="display: flex;">
                <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                {{!localSolverEnabled ? '%' : ''}}
              </span>
            </div>
        </div>

        <div class="row">
            <div class="control_group">
                <div class="col-md-7" style="padding:0">
                    <label style="width:57%">{{userBundle['workspace.collection.bric.frame.noOfFramesAllocated']}}</label>
                    <input *ngIf="!frame.relative" type="text" class="form_control txt disable-network" [(ngModel)]="frame.allocated" [disabled]="true" [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" readonly style="width:43%" />
                    <input *ngIf="frame.relative" type="text" class="form_control txt disable-network" [(ngModel)]="frame.allocated" [disabled]="true" [imask]="relativePercentageInputMask" [unmask]="maskService.unmask.typed" readonly style="width:43%" />
                    <span *ngIf="frame.relative" class="percentage-illumination" style="display: flex; left: -8%;">
                      <em class="fa fa-percent" aria-hidden="true"></em>
                    </span>
                </div>
                <div class="col-md-5" *ngIf="uiControl.toleranceEnabled" style="padding:0">
                  <label style="width:44%">{{userBundle['workspace.common.tolerance']}}</label>
                  <input type="text" class="form_control txt" [(ngModel)]="frame.tolerance" [imask]="percentageToleranceInputMask"
                    [unmask]="maskService.unmask.typed" style="width:48%" />
                  <span class="percentage-illumination" style="display: flex; left: -8%;">
                    <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                    {{!localSolverEnabled ? '%' : ''}}
                  </span>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="localSolverEnabled">
            <div class="col-md-6">
                <div class="control_group">
                  <label style="width: 60%">{{userBundle['workspace.bric.greenTolerance']}}</label>
                  <input type="text" class="form_control txt" [(ngModel)]="frame.greenTolerance" appNumberMask [min]="0" [max]="100"
                    [scale]="2" style="width: 40%" (blur)="setDefaultGreenTolerance()" />
                  <span class="percentage-illumination" style="display: flex; left: -8%;">
                    <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                    {{!localSolverEnabled ? '%' : ''}}
                  </span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="control_group">
                  <label style="width: 60%">{{userBundle['workspace.bric.orangeTolerance']}}</label>
                  <input type="text" class="form_control txt" [(ngModel)]="frame.orangeTolerance" appNumberMask [min]="0" [max]="100"
                    [scale]="2" style="width: 40%" (blur)="setDefaultOrangeTolerance()" />
                  <span class="percentage-illumination" style="display: flex; left: -8%;">
                    <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                    {{!localSolverEnabled ? '%' : ''}}
                  </span>
                </div>
            </div>
            <div class="col-md-6" *ngIf="!uiControl.hideObjectivePriority">
                <div class="control_group">
                    <label>{{userBundle['workspace.bric.priority']}}</label>
                    <input type="text" class="form_control txt" [(ngModel)]="frame.priority" appNumberMask [min]="0" [max]="999" [scale]="0" />
                </div>
            </div>
            <div class="col-md-6" *ngIf="!uiControl.hideObjectiveWeight">
                <div class="control_group">
                  <label>{{userBundle['workspace.bric.weight']}}</label>
                  <input type="text" class="form_control txt" [(ngModel)]="frame.weight" appNumberMask [min]="0" [max]="100"
                    [scale]="2" />
                  <span class="percentage-illumination" style="display: flex; left: -8%;">
                    <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                    {{!localSolverEnabled ? '%' : ''}}
                  </span>
                </div>
            </div>
            <div class="control_group">
                <label style="width:50%">{{userBundle['workspace.bric.boostedTarget']}}
        </label>
                <label class="switchAllowFrames">
          <input type="checkbox" [(ngModel)]="frame.boostedTarget">
          <span class="sliderAllowFrames round"></span>
        </label>
            </div>
        </div>
    </div>
</app-sb-modal-wrapper>
