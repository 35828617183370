<div class="innerpage bounceInRight animated">
  <app-result-summary *ngIf="resultData.resultSummary" [labels]="resultSummaryLabels"
    [routePerformance]="resultData?.uiControl?.routePerformanceEnabled"
    [resultSummary]="resultData?.resultSummary"></app-result-summary>
  <app-result-details class="dropdown-show" [initialConfig]="initialConfig" (toggleAccordion)="onAccordionClick()"
    [isDown]="activeAccordion" *ngIf="isResultDataLoaded"></app-result-details>
  <app-map *ngIf="mapHelper" [mapHelper]="mapHelper" [uiControl]="uiControl" [userBundle]="userBundle"
    [isOnline]="isOnline" (toggleAccordion)="onAccordionClick()" [isDown]="activeAccordion"></app-map>
  <div class="footer_btns">
    <button class="btn btn-primary smartbricbtn float_left" (click)="redirectToWorkspacePage()">
      <img src="images/smartbric_icon.png" alt="">{{userBundle['common.smart'] || 'Smart'}}
      <strong>{{userBundle['common.brics'] || 'BRICS'}}</strong>
    </button>

    <div *ngIf="uiControl.showActionButtons || initialConfig.uiControl.showActionButtons">
      <button class="btn btn-book orange right" (click)="saveCampaign(2)"
        [disabled]="readOnly || disableBookedConfirmedCampaign"
        *ngIf="user.resultTabAccess.requestConfirmation && uiControl.showRequestBookButton">
        <img src="images/book.png" alt="">{{userBundle['result.buttonLabel.requestBooking']}}
      </button>
      <button class="btn btn-book grey right" (click)="saveCampaign(3)"
        [disabled]="readOnly || disableBookedConfirmedCampaign"
        *ngIf="user.resultTabAccess.requestOption && uiControl.showRequestOptionButton">
        <img src="images/book.png" alt="">{{userBundle['result.buttonLabel.requestOption']}}
      </button>
      <button class="btn btn-book orange right" (click)="saveCampaign(6)" [disabled]="readOnly"
        *ngIf="user.resultTabAccess.requestConfirmation && uiControl.showBookButton">
        <img src="images/book.png" alt="">{{userBundle['result.buttonLabel.Book']}}
      </button>
      <button class="btn btn-book grey right" (click)="saveCampaign(7)" [disabled]="readOnly"
        *ngIf="user.resultTabAccess.requestOption && uiControl.showOptionButton">
        <img src="images/book.png" alt="">{{userBundle['result.buttonLabel.Option']}}
      </button>
      <button class="btn btn-save right" (click)="saveCampaign(1)"
        *ngIf="user.resultTabAccess.saveSearch && uiControl.showSaveButton"
        [disabled]="readOnly || disableBookedConfirmedCampaign">
        <img src="images/save2.png" alt="">{{userBundle['common.save']}}
      </button>
      <button class="btn btn-proposal right" (click)="proposal(4)"
        *ngIf="user.resultTabAccess.createProposal && !showHidePerformance && !bookingStatusCancelled && uiControl.showProposalButton"
        [disabled]="readOnly">
        <img src="images/proposal.png" alt="">{{userBundle['common.proposal']}}
      </button>
      <button class="btn btn-proposal right" *ngIf="uiControl.showCCP3LinkButton && !bookingStatusCancelled && isLoadedCampaign" (click)="proposal(10)" [disabled]="readOnly">
        <img src="../../../../../images/proposal.png" alt="">{{userBundle['result.buttonLabel.ccp3link']}}
      </button>
      <button class="btn btn-proposal right" (click)="performance(5)" *ngIf="user.resultTabAccess.createProposal && showHidePerformance && !bookingStatusCancelled && uiControl.showPerformanceButton"
      [disabled]="readOnly">
        <img src="../../../../../images/proposal.png" alt="">{{userBundle['result.buttonLabel.performance']}}
      </button>
    </div>
  </div>
</div>
