import { AfterViewInit, Component, ViewChild, forwardRef, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
const CUSTOM_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AgTypeAheadCellEditorComponent),
  multi: true
};
@Component({
  selector: 'app-ag-type-ahead-cell-editor',
  templateUrl: './ag-type-ahead-cell-editor.component.html',
  styleUrls: ['./ag-type-ahead-cell-editor.component.css'],
  providers: [CUSTOM_ACCESSOR]
})
export class AgTypeAheadCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value: any;
  public valueText: string;
  public isParent: boolean;
  private cancelBeforeStart = false;
  public dataArr: any[] = [];
  public valKey = '';
  public textKey = '';

  @ViewChild('input', { read: ViewContainerRef }) public input;

  agInit(params: any): void {
    this.params = params;
    this.value = [];
    this.isParent = this.params.node.data.isParent;
    this.valueText = '';

    this.valKey = this.params.valKey;
    this.textKey = this.params.textKey;
    this.dataArr = this.params.dataArr(this.params.node.data);

    if (this.params.value && this.params.value.length > 0) {
      this.valueText = this.params.value[0][this.textKey];
    }

    // only start edit if key pressed is a number, not a letter
    // this.cancelBeforeStart = this.isParent || (this.params.node.rowPinned == 'bottom' && this.params.rowIndex == 1) ? true : false;
    if (this.cancelBeforeStart === false && this.params.cellEditable) {
      this.cancelBeforeStart = !this.params.cellEditable(this.params);
    }
  }

  getValue(): any {
    return this.params.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
    return false;
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    setTimeout(() => {
      this.input.element.nativeElement.focus();
    }, 10);
  }

  onItemAdded = (item) => {
    this.params.value = [item];
    this.params.stopEditing();
  }
}
