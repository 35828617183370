import { Component, Input, Output, EventEmitter, OnInit, HostListener } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataShareService } from '../../../services/data-share.service';

@Component({
  selector: 'app-sb-modal-wrapper',
  templateUrl: './sb-modal-wrapper.component.html',
  styleUrls: ['./sb-modal-wrapper.component.css']
})
export class SbModalWrapperComponent implements OnInit {

  /**
   * @description Holds the data passed to modal component
   * @type {object}
   * @memberof SbModalWrapperComponent
   */
  @Input() resolveObject: object = {};

  /**
   * @description Displays title on modal
   * @type {string}
   * @memberof SbModalWrapperComponent
   */
  @Input() title = '';

  /**
   * @description set readOnly Button
   * @type {string}
   * @memberof SbModalWrapperComponent
   */
  @Input() readOnlyModal: boolean = false;

  /**
   * @description Gives title background
   * @type {string}
   * @memberof SbModalWrapperComponent
   */
  @Input() titleBackgroundColor = '';

  /**
   * @description emits event on closing modal
   * @type {EventEmitter<any>}
   * @memberof SbModalWrapperComponent
   */
  @Output() closed: EventEmitter<any> = new EventEmitter();

  /**
   * @description emits event on saving modal
   * @type {EventEmitter<any>}
   * @memberof SbModalWrapperComponent
   */
  @Output() saved: EventEmitter<any> = new EventEmitter();

  @Output() cleared: EventEmitter<any> = new EventEmitter();

  @Input() saveButton = 'OK';
  @Input() ignoreButton = 'Ignore';
  @Input() isIgnoreButtonEnabled = false;

  @Input() isButtonHide = false;
  @Input() isdefaultDesign = false;
  @Input() showCloseButton = true;

  @Input() clearAllButton = 'Clear all'
  @Input() isClearAllButtonEnabled = false;

  @Input() footerMessage = '';

  isButtonHideUi = false;

  alternateDesign: number;
  bodyEle: HTMLElement = document.querySelector('body');

  userBundle: object;
  /**
   * Creates an instance of SbModalWrapperComponent.
   * @author Amit Mahida
   * @param {NgbActiveModal} ngbActiveModal
   * @memberof SbModalWrapperComponent
   */
  constructor(
    private ngbActiveModal: NgbActiveModal,
    private dataShareService: DataShareService
  ) {
    setTimeout(() => {
      this.isButtonHideUi = this.isButtonHide;
      this.alternateDesign = this.isdefaultDesign ? null : this.dataShareService.getInitialConfigByKey('alternateDesign');
    });
  }

  ngOnInit() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.saveButton = this.saveButton ? this.saveButton : this.userBundle['common.ok'];
  }

  /**
   * @description Hanldes close event
   * @author Amit Mahida
   * @memberof SbModalWrapperComponent
   */
  close(reason = 'cancel' || 'dismiss') {
    let activeModal: any;
    activeModal = this.ngbActiveModal;
    const data = {
      activeModal,
      reason
    };

    this.closed.emit(data);
  }

  /**
   * @description Hanldes save event
   * @author Amit Mahida
   * @memberof SbModalWrapperComponent
   */
  save() {
    let activeModal: any;
    activeModal = this.ngbActiveModal;
    const data = {
      activeModal
    };
    this.saved.emit(data);
  }
  /**
   * @description Handles Esc keydown event
   * @author Ravi Teja
   * @memberof SbModalWrapperComponent
   */
  @HostListener('window:keydown.esc', ['$event'])
  onEsc(event: KeyboardEvent): void {
    if (event && event.key === 'Escape') {
      this.close('escape');
    }
  }

  /**
  * @description Handles clear form action
  * @author Rushabh Trivedi
  * @memberof SbModalWrapperComponent
  */
  clearAll() {
    let activeModal: any;
    activeModal = this.ngbActiveModal;
    const data = {
      activeModal
    };
    this.cleared.emit(data);
  }
}
