<div [ngClass]="{'readOnly' : readOnly }">
  <div class="width_3">
    <div class="control_group" [hidden]="hideUIElements('common.barterName')">
      <label class="label">{{config.userBundle['common.barterName']}}</label>
      <div class='gcontainer'>
        <app-sb-select [config]="barterLookupConfig" [items]="barterList" [currentItem]="selectedBarter"
          (onItemSelected)="onBarterSelected($event)" (onItemRemoved)="removeSelectedBarter()"
          (responseData)="getBarterResponse($event)">
        </app-sb-select>
        <input type="text" [(ngModel)]="booking.barterInvoiceStatusCode"
          *ngIf="config.uiControl.invoiceClientStatusEnabled" readonly class="status-box right" />
      </div>
    </div>
    <div class="control_group">
      <label class="label"
        [ngClass]="{'required' : (booking.salesPersonId == null || booking.salesPersonId.toString() =='')} ">
        {{config.userBundle['common.salesPersonName']}}
      </label>
      <div class='gcontainer'>
        <app-sb-select id="salesperson" [config]="salesPersonLookupConfig" [items]="salesPersonList"
          [currentItem]="selectedSalesPerson" (onItemSelected)="onSalesPersonSelected($event)"
          (onItemRemoved)="removeSelectedSalesPerson()" (responseData)="getSalesPersonResponse($event)">
        </app-sb-select>
      </div>
    </div>
    <div class="control_group">
      <label class="label datelabel">
        {{config.userBundle['commercial.label.bookingEnd']}}
      </label>
      <input type="text" [disabled]="true" [ngModel]="booking.endDate | dateFormat : displayDateFormat"
        class="form_control col-md-3">
    </div>
    <!-- <div class="control_group">
    <label class="label">{{config.userBundle['common.productionTypeName']}}</label>

    <select id="Select1" class="form_control" [(ngModel)]="booking.productionTypeId">
      <option *ngFor="let item of productionType" [value]="item.productionTypeId">
        {{item.productionTypeName}}
      </option>
    </select>
  </div> -->
    <div class="control_group">
      <label class="label datelabel">
        {{config.userBundle['commercial.label.bookingStart']}}
      </label>
      <div class='gcontainer'>
        <input type="text" [disabled]="true" [ngModel]="booking.startDate | dateFormat : displayDateFormat"
          class="form_control">
      </div>
    </div>

    <!-- <div class="control_group">
    <label class="label">{{config.userBundle['common.buyerContactName']}}</label>
    <div class='gcontainer'>
      <app-sb-select [allowClear]="true" [items]="buyerContactList"
        [currentItem]="selectedBuyerContact" [lookupUrl]="buyerContactURL" [minLength]="2"
        (onItemSelected)="onbuyerContactSelected($event)" (onItemRemoved)="removeSelectedbuyerContact()"
        (responseData)="getBuyerContactResponse($event)"
        [placeholder]="config.userBundle['common.lookup.defaultOption']" [extraParams]="buyerContactSearchParams"
        [searchKeyParamName]="'buyerContactName'" [appendSearchKeyParamNameIn]="'data'" [displayInvoiceCode]='false'>
      </app-sb-select>
    </div>
  </div> -->
  </div>
  <div class="width_3">
    <div class="control_group" [hidden]="hideUIElements('common.barter.commission')">
      <label class="label">{{config.userBundle['common.barter.commission']}}</label>
      <input type="text" class="form_control percentage" [(ngModel)]="booking.barterRate" [appOnlyNumber]="true"
        [decimalPoints]="2" [minValue]="9" [maxValue]="100" />
    </div>

    <div class="control_group">
      <label class="label"
        [ngClass]="{'required' : (booking.adminPersonId == null || booking.adminPersonId.toString() =='')} ">
        {{config.userBundle['common.adminPersonName']}}
      </label>
      <div class='gcontainer'>
        <app-sb-select [config]="adminPersonLookupConfig" [items]="adminPersonList" [currentItem]="selectedAdminPerson"
          (onItemSelected)="onAdminPersonSelected($event)" (onItemRemoved)="removeSelectedAdminPerson()"
          (responseData)="getAdminPersonResponse($event)">
        </app-sb-select>
      </div>
    </div>




  </div>
  <div class="width_3">
    <div class="control_group" [hidden]="hideUIElements('common.barter.commission')">
      <label class="label">{{config.userBundle['common.barter.preValue']}}</label>
      <input type="text" class="form_control" [(ngModel)]="booking.preBarterValue" />
    </div>

    <div class="control_group" [hidden]="hideUIElements('common.ctuPersonName')">
      <label class="label">{{config.userBundle['common.ctuPersonName']}}</label>
      <!-- <select id="ctuperson{{$index}}" class="form_control" [(ngModel)]="booking.ctuPersonId" title="Select CTU Person"
      value="idCtuPerson" text="ctuPersonName" data="ctuPerson" tabindex="{{$index}}"
      lookupurl="{{config.serviceCalls.LOOKUP_CTU_PERSON_URL}}" lookupname="ctuPersonName" action="lookupCtuPerson"
      smart-bricks-chosen>
      <option></option>
    </select> -->
      <div class='gcontainer'>
        <app-sb-select [config]="ctuPersonLookupConfig" [items]="ctuPersonList" [currentItem]="selectedCtuPerson"
          (onItemSelected)="onCtuPersonSelected($event)" (onItemRemoved)="removeSelectedCtuPerson()"
          (responseData)="getCtuPersonResponse($event)">
        </app-sb-select>
      </div>
    </div>
  </div>

  <div class="width_8">
    <div class="control_group">
      <label class="label">{{config.userBundle['common.comments']}}</label>
      <div class='gcontainer'>
        <textarea class="form_control customtextarea-comments" [(ngModel)]="booking.comments" maxlength="2000" cols="30"
          rows="1"></textarea>
      </div>
    </div>
  </div>
  <!-- <label class="subtitles">{{config.userBundle['commercial.title.financialSummary']}}</label> -->

  <!-- <div class="width_3" *ngIf="commercialConfig.uiControl.tradingEnabled">
  <div class="control_group">
    <label class="label">{{config.userBundle['common.grossValue']}}</label>
    <input type="text" class="form_control" [(ngModel)]="booking.grossMediaValue" (change)="manageCalculations()"
      [imask]="iMaskCurrencyOption" [unmask]="'typed'" [(ngModel)]="booking.grossMediaValue" />
  </div>
</div>

<div class="width_3" *ngIf="commercialConfig.uiControl.tradingEnabled">
  <div class="control_group">
    <label class="label">{{config.userBundle['common.netValue']}}</label>
    <input type="text" class="form_control "   [imask]="iMaskCurrencyOption" [unmask]="'typed'"  [disabled]="true" [(ngModel)]="booking.netMediaValue" />
  </div>
</div>

<div class="width_3" *ngIf="!config.uiControl.hidePrice">
  <div class="control_group">
    <label class="label">{{config.userBundle['common.smartBricsValue']}}</label>
    <input type="text" [(ngModel)]="booking.smartBricsValue" [disabled]="true" class="form_control" maxlen="9"
    [imask]="iMaskCurrencyOption" [unmask]="'typed'"  />
  </div>
</div> -->

  <div *ngIf="!booking.hasOwnProperty('hideMediaControl') || !booking.hideMediaControl ">
    <label class="subtitles">{{config.userBundle['commercial.title.mediaInformation']}}</label>

    <div class="width_3">
      <div class="control_group">
        <label class="label" [ngClass]="{'required':(isMediaLengthRequired
           && (booking.mediaLength === null
            || booking.mediaLength === ''|| booking.mediaLength === '0'))}">
          {{config.userBundle['commercial.label.mediaLength']}}</label>
        <input id="mediaLength" type="text" class="form_control" [(ngModel)]="booking.mediaLength"
          [appOnlyNumber]="true" [decimalPoints]="0" [maxValue]="999" />
      </div>
    </div>

  </div>
</div>