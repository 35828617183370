<div *ngIf="config && config.multiple === false">
  <div tabindex="0" [appOffClick]="clickedOutside" class="ui-select-container dropdown" [ngClass]="{'open' : inputMode}">
    <div [ngClass]="{'ui-disabled': _disabled}"></div>
    <div class="ui-select-match" *ngIf="!inputMode">
      <span tabindex="-1" id="gSpan" (click)="matchClick($event)"
        class="btn-default-single btn btn-default btn-secondary form-control ui-select-toggle block-hover"
        style="outline: 0;box-shadow: none;">
        <span *ngIf="active.length <= 0" class="ui-select-placeholder">{{ _disabled ? '' : config.placeholder}}</span>
        <span *ngIf="active.length > 0" class="ui-select-match-text pull-left"
          [ngClass]="{'ui-select-allow-clear': config.allowClear && active.length > 0}" [innerHTML]="active[0][textField]"></span>
        <em class="dropdown-toggle pull-right"></em>
        <em class="caret pull-right" [ngStyle]="{'border-top': '4px solid grey'}"></em>
        <a *ngIf="!_disabled && config.allowClear && active.length>0" class="remove-icon" (click)="remove($event)">
          <em class="fa fa-times" aria-hidden="true"></em>
        </a>
        <div style="min-height: 28px !important;" [hidden]="!config.displayInvoiceCode" class="status-box right">
          {{active[0]?.invoiceStatusCode}}</div>
        <!-- <label type="text" [(ngModel)]="active[0]?.invoiceStatusCode" [hidden]="!_displayInvoiceCode" readonly class="status-box right"> -->
        <!-- <input type="text" [(ngModel)]="active[0].invoiceStatusCode" class="status-box right"> -->

      </span>
    </div>

    <div class="inputContainer" [ngClass]="{'inputContainer-applyUI': applyInputUI}">
      <input id='sb-input' #searchInput type="text" autocomplete="false" tabindex="0" (blur)="focusOutForInput()"
        [disabled]="_disabled" class="form-control ui-select-search block-border input-extend-single" *ngIf="inputMode">
    </div>


    <ul id="popupForSingleMode" (mouseenter)="onPopupMouseEnter()"
      *ngIf="optionsOpened && options && options.length > 0 && !firstItemHasChildren"
      class="ui-select-choices dropdown-menu" role="menu">
      <li *ngFor="let o of options;trackBy: trackByFn" role="menuitem">
        <div class="ui-select-choices-row" [class.active]="isActive(o)" (mouseenter)="selectActive(o)"
          (mousedown)="selectMatch(o, $event)" data-id="popuprow">
          <a id="ui-select-choices-row" href="javascript:void(0)" class="dropdown-item rowItem">
            <div [innerHTML]="o[textField] | highlight:inputValue"></div>
          </a>
        </div>
      </li>
    </ul>
    <ul id="popupForSingleMode"
      *ngIf="isTextFilled && options && options.length < 1"
      class="ui-select-choices dropdown-menu" role="menu">
      <li role="menuitem" class="looking-for-li">
        <div class="ui-select-choices-row">
          <a id="ui-select-choices-row" href="javascript:void(0)" class="dropdown-item rowItem">
            <div>Looking for '{{ inputValue }}'</div>
          </a>
        </div>
      </li>
    </ul>

  </div>
</div>