import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FilterDataService } from '../../../core/services/filter-data.service';
import { DataShareService } from '../../../core/services/data-share.service';
import { SotBase } from '../sot-base';
import { LogHelperService } from '../../../core/services';

@Component({
  selector: 'app-sot-filter',
  templateUrl: './sot-filter.component.html',
  styleUrls: ['./sot-filter.component.css']
})
export class SotFilterComponent extends SotBase implements OnInit {

  /**
   * It will take data to configure filter window.
   */
  @Input() resolveObject;
  /**
   * It calls event to close filter winodw.
   */
  @Output() closeSidebar = new EventEmitter<string>();

  /**
   * Constructor
   * @param filterDataService Service which used to handle filter data.
   * @param dataShareService Service to share data between compoenents.
   */
  constructor(
    private filterDataService: FilterDataService,
    private logHelperService: LogHelperService,
    dataShareService: DataShareService
  ) {
    super(dataShareService);
  }

  /**
   * Function which loads initially.
   */
  ngOnInit() {
    this.init(this.filterDataService.getShare());
  }

  /**
   * To store selected items/data.
   */
  saveClick(): void {
    if (this.shareOfTime.sot && this.shareOfTime.sot > 0) {
      this.filterDataService.setShare(this.shareOfTime);
      this.closeSidebar.emit();
    } else {
      this.logHelperService.logError(this.initialConfig.userBundle['sot.error.zeroSOTNotAllowed']);
    }
  }

  /**
   * To close filter window.
   */
  closeClick(): void {
    this.closeSidebar.emit();
  }

}
