<button aria-label="Close" class="close closeBtn" type="button" (click)="onClose()">x</button>
<div class="modal-body" style="max-height: 600px;">
    <div style="padding: 15px;">
        <ngb-carousel *ngIf="galleryImages && galleryImages.length > 0">
            <ng-template ngbSlide *ngFor="let gallImg of galleryImages; trackBy: trackByItem;" class="animated slideInDown">
                <img *ngIf="initialConfig?.uiControl?.enableNewFolioFurnitureAPI" [src]="gallImg" alt="first slide" style="max-height: 570px;">
                <img *ngIf="!initialConfig?.uiControl?.enableNewFolioFurnitureAPI" [src]="gallImg | secure" alt="first slide" style="max-height: 570px;">
            </ng-template>
        </ngb-carousel>
        <div class="noImageFound" *ngIf="galleryImages.length ==0 && !isLoadingData">
            {{initialConfig.userBundle['geoplanner.text.noGalleryImage'] || 'No image available.'}}
        </div>
        <div class="noImageFound" *ngIf="galleryImages.length ==0 && isLoadingData">
            {{initialConfig.userBundle['geoplanner.text.loadingImage'] || 'Loading...'}}
        </div>
    </div>
</div>
