import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/observable/fromEvent';
import {
  Component, Input, Output, EventEmitter, ElementRef,
  OnInit,
  ViewChild,
  HostListener
} from '@angular/core';
import { SelectItem } from './core/select-item';
import { stripTags } from './core/select-pipes';
import { OptionsBehavior } from './core/select-interfaces';
import { GenericBehavior } from './core/generic-behavior';
import { ChildrenBehavior } from './core/children-behavior';
import { Observable } from 'rxjs';
import { SbSelectService } from './sb-select.service';
import * as _ from 'lodash';
import { SelectConfig } from './core/select-config';

@Component({
  selector: 'app-sb-select',
  templateUrl: './sb-select.component.html',
  styleUrls: ['./sb-select.component.css']
})
export class SbSelectComponent implements OnInit {

  readonly POPUP_FOR_SINGLE_MODE = '#popupForSingleMode';

  /**
   * @description Emits event if options are opened for *this* component
   * @private
   * @memberof SbSelectComponent
   */
  public set optionsOpened(value: boolean) {
    this._optionsOpened = value;
    this.opened.emit(value);
  }

  /**
   * @description
   * @readonly
   * @private
   * @type {boolean}
   * @memberof SbSelectComponent
   */
  public get optionsOpened(): boolean {
    return this._optionsOpened;
  }

  /**
   * @description
   * @readonly
   * @private
   * @type {boolean}
   * @memberof SbSelectComponent
   */
  public get isTextFilled(): boolean {
    if (this.inputMode && this.inputValue && this.inputValue.trim() !== '') {
      return true;
    }
    return false;
  }

  /**
   * @description
   * @type {SelectConfig}
   * @memberof SbSelectComponent
   */
  @Input() public set config(value: SelectConfig) {
    this.configLocal = value;
  }

  public get config(): SelectConfig {
    return this.configLocal;
  }

  public get idField() {
    return this.config && this.config.idField ? this.config.idField : 'id';
  }

  public get textField() {
    return this.config && this.config.textField ? this.config.textField : 'text';
  }

  /**
   * @description Holds the current selected item
   * @memberof SbSelectComponent
   */
  @Input() public set currentItem(value: SelectItem) {
    if (!_.isEmpty(value) && value.hasOwnProperty(this.idField) && value[this.textField] != null && !_.isEmpty(value[this.textField])) {
      this.active[0] = value;
      this.data.next(this.active[0]);
      this.focusToInput(stripTags(value[this.textField]));
    } else {
      this.selectedOption = undefined;
      this.active = [];
    }
  }

  /**
   * @description List of options available
   * @memberof SbSelectComponent
   */
  @Input() public set items(value: any[]) {
    if (!_.isUndefined(value)) {
      if (value.length === 0) {
        this._items = [];
        this.itemObjects = [];
        this.options = [];
      } else {
        this._items = value.filter((item: any) => {
          if ((typeof item === 'string') || (typeof item === 'object' && item[this.textField])) {
            return item;
          }
        });
        this.itemObjects = this._items.map((item: any) => new SelectItem(item, this.idField, this.textField));
        if (this.element.nativeElement.querySelector('#sb-input:focus')) {
          this.openList();
        }
      }
    }
  }

  /**
   * @description Disable the component if is set to true
   * @memberof SbSelectComponent
   */
  @Input() public set disabled(value: boolean) {
    this._disabled = value;
    if (this._disabled === true) {
      this.hideOptions();
    }
  }

  /**
   * @readonly
   * @type {boolean}
   * @memberof SbSelectComponent
   */
  public get disabled(): boolean {
    return this._disabled;
  }

  /**
   * @description Identifies active component
   * @memberof SbSelectComponent
   */
  @Input() public set active(selectedItems: any[]) {
    if (!selectedItems || selectedItems.length === 0) {
      this._active = [];
    } else {
      const areItemsStrings = typeof selectedItems[0] === 'string';

      this._active = selectedItems.map((item: any) => {
        let data = null;
        if (areItemsStrings) {
          data = item;
        } else {
          const obj = {};
          obj[this.idField] = item[this.config.idField];
          obj[this.textField] = item[this.config.textField];
          data = obj;
        }

        return new SelectItem(data, this.idField, this.textField);
      });
    }
  }

  public get active(): any[] {
    return this._active;
  }
  // Constructor
  constructor(
    element: ElementRef,
    private sbSelectService: SbSelectService
  ) {
    this.element = element;
    this.clickedOutside = this.clickedOutside.bind(this);

    const eventStream = Observable.fromEvent(element.nativeElement, 'keyup')
      .map((e) => { this.keyBoardEvent = e; })
      .debounceTime(400);

    eventStream.subscribe(() => { this.inputEvent(this.keyBoardEvent, true); });
  }

  /**
   * @description
   * @readonly
   * @type {boolean}
   * @memberof SbSelectComponent
   */
  public get firstItemHasChildren(): boolean {
    return this.itemObjects[0] && this.itemObjects[0].hasChildren();
  }

  private configLocal: SelectConfig;

  /**
   * @description Search input reference
   * @memberof SbSelectComponent
   */
  @ViewChild('searchInput') searchInput;

  /**
   * @description Local variable to handle current state of component
   * @private
   * @type {boolean}
   * @memberof SbSelectComponent
   */
  public inputMode = false;

  /**
   * @description Helps in identifying for which component options list is opened currently
   * @private
   * @type {boolean}
   * @memberof SbSelectComponent
   */
  // tslint:disable-next-line:variable-name
  private _optionsOpened = false;

  /**
   * @description Manipulates behavior of the options list
   * @private
   * @type {OptionsBehavior}
   * @memberof SbSelectComponent
   */
  private behavior: OptionsBehavior;

  /**
   * @description Holds the current searched term
   * @private
   * @type {string}
   * @memberof SbSelectComponent
   */
  public inputValue = '';

  /**
   * @description Holds list if provided from outside
   * @private
   * @type {Array<any>}
   * @memberof SbSelectComponent
   */

  // tslint:disable-next-line:variable-name
  private _items: any[] = [];

  /**
   * @description Disables component if set to true
   * @private
   * @type {boolean}
   * @memberof SbSelectComponent
   */

  // tslint:disable-next-line:variable-name
  public _disabled = false;

  /**
   * @description Local variable maintain active state of component.
   * @private
   * @type {SelectItem[]}
   * @memberof SbSelectComponent
   */

  // tslint:disable-next-line:variable-name
  private _active: SelectItem[] = [];

  // ============= For Extra properties ===============
  /**
   * @description Handles debounce of input
   * @private
   * @type {Number}
   * @memberof SbSelectComponent
   */
  private isSearchOperationFinished = 0;

  /**
   * @description Handles debounce on keyboard event
   * @private
   * @type {*}
   * @memberof SbSelectComponent
   */
  private keyBoardEvent: any;

  /**
   * @description Handles common data
   * @type {EventEmitter<any>}
   * @memberof SbSelectComponent
   */
  @Output() public data: EventEmitter<any> = new EventEmitter();

  /**
   * @description Triggers selected item from list
   * @type {EventEmitter<any>}
   * @memberof SbSelectComponent
   */
  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onItemSelected: EventEmitter<any> = new EventEmitter();

  /**
   * @description Callback if selected item is removed
   * @type {EventEmitter<any>}
   * @memberof SbSelectComponent
   */
  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onItemRemoved: EventEmitter<any> = new EventEmitter();

  /**
   * @description Handles type in event
   * @type {EventEmitter<any>}
   * @memberof SbSelectComponent
   */
  @Output() public typed: EventEmitter<any> = new EventEmitter();

  /**
   * @description Handles open event of list
   * @type {EventEmitter<any>}
   * @memberof SbSelectComponent
   */
  @Output() public opened: EventEmitter<any> = new EventEmitter();

  /**
   * @description callback which provides response data from server
   * @type {EventEmitter<any>}
   * @memberof SbSelectComponent
   */
  @Output() public responseData: EventEmitter<any> = new EventEmitter();

  /**
   * @description Triggers multiple remove
   * @type {EventEmitter<any>}
   * @memberof SbSelectComponent
   */
  @Output() public removedMultiple: EventEmitter<any> = new EventEmitter();

  public options: SelectItem[] = [];
  public itemObjects: SelectItem[] = [];
  public activeOption: SelectItem;
  public selectedOption: SelectItem;
  public activeOptionBrics: SelectItem;
  public element: ElementRef;

  /**
   * @description Applies boxed ui to input field
   * @type {boolean}
   * @memberof SbSelectComponent
   */
  applyInputUI = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.element.nativeElement.contains(event.target)) {
      this.clickedOutside();
    }
  }

  /**
   * @description sets focut to inner input
   * @author Amit Mahida
   * @private
   * @param {string} [value='']
   * @memberof SbSelectComponent
   */
  private focusToInput(value = ''): void {
    setTimeout(() => {
      const el = this.element.nativeElement.querySelector('#sb-input');
      if (el) {
        el.focus();
        if (this.config.keepSearchTerm) {
          el.value = value.length > 0 ? value : this.inputValue;
        } else {
          this.inputValue = '';
        }

        this.cleanPopUpRowFromActiveStyle();
      }
    }, 10);
  }

  /**
   * @description
   * @author Amit Mahida
   * @private
   * @param {*} value
   * @returns
   * @memberof SbSelectComponent
   */
  private getIndexOfObject(value: any) {
    let indexData;
    if (value) {
      _.each(this.itemObjects, (item, index) => {
        if (item[this.idField] === value[this.idField]) {
          indexData = index;
        }
      });
      return indexData;
    } else {
      return false;
    }
  }

  /**
   * @description
   * @author Amit Mahida
   * @private
   * @param {*} [selectedIndex=-1]
   * @memberof SbSelectComponent
   */
  private cleanPopUpRowFromActiveStyle(selectedIndex: any = -1) {
    const currentIndex = selectedIndex;
    _.each(this.element.nativeElement.querySelectorAll('div[data-id="popuprow"]'), (row, index) => {
      if (index !== currentIndex) {
        row.classList.remove('active');
      } else {
        row.classList.add('active');
      }
    });
  }

  /**
   * @description
   * @author Amit Mahida
   * @private
   * @memberof SbSelectComponent
   */
  private hideOptions(): void {
    this.inputMode = false;
    this.optionsOpened = false;
  }

  /**
   * @description
   * @author Amit Mahida
   * @private
   * @memberof SbSelectComponent
   */
  private selectActiveMatch(): void {
    this.selectMatch(this.activeOption);
  }

  /**
   * @description
   * @author Amit Mahida
   * @private
   * @param {SelectItem} value
   * @param {Event} [e=void 0]
   * @returns {void}
   * @memberof SbSelectComponent
   */
  public selectMatch(value: SelectItem, e: Event = void 0): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.options.length <= 0) {
      return;
    } else {
      this.selectedOption = value;
    }
    if (this.config.multiple === true) {
      if (_.find(this.active, value) == null) {
        this.active.push(value);
      }
      this.data.next(this.active);
    } else if (this.config.multiple === false) {

      this.active[0] = value;
      this.data.next(this.active[0]);
    }

    const selectedItem = _.filter(this.itemObjects, (item) => {
      return (item[this.idField] === value[this.idField]);
    });
    if (selectedItem.length !== 0) {
      value.source = selectedItem[0].source;
    }

    if (this.active.length !== 0 && this.active[0].source && this.active[0].source.invoiceStatusCode) {
      this.active[0].invoiceStatusCode = this.active[0].source.invoiceStatusCode;
    }
    this.doEvent('onItemSelected', value);
    this.hideOptions();

    if (this.config.multiple === true) {
      this.focusToInput('');
    } else {
      this.activeOptionBrics = Object.assign({}, this.activeOption);
      const popupForSingleMode = document.querySelector(this.POPUP_FOR_SINGLE_MODE);
      popupForSingleMode.parentNode.removeChild(popupForSingleMode);
      this.focusToInput(stripTags(value[this.textField]));
    }

  }

  /**
   * @description
   * @author Amit Mahida
   * @private
   * @param {*} params
   * @param {string} lookupUrl
   * @returns
   * @memberof SbSelectComponent
   */
  private callRemoteUrl(params: any, lookupUrl: string) {
    if (this.config.hideLoadingBar) {
      params.hideLoader = true;
    }

    return this.sbSelectService.getData(lookupUrl, params);
  }

  /**
   * @description
   * @author Amit Mahida
   * @param {SelectItem} value
   * @memberof SbSelectComponent
   */
  public selectActive(value: SelectItem): void {
    const menu = document.querySelectorAll(this.POPUP_FOR_SINGLE_MODE);
    if (menu) {
      const active = menu[0].querySelectorAll('.selected');
      if (active && active[0]) {
        active[0].classList.remove('selected');
      }
    }
    this.activeOption = value;
  }

  /**
   * @description
   * @author Amit Mahida
   * @protected
   * @param {*} e
   * @memberof SbSelectComponent
   */
  public focusOutForInput(): void {
    this.clickedOutside();
  }

  /**
   * @description
   * @author Amit Mahida
   * @protected
   * @param {SelectItem} value
   * @returns {boolean}
   * @memberof SbSelectComponent
   */
  public isActive(value: SelectItem): boolean {
    if (this.config && this.config.idField) {
      return this.activeOption[this.textField] === value[this.textField] && this.activeOption[this.config.idField] === value[this.config.idField];
    } else {
      return this.activeOption[this.textField] === value[this.textField];
    }
  }

  /**
   * @description
   * @author Amit Mahida
   * @protected
   * @param {*} e
   * @returns {void}
   * @memberof SbSelectComponent
   */
  public matchClick(e: any): void {
    if (this._disabled === true) {
      return;
    }

    if (!_.isUndefined(this.itemObjects) && this.itemObjects.length > 0) {
      this.applyInputUI = true;
      this.focusToInput();
      this.openList();
      return;
    }

    if (!e.target.classList.contains('glyphicon-remove')) {
      this.inputMode = !this.inputMode;
      if (this.inputMode === true && ((this.config.multiple === true && e) || this.config.multiple === false)) {
        this.focusToInput();
        this.applyInputUI = true;
      }
    }

  }

  /**
   * @description
   * @author Amit Mahida
   * @memberof SbSelectComponent
   */
  public openList(): void {
    this.options = _.cloneDeep(this.itemObjects);
    this.optionsOpened = true;
    this.inputMode = true;

    if (!_.isUndefined(this.selectedOption) && this.selectedOption[this.textField].length > 0) {
      const popupForSingleMode = document.querySelectorAll(this.POPUP_FOR_SINGLE_MODE);
      const matchingText = this.selectedOption.text;
      if (popupForSingleMode) {
        const ancor = popupForSingleMode[0] && popupForSingleMode[0].querySelectorAll('a');
        if (Array.isArray(ancor)) {
          Array.prototype.filter.call(ancor, (a) => {
            if (a.textContent && a.textContent.trim() === matchingText) {
              a.classList.add('selected');
            }
            return true;
          });
        }
      }
    } else if (this.options.length > 0) {
      this.behavior.first();
    }
  }

  /**
   * To handle Backspace input
   * @param e event
   */
  handleBackspaceInput(e) {
    const el: any = this.element.nativeElement.querySelector('div.ui-select-container > input');
    if (!_.isNull(el) && (!el.value || el.value.length <= 0)) {
      if (this.active.length > 0) {
        this.remove(this.active[this.active.length - 1]);
      }
      e.preventDefault();
    }
  }

  /**
   * To handle Esc input
   * @param e event
   */
  handleEscInput(e) {
    this.hideOptions();
    this.element.nativeElement.children[0].focus();
    e.preventDefault();
  }

  /**
   * To handle Delete input
   * @param e event
   */
  handleDelInput(e) {
    if (this.active.length > 0) {
      this.remove(this.active[this.active.length - 1]);
    }
    e.preventDefault();
  }

  /**
   * To handle Up key input
   * @param e event
   */
  handleUpKeyInput(e) {
    this.behavior.prev();
    e.preventDefault();
    const menu = document.querySelectorAll('.dropdown-menu');
    let active;
    if (menu) {
      active = menu[0].querySelectorAll('.active');
    }

    let height = null;
    if (active) {
      height = active[0] && active[0].clientHeight; // Height of <li>
    }
    if (height != null && menu) {
      const top = menu[0].scrollTop; // Current top of scroll window
      const menuHeight = menu[0].scrollHeight; // Full height of <ul>
      if (top !== 0) {
        // All but top item goes up
        menu[0].scrollTop = top - height;
      } else {
        // Top item - go to bottom of menu
        menu[0].scrollTop = menuHeight + height;
      }
    }
  }

  /**
   * To handle Down key input
   * @param e event
   */
  handleDownKeyInput(e) {
    // To do : Change Needed ://
    /* tslint:disable:no-string-literal */
    if (this.getIndexOfObject(this.behavior['actor'].activeOption) === 0) {
      this.cleanPopUpRowFromActiveStyle();
      // var firstrow=this.element.nativeElement.querySelector('div[data-id='popuprow']')
      // $(firstrow).addClass('active');
    }
    this.behavior.next();
    e.preventDefault();
    const menu = document.querySelectorAll('.dropdown-menu');
    let active;
    if (menu) {
      active = menu[0].querySelectorAll('.active');
    }
    let height;
    if (active) {
      height = active[0] && active[0].clientHeight; // Height of <li>
    }
    if (height != null && menu) {
      const top = menu[0].scrollTop; // Current top of scroll window
      const menuHeight = menu[0].scrollHeight; // Full height of <ul>
      const nextHeight = top + height; // Next scrollTop height
      const maxHeight = menuHeight - height; // Max scrollTop height

      if (nextHeight <= maxHeight) {
        // All but bottom item goes down
        menu[0].scrollTop = top + height;
      } else {
        // Bottom item - go to top of menu
        menu[0].scrollTop = 0;
      }
    }
  }

  /**
   * To handle Enter key input
   * @param e event
   */
  handleEnterKeyInput(e) {
    if (this.active.indexOf(this.activeOption) === -1) {
      this.selectActiveMatch();
      this.behavior.next();
    }
    e.preventDefault();
  }

  /**
   * @description
   * @author Amit Mahida
   * @param {*} e
   * @param {boolean} [isUpMode=false]
   * @returns {void}
   * @memberof SbSelectComponent
   */
  public inputEvent(e: any, isUpMode = false): void {
    // tab
    if (e.keyCode === 9) {
      return;
    }
    // e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 38 || e.keyCode === 40 ||
    if (isUpMode && (e.keyCode === 13)) {
      e.preventDefault();
      return;
    }
    if (!isUpMode) {
      switch (e.keyCode) {
        // backspace
        case 8: {
          this.handleBackspaceInput(e);
          break;
        }
        // esc
        case 27: {
          this.handleEscInput(e);
          return;
        }
        // del
        case 46: {
          this.handleDelInput(e);
          break;
        }
        // up
        case 38: {
          this.handleUpKeyInput(e);
          return;
        }
        // down
        case 40: {
          this.handleDownKeyInput(e);
          return;
        }
        // enter
        case 13: {
          this.handleEnterKeyInput(e);
          return;
        }
      }
    }
    // left
    if (!isUpMode && e.keyCode === 37 && this._items.length > 0) {
      this.behavior.first();
      e.preventDefault();
      return;
    }
    // right
    if (!isUpMode && e.keyCode === 39 && this._items.length > 0) {
      this.behavior.last();
      e.preventDefault();
      return;
    }
    const target = e.target || e.srcElement;
    if (target && target.value) {
      this.handleSearchInput(target);
    } else {
      this.options = _.cloneDeep(this.itemObjects);
    }
  }

  /**
   * To handle search input
   * @param target target of the element
   */
  handleSearchInput(target) {
    this.inputValue = target.value;

    this.doEvent('typed', this.inputValue);

    // this portion need to be Execute from consumer of this component
    if (this.inputValue.length >= this.config.minLength && !this.config.searchInItemsOnly) {
      const params = this.getParamsForSearchInput();
      this.callRemoteUrl(params, this.config.lookupUrl).subscribe((res) => {
        if (res['data']) {
          this.responseData.emit(res['data']);
        } else {
          this.items = [];
          this.options = [];
          if (this.searchInput) {
            this.searchInput.nativeElement.value = '';
          }
        }
      });
    } else {
      this.options = this.itemObjects
        .filter((option: SelectItem) => option[this.textField].toLowerCase().indexOf(this.inputValue.toLowerCase()) > -1);
    }

    // =======================================================================
    // Modification as per SmartBrics Requirement
    // this will open List if data is available
    if (this.isSearchOperationFinished === 0) {
      const id = setTimeout(() => {
        if (_.isUndefined(this.activeOptionBrics)) {
          this.cleanPopUpRowFromActiveStyle();
          clearTimeout(id);
        }
      }, 10);
      this.isSearchOperationFinished = 1;
    }
  }

  /**
   * Get Parameters for search input
   */
  getParamsForSearchInput() {
    const params = {};
    if (this.config.extraParams) {
      for (const key in this.config.extraParams) {
        if (this.config.extraParams.hasOwnProperty(key)) {
          params[key] = this.config.extraParams[key];
        }
      }
    }
    if (this.config.appendSearchKeyParamNameIn !== '') {
      const searchKeyParam = {};
      searchKeyParam[this.config.searchKeyParamName] = this.inputValue;
      params[this.config.appendSearchKeyParamNameIn] = Object.assign(searchKeyParam, params[this.config.appendSearchKeyParamNameIn]);
      params[this.config.appendSearchKeyParamNameIn] = JSON.stringify(params[this.config.appendSearchKeyParamNameIn]);
    } else {
      params[this.config.searchKeyParamName] = this.inputValue;
    }
    return params;
  }

  /**
   * @description
   * @author Amit Mahida
   * @memberof SbSelectComponent
   */
  public onPopupMouseEnter() {
    this.cleanPopUpRowFromActiveStyle();
  }

  /**
   * @description
   * @author Amit Mahida
   * @returns {*}
   * @memberof SbSelectComponent
   */
  public ngOnInit(): any {
    this.behavior = (this.firstItemHasChildren) ?
      new ChildrenBehavior(this) : new GenericBehavior(this);
  }

  /**
   * @description
   * @author Amit Mahida
   * @param {*} item
   * @returns {void}
   * @memberof SbSelectComponent
   */
  public remove(item: any): void {
    if (this._disabled === true) {
      return;
    }

    if (this.config.multiple === true && this.active) {
      const index = this.active.indexOf(item);
      this.active.splice(index, 1);
      this.data.next(this.active);
      this.doEvent('onItemRemoved', item);
      this.removedMultiple.emit(item);
    }
    if (this.config.multiple === false) {
      this.active = [];
      this.data.next(this.active);
      this.doEvent('onItemRemoved', item);
      item.stopPropagation();
    }
    this.selectedOption = undefined;

  }

  /**
   * @description
   * @author Amit Mahida
   * @param {SelectItem} item
   * @memberof SbSelectComponent
   */
  public removeOnReset(item: SelectItem): void {
    this.active = [];
    this.data.next(this.active);
    this.doEvent('onItemRemoved', item);
  }

  /**
   * @description
   * @author Amit Mahida
   * @param {string} type
   * @param {*} value
   * @memberof SbSelectComponent
   */
  public doEvent(type: string, value: any): void {
    if ((this as any)[type] && value) {
      (this as any)[type].next(value);
    }
  }

  /**
   * @description
   * @author Amit Mahida
   * @memberof SbSelectComponent
   */
  public clickedOutside(): void {
    this.inputMode = false;
    this.optionsOpened = false;
    this.applyInputUI = false;
  }

  trackByFn(index) {
    return index;
  }
}
