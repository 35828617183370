import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { HistoryStackService } from './history-stack.service';
import { StateService } from './state.service';
import { BrickBaseService } from './brick-base.service';
import { CellAttributeService } from './cell-attributes.service';
import { DataShareService } from './data-share.service';
import { WorkspaceService } from './workspace.service';
import { SystemFlags, Filter } from '../../models';
import { LoadWorkspace } from '../../workspace/load-workspace';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';

import * as _ from 'lodash';
import { ColumnConfigs } from '../../models/MapData.model';
import { GLOBAL } from '../utils/app.constant';

@Injectable()
export class SyncWsBricksService {

  constructor(
    private sharedService: SharedService,
    private historyStackService: HistoryStackService,
    private stateService: StateService,
    private brickBaseService: BrickBaseService,
    private cellAttributeService: CellAttributeService,
    private dataShareService: DataShareService,
    private appHeaderService: AppHeaderService,
    private workspaceService: WorkspaceService
  ) { }

  public getBricks() {
    const params = {
      action: 'getBricks'
    };
    const serviceURL = this.dataShareService.getServiceCallUrlByKey('GET_BRICS');
    return this.sharedService.makeServerCall(params, serviceURL);
  }

  public processCampaignLoad(res) {
    this.historyStackService.resetHistoryStack();
    GLOBAL.BRIC_CAMPAIGN_ID = res.data.bricsCampaignId;

    let bricsData = res.data.bricsData;

    // Alkesh Shah: SM-3842 - No need to reset this flag when this processCampaignLoad method is called from VP or GM.
    // below 2 flags will be reset only in case load campaign from search/global search - right now it done from app-header
    // SystemFlags.getBasketDataForGP = false;
    // SystemFlags.getBasketDataForVP = false;
    SystemFlags.readOnly = res.data.readOnly || false;
    SystemFlags.readOnlyWorkspace = res.data.readOnlyWorkspace || false;
    SystemFlags.splitable = res.data.splitable || false;

    if (res.data.lockedMessage) {
      this.appHeaderService.changeLockedMessage(res.data.lockedMessage);
    } else {
      this.appHeaderService.changeLockedMessage(null);
    }

    if (res.data.infoMessage) {
      this.appHeaderService.changeInfoMessage(res.data.infoMessage);
    } else {
      this.appHeaderService.changeInfoMessage(null);
    }

    if (bricsData && bricsData.length > 0) {
      bricsData = _.sortBy(bricsData, 'row');
      this.bricksDataCalculations(bricsData, res.data);
    }
  }

  /**
   * @description Updates workspace's brics related data on filter update
   * @author Amit Mahida
   * @param {*} bricsData
   * @param {*} data
   * @memberof SyncWsBricksService
   */
  public bricksDataCalculations(bricsData: any, data: any) {

    let columnConfig: ColumnConfigs = this.stateService.getWorkspaceObject('columnConfig');
    if (data.columnConfig) {
      this.stateService.setWorkspaceObject('columnConfig', data.columnConfig);
      columnConfig = data.columnConfig;
    }

    let columnLineNumber = this.stateService.getWorkspaceObject('columnLineNumber');
    if (data.columnLineNumber) {
      this.stateService.setWorkspaceObject('columnLineNumber', data.columnLineNumber);
      columnLineNumber = data.columnLineNumber;
    }

    let columnSummary = this.stateService.getAllColumnSummary();
    if (data.columnSummary && Object.keys(data.columnSummary).length > 0) {
      this.stateService.setColumnSummary(data.columnSummary);
      columnSummary = data.columnSummary;
    }

    let columnHelper = this.stateService.getColumnHelper();
    if (data.columnHelper && Object.keys(data.columnHelper).length > 0) {
      this.stateService.setColumnHelper(data.columnHelper);
      columnHelper = data.columnHelper;
    }
    // create New Workspace filter object to load campaign
    // this will create new instance if not exit else use same instance of filter
    let filterObj: Filter = this.stateService.getWorkspaceFilterObj();
    const loadWorkspace: LoadWorkspace = new LoadWorkspace(this.brickBaseService, this.cellAttributeService, this.sharedService, this.stateService);
    filterObj = loadWorkspace.getWorkspaceFilter(
      filterObj,
      bricsData,
      this.dataShareService.getInitialConfig(),
      this.dataShareService.getBricsMasterData(),
      columnConfig,
      columnSummary,
      data.lookupData,
      null,
      columnLineNumber,
      columnHelper
    );
    filterObj = this.workspaceService.validateBricksAgainstColumnConfig(filterObj, columnConfig);
    this.stateService.setWorkspaceObject('filter', filterObj);
    this.cacheWorkspace(data);
  }

  /**
   * @description updates state of workspace properties
   * @author Amit Mahida
   * @param {*} data
   * @memberof SyncWsBricksService
   */
  cacheWorkspace(data): void {
    if (data && data.campaignSummary && data.campaignSummary.length &&  SystemFlags.isCampaignSummaryChanged) {
      this.stateService.setCampaignSummary(data.campaignSummary);
    }
    this.stateService.setColumnSummary(data.columnSummary);
    this.stateService.setColumnHelper(data.columnHelper);
    if (data.columnConfig) {
      this.stateService.setWorkspaceObject('columnConfig', data.columnConfig);
    }

    if (data.campaignParameters) {
      this.stateService.setWorkspaceObject('campaignParameters', data.campaignParameters);
    }
  }

}
