import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, CommercialConfig, HistoryROC } from '../../commercial.model';
import { DataShareService } from '../../../core/services/data-share.service';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import * as _ from 'lodash';
import { StateService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { CampaignDetailsBase } from '../campaign-details-base';
import { SystemFlags } from '../../../models/system-flags';
import { CampaignGroupTypeList } from '../../../core/enum';
import { Campaign } from '../../../models/campaign';
import { NumberMaskModel } from '../../../models/imask';

@Component({
  selector: 'app-campaign-details-sg',
  templateUrl: './campaign-details-sg.component.html',
  styleUrls: ['./campaign-details-sg.component.css']
})
export class CampaignDetailsSgComponent extends CampaignDetailsBase implements OnInit, OnDestroy {
  /**
   * @description - Campaign detail object
   * @type {CampaignDetails}
   * @memberof CampaignDetailsSgComponent
   */
  @Input() campaignDetails: CampaignDetails;
  /**
   * @description - Commercial configuration object
   * @type {CommercialConfig}
   * @memberof CampaignDetailsSgComponent
   */
  @Input() commercialConfig: CommercialConfig;
  @Input() historyROC: HistoryROC;
  @Output() manuallyCPTUpdated: EventEmitter<any> = new EventEmitter();

  /**
   * @description - booking line data change subscription
   * @type {Subscription}
   * @memberof CampaignDetailsSgComponent
   */
  bookingLineDataSubscription: Subscription;

  /**
   * @description set readOnly
   * @memberof CampaignDetailsSgComponent
   */
  public readOnly = SystemFlags.readOnly;

  campaignGroupTypeList = CampaignGroupTypeList;

  currencyInputMask: NumberMaskModel;

  searchPageObject: Campaign;

  /**
   * Creates an instance of CampaignDetailsSgComponent.
   * @author Alkesh Shah
   * @param {DataShareService} dataShareService
   * @param {LookupViewService} lookupViewService
   * @param {CampaignDetailsService} campaignDetailsService
   * @param {CommercialService} commercialService
   * @param {StateService} stateService
   * @memberof CampaignDetailsSgComponent
   */
  constructor(
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    commercialService: CommercialService,
    stateService: StateService,
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService
    );
    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe((value: any) => {
      if (value.rowIndex >= 0 && value.columnName) {
        this.calculateCPT(value);
      }
      this.onBookingLineChange();
    });
  }

  /**
   * @description - angular life cycle hook - call on component destroy
   * @author Alkesh Shah
   * @memberof CampaignDetailsSgComponent
   */
  ngOnDestroy() {
    this.bookingLineDataSubscription.unsubscribe();
  }

  /**
   * @description - Angular life cycle hook - call on component init
   * @author Alkesh Shah
   * @memberof CampaignDetailsSgComponent
   */
  ngOnInit(): void {
    super.init(this.campaignDetails);
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    super.enableSubAgency();
    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);

    this.setLookupConfig();
    this.currencyInputMask = this.maskService.currencyInputMask({ max: 999999999999999999999, padFractionalZeros: true });
    this.searchPageObject = this.stateService.getCampaign();
  }

  /**
   * @description To set the configuration for all lookup components
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setLookupConfig() {
    this.setAdvertiserConfig();
    this.setBrandConfig();
    this.setSpecialistConfig();
    this.setAgencyConfig();
    this.setSubAgencyConfig();
  }

  /**
   * @description To set the configuration for agency lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setAgencyConfig() {
    super.setAgencyConfig();
    this.agencyLookupConfig.displayInvoiceCode = this.uiControl && this.uiControl.invoiceClientStatusEnabled;
  }

  /**
   * @description - callback method call on specialist name change
   * @author Alkesh Shah
   * @param {*} value
   * @memberof CampaignDetailsSgComponent
   */
  onSpecialistNameSelected(value: any): void {
    if (!_.isUndefined(this.campaignDetails.specialist)) {
      super.onSpecialistChange(value);
      this.manageGrossMediaValueWithoutIndex(); // This function has to be used in case if the booking grid is used;
    }
  }

  /**
   * @description - callback method call on removing selected specialist
   * @author Alkesh Shah
   * @memberof CampaignDetailsSgComponent
   */
  removeSelectedSpecialistName() {
    super.onSpecialistChange();
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description - Callback method call on changing specialist percentage
   * @author Alkesh Shah
   * @memberof CampaignDetailsSgComponent
   */
  onSpecialistPercentageChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description - callback method call on agency name change
   * @author Alkesh Shah
   * @param {*} value
   * @memberof CampaignDetailsSgComponent
   */
  onAgencyNameSelected(value: any): void {
    if (!_.isUndefined(this.campaignDetails.agency)) {
      super.onAgencyChange(value);
      super.enableSubAgency();
      this.manageGrossMediaValueWithoutIndex();
    }
  }

  /**
   * @description - callback method call on removing selected agency
   * @author Alkesh Shah
   * @memberof CampaignDetailsSgComponent
   */
  removeSelectedAgencyName(): void {
    super.onAgencyChange();
    super.enableSubAgency();
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description - Callback method call on changing agency percentage
   * @author Alkesh Shah
   * @memberof CampaignDetailsSgComponent
   */
  onAgencyPercentageChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description - calculate CPT
   * @author Alkesh Shah
   * @param {*} value
   * @memberof CampaignDetailsSgComponent
   */
  calculateCPT(value) {
    if (value.columnName === 'grossMediaValue') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex,
        undefined, Number(this.campaignDetails.bookingList[value.rowIndex].grossMediaValue));
    } else if (value.columnName === 'pmpdiscount') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex);
    }
  }

  /**
   * @description on CPT value changes then update pmp discount in grid
   * @author Alkesh Shah
   * @memberof CampaignDetailsUkComponent
   */
  onAverageCPTChanged() {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT); // SM-4776
    this.commercialService.notifyCampaignDetails(this.campaignDetails.bookingList);
    if (this.uiControl.enableUserDefinedCPMPricingCalculation) {
      this.manuallyCPTUpdated.emit();
    }
  }

  /**
   * @description calculate gross media value of each bookingList
   * @author Alkesh Shah
   * @memberof CampaignDetailsSgComponent
   */
  manageGrossMediaValueWithoutIndex() {
    if (this.campaignDetails.bookingList) {
      // @ts-ignore
      this.campaignDetails.bookingList.forEach((element, index) => {
        this.campaignDetailsService.manageGrossMediaValue(index, this.campaignDetails, 'bookingList');
      });
    }
    this.commercialService.notifyGrid(this.campaignDetails.bookingList);
  }

  /**
   * @description bookingList change callback method
   * @author Alkesh Shah
   * @memberof CampaignDetailsSgComponent
   */
  onBookingLineChange() {
    this.campaignDetailsService.populateAgencyPercentage(this.campaignDetails);
    this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description - callback method called on assign to value change
   * @author Alkesh Shah
   * @memberof CampaignDetailsSgComponent
   */
  assignedToChange() {
    this.campaignDetailsService.populateAgencyPercentage(this.campaignDetails);
    this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
    super.enableSubAgency();
    this.setActiveAssignedTo();
  }
}
