import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { getFormData } from '../../utils/formData';
import { GLOBAL } from '../../utils/app.constant';
import { throwError } from 'rxjs';
@Injectable()
export class SbSelectService {

  constructor(
    private http: HttpClient
  ) { }

  extractData(res: Response) {
    return res;
  }

  handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body: any = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }

  getData(url, params) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    params = { ...params, hideLoader: true };
    const requestOptions = {
      headers,
      body: getFormData(params),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      url,
      requestOptions
    )
      .map(this.extractData)
      .catch(this.handleError);
  }

}
