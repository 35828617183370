/* tslint:disable */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { Frame, SwapData } from '../../../models';
declare const _;
@Injectable()
export class SwapZoneService {

  /**
   * @description displays swap list in swap details sidebar
   * @memberof SwapZoneService
   */
  swaps: SwapData[] = [];

  /**
   * @description holds frame list to swap with
   * @type {Frame[]}
   * @memberof SwapZoneService
   */
  framesList: Frame[] = [];
  framesListGroupByVU: { [key: string]: Frame[] };

  /**
   * @description toggles swap mode
   * @type {boolean}
   * @memberof SwapZoneService
   */
  swapMode = false;

  /**
   * @description swap data source subject
   * @private
   * @type {Subject<SwapData>}
   * @memberof SwapZoneService
   */
  private swapDataSource: Subject<SwapData> = new Subject();

  /**
   * @description swap Data observable
   * @type {Observable<SwapData>}
   * @memberof SwapZoneService
   */
  public swapData$: Observable<SwapData> = this.swapDataSource.asObservable();

  /**
   * @description revert swap source subject
   * @private
   * @type {Subject<SwapData>}
   * @memberof SwapZoneService
   */
  private revertSwapSource: Subject<SwapData> = new Subject();

  private revertFrameSwapSource: Subject<boolean> = new Subject();

  /**
   * @description revert swap observable
   * @type {Observable<SwapData>}
   * @memberof SwapZoneService
   */
  public swapRevert$: Observable<SwapData> = this.revertSwapSource.asObservable();

  public swapFrameRevert$: Observable<boolean> = this.revertFrameSwapSource.asObservable();

  /**
   * @description swap done source subject
   * @private
   * @type {Subject<SwapData>}
   * @memberof SwapZoneService
   */
  private swapDoneSource: Subject<SwapData> = new Subject();

  /**
   * @description swap done observable
   * @type {Observable<SwapData>}
   * @memberof SwapZoneService
   */
  public swapDone$: Observable<SwapData> = this.swapDoneSource.asObservable();

  /**
   * @description swap mode subject
   * @private
   * @type {Subject<boolean>}
   * @memberof SwapZoneService
   */
  private swapModeSource: Subject<boolean> = new Subject();

  /**
   * @description swap mode observable
   * @type {Observable<boolean>}
   * @memberof SwapZoneService
   */
  public swapMode$: Observable<boolean> = this.swapModeSource.asObservable();

  /**
   * @description show/hide update swap button
   * @type {boolean}
   * @memberof SwapZoneService
   */
  showUpdateButton = false;

  allSwapsSelected = false;

  /**
   * @description sets swap data
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @memberof SwapZoneService
   */
  setSwapData(swapData: SwapData) {
    if (Object.keys(swapData).length > 0) {
      for (const swap of this.swaps) {
        if (swap.campaignReferences.length === 1) {
          const cref = swap.campaignReferences[0];
          const indexOfMatchingSwapData = swapData.campaignReferences.findIndex(
            crefSwap => cref.campaignReference === crefSwap.campaignReference
              && cref.startDate === crefSwap.startDate
              && cref.endDate === crefSwap.endDate);
          if (indexOfMatchingSwapData > -1) {
            swap.frames = swap.frames.concat(swapData.frames);
            swapData.campaignReferences.splice(indexOfMatchingSwapData, 1);
          }
        }
      }
      if ((swapData.campaignReferences.length || (swapData.bookingDetailGroups && swapData.bookingDetailGroups.length)) && swapData.frames.length) {
        this.swaps.push(swapData);
      }
    } else {
      this.resetAll();
    }
    this.manageVisibilityOfUpdateButton();
    this.swapDataSource.next(swapData);
  }

  /**
   * @description setup frame list
   * @author Amit Mahida
   * @param {Frame[]} frames
   * @memberof SwapZoneService
   */
  setFramesList(frames: Frame[]) {
    this.framesList = frames;
    this.framesListGroupByVU = _.groupBy(this.framesList, 'visualUnitId');
  }

  /**
   * @description returns frame list
   * @author Amit Mahida
   * @returns {Frame[]}
   * @memberof SwapZoneService
   */
  getFramesList(): Frame[] {
    return this.framesList;
  }

  /**
   * @description toggles swap details sidebar
   * @author Amit Mahida
   * @param {boolean} swapMode
   * @memberof SwapZoneService
   */
  openSwapZone(swapMode: boolean) {
    this.swapModeSource.next(swapMode);
  }

  /**
   * @description updates concertina data on swap
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @memberof SwapZoneService
   */
  updateConcertinaOnSwap(swapData: SwapData) {
    this.swapDoneSource.next(swapData);
  }

  updateFrameRevertOnSwap(frameRevert: boolean) {
    this.revertFrameSwapSource.next(frameRevert);
  }

  /**
   * @description Handles swap revert operation
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @param {number} swapDataIndex
   * @param {number} [frameIndex]
   * @memberof SwapZoneService
   */
  handleSwapRevert(swapData: SwapData, swapDataIndex: number, frameIndex?: number) {
    if (!_.isUndefined(frameIndex) && swapData.frames[frameIndex].newY) {
      swapData.onlyToFrameIndex = frameIndex;
    } else {
      this.swaps.splice(swapDataIndex, 1);
    }
    this.revertSwapSource.next(swapData);
    if (!_.isUndefined(frameIndex)) {
      delete swapData.onlyToFrameIndex;
      delete swapData.frames[frameIndex].newY;
      delete swapData.frames[frameIndex].newFrame;
      delete swapData.frames[frameIndex].newVisualUnitId;
    }
  }

  /**
   * @description Triggers revert event of a swap / new frame selected along with vu frames
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @param {number} swapDataIndex
   * @param {number} [frameIndex]
   * @memberof SwapZoneService
   */
  revertSwap(swapData: SwapData, swapDataIndex: number, frameIndex?: number) {
    if (!_.isUndefined(frameIndex) && swapData.frames[frameIndex].newVisualUnitId) {
      const vuId = swapData.frames[frameIndex].newVisualUnitId;
      for (let index = 0; index < swapData.frames.length; index++) {
        const vuFrame = swapData.frames[index];
        const vuNewFrame = vuFrame.newY ? swapData.frames[index].newFrame[0] : null;
        if (vuNewFrame && vuNewFrame.visualUnitId && vuNewFrame.visualUnitId === vuId) {
          vuFrame.newFrame = [];
          this.handleSwapRevert(swapData, swapDataIndex, index);
        }
      }
    }
    this.handleSwapRevert(swapData, swapDataIndex, frameIndex);
    this.manageVisibilityOfUpdateButton();
  }

  /**
   * @description Manages visibility of update button
   * @author Amit Mahida
   * @memberof SwapZoneService
   */
  manageVisibilityOfUpdateButton() {
    let allSwapDone = false;
    for (const swap of this.swaps) {
      if (swap.frames.length === swap.frames.filter(similarVuFrame => similarVuFrame.newFrame && similarVuFrame.newFrame.length).length) {
        allSwapDone = true;
      } else {
        allSwapDone = false;
        break;
      }
    }
    if (allSwapDone) {
      this.showUpdateButton = true;
    } else {
      this.showUpdateButton = false;
    }
  }

  /**
   * @description resets all swaps
   * @author Amit Mahida
   * @memberof SwapZoneService
   */
  resetAll() {
    for (let index = this.swaps.length - 1; index >= 0; index--) {
      const swap = this.swaps[index];
      this.revertSwap(swap, index);
    }
    this.swaps = [];
  }

  /**
   * @description returns selected swap's count
   * @author Amit Mahida
   * @returns {number}
   * @memberof SwapZoneService
   */
  getSelectedSwapsCount(): number {
    return this.swaps.filter(swap => swap.selected).length;
  }

  /**
   * @description to select all swaps at once
   * @author Amit Mahida
   * @memberof SwapZoneService
   */
  onSelectAllSwaps() {
    for (const swap of this.swaps) {
      if (this.allSwapsSelected) {
        swap.selected = true;
      } else {
        swap.selected = false;
      }
    }
  }

  /**
   * @description checks if all swaps are selected
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof SwapZoneService
   */
  isAllSwapsSelected(): boolean {
    return this.getSelectedSwapsCount() === this.swaps.length;
  }

  /**
   * @description reverts selected swaps
   * @author Amit Mahida
   * @memberof SwapZoneService
   */
  deleteSwaps() {
    if (this.allSwapsSelected) {
      for (let index = this.swaps.length - 1; index >= 0; index--) {
        const swap = this.swaps[index];
        // const swapDoneFrames = swap.frames.filter(frame => frame.newFrame && frame.newFrame.length);
        if (swap.selected && swap.frames && swap.frames.length) {
          for (let frameIndex = 0; frameIndex < swap.frames.length; frameIndex++) {
            const frame = swap.frames[frameIndex];
            if (frame.newFrame && frame.newFrame.length) {
              this.revertSwap(swap, index, frameIndex);
            }
          }
        }
      }
    }
    for (let index = this.swaps.length - 1; index >= 0; index--) {
      const swap = this.swaps[index];
      if (swap.selected) {
        this.revertSwap(swap, index);
      }
    }
    if (this.swaps.length === 0) {
      this.allSwapsSelected = false;
    }
  }

  getSwapReqParams() {
    const params: any = {};
    this.swaps.forEach((swap) => {
      for (const cref of swap.campaignReferences) {
        if (!params[cref.campaignReference]) {
          params[cref.campaignReference] = [];
        }
        for (const swapVu of swap.frames) {
          params[cref.campaignReference].push(
            {
              frameId: swapVu.frameId,
              startDate: cref.startDate,
              endDate: cref.endDate,
              newFrameId: swapVu.newFrame[0].frameId,
              sot: cref.sot
            }
          );
        }
      }
    });
    return params;
  }

  /**
   * @description return list of frames with similar visual unit id
   * @author Amit Mahida
   * @param {Number} visualUnitId
   * @memberof SwapZoneService
   */
  getFramesForSameVu(visualUnitId: number) {
    return this.framesList.filter(frame => frame.visualUnitId === visualUnitId);
  }

  /**
   * @description Returns total no of unique campaigns in swap zone
   * @author Amit Mahida
   * @returns
   * @memberof SwapZoneService
   */
  getTotalCampaignRefsForSwap() {
    const uniqueCampaigns = [];
    this.swaps.forEach((swap) => {
      swap.campaignReferences.forEach((cref) => {
        if (uniqueCampaigns.indexOf(cref.campaignReference) === -1) {
          uniqueCampaigns.push(cref.campaignReference);
        }
      });
    });
    return uniqueCampaigns.length;
  }

}
