import { ColumnHelper } from './column-helper';

export class ProcessParams {
  bricsCampaignId: number;
  action: string;
  data: string;
}

export class ProcessBrick {
  bricsData = [];
  campaignParameters: CampaignParameters;
  columnHelper: ColumnHelper = {};
  columnLineNumber: {};
  deletedColumns: number[] = [];
}

export class CampaignParameters {
  constructor(
    public quocFactor = 5,
    public audienceFactor = 5
  ) { }
}
