import { Component, OnInit, Input } from '@angular/core';
import { ResultService } from '../result.service';
import { GLOBAL } from '../../core/utils/app.constant';

@Component({
  selector: 'app-result-summary',
  templateUrl: './result-summary.component.html',
  styleUrls: ['./result-summary.component.css']
})
export class ResultSummaryComponent implements OnInit {

  @Input() labels: any = {};
  @Input() availability: boolean;
  @Input() routePerformance: boolean;
  @Input() resultSummary: any = {};
  public isDown = true;
  public selectedSummary: any = null;
  constructor(private resultService: ResultService) { }

  ngOnInit() {
    this.selectedSummary = this.resultSummary ? this.resultSummary.targetValues.length - 1 : null;
  }

  getResultSummary(event) {
    event.stopPropagation();
    const param = {
      action: 'getRouteData',
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
    };

    this.resultService.getCoverageAndFrequency(param).subscribe((res) => {
      if (res.status.toUpperCase() === 'OK' && res.data) {
        this.resultSummary = {};
        this.resultSummary = res.data.resultSummary;
      } else {
        console.error(this.labels.error);
      }
    }, () => {
      console.error(this.labels.error);
    });
  }

  toggleValues(value: string, index?: number) {
    if (value === 'target') {
      this.selectedSummary = index;
    } else if (value === 'actual') {
      this.selectedSummary = 'actual';
    }
  }

  trackByItem(index, item) {
    return item;
  }

  trackByIndex(index, item) {
    return index;
  }

}
