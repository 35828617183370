import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, CommercialConfig, HistoryROC } from '../../commercial.model';
import { DataShareService } from '../../../core/services/data-share.service';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { StateService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { CampaignDetailsBase } from '../campaign-details-base';
import { SystemFlags } from '../../../models/system-flags';
import { SbSelectedBrand } from '../../../models';

@Component({
  selector: 'app-campaign-details-default',
  templateUrl: './campaign-details-default.component.html',
  styleUrls: ['./campaign-details-default.component.css']
})
export class CampaignDetailsDefaultComponent extends CampaignDetailsBase implements OnInit, OnDestroy {
  @Input() campaignDetails: CampaignDetails;
  @Input() commercialConfig: CommercialConfig;
  @Input() historyROC: HistoryROC;
  bookingLineDataSubscription: Subscription;

  /**
   * @description set readOnly
   * @memberof CampaignDetailsDefaultComponent
   */
  public readOnly = SystemFlags.readOnly;

  public customerId = null;

  constructor(
    commercialService: CommercialService,
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    stateService: StateService
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService

    );
    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe(() => {
      this.onBookingLineChange();
    });
  }

  ngOnDestroy() {
    this.bookingLineDataSubscription.unsubscribe();
  }

  ngOnInit(): void {
    super.init(this.campaignDetails);
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    this.enableSubAgency();
    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);
    this.setLookupConfig();
    this.setCustomerId();
  }

  onAdvertiserNameSelected(value): void {
    super.onAdvertiserNameSelected(value);
    this.setCustomerId();
  }

  onBrandSelected(value: SbSelectedBrand): void {
    super.onBrandSelected(value);
    this.setCustomerId();
  }

  // Functions Related to Specialist//
  onSpecialistNameSelected(value: any): void {
    if (this.campaignDetails.specialist) {
      super.onSpecialistChange(value);
      this.commercialService.notifyGrid(this.campaignDetails); // This is used when booking Details is used
    }
  }

  removeSelectedAdvertiserName(event) {
    super.removeSelectedAdvertiserName(event);
    this.setCustomerId();
  }

  removeSelectedBrand(event: any) {
    super.removeSelectedBrand(event);
    this.setCustomerId();
  }

  removeSelectedSpecialistName() {
    super.onSpecialistChange();
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  onSpecialistPercentageChange() {
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  // Functions related to Agencies//
  onAgencyNameSelected(value: any): void {
    if (this.campaignDetails.agency) {
      super.onAgencyChange(value);
      this.enableSubAgency();
      this.commercialService.notifyGrid(this.campaignDetails);
    }
  }
  removeSelectedAgencyName(): void {
    super.onAgencyChange();
    this.enableSubAgency();
    this.commercialService.notifyGrid(this.campaignDetails);
  }
  onAgencyPercentageChange() {
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  manageGrossMediaValueWithoutIndex() {
    if (this.campaignDetails.bookings) {
      // @ts-ignore
      this.campaignDetails.bookings.forEach((element, index) => {
        this.campaignDetailsService.manageGrossMediaValue(index, this.campaignDetails, 'bookings');
      });
    }
  }

  onBookingLineChange() {
    this.campaignDetails.grossMediaValue = 0;
    this.campaignDetails.netMediaValue = 0;
    this.campaignDetails.bookings.forEach((booking) => {
        this.campaignDetails.grossMediaValue += booking.grossMediaValue;
        this.campaignDetails.netMediaValue += booking.netMediaValue;
    });
  }

  assignedToChange() {
    this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
    this.enableSubAgency();
    this.setActiveAssignedTo();
  }

  setCustomerId() {
    if (this.campaignDetails && this.campaignDetails.advertiser && this.campaignDetails.advertiser.organisationTypeId === 1) {
      this.customerId = this.campaignDetails.advertiser.advertiserCode;
    } else {
      this.customerId = null;
    }
  }
}
