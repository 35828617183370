<div class="tab-container">
  <div class="tabs-menu">
    <div id="viewContainer" (window:resize)="onResize()"
      [ngStyle]="{'width': initialConfig.userData.locale == 'de-de' && screen.width > 1536 ? 'calc(100% - 468px)' : ''}">
      <div class="tab-list" [ngStyle]="{'width': initialConfig.userData.locale == 'de-de' ? '1550px' : ''}">
        <div class="tab-item" *ngFor="let tab of tabs; trackBy: trackByItem; let i = index" [ngClass]="{'current': selectedTabIndex == i}">
          <a href="javascript:void(0)" (click)="selectConcertinaTab(i, tab)">
            <span class="text">{{userBundle['result.concertina.tab.' + tab ]}}</span>
          </a>
        </div>
      </div>
    </div>
    <div [hidden]="!frameStatusFiltersEnabled || !showNavigation" id="leftArrow" (click)="tabSlideLeft()"> <em
        class="fa fa-angle-double-left" aria-hidden="true"></em> </div>
    <div [hidden]="!frameStatusFiltersEnabled || !showNavigation" id="rightArrow" (click)="tabSlideRight()"
      [ngStyle]="{'right': initialConfig.userData.locale == 'de-de' ? '570px' : ''}"> <em
        class="fa fa-angle-double-right" aria-hidden="true"></em> </div>


        <div class="float_right" [ngClass]="{'right0': !frameStatusFiltersEnabled}"
            [ngStyle]="{'width': initialConfig.userData.locale == 'de-de' && screen.width < 1600 ? '49%' : ''}">
            <div [hidden]="!(audienceSelectionEnabled && showAudienceDropdown && selectedAudienceList.length > 0)"
                class="control_group">
                <select id="audienceType" name="audience-type" class="form_control"
                    [(ngModel)]="concertinaCanvasService.selectedAudienceCategory"
                    (ngModelChange)="audienceCategoryChange($event)">
                    <option [value]="audience.audienceCategoryId"
                        *ngFor="let audience of selectedAudienceList; trackBy: trackByAudienceCategory; let i = index"
                        [selected]="concertinaCanvasService.selectedAudienceCategory == audience.audienceCategoryId">
                        {{audience.audienceCategoryName}}
                    </option>
                </select>
            </div>
            <div class="download-button">
                <span (click)="exportConcertina($event)" title="{{userBundle['result.concertina.download'] || 'Download Concertina'}}" class="download-concertina}}">
                    <img src="../../../../../../../../assets/img/copy_concertina.png" alt="" width="50" />
                </span>
            </div>
            <div class="sort_by">
                <div (click)="openSorting()">
                    <span class="text">{{userBundle['result.label.sortBy']}}</span>
                    <img src="../../../../../../../../assets/img/sortby.png" alt="sort_by_image">
                </div>
                <app-concertina-sortable (notifySortableFlagChange)="notifySortableFlagChange($event)"
                    *ngIf="filters.length > 0" [sortableItems]="filters"></app-concertina-sortable>
            </div>
            <div class="auto-expand-tabs" *ngIf="frameStatusFiltersEnabled">
                <div [(ngModel)]="selectedFrameFilter" (change)="onFrameStatusFilterChange()" class="btn-group "
                    ngbRadioGroup name="radioBasic" id="selectedFrameFilterBtnGrp">
                    <label ngbButtonLabel class="btn btn-primary drawingbtn"
                        ngbTooltip="{{userBundle['geoplanner.text.all'] || 'All'}}" [placement]="'bottom'">
                        <input ngbButton type="radio" [value]="frameFilterType.all">
                        <em class="icon">
                            <img alt="all_frames" src="../../../../../../../../assets/img/all_frames.png">
                        </em> {{framesCount.total}}
                    </label>
                    <label ngbButtonLabel class="btn btn-primary drawingbtn"
                        ngbTooltip="{{userBundle['geoplanner.text.available'] || 'Available'}}" [placement]="'bottom'">
                        <input ngbButton type="radio" [value]="frameFilterType.available">
                        <em class="icon">
                            <img alt="available_frames" src="../../../../../../../../assets/img/available_frames.png">
                        </em> {{framesCount.available}}
                    </label>
                    <label ngbButtonLabel class="btn btn-primary drawingbtn"
                        ngbTooltip="{{userBundle['geoplanner.text.selected'] || 'Selected'}}" [placement]="'bottom'">
                        <input ngbButton type="radio" [value]="frameFilterType.selected">
                        <em class="icon">
                            <img alt="selected_frames" src="../../../../../../../../assets/img/selected_frames.png">
                        </em> {{framesCount.selected}}
                    </label>
                </div>

                <button id="viewCartmapbtn" (click)="toggleBasketMode()" [disabled]="cartService.cartData.length ==0"
                    [ngClass]="{'active': isViewCartOnPlanner}" class="btn btn-primary view_cart_planner_btn"
                    ngbTooltip="{{userBundle['vp.text.viewCartOnPlanner'] || 'View Cart On Planner'}}"
                    [placement]="'bottom'" style="pointer-events: auto;">
                    <em class="icon" style="margin-right: 2px;">
                        <img alt="" src="../../../../../../../../assets/img/cartframes.png">
                        <img class="cart-tick-img" *ngIf="isViewCartOnPlanner" alt="" src="../../../../../../../../assets/img/tickblueicon.png">
                    </em> {{userBundle['geoplanner.text.cart'] || 'Cart'}}
                </button>
            </div>
        </div>
      </div>
    </div>