import { getLocaleDateFormat, FormatWidth, NumberSymbol, getLocaleNumberSymbol } from '@angular/common';

export class LocaleData {
  static displayDateFormat = 'M/d/yy';
  static locale = 'en-Us';

  static NUM_GROUP_SEP = ',';
  static NUM_DECIMAL_SEP = '.';

  static setLocaleDateFormat() {
    this.displayDateFormat = getLocaleDateFormat('en-US', FormatWidth.Short);
  }

  static setLocaleNumberFormat(localeId) {
    this.NUM_GROUP_SEP = getLocaleNumberSymbol(localeId, NumberSymbol.CurrencyGroup);
    this.NUM_DECIMAL_SEP = getLocaleNumberSymbol(localeId, NumberSymbol.CurrencyDecimal);
  }

  static convertIntoNumber(value) {
    if (value) {
      // tslint:disable-next-line:radix
      return parseInt((value).toString().split(LocaleData.NUM_GROUP_SEP).join(''));
    }
  }

  static replaceLocalDecimalCharWithDot(no) {
    if (this.NUM_DECIMAL_SEP !== '.') {
      return parseFloat(String(no).replace(this.NUM_DECIMAL_SEP, '.'));
    }
    return parseFloat(no);
  }
}
