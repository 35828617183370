<app-sb-modal-wrapper [title]="initialConfig.userBundle['workspace.pcm.addProduct']"
  [titleBackgroundColor]="brickBgColor" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <div style="padding-left: 10px">
    <div class="btn-group">
      <input type="radio" name="radio_groups" [(ngModel)]="typeSelection" [value]="0" autocomplete="off" id="check1"
        checked />
      <label for="check1" class="">{{
        initialConfig.userBundle["workspace.pcm.productDetails"]
      }}</label>
      <input type="radio" name="minValueDay" [(ngModel)]="typeSelection" [value]="1" autocomplete="off" id="check2" />
      <label for="check2" class="">{{
        initialConfig.userBundle["workspace.pcm.accessControls"]
      }}</label>
      <input type="radio" *ngIf="initialConfig.uiControl.pcmPriceConfigurationEnabled" name="priceConfiguration"
        [(ngModel)]="typeSelection" [value]="2" autocomplete="off" id="check3" />
      <label *ngIf="initialConfig.uiControl.pcmPriceConfigurationEnabled" for="check3" class="">{{
        initialConfig.userBundle["workspace.pcm.priceConfiguration"]
      }}</label>
    </div>
  </div>
  <div class="row pcm-tab-container">
    <div *ngIf="typeSelection === 0">
      <div class="control_group" id="product_name">
        <label class="label">{{
            initialConfig.userBundle["workspace.pcm.productCatalogueName"]
          }}
          &nbsp;*</label>
        <input class="form_control" [(ngModel)]="productCatalogue.productCatalogueName" type="text" />
      </div>
      <div class="control_group" id="product_type">
        <label class="label">{{
            initialConfig.userBundle["workspace.pcm.productCatalogueType"]
          }}
          &nbsp;*</label>
        <select class="form_control" [(ngModel)]="productCatalogue.productCatalogueTypeId">
          <option [ngValue]="0" selected>
            {{ initialConfig.userBundle["common.lookup.defaultOption"] }}
          </option>
          <option *ngFor="let item of productCatalogueType; trackBy: trackByIdProductCatalogueType;" [ngValue]="item.idProductCatalogueType">
            {{ item.productCatalogueTypeName }}
          </option>
        </select>
      </div>
      <div class="control_group" id="product_group">
        <label class="label">{{
            initialConfig.userBundle["workspace.pcm.productCatalogueGroup"]
          }}
          &nbsp;*</label>
        <select class="form_control" [(ngModel)]="productCatalogue.productCatalogueGroupId">
          <option [ngValue]="0" selected>
            {{ initialConfig.userBundle["common.lookup.defaultOption"] }}
          </option>
          <option *ngFor="let item of productCatalogueGroup; trackBy: trackByIdProductCatalogueGroup;" [ngValue]="item.idProductCatalogueGroup">
            {{ item.productCatalogueGroupName }}
          </option>
        </select>
      </div>
      <div class="control_group" id="product_desc">
        <label class="label">{{
            initialConfig.userBundle[
              "workspace.pcm.productCatalogueDescription"
            ]
          }}
          &nbsp;*</label>
        <textarea class="form_control customtextarea-comments"
          [(ngModel)]="productCatalogue.productCatalogueDescription" maxlength="2000" cols="30" rows="1"
          data-gramm="true" data-txt_gramm_id="f31abdbe-c308-266b-79eb-46c2d0cb6c01"
          data-gramm_id="f31abdbe-c308-266b-79eb-46c2d0cb6c01" spellcheck="false" data-gramm_editor="true" style="
            z-index: auto;
            position: relative;
            line-height: 31px;
            font-size: 14px;
            transition: none;
            background: transparent !important;
          ">
        </textarea>
      </div>
    </div>
    <div *ngIf="typeSelection === 1">
      <div class="tabs_container horizontal">
        <ul class="tabs-menu">
          <li *ngFor="let ac of accessControls; trackBy: trackByIndex; let i = index" (click)="selectTab(ac, i)"
            [ngClass]="selectedTab.name == ac.name ? 'current' : ''" id="tab-{{ ac.organisationTypeId }}">
            <span class="text">{{ ac.name }}</span>
          </li>
        </ul>
        <div class="tab">
          <div id="tab-{{ j }}" class="tab-content animated" *ngFor="let lookup of accessControls; trackBy: trackByIndex; let j = index"
            [ngStyle]="{ display: j == selectedTab.index ? 'block' : 'none' }">
            <tag-input class="tag-input" [(ngModel)]="selectedTab.selected" [identifyBy]="selectedTab.identifyBy"
              [displayBy]="selectedTab.displayBy" [onlyFromAutocomplete]="true" [placeholder]="selectedTab.name"
              [secondaryPlaceholder]="selectedTab.name" [showDropdownIfEmpty]="
                selectedTab?.usePredifinedList ? true : false
              " [inputClass]="'form-control'">
              <ng-template let-item="item" let-index="index"> </ng-template>
              <tag-input-dropdown [showDropdownIfEmpty]="
                  selectedTab?.usePredifinedList ? true : false
                " [identifyBy]="selectedTab.identifyBy" [displayBy]="selectedTab.displayBy" [minimumTextLength]="0"
                [autocompleteItems]="
                  selectedTab?.usePredifinedList
                    ? selectedTab.usePredifinedList
                    : null
                " [autocompleteObservable]="
                  selectedTab?.usePredifinedList ? null : loadLookUpData
                " [visibleMenuAfterItemAdd]="true" [maintainSearchText]="true" [appendToBody]="false"
                [inputClass]="'form-control'">
              </tag-input-dropdown>
            </tag-input>
            <ul class="summaryContainer">
              <li *ngFor="let item of selectedTab.selected; trackBy: trackByIndex; let index = index">
                <label>{{ item[selectedTab.displayBy] }}</label>
                <span class="right" style="cursor: pointer" (click)="removeItemById(index, j)">
                  <em class="fa fa-times" aria-hidden="true"></em></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="typeSelection === 2">
      <div class="tabs_container horizontal">
        <div class="control_group">
          <label class="switchAllowFrames">
            <input type="checkbox" [(ngModel)]="productCatalogue.priceConfiguration.fixedCPM"
              (click)="clearfixedCPMValue($event)" />
            <span class="sliderAllowFrames round"></span>
          </label>
          <label id="cpmField" class="label">{{initialConfig.userBundle["workspace.pcm.fixedCPM"]}}&nbsp;<span
              *ngIf="productCatalogue.priceConfiguration.fixedCPM">*</span></label>
          <input id="cpmValue" type="text" class="form_control"
            [(ngModel)]="productCatalogue.priceConfiguration.fixedCPMValue"
            [disabled]="!productCatalogue.priceConfiguration.fixedCPM" [imask]="currencyInputMask"
            [unmask]="maskService.unmask.typed" />
        </div>
        <div>
          <div class="pcMessage">{{initialConfig.userBundle["pcm.addproduct.pricingConfiguration.message"]}}</div>
          <div>
            <label class="switchAllowFrames">
              <input type="checkbox" [(ngModel)]="productCatalogue.priceConfiguration.limitPricingFactors"
                (click)="togglepcmPricingFactors($event)" />
              <span class="sliderAllowFrames round"></span>
            </label>
            <div class="litmitPC">
              {{initialConfig.userBundle["pcm.addproduct.pricingConfiguration.limitProductPricingScope"]}}</div>
          </div>
        </div>
        <div *ngIf="productCatalogue.priceConfiguration?.factors.length" class="pricingFactorMain">
          <div class="control_group" *ngFor="let item of productCatalogue.priceConfiguration.factors; trackBy: trackByIndex; let i = index">
            <label class="switchAllowFrames">
              <input type="checkbox" [disabled]="productCatalogue.priceConfiguration.limitPricingFactors===false"
                [(ngModel)]="productCatalogue.priceConfiguration.factors[i]['enabled']"
                (click)="toggleSinglePCMFactor($event,productCatalogue.priceConfiguration.factors[i])" />
              <span class="sliderAllowFrames round"></span>
            </label>
            <div class="pricingFactorLabels">
              {{initialConfig.userBundle["pcm.addproduct.pricingConfiguration.factor."+ productCatalogue.priceConfiguration.factors[i]['factor']]  }}

            </div>
            <div>
              <label id="factorMin"
                class="label">{{initialConfig.userBundle["pcm.addproduct.pricingConfiguration.min"]}}</label>
              <input id="factorMinValue" type="text" class="form_control"
                [(ngModel)]="productCatalogue.priceConfiguration.factors[i]['min']"
                [disabled]="!productCatalogue.priceConfiguration.factors[i]['enabled']  || !productCatalogue.priceConfiguration.limitPricingFactors"
                [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" />
            </div>
            <div>
              <label id="factorMax"
                class="label">{{initialConfig.userBundle["pcm.addproduct.pricingConfiguration.max"]}}</label>
              <input id="factorMaxValue" type="text" class="form_control"
                [(ngModel)]="productCatalogue.priceConfiguration.factors[i]['max']"
                [disabled]="!productCatalogue.priceConfiguration.factors[i]['enabled'] || !productCatalogue.priceConfiguration.limitPricingFactors"
                [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</app-sb-modal-wrapper>
