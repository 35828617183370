import { Component, OnInit, OnDestroy } from '@angular/core';
import { CampaignSummary, InitialConfigModel, SystemData, SystemFlags } from '../../models';
import { DataShareService, StateService } from '../../core/services';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-campaign-summary',
  templateUrl: './campaign-summary.component.html'
})
export class CampaignSummaryComponent implements OnInit, OnDestroy {
  systemData: SystemData;
  campaignSummary: CampaignSummary[] = [];
  loadedCampaignSummary: CampaignSummary[] = [];
  isLoadedCampaign = false;
  campaignSummarySubscription: Subscription;
  validWorkspaceSubscription: Subscription;
  isValidWorkspace = false;
  initialConfig: InitialConfigModel;
  showFinalPrice: boolean = false;
  finalPrice;
  loadedFinalPrice;

  constructor(
    private dataShareService: DataShareService,
    private stateService: StateService
  ) {
    this.campaignSummarySubscription = this.stateService.campaignSummary$.subscribe((newSummary: CampaignSummary[]) => {
      if (newSummary && newSummary.length > 0) {
        this.campaignSummary = newSummary;
        this.isLoadedCampaign = SystemFlags.isLoadedCampaign || false;
        this.finalPrice = this.dataShareService.finalPrice;
        this.dataShareService.isCampaignSummaryAvbl = true;
        if (!this.isLoadedCampaign) {
          this.setCampaignSummaryClass();
        } else {
          this.setSummaryClassForLoadedCampaign();
        }
      }

    });

    this.validWorkspaceSubscription = this.stateService.validWorkspace$.subscribe((isValidWorkspace) => {
      this.isValidWorkspace = isValidWorkspace;
    });
  }

  ngOnInit() {
    this.systemData = this.dataShareService.getInitialConfigByKey('systemData');
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.showFinalPrice = this.initialConfig.uiControl.showCommercialDetailsOnWorkspace;
    this.finalPrice = this.dataShareService.finalPrice;
    this.loadedFinalPrice = this.dataShareService.loadedFinalPrice;
    this.isLoadedCampaign = SystemFlags.isLoadedCampaign || false;
    if (this.isLoadedCampaign) {
      this.loadedCampaignSummary = this.stateService.getLoadedCampaignSummary();
      if (this.loadedCampaignSummary.length === 2) {
        this.loadedCampaignSummary[1].class = 'databar2';
      }
      if (this.loadedCampaignSummary.length === 3) {
        this.loadedCampaignSummary[1].class = 'databar2';
        this.loadedCampaignSummary[2].class = 'databar3';
      }
    }
  }

  /**
   * Set Campaign Summary Class for single summary
   */
  setCampaignSummaryClass() {
    switch (this.campaignSummary.length) {
      case 2: {
        this.campaignSummary[1].class = 'databar2';
        break;
      }
      case 3: {
        this.campaignSummary[1].class = 'databar2';
        this.campaignSummary[2].class = 'databar3';
        break;
      }
    }
  }

  setSummaryClassForLoadedCampaign() {
    const loadedCampaignSummary = this.stateService.getLoadedCampaignSummary();
    if (loadedCampaignSummary.length === 0) {
      this.setCampaignSummaryClass();
    } else {
      switch (this.campaignSummary.length) {
        case 1: {
          this.campaignSummary[0].class = 'purple1';
          break;
        }
        case 2: {
          this.campaignSummary[0].class = 'purple1';
          this.campaignSummary[1].class = 'purple2';
          break;
        }
        case 3: {
          this.campaignSummary[0].class = 'purple1';
          this.campaignSummary[1].class = 'purple2';
          this.campaignSummary[2].class = 'purple3';
          break;
        }
      }
    }
  }

  getCapaignSummaryWidth(type) {
    let tmpWidth = '0';
    let minWidth = '';
    switch (type) {
      case 'date':
        tmpWidth = '17%';
        minWidth = '240px';
        break;
      case 'audiance':
        tmpWidth = '18%';
        break;
      case 'impressions':
        tmpWidth = '18%';
        break;
      case 'price':
        tmpWidth = '17%';
        break;
      case 'frame':
        tmpWidth = '13%';
        break;
      case 'qoc':
        tmpWidth = '9%';
        break;
      case 'rating':
        tmpWidth = '8%';
        break;
    }
    const style: any = {
      width: tmpWidth
    };
    if (minWidth !== '') {
      style.minWidth = minWidth;
    }
    return tmpWidth;
  }

  ngOnDestroy() {
    if (this.campaignSummarySubscription) {
      this.campaignSummarySubscription.unsubscribe();
    }
    if (this.validWorkspaceSubscription) {
      this.validWorkspaceSubscription.unsubscribe();
    }
  }

  trackByItem(index, item) {
    return item;
  }

  trackByIndex(index, item) {
    return index;
  }
}
