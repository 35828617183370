import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import * as _ from 'lodash';
@Directive({
  selector: '[appMasterTooltip]'
})
export class MasterTooltipDirective {

  @Input() alwaysDisplay = false;
  @Input() tooltipName = '';
  @Input() tooltipScope = 0; // 0 means inside, 1 means sibling

  tooltipElement: any;

  constructor(private el: ElementRef) { }

  /**
   * @description Get the tooltip element form dom
   * @author Darshan Vachhani
   * @returns {ElementRef}
   * @memberof MasterTooltipDirective
   */
  getTooltipElement() {
    if (this.tooltipScope === 0) {
      // Find tooltip inside el
      return this.el.nativeElement.querySelector(`div.${this.tooltipName}`);
    } else {
      // Find tooltip at el level
      return this.el.nativeElement.parentNode.querySelector(`div.${this.tooltipName}`);
    }
  }

  hideTooltipElement() {
    if (!_.isUndefined(this.alwaysDisplay) && !this.alwaysDisplay && this.tooltipElement) {
      this.tooltipElement.style.display = 'none';
    }
  }

  /**
   * @description Mouseover event set style of element
   * @author Darshan Vachhani
   * @memberof MasterTooltipDirective
   */
  @HostListener('mouseover', ['$event']) mouseOver() {
    // DV - Get tooltip element on mouseover
    this.tooltipElement = this.getTooltipElement();

    if (this.tooltipElement) {
      this.tooltipElement.style.display = 'block';
    }
  }

  /**
   * @description Mouseout event set style of element
   * @author Darshan Vachhani
   * @memberof MasterTooltipDirective
   */
  @HostListener('mouseout', ['$event']) mouseOut() {
    this.hideTooltipElement();
  }

  /**
   * @description mousedown event set style of element
   * @author Darshan Vachhani
   * @memberof MasterTooltipDirective
   */
  @HostListener('mousedown', ['$event']) mousedown() {
    this.hideTooltipElement();
  }

  /**
   * @description mouseup event set style of element
   * @author Darshan Vachhani
   * @memberof MasterTooltipDirective
   */
  @HostListener('mouseup', ['$event']) mouseup() {
    if (this.tooltipElement) {
      this.tooltipElement.style.display = 'block';
    }
  }
}
