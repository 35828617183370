import {
  TagBricSelectedDataModel,
  TagModel,
  MediaBricSelectedDataModel,
  SelectionItemModel,
  FormatBricSelectedDataModel,
  LocationBricSelectedDataModel,
  InchargeBricSelectedDataModel,
  BudgetBricSelectedDataModel,
  SOTBricSelectedDataModel,
  UiControl,
  ProximityData,
  Row,
  Cell
} from '../../models';
import { DynamicKeyObjectData } from '../../models/Dynamic.model';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { isNumber } from './isNumber';
import { GLOBAL } from './app.constant';
import { VolumeBricSelectedDataModel } from '../../models/MapDataRequest.model';

export class RequestJsonGenerator {

  prepareTagBrickRequestJson(tagBricSelectedData: TagBricSelectedDataModel, brickText) {
    const selectedTags: TagModel[] = [];
    _.each(tagBricSelectedData.hasOwnProperty('allTabSelection') ? tagBricSelectedData.allTabSelection : tagBricSelectedData, (val: TagModel[]) => {
      if (val.length > 0) {
        _.each(val, (tag: TagModel) => {
          selectedTags.push(tag);
        });
      }
    });

    const groupedData = _.groupBy(selectedTags, item => [item.exclude || false, item.relative || false]);

    const requestData = Object.keys(groupedData).map((groupKey) => {
      const [exclude, relative] = groupKey.split(',');

      const selectedTagIds = {};
      groupedData[groupKey].forEach((tag) => {
        selectedTagIds[tag.tagId] = -1;
      });

      return [
        {
          selectionId: 26,
          exclude: exclude === 'true' ? true : false,
          relative: relative === 'true' ? true : false
        },
        selectedTagIds
      ];
    });

    return { [brickText]: requestData };
  }

  /*
 *repare request json data for media bric
 *param {any} mediaBricSelectedData - Selected media bric data
 *memberof RequestJsonService
 */
  prepareMediaBricReqJson(mediaBricSelectedData: MediaBricSelectedDataModel, brickText: string) {
    if (mediaBricSelectedData.mediaTypeName) {
      const mediaSelectionId = '22';
      const mediaObj = {};
      mediaObj[brickText] = {};
      mediaObj[brickText][mediaSelectionId] = {};
      mediaObj[brickText][mediaSelectionId][mediaBricSelectedData.mediaTypeId] = -1;
      return mediaObj;
    }
  }

  /**
   * @author Vijay Sutaria
   * @param brickText brickText i.e selectionCriteriaChannel
   * @param data actual selected data set , populated inside brick after selection
   * @param selectionId selectionId
   */
  public getNewStructureForLocalSolver(brickText: string, data, selectionId: string, resultObj = {}) {
    if (data && data.length) {
      let displayId: string;
      switch (true) {
        case Boolean(data.find(d => d.hasOwnProperty('lookupId'))):
          displayId = 'lookupId';
          break;
        case Boolean(data.find(d => d.hasOwnProperty('productId'))):
          displayId = 'productId';
          break;
        case Boolean(data.find(d => d.hasOwnProperty('userSelectionId'))):
          displayId = 'userSelectionId';
          break;
        default:
          displayId = 'id';
          break;
      }

      if (Object.keys(resultObj).length === 0) {
        resultObj[brickText] = [];
      }

      const relativeFiltered = data.filter(c => c.relative);
      const nonRelativeFiltered = data.filter(c => !c.relative);

      if (displayId === 'userSelectionId') {
        data.forEach((element: any) => {
          resultObj[brickText].push([{ selectionId: selectionId.toString(), userSelectionId: element.userSelectionId, exclude: element.exclude }, {}]);
        });
      } else {
        if (relativeFiltered.length > 0) {
          const robject = {};
          relativeFiltered.forEach(r => robject[r[displayId]] = -1);
          resultObj[brickText].push([{ selectionId: selectionId.toString(), relative: true }, robject]);
        }

        if (nonRelativeFiltered.length > 0) {
          const nrobject = {};
          nonRelativeFiltered.forEach(r => nrobject[r[displayId]] = -1);
          resultObj[brickText].push([{ selectionId: selectionId.toString(), relative: false }, nrobject]);
        }
      }
    }
    return resultObj;
  }

  /**
 * Method to get object for all selection
 * @param selectedData selected data
 * @param localSolverEnabled Local solver is enabled or not
 * @returns return data in valid format
 */
  getAllSelectionObject(selectedData: any, localSolverEnabled: boolean) {
    if (localSolverEnabled === true || localSolverEnabled === false) { // Flag is available ,always send new format
      return [
        [
          {
            'selectionId': '-99',
            'relative': false
          },
          {
            '-99': -99
          }
        ]
      ];
    } else {
      return selectedData;
    }
  }

  /**
   * Method to get request json for environment/channel brick
   * @param channelBricSelectedData Selected objectct
   * @param brickText sub property text
   * @param lookupColumnData look up data
   * @param localSolverEnabled flag to check if local solver is enabled or not
   */
  public prepareEnvironmentBricReqJson(channelBricSelectedData: SelectionItemModel[], brickText: string, lookupColumnData, localSolverEnabled) {
    if (channelBricSelectedData.length > 0) {
      const environmentSelectionData = lookupColumnData;
      const environmentSelectionId = environmentSelectionData.lookup[0].selectionId;
      let environmentObj = {};

      if (localSolverEnabled === true || localSolverEnabled === false) {
        environmentObj = this.getNewStructureForLocalSolver(brickText, channelBricSelectedData, environmentSelectionId);
      } else {
        environmentObj[brickText] = {};
        environmentObj[brickText][environmentSelectionId] = {};
        for (const channelBricSelectedDataChild of channelBricSelectedData) {
          environmentObj[brickText][environmentSelectionId][channelBricSelectedDataChild.id] = -1;
        }
      }
      return environmentObj;
    }
  }

  /**
   * Generate request json for format brick
   * @param selectedData selected data from modal window
   * @param brickText sub key of request json
   * @param hiddenUIElements hidden ui elements
   * @param localSolverEnabled localsolver enable or not flag
   */
  prepareFormatBricReqJson(selectedData: FormatBricSelectedDataModel, brickText: string, hiddenUIElements, localSolverEnabled) {
    let formatObj = {};
    const selectionCriteriaRestriction = {
      '1': false, // Dominate Furniture
      '2': false, // Back to Back Panels
      '3': false // parallel panels
    };
    formatObj[brickText] = {};
    if (!_.isEmpty(selectedData) && !selectedData.hasOwnProperty('-99')) {
      const selectionCriteriaFormat: DynamicKeyObjectData<DynamicKeyObjectData<number>> = {};
      const dataKeys = Object.keys(selectedData);
      if (localSolverEnabled === true || localSolverEnabled === false) {
        formatObj[brickText] = [];
        for (const dataKey of dataKeys) {
          if (Array.isArray(selectedData[dataKey])) {
            formatObj = this.getNewStructureForLocalSolver(brickText, selectedData[dataKey], dataKey, formatObj);
          } else if (dataKey === 'selectionCriteriaRestriction' && hiddenUIElements.indexOf('workspace.format.checkboxGroup') === -1) {
            const obj: SelectionItemModel[] = (selectedData[dataKey] as any) as SelectionItemModel[];
            if (obj) {
              if (obj['1'] && hiddenUIElements.indexOf('workspace.format.dominateFurniture') === -1) {
                selectionCriteriaRestriction['1'] = true;
              } else if (!obj['1'] && hiddenUIElements.indexOf('workspace.format.dominateFurniture') === -1) {
                delete selectionCriteriaRestriction['1'];
              }
              if (obj['2'] && hiddenUIElements.indexOf('workspace.format.backtoBackPanels') === -1) {
                selectionCriteriaRestriction['2'] = true;
              } else if (!obj['2'] && hiddenUIElements.indexOf('workspace.format.backtoBackPanels') !== -1) {
                delete selectionCriteriaRestriction['2'];
              }
              if (obj['3'] && hiddenUIElements.indexOf('workspace.format.parallelPanels') === -1) {
                selectionCriteriaRestriction['3'] = true;
              } else if (!obj['3'] && hiddenUIElements.indexOf('workspace.format.parallelPanels') !== -1) {
                delete selectionCriteriaRestriction['3'];
              }
            }
          }
        }
      } else {
        for (const curKey of dataKeys) {
          const obj: SelectionItemModel[] = (selectedData[curKey]) as SelectionItemModel[];
          const formatDetailsId: DynamicKeyObjectData<number> = {};
          if (obj.length > 0) {
            obj.forEach((val: SelectionItemModel) => {
              formatDetailsId[val.id] = -1;
            });
            selectionCriteriaFormat[curKey] = formatDetailsId;
          }
          if (curKey === 'selectionCriteriaRestriction' && hiddenUIElements.indexOf('workspace.format.checkboxGroup') === -1) {
            if (obj) {
              if (obj['1'] && hiddenUIElements.indexOf('workspace.format.dominateFurniture') === -1) {
                selectionCriteriaRestriction['1'] = true;
              } else if (!obj['1'] && hiddenUIElements.indexOf('workspace.format.dominateFurniture') === -1) {
                delete selectionCriteriaRestriction['1'];
              }
              if (obj['2'] && hiddenUIElements.indexOf('workspace.format.backtoBackPanels') === -1) {
                selectionCriteriaRestriction['2'] = true;
              } else if (!obj['2'] && hiddenUIElements.indexOf('workspace.format.backtoBackPanels') !== -1) {
                delete selectionCriteriaRestriction['2'];
              }
              if (obj['3'] && hiddenUIElements.indexOf('workspace.format.parallelPanels') === -1) {
                selectionCriteriaRestriction['3'] = true;
              } else if (!obj['3'] && hiddenUIElements.indexOf('workspace.format.parallelPanels') !== -1) {
                delete selectionCriteriaRestriction['3'];
              }
            }
          }
        }
        formatObj[brickText] = selectionCriteriaFormat;
        if (hiddenUIElements.indexOf('workspace.format.checkboxGroup') === -1) {
          formatObj['selectionCriteriaRestriction'] = selectionCriteriaRestriction;
        }
      }
    } else {
      formatObj[brickText] = this.getAllSelectionObject(selectedData, localSolverEnabled);
    }
    formatObj['selectionCriteriaRestriction'] = selectionCriteriaRestriction;
    return formatObj;
  }

  prepareLocationBricReqJson(selectedData: LocationBricSelectedDataModel, brickText: string, localSolverEnabled) {
    let locationObj = {};
    if (!_.isEmpty(selectedData) && !selectedData.hasOwnProperty('-99')) {
      const selectionCriteriaLocation: DynamicKeyObjectData<DynamicKeyObjectData<number>> = {};
      const dataKeys = Object.keys(selectedData);
      if (localSolverEnabled === true || localSolverEnabled === false) {
        locationObj[brickText] = [];
        for (const curKey of dataKeys) {
          if (curKey === 'fileList') {
            const fileDataKeys = Object.keys(selectedData[curKey]);
            for (const fileDataKey of fileDataKeys) {
              locationObj = this.getNewStructureForLocalSolver(brickText, selectedData[curKey][fileDataKey], fileDataKey, locationObj);
            }
          } else {
            locationObj = this.getNewStructureForLocalSolver(brickText, selectedData[curKey], curKey, locationObj);
          }
        }
      } else {
        locationObj[brickText] = {};
        for (const curKey of dataKeys) {
          const obj: SelectionItemModel[] = selectedData[curKey];
          const locationDetailsId: DynamicKeyObjectData<number> = {};
          if (obj.length > 0) {
            obj.forEach((val: SelectionItemModel) => {
              if (val) {
                if (!_.isUndefined(val.id)) {
                  locationDetailsId[val.id] = -1; // SBRICS-2315 : Yuvraj Chauhan : 07/03/2017
                } else if (!_.isUndefined(val.lookupId)) {
                  locationDetailsId[val.lookupId] = -1; // SBRICS-2315 : Yuvraj Chauhan : 07/03/2017
                }
              }
            });
            selectionCriteriaLocation[curKey] = locationDetailsId;
          }
        }
        locationObj[brickText] = selectionCriteriaLocation;
      }
    } else {
      locationObj[brickText] = this.getAllSelectionObject(selectedData, localSolverEnabled);
    }
    return locationObj;
  }

  prepareProximityBricReqJson(proximityBricSelectedData: ProximityData, brickText: string) {
    if (!_.isEmpty(proximityBricSelectedData)) {
      const userSelectionIds = [];
      if (proximityBricSelectedData.postcodeSelection.userSelectionId != null) {
        userSelectionIds.push(proximityBricSelectedData.postcodeSelection.userSelectionId);
      }
      if (proximityBricSelectedData.pointOfInterest.userSelectionId != null) {
        userSelectionIds.push(proximityBricSelectedData.pointOfInterest.userSelectionId);
      }
      if (proximityBricSelectedData.points.userSelectionId != null) {
        userSelectionIds.push(proximityBricSelectedData.points.userSelectionId);
      }
      const proximityFilterRequestJsonObject = {
      };
      proximityFilterRequestJsonObject[brickText] = {
        'userSelectionIds': userSelectionIds
      };
      return proximityFilterRequestJsonObject;
    }
  }

  prepareAudienceBricReqJson(audienceBricSelectedData: any, brickText: string) {
    const audienceFilterRequestJsonObject = {};
    if (audienceBricSelectedData && audienceBricSelectedData.audienceCategoryId) {
      audienceFilterRequestJsonObject[brickText] = {
        'audienceCategoryId': audienceBricSelectedData.audienceCategoryId
      };
    } else {
      audienceFilterRequestJsonObject[brickText] = audienceBricSelectedData;
    }
    return audienceFilterRequestJsonObject;

  }

  // Below code commented by Nishit as prepareAudienceBricReqJson and below method has same code
  // prepareSecondaryAudienceBricReqJson(audienceBricSelectedData: any, brickText: string) {
  //   if (audienceBricSelectedData && audienceBricSelectedData.audienceCategoryId) {
  //     const audienceFilterRequestJsonObject = {};
  //     audienceFilterRequestJsonObject[brickText] = {
  //       'audienceCategoryId': audienceBricSelectedData.audienceCategoryId
  //     };
  //     return audienceFilterRequestJsonObject;
  //   }
  // }

  prepareSotBricReqJson(sotBricSelectedData, brickText: string) {
    const sotFilterRequestJsonObject = {};
    if (sotBricSelectedData.sotType === '1') {
      sotFilterRequestJsonObject[brickText] = {
        framesSlots: sotBricSelectedData.framesSlots
      };
    } else {
      if (sotBricSelectedData && sotBricSelectedData.sot === 'Mixed') {
        sotFilterRequestJsonObject[brickText] = {};
      } else if (sotBricSelectedData && isNumber(sotBricSelectedData.sot)) {

        const sotRequestData: SOTBricSelectedDataModel = _.clone(sotBricSelectedData); // reverted this line for SM-5421
        // added below condition as impact for SM-5421
        if (sotRequestData.hasOwnProperty('framesSlots')) {
          delete sotRequestData.framesSlots;
        }
        if (sotBricSelectedData.commercialSOT !== undefined || sotBricSelectedData.dummySot !== undefined) {
          sotRequestData.commercialSOT = sotBricSelectedData.commercialSOT || sotBricSelectedData.dummySot;
          sotRequestData.frequency = sotBricSelectedData.frequency;
        }
        sotFilterRequestJsonObject[brickText] = sotRequestData;
      } else if (sotBricSelectedData.hasOwnProperty('-99')) {
        sotFilterRequestJsonObject[brickText] = sotBricSelectedData;
      }
    }
    return sotFilterRequestJsonObject;
  }

  prepareSotBricReqJsonPCM(sotBricSelectedData, brickText: string) {
    const sotFilterRequestJsonObject = {};
    sotFilterRequestJsonObject[brickText] = sotBricSelectedData;
    return sotFilterRequestJsonObject;
  }

  public prepareListBricReqJson(listBricSelectedData: any, brickText: string, hardAllocationEnabled: boolean, swapEnabled: boolean) {
    const listFilterRequestJsonObject = {};
    listFilterRequestJsonObject[brickText] = [];
    if (listBricSelectedData && !_.isUndefined(listBricSelectedData.listUpload)) {
      listBricSelectedData.listUpload.fileList.forEach((element) => {
        const obj = {
          'userSelectionId': element.userSelectionId,
          'exclude': element.exclude
        };
        if (hardAllocationEnabled) {
          obj['hardAllocated'] = element.hardAllocated;
        }
        if (swapEnabled) {
          obj['swap'] = element.swap;
        }
        listFilterRequestJsonObject[brickText].push(obj);
      });
    }

    if (listBricSelectedData && !_.isUndefined(listBricSelectedData.routeFrameIds)
      && (listBricSelectedData.routeFrameIds.include.length > 0
        || listBricSelectedData.routeFrameIds.exclude.length > 0)) {
      const reqJson = listBricSelectedData.routeFrameIds.requestJSON;
      if (!_.isEmpty(reqJson.include)) {
        listFilterRequestJsonObject[brickText].push({
          'userSelectionId': reqJson.include.userSelectionId,
          'exclude': reqJson.include.exclude
        });
      }
      if (!_.isEmpty(reqJson.exclude)) {
        listFilterRequestJsonObject[brickText].push({
          'userSelectionId': reqJson.exclude.userSelectionId,
          'exclude': reqJson.exclude.exclude
        });
      }
    }
    return listFilterRequestJsonObject;
  }

  public prepareInchargeBricReqJson(inchargeBricSelectedData: InchargeBricSelectedDataModel, brickText: string,
    datePipe: DatePipe, pcmMode) {
    const inchargeObj = {};
    if (inchargeBricSelectedData.startDate && inchargeBricSelectedData.endDate) {
      inchargeObj[brickText] = {
        startDate: datePipe.transform(inchargeBricSelectedData.startDate, GLOBAL.DATE_PARSE_FORMAT),
        endDate: datePipe.transform(inchargeBricSelectedData.endDate, GLOBAL.DATE_PARSE_FORMAT)
      };
      if (pcmMode) {
        inchargeObj[brickText]['minValue'] = {
          value: inchargeBricSelectedData.minValue,
          unit: inchargeBricSelectedData.minValueUnit
        };
        inchargeObj[brickText]['defaultValue'] = {
          value: inchargeBricSelectedData.defaultValue,
          unit: inchargeBricSelectedData.defaultValueUnit
        };
        if (_.isUndefined(inchargeBricSelectedData.defaultWeekDay) ||
          inchargeBricSelectedData.defaultWeekDay == null ||
          inchargeBricSelectedData.defaultWeekDay !== -1) {
          inchargeObj[brickText]['defaultWeekDay'] = inchargeBricSelectedData.defaultWeekDay;
        }
      }
    } else if (inchargeBricSelectedData.hasOwnProperty('-99')) {
      inchargeObj[brickText] = {};
    }
    return inchargeObj;
  }

  public prepareFrameBricReqJson(frameBricSelectedData: any, brickText: string) {
    if (!_.isEmpty(frameBricSelectedData)) {
      const frameObj = {};
      frameObj[brickText] = {
        illuminated: (frameBricSelectedData.illumination === 1 ? true : false)
      };
      return frameObj;
    }
  }

  /**
   * @description Prepares bricks request JSON for frame brick
   * @author Amit Mahida
   * @param {*} frameBricSelectedData
   * @returns
   * @memberof RequestJsonGenerator
   */
  public prepareFrameBricReqJsonForWS(frameBricSelectedData: any, brickText: string, uiControl: UiControl) {
    const frameObj = {};
    if (!_.isEmpty(frameBricSelectedData) && !frameBricSelectedData.hasOwnProperty('-99')) {
      frameObj[brickText] = {
        ...frameBricSelectedData,
        illumination: frameBricSelectedData.illumination !== null ? frameBricSelectedData.illumination : '',
      };
      if (frameObj[brickText].illumination === '') {
        delete frameObj[brickText].illumination;
      }
      if (uiControl.toleranceEnabled) {
        frameObj[brickText].tolerance = frameBricSelectedData.tolerance || 0;
      } else {
        delete frameObj[brickText].tolerance;
      }
      return frameObj;
    } else {
      frameObj[brickText] = _.cloneDeep(GLOBAL.allSelection);
      return frameObj;
    }
  }

  /**
   * @description Prepares bricks request JSON for channel/product brick
   * @author Amit Mahida
   * @param {*} channelBricSelectedData
   * @returns
   * @memberof RequestJsonGenerator
   */
  public prepareChannelBricReqJson(channelBricSelectedData: any, brickText: string, lookupColumnData, localSolverEnabled) {
    const channelObj = {
      [brickText]: [],
    };
    const channelSelectionData = lookupColumnData;
    const channelSelectionId = channelSelectionData.lookup[0].selectionId;

    if (localSolverEnabled === true || localSolverEnabled === false) {
      return this.getNewStructureForLocalSolver(brickText, channelBricSelectedData, channelSelectionId, channelObj);
    } else {
      const selectionCriteriaProduct = {};
      if (channelBricSelectedData && channelBricSelectedData[GLOBAL.productBricSelectionId]) {
        channelBricSelectedData[GLOBAL.productBricSelectionId].forEach((channel) => {
          if (channel.hasOwnProperty('productGroupId')) {
            channel.product.forEach((element) => {
              selectionCriteriaProduct[element.productId] = -1;
            }, this);
          } else {
            selectionCriteriaProduct[channel.productId] = -1;
          }
        }, this);
      } else if (channelBricSelectedData && channelBricSelectedData.length > 0) {
        channelBricSelectedData.forEach((channel) => {
          if (channel.hasOwnProperty('productGroupId')) {
            channel.product.forEach((element) => {
              selectionCriteriaProduct[element.productId] = -1;
            }, this);
          } else {
            selectionCriteriaProduct[channel.productId] = -1;
          }
        }, this);
      }

      const channelRequestJSON = [];
      const selectionIdObject = {
        selectionId: channelSelectionId
      };
      channelRequestJSON.push(selectionIdObject);
      channelRequestJSON.push(selectionCriteriaProduct);
      channelObj[brickText].push(channelRequestJSON);
      return channelObj;
    }
  }

  /**
   * @description Prepare Request Json data of Budget Brick
   * @author Shivani Patel
   * @param {BudgetBricSelectedDataModel} budgetBricSelectedData
   * @returns Request Json of Budget Brick
   * @memberof RequestJsonGenerator
   */
  prepareBudgetBricReqJson(budgetBricSelectedData: BudgetBricSelectedDataModel, brickText: string, pcmMode: boolean) {
    const budgetRequest = _.clone(budgetBricSelectedData);
    if (budgetRequest) {

      if (budgetRequest.hasOwnProperty('allocated')) {
        delete budgetRequest.allocated;
      }
      if (pcmMode && !budgetRequest.hasOwnProperty('-99')) {
        const reqParams = {};
        reqParams[brickText] = { ...budgetRequest };
        reqParams[brickText].isAllSelection = false;
        if (budgetRequest.minPrice) {
          reqParams[brickText].minPrice = budgetRequest.minPrice;
        }
        if (budgetRequest.maxPrice) {
          reqParams[brickText].maxPrice = budgetRequest.maxPrice;
        }
        if (budgetRequest.sotFloor) {
          reqParams[brickText].sotFloor = budgetRequest.sotFloor;
        }
        if (budgetRequest.sotCeiling) {
          reqParams[brickText].sotCeiling = budgetRequest.sotCeiling;
        }
        if (budgetRequest.allocateAllFrames) {
          reqParams[brickText].allocateAllFrames = true;
        }
        return reqParams;
      } else {
        budgetRequest.isAllSelection = false;
        if (budgetRequest.hasOwnProperty('-99')) {
          budgetRequest.isAllSelection = true;
        }
        return {
          [brickText]: {
            ...budgetRequest,
          }
        };
      }
    }
  }

  /**
   * @description Prepare Request Json data of Distribution Brick
   * @author Amit Mahida
   * @param {*} distributionBricSelectedData
   * @returns
   * @memberof RequestJsonGenerator
   */
  prepareDistributionBricReqJson(distributionBricSelectedData, brickText: string) {
    if (distributionBricSelectedData) {
      return {
        [brickText]: {
          distributionType: distributionBricSelectedData.distributionType,
        }
      };
    }
  }

  /**
   * @description Prepare Request Json data of Volume Brick
   * @author Amit Mahida
   * @param {*} volumeBricSelectedData
   * @returns
   * @memberof RequestJsonGenerator
   */
  prepareVolumeBricReqJson(volumeBricSelectedData: VolumeBricSelectedDataModel, brickText: string) {
    const volumeRequest = _.clone(volumeBricSelectedData);

    if (volumeRequest) {
      volumeRequest.isAllSelection = false;
      if (volumeRequest.hasOwnProperty('-99')) {
        volumeRequest.isAllSelection = true;
      }

      // checked allocated property as for PCM mode we should not get 'allocated'
      if (volumeRequest.hasOwnProperty('allocated')) {
        delete volumeRequest.allocated;
      }
      // SM-3212, VJ: 25June2019
      // if (pcmMode) {
      //   return {
      //     [brickText]: { 'impressions': volumeRequest.impressions }
      //   };
      // } else {
      return {
        [brickText]: volumeRequest
      };
      // }

    }
  }

  /**
   * @description Prepare Request Json data of Pricing Tag Brick
   * @author Amit Mahida
   * @param {*} pricingTagBricSelectedData
   * @returns
   * @memberof RequestJsonGenerator
   */
  preparePricingTagBricReqJson(pricingTagBricSelectedData, brickText: string, lookupColumnData) {
    const pricingTagObj = {};
    const requestData = [];
    const pricingTagSelectionData = lookupColumnData;
    const pricingTagSelectionId = pricingTagSelectionData.lookup[0].selectionId;
    if (!Array.isArray(pricingTagBricSelectedData) && Array.isArray(pricingTagBricSelectedData[pricingTagSelectionId])) {
      pricingTagBricSelectedData = pricingTagBricSelectedData[pricingTagSelectionId];
    }
    if (pricingTagBricSelectedData) {
      const isRateCard = pricingTagBricSelectedData && pricingTagBricSelectedData[0] && pricingTagBricSelectedData[0].openRateCard;
      const greenTolerance = pricingTagBricSelectedData && pricingTagBricSelectedData[0] && pricingTagBricSelectedData[0].greenTolerance;
      const orangeTolerance = pricingTagBricSelectedData && pricingTagBricSelectedData[0] && pricingTagBricSelectedData[0].orangeTolerance;
      const priority = pricingTagBricSelectedData && pricingTagBricSelectedData[0] && pricingTagBricSelectedData[0].priority;
      const optionStackTrueIds = {};
      const optionStackTrueEditableIds = {};
      const optionStackFalseIds = {};
      const optionStackFalseEditableIds = {};

      const optionStackTrueFixedIds = {};
      const optionStackTrueEditableFixedIds = {};
      const optionStackFalseFixedIds = {};
      const optionStackFalseEditableFixedIds = {};

      const optionStackTrue: any = [{
        'selectionId': pricingTagSelectionId || 28,
        'optionStack': true,
        'openRateCard': isRateCard
      }];
      const optionStackTrueEditable: any = [{
        'selectionId': pricingTagSelectionId || 28,
        'optionStack': true,
        'editable': true,
        'openRateCard': isRateCard
      }];
      const optionStackFalse: any = [{
        'selectionId': pricingTagSelectionId || 28,
        'optionStack': false,
        'openRateCard': isRateCard
      }];
      const optionStackFalseEditable: any = [{
        'selectionId': pricingTagSelectionId || 28,
        'optionStack': false,
        'editable': true,
        'openRateCard': isRateCard
      }];

      const optionStackTrueFixed: any = [{
        'selectionId': pricingTagSelectionId || 28,
        'optionStack': true,
        'fixedPackagePrice': true,
        'openRateCard': isRateCard
      }];
      const optionStackTrueEditableFixed: any = [{
        'selectionId': pricingTagSelectionId || 28,
        'optionStack': true,
        'editable': true,
        'fixedPackagePrice': true,
        'openRateCard': isRateCard
      }];
      const optionStackFalseFixed: any = [{
        'selectionId': pricingTagSelectionId || 28,
        'optionStack': false,
        'fixedPackagePrice': true,
        'openRateCard': isRateCard
      }];
      const optionStackFalseEditableFixed: any = [{
        'selectionId': pricingTagSelectionId || 28,
        'optionStack': false,
        'editable': true,
        'fixedPackagePrice': true,
        'openRateCard': isRateCard
      }];

      if ((greenTolerance === 0 || greenTolerance) && (orangeTolerance === 0 || orangeTolerance) && (priority === 0 || priority)) {
        const objectiveSetting = {
          greenTolerance,
          orangeTolerance,
          priority
        }
        optionStackTrue[0] = {...optionStackTrue[0], ...objectiveSetting};
        optionStackTrueEditable[0] = {...optionStackTrueEditable[0], ...objectiveSetting};
        optionStackFalse[0] = {...optionStackFalse[0], ...objectiveSetting};
        optionStackFalseEditable[0] = {...optionStackFalseEditable[0], ...objectiveSetting};
        optionStackTrueFixed[0] = {...optionStackTrueFixed[0], ...objectiveSetting};
        optionStackTrueEditableFixed[0] = {...optionStackTrueEditableFixed[0], ...objectiveSetting};
        optionStackFalseFixed[0] = {...optionStackFalseFixed[0], ...objectiveSetting};
        optionStackFalseEditableFixed[0] = {...optionStackFalseEditableFixed[0], ...objectiveSetting};
      }

      for (let index = 0; index < pricingTagBricSelectedData.length; index++) {
        const obj = pricingTagBricSelectedData[index];
        let quantity = obj.networkDefaultQuantity;
        if (isRateCard) {
          // SM-9077: RateCard feature
          quantity = obj && obj.frameData && obj.frameData[2] ? obj.frameData[2] : 0;
        }
        if (obj.optionStack) {
          if (obj.noOfFramesEditable) {
            if (obj.fixedPackagePrice) {
              optionStackTrueEditableFixedIds[obj.networkId] = Number(quantity);
            } else {
              optionStackTrueEditableIds[obj.networkId] = Number(quantity);
            }
          } else {
            if (obj.fixedPackagePrice) {
              optionStackTrueFixedIds[obj.networkId] = Number(quantity);
            } else {
              optionStackTrueIds[obj.networkId] = Number(quantity);
            }
          }
        } else {
          if (obj.noOfFramesEditable) {
            if (obj.fixedPackagePrice) {
              optionStackFalseEditableFixedIds[obj.networkId] = Number(quantity);
            } else {
              optionStackFalseEditableIds[obj.networkId] = Number(quantity);
            }
          } else {
            if (obj.fixedPackagePrice) {
              optionStackFalseFixedIds[obj.networkId] = Number(quantity);
            } else {
              optionStackFalseIds[obj.networkId] = Number(quantity);
            }
          }
        }
      }

      if (!_.isEmpty(optionStackTrueIds)) {
        optionStackTrue.push(optionStackTrueIds);
        requestData.push(optionStackTrue);
      }
      if (!_.isEmpty(optionStackFalseIds)) {
        optionStackFalse.push(optionStackFalseIds);
        requestData.push(optionStackFalse);
      }
      if (!_.isEmpty(optionStackTrueEditableIds)) {
        optionStackTrueEditable.push(optionStackTrueEditableIds);
        requestData.push(optionStackTrueEditable);
      }
      if (!_.isEmpty(optionStackFalseEditableIds)) {
        optionStackFalseEditable.push(optionStackFalseEditableIds);
        requestData.push(optionStackFalseEditable);
      }
      if (!_.isEmpty(optionStackTrueFixedIds)) {
        optionStackTrueFixed.push(optionStackTrueFixedIds);
        requestData.push(optionStackTrueFixed);
      }
      if (!_.isEmpty(optionStackFalseFixedIds)) {
        optionStackFalseFixed.push(optionStackFalseFixedIds);
        requestData.push(optionStackFalseFixed);
      }
      if (!_.isEmpty(optionStackTrueEditableFixedIds)) {
        optionStackTrueEditableFixed.push(optionStackTrueEditableFixedIds);
        requestData.push(optionStackTrueEditableFixed);
      }
      if (!_.isEmpty(optionStackFalseEditableFixedIds)) {
        optionStackFalseEditableFixed.push(optionStackFalseEditableFixedIds);
        requestData.push(optionStackFalseEditableFixed);
      }
    }
    pricingTagObj[brickText] = requestData;

    return pricingTagObj;
  }

  /**
   * @description Prepare Request params of Pricing Brick
   * @author Amit Mahida
   * @param {*} pricingBricSelectedData
   * @returns
   * @memberof RequestJsonGenerator
   */
  preparePricingBricReqJson(pricingBricSelectedData, brickText: string) {
    if (pricingBricSelectedData) {
      return {
        [brickText]: {
          'pricingFactor': pricingBricSelectedData
        }
      };
    }
  }

  /**
   * @description Prepare Request params of Frame quality Brick
   * @author Amit Mahida
   * @param {*} frameQualityBricSelectedData
   * @returns
   * @memberof RequestJsonGenerator
   */
  prepareFrameQualityBricReqJson(frameQualityBricSelectedData, brickText: string) {
    const reqJson = {};
    if (frameQualityBricSelectedData.hasOwnProperty('audienceLevel')) {
      reqJson[brickText] = { 'audienceLevel': frameQualityBricSelectedData.audienceLevel };
    }

    if (frameQualityBricSelectedData.hasOwnProperty('isEditable')) {
      reqJson[brickText] = { ...reqJson[brickText], 'isEditable': frameQualityBricSelectedData.isEditable };
    }

    return reqJson;

  }

  /**
   * @description Prepare Request params of Pattern Brick
   * @author Amit Mahida
   * @param {*} patternBricSelectedData
   * @returns
   * @memberof RequestJsonGenerator
   */
  preparePatternBricReqJson(patternBricSelectedData, brickText: string) {
    if (patternBricSelectedData) {
      return {
        [brickText]: patternBricSelectedData
      };
    }
  }

  /**
   * @description returns request params for product catalogue brick
   * @author Amit Mahida
   * @param {number} selectedProductCatalogueId
   * @returns
   * @memberof RequestJsonGenerator
   */
  prepareProductCatalogueBricReqJson(selectedProductCatalogueId: number, brickText: string) {
    if (selectedProductCatalogueId) {
      return {
        [brickText]: {
          'productCatalogueId': selectedProductCatalogueId
        }
      };
    }
  }
  prepareAllAudienceReqJson(selectedAllAudienceData, brickText) {
    if (selectedAllAudienceData.hasOwnProperty('-99')) {
      return { [brickText]: selectedAllAudienceData };
    } else {
      return {
        [brickText]: {
          'categories': selectedAllAudienceData.categories,
          'segmentSelection': selectedAllAudienceData.segmentSelection
        }
      };
    }
  }
  preparePrimaryAudienceReqJson(selectedPrimaryAudience: any, brickText: string) {
    if (selectedPrimaryAudience.hasOwnProperty('-99')) {
      return { [brickText]: {} };
    } else {
      const obj = {
        selectionPrimaryAudiences: {}
      };
      for (const key in selectedPrimaryAudience) {
        if (selectedPrimaryAudience.hasOwnProperty(key)) {
          const audienceCategories = selectedPrimaryAudience[key];
          obj.selectionPrimaryAudiences[key] = [];
          audienceCategories.forEach((audienceCategory) => {
            obj.selectionPrimaryAudiences[key].push(audienceCategory.audienceCategoryId);
          });
        }
      }
      return obj;
    }
  }

  prepareObjectiveReqJson(selected: {
    objectives: Row[],
    status: number
  }, brickText: string) {
    const brickRequestJSON = [];
    if (selected.hasOwnProperty('-99')){
      const objectiveRequest = _.clone(selected);
      objectiveRequest.isAllSelection = true;
      return {
        [brickText] : { ...objectiveRequest}
      }
    }
    selected.objectives.forEach((row: Row) => {
      row.cells.forEach((cell: Cell) => {
        const requestJson: any = {};
        if (cell.requestJSON) {
          requestJson.columns = [];
          requestJson.row = row.index;

          if (cell.cellWidth > 1) {
            const columnarray = [];
            for (let c = 0; c < cell.cellWidth; c++) {
              columnarray.push(cell.cellIndex + c);
            }
            requestJson.columns = columnarray;
          } else {
            requestJson.columns.push(cell.cellIndex);
          }

          for (const key of Object.keys(cell.requestJSON)) {
            requestJson[key] = cell.requestJSON[key];
          }
          brickRequestJSON.push(requestJson);
        }
      });
    });

    return {
      [brickText]: {
        bricsData: brickRequestJSON,
        status: selected.status,
      }
    };
  }

  public prepareMultiTargetReqJson(selectedMultiTarget: any, brickText: string, enabledPCM: boolean) {
    if (selectedMultiTarget && selectedMultiTarget.listUpload && !enabledPCM) {
      if (selectedMultiTarget.selectedSOTOption) {
        return { [brickText]: [{ 'userSelectionId': selectedMultiTarget.listUpload.fileList[0].userSelectionId, selectedSOTOption: selectedMultiTarget.selectedSOTOption }] };
      }
      return { [brickText]: [{ 'userSelectionId': selectedMultiTarget.listUpload.fileList[0].userSelectionId }] };
    } else if (enabledPCM) {
      return { [brickText]: [selectedMultiTarget] };
    }
  }
}
