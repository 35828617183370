import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-text-cell-renderer',
  templateUrl: './ag-text-cell-renderer.component.html',
  styleUrls: ['./ag-text-cell-renderer.component.css']
})
export class AgTextCellRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;
  public displayText = '';
  public isEditable = false;

  // called on init
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setValues();
  }

  // called when the cell is refreshed
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.setValues();
    return true;
  }

  private setValues(): void {
    this.isEditable = false;
    /* tslint:disable:no-string-literal */
    if (this.params['cellEditable']) {
      this.isEditable = this.params['cellEditable'](this.params.node);
    }

    this.displayText = this.params.data[this.params.column.getColId()];
  }
}
