import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { CampaignDetails, CommercialConfig, BookingLine } from '../../commercial.model';
import { DataShareService, CommercialService, StateService } from '../../../core/services/index';
import { SbSelectedItem, DEFAULT_SELECTED_ITEMS } from '../../../models/sb-selected-item';
import {
  defaultBuyerContactParams,
  defaultAdminPersonParams,
  defaultSalesPersonParams,
  defaultBarterParams,
  defaultctuPersonParams
} from '../../../models/request-params';
import { InitialConfigModel, SystemFlags } from '../../../models';
import * as _ from 'lodash';
import { LocaleData } from '../../../core/utils/LocaleData';
import { Subscription } from 'rxjs';
import { GLOBAL } from '../../../core/utils/app.constant';
import { SelectConfig } from '../../../core/components/sb-select/core/select-config';
import { CampaignDetailsBase } from '../../campaign-details/campaign-details-base';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { CampaignDetailsService } from '../../campaign-details/campaign-details.service';

@Component({
  selector: 'app-booking-detail-norway',
  templateUrl: './booking-detail-norway.component.html'
})
export class BookingDetailNorwayComponent extends CampaignDetailsBase implements OnInit, OnDestroy, OnChanges {
  @Input() campaignDetails: CampaignDetails;
  @Input() commercialConfig: CommercialConfig;
  @Input() rowIndex: number;
  @Input() bookings: BookingLine[];
  @Input() bookingStatusId: any;

  gridDataSubscription: Subscription;
  config: InitialConfigModel;
  booking: BookingLine = new BookingLine();
  productionType: any[] = [];
  isMediaLengthRequired = false;
  buyerContactList: SbSelectedItem[] = [];
  selectedBuyerContact: SbSelectedItem = _.clone(DEFAULT_SELECTED_ITEMS);

  adminPersonList: SbSelectedItem[] = [];
  selectedAdminPerson: SbSelectedItem = _.clone(DEFAULT_SELECTED_ITEMS);

  salesPersonList: SbSelectedItem[] = [];
  selectedSalesPerson: SbSelectedItem = _.clone(DEFAULT_SELECTED_ITEMS);
  barterList: SbSelectedItem[] = [];
  selectedBarter: SbSelectedItem = _.clone(DEFAULT_SELECTED_ITEMS);

  ctuPersonList: SbSelectedItem[] = [];
  selectedCtuPerson: SbSelectedItem = _.clone(DEFAULT_SELECTED_ITEMS);

  /**
   * @description date format to use to display date
   * @type {string}
   * @memberof BookingDetailsDefaultComponent
   */
  displayDateFormat = '';

  /**
   * @description
   * @type {SelectConfig}
   * @memberof BookingDetailsDefaultComponent
   */
  public barterLookupConfig: SelectConfig;

  /**
   * @description
   * @type {SelectConfig}
   * @memberof BookingDetailNorwayComponent
   */
  public buyerContactLookupConfig: SelectConfig;

  /**
   * @description
   * @type {SelectConfig}
   * @memberof BookingDetailNorwayComponent
   */
  public adminPersonLookupConfig: SelectConfig;

  /**
   * @description
   * @type {SelectConfig}
   * @memberof BookingDetailsDefaultComponent
   */
  public salesPersonLookupConfig: SelectConfig;

  /**
   * @description
   * @type {SelectConfig}
   * @memberof BookingDetailsDefaultComponent
   */
  public ctuPersonLookupConfig: SelectConfig;

  iMaskCurrencyOption = {
    mask: Number, // enable number mask
    // other options are optional with defaults below
    scale: 11, // digits after point, 0 for integers
    signed: false, // disallow negative
    thousandsSeparator: LocaleData.NUM_GROUP_SEP, // any single char
    padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
    normalizeZeros: true, // appends or removes zeros at ends
    radix: LocaleData.NUM_DECIMAL_SEP, // fractional delimiter
    mapToRadix: ['.'], // symbols to process as radix
    // additional number interval options (e.g.)
    min: 0,
    max: 999999999,
  };

  /**
   * @description set readOnly
   * @memberof CampaignDetailsDefaultComponent
   */
  public readOnly = SystemFlags.readOnly;

  readonly defaultOption = 'common.lookup.defaultOption';

  /**
   * @description
   * @memberof BookingDetailNorwayComponent
   */
  bookingStatusList = GLOBAL.bookingStatusList;
  constructor(
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    stateService: StateService,
    commercialService: CommercialService
  ) {
    super(dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService
    );
    this.config = this.dataShareService.getInitialConfig();
  }

  ngOnDestroy() {
    this.gridDataSubscription.unsubscribe();
  }

  ngOnChanges(prop) {
    if (!prop.bookingStatusId.firstChange) {
      this.checkIsMediaLengthRequired();
    }
  }
  ngOnInit(): void {
    this.productionType = this.dataShareService.getInitialConfigByKey('productionType');
    this.booking = this.bookings[this.rowIndex];
    this.booking.productionTypeId = this.booking.productionTypeId || -1;
    this.displayDateFormat = LocaleData.displayDateFormat;
    this.gridDataSubscription = this.commercialService.gridData$.subscribe(() => {
      this.manageCalculations();
    });
    this.selectedBarter = this.commercialService.populateSelectedDropdown(this.booking.barterId, this.booking.barterName);
    this.selectedBuyerContact = this.commercialService.populateSelectedDropdown(this.booking.buyerContactId,
      this.booking.buyerContactName);
    this.selectedSalesPerson = this.commercialService.populateSelectedDropdown(this.booking.salesPersonId,
      this.booking.salesPersonName);
    this.selectedAdminPerson = this.commercialService.populateSelectedDropdown(this.booking.adminPersonId,
      this.booking.adminPersonName);
    this.selectedCtuPerson = this.commercialService.populateSelectedDropdown(this.booking.idCtuPerson,
      this.booking.ctuPersonName);
    this.checkIsMediaLengthRequired();
    this.setLookupConfig();
  }

  /**
   * @description To set the configuration for all lookup components
   * @author Dhaval Patel
   * @memberof BookingDetailsDefaultComponent
   */
  setLookupConfig() {
    this.setBarterConfig();
    this.setBuyerContactConfig();
    this.setAdminPersonConfig();
    this.setSalesPersonConfig();
    this.setCtuPersonConfig();
  }

  /**
   * @description To set the configuration for barter lookup component
   * @author Dhaval Patel
   * @memberof BookingDetailsDefaultComponent
   */
  setBarterConfig() {
    this.barterLookupConfig = new SelectConfig({
      placeholder: this.config.userBundle[this.defaultOption],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_BARTER_URL'),
      displayInvoiceCode: false,
      extraParams: defaultBarterParams,
      searchKeyParamName: 'barterName'
    });
  }

  /**
   * @description To set the configuration for buyer contact lookup component
   * @author Dhaval Patel
   * @memberof BookingDetailsDefaultComponent
   */
  setBuyerContactConfig() {
    this.buyerContactLookupConfig = new SelectConfig({
      placeholder: this.config.userBundle[this.defaultOption],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_BUYER_CONTRACT_URL'),
      displayInvoiceCode: false,
      extraParams: defaultBuyerContactParams,
      searchKeyParamName: 'buyerContactName'
    });
  }

  /**
   * @description To set the configuration for admin person lookup component
   * @author Dhaval Patel
   * @memberof BookingDetailsDefaultComponent
   */
  setAdminPersonConfig() {
    this.adminPersonLookupConfig = new SelectConfig({
      placeholder: this.config.userBundle[this.defaultOption],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_ADMIN_PERSON_URL'),
      displayInvoiceCode: false,
      extraParams: defaultAdminPersonParams,
      searchKeyParamName: 'adminPersonName'
    });
  }

  /**
   * @description To set the configuration for sales person lookup component
   * @author Dhaval Patel
   * @memberof BookingDetailsDefaultComponent
   */
  setSalesPersonConfig() {
    this.salesPersonLookupConfig = new SelectConfig({
      placeholder: this.config.userBundle[this.defaultOption],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_SALES_PERSON_URL'),
      displayInvoiceCode: false,
      extraParams: defaultSalesPersonParams,
      searchKeyParamName: 'salesPersonName'
    });
  }

  /**
   * @description To set the configuration for ctu person lookup component
   * @author Dhaval Patel
   * @memberof BookingDetailsDefaultComponent
   */
  setCtuPersonConfig() {
    this.ctuPersonLookupConfig = new SelectConfig({
      placeholder: this.config.userBundle[this.defaultOption],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_CTU_PERSON_URL'),
      displayInvoiceCode: false,
      extraParams: defaultctuPersonParams,
      searchKeyParamName: 'ctuPersonName'
    });
  }

  /** This method used on DOM to hide the element
   *
   *
   * @param {*} key
   * @returns
   * @memberof BookingDetailsDefaultComponent
   */
  hideUIElements(key) {
    return this.dataShareService.hideUIElements(key);
  }

  // Functions related to Buyer Contact//
  onbuyerContactSelected(value: any) {
    this.booking.buyerContactId = this.selectedBuyerContact.id = value.id;
    this.booking.buyerContactName = this.selectedBuyerContact.text = value.text;
  }

  removeSelectedbuyerContact() {
    this.selectedBuyerContact = _.clone(DEFAULT_SELECTED_ITEMS);
    this.booking.buyerContactId = null;
    this.booking.buyerContactName = null;

  }

  getBuyerContactResponse(value: any): void {
    if (!_.isUndefined(value.buyerContact) && value.buyerContact.length > 0) {
      this.buyerContactList = [];
      _.each(value.buyerContact, (item) => {
        this.buyerContactList.push({
          id: item.buyerContactId,
          text: item.buyerContactName,
          source: item
        });
      });
    }
  }

  // Functions related to Admin Persons//
  onAdminPersonSelected(value: any) {
    this.booking.adminPersonId = this.selectedAdminPerson.id = value.id;
    this.booking.adminPersonName = this.selectedAdminPerson.text = value.text;
  }

  removeSelectedAdminPerson() {
    this.selectedAdminPerson = _.clone(DEFAULT_SELECTED_ITEMS);
    this.booking.adminPersonId = null;
    this.booking.adminPersonName = null;

  }

  getAdminPersonResponse(value: any): void {

    if (!_.isUndefined(value.adminPerson) && value.adminPerson.length > 0) {
      this.adminPersonList = [];
      _.each(value.adminPerson, (item) => {
        this.adminPersonList.push({
          id: item.idAdminPerson,
          text: item.adminPersonName,
          source: item
        });
      });
    }
  }

  // Functions related to Sales Persons//
  onSalesPersonSelected(value: any) {
    this.booking.salesPersonId = this.selectedSalesPerson.id = value.id;
    this.booking.salesPersonName = this.selectedSalesPerson.text = value.text;
  }

  removeSelectedSalesPerson() {
    this.selectedSalesPerson = _.clone(DEFAULT_SELECTED_ITEMS);
    this.booking.salesPersonId = null;
    this.booking.salesPersonName = null;

  }

  getSalesPersonResponse(value: any): void {
    if (!_.isUndefined(value.salesPerson) && value.salesPerson.length > 0) {
      this.salesPersonList = [];
      _.each(value.salesPerson, (item) => {
        this.salesPersonList.push({
          id: item.idSalesPerson,
          text: item.salesPersonName,
          source: item
        });
      });
    }
  }

  // Functions related to Barter//
  onBarterSelected(value: any) {
    this.booking.barterId = this.selectedBarter.id = value.id;
    this.booking.barterName = this.selectedBarter.text = value.text;
    this.booking.barterInvoiceStatusCode = this.selectedBarter.invoiceStatusCode = value.invoiceStatusCode;
  }

  removeSelectedBarter() {
    this.selectedBarter = _.clone(DEFAULT_SELECTED_ITEMS);
    this.booking.barterId = null;
    this.booking.barterName = null;
    this.booking.barterInvoiceStatusCode = this.selectedBarter.invoiceStatusCode = null;

  }

  getBarterResponse(value: any): void {
    if (!_.isUndefined(value.organisation) && value.organisation.length > 0) {
      this.salesPersonList = [];
      _.each(value.organisation, (item) => {
        this.barterList.push({
          id: item.organisationId,
          text: item.organisationName,
          source: item
        });
      });
    }
  }

  // Functions related to Barter//
  onCtuPersonSelected(value: any) {
    this.booking.idCtuPerson = this.selectedBarter.id = value.id;
    this.booking.ctuPersonName = this.selectedBarter.text = value.text;
  }

  removeSelectedCtuPerson() {
    this.selectedBarter = _.clone(DEFAULT_SELECTED_ITEMS);
    this.booking.idCtuPerson = null;
    this.booking.ctuPersonName = null;
  }

  getCtuPersonResponse(value: any): void {
    if (!_.isUndefined(value.ctuPerson) && value.ctuPerson.length > 0) {
      this.ctuPersonList = [];
      _.each(value.ctuPerson, (item) => {
        this.ctuPersonList.push({
          id: item.idCtuPerson,
          text: item.ctuPersonName,
          source: item
        });
      });
    }
  }

  /**
   * Manages calculation for Net Media and notifies the Campaign Details
   *
   * @memberof BookingDetailsDefaultComponent
   */
  manageCalculations() {
    this.booking.netMediaValue = this.commercialService.calculateNetMedia(this.rowIndex, this.campaignDetails, 'bookings');
    this.commercialService.notifyCampaignDetails(this.bookings);
  }

  checkIsMediaLengthRequired() {
    if (Array.isArray(this.commercialConfig.uiControl.mediaLengthRequired)) {
      const statuses = this.commercialConfig.uiControl.mediaLengthRequired;
      if (statuses.indexOf(this.campaignDetails.campaignStatusId) > -1
        || statuses.indexOf(this.bookingStatusId) > -1) {
        this.isMediaLengthRequired = true;
      } else {
        this.isMediaLengthRequired = false;
      }
    } else {
      this.isMediaLengthRequired = false;
    }

  }

  manageGrossMediaValue() {
    super.manageGrossMediaValueWithoutIndex('bookings', false);
  }

  trackByProductionType(index, item) {
    return item?.productionTypeId;
  }
}
