import { Component, AfterViewInit } from '@angular/core';
import { IFloatingFilter, IFloatingFilterParams, TextFilter } from 'ag-grid-community';
import { AgFrameworkComponent } from 'ag-grid-angular';

export interface TextFloatingFilterParams extends IFloatingFilterParams {
  value: string;
  decimalRound: number;
}

@Component({
  selector: 'app-ag-text-floating-filter',
  templateUrl: './ag-text-floating-filter.component.html',
  styleUrls: ['./ag-text-floating-filter.component.css']
})
export class AgTextFloatingFilterComponent implements IFloatingFilter, AgFrameworkComponent<TextFloatingFilterParams>, AfterViewInit {

  private params: TextFloatingFilterParams;
  public currentValue: string;

  agInit(params: TextFloatingFilterParams): void {
    this.params = params;
    this.currentValue = params.currentParentModel() ? params.currentParentModel().value : params.value;
  }

  valueChanged() {
    this.params.parentFilterInstance((instance) => {
      (instance as TextFilter).onFloatingFilterChanged('text', this.buildModel());
    });
  }

  clearFilterClick() {
    this.currentValue = '';
    this.valueChanged();
  }

  ngAfterViewInit(): void {
    this.valueChanged();
  }

  onParentModelChanged(parentModel: any): void {
    if (!parentModel) {
      this.currentValue = '';
    } else {
      // note that the filter could be anything here, but our purposes we're assuming a greater than filter only,
      // so just read off the value and use that
      this.currentValue = parentModel.value;
    }
  }

  buildModel(): any {
    if (this.currentValue === '') {
      return null;
    }
    return {
      value: this.currentValue,
      type: 'text',
      decimalRound: this.params.decimalRound
    };
  }

}
