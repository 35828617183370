<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [readOnlyModal]="readOnlyModal" [titleBackgroundColor]="resolveObject.brick.color"
    (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
    <div class="modal-body hod-modal-body">
        <div [ngClass]="{'readOnly' : readOnlyModal}">
            <div class="control_group">
                <select id="Select3" class="select form-control col-md-10" [(ngModel)]="network">
                    <option [ngValue]="null" selected>{{userBundle['workspace.collection.network.lookup.option']}}</option>
                    <option *ngFor="let item of hodOptions; trackBy: trackByHodId" [ngValue]="item">{{item.hodName}}</option>
                </select>
            </div>
        </div>
    </div>
</app-sb-modal-wrapper>
