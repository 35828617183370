import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DataShareService } from '../../core/services/data-share.service';
import { ICellRendererParams } from 'ag-grid-community';
import { InitialConfigModel } from '../../models';

@Component({
  selector: 'app-ag-cart-list-item',
  templateUrl: './ag-cart-list-item.component.html',
  styleUrls: ['./ag-cart-list-item.component.css']
})
export class AgCartListItemComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;
    /**
     * application initial config file
     * @private
     * @type {*}@memberof AgFurniture
     */
  public initialConfig: InitialConfigModel;

  constructor(private dataShareService: DataShareService) {
    this.initialConfig = this.dataShareService.getInitialConfig();
  }

    // called on init
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

    // called when the cell is refreshed
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onDeleteClick(): void {
    this.params.context.componentParent.deleteFrameFromCart(this.params.data);
  }

  onImageClick(event: MouseEvent): void {
    event.stopPropagation();
    this.params.context.componentParent.onShowFurnitureImages(this.params.data);
  }
}
