import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

export class NgbDatepickerHelper {

  /**
   * @description convert ngbDateStruct to Date object
   * @author Alkesh Shah
   * @param {NgbDateStruct} inst ngbDateStruct instance
   * @returns {Date}
   * @memberof NgbDatePickerHelper
   */
  static convertDateStructToDate(inst: NgbDateStruct): Date {
    let date = null;
    if (inst) {
      date = new Date(inst.year, inst.month - 1, inst.day);
    }
    return date;
  }

  /**
   * @description Convert Date object to ngbDateStruct object
   * @author Alkesh Shah
   * @param {Date} date
   * @returns {NgbDateStruct}
   * @memberof NgbDatePickerHelper
   */
  static convertDateToDateStruct(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  static isEqual(date: Date, dateStruct: NgbDateStruct): boolean {
    return (date.getFullYear() === dateStruct.year &&
      date.getMonth() + 1 === dateStruct.month &&
      date.getDate() === dateStruct.day);
  }

  /**
   * @description convert ngbDateStruct to standard date string "<<Year>><<Month>><<Day>>"
   * ie. for 1-Jan-2020 it will return "20200101"
   * <<Year>> in four digit, <<Month>><<Day>> in 2 digit padded
   * @author Alkesh Shah
   * @static
   * @param {NgbDateStruct} date - input date struct to convert
   * @returns {string} formatted string
   * @memberof NgbDatepickerHelper
   */
  static getDateString(date: NgbDateStruct): string {
    return `${date.year}${_.padStart(date.month.toString(), 2, '0')}${_.padStart(date.day.toString(), 2, '0')}`;
  }
}
