import { Component, OnInit, Input } from '@angular/core';
import {
  CellAttributeService,
  BrickBaseService,
  DataShareService,
  LogHelperService
} from '../../../core/services';
import { CellValues } from '../../../models/workspace/index';
import { InitialConfigModel } from '../../../models';
import { SotPCMBase } from '../sot-pcm-base';

@Component({
  selector: 'app-sot-pcm-modal',
  templateUrl: './sot-pcm-modal.component.html',
  styleUrls: ['./sot-pcm-modal.component.css']
})
export class SotPCMModalComponent extends SotPCMBase implements OnInit {

  /**
   * It will take data to configure modal window.
   */
  @Input() resolveObject: CellValues;
  readOnlyModal = false;
  isShow = false;

  /**
   * It contians initial config data.
   */
  public initialConfig: InitialConfigModel;

  constructor(
    private cellAttributeService: CellAttributeService,
    private brickBaseService: BrickBaseService,
    dataShareService: DataShareService,
    logHelperService: LogHelperService
  ) {
    super(dataShareService, logHelperService);
  }

  ngOnInit() {
    const selectedValue = this.resolveObject.selectedValues ? this.resolveObject.selectedValues : {};
    this.init(selectedValue);
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    setTimeout(() => {
      this.isShow = true;
      this.stepUpdated(this.increment);
    }, 500);
  }

  /**
   * callback to close modal popup window
   * @param {*} event - modal event object
   * @memberof SotPCMModalComponent
   */
  onModalClosed(event) {
    let value = this.resolveObject.selectedValues;
    let defaultCondition = (this.increment === this.defaultOptions.step) && (this.maxValue === this.defaultOptions.ceil) && (this.minValue === this.defaultOptions.floor) && (this.def === 15);
    let checkCondition = this.resolveObject.selectedValues['-99'] ? defaultCondition : (this.maxValue === value.max) && (this.minValue === value.min) && (this.increment === value.increment) && (this.def === value.def);
    if (event.reason === 'escape' && !(checkCondition)){
      if (window.confirm(this.initialConfig['userBundle']['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    }
    else{
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * callback function - called during save click
   * @param {*} event - modal event object
   * @memberof SotPCMModalComponent
   */
  onModalSaved(event) {
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    if (this.isValidSelection()) {
      values.selectedValues = {
        min: this.minValue,
        max: this.maxValue,
        def: this.def,
        increment: this.increment
      };
      values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.SOT, values.selectedValues);
      values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.SOT, values.selectedValues);
      event.activeModal.close(values);
    }
  }

  /**
   * @description
   * @author Kishan Patel
   * @param {*} no
   * @returns
   * @memberof SotPCMModalComponent
   */
  maxMultipleOf(no) {
    let maxMultiple = 0;
    for (let index = 1; index <= 100; index++) {
      if ((no * index) <= 100) {
        maxMultiple = no * index;
      }
    }
    return maxMultiple;
  }
}
