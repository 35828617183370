import { Component, AfterViewInit } from '@angular/core';
import { IFloatingFilter, IFloatingFilterParams, TextFilter } from 'ag-grid-community';
import { AgFrameworkComponent } from 'ag-grid-angular';

export interface SelectFloatingFilterParams extends IFloatingFilterParams {
  value: string;
  valKey: string;
  textKey: string;
  dataArr(): any[];
}

@Component({
  selector: 'app-ag-select-floating-filter',
  templateUrl: './ag-select-floating-filter.component.html',
  styleUrls: ['./ag-select-floating-filter.component.css']
})
export class AgSelectFloatingFilterComponent implements IFloatingFilter, AgFrameworkComponent<SelectFloatingFilterParams>, AfterViewInit {

  private params: SelectFloatingFilterParams;
  public currentValue: string;

  public dataArr = [];
  public valKey: string;
  public textKey: string;

  agInit(params: SelectFloatingFilterParams): void {
    this.params = params;
    this.currentValue = params.currentParentModel() ? params.currentParentModel().value : params.value;
    this.dataArr = params.dataArr();
    this.valKey = params.valKey;
    this.textKey = params.textKey;
  }

  valueChanged() {
    this.params.parentFilterInstance((instance) => {
      (instance as TextFilter).onFloatingFilterChanged('select', this.buildModel());
    });
  }

  clearFilterClick() {
    this.currentValue = '';
    this.valueChanged();
  }

  ngAfterViewInit(): void {
    this.valueChanged();
  }

  onParentModelChanged(parentModel: any): void {
    if (!parentModel) {
      this.currentValue = '';
    } else {
      // note that the filter could be anything here, but our purposes we're assuming a greater than filter only,
      // so just read off the value and use that
      this.currentValue = parentModel.value;
    }
  }

  buildModel(): any {
    if (this.currentValue === '') {
      return null;
    }
    return {
      value: this.currentValue,
      type: 'select'
    };
  }

  trackByValKey(index, item) {
    return item && item[this.valKey];
  }

}
