import { DataShareService } from '../../core/services/data-share.service';
import { AudienceCategoryGroup, AudienceCategory, AudienceCategoryType } from './../../models/audience-filter.model';
import * as _ from 'lodash';
import { AudienceBricService } from '../../core/services/audience-bric.service';

export class PrimaryAudienceBase {

  /* store current audience tag from inital config
  *
  * @type {*}
  * @memberof AudienceBase
  */
  public audienceTags: any;
  /**
   * store category for audience from inital config
   * @type {*}
   * @memberof AudienceBase
   */
  public audienceCategoryGroup: AudienceCategoryGroup;

  /**
   * It defines category type for audience.
   */
  public audienceCategoryType: AudienceCategoryType[];

  /**
   * store single value from audiance grid
   *
   * @type {*}
   * @memberof AudienceBase
   */
  selectedAudience: AudienceCategory = new AudienceCategory();

  userBundle: any;
  /**
   * @description for store uiControl
   * @memberof AudienceBase
   */
  uiControl: any;
  /**
   * @description for  store primaryAudienceTypes in Uicontrol
   * @memberof AudienceBase
   */
  primaryAudienceTypes;

  constructor(
    private dataService: DataShareService,
    private audienceBricService: AudienceBricService
  ) { }

  /**
   * @description getting audience Category group and option
   * @author Kishan Patel
   * @param {*} audienceCategoryGroupId
   * @memberof PrimaryAudienceBase
   */

  init(audienceCategoryGroupId) {
    this.userBundle = this.dataService.getInitialConfigByKey('userBundle');
    this.uiControl = this.dataService.getInitialConfigByKey('uiControl');
    let audienceCategoryGroup = _.cloneDeep(this.dataService.getInitialConfigByKey('audienceCategoryGroup'));
    const audienceTags = _.cloneDeep(this.dataService.getInitialConfigByKey('audienceTags'));
    this.primaryAudienceTypes = this.uiControl.primaryAudienceTypes[audienceCategoryGroupId];
    this.audienceCategoryType = [];
    audienceCategoryGroup = this.audienceBricService.populateDataForAudienceBric(
      audienceCategoryGroupId,
      audienceCategoryGroup, audienceTags);

    for (const primaryAudienceTypeId of this.primaryAudienceTypes) {
      this.audienceCategoryType.push(audienceCategoryGroup.audienceCategoryType.filter(audienceCategoryType => audienceCategoryType.audienceCategoryTypeId === primaryAudienceTypeId)[0]);
    }
    this.audienceCategoryGroup = audienceCategoryGroup;
  }
}
