import { Component, OnInit } from '@angular/core';
import { StateService } from '../../../app/core/services/state.service';
import { CampaignDetailsService } from '../campaign-details/campaign-details.service';
import { FreeOFChargeResult, HistoryROC } from '../commercial.model';
import { SystemFlags } from '../../models';
import { DataShareService, LogHelperService } from '../../core/services';
import { CommercialService } from '../../core/services/commercial.service';
import { CommercialButtonService } from '../commercial-buttons/commercial-buttons.service';
import { CampaignStatus } from '../../core/enum';
import { DatePipe } from '@angular/common';
import { GLOBAL } from '../../core/utils/app.constant';
import { CommercialBase } from '../commercial-base';
import { RequiredCampaignFields, RequiredBookingsFields, PopulateCommercialFields, PopulateBookingDetailFields } from '../../models/RequiredFields';

@Component({
  selector: 'app-commercial-hk',
  templateUrl: './commercial-hk.component.html',
  styleUrls: ['./commercial-hk.component.css']
})
export class CommercialHkComponent extends CommercialBase implements OnInit {
  systemFlags = SystemFlags;
  /**
   * @description commercial data
   * @memberof CommercialHkComponent
   */
  public commercialData;

  /**
   * @description Accordian for campaign details
   * @memberof CommercialHkComponent
   */
  hideCampaignDetails: boolean;

  /**
   * @description Accordian booking grid
   * @type {boolean}
   * @memberof CommercialHkComponent
   */
  hideBookingGrid: boolean;
  /**
   * @description Accordian for Discount Grid
   * @memberof CommercialHkComponent
   */
  hideDiscountGrid: boolean;
  /**
   * @description set bookingStatusList
   * @memberof CommercialUkComponent
   */
  bookingStatusList = GLOBAL.bookingStatusList;

  /**
   * @description this will show the history of campaign updated in case of reason/cancel for change
   * @memberof CommercialHkComponent
   */
  historyROC = new HistoryROC();

  constructor(
    stateService: StateService,
    campaignDetailsService: CampaignDetailsService,
    dataShareService: DataShareService,
    commercialService: CommercialService,
    commercialButtonService: CommercialButtonService,
    logHelperService: LogHelperService,
    datePipe: DatePipe
  ) {
    super(stateService, logHelperService, commercialButtonService, dataShareService, commercialService, datePipe, campaignDetailsService);
  }

  ngOnInit() {
    this.restoreCommercialData();
    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  /**
   * @description get commercial data
   * @author Darshan Vachhani
   * @memberof CommercialHkComponent
   */
  restoreCommercialData() {
    this.commercialConfig.user = this.dataShareService.getUserModel();
    this.getCommercialDataFromService();
  }

  /**
   * @description get commercial data from service
   * @author Darshan Vachhani
   * @memberof CommercialHkComponent
   */
  getCommercialDataFromService(isManual = false) {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
    };

    this.commercialService.loadCommercialCampaignDetails(param).subscribe((data) => {
      if (data.status === 'OK') {
        this.commercialConfig.campaignStatus = data.data.campaignStatusId;
        this.commercialConfig.bookingStatus = data.data.bookingStatus;
        this.commercialConfig.uiControl = data.data.uiControl;
        this.dataShareService.setCommercialConfig(this.commercialConfig);
        this.commercialData = data.data;
        if (isManual) {
          this.dataShareService.updateBookingList(this.commercialData.bookingList);
        }

        this.commercialData.bookingStatusName =
          this.campaignDetailsService.getStatusNameFromId(data.data.campaignStatusId);
        const assignedToList = this.dataShareService.getInitialConfigByKey('assignedTo');
        this.campaignDetails = this.campaignDetailsService.populateCampaignDetails(
          this.commercialData, this.initialConfig, assignedToList);
        if (this.commercialData.bookingDetails) {
          this.processDiscounts(this.commercialData);
        }
        if (isManual) {
          this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT);
        } else {
          this.campaignDetailsService.calculateCPT(this.campaignDetails);
        }
        this.historyROC = this.campaignDetailsService.populateHistoryROC(this.historyROC, data.data.auditReasonsHistoryMap, this.commercialConfig.uiControl.historyROC);
      }
    });

  }

  /**
   * @description this is output event from commercialbutton when click on update/option/book/cancel button click
   * @author Darshan Vachhani
   * @param {*} status
   * @memberof CommercialHkComponent
   */
  onBookCampaign(status: number) {
    if (this.validateCommercialCampaign(status)) {
      status = status || this.campaignDetails.campaignStatusId;

      if ((status === this.bookingStatusList.Confirmed && this.campaignDetails.grossMediaValue === 0 && this.commercialConfig.uiControl.enableFOC) ||
        this.isConfirm(status)) {
        this.confirmCampaign(this.createRequestJSONForCommercial);
        /*
          Need to trigger ROChange OR ROCancel in below scenario [SM-5283]
          1. It should be loaded campaign and there should be data in auditType(to populate drop downs) in Initial Config
          2. Trigger ROCancel OR
          3. Trigger ROChange
        */
      } else if (this.isROC(status)) {
        this.handleROC(status);
      } else if ((this.commercialConfig.uiControl.tradingEnabled ?
        (this.campaignDetails.grossMediaValue === 0 && this.commercialConfig.uiControl.enableFOC) : false) &&
        status === CampaignStatus.confirm) {
        this.commercialButtonService.openFreeOFChargeOverlay().then((result: FreeOFChargeResult) => {
          if (result) {
            this.campaignDetails.freeOfCharge = result.freeOfCharge;
            let comment = '';
            if (result.freeOfCharge && result.freeOfCharge.specifyOther) {
              comment = (this.commercialButtonService.startsWith(result.freeText, 'FOC - ') ? '' : 'FOC - ') + result.freeText;
            } else {
              comment = (this.commercialButtonService.startsWith(result.freeOfCharge.auditReasonName, 'FOC - ') ? '' : 'FOC - ')
                + result.freeOfCharge.auditReasonName;
            }
            result.freeOfCharge.comment = comment;
            this.campaignDetails.comments = (this.campaignDetails.comments ?
              (`${this.campaignDetails.comments}\n\r${comment}`) : comment);
            const campaignStatus = CampaignStatus.confirm || this.campaignDetails.campaignStatusId;
            const param = {
              bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
              data: JSON.stringify(this.createRequestJSONForCommercial(campaignStatus))
            };
            this.commercialButtonService.validateCommercialScreenServiceRequest(param, this.campaignDetails.campaignStatusId);
          }
        });
      } else {
        this.validateServiceRequest(status, this.createRequestJSONForCommercial);
      }
    }
  }

  /**
   * Need to trigger ROChange OR ROCancel in below scenario
   * @param status Status
   */
  handleROC(status) {
    this.commercialButtonService.openReasonForChangeOverlay(
      this.commercialButtonService.populateDataParamReasonForChange(status, this.campaignDetails, this.commercialConfig.uiControl))
      .then((result: any) => {
        if (result) {
          this.campaignDetails.reasonForChange = result.reasonForChange;
          if (result.freeText !== '' && result.freeText != null) {
            this.campaignDetails.reasonForChange.freeText = result.freeText;
          }

          const param = {
            bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
            data: JSON.stringify(this.createRequestJSONForCommercial(status))
          };
          this.commercialButtonService.validateCommercialScreenServiceRequest(param, status);
        }
      }, (reason) => {
        console.log(reason);
      });
  }

  /**
   * @description commercial form validity
   * @author Darshan Vachhani
   * @returns {boolean}
   * @memberof CommercialHkComponent
   */
  validateCommercialCampaign = (status) => {
    const requiredFields: RequiredCampaignFields = new RequiredCampaignFields();
    requiredFields.campaignEndDate = true;
    let message = this.validateCommonFields(requiredFields);
    if (!message && this.campaignDetails.bookingList) {
      this.campaignDetails.bookingList.forEach((obj: any) => {
        const requiredBookingsFields: RequiredBookingsFields = new RequiredBookingsFields();
        if (!message) {
          message = this.validateBookingFields(obj, requiredBookingsFields);
        }
        if (!message && obj.containsDigital) {
          message = this.validateMediaLength(obj, status, true);
        }
      });
    }
    return this.isValid(message);
  }

  /**
   * @description create requestJSON for passing to backend
   * @author Darshan Vachhani
   * @param {*} status
   * @returns
   * @memberof CommercialHkComponent
   */
  createRequestJSONForCommercial = (status) => {
    const campaignDetails: any = this.campaignDetails;
    const populate: PopulateCommercialFields = this.getCommercialFields();
    const temp = this.populateBookCommercialCampaignParam(status, campaignDetails, populate);
    const populateBooking: PopulateBookingDetailFields = this.getBookingDetailFields();
    temp.bookingList = this.populateBookingDetailsServiceObject(campaignDetails, 'bookingList', populateBooking);
    temp.discounts = campaignDetails.discounts;
    if (campaignDetails.discountGridData.discounts) {
      temp.bookingDetails = campaignDetails.discountGridData.discounts;
    }
    return temp;
  }

  /**
   * To override the commercial fields
   * This override fields are used to override the default value
   * @memberof CommercialHkComponent
   * @author Dhaval Patel
   */
  getCommercialFields() {
    const populate: PopulateCommercialFields = super.getCommercialFields();
    populate.setPCFields();
    populate.specialist = false;
    populate.grossMediaValue = false;
    populate.subAgency = false;
    populate.assignedToId = false;
    populate.financeAmount = true;
    populate.selectedCampaignReference = true;
    return populate;
  }

  /**
   * To override the booking detail fields
   * This override fields are used to override the default value
   * @memberof CommercialHkComponent
   * @author Dhaval Patel
   */
  getBookingDetailFields() {
    const populate: PopulateBookingDetailFields = super.getBookingDetailFields();
    populate.setPICFields();
    populate.setCCBIBFields();
    populate.setPOORIFields();
    populate.financeAmount = true;
    populate.setPMDBFields();
    return populate;
  }

  processDiscounts(commercialData) {
    const discountGridData = {
      discounts: commercialData.bookingDetails,
      statusCategory: commercialData.statusCategory,
      rateTypes: commercialData.rateTypes // SM-4113
    };
    this.campaignDetails.discountGridData = discountGridData;
  }

  async manuallyCPTUpdated() {
    const campaigndata = this.createRequestJSONForCommercial(this.campaignDetails.campaignStatusId);
    await this.updateCommercialInfoForCPT(campaigndata);
    this.getCommercialDataFromService(true);
  }

}
