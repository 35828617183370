/* tslint:disable:no-duplicate-string */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/toPromise';
import { getFormData } from '../../utils/formData';
import { GLOBAL } from '../../utils/app.constant';
import { DataShareService } from '../../services';
import { _throw } from 'rxjs/observable/throw';

@Injectable()
export class LookupViewService {
  readonly URL_ENCODED = 'application/x-www-form-urlencoded';
  /**
   * Constructor
   *
   * @param http injected the http object
   */
  constructor(
    private http: HttpClient,
    private dataShareService: DataShareService
  ) { }

  /**
   * Get the route data
   * @param query queryParams
   * @return Responsed route data
   */
  getRouteData(query: string): Observable<any[]> {
    const data = { routeFrameId: query };
    const param = {
      hideLoader: true,
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
      action: 'lookupRouteFrameId',
      data: JSON.stringify(data)
    };
    const headers = new HttpHeaders({
      'Content-Type': this.URL_ENCODED
    });
    const requestOptions = {
      headers,
      body: getFormData(param),
    };
    return this.http.request(GLOBAL.HTTP_METHOD, this.dataShareService.getServiceCallUrlByKey('LOOKUP_ROUTEID_URL'), requestOptions)
      .map(this.extractData)
      .catch(this.handleErrorObservable);
  }

  /**
   * Get the tag data
   * @param query queryParams
   * @return Responsed tag data
   */
  getTagData(data: string | any): Observable<any[]> {
    const param = {
      hideLoader: true,
      data: JSON.stringify(data)
    };
    const headers = new HttpHeaders({
      'Content-Type': this.URL_ENCODED
    });
    const requestOptions = {
      headers,
      body: getFormData(param),
    };
    return this.http.request(GLOBAL.HTTP_METHOD, this.dataShareService.getServiceCallUrlByKey('LOOKUP_RESPONSE_URL'), requestOptions)
      .map(this.extractData)
      .catch(this.handleErrorObservable);
  }

  /**
   * Convert the responsed data to JSON object.
   * @param res resposed data
   * @return Converted JSON response.
   */
  private extractData(res: HttpResponse<any>) {
    /* tslint:disable:no-string-literal */
    return res['data'] || {};
  }

  /**
   * Handle the error.
   * @param error response with error.
   * @return Throw the error message.
   */
  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    return _throw(error.message || error);
  }

  /**
   * @description Fetch the data when the lookup url and params are been passed.
   * @author Amit Mahida
   * @param {string} url
   * @param {object} params
   * @param {boolean} [isDataParam]
   * @param {*} [query]
   * @returns
   * @memberof LookupViewService
   */
  getData(url: string, params: object, isDataParam: boolean = false, query?: any) {
    if (isDataParam) {
      params['data'] = JSON.stringify(query);
    }

    const headers = new HttpHeaders({
      'Content-Type': this.URL_ENCODED
    });
    const requestOptions = {
      headers,
      body: getFormData(params),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      url,
      requestOptions
    )
      .map(this.extractData)
      .catch(this.handleErrorObservable);
  }
}
