import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { DataShareService, LogHelperService } from '../../../core/services/index';
import { UiControl } from '../../../models';
import { DatePipe } from '@angular/common';
import { GLOBAL } from '../../../core/utils/app.constant';
import { NgbDatepickerHelper } from '../../../core/components/ngb-datepicker/ngb-datepicker-helper';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-campaign-split',
  templateUrl: './campaign-split.component.html'
})
export class CampaignSplitComponent implements OnInit, AfterViewInit {
  @Input() resolveObject;
  @ViewChild('startDate') startDateEle: NgbInputDatepicker;
  campaignSplitModal = {
    startDate: null
  };
  userBundle: any;
  uiControl: UiControl;
  titleColor = 'rgb(248, 74, 74)';
  splitOptions;
  futureSplitFrom;

  minStartDate: NgbDateStruct;
  maxStartDate: NgbDateStruct;
  /**
   * @description specific startDate day properties cached object for ngbDatePicker day template
   * @memberof RangeComponent
   */
  startDayObjCache = {};
  constructor(
    private dataShareService: DataShareService,
    private logHelperService: LogHelperService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.splitOptions = this.resolveObject.selected;
    if (this.splitOptions.futureSplitFrom) {
      this.futureSplitFrom = new Date(this.splitOptions.futureSplitFrom).setHours(0, 0, 0, 0);
    } else {
      this.futureSplitFrom = new Date(this.splitOptions.startDate).setHours(0, 0, 0, 0);
      // this will be the case where 'futureSplitFrom' is not availabe in future dates of ongoing campaigns
    }
    const minDate = new Date(this.splitOptions.startDate as Date);
    this.minStartDate = NgbDatepickerHelper.convertDateToDateStruct(minDate);
    const maxDate = new Date(this.splitOptions.endDate as Date);
    this.maxStartDate = NgbDatepickerHelper.convertDateToDateStruct(maxDate);
  }

  ngAfterViewInit() {
    // Below code for SM-3681
    setTimeout(() => {
      this.startDateEle.open();
    }, 300);
  }

  /**
   * open start date datepicker on click
   * @param $event click event object
   * @param datePicker start/end date picker object
   */
  openDatePicker($event: Event, datePicker: NgbInputDatepicker): void {
    $event.preventDefault();
    $event.stopPropagation();
    datePicker.toggle();
  }

  /**
   * close date picker on out-side click
   * @param event click event object
   * @param datePicker start/end date picker object
   */
  closeDatePickerOutsideClick(event: any, datePicker: NgbInputDatepicker): void {
    if (datePicker.isOpen()) {
      if (event.target.offsetParent == null && event.target.nodeName !== 'OPTION') {
        datePicker.close();
      } else if (event.target.offsetParent.nodeName !== 'NGB-DATEPICKER') {
        datePicker.close();
      }
    }
  }

  markDayDisabled = (date: NgbDateStruct): boolean => {
    const daystring = this.cacheStartDateObj(date, false);
    return this.startDayObjCache[daystring].isDisabled;
  }

  /**
   * @description prepare a startDate day object of ngb-datepicker
   * @author Alkesh Shah
   * @param {NgbDateStruct} date - current date
   * @returns
   * @memberof RangeComponent
   */
  prepareStartDayObj(date: NgbDateStruct, disabled: boolean) {
    let obj = {
      isToday: false,
      isDisabled: false,
      isWeekday: false
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selectedDay: any = NgbDatepickerHelper.convertDateStructToDate(date);
    if (disabled) {
      obj.isDisabled = true;
    } else if (NgbDatepickerHelper.isEqual(today, date)) {
      if (this.splitOptions.current) {
        obj.isDisabled = false;
        obj.isToday = true;
      } else {
        obj.isDisabled = true;
      }
    } else if ((selectedDay.setHours(0, 0, 0, 0) < today) || (selectedDay.setHours(0, 0, 0, 0) > today && selectedDay.setHours(0, 0, 0, 0) < this.futureSplitFrom)) {
      obj.isDisabled = true;
    } else {
      if (this.splitOptions.futureSplitInterval && this.splitOptions.futureSplitInterval === 7) {
        obj = this.futureSplitInterval7(obj, selectedDay);
      } else {
        obj = this.futureSplitInterval1(obj, selectedDay);
      }
    }
    return obj;
  }

  futureSplitInterval7(obj, selectedDay) {
    const weekNo = selectedDay.getWeek() + 1;
    const condition = ((weekNo % this.uiControl.defaultInChargeLength === 0)
      && (selectedDay.getDay() === this.uiControl.defaultInChargeDay));
    if (condition) {
      obj.isWeekday = true;
    } else if (selectedDay.getDay() === this.uiControl.defaultInChargeDay) {
      obj.isWeekday = false;
    } else {
      obj.isDisabled = true;
    }
    return obj;
  }

  futureSplitInterval1(obj, selectedDay) {
    let weekNumber = selectedDay.getWeek() + 1;
    if (this.uiControl.inchargeContinuousFromLastYear && selectedDay.getFullYear() > 2020) {
      weekNumber = selectedDay.getWeekFromDate(GLOBAL.continuousFromDate) + 1;
    }

    if (this.splitOptions.futureSplitInterval && this.splitOptions.futureSplitInterval === 1) {
      const condition = ((weekNumber % this.uiControl.defaultInChargeLength === 0)
        && (selectedDay.getDay() === this.uiControl.defaultInChargeDay));
      obj.isWeekday = condition;
    }
    return obj;
  }

  /**
   * @description cache specific days object if is not exists
   * @author Alkesh Shah
   * @param {NgbDateStruct} date - current date object
   * @returns {string}
   * @memberof RangeComponent
   */
  cacheStartDateObj(date: NgbDateStruct, disabled: boolean): string {
    const daystring = NgbDatepickerHelper.getDateString(date);
    if (!this.startDayObjCache[daystring]) {
      this.startDayObjCache[daystring] = this.prepareStartDayObj(date, disabled);
    }
    return daystring;
  }

  /**
   * @description get the specific dates property
   * @author Alkesh Shah
   * @param {NgbDateStruct} date current day
   * @param {string} key property key to retrive
   * @returns {boolean}
   * @memberof RangeComponent
   */
  getStartDayProp(date: NgbDateStruct, disabled: boolean, key: string): boolean {
    const daystring = this.cacheStartDateObj(date, disabled);
    return this.startDayObjCache[daystring][key];
  }

  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }

  onModalSaved(event) {
    const splitDetails: any = {};
    if (this.campaignSplitModal.startDate) {
      const todaysDate = this.datePipe.transform(new Date(), GLOBAL.DATE_PARSE_FORMAT);
      const startDate = NgbDatepickerHelper.convertDateStructToDate(this.campaignSplitModal.startDate as NgbDateStruct);
      const selectedDate = this.datePipe.transform(startDate, GLOBAL.DATE_PARSE_FORMAT);

      if (todaysDate === selectedDate) {
        splitDetails.splitCurrent = true;
      } else {
        splitDetails.splitOn = selectedDate;
      }
      event.activeModal.close(splitDetails);
    } else {
      this.logHelperService.logError(this.userBundle['workspace.split.validation.mandatory']);
    }

  }

}
