<app-filter-layout [resolveObject]="resolveObject" (closeClick)="closeClick()" (saveClick)="saveClick()">
  <div class="form-group-wrapper_geo">
    <div class="form-group_geo horizontal_geo">
      <label style="width:25%;">{{initialConfig.userBundle['workspace.collection.bric.frame.illumination']}}: </label>
      <div id="ill-1">
        <span class="text"></span>
        <input type="radio" class="radio_geo" [checked]="illumination==1" (change)="onIlluminationChange(1)" name="radio-illumination"
          id="radio-1">
        <label style="width:15%;" class="radio-label" for="radio-1"><em class="fa fa-circle-o "></em>{{initialConfig.userBundle['geoplanner.text.frame.on']}}</label>
      </div>
      <div id="ill-2">
        <span class="text"></span>
        <input type="radio" class="radio_geo" [checked]="illumination==2" (change)="onIlluminationChange(2)" name="radio-illumination"
          id="radio-2">
        <label style="width:15%;" class="radio-label" for="radio-2"><em class="fa fa-circle-o"></em>{{initialConfig.userBundle['geoplanner.text.frame.off']}}</label>
      </div>
      <div id="ill-3">
        <span class="text"></span>
        <input type="radio" class="radio_geo" [checked]="illumination==3" (change)="onIlluminationChange(3)" name="radio-illumination"
          id="radio-3">
        <label style="width:15%;" class="radio-label" for="radio-3"><em class="fa fa-circle-o "></em>{{initialConfig.userBundle['geoplanner.text.frame.all']}}</label>
      </div>
    </div>
  </div>
</app-filter-layout>