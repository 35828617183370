import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class LoaderService {
  public isVisible = false;
  backButton = false;

  constructor(private spinnerService: NgxSpinnerService) {} // private spinnerService: Ng4LoadingSpinnerService) { } //TODO : Uncomment
  show() {
    this.isVisible = true;
    this.spinnerService.show();
  }
  hide() {
    this.isVisible = false;
    this.spinnerService.hide();
  }

  setBackButton(val) {
    this.backButton = val;
  }

  getBackButton() {
    return this.backButton;
  }
}
