import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ExcelCell, ExportToExcel, ExcelColumn } from '../../../core/utils/exportToExcel';
import { GridOptions, ColDef } from 'ag-grid-community';
import { NgbDropdown, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataShareService } from '../../../core/services/data-share.service';
import { LocaleData } from '../../../core/utils/LocaleData';
import { AgDateCellRendererComponent, AgCustomRowUtil, AgTextFloatingFilterComponent, AgCustomFilterMenuComponent, AgCustomHeaderComponent, AgCustomSort, AgReshuffleLinkCellRendererComponent } from '../../../commercial/discount-grid/ag-grid-support/index';
import { DatePipe, DecimalPipe } from '@angular/common';
import { AgToggleCellRendererComponent } from '../../../commercial/discount-grid/ag-grid-support/cell-renderer/ag-toggle-cell-renderer/ag-toggle-cell-renderer.component';
import * as _ from 'lodash';
import { AgTrafficLightCellRendererComponent } from '../../../commercial/discount-grid/ag-grid-support/cell-renderer/ag-traffic-light-cell-renderer/ag-traffic-light-cell-renderer.component';
import { AgResuffleTrafficLightCellRendererComponent } from '../../../commercial/discount-grid/ag-grid-support/cell-renderer/ag-reshuffle-traffic-light-cell-renderer/ag-reshuffle-traffic-light-cell-renderer.component';

@Component({
  selector: 'reshuffle-detail-ls-result',
  templateUrl: './reshuffle-detail-ls-result.component.html',
  styleUrls: ['./../../../commercial/discount-grid/ag-grid-custom-style.css', './../../../commercial/discount-grid/ag-grid-col-select-style.css', './reshuffle-detail-ls-result.component.css'],
  host: {
    '(document:click)': 'onDocumentClick($event)'
  }
})
export class ReshuffleDetailLSResultComponent {

  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  @Input() resolveObject;

  @Output() selectionChange = new EventEmitter<any>();

  @Output() lockChange = new EventEmitter<any>();

  @Output() fillerChange = new EventEmitter<any>();

  rowData: any = [];
  public titleColor = '#03a9f4';
  groupingField = 'campaignId';

  /**
   * Ag-Grid Options Object
   */
  campaignGridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  campaignColumnDefs: ColDef[];

  footerRowData: any[];

  /**
   * user bundle data
   */
  userBundle: any = {};
  sortObj: AgCustomSort;
  expireTime = 0;
  expireMins = 0;
  intervalObj: any;
  allowConfirmReshuffle: boolean = true;

  constructor(
    private dataShareService: DataShareService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private activeModel: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.rowData = this.resolveObject.data.reshuffleSummary;
    if (this.rowData) {
      this.rowData.forEach((g) => {
        g.isFilterPass = true;
        g.filler = false;
        g.campaignRef = `${g.campaignRef}-${(g.columnIndex + 1)}`; //SM-9793
      });
    }

    this.setFooterRows();
    this.prepareColDef();
    this.prepareRowData();
    this.allowConfirmReshuffle = !this.resolveObject.data.allowConfirmReshuffle;
    // we pass an empty gridOptions in, so we can grab the api out
    this.campaignGridOptions = <GridOptions> {
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      onRowDataChanged: (event) => {
        event.api.setPinnedBottomRowData(this.footerRowData);
      },
      scrollbarWidth: 10,
      getRowHeight: this.getRowHeight,
      headerHeight: 34,
      rowSelection: 'single',
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      onFilterChanged: this.onFilterChanged,
      suppressScrollOnNewData: true,
      groupingField: this.groupingField,
      // suppressMovableColumns: false,
      getRowStyle: (params) => {
        if (params.data.isChildHeader) {
          return { backgroundColor: 'lightgray', fontWeight: 'bold' };
        }
      },
    };

    this.expireTime = this.resolveObject.data.expireTime;
    this.expireTime = this.expireTime / 1000; // converting milliseconds to seconds
    this.expireMins = 0;
    if (this.expireTime > 60) {
      this.expireMins = parseInt(String(this.expireTime / 60), 10);
      this.expireTime = parseInt(String(this.expireTime - (this.expireMins * 60)), 10);
    }

    this.intervalObj = setInterval(this.startTimer, 1000);
  }

  startTimer = () => {
    this.expireTime--;
    if (this.expireTime <= 0 && this.expireMins <= 0) {
      this.activeModel.dismiss('timeout');
      this.stopTimer();
    } else if (this.expireTime <= 0) {
      this.expireTime = 60;
      this.expireMins--;
    }
  }

  stopTimer() {
    clearInterval(this.intervalObj);
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else {
      height = 25;
    }
    return height;
  }

  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen()) {
      if ($event.button !== 2 && !dropdownEle._isEventFromToggle($event)
        && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
        dropdownEle.close();
      }
    }
  }

  // Data functions
  getChildRows(): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData);
  }

  getSelectedChildRows(): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData);
  }

  getGridSummary() {
    return `<span>${this.userBundle['discount.common.totalFrames']}: ${this.getChildRows().length} &#45;
      ${this.userBundle['discount.common.selectedFrames']}: ${this.getSelectedChildRows().length}</span>`;
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }

  isFullWidthCell = (rowNode): boolean => {
    return (rowNode.data.gridSummary ? true : false);
  }

  blankCellRenderer() {
    return '';
  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinRowIndex: 0 },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.map((r) => {
        r.isFilterPass = true;
      });
    }
    // Refresh Grid Header checkbox component
    this.campaignGridOptions.api['headerRootComp'].forEachHeaderElement((element) => {
      if (element.column && element.column.colId === 'selectChk' && element.columnApi && element.childComponents.length > 1) {
        const headerCmpIns = element.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  prepareColDef() {
    this.campaignColumnDefs = [
      {
        headerName: this.userBundle['common.campaignRef_columnno'] || 'Campaign Ref-Column',
        field: 'campaignRef',
        width: 135,
        cellRendererFramework: AgReshuffleLinkCellRendererComponent,
        cellRendererParams: {
          queryString: { 'id': 'campaignId' },
          expand: row => this.expandRow(row),
          collapse: row => this.collapseRow(row)
        },
        colSpan: (params) => {
          const data = params.data;
          if (!data.isParent) {
            return 3;
          }
          return 1;
        }
      },
      // {
      //   headerName: this.userBundle['reshuffle.text.columnNumber'] || 'Column Number',
      //   field: 'columnIndex',
      //   width: 70,
      //   pinned: 'left'
      // },
      {
        headerName: 'Filler',
        field: 'filler',
        filter: false,
        cellRendererFramework: AgToggleCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return false;
          },
          class_on: () => 'filler_on',
          class_off: () => 'filler_off'
        },
        width: 40,
      },
      {
        headerName: this.userBundle['reshuffle.text.current'] || 'Current',
        // @ts-ignore
        children: [{
          headerName: this.userBundle['reshuffle.text.objectiveStatus'] || 'Status',
          field: 'objectivesStatus',
          width: 45,
          filter: false,
          cellRendererFramework: AgTrafficLightCellRendererComponent,
          cellRendererParams: {
            noModification: true
          }
        },
        {
          headerName: this.userBundle['reshuffle.text.nbFrames_alloc'] || 'Nb Frame (Allocated)',
          field: 'nbFrames',
          width: 125,
          cellRenderer: (params) => {
            if (params.data.isParent) {
              return params.data.nbFrames;
            } else {
              if (params.data.isChildHeader) {
                return 'Frames';
              } else if (params.data.nbFrames !== undefined && params.data.nbFrames !== null && params.data.nbFramesPercent !== undefined && params.data.nbFramesPercent !== null) {
                return `${params.data.nbFrames} (${params.data.nbFramesPercent}%)`;
              } else if (params.data.nbFrames !== undefined && params.data.nbFrames !== null) {
                return params.data.nbFrames;
              }
            }
            return '';
          }
        },
        {
          headerName: this.userBundle['reshuffle.text.impression_alloc'] || 'Impressions(Allocated)',
          field: 'impressions',
          width: 150,
          cellRenderer: this.decimalCellRenderer
        },
        {
          headerName: this.userBundle['reshuffle.text.automationValue'] || 'Automation value',
          field: 'price',
          width: 130,
          cellRenderer: this.decimalCellRenderer
        }]
      },
      {
        headerName: this.userBundle['reshuffle.text.after'] || 'After',
        // @ts-ignore
        children: [{
          headerName: this.userBundle['reshuffle.text.objectivesStatusReshuffled'] || 'Status',
          field: 'objectivesStatusReshuffled',
          width: 60,
          filter: false,
          cellRendererFramework: AgResuffleTrafficLightCellRendererComponent,
          cellRendererParams: {
            noModification: true
          }
        },
        {
          headerName: this.userBundle['reshuffle.text.nbFramesReshuffled'] || 'Frames Reshuffled',
          field: 'nbFramesReshuffled',
          width: 125,
          cellRenderer: (params) => {
            if (params.data.isParent) {
              return params.data.nbFramesReshuffled;
            } else {
              if (params.data.isChildHeader) {
                return 'Frames';
              } else if (params.data.nbFramesReshuffled !== undefined && params.data.nbFramesReshuffled !== null && params.data.nbFramesReshuffledPercent !== undefined && params.data.nbFramesReshuffledPercent !== null) {
                return `${params.data.nbFramesReshuffled} (${params.data.nbFramesReshuffledPercent}%)`;
              } else if (params.data.nbFramesReshuffled !== undefined && params.data.nbFramesReshuffled !== null) {
                return params.data.nbFramesReshuffled;
              }
            }
            return '';
          }
        },
        {
          headerName: this.userBundle['reshuffle.text.impressionsReshuffled'] || 'Impression Reshuffled',
          field: 'impressionsReshuffled',
          width: 150,
          cellRenderer: this.decimalCellRenderer
        },
        {
          headerName: this.userBundle['reshuffle.text.priceReshuffled'] || 'Price Reshuffled',
          field: 'priceReshuffled',
          width: 130,
          cellRenderer: this.decimalCellRenderer
        }]
      },
      {
        headerName: this.userBundle['common.channelName'] || 'Channel',
        field: 'channel',
        width: 100,
      },
      {
        headerName: this.userBundle['common.title'] || 'Title',
        field: 'title',
        width: 150,
      },
      {
        headerName: this.userBundle['reshuffle.text.campaignGroup'] || 'Campaign Group',
        field: 'campaignGroup',
        width: 110,
      },
      {
        headerName: this.userBundle['reshuffle.text.campaignStatus'] || 'Campaign Status',
        field: 'campaignStatus',
        width: 110,
      },
      {
        headerName: this.userBundle['reshuffle.text.startDate'] || 'Start Date',
        field: 'startDate',
        width: 80,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          dateFormat: LocaleData.displayDateFormat
        }
      },
      {
        headerName: this.userBundle['reshuffle.text.endDate'] || 'End Date',
        field: 'endDate',
        width: 80,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          dateFormat: LocaleData.displayDateFormat
        }
      },
      {
        headerName: this.userBundle['reshuffle.text.advertiser'] || 'Advertiser',
        field: 'advertiser',
        width: 160,
      },
      {
        headerName: this.userBundle['reshuffle.text.brand'] || 'Brand',
        field: 'brand',
        width: 150,
      },
      {
        headerName: this.userBundle['reshuffle.text.salesPerson'] || 'Sales Person',
        field: 'salesPerson',
        width: 100,
      },
      {
        headerName: this.userBundle['reshuffle.text.adminPerson'] || 'Admin Person',
        field: 'adminPerson',
        width: 100,
      }
    ];

    this.applyColumnConfiguration(this.campaignColumnDefs);

    this.sortObj = new AgCustomSort([], [], [], this.groupingField, true);
  }

  applyColumnConfiguration(campaignColumnDefs) {
    for (const campaignColumnDef of campaignColumnDefs) {
      campaignColumnDef.floatingFilterComponentFramework = AgTextFloatingFilterComponent;
      campaignColumnDef.floatingFilterComponentParams = {
        value: '',
        suppressFilterButton: true,
        decimalRound: null
      };
      campaignColumnDef.filterParams = {
        newRowsAction: 'keep'
      };
      campaignColumnDef.filterFramework = AgCustomFilterMenuComponent;
      campaignColumnDef.headerComponentFramework = AgCustomHeaderComponent;

      campaignColumnDef.cellClass = (params): string[] => {
        const retArr: string[] = [];
        if (params.node.rowPinned === 'bottom') {
          retArr.push('fontBold', 'borderBottom');
        }
        if (params.data.isSelected) {
          retArr.push('selectedRow');
        }
        return retArr;
      };

      if (campaignColumnDef.children && campaignColumnDef.children.length > 0) {
        this.applyColumnConfiguration(campaignColumnDef.children);
      }
    }
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
  }

  decimalCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    let text = params.value;
    if (params.data.isChildHeader) {
      switch (params.column.colId) {
        case 'impressions':
        case 'impressionsReshuffled':
          text = 'Impressions';
          break;
        case 'price':
        case 'priceReshuffled':
          text = 'Automation Value';
          break;
      }
    } else {
      if (params.column.colId === 'nbFrames_alloc' ||
        params.column.colId === 'nbFrames_obj' ||
        params.column.colId === 'impression_alloc' ||
        params.column.colId === 'impression_obj'
      ) {
        text = this.decimalPipe.transform(text, '.0-0');
      } else {
        text = this.decimalPipe.transform(text, '.2-2');
      }
    }
    eDiv.innerHTML = text;
    return eDiv;
  }

  /**
   * download cart data in excel format
   */
  downloadExcel() {
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    // Add Header Row
    let sheetRow: ExcelCell[] = [];
    for (const campaignColumnDef of this.campaignColumnDefs) {
      const cell: ExcelCell = {
        text: campaignColumnDef.headerName,
        fontColor: '#FFFFFF',
        bgColor: '#000000',
        borderColor: '#CCCCCC',
        isFontBold: true
      };
      const col: ExcelColumn = {
        width: 12
      };
      excelColumn.push(col);
      sheetRow.push(cell);
    }
    sheetData.push(sheetRow);

    // Add Data rows
    let rowsInserted = 0;
    for (const row of this.rowData) {
      sheetRow = [];
      for (const campaignColumnDef of this.campaignColumnDefs) {
        let cellVal = row[campaignColumnDef.field];
        if (campaignColumnDef.field === 'startDate' || campaignColumnDef.field === 'endDate') {
          if (cellVal) {
            cellVal = this.datePipe.transform((new Date(cellVal)), LocaleData.displayDateFormat);
          }
        }
        const cell: ExcelCell = {
          text: cellVal,
          fontColor: '#000000',
          bgColor: rowsInserted % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
          borderColor: '#CCCCCC'
        };
        sheetRow.push(cell);
      }
      rowsInserted++;
      sheetData.push(sheetRow);
    }
    const cartText = 'reshuffle_detail';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }
  onModalClosed(event) {
    event.activeModal.close('cancel');
  }
  onModalSaved(event) {
    event.activeModal.close('save');
  }

  /**
   * This method will find out the parent row using grouping key
   * @param groupingId Grouping ID
   * @param data Table Data
   * @param groupingField Grouping Field
   * @returns parent row
   * @author Dhaval Patel
   */
  getParentRows(groupingId?: string, data?: any[], groupingField?: string): any[] {
    const newData = data || this.rowData;
    return AgCustomRowUtil.getParentRows(newData, groupingField, groupingId);
  }

  /**
   * This method will prepare row data for parent level
   * @author Dhaval Patel
   */
  prepareRowData() {
    if (this.rowData) {
      this.rowData.forEach((element) => {
        element.isParent = true;
        element.isVisible = true;
        element.isExpanded = false;
      });
    }
  }

  /**
   * This method will expand selected row
   * @param row rowData
   * @author Dhaval Patel
   */
  expandRow(row: any) {
    const childHeader: any = {
      tvAreaName: 'TV AREA',
      campaignId: row.campaignId,
      columnIndex: row.columnIndex,
      isChildHeader: true,
      isParent: false
    };
    if (row && !row.isExpanded && this.rowData && this.rowData.length) {
      const index = this.rowData.findIndex(item => item.campaignId === row.campaignId && item.columnIndex === row.columnIndex && item.isParent);
      this.rowData[index].isExpanded = true;
      if (this.rowData[index].tvAreaReshuffleSummary) {
        for (const key in this.rowData[index].tvAreaReshuffleSummary) {
          if (this.rowData[index].tvAreaReshuffleSummary.hasOwnProperty(key)) {
            const child = _.cloneDeep(this.rowData[index].tvAreaReshuffleSummary[key]);
            child.isParent = false;
            this.rowData.splice(index + 1, 0, child);
          }
        }
      }
      this.rowData.splice(index + 1, 0, childHeader);
      this.rowData = _.cloneDeep(this.rowData);
    }
  }

  /**
   * This method will collapse selected row
   * @param row rowData
   * @author Dhaval Patel
   */
  collapseRow(row: any) {
    if (row && row.isExpanded && this.rowData && this.rowData.length) {
      const index = this.rowData.findIndex(item => item.campaignId === row.campaignId && item.columnIndex === row.columnIndex && item.isParent);
      if (index !== -1 && this.rowData[index]) {
        this.rowData[index].isExpanded = false;
      }
      this.rowData = _.cloneDeep(this.rowData.filter(item => !(item.campaignId === row.campaignId && item.columnIndex === row.columnIndex && !item.isParent)));
    }
  }
}
