import { Component } from '@angular/core';
import { AgLinkCellRendererComponent } from '../ag-link-cell-renderer/ag-link-cell-renderer.component';

@Component({
  selector: 'app-ag-reshuffle-link-cell-renderer',
  templateUrl: './ag-reshuffle-link-cell-renderer.component.html'
})
export class AgReshuffleLinkCellRendererComponent extends AgLinkCellRendererComponent {

  public getParentRowText(): string {
    return this.params.data[this.params.column.colId];
  }

  expand() {
    if (this.params && typeof this.params.expand === 'function') {
      this.params.expand(this.params.data);
    }
  }

  collapse() {
    if (this.params && typeof this.params.collapse === 'function') {
      this.params.collapse(this.params.data);
    }
  }
}
