import {
  Component, OnInit, Output, Input,
  EventEmitter, OnDestroy, ChangeDetectorRef, AfterViewInit
} from '@angular/core';
import { DuplicationComponent } from './duplication';
import { ProductCategoryComponent } from './product-category';
import { ProximityProhibitionComponent } from './proximity-prohibition';
import { SearchService } from '../search.service';
import { SbModalPopupService } from '../../core/components/sb-modal-popup';
import { DataShareService, StateService, SharedService, LogHelperService } from '../../core/services';
import { LookupViewService } from './../../core/components/lookup-view/lookup-view.service';
import { Subscription } from 'rxjs';

import {
  Campaign,
  SystemFlags,
} from '../../models';
import * as _ from 'lodash';
import { CampaignDetailsService } from '../../commercial/campaign-details/campaign-details.service';
import { CampaignDetailsBase } from '../../commercial/campaign-details/campaign-details-base';
import { ThirdParty } from '../../core/enum';
import { GLOBAL } from '../../core/utils/app.constant';
import { GeoMapService } from '../../geo-map/geo-map.service';
import { AppHeaderService } from 'app/root/app-header/app-header.service';

@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.css']
})
export class CampaignDetailsComponent extends CampaignDetailsBase implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  // private routerSub: Subscription;

  // tslint:disable-next-line:variable-name
  private _isLoadedCampaign = false;
  proximityProhibitionGroup = [];
  userBundle = {};
  public userData;
  public campaignTypeGroup = [];
  public campaignType = [];

  private changedAdvertiser: boolean;

  public common = this.getInitialCommonFlags();

  public userModel: any;
  resetSpeciallistCampaignFlag: boolean = false;
  resetAgencyCampaignFlag: boolean = false;
  isConfigAvailable = false;
  customerId = null;
  disabledGeoPlannerButton = false;
  disabledSmartBricsButton = false;
  disabledVisualPlannerButton = false;

  /**
   * Property to restrict the service call for Agency
   *
   * @memberof CampaignDetailsBase
   */
  isAgencyServerCallRestricted: boolean;

  @Input() campaignDetails: Campaign = new Campaign();

  @Output() campaignChange = new EventEmitter();

  @Input() public set isLoadedCampaign(value: boolean) {
    this._isLoadedCampaign = value;
    SystemFlags.isLoadedCampaign = value;
    this.common.isDisabled = this.isLoadedCampaign && !SystemFlags.enableSearchFieldsForExternalUser;
  }

  isInvoiceLocked = false;

  @Output() emitOnSearch = new EventEmitter();

  public get isLoadedCampaign(): boolean {
    return this._isLoadedCampaign;
  }

  public get isFillerCampaign(): boolean {
    return SystemFlags.isFillerCampaign;
  }

  /**
   * Creates an instance of CampaignDetailsComponent.
   * @memberof CampaignDetailsComponent
   */
  constructor(
    private searchService: SearchService,
    dataShareService: DataShareService,
    public stateService: StateService,
    private sbModalPopupService: SbModalPopupService,
    lookupViewService: LookupViewService,
    public sharedService: SharedService,
    campaignDetailsService: CampaignDetailsService,
    private appHeaderService: AppHeaderService,
    private changeDetectorRef: ChangeDetectorRef,
    public geoMapService: GeoMapService,
    public logHelperService: LogHelperService
    // private router: Router
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      null,
      stateService,
      logHelperService
    );

  }

  /**
   * @description Returns initial flags to enable/disable/hide the fields
   * @author Shivani Patel
   * @returns
   * @memberof CampaignDetailsComponent
   */
  getInitialCommonFlags() {
    return {
      disableProductCategory: false,
      disableSpecialistBlacklist: true,
      disableAgencyBlacklist: true,
      disableProximityProhibition: true,
      disableSalesTeam: false,
      excludeCustomFormats: false,
      disablecampaignGroup: false,
      disableSpecialist: false,
      disableAgency: false,
      hideSalesTeam: false,
      isDisabled: false
    };
  }

  /**
   * @description Updates campaign values
   * @author Amit Mahida
   * @param {Campaign} value
   * @memberof CampaignDetailsComponent
   */
  updateCampaign(value: Campaign) {
    if (value) {
      if (!(value.brand && value.brand.brandId)) {
        this.resetBrandCampaignFlag = !this.resetAdvertiserCampaignFlag;
      }

      if (value.advertiser) {
        this.campaignDetails.excludeAdvertiserBlacklist =
          this.campaignDetails.advertiser && this.campaignDetails.advertiser.advertiserId > 0 ?
            this.campaignDetails.excludeAdvertiserBlacklist : true;
      } else {
        this.resetAdvertiserCampaignFlag = true;
      }

      if (this.campaignDetailsService.advertisersList.length > 0) {
        this.advertisersList = this.campaignDetailsService.advertisersList;
      }
      if (this.campaignDetailsService.brandsList && this.campaignDetailsService.brandsList.length > 0) {
        this.brandsList = this.campaignDetailsService.brandsList;
      }
      if (this.campaignDetailsService.productCategoryList.length > 0) {
        this.productCategoryList = this.campaignDetailsService.productCategoryList;
      }
      if (this.campaignDetailsService.specialistsList.length > 0) {
        this.specialistsList = this.campaignDetailsService.specialistsList;
      }
      if (this.campaignDetailsService.agenciesList.length > 0) {
        this.agenciesList = this.campaignDetailsService.agenciesList;
      }

      if (value.thirdParty) {
        if (value.thirdParty[ThirdParty.specialist]) {
          value.specialist = {
            specialistId: value.thirdParty[ThirdParty.specialist].thirdPartyId,
            specialistName: value.thirdParty[ThirdParty.specialist].thirdPartyName,
            invoiceStatusCode: value.thirdParty[ThirdParty.specialist].invoiceStatusCode
          };
        }
        if (value.thirdParty[ThirdParty.agency]) {
          value.agency = {
            agencyId: value.thirdParty[ThirdParty.agency].thirdPartyId,
            agencyName: value.thirdParty[ThirdParty.agency].thirdPartyName,
            invoiceStatusCode: value.thirdParty[ThirdParty.agency].invoiceStatusCode
          };
        }
      }

      if (value.specialist) {
        // case when we navigate from commercial page to search page, SM-4177
        this.campaignDetails.excludeSpecialistBlacklist = this.campaignDetails.specialist && this.campaignDetails.specialist.specialistId > 0 ?
          this.campaignDetails.excludeSpecialistBlacklist : true;
      }
      if (value.agency) {
        // case when we navigate from commercial page to search page, SM-4177
        this.campaignDetails.excludeAgencyBlacklist = this.campaignDetails.agency && this.campaignDetails.agency.agencyId > 0 ?
          this.campaignDetails.excludeAgencyBlacklist : true;
      }
      const responseArray = this.generateProximityprohabitionJSON();
      if (responseArray.length > 0) {
        this.common.disableProximityProhibition = false;
      }

      this.common.isDisabled = this.isLoadedCampaign && !SystemFlags.enableSearchFieldsForExternalUser;
      this.checkForInvoieLock();
    }
    this.setCustomerId();
  }

  /**
   * @description Performs actions on advertiser name selection
   * @author Amit Mahida
   * @param {*} value
   * @memberof CampaignDetailsComponent
   */
  onAdvertiserNameSelected(value: any): void {
    //   return e.advertiserId !== this.campaignDetails.advertiser.advertiserId;
    // });

    // SM-8819 reset duplication when advertiser changed, and only current selected advertiser should be there as duplication
    this.campaignDetails.duplicationAdvertisers = [];
    this.stateService.setSearchPageObject('duplicationData', this.campaignDetails.duplicationAdvertisers);
    super.onAdvertiserNameSelected(value);
    this.campaignDetails.excludeAdvertiserBlacklist = true;
    this.setCustomerId();
    if(this.uiControl.isBrandMandatory && this.campaignDetails.advertiser.advertiserName && !this.campaignDetails.brand.brandName){
      this.dataShareService.activateCommercialTab(false)
    } 
  }

  onBrandSelected(value) {
    super.onBrandSelected(value);
    this.setCustomerId();
  }

  /**
   * @description Performs actions on agency name selection
   * @author Amit Mahida
   * @param {*} value
   * @memberof CampaignDetailsComponent
   */
  onAgencyNameSelected(value: any): void {
    super.onAgencyChange(value);
    if (!_.isUndefined(this.campaignDetails.agency)) {
      this.campaignDetails.excludeAgencyBlacklist = true;
    }

  }

  /**
   * @description Performs actions on agency name selection
   * @author Dhaval Patel
   * @param {*} value
   * @memberof CampaignDetailsComponent
   */
  onSpecialistNameSelected(value: any): void {
    this.campaignDetails.excludeSpecialistBlacklist = true;
    super.onSpecialistChange(value);
  }

  /**
   * @description Performs actions on advertiser name removal
   * @author Amit Mahida
   * @memberof CampaignDetailsComponent
   */
  removeSelectedAdvertiserName(event): void {
    //   return e.advertiserId !== this.campaignDetails.advertiser.advertiserId;
    // });
    this.campaignDetails.duplicationAdvertisers = []; // SM-8819 reset duplication when advertiser removed
    this.stateService.setSearchPageObject('duplicationData', this.campaignDetails.duplicationAdvertisers);
    super.removeSelectedAdvertiserName(event, false, this.campaignDetails);
    this.campaignDetails.excludeAdvertiserBlacklist = true;
    this.setCustomerId();
  }

  removeSelectedBrand(event, campaignDetails) {
    super.removeSelectedBrand(event, false, campaignDetails);
    this.setCustomerId();
  }

  /**
   * @description Performs actions on specialist name removal
   * @author Amit Mahida
   * @memberof CampaignDetailsComponent
   */
  removeSelectedSpecialistName(): void {
    this.campaignDetails.excludeSpecialistBlacklist = true;
    super.onSpecialistChange(null);
  }

  /**
   * @description Performs actions on agency name removal
   * @author Amit Mahida
   * @memberof CampaignDetailsComponent
   */
  removeSelectedAgencyName(): void {
    super.onAgencyChange(null);
    this.campaignDetails.excludeAgencyBlacklist = true;
  }

  /**
   * @description Perfomrs actions on reset campaign
   * @author Amit Mahida
   * @param {*} makeServiceCall
   * @memberof CampaignDetailsComponent
   */
  resetCampaign(makeServiceCall: boolean): void {
    // based on value of makeServiceCall, if will make service call to backend
    this.campaignDetails = new Campaign();
    this.appHeaderService.changeforceRerunStatus(false);

    // Amit: Reset dropdown list of every chosen
    this.brandsList = [];
    this.advertisersList = [];
    this.specialistsList = [];
    this.agenciesList = [];
    this.campaignDetailsService.resetAllList();

    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);
    this.common = this.getInitialCommonFlags();
    this.isLoadedCampaign = false;
    SystemFlags.advertiserChange = false;
    SystemFlags.isPPCampaign = false;
    SystemFlags.isAPICampaign = false;
    SystemFlags.isFillerCampaign = false;
    this.isInvoiceLocked = false;
    // Reset the GM/VP Tabs//
    this.dataShareService.enableWorkspaceTab(true);
    this.dataShareService.activateVisualPlannerTab(true);
    this.dataShareService.activateGeoMapperTab(true);
    this.stateService.setSearchPageObject('duplicationData', {});
    this.stateService.setSearchPageObject('selectedText', {});
    // clear process request for GM/VP
    this.geoMapService.resetProcessRequest();
    if (this.uiControl.executeButtonGmVp) {
      this.geoMapService.allowProcessGm(false);
      this.geoMapService.allowProcessVp(false);
    }

    const refreshCampaign = this.searchService.resetCampaign(makeServiceCall, undefined, undefined, true);
    if (refreshCampaign) {
      refreshCampaign.then((data: any) => {
        if (data && data.status === 'OK') {
          this.restoreForExternalAccess();
        }
      });
    }
    this.setAdvertiserConfig();
    this.setBrandConfig();
    this.setAgencyConfig();
    this.setCustomerId();
    this.dataShareService.enableWorkspaceTab(true);
  }

  /**
   * @description Opens duplication modal popup
   * @author Amit Mahida
   * @memberof CampaignDetailsComponent
   */
  openDuplication(): void {
    const data = {
      SelectedValue: null,
      searchAdvertizer: null,
      changedAdvertiser: false
    };

    data.SelectedValue = _.cloneDeep(this.campaignDetails.duplicationAdvertisers);
    // }
    if (this.campaignDetails.advertiser.advertiserId > 0) {
      data.searchAdvertizer = {
        'advertiserId': this.campaignDetails.advertiser.advertiserId,
        'advertiserName': this.campaignDetails.advertiser.advertiserName,
        'auto': true
      };
    }
    data.changedAdvertiser = this.changedAdvertiser;
    this.sbModalPopupService.open(DuplicationComponent, { data }).result.then((result) => {
      if (result) {
        this.campaignDetails.duplicationAdvertisers = _.cloneDeep(result);
        this.stateService.setCampaign(this.campaignDetails);
      } else {
        this.campaignDetails.duplicationAdvertisers = null;
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  /**
   * @description Opens proximity prohibition modal popup
   * @author Amit Mahida
   * @memberof CampaignDetailsComponent
   */
  openProximityProhibition(): void {
    if (this.proximityProhibitionGroup && this.proximityProhibitionGroup.length) {

      let data: any;
      data = this.campaignDetails.proximityProhibition;
      this.sbModalPopupService.open(ProximityProhibitionComponent, { data }).result.then((result) => {
        if (result) {
          this.campaignDetails.proximityProhibition = _.cloneDeep(result);
          const responseProximityprohabitionJSON = this.generateProximityprohabitionJSON();

          if (responseProximityprohabitionJSON.length === 0) {
            this.campaignDetails.excludeProximityProhibition = false;
          } else {
            this.campaignDetails.excludeProximityProhibition = true;
            this.common.disableProximityProhibition = false;
          }
        }
      }, (reason) => {
        console.log(reason);
      });
    }
  }

  /**
   * @description Open product category modal popup
   * @author Amit Mahida
   * @memberof CampaignDetailsComponent
   */
  openProductCategory(): void {
    let data: any;
    data = this.campaignDetails.exclusivityMask ? this.campaignDetails.exclusivityMask : '1';
    this.sbModalPopupService.open(ProductCategoryComponent, { data }).result.then((result) => {
      this.campaignDetails.exclusivityMask = result;
    }, (reason) => {
      console.log(reason);
    });
  }

  /**
   * @description Set Campaing Type or Group Ids set
   * @author Kishan Patel
   * @memberof CampaignDetailsComponent
   */
  setCampaingTypeGroup() {
    // setTimeout(() => {
    if (this.campaignDetails && this.campaignDetails.campaignTypeGroupId !== -1 && this.campaignDetails.campaignTypeId !== -1) {
      this.loadCampaignGroupWithValues(this.campaignDetails.campaignTypeGroupId, this.campaignDetails.campaignTypeId);
    } else {
      this.loadDefaultCampaignGroup();
    }
    //   }, 10);
  }

  /**
   * @description Initialize campaign details component
   * @author Amit Mahida
   * @memberof CampaignDetailsComponent
   */
  ngOnInit() {
    this.subscriptions = [];
    this.campaignDetails = this.stateService.getCampaign();
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.systemData = this.dataShareService.getInitialConfigByKey('systemData');
    this.initCampaignDetails();
    this.setCampaingTypeGroup();
    this.restoreForExternalAccess();
    // changed position, as it was not storing initial state. SM-4427
    this.stateService.setInitialStateCampaign(_.cloneDeep(this.campaignDetails));
    this.updateCampaign(this.campaignDetails);
    // explicit subscription to todos counts
    this.subscriptions.push(this.searchService.campaignDetailsChanged.subscribe((campaign: Campaign) => {
      if (campaign) {
        if (SystemFlags.isCloneCampaign && SystemFlags.isRangeSelectionMandatory) {
          this.common.disablecampaignGroup = false;
        }
        this.campaignDetails = _.cloneDeep(campaign);
        this.updateCampaign(this.campaignDetails);
        this.campaignTypeGroup = this.filterCampaignTypeGroup();
        this.setCampaingTypeGroup();
      }
    }));
    this.stateService.setCampaign(_.cloneDeep(this.campaignDetails));

    // disableAgency and disableSpecialist are coming from CampaignDetails of Commercial
    this.common.disableAgency = this.campaignDetails.disableAgency;
    this.common.disableSpecialist = this.campaignDetails.disableSpecialist;
    this.subscriptions.push(this.dataShareService.languageChangedSub.subscribe((result) => {
      if (result) {
        this.userBundle = {};
        this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
        this.setLookupConfig();
        this.changeDetectorRef.detectChanges();
      }
    }));
    this.subscriptions.push(
      this.dataShareService.isEnableWorkspaceTab.subscribe((isTabActive) => {        
          this.disabledSmartBricsButton = !isTabActive;        
      })
    );

    this.subscriptions.push(
      this.dataShareService.isEnableGeoMapperTab.subscribe((isTabActive) => {
        if (this.uiControl['enableGeoMapper']) {
          this.disabledGeoPlannerButton = !isTabActive;
        } else {
          this.disabledGeoPlannerButton = true;
        }
      })
    );
    this.subscriptions.push(
      this.dataShareService.isEnableVisualPlannerTab.subscribe((isTabActive) => {
        if (this.uiControl['enableVisualPlanner']) {
          this.disabledVisualPlannerButton = !isTabActive;
        } else {
          this.disabledVisualPlannerButton = true;
        }
      })
    );
    let _campaignDetails = this.stateService.getCampaign();
    
    if (this.uiControl?.isBrandMandatory && (_campaignDetails.advertiser.advertiserId && !_campaignDetails.brand.brandId) ) {
      this.dataShareService.enableGeoMapperTab(false);
      this.dataShareService.enableVisualPlannerTab(false);
      this.dataShareService.enableWorkspaceTab(false);
    }
    this.checkForInvoieLock();
  }

  checkForInvoieLock() {
    if ((SystemFlags.readOnly && this.uiControl && this.uiControl.invoiceLockFeature)) {
      this.common.isDisabled = true;
      this.common.disableAgency = true;
      this.common.disableAgencyBlacklist = true;
      this.common.disableProductCategory = true;
      this.common.disableProximityProhibition = true;
      this.common.disableSalesTeam = true;
      this.common.disableSpecialist = true;
      this.common.disableSpecialistBlacklist = true;
      this.common.disablecampaignGroup = true;
      this.common.excludeCustomFormats = true;
      this.isInvoiceLocked = true;
    }
  }

  /**
   * @description Destroys subscriptions
   * @author Amit Mahida
   * @memberof CampaignDetailsComponent
   */
  ngOnDestroy() {
    this.subscriptions.forEach((element) => {
      element.unsubscribe();
    });
  }
  /**
   * @description Checks for the filler campaign
   * @author Shreni
   * @date 2020-04-01
   * @param {*} campaignType
   * @returns
   * @memberof CampaignDetailsComponent
   */
  checkIsFillerCampaign(campaignType) {
    if (campaignType.campaignTypeGroupCode === GLOBAL.campaignTypeGroupCode.GROUP_FILLER) {
      this.dataShareService.activateVisualPlannerTab(false);
      this.dataShareService.activateGeoMapperTab(false);
      return true;
    } else {
      this.dataShareService.activateVisualPlannerTab(true);
      this.dataShareService.activateGeoMapperTab(true);
      return false;
    }
  }

  initCampaignDetails(): void {
    this.proximityProhibitionGroup = this.dataShareService.getInitialConfigByKey('proximityProhibitionGroup');
    this.userModel = this.dataShareService.getUserModel();
    this.productCategoryList = this.campaignDetailsService.setProductCategoryList([]);
    const salesTeamList = this.dataShareService.getInitialConfigByKey('salesTeam');
    this.salesTeamList = Array.isArray(salesTeamList) ? salesTeamList.filter(item => item.inUse === true) : [];
    this.userData = this.dataShareService.getInitialConfigByKey('userData');

    this.agencyLookupUrl = this.dataShareService.getServiceCallUrlByKey('LOOKUP_AGENCY_URL');
    this.campaignTypeGroup = this.filterCampaignTypeGroup();
    this.campaignType = this.dataShareService.getInitialConfigByKey('campaignType');
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.setLookupConfig();

    this.isConfigAvailable = true;
    this.isAgencyServerCallRestricted = this.userData && this.userData.thirdParty && this.userData.thirdParty[ThirdParty.agency] ? true : false;
    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);
  }

  /**
   * @description To set the configuration for all lookup components
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setLookupConfig() {
    this.setAdvertiserConfig();
    this.setBrandConfig();
    this.setSpecialistConfig();
    this.setAgencyConfig();
  }

  /**
   * @description To set the configuration for advertiser lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setAdvertiserConfig() {
    super.setAdvertiserConfig();
    this.advertiserLookupConfig.displayInvoiceCode = false;
  }

  /**
   * @description To set the configuration for brand lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setBrandConfig() {
    super.setBrandConfig();
    this.brandLookupConfig.displayInvoiceCode = false;
  }

  /**
   * @description To set the configuration for brand lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setSpecialistConfig() {
    super.setSpecialistConfig();
    this.specialistLookupConfig.displayInvoiceCode = false;
    this.specialistLookupConfig.searchInItemsOnly = this.isAgencyServerCallRestricted;
  }

  /**
   * @description To set the configuration for agency lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setAgencyConfig() {
    super.setAgencyConfig();
    this.agencyLookupConfig.allowClear = !this.common.isDisabled && !this.common.disableAgency;
    this.agencyLookupConfig.displayInvoiceCode = false;
  }

  /**
   * @description Method to filter the campaign group based on the type of the user
   * @author Shreni Shah
   * @date 2020-01-31
   * @returns
   * @memberof CampaignDetailsComponent
   */
  filterCampaignTypeGroup() {
    let campaignTypeGroup = this.dataShareService.getInitialConfigByKey('campaignTypeGroup');
    if (campaignTypeGroup) {
      const externalExcesscampaignTypeGroupCode = GLOBAL.campaignTypeGroupCode.GROUP_EXTERNAL_ACCESS;
      if (this.userData && this.userData.externalUser && !SystemFlags.isLoadedCampaign) {
        // External User + Fresh Campaign => Allow only external access campaign type//
        campaignTypeGroup = campaignTypeGroup.filter(e => e.campaignTypeGroupCode === externalExcesscampaignTypeGroupCode);
      } else if (this.userData && !this.userData.externalUser && !SystemFlags.isLoadedCampaign) {
        // Normal User + Fresh Campaign => Allow all the campaignTypes  except the external access//
        campaignTypeGroup = campaignTypeGroup.filter(e => e.campaignTypeGroupCode !== externalExcesscampaignTypeGroupCode);
      } else {
        // Any User + Loaded Campaign => Allow all campaign types //
        campaignTypeGroup = campaignTypeGroup;
      }
      return campaignTypeGroup;
    }
  }

  /**
   *C proximity prohibition schema for request params
   * @author Amit Mahida
   * @returns Array<Object>[]
   * @memberof CampaignDetailsComponent
   */
  generateProximityprohabitionJSON(): Object[] {
    const responseArray = [];
    if (this.campaignDetails.proximityProhibition) {
      this.campaignDetails.proximityProhibition.forEach((groupObj) => {
        groupObj.proximityProhibitionType.forEach((prohibitionTypeObj) => {
          if (prohibitionTypeObj.distance != null && prohibitionTypeObj.distance !== 0) {
            let jsonObj = {
              prohibitionTypeId: prohibitionTypeObj.prohibitionTypeId,
              distance: prohibitionTypeObj.distance
            };
            responseArray.push(jsonObj);
          }
        });
      });
      return responseArray;
    }
  }

  /**
   * @description Redirect to workspace
   * @author Nishit Parekh
   * @param {boolean} stopredirectionFromHere
   * @param {string} pageName
   * @returns
   * @memberof CampaignDetailsComponent
   */
  goToWorkspace(pageName: string): void {
    this.campaignDetails.campaignTypeId = Number(this.campaignDetails.campaignTypeId);
    this.stateService.setCampaign(this.campaignDetails);
    this.searchService.redirectFromSearch(pageName, this.campaignDetails).then((pageName) => {
      this.emitOnSearch.emit(pageName);
    }).catch((err) => {
      console.log(err);
    });
  }

  /**
   * @description Campaign Group Change
   * @author Kishan Patel
   * @param {*}
   * @memberof CampaignDetailsComponent
   */
  onCampaignGroupChange() {
    if (this.campaignDetails.campaignTypeGroupId.toString() !== '-1') {
      if (this.campaignTypeGroup) {
        const campaignType = this.campaignTypeGroup.find(x => x.campaignTypeGroupId === Number(this.campaignDetails.campaignTypeGroupId));
        this.campaignType = campaignType.campaignType;
        if (this.campaignType.find(x => x.default)) {
          this.campaignDetails.campaignTypeId = this.campaignType.find(x => x.default).campaignTypeId;
        } else {
          this.campaignDetails.campaignTypeId = -1;
        }
        if (campaignType) {
          SystemFlags.isFillerCampaign = this.checkIsFillerCampaign(campaignType);
        }
      }
    }

  }
  loadCampaignGroupWithValues(campaignGroupId, campaignTypeId) {
    this.campaignDetails.campaignTypeGroupId = campaignGroupId;
    if (campaignGroupId != null && this.campaignTypeGroup) {
      const campaignType = this.campaignTypeGroup.find(x => x.campaignTypeGroupId === Number(campaignGroupId));
      this.campaignType = campaignType.campaignType;
      if (this.campaignType.find(x => x.campaignTypeId === Number(campaignTypeId))) {
        this.campaignDetails.campaignTypeId = this.campaignType.find(x => x.campaignTypeId === Number(campaignTypeId)).campaignTypeId;
      } else {
        this.campaignDetails.campaignTypeId = -1;
      }
      if (campaignType) {
        SystemFlags.isFillerCampaign = this.checkIsFillerCampaign(campaignType);
      }
    }
    this.common.disablecampaignGroup = true;
  }

  loadDefaultCampaignGroup() {
    if (this.campaignTypeGroup != null && this.campaignTypeGroup.length > 0) {
      const selection = this.campaignTypeGroup.find(x => x.default);
      const campaign = this.campaignDetails;
      campaign.campaignTypeGroupId = selection.campaignTypeGroupId;
      this.campaignType = selection.campaignType;
      if (this.campaignType.find(x => x.default)) {
        campaign.campaignTypeId = this.campaignType.find(x => x.default).campaignTypeId;
      }
      this.campaignDetails = campaign;
    }
  }

  hideUIElements(key) {
    return this.dataShareService.hideUIElements(key);
  }
  // Added code for external access, if any value for third party sent by backend in initial config, need to assign it to id fields
  // At the same time, these fields will be disabled with the value sent by backend
  // VJ: 14/11/2018, SB-1884
  restoreForExternalAccess() {
    if (this.dataShareService.hideUIElements('search.campaign.salesteam')) {
      this.common.hideSalesTeam = true;
    } else {
      this.common.hideSalesTeam = false;
    }
    if (this.userData.thirdParty) {
      if (this.userData.thirdParty[ThirdParty.specialist]) {
        this.campaignDetails.specialist = {
          specialistId: this.userData.thirdParty[ThirdParty.specialist].thirdPartyId,
          specialistName: this.userData.thirdParty[ThirdParty.specialist].thirdPartyName,
          invoiceStatusCode: this.userData.thirdParty[ThirdParty.specialist].invoiceStatusCode
        };
        this.common.disableSpecialist = true;
      }

      if (this.userData.thirdParty[ThirdParty.agency]) {
        if (this.uiControl.allowDefaultMediaAgency) {
          for (const thirdParty of this.userData.thirdParty[ThirdParty.agency]) {
            if (thirdParty.isDefault) {
              this.campaignDetails.agency = {
                agencyId: thirdParty.thirdPartyId,
                agencyName: thirdParty.thirdPartyName,
                invoiceStatusCode: thirdParty.invoiceStatusCode
              };
              break;
            }
          }
          if (this.userData.thirdParty[ThirdParty.agency].length === 1 && this.userData.thirdParty[ThirdParty.agency][0].isDefault) {
            this.common.disableAgency = true;
          }
        } else {
          if (!SystemFlags.isLoadedCampaign) {
            this.campaignDetails.agency = {
              agencyId: this.userData.thirdParty[ThirdParty.agency][0].thirdPartyId,
              agencyName: this.userData.thirdParty[ThirdParty.agency][0].thirdPartyName,
              invoiceStatusCode: this.userData.thirdParty[ThirdParty.agency][0].invoiceStatusCode
            };
          }
          if (this.userData.thirdParty[ThirdParty.agency].length === 1) {
            this.common.disableAgency = true;
          }
        }
      }
      // VJ: 14/11/2018, SB-1884 END
    }

    // below code for SM-2559
    if (this.userData.externalUser && this.userData.externalUserData) {
      if (this.userData.externalUserData.defaultSalesTeam) {
        this.campaignDetails.salesTeamId = this.userData.externalUserData.defaultSalesTeam.salesTeamId;
        this.common.disableSalesTeam = true;
      }
    }
  }

  setCustomerId() {
    if (this.campaignDetails && this.campaignDetails.advertiser && this.campaignDetails.advertiser.organisationTypeId === 1) {
      this.customerId = this.campaignDetails.advertiser.advertiserCode;
    } else {
      this.customerId = null;
    }
  }

}
