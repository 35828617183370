/* tslint:disable */
import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { SwapZoneService } from './swap-zone.service';
import { Observable , of } from 'rxjs';
import { Subscription } from 'rxjs';
import { ConcertinaDataService } from '../concertina/concertina-data.service';
import { RequestJsonService } from '../../../geo-map/request-json.service';
import { InitialConfigModel } from '../../../models/initial-config.model';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { SwapData, FrameGrouping, VUGrouping, Frame, Booking } from '../../../models';
import { SwapCampaign, SwapFrame, SwapBookingDetails } from '../../../models/vp-swap';
import { SbModalPopupService } from '../sb-modal-popup';
import { DigitalSwapComponent } from './digital-swap/digital-swap.component';
import * as moment from 'moment';
import * as _ from 'lodash';
import { GLOBAL } from '../../utils/app.constant';

@Component({
  selector: 'app-swap-zone',
  templateUrl: './swap-zone.component.html',
  styleUrls: ['./swap-zone.component.css']
})
export class SwapZoneComponent implements OnInit, OnDestroy {

  /**
   * @description Initial config data
   * @type {InitialConfigModel}
   * @memberof SwapZoneComponent
   */
  @Input() initialConfig: InitialConfigModel;

  /**
   * @description emits event on closing swap details sidebar
   * @type {EventEmitter<boolean>}
   * @memberof SwapZoneComponent
   */
  @Output() closeSwapZone: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * @description Toggles swap zone sidebar
   * @memberof SwapZoneComponent
   */
  openSidebar = false;

  /**
   * @description filtered frame list for individual swaps
   * @type {Frame[]}
   * @memberof SwapZoneComponent
   */
  frameList: VUGrouping[] = [];

  /**
   * @description show/hide update button in swap details sidebar
   * @type {boolean}
   * @memberof SwapZoneComponent
   */
  // showUpdateButton: boolean = false;

  /**
   * @description Width of side bar
   * @type {number}
   * @memberof SwapZoneComponent
   */
  swapZoneWidth = 400;

  /**
   * @description
   * @type {Subscription}
   * @memberof SwapZoneComponent
   */
  // swapDataSubscription: Subscription;

  /**
   * @description
   * @type {Subscription}
   * @memberof SwapZoneComponent
   */
  swapModeSubscription: Subscription;

  currentFocusOn = '';

  selectedSwapWiseGroups = {};

  displayDateFormat = GLOBAL.SWAP_DISPLAY_DATE_FORMAT;
  displayTimeFormat = GLOBAL.SWAP_DISPLAY_TIME_FORMAT;

  /**
   * Creates an instance of SwapZoneComponent.
   * @author Amit Mahida
   * @param {SwapZoneService} swapZoneService
   * @param {ConcertinaDataService} concertinaDataService
   * @param {RequestJsonService} requestJsonService
   * @param {LogHelperService} logHelperService
   * @memberof SwapZoneComponent
   */
  constructor(
    public swapZoneService: SwapZoneService,
    private concertinaDataService: ConcertinaDataService,
    private requestJsonService: RequestJsonService,
    private logHelperService: LogHelperService,
    private sbModalPopupService: SbModalPopupService
  ) { }

  ngOnInit() {
    this.swapModeSubscription = this.swapZoneService.swapMode$.subscribe((swapMode) => {
      this.openSidebar = swapMode;
    });
  }

  /**
   * @description toggle swap zone sidebar
   * @author Amit Mahida
   * @memberof SwapZoneComponent
   */
  toggleSideBar() {
    this.openSidebar = !this.openSidebar;
    this.closeSwapZone.emit(true);
  }

  ngOnDestroy() {
    this.swapModeSubscription.unsubscribe();
  }

  /**
   * @description Updates new frame id in a swap
   * @author Amit Mahida
   * @param {Frame} frame
   * @param {SwapData} swapData
   * @param {number} [frameIndex]
   * @memberof SwapZoneComponent
   */
  updateNewFrameId(frame: Frame, swapData: SwapData, frameIndex?: number) {
    // if (newVuOrMultiFrame === 'similarVu') {
    if (frame.visualUnitId) {
      const framesHavingSameVU = this.swapZoneService.framesList.filter(frameData => frameData.visualUnitId === frame.visualUnitId);
      swapData.frames[frameIndex].newFrame = [];
      const swapNotDoneFrames = swapData.frames.filter(vuFrame => !vuFrame.newFrame || (vuFrame.newFrame && !vuFrame.newFrame.length));
      if (framesHavingSameVU.length > swapNotDoneFrames.length) {
        this.logHelperService.logError(this.initialConfig.userBundle['vp.swap.error.frameCountDoesNotMatch'] || 'Frame count does not match with target visual unit');
      } else {
        for (const autoSwapFrame of swapData.frames) {
          const swapNotDone = !autoSwapFrame.newFrame || (autoSwapFrame.newFrame && !autoSwapFrame.newFrame.length);
          if (swapNotDone && framesHavingSameVU.length) {
            const swapAutoData = framesHavingSameVU[0];
            autoSwapFrame.newY = swapAutoData.y;
            autoSwapFrame.newVisualUnitId = swapAutoData.visualUnitId;
            autoSwapFrame.newFrame = [];

            swapAutoData['value'] = swapAutoData.frameId;
            swapAutoData['display'] = swapAutoData.frameCode;
            autoSwapFrame.newFrame[0] = swapAutoData;

            framesHavingSameVU.splice(0, 1);
          }
        }
      }
    } else {
      swapData.frames[frameIndex].newY = swapData.frames[frameIndex].newFrame[0].y;
    }
    this.swapZoneService.updateConcertinaOnSwap(swapData);

    if (this.swapZoneService.swaps.length === 0) {
      this.openSidebar = false;
    }
    this.swapZoneService.manageVisibilityOfUpdateButton();
  }

  /**
   * @description Reverts entire swap entity of a campaign
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @param {number} updateSwapIndex
   * @returns
   * @memberof SwapZoneComponent
   */
  revertSwap(swapData: SwapData, updateSwapIndex: number) {
    if (swapData.frames && swapData.frames.length) {
      let framesNotUsedInAnotherSwap = false;
      for (const frame of swapData.frames) {
        if (this.isFrameSelectedInSwapZone(frame as Frame, swapData)) {
          framesNotUsedInAnotherSwap = true;
          break;
        }
      }
      if (framesNotUsedInAnotherSwap) {
        this.logHelperService.logError(this.initialConfig.userBundle['vp.swap.revertSwapNotAllowed']);
        return;
      }
    }
    this.swapZoneService.revertSwap(swapData, updateSwapIndex);
  }

  /**
   * @description checks if frame is already selected in a swap
   * @author Amit Mahida
   * @param {Frame} frame
   * @param {SwapData} swapData
   * @param {SwapFrame} [targetFrame]
   * @returns {boolean}
   * @memberof SwapZoneComponent
   */
  isFrameSelectedInSwapZone(frame: Frame, swapData: SwapData, targetFrame?: SwapFrame): boolean {
    let isFrameSelected = false;
    const swapDoneSwaps = this.swapZoneService.swaps.filter(swap => swap.frames.filter(frameIn => frameIn && frameIn.newFrame).length);
    for (const swapDone of swapDoneSwaps) {
      const swapDoneFrames = swapDone.frames.filter(swapDoneFrame => swapDoneFrame.newY);
      if (swapDoneFrames.findIndex(f => f.newFrame[0] && f.newFrame[0].frameId === frame.frameId) > -1) {
        for (const campaignRef of swapDone.campaignReferences) {
          for (const targetCref of swapData.campaignReferences) {
            if (moment(campaignRef.startDate).isSame(targetCref.startDate) || moment(campaignRef.endDate).isSame(targetCref.endDate)
              || moment(campaignRef.startDate).isBetween(targetCref.startDate, targetCref.endDate)
              || moment(campaignRef.endDate).isBetween(targetCref.startDate, targetCref.endDate)) {
              // Frame is selected in time period
              if (targetFrame && targetFrame.isDigital) {
                isFrameSelected = this.isFrameAvailableWithTargetSOT(frame, targetFrame, swapData) ? false : true;
              } else {
                isFrameSelected = true;
              }
              if (isFrameSelected) {
                return isFrameSelected;
              }
            }
          }
        }
      }
    }
    return isFrameSelected;
  }

  /**
   * @description checks if frame is booked between the date range
   * @author Amit Mahida
   * @param {Frame} frame
   * @param {SwapFrame} targetFrame
   * @param {SwapData} swapData
   * @returns {boolean}
   * @memberof SwapZoneComponent
   */
  isFrameBookedInTimeline(frame: Frame, targetFrame: SwapFrame, swapData: SwapData): boolean {
    let isFrameBooked = false;
    frame.bookings.forEach((booking: Booking) => {
      if (Object.keys(booking).length > 0) {
        for (const campaignRef of swapData.campaignReferences) {
          if (moment(booking.startDate).isSame(campaignRef.startDate)
            || moment(booking.endDate).isSame(campaignRef.endDate)
            || moment(booking.startDate).isBetween(campaignRef.startDate, campaignRef.endDate)
            || moment(booking.endDate).isBetween(campaignRef.startDate, campaignRef.endDate)
            || moment(campaignRef.startDate).isBetween(booking.startDate, booking.endDate)
            || moment(campaignRef.endDate).isBetween(booking.startDate, booking.endDate)) {
            if (targetFrame.isDigital) {
              isFrameBooked = this.isFrameAvailableWithTargetSOT(frame, targetFrame, swapData) ? false : true;
            } else {
              isFrameBooked = true;
            }
            if (isFrameBooked) {
              return isFrameBooked;
            }
          }
        }
      }
    });
    return isFrameBooked;
  }

  /**
   * @description checks frame level conditions to be allowed in the target list of a swap
   * @author Amit Mahida
   * @param {Frame} frame
   * @param {SwapFrame} targetToFrame
   * @returns {boolean}
   * @memberof SwapZoneComponent
   */
  checkForFrameDetails(frame: Frame, targetToFrame: SwapFrame): boolean {
    return frame.frameId !== targetToFrame.frameId
      && ((targetToFrame.visualUnitId && frame.visualUnitId) ? targetToFrame.visualUnitId !== frame.visualUnitId : true) // Filter with visual unit id
      && ((targetToFrame.visualUnitId === undefined && frame.visualUnitId) ? (this.swapZoneService.framesListGroupByVU[frame.visualUnitId].length === 1) : true) // if frame to swap is not having any Vu id & target frame's vu has only ony frame
      && frame.productFormatId === targetToFrame.productFormatId; // Frame should be of similar product format
  }

  /**
   * @description Check if the digital frame has same swap booking
   * @author Amit Mahida
   * @param {string} y
   * @param {string} xKey
   * @param {SwapCampaign} cref
   * @returns {boolean}
   * @memberof SwapZoneComponent
   */
  isFrameHasSameBookingInSameTimeSlot(y: string, xKey: string, cref: SwapCampaign) {
    return this.concertinaDataService.originalConcertinaData[y].xValues[xKey].bookingDetails
      .findIndex(bd => bd.campaignReference === cref.campaignReference
        && bd.startDate === cref.startDate
        && bd.endDate === cref.endDate) > -1;
  }

  /**
   * @description Returns true if the frame is having available SOT greater than target SOT
   * @author Amit Mahida
   * @param {Frame} frameToCheck
   * @param {SwapFrame} targetFrame
   * @param {SwapData} swapData
   * @returns {boolean}
   * @memberof SwapZoneComponent
   */
  isFrameAvailableWithTargetSOT(frameToCheck: Frame, targetFrame: SwapFrame, swapData: SwapData): boolean {
    let frameAvailable = false;
    const xGroups = this.concertinaDataService.originalConcertinaData.groups.xGroups;
    for (const cref of swapData.campaignReferences) {
      const startXIndex = xGroups.indexOf(cref.xStart);
      const endXIndex = xGroups.indexOf(cref.xEnd);
      for (let index = startXIndex; index <= endXIndex; index++) {
        const xKey = xGroups[index];
        if (this.concertinaDataService.originalConcertinaData[frameToCheck.y].xValues[xKey].availableSOT >= targetFrame.targetSOT
          && !this.isFrameHasSameBookingInSameTimeSlot(frameToCheck.y, xKey, cref)) {
          frameAvailable = true;
        } else {
          frameAvailable = false;
          break;
        }
      }
    }
    return frameAvailable;
  }

  /**
   * @description filters frame list on selected swap
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @param {SwapFrame} targetFrame
   * @memberof SwapZoneComponent
   */
  filterFrameList(swapData: SwapData, targetFrame: SwapFrame) {
    if (this.currentFocusOn === `${swapData.id}-${targetFrame.frameId}`) {
      return;
    }
    this.currentFocusOn = `${swapData.id}-${targetFrame.frameId}`;
    this.frameList = [];
    let frameList: Frame[] = [];
    frameList = this.swapZoneService.framesList.filter((frame: Frame) => {
      return this.checkForFrameDetails(frame, targetFrame)
        && !this.isFrameBookedInTimeline(frame, targetFrame, swapData) // Frame is not booked in the same timeline of target swap
        && !this.isFrameSelectedInSwapZone(frame, swapData, targetFrame) // Frame is not used in another target frame in a swap
        && swapData.frames.findIndex(f => f.frameId === frame.frameId) === -1 // Frame is not included in same swapData
        && swapData.frames.findIndex(f => f.newFrame && f.newFrame[0] ? f.newFrame[0].frameId === frame.frameId : false) === -1; // Frame is not selected as target frame in same swapData
    });

    const groupedVU: object = _.groupBy(frameList, 'visualUnitId');
    const groupedFrameList: VUGrouping[] = [];
    if (groupedVU) {
      const vuArray = Object.keys(groupedVU);
      for (const key of vuArray) {
        if (groupedVU[key] && groupedVU[key].length) {
          if (key === 'undefined') {
            groupedFrameList.push({
              key,
              display: `${this.initialConfig.userBundle['vp.swap.nonVU']} (${groupedVU[key].length} ${this.initialConfig.userBundle['workspace.pcm.export.frames']})`,
              list: groupedVU[key]
            });
          } else {
            groupedFrameList.push({
              key,
              display: `${this.initialConfig.userBundle['vp.swap.vuID']} : ${key} (${groupedVU[key].length} ${this.initialConfig.userBundle['workspace.pcm.export.frames']})`,
              list: groupedVU[key]
            });
          }
        }
      }
    }
    this.frameList = _.clone(groupedFrameList);
  }

  /**
   * @description Filter the frames with grouping while searching
   * @author Dhaval Patel
   * @param {string} query
   * @returns {Observable<FrameGrouping[]>}
   * @memberof SwapZoneComponent
   */
  loadFrameList = (query: string): Observable<FrameGrouping[]> => {
    if (query === null) {
      return of([]);
    }
    let result: FrameGrouping[] = [];
    const frameList: VUGrouping[] = _.clone(this.frameList);
    if (frameList === null || !frameList.length) {
      return of([]);
    }
    frameList.forEach((groupedFrames: VUGrouping) => {
      if (groupedFrames && groupedFrames.list && groupedFrames.list.length) {
        const prepareFrames: FrameGrouping[] = [];
        groupedFrames.list.forEach((frame: FrameGrouping) => {
          if (frame.frameCode.toLowerCase().replace(' ', '').indexOf(query.toLowerCase().replace(' ', '')) !== -1) {
            prepareFrames.push(frame);
          }
        });
        if (prepareFrames && prepareFrames.length) {
          const parentFrame: FrameGrouping = this.makeParentFrameOption(groupedFrames, prepareFrames[0]);
          if (parentFrame !== null) {
            result.push(parentFrame);
          }
          result = result.concat(prepareFrames);
        }
      }
    });
    return of(result);
  }

  /**
   * @description To make visual unit as parent option in grouping
   * @author Dhaval Patel
   * @param {VUGrouping} groupedFrame
   * @param {FrameGrouping} firstFrame
   * @returns {FrameGrouping}
   * @memberof SwapZoneComponent
   */
  private makeParentFrameOption(groupedFrame: VUGrouping, firstFrame: FrameGrouping): FrameGrouping {
    if (groupedFrame !== null && firstFrame !== null) {
      const result = _.clone(firstFrame);
      result.display = groupedFrame.display;
      result.isParent = true;
      return result;
    }
    return null;
  }

  /**
   * @description Stop internal search in list
   * @author Dhaval Patel
   * @returns {boolean}
   * @memberof SwapZoneComponent
   */
  frameListMatchingFn = (): boolean => {
    // data is already filtered by back-end so it will always return true
    // This is needed as we dont want to show searched text in autocomplete and
    // in this case component automatically filter-out data whose text doesn't match search text
    return true;
  }

  /**
   * @description sends swap request to server
   * @author Amit Mahida
   * @memberof SwapZoneComponent
   */
  performSwap() {
    const params = this.swapZoneService.getSwapReqParams();
    this.concertinaDataService.performSwap(params).subscribe((res: any) => {
      if (res && res.status === 'OK') {
        if (this.requestJsonService.requestJsonData.length > 0) {
          this.requestJsonService.requestJsonDataChange();
        }
        this.swapZoneService.setSwapData(new SwapData());
        this.logHelperService.logSuccess(res.message);
      } else {
        this.logHelperService.logError(res.message);
      }
    }, (error) => {
      this.logHelperService.logError(error);
    });
  }

  /**
   * @description selected swap data to delete
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @memberof SwapZoneComponent
   */
  selectSwapToDelete(swapData: SwapData) {
    const swapDone = swapData.frames.length === swapData.frames.filter(frame => frame.newFrame && frame.newFrame.length).length;
    if (!swapDone) {
      setTimeout(() => {
        if (this.swapZoneService.isAllSwapsSelected()) {
          this.swapZoneService.allSwapsSelected = true;
        } else {
          this.swapZoneService.allSwapsSelected = false;
        }
      }, 150);
    }
  }

  /**
   * @description Returns min SOT booked in a digital booking group
   * @author Amit Mahida
   * @param {SwapCampaign[]} bookingDetails
   * @returns {number}
   * @memberof SwapZoneComponent
   */
  getMinSOT(bookingDetails: SwapCampaign[]): number {
    let min = bookingDetails[0].sot;
    for (const bookingDetail of bookingDetails) {
      if (bookingDetail.sot < min) {
        min = bookingDetail.sot;
      }
    }
    return min;
  }

  /**
   * @description Maintains selected booking details group
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @param {SwapBookingDetails} bookingDetailsGroup
   * @memberof SwapZoneComponent
   */
  manageSelectedGroups(swapData: SwapData, bookingDetailsGroup: SwapBookingDetails) {
    if (this.selectedSwapWiseGroups[swapData.id] && this.selectedSwapWiseGroups[swapData.id].indexOf(bookingDetailsGroup.groupId) > -1) {
      const gIndex = this.selectedSwapWiseGroups[swapData.id].indexOf(bookingDetailsGroup.groupId);
      this.selectedSwapWiseGroups[swapData.id][gIndex] = bookingDetailsGroup.groupId;
    } else {
      this.selectedSwapWiseGroups[swapData.id] = [];
      this.selectedSwapWiseGroups[swapData.id].push(bookingDetailsGroup.groupId);
    }
  }

  /**
   * @description Open modal popup to select a booking to swap from a booking group
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @param {SwapBookingDetails} bookingDetailsGroup
   * @param {boolean} [editMode=false]
   * @memberof SwapZoneComponent
   */
  openDigitalSwap(swapData: SwapData, bookingDetailsGroup: SwapBookingDetails, editMode = false) {
    const resolveObject = {
      editMode,
      bookingDetails: bookingDetailsGroup.bookingDetails,
      selectedSOT: swapData.selectedSOT
    };
    this.sbModalPopupService.open(DigitalSwapComponent, resolveObject, { backdrop: true }).result.then((selectedBookings: SwapCampaign[]) => {
      bookingDetailsGroup.selected = true;
      this.manageSelectedGroups(swapData, bookingDetailsGroup);
      swapData.selectedSOT = selectedBookings[0].sot;
      for (const selectedBooking of selectedBookings) {
        const gIndex = swapData.campaignReferences.findIndex(swap => swap.addedFromGroupId === bookingDetailsGroup.groupId);
        selectedBooking.addedFromGroupId = bookingDetailsGroup.groupId;
        if (gIndex > -1) {
          // Campaign ref was already added from group
          swapData.campaignReferences[gIndex] = selectedBooking;
        } else {
          swapData.campaignReferences.push(selectedBooking);
        }
      }
      let frameIndex = 0;
      let framesToRemove = [];
      let objFramesToRemove = [];
      for (const swapFrame of swapData.frames) {
        let ifCampaignRefExist = false;
        if (!swapFrame.campaignReference) {
          swapFrame.campaignReference = [];
          swapFrame.campaignReference.push(selectedBookings[0].campaignReference);
        } else {
          swapFrame.campaignReference.forEach(campaignRef => {
            if (campaignRef === selectedBookings[0].campaignReference) {
              ifCampaignRefExist = true;
            }
          });
          if(!ifCampaignRefExist) {
            framesToRemove.push(frameIndex);
            objFramesToRemove.push(swapFrame);
          } else {
            objFramesToRemove.push(undefined);
          }
        }
        swapFrame.targetSOT = selectedBookings[0].sot;
        frameIndex++;
      }
      const remainingFrame = _.difference(swapData.frames, objFramesToRemove);
      swapData.frames = remainingFrame;
      framesToRemove.forEach((index) => {
        if (objFramesToRemove[index]) {
          const yKey = objFramesToRemove[index].newY ? objFramesToRemove[index].newY : objFramesToRemove[index].y;
          const startXIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(swapData.xStart);
          const endXIndex = this.concertinaDataService.originalConcertinaData.groups.xGroups.indexOf(swapData.xEnd);
          if (startXIndex > -1 && endXIndex > -1) {
            for (let index = startXIndex; index <= endXIndex; index++) {
              const xKey = this.concertinaDataService.originalConcertinaData.groups.xGroups[index];
              const bookingsToRevert = swapData.bookingDetailsColumnWise[xKey].filter(bookingDetail => bookingDetail.campaignReference !== selectedBookings[0].campaignReference);
              this.concertinaDataService.updateConcertinaDataAfterFrameRevert(yKey, xKey, bookingsToRevert);
            }
            this.swapZoneService.updateFrameRevertOnSwap(true);
          }
        }
      });
    }, (reason) => {
      console.log(reason);
    });
  }
  
  /**
   * @description Open modal popup to select different booking to swap from a booking group
   * @author Amit Mahida
   * @param {SwapData} swapData
   * @param {string} bookingDetailsGroupId
   * @returns
   * @memberof SwapZoneComponent
   */
  editDigitalSwap(swapData: SwapData, bookingDetailsGroupId: string) {
    const targetFrameSelected = swapData.frames.filter(f => f.newFrame && f.newFrame.length);
    if (targetFrameSelected.length) {
      this.logHelperService.logInfo(this.initialConfig.userBundle['vp.swap.removeAllTargetFrames'] || 'Please remove all target frames to select different campaign');
      return;
    }
    const bookingDetailsGroup = swapData.bookingDetailGroups.find(g => g.groupId === bookingDetailsGroupId);
    this.openDigitalSwap(swapData, bookingDetailsGroup, true);
  }

  /**
   * @description Returns minimum start date from the booking list
   * @author Amit Mahida
   * @param {SwapCampaign[]} bookings
   * @returns {string}
   * @memberof SwapZoneComponent
   */
  getMinStartDate(bookings: SwapCampaign[]): string {
    let minDate = bookings && bookings.length ? bookings[0].startDate : '';
    for (const booking of bookings) {
      if (booking.startDate < minDate) {
        minDate = booking.startDate;
      }
    }
    return minDate;
  }

  /**
   * @description Returns minimum end date from the booking list
   * @author Amit Mahida
   * @param {SwapCampaign[]} bookings
   * @returns {string}
   * @memberof SwapZoneComponent
   */
  getMaxEndDate(bookings: SwapCampaign[]): string {
    let maxDate = '';
    for (const booking of bookings) {
      if (booking.endDate > maxDate) {
        maxDate = booking.endDate;
      }
    }
    return maxDate;
  }

  /**
   * @description Returns true if SOT matches with the target selectedSOT
   * @author Amit Mahida
   * @param {SwapBookingDetails} group
   * @param {SwapData} swapData
   * @returns {boolean}
   * @memberof SwapZoneComponent
   */
  sotMatching(group: SwapBookingDetails, swapData: SwapData): boolean {
    if (swapData.selectedSOT) {
      return group.bookingDetails.filter(b => b.sot === swapData.selectedSOT).length > 0;
    }
    return true;
  }

  trackByItem(index, item) {
    return item;
  }

  trackByFrame(index, frame) {
    return frame?.frameId;
  }

  trackById(index, swap) {
    return swap?.id;
  }

}
