import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { GridOptions, ColDef } from 'ag-grid-community';
import { ExcelCell, ExportToExcel, ExcelColumn } from '../../../core/utils/exportToExcel';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { DataShareService } from '../../../core/services/data-share.service';
import {
  AgCheckboxComponent,
  AgCustomFilterMenuComponent,
  AgCustomHeaderComponent,
  AgTextCellEditorComponent,
  AgSelectCellEditorComponent,
  AgNumberCellRendererComponent,
  AgCurrencyCellRendererComponent,
  AgSelectCellRendererComponent,
  AgTextCellRendererComponent,
  AgPercentageCellRendererComponent,
  AgCustomSort,
  AgCustomRowUtil,
  AgTextFloatingFilterComponent,
  AgSelectFloatingFilterComponent,
  AgLsCellRendererComponent
} from '../ag-grid-support/index';
import * as _ from 'lodash';
import { LocaleData } from '../../../core/utils/LocaleData';
import { AgLockComponent } from '../ag-grid-support/ag-lock/ag-lock.component';
import { CommercialService, StateService } from '../../../core/services';
import { SystemFlags } from '../../../models';

export const defaultDiscountData = {
  seasonnalityIndex: 100,
  discount: 0,
  smartContentValue: 0,
  statusCategoryId: 1
};

@Component({
  selector: 'app-discount-grid-belgium',
  templateUrl: './discount-grid-belgium.component.html',
  styleUrls: ['../ag-grid-custom-style.css', '../ag-grid-col-select-style.css', './discount-grid-belgium.component.css'],
  host: {
    '(document:click)': 'onDocumentClick($event)'
  }
})
export class DiscountGridBelgiumComponent implements OnInit {

  // @Input() config: any;
  @Input() campaignDetails;

  /**
   * This is currently used only for Seasonality Index value change
   * In future if required then same can be used for diff purpose
   * This will return all the rows of discount grid
   */
  @Output() discountGridChanged: EventEmitter<any> = new EventEmitter();

  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  sortObj: AgCustomSort;

  /**
   * Ag-Grid Options Object
   */
  gridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  columnDefs: ColDef[];

  /**
   * column list to show in show/hide column dropdown
   */
  columnList: any = [];

  /**
   * list of Cart Data
   * @memberof CartDetailComponent
   */
  rowData: any[] = [];

  /**
   * Grid footer data
   */
  footerRowData: any[];

  /**
   * user bundle data
   */
  userBundle: any = {};
  userData: any = {};

  statusCategory = [];
  indexedStatusCategory = [];

  numericFields = [];
  dateRangeFields = [];
  selectFields = [];
  groupingField: string = null;

  isDiscountGridEditable = false;

  public precisionOnPercentage = 7;
  public precisionOnPercentageDisplay = 2;

  constructor(
    private dataShareService: DataShareService,
    private toaster: LogHelperService,
    private stateService: StateService,
    private commercialService: CommercialService
  ) {
  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.userData = this.dataShareService.getInitialConfigByKey('userData');
    this.setArrayLists();
    this.setFooterRows();
    this.prepareRowData();
    // SM-8081 : If discountsV2 is exist then we need to display v2 grid otherwise old grid.
    if (this.isDiscountV2) {
      this.prepareColDefV2();
    } else {
      this.prepareColDef();
    }
    this.reset();
    // we pass an empty gridOptions in, so we can grab the api out
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        componentParent: this
      },
      onRowDataChanged: (event) => {
        event.api.setPinnedBottomRowData(this.footerRowData);
      },
      scrollbarWidth: 10,
      onGridReady: this.onGridReady,
      getRowHeight: this.getRowHeight,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      suppressScrollOnNewData: true,
      floatingFilter: true,
      onFilterChanged: this.onFilterChanged,
      groupingField: this.groupingField,
      onCellEditingStopped: this.onCellEditingStopped,
      // SM-8081 : Discount grid's columns should be movable for V2
      suppressMovableColumns: this.isDiscountV2,
      isExternalFilterPresent: () => true,
      // tslint:disable-next-line:ter-arrow-parens
      doesExternalFilterPass: (node) => node.data.isVisible
    } as GridOptions;
  }

  get isDiscountV2() {
    if (this.campaignDetails && this.campaignDetails.discountsV2 && this.campaignDetails.discountsV2.length) {
      return true;
    }
    return false;
  }

  setArrayLists() {
    this.setStatusCategory();
  }

  setStatusCategory() {
    this.statusCategory = this.campaignDetails.statusCategory;
    this.indexedStatusCategory = [];

    if (this.statusCategory && _.isArray(this.statusCategory)) {
      for (const sc of this.statusCategory) {
        const object = sc;
        this.indexedStatusCategory[object.idStatusCategory] = sc;
      }
    }
  }

  onGridReady = () => {
    // set grid readonly if campaign is readonly
    this.manageReadOnly();

    if (!this.dataShareService.userModel.commercialTabAccess.dgLockAccess) {
      this.gridOptions.columnApi.setColumnVisible('locked', false);
    }
    this.columnList = this.gridOptions.columnApi.getAllColumns();
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else if (params.data.isVisible) {
      height = 30;
    }
    return height;
  }

  isFullWidthCell = (rowNode): boolean => {
    return rowNode.data.gridSummary;
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }

  /**
   * @description Calculates media cost on given SB value and seassonal index
   * @memberof DiscountGridBelgiumComponent
   */
  calculateMediaCost = (smartBricsValue: number, seasonnalityIndex: number) => {
    const mediaCost = smartBricsValue * (seasonnalityIndex / 100);
    return mediaCost.toFixed(2);
  }

  onCellEditingStopped = (event) => {
    if (event.rowPinned !== 'bottom') {
      switch (event.column.colId) {
        case 'discount':
          event.data.net = this.calculateNetWithoutTax(event.data.grossValue, event.data.discount);
          break;
        case 'net':
          event.data.discount = this.calculateDiscount(event.data.grossValue, event.data.net);
          break;
        case 'grossValue':
          event.data.net = this.calculateNetWithoutTax(event.data.grossValue, event.data.discount);
          break;
        case 'seasonnalityIndex':
          if (event.data.seasonnalityIndex < 0) {
            event.data.seasonnalityIndex = 0;
          }
          event.data.grossValue = this.calculateMediaCost(event.data.smartBricsValue, event.data.seasonnalityIndex);
          event.data.net = this.calculateNetWithoutTax(event.data.grossValue, event.data.discount);
          break;
        case 'technicalFee':
          if (event.data.technicalFee) {
            event.data.technicalFeeCalc = LocaleData.replaceLocalDecimalCharWithDot(event.data.technicalFee);
          }
          break;
        default:
          break;
      }
      this.rowData = _.clone(this.rowData);
    }
  }

  /**
   * @description calculation will be done when seasonnality index changed (Discount grid V2)
   * @author Dhaval Patel
   * @param event event of discount grid cell
   */
  updateGrossAndNet(event) {
    if (event.node && event.node.rowPinned !== 'bottom') {
      event.data.grossValue = this.calculateMediaCost(event.data.smartBricsValue, event.data.seasonnalityIndex);
      event.data.net = Number(this.calculateNetWithoutTax(event.data.grossValue, event.data.discount));
      this.rowData = _.clone(this.rowData);
    }
  }

  /**
   * @description calculation will be done when technical fee value changed (Discount grid V2)
   * @author Dhaval Patel
   * @param event event of discount grid cell
   */
  technicalFeeUpdatedLS(event) {
    event.data.technicalFeeCalc = LocaleData.replaceLocalDecimalCharWithDot(event.data.technicalFee);
    this.rowData = _.clone(this.rowData);
  }

  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen() && $event.button !== 2 && !dropdownEle._isEventFromToggle($event)
      && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
      dropdownEle.close();
    }
  }

  prepareRowData() {
    this.rowData = [];
    const discountProperty = this.getDiscountProperty();
    for (const campaignDetail of this.campaignDetails[discountProperty]) {
      const curRow: any = campaignDetail;
      curRow.isFilterPass = true;
      curRow.isParent = false;
      curRow.isSelected = false;
      curRow.isVisible = true;
      this.rowData.push(curRow);
    }
    const lockedDiscounts = this.stateService.getCommercialObject('lockedDiscounts');
    for (const key in lockedDiscounts) {
      if (lockedDiscounts.hasOwnProperty(key) && this.rowData[key]) {
        const curRow = lockedDiscounts[key];
        this.rowData[key] = { ...this.rowData[key], ...curRow };
      }
    }
    this.stateService.setCommercialObject('lockedDiscounts', {});

  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinRowIndex: 1 },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
  }

  getGridSummary() {
    return `<span>${this.userBundle['discount.common.totalFrames']}: ${this.getChildRows().length} &#45;
    ${this.userBundle['discount.common.selectedFrames']}: ${this.getSelectedChildRows().length}</span>`;
  }

  moneyIconCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {
      const eImgMoney: HTMLImageElement = document.createElement('img');
      eImgMoney.src = 'images/general/money_icon.png';
      eImgMoney.title = 'Money';
      eImgMoney.classList.add('money_icon');
      eImgMoney.onclick = this.money;
      eDiv.appendChild(eImgMoney);
    }
    return eDiv;
  }

  weekPinnedRowCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {
      // apply Icon
      const eImgApply: HTMLImageElement = document.createElement('img');
      eImgApply.src = 'images/general/apply.png';
      eImgApply.title = 'Apply';
      eImgApply.onclick = this.apply;
      eDiv.appendChild(eImgApply);

      // spread Icon
      const eImgSpread: HTMLImageElement = document.createElement('img');
      eImgSpread.src = 'images/general/spread.png';
      eImgSpread.title = 'Spread';
      eImgSpread.onclick = this.spread;
      eDiv.appendChild(eImgSpread);

      // reset Icon
      const eImgReset: HTMLImageElement = document.createElement('img');
      eImgReset.src = 'images/general/reset.png';
      eImgReset.title = 'Reset';
      eImgReset.onclick = this.reset;
      eDiv.appendChild(eImgReset);
    }
    return eDiv;
  }

  formatNumberCellValue = (value) => {
    let val = value;
    if (val === 0 || (val && val !== '')) {
      val = parseFloat(val);
      val = val.toFixed(this.precisionOnPercentageDisplay);
    }
    return val;
  }

  // tslint:disable-next-line: no-big-function   // tslint:disable-next-line: cognitive-complexity
  /**
   * @description It is used for editable grid
   * @author Dhaval Patel
   * @returns boolean is editable or not
   */
  isEditableCell = () => {
    return true;
  }

  prepareColDef() {
    this.columnDefs = [
      {
        headerName: '', width: 30, editable: false,
        pinned: 'left', filter: false, colId: 'selectChk',
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: (params): string[] => {
          const retArr: string[] = [];
          if (params.data.isSelected) {
            retArr.push('selectedRow');
          }
          if (params.node.rowPinned === 'bottom') {
            retArr.push('borderBottom');
          }
          return retArr;
        }
      },
      {
        headerName: '', width: 30, editable: false,
        pinned: 'left', filter: false, field: 'locked',
        cellRendererFramework: AgLockComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        cellRendererParams: {
          handleLock: (isLocked, rowIndex) => {
            if (!isLocked) {
              this.setDefaultDiscountOnUnlock(rowIndex);
            }
            this.reset();
          }
        }
      },
      {
        headerName: this.userBundle['discount.belgium.networkName'], field: 'networkName', width: 100, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.weekPinnedRowCellRenderer
      },
      {
        headerName: this.userBundle['discount.belgium.daterange'], field: 'week', width: 150, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer,
      },
      {
        headerName: this.userBundle['discount.belgium.numberFrames'], field: 'numberFrames', width: 100, editable: false,
        cellRendererFramework: AgNumberCellRendererComponent,
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.belgium.channelName'], field: 'channelName', width: 150, editable: false, pinned: false,
        cellRendererFramework: AgTextCellRendererComponent
      },
      {
        headerName: this.userBundle['discount.belgium.businessArea'], field: 'businessAreaName', width: 150, editable: false, pinned: false,
        cellRendererFramework: AgTextCellRendererComponent
      },
      {
        headerName: this.userBundle['discount.belgium.smartBricsValue'] ? this.userBundle['discount.belgium.smartBricsValue'] :
          'Smart Brics Value', field: 'smartBricsValue', width: 150, editable: false,
        cellRendererFramework: AgNumberCellRendererComponent
      },
      {
        headerName: this.userBundle['discount.belgium.seasonnalityIndex'], field: 'seasonnalityIndex', width: 150, editable: true,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          }
        },
        pinnedRowCellRenderer: this.blankCellRenderer,
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: (data) => {
            return data.pinRowIndex !== 1;
          }
        }
      },
      {
        headerName: this.userBundle['discount.belgium.mediaCost'], field: 'grossValue', width: 150, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          prefixText: (params) => {
            if (params.grossValue === 0 || (params.grossValue && params.grossValue !== '')) {
              return '';
            } else {
              return this.userBundle['discount.common.total'];
            }
          },
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          }
        }
      },
      {
        headerName: this.userBundle['discount.belgium.discount'], field: 'discount', width: 150, editable: true,
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          },
          calcCellValue: this.calcAvgDiscountPinnedRow,
          gridSumRow: 1
        },
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          },
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.belgium.netwithouttaxes'], field: 'net', width: 150, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          prefixText: (params) => {
            if (params.net === 0 || (params.net && params.net !== '')) {
              return '';
            } else {
              return this.userBundle['discount.common.total'];
            }
          },
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          }
        }
      },
      {
        headerName: this.userBundle['discount.belgium.statusCategory'],
        field: 'statusCategoryId', width: 150, editable: true, pinned: false,
        cellRendererFramework: AgSelectCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          valKey: 'idStatusCategory',
          textKey: 'statusCategoryName',
          dataArr: () => {
            return this.statusCategory;
          }
        },
        pinnedRowCellRendererFramework: AgSelectCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          valKey: 'idStatusCategory',
          textKey: 'statusCategoryName',
          dataArr: () => {
            return this.statusCategory;
          }
        },
        cellEditorFramework: AgSelectCellEditorComponent,
        cellEditorParams: {
          valKey: 'idStatusCategory',
          textKey: 'statusCategoryName',
          dataArr: () => {
            return this.statusCategory;
          },
          cellEditable: () => {
            return true;
          }
        }
      },
      {
        headerName: this.userBundle['discount.belgium.technicalFee'], field: 'technicalFee', width: 150, editable: true,
        pinnedRowCellRendererFramework: AgNumberCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          calcCellValue: (rows, colId) => {
            if (this.footerRowData[0].technicalFee === 0 ||
              (this.footerRowData[0].technicalFee && this.footerRowData[0].technicalFee !== '')) {
              return this.footerRowData[0].technicalFee;
            } else {
              const sum = AgCustomRowUtil.sumFieldValues(rows, colId);
              return String(sum);
            }
          },
          formatCellValue: this.formatNumberCellValue
        },
        cellRendererFramework: AgNumberCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          formatCellValue: this.formatNumberCellValue
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          }
        }
      },
      {
        headerName: this.userBundle['discount.belgium.smartContentValue'], field: 'smartContentValue', width: 100, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          }
        }
      },
      {
        headerName: this.userBundle['discount.belgium.uploadCostValue'], field: 'uploadCostValue', width: 100, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: () => {
            return true;
          }
        }
      }
    ];

    this.numericFields = [
      'numberFrames',
      'smartBricsValue',
      'seasonnalityIndex',
      'grossValue',
      'discount',
      'net',
      'technicalFee',
      'smartContentValue',
      'uploadCostValue'
    ];

    this.dateRangeFields = ['week'];
    this.selectFields = ['statusCategoryId'];
    this.processColDefs();

    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField, true);
  }

  processColDefs() {
    const decimalFieldsForFilter = [
      'smartBricsValue',
      'seasonnalityIndex',
      'grossValue',
      'discount',
      'net',
      'technicalFee',
      'smartContentValue',
      'uploadCostValue'
    ];
    for (let i = 1; i < this.columnDefs.length; i++) {
      if (this.columnDefs[i].field === 'statusCategoryId') {
        this.columnDefs[i].floatingFilterComponentFramework = AgSelectFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          suppressFilterButton: true,
          valKey: 'idStatusCategory',
          textKey: 'statusCategoryName',
          dataArr: () => {
            return this.statusCategory;
          },
          value: ''
        };
      } else {
        this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          value: '',
          suppressFilterButton: true,
          decimalRound: decimalFieldsForFilter.indexOf(this.columnDefs[i].field) > -1 ? 2 : null
        };
      }

      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };
      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => this.getCellClass(params);
    }
  }

  getCellClass(params) {
    const retArr: string[] = [];
    if (params.data.isParent || params.node.rowPinned === 'bottom') {
      retArr.push('fontBold');
    }
    if (params.node.rowPinned === 'bottom') {
      retArr.push('borderBottom');
    }
    if (params.data.isSelected) {
      retArr.push('selectedRow');
    }
    return retArr;
  }

  /**
   * @description Prepare column configuration for dicount grid V2
   * @author Dhaval Patel
   */
  prepareColDefV2() {
    this.columnDefs = [
      {
        headerName: '', width: 30, editable: false,
        pinned: 'left', filter: false, colId: 'selectChk',
        lockPosition: true,
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: (params): string[] => {
          const retArr: string[] = [];
          if (params.data.isSelected) {
            retArr.push('selectedRow');
          }
          if (params.node.rowPinned === 'bottom') {
            retArr.push('borderBottom');
          }
          return retArr;
        }
      },
      {
        headerName: '', width: 30, editable: false,
        pinned: 'left', filter: false, field: 'locked',
        lockPosition: true,
        cellRendererFramework: AgLockComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        cellRendererParams: {
          handleLock: (isLocked, rowIndex) => {
            if (!isLocked) {
              this.setDefaultDiscountOnUnlock(rowIndex);
            }
            this.reset();
          }
        }
      },
      {
        headerName: this.userBundle['discount.belgium.product'], field: 'productName', width: 150, editable: false, pinned: true,
        lockPosition: true,
        pinnedRowCellRenderer: this.weekPinnedRowCellRenderer,
      },
      {
        headerName: this.userBundle['discount.belgium.daterange'], field: 'week', width: 150, editable: false, pinned: true,
        lockPosition: true,
        pinnedRowCellRenderer: this.moneyIconCellRenderer,
      },
      {
        headerName: this.userBundle['discount.belgium.networkName'], field: 'networkName', width: 100, editable: false, pinned: true,
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.belgium.channelName'], field: 'channelName', width: 150, editable: false, pinned: false,
        cellRendererFramework: AgTextCellRendererComponent
      },
      {
        headerName: this.userBundle['discount.belgium.businessArea'], field: 'businessAreaName', width: 150, editable: false, pinned: false,
        cellRendererFramework: AgTextCellRendererComponent
      },
      {
        headerName: this.userBundle['discount.belgium.media'], field: 'mediaName', width: 150, editable: false, pinned: false,
        cellRendererFramework: AgTextCellRendererComponent
      },
      {
        headerName: this.userBundle['discount.belgium.marketingName'], field: 'marketingName', width: 150, editable: false, pinned: false,
        cellRendererFramework: AgTextCellRendererComponent
      },
      {
        headerName: this.userBundle['discount.belgium.numberFrames'], field: 'numberFrames', width: 100, editable: false,
        cellRendererFramework: AgLsCellRendererComponent,
        cellRendererParams: {
          key: 'numberFrames',
          workspaceKey: 'workspaceNumberFrames',
          displayType: 'Number',
          undoKey: 'numberFramesUndo',
          backupKey: 'numberFramesBackup',
        },
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.belgium.automationValue'] ? this.userBundle['discount.belgium.automationValue'] :
          'Automation Value', field: 'smartBricsValue', width: 150, editable: false,
        cellRendererFramework: AgLsCellRendererComponent,
        onCellValueChanged: (params) => {
          this.updateGrossAndNet(params);
        },
        cellRendererParams: {
          key: 'smartBricsValue',
          workspaceKey: 'workspaceSmartBricsValue',
          displayType: 'Currency',
          undoKey: 'smartBricsValueUndo',
          backupKey: 'smartBricsValueBackup'
        },
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.belgium.seasonnalityIndex'], field: 'seasonnalityIndex', width: 150, editable: true,
        cellRendererFramework: AgLsCellRendererComponent,
        onCellValueChanged: (params) => {
          if (params && params.data &&
            params.data.seasonnalityIndex !== params.data.workspaceSeasonalityIndex) {
            params.data.seasonnalityIndexUndo = false;
          }
          this.updateGrossAndNet(params);
          this.discountGridChanged.emit();
        },
        cellRendererParams: {
          key: 'seasonnalityIndex',
          workspaceKey: 'workspaceSeasonalityIndex',
          displayType: 'Percentage',
          undoKey: 'seasonnalityIndexUndo',
          backupKey: 'seasonnalityIndexBackup',
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isEditableCell
        },
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: this.isEditableCell,
          calcCellValue: this.calcAvgSeasonnalityIndexPinnedRow,
          gridSumRow: 1
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: this.isEditableCell
        }
      },
      {
        headerName: this.userBundle['discount.belgium.mediaCost'], field: 'grossValue', width: 150, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: this.isEditableCell,
          prefixText: (params) => {
            if (params.grossValue === 0 || (params.grossValue && params.grossValue !== '')) {
              return '';
            } else {
              return this.userBundle['discount.common.total'];
            }
          },
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: this.isEditableCell
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isEditableCell
        }
      },
      {
        headerName: this.userBundle['discount.belgium.discount'], field: 'discount', width: 150, editable: true,
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: this.isEditableCell,
          calcCellValue: this.calcAvgDiscountPinnedRow,
          gridSumRow: 1
        },
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: this.isEditableCell
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentage,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isEditableCell,
          maxValue: 100
        }
      },
      {
        headerName: this.userBundle['discount.belgium.netwithouttaxes'], field: 'net', width: 150, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: this.isEditableCell,
          prefixText: (params) => {
            if (params.net === 0 || (params.net && params.net !== '')) {
              return '';
            } else {
              return this.userBundle['discount.common.total'];
            }
          },
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: this.isEditableCell
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isEditableCell
        }
      },
      {
        headerName: this.userBundle['discount.belgium.statusCategory'],
        field: 'statusCategoryId', width: 150, editable: true, pinned: false,
        cellRendererFramework: AgSelectCellRendererComponent,
        onCellValueChanged: (param) => {
          if (this.isDiscountV2) {
            param.data.statusCategoryId = parseInt(param.data.statusCategoryId, 0);
            this.commercialService.updateDiscounts(this.campaignDetails.discountsV2);
          }
        },
        cellRendererParams: {
          cellEditable: this.isEditableCell,
          valKey: 'idStatusCategory',
          textKey: 'statusCategoryName',
          dataArr: () => {
            return this.statusCategory;
          }
        },
        pinnedRowCellRendererFramework: AgSelectCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: this.isEditableCell,
          valKey: 'idStatusCategory',
          textKey: 'statusCategoryName',
          dataArr: () => {
            return this.statusCategory;
          }
        },
        cellEditorFramework: AgSelectCellEditorComponent,
        cellEditorParams: {
          valKey: 'idStatusCategory',
          textKey: 'statusCategoryName',
          dataArr: () => {
            return this.statusCategory;
          },
          cellEditable: this.isEditableCell
        }
      },
      {
        headerName: this.userBundle['discount.belgium.technicalFee'], field: 'technicalFee', width: 150, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: this.isEditableCell,
          gridSumRow: 1
        },
        cellRendererFramework: AgLsCellRendererComponent,
        onCellValueChanged: (params) => {
          if (params && params.data &&
            params.data.technicalFee !== params.data.flTaxes) {
            params.data.technicalFeeUndo = false;
          }
          this.technicalFeeUpdatedLS(params);
          this.discountGridChanged.emit();
        },
        cellRendererParams: {
          key: 'technicalFee',
          workspaceKey: 'flTaxes',
          displayType: 'Currency',
          undoKey: 'technicalFeeUndo',
          backupKey: 'technicalFeeBackup',
          cellEditable: this.isEditableCell,
          formatCellValue: (value) => {
            let val = value;
            if (val === 0 || (val && val !== '')) {
              val = parseFloat(val);
              val = val.toFixed(this.precisionOnPercentageDisplay);
            }
            return val;
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isEditableCell
        }
      },
      {
        headerName: this.userBundle['discount.belgium.printingCost'], field: 'printingCost', width: 100, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: this.isEditableCell,
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: this.isEditableCell
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isEditableCell
        }
      },
      {
        headerName: this.userBundle['discount.belgium.zippingCost'], field: 'zippingCost', width: 100, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: this.isEditableCell,
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: this.isEditableCell
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isEditableCell
        }
      },
      {
        headerName: this.userBundle['discount.belgium.stampingCost'], field: 'stampingCost', width: 100, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: this.isEditableCell,
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: this.isEditableCell
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isEditableCell
        }
      },
      {
        headerName: this.userBundle['discount.belgium.transportCost'], field: 'transportCost', width: 100, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: this.isEditableCell,
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: this.isEditableCell
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isEditableCell
        }
      },
      {
        headerName: this.userBundle['discount.belgium.uploadCostValue'], field: 'uploadCostValue', width: 100, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: this.isEditableCell,
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: this.isEditableCell
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          allowReplaceDecimalSeparator: true,
          cellEditable: this.isEditableCell
        }
      }
    ];

    this.numericFields = [
      'numberFrames',
      'smartBricsValue',
      'seasonnalityIndex',
      'grossValue',
      'discount',
      'net',
      'technicalFee',
      'smartContentValue',
      'printingCost',
      'stampingCost',
      'zippingCost',
      'transportCost',
      'uploadCostValue'
    ];

    this.dateRangeFields = ['week'];
    this.selectFields = ['statusCategoryId'];
    const decimalFieldsForFilter = [
      'smartBricsValue',
      'seasonnalityIndex',
      'grossValue',
      'discount',
      'net',
      'technicalFee',
      'smartContentValue',
      'printingCost',
      'stampingCost',
      'zippingCost',
      'transportCost',
      'uploadCostValue'
    ];
    for (let i = 1; i < this.columnDefs.length; i++) {
      if (this.columnDefs[i].field === 'statusCategoryId') {
        this.columnDefs[i].floatingFilterComponentFramework = AgSelectFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          suppressFilterButton: true,
          valKey: 'idStatusCategory',
          textKey: 'statusCategoryName',
          dataArr: () => {
            return this.statusCategory;
          },
          value: ''
        };
      } else {
        this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
        this.columnDefs[i].floatingFilterComponentParams = {
          value: '',
          suppressFilterButton: true,
          decimalRound: decimalFieldsForFilter.indexOf(this.columnDefs[i].field) > -1 ? 2 : null
        };
      }

      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };
      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => {
        const retArr: string[] = [];
        if (params.data.isParent || params.node.rowPinned === 'bottom') {
          retArr.push('fontBold');
        }
        if (params.node.rowPinned === 'bottom') {
          retArr.push('borderBottom');
        }
        if (params.data.isSelected) {
          retArr.push('selectedRow');
        }
        return retArr;
      };
    }

    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField, true);
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
    // restore original data order when sorting is cleared
    if (this.sortObj.sortColumns.length === 0) {
      const discountProperty = this.getDiscountProperty();
      this.rowData = _.clone(this.campaignDetails[discountProperty]);
    }
  }

  blankCellRenderer() {
    return '';
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.forEach((r) => {
        r.isFilterPass = true;
      });
    }
    // Refresh Grid Header checkbox component
    /* tslint:disable:no-string-literal */
    this.gridOptions.api['headerRootComp'].forEachHeaderElement((element) => {
      if (element.column.colId === 'selectChk' && element.columnApi && element.childComponents && element.childComponents.length > 1) {
        const headerCmpIns = element.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  clearAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    this.gridOptions.api.onFilterChanged();
  }

  spread = () => {
    if (!this.isDiscountGridEditable) {
      return;
    }
    const selectedChildRows = this.getSelectedChildRows();
    if (selectedChildRows.length > 0) {
      if (this.footerRowData[0].net === 0 || this.footerRowData[0].net) {
        for (const selectedRow of selectedChildRows) {
          selectedRow.net = this.footerRowData[0].net / selectedChildRows.length;
          selectedRow.discount = this.calculateDiscount(selectedRow.grossValue, selectedRow.net);
        }

        this.resetApply();
        this.rowData = _.clone(this.rowData);
      }
    }
  }

  money = () => {
    this.gridOptions.api.setFocusedCell(0, 'smartBricsValue');
    this.gridOptions.api.ensureColumnVisible('smartBricsValue');
  }

  apply = () => {
    if (!this.isDiscountGridEditable) {
      return;
    }
    const selectedChildRows = this.getSelectedChildRows();
    if (selectedChildRows.length > 0) {
      if ((this.footerRowData[0].statusCategoryId || this.footerRowData[0].discount === 0
        || this.footerRowData[0].discount || this.footerRowData[0].grossValue === 0 || this.footerRowData[0].grossValue ||
        this.footerRowData[0].uploadCostValue === 0 || this.footerRowData[0].uploadCostValue ||
        this.footerRowData[0].smartContentValue === 0 || this.footerRowData[0].smartContentValue ||
        this.footerRowData[0].printingCost === 0 || this.footerRowData[0].printingCost ||
        this.footerRowData[0].transportCost === 0 || this.footerRowData[0].transportCost ||
        this.footerRowData[0].zippingCost === 0 || this.footerRowData[0].zippingCost ||
        this.footerRowData[0].stampingCost === 0 || this.footerRowData[0].stampingCost ||
        this.footerRowData[0].net === 0 || this.footerRowData[0].net ||
        this.footerRowData[0].technicalFee === 0 || this.footerRowData[0].technicalFee ||
        this.footerRowData[0].seasonnalityIndex === 0 || this.footerRowData[0].seasonnalityIndex
      )) {
        for (const selectedChildRow of selectedChildRows) {
          if (this.footerRowData[0].discount === 0 || this.footerRowData[0].discount) {
            selectedChildRow.discount = this.footerRowData[0].discount;
            selectedChildRow.net = this.calculateNetWithoutTax(selectedChildRow.grossValue, selectedChildRow.discount);
          }

          if (this.footerRowData[0].grossValue === 0 || this.footerRowData[0].grossValue) {
            selectedChildRow.grossValue = this.footerRowData[0].grossValue;
            selectedChildRow.net = this.calculateNetWithoutTax(selectedChildRow.grossValue, selectedChildRow.discount);
          }

          if (this.footerRowData[0].smartContentValue === 0 || this.footerRowData[0].smartContentValue) {
            selectedChildRow.smartContentValue = this.footerRowData[0].smartContentValue;
          }

          if (this.footerRowData[0].printingCost === 0 || this.footerRowData[0].printingCost) {
            selectedChildRow.printingCost = this.footerRowData[0].printingCost;
          }

          if (this.footerRowData[0].zippingCost === 0 || this.footerRowData[0].zippingCost) {
            selectedChildRow.zippingCost = this.footerRowData[0].zippingCost;
          }

          if (this.footerRowData[0].stampingCost === 0 || this.footerRowData[0].stampingCost) {
            selectedChildRow.stampingCost = this.footerRowData[0].stampingCost;
          }

          if (this.footerRowData[0].transportCost === 0 || this.footerRowData[0].transportCost) {
            selectedChildRow.transportCost = this.footerRowData[0].transportCost;
          }

          if (this.footerRowData[0].uploadCostValue === 0 || this.footerRowData[0].uploadCostValue) {
            selectedChildRow.uploadCostValue = this.footerRowData[0].uploadCostValue;
          }

          if (this.footerRowData[0].statusCategoryId) {
            selectedChildRow.statusCategoryId = this.footerRowData[0].statusCategoryId;
            this.commercialService.updateDiscounts(this.campaignDetails.discountsV2);
          }

          if (this.footerRowData[0].net === 0 || this.footerRowData[0].net) {
            selectedChildRow.net = this.footerRowData[0].net;
            selectedChildRow.discount = this.calculateDiscount(selectedChildRow.grossValue, selectedChildRow.net);
          }

          if (this.footerRowData[0].technicalFee === 0 || this.footerRowData[0].technicalFee) {
            selectedChildRow.technicalFee = this.footerRowData[0].technicalFee;
            if (this.isDiscountV2) {
              selectedChildRow.technicalFeeUndo = false;
            }
          }

          if (this.footerRowData[0].seasonnalityIndex === 0 || this.footerRowData[0].seasonnalityIndex) {
            selectedChildRow.seasonnalityIndex = this.footerRowData[0].seasonnalityIndex;
            if (selectedChildRow.seasonnalityIndex < 0) {
              selectedChildRow.seasonnalityIndex = 0;
            }
            selectedChildRow.grossValue = this.calculateMediaCost(selectedChildRow.smartBricsValue, selectedChildRow.seasonnalityIndex);
            selectedChildRow.net = this.calculateNetWithoutTax(selectedChildRow.grossValue, selectedChildRow.discount);
          }
        }
        this.resetApply();
        this.rowData = _.clone(this.rowData);
      }
    } else {
      this.toaster.logInfo('Select atleast one row!');
    }
  }

  reset = () => {
    this.resetApply();
    this.rowData = _.clone(this.rowData);
  }

  resetApply = () => {
    const columnArray = ['statusCategoryId', 'discount', 'net', 'smartContentValue', 'printingCost', 'zippingCost', 'stampingCost', 'transportCost', 'uploadCostValue', 'grossValue', 'technicalFee', 'seasonnalityIndex'];
    for (const c of columnArray) {
      this.footerRowData[0][c] = null;
    }
    this.discountGridChanged.emit();
  }

  // Data functions
  getChildRows(): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData);
  }

  getSelectedChildRows(): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData);
  }

  calcAvgDiscount(rows) {
    const valueObj = { value1: 0, value2: 0 };
    rows.forEach((row) => {
      valueObj.value1 += parseFloat(row.grossValue);
      valueObj.value2 += parseFloat(row.net);
    });
    let value = 0;
    if (valueObj.value2 !== 0 && valueObj.value1 !== 0) {
      value = (1 - (valueObj.value2 / valueObj.value1)) * 100;
    }
    return value;
  }

  calcAvgSeasonnalityIndex(rows) {
    let total = 0;
    if (rows.length) {
      rows.forEach((row) => {
        total += row.seasonnalityIndex;
      });
      let avgIndexValue = total / rows.length;
      avgIndexValue = Number(avgIndexValue.toFixed(this.precisionOnPercentageDisplay));
      return avgIndexValue;
    }
    return 0;
  }

  calcAvgDiscountPinnedRow = (rows) => {
    if (this.footerRowData) {
      if (this.footerRowData[0].discount === 0 || (this.footerRowData[0].discount && this.footerRowData[0].discount !== '')) {
        return this.footerRowData[0].discount;
      } else {
        return this.calcAvgDiscount(rows);
      }
    } else {
      return this.calcAvgDiscount(rows);
    }
  }

  calcAvgSeasonnalityIndexPinnedRow = (rows) => {
    if (this.footerRowData) {
      if (this.footerRowData[0]['seasonnalityIndex'] === 0 || (this.footerRowData[0]['seasonnalityIndex'] && this.footerRowData[0]['seasonnalityIndex'] !== '')) {
        return this.footerRowData[0]['seasonnalityIndex'];
      } else {
        return this.calcAvgSeasonnalityIndex(rows);
      }
    } else {
      return this.calcAvgSeasonnalityIndex(rows);
    }
  }

  calculateDiscount(grossValue, net) {
    const mediaValue = parseFloat(grossValue);
    const agencyNet = parseFloat(net);
    const discount = (mediaValue !== 0) ? ((mediaValue - agencyNet) / mediaValue * 100) : 0;
    if (this.isDiscountV2) {
      return discount.toFixed(this.precisionOnPercentage);
    }
    return discount.toFixed(this.precisionOnPercentageDisplay);
  }

  /**
   * @description : Calculate the NET value.
   * @param {number} rateCard - RateCard value
   * @param {number} discount - value of discount
   * @returns Calculated net value
   */
  calculateNetWithoutTax(grossVal, discountVal) {
    const grossValue = parseFloat(grossVal);
    const discount = parseFloat(discountVal);

    const finalNet = grossValue - (grossValue * (discount / 100));
    if (isNaN(finalNet)) {
      return 0.00;
    }
    return finalNet.toFixed(this.precisionOnPercentageDisplay);
  }

  /**
   * download cart data in excel format
   */
  downloadExcel() {
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    // Add Header Row
    let sheetRow: ExcelCell[] = [];
    for (let j = 1; j < this.columnDefs.length; j++) {
      if (this.gridOptions.columnApi.getColumn(this.columnDefs[j].field)['visible']) {
        const cell: ExcelCell = {
          text: this.columnDefs[j].headerName,
          fontColor: '#FFFFFF',
          bgColor: '#000000',
          borderColor: '#CCCCCC',
          isFontBold: true
        };
        if (this.columnDefs[j].field === 'locked') {
          cell.text = this.userBundle['discount.be.locked'] || 'Locked';
        }
        const col: ExcelColumn = {
          width: this.columnDefs[j].field === 'week' ? 15 : 12
        };
        excelColumn.push(col);
        sheetRow.push(cell);
      }
    }
    sheetData.push(sheetRow);

    // Add Data rows
    let rowsInserted = 0;
    for (const row of this.rowData) {
      if (row.isParent) {
        continue;
      }

      sheetRow = [];
      for (let j = 1; j < this.columnDefs.length; j++) {
        if (this.gridOptions.columnApi.getColumn(this.columnDefs[j].field)['visible']) {
          let cellVal = row[this.columnDefs[j].field];
          if (this.selectFields.indexOf(this.columnDefs[j].field) > -1) {
            cellVal = row[`${this.columnDefs[j].field}_sort`];
          }
          const cell: ExcelCell = {
            text: cellVal,
            fontColor: '#000000',
            bgColor: rowsInserted % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
            borderColor: '#CCCCCC'
          };
          sheetRow.push(cell);
        }
      }
      rowsInserted++;
      sheetData.push(sheetRow);
    }
    const cartText = 'discount';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }

  populateSheetRow(sheetRow: any[], row, rowsInserted: number) {
    for (let j = 1; j < this.columnDefs.length; j++) {
      if (this.gridOptions.columnApi.getColumn(this.columnDefs[j].field)['visible']) {
        let cellVal = row[this.columnDefs[j].field];
        if (this.selectFields.indexOf(this.columnDefs[j].field) > -1) {
          cellVal = row[`${this.columnDefs[j].field}_sort`];
        }
        const cell: ExcelCell = {
          text: cellVal,
          fontColor: '#000000',
          bgColor: rowsInserted % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
          borderColor: '#CCCCCC'
        };
        sheetRow.push(cell);
      }
    }
    return sheetRow;
  }

  toggleColumnVisible(colId, isVisible) {
    const isVisibleLocal = !isVisible;
    this.gridOptions.columnApi.setColumnVisible(colId, isVisibleLocal);
  }

  /**
   * @description Sets default values in the unlocked discount line
   * @author Amit Mahida
   * @param {number} rowIndex
   * @memberof DiscountGridBelgiumComponent
   */
  setDefaultDiscountOnUnlock(rowIndex: number) {
    this.rowData[rowIndex].uploadCostValue = this.rowData[rowIndex].defaultUploadCost; // Calculated on backend
    this.rowData[rowIndex].technicalFee = this.rowData[rowIndex].defaultTechnicalFee; // Calculated on backend
    this.rowData[rowIndex].technicalFeeCalc = this.rowData[rowIndex].defaultTechnicalFee; // Calculated on backend
    this.rowData[rowIndex] = { ...this.rowData[rowIndex], ...defaultDiscountData }; // Set defaults
    this.rowData[rowIndex].grossValue = this.calculateMediaCost(this.rowData[rowIndex].smartBricsValue, this.rowData[rowIndex].seasonnalityIndex);
    this.rowData[rowIndex].net = this.calculateNetWithoutTax(this.rowData[rowIndex].grossValue, this.rowData[rowIndex].discount);
    const discountProperty = this.getDiscountProperty();
    this.campaignDetails[discountProperty] = _.clone(this.rowData);
    const lockedDiscounts = this.stateService.getCommercialObject('lockedDiscounts');
    delete lockedDiscounts[rowIndex];
    this.stateService.setCommercialObject('lockedDiscounts', lockedDiscounts);
  }

  /**
   * @description To identify the discount column name
   * @author Dhaval Patel
   * @returns discount column name
   */
  private getDiscountProperty(): string {
    if (this.isDiscountV2) {
      return 'discountsV2';
    }
    return 'discounts';
  }

  /**
   * @description To check that is discount grid is editable or not
   * @author Dhaval Patel
   */
  private manageReadOnly() {
    if (this.dataShareService.getInitialConfigByKey('uiControl').externalUser || SystemFlags.readOnly
      || (this.isDiscountV2 && !(this.userData && this.userData.tokens && this.userData.tokens.indexOf('EDIT_DISCOUNT_GRID') > -1))) {
      this.isDiscountGridEditable = false;
      const ele: any = document.querySelector('.ag-theme-fresh .ag-body-viewport');
      if (ele) {
        ele.className = `${ele.className} readOnly`;
      }
      const ele1: any = document.querySelector('.ag-theme-fresh .ag-floating-bottom');
      if (ele1) {
        ele1.className = `${ele1.className} readOnly`;
      }
    } else {
      this.isDiscountGridEditable = true;
      const ele: any = document.querySelector('.ag-theme-fresh .ag-body-viewport');
      if (ele) {
        ele.classList.remove('readOnly');
      }
      const ele1: any = document.querySelector('.ag-theme-fresh .ag-floating-bottom');
      if (ele1) {
        ele1.classList.remove('readOnly');
      }
    }
  }

  /**
   * @description This method will be called from parent component when readOnly property is changed.
   * @author Dhaval Patel
   */
  readOnlyUpdate() {
    this.manageReadOnly();
  }

  trackByField(index, column) {
    return column?.colDef?.field;
  }

}
