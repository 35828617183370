import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, CommercialConfig, HistoryROC } from '../../commercial.model';
import { DataShareService } from '../../../core/services/data-share.service';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { StateService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { CampaignDetailsBase } from '../campaign-details-base';
import { MaskService } from '../../../core/services/mask.service';
import { NumberMaskModel } from '../../../models/imask';
import { CampaignType, CampaignGroupTypeList } from '../../../core/enum';
import { Campaign } from '../../../models/campaign';
import { SystemFlags } from '../../../models/system-flags';

@Component({
  selector: 'app-campaign-details-denmark',
  templateUrl: './campaign-details-denmark.component.html',
  styleUrls: ['./campaign-details-denmark.component.css']
})
export class CampaignDetailsDenmarkComponent extends CampaignDetailsBase implements OnInit, OnDestroy {
  @Input() campaignDetails: CampaignDetails;
  @Input() commercialConfig: CommercialConfig;
  @Input() historyROC: HistoryROC;
  @Output() manuallyCPTUpdated: EventEmitter<any> = new EventEmitter();

  /**
   * @description Data Subscription
   * @type {Subscription}
   * @memberof CampaignDetailsDenmarkComponent
   */
  bookingLineDataSubscription: Subscription;

  maskService: MaskService = new MaskService();

  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof BookingDetailsDenmarkComponent
   */
  currencyInputMask: NumberMaskModel;

  /**
   * @description Campaign Type used in .html file
   * @memberof CampaignDetailsDenmarkComponent
   */
  campaignType = CampaignType;

  /**
   * @description Search page object
   * @type {Campaign}
   * @memberof CampaignDetailsDenmarkComponent
   */
  searchPageObject: Campaign;

  /**
   * @description set readOnly
   * @memberof CampaignDetailsDenmarkComponent
   */
  public readOnly = SystemFlags.readOnly;

  campaignGroupTypeList = CampaignGroupTypeList;

  constructor(
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    commercialService: CommercialService,
    stateService: StateService
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService
    );

    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe((value: any) => {
      if (value.rowIndex >= 0 && value.columnName) {
        this.calculateCPT(value);
      }
      this.onBookingLineChange();
    });
  }

  ngOnInit(): void {
    this.currencyInputMask = this.maskService.currencyInputMask({ max: 999999999999999999999, padFractionalZeros: true });
    super.init(this.campaignDetails);
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);
    this.searchPageObject = this.stateService.getCampaign();
    this.manageGrossMediaValueWithoutIndex();
    this.setLookupConfig();
  }

  ngOnDestroy() {
    this.bookingLineDataSubscription.unsubscribe();
  }

  /**
   * @description To set the configuration for all lookup components
   * @author Dhaval Patel
   * @memberof CampaignDetailsDenmarkComponent
   */
  setLookupConfig() {
    this.setAdvertiserConfig();
    this.setBrandConfig();
    this.setSpecialistConfig();
    this.setAgencyConfig();
  }

  /**
   * @description - Callback method call on specialist name changed
   * @author Shivani Patel
   * @param {*} value
   * @memberof CampaignDetailsDenmarkComponent
   */
  onSpecialistNameSelected(value: any): void {
    if (this.campaignDetails.specialist) {
      super.onSpecialistChange(value);
      this.manageGrossMediaValueWithoutIndex();
    }
  }

  /**
   * @description - Callback method call on removing selected specialist
   * @author Shivani Patel
   * @memberof CampaignDetailsDenmarkComponent
   */
  removeSelectedSpecialistName() {
    super.onSpecialistChange();
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description - Callback method call on changing specialist percentage
   * @author Shivani Patel
   * @memberof CampaignDetailsDenmarkComponent
   */
  onSpecialistPercentageChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description - Callback method call on agency name changed
   * @author Shivani Patel
   * @param {*} value
   * @memberof CampaignDetailsDenmarkComponent
   */
  onAgencyNameSelected(value: any): void {
    if (this.campaignDetails.agency) {
      super.onAgencyChange(value);
      this.manageGrossMediaValueWithoutIndex();
    }
  }

  /**
   * @description - Callback method call on removing selected agency
   * @author Shivani Patel
   * @memberof CampaignDetailsDenmarkComponent
   */
  removeSelectedAgencyName(): void {
    super.onAgencyChange();
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description - Callback method call on changing agency percentage
   * @author Shivani Patel
   * @memberof CampaignDetailsDenmarkComponent
   */
  onAgencyPercentageChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description Fires when something is changed in Booking Details
   * @author Shivani Patel
   * @memberof CampaignDetailsDenmarkComponent
   */
  onBookingLineChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description Update pmp discount in grid when CPT value gets changed
  * @author Shivani Patel
   * @memberof CampaignDetailsDenmarkComponent
   */
  onAverageCPTChanged() {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT);
    this.onBookingLineChange();
    if (this.uiControl.enableUserDefinedCPMPricingCalculation) {
      this.manuallyCPTUpdated.emit();
    }
  }

  /**
   * @description calculate CPT from gross media value or pmp discount change
   * @author Kishan Patel
   * @param {*} value
   * @memberof CampaignDetailsUkComponent
   */
  calculateCPT(value: { rowIndex?: number, columnName?: string }) {
    if (value.columnName === 'grossMediaValue') {
      this.campaignDetailsService.calculateCPT(
        this.campaignDetails, value.rowIndex,
        undefined,
        this.campaignDetails.bookingList[value.rowIndex].grossMediaValue
      );
    } else if (value.columnName === 'pmpdiscount') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex);
    }
  }

}
