<app-sb-modal-wrapper [title]="userBundle['workspace.framequality.bric.heading']"
  [titleBackgroundColor]="resolveObject.brick.color" [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)"
  (saved)="onModalSaved($event)">
  <div [ngClass]="{'readOnly' : readOnlyModal}">
    <div class="control_group">
      <div class="full-width sot-slider">
        <ngx-slider [(value)]="frameQuality.audienceLevel" [options]="options"></ngx-slider>
      </div>
    </div>
    <div class="control_group" *ngIf="pcmMode">
      <input type="checkbox" [(ngModel)]="frameQuality.isEditable" class="checkbox" id="isEditable" />
      <label for="isEditable">
        {{userBundle['workspace.frameQuality.fqEditable'] || 'FQ editable'}}</label>
    </div>
  </div>
</app-sb-modal-wrapper>
