import { Directive, ElementRef, Input, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import * as Hammer from 'hammerjs';
import { WorkspaceService } from '../services';
import { JqScale } from '../utils/JqScale';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appPanZoom]'
})
export class PanZoomDirective implements AfterViewInit, OnDestroy {

  currentZoomLevel = 1;
  @Input() refContainer;
  @Input() zoomIn;
  @Input() zoomOut;
  @Input() zoomReset;
  // private privateStartPanning: boolean;

  defaultPanSub: Subscription;
  @Input() disablePan = false;

  readonly DATA_JQSCALE = 'data-jqscale';
  readonly TOP_CENTER = 'top center';

  @HostListener('mousewheel') onmousewheel(event) {
    this.wheel(event);
  }

  @HostListener('DOMMouseScroll') onDOMMouseScroll(event) {
    this.wheel(event);
  }

  constructor(
    private el: ElementRef,
    private workspaceService: WorkspaceService
  ) {
    this.defaultPanSub = this.workspaceService.defaultPanningSub.subscribe((defaultPan: boolean) => {
      if (defaultPan) {
        this.ZoomDefault();
      }
    });
  }

  // tslint:disable-next-line:function-name
  ZoomIn() {
    let element = null;
    if (this.refContainer) {
      element = this.refContainer;
    } else {
      element = this.el.nativeElement;
    }
    if (element.getAttribute(this.DATA_JQSCALE)) {
      this.currentZoomLevel = parseFloat(element.getAttribute(this.DATA_JQSCALE).split(',')[0]);
    }
    this.currentZoomLevel = this.currentZoomLevel + 0.1;
    if (this.currentZoomLevel > 2) {
      this.currentZoomLevel = 2;
    }
    if (element != null) {
      JqScale.jqscale(element, this.currentZoomLevel);
      element.style.transformOrigin = this.TOP_CENTER;
      if (element.clientWidth >= element.parentElement.clientWidth) {
        element.style.transformOrigin = 'top left';
      }
    }
  }

  // tslint:disable-next-line:function-name
  ZoomOut() {
    let element = null;
    if (this.refContainer) {
      element = this.refContainer;
    } else {
      element = this.el.nativeElement;
    }
    if (element.getAttribute(this.DATA_JQSCALE)) {
      this.currentZoomLevel = parseFloat(element.getAttribute(this.DATA_JQSCALE).split(',')[0]);
    }

    if (this.currentZoomLevel > 0.5) {
      this.currentZoomLevel = this.currentZoomLevel - 0.1;
      if (element != null) {
        JqScale.jqscale(element, this.currentZoomLevel);
        element.style.transformOrigin = this.TOP_CENTER;
      }
      if (element.clientWidth >= element.parentElement.clientWidth) {
        element.style.transformOrigin = 'top left';
      }
    }
  }

  // tslint:disable-next-line:function-name
  ZoomDefault() {
    this.currentZoomLevel = 1;
    let element = null;
    if (this.refContainer) {
      element = this.refContainer;
    } else {
      element = this.el.nativeElement;
    }
    if (element != null) {
      JqScale.jqscale(element, this.currentZoomLevel);
      // : transform;
      const matrix = element.style.transform.split(',');
      matrix[4] = 0;
      matrix[5] = 0;
      element.style.transform = matrix.join();
      element.style.transformOrigin = this.TOP_CENTER;
      if (element.style.width >= element.parentElement.clientWidth) {
        element.style.transformOrigin = 'top left';
      }
    }
  }

  wheel(event) {
    let delta = 0;
    if (!event) {
      /* tslint:disable:deprecation */
      event = window.event;
    } /* For IE. */

    if (event.wheelDelta) { /* IE/Opera. */
      delta = event.wheelDelta / 120;
    } else if (event.detail) { /** Mozilla case. */
      /** In Mozilla, sign of delta is different than in IE.
       * Also, delta is multiple of 3.
       */
      delta = -event.detail / 3;
    }

    if (delta > 0) {
      // scrolling up
      this.ZoomIn();
    } else {
      this.ZoomOut();
    }
  }

  /**
   * To get the Element
   */
  getElement() {
    if (this.refContainer) {
      return this.refContainer;
    }
    return this.el.nativeElement;
  }

  /**
   * To get the matrix
   * @param element Element
   * @param ev Event
   */
  getMatrix(element: any, ev: any) {
    const panningWidthThreshold = element.parentElement.clientWidth;
    const panningHeightThreshold = element.parentElement.clientHeight;
    const matrix = element.style.transform.split(',');
    const panRowActive = element.clientWidth > element.parentElement.clientWidth;
    const ghostHeight = 85;
    const panColActive = (element.clientHeight - ghostHeight) > element.parentElement.clientHeight;
    if (panRowActive) {
      matrix[4] = parseInt(matrix[4], 10) + (ev.deltaX + ev.velocityX);
      if (matrix[4] < 0) {
        const matrix4 = (element.clientWidth - panningWidthThreshold) * -1;
        if (matrix4 > matrix[4]) {
          matrix[4] = matrix4;
        }
      } else {
        matrix[4] = 0;
      }
    }
    if (panColActive) {
      matrix[5] = parseInt(matrix[5], 10) + (ev.deltaY + ev.velocityY);
      if (matrix[5] < 0) {
        const matrix5 = (element.clientHeight - panningHeightThreshold) * -1;
        if (matrix5 > matrix[5]) {
          matrix[5] = matrix5;
        }
      } else {
        matrix[5] = 0;
      }
      matrix[5] = `${matrix[5]})`;
    }
    return matrix;
  }

  ngAfterViewInit() {
    const element = this.getElement();
    element.style.willChange = 'transform';
    element.style.transformOrigin = this.TOP_CENTER;
    JqScale.jqscale(element, this.currentZoomLevel);

    // create a simple instance
    // by default, it only adds horizontal recognizers
    const mc = new Hammer(element.parentElement, {
      threshold: 0,
      direction: Hammer.DIRECTION_ALL
    });
    // listen to events...
    mc.on('pan', (ev) => {
      if (this.disablePan) {
        return;
      }
      const matrix = this.getMatrix(element, ev);
      const updatedMatrix = matrix.join();
      element.style.transform = updatedMatrix;
    });

    this.zoomIn.onclick = () => {
      this.ZoomIn();
    };
    this.zoomOut.onclick = () => {
      this.ZoomOut();
    };
    this.zoomReset.onclick = () => {
      this.ZoomDefault();
    };
  }

  ngOnDestroy() {
    this.defaultPanSub.unsubscribe();
  }

}
