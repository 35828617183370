<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
[readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <div class="tabs_container horizontal">
    <ul class="tabs-menu">
      <!-- Amit : To resolve SBRICS-1370 we have removed orderBy: 'selectionId' from below two loops-->
      <li class="" *ngFor="let lookup of lookups.lookup; trackBy: trackBySelectionId; let i = index" [ngClass]="{'current': i == selectedItem}">
        <a (click)="showContent(i)" style="cursor: pointer;">
          <span class="text">{{lookup.displayName}}</span>
        </a>
      </li>
    </ul>
    <div class="tab" [ngClass]="{'readOnly' : readOnlyModal}">
      <div id="tab-{{j}}" class="tab-content" *ngFor="let lookup of lookups.lookup; trackBy: trackBySelectionId; let j = index"
        [ngStyle]="{'display': j==selectedItem?'block':'none'}">
        <app-lookup-view [lookupType]="lookup.lookupType" [selectionId]="lookup.selectionId"
          [(ngModel)]="selected[lookup.selectionId]" [placeholder]="lookup.displayName" [selectAll]="lookup.selectAll"
          [regexp]="lookup.regExp" [displaycode]="lookup.displayCode"
          [configLookup]="configLookup?configLookup[lookup.selectionId]: null"
          [bricId]="brickBaseService.brickID.Format" [isRelativeFilterVisible]="localSolverEnabled">
        </app-lookup-view>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="row" [hidden]="hideUIElements('workspace.format.checkboxGroup')"
      [ngClass]="{'readOnly' : readOnlyModal || appHeaderService.objectiveMode }">
      <div class="col-sm-6" [hidden]="hideUIElements('workspace.format.dominateFurniture')">
        <input type="checkbox" [(ngModel)]="selected.selectionCriteriaRestriction[1]" class="checkbox" id="DF"
          name="DF">
        <label for="DF">{{initialConfig.userBundle['workspace.collection.bric.format.dominateFurniture']}}</label>
      </div>
      <div class="col-sm-6" [hidden]="hideUIElements('workspace.format.backtoBackPanels')">
        <input type="checkbox" [(ngModel)]="selected.selectionCriteriaRestriction[2]" class="checkbox" id="BBP"
          name="BBP">
        <label for="BBP">{{initialConfig.userBundle['workspace.collection.bric.format.b2b']}}</label>
      </div>
      <div class="col-sm-6" [hidden]="hideUIElements('workspace.format.parallelPanels')">
        <input type="checkbox" [(ngModel)]="selected.selectionCriteriaRestriction[3]" class="checkbox" id="PP"
          name="PP">
        <label for="PP">{{initialConfig.userBundle['workspace.collection.bric.format.parallel']}}</label>
      </div>
    </div>

  </div>
</app-sb-modal-wrapper>
