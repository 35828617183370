import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ProductCatalogueBase } from '../product-catalogue-base';
import {
  CellValues,
  ProductCatalogueType,
  ProductCatalogue,
  TemplateProps,
  Row
} from '../../../models/workspace';
import {
  DataShareService,
  CellAttributeService,
  BrickBaseService,
  StateService,
  LogHelperService,
  PcmService
} from '../../../core/services';
import { ITreeState } from '@circlon/angular-tree-component';
import { TreeViewComponent } from '../../../core/components/tree-view/tree-view.component';
import { Filter } from '../../../../app/models/workspace/filters';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-product-catalogue-modal',
  templateUrl: './product-catalogue-modal.component.html',
  styleUrls: ['./product-catalogue-modal.component.css']
})
export class ProductCatalogueModalComponent extends ProductCatalogueBase implements OnInit {

  /**
   * @description brick object
   * @type {CellValues}
   * @memberof ProductCatalogueModalComponent
   */

  @ViewChild(TreeViewComponent)
  private treeViewComponent: TreeViewComponent;
  productCatalogueTypes: ProductCatalogueType[];
  productCatalogueDisplay: ProductCatalogue[];
  @Input() resolveObject: CellValues;
  activeTabIndex: number;
  options = {
    idField: 'idProductCatalogue',
    displayField: 'productCatalogueName',
    childrenField: 'products',
    useCheckbox: false,
    scrollContainer: document.body.parentElement
  };
  templateProps: TemplateProps;
  state: ITreeState;
  filterObj: Filter;
  hideDisabled = false;

  /**
   * @description Ser ReadOnlyModal
   * @type {boolean}
   * @memberof ProductCatalogueModalComponent
   */
  readOnlyModal = false;
  constructor(
    dataShareService: DataShareService,
    private stateService: StateService,
    private brickBaseService: BrickBaseService,
    private pcmService: PcmService,
    private cellAttributeService: CellAttributeService,
    public appHeaderService: AppHeaderService,
    private logHelperService: LogHelperService

  ) {
    super(dataShareService);
    this.filterObj = this.stateService.getWorkspaceFilterObj();
  }

  ngOnInit() {
    if (this.uiControl.hideDisabledProductsInPcm) {
      this.hideDisabled = true;
      this.productCatalogue = this.pcmService.getInUseProduct(this.productCatalogue);
    }
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.productCatalogueTypes = this.productCatalogueType;
    this.templateProps = {
      displayId: 'idProductCatalogue',
      displayName: 'productCatalogueName',
      displayRadioButton: true,
      showId: this.enableProductId,
      searchPlaceHolder: this.userBundle['workspace.collection.bric.productCatalogue.searchProduct'] || 'Search Product',
      noDataAvailableText: this.userBundle['workspace.collection.bric.productCatalogue.noData'] || 'Products Not Available',
      expandFirstNode: true
    };
    this.activeTabIndex = 0;
    if (!this.appHeaderService.enabledPCM) {
      this.filterProducts();
    }
    this.onTabChange(this.activeTabIndex, this.productCatalogueTypes[0].idProductCatalogueType);
  }
  /**
   * Filters the Products
   *
   * @memberof ProductCatalogueModalComponent
   */
  filterProducts() {
    const campaignDetails = this.stateService.getCampaign();
    let selectedDateRange: any = {};
    // Get the Row Index of Incharge BRIC//
    const row: Row = this.filterObj.getRowByBrickId(Number(this.brickBaseService.brickID.Incharge));
    /* Use the cellIndex of Product BRICK, to identify correct Incharge BRIC to filter the products
     In case if the index is undefined execute the function getLastNonEmptyCell()
    */
    const cell = _.isUndefined(row.cells[this.resolveObject.brick.cellIndex]) ?
      row.cells[row.getLastNonEmptyCell(this.resolveObject.brick.cellIndex)] :
      row.cells[this.resolveObject.brick.cellIndex];
    if (!_.isUndefined(row) && !_.isUndefined(cell)) {
      selectedDateRange = {
        startDate: cell.selected.startDate,
        endDate: cell.selected.endDate
      };
    }
    this.productCatalogue = this.pcmService.filterProductsBasedOnRestriction(this.productCatalogue, campaignDetails, selectedDateRange, true, this.stateService);
  }

  /**
   * Filter the Products again based on the Tab
   *
   * @param {*} index
   * @param {*} typeId
   * @memberof ProductCatalogueModalComponent
   */
  onTabChange(index, typeId) {
    this.activeTabIndex = index;
    const filteredData = this.filterProductCatalogueByProductType(parseInt(typeId, 10));
    const groupedData = this.groupProductCatalogueByProductGroup(filteredData);
    const productCatalogueData = this.createStructuredProductCataglogueAfterGrouping(groupedData);
    this.productCatalogueDisplay = productCatalogueData;

  }
  /**
   * Method to get the product Cataglogue Details
   *
   * @returns
   * @memberof ProductCatalogueModalComponent
   */
  getProductCatalogueDetails() {
    let selectedProduct;
    const selectedLeafNodeIds = this.treeViewComponent.getSelectedLeafNodeIds();
    for (const key in selectedLeafNodeIds) {
      selectedProduct = this.treeViewComponent.getNodeById(parseInt(key, 10));
    }
    return selectedProduct ? selectedProduct.data : selectedProduct;
  }

  /**
   * @description
   * @author
   * @param {*} event
   * @memberof ProductCatalogueModalComponent
   */
  onModalClosed(event) {
    const isSelected = this.getProductCatalogueDetails();
    if (event.reason === 'escape' && isSelected) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * @description Handles modal save
   * @author
   * @param {*} event
   * @memberof ProductCatalogueModalComponent
   */
  onModalSaved(event) {
    const selectedValues = this.getProductCatalogueDetails();
    if (selectedValues) {
      const values: CellValues = new CellValues();
      values.brick = this.resolveObject.brick;
      values.selectedValues = selectedValues;
      values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.ProductCatalogue, values.selectedValues);
      values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.ProductCatalogue,
        values.selectedValues.idProductCatalogue);
      values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.ProductCatalogue, values.selectedValues);
      event.activeModal.close(values);
    } else {
      this.logHelperService.logError(this.userBundle['common.error.noDataSelect']);
    }
  }

  trackByProductCatalogueType(index, tab) {
    return tab?.idProductCatalogueType;
  }

  hideDisabledChanged() {
    this.hideDisabled = !this.hideDisabled;
    if (this.hideDisabled) {
      this.productCatalogue = this.pcmService.getInUseProduct(this.productCatalogue);
    } else {
      this.productCatalogue = this.productCatalogueHolder.productCatalogue;
    }
    if (this.productCatalogueTypes && this.productCatalogueTypes[this.activeTabIndex]) {
      this.onTabChange(this.activeTabIndex, this.productCatalogueTypes[this.activeTabIndex].idProductCatalogueType);
    }
  }

}
