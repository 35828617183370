<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color" [readOnlyModal]="readOnlyModal"
  (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <div [ngClass]="{'readOnly' : readOnlyModal}">
    <div class="control_group">
      <label class="nopadding">{{initialConfig.userBundle['workspace.pricing.bric.minPricing']}}</label>
      <label class="nopadding right text-right">{{initialConfig.userBundle['workspace.pricing.bric.maxPricing']}}</label>
      <div class="pricing">
        <ngx-slider [(value)]="pricingFactor" [options]="options"></ngx-slider>
      </div>
      <br />
    </div>
  </div>
</app-sb-modal-wrapper>
