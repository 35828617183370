import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, CommercialConfig } from '../../commercial.model';
import { DataShareService } from '../../../core/services/data-share.service';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { StateService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { CampaignDetailsBase } from '../campaign-details-base';
import { SystemFlags } from '../../../models/system-flags';
import { Campaign } from '../../../models';
import { CampaignGroupTypeList, CampaignStatus } from '../../../core/enum';
import { NumberMaskModel } from '../../../models/imask';

@Component({
  selector: 'app-campaign-details-es',
  templateUrl: './campaign-details-es.component.html',
  styleUrls: ['./campaign-details-es.component.css']
})
export class CampaignDetailsEsComponent extends CampaignDetailsBase implements OnInit, OnDestroy {
  @Input() campaignDetails: CampaignDetails;
  @Input() commercialConfig: CommercialConfig;
  @Output() manuallyCPTUpdated: EventEmitter<any> = new EventEmitter();

  bookingLineDataSubscription: Subscription;

  /**
   * @description Search page campaign object
   * @type {Campaign}
   * @memberof CampaignDetailsEsComponent
   */
  searchPageObject: Campaign;

  campaignGroupTypeList = CampaignGroupTypeList;

  /**
   * @description set readOnly
   * @memberof CampaignDetailsDefaultComponent
   */
  public readOnly = SystemFlags.readOnly;

  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof BookingDetailsDenmarkComponent
   */
  currencyInputMask: NumberMaskModel;

  constructor(
    commercialService: CommercialService,
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    stateService: StateService
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService

    );
    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe(() => {
      this.onBookingLineChange();
      this.manageGrossMediaValueWithoutIndex();
    });
  }

  ngOnInit(): void {
    super.init(this.campaignDetails);
    this.currencyInputMask = this.maskService.currencyInputMask({ max: 999999999999999999999, padFractionalZeros: true });
    this.searchPageObject = this.stateService.getCampaign();
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    this.enableSubAgency();
    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);
    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe((value: any) => {
      if (value.rowIndex >= 0 && value.columnName) {
        this.calculateCPT(value);
      }
      this.onBookingLineChange();
    });
    this.setLookupConfig();
  }

  calculateCPT(value: any) {
    if (value.columnName === 'grossMediaValue') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex,
        undefined, Number(this.campaignDetails.bookingList[value.rowIndex].grossMediaValue));
    } else if (value.columnName === 'pmpdiscount') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex);
    }
  }

  // Functions Related to Specialist//
  onSpecialistNameSelected(value: any): void {
    if (this.campaignDetails.specialist) {
      super.onSpecialistChange(value);
      this.manageGrossMediaValueWithoutIndex(); // This function has to be used in case if the booking grid is used;
      this.commercialService.notifyGrid(this.campaignDetails); // This is used when booking Details is used
    }
  }

  removeSelectedSpecialistName() {
    super.onSpecialistChange();
    this.manageGrossMediaValueWithoutIndex();
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  onSpecialistPercentageChange() {
    this.manageGrossMediaValueWithoutIndex();
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  // Functions related to Agencies//
  onAgencyNameSelected(value: any): void {
    if (this.campaignDetails.agency) {
      super.onAgencyChange(value);
      this.enableSubAgency();
      this.manageGrossMediaValueWithoutIndex();
      this.commercialService.notifyGrid(this.campaignDetails);
    }
  }
  removeSelectedAgencyName(): void {
    super.onAgencyChange();
    this.enableSubAgency();
    this.manageGrossMediaValueWithoutIndex();
    this.commercialService.notifyGrid(this.campaignDetails);
  }
  onAgencyPercentageChange() {
    this.manageGrossMediaValueWithoutIndex();
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  /**
   * @description Manage the gross media value
   * @author Dhaval Patel
   * @memberof CampaignDetailsEsComponent
   */
  manageGrossMediaValueWithoutIndex() {
    super.manageGrossMediaValueWithoutIndex('bookingList', false);
  }

  onBookingLineChange() {
    this.campaignDetails.grossMediaValue = 0;
    this.campaignDetails.netMediaValue = 0;
    this.campaignDetails.bookingList.forEach((booking) => {
      if (booking.bookingStatusId !== CampaignStatus.cancel) {
        this.campaignDetails.grossMediaValue += booking.grossMediaValue;
        this.campaignDetails.netMediaValue += booking.netMediaValue;
      }
    });
  }

  assignedToChange() {
    this.campaignDetailsService.populateAgencyPercentage(this.campaignDetails);
    this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
    this.enableSubAgency();
    this.setActiveAssignedTo();
  }

  /**
   * @description Call on average CPT change
   * @author Amit Mahida
   * @memberof CampaignDetailsEsComponent
   */
  onAverageCPTChanged() {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT); // SM-4776
    this.commercialService.notifyGrid(this.campaignDetails.bookingList);
    if (this.uiControl.enableUserDefinedCPMPricingCalculation) {
      this.manuallyCPTUpdated.emit();
    }
  }

  ngOnDestroy(): void {
    this.bookingLineDataSubscription.unsubscribe();
  }
}
