import {
  Component, OnInit, Type,
  ComponentFactoryResolver, ViewEncapsulation, ViewChild, OnDestroy, ChangeDetectorRef
} from '@angular/core';
import { Router } from '@angular/router';
import { BricHostDirective } from '../core/directives/bric-host.directive';

import { DataShareService } from '../core/services/data-share.service';
import { StateService } from '../core/services/state.service';
import { SyncWsBricksService } from '../core/services/sync-ws-bricks.service';
import { LogHelperService } from '../core/services/log-helper.service';
import { CampaignService } from '../core/services/campaign.service';
import { CartService } from '../geo-map/cart.service';
import { AppNameEnum } from '../core/enum';
import { Subscription } from 'rxjs';
import { ConcertinaCanvasService } from '../core/components/concertina/concertina-canvas.service';
import { SystemFlags, FrameModel, Filter } from '../models';
import * as _ from 'lodash';
import { GLOBAL } from '../core/utils/app.constant';
import { ConcertinaDataService } from '../core/components/concertina';
import { SwapZoneService } from '../core/components/swap-zone';

@Component({
  selector: 'app-visual-planner',
  templateUrl: './visual-planner.component.html',
  styleUrls: ['../app.component.css', './visual-planner.component.css', '../app-secondary.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VisualPlannerComponent implements OnInit, OnDestroy {
  mapDataSubscriber: Subscription;
  closeFilterAreaSubscription: Subscription;
  /**
   * It contains config data which passed from SmartBRICS.
   */
  public config: any = {};

  /**
   * Defines brick host directive view instance.
   */
  @ViewChild(BricHostDirective) bricHost: BricHostDirective;
  /**
   * Flag which defines filter area visible or not.
   */
  isFilterAreaVisible = true;
  /**
   * Flag which defines filter bar expanded or not.
   */
  isFilterBarExpanded = false;
  /**
   * It defines initial configuration data.
   */
  initialConfig: any = { userBundle: [] };
  /**
   * Defines left alignment for filter window.
   */
  public secondSliderLeft = '-330px';
  /**
   * Flag to define side bar is large or not.
   */
  public isLargeDetailSidebar = false;
  /**
   * Time to open for side bar.
   */
  private sliderTimer: any;

  /**
   * @description initial cart data on geomapper load
   * needed to compare on route change to detect change in data or not
   * @type {FrameModel[]}
   * @memberof AppComponent
   */
  initialCartData: FrameModel[] = [];

  /**
   * @description flag to check navigation allowed or not, used in can deactivate GM service in router
   * @type {boolean}
   * @memberof AppComponent
   */
  public allowNavigation = false;

  private subscriptions: Subscription[] = [];
  constructor(
    private router: Router,
    private dataShareService: DataShareService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private campaignService: CampaignService,
    private cartService: CartService,
    public concertinaCanvasService: ConcertinaCanvasService,
    private concertinaDataService: ConcertinaDataService,
    private stateService: StateService,
    private syncWsBricksService: SyncWsBricksService,
    private logHelperService: LogHelperService,
    private changeDetectorRef: ChangeDetectorRef,
    public swapZoneService: SwapZoneService
  ) {
    this.subscriptions.push(this.dataShareService.languageChangedSub.subscribe((result) => {
      if (result) {
        this.ngOnInit();
        this.changeDetectorRef.detectChanges();
      }
    }));
    this.campaignService.restoreVisualPlannerBackupData();
    this.mapDataSubscriber = this.cartService.cartData$
      .subscribe(this.cartDataSubscription);
    this.isFilterAreaVisible = true;
    this.closeFilterAreaSubscription = this.concertinaCanvasService.$toggleFilterAreaSub.subscribe((close) => {
      this.isFilterAreaVisible = close;
      this.toggleFilterArea();
    });
  }

  /**
   * @description callback function - called when user navigate to other screen
   * @author Alkesh Shah
   * @param {*} url
   * @returns
   * @memberof AppComponent
   */
  beforeNavigate(url) {
    return new Promise((resolve) => {
      this.campaignService.takeVisualPlannerBackupData();
      const originalCartData = JSON.stringify(this.initialCartData);
      const latestCartData = JSON.stringify(this.cartService.cartData);

      this.syncWsBricksService.getBricks().subscribe((res) => {
        if (res.status === 'OK') {
          // when geomapper cart is empty and user moves to other page
          // or when user delete all frames from cart and moves to other page
          // backend will not send data.
          // need to reset workspace
          if (!res.data) {
            res.data = {
              bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
              bricsData: [],
              campaignParameters: this.stateService.getWorkspaceObject('campaignParameters'),
              campaignSummary: [],
              columnConfig: {},
              columnHelper: {},
              columnLineNumber: {},
              columnSummary: {},
              readOnly: false,
              readOnlyWorkspace: false,
              splitable: false
            };
          }
          this.dataShareService.finalPrice = res.data.campaignSummary.map(summary => summary.finalPrice);
          if (originalCartData !== latestCartData) {
            SystemFlags.getBasketDataForGP = true;
            SystemFlags.isCampaignSummaryChanged = true;
            // SM-4348 - maintain reshuffle column if no change done in Campaign from VP
            const filterObj: Filter = this.stateService.getWorkspaceFilterObj();
            filterObj.reshuffle.reshufflingColumns = [];
            filterObj.toleranceLimit.cellAddress = [];
            this.stateService.setWorkspaceObject('filter', filterObj);
          } else {
            SystemFlags.isCampaignSummaryChanged = false;
          }
          this.syncWsBricksService.processCampaignLoad(res);

        } else {
          this.logHelperService.logError(res.message);
        }

        this.allowNavigation = true;
        this.router.navigate([url]);
        resolve(url);
      });

    });
  }

  /**
   * To load filter component data.
   * @param param Object which contains data for filter to be loaded
   */
  loadBrickDetailComponent(param: { component: Type<any>, resolveObject: any }) {
    clearTimeout(this.sliderTimer);

    this.isLargeDetailSidebar = false;
    if (param.resolveObject.isLargeSidebar) {
      this.isLargeDetailSidebar = true;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(param.component);

    const viewContainerRef = this.bricHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (componentRef.instance).resolveObject = param.resolveObject;
    (componentRef.instance).closeSidebar.subscribe(this.hideBrickDetSideBar);

    this.openFilterDetailSlider();
  }

  /**
   * To hide brick detail side bar.
   */
  hideBrickDetSideBar = () => {
    this.closeFilterDetailSlider();
    this.sliderTimer = setTimeout(() => {
      const viewContainerRef = this.bricHost.viewContainerRef;
      viewContainerRef.clear();
    }, 2000);
  }

  /**
   * To toggle filter area.
   */
  toggleFilterArea() {
    if (this.isFilterAreaVisible) {
      if (this.secondSliderLeft === '250px') {
        this.hideBrickDetSideBar();
      }
      document.body.className = document.body.className.replace('filter-open', '');
    } else {
      document.body.className = `${document.body.className}  filter-open `;
      if(this.swapZoneService.swapMode){
        this.swapZoneService.swapMode = false;
      }
    }
    this.isFilterAreaVisible = !this.isFilterAreaVisible;
    setTimeout(() => {
      this.concertinaCanvasService.fitStageIntoParentContainer();
    }, 800);
    this.concertinaCanvasService.onFilterAreaVisibilityChange(this.isFilterAreaVisible);
  }

  /**
   * Toggle filter bar.
   * @param value Boolean flag for expanded or not.
   */
  onFilterBarToggle(value) {
    this.isFilterBarExpanded = value;
  }

  /**
   * To open filter detail sidebar.
   */
  openFilterDetailSlider() {
    this.secondSliderLeft = '250px';
  }

  /**
   * To close filter detail sidebar.
   */
  closeFilterDetailSlider() {
    this.isLargeDetailSidebar = false;
    this.secondSliderLeft = '-330px';
  }

  ngOnInit() {
    this.dataShareService.appName = AppNameEnum.visualplanner;
    this.initialConfig = this.dataShareService.getInitialConfig();
    const campaignLoadObj = this.stateService.getVPObject('campaignLoad');
    const vpCommWithOtherPage = this.stateService.getVPObject('commWithOtherPage');
    if (SystemFlags.getBasketDataForVP) {
      vpCommWithOtherPage.refreshFilter = false;
    }
    this.config.refreshFilter = vpCommWithOtherPage.refreshFilter;
    this.config.isLoadedData = campaignLoadObj.isLoadData;
    this.config.onInitialCartDataSet = this.setInitialCartData;

    // if new need to reset VP
    if (campaignLoadObj.isResetCampaign) {
      this.cartService.setCartSummary({});
      this.cartService.rebuildCartData([]);
      this.concertinaDataService.setBasketMode(false);
      this.campaignService.clearFilters();
      this.campaignService.clearBackupFilters();
      this.concertinaCanvasService.showFI = false;
    }

    vpCommWithOtherPage.refreshFilter = false;
    this.stateService.setVPObject('commWithOtherPage', vpCommWithOtherPage);
    this.stateService.setVPObject('campaignLoad', {
      isLoadData: false,
      isResetCampaign: false
    });

    this.initialCartData = _.cloneDeep(this.cartService.cartData);
  }

  /**
   * @description set original initial cart data
   * @memberof VisualPlannerComponent
   */
  setInitialCartData = (data: FrameModel[]) => {
    this.initialCartData = _.cloneDeep(data);
  }
  /**
   * To redirect to Result page.
   */
  redirectToResultPage() {
    this.router.navigate(['/result']);
  }

  /**
   * Subscription function for map data.
   * @param mapData Data for the map.
   */
  cartDataSubscription = (data) => {
    this.dataShareService.activateResultTab((data.length > 0));
    this.dataShareService.activateCommercialTab((data.length > 0));
  }

  ngOnDestroy() {
    this.mapDataSubscriber.unsubscribe();
    this.closeFilterAreaSubscription.unsubscribe();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.concertinaDataService.setBasketMode(false);
  }

}
