import { Injectable } from '@angular/core';
import { getLocaleMonthNames, FormStyle, TranslationWidth, getLocaleDayNames, formatDate } from '@angular/common';

@Injectable()
export class NgbDatepickerI18 {

  getWeekdayShortName(weekday: number): string {
    const weekName = getLocaleDayNames('en-US', FormStyle.Format, TranslationWidth.Abbreviated);
    if (weekday === 7) {
      return weekName[0];
    }
    return weekName[weekday];
  }

  getMonthShortName(month: number): string {
    const monthNames = getLocaleMonthNames('en-US', FormStyle.Format, TranslationWidth.Abbreviated);
    return monthNames[month - 1];
  }

  getMonthFullName(month: number): string {
    const monthNames = getLocaleMonthNames('en-US', FormStyle.Format, TranslationWidth.Abbreviated);
    return monthNames[month - 1];
  }

  getDayAriaLabel(date) {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return formatDate(jsDate, 'fullDate', 'en-US');
  }

  getYearNumerals(year) { return `${year}`; }

  getWeekNumerals(weekNumber) { return `${weekNumber}`; }
}
