import { Injectable } from '@angular/core';

@Injectable()
export class DistanceConverterService {
  /**
   * Convert the value(Meter to KM)
   * @param meter value in meter
   * @return value in KM.
   */
  fromMeterToKm(meter: number): number {
    // Return Km
    const km = meter / 1000;
    return parseFloat(km.toFixed(2));
  }

  /**
   * Convert the value(miles to KM)
   * @param miles value in miles
   * @return value in KM.
   */
  fromMilesToKm(miles: number): number {
    // Return Km
    const km = miles * 1.60934;
    return parseFloat(km.toFixed(2));
  }

  /**
   * Convert the value(KM to meter)
   * @param km value in KM
   * @return value in meter
   */
  fromKmToMeter(km: number): number {
    // return Meter
    const meter = km * 1000;
    return parseFloat(meter.toFixed(2));
  }

  /**
   * Convert the value(miles to meter)
   * @param miles value in miles
   * @return value in meter
   */
  fromMilesToMeter(miles: number): number {
    // return Meter
    const meter = miles * 1609.34;
    return parseFloat(meter.toFixed(2));
  }

  /**
   * Convert the value(miles to meter)
   * @param miles value in miles
   * @return value in meter
   */
  fromFeetToMeter(feet: number): number {
    // return Meter
    const meter = feet * 0.3048;
    return parseFloat(meter.toFixed(2));
  }

  /**
   * Convert the value(KM to miles)
   * @param km value in KM
   * @return value in miles
   */
  fromKmToMiles(km: number): number {
    const miles = km * 0.621371;
    return parseFloat(miles.toFixed(4));
  }

  /**
   * Convert the value(Meter to miles)
   * @param meter value in meter
   * @return value in miles
   */
  fromMeterToMiles(meter: number): number {
    const miles = meter * 0.000621371;
    return parseFloat(miles.toFixed(4));
  }
}
