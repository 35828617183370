<ag-grid-angular class="ag-theme-fresh grid" [gridOptions]="gridOptions" [rowData]="rowData" [columnDefs]="columnDefs">
</ag-grid-angular>

<div class="col_select_menu">
    <div ngbDropdown class="d-inline-block" placement="left" [autoClose]="false" #toggleColumnContainer>
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
            <em class="fa fa-bars"></em>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="drop_container">
            <div class="checkbox clearBoth" (click)="clearAllFilters()">
                <label class="center-block" style="cursor: pointer">Clear all filters</label>
            </div>
            <div class="checkbox clearBoth" (click)="downloadExcel()">
                <label class="center-block" style="cursor: pointer">Excel Export</label>
            </div>
            <div class="checkbox clearBoth">
                <label class="center-block">Columns:</label>
            </div>
            <div class="checkCell checkbox clearBoth" *ngFor="let column of columnList; trackBy: trackByField;" [style.display]="column.colDef.field ? 'block' : 'none'">
                <label class="center-block">
                    <input type="checkbox" [checked]="column.visible" (change)="toggleColumnVisible(column.colDef.field, column.visible)" name="check-channel"
                        id="channel-{{column.colDef.field}}">
                    <i class="fa fa-check"></i>
                    <i class="fa fa-close"></i>
                    <span *ngIf="column.colDef.field == 'locked'">Locked</span>
                    <span *ngIf="column.colDef.field == 'warning'">Warning</span>
                    <span *ngIf="column.colDef.field != 'locked' && column.colDef.field != 'warning' ">{{column.colDef.headerName}}</span>
                </label>
            </div>
        </div>
    </div>
</div>
