import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {
  DataShareService,
  CellAttributeService,
  BrickBaseService,
  LogHelperService,
  MaskService,
  WorkspaceService
} from '../../../core/services';
import { CellValues } from '../../../models/workspace';
import { BudgetBase } from '../budget-base';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';
import { NumberMaskModel } from '../../../models/imask';
import { GLOBAL } from '../../../core/utils/app.constant';

@Component({
  selector: 'app-budget-modal',
  templateUrl: './budget-modal.component.html'
})
export class BudgetModalComponent extends BudgetBase implements OnInit {
  /**
   * It will take data to configure modal window.
   */
  @Input() resolveObject: CellValues;

  @ViewChild('defaultPrice') defaultPriceElementRef: ElementRef;
  @ViewChild('minPrice') minPriceElementRef: ElementRef;
  @ViewChild('maxPrice') maxPriceElementRef: ElementRef;
  @ViewChild('sotFloor') sotFloorElementRef: ElementRef;
  @ViewChild('sotCeiling') sotCeilingElementRef: ElementRef;
  readOnlyModal = false;

  maskService: MaskService = new MaskService();

  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof BudgetModalComponent
   */
  currencyInputMask: NumberMaskModel;

  /**
   * @description  Mask input config for Percentage
   * @type {NumberMaskModel}
   * @memberof BudgetModalComponent
   */
  percentageInputMask: NumberMaskModel;

  currencyAllocatedInputMask: NumberMaskModel;
  /**
   * @description disable fllor and ceiling input text box
   * @type {boolean}
   * @memberof BudgetModalComponent
   */
  disableFloorAndCeiling = false;

  /**
   * @description  Mask input config for tolerance Percentage field
   * @type {NumberMaskModel}
   * @memberof BudgetModalComponent
   */
  percentageToleranceInputMask: NumberMaskModel;

  /**
   * @description Mask input config for tolerance field, max 100 - 0 decimal
   * @type {NumberMaskModel}
   * @memberof BudgetModalComponent
   */
  numberInputMask: NumberMaskModel;
  public global = GLOBAL;
  localSolverEnabled: boolean;
  objectiveMode: boolean;
  isVioohAllocationEngineSelected = false;

  constructor(
    dataShareService: DataShareService,
    public logHelperService: LogHelperService,
    private cellAttributeService: CellAttributeService,
    public brickBaseService: BrickBaseService,
    public appHeaderService: AppHeaderService,
    public workspaceService: WorkspaceService
  ) {
    super(dataShareService, appHeaderService, logHelperService, brickBaseService, workspaceService);
    this.objectiveMode = this.appHeaderService.objectiveMode;
  }

  ngOnInit() {
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    const selectedValues = this.resolveObject.selectedValues && !this.resolveObject.selectedValues.hasOwnProperty('-99')
      ? this.resolveObject.selectedValues : {};
    this.localSolverEnabled = this.resolveObject.reshufflingEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_LS || this.resolveObject.reshufflingEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH || this.objectiveMode;

    this.init(this.resolveObject);
    if (this.resolveObject.sot) {
      this.disableFloorAndCeiling = this.resolveObject.sot.disableFloorAndCeiling;
    }
    this.setAllocateAllFrames(selectedValues);
    this.restoreFromProductValidation();

    this.budget.tolerance = this.budget.tolerance || this.dataShareService.getInitialConfig().uiControl.defaultTolerance; // SM-5016

    /* setFloorAndCeling method should be called if both the values are blank for normal mode
          or when the Product is used with Budget as the OptionalBric and Budget brick is dragged for the first time */
    if ((!this.budget.sotFloor && !this.budget.sotCeiling) ||
      (this.productValidations && (this.productValidations.sotFloor || this.productValidations.sotCeiling) && !this.resolveObject.isEditMode) ||
      (this.resolveObject.sot && this.resolveObject.sot.sotFloor && this.resolveObject.sot.sotCeiling)
    ) {
      this.setFloorAndCeling();
    }

    this.applyCurrencyMask();
    this.previousValues = Object.assign({},this.budget);
    this.isVioohAllocationEngineSelected = this.resolveObject.reshufflingEngine === 'VIOOH' ? true : false;
  }

  setAllocateAllFrames(selectedValues) {
    if (!this.pcmMode) {
      let allocateAllFrames = true;
      if (selectedValues.hasOwnProperty('allocateAllFrames')) {
        allocateAllFrames = selectedValues.allocateAllFrames;
      } else if (this.uiControl.hasOwnProperty('defaultAllocateAllFrames')) {
        allocateAllFrames = this.uiControl.defaultAllocateAllFrames;
      }
      selectedValues.allocateAllFrames = allocateAllFrames;
    }
  }

  restoreFromProductValidation() {
    if (this.productValidations && !this.resolveObject.isEditMode) {
      if (this.productValidations.sotFloor) {
        this.budget.sotFloor = this.productValidations.sotFloor;
      }

      if (this.productValidations.sotCeiling) {
        this.budget.sotCeiling = this.productValidations.sotCeiling;
      }
      if (this.productValidations.price) {
        this.budget.price = this.productValidations.price;
      }
      if (this.productValidations.allocateAllFrames) {
        this.budget.allocateAllFrames = this.productValidations.allocateAllFrames;
      }
    }

    if(this.productValidations && this.productValidations.sotIncrement){
      this.budget.sotIncrement = this.productValidations.sotIncrement;
    }
  }

  applyCurrencyMask() {
    const currencyInputMask: any = {
      max: 999999999999999999999,
      scale: 2
    };
    this.budget.tolerance = this.budget.tolerance || this.uiControl.defaultTolerance; // SM-5016
    this.setDefaultGreenTolerance();
    this.setDefaultOrangeTolerance();

    /* setFloorAndCeling method should be called if both the values are blank for normal mode
          or when the Product is used with Budget as the OptionalBric and Budget brick is dragged for the first time */
    if ((!this.pcmMode && !this.budget.sotFloor && !this.budget.sotCeiling) ||
      (this.productValidations && (this.productValidations.sotFloor || this.productValidations.sotCeiling) && !this.resolveObject.isEditMode) ||
      (this.resolveObject.sot && this.resolveObject.sot.sotFloor && this.resolveObject.sot.sotCeiling)
    ) {
      this.setFloorAndCeling();
    }

    if (this.productValidations && this.productValidations.allocateAllFrames) {
      this.budget.allocateAllFrames = this.productValidations.allocateAllFrames;
    }

    this.currencyInputMask = this.maskService.currencyInputMask(currencyInputMask);
    this.currencyAllocatedInputMask = this.maskService.currencyInputMask({
      max: 999999999999999999999,
      scale: 2
    });
    this.percentageInputMask = this.maskService.currencyInputMask({ min: 0, max: 100 });
    this.percentageToleranceInputMask = this.maskService.currencyInputMask({
      min: this.uiControl.toleranceMin ? this.uiControl.toleranceMin : 0,
      max: 100,
      scale: 0
    });
    this.numberInputMask = this.maskService.currencyInputMask({ max: 999999999999999999999, scale: 0, min: 0 });
  }

  calculateFloorAndCeiling() {
    if (!this.pcmMode && this.resolveObject.sot && !this.resolveObject.sot.mediaOnlyPaper) {
      this.setFloorAndCeling();
    }
  }

  onModalClosed(event) {
    if (event.reason === 'escape' && !(JSON.stringify(this.previousValues) == JSON.stringify(this.budget))) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  onModalSaved(event) {
    this.resetBudgetValue();
    if (!this.validateBudgetInputs()) {
      return;
    }
    if (this.budget.greenTolerance !== null
      && this.budget.orangeTolerance !== null
      && this.budget.greenTolerance > this.budget.orangeTolerance) {
      this.logHelperService.logError(this.userBundle['workspace.error.tolerance.orangeMustBeGreaterOrEqualToGreen'] || 'Orange Tolerance must be greater than or equal to Green Tolerance');
      return false;
    }
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;

    values.selectedValues = this.budget;
    if (this.uiControl.volumeRangeValidationEnabled) {
      if (this.sotFloorElementRef && this.sotCeilingElementRef.nativeElement.value === '') {
        values.selectedValues.sotCeiling = null;
      }
      if (this.sotFloorElementRef && this.sotFloorElementRef.nativeElement.value === '') {
        values.selectedValues.sotFloor = null;
      }
    } else {
      delete values.selectedValues.sotFloor;
      delete values.selectedValues.sotCeiling;
    }

    if (!this.uiControl.toleranceEnabled) {
      delete values.selectedValues.tolerance;
    }
    if (!values.selectedValues.tolerance) {
      values.selectedValues.allocated = 0;
    }
    values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Budget, values.selectedValues);
    values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Budget, values.selectedValues);
    values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Budget, values.selectedValues);
    this.previousValues = Object.assign({},this.resolveObject.selectedValues);

    event.activeModal.close(values);

  }

  private resetBudgetValue() {
    if (this.defaultPriceElementRef.nativeElement.value === '') {
      this.budget.price = null;
    }
    if (this.minPriceElementRef && this.minPriceElementRef.nativeElement.value === '') {
      this.budget.minPrice = null;
    }
    if (this.maxPriceElementRef && this.maxPriceElementRef.nativeElement.value === '') {
      this.budget.maxPrice = null;
    }
  }

  setFloorAndCeling() {
    if (this.uiControl.autoCalculateFloorAndCeiling) {
      this.budget.sotFloor = this.calculateFloor();
      this.budget.sotCeiling = this.calculateCeiling();
    }
  }

  /**
   * @description calculates ceiling value
   * @author Nishit Parekh
   * @returns ceiling value
   * @memberof BudgetModalComponent
   */
  calculateCeiling() {
    /* CEILING = MIN(FLOOR + 2 * SOT Swing, MAX value specified in PCM (if PCM used) )*/
    if (this.uiControl.autoCalculateFloorAndCeiling) {
      if (this.resolveObject.sot && this.resolveObject.sot.sotCeiling && this.budget.sotFloor) {
        this.disableFloorAndCeiling = this.resolveObject.sot.disableFloorAndCeiling;
        return Number(this.resolveObject.sot.sotCeiling);
      } else if (this.budget.sotFloor && (!this.resolveObject.sot.mediaOnlyPaper || this.pcmMode)) {
        const floorCeilingPrecision = this.uiControl.floorCeilingPrecision ? this.uiControl.floorCeilingPrecision : 2;
        const sotSwing = this.uiControl.sotSwing;
        return this.workspaceService.computeCeilingFromFormula(floorCeilingPrecision, sotSwing, this.budget.sotFloor, this.productValidations);
      } else if (this.resolveObject.sot && this.resolveObject.sot.mediaOnlyPaper) {
        return this.budget.sotCeiling == null ? 100 : Number(this.budget.sotCeiling);
      }
      return null;
    }
    return null;
  }
  /**
   * @description calculates floor value
   * @author Nishit Parekh
   * @returns calculated floor value
   * @memberof BudgetModalComponent
   */
  calculateFloor(): number {
    if (this.uiControl.autoCalculateFloorAndCeiling) {
      if (this.resolveObject.sot && this.resolveObject.sot.sotFloor) {
        this.disableFloorAndCeiling = this.resolveObject.sot.disableFloorAndCeiling;
        return Number(this.resolveObject.sot.sotFloor);
      } else if ((this.resolveObject.columnSummary && Object.keys(this.resolveObject.columnSummary).length) && (!this.resolveObject.sot.mediaOnlyPaper || this.pcmMode)) {
        const floorCeilingPrecision = this.uiControl.floorCeilingPrecision
          ? this.uiControl.floorCeilingPrecision : 2;
        return this.workspaceService.computeFloorFromFormula(floorCeilingPrecision,
          this.budget.price, this.uiControl.sotSwing, this.productValidations, this.resolveObject.columnSummary, 'price');
      } else if (this.resolveObject.sot && this.resolveObject.sot.mediaOnlyPaper) {
        return this.resolveObject.sot.sotFloor == null ? 1 : Number(this.resolveObject.sot.sotFloor);
      }
      return null;
    }
    return null;
  }

  updateAllocation(event) {
    if (this.productValidations && this.productValidations.maxPrice
      && this.resolveObject.columnSummaryWithoutBrick.price
      && this.resolveObject.columnSummaryWithoutBrick.price > this.productValidations.maxPrice
    ) {
      this.budget.allocateAllFrames = false;
      this.logHelperService.logError(this.userBundle['budget.error.allocationIsGreaterThanMax'] || 'Allocation would be greater than max');
      event.preventDefault();
    }
  }

  onChangeRelative(relative) {
    if (relative && this.budget.price > 100) {
      this.budget.price = null;
    }

    this.budget.allocated = null;
  }

  setDefaultGreenTolerance() {
    if (this.budget.greenTolerance === null || this.budget.greenTolerance === undefined) {
      this.budget.greenTolerance = this.uiControl.greenToleranceDefault || null;
    }
  }

  setDefaultOrangeTolerance() {
    if (this.budget.orangeTolerance === null || this.budget.orangeTolerance === undefined) {
      this.budget.orangeTolerance = this.uiControl.orangeToleranceDefault || null;
    }
  }
}
