import { Row } from './row';
import { Cell } from './cell';
import { Brick } from '../../workspace/brick-model';
import { CellPosition, ExpandDirection } from './cell-position';
import { ProcessBrick, ProcessParams, CampaignParameters } from './processBrick';
import { CellValues } from './cell-values';
import { InitialConfigModel, ColumnHelper, SOTBricSelectedDataModel } from '../../models';
import { StateService } from './../../core/services/state.service';
import { BrickBaseService } from './../../core/services/brick-base.service';
import { DataShareService } from './../../core/services/data-share.service';
import { LogHelperService } from './../../core/services/log-helper.service';
import { WorkspaceService } from './../../core/services/workspace.service';
import { HistoryStackService } from './../../core/services/history-stack.service';
import { SharedService } from './../../core/services/shared.service';
import { PcmService } from './../../core/services/pcm.service';
import { ColumnLock } from './column-lock';
import * as _ from 'lodash';
import { ColumnSummary } from './column-summary';
import { ColumnSummaryTooltip } from './column-summary-tooltip';
import { DatePipe } from '@angular/common';
import { LocaleData } from '../../core/utils/LocaleData';
import { LoadWorkspace } from '../../workspace/load-workspace';
import { CellAttributeService } from '../../core/services/cell-attributes.service';
import { ProductHelper } from './product';
import { SystemFlags } from '../system-flags';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { ProductCatalogue } from '../../models/productCatalogue';
import { List } from 'linqts';
import { HistoryStackData } from './historyStackData';
import * as moment from 'moment';
import { DateRange } from './range-window';
import { isNumber } from '../../core/utils/isNumber';
import { Reshuffle } from './reshuffle';
import { ToleranceLimit } from './tolerance-limit';
import { SotFloorCeilingDataModel } from '../MapDataRequest.model';
import { GLOBAL } from '../../core/utils/app.constant';
import { BricsMasterData, Category } from '../bricsMasterData';
import { Distribution } from '../../bricks/distribution/distribution-base';
import { ConfigUtils } from './../../core/utils/configUtils';
import { PopulateSelected } from '../../workspace/populate-selected';

export class Filter {

  /**
   * @description lock all button
   * @type {boolean}
   * @memberof Filter
   */
  lockAllButton = false;
  public rows: Row[];
  public brickRequestJSON = [];
  initialConfig: InitialConfigModel;
  brickBaseService: BrickBaseService = new BrickBaseService();
  public campaignParameters: CampaignParameters = new CampaignParameters();
  columnLock: ColumnLock;
  markSelectedCount = 0;
  actualBricsDeleted = 0;
  deletedColIndex: number[] = [];
  allocationEngines: string[] = [];
  productDetails: ProductHelper[] = [];
  productCatalogue: ProductCatalogue;
  /**
   * PCM Mode : Flag to identify the EditMode for Product
   * */
  productEditMode = false;
  isPastColumnHidden = false;
  userBundle: object;
  reshuffle: Reshuffle;
  toleranceLimit: ToleranceLimit;
  /**
   * @description Used to hold column summary before adding budget or volume
   * @memberof Filter
   */
  columnSummaryWithoutBrick = [];
  columnSummary: { [key: string]: ColumnSummary };

  /**
   * @description Holds bricsData of objective measures
   * @type {any[]}
   * @memberof Filter
   */
  objectiveMeasuresData: any[] = [];

  totalFtgIteration = 0;
  lastFtgIteration = 0;
  breakFtgIteration = false;
  ftgIterationAllowed = false;
  objectType = '';
  constructor(
    public stateService: StateService,
    private dataShareService: DataShareService,
    private logHelperService: LogHelperService,
    private workspaceService: WorkspaceService,
    private datePipe: DatePipe,
    private cellAttributeService: CellAttributeService,
    private historyStackService: HistoryStackService,
    private sharedService: SharedService,
    public pcmService: PcmService,
    private appHeaderService: AppHeaderService
  ) {
    this.columnLock = new ColumnLock(stateService, dataShareService, logHelperService, workspaceService);
    this.rows = [];
    this.deletedColIndex = [];
    this.initialConfig = this.dataShareService.getInitialConfig();
    const campaignParameters = this.stateService.getWorkspaceObject('campaignParameters');
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    if (Object.keys(campaignParameters).length > 0) {
      this.campaignParameters = campaignParameters;
    }
    this.reshuffle = new Reshuffle(this.stateService, this.logHelperService, this.brickBaseService, this.dataShareService);
    this.toleranceLimit = new ToleranceLimit(this.stateService, this.brickBaseService, this.dataShareService);
  }

  /**
   * @description Handles drop brick event
   * @author VJ, Amit Mahida
   * @param {Brick} brick
   * @param {number} [rowIndex=-1]
   * @param {number} [colIndex=-1]
   * @param {ExpandDirection} [expandDirection=ExpandDirection.None]
   * @param {boolean} [pcmMode=false]
   * @param {boolean} [isGhost=false]
   * @returns {CellPosition}
   * @memberof Filter
   */
  public dropBrick(brick: Brick, rowIndex = -1, colIndex = -1,
    expandDirection: ExpandDirection = ExpandDirection.None, pcmMode = false, isGhost = false): CellPosition {
    // Decide detect position
    const cellPosition: CellPosition = this.detectCellPosition(brick, rowIndex, colIndex, expandDirection, isGhost);

    // Check If Its allowed to be pushed at detected position
    const dropCondition: { isValid: boolean, note: string } =
      this.checkIfCellIsAllowedAtPosition(cellPosition, brick, pcmMode, expandDirection, true, colIndex);

    if (cellPosition.rowIndex !== -1 && cellPosition.cellIndex !== -1 && dropCondition.isValid) {
      return cellPosition;
    } else {
      cellPosition.cellIndex = -1;
      cellPosition.rowIndex = -1;
      cellPosition.note = dropCondition.note;
      return cellPosition;
    }
  }

  /**
   * @description General Drop rules: Every bric should check and pass all the cases before it gets dropped
   * @author Shivani Patel
   * @param {CellPosition} cellPosition
   * @param {Brick} brick
   * @returns {{ isValid: boolean, note: string }}
   * @memberof Filter
   */
  checkGeneralDropRules(cellPosition: CellPosition, brick: Brick, pcmMode = false): { isValid: boolean, note: string } {

    // If it is new row then use targetRowIndex else rowIndex
    const dropRowIndex = cellPosition.targetRowIndex > -1 && !this.isRowAvailable(brick.bricid) ? cellPosition.targetRowIndex : cellPosition.rowIndex;

    // If cell is dropped on other type of row, then its not allowed
    if (this.isRowAvailable(brick.bricid) && this.rows[dropRowIndex] && this.rows[dropRowIndex].bric.bricid !== brick.bricid) {
      return {
        note: this.userBundle['worksapce.error.bric.notAllowed'] || 'This brick is not allowed to drop here!',
        isValid: false
      };
    }

    // In Workspace, If current column is locked or past then do not allowed to add any brick in that column
    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
    if (!pcmMode && !this.appHeaderService.objectiveMode && columnHelper && columnHelper[cellPosition.cellIndex]) {
      // Added OR condition for SM-3983, Nishit
      // Added pcm condition for SM-4009 as in PCM mode there is no locked or past columns, Shivani
      return {
        note: this.userBundle['worksapce.drop.lockedorpast.error'],
        isValid: false
      };
    }

    // Do not allow to drop brick on left side of locked column if dropped cell is not Empty.
    // If expandDirection is left or right then current cell should be exist on the workspace.
    // Below code is commented to allow user to drop before locked column SM-4751
    // if (cellPosition.expandDirection !== ExpandDirection.None && this.isLeftSideOfLockedColumn(cellPosition.cellIndex)) {
    //   return {
    //     note: this.userBundle['worksapce.drop.brickonLeft.error'],
    //     isValid: false
    //   };
    // }

    // If Product exists in current column
    const productDetail: ProductHelper = this.getProductHelperIfProductExists(cellPosition.cellIndex);
    let productExistInCellIndex = false;
    if (productDetail) {
      for (let i = productDetail.columnIndex; i < (productDetail.columnIndex + productDetail.productWidth); i += 1) {
        if (cellPosition.cellIndex === i) {
          productExistInCellIndex = true;
        }
      }
    }
    if (productDetail && productExistInCellIndex) {
      // Do not allow to drop a brick to the column where product exist and it is not allowed in that product
      if (productDetail.optional[cellPosition.cellIndex].indexOf(brick.bricid) === -1) {
        return {
          note: this.userBundle['common.error.notallowed'],
          isValid: false
        };
      }

      // Do not allow to drop a brick on the left or right direction of a product column if the targeted cell is not Empty.
      if (
        cellPosition.expandDirection !== ExpandDirection.None
        && this.rows[dropRowIndex]
        && this.rows[dropRowIndex].cells[cellPosition.cellIndex]
        && !this.rows[dropRowIndex].cells[cellPosition.cellIndex].isEmpty) {
        return {
          note: this.userBundle['worksapce.drop.brickOnProduct.error'] || 'Can not add brick on the top of existing brick for Product Column',
          isValid: false
        };
      }
    }

    // Do not allow to drop any bric on the secondary bric.
    // Eg. It should not allow to drop any bric inbetween Incharge and Pattern
    if (this.rows[dropRowIndex] && !this.rows[dropRowIndex].bric.isprimary && !this.isRowAvailable(brick.bricid)) {
      return {
        note: this.userBundle['worksapce.error.bric.notAllowed'] || 'This brick is not allowed to drop here!',
        isValid: false
      };
    }

    return {
      note: '',
      isValid: true
    };
  }

  /**
  * @description This method will be called when allocation engine is clicked (SM-11461)
  * @author Siddharth Vaidya
  * @param {CellPosition} cellPosition
  * @param {Brick} brick
  */
  CheckIfMultitragetBrickIsAllowed(cellPosition?: CellPosition, brick?: Brick, colIndex?: number) {
    // When we drop multi target bric, check if multi targeting brics {this.brickBaseService.multiTargetBrics} are present on that index if yes dont allow to drop and increment celIndex
    const columns = this.rows[0].cells.length;
    let notAllowedIndex = [];
    notAllowedIndex.length = columns;
    let includesMTBwithReduction = [];
    for (let i = 0; i < columns; i++) {
      notAllowedIndex[i] = false;
      if (brick && this.getProductHelperIfProductExists(i) && !this.checkIfBricIsOptionalInProduct(i, brick)) {
        notAllowedIndex[i] = true;
        continue;
      }
      for (let index = 0; index < this.rows.length; index++) {
        for (let j = 0; j < this.rows.length; j++) {
          const k = this.rows[j];
          const element = this.rows[index];
          if (element.bric.bricid == this.brickBaseService.brickID.MultiTarget &&
            ((k.cells[colIndex].isEmpty && k.cells[colIndex].isHidden) || (!k.cells[colIndex].isEmpty && !k.cells[colIndex].isHidden))) {
            if (k.bric.bricid == this.brickBaseService.brickID.Volume ||
              k.bric.bricid == this.brickBaseService.brickID.Budget ||
              k.bric.bricid == this.brickBaseService.brickID.Frame) {
              includesMTBwithReduction[i] = true;
            }
          }
        }
      }
      if (includesMTBwithReduction[i]) {
        for (const row of this.rows) {
          if (row.cells[colIndex] &&
            ((this.brickBaseService.multiTargetBrics.includes(row.bric.bricid) && !(row.cells[colIndex].isEmpty && !row.cells[colIndex].isHidden)) ||
              (row.bric.bricid === this.brickBaseService.brickID.MultiTarget &&
                ((row.cells[colIndex].isEmpty && row.cells[colIndex].isHidden) || (!row.cells[colIndex].isEmpty && !row.cells[colIndex].isHidden))))
            && !this.checkIfReductionIsAllowedWithMTB(i, row)
          ) {
            notAllowedIndex[i] = true;
            break;
          }
        }
      }
    }
    notAllowedIndex = this.findNotAllowedIndex(notAllowedIndex);
    const cellIndex = notAllowedIndex.indexOf(false);
    if (cellPosition?.cellIndex) {
      cellPosition.cellIndex = cellIndex === -1 ? cellPosition?.cellIndex : cellIndex;
    }

    if (notAllowedIndex[colIndex] == true) {
      return {
        note: this.userBundle['common.error.allocation.engine.multitarget'] || 'Multi-target brick with Reduction brick is only valid for VAE engine.',
        isValid: false
      };
    }
  }
  /**
   * @description This method will check if brick is allowed or not to drop.
   * This method is used in PCM too so make sure to text any change in this method in both
   * @author Amit Mahida, VJ
   * @param {CellPosition} cellPosition
   * @param {Brick} brick
   * @param {boolean} [pcmMode=false]
   * @param {ExpandDirection} [expandDirection=ExpandDirection.None]
   * @returns {{ isValid: boolean, note: string }}
   * @memberof Filter
   */
  public checkIfCellIsAllowedAtPosition(cellPosition: CellPosition, brick: Brick,
    pcmMode = false, expandDirection: ExpandDirection = ExpandDirection.None, isNewBrickDrop = false, colIndex = -1)
    : { isValid: boolean, note: string } {
    // If it is new row then use targetRowIndex else rowIndex
    const dropRowIndex = cellPosition.targetRowIndex > -1 && !this.isRowAvailable(brick.bricid) ? cellPosition.targetRowIndex : cellPosition.rowIndex;
    const note = this.userBundle['worksapce.error.bric.notAllowed'] || 'This brick is not allowed to drop here!';
    const isMandatoryBricksAvailable = this.isMandatoryBricksAvailable();
    // General - Every bric should check and pass all the cases before it gets dropped.
    const validateGeneralRules = this.checkGeneralDropRules(cellPosition, brick, pcmMode);
    if (!validateGeneralRules.isValid) {
      return validateGeneralRules;
    }

    if (brick.bricid === this.brickBaseService.brickID.Incharge) { // If dragged bric is Incharge
      if (pcmMode) {
        // Only one Incharge is allowed in PCM mode
        const inchargeRow = this.getRowByBrickId(this.brickBaseService.brickID.Incharge);
        if (inchargeRow) {
          return {
            note: this.userBundle['worksapce.error.bric.multipleIncharge'] || 'Multiple Incharge Bric not allowed',
            isValid: false
          };
        }
      } else {
        // If Incharge is dropped on top of existing Incharge in left side, and that column has pattern then its not allowed
        const patternRow = this.getRowByBrickId(Number(this.brickBaseService.brickID.Pattern));
        if (expandDirection === ExpandDirection.Left && patternRow && isNewBrickDrop) {
          return {
            note: this.userBundle['workspace.error.insert'],
            isValid: false
          };
        }
      }
    } else if (brick.bricid === this.brickBaseService.brickID.Pattern) {
      const inchargeRow = this.getRowByBrickId(Number(this.brickBaseService.brickID.Incharge));

      // Pattern brick is not allowed without Incharge
      if (!(inchargeRow && inchargeRow.cells && inchargeRow.cells[Number(cellPosition.cellIndex)])) {
        return {
          note: this.userBundle['workspace.error.pattern.withoutrange'],
          isValid: false
        };
      }

      // If required brics are missing
      if (!isMandatoryBricksAvailable) {
        return {
          note: this.userBundle['workspace.error.missingRequiredBricks'],
          isValid: false
        };
      }
      // Pattern brick must be added after Incharge
      if (dropRowIndex <= inchargeRow.index) {
        return {
          note: this.userBundle['workspace.error.pattern.addincharge'],
          isValid: false
        };
      }

      // Do not allow to drop new Pattern bric on left or right side of existing one
      if (isNewBrickDrop && cellPosition.expandDirection !== ExpandDirection.None
        && this.isRowAvailable(this.brickBaseService.brickID.Pattern)) {
        // SM-3558 Angular 5 : User is able to Drag & Drop new Pattern Bric on already dropped Pattern Bric
        // First condition is to check if you are dropping bric drop zone or particular cell
        // 2nd condition is for pattern row already created or not
        return {
          note,
          isValid: false
        };
      }

      if (this.brickBaseService.brickID.Objective === brick.bricid) {
        // GISGO allocation engine can not have Objective brick, SM-6264
        if (this.allocationEngines[cellPosition.cellIndex] === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_GISGO) {
          return {
            note,
            isValid: false
          };
        }
      }

      const error = this.checkIfMultiTargetBricAllowedToDrop(cellPosition, this.brickBaseService.brickID.Pattern, true);
      if (error) {
        return error;
      }

    } else if (this.isQuantityBric(brick.bricid)) {
      // Quantity brick is not allowed without having any existing column, means it can not be added at last cell index if its not exists.
      if (
        !this.appHeaderService.objectiveMode
        && (this.getMaxCellIndex() < cellPosition.cellIndex
          || (this.rows[cellPosition.rowIndex] && this.rows[cellPosition.rowIndex].cells[cellPosition.cellIndex]
            && this.rows[cellPosition.rowIndex].cells[cellPosition.cellIndex].isEmpty && this.rows[cellPosition.rowIndex].cells[cellPosition.cellIndex].isHidden))
      ) {
        return {
          note,
          isValid: false
        };
      }

      if (this.initialConfig.uiControl.allowSpotAllocation && this.allocationEngines[cellPosition.cellIndex] === 'VIOOH') {
        let isDistributionBrickAvailable = false;
        this.rows.forEach((row: Row) => {
          for (let j = 0; j <= this.getMaxCellIndex(); j++) {
            if ((j === cellPosition.cellIndex && !row.cells[j].isEmpty) || (j === cellPosition.cellIndex && row.cells[j].isEmpty && row.cells[j].isHidden)) {
              if (row.bric.bricid === this.brickBaseService.brickID.Distribution && (brick.bricid !== 9)) {
                isDistributionBrickAvailable = true;
                break;
              }
            }
          }
        });
        if (isDistributionBrickAvailable) {
          return {
            note: this.userBundle['worksapce.drop.spotAllocation.error'] || 'Distribution brick and reduction brick can not be dropped in the same column',
            isValid: false
          };
        }
      }

      // ALL SOT RELATED CASES WILL BE ADDED INSIDE BELOW BLOCK
      if (brick.bricid === this.brickBaseService.brickID.SOT) {
        // SOT brick is not allowed to drop (left side) on existing SOT brick
        if (
          this.isRowAvailable(brick.bricid)
          && this.rows[dropRowIndex].cells[cellPosition.cellIndex]
          && !this.rows[dropRowIndex].cells[cellPosition.cellIndex].isEmpty
          && expandDirection !== ExpandDirection.Right) {
          return {
            note: this.userBundle['common.error.notallowed'],
            isValid: false
          };
        }
      } else { // ALL QTY EXCEPT SOT RELATED CASES WILL BE ADDED INSIDE THIS else
        // Quantity brick is not allowed to drop on existing Quantity brick (Except SOT)
        const isReductionBric = this.isReductionBric(brick.bricid);

        if (
          this.isRowAvailable(brick.bricid)
          && this.rows[dropRowIndex].cells[cellPosition.cellIndex]
          && !this.rows[dropRowIndex].cells[cellPosition.cellIndex].isEmpty && !this.rows[dropRowIndex].cells[colIndex].isEmpty) {
          return {
            note: this.userBundle['common.error.notallowed'],
            isValid: false
          };
        }
      }

      const error = this.checkIfMultiTargetBricAllowedToDrop(cellPosition, brick.bricid);
      if (error) {
        return error;
      }

    } else if (brick.bricid === this.brickBaseService.brickID.ProductCatalogue) {
      if (pcmMode) {
        // Product Catalogue bric can only be dropped on empty Worksapce
        if (this.rows.length) {
          // SM-3548
          return {
            note: this.userBundle['workspace.error.productCatalogue.emptyWorksapce'] || 'Product Catalogue bric can only be dropped on empty Worksapce',
            isValid: false
          };
        }
      } else {
        const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
        // Product Bric can be dropped after incharge brick, no need to place mandatory bricks
        // Multiple Products should be allowed to be placed under same incharge. Incharge brick will be stretched in such cases.
        if (!this.isRowAvailable(Number(this.brickBaseService.brickID.Incharge))) {
          return {
            note: this.userBundle['workspace.error.productCatalogue.addincharge'] || 'Incharge brick should be present on the workspace.',
            isValid: false
          };
        } else if (columnHelper && Object.keys(columnHelper).length) {
          const cellIndex = this.getCellIndexAgainstProduct(cellPosition.cellIndex, brick.bricid);
          // cellIndex == -1 OR if last column has range bric disabled, dont allow to drop product.
          const incharge = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Incharge, this.rows);
          if (cellIndex === -1 || (cellIndex === this.rows[0].cells.length && this.rows[incharge.Available_row_Index].cells[incharge.Available_lastcell_Index].isLocked)) {
            return {
              note: this.userBundle['worksapce.drop.lockedorpast.error'],
              isValid: false
            };
          }
          cellPosition.cellIndex = cellIndex;
        }
      }
      const error = this.checkIfMultiTargetBricAllowedToDrop(cellPosition, this.brickBaseService.brickID.ProductCatalogue, true);
      if (error) {
        return error;
      }
    } else if (brick.bricid === this.brickBaseService.brickID.Network) {
      // Network Bric is not allowed for ongoing campaign with past date columns
      if (this.isNonEditableColsPresent()) {
        return {
          note: this.userBundle['worksapce.error.AudienceNetwork.ongoingCampaign'] || 'Audience bric or Network Bric is not allowed for ongoing campaign with past date columns!',
          isValid: false
        };
      }

      // Network Bric can be dropped After incharge is placed
      if (!this.isRowAvailable(Number(this.brickBaseService.brickID.Incharge))) {
        return {
          note: this.userBundle['workspace.error.Network.addincharge'] || 'Please add Incharge bric to drop Network bric',
          isValid: false
        };
      }

      // Network Bric can not be dropped If more than one incharge is present (If incharge is stretched, it is fine)
      if (!this.checkIfOnlyOneInchargeExist()) {
        return {
          note: this.userBundle['workspace.error.network.onlyOneIncharge'] || 'Only one Incharge bric should be present to drop Network bric',
          isValid: false
        };
      }

      // Network Bric can not be dropped If the locked columns present on the workspace
      if (this.isLockedColsPresent()) {
        return {
          note: this.userBundle['worksapce.unlock.error'],
          isValid: false
        };
      }
    } else if (brick.bricid === this.brickBaseService.brickID.FrameQuality) {
      // Frame Quality should be dropped on the column if there is at least one quantity brick available
      if (!this.checkIfAtleastOneQtyBrickExist(cellPosition.cellIndex)) {
        return {
          note: this.userBundle['workspace.error.framequality.validation'],
          isValid: false
        };
      }

      const error = this.checkIfMultiTargetBricAllowedToDrop(cellPosition, this.brickBaseService.brickID.FrameQuality, true);
      if (error) {
        return error;
      }
    } else if (brick.bricid === this.brickBaseService.brickID.Audience
      || brick.bricid === this.brickBaseService.brickID.SecondaryAudience
      || brick.bricid === this.brickBaseService.brickID.PrimaryAudience
    ) {
      // Audience brics are not allowed for ongoing campaign with past date columns!
      const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
      if (Object.keys(columnHelper).length) {
        return {
          note: this.userBundle['worksapce.error.AudienceNetwork.ongoingCampaign'] || 'Audience bric or Network Bric is not allowed for ongoing campaign with past date columns!',
          isValid: false
        };
      }

      // If audience is optional in all already dropped products then only allow to drop
      let audienceAllowedInAllProduct = true;
      for (const productDetail of this.productDetails) {
        for (const key in productDetail.optional) {
          if (productDetail.optional.hasOwnProperty(key)) {
            const optionalBrics = productDetail.optional[key];
            if (optionalBrics.indexOf(brick.bricid) === -1) {
              audienceAllowedInAllProduct = false;
            }
          }
        }
        if (!audienceAllowedInAllProduct) {
          break;
        }
      }
      if (!audienceAllowedInAllProduct) {
        return {
          note: this.userBundle['workspace.error.audienceNotAllowedInAProduct'] || 'Audience brick in not allowed in one or more of the available products',
          isValid: false
        };
      }

      // Only one Audience brick should be allowed
      // VJ: added AllAudience Condition since Thomas has reported a case in slack where ID of SecondaryAudience is AllAudience so should not check for AllAudience for the limit
      // This is for AE.INT environment
      // DT 09-10-2019
      if (brick.bricid !== this.brickBaseService.brickID.AllAudience && (this.isRowAvailable(Number(this.brickBaseService.brickID.Audience)) || this.isRowAvailable(Number(this.brickBaseService.brickID.PrimaryAudience))
        || this.isRowAvailable(Number(this.brickBaseService.brickID.SecondaryAudience)))) {
        return {
          note: this.userBundle['workspace.error.limitAudience'],
          isValid: false
        };
      }

      // Do not allow Audience & Secondary Audience if the required brics are missing on same column on the workspace.
      if (!isMandatoryBricksAvailable) {
        return {
          note: this.userBundle['workspace.error.missingRequiredBricks'],
          isValid: false
        };
      }
      const colConfig = pcmMode ? this.stateService.getPCMObject('columnConfig') : this.stateService.getWorkspaceObject('columnConfig');
      // we do not know which data source to pass as there will be multiple data source for these brics // Nishit
      if (colConfig && !colConfig[cellPosition.cellIndex]) {
        return {
          note: this.userBundle['workspace.error.invalidBricks'],
          isValid: false
        };
      }
      // Do not allow Audience & Secondary Audience when there are non-editable/locked columns present on the workspace.
      if (this.isLockedColsPresent()) {
        return {
          note: this.userBundle['worksapce.unlock.error'],
          isValid: false
        };
      }

      // Validate Audience brics against column config
      if (brick.bricid === this.brickBaseService.brickID.Audience && colConfig[cellPosition.cellIndex]
        && colConfig[cellPosition.cellIndex].audienceCategoryGroupId !== brick.audienceCategoryGroupId
      ) {
        return {
          note: this.userBundle['workspace.error.incorrectAudience'],
          isValid: false
        };
      }

      // Validate All Audience Brick against audienceCategoryGroup and secondaryAudienceGroups//
      if (brick.bricid === this.brickBaseService.brickID.AllAudience
        && colConfig[cellPosition.cellIndex]
        && this.initialConfig.uiControl.secondaryAudienceGroups
      ) {
        let exists = false;
        this.initialConfig.uiControl.secondaryAudienceGroups.forEach((element) => {
          this.initialConfig.audienceCategoryGroup.forEach((audienceCategoryGroup) => {
            if (audienceCategoryGroup.audienceCategoryGroupId === element) {
              exists = true;
            }
          });
        });
        if (exists === false) {
          return {
            note: this.userBundle['workspace.error.incorrectAudience'],
            isValid: false
          };
        }
      }

      // Validate SecondaryAudience brics against column config
      if (brick.bricid === this.brickBaseService.brickID.SecondaryAudience && colConfig[cellPosition.cellIndex]
        && colConfig[cellPosition.cellIndex].secondaryAudience.indexOf(brick.audienceCategoryGroupId) === -1
      ) {
        return {
          note: this.userBundle['workspace.error.incorrectAudience'],
          isValid: false
        };
      }

      if (brick.bricid === this.brickBaseService.brickID.PrimaryAudience && this.validateProductAgainstPrimaryAudience()) {
        return {
          note: this.userBundle['workspace.error.notAllowedBrick'],
          isValid: false
        };
      }
    } else if ((brick.bricid === this.brickBaseService.brickID.Tag) // || brick.bricid === this.brickBaseService.brickID.Channel) SM-8432
      && !pcmMode
      && !this.appHeaderService.objectiveMode
      && cellPosition.cellIndex <= this.getMaxCellIndex()) {
      // In PCM mode, Tag/Channel bric always allowed
      // Check all the rules for Tag and Channel bric only if dropped column is already exist
      const validity = {
        note: brick.bricid === this.brickBaseService.brickID.Tag
          ? this.userBundle['workspace.error.tag.drop']
          : this.userBundle['workspace.error.notAllowedBrick'],
        isValid: false
      };

      const colConfig = this.stateService.getWorkspaceObject('columnConfig');
      if (!colConfig) {
        return validity;
      }

      const groupData = this.sharedService.getTagDataFromColumnConfig(
        brick.bricid,
        colConfig[cellPosition.cellIndex] ? colConfig[cellPosition.cellIndex] : colConfig,
        brick.bricid === this.brickBaseService.brickID.Tag
          ? _.cloneDeep(this.initialConfig.tagGroup)
          : _.cloneDeep(this.initialConfig.productGroup)
      );
      if (!groupData || _.isEmpty(groupData)) {
        return validity;
      }
    } else if (brick.bricid === this.brickBaseService.brickID.AllAudience) {
      let audienceCategoryGroup = [];
      if (this.initialConfig.uiControl.secondaryAudienceGroups && this.initialConfig.uiControl.secondaryAudienceGroups.length) {
        this.initialConfig.uiControl.secondaryAudienceGroups.forEach((id) => {
          audienceCategoryGroup = this.initialConfig.audienceCategoryGroup.filter((item) => {
            return id === item.audienceCategoryGroupId;
          });
        });
      }
      if (audienceCategoryGroup && !audienceCategoryGroup.length) {
        return {
          note: this.userBundle['workspace.error.notAllowedBrick'],
          isValid: false
        };
      }
    } else if (brick.bricid === this.brickBaseService.brickID.MultiTarget) {
      if (this.rows && !this.rows.length) {
        return {
          note: this.userBundle['workspace.error.missingRequiredBricks'],
          isValid: false
        };
      }
      // When we drop multi target bric, check if multi targeting brics {this.brickBaseService.multiTargetBrics} are present on that index if yes dont allow to drop and increment celIndex
      const columns = this.rows[0].cells.length;
      let notAllowedIndex = [];
      notAllowedIndex.length = columns;

      for (let i = 0; i < columns; i++) {
        notAllowedIndex[i] = false;
        if (this.getProductHelperIfProductExists(i) && !this.checkIfBricIsOptionalInProduct(i, brick)) {
          notAllowedIndex[i] = true;
          continue;
        }
        for (const row of this.rows) {
          if (row.cells[i] &&
            ((this.brickBaseService.multiTargetBrics.includes(row.bric.bricid) && !(row.cells[i].isEmpty && !row.cells[i].isHidden)) ||
              (row.bric.bricid === this.brickBaseService.brickID.MultiTarget &&
                ((row.cells[i].isEmpty && row.cells[i].isHidden) || (!row.cells[i].isEmpty && !row.cells[i].isHidden))))
            && !this.checkIfReductionIsAllowedWithMTB(i, row)
          ) {
            notAllowedIndex[i] = true;
            break;
          }
        }
      }

      notAllowedIndex = this.findNotAllowedIndex(notAllowedIndex);
      const cellIndex = notAllowedIndex.indexOf(false);
      cellPosition.cellIndex = cellIndex === -1 ? cellPosition.cellIndex : cellIndex;

      if (notAllowedIndex.filter(e => e === true).length === columns) {
        return {
          note: this.userBundle['common.error.drop.multitarget'] || 'Multi-Target bric cannot be dropped on colum with Pattern/Time/Distribution/Product bric.',
          isValid: false
        };
      }
    } else if (brick.bricid === this.brickBaseService.brickID.Distribution) {
      if (this.initialConfig.uiControl.allowSpotAllocation && this.allocationEngines[cellPosition.cellIndex] === 'VIOOH') {
        let isReductionBricAvailable = false;
        this.rows.forEach((row: Row) => {
          for (let j = 0; j <= this.getMaxCellIndex(); j++) {
            if ((j === cellPosition.cellIndex && !row.cells[j].isEmpty) || (j === cellPosition.cellIndex && row.cells[j].isEmpty && row.cells[j].isHidden)) {
              isReductionBricAvailable = this.isQuantityBrick(row.bric.bricid);
              if (isReductionBricAvailable) {
                break;
              }
            }
          }
        });
        if (isReductionBricAvailable) {
          return {
            note: this.userBundle['worksapce.drop.spotAllocation.error'] || 'Distribution brick and reduction brick can not be dropped in the same column',
            isValid: false
          };
        }
      }
      const error = this.checkIfMultiTargetBricAllowedToDrop(cellPosition, this.brickBaseService.brickID.Distribution, true);
      if (error) {
        return error;
      }
    } else if (brick.bricid === this.brickBaseService.brickID.List) {
      const error = this.checkIfMultiTargetBricAllowedToDrop(cellPosition, this.brickBaseService.brickID.List, true);
      if (error) {
        return error;
      }
    }

    // SM-3559 If required brics are missing and drop a bric(except required and special brics) directly on particular empty cell.
    const allowedBrics = _.union(this.initialConfig.uiControl.processingBrics, this.initialConfig.uiControl.optionalBrics);
    if (!this.appHeaderService.objectiveMode
      && cellPosition.rowIndex !== -1 && cellPosition.cellIndex !== -1
      && !isMandatoryBricksAvailable && allowedBrics.indexOf(brick.bricid) === -1) {
      return {
        isValid: false,
        note: this.userBundle['workspace.error.missingRequiredBricks']
      };
    }

    return {
      note: '',
      isValid: true
    };
  }

  /**
   * This method checks if there is already reduction brick in the column and allocation engine is VIOOH
   * implemented for SM-11079 to allow MTB with reduction brick if allocation engine is VIOOH
   * @param columnIndex -  index of the coulmn
   * @param row - current row of th column
   */
  checkIfReductionIsAllowedWithMTB(columnIndex, row): boolean {
    let isAllowed = false;
    const isAllowedWithEngine = this.isDropAllowedWithEngine(columnIndex, row.bric.bricid);
    if (row.cells[columnIndex] && isAllowedWithEngine &&
      !(row.cells[columnIndex].isEmpty && !row.cells[columnIndex].isHidden)) {
      isAllowed = true;
    }
    return isAllowed;
  }

  checkIfMultiTargetBricAllowedToDrop(cellPosition: CellPosition, bricId: string | number, updateCellPosition = false) {
    const notAllowedIndex = this.multiTargetBricDropRestrict(bricId);
    if (notAllowedIndex.length && notAllowedIndex.every(e => e === true) || (!updateCellPosition && notAllowedIndex[cellPosition.cellIndex])) {
      return {
        note: this.userBundle['common.error.drop.multitargeting'] || 'Pattern/Time/Distribution/Product bric cannot be dropped on column with Multi-Target bric.',
        isValid: false
      };
    } else {
      const cellIndex = notAllowedIndex.indexOf(false);
      cellPosition.cellIndex = cellIndex > -1 && updateCellPosition ? cellIndex : cellPosition.cellIndex;
    }
  }

  /**
   * @description If multi target bric is present in the column, dont allow to dorp Multi targeting brics like sot/pattern/list etc
   * @returns {*} indices of allowed broc drop index
   * @memberof Filter
   */
  multiTargetBricDropRestrict(bricId): boolean[] {
    const multiTargetRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.MultiTarget, this.rows).Available_row_Index;
    const multiTargettingBric = this.workspaceService.isBricRowAvailable(bricId, this.rows).Available_row_Index;
    const notAllowedIndex: boolean[] = [];
    if (multiTargetRowIndex !== null) {
      notAllowedIndex.length = this.rows[0].cells.length;
      for (let index = 0; index < notAllowedIndex.length; index++) {
        notAllowedIndex[index] = false;
        // if bric is on that column or its srtetch
        const isEmpty = this.rows[multiTargetRowIndex].cells[index].isEmpty;
        const isHidden = this.rows[multiTargetRowIndex].cells[index].isHidden;
        // one of the multi Targetting Bric
        let bricEmpty = null;
        let bricHidden = null;
        let hasFrameBrick = null;
        if (multiTargettingBric !== null) {
          bricEmpty = this.rows[multiTargettingBric].cells[index].isEmpty;
          bricHidden = this.rows[multiTargettingBric].cells[index].isHidden;
          hasFrameBrick = !_.isEmpty(this.rows[multiTargettingBric].cells[index].selected)
        }

        // SM-11079 - check for the reduction brick and VAE engine to allow drop
        const isDropAllowed = this.isDropAllowedWithEngine(index, bricId);
        if ((((isEmpty && isHidden) || (!isEmpty && !isHidden)) || (multiTargettingBric !== null && ((bricEmpty && bricHidden) || (!bricEmpty && !bricHidden)))) && !isDropAllowed || hasFrameBrick) {
          notAllowedIndex[index] = true;
        }
      }
      return this.findNotAllowedIndex(notAllowedIndex);
    }
    return notAllowedIndex;
  }


  /**
   * Check if the drop for the reduction brick is allowed with the current allocation engine for the column
   * @param index - index of the column
   * @param bricId - id of the brick dropped
   */
  isDropAllowedWithEngine(index, bricId) {
    const allocationEngine = this.allocationEngines[index];
    let isDropAllowed = false;
    if (((bricId === this.brickBaseService.brickID.Volume || bricId === this.brickBaseService.brickID.Frame || bricId === this.brickBaseService.brickID.Budget))
      && allocationEngine === 'VIOOH' &&
      (!this.appHeaderService.enabledPCM && !this.appHeaderService.objectiveMode)) {
      isDropAllowed = true;
    }
    return isDropAllowed;
  }

  /**
   * @description Multi Target Brics and Locked columns calculations
   * @param {boolean[]} notAllowedIndex
   * @returns {*}  {boolean[]}
   * @memberof Filter
   */
  findNotAllowedIndex(notAllowedIndex: boolean[]): boolean[] {
    const helper: ColumnHelper = this.stateService.columnHelper;
    if (helper && Object.keys(helper).length) {
      notAllowedIndex = notAllowedIndex.map((e, index) => helper[index] ? helper[index].userLocked || helper[index].systemLocked || e : e);
    }
    return notAllowedIndex;
  }

  /**
   * @description Returns true if given cell is left side of the locked column.
   * @author Shivani Patel
   * @param {number} colIndex
   * @returns {boolean}
   * @memberof Filter
   */
  isLeftSideOfLockedColumn(colIndex: number): boolean {
    const totalColumns: number = this.getMaxCellIndex() + 1;
    for (let cellIndex: number = colIndex; cellIndex < totalColumns; cellIndex++) {
      const row: Row = this.rows.find((row: Row) => row.cells[cellIndex].cellWidth === 1 && !row.cells[cellIndex].isEmpty && row.cells[cellIndex].isLocked);
      if (row) {
        return true;
      }
    }
    return false;
  }

  /**
   * @description Method to detect possible position to add new brick
   * @author Vijay Sutaria
   * @param {Brick} brick
   * @param {number} [rowIndex=-1] if cell is dropped on top of any existing row
   * @param {number} [colIndex=-1] if cell is dropped on top of any existing cell
   * @param {ExpandDirection} [expandDirection=ExpandDirection.None]
   * @param {boolean} [isGhost=false]
   * @returns {CellPosition} returns an object for the position where Cell can be added
   * @memberof Filter
   */
  public detectCellPosition(brick: Brick, rowIndex = -1, colIndex = -1,
    expandDirection: ExpandDirection = ExpandDirection.None, isGhost = false): CellPosition {
    const cellPosition: CellPosition = new CellPosition();
    cellPosition.targetRowIndex = rowIndex === null ? -1 : rowIndex;
    cellPosition.expandDirection = ExpandDirection.None;

    // Check If row already exists
    const existingRowIndex: number = this.getExistingRowIndex(brick.bricid);
    if (rowIndex >= 0 && rowIndex !== existingRowIndex && !isGhost) {
      // New cell is dropped on existing row but not of same type
      cellPosition.cellIndex = existingRowIndex === -1 ? 0 : this.rows[existingRowIndex].lastEmptyCellIndex();
      cellPosition.rowIndex = existingRowIndex === -1 ? this.rows.length : existingRowIndex;
    } else if (existingRowIndex === -1) {
      // Row not exists
      // const row = new Row();
      // row.index = this.rows.length;
      cellPosition.rowIndex = this.rows.length;
      cellPosition.cellIndex = !isGhost ? 0 : colIndex;
    } else {
      // This means Cell is dropped on existing cell, which may not be empty
      cellPosition.rowIndex = existingRowIndex;
      if (colIndex > -1) {
        // Dropped on existing cell, If its empty drop it, otherwise need to stretch same column in other rows
        cellPosition.cellIndex = colIndex;
        if (this.rows[existingRowIndex].cells[colIndex] && !this.rows[existingRowIndex].cells[colIndex].isEmpty) {
          cellPosition.expandDirection = expandDirection;
        }
      } else {
        cellPosition.cellIndex = this.rows[existingRowIndex].lastEmptyCellIndex();
      }
    }

    /**
     * Should allow to drop Pattern bric at a specific column
     * 1) If Pattern bric row does not exist on the workspace
     * 2) If Pattern bric row exist and the cell (with current dropped column index) of the same row is empty
     */
    if (brick.bricid === this.brickBaseService.brickID.Pattern) {
      // Pattern row should be next to incharge
      cellPosition.rowIndex = this.getExistingRowIndex(Number(this.brickBaseService.brickID.Incharge)) + 1;

      // Clear the target row as Pattern brick always next to the Incharge
      cellPosition.targetRowIndex = -1;

      if (colIndex >= 0
        && (existingRowIndex === -1 || (this.rows[existingRowIndex].cells[colIndex].isEmpty && !this.rows[existingRowIndex].cells[colIndex].isHidden))) {
        cellPosition.cellIndex = colIndex;
      }
    }

    /**
     * Product and it's mandatory rows always comes after the Incharge bric row so we'll always use detected rowIndex
     */
    if (brick.bricid === this.brickBaseService.brickID.ProductCatalogue) {
      // Clear the target row
      cellPosition.targetRowIndex = -1;
    }

    if (brick.bricid === this.brickBaseService.brickID.Objective) {
      // Objective brick is not allowed in column where allocation engine is GISGO
      while (this.allocationEngines[cellPosition.cellIndex] && this.allocationEngines[cellPosition.cellIndex] === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_GISGO) {
        cellPosition.cellIndex += 1;
      }
    }
    if (this.isRowAvailable(Number(this.brickBaseService.brickID.MultiTarget)) && this.isQuantityBric(brick.bricid)) {
      // MultiTarget row is present
      this.checkIfMultiTargetBricAllowedToDrop(cellPosition, brick.bricid, true);
    }
    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();

    // Below logic is to increment cell index by 1
    // All below cases will work only when we have next column available, otherwise it will consider current column only
    // if current column is locked, and we have next column
    // if current column has product and we have next column
    // if current column has product, and current bric is not part of optional array
    // if current column is not editable
    // No need to execute below logic if its Audience Brick, Mobile Brick(secondary audience)
    // added isBricRowAvailable... condition for SM-4453 (product)
    // Added a new condition `this.appHeaderService && this.appHeaderService.enabledPCM` SM-4515
    // Added  (this.rows[0].cells[tmpIndex + 1] !== undefined)
    let tmpIndex = cellPosition.cellIndex;
    while (this.rows.length > 0 && this.rows[0].cells[tmpIndex] && expandDirection === ExpandDirection.None &&
      ((this.isLockedColsPresent(tmpIndex))
        || (this.getProductHelperIfProductExists(tmpIndex) &&
          !this.checkIfBricIsOptionalInProduct(tmpIndex, brick)
        )
        || (!(this.appHeaderService && (this.appHeaderService.enabledPCM || this.appHeaderService.objectiveMode)) && columnHelper && Object.keys(columnHelper).length > 0 && columnHelper[tmpIndex] && !columnHelper[tmpIndex].systemLocked)
      ) && brick.bricid !== this.brickBaseService.brickID.Audience
      && brick.bricid !== this.brickBaseService.brickID.PrimaryAudience
      && brick.bricid !== this.brickBaseService.brickID.SecondaryAudience) {
      tmpIndex += 1;
    }
    if (this.rows.length > 0) {
      if (this.rows[0].cells[tmpIndex] &&
        (this.brickBaseService.quantityBrickId.indexOf(brick.bricid) === - 1 ||
          (this.brickBaseService.quantityBrickId.indexOf(brick.bricid) > - 1))) { // If Qty brick then cell at tmpIndex must be available
        cellPosition.cellIndex = tmpIndex;
      }
    }

    return cellPosition;
  }

  /**
   * @description Method check if the brick is optional in the product based on product details
   * @author Shreni Shah
   * @date 2020-06-18
   * @param {number} tmpIndex
   * @param {Brick} brick
   * @returns
   * @memberof Filter
   */
  checkIfBricIsOptionalInProduct(tmpIndex: number, brick: Brick) {
    const productDetails = this.getProductHelperIfProductExists(tmpIndex);
    const cellIndex = this.getCellIndexForCheckingOptionalBrick(tmpIndex, productDetails);
    if (productDetails.optional && productDetails.optional[cellIndex].indexOf(brick.bricid) > -1) {
      return true;
    }
    else return false;
  }

  /**
   * @description Method to get the index on which the optional product condition need to be checked
   * @author Shreni Shah
   * @date 2020-06-18
   * @param {number} tmpIndex
   * @param {ProductHelper} productDetails
   * @returns
   * @memberof Filter
   */
  getCellIndexForCheckingOptionalBrick(tmpIndex: number, productDetails: ProductHelper) {
    if (productDetails && productDetails.validation && productDetails.validation[tmpIndex]) {
      return tmpIndex;
    } else {
      return this.checkIfCellExistsInColumn(tmpIndex, Number(this.brickBaseService.brickID.ProductCatalogue), true);
    }
  }

  /**
   * @description
   * @author Vijay Sutaria
   * @param {number} colIndex column index where product existance to be checked
   * @returns {ProductHelper} Element of product details if found else null
   * @memberof Filter
   */

  /**
   * @description This method will search for ProductHelper element from ProductDetails variable
   * @author Vijay Sutaria, Amit Mahida
   * @param {number} colIndex column index where product existance to be checked
   * @returns {ProductHelper} Element of product details if found else null
   * @memberof Filter
   */
  getProductHelperIfProductExists(colIndex: number): ProductHelper {
    const colIndexToCheck = this.checkIfCellExistsInColumn(colIndex, Number(this.brickBaseService.brickID.ProductCatalogue), true);
    let productDetail: ProductHelper = null;
    const cachedProductDetails = this.stateService.getWorkspaceObject('productDetails');
    if (cachedProductDetails && cachedProductDetails.length > 0 && colIndexToCheck !== -1) {
      // Intentionally written [0] after filter, since if product is there in a column then product details must have that element in it.
      productDetail = cachedProductDetails.filter(p => p.optional[colIndexToCheck])[0];
    }
    return productDetail;
  }

  /**
   * @description returns true if brick is allowed for upper row update
   * @author Amit Mahida
   * @param {number} colIndex
   * @param {number} bricId
   * @returns {boolean}
   * @memberof Filter
   */
  isCellAllowedForAutoExpand(colIndex: number, bricId: number): boolean {
    let isCellAllowedToExpand = true;

    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
    if (!_.isEmpty(columnHelper) && columnHelper[colIndex] && columnHelper[colIndex].systemLocked) {
      // Brics are not allowed to stretch if the column is non-editable.
      isCellAllowedToExpand = false;
    } else if (this.isLockedColsPresent(colIndex) && bricId !== this.brickBaseService.brickID.Incharge) {
      // Only Incharge brick should be stretched if the column is locked.
      isCellAllowedToExpand = false;
    } else if (this.checkIfCellExistsInColumn(colIndex, Number(this.brickBaseService.brickID.ProductCatalogue), true) !== -1
      && bricId !== this.brickBaseService.brickID.Incharge) {
      // Product Catalogue and its bricks shouldn't get stretched in upper row updates.
      isCellAllowedToExpand = false;
    } else if (bricId === this.brickBaseService.brickID.SOT) {
      // SM-7103: Stop auto stretch when solts are selected in sot
      const bric = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.SOT, this.rows);
      let cellIndex = this.rows[bric.Available_row_Index].getOverlappingCell(bric.Available_lastcell_Index);
      cellIndex = cellIndex === -1 ? bric.Available_lastcell_Index : cellIndex;
      if (this.rows[bric.Available_row_Index].cells[cellIndex] && this.rows[bric.Available_row_Index].cells[cellIndex].selected.sotType === '1') {
        isCellAllowedToExpand = false;
      }
    } else if (bricId === this.brickBaseService.brickID.MultiTarget || this.brickBaseService.multiTargetBrics.includes(bricId)) {
      // if bric row is Multi Target brics or multi targeting brics and if both rows are present, don't stretch THEM
      if (this.getExistingRowIndex(Number(this.brickBaseService.brickID.MultiTarget)) > -1 && this.brickBaseService.multiTargetBrics.some(e => this.getExistingRowIndex(Number(e)) > -1)) {
        isCellAllowedToExpand = false;
      }
    }
    /* VJ: No need of this case as its covered in other way directly where isCellAllowedForAutoExpand is called
    else if (this.brickBaseService.quantityBrickId.indexOf(bricId) > -1
      && !this.checkIfBrickIsUnderSingleBrick(bricId, Number(this.brickBaseService.brickID.Incharge), colIndex + 1, true).result) {
      // Qty bricks shouldn't get stretched in upper row updates.
      isCellAllowedToExpand = false;
    }*/

    return isCellAllowedToExpand;
  }

  /**
   * @description This method is used to check if a brick is under same brick,
   * Ex: Qty is under same incharge or not, then call this as checkIfBrickIsUnderSingleBrick(qtyBrickID, InchargeBricID, colIndex)
   * @author Vijay Sutaria
   * @param {number} childBrickId brick id to check (ex: Qty brick id)
   * @param {number} parentBrickId brickIdToCheck will under this brick id (ex: Incharge brick id)
   * @param {number} colIndex colIndex of cell to check
   * @param {boolean} [useLastUsedIndex=true]
   * @returns true if its under single brick
   * @memberof Filter
   */
  public checkIfBrickIsUnderSingleBrick(childBrickId: number, parentBrickId: number, colIndex: number,
    useLastUsedIndex = true, expandDirection: ExpandDirection = ExpandDirection.None): {
      startIndexQty: number,
      endIndexQty: number,
      startIndex: number,
      endIndex: number,
      result: boolean
      expandCount: number
    } {
    const row = this.getRowByBrickId(parentBrickId);

    let startIndex;
    let endIndex;
    if (useLastUsedIndex && (!row.cells[colIndex] || (row.cells[colIndex].isEmpty && !row.cells[colIndex].isHidden))) {
      // This means current cell is visible and empty, lets find last used index before colIndex
      let tmpColIndex = colIndex - 1;
      while (tmpColIndex > 0 && row.cells[tmpColIndex].isEmpty && !row.cells[tmpColIndex].isHidden) {
        tmpColIndex--;
      }

      endIndex = tmpColIndex;
      startIndex = row.getOverlappingCell(endIndex);
      startIndex = startIndex === -1 ? endIndex : startIndex;
    } else {
      startIndex = row.getOverlappingCell(colIndex);
      startIndex = startIndex === -1 ? colIndex : startIndex;
      endIndex = row.cells[startIndex].cellWidth - 1 + startIndex;
    }

    const origRow = this.getRowByBrickId(childBrickId);
    let startIndexQty;
    let endIndexQty;
    if (useLastUsedIndex && (!origRow.cells[colIndex] || (origRow.cells[colIndex].isEmpty && !origRow.cells[colIndex].isHidden))) {
      // This means current cell is visible and empty, lets find last used index before colIndex
      let tmpColIndex = colIndex > 0 ? colIndex - 1 : 0;
      while (tmpColIndex > 0 && origRow.cells[tmpColIndex].isEmpty && !origRow.cells[tmpColIndex].isHidden) {
        tmpColIndex--;
      }

      endIndexQty = tmpColIndex;
      startIndexQty = origRow.getOverlappingCell(endIndexQty);
      startIndexQty = startIndexQty === -1 ? endIndexQty : startIndexQty;
    } else {
      if (expandDirection === ExpandDirection.Right && colIndex > 0) {
        colIndex -= 1;
      }
      startIndexQty = origRow.getOverlappingCell(colIndex);
      startIndexQty = startIndexQty === -1 ? colIndex : startIndexQty;
      endIndexQty = origRow.cells[startIndex].cellWidth - 1 + startIndex;
    }

    // Check if last qty brick is under another range and still need to expand till that range's end index
    const startIndexParent = row.getOverlappingCell(endIndexQty);
    let endIndexParent = -1;
    let expandCellCount = 0;
    if (parentBrickId === this.brickBaseService.brickID.Incharge && startIndexParent !== -1) {
      // Stretch endIndexQty till Parent Range length
      const parentWidth = row.cells[startIndexParent].cellWidth;
      endIndexParent = (parentWidth === 1 ? 0 : parentWidth) + startIndexParent - 1;
      expandCellCount = expandDirection === ExpandDirection.None ?
        origRow.cells[startIndexQty].cellWidth + endIndexParent - endIndexQty : (endIndexParent - endIndexQty);
    } else {
      expandCellCount = expandDirection === ExpandDirection.None ?
        origRow.cells[startIndexQty].cellWidth + endIndex - endIndexQty : (endIndex - endIndexQty);
    }
    expandCellCount = colIndex < (startIndexQty + expandCellCount - 1) ? (colIndex - startIndexQty + 1) : expandCellCount;
    return {
      startIndex,
      endIndex,
      startIndexQty,
      endIndexQty,
      result: expandCellCount > 0
        && !origRow.cells[startIndexQty].isEmpty
        && ((startIndexQty >= startIndex && endIndexQty <= endIndex)
          || (startIndexQty >= startIndexParent && endIndexQty < endIndexParent)),
      expandCount: expandCellCount
      // result: (startIndexQty >= startIndex && endIndexQty <= endIndex)
    };
  }

  /**
   * @description This method will create actual cell based on positions and values sent as parmeter
   * @author Vijay Sutaria
   * @param {CellValues} result object with required cell details
   * @param {boolean} [pushInHistoryStack=true]
   * @returns {boolean}
   * @memberof Filter
   */
  public addBrick(result: CellValues, pushInHistoryStack = true, isEditMode = false): boolean {
    const rowsBeforeChange = _.cloneDeep(this.rows);
    let makeServerCall = true;
    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();

    // If true then push current filter into history stack
    if (pushInHistoryStack) {
      if (this.appHeaderService && this.appHeaderService.enabledPCM) {
        this.historyStackService.pushInHistoryStackPCM(new HistoryStackData(this));
      } else if (this.appHeaderService && this.appHeaderService.objectiveMode) {
        this.historyStackService.pushInHistoryStackObjective(new HistoryStackData(this));
      } else {
        this.historyStackService.pushInHistoryStack(new HistoryStackData(this));
        this.historyStackService.pushColumnLineNumberInHistoryStack(this.stateService.getWorkspaceObject('columnLineNumber'));
        this.historyStackService.pushColumnHelperInHistoryStack(columnHelper);
      }
    }

    if (this.appHeaderService && this.appHeaderService.enabledPCM) {
      if (result.selectedValues && (Object.keys(result.selectedValues).length === 0 || result.selectedValues.hasOwnProperty('-99'))) {
        makeServerCall = false;
      }
    }

    if (this.appHeaderService && this.appHeaderService.objectiveMode) {
      makeServerCall = false;
    }

    if (!isEditMode) {
      let autoExpand = false;
      let cellIndex;
      cellIndex = result.brick.cellIndex;
      if (!this.isRowAvailable(result.brick.bricid)) {
        // Row not exists
        const row = new Row();
        row.createNewRow(this.rows.length, result, this.stateService.getColumnHelper(), this.appHeaderService.objectiveMode);
        if (result.brick.targetRowIndex > -1) {
          row.index = result.brick.targetRowIndex;
          this.rows.splice(result.brick.targetRowIndex, 0, row);
        } else {
          row.index = result.brick.rowIndex;
          this.rows.splice(result.brick.rowIndex, 0, row);
        }
        autoExpand = false;
      } else {
        if (this.rows[result.brick.rowIndex].isHidden) {
          this.rows[result.brick.rowIndex].isHidden = false;
        }
        if (cellIndex === this.rows[result.brick.rowIndex].cells.length) {
          // On workspace or at last index
          this.rows[result.brick.rowIndex].pushNewCell(result, cellIndex);
          autoExpand = false;
        } else if (result.brick.expandDirection === ExpandDirection.Left) {
          // Dropped on left side
          this.rows[result.brick.rowIndex].pushNewCell(result, cellIndex);
          autoExpand = true;
        } else if (result.brick.expandDirection === ExpandDirection.Right) {
          // Dropped on right side
          if (this.rows[result.brick.rowIndex].cells.length > result.brick.cellIndex) {
            // Case when dropped on expanded cell
            cellIndex = cellIndex + this.rows[result.brick.rowIndex].cells[cellIndex].cellWidth;

            this.rows[result.brick.rowIndex].pushNewCell(result, cellIndex, result.brick.expandDirection);
            autoExpand = true;
          } else {
            // TODO:
          }
        } else {
          // Dropped on workspace
          this.rows[result.brick.rowIndex].cells[cellIndex].assignValues(
            result.brick.rowIndex,
            result,
            this.rows[result.brick.rowIndex].cells[cellIndex].cellWidth || null
          );
        }
      }

      if (result.brick.rowIndex !== 0 || autoExpand) {
        // If not first row, then need to stretch cell of all above rows
        // expandAllRow is true, then need to expand cell of each row except the current one
        const lastRowIndex: number = autoExpand ? this.rows.length : result.brick.rowIndex;

        cellIndex = cellIndex !== -1 ? cellIndex : this.rows[result.brick.rowIndex].cells.length - 1;
        result.brick.cellIndex = cellIndex;
        const cellIndexFromDirection = result.brick.expandDirection === ExpandDirection.Right && cellIndex !== 0 ? cellIndex - 1 : cellIndex;

        if (result.brick.expandDirection !== ExpandDirection.None) {
          // When adding new brick in right of first cell and second one is already locked then its not allowing because at 2nd position its already true
          if (columnHelper) {
            this.manageColumnHelper(cellIndex);
          }
        }

        if (rowsBeforeChange[result.brick.rowIndex] && (!rowsBeforeChange[result.brick.rowIndex].cells[result.brick.cellIndex] ||
          (rowsBeforeChange[result.brick.rowIndex].cells[result.brick.cellIndex] && !rowsBeforeChange[result.brick.rowIndex].cells[result.brick.cellIndex].isEmpty))) {
          for (let i = 0; i < lastRowIndex; i++) {
            if (i !== result.brick.rowIndex // Current Row where brick is dropped should not get expanded
              && (this.rows[i].lastEmptyCellIndex() < this.rows[result.brick.rowIndex].cells.length
                || (this.rows[i].cells[cellIndex] && this.rows[i].cells[cellIndex].isEmpty))
            ) {
              const isQtyBrick: boolean = this.isQuantityBric(this.rows[i].bric.bricid);
              const overlappedIndex = this.rows[i].getOverlappingCell(cellIndex);
              const productDetails = this.stateService.getWorkspaceObject('productDetails');

              if ((isQtyBrick || this.rows[i].bric.bricid === this.brickBaseService.brickID.ProductCatalogue || this.rows[i].bric.bricid === this.brickBaseService.brickID.PricingTag)
                && (result.brick.expandDirection === ExpandDirection.Left
                  || result.brick.bricid === this.brickBaseService.brickID.Incharge
                  || (this.rows[result.brick.rowIndex].cells[cellIndexFromDirection] && this.rows[result.brick.rowIndex].cells[cellIndexFromDirection].isEmpty && result.brick.expandDirection !== ExpandDirection.None))
                && this.rows[i].bric.bricid !== this.brickBaseService.brickID.SOT
                && (overlappedIndex === -1 || overlappedIndex === cellIndex)) {
                // If Qty brick then it should shift along with the original column instead of stretching
                this.rows[i].createEmptyCellAtIndex(i, cellIndex, false, columnHelper);
              } else if (this.rows[result.brick.rowIndex].cells[cellIndexFromDirection] && this.rows[result.brick.rowIndex].cells[cellIndexFromDirection].isEmpty && !this.rows[result.brick.rowIndex].cells[cellIndexFromDirection].isHidden && result.brick.targetRowIndex !== -1) {
                // Check if user has dropped original brick at empty cell then no need to stretch any other bricks
                // Else in other case it should be stretched
              } else if (this.rows[i].bric.bricid === this.brickBaseService.brickID.Pattern
                && (result.brick.bricid === this.brickBaseService.brickID.Incharge || (this.rows[i].cells[cellIndexFromDirection] && this.rows[i].cells[cellIndexFromDirection].isEmpty))
                && result.brick.expandDirection !== ExpandDirection.None) {
                // If Pattern brick then it should shift along with the original column instead of stretching
                this.rows[i].createEmptyCellAtIndex(i, cellIndex, false, columnHelper);
              } else if (this.rows[i].cells[result.brick.cellIndex]
                && this.rows[i].cells[result.brick.cellIndex].isEmpty
                && !this.rows[i].cells[result.brick.cellIndex].isHidden
                && result.brick.expandDirection !== ExpandDirection.None) {
                // If cell at cellIndexFromDirection is empty then new empty cell must be pushed so that rest cells can be move along with their column
                this.rows[i].createEmptyCellAtIndex(i, cellIndex, false, columnHelper);
              } else if (this.brickBaseService.brickID.Objective === this.rows[i].bric.bricid) {
                // Objective brick is not allowed to stretch in any case
                this.rows[i].createEmptyCellAtIndex(i, cellIndex, false, columnHelper);
              } else if (this.isCellAllowedForAutoExpand(result.brick.cellIndex, this.rows[i].bric.bricid)
                && this.isRowAvailable(Number(this.brickBaseService.brickID.Incharge))) {
                // Search for last used index, if current is empty
                if (((isQtyBrick || this.rows[i].bric.bricid === this.brickBaseService.brickID.PricingTag) && this.rows[i].bric.bricid !== this.brickBaseService.brickID.SOT)
                  || this.rows[i].bric.bricid === this.brickBaseService.brickID.Pattern) {

                  if (!GLOBAL.localSolverEnabled || this.initialConfig.uiControl.allowReductionBrickSpan) { // SM-6264
                    // find previous index which is under same range
                    const res = this.checkIfBrickIsUnderSingleBrick(this.rows[i].bric.bricid, Number(this.brickBaseService.brickID.Incharge),
                      cellIndex, true, result.brick.expandDirection);

                    if (res.result) {
                      let newExpandCount = res.expandCount;
                      if (this.rows[i].cells[res.startIndexQty].cellWidth + res.expandCount > (cellIndex + 1)) {
                        newExpandCount = cellIndex - res.endIndexQty;
                      }

                      this.rows[i].expandCell(res.startIndexQty, result.brick.expandDirection, newExpandCount, true, productDetails);
                    }
                  }
                } else {
                  const expandCount = this.rows[i].expandCell(cellIndex, result.brick.expandDirection, undefined,
                    result.brick.expandDirection === ExpandDirection.None,
                    productDetails);

                  if (expandCount > 0) {
                    // If Current expanded was Range then need to stretch pattern same as it if exists
                    if (this.rows[i].bric.bricid === this.brickBaseService.brickID.Incharge && expandCount > 0) {
                      const patternRow: Row = this.getRowByBrickId(Number(this.brickBaseService.brickID.Pattern));

                      const res = this.checkIfBrickIsUnderSingleBrick(this.rows[i + 1].bric.bricid, Number(this.brickBaseService.brickID.Incharge),
                        cellIndex, true, result.brick.expandDirection);

                      if (patternRow && patternRow.cells.length >= cellIndex && res.result) {
                        patternRow.expandCell(cellIndex, result.brick.expandDirection, undefined,
                          result.brick.expandDirection === ExpandDirection.None, productDetails
                        );

                        i++; // increasing index to skip pattern row which is next always
                      }
                    } else if (rowsBeforeChange[i].bric.bricid === this.brickBaseService.brickID.Media
                      && this.initialConfig.uiControl.defaultAllocationEngine) {
                      // Need to manage allocationEngines variable when media is stretched
                      // Need to find overlap index again as above above else, it is expanded
                      const startIndex = this.rows[i].getOverlappingCell(cellIndex);
                      if (startIndex !== -1) { // This considtion should always return >-1 value
                        this.allocationEngines.splice(cellIndex, 0, this.allocationEngines[startIndex]);
                      }
                    }
                  }
                }

                // } else {
                //   this.rows[i].expandCell(cellIndex, result.brick.expandDirection);
                // }
              }
            }
          }
        }
      }
      this.lockAllButton = this.isAllColumnsLocked();
      this.manageAudienceBrickSize();

      if (SystemFlags.isFillerCampaign) {
        this.handleFillerCampaign(cellIndex, columnHelper);
      }

      if (this.stateService.getWorkspaceFilterObj().rows.length > 0 &&
        this.stateService.getWorkspaceFilterObj().rows[0].cells.length !== this.rows[0].cells.length) {
        this.incrementColumnLineNumber(cellIndex);
      }
    } else {
      this.rows[result.brick.rowIndex].cells[result.brick.cellIndex].assignValues(
        result.brick.rowIndex,
        result,
        this.rows[result.brick.rowIndex].cells[result.brick.cellIndex].cellWidth || null
      );
    }

    this.reApplyIndex();

    // When we save values for Sot, Need to update floor ceiling values for volume/budget bric
    if (result.brick.bricid === this.brickBaseService.brickID.SOT && this.appHeaderService && !this.appHeaderService.enabledPCM && !this.appHeaderService.objectiveMode) {
      // Reason for adding this code here is bric in not added to this.filter.rows, due to which getSOTvalue() return null even though we have SOT bric.
      const startIndex = result.brick.cellIndex;
      const row = this.getRowByBrickId(Number(this.brickBaseService.brickID.SOT));
      const cellWidth = row.cells[startIndex].cellWidth;
      for (let index = startIndex; index < cellWidth + startIndex; index++) {
        this.updateFloorAndCeilingValues(index, false);
      }
    }

    if (this.appHeaderService && this.appHeaderService.enabledPCM) {
      this.stateService.setPCMObject('filter', this.stateService.clone(this));
    }
    if (this.appHeaderService && this.appHeaderService.objectiveMode) {
      this.stateService.setObjectiveObject('filter', this.stateService.clone(this));
    }
    if (result.brick.bricid === this.brickBaseService.brickID.ProductCatalogue) {
      if (this.appHeaderService.enabledPCM) {
        this.productEditMode = true;
      } else {
        this.productEditMode = false;
      }
      this.loadProduct(result);
      this.alignAllocationEngineValueOnBrickDrop(result);
    } else {
      if (this.appHeaderService && !this.appHeaderService.enabledPCM && !this.appHeaderService.objectiveMode) {
        this.stateService.setWorkspaceObject('filter', this.stateService.clone(this));
      }
      this.createEmptyCells();
      this.alignAllocationEngineValueOnBrickDrop(result);
      this.postCellUpdates(makeServerCall);
    }
    return true;
  }

  /**
   * This method will adjust allocationEngin when use hads dropped media brick
   * @param result result of add brick
   */
  alignAllocationEngineValueOnBrickDrop(result) {
    if (result.brick.bricid === this.brickBaseService.brickID.Media && this.initialConfig.uiControl.defaultAllocationEngine) { // This means Local Solver is enabled
      const allocationEngine = result.selectedValues.selectedMediaObject.defaultAllocationEngine || this.initialConfig.uiControl.defaultAllocationEngine;
      if (result.brick.expandDirection === ExpandDirection.None) {
        this.allocationEngines[result.brick.cellIndex] = allocationEngine;
      } else {
        this.allocationEngines.splice(result.brick.cellIndex, 0, allocationEngine);
      }
    }
  }

  /**
   * @description get the sot value if bric is present [SM-4252]
   * @author Nishit Parekh
   * @param {Brick} brick brick object
   * @returns selected sot value if bric is present
   * @memberof WorkspaceComponent
   */
  getSOTValue(cellIndex: number, brickId?: number): SotFloorCeilingDataModel | Object {
    // Check if the volume/Budget if stretched and if there are multiple SOT get the Min and Max  //
    const volumeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Volume, this.rows).Available_row_Index;
    const budgetRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Budget, this.rows).Available_row_Index;
    const volumeStretchedIndex = volumeRowIndex !== null ? this.rows[volumeRowIndex].getOverlappingCell(cellIndex) : -1;
    const budgetStretchedIndex = budgetRowIndex !== null ? this.rows[budgetRowIndex].getOverlappingCell(cellIndex) : -1;
    let objectiveRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Objective, this.rows).Available_row_Index;;
    let alternetRows = null;
    let timeRowIndex = null;
    let currentObjectiveCellIndex = null;
    let stretchedTimeIndex = -1;
    let isTimeCellAvailable = false;

    if (this.objectType == "workspace") {
      timeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.SOT, this.rows).Available_row_Index;
      alternetRows = objectiveRowIndex !== null ? this.stateService.getObjectiveFilterObj().rows : null;

      if (timeRowIndex != null) {
        stretchedTimeIndex = this.rows[timeRowIndex].getOverlappingCell(cellIndex);

        let index = stretchedTimeIndex !== -1 ? stretchedTimeIndex : cellIndex;

        isTimeCellAvailable = timeRowIndex !== null && this.rows[timeRowIndex].cells[index] && this.rows[timeRowIndex].cells[index].selected ?
          Object.keys(this.rows[timeRowIndex].cells[index].selected).length && !this.rows[timeRowIndex].cells[index].isEmpty ? true : false : false;
      }
    } else if (this.objectType == "objective") {
      currentObjectiveCellIndex = this.stateService.getObjectiveObject('currentObjectiveData').brick.cellIndex;
      alternetRows = this.stateService.getWorkspaceFilterObj().rows;
      timeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.SOT, alternetRows).Available_row_Index;
      objectiveRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Objective, alternetRows).Available_row_Index;

      if (timeRowIndex != null) {
        stretchedTimeIndex = alternetRows[timeRowIndex].getOverlappingCell(currentObjectiveCellIndex);

        let index = stretchedTimeIndex !== -1 ? stretchedTimeIndex : currentObjectiveCellIndex;

        isTimeCellAvailable = timeRowIndex !== null && alternetRows[timeRowIndex].cells[index] && alternetRows[timeRowIndex].cells[index].selected ?
          Object.keys(alternetRows[timeRowIndex].cells[index].selected).length && !alternetRows[timeRowIndex].cells[index].isEmpty ? true : false : false;
      }
    }

    let alternetTimeRowIndex = null;
    let alternetVolumeRowIndex = null;
    let alternetBudgetRowIndex = null;
    let alternetVolumeStretchedIndex = null;
    let alternetBudgetStretchedIndex = null;

    if (alternetRows && alternetRows.length !== 0) {
      alternetVolumeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Volume, alternetRows).Available_row_Index;
      alternetVolumeStretchedIndex = alternetVolumeRowIndex !== null ? alternetRows[alternetVolumeRowIndex].getOverlappingCell(cellIndex) : -1;

      alternetBudgetRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Budget, alternetRows).Available_row_Index;
      alternetBudgetStretchedIndex = alternetBudgetRowIndex !== null ? alternetRows[alternetBudgetRowIndex].getOverlappingCell(cellIndex) : -1;

      alternetTimeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.SOT, alternetRows).Available_row_Index;
    }
    let needFromAlternet = false;

    if (this.objectType == "workspace" && objectiveRowIndex !== null) {
      needFromAlternet = !isTimeCellAvailable && objectiveRowIndex == _.min([volumeRowIndex, budgetRowIndex, objectiveRowIndex]);
    } else if (this.objectType == "objective") {
      needFromAlternet = isTimeCellAvailable || objectiveRowIndex != _.min([alternetVolumeRowIndex, alternetBudgetRowIndex, objectiveRowIndex]);
    }

    let rows = needFromAlternet ? alternetRows : this.rows;

    if (needFromAlternet && this.objectType == "objective") {
      cellIndex = currentObjectiveCellIndex;
    }

    if (isTimeCellAvailable) {
      if (needFromAlternet) {
        if (alternetVolumeRowIndex !== null && alternetVolumeStretchedIndex !== -1) {
          return this.getFloorAndCeilingForMultipleSOT(alternetVolumeStretchedIndex, alternetVolumeRowIndex, timeRowIndex);
        }
        if (alternetBudgetRowIndex !== null && alternetBudgetStretchedIndex !== -1) {
          return this.getFloorAndCeilingForMultipleSOT(alternetBudgetStretchedIndex, alternetBudgetRowIndex, timeRowIndex);
        }
      } else {
        if (volumeRowIndex !== null && volumeStretchedIndex !== -1) {
          return this.getFloorAndCeilingForMultipleSOT(volumeStretchedIndex, volumeRowIndex, timeRowIndex);
        }
        if (budgetRowIndex !== null && budgetStretchedIndex !== -1) {
          return this.getFloorAndCeilingForMultipleSOT(budgetStretchedIndex, budgetRowIndex, timeRowIndex);
        }
      }
      let sotBRIC: Cell;
      let inUseByResizable;

      sotBRIC = rows[timeRowIndex].cells[cellIndex];
      inUseByResizable = rows[timeRowIndex].getOverlappingCell(cellIndex);

      const sotValue: SotFloorCeilingDataModel = {
        sotCeiling: null,
        sotFloor: null,
        disableFloorAndCeiling: false
      };
      const columnSummary = SystemFlags.incorrectSolution ? null : this.stateService.getAllColumnSummary();
      // If SOT Brick is Stretched
      if (inUseByResizable !== -1) {
        const sotResizedBric = rows[timeRowIndex].cells[inUseByResizable];
        if (Object.keys(sotResizedBric.selected).length && sotResizedBric.selected.sot) {
          if (sotResizedBric.selected.sot === 'Mixed') {
            if (columnSummary && columnSummary[cellIndex]) {
              sotValue.sotFloor = parseFloat(columnSummary[cellIndex].sot[0].toString());
              const index = columnSummary[cellIndex].sot.length;
              sotValue.sotCeiling = parseFloat(columnSummary[cellIndex].sot[index - 1].toString());
              sotValue.disableFloorAndCeiling = true;
              return sotValue;
            }
          } else {
            sotValue.disableFloorAndCeiling = true;
            return this.populateSot(sotValue, sotResizedBric);
          }
        } else {
          // return this.getFloorCeilingSOTValue(rows, rowIndex, volumeRowIndex, budgetRowIndex, volumeStretchedIndex, budgetStretchedIndex, brickId, cellIndex, needFromObjective)
          return needFromAlternet ?
            this.getFloorCeilingSOTValue(rows, alternetVolumeRowIndex, alternetBudgetRowIndex, objectiveRowIndex, alternetVolumeStretchedIndex, alternetBudgetStretchedIndex, brickId, cellIndex, needFromAlternet) :
            this.getFloorCeilingSOTValue(rows, volumeRowIndex, budgetRowIndex, objectiveRowIndex, volumeStretchedIndex, budgetStretchedIndex, brickId, cellIndex, needFromAlternet);
        }
      } else { // Brick is not Stretched
        if (sotBRIC && Object.keys(sotBRIC.selected).length && sotBRIC.selected.sot && !sotBRIC.isEmpty) {
          if (sotBRIC.selected.sot === 'Mixed') {
            if (columnSummary && columnSummary[cellIndex] && columnSummary[cellIndex].sot && columnSummary[cellIndex].sot.length) {
              sotValue.sotFloor = parseFloat(columnSummary[cellIndex].sot[0].toString());
              const index = columnSummary[cellIndex].sot.length;
              sotValue.sotCeiling = parseFloat(columnSummary[cellIndex].sot[index - 1].toString());
              sotValue.disableFloorAndCeiling = true;
              return sotValue;
            }
          } else {
            sotValue.disableFloorAndCeiling = true;
            return this.populateSot(sotValue, sotBRIC);
          }
        } else {
          // return this.getFloorCeilingSOTValue(rows, rowIndex, volumeRowIndex, budgetRowIndex, volumeStretchedIndex, budgetStretchedIndex, brickId, cellIndex, needFromObjective);
          return needFromAlternet ?
            this.getFloorCeilingSOTValue(rows, alternetVolumeRowIndex, alternetBudgetRowIndex, objectiveRowIndex, alternetVolumeStretchedIndex, alternetBudgetStretchedIndex, brickId, cellIndex, needFromAlternet) :
            this.getFloorCeilingSOTValue(rows, volumeRowIndex, budgetRowIndex, objectiveRowIndex, volumeStretchedIndex, budgetStretchedIndex, brickId, cellIndex, needFromAlternet);
        }
      }
    } else {
      return needFromAlternet ?
        this.getFloorCeilingSOTValue(rows, alternetVolumeRowIndex, alternetBudgetRowIndex, objectiveRowIndex, alternetVolumeStretchedIndex, alternetBudgetStretchedIndex, brickId, cellIndex, needFromAlternet) :
        this.getFloorCeilingSOTValue(rows, volumeRowIndex, budgetRowIndex, objectiveRowIndex, volumeStretchedIndex, budgetStretchedIndex, brickId, cellIndex, needFromAlternet);
    }
    return {};
  }

  getFloorCeilingSOTValue(rows, volumeRowIndex, budgetRowIndex, objectiveRowIndex, volumeStretchedIndex, budgetStretchedIndex, brickId, cellIndex, needFromAlternet): SotFloorCeilingDataModel | Object {
    // SM-9632
    if (!this.appHeaderService.enabledPCM && brickId) {

      let stretchedIndex = (volumeRowIndex !== null && budgetRowIndex !== null) ?
        (volumeRowIndex < budgetRowIndex ? volumeStretchedIndex : budgetStretchedIndex) :
        (volumeRowIndex !== null ? volumeStretchedIndex :
          (budgetRowIndex !== null ? budgetStretchedIndex : -1));

      let index = stretchedIndex !== -1 ? stretchedIndex : cellIndex

      let isVolumeCellAvailable = volumeRowIndex !== null && rows[volumeRowIndex].cells[index] && rows[volumeRowIndex].cells[index].selected ?
        Object.keys(rows[volumeRowIndex].cells[index].selected).length && !rows[volumeRowIndex].cells[index].isEmpty ? true : false : false;
      let isBudgetCellAvailable = budgetRowIndex !== null && rows[budgetRowIndex].cells[index] && rows[budgetRowIndex].cells[index].selected ?
        Object.keys(rows[budgetRowIndex].cells[index].selected).length && !rows[budgetRowIndex].cells[index].isEmpty ? true : false : false;

      let rowIndex = (isVolumeCellAvailable && isBudgetCellAvailable) ?
        (volumeRowIndex < budgetRowIndex ? volumeRowIndex : budgetRowIndex) :
        (isVolumeCellAvailable ? volumeRowIndex :
          isBudgetCellAvailable ? budgetRowIndex : null);
      let itSelf = (!needFromAlternet && ((rowIndex == volumeRowIndex && brickId == this.brickBaseService.brickID.Volume) || (rowIndex == budgetRowIndex && brickId == this.brickBaseService.brickID.Budget)))

      if (this.objectType == "workspace" && needFromAlternet) {
        const sotValue: SotFloorCeilingDataModel = {
          sotFloor: null,
          sotCeiling: null,
          disableFloorAndCeiling: !itSelf
        };

        let isInnerCellAvailable = false;
        if (objectiveRowIndex !== null && this.rows[objectiveRowIndex].cells[cellIndex].selected.objectives) {
          this.rows[objectiveRowIndex].cells[cellIndex].selected.objectives.forEach(function (row, objectiveindex) {
            if (volumeRowIndex == objectiveindex || budgetRowIndex == objectiveindex) {
              row.cells.forEach(function (cell, objectiveInnerindex) {
                if (!cell.isEmpty && cell.selected) {
                  if (cell.selected.sotFloor != undefined && cell.selected.sotFloor != null) {
                    let floor = parseFloat(cell.selected.sotFloor.toString());
                    if (sotValue.sotFloor != undefined && sotValue.sotFloor != null) {
                      sotValue.sotFloor = floor < sotValue.sotFloor ? floor : sotValue.sotFloor;
                    } else {
                      sotValue.sotFloor = floor;
                    }
                  }

                  if (cell.selected.sotCeiling != undefined && cell.selected.sotCeiling != null) {
                    let ceiling = parseFloat(cell.selected.sotCeiling.toString());
                    if (sotValue.sotCeiling != undefined && sotValue.sotCeiling != null) {
                      sotValue.sotCeiling = ceiling > sotValue.sotCeiling ? ceiling : sotValue.sotCeiling;
                    } else {
                      sotValue.sotCeiling = ceiling;
                    }
                  }

                  if (sotValue.sotFloor != undefined && sotValue.sotFloor != null && sotValue.sotCeiling != undefined && sotValue.sotCeiling != null) {
                    isInnerCellAvailable = true;
                    sotValue.disableFloorAndCeiling = true;
                  }
                }
              });
            }
          });
        }
        return sotValue;
      }
      if (this.objectType == "objective" && !needFromAlternet) {
        const volumeCellIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Volume, rows).Available_First_Cell_Index;
        const budgetCellIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Budget, rows).Available_First_Cell_Index;

        rowIndex = (volumeCellIndex !== null && budgetCellIndex !== null) ?
          (volumeCellIndex == budgetCellIndex ? _.min([volumeRowIndex, budgetRowIndex]) :
            (volumeCellIndex < budgetCellIndex ? volumeRowIndex : budgetRowIndex)) :
          (volumeCellIndex !== null ? volumeRowIndex :
            (budgetCellIndex !== null ? budgetRowIndex : null));

        index = _.min([volumeCellIndex, budgetCellIndex]); //cell index of the highest privileage cell

        if (rowIndex !== null) {

          let disableFloorAndCeiling = (itSelf && index == cellIndex) ? false : true;
          //rowindex not null implies there is a volume/budget bric already, so enable SOT only if highest privileage cell is selected

          const sotValue: SotFloorCeilingDataModel = {
            sotFloor: null,
            sotCeiling: null,
            disableFloorAndCeiling: disableFloorAndCeiling
          };
          const budgetOrVolumeBric: Cell = rows[rowIndex].cells[index];

          let allowTOchangeSOTValue = budgetOrVolumeBric && Object.keys(budgetOrVolumeBric.selected).length && !budgetOrVolumeBric.isEmpty ? true : false;;

          if (allowTOchangeSOTValue) {
            sotValue.sotFloor = budgetOrVolumeBric.selected.sotFloor;
            sotValue.sotCeiling = budgetOrVolumeBric.selected.sotCeiling;
            sotValue.disableFloorAndCeiling = disableFloorAndCeiling;
            return sotValue;
          }
        }
      }
      if (rowIndex !== null) {
        const sotValue: SotFloorCeilingDataModel = {
          sotFloor: null,
          sotCeiling: null,
          disableFloorAndCeiling: !itSelf
        };
        const budgetOrVolumeBric: Cell = rows[rowIndex].cells[index];

        let allowTOchangeSOTValue = budgetOrVolumeBric && Object.keys(budgetOrVolumeBric.selected).length && !budgetOrVolumeBric.isEmpty ? true : false;;

        if (allowTOchangeSOTValue) {
          sotValue.sotFloor = budgetOrVolumeBric.selected.sotFloor;
          sotValue.sotCeiling = budgetOrVolumeBric.selected.sotCeiling;
          sotValue.disableFloorAndCeiling = !itSelf;
          return sotValue;
        }
      }
    }
    return {};
  }

  /**
   * @description populate floor and ceiling for Sot
   * @author Nishit Parekh
   * @param {*} sotValue
   * @param {Cell} sotBRIC
   * @returns sotValue
   * @memberof Filter
   */
  populateSot(sotValue: any, sotBRIC: Cell) {
    sotValue.sotFloor = sotBRIC.selected.sot;
    sotValue.sotCeiling = sotBRIC.selected.sot;
    return sotValue;
  }

  //SM-9596
  getSOTForPCM(cellIndex: number, brickId?: number): SotFloorCeilingDataModel | Object {
    let rowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.SOT, this.rows).Available_row_Index;
    // Check if the volume/Budget if stretched and if there are multiple SOT get the Min and Max  //
    const volumeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Volume, this.rows).Available_row_Index;
    const budgetRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Budget, this.rows).Available_row_Index;
    const volumeStretchedIndex = volumeRowIndex !== null ? this.rows[volumeRowIndex].getOverlappingCell(cellIndex) : -1;
    const budgetStretchedIndex = budgetRowIndex !== null ? this.rows[budgetRowIndex].getOverlappingCell(cellIndex) : -1;
    if (rowIndex !== null) {
      if (volumeRowIndex !== null && volumeStretchedIndex !== -1) {
        return this.getFloorAndCeilingForMultipleSOT(volumeStretchedIndex, volumeRowIndex, rowIndex);
      }
      if (budgetRowIndex !== null && budgetStretchedIndex !== -1) {
        return this.getFloorAndCeilingForMultipleSOT(budgetStretchedIndex, budgetRowIndex, rowIndex);
      }
      const sotBRIC: Cell = this.rows[rowIndex].cells[cellIndex];
      let isSOTAvailalbe = sotBRIC && Object.keys(sotBRIC.selected).length ? true : false;
      const inUseByResizable = this.rows[rowIndex].getOverlappingCell(cellIndex);
      const sotValue: SotFloorCeilingDataModel = {
        sotCeiling: null,
        sotFloor: null,
        sotIncrement: null,
        disableFloorAndCeiling: null,
        disableSOTIncrement: true
      };
      // If SOT Brick is Stretched
      if (inUseByResizable !== -1) {
        const sotResizedBric = this.rows[rowIndex].cells[inUseByResizable];
        isSOTAvailalbe = sotResizedBric && Object.keys(sotResizedBric.selected).length ? true : false;
        if (sotResizedBric && Object.keys(sotResizedBric.selected).length && sotResizedBric.selected.increment) {
          sotValue.sotFloor = sotResizedBric.selected.min != undefined && sotResizedBric.selected.min != null ? parseFloat(sotResizedBric.selected.min.toString()) : sotResizedBric.selected.min;
          sotValue.sotCeiling = sotResizedBric.selected.max != undefined && sotResizedBric.selected.max != null ? parseFloat(sotResizedBric.selected.max.toString()) : sotResizedBric.selected.max;
          sotValue.sotIncrement = sotResizedBric.selected.increment != undefined && sotResizedBric.selected.increment != null ? parseFloat(sotResizedBric.selected.increment.toString()) : sotResizedBric.selected.increment;
          sotValue.disableFloorAndCeiling = true;
          return sotValue;
        } else {
          return this.getFloorCeilingSOTValueForPCM(rowIndex, volumeRowIndex, budgetRowIndex, volumeStretchedIndex, budgetStretchedIndex, brickId, cellIndex, isSOTAvailalbe);
        }
      } else { // Brick is not Stretched
        if (sotBRIC && Object.keys(sotBRIC.selected).length && sotBRIC.selected.increment) {
          sotValue.sotFloor = sotBRIC.selected.min != undefined && sotBRIC.selected.min != null ? parseFloat(sotBRIC.selected.min.toString()) : sotBRIC.selected.min;
          sotValue.sotCeiling = sotBRIC.selected.max != undefined && sotBRIC.selected.max != null ? parseFloat(sotBRIC.selected.max.toString()) : sotBRIC.selected.max;
          sotValue.sotIncrement = sotBRIC.selected.increment != undefined && sotBRIC.selected.increment != null ? parseFloat(sotBRIC.selected.increment.toString()) : sotBRIC.selected.increment;
          sotValue.disableFloorAndCeiling = true;
          return sotValue;
        } else {
          return this.getFloorCeilingSOTValueForPCM(rowIndex, volumeRowIndex, budgetRowIndex, volumeStretchedIndex, budgetStretchedIndex, brickId, cellIndex, isSOTAvailalbe);
        }
      }
    } else {
      return this.getFloorCeilingSOTValueForPCM(rowIndex, volumeRowIndex, budgetRowIndex, volumeStretchedIndex, budgetStretchedIndex, brickId, cellIndex, false);
    }
  }

  getFloorCeilingSOTValueForPCM(rowIndex, volumeRowIndex, budgetRowIndex, volumeStretchedIndex, budgetStretchedIndex, brickId, cellIndex, isSOTAvailalbe): SotFloorCeilingDataModel | Object {
    if (!this.appHeaderService.objectiveMode && brickId) {
      const stretchedIndex = (volumeRowIndex !== null && budgetRowIndex !== null) ?
        (volumeRowIndex < budgetRowIndex ? volumeStretchedIndex : budgetStretchedIndex) :
        (volumeRowIndex !== null ? volumeStretchedIndex :
          (budgetRowIndex !== null ? budgetStretchedIndex : -1));

      let index = stretchedIndex !== -1 ? stretchedIndex : cellIndex
      let isVolumeCellAvailable = volumeRowIndex !== null ?
        Object.keys(this.rows[volumeRowIndex].cells[index].selected).length && !this.rows[volumeRowIndex].cells[index].isEmpty ? true : false : false;
      let isBudgetCellAvailable = budgetRowIndex !== null ?
        Object.keys(this.rows[budgetRowIndex].cells[index].selected).length && !this.rows[budgetRowIndex].cells[index].isEmpty ? true : false : false;


      if (isVolumeCellAvailable && isBudgetCellAvailable) {
        rowIndex = volumeRowIndex < budgetRowIndex ? volumeRowIndex : budgetRowIndex;
      } else if (isVolumeCellAvailable) {
        rowIndex = volumeRowIndex !== null ? volumeRowIndex : null;
      } else if (isBudgetCellAvailable) {
        rowIndex = budgetRowIndex !== null ? budgetRowIndex : null;
      } else {
        rowIndex = null;
      }
      let itSelf = (rowIndex == volumeRowIndex && brickId == this.brickBaseService.brickID.Volume) || (rowIndex == budgetRowIndex && brickId == this.brickBaseService.brickID.Budget)

      if (rowIndex !== null) {
        const sotValue: SotFloorCeilingDataModel = {
          sotCeiling: null,
          sotFloor: null,
          sotIncrement: null,
          disableSOTIncrement: !itSelf,
          disableFloorAndCeiling: !itSelf
        };
        // let increment = null;
        const budgetOrVolumeBric: Cell = this.rows[rowIndex].cells[index];

        if (budgetOrVolumeBric.selected && !budgetOrVolumeBric.isEmpty && itSelf) {
          sotValue.sotFloor = budgetOrVolumeBric.selected.sotFloor;
          sotValue.sotCeiling = budgetOrVolumeBric.selected.sotCeiling;
          sotValue.sotIncrement = budgetOrVolumeBric.selected.sotIncrement;
          sotValue.disableSOTIncrement = isSOTAvailalbe ? true : sotValue.disableSOTIncrement;
          return sotValue;
        } else if (budgetOrVolumeBric.selected && !budgetOrVolumeBric.isEmpty) {
          if (budgetOrVolumeBric.selected.sotIncrement) {
            sotValue.sotFloor = budgetOrVolumeBric.selected.sotFloor;
            sotValue.sotCeiling = budgetOrVolumeBric.selected.sotCeiling;
            sotValue.disableFloorAndCeiling = true;
          } else {
            sotValue.disableFloorAndCeiling = false;
          }
          sotValue.sotIncrement = budgetOrVolumeBric.selected.sotIncrement;
          sotValue.disableSOTIncrement = isSOTAvailalbe ? true : sotValue.disableSOTIncrement;
          return sotValue;
        } else if (!itSelf) {
          delete sotValue.sotFloor;
          delete sotValue.sotCeiling;
          delete sotValue.sotIncrement;
          sotValue.disableSOTIncrement = isSOTAvailalbe ? true : false;
          sotValue.disableFloorAndCeiling = false;
          return sotValue;
        }
      }
    }
    return {};
  }

  //SM-9596
  generateSOTBrickRequestJSON() {
    // let timeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.SOT, this.rows).Available_row_Index;
    const volumeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Volume, this.rows).Available_row_Index;
    const budgetRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Budget, this.rows).Available_row_Index;

    let timeRowIndex = null;

    const objectiveRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Objective, this.rows).Available_row_Index;
    let alternetRows = null;
    let currentObjectiveCellIndex = null;
    let stretchedTimeIndex = -1;
    let isTimeCellAvailable = false;

    if (this.objectType == "workspace") {
      timeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.SOT, this.rows).Available_row_Index;
      alternetRows = objectiveRowIndex !== null ? this.stateService.getObjectiveFilterObj().rows : null;
    } else if (this.objectType == "objective") {
      currentObjectiveCellIndex = this.stateService.getObjectiveObject('currentObjectiveData').brick.cellIndex;
      alternetRows = this.stateService.getWorkspaceFilterObj().rows;
      timeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.SOT, alternetRows).Available_row_Index;

      if (timeRowIndex != null) {
        stretchedTimeIndex = alternetRows[timeRowIndex].getOverlappingCell(currentObjectiveCellIndex);

        let index = stretchedTimeIndex !== -1 ? stretchedTimeIndex : currentObjectiveCellIndex;

        isTimeCellAvailable = timeRowIndex !== null && alternetRows[timeRowIndex].cells[index] && alternetRows[timeRowIndex].cells[index].selected ?
          Object.keys(alternetRows[timeRowIndex].cells[index].selected).length && !alternetRows[timeRowIndex].cells[index].isEmpty ? true : false : false;
      }
    } else if (this.objectType == "pcm") {
      timeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.SOT, this.rows).Available_row_Index;
    }

    let alternetVolumeRowIndex = null;
    let alternetBudgetRowIndex = null;
    let alternetVolumeBudgetRowIndex = null;

    if (alternetRows && alternetRows.length !== 0) {
      alternetVolumeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Volume, alternetRows).Available_row_Index;
      alternetBudgetRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Budget, alternetRows).Available_row_Index;

      alternetVolumeBudgetRowIndex = _.min([alternetVolumeRowIndex, alternetBudgetRowIndex]);
    }
    let needFromAlternet = false;
    if (this.objectType == "workspace") {
      needFromAlternet = timeRowIndex === null && objectiveRowIndex == _.min([volumeRowIndex, budgetRowIndex, objectiveRowIndex]);
    } else if (this.objectType == "objective") {
      needFromAlternet = isTimeCellAvailable || objectiveRowIndex != _.min([alternetVolumeRowIndex, alternetBudgetRowIndex, objectiveRowIndex]);
    }

    if (((this.objectType == "workspace" || this.objectType == "pcm") && timeRowIndex !== null) || (this.objectType == "objective" && isTimeCellAvailable)) {
      if (needFromAlternet) {
        const inUseByResizable = alternetRows[timeRowIndex].getOverlappingCell(currentObjectiveCellIndex);

        let currentSOTBRIC = inUseByResizable !== -1 ? alternetRows[timeRowIndex].cells[inUseByResizable] : alternetRows[timeRowIndex].cells[currentObjectiveCellIndex];

        const columnSummary = SystemFlags.incorrectSolution ? null : this.stateService.getAllColumnSummary();

        let sotValue: SotFloorCeilingDataModel = {
          sotFloor: currentSOTBRIC.selected.min != undefined && currentSOTBRIC.selected.min != null ? parseFloat(currentSOTBRIC.selected.min.toString()) : currentSOTBRIC.selected.min,
          sotCeiling: currentSOTBRIC.selected.max != undefined && currentSOTBRIC.selected.max != null ? parseFloat(currentSOTBRIC.selected.max.toString()) : currentSOTBRIC.selected.max,
          sotIncrement: currentSOTBRIC.selected.increment != undefined && currentSOTBRIC.selected.increment != null ? parseFloat(currentSOTBRIC.selected.increment.toString()) : currentSOTBRIC.selected.increment
        };

        if (!this.appHeaderService.enabledPCM && currentSOTBRIC.selected.sot && !currentSOTBRIC.isEmpty) {
          if (currentSOTBRIC.selected.sot === 'Mixed') {
            if (columnSummary && columnSummary[currentObjectiveCellIndex] && columnSummary[currentObjectiveCellIndex].sot && columnSummary[currentObjectiveCellIndex].sot.length) {
              sotValue.sotFloor = parseFloat(columnSummary[currentObjectiveCellIndex].sot[0].toString());
              const index = columnSummary[currentObjectiveCellIndex].sot.length;
              sotValue.sotCeiling = parseFloat(columnSummary[currentObjectiveCellIndex].sot[index - 1].toString());
            }
          } else {
            sotValue = { ...sotValue, ...this.populateSot(sotValue, currentSOTBRIC) }
          }
        }

        if (volumeRowIndex !== null) {
          this.rows[volumeRowIndex].cells.forEach((cell: Cell, cellIndex) => {
            if (typeof this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON === 'object'
              && this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction) {
              if (this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.hasOwnProperty('-99')) {
                delete this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction['-99'];
              }
              this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.isAllSelection = false;
              this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
              this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
              if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
              }
              if (this.rows[volumeRowIndex].cells[cell.cellIndex].selected && !this.rows[volumeRowIndex].cells[cell.cellIndex].isEmpty) {
                this.rows[volumeRowIndex].cells[cell.cellIndex].selected.isAllSelection = false;
                this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotFloor = sotValue.sotFloor;
                this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotCeiling = sotValue.sotCeiling;
                if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                  this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotIncrement = sotValue.sotIncrement;
                }
              }
              this.rows[volumeRowIndex].cells[cell.cellIndex].toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Volume, this.rows[volumeRowIndex].cells[cell.cellIndex].selected);
            }
          });
        }
        if (budgetRowIndex !== null) {
          this.rows[budgetRowIndex].cells.forEach((cell: Cell, cellIndex) => {
            if (typeof this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON === 'object'
              && this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget) {
              if (this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.hasOwnProperty('-99')) {
                delete this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget['-99'];
              }
              this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.isAllSelection = false;
              this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotFloor = sotValue.sotFloor;
              this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotCeiling = sotValue.sotCeiling;
              if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotIncrement = sotValue.sotIncrement;
              }
              if (this.rows[budgetRowIndex].cells[cell.cellIndex].selected && !this.rows[budgetRowIndex].cells[cell.cellIndex].isEmpty) {
                this.rows[budgetRowIndex].cells[cell.cellIndex].selected.isAllSelection = false;
                this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotFloor = sotValue.sotFloor;
                this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotCeiling = sotValue.sotCeiling;
                if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                  this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotIncrement = sotValue.sotIncrement;
                }
              }
              this.rows[budgetRowIndex].cells[cell.cellIndex].toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Budget, this.rows[budgetRowIndex].cells[cell.cellIndex].selected);
            }
          });
        }

        if (objectiveRowIndex !== null) {

          this.rows[budgetRowIndex].cells.forEach((cell: Cell, cellIndex) => {
            if (typeof this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON === 'object'
              && this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON.selectionObjectives) {

              if (this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON.selectionObjectives.hasOwnProperty('bricsData')) {
                this.rows[objectiveRowIndex].cells[cellIndex].requestJSON.selectionObjectives.bricsData.forEach((bricsData, index) => {
                  if (bricsData.hasOwnProperty('selectionAudienceReduction')) {
                    if (alternetVolumeRowIndex !== null
                      && bricsData.selectionAudienceReduction) {
                      bricsData.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                      bricsData.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                      if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                        bricsData.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                      }
                    }
                  }

                  if (bricsData.hasOwnProperty('selectionBudget')) {
                    if (alternetVolumeRowIndex !== null
                      && bricsData.selectionBudget) {
                      bricsData.selectionBudget.sotFloor = sotValue.sotFloor;
                      bricsData.selectionBudget.sotCeiling = sotValue.sotCeiling;
                      if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                        bricsData.selectionBudget.sotIncrement = sotValue.sotIncrement;
                      }
                    }
                  }
                });
              }

              if (this.objectType == "workspace" && alternetVolumeRowIndex !== null) {
                this.rows[objectiveRowIndex].cells[cell.cellIndex].selected.objectives[alternetVolumeRowIndex].cells.forEach((innerCell, inncerCellIndex) => {

                  if (typeof innerCell.requestJSON === 'object'
                    && innerCell.requestJSON.selectionAudienceReduction) {
                    innerCell.requestJSON.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                    innerCell.requestJSON.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                    innerCell.requestJSON.selectionAudienceReduction.isAllSelection = false;
                    if (sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) {
                      innerCell.requestJSON.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                    }
                    if (innerCell.selected && !innerCell.isEmpty) {
                      innerCell.selected.sotFloor = sotValue.sotFloor;
                      innerCell.selected.sotCeiling = sotValue.sotCeiling;
                      if (sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) {
                        innerCell.selected.sotIncrement = sotValue.sotIncrement;
                      }
                      innerCell.selected.isAllSelection = false;
                    }
                    innerCell.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Volume, innerCell.selected);
                  }
                });
              }

              if (this.objectType == "workspace" && alternetBudgetRowIndex !== null) {
                this.rows[objectiveRowIndex].cells[cell.cellIndex].selected.objectives[alternetBudgetRowIndex].cells.forEach((innerCell, inncerCellIndex) => {

                  if (typeof innerCell.requestJSON === 'object'
                    && innerCell.requestJSON.selectionBudget) {
                    innerCell.requestJSON.selectionBudget.sotFloor = sotValue.sotFloor;
                    innerCell.requestJSON.selectionBudget.sotCeiling = sotValue.sotCeiling;
                    innerCell.requestJSON.selectionBudget.isAllSelection = false;
                    if (sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) {
                      innerCell.requestJSON.selectionBudget.sotIncrement = sotValue.sotIncrement;
                    }
                    if (innerCell.selected && !innerCell.isEmpty) {
                      innerCell.selected.sotFloor = sotValue.sotFloor;
                      innerCell.selected.sotCeiling = sotValue.sotCeiling;
                      if (sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) {
                        innerCell.selected.sotIncrement = sotValue.sotIncrement;
                      }
                      innerCell.selected.isAllSelection = false;
                    }
                    innerCell.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Budget, innerCell.selected);
                  }
                });
              }
            }
          });
        }
      } else {
        this.rows[timeRowIndex].cells.forEach((cell: Cell, cellIndex) => {
          const sotBRIC: Cell = cell;
          const inUseByResizable = this.rows[timeRowIndex].getOverlappingCell(cell.cellIndex);
          const columnSummary = SystemFlags.incorrectSolution ? null : this.stateService.getAllColumnSummary();
          if (inUseByResizable !== -1) {
            const sotResizedBric = this.rows[timeRowIndex].cells[inUseByResizable];
            if (typeof sotResizedBric.requestJSON === 'object' && sotResizedBric.requestJSON.selectionSot && sotResizedBric.requestJSON.selectionSot.hasOwnProperty('-99')) {
              this.allSelectionFloorCeilingBrics(volumeRowIndex, budgetRowIndex, cellIndex);
            } else {
              let productHelper;
              if (this.productDetails && this.productDetails.length) {
                productHelper = this.getProductHelperIfProductExists(sotResizedBric.cellIndex);
              }

              let allowTOchangeSOTValue = (this.appHeaderService.enabledPCM && sotResizedBric && Object.keys(sotResizedBric.selected).length && sotResizedBric.selected.increment) ||
                (!this.appHeaderService.enabledPCM && this.objectType != "objective" && sotResizedBric && Object.keys(sotResizedBric.selected).length) ? true : false;;

              if (allowTOchangeSOTValue) {
                let sotValue: SotFloorCeilingDataModel = {
                  sotFloor: sotResizedBric.selected.min != undefined && sotResizedBric.selected.min != null ? parseFloat(sotResizedBric.selected.min.toString()) : sotResizedBric.selected.min,
                  sotCeiling: sotResizedBric.selected.max != undefined && sotResizedBric.selected.max != null ? parseFloat(sotResizedBric.selected.max.toString()) : sotResizedBric.selected.max,
                  sotIncrement: sotResizedBric.selected.increment != undefined && sotResizedBric.selected.increment != null ? parseFloat(sotResizedBric.selected.increment.toString()) : sotResizedBric.selected.increment
                };

                if (!this.appHeaderService.enabledPCM && sotResizedBric.selected.sot) {
                  if (sotResizedBric.selected.sot === 'Mixed') {
                    if (columnSummary && columnSummary[cellIndex]) {
                      sotValue.sotFloor = parseFloat(columnSummary[cellIndex].sot[0].toString());
                      const index = columnSummary[cellIndex].sot.length;
                      sotValue.sotCeiling = parseFloat(columnSummary[cellIndex].sot[index - 1].toString());
                    }
                  } else if (this.objectType == "pcm" && sotBRIC.selected.min >= 0 && sotBRIC.selected.max >= 0) {
                    // SM-10712
                    sotValue.sotFloor = sotBRIC.selected.min;
                    sotValue.sotCeiling = sotBRIC.selected.max;
                    return sotValue;
                  } else {
                    sotValue = { ...sotValue, ...this.populateSot(sotValue, sotResizedBric) }
                  }
                }

                if (volumeRowIndex !== null) {
                  if (typeof this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON === 'object'
                    && this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction) {
                    if (this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.hasOwnProperty('-99')) {
                      delete this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction['-99'];
                    }
                    this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.isAllSelection = false;
                    this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                    this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                    if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                      this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                    }
                    if (this.rows[volumeRowIndex].cells[cell.cellIndex].selected && !this.rows[volumeRowIndex].cells[cell.cellIndex].isEmpty) {
                      this.rows[volumeRowIndex].cells[cell.cellIndex].selected.isAllSelection = false;
                      this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotFloor = sotValue.sotFloor;
                      this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotCeiling = sotValue.sotCeiling;
                      if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                        this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotIncrement = sotValue.sotIncrement;
                      }
                    }
                    this.rows[volumeRowIndex].cells[cell.cellIndex].toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Volume, this.rows[volumeRowIndex].cells[cell.cellIndex].selected);
                  }
                }
                if (budgetRowIndex !== null) {
                  if (typeof this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON === 'object'
                    && this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget) {
                    if (this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.hasOwnProperty('-99')) {
                      delete this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget['-99'];
                    }
                    this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.isAllSelection = false;
                    this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotFloor = sotValue.sotFloor;
                    this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotCeiling = sotValue.sotCeiling;
                    if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                      this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotIncrement = sotValue.sotIncrement;
                    }
                    if (this.rows[budgetRowIndex].cells[cell.cellIndex].selected && !this.rows[budgetRowIndex].cells[cell.cellIndex].isEmpty) {
                      this.rows[budgetRowIndex].cells[cell.cellIndex].selected.isAllSelection = false;
                      this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotFloor = sotValue.sotFloor;
                      this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotCeiling = sotValue.sotCeiling;
                      if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                        this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotIncrement = sotValue.sotIncrement;
                      }
                    }
                    this.rows[budgetRowIndex].cells[cell.cellIndex].toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Budget, this.rows[budgetRowIndex].cells[cell.cellIndex].selected);
                  }
                }
                if (objectiveRowIndex !== null
                  && typeof this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON === 'object'
                  && this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON.selectionObjectives) {

                  if (this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON.selectionObjectives.hasOwnProperty('bricsData')) {
                    this.rows[objectiveRowIndex].cells[cellIndex].requestJSON.selectionObjectives.bricsData.forEach((bricsData, index) => {
                      if (bricsData.hasOwnProperty('selectionAudienceReduction')) {
                        if (alternetVolumeRowIndex !== null
                          && bricsData.selectionAudienceReduction) {
                          bricsData.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                          bricsData.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                          if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                            bricsData.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                          }
                        }
                      }

                      if (bricsData.hasOwnProperty('selectionBudget')) {
                        if (alternetVolumeRowIndex !== null
                          && bricsData.selectionBudget) {
                          bricsData.selectionBudget.sotFloor = sotValue.sotFloor;
                          bricsData.selectionBudget.sotCeiling = sotValue.sotCeiling;
                          if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                            bricsData.selectionBudget.sotIncrement = sotValue.sotIncrement;
                          }
                        }
                      }
                    });
                  }

                  if (this.objectType == "workspace" && alternetVolumeRowIndex !== null) {
                    this.rows[objectiveRowIndex].cells[cell.cellIndex].selected.objectives[alternetVolumeRowIndex].cells.forEach((innerCell, inncerCellIndex) => {

                      if (typeof innerCell.requestJSON === 'object'
                        && innerCell.requestJSON.selectionAudienceReduction) {
                        innerCell.requestJSON.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                        innerCell.requestJSON.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                        innerCell.requestJSON.selectionAudienceReduction.isAllSelection = false;
                        if (sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) {
                          innerCell.requestJSON.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                        }
                        if (innerCell.selected && !innerCell.isEmpty) {
                          innerCell.selected.sotFloor = sotValue.sotFloor;
                          innerCell.selected.sotCeiling = sotValue.sotCeiling;
                          if (sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) {
                            innerCell.selected.sotIncrement = sotValue.sotIncrement;
                          }
                          innerCell.selected.isAllSelection = false;
                        }
                        innerCell.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Volume, innerCell.selected);
                      }
                    });
                  }

                  if (this.objectType == "workspace" && alternetBudgetRowIndex !== null) {
                    this.rows[objectiveRowIndex].cells[cell.cellIndex].selected.objectives[alternetBudgetRowIndex].cells.forEach((innerCell, inncerCellIndex) => {

                      if (typeof innerCell.requestJSON === 'object'
                        && innerCell.requestJSON.selectionBudget) {
                        innerCell.requestJSON.selectionBudget.sotFloor = sotValue.sotFloor;
                        innerCell.requestJSON.selectionBudget.sotCeiling = sotValue.sotCeiling;
                        innerCell.requestJSON.selectionBudget.isAllSelection = false;
                        if (sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) {
                          innerCell.requestJSON.selectionBudget.sotIncrement = sotValue.sotIncrement;
                        }
                        if (innerCell.selected && !innerCell.isEmpty) {
                          innerCell.selected.sotFloor = sotValue.sotFloor;
                          innerCell.selected.sotCeiling = sotValue.sotCeiling;
                          if (sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) {
                            innerCell.selected.sotIncrement = sotValue.sotIncrement;
                          }
                          innerCell.selected.isAllSelection = false;
                        }
                        innerCell.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Budget, innerCell.selected);
                      }
                    });
                  }
                }
              } else {
                needFromAlternet ?
                  this.genFloorCeilingBricsReqJSON(volumeRowIndex, budgetRowIndex, objectiveRowIndex, needFromAlternet, alternetVolumeBudgetRowIndex, alternetVolumeRowIndex, alternetBudgetRowIndex) :
                  this.genFloorCeilingBricsReqJSON(volumeRowIndex, budgetRowIndex, objectiveRowIndex, needFromAlternet, alternetVolumeBudgetRowIndex, alternetVolumeRowIndex, alternetBudgetRowIndex);
              }
            }
          } else { // Brick is not Stretched
            if (typeof sotBRIC.requestJSON === 'object' && sotBRIC.requestJSON.selectionSot && sotBRIC.requestJSON.selectionSot.hasOwnProperty('-99')) {
              this.allSelectionFloorCeilingBrics(volumeRowIndex, budgetRowIndex, cellIndex);
            } else {

              let productHelper;
              if (this.productDetails && this.productDetails.length) {
                productHelper = this.getProductHelperIfProductExists(sotBRIC.cellIndex);
              }

              let allowTOchangeSOTValue = (this.appHeaderService.enabledPCM && sotBRIC && Object.keys(sotBRIC.selected).length && sotBRIC.selected.increment) ||
                (!this.appHeaderService.enabledPCM && this.objectType != "objective" && sotBRIC && Object.keys(sotBRIC.selected).length) ? true : false;;

              if (allowTOchangeSOTValue) {
                let sotValue: SotFloorCeilingDataModel = {
                  sotFloor: sotBRIC.selected.min != undefined && sotBRIC.selected.min != null ? parseFloat(sotBRIC.selected.min.toString()) : sotBRIC.selected.min,
                  sotCeiling: sotBRIC.selected.max != undefined && sotBRIC.selected.max != null ? parseFloat(sotBRIC.selected.max.toString()) : sotBRIC.selected.max,
                  sotIncrement: sotBRIC.selected.increment != undefined && sotBRIC.selected.increment != null ? parseFloat(sotBRIC.selected.increment.toString()) : sotBRIC.selected.increment
                };

                if (!this.appHeaderService.enabledPCM && sotBRIC.selected.sot && !sotBRIC.isEmpty) {
                  if (sotBRIC.selected.sot === 'Mixed') {
                    if (columnSummary && columnSummary[cellIndex] && columnSummary[cellIndex].sot && columnSummary[cellIndex].sot.length) {
                      sotValue.sotFloor = parseFloat(columnSummary[cellIndex].sot[0].toString());
                      const index = columnSummary[cellIndex].sot.length;
                      sotValue.sotCeiling = parseFloat(columnSummary[cellIndex].sot[index - 1].toString());
                    }
                  } else if (this.objectType == "pcm" && sotBRIC.selected.min >= 0 && sotBRIC.selected.max >= 0) {
                    // SM-10712
                    sotValue.sotFloor = sotBRIC.selected.min;
                    sotValue.sotCeiling = sotBRIC.selected.max;
                    return sotValue;
                  } else {
                    sotValue = { ...sotValue, ...this.populateSot(sotValue, sotBRIC) }
                  }
                }
                if (volumeRowIndex !== null) {
                  if (typeof this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON === 'object'
                    && this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction) {
                    if (this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.hasOwnProperty('-99')) {
                      delete this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction['-99'];
                    }
                    this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.isAllSelection = false;
                    this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                    this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                    if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                      this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                    }
                    if (this.rows[volumeRowIndex].cells[cell.cellIndex].selected && !this.rows[volumeRowIndex].cells[cell.cellIndex].isEmpty) {
                      this.rows[volumeRowIndex].cells[cell.cellIndex].selected.isAllSelection = false;
                      this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotFloor = sotValue.sotFloor;
                      this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotCeiling = sotValue.sotCeiling;
                      if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                        this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotIncrement = sotValue.sotIncrement;
                      }
                    }
                    this.rows[volumeRowIndex].cells[cell.cellIndex].toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Volume, this.rows[volumeRowIndex].cells[cell.cellIndex].selected);
                  }
                }
                if (budgetRowIndex !== null) {
                  if (typeof this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON === 'object'
                    && this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget) {
                    if (this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.hasOwnProperty('-99')) {
                      delete this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget['-99'];
                    }
                    this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.isAllSelection = false;
                    this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotFloor = sotValue.sotFloor;
                    this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotCeiling = sotValue.sotCeiling;
                    if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                      this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotIncrement = sotValue.sotIncrement;
                    }
                    if (this.rows[budgetRowIndex].cells[cell.cellIndex].selected && !this.rows[budgetRowIndex].cells[cell.cellIndex].isEmpty) {
                      this.rows[budgetRowIndex].cells[cell.cellIndex].selected.isAllSelection = false;
                      this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotFloor = sotValue.sotFloor;
                      this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotCeiling = sotValue.sotCeiling;
                      if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                        this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotIncrement = sotValue.sotIncrement;
                      }
                    }
                    this.rows[budgetRowIndex].cells[cell.cellIndex].toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Budget, this.rows[budgetRowIndex].cells[cell.cellIndex].selected);
                  }
                }
                if (objectiveRowIndex !== null
                  && typeof this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON === 'object'
                  && this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON.selectionObjectives) {

                  if (this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON.selectionObjectives.hasOwnProperty('bricsData')) {
                    this.rows[objectiveRowIndex].cells[cellIndex].requestJSON.selectionObjectives.bricsData.forEach((bricsData, index) => {
                      if (bricsData.hasOwnProperty('selectionAudienceReduction')) {
                        if (alternetVolumeRowIndex !== null
                          && bricsData.selectionAudienceReduction) {
                          bricsData.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                          bricsData.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                          if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                            bricsData.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                          }
                        }
                      }

                      if (bricsData.hasOwnProperty('selectionBudget')) {
                        if (alternetVolumeRowIndex !== null
                          && bricsData.selectionBudget) {
                          bricsData.selectionBudget.sotFloor = sotValue.sotFloor;
                          bricsData.selectionBudget.sotCeiling = sotValue.sotCeiling;
                          if ((sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) || this.objectType == "pcm") {
                            bricsData.selectionBudget.sotIncrement = sotValue.sotIncrement;
                          }
                        }
                      }
                    });
                  }

                  if (this.objectType == "workspace" && alternetVolumeRowIndex !== null) {
                    this.rows[objectiveRowIndex].cells[cell.cellIndex].selected.objectives[alternetVolumeRowIndex].cells.forEach((innerCell, inncerCellIndex) => {

                      if (typeof innerCell.requestJSON === 'object'
                        && innerCell.requestJSON.selectionAudienceReduction) {
                        innerCell.requestJSON.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                        innerCell.requestJSON.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                        innerCell.requestJSON.selectionAudienceReduction.isAllSelection = false;
                        if (sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) {
                          innerCell.requestJSON.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                        }
                        if (innerCell.selected && !innerCell.isEmpty) {
                          innerCell.selected.sotFloor = sotValue.sotFloor;
                          innerCell.selected.sotCeiling = sotValue.sotCeiling;
                          if (sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) {
                            innerCell.selected.sotIncrement = sotValue.sotIncrement;
                          }
                          innerCell.selected.isAllSelection = false;
                        }
                        innerCell.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Volume, innerCell.selected);
                      }
                    });
                  }

                  if (this.objectType == "workspace" && alternetBudgetRowIndex !== null) {
                    this.rows[objectiveRowIndex].cells[cell.cellIndex].selected.objectives[alternetBudgetRowIndex].cells.forEach((innerCell, inncerCellIndex) => {

                      if (typeof innerCell.requestJSON === 'object'
                        && innerCell.requestJSON.selectionBudget) {
                        innerCell.requestJSON.selectionBudget.sotFloor = sotValue.sotFloor;
                        innerCell.requestJSON.selectionBudget.sotCeiling = sotValue.sotCeiling;
                        innerCell.requestJSON.selectionBudget.isAllSelection = false;
                        if (sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) {
                          innerCell.requestJSON.selectionBudget.sotIncrement = sotValue.sotIncrement;
                        }
                        if (innerCell.selected && !innerCell.isEmpty) {
                          innerCell.selected.sotFloor = sotValue.sotFloor;
                          innerCell.selected.sotCeiling = sotValue.sotCeiling;
                          if (sotValue.sotIncrement != undefined && sotValue.sotIncrement != null) {
                            innerCell.selected.sotIncrement = sotValue.sotIncrement;
                          }
                          innerCell.selected.isAllSelection = false;
                        }
                        innerCell.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Budget, innerCell.selected);
                      }
                    });
                  }
                }
              } else {
                needFromAlternet ?
                  this.genFloorCeilingBricsReqJSON(volumeRowIndex, budgetRowIndex, objectiveRowIndex, needFromAlternet, alternetVolumeBudgetRowIndex, alternetVolumeRowIndex, alternetBudgetRowIndex) :
                  this.genFloorCeilingBricsReqJSON(volumeRowIndex, budgetRowIndex, objectiveRowIndex, needFromAlternet, alternetVolumeBudgetRowIndex, alternetVolumeRowIndex, alternetBudgetRowIndex);
              }
            }
          }
        });
      }
    } else {
      needFromAlternet ?
        this.genFloorCeilingBricsReqJSON(volumeRowIndex, budgetRowIndex, objectiveRowIndex, needFromAlternet, alternetVolumeBudgetRowIndex, alternetVolumeRowIndex, alternetBudgetRowIndex) :
        this.genFloorCeilingBricsReqJSON(volumeRowIndex, budgetRowIndex, objectiveRowIndex, needFromAlternet, alternetVolumeBudgetRowIndex, alternetVolumeRowIndex, alternetBudgetRowIndex);
    }

  }

  allSelectionFloorCeilingBrics(volumeRowIndex, budgetRowIndex, index) {
    if (volumeRowIndex !== null
      && typeof this.rows[volumeRowIndex].cells[index].requestJSON === 'object'
      && this.rows[volumeRowIndex].cells[index].requestJSON.selectionAudienceReduction
      && this.rows[volumeRowIndex].cells[index].requestJSON.selectionAudienceReduction.sotIncrement) {
      this.rows[volumeRowIndex].cells[index].requestJSON.selectionAudienceReduction.sotIncrement = null;
    }
    if (budgetRowIndex !== null
      && typeof this.rows[budgetRowIndex].cells[index].requestJSON === 'object'
      && this.rows[budgetRowIndex].cells[index].requestJSON.selectionBudget
      && this.rows[budgetRowIndex].cells[index].requestJSON.selectionBudget.sotIncrement) {
      this.rows[budgetRowIndex].cells[index].requestJSON.selectionBudget.sotIncrement = null;
    }
  }

  genFloorCeilingBricsReqJSON(volumeRowIndex, budgetRowIndex, objectiveRowIndex?, needFromAlternet?, alternetVolumeBudgetRowIndex?, alternetVolumeRowIndex?, alternetBudgetRowIndex?) {
    let rowIndex = null;
    let volumeCellIndex = null;
    let budgetCellIndex = null;
    if (objectiveRowIndex !== null && alternetVolumeBudgetRowIndex) {
      rowIndex = _.min([volumeRowIndex, budgetRowIndex, objectiveRowIndex]);
    } else {
      rowIndex = _.min([volumeRowIndex, budgetRowIndex]);
    }
    if (this.objectType == "objective" && !needFromAlternet) {
      volumeCellIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Volume, this.rows).Available_First_Cell_Index;
      budgetCellIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Budget, this.rows).Available_First_Cell_Index;

      rowIndex = (volumeCellIndex !== null && budgetCellIndex !== null) ?
        (volumeCellIndex == budgetCellIndex ? _.min([volumeRowIndex, budgetRowIndex]) :
          (volumeCellIndex < budgetCellIndex ? volumeRowIndex : budgetRowIndex)) :
        (volumeCellIndex !== null ? volumeRowIndex :
          (budgetCellIndex !== null ? budgetRowIndex : null));
    }
    if (rowIndex != undefined) {
      this.rows[rowIndex].cells.forEach((cell: Cell, loopIndex) => {

        const stretchedIndex = rowIndex !== null ? this.rows[rowIndex].getOverlappingCell(cell.cellIndex) : -1;
        let cellIndex = stretchedIndex !== -1 ? stretchedIndex : cell.cellIndex;
        if (this.objectType == "objective" && !needFromAlternet) {
          cellIndex = _.min([volumeCellIndex, budgetCellIndex]);
        }

        if (rowIndex !== null) {
          //get the highest privilage cell to copy SOT values
          const budgetOrVolumeBric: Cell = this.rows[rowIndex].cells[cellIndex];
          // isCellAvailable property will be set to true if the cell is present at that rowIndex
          let isCellAvailable = rowIndex !== null && this.rows[rowIndex].cells[cell.cellIndex] && this.rows[rowIndex].cells[cell.cellIndex].selected ?
            Object.keys(this.rows[rowIndex].cells[cell.cellIndex].selected).length && !this.rows[rowIndex].cells[cell.cellIndex].isEmpty ? true : false : false;
          const sotValue: SotFloorCeilingDataModel = {
            sotFloor: budgetOrVolumeBric.selected.sotFloor != undefined && budgetOrVolumeBric.selected.sotFloor != null ? parseFloat(budgetOrVolumeBric.selected.sotFloor.toString()) : budgetOrVolumeBric.selected.sotFloor,
            sotCeiling: budgetOrVolumeBric.selected.sotCeiling != undefined && budgetOrVolumeBric.selected.sotCeiling != null ? parseFloat(budgetOrVolumeBric.selected.sotCeiling.toString()) : budgetOrVolumeBric.selected.sotCeiling,
            sotIncrement: budgetOrVolumeBric.selected.sotIncrement != undefined && budgetOrVolumeBric.selected.sotIncrement != null ? parseFloat(budgetOrVolumeBric.selected.sotIncrement.toString()) : budgetOrVolumeBric.selected.sotIncrement
          };

          let allowTOchangeSOTValue = (this.appHeaderService.enabledPCM && budgetOrVolumeBric.selected.sotIncrement) ||
            (!this.appHeaderService.enabledPCM) ? true : false;

          if (objectiveRowIndex == rowIndex && alternetVolumeBudgetRowIndex && isCellAvailable) {
            if (typeof budgetOrVolumeBric.requestJSON === 'object' && budgetOrVolumeBric.requestJSON.selectionObjectives && budgetOrVolumeBric.requestJSON.selectionObjectives.hasOwnProperty('-99')) {
              // this.allSelectionFloorCeilingBrics(null, budgetRowIndex, cellIndex)
            } else if (budgetOrVolumeBric.selected && budgetOrVolumeBric.selected.objectives && budgetOrVolumeBric.selected.objectives[alternetVolumeBudgetRowIndex].cells[0].selected) {

              let isInnerCellAvailable = false;
              budgetOrVolumeBric.selected.objectives.forEach(function (row, index) {
                if (alternetBudgetRowIndex == index || alternetVolumeRowIndex == index) {
                  row.cells.forEach(function (cell, index) {
                    if (!cell.isEmpty && cell.selected) {
                      if (cell.selected.sotFloor != undefined && cell.selected.sotFloor != null) {
                        let floor = parseFloat(cell.selected.sotFloor.toString());
                        if (sotValue.sotFloor != undefined && sotValue.sotFloor != null) {
                          sotValue.sotFloor = floor < sotValue.sotFloor ? floor : sotValue.sotFloor;
                        } else {
                          sotValue.sotFloor = floor;
                        }
                      }

                      if (cell.selected.sotCeiling != undefined && cell.selected.sotCeiling != null) {
                        let ceiling = parseFloat(cell.selected.sotCeiling.toString());
                        if (sotValue.sotCeiling != undefined && sotValue.sotCeiling != null) {
                          sotValue.sotCeiling = ceiling > sotValue.sotCeiling ? ceiling : sotValue.sotCeiling;
                        } else {
                          sotValue.sotCeiling = ceiling;
                        }
                      }

                      if (sotValue.sotFloor != undefined && sotValue.sotFloor != null && sotValue.sotCeiling != undefined && sotValue.sotCeiling != null) {
                        isInnerCellAvailable = true;
                      }
                    }
                  });
                }
              });

              sotValue.sotIncrement = budgetOrVolumeBric.selected.objectives[alternetVolumeBudgetRowIndex].cells[0].selected.sotIncrement != undefined
                && budgetOrVolumeBric.selected.objectives[alternetVolumeBudgetRowIndex].cells[0].selected.sotIncrement != null ?
                parseFloat(budgetOrVolumeBric.selected.objectives[alternetVolumeBudgetRowIndex].cells[0].selected.sotIncrement.toString()) :
                budgetOrVolumeBric.selected.objectives[alternetVolumeBudgetRowIndex].cells[0].selected.sotIncrement;

              if (budgetRowIndex !== null && isInnerCellAvailable
                && typeof this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON === 'object'
                && this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget) {
                if (this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.hasOwnProperty('-99')) {
                  delete this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget['-99'];
                }
                this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.isAllSelection = false;
                if (allowTOchangeSOTValue) {
                  this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotFloor = sotValue.sotFloor;
                  this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotCeiling = sotValue.sotCeiling;
                  if (this.rows[budgetRowIndex].cells[cell.cellIndex].selected && !this.rows[budgetRowIndex].cells[cell.cellIndex].isEmpty) {
                    this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotFloor = sotValue.sotFloor;
                    this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotCeiling = sotValue.sotCeiling;
                  }
                }
                this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotIncrement = sotValue.sotIncrement;
                if (this.rows[budgetRowIndex].cells[cell.cellIndex].selected && !this.rows[budgetRowIndex].cells[cell.cellIndex].isEmpty) {
                  this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotIncrement = sotValue.sotIncrement;
                  this.rows[budgetRowIndex].cells[cell.cellIndex].selected.isAllSelection = false;
                }
                this.rows[budgetRowIndex].cells[cell.cellIndex].toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Budget, this.rows[budgetRowIndex].cells[cell.cellIndex].selected);
              }

              if (volumeRowIndex !== null && isInnerCellAvailable
                && typeof this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON === 'object'
                && this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction) {
                if (this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.hasOwnProperty('-99')) {
                  delete this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction['-99'];
                }
                if (allowTOchangeSOTValue) {
                  this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                  this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                  if (this.rows[volumeRowIndex].cells[cell.cellIndex].selected && !this.rows[volumeRowIndex].cells[cell.cellIndex].isEmpty) {
                    this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotFloor = sotValue.sotFloor;
                    this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotCeiling = sotValue.sotCeiling;
                  }
                }
                this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.isAllSelection = false;
                this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                if (this.rows[volumeRowIndex].cells[cell.cellIndex].selected && !this.rows[volumeRowIndex].cells[cell.cellIndex].isEmpty) {
                  this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotIncrement = sotValue.sotIncrement;
                  this.rows[volumeRowIndex].cells[cell.cellIndex].selected.isAllSelection = false;
                }
                this.rows[volumeRowIndex].cells[cell.cellIndex].toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Volume, this.rows[volumeRowIndex].cells[cell.cellIndex].selected);
              }
            }
          }

          if (volumeRowIndex == rowIndex && isCellAvailable) {
            if (typeof budgetOrVolumeBric.requestJSON === 'object' && budgetOrVolumeBric.requestJSON.selectionAudienceReduction && budgetOrVolumeBric.requestJSON.selectionAudienceReduction.hasOwnProperty('-99')) {
              this.allSelectionFloorCeilingBrics(null, budgetRowIndex, cellIndex)
            } else {
              if (volumeRowIndex !== null
                && typeof this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON === 'object'
                && this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction) {
                if (allowTOchangeSOTValue) {
                  this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                  this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                  if (this.rows[volumeRowIndex].cells[cell.cellIndex].selected && !this.rows[volumeRowIndex].cells[cell.cellIndex].isEmpty) {
                    this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotFloor = sotValue.sotFloor;
                    this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotCeiling = sotValue.sotCeiling;
                  }
                }
                this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                this.rows[volumeRowIndex].cells[cell.cellIndex].toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Volume, this.rows[volumeRowIndex].cells[cell.cellIndex].selected);
              }

              if (budgetRowIndex !== null
                && typeof this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON === 'object'
                && this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget) {
                if (this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.hasOwnProperty('-99')) {
                  delete this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget['-99'];
                }
                this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.isAllSelection = false;
                if (allowTOchangeSOTValue) {
                  this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotFloor = sotValue.sotFloor;
                  this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotCeiling = sotValue.sotCeiling;
                  if (this.rows[budgetRowIndex].cells[cell.cellIndex].selected && !this.rows[budgetRowIndex].cells[cell.cellIndex].isEmpty) {
                    this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotFloor = sotValue.sotFloor;
                    this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotCeiling = sotValue.sotCeiling;
                  }
                }
                this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotIncrement = sotValue.sotIncrement;
                if (this.rows[budgetRowIndex].cells[cell.cellIndex].selected && !this.rows[budgetRowIndex].cells[cell.cellIndex].isEmpty) {
                  this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotIncrement = sotValue.sotIncrement;
                  this.rows[budgetRowIndex].cells[cell.cellIndex].selected.isAllSelection = false;
                }
                this.rows[budgetRowIndex].cells[cell.cellIndex].toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Budget, this.rows[budgetRowIndex].cells[cell.cellIndex].selected);
              }

              if (objectiveRowIndex !== null && this.rows[objectiveRowIndex]
                && typeof this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON === 'object'
                && this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON.selectionObjectives) {
                if (allowTOchangeSOTValue) {
                  if (this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON.selectionObjectives.hasOwnProperty('bricsData')) {
                    this.rows[objectiveRowIndex].cells[cellIndex].requestJSON.selectionObjectives.bricsData.forEach((bricsData, index) => {
                      if (bricsData.hasOwnProperty('selectionAudienceReduction')) {
                        if (alternetVolumeRowIndex !== null
                          && bricsData.selectionAudienceReduction) {
                          bricsData.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                          bricsData.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                          bricsData.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                        }
                      }

                      if (bricsData.hasOwnProperty('selectionBudget')) {
                        if (alternetVolumeRowIndex !== null
                          && bricsData.selectionBudget) {
                          bricsData.selectionBudget.sotFloor = sotValue.sotFloor;
                          bricsData.selectionBudget.sotCeiling = sotValue.sotCeiling;
                          bricsData.selectionBudget.sotIncrement = sotValue.sotIncrement;
                        }
                      }
                    });
                  }

                  if (this.objectType == "workspace" && alternetVolumeRowIndex !== null) {
                    this.rows[objectiveRowIndex]?.cells[cell.cellIndex].selected.objectives[alternetVolumeRowIndex]?.cells.forEach((innerCell, inncerCellIndex) => {

                      if (typeof innerCell.requestJSON === 'object'
                        && innerCell.requestJSON.selectionAudienceReduction) {
                        innerCell.requestJSON.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                        innerCell.requestJSON.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                        innerCell.requestJSON.selectionAudienceReduction.isAllSelection = false;
                        innerCell.requestJSON.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                        if (innerCell.selected && !innerCell.isEmpty) {
                          innerCell.selected.sotFloor = sotValue.sotFloor;
                          innerCell.selected.sotCeiling = sotValue.sotCeiling;
                          innerCell.selected.sotIncrement = sotValue.sotIncrement;
                          innerCell.selected.isAllSelection = false;
                        }
                        innerCell.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Volume, innerCell.selected);
                      }
                    });
                  }

                  if (this.objectType == "workspace" && alternetBudgetRowIndex !== null) {
                    this.rows[objectiveRowIndex].cells[cell.cellIndex].selected.objectives[alternetBudgetRowIndex].cells.forEach((innerCell, inncerCellIndex) => {

                      if (typeof innerCell.requestJSON === 'object'
                        && innerCell.requestJSON.selectionBudget) {
                        innerCell.requestJSON.selectionBudget.sotFloor = sotValue.sotFloor;
                        innerCell.requestJSON.selectionBudget.sotCeiling = sotValue.sotCeiling;
                        innerCell.requestJSON.selectionBudget.isAllSelection = false;
                        innerCell.requestJSON.selectionBudget.sotIncrement = sotValue.sotIncrement;
                        if (innerCell.selected && !innerCell.isEmpty) {
                          innerCell.selected.sotFloor = sotValue.sotFloor;
                          innerCell.selected.sotCeiling = sotValue.sotCeiling;
                          innerCell.selected.sotIncrement = sotValue.sotIncrement;
                          innerCell.selected.isAllSelection = false;
                        }
                        innerCell.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Budget, innerCell.selected);
                      }
                    });
                  }
                }
              }
            }
          }

          if (budgetRowIndex == rowIndex && isCellAvailable) {
            if (typeof budgetOrVolumeBric.requestJSON === 'object' && budgetOrVolumeBric.requestJSON.selectionBudget && budgetOrVolumeBric.requestJSON.selectionBudget.hasOwnProperty('-99')) {
              this.allSelectionFloorCeilingBrics(volumeRowIndex, null, cellIndex)
            } else {
              if (budgetRowIndex !== null
                && typeof this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON === 'object'
                && this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget) {
                if (allowTOchangeSOTValue) {
                  this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotFloor = sotValue.sotFloor;
                  this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotCeiling = sotValue.sotCeiling;
                  if (this.rows[budgetRowIndex].cells[cell.cellIndex].selected && !this.rows[budgetRowIndex].cells[cell.cellIndex].isEmpty) {
                    this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotFloor = sotValue.sotFloor;
                    this.rows[budgetRowIndex].cells[cell.cellIndex].selected.sotCeiling = sotValue.sotCeiling;
                  }
                }
                this.rows[budgetRowIndex].cells[cell.cellIndex].requestJSON.selectionBudget.sotIncrement = sotValue.sotIncrement;
                this.rows[budgetRowIndex].cells[cell.cellIndex].toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Budget, this.rows[budgetRowIndex].cells[cell.cellIndex].selected);
              }

              if (volumeRowIndex !== null
                && typeof this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON === 'object'
                && this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction) {
                if (this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.hasOwnProperty('-99')) {
                  delete this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction['-99'];
                }
                if (allowTOchangeSOTValue) {
                  this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                  this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                  if (this.rows[volumeRowIndex].cells[cell.cellIndex].selected && !this.rows[volumeRowIndex].cells[cell.cellIndex].isEmpty) {
                    this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotFloor = sotValue.sotFloor;
                    this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotCeiling = sotValue.sotCeiling;
                  }
                }
                this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.isAllSelection = false;
                this.rows[volumeRowIndex].cells[cell.cellIndex].requestJSON.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                if (this.rows[volumeRowIndex].cells[cell.cellIndex].selected && !this.rows[volumeRowIndex].cells[cell.cellIndex].isEmpty) {
                  this.rows[volumeRowIndex].cells[cell.cellIndex].selected.sotIncrement = sotValue.sotIncrement;
                  this.rows[volumeRowIndex].cells[cell.cellIndex].selected.isAllSelection = false;
                }
                this.rows[volumeRowIndex].cells[cell.cellIndex].toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Volume, this.rows[volumeRowIndex].cells[cell.cellIndex].selected);
              }

              if (objectiveRowIndex !== null && this.rows[objectiveRowIndex]
                && typeof this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON === 'object'
                && this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON.selectionObjectives) {

                if (allowTOchangeSOTValue) {
                  if (this.rows[objectiveRowIndex].cells[cell.cellIndex].requestJSON.selectionObjectives.hasOwnProperty('bricsData')) {
                    this.rows[objectiveRowIndex].cells[cellIndex].requestJSON.selectionObjectives.bricsData.forEach((bricsData, index) => {
                      if (bricsData.hasOwnProperty('selectionAudienceReduction')) {
                        if (alternetVolumeRowIndex !== null
                          && bricsData.selectionAudienceReduction) {
                          bricsData.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                          bricsData.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                          bricsData.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                        }
                      }

                      if (bricsData.hasOwnProperty('selectionBudget')) {
                        if (alternetVolumeRowIndex !== null
                          && bricsData.selectionBudget) {
                          bricsData.selectionBudget.sotFloor = sotValue.sotFloor;
                          bricsData.selectionBudget.sotCeiling = sotValue.sotCeiling;
                          bricsData.selectionBudget.sotIncrement = sotValue.sotIncrement;
                        }
                      }
                    });
                  }

                  if (this.objectType == "workspace" && alternetVolumeRowIndex !== null) {
                    this.rows[objectiveRowIndex].cells[cell.cellIndex].selected.objectives[alternetVolumeRowIndex].cells.forEach((innerCell, inncerCellIndex) => {

                      if (typeof innerCell.requestJSON === 'object'
                        && innerCell.requestJSON.selectionAudienceReduction) {
                        innerCell.requestJSON.selectionAudienceReduction.sotFloor = sotValue.sotFloor;
                        innerCell.requestJSON.selectionAudienceReduction.sotCeiling = sotValue.sotCeiling;
                        innerCell.requestJSON.selectionAudienceReduction.isAllSelection = false;
                        innerCell.requestJSON.selectionAudienceReduction.sotIncrement = sotValue.sotIncrement;
                        if (innerCell.selected && !innerCell.isEmpty) {
                          innerCell.selected.sotFloor = sotValue.sotFloor;
                          innerCell.selected.sotCeiling = sotValue.sotCeiling;
                          innerCell.selected.sotIncrement = sotValue.sotIncrement;
                          innerCell.selected.isAllSelection = false;
                        }
                        innerCell.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Volume, innerCell.selected);
                      }
                    });
                  }

                  if (this.objectType == "workspace" && alternetBudgetRowIndex !== null) {
                    this.rows[objectiveRowIndex].cells[cell.cellIndex].selected.objectives[alternetBudgetRowIndex].cells.forEach((innerCell, inncerCellIndex) => {

                      if (typeof innerCell.requestJSON === 'object'
                        && innerCell.requestJSON.selectionBudget) {
                        innerCell.requestJSON.selectionBudget.sotFloor = sotValue.sotFloor;
                        innerCell.requestJSON.selectionBudget.sotCeiling = sotValue.sotCeiling;
                        innerCell.requestJSON.selectionBudget.isAllSelection = false;
                        innerCell.requestJSON.selectionBudget.sotIncrement = sotValue.sotIncrement;
                        if (innerCell.selected && !innerCell.isEmpty) {
                          innerCell.selected.sotFloor = sotValue.sotFloor;
                          innerCell.selected.sotCeiling = sotValue.sotCeiling;
                          innerCell.selected.sotIncrement = sotValue.sotIncrement;
                          innerCell.selected.isAllSelection = false;
                        }
                        innerCell.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Budget, innerCell.selected);
                      }
                    });
                  }
                }
              }
            }
          }
        }
      })
    }
    // }
  }

  /**
   * @description Function to get the Min Floor and Max Ceiling from multiple SOT BRICS
   * @author Shreni Shah
   * @param {*} cellIndex - cell index of the brick for which we are making the computations
   * @param {*} rowIndex -  row index of the brick for which we are making the computations
   * @param {*} sotRowIndex - Row Index of Sot Brick
  */
  getFloorAndCeilingForMultipleSOT(cellIndex, rowIndex, sotRowIndex) {
    const brick = this.rows[rowIndex].cells[cellIndex];
    const allSOT = [];
    let sotValue: SotFloorCeilingDataModel = {
      sotCeiling: null,
      sotFloor: null,
      disableFloorAndCeiling: true
    };
    if (this.appHeaderService.enabledPCM) {
      sotValue = { ...sotValue, ...{ 'sotIncrement': null, 'disableSOTIncrement': true } };
    }
    for (let i = cellIndex; i < (brick.cellWidth + brick.cellIndex); i++) {
      let sotBRIC: Cell;
      const sotStretchedIndex = this.rows[sotRowIndex].getOverlappingCell(i);
      if (sotStretchedIndex !== -1) {
        sotBRIC = this.rows[sotRowIndex].cells[sotStretchedIndex];
      } else {
        sotBRIC = this.rows[sotRowIndex].cells[i];
      }
      if (sotBRIC.selected && sotBRIC.selected.sot && !sotBRIC.isEmpty) {
        allSOT.push(parseFloat(sotBRIC.selected.sot));
        if (i == cellIndex && this.appHeaderService.enabledPCM && sotBRIC.selected.increment) {
          sotValue.sotIncrement = sotBRIC.selected.increment
        }
      }
    }
    sotValue.sotFloor = Math.min(...allSOT);
    sotValue.sotCeiling = Math.max(...allSOT);
    return sotValue;

  }
  /**
   * @description This method is used to update the Floor and Ceiling Values in Volume/Budget BRIC after the Process bricks
   * is called
   * @author Shreni Shah
   * @date 2019-11-22
   * @param {number} cellIndex
   * @param {boolean} overwriteFloorCeilingForPaperColumn This will be true in case of paper colum during multidrop and when we change media Bric value to Paper
   * @memberof Filter
   */
  updateFloorAndCeilingValues(cellIndex: number, overwriteFloorCeilingForPaperColumn: boolean) {
    if (this.initialConfig.uiControl.autoCalculateFloorAndCeiling) {
      /* SD : SM-4252/SM-4721 - If Sot Brick is present, Floor and Ceiling on Budget/Volume Brick should have default SOT value */
      const volumeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Volume, this.rows).Available_row_Index;
      const budgetRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Budget, this.rows).Available_row_Index;
      const sotValue = this.getSOTValue(cellIndex);
      if (volumeRowIndex != null) {
        const volumeBrickStretchedIndex = this.rows[volumeRowIndex].getOverlappingCell(cellIndex);
        let volumeBric: Cell = null;
        if (volumeBrickStretchedIndex !== -1) {
          volumeBric = this.rows[volumeRowIndex].cells[volumeBrickStretchedIndex];
        } else {
          volumeBric = this.rows[volumeRowIndex].cells[cellIndex];
        }
        if (volumeBric && !volumeBric.isEmpty) {
          if (sotValue && Object.keys(sotValue).length) { // Case when SOT brick is Present
            volumeBric.selected.sotFloor = sotValue['sotFloor'];
            volumeBric.selected.sotCeiling = sotValue['sotCeiling'];
            volumeBric.toolTipText = this.cellAttributeService.getToolTip(
              this.brickBaseService.brickID.Volume,
              volumeBric.selected
            );
            volumeBric.requestJSON = this.cellAttributeService.getBrickRequestJSON(
              this.brickBaseService.brickID.Volume,
              volumeBric.selected
            );
          } else { // Case sepecially written for MultiDrop //
            let sotFloor = null;
            let sotCeiling = null;
            let sotIncrement = null;
            const floorCeilingNull = volumeBric.selected.sotFloor == null && volumeBric.selected.sotCeiling == null;
            if (floorCeilingNull && volumeBric.selected.impressions != null && !this.checkIfColumnHasOnlyPaper(cellIndex)) {
              let productValidations: any = {
                sotFloor: null,
                sotCeiling: null
              };
              if (this.productDetails && this.productDetails.length) {
                const productHelper = this.getProductHelperIfProductExists(volumeBric.cellIndex);
                if (productHelper) {
                  productValidations = this.getProductValidations(volumeBric.cellIndex, productHelper, Number(this.brickBaseService.brickID.Volume));
                }
              }
              const allcolumnSummary = this.stateService.getAllColumnSummary();
              let columnSummary = null;
              if (allcolumnSummary && Object.keys(allcolumnSummary).length) {
                columnSummary = this.getColumnSummary(allcolumnSummary, this.rows[volumeRowIndex].bric.bricid, volumeBric.cellIndex) as ColumnSummary;
              }
              sotFloor = this.workspaceService.computeFloorFromFormula(this.initialConfig.uiControl.floorCeilingPrecision,
                volumeBric.selected.impressions, this.initialConfig.uiControl.sotSwing,
                productValidations,
                columnSummary, 'audienceImpressions');
              sotCeiling = this.workspaceService.computeCeilingFromFormula(this.initialConfig.uiControl.floorCeilingPrecision,
                this.initialConfig.uiControl.sotSwing, sotFloor, productValidations);
              // Setting sotIncrement for SM-10689
              if (productValidations && productValidations.sotIncrement >= 0) {
                sotIncrement = productValidations.sotIncrement;
              }
              // (When both floor and ceiling is null i.e blank OR change Media from digital to paper) and media as paper, need to set sot Floor and ceiling to default
            } else if (volumeBric.selected.impressions != null && overwriteFloorCeilingForPaperColumn && (floorCeilingNull || volumeBric.selected.sotFloor != null || volumeBric.selected.sotCeiling != null)) {
              sotFloor = 1;
              sotCeiling = 100;
            } else {
              // In rest cases return the selected values
              sotFloor = volumeBric.selected.sotFloor;
              sotCeiling = volumeBric.selected.sotCeiling;
            }

            volumeBric.selected.sotFloor = sotFloor;
            volumeBric.selected.sotCeiling = sotCeiling;
            // Setting sotIncrement for SM-10689
            if (sotIncrement !== null && sotIncrement >= 0 && sotIncrement !== undefined) {
              volumeBric.selected.sotIncrement = sotIncrement;
            }

            // Update tooltip and request JSON //
            volumeBric.toolTipText = this.cellAttributeService.getToolTip(
              this.brickBaseService.brickID.Volume,
              volumeBric.selected
            );
            volumeBric.requestJSON = this.cellAttributeService.getBrickRequestJSON(
              this.brickBaseService.brickID.Volume,
              volumeBric.selected
            );
          }
        }
      }
      if (budgetRowIndex != null) {
        const budgetBrickStretchedIndex = this.rows[budgetRowIndex].getOverlappingCell(cellIndex);
        let budgetBric: Cell = null;
        if (budgetBrickStretchedIndex !== -1) {
          budgetBric = this.rows[budgetRowIndex].cells[budgetBrickStretchedIndex];
        } else {
          budgetBric = this.rows[budgetRowIndex].cells[cellIndex];
        }
        if (budgetBric && !budgetBric.isEmpty) {
          if (sotValue && Object.keys(sotValue).length) {
            const floorCeilingNull = budgetBric.selected.sotFloor == null && budgetBric.selected.sotCeiling == null;
            if (overwriteFloorCeilingForPaperColumn && (floorCeilingNull || budgetBric.selected.sotFloor != null || budgetBric.selected.sotCeiling != null)) {
              budgetBric.selected.sotFloor = 1;
              budgetBric.selected.sotCeiling = 100;
              // When we change Media from digital to paper, need to set sot Floor and ceiling to default
            }
            else {
              budgetBric.selected.sotFloor = sotValue['sotFloor'];
              budgetBric.selected.sotCeiling = sotValue['sotCeiling'];
            }
            budgetBric.toolTipText = this.cellAttributeService.getToolTip(
              this.brickBaseService.brickID.Budget,
              budgetBric.selected
            );
            budgetBric.requestJSON = this.cellAttributeService.getBrickRequestJSON(
              this.brickBaseService.brickID.Budget,
              budgetBric.selected
            );
          } else {
            let sotFloor = null;
            let sotCeiling = null;
            let sotIncrement = null;
            const floorCeilingNull = budgetBric.selected.sotFloor == null && budgetBric.selected.sotCeiling == null;
            if (floorCeilingNull && budgetBric.selected.price && !this.checkIfColumnHasOnlyPaper(cellIndex)) {
              let productValidations: any = {
                sotFloor: null,
                sotCeiling: null
              };
              if (this.productDetails && this.productDetails.length) {
                const productHelper = this.getProductHelperIfProductExists(budgetBric.cellIndex);
                if (productHelper) {
                  productValidations = this.getProductValidations(budgetBric.cellIndex, productHelper, Number(this.brickBaseService.brickID.Budget));
                }
              }
              const allcolumnSummary = this.stateService.getAllColumnSummary();
              const columnSummary = this.getColumnSummary(allcolumnSummary, this.rows[budgetRowIndex].bric.bricid, budgetBric.cellIndex) as ColumnSummary;
              sotFloor = this.workspaceService.computeFloorFromFormula(this.initialConfig.uiControl.floorCeilingPrecision,
                budgetBric.selected.price, this.initialConfig.uiControl.sotSwing,
                productValidations,
                columnSummary, 'price');
              sotCeiling = this.workspaceService.computeCeilingFromFormula(this.initialConfig.uiControl.floorCeilingPrecision,
                this.initialConfig.uiControl.sotSwing, sotFloor, productValidations);
              // Setting sotIncrement for SM-10689
              if (productValidations && productValidations.sotIncrement >= 0) {
                sotIncrement = productValidations.sotIncrement;
              }
              // (When both floor and ceiling is null i.e blank OR change Media from digital to paper) and media as paper, need to set sot Floor and ceiling to default
            } else if (overwriteFloorCeilingForPaperColumn && (floorCeilingNull || budgetBric.selected.sotFloor != null || budgetBric.selected.sotCeiling != null)) {
              sotFloor = 1;
              sotCeiling = 100;
              // When we change Media from digital to paper, need to set sot Floor and ceiling to default
            } else {
              // In rest cases return the selected values
              sotFloor = budgetBric.selected.sotFloor;
              sotCeiling = budgetBric.selected.sotCeiling;
            }

            budgetBric.selected.sotFloor = sotFloor;
            budgetBric.selected.sotCeiling = sotCeiling;
            // Setting sotIncrement for SM-10689
            if (sotIncrement !== null && sotIncrement >= 0 && sotIncrement !== undefined) {
              budgetBric.selected.sotIncrement = sotIncrement;
            }
            budgetBric.toolTipText = this.cellAttributeService.getToolTip(
              this.brickBaseService.brickID.Budget,
              budgetBric.selected
            );
            budgetBric.requestJSON = this.cellAttributeService.getBrickRequestJSON(
              this.brickBaseService.brickID.Budget,
              budgetBric.selected
            );
          }
        }
      }
    }
  }

  public willColumnExpand(colIndex: number): boolean {
    let willExpand = true;
    if (this.rows.length > 0) {
      this.rows.forEach((r) => {
        willExpand = willExpand && this.isCellAllowedForAutoExpand(colIndex, r.bric.bricid);
      });
    } else {
      willExpand = false;
    }
    return willExpand;
  }

  /**
   * @description Below method will adjust Audience bricks's size based on current no of columns
   * VJ: Below logic was already part of add brick method, which is moved by me to this method
   * logic refactored by Nishit
   * @author Vijay Sutaria, Nishit
   * @memberof Filter
   */
  public manageAudienceBrickSize(): void {
    // there can be only one Audience Bric on worksapce
    let audianceRowIndex: number = this.getExistingRowIndex(Number(this.brickBaseService.brickID.Audience));
    audianceRowIndex = audianceRowIndex > -1 ? audianceRowIndex : this.getExistingRowIndex(Number(this.brickBaseService.brickID.SecondaryAudience));
    audianceRowIndex = audianceRowIndex > -1 ? audianceRowIndex : this.getExistingRowIndex(Number(this.brickBaseService.brickID.PrimaryAudience));
    if (audianceRowIndex > -1 &&
      (this.rows[audianceRowIndex].lastEmptyCellIndex() < this.rows[audianceRowIndex].cells.length || (this.rows[audianceRowIndex].cells.length - 1) <= this.getMaxCellIndex())) {
      // Audience brick should be stretched on all columns.
      const lastEmptyCellIndex = this.rows[audianceRowIndex].lastEmptyCellIndex();
      // hardcoding 0 index as we need to stretch cell at 0th index
      const zeroRowAudience = (this.rows[0].bric.bricid === Number(this.brickBaseService.brickID.Audience)) || (this.rows[0].bric.bricid === Number(this.brickBaseService.brickID.SecondaryAudience));
      this.rows[audianceRowIndex].cells[0].cellWidth = zeroRowAudience ? this.getMaxCellIndex() + 1 : this.rows[0].cells.length;
      for (let i = lastEmptyCellIndex; i < this.rows[audianceRowIndex].cells[lastEmptyCellIndex - 1].cellWidth; i++) {
        const cell = new Cell();
        cell.createEmptyCell(true, false);
        this.rows[audianceRowIndex].cells.splice(i, 1, cell);
      }
    }

    // this case is when there is `same audience bric id` on worksapce and incoming product bric
    // audience bric with different bric id is not solved
    if (audianceRowIndex > -1
      && this.rows[audianceRowIndex].cells.filter(e => !e.isEmpty && !e.isHidden).length > 1) {

      this.rows[audianceRowIndex].cells[0].cellWidth = this.rows[0].cells.length;
      // hard coding it to 1 as audience bric always start from 0th index
      for (let i = 1; i < this.rows[0].cells.length; i++) {
        const cell = new Cell();
        cell.createEmptyCell(true, false);
        this.rows[audianceRowIndex].cells.splice(i, 1, cell);
      }
    }

    // DV: if there is 2 (both columns are product bric) column structure
    // and audiance brick come in 2nd column (2nd product drop)
    // than it should be start from first column
    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
    if (audianceRowIndex > -1
      && this.rows[audianceRowIndex].cells[0].isEmpty
      && !this.rows[audianceRowIndex].cells[0].isHidden && columnHelper && !columnHelper[0].systemLocked) {
      let maxCellIndex = this.getMaxCellIndex();
      const startIndex = this.rows[audianceRowIndex].getOverlappingCell(maxCellIndex);
      maxCellIndex = startIndex === -1 ? maxCellIndex : startIndex;

      this.rows[audianceRowIndex].cells[0] = _.clone(this.rows[audianceRowIndex].cells[maxCellIndex]);
      this.rows[audianceRowIndex].cells[0].cellWidth = this.rows[0].cells.length;
      // hard coding it to 1 as audience bric always start from 0th index
      for (let i = 1; i < this.rows[0].cells.length; i++) {
        const cell = new Cell();
        cell.createEmptyCell(true, false);
        this.rows[audianceRowIndex].cells.splice(i, 1, cell);
      }
    }
  }

  /**
   * @description This will remove the bric/row from workspace
   * @author Nishit Parekh
   * @param {number} rowIndex
   * @param {number} cellIndex
   * @memberof Filter
   */
  public removeBric(rowIndex: number, cellIndex: number): void {
    if (!this.rows[rowIndex].cells[cellIndex].isLocked) {
      if (this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.SOT && this.productDetails.length) {
        // check if the SOT cell has product at the cells higher to it
        for (let i = 0; i < this.rows.length; i++) {
          const row = this.rows[i];
          if (row.bric.bricid === this.brickBaseService.brickID.ProductCatalogue) {
            const productCells = row.cells;
            if (row.cells[cellIndex] && ((row.cells[cellIndex].isHidden && row.cells[cellIndex].isEmpty) ||
              (!row.cells[cellIndex].isEmpty && !row.cells[cellIndex].isHidden))) {
              this.logHelperService.logError(this.initialConfig.userBundle['worksapce.pcm.mandatoryInProduct'] || 'Cannot delete mandatory brics of a product!');
              return;
            }
          }
        }
      }
      if (this.rows[rowIndex].cells[cellIndex].isMandatoryForProduct && this.brickBaseService.PCM_EDITABLE_BRICS.indexOf(this.rows[rowIndex].bric.bricid) > -1) {
        const colIndexToCheck = this.checkIfCellExistsInColumn(cellIndex,
          Number(this.brickBaseService.brickID.ProductCatalogue), true);
        const isproductAvailableInCol = colIndexToCheck !== -1;
        let isOptionalInProduct;
        if (Number(this.rows[rowIndex].bric.bricid) === this.brickBaseService.brickID.Objective) {
          isOptionalInProduct = this.pcmService.isOptionalInProduct(colIndexToCheck, this.productDetails,
            Number(this.rows[rowIndex].bric.bricid), cellIndex);
        } else {
          isOptionalInProduct = this.pcmService.isOptionalInProduct(colIndexToCheck, this.productDetails,
            Number(this.rows[rowIndex].bric.bricid));
        }
        if (isproductAvailableInCol && !isOptionalInProduct) {
          this.logHelperService.logError(this.initialConfig.userBundle['worksapce.pcm.mandatoryInProduct'] || 'Cannot delete mandatory brics of a product!');
          return;
        }
      }
      // if (this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Incharge) {
      //   this.deleteRow(rowIndex);
      // } else
      if (this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.ProductCatalogue) {
        this.workspaceService.removeProductBRIC(this.rows, rowIndex, cellIndex);
        this.productDetails = this.stateService.getWorkspaceObject('productDetails');
        if (this.productDetails && this.productDetails.length) {
          for (const product of this.productDetails) {
            if (product && product.columnIndex === cellIndex) {
              this.productDetails.splice(cellIndex, 1);
            }
          }
        }
        this.stateService.setWorkspaceObject('productDetails', this.productDetails);
        const inchargeRowIndex = this.getExistingRowIndex(Number(this.brickBaseService.brickID.Incharge));

        if (inchargeRowIndex !== -1) {
          this.rows[inchargeRowIndex].cells[cellIndex].showWarningIcon = false;
        }
        this.postCellUpdates();
      } else if (this.rows[rowIndex].cells.filter(e => !e.isEmpty).length > 1) {
        // if there are 2 or more visible cells, it will remove selected cell and create empty cell at that place

        // get overlapping cell index
        let ovelappingCellIndex: number = this.rows[rowIndex].getOverlappingCell(cellIndex);
        ovelappingCellIndex = ovelappingCellIndex !== -1 ? ovelappingCellIndex : cellIndex;
        const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
        // If it is single cell, It will execute only once to remove the cell.
        // If the cell is stretched, It will loop through all cells depending on width and remove it one by one.
        for (let i: number = ovelappingCellIndex + this.rows[rowIndex].cells[ovelappingCellIndex].cellWidth - 1; i >= ovelappingCellIndex; i--) {
          this.rows[rowIndex].removeCell(i);
          this.rows[rowIndex].createEmptyCellAtIndex(rowIndex, i, false, columnHelper);
          //  Remove Patten Brick if Incharge brick delete
          if (this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Incharge) {
            this.removePattern(i);
          }
          // ---
        }
        // Addinf if conditin so that in case of multilidelete, it does not call below code every time
        if (this.markSelectedCount === this.actualBricsDeleted) {
          this.postCellUpdates();
          this.actualBricsDeleted = 0;
          this.markSelectedCount = 0;
        }
      } else {
        // if there is only one visible cell, it will delete the row
        this.deleteRow(rowIndex);
        this.reApplyIndex();
        // Remove Patten Brick if Incharge brick delete
        if (this.rows[rowIndex] && this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Pattern) {
          this.removePattern(cellIndex, true);
        }
        // ---
        // update row index at row and cell level
        // Addinf if conditin so that in case of multilidelete, it does not call below code every time
        if (this.markSelectedCount === this.actualBricsDeleted) {
          this.postCellUpdates();
          this.actualBricsDeleted = 0;
          this.markSelectedCount = 0;
        }
      }
      this.isMandatoryBricksAvailable();

      // When last cell is removed from the workspace
      if (!this.rows.length) {
        this.stateService.setWorkspaceObject('columnLineNumber', {});
      }
      // commenting below code as already called in autoSuppress, Nishit
      // const oldFilter = this.stateService.getWorkspaceFilterObj();
      // if (oldFilter.rows.length && this.rows.length && oldFilter.rows[0].cells.length !== this.rows[0].cells.length) {
      //   this.decrementColumnLineNumber(cellIndex);
      // }
      const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
      for (const row of this.rows) {
        row.setIsHidden(this.isPastColumnHidden, columnHelper);
      }

    } else {
      this.logHelperService.logError(this.initialConfig.userBundle['worksapce.unlock.error'] || 'Cannot delete locked Brics');
    }
  }

  /**
   * @description this will called when we multi delete brics
   * @author Nishit Parekh
   * @memberof Filter
   */
  public removeMultipleBric(): void {
    for (let i: number = this.rows.length - 1; i >= 0; i--) {
      // Added this.rows[i] If there are empty cells on the row and the entire row has been deleted in previous iteration.
      // we need to continue from next row iteration.
      for (let j: number = this.rows[i].cells.length - 1; this.rows[i] && j >= 0; j--) {
        if (this.rows[i].cells[j] && this.rows[i].cells[j].markSelected) { // additional condition to check isEmpty can be added if necessary
          ++this.actualBricsDeleted;
          this.removeBric(this.rows[i].cells[j].rowIndex, this.rows[i].cells[j].cellIndex);
        }
      }
    }
    this.isMandatoryBricksAvailable();
  }

  /**
   * @description returns array of supporting bric ids
   * @author Amit Mahida
   * @param {number} categoryId
   * @param {number} bricId
   * @returns {number[]}
   * @memberof Filter
   */
  getAllSupportingBricsIdsFromMasterJSON(categoryId: number, bricId: number): number[] {
    const supportingBricIds: number[] = [];
    const masterData = this.dataShareService.getBricsMasterData();
    for (const obj of masterData.category) {
      if (obj.categoryid === categoryId) {
        for (const bric of obj.brics) {
          if (bric.primaryid === bricId) {
            supportingBricIds.push(bric.bricid);
          }
        }
      }
    }
    return supportingBricIds;
  }

  /**
   * @description returns array of supporting bric row indexes
   * @author Amit Mahida
   * @param {number[]} supportingBrics
   * @returns {number[]}
   * @memberof Filter
   */
  getSupportingBricsRowsIndexesList(supportingBrics: number[]): number[] {
    const supportingBricRowIndexes: number[] = [];
    if (supportingBrics.length > 0) {
      for (const bricid of supportingBrics) {
        const isRowAvailable: boolean = this.isRowAvailable(bricid);
        const rowIndex: number = this.getExistingRowIndex(bricid);
        if (isRowAvailable && rowIndex != null) {
          supportingBricRowIndexes.push(rowIndex);
        }
      }
    }
    return supportingBricRowIndexes;
  }

  /**
   * @description Handles and validates delete row operation
   * @author Amit Mahida
   * @param {number} rowIndex
   * @returns {void}
   * @memberof Filter
   */
  deleteRow(rowIndex: number): void {
    if (!this.rows[rowIndex]) {
      this.logHelperService.logInfo(this.initialConfig.userBundle['workspace.error.dragElement']);
      return;
    }
    const dragIsPrimary: boolean = this.rows[rowIndex].bric.isprimary;
    const masterData = this.dataShareService.getBricsMasterData();
    const rowBackup = _.cloneDeep(this.rows[rowIndex]);

    if (dragIsPrimary) {
      // Dragged Row is Primary Row
      const dragSupportingBricIds: number[] =
        this.getAllSupportingBricsIdsFromMasterJSON(this.rows[rowIndex].bric.categoryid, this.rows[rowIndex].bric.bricid);
      if (dragSupportingBricIds.length > 0) {
        // Has Supporting Bric = TRUE
        const dragSupprtingBricsRowIndexes: number[] = this.getSupportingBricsRowsIndexesList(dragSupportingBricIds);
        for (let index: number = dragSupprtingBricsRowIndexes.length - 1; index >= 0; index--) {
          const supportingRowIndex: number = dragSupprtingBricsRowIndexes[index];
          this.rows.splice(supportingRowIndex, 1);
          if (this.initialConfig.uiControl.pendoEnabled) {
            const brickName = masterData.category.filter(m => m.categoryid === rowBackup.bric.categoryid)[0].brics.filter(b => b.bricid === rowBackup.bric.bricid)[0].bricname;
            ConfigUtils.manuallyPushEventsToPendo(`Removing ${brickName}`, {
              'RowIndex': index,
              'Group': 'Workspace',
              'Type': brickName
            });
          }
        }
        this.rows.splice(rowIndex, 1);
      } else {
        // Has Supporting Bric = false
        // When proudct Row is Deleted
        if (this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.ProductCatalogue) {
          for (let cellIndex = 0; this.rows[rowIndex] && cellIndex < this.rows[rowIndex].cells.length; cellIndex++) {
            // SM-3972: If product row contains empty brics and the entire row has deleted in previous iteration then need to break the loop
            if (!this.isRowAvailable(this.brickBaseService.brickID.ProductCatalogue)) {
              break;
            }
            const bric: Cell = this.rows[rowIndex].cells[cellIndex];
            if (!bric.isEmpty && !bric.isHidden) {
              this.workspaceService.removeProductBRIC(this.rows, rowIndex, cellIndex);
              const inchargeRowIndex = this.getExistingRowIndex(Number(this.brickBaseService.brickID.Incharge));
              if (inchargeRowIndex !== -1) {
                this.rows[inchargeRowIndex].cells[cellIndex].showWarningIcon = false;
              }
            }
          }
          // Added this as empty row was created, as mentioned in SM-3985
          this.deleteRowOfIndex(rowIndex);
        } else {
          if (this.rows[rowIndex].cells.filter(cell => cell.isMandatoryForProduct).length === 0) {
            this.rows.splice(rowIndex, 1);
          } else {
            this.deleteRowOfIndex(rowIndex);
          }
        }
      }
    } else {
      // Dragged Row is Supporting Row
      if (this.rows[rowIndex].cells.filter(cell => cell.isMandatoryForProduct).length === 0) {
        this.rows.splice(rowIndex, 1);
      } else {
        this.logHelperService.logError(this.initialConfig.userBundle['common.error.notallowed']);
      }
    }
    this.workspaceService.setDefaultPanning();
    // VJ2 Dt.15July2015 Disable disableResultCommercialTab on delete of InchargeBric
    if (this.appHeaderService
      && !this.appHeaderService.enabledPCM
      && !this.appHeaderService.objectiveMode
      && !this.isRowAvailable(Number(this.brickBaseService.brickID.Incharge))) {
      this.dataShareService.activateResultTab(false);
      this.dataShareService.activateCommercialTab(false);
    }
    if (this.initialConfig.uiControl.pendoEnabled) {
      const brickName = masterData.category.filter(m => m.categoryid === rowBackup.bric.categoryid)[0].brics.filter(b => b.bricid === rowBackup.bric.bricid)[0].bricname;
      ConfigUtils.manuallyPushEventsToPendo(`Removing ${brickName}`, {
        'RowIndex': rowIndex,
        'Group': 'Workspace',
        'Type': brickName
      });
    }
    this.reApplyIndex();
    this.isMandatoryBricksAvailable();
  }
  /**
   * @description moved common logic in a sperate function, this will delete row if specific conditions are met.
   * @author Nishit Parekh
   * @param {*} rowIndex
   * @memberof Filter
   */
  deleteRowOfIndex(rowIndex) {
    let deleteRow = 0;
    if (this.rows[rowIndex]) {
      this.rows[rowIndex].cells.forEach((cell) => {
        if (/*this.checkIfCellExistsInColumn(cell.cellIndex, this.rows[rowIndex].bric.bricid)&&*/ // VJ: SM-3901
          cell.isEmpty && !cell.isHidden) {
          deleteRow++;
        }
      });
      // changed condition for SM-2910
      if (deleteRow === this.rows[rowIndex].cells.length) {
        this.rows.splice(rowIndex, 1);
      }
      // else {
      //   this.logHelperService.logError(this.initialConfig.userBundle['common.error.notallowed']);
      // } // SD : SM-4444
    }
  }

  /**
   * @description Handles remove row event
   * @author Amit Mahida
   * @param {number} rowIndex
   * @memberof Filter
   */
  removeRow(rowIndex: number) {
    // Do not allow to remove a row if the locked or nonEditable cell exist on it.
    const isLockedNonEditable: Cell = this.rows[rowIndex].cells.find(cell => cell.isLocked || !cell.isEditable);
    if (isLockedNonEditable) {
      this.logHelperService.logError(this.initialConfig.userBundle['worksapce.remove.brickonLeft.error']);
      return;
    }

    // Do not allow to remove a row if any bric of the given row is mandatory for the product.
    const isMandatoryForProduct: Cell = this.rows[rowIndex].cells.find(cell =>
      cell.isMandatoryForProduct && this.rows[rowIndex].bric.bricid !== this.brickBaseService.brickID.ProductCatalogue
    );
    if (isMandatoryForProduct) {
      this.logHelperService.logError(this.initialConfig.userBundle['worksapce.remove.mandatoryForProduct.error'] || 'Can not remove the brick(s) if they are mandatory for products');
      return;
    }

    if (this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Frame && this.isRowAvailable(Number(this.brickBaseService.brickID.Pricing))) {
      // Remove Pricing Row
      const pricingRowIndex: number = this.getExistingRowIndex(Number(this.brickBaseService.brickID.Pricing));
      this.deleteRow(pricingRowIndex);
    }
    this.deleteRow(rowIndex);
    // Remove Patten Brick if Incharge brick delete
    if (this.rows[rowIndex] && this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Pattern) {
      this.removePattern(0, true);
    }
    this.postCellUpdates();
  }

  /**
   * @description check rules for resizing quantity bricks
   * @author Amit Mahida
   * @param {Cell} cell
   * @param {number} endIndex
   * @returns {boolean}
   * @memberof Filter
   */
  public checkQtyBrickResizeRules(cell: Cell, endIndex: number): boolean {
    let isValid = true; // Allowed
    const rowIndex: number = cell.rowIndex;
    const currentCellIndex: number = cell.cellIndex;
    const brickId: number = this.rows[cell.rowIndex].bric.bricid;

    const numOfIncreasedCell: number = Math.ceil(endIndex - (currentCellIndex + cell.cellWidth));
    if ((brickId === this.brickBaseService.brickID.Pattern ||
      brickId === this.brickBaseService.brickID.SOT)
      && this.initialConfig.uiControl.expandPatternBrickOverMultipleColumns
      && this.isRowAvailable(Number(this.brickBaseService.brickID.Incharge))
      && this.isRowAvailable(Number(this.brickBaseService.brickID.ProductCatalogue))) {
      /**
       * SM-6474
       * All columns must have product with pattern in it
       * All columns must have same range brick and same value in it
       * All Pattern inside the product must have same values
       */
      const inchargeBrickRow: Row = this.getRowByBrickId(Number(this.brickBaseService.brickID.Incharge));
      const productBrickRow: Row = this.getRowByBrickId(Number(this.brickBaseService.brickID.ProductCatalogue));

      const totalCoveredCell = productBrickRow.cells
        .filter(c => c.cellIndex >= currentCellIndex && c.cellIndex < endIndex).length;

      if (totalCoveredCell < (endIndex - currentCellIndex)) {
        // 1 or more cells are empty in product row between start and end index of stretch
        isValid = false;
      } else {
        let patternConfig;
        let index = 0;
        while (this.productDetails[index]
          && this.productDetails[index].columnIndex >= currentCellIndex
          && brickId !== this.brickBaseService.brickID.SOT
          && this.productDetails[index].columnIndex < endIndex) {

          Object.keys(this.productDetails[index].validation).forEach((v) => {
            if (!patternConfig) {
              patternConfig = JSON.stringify(this.productDetails[index].validation[v][brickId]);
            }

            if (
              !this.productDetails[index].validation[v][brickId] ||
              patternConfig !== JSON.stringify(this.productDetails[index].validation[v][brickId])) {
              isValid = false;
            }
          });

          index++;
        }

        const inchargeJSON = JSON.stringify(inchargeBrickRow.cells[currentCellIndex].requestJSON);
        const differentIncharge = inchargeBrickRow.cells.filter(c => c.cellIndex > currentCellIndex
          && c.cellIndex < endIndex
          && !c.isEmpty
          && JSON.stringify(c.requestJSON) !== inchargeJSON);

        if (differentIncharge.length) {
          isValid = false;
        }
      }
    } else if ((this.isQuantityBric(brickId) && brickId !== this.brickBaseService.brickID.SOT
      || (brickId === this.brickBaseService.brickID.Pattern)
      || brickId === this.brickBaseService.brickID.PricingTag
    ) && this.isRowAvailable(Number(this.brickBaseService.brickID.Incharge))) {
      // If Incharge brick exist then only we have to take care of qty bricks expand
      if (currentCellIndex < endIndex) {
        // Case 1: When user extends the qty brick
        const inchargeBrickRow: Row = this.getRowByBrickId(Number(this.brickBaseService.brickID.Incharge));

        if (inchargeBrickRow && inchargeBrickRow.index != null && inchargeBrickRow.index > -1) {
          // This means incharge brick row exist
          let currentBrickStartIndex: number = this.rows[inchargeBrickRow.index].getOverlappingCell(currentCellIndex);
          if (!this.rows[inchargeBrickRow.index].cells[currentCellIndex].isEmpty) {
            // Cell is in use
            if (currentBrickStartIndex === -1) {
              // It's not resized
              currentBrickStartIndex = currentCellIndex;
            }

            const endBrickStartIndex: number =
              this.rows[inchargeBrickRow.index].getOverlappingCell(currentCellIndex + cell.cellWidth + numOfIncreasedCell - 1);

            if (currentBrickStartIndex === -1 || (currentBrickStartIndex !== endBrickStartIndex)) {
              isValid = false; // Not allowed
            }
          } else if (currentBrickStartIndex > -1) {
            // It's resized
            // On current index incharge brick is also resized
            const lastIndexInInchargeRow: number =
              currentBrickStartIndex + this.rows[inchargeBrickRow.index].cells[currentBrickStartIndex].cellWidth - 1;
            if (lastIndexInInchargeRow <
              (currentCellIndex + numOfIncreasedCell + this.rows[rowIndex].cells[currentCellIndex].cellWidth - 1)) {
              isValid = false; // Not allowed
            }
          } else {
            // Cell is empty
            // This will be executed when at current index is blank space in Range row,
            // So checking if all the bricks at any index before end index is unused or not.
            let tmpCurrentCellIndex: number = currentCellIndex;
            // VJ: dt 30Nov2015
            // Find next used index
            // And if brick is stretched beyond the next used incharge brick index, then not allowed
            // Allowed if currentindex in inchargebrick is not used and brick is stretched before the next used incharge brick
            while (tmpCurrentCellIndex <=
              (currentCellIndex + numOfIncreasedCell + this.rows[rowIndex].cells[currentCellIndex].cellWidth - 1)) {
              if (!this.rows[inchargeBrickRow.index].cells[tmpCurrentCellIndex].isEmpty) {
                isValid = false; // Not allowed
                break;
              }
              tmpCurrentCellIndex++;
            }
          }
        }
      }
    } else if (brickId === this.brickBaseService.brickID.Incharge) {
      const inchargeBrickRow: Row = this.getRowByBrickId(Number(this.brickBaseService.brickID.Incharge));
      if (inchargeBrickRow && inchargeBrickRow.index != null && inchargeBrickRow.index > -1) {
        const resizeStartFromColumnIndex = inchargeBrickRow.cells[currentCellIndex].cellWidth - 1 + currentCellIndex;

        // VJ:, dt 29/09/2015:If incharge brick and collapsing then...
        if (currentCellIndex > endIndex) {
          // Collapsing Incharge Brick
          // If collapsing incharge brick then we need to collapse qty brick in all rows and in same column as incharge brick
          // Get All Qty brick rows
          const noOfDecreasedCell: number = Math.ceil(currentCellIndex - endIndex);
          const resizeEndToColumnIndex: number = resizeStartFromColumnIndex - noOfDecreasedCell;
          const columnIndexOverlappingResizeEndColumn: number =
            this.rows[inchargeBrickRow.index].getOverlappingCell(resizeEndToColumnIndex);

          for (const rowObj of this.rows) {
            let tmpResizeStartFromColumnIndex: number = resizeStartFromColumnIndex;
            // Nishit, Added condition for Pricing tag Bric, SMI-319
            if (this.isQuantityBric(rowObj.bric.bricid) || rowObj.bric.bricid === this.brickBaseService.brickID.Pattern) {
              let currentQtyStartIndex = -1;

              // SBRICS-1557  DA:Getting Console Error while collapsing Range Bric (Matrix was not getting maintained)
              if (rowObj.cells[tmpResizeStartFromColumnIndex]) {
                if (rowObj.cells[tmpResizeStartFromColumnIndex].isEmpty) {
                  while (true) {
                    tmpResizeStartFromColumnIndex = tmpResizeStartFromColumnIndex - 1;
                    if (tmpResizeStartFromColumnIndex < 0 || !rowObj.cells[tmpResizeStartFromColumnIndex].isEmpty) {
                      break;
                    }
                  }
                  currentQtyStartIndex = tmpResizeStartFromColumnIndex;
                }
              } else {
                currentQtyStartIndex = rowObj.getOverlappingCell(tmpResizeStartFromColumnIndex);

                // VJ: dt: 9Oct2015, If Current brick is not in use by resizable, then it must be a white space
                // So find a used brick/usedbyresizable brick
                let qtyIndex: number;
                if (currentQtyStartIndex === -1) {
                  for (qtyIndex = tmpResizeStartFromColumnIndex - 1; qtyIndex >= resizeEndToColumnIndex; qtyIndex--) {
                    if (!rowObj.cells[qtyIndex].isEmpty) {
                      if (rowObj.cells[qtyIndex].isEmpty) {
                        currentQtyStartIndex = rowObj.getOverlappingCell(tmpResizeStartFromColumnIndex);
                      } else {
                        currentQtyStartIndex = qtyIndex;
                      }

                      if (currentQtyStartIndex === -1) {
                        // again we found a blank space, go ahead to find the next used brick
                      } else {
                        // We found the previous used qty brick, so break the loop
                        break;
                      }
                    }
                  }
                }
              }

              const allBricksStartIndex: number[] = [];
              allBricksStartIndex.push(currentQtyStartIndex);

              // There are multiple qty bricks in same row covered by single red brick

              while ((currentQtyStartIndex - 1 > resizeEndToColumnIndex)) {
                const qtyStartIndex = rowObj.getOverlappingCell(currentQtyStartIndex - 1);
                if (qtyStartIndex > -1) {
                  allBricksStartIndex.push(qtyStartIndex);
                  currentQtyStartIndex = qtyStartIndex;
                } else if (currentQtyStartIndex - 1 > 0) {
                  currentQtyStartIndex--;
                } else {
                  break; // Break the while loop
                }
              }

              let actualNumOfDecreasedCell: number = noOfDecreasedCell;
              for (const brickStartIndex of allBricksStartIndex) {
                if (columnIndexOverlappingResizeEndColumn <= brickStartIndex) {

                  if (rowObj.cells[brickStartIndex].cellWidth > 1) {
                    const prevAfterResizedCellWidth: number = rowObj.cells[brickStartIndex].cellWidth;

                    let decrease: number;
                    if (prevAfterResizedCellWidth < actualNumOfDecreasedCell) {
                      decrease = prevAfterResizedCellWidth - 1;
                      actualNumOfDecreasedCell = actualNumOfDecreasedCell - prevAfterResizedCellWidth; // VJ:-1 is not required here
                    } else {
                      decrease = actualNumOfDecreasedCell;
                    }

                    if (brickStartIndex <= resizeEndToColumnIndex) {
                      rowObj.cells[brickStartIndex].cellWidth -= actualNumOfDecreasedCell;

                      if (rowObj.cells[brickStartIndex].cellWidth <= 0) {
                        rowObj.cells[brickStartIndex].cellWidth = 1;
                      }
                      const start: number = prevAfterResizedCellWidth + brickStartIndex - 1 - decrease;
                      for (let i = prevAfterResizedCellWidth + brickStartIndex - 1; i > start; i--) {
                        rowObj.cells[i].isEmpty = false;
                      }
                    }
                  }

                }
              }
            }
          }
        } else if (currentCellIndex < endIndex) {
          // Expanding Incharge Brick
          const noOfIncreasedCell: number = Math.ceil(endIndex - currentCellIndex);
          const resizeEndToColumnIndex: number = resizeStartFromColumnIndex + noOfIncreasedCell;

          for (const row of this.rows) {
            if (this.isQuantityBric(row.bric.bricid)) {
              let resizeEndToColumnsEndIndex: number = this.rows[rowIndex].getOverlappingCell(resizeEndToColumnIndex);
              if (resizeEndToColumnsEndIndex === -1) {
                resizeEndToColumnsEndIndex = resizeEndToColumnIndex - 1;
              }

              // Added condition to check brick data exist or not : Yuvraj Chauhan : 28/07/2016 : SBRICS-1766
              if (this.rows[rowIndex].cells.length > 0 && this.rows[rowIndex].cells[resizeEndToColumnsEndIndex]) {
                resizeEndToColumnsEndIndex += this.rows[rowIndex].cells[resizeEndToColumnsEndIndex].cellWidth - 1;
              }

              if (resizeEndToColumnsEndIndex > resizeEndToColumnIndex) {
                // Qty brick's end index should not be more than the end index in inchargebrick row
                isValid = false;
                return;
              }
            }
          }
        }
      }
    }
    return isValid;
  }

  /**
   * @description Handles width change event on resizing a cell
   * @author VJ, Amit Mahida
   * @param {Cell} cell
   * @param {number} newWidth
   * @param {boolean} [storeInHistory=true] used mainly when this method called in recursion
   * @memberof Filter
   */
  public cellWidthChanged(cell: Cell, newWidth: number, storeInHistory = true) {
    if (newWidth && this.validateStretching(cell, cell.cellIndex + newWidth)) {
      if (storeInHistory) {
        if (this.appHeaderService && this.appHeaderService.enabledPCM) {
          this.historyStackService.pushInHistoryStackPCM(new HistoryStackData(this));
        } else if (this.appHeaderService && this.appHeaderService.objectiveMode) {
          this.historyStackService.pushInHistoryStackObjective(new HistoryStackData(this));
        } else {
          this.historyStackService.pushInHistoryStack(new HistoryStackData(this));
          this.historyStackService.pushColumnHelperInHistoryStack(this.stateService.getColumnHelper());
        }
      }

      let expandDirection = ExpandDirection.Right;
      let changeCount: number = newWidth - cell.cellWidth;

      if (changeCount < 0) {
        changeCount = -1 * changeCount;
        expandDirection = ExpandDirection.Left;
      }

      const width: number = newWidth > cell.cellWidth ? newWidth : cell.cellWidth;

      const lastIndex: number = cell.cellIndex + width - 1;
      const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
      const firstSOTVal = this.rows[cell.rowIndex].cells[0].selected.sot; // get the value of the property from the first object
      let isProductRow = false;
      let isSOTValuesSameForAllBrick = false;
      if (this.rows[cell.rowIndex].bric.bricid === this.brickBaseService.brickID.SOT) {
        for (let i = 0; i < this.rows.length; i++) {
          const targetIndex = lastIndex;
          if (this.rows[i].bric.bricid == this.brickBaseService.brickID.ProductCatalogue) {
            if (!this.rows[i].cells[targetIndex].isEmpty || this.rows[i].cells[targetIndex].isHidden) {
              isProductRow = true;
            }
          }
        }
        for (let i = 0; i < this.rows[cell.rowIndex].cells.length; i++) {
          if (!_.isEmpty(this.rows[cell.rowIndex].cells[i].selected) && !isProductRow && this.rows[cell.rowIndex].cells[i].selected.sot == firstSOTVal) {
            isSOTValuesSameForAllBrick = true;
          }
        }
      }
      if (expandDirection === ExpandDirection.Right
        && this.initialConfig.uiControl.expandPatternBrickOverMultipleColumns
        && (this.rows[cell.rowIndex].bric.bricid === this.brickBaseService.brickID.Pattern ||
          (this.rows[cell.rowIndex].bric.bricid === this.brickBaseService.brickID.SOT && !isSOTValuesSameForAllBrick))) {
        // Need to copy same pattern brick to other places instead of stretching it
        for (let i: number = cell.cellIndex + 1; i <= lastIndex; i++) {
          this.rows[cell.rowIndex].cells[i].updateCellValues(this.rows[cell.rowIndex].cells[cell.cellIndex]);
        }
      } else {
        for (let i: number = lastIndex - changeCount + 1; i <= lastIndex; i++) {
          if (this.rows[cell.rowIndex].cells[i]) {
            if (this.rows[cell.rowIndex].cells[i].cellWidth > 1) {
              this.rows[cell.rowIndex].cells[i + 1].updateCellValues(this.rows[cell.rowIndex].cells[i]);
              this.rows[cell.rowIndex].cells[i + 1].cellWidth -= 1;
            }
            // If current cell is stretched then hidden true if squeezed then hidden false
            this.rows[cell.rowIndex].cells[i].createEmptyCell(cell.cellWidth < newWidth, (columnHelper && columnHelper[cell.cellIndex] ? true : false));
          } else {
            this.rows[cell.rowIndex].createEmptyCellAtIndex(cell.rowIndex, i, false, columnHelper);
          }
        }

        // Below logic is to collapse qty brick if range brick is collapsed
        if (this.rows[cell.rowIndex].bric.bricid === this.brickBaseService.brickID.Incharge && newWidth < cell.cellWidth) {
          // 1) Find Qty rows
          this.rows.forEach((r) => {
            // find overlapped qty brick
            if (this.isQuantityBric(r.bric.bricid) && r.bric.bricid !== this.brickBaseService.brickID.SOT) {
              // 2) Reduce size to make same as Incharge
              let qtyCellIndex = r.getOverlappingCell(lastIndex - changeCount);

              if (qtyCellIndex === -1) {
                qtyCellIndex = lastIndex - changeCount;
              }

              if (r.cells[qtyCellIndex].cellWidth > newWidth - (qtyCellIndex - cell.cellIndex)) {
                this.cellWidthChanged(r.cells[qtyCellIndex], newWidth - (qtyCellIndex - cell.cellIndex), false);
              }
            }
          });
        }

        if (this.rows[cell.rowIndex].bric.bricid === this.brickBaseService.brickID.Media && this.initialConfig.uiControl.defaultAllocationEngine) { // This means Local Solver is enabled
          // SM-6264
          // Always need to reset to allocationEngine when media type change
          const allocationEngine = cell.selected.selectedMediaObject.defaultAllocationEngine || this.initialConfig.uiControl.defaultAllocationEngine;
          if (expandDirection === ExpandDirection.Right) {
            for (let i: number = cell.cellIndex + 1; i <= lastIndex; i++) {
              this.allocationEngines[i] = allocationEngine;
            }
          } else if (expandDirection === ExpandDirection.Left) {
            for (let i: number = lastIndex - changeCount + 1; i <= lastIndex; i++) {
              this.allocationEngines[i] = undefined;
            }
          }
        }
        cell.cellWidth = newWidth;
        // replaced with below for SM-6384
        this.reRenderQuantityBrick();
      }
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description This method is for validating SOT brick should be allowed to stretch or not
   * @param {Cell} cell
   * @param {number} endIndex
   * @returns {boolean}
   * @memberof Filter
   */
  validateSOTbrick(cell: Cell, endIndex: number): boolean {
    let valid = true;
    const rowIndex: number = cell.rowIndex;
    const colIndex: number = cell.cellIndex;
    let validSotValues = {};
    let allSelectionBrick = false;
    const totalColumns = this.rows[0].cells.length;
    const isColumnsEqualToProduct = totalColumns == this.productDetails.length;
    this.productDetails.sort((a, b) => a.columnIndex - b.columnIndex);
    for (let i = 1; i < this.productDetails.length; i++) {
      let validSotValueForSecondProduct = [];
      const _product = this.productDetails[i].validation[i] || this.productDetails[i].validation[colIndex];
      if (_product !== undefined) {
        if (!_product[this.brickBaseService.brickID.SOT]) {
          this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.product.sotbrickvalidation'] || 'Selected product does not contain SOT brick');
          valid = false;
          return;
        }
        if (_product[this.brickBaseService.brickID.SOT][-99]) {
          allSelectionBrick = true;
        }
        let sotMaxValue = _product[this.brickBaseService.brickID.SOT].max;
        let sotIncrementValue = _product[this.brickBaseService.brickID.SOT].increment;
        let sotCurrentValue = _product[this.brickBaseService.brickID.SOT].sot;
        for (let j = sotCurrentValue; j <= sotMaxValue; j = j + sotIncrementValue) {
          validSotValueForSecondProduct.push(j);
        }
        validSotValues[i] = validSotValueForSecondProduct;
        for (let q = 1; q <= endIndex - 1; q++) {
          if (!allSelectionBrick && validSotValues[q] && !validSotValues[q].includes(Number(this.rows[rowIndex].cells[cell.cellIndex].selected.sot))) {
            this.logHelperService.logError(this.initialConfig.userBundle['common.error.notallowed']);
            valid = false;
          }
        }
      }
    }
    return valid;
  }

  /**
   * @description Creates new Row if the row is not present and pushes
   * the cell if the row is present for autopopulating SOT and Distribution BRIC
   * @author Shreni
   * @date 2020-04-01
   * @param {(String | Number)} brickId
   * @param {CellValues} values
   * @param {ColumnHelper} columnHelper
   * @param {number} cellIndex
   * @memberof Filter
   */
  createRowOrCellForFillerCampaign(brickId: string | number, values: CellValues, columnHelper: ColumnHelper, cellIndex: number) {
    const availability = this.workspaceService.isBricRowAvailable(brickId, this.rows);
    if (availability.Available_row_Index === null) {     // Case 1 : Row is not available //
      const row = new Row();
      row.createNewRow(this.rows.length, values, columnHelper, this.appHeaderService.objectiveMode);
      this.rows.push(row);
    } else {
      const resizableIndex = this.rows[availability.Available_row_Index].getOverlappingCell(cellIndex);
      if (resizableIndex < 0) {
        const cell = this.rows[availability.Available_row_Index].cells[cellIndex];
        if (_.isUndefined(cell) || (!_.isUndefined(cell) && cell.isEmpty)) {
          this.rows[availability.Available_row_Index].pushNewCell(values, cellIndex, ExpandDirection.None);
        }
      }
    }
  }
  /**
   * @description Autopopulates SOT and Distribution BRICK for Filler Campaign
   * @author Shreni
   * @date 2020-04-01
   * @param {*} cellIndex
   * @param {*} columnHelper
   * @memberof Filter
   */
  handleFillerCampaign(cellIndex, columnHelper) {
    const areMandatoryBricsPresent = this.isMandatoryBricksAvailable(cellIndex);
    if (areMandatoryBricsPresent) {
      // Autopopulate Share BRIC//
      const valuesSOT: CellValues = {
        readOnlyModal: false,
        isOptionalInProduct: false,
        isEditMode: false
      };
      const brickDetailsSOT = this.getBricDetailsFromMasterData(Number(this.brickBaseService.brickID.SOT));
      valuesSOT.brick = brickDetailsSOT['_elements'][0];
      let selectedValuesSOT: SOTBricSelectedDataModel;
      selectedValuesSOT = {
        sot: '100'
      };
      valuesSOT.selectedValues = selectedValuesSOT;
      valuesSOT.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.SOT, valuesSOT.selectedValues);
      valuesSOT.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.SOT, valuesSOT.selectedValues);
      this.createRowOrCellForFillerCampaign(this.brickBaseService.brickID.SOT, valuesSOT, columnHelper, cellIndex);
      // Autopopulate Distribution BRIC//
      const valuesDistribution: CellValues = {
        readOnlyModal: false,
        isOptionalInProduct: false,
        isEditMode: false
      };
      const brickDetails = this.getBricDetailsFromMasterData(Number(this.brickBaseService.brickID.Distribution));
      valuesDistribution.brick = brickDetails['_elements'][0];
      let selectedValuesDistribution: Distribution;
      selectedValuesDistribution = {
        distributionName: 'Sweep',
        distributionType: 3
      };
      valuesDistribution.selectedValues = selectedValuesDistribution;
      valuesDistribution.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Distribution, valuesDistribution.selectedValues);
      valuesDistribution.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Distribution, valuesDistribution.selectedValues);
      this.createRowOrCellForFillerCampaign(this.brickBaseService.brickID.Distribution, valuesDistribution, columnHelper, cellIndex);
    }
  }

  /**
   * @description This is group of method which is must to execute after having any change in CELL / filterObject
   * @author Vijay Sutaria
   * @param {boolean} [makeServerCall=false]
   * @memberof Filter
   */
  async postCellUpdates(makeServerCall = false) {
    this.autoSuppress();
    // reapply Index is already invoked inside autoSupress since indexing must be reapplying after having change in filter object
    // this.reApplyIndex();
    this.generateSOTBrickRequestJSON();
    this.generateBrickRequestJSON();
    if (this.appHeaderService && this.appHeaderService.enabledPCM) {
      this.stateService.setPCMObject('filter', this.stateService.clone(this));
    } else if (this.appHeaderService && this.appHeaderService.objectiveMode) {
      this.stateService.setObjectiveObject('filter', this.stateService.clone(this));
    } else {
      this.stateService.setWorkspaceObject('filter', this.stateService.clone(this));
    }
    if (this.appHeaderService && this.appHeaderService.enabledPCM) {
      // case when we add brics with All Selection, no need to call backend
      if (makeServerCall) {
        this.filterProductBuild();
      }
    } else if (!this.appHeaderService.objectiveMode) {
      await this.processBrics();
    }
    if (this.checkFtgIterationAllowed()) {
      let breakForAllCells = false;
      const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
      for (const row of this.rows) {
        if (row.bric.bricid === this.brickBaseService.brickID.Volume) {
          for (const cell of row.cells) {
            const columnSummary = this.stateService.getColumnSummary(cell.cellIndex.toString());
            const systemLocked = columnHelper && columnHelper[cell.cellIndex] && columnHelper[cell.cellIndex].systemLocked;
            if (!systemLocked && cell.selected && cell.requestJSON && cell.requestJSON.selectionAudienceReduction) {
              if (columnSummary.secondaryImpression === cell.selected.actualFtgAllocated || cell.selected.actualFtgTarget === cell.selected.actualFtgAllocated) {
                // Break loop if next iteration has same secondary impressions that previous had
                cell.selected.breakFTGIteration = true;
              } else {
                cell.selected.breakFTGIteration = false;
              }
              cell.selected.actualFtgAllocated = _.clone(columnSummary.secondaryImpression);
              cell.requestJSON.selectionAudienceReduction.actualFtgAllocated = _.clone(cell.selected.actualFtgAllocated);
              cell.toolTipText = this.cellAttributeService.getToolTip(
                this.brickBaseService.brickID.Volume,
                cell.selected
              );
            }
          }
          if (row.cells.filter(cell => cell.selected.breakFTGIteration).length === row.cells.length) {
            // Stop iteration for all columns
            breakForAllCells = true;
          }
        }
      }
      if (breakForAllCells) {
        this.breakFTGIteration();
      }
    }
    if (this.checkFtgIterationAllowed()
      && !this.breakFtgIteration
      && this.initialConfig.uiControl.maxFinalAttributeIterations > 1
      && this.totalFtgIteration < this.initialConfig.uiControl.maxFinalAttributeIterations) {
      // Run iteration for final target group
      this.totalFtgIteration++;
      this.lastFtgIteration = _.clone(this.totalFtgIteration);
      this.postCellUpdates(makeServerCall);
    } else {
      this.totalFtgIteration = 0;
      this.breakFtgIteration = false;
    }

  }

  /**
   * @description check if row contains product
   * @author Siddharth Vaidua
   * @param {Cell} colIndex
   * @param {number} endIndex
   * @returns {boolean}
   * @memberof Filter
  */
  checkIfProductRowIsInvolved(rowIndex: number, endIndex: number, colIndex: number): boolean {
    let result = false;
    for (let i = 0; i < this.rows.length; i++) {
      const targetIndex = endIndex - 1;
      if (this.rows[i].bric.bricid == this.brickBaseService.brickID.ProductCatalogue) {
        if ((!this.rows[i].cells[colIndex].isEmpty || this.rows[i].cells[colIndex].isHidden) &&
          (this.rows[i].cells[targetIndex].isEmpty && !this.rows[i].cells[targetIndex].isHidden)) {
          result = true;
        }
      }
    }
    return result;
  }

  /**
   * @description validates rules for stretchig a cell
   * @author Amit Mahida
   * @param {Cell} cell
   * @param {number} endIndex
   * @returns {boolean}
   * @memberof Filter
   */
  validateStretching(cell: Cell, endIndex: number): boolean {
    const rowIndex: number = cell.rowIndex;
    const colIndex: number = cell.cellIndex;
    // TODO: VJ: Need to add validation logic here to allow stretch
    let valid = true;

    if (this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Objective) {
      // SM-7006, Objective brick is not allowed to stretch
      valid = false;
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.notallowed']);
      return valid;
    }

    // case when we resize Incharge bric and has pattern bric below it
    if (this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Incharge && this.rows[cell.rowIndex + 1] &&
      this.rows[cell.rowIndex + 1].bric.bricid === this.brickBaseService.brickID.Pattern) {
      this.checkIfPatternIsValidAfterResize(cell, colIndex, endIndex);
    }
    // Check if brick is stretched over locked column
    const columnHelper = this.stateService.getColumnHelper();
    valid = this.rows[rowIndex].cells.filter(c => c.cellIndex > colIndex && c.cellIndex < endIndex && columnHelper && columnHelper[c.cellIndex]).length === 0;
    if (!valid) {
      this.logHelperService.logError(this.initialConfig.userBundle['worksapce.stretch.locked.error']);
      return valid;
    }

    const isSOTBrick = this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.SOT;
    const result = this.checkIfProductRowIsInvolved(rowIndex, endIndex, colIndex);
    // dont allow to stretch SOT brick from product to non product row
    if (this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.SOT && this.productDetails.length) {
      if (result) {
        this.logHelperService.logError(this.initialConfig.userBundle['common.error.notallowed']);
        return;
      }
    }

    // Check rules for quantity bricks
    valid = isSOTBrick && !result ? true : this.checkQtyBrickResizeRules(cell, endIndex);
    valid = valid && (!cell.isMandatoryForProduct || this.rows[rowIndex].bric.bricid !== this.brickBaseService.brickID.ProductCatalogue);
    if (!valid) {
      this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.spanDenied']);
      return valid;
    }


    // check whether SOT brick can stretch or not
    if (this.rows[rowIndex].bric.bricid == Number(this.brickBaseService.brickID.SOT)
      && this.productDetails.length >= 2) {
      valid = this.validateSOTbrick(cell, endIndex)
    }

    // Only allow to stretch optional brics of product
    const numOfIncreasedCell: number = endIndex - (cell.cellIndex + cell.cellWidth);

    if (this.rows[rowIndex].bric.bricid !== Number(this.brickBaseService.brickID.Pattern) &&
      this.rows[rowIndex].bric.bricid !== Number(this.brickBaseService.brickID.SOT)
      || !this.initialConfig.uiControl.expandPatternBrickOverMultipleColumns) {
      valid = this.workspaceService.isBrickAllowedToMoveOrStretch(cell.cellIndex, this.productDetails);

      // Don't allow to stretch on next product
      valid = valid && (this.checkIfCellExistsInColumn((cell.cellIndex + (numOfIncreasedCell > 0 ? numOfIncreasedCell : 0)),
        Number(this.brickBaseService.brickID.ProductCatalogue)) === -1);

      if (this.rows[rowIndex].bric.bricid === Number(this.brickBaseService.brickID.Pattern)
        || this.rows[rowIndex].bric.bricid === Number(this.brickBaseService.brickID.SOT)
        || this.rows[rowIndex].bric.bricid === Number(this.brickBaseService.brickID.Distribution)) {
        const stretchOnProduct = this.checkCellStretchOnProduct(cell.cellIndex + cell.cellWidth - 1, numOfIncreasedCell);
        if (stretchOnProduct.isProductExist) {
          valid = stretchOnProduct.allProductSame;
        }
      }

      if (!valid) {
        this.logHelperService.logError(this.initialConfig.userBundle['common.error.notallowed']);
        return valid;
      }
    }
    // Don't allow objective to stretch on next Incharge
    if (GLOBAL.localSolverEnabled && this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Objective) {
      const inchargeStartIndex = this.checkIfCellExistsInColumn(cell.cellIndex, Number(this.brickBaseService.brickID.Incharge), true);
      const inchargeCell = this.getRowByBrickId(Number(this.brickBaseService.brickID.Incharge)).cells[inchargeStartIndex];
      const inchargeWidth = inchargeCell.cellIndex + inchargeCell.cellWidth;
      const widthIncreased = (cell.cellIndex + cell.cellWidth + (numOfIncreasedCell > 0 ? numOfIncreasedCell : 0));
      valid = valid && widthIncreased <= inchargeWidth;
      if (!valid) {
        this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.spanDenied']);
        return valid;
      }
    }
    // When slots selected in sot bric, need to stop stretching SM-7103
    if ((this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.SOT) && (this.rows[rowIndex].cells[colIndex].selected.sotType === '1')) {
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.sotSlots.notallowed'] || 'This is not allowed when slots is selected');
      valid = false;
      return valid;
    }

    valid = this.multiTargetStretchRules(valid, rowIndex, colIndex, endIndex);

    return valid;
  }

  /**
   * @description stretching rules for multi target Bric SM-7112
   * @param {boolean} valid stretch is valid
   * @param {number} rowIndex of stretched bric
   * @param {number} cellIndex of stretched bric
   * @param {number} endIndex of stretch bric
   * @returns {*} if stretch is valid or not
   * @memberof Filter
   */

  multiTargetStretchRules(valid: boolean, rowIndex: number, cellIndex: number, endIndex: number): boolean {
    if (this.rows[rowIndex].bric.bricid !== this.brickBaseService.brickID.MultiTarget &&
      !this.brickBaseService.multiTargetBrics.includes(this.rows[rowIndex].bric.bricid)) {
      return valid;
    }

    // when we stretch multi target bric
    if (this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.MultiTarget) {
      for (let i = cellIndex; i < endIndex; i++) {
        for (const row of this.rows) {
          const isEmpty = row.cells[i].isEmpty;
          const isHidden = row.cells[i].isHidden;
          if (row.bric.bricid !== this.brickBaseService.brickID.MultiTarget &&
            this.brickBaseService.multiTargetBrics.includes(row.bric.bricid) && !(isEmpty && !isHidden)) {
            valid = false;
            this.logHelperService.logError(this.initialConfig.userBundle['common.error.stretch.multitarget'] || 'Multi-Target bric cannot be stretched over Pattern/Time/Distribution/Product bric.');
            break;
          }
        }
      }
    }

    const multiTargetBric = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.MultiTarget, this.rows).Available_row_Index;
    // When we stretch brics related multi brics {this.brickBaseService.multiTargetBrics}
    if (this.brickBaseService.multiTargetBrics.includes(this.rows[rowIndex].bric.bricid) && multiTargetBric !== null && this.rows[multiTargetBric]) {
      for (let i = cellIndex; i < endIndex; i++) {
        const isEmpty = this.rows[multiTargetBric].cells[i].isEmpty;
        const isHidden = this.rows[multiTargetBric].cells[i].isHidden;
        if ((isEmpty && isHidden) || (!isEmpty && !isHidden)) {
          valid = false;
          this.logHelperService.logError(this.initialConfig.userBundle['common.error.stretch.multitargeting'] || 'Pattern/Time/Distribution/Product bric cannot be stretched over Multi-Target bric.');
          break;
        }
      }
    }

    return valid;
  }

  /**
   * @description when Incharg Bric is stretched and it has pattern bric below it
   * @author Nishit Parekh
   * @param {Cell} cell
   * @param {number} startw
   * @param {number} endw
   * @memberof Filter
   */
  checkIfPatternIsValidAfterResize(cell: Cell, startw: number, endw: number): void {
    const startIndex: number = startw + cell.cellWidth;
    if (startIndex < endw) {
      for (let i: number = startIndex; i < endw; i++) {
        this.rows[cell.rowIndex + 1].cells[i].selected = {};
        this.rows[cell.rowIndex + 1].cells[i].requestJSON = null;
      }
    } else if (startIndex > endw) {
      for (let i: number = startIndex - 1; i >= endw; i--) {
        this.rows[cell.rowIndex + 1].cells[i].selected = {};
        this.rows[cell.rowIndex + 1].cells[i].requestJSON = null;
      }
    }
  }

  /**
   * @description High lights bric on process bricks response failure
   * @author Amit Mahida
   * @param {*} res
   * @returns {boolean}
   * @memberof Filter
   */
  validateProcessBricksResponse(res): boolean {
    let validWorkspace = true;
    if (res['status'] === 'KO') {
      if (res['data'] && res['data'].bricFailure) {
        if (!_.isUndefined(res['data'].bricFailure.row) && !_.isUndefined(res['data'].bricFailure.column)) {
          const rowIndex = res['data'].bricFailure.row;
          const colIndex = res['data'].bricFailure.column;
          this.rows[rowIndex].isValid = false;
          if (this.rows[rowIndex].getOverlappingCell(colIndex) !== -1) {
            const overlappedCellIndex = this.rows[rowIndex].getOverlappingCell(colIndex);
            this.rows[rowIndex].cells[overlappedCellIndex].isValid = false;
          } else {
            this.rows[rowIndex].cells[colIndex].isValid = false;
          }
        }
      }
      validWorkspace = false;
    } else if (res['status'] === 'OK') {
      for (const row of this.rows) {
        row.isValid = true;
        for (const cell of row.cells) {
          cell.isValid = true;
        }
      }
      validWorkspace = true;
    }
    return validWorkspace;
  }

  /**
   * @description It sets workspace to invalid state, Clears ColumnSummary and CampaignSummary, and set result tab to false.
   * @author Shivani Patel
   * @param {boolean} [valid=true]
   * @memberof Filter
   */
  setWorkspaceValidity(valid = true) {
    SystemFlags.incorrectSolution = !valid;
    this.stateService.updateWorkspaceValidity(valid);
    if (!valid) {
      this.stateService.clearProcessResponse();
    }
    this.dataShareService.activateResultTab(valid);
    this.dataShareService.activateCommercialTab(valid)
    if (valid && SystemFlags.isCloneCampaign) {
      this.dataShareService.enableGeoMapperTab(true);
      this.dataShareService.enableVisualPlannerTab(true);
    }
  }

  /**
   * @description This method will send bricks data to backend for processing
   * @returns {void}
   * @memberof Filter
   */
  processBrics(): Promise<boolean> {
    return new Promise((resolve) => {
      this.reshuffle.reshufflingColumns = [];
      SystemFlags.isCampaignSummaryChanged = true;
      if (!this.validateCurrentWorkspace()) {
        this.setWorkspaceValidity(false);
        this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.invalidBricks']);
        return;
      }
      if ((SystemFlags.isFillerCampaign && !this.checkMandatoryBRICSForFillerCampaign()) || !this.isMandatoryBricksAvailable()) {
        this.setWorkspaceValidity(false);
        return;
      }

      // High level requirement of Process Bricks:
      // Check for mandatory bricks
      // Backup Old Column Summary
      // Disable Commercial and Result Tab
      // Add bricsData, campaignParameters, columnHelper, columnLineNumber, deletedColumns in request
      // Mark all bricks as valid to remove previsouly marked error
      // Maintain campaign summary bar
      // Throw / Show Error if response if KO

      if (!this.isValidSelectionForProduct()) {
        this.setWorkspaceValidity(false);
        this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.invalidBricks']);
        return;
      }

      const param: ProcessParams = new ProcessParams();
      const processBrick: ProcessBrick = new ProcessBrick();
      processBrick.bricsData = this.brickRequestJSON;
      // TODO: Need to remove hardcoding of bricsCampaignId.
      param.bricsCampaignId = 1;
      param.action = 'processBrics';

      processBrick.campaignParameters = this.campaignParameters;

      const columnHelper: ColumnHelper = _.clone(this.stateService.getColumnHelper());

      if (this.initialConfig.uiControl.defaultAllocationEngine) {
        // SM-6264
        if (this.allocationEngines && this.allocationEngines.length > 0) {
          Object.keys(this.allocationEngines).forEach((index) => {
            columnHelper[index] = {
              systemLocked: columnHelper[index] ? columnHelper[index].systemLocked : false,
              shown: columnHelper[index] ? columnHelper[index].shown : true,
              userLocked: columnHelper[index] ? columnHelper[index].userLocked : false,
              allocationEngine: this.allocationEngines[index]
                || this.initialConfig.uiControl.defaultAllocationEngine
                || GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_GISGO
            };
          });
        } else {
          for (let r = 0; r < this.rows[0].cells.length; r++) {
            columnHelper[r] = {
              systemLocked: columnHelper[r] ? columnHelper[r].systemLocked : false,
              shown: columnHelper[r] ? columnHelper[r].shown : true,
              userLocked: columnHelper[r] ? columnHelper[r].userLocked : false,
              allocationEngine: this.initialConfig.uiControl.defaultAllocationEngine
                || GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_GISGO
            };
          }
        }
      }

      if (columnHelper && Object.keys(columnHelper).length) {
        processBrick.columnHelper = columnHelper;
      } else {
        delete processBrick.columnHelper;
      }
      // TODO:
      processBrick.columnLineNumber = this.stateService.getWorkspaceObject('columnLineNumber');
      if (Object.keys(processBrick.columnLineNumber).length === 0) {
        delete processBrick.columnLineNumber;
      }

      // // SMI-471,VJ:10Nov17 Need to send index of deleted columns for backend processing
      if (this.deletedColIndex && this.deletedColIndex.length > 0) {
        processBrick.deletedColumns = this.deletedColIndex;
        this.deletedColIndex = [];
      } else {
        delete processBrick.deletedColumns;
      }

      param.data = JSON.stringify(processBrick);
      const bricChangeUrl: string = this.dataShareService.getServiceCallUrlByKey('BRIC_CHANGE_URL');
      const campaignUrl: string = this.dataShareService.getServiceCallUrlByKey('CAMPAIGN_URL');
      const serviceURL: string = bricChangeUrl ? bricChangeUrl : campaignUrl;
      this.workspaceService.processBricks(param, serviceURL).subscribe((res) => {
        this.dataShareService.finalPrice = res.data.campaignSummary.map(summary => summary.finalPrice);
        this.updateForceRerunStatus(res);
        this.processBricksCallback(res);
        resolve(true);
      });
    });
  }

  mergeObjectiveMeasures(objectiveMeasures: any[]) {
    this.objectiveMeasuresData = objectiveMeasures;
    this.rows = this.workspaceService.mergeObjectiveMeasures(objectiveMeasures, this.rows);
    if (this.appHeaderService.objectiveMode) {
      this.generateBrickRequestJSON();
    }
  }

  processBricksCallback = (res) => {
    this.toleranceLimit.cellAddress = [];
    SystemFlags.brickProcessed = true;
    if (this.validateProcessBricksResponse(res) && res['status'] === 'OK') {
      let filter: Filter = this;
      this.stateService.setProcessBrickResponse(res['data']);
      if (this.appHeaderService && this.appHeaderService.enabledPCM) {
        this.stateService.setPCMObject('filter', this.stateService.clone(this));
      } else if (this.appHeaderService && this.appHeaderService.objectiveMode) {
        this.stateService.setObjectiveObject('filter', this.stateService.clone(this));
        filter = this.stateService.getWorkspaceFilterObj();
      } else {
        this.stateService.setWorkspaceObject('filter', this.stateService.clone(this));
        if (GLOBAL.localSolverEnabled) {
          if (res.data.objectiveMeasures) {
            this.mergeObjectiveMeasures(res.data.objectiveMeasures);
          }

          if (res.data.columnSummary) {
            const keys = Object.keys(res.data.columnSummary);
            keys.forEach((k: any) => {
              this.allocationEngines[k] = res.data.columnSummary[k].allocationEngine;
            });
          }
        }
      }
      this.workspaceService.validateBricksAgainstColumnConfig(filter, res['data']['columnConfig']);
      this.columnSummary = this.stateService.getAllColumnSummary();
      if(Object.keys(res.data.columnSummary).length> 0) {
        for (const key in res.data.columnSummary) {
          if(res.data.columnSummary[key].numberFrames == 0) {
            this.rows[key].isValid = false;
            this.setWorkspaceValidity(false);
            this.stateService.setCampaignSummary([]);
            this.stateService.setColumnSummary({});
            this.stateService.setLoadedCampaignSummary([]);
            this.logHelperService.logError(this.userBundle['worksapce.error.Noframesavailable'] || 'No frames available!');
            return;
          }
        }
      }
      this.setWorkspaceValidity();
    } else {
      this.setWorkspaceValidity(false);
      let reshuffleColumn = null;
      if (res['data'] && res['data'].bricFailure) {
        reshuffleColumn = res['data'].bricFailure.column;
        const columnIndex = res['data'].bricFailure.column;
        if (columnIndex > 0) {
          for (let index = 0; index < columnIndex; index += 1) {
            if (this.stateService.columnHelper && this.stateService.columnHelper[index] && !this.stateService.columnHelper[index].shown) {
              reshuffleColumn -= 1;
            }
          }
        }
      }
      this.populateReshufflingColumns(true, reshuffleColumn);
      this.logHelperService.logError(res['message'] || this.userBundle['worksapce.error.solutionNotPossible']);
      this.breakFTGIteration();
    }
  }

  /**
   * @description Method to check avaialability of mandatory bricks
   * @author Vijay Sutaria, Amit Mahida
   * @param {number} [colIndex]
   * @returns {boolean}
   * @memberof Filter
   */
  isMandatoryBricksAvailable(colIndex?: number): boolean {
    if (this.appHeaderService.objectiveMode) {
      return true;
    }

    // Find Rows with mandatory bricks
    const mandatoryRows: Row[] = this.rows.filter((item) => {
      return this.initialConfig.uiControl.processingBrics.indexOf(item.bric.bricid) !== -1;
    });

    // If count of mandatory row is less than processing bricks items then workspace is invalid
    if (mandatoryRows.length !== this.initialConfig.uiControl.processingBrics.length) {
      return false;
    }

    // check if any cell is empty in mandatory rows
    let isValid = false;

    if (!_.isUndefined(colIndex)) {
      isValid = (mandatoryRows.filter((r: Row) => {
        return r.cells[colIndex] && (!r.cells[colIndex].isEmpty || (r.cells[colIndex].isEmpty && r.cells[colIndex].isHidden));
        // If any cell is visible-empty in any row then length will not be 0 and , and so this will be false
      })).length === this.initialConfig.uiControl.processingBrics.length;
    } else {
      isValid = (mandatoryRows.filter((r: Row) => {
        return r.cells.filter((c: Cell) => {
          return c.isEmpty && !c.isHidden;
        }).length === 0;
        // If any cell is visible-empty in any row then length will not be 0 and , and so this will be false
      })).length === this.initialConfig.uiControl.processingBrics.length;
    }

    return isValid;
  }

  /**
  * @description Check Mandatory BRICS for Filler Campaign
  * @author Shreni
  * @param {number} [colIndex]
  * @returns {boolean}
  * @memberof Filter
  */
  checkMandatoryBRICSForFillerCampaign(colIndex?: number): boolean {
    const processingBRICS = this.initialConfig.uiControl.processingBrics.concat(
      [Number(this.brickBaseService.brickID.SOT), Number(this.brickBaseService.brickID.Distribution)]);

    // Find Rows with mandatory bricks
    const mandatoryRows: Row[] = this.rows.filter((item) => {
      return processingBRICS.indexOf(item.bric.bricid) !== -1;
    });

    // If count of mandatory row is less than processing bricks items then workspace is invalid
    if (mandatoryRows.length !== processingBRICS.length) {
      return false;
    }

    // check if any cell is empty in mandatory rows
    let isValid = false;

    if (!_.isUndefined(colIndex)) {
      isValid = (mandatoryRows.filter((r: Row) => {
        return r.cells[colIndex] && (!r.cells[colIndex].isEmpty || r.cells[colIndex].isHidden);
        // If any cell is visible-empty in any row then length will not be 0 and , and so this will be false
      })).length === processingBRICS.length;
    } else {
      isValid = (mandatoryRows.filter((r: Row) => {
        return r.cells.filter((c: Cell) => {
          return c.isEmpty && !c.isHidden;
        }).length === 0;
        // If any cell is visible-empty in any row then length will not be 0 and , and so this will be false
      })).length === processingBRICS.length;
    }
    return isValid;
  }

  /**
   * @description This method will check if brick id is of mandatory brick
   * @author Vijay Sutaria
   * @param {number} brickID
   * @returns {boolean}
   * @memberof Filter
   */
  isMandatoryBrick(brickID: number): boolean {
    return this.initialConfig.uiControl.processingBrics.indexOf(brickID) > -1;
  }

  /**
   * @description Check if final target group iteration is allowed
   * @author Amit Mahida
   * @returns
   * @memberof Filter
   */
  checkFtgIterationAllowed() {
    let allowed = false;
    for (const row of this.rows) {
      for (const cell of row.cells) {
        const columnSummary = this.stateService.getColumnSummary(cell.cellIndex.toString());
        allowed = this.initialConfig.uiControl.allocateImpressionsToFTG
          && this.checkIfCellExistsInColumn(cell.cellIndex, Number(this.brickBaseService.brickID.Volume), true) > -1
          && this.checkIfCellExistsInColumn(cell.cellIndex, Number(this.brickBaseService.brickID.AllAudience), true) > -1
          && columnSummary && !_.isUndefined(columnSummary.secondaryImpression);
        if (allowed) {
          break;
        }
      }
      if (allowed) {
        break;
      }
    }
    this.ftgIterationAllowed = allowed;
    return allowed;
  }

  breakFTGIteration() {
    this.totalFtgIteration = 0;
    this.breakFtgIteration = true;
  }

  /**
   * @description Combines request json of all bricks added to workspace to send it to backend
   * @author Vijay Sutaria
   * @memberof Filter
   */
  generateBrickRequestJSON() {
    this.brickRequestJSON = [];
    this.rows.forEach((row: Row) => {
      row.cells.forEach((cell: Cell) => {
        const requestJson: any = {};
        if (cell.requestJSON && !cell.isEmpty) {
          requestJson.columns = [];
          requestJson.row = row.index;

          // Case when the column is dragged//
          if (cell.cellWidth > 1) {
            const columnarray = [];
            for (let c = 0; c < cell.cellWidth; c++) {
              columnarray.push(cell.cellIndex + c);
            }
            requestJson.columns = columnarray;
          } else {
            requestJson.columns.push(cell.cellIndex);
          }
          // Used Loop Becuase for Format we will have multiple key to be sent //
          const requestJSONKeys = Object.keys(cell.requestJSON);
          for (const key of requestJSONKeys) {
            requestJson[key] = cell.requestJSON[key];
          }

          if (row.bric && (row.bric.bricid === this.brickBaseService.brickID.ProductCatalogue)) {
            const colIndexToCheck = this.checkIfCellExistsInColumn(cell.cellIndex, this.brickBaseService.brickID.ProductCatalogue, true);
            const product = this.productDetails.find(p => p.columnIndex === colIndexToCheck);
            if (product) {
              requestJson.selectionProductCatalogue.explodedColumn = {};
              for (const productValidation of Object.keys(product.validation)) {
                const index = Object.keys(product.validation).indexOf(productValidation);
                const col = Number(productValidation);
                if (product.validation[col] && !product.validation[col + 1] && !_.isUndefined(Object.keys(product.validation)[index + 1])) {
                  requestJson.selectionProductCatalogue.explodedColumn[col] = Number(Object.keys(product.validation)[index + 1]) - Number(Object.keys(product.validation)[index]) - 1;
                }
                if (_.isUndefined(Object.keys(product.validation)[index + 1]) && col < (row.cells[colIndexToCheck].cellIndex + row.cells[colIndexToCheck].cellWidth - 1)) {
                  // For last validation
                  requestJson.selectionProductCatalogue.explodedColumn[col] = (row.cells[colIndexToCheck].cellIndex + row.cells[colIndexToCheck].cellWidth - 1) - Number(Object.keys(product.validation)[index]);
                }
              }
            }
            if (!_.isUndefined(requestJson.selectionProductCatalogue.explodedColumn) && !Object.keys(requestJson.selectionProductCatalogue.explodedColumn).length) {
              delete requestJson.selectionProductCatalogue.explodedColumn;
            }
          }
          if (row.bric && row.bric.bricid === this.brickBaseService.brickID.Volume
            && this.checkFtgIterationAllowed()
          ) {
            const columnSummary = this.stateService.getColumnSummary(cell.cellIndex.toString());
            const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
            const systemLocked = columnHelper && columnHelper[cell.cellIndex] && columnHelper[cell.cellIndex].systemLocked;

            cell.requestJSON.selectionAudienceReduction.totalFtgIteration = _.clone(this.lastFtgIteration);
            if (this.totalFtgIteration === 0 && (cell.selected.updatedFromModal || cell.selected.multiDropped)) {
              // This is only called when user has manually updated impressions and clicked ok or multidropped
              cell.selected.actualFtgTarget = _.cloneDeep(cell.selected.impressions);
              cell.requestJSON.selectionAudienceReduction.actualFtgTarget = _.clone(cell.selected.actualFtgTarget);
              delete cell.selected.updatedFromModal;
              delete cell.selected.multiDropped;
              delete cell.selected.breakFTGIteration;
            }
            if (!cell.selected.breakFTGIteration && cell.selected.actualFtgTarget && columnSummary.secondaryImpression && !systemLocked && !cell.selected.updatedFromAudience) {
              cell.selected.impressions = parseInt((cell.selected.actualFtgTarget * (columnSummary.audienceImpressions / columnSummary.secondaryImpression)).toString(), 10);
              cell.requestJSON.selectionAudienceReduction.impressions = _.clone(cell.selected.impressions);
            }
            if (cell.selected.updatedFromAudience) {
              delete cell.selected.updatedFromAudience;
            }
          }

          if (row.bric && row.bric.bricid === this.brickBaseService.brickID.Objective) {
            requestJson[this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Objective]].disableObjective = cell.selected.disableObjective;
            requestJson[this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Objective]].isAllSelection = _.isEmpty(cell.selected.objectives) ? true : false;
          }
          this.brickRequestJSON.push(requestJson);
        }
      });
    });
  }

  /**
   * @description This method is to create empty cells at the end of Rows when new brick is added to top rows
   * @memberof Filter
   */
  createEmptyCells() {
    const maxCellIndex: number = this.getMaxCellIndex();
    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
    this.rows.forEach((row: Row) => {
      row.createEmptyCells(maxCellIndex, columnHelper);
    });
  }

  /**
   * @description Checks whether the cell is allowed to move or not
   * @author Shivani Patel
   * @param {Cell} dragCell
   * @param {Cell} dropCell
   * @param {ExpandDirection} expandDirection
   * @returns {boolean} Returns true if swap cell is allowed
   * @memberof Filter
   */
  validateMoveCellRules(dragCell: Cell, dropCell: Cell, expandDirection: ExpandDirection): boolean {
    const cellPosition: CellPosition = new CellPosition();
    cellPosition.rowIndex = dropCell.rowIndex;
    cellPosition.cellIndex = dropCell.cellIndex;
    cellPosition.expandDirection = expandDirection;
    const allowed: {
      isValid: boolean;
      note: string;
    } = this.checkIfCellIsAllowedAtPosition(cellPosition, this.rows[dragCell.rowIndex].bric);

    if (!allowed.isValid) {
      this.logHelperService.logError(allowed.note);
      return false;
    }

    // Do not allow product to drop to other columns.
    if (this.rows[dragCell.rowIndex].bric.bricid === this.brickBaseService.brickID.ProductCatalogue) {
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.notallowed']);
      return false;
    }

    // Do not allow product's optional brics to drop to other columns.
    const productDetail: ProductHelper = this.getProductHelperIfProductExists(dragCell.cellIndex);
    if (productDetail && productDetail.optional[productDetail.columnIndex].indexOf(this.rows[dragCell.rowIndex].bric.bricid) !== -1) {
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.notallowed']);
      return false;
    }

    // Do not allow any brics to drop to the columns where the product exist.
    if (this.getProductHelperIfProductExists(dropCell.cellIndex)) {
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.notallowed']);
      return false;
    }

    // Pattern Bric cannot be drag and dropped from one column to another if it has different incharge bric above it.
    if (this.rows[dragCell.rowIndex].bric.bricid === this.brickBaseService.brickID.Pattern) {
      const inchargeRow: Row = this.getRowByBrickId(Number(this.brickBaseService.brickID.Incharge));
      const inchargeOverlappingIndex: number = inchargeRow.getOverlappingCell(dragCell.cellIndex);
      if (inchargeOverlappingIndex === -1 || !(dragCell.cellIndex !== dropCell.cellIndex
        && dropCell.cellIndex >= inchargeOverlappingIndex
        && dropCell.cellIndex < inchargeOverlappingIndex + inchargeRow.cells[inchargeOverlappingIndex].cellWidth)) {
        this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.patternSwap']);
        return false;
      }
    }
    return true;
  }

  /**
   * @description Method to move cell or row
   * @param {(Row & Cell)} data Row or Cell object to move
   * @param {number} rowIndex dest row index
   * @param {number} colIndex dest col index
   * @param {ExpandDirection} expandDirection
   * @returns
   * @memberof Filter
   */
  public moveBrick(data: Row & Cell, rowIndex: number, colIndex: number, expandDirection: ExpandDirection) {
    if (data.cellIndex === colIndex && data.rowIndex === rowIndex) {
      return;
    } // Source and dest is same

    // Moving brick in same row, if this is the case then it must be cell
    if (data.rowIndex === rowIndex && colIndex !== undefined && colIndex != null) {
      if (this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Incharge) {
        data.selected = {
          ...data.selected,
          startDate: new Date(data.selected.startDate),
          endDate: new Date(data.selected.endDate)
        };
      }
      if (!this.validateMoveCellRules(data, this.rows[rowIndex].cells[colIndex], expandDirection)) {
        return;
      }

      if (this.appHeaderService && this.appHeaderService.enabledPCM) {
        this.historyStackService.pushInHistoryStackPCM(new HistoryStackData(this));
      } else if (this.appHeaderService && this.appHeaderService.objectiveMode) {
        this.historyStackService.pushInHistoryStackObjective(new HistoryStackData(this));
      } else {
        this.historyStackService.pushInHistoryStack(new HistoryStackData(this));
        this.historyStackService.pushColumnHelperInHistoryStack(this.stateService.getColumnHelper());
      }

      if (expandDirection === ExpandDirection.Right) {
        const tmpCell: Cell = this.rows[rowIndex].cells[colIndex];
        colIndex = tmpCell.cellWidth - 1 + tmpCell.cellIndex;
      }

      const tmpColIndex: number = expandDirection === 1
        && (!this.rows[rowIndex].cells[colIndex].isEmpty
          || (this.rows[rowIndex].cells[colIndex].isEmpty && this.rows[rowIndex].cells[colIndex].isHidden))
        ? colIndex + 1 : colIndex;

      const sourceIndex: number = data.cellIndex;
      const isDestEmpty: boolean = this.rows[rowIndex].cells[tmpColIndex] && this.rows[rowIndex].cells[tmpColIndex].isEmpty && !this.rows[rowIndex].cells[tmpColIndex].isHidden;

      // Cell Swapping && Push empty Cell from where Cell is moved
      this.rows[rowIndex].moveCell(data as Cell, tmpColIndex, isDestEmpty);

      if (this.rows[rowIndex] && this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Media && this.initialConfig.uiControl.defaultAllocationEngine) {
        const sourceEngine = this.allocationEngines[sourceIndex];

        if (expandDirection === ExpandDirection.Left) {
          // right to left
          this.allocationEngines.splice(sourceIndex, this.rows[rowIndex].cells[sourceIndex].cellWidth, '');
          this.allocationEngines.splice(tmpColIndex, 0, sourceEngine);
        } else if (expandDirection === ExpandDirection.Right) {
          // left to right
          this.allocationEngines.splice(tmpColIndex, 0, sourceEngine);
          this.allocationEngines.splice(sourceIndex, this.rows[rowIndex].cells[sourceIndex].cellWidth, '');
        }
      }

      if (!isDestEmpty) {
        this.manageColumnHelper(tmpColIndex);
      }

      // Stretch All Rows except current
      for (let i = 0; i < this.rows.length; i++) {
        // added and condition for swaping pattern bric under stretched range bric
        if (this.rows[i].bric.bricid === this.brickBaseService.brickID.Pattern && !isDestEmpty) {
          const pattrerBrick = this.rows[i].cells.splice(sourceIndex, 1);
          this.rows[i].createEmptyCellAtIndex(i, sourceIndex, false);
          this.rows[i].cells.splice(tmpColIndex, isDestEmpty ? 1 : 0, pattrerBrick[0]);
        } else if (!isDestEmpty && rowIndex !== i) { // Need to stretch all rows and brick dropped is not empty
          let cellIndexToExpand = tmpColIndex;
          if (expandDirection === ExpandDirection.Right) {
            cellIndexToExpand = cellIndexToExpand === 0 ? 0 : cellIndexToExpand - 1;
          }
          if (!this.rows[i].cells[cellIndexToExpand]
            || (this.rows[i].cells[cellIndexToExpand].isEmpty && !this.rows[i].cells[cellIndexToExpand].isHidden)
            || (this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Incharge && (this.isQuantityBric(this.rows[i].bric.bricid)
              || this.rows[i].bric.bricid === this.brickBaseService.brickID.PricingTag))
          ) {
            this.rows[i].createEmptyCellAtIndex(i, tmpColIndex, false);
          } else if (this.isQuantityBric(this.rows[i].bric.bricid)
            && GLOBAL.localSolverEnabled
            && !this.initialConfig.uiControl.allowReductionBrickSpan) {
            this.rows[i].createEmptyCellAtIndex(i, tmpColIndex, false);
          } else {
            this.rows[i].expandCell(tmpColIndex, expandDirection);
          }
        }
      }
      this.reApplyIndex();
      this.createEmptyCells();
      this.postCellUpdates();
    } else if (data.rowIndex !== rowIndex) {
      this.swapRow(data.rowIndex, rowIndex);
    }
  }

  /**
   * @description Used to swap two rows
   * @author Shivani Patel
   * @param {number} dragRowIndex
   * @param {number} dropRowIndex
   * @memberof Filter
   */
  public swapRow(dragRowIndex: number, dropRowIndex: number) {
    // If dragRowIndex and dropRowIndex are undefined or null then return
    if (dragRowIndex === undefined || dragRowIndex === null
      || dropRowIndex === undefined || dropRowIndex === null) {
      return;
    }

    // Do not allow to swap row if any column of workspace has been locked
    if (this.isLockedColsPresent()) {
      this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.notAllowedSwap']);
      return;
    }

    // Do not allow to swap if any of the bric is none primary.
    if (!this.rows[dragRowIndex].bric.isprimary || !this.rows[dropRowIndex].bric.isprimary) {
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.notallowed']);
      return;
    }

    // Do not allow Product bric to drag
    if (this.rows[dragRowIndex].bric.bricid === this.brickBaseService.brickID.ProductCatalogue) {
      this.logHelperService.logError(this.initialConfig.userBundle['common.error.notallowed']);
      return;
    }

    // Product and it's mandatory rows always comes after the Incharge bric row.
    if (this.rows[dragRowIndex].bric.bricid === this.brickBaseService.brickID.Incharge
      && this.isRowAvailable(Number(this.brickBaseService.brickID.ProductCatalogue))) {
      let allowed = true;
      for (let i = 0; i <= dropRowIndex; i++) {
        if (this.rows[i].cells.filter((cell: Cell) => cell.isMandatoryForProduct).length) {
          allowed = false;
          break;
        }
      }
      if (!allowed) {
        this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.notAllowedSwap']);
        return;
      }
    }

    // Push current workspace to historystack.
    if (this.appHeaderService && this.appHeaderService.enabledPCM) {
      this.historyStackService.pushInHistoryStackPCM(new HistoryStackData(this));
    } else if (this.appHeaderService && this.appHeaderService.objectiveMode) {
      this.historyStackService.pushInHistoryStackObjective(new HistoryStackData(this));
    } else {
      this.historyStackService.pushInHistoryStack(new HistoryStackData(this));
      this.historyStackService.pushColumnHelperInHistoryStack(this.stateService.getColumnHelper());
    }

    // Contains rows to move at dropIndex
    let rowsToMove: Row[] = [];
    // If Pattern Bric available under Incharge Bric then move pattern along with incharge
    if (this.rows[dragRowIndex].bric.bricid === this.brickBaseService.brickID.Incharge
      && this.isRowAvailable(Number(this.brickBaseService.brickID.Pattern))) {
      // Move row of incharge and pattern bric
      rowsToMove = this.rows.splice(dragRowIndex, 2);
    } else if // If Pattern Bric is available and any other bric is placed on the Incharge Bric//
      (this.rows[dropRowIndex].bric.bricid === this.brickBaseService.brickID.Incharge
      && this.isRowAvailable(Number(this.brickBaseService.brickID.Pattern))) {
      // Splice the Dragged Brick//
      rowsToMove = this.rows.splice(dragRowIndex, 1);
      // New Row Index of Incharge //
      const rowIndexIncharge = this.rows.findIndex((item) => { return item.bric.bricid === this.brickBaseService.brickID.Incharge; });
      // If dragRowIndex and new Index of Incharge after splice are same //
      if (dragRowIndex === rowIndexIncharge) {
        dropRowIndex = rowIndexIncharge + 2;
      } else {
        dropRowIndex = rowIndexIncharge;
      }
    } else {
      rowsToMove = this.rows.splice(dragRowIndex, 1);
    }

    // If we are swapping from top to bottom
    if (dragRowIndex < dropRowIndex) {
      // As we have already removed rows from dragIndex and we are dragging top to bottom the position of dropIndex has changed.
      // So, we need to set the dropIndex
      dropRowIndex -= rowsToMove.length - 1; // Use "rowsToMove.length - 1" as we will insert the dragged brics i.e. rowsToMove to after the dropped index
    }

    // Move draggedRow to dropIndex
    this.rows.splice(dropRowIndex, 0, ...rowsToMove);

    this.reApplyIndex();
    this.postCellUpdates();
  }

  /**
   * @description Get max column index of current workspace
   * @author Vijay Sutaria
   * @returns {number}
   * @memberof Filter
   */
  public getMaxCellIndex(): number {
    let maxCellIndex = 0;
    this.rows.forEach((row: Row) => {
      row.cells.forEach((cell: Cell) => {
        if (!cell.isEmpty && cell.cellIndex > maxCellIndex) {
          maxCellIndex = cell.cellIndex;
        }
      });
    });
    return maxCellIndex;
  }

  /**
   * @description Reapply column and row index to all bricks
   * @author Vijay Sutaria
   * @private
   * @memberof Filter
   */
  private reApplyIndex() {
    let i = 0;
    this.rows.forEach((element: Row) => {
      element.index = i++;
      element.reIndexCells(element.index);
    });
  }

  /**
   * @description This method will take care of automatic suppressing of columns if whole column can be removed
   * @private
   * @returns
   * @memberof Filter
   */
  private autoSuppress() {
    const deletedIndex = [];

    // TODO: Don't shrink the bric if it is past campaign (SBRICS:2318)
    // case 1: Suppress columns which has empty cells in each row
    const maxCellIndex = this.rows.length > 0 ? this.rows[0].cells.length : -1;

    // case where all brics in row are empty, need to delete that row
    for (let i: number = this.rows.length - 1; i >= 0; i--) {
      if (this.rows[i].cells.filter((e: Cell) => e.isEmpty).length === maxCellIndex) {
        this.deleteRow(i);
      }
    }

    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();

    for (let i: number = maxCellIndex - 1; i >= 0; i--) {
      let suppress = true;

      if (columnHelper && columnHelper[i] && columnHelper[i].systemLocked) {
        // SBRICS:2318  Don't shrink the bric if it is past campaign
        continue; // SM-3668
      }

      this.rows.forEach((row: Row) => {
        if (row.cells[i] && !row.cells[i].isEmpty) {
          if (row.cells[i].cellWidth > 1) {
            // Below case is to handle few cases where we have an empty cell , strechted cell, overlapped cell in 3 rows
            // Also it will not collapse when there is a overlapped cell, stretched cell, overlapped cell in 3 rows
            // Check for cell stretched in other rows
            this.rows.forEach((r: Row) => {
              const startCellIndex: number = r.getOverlappingCell(i);
              if ((r.cells[i].isEmpty && !r.cells[i].isHidden) || (r.index === row.index)) {
                // skip if current cell is already empty and visible or its same row as outer loop
                return;
              } else if (r.cells[i].isEmpty && r.cells[i].isHidden
                && startCellIndex <= i && (startCellIndex + r.cells[startCellIndex].cellWidth - 1) > i) {
                return;
              } else if (!r.cells[i].isEmpty && r.cells[i].cellWidth > 1) {
                return;
              } else {
                suppress = false;
                return;
              }
            });
          } else {
            suppress = false;
          }
          return false; // Break Loop
        }
      });

      if (suppress) {
        // Remove this cell from each row
        this.rows.forEach((row: Row) => {
          if (row.cells[i] && row.cells[i].isHidden) { // Must be covered by stretched cell
            let index: number = i;
            while (row.cells[index].isHidden) {
              index--;
            }
            if (row.cells[index].cellWidth > 1) {
              row.cells[index].cellWidth--;
              row.cells.splice(i, 1);
            }
          } else if (row.cells[i] && row.cells[i].cellWidth > 1) {
            // If current brick is not empty but stretched then simply reduce its size
            // and remove one of the cell which is overlapped by current brick
            if (row.cells[i].cellWidth > 1) {
              row.cells[i].cellWidth--;
              row.cells.splice(i + 1, 1);
            }
          } else {
            row.cells.splice(i, 1);
          }
        });
        deletedIndex.push(i);
      }
    }
    // case 1: Ends

    // don't change position as it cause issue with multi delete of more than 2 columns
    this.reApplyIndex();

    // Case to update the columnLineNumber whenever the column is Deleted
    if (deletedIndex.length > 0) {
      for (let index = deletedIndex.length - 1; index >= 0; index--) {
        const colIndex = deletedIndex[index];
        this.deletedColIndex.push(colIndex);
        this.decrementColumnLineNumber(colIndex);
        this.manageColumnHelper(colIndex, false);
        if (this.initialConfig.uiControl.defaultAllocationEngine) {
          this.allocationEngines.splice(colIndex, 1);
        }
      }
    }

    if (!(this.appHeaderService && (this.appHeaderService.enabledPCM || this.appHeaderService.objectiveMode))) {
      if (deletedIndex.length === 0) {
        this.deletedColIndex.forEach(delIndex => {
          deletedIndex.push(delIndex);
        });
      }
      this.reIndexingProductDetails(deletedIndex);
    }
    if (this.appHeaderService && this.appHeaderService.enabledPCM) {
      this.stateService.setPCMObject('filter', this.stateService.clone(this));
    } else if (this.appHeaderService && this.appHeaderService.objectiveMode) {
      this.stateService.setObjectiveObject('filter', this.stateService.clone(this));
    } else {
      this.stateService.setWorkspaceObject('filter', this.stateService.clone(this));
    }
  }

  /**
   * @description create a row with default values - specially for load campaign
   * @author Alkesh Shah
   * @param {Brick} bricDetailsMaster - bric detail
   * @param {number} rowIndex - row index
   * @param {number} maxColumns - number of columns to create
   * @param {*} curRowJsonData - current row json data
   * @returns {Row}
   * @memberof LoadWorkspace
   */
  public createRow(bricDetailsMaster: Brick, rowIndex: number, maxColumns: number, curRowJsonData: any, bricText: string): void {
    const rowObj: Row = new Row();
    rowObj.createRowWithValues(bricDetailsMaster, rowIndex, maxColumns, curRowJsonData, bricText);
    this.rows.push(rowObj);
  }

  // This method returns the row for the particular brick
  public getRowByBrickId(id: number): Row {
    return this.rows.find((row: Row) => {
      return row['bric'].bricid === id;
    });
  }

  /**
   * @description Method to explode cell with multiple values
   * @author Vijay Sutaria
   * @param {Cell} cell cell which will be exploded
   * @param {pcmMode} boolean true if this method is called from pcmMode
   * @returns {boolean} returns true if Explode icon should remain selected
   * @memberof Filter
   */
  public explodeCell(cell: Cell, pcmMode = false): boolean {
    if (!cell.isEditable || cell.isMandatoryForProduct) return true;

    if (cell.isLocked) {
      this.logHelperService.logError(this.initialConfig.userBundle['worksapce.explode.error']);
      return true;
    }

    // If rows length grather than 1 than incharge brick should not explode
    if (this.rows.length > 1 && this.rows[cell.rowIndex].bric.bricid === this.brickBaseService.brickID.Incharge) {
      this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.notAllowedExplode']);
      return true;
    }

    const brickID: number = this.rows[cell.rowIndex].bric.bricid;
    if (this.brickBaseService.EXPLODE_ALLOWED_ON_BRICS.indexOf(brickID) === -1) {
      this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.notAllowedExplode']);
      return true; // No meaning of explode
    }

    if (Object.keys(cell.selected).length === 0 || cell.selected.hasOwnProperty('-99')) {
      this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.notAllowedExplode']);
      return true;
    }
    const historyStackToPush: HistoryStackData = new HistoryStackData(this);
    let exploded = false;
    let keys = Object.keys(cell.selected);
    let localSelected = cell.selected;
    if (brickID === this.brickBaseService.brickID.Tag) {
      keys = Object.keys(cell.selected['allTabSelection']);
      localSelected = cell.selected['allTabSelection'];
    }
    // Check if all sub keys of cell.selected has more than 1 value selected to proceed for explode
    let totalSelected = 0;
    for (const key of keys) {
      if (localSelected[key].length) {  // this will skip if any key has non array value or 0 in array
        totalSelected += localSelected[key].length;
      }
    }
    const startIndex: number = cell.cellIndex;
    const endIndex: number = cell.cellIndex + cell.cellWidth - 1;
    if (cell.cellWidth > 1) {
      // If cell to explod is already stretched then split it with same values and explode each cell
      cell.cellWidth = 1;
      for (let e: number = startIndex + 1; e <= endIndex; e++) {
        const cellToUpdate: Cell = this.rows[cell.rowIndex].cells[e];
        cell.toolTipText = this.cellAttributeService.getToolTip(brickID, cell.selected);
        cellToUpdate.updateCellValues(cell);

      }
    }

    // If current row is Incharge row then It should explode range by 2 weeks
    if (this.rows[cell.rowIndex].bric.bricid === this.brickBaseService.brickID.Incharge) {
      if (pcmMode) {
        this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.notAllowedExplode']);
        return;
      }
      const tmpEndDate: Date = new Date(cell.selected.endDate);
      const tmpStartDate: Date = new Date(cell.selected.startDate);
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffDays = Math.round(Math.abs((tmpEndDate.getTime() - tmpStartDate.getTime()) / oneDay));

      if (diffDays > 13) {
        let startDate: Date = tmpStartDate;
        let endDate: Date;
        const explodedCells: Cell[] = [];
        while (startDate <= tmpEndDate) {
          endDate = startDate.addDays(13);
          if (endDate > new Date(cell.selected.endDate)) {
            endDate = new Date(cell.selected.endDate);
          }
          const newCell: Cell = new Cell();
          newCell.updateCellValues(cell);
          newCell.selected = {
            startDate,
            endDate
          };
          newCell.displayText = this.cellAttributeService.getDisplayText(brickID, newCell.selected);
          newCell.requestJSON = this.cellAttributeService.getBrickRequestJSON(brickID, newCell.selected);
          explodedCells.push(newCell);
          startDate = endDate.addDays(1);
        }
        this.rows[cell.rowIndex].cells.splice(cell.cellIndex, 1, ...explodedCells);
        exploded = true;
      }
    } else if (brickID === this.brickBaseService.brickID.Location && this.initialConfig.uiControl.geoLocationTree) {  // check exploded brick is Location
      let explodedCells = []; // maintain exploded cell array
      let totalexplodedCell = 0; // maintain total exploded count
      for (const key of keys) {
        if (localSelected[key].length > 0) {
          if (this.initialConfig.uiControl.geoLocationTree && Object.keys(GLOBAL.geoTree).indexOf(key) > -1) { // for tree view exist in location
            this.explodeAreaTreeViewData(key, cell, explodedCells);
            totalSelected = explodedCells.length !== 0 ? explodedCells.length : totalSelected;
            this.rows[cell.rowIndex].cells.splice(cell.cellIndex + 1, 0, ...explodedCells); // add exploded cell in current row index
            totalexplodedCell = totalexplodedCell + explodedCells.length;
            exploded = totalexplodedCell > 0;
            explodedCells = [];
          } else { // for other location
            for (let j = 0; j < localSelected[key].length; j++) {
              const newCell: Cell = new Cell();
              newCell.updateCellValues(cell);
              newCell.selected = {};
              newCell.selected[key] = [];
              newCell.selected[key].push(localSelected[key][j]);
              newCell.displayText = this.cellAttributeService.getDisplayText(brickID, newCell.selected);
              newCell.requestJSON = this.cellAttributeService.getBrickRequestJSON(brickID, newCell.selected);
              newCell.toolTipText = this.cellAttributeService.getToolTip(brickID, newCell.selected);
              this.rows[cell.rowIndex].cells.splice(cell.cellIndex + j + 1, 0, newCell);
              exploded = true;
            }
          }
        }
      }
      if (exploded) {
        let cellExploded = 0;
        for (const key of keys) {
          if (localSelected[key].length && Object.keys(GLOBAL.geoTree).indexOf(key) === -1) {
            cellExploded += localSelected[key].length;
          }
        }
        this.processExplodeCell(cell, cellExploded + totalexplodedCell);
      }
    } else {
      for (let explodeCount = 0; explodeCount <= (endIndex - startIndex); explodeCount++) {
        const cellToExplode = this.rows[cell.rowIndex].cells[cell.cellIndex + (explodeCount * totalSelected)];
        cellToExplode.cellIndex = cell.cellIndex + (explodeCount * totalSelected);
        for (const key of keys) {
          let localCellToExplodeSelected = cellToExplode.selected;
          if (brickID === this.brickBaseService.brickID.Tag) {
            localCellToExplodeSelected = cellToExplode.selected['allTabSelection'];
          }
          if (localCellToExplodeSelected[key].length > 0) {

            for (let j = 0; j < localSelected[key].length; j++) {
              const newCell: Cell = new Cell();
              newCell.updateCellValues(cellToExplode);
              if (brickID === this.brickBaseService.brickID.Tag) {
                newCell.selected = {
                  'allTabSelection': {}
                };
                newCell.selected.allTabSelection[key] = [];
                newCell.selected.allTabSelection[key].push(localCellToExplodeSelected[key][j]);
              } else {
                newCell.selected = {};
                newCell.selected[key] = [];
                newCell.selected[key].push(localCellToExplodeSelected[key][j]);
              }
              if (brickID === this.brickBaseService.brickID.Format) {
                newCell.selected.selectionCriteriaRestriction = localCellToExplodeSelected.selectionCriteriaRestriction;
              }
              newCell.displayText = this.cellAttributeService.getDisplayText(brickID, newCell.selected);
              newCell.requestJSON = this.cellAttributeService.getBrickRequestJSON(brickID, newCell.selected);
              newCell.toolTipText = this.cellAttributeService.getToolTip(brickID, newCell.selected);
              this.rows[cellToExplode.rowIndex].cells.splice(cellToExplode.cellIndex + j + 1, 0, newCell);
            }

            exploded = true;
          }
        }
        this.processExplodeCell(cellToExplode, totalSelected);
      }
    }
    if (exploded) {
      if (this.appHeaderService && this.appHeaderService.enabledPCM) {
        this.historyStackService.pushInHistoryStackPCM(historyStackToPush);
      } else if (this.appHeaderService && this.appHeaderService.objectiveMode) {
        this.historyStackService.pushInHistoryStackObjective(historyStackToPush);
      } else {
        this.historyStackService.pushInHistoryStack(historyStackToPush);
        this.historyStackService.pushColumnHelperInHistoryStack(this.stateService.getColumnHelper());
      }

      this.historyStackService.pushColumnLineNumberInHistoryStack(this.stateService.getWorkspaceObject('columnLineNumber'));

      if (this.stateService.getWorkspaceFilterObj().rows.length > 0 &&
        this.stateService.getWorkspaceFilterObj().rows[0].cells.length !== this.rows[0].cells.length) {
        const addedColumnCount = this.rows[0].cells.length - this.stateService.getWorkspaceFilterObj().rows[0].cells.length;
        // Increment the ColumnLine Number//
        for (let index = 0; index < addedColumnCount; index++) {
          this.incrementColumnLineNumber(startIndex);
        }
        if (this.appHeaderService && !this.appHeaderService.enabledPCM && !this.appHeaderService.objectiveMode && this.productDetails.length) {
          this.productDetails = this.pcmService.updateProductValidationsOnExplode(cell.cellIndex, this.productDetails, addedColumnCount);
        }

        // re indexing of lockButtons array when nb of column changes e.g. explode
        const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
        if (Object.keys(columnHelper).length) {
          for (let index = cell.cellIndex; index < (cell.cellIndex + addedColumnCount); index++) {
            this.manageColumnHelper(index, true);
          }
        }
      }
      this.postCellUpdates(true); // added true flag for SM-3910
    }
    return false;
  }

  /**
  * @description check is brics is quanriry bric or not
   * @author Darshan Vachhani
   * @param { number } bricid
   * @returns { boolean }
   * @memberof Filter
   */
  // public isQuantityBric(bricid: number): boolean {
  //   return this.brickBaseService.quantityBrickId.indexOf(bricid) !== -1 || bricid === this.brickBaseService.brickID.PricingTag || bricid === this.brickBaseService.brickID.SOT;
  // }
  // updated for SM-10912
  public isQuantityBric(bricid: number): boolean {
    return this.brickBaseService.quantityBrickId.indexOf(bricid) !== -1 || bricid === this.brickBaseService.brickID.SOT;
  }

  /**
  * @description check is brics is reduction bric or not
   * @author Vijay Sutaria
   * @param { number } bricid
   * @returns { boolean }
   * @memberof Filter
   */
  public isReductionBric(bricid: number): boolean {
    return (this.brickBaseService.quantityBrickId.indexOf(bricid) !== -1 || bricid === this.brickBaseService.brickID.PricingTag) && bricid !== this.brickBaseService.brickID.Volume && bricid !== this.brickBaseService.brickID.Frame && bricid !== this.brickBaseService.brickID.Budget;
  }

  /**
  * @description check is brics is reduction bric or not
   * @author Vijay Sutaria
   * @param { number } bricid
   * @returns { boolean }
   * @memberof Filter
   */
  public isQuantityBrick(bricid: number): boolean {
    return this.brickBaseService.quantityBrickId.indexOf(bricid) !== -1 || bricid === this.brickBaseService.brickID.PricingTag;
  }

  /**
   * @description Delete existing row and create new row with multiple cell
   * @author Darshan Vachhani
   * @param {*} brickData
   * @memberof Filter
   */
  public dropMultipleBrics(brickData) {
    const historyStackData = new HistoryStackData(this);
    const columnLineNumberHistoryStack = this.stateService.getWorkspaceObject('columnLineNumber');
    const maxColIndex = this.getMaxCellIndex();
    let insertedBricsCount = 0;
    for (let i = 0; i <= maxColIndex; i++) {
      const cellPosition: CellPosition = this.detectCellPosition(brickData, -1, i);
      // Check if row is already available or not.
      if ((this.isRowAvailable(brickData.bricid) && !this.rows[cellPosition.rowIndex].cells[cellPosition.cellIndex].isEmpty
        && !this.rows[cellPosition.rowIndex].cells[cellPosition.cellIndex].isHidden)
        || !this.checkIfCellIsAllowedAtPosition(cellPosition, brickData, false).isValid) {
        continue;
      }

      insertedBricsCount += 1;
      const cellValues: CellValues = new CellValues();
      cellValues.brick = { ...brickData, ...cellPosition };
      this.addMultipleBric(cellValues);
      const cell: Cell = this.rows[cellPosition.rowIndex].cells[cellPosition.cellIndex];
      const productHelper: ProductHelper = this.getProductHelperIfProductExists(cell.cellIndex);
      const overwriteFloorCeilingForPaperColumn = this.checkIfColumnHasOnlyPaper(i);
      this.updateQuantityBrick(cellPosition.rowIndex, cellPosition.cellIndex, brickData.bricid, overwriteFloorCeilingForPaperColumn, true);
      // Multidrop - volume/Budget : Respect product validation.
      if (productHelper && Object.keys(productHelper).length && productHelper.validation) {
        const productValidations = this.getProductValidations(cell.cellIndex, productHelper, brickData.bricid);
        if (productValidations && brickData.bricid === this.brickBaseService.brickID.Budget) {
          if (cell && cell.selected && Object.keys(cell.selected).length) {
            if (productValidations.price) {
              cell.selected.price = productValidations.price;
            }
            if (productValidations.minPrice) {
              cell.selected.price = cell.selected.price < productValidations.minPrice ? productValidations.minPrice : cell.selected.price;
            }
            if (productValidations.maxPrice) {
              cell.selected.price = cell.selected.price > productValidations.maxPrice ? productValidations.maxPrice : cell.selected.price;
            }
            cell.selected.allocateAllFrames = productValidations.allocateAllFrames;
          }
        } else if (productValidations && brickData.bricid === this.brickBaseService.brickID.Volume) {
          if (cell && cell.selected && Object.keys(cell.selected).length) {
            if (productValidations.impressions) {
              cell.selected.impressions = productValidations.impressions;
            }
            if (productValidations.minImpressions) {
              cell.selected.impressions = cell.selected.impressions < productValidations.minImpressions ? productValidations.minImpressions : cell.selected.impressions;
            }
            if (productValidations.maxImpressions) {
              cell.selected.impressions = cell.selected.impressions > productValidations.maxImpressions ? productValidations.maxImpressions : cell.selected.impressions;
            }
          }
        }
        cell.selected.sotFloor = cell.selected.sotCeiling = null;
        if (productValidations && !_.isUndefined(productValidations.sotFloor)) {
          cell.selected.sotFloor = productValidations.sotFloor;
        }
        if (productValidations && !_.isUndefined(productValidations.sotCeiling)) {
          cell.selected.sotCeiling = productValidations.sotCeiling;
        }
        this.updateFloorAndCeilingValues(cell.cellIndex, overwriteFloorCeilingForPaperColumn);
        cell.requestJSON = this.cellAttributeService.getBrickRequestJSON(
          brickData.bricid,
          cell.selected
        );
      }
      if (!this.checkFtgIterationAllowed()) {
        this.generateSOTBrickRequestJSON();
        this.generateBrickRequestJSON();
      }
      if (!this.workspaceService.isCellValidAgainstProductValidation(brickData.bricid, cell, productHelper)) {
        cell.createEmptyCell();
        insertedBricsCount -= 1;
      }
    }
    if (this.appHeaderService && this.appHeaderService.enabledPCM) {
      // multi drop functionality not available in PCM
      // this.filterProductBuild();
    } else if (this.appHeaderService && this.appHeaderService.objectiveMode) {
      //
    } else {
      if (insertedBricsCount > 0) {
        this.historyStackService.pushInHistoryStack(historyStackData);
        this.historyStackService.pushColumnLineNumberInHistoryStack(columnLineNumberHistoryStack);
        this.historyStackService.pushColumnHelperInHistoryStack(this.stateService.getColumnHelper());
        if (this.checkFtgIterationAllowed && brickData.bricid === this.brickBaseService.brickID.Volume) {
          this.postCellUpdates();
        } else {
          this.processBrics();
        }
      } else {
        this.workspaceService.removeBlankRows(this.rows);
      }
    }
  }
  /**
   * @description This methods return the product validations based on the cell index.
   * There are two different ways in which the product validations are maintained
   * For MultiColumn Product, all the column will have seperate validations
   * For Product that is Stretched due to explode will have validations at the start index of product.
   * @author Shreni Shah
   * @date 2020-08-14
   * @param {number} cellIndex
   * @param {ProductHelper} productHelper
   * @param {Brick} brickData
   * @returns
   * @memberof Filter
   */
  public getProductValidations(cellIndex: number, productHelper: ProductHelper, brickId: number) {
    const productBrickIndex = this.checkIfCellExistsInColumn(cellIndex, Number(this.brickBaseService.brickID.ProductCatalogue), true);
    let productValidations = null;
    // Case of multip columnProduct or single columnProduct
    if (productHelper.validation[cellIndex]) {
      productValidations = productHelper.validation[cellIndex][brickId];
    } else if (productHelper.validation[productBrickIndex]) { // Case of product stretched due to explode
      productValidations = productHelper.validation[productBrickIndex][brickId];
    }
    return productValidations;
  }

  /**
   * @description Check if row is available or not
   * @author Darshan Vachhani
   * @param {number} brickId
   * @returns {boolean}
   * @memberof Filter
   */
  isRowAvailable(brickId: number, colIndex?: number): boolean {
    return this.getExistingRowIndex(brickId, colIndex) !== -1;
  }

  /**
   * @description Add brics
   * @author Darshan Vachhani
   * @param {CellValues} result
   * @returns
   * @memberof Filter
   */
  addMultipleBric(result: CellValues) {
    if (result.brick.rowIndex === this.rows.length) {// Row not exists
      const row = new Row();
      row.createNewRow(this.rows.length, result, this.stateService.getColumnHelper(), this.appHeaderService.objectiveMode);
      this.rows.push(row);
    } else {
      if (result.brick.cellIndex === this.rows[result.brick.rowIndex].cells.length) {
        this.rows[result.brick.rowIndex].pushNewCell(result, result.brick.cellIndex);
      } else {
        this.rows[result.brick.rowIndex].cells[result.brick.cellIndex].assignValues(result.brick.rowIndex, result);
      }
    }
    if (this.appHeaderService && this.appHeaderService.enabledPCM) {
      this.stateService.setPCMObject('filter', this.stateService.clone(this));
    } else if (this.appHeaderService && this.appHeaderService.objectiveMode) {
      this.stateService.setObjectiveObject('filter', this.stateService.clone(this));
    } else {
      this.stateService.setWorkspaceObject('filter', this.stateService.clone(this));
    }

    this.reApplyIndex();
    this.createEmptyCells();
    return true;
  }

  /**
   * @description Used to re calculate the values of all the quantity brics of workspace
   * @memberof Filter
   */
  reRenderQuantityBrick() {
    this.rows.forEach((row) => {
      if (this.isQuantityBric(row.bric.bricid)) {
        row.cells.forEach((cell) => {
          if (!cell.isEmpty) {
            this.updateQuantityBrick(
              row.index,
              cell.cellIndex,
              row.bric.bricid,
              false
            );
          }
        });
      }
    });
  }

  /**
   * @description create requestjson, selectedvalues and display text for quantity brics
   * @author Amit Mahida
   * @param {number} rowIndex
   * @param {number} cellIndex
   * @param {number} brickId
   * @param {boolean} overwriteFloorCeilingForPaperColumn This will be true in case of paper colum during multidrop and when we change media Bric value to Paper
   * @param {boolean} [multiDropped=false]
   * @memberof Filter
   */
  updateQuantityBrick(rowIndex: number, cellIndex: number, brickId: number, overwriteFloorCeilingForPaperColumn: boolean, multiDropped = false) {
    // SM-8991
    if (this.rows && this.rows[rowIndex] && this.rows[rowIndex].cells && this.rows[rowIndex].cells[cellIndex]) {
      this.rows[rowIndex].cells[cellIndex].selected = this.getQuantityBricSelectedValue(rowIndex, cellIndex, brickId);
      // SM-4749, making behaviour same as angular 1
      if (brickId === this.brickBaseService.brickID.Volume) {
        const selected = _.clone(this.rows[rowIndex].cells[cellIndex].selected);
        this.rows[rowIndex].cells[cellIndex].selected.impressions = selected.impressions ? selected.impressions : selected.allocated;
        if (multiDropped) {
          this.rows[rowIndex].cells[cellIndex].selected.multiDropped = multiDropped;
        }
      }
      if (brickId === this.brickBaseService.brickID.Budget) {
        const selected = _.clone(this.rows[rowIndex].cells[cellIndex].selected);
        this.rows[rowIndex].cells[cellIndex].selected.price = selected.price ? selected.price : selected.allocated;
      }

      // fix for SM-4581 and SM-4512
      if (brickId === this.brickBaseService.brickID.SOT && this.rows[rowIndex].cells[cellIndex].requestJSON) {
        if (!(this.appHeaderService && (this.appHeaderService.enabledPCM || this.appHeaderService.objectiveMode))) {
          const colSum = this.sharedService.getColumnSummaries(this.stateService.columnSummary, cellIndex, (cellIndex + this.rows[rowIndex].cells[cellIndex].cellWidth));
          this.sharedService.setSOTMaxFramesSlotsObj(this.rows[rowIndex].cells[cellIndex].selected, colSum);
        }
      }
      this.updateFloorAndCeilingValues(cellIndex, overwriteFloorCeilingForPaperColumn);
      this.rows[rowIndex].cells[cellIndex].displayText = this.cellAttributeService.getDisplayText(
        brickId,
        this.rows[rowIndex].cells[cellIndex].selected
      );
      this.rows[rowIndex].cells[cellIndex].toolTipText = this.cellAttributeService.getToolTip(
        brickId,
        this.rows[rowIndex].cells[cellIndex].selected
      );
      this.rows[rowIndex].cells[cellIndex].requestJSON = this.cellAttributeService.getBrickRequestJSON(
        brickId,
        this.rows[rowIndex].cells[cellIndex].selected
      );
    }
  }

  /**
   * @description Returns selectedvalues for quantity brics
   * @author Darshan Vachhani, Shivani Patel
   * @param {*} cellValues
   * @param {*} brickId
   * @param {*} columnSummary
   * @returns
   * @memberof Filter
   */
  getQuantityBricSelectedValue(rowIndex: number, cellIndex: number, brickId: number) {
    const populateSelected: PopulateSelected = new PopulateSelected();
    const isCellExistsInColumn: boolean = this.checkIfCellExistsInColumn(cellIndex, brickId) !== -1;

    const selected = isCellExistsInColumn && this.rows[rowIndex].cells[cellIndex].selected
      ? this.rows[rowIndex].cells[cellIndex].selected
      : {};

    if (selected.hasOwnProperty('-99')) {
      return selected;
    }

    /**
     * @description Returns summation of all quantity values from columnSummary by key.
     * @param {string} key
     * @returns {number}
     */
    const getQuantityByKey = (key: string): number => {
      if (this.appHeaderService.objectiveMode) {
        cellIndex = this.stateService.getObjectiveObject('currentObjectiveData').brick.cellIndex;
      }

      const cellEndId: number = isCellExistsInColumn ? cellIndex + this.rows[rowIndex].cells[cellIndex].cellWidth : cellIndex + 1;
      let value = 0;

      for (let cellId: number = cellIndex; cellId < cellEndId; cellId++) {
        const cellColumnSummary: ColumnSummary = this.stateService.getColumnSummary(cellId.toString());
        if (cellColumnSummary) {
          value += cellColumnSummary[key];
        }
      }
      return value;
    };

    const objectiveMeasure = isCellExistsInColumn ? this.workspaceService.getObjectiveMeasuresOfCell(this.rows[rowIndex].cells[cellIndex], this.objectiveMeasuresData) : null;

    switch (brickId) {
      case this.brickBaseService.brickID.Frame:
        if (this.allocationEngines[cellIndex] === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_LS
          || this.allocationEngines[cellIndex] === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH
          || this.appHeaderService.objectiveMode) {
          if (selected.greenTolerance === null || selected.greenTolerance === undefined) {
            selected.greenTolerance = this.initialConfig.uiControl.greenToleranceDefault || null;
          }
          if (selected.orangeTolerance === null || selected.orangeTolerance === undefined) {
            selected.orangeTolerance = this.initialConfig.uiControl.orangeToleranceDefault || null;
          }
        }
        const frameObj = {
          ...selected,
          allocated: getQuantityByKey('numberFrames'),
          frameCount: selected.frameCount || getQuantityByKey('numberFrames'),
          illumination: selected.illumination || null,
          tolerance: selected.tolerance || 0
        };
        if (this.appHeaderService.objectiveMode && objectiveMeasure) {
          frameObj.allocated = objectiveMeasure[this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Frame]].allocatedFrameCount;
        }
        return populateSelected.getFrameSelectedValue(frameObj);
      case this.brickBaseService.brickID.Volume:
        let productValidations = null;
        if (this.productDetails && this.productDetails.length) {
          const productHelper = this.getProductHelperIfProductExists(cellIndex);
          if (productHelper && productHelper.validation) {
            productValidations = this.getProductValidations(cellIndex, productHelper, Number(this.brickBaseService.brickID.Volume));
          }
        }
        const volumeObj = {
          ...selected,
          allocated: getQuantityByKey('audienceImpressions'),
          // commented for SM-4749
          // impressions: selected.impressions || getQuantityByKey('audienceImpressions'),
          impressions: selected.impressions,
          actualFtgTarget: selected.actualFtgTarget,
          actualFtgAllocated: this.checkFtgIterationAllowed() ? selected.actualFtgAllocated : undefined,
          sotFloor: isNumber(selected.sotFloor) ? selected.sotFloor : null,
          sotCeiling: isNumber(selected.sotCeiling) ? selected.sotCeiling : null,
          tolerance: selected.tolerance || this.initialConfig.uiControl.defaultTolerance,
          allocateAllFrames: _.isUndefined(selected.allocateAllFrames)
            ? this.initialConfig.uiControl.hasOwnProperty('defaultAllocateAllFrames')
              ? this.initialConfig.uiControl.defaultAllocateAllFrames
              : true
            : selected.allocateAllFrames
        };
        if (this.appHeaderService.objectiveMode && objectiveMeasure) {
          volumeObj.allocated = objectiveMeasure[this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Volume]].allocatedImpressions;
        }

        if (productValidations && productValidations.allocateAllFrames) {
          volumeObj.allocateAllFrames = productValidations.allocateAllFrames;
        }
        return populateSelected.getSelectionVolumeSelectedValue(volumeObj);
      case this.brickBaseService.brickID.Budget:
        const priceObj = {
          ...selected,
          allocated: parseFloat(getQuantityByKey('price').toFixed(2)),
          // price: selected.price || parseFloat(getQuantityByKey('price').toFixed(2)),
          price: selected.price,
          sotFloor: isNumber(selected.sotFloor) ? selected.sotFloor : null,
          sotCeiling: isNumber(selected.sotCeiling) ? selected.sotCeiling : null,
          tolerance: selected.tolerance || this.initialConfig.uiControl.defaultTolerance,
          allocateAllFrames: _.isUndefined(selected.allocateAllFrames)
            ? this.initialConfig.uiControl.hasOwnProperty('defaultAllocateAllFrames')
              ? this.initialConfig.uiControl.defaultAllocateAllFrames
              : true
            : selected.allocateAllFrames
        };

        if (this.appHeaderService.objectiveMode && objectiveMeasure) {
          priceObj.allocated = objectiveMeasure[this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Budget]].allocatedPrice;
        }
        return priceObj;
      case this.brickBaseService.brickID.SOT:
        if (this.appHeaderService && this.appHeaderService.enabledPCM) {
          return selected;
        }
        let shareOfTime: SOTBricSelectedDataModel = {
          sot: 'Mixed',
          sotType: '0',
          framesSlots: 1,
        };
        shareOfTime = { ...shareOfTime, ...selected };
        if (this.rows[rowIndex]) {
          const colSum = this.sharedService.getColumnSummaries(this.stateService.columnSummary, cellIndex, (cellIndex + this.rows[rowIndex].cells[cellIndex].cellWidth));
          this.sharedService.setSOTMaxFramesSlotsObj(shareOfTime, colSum);
        } else {
          const columnSummary: ColumnSummary = this.stateService.getColumnSummary(cellIndex.toString());
          this.sharedService.setSOTMaxFramesSlotsObj(shareOfTime, [columnSummary]);
        }
        const columnSummary: ColumnSummary = this.stateService.getColumnSummary(cellIndex.toString());
        let sotValue: any;
        if (columnSummary && (Array.isArray(columnSummary.sot) && columnSummary.sot.length === 1 && isNumber(columnSummary.sot[0]))) {
          sotValue = columnSummary.sot;
        } else if (columnSummary && selected.sot !== 'Mixed') {
          sotValue = columnSummary.sot;
        } else {
          sotValue = [selected.sot || 'Mixed'];
        }
        if (sotValue && sotValue.length > 0) {
          if (sotValue.length === 1) {
            shareOfTime.sot = sotValue[0];

            // SM-2818, Nishit, multidrop and load campaign re run case
            if (this.initialConfig.uiControl.sotFrequencyEnabled && _.isEmpty(selected)) {
              // here 1 is default frequency
              shareOfTime.dummySot = sotValue[0];
              shareOfTime.commercialSOT = (sotValue[0] * 1);
            }
          } else if (sotValue.length > 1) {
            shareOfTime.sot = 'Mixed';
          }
        }
        return shareOfTime;
      default:
        return;
    }
  }

  /**
   * @description locks column on given column index
   * @author Amit Mahida
   * @param {number} colIndex
   * @param {boolean} lock
   * @memberof Filter
   */
  public lockColumn(colIndex: number) {
    // Break stretched cells
    this.columnLock.breakLockedColumn(colIndex, this.rows);

    // Rerender values of quantity bric after splitting the stretched cells.
    this.reRenderQuantityBrick();

    // Generate brick request json after splitting the stretched cells.
    this.generateBrickRequestJSON();
    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
    if (columnHelper && !columnHelper[colIndex]) {
      columnHelper[colIndex] = {
        systemLocked: false,
        userLocked: true,
        shown: true,
        allocationEngine: ''
      };
    } else if (columnHelper && columnHelper[colIndex] && !columnHelper[colIndex].systemLocked) {
      delete columnHelper[colIndex];
    }

    this.columnLock.lockColumn(colIndex, columnHelper, this.rows);
    this.stateService.setColumnHelper(columnHelper);
  }

  /**
   * @description toggles all lock
   * @author Amit Mahida
   * @param {boolean} lock
   * @memberof Filter
   */
  public toggleAllColumnsLock(lock: boolean) {
    this.columnLock.toggleAllColumnsLock(lock, this.rows);
  }

  /**
   * @description returns true if all columns locked
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof Filter
   */
  isAllColumnsLocked(): boolean {
    return this.columnLock.isAllColumnsLocked(this);
  }

  /**
   * @description updates state of frozen columns
   * @author Amit Mahida
   * @memberof Filter
   */
  updateWorkspaceOnLock() {
    this.columnLock.updateWorkspaceOnLock(this);
  }

  /**
   * @description Manages index of lock buttons in column Helper
   * @author Amit Mahida
   * @param {number} colIndex added/deleted col index on left of locked cols
   * @param {boolean} [added=true] set to false if column is deleted
   * @memberof Filter
   */
  manageColumnHelper(colIndex: number, added = true) {
    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
    if (added) {
      const allIndex = Object.keys(columnHelper);
      for (let i = Number(allIndex[allIndex.length - 1]); i >= colIndex; i--) {
        if (columnHelper && columnHelper[i]) {
          columnHelper[i + 1] = columnHelper[i];
          delete columnHelper[i]; // Moving previous to the next
        }
      }
    } else {
      for (const key in columnHelper) {
        if (columnHelper.hasOwnProperty(key)) {
          if (Number(key) > colIndex) { // Moving next to the previous
            columnHelper[Number(key) - 1] = columnHelper[key];
            delete columnHelper[key];
          }
        }
      }
    }
    this.lockAllButton = this.isAllColumnsLocked();
    this.stateService.setColumnHelper(columnHelper);
  }

  /**
   * @description generates column summary tooltip array from column summary object
   * @author Amit Mahida
   * @param {{ [key: string]: ColumnSummary }} columnSummary
   * @returns
   * @memberof Filter
   */
  generateColumnSummary(columnSummary: { [key: string]: ColumnSummary }) {
    const columnSummarys: ColumnSummaryTooltip[] = [];
    if (columnSummary && Object.keys(columnSummary).length > 0) {
      for (const key in columnSummary) {
        if (columnSummary.hasOwnProperty(key)) {
          const object = columnSummary[key];
          const temp: ColumnSummaryTooltip = new ColumnSummaryTooltip();
          if (object.priceDisplay) {
            const price = {
              value: object.priceDisplay,
              imagePath: 'images/currency.png'
            };
            temp.dBudgetSummary = price;
          }
          if (object.audienceRatingDisplay) {
            const audienceRating = {
              value: object.audienceRatingDisplay,
              imagePath: 'images/audiance.png'
            };
            temp.bRatingSummary = audienceRating;
          }
          if (object.audienceImpressionsDisplay) {
            const audienceImpressions = {
              value: object.audienceImpressionsDisplay,
              imagePath: 'images/impressions.png'
            };
            temp.cImpressionsSummary = audienceImpressions;
          }
          if (object.startDate && object.endDate) {
            const incharge = {
              value: `${this.datePipe.transform(object.startDate, LocaleData.displayDateFormat)} -
              ${this.datePipe.transform(object.endDate, LocaleData.displayDateFormat)}`,
              imagePath: 'images/calendar.png',
            };
            temp.aInchargeSummary = incharge;
          }
          if (object.numberFramesDisplay) {
            const frame = {
              value: object.numberFramesDisplay,
              imagePath: 'images/frames.png',
            };
            temp.eFrameSummary = frame;
          }
          if (object.averageQocDisplay) {
            const averageQoc = {
              value: object.averageQocDisplay,
              imagePath: 'images/AverageQoc.png',
            };
            temp.fAverageQocSummary = averageQoc;
          } else {
            delete temp.fAverageQocSummary;
          }
          columnSummarys[key] = temp;
        }
      }
    }

    for (let index = 0; index < columnSummarys.length; index++) {
      const columnSummary = columnSummarys[index];
      if (typeof columnSummary === 'undefined') {
        columnSummarys[index] = new ColumnSummaryTooltip();
        columnSummarys[index].isHidden = true;
      }
    }
    return columnSummarys;
  }

  /**
   * @description Populate network structure
   * @author Darshan Vachhani
   * @param {CellValues} netWorkBrickData
   * @memberof Filter
   */
  addNetworkData(netWorkBrickData: CellValues) {

    let makeServerCall = true;

    if (this.appHeaderService && this.appHeaderService.enabledPCM) {
      this.historyStackService.pushInHistoryStackPCM(new HistoryStackData(this));
      this.stateService.setPCMObject('filter', this.stateService.clone(this));
      if (netWorkBrickData.selectedValues && (Object.keys(netWorkBrickData.selectedValues).length === 0 || netWorkBrickData.selectedValues.hasOwnProperty('-99'))) {
        makeServerCall = false;
      }
    } else {
      this.historyStackService.pushInHistoryStack(new HistoryStackData(this));
      this.historyStackService.pushColumnHelperInHistoryStack(this.stateService.getColumnHelper());
      this.stateService.setWorkspaceObject('filter', this.stateService.clone(this));
    }

    // Find existing workspace object range brics index
    const selectedValues = Object.assign([], netWorkBrickData.selectedValues.bricsData);
    const loadWorkspace: LoadWorkspace = new LoadWorkspace(this.brickBaseService, this.cellAttributeService, this.sharedService, this.stateService);
    const loadedColumnConfig = this.stateService.getWorkspaceObject('columnConfig');
    const loadedColumnSummary = this.stateService.getAllColumnSummary();
    const netWorkFilterObj =
      loadWorkspace.getWorkspaceFilter(
        this.stateService.getFreshFilterObj(),
        selectedValues, this.initialConfig,
        this.dataShareService.getBricsMasterData(), loadedColumnConfig, loadedColumnSummary,
        null, null, null);
    const cloneNetworkRows = _.cloneDeep(netWorkFilterObj.rows);

    // rows will have only those cells which are not part of selected network
    this.rows = this.rows.filter((row) => {
      return cloneNetworkRows.findIndex((item) => {
        if (row.cells && row.cells.length > 0) {
          return item.bric.bricid === row.bric.bricid;
        }
      }) === -1;
    });

    this.autoSuppress(); // This will remove any extra columns from remaining rows

    // Stretch first cell of all remainig rows to the max length of network
    const nwMaxCellIndex = cloneNetworkRows[0].cells.length;
    const wsMaxCellIndex = this.getMaxCellIndex() + 1;

    this.rows.forEach((r) => {
      if (nwMaxCellIndex > wsMaxCellIndex) {
        // Stretching existing bricks
        for (let i = 1; i <= nwMaxCellIndex - r.cells[0].cellWidth; i++) {
          const cell: Cell = new Cell();
          cell.createEmptyCell(true);
          cell.cellIndex = i;
          r.cells.splice(i, 0, cell);
        }
        r.cells[0].cellWidth = nwMaxCellIndex;
      } else {
        // Collapsing existing bricks
        if (r.cells[0].cellWidth > 1) {
          for (let i = r.cells[0].cellWidth - 1; i >= wsMaxCellIndex; i--) {
            r.cells.splice(i, 1);
            r.cells[0].cellWidth -= 1;
          }
        } else if (r.cells.length > wsMaxCellIndex) {
          const curLength = r.cells.length;
          for (let i = curLength - 1; i > curLength - wsMaxCellIndex - 1; i--) {
            r.cells.splice(i, 1);
          }
        }
      }
    });

    // Merging rows and network structure
    netWorkFilterObj.rows.forEach((filterRow) => {
      this.rows.push(filterRow);
    });

    // Push empty cells to maintain matrix
    this.createEmptyCells();

    // this.createEmptyCellToMaxCell();
    // Adjust first cell cellwidth
    // this.rows.forEach((row) => {
    //   const index = _.findIndex(cloneNetworkRows, (o) => { if (o) { return o.bric.bricid === row.bric.bricid; } });
    //   if (index === -1) {
    //     let availbleBric = 0;
    //     // check if only one bric available than give maximum cellwidth
    //     row.cells.forEach((cell) => {
    //       if (cell && cell.isEmpty && cell.isHidden) {
    //         availbleBric = availbleBric + 1;
    //       }
    //     });
    //     row.cells[0].cellWidth = availbleBric + 1;
    //   }
    // });
    // this.reApplyIndex();
    this.postCellUpdates(makeServerCall);
  }

  /**
   * @description Create empty cell upto max column index with hidden true and empty true
   * @author Darshan Vachhani
   * @memberof Filter
   */
  createEmptyCellToMaxCell() {
    const maxCellIndex = this.getMaxCellIndex();
    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
    this.rows.forEach((row) => {
      while ((row.cells.length - 1 < maxCellIndex) && columnHelper) {
        const cell = new Cell();
        cell.createEmptyCell(true, columnHelper[row.cells.length] ? true : false);
        row.cells.push(cell);
        cell.cellIndex = row.cells.length - 1;
      }
    });
  }

  /**
   * @Amit : No more used
   * Populate the resolveObject Product Edit Mode : PCM. These details
   * will be filled in the modal of Save Product
   * @param {*} data
   * @returns
   * @memberof Filter
   */
  // populateProductCatalogueData(data) {
  //   const productCatalogue: ProductCatalogue = new ProductCatalogue();
  //   productCatalogue.productCatalogueDescription = data['productCatalogueDescription'];
  //   productCatalogue.productCatalogueGroupId = data['productCatalogueGroupId'];
  //   productCatalogue.productCatalogueName = data['productCatalogueName'];
  //   productCatalogue.productCatalogueTypeId = data['productCatalogueTypeId'];
  //   productCatalogue.restrictions.advertisers = data['restrictions']['advertisers'];
  //   productCatalogue.restrictions.agencies = data['restrictions']['agencies'];
  //   productCatalogue.restrictions.specialists = data['restrictions']['specialists'];
  //   productCatalogue.idProductCatalogue = data['idProductCatalogue'];
  //   productCatalogue.inUse = data['inUse'];
  //   return productCatalogue;
  // }

  loadProduct(productData: CellValues) {
    const params = {
      idProductCatalogue: productData.selectedValues['idProductCatalogue'],
      action: 'getProductCatalogue'
    };
    const serviceUrl = this.dataShareService.getServiceCallUrlByKey('PCM_GET_PRODUCT');
    this.workspaceService.getProduct(params, serviceUrl).subscribe((res) => {
      if (res['status'] === 'OK') {
        // const rowBeforeChange = _.cloneDeep(this.rows);
        // check if audience bric is already present on worksapce
        const audienceOnWorkspaceReadyForFurtherChecks: Row[] = _.cloneDeep(this.rows.filter((e) => {
          if (this.brickBaseService.singleAudienceBrics.indexOf(e.bric.bricid) > -1) {
            if (e.cells[0].cellWidth > 1) { // Stretched + Locked Case
              return true;
            } else {
              this.rows.forEach((row) => { // Single Column + Product
                if (row.bric.bricid === this.brickBaseService.brickID.ProductCatalogue) {
                  return true;
                }
              });
            }
          }
        }
        ));

        const bricsData = res['data']['bricsData'];
        const loadWorkspace: LoadWorkspace = new LoadWorkspace(this.brickBaseService, this.cellAttributeService, this.sharedService, this.stateService);
        const isLinkedWithChannel = this.workspaceService.isProductCatalogLinkedWithChannel(bricsData, this.rows);
        if (!isLinkedWithChannel) {
          if (this.appHeaderService && this.appHeaderService.enabledPCM) {
            this.rows = _.cloneDeep(this.historyStackService.popFromHistoryStackPCM(this.stateService.getPCMFilterObj()).rows);
          } else {
            this.rows = _.cloneDeep(this.historyStackService.popFromHistoryStack(this.stateService.getWorkspaceFilterObj()).rows);
            this.historyStackService.popColumnHelperInHistoryStack();
          }
          this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.product.environment'] || 'Selected Channel is not matched with linked channel');
          return;
        }

        if (!this.appHeaderService.enabledPCM && !this.appHeaderService.objectiveMode) {
          const allowReductionBrickSpan = this.workspaceService.isAllowReductionBrickSpan(bricsData);
          if (!allowReductionBrickSpan) {
            this.rows = _.cloneDeep(this.historyStackService.popFromHistoryStack(this.stateService.getWorkspaceFilterObj()).rows);
            this.historyStackService.popColumnHelperInHistoryStack();
            this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.product.reductionBrickSpan'] || 'Reduction bricks are not allowed to be stretched across multiple columns in this workspace');
            return;
          }
        }

        const loadedColumnConfig = this.stateService.getWorkspaceObject('columnConfig');
        const loadedColumnSummary = this.stateService.getAllColumnSummary();
        this.createEmptyCells();
        const workspaceFilter = loadWorkspace.getWorkspaceFilter(
          this.stateService.getFreshFilterObj(), bricsData, this.initialConfig,
          this.dataShareService.getBricsMasterData(), loadedColumnConfig, loadedColumnSummary,
          res['data'].lookupData, true, null);
        const productRows = workspaceFilter.rows;
        const mediaRow = productRows.find(row => row.bric.bricid === this.brickBaseService.brickID.Media);
        let productCellIndex = productData.brick.cellIndex;
        if (mediaRow && mediaRow.cells) {
          for (const cell of mediaRow.cells) {
            let finalCell = cell;
            const index = mediaRow.cells.indexOf(cell);
            const strechedIndex = mediaRow.getOverlappingCell(index);
            if (strechedIndex !== -1) {
              finalCell = mediaRow.cells[strechedIndex];
            }
            if (finalCell.selected && finalCell.selected.selectedMediaObject && finalCell.selected.selectedMediaObject.defaultAllocationEngine) {
              this.allocationEngines.splice(productCellIndex, 0, finalCell.selected.selectedMediaObject.defaultAllocationEngine);
              productCellIndex += 1;
            }
          }
        }
        if (this.appHeaderService && this.appHeaderService.enabledPCM) {
          this.stateService.setPCMObject('productToDisable', res.data);
          this.rows = _.cloneDeep(productRows);
          this.stateService.setPCMObject('filter', this.stateService.clone(this));
          this.productCatalogue = { ...res['data'] };
          this.productEditMode = true;
          this.generateBrickRequestJSON();
          this.filterProductBuild();
        } else {
          const columnLineNumber = this.stateService.getWorkspaceObject('columnLineNumber');

          this.workspaceService.populateProductBric(productRows, this.rows, productData.brick.cellIndex, productData.brick.rowIndex).then((existingFilter: Row[]) => {
            // SM-4865 VJ: We are removing blank rows in populateProductBric but
            // not setting indexes again so few times console error logged in below methods
            this.reApplyIndex();
            // note: promise will return data
            // in angular1 resize of Audience Bric was handelled in autoSupress

            // Managing ColumnLineNumber
            // VJ : Based on discussion with Abhay push index when new product is dropped for SM-8147
            const columnLineArrayKeys = Object.keys(columnLineNumber);
            if (columnLineArrayKeys.length && !columnLineNumber[productData.brick.cellIndex]) {
              const columnLineArray = columnLineArrayKeys.map((columnIndex) => { return columnLineNumber[columnIndex]; });
              // Get Max Column Line Number
              const currentMaxIndex = Math.max(...columnLineArray) || 0;
              // This will increase all subsequent column line numbers

              for (let colIndex = columnLineArrayKeys.length - 1; colIndex >= 0; colIndex--) {
                const finalKey: number = parseInt(columnLineArrayKeys[colIndex], 10);
                if (columnLineNumber.hasOwnProperty(finalKey) && finalKey > productData.brick.cellIndex) {
                  const value = columnLineNumber[finalKey];
                  delete columnLineNumber[finalKey];
                  columnLineNumber[finalKey + 1] = value;
                }
              }

              for (let colIndex = productData.brick.cellIndex; colIndex < productData.brick.cellIndex + res.data.bricsData[0].columns.length; colIndex++) {
                columnLineNumber[colIndex] = currentMaxIndex + 1;
              }

              this.stateService.setWorkspaceObject('columnLineNumber', columnLineNumber);
            }

            const cloneProductDtails: ProductHelper[] = _.cloneDeep(this.productDetails);
            this.productDetails = this.workspaceService.setProductData(productRows, existingFilter,
              this.productDetails, productData.selectedValues['idProductCatalogue'], productData.brick.cellIndex);

            // Route/multi bric present on worksapce, try to add product which does not have route/multi bric
            if (audienceOnWorkspaceReadyForFurtherChecks.length === 1) {

              let isAllSelectionAudienceAllowed = false;
              const isLockedColsPresent = this.isLockedColsPresent();
              /* Check if Product is having the All Selection BRICK similar to the one placed on the WorkSpace(Same BRIC ID's).*/
              this.productDetails.forEach((e) => {
                if (!isAllSelectionAudienceAllowed) {
                  Object.keys(e.optional).forEach((i) => {
                    // added !isLockedColsPresent for case mentioned in SM-6035
                    isAllSelectionAudienceAllowed = e.optional[i].indexOf(audienceOnWorkspaceReadyForFurtherChecks[0].bric.bricid) > -1 && !isLockedColsPresent ? true : false;
                  });
                }
              });
              let isAudiecePresentInProduct = false;
              if (!isAllSelectionAudienceAllowed) {
                // Check for Mandatory Audience BRICS with the same values. //
                const isSpecificAudienceAllowed = productRows.some((bric) => {
                  if (this.brickBaseService.singleAudienceBrics.indexOf(bric.bric.bricid) > -1) {
                    isAudiecePresentInProduct = true;
                    if (bric.bric.bricid === audienceOnWorkspaceReadyForFurtherChecks[0].bric.bricid) {
                      const requestJSONText = this.brickBaseService.brickReqJsonText[bric.bric.bricid];
                      return (JSON.stringify(bric.cells[0].requestJSON[requestJSONText]) === JSON.stringify(audienceOnWorkspaceReadyForFurtherChecks[0].cells[0].requestJSON[requestJSONText]));
                    }
                  }
                });
                // no change in logic, just added isLockedColsPresent variable to check for locked columns
                if ((isSpecificAudienceAllowed === false && isAudiecePresentInProduct) || isLockedColsPresent) {
                  this.rows = [];
                  this.productDetails = cloneProductDtails;
                  this.rows = _.cloneDeep(this.historyStackService.popFromHistoryStack(this.stateService.getFreshFilterObj()).rows); // to handle SM-5994 and SM-6035
                  this.autoSuppress();
                  if (isLockedColsPresent) {
                    this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.product.audience_locked'] || 'Product brick is not allowed to drop if the column is either locked or in past campaign');
                  } else {
                    this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.product.audience'] || 'Product brick is not allowed to drop due to mismatch in Audience Data');
                  }
                  return;
                }
              }
            }

            const productBRIC = this.rows.filter(row => row.bric.bricid === this.brickBaseService.brickID.ProductCatalogue)[0].cells[productData.brick.cellIndex];
            const cellWidth = this.rows[productBRIC.rowIndex].cells[productBRIC.cellIndex].cellWidth - 1;

            for (let colIndex = 0; colIndex < productBRIC.cellWidth; colIndex++) {
              if (this.deletedColIndex.indexOf(productBRIC.cellIndex + colIndex) > -1) {
                this.deletedColIndex.splice(this.deletedColIndex.indexOf(productBRIC.cellIndex + colIndex), 1);
              }
            }
            if (this.rows[productBRIC.rowIndex].cells[productBRIC.cellIndex].cellWidth > 1) {
              for (let index = productBRIC.cellIndex; index < (productBRIC.cellIndex + cellWidth); index++) {
                this.manageColumnHelper(index, true);
              }
            }
            this.stateService.setWorkspaceObject('filter', this.stateService.clone(this));
            this.stateService.setWorkspaceObject('productDetails', this.productDetails);
            // dont change sequence of below methods, SM-4045
            this.manageAudienceBrickSize();
            // Changes for SM-4581 and SM-4512
            this.reRenderQuantityBrick();
            this.postCellUpdates();
          }).catch(() => {
            this.logHelperService.logError(this.initialConfig.userBundle['workspace.pcm.add.failer']);
          });
        }

      } else {
        this.logHelperService.logError(res['message']);
      }
    });
  }

  /**
   * @description Find index of existing row
   * @author Darshan Vachhani
   * @param {*} brickId
   * @returns {number}
   * @memberof Filter
   */
  getExistingRowIndex(brickId: number, colIndex?: number): number {
    return this.rows.findIndex((row) => {
      if (row.cells && row.cells.length > 0) {
        return row.bric && brickId === row.bric.bricid;
      }
    });
  }

  /**
   * @description Removes the single cell of the workspace (used for Wiper)
   * @author Shivani Patel
   * @param {number} rowIndex
   * @param {number} cellIndex
   * @memberof Filter
   */
  removeBricForClearWorkspace(rowIndex: number, cellIndex: number) {
    // If current cell is last cell of the row, then remove the row else remove the cell
    const deleteRow = this.rows[rowIndex].cells.length === 1;
    if (deleteRow && !this.rows[rowIndex].cells[cellIndex].isEmpty) {
      this.rows.splice(rowIndex, 1);
    } else {
      // If current cell is streched, Decrement the width of the cell from which current cell is streched.
      const ovelappingCellIndex = this.rows[rowIndex].getOverlappingCell(cellIndex);
      if (ovelappingCellIndex !== -1) {
        this.rows[rowIndex].cells[ovelappingCellIndex].cellWidth--;
        this.rows[rowIndex].removeCell(ovelappingCellIndex + 1);
      } else {
        if (this.rows[rowIndex].cells[cellIndex].cellWidth > 1) {
          this.rows[rowIndex].cells[cellIndex].cellWidth--;
          this.rows[rowIndex].removeCell(cellIndex + 1);
        } else {
          this.rows[rowIndex].removeCell(cellIndex);
        }
      }
    }
  }

  /**
   * @description To clear workspace area
   * @author Shivani Patel, Amit Mahida
   * @param {ColumnHelper} columnHelper
   * @returns
   * @memberof Filter
   */
  clearWorkspace(columnHelper: ColumnHelper, historyRequired = true) {
    const deletedIndex = [];
    // If there is no brick available to remove then show info
    if (!this.rows.length) {
      this.logHelperService.logInfo(this.initialConfig.userBundle['workspace.info.bricsNotAvailabeToClear']);
      if (this.appHeaderService && !this.appHeaderService.enabledPCM && !this.appHeaderService.objectiveMode) {
        SystemFlags.incorrectSolution = true;
      }
      return;
    }

    /**
     * Clone current workspace i.e. filter object and
     * if any change in workspace after cleaning then later store it to the HistoryStack
     */
    const historyStackData = new HistoryStackData(this);
    const historyStackColumnLineNumber = this.stateService.getWorkspaceObject('columnLineNumber');

    // In PCM Mode, clear the necessary Flags
    if (this.appHeaderService && this.appHeaderService.enabledPCM) {
      // Clears the ProductCatalogue which is filled while editting the product in PCM Mode//
      this.productCatalogue = new ProductCatalogue();
      // Reset the Product Edit Mode
      this.productEditMode = false;

      // Reset the workspace
      this.rows = [];

      // Push data to historystack and set new filter object of PCM in state Service.
      if (historyRequired) {
        this.historyStackService.pushInHistoryStackPCM(historyStackData);
      } else {
        this.historyStackService.resetHistoryStackForPCM();
      }
      this.stateService.setPCMObject('filter', this.stateService.getFreshFilterObj());
    } else if (this.appHeaderService && this.appHeaderService.objectiveMode) {
      // Reset the workspace
      this.rows = [];

      // Push data to historystack and set new filter object of PCM in state Service.
      this.historyStackService.pushInHistoryStackObjective(historyStackData);
      this.stateService.setObjectiveObject('filter', this.stateService.getFreshFilterObj());
    } else {
      // If past column is hidden then show error
      if (this.isPastColumnHidden) {
        this.logHelperService.logError(this.initialConfig.userBundle['worksapce.error.hidden']);
        return;
      }

      // Set to true if any Brick or Row has been clean from Workspace.
      let callAutoSupress = false;
      const deletedCols = {};

      for (let rowIndex = this.rows.length - 1; rowIndex >= 0; rowIndex--) {
        if (this.rows[rowIndex]) {
          const totalColumns = this.rows.length ? this.rows[0].cells.length : 0;
          for (let cellIndex = totalColumns - 1; cellIndex >= 0; cellIndex--) {
            const isLocked = columnHelper && columnHelper[cellIndex];
            const isNonEditableCol = columnHelper && columnHelper[cellIndex] && columnHelper[cellIndex].systemLocked ? true : false;
            // It should not clear the columns which are locked or non-editable
            if (isLocked || isNonEditableCol) {
              continue;
            }
            if (this.rows[rowIndex].cells[cellIndex]) {
              this.removeBricForClearWorkspace(rowIndex, cellIndex);
              deletedCols[cellIndex] = true;
              callAutoSupress = true;
            }
          }
        }
      }

      /**
       * This block gets executed when we have some rows to remove from the workspace
       * (The first condition gets false as there are some rows to clean)
       * and
       * after removing that workspace gets blank (when there is no locked column)
       * So, in this case, we have to clean column and campaign summary
       */
      if (!this.rows.length) {
        // Clear the necessary Flags
        this.productDetails = [];
        this.stateService.setColumnHelper({});
        this.stateService.setWorkspaceObject('columnLineNumber', {});
        this.lockAllButton = false;
        this.brickRequestJSON = [];
        this.columnSummaryWithoutBrick = [];
        this.reshuffle.reshufflingColumns = [];

        // Push data to historystack and set new filter object of PCM in state Service.
        this.historyStackService.pushInHistoryStack(historyStackData);
        this.historyStackService.pushColumnLineNumberInHistoryStack(historyStackColumnLineNumber);
        this.historyStackService.pushColumnHelperInHistoryStack(this.stateService.getColumnHelper());

        this.stateService.setWorkspaceObject('filter', this.stateService.clone(this));
        this.stateService.setWorkspaceObject('productDetails', this.productDetails);

        this.setWorkspaceValidity(false);
      }

      /**
       * If there are locked columns and any Brick after the locked column has cleaned from the workspace
       */
      if (this.rows.length && callAutoSupress) {
        for (const deletedCol of Object.keys(deletedCols)) {
          this.deletedColIndex.push(Number(deletedCol));
          deletedIndex.push(Number(deletedCol));
        }
        if (deletedIndex.length > 0) {
          for (let index = deletedIndex.length - 1; index >= 0; index--) {
            const colIndex = deletedIndex[index];
            this.decrementColumnLineNumber(colIndex);
          }
        }
        this.historyStackService.pushColumnHelperInHistoryStack(this.stateService.getColumnHelper());
        this.autoSuppress();
        if (deletedIndex.length > 0) {
          for (let index = deletedIndex.length - 1; index >= 0; index--) {
            const colIndex = deletedIndex[index];
            this.manageColumnHelper(colIndex, false);
            this.allocationEngines.splice(colIndex, 1);
          }
        }
        if (!(this.appHeaderService && (this.appHeaderService.enabledPCM || this.appHeaderService.objectiveMode)) && !callAutoSupress) {
          this.reIndexingProductDetails(deletedIndex);
        }
        this.generateBrickRequestJSON();

        this.stateService.setWorkspaceObject('filter', this.stateService.clone(this));

        this.processBrics();

        // Push data to historystack and set new filter object of PCM in state Service.
        this.historyStackService.pushInHistoryStack(historyStackData);
        this.historyStackService.pushColumnLineNumberInHistoryStack(historyStackColumnLineNumber);

      }
      if (!SystemFlags.isFillerCampaign) {
        this.displayBricksInLeftPanel();
      }
    }
  }

  /**
   * @description this fn will return count of brics selected for multi delete
   * @author Nishit Parekh
   * @returns {number}
   * @memberof Filter
   */
  public getMarkSelectedCount(): number {
    this.markSelectedCount = 0;
    this.rows.forEach((row: Row) => {
      row.cells.forEach(cell => cell.markSelected ? ++this.markSelectedCount : false);
    });
    return this.markSelectedCount;
  }

  /**
   * @description This will set markSelected property for cell to false
   * @author Nishit Parekh
   * @memberof Filter
   */
  removeMarkSelected(): void {
    this.rows.forEach((row) => {
      row.cells.forEach((cell: Cell) => {
        cell.markSelected = false;
      });
    });
  }

  markWorkspaceInvalid() {
    this.stateService.setColumnSummary({});

    // SM-3927: Commented below line as we do not need to clear the Loaded Campaign Summary if the workspace is Invalid
    // this.stateService.setLoadedCampaignSummary([]);
    this.stateService.updateWorkspaceValidity(!SystemFlags.incorrectSolution);
    SystemFlags.incorrectSolution = true;
    this.dataShareService.activateResultTab(false);
    this.dataShareService.activateCommercialTab(false);
  }

  /**
   * @description This method will detect position where new cell to be created
   * @author Nishit Parekh
   * @param {*} data dates array, it will create brics same as length of array and last date
   * @param {CellValues} result object with required cell details
   * @memberof Filter
   */
  addMultipleRangeBric(data: { bricksToPush: DateRange[], bricToReplace: DateRange[] }, result: CellValues) {
    const addBricsToWorkspace = (pushCellIndex: number, items: DateRange[]) => {
      const cloneResult = _.cloneDeep(result);
      cloneResult.selectedValues.startDate = '';
      cloneResult.selectedValues.endDate = '';
      cloneResult.displayText = '';
      cloneResult.requestJson = '';

      for (let index = 0; index < items.length; index++) {
        const element = _.cloneDeep(cloneResult);
        element.brick.cellIndex = pushCellIndex + index;
        element.selectedValues.startDate = items[index].startDate;
        element.selectedValues.endDate = items[index].endDate;
        element.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Incharge, element.selectedValues);
        element.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Incharge, element.selectedValues);
        this.addBrick(element, false);
      }
    };

    if (this.appHeaderService && this.appHeaderService.enabledPCM) {
      this.historyStackService.pushInHistoryStackPCM(new HistoryStackData(this));
    } else if (this.appHeaderService && this.appHeaderService.objectiveMode) {
      this.historyStackService.pushInHistoryStackObjective(new HistoryStackData(this));
    } else {
      this.historyStackService.pushInHistoryStack(new HistoryStackData(this));
      this.historyStackService.pushColumnLineNumberInHistoryStack(this.stateService.getWorkspaceObject('columnLineNumber'));
      this.historyStackService.pushColumnHelperInHistoryStack(this.stateService.getColumnHelper());
    }

    // Alkesh Shah
    // Case: when there 2columns of media and 1 column of environment and only one column of incharge, dropping range - deletes environment row
    // it should not delete environment row, should stretch environment
    const inchargeRow = this.getRowByBrickId(Number(this.brickBaseService.brickID.Incharge));
    let lastInchargeEmptyCell = 0;
    if (inchargeRow) {
      lastInchargeEmptyCell = inchargeRow.lastEmptyCellIndex();
    }
    // Add new brics at last
    addBricsToWorkspace(lastInchargeEmptyCell, data.bricksToPush);

    // Replace current edited bric
    addBricsToWorkspace(result.brick.cellIndex, data.bricToReplace);
  }

  /**
   * @description this fn will re index the productDetails array when any column/s is added or removed
   * @author Nishit Parekh, Amit Mahida
   * @memberof Filter
   */
  reIndexingProductDetails(deletedColIndex?: number[]): void {
    const productRow = this.rows.find((row) => {
      return row.bric.bricid === this.brickBaseService.brickID.ProductCatalogue;
    });

    // check if productRow and productDetails exists if not, no need for re indexing
    if (!this.productDetails || this.productDetails.length === 0) {
      this.productDetails = this.stateService.getWorkspaceObject('productDetails');
    }
    if (productRow && this.productDetails.length > 0) {
      this.productDetails = _.sortBy(this.productDetails, 'columnIndex');
      if (deletedColIndex) {
        for (let productIndex = this.productDetails.length - 1; productIndex >= 0; productIndex--) {
          const productDetail = this.productDetails[productIndex];
          let productPresent = true;
          if (deletedColIndex.indexOf(productDetail.columnIndex) > -1) {
            productPresent = false;
          }
          if (productPresent) {
            for (const deletedIndex of deletedColIndex) {
              productPresent = deletedIndex !== (productDetail.columnIndex + productDetail.productWidth - 1);
              if (!productPresent) {
                break;
              }
            }
          }
          if (!productPresent) {
            this.productDetails.splice(productIndex, 1);
          }
        }
      }

      // change position of columns in productDetails when a product or colum is removed
      let productCount = 0;
      productRow.cells.forEach((product) => {
        if (!product.isEmpty && !product.isHidden) {
          const productDetails = this.productDetails.filter((productDetail) => {
            return product.selected.idProductCatalogue === productDetail.idProductCatalogue;
          });
          // case when there is only one entry in productDetails matching product id
          if (productDetails && productDetails.length === 1) {
            const productDetail = productDetails[0];

            if (productDetail && (productDetail.columnIndex !== product.cellIndex)) {
              const newValidation = {};
              const newOptional = {};
              for (let i = product.cellIndex, j = 0; i < product.cellWidth + product.cellIndex; i++, j++) {
                Object.keys(productDetail.validation).forEach((column, index) => {
                  if (index === j) {
                    newValidation[i] = productDetail.validation[column];
                    newOptional[i] = productDetail.optional[column];
                  }
                });
              }
              productDetail.columnIndex = product.cellIndex;
              productDetail.validation = newValidation;
              productDetail.optional = newOptional;
              Object.assign(this.productDetails.find(b => (b.idProductCatalogue === productDetail.idProductCatalogue)), productDetail);
            }
          } else if (productDetails.length > 1) {
            // case when there are more than one entry in productDetails matching same product id
            productDetails.forEach((ele) => {
              if (!(ele.columnIndex === product.cellIndex) && (ele.columnIndex === product['indexBeforeDelete'])) {
                const newValidation = {};
                const newOptional = {};

                for (let i = product.cellIndex, j = 0; i < product.cellWidth + product.cellIndex; i++, j++) {
                  Object.keys(ele.validation).forEach((column, index) => {
                    if (index === j) {
                      newValidation[i] = ele.validation[column];
                      newOptional[i] = ele.optional[column];
                    }
                  });
                }
                ele.columnIndex = product.cellIndex;
                ele.validation = newValidation;
                ele.optional = newOptional;
                Object.assign(this.productDetails.find(b => (b.columnIndex === ele.columnIndex)), ele);
              }
            });
          } else {
            console.log('productDetails should not be empty');
          }
          if (this.productDetails[productCount] && product.cellIndex !== this.productDetails[productCount].columnIndex) {
            const validations = this.productDetails[productCount].validation;
            let validationCount = 0;
            for (const key in validations) {
              if (validations.hasOwnProperty(key)) {
                validations[product.cellIndex + validationCount] = validations[key];
                delete validations[key];
                validationCount++;
              }
            }

            const optionalBrics = this.productDetails[productCount].optional;
            let optionalBricsCount = 0;
            for (const key in optionalBrics) {
              if (optionalBrics.hasOwnProperty(key)) {
                optionalBrics[product.cellIndex + optionalBricsCount] = optionalBrics[key];
                delete optionalBrics[key];
                optionalBricsCount++;
              }
            }
            this.productDetails[productCount].columnIndex = product.cellIndex;
          }
          productCount++;
        }
      });
    } else {
      // call set product
      this.productDetails = [];
    }

    this.stateService.setWorkspaceObject('productDetails', this.productDetails);
    if (this.productDetails && this.productDetails.length > 0) {
      this.displayBricksInLeftPanel();
    }
  }

  /**
    * @description Updates product validations array on loading a campaign
    * @author Amit Mahida
    * @returns
    */
  async updateProductValidationsOnLoad(productsUsedInCampaign, count) {
    const promise = new Promise((resolve, reject) => {
      const product = productsUsedInCampaign[count - 1];
      let idProductCatalogue = product.selected.idProductCatalogue;
      const params = {
        idProductCatalogue,
        action: 'getProductCatalogue',
      };
      const serviceUrl = this.dataShareService.getServiceCallUrlByKey('PCM_GET_PRODUCT');
      this.workspaceService.getProduct(params, serviceUrl).subscribe((data) => {
        const bricsData = _.sortBy(data.data.bricsData, 'row');
        const loadWorkspace: LoadWorkspace = new LoadWorkspace(this.brickBaseService, this.cellAttributeService, this.sharedService, this.stateService);
        const loadedColumnConfig = this.stateService.getWorkspaceObject('columnconfig');
        const loadedColumnSummary = this.stateService.getAllColumnSummary();

        const productRows = loadWorkspace.getWorkspaceFilter(
          this.stateService.getFreshFilterObj(), bricsData, this.initialConfig,
          this.dataShareService.getBricsMasterData(), loadedColumnConfig, loadedColumnSummary,
          null, true, null).rows;

        this.productDetails = this.workspaceService.setProductData(productRows, this.rows, this.productDetails, idProductCatalogue);
        this.stateService.setWorkspaceObject('productDetails', this.productDetails);
        idProductCatalogue = null;

        this.displayBricksInLeftPanel();
        resolve(true);
      }, (err) => {
        console.error(err);
        reject(false);
      });
    });

    const result = await promise; // wait till the promise resolves (*)
    if (result) {
      count--;
      if (count > 0) {
        this.updateProductValidationsOnLoad(productsUsedInCampaign, count);
      } else {
        this.disableMandatoryProductBricksOnLoad();
        if (this.appHeaderService && this.appHeaderService.enabledPCM) {
          this.stateService.setPCMObject('filter', this.stateService.clone(this));
        } else if (this.appHeaderService && this.appHeaderService.objectiveMode) {
          this.stateService.setObjectiveObject('filter', this.stateService.clone(this));
        } else {
          this.stateService.setWorkspaceObject('filter', this.stateService.clone(this));
        }
      }
    } else {
      return;
    }

  }

  /**
   * @description
   * @author Amit Mahida
   * @param {number} colIndex
   * @param {number} bricId
   * @returns
   * @memberof Filter
   */

  /**
   * @description returns true if single cell is found on given colIndex
   * @author Amit Mahida
   * @param {number} colIndex
   * @param {number} bricId
   * @param {boolean} [isOverlapped=false] if true it will check for overlapping cell from previous column
   * @returns
   * @memberof Filter
   */
  checkIfCellExistsInColumn(colIndex: number, bricId: number, isOverlapped = false): number {
    let cellIndex = -1;
    if (this.isRowAvailable(bricId)) {
      const rowIndex = this.rows.findIndex((row) => {
        return row.bric && row.bric.bricid === bricId && row.cells[colIndex]
          && (!row.cells[colIndex].isEmpty || (row.cells[colIndex].isEmpty && row.cells[colIndex].isHidden));
      });
      if (rowIndex !== -1) {
        cellIndex = colIndex;
        if (isOverlapped && this.rows[rowIndex].getOverlappingCell(colIndex) !== -1) {
          cellIndex = this.rows[rowIndex].getOverlappingCell(colIndex);
        }
      }
    }
    return cellIndex;
  }

  /**
   * @description Remove Highlighters from DOM. SM-2697
   * @author Shivani Patel
   * @memberof Filter
   */
  removeHighlighters() {
    let highlightedRow = this.rows.filter(r => r.highlightRow || r.disableCursorHighlight);

    if (highlightedRow.length) {
      // If only row is highlighted
      highlightedRow.forEach((row: Row) => {
        row.removeHighlight();
      });
    } else {
      // If any cell is highlighted
      highlightedRow = this.rows.filter(r => r.cells.filter(c => c.highlightLeft || c.highlightRight || c.highlightCell).length);
      highlightedRow.forEach((row: Row) => {
        const highlightedCell = row.cells.filter(c => c.highlightLeft || c.highlightRight || c.highlightCell);

        highlightedCell.forEach((cell: Cell) => {
          cell.removeHighlight();
        });
      });
    }
  }

  /**
   * This function will take the cellIndex of the deleted column
   * and then will decement the index of all the columns in the columnLineNumber
   * those greater than the deletedCellIndex and will updat ethe state service
   *
   * @param {*} cellIndex
   * @memberof Filter
   */
  decrementColumnLineNumber(cellIndex) {
    if (this.appHeaderService && !this.appHeaderService.enabledPCM && !this.appHeaderService.objectiveMode) {
      const columnLineNumber = this.stateService.getWorkspaceObject('columnLineNumber');
      for (const key in columnLineNumber) {
        if (columnLineNumber.hasOwnProperty(key)) {
          const finalKey: number = parseInt(key, 10);
          if (finalKey > cellIndex) { // Update indexes in columnLineNumber which are greater than deleted  index
            const value = columnLineNumber[finalKey];
            delete columnLineNumber[key];
            columnLineNumber[finalKey - 1] = value;
          } else if (finalKey === cellIndex || columnLineNumber[key] === columnLineNumber[cellIndex]) {
            // Delete ColumnLineNumber if the cellIndex which is deleted exits in the columnLineNumber
            delete columnLineNumber[key];
          }
        }
      }
      this.stateService.setWorkspaceObject('columnLineNumber', columnLineNumber);
    }
  }

  /**
  * This function will take the cellIndex of the newly added column
  * and then will increment the index of all the columns in the columnLineNumber
  * those greater than the addedCellIndex and will update the state service.
  *
  * @param {number} cellIndex
  * @memberof Filter
  */
  incrementColumnLineNumber(cellIndex: number) {
    if (this.appHeaderService && !this.appHeaderService.enabledPCM && !this.appHeaderService.objectiveMode) {
      const columnLineNumber = this.stateService.getWorkspaceObject('columnLineNumber');
      for (const key in columnLineNumber) {
        if (columnLineNumber.hasOwnProperty(key)) {
          const finalKey: number = parseInt(key, 10);
          if (parseInt(key, 10) >= cellIndex) { // Update indexes in columnLineNumber which are greater than newly added index
            const value = columnLineNumber[finalKey];
            delete columnLineNumber[key];
            columnLineNumber[finalKey + 1] = value;
          }

        }
      }
      this.stateService.setWorkspaceObject('columnLineNumber', columnLineNumber);
    }
  }
  /**
   * @description returns true if there's only one range brick exist on workspace
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof Filter
   */
  checkIfOnlyOneInchargeExist(): boolean {
    const inchargeRowIndex = this.getExistingRowIndex(Number(this.brickBaseService.brickID.Incharge));
    if (this.rows[inchargeRowIndex].cells.length === 1) {
      return true;
    } else if (this.rows[inchargeRowIndex].cells.length > 1
      && this.rows[inchargeRowIndex].cells[0].cellWidth === this.rows[inchargeRowIndex].cells.length) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description returns true if there's Quantity brick exist on column
   * @author Amit Mahida
   * @param {number} colIndex
   * @returns {boolean}
   * @memberof Filter
   */
  checkIfAtleastOneQtyBrickExist(colIndex: number): boolean {
    return this.rows.filter(row =>
      this.brickBaseService.quantityBrickId.indexOf(row.bric.bricid) > -1
      && row.cells[colIndex] !== undefined
    ).length > 0;
  }

  /**
   * @description returns brick details from master data
   * @author Amit Mahida
   * @param {number} bricid
   * @param {number} [categoryId]
   * @param {number} [audiencecategorygroupid]
   * @returns
   * @memberof Filter
   */
  getBricDetailsFromMasterData(bricid: number, categoryId?: number, audiencecategorygroupid?: number) {
    const masterData = this.dataShareService.getBricsMasterData();

    const bricDetails = new List<any>(masterData.category)
      .Where((obj) => {
        if (audiencecategorygroupid && categoryId) {
          return obj.categoryid === categoryId
            && obj.brics.filter(bricobj => bricobj.bricid === bricid
              && bricobj.audienceCategoryGroupId === audiencecategorygroupid).length > 0;
        } else if (categoryId) {
          return obj.categoryid === categoryId;
        } else {
          return obj.brics.filter(bricobj => bricobj.bricid === bricid).length > 0;
        }
      }).Select((category) => {
        return category.brics.filter(bricobj => bricobj.bricid === bricid)[0];
      });
    return bricDetails;
  }

  /**
   * @description Mark bricks mandatory against product validations while loading a campaign
   * @author Amit Mahida
   * @memberof Filter
   */
  disableMandatoryProductBricksOnLoad() {
    let isAudienceBrickCounted = false;
    if (this.productDetails.length) {
      for (const row of this.rows) {
        for (const brick of row.cells) {
          for (const element of this.productDetails) {
            if (!_.isUndefined(element.optional[brick.cellIndex])) {
              const optionalBricks = element.optional[brick.cellIndex].concat(this.brickBaseService.OPTIONAL_MANDATORY_BRICS);
              if (optionalBricks.indexOf(row.bric.bricid) === -1) {
                const nonAudBrics = this.brickBaseService.singleAudienceBrics.indexOf(row.bric.bricid) === -1;
                if (nonAudBrics && !brick.isHidden && !brick.isEmpty) {
                  brick.isMandatoryForProduct = true;
                } else if (!nonAudBrics) {
                  if (row.bric.bricid === this.brickBaseService.brickID.Audience && !isAudienceBrickCounted) {
                    isAudienceBrickCounted = true;
                    row.cells[0].isMandatoryForProduct = true;
                  } else if (row.bric.bricid !== this.brickBaseService.brickID.Audience) {
                    row.cells[0].isMandatoryForProduct = true;
                  }
                } else {
                  brick.isMandatoryForProduct = false;
                }
              } else {
                if (row.bric.bricid === this.brickBaseService.brickID.Audience) {
                  isAudienceBrickCounted = true;
                } else if (this.brickBaseService.quantityBrickId.indexOf(row.bric.bricid) > -1 && element.optional[brick.cellIndex].indexOf(row.bric.bricid) === -1) {
                  // If Qty brick is part of product with some value then do not allow to delete it. User can still edit its value
                  brick.isMandatoryEditableForProduct = true;
                }
              }
            }
          }
        }
      }
    }
  }
  /**
   * @description process brics call form PCM
   * @author Nishit Parekh
   * @returns void
   * @memberof Filter
   */
  filterProductBuild(): void {
    if (!this.isMandatoryBricksAvailable()) return; // No need to execute below logic if mandatory bricks are missing

    if (!this.validateMultiTargetBric(true)) {
      return;
    }

    const param: ProcessParams = new ProcessParams();
    const processBrick = {};
    processBrick['bricsData'] = this.brickRequestJSON;
    // TODO: Need to remove hardcoding of bricsCampaignId.
    param.bricsCampaignId = 1;
    param.action = 'filterProductBuild';

    param.data = JSON.stringify(processBrick);
    const serviceURL = this.dataShareService.getServiceCallUrlByKey('PCM_PROCESS_BRICS');
    this.workspaceService.processBricks(param, serviceURL).subscribe((res) => {
      if (this.validateProcessBricksResponse(res) && res['status'] === 'OK') {
        this.stateService.setPCMObject('columnConfig', res.data['columnConfig']);
        if (GLOBAL.localSolverEnabled && res.data.objectiveMeasures) {
          this.mergeObjectiveMeasures(res.data.objectiveMeasures);
        }
      } else {
        // SM-3947: Should use old columnConfig if we get KO from the backend
        // this.stateService.setPCMObject('columnConfig', []);
        this.logHelperService.logError(res['message']);
      }
    });
  }

  updateForceRerunStatus(res) {
    this.appHeaderService.changeforceRerunStatus(res.data.forceRerun);
  }
  /**
   * @description validate current workspace
   * @author Darshan Vachhani
   * @memberof Filter
   */
  validateCurrentWorkspace() {

    let isValid = true;
    const initialConfig = this.dataShareService.getInitialConfig();
    /**
      * @CASE         : 1
      * @Date         : 27/03/2019
      * @Description  : 1) Check if values of key is empty
                        2) If values are empty then highlight that bric
      * @Author       : Darshan Vachhani
      */

    this.rows.forEach((val) => {
      val.isValid = true;
      val.cells.forEach((obj) => {
        let valCount = 0;
        obj.isValid = true;
        for (const key in obj.selected) {
          if (_.isArray(obj.selected[key]) && obj.selected[key].length === 0) {
            valCount += 1;
          }
        }
        if (_.isObject(obj.selected) && valCount === Object.keys(obj.selected).length && !obj.isHidden && !obj.isEmpty) {
          obj.isValid = false;
          val.isValid = false;
          isValid = false;
        }
      });
    });

    /**
     * @CASE         : 2
     * @Description  : Qty brick is stretch to 2 columns and its before Range brick,
     *                 now range and qty brick row is swapped, result workspace is not allowed
     * @Author       : Darshan Vachhani
     */

    const inchargeRowIndex = this.getExistingRowIndex(Number(this.brickBaseService.brickID.Incharge));
    if (inchargeRowIndex !== -1) {
      const availableQtyBricIndex = [];
      this.brickBaseService.quantityBrickId.forEach((qtyBricId: number) => {
        const qtyBricIndex = this.getExistingRowIndex(qtyBricId);
        if (qtyBricIndex !== -1) {
          availableQtyBricIndex.push(qtyBricIndex);
        }
      });
      for (const availableQtyBric of availableQtyBricIndex) {
        const qtyBrickRow = this.rows[availableQtyBric];
        for (let indexOfCell = 0; indexOfCell < qtyBrickRow.cells.length; indexOfCell++) {
          if (
            qtyBrickRow.cells[indexOfCell].cellWidth > 1 &&
            // !qtyBrickRow.cells[indexOfCell].isDisable &&
            qtyBrickRow.bric.bricid !== this.brickBaseService.brickID.SOT) { // and condition added for SBRICS-1883, Nishit
            const endIndex = indexOfCell + qtyBrickRow.cells[indexOfCell].cellWidth - 1;
            const start = this.rows[inchargeRowIndex].getOverlappingCell(indexOfCell);
            const end = this.rows[inchargeRowIndex].getOverlappingCell(endIndex);
            if ((start !== end || (start === -1 && end === -1))
              && (!this.rows[inchargeRowIndex].cells[indexOfCell].isEmpty
                || !this.rows[inchargeRowIndex].cells[endIndex].isEmpty)) {
              qtyBrickRow.isValid = false;
              qtyBrickRow.cells[indexOfCell].isValid = false;
              isValid = false;
              return;
            }
          }
        }
      }
    }
    /**
     * @CASE         : 3
     * @JIRA         : SBRICS-1450
     * @Description  : 1) If selectionPattern object of Pattern Bric is null then it should show error message.
     *                 2) This case happens when we resize or stretch the Range Bric and column contains the Pattern Bric.
     * @Author       : Darshan Vachhani
     */
    const patternBrickIndex = this.getExistingRowIndex(Number(this.brickBaseService.brickID.Pattern));
    if (patternBrickIndex !== -1) {
      const patternBrickRow = this.rows[patternBrickIndex];
      const cellLength = patternBrickRow.cells.length;
      for (let i = 0; i < cellLength; i++) {
        const selectionPattern = patternBrickRow.cells[i].requestJSON;
        const isUsed = !patternBrickRow.cells[i].isEmpty;
        if (selectionPattern === null && isUsed) {
          patternBrickRow.isValid = false;
          patternBrickRow.cells[i].isValid = false;
          isValid = false;
        }
      }
    }

    /**
     * @description This case is written for SBRICS-3005,
     *              where we need to hilight Pricing Brick if frame brick does not exist in that column
     * @author Darshan Vachhani
     * @returns does not return
     */
    const pricingBricIndex = this.getExistingRowIndex(Number(this.brickBaseService.brickID.Pricing));
    if (pricingBricIndex !== -1) {
      const pricingBricRow = this.rows[pricingBricIndex];
      pricingBricRow.cells.forEach((cell) => {
        if ((!cell.isEmpty && !cell.isHidden) || (cell.isEmpty && cell.isHidden)) {
          if (!this.checkForAllowedBrics(
            this.stateService.getWorkspaceObject('columnConfig'),
            this.brickBaseService.brickID.Pricing,
            cell.cellIndex).isAllowed) {
            cell.isValid = false;
            isValid = false;
          }
        }
      });
    }

    const productCatalogueIndex = this.getExistingRowIndex(Number(this.brickBaseService.brickID.ProductCatalogue));
    if (productCatalogueIndex !== -1) {
      const filterDisabledProducts = SystemFlags.isLoadedCampaign ? false : true;
      this.rows = this.pcmService.markProductBricInvalid(
        this.rows,
        this.stateService.getCampaign(),
        inchargeRowIndex,
        filterDisabledProducts,
        this.stateService);
      const productIndex = this.getExistingRowIndex(Number(this.brickBaseService.brickID.ProductCatalogue));
      if (productIndex !== -1) {
        const productCatalogueRow = this.rows[productIndex];
        productCatalogueRow.cells.forEach((cell) => {
          if (!cell.isEmpty && !cell.isHidden && !cell.isValid) {
            isValid = false;
          }
        });
      }
    }

    // Check for Frame Quality Brick//
    const frameQualityRowIndex = this.getExistingRowIndex(Number(this.brickBaseService.brickID.FrameQuality));
    if (frameQualityRowIndex !== -1) {
      for (let colIndex = 0; colIndex < this.rows[0].cells.length; colIndex++) {
        const isColumnValid = this.isMandatoryBricksAvailable(this.rows[0].cells[colIndex].cellIndex);
        if (isColumnValid) {
          let isQuantityBricPresentInColumn = false;
          this.rows.forEach((element, index) => {
            if (this.isQuantityBric(element.bric.bricid) &&
              !(element.bric.bricid === this.brickBaseService.brickID.SOT)) {
              let isResized = false;
              if (this.rows[index].getOverlappingCell(colIndex) > -1) {
                isResized = true;
              }
              if ((!element.cells[colIndex].isEmpty && !element.cells[colIndex].isHidden)
                || (isResized && element.cells[colIndex].isEmpty && element.cells[colIndex].isHidden)) {
                isQuantityBricPresentInColumn = true;
              }
            }
          });
          if (isQuantityBricPresentInColumn === false) {
            let isResized = false;
            const resizedIndex = this.rows[frameQualityRowIndex].getOverlappingCell(colIndex);
            if (resizedIndex > -1) {
              isResized = true;
            }
            if ((!this.rows[frameQualityRowIndex].cells[colIndex].isEmpty
              && !this.rows[frameQualityRowIndex].cells[colIndex].isHidden) ||
              (isResized && this.rows[frameQualityRowIndex].cells[colIndex].isEmpty
                && this.rows[frameQualityRowIndex].cells[colIndex].isHidden)
            ) {
              isValid = false;
              if (isResized) {
                this.rows[frameQualityRowIndex].cells[resizedIndex].isValid = false;
              } else {
                this.rows[frameQualityRowIndex].cells[colIndex].isValid = false;
              }
            }
          }
        }
      }

    }

    // check for Multi-Target Bric
    isValid = this.validateMultiTargetBric(isValid);

    /**
     * @CASE         : 4
     * @Description  : Checks whether required bricks are there in particular column
     * @Author       : Darshan Vachhani
     */
    const totalNoOfColumns = this.getMaxCellIndex() + 1;
    for (let cellIndex = 0; cellIndex < totalNoOfColumns; cellIndex++) {
      const isColumnValid = this.isMandatoryBricksAvailable(cellIndex);
      // Hightlight the first nonmandatory brics in the particular column
      if (isColumnValid === false) {
        // Check if the brick is amongst the list of mandatory brics
        const allowedBrics = _.union(initialConfig.uiControl.processingBrics, initialConfig.uiControl.optionalBrics);
        for (const row of this.rows) {
          if (allowedBrics.indexOf(row.bric.bricid) === -1) {
            if (!row.cells[cellIndex].isEmpty && !row.cells[cellIndex].isHidden) { // Case when invalid brick is visible and used
              row.isValid = false;
              row.cells[cellIndex].isValid = false;
              isValid = false;
              break;
            } else if (row.cells[cellIndex].isEmpty && row.cells[cellIndex].isHidden) {// Case when invalid brick is stretched brick
              const resizableIndex = row.getOverlappingCell(cellIndex);
              if (resizableIndex !== -1) {
                row.isValid = false;
                row.cells[resizableIndex].isValid = false;
                isValid = false;
                break;
              }
            }
          }
        }
      }
    }

    /**
 * @CASE         : 5
 * @Description  : Objective Brick is not allowed for column with GISGO allocation engine
 * @Author       : Vijay Sutaria
 * @Date         : 21-04-2021
 */
    if (GLOBAL.localSolverEnabled) {
      const objectiveBrickRowIndex = this.getExistingRowIndex(Number(this.brickBaseService.brickID.Objective));
      if (objectiveBrickRowIndex !== -1) {
        for (let colIndex = 0; colIndex < this.rows[objectiveBrickRowIndex].cells.length; colIndex++) {
          if (!this.rows[objectiveBrickRowIndex].cells[colIndex].isEmpty &&
            !this.rows[objectiveBrickRowIndex].cells[colIndex].isHidden &&
            this.allocationEngines &&
            this.allocationEngines[colIndex] === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_GISGO) {
            this.rows[objectiveBrickRowIndex].cells[colIndex].isValid = false;
            isValid = false;
          }
        }
      }
    }

    isValid = this.validateFormatBric(isValid);

    return isValid;
  }

  validateMultiTargetBric(isValid: boolean) {
    // check for Multi-Target Bric
    const multiTarget = this.getExistingRowIndex(Number(this.brickBaseService.brickID.MultiTarget));
    if (multiTarget !== -1) {
      const columns = this.rows[0].cells.length;
      for (let i = 0; i < columns; i++) {
        const inUseByResizable = this.rows[multiTarget].getOverlappingCell(i);
        const cell = this.rows[multiTarget].cells[inUseByResizable === -1 ? i : inUseByResizable];
        // when column has media with only paper and same column has selected multi target bric
        if (this.checkIfColumnHasOnlyPaper(i) && cell.selected && Object.keys(cell.selected).length) {
          cell.isValid = false;
          isValid = false;
          this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.multitarget.media'] || 'Please change value of Media bric to make solution viable.');
        }
      }
    }
    return isValid;
  }

  /**
   * To check is valid format bric or not
   * @param isValid Boolean isValid brick
   * @returns boolean
   * @author Dhaval Patel
   */
  validateFormatBric(isValid: boolean) {
    // check for Format Bric
    if (this.rows.length > 0) {
      for (let rowIndex = 0; rowIndex < this.rows.length; rowIndex++) {
        const element = this.rows[rowIndex];
        if (element && element.bric && element.bric.bricid === this.brickBaseService.brickID.Format) {
          if (element.cells && element.cells.length) {
            for (let cellIndex = 0; cellIndex < element.cells.length; cellIndex++) {
              const cell = element.cells[cellIndex];
              if (!cell.isEmpty && cell.requestJSON && (!cell.requestJSON.selectionCriteriaFormat || cell.requestJSON.selectionCriteriaFormat.length === 0)) {
                isValid = false;
                break;
              }
            }
          }
        }
      }
    }
    return isValid;
  }

  /**
   * @description validate pricing bric for frame bric is available or not
   * @author Darshan Vachhani
   * @param {*} dropAtcellIndex
   * @returns return true if frame bric is available
   * @memberof Filter
   */
  validatePricingBRIC(dropAtcellIndex) {
    let frameBRICExists = false;
    this.rows.forEach((row) => {
      if (row.bric.bricid === this.brickBaseService.brickID.Frame
        && (!row.cells[dropAtcellIndex].isEmpty
          || (row.cells[dropAtcellIndex].isEmpty && row.cells[dropAtcellIndex].isHidden))) {
        frameBRICExists = true;
      }
    });
    return frameBRICExists;
  }

  /*
     * Below method checks whether the brics is allowed or not based on (column config if its available) OR (Mandatory Brics)
     * @function
     * @param {columnConfig} - Column config for a particular column
     * @param {bricId} - Id of Bric
     * @param {dropAtcellIndex} - Column Index
     * Returns true if the bric is allowed else false
     * @author : Darshan Vachhani
     */
  checkForAllowedBrics(columnConfig, bricId, dropAtcellIndex) {
    const initialConfig = this.dataShareService.getInitialConfig();
    const returnObject = {
      'isAllowed': true,
      'logInfo': ''
    };

    const allowedBrics = _.union(initialConfig.uiControl.processingBrics, initialConfig.uiControl.optionalBrics);

    if (allowedBrics.indexOf(bricId) > -1) {
      return returnObject;
    } else {
      returnObject.isAllowed = !this.isMandatoryBricksAvailable(dropAtcellIndex);
      returnObject.logInfo = initialConfig.userBundle['workspace.error.missingRequiredBricks'];
    }

    // Once the mandatory bric checking is done, then check for column config //
    if (returnObject.isAllowed) {
      // check if coloumn config exits or not//
      if (Object.keys(columnConfig).length > 0) {
        if (bricId === this.brickBaseService.brickID.Pricing) {
          if (!this.validatePricingBRIC(dropAtcellIndex)) {
            returnObject.isAllowed = false;
            returnObject.logInfo = initialConfig.userBundle['common.error.notallowed'];
          }
        }
        return returnObject;
      } else {
        // case where there is not data in column config and when pattern bric or Rating bric is dropped  //issue found, internal testing
        // we do not know which data source to pass as there will be multiple data source for these brics // Nishit

        if (bricId === this.brickBaseService.brickID.Pattern
          || bricId === this.brickBaseService.brickID.Audience
          || bricId === this.brickBaseService.brickID.SecondaryAudience) {
          returnObject.isAllowed = false;
          returnObject.logInfo = 'Please add required bricks or correct invalid brick!';
        }

        // below condition for SBRICS-3005, Nishit
        if (bricId === this.brickBaseService.brickID.Pricing) {
          if (!this.validatePricingBRIC(dropAtcellIndex)) {
            returnObject.isAllowed = false;
            returnObject.logInfo = initialConfig.userBundle['common.error.notallowed'];
          }
        }
      }
    }

    return returnObject;
  }

  /**
   * @description This will check if Range and SoT brics have selected valid valies when product is used
   * @author Nishit Parekh
   * @returns {boolean}
   * @memberof Filter
   */
  isValidSelectionForProduct(): boolean {
    let validProduct = true;
    this.rows.forEach((row: Row) => {
      row.cells.forEach((brick: Cell) => {
        for (const product of this.productDetails) {
          switch (row.bric.bricid) {
            case this.brickBaseService.brickID.Incharge:
              if (!SystemFlags.splitted && !_.isUndefined(product.validation[brick.cellIndex]) && Object.keys(brick.selected).length) {
                const validRange = product.validation[brick.cellIndex][row.bric.bricid];

                if (validRange && Object.keys(validRange).length === 0) {
                  brick.isValid = true;
                } else {
                  let minDays = -1;
                  if (validRange.minValue) {
                    minDays = (validRange.minValue.unit === 1 ? validRange.minValue.value * 7 : validRange.minValue.value);
                  }
                  if (moment(brick.selected.startDate).isSameOrAfter(validRange.startDate) &&
                    moment(brick.selected.endDate).isSameOrBefore(validRange.endDate) &&
                    (!this.initialConfig.uiControl.showInvalidProducts ||
                      ((minDays - 1) <= moment(brick.selected.endDate).diff(moment(brick.selected.startDate), 'days') &&
                        ((!validRange.defaultWeekDay && validRange.defaultWeekDay !== 0) || new Date(brick.selected.startDate).getDay() === validRange.defaultWeekDay)))) {
                    brick.isValid = true;
                  } else {
                    validProduct = false;
                    brick.isValid = false;
                  }
                  if (validRange.hasOwnProperty('defaultWeekDay') &&
                    (validRange['defaultWeekDay'] !== new Date(brick.selected.startDate).getDay())) {
                    validProduct = false;
                    brick.isValid = false;
                  }
                }
              } else if (SystemFlags.splitted && !_.isUndefined(product.validation[brick.cellIndex]) && Object.keys(brick.selected).length) {
                //added logic to validate the incharge bric when splitted
                const validRange = product.validation[brick.cellIndex][row.bric.bricid];
                if (validRange && validRange.startDate && validRange.endDate && (moment(brick.selected.startDate).isBefore(validRange.startDate) ||
                  moment(brick.selected.endDate).isAfter(validRange.endDate))) {
                  validProduct = false;
                  brick.isValid = false;
                }
              }
              break;
            case this.brickBaseService.brickID.SOT:
              if (!_.isUndefined(product.validation[brick.cellIndex]) && Object.keys(brick.selected).length) {
                const validations = product.validation[brick.cellIndex][row.bric.bricid];
                const selectedSot = parseFloat(parseFloat(brick.selected.sot).toFixed(2));

                if (!_.isUndefined(validations)) {
                  if (selectedSot >= validations.min && selectedSot <= validations.max &&
                    selectedSot % validations.increment === 0 || validations.hasOwnProperty('-99') ||
                    Object.keys(validations).length === 0
                  ) {
                    brick.isValid = true;
                  } else {
                    validProduct = false;
                    brick.isValid = false;
                  }
                }
              }
              break;
            default:
              break;
          }
        }
      });
    });
    return validProduct;
  }

  /**
   * @description removes unused column configs
   * @author Amit Mahida
   * @memberof Filter
   */
  removeUnUsedColumnConfigs() {
    const virtualLastIndex = this.getMaxCellIndex();
    const columnConfig = this.stateService.getWorkspaceObject('columnConfig');
    for (let colIndex = 0; colIndex <= virtualLastIndex; colIndex++) {
      const mandatoryBricksAvailable = this.isMandatoryBricksAvailable(colIndex);

      if (!mandatoryBricksAvailable && columnConfig && columnConfig[colIndex]) {
        delete columnConfig[colIndex];
      }
    }
    this.stateService.setWorkspaceObject('columnConfig', columnConfig);
  }

  /**
  * This function will be used only when external user logged in
  * This function will add or remove bricks from left panel based on current workspace state
  * This function should not execute if external user
  * VJ: 14/11/2018
  */
  displayBricksInLeftPanel() {
    const masterData: BricsMasterData = this.dataShareService.getBricsMasterData();
    const userData = this.dataShareService.getInitialConfigByKey('userData');

    let brickIDs = [];
    this.productDetails.forEach((obj: ProductHelper) => {
      Object.keys(obj.optional).forEach((o) => {
        brickIDs = brickIDs.concat(obj.optional[o]);
      });
    });

    brickIDs = brickIDs.filter((this.onlyUnique)); // Unique Brick IDs

    masterData.category.forEach((category: Category) => {
      category.brics.forEach((brick: Brick) => {
        // If this brick is part of added product and its optional then must be visible in left panel
        brick.optionalForProduct = brickIDs && brickIDs.length > 0 && brickIDs.indexOf(brick.bricid) !== -1;
      });

      // Hiding category if all brics are hidden
      if (userData.externalUser) {
        category.hide = category.brics.filter(b => b.hide && !b.optionalForProduct).length === category.brics.length;
      }
    });
    this.stateService.setMasterDataForLeftPanel(masterData);
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  /**
   * @description show/hide past columns
   * @author Amit Mahida
   * @param {boolean} isHidden
   * @param {ColumnHelper} columnHelper
   * @param {ColumnSummaryTooltip[]} columnSummarys
   * @memberof Filter
   */
  manageVisibilityOfPastColumns(isHidden: boolean, columnHelper: ColumnHelper, columnSummarys: ColumnSummaryTooltip[]) {
    let chChanged = false;
    for (const row of this.rows) {
      for (const cell of row.cells) {
        if (columnHelper && columnHelper[cell.cellIndex] && columnHelper[cell.cellIndex].systemLocked) {
          columnHelper[cell.cellIndex].shown = !isHidden;
          chChanged = true;
          if (columnSummarys[cell.cellIndex]) {
            columnSummarys[cell.cellIndex].isHidden = isHidden;
          }
        }
      }
    }

    if (chChanged) {
      this.stateService.setColumnHelper(columnHelper);
    }
  }

  /**
   * @description Remove Patten Brick if Incharge brick delete
     * @author Kishan Patel
     * @param {*} cellIndex
     * @param {boolean} [isRowDelete=false]
     * @memberof Filter
     */
  removePattern(cellIndex, isRowDelete = false) {
    const patternRow = this.getRowByBrickId(Number(this.brickBaseService.brickID.Pattern));
    if (patternRow) {
      if (isRowDelete) {
        this.deleteRow(patternRow.index);
        this.reApplyIndex();
      } else {
        this.rows[patternRow.index].removeCell(cellIndex);
        this.rows[patternRow.index].createEmptyCellAtIndex(patternRow.index, cellIndex, false, this.stateService.getColumnHelper());
      }
    }
  }

  //#region - SM-3485 Reshuffle
  checkToleranceReshuffleBricks(): void {
    this.reshuffle.checkToleranceReshuffleBricks(this.rows, this.stateService.getColumnHelper());
  }

  populateReshufflingColumns(isNoAvailableFrame: boolean, reshuffleColIndexTocheck: number) {
    const mediaBrickRow = this.getRowByBrickId(Number(this.brickBaseService.brickID.Media));
    this.reshuffle.populateReshufflingColumns(this.rows, mediaBrickRow, isNoAvailableFrame, reshuffleColIndexTocheck, this.stateService.getColumnHelper());
  }
  //#endregion - SM-3485 Reshuffle

  // SM-4480 Tolerance limit
  populateToleranceLimitCellAdd() {
    this.toleranceLimit.populateToleranceLimit(this.rows);
  }

  getSOTRestrictionDetails(brick) {
    if (this.initialConfig.uiControl.autoCalculateFloorAndCeiling) {
      const restrictionObject = {
        'productValidationVolume': null,
        'productValidationBudget': null
      };
      const volumeRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Volume, this.rows).Available_row_Index;
      if (volumeRowIndex != null) {
        const volumeBric = this.rows[volumeRowIndex].cells[brick.cellIndex];
        const validations = this.getProductHelperIfProductExists(volumeBric.cellIndex);
        if (validations) {
          restrictionObject.productValidationVolume = validations.validation[volumeBric.cellIndex][this.brickBaseService.brickID.Volume];
        }
      }
      const budgetRowIndex = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Budget, this.rows).Available_row_Index;
      if (budgetRowIndex != null) {
        const budgetBric = this.rows[budgetRowIndex].cells[brick.cellIndex];
        const validations = this.getProductHelperIfProductExists(budgetBric.cellIndex);
        if (validations) {
          restrictionObject.productValidationBudget = validations.validation[budgetBric.cellIndex][this.brickBaseService.brickID.Budget];
        }
      }
      return restrictionObject;
    } else return null;
  }

  /**
   * @description this will calculate the index for which column summary needs to be returned [SM-4252]
   * @author Nishit Parekh
   * @param {{[key: string]: ColumnSummary }} columnSummaryAll column summary for all columns
   * @param {number} bricId brick id
   * @param {Brick} brick brick object
   * @returns column summary for specific column
   * @memberof WorkspaceComponent
   */
  getColumnSummary(columnSummaryAll: { [key: string]: ColumnSummary }, bricId: number, cellIndex: number) {
    const rowIndex = this.workspaceService.isBricRowAvailable(bricId, this.rows).Available_row_Index;
    const columnsummary = {};
    if (rowIndex !== null) {
      const inUseByResizable = this.rows[rowIndex].getOverlappingCell(cellIndex);
      // If Brick is Stretched
      if (inUseByResizable !== -1) {
        for (let i = inUseByResizable; i < (this.rows[rowIndex].cells[cellIndex].cellWidth + cellIndex); i++) {
          columnsummary[i] = columnSummaryAll[i];
        }
        return columnsummary;
      } else {
        // Brick is not Stretched
        columnsummary[cellIndex] = columnSummaryAll[cellIndex] || {};
        return columnsummary;
      }
    } else {
      columnsummary[cellIndex] = columnSummaryAll && Object.keys(columnSummaryAll).length ? columnSummaryAll[cellIndex] : {};
      return columnsummary;
    }
  }

  validateProductAgainstPrimaryAudience() {
    let primaryAudienceAsOptional = false;
    // check if product row exists
    const productExists = this.rows.filter(e => e.bric.bricid === this.brickBaseService.brickID.ProductCatalogue);
    if (productExists) {
      // check if product has route bric as optional
      this.productDetails.forEach((e) => {
        if (!primaryAudienceAsOptional) {
          Object.keys(e.optional).forEach((i) => {
            primaryAudienceAsOptional = e.optional[i].indexOf(this.brickBaseService.brickID.PrimaryAudience) > -1 ? false : true;
          });
        }
      });
    }

    return primaryAudienceAsOptional;
  }

  /**
   * @description Returns true if non editable columns present
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof Filter
   * @param colIndex @optional
   * @override If colIndex is passed it will check whether the column is not editable or not for the given colIndex
   */
  isNonEditableColsPresent(colIndex?: number) {
    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
    let isNonEditableColsPresent = false;
    if (colIndex !== undefined) {
      return columnHelper && columnHelper[colIndex] && columnHelper[colIndex].systemLocked;
    }
    for (const key in columnHelper) {
      if (columnHelper.hasOwnProperty(key)) {
        const columnH = columnHelper[key];
        if (columnH.systemLocked) {
          isNonEditableColsPresent = true;
        }
      }
    }
    return isNonEditableColsPresent;
  }

  /**
   * @description Returns true if locked columns present
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof Filter
   * @param colIndex @optional
   * @override If colIndex is passed it will check whether the column is locked or not for the given colIndex
   */
  isLockedColsPresent(colIndex?: number) {
    const columnHelper: ColumnHelper = this.stateService.getColumnHelper();
    if (colIndex !== undefined) {
      // Due to SM-6264, We are sending allocationEngine in columnHelper with both locked property as false
      // But here in below line we are just checking if record is available at index or not
      // So now onwards need to check if any of locked property is true or not
      return columnHelper
        && columnHelper[colIndex]
        && (columnHelper[colIndex].systemLocked || columnHelper[colIndex].userLocked)
        ? true : false;
    }
    let isLockedColsPresent = false;
    for (const key in columnHelper) {
      if (columnHelper.hasOwnProperty(key)) {
        const columnH = columnHelper[key];
        if (!columnH.systemLocked) {
          isLockedColsPresent = true;
        }
      }
    }
    return isLockedColsPresent;
  }
  /**
    * @description create a new cell with selected vaue and push in selected Values Array
    * @author Kishan Patel
    * @returns null
    * @memberof Filter
    * @param selectionId (106,107,108) for treeview selectionId which is in location selection object
    *  selected
    *  selectedValues is array
    * @override
    */
  locationCommon(selectionId, selected, selectedValues, cellToExplode) {
    const newCell: Cell = new Cell();
    newCell.updateCellValues(cellToExplode);
    newCell.selected = {};
    newCell.selected[selectionId] = [];
    newCell.selected[selectionId].push(selected);
    newCell.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Location, newCell.selected);
    newCell.requestJSON = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Location, newCell.selected);
    newCell.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Location, newCell.selected);
    selectedValues.push(newCell);
  }

  refresh(): Filter {
    const masterData = this.dataShareService.getBricsMasterData();
    const mediaTypes = this.dataShareService.getInitialConfigByKey('mediaType');

    for (const row of this.rows) {
      for (const cell of row.cells) {
        if (!cell.isEmpty) {
          if (Number(row.bric.bricid) === Number(this.brickBaseService.brickID.Media)) {
            const selectedMedia = mediaTypes.find(mt => mt.mediaTypeId === cell.selected.selectedMediaObject.mediaTypeId);
            cell.selected.selectedMediaObject.mediaTypeName = selectedMedia ? selectedMedia.mediaTypeName : cell.selected.selectedMediaObject.mediaTypeName;
          }
          cell.displayText = this.cellAttributeService.getDisplayText(row.bric.bricid, cell.selected);
          cell.toolTipText = this.cellAttributeService.getToolTip(row.bric.bricid, cell.selected);
        }
      }
      const category = masterData.category.find(cat => cat.categoryid === row.bric.categoryid);
      if (category) {
        row.bric.categoryName = category.name;
        const bric = category.brics.find(bric => bric.bricid === row.bric.bricid);
        row.bric.bricname = bric.bricname;
      }

    }
    return this;
  }

  /**
   * @description common function for process after explod any cell
   * @author Kishan Patel
   * @param {*} cell
   * @param {*} totalSelected
   * @memberof Filter
   */
  processExplodeCell(cell, totalSelected) {
    this.rows[cell.rowIndex].cells.splice(cell.cellIndex, 1); // Remove cell which is exploded

    // Update width of all other row's cell and stretch it
    for (let rowIndex = 0; rowIndex < this.rows.length; rowIndex++) {
      if (rowIndex !== cell.rowIndex) {

        // Find previous cell which is overlapping current cell
        let overlappedCellIndex = this.rows[rowIndex].getOverlappingCell(cell.cellIndex);
        overlappedCellIndex = overlappedCellIndex !== -1 ? overlappedCellIndex : cell.cellIndex;

        // If overlapping cell is Empty, then it should insert Empty cells otherwise It should stretched the overlapping cell
        // VJ: 2nd condition is added for SM-6264 where if its LS and reduction brick spanning is not allowed
        if ((this.rows[rowIndex].cells[overlappedCellIndex].isEmpty && !this.rows[rowIndex].cells[overlappedCellIndex].isHidden)
          || (GLOBAL.localSolverEnabled && !this.initialConfig.uiControl.allowReductionBrickSpan
            && this.brickBaseService.quantityBrickId.indexOf(this.rows[rowIndex].bric.bricid) > - 1)
        ) {
          for (let j = 0; j < totalSelected - 1; j++) {
            const emptyCell: Cell = new Cell();
            emptyCell.createEmptyCell(false);
            this.rows[rowIndex].cells.splice(cell.cellIndex + j + 1, 0, emptyCell);
            if (this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Media && this.initialConfig.uiControl.defaultAllocationEngine) { // This means Local Solver is enabled
              const allocationEngine = this.rows[rowIndex].cells[cell.cellIndex].selected.selectedMediaObject.defaultAllocationEngine || this.initialConfig.uiControl.defaultAllocationEngine;
              this.allocationEngines.splice(cell.cellIndex + j + 1, 0, allocationEngine);
            }
          }
        } else {
          this.rows[rowIndex].cells[overlappedCellIndex].cellWidth += totalSelected - 1;
          for (let j = 0; j < totalSelected - 1; j++) {
            const newBlankHiddenCell: Cell = new Cell();
            newBlankHiddenCell.createEmptyCell(true);
            this.rows[rowIndex].cells.splice(cell.cellIndex + j + 1, 0, newBlankHiddenCell);
            if (this.rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Media && this.initialConfig.uiControl.defaultAllocationEngine) { // This means Local Solver is enabled
              const allocationEngine = (this.rows[rowIndex].cells[cell.cellIndex].selected && this.rows[rowIndex].cells[cell.cellIndex].selected.selectedMediaObject && this.rows[rowIndex].cells[cell.cellIndex].selected.selectedMediaObject.defaultAllocationEngine) || this.initialConfig.uiControl.defaultAllocationEngine;
              this.allocationEngines.splice(cell.cellIndex + j + 1, 0, allocationEngine);
            }
          }
        }
      }
    }
  }

  /**
   * @description for getting treeview selected value for exploded Area location
   * @author Kishan Patel
   * @param {*} indexValue
   * @param {*} cell
   * @param {*} explodedCells
   * @memberof Filter
   */
  explodeAreaTreeViewData(indexValue, cell: Cell, explodedCells) {
    const columnConfig = this.stateService.getWorkspaceObject('columnConfig');
    const configLookup = columnConfig && columnConfig[cell.cellIndex] ? columnConfig[cell.cellIndex].lookupData : [];
    const treeData = this.sharedService.filterGeoTreeWithColumnConfig(this.initialConfig.geoLevels, configLookup);
    const selectedValue = cell.selected[indexValue];
    let otherAreas = false;
    const keys = Object.keys(cell.selected);
    for (const key of keys) {
      if (key !== indexValue && cell.selected[key].length > 0) {
        otherAreas = true;
        break;
      }
    }

    if (treeData) {
      cell.selected[indexValue].forEach((current) => {
        let result = null;
        switch (Number(indexValue)) {
          case GLOBAL.geoArea.area106:
            result = treeData.find(e => e.id === current.id);
            if (Object.keys(result).length && result['child'] && result['child'].length && selectedValue.length === 1 && !otherAreas) {
              result['child'].forEach((ele) => {
                cell.selected[indexValue] = [];
                this.locationCommon(GLOBAL.geoArea.area107, { id: ele.id, name: ele.name }, explodedCells, cell);
              });
            } else if (Object.keys(result).length && (result['child'] && selectedValue.length > 1 || !result['child'].length || otherAreas)) {
              this.locationCommon(GLOBAL.geoArea.area106, { ...current }, explodedCells, cell);
            }
            break;

          case GLOBAL.geoArea.area107:

            treeData.forEach((e) => {
              if (e.child && !result) {
                result = e.child.find(a => a.id === current.id);
              }
            });
            result = result || {};
            if (Object.keys(result).length && result['child'] && result['child'].length && selectedValue.length === 1 && !otherAreas) {
              result['child'].forEach((ele) => {
                cell.selected[indexValue] = [];
                this.locationCommon(GLOBAL.geoArea.area108, { id: ele.id, name: ele.name }, explodedCells, cell);
              });
            } else if (Object.keys(result).length && ((result['child'].length && selectedValue.length > 1) || !result['child'].length || otherAreas)) {
              this.locationCommon(GLOBAL.geoArea.area107, { ...current }, explodedCells, cell);
            }
            break;

          case GLOBAL.geoArea.area108:

            treeData.forEach((e) => {
              if (e.child) {
                e.child.forEach((a) => {
                  if (a.child && a.child.length && !result) {
                    result = a.child.find(a => a.id === current.id);
                  }
                });
              }
            });

            result = result || {};
            if (Object.keys(result).length) {
              cell.selected[indexValue] = [];
              this.locationCommon(GLOBAL.geoArea.area108, { ...current }, explodedCells, cell);
            }
            break;
          default:
            break;
        }
      });
    }
  }

  checkIfColumnHasOnlyPaper(cellIndex: number): boolean {
    let mediaBrick: Cell;
    const mediaRow: Row = this.rows.find(row => row.bric.bricid === this.brickBaseService.brickID.Media);
    if (mediaRow) {
      const inUseByResizable: number = mediaRow.getOverlappingCell(cellIndex);
      mediaBrick = inUseByResizable === -1 ? mediaRow.cells[cellIndex] : mediaRow.cells[inUseByResizable];
    }
    return mediaBrick && mediaBrick.selected && mediaBrick.selected.selectedMedia === '0';
  }

  /**
     * @description This recusrsive function will decides at which column,
     * it should be allow to drop bric when dropped on drop zone
     * @param {*} cellIndex cell index at which new bric needs to be placed
     * @param {*} draggedBricId brick Id of the bric
     * @returns cellIndex allowed to drop
     */
  getCellIndexAgainstProduct(cellIndex: number, draggedBricId: number) {
    // below code to check if optional bric is allowed to drop at which index
    if (this.productDetails.length > 0 && this.pcmService.checkIfProductExistsInColumn(this.rows, cellIndex, this.productDetails)) {
      const cellIndexToCheckValidation = this.pcmService.getOverlappingProductColumn(this.rows, cellIndex);
      const optionalBric = this.productDetails.filter((product: ProductHelper) => {
        return product.optional[cellIndexToCheckValidation] && product.optional[cellIndexToCheckValidation].indexOf(draggedBricId) > -1;
      });
      if (optionalBric && optionalBric.length > 0) {
        const bricExists = null;
        this.rows.forEach((ele: Row) => {
          return ele.cells[cellIndex].isHidden && ele.cells[cellIndex].isEmpty;
        });

        if (bricExists) {
          return this.getCellIndexAgainstProduct(cellIndex + 1, draggedBricId);
        } else {
          return cellIndex;
        }
      } else {
        return this.getCellIndexAgainstProduct(cellIndex + 1, draggedBricId);
      }
    } else if (draggedBricId === this.brickBaseService.brickID.ProductCatalogue) {
      // added this else if condition for SM-3507, When range bric is stretched and 1st column is locked/past and we drop product bric
      const inchargBricRow = this.workspaceService.isBricRowAvailable(this.brickBaseService.brickID.Incharge, this.rows);
      const resizeIndex = this.rows[inchargBricRow.Available_row_Index].getOverlappingCell(cellIndex);
      const lockButtons = this.stateService.getColumnHelper();
      if (resizeIndex > -1) {
        return this.rows[inchargBricRow.Available_row_Index].cells[resizeIndex].isLocked || (lockButtons[resizeIndex] && lockButtons[resizeIndex].systemLocked) ? this.getCellIndexAgainstProduct(cellIndex + 1, draggedBricId) : cellIndex;
      } else {
        return (this.rows[inchargBricRow.Available_row_Index].cells[cellIndex] && this.rows[inchargBricRow.Available_row_Index].cells[cellIndex].isLocked) || (lockButtons[cellIndex] && lockButtons[cellIndex].systemLocked) ? this.getCellIndexAgainstProduct(cellIndex + 1, draggedBricId) : cellIndex;
      }
    } else if (this.rows.length > 0 && (cellIndex === this.rows[0].cells.length - 1) && !this.pcmService.checkIfProductExistsInColumn(this.rows, cellIndex, this.productDetails)) {
      // case when bric (non mandatory) tries to create a new COLUMN after existing columns, allowed to drop if it is non quantity
      if ((this.isQuantityBric(draggedBricId) || (draggedBricId === this.brickBaseService.brickID.SOT)) && (!this.isMandatoryBricksAvailable(cellIndex))) {
        this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.quantity.drop']);
        return -1;
      } else {
        return cellIndex;
      }
    } else {
      // case when no product exists on workspace
      return cellIndex;
    }
  }

  /**
   * @description Get brick ids present on current workspace
   * @author Amit Mahida
   * @param {number} colIndex
   * @returns Array of brick Ids
   * @memberof Filter
   */
  getNonEmptyBricsOfColumn(colIndex: number) {
    return this.rows
      .filter((rowObj: any) => {
        let row: Row = new Row();
        if (!(rowObj instanceof Row)) {
          for (var prop in rowObj) {
            row[prop] = rowObj[prop];
          }
        } else {
          row = rowObj;
        }

        const cellIndex = row.getOverlappingCell(colIndex);
        if (cellIndex !== -1) {
          colIndex = cellIndex;
        }
        return !row.cells[colIndex].isEmpty && !row.cells[colIndex].isHidden;
      }).map(row => row.bric.bricid);
  }

  /**
   * @description Is stretch feature is allowed or not on product
   * If stretching is on same product then it will be allowed.
   * If stretching is not on same product then not allowed
   * If stretching is on product and non-product columns then not allowed
   * If stretching is on non-product columns then it allowed
   * @author Dhaval Patel
   * @param {number} colIndex
   * @param {number} numOfIncreasedCell
   * @returns object
   * @memberof Filter
   */
  checkCellStretchOnProduct(cellIndex, numOfIncreasedCell) {
    const result = {
      isProductExist: false,
      allProductSame: true
    };
    let productId = null;
    if (cellIndex > -1 && numOfIncreasedCell > 0) {
      for (let i = 0; i <= numOfIncreasedCell; i++) {
        const product = this.getProductHelperIfProductExists(cellIndex + i);
        if (product) {
          result.isProductExist = true;
          if (productId && productId !== product.idProductCatalogue) {
            result.allProductSame = false;
          } else {
            productId = product.idProductCatalogue;
          }
        } else {
          result.allProductSame = false;
        }
      }
    }
    return result;
  }

}
