import { Injectable } from '@angular/core';
import { VisualPlannerComponent } from '../../geoplanner/src/app/visual-planner/visual-planner.component';
import { CanDeactivate } from '@angular/router';
import { LoaderService } from '../../geoplanner/src/app/core/services';

@Injectable()
export class CanDeactivateVpService implements CanDeactivate<VisualPlannerComponent> {

  constructor(private loaderService: LoaderService) { }

  // @ts-ignore
  canDeactivate(component: VisualPlannerComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean { // tslint:disable-line

    if (this.loaderService.getBackButton()) {
      this.loaderService.setBackButton(false);
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);
      return false;
    } else if (component.allowNavigation) {
      return true;
    } else {
      component.beforeNavigate(nextState.url);
      return false;
    }
  }
}
