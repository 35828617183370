import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, CommercialConfig, HistoryROC } from '../../commercial.model';
import { DataShareService } from '../../../core/services/data-share.service';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { StateService, MaskService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { CampaignDetailsBase } from '../campaign-details-base';
import { SystemFlags } from '../../../models/system-flags';
import { Campaign } from '../../../models/campaign';
import { NumberMaskModel } from '../../../models/imask';
import { CampaignGroupTypeList } from '../../../core/enum';

@Component({
  selector: 'app-campaign-details-fi',
  templateUrl: './campaign-details-fi.component.html',
  styleUrls: ['./campaign-details-fi.component.css']
})
export class CampaignDetailsFiComponent extends CampaignDetailsBase implements OnInit, OnDestroy {
  @Input() campaignDetails: CampaignDetails;
  @Input() commercialConfig: CommercialConfig;
  @Input() historyROC: HistoryROC;
  @Output() manuallyCPTUpdated: EventEmitter<any> = new EventEmitter();

  bookingLineDataSubscription: Subscription;

  /**
   * @description set readOnly
   * @memberof CampaignDetailsFiComponent
   */
  public readOnly = SystemFlags.readOnly;

  maskService: MaskService = new MaskService();

  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof CampaignDetailsFiComponent
   */
  currencyInputMask: NumberMaskModel;

  /**
   * @description store search page object
   * @type {Campaign}
   * @memberof CampaignDetailsFiComponent
   */
  searchPageObject: Campaign;

  campaignGroupTypeList = CampaignGroupTypeList;

  constructor(
    commercialService: CommercialService,
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    stateService: StateService
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService

    );

  }

  ngOnDestroy() {
    this.bookingLineDataSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.currencyInputMask = this.maskService.currencyInputMask({ max: 999999999999999999999, padFractionalZeros: true });

    super.init(this.campaignDetails);
    this.searchPageObject = this.stateService.getCampaign();
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    this.enableSubAgency();
    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);
    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe((value: any) => {
      if (value.rowIndex >= 0 && value.columnName) {
        this.calculateCPT(value);
      }
      this.onBookingLineChange();
    });

    this.setLookupConfig();
  }

  /**
   * @description calculate CPT from gross media value or pmp discount change
   * @author Kishan Patel
   * @param {*} value
   * @memberof CampaignDetailsUkComponent
   */
  calculateCPT(value) {
    if (value.columnName === 'grossMediaValue') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex,
        undefined, Number(this.campaignDetails.bookingList[value.rowIndex].grossMediaValue));
    } else if (value.columnName === 'pmpdiscount') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex);
    }
  }

  // Functions Related to Specialist//
  onSpecialistNameSelected(value: any): void {
    if (this.campaignDetails.specialist) {
      super.onSpecialistChange(value);
      this.manageGrossMediaValueWithoutIndex(); // This function has to be used in case if the booking grid is used;
    }
  }

  removeSelectedSpecialistName() {
    super.onSpecialistChange();
    this.manageGrossMediaValueWithoutIndex();
  }

  onSpecialistPercentageChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  // Functions related to Agencies//
  onAgencyNameSelected(value: any): void {
    if (this.campaignDetails.agency) {
      super.onAgencyChange(value);
      this.enableSubAgency();
      this.manageGrossMediaValueWithoutIndex();
    }
  }
  removeSelectedAgencyName(): void {
    super.onAgencyChange();
    this.enableSubAgency();
    this.manageGrossMediaValueWithoutIndex();
  }
  onAgencyPercentageChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  /**
   * @description update gross media value from grid update
   * @author Kishan Patel
   * @memberof CampaignDetailsUkComponent
   */
  onBookingLineChange() {
    this.campaignDetailsService.populateAgencyPercentage(this.campaignDetails);
    this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
  }

  assignedToChange() {
    this.campaignDetailsService.populateAgencyPercentage(this.campaignDetails);
    this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
    this.enableSubAgency();
    this.setActiveAssignedTo();
  }

  /**
   * @description on CPT value changes then update pmp discount in grid
   * @author Kishan Patel
   * @memberof CampaignDetailsUkComponent
   */
  onAverageCPTChanged() {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT); // SM-4776
    this.commercialService.notifyCampaignDetails(this.campaignDetails.bookingList);
    if (this.uiControl.enableUserDefinedCPMPricingCalculation) {
      this.manuallyCPTUpdated.emit();
    }
  }

}
