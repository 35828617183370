<app-sb-modal-wrapper
  [title]="initialConfig.userBundle['vp.text.campaignToSwap'] || 'Select Campaign to take into SWAP:'"
  [titleBackgroundColor]="'lightblue'" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <table class="table table-striped" aria-label="digital-swap">
    <thead>
      <tr>
        <th id="Reference">Campaign Reference</th>
        <th id="sot">SOT</th>
        <th id="range">Range</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let booking of resolveObject.bookingDetails; trackBy: trackByItem;"
        [ngClass]="{red: resolveObject.selectedSOT && booking.sot !== resolveObject.selectedSOT}">
        <td>
          <label class="text-left small" style="background: transparent;padding-left: 6px !important;"
            [for]="'bookingSelected-' + booking.campaignReference + booking.sot + booking.startDate + booking.endDate">
            <input type="checkbox" class="checkbox small" [(ngModel)]="booking.selected"
              (change)="bookingChange(booking)" [disabled]="(selectedBooking && selectedBooking !== (booking.campaignReference + booking.sot + booking.startDate + booking.endDate)) ||
              (resolveObject.selectedSOT && booking.sot !== resolveObject.selectedSOT)"
              [name]="'bookingSelected-' + booking.campaignReference + booking.sot + booking.startDate + booking.endDate"
              [id]="'bookingSelected-' + booking.campaignReference + booking.sot + booking.startDate + booking.endDate">
            <span class="checkbox-label">{{booking.campaignReference}}</span>
          </label>
        </td>
        <td>
          {{booking.sot | number : '2.2-2'}} %
        </td>
        <td>{{booking.startDate | date : displayDateFormat}} - {{booking.startDate | date : displayTimeFormat}} <br>
          {{booking.endDate | date : displayDateFormat}} - {{booking.endDate | date : displayTimeFormat}}</td>
      </tr>
    </tbody>
  </table>
</app-sb-modal-wrapper>
