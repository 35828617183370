import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StateService, LoaderService } from '../../geoplanner/src/app/core/services';
import { ObjectiveComponent } from '../../geoplanner/src/app/workspace/objective/objective.component';

@Injectable()
export class CanDeactivateObjectiveService implements CanDeactivate<ObjectiveComponent> {

  constructor(
    private stateService: StateService,
    private loaderService: LoaderService
  ) { }

  // @ts-ignore
  canDeactivate(component: ObjectiveComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean {
    if (this.loaderService.getBackButton()) {
      this.loaderService.setBackButton(false);
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);
      return false;
    }

    this.stateService.setObjectiveObject('filter', this.stateService.clone(component.filter));
    return component.beforeNavigate();
  }

}
