<app-sb-modal-wrapper [title]="userBundle['search.exclusion.proximityProhibition']"
  [titleBackgroundColor]="titleBackgroundColor" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <ng-template ngFor let-groupname [ngForOf]="proximityProhibitionGroup" [ngForTrackBy]="trackByProhibitionGroupName" let-i="index">
    <div class="control_group">
      <h5>{{groupname.prohibitionGroupName}}</h5>
    </div>
    <div class="control_group proximity-prohibation" *ngFor="let type of groupname.proximityProhibitionType; trackBy: trackByProhibitionTypeName;">
      <label class="label">{{type.prohibitionTypeName}}</label>
      <input class="textinput form_control" [(ngModel)]="type.distance" type="text"
        [imask]="maskService.currencyInputMask({ max: 999999999999999999999, scale: 4 })"
        [unmask]="maskService.unmask.typed">
      <!--{{type.distanceType}}-->
      <div class="btn-group">
        <input type="radio" name="proximity_radio_groups_{{groupname.prohibitionGroupId+type.prohibitionTypeId}}"
          [(ngModel)]="type.distanceType" value="0" autocomplete="off"
          id="1check{{groupname.prohibitionGroupId+type.prohibitionTypeId}}" (change)="onChangeDistanceType(type)">
        <label for="1check{{groupname.prohibitionGroupId+type.prohibitionTypeId}}"
          [ngClass]="type.distanceType == 0 ? 'active' : ''">{{userBundle['common.metres']}}</label>

        <input type="radio" name="proximity_radio_groups_{{groupname.prohibitionGroupId+type.prohibitionTypeId}}"
          [(ngModel)]="type.distanceType" value="1" autocomplete="off"
          id="2check{{groupname.prohibitionGroupId+type.prohibitionTypeId}}" (change)="onChangeDistanceType(type)">
        <label for="2check{{groupname.prohibitionGroupId+type.prohibitionTypeId}}"
          [ngClass]="type.distanceType == 1 ? 'active' : ''">{{userBundle['common.kilometres']}}</label>

        <input type="radio" name="proximity_radio_groups_{{groupname.prohibitionGroupId+type.prohibitionTypeId}}"
          [(ngModel)]="type.distanceType" value="2" autocomplete="off"
          id="3check{{groupname.prohibitionGroupId+type.prohibitionTypeId}}" (change)="onChangeDistanceType(type)">
        <label for="3check{{groupname.prohibitionGroupId+type.prohibitionTypeId}}"
          [ngClass]="type.distanceType == 2 ? 'active' : ''">{{userBundle['common.miles']}}</label>
      </div>
    </div>
  </ng-template>


</app-sb-modal-wrapper>
