import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataShareService } from '../../core/services';
import { UiControl, UserModel } from '../../models';
import { LogHelperService } from './../../core/services/log-helper.service';

@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.component.html'
})
export class ProposalComponent implements OnInit {
  /**
   * It will take object data as input to configure.
   */
  @Input() resolveObject;
  /**
   * It contains data for campaign.
   */
  uiControl: UiControl;
  public campaignSave: any;
  userModel: UserModel;
  /**
   * Constructor
   * @param activeModal Describes for bootstrap modal.
   * @param logHelper Service for log messages.
   */
  constructor(private dataShareService: DataShareService, private activeModal: NgbActiveModal, private logHelper: LogHelperService) { }

  /**
   * Function that loads initially.
   */
  ngOnInit() {
    this.resolveObject.CCPHideFinanceEnabled = false; // Temporary basis, remove it later on
    this.campaignSave = {
      campaignName: null,
      hideFinance: true
    };
    this.userModel = this.dataShareService.getUserModel();
    if (this.resolveObject.SelectedValue !== null) {
      this.campaignSave.campaignName = this.resolveObject.SelectedValue.campaignName;
    }
  }

  /**
   * To save the given data.
   */
  onSave() {
    this.logHelper.logSuccess(this.resolveObject.successMsg);
    this.activeModal.close();
  }

  /**
   * To close the opened modal.
   */
  onClose() {
    this.activeModal.dismiss();
  }
}
