import { Injectable } from '@angular/core';
import { CellValues, Filter } from '../../models';
import { BrickBaseService, StateService, CellAttributeService, HistoryStackService } from '../../core/services';
import { HistoryStackData } from '../../models/workspace/historyStackData';
import * as _ from 'lodash';
import { Brick } from '../brick-model';

export enum ObjectiveModeForEnum {
  workspace = 0,
  pcm = 1
}

@Injectable()
export class ObjectiveService {
  /**
   * @description Returns true if in edit mode
   * @memberof ObjectiveService
   */
  isEditMode = false;

  /**
   * @description Deviced obective mode is turned on for workspace/pcm
   * @type {number}
   * @memberof ObjectiveService
   */
  objectiveModeFor: number;

  constructor(
    private brickBaseService: BrickBaseService,
    private stateService: StateService,
    private cellAttributeService: CellAttributeService,
    public historyStackService: HistoryStackService,
  ) { }

  getSelectedForCollectionBrick(lookups, brickId) {
    const brick: Brick = this.stateService.getObjectiveObject('currentObjectiveData').brick;
    const filterObj = this.stateService.getWorkspaceFilterObj();
    const mainLocationRow = filterObj.getExistingRowIndex(brickId);
    let mainSelected = {};
    if (mainLocationRow >= 0) {
      const objectiveDataCellIndex = filterObj.rows[mainLocationRow].cells[brick.cellIndex] ? brick.cellIndex : brick.cellIndex - 1;
      const overlappingCellIndex = filterObj.rows[mainLocationRow].getOverlappingCell(objectiveDataCellIndex);
      mainSelected = filterObj.rows[mainLocationRow].cells[overlappingCellIndex === -1 ? objectiveDataCellIndex : overlappingCellIndex].selected;
      lookups = !_.isEmpty(mainSelected) ? _.cloneDeep(this.filterListBySelectedValues(lookups, brickId, mainSelected)) : lookups;
    }

    return {
      lookups,
      selected: mainSelected
    };
  }

  filterListBySelectedValues(list, brickId, selectedValues) {
    switch (brickId) {
      case this.brickBaseService.brickID.Location:
      case this.brickBaseService.brickID.Format:
      case this.brickBaseService.brickID.Environment:
        if (list.lookup) {
          list.lookup = list.lookup.filter((l) => {
            if (l.selectionIds) {
              l.selectionIds = l.selectionIds.filter(sId => selectedValues[sId]);
              return l.selectionIds.length > 0;
            }
            return selectedValues[l.selectionId];
          });
        } else {
          if (selectedValues.length > 0) {
            list = list.filter(l => selectedValues.filter(s => s.id === l.id).length > 0);
          }
        }
        break;
      case this.brickBaseService.brickID.Tag:
        const selectedTags = selectedValues.allTabSelection;
        const tagGroups = Object.keys(list);
        for (let index = tagGroups.length - 1; index >= 0; index--) {
          const tagGroupIndex = tagGroups[index];
          const tagGroup = list[tagGroupIndex];
          if (selectedTags[tagGroupIndex]) {
            tagGroup.tag = tagGroup.tag.filter(tag => selectedTags[tagGroupIndex].filter(f => f.tagId === tag.tagId).length);
          }

          if (!tagGroup.tag.length) {
            delete list[tagGroupIndex];
          }
        }
        break;
      case this.brickBaseService.brickID.PricingTag:
        const selectedNetworks = selectedValues.allTabSelection;
        list = list.map((networkGroup) => {
          if (!networkGroup) return networkGroup;

          return {
            ...networkGroup,
            network: networkGroup.network.filter(network => selectedNetworks.indexOf(network.networkId) > -1)
          };
        }).filter(networkGroup => networkGroup && networkGroup.network.length);
        break;

      default:
    }
    return list;
  }

  updateWorkspaceOnSave(objectiveFilter: Filter) {
    const currentObjectiveData: CellValues = this.stateService.getObjectiveObject('currentObjectiveData');
    currentObjectiveData.selectedValues = { objectives: objectiveFilter.rows };
    objectiveFilter.generateBrickRequestJSON();
    currentObjectiveData.requestJson = {};
    currentObjectiveData.requestJson[this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Objective]] = {
      bricsData: objectiveFilter.brickRequestJSON
    };

    currentObjectiveData.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Objective, currentObjectiveData.selectedValues);

    switch (this.objectiveModeFor) {
      case ObjectiveModeForEnum.workspace:
        const workspaceFilter = this.stateService.getWorkspaceFilterObj();
        this.historyStackService.pushInHistoryStack(new HistoryStackData(workspaceFilter));
        workspaceFilter.addBrick(currentObjectiveData, true, this.isEditMode);
        this.stateService.setWorkspaceObject('filter', this.stateService.clone(workspaceFilter));
        break;
      case ObjectiveModeForEnum.pcm:
        const pcmFilter = this.stateService.getPCMFilterObj();
        this.historyStackService.pushInHistoryStackPCM(new HistoryStackData(pcmFilter));
        pcmFilter.addBrick(currentObjectiveData, true, this.isEditMode);
        this.stateService.setPCMObject('filter', pcmFilter);
        break;
    }

    this.stateService.setObjectiveObject('currentObjectiveData', currentObjectiveData);
  }
}
