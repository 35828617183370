import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { IFilterAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-custom-filter-menu',
  templateUrl: './ag-custom-filter-menu.component.html',
  styleUrls: ['./ag-custom-filter-menu.component.css']
})
export class AgCustomFilterMenuComponent implements IFilterAngularComp {

  public params: any;
  public text = '';
  public filterType = 'text';
  public decimalRound: number = null;
  public sortOrder = '';
  private groupingField = '';

  @ViewChild('input', { read: ViewContainerRef }) public input;

  agInit(params: IFilterParams): void {
    this.params = params;
    this.groupingField = this.params.column.gridApi.gridCore.gridOptions.groupingField;
  }

  isFilterActive(): boolean {
    return this.text !== null && this.text !== undefined && this.text !== '';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    if (params.data.isParent && this.groupingField) {
      const childRows = this.params.context.componentParent.rowData.filter((row) => {
        return (!row.isParent && row[this.groupingField] === params.data[this.groupingField]);
      });
      let anypass = false;
      for (const childRow of childRows) {
        childRow.isFilterPass = this.filterFunc(childRow[this.params.column.colId]);
        if (childRow.isFilterPass) {
          anypass = true;
          // break;
        }
      }
      params.data.isFilterPass = anypass;
      return params.data.isFilterPass;
    } else {
      params.data.isFilterPass = this.filterFunc(params.data[this.params.column.colId]);
      return params.data.isFilterPass;
    }
  }

  filterFunc(actText): boolean {
    if (this.filterType === 'select') {
      return this.selectFilterFunc(actText);
    } else {
      return this.stringFilterFunc(actText);
    }
  }

  selectFilterFunc(actText): boolean {
    return (String(this.text) === String(actText));
  }

  stringFilterFunc(actText): boolean {
    let text = actText;
    if (text == null || typeof (text) === 'undefined') {
      text = '';
    }
    if (typeof (this.decimalRound) === 'number' && typeof (text) === 'number') {
      text = text.toFixed(this.decimalRound);
    }
    return this.text.toLowerCase()
      .split(' ')
      .every((filterWord) => {
        return text.toString().toLowerCase().indexOf(filterWord) >= 0;
      });
  }

  getModel(): any {
    return { value: this.text };
  }

  setModel(model: any): void {
    this.text = model ? model.value : '';
    this.filterType = model ? model.type : 'text';
    this.decimalRound = model ? model.decimalRound : null;
  }

  // noinspection JSMethodCanBeStatic
  componentMethod(message: string): void {
    alert(`Alert from PartialMatchFilterComponent ${message}`);
  }

  // @ts-ignore
  onFloatingFilterChanged(type, model): void {
    this.text = model ? model.value : '';
    this.filterType = model ? model.type : 'text';
    this.decimalRound = model ? model.decimalRound : null;
    this.params.filterChangedCallback();
  }

  onChange(newValue): void {
    if (this.text !== newValue) {
      this.text = newValue;
      this.params.filterChangedCallback();
    }
  }

  isColumnSorted(): boolean {
    this.sortOrder = '';

    const sortColumn = this.params.context.componentParent.sortObj.getSortColumn(this.params.column.colId);
    if (sortColumn) {
      this.sortOrder = sortColumn.order;
      return true;
    } else {
      return false;
    }
  }

  sortAscending() {
    this.sort('asc');
  }

  sortDescending() {
    this.sort('desc');
  }

  removeSort() {
    this.sort('');
  }

  private sort(order) {
    this.params.context.componentParent.sortData(this.params.column.getColId(), order);
    this.hideMenu();
  }

  hideColumn() {
    this.params.column.columnApi.setColumnVisible(this.params.column.getColId(), false);
    this.hideMenu();
  }

  pinLeft() {
    this.params.column.columnApi.setColumnPinned(this.params.column.getColId(), 'left');
    this.hideMenu();
  }

  pinRight() {
    this.params.column.columnApi.setColumnPinned(this.params.column.getColId(), 'right');
    this.hideMenu();
  }

  unpin() {
    this.params.column.columnApi.setColumnPinned(this.params.column.getColId(), '');
    this.hideMenu();
  }

  private hideMenu() {
    const menuEle: any = document.getElementsByClassName('ag-menu');
    for (const element of menuEle) {
      element.style.display = 'none';
    }
  }
}
