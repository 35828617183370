<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
  [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)" (saved)="onModalSaved()">
  <div class="modal_content" [ngClass]="{'readOnly' : readOnlyModal}">
    <div class="width_6">
      <div class="control_group">
        <input type="checkbox" [(ngModel)]="selectedTarget.allowDays" class="checkbox " id="days">
        <label for="days">{{userBundle['workspace.pcm.multiTarget.bric.allowDays']}} </label>
      </div>
      <div class="control_group">
        <input type="checkbox" [(ngModel)]="selectedTarget.allowDayParts" class="checkbox" id="dayparts">
        <label for="dayparts">{{userBundle['workspace.pcm.multiTarget.bric.allowDayParts']}}</label>
      </div>
      <div class="control_group">
        <input type="checkbox" [(ngModel)]="selectedTarget.allowHours" class="checkbox" id="hourpart">
        <label for="hourpart">{{userBundle['workspace.pcm.multiTarget.bric.allowHours']}}</label>
      </div>
      <div class="control_group">
        <input type="checkbox" [(ngModel)]="selectedTarget.allowSOT" class="checkbox" id="sot" (change)="allowSOTChange()">
        <label for="sot">{{userBundle['workspace.pcm.multiTarget.bric.allowSOT']}}</label>
      </div>
      <div class="control_group custom-sot">
        <input type="checkbox" [(ngModel)]="selectedTarget.allowCustomSOT" (change)="allowCustomSOTChange()" class="checkbox"
          id="allowCustomSOT" [disabled]="!selectedTarget.allowSOT">
        <label for="allowCustomSOT">{{userBundle['workspace.pcm.multiTarget.bric.allowCustomSOT']}}</label>
      </div>
    </div>

    <div class="control_group">
      <div class="full-width sot-slider" *ngIf="isShow">
        <ngx-slider [(value)]="selectedTarget.sotRangeMinVal" [(highValue)]="selectedTarget.sotRangeMaxVal"
          [options]="options"></ngx-slider>
      </div>
      <div class="left col-md-10">
        <label for="rbt-frame">{{userBundle['workspace.pcm.increment']}}</label>
        <input id="txt_increment" type="text" class="form_control" [(ngModel)]="selectedTarget.increment" appNumberMask
          [min]="1" [max]="100" [scale]="2" [defaultValue]="1" autocomplete="off"
          (change)="stepUpdated(selectedTarget.increment, true)" style="width: 66%" [readonly]="!selectedTarget.allowCustomSOT" />
        <span class="percentage-illumination" *ngIf="selectedTarget.increment">%</span>
      </div>
      <div class="left col-md-10">
        <label for="rbt-frame">{{userBundle['workspace.pcm.default']}}</label>
        <input id="txt_default" type="text" class="form_control" [(ngModel)]="selectedTarget.defaultSot" appNumberMask
          [min]="1" [max]="100" [scale]="2" [defaultValue]="1" autocomplete="off" style="width: 66%" [readonly]="!selectedTarget.allowCustomSOT"/>
        <span class="percentage-illumination" *ngIf="selectedTarget.defaultSot">%</span>
      </div>
    </div>
  </div>
</app-sb-modal-wrapper>
