import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Options } from '@angular-slider/ngx-slider';
import { BrickBaseService, CellAttributeService, DataShareService, LogHelperService } from '../../../core/services';
import { CellValues } from '../../../models';
import { MultiTarget } from '../../../models/multi-target.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-multi-target-pcm-modal',
  templateUrl: './multi-target-pcm-modal.component.html',
  styleUrls: ['./multi-target-pcm-modal.component.css']
})
export class MultiTargetPcmModalComponent implements OnInit {
  @Input() resolveObject: CellValues;

  selectedTarget: MultiTarget = new MultiTarget();

  userBundle = {};

  isShow = false;
  readOnlyModal = false;
  defaultOptions = {
    floor: 0,
    ceil: this.maxMultipleOf(this.selectedTarget.increment),
    step: this.selectedTarget.increment,
    showTicks: true,
    noSwitching: true,
    enforceStep: false,
    disabled: !this.selectedTarget.allowCustomSOT,
    translate: (value) => {
      return value.toFixed(2);
    }
  };
  public options: Options = _.cloneDeep(this.defaultOptions);

  constructor(
    private dataShareService: DataShareService,
    private brickBaseService: BrickBaseService,
    private logHelperService: LogHelperService,
    // private multiTargetService: MultiTargetService,
    private activeModal: NgbActiveModal,
    private cellAttributeService: CellAttributeService,
  ) { }

  init(selectedValue) {
    setTimeout(() => {
      this.setSliderOptions(selectedValue);
    }, 100);
  }

  ngOnInit() {
    const selectedValue = this.resolveObject.selectedValues ? this.resolveObject.selectedValues : null;
    this.init(selectedValue);
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.stepUpdated(this.selectedTarget.increment, true);
  }

  setSliderOptions(selectedVal: MultiTarget) {
    if (selectedVal) {
      this.stepUpdated(selectedVal.increment);
      this.selectedTarget = selectedVal;
    }
    setTimeout(() => {
      this.isShow = true;
      this.options = Object.assign({}, this.options, {
        ceil: this.maxMultipleOf(Number(this.selectedTarget.increment)),
        step: Number(this.selectedTarget.increment),
        disabled: !this.selectedTarget.allowCustomSOT
      });
    }, 300);
  }

  maxMultipleOf(no: number) {
    let maxMultiple = 0;
    for (let index = 1; index <= 100; index++) {
      if ((no * index) <= 100) {
        maxMultiple = no * index;
      }
    }
    return maxMultiple;
  }

  stepUpdated = (increment, updated = false, min = 0) => {
    if (updated) {
      this.selectedTarget.sotRangeMaxVal = this.maxMultipleOf(Number(increment));
      this.selectedTarget.sotRangeMinVal = min;
    }
    this.options = Object.assign({}, this.defaultOptions, {
      step: Number(increment),
      ceil: this.maxMultipleOf(Number(increment)),
      disabled: !this.selectedTarget.allowCustomSOT
    });
  }

  isValidSelection(): boolean {
    const isValid = true;
    if (!this.options.step || !this.selectedTarget.defaultSot) {
      this.logHelperService.logError(this.userBundle['sot.error.error1'] || 'Increment and Default both must be greater than zero!');
      return;
    }
    if (this.selectedTarget.defaultSot < this.selectedTarget.sotRangeMinVal ||
      this.selectedTarget.defaultSot > this.selectedTarget.sotRangeMaxVal) {
      this.logHelperService.logError(this.userBundle['sot.error.error2'] || 'Default value should be in between of selected range!');
      return;
    }
    if (this.selectedTarget.defaultSot % this.options.step !== 0) {
      this.logHelperService.logError(this.userBundle['common.error.invalidWorkspace'] || 'Default value shold be in steps!');
      return;
    }
    return isValid;
  }

  allowCustomSOTChange() {
    this.options = Object.assign({}, this.options, { disabled: !this.selectedTarget.allowCustomSOT });
    if (!this.selectedTarget.allowCustomSOT) {
      const defaultMultiTarget = new MultiTarget();
      this.selectedTarget.defaultSot = defaultMultiTarget.defaultSot;
      this.selectedTarget.increment = defaultMultiTarget.increment;
      this.selectedTarget.sotRangeMinVal = defaultMultiTarget.sotRangeMinVal;
      this.selectedTarget.sotRangeMaxVal = defaultMultiTarget.sotRangeMaxVal;
      this.options = Object.assign({}, this.options, {
        floor: this.defaultOptions.floor,
        ceil: this.maxMultipleOf(Number(this.selectedTarget.increment)),
        step: Number(this.selectedTarget.increment),
        showTicks: true,
        translate: (value) => {
          return value.toFixed(2);
        }
      });
    }
  }

  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }

  /**
   * @description callback function - called during save click
   * @author Nishit Parekh
   * @param {*} event
   * @memberof MultiTargetModalComponent
   */
  onModalSaved() {
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    if (this.isValidSelection()) {
      values.selectedValues = this.selectedTarget;
      values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.MultiTarget, values.selectedValues);
      values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.MultiTarget, values.selectedValues);
      values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.MultiTarget, values.selectedValues);
      this.activeModal.close(values);
    }
  }

  allowSOTChange() {
    if (!this.selectedTarget.allowSOT) {
      this.selectedTarget.allowCustomSOT = false;
      this.allowCustomSOTChange();
    }
  }

}
