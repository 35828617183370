import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '../cart.service';
import { LogHelperService } from '../../core/services/log-helper.service';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { CartDetailComponent } from '../cart-detail/cart-detail.component';
import { AgCartListItemComponent } from '../ag-cart-list-item/ag-cart-list-item.component';
import { GoogleAnalyticsEvents, GoogleAnalyticsEventCategory, EventActionsCart } from '../../GoogleAnalytics/GoogleAnalyticsEvent';
import { AppNameEnum } from '../../core/enum';
import { DataShareService } from '../../core/services/data-share.service';
import { GridOptions, ColDef } from 'ag-grid-community';
import { InitialConfigModel, FrameModel, ResponseModel, SaveFrameResponseModel, FurnitureModel, SystemFlags } from '../../models/index';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.css']
})
export class CartSummaryComponent implements OnInit, OnDestroy {
  /**
   * application initial config file
   * @type {*}@memberof CartSummaryComponent
   */
  @Input() initialConfig: InitialConfigModel;

  /**
   * summary frame item click event
   * @type {*}@memberof CartSummaryComponent
   */
  @Output() cellClick: EventEmitter<any> = new EventEmitter<any>();

  /**
   * summary frame item click event
   * @type {*}@memberof CartSummaryComponent
   */
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

  /**
   * furniture folio gallery img icon click event
   * @type {*}@memberof CartSummaryComponent
   */
  @Output() furnitureIconClick: EventEmitter<FurnitureModel> = new EventEmitter<FurnitureModel>();

  /**
   * on close button click out put event
   * @type {*}@memberof CartSummaryComponent
   */
  @Output() closeCartSummary: EventEmitter<void> = new EventEmitter<void>();

  /**
   * on cart detail popup close output event
   * @type {*}@memberof CartSummaryComponent
   */
  @Output() cartDetailClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Ag-Grid Options Object
   */
  gridOptionsCart: GridOptions;

  /**
   * cart data subscriber object
   */
  cartDataSubscriber: Subscription;

  cartDataTimer: any;

  /**
   * ngbModal options
   */
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'sm'
  };

  /**
   * Cart simple view filter text
   */
  cartFilterText = '';

  /**
   * selected frame count in cart
   */
  cartSelectedFrameCount = 0;

  /**
   * total frame count in cart
   */
  cartTotalFrameCount = 0;

  /**
   * cart data subscriber object
   */
  allCartFrameSelected = false;

  systemFlags = SystemFlags;
  constructor(
    private cartService: CartService,
    private logHelper: LogHelperService,
    private modalService: NgbModal,
    private dataShareService: DataShareService
  ) { }

  ngOnInit() {
    // ag-grid config for Cart sidebar
    this.gridOptionsCart = {
      suppressMovableColumns: true,
      suppressCellSelection: true,
      context: {
        componentParent: this
      },
      defaultColDef: {
        filter: true,
        resizable: false
      },
      isExternalFilterPresent: () => {
        return true;
      },
      doesExternalFilterPass: (node) => {
        if (this.cartFilterText && this.cartFilterText !== '') {
          return node.data.frameName.toLowerCase().indexOf(this.cartFilterText.toLowerCase()) > -1;
        } else {
          return true;
        }
      },
      onRowDataChanged: () => {
        this.cartSummaryChange();
      },
      onFilterChanged: () => {
        this.cartSummaryChange();
      },
      headerHeight: 0,
      getRowHeight: () => {
        return 92;
      },
      columnDefs: this.getCartColumnDefs(),
      onCellClicked: this.onCartCellClicked,
      getRowClass: (param) => {
        if (param.node.data.selected_cart) {
          return 'cart_selected_cell';
        }
      },
      getRowStyle: (param) => {
        if (param.node.data.isDeletable) {
          return { cursor: 'pointer' };
        } else {
          return { cursor: 'not-allowed' };
        }
      },
      onGridReady: () => {
        // set grid readonly if campaign is readonly
        if (SystemFlags.readOnlyWorkspace && !SystemFlags.splitable) {
          const eleGridBody: any =
            document.querySelector('.cart_summary_grid .ag-body-viewport .ag-center-cols-viewport .ag-center-cols-container');
          eleGridBody.className = `${eleGridBody.className}  readOnly`;
        }
      }
    } as GridOptions;

    this.cartDataSubscriber = this.cartService.cartData$.subscribe(this.cartDataSubscription);
  }

  cartSummaryChange() {
    const cartDataAfterFilter = this.getCartDataAfterFilter();
    this.cartSelectedFrameCount = this.cartService.getSelectedFrameCount(cartDataAfterFilter);
    this.cartTotalFrameCount = cartDataAfterFilter.length;
    this.setAllCartFrameSelected();
  }

  /**
   * Angular lifecycle hook - will be Called once  just before Angular destroys the directive/component
   */
  ngOnDestroy() {
    this.cartDataSubscriber.unsubscribe();
  }

  /**
   * Get ag-grid column config object for cart grid
   * @returns
   * @memberof CartSummaryComponent
   */
  getCartColumnDefs(): ColDef[] {
    return [
      {
        headerName: this.initialConfig.userBundle['geoplanner.text.action'] || 'Action',
        width: 340,
        field: 'frameName',
        cellRendererFramework: AgCartListItemComponent
      }
    ];
  }

  /**
   * cart data subscription method
   * @memberof CartSummaryComponent
   */
  cartDataSubscription = (cartData: FrameModel[]): void => {
    if (this.gridOptionsCart.api) {
      clearTimeout(this.cartDataTimer);
      this.gridOptionsCart.api.setRowData(cartData);
    } else {
      this.cartDataTimer = setTimeout(() => {
        this.cartDataSubscription(cartData);
      }, 50);
    }
  }

  /**
   * close cart summary event
   * @memberof CartSummaryComponent
   */
  closeSummary() {
    this.closeCartSummary.emit();
  }

  /**
   * on furniture folio gallery image icon click event
   * @param {FurnitureModel} furniture
   * @memberof CartSummaryComponent
   */
  onShowFurnitureImages(furniture: FurnitureModel): void {
    this.furnitureIconClick.emit(furniture);
  }

  /**
   * open Cart Detail modal popup
   * @memberof GeoMapComponent
   */
  openCartDetail(): void {
    const modalOptions: any = {
      backdrop: this.ngbModalOptions.backdrop,
      keyboard: this.ngbModalOptions.keyboard,
      size: 'md'
    };
    const modalRef = this.modalService.open(CartDetailComponent, modalOptions);
    modalRef.result
      .then((isDataChanged) => {
        this.cartDetailClose.emit(isDataChanged);
      }, (reason) => {
        console.log(reason);
      }).catch((isDataChanged) => {
        this.cartDetailClose.emit(isDataChanged);
      });
  }

  /** trigger when changing text in search box */
  onCartFilterChange(): void {
    this.gridOptionsCart.api.onFilterChanged();
  }

  /**
   * get cart data after filter applied
   */
  getCartDataAfterFilter(): FrameModel[] {
    const cartDataAfterFilter: FrameModel[] = [];
    if (this.gridOptionsCart.api) {
      this.gridOptionsCart.api.forEachNodeAfterFilter((node) => {
        cartDataAfterFilter.push(node.data);
      });
    }
    return cartDataAfterFilter;
  }

  /**
   * click event - trigger when clicking on select all checkbox
   */
  onSelectAllCartFrameClick(): void {
    const cartDataAfterFilter: FrameModel[] = this.getCartDataAfterFilter();
    // this.cartService.cartData.map((frame) => {
    cartDataAfterFilter.forEach((frame: FrameModel) => {
      if (frame.isDeletable) {
        frame.selected_cart = this.allCartFrameSelected;
      }
    });
    this.gridOptionsCart.api.redrawRows();
    this.cartSelectedFrameCount = this.cartService.getSelectedFrameCount(cartDataAfterFilter);
    this.cartTotalFrameCount = cartDataAfterFilter.length;
  }

  /**
   * set all frame selected in cart or not
   */
  setAllCartFrameSelected(): void {
    const cartDataAfterFilter: FrameModel[] = this.getCartDataAfterFilter();
    const deletableFramesData: FrameModel[] = cartDataAfterFilter.filter((frame: FrameModel) => {
      return frame.isDeletable;
    });

    if (deletableFramesData.length > 0) {
      this.allCartFrameSelected = this.cartSelectedFrameCount === deletableFramesData.length;
    } else {
      this.allCartFrameSelected = false;
    }
  }

  /**
   * cart summary cell click event
   *
   * @memberof CartSummaryComponent
   */
  onCartCellClicked = (param) => {
    if (!param.node.data.isDeletable) {
      return;
    }
    if (param.node.data.visualUnitId) {
      this.cartService.cartData.forEach((frame: FrameModel) => {
        if (frame.visualUnitId === param.node.data.visualUnitId
          && frame.startDate === param.node.data.startDate
          && frame.endDate === param.node.data.endDate) {
          frame.selected_cart = !frame.selected_cart;
          const tempParam = {
            node: {
              data: _.clone(frame)
            }
          };
          this.cellClick.emit(tempParam);
        }
      });
    } else {
      param.node.data.selected_cart = !param.node.data.selected_cart;
      this.cellClick.emit(param);
    }
    param.api.redrawRows();
    const cartDataAfterFilter = this.getCartDataAfterFilter();
    this.cartSelectedFrameCount = this.cartService.getSelectedFrameCount(cartDataAfterFilter);
    this.cartTotalFrameCount = cartDataAfterFilter.length;
    this.setAllCartFrameSelected();
  }

  /**
   * Delete selected frame from cart
   * @param {any} extendedCodeID
   * @memberof CartSummaryComponent
   */
  deleteFrameFromCart(frame?: FrameModel) {
    const cartDataAfterFilter: FrameModel[] = this.getCartDataAfterFilter();
    if (this.cartService.getSelectedFrameCount(cartDataAfterFilter) === 0) {
      const saveMsg = this.initialConfig.userBundle['geoplanner.error.selectframes'] || 'Please select frames to delete.';
      this.logHelper.logError(saveMsg);
      return;
    }

    if ((this.dataShareService.appName === AppNameEnum.geomapper && SystemFlags.getBasketDataForGP)
      || (this.dataShareService.appName === AppNameEnum.visualplanner && SystemFlags.getBasketDataForVP)) {
      const modalOptions: any = {
        backdrop: this.ngbModalOptions.backdrop,
        keyboard: this.ngbModalOptions.keyboard,
      };
      const modalRef = this.modalService.open(ConfirmationComponent, modalOptions);
      modalRef.componentInstance.resolveObject = {
        content: this.initialConfig.userBundle['geoplanner.confirm.redirection'],
        title: this.initialConfig.userBundle['result.confirmation.label'],
        ok: this.initialConfig.userBundle['common.ok'],
      };
      modalRef.result.then(() => {
        this.deleteFrame(frame);
        if (this.dataShareService.appName === AppNameEnum.visualplanner) {
          SystemFlags.getBasketDataForVP = false;
        } else {
          SystemFlags.getBasketDataForGP = false;
        }
      }, (reason) => {
        console.log(reason);
      }).catch(() => {
        // nothing to be done on close
      });
    } else {
      this.deleteFrame(frame);
    }
  }

  deleteFrame(frame?: FrameModel) {
    const tempCartData: FrameModel[] = _.cloneDeep(this.cartService.cartData);
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Cart, EventActionsCart.DeleteFrame, 'Delete frame from cart');
    if (frame) {
      this.cartService.removeFrame(frame.frameId, frame.startDate, frame.endDate, false);
    } else {
      const cartDataAfterFilter: FrameModel[] = this.getCartDataAfterFilter();
      for (const filter of cartDataAfterFilter) {
        if (filter.selected_cart) {
          this.cartService.removeFrame(filter.frameId, filter.startDate,
            filter.endDate, false);
        }
      }
    }
    this.cartService.saveFrame().subscribe((res: ResponseModel<SaveFrameResponseModel>) => {
      if (res.status === 'OK') {
        this.delete.emit();
        const saveMsg = this.initialConfig.userBundle['geoplanner.success.deleteframes'] || 'Frames deleted successfully.';
        this.logHelper.logSuccess(saveMsg);
      } else if (res.status === 'KO') {
        this.cartService.setCartData(_.cloneDeep(tempCartData));
        this.logHelper.logError(res.message);
      }
    }, (err) => {
      this.cartService.setCartData(_.cloneDeep(tempCartData));
      this.logHelper.logError(err.message);
    });
  }

}
