declare let saveAs; // FileSaver.js
declare const XLSX: any;

export interface ExcelCell {
  text: string | number;
  bgColor?: string;
  fontColor?: string;
  isFontBold?: boolean;
  borderColor?: string;
}

export interface ExcelColumn {
  width: number;
}

class Workbook {
  // tslint:disable-next-line:variable-name
  SheetNames = [];
  // tslint:disable-next-line:variable-name
  Sheets = {};
}

export class ExportToExcel {
  private sheetData: ExcelCell[][];
  private excelColumn: ExcelColumn[];

  /**
   * Download Excel with provided sheet data and column config
   * @param {string} sheetName - name of the work sheet
   * @param {ExcelCell[][]} sheetData - data to render on work sheet
   * @param {ExcelColumn[]} excelColumn - excel column config
   * @memberof ExportToExcel
   */
  public download(sheetName: string, sheetData: ExcelCell[][], excelColumn: ExcelColumn[]) {
    this.sheetData = sheetData;
    this.excelColumn = excelColumn;
    const fileName = `${sheetName.split(' ').join('')}.xlsx`;
    const wb = new Workbook();
    const ws = this.prepareWorkSheet();

    /* add worksheet to workbook */
    wb.SheetNames.push(sheetName);
    wb.Sheets[sheetName] = ws;
    const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

    saveAs(new Blob([this.stringToArrayBuffer(wbout)], { type: 'application/octet-stream' }), fileName);
  }

  /**
   * Convert string to buffer array
   * @private
   * @param {any} str - string to convert
   * @returns {ArrayBuffer}
   * @memberof ExportToExcel
   */
  private stringToArrayBuffer(str): ArrayBuffer {
    const buf = new ArrayBuffer(str.length);
    const view = new Uint8Array(buf);
    // tslint:disable-next-line:no-bitwise
    for (let i = 0; i !== str.length; ++i) { view[i] = str.charCodeAt(i) & 0xFF; }
    return buf;
  }

  /**
   * Prepare worksheet based on sheet data provided
   * @private
   * @returns
   * @memberof ExportToExcel
   */
  private prepareWorkSheet() {
    const range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
    const ws = this.prepareCell(range);
    if (range.s.c < 10000000) { ws['!ref'] = XLSX.utils.encode_range(range); }

    ws['!cols'] = this.getColumnWidth();
    return ws;
  }

  /**
   * Prepare cell based on sheet data provided
   * @param range Range of worksheet
   */
  private prepareCell(range) {
    const ws = {};
    for (let R = 0; R !== this.sheetData.length; ++R) {
      for (let C = 0; C !== this.sheetData[R].length; ++C) {
        if (range.s.r > R) { range.s.r = R; }
        if (range.s.c > C) { range.s.c = C; }
        if (range.e.r < R) { range.e.r = R; }
        if (range.e.c < C) { range.e.c = C; }
        const cell: any = this.getSheetCell(this.sheetData[R][C].text);

        this.styleSheetCell(cell, this.sheetData[R][C]);

        const cellRef = XLSX.utils.encode_cell({ c: C, r: R });
        ws[cellRef] = cell;
      }
    }
    return ws;
  }

  /**
   * get the woek sheet cell with type formatting
   * @private
   * @param {*} value - value to set in cell
   * @returns
   * @memberof ExportToExcel
   */
  private getSheetCell(value: any) {
    const val = value == null ? '' : value;
    const cell: any = { v: val };

    if (typeof cell.v === 'number') {
      cell.t = 'n';
    } else if (typeof cell.v === 'boolean') {
      cell.t = 'b';
    } else {
      cell.t = 's';
    }

    return cell;
  }

  /**
   * style worksheet cell
   * @private
   * @param {*} cell - worksheet cell object
   * @param {ExcelCell} cellOption - cell style options
   * @memberof ExportToExcel
   */
  private styleSheetCell(cell: any, cellOption: ExcelCell) {
    const bgColor = cellOption.bgColor ? cellOption.bgColor.replace('#', 'FF') : 'FFFFFFFF';
    const fontColor = cellOption.fontColor ? cellOption.fontColor.replace('#', 'FF') : 'FF000000';
    const borderColor = cellOption.borderColor ? cellOption.borderColor.replace('#', 'FF') : 'FFFFFFFF';
    const isFontBold = cellOption.isFontBold ? cellOption.isFontBold : false;
    cell.s = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: bgColor }
      },
      font: {
        color: { rgb: fontColor },
        bold: isFontBold,
        sz: '11'
      },
      border: {
        top: { style: 'thin', color: { rgb: borderColor } },
        left: { style: 'thin', color: { rgb: borderColor } },
        bottom: { style: 'thin', color: { rgb: borderColor } },
        right: { style: 'thin', color: { rgb: borderColor } }
      },
      alignment: {
        horizontal: 'center'
      }
    };
  }

  /**
   * Set column width
   * @private
   * @returns
   * @memberof ExportToExcel
   */
  private getColumnWidth() {
    const wscols = [];
    for (const column of this.excelColumn) {
      wscols.push({ wch: column.width });
    }
    return wscols;
  }
}
