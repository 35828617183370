<app-sb-modal-wrapper [title]="userBundle['workspace.split.heading']" [titleBackgroundColor]="titleColor" (closed)="onModalClosed($event)"
    (saved)="onModalSaved($event)">
    <!-- {{InitialConfig.userBundle['workspace.incharge.bric.range.startDate']}} -->
    <div class="control_group">
        <label for="">{{userBundle['workspace.split.selectedDate']}}</label>
        <!-- <input type="text"  class="form_control" name="startdate" #startDate [ngModel]="campaignSplitModal.startDate | date:displayDateFormat"
            ui-date="dateOptionsFrom" readonly> -->
        <input type="text" class="form_control" name="startdate" #startDate="ngbDatepicker" [markDisabled]="markDayDisabled"
            [(ngModel)]="campaignSplitModal.startDate" [dayTemplate]="startDayTemplate" [minDate]="minStartDate" [maxDate]="maxStartDate"
            ngbDatepicker [outsideDays]="'collapsed'" (click)="openDatePicker($event, startDate)" (document:click)="closeDatePickerOutsideClick($event, startDate)" readonly>
    </div>  
 
</app-sb-modal-wrapper>
<ng-template #startDayTemplate let-date="date" let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled">
    <span class="day-container" [class.hidden]="date.month !== currentMonth" [class.text-muted]="getStartDayProp(date, disabled, 'isDisabled')">
      <div class="custom-day" 
        [class.today]="getStartDayProp(date, disabled, 'isToday')"
        [class.weekend]="getStartDayProp(date, disabled, 'isWeekday')"
        [class.selected]="selected"
      >
        {{ date.day }}
    </div>
    </span>
</ng-template>