<div class="contentarea">
    <dl class="accordion animated bounceInDown content">
        <dt (click)="hideDiscountGrid = !hideDiscountGrid">
            <span class="editable-title">
                <span class="text discount-header">{{initialConfig.userBundle['commercial.tabTitle.discountGrid']}}
                </span>
            </span>
            <a href="javascript:void(0)" [ngClass]="{'down': !hideDiscountGrid}" class="title">
                <span class="right">
                    <span class="icon_wrapper">
                        <span class="icons animated"></span>
                    </span>
                </span>
            </a>
        </dt>
        <dd [ngClass]="{'down' : !hideDiscountGrid}">
            <app-discount-grid-us *ngIf="commercialData?.discounts && commercialData.discounts.length"
                [commercialConfig]="commercialConfig" [gridData]="gridData">
            </app-discount-grid-us>
        </dd>
    </dl>

    <dl class="accordion animated bounceInDown content">
        <dt (click)="hideCampaignDetails = !hideCampaignDetails">
            <span class="editable-title">
                <span class="text">{{initialConfig.userBundle['commercial.tabTitle.campaignDetails']}}
                    &nbsp;-&nbsp; </span>
                <span class="text allow-copy"> {{commercialData?.campaignReference}}
                </span>
            </span>
            <a href="javascript:void(0)" [ngClass]="{'down': !hideCampaignDetails}" class="title">
                <span class="right">
                    <span class="text">{{commercialData?.bookingStatusName}}</span>
                    <span class="icon_wrapper">
                        <span class="icons animated"></span>
                    </span>
                </span>
            </a>
        </dt>
        <dd [ngClass]="{'readOnly' : systemFlags.readOnly, 'down' : !hideCampaignDetails}">
            <app-campaign-details-us *ngIf="commercialData" [commercialConfig]="commercialConfig"
                [bookingStatusId]="bookingStatusId" [campaignDetails]="campaignDetails" [historyROC]="historyROC"
                (calculateCPTEvent)="calculateCPT()" (manuallyCPTUpdated)="manuallyCPTUpdated()"></app-campaign-details-us>
        </dd>
    </dl>

    <dl class="accordion animated bounceInDown content">
        <dt (click)="hideBookingDetails = !hideBookingDetails">
            <span class="editable-title">
                <span class="text">{{initialConfig?.userBundle['commercial.tabTitle.bookingDetails']}} </span>
            </span>
            <a href="javascript:void(0)" class="title" [ngClass]="!hideBookingDetails?'down':''">
                <span class="right">
                    <span class="icon_wrapper">
                        <span class="icons animated"></span>
                    </span>
                </span>
            </a>
        </dt>
        <dd [ngClass]="{'down' : !hideBookingDetails}">
            <app-booking-grid-uk *ngIf="commercialData" [commercialConfig]="commercialConfig"
                [bookingList]="commercialData.bookingList" [isAdminPersonMandatory]="false">
            </app-booking-grid-uk>
        </dd>
    </dl>
    <app-commercial-buttons *ngIf="commercialData" [commercialConfig]="commercialConfig" [campaignDetails]="campaignDetails"
        [paperBookingNotAllowed]="paperBookingNotAllowed" [validateFn]="validateCommercialCampaign"
        [populateRequestJson]="createRequestJSONForCommercial" (bookCampaign)="bookCommercialCampaign($event)"
        (updateCampaign)="onUpdateClicked($event)">
    </app-commercial-buttons>
</div>
