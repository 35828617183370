import { AfterViewInit, Component, ViewChild, HostListener } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerHelper } from '../../../../../core/components/ngb-datepicker/ngb-datepicker-helper';

@Component({
  selector: 'app-ag-date-cell-editor',
  templateUrl: './ag-date-cell-editor.component.html',
  styleUrls: ['./ag-date-cell-editor.component.css']
})
export class AgDateCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  /**
   * Ag-grid cell param object
   */
  private params: any;
  /**
   * whether to cancel cell editing before it start
   */
  private cancelBeforeStart = false;
  /**
   * cell value
   */
  public value: NgbDateStruct;
  /**
   * min date allowed in calendar
   */
  public minDate: NgbDateStruct;
  /**
   * max date allowed in calendar
   */
  public maxDate: NgbDateStruct;
  public cellDisable = false;
  dayObjCache = {};
  isDisableAllDays = false;
  /**
   * html input element
   */
  @ViewChild('input') public input: NgbInputDatepicker;

  public isParent: boolean;

  /**
   * Ag-grid cell init method
   * @param params - cell param object
   */
  agInit(params: any): void {
    this.params = params;
    let minDate;
    let maxDate;
    this.isParent = this.params.node.data.isParent;
    let value;
    value = typeof this.params.value === 'function' ? this.params.value(this.params.node.data) : this.params.value;

    if (value) {
      value = new Date(value);
      this.value = NgbDatepickerHelper.convertDateToDateStruct(value);
    }
    if (this.params.minDate) {
      minDate = this.params.minDate(this.params);
      if (minDate) {
        this.minDate = NgbDatepickerHelper.convertDateToDateStruct(minDate);
      }
    }

    if (this.params.maxDate) {
      maxDate = this.params.maxDate(this.params);
      if (maxDate) {
        this.maxDate = NgbDatepickerHelper.convertDateToDateStruct(maxDate);
      }
    }
    if (minDate && maxDate && minDate > maxDate) {
      this.maxDate = this.minDate;
      this.isDisableAllDays = true;
    }
    this.cancelBeforeStart = this.isParent || (this.params.node.rowPinned === 'bottom' && this.params.rowIndex === 1);
    if (this.cancelBeforeStart === false && this.params.cellEditable) {
      this.cancelBeforeStart = !this.params.cellEditable(this.params.node.data);
    }

    if (this.params.cellDisable) {
      this.cellDisable = this.params.cellDisable(this.params.node.data);
    }
  }

  /**
   * ag-grid get cell value method
   */
  getValue(): any {
    return this.params.node.data[this.params.column.colId];
  }

  markDayDisabled = (date: NgbDateStruct): boolean => {
    const daystring = this.cacheStartDateObj(date, this.isDisableAllDays);
    return this.dayObjCache[daystring].isDisabled;
  }

  cacheStartDateObj(date: NgbDateStruct, disabled: boolean): string {
    const daystring = NgbDatepickerHelper.getDateString(date);
    if (!this.dayObjCache[daystring]) {
      this.dayObjCache[daystring] = this.prepareStartDayObj(date, disabled);
    }
    return daystring;
  }

  /**
   * ag-grid cell cancel edit before start method
   */
  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
    return false;
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  /**
   * Angular after view init life cycle hook
   */
  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.cellDisable) {
        this.input.open();
      }
    }, 100);
  }

  /**
   * open start/end date datepicker on click
   * @param $event click event object
   * @param datePicker start/end date picker object
   */
  openDatePicker($event: Event, datePicker: NgbInputDatepicker): void {
    if (!this.cellDisable) {
      $event.preventDefault();
      $event.stopPropagation();
      datePicker.toggle();
    }
  }

  /**
   * @description get the specific dates property
   * @author Alkesh Shah
   * @param {NgbDateStruct} date current day
   * @param {string} key property key to retrive
   * @returns {boolean}
   * @memberof AgDateCellEditorComponent
   */
  getStartDayProp(date: NgbDateStruct, disabled: boolean, key: string): boolean {
    const obj = {
      isToday: false,
      isDisabled: false,
      isWeekday: false
    };

    const today = new Date();
    if (disabled) {
      obj.isDisabled = true;
    } else if (NgbDatepickerHelper.isEqual(today, date)) {
      obj.isToday = true;
    }

    return obj[key];
  }

  /**
   * called when date selection change
   * @param dateText - selected date text
   * @param inst - calendar instance
   */
  onDateSelect(inst: NgbDateStruct) {
    const newDate = NgbDatepickerHelper.convertDateStructToDate(inst);
    if (this.params.onDateSelect) {
      this.params.onDateSelect(this.params, newDate);
    }
    this.params.api.stopEditing();
  }

  clearDate() {
    this.value = null;
    if (this.params.onDateSelect) {
      this.params.onDateSelect(this.params, null);
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick($event) {
    // on clear button click do nothing
    if ($event.target && ($event.target.className === 'fa fa-remove' || $event.target.className === 'clearIcon')) {
      return;
    }

    if ($event.target.offsetParent == null || $event.target.offsetParent.nodeName !== 'NGB-DATEPICKER') {
      this.params.api.stopEditing();
    }
  }
  prepareStartDayObj(date: NgbDateStruct, disabled: boolean) {
    const obj = {
      isToday: false,
      isDisabled: false,
      isWeekday: false
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selecteddaye: any = NgbDatepickerHelper.convertDateStructToDate(date);
    if (disabled) {
      obj.isDisabled = true;
    } else if (NgbDatepickerHelper.isEqual(today, selecteddaye)) {
      obj.isToday = true;
    }
    return obj;
  }
}
