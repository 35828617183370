<div class="sort_by_popup" [ngClass]="{'right350px': applyRightMargin}">
  <ul dnd-sortable-container [sortableData]="sortableItems">
    <li *ngFor="let item of sortableItems; trackBy: trackByIndex; let i = index" dnd-sortable (onDragEnd)="refreshConcertinaGrid()" [sortableIndex]="i">
      <span>{{item['displayText']}}</span>
      <div class="checkboxs_switch">
        <input id="column_{{i}}" type="checkbox" name="check" (click)="refreshConcertinaGrid()" [(ngModel)]="item['value']">
        <label for="column_{{i}}">
          <span class="head"></span>
        </label>
      </div>
    </li>
  </ul>
</div>
