import { CellBaseModal } from './cell-base';
import { PoiAttribute, PoiType } from './../initial-config.model';
import { DistanceUnit, ProximityTypeSelection } from '../../core/enum';

export class ProximityWindow extends CellBaseModal {
  selectedPattern: object = {}; // TODO: Need to define type
}

export class ProximityProhibitionType {
  distance: number;
  distanceType: number;
  distancetypedisplay: number;
  distancevaluedisplay: number;
  olddistanceType: number;
  prohibitionTypeId: number;
  prohibitionTypeName: string;
}

export class ProximityProhibitionGroup {
  prohibitionGroupId: number;
  prohibitionGroupName: string;
  proximityProhibitionType: ProximityProhibitionType[];
}

export class ProximityData {
  public file?: any = null;
  public pointsFile?: any = null;
  public typeSelection: number = ProximityTypeSelection.PostCode;
  public fileName = 'Upload File';
  public pointsFileName = 'Upload File';
  public postcodeSelection: PostcodeSelection = new PostcodeSelection();
  public points: PointsSelection = new PointsSelection();
  public pointOfInterest: PointOfInterest = new PointOfInterest();
}

export class PostcodeSelection {
  public file?: any = null;
  public listUploadSelection = false;
  public listUpload: any[] = [];
  public selectedModelValues: any[] = [];
  public distance: ProximityDistance = new ProximityDistance();
  public disablelistupload: string | boolean = 'true';
  public include: string | boolean = 'true';
  public userSelectionId: number = null;
}

export class PointsSelection {
  public file?: any = null;
  public listUploadSelection = false;
  public listUpload: any[] = [];
  public selectedModelValues: any[] = [];
  public distance: ProximityDistance = new ProximityDistance();
  public disablelistupload: string | boolean = 'true';
  public include: string | boolean = 'true';
  public userSelectionId: number = null;
  public latitude: string;
  public longitude: string;
}

export class POIAttributeTreeData {
  id: number;
  name: string;
}

export class POIAttributes {
  poiAttributeId: number;
  poi: number[];
  poiRelative?: number[];
}

export class PointOfInterest {
  public selectedModelValues: any[] = [];
  public radioButtons = {
    selection: null,
    disabled: true,
  };
  public distance: ProximityDistance = new ProximityDistance();
  public include: boolean | string = 'true';
  public userSelectionId: number = null;
  public datasource: PoiType[] = [];
  public selectedDatasource: PoiType = new PoiType();
  public selectedDatasourceId: number = null;
  public attributeData: PoiAttribute[] = [];
  public selectedAttribute: PoiAttribute = new PoiAttribute();
  public selectedAttributeId: string | number = null;
  public attributeTreeData: POIAttributeTreeData[] = [];
  public selectedAttributeTree: POIAttributeTreeData = new POIAttributeTreeData();
  public selectedAttributeTreeId: number = 0;
  public poiAttributes: POIAttributes[];
}

export class ProximityDistance {
  public distancevalue: number = null;
  public distanceType: string = DistanceUnit.Meters;
  public olddistanceType: string = DistanceUnit.Meters;
}
