import { Injectable } from '@angular/core';
import { ResultComponent } from '../../geoplanner/src/app/result/result.component';
import { CanDeactivate } from '@angular/router';
import { LoaderService } from '../../geoplanner/src/app/core/services';

@Injectable()
export class CanDeactivateResultService implements CanDeactivate<ResultComponent> {

  constructor(private loaderService: LoaderService) { }

  // @ts-ignore
  canDeactivate(component: ResultComponent): boolean { // tslint:disable-line

    if (this.loaderService.getBackButton()) {
      this.loaderService.setBackButton(false);
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);
      return false;
    }
    return true;
  }
}
