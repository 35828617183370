import { Component, OnInit, Input } from '@angular/core';
import { DataShareService } from '../../../core/services/data-share.service';
import { InitialConfigModel } from '../../../models/initial-config.model';
import { StateService } from '../../../core/services/state.service';

@Component({
  selector: 'app-advance-dashboard',
  templateUrl: './advance-dashboard.component.html',
  styleUrls: ['./advance-dashboard.component.css'],
})

export class AdvanceDashboardComponent implements OnInit {
  /**
   *
   * pass params from main workspace componenet
   * @memberof AdvanceDashboardComponent
   */
  @Input() resolveObject;
  /**
   * @description store initialConfig
   * @type {InitialConfigModel}
   * @memberof AdvanceDashboardComponent
   */
  initialConfig: InitialConfigModel;

  /**
   * set brick color value
   *
   * @memberof AdvanceAvailabilityComponent
   */
  brickBgColor = '#333333';

  configObj = {
    InitialConfig: {},
    stateService: this.stateService,
    // isOnline: $rootScope.isOnline == true ? true : false
  };

  constructor(
    dataShareService: DataShareService,
    private stateService: StateService
  ) {
    this.initialConfig = dataShareService.getInitialConfig();
    this.configObj.InitialConfig = this.initialConfig;
  }

  ngOnInit(): void {

  }

  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }

  onModalSaved(event) {
    event.activeModal.close();
  }

}
