import { ProductCategoryLookUp } from './product-category-lookup';
import { Organisation } from './organisation';

export class Brand {
  brandId = 0;
  brandName = '';
  invoiceStatusCode = '';
  productCategory?: ProductCategoryLookUp[];
  organisations?: Organisation[];
}
