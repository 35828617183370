<div class="contentarea">
  <dl class="accordion  bounceInDown content">
    <dt (click)="hideDiscountGrid = !hideDiscountGrid">
      <span class="editable-title">
        <span class="text">Discount Grid </span>
        <span class="text allow-copy" *ngIf="campaignDetails.campaignReference">&nbsp;
        </span>
      </span>
      <a href="javascript:void(0)" class="title" [ngClass]="!hideDiscountGrid?'down':''">
        <span class="right">
          <span class="text">&nbsp;</span>
          <span class="icon_wrapper">
            <span class="icons animated"></span>
          </span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'down' : !hideDiscountGrid}">
      <app-discount-grid-belgium *ngIf="commercialData" [campaignDetails]="campaignDetails" (discountGridChanged)="discountGridChanged()">
      </app-discount-grid-belgium>
    </dd>
  </dl>
  <dl class="accordion animated bounceInDown content campaign-details">
    <dt (click)="hideCampaignDetail = !hideCampaignDetail">
      <span class="editable-title">
        <span class="text">{{initialConfig.userBundle['commercial.tabTitle.campaignDetails']}}
          &nbsp;-&nbsp;
        </span>
        <span class="text allow-copy"> {{commercialData?.campaignReference}}
        </span>
      </span>
      <a href="javascript:void(0)" class="title" [ngClass]="!hideCampaignDetail?'down':''">
        <span class="right">
          <span class="text">{{commercialData?.bookingStatusName}}</span>
          <span class="icon_wrapper">
            <span class="icons animated"></span>
          </span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'readOnly' : systemFlags.readOnly, 'down': !hideCampaignDetail }">
      <app-campaign-details-belgium *ngIf="commercialData" [bookingStatusId]="bookingStatusId"
        [commercialConfig]="commercialConfig" [campaignDetails]="campaignDetails" [historyROC]="historyROC"
        (reaonlyChanged)="reaonlyChanged()" (manuallyCPTUpdated)="manuallyCPTUpdated()">
      </app-campaign-details-belgium>
    </dd>
  </dl>
  <dl class="accordion animated bounceInDown content booking-grid">
    <dt (click)="hideBookingGrid = !hideBookingGrid">
      <span class="editable-title">
        <span class="text">{{initialConfig?.userBundle['commercial.tabTitle.bookingDetails']}} </span>
      </span>
      <a href="javascript:void(0)" class="title" [ngClass]="!hideBookingGrid?'down':''">
        <span class="right">
          <span class="icon_wrapper">
            <span class="icons animated"></span>
          </span>
        </span>
      </a>
    </dt>
    <dd [ngClass]="{'down': !hideBookingGrid }">
      <app-booking-grid-uk *ngIf="commercialData" [commercialConfig]="commercialConfig"
        [bookingList]="commercialData.bookingList">
      </app-booking-grid-uk>
    </dd>
  </dl>


  <app-commercial-buttons *ngIf="commercialData" [commercialConfig]="commercialConfig"
    [campaignDetails]="campaignDetails" [validateFn]="validateCommercialCampaign"
    [populateRequestJson]="createRequestJSONForCommercial" (bookCampaign)="onBookCampaign($event)">
  </app-commercial-buttons>
</div>
