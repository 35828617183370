import { Filter } from './filters';
import { ProductHelper } from './product';
import { CellValues } from './cell-values';

export class ObjectiveObject {
  filter: Filter;
  productDetails: ProductHelper[] = [];

  /**
   * @description Holds cell values for selected objective brick
   * @type {CellValues}
   * @memberof ObjectiveObject
   */
  currentObjectiveData: CellValues;
}
