<app-sb-modal-wrapper [title]="reasonForChange.title" [titleBackgroundColor]="brickBgColor" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <div class="row">
    <div class="control_group">
      <label>{{reasonForChange.labletext}} :</label>
      <select class="form_control chzn-container" id="auditReasonName" [(ngModel)]="campaign.reasonForChange" name="auditReasonName">
        <option *ngFor="let item of reasonDropdown;  trackBy: trackByItem;" [ngValue]="item">{{item.auditReasonName}}</option>
      </select>
    </div>

    <div *ngIf="campaign.reasonForChange.specifyOther" class="control_group">
      <label>{{initialConfig.userBundle['commercial.label.reason'] || 'Reason'}} :</label> <input type="text" class="form_control" [(ngModel)]="campaign.freeText" />
    </div>
  </div>
</app-sb-modal-wrapper>
