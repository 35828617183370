import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbModal, NgbModalOptions, NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { GridOptions, ColDef, RowNode, ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import { ExcelCell, ExportToExcel, ExcelColumn } from '../../core/utils/exportToExcel';
import { CurrencyFormatPipe } from '../../core/pipes/currency-format.pipe';
import { AgCalendarCellEditorComponent } from '../ag-calendar-cell-editor/ag-calendar-cell-editor.component';
import { AgCheckboxComponent } from '../ag-checkbox/ag-checkbox.component';
import { AgTextCellRendererComponent } from '../ag-text-cell-renderer/ag-text-cell-renderer.component';
import { ConfirmationComponent } from './../confirmation/confirmation.component';
import { DataShareService } from '../../core/services/data-share.service';
import { CartService } from '../cart.service';
import { LogHelperService } from '../../core/services/log-helper.service';
import { editablePeriodId, DateType } from '../status.enum';
import { FrameModel, InitialConfigModel, ResponseModel, SaveFrameResponseModel, CartDetailColumnItem, SystemFlags } from '../../models';
import { GoogleAnalyticsEvents, GoogleAnalyticsEventCategory, EventActionsCart } from '../../GoogleAnalytics/GoogleAnalyticsEvent';
import { AppNameEnum } from '../../core/enum';
import { CartDetailService } from './cart-detail.service';

import * as _ from 'lodash';
import * as moment from 'moment';
import { GLOBAL } from '../../core/utils/app.constant';
import { LocaleData } from '../../core/utils/LocaleData';

@Component({
  selector: 'app-cart-detail',
  templateUrl: './cart-detail.component.html',
  styleUrls: ['./cart-detail.component.css']
})
export class CartDetailComponent implements OnInit {
  /**
   * list of Cart Data
   * @memberof CartDetailComponent
   */
  cartData: FrameModel[] = [];

  /**
   * Grid footer row data
   * @memberof CartDetailComponent
   */
  footerRowData: any[] = [{}];

  /**
   * channel ngbDropdown element. needed this to close dropdown manually i.e. when clicking out side of element
   * @type {NgbDropdown}
   * @memberof FilterAreaComponent
   */
  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  /**
   * Channel Container element. needed to check wheter click operation happend inside this element or not for manual dropdown close
   * @type {ElementRef}
   * @memberof FilterAreaComponent
   */
  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  /**
   * Ag-Grid Options Object
   */
  gridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {ColDef[]}
   * @memberof CartDetailComponent
   */
  columnDefs: ColDef[] | any[];

  /**
   * application initial config file
   * @type {*}@memberof CartDetailComponent
   */
  public initialConfig: InitialConfigModel;

  bodyEle: HTMLElement = document.querySelector('body');

  /**
   * ngbModal options
   * @type {NgbModalOptions}@memberof CartDetailComponent
   */
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'sm'
  };

  isDataChanged = false;

  systemFlags = SystemFlags;

  readonly REDIRECTION = 'geoplanner.confirm.redirection';
  readonly CONFIRMATION_LABEL = 'result.confirmation.label';

  /**
   * constructor method
   * @param activeModal
   * @param dataShareService
   * @param cartService
   * @param datePipe
   * @param currencyFormatPipe
   * @param logHelper
   */
  constructor(
    private activeModal: NgbActiveModal,
    private dataShareService: DataShareService,
    private cartService: CartService,
    private datePipe: DatePipe,
    private currencyFormatPipe: CurrencyFormatPipe,
    private logHelper: LogHelperService,
    private cartDetailService: CartDetailService,
    private modalService: NgbModal) {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.cartData = _.cloneDeep(this.cartService.cartData);
    this.setCartGridOptions();
  }

  /**
   * Angular life cycle hook event
   */
  ngOnInit() {
    this.setCartGridColumnDef();
  }

  /**
   * set ag-grid grid options for cart data
   */
  private setCartGridOptions(): void {
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
      },
      scrollbarWidth: 10,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      getRowHeight: this.getRowHeight,
      onGridReady: (params) => {
        // set grid readonly if campaign is readonly
        if (SystemFlags.readOnlyWorkspace && !SystemFlags.splitable) {
          const eleGridBody: any =
            document.querySelector('.cart-detail-grid .ag-body-viewport .ag-center-cols-viewport .ag-center-cols-container');
          eleGridBody.className = `${eleGridBody.className} readOnly`;
          const eleLeftPin: any = document.querySelector('.cart-detail-grid .ag-pinned-left-cols-viewport .ag-pinned-left-cols-container');
          eleLeftPin.className = `${eleLeftPin.className}  readOnly`;
          const eleBottomRow: any = document.querySelector('.cart-detail-grid .ag-root > .ag-floating-bottom');
          eleBottomRow.className = `${eleBottomRow.className}  readOnly`;
          const eleHeaderCheckCell: any = document.querySelector('.cart-detail-grid .ag-root > .ag-header .checkCell');
          eleHeaderCheckCell.className = `${eleHeaderCheckCell.className}  readOnly`;
        }
        setTimeout(() => {
          params.api.sizeColumnsToFit();
        }, 500);
      },
      getRowClass: (params) => {
        const rowClass = [];
        if (params.data.selected_cart) {
          rowClass.push('selectedRow');
        }
        return rowClass;
      },
      onRowDataChanged: () => {
        this.gridOptions.api.refreshHeader();
      },
      onFilterChanged: () => {
        this.gridOptions.api.refreshHeader();
      },
      suppressScrollOnNewData: true,
      floatingFiltersHeight: 30,
      headerHeight: 27,
      icons: {
        filter: '<i class="fa fa-filter"/>',
        sortAscending: '<i class="fa fa-sort-asc"/>',
        sortDescending: '<i class="fa fa-sort-desc"/>',
      }
    } as GridOptions;
  }

  /**
   * checks whether row is full width cell or not
   */
  isFullWidthCell = (rowNode: RowNode): boolean => {
    return rowNode.data.gridSummary;
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'cart_summart_row';
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }

  getGridSummary(): string {
    let totalPrice: number = this.getSumFieldValues(this.cartData, 'price');
    totalPrice = this.currencyFormatPipe.transform(totalPrice);
    // tslint:disable-next-line:prefer-template
    return '<span><b>' + this.initialConfig.userBundle['geoplanner.text.totalframes'] + ':</b> ' + this.cartData.length
      + ' - <b>' + this.initialConfig.userBundle['geoplanner.text.totalprice'] + ':</b> ' + totalPrice;
  }

  getSumFieldValues(rows: FrameModel[], fieldName: string): number {
    let sum = 0;
    rows.forEach((row: FrameModel) => {
      if (row[fieldName]) {
        sum = sum + parseFloat(row[fieldName]);
      }
    });
    return sum;
  }

  public dateComparator(date1: Date, date2: Date): number {
    if (date1 === null && date2 === null) {
      return 0;
    }
    if (date1 === null) {
      return -1;
    }
    if (date2 === null) {
      return 1;
    }
    if (date1 < date2) { return -1; }
    if (date1 > date2) { return 1; }
    return 0;
  }
  /**
   * set ag-grid column def object for cart grid
   */
  private setCartGridColumnDef(): void {
    this.columnDefs = [];
    const columnDefs: any[] = [{
      headerName: '', field: 'selected_cart',
      width: 40, minWidth: 40, editable: false, pinned: 'left',
      cellRendererFramework: AgCheckboxComponent,
      headerComponentFramework: AgCheckboxComponent,
      pinnedRowCellRenderer: this.blankCellRenderer,
      headerClass: 'checkCell',
      filter: false,
      suppressMovable: true,
      cellStyle: (params) => {
        const style = {
          borderLeftColor: 'transparent !important;',
          borderLeftWidth: '3px !important'
        };
        /** Request brandon on slack
         *  use  unavailabilityColor instead of unavailability field
         *  highlight using unavailabilityColor and not red
         */
        if (params.data.unavailabilityColor && params.data.unavailabilityColor !== '') {
          style.borderLeftColor = `${params.data.unavailabilityColor} !important;`;
        }
        return style;
      },
      cellRendererParams: {
        onSelectChange: this.onRowSelectChange
      },
      headerComponentParams: {
        onSelectChange: this.onRowSelectChange
      }
    }];

    this.cartDetailService.getColumnList().then((colList: CartDetailColumnItem[]) => {
      columnDefs.push(...this.prepareCartGridColumnDef(colList));
      this.columnDefs = columnDefs;
    });
  }

  // tslint:disable-next-line: no-big-function
  prepareCartGridColumnDef = (colList: CartDetailColumnItem[]) => {
    const columnDefs: any[] = [];
    for (const col of colList) {
      switch (col.field) {
        case 'frameName':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.frames'] || 'Frames',
            field: 'frameName',
            pinnedRowCellRenderer: this.actionBtnPinnedRowCellRenderer,
            width: 150,
            minWidth: 150,
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            filter: 'text',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            }
          });
          break;
        case 'startDate':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['workspace.incharge.bric.range.startDate'],
            field: 'startDate',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            width: 120,
            minWidth: 120,
            editable: true,
            cellRendererFramework: AgTextCellRendererComponent,
            cellRendererParams: {
              cellEditable: this.isStartDateEditable
            },
            pinnedRowCellRendererFramework: AgTextCellRendererComponent,
            pinnedRowCellRendererParams: {
              cellEditable: this.isStartDateEditable
            },
            cellEditorFramework: AgCalendarCellEditorComponent,
            cellEditorParams: {
              minDate: this.getMinDateForCalenderEditor,
              maxDate: this.getMaxDateForCalenderEditor,
              onDateSelect: (params, newDate) => {
                this.updateVuFrames(params, newDate);
                params.node.data.startDate = this.datePipe.transform(newDate, LocaleData.displayDateFormat);
                params.node.data.startDateReq = moment(newDate).startOf('day').format(GLOBAL.MOMENT_REQ_DATE_FORMAT);
              },
              dateType: DateType.start,
              dateInterval: this.getDateIntervalForCalendarEditor,
              cellEditable: this.isStartDateEditable
            },
            // @ts-ignore
            comparator: (date1, date2, node1, node2) => {
              const val1 = new Date(node1.data.startDateReq);
              const val2 = new Date(node2.data.startDateReq);
              return this.dateComparator(val1, val2);
            }
          });
          break;
        case 'endDate':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['workspace.incharge.bric.range.endDate'],
            field: 'endDate',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            width: 120,
            minWidth: 120,
            editable: true,
            cellRendererFramework: AgTextCellRendererComponent,
            cellRendererParams: {
              cellEditable: this.isEndDateEditable
            },
            pinnedRowCellRendererFramework: AgTextCellRendererComponent,
            pinnedRowCellRendererParams: {
              cellEditable: this.isEndDateEditable
            },
            cellEditorFramework: AgCalendarCellEditorComponent,
            cellEditorParams: {
              minDate: this.getMinDateForCalenderEditor,
              maxDate: this.getMaxDateForCalenderEditor,
              onDateSelect: (params, newDate) => {
                this.updateVuFrames(params, undefined, newDate);
                params.node.data.endDate = this.datePipe.transform(newDate, LocaleData.displayDateFormat);
                params.node.data.endDateReq = moment(newDate).endOf('day').format(GLOBAL.MOMENT_REQ_DATE_FORMAT);
              },
              dateType: DateType.end,
              dateInterval: this.getDateIntervalForCalendarEditor,
              cellEditable: this.isEndDateEditable
            },
            // @ts-ignore
            comparator: (date1, date2, node1, node2) => {
              const val1 = new Date(node1.data.endDateReq);
              const val2 = new Date(node2.data.endDateReq);
              return this.dateComparator(val1, val2);
            }
          });
          break;
        case 'sot':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.addons.filter.sot.heading'] || 'SOT',
            field: 'sot',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            cellClass: 'textAlignRight',
            pinnedRowCellRenderer: this.blankCellRenderer,
            minWidth: 60,
            width: 60
          });
          break;
        case 'mediaName':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['workspace.collection.bric.media.heading'],
            field: 'mediaName',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            pinnedRowCellRenderer: this.blankCellRenderer,
            minWidth: 90,
            width: 90
          });
          break;
        case 'productFormatName':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.format']
              || 'Format', // this.initialConfig.userBundle['geoplanner.text.product'] || "Product",
            field: 'productFormatName',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            pinnedRowCellRenderer: this.blankCellRenderer,
            minWidth: 100,
            width: 100
          });
          break;
        case 'townName':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.city'] || 'City',
            field: 'townName',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            pinnedRowCellRenderer: this.blankCellRenderer,
            minWidth: 100,
            width: 100
          });
          break;
        case 'districtName':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.district'] || 'District',
            field: 'districtName',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            pinnedRowCellRenderer: this.blankCellRenderer,
            minWidth: 100,
            width: 100
          });
          break;
        case 'address':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.address'] || 'Address',
            field: 'address',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            pinnedRowCellRenderer: this.blankCellRenderer,
            minWidth: 100,
            width: 100
          });
          break;
        case 'postcode':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.postcode'] || 'Postal Code',
            field: 'postcode',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            pinnedRowCellRenderer: this.blankCellRenderer,
            minWidth: 100,
            width: 100
          });
          break;
        case 'latitude':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.latitude'] || 'Latitude',
            field: 'latitude',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            pinnedRowCellRenderer: this.blankCellRenderer,
            minWidth: 80,
            width: 80
          });
          break;
        case 'longitude':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.longitude'] || 'Longitude',
            field: 'longitude',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            pinnedRowCellRenderer: this.blankCellRenderer,
            minWidth: 90,
            width: 90
          });
          break;
        case 'price':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.price'] || 'Price',
            field: 'price',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            minWidth: 90,
            width: 90,
            cellClass: 'textAlignRight',
            pinnedRowCellRenderer: this.blankCellRenderer,
            cellRenderer: this.currencyCellRenderer
          });
          break;
        case 'furnitureName':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.furniture'] || 'Furniture',
            field: 'furnitureName',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            width: 150,
            minWidth: 150,
            pinnedRowCellRenderer: this.blankCellRenderer,
          });
          break;
        case 'channelName':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['workspace.collection.bric.channel.heading'],
            field: 'channelName',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            pinnedRowCellRenderer: this.blankCellRenderer,
            width: 90,
            minWidth: 90,
          });
          break;
        case 'familyName':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.family'] || 'Family',
            field: 'familyName',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            pinnedRowCellRenderer: this.blankCellRenderer,
            minWidth: 90,
            width: 90
          });
          break;
        case 'subFamilyName':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.sub_family'] || 'Sub-Family',
            field: 'subFamilyName',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            pinnedRowCellRenderer: this.blankCellRenderer,
            minWidth: 100,
            width: 100
          });
          break;
        case 'frameTypeName':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.frame_type'] || 'Frame Type',
            field: 'frameTypeName',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            pinnedRowCellRenderer: this.blankCellRenderer,
            minWidth: 110,
            width: 110
          });
          break;
        case 'frameAltCode':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.tabid'] || 'TAB ID',
            field: 'frameAltCode',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            pinnedRowCellRenderer: this.blankCellRenderer,
            minWidth: 70,
            width: 70
          });
          break;
        case 'unavailability':
          columnDefs.push({
            headerName: this.initialConfig.userBundle['geoplanner.text.unavailability'] || 'Unavailability Reason',
            field: 'unavailability',
            suppressMenu: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true
            },
            isVisible: col.isVisible,
            hide: !col.isVisible,
            pinned: col.pinned,
            width: 150,
            minWidth: 150,
            pinnedRowCellRenderer: this.blankCellRenderer
          });
          break;
        default:
          break;
      }
    }
    return columnDefs;
  }

  /**
   * checks whether end date is editable or not
   */
  isEndDateEditable = (rowNode: RowNode): boolean => {
    let returnVal = false;
    if (rowNode.rowPinned === 'bottom') {
      returnVal = true;
    } else {
      return (rowNode.data.editablePeriodId === editablePeriodId.future || rowNode.data.editablePeriodId === editablePeriodId.running);
    }
    return returnVal;
  }

  /**
   * checks whether start date is editable or not
   */
  isStartDateEditable = (rowNode: RowNode): boolean => {
    let returnVal = false;
    if (rowNode.rowPinned === 'bottom') {
      returnVal = true;
    } else {
      return (rowNode.data.editablePeriodId === editablePeriodId.future);
    }
    return returnVal;
  }

  getMinDateForCalenderEditor = (params: ICellEditorParams): Date => {
    if (params.node.rowPinned === 'bottom') {
      const selectedCnt: number = this.cartService.getSelectedFrameCount(this.cartData);
      if (selectedCnt > 0) {
        let maxStartdate = this.getMaxStartDateFromSelectedData(params.column.getColId());
        if (params.column.getColId() === 'endDate' && maxStartdate !== null && params.data.startDateReq) {
          const startDateSelected = new Date(params.data.startDateReq);
          if (startDateSelected > maxStartdate) {
            maxStartdate = startDateSelected;
          }
        }
        return maxStartdate == null ? new Date() : maxStartdate;
      } else {
        const today: any = new Date();
        return today.addDays(1);
      }
    } else {
      return this.getFrameStartEditDate(params.node.data);
    }
  }

  getMaxDateForCalenderEditor = (params: ICellEditorParams): Date => {
    if (params.node.rowPinned === 'bottom') {
      const today: any = new Date();
      const selectedCnt = this.cartService.getSelectedFrameCount(this.cartData);
      if (selectedCnt > 0) {
        return this.getMinEndDateFromSelectedData(params.column.getColId());
      } else {
        return today.addDays(-1);
      }
    } else {
      return new Date((moment(params.node.data.endDateReq) as any));
    }
  }

  getDateIntervalForCalendarEditor = (params: ICellEditorParams): number => {
    let dateInterval = 1;
    if (params.node.rowPinned === 'bottom') {
      const selectedCnt: number = this.cartService.getSelectedFrameCount(this.cartData);
      if (selectedCnt > 0) {
        const data = this.cartData.filter((asset) => {
          return (asset.selected_cart);
        });
        for (const frame of data) {
          if (frame.dateInterval && frame.dateInterval > dateInterval) {
            dateInterval = frame.dateInterval;
          }
        }
      }
    } else {
      dateInterval = params.node.data.dateInterval ? params.node.data.dateInterval : 1;
    }
    return dateInterval;
  }

  getFrameStartEditDate(frame: FrameModel): Date {
    let startEditDate: Date;
    // case of future frame
    if (frame.editablePeriodId === editablePeriodId.future) {
      startEditDate = new Date((moment(frame.startDateReq) as any));
    } else { // case of running. past frame are not allowed to edit
      startEditDate = new Date((moment(frame.splitFromDate) as any));
    }
    return startEditDate;
  }

  /**
   * @description updates visual unit frames on selection change & date range change
   * @author Amit Mahida
   * @param {ICellRendererParams} params
   * @memberof CartDetailComponent
   */
  updateVuFrames(params: ICellRendererParams, newStartDate?: Date, newEndDate?: Date) {
    if (params && params.node && params.node.data && params.node.data.visualUnitId) {
      this.cartData.forEach((frame: FrameModel) => {
        const matchingStartAndEndDate = frame.startDate === params.node.data.startDate && frame.endDate === params.node.data.endDate;
        if (frame.frameId !== params.node.data.frameId
          && frame.visualUnitId === params.node.data.visualUnitId
          && matchingStartAndEndDate
        ) {
          frame.selected_cart = params.node.data.selected_cart;
          if (newStartDate) {
            frame.startDate = this.datePipe.transform(newStartDate, LocaleData.displayDateFormat);
            frame.startDateReq = moment(newStartDate).startOf('day').format(GLOBAL.MOMENT_REQ_DATE_FORMAT);
          }
          if (newEndDate) {
            frame.endDate = this.datePipe.transform(newEndDate, LocaleData.displayDateFormat);
            frame.endDateReq = moment(newEndDate).endOf('day').format(GLOBAL.MOMENT_REQ_DATE_FORMAT);
          }
        }
      });
    }
  }

  onRowSelectChange = (params?: ICellRendererParams): void => {
    this.footerRowData[0].startDate = '';
    this.footerRowData[0].startDateReq = '';
    this.footerRowData[0].endDate = '';
    this.footerRowData[0].endDateReq = '';
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setPinnedBottomRowData(this.footerRowData);
    }
    this.updateVuFrames(params);
  }
  /**
   * returns height of row for cart data grid
   */
  getRowHeight = (params: ICellRendererParams): number => {
    let height = 25;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 36;
    }
    return height;
  }

  /**
   * action btn cell renderer
   */
  actionBtnPinnedRowCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    // Delete Icon
    const eImgDiv: HTMLDivElement = document.createElement('div');
    eImgDiv.className = 'deleteBtn';
    const eITag: HTMLElement = document.createElement('i');
    eITag.className = 'fa fa-trash';
    eImgDiv.appendChild(eITag);
    eImgDiv.title = this.initialConfig.userBundle['common.delete'] || 'Delete';
    eImgDiv.onclick = this.onDeleteClick;
    eDiv.appendChild(eImgDiv);
    //
    // apply Icon
    const eImgDivApply: HTMLDivElement = document.createElement('div');
    eImgDivApply.className = 'applyBtn';
    const eITagApply: HTMLElement = document.createElement('i');
    eITagApply.className = 'fa fa-check';
    eImgDivApply.appendChild(eITagApply);
    eImgDivApply.title = this.initialConfig.userBundle['common.apply'] || 'Apply';
    eImgDivApply.onclick = this.onApplyClick;
    eDiv.appendChild(eImgDivApply);
    return eDiv;
  }

  /**
   * delete button from action cell click
   */
  onDeleteClick = (): void => {

    const tempCartData: FrameModel[] = _.cloneDeep(this.cartService.cartData);
    this.cartService.setCartData(this.cartData);
    if (this.cartService.getSelectedFrameCount() === 0) {
      const saveMsg = this.initialConfig.userBundle['geoplanner.error.selectframes'] || 'Please select frames.';
      this.logHelper.logError(saveMsg);
      this.cartService.setCartData(_.cloneDeep(tempCartData));
      return;
    }

    if ((this.dataShareService.appName === AppNameEnum.geomapper && SystemFlags.getBasketDataForGP)
      || (this.dataShareService.appName === AppNameEnum.visualplanner && SystemFlags.getBasketDataForVP)) {
      const modalRef = this.modalService.open(ConfirmationComponent, this.ngbModalOptions);
      modalRef.componentInstance.resolveObject = {
        content: this.initialConfig.userBundle[this.REDIRECTION],
        title: this.initialConfig.userBundle[this.CONFIRMATION_LABEL],
        ok: this.initialConfig.userBundle['common.ok'],
      };
      modalRef.result.then(() => {
        this.delete();
        if (this.dataShareService.appName === AppNameEnum.visualplanner) {
          SystemFlags.getBasketDataForVP = false;
        } else {
          SystemFlags.getBasketDataForGP = false;
        }
      }, (reason) => {
        console.log(reason);
      }).catch(() => {
        // nothing to be done on close
      });
    } else {
      this.delete();
    }

  }

  delete(): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Cart, EventActionsCart.DeleteFrame, 'Delete frame from cart');
    const tempCartData: FrameModel[] = _.cloneDeep(this.cartService.cartData);
    const selectedFrames: FrameModel[] = this.cartService.getSelectedFrames();
    let isAnyFrameNotDeletable = false;
    for (const frame of selectedFrames) {
      if (!frame.isDeletable) {
        isAnyFrameNotDeletable = true;
        break;
      }
    }
    if (isAnyFrameNotDeletable) {
      const notAllowedToDeleteMsg: string = this.initialConfig.userBundle['geoplanner.error.notdeletable'] ||
        'past or running frames are not allowed to delete.';
      this.logHelper.logError(notAllowedToDeleteMsg);
      this.cartService.setCartData(_.cloneDeep(tempCartData));
      return;
    }

    this.cartService.removeSelectedFrame();
    this.cartService.saveFrame().subscribe((res: ResponseModel<SaveFrameResponseModel>) => {
      if (res.status === 'OK') {
        this.isDataChanged = true;
        this.cartData = _.cloneDeep(this.cartService.cartData);
        this.gridOptions.api.refreshHeader();
        const saveMsg = this.initialConfig.userBundle['geoplanner.success.deleteframes'] || 'Frames deleted successfully.';
        this.logHelper.logSuccess(saveMsg);
      } else if (res.status === 'KO') {
        this.cartService.setCartData(_.cloneDeep(tempCartData));
        this.logHelper.logError(res.message);
      }
    }, (err) => {
      this.cartService.setCartData(_.cloneDeep(tempCartData));
      this.logHelper.logError(err.message);
    });

  }

  /**
   * apply button from action cell click
   */
  onApplyClick = (): void => {

    const tempCartData: FrameModel[] = _.cloneDeep(this.cartService.cartData);
    if (this.cartService.getSelectedFrameCount(this.cartData) === 0 ||
      (!this.footerRowData[0].startDateReq && !this.footerRowData[0].endDateReq) ||
      (this.footerRowData[0].startDateReq === '' && this.footerRowData[0].endDateReq === '')) {
      const saveMsg = this.initialConfig.userBundle['geoplanner.error.selectframes'] || 'Please select frames.';
      this.logHelper.logError(saveMsg);
      this.cartService.setCartData(_.cloneDeep(tempCartData));
      return;
    }

    if ((this.dataShareService.appName === AppNameEnum.geomapper && SystemFlags.getBasketDataForGP)
      || (this.dataShareService.appName === AppNameEnum.visualplanner && SystemFlags.getBasketDataForVP)) {
      const modalRef = this.modalService.open(ConfirmationComponent, this.ngbModalOptions);
      modalRef.componentInstance.resolveObject = {
        content: this.initialConfig.userBundle[this.REDIRECTION],
        title: this.initialConfig.userBundle[this.CONFIRMATION_LABEL],
        ok: this.initialConfig.userBundle['common.ok'],
      };
      modalRef.result.then(() => {
        this.apply();
        if (this.dataShareService.appName === AppNameEnum.visualplanner) {
          SystemFlags.getBasketDataForVP = false;
        } else {
          SystemFlags.getBasketDataForGP = false;
        }
      }, (reason) => {
        console.log(reason);
      }).catch(() => {
        // nothing to be done on close
      });
    } else {
      this.apply();
    }

  }

  apply(): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Cart, EventActionsCart.ApplyChanges, 'Apply changes from cart detail grid');
    const tempCartData: FrameModel[] = _.cloneDeep(this.cartService.cartData);
    const selectedData: FrameModel[] = this.cartData.filter((asset: FrameModel) => {
      return (asset.selected_cart);
    });
    if (this.footerRowData[0].startDateReq && this.footerRowData[0].startDateReq !== '') {
      selectedData.forEach((frame) => {
        frame.startDate = this.footerRowData[0].startDate;
        frame.startDateReq = this.footerRowData[0].startDateReq;
      });
    }
    if (this.footerRowData[0].endDateReq && this.footerRowData[0].endDateReq !== '') {
      selectedData.forEach((frame: FrameModel) => {
        frame.endDate = this.footerRowData[0].endDate;
        frame.endDateReq = this.footerRowData[0].endDateReq;
      });
    }
    this.cartService.setCartData(this.cartData);
    this.cartService.saveFrame().subscribe((res: ResponseModel<SaveFrameResponseModel>) => {
      if (res.status === 'OK') {
        this.isDataChanged = true;
        this.onRowSelectChange(); // reset footer row selected data
        this.cartData = _.cloneDeep(this.cartService.cartData);
        this.gridOptions.api.refreshHeader();
        const saveMsg = this.initialConfig.userBundle['geoplanner.success.saveselectedframes'] || 'Selected frames saved.';
        this.logHelper.logSuccess(saveMsg);
      } else if (res.status === 'KO') {
        this.cartService.setCartData(_.cloneDeep(tempCartData));
        this.logHelper.logError(res.message);
      }
    }, (err) => {
      this.cartService.setCartData(_.cloneDeep(tempCartData));
      this.logHelper.logError(err.message);
    });
  }

  /**
   * formats the cell to currency format
   */
  currencyCellRenderer = (params: ICellRendererParams): string => {
    const val = params.data[params.column.getColId()];
    return this.currencyFormatPipe.transform(val);
  }

  /**
   * blank cell renderer
   * @param params
   */
  blankCellRenderer(): string {
    return '';
  }

  /**
   * Trigger on document click
   * this is needed for channel ngbDropdown component to manually close on clicking out side of component
   * @param {any} $event
   * @memberof FilterAreaComponent
   */
  @HostListener('document:click', ['$event'])
  onDocumentClick($event: MouseEvent): void {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen() && $event.button !== 2 && !dropdownEle._isEventFromToggle($event)
      && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
      dropdownEle.close();
    }
  }

  /**
   * toggle column visibility in cart data grid
   * @param key - field name of column
   * @param value - true/false whether to show or hide
   */
  toggleColumnVisible(key: string, value: boolean): void {
    this.gridOptions.columnApi.setColumnVisible(key, value);
    this.gridOptions.api.sizeColumnsToFit();
  }

  isPastFrame = (frame: FrameModel, colId: string) => {
    if (colId === 'startDate') {
      return (frame.editablePeriodId === editablePeriodId.past || frame.editablePeriodId === editablePeriodId.running);
    } else {
      return (frame.editablePeriodId === editablePeriodId.past);
    }
  }

  getMaxStartDateFromSelectedData(colId: string): Date {
    let maxStartDate: Date;
    const data = this.cartData.filter((asset) => {
      return (asset.selected_cart);
    });
    const pastFrames = data.filter((frame) => {
      return this.isPastFrame(frame, colId);
    });
    if (pastFrames.length > 0) {
      return null;
    }
    /* tslint:disable:prefer-for-of */
    for (let i = 0; i < data.length; i++) {
      const frame = data[i];
      if (i === 0) {
        maxStartDate = this.getFrameStartEditDate(frame);
      } else {
        const d = this.getFrameStartEditDate(frame);
        if (d > maxStartDate) {
          maxStartDate = d;
        }
      }
    }
    return maxStartDate;
  }
  getMinEndDateFromSelectedData(colId: string): Date {
    let minEndDate: Date;
    const data = this.cartData.filter((asset) => {
      return (asset.selected_cart);
    });
    const pastFrames = data.filter((frame) => {
      return this.isPastFrame(frame, colId);
    });
    if (pastFrames.length > 0) {
      const today: any = new Date();
      return today.addDays(-1);
    }
    /* tslint:disable:prefer-for-of */
    for (let i = 0; i < data.length; i++) {
      const frame = data[i];
      if (i === 0) {
        minEndDate = new Date((moment(frame.endDateReq) as any));
      } else {
        const d = new Date((moment(frame.endDateReq) as any));
        if (d < minEndDate) {
          minEndDate = d;
        }
      }
    }
    return minEndDate;
  }


  addHeaderRow(sheetData: ExcelCell[][], excelColumn: ExcelColumn[]) {
    const sheetRow: ExcelCell[] = [];
    for (const column of this.columnDefs) {
      if (column.isVisible) {
        const cell: ExcelCell = {
          text: column.headerName,
          fontColor: '#FFFFFF',
          bgColor: '#000000',
          borderColor: '#CCCCCC',
          isFontBold: true
        };
        const col: ExcelColumn = {
          width: column.field === 'frameName' ? 14 : 12
        };
        excelColumn.push(col);
        sheetRow.push(cell);
      }
    }
    sheetData.push(sheetRow);
  }

  addDataRows(sheetData: ExcelCell[][]) {
    /* tslint:disable:prefer-for-of */
    for (let i = 0; i < this.cartData.length; i++) {
      // tslint:disable-next-line:no-shadowed-variable
      const sheetRow: ExcelCell[] = [];
      for (const column of this.columnDefs) {
        if (column.isVisible) {
          const cell: ExcelCell = {
            text: this.cartData[i][column.field],
            fontColor: '#000000',
            bgColor: i % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
            borderColor: '#CCCCCC'
          };
          sheetRow.push(cell);
        }
      }
      sheetData.push(sheetRow);
    }
  }

  /**
   * download cart data in excel format
   */
  downloadExcel(): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Cart, EventActionsCart.ExportExcel, 'Export cart data to excel');
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    this.addHeaderRow(sheetData, excelColumn);
    // Add Data rows
    this.addDataRows(sheetData);
    const cartText = this.initialConfig.userBundle['geoplanner.cart.text.detail'] || 'Cart Detail';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }

  /**
   * store cart detail column Grid column state
   */
  storeCartDetailColumnList(): void {
    const allCols = this.gridOptions.columnApi.getColumnState();
    const colList: CartDetailColumnItem[] = [];
    for (const col of allCols) {
      if (col.colId !== 'selected_cart') {
        colList.push({
          field: col.colId,
          isVisible: !col.hide,
          pinned: col.pinned
        });
      }
    }
    this.cartDetailService.setColumnList(colList).then();
  }

  /**
   * To save the given data.
   */
  onSave(): void {

    if ((this.dataShareService.appName === AppNameEnum.geomapper && SystemFlags.getBasketDataForGP)
      || (this.dataShareService.appName === AppNameEnum.visualplanner && SystemFlags.getBasketDataForVP)) {
      const modalRef = this.modalService.open(ConfirmationComponent, this.ngbModalOptions);
      modalRef.componentInstance.resolveObject = {
        content: this.initialConfig.userBundle[this.REDIRECTION],
        title: this.initialConfig.userBundle[this.CONFIRMATION_LABEL],
        ok: this.initialConfig.userBundle['common.ok'],
      };
      modalRef.result.then(() => {
        this.save();
        if (this.dataShareService.appName === AppNameEnum.visualplanner) {
          SystemFlags.getBasketDataForVP = false;
        } else {
          SystemFlags.getBasketDataForGP = false;
        }
      }, (reason) => {
        console.log(reason);
      }).catch(() => {
        // nothing to be done on close
      });
    } else {
      this.save();
    }
  }

  save() {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Cart, EventActionsCart.UpdateCart, 'Update cart from cart detail modal');
    const tempCartData: FrameModel[] = _.cloneDeep(this.cartService.cartData);
    this.cartService.setCartData(this.cartData);
    this.cartService.saveFrame().subscribe((res: ResponseModel<SaveFrameResponseModel>) => {
      if (res.status === 'OK') {
        this.isDataChanged = true;
        const saveMsg = this.initialConfig.userBundle['geoplanner.success.saveselectedframes'] || 'Selected frames saved.';
        this.logHelper.logSuccess(saveMsg);
        this.storeCartDetailColumnList();
        this.activeModal.close(this.isDataChanged);
      } else if (res.status === 'KO') {
        this.cartService.setCartData(_.cloneDeep(tempCartData));
        this.logHelper.logError(res.message);
      }
    }, (err) => {
      this.cartService.setCartData(_.cloneDeep(tempCartData));
      this.logHelper.logError(err.message);
    });
  }

  /**
   * To close the opened modal.
   */
  onClose(): void {
    this.storeCartDetailColumnList();
    this.activeModal.dismiss(this.isDataChanged);
  }

  trackByField(index, column) {
    return column?.field;
  }
}
