<div [ngClass]="{'readOnly' : readOnly }">
  <div class="width_3">
    <div class="control_group">
      <label class="label" [ngClass]="{'required' : (booking.salesPersonId == null || booking.salesPersonId.toString() =='')} ">
        {{config.userBundle['common.salesPersonName']}}
      </label>
      <div class='gcontainer'>
        <app-sb-select id="salesperson" [allowClear]="true" [items]="salesPersonList"
          [currentItem]="selectedSalesPerson" [lookupUrl]="salesPersonURL" [minLength]="2"
          (onItemSelected)="onSalesPersonSelected($event)" (onItemRemoved)="removeSelectedSalesPerson()"
          (responseData)="getSalesPersonResponse($event)"
          [placeholder]="config.userBundle['common.lookup.defaultOption']" [extraParams]="salesPersonSearchParams"
          [searchKeyParamName]="'salesPersonName'" [appendSearchKeyParamNameIn]="'data'" [displayInvoiceCode]='false'>
        </app-sb-select>
      </div>
    </div>
  </div>
  <div class="width_3">
    <div class="control_group">
      <label class="label datelabel">
        {{config.userBundle['commercial.label.bookingStart']}}
      </label>
      <div class='gcontainer'>
        <input type="text" [disabled]="true" [ngModel]="booking.startDate | dateFormat : displayDateFormat"
          class="form_control">
      </div>
    </div>
  </div>
  <div class="width_3">
    <div class="control_group">
      <label class="label datelabel">
        {{config.userBundle['commercial.label.bookingEnd']}}
      </label>
      <input type="text" [disabled]="true" [ngModel]="booking.endDate | dateFormat : displayDateFormat"
        class="form_control col-md-3">
    </div>
  </div>

  <div class="width_12">
    <div class="control_group">
      <label class="label">{{config.userBundle['common.comments']}}</label>
      <div class='gcontainer'>
        <textarea class="form_control customtextarea-comments" [(ngModel)]="booking.comments" maxlength="2000" cols="30"
          rows="1"></textarea>
      </div>
    </div>
  </div>


  <div *ngIf="!booking.hasOwnProperty('hideMediaControl') || !booking.hideMediaControl ">
    <label class="subtitles">{{config.userBundle['commercial.title.mediaInformation']}}</label>

    <div class="width_3">
      <div class="control_group">
        <label class="label">{{config.userBundle['commercial.label.mediaLength']}}</label>
        <input id="mediaLength" type="text" class="form_control" [(ngModel)]="booking.mediaLength" [appOnlyNumber]="true"
          [decimalPoints]="0" [maxValue]="999" />
      </div>
    </div>
  </div>