<dl class="accordion content animated bounceInDown" *ngIf="!availability">
  <dt (click)="isDown = !isDown">
    <a href="javascript:void(0)" class="title" [ngClass]="{'down': isDown}">
      <span class="text">{{labels?.header}}</span>
      <div class="icon_wrapper"><span class="icons animated"></span></div>
      <span *ngIf="true" (click)="getResultSummary($event)" style="float:right;"><img src="images/signal-coverage.png" alt="signal-coverage" class="signal-coverage"/></span>
    </a>
  </dt>
  <dd [ngClass]="{'down': isDown}">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th *ngIf="resultSummary?.actualValues">&nbsp;</th>
              <th [hidden]="header == null" id="header" *ngFor="let header of resultSummary?.columnHeaders; trackBy: trackByItem;">{{header}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let targets of resultSummary?.targetValues; trackBy: trackByIndex; let i = index; let last = last" (click)="toggleValues('target', i)"
              [ngClass]="{'active-summary': i === selectedSummary}" style="cursor:pointer;">
              <td *ngIf="resultSummary?.actualValues" [style.background]="last ? '#c9ffc9' : ''">{{labels['target']}}</td>
              <td [hidden]="target == null" *ngFor="let target of targets; trackBy: trackByItem;" [style.background]="last ? '#c9ffc9' : ''">
                {{target}}
              </td>
            </tr>
            <tr *ngIf="resultSummary?.actualValues" (click)="toggleValues('actual')" style="cursor:pointer;" [ngClass]="{'active-summary': selectedSummary === 'actual'}">
              <td *ngIf="resultSummary?.actualValues">{{labels['actual']}}</td>
              <td [hidden]="actual == null" *ngFor="let actual of resultSummary?.actualValues; trackBy: trackByItem;">
                {{actual}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </dd>
</dl>
