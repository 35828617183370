<div class="savecampaign modal-header">
    <h3 class="modal-title">{{resolveObject.title}}</h3>
    <button aria-label="Close" class="close" type="button" (click)="onClose()">x</button>
</div>
<div class="modal-body">
    <div class="confirm-message-geomap">
        {{resolveObject.content}}
    </div>

</div>
<div class="modal-footer" style="clear: both">
    <button class="btn btn-primary" (click)="onSave()">{{resolveObject.ok}}</button>
    <div class="checkbox_lock">
        <input type="checkbox" id="lock" name="lock">
        <label for="lock" class="animated"></label>
    </div>
</div>