import { CurrencyFormatPipe } from '../pipes/currency-format.pipe';
import { DistributionEnum } from '../../bricks/distribution/distribution-enum';
import { AudienceEnum } from '../../bricks/audience/audience-enum';
import { DecimalPipe } from '@angular/common';
import { LocaleData } from './LocaleData';
import { DateFormatPipe } from '../pipes/date-format.pipe';
declare var _: any;

export class DisplayTextGenerator {
  allSelectionText = '';
  userBundle = {};
  dateFormatPipe: DateFormatPipe;
  constructor(
    private decimalPipe: DecimalPipe
  ) {
    this.dateFormatPipe = new DateFormatPipe();
  }

  getDisplayTextForInchargeBrick(selectedValues, userBundle, showDayofWeekRangeBric) {
    if (selectedValues.hasOwnProperty('-99')) { return userBundle['workspace.common.allSelection'] || this.allSelectionText; } else {
      const startDate = showDayofWeekRangeBric
        ? this.dateFormatPipe.transform((new Date(selectedValues.startDate)), `dddd, ${LocaleData.displayDateFormat}`)
        : this.dateFormatPipe.transform((new Date(selectedValues.startDate)), LocaleData.displayDateFormat);

      const endDate = showDayofWeekRangeBric
        ? this.dateFormatPipe.transform((new Date(selectedValues.endDate)), `dddd, ${LocaleData.displayDateFormat}`)
        : this.dateFormatPipe.transform((new Date(selectedValues.endDate)), LocaleData.displayDateFormat);

      return `${startDate}<br/>
      ${userBundle['workspace.rating.bric.to']}
      </br>
      ${endDate}`;
    }
  }

  getDisplayTextForProximityBrick(selectedValues, multipleSelectionText, userBundle) {

    let strValue = '';

    if (selectedValues.hasOwnProperty('-99')) return userBundle['workspace.common.allSelection'] || this.allSelectionText;

    let totalLength = 0;
    if (selectedValues.postcodeSelection) {
      totalLength += selectedValues.postcodeSelection.selectedModelValues.length;
      totalLength += selectedValues.postcodeSelection.listUpload.length;
    }
    if (selectedValues.points) {
      totalLength += selectedValues.points.selectedModelValues.length;
      totalLength += selectedValues.points.listUpload.length;
    }
    if (selectedValues.pointOfInterest) {
      totalLength += selectedValues.pointOfInterest.selectedModelValues.length;
    }
    if (totalLength < 3) {
      // Postcode
      if (selectedValues.postcodeSelection && selectedValues.postcodeSelection.selectedModelValues.length > 0) {
        for (const selectedModelValue of selectedValues.postcodeSelection.selectedModelValues) {
          strValue += selectedModelValue['postcode'];
          strValue += '<br/>';
        }
      }
      if (selectedValues.postcodeSelection && selectedValues.postcodeSelection.listUploadSelection === true
        && selectedValues.postcodeSelection.listUpload.length > 0) {
        strValue += `Postcodes Count: ${selectedValues.postcodeSelection.listUpload[0].validCount}`;
        strValue += '<br/>';
      }

      // Points
      if (selectedValues.points && selectedValues.points.selectedModelValues.length > 0) {
        selectedValues.points.selectedModelValues.forEach(i => {
          strValue += (userBundle['workspace.collection.bric.proximity.points'] || 'Points');
          strValue += '<br/>';
        });
      }
      if (selectedValues.points && selectedValues.points.listUploadSelection === true
        && selectedValues.points.listUpload.length > 0) {
        strValue += `Points Count: ${selectedValues.points.listUpload[0].validCount}`;
        strValue += '<br/>';
      }

      if (selectedValues.pointOfInterest && selectedValues.pointOfInterest.selectedModelValues.length > 0) {
        for (let i = 0; i < selectedValues.pointOfInterest.selectedModelValues.length; i++) {
          strValue += selectedValues.pointOfInterest.selectedModelValues[i]['poiName'];
          if (i !== selectedValues.pointOfInterest.selectedModelValues.length - 1) {
            strValue += '<br/>';
          }
        }
      }
    } else {
      strValue = multipleSelectionText;
    }
    return strValue;
  }

  getDisplayTextForFrameBrick(selectedValues, userBundle: Object, decimalPipe: DecimalPipe, enabledPCM: boolean) {
    if (selectedValues.hasOwnProperty('-99')) {
      return userBundle['workspace.common.allSelection'] || this.allSelectionText;
    } else {
      const allocated = (selectedValues.allocated ? decimalPipe.transform(selectedValues.allocated) + (selectedValues.relative ? ' %' : '') : 0);
      return `${decimalPipe.transform(selectedValues.frameCount)} ${selectedValues.relative ? '%' : ''}<br/>
              ${userBundle['workspace.collection.bric.frame.heading']}<br/>
              ${!enabledPCM ?  allocated : ''}<br/>
              ${!enabledPCM ? userBundle['workspace.collection.bric.frame.noOfFramesAllocated'] : ''}
              `;
    }
  }

  getDisplayTextForMediaBrick(selectedValues): string {
    return selectedValues.selectedMediaObject.mediaTypeName;
  }

  getDisplayTextForDistributionBrick(values, userBundle): string {
    const distributionEnum = new DistributionEnum().getDistributionEnum(userBundle);
    return distributionEnum[values.distributionType];
  }

  getDisplayTextForAudienceBrick(values, userBundle: Object): string {
    try {
      const audienceEnum = new AudienceEnum().getAudienceEnum();
      let displayText = '';
      if (values.hasOwnProperty('-99')) {
        return userBundle['workspace.common.allSelection'] || this.allSelectionText;
      } else {
        let setHeightWidth = '';
        switch (values.audienceCategoryTypeCode || values.src) {
          case 'UNIVERSE':
          case 'A':
          case 'AB':
          case 'ABC1':
          case 'assets/img/audience/universe.png':
          case 'assets/img/audience/a.png':
          case 'assets/img/audience/ab.png':
          case 'assets/img/audience/abc1.png':
            setHeightWidth = 'audience-displaytext';
            break;

        }
        if (audienceEnum[values.audienceCategoryTypeCode]) {
          displayText = `<img src ='${audienceEnum[values.audienceCategoryTypeCode]}' class='${setHeightWidth}'/ >
          <span> &nbsp;&nbsp;</span><span> ${values.audienceCategoryName}</span> `;
          return displayText;
        } else if (values.src) {
          displayText = `<img src ='${values.src}' class='${setHeightWidth}'/ >
                        <span> &nbsp;&nbsp;</span><span> ${values.audienceCategoryName}</span> `;
          return displayText;
        } else {
          return values.audienceCategoryName || values.description || '';
        }
      }
    } catch (err) {
      console.log('Error in getDisplayTextForAudienceBrick function');
    }
  }

  getDisplayTextForChannelNewBrick(selectedValues, userBundle: Object, multipleSelectionText: string, selectionId: number): string {
    try {
      if (selectedValues.hasOwnProperty('-99')) {
        return userBundle['workspace.common.allSelection'] || this.allSelectionText;
      } else {
        selectedValues = selectedValues[selectionId];
        let strValue = '';
        if (selectedValues) {
          if (selectedValues.length < 4) {
            selectedValues.forEach((element) => {
              strValue += this.formatDisplayText(element.productName);
              strValue += '<br />';
            });
          } else {
            strValue = multipleSelectionText;
          }
        }
        return strValue;
      }
    } catch (err) {
      console.log('Error in getDisplayTextForChannelNewBrick function');
    }
  }

  getDisplayTextForSOTBrick(sotFrequencyEnabled: boolean, selectedValues, userBundle, pcmMode): string {
    if (selectedValues.hasOwnProperty('-99')) {
      return userBundle['workspace.common.allSelection'] || this.allSelectionText;
    }
    if (pcmMode) {
      return `Min:${this.decimalPipe.transform(selectedValues.min)}<br />Max:${this.decimalPipe.transform(selectedValues.max)}<br />Default:${this.decimalPipe.transform(selectedValues.def)}`;
    } else {
      if (selectedValues.sotType !== '1') {
        if (selectedValues.sot === 'Mixed' || selectedValues.dummySot === 'Mixed') {
          return `${sotFrequencyEnabled && selectedValues.dummySot ? selectedValues.dummySot : selectedValues.sot} %`;
        } else {
          return `${sotFrequencyEnabled && selectedValues.dummySot ? this.decimalPipe.transform(selectedValues.dummySot) : this.decimalPipe.transform(selectedValues.sot)} %`;
        }
      } else {
        return `${selectedValues.framesSlots} / ${selectedValues.maxFramesSlotsText}`;
      }
    }
  }

  getDisplayTextForPricingTagBrick(selectedValues, multipleSelectionText: string, selectionId, userBundle: Object): string {

    if (!Object.keys(selectedValues).length) {
      return '';
    }
    if (selectedValues.hasOwnProperty('-99')) {
      return userBundle['workspace.common.allSelection'] || this.allSelectionText;
    }
    selectedValues = selectedValues[selectionId];
    let displayText = '';
    if (selectedValues.length > 3) {
      displayText = multipleSelectionText;
    } else {
      if (selectedValues && selectedValues[0] && selectedValues[0].openRateCard) {
        // SM-9077: RateCard feature
        const text = `RC: ${selectedValues[0].networkName}`;
        displayText = `${this.formatDisplayText(text)}`;
      } else {
        for (const element of selectedValues) {
          displayText += `${this.formatDisplayText(element.networkName)}<br />`;
        }
      }
    }
    return displayText;
  }

  getDisplayTextForAllAudience(selectedValues, multipleSelectionText: string, userBundle) {
    if (selectedValues.hasOwnProperty('-99')) { return userBundle['workspace.common.allSelection'] || this.allSelectionText; }
    const categories = selectedValues.categories;
    let displayText = '';
    if (categories.length > 3) {
      displayText = multipleSelectionText;
    } else {
      for (const element of categories) {
        displayText += `${this.formatDisplayText(element.audienceCategoryName)}<br />`;
      }
    }
    return displayText;
  }

  getDisplayTextForProductCatalogue(selectedValues) {
    return selectedValues ? selectedValues.productCatalogueName : '';
  }

  getDisplayText(selected, userBundle: Object, multipleSelectionText: string, locationInAlphabeticalOrder?: boolean, limit?: number): string {
    try {
      if (selected.hasOwnProperty('-99')) {
        return userBundle['workspace.common.allSelection'] || this.allSelectionText;
      } else {
        const valuesLocal = selected || {};
        if (Object.keys(valuesLocal).length === 0) {
          return;
        }
        let offsetval = 0;
        const selectedvalues = [];
        _.each(valuesLocal, (val) => {
          if (val) {
            _.each(val, (obj) => {
              if (obj) {
                if (_.isArray(obj)) {
                  _.each(obj, ((o: any) => {
                    selectedvalues[offsetval] = this.formatDisplayText(o.userSelectionName, limit);
                    offsetval++;
                  }));
                } else if (Object.keys(obj).length > 0) {
                  if (obj.displayCode) {
                    selectedvalues[offsetval] = obj.code;
                  } else {
                    selectedvalues[offsetval] = obj.name || obj.lookupName || obj.productName || obj.audienceCategoryName;
                  }
                  selectedvalues[offsetval] = this.formatDisplayText(selectedvalues[offsetval], limit);
                  offsetval++;
                }
              }
            });
          }
        });

        if (offsetval === 0) {
          return null;
        } else if (offsetval > 0 && offsetval <= 3) {
          if (locationInAlphabeticalOrder) {
            selectedvalues.sort();
          }
          return selectedvalues.join('<br/>');
        } else if (offsetval > 3) {
          return multipleSelectionText;
        }
      }
    } catch (err) {
      console.log('Error in getDisplayText function');
    }
  }

  getDisplayTextForBudget(selectedValues, currencyPipe: CurrencyFormatPipe, userBundle, isPcmMode = false): string {
    let bricString = '';
    if (selectedValues.hasOwnProperty('-99')) {
      return userBundle['workspace.common.allSelection'] || this.allSelectionText;
    } else {
      if (selectedValues.price) {
        const priceLabel = isPcmMode ? userBundle['workspace.budget.bric.price.price-pcm'] : userBundle['workspace.budget.bric.price.price'];
        bricString += `<div><span class="">${priceLabel}</span>: `;

        if (selectedValues.relative) {
          bricString += `${selectedValues.price} %</div>`;
        } else {
          bricString += `${currencyPipe.transform(selectedValues.price)} </div>`;
        }
      }

      if (!isPcmMode) {
        bricString += `<div><span class="">${userBundle['workspace.budget.bric.price.priceAllocated.approx']}</span>: `;
        bricString += `${selectedValues.allocated ? (selectedValues.relative ? (selectedValues.allocated + ' %') : currencyPipe.transform(selectedValues.allocated)) : 0} </div>`;
      }

      if (selectedValues.minPrice) {
        bricString += `<div><span class="">${userBundle['workspace.common.minPrice']}</span>: `;
        bricString += `${currencyPipe.transform(selectedValues.minPrice)} </div>`;
      }

      if (selectedValues.maxPrice) {
        bricString += `<div><span class="">${userBundle['workspace.common.maxPrice']}</span>: `;
        bricString += `${currencyPipe.transform(selectedValues.maxPrice)} </div>`;
      }

      if (!bricString) {
        bricString = userBundle['workspace.pcm.export.price'];
      }
      return bricString;
    }
  }

  getDisplayTextForFrameQuality(selectedValues, userBundle: Object): string {
    if (selectedValues.hasOwnProperty('-99')) {
      return userBundle['workspace.common.allSelection'] || this.allSelectionText;
    }
    return `${selectedValues.audienceLevel}<br />${userBundle['workspace.framequality.bric.title']}`;
  }

  getDisplayTextForList(selectedValues, userBundle: Object): string {
    if (selectedValues.hasOwnProperty('-99')) {
      return userBundle['workspace.common.allSelection'] || this.allSelectionText;
    } else {
      let displayText = '';
      let excluded = 0;
      let included = 0;
      if (selectedValues.routeFrameIds && Object.keys(selectedValues.routeFrameIds).length > 0) {
        const tempRouteFrameIds = selectedValues.routeFrameIds.requestJSON;

        if (Object.keys(tempRouteFrameIds.exclude).length > 0) {
          excluded += tempRouteFrameIds.exclude.validCount;
        }
        if (Object.keys(tempRouteFrameIds.include).length > 0) {
          included += tempRouteFrameIds.include.validCount;
        }
      }
      if (selectedValues.listUpload && Object.keys(selectedValues.listUpload).length > 0) {
        const listUpload = selectedValues.listUpload;
        listUpload.fileList.forEach((element) => {
          if (element.exclude) {
            excluded += element.validCount;
          } else {
            included += element.validCount;
          }
        });
      }

      if (included > 0) {
        displayText = `${userBundle['workspace.collection.bric.list.include']}: ${included} ${userBundle['workspace.collection.bric.frame.heading']}`;
      }
      if (included > 0 && excluded > 0) {
        displayText += '<br />';
      }
      if (excluded > 0) {
        displayText += `${userBundle['workspace.collection.bric.list.exclude']}: ${excluded} ${userBundle['workspace.collection.bric.frame.heading']}`;
      }

      return displayText;
    }
  }

  getDisplayTextForPricing(solutionPrice, selectedValues, titleText: string, currencyPipe: CurrencyFormatPipe) {
    const value = solutionPrice ? parseFloat(solutionPrice[selectedValues]) : 0;
    return `${currencyPipe.transform(value)}<br />${titleText}`;
  }

  getDisplayTextForTag(selectedValues, userBundle: Object, multipleSelectionText: string) {
    let strValue = '';
    const selectedTags = [];

    if (selectedValues.hasOwnProperty('-99')) {
      return userBundle['workspace.common.allSelection'] || this.allSelectionText;
    } else {
      _.each(selectedValues.allTabSelection, (val) => {
        if (val.length > 0) {
          _.each(val, (tag) => {
            selectedTags.push(tag);
          });
        }
      });
      if (selectedTags.length < 4) {
        selectedTags.forEach((element) => {
          strValue += this.formatDisplayText(element.tagName);
          strValue += '<br />';
        });
      } else {
        strValue = multipleSelectionText;
      }
      return strValue;
    }
  }

  getDisplayTextForVolume(selectedValues, userBundle, decimalPipe: DecimalPipe, impressionsText: string, allocatedText: string) {
    if (selectedValues.hasOwnProperty('-99')) {
      return userBundle['workspace.common.allSelection'] || this.allSelectionText;
    } else {
      const impressions = `${decimalPipe.transform(selectedValues.impressions)} ${selectedValues.relative ? '%' : ''}<br/>`;
      const allocated = `${selectedValues.allocated ? decimalPipe.transform(selectedValues.allocated) + (selectedValues.relative ? ' %' : '') : 0} <br/>  ${allocatedText}`;
      return `${selectedValues.impressions ? impressions : ''} ${impressionsText} <br/>
      ${allocatedText ? allocated : ''}
      ${selectedValues.allocateAllFrames ? '<em class="fa fa-clone icontruefalse" aria-hidden="true"></em>' : ''}`;
    }
  }

  /**
   * @description It formats display text by limit.
   * @author Shivani Patel
   * @param {String} [text='']
   * @param {number} [limit=12]
   * @returns
   * @memberof DisplayTextGenerator
   */
  formatDisplayText(text: string = '', limit = 12) {
    return text.length > limit ? `${text.substring(0, limit)}...` : text;
  }

  generateReqJsonAndDisplayTextForPrimaryAudience(selectedPrimaryAudience, multipleSelectionText: string, userBundle: Object) {
    if (selectedPrimaryAudience.hasOwnProperty('-99')) {
      return userBundle['workspace.common.allSelection'] || this.allSelectionText;
    } else {
      const obj = {
        displayText: [],
        selectionPrimaryAudiences: {}
      };
      for (const key in selectedPrimaryAudience) {
        if (selectedPrimaryAudience.hasOwnProperty(key)) {
          const audienceCategories = selectedPrimaryAudience[key];
          obj.selectionPrimaryAudiences[key] = [];
          audienceCategories.forEach((audienceCategory) => {
            obj.displayText.push(audienceCategory.audienceCategoryName);
            obj.selectionPrimaryAudiences[key].push(audienceCategory.audienceCategoryId);
          });
        }
      }
      if (obj.displayText.length > 3) {
        return multipleSelectionText;
      } else {
        let displayText = '';
        obj.displayText.forEach((displayElement) => {
          displayText += displayElement;
          displayText += '<br/>';
        });
        return displayText;
      }
    }
  }

  getDisplayTextForMultiTarget(userBundle, enabledPCM, selectedValues) {
    if (enabledPCM) {
      let label = '<div class="contents">';
      label = `${label}${userBundle['workspace.pcm.bricname.multitarget']}<br />`;
      if (selectedValues) {
        let selectedCount = 0;
        if (selectedValues.allowDays) {
          label += `${userBundle['workspace.pcm.multiTarget.bric.allowDays']}<br />`;
          selectedCount++;
        }
        if (selectedValues.allowDayParts) {
          label += `${userBundle['workspace.pcm.multiTarget.bric.allowDayParts']}<br />`;
          selectedCount++;
        }
        if (selectedValues.allowHours) {
          label += `${userBundle['workspace.pcm.multiTarget.bric.allowHours']}<br />`;
          selectedCount++;
        }
        if (selectedValues.allowSOT) {
          label += `${userBundle['workspace.pcm.multiTarget.bric.allowSOT']}<br />`;
          selectedCount++;
        }
        if (selectedValues.allowCustomSOT) {
          label += `${userBundle['workspace.pcm.multiTarget.bric.allowCustomSOT']}`;
          selectedCount++;
        }
        label = `${label}</div>`;
        if (selectedCount > 3) {
          label = `${userBundle['worksapce.pcm.multiTarget.multipleSelection']}<br />`;
        }
        return label;
      } else {
        return userBundle['workspace.pcm.bricname.multitarget'];
      }
    } else {
      return this.userBundle['workspace.bricname.multitarget'];
    }
  }
}
