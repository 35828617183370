<app-filter-layout [resolveObject]="resolveObject" (closeClick)="close()" (saveClick)="save()">
  <div class="form-group-wrapper_geo grpCnt">
    <div class="form-group_geo">
      <div *ngFor="let lookup of lookups.lookup; trackBy: trackBySelectionId; let i = index;" id="location-{{i}}"
        class="col-sm-6">
        <span class="text"></span>
        <input type="radio" class="radio_geo" [checked]="selectedItem === i" (change)="showContent(i)"
          name="rb-location" id="rb-{{i}} ">
        <label
          [ngClass]="{'highlight-tab': isDataAvailable(lookup.selectionIds? lookup.selectionIds : lookup.selectionId)}"
          class="radio-label" for="rb-{{i}} "><em class="fa fa-circle-o "></em> {{lookup.displayName}}</label>
      </div>
    </div>
  </div>
  <div class="form-group-wrapper_geo ">
    <h3 class="form-title_geo"><span class="selected-title">{{initialConfig.userBundle["geoplanner.text.searchFor"] ||
        'Search for'}}
        {{searchTitle}}</span></h3>
    <div class="content-area" [ngClass]="{'readOnly' : resolveObject.readOnlyModal}">
      <div *ngFor="let lookup of lookups.lookup; trackBy: trackBySelectionId; let i = index">
        <div id="tab-{{i}}" class="tab-content" [ngStyle]="{'display': i == selectedItem?'block':'none'}">
          <div *ngIf="!lookup.selectionIds">
            <app-lookup-view [lookupType]="lookup.lookupType" [selectionId]="lookup.selectionId"
              [(ngModel)]="selected[lookup.selectionId]" [selectAll]="lookup.selectAll"
              [placeholder]="lookup.displayName" [regexp]="lookup.regExp" [fileList]="fileList"
              [showFileUploader]="initialConfig.uiControl.allowFileUploadForLocationBric" [isAutoHeight]="true"
              [configLookup]="configLookup?configLookup[lookup.selectionId]: null">
            </app-lookup-view>
          </div>
          <div *ngIf="lookup.selectionIds && !initialConfig.uiControl.geoLocationTree">
            <div *ngFor="let selectionid of lookup.selectionIds; trackBy: trackByItem;">
              <app-lookup-view class="left" [lookupType]="lookup.lookupType" [selectionId]="selectionid"
                [(ngModel)]="selected[selectionid]" [selectAll]="lookup.selectAll" [fileList]="fileList"
                [showFileUploader]="initialConfig.uiControl.allowFileUploadForLocationBric" [isAutoHeight]="true"
                [placeholder]="initialConfig.userBundle['ui.workspace.selectionId.'+selectionid+'.name']"
                [regexp]="lookup.regExp" [configLookup]="configLookup?configLookup[selectionid]: null"
                style="width:100%">
              </app-lookup-view>
            </div>
          </div>
          <div *ngIf="lookup.selectionIds && initialConfig.uiControl.geoLocationTree">
            <app-tree-view id="geoTree" [data]="treeData" [options]="options" [templateProps]="templateProps" [skipFilter]="true"
              (onSelect)="onSelectNode($event)" (onDeSelect)="onDeSelectNode($event)" emitEvent="true">
            </app-tree-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-filter-layout>
