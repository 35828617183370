import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DataShareService } from '../../../core/services/data-share.service';
import { SharedService } from '../../../core/services/shared.service';
import { FilterDataService } from '../../../core/services/filter-data.service';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { BrickBaseService } from '../../../core/services/brick-base.service';
import { FormatBase } from '../format-base';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';
import { ObjectiveService } from '../../../workspace/objective/objective.service';
import { StateService } from '../../../core/services';

@Component({
  selector: 'app-format-filter',
  templateUrl: './format-filter.component.html',
  styleUrls: ['./format-filter.component.css']
})
export class FormatFilterComponent extends FormatBase implements OnInit {

  /**
   * @description It will take data to configure filter window.
   * @type {*}
   * @memberof FormatFilterComponent
   */
  @Input() resolveObject: any;

  /**
   * @description It calls event to close filter winodw.
   * @memberof FormatFilterComponent
   */
  @Output() closeSidebar = new EventEmitter<string>();

  /**
   * Creates an instance of FormatFilterComponent.
   * @author Alkesh Shah
   * @param {DataShareService} dataShareService Service to share data between compoenents.
   * @param {SharedService} sharedService Common shared service.
   * @param {FilterDataService} filterDataService Service which used to handle filter data.
   * @param {LogHelperService} logHelper Service for log messages.
   * @param {BrickBaseService} brickBaseService Service for brick-base data.
   * @memberof FormatFilterComponent
   */
  constructor(
    dataShareService: DataShareService,
    sharedService: SharedService,
    private filterDataService: FilterDataService,
    private logHelper: LogHelperService,
    brickBaseService: BrickBaseService,
    appHeaderService: AppHeaderService,
    objectiveService: ObjectiveService,
    stateService: StateService
  ) {
    super(dataShareService, sharedService, brickBaseService, appHeaderService, objectiveService, stateService);
  }

  /**
   * @description angular life cycle hook - called on component init
   * @author Alkesh Shah
   * @memberof FormatFilterComponent
   */
  ngOnInit(): void {
    this.init(this.resolveObject.ColumnConfig, this.resolveObject.SelectedValue);
  }

  /**
   * @description callback method on save button click
   * @author Alkesh Shah
   * @memberof FormatFilterComponent
   */
  save(): void {
    if (this.isValidSelection()) {
      this.filterDataService.setFormat(this.selected);
      this.closeSidebar.emit();
    } else {
      this.logHelper.logError(this.initialConfig.userBundle['common.error.noDataSelect']);
    }
  }

  /**
   * @description callback method on close button click
   * @author Alkesh Shah
   * @memberof FormatFilterComponent
   */
  close(): void {
    this.closeSidebar.emit();
  }
}
