import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DataShareService, LogHelperService } from '../../../core/services/index';
import { CellValues } from './../../../models/workspace';
import { AudienceCategory } from '../../../models/audience-filter.model';
import { AudienceBricService, CellAttributeService, BrickBaseService } from './../../../core/services';
import { AudienceBase } from '../audience-base';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-audience',
  templateUrl: './audience-modal.component.html',
  styleUrls: ['./audience-modal.component.css'],
})

export class AudienceModalComponent extends AudienceBase implements OnInit {

  @Input() resolveObject: CellValues;
  @ViewChild('instance') typeaheadInstance: NgbTypeahead;
  focus$ = [];
  click$ = [];
  readOnlyModal = false;

  constructor(dataService: DataShareService, audienceBricService: AudienceBricService, private cellAttributeService: CellAttributeService, private brickBaseService: BrickBaseService, private logHelperService: LogHelperService) {
    super(dataService, audienceBricService);
  }

  ngOnInit(): void {
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.init(this.resolveObject.brick.audienceCategoryGroupId);
    if (this.resolveObject.selectedValues) {
      this.selectedAudience = this.resolveObject.selectedValues;
      this.initializeAudience();
    }
    this.previousValues = Object.assign({},this.selectedAudience);
  }

  initializeAudience = () => {
    if (this.resolveObject.brick.audienceCategoryGroupId === 3) {
      this.audienceCategoryType[0].audienceCategory.forEach((obj: any, i) => {
        if (obj.audienceCategory && obj.audienceCategory.length > 0) {
          obj.audienceCategory.forEach((categoryObj) => {
            if (this.selectedAudience.audienceCategoryId === categoryObj.audienceCategoryId) {
              this.selectedAudience = categoryObj;
              this.item[i] = this.selectedAudience;
              this.selectedIndexTagInput = i;
              this.valueText[i] = this.selectedAudience.audienceCategoryName;
              this.selectedAudience.audienceCategoryTypeCode = this.audienceCategoryType[0].audienceCategoryTypeCode;
            }
          });
        }
      });
    } else {
      this.audienceCategoryType.forEach((obj, i) => {
        if (obj.audienceCategoryTypeCode !== this.options.PrimaryAudienceType) {
          obj.audienceCategory.forEach((categoryObj) => {
            if (this.selectedAudience.audienceCategoryId === categoryObj.audienceCategoryId) {
              this.item[i] = this.selectedAudience;
              this.valueText[i] = this.selectedAudience.description;
              this.selectedIndexTagInput = i;
            }
          });
        } else {
          obj.audienceCategory.forEach((categoryObj, j) => {
            if (this.selectedAudience.audienceCategoryId === categoryObj.audienceCategoryId) {
              this.selectedAudience = categoryObj;
              this.selectedIndex = j;
              this.selectedAudience.audienceCategoryTypeCode = this.audienceCategoryType[0].audienceCategoryTypeCode;
            }
          });
        }
      });
    }
  }

  /**
   * Search audience dropdown item text formatter
   * @memberof GeoMapComponent
   */
  searchAudienceCategoryFormatter = (item: AudienceCategory): string => {
    return item.audienceCategoryName;
  }

  searchAudienceCategory = (list: AudienceCategory[], index: number) => (text$: Observable<string>): Observable<any[]> => {
    const debouncedText$ = text$.debounceTime(200).distinctUntilChanged();
    this.click$[index] = new Subject<string>();
    const clicksWithClosedPopup$ = this.click$[index].pipe(filter(() => !this.typeaheadInstance.isPopupOpen()));
    this.focus$[index] = new Subject<string>();
    const inputFocus$ = this.focus$[index];
    return merge(...[debouncedText$, inputFocus$, clicksWithClosedPopup$]).map((term: string) => {
      if (term === '') {
        return list;
      } else if (this.item[index] && this.item[index].audienceCategoryName === term) {
        return [];
      } else {
        return list.filter(v => v.audienceCategoryName.toLowerCase().indexOf(term.toLowerCase()) > -1);
      }
    });
  }

  onTextChange(event, index) {
    if (!this.item[index] || this.item[index].audienceCategoryName !== event.target.value) {
      this.valueText[index] = '';
      // tslint:disable-next-line: no-string-literal
      this.typeaheadInstance['_inputValueBackup'] = '';
      this.item[index] = null;
      this.selectedIndexTagInput = null;
      this.selectedAudience = new AudienceCategory();
    }
  }

  /**
   * @description close event for model popup
   * @author Kishan Patel
   * @param {*} event
   * @memberof AudienceModalComponent
   */
  onModalClosed(event) {
    if (event.reason === 'escape' && !(JSON.stringify(this.previousValues) == JSON.stringify(this.selectedAudience))) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * @description save click event for modal popup
   * @author Kishan Patel
   * @param {*} event
   * @memberof AudienceModalComponent
   */
  onModalSaved(event) {
    if (this.selectedAudience.hasOwnProperty('-99') || this.selectedAudience.audienceCategoryId) {
      const values: CellValues = new CellValues();
      values.brick = this.resolveObject.brick;

      values.selectedValues = this.selectedAudience;
      values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Audience, this.selectedAudience);
      if (this.resolveObject.brick.bricid === 2) {
        values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Audience, this.selectedAudience);
      } else {
        values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.SecondaryAudience,
          this.selectedAudience);
      }
      this.previousValues = Object.assign({},this.resolveObject.selectedValues);
      event.activeModal.close(values);
    } else {
      this.logHelperService.logError(this.userBundle['common.error.noDataSelect']);
    }
  }
  onFocus($event, i) {
    this.focus$[i].next($event.target.value);
  }
  onClick($event: any, i) {
    this.click$[i].next($event.target.value);
  }

  trackByAudienceCategoryType(index, categoryType) {
    return categoryType?.audienceCategoryTypeId;
  }

  trackByAudienceCategory(index, category) {
    return category?.audienceCategoryId;
  }

  trackByItem(index, item) {
    return item;
  }
}
