import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { CellValues } from '../../models/workspace/cell-values';
import { DataShareService, CellAttributeService, BrickBaseService, LogHelperService } from '../../core/services';
import { AudienceCategoryGroup, UiControl, AudienceCategory, TemplateProps, UserModel } from '../../models';
import { TreeViewComponent } from '../../core/components/tree-view/tree-view.component';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-all-audience',
  templateUrl: './all-audience.component.html',
  styleUrls: ['./all-audience.component.css']
})
export class AllAudienceComponent implements OnInit, AfterViewInit {
  @Input() resolveObject: CellValues;
  @ViewChild(TreeViewComponent)
  public treeViewComponent: TreeViewComponent;
  readOnlyModal = false;
  userBundle: any;
  uiControl: UiControl;
  audienceCategoryGroup: AudienceCategoryGroup[];
  user: UserModel;
  tabList: AudienceCategoryGroup[] = [];
  audienceCategory: AudienceCategory[];
  options = {
    idField: 'audienceCategoryId',
    displayField: 'audienceCategoryName',
    childrenField: 'audienceCategory',
    useCheckbox: true,
    scrollContainer: document.body.parentElement
  };
  templateProps: TemplateProps;
  activeTabIndex = 0;
  defaultSegmentWeight = 5;
  allAudienceState = {
    categories: [],
    segmentWeight: this.defaultSegmentWeight,
    lock: false,
    segmentSelection: 'OR',
    selectAll: false
  };
  sliderOptions: Options = {
    floor: 0,
    ceil: 10,
    showTicks: true,
    noSwitching: true,
    enforceStep: false,
    disabled: true
  };
  constructor(
    private dataShareService: DataShareService,
    private cellAttributeService: CellAttributeService,
    private brickBaseService: BrickBaseService,
    private logHelperService: LogHelperService
  ) { }

  ngOnInit() {
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.audienceCategoryGroup = this.dataShareService.getInitialConfigByKey('audienceCategoryGroup');
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.user = this.dataShareService.getUserModel();
    this.templateProps = {
      displayId: 'audienceCategoryId',
      displayName: 'audienceCategoryName',
      displayRadioButton: false,
      searchPlaceHolder: this.userBundle.search,
      showId: false,
      allowCustomCheckBoxEvents: true
    };
    this.tabList = this.populateTabList();
    if (this.tabList && this.tabList.length) {
      this.onTabChange(0); // By default pass the 0th Index
    }
    if (this.resolveObject.selectedValues && !this.resolveObject.selectedValues.hasOwnProperty('-99')) {
      this.allAudienceState = {
        ...this.allAudienceState,
        categories: this.resolveObject.selectedValues.categories,
        segmentSelection: this.resolveObject.selectedValues.segmentSelection
      };
      this.allAudienceState.categories.forEach((element) => {
        element.selectedForSegmentWeight = false;
      });
      // Have to use Timeout //
      // issue Ref: https://github.com/angular-slider/ngx-slider/issues/58
      setTimeout(() => {
        this.sliderOptions = {
          ...this.sliderOptions
        };
      }, 400);
    }
  }

  onSelectAll(value) {
    if (value) {
      for (let i = 0; i < this.uiControl.maxSegmentSelection; i++) {
        if (this.allAudienceState.categories.length < this.uiControl.maxSegmentSelection) {
          const id = this.treeViewComponent.tree.treeModel.nodes[i].audienceCategoryId;
          this.treeViewComponent.tree.treeModel.getNodeById(id).setIsSelected(value);
        }
      }
    } else {
      Object.keys(this.treeViewComponent.tree.treeModel.selectedLeafNodeIds).forEach((key) => {
        this.treeViewComponent.tree.treeModel.getNodeById(key).setIsSelected(value);
      });
    }
    // Reset the flag so that when user selects/deselects the node individually, SelectAll
    // checked will get removed
    this.treeViewComponent.manuallySelectedAll = false;
  }

  ngAfterViewInit() {
    /* If I load the nodes via directive, and immediately look for the nodes via "treeModel.getNodeById()" I get an exception.
     You can get around this by delaying the setIsSelected by a setTimeout.
     This behavior makes sense, because of the angular lifecycle.
     The data in the directive has to go through the OnChanges routine before they can be manipulated.
    Delaying it a cycle makes it work.*/
    setTimeout(() => {
      if (this.resolveObject.selectedValues != null && !this.resolveObject.selectedValues.hasOwnProperty('-99')) {
        this.resolveObject.selectedValues.categories.forEach((audieceCategory) => {
          this.treeViewComponent.tree.treeModel.getNodeById(audieceCategory.audienceCategoryId).setIsSelected(true);
        });
      }
    });
  }

  /**
   * @description Subscription of the even emitted from the tree-view-component when user selectes the checkbox
   * @author Shreni Shah
   * @date 2019-07-18
   * @param {*} selectEvent
   * @memberof AllAudienceComponent
   */
  onSelectNode(selectEvent) {
    if (Object.keys(selectEvent.treeModel.selectedLeafNodeIds).length > this.uiControl.maxSegmentSelection) {
      setTimeout(() => {
        selectEvent.node.setIsSelected(false);
        delete selectEvent.treeModel.selectedLeafNodeIds[selectEvent.node.id];
        this.refreshTreeView();
      });
    } else {
      if (selectEvent && selectEvent.node) {
        this.addNodesToLocalSate(selectEvent.node.data);
      }
    }
  }

  /**
   * @description Subscription of the event emitted from the tree-view-component when user unchecks the checkbox
   * @author Shreni Shah
   * @date 2019-07-18
   * @param {*} deselectEvent
   * @memberof AllAudienceComponent
   */
  onDeSelectNode(deselectEvent) {
    delete deselectEvent.treeModel.selectedLeafNodeIds[deselectEvent.node.id];
    this.refreshTreeView();
    this.deleteNodesFromLocalSate(deselectEvent.node.id);
  }

  /**
   * @description Method to delete the nodes from the local state
   * @author Shreni Shah
   * @date 2019-07-18
   * @param {*} id
   * @memberof AllAudienceComponent
   */
  deleteNodesFromLocalSate(id) {
    if (this.allAudienceState && this.allAudienceState.categories.length) {
      const index = this.allAudienceState.categories.findIndex((item) => {
        return item.audienceCategoryId === id;
      });
      if (index !== -1) {
        this.allAudienceState.categories.splice(index, 1);
      }
    }
  }

  /**
   * @description Method to add the notes to  local state
   * @author Shreni Shah
   * @date 2019-07-18
   * @param {*} node
   * @memberof AllAudienceComponent
   */
  addNodesToLocalSate(node) {
    node = {
      ...node,
      weight: this.defaultSegmentWeight,
      selectedForSegmentWeight: false
    };
    if (this.allAudienceState.categories.length) {
      const category = this.allAudienceState.categories.find((element) => {
        return element.audienceCategoryId === node.audienceCategoryId;
      });
      if (!category) {
        this.allAudienceState.categories.push(node);
      }
    } else {
      this.allAudienceState.categories.push(node);
    }
  }

  /**
   * @description Method populates the audienceCategory beased on the index of the tab
   * @author Shreni Shah
   * @date 2019-07-18
   * @param {*} index
   * @memberof AllAudienceComponent
   */
  onTabChange(index) {
    // Get the list of Audience Category from Audience Category Type //
    this.activeTabIndex = index;
    this.audienceCategory =
      this.tabList[index].audienceCategoryType && this.tabList[index].audienceCategoryType[0] ?
        this.tabList[index].audienceCategoryType[0].audienceCategory : [];
  }
  /**
   * @description Method to refresh the tree after making selection, deselection , adding and deleting the node
   * @author Shreni Shah
   * @date 2019-07-18
   * @memberof AllAudienceComponent
   */
  refreshTreeView() {
    this.treeViewComponent.tree.treeModel.update();
  }

  /**
   * @description Mehtod to remove the selected node on click of the cross Icon
   * @author Shreni Shah
   * @date 2019-07-18
   * @param {*} deletedItem
   * @memberof AllAudienceComponent
   */
  removeItem(deletedItem) {
    delete this.treeViewComponent.tree.treeModel.selectedLeafNodeIds[deletedItem.audienceCategoryId];
    this.refreshTreeView();
    this.deleteNodesFromLocalSate(deletedItem.audienceCategoryId);
    this.treeViewComponent.allChecked = false;
  }

  /**
   * @description Filters the AudienceCategoryGroup based on secondaryAudienceGroups giving in Initial Config
   * @author Shreni Shah
   * @date 2019-07-16
   * @memberof AllAudienceComponent
   */
  populateTabList() {
    const tabList = [];
    if (this.uiControl.secondaryAudienceGroups && this.uiControl.secondaryAudienceGroups.length) {
      this.uiControl.secondaryAudienceGroups.forEach((id) => {
        const audienceCategoryGroup = this.audienceCategoryGroup.filter((item) => {
          return id === item.audienceCategoryGroupId;
        });
        if (audienceCategoryGroup.length > 0) {
          tabList.push(audienceCategoryGroup[0]);
        }
      });
    }
    return tabList;
  }

  selectItem(item) {
    if (this.allAudienceState && this.allAudienceState.categories.length) {
      const index = this.allAudienceState.categories.findIndex((data) => {
        return data.audienceCategoryId === item.audienceCategoryId;
      });
      this.allAudienceState.categories[index].selectedForSegmentWeight = !this.allAudienceState.categories[index].selectedForSegmentWeight;
    }
    this.toggleSliderVisibility();
  }

  onSliderValueChange() {
    if (this.allAudienceState && this.allAudienceState.categories.length) {
      this.allAudienceState.categories.forEach((element) => {
        if (element.selectedForSegmentWeight) {
          element.weight = this.allAudienceState.segmentWeight;
        }
      });
    }
  }

  /**
   * @description Checkbox Change Event
   * @author Shreni Shah
   * @date 2019-07-19
   * @memberof AllAudienceComponent
   */
  onLockChange() {
    this.toggleSliderVisibility();
  }
  /** Method that to control the visiblity of Slider
   * @description
   * @author Shreni Shah
   * @date 2019-07-19
   * @memberof AllAudienceComponent
   */
  toggleSliderVisibility() {
    // If Check box is not checked, then disable the slider
    if (!this.allAudienceState.lock) {
      this.sliderOptions = {
        ...this.sliderOptions,
        disabled: true
      };
    } else if // If checkbox is checked, enable the slider
      (this.allAudienceState.lock
      && this.allAudienceState.categories
      && this.allAudienceState.categories.length) {
      const selectedItems = this.allAudienceState.categories.filter((element) => {
        return element.selectedForSegmentWeight === true;
      });
      if (selectedItems.length > 0) {
        this.sliderOptions = {
          ...this.sliderOptions,
          disabled: false
        };
      } else {  // If no items are selcted, then disable the slider
        this.sliderOptions = {
          ...this.sliderOptions,
          disabled: true
        };
      }
    }
  }

  /**
   * @description Closes the Modal Popup
   * @author Shreni Shah
   * @date 2019-07-16
   * @param {*} event
   * @memberof AllAudienceComponent
   */
  onModalClosed(event) {
    if (event.reason === 'escape' && this.allAudienceState && this.allAudienceState.categories && this.allAudienceState.categories.length) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * @description Method to close the modal popup on click of OK button and perform the needed operations
   * @author Shreni Shah
   * @date 2019-07-16
   * @param {*} event
   * @memberof AllAudienceComponent
   */
  onModalSaved(event) {
    if (this.allAudienceState && this.allAudienceState.categories && this.allAudienceState.categories.length) {
      const values: CellValues = new CellValues();
      values.brick = this.resolveObject.brick;
      values.selectedValues = this.allAudienceState;
      values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.AllAudience, values.selectedValues);
      values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.AllAudience, values.selectedValues);
      values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.AllAudience, values.selectedValues);
      event.activeModal.close(values);
    } else {
      this.logHelperService.logError(this.userBundle['common.error.noDataSelect']);
    }
  }
  /**
   * @description resets the treeview to original state
   * @author Shreni Shah
   * @date 2019-08-20
   * @memberof AllAudienceComponent
   */
  removeAllSelected() {
    if (this.allAudienceState.categories && this.allAudienceState.categories.length) {
      const categoriesLength = this.allAudienceState.categories.length;
      for (let i = 0; i < categoriesLength; i++) {
        const category = this.allAudienceState.categories[i];
        delete this.treeViewComponent.tree.treeModel.selectedLeafNodeIds[category.audienceCategoryId];
      }
      this.refreshTreeView();
    }
    this.allAudienceState = {
      categories: [],
      segmentWeight: this.defaultSegmentWeight,
      lock: false,
      segmentSelection: 'OR',
      selectAll: false
    };
    this.treeViewComponent.allChecked = false;
  }

  trackByAudienceCategoryGroup(index, tab) {
    return tab?.audienceCategoryGroupId;
  }

  trackByAudienceCategory(index, item) {
    return item?.audienceCategoryId
  }

}
