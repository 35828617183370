import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LogHelperService } from '../../core/services/log-helper.service';
import { ConfirmationModalComponent } from '../../result/confirmation-modal/confirmation-modal.component';
import { SbModalPopupService } from '../components/sb-modal-popup';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StateService } from './state.service';
import { isExist } from '../utils/isExist';
import { getFormData } from '../utils/formData';
import { GLOBAL } from '../utils/app.constant';
import { DataShareService } from './data-share.service';
import { BookingLine, Discount } from '../../commercial/commercial.model';
import { _throw } from 'rxjs/observable/throw';
import { environment } from '../../../../../../environments/environment';
import { Router } from '@angular/router';
import { EnvironmentId } from '../enum';
@Injectable()
export class CommercialService {
  /**
   * @description Request Headers
   * @private
   * @memberof CampaignService
   */
  contentType = { 'Content-Type': 'application/x-www-form-urlencoded' };

  private headers = new HttpHeaders(this.contentType);

  private campaignDetailSource = new Subject();
  public campaignDetailData$ = this.campaignDetailSource.asObservable();

  private gridDataSource = new Subject();
  public gridData$ = this.gridDataSource.asObservable();

  private discountSource: Subject<Discount[]> = new Subject();
  public discounts$ = this.discountSource.asObservable();

  $resetBookingStatusId: Subject<boolean> = new Subject<any>();

  public goToOtherPage = false;

  public isStatusChanged = false;

  private _isTitlePersist = false;

  constructor(
    private sbModalPopupService: SbModalPopupService,
    private http: HttpClient,
    private logHelperService: LogHelperService,
    private stateService: StateService,
    private dataShareService: DataShareService,
    private router: Router
  ) { }

  setGoToOtherPage(val) {
    this.goToOtherPage = val;
  }

  getGoToOtherPage() {
    return this.goToOtherPage;
  }

  /**
   * @description Notifies Grid
   * @author Shivani Patel
   * @param {*} value
   * @memberof CampaignService
   */
  notifyGrid(value) {
    this.gridDataSource.next(value);
  }

  updateDiscounts(value) {
    this.discountSource.next(value);
  }

  /**
   * @description Clears brand in all discount lines
   * @author Amit Mahida
   * @param {Discount[]} discounts
   * @returns {Discount[]}
   * @memberof CommercialService
   */
  clearBrandInDiscounts(discounts: Discount[]): Discount[] {
    for (const row of discounts) {
      delete row.brandId;
      delete row.brandName;
    }
    return discounts;
  }

  notifyCampaignDetails(value, index?: number, columnName?: string) {
    value.rowIndex = index;
    value.columnName = columnName;
    this.campaignDetailSource.next(value);
  }

  // setter and getter for TitlePersistent
  get IsTitlePersist(): boolean {
    return this._isTitlePersist;
  }

  set IsTitlePersist(value: boolean) {
    this._isTitlePersist = value;
  }

  openConfirmationModal(
    message: string,
    title: string,
    color: string,
    reset = false,
    needFooter = false,
    saveButton?: string,
    cancelButton?: string,
    nextStateURL?: any,
    isForStatusChange = false,
    isForProcessingParam = false
  ): Promise<any> | void {
    this.sbModalPopupService
      .open(ConfirmationModalComponent, {
        color,
        SelectedValue: message,
        SelectedTitle: title.toUpperCase(),
        confirmation: true,
        needFooter: needFooter,
        saveButton: saveButton,
        cancelButton: cancelButton,
        isForProcessing: isForProcessingParam
      })
      .result.then(
        () => {

          if (isForStatusChange) {
            this.$resetBookingStatusId.next(true);
            this.isStatusChanged = false;
          }
          if (nextStateURL) {
            this.router.navigate([nextStateURL]);
          }
          if (isForProcessingParam) {
            return new Promise((resolve) => {
              resolve(true);
            });
          }
          console.log('Confirmation Pop up closed successfully');
        },
        (reason) => {
          if (isForProcessingParam) {
            return new Promise((resolve) => {
              resolve(true);
            });
          }
          console.log(reason);
        }
      );
  }
  /**
   * @description To get request options
   * @author Kishan Patel
   * @param {object} params
   * @returns
   * @memberof CampaignService
   */
  getOptions(params: object) {
    return {
      body: getFormData(params),
      headers: this.headers,
    };
  }

  /**
   * @description Fetch campaign details from server
   * @author Amit Mahida
   * @param {object} reqParams
   * @returns
   * @memberof CampaignService
   */
  validateCommercialScreen(reqParams: object) {
    const param = {
      action: 'validateCommercialScreen',
    };
    const paramsData = Object.assign(reqParams, param);
    const reqUrl = this.dataShareService.getServiceCallUrlByKey(
      'BOOK_COMMERCIAL_CAMPAIGN'
    );
    return this.http
      .request(GLOBAL.HTTP_METHOD, reqUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
        }),
        body: paramsData,
      })
      .map((res: Response) => {
        return res;
      })
      .catch((error: Response | any) => {
        this.logHelperService.logError(error.message || error);
        return _throw(error.message || error);
      });
  }

  makeBookCommercialCall(action, urlKey, param1) {
    const param = {
      action: action,
      referer: environment.landingAppUrl,
    };
    const reqUrl = this.dataShareService.getServiceCallUrlByKey(urlKey);
    const paramsData = Object.assign(param1, param);
    return this.http
      .request(GLOBAL.HTTP_METHOD, reqUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
        }),
        body: paramsData,
      })
      .map((res: Response) => {
        return res;
      })
      .catch((error: Response | any) => {
        if (error.status) {
          this.logHelperService.logError(error.message || error);
        }
        return Observable.throw(error.message || error);
      });
  }

  makeRequestCommercialCall(action, urlKey, param1) {
    const param = {
      action: action,
      referer: environment.landingAppUrl,
    };
    const reqUrl = this.dataShareService.getServiceCallUrlByKey(urlKey);
    const paramsData = Object.assign(param1, param);
    return this.http
      .request(GLOBAL.HTTP_METHOD, reqUrl, this.getOptions(paramsData))
      .map((res: Response) => {
        return res;
      })
      .catch((error: Response | any) => {
        this.logHelperService.logError(error.message || error);
        return Observable.throwError(error.message || error);
      });
  }

  saveCommercialCampaignData(param1) {
    const environmentId = this.dataShareService.getInitialConfig().systemData.environmentId;
    const environmentCountyId: typeof EnvironmentId = EnvironmentId;
    if (environmentId === environmentCountyId.US) {
      const parsedData = JSON.parse(param1.data);
      parsedData['uiControl'] = {};
      parsedData['uiControl']['isTitlePersist'] = this.IsTitlePersist;
      param1.data = JSON.stringify(parsedData);
    }
    return this.makeBookCommercialCall(
      'bookBricsCampaign',
      'BOOK_COMMERCIAL_CAMPAIGN',
      param1
    );
  }

  revertOptionToProprosal(param1) {
    const environmentId = this.dataShareService.getInitialConfig().systemData.environmentId;
    const environmentCountyId: typeof EnvironmentId = EnvironmentId;
    if (environmentId === environmentCountyId.US) {
      const parsedData = JSON.parse(param1.data);
      parsedData['uiControl'] = {};
      parsedData['uiControl']['isTitlePersist'] = this.IsTitlePersist;
      param1.data = JSON.stringify(parsedData);
    }
    return this.makeBookCommercialCall(
      'revertOptionToProposal',
      'BOOK_COMMERCIAL_CAMPAIGN',
      param1
    );
  }

  updateCommercialInfo(param1) {
    const environmentId = this.dataShareService.getInitialConfig().systemData.environmentId;
    const environmentCountyId: typeof EnvironmentId = EnvironmentId;
    if (environmentId === environmentCountyId.US) {
      const parsedData = JSON.parse(param1.data);
      parsedData['uiControl'] = {};
      parsedData['uiControl']['isTitlePersist'] = this.IsTitlePersist;
      param1.data = JSON.stringify(parsedData);
    }
    return this.makeBookCommercialCall(
      'updateCommercialInfo',
      'BOOK_COMMERCIAL_CAMPAIGN',
      param1
    );
  }

  campaignRequestBooking(param1) {
    return this.makeRequestCommercialCall(
      'requestBooking',
      'CAMPAIGN_REQUEST_URL',
      param1
    );
  }

  campaignSave(param1) {
    return this.makeRequestCommercialCall(
      'saveCampaign',
      'CAMPAIGN_REQUEST_URL',
      param1
    );
  }

  loadCommercialCampaignDetails(parameter) {
    const param = {
      action: 'getCommercialDetail',
    };
    const reqUrl = this.dataShareService.getServiceCallUrlByKey(
      'LOAD_COMMERCIAL_CAMPAIGN'
    );
    const paramsData = Object.assign(parameter, param);
    return this.http
      .request(GLOBAL.HTTP_METHOD, reqUrl, this.getOptions(paramsData))
      .map((res: Response | any) => {
        if (!res.data.calculateAtUI) {
          if (res.data.netMediaValue) {
            res.data.netMediaValue = parseFloat(
              res.data.netMediaValue.toFixed(2)
            );
          }
          if (!res.data.bookingList && res.data.bookings.length) {
            res.data.bookings.forEach((booking) => {
              booking.netMediaValue = parseFloat(
                booking.netMediaValue.toFixed(2)
              );
            });
          }
        }
        return res;
      })
      .catch((error: Response | any) => {
        this.logHelperService.logError(error.message || error);
        return _throw(error.message || error);
      });
  }

  clearLoadedCampaign() {
    const param = {
      action: 'resetUI',
    };
    const reqUrl = this.dataShareService.getServiceCallUrlByKey(
      'CLEAR_LOADED_CAMPAIGN'
    );
    return this.http
      .request(GLOBAL.HTTP_METHOD, reqUrl, this.getOptions(param))
      .map((res: Response) => {
        return res;
      })
      .catch((error: Response | any) => {
        this.logHelperService.logError(error.message || error);
        return _throw(error.message || error);
      });
  }

  /**
   * @description Returns true if the key element has to be hidden on UI
   * @author Shivani Patel
   * @param {string} key
   * @returns
   * @memberof CommercialService
   */
  commercialHiddenElements(key: string) {
    const commercialDetails =
      this.stateService.getCommercialObject('commercialDetails');
    if (Object.keys(commercialDetails).length) {
      return isExist(commercialDetails.uiControl.hiddenElements, key);
    }
    return false;
  }

  /**
   * @description Returns true if the key element has to be disabled
   * @author Shivani Patel
   * @param {string} key
   * @returns
   * @memberof CommercialService
   */
  commercialDisabledElements(key: string) {
    const commercialDetails =
      this.stateService.getCommercialObject('commercialDetails');
    if (Object.keys(commercialDetails).length) {
      return isExist(commercialDetails.uiControl.disabledElements, key);
    }
    return false;
  }

  /**
   * @description Calculates net Media
   * @param {*} index
   * @param {*} campaignDetails
   * @param {*} propertyName
   * @returns
   * @memberof CommercialService
   */
  calculateNetMedia(index, campaignDetails, propertyName) {
    let netMedia = 0;
    let specialistpercent = 0;
    const grossMediaValue =
      campaignDetails[propertyName][index].grossMediaValue;
    if (
      campaignDetails.specialist.specialistId > 0 &&
      campaignDetails.specialistPercentage > 0
    ) {
      specialistpercent = campaignDetails.specialistPercentage;
    }
    let agencypercent = 0;
    if (
      campaignDetails.agency.agencyId > 0 &&
      campaignDetails.agencyPercentage > 0
    ) {
      agencypercent = campaignDetails.agencyPercentage;
    }
    netMedia =
      grossMediaValue -
      (grossMediaValue / 100) * specialistpercent -
      (grossMediaValue / 100) * agencypercent;

    return parseFloat(netMedia.toFixed(2));
  }

  /**
   * @description Set all auto populate dropdown with invoice status
   * @author Kishan Patel
   * @param {number} id
   * @param {string} text
   * @param {string} invoiceStatusCode
   * @returns
   * @memberof CampaignDetailsService
   */
  populateSelectedDropdownWithCode(
    id: number,
    text: string,
    invoiceStatusCode: string
  ) {
    return {
      id,
      text,
      invoiceStatusCode,
    };
  }

  /**
   * @description set all auto populate dropdown without invoice
   * @author Kishan Patel
   * @param {number} id
   * @param {string} text
   * @returns
   * @memberof CampaignDetailsService
   */
  populateSelectedDropdown(id: number, text: string) {
    return {
      id,
      text,
    };
  }

  /**
   * @description calculate net media and gross media value from barter pre barter value change
   * @author Kishan Patel
   * @param {*} params
   * @memberof CommercialService
   */
  onSelectionChange(params) {
    if (params.value !== '') {
      this.stateService.setAnyValue(
        `bookedGrossMediaValue${params.rowIndex}`,
        params.bookingList[params.rowIndex].grossMediaValue
      );
      this.stateService.setAnyValue(
        `bookedNetMedia${params.rowIndex}`,
        params.bookingList[params.rowIndex].netMediaValue
      );
      params.bookingList[params.rowIndex].preBarterValue =
        params.bookingList[params.rowIndex].grossMediaValue;
      params.bookingList[params.rowIndex].grossMediaValue = 0;
      params.bookingList[params.rowIndex].netMediaValue = 0;
    } else {
      params.bookingList[params.rowIndex].grossMediaValue =
        parseFloat(
          this.stateService.getAnyValue(
            `bookedGrossMediaValue${params.rowIndex}`
          )
        ) || params.bookingList[params.rowIndex].preBarterValue;
      params.bookingList[params.rowIndex].netMediaValue =
        this.stateService.getAnyValue(`bookedNetMedia${params.rowIndex}`) || 0;
      params.bookingList[params.rowIndex].barterId = null;
      params.bookingList[params.rowIndex].barterRate = null;
    }
  }

  /**
   * @description Adds extra params to each booking line for all environments
   * @author Amit Mahida
   * @param {*} bookingLineReq
   * @param {BookingLine} bookingLine
   * @returns
   * @memberof CommercialService
   */
  addExtraParamsInBookingLine(bookingLineReq: any, bookingLine: BookingLine) {
    // Extra fields, required by backend
    bookingLineReq.lineNumber = bookingLine.lineNumber;
    bookingLineReq.version = bookingLine.version;
    bookingLineReq.bookingLineNumber = bookingLine.bookingLineNumber;
    bookingLineReq.containsDigital = bookingLine.containsDigital;
    bookingLineReq.containsPaper = bookingLine.containsPaper;
    bookingLineReq.systemReverted = bookingLine.systemReverted;
    bookingLineReq.skipIntegration = bookingLine.skipIntegration;
    bookingLineReq.previousBookingStatusId =
      bookingLine.previousBookingStatusId;
    return bookingLineReq;
  }

  getExternalReference() {
    const param = {
      action: 'lookupreference',
      data: JSON.stringify({}),
    };
    const reqUrl = this.dataShareService.getServiceCallUrlByKey(
      'LOOKUP_RESPONSE_URL'
    );
    return this.http
      .request(GLOBAL.HTTP_METHOD, reqUrl, this.getOptions(param))
      .map((res: Response) => {
        return res;
      })
      .catch((error: Response | any) => {
        this.logHelperService.logError(error.message || error);
        return Observable.throwError(error.message || error);
      });
  }

  getHeader(externalReference) {
    const param = {
      action: 'lookupcampaignheader',
      data: JSON.stringify({
        code: externalReference,
      }),
    };
    const reqUrl = this.dataShareService.getServiceCallUrlByKey(
      'LOOKUP_RESPONSE_URL'
    );
    return this.http
      .request(GLOBAL.HTTP_METHOD, reqUrl, this.getOptions(param))
      .map((res: Response) => {
        return res;
      })
      .catch((error: Response | any) => {
        this.logHelperService.logError(error.message || error);
        return Observable.throwError(error.message || error);
      });
  }
}
