import { mathRandomNumber } from "../core/utils/mathRandom";

export class ShapeCollection {
  /**
   * drawed shape collection
   * @type {*}@memberof ShapeCollection
   */
  collection: any = {};

  /**
   * selected shape object
   * @type {*}@memberof ShapeCollection
   */
  selectedShape: any = null;

  /**
   * selected shape change event callback
   * @type {*}@memberof ShapeCollection
   */
  onSelectedShapeChange: any;

  constructor(onSelectedShapeChange) {
    this.onSelectedShapeChange = onSelectedShapeChange;
  }

  /**
   * add new shape in shape collection object
   * @param {any} shape - new shape object
   * @param {any} type - type of shape
   * @memberof ShapeCollection
   */
  add(shape, type) {
    shape.type = type;
    shape.id = `${new Date().getTime()} _ ${Math.floor(mathRandomNumber() * 1000)}`;
    this.collection[shape.id] = shape;
    this.setSelection(shape);
    google.maps.event.addListener(shape, 'click', () => {
      this.setSelection(shape);
    });

    // bind shape edit events
    if (shape.type === google.maps.drawing.OverlayType.POLYGON) {
      const paths = shape.getPaths();
      paths.forEach((path) => {
        google.maps.event.addListener(path, 'set_at', () => {
          this.onSelectedShapeChange(false);
        });
      });
    } else if (shape.type === google.maps.drawing.OverlayType.RECTANGLE) {
      google.maps.event.addListener(shape, 'bounds_changed', () => {
        this.onSelectedShapeChange(false);
      });
    } else if (shape.type === google.maps.drawing.OverlayType.CIRCLE) {
      google.maps.event.addListener(shape, 'radius_changed', () => {
        this.onSelectedShapeChange(false);
      });
      google.maps.event.addListener(shape, 'center_changed', () => {
        this.onSelectedShapeChange(false);
      });
    }
  }

    /**
     * set shape as selected
     * @param {any} shape
     * @memberof ShapeCollection
     */
  setSelection(shape) {
    if (this.selectedShape !== shape) {
      this.clearSelection(true);
      this.selectedShape = shape;
      shape.set('draggable', true);
      shape.set('editable', true);
      this.onSelectedShapeChange(false);
    } else {
      this.onSelectedShapeChange(true);
    }
  }

    /**
     * Delete selected shape
     * @memberof ShapeCollection
     */
  deleteSelected() {
    if (this.selectedShape) {
      const shape = this.selectedShape;
      this.clearSelection(false);
      shape.setMap(null);
      delete this.collection[shape.id];
    }
  }

    /**
     * clear selected shape
     * @param {boolean} isFromSetSelection - whether is called from setSelection method
     * @memberof ShapeCollection
     */
  clearSelection(isFromSetSelection: boolean) {
    if (this.selectedShape) {
      this.selectedShape.set('draggable', false);
      this.selectedShape.set('editable', false);
      this.selectedShape = null;
      if (!isFromSetSelection) {
        this.onSelectedShapeChange(false);
      }
    }
  }
}
