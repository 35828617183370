import { Injectable } from '@angular/core';
import { DataShareService } from './data-share.service';
import { Subscription } from 'rxjs';
import { RequestJsonService } from '../../geo-map/request-json.service';
import { BrickBaseService } from './brick-base.service';
import { ColumnConfig } from '../../models/MapData.model';
import * as _ from 'lodash';

@Injectable()
export class FilterSupportService {
  /**
   * request json data subscriber
   * @type {Subscription}@memberof FilterSupportService
   */
  requestJsonDataSubscriber: Subscription;

  /**
   * Request json data object
   * @private
   * @type {*}@memberof RequestJsonService
   */
  private requestJsonData: any = [];

  constructor(private dataShareService: DataShareService,
              private requestJsonService: RequestJsonService,
              private brickBaseService: BrickBaseService) {
    this.requestJsonDataSubscriber = this.requestJsonService.requestJson$
      .subscribe((data) => {
        this.requestJsonData = data;
      });
  }

  /*
    * Below method checks whether the brics is allowed or not based on (column config if its available) OR (Mandatory Brics)
    * @function
    * @param {columnConfig} - Column config for a particular column
    * @param {bricId} - Id of Bric
    * @param {items} - $scope.items
    * @param {dropAtcellIndex} - Column Index
    * Returns true if the bric is allowed else false
    * @author : Shreni Shah
    */
  checkForAllowedBrics(columnConfig: ColumnConfig, bricId: number | string) {
    const initialConfig = this.dataShareService.getInitialConfig();
    const returnObject = { isAllowed: true, logInfo: '' };

    // Check for Mandatory brics is compulsory//
    const allowedBrics = _.union(initialConfig.uiControl.processingBrics, initialConfig.uiControl.optionalBrics);

    if (allowedBrics.indexOf(bricId) > -1) {
      return returnObject;
    } else {
      returnObject.isAllowed = !this.checkIfRequiredBricksAreMissing(this.requestJsonData);
      returnObject.logInfo = initialConfig.userBundle['workspace.error.missingRequiredBricks'];
    }

    // Once the mandatory bric checking is done, then check for column config//
    if (returnObject.isAllowed) {
      // check if coloumn config exits or not//
      if (columnConfig && Object.keys(columnConfig).length) {

        // Allow all the brics to be placed on DOM except (Brics with selectionsId's))
        // BricswithSelectionId's =  BricswithSelectionId's - ( Required Brics + Optional Brics )

        // var notAllowedBRICS = getListOfNotAllowedBrics();
        /**
         * Alkesh Shah
         * Not Allowed list is empty so it will never have this bric so commenting all code
         */
        // var notAllowedBRICS = []; //commenting above code and adding this line for SBRICS-1624, Nishit
        // var results = new List<any>(notAllowedBRICS)
        //   .Where(function (x) {
        //     return parseInt(x.Key) == parseInt(bricId);
        //   }).Select(function (x) {
        //     return x
        //   }).ToArray();

        // if (results.length == 0)
        return returnObject;

        // else {
        //  returnObject.isAllowed = this.checkIfBricIsAllowedInColumnConfig(columnConfig, dropAtcellIndex, bricId);
        //  returnObject.logInfo = "Bric not allowed as per column config";
        // }
      } else {
        // case where there is not data in column config and when pattern bric or Rating bric is dropped  //issue found, internal testing
        // we do not know which data source to pass as there will be multiple data source for these brics // Nishit
        // if (bricId == PatternBrickID || bricId == RatingBrickID || bricId == SecondaryAudienceBrickID) {
        if (bricId === this.brickBaseService.brickID.Audience) {
          returnObject.isAllowed = false;
          returnObject.logInfo = 'Please add required bricks or correct invalid brick!';
        }
      }
    }

    return returnObject;
  }

  checkIfRequiredBricksAreMissing(reqJSON) {
    let brickMissing = true;
    const initialConfig = this.dataShareService.getInitialConfig();
    if (!initialConfig) {
      return brickMissing;
    }
    const requiredBricks = initialConfig.uiControl.processingBrics;
    for (let i = 0; i < requiredBricks.length; i++) {
      const selectionCriteriaText = this.brickBaseService.brickReqJsonText[requiredBricks[i]];
      let isExist = false;
      for (const req of reqJSON) {
        if (req[selectionCriteriaText]) {
          isExist = true;
        }
      }
      if (!isExist) {
        brickMissing = true;
        break;
      }
      if (i === requiredBricks.length - 1) {
        brickMissing = false;
      }
    }

    return brickMissing;
  }
}
