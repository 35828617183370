import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-checkbox',
  templateUrl: './ag-checkbox.component.html',
  styleUrls: ['./ag-checkbox.component.css']
})
export class AgCheckboxComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;
  public isSelected: boolean;
  // called on init
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setValues();
  }

  // called when the cell is refreshed
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.setValues();
    return true;
  }

  private setValues(): void {
    if (this.params.data) { // data row
      this.isSelected = this.params.data[this.params.column.getColId()];
    } else { // header cell
      const rowData = this.getRowData();
      const selectedParentRows = rowData.filter((row) => {
        return row[this.params.column.getColId()];
      });
      this.isSelected = rowData.length === selectedParentRows.length;
    }
  }

  onSelectChange($event: any): void {
    this.isSelected = $event.target.checked;
    if (this.params.data) { // data row
      this.params.data[this.params.column.getColId()] = this.isSelected;
    } else { // header cell
      const rowData = this.getRowData();
      for (const r of rowData) {
        r[this.params.column.getColId()] = this.isSelected;
      }
    }

    /* tslint:disable:no-string-literal */
    if (this.params['onSelectChange']) {
      this.params['onSelectChange'](this.params);
    }
    this.params.api.refreshHeader();
    this.params.api.redrawRows();
  }

  getRowData(): any {
    const rowData = [];
    this.params.api.forEachNodeAfterFilter((node) => {
      rowData.push(node.data);
    });
    return rowData;
  }
}
