import { Component, OnInit, Input } from '@angular/core';
import { DataShareService } from '../../../core/services/data-share.service';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { AddProductBase } from './add-product-base';
import { NumberMaskModel } from '../../../models/imask';
import { MaskService } from '../../../core/services/mask.service';
import { PriceConfiguration, PricingFactor } from '../../../models/productCatalogue';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css'],
})

export class AddProductComponent extends AddProductBase implements OnInit {
  /**
   *
   * pass params from main workspace componenet
   * @memberof AdvanceAvailabilityComponent
   */
  @Input() resolveObject;

  /**
   * @description Mask input config for tolerance field, max 100 - 0 decimal
   * @type {NumberMaskModel}
   * @memberof AddProductComponent
   */
  currencyInputMask: NumberMaskModel = {
    max: 999999999999999999999,
    scale: 2
  };
  maskService: MaskService = new MaskService();

  constructor(public dataShareService: DataShareService,
              public logHelperService: LogHelperService,
              public lookupViewService: LookupViewService) {
    super(lookupViewService, dataShareService, logHelperService);
    this.initialConfig = dataShareService.getInitialConfig();
  }

  ngOnInit(): void {
    this.ngInit();
    this.currencyInputMask = this.maskService.currencyInputMask(this.currencyInputMask);
    if (this.resolveObject && Object.keys(this.resolveObject).length !== 0) {
      this.accessControls[0].selected = this.resolveObject.restrictions.advertisers;
      this.accessControls[1].selected = this.resolveObject.restrictions.agencies;
      this.accessControls[2].selected = this.resolveObject.restrictions.specialists;
      this.accessControls[3].selected = this.resolveObject.restrictions.campaignTypes;
      this.productCatalogue = {
        ...this.resolveObject,
        restrictions: {
          ...this.resolveObject.restrictions,
          advertisers: this.createAccessControlsMapping(this.accessControls[0]),
          agencies: this.createAccessControlsMapping(this.accessControls[1]),
          specialists: this.createAccessControlsMapping(this.accessControls[2])
        },
      };
      if (this.initialConfig.uiControl.pcmPriceConfigurationEnabled) {
        this.productCatalogue.priceConfiguration = this.resolveObject.priceConfiguration ? _.cloneDeep(this.resolveObject.priceConfiguration) : new PriceConfiguration();
        if (!this.resolveObject.priceConfiguration.factors || this.resolveObject.priceConfiguration.factors.length === 0) {
          this.initializePricingFactors();
        }
      } else { delete this.productCatalogue.priceConfiguration; }
    }
  }

  clearfixedCPMValue(event) {
    if (!event.target.checked) {
      this.productCatalogue.priceConfiguration.fixedCPMValue = null;
    }
  }

  togglepcmPricingFactors(event) {
    if (!event.target.checked) {
      this.initializePricingFactors();
    }
  }

  toggleSinglePCMFactor(event, factor: PricingFactor) {
    if (!event.target.checked) {
      factor.min = null;
      factor.max = null;
    }

  }

  onModalClosed(event) {
    event.activeModal.dismiss();
  }

  removeItemById(index, accessControlsIndex) {
    if (index !== null) {
      this.accessControls[accessControlsIndex].selected.splice(index, 1);
    }
  }

  onModalSaved(event) {
    if (this.validationFn()) {
      this.productCatalogue.restrictions.advertisers = this.createAccessControlsMapping(this.accessControls[0]);
      this.productCatalogue.restrictions.agencies = this.createAccessControlsMapping(this.accessControls[1]);
      this.productCatalogue.restrictions.specialists = this.createAccessControlsMapping(this.accessControls[2]);
      this.productCatalogue.restrictions.campaignTypes = this.createAccessControlsMapping(this.accessControls[3]);
      if (this.productCatalogue.restrictions.advertisers.length === 0) {
        delete this.productCatalogue.restrictions.advertisers;
      }
      if (this.productCatalogue.restrictions.agencies.length === 0) {
        delete this.productCatalogue.restrictions.agencies;
      }
      if (this.productCatalogue.restrictions.specialists.length === 0) {
        delete this.productCatalogue.restrictions.specialists;
      }
      if (this.productCatalogue.restrictions.campaignTypes.length === 0) {
        delete this.productCatalogue.restrictions.campaignTypes;
      }
      if (!this.initialConfig.uiControl.pcmPriceConfigurationEnabled) {
        delete this.productCatalogue.priceConfiguration;
      }
      if (this.initialConfig.uiControl.pcmPriceConfigurationEnabled &&
        this.productCatalogue.priceConfiguration &&
        !this.productCatalogue.priceConfiguration.fixedCPM
      ) {
        delete this.productCatalogue.priceConfiguration.fixedCPMValue;
      }
      event.activeModal.close(this.productCatalogue);
    }
  }

  trackByIndex(index, item) {
    return index;
  }

  trackByIdProductCatalogueType(index, item) {
    return item?.idProductCatalogueType;
  }

  trackByIdProductCatalogueGroup(index, item) {
    return item?.idProductCatalogueGroup;
  }

}
