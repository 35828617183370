import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StateService } from '../../../app/core/services/state.service';
import { CampaignDetailsService } from '../campaign-details/campaign-details.service';
import { CampaignDetails, HistoryROC } from '../commercial.model';
import { LogHelperService, DataShareService } from '../../core/services';
import { CommercialButtonService } from '../commercial-buttons/commercial-buttons.service';
import { CampaignStatus } from '../../core/enum';
import { SystemFlags } from '../../models';
import { DatePipe } from '@angular/common';
import { CommercialService } from '../../core/services/commercial.service';
import * as _ from 'lodash';
import { GLOBAL } from '../../core/utils/app.constant';
import { CommercialBase } from '../commercial-base';
import { RequiredCampaignFields, RequiredBookingsFields, PopulateCommercialFields, PopulateBookingDetailFields } from '../../models/RequiredFields';

@Component({
  selector: 'app-commercial-uk',
  templateUrl: './commercial-uk.component.html'
})
export class CommercialUkComponent extends CommercialBase implements OnInit {
  systemFlags = SystemFlags;
  @Output() redirectionHomePage = new EventEmitter<any>();

  // @Output() redirectionHomePage = new EventEmitter<any>();
  /**
   * @description store commercial data
   * @memberof CommercialUKComponent
   */
  public commercialData;

  /**
   * @description show hide booking Grid
   * @type {boolean}
   * @memberof CommercialUkComponent
   */
  hideBookingGrid: boolean;
  /**
   * @description store campaign detail show hide
   * @type {boolean}
   * @memberof CommercialUkComponent
   */
  hideCampaignDetail: boolean;

  /**
   * @description set bookingStatusList
   * @memberof CommercialUkComponent
   */
  bookingStatusList = GLOBAL.bookingStatusList;

  /**
   * @description this will show the history of campaign updated in case of reason/cancel for change
   * @memberof CommercialUkComponent
   */
  historyROC = new HistoryROC();

  constructor(
    stateService: StateService,
    commercialService: CommercialService,
    campaignDetailsService: CampaignDetailsService,
    logHelperService: LogHelperService,
    commercialButtonService: CommercialButtonService,
    datePipe: DatePipe,
    dataShareService: DataShareService
  ) {
    super(stateService, logHelperService, commercialButtonService, dataShareService, commercialService, datePipe, campaignDetailsService);
  }

  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.setDefaultAgencySpecialistPerc();
    this.restoreCommercialData();
  }

  restoreCommercialData() {
    this.commercialConfig.user = this.dataShareService.getUserModel();
    // added it to scope to make is accessible to view
    this.getCommercialDataFromService();

  }

  /**
   * @description  get commercial data from service
   * @author Kishan Patel
   * @memberof CommercialUkComponent
   */
  getCommercialDataFromService(isManual = false) {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
      brickProcessed: SystemFlags.brickProcessed === true && SystemFlags.isLoadedCampaign ? true : false
    };
    SystemFlags.brickProcessed = false;
    this.commercialService.loadCommercialCampaignDetails(param).subscribe((data) => {
      if (data.status === 'OK') {
        this.commercialData = data.data;
        if (isManual) {
          this.dataShareService.updateBookingList(this.commercialData.bookingList);
        }
        this.commercialConfig.campaignStatus = data.data.campaignStatusId;
        this.commercialConfig.bookingStatus = data.data.bookingStatus;
        this.commercialConfig.uiControl = this.commercialData.uiControl;

        this.commercialData.bookingStatusName =
          this.campaignDetailsService.getBookingStatusNameFromId(this.commercialData.bookingList || []);
        const assignedToList = this.dataShareService.getInitialConfigByKey('assignedTo');
        this.campaignDetails = this.campaignDetailsService.populateCampaignDetails(this.commercialData, this.initialConfig, assignedToList);
        if (this.campaignDetails.bookingList && this.campaignDetails.bookingList.length > 0) {

          _.forEach(this.campaignDetails.bookingList, (obj, i) => {
            if (!obj['hideBlanking']) {
              if (this.campaignDetails.bookingList[i]['noBlanking']) {
                this.campaignDetails.bookingList[i]['noBlanking'] = true;
                this.campaignDetails.bookingList[i].blankingOption = 'noBlanking';
              } else if (this.campaignDetails.bookingList[i]['blankOnTermination']) {
                this.campaignDetails.bookingList[i]['noBlanking'] = true;
                this.campaignDetails.bookingList[i].blankingOption = 'blankOnTermination';
              } else if (this.campaignDetails.bookingList[i]['blankOn']) {
                this.campaignDetails.bookingList[i].blankOn = new Date(this.campaignDetails.bookingList[i]['blankOn'].replace(/-/g, '/'));
                this.campaignDetails.bookingList[i].blankingOption = 'blankOn';
              } else if (this.campaignDetails.bookingList[i]['blankBy']) {
                this.campaignDetails.bookingList[i].blankBy = new Date(this.campaignDetails.bookingList[i]['blankBy'].replace(/-/g, '/'));
                this.campaignDetails.bookingList[i].blankingOption = 'blankBy';
              }
            }
          });
        }
        if (isManual) {
          this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT);
        } else {
          this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, undefined, undefined, {}, this.commercialConfig.uiControl.campaignCPTScale || 2);
        }
        this.historyROC = this.campaignDetailsService.populateHistoryROC(this.historyROC, data.data.auditReasonsHistoryMap, this.commercialConfig.uiControl.historyROC);
      }
    });

  }

  /**
   * Update Booking List
   */
  updateBookingList() {
    if (this.campaignDetails.bookingList && this.campaignDetails.bookingList.length > 0) {

      _.forEach(this.campaignDetails.bookingList, (obj, i) => {
        if (!obj['hideBlanking']) {
          if (this.campaignDetails.bookingList[i]['noBlanking']) {
            this.campaignDetails.bookingList[i]['noBlanking'] = true;
            this.campaignDetails.bookingList[i].blankingOption = 'noBlanking';
          } else if (this.campaignDetails.bookingList[i]['blankOnTermination']) {
            this.campaignDetails.bookingList[i]['noBlanking'] = true;
            this.campaignDetails.bookingList[i].blankingOption = 'blankOnTermination';
          } else if (this.campaignDetails.bookingList[i]['blankOn']) {
            this.campaignDetails.bookingList[i].blankOn = new Date(this.campaignDetails.bookingList[i]['blankOn'].replace(/-/g, '/'));
            this.campaignDetails.bookingList[i].blankingOption = 'blankOn';
          } else if (this.campaignDetails.bookingList[i]['blankBy']) {
            this.campaignDetails.bookingList[i].blankBy = new Date(this.campaignDetails.bookingList[i]['blankBy'].replace(/-/g, '/'));
            this.campaignDetails.bookingList[i].blankingOption = 'blankBy';
          }
        }
      });
    }
  }

  goToHomePage() {
    this.redirectionHomePage.emit();
  }

  /**
   * @description commercial form validity
   * @memberof CommercialUkComponent
   */
  validateCommercialCampaign = () => {
    const campaignDetails = this.campaignDetails;
    const requiredFields: RequiredCampaignFields = new RequiredCampaignFields();
    requiredFields.assignedTo = true;
    let message = this.validateCommonFields(requiredFields);
    if (!message && campaignDetails.bookingList) {
      campaignDetails.bookingList.forEach((obj) => {
        const requiredBookingsFields: RequiredBookingsFields = new RequiredBookingsFields(true, true, true, true, true);
        // added if condition for SM-6615
        if (!message && obj.bookingStatusId !== CampaignStatus.cancel) {
          message = this.validateBookingFields(obj, requiredBookingsFields);
        }
        if (!message && obj.containsDigital) {
          message = this.validateMediaLength(obj, obj.bookingStatusId);
        }
      });
    }
    return this.isValid(message);
  }

  /**
   * @description this is output event from commercialbutton when click on update/option/book/cancel button click
   * @author Kishan Patel
   * @param {*} status
   * @memberof CommercialUkComponent
   */
  onBookCampaign(status: number) {
    if (this.validateCommercialCampaign()) {
      status = status || this.campaignDetails.campaignStatusId;
      // added for SM-4433, also fix in ag-number-mask-cell-editor.component
      this.commercialService.notifyCampaignDetails(this.campaignDetails.bookingList);
      if (SystemFlags.isLoadedCampaign && (this.initialConfig.auditType
        && this.initialConfig.auditType.length > 0) &&
        ((status === CampaignStatus.cancel) ||
          (!this.commercialButtonService.checkIfAllTheBookingLinesAreInOption(this.campaignDetails.bookingList)
            && status === CampaignStatus.confirm
            && this.commercialButtonService.roChange(this.campaignDetails, this.commercialConfig.uiControl, 'bookingList', status, this.populateCampaignAuditDetailsObject)))) {
        this.handleROC(status, this.createRequestJSONForCommercial);
      } else if (this.campaignDetails.grossMediaValue === 0
        && this.commercialButtonService.chekIfTheAnyOneBookingLineIsConfirmed(this.campaignDetails.bookingList)) {
        this.confirmCampaign(this.createRequestJSONForCommercial);
      } else {
        this.validateServiceRequest(status, this.createRequestJSONForCommercial);
      }
    }

  }

  /**
   * @description create Request JSON
   * @memberof CommercialUkComponent
   */
  createRequestJSONForCommercial = (status) => {
    let campaignDetails = this.campaignDetails;
    if (campaignDetails == null) {
      campaignDetails = this.stateService.getCommercialObject('campaignDetails');
    }
    const populate: PopulateCommercialFields = this.getCommercialFields();
    const temp = this.populateBookCommercialCampaignParam(status, campaignDetails, populate);
    const populateBooking: PopulateBookingDetailFields = this.getBookingDetailFields();
    temp.bookingList = this.populateBookingDetailsServiceObject(campaignDetails, 'bookingList', populateBooking);
    return temp;
  }

  /**
   * To override the commercial fields
   * This override fields are used to override the default value
   * @memberof CommercialUkComponent
   * @author Dhaval Patel
   */
  getCommercialFields() {
    const populate: PopulateCommercialFields = super.getCommercialFields();
    populate.setPCFields();
    populate.overs = true;
    populate.financeAmount = true;
    return populate;
  }

  /**
   * To override the booking detail fields
   * This override fields are used to override the default value
   * @memberof CommercialUkComponent
   * @author Dhaval Patel
   */
  getBookingDetailFields() {
    const populate: PopulateBookingDetailFields = super.getBookingDetailFields();
    populate.setPICFields();
    populate.setCCBIBFields();
    populate.setPOORIFields();
    populate.financeAmount = true;
    populate.setPMDBFields();
    populate.isCancellationPending = true;
    return populate;
  }

  showHideBookingGrid() {
    this.hideBookingGrid = !this.hideBookingGrid;
  }

  showHideCampaignDetail() {
    this.hideCampaignDetail = !this.hideCampaignDetail;
  }

  /**
   * @description Campaign audit details object
   * @author Darshan Vachhani
   * @param {CampaignDetails} commercialData
   * @returns
   * @memberof CommercialButtonService
   */
  populateCampaignAuditDetailsObject(commercialData: CampaignDetails, propertyName: string) {

    let commercialAuditObject = null;
    // SBRICS-226 Commercial Page Audit Object
    // SBRICS-1810, Nishit updated code for multiple booking
    if (Object.keys(commercialData).length) {
      commercialAuditObject = {
        brandId: commercialData.brand.brandId || null, // chosen search
        specialistId: commercialData.specialist.specialistId || null,
        specialistPercentage: commercialData.specialistPercentage || null,
        agencyId: commercialData.agency.agencyId || null,
        agencyPercentage: commercialData.agencyPercentage || null,
        advertiserId: commercialData.advertiser.advertiserId || null
      };
      commercialAuditObject[propertyName] = [];
      if (commercialData[propertyName] && commercialData[propertyName].length > 0) {
        let i = 0;
        commercialData[propertyName].forEach((bookingObj) => {
          if (propertyName === 'bookings' ||
            (propertyName === 'bookingList' && typeof (bookingObj) === 'object' && ((bookingObj.bookingStatusId
              && bookingObj.bookingStatusId !== CampaignStatus.option) || !bookingObj.bookingStatusId))) { // SM-2111
            commercialAuditObject[propertyName][i] = {};
            commercialAuditObject[propertyName][i].barterId = bookingObj.barterId || null;
            commercialAuditObject[propertyName][i].barterRate = bookingObj.barterRate || null;
            commercialAuditObject[propertyName][i].preBarterValue = bookingObj.preBarterValue || null;
            commercialAuditObject[propertyName][i].grossMediaValue =
              bookingObj.grossMediaValue && parseFloat(bookingObj.grossMediaValue.toFixed(2)) || null;
            commercialAuditObject[propertyName][i].netMediaValue =
              bookingObj.netMediaValue && parseFloat(bookingObj.netMediaValue.toFixed(2)) || null;
            i = i + 1;
          }
        });
      }
    }
    return commercialAuditObject;
  }

  async manuallyCPTUpdated() {
    const campaigndata = this.createRequestJSONForCommercial(this.campaignDetails.campaignStatusId);
    await this.updateCommercialInfoForCPT(campaigndata);
    this.getCommercialDataFromService(true);
  }
}
