import { Component, OnInit, Input } from '@angular/core';
import { MediaWindow, CellValues } from '../../models/workspace/index';
import { MediaTypeModel } from '../../models';
import {
  DataShareService,
  CellAttributeService,
  BrickBaseService,
  LogHelperService
} from '../../core/services/index';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html'
})

export class MediaComponent implements OnInit {
  /**
   * @description initial config object
   * @type {MediaTypeModel}
   * @memberof MediaComponent
   */
  mediaTypes: MediaTypeModel;
  /**
   * @description It will take data to configure modal window.
   * @type {CellValues}
   * @memberof MediaComponent
   */
  @Input() resolveObject: CellValues;
  /**
   * @description selected value of window
   * @type {MediaWindow}
   * @memberof MediaComponent
   */
  public mediaModal: MediaWindow = new MediaWindow();
  readOnlyModal = false;

  /**
   * @description
   * @type {*}
   * @memberof MediaComponent
   */
  userBundle: any;
  /**
   * Creates an instance of MediaComponent.
   * @author Alkesh Shah
   * @param {DataShareService} dataService
   * @memberof MediaComponent
   */
  constructor(
    private dataService: DataShareService,
    private cellAttributeService: CellAttributeService,
    private brickBaseService: BrickBaseService,
    private logHelperService: LogHelperService,
    private dataShareService: DataShareService
  ) {
  }

  /**
   * @description angualr life cycle hook
   * called on component initialization phase
   * @author Alkesh Shah
   * @memberof MediaComponent
   */
  ngOnInit(): void {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.mediaTypes = this.dataService.getInitialConfigByKey('mediaType');
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    if (this.resolveObject.selectedValues) {
      this.mediaModal = this.resolveObject.selectedValues;
    }
  }

  /**
   * @description callback function called on media change
   * @author Alkesh Shah
   * @param {*} media
   * @memberof MediaComponent
   */
  onMediaSelect(media) {
    this.mediaModal.selectedMediaObject = media;
  }

  /**
   * callback to close modal popup window
   * @param {*} event - modal event object
   * @memberof MediaComponent
   */
  onModalClosed(event) {
    const defaultMediaModal = new MediaWindow();
    if (event.reason === 'escape' && JSON.stringify(this.mediaModal) !==  JSON.stringify(defaultMediaModal)) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * callback function - called during save click
   * @param {*} event - modal event object
   * @memberof MediaComponent
   */
  onModalSaved(event) {
    if (this.mediaModal.selectedMedia) {
      const values: CellValues = new CellValues();
      values.brick = this.resolveObject.brick;
      values.selectedValues = this.mediaModal;
      values.displayText = this.mediaModal.selectedMediaObject.mediaTypeName;
      values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Media, values.selectedValues);
      values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Media,
        values.selectedValues.selectedMediaObject);
      event.activeModal.close(values);
    } else {
      this.logHelperService.logError(this.userBundle['common.error.noDataSelect']);
    }
  }

  trackByMediaType(index, media) {
    return media?.mediaTypeId;
  }
}
