import { DateFormatPipe } from '../../core/pipes/date-format.pipe';
import { LocaleData } from '../../core/utils/LocaleData';
import { ColDef } from 'ag-grid-community';
import { AgCustomFilterMenuComponent } from './ag-grid-support/ag-custom-filter-menu/ag-custom-filter-menu.component';
import { AgDateFloatingFilterComponent } from './ag-grid-support/ag-date-floating-filter/ag-date-floating-filter.component';
import { UiControl } from '../../models';

export class TableColumns {
  constructor(private userBundle: any, private uiControl: UiControl) {
    this.dateFormatPipe = new DateFormatPipe();
    this.DATE_FORMAT = LocaleData.displayDateFormat;
  }
  dateFormatPipe: DateFormatPipe;
  DATE_FORMAT = '';

  commonColumns: ColDef[] = [
    {
      headerName: this.userBundle['common.campaignRef'],
      field: 'campaignReference',
      tooltipField: 'campaignReference',
    },
    {
      headerName: this.userBundle['common.externalId'],
      field: 'externalId',
      tooltipField: 'externalId',
    },
    {
      headerName: this.userBundle['common.title'],
      field: 'title',
      tooltipField: 'title',
    },
    {
      headerName: this.userBundle['common.savetitle'],
      field: 'save',
      tooltipField: 'save',
    },
    {
      headerName: this.userBundle['common.status'],
      field: 'campaignStatusName',
      tooltipField: 'campaignStatusName',
    },
    {
      headerName: this.userBundle['common.readyForPosting'],
      field: 'readyForPosting',
      tooltipField: 'readyForPosting',
    },
    {
      headerName: this.userBundle['common.fromDate'],
      field: 'startDate',
      floatingFilterComponentFramework: AgDateFloatingFilterComponent,
      floatingFilterComponentParams: {
        value: '',
        suppressFilterButton: true,
        filterType: 'fromto',
      },
      filterFramework: AgCustomFilterMenuComponent,
      cellRenderer: (params) => {
        return this.dateFormatPipe.transform(
          params.value,
          this.uiControl.showDayofWeekRangeBric
            ? `dddd, ${this.DATE_FORMAT}`
            : this.DATE_FORMAT
        );
      },
    },
    {
      headerName: this.userBundle['common.toDate'],
      field: 'endDate',
      floatingFilterComponentFramework: AgDateFloatingFilterComponent,
      floatingFilterComponentParams: {
        value: '',
        suppressFilterButton: true,
        filterType: 'fromto',
      },
      filterFramework: AgCustomFilterMenuComponent,
      cellRenderer: (params) => {
        return this.dateFormatPipe.transform(
          params.value,
          this.uiControl.showDayofWeekRangeBric
            ? `dddd, ${this.DATE_FORMAT}`
            : this.DATE_FORMAT
        );
      },
    },
    {
      headerName: this.userBundle['common.floatingStartDate'] || 'Floating Start Date',
      field: 'floatingStartDate',
      floatingFilterComponentFramework: AgDateFloatingFilterComponent,
      floatingFilterComponentParams: {
        value: '',
        suppressFilterButton: true,
        filterType: 'fromto',
      },
      filterFramework: AgCustomFilterMenuComponent,
      cellRenderer: (params) => {
        return this.dateFormatPipe.transform(
          params.value,
          this.uiControl.showDayofWeekRangeBric
            ? `dddd, ${this.DATE_FORMAT}`
            : this.DATE_FORMAT
        );
      },
    },
    {
      headerName: this.userBundle['common.floatingEndDate'] || 'Floating End Date',
      field: 'floatingEndDate',
      floatingFilterComponentFramework: AgDateFloatingFilterComponent,
      floatingFilterComponentParams: {
        value: '',
        suppressFilterButton: true,
        filterType: 'fromto',
      },
      filterFramework: AgCustomFilterMenuComponent,
      cellRenderer: (params) => {
        return this.dateFormatPipe.transform(
          params.value,
          this.uiControl.showDayofWeekRangeBric
            ? `dddd, ${this.DATE_FORMAT}`
            : this.DATE_FORMAT
        );
      },
    },
    {
      headerName: this.userBundle['common.advertiserName'],
      field: 'advertiserName',
      tooltipField: 'advertiserName',
      comparator: this.stringComparator,
    },
    {
      headerName: this.userBundle['common.brandName'],
      field: 'brandName',
      tooltipField: 'brandName',
      comparator: this.stringComparator,
    },
    {
      headerName: this.userBundle['common.salesTeamName'],
      field: 'salesTeamName',
      tooltipField: 'salesTeamName',
      comparator: this.stringComparator,
    },
    {
      headerName: this.userBundle['common.specialistName'],
      field: 'specialistName',
      tooltipField: 'specialistName',
      comparator: this.stringComparator,
    },
    {
      headerName: this.userBundle['common.agencyName'],
      field: 'agencyName',
      tooltipField: 'agencyName',
      comparator: this.stringComparator,
    },
    {
      headerName: this.userBundle['common.optionDroppingDate'],
      field: 'optionDroppingDate',
      floatingFilterComponentFramework: AgDateFloatingFilterComponent,
      floatingFilterComponentParams: {
        value: '',
        suppressFilterButton: true,
        filterType: 'ledatestring',
      },
      filterFramework: AgCustomFilterMenuComponent,
      cellRenderer: (params) => {
        return this.dateFormatPipe.transform(params.value, this.DATE_FORMAT);
      },
    },
    {
      headerName: this.userBundle['common.salesPersonNames'],
      field: 'salesPersonNames',
      tooltipField: 'salesPersonNames',
      comparator: this.stringComparator,
    },
    {
      headerName: this.userBundle['common.adminPersonNames'],
      field: 'adminPersonNames',
      tooltipField: 'adminPersonNames',
      comparator: this.stringComparator,
    },
    {
      headerName: this.userBundle['common.salesAdmin'],
      field: 'salesAdmin',
      tooltipField: 'salesAdmin',
      comparator: this.stringComparator,
    },
    {
      headerName: this.userBundle['common.channelName'],
      field: 'channelName',
      tooltipField: 'channelName',
      comparator: this.stringComparator,
    },
    {
      headerName: this.userBundle['common.businessArea'],
      field: 'businessArea',
      tooltipField: 'businessArea',
      comparator: this.stringComparator,
    },
    {
      headerName: this.userBundle['common.updatedDate'],
      field: 'updateDate',
      floatingFilterComponentFramework: AgDateFloatingFilterComponent,
      floatingFilterComponentParams: {
        value: '',
        suppressFilterButton: true,
        filterType: 'datestring',
      },
      filterFramework: AgCustomFilterMenuComponent,
      cellRenderer: (params) => {
        return this.dateFormatPipe.transform(params.value, this.DATE_FORMAT);
      },
    },
    {
      headerName: this.userBundle['common.createdBy'],
      field: 'createdBy',
      tooltipField: 'createdBy',
      comparator: this.stringComparator,
    },
    {
      headerName: this.userBundle['common.createdDate'],
      field: 'createDate',
      floatingFilterComponentFramework: AgDateFloatingFilterComponent,
      floatingFilterComponentParams: {
        value: '',
        suppressFilterButton: true,
        filterType: 'datestring',
      },
      filterFramework: AgCustomFilterMenuComponent,
      cellRenderer: (params) => {
        return this.dateFormatPipe.transform(params.value, this.DATE_FORMAT);
      }
    },
    {
      headerName: this.userBundle['common.requestBookingDroppingDate'],
      field: 'requestBookingDroppingDate',
      tooltipField: 'requestBookingDroppingDate',
    },
    {
      headerName: this.userBundle['common.campaignType'],
      field: 'campaignType',
      tooltipField: 'campaignType',
      comparator: this.stringComparator,
    },
    {
      headerName: this.userBundle['common.dgNumber'],
      field: 'dgNumber',
      tooltipField: 'dgNumber',
    },
    {
      headerName: this.userBundle['common.opportunityLineId'],
      field: 'opportunityLineId',
      tooltipField: 'opportunityLineId',
    },
    {
      headerName: this.userBundle['common.customerId'],
      field: 'customerId',
      tooltipField: 'customerId',
    },
  ];

  /**
   * @description  Custom sorting function to keep nulls on last on asc order
   * @author Amit Mahida
   * @param {string} a
   * @param {string} b
   * @returns {number}
   * @memberof TableColumns
   */
  stringComparator(a: string, b: string): number {
    const va = a === null ? '' : `${a}`;
    const vb = b === null ? '' : `${b}`;
    return va > vb ? 1 : va === vb ? 0 : -1;
  }
}
