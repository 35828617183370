<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
  [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)" (saved)="onModalSaved()">
  <div class="multitargetmodal">
    <div class="tabs_container horizontal">
      <div [ngClass]="{'readOnly' : readOnlyModal}">
        <div id="tabList" class="tab-content list-popup">
          <div class="scrolldiv">
            <div class="control_group">
              <app-file-upload #fileComponent [multipleFiles]="true" ngDefaultControl extension=".xls,.xlsx"
                [(fileName)]="listData.fileNameLabel" logerror="logerror(data)" [noOfFiles]="1"
                (savefilelocally)="saveFilelocally($event)">
              </app-file-upload>
            </div>
          </div>
          <div class="clear"></div>
          <span class="selectedFile">{{initialConfig.userBundle['workspace.collection.list.title.selectedFile']}}:</span>
          <ul class="summaryContainer" *ngIf="listData.listUpload.fileList.length > 0">
            <li *ngFor="let file of listData.listUpload.fileList; trackBy: trackByFile; let i = index;">
              <label>
                {{((file.userSelectionName || file.name).length > 30) ? (file.userSelectionName ||
                file.name).substring(0, 30) + '...' : (file.userSelectionName || file.name)}}</label>
              <span class="right col-md-2">
                <a class="download-btn" (click)="downloadListFile(file.userSelectionId, file.userSelectionName)"
                  *ngIf="file.userSelectionId" title="Click to Download {{file.userSelectionName}}" target="_parent">
                  <img [src]="'images/download.png'" alt="download" class="cursorClass"/>
                </a>
                <a class="download-btn" *ngIf="!file.userSelectionId">
                  <img [src]="'images/download.png'" style="opacity: 0.3"  alt="download" />
                </a>
                <a class="right" (click)="removeFile(i)">
                  <img [src]="'images/sub_black_delete.png'" height="10px" width="10px" alt="delete" />
                </a>
              </span>
            </li>
          </ul>
          <div class="clear"></div>
          <div>
            <p class="targetSOT-title">{{initialConfig.userBundle['workspace.brick.multitarget.sotAvailability.optiontitle'] || 'When the target SOT for an hour is not available:'}}</p>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="rbtnsot" id="rbtnSkipWholeFrame" [(ngModel)]="selectedSOTOption"  value="SKIP_FRAME" class="radio small">
              <label class="form-check-label" for="rbtnSkipWholeFrame">
                {{ initialConfig.userBundle['workspace.brick.multitarget.sotAvailability.skipwholeframe'] || 'Skip Whole Frame' }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="rbtnsot" id="rbtnSkipHour" [(ngModel)]="selectedSOTOption" value="SKIP_HOUR" class="radio small">
              <label class="form-check-label" for="rbtnSkipHour">
                {{ initialConfig.userBundle['workspace.brick.multitarget.sotAvailability.skiphour'] || 'Skip hour' }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="rbtnsot" id="rbtnSweepUp" [(ngModel)]="selectedSOTOption" value="SWEEP" class="radio small">
              <label class="form-check-label" for="rbtnSweepUp">
                {{ initialConfig.userBundle['workspace.brick.multitarget.sotAvailability.sweepup'] || 'Sweep up' }}
              </label>
            </div>
          </div>
          <div class="clear"></div>          
        </div>
      </div>
    </div>
  </div>
</app-sb-modal-wrapper>
