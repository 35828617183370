import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DataShareService } from './../../core/services/data-share.service';
import { InitialConfigModel } from '../../models';

@Component({
  selector: 'app-filter-layout',
  templateUrl: './filter-layout.component.html',
  styleUrls: ['./filter-layout.component.css']
})
export class FilterLayoutComponent implements OnInit {

  /**
   * It will take data to configure filter layout.
   */
  @Input() resolveObject: any;
  /**
   * It calls event to close filter winodw.
   */
  @Output() closeClick = new EventEmitter<string>();
  /**
   * It calls event to save filter data.
   */
  @Output() saveClick = new EventEmitter<string>();
  /**
   * It contians initial config data.
   */
  public initialConfig: InitialConfigModel ;

  /**
   * Constructor
   * @param dataShareService Service to share data between components
   */
  constructor(private dataShareService: DataShareService) { }

  /**
   * Function which loads initially.
   */
  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  /**
   * Function which emits close event.
   */
  onClose() {
    this.closeClick.emit('Close button Clicked!');
  }

  /**
   * Function which emits save event.
   */
  onSave() {
    this.saveClick.emit('Save button Clicked!');
  }

}
