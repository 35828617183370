import { FrameStatus, editablePeriodId } from '../geo-map/status.enum';
import { DynamicKeyArrayData, DynamicKeyObjectData } from './Dynamic.model';
import { VipProcessFilterModel } from './vip-process-filter.model';

export class MapDataModel {
  columnConfig: DynamicKeyObjectData<ColumnConfig>;
  assetData: FurnitureModel[];
  planning?: VipProcessFilterModel;
  requestFrameTimings?: [string, string][];
  indoorMaps?: any[];
  familyMapIcon?: any[];
  frameOrientations?: any[];
  frameSizeFamilyMapIcons?: any[];
}

export interface ColumnConfig {
  lookupData: ColumnConfigLookUpData;
  audienceCategoryGroupId: number;
  dayPartGroupId: number;
  allowedChannels: number[];
  secondaryAudience: number[];
  rateCardLookupData?: DynamicKeyArrayData<any>;
}

export interface ColumnConfigs {
  [key: number]: ColumnConfig;
}

export interface ColumnConfigLookUpData {
  [key: string]: DynamicKeyArrayData<any>;
}

export interface FurnitureModel {
  furnitureId: number;
  furnitureName: string;
  furnitureFullName?: string;
  latitude: number;
  longitude: number;
  address: string;
  gmIconId?: number | string;
  legendId?: number;
  galleryUrl?: string;
  frames: FrameModel[];
  // internal properties
  visible?: boolean; // furniture should be visible on map or not
  icon?: string; // icon to display on map
  selected?: boolean; // Furniture is selected in frame selection info window
  isPlottedMarker?: boolean; // Is it other campaign plot furniture or not
  planning?: VipProcessFilterModel;
  mapId?: string | number;
  iconImage: string;
  iconImageId: any;
  iconOrientation: number;
  height: number;
  width: number;
  subFamilyName: string;
  disabled: boolean;
  familyId?: string | number;
}

export interface FrameModel {
  startDate?: string; // start date to display on site (will be in user display format)
  endDate?: string; // end date to display on site (will be in user display format)
  startDateReq?: string; // start date to send in request (will be in request format ie. YYYY-MM-DD)
  endDateReq?: string; // end date to send in request (will be in request format ie. YYYY-MM-DD)
  furnitureId?: number;
  furnitureName?: string;
  galleryUrl?: string;
  gmIconId?: number | string;
  latitude: number;
  longitude: number;
  address: string;
  legendId?: number;
  businessAreaName?: string;
  channelName?: string;
  districtName?: string;
  familyName?: string;
  frameId: number;
  frameName: string;
  frameSizeName?: string;
  frameSizeFamilyName?: string;
  frameTypeName?: string;
  illuminated?: boolean;
  mediaName?: string;
  postcode?: string;
  price?: number;
  productFormatName?: string;
  sot?: number | string;
  subFamilyName?: string;
  townName?: string;
  unavailability?: string; // unavailability reason of frame - if frame in cart but now not available
  unavailabilityColor?: string; // color to use highlight the unavailable frame record - Request by brandon on slack
  status: FrameStatus;
  availability?: string;
  frameAltCode?: string;
  frameSizeFamilyId?: number;

  frameOrientationId?: number;
  frameOrientationCode?: string;
  frameOrientationName?:string;
  frameOrientationIconURL?: any;
  frameOrientationColourCode?: string;
  // If past is true, means its past frame and user cant edit or delete that frame from cart,
  // If splitFromDate exist, means its running frame and user cant change start date, only change end date, can't delete
  // If past is false and splitFromDate not exist, means its future and user can change start and end date.
  // also can delete frame from cart
  // In case of running user can change end date upto splitFromDate
  // Date change should be done in dateInterval days.
  past?: boolean; // whether it is past or not
  dateInterval?: number; // interval to change date
  splitFromDate?: string; // splitable date

  requestFrameTimings?: [string, string][]; //

  editablePeriodId?: editablePeriodId; // past, running, future
  selected_cart?: boolean; // is cart frame selected in cart summary
  isDeletable?: boolean; // is frame deletable
  visible?: boolean; // frame should be visible or not on map
  selected?: boolean; // frame is selected in frame selection info window
  channelId?: number;
  visualUnitId?: number;
  disabled: boolean;

  mapId?: string | number;
  iconImage?: string;
  iconImageId?: any;
  iconOrientation?: number;
  height?: number;
  width?: number;
  familyId?: string | number;
}

export interface MapDataObservableModel {
  mapData: FurnitureModel[];
  requestJSON: any;
  isViewCartOnMap: boolean;
  planning?: VipProcessFilterModel;
  requestFrameTimings?: [string, string][];
  indoorMaps?: any[];
  familyMapIcon?: any[];
  frameOrientations?: any[];
  frameSizeFamilyMapIcons?: any[];
}

