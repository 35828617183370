import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, CommercialConfig, HistoryROC } from '../../commercial.model';
import { DataShareService } from '../../../core/services/data-share.service';
import {
  SystemFlags
} from '../../../models';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { StateService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { CampaignDetailsBase } from '../campaign-details-base';
import { CampaignGroupTypeList } from '../../../core/enum';
import { Campaign } from '../../../models/campaign';
import { NumberMaskModel } from '../../../models/imask';

@Component({
  selector: 'app-campaign-details-hk',
  templateUrl: './campaign-details-hk.component.html',
  styleUrls: ['./campaign-details-hk.css']
})

export class CampaignDetailsHKComponent extends CampaignDetailsBase implements OnInit, OnDestroy {
  @Input() campaignDetails: CampaignDetails = new CampaignDetails();
  @Input() commercialConfig: CommercialConfig = new CommercialConfig();
  @Input() historyROC: HistoryROC;
  @Output() manuallyCPTUpdated: EventEmitter<any> = new EventEmitter();

  campaignGroupTypeList = CampaignGroupTypeList;

  /**
   * @description store search page object
   * @type {Campaign}
   * @memberof CampaignDetailsFiComponent
   */
  searchPageObject: Campaign;

  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof CampaignDetailsFiComponent
   */
  currencyInputMask: NumberMaskModel;

  bookingLineDataSubscription: Subscription;

  /**
   * @description set readOnly
   * @memberof CampaignDetailsHkComponent
   */
  public readOnly = SystemFlags.readOnly;

  constructor(
    commercialService: CommercialService,
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    stateService: StateService
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService

    );
    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe((value: any) => {
      if (value.rowIndex >= 0 && value.columnName) {
        this.calculateCPT(value);
      }
      this.onBookingListChange();
    });
  }

  ngOnDestroy() {
    this.bookingLineDataSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.init(this.campaignDetails);
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    this.enableSubAgency();
    this.setAgencyFromConfig(this.campaignDetails);
    this.setLookupConfig();
    this.currencyInputMask = this.maskService.currencyInputMask({ max: 999999999999999999999, padFractionalZeros: true });
    this.searchPageObject = this.stateService.getCampaign();
  }

  /**
   * @description To set the configuration for all lookup components
   * @author Dhaval Patel
   * @memberof CampaignDetailsHKComponent
   */
  setLookupConfig() {
    this.setAdvertiserConfig();
    this.setBrandConfig();
    this.setAgencyConfig();
  }

  // Functions related to Agencies//
  onAgencyNameSelected(value: any): void {
    if (this.campaignDetails.agency) {
      this.onAgencyChange(value);
      this.enableSubAgency();
    }
  }
  removeSelectedAgencyName(): void {
    this.onAgencyChange();
    this.enableSubAgency();
  }

  onBookingListChange() {
    this.campaignDetails.grossMediaValue = 0;
    this.campaignDetails.netMediaValue = 0;
    this.campaignDetails.bookingList.forEach((booking) => {
      this.campaignDetails.grossMediaValue += booking.grossMediaValue;
      this.campaignDetails.netMediaValue += booking.netMediaValue;
    });
  }

  /**
   * @description on CPT value changes then update pmp discount in grid
   * @author Dhaval Patel
   * @memberof CampaignDetailsUkComponent
   */
  onAverageCPTChanged() {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT); // SM-4776
    this.commercialService.notifyCampaignDetails(this.campaignDetails.bookingList);
    if (this.uiControl.enableUserDefinedCPMPricingCalculation) {
      this.manuallyCPTUpdated.emit();
    }
  }

  /**
   * @description calculate CPT from gross media value or pmp discount change
   * @author Dhaval Patel
   * @param {*} value
   * @memberof CampaignDetailsUkComponent
   */
  calculateCPT(value: { rowIndex?: number, columnName?: string }) {
    if (value.columnName === 'grossMediaValue') {
      this.campaignDetailsService.calculateCPT(
        this.campaignDetails, value.rowIndex,
        undefined,
        this.campaignDetails.bookingList[value.rowIndex].grossMediaValue
      );
    } else if (value.columnName === 'pmpdiscount') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex);
    }
  }
}
