import { Brick } from './../../workspace/brick-model';
import { ColumnConfig } from '../../models/MapData.model';
import { ColumnSummary } from './column-summary';

export class CellValues {
  constructor(
    public brick?: Brick,
    public selectedValues?: any,
    public displayText?: string,
    public requestJson?: any,
    public columnConfig?: ColumnConfig,
    public columnSummary?: ColumnSummary,
    public readOnlyModal = false,
    public toolTipText?: string,
    public isOptionalInProduct = false,
    public columnSummaryWithoutBrick?: any,
    public productValidations?: any,
    public cellWidth?: number,
    public sot?: {
      sotFloor?: number | string,
      sotCeiling?: number | string,
      mediaOnlyPaper?: boolean,
      disableFloorAndCeiling?: boolean,
      disableSOTIncrement?: boolean,
      sotIncrement?: number
    },
    public isEditMode = false,
    public sotRestriction?: {
      productValidationVolume: any,
      productValidationBudget: any
    },
    public tagGroupData?: any,
    public reshufflingEngine?: string
  ) {
  }
}
