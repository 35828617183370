import { StateService, BrickBaseService, DataShareService } from '../../core/services';
import { InitialConfigModel } from '../initial-config.model';
import { Row } from './row';

export class ToleranceLimit {

  /**
   * @description cell addresses where need to show tolerance limit icon
   * @type {string[]}
   * @memberof ToleranceLimit
   */
  public cellAddress: string[] = [];

  /**
   * @description brick ids to use in tolerance limit
   * @private
   * @type {number[]}
   * @memberof ToleranceLimit
   */
  private toleranceLimitBrickIds: number[] = [];

  /**
   * @description global initial config
   * @private
   * @type {InitialConfigModel}
   * @memberof ToleranceLimit
   */
  private initialConfig: InitialConfigModel;

  constructor(
    private stateService: StateService,
    private brickBaseService: BrickBaseService,
    private dataShareService: DataShareService,
  ) {
    this.toleranceLimitBrickIds = [
      this.brickBaseService.brickID.Frame as number,
      this.brickBaseService.brickID.Budget as number,
      this.brickBaseService.brickID.Volume as number,
    ];
    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  private isColumnNonEditable(columnIndex: number): boolean {
    const columnHelper = this.stateService.getColumnHelper();
    if (
      columnHelper &&
      columnHelper[columnIndex] &&
      columnHelper[columnIndex].systemLocked
    ) {
      return true;
    } else {
      return false;
    }
  }

  private getColsQtyThreasholdLimit(rows: Row[], rowIndex: number, requestedKey: string): string[] {
    const cols = [];
    const row = rows[rowIndex];
    for (let i = 0; i < row.cells.length; i++) {
      if (row.cells[i] && row.cells[i].selected && Object.keys(row.cells[i].selected).length > 0 && !row.cells[i].isEmpty) {
        const allocated = parseFloat(row.cells[i].selected.allocated) || 0;
        const requested = parseFloat(row.cells[i].selected[requestedKey]) || 0;
        const tolerance = parseFloat(row.cells[i].selected.tolerance) || 0;
        const minThreshold = (requested * (100 - tolerance)) / 100;
        if (allocated < minThreshold && (!this.isColumnNonEditable(i))) { // ignore past columns
          cols.push(`${rowIndex},${i}`);
        }
      }
    }
    return cols;
  }

  private getRowIndexOfToleranceLimitBricks(rows: Row[]): number[] {
    const rowIndexToleranceLimitBricks: number[] = [];
    rows.forEach((row, index: number) => {
      if (this.toleranceLimitBrickIds.indexOf(row.bric.bricid) !== -1) {
        rowIndexToleranceLimitBricks.push(index);
      }
    });
    return rowIndexToleranceLimitBricks;
  }

  private getCellAddressToleranceLimitBricks(rows: Row[]) {
    const cells = [];
    const rowIndexToleranceLimitBrick = this.getRowIndexOfToleranceLimitBricks(rows);
    for (const rowIndex of rowIndexToleranceLimitBrick) {
      let cellAddresses = [];
      if (rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Frame) {
        cellAddresses = this.getColsQtyThreasholdLimit(rows, rowIndex, 'frameCount');
      } else if (rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Budget) {
        cellAddresses = this.getColsQtyThreasholdLimit(rows, rowIndex, 'price');
      } else if (rows[rowIndex].bric.bricid === this.brickBaseService.brickID.Volume) {
        cellAddresses = this.getColsQtyThreasholdLimit(rows, rowIndex, 'impressions');
      }

      for (const address of cellAddresses) {
        if (cells.indexOf(address) === -1) {
          cells.push(address);
        }
      }
    }
    return cells;
  }

  public populateToleranceLimit(rows: Row[]): void {
    this.cellAddress = [];
    if (Object.keys(this.stateService.columnSummary).length > 0 && this.initialConfig.uiControl.checkToleranceLimit) {
      this.cellAddress = this.getCellAddressToleranceLimitBricks(rows);
      if (this.cellAddress.length > 0) {
        this.dataShareService.activateResultTab(false);
        this.dataShareService.activateCommercialTab(false);
      }
    }
  }
}
