import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalOptions, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { FormControl } from '@angular/forms';
import 'rxjs/add/operator/debounceTime';

import { ConcertinaHttpService } from './concertina-http.service';
import { ConcertinaCanvasService } from './concertina-canvas.service';
import { ConcertinaDataService } from './concertina-data.service';
import { GeoMapService } from '../../../geo-map/geo-map.service';
import { LogHelperService } from '../../services/log-helper.service';
import { CartService } from '../../../geo-map/cart.service';
import { FrameFilterType } from '../../../geo-map/status.enum';
import { FolioImageComponent } from '../../../geo-map/folio-image/folio-image.component';
import { AppNameEnum } from '../../enum';
import { DataShareService } from '../../services/data-share.service';
import { LoaderService } from '../../services/loader.service';
import { ConfirmationComponent } from '../../../geo-map/confirmation/confirmation.component';
import { CampaignService } from '../../services/campaign.service';
import { RequestJsonService } from '../../../geo-map/request-json.service';
import * as _ from 'lodash';
import { SwapZoneService } from '../swap-zone';
import { GLOBAL } from '../../utils/app.constant';
import { SwapData, FrameDetails, SystemFlags, InitialConfigModel } from '../../../models';
import { BrickBaseService } from '../../services/brick-base.service';

// declare let CommonFunctions: any; Commenting this line as this might have got added in merging

/**
 * Concertina component
 *
 * @export
 * @class ConcertinaComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-concertina',
  templateUrl: './concertina.component.html',
  styleUrls: ['./concertina.component.css']
})
export class ConcertinaComponent implements OnInit, OnDestroy {

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {
    if (this.swapZoneService.swapMode) {
      if (event.keyCode === 16) {
        this.concertinaCanvasService.shiftPressed = true;
      }
      if (event.keyCode === 17) {
        this.concertinaCanvasService.controlPressed = true;
      }
    }
  }
  @HostListener('window:keyup', ['$event'])
  onKeyUp(event) {
    if (this.swapZoneService.swapMode) {
      if (event.keyCode === 16) {
        this.concertinaCanvasService.shiftPressed = false;
      }
      if (event.keyCode === 17) {
        this.concertinaCanvasService.controlPressed = false;
      }
    }
  }

  // tslint:disable-next-line:variable-name
  private _frameDetailsEnabled;
  // tslint:disable-next-line:variable-name
  private _frameSelectionEnabled;
  // tslint:disable-next-line:variable-name
  private _expandedTab;
  // tslint:disable-next-line:variable-name
  private _availability;

  revertingSwap = false;
  tempTabSwitchEvent = {};
  searchFrame = '';
  filterFrames = new FormControl();
  formCtrlSub: Subscription;
  concertinaTooltip = {
    show: false,
    data: [],
    top: '0px',
    left: '0px',
    isString: false
  };

  @ViewChild('frameDetailsContainer') frameDetailsContainer;

  /**
     * map setting ngbDropdown element. needed this to close dropdown manually i.e. when clicking out side of element
     * @type {NgbDropdown}
     * @memberof GeoMapComponent
     */
  @ViewChild(NgbDropdown) mapSetting: NgbDropdown;
  /**
     * Channel Container element. needed to check wheter click operation happend inside this element or not for manual dropdown close
     * @type {ElementRef}
     * @memberof FilterAreaComponent
     */
  @ViewChild('plannerSettingContainer') plannerSettingContainer: ElementRef;
  showFrameDetails = false;
  showHeader = true;
  swapZoneOpen = false;
  isFilterAreaOpen = true;

  /**
   * cart data subscriber object
   */
  cartDataSubscriber: Subscription;
  /**
   * basket mode subscriber object
   */
  basketModeSubscriber: Subscription;

  /**
   * Filter Type
   *
   * @memberof ConcertinaComponent
   */
  public frameFilterType = FrameFilterType.all;

  /**
     * ngbModal options
     * @type {NgbModalOptions}@memberof GeoMapComponent
     */
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'sm'
  };

  isCartVisible = false;

  /**
     * width of cart
     * @type {number}@memberof GeoMapComponent
     */
  cartWidth = 350;

  /**
   * @description Pass booking details params
   * @memberof ConcertinaComponent
   */
  bookingDetailsParams = {};

  audienceSelectionEnabled = false;

  /**
   * availability, this will be true when called from workspace, when click on availability
   *
   * @type {object}
   * @memberof ConcertinaComponent
   */

  @Input() public set availability(val) {
    this.concertinaDataService.setAvailability(val);
    this._availability = val;
  }

  public get availability() {
    return this._availability;
  }

  @Input() config;

  /**
   * Initial config data
   *
   * @type {object}
   * @memberof ConcertinaComponent
   */
  @Input() initialConfig: InitialConfigModel;

  /**
   * Defines flag for tab expand/collapse
   * @memberof ConcertinaComponent
   */

  @Input() public set expandedTab(value: boolean) {
    if (value && this.availability) {
      this.expandResultDetails();
    }
    this._expandedTab = value;
  }

  public get expandedTab(): boolean {
    return this._expandedTab;
  }

  @Input() public set frameDetailsEnabled(value: boolean) {
    this.concertinaCanvasService.frameDetailsEnabled = value;
    this._frameDetailsEnabled = value;
  }
  public get frameDetailsEnabled() {
    return this._frameDetailsEnabled;
  }

  @Input() frameStatusFiltersEnabled;
  @Input() bookingDetailsEnabled;
  @Input() frameSearchFilterEnabled = false;

  @Input() public set frameSelectionEnabled(value: boolean) {
    this.concertinaCanvasService.frameSelectionEnabled = value;
    this._frameSelectionEnabled = value;
  }
  public get frameSelectionEnabled() {
    return this._frameSelectionEnabled;
  }

  @Output() goToResultPage: EventEmitter<any> = new EventEmitter<any>();

  frameDetailsSubscription: Subscription;
  campaignDetailsSubscription: Subscription;
  tooltipSubscription: Subscription;
  digitalSwapSubscription: Subscription;
  swapDoneSubscription: Subscription;
  swapRevertSubscription: Subscription;
  swapFrameRevertSubscription: Subscription;
  swapZoneDetailsSubscription: Subscription;
  filterAreaSliderSubscription: Subscription;

  public frameDetailObj = {
    frame: new FrameDetails(),
    top: '0px',
    left: '0px'
  };

  /**
   * Defines flag to enable/disable nav buttons
   * @memberof ConcertinaComponent
   */
  showNavButtons = false;

  /**
   * Grouping data
   *
   * @type {any[]}
   * @memberof ConcertinaComponent
   */
  groupingData: any[] = [];
  /**
   * Selected concertina tab
   *
   * @type {number}
   * @memberof ConcertinaComponent
   */
  currentDataType: number;
  /**
   * Array of concertina tabs
   *
   * @type {number[]}
   * @memberof ConcertinaComponent
   */
  concertinaTabs: number[] = [];
  /**
   * Array of sortable items
   *
   * @type {object[]}
   * @memberof ConcertinaComponent
   */
  sortableItems: object[] = [];

  /**
   * Array of sortable items
   *
   * @type {object[]}
   * @memberof ConcertinaComponent
   */
  originalSortableItems: object[] = [];
  /**
   * Request data
   *
   * @type {object}
   * @memberof ConcertinaComponent
   */
  requestData: object = {};
  /**
   * Grouped data
   *
   * @type {object}
   * @memberof ConcertinaComponent
   */
  groupedData: object = {};
  /**
   * Period data
   *
   * @type {object}
   * @memberof ConcertinaComponent
   */
  periodData: object = {};
  /**
   * Subscription variable for filters
   *
   * @type {Subscription}
   * @memberof ConcertinaComponent
   */
  filterSubscription: Subscription;

  showFrameSearchFilter = true;

  selectedAudienceList = [];

  subscriptions: Subscription[] = [];
  /**
   *Creates an instance of ConcertinaComponent.
   * @author Amit Mahida
   * @param {ConcertinaHttpService} httpSerivce
   * @param {ConcertinaCanvasService} concertinaCanvasService
   * @param {ConcertinaDataService} concertinaDataService
   * @param {GeoMapService} gMapService
   * @param {NgbModal} modalService
   * @param {LogHelperService} logHelper
   * @param {CartService} cartService
   * @param {DataShareService} dataShareService
   * @param {LoaderService} loaderService
   * @param {CampaignService} campaignService
   * @param {RequestJsonService} requestJsonService
   * @param {SwapZoneService} swapZoneService
   * @memberof ConcertinaComponent
   */
  constructor(
    private httpSerivce: ConcertinaHttpService,
    public concertinaCanvasService: ConcertinaCanvasService,
    public concertinaDataService: ConcertinaDataService,
    private gMapService: GeoMapService,
    private modalService: NgbModal,
    private logHelper: LogHelperService,
    public cartService: CartService,
    private dataShareService: DataShareService,
    private loaderService: LoaderService,
    private campaignService: CampaignService,
    private requestJsonService: RequestJsonService,
    private brickBaseService: BrickBaseService,
    public swapZoneService: SwapZoneService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.subscriptions.push(this.dataShareService.languageChangedSub.subscribe((result) => {
      if (result) {
        this.concertinaCanvasService.resetConcertinaData();
        this.groupingData = this.updateGroupingData(this.originalSortableItems);
        this.ngOnInit();
        this.expandResultDetails();
        this.changeDetectorRef.detectChanges();
      }
    }));

  }

  /**
   * @description Sets concertina data on filter change
   * @author Amit Mahida
   * @param {*} result
   * @param {*} [requestJSON]
   * @memberof ConcertinaComponent
   */
  setConcertinaData(result, requestJSON?, message?: string) {
    if (result['concertina'] && result['groups']) {
      if (!_.isUndefined(requestJSON)) {
        this.cartService.setRequestJSON(requestJSON);
      }
      const data = {
        data: {
          concertina: result['concertina'],
          groups: result['groups'],
          xHeadings: result['xHeadings']
        }
      };
      const response = this.concertinaDataService.generateResponseForConcertina(data, {});
      this.concertinaDataService.setGroupedData(response['groupedData']);
      this.concertinaCanvasService.pushConcertinaData(response['groupedData'], data.data.groups, data.data.xHeadings);
      this.concertinaCanvasService.setGroups(response['requestData']);
      this.concertinaCanvasService.userBundle = this.initialConfig['userBundle'];
      this.concertinaCanvasService.drilledDownKeys = [];
      this.concertinaCanvasService.initThisService();
      if (this.concertinaCanvasService.isFiltersApplied()) {
        this.concertinaCanvasService.filterConcertinaData();
      } else {
        // this.loaderService.show();
        setTimeout(() => {
          this.concertinaCanvasService.refreshTable();
          // this.loaderService.hide();
        }, 10);
      }
      this.concertinaDataService.$framesCount.next(this.concertinaDataService.filterCounts);
    } else {
      this.concertinaCanvasService.initThisService();
      if (message) {
        this.concertinaCanvasService.printNoDataFound(message);
      } else {
        this.concertinaCanvasService.printNoDataFound(this.initialConfig['userBundle']['vip.concertina.selectMandatoryFilters']
          || 'Please Select Mandatory Filters!');
      }
      this.concertinaCanvasService.resetConcertinaData();
      this.clearFrameSearch();
      this.concertinaDataService.$framesCount.next({ total: 0, available: 0, selected: 0 });
    }
  }

  /**
   * @description Populates list of selected audience categories from workspace
   * @author Amit Mahida
   * @param {*} rows
   * @returns
   * @memberof ConcertinaComponent
   */
  fetchListOfSelectedAudienceTypes(rows) {
    let selectedAudienceList = [];
    const audienceRow = rows.filter(row => row.bricId === this.brickBaseService.brickID.AllAudience)[0];
    if (audienceRow) {
      for (const cell of audienceRow.brics) {
        selectedAudienceList = _.union(selectedAudienceList, cell.Selected.filteredData);
      }
    }
    selectedAudienceList = _.uniqBy(selectedAudienceList, 'audienceCategoryId');
    selectedAudienceList = _(selectedAudienceList).chain().sortBy((aud) => {
      return aud.audienceCategoryName;
    }).sortBy((aud) => {
      return Number(aud.weightDisplay) * -1;
    }).value();
    return selectedAudienceList;
  }

  /**
   * Initialization method
   *
   * @memberof ConcertinaComponent
   */
  ngOnInit() {
    this.searchFrame = '';
    this.concertinaCanvasService.frameSearchKey = '';
    // debounce keystroke events
    this.formCtrlSub = this.filterFrames.valueChanges
      .debounceTime(1000)
      .subscribe((newValue) => {
        this.searchFrame = newValue;
        this.concertinaCanvasService.frameSearchKey = newValue;
        this.concertinaCanvasService.filterConcertinaData();
      });

    this.concertinaCanvasService.initialConfig = this.initialConfig;
    if (this.initialConfig.uiControl.hideShowAdvertiserIdEnabled) {
      this.gMapService.concertinaHideAdvertiserId = this.concertinaCanvasService.hideAdvertiserId;
    }

    this.getConfigurationData().then((response: any) => {
      if (response) {
        if (this.dataShareService.appName === AppNameEnum.visualplanner && this.frameSelectionEnabled) {
          this.cartService.setIsViewCartOnMap(false);
          if (this.config.refreshFilter) {
            if (this.requestJsonService.requestJsonData.length > 0) {
              this.requestJsonService.requestJsonDataChange();
            }
          }
          // Alkesh - SM-2400: moved getBasket call here
          // to make sure API call are in correct order
          if (SystemFlags.getBasketDataForVP || this.config.isLoadedData) {
            this.concertinaCanvasService.showFI = false;
            this.cartService.getBasket().then((res) => {
              if (res.status === 'OK') {
                const result: any = res.data;
                if (!_.isUndefined(result)) {
                  this.cartService.setCartSummary(result.campaignSummary[0]);
                  this.cartService.rebuildCartData(result.basketData);
                  if (result.basketData.length > 0) {
                    this.concertinaDataService.setBasketMode(true);
                    this.campaignService.loadCartOnPlanner();
                  } else {
                    this.concertinaDataService.setBasketMode(false);
                  }
                  this.campaignService.clearBackupFilters();
                  this.config.onInitialCartDataSet(this.cartService.cartData);
                  this.concertinaDataService.filterCounts = result.filterCounts;
                  this.concertinaDataService.$framesCount.next(this.concertinaDataService.filterCounts);
                }
              } else {
                this.logHelper.logError(res.message);
              }
            });
          } else if (this.concertinaDataService.basketMode) {
            this.campaignService.loadCartOnPlanner();
          }
          this.filterSubscription = this.gMapService.mapData$.subscribe((res: any) => {
            if (res && res['planning'] && Object.keys(res['planning']).length) {
              const result = res['planning'];
              if (result['concertina'] && result['groups'] && result['xHeadings']) {
                this.concertinaDataService.setBasketMode(false);
                this.showFrameSearchFilter = true;
                this.concertinaCanvasService.resetConcertinaData();
                this.concertinaCanvasService.resetCurrentSelection();
                this.setConcertinaData(result, res.requestJSON);
                if (result.filterCounts) {
                  this.concertinaDataService.filterCounts = result.filterCounts;
                  this.concertinaDataService.$framesCount.next(this.concertinaDataService.filterCounts);
                }
              } else {
                if (!this.concertinaDataService.basketMode) {
                  this.setConcertinaData({}, {}, this.initialConfig['userBundle']
                  ['vip.concertina.noDataAvailable'] || 'No Data Available!');
                  this.showFrameSearchFilter = false;
                }
              }
            } else {
              if (!this.concertinaDataService.basketMode) {
                this.showFrameSearchFilter = false;
                this.setConcertinaData({}, {});
              }
            }
            this.bookingDetailsParams = {};
          });

          this.basketModeSubscriber = this.concertinaDataService.basketMode$.subscribe((isBasketMode: boolean) => {
            if (isBasketMode) {
              this.showFrameSearchFilter = true;
              this.concertinaCanvasService.initThisService();
            }
          });

          this.frameDetailsSubscription = this.concertinaCanvasService.$frameDetailsSub.subscribe((res) => {
            const event = res.event;
            const frameDetails = res.data;
            if (frameDetails != null) {
              this.frameDetailObj.top = `${event.evt.pageY - 286}px`;
              this.frameDetailObj.left = `${event.evt.pageX - 124}px`;
              this.frameDetailObj.frame = frameDetails;
              this.showFrameDetails = true;
            } else {
              this.showFrameDetails = false;
            }
          });

          this.campaignDetailsSubscription = this.concertinaCanvasService.$campaignDetailsSub.subscribe((res) => {
            this.bookingDetailsParams = {};
            const bookingDetailsParams = {
              frame: res.frame,
              requestFrameTimings: [],
              frameCode: res.frameCode
            };
            res.range.forEach((element) => {
              bookingDetailsParams.requestFrameTimings.push([
                element.startDate, element.endDate
              ]);
            });

            const reqParam = {};
            reqParam['concertinaGrouping'] = JSON.stringify(this.concertinaCanvasService.groupingData);
            const reqData = {};
            reqData[bookingDetailsParams.frame] = bookingDetailsParams.requestFrameTimings;
            if (bookingDetailsParams.frame === 'y' || bookingDetailsParams.frame.toString().indexOf(GLOBAL.CONCERTINA_SEPARATOR) > -1) {
              reqParam['groupingData'] = JSON.stringify(reqData);
              reqParam['frameData'] = '{}';
            } else {
              reqParam['frameData'] = JSON.stringify(reqData);
              reqParam['groupingData'] = '{}';
            }
            this.bookingDetailsParams = {
              reqParam,
              frameCode: bookingDetailsParams.frameCode,
              sot: res.sot,
              impressions: res.impressions,
              bookingDetails: res.bookingDetails
            };
          });

          this.swapDoneSubscription = this.swapZoneService.swapDone$.subscribe((swapDone) => {
            this.concertinaCanvasService.swapDone(swapDone);
          });

          this.swapRevertSubscription = this.swapZoneService.swapRevert$.subscribe((swapData) => {
            this.concertinaCanvasService.revertSwap(swapData);
            if (this.revertingSwap && this.swapZoneService.swaps.length === 0) {
              this.tabChanged(this.tempTabSwitchEvent);
            }
          });

          this.swapFrameRevertSubscription = this.swapZoneService.swapFrameRevert$.subscribe((frameReverted) => {
            if (frameReverted) {
              this.concertinaCanvasService.updateTable();
            }
          });

          this.swapZoneDetailsSubscription = this.concertinaCanvasService.$swapZoneOpenSub.subscribe(() => {
            this.swapZoneOpen = true;
            this.swapZoneService.openSwapZone(this.swapZoneOpen);
          });
          this.filterAreaSliderSubscription = this.concertinaCanvasService.$filterAreaSliderSub.subscribe((open) => {
            this.isFilterAreaOpen = open;
          });
        }

        if (this.dataShareService.appName === AppNameEnum.result) {
          this.audienceSelectionEnabled = true;
          this.expandResultDetails();
        } else {
          this.audienceSelectionEnabled = false;
        }
        this.tooltipSubscription = this.concertinaCanvasService.$concertinaTooltip.subscribe((res) => {
          this.concertinaTooltip = res.tooltipData;
          if (this.concertinaTooltip.show) {
            if (this.concertinaTooltip.data.length > 0) {
              if ((typeof (this.concertinaTooltip.data) === 'string')) {
                this.concertinaTooltip.isString = true;
              }
            } else {
              this.concertinaTooltip.show = false;
            }
          }
          const event = res.event;
          if (event !== null) {
            if ((event.evt.pageY + 195) > window.screen.availHeight) {
              this.concertinaTooltip.top = `${(event.evt.pageY - 215)}px`;
            } else {
              this.concertinaTooltip.top = `${event.evt.pageY - 65}px`;
            }

            if (this.dataShareService.appName === AppNameEnum.visualplanner) {
              if ((event.evt.pageX + 294) > window.screen.availWidth) {
                this.concertinaTooltip.left = `${(event.evt.pageX + 5 - 289)}px`;
              } else {
                this.concertinaTooltip.left = `${event.evt.pageX + 5}px`;
              }
            } else if (this.dataShareService.appName === AppNameEnum.result) {
              if ((event.evt.pageX + 294) > window.screen.availWidth) {
                this.concertinaTooltip.left = `${(event.evt.pageX + 5 - 289)}px`;
              } else {
                this.concertinaTooltip.left = `${event.evt.pageX - this.concertinaCanvasService.stageWidth}px`;
              }
            } else {
              this.concertinaTooltip.top = `${event.evt.pageY - 110}px`;
              if ((event.evt.pageX + 284) > window.screen.availWidth) {
                this.concertinaTooltip.left = `${(event.evt.pageX - 278)}px`;
              } else {
                this.concertinaTooltip.left = `${event.evt.pageX - 175}px`;
              }
            }
          }
        });
        if (this.dataShareService.appName === AppNameEnum.workspace) {
          this.expandedTab = true;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.swapZoneService.swapMode) {
      this.swapZoneService.setSwapData(new SwapData());
      this.concertinaCanvasService.readOnlyForSwap = false;
      this.swapZoneService.swapMode = false;
    }
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.cartDataSubscriber) {
      this.cartDataSubscriber.unsubscribe();
    }
    if (this.basketModeSubscriber) {
      this.basketModeSubscriber.unsubscribe();
    }
    if (this.frameDetailsSubscription) {
      this.frameDetailsSubscription.unsubscribe();
    }
    if (this.tooltipSubscription) {
      this.tooltipSubscription.unsubscribe();
    }
    if (this.campaignDetailsSubscription) {
      this.campaignDetailsSubscription.unsubscribe();
    }
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
    if (this.swapDoneSubscription) {
      this.swapDoneSubscription.unsubscribe();
    }
    if (this.swapRevertSubscription) {
      this.swapRevertSubscription.unsubscribe();
    }
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
    if (this.swapFrameRevertSubscription) {
      this.swapFrameRevertSubscription.unsubscribe();
    }
    if (this.swapZoneDetailsSubscription) {
      this.swapZoneDetailsSubscription.unsubscribe();
    }
    if (this.filterAreaSliderSubscription) {
      this.filterAreaSliderSubscription.unsubscribe();
    }
    this.concertinaCanvasService.currentConcertinaPos = {
      x: 0, y: 0
    };
    this.concertinaCanvasService.previousDataX = 0;
    this.concertinaCanvasService.resetConcertinaData();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

  }

  /**
   * To get concertina data
   * @param {any} key Selected key to get data
   * @returns Concertina data
   * @memberof ConcertinaComponent
   */
  generateConcertina(key) {
    return new Promise((resolve) => {
      if (this.expandedTab) {
        this.concertinaDataService.setAvailability(this.availability);
        let hideAdvertiserId;
        if (this.initialConfig.uiControl.hideShowAdvertiserIdEnabled) {
          hideAdvertiserId = this.concertinaCanvasService.hideAdvertiserId;
        }
        this.concertinaDataService.getConcertinaData(key, this.groupingData, this.requestData,
          this.groupedData, hideAdvertiserId).then((data) => {
            if (data['groupedData'] || data['requestData'] || data['groupingData']) {
              this.periodData = this.groupedData['y'] ? this.groupedData['y'].xGroups : [];
              this.groupingData = data['groupingData'];
              this.requestData = data['requestData'];
              this.groupedData = data['groupedData'];
              this.concertinaDataService.setGroupedData(this.groupedData);
              this.concertinaCanvasService.setConcertinaData(this.groupedData, data['groups'], data['xHeadings'], true);
              resolve(true);
            }
          }, (error) => {
            console.log(error);
          });
      } else {
        console.log('Error');
      }
    });
  }

  /**
   * @description Get concertina config data from server
   * @author Amit Mahida
   * @memberof ConcertinaComponent
   */
  getConfigurationData() {
    return new Promise((resolve, reject) => {
      const reqParams = {
        'bricsCampaignId': GLOBAL.BRIC_CAMPAIGN_ID,
        'action': 'getConcertinaConfig'
      };
      if (this.availability) {
        reqParams['availabilityMode'] = this.availability;
      }
      this.httpSerivce.getConcertinaConfig(reqParams).subscribe((res) => {
        if ((res['status']).toUpperCase() === 'OK' && res['data']) {
          const data = res['data'];
          this.concertinaTabs = data['concertinaTabs'];
          this.sortableItems = data['sortableItems'];
          this.originalSortableItems = JSON.parse(JSON.stringify(data['sortableItems']));
          this.selectedAudienceList = data.selectedAudienceCategories || [];
          this.concertinaCanvasService.setSelectedTab(0);
          this.concertinaDataService.currentDataType = 0;
          this.groupingData = this.updateGroupingData(this.sortableItems);
          this.concertinaCanvasService.setGroupingdata(this.groupingData);
          resolve(true);
        }
      }, (error) => {
        reject(error);
      });
    });
  }

  /**
   * @description Fits concertina to zoom level
   * @author Amit Mahida
   * @memberof ConcertinaComponent
   */
  onResize() {
    this.concertinaCanvasService.fitStageIntoParentContainer();
  }

  /**
     * toggle cart display
     * @memberof GeoMapComponent
     */
  toggleCart() {
    this.isCartVisible = !this.isCartVisible;
  }

  /**
   * To update grouping data   *
   * @param {any} sortableItems Array of sortable items
   * @returns Grouping data object
   * @memberof ConcertinaComponent
   */
  updateGroupingData(sortableItems) {
    const groupingData = [];
    _.map(sortableItems, (obj) => {
      if (obj['value'] === true) {
        groupingData.push(obj['selectionId']);
      }
    });
    return groupingData;
  }

  /**
   * @description On Grouping change updates concertina data
   * @author Amit Mahida
   * @param {any} filters
   * @memberof ConcertinaComponent
   */
  onGroupingChange(filters: any[]) {
    if (!_.isUndefined(this.concertinaDataService.concertinaData)) {
      const newGroupingData = this.updateGroupingData(filters);
      this.cartService.concertinaGrouping = newGroupingData;
      this.requestData = {};
      this.concertinaCanvasService.setGroupingdata(newGroupingData);
      this.groupingData = newGroupingData;
      this.generateConcertina(undefined).then((data) => {
        if (data) {
          this.concertinaCanvasService.setGroups(this.requestData);
          this.concertinaCanvasService.resetCurrentSelection();
          this.concertinaCanvasService.initThisService();
          this.loaderService.show();
          setTimeout(() => {
            this.concertinaCanvasService.currentConcertinaPos = {
              x: 0, y: 0
            };
            this.concertinaCanvasService.previousDataX = 0;
            this.concertinaCanvasService.drilledDownKeys = [];
            if (this.concertinaCanvasService.isFiltersApplied()) {
              this.concertinaCanvasService.filterConcertinaData();
            } else {
              this.concertinaCanvasService.refreshTable();
            }
            this.loaderService.hide();
          }, 10);
        }
      });
    } else {
      const newGroupingData = this.updateGroupingData(filters);
      this.cartService.concertinaGrouping = newGroupingData;
      this.concertinaCanvasService.setGroupingdata(newGroupingData);
      this.groupingData = newGroupingData;
    }
  }

  /**
   * @description Returns selected tab details
   * @author Amit Mahida
   * @param {any} event
   * @memberof ConcertinaComponent
   */
  tabChanged(event) {
    if (event.resetSwaps) {
      this.revertingSwap = true;
      delete event.resetSwaps;
      this.tempTabSwitchEvent = event;
      this.swapZoneService.allSwapsSelected = true;
      this.swapZoneService.swaps.forEach(swap => swap.selected = true);
      this.swapZoneService.deleteSwaps();
    } else {
      this.revertingSwap = false;
      if (!_.isUndefined(this.concertinaDataService.concertinaData)
        && !_.isEmpty(this.concertinaDataService.concertinaData)) {
        if (this.dataShareService.appName === AppNameEnum.workspace && this.concertinaCanvasService.tabValue === GLOBAL.concertinaNotSelectedTab) {
          this.concertinaCanvasService.forceFilterApplied = true;
        }
        this.concertinaCanvasService.setSelectedTab(event.index);
        this.concertinaDataService.currentDataType = event.index;
        this.concertinaCanvasService.tabValue = event.tab;
        if (this.concertinaCanvasService.isFiltersApplied()) {
          this.concertinaCanvasService.filterConcertinaData();
        } else {
          this.concertinaCanvasService.updateTable();
        }
      }
    }

  }

  /**
   *
   * @memberof ConcertinaComponent
   */
  statusFilterChange(status) {
    if (!_.isUndefined(this.concertinaDataService.concertinaData)) {
      this.showFrameDetails = false;
      this.bookingDetailsParams = {};
      this.concertinaCanvasService.statusFilter = status;
      this.concertinaCanvasService.filterConcertinaData();
    }
  }

  /**
   * @description Redirect to result page
   * @author Amit Mahida
   * @memberof ConcertinaComponent
   */
  redirectToResultPage() {
    this.goToResultPage.emit();
  }

  /**
   * @description Handles vertical scrolling
   * @author Amit Mahida
   * @param {*} event
   * @memberof ConcertinaComponent
   */
  onScroll(event) {
    this.showFrameDetails = false;
    this.showHeader = event.currentTarget.scrollTop < 35 ? true : false;
  }

  /**
   * @description Handles horizontal scrolling
   * @author Amit Mahida
   * @param {*} event
   * @memberof ConcertinaComponent
   */
  onScrollHeader() {
    // TODO : horizontal scrolling on mouse wheel
    // console.log(event.eventPhase);
  }

  onShowFurnitureImages(furniture) {
    const modalOptions: any = {
      keyboard: this.ngbModalOptions.keyboard,
      size: 'md animated slideInDown',
      windowClass: 'alignmodal'
    };
    const modalRef = this.modalService.open(FolioImageComponent, modalOptions);
    modalRef.componentInstance.furniture = furniture;
  }

  /**
   * @description Clears the search key
   * @author Amit Mahida
   * @memberof ConcertinaComponent
   */
  clearFrameSearch() {
    const searchInput: any = document.querySelector('#frameSearchFilter');
    if (searchInput) {
      searchInput.value = '';
    }
    this.searchFrame = '';
    this.concertinaCanvasService.frameSearchKey = '';
    this.concertinaCanvasService.filterConcertinaData();
  }

  //#region - cart summary

  /**
   * Deleting frame From cart summary
   * @memberof GeoMapComponent
   */
  onDeleteFromCartSummary() {
    if (this.requestJsonService.requestJsonData.length > 0) {
      this.requestJsonService.requestJsonDataChange();
    } else if (this.cartService.cartData.length === 0) {
      this.concertinaDataService.setBasketMode(false);
      this.concertinaCanvasService.resetConcertinaData();
      this.setConcertinaData({}, {});
    } else if (this.concertinaDataService.basketMode) {
      this.campaignService.loadCartOnPlanner();
    }
  }

  /**
   * on cart detail modal pop up close event handler
   * @memberof GeoMapComponent
   */
  onCartDetailClose(isDataChanged: boolean) {
    if (isDataChanged) {
      if (this.requestJsonService.requestJsonData.length > 0) {
        this.requestJsonService.requestJsonDataChange();
      } else if (this.concertinaDataService.basketMode) {
        this.campaignService.loadCartOnPlanner();
      }
    }
  }
  //#endregion - cart summary

  isDuplicateIndustry(data, industry) {
    let count = 0;
    // For industry tab only
    if (this.concertinaCanvasService.selectedTabIndex === 6) {
      for (const element of data) {
        if (element === industry) {
          count++;
        }
      }
    }
    if (count > 1) {
      return '#ff0000';
    } else {
      return '#000000';
    }
  }

  resetSelection() {
    this.concertinaCanvasService.resetDeleteMarks();
    this.concertinaCanvasService.resetNewSelection();
    this.concertinaCanvasService.resetSwapSelection();
  }

  /**
   * @description Handles request to save the selected frames to cart
   * @author Amit Mahida
   * @memberof ConcertinaComponent
   */
  saveToCart() {
    const tempCartData = _.cloneDeep(this.cartService.cartData);
    this.concertinaCanvasService.updateCartDataOnDelete();
    if (this.concertinaCanvasService.loadingLayer) {
      this.concertinaCanvasService.loadingLayer.hide();
    }
    this.concertinaCanvasService.saveFrame().subscribe((res: any) => {
      if (res.status === 'OK') {
        if (res.data.campaignSummary) {
          this.cartService.setCartSummary(res.data.campaignSummary[0]);
        }
        const saveMsg = this.initialConfig.userBundle['geoplanner.success.saveselectedframes'] || 'Selected frames saved.';
        this.logHelper.logSuccess(saveMsg);
      } else if (res.status === 'KO') {
        this.cartService.setCartData(_.cloneDeep(tempCartData));
        this.logHelper.logError(res.message);
      }
      if (this.concertinaDataService.basketMode) {
        this.campaignService.loadCartOnPlanner();
      } else {
        this.requestJsonService.requestJsonDataChange();
      }
    }, (err) => {
      this.cartService.setCartData(_.cloneDeep(tempCartData));
      this.logHelper.logError(err.message);
    });
  }

  saveSelectedFrames(): void {
    this.loaderService.show();
    // Show loader - needed timeout to start loader first
    // Needed: In large data set it looks browser hanged
    setTimeout(() => {
      if (SystemFlags.getBasketDataForVP) {
        const modalOptions: any = {
          backdrop: this.ngbModalOptions.backdrop,
          keyboard: this.ngbModalOptions.keyboard,
        };
        this.loaderService.hide();
        const modalRef = this.modalService.open(ConfirmationComponent, modalOptions);
        modalRef.componentInstance.resolveObject = {
          content: this.initialConfig.userBundle['geoplanner.confirm.redirection'],
          title: this.initialConfig.userBundle['result.confirmation.label'],
          ok: this.initialConfig.userBundle['common.ok'],
        };
        modalRef.result.then(() => {
          this.saveToCart();
          SystemFlags.getBasketDataForVP = false;
        }, (reason) => {
          console.log(reason);
        }).catch(() => {
          // nothing to be done on close
        });
      } else {
        this.loaderService.hide();
        this.saveToCart();
      }
    }, 10);
  }

  toggleSwapMode() {
    if (this.swapZoneService.swapMode && (this.swapZoneService.swaps.length > 0 || Object.keys(this.concertinaCanvasService.multiSelectionForSwap).length)) {
      const modalRef = this.modalService.open(ConfirmationComponent, this.ngbModalOptions);
      modalRef.componentInstance.resolveObject = {
        content: this.initialConfig.userBundle['vp.swap.confirm.message']
          ? this.initialConfig.userBundle['vp.swap.confirm.message'] : 'If the swap mode is turned off, the work would be lost!',
        title: this.initialConfig.userBundle['vp.swap.confirmation.label']
          ? this.initialConfig.userBundle['vp.swap.confirmation.label'] : 'Swap Confiramtion',
        ok: this.initialConfig.userBundle['common.ok']
      };
      modalRef.result.then(() => {
        this.swapZoneService.swapMode = !this.swapZoneService.swapMode;
        if (this.swapZoneService.swapMode) {
          this.concertinaCanvasService.readOnlyForSwap = true;
          this.resetSelection();
        } else {
          this.concertinaCanvasService.readOnlyForSwap = false;
        }
        if (this.requestJsonService.requestJsonData.length > 0) {
          this.requestJsonService.requestJsonDataChange();
          this.swapZoneService.setSwapData(new SwapData());
        }
      }, () => {
        // do nothing
      }).catch(() => {
        // nothing to be done on close
      });
    } else {
      this.swapZoneService.swapMode = !this.swapZoneService.swapMode;
      if (this.swapZoneService.swapMode) {
        this.concertinaCanvasService.readOnlyForSwap = true;
        this.concertinaCanvasService.toggleFilterArea(true);
        this.resetSelection();
      } else {
        this.concertinaCanvasService.readOnlyForSwap = false;
        this.concertinaCanvasService.toggleFilterArea(false);
      }
      this.concertinaCanvasService.updateTable();
    }
  }

  openSwapZone() {
    this.swapZoneOpen = !this.swapZoneOpen;
    this.swapZoneService.openSwapZone(this.swapZoneOpen);
  }

  /**
   * This method checks if the Selected Tab is Campaign Refrence Tab
   *
   * @memberof ConcertinaComponent
   */
  isCampaignRefrenceTab() {
    return this.concertinaCanvasService.tabValue === GLOBAL.vpSwappingTabId;
  }

  onSwapZoneClose() {
    this.swapZoneOpen = false;
    this.swapZoneService.openSwapZone(false);
  }

  /**
   * Trigger on document click
   * this is needed for map setting ngbDropdown component to manually close on clicking out side of component
   * @param {any} $event
   * @memberof GeoMapComponent
   */
  onDocumentClick($event: MouseEvent): void {
    const dropdownEle: any = this.mapSetting;
    if (dropdownEle.isOpen()) {
      if ($event.button !== 2 && !dropdownEle._isEventFromToggle($event)
        && !!this.plannerSettingContainer.nativeElement && !this.plannerSettingContainer.nativeElement.contains($event.target)) {
        dropdownEle.close();
      }
    }
  }

  toggleFI() {
    this.concertinaCanvasService.showFI = !this.concertinaCanvasService.showFI;
    this.concertinaCanvasService.toggleFI();
  }

  toggleHideAdvertiserId() {
    this.concertinaCanvasService.hideAdvertiserId = !this.concertinaCanvasService.hideAdvertiserId;
    this.gMapService.concertinaHideAdvertiserId = this.concertinaCanvasService.hideAdvertiserId;
    this.requestData = {};
    this.generateConcertina(undefined).then((data) => {
      if (data) {
        this.concertinaCanvasService.setGroups(this.requestData);
        this.concertinaCanvasService.resetCurrentSelection();
        this.concertinaCanvasService.initThisService();
        this.loaderService.show();
        setTimeout(() => {
          this.concertinaCanvasService.currentConcertinaPos = {
            x: 0, y: 0
          };
          this.concertinaCanvasService.previousDataX = 0;
          this.concertinaCanvasService.drilledDownKeys = [];
          if (this.concertinaCanvasService.isFiltersApplied()) {
            this.concertinaCanvasService.filterConcertinaData();
          } else {
            this.concertinaCanvasService.refreshTable();
          }
          this.loaderService.hide();
        }, 10);
      }
    });
  }

  /**
   * This method will be called when result details tab will be expanded or user change language
   * @memberof ConcertinaComponent
   */
  private expandResultDetails() {
    const availability = this.concertinaDataService.getAvailability();
    if (this.dataShareService.appName === AppNameEnum.result || availability) {
      if (_.isEmpty(this.concertinaCanvasService.groups)) {
        const params: any = {
          'bricsCampaignId': GLOBAL.BRIC_CAMPAIGN_ID,
          'action': 'processConcertina',
          'concertinaRequest': {
            'grouping': this.groupingData,
            'groups': {}
          }
        };
        if (availability) {
          params['availabilityMode'] = availability;
        }
        this.concertinaDataService.getConcertinaDataForResult(params).subscribe((res) => {
          if (res['data']) {
            const result = res['data'];
            this.setConcertinaData(result);
          } else {
            this.logHelper.logError('Not getting data from server!');
          }
        });
      }
    }
  }

  trackByItem(index, item) {
    return item;
  }

}
