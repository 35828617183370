import { Injectable } from '@angular/core';
import { DisplayTextGenerator } from './../utils/display-text-generator';
import { RequestJsonGenerator } from './../utils/request-json-generator';

import { CurrencyFormatPipe } from '../pipes/currency-format.pipe';

import { DataShareService } from './data-share.service';
import { SharedService } from './shared.service';
import { BrickBaseService } from './brick-base.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { TooltipTextGenerator } from '../utils/tooltip-text-generator';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { GLOBAL } from '../utils/app.constant';

@Injectable()
export class CellAttributeService {
  private multipleText: string;
  private userBundle: any;
  private displayTextGenerator: DisplayTextGenerator;
  private requestJsonGenerator: RequestJsonGenerator;
  private tooltipTextGenerator: TooltipTextGenerator;

  constructor(
    private brickBaseService: BrickBaseService,
    private currencyFormatPipe: CurrencyFormatPipe,
    private decimalPipe: DecimalPipe,
    private dataShareService: DataShareService,
    private sharedService: SharedService,
    private datePipe: DatePipe,
    private appHeaderService: AppHeaderService
  ) {
    this.displayTextGenerator = new DisplayTextGenerator(decimalPipe);
    this.requestJsonGenerator = new RequestJsonGenerator();
    this.tooltipTextGenerator = new TooltipTextGenerator();
  }

  private setUserBundle() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.displayTextGenerator.userBundle = this.userBundle;
    this.tooltipTextGenerator.userBundle = this.userBundle;
    this.displayTextGenerator.allSelectionText = this.userBundle['workspace.common.allSelection'];
    this.tooltipTextGenerator.allSelectionText = this.userBundle['workspace.common.allSelection'];
    this.multipleText = this.userBundle['worksapce.brisc.label.multipleSelection'];
  }

  /**
   * @description Method to get display text
   * @author Vijay Sutaria
   * @param {number} brickID Brick ID for which display text is to be generated
   * @param {*} selectedValue Selected Value object from which display text will be generated
   * @param {number} [cellIndex] CellIndex of brick (Optional parameter)
   * @returns returns display text in string format
   * @memberof CellAttributeService
   */
  public getDisplayText(brickID: number | string, selectedValue: any, cellIndex?: number) {
    const uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.setUserBundle();
    switch (brickID) {
      case this.brickBaseService.brickID.Audience:
        return this.displayTextGenerator.getDisplayTextForAudienceBrick(selectedValue, this.userBundle);
      case this.brickBaseService.brickID.SecondaryAudience:
        return this.displayTextGenerator.getDisplayTextForAudienceBrick(selectedValue, this.userBundle);
      case this.brickBaseService.brickID.Budget:
        return this.displayTextGenerator.getDisplayTextForBudget(
          selectedValue,
          this.currencyFormatPipe,
          this.userBundle,
          this.appHeaderService.enabledPCM,
        );
      case this.brickBaseService.brickID.Channel:
        const lookUpColumnForBrick = this.dataShareService.getInitialConfigByKey('lookupColumn').find((e: any) => e.brickId === this.brickBaseService.brickID.Channel);
        const selectionId = lookUpColumnForBrick.lookup[0].selectionId;
        return this.displayTextGenerator.getDisplayTextForChannelNewBrick(
          selectedValue,
          this.userBundle,
          this.multipleText,
          selectionId
        );
      case this.brickBaseService.brickID.Distribution:
        return this.displayTextGenerator.getDisplayTextForDistributionBrick(selectedValue, this.userBundle);
      case this.brickBaseService.brickID.Environment:
        return this.displayTextGenerator.getDisplayText(selectedValue, this.userBundle, this.multipleText, false, 18);
      case this.brickBaseService.brickID.Format:
      case this.brickBaseService.brickID.Location:
        return this.displayTextGenerator.getDisplayText(selectedValue, this.userBundle, this.multipleText, uiControl.locationInAlphabeticalOrder);
      case this.brickBaseService.brickID.Frame:
        return this.displayTextGenerator.getDisplayTextForFrameBrick(
          selectedValue,
          this.userBundle,
          this.decimalPipe,
          this.appHeaderService.enabledPCM
        );
      case this.brickBaseService.brickID.FrameQuality:
        return this.displayTextGenerator.getDisplayTextForFrameQuality(
          selectedValue,
          this.userBundle
        );
      case this.brickBaseService.brickID.Incharge:
        return this.displayTextGenerator.getDisplayTextForInchargeBrick(selectedValue, this.userBundle, uiControl.showDayofWeekRangeBric);
      case this.brickBaseService.brickID.List:
        return this.displayTextGenerator.getDisplayTextForList(
          selectedValue,
          this.userBundle
        );
      case this.brickBaseService.brickID.Media:
        return this.displayTextGenerator.getDisplayTextForMediaBrick(selectedValue);
      case this.brickBaseService.brickID.Pricing:
        return this.displayTextGenerator.getDisplayTextForPricing(
          this.dataShareService.getColumnSummary(cellIndex).solutionPrice,
          selectedValue,
          this.userBundle['workspace.pricing.title'],
          this.currencyFormatPipe
        );
      case this.brickBaseService.brickID.PricingTag:
        const lookupColumn = this.dataShareService.getInitialConfigByKey('lookupColumn').find((e: any) => e.brickId === this.brickBaseService.brickID.PricingTag);
        const pricingTagSelectionId = lookupColumn.lookup[0].selectionId;
        return this.displayTextGenerator.getDisplayTextForPricingTagBrick(
          selectedValue,
          this.multipleText,
          pricingTagSelectionId,
          this.userBundle
        );
      case this.brickBaseService.brickID.Proximity:
        return this.displayTextGenerator.getDisplayTextForProximityBrick(selectedValue, this.multipleText, this.userBundle);
      case this.brickBaseService.brickID.SOT:
        return this.displayTextGenerator.getDisplayTextForSOTBrick(
          uiControl.sotFrequencyEnabled,
          selectedValue,
          this.userBundle,
          this.appHeaderService.enabledPCM
        );
      case this.brickBaseService.brickID.Tag:
        return this.displayTextGenerator.getDisplayTextForTag(selectedValue, this.userBundle, this.multipleText);
      case this.brickBaseService.brickID.Volume:
        const impressions = this.appHeaderService.enabledPCM
          ? null
          : this.userBundle['workspace.rating.bric.volume.impressionsAllocated.approx'];
        return this.displayTextGenerator.getDisplayTextForVolume(
          selectedValue,
          this.userBundle,
          this.decimalPipe,
          this.appHeaderService.enabledPCM ? this.userBundle['workspace.rating.bric.volume.impressions-pcm'] : this.userBundle['workspace.rating.bric.volume.impressions'],
          impressions
        );
      case this.brickBaseService.brickID.ProductCatalogue:
        return this.displayTextGenerator.getDisplayTextForProductCatalogue(selectedValue);
      // case this.brickBaseService.brickID.Channel:
      //   return this.displayTextGenerator.getDisplayTextForChannelNewBrick(selectedValue, this.userBundle['worksapce.brisc.label.multipleSelection'],);
      case this.brickBaseService.brickID.AllAudience:
        return this.displayTextGenerator.getDisplayTextForAllAudience(selectedValue, this.multipleText, this.userBundle);
      case this.brickBaseService.brickID.PrimaryAudience:
        return this.displayTextGenerator.generateReqJsonAndDisplayTextForPrimaryAudience(selectedValue, this.multipleText, this.userBundle);
      case this.brickBaseService.brickID.Pattern:
        return this.userBundle['workspace.incharge.pattern.heading'];
      case this.brickBaseService.brickID.Objective:
        return selectedValue.hasOwnProperty('-99') || selectedValue.isAllSelection ? this.userBundle['workspace.common.allSelection'] || '' : this.userBundle['workspace.objective.bric.title'];
      case this.brickBaseService.brickID.MultiTarget:
        return this.displayTextGenerator.getDisplayTextForMultiTarget(this.userBundle, this.appHeaderService.enabledPCM, selectedValue);
      default:
        throw new Error(`Display text logic not written for brick id : '${brickID}`);

      // Below are bricks for which display text logic is not written
      // case this.brickBaseService.brickID.Facing:
      // case this.brickBaseService.brickID.FrameIllumination:
      // case this.brickBaseService.brickID.Network:
      // case this.brickBaseService.brickID.ProductCatalogue:
      // case this.brickBaseService.brickID.SecondaryAudience:
    }
  }

  /**
   * @description Method to get request json object for any brick
   * @author Vijay Sutaria
   * @param {number} brickID brick id of cell for who request json is to be prepared
   * @param {*} value selected value object of cell
   * @returns returns Request JSON object
   * @memberof CellAttributeService
   */
  public getBrickRequestJSON(brickID: number | string, value: any) {
    this.setUserBundle();
    const brickText: string = this.brickBaseService.brickReqJsonText[brickID];
    const uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    switch (brickID) {
      case this.brickBaseService.brickID.Audience:
        return this.requestJsonGenerator.prepareAudienceBricReqJson(value, brickText);
      case this.brickBaseService.brickID.Budget:
        return this.requestJsonGenerator.prepareBudgetBricReqJson(value, brickText, this.appHeaderService.enabledPCM);
      case this.brickBaseService.brickID.Channel: {
        const lookupColumnData = this.sharedService.getLookupColumnData(this.brickBaseService.brickID.Channel, null);
        return this.requestJsonGenerator.prepareChannelBricReqJson(value, brickText, lookupColumnData, GLOBAL.localSolverEnabled);
      }
      case this.brickBaseService.brickID.Distribution:
        return this.requestJsonGenerator.prepareDistributionBricReqJson(value, brickText);
      case this.brickBaseService.brickID.Environment: {
        const lookupColumnData = this.sharedService.getLookupColumnData(this.brickBaseService.brickID.Environment, null);
        const channelSelectionId = lookupColumnData.lookup[0].selectionId;
        return this.requestJsonGenerator.prepareEnvironmentBricReqJson(value[channelSelectionId], brickText, lookupColumnData, GLOBAL.localSolverEnabled);
      }
      case this.brickBaseService.brickID.Format: {
        const hiddenElements = uiControl.hiddenElements;
        return this.requestJsonGenerator.prepareFormatBricReqJson(value, brickText, hiddenElements, GLOBAL.localSolverEnabled);
      }
      case this.brickBaseService.brickID.Location:
        return this.requestJsonGenerator.prepareLocationBricReqJson(value, brickText, GLOBAL.localSolverEnabled);
      case this.brickBaseService.brickID.Frame:
        return this.requestJsonGenerator.prepareFrameBricReqJsonForWS(value, brickText, uiControl);
      case this.brickBaseService.brickID.FrameQuality:
        return this.requestJsonGenerator.prepareFrameQualityBricReqJson(value, brickText);
      case this.brickBaseService.brickID.Incharge:
        return this.requestJsonGenerator.prepareInchargeBricReqJson(value, brickText, this.datePipe, this.appHeaderService.enabledPCM);
      case this.brickBaseService.brickID.List: {
        return this.requestJsonGenerator.prepareListBricReqJson(value, brickText, uiControl.hardAllocationEnabled, uiControl.swapEnabled);
      }
      case this.brickBaseService.brickID.Media:
        return this.requestJsonGenerator.prepareMediaBricReqJson(value, brickText);
      case this.brickBaseService.brickID.Pricing:
        return this.requestJsonGenerator.preparePricingBricReqJson(value, brickText);
      case this.brickBaseService.brickID.PricingTag: {
        const lookupColumnData = this.sharedService.getLookupColumnData(this.brickBaseService.brickID.PricingTag, null);
        return this.requestJsonGenerator.preparePricingTagBricReqJson(value, brickText, lookupColumnData);
      }
      case this.brickBaseService.brickID.ProductCatalogue:
        return this.requestJsonGenerator.prepareProductCatalogueBricReqJson(value, brickText);
      case this.brickBaseService.brickID.Proximity:
        return this.requestJsonGenerator.prepareProximityBricReqJson(value, brickText);
      case this.brickBaseService.brickID.SecondaryAudience:
        return this.requestJsonGenerator.prepareAudienceBricReqJson(value, brickText);
      case this.brickBaseService.brickID.SOT:
        if (this.appHeaderService.enabledPCM) {
          return this.requestJsonGenerator.prepareSotBricReqJsonPCM(value, brickText);
        } else {
          return this.requestJsonGenerator.prepareSotBricReqJson(value, brickText);
        }
      case this.brickBaseService.brickID.Tag:
        return this.requestJsonGenerator.prepareTagBrickRequestJson(value, brickText);
      case this.brickBaseService.brickID.Volume:
        return this.requestJsonGenerator.prepareVolumeBricReqJson(value, brickText);
      case this.brickBaseService.brickID.Pattern:
        return this.requestJsonGenerator.preparePatternBricReqJson(value, brickText);
      case this.brickBaseService.brickID.FrameIllumination:
        return this.requestJsonGenerator.prepareFrameBricReqJson(value, brickText);
      case this.brickBaseService.brickID.AllAudience:
        return this.requestJsonGenerator.prepareAllAudienceReqJson(value, brickText);
      case this.brickBaseService.brickID.PrimaryAudience:
        return this.requestJsonGenerator.preparePrimaryAudienceReqJson(value, brickText);
      case this.brickBaseService.brickID.Objective:
        return this.requestJsonGenerator.prepareObjectiveReqJson(value, brickText);
      case this.brickBaseService.brickID.MultiTarget:
        return this.requestJsonGenerator.prepareMultiTargetReqJson(value, brickText, this.appHeaderService.enabledPCM);
      default:
        throw new Error(`Request JSON logic not written for brick id : ${brickID}`);

      // Below are bricks for which display text logic is not written
      // case this.brickBaseService.brickID.Facing:
      // case this.brickBaseService.brickID.Network:
    }
  }

  /**
   * @description Set Tooltuip for Bricks
   * @author Kishan Patel
   * @param {(number | string)} brickID
   * @param {*} selectedValue
   * @returns
   * @memberof CellAttributeService
   */
  public getToolTip(brickID: number | string, selectedValue: any, lookups?) {
    this.setUserBundle();
    switch (brickID) {
      case this.brickBaseService.brickID.Environment:
        return this.tooltipTextGenerator.getTooltipForEnvironmentBrick(this.brickBaseService.brickText.Environment, selectedValue);
      case this.brickBaseService.brickID.Tag:
        return this.tooltipTextGenerator.getTooltipForTag(selectedValue, this.userBundle['workspace.collection.bric.selectedTags']);
      case this.brickBaseService.brickID.PricingTag:
        return this.tooltipTextGenerator.getToolTipForPricingTagBrick(selectedValue, this.userBundle);
      case this.brickBaseService.brickID.Format:
      case this.brickBaseService.brickID.Location:
        return this.tooltipTextGenerator.getTooltipForFormatLocationChannelBrick(selectedValue, lookups, this.userBundle);
      case this.brickBaseService.brickID.List:
        return this.tooltipTextGenerator.getToolTipForList(selectedValue, this.userBundle);
      case this.brickBaseService.brickID.ProductCatalogue:
        return this.tooltipTextGenerator.getTooltipForProductCatalogue(selectedValue, this.userBundle);
      case this.brickBaseService.brickID.Channel:
        return this.tooltipTextGenerator.getTooltipForFormatLocationChannelBrick(selectedValue, lookups, this.userBundle);
      case this.brickBaseService.brickID.Proximity:
        return this.tooltipTextGenerator.getTooltipForProximityBrick(selectedValue, this.userBundle);
      case this.brickBaseService.brickID.AllAudience:
        return this.tooltipTextGenerator.getToolTipForAllAudienceBrick(selectedValue, this.userBundle);
      case this.brickBaseService.brickID.Volume:
        return this.tooltipTextGenerator.getToolTipForVolumeBrick(selectedValue, this.userBundle, this.decimalPipe, this.appHeaderService.enabledPCM);
      case this.brickBaseService.brickID.Budget:
        return this.tooltipTextGenerator.getToolTipForBudgetBrick(selectedValue, this.userBundle, this.currencyFormatPipe, this.appHeaderService.enabledPCM);
      case this.brickBaseService.brickID.PrimaryAudience:
        return this.tooltipTextGenerator.getToolTipForPrimaryAudience(selectedValue, this.brickBaseService.brickText.PrimaryAudience);
      case this.brickBaseService.brickID.MultiTarget:
        return this.tooltipTextGenerator.getToolTipForMultiTarget(selectedValue, this.userBundle, this.appHeaderService.enabledPCM);
      default:
        return null;
    }
  }

}
