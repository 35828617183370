import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-clone-button',
  templateUrl: './ag-clone-button.component.html',
  styleUrls: ['./ag-clone-button.component.css']
})
export class AgCloneButtonComponent implements AgRendererComponent {

  hide = false;
  public params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.hide = this.params.data.save && this.params.data.save.toLowerCase() === 'in progress';
  }

  public cloneCampaign(e) {
    e.stopPropagation();
    this.params.context.componentParent.cloneCampaign(this.params.data);
  }

  refresh(): boolean {
    return false;
  }
}
