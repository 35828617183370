import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-commercial-valdiation',
  templateUrl: './commercial-valdiation.component.html',
  styleUrls: ['./commercial-valdiation.component.css']
})
export class CommercialValdiationComponent {
  bodyEle: HTMLElement = document.querySelector('body');
  brickBgColor = '#ef3f3b';
  title = 'Information';
  initialConfig = null;
  @Input() resolveObject: any;

  constructor(
    private activeModal: NgbActiveModal,
  ) {}

  onModalSaved() {
    if (this.resolveObject.discounts && this.resolveObject.discounts.length) {
      this.resolveObject.updateDiscounts = true;
    } else {
      this.resolveObject.callBookingService = true;
    }
    this.activeModal.close(this.resolveObject);
  }

  onModalClosed() {
    this.activeModal.dismiss('dismiss');
  }

}
