import { DayPartGroup, DayPartSpan, Daypart } from './../../models/initial-config.model';
import * as _ from 'lodash';

/**
 * Common Class for Model Popup and Selected value of Load Compaing
 */
export class PatternCommon {
  /**
   * @description format data for daypart and hours dropdown
   * @author Nishit Parekh
   * @param {number} dayPartGroupId
   * @param {DayPartGroup} dayPartGroup
   * @returns data for dropdowns
   * @memberof PatternCommon
   */
  generateDropDownForPattern(dayPartGroupId: number, dayPartGroup: DayPartGroup) {

    const dayPartGroupClone = _.cloneDeep(dayPartGroup);
    const dropDownData = {
      dayPart: [],
      spans: [],
    };

    _.forEach(dayPartGroupClone, (obj: any) => {
      if (dayPartGroupId === obj.dayPartGroupId) {
        _.forEach(obj.dayPartGroupDetail, (mainObj) => {
          if (mainObj.dayPartGroupSpans) {
            _.forEach(mainObj.dayPartGroupSpans, (spanObj) => {
              spanObj.dayPartId = 0;
              dropDownData.spans.push(spanObj);
            });
          }
          delete mainObj.dayPartGroupSpans;
          dropDownData.dayPart.push(mainObj);
        });
      }
    });

    dropDownData.dayPart = _.sortBy(dropDownData.dayPart, o => o.dayPartId);

    return dropDownData;
  }

  /**
   * @description generates display text to be displayed on pattern cell
   * @author Nishit Parekh
   * @param {Daypart[]} values values array for celeted cell in pattern
   * @returns {string} FD, RH, H etc
   * @memberof PatternCommon
   */
  getDisplayText(values: Daypart[]): string {

    let displayText = '';
    const dayPartCount = this.countForDisplayText(values);

    if (dayPartCount.fullDay > 0) {
      displayText = 'Fd';
    } else if (dayPartCount.dayPart > 0 && dayPartCount.Hours === 0) {
      displayText = 'Rg';
    } else if (dayPartCount.Hours > 0 && dayPartCount.dayPart === 0) {
      displayText = 'Hr';
    } else if (dayPartCount.Hours > 0 && dayPartCount.dayPart > 0) {
      displayText = 'RH';
    } else {
      displayText = ' ';
    }
    return displayText;
  }

  countForDisplayText(values) {
    const dayPartCount = {
      fullDay: 0,
      dayPart: 0,
      Hours: 0
    };

    _.forEach(values, (obj) => {
      if (typeof (obj) === 'object' && obj.dayPartId != null) {
        if (obj.dayPartId === -1) {
          dayPartCount.fullDay++;
        } else if (obj.dayPartId > 0) {
          dayPartCount.dayPart++;
        } else if (obj.dayPartId === 0) { // added hours logic for SBRICS-1645, Nishit
          dayPartCount.Hours++;
        }
      } else if (Array.isArray(obj) && obj[0].dayPartId === 0 && obj[1].dayPartId === 0) {
        dayPartCount.Hours++;
      }
    });

    return dayPartCount;
  }

  /**
   * @description generates value array for selected days in pattern
   * @author Nishit Parekh
   * @param {Daypart[]} dayPart
   * @param {DayPartSpan[]} spans
   * @returns array of selected daypart or hours or combination
   * @memberof PatternCommon
   */
  getSelectedValue(dayPart: Daypart[], spans: DayPartSpan[]) {
    const selectedValues = [];
    // Nishit, SBRICS-1665

    const len = dayPart.length;
    for (let i = 0; i < len; i++) {
      if (dayPart[i].dayPartId !== 0) {
        // full day or daypart
        selectedValues[i] = dayPart[i];

      } else if (dayPart[i].dayPartId === 0) {
        const hours = [];
        for (const span of spans) {

          if (dayPart[i].startOffset === span.startOffset) {
            hours[0] = span;
          }

          if (dayPart[i].endOffset === span.endOffset) {
            hours[1] = span;
          }
        }
        selectedValues[i] = hours;
      }
    }
    return selectedValues;
  }

}
