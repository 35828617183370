export class UserDataModel {
  userName: string = null;
  name: string = null;
  fullName: string = null;
  initials: string = null;
  userGroups: string[] = [];
  timeZone: string = null;
  locale: string = null;
  emailAddress: string = null;
  tokens: string[] = [];
  internalAccess: boolean = null;
  externalUser = false;
  externalUserData?: any = {};
  authorizedChannel: number[];
}
