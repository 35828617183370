import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FrameQualityBase } from '../frame-quality-base';
import {
  DataShareService,
  CellAttributeService,
  BrickBaseService
} from '../../../core/services';
import { CellValues } from '../../../models/workspace/index';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';

@Component({
  selector: 'app-framequality-modal',
  templateUrl: './frame-quality-modal.component.html',
  styleUrls: ['./frame-quality-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FramequalityModalComponent extends FrameQualityBase implements OnInit {
  /**
   * It will take data to configure modal window.
   */
  @Input() resolveObject: CellValues;
  readOnlyModal = false;

  constructor(
    dataShareService: DataShareService,
    private cellAttributeService: CellAttributeService,
    private brickBaseService: BrickBaseService,
    appHeaderService: AppHeaderService
  ) {
    super(dataShareService, appHeaderService);
  }

  ngOnInit() {
    const selectedValue = this.resolveObject.selectedValues && !this.resolveObject.selectedValues['-99'] ? this.resolveObject.selectedValues : {};
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.init(selectedValue, this.resolveObject);
    this.previousValues = Object.assign({},this.frameQuality);
  }

  /**
   * callback to close modal popup window
   * @param {*} event - modal event object
   * @memberof FramequalityModalComponent
   */
  onModalClosed(event) {
    if (event.reason === 'escape' && !(JSON.stringify(this.previousValues) == JSON.stringify(this.frameQuality))) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * callback function - called during save click
   * @param {*} event - modal event object
   * @memberof FramequalityModalComponent
   */
  onModalSaved(event) {
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    if (this.pcmMode) {
      values.selectedValues = this.frameQuality;
    } else {
      values.selectedValues = {
        audienceLevel: this.frameQuality.audienceLevel
      };
    }
    values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.FrameQuality, values.selectedValues);
    values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.FrameQuality, values.selectedValues);
    this.previousValues = Object.assign({},this.resolveObject.selectedValues);
    event.activeModal.close(values);
  }

}
