import { Component } from '@angular/core';
import { IFloatingFilter, IFloatingFilterParams, TextFilter } from 'ag-grid-community';
import { AgFrameworkComponent } from 'ag-grid-angular';

export interface TextFloatingFilterParams extends IFloatingFilterParams {
  value: string;
  filterType: string;
}

@Component({
  selector: 'app-ag-date-floating-filter',
  templateUrl: './ag-date-floating-filter.component.html',
  styleUrls: ['./ag-date-floating-filter.component.css']
})
export class AgDateFloatingFilterComponent implements IFloatingFilter, AgFrameworkComponent<TextFloatingFilterParams> {

  private params: TextFloatingFilterParams;
  public currentValue: string;

  timeOut = null;

  agInit(params: TextFloatingFilterParams): void {
    this.params = params;
    this.currentValue = params.currentParentModel().value;
  }
  valueChanged() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
    this.timeOut = setTimeout(() => {
      this.params.parentFilterInstance((instance) => {
        (instance as TextFilter).onFloatingFilterChanged('text', this.buildModel());
      });
    }, 500);
  }

  clearFilterClick() {
    this.currentValue = '';
    this.valueChanged();
  }

  onParentModelChanged(parentModel: any): void {
    if (!parentModel) {
      this.currentValue = '';
    } else {
      // note that the filter could be anything here, but our purposes we're assuming a greater than filter only,
      // so just read off the value and use that
      this.currentValue = parentModel.value;
    }
  }

  buildModel(): any {
    if (this.currentValue === '') {
      return null;
    }
    return {
      value: this.currentValue,
      type: this.params.filterType || 'text'
    };
  }

}
