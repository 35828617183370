<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
    [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
    <div [ngClass]="{'readOnly' : readOnlyModal}">
        <div class="row">
            <div [ngClass]="{'col-md-7': localSolverEnabled}">
                <div class="control_group" *ngIf="!pcmMode">
                    <label for="rbt-impression" [ngStyle]="localSolverEnabled ? {width: '60%'} : {}">{{userBundle['workspace.rating.bric.volume.impressions']}}</label>
                    <input *ngIf="!ratingImpression.relative" type="text" class="form_control" [(ngModel)]="ratingImpression.impressions" [disabled]="resolveObject.productValidations && productValidations?.impressions && !productValidations?.minImpressions && !productValidations?.maxImpressions"
                        [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" (ngModelChange)="calculateFloorAndCeiling()" [ngStyle]="localSolverEnabled ? {width: '40%'} : {}" />
                    <input *ngIf="ratingImpression.relative" type="text" class="form_control" [(ngModel)]="ratingImpression.impressions" [disabled]="resolveObject.productValidations && productValidations?.impressions && !productValidations?.minImpressions && !productValidations?.maxImpressions"
                        [imask]="percentageInputMask" [unmask]="maskService.unmask.typed" (ngModelChange)="calculateFloorAndCeiling()" [ngStyle]="localSolverEnabled ? {width: '40%'} : {}" />
                    <span *ngIf="ratingImpression.relative" class="percentage-illumination" style="display: flex; left: -8%;">
                        <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                        {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
                <div class="control_group" *ngIf="pcmMode">
                    <label for="rbt-impression">{{userBundle['workspace.rating.bric.volume.impressions-pcm']}}</label>
                    <input type="text" class="form_control" [(ngModel)]="ratingImpression.impressions" [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" />
                </div>
            </div>
            <div class="col-md-5 pl-0" *ngIf="localSolverEnabled && objectiveMode">
                <div class="control_group pl-0">
                    <div class="btn-group">
                        <input type="radio" name="radio_groups_pd" [(ngModel)]="ratingImpression.relative" [value]="false" autocomplete="off" id="check_units_pd1" (change)="onChangeRelative(ratingImpression.relative)">
                        <label for="check_units_pd1" class="">
                            {{userBundle['common.amount'] || 'Amount'}}
                        </label>
                        <input type="radio" name="radio_groups_pd" [(ngModel)]="ratingImpression.relative" [value]="true" autocomplete="off" id="check_units_pd2" (change)="onChangeRelative(ratingImpression.relative)">
                        <label for="check_units_pd2" class="">
                            {{userBundle['common.percentage'] || 'Percentage'}}
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="control_group row" *ngIf="pcmMode">
            <label>
                {{userBundle['impression.common.minImpressions']}}
            </label>
            <input type="text" class="form_control txt" [(ngModel)]="ratingImpression.minImpressions" [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" #minImpressions />
        </div>
        <div class="control_group row" *ngIf="pcmMode">
            <label>{{userBundle['impression.common.maxImpressions']}}</label>
            <input type="text" class="form_control txt" [(ngModel)]="ratingImpression.maxImpressions" [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" #maxImpressions />
        </div>

        <div class="row" *ngIf="uiControl.volumeRangeValidationEnabled && !(objectiveMode && !uiControl.objectiveSotFloorCeilingEnabled)">
            <div class="col-md-6">
                <div class="control_group">
                    <label>
                        {{userBundle['workspace.common.floor']}}
                    </label>
                    <input type="text" class="form_control txt" [(ngModel)]="ratingImpression.sotFloor" [disabled]="disableFloorAndCeiling" [imask]="percentageInputMaskForSOT" [unmask]="maskService.unmask.typed" #sotFloor />
                    <span class="percentage-illumination" style="display: flex;">
                        <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                        {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="control_group">
                    <label>{{userBundle['workspace.common.ceiling']}}</label>
                    <input type="text" class="form_control txt" [(ngModel)]="ratingImpression.sotCeiling" [disabled]="disableFloorAndCeiling" [imask]="percentageInputMaskForSOT" [unmask]="maskService.unmask.typed" #sotCeiling />
                    <span class="percentage-illumination" style="display: flex;">
                        <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                        {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="full-width">
                <div class="control_group flexible">
                    <label>{{userBundle['workspace.rating.bric.volume.impressionsAllocated.approx']}}</label>
                    <input *ngIf="!ratingImpression.relative" type="text" class="form_control txt disable-network" [(ngModel)]="ratingImpression.allocated" [disabled]="true" [imask]="numberInputMask" [unmask]="maskService.unmask.typed" readonly />
                    <input *ngIf="ratingImpression.relative" type="text" class="form_control txt disable-network" [(ngModel)]="ratingImpression.allocated" [disabled]="true" [imask]="percentageInputMask" [unmask]="maskService.unmask.typed" readonly />
                    <span *ngIf="ratingImpression.relative" class="percentage-illumination" style="display: flex; left: -8%;">
                      <em class="fa fa-percent" aria-hidden="true"></em>
                  </span>
                </div>
            </div>
            <div class="col-md-7" *ngIf="uiControl.toleranceEnabled">
                <div class="control_group">
                    <label>{{userBundle['workspace.common.tolerance']}}</label>
                    <input type="text" class="form_control txt" [(ngModel)]="ratingImpression.tolerance" [imask]="percentageToleranceInputMask" [unmask]="maskService.unmask.typed" />
                    <span class="percentage-illumination" style="display: flex; left: -8%;">
                        <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                        {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="localSolverEnabled">
            <div class="col-md-6">
                <div class="control_group">
                    <label style="width: 60%">{{userBundle['workspace.bric.greenTolerance']}}</label>
                    <input type="text" class="form_control txt" [(ngModel)]="ratingImpression.greenTolerance" appNumberMask [min]="0" [max]="100" [scale]="2" style="width: 40%" (blur)="setDefaultGreenTolerance()" />
                    <span class="percentage-illumination" style="display: flex; left: -8%;">
                        <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                        {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="control_group">
                    <label style="width: 60%">{{userBundle['workspace.bric.orangeTolerance']}}</label>
                    <input type="text" class="form_control txt" [(ngModel)]="ratingImpression.orangeTolerance" appNumberMask [min]="0" [max]="100" [scale]="2" style="width: 40%" (blur)="setDefaultOrangeTolerance()" />
                    <span class="percentage-illumination" style="display: flex; left: -8%;">
                        <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                        {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
            </div>
            <div class="col-md-6" *ngIf="!uiControl.hideObjectivePriority">
                <div class="control_group">
                    <label>{{userBundle['workspace.bric.priority']}}</label>
                    <input type="text" class="form_control txt" [(ngModel)]="ratingImpression.priority" appNumberMask [min]="0" [max]="999" [scale]="0" />
                </div>
            </div>
            <div class="col-md-6" *ngIf="!uiControl.hideObjectiveWeight">
                <div class="control_group">
                    <label>{{userBundle['workspace.bric.weight']}}</label>
                    <input type="text" class="form_control txt" [(ngModel)]="ratingImpression.weight" appNumberMask [min]="0" [max]="100" [scale]="2" />
                    <span class="percentage-illumination" style="display: flex; left: -8%;">
                        <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                        {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
            </div>
            <div class="control_group">
                <label style="width: 50%">{{userBundle['workspace.bric.boostedTarget']}}
                </label>
                <label class="switchAllowFrames">
                    <input type="checkbox" [(ngModel)]="ratingImpression.boostedTarget">
                    <span class="sliderAllowFrames round"></span>
                </label>
            </div>
        </div>

        <div class="row" *ngIf="!(objectiveMode && !uiControl.objectiveAllocateAllFramesEnabled) && !isVioohAllocationEngineSelected">
            <div class="control_group">
                <label style="width: 50%">
                    {{userBundle['workspace.common.allocateAllFrames'] ? userBundle['workspace.common.allocateAllFrames'] :
                    'Use All Available Frames' }}
                </label>
                <label class="switchAllowFrames">
                    <input type="checkbox" [disabled]="!user.workspaceTabAccess.allocateAllFrames || (productValidations && productValidations.allocateAllFrames)"
                        [(ngModel)]="ratingImpression.allocateAllFrames" (click)="updateAllocation($event)">
                    <span class="sliderAllowFrames round"></span>
                </label>
            </div>
        </div>

        <div class="control_group row" *ngIf="pcmMode">
            <label>
                {{userBundle['workspace.pcm.increment']}}
            </label>
            <input type="text" class="form_control txt" [disabled]="resolveObject.sot.disableSOTIncrement" [(ngModel)]="ratingImpression.sotIncrement" [imask]="percentageInputMask" [unmask]="maskService.unmask.typed" />
            <span class="percentage-illumination" style="display: flex;">
                <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                {{!localSolverEnabled ? '%' : ''}}
            </span>
        </div>

    </div>
    <div footer class="right pcm-validation-info"
        *ngIf="productValidations" appMasterTooltip
        [tooltipName]="'mastertooltip'" [alwaysDisplay]="false">
        <img height="20px" width="20px" src="assets/img/info-image.png" alt="info-image" />
        <div class="mastertooltip modaltooltip">
            <div class="triangle-up"></div>
            <h5>{{userBundle['workspace.common.validValues']}}</h5>
            <br>
            <div *ngIf="productValidations?.impressions">
                {{userBundle['workspace.rating.bric.volume.impressions-pcm']}} : {{productValidations?.impressions}}
            </div>
            <div *ngIf="productValidations?.minImpressions">
                {{userBundle['impression.common.minImpressions']}} : {{productValidations?.minImpressions}}
            </div>
            <div *ngIf="productValidations?.maxImpressions">
                {{userBundle['impression.common.maxImpressions']}} : {{productValidations?.maxImpressions}}
            </div>
            <div *ngIf="productValidations?.sotFloor">
                {{userBundle['workspace.common.minFloor']}} : {{productValidations?.sotFloor}}
            </div>
            <div *ngIf="productValidations?.sotCeiling">
                {{userBundle['workspace.common.maxCeiling']}} : {{productValidations?.sotCeiling}}
            </div>
            <div *ngIf="productValidations?.sotIncrement">
                {{userBundle['workspace.pcm.increment']}} : {{productValidations?.sotIncrement}}
            </div>
        </div>
    </div>
</app-sb-modal-wrapper>
