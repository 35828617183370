import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { LocaleData } from '../utils/LocaleData';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any, dateFormat?: any): any {
    if (!dateFormat) {
      dateFormat = 'dd/MM/yyyy';
    }
    if (value !== '' && value !== undefined) {
      if (dateFormat.toString().includes('dddd')) {
        dateFormat = dateFormat.toUpperCase().replaceAll('DDDD', 'dddd');
        return moment(value).locale(LocaleData.locale).format(dateFormat);
      }
      return moment(value).format(dateFormat.toUpperCase());
    }

  }

}
