import { Component, OnInit, Input } from '@angular/core';
import { DataShareService, CellAttributeService, BrickBaseService } from '../../../core/services/index';
import { CellValues } from '../../../models/workspace';
import { DistributionBase } from '../../../bricks/distribution/distribution-base';

@Component({
  selector: 'app-distribution',
  templateUrl: './distribution-modal.component.html',
  styleUrls: ['./distribution-modal.component.css'],
})

export class DistributionModalComponent extends DistributionBase implements OnInit {

  /**
   * resolve variable pass by main component
   *
   * @type {Brick}
   * @memberof DistributionModalComponent
   */
  @Input() resolveObject: CellValues;
  readOnlyModal = false;

  constructor(
    dataShareService: DataShareService,
    private cellAttributeService: CellAttributeService,
    private brickBaseService: BrickBaseService
  ) {
    super(dataShareService);
  }

  ngOnInit(): void {
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.init(this.resolveObject.selectedValues);
    this.previousValues = Object.assign({},this.distribution);
  }

  onModalClosed(event) {
    if (event.reason === 'escape' && !(JSON.stringify(this.previousValues) == JSON.stringify(this.distribution))) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  onModalSaved(event) {
    this.setDistributionName();
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    values.selectedValues = this.distribution;
    values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Distribution, values.selectedValues);
    values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Distribution, values.selectedValues);
    this.previousValues = Object.assign({},this.resolveObject.selectedValues);
    event.activeModal.close(values);
  }

}
