<app-filter-layout [resolveObject]="resolveObject" (closeClick)="closeClick()" (saveClick)="saveClick()">
    <div class="form-group-wrapper_geo">
        <div class="form-group_geo horizontal_geo">
            <div class="scrolldiv">
                <label *ngFor="let item of facings; trackBy: trackById;" class="small">
                    <input type="checkbox" appChecklist class="checkbox small" [(checklist)]="facing" [checklistValue]="item">
                    <span>{{item.name}}</span>
                </label>
            </div>
        </div>
    </div>
</app-filter-layout>
