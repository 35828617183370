import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataShareService, StateService } from '../core/services';
import { SystemFlags, UserModel, SystemData, UserDataModel } from '../models';
import { SearchService } from './search.service';
import { Subscription } from 'rxjs';
import { AppNameEnum } from '../core/enum';
import { GLOBAL } from '../core/utils/app.constant';
import { CampaignDetailsComponent } from './campaign-details';
import { AppHeaderService } from '../../../../root/app-header/app-header.service';
import { environment } from '../../../../../environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('campaignDetails') campaignDetails !: CampaignDetailsComponent;
  loadSearchView = true;
  public userModel: UserModel;
  public userBundle: object = {};
  accordionCampaignOpened = true;
  private subscriptions: Subscription[] = [];
  public userData: UserDataModel;
  public systemData: SystemData;
  public calledFromGoToWorkspacePage = false;
  frontEndVersion = '';
  isInitialConfigExist: boolean;
  public isLoadedCampaign = false;
  selectedEnvironment = '';
  isMarketChangeble = true;

  constructor(
    private dataShareService: DataShareService,
    public searchService: SearchService,
    public stateService: StateService,
    private router: Router,
    private appHeaderService: AppHeaderService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.subscriptions.push(this.stateService.serchAccordian.subscribe(() => {
      this.accordionCampaignOpened = true;
    }));
    this.subscriptions.push(this.dataShareService.languageChangedSub.subscribe((result) => {
      if (result) {
        this.changeDetectorRef.detectChanges();
      }
    }));
  }

  ngOnInit() {
    this.dataShareService.appName = AppNameEnum.search;
    // First condition is used for global serch
    if (this.dataShareService.getInitialConfig() || this.stateService.isDataAvailable) {
      this.getInitialConfig();
    }
    this.subscriptions.push(this.dataShareService.initialConfigSub.subscribe((config) => {
      if (config) {
        this.getInitialConfig();
      }
    }));
    this.subscriptions.push(this.dataShareService.loadedCampaignSub.subscribe((isLoadedCampaign: boolean) => {
      this.isLoadedCampaign = isLoadedCampaign;
    }));
    this.isLoadedCampaign = SystemFlags.isLoadedCampaign;
    this.subscriptions.push(this.appHeaderService.selectedEnvironment$.subscribe((newEnv) => {
      this.selectedEnvironment = newEnv;
    }));
    this.subscriptions.push(this.appHeaderService.environmentList$.subscribe((envList) => {
      if (envList && envList.length > 1) {
        this.isMarketChangeble = true;
      } else {
        this.isMarketChangeble = false;
      }
    }));
  }

  getInitialConfig() {
    const uiControl = this.dataShareService.getInitialConfig().uiControl;
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.userData = { ...this.dataShareService.getInitialConfigByKey('userData'), ...GLOBAL.USER };
    this.systemData = this.dataShareService.getInitialConfigByKey('systemData');
    this.userModel = this.dataShareService.getUserModel();
    this.frontEndVersion = GLOBAL.frontEndVersion;
    this.isInitialConfigExist = true;
    this.accordionCampaignOpened = _.isUndefined(uiControl.searchDefaultExpandSmartSearch) ? this.accordionCampaignOpened : !uiControl.searchDefaultExpandSmartSearch;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((element) => {
      element.unsubscribe();
    });
  }

  /**
   * To open timeout dialog
   * @memberof DashboardComponent
   */
  openTimeoutModal() {
    alert(this.userBundle['alert.timeout']);
    window.location.reload();
  }

  goToWorkspace(pageName: string) {
    this.calledFromGoToWorkspacePage = true;
    if (!pageName) {
      this.router.navigate(['/workspace']);
    } else {
      this.router.navigate([pageName]);
    }
  }

  changeEnv() {
    if (this.isMarketChangeble) {
      window.location.replace(environment.landingAppUrl);
    }
  }

}
