declare let MapLabel;
export class MapLabelCollection {
  /**
   * added Map Label collection
   * @type {*}@memberof MapLabelCollection
   */
  collection: any = {};

  /**
   * Add New Map Label
   * @param text - Text to display
   * @param position - position of label on map
   * @param map - map object
   * @param id - id to bind with shape
   */
  add(text: string, position: any, map: any, id: string): void {
    const paneName = 'markerLayer';
    const mapLabel = new MapLabel({
      text,
      position,
      map,
      paneName,
      fontSize: 'bold 14',
      strokeWeight: 2,
      fontFamily: 'sans-serif',
    });
    this.collection[id] = mapLabel;
  }

  /**
   * Check whether Map label exists for given id
   * @param {string} id - shape id for which to search label
   * @returns {boolean}
   * @memberof MapLabelCollection
   */
  exists(id: string): boolean {
    return this.collection[id];
  }

  /**
   * Delete Map Label
   * @param id - id to bind with shape
   */
  delete(id: string): void {
    this.collection[id].setMap(null);
    delete this.collection[id];
  }

  /**
   * get Display text of Map Label for given shape id
   * @param {string} id - shape id for which label text is needed
   * @returns {string} - Map Label text
   * @memberof MapLabelCollection
   */
  getText(id: string): string {
    return this.collection[id].get('text');
  }

  /**
   * Set Text of Map Label for given shape id
   * @param {string} id - Shape ID
   * @param {string} text - text to set
   * @memberof MapLabelCollection
   */
  setText(id: string, text: string): void {
    this.collection[id].set('text', text);
  }

  setPosition(id: string, position: any): void {
    this.collection[id].set('position', position);
  }
}
