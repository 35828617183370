import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CommercialService } from '../../../core/services/commercial.service';
import { CampaignDetails, CommercialConfig, HistoryROC } from '../../commercial.model';
import { DataShareService } from '../../../core/services/data-share.service';
import { LookupViewService } from '../../../core/components/lookup-view/lookup-view.service';
import { MaskService, StateService } from '../../../core/services';
import { CampaignDetailsService } from '../campaign-details.service';
import { CampaignDetailsBase } from '../campaign-details-base';
import { SystemFlags } from '../../../models/system-flags';
import { Campaign, SbSelectedBrand } from '../../../models';
import { NumberMaskModel } from '../../../models/imask';
import { CampaignGroupTypeList } from '../../../core/enum';

@Component({
  selector: 'app-campaign-details-at',
  templateUrl: './campaign-details-at.component.html',
  styleUrls: ['./campaign-details-at.component.css']
})
export class CampaignDetailsAtComponent extends CampaignDetailsBase implements OnInit {
  @Input() campaignDetails: CampaignDetails;
  @Input() commercialConfig: CommercialConfig;
  @Input() historyROC: HistoryROC;
  @Output() manuallyCPTUpdated: EventEmitter<any> = new EventEmitter();

  maskService: MaskService = new MaskService();
  searchPageObject: Campaign;
  campaignGroupTypeList = CampaignGroupTypeList;

  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof CampaignDetailsAtComponent
   */
  currencyInputMask: NumberMaskModel;

  bookingLineDataSubscription: Subscription;

  /**
 * @description set readOnly
 * @memberof CampaignDetailsAtComponent
 */
  public readOnly = SystemFlags.readOnly;

  public customerId = null;

  constructor(
    commercialService: CommercialService,
    dataShareService: DataShareService,
    lookupViewService: LookupViewService,
    campaignDetailsService: CampaignDetailsService,
    stateService: StateService
  ) {
    super(
      dataShareService,
      campaignDetailsService,
      lookupViewService,
      commercialService,
      stateService

    );
    this.bookingLineDataSubscription = this.commercialService.campaignDetailData$.subscribe((value) => {
      if (value['rowIndex'] >= 0 && value['columnName']) {
        this.calculateCPT(value);
      }
      this.onBookingLineChange();
    });
  }

  ngOnDestroy() {
    this.bookingLineDataSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.currencyInputMask = this.maskService.currencyInputMask({ max: 999999999999999999999, padFractionalZeros: true });
    super.init(this.campaignDetails);
    this.stateService.setCommercialObject('commercialDetails', this.campaignDetails);
    this.enableSubAgency();
    this.setAgencyFromConfig(this.campaignDetails);
    this.setSpecialistFromConfig(this.campaignDetails);
    this.searchPageObject = this.stateService.getCampaign();
    if (this.campaignDetails.calculateAtUI) {
      this.manageGrossMediaValueWithoutIndex();
    }
    this.setLookupConfig();
    this.setCustomerId();
  }

  onAdvertiserNameSelected(value): void {
    super.onAdvertiserNameSelected(value);
    this.setCustomerId();
  }

  onBrandSelected(value: SbSelectedBrand): void {
    super.onBrandSelected(value);
    this.setCustomerId();
  }

  // Functions Related to Specialist//
  onSpecialistNameSelected(value: any): void {
    if (this.campaignDetails.specialist) {
      super.onSpecialistChange(value);
      this.manageGrossMediaValueWithoutIndex(); // This function has to be used in case if the booking grid is used;
      this.commercialService.notifyGrid(this.campaignDetails); // This is used when booking Details is used
    }
  }

  removeSelectedAdvertiserName(event) {
    super.removeSelectedAdvertiserName(event);
    this.setCustomerId();
  }

  removeSelectedBrand(event: any) {
    super.removeSelectedBrand(event);
    this.setCustomerId();
  }

  removeSelectedSpecialistName() {
    super.onSpecialistChange();
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  onSpecialistPercentageChange() {
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  // Functions related to Agencies//
  onAgencyNameSelected(value: any): void {
    if (this.campaignDetails.agency) {
      super.onAgencyChange(value);
      this.enableSubAgency();
      this.commercialService.notifyGrid(this.campaignDetails);
    }
  }
  removeSelectedAgencyName(): void {
    super.onAgencyChange();
    this.enableSubAgency();
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  onAgencyPercentageChange() {
    this.commercialService.notifyGrid(this.campaignDetails);
  }

  onBookingLineChange() {
    this.manageGrossMediaValueWithoutIndex();
  }

  assignedToChange() {
    this.campaignDetailsService.populateAgencyPercentage(this.campaignDetails);
    this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    this.manageGrossMediaValueWithoutIndex();
    this.enableSubAgency();
    this.setActiveAssignedTo();
  }

  setCustomerId() {
    if (this.campaignDetails && this.campaignDetails.advertiser && this.campaignDetails.advertiser.organisationTypeId === 1) {
      this.customerId = this.campaignDetails.advertiser.advertiserCode;
    } else {
      this.customerId = null;
    }
  }

  onAverageCPTChanged() {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, this.campaignDetails.campaignCPT);
    this.onBookingLineChange();
    if (this.uiControl.enableUserDefinedCPMPricingCalculation) {
      this.manuallyCPTUpdated.emit();
    }
  }

  /**
   * @description calculate CPT from gross media value or pmp discount change
   * @author Amit Mahida
   * @param {{ rowIndex?: number, columnName?: string }} value
   * @memberof CampaignDetailsAtComponent
   */
  calculateCPT(value: { rowIndex?: number, columnName?: string }) {
    if (value.columnName === 'grossMediaValue') {
      this.campaignDetailsService.calculateCPT(
        this.campaignDetails, value.rowIndex,
        undefined,
        this.campaignDetails.bookingList[value.rowIndex].grossMediaValue
      );
    } else if (value.columnName === 'pmpdiscount') {
      this.campaignDetailsService.calculateCPT(this.campaignDetails, value.rowIndex);
    }
  }
}
