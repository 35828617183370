import { Component, OnInit, Input } from '@angular/core';
import { DataShareService } from '../../../app/core/services/data-share.service';
import { GLOBAL } from '../../core/utils/app.constant';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})

export class ConfirmationModalComponent implements OnInit {

  @Input() resolveObject: any;

  campaignSave = {
    campaignName: ''
  };
  brickBgColor: string;
  userBundle: object;
  selectedTitle = '';
  confirmation = false;
  needFooter = false;
  saveButton = '';
  cancelButton = '';

  constructor(private dataShareService: DataShareService,
    private ngbActiveModal: NgbActiveModal) { }

  ngOnInit() {
    this.brickBgColor = this.resolveObject.color || GLOBAL.DEFAULT_TITLE_BACKGROUND_COLOR;
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.campaignSave = this.resolveObject.SelectedValue;
    if (this.resolveObject.SelectedValue && this.resolveObject.SelectedValue.campaignName) {
      this.campaignSave = this.resolveObject.SelectedValue;
      // this.message = this.resolveObject.SelectedValue.campaignName;
    }
    // else {
    //   this.campaignSave['campaignName']  = this.resolveObject.SelectedValue;
    //   // this.message = this.resolveObject.SelectedValue;
    // }
    this.selectedTitle = this.resolveObject.SelectedTitle;
    this.confirmation = this.resolveObject.confirmation;
    if ('needFooter' in this.resolveObject) {
      this.needFooter = this.resolveObject.needFooter;
    }
    if ('saveButton' in this.resolveObject) {
      this.saveButton = this.resolveObject.saveButton ? this.resolveObject.saveButton : this.userBundle['common.ok'];
    }
    if ('cancelButton' in this.resolveObject) {
      this.cancelButton = this.resolveObject.cancelButton ? this.resolveObject.cancelButton : this.userBundle['common.ok'];
    }
  }

  onModalSaved(event) {
    event.activeModal.close(this.campaignSave);
  }

  onModalClosed(event) {
    event.activeModal.dismiss('dismiss');
  }

  onModalCancel() {
    let activeModal: any;
    activeModal = this.ngbActiveModal;
    activeModal.dismiss('dismiss');
  }

}
