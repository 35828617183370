export class SwapCampaign {
  campaignReference: string;
  startDate: string;
  endDate: string;
  xStart: string;
  xEnd: string;
  sot?: number;
  addedFromGroupId?: string; // If added from a digital swap group the it holds the group id else undefined
  selected?: boolean;
}
export class SwapFrame {
  frameId: number;
  frameCode: string;
  visualUnitId: number;
  productFormatId: number;
  y: string;
  isDigital: boolean;
  newY?: string;
  newFrame?: Frame[];
  availableSOT?: number;
  targetSOT?: number;
  newVisualUnitId?: number;
  campaignReference?: string[];
}

export class SwapBookingDetails {
  groupId: string;
  bookingDetails: SwapCampaign[];
  allowed = true;
  selected?: boolean;
}
export class SwapData {
  id: number;
  campaignReferences: SwapCampaign[]; // Used for final swap
  bookingDetailGroups?: SwapBookingDetails[]; // Used for intermediate selection for digital swap
  bookingDetailsColumnWise?: { [key: string]: SwapCampaign[] }; // Used to hold column wise booking details
  frames: SwapFrame[];
  xStart: string;
  xEnd: string;
  selected?: boolean;
  onlyToFrameIndex?: number;
  selectedSOT?: number;
}

export class Frame {
  bookings: Booking[];
  frameCode: string;
  frameId: number;
  visualUnitId: number;
  productFormatId: number;
  y: string;
  isDigital: boolean;
}

export class Booking {
  campaignReference: string;
  startDate: string;
  endDate: string;
  sot?: number;
}

export interface SwapRequestParams {
  [key: string]: {
    frameId: number,
    startDate: string,
    endDate: string,
    newFrameId?: number
  }[];
}

export class FrameGrouping extends Frame {
  display: string;
  isParent: boolean;
}

export class VUGrouping {
  key: string;
  list: FrameGrouping[];
  display: string;
}
