import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CommercialService } from '../../core/services/commercial.service';
import { CampaignStatus, EnvironmentId } from '../../core/enum';
import { DataShareService, LogHelperService, StateService, SharedService } from '../../core/services';
import { SbModalPopupService } from '../../core/components/sb-modal-popup/sb-modal-popup.service';
import { CommercialButtonService } from './commercial-buttons.service';
import { CommercialConfig, CampaignDetails } from '../commercial.model';
import { PerformanceProposalComponent } from '../../result/performance-proposal/performance-proposal.component';
import { ConfirmationModalComponent } from '../../result/confirmation-modal/confirmation-modal.component';
import { CommercialButtonVisibility } from './commercial-button-visibility-model';
import * as moment from 'moment';
import * as _ from 'lodash';
import { SystemFlags, UserModel, SaveCampaignResolvedObject, InitialConfigModel } from '../../models';
import { GoogleAnalyticsEvents, GoogleAnalyticsEventCategory } from '../../GoogleAnalytics/GoogleAnalyticsEvent';
import { SaveCampaignComponent } from '../savecampaign/savecampaign.component';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { SearchService } from '../../search';
import { GLOBAL } from '../../core/utils/app.constant';
import { CampaignSaveDetails } from '../../models/CampaignSaveDetails';
import { AuditType } from '../../models/initial-config.model';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-commercial-buttons',
  templateUrl: './commercial-buttons.component.html'
})

export class CommercialButtonsComponent implements OnInit, OnDestroy {
  /**
   * @description Campaign details
   * @memberof CommercialButtonsComponent
   */
  @Input() campaignDetails;
  /**
   * @description Function to create request json
   * @memberof CommercialButtonsComponent
   */
  @Input() populateRequestJson;
  /**
   * @description function to validate
   * @memberof CommercialButtonsComponent
   */
  @Input() validateFn;

  /**
   * @description bookingList to identify weather the campaign has multiple booking statuses
   * @memberof CommercialButtonsComponent
   */
  @Input() bookingList = [];

  /**
   * @description this flag is added for SM-4408, where book and option buttons must be disabled if flag is true.
   * This is for US only for now.
   */
  @Input() paperBookingNotAllowed = false;

  /**
   * @description output event to book campaign
   * @memberof CommercialButtonsComponent
   */
  @Output() bookCampaign = new EventEmitter();
  @Output() updateCampaign = new EventEmitter();
  campaignSaveDetails: CampaignSaveDetails = {
    campaignName: '',
    hideFinance: true,
  };

  /**
   * @description config
   * @type {CommercialConfig}
   * @memberof CommercialButtonsComponent
   */
  @Input() commercialConfig: CommercialConfig;

  /**
   * @description userBundle
   * @memberof CommercialButtonsComponent
   */
  userBundle: object;

  auditType: AuditType[];
  /**
   * @description disable booked button if status is confirm
   * @type {boolean}
   * @memberof CommercialButtonsComponent
   */
  public disableBookedConfirmedCampaign = false;
  /**
   * @description booking status is cancel
   * @type {boolean}
   * @memberof CommercialButtonsComponent
   */
  public bookingStatusCancelled = false;
  /**
   * @description show hide performance
   * @type {boolean}
   * @memberof CommercialButtonsComponent
   */
  public showHidePerformance = false;
  /**
   * @description usermodel
   * @type {*}
   * @memberof CommercialButtonsComponent
   */
  public userModel: UserModel;
  /**
   * @description Environment id come form backend
   * @type {number}
   * @memberof CommercialButtonsComponent
   */
  environmentId: number;
  environmentnum = EnvironmentId;
  /**
   * @description commercial button show/hide
   * @type {CommercialButtonVisibility}
   * @memberof CommercialButtonsComponent
   */
  public commercialButtonVisible: CommercialButtonVisibility = new CommercialButtonVisibility();

  readOnly = SystemFlags.readOnly;

  isLoadedCampaign = SystemFlags.isLoadedCampaign;

  readonly DATE_FORMAT = 'MM-DD-YYYY';
  readonly CONFIRMATION_LABEL = 'result.confirmation.label';
  readonly CONNECTING = 'common.error.connecting';
  readonly PROPOSAL_LABEL = 'common.proposal';

  config: InitialConfigModel;

  subscriptions: Subscription[] = [];
  constructor(
    private commercialService: CommercialService,
    private dataShareService: DataShareService,
    private logHelperService: LogHelperService,
    private sbModalPopupService: SbModalPopupService,
    private stateService: StateService,
    private commercialButtonService: CommercialButtonService,
    private sharedService: SharedService,
    private searchService: SearchService,
    private appHeaderService: AppHeaderService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.subscriptions.push(this.dataShareService.languageChangedSub.subscribe((result) => {
      if (result) {
        this.ngOnInit();
        this.changeDetectorRef.detectChanges();
      }
    }));
    this.config = this.dataShareService.getInitialConfig();
  }

  ngOnInit() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.userModel = this.dataShareService.getUserModel();
    this.environmentId = this.dataShareService.getInitialConfigByKey('systemData').environmentId;
    this.auditType = this.dataShareService.getInitialConfigByKey('auditType');
    this.checkForInvoiceLock();
    this.handleShowHideButtons();
  }

  handleShowHideButtons() {
    const campaignSearch = this.stateService.getCampaign();
    if (campaignSearch) {
      /* SD : SM-3939 BE - Request Booking available when campaign status = Option 1 */
      if (this.environmentId === EnvironmentId.Belgium) {
        if (campaignSearch.bookingStatusId === CampaignStatus.confirm) {
          this.disableBookedConfirmedCampaign = true;
        }
      } else {
        if (campaignSearch.bookingStatusId === CampaignStatus.confirm
          || campaignSearch.bookingStatusId === CampaignStatus.option) {
          this.disableBookedConfirmedCampaign = true;
        }
      }

    }
    this.showPerformance(campaignSearch);

    this.cancelButtonShowHide();
    this.updateButtonShowHide();
    this.optionButtonShowHide();
    this.bookButtonShowHide();
    this.requestBookingButtonShowHide();
    this.requestOptionButtonShowHide();
    this.proposalButtonShowHide();
    this.performanceButtonShowHide();
    this.revertOptionToProposalShowHide();
    this.saveButtonShowHide();
    this.confirmProposalButtonShowHide();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  confirmProposalButtonShowHide() {
    // Currrently this button is only in Shanghai //
    if (this.environmentId === EnvironmentId.CN && (this.userModel.resultTabAccess.createProposal
      && this.commercialConfig.uiControl && this.commercialConfig.uiControl.showProposalButton
      && !this.showHidePerformance
      && !this.bookingStatusCancelled
    )) {
      this.commercialButtonVisible.showConfirmProposalButton = true;
    }
    if (this.environmentId === EnvironmentId.CNGA
      || this.environmentId === EnvironmentId.CNSHA || this.environmentId === EnvironmentId.CN) {
      this.commercialButtonVisible.showConfirmProposalButton = false;
    }
  }
  /**
   * @description Based on booking status id show/hide performance and cancel button
   * @author Darshan Vachhani
   * @param {*} campaignSearch
   * @memberof CommercialButtonsComponent
   */
  showPerformance(campaignSearch) {
    // VJ SONI : 16Nov2015 : SBRICS-794
    this.bookingStatusCancelled = false;
    if (!_.isUndefined(campaignSearch.bookingStatusId) && !SystemFlags.isPPCampaign) {
      if (campaignSearch.bookingStatusId === CampaignStatus.confirm) {
        this.showHidePerformance = true;
      } else if (campaignSearch.bookingStatusId === CampaignStatus.option) {
        this.showHidePerformance = false;
      } else if (campaignSearch.bookingStatusId === CampaignStatus.cancel) {
        this.bookingStatusCancelled = true;
      }
    } else {
      this.showHidePerformance = false;
    }
  }

  /**
   * @description for different Country cancel button show/Hide
   * @author Darshan Vachhani
   * @memberof CommercialButtonsComponent
   */
  cancelButtonShowHide() {
    let cancelButtonDisabled = false;
    if (new Date(this.campaignDetails.campaignStartDate) < new Date()) {
      cancelButtonDisabled = true;
    }
    switch (this.environmentId) {
      case EnvironmentId.UK:
      case EnvironmentId.ES:
        {
          if (!this.campaignDetails.campaignStatusId
            || this.campaignDetails.campaignStatusId === CampaignStatus.cancel
            || cancelButtonDisabled) {
            this.commercialButtonVisible.disabledCancelButton = true;
          }
          if (this.userModel.commercialTabAccess.cancelBooking && !SystemFlags.isPPCampaign) {
            this.commercialButtonVisible.showCancelButton = true;
          }
          break;
        }
      case EnvironmentId.US:
        {
          if (!this.campaignDetails.campaignStatusId
            || this.campaignDetails.campaignStatusId === CampaignStatus.cancel
            || (this.campaignDetails.campaignStatusId !== CampaignStatus.proposal && cancelButtonDisabled)
            || this.paperBookingNotAllowed // SM-4408
            || !this.userModel.commercialTabAccess.cancelBooking) {
            this.commercialButtonVisible.disabledCancelButton = true;
          }
          if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.showCancelButton && !SystemFlags.isPPCampaign) {
            this.commercialButtonVisible.showCancelButton = true;
          }
          break;
        }
      case EnvironmentId.US: {
        if (!this.campaignDetails.campaignStatusId
          || this.campaignDetails.campaignStatusId === CampaignStatus.cancel
          || (this.campaignDetails.campaignStatusId !== CampaignStatus.proposal && cancelButtonDisabled)
          || this.paperBookingNotAllowed // SM-4408
          || !this.userModel.commercialTabAccess.cancelBooking) {
          this.commercialButtonVisible.disabledCancelButton = true;
        }
        if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.showCancelButton && !SystemFlags.isPPCampaign) {
          this.commercialButtonVisible.showCancelButton = true;
        }
        break;
      }
      default:
        this.defaultShowHideCancelButton(cancelButtonDisabled);
        break;
    }
  }

  defaultShowHideCancelButton(cancelButtonDisabled) {
    if (!this.campaignDetails.campaignStatusId
      || this.campaignDetails.campaignStatusId === CampaignStatus.cancel
      || cancelButtonDisabled
      || !this.userModel.commercialTabAccess.cancelBooking) {
      this.commercialButtonVisible.disabledCancelButton = true;
    }
    if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.showCancelButton) {
      this.commercialButtonVisible.showCancelButton = true;
    }
  }

  /**
   * @description for different Country Update button show/Hide
   * @author Darshan Vachhani
   * @memberof CommercialButtonsComponent
   */
  updateButtonShowHide() {
    if (this.environmentId !== EnvironmentId.UK) {
      if (SystemFlags.isPPCampaign && !SystemFlags.readOnly) {
        switch (this.campaignDetails.campaignStatusId) {
          case CampaignStatus.proposal:
            this.commercialButtonVisible.showProposalButton = this.commercialConfig.uiControl.showProposalButton;
            break;
          case CampaignStatus.confirm:
          case CampaignStatus.option:
            this.commercialButtonVisible.showUpdateButton = this.commercialConfig.uiControl.showUpdateButton;
            break;
        }
      } else {
        if (!this.campaignDetails.campaignStatusId
          || this.campaignDetails.campaignStatusId === CampaignStatus.cancel
          || !this.userModel.commercialTabAccess.updateBooking
          || this.paperBookingNotAllowed) {
          this.commercialButtonVisible.disabledUpdateButton = true;
        }
        if (SystemFlags.isLoadedCampaign && this.commercialConfig.uiControl && this.commercialConfig.uiControl.showUpdateButton) {
          if (this.userModel.commercialTabAccess.updateBooking) {
            this.commercialButtonVisible.showUpdateButton = true;
          } else if ((this.campaignDetails.campaignStatusId === CampaignStatus.confirm
            && this.userModel.commercialTabAccess.updateConfirmBooking) || (this.campaignDetails.campaignStatusId === CampaignStatus.option
              && this.userModel.commercialTabAccess.updateOptionBooking)) {
            this.commercialButtonVisible.showUpdateButton = true;
            this.commercialButtonVisible.disabledUpdateButton = false;
          }
        }
      }
    }

  }

  /**
   * @description for different Country option button show/Hide
   * @author Darshan Vachhani
   * @memberof CommercialButtonsComponent
   */
  optionButtonShowHide() {
    if (this.environmentId !== EnvironmentId.UK) {
      // Disabled
      if (this.campaignDetails.campaignStatusId === CampaignStatus.cancel
        || this.campaignDetails.campaignStatusId === CampaignStatus.confirm
        || this.campaignDetails.campaignStatusId === CampaignStatus.option
        || !this.userModel.commercialTabAccess.optionStatus
        || this.paperBookingNotAllowed) {
        this.commercialButtonVisible.disabledOptionButton = true;
      }
      // Hide/Show
      if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.showOptionButton && !SystemFlags.isPPCampaign) {
        this.commercialButtonVisible.showOptionButton = true;
      }
    }
  }

  /**
   * @description for different Country option button show/Hide
   * @author Darshan Vachhani
   * @memberof CommercialButtonsComponent
   */
  bookButtonShowHide() {
    const bookButtonDisabled = this.disableBookButton();
    if (this.environmentId === EnvironmentId.UK) {
      // Disable
      if (this.campaignDetails.campaignStatusId === CampaignStatus.cancel
        || bookButtonDisabled) {
        this.commercialButtonVisible.disabledBookButton = true;
      }
      // Show/Hide
      if (this.userModel.commercialTabAccess.confirmedStatus) {
        this.commercialButtonVisible.showBookButton = true;
      }
    } else {
      // Disabled
      if (this.campaignDetails.campaignStatusId === CampaignStatus.cancel
        || this.campaignDetails.campaignStatusId === CampaignStatus.confirm
        || bookButtonDisabled
        || !this.userModel.commercialTabAccess.confirmedStatus
        || this.paperBookingNotAllowed) {
        this.commercialButtonVisible.disabledBookButton = true;
      }
      if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.showBookButton) {
        this.commercialButtonVisible.showBookButton = true;
      }
    }
  }

  disableBookButton() {
    let bookButtonDisabled = false;
    if (new Date(this.campaignDetails.campaignEndDate) < new Date()) {
      if (moment(moment(this.campaignDetails.campaignStartDate).format(this.DATE_FORMAT), this.DATE_FORMAT)
        .isSameOrAfter(moment(moment().format(this.DATE_FORMAT), this.DATE_FORMAT))
        && this.campaignDetails.campaignStatusId === CampaignStatus.option) {
        bookButtonDisabled = false;
      } else if (!(moment(moment(this.campaignDetails.campaignStartDate).format(this.DATE_FORMAT), this.DATE_FORMAT)
        .isSameOrAfter(moment(moment().format(this.DATE_FORMAT), this.DATE_FORMAT)))
        && this.campaignDetails.campaignStatusId === CampaignStatus.option) {
        bookButtonDisabled = true;
      }
    }
    if (this.environmentId === EnvironmentId.UK) {
      // Disable
      if (this.campaignDetails.campaignStatusId === CampaignStatus.cancel
        || bookButtonDisabled) {
        this.commercialButtonVisible.disabledBookButton = true;
      }
      // Show/Hide
      if (this.userModel.commercialTabAccess.confirmedStatus) {
        this.commercialButtonVisible.showBookButton = true;
      }
    } else {
      // Disabled
      if (this.campaignDetails.campaignStatusId === CampaignStatus.cancel
        || this.campaignDetails.campaignStatusId === CampaignStatus.confirm
        || bookButtonDisabled
        || !this.userModel.commercialTabAccess.confirmedStatus
        || this.paperBookingNotAllowed) {
        this.commercialButtonVisible.disabledBookButton = true;
      }
      if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.showBookButton && !SystemFlags.isPPCampaign) {
        this.commercialButtonVisible.showBookButton = true;
      }
    }
    return bookButtonDisabled;
  }

  /**
   * @description for different Country request booking button show/Hide
   * @author Darshan Vachhani
   * @memberof CommercialButtonsComponent
   */
  requestBookingButtonShowHide() {
    switch (this.environmentId) {
      case EnvironmentId.UK: break;
      case EnvironmentId.Belgium:
        const userData = this.dataShareService.getInitialConfigByKey('userData');
        if (this.campaignDetails.campaignStatusId === GLOBAL.BOOKING_STATUS.OPTION && userData.tokens.indexOf('REQUEST_CONFIRMATION') > -1
          && userData.externalUser) {
          this.commercialButtonVisible.disabledRequestBookingButton = false;
        } else if (this.readOnly || this.disableBookedConfirmedCampaign || !this.userModel.resultTabAccess.requestConfirmation) {
          this.commercialButtonVisible.disabledRequestBookingButton = true;
        }
        if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.showRequestBookButton && !SystemFlags.isPPCampaign) {
          this.commercialButtonVisible.showRequestBookingButton = true;
        }
        break;
      default: {
        // Disabled
        if (this.readOnly || this.disableBookedConfirmedCampaign || !this.userModel.resultTabAccess.requestConfirmation) {
          this.commercialButtonVisible.disabledRequestBookingButton = true;
        }
        if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.showRequestBookButton && !SystemFlags.isPPCampaign) {
          this.commercialButtonVisible.showRequestBookingButton = true;
        }
        break;
      }
    }
  }

  /**
   * @description for different Country request option button show/Hide
   * @author Darshan Vachhani
   * @memberof CommercialButtonsComponent
   */
  requestOptionButtonShowHide() {
    if (this.environmentId !== EnvironmentId.UK) {
      // Disabled
      if (this.readOnly || this.disableBookedConfirmedCampaign || !this.userModel.resultTabAccess.requestOption) {
        this.commercialButtonVisible.disabledRequestOptionButton = true;
      }
      if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.showRequestOptionButton && !SystemFlags.isPPCampaign) {
        this.commercialButtonVisible.showRequestOptionButton = true;
      }
    }
  }

  /**
   * @description for different Country proposal button show/Hide
   * @author Darshan Vachhani
   * @memberof CommercialButtonsComponent
   */
  proposalButtonShowHide() {
    if (this.environmentId !== EnvironmentId.UK && (this.userModel.resultTabAccess.createProposal
      && !this.showHidePerformance
      && !this.bookingStatusCancelled
      && this.commercialConfig.uiControl && this.commercialConfig.uiControl.showProposalButton && !SystemFlags.isPPCampaign)) {
      this.commercialButtonVisible.showProposalButton = true;
    }
  }

  /**
   * @description for different Country performance button show/Hide
   * @author Darshan Vachhani
   * @memberof CommercialButtonsComponent
   */
  performanceButtonShowHide() {
    if (this.environmentId !== EnvironmentId.UK) {
      const userData = this.dataShareService.getInitialConfigByKey('userData');
      if (this.showHidePerformance
        && !this.bookingStatusCancelled
        && userData.tokens.indexOf('CREATE_PERFORMANCE_LINK') > -1
        && this.commercialConfig.uiControl && this.commercialConfig.uiControl.showPerformanceButton && !SystemFlags.isPPCampaign) {
        this.commercialButtonVisible.showPerformanceButton = true;
      }
    }
  }

  revertOptionToProposalShowHide() {
    if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.showRevertOptionToProposal && !SystemFlags.isPPCampaign) {
      this.commercialButtonVisible.showRevertOptionToProposal = true;
    }
  }

  /**
   * @description for different Country save button show/Hide
   * @author Darshan Vachhani
   * @memberof CommercialButtonsComponent
   */
  saveButtonShowHide() {
    if (this.environmentId === EnvironmentId.UK) {
      return false;
    }
    // Disabled
    if (!this.userModel.resultTabAccess.saveSearch) {
      this.commercialButtonVisible.disabledSaveButton = true;
    }
    let hasOptionBooking = false;
    let hasConfirmBooking = false;
    this.bookingList.forEach((obj) => {
      if (obj.bookingStatusId === CampaignStatus.confirm) {
        hasConfirmBooking = true;
      }
      if (obj.bookingStatusId === CampaignStatus.option) {
        hasOptionBooking = true;
      }
    });

    if (this.commercialConfig.uiControl && SystemFlags.isLoadedCampaign) {
      if (!this.userModel.commercialTabAccess.updateBooking) {
        if (hasConfirmBooking && !this.userModel.commercialTabAccess.updateConfirmBooking) {
          this.commercialButtonVisible.disabledSaveButton = true;
        }
        if (hasOptionBooking && !this.userModel.commercialTabAccess.updateOptionBooking) {
          this.commercialButtonVisible.disabledSaveButton = true;
        }
      }
    }

    if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.showSaveButton && !SystemFlags.isPPCampaign) {
      this.commercialButtonVisible.showSaveButton = true;
    }
  }

  /**
   * @description book/option/update/cancel commercial emit event
   * @author Darshan Vachhani
   * @param {*} status
   * @memberof CommercialButtonsComponent
   */
  bookCampaignData(status, updateClicked: boolean = false) {
    if (updateClicked) {
      this.updateCampaign.emit(true);
    }
    this.bookCampaign.emit(status);
  }

  /**
   * @description Requestoption and requestbooking button click
   * @author Darshan Vachhani
   * @param {*} requestParam
   * @memberof CommercialButtonsComponent
   */
  onRequestOptionAndBookingClick(requestParam) {
    const originalRequestParam = requestParam === 2 ? 8 : requestParam === 3 ? 7 : null;
    if (SystemFlags.isLoadedCampaign && (this.auditType && this.auditType.length > 0) &&
      (this.commercialButtonService.roCancel(this.campaignDetails, this.commercialConfig.uiControl, originalRequestParam) ||
        this.commercialButtonService.roChange(this.campaignDetails, this.commercialConfig.uiControl, 'bookingList', originalRequestParam))) {

      this.commercialButtonService.openReasonForChangeOverlay(
        this.commercialButtonService.populateDataParamReasonForChange(originalRequestParam, this.campaignDetails, this.commercialConfig.uiControl))
        .then((result: any) => {
          if (result) {
            this.addROCtoCampaignDetails(result, originalRequestParam);
            this.campaignRequestOptionAndBooking(requestParam, this.campaignDetails, originalRequestParam);
          }
        }, (reason) => {
          console.log(reason);
        });
    } else {
      this.campaignRequestOptionAndBooking(requestParam, this.campaignDetails, originalRequestParam);
    }
  }

  requestBookingCallback = (response, reqParams) => {
    const saveMessageObject: SaveCampaignResolvedObject = {
      title: this.userBundle[this.CONFIRMATION_LABEL].toUpperCase(),
      message: response.message || 'Request processed successfully',
      headerColor: reqParams.color,
    };
    this.callResetAndNavigateToSearch(saveMessageObject);
  }

  /**
   * @description commercial requestoption and requestbooking
   * @author Darshan Vachhani
   * @param {*} requestParam
   * @param {*} campaignDetails
   * @param {*} requestStatusId
   * @memberof CommercialButtonsComponent
   */
  campaignRequestOptionAndBooking(requestParam, campaignDetails, requestStatusId) {
    if (this.validateFn(requestStatusId)) {
      const campaigndata =
        this.populateRequestJson(campaignDetails.campaignStatusId);
      this.sendGAEvent(campaignDetails.campaignStatusId);

      const data = {
        data: JSON.stringify(campaigndata),
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
      };
      this.campaignSaveDetails = {
        campaignName: campaigndata.title,
        hideFinance: true
      };
      const reqParams = this.dataShareService.getParamsForActionButtons(requestParam);
      this.commercialService.updateCommercialInfo(data).subscribe((res: any) => {
        if (String(res.status) === 'OK' && (!campaigndata['title'] || campaigndata['title'] === '')) {
          this.commercialButtonService.populateCampaignTitle(this.campaignDetails.advertiser.advertiserName, this.campaignSaveDetails);
          this.sbModalPopupService.open(ConfirmationModalComponent, {
            SelectedValue: this.campaignSaveDetails,
            SelectedTitle: this.userBundle[this.CONFIRMATION_LABEL].toUpperCase(),
            confirmation: false,
            color: reqParams.color
          }).result.then((result) => {
            if (result) {
              const param = {
                title: result.campaignName,
                bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
                requestStatusId,
              };
              this.commercialService.campaignRequestBooking(param).subscribe((response) => {
                this.requestBookingCallback(response, reqParams);
              }, () => {
                this.logHelperService.logError(this.userBundle[this.CONNECTING]);
              });
            }
          }, (reason) => {
            console.log(reason);
          });
        } else if (String(res.status) === 'KO') {
          this.logHelperService.logError(String(res.message));
        } else {
          const param = {
            bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
            requestStatusId
          };
          this.commercialService.campaignRequestBooking(param).subscribe((response) => {
            if (String(response.status) === 'KO' &&
              (this.userBundle['commercial.error.largeCampaign'] && (response['message'].split('<=')[0] === this.userBundle['commercial.error.largeCampaign'].split('<=')[0]))) {
              // updated for SM-10198
              this.commercialService.openConfirmationModal(response['message'],
                this.userBundle[this.CONFIRMATION_LABEL].toUpperCase(), reqParams.color, requestParam !== 1, false, undefined, undefined, 'workspace');
            } else {
              this.requestBookingCallback(response, reqParams);
            }
          }, () => {
            this.logHelperService.logError(this.userBundle[this.CONNECTING]);
          });

        }
      });
    }
  }

  revertOptionToProprosal = () => {
    const statusId: number = CampaignStatus.proposal;
    if (this.validateFn(statusId)) {
      const campaigndata = this.populateRequestJson(statusId);
      const data = {
        data: JSON.stringify(campaigndata),
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
      };

      this.commercialService.revertOptionToProprosal(data).subscribe((res: any) => {
        if (String(res.status) === 'KO') {
          this.logHelperService.logError(res.message || this.logHelperService.logError(this.userBundle['commercial.error.ko']));
        } else if (String(res.status) === 'OK') {
          const saveMessageObject: SaveCampaignResolvedObject = {
            title: this.userBundle[this.CONFIRMATION_LABEL].toUpperCase(),
            message: res.message || 'Campaign updated successfully',
            headerColor: '#ef3f3b',
          };
          this.callResetAndNavigateToSearch(saveMessageObject);
        }
      }, () => {
        this.logHelperService.logError(this.userBundle['commercial.error.ko']);
      });
    }
  }

  /**
   * @description commercial performance button click
   * @author Darshan Vachhani
   * @param {*} requestParam
   * @memberof CommercialButtonsComponent
   */
  performance(requestParam) {
    const reqParams = this.dataShareService.getParamsForActionButtons(requestParam);
    const campaigndata =
      this.populateRequestJson(this.campaignDetails.campaignStatusId);
    this.sendGAEvent(this.campaignDetails.campaignStatusId);
    if (reqParams.hideFinance) {
      this.campaignSaveDetails = {
        campaignName: campaigndata.title,
        hideFinance: true
      };
      this.sbModalPopupService.open(PerformanceProposalComponent, {
        SelectedValue: this.campaignSaveDetails,
        SelectedTitle: this.userBundle[reqParams.userBundle].toUpperCase(),
        hideTitle: reqParams.hideTitle,
        color: reqParams.color,
        showTouchedPOIOnly: this.commercialConfig.uiControl.enableTouchedPOIFeature ? this.commercialConfig.uiControl.showTouchedPOIOnly : false,
        allowEditToTouchedPOIs: this.commercialConfig.uiControl.enableTouchedPOIFeature ? this.commercialConfig.uiControl.allowEditToTouchedPOIs : false
      }).result.then((result) => {
        if (result) {
          reqParams.title = result.campaignName;
          reqParams.hideFinance = result.hideFinance;
          reqParams.hideImpression = result.hideImpression;
          reqParams.bricsCampaignId = GLOBAL.BRIC_CAMPAIGN_ID;
          reqParams.showTouchedPOIOnly = result.showTouchedPOIOnly;
          reqParams.allowEditToTouchedPOIs = result.allowEditToTouchedPOIs;
          reqParams['referer'] = environment.landingAppUrl;
          this.sharedService.actionButtonRequest(reqParams, reqParams.url).subscribe((res) => {
            if (res.status.toUpperCase() === 'OK' && res.data && res.data.clientPortelURL) {
              this.commercialButtonService.openConfirmationModalPP(
                res.data.clientPortelURL, this.userBundle['result.showMessage.performance'],
                this.userBundle[reqParams.userBundle].toUpperCase(),
                this.userBundle['result.showMessage.performance.urlLabel'], reqParams.color, false, false, false, res.data.campaignAddedForProcessing ? true : false);

            } else {
              console.log(res.message);
            }
          }, () => {
            console.error('Error');
          });
        }
      }, (reason) => {
        console.log(reason);
      });

    } else {
      reqParams['action'] = reqParams['action'];
      reqParams['bricsCampaignId'] = GLOBAL.BRIC_CAMPAIGN_ID;
      const campaignSaveParam = {
        action: 'getClientPortelURL',
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        referer: environment.landingAppUrl,
        showTouchedPOIOnly: this.commercialConfig.uiControl.enableTouchedPOIFeature ? this.commercialConfig.uiControl.showTouchedPOIOnly : false,
        allowEditToTouchedPOIs: this.commercialConfig.uiControl.enableTouchedPOIFeature ? this.commercialConfig.uiControl.allowEditToTouchedPOIs : false
      };
      this.sharedService.actionButtonRequest(campaignSaveParam, reqParams.url).subscribe((res) => {
        if (res.status.toUpperCase() === 'OK' && res.data && res.data.clientPortelURL) {
          this.commercialButtonService.openConfirmationModalPP(
            res.data.clientPortelURL, res.data.message || 'Successfully created Performance URL',
            this.userBundle['result.buttonLabel.performance'].toUpperCase(),
            this.userBundle['result.showMessage.performance.urlLabel'],
            reqParams.color, false, false, false, res.data.campaignAddedForProcessing ? true : false);

        } else {
          console.log(res.message);
        }
      }, () => {
        console.error('Error');
      });

    }
  }

  getClientPortelURL(reqParams) {
    reqParams.bricsCampaignId = GLOBAL.BRIC_CAMPAIGN_ID;
    const campaignSaveParam = {
      action: 'getClientPortelURL',
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
      referer: environment.landingAppUrl
    };
    this.sharedService.actionButtonRequest(campaignSaveParam, reqParams.url).subscribe((res) => {
      if (res.status.toUpperCase() === 'OK' && res.data && res.data.clientPortelURL) {
        this.commercialButtonService.openConfirmationModalPP(
          res.data.clientPortelURL, res.data.message || 'Successfully created Performance URL',
          this.userBundle['result.buttonLabel.performance'].toUpperCase(),
          this.userBundle['result.showMessage.performance.urlLabel'],
          reqParams.color, false);
      } else {
        console.log(res.message);
      }
    }, () => {
      console.error('Error');
    });
  }

  /**
   * @description commercial proposal button click
   * @author Darshan Vachhani
   * @param {*} requestParam
   * @memberof CommercialButtonsComponent
   */
  proposal(requestParam) {
    if (this.validateFn(requestParam)) {

      const status = requestParam || this.campaignDetails.campaignStatusId;
      /*
        Need to trigger ROChange OR ROCancel in below scenario [SM-5283]
        1. It should be loaded campaign and there should be data in auditType(to populate drop downs) in Initial Config
        2. Trigger ROCancel OR
        3. Trigger ROChange
      */
      if (SystemFlags.isLoadedCampaign && (this.auditType && this.auditType.length > 0) &&
        (this.commercialButtonService.roCancel(this.campaignDetails, this.commercialConfig.uiControl, status) ||
          this.commercialButtonService.roChange(this.campaignDetails, this.commercialConfig.uiControl, 'bookingList', status))) {

        this.commercialButtonService.openReasonForChangeOverlay(
          this.commercialButtonService.populateDataParamReasonForChange(status, this.campaignDetails, this.commercialConfig.uiControl))
          .then((result: any) => {
            if (result) {
              this.addROCtoCampaignDetails(result, status);
              this.proposalOverlay(requestParam, this.campaignDetails);
            }
          }, (reason) => {
            console.log(reason);
          });
      } else {
        this.proposalOverlay(requestParam, this.campaignDetails);
      }
    }
  }

  /**
   * @description Common code for proposaloverlay
   * @author Nishit Parekh
   * @param {number} requestParam request id
   * @param {CampaignDetails} campaignDetails campaign details object
   * @memberof CommercialButtonsComponent
   */
  proposalOverlay(requestParam: number, campaignDetails: CampaignDetails) {
    const reqParams = this.dataShareService.getParamsForActionButtons(requestParam);
    campaignDetails.campaignStatusId = campaignDetails.campaignStatusId || GLOBAL.BOOKING_STATUS.PROPOSAL;
    const campaigndata =
      this.populateRequestJson(campaignDetails.campaignStatusId);
    this.sendGAEvent(campaignDetails.campaignStatusId);
    const data = {
      data: JSON.stringify(campaigndata),
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
    };
    this.campaignSaveDetails = {
      campaignName: campaigndata.title,
      hideFinance: true
    };
    this.commercialService.updateCommercialInfo(data).subscribe(() => {
      this.commercialButtonService.populateCampaignTitle(this.campaignDetails.advertiser.advertiserName, this.campaignSaveDetails);

      this.sbModalPopupService.open(PerformanceProposalComponent, {
        SelectedValue: this.campaignSaveDetails,
        SelectedTitle: this.userBundle[this.PROPOSAL_LABEL].toUpperCase(),
        hideTitle: reqParams.hideTitle,
        color: reqParams.color,
        hideFinance: reqParams.hideFinance,
        showTouchedPOIOnly: this.commercialConfig.uiControl.showTouchedPOIOnly,
        allowEditToTouchedPOIs: this.commercialConfig.uiControl.allowEditToTouchedPOIs,
        overrideProposalExpiryDate: this.commercialConfig.uiControl.overrideProposalExpiryDate,
        proposalGlobalExpiryPeriodInDays: this.commercialConfig.uiControl.proposalGlobalExpiryPeriodInDays,
      }).result.then((result) => {
        if (result) {
          reqParams.title = result.campaignName;
          reqParams.hideFinance = result.hideFinance;
          reqParams.hideImpression = result.hideImpression;
          reqParams.showTouchedPOIOnly = result.showTouchedPOIOnly;
          reqParams.allowEditToTouchedPOIs = result.allowEditToTouchedPOIs;
          reqParams.bricsCampaignId = GLOBAL.BRIC_CAMPAIGN_ID;
          const campaignSaveParam: any = {
            action: 'getClientPortelURL',
            bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
            title: result.campaignName,
            hideFinance: result.hideFinance,
            hideImpression: result.hideImpression,
            showTouchedPOIOnly: result.showTouchedPOIOnly,
            allowEditToTouchedPOIs: result.allowEditToTouchedPOIs,
            referer: environment.landingAppUrl
          };
          if (this.commercialConfig.uiControl.overrideProposalExpiryDate) {
            campaignSaveParam.newValidFromDate = result.newValidFromDate;
            campaignSaveParam.newValidToDate = result.newValidToDate;
          }
          this.sharedService.actionButtonRequest(campaignSaveParam, reqParams.url).subscribe((res) => {
            if (res.status.toUpperCase() === 'OK' && res.data && res.data.clientPortelURL) {
              this.commercialButtonService.openConfirmationModalPP(
                res.data.clientPortelURL, res.message || this.userBundle['result.showMessage.proposal'],
                this.userBundle['common.proposal'].toUpperCase(),
                this.userBundle['result.showMessage.proposal.urlLabel'], reqParams.color, true, true, false, res.data.campaignAddedForProcessing ? true : false);
            } else {
              if (res.status.toUpperCase() === 'KO') {
                if (this.userBundle['commercial.error.largeCampaign'] && (res.message.split('<=')[0] === this.userBundle['commercial.error.largeCampaign'].split('<=')[0])) {
                  // updated for SM-10198
                  this.commercialService.openConfirmationModal(res.message || 'Search saved successfully',
                    this.userBundle[this.CONFIRMATION_LABEL].toUpperCase(), reqParams.color, requestParam !== 1, false, undefined, undefined, 'workspace');
                } else {
                  const frameisOOCBundle = this.config.userBundle['commercial.error.oocFrame'] || 'Frame is OOC';
                  const saveMessageObject: SaveCampaignResolvedObject = {
                    message: res.message || 'Please Re-run Solution',
                    title: this.userBundle['result.confirmation.label'].toUpperCase(),
                    headerColor: GLOBAL.DEFAULT_TITLE_BACKGROUND_COLOR,
                    isIgnoreButtonEnabled: res.message === frameisOOCBundle ? this.config.uiControl['ignoreOOCFrames'] : false,
                  };
                  this.redirectToWorkspace(saveMessageObject, campaignSaveParam, reqParams);
                }
              }
            }
          }, () => {
            console.error('Error');
          });

        }
      }, (reason) => {
        console.log(reason);
      });
    });
  }

  redirectToWorkspace(saveMessageObject: SaveCampaignResolvedObject, campaignSaveParam, reqParams) {
    saveMessageObject.defaultDesign = true;
    campaignSaveParam.ignoreOOCFrames = true; // if set true, excludes ooc check in backend
    this.sbModalPopupService.open(SaveCampaignComponent, saveMessageObject).result.then(
      (res) => {
        if (res && res === 'cancel') {
          this.sharedService.actionButtonRequest(campaignSaveParam, reqParams.url).subscribe((res) => {
            if (res.status.toUpperCase() === 'OK' && res.data && res.data.clientPortelURL) {

              this.commercialButtonService.openConfirmationModalPP(
                res.data.clientPortelURL, this.userBundle['result.showMessage.proposal'],
                this.userBundle[this.PROPOSAL_LABEL].toUpperCase(),
                this.userBundle['result.showMessage.proposal.urlLabel'], reqParams.color, true, true);

            } else {
              console.log(res.message);
              if (res.status.toUpperCase() === 'KO') {
                const saveObject: SaveCampaignResolvedObject = {
                  message: res.message || 'Please Re-run Solution',
                  title: this.userBundle['result.confirmation.label'].toUpperCase(),
                  headerColor: GLOBAL.DEFAULT_TITLE_BACKGROUND_COLOR,
                };
                this.commercialButtonService.redirectToWorkspace(saveObject);
              }

            }
          }, () => {
            console.error('Error');
          });
        } else {
          SystemFlags.forcefullyCallProcessBrics = true;
          this.router.navigate(['/workspace']);
        }
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  handleConfirmProposal(requestParam, campaignDetails) {
    const reqParams = this.dataShareService.getParamsForActionButtons(requestParam);

    const campaigndata =
      this.populateRequestJson(campaignDetails.campaignStatusId);
    this.sendGAEvent(campaignDetails.campaignStatusId);
    const data = {
      data: JSON.stringify(campaigndata),
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
    };
    this.campaignSaveDetails = {
      campaignName: campaigndata['title'],
      hideFinance: false,
      hideImpression: false
    };
    this.commercialService.updateCommercialInfo(data).subscribe(() => {
      this.commercialButtonService.populateCampaignTitle(this.campaignDetails.advertiser.advertiserName, this.campaignSaveDetails);

      this.sbModalPopupService.open(PerformanceProposalComponent, {
        SelectedValue: this.campaignSaveDetails,
        SelectedTitle: this.userBundle['common.proposal'].toUpperCase(),
        hideTitle: reqParams.hideTitle,
        color: reqParams.color
      }).result.then((result) => {
        if (result) {
          const campaignSaveParam = {
            title: result.campaignName,
            hideFinance: result['hideFinance'],
            hideImpression: result.hideImpression,
            action: 'proposalCampaign',
            bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
            referer: environment.landingAppUrl
          };
          this.sharedService.actionButtonRequest(campaignSaveParam, reqParams.url).subscribe((res) => {
            if (res.status.toUpperCase() === 'OK') {

              const saveMessageObject: SaveCampaignResolvedObject = {
                title: this.userBundle['result.confirmation.label'].toUpperCase(),
                message: res.message || this.userBundle['result.showMessage.proposal'] || 'Successfully created Proposal',
                headerColor: reqParams.color,
              };
              this.callResetAndNavigateToSearch(saveMessageObject);
            } else {
              console.log(res.message);
            }
          }, () => {
            console.error('Error');
          });

        }
      }, (reason) => {
        console.log(reason);
      });
    });
  }

  /**
   * @description commercial confirm  proposal button click
   * @author Shreni Shah
   * @param {*} requestParam
   * @memberof CommercialButtonsComponent
   */
  confirmProposal(requestParam) {
    const campaignDetails = this.campaignDetails;
    if (this.validateFn(requestParam)) {
      const status = requestParam || this.campaignDetails.campaignStatusId;
      /*
        Need to trigger ROChange OR ROCancel in below scenario [SM-5283]
        1. It should be loaded campaign and there should be data in auditType(to populate drop downs) in Initial Config
        2. Trigger ROCancel OR
        3. Trigger ROChange
      */
      if (SystemFlags.isLoadedCampaign && (this.auditType && this.auditType.length > 0) &&
        (this.commercialButtonService.roCancel(this.campaignDetails, this.commercialConfig.uiControl, status) ||
          this.commercialButtonService.roChange(this.campaignDetails, this.commercialConfig.uiControl, 'bookingList', status))) {
        this.commercialButtonService.openReasonForChangeOverlay(
          this.commercialButtonService.populateDataParamReasonForChange(status, this.campaignDetails, this.commercialConfig.uiControl))
          .then((result: any) => {
            if (result) {
              this.addROCtoCampaignDetails(result, status);
              this.handleConfirmProposal(requestParam, campaignDetails);
            }
          }, (reason) => {
            console.log(reason);
          });
      } else {
        this.handleConfirmProposal(requestParam, campaignDetails);
      }
    }
  }

  /**
   * @description save and create new commerical
   * @author Darshan Vachhani
   * @param {*} requestParam
   * @memberof CommercialButtonsComponent
   */
  saveCampaign(requestParam) {
    const campaignDetails = this.campaignDetails;
    campaignDetails.campaignStatusId = requestParam;
    const campaigndata =
      this.populateRequestJson(campaignDetails.campaignStatusId);
    this.sendGAEvent(campaignDetails.campaignStatusId);
    if (SystemFlags.isLoadedCampaign && (this.auditType && this.auditType.length > 0) &&
      (this.commercialButtonService.roCancel(this.campaignDetails, this.commercialConfig.uiControl, requestParam) ||
        this.commercialButtonService.roChange(this.campaignDetails, this.commercialConfig.uiControl, 'bookingList', requestParam))) {

      this.commercialButtonService.openReasonForChangeOverlay(
        this.commercialButtonService.populateDataParamReasonForChange(requestParam, this.campaignDetails, this.commercialConfig.uiControl))
        .then((result: any) => {
          if (result) {
            this.addROCtoCampaignDetails(result, requestParam);
            this.saveCampaignOverlay(requestParam, campaigndata);
          }
        }, (reason) => {
          console.log(reason);
        });
    } else {
      this.saveCampaignOverlay(requestParam, campaigndata);
    }
  }

  campaignSave(param, reqParams, requestParam) {
    this.commercialService.campaignSave(param).subscribe((response: any) => {
      const message = response.message;
      // Updated for SM-10198
      if (this.userBundle['commercial.error.largeCampaign'] && (message.split('<=')[0] === this.userBundle['commercial.error.largeCampaign'].split('<=')[0])) {
        this.commercialService.openConfirmationModal(message,
          this.userBundle[this.CONFIRMATION_LABEL].toUpperCase(), reqParams.color, requestParam !== 1, false, undefined, undefined, 'workspace');
      } else {
        this.commercialService.openConfirmationModal(message || 'Search saved successfully',
          this.userBundle[this.CONFIRMATION_LABEL].toUpperCase(), reqParams.color, requestParam !== 1);
      }
    }, () => {
      this.logHelperService.logError(this.userBundle[this.CONNECTING]);
    });
  }

  /**
   * @description
   * @author Nishit Parekh
   * @param {number} requestParam
   * @param {*} campaigndata
   * @memberof CommercialButtonsComponent
   */
  saveCampaignOverlay(requestParam: number, campaigndata) {
    const data = {
      data: JSON.stringify(campaigndata),
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
    };
    this.campaignSaveDetails = {
      campaignName: campaigndata.title,
      hideFinance: true
    };
    const reqParams = this.dataShareService.getParamsForActionButtons(requestParam);
    this.commercialService.updateCommercialInfo(data).subscribe((res: any) => {
      if (String(res.status) === 'OK' && (!campaigndata['title'] || campaigndata['title'] === '')) {
        this.commercialButtonService.populateCampaignTitle(this.campaignDetails.advertiser.advertiserName, this.campaignSaveDetails);
        this.sbModalPopupService.open(ConfirmationModalComponent, {
          SelectedValue: this.campaignSaveDetails,
          SelectedTitle: this.userBundle[this.CONFIRMATION_LABEL].toUpperCase(),
          confirmation: false,
          color: reqParams.color
        }).result.then((result) => {
          if (result) {
            const param = {
              title: result.campaignName,
              bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
            };
            this.commercialService.campaignSave(param).subscribe((res) => {
              const message = res['message'];
              const refreshCampaign = this.commercialService.openConfirmationModal(message || 'Search saved successfully',
                this.userBundle['result.confirmation.label'].toUpperCase(), reqParams.color, requestParam !== 1, false, null, null, null,
                res && res['data'] && res['data']['campaignAddedForProcessing'] ? true : false);
              if (refreshCampaign) {
                refreshCampaign.then((data: any) => {
                  if (data) {
                    this.resetUiAndRedirectToSearchPage();
                  }
                });
              }
            }, () => {
              this.logHelperService.logError(this.userBundle['common.error.connecting']);
            });
          }
        }, (reason) => {
          console.log(reason);
        });
      } else if (String(res.status) === 'KO') {
        this.logHelperService.logError(String(res.message));
      } else {
        const param = {
          bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        };
        this.commercialService.campaignSave(param).subscribe((data) => {
          const message = data['message'];
          const refreshCampaign = this.commercialService.openConfirmationModal(message || 'Search saved successfully',
            this.userBundle['result.confirmation.label'].toUpperCase(), reqParams.color, requestParam !== 1, false, null, null, null,
            data && data['data'] && data['data']['campaignAddedForProcessing'] ? true : false);
          if (refreshCampaign) {
            refreshCampaign.then((data: any) => {
              if (data) {
                this.resetUiAndRedirectToSearchPage();
              }
            });
          }
        }, () => {
          this.logHelperService.logError(this.userBundle['common.error.connecting']);
        });
      }
    });
  }

  sendGAEvent = (statusId) => {
    let eventName: any = '';
    eventName = this.getEventNameStatusId(statusId);
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Commercial,
      eventName, 'Commercial Action');
  }

  getEventNameStatusId(statusId) {
    let eventName = '';
    if (statusId === 5) {
      eventName = 'Cancel';
    } else if (statusId === 2) {
      eventName = 'Option';
    } else if (statusId === 4) {
      eventName = 'Book';
    } else if (this.campaignDetails.campaignStatusId === statusId) {
      eventName = 'Update';
    } else {
      eventName = '';
    }
    return eventName;
  }

  callResetAndNavigateToSearch(saveMessageObject: SaveCampaignResolvedObject) {
    saveMessageObject.defaultDesign = true;
    this.sbModalPopupService.open(SaveCampaignComponent, saveMessageObject).result.then(() => {
      this.commercialService.clearLoadedCampaign().subscribe((res) => {
        this.searchService.resetCampaign(false, false, true);
        if (String(res.status) === 'OK') {
          SystemFlags.isLoadedCampaign = false;
          this.appHeaderService.changeResetConfig({ getConfig: true, getBricStructure: true });
          this.commercialService.setGoToOtherPage(true);
          this.dataShareService.activateResultTab(false);
          this.dataShareService.activateCommercialTab(false);
          this.router.navigate(['/search']);
        } else {
          this.logHelperService.logError(this.userBundle[this.CONNECTING]);
        }
      });
    }, (reason) => {
      console.log(reason);
    });
    SystemFlags.keepAliveThreadActive = true;
  }

  /**
   * @description Add ROChange/Cancel to Campaign Details
   * @author Nishit Parekh
   * @param {*} result reason ofr change/cancel + free text
   * @param {number} status status of campaign
   * @memberof CommercialButtonsComponent
   */
  addROCtoCampaignDetails(result, status: number) {
    if (this.environmentId === EnvironmentId.HKAI ||
      this.environmentId === EnvironmentId.HKCTS ||
      this.environmentId === EnvironmentId.HKM) {
      this.campaignDetails.reasonForChange = result.reasonForChange;
      if (result.freeText !== '' && result.freeText != null) {
        this.campaignDetails.reasonForChange.freeText = result.freeText;
      }
    } else {
      this.campaignDetails = this.commercialButtonService.reasonForChangeOverlayThen(this.campaignDetails, result, status);
    }
  }

  checkForInvoiceLock() {
    const uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    const userData = this.dataShareService.getInitialConfigByKey('userData');
    if (userData.tokens.indexOf('EDIT_LOCKED_STATUS') > -1 && SystemFlags.lockReason !== 'USER_LOCKED'
      && uiControl && uiControl.invoiceLockFeature) {
      this.readOnly = false;
    }
  }

  readOnlyChanged() {
    this.checkForInvoiceLock();
    this.handleShowHideButtons();
  }

  resetUiAndRedirectToSearchPage() {
    this.commercialService.clearLoadedCampaign().subscribe((res: any) => {
      this.searchService.resetCampaign(false, false, true);
      if (res.status === 'OK') {
        SystemFlags.isLoadedCampaign = false;
        this.appHeaderService.changeResetConfig({ getConfig: true, getBricStructure: true });
        this.commercialService.setGoToOtherPage(true);
        this.dataShareService.activateResultTab(false);
        this.dataShareService.activateCommercialTab(false);
        this.router.navigate(['/search']);
      } else {
        this.logHelperService.logError(this.userBundle['common.error.connecting']);
      }
    });
  }
}
