import { Component, OnInit, Input } from '@angular/core';
import { BookingLine } from '../../commercial.model';
import { DataShareService } from '../../../core/services/index';
import { SbSelectedItem } from '../../../models/sb-selected-item';
import {
  RequestParams,
  defaultBuyerContactParams,
  defaultctuPersonParams
} from '../../../models/request-params';
import { InitialConfigModel, SystemFlags } from '../../../models';
import { LocaleData } from '../../../core/utils/LocaleData';
import { MaskService } from '../../../core/services/mask.service';
import { NumberMaskModel } from '../../../models/imask';
import { SelectConfig } from '../../../core/components/sb-select/core/select-config';

@Component({
  selector: 'app-booking-detail-germany',
  templateUrl: './booking-detail-germany.component.html',
  styleUrls: ['./booking-detail-germany.component.css']
})
export class BookingDetailsGermanyComponent implements OnInit {
  @Input() rowIndex: number;
  @Input() bookings: BookingLine[];

  /**
   * @description Initial Config
   * @type {InitialConfigModel}
   * @memberof BookingDetailsGermanyComponent
   */
  initialConfig: InitialConfigModel;

  booking: BookingLine;
  productionType: any[] = [];

  buyerContactList: SbSelectedItem[] = [];
  public buyerContactSearchParams: RequestParams = defaultBuyerContactParams;

  ctuPersonList: SbSelectedItem[] = [];
  public ctuPersonSearchParams: RequestParams = defaultctuPersonParams;

  /**
   * @description Date format to use to display date
   * @type {string}
   * @memberof BookingDetailsGermanyComponent
   */
  displayDateFormat = '';

  maskService: MaskService = new MaskService();

  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof BookingDetailsGermanyComponent
   */
  currencyInputMask: NumberMaskModel;
  /**
   * @description  Mask input config for Percentage
   * @type {NumberMaskModel}
   * @memberof BookingDetailsGermanyComponent
   */
  percentageInputMask: NumberMaskModel;

  /**
   * @description
   * @type {SelectConfig}
   * @memberof BookingDetailsGermanyComponent
   */
  public buyerContactLookupConfig: SelectConfig;

  /**
   * @description
   * @type {SelectConfig}
   * @memberof BookingDetailsGermanyComponent
   */
  public ctuPersonLookupConfig: SelectConfig;

  /**
   * @description set readOnly
   * @memberof BookingDetailsGermanyComponent
   */
  public readOnly = SystemFlags.readOnly;
  constructor(
    public dataShareService: DataShareService
  ) { }

  ngOnInit(): void {
    this.currencyInputMask = this.maskService.currencyInputMask();
    this.percentageInputMask = this.maskService.currencyInputMask({ max: 100 });
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.productionType = this.dataShareService.getInitialConfigByKey('productionType');
    this.displayDateFormat = LocaleData.displayDateFormat;
    this.booking = this.bookings[this.rowIndex];
    this.setBuyerContactConfig();
    this.setCtuPersonConfig();
  }

  /**
   * @description To set the configuration for buyer contact lookup component
   * @author Dhaval Patel
   * @memberof BookingDetailsGermanyComponent
   */
  setBuyerContactConfig() {
    this.buyerContactLookupConfig = new SelectConfig({
      placeholder: this.initialConfig.userBundle['common.lookup.defaultOption'],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_BUYER_CONTRACT_URL'),
      extraParams: this.buyerContactSearchParams,
      searchKeyParamName: 'buyerContactName',
      idField: 'buyerContactId',
      textField: 'buyerContactName'
    });
  }

  /**
   * @description To set the configuration for buyer contact lookup component
   * @author Dhaval Patel
   * @memberof BookingDetailsGermanyComponent
   */
  setCtuPersonConfig() {
    this.ctuPersonLookupConfig = new SelectConfig({
      placeholder: this.initialConfig.userBundle['common.lookup.defaultOption'],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_CTU_PERSON_URL'),
      extraParams: this.ctuPersonSearchParams,
      searchKeyParamName: 'ctuPersonName',
      idField: 'ctuPersonId',
      textField: 'ctuPersonName'
    });
  }

  // Functions related to Buyer Contact
  onbuyerContactSelected(value: any) {
    this.booking.buyerContactId = value.buyerContactId;
    this.booking.buyerContactName = value.buyerContactName;
  }
  removeSelectedbuyerContact() {
    this.booking.buyerContactId = null;
    this.booking.buyerContactName = null;
  }
  getBuyerContactResponse(value: any): void {
    if (value.buyerContact && value.buyerContact.length) {
      this.buyerContactList = value.buyerContact.map((item) => {
        item.source = item;
        return item;
      });
    }
  }

  // Functions related to Ctu Person
  onCtuPersonSelected(value: any) {
    this.booking.ctuPersonId = value.ctuPersonId;
    this.booking.ctuPersonName = value.ctuPersonName;
  }
  removeSelectedCtuPerson() {
    this.booking.ctuPersonId = null;
    this.booking.ctuPersonName = null;
  }
  getCtuPersonResponse(value: any): void {
    if (value.ctuPerson && value.ctuPerson.length) {
      this.ctuPersonList = value.ctuPerson.map(item => ({
        ctuPersonId: item.idCtuPerson,
        ctuPersonName: item.ctuPersonName,
        source: item
      }));
    }
  }

  trackByProductionType(index, item) {
    return item?.productionTypeId;
  }

}
