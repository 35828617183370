import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FilterDataService } from './../../core/services/filter-data.service';
import { DataShareService } from './../../core/services/data-share.service';

@Component({
  selector: 'app-frame-filter',
  templateUrl: './frame-filter.component.html',
  styleUrls: ['./frame-filter.component.css']
})
export class FrameFilterComponent implements OnInit {

  /**
   * It will take data to configure filter window.
   */
  @Input() resolveObject;
  /**
   * It calls event to close filter winodw.
   */
  @Output() closeSidebar = new EventEmitter<string>();
  /**
   * It contains sot filter data.
   */
  public illumination = 3;
  /**
   * It contians initial config data.
   */
  public initialConfig: any;

  /**
   * Constructor
   * @param filterDataService Service which used to handle filter data.
   * @param dataShareService Service to share data between compoenents.
   */
  constructor(
    private filterDataService: FilterDataService,
    private dataShareService: DataShareService) { }

  /**
   * Function which loads initially.
   */
  ngOnInit() {
    this.initialConfig = this.dataShareService.getInitialConfig();
    const frame = this.filterDataService.getFrame();
    if (frame && Object.keys(frame).length !== 0) {
      this.illumination = frame.illumination;
    }
  }

  onIlluminationChange(val) {
    this.illumination = val;
  }

  /**
   * To store selected items/data.
   */
  saveClick() {
    if (this.illumination === 3) {
      this.filterDataService.setFrame({});
    } else {
      const tempFrame = this.filterDataService.getFrame();
      tempFrame.illumination = this.illumination;
      this.filterDataService.setFrame(tempFrame);
    }
    this.closeSidebar.emit();
  }

  /**
   * To close filter window.
   */
  closeClick() {
    this.closeSidebar.emit();
  }

}
