import { GLOBAL } from '../core/utils/app.constant';

export interface RequestParams {
  action: string;
  data: object;
}

export let defaultBrandSearchParams: RequestParams = {
  action: 'lookupBrand',
  data: {}
};

export let defaultAdvertiserSearchParams: RequestParams = {
  action: 'lookupOrganisation',
  data: {
    organisationTypeId: 1,
    bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
  }
};

export let defaultSpecialistSearchParams: RequestParams = {
  action: 'lookupOrganisation',
  data: {
    organisationTypeId: 2,
    bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
  }
};

export let defaultBuyerContactParams: RequestParams = {
  action: 'lookupBuyerContact',
  data: {}
};

export let defaultAdminPersonParams: RequestParams = {
  action: 'lookupAdminPerson',
  data: {}
};

export let defaultSalesPersonParams: RequestParams = {
  action: 'lookupSalesPerson',
  data: {}
};

export let defaultBarterParams: RequestParams = {
  action: 'lookupOrganisation',
  data: {
    organisationTypeId: 5,
    bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
  }
};

export let defaultAgencySearchParams: RequestParams = {
  action: 'lookupOrganisation',
  data: {
    organisationTypeId: 3,
    bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
  }
};

export let defaultSubAgencySearchParams: RequestParams = {
  action: 'lookupOrganisation',
  data: {
    organisationTypeId: 8,
    bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
  }
};

export let defaultBarterSearchParams: RequestParams = {
  action: 'lookupOrganisation',
  data: {
    organisationTypeId: 5,
    bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
  }
};

export let defaultctuPersonParams: RequestParams = {
  action: 'lookupCtuPerson',
  data: {}
};

export let defaultSalesPersonSearchParams: RequestParams = {
  action: 'lookupSalesPerson',
  data: {}
};

export let defaultAdminPersonSearchParams: RequestParams = {
  action: 'lookupAdminPerson',
  data: {}
};

export let defaultInternalAdvertiserParams: RequestParams = {
  action: 'lookupOrganisation',
  data: {
    organisationTypeId: 5
  }
};

export class CommercialButtonActions {
  title: string;
  action: string;
  bricsCampaignId: number;
  url: string;
  userBundle: string;
  color: string;
  hideFinance?: boolean;
  hideImpression?: boolean;
  hideTitle?: boolean;
  showTouchedPOIOnly?: boolean;
  allowEditToTouchedPOIs?: boolean;
}

export class SelectionFlags {
  selectionId: number;
  relative?: boolean;
  exclude?: boolean;
}
