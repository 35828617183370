/**
 * @description checks whether provided variable is number or not
 * @author Alkesh Shah
 * @export
 * @param {any} x - value to check
 * @returns {boolean} - true if number else false
 */
export function isNumber(x: any): boolean {
  if (isNaN(x) || x === null || x === undefined || x === '') {
    return false;
  }
  return true;
}
