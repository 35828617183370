import { Directive, ElementRef, Input, Output, AfterViewInit, EventEmitter } from '@angular/core';
import * as interact from 'interactjs';

@Directive({
  selector: '[appResizable]'
})

export class ResizableDirective implements AfterViewInit {

  /**
   * @description value to resizing step
   * @memberof ResizableDirective
   */
  @Input() minResizeWidth = 100;

  /**
   * @description Holds actual cell width
   * @type {number}
   * @memberof ResizableDirective
   */
  @Input() actualCellWidth: number;

  /**
   * @description value to resizing step
   * @memberof ResizableDirective
   */
  @Input() maxResizeWidth = 200;

  /**
   * @description disable resizing
   * @type {boolean}
   * @memberof ResizableDirective
   */
  @Input() disableResize = false;

  /**
   * @description This event will be raised when resize will start
   * @memberof ResizableDirective
   */
  @Output() resizeStart: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @description This event will be raised when resize will stop
   * @memberof ResizableDirective
   */
  @Output() resizeStop: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @description This event will be raised during resizing
   * @memberof ResizableDirective
   */
  @Output() resizing: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @description It holds temp effect element added virtually
   * @memberof ResizableDirective
   */
  clonedElement;

  /**
   * @description Handles resizing
   * @memberof ResizableDirective
   */
  resizeHandle;

  resizeThreshold = 5;

  readonly HELPER = '.ui-resizable-helper';
  constructor(private el: ElementRef) {
    //
  }

  /**
   * @description Angular life cycle hook
   * @author Vijay Sutaria
   * @memberof ResizableDirective
   */
  ngAfterViewInit(): void {
    this.resizeHandle = this.el.nativeElement.getElementsByClassName('appResizableHandle')[0];
    if (this.resizeHandle) {
      this.resizeHandle.addEventListener('mousedown', this.initResize, false);
      this.initResize();
    }
  }

  onResizeStart(event) {
    const actualElement = event.target;

    const elementOffset = {
      top: actualElement.getBoundingClientRect().top + window.scrollY,
      left: actualElement.getBoundingClientRect().left + window.scrollX,
    };

    event.preventDefault();
    event.stopPropagation();
    if (!this.clonedElement) {
      const div = document.createElement('div');
      div.className += 'ui-resizable-helper';
      div.style.overflow = 'hidden';
      this.clonedElement = div;

    }

    this.clonedElement.style.width = parseInt(actualElement.getBoundingClientRect().width, 10) - 1 + 'px';
    this.clonedElement.style.height = parseInt(actualElement.getBoundingClientRect().height, 10) - 1 + 'px';
    this.clonedElement.style.position = 'absolute';
    this.clonedElement.style.left = `${elementOffset.left}px`;
    this.clonedElement.style.top = `${elementOffset.top}px`;
    this.clonedElement.style.zIndex = 90;
    document.body.appendChild(this.clonedElement);
    this.resizeStart.emit(event);
  }

  resize(event, limits) {
    const actualElement = event.target;
    const helper: any = document.querySelector(this.HELPER);
    if (helper) {
      const ui = {
        position: {
          top: parseInt(helper.offsetTop, 10),
          left: parseInt(helper.offsetLeft, 10)
        }
      };
      helper.style.width = parseInt(actualElement.getBoundingClientRect().width, 10) + (event.clientX - event.x0) + 'px';
      helper.style.height = actualElement.getBoundingClientRect().height + 'px';
      helper.style.position = 'absolute';
      helper.style.left = `${ui.position.left}px`;
      helper.style.top = `${ui.position.top}px`;
      helper.style.minWidth = `${limits.minWidth}px`;
      helper.style.maxWidth = `${limits.maxWidth}px`;
      this.resizing.emit(event);
    }

  }

  onResizeStop(event, minResizeWidth) {
    const helper: any = document.querySelector(this.HELPER);
    if (helper) {
      const ui = {
        size: {
          height: helper.offsetHeight - 1,
          width: helper.offsetWidth - 1
        }
      };
      const endW = ui.size.width + this.resizeThreshold;
      event.preventDefault();
      event.stopPropagation();
      document.querySelector(this.HELPER).remove();
      if (endW - minResizeWidth > 0) {
        this.resizeStop.emit({ stepsDifference: Math.round(endW / minResizeWidth) });
      }
    }
  }

  /**
   * @description This method will execute when user will press handle to start stretching
   * @memberof ResizableDirective
   */
  initResize = () => {
    let limits = {};
    let minResizeWidth;
    //  Todo : to be checked if its true//
    interact.default(this.el.nativeElement).resizable({
      axis: 'x',
      edges: {
        top: false,      // Use pointer coords to check for resize.
        left: false,    // Disable resizing from left edge.
        bottom: false,   // Resize if pointer target matches selector
        right: true     // Resize if pointer target is the given Element
      },
      square: true
    }).on('resizestart', (event) => {
      const actualElement = event.target;
      if (
        (actualElement.getBoundingClientRect().width < this.minResizeWidth || actualElement.getBoundingClientRect().width > this.minResizeWidth)) {
        // In case of zoom In/Out
        minResizeWidth = actualElement.getBoundingClientRect().width;
        if (this.actualCellWidth > 1) {
          minResizeWidth = actualElement.getBoundingClientRect().width / this.actualCellWidth;
        }
      } else {
        minResizeWidth = this.minResizeWidth;
      }

      limits = {
        maxWidth: this.maxResizeWidth * minResizeWidth,
        minWidth: minResizeWidth
      };

      // added if conditions as it was resizing for first time in case of loaded campaign and column locking and made service call
      if (!this.disableResize) {
        this.onResizeStart(event);
      }

    }).on('resizeend', (event) => {
      // added if conditions as it was resizing for first time in case of loaded campaign and made service call
      if (!this.disableResize) {
        this.onResizeStop(event, minResizeWidth);
      }
    }).on('resizemove', (event) => {
      // added if conditions as it was resizing for first time in case of loaded campaign and made service call
      if (!this.disableResize) {
        this.resize(event, limits);
      }
    });
  }
}
