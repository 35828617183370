<div class="savecampaign modal-header" [ngStyle]="{'background-color':brickBgColor}">
    <h3 class="modal-title">{{userBundle['result.confirmation.label']}}</h3>
    <button aria-label="Close" class="close" type="button" (click)="onModalClosed()">x</button>
</div>
<div class="modal-body">
    <div class="removal-confirm-message"> {{userBundle['commercial.competition.confirmation']}} </div>
    <ag-grid-angular class="ag-theme-fresh" [gridOptions]="gridOptions" [rowData]="rowData" [columnDefs]="columnDefs">
    </ag-grid-angular>

</div>
<div class="modal-footer" style="clear: both">
    <div footer>
        <button class="btn btn-primary" style="min-width: auto;"
            (click)="onModalSaved()">{{userBundle['common.yes']}}</button>
        <button class="btn btn-danger confirm" style="min-width: auto;"
            (click)="onModalClosed()">{{userBundle['common.no']}}</button>
    </div>
</div>
